import React from 'react';
import { Row, Col } from 'antd';
import './AntFilterWrapper.scss';

const AntFilterWrapper = props => {
  return (
    <div className="filter-wrapper-container">
      <Row gutter={{ sm: 16 }}>
        {React.Children.map(props.children, (child, index) => {
          return <Col key={index}>{child}</Col>;
        })}
      </Row>
    </div>
  );
};

export default AntFilterWrapper;
