/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";

/**
* Attachments service.
* @module api/CameraApi
* @version 1.0
*/
export default class CameraApi {

  /**
  * Constructs a new CameraApi. 
  * @alias module:api/CameraApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Receive the camera status by device id.
   * @callback module:api/CameraApi~getCameraStatusCallback
   */
  getCameraStatusById(id, key, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling getCameraStatusById");
    }

    if (key === undefined || id === null) {
      throw new Error("Missing the required parameter 'key' when calling getCameraStatusById");
    }

    let pathParams = {
      'id': id
    };

    let queryParams = {
      'key': key,
    };

    let headerParams = {
    };

    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = [];
    let returnType = "json";
    return this.apiClient.callApi(
      '/cameras/{id}/status', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null, callback
    );
  }

  /**
   * Receive the camera video by device id, start time, length, and provider.
   * @callback module:api/CameraApi~getCameraStatusCallback
   */
  requestVideo(id, key, start, length, provider, camera, requestorId,checkDuplicate,callback) {
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling requestVideo");
    }

    if (key === undefined || id === null) {
      throw new Error("Missing the required parameter 'key' when calling requestVideo");
    }

    if (start === undefined || start === null) {
      throw new Error("Missing the required parameter 'start' when calling requestVideo");
    }

    if (length === undefined || length === null) {
      throw new Error("Missing the required parameter 'start' when calling requestVideo");
    }

    if (provider === undefined || provider === null) {
      throw new Error("Missing the required parameter 'provider' when calling requestVideo");
    }

    if (requestorId === undefined || requestorId === null) {
      throw new Error("Missing the required parameter 'requestorId' when calling requestVideo");
    }
    
    let postBody = {
      Start: start,
      Length: length,
      Provider: provider,
      RequestorId: requestorId,
      CheckDuplicate:checkDuplicate
    };

    if (camera != null) {
      postBody.Camera = camera;
    }

    let pathParams = {
      'id': id
    };

    let queryParams = {
      'key': key,
    };

    let headerParams = {
    };

    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = [];
    let returnType = "json";
    return this.apiClient.callApi(
      '/cameras/{id}/videos', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null, callback
    );
  }

  /**
   * Request video footage for an event.
   */
  requestUploadVideo(deviceId, eventId, provider, eventTime, callback) {
    if (!deviceId) {
      throw new Error("Missing the required parameter 'deviceId' when calling requestVideo");
    }

    if (!eventId) {
      throw new Error("Missing the required parameter 'key' when calling requestVideo");
    }

    if (!provider) {
      throw new Error("Missing the required parameter 'provider' when calling requestVideo");
    }

    if (!eventTime) {
      throw new Error("Missing the required parameter 'eventTime' when calling requestVideo");
    }
    
    let postBody = {
      EventId: eventId,
      Provider: provider,
      EventDateTime: eventTime
    };

    let pathParams = {
      'id': deviceId
    };

    let queryParams = {
    };

    let headerParams = {
    };

    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = [];
    let returnType = "json";
    return this.apiClient.callApi(
      '/cameras/{id}/videos/upload', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null, callback
    );
  }

  /**
   * Request retry video footage for an event.
   */
  requestRetryVideoFootage(deviceId, eventId, provider, eventTime, callback) {
    if (!deviceId) {
      throw new Error("Missing the required parameter 'deviceId' when calling requestRetryVideoFootage");
    }

    if (!eventId) {
      throw new Error("Missing the required parameter 'key' when calling requestRetryVideoFootage");
    }

    if (!provider) {
      throw new Error("Missing the required parameter 'provider' when calling requestRetryVideoFootage");
    }

    if (!eventTime) {
      throw new Error("Missing the required parameter 'eventTime' when calling requestRetryVideoFootage");
    }
    
    let postBody = {
      EventId: eventId,
      Provider: provider,
      EventDateTime: eventTime
    };

    let pathParams = {
      'deviceId': deviceId
    };

    let queryParams = {
    };

    let headerParams = {
    };

    let formParams = {
    };

    let authNames = [];
    let contentTypes = [];
    let accepts = [];
    let returnType = "json";
    return this.apiClient.callApi(
      '/cameras/{deviceId}/videos/retry', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, null, callback
    );
  }
}
