import React, { useCallback, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';
import { FormLabel } from 'react-bootstrap';
import { Field } from 'formik';
import { Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useIsFetching as useIsDriversFetching, useDrivers } from 'features/users/usersSlice';
import { useBranches } from 'features/locations/locationsSlice';
import { useDevices } from 'features/fleets/fleetsSlice';

import { useCan, entities } from 'features/permissions';
import { useUserInfo, useUserKey } from 'features/user/userSlice';
import styles from 'components/multipleSelectTable/MultipleSelectTable.module.scss';

import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { ELD_TACHO_DRIVER_PATTERN, getCompanyDriverOptions } from '../APICalls';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { toLower } from 'lodash';
import {
  DEFAULT_DRIVER_FORM_FIELD_NAME,
  canVehicleHasDefaultDriver as vehicleCanDefaultDriver
} from './constants';

const driverOptionMapFunc = (driver, branches) => {
  return {
    ...driver,
    fullName: `${driver.firstName || ''} ${driver.lastName || ''}`,
    branchName: branches?.find(
      branch => parseInt(branch?.id, 10) === parseInt(driver?.location?.id, 10)
    )?.name
  };
};

const useCurrentDriverOptions = () => {
  const _drivers = useDrivers();
  const isFetchingDrivers = useIsDriversFetching();
  const branches = useBranches();
  const driverOptions = useMemo(
    () =>
      isFetchingDrivers
        ? []
        : _drivers
            ?.filter(
              d =>
                !d.rulesets?.length ||
                d.rulesets.some(r => !(!r.expiresAt && r.ruleset.match(ELD_TACHO_DRIVER_PATTERN)))
            )
            .map(driver => driverOptionMapFunc(driver, branches)),
    [_drivers, isFetchingDrivers, branches]
  );
  return {
    isLoading: isFetchingDrivers,
    driverOptions
  };
};

const useCompanyDriverOptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyDriverOptions, setCompanyDriverOptions] = useState(null);
  const userKey = useUserKey();
  const currentUser = useUserInfo();

  const currentCompanyId = useCurrentCompanyId();
  const {
    isLoading: isCurrentLoading,
    driverOptions: currentDriverOpions
  } = useCurrentDriverOptions();

  const fetchCompanyDriverOptions = useCallback(
    companyId => {
      const isCompanyChanged = !(
        !companyId || parseInt(currentCompanyId, 10) === parseInt(companyId, 10)
      );
      if (isCompanyChanged) {
        setIsLoading(true);
        getCompanyDriverOptions(
          companyId,
          userKey,
          currentUser?.siteAdmin,
          driverOptionMapFunc
        ).then(options => {
          setIsLoading(false);
          setCompanyDriverOptions(options);
        });
      } else {
        setCompanyDriverOptions(null);
      }
    },
    [currentUser, userKey, currentCompanyId]
  );

  const isLoadingOptions = useMemo(() => isLoading || isCurrentLoading, [
    isLoading,
    isCurrentLoading
  ]);
  const driverOptions = useMemo(() => companyDriverOptions || currentDriverOpions, [
    companyDriverOptions,
    currentDriverOpions
  ]);

  return {
    isLoading: isLoadingOptions,
    driverOptions,
    fetchCompanyDriverOptions
  };
};
const isIDEqual = (idA, idB) => {
  const NoId = Symbol('NO_ID');
  const a =
    idA && !isNaN(parseInt(idA, 10)) && isFinite(parseInt(idA, 10)) ? parseInt(idA, 10) : NoId;
  const b =
    idB && !isNaN(parseInt(idB, 10)) && isFinite(parseInt(idB, 10)) ? parseInt(idB, 10) : NoId;
  return {
    isEqual: a === b,
    isBRemovedFromA: a !== b && b === NoId
  };
};

export const useDefaultDriverField = ({ vehicleInfo, formFieldName }) => {
  const { t } = useTranslation();
  const can = useCan();
  const currentUser = useUserInfo();
  const currentCompanyId = useCurrentCompanyId();

  const { driverOptions, isLoading, fetchCompanyDriverOptions } = useCompanyDriverOptions(
    currentCompanyId
  );

  const devices = useDevices();
  const [companyDevices, setCompanyDevices] = useState(devices);

  const canSetDefaultDriver = useMemo(
    () =>
      can({
        everyEntity: [entities.VEHICLE_UPDATE, entities.USER_UPDATE]
      }),
    [currentUser]
  );

  const canVehicleHasDefaultDriver = useCallback(
    vehicleData => vehicleCanDefaultDriver(vehicleData, companyDevices),
    [companyDevices]
  );

  const canDefaultDriver = useCallback(
    vehicleFormValues => canSetDefaultDriver && canVehicleHasDefaultDriver(vehicleFormValues),
    [canSetDefaultDriver, canVehicleHasDefaultDriver]
  );

  const [defaultDriverStatus, setDefaultDriverStatus] = useState({
    isChanged: false,
    isRemoved: false
  });

  const DefaultDriverSelect = useCallback(() => {
    return (
      <DriverSelectFormField
        formFieldName={formFieldName}
        onDriverChanged={setDefaultDriverStatus}
        driverOptions={driverOptions}
        isLoading={isLoading}
      />
    );
  }, [formFieldName, driverOptions, isLoading]);

  const confirmDefaultDriverChange = useCallback(
    async values => {
      return new Promise(resolve => {
        if (canDefaultDriver(values)) {
          const { isChanged, isRemoved } = defaultDriverStatus;
          const selectedDriver = driverOptions?.find(
            driverOpt => parseInt(driverOpt?.id, 10) === parseInt(values?.[formFieldName], 10)
          );
          if (isChanged) {
            confirmationModal(
              t('Common.Modal.SureTitle'),
              t(`Vehicles.Form.DriverMng.${isRemoved ? 'ConfirmRemove' : 'ConfirmInsup'}`, {
                driverName: selectedDriver?.fullName,
                vehicleName: vehicleInfo?.name
              }),
              t('Vehicles.Form.DriverMng.Continue'),
              t('Vehicles.Form.DriverMng.Back'),
              () => {
                resolve({
                  confirmed: true
                });
              },
              null,
              null,
              null,
              '600px'
            );
          } else {
            resolve({
              confirmed: true
            });
          }
        } else {
          resolve({
            confirmed: true,
            removeDefaultDriverField: true
          });
        }
      });
    },
    [t, canDefaultDriver, defaultDriverStatus, formFieldName, vehicleInfo]
  );

  const onCompanyFieldChange = useCallback(
    (companyId, initialValues, setFieldValue) => {
      const { isEqual } = isIDEqual(initialValues?.companyId, companyId);
      setFieldValue(
        DEFAULT_DRIVER_FORM_FIELD_NAME,
        isEqual ? initialValues?.[DEFAULT_DRIVER_FORM_FIELD_NAME] : ''
      );
      fetchCompanyDriverOptions(companyId);
    },
    [fetchCompanyDriverOptions]
  );

  return {
    onCompanyFieldChange,
    setCompanyDevices,
    canDefaultDriver,
    DefaultDriverSelect,
    confirmDefaultDriverChange
  };
};

const DriverSelectFormField = ({ formFieldName, onDriverChanged, driverOptions, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Field name={formFieldName}>
      {({ field, form, meta }) => (
        <Row>
          <Col span={24}>
            <FormLabel>{t('Vehicles.Form.DriverMng.DefaultDriver')}</FormLabel>
          </Col>
          <Col span={24}>
            <Select
              allowClear
              showSearch
              className={styles.selectContainer}
              disabled={isLoading}
              loading={isLoading}
              filterOption={(input, option) => {
                const matched = driverOptions?.find(
                  driverOpt => parseInt(driverOpt?.id, 10) === parseInt(option.value, 10)
                );
                return (
                  (matched?.fullName && toLower(matched.fullName).includes(toLower(input))) ||
                  (matched?.branchName && toLower(matched.branchName).includes(toLower(input))) ||
                  (matched?.licenceNumber &&
                    toLower(matched.licenceNumber).includes(toLower(input)))
                );
              }}
              value={field?.value}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              optionLabelProp="label"
              onSelect={value => {
                form.setFieldValue(field?.name, value);
                const { isEqual, isBRemovedFromA } = isIDEqual(meta.initialValue, value);
                onDriverChanged({
                  isChanged: !isEqual,
                  isRemoved: isBRemovedFromA
                });
              }}
              onClear={() => {
                form.setFieldValue(field?.name, '');
                const { isEqual, isBRemovedFromA } = isIDEqual(meta.initialValue, '');
                onDriverChanged({
                  isChanged: !isEqual,
                  isRemoved: isBRemovedFromA
                });
              }}
              placeholder={t('DriverManagement.Form.SelectDriver')}
              style={{ width: '100%' }}
            >
              <Select.Option className={styles.alertItemContainer} key={String(nanoid())} disabled>
                <div
                  className={styles.optionsContainer}
                  onMouseDown={e => e.preventDefault()}
                  disabled
                >
                  <span className={styles.option}>{t('Common.Name')}</span>
                  <span className={styles.option}>{t('Common.Registration')}</span>
                  <span className={styles.option}>{t('Common.Branch')}</span>
                </div>
              </Select.Option>
              {driverOptions?.map(driver => (
                <Select.Option
                  className={styles.alertItemContainer}
                  key={String(driver.id)}
                  value={driver.id}
                  label={driver?.fullName}
                >
                  <div className={styles.optionsContainer}>
                    <span className={styles.option}>{driver?.fullName}</span>
                    <span className={styles.option}>{driver?.licenceNumber || ''}</span>
                    <span className={styles.option}>{driver?.branchName || ''}</span>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      )}
    </Field>
  );
};
