import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './WeeklyScheduleSelector.module.scss';
import {
  getDaysOfWeek,
  getHoursMins,
  isEndOfDay,
  gethoursOfDay,
  getFormatedHoursMins
} from './helpers';
import { Row, Col } from 'antd';

/**period
 * {iosDayOfWeek}:[{start: hh:mm},{end: hh:mm}]
 */

const SELECT_TYPE = {
  ADD: 'ADD',
  REMOVE: 'REMOVE'
};

const EMPTY_OBJECT = {};

const weeklySelectionToPeriod = (selection = new Set()) => {
  const period = {};
  selection = selection || new Set();
  selection = new Set(Array.from(selection).sort());
  for (const dayTime of selection.values()) {
    const iosDayOfWeek = (dayTime - (dayTime % 24)) / 24;
    const dayHours = dayTime % 24;
    const nextDayHours = dayHours + 1;
    if (period[iosDayOfWeek]?.length) {
      const isExtendingDownSegment = period[iosDayOfWeek].find(segment => {
        return dayHours === segment.end;
      });
      const isExtendingUpSegment = period[iosDayOfWeek].find(segment => {
        let prevStartHour = segment.start - 1;
        prevStartHour = prevStartHour < 0 ? 0 : prevStartHour;
        return dayHours === prevStartHour;
      });

      if (isExtendingDownSegment) {
        isExtendingDownSegment.end = nextDayHours;
      } else if (isExtendingUpSegment) {
        isExtendingUpSegment.start = dayHours;
      } else {
        period[iosDayOfWeek].push({
          start: dayHours,
          end: nextDayHours
        });
      }
    } else {
      period[iosDayOfWeek] = [
        {
          start: dayHours,
          end: nextDayHours
        }
      ];
    }
  }
  return Object.keys(period)
    .map(isoday => ({
      isoday,
      dayPeriods: period[isoday].map(segment => ({
        start: getFormatedHoursMins(segment.start),
        end: getFormatedHoursMins(segment.end)
      }))
    }))
    .reduce(
      (a, c) => ({
        ...a,
        [c.isoday]: c.dayPeriods
      }),
      {}
    );
};

const weeklyPeriodToSelection = (period = {}) => {
  let selection = new Set();
  period = period || {};
  for (const isoday of Object.keys(period)) {
    for (const dayPeriods of period[isoday]) {
      const { hours: startHours } = getHoursMins(dayPeriods.start);
      const { hours: endHours } = getHoursMins(dayPeriods.end);

      for (let index = startHours; index < endHours; index++) {
        selection.add(isoday * 24 + index);
      }
      if (isEndOfDay(dayPeriods.end)) {
        selection.add(isoday * 24 + endHours);
      }
    }
  }
  selection = new Set(Array.from(selection).sort());
  return selection;
};

const getHourCellCls = (day, hour, selection, dayTime) => {
  const period = weeklySelectionToPeriod(selection);
  const dayActiveSegments = period?.[day.isoWeekday] || [];
  let isFirstActivated = false,
    isLastActivated = false,
    CSSCls = '';
  const isActiveHour = selection.has(dayTime);
  try {
    isFirstActivated = dayActiveSegments.find(
      activatedSegment => getHoursMins(activatedSegment.start).hours === hour.start
    );
    isLastActivated = dayActiveSegments.find(
      activatedSegment =>
        (isEndOfDay(activatedSegment.end) ? 24 : getHoursMins(activatedSegment.end).hours) ===
        hour.end
    );
    CSSCls = `${isFirstActivated ? styles.firstActivatedDateCell : ''} ${
      isLastActivated ? styles.lastActivatedDateCell : ''
    }`;
  } catch (error) {
    CSSCls = '';
  }
  return `${isActiveHour ? styles.activeHour : ''} ${isActiveHour ? CSSCls : ''}`;
};

export const WeeklyScheduleSelector = ({
  period = EMPTY_OBJECT,
  onPeriodChange = () => {},
  diabled = false,
  renderDateLabel = dayOfWeek => dayOfWeek.label,
  wrapperCls = styles.scheduleSelectorWrapper
}) => {
  const { t } = useTranslation();
  const [selectionMeta, setSelectionMeta] = useState({ type: null, start: null });
  const [selection, setSelection] = useState(new Set());

  const daysOfWeek = useMemo(() => getDaysOfWeek(t), [t]);
  const hoursOfDay = gethoursOfDay();

  useEffect(() => {
    setSelection(weeklyPeriodToSelection(period));
  }, [period]);

  const onSelectStart = useCallback(
    dayTime => {
      const type = selection.has(dayTime) ? SELECT_TYPE.REMOVE : SELECT_TYPE.ADD;
      setSelectionMeta({
        type,
        start: dayTime
      });
      setSelection(cells => {
        if (type === SELECT_TYPE.ADD) {
          cells.add(dayTime);
        } else {
          cells.delete(dayTime);
        }
        return cells;
      });
    },
    [selection]
  );

  const onSelecting = useCallback(
    dayTime => {
      const { type, start } = selectionMeta;
      if (type === null || start === null) {
        return;
      } else {
        setSelection(cells => {
          if (type === SELECT_TYPE.ADD) {
            cells.add(dayTime);
          } else {
            cells.delete(dayTime);
          }
          return cells;
        });
      }
    },
    [selectionMeta]
  );

  const onSelectEnd = useCallback(() => {
    onPeriodChange(weeklySelectionToPeriod(selection));
    setSelectionMeta({
      type: null,
      start: null
    });
  }, [selection]);

  useEffect(() => {
    document.addEventListener('mouseup', onSelectEnd);
    return () => {
      document.removeEventListener('mouseup', onSelectEnd);
    };
  }, [onSelectEnd]);

  return (
    <div className={`${wrapperCls}`}>
      <Row className={`${styles.scheduleSelectorGrid}`} wrap={false}>
        {daysOfWeek.map((day, dayIndex) => (
          <React.Fragment key={dayIndex}>
            {dayIndex === 0 && (
              <Col key={`timeLabel`} className={`${styles.verticalTimeLabel}`}>
                {hoursOfDay.map((hour, hourIndex) => (
                  <Row key={`timeHour${hourIndex}`} className={`${styles.verticalTimeLabelItem}`}>
                    <div key={`timeHourLabel${hourIndex}`}>{hour.label}</div>
                  </Row>
                ))}
              </Col>
            )}
            <Col flex={1} key={`dayCol${dayIndex}`} className={styles.day}>
              {hoursOfDay.map((hour, hourIndex) => {
                const dayTime = day.isoWeekday * 24 + hour.time;
                return (
                  <HourCell
                    key={`dayHour${hourIndex}`}
                    wrapperCls={`${styles.hour} ${diabled ? '' : styles.hourWithHover}`}
                    activeCls={getHourCellCls(day, hour, selection, dayTime)}
                    onMouseDown={e => !diabled && onSelectStart(dayTime)}
                    onMouseUp={e => !diabled && onSelecting(dayTime)}
                    onMouseMove={e => !diabled && onSelecting(dayTime)}
                  />
                );
              })}
              <Row flex={1} className={styles.daysOfWeekLabelItem} key={`dayLabel${dayIndex}`}>
                <span>{renderDateLabel(day)}</span>
              </Row>
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </div>
  );
};

const HourCell = ({ onMouseDown, onMouseUp, onMouseMove, wrapperCls, activeCls }) => {
  return (
    <Row
      flex={1}
      className={wrapperCls}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      <div className={activeCls}></div>
    </Row>
  );
};

export const WeeklyScheduleSelectorViewOnly = ({ period = {} }) => {
  return (
    <WeeklyScheduleSelector
      period={period}
      diabled={true}
      renderDateLabel={dayOfWeek => dayOfWeek.shortLabel}
      wrapperCls={styles.scheduleSelectorViewWrapper}
    />
  );
};
