import React from 'react';
import { DateRangePicker } from 'components/ant/DateTime/DateRangePicker';
import moment from 'moment';
import dayjs from 'dayjs';

export function ELDDateRangePicker({ defaultDates, onDateRangeChanged, disabled, format }) {
  return (
    <DateRangePicker
      defaultDates={defaultDates}
      disabled={disabled}
      maxDayRange={30}
      availableDatesRange={[dayjs().subtract(6, 'month'), dayjs()]}
      onDateRangeChanged={onDateRangeChanged}
      format={format}
    />
  );
}
