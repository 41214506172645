import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import moment from 'moment';

import { ViewDriverLink } from 'components/sentinel/ViewDriverLink';
import { RECIPIENT_TYPE } from 'containers/Messaging/constants';
import { MessageComposeModal } from 'containers/Messaging/MessageComposeModal';
import { GPIOModal } from 'containers/Tracking/Modals/GPIOModal';
import { RouteToModal, useRouteTo } from 'containers/Tracking/RouteTo';
import { buildHDDataUrl } from 'containers/Tracking/helpers';

import { RequestFootageModal } from 'features/requestFootage/RequestFootageModal';
import { VehicleFootageRequestMenuButtons } from 'features/requestFootage/RequestFootageButton';
import { ForceLogoffMenuButton } from 'features/users/forceLogoff/forceLogoffMenuButton';
import { getIsFatigueComplianceDriver } from 'features/users/forceLogoff/useForceLogoff';
import {
  entities,
  services,
  useCan,
  useCanEveryEntity,
  useCanOneOfServices,
  useIQCameraUser,
  FeatureFlag,
  useCanFeatureFlag
} from 'features/permissions';
import {
  CompanyConfigKey,
  CompanyConfigValue,
  useCurrentCompany,
  useSubCompanyEntityConfig
} from 'features/company/companySlice';
import { useDrivers } from 'features/users/usersSlice';
import { useDevices, useVehicles } from 'features/fleets/fleetsSlice';

import styles from './ActionsMenu.module.scss';
import { ActivateRecoveryModeModel } from '../Modals/ActivateRecoveryModeModel';

export const ActionsMenu = ({ device, openShareLiveLocationModal, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const company = useCurrentCompany();
  const can = useCan();
  const hideNonBusinessTrips = useSubCompanyEntityConfig(
    company?.id,
    CompanyConfigKey.HideNonBusiness
  );
  const drivers = useDrivers();
  const vehicles = useVehicles();
  const devices = useDevices();

  const [showMessagingModal, setShowMessagingModal] = useState(false);
  const [showGPIOModal, setShowGPIOModal] = useState(false);
  const [showActivateRecoveryMode, setShowActivateRecoveryMode] = useState(false);
  const [messagingRecipient, setMessagingeRecipient] = useState(null);
  const [showRequestVideoModal, setShowRequestVideoModal] = useState(false);
  const [requestVideoDeviceId, setRequestVideoDeviceId] = useState(null);
  const [requestVideoVehicleId, setRequestVideoVehicleId] = useState(null);
  const [showRouteToModal, setShowRouteToModal] = useState(false);
  const [routeData, setRouteData] = useState(null);
  const logonDriver = drivers?.find(d => d.id === device.driver?.id);
  const entityType = !!device.vehicleId ? entities.VEHICLE : entities.DEVICE;
  const selectedVehicle = vehicles?.find(v => v.id === device.vehicleId);
  const isDefaultDriver =
    entityType === entities.VEHICLE &&
    selectedVehicle &&
    logonDriver &&
    selectedVehicle.defaultDriver === logonDriver.id;

  const canMessaging = useCanOneOfServices(services.MESSAGING);
  const canGPIO = useCanOneOfServices(services.GPIO);
  const canForceLogoffButtonPermission = useCanEveryEntity([
    entities.USER_VIEW,
    entities.USER_UPDATE,
    entities.VEHICLE_VIEW,
    entities.VEHICLE_UPDATE
  ]);
  const canCameraService = useCanOneOfServices(services.CAMERA);
  const canVideoEntities = useCanEveryEntity([entities.VIDEO, entities.VIDEO_CREATE]);
  const canShareVehicleLiveLocation = useCanEveryEntity([entities.VEHICLE_SHARE]);

  const { canAccessNonCameraFeatures } = useIQCameraUser();

  const showHDData = can({
    everyService: [services.TRACKINGHD],
    everyCompanyService: [services.TRACKINGHD]
  });

  const { isEWDDriver, isFatigueComplianceDriver } = useMemo(() => {
    const isEWDDriver =
      can({ everyCompanyService: [services.EWD] }) &&
      !!logonDriver?.associations?.find(
        assoc => assoc?.domain === 'EWD' && assoc?.externalId?.includes('driver_id')
      );

    const isFatigueComplianceDriver = getIsFatigueComplianceDriver(logonDriver);

    return { isEWDDriver, isFatigueComplianceDriver };
  }, [logonDriver]);

  const cameraDevices = useMemo(() => {
    const cameraDevices = new Map();

    if (device.id && devices) {
      const newDevice = devices.find(d => d.id === device.id);
      if (newDevice?.type.name === 'Camera') {
        cameraDevices.set(device.id, newDevice);
      }
    }

    if (devices && device.vehicle?.id) {
      devices.forEach(d => {
        if (d.type?.name === 'Camera' && d.vehicle?.id === device.vehicle?.id) {
          cameraDevices.set(d.id, d);
        }
      });
    }

    return Array.from(cameraDevices.values());
  }, [device, devices]);

  const isSmartNav3D = useCallback(() => {
    return company.features?.some(feature => feature.code === services.SMARTNAV3D);
  }, [company]);

  const handleViewVehicle = event => {
    device.vehicleId && history.push(`/settings/vehicles/id/${device.vehicleId}`);
  };

  const handleViewDevice = event => {
    device.id && history.push(`/settings/devices/id/${device.id}`);
  };

  const handleMessageVehicle = event => {
    if (device.vehicleId && device.vehicleId > -1) {
      event.domEvent.preventDefault();
      event.domEvent.stopPropagation();
      setMessagingeRecipient({
        recipientType: RECIPIENT_TYPE.VEHICLE,
        recipientId: device.vehicleId
      });
      setShowMessagingModal(true);
    }
  };

  const handleMessageDevice = event => {
    if (device.id || device.deviceStats?.deviceId) {
      event.domEvent.preventDefault();
      event.domEvent.stopPropagation();
      setMessagingeRecipient({
        recipientType: RECIPIENT_TYPE.DEVICE,
        recipientId: device.id || device.deviceStats?.deviceId
      });
      setShowMessagingModal(true);
    }
  };

  const handleRouteTo = event => {
    event.domEvent.preventDefault();
    event.domEvent.stopPropagation();
    setShowRouteToModal(true);
    setRouteData(device);
  };

  const handleGPIOclick = event => {
    setShowGPIOModal(true);
  };

  const cancelRouteTo = () => {
    setShowRouteToModal(false);
    setRouteData(null);
  };

  const handleRequestVideo = (vehicleId, deviceId) => {
    setRequestVideoDeviceId(deviceId);
    setRequestVideoVehicleId(vehicleId);
    setShowRequestVideoModal(true);
  };

  const handleHDData = () => {
    device.id &&
      history.push(
        buildHDDataUrl(
          device.id,
          moment()
            .startOf('day')
            .format(),
          moment()
            .endOf('day')
            .format()
        )
      );
  };

  const handleRecovery = () => {
    setShowActivateRecoveryMode(true);
  };

  const routeToProps = useRouteTo(routeData);

  const isGPIOcapable = device.vehicleName !== '' && device.type.code === 'HERMES';

  const actionMenuItems = useMemo(() => {
    const menuItems = [];

    if (canAccessNonCameraFeatures) {
      device.vehicleId &&
        menuItems.push({
          key: '1',
          eventKey: '1',
          onClick: handleViewVehicle,
          label: t('Tracking.ActionsMenu.ViewVehicle')
        });

      device.id &&
        menuItems.push({
          key: '2',
          eventKey: '2',
          onClick: handleViewDevice,
          label: t('Tracking.ActionsMenu.ViewDevice')
        });

      device.driver?.id &&
        menuItems.push({
          key: '3',
          eventKey: '3',
          label: (
            <ViewDriverLink driverId={device.driver?.id}>
              {({ onClick }) => (
                <span onClick={onClick}>{t('Tracking.ActionsMenu.ViewDriver')}</span>
              )}
            </ViewDriverLink>
          )
        });

      device.vehicleId &&
        canMessaging &&
        device.messagingDevice &&
        menuItems.push({
          key: '4',
          eventKey: '4',
          onClick: handleMessageVehicle,
          label: t('Tracking.ActionsMenu.MessageVehicle')
        });

      !device.vehicleId &&
        canMessaging &&
        device.messagingDevice &&
        menuItems.push({
          key: '5',
          eventKey: '5',
          onClick: handleMessageDevice,
          label: t('Tracking.ActionsMenu.MessageDevice')
        });

      device.routeToDevice &&
        isSmartNav3D() &&
        !(hideNonBusinessTrips && device?.deviceStats?.attr === CompanyConfigValue.Private) &&
        canMessaging &&
        device.messagingDevice &&
        menuItems.push({
          key: '6',
          eventKey: '6',
          onClick: handleRouteTo,
          label: t('Tracking.ActionsMenu.RouteTo')
        });

      canGPIO &&
        isGPIOcapable &&
        menuItems.push({
          key: '7',
          eventKey: '7',
          onClick: handleGPIOclick,
          label: t('Common.EllipsisButton.GPIO')
        });

      !isEWDDriver &&
        !isFatigueComplianceDriver &&
        canForceLogoffButtonPermission &&
        logonDriver &&
        menuItems.push({
          key: '9',
          eventKey: '9',
          title: isDefaultDriver ? t('Users.ForceLogoff.NoDefaultDriverLogoff') : '',
          disabled: isDefaultDriver,
          label: (
            <ForceLogoffMenuButton
              itemKey={'9'}
              vehicleId={device.vehicleId}
              deviceId={!device.vehicleId && device.id}
              driverId={device.driver?.id}
              menuType={'ant'}
              showName={false}
            />
          )
        });

      showHDData &&
        device.id &&
        menuItems.push({
          key: '10',
          eventKey: '10',
          onClick: handleHDData,
          label: t('Tracking.HDData')
        });

      device.id &&
        device.type.code === 'ATRACKER' &&
        menuItems.push({
          key: '12',
          eventKey: '12',
          onClick: handleRecovery,
          label: t('Tracking.RecoveryMode.ActivateRecoveryMode')
        });
    }

    canCameraService &&
      canVideoEntities &&
      !!cameraDevices.length &&
      cameraDevices.forEach(camera => {
        menuItems.push({
          key: `8-${camera.id}`,
          eventKey: `8-${camera.id}`,
          label: (
            <VehicleFootageRequestMenuButtons
              itemKey={`8-${camera.id}`}
              deviceId={device.id}
              vehicleId={device.vehicleId}
              onMenuClicked={handleRequestVideo}
              menuType={'ant'}
              propCameraDevices={[camera]}
            />
          )
        });
      });

    canShareVehicleLiveLocation &&
      device.vehicleId &&
      menuItems.push({
        key: '11',
        eventKey: '11',
        onClick: () => openShareLiveLocationModal(device.vehicle),
        label: t('Tracking.ActionsMenu.ShareVehicleLiveLocation')
      });

    return menuItems;
  }, [
    canAccessNonCameraFeatures,
    device,
    canMessaging,
    isSmartNav3D,
    canGPIO,
    isGPIOcapable,
    isEWDDriver,
    isFatigueComplianceDriver,
    canForceLogoffButtonPermission,
    logonDriver,
    canCameraService,
    canVideoEntities,
    cameraDevices
  ]);

  return actionMenuItems.length ? (
    <div className={styles.actionsMenuButton} {...props}>
      <Dropdown
        menu={{ items: actionMenuItems, rootClassName: styles.actionsMenu }}
        placement="bottomLeft"
        trigger="click"
      >
        <i style={{ fontSize: '26px' }} className={'tn-i-elipsis'} />
      </Dropdown>

      {showMessagingModal && (
        <MessageComposeModal
          visible={showMessagingModal}
          showModal={setShowMessagingModal}
          recipients={[messagingRecipient]}
        />
      )}

      {showRequestVideoModal && (
        <RequestFootageModal
          showModal={showRequestVideoModal}
          vehicleId={requestVideoVehicleId}
          deviceId={requestVideoDeviceId}
          onClose={() => {
            setShowRequestVideoModal(false);
          }}
        />
      )}

      {showRouteToModal && (
        <RouteToModal
          title={t('Tracking.RouteTo')}
          isOpen={showRouteToModal}
          data={routeData}
          handleCancel={cancelRouteTo}
          {...routeToProps}
        />
      )}

      {isGPIOcapable && showGPIOModal && (
        <GPIOModal
          visible={showGPIOModal}
          showModal={setShowGPIOModal}
          vehicleId={device.vehicleId}
          vehicleName={device.vehicleName}
        />
      )}
      {showActivateRecoveryMode && (
        <ActivateRecoveryModeModel
          visible={showActivateRecoveryMode}
          setShowModal={setShowActivateRecoveryMode}
          deviceId={device.id}
        />
      )}
    </div>
  ) : null;
};
