import React, { useEffect, useState, useReducer, useRef, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useIsChatting,
  useIsChattingFailed,
  useChattingFailedError,
  useIsChattingSuccess,
  setInitailChattingStatus,
  chat,
  useChatHistoricalMessages,
  deleteMessages,
  useIsDeleteMessagesFailed,
  useDeleteMessageFailedError,
  useIsDeleteMessagesSuccess,
  setInitailDeleteMessagesStatus,
  fetchHistoricalMessagesByEntity,
  useIsHistoricalMessagesbyEntityFetching,
  useUserMessagingPermissions,
  getDriverLatestInteractiveDevices,
  useUnreadMessagesMeta
} from 'features/messaging/messagingSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import {
  Skeleton,
  Card,
  Avatar,
  Input,
  Form,
  Button,
  Tooltip,
  Row,
  Col,
  Checkbox,
  Popover,
  Menu,
  Space,
  Alert,
  Radio
} from 'antd';
import { CloseOutlined, SearchOutlined, PrinterFilled, DeleteFilled } from '@ant-design/icons';
import styles from './Messaging.module.scss';
import {
  getMessageDisplayDate,
  shortName,
  chatEntityMessageSentFailed,
  getMessageSubject,
  getMessageBody,
  isRouteToMessage,
  getChatMessageDriverName,
  messageCanMarkAsRead
} from './helpers';
import {
  MESSAGE_STATUE,
  RECIPIENT_TYPE,
  MESSAGE_SENDER_TYPE,
  MESSAGE_STYLES,
  MESSAGE_TYPE,
  MESSAGE_PERMISSION_ENTITY_TYPE,
  MESSAGE_NOTIFICATION_TYPE
} from './constants';
import styled from '@emotion/styled';
import { PrintBtn } from './Print';
import { useLocalization } from 'features/localization/localizationSlice';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { MESSAGE_DELETE_MODE } from './constants';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import usePrevious from 'features/common/hooks/previous';
import { BUTTON_IDS } from 'utils/globalConstants';

const ChatBoxWrapper = styled.div`
  height: 100%;
  .ant-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 8px;
    .ant-card-head {
      min-height: 68px;
      border: 1px solid #e8ebee;
    }
    .ant-card-body {
      flex: 1 1 auto;
      background-color: rgb(249, 249, 249);
      overflow-y: scroll;
    }
  }
  .ant-card-actions {
    border-radius: 0 0 8px 8px;
    border: 1px solid #e8ebee;
  }
`;
const replyingStyle = {
  backgroundColor: '#e6f7ff',
  borderColor: '#91d5ff'
};

const groupMessageStyle = {
  backgroundColor: 'rgb(249,249,249)',
  borderColor: '#64748b',
  borderStyle: 'dashed'
};
const ChatMsgRow = styled.div`
  min-width: 80%;
  text-align: center;
  padding: 8px;
  border-width: 1px;
  border-style: ${props =>
    props.showAsGroupSeriesMessage ? groupMessageStyle.borderStyle : 'solid'};
  border-radius: 4px;
  border-color: ${props => {
    const { senderType, replying, showAsGroupSeriesMessage, notificationType } = props;
    if (replying) {
      return replyingStyle.borderColor;
    }
    if (showAsGroupSeriesMessage) {
      return groupMessageStyle.borderColor;
    }
    if (notificationType === MESSAGE_NOTIFICATION_TYPE.ROUTE_TO) {
      return MESSAGE_STYLES.ROUTE_TO.borderColor;
    }
    if (senderType === MESSAGE_SENDER_TYPE.WEB_DISPATCHER) {
      return MESSAGE_STYLES.WEB_DISPATCHER.borderColor;
    }
    return MESSAGE_STYLES.OTHER.borderColor;
  }};
  background: ${props => {
    const { senderType, replying, showAsGroupSeriesMessage, notificationType } = props;
    if (replying) {
      return replyingStyle.backgroundColor;
    }
    if (showAsGroupSeriesMessage) {
      return groupMessageStyle.backgroundColor;
    }
    if (notificationType === MESSAGE_NOTIFICATION_TYPE.ROUTE_TO) {
      return MESSAGE_STYLES.ROUTE_TO.backgroundColor;
    }
    if (senderType === MESSAGE_SENDER_TYPE.WEB_DISPATCHER) {
      return MESSAGE_STYLES.WEB_DISPATCHER.backgroundColor;
    }
    return MESSAGE_STYLES.OTHER.backgroundColor;
  }};
  position: relative;
  .messageChatClip {
    border-top-color: ${props => {
      const { senderType, replying, showAsGroupSeriesMessage, notificationType } = props;
      if (replying) {
        return replyingStyle.borderColor;
      }
      if (showAsGroupSeriesMessage) {
        return groupMessageStyle.borderColor;
      }
      if (notificationType === MESSAGE_NOTIFICATION_TYPE.ROUTE_TO) {
        return MESSAGE_STYLES.ROUTE_TO.borderColor;
      }
      if (senderType === MESSAGE_SENDER_TYPE.WEB_DISPATCHER) {
        return MESSAGE_STYLES.WEB_DISPATCHER.borderColor;
      }
      return MESSAGE_STYLES.OTHER.borderColor;
    }};
  }
  .messageChatClip:after {
    border-top-color: ${props => {
      const { senderType, replying, showAsGroupSeriesMessage, notificationType } = props;
      if (replying) {
        return replyingStyle.backgroundColor;
      }
      if (showAsGroupSeriesMessage) {
        return groupMessageStyle.backgroundColor;
      }
      if (notificationType === MESSAGE_NOTIFICATION_TYPE.ROUTE_TO) {
        return MESSAGE_STYLES.ROUTE_TO.backgroundColor;
      }
      if (senderType === MESSAGE_SENDER_TYPE.WEB_DISPATCHER) {
        return MESSAGE_STYLES.WEB_DISPATCHER.backgroundColor;
      }
      return MESSAGE_STYLES.OTHER.backgroundColor;
    }};
  }
  &:hover {
    cursor: pointer;
  }
`;

const ChatBubbleActions = {
  SELECT: 'SELECT',
  REPLY: 'REPLY'
};

const ChatToolbarMode = {
  Default: 'Default',
  Seach: 'Seach',
  Select: 'Select'
};

export const MessagingChatBox = ({
  getChatEntityName,
  listOfDriverVehicleWithMessagingDevices
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localization = useLocalization();

  const isGettingHistoricalMessages = useIsHistoricalMessagesbyEntityFetching();

  const {
    chatEntity,
    isGroupMessageChatEntity,
    flattenHistoricalMessages,
    hasUnreadMessages,
    markAsRead
  } = useChatHistoricalMessages();
  const userMsgPermission = useUserMessagingPermissions();

  const [chatToolbarMode, setChatToolbarMode] = useState(ChatToolbarMode.Default);
  const [searchKeywords, setSearchKeywords] = useState(null);
  const setSearchMode = mode => {
    mode ? setChatToolbarMode(ChatToolbarMode.Seach) : setChatToolbarMode(ChatToolbarMode.Default);
    setSearchKeywords(null);
  };
  const setSelectMode = mode => {
    mode ? setChatToolbarMode(ChatToolbarMode.Select) : setChatToolbarMode(ChatToolbarMode.Default);
    setSearchKeywords(null);
  };

  const [replyingMessage, setReplyingMessage] = useState(null);
  const [selectedChatMsgs, setSelectedChatMsgs] = useState([]);

  const prevChatEntity = usePrevious(chatEntity);

  const isSameChatEntity = useMemo(() => {
    return (
      prevChatEntity?.chatEntityType === chatEntity.chatEntityType &&
      prevChatEntity?.chatEntityName === chatEntity.chatEntityName &&
      prevChatEntity?.chatEntityId === chatEntity.chatEntityId
    );
  }, [prevChatEntity, chatEntity]);

  useEffect(() => {
    if (!isSameChatEntity) {
      setSearchMode(false);
      setSearchKeywords(null);
      setSelectMode(false);
      setSelectedChatMsgs([]);
      setReplyingMessage(null);
    }
  }, [isSameChatEntity]);

  useEffect(() => {
    dispatch(fetchHistoricalMessagesByEntity(chatEntity));
  }, [chatEntity, dispatch]);

  useEffect(() => {
    if (hasUnreadMessages) {
      markAsRead();
    }
  }, [hasUnreadMessages, markAsRead]);

  return (
    <ChatBoxWrapper>
      <Card
        className={`${styles.messageChatBox} ${
          isGettingHistoricalMessages ? styles.messageChatBoxloading : ''
        }`}
        loading={isGettingHistoricalMessages}
        title={
          <Skeleton loading={false} avatar active>
            <Card.Meta
              style={{ display: 'flex', alignItems: 'center' }}
              avatar={
                <ChatAvatar
                  size={38}
                  name={getChatEntityName(chatEntity)}
                  style={{ fontSize: '18px' }}
                />
              }
              title={getChatEntityName(chatEntity, true)}
            />
          </Skeleton>
        }
        extra={
          <ChatToolbar
            {...{
              t,
              chatEntity,
              isGroupMessageChatEntity,
              localization,
              selectMode: chatToolbarMode === ChatToolbarMode.Select,
              setSelectMode,
              selectedChatMsgs,
              setSelectedChatMsgs,
              searchKeywords,
              setSearchKeywords,
              hasDeleteMsgPermission:
                userMsgPermission[MESSAGE_PERMISSION_ENTITY_TYPE.Message].delete,
              searchMode: chatToolbarMode === ChatToolbarMode.Seach,
              setSearchMode
            }}
          />
        }
        bordered={false}
        actions={[
          <ChatForm
            {...{
              isGroupMessageChatEntity,
              t,
              dispatch,
              chatEntity,
              replyingMessage,
              setReplyingMessage,
              listOfDriverVehicleWithMessagingDevices,
              hasSendMsgPermission: userMsgPermission[MESSAGE_PERMISSION_ENTITY_TYPE.Message].send
            }}
          />
        ]}
      >
        <ChatBubbles
          {...{
            t,
            localization,
            setReplyingMessage,
            replyingMessage,
            chatEntity,
            isGroupMessageChatEntity,
            flattenHistoricalMessages,
            selectMode: chatToolbarMode === ChatToolbarMode.Select,
            setSelectMode,
            selectedChatMsgs,
            setSelectedChatMsgs,
            searchKeywords,
            hasSendMsgPermission: userMsgPermission[MESSAGE_PERMISSION_ENTITY_TYPE.Message].send,
            searchMode: chatToolbarMode === ChatToolbarMode.Seach
          }}
        />
      </Card>
    </ChatBoxWrapper>
  );
};

export default MessagingChatBox;

const ChatAvatar = ({ name, tooltip, size, style }) => {
  const avt = (
    <Avatar
      style={{
        backgroundColor: 'gray',
        verticalAlign: 'middle',
        fontWeight: 500,
        ...(style || {})
      }}
      size={size || 'large'}
      gap={0}
    >
      {shortName(name)}
    </Avatar>
  );
  return tooltip ? (
    <Tooltip title={<div className={styles.chatAvatarTooltip}>{tooltip}</div>}>{avt}</Tooltip>
  ) : (
    avt
  );
};

const ChatToolbar = ({
  t,
  chatEntity,
  isGroupMessageChatEntity,
  localization,
  selectMode,
  setSelectMode,
  searchKeywords,
  setSearchKeywords,
  selectedChatMsgs,
  setSelectedChatMsgs,
  hasDeleteMsgPermission,
  searchMode,
  setSearchMode
}) => {
  const deleteActionType = {
    setDeleteMode: 'setDeleteMode',
    doDelete: 'doDelete',
    reset: 'reset'
  };

  const dispatch = useDispatch();

  const isDeleteSuccess = useIsDeleteMessagesSuccess();
  const isDeleteFailed = useIsDeleteMessagesFailed();
  const failedDeleteErrorStr = useDeleteMessageFailedError();

  const [deleteMode, dispatchDeleteMode] = useReducer(
    (state, action) => {
      if (action.type === deleteActionType.setDeleteMode) {
        return { mode: action.mode, doDelete: false };
      } else if (action.type === deleteActionType.doDelete) {
        return { ...state, doDelete: true };
      } else if (action.type === deleteActionType.reset) {
        return {
          mode: MESSAGE_DELETE_MODE.ALL,
          doDelete: false
        };
      }
      return state;
    },
    {
      mode: MESSAGE_DELETE_MODE.ALL,
      doDelete: false
    }
  );

  const printSelectedMessages = ({ url, blob }) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(setInitailDeleteMessagesStatus());
      dispatch(
        openToast({
          type: ToastType.Success,
          title: t('Messaging.Notifications.Deleted', { entity: t('Entity.Message') })
        })
      );
    } else if (isDeleteFailed) {
      dispatch(setInitailDeleteMessagesStatus());
      dispatch(
        openToast({
          type: ToastType.Error,
          title: t('Messaging.Notifications.DeleteFailed', {
            entity: t('Entity.Message'),
            error: failedDeleteErrorStr
          })
        })
      );
    }
  }, [isDeleteSuccess, isDeleteFailed, failedDeleteErrorStr, dispatch, t]);

  const selectingMultipleRecipients = useCallback(
    (isGroupMessageChatEntity, selectedChatMsgs) => {
      const onlyOneRecipient = selectedChatMsgs.every(msg =>
        msg.recipients?.every(
          recipient =>
            recipient.chatEntityType === chatEntity.chatEntityType &&
            recipient.chatEntityName === chatEntity.chatEntityName &&
            recipient.chatEntityId === chatEntity.chatEntityId
        )
      );
      return !isGroupMessageChatEntity && !onlyOneRecipient;
    },
    [chatEntity]
  );

  useEffect(() => {
    dispatchDeleteMode({
      type: 'setDeleteMode',
      mode: selectingMultipleRecipients(isGroupMessageChatEntity, selectedChatMsgs)
        ? MESSAGE_DELETE_MODE.SINGLE
        : MESSAGE_DELETE_MODE.ALL
    });
  }, [chatEntity, isGroupMessageChatEntity, selectedChatMsgs, selectingMultipleRecipients]);

  useEffect(() => {
    if (deleteMode.doDelete) {
      dispatch(deleteMessages(selectedChatMsgs, deleteMode.mode, chatEntity));
      dispatchDeleteMode({ type: deleteActionType.reset });
    }
  }, [deleteMode, deleteActionType.reset, selectedChatMsgs, chatEntity, dispatch]);

  const handleDeleteButton = e => {
    let modelContent = <></>;
    if (selectingMultipleRecipients(isGroupMessageChatEntity, selectedChatMsgs)) {
      modelContent = (
        <>
          <Radio.Group
            onChange={e => dispatchDeleteMode({ type: 'setDeleteMode', mode: e.target.value })}
            defaultValue={MESSAGE_DELETE_MODE.SINGLE}
          >
            <Radio value={MESSAGE_DELETE_MODE.SINGLE}>
              {t('Messaging.DeleteMessageModal.Delete Only', { name: chatEntity.chatEntityName })}
            </Radio>
            <Radio value={MESSAGE_DELETE_MODE.ALL}>
              {t('Messaging.DeleteMessageModal.Delete All')}
            </Radio>
          </Radio.Group>
        </>
      );
    }
    confirmationModal(
      `${t('Common.Modal.SureQuestion', { name: `${t('Messaging.SelectedMessages')}` })}`,
      modelContent,
      `${t('Common.Modal.Delete')}`,
      `${t('Common.Modal.Cancel')}`,
      () => dispatchDeleteMode({ type: 'doDelete' }),
      'delete',
      null,
      `${styles.messageDeletionModal} ${isGroupMessageChatEntity ? '' : styles.singleEntityChat}`
    );
  };

  return (
    <>
      <Space size={8} style={{ height: '32px' }}>
        {searchMode && (
          <>
            <AntSearchbar
              value={searchKeywords || ''}
              onFilter={value => {
                //prevent inputing special chars
                setSearchKeywords(value.replace(/[^\w\s]/, ''));
              }}
            />
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={e => {
                setSearchMode(false);
                setSearchKeywords(null);
              }}
            />
          </>
        )}
        {!searchMode && !selectMode && (
          <>
            <Tooltip title={t('Messaging.SearchContent')}>
              <Button
                type="text"
                icon={<SearchOutlined style={{ fontSize: '18px' }} />}
                onClick={e => {
                  setSearchMode(true);
                }}
                id={BUTTON_IDS.messagingChatBoxSearch}
              ></Button>
            </Tooltip>
            <Button
              type="text"
              icon={<i style={{ fontSize: '26px' }} className={'tn-i-elipsis'} />}
              onClick={e => {
                setSelectMode(true);
              }}
              id={BUTTON_IDS.messagingChatBoxSelect}
            />
          </>
        )}
        {selectMode && (
          <>
            <Tooltip title={t('Messaging.Print')}>
              {
                <PrintBtn
                  t={t}
                  chatEntity={chatEntity}
                  dateFormat={localization.formats.time.formats.dmY}
                  dateTimeFormat={localization.formats.time.formats.dmY_imsp}
                  messages={selectedChatMsgs}
                  icon={<PrinterFilled style={{ fontSize: '18px' }} />}
                  onPrint={printSelectedMessages}
                />
              }
            </Tooltip>
            <Tooltip
              title={hasDeleteMsgPermission ? t('Messaging.Delete') : t('Messaging.NoPermission')}
            >
              <Button
                disabled={!hasDeleteMsgPermission || selectedChatMsgs.length === 0}
                type="text"
                icon={<DeleteFilled style={{ fontSize: '18px' }} />}
                onClick={handleDeleteButton}
                id={BUTTON_IDS.messagingChatBoxDelete}
              ></Button>
            </Tooltip>
            <Button
              type="text"
              icon={<CloseOutlined style={{ fontSize: '18px' }} />}
              onClick={e => {
                setSelectMode(false);
                setSelectedChatMsgs([]);
              }}
              id={BUTTON_IDS.messagingChatBoxClose}
            />
          </>
        )}
      </Space>
    </>
  );
};

const ChatBubbles = ({
  t,
  localization,
  replyingMessage,
  setReplyingMessage,
  chatEntity,
  isGroupMessageChatEntity,
  flattenHistoricalMessages,
  selectMode,
  setSelectMode,
  selectedChatMsgs,
  setSelectedChatMsgs,
  searchKeywords,
  hasSendMsgPermission,
  searchMode
}) => {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 150
  });
  const chatMsgs = useMemo(() => {
    const cleanKeyWorkds = searchKeywords && searchKeywords.trim() ? searchKeywords.trim() : null;
    const messages = flattenHistoricalMessages || [];
    if (cleanKeyWorkds) {
      try {
        const reg = new RegExp(cleanKeyWorkds, 'gi');
        return messages.filter(msg => reg.test(msg.body) || reg.test(msg.subject));
      } catch (error) {
        return [];
      }
    }
    return messages;
  }, [searchKeywords, flattenHistoricalMessages]);

  useEffect(() => {
    setSelectedChatMsgs(prevSelectedMsgs => {
      if (!prevSelectedMsgs?.length) {
        return [];
      } else {
        return chatMsgs.filter(msg => {
          return prevSelectedMsgs.some(prevMsg => prevMsg.id === msg.id);
        });
      }
    });
    setReplyingMessage(prevReplyingMsg => {
      return (!!prevReplyingMsg && chatMsgs.find(msg => msg.id === prevReplyingMsg.id)) || null;
    });
  }, [chatMsgs, setSelectedChatMsgs, setReplyingMessage]);

  const onChatMsgChecked = (message, checked) => {
    if (selectedChatMsgs.some(selected => selected.id === message.id)) {
      if (!checked) {
        const _selectedChatMsgs = selectedChatMsgs.filter(selected => selected.id !== message.id);
        setSelectedChatMsgs(_selectedChatMsgs);
        if (_selectedChatMsgs.length === 0) {
          setSelectMode(false);
        }
      }
    } else {
      if (checked) {
        setSelectedChatMsgs([...selectedChatMsgs, message]);
      }
    }
  };

  const [listRef, setListRef] = useState(null);
  const timeoutRef = useRef();
  useEffect(() => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
    }
    const id = setTimeout(() => {
      if (searchMode) {
        if (!!searchKeywords) {
          listRef && listRef.scrollToRow(0);
        } else {
          listRef && listRef.scrollToRow(chatMsgs.length - 1);
        }
      } else if (!selectMode) {
        listRef && listRef.scrollToRow(chatMsgs.length - 1);
      }
    });
    timeoutRef.current = id;
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [chatMsgs, searchMode, searchKeywords, selectMode, listRef]);

  const [actionMenuVisibleRowIndex, setActionMenuVisibleRowIndex] = useState(null);

  const onClickMsg = rowIndex => e => {
    //setActionMenuVisibleRowIndex(rowIndex);
  };

  const handleOnActionMenuDisplay = useCallback((visible, index) => {
    if (visible) {
      setActionMenuVisibleRowIndex(index);
    } else {
      setActionMenuVisibleRowIndex(null);
    }
  }, []);

  const getMessageChatAvatarTooltip = useCallback(
    message => {
      const isWebSender = message.messageSender?.senderType === MESSAGE_SENDER_TYPE.WEB_DISPATCHER;
      if (!isWebSender && chatEntity.chatEntityType === RECIPIENT_TYPE.VEHICLE) {
        return (
          <>
            <p>{`${t('Entity.Vehicle')} : ${message.messageSender.senderName}`}</p>
            <p>{`${t('Vehicles.View.Driver')} : ${getChatMessageDriverName(message, chatEntity) ||
              t('Tracking.NotLoggedIn')}`}</p>
          </>
        );
      } else if (!isWebSender && chatEntity.chatEntityType === RECIPIENT_TYPE.DEVICE) {
        return (
          <>
            <p>{`${t('Entity.Device')} : ${message.messageSender.senderName}`}</p>
            <p>{`${t('Vehicles.View.Driver')} : ${getChatMessageDriverName(message, chatEntity) ||
              t('Tracking.NotLoggedIn')}`}</p>
          </>
        );
      }
      return `${message.messageSender.senderName}`;
    },
    [t, chatEntity]
  );

  const onLeaveSelecting = rowIndex => e => {
    setActionMenuVisibleRowIndex(null);
  };

  const rowRenderer = ({ index, key, style, parent }) => {
    const msg = chatMsgs[index];
    return (
      <CellMeasurer key={key} cache={cache} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style} onClick={onClickMsg(index)} onMouseLeave={onLeaveSelecting(index)}>
          <ChatBubble
            t={t}
            messageChatAvatarTooltip={getMessageChatAvatarTooltip(msg)}
            localization={localization}
            replyingMessage={replyingMessage}
            setReplyingMessage={setReplyingMessage}
            message={msg}
            isGroupMessageChatEntity={isGroupMessageChatEntity}
            selectMode={selectMode}
            setSelectMode={setSelectMode}
            keywords={searchKeywords}
            onCheck={checked => onChatMsgChecked(msg, checked)}
            checked={selectedChatMsgs.some(selected => selected.id === msg.id)}
            hasSendMsgPermission={hasSendMsgPermission}
            searchMode={searchMode}
            messageSentFailed={chatEntityMessageSentFailed(chatEntity, msg)}
            actionMenuVisible={actionMenuVisibleRowIndex === index}
            onActionMenuDisplay={visible => handleOnActionMenuDisplay(visible, index)}
            key={msg.id}
          />
        </div>
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          ref={setListRef}
          className={`${styles.messageChatBubbleList}`}
          rowCount={chatMsgs.length}
          width={width}
          height={height}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowRenderer={rowRenderer}
          overscanRowCount={3}
        />
      )}
    </AutoSizer>
  );
};

const ChatBubble = ({
  t,
  localization,
  message,
  messageChatAvatarTooltip,
  isGroupMessageChatEntity,
  selectMode,
  setSelectMode,
  keywords,
  onCheck,
  checked,
  replyingMessage,
  setReplyingMessage,
  hasSendMsgPermission,
  messageSentFailed,
  actionMenuVisible,
  onActionMenuDisplay
}) => {
  const unreadMessagesMeta = useUnreadMessagesMeta();
  const messageIsUnread = useMemo(() => {
    return (
      messageCanMarkAsRead(message) &&
      (!unreadMessagesMeta[message?.id] ||
        (!unreadMessagesMeta[message?.id]?.isMarkingAsRead &&
          !unreadMessagesMeta[message?.id]?.readAt &&
          !unreadMessagesMeta[message?.id]?.error))
    );
  }, [message, unreadMessagesMeta]);
  const messageSubjectCls = useMemo(
    () => `${styles.messageChatSubject} ${messageIsUnread ? styles.unreadChat : ''}`,
    [messageIsUnread]
  );

  const messageCanBeReply = useCallback(
    () =>
      !isRouteToMessage(message) &&
      !messageSentFailed &&
      hasSendMsgPermission &&
      message.messageSender?.senderType === MESSAGE_SENDER_TYPE.OTHER,
    [message, hasSendMsgPermission, messageSentFailed]
  );

  const messageSenderDisplay = useMemo(() => {
    //in group chat, a message send from device need to show the device/vehicle name
    return (
      isGroupMessageChatEntity && messageCanBeReply(message) && message.messageSender?.senderName
    );
  }, [isGroupMessageChatEntity, message, messageCanBeReply]);

  const messageRecipientDisplay = useMemo(() => {
    //in group chat, a message send to one recipient need to indicate which recipient is sent to
    return isGroupMessageChatEntity && message.recipients && message.recipients.length === 1
      ? message.recipients[0].chatEntityName
      : null;
  }, [isGroupMessageChatEntity, message]);

  const handleAction = e => {
    const { key } = e;
    if (key === ChatBubbleActions.SELECT) {
      setReplyingMessage(null);
      setSelectMode(true);
      onCheck(true);
    } else if (key === ChatBubbleActions.REPLY) {
      setReplyingMessage(message);
      setSelectMode(false);
    }
  };

  const bubbleLayout = useMemo(() => {
    const isWebSender = message.messageSender?.senderType === MESSAGE_SENDER_TYPE.WEB_DISPATCHER;
    return {
      chatAvatarLayout: {
        md: {
          span: 3,
          push: isWebSender ? (selectMode ? 20 : 21) : 0
        },
        lg: {
          span: 2,
          push: isWebSender ? (selectMode ? 20 : 21) : 0
        }
      },
      chatMessageBoxLayout: {
        md: {
          span: selectMode ? 17 : 18
        },
        lg: {
          span: selectMode ? 18 : 19
        }
      },
      popoverLayout: {
        placement: isWebSender ? 'leftTop' : 'rightTop'
      },
      chatClipClassName: isWebSender ? styles.messageChatFrom : styles.messageChatTo
    };
  }, [message, selectMode]);

  const menuItems = [
    {
      key: ChatBubbleActions.REPLY,
      disabled: !messageCanBeReply(message),
      label: (
        <Tooltip title={!hasSendMsgPermission ? t('Messaging.NoPermission') : null}>
          {t('Messaging.Reply')}
        </Tooltip>
      )
    },
    {
      key: ChatBubbleActions.SELECT,
      label: t('Common.Select')
    }
  ];

  return (
    <Row gutter={16} style={{ marginTop: '10px', marginLeft: '34px', marginBottom: '10px' }}>
      {selectMode && (
        <Col span={1}>
          <Checkbox onChange={e => onCheck(e.target.checked)} checked={checked}></Checkbox>
        </Col>
      )}
      {
        <Col {...bubbleLayout.chatAvatarLayout}>
          <ChatAvatar
            size={30}
            style={{ fontSize: '13px' }}
            name={`${message.messageSender.senderName}`}
            tooltip={messageChatAvatarTooltip}
          />
        </Col>
      }
      <Col {...bubbleLayout.chatMessageBoxLayout}>
        <Popover
          placement={bubbleLayout.popoverLayout.placement}
          content={
            <Menu onClick={handleAction} selectable={false} mode="vertical" items={menuItems} />
          }
          trigger="click"
          open={actionMenuVisible}
          onOpenChange={visible => onActionMenuDisplay(visible)}
        >
          <ChatMsgRow
            senderType={message.messageSender.senderType}
            replying={replyingMessage && replyingMessage.id === message.id}
            showAsGroupSeriesMessage={!isGroupMessageChatEntity && message.isGroupSeriesMessage}
            notificationType={message.notificationType}
          >
            <Col span={24}>
              {messageSenderDisplay && (
                <Row>
                  <p className={messageSubjectCls}>{`${t(
                    'Messaging.From'
                  )} : ${messageSenderDisplay}`}</p>
                </Row>
              )}
              {messageRecipientDisplay && (
                <Row>
                  <p className={messageSubjectCls}>{`${t(
                    'Messaging.Replying To'
                  )} : ${messageRecipientDisplay}`}</p>
                </Row>
              )}
              <Row>
                <p className={messageSubjectCls}>{getMessageSubject(t, message)}</p>
              </Row>
              <Row>{boldKeywords(keywords, getMessageBody(message))}</Row>
              <Row
                className={styles.messageChatMeta}
                justify={
                  getMsgReceiptStatus(message, t, localization.formats.time.formats.dmY_imsp)
                    ? 'space-between'
                    : 'end'
                }
              >
                {getMsgReceiptStatus(message, t, localization.formats.time.formats.dmY_imsp)}
              </Row>
              {messageSentFailed && (
                <Row className={styles.messageSentFailedInfo}>
                  {t('Messaging.MessageSentFailed')}
                </Row>
              )}
            </Col>
            <div className={`${bubbleLayout.chatClipClassName} messageChatClip`}></div>
          </ChatMsgRow>
        </Popover>
      </Col>
    </Row>
  );
};

const ChatForm = ({
  t,
  dispatch,
  chatEntity,
  isGroupMessageChatEntity,
  replyingMessage,
  setReplyingMessage,
  listOfDriverVehicleWithMessagingDevices,
  hasSendMsgPermission
}) => {
  const [form] = Form.useForm();
  const formIsInvalid = useCallback(() => {
    return (
      !form.getFieldValue('subject') ||
      !form.getFieldValue('body') ||
      form.getFieldsError(['subject', 'body']).some(f => !!f.errors.length)
    );
  }, [form]);
  const formInitialValue = {
    subject: '',
    body: '',
    requireAck: false
  };
  const [disableSubmit, setDisableSubmitBtn] = useState(formIsInvalid());
  const [formValue, setFormValue] = useState(formInitialValue);
  const [isFetchingDriverDevices, setIsFetchingDriverDevices] = useState(false);

  const timeoutRef = useRef();
  useEffect(() => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setDisableSubmitBtn(formIsInvalid());
    });
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [formValue, formIsInvalid]);

  const isChatting = useIsChatting();
  const isChatSuccess = useIsChattingSuccess();
  const isChatFailed = useIsChattingFailed();
  const failedErrorStr = useChattingFailedError();

  useEffect(() => {
    if (isChatSuccess) {
      dispatch(setInitailChattingStatus());
      dispatch(openToast({ type: ToastType.Success, title: t('Messaging.MessageSent') }));
      setReplyingMessage(null);
      form.resetFields();
      //reset form and disable submit button after sending successfully
      setDisableSubmitBtn(formIsInvalid());
    } else if (isChatFailed) {
      dispatch(setInitailChattingStatus());
      dispatch(
        openToast({
          type: ToastType.Error,
          title: t('Messaging.MessageSendFailed', { error: failedErrorStr })
        })
      );
    }
  }, [
    isChatSuccess,
    isChatFailed,
    form,
    setReplyingMessage,
    failedErrorStr,
    formIsInvalid,
    dispatch,
    t
  ]);

  useEffect(() => {
    if (replyingMessage) {
      form.setFieldsValue({ subject: t('Messaging.Re', { subject: replyingMessage.subject }) });
    } else {
      form.resetFields();
    }
  }, [replyingMessage, form, t]);

  const getDriverInteractiveDevices = async driverId => {
    //make sure get latest devicesStats with driver-device binding relationship
    const olderInteractiveDevices = listOfDriverVehicleWithMessagingDevices.drivers.find(
      driver => driver.id === driverId
    )?.interactiveDevices;
    try {
      setIsFetchingDriverDevices(true);
      const { devicesStats, devices } = await dispatch(getDriverLatestInteractiveDevices(driverId));
      setIsFetchingDriverDevices(false);
      return {
        interactiveDevices: devices?.length ? devices : olderInteractiveDevices,
        devicesStats
      };
    } catch (error) {
      setIsFetchingDriverDevices(false);
      return {
        interactiveDevices: olderInteractiveDevices,
        devicesStats: []
      };
    }
  };

  const handleSubmit = async values => {
    // console.log('handleSubmit:', chatEntity, values, replyingMessage);
    let events = [],
      parent,
      latestDevicesStats = [];
    if (replyingMessage) {
      parent = { id: replyingMessage.id };
      const eventType = MESSAGE_TYPE['MSG-REPLY'];
      //Check sender
      const replyingMsgSender = replyingMessage.messageSender;
      const hasReplyingMsgSenderInfo =
        replyingMsgSender && replyingMsgSender.senderEntity && replyingMsgSender.senderId;
      if (
        replyingMessage.sender ||
        (hasReplyingMsgSenderInfo && replyingMsgSender.senderEntity === RECIPIENT_TYPE.DRIVER)
      ) {
        const receiver = replyingMessage.sender || { id: replyingMsgSender.senderId };
        const { interactiveDevices, devicesStats } = await getDriverInteractiveDevices(receiver.id);
        latestDevicesStats = devicesStats;
        if (interactiveDevices && interactiveDevices.length) {
          interactiveDevices.forEach(device => {
            events.push({ eventType, receiver, device: { id: device.deviceId } });
          });
        } else {
          events.push({ eventType, receiver });
        }
      }
      if (
        replyingMessage.vehicle ||
        (hasReplyingMsgSenderInfo && replyingMsgSender.senderEntity === RECIPIENT_TYPE.VEHICLE)
      ) {
        const vehicle = replyingMessage.vehicle || { id: replyingMsgSender.senderId };
        const interactiveDevices = listOfDriverVehicleWithMessagingDevices.vehicles.find(
          driver => driver.id === vehicle.id
        )?.interactiveDevices;
        if (interactiveDevices && interactiveDevices.length) {
          interactiveDevices.forEach(device => {
            events.push({ eventType, vehicle, device: { id: device.id } });
          });
        } else {
          events.push({ eventType, vehicle });
        }
      }
      if (
        replyingMessage.device ||
        (hasReplyingMsgSenderInfo && replyingMsgSender.senderEntity === RECIPIENT_TYPE.DEVICE)
      ) {
        const device = replyingMessage.device || { id: replyingMsgSender.senderId };
        events.push({ eventType, device });
      }
    } else {
      const eventType = MESSAGE_TYPE['MSG-NEW'];
      if (chatEntity.chatEntityType === RECIPIENT_TYPE.DRIVER) {
        const receiver = { id: chatEntity.chatEntityId };
        const { interactiveDevices, devicesStats } = await getDriverInteractiveDevices(receiver.id);
        latestDevicesStats = devicesStats;
        if (interactiveDevices && interactiveDevices.length) {
          interactiveDevices.forEach(device => {
            events.push({ eventType, receiver, device: { id: device.deviceId } });
          });
        } else {
          events.push({ eventType, receiver });
        }
      } else if (chatEntity.chatEntityType === RECIPIENT_TYPE.VEHICLE) {
        const vehicle = { id: chatEntity.chatEntityId };
        const interactiveDevices = listOfDriverVehicleWithMessagingDevices.vehicles.find(
          driver => driver.id === vehicle.id
        )?.interactiveDevices;
        if (interactiveDevices && interactiveDevices.length) {
          interactiveDevices.forEach(device => {
            events.push({ eventType, vehicle, device: { id: device.id } });
          });
        } else {
          events.push({ eventType, vehicle });
        }
      } else if (chatEntity.chatEntityType === RECIPIENT_TYPE.DEVICE) {
        const device = { id: chatEntity.chatEntityId };
        events.push({ eventType, device });
      }
    }
    if (events.length) {
      dispatch(chat({ ...values, events, parent }, latestDevicesStats));
    }
  };

  const cancelReply = e => {
    setReplyingMessage(null);
  };
  return (
    <>
      {replyingMessage && (
        <Alert
          className={styles.messageChatReplyIndicateAlert}
          style={{ position: 'absolute', top: '-50px' }}
          message={
            <>
              <b>{t('Messaging.Replying To')}</b>
              {` : ${replyingMessage.subject}`}
            </>
          }
          type="info"
          closeText={
            <Tooltip title={`${t('Messaging.Cancel')} ${t('Messaging.Reply')}`}>
              {t('Messaging.Cancel')}
            </Tooltip>
          }
          onClose={cancelReply}
        />
      )}
      {(!isGroupMessageChatEntity || replyingMessage) && (
        <Form
          form={form}
          name="messaging-chat-form"
          onValuesChange={(changedValues, allValues) => {
            setFormValue(allValues);
          }}
          onFinish={handleSubmit}
          initialValues={formInitialValue}
        >
          <Row
            style={{
              width: '100%',
              paddingRight: '20px',
              paddingLeft: '20px',
              marginRight: 0,
              marginLeft: 0
            }}
            justify="space-between"
          >
            <Col span={22}>
              <Form.Item
                style={{ marginBottom: '5px', textAlign: 'left' }}
                name="subject"
                rules={[
                  { required: true, message: t('Messaging.Form.FormValidation.SubjectRequired') },
                  { max: 50, message: t('Messaging.Form.FormValidation.SubjectLength') }
                ]}
                hasFeedback
              >
                <Input placeholder={t('Messaging.Subject')} />
              </Form.Item>

              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                name="body"
                rules={[
                  { required: true, message: t('Messaging.Form.FormValidation.MessageRequired') },
                  { max: 1000, message: t('Messaging.Form.FormValidation.MessageLength') }
                ]}
                hasFeedback
              >
                <Input.TextArea
                  className={'showScrollbarsOnHover'}
                  placeholder={t('Messaging.Message')}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                name="requireAck"
                valuePropName="checked"
              >
                <Checkbox>
                  <span style={{ fontSize: '14px' }}>
                    {t('Messaging.MessageRequiresDriverAcknowledgement')}
                  </span>
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={2} style={{ alignSelf: 'flex-end', paddingLeft: 0, paddingRight: 0 }}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Tooltip
                  title={
                    hasSendMsgPermission
                      ? !!replyingMessage
                        ? t('Messaging.Reply')
                        : t('Messaging.Send')
                      : t('Messaging.NoPermission')
                  }
                >
                  <Button
                    loading={isChatting || isFetchingDriverDevices}
                    disabled={!hasSendMsgPermission || disableSubmit}
                    htmlType="submit"
                    style={{ borderWidth: 0, fontSize: '18px', background: 'transparent' }}
                    icon={<i className="tn-i-send" style={{ fontSize: '20px' }} />}
                    id={BUTTON_IDS.messagingChatBoxSubmit}
                  />
                </Tooltip>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

const getMsgReceiptStatus = (message, t, dateTimeFormat) => {
  const status = message.messageStatus;
  //const isDevice = message.chatEntity.recipientType === RECIPIENT_TYPE.DEVICE;
  const ret = [];
  const sent = status.find(statu => MESSAGE_STATUE.SENT === statu.status);
  if (sent) {
    ret.push(
      <p key={MESSAGE_STATUE.SENT}>{`${t(
        `Messaging.STATUS.${MESSAGE_STATUE.SENT}`
      )} : ${getMessageDisplayDate(t, sent.dateTime, dateTimeFormat)}`}</p>
    );
  }
  const received = status.find(statu => MESSAGE_STATUE.RECEIVED === statu.status);
  ret.push(
    <p key={MESSAGE_STATUE.RECEIVED} className={!received && styles.receivedColWidth}>
      {`${t(`Messaging.STATUS.${MESSAGE_STATUE.RECEIVED}`)} : `}
      {received ? getMessageDisplayDate(t, received.dateTime, dateTimeFormat) : ''}
    </p>
  );
  const read = status.find(statu => MESSAGE_STATUE.READ === statu.status);
  ret.push(
    <p key={MESSAGE_STATUE.READ} className={!read && styles.readColWidth}>
      {`${t(`Messaging.STATUS.${MESSAGE_STATUE.READ}`)} : `}
      {read ? getMessageDisplayDate(t, read.dateTime, dateTimeFormat) : ''}
    </p>
  );
  return ret.length ? ret : null;
};

const boldKeywords = (keywords, body) => {
  if (keywords && keywords.trim()) {
    let reg;
    try {
      reg = new RegExp(keywords, 'gi');
    } catch (error) {
      return <p className={styles.messageChatBody}>{body}</p>;
    }
    const fragments = [];
    let m;
    do {
      m = reg.exec(body);
      if (m) {
        let index = m.index;
        for (const c of m[0]) {
          fragments.push({
            index: index,
            word: c
          });
          index += 1;
        }
      }
    } while (m);
    let words = [],
      spanCollecting;
    for (let index = 0; index < body.length; index++) {
      const char = body[index];
      if (fragments.every(frag => frag.index !== index)) {
        if (index === 0) {
          spanCollecting = true;
          words.push({
            word: char
          });
        } else {
          if (spanCollecting) {
            words[words.length - 1].word = words[words.length - 1].word.concat(char);
          } else {
            spanCollecting = true;
            words.push({
              word: char
            });
          }
        }
      } else {
        if (index === 0) {
          spanCollecting = false;
          words.push({
            word: char,
            bold: true
          });
        } else {
          if (spanCollecting) {
            spanCollecting = false;
            words.push({
              word: char,
              bold: true
            });
          } else {
            words[words.length - 1].word = words[words.length - 1].word.concat(char);
          }
        }
      }
    }
    return (
      <p className={styles.messageChatBody}>
        {words.map((word, index) =>
          word.bold ? (
            <strong key={index}>{word.word}</strong>
          ) : (
            <span key={index}>{word.word}</span>
          )
        )}
      </p>
    );
  }
  return <p className={styles.messageChatBody}>{body}</p>;
};
