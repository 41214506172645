import React from 'react';

import { LoadingTable } from 'components/grid/LoadingTable';
import { Column, Table, AutoSizer } from 'react-virtualized';
import {
  cache,
  nameCellRenderer,
  fleetsCellRenderer,
  ellipsisButtonCellRenderer
} from './CellRenderers';
import { useTranslation } from 'react-i18next';
import { useCompanies } from 'features/company/companySlice';
import { useCanEveryEntity, entities } from 'features/permissions';

export const FormsTable = ({
  forms,
  company,
  isLoading,
  setTableRef,
  typeOfEntityToDelete,
  handleDeleteAction,
  fleets
}) => {
  const { t } = useTranslation();
  const companies = useCompanies();
  const canViewForms = useCanEveryEntity(entities.FORM_VIEW);
  if (isLoading) return <LoadingTable columnSizes={[73, 94, 57, 57, 94, 19]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 300,
          company: 150,
          questions: 200,
          version: 150,
          elipsisButton: 60
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.name +
            fixedColumnsWidth.company +
            fixedColumnsWidth.questions +
            fixedColumnsWidth.version +
            fixedColumnsWidth.elipsisButton);

        const columnStyle = {
          // padding: "12px 12px 12px 0"
        };
        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            headerStyle={{ paddingTop: '18px' }}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={forms.length}
            rowGetter={({ index }) => forms[index]}
            rowStyle={{ alignItems: 'center' }}
            ref={ref => setTableRef(ref)}
          >
            <Column
              style={columnStyle}
              label={t('Forms.Table.Name')}
              dataKey="Name"
              width={fixedColumnsWidth.name}
              cellRenderer={props => nameCellRenderer({ ...props, canViewForms })}
            />
            <Column
              style={columnStyle}
              label={t('Forms.Table.Company')}
              dataKey=""
              width={fixedColumnsWidth.company}
              cellDataGetter={({ rowData }) =>
                companies.find(c => c.id === parseInt(rowData.CompanyId, 10))?.name || company.name
              }
            />
            <Column
              style={columnStyle}
              label={t('Forms.Table.Version')}
              dataKey="Version"
              width={fixedColumnsWidth.version}
            />
            <Column
              style={columnStyle}
              label={t('Forms.Table.NumberOfQuestions')}
              width={fixedColumnsWidth.questions}
              cellDataGetter={({ rowData }) => rowData.Elements.length}
              dataKey="Elements"
            />
            <Column
              style={columnStyle}
              label={t('Forms.Table.Fleets')}
              dataKey="Id"
              width={dynamicColumnWidth}
              cellRenderer={props => fleetsCellRenderer({ ...props, fleets: fleets, companies, t })}
            />
            <Column
              label={t('Forms.Table.Actions')}
              dataKey=""
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.elipsisButton}
              cellRenderer={props =>
                ellipsisButtonCellRenderer({ ...props, typeOfEntityToDelete, handleDeleteAction })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
