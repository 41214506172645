import React, { createRef, useState, useEffect } from 'react';
import { Polygon } from 'react-google-maps';
import { DashedPolyline } from './DashedPolyline';
import { GEOFENCE_DEFAULT_COLORS } from 'features/geofences/geofencesUtil';

const DefaultMultipolygonOptions = {
  strokeOpacity: 0,
  strokeWeight: 2,
  fillColor: GEOFENCE_DEFAULT_COLORS.MANAGED,
  fillOpacity: 0.35
};

export const MultiPolygon = ({
  id,
  paths,
  selected,
  onClick,
  onMouseUp,
  editable,
  fillColor,
  dashedBorder = false
}) => {
  const polygonRef = createRef();
  /*
  const visiblePaths = useMemo(
    () =>
      paths.filter(path => {
        const geofenceBounds = new google.maps.LatLngBounds();
        path.forEach(point => geofenceBounds.extend(new google.maps.LatLng(point)));
        const geofenceVisibleOnCurrentMapBounds = mapBounds?.intersects(geofenceBounds);
        return geofenceVisibleOnCurrentMapBounds;
      }),
    [paths]
  );
*/
  const [options, setOptions] = useState(DefaultMultipolygonOptions);
  useEffect(() => {
    setOptions({
      ...DefaultMultipolygonOptions,
      fillColor: fillColor
    });
  }, [fillColor]);

  return (
    <>
      <Polygon
        ref={polygonRef}
        paths={paths}
        onClick={onClick}
        onMouseUp={onMouseUp}
        editable={editable}
        options={options}
      />
      {dashedBorder &&
        paths?.map((visiblePath, index) => (
          <DashedPolyline key={`polyline-${id}-${index}`} path={visiblePath} selected={selected} />
        ))}
    </>
  );
};
