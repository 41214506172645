/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Fleet from '../model/Fleet';

/**
* Fleets service.
* @module api/FleetsApi
* @version 1.0
*/
export default class FleetsApi {

    /**
    * Constructs a new FleetsApi.
    * @alias module:api/FleetsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the deleteFleet operation.
     * @callback module:api/FleetsApi~deleteFleetCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete a fleet
     * A token key is required for this call.
     * @param {String} id
     * @param {String} key API key for a company
     * @param {module:api/FleetsApi~deleteFleetCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteFleet(id, key, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteFleet");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling deleteFleet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/fleets/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the findById operation.
     * @callback module:api/FleetsApi~findByIdCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Fleet} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Look up fleet by ID
     * A token key is required for this call.
     * @param {Number} id
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {String} opts.embed What needs to be included in response, currently supports: vehicles, devices, geofences, devicestats, runsheets
     * @param {String} opts.deviceTypes Type of devices to be included
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {module:api/FleetsApi~findByIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Fleet}
     */
    findById(id, key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findById");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling findById");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'embed': opts['embed'],
        'device_types': opts['deviceTypes'],
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Fleet;
      return this.apiClient.callApi(
        '/fleets/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the insert operation.
     * @callback module:api/FleetsApi~insertCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Fleet} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add a new fleet
     * A token key is required for this call.
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {module:model/Fleet} opts.body
     * @param {module:api/FleetsApi~insertCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Fleet}
     */
    insert(key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling insert");
      }

      let pathParams = {
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Fleet;
      return this.apiClient.callApi(
        '/fleets', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the list operation.
     * @callback module:api/FleetsApi~listCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Fleet>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Lists fleets or look up by vehicle
     * A token key is required for this call.
     * @param {String} key API key for user/company
     * @param {Object} opts Optional parameters
     * @param {Number} opts.vehicleId Vehicle Id
     * @param {String} opts.embed What needs to be included in response
     * @param {String} opts.deviceTypes Type of devices to be included
     * @param {String} opts.mode Mode of query
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {module:api/FleetsApi~listCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Fleet>}
     */
    list(key, opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'vehicle_id': opts['vehicleId'],
        'embed': opts['embed'],
        'device_types': opts['deviceTypes'],
        'mode': opts['mode'],
        'pruning': opts['pruning'],
        'key': key,
        'company_id': opts['companyId'],
        'direction': opts['direction']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Fleet];
      return this.apiClient.callApi(
        '/fleets', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the merge operation.
     * @callback module:api/FleetsApi~mergeCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {module:model/Fleet} opts.body
     * @param {module:api/FleetsApi~mergeCallback} callback The callback function, accepting three arguments: error, data, response
     */
    merge(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'pruning': opts['pruning']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/fleets/merge', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the update operation.
     * @callback module:api/FleetsApi~updateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Fleet} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a fleet
     * A token key is required for this call.
     * @param {String} id
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {module:model/Fleet} opts.body
     * @param {module:api/FleetsApi~updateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Fleet}
     */
    update(id, key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling update");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling update");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Fleet;
      return this.apiClient.callApi(
        '/fleets/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
