import { asyncThunk } from 'utils/api';

import { FETCH_VEHICLE_MODELS, ADD_VEHICLE_MODEL } from './actions';

export const VEHICLE_MODELS_URL = '/vehiclemodels';
export const fetchVehicleModels = asyncThunk(FETCH_VEHICLE_MODELS, VEHICLE_MODELS_URL);
export const addVehicleModel = asyncThunk(
  ADD_VEHICLE_MODEL,
  VEHICLE_MODELS_URL,
  'post',
  fetchVehicleModels
);
