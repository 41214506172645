import React from 'react';
import { Spin, Empty } from 'antd';

export const LoadingSpinner = ({ isLoading = true, length }) => {
  return !length ? (
    isLoading ? (
      <div
        style={{
          margin: '20px 0',
          padding: '30px 50px',
          textAlign: 'center',
          background: 'transparent'
        }}
      >
        <Spin />
      </div>
    ) : (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    )
  ) : null;
};
