import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const initialState = {
  user: {
    id: 0,
    email: '',
    companyId: 0,
    firstName: '',
    lastName: '',
    username: ''
  }
};

export const sentinelUserSlice = createSlice({
  name: 'sentinelUser',
  initialState: initialState,
  reducers: {
    setUser(state, action) {
      const user = action.payload;
      state.user = user;
    },
    clearUser(state, action) {
      state.user = {};
    }
  }
});

export const { setUser, clearUser } = sentinelUserSlice.actions;

export default sentinelUserSlice.reducer;

export const useDriverTimezone = () => {
  const driverTimezone = useSelector(state => state.sentinel.user.timeZone);
  return driverTimezone ? driverTimezone : 'UTC';
};
