import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout, Menu, Tooltip } from 'antd';
import { matchPath } from 'react-router';
import { useLocation, useHistory } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import cn from 'classnames';

import { MENU_IDS } from 'utils/globalConstants';

import {
  services,
  entities,
  GlobalRoles,
  FeatureFlag,
  useCan,
  useCanOneOfEntities,
  useCanOneOfServices,
  useCanOneOfRoles,
  useIQCameraUser
} from 'features/permissions';
import { useUserInfo } from 'features/user/userSlice';
import { useActiveBrand } from 'features/brands/useActiveBrand';
import {
  usePages,
  administrationPages,
  configurationPages,
  userPages,
  supportToolPagesFeatures
} from 'Pages';

import { useSSOGuard, SSO_GUARD_FEATURE_CONFIG } from 'containers/SSO/ssoHook';

import { Icon } from './Icon';
import logoTNSm from 'components/tn/assets/images/layout/nav/logo-collapsed.svg';
import logoTNLg from 'components/tn/assets/images/layout/nav/logo.svg';

import styles from './nav.module.scss';
import { useCustomEventListener, CustomEvents } from 'utils/hooks/useCustomEventListener';
import { useIsFetchingCurrentCompanyServices } from 'features/company/companySlice';

const { Sider } = Layout;

export const LeftNav = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const user = useUserInfo();
  const location = useLocation();
  const can = useCan();
  const isFetchingCompanyServices = useIsFetchingCurrentCompanyServices();

  const hasSiteAdminOrResellerRole = useCanOneOfRoles([
    GlobalRoles.Reseller,
    GlobalRoles.SiteAdmin
  ]);

  const canAdministration = useCanOneOfEntities([
    entities.USER,
    entities.ROLE,
    entities.FLEET,
    entities.VEHICLE,
    entities.DEVICE,
    entities.LOCATION,
    entities.GEOFENCE,
    entities.SUBCOMPANY,
    entities.WIFI
  ]);
  const canConfiguration = useCanOneOfEntities([
    entities.COMPANYALERT,
    entities.FORM,
    entities.PRETRIPCHECKLIST,
    entities.DRIVERMANAGEMENTTYPE,
    entities.VEHICLEMAINTENANCETYPE,
    entities.VEHICLETYPE,
    entities.VEHICLE,
    entities.SMARTJOBSCODE,
    entities.COMPANY
  ]);
  const canUser = useCanOneOfServices([services.COMMON]);
  const canSupportTools = can({
    otherConditions: [() => user?.siteAdmin]
  });
  const canAdminRole = useCanOneOfRoles([
    GlobalRoles.Admin,
    GlobalRoles.IQCameraAdministrator,
    GlobalRoles.SubCompanyAdmin
  ]);
  const { canAccessNonCameraFeatures } = useIQCameraUser();
  const canBulkImport = useCanOneOfEntities([entities.BULKIMPORT]);
  const canResellerRole = useCanOneOfRoles([GlobalRoles.Reseller]);
  const canCompanyConfig = canAdminRole || canBulkImport || canResellerRole;
  const { ssoUnAvailable, needGuard, runGuard } = useSSOGuard();
  const pages = usePages();
  const brand = useActiveBrand();

  const subMenuPagesMap = {
    [MENU_IDS.admin]: administrationPages(t, canAccessNonCameraFeatures),
    [MENU_IDS.config]: configurationPages(t, user, canCompanyConfig, canAccessNonCameraFeatures),
    [MENU_IDS.user]: userPages(t, canAccessNonCameraFeatures),
    [MENU_IDS.supportTools]: canSupportTools ? supportToolPagesFeatures(t) : null
  };

  const [isMainNavPinned, setIsMainNavPinned] = useState(true);
  const [isMainNavExpanded, setIsMainNavExpanded] = useState(true);
  const [isSubNavExpanded, setIsSubNavExpanded] = useState(false);
  const [isSupportToolsExpanded, setIsSupportToolsExpanded] = useState(false);

  const [subMenuOpenKeys, setSubMenuOpenKeys] = useState([]);
  const [currentMainMenuItem, setCurrentMainMenuItem] = useState();
  const [currentExternalMenuItem, setCurrentExternalMenuItem] = useState();
  const [currentSettingsMenuItem, setCurrentSettingsMenuItem] = useState();
  const [currentSubMenuItem, setCurrentSubMenuItem] = useState();

  const [currentSupportToolsMenuItem, setCurrentSupportToolsMenuItem] = useState();
  const [currentSupportToolsSubMenuItem, setCurrentSupportToolsSubMenuItem] = useState();

  const isDriverPortal = can({
    everyCompanyService: [services.ELD],
    featureFlag: [FeatureFlag.driverPortal.flag],
    everyEntity: [entities.DRIVERPORTAL],
    otherConditions: [() => user?.type?.code === 'DRIVER']
  });
  const canAccessTripManager =
    user?.type?.code !== 'DRIVER' ||
    (user?.type?.code === 'DRIVER' &&
      can({ featureFlag: FeatureFlag.driverPortalTripManager.flag }))
      ? true
      : false;

  const closeSidebar = useCallback(() => {
    setIsMainNavPinned(false);
    setIsMainNavExpanded(false);
  }, []);

  useCustomEventListener(CustomEvents.autoCloseSidebar, closeSidebar);

  const handleMenuItemClick = useCallback(
    item => {
      const isItemWithSSOGuard = item['data-ssoguardfeature'],
        itemURL =
          item?.['data-link'] && item?.['data-link'] !== 'undefined' ? item?.['data-link'] : null;
      if (isItemWithSSOGuard) {
        if (!ssoUnAvailable) {
          if (needGuard) {
            runGuard();
          } else {
            itemURL && history.push(itemURL);
          }
        }
      } else {
        if (item?.['key'] && item?.['key'] === 'menu_fatigueDriverPortal') {
          const dpURL = itemURL + '/' + user?.id;
          dpURL && history.push(dpURL);
        } else {
          itemURL && history.push(itemURL);
        }
      }
    },
    [history, runGuard, needGuard, ssoUnAvailable, user]
  );

  useEffect(() => {
    if (user?.type?.code === 'DRIVER') {
      const driverPortalPages = pages.filter(
        page =>
          ((isDriverPortal && page.id === MENU_IDS.fatigueDriverPortal) ||
            (canAccessTripManager && page.id === MENU_IDS.fbtmanager)) &&
          page.navPosition === 'top' &&
          !page.isExternalLink &&
          can({ ...page })
      );

      if (
        driverPortalPages.findIndex(p => p.id === MENU_IDS.fatigueDriverPortal) > -1 &&
        location.pathname.startsWith('/fatigue/eld')
      ) {
        setCurrentMainMenuItem(MENU_IDS.fatigueDriverPortal);
      } else if (
        driverPortalPages.findIndex(p => p.id === MENU_IDS.fbtmanager) > -1 &&
        location.pathname.startsWith('/fbtmanager')
      ) {
        setCurrentMainMenuItem(MENU_IDS.fbtmanager);
      }
    } else {
      setCurrentMainMenuItem(null);
      setIsSubNavExpanded(location.pathname.startsWith('/settings'));
      setIsSupportToolsExpanded(location.pathname.startsWith('/supportTools'));
      setSubMenuOpenKeys([
        ...(isParentMenuItemOpen(MENU_IDS.admin) ? [MENU_IDS.admin] : []),
        ...(isParentMenuItemOpen(MENU_IDS.config) ? [MENU_IDS.config] : []),
        ...(isParentMenuItemOpen(MENU_IDS.user) ? [MENU_IDS.user] : []),
        ...(isParentMenuItemOpen(MENU_IDS.supportTools) ? [MENU_IDS.supportTools] : [])
      ]);

      // When navigating between pages and subpages without using the nav or subnav
      const currentSubMenuId = currentSubMenuItem?.split('_')[1];
      if (currentSubMenuId && !location.pathname.includes(currentSubMenuId)) {
        const newSubMenuItemId = location.pathname.split('/')[2];
        setCurrentSubMenuItem(`menu_${newSubMenuItemId}`);
      }
    }
  }, [location.pathname, isDriverPortal, canAccessTripManager, user]);

  const isSsoUnavailable = page => {
    return !!page.ssoGuardFeature && ssoUnAvailable;
  };

  const ssoUnavailableTooltip = page => {
    return SSO_GUARD_FEATURE_CONFIG[page.title]?.ssoUnavailableText(t);
  };

  const TooltipWrapper = ({ conditional, title, children, props }) => {
    return conditional ? (
      <Tooltip title={title} {...props}>
        {children}
      </Tooltip>
    ) : (
      <>{children}</>
    );
  };

  const MenuItemLabel = ({ page }) => {
    // console.debug('LeftNav - MenuItemLabel', { menuItem: page.title, page, isSsoRequired: isSsoUnavailable(page) });

    return (
      <>
        <TooltipWrapper conditional={isSsoUnavailable(page)} title={ssoUnavailableTooltip(page)}>
          <span style={{ display: 'inline-flex', width: '100%' }}>
            {page.titleComponent ? <page.titleComponent /> : page.title}
          </span>
        </TooltipWrapper>

        {page.isExternalLink && <Icon id="external-link" />}
        {page.isAdminFeature && <Icon id="admin-feature" />}
        {page.id === MENU_IDS.settings &&
          (isSubNavExpanded ? (
            <LeftOutlined className="ant-menu-item-icon" />
          ) : (
            <RightOutlined className="ant-menu-item-icon" />
          ))}
        {page.id === MENU_IDS.supportTools &&
          (isSupportToolsExpanded ? (
            <LeftOutlined className="ant-menu-item-icon" />
          ) : (
            <RightOutlined className="ant-menu-item-icon" />
          ))}
      </>
    );
  };

  const MenuItemIcon = ({ page }) => {
    return page.iconSvg || page.iconClassName || page.icon ? (
      <TooltipWrapper conditional={isSsoUnavailable(page)} title={ssoUnavailableTooltip(page)}>
        {page.iconSvg ? (
          <Icon id={page.iconSvg} />
        ) : page.iconClassName ? (
          <i className={cn(page.iconClassName, 'ant-menu-item-icon')} />
        ) : (
          <FontAwesomeIcon className={'ant-menu-item-icon'} icon={page.icon} />
        )}
      </TooltipWrapper>
    ) : null;
  };

  const pageToMenuItem = page => {
    return {
      key: page.id,
      id: page.id,
      className: matchPath(location.pathname, {
        path: page.path,
        exact: !!page.exact
      })
        ? 'ant-menu-item-selected'
        : '',
      disabled: isSsoUnavailable(page),
      icon: <MenuItemIcon page={page} />,
      label: <MenuItemLabel page={page} />,
      'data-link': page.link,
      'data-ssoguardfeature': page.ssoGuardFeature,
      onClick: !!page.linkGenerator
        ? async e => {
            const url = await page.linkGenerator();
            url && window.open(url, '_blank');
            e.domEvent.stopPropagation();
          }
        : null
    };
  };

  const isParentMenuItemOpen = useCallback(
    subMenuId =>
      subMenuPagesMap[subMenuId]?.reduce(
        (accumulator, current) =>
          accumulator ||
          matchPath(location.pathname, {
            path: current.path,
            exact: !!current.exact
          }),
        false
      ),
    [administrationPages, supportToolPagesFeatures, location]
  );

  const mainMenuItems = useMemo(() => {
    if (user?.type?.code === 'DRIVER') {
      const driverPortalPages = pages
        .filter(
          page =>
            ((isDriverPortal && page.id === MENU_IDS.fatigueDriverPortal) ||
              (canAccessTripManager && page.id === MENU_IDS.fbtmanager)) &&
            page.navPosition === 'top' &&
            !page.isExternalLink &&
            can({ ...page })
        )
        .map(page => pageToMenuItem(page));

      return driverPortalPages;
    } else {
      const visiblePages = pages
        .filter(page => page.navPosition === 'top' && !page.isExternalLink && can({ ...page }))
        .map(page => pageToMenuItem(page));

      return visiblePages;
    }
  }, [pages, user, isDriverPortal, canAccessTripManager]);

  const externalMenuItems = useMemo(
    () =>
      pages
        .filter(page => page.isExternalLink && page.navPosition !== 'bottom' && can({ ...page }))
        .map(page => pageToMenuItem(page)),
    [pages]
  );

  const settingsMenuItems = useMemo(
    () =>
      pages
        .filter(page => page.navPosition === 'bottom' && can({ ...page }))
        .map(page => pageToMenuItem(page)),
    [pages]
  );

  const supportToolsSubMenuItems = useMemo(() => {
    const supportToolsELDItems = supportToolPagesFeatures(t, user, hasSiteAdminOrResellerRole)
      .filter(page => can({ ...page }))
      .map(page => pageToMenuItem(page));
    return [
      ...[
        canSupportTools && {
          key: MENU_IDS.supportTools,
          id: MENU_IDS.supportTools,
          label: t('SupportToolsELD.Features'),
          children: supportToolsELDItems
        }
      ]
    ];
  });

  const subMenuItems = useMemo(() => {
    const adminMenuItems = administrationPages(t, canAccessNonCameraFeatures)
      .filter(page => can({ ...page }))
      .map(page => pageToMenuItem(page));
    const configMenuItems = configurationPages(
      t,
      user,
      canCompanyConfig,
      canAccessNonCameraFeatures
    )
      .filter(page => can({ ...page }))
      .map(page => pageToMenuItem(page));
    const userMenuItems = userPages(t, canAccessNonCameraFeatures)
      .filter(page => can({ ...page }))
      .map(page => pageToMenuItem(page));
    return [
      ...[
        canAdministration && {
          key: MENU_IDS.admin,
          id: MENU_IDS.admin,
          label: t('Common.Administration'),
          children: adminMenuItems
        }
      ],
      ...[
        (canConfiguration || canCompanyConfig) &&
          configMenuItems.length && {
            key: MENU_IDS.config,
            id: MENU_IDS.config,
            label: t('Common.Configuration'),
            children: configMenuItems
          }
      ],
      ...[
        canUser && {
          key: MENU_IDS.user,
          id: MENU_IDS.user,
          label: t('Common.User'),
          children: userMenuItems
        }
      ]
    ];
  }, [
    administrationPages,
    configurationPages,
    userPages,
    supportToolPagesFeatures,
    location,
    t,
    isFetchingCompanyServices
  ]);

  const clearActiveMenuItems = () => {
    const selector = `.${styles.mainNav} li`;
    const menuItems = document.querySelectorAll(selector);

    Object.values(menuItems).forEach(li => li.classList.remove('ant-menu-item-active'));
  };

  const getMenuIem = useCallback((keyPath = [], menuItems = []) => {
    if (!keyPath?.length || !menuItems.length) {
      return;
    }
    const getItem = (itemKey, items = []) => items.find(item => item.key === itemKey);
    let keyIndex = keyPath.length - 1,
      matchedItem = getItem(keyPath[keyPath.length - 1], menuItems);
    while (keyIndex > 0 && matchedItem) {
      keyIndex--;
      matchedItem = getItem(keyPath[keyIndex], matchedItem.children || []);
    }
    return {
      item: matchedItem,
      itemURL:
        matchedItem?.['data-link'] && matchedItem?.['data-link'] !== 'undefined'
          ? matchedItem?.['data-link']
          : null
    };
  }, []);

  return (
    <>
      {/* main nav */}
      <Sider
        theme="dark"
        id="leftMainNav"
        trigger={null}
        className={cn(styles.leftNav, styles.mainNav)}
        collapsed={!isMainNavExpanded}
        onCollapse={value => setIsMainNavExpanded(!value)}
        onMouseEnter={e => {
          !isMainNavExpanded && setIsMainNavExpanded(true);
        }}
        onMouseLeave={e => {
          !isMainNavPinned && isMainNavExpanded && setIsMainNavExpanded(false);
        }}
      >
        {/* logo */}
        <div
          className={cn(
            styles.logoWrapper,
            { [styles.pinned]: isMainNavPinned },
            { [styles.expanded]: isMainNavExpanded }
          )}
          onClick={e => {
            setIsMainNavPinned(!isMainNavPinned);
          }}
        >
          <div
            className={styles.logo}
            style={{
              ...(!isMainNavExpanded && {
                paddingLeft: `${brand?.logos?.smLeftNavLogo?.paddingLeft || '8'}px`
              })
            }}
          >
            <img
              src={brand?.logos?.sm || logoTNSm}
              alt={brand?.product || 'TN360'}
              style={{
                opacity: isMainNavExpanded ? 0 : 1,
                width: isMainNavExpanded ? 0 : '100%'
              }}
            />
            <img
              src={brand?.logos?.lg || logoTNLg}
              alt={brand?.product || 'TN360'}
              style={{
                opacity: isMainNavExpanded ? 1 : 0,
                width: isMainNavExpanded ? '100%' : 0
              }}
            />
          </div>
          {isMainNavExpanded && (
            <Tooltip title={isMainNavPinned ? t('Nav.UnpinMenu') : t('Nav.PinMenu')}>
              {isMainNavPinned ? (
                <Icon
                  id="pin-pinned"
                  className={cn(styles.logoPin, { [styles.expanded]: isMainNavExpanded })}
                />
              ) : (
                <Icon
                  id="pin-unpinned"
                  className={cn(styles.logoPin, { [styles.expanded]: isMainNavExpanded })}
                />
              )}
            </Tooltip>
          )}
        </div>

        {/* main menu */}
        <Menu
          theme="dark"
          mode="inline"
          id="leftNavMain"
          items={mainMenuItems}
          selectedKeys={[currentMainMenuItem]}
          onSelect={({ key, keyPath }) => {
            const { item } = getMenuIem(keyPath, mainMenuItems);
            handleMenuItemClick(item);
            setCurrentMainMenuItem(key);
            setCurrentExternalMenuItem(null);
            setCurrentSettingsMenuItem(null);
            setIsSubNavExpanded(false);
            setIsSupportToolsExpanded(false);
          }}
        />

        {/* external menu */}
        <Menu
          theme="dark"
          mode="inline"
          id="leftNavExternal"
          items={externalMenuItems}
          selectedKeys={[currentExternalMenuItem]}
          onSelect={({ keyPath }) => {
            const { itemURL } = getMenuIem(keyPath, externalMenuItems);
            if (itemURL) {
              itemURL.startsWith('http') ? window.open(itemURL, '_blank') : history.push(itemURL);
            }
          }}
        />

        {/* settings menu */}
        <Menu
          theme="dark"
          mode="inline"
          id="leftNavSettings"
          items={settingsMenuItems}
          selectedKeys={[currentSettingsMenuItem, currentSupportToolsMenuItem]}
          onClick={({ key, keyPath }) => {
            const { itemURL } = getMenuIem(keyPath, settingsMenuItems);
            if (itemURL) {
              itemURL.startsWith('http')
                ? window.open(itemURL, '_blank')
                : (!location.pathname.startsWith('/settings') ||
                    !location.pathname.startsWith('/supportTools')) &&
                  history.push(itemURL);
            }

            if (key === MENU_IDS.settings) {
              setCurrentSettingsMenuItem(key);
              setCurrentSupportToolsMenuItem(null);
              setCurrentSupportToolsSubMenuItem(null);
              setCurrentMainMenuItem(null);
              setCurrentExternalMenuItem(null);
              setCurrentSubMenuItem(null);
              setIsSubNavExpanded(!isSubNavExpanded);
              setIsSupportToolsExpanded(false);
            }

            if (key === MENU_IDS.supportTools) {
              setCurrentSettingsMenuItem(null);
              setCurrentSupportToolsMenuItem(key);
              setCurrentSupportToolsSubMenuItem(null);
              setCurrentMainMenuItem(null);
              setCurrentExternalMenuItem(null);
              setCurrentSubMenuItem(null);
              setIsSubNavExpanded(false);
              setIsSupportToolsExpanded(!isSupportToolsExpanded);
            }
          }}
        />
      </Sider>

      {/* sub nav */}
      <Sider
        theme="light"
        trigger={null}
        className={[styles.leftNav, styles.subNav].join(' ')}
        collapsed={!isSubNavExpanded}
        onCollapse={value => setIsSubNavExpanded(!value)}
      >
        <Menu
          theme="light"
          mode="inline"
          id="leftSubNav"
          items={subMenuItems}
          selectedKeys={[currentSubMenuItem]}
          openKeys={isSubNavExpanded && subMenuOpenKeys}
          onOpenChange={openKeys => {
            openKeys?.length && setSubMenuOpenKeys(openKeys);
          }}
          onClick={({ key, keyPath }) => {
            const { itemURL } = getMenuIem(keyPath, subMenuItems);
            itemURL && history.push(itemURL);
            setCurrentSubMenuItem(key);
          }}
        />
      </Sider>

      {/* support tools sub nav */}
      <Sider
        theme="light"
        trigger={null}
        className={[styles.leftNav, styles.subNav].join(' ')}
        collapsed={!isSupportToolsExpanded}
        onCollapse={value => setIsSupportToolsExpanded(!value)}
      >
        <Menu
          theme="light"
          mode="inline"
          id="leftSubNav"
          items={supportToolsSubMenuItems}
          selectedKeys={[currentSupportToolsSubMenuItem]}
          openKeys={isSupportToolsExpanded && subMenuOpenKeys}
          onOpenChange={openKeys => {
            openKeys?.length && setSubMenuOpenKeys(openKeys);
          }}
          onClick={({ key, keyPath }) => {
            const { itemURL } = getMenuIem(keyPath, supportToolsSubMenuItems);
            itemURL && history.push(itemURL);
            setCurrentSupportToolsMenuItem(key);
          }}
        />
      </Sider>
    </>
  );
};
