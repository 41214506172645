import * as Yup from 'yup';
import i18n from 'i18next';
import { UNITS } from 'features/localization/localization';
import { deviceCapabilities } from 'features/permissions/deviceFeaturesByFirmware';

export const VehicleTypesLens = {
  All: 'VehicleTypes.AllFilter',
  Deleted: 'VehicleTypes.DeletedFilter'
};

export const DELETED_VEHICLES_TYPES_PATH = '/settings/vehicleTypes/deleted';

const getAccelerationLimit = unit => {
  switch (unit) {
    case UNITS.FT_SQUARE:
      return 30;
    case UNITS.G_FORCE:
      return 1;
    case UNITS.M_SQUARE:
    default:
      return 9;
  }
};

const getImpactLimit = unit => {
  switch (unit) {
    case UNITS.FT_SQUARE:
      return {
        min: 16.1,
        max: 215.0
      };
    case UNITS.G_FORCE:
      return {
        min: 0.5,
        max: 6.68
      };
    case UNITS.M_SQUARE:
      return {
        min: 4.9,
        max: 65.6
      };
    default:
      return {
        min: 4.9,
        max: 65.6
      };
  }
};

export const validationSchema = localization => () => {
  const lowerLimit = 0;
  const accelerationLimit = getAccelerationLimit(localization.formats.acceleration.unit);
  const temperatureLimit =
    localization.formats.temperature.unit === UNITS.TEMPERATURE_CELSIUS ? 200 : 400;
  const pressureLimit = localization.formats.pressure.oil === UNITS.PRESSURE_KPA ? 500 : 73;
  const impactLimit = getImpactLimit(localization.formats.acceleration.unit);

  return Yup.object().shape({
    name: Yup.string()
      .required(i18n.t('VehicleTypes.FormValidation.NameRequired'))
      .matches(/\S/, i18n.t('VehicleTypes.FormValidation.NameRequired'))
      .max(50, i18n.t('VehicleTypes.FormValidation.NameMax')),
    harshAcceleration: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .positive(i18n.t('VehicleTypes.FormValidation.PositiveValue'))
      .max(
        accelerationLimit,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${lowerLimit}-${accelerationLimit}`,
          unit: localization.formats.acceleration.unit
        })
      ),
    harshBrake: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .negative(i18n.t('VehicleTypes.FormValidation.NegativeValue'))
      .min(
        -accelerationLimit,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `-${accelerationLimit}-${lowerLimit}`,
          unit: localization.formats.acceleration.unit
        })
      ),
    harshCornering: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .positive(i18n.t('VehicleTypes.FormValidation.PositiveValue'))
      .max(
        accelerationLimit,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${lowerLimit}-${accelerationLimit}`,
          unit: localization.formats.acceleration.unit
        })
      ),
    harshCorneringLeft: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .positive(i18n.t('VehicleTypes.FormValidation.PositiveValue'))
      .max(
        accelerationLimit,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${lowerLimit}-${accelerationLimit}`,
          unit: localization.formats.acceleration.unit
        })
      ),
    harshCorneringRight: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .positive(i18n.t('VehicleTypes.FormValidation.PositiveValue'))
      .max(
        accelerationLimit,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${lowerLimit}-${accelerationLimit}`,
          unit: localization.formats.acceleration.unit
        })
      ),
    impact: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .positive(i18n.t('VehicleTypes.FormValidation.PositiveValue'))
      .min(
        impactLimit.min,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${impactLimit.min}-${impactLimit.max}`,
          unit: localization.formats.acceleration.unit
        })
      )
      .max(
        impactLimit.max,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${impactLimit.min}-${impactLimit.max}`,
          unit: localization.formats.acceleration.unit
        })
      ),
    overRev: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .positive(i18n.t('VehicleTypes.FormValidation.PositiveValue'))
      .max(
        20000,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${lowerLimit}-20000`,
          unit: `RPM`
        })
      ),
    idleTime: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .positive(i18n.t('VehicleTypes.FormValidation.PositiveValue'))
      .max(
        1440,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${lowerLimit}-1440`,
          unit: `minutes`
        })
      ),
    oilOverTemp: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .positive(i18n.t('VehicleTypes.FormValidation.PositiveValue'))
      .max(
        temperatureLimit,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${lowerLimit}-${temperatureLimit}`,
          unit: localization.formats.temperature.unit
        })
      ),
    coolantOverTemp: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .positive(i18n.t('VehicleTypes.FormValidation.PositiveValue'))
      .max(
        temperatureLimit,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${lowerLimit}-${temperatureLimit}`,
          unit: localization.formats.temperature.unit
        })
      ),
    oilPressure: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .positive(i18n.t('VehicleTypes.FormValidation.PositiveValue'))
      .max(
        pressureLimit,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${lowerLimit}-${pressureLimit}`,
          unit: localization.formats.pressure.oil
        })
      ),
    companyId: Yup.string().required(i18n.t('VehicleTypes.FormValidation.CompanyRequired')),
    code: Yup.string()
      .required(i18n.t('VehicleTypes.FormValidation.CodeRequired'))
      .matches(/^[A-Z0-9_]*$/, i18n.t('VehicleTypes.FormValidation.CodeValidation')),
    [FuelFieldKey.FuelType]: Yup.string()
      .notRequired()
      .oneOf([...Object.values(FUEL_TYPE).map(type => type.value)], null, ''),
    [FuelFieldKey.AVRFuelEfficiency]: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .positive(i18n.t('VehicleTypes.FormValidation.PositiveValue'))
      .max(
        999,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${0}-${999}`,
          unit: localization.formats.fuel.unit_avr_efficiency
        })
      ),
    [FuelFieldKey.Co2EmissionsFactor]: Yup.number()
      .typeError(i18n.t('VehicleTypes.FormValidation.NumberTypeError'))
      .max(
        999,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${0}-${999}`,
          unit: localization.formats.fuel.unit_co2_emission_factor
        })
      )
      .min(
        0,
        i18n.t('VehicleTypes.FormValidation.RangeValidation', {
          range: `${0}-${999}`,
          unit: localization.formats.fuel.unit_co2_emission_factor
        })
      )
  });
};
export const FuelFieldKey = {
  FuelType: 'fuelType',
  AVRFuelEfficiency: 'averagefuelefficiency',
  Co2EmissionsFactor: 'co2emissionsfactor'
};
export const initialValues = {
  name: '',
  description: '',
  code: '',
  companyId: '',
  harshAcceleration: '',
  harshBrake: '',
  harshCornering: '',
  harshCorneringLeft: '',
  harshCorneringRight: '',
  impact: '',
  overRev: '',
  idleTime: '',
  icon: 'tn-i-flatbed',
  oilOverTemp: '',
  coolantOverTemp: '',
  oilPressure: '',
  speedLimitClass: '',
  [FuelFieldKey.FuelType]: '',
  [FuelFieldKey.AVRFuelEfficiency]: '',
  [FuelFieldKey.Co2EmissionsFactor]: ''
};

export const iconSet = t => {
  return [
    {
      labelOptGroup: t('VehicleTypes.Form.Agriculture'),
      options: [
        {
          id: 'tn-i-barley',
          translatedLabel: t('VehicleTypes.Form.Barley')
        },
        {
          id: 'tn-i-corn',
          translatedLabel: t('VehicleTypes.Form.Corn')
        },
        {
          id: 'tn-i-cow',
          translatedLabel: t('VehicleTypes.Form.Cow')
        },
        {
          id: 'tn-i-pig-variant',
          translatedLabel: t('VehicleTypes.Form.Pig')
        },
        {
          id: 'tn-i-food-apple',
          translatedLabel: t('VehicleTypes.Form.Apple')
        },
        {
          id: 'tn-i-horse',
          translatedLabel: t('VehicleTypes.Form.Horse')
        },
        {
          id: 'tn-i-fish',
          translatedLabel: t('VehicleTypes.Form.Fish')
        },
        {
          id: 'tn-i-sheep',
          translatedLabel: t('VehicleTypes.Form.Sheep')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.Alerts'),
      options: [
        {
          id: 'tn-i-alert',
          translatedLabel: t('VehicleTypes.Form.Alert')
        },
        {
          id: 'tn-i-alert-box',
          translatedLabel: t('VehicleTypes.Form.AlertBox')
        },
        {
          id: 'tn-i-alert-circle',
          translatedLabel: t('VehicleTypes.Form.AlertCircle')
        },
        {
          id: 'tn-i-alert-decagram',
          translatedLabel: t('VehicleTypes.Form.AlertDecagram')
        },
        {
          id: 'tn-i-alert-octagon',
          translatedLabel: t('VehicleTypes.Form.AlertOctagon')
        },
        {
          id: 'tn-i-alert-rhombus',
          translatedLabel: t('VehicleTypes.Form.AlertRhombus')
        },
        {
          id: 'tn-i-alarm-light',
          translatedLabel: t('VehicleTypes.Form.AlarmLight')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.Alphabet'),
      options: [
        {
          id: 'tn-i-alpha-a-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetA')
        },
        {
          id: 'tn-i-alpha-b-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetB')
        },
        {
          id: 'tn-i-alpha-c-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetC')
        },
        {
          id: 'tn-i-alpha-d-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetD')
        },
        {
          id: 'tn-i-alpha-e-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetE')
        },
        {
          id: 'tn-i-alpha-f-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetF')
        },
        {
          id: 'tn-i-alpha-g-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetG')
        },
        {
          id: 'tn-i-alpha-h-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetH')
        },
        {
          id: 'tn-i-alpha-i-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetI')
        },
        {
          id: 'tn-i-alpha-j-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetJ')
        },
        {
          id: 'tn-i-alpha-k-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetK')
        },
        {
          id: 'tn-i-alpha-l-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetL')
        },
        {
          id: 'tn-i-alpha-m-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetM')
        },
        {
          id: 'tn-i-alpha-n-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetN')
        },
        {
          id: 'tn-i-alpha-o-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetO')
        },
        {
          id: 'tn-i-alpha-p-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetP')
        },
        {
          id: 'tn-i-alpha-q-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetQ')
        },
        {
          id: 'tn-i-alpha-r-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetR')
        },
        {
          id: 'tn-i-alpha-s-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetS')
        },
        {
          id: 'tn-i-alpha-t-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetT')
        },
        {
          id: 'tn-i-alpha-u-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetU')
        },
        {
          id: 'tn-i-alpha-v-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetV')
        },
        {
          id: 'tn-i-alpha-w-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetW')
        },
        {
          id: 'tn-i-alpha-x-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetX')
        },
        {
          id: 'tn-i-alpha-y-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetY')
        },
        {
          id: 'tn-i-alpha-z-circle',
          translatedLabel: t('VehicleTypes.Form.AlphabetZ')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.Amenities'),
      options: [
        {
          id: 'tn-i-silverware-fork-knife',
          translatedLabel: t('VehicleTypes.Form.SilverwareForkKnife')
        },
        {
          id: 'tn-i-cup-water',
          translatedLabel: t('VehicleTypes.Form.CupWater')
        },
        {
          id: 'tn-i-flask-outline',
          translatedLabel: t('VehicleTypes.Form.Flask')
        },
        {
          id: 'tn-i-gas-station',
          translatedLabel: t('VehicleTypes.Form.GasStation')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.CommercialVehicles'),
      options: [
        {
          id: 'tn-i-truck',
          translatedLabel: t('VehicleTypes.Form.Truck')
        },
        {
          id: 'tn-i-flatbed',
          translatedLabel: t('VehicleTypes.Form.Flatbed')
        },
        {
          id: 'tn-i-truck-semi-trailer',
          translatedLabel: t('VehicleTypes.Form.TruckSemiTrailer')
        },
        {
          id: 'tn-i-trailer',
          translatedLabel: t('VehicleTypes.Form.Trailer')
        },
        {
          id: 'tn-i-tanker',
          translatedLabel: t('VehicleTypes.Form.Tanker')
        },
        {
          id: 'tn-i-dump-truck',
          translatedLabel: t('VehicleTypes.Form.DumpTruck')
        },
        {
          id: 'tn-i-truck-off-road',
          translatedLabel: t('VehicleTypes.Form.TruckOffRoad')
        },
        {
          id: 'tn-i-truck-cargo-container',
          translatedLabel: t('VehicleTypes.Form.TruckCargoContainer')
        },
        {
          id: 'tn-i-truck-check-outline',
          translatedLabel: t('VehicleTypes.Form.TruckCheck')
        },
        {
          id: 'tn-i-truck-delivery-outline',
          translatedLabel: t('VehicleTypes.Form.TruckDelivery')
        },
        {
          id: 'tn-i-truck-minus-outline',
          translatedLabel: t('VehicleTypes.Form.TruckMinus')
        },
        {
          id: 'tn-i-truck-plus-outline',
          translatedLabel: t('VehicleTypes.Form.TruckPlus')
        },
        {
          id: 'tn-i-truck-remove-outline',
          translatedLabel: t('VehicleTypes.Form.TruckRemove')
        },
        {
          id: 'tn-i-truck-alert-outline',
          translatedLabel: t('VehicleTypes.Form.TruckAlert')
        },
        {
          id: 'tn-i-truck-snowflake',
          translatedLabel: t('VehicleTypes.Form.TruckSnowflake')
        },
        {
          id: 'tn-i-truck-flatbed',
          translatedLabel: t('VehicleTypes.Form.TruckFlatbed')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.Containers'),
      options: [
        {
          id: 'tn-i-metal-scrap-bin',
          translatedLabel: t('VehicleTypes.Form.MetalScrapBin')
        },
        {
          id: 'tn-i-yard-container-10-cubic',
          translatedLabel: t('VehicleTypes.Form.YardContainer10')
        },
        {
          id: 'tn-i-yard-container-20-cubic',
          translatedLabel: t('VehicleTypes.Form.YardContainer20')
        },
        {
          id: 'tn-i-yard-container-30-cubic',
          translatedLabel: t('VehicleTypes.Form.YardContainer30')
        },
        {
          id: 'tn-i-yard-container-40-cubic',
          translatedLabel: t('VehicleTypes.Form.YardContainer40')
        },
        {
          id: 'tn-i-yard-container-50-cubic',
          translatedLabel: t('VehicleTypes.Form.YardContainer50')
        },
        {
          id: 'tn-i-forklift-4x4',
          translatedLabel: t('VehicleTypes.Form.ForkLiftBin4x4')
        },
        {
          id: 'tn-i-forklift-4x6',
          translatedLabel: t('VehicleTypes.Form.ForkLiftBin4x6')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.ElectricVehicles'),
      options: [
        {
          id: 'tn-i-car-electric',
          translatedLabel: t('VehicleTypes.Form.ElectricCar')
        },
        {
          id: 'tn-i-bus-electric',
          translatedLabel: t('VehicleTypes.Form.ElectricBus')
        },
        {
          id: 'tn-i-truck-electric',
          translatedLabel: t('VehicleTypes.Form.ElectricTruck')
        },
        {
          id: 'tn-i-van-electric',
          translatedLabel: t('VehicleTypes.Form.ElectricVan')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.EmergencyVehicle'),
      options: [
        {
          id: 'tn-i-ambulance',
          translatedLabel: t('VehicleTypes.Form.Ambulance')
        },
        {
          id: 'tn-i-fire-truck',
          translatedLabel: t('VehicleTypes.Form.FireTruck')
        },
        {
          id: 'tn-i-police-car',
          translatedLabel: t('VehicleTypes.Form.PoliceCar')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.Energy'),
      options: [
        {
          id: 'tn-i-lightning-bolt',
          translatedLabel: t('VehicleTypes.Form.LightningBolt')
        },
        {
          id: 'tn-i-leaf',
          translatedLabel: t('VehicleTypes.Form.ZeroEmissions')
        },
        {
          id: 'tn-i-gas-cylinder',
          translatedLabel: t('VehicleTypes.Form.GasCylinder')
        },
        {
          id: 'tn-i-battery',
          translatedLabel: t('VehicleTypes.Form.Battery')
        },
        {
          id: 'tn-i-hydrogen',
          translatedLabel: t('VehicleTypes.Form.Hydrogen')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.HeavyMachinery'),
      options: [
        {
          id: 'tn-i-bulldozer',
          translatedLabel: t('VehicleTypes.Form.Bulldozer')
        },
        {
          id: 'tn-i-crane',
          translatedLabel: t('VehicleTypes.Form.Crane')
        },
        {
          id: 'tn-i-digger',
          translatedLabel: t('VehicleTypes.Form.Digger')
        },
        {
          id: 'tn-i-forklift',
          translatedLabel: t('VehicleTypes.Form.ForkLift')
        },
        {
          id: 'tn-i-tractor',
          translatedLabel: t('VehicleTypes.Form.Tractor')
        },
        {
          id: 'tn-i-tractor-variant',
          translatedLabel: t('VehicleTypes.Form.TractorVariant')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.Numbers'),
      options: [
        {
          id: 'tn-i-numeric-0-circle',
          translatedLabel: t('VehicleTypes.Form.Number0')
        },
        {
          id: 'tn-i-numeric-1-circle',
          translatedLabel: t('VehicleTypes.Form.Number1')
        },
        {
          id: 'tn-i-numeric-2-circle',
          translatedLabel: t('VehicleTypes.Form.Number2')
        },
        {
          id: 'tn-i-numeric-3-circle',
          translatedLabel: t('VehicleTypes.Form.Number3')
        },
        {
          id: 'tn-i-numeric-4-circle',
          translatedLabel: t('VehicleTypes.Form.Number4')
        },
        {
          id: 'tn-i-numeric-5-circle',
          translatedLabel: t('VehicleTypes.Form.Number5')
        },
        {
          id: 'tn-i-numeric-6-circle',
          translatedLabel: t('VehicleTypes.Form.Number6')
        },
        {
          id: 'tn-i-numeric-7-circle',
          translatedLabel: t('VehicleTypes.Form.Number7')
        },
        {
          id: 'tn-i-numeric-8-circle',
          translatedLabel: t('VehicleTypes.Form.Number8')
        },
        {
          id: 'tn-i-numeric-9-circle',
          translatedLabel: t('VehicleTypes.Form.Number9')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.Others'),
      options: [
        {
          id: 'tn-i-asset',
          translatedLabel: t('VehicleTypes.Form.Asset')
        },
        {
          id: 'tn-i-wheelchair',
          translatedLabel: t('VehicleTypes.Form.WheelChair')
        },
        {
          id: 'tn-i-cube',
          translatedLabel: t('VehicleTypes.Form.Cube')
        },
        {
          id: 'tn-i-cube-outline',
          translatedLabel: t('VehicleTypes.Form.CubeOutline')
        },
        {
          id: 'tn-i-rectangle',
          translatedLabel: t('VehicleTypes.Form.Rectangle')
        },
        {
          id: 'tn-i-rectangle-outline',
          translatedLabel: t('VehicleTypes.Form.RectangleOutline')
        },
        {
          id: 'tn-i-bag-suitcase',
          translatedLabel: t('VehicleTypes.Form.BagSuitcase')
        },
        {
          id: 'tn-i-snowflake',
          translatedLabel: t('VehicleTypes.Form.Snowflake')
        },
        {
          id: 'tn-i-forest',
          translatedLabel: t('VehicleTypes.Form.Forest')
        },
        {
          id: 'tn-i-sprout',
          translatedLabel: t('VehicleTypes.Form.Sprout')
        },
        {
          id: 'tn-i-water',
          translatedLabel: t('VehicleTypes.Form.Water')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.PassengerVehicles'),
      options: [
        {
          id: 'tn-i-sedan',
          translatedLabel: t('VehicleTypes.Form.Sedan')
        },
        {
          id: 'tn-i-pickup',
          translatedLabel: t('VehicleTypes.Form.Pickup')
        },
        {
          id: 'tn-i-minibus',
          translatedLabel: t('VehicleTypes.Form.Bus')
        },
        {
          id: 'tn-i-caravan',
          translatedLabel: t('VehicleTypes.Form.Caravan')
        },
        {
          id: 'tn-i-car-sports',
          translatedLabel: t('VehicleTypes.Form.CarSports')
        },
        {
          id: 'tn-i-taxi',
          translatedLabel: t('VehicleTypes.Form.Taxi')
        },
        {
          id: 'tn-i-motorbike',
          translatedLabel: t('VehicleTypes.Form.Motorbike')
        },
        {
          id: 'tn-i-car-estate',
          translatedLabel: t('VehicleTypes.Form.CarEstate')
        },
        {
          id: 'tn-i-van-passenger',
          translatedLabel: t('VehicleTypes.Form.VanPassenger')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.RailVehicles'),
      options: [
        {
          id: 'tn-i-train-car-autorack',
          translatedLabel: t('VehicleTypes.Form.TrainCarAutoTrack')
        },
        {
          id: 'tn-i-train-car-box',
          translatedLabel: t('VehicleTypes.Form.TrainCarAutoBox')
        },
        {
          id: 'tn-i-train-car-box-full',
          translatedLabel: t('VehicleTypes.Form.TrainCarAutoBoxFull')
        },
        {
          id: 'tn-i-train-car-centerbeam',
          translatedLabel: t('VehicleTypes.Form.TrainCarAutoCenterBeam')
        },
        {
          id: 'tn-i-train-car-centerbeam-full',
          translatedLabel: t('VehicleTypes.Form.TrainCarAutoCenterBeamFull')
        },
        {
          id: 'tn-i-train-car-container',
          translatedLabel: t('VehicleTypes.Form.TrainCarAutoContainer')
        },
        {
          id: 'tn-i-train-car-flatbed',
          translatedLabel: t('VehicleTypes.Form.TrainCarAutoFlatbed')
        },
        {
          id: 'tn-i-train-car-flatbed-car',
          translatedLabel: t('VehicleTypes.Form.TrainCarAutoFlatbedCar')
        },
        {
          id: 'tn-i-train-car-tank',
          translatedLabel: t('VehicleTypes.Form.TrainCarAutoTank')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.RecreationVehicles'),
      options: [
        {
          id: 'tn-i-atv',
          translatedLabel: t('VehicleTypes.Form.Atv')
        },
        {
          id: 'tn-i-bicycle',
          translatedLabel: t('VehicleTypes.Form.Bicycle')
        },
        {
          id: 'tn-i-golf-cart',
          translatedLabel: t('VehicleTypes.Form.GolfCart')
        },
        {
          id: 'tn-i-scooter',
          translatedLabel: t('VehicleTypes.Form.Scooter')
        },
        {
          id: 'tn-i-snowmobile',
          translatedLabel: t('VehicleTypes.Form.SnowMobile')
        },
        {
          id: 'tn-i-rv-truck',
          translatedLabel: t('VehicleTypes.Form.RvTruck')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.ServiceVehicles'),
      options: [
        {
          id: 'tn-i-tow-truck',
          translatedLabel: t('VehicleTypes.Form.TowTruck')
        },
        {
          id: 'tn-i-plane-car',
          translatedLabel: t('VehicleTypes.Form.PlaneCar')
        },
        {
          id: 'tn-i-plane-train',
          translatedLabel: t('VehicleTypes.Form.PlainTrain')
        },
        {
          id: 'tn-i-van-utility',
          translatedLabel: t('VehicleTypes.Form.VanUtility')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.ToolsAndMaintenance'),
      options: [
        {
          id: 'tn-i-car-wrench',
          translatedLabel: t('VehicleTypes.Form.CarWrench')
        },
        {
          id: 'tn-i-hammer-wrench',
          translatedLabel: t('VehicleTypes.Form.HammerWrench')
        },
        {
          id: 'tn-i-ladder',
          translatedLabel: t('VehicleTypes.Form.Ladder')
        },
        {
          id: 'tn-i-wrench-tools',
          translatedLabel: t('VehicleTypes.Form.Wrench')
        },
        {
          id: 'tn-i-ruler-square-compass',
          translatedLabel: t('VehicleTypes.Form.RulerSquareCompass')
        },
        {
          id: 'tn-i-pipe-wrench',
          translatedLabel: t('VehicleTypes.Form.PipeWrench')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.WaterCraft'),
      options: [
        {
          id: 'tn-i-ship-bulk',
          translatedLabel: t('VehicleTypes.Form.ShipBulk')
        },
        {
          id: 'tn-i-ship-barge',
          translatedLabel: t('VehicleTypes.Form.ShipContainer')
        },
        {
          id: 'tn-i-ship-container',
          translatedLabel: t('VehicleTypes.Form.ShipBarge')
        }
      ]
    },
    {
      labelOptGroup: t('VehicleTypes.Form.HazardSymbols'),
      options: [
        {
          id: 'tn-i-flameable',
          translatedLabel: t('VehicleTypes.Form.Flammeable')
        },
        {
          id: 'tn-i-explosive',
          translatedLabel: t('VehicleTypes.Form.Explosive')
        },
        {
          id: 'tn-i-biohazard',
          translatedLabel: t('VehicleTypes.Form.Biohazard')
        },
        {
          id: 'tn-i-poison',
          translatedLabel: t('VehicleTypes.Form.Poison')
        },
        {
          id: 'tn-i-radioactive',
          translatedLabel: t('VehicleTypes.Form.Radioactive')
        }
      ]
    }
  ];
};

export const speedLimitClassSet = t => {
  return [
    {
      value: 'LIGHT',
      label: t('VehicleTypes.Light')
    },
    {
      value: 'MEDIUM',
      label: t('VehicleTypes.Medium')
    },
    {
      value: 'HEAVY',
      label: t('VehicleTypes.Heavy')
    }
  ];
};

export const ENGINE_PROFILES = (t, localization) => {
  return [
    {
      label: `${t('VehicleTypes.Form.HarshAcceleration', {
        unit: localization.formats.acceleration.unit
      })}`,
      key: 'acceleration.threshold',
      BPM: true,
      SafetyAnalytics: true
    },
    {
      label: `${t('VehicleTypes.Form.HarshBrake', {
        unit: localization.formats.acceleration.unit
      })}`,
      key: 'harshbrake.threshold',
      BPM: true,
      SafetyAnalytics: true
    },
    {
      label: `${t('VehicleTypes.Form.HarshCornering', {
        unit: localization.formats.acceleration.unit
      })}`,
      key: 'harshcornering.threshold',
      SafetyAnalytics: true,
      BPM: true,
      deviceFeature: f => !f[deviceCapabilities.HarshManeuveurs]
    },
    {
      label: `${t('VehicleTypes.Form.HarshCornering', {
        unit: localization.formats.acceleration.unit
      })}`,
      SafetyAnalytics: true,
      BPM: true,
      deviceFeature: f => f[deviceCapabilities.HarshManeuveurs],
      segments: [
        {
          label: `${t('Alerts.GPIO.left')}`,
          key: 'harshleft.threshold'
        },
        {
          label: `${t('Alerts.GPIO.right')}`,
          key: 'harshright.threshold'
        }
      ]
    },
    {
      label: `${t('VehicleTypes.Form.Impact', {
        unit: localization.formats.acceleration.unit
      })}`,
      key: 'impact.threshold',
      BPM: true,
      deviceFeature: f => f[deviceCapabilities.Impact]
    },
    {
      label: t('VehicleTypes.Form.OverRev'),
      key: 'overrev.threshold'
    },
    {
      label: t('VehicleTypes.Form.IdleTime'),
      key: 'idle.threshold',
      BPM: true
    },
    {
      label: `${t('VehicleTypes.Form.OilOverTemp', {
        unit: localization.formats.temperature.unit
      })}`,
      key: 'oiltemp.threshold'
    },
    {
      label: `${t('VehicleTypes.Form.CoolantOverTemp', {
        unit: localization.formats.temperature.unit
      })}`,
      key: 'coolanttemp.threshold'
    },
    {
      label: `${t('VehicleTypes.Form.OilPressure', {
        unit: localization.formats.pressure.oil
      })}`,
      key: 'oilpressure.threshold',
      VPMOnly: true
    }
  ];
};
const CO2_FUEL_TYPE = {
  Diesel: {
    key: 'fuel_type_diesel',
    value: 'DIESEL',
    translated: 'VehicleTypes.Fuel.Diesel'
  },
  Petrol: {
    key: 'fuel_type_petrol',
    value: 'PETROL',
    translated: 'VehicleTypes.Fuel.Petrol'
  }
};
export const FUEL_TYPE = {
  ...CO2_FUEL_TYPE,
  Electricity: {
    key: 'fuel_type_electricity',
    value: 'ELECTRICITY',
    translated: 'VehicleTypes.Fuel.Electricity'
  }
};
export const isCo2FuelType = fuelTypeValue =>
  Object.values(CO2_FUEL_TYPE).some(type => fuelTypeValue === type.value);
export const isValidFuelType = fuelTypeValue =>
  Object.values(FUEL_TYPE).some(type => fuelTypeValue === type.value);

export const FUEL_PROFILES = (t, localization) => {
  return {
    [FuelFieldKey.FuelType]: {
      label: t('VehicleTypes.Fuel.Type'),
      key: FuelFieldKey.FuelType,
      placeholder: `${t('Entity.Vehicle')} ${t('VehicleTypes.Fuel.Type')}`,
      options: Object.keys(FUEL_TYPE).map(type => ({
        label: t(`VehicleTypes.Fuel.${type}`),
        ...FUEL_TYPE[type]
      })),
      canShow: () => true
    },
    [FuelFieldKey.AVRFuelEfficiency]: {
      label: `${t('VehicleTypes.Fuel.AverageFuelEfficiency', {
        unit: localization.formats.fuel.unit_avr_efficiency
      })}`,
      key: FuelFieldKey.AVRFuelEfficiency,
      tooltip: `${t('VehicleTypes.Tooltip.AverageFuelEfficiency', {
        unit: localization.formats.fuel.unit_avr_efficiency
      })}`,
      canShow: formValues => formValues && !!isCo2FuelType(formValues[FuelFieldKey.FuelType])
    },
    [FuelFieldKey.Co2EmissionsFactor]: {
      label: `${t('VehicleTypes.Fuel.CO2EmissionsFactor', {
        unit: localization.formats.fuel.unit_co2_emission_factor
      })}`.replace('2', '\u2082'),
      key: FuelFieldKey.Co2EmissionsFactor,
      tooltip: `${t('VehicleTypes.Tooltip.CO2EmissionsFactor', {
        unit: localization.formats.fuel.unit_co2_emission_factor
      })}`.replace('2', '\u2082'),
      canShow: formValues => formValues && !!isCo2FuelType(formValues[FuelFieldKey.FuelType])
    }
  };
};

export const TABS = {
  DELETED: 'deleted'
};

export const VEHICLE_TYPES_LIST = {
  NORMAL_LIST: 'list',
  DELETED_LIST: 'deletedList'
};

export const Paths = {
  DEFAULT: '/settings/vehicleTypes',
  ADD: '/settings/vehicleTypes/newVehicleType',
  EDIT_BY_ID: '/settings/vehicleTypes/edit/id/',
  COPY_BY_ID: '/settings/vehicleTypes/copy/id/',
  VIEW_BY_ID: '/settings/vehicleTypes/id'
};
