import React from 'react';
import { FormGroup, Col, FormLabel } from 'react-bootstrap';
import styles from './SearchableListMultiSelect.module.scss';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import FakeCheckbox from '../fake-checkbox/FakeCheckbox';

const HEADER_ITEMS_HEIGHT = 109;
const LABEL_HEIGHT = 34;

const ControlledListMultiSelect = ({
  fieldName,
  label,
  options,
  height,
  isRequired,
  isDisabled,
  disabledMessage,
  placeholder,
  allLabel,
  hideSelectAll,
  onCheck,
  onSearch
}) => {
  const allListItemsHeight = 25;
  const listLabelHeight = label ? 0 : LABEL_HEIGHT;
  const headerHeight = HEADER_ITEMS_HEIGHT - listLabelHeight;
  const listHeight = height - headerHeight - allListItemsHeight;

  const handleCheck = (selection, selectAll) => () => {
    if (selectAll) {
      const allSelected = options.every(option => option.checked);
      onCheck(options.map(option => ({ ...option, checked: allSelected ? false : true })));
    }

    onCheck([{ ...selection, checked: !selection.checked }], fieldName);
  };

  const handleSearch = event => {
    onSearch(event.target.value.toLowerCase());
  };

  const isCheckedAll =
    options.length && options.length === (options || []).filter(val => val.checked).length;

  return (
    <FormGroup as={Col} className={styles.col} style={{ height: `${height}px` }}>
      {label && <FormLabel>{label}</FormLabel>}
      {isRequired && <span className={styles.inputRequired}>*</span>}
      <div className={styles.listContainer}>
        <input
          className={`${styles.searchInput} ${isDisabled ? styles.disabled : ''}`}
          type="text"
          placeholder={placeholder}
          onChange={handleSearch}
          readOnly={isDisabled ? true : false}
        />
        {isDisabled ? (
          <div className={styles.disabledMessage}>{disabledMessage}</div>
        ) : (
          <div className={styles.list}>
            <div>
              {!hideSelectAll && (
                <div className={styles.listRow} onClick={handleCheck}>
                  <FakeCheckbox checked={isCheckedAll} />
                  <label htmlFor={`${fieldName}All`} className={styles.label}>
                    {allLabel}
                  </label>
                </div>
              )}
              <InfiniteLoader itemCount={options.length} isItemLoaded={() => true}>
                {({ onItemsRendered, ref }) => (
                  <List
                    className={styles.internalList}
                    height={listHeight}
                    itemCount={options.length}
                    itemSize={25}
                    ref={ref}
                    onItemsRendered={onItemsRendered}
                  >
                    {({ index, style }) => {
                      const value = options[index];
                      return (
                        <div
                          className={styles.listRow}
                          key={`${value.label}-${value.value}-${index}`}
                          onClick={handleCheck(value)}
                          style={style}
                        >
                          {value.value !== undefined && <FakeCheckbox checked={value.checked} />}
                          <label
                            htmlFor={`${value.label}-${value.value}`}
                            className={`${styles.label} ${value.value === undefined &&
                              styles.category}`}
                          >
                            {value.label}
                          </label>
                        </div>
                      );
                    }}
                  </List>
                )}
              </InfiniteLoader>
            </div>
          </div>
        )}
      </div>
    </FormGroup>
  );
};

ControlledListMultiSelect.propTypes = {};

ControlledListMultiSelect.defaultProps = {
  options: []
};

export default ControlledListMultiSelect;
