export const scorecardSelectors = {
  getSummary: state => state.scorecard.summary,
  isFetchingSummary: state => state.scorecard.isFetchingSummary,
  isSuccessSummary: state => state.scorecard.successSummary,
  isErrorSummary: state => state.scorecard.errorSummary,
  getBuckets: state => state.scorecard.buckets,
  isFetchingBucket: state => state.scorecard.isFetchingBucket,
  isSuccessBucket: state => state.scorecard.successBucket,
  isErrorBucket: state => state.scorecard.errorBucket,
  getBucketDefaults: state => state.scorecard.bucketDefaults,
  isFetchingBucketDefaults: state => state.scorecard.isFetchingBucketDefaults,
  isSuccessBucketDefaults: state => state.scorecard.successBucketDefaults,
  isErrorBucketDefaults: state => state.scorecard.errorBucketDefaults,
  getConfig: state => state.scorecard.config,
  isFetchingConfig: state => state.scorecard.isFetchingConfig,
  isSuccessConfig: state => state.scorecard.successConfig,
  isErrorConfig: state => state.scorecard.errorConfig,
  isUpdatingConfig: state => state.scorecard.isUpdatingConfig,
  getScoreEvents: state => state.scorecard.scoreEvents,
  isFetchingScoreEvents: state => state.scorecard.isFetchingScoreEvents,
  isSuccessScoreEvents: state => state.scorecard.successScoreEvents,
  isErrorScoreEvents: state => state.scorecard.errorScoreEvents,
  isIncludeExcludeEventsSaving: state => state.scorecard.isIncludeExcludeEventsSaving,
  getEnabledEventTypes: state => state.scorecard.enabledEventTypes,
  isFetchingEnabledEventTypes: state => state.scorecard.isFetchingEnabledEventTypes,
  isSuccessEnabledEventTypes: state => state.scorecard.successEnabledEventTypes,
  isErrorEnabledEventTypes: state => state.scorecard.errorEnabledEventTypes,
  lastRequestEnabledEventTypes: state => state.scorecard.lastRequestEnabledEventTypes,
  getEnabledEventTypesBranch: state => state.scorecard.enabledEventTypesBranch,
  isFetchingEnabledEventTypesBranch: state => state.scorecard.isFetchingEnabledEventTypesBranch,
  isSuccessEnabledEventTypesBranch: state => state.scorecard.successEnabledEventTypesBranch,
  isErrorEnabledEventTypesBranch: state => state.scorecard.errorEnabledEventTypesBranch,
  lastRequestEnabledEventTypesBranch: state => state.scorecard.lastRequestEnabledEventTypesBranch,
  getEnabledEventTypesFleet: state => state.scorecard.enabledEventTypesFleet,
  isFetchingEnabledEventTypesFleet: state => state.scorecard.isFetchingEnabledEventTypesFleet,
  isSuccessEnabledEventTypesFleet: state => state.scorecard.successEnabledEventTypesFleet,
  isErrorEnabledEventTypesFleet: state => state.scorecard.errorEnabledEventTypesFleet,
  lastRequestEnabledEventTypesFleet: state => state.scorecard.lastRequestEnabledEventTypesFleet
};
