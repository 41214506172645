import React, { useState } from 'react';
import { Dropdown, Row, Col } from 'react-bootstrap';
import { Button } from 'antd';
import TreeSelect, { checkedListItems } from './TreeSelect';
import './TreeSelect.scss';

import './DropdownTreeSelect.scss';
import { useTranslation } from 'react-i18next';
import { BUTTON_IDS } from 'utils/globalConstants';

const DropdownTree = React.forwardRef(
  ({ style, className, 'aria-labelledby': labeledBy, tree, onChange, showSelectAll }, ref) => {
    const [clearSelected, setClearSelected] = useState(false);
    const { t } = useTranslation();

    return (
      <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
        <div style={{ maxHeight: '70vh', overflow: 'scroll' }}>
          <TreeSelect
            tree={tree}
            onChange={onChange}
            showSelectAll={showSelectAll}
            clearSelected={clearSelected}
            setClearSelected={val => setClearSelected(val)}
          />
        </div>
        <div className={'dropdown-menu__footer'}>
          <Row className="align-items-center">
            <Col className="col-auto mr-auto" style={{ color: 'rgb(98, 98, 98)' }}>
              {checkedListItems(tree).length} {t('Common.Selected')}
            </Col>
            <Col className="col-auto">
              <Button
                ghost
                danger
                id={BUTTON_IDS.dropdownTree}
                disabled={checkedListItems(tree).length === 0}
                size={'medium'}
                onClick={() => setClearSelected(true)}
              >
                {t('Common.Clear')}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
);

const DropdownTreeSelect = ({ style, title, tree, onChange, showSelectAll }) => {
  const [treeOpen, setTreeOpen] = useState(false);

  return (
    <Dropdown
      className="dropdown-tree-select"
      style={style}
      onToggle={isOpen => {
        setTreeOpen(isOpen);
      }}
      show={treeOpen}
    >
      <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu
        tree={tree}
        onChange={onChange}
        showSelectAll={showSelectAll}
        as={DropdownTree}
        style={{ minWidth: '20rem' }}
      />
    </Dropdown>
  );
};

export default DropdownTreeSelect;
