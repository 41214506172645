import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import {
  entities,
  services,
  useCanEveryEntity,
  useCanOneOfServices,
  useIQCameraUser
} from 'features/permissions';

import { ViewDriverLink } from 'components/sentinel/ViewDriverLink';
import { CardType } from './CardType';

import styles from './Cards.module.scss';

export const EllipsisButtonContextMenu = ({
  card,
  showMap,
  setShowMap,
  onMessageVehicle,
  iconStyle,
  iconHorizontal,
  cardKey,
  hideNonBusiness = false
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { canAccessNonCameraFeatures } = useIQCameraUser();

  const canEditVehicle = useCanEveryEntity([entities.VEHICLE_UPDATE]);
  const canMessaging = useCanOneOfServices([services.MESSAGING]);
  const canEditUser = useCanEveryEntity([entities.USER_UPDATE]);

  const menuItems = [];

  if (canAccessNonCameraFeatures) {
    card.cardType === CardType.Driver &&
      card.driver?.id &&
      menuItems.push({
        key: `view-${cardKey}`,
        eventKey: `view-${cardKey}`,
        label: (
          <ViewDriverLink driverId={card.driver.id}>
            {({ onClick }) => <span onClick={onClick}>{t('Tracking.ViewDriver')}</span>}
          </ViewDriverLink>
        )
      });

    card.cardType === CardType.Driver &&
      card.driver?.id &&
      canEditUser &&
      menuItems.push({
        key: `update-${cardKey}`,
        eventKey: `update-${cardKey}`,
        onClick: () => history.push(`/settings/users/edit/id/${card.driver.id}`),
        label: t('Tracking.EditDriver')
      });

    card.cardType === CardType.Vehicle &&
      canEditVehicle &&
      card.vehicle?.id &&
      menuItems.push({
        key: `update-${cardKey}`,
        eventKey: `update-${cardKey}`,
        onClick: () => history.push(`/settings/vehicles/edit/id/${card.vehicle.id}`),
        label: t('Tracking.EditVehicle')
      });

    card.messagingDevice &&
      canMessaging &&
      menuItems.push({
        key: `messaging-${cardKey}`,
        eventKey: `messaging-${cardKey}`,
        onClick: () => {
          let elems = document.getElementsByClassName('ant-dropdown');
          elems[elems.length - 1].classList.add('ant-select-dropdown-hidden');
          onMessageVehicle(card);
        },
        label: card?.vehicleId ? t('Tracking.MessageVehicle') : t('Tracking.MessageDevice')
      });
  }

  menuItems.push({
    key: `toggle-map-${cardKey}`,
    eventKey: `toggle-map-${cardKey}`,
    onClick: () => setShowMap(card),
    disabled: hideNonBusiness,
    label: showMap ? t('Common.CloseMap') : t('Common.OpenMap')
  });

  const menu = <Menu className={styles.contextMenu} items={menuItems} />;

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <FontAwesomeIcon
        icon={iconHorizontal ? faEllipsisH : faEllipsisV}
        className={styles.ellipsisButton}
        style={iconStyle ? iconStyle : {}}
      />
    </Dropdown>
  );
};
