import { Col, Row } from 'antd';
import InfoRow from 'components/form/info-row/InfoRow';
import { GOOGLE_MAPS_API_KEY } from 'config';
import { useDeletedDevices } from 'features/devices/devicesDeletedSlice';
import { useDevices } from 'features/fleets/fleetsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { fetchPosition, usePositions } from 'features/smartJobs/smartJobsSlice';
import P from 'prop-types';
import React, { useEffect } from 'react';
import { RunsheetMap } from '../RunsheetMap';
import styles from '../SmartJobs.module.scss';
import {
  viewDetailsLabelStyle,
  viewDetailsValueStyle,
  ViewRunsheetLabels,
  EVENT_STATUS
} from '../utils/constants';
import { getInfoRowValue, getRunsheetLinkedDevice } from '../utils/helpers';
import { useTranslation } from 'react-i18next';
import { toTitleCase } from 'utils/strings';
import { useDispatch } from 'react-redux';

export const Details = ({ runsheet, events }) => {
  const { t } = useTranslation();

  const startEventTime = events?.find(event => event.type === EVENT_STATUS.OPENED)?.eventAt;
  const endEventTime = events?.find(event => event.type === EVENT_STATUS.COMPLETED)?.eventAt;
  const ids = {
    vehicleId: runsheet?.vehicle?.id,
    deviceVehicleId: runsheet?.device?.vehicle?.id,
    deviceId: runsheet?.device?.id
  };
  const eventTimes = { startEventTime, endEventTime };

  const dispatch = useDispatch();
  const positions = usePositions();
  const localization = useLocalization();
  const devices = useDevices();
  const deletedDevices = useDeletedDevices();
  const linkedDevice = getRunsheetLinkedDevice(devices, deletedDevices, runsheet);
  const translatedLabels = ViewRunsheetLabels.map(label => ({
    ...label,
    name: toTitleCase(t(`Common.${label.key}`))
  }));

  useEffect(() => {
    if (runsheet && events?.length > 0) {
      dispatch(fetchPosition(ids, eventTimes));
    }
  }, [runsheet, events]);

  return (
    <Row>
      <Col span={10} className={styles.container}>
        <div className={styles.infoRow}>
          {translatedLabels.map(label => (
            <InfoRow
              key={label.key}
              label={label.name}
              value={getInfoRowValue(
                { label: label.value, runsheet, startEventTime, endEventTime, linkedDevice },
                localization,
                t
              )}
              labelsxValue={viewDetailsLabelStyle}
              sxValue={viewDetailsValueStyle}
            />
          ))}
        </div>
      </Col>
      <Col span={14}>
        <RunsheetMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`}
          runsheet={runsheet}
          positions={positions}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </Col>
    </Row>
  );
};

Details.propTypes = {
  runsheetData: P.object
};
