import React, { useLayoutEffect, useState } from 'react';
import {
  AutoSizer,
  Table as RVTable,
  Column,
  defaultTableRowRenderer,
  CellMeasurer,
  defaultTableCellRenderer
} from 'react-virtualized';

const TableCell = ({ dataKey, rowData, rowIndex, parent, cellProps, cellRenderer, ...props }) => {
  const [renderComponent, setRenderComponent] = useState(null);
  useLayoutEffect(() => {
    setRenderComponent(prev => {
      if (prev == null || !cellProps.isScrolling) {
        if (cellRenderer) {
          return cellRenderer({ dataKey, rowData, rowIndex, parent, cellProps });
        } else {
          return defaultTableCellRenderer({
            rowIndex,
            rowData,
            dataKey,
            parent,
            ...cellProps
          });
        }
      } else {
        return prev;
      }
    });
  }, [dataKey, rowData, rowIndex, parent, cellRenderer, cellProps]);

  return <>{renderComponent}</>;
};

const Table = ({
  cols,
  rows,
  rowHeight = 42,
  rowRenderer = defaultTableRowRenderer,
  cellCache,
  ...props
}) => {
  return (
    <>
      <AutoSizer>
        {({ height, width }) => (
          <RVTable
            rowRenderer={rowRenderer}
            width={width}
            height={height}
            headerHeight={52}
            gridClassName="showScrollbarsOnHover"
            rowClassName="tableRow"
            rowCount={rows.length}
            rowGetter={({ index }) => rows[index]}
            rowHeight={rowHeight}
          >
            {cols.map(({ label, dataKey, ...props }, idx) => {
              return (
                <Column
                  {...props}
                  width={
                    props.width ? (parseFloat(props.width) * width) / 100 : width / cols.length - 42
                  }
                  key={dataKey + label}
                  style={{
                    padding: '6px 6px',
                    display: 'flex',
                    alignItems: 'center',
                    ...props.style
                  }}
                  label={label}
                  dataKey={dataKey}
                  cellRenderer={({ rowIndex, rowData, dataKey, parent, ...cellProps }) => (
                    <CellMeasurer
                      columnIndex={idx}
                      parent={parent}
                      rowIndex={rowIndex}
                      key={dataKey}
                      cache={cellCache}
                    >
                      <TableCell
                        cellRenderer={props.cellRenderer}
                        rowIndex={rowIndex}
                        rowData={rowData}
                        dataKey={dataKey}
                        parent={parent}
                        cellProps={cellProps}
                      />
                    </CellMeasurer>
                  )}
                />
              );
            })}
          </RVTable>
        )}
      </AutoSizer>
    </>
  );
};

export default Table;
