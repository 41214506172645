import React, { useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { EWD } from 'containers/Fatigue/ewd/EWD';
import { Sentinel } from 'containers/Fatigue/sentinel/Sentinel';
import { Row, Col } from 'antd';
import SubTopNav from 'components/nav/SubTopNav';
import Page from 'components/pages/Page';
import { useDispatch } from 'react-redux';
import { setBackButton, useBackButton } from 'features/page/pageSlice';
import { TabNavLink } from 'components/nav/NavLinks';
import { CanOrNull } from 'features/permissions/CanOrNull';
import services from 'features/permissions/services';
import { fetchEWDUser, useEWDUser } from 'features/user/EWDUserHooks';
import { fetchKeepers, useEWDKeeperId } from 'features/ewd/components/keepers/keepersSlice';

const Nav = () => {
  const isCurrentEWDUser = !!useEWDUser()?.auth?.token;
  return (
    <>
      <CanOrNull everyService={[services.SWD]}>
        <TabNavLink
          isActive={(match, location) => {
            return !location?.pathname?.match(/\/fatigue\/ewd/i);
          }}
          to="/fatigue/sentinel"
        >
          Sentinel
        </TabNavLink>
      </CanOrNull>
      <CanOrNull everyService={[services.EWD]} extraCheck={isCurrentEWDUser}>
        <TabNavLink to="/fatigue/ewd">EWD</TabNavLink>
      </CanOrNull>
    </>
  );
};

export const FatigueAu = () => {
  const backButton = useBackButton();
  const dispatch = useDispatch();
  const ewdUser = useEWDUser();
  const isCurrentEWDUser = !!ewdUser?.auth?.token;
  const ewdKeeperId = useEWDKeeperId();

  useEffect(() => {
    if (!ewdUser?.auth?.expiry) {
      dispatch(fetchEWDUser());
    }
    if (ewdUser?.auth?.expiry) {
      const isJWTExpired = ewdUser.auth.expiry * 1000 < new Date().valueOf();
      if (isJWTExpired) {
        dispatch(fetchEWDUser());
      }
    }
  }, [dispatch, ewdUser]);

  useEffect(() => {
    if (ewdUser?.auth && ewdKeeperId === null) {
      dispatch(fetchKeepers());
    }
  }, [ewdUser, ewdKeeperId, dispatch]);

  useEffect(() => {
    dispatch(setBackButton(false));
  }, [dispatch]);

  const page = useMemo(() => {
    return {
      usePageTitle: true,
      showBackButton: backButton,
      component: () => (
        <>
          <Route
            exact
            path={[
              '/fatigue(/sentinel)?(/All|/Working_Driving|/Resting)?',
              '/fatigue/ewd(/All|/Working_Driving|/Resting)?'
            ]}
          >
            <Row>
              <Col span={24}>
                <SubTopNav left={() => <Nav />} right={null} />
              </Col>
            </Row>
          </Route>
          <Row style={{ flex: '1' }}>
            <Switch>
              <Route path={['/fatigue/ewd']}>
                <CanOrNull everyService={[services.EWD]} extraCheck={isCurrentEWDUser}>
                  <EWD />
                </CanOrNull>
              </Route>
              <Route path={'/fatigue(/sentinel)?(/All|/Working_Driving|/Resting)?'}>
                <CanOrNull everyService={[services.SWD]}>
                  <Sentinel />
                </CanOrNull>
              </Route>
            </Switch>
          </Row>
        </>
      )
    };
  }, [backButton, isCurrentEWDUser]);

  return <Page page={page} />;
};
