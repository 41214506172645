import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { cache, nameSubjectRenderer, checkboxCellRenderer } from './CellRenderers';
import { useTranslation } from 'react-i18next';
import { LoadingTable } from 'components/grid/LoadingTable';

export const ModuleTable = ({
  modules,
  isLoading,
  setTableRef,
  handleClickModule,
  isForm,
  minHeight = 0
}) => {
  const { t } = useTranslation();
  if (isLoading) return <LoadingTable columnSizes={[80, 60, 60, 68, 63, 49]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          selected: 30
        };

        const dynamicColumnWidth = width - fixedColumnsWidth.selected;

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={Math.max(height, minHeight)}
            headerHeight={52}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={modules.length}
            rowGetter={({ index }) => modules[index]}
            ref={ref => setTableRef(ref)}
          >
            <Column
              label=""
              dataKey="selected"
              width={fixedColumnsWidth.selected}
              cellRenderer={props => checkboxCellRenderer({ ...props, isForm, handleClickModule })}
            />
            <Column
              label={t('Roles.EntityTable.ServiceName')}
              dataKey="name"
              width={dynamicColumnWidth}
              cellRenderer={props => nameSubjectRenderer({ ...props, isForm, t })}
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
