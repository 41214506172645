import React from 'react';
import { Switch, Route } from 'react-router-dom';

//components
import { SubCompanies } from './SubCompanies';
import { SubCompaniesView } from './SubCompaniesView';
import { SubCompaniesAudits } from './SubCompaniesAudits';
import { SubCompaniesForm } from './SubCompaniesForm';
import { NoAccessBody } from 'components/pages/NoAccess';
import { Can, entities } from 'features/permissions';

//constants
import { PATHS, ACTIONS } from './constants';

export const SubCompaniesRouter = () => {
  return (
    <Can everyEntity={[entities.SUBCOMPANY]} onFail={<NoAccessBody />}>
      <Switch>
        <Route path={PATHS.SUBCOMPANY_VIEW}>
          <Can everyEntity={[entities.SUBCOMPANY_VIEW]} onFail={<NoAccessBody />}>
            <SubCompaniesView />
          </Can>
        </Route>
        <Route path={PATHS.SUBCOMPANY_ADD}>
          <Can everyEntity={[entities.SUBCOMPANY_CREATE]} onFail={<NoAccessBody />}>
            <SubCompaniesForm action={ACTIONS.ADD} />
          </Can>
        </Route>
        <Route path={PATHS.SUBCOMPANY_AUDITS}>
          <Can everyEntity={[entities.SUBCOMPANY_AUDIT]} onFail={<NoAccessBody />}>
            <SubCompaniesAudits />
          </Can>
        </Route>
        <Route path={PATHS.SUBCOMPANY_EDIT}>
          <Can everyEntity={[entities.SUBCOMPANY_UPDATE]} onFail={<NoAccessBody />}>
            <SubCompaniesForm action={ACTIONS.EDIT} />
          </Can>
        </Route>
        <Route path={PATHS.SUBCOMPANY_DEFAULT}>
          <SubCompanies />
        </Route>
      </Switch>
    </Can>
  );
};
