import { useState, useCallback, useMemo } from 'react';
import * as moment from 'moment';
import { orderBy } from 'lodash';

import { useSnapshotsByDeviceIds } from 'features/dashboard/dashboardSlice';

import { RECIPIENT_TYPE } from 'containers/Messaging/constants';

import { useCompanySnapshotsEntities } from './useCompanySnapshotsEntities';
import { useSnapshotsFilter } from './useSnapshotsFilter';
import { getPositionedFootages } from '../helpers';

export const useSnapshots = ({ showNonCameraVehicle = false }) => {
  const {
    isLoading,
    snapshotsEntities: entities,
    fleets: allFleets,
    vehiclesAndDevices: allVehicles,
    companies: allCompanies,
    companyId,
    vehiclesAndDevicesIds
  } = useCompanySnapshotsEntities({ showNonCameraVehicle });

  const {
    sortOption,
    filterText,
    selectedYear,
    selectedMonth,
    filterVehicles,
    filteredDeviceIds,
    from,
    to,
    orderField,
    orderDirection,
    rowOffset,
    setRowOffset,
    ...filterProps
  } = useSnapshotsFilter({
    allCompanies,
    allFleets,
    allVehicles,
    companyId,
    vehiclesAndDevicesIds
  });

  const [showPopup, setShowpopup] = useState(false);
  const [showFootageModal, setShowFootageModal] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [requestVideoDeviceId, setRequestVideoDeviceId] = useState(null);
  const [requestVideoVehicleId, setRequestVideoVehicleId] = useState(null);

  const { data, isLoading: isLoadingSnapshots, allLoaded } = useSnapshotsByDeviceIds({
    companyId,
    deviceIds: filteredDeviceIds,
    rowOffset,
    from,
    to,
    orderField,
    orderDirection,
    searchText: filterText,
    latestOnly: true
  });

  const snapshotsEntities = useMemo(() => {
    const getSnapshotsByDeviceIds = snapshotsEntity => {
      const devicesOfSnapshotsEntity =
        snapshotsEntity.nodeType === 'Vehicle' && snapshotsEntity.devices
          ? snapshotsEntity.devices
          : [{ id: snapshotsEntity.deviceId }];
      const deviceSnapshots = devicesOfSnapshotsEntity
        .filter(d => !!d?.id)
        .map(d => d.id)
        .reduce((acc, deviceId) => [...acc, ...(data?.[deviceId] || [])], []);
      return deviceSnapshots;
    };
    const ret = [];
    for (const snapshotsEntity of entities) {
      const positionedFootages = getPositionedFootages(getSnapshotsByDeviceIds(snapshotsEntity));
      const snapshotsCount = Object.values(positionedFootages).filter(f => !!f).length;
      if (snapshotsCount) {
        ret.push({
          ...snapshotsEntity,
          positionedFootages,
          snapshotsCount
        });
      }
    }
    return ret;
  }, [entities, data]);

  const SortSnapshots = useCallback(
    o => {
      let lastEventTime = -1;
      if (o.nodeType === 'Vehicle') {
        lastEventTime =
          o.devices?.reduce((prev, d) => {
            return Math.max(
              prev,
              data?.[d.id]?.reduce(
                (prev, curr) => Math.max(prev, moment(curr.timeAt).valueOf()),
                -1
              )
            );
          }, -1) || -1;
      } else {
        lastEventTime =
          data?.[o.deviceId]?.reduce(
            (prev, curr) => Math.max(prev, moment(curr.timeAt).valueOf()),
            -1
          ) || -1;
      }
      return lastEventTime;
    },
    [data]
  );

  const snapshots = useMemo(() => {
    //Sort
    const sorts = [SortSnapshots, 'deviceName'];
    const order = [sortOption.order, 'asc'];

    let sortedSnapshotsEntities = [];
    if (sortOption.item === 'vehicleName') {
      sorts.splice(0, 1);
      sorts.splice(1, 1);
      sortedSnapshotsEntities = orderBy(
        snapshotsEntities,
        [snapshot => snapshot.deviceName.toLowerCase()],
        order
      );
    } else {
      sortedSnapshotsEntities = orderBy(snapshotsEntities, sorts, order);
    }

    //Filter
    return sortedSnapshotsEntities.filter(
      s =>
        filterVehicles.find(v => v.id === s.nodeId)?.checked &&
        (!filterText ||
          filterText.trim() === '' ||
          s.deviceName?.toLowerCase().includes(filterText.toLowerCase()))
    );
  }, [snapshotsEntities, filterVehicles, filterText, SortSnapshots, sortOption]);

  const snapshotsCount = useMemo(() => snapshots.reduce((a, s) => a + s.snapshotsCount, 0), [
    snapshots
  ]);

  const handleMessageVehicle = useCallback(({ id, deviceId }) => {
    const recipient = !!id
      ? {
          recipientType: RECIPIENT_TYPE.VEHICLE,
          recipientId: id
        }
      : {
          recipientType: RECIPIENT_TYPE.DEVICE,
          recipientId: deviceId
        };
    setRecipient(recipient);
    setShowpopup(true);
  }, []);

  const handleRequestVideo = useCallback((vehicleId, deviceId) => {
    setRequestVideoDeviceId(deviceId);
    setRequestVideoVehicleId(vehicleId);
    setShowFootageModal(true);
  }, []);

  const onLoadMore = useCallback(() => setRowOffset(row => row + 100), []);

  return {
    isDataFetching: isLoading,
    filters: {
      snapshotsCount,
      filterText,
      filterVehicles,
      selectedYear,
      selectedMonth,
      sortOption,
      ...filterProps
    },
    snapshots: {
      isLoading: isLoadingSnapshots,
      snapshots,
      snapshotsCount,
      snapshotYear: selectedYear,
      snapshotMonth: selectedMonth,
      sortBy: sortOption,
      onLoadMore,
      allLoaded,
      onMessageVehicle: handleMessageVehicle,
      onRequestFootage: handleRequestVideo
    },
    messagingProps: {
      showPopup,
      visible: showPopup,
      showModal: setShowpopup,
      recipients: [recipient]
    },
    requestVideoProps: {
      showFootageModal,
      showModal: showFootageModal,
      vehicleId: requestVideoVehicleId,
      deviceId: requestVideoDeviceId,
      onClose: () => {
        setShowFootageModal(false);
      }
    }
  };
};
