import { Alert, Button, DatePicker, Form, Input, Modal, Select, Space, Tooltip } from 'antd';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import moment from 'moment/moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ToastType } from 'components/notifications/toasts/Toast';

import { useCurrentCompany } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useUserKey } from 'features/user/userSlice';

import { parseErrorMessage } from 'utils/strings';
import countriesPrefix from './utils/prefixNumbers.json';

import { API_PATH } from 'config';

import style from './ShareJob.module.scss';

export const ShareJobModal = ({
  isOpen: isModalOpen,
  job = {},
  jobOrVehicle,
  onCancel,
  vehicle = {}
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentCompany = useCurrentCompany();
  const userKey = useUserKey();

  const [copiedLink, setCopiedLink] = useState(false);
  const [copiedLinkText, setCopiedLinkText] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [disabledNext, setDisabledNext] = useState(true);
  const [email, setEmail] = useState('');
  const [generatedUrl, setGeneratedUrl] = useState('');
  const [phoneCountryCode, setPhoneCountryCode] = useState(
    countriesPrefix.find(cp => cp.code === currentCompany.country)?.code || countriesPrefix[0].code
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phonePrefix, setPhonePrefix] = useState(null);

  const [form] = Form.useForm();
  const countrySelectorWatch = Form.useWatch('country', form);

  let calendarSelectedDates = useRef({ dates: [moment(), null] });

  const handleCurrentStep = () => {
    currentStep === 1 ? setCurrentStep(2) : setCurrentStep(1);
  };

  const handleDateRangeClose = isOpen => {
    setDatePickerOpen(isOpen);
  };

  const handleDisableDates = current => {
    return (
      moment(current).isBefore(moment(), 'day') ||
      (calendarSelectedDates.current?.dates?.length > 0 &&
        calendarSelectedDates.current?.dates[0] &&
        current >= moment(calendarSelectedDates.current?.dates[0]).add(7, 'days'))
    );
  };

  const handleDisabledTime = date => {
    if (moment(calendarSelectedDates.current.dates[0]).format('LL') === moment(date).format('LL')) {
      return {
        disabledHours: () =>
          Array.from(Array(24).keys()).splice(
            0,
            moment(calendarSelectedDates.current.dates[0]).hour()
          ),
        disabledMinutes: () =>
          Array.from(Array(60).keys()).splice(
            0,
            moment(calendarSelectedDates.current.dates[0]).minute()
          ),
        disabledSeconds: () =>
          Array.from(Array(60).keys()).splice(
            0,
            moment(calendarSelectedDates.current.dates[0]).second()
          )
      };
    }
  };

  const handleModalClose = () => {
    setCurrentStep(1);
    form.resetFields();
    onCancel();
  };

  const handleOnCalendarPicker = dates => {
    dates.includes(null) ? setDisabledNext(true) : setDisabledNext(false);

    calendarSelectedDates.current = {
      dates: dates || null
    };
  };

  const handleSelectPrefix = value => {
    const country = countriesPrefix.find(e => e.name === value);
    setPhoneCountryCode(country.code);
    setPhonePrefix(country.dial_code);
  };

  const handleSendEmail = () => {
    fetch(`${API_PATH}/links/${jobOrVehicle === 'job' ? 'pod' : 'tracking'}/share`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token token="${userKey}"`
      },
      body: JSON.stringify({
        link: generatedUrl,
        emails: [email]
      })
    })
      .then(() => {
        setEmail('');
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('SmartJobs.ShareJobModal.EmailSuccess')
          })
        );
      })
      .catch(err =>
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(err?.data)
          })
        )
      );
  };

  const handleSendSms = () => {
    fetch(`${API_PATH}/links/${jobOrVehicle === 'job' ? 'pod' : 'tracking'}/share`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token token="${userKey}"`
      },
      body: JSON.stringify({
        link: generatedUrl,
        phoneNumbers: [parsePhoneNumber(`${phonePrefix}${phoneNumber}`, phoneCountryCode).number]
      })
    })
      .then(response => {
        setPhoneNumber('');
        form.resetFields(['phone']);
        dispatch(
          openToast({
            type: response.ok ? ToastType.Success : ToastType.Error,
            message: response.ok
              ? t('SmartJobs.ShareJobModal.SmsSuccess')
              : t('SmartJobs.ShareJobModal.ErrorCode', { code: response.status })
          })
        );
      })
      .catch(err =>
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(err?.data)
          })
        )
      );
  };

  const phonePrefixSearch = (input, option) =>
    (option?.value ?? '').toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (
      (job?.id || vehicle?.id) &&
      calendarSelectedDates.current?.dates?.length === 2 &&
      !calendarSelectedDates.current?.dates?.includes(null) &&
      !datePickerOpen
    ) {
      const queryParams = new URLSearchParams({
        company_id: currentCompany.id
      });
      const fetchUrl = async () =>
        await fetch(
          `${API_PATH}/links/${jobOrVehicle === 'job' ? 'pod' : 'tracking'}?${queryParams}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token token="${userKey}"`
            },
            body:
              jobOrVehicle === 'job'
                ? JSON.stringify({
                    jobId: job.id,
                    startAt: calendarSelectedDates.current.dates[0].format(),
                    expiresAt: calendarSelectedDates.current.dates[1].format()
                  })
                : JSON.stringify({
                    vehicleIds: [vehicle.id],
                    startAt: calendarSelectedDates.current.dates[0].format(),
                    expiresAt: calendarSelectedDates.current.dates[1].format()
                  })
          }
        )
          .then(response => response.text())
          .then(response => {
            setGeneratedUrl(response);
          });

      fetchUrl().catch(err =>
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(err?.data)
          })
        )
      );
    }
  }, [job?.id, vehicle?.id, calendarSelectedDates.current, datePickerOpen]);

  useEffect(() => {
    form.validateFields(['phone']);
  }, [countrySelectorWatch]);

  return (
    <Modal
      centered
      className={style.shareJobModal}
      destroyOnClose={true}
      footer={null}
      onCancel={handleModalClose}
      open={isModalOpen}
      title={
        jobOrVehicle === 'job'
          ? t('SmartJobs.ShareJobModal.ModalTitle')
          : t('Tracking.ShareModal.Title')
      }
    >
      {currentStep === 1 && (
        <>
          <Form layout="vertical" preserve={false}>
            <Form.Item
              label={
                jobOrVehicle === 'job'
                  ? t('SmartJobs.ShareJobModal.Smartjob')
                  : t('Tracking.ShareModal.Vehicle')
              }
              className={jobOrVehicle === 'vehicle' && style.vehicleInput}
            >
              <Input
                defaultValue=""
                value={jobOrVehicle === 'job' ? job?.customerName : vehicle?.name}
                disabled
              />
              {jobOrVehicle === 'vehicle' && (
                <Alert
                  className={style.vehicleUsedWarning}
                  message={t('Tracking.ShareModal.VehicleUsedWarning')}
                  showIcon
                  type="warning"
                />
              )}
            </Form.Item>
            <Form.Item label={t('SmartJobs.ShareJobModal.RangePickerTitle')}>
              <DatePicker.RangePicker
                className={style.datePicker}
                defaultValue={[moment(), null]}
                disabled={[true, false]}
                disabledDate={handleDisableDates}
                disabledTime={handleDisabledTime}
                onCalendarChange={handleOnCalendarPicker}
                onOpenChange={handleDateRangeClose}
                showTime
              />
            </Form.Item>
            <Button type="primary" onClick={handleCurrentStep} disabled={disabledNext}>
              {t('SmartJobs.ShareJobModal.Next')}
            </Button>
          </Form>
        </>
      )}
      {currentStep === 2 && (
        <>
          <Form form={form} layout="vertical">
            <Form.Item>
              <Space.Compact block={true} wrap={true}>
                <Input
                  className={style.inputInGroup}
                  defaultValue=""
                  readOnly
                  value={generatedUrl}
                />
                <Tooltip title={copiedLinkText} placement="bottom" open={copiedLink}>
                  <Button
                    className={style.buttonInGroup}
                    disabled={!generatedUrl}
                    onClick={e => {
                      navigator.clipboard
                        .writeText(generatedUrl)
                        .then(() => {
                          setCopiedLinkText(t('SmartJobs.ShareJobModal.LinkCopied'));
                          setCopiedLink(true);
                          setTimeout(() => {
                            setCopiedLink(false);
                          }, 3000);
                        })
                        .catch(() => {
                          setCopiedLinkText(t('SmartJobs.ShareJobModal.LinkCopiedError'));
                          setCopiedLink(true);
                          setTimeout(() => {
                            setCopiedLink(false);
                          }, 3000);
                        });
                      e.currentTarget.blur();
                    }}
                  >
                    {t('SmartJobs.ShareJobModal.CopyLink')}
                  </Button>
                </Tooltip>
              </Space.Compact>
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {formInstance => {
                const emailError = formInstance.getFieldError('email').length > 0;
                return (
                  <Form.Item
                    label={t('SmartJobs.ShareJobModal.EmailTitle')}
                    name="email"
                    rules={[
                      {
                        type: 'email',
                        message: t('SmartJobs.ShareJobModal.EmailError')
                      }
                    ]}
                  >
                    <Space.Compact block={true} wrap={true}>
                      <Input
                        className={style.inputInGroup}
                        defaultValue=""
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                      />
                      <Button
                        className={style.buttonInGroup}
                        disabled={!email || (email && emailError)}
                        onClick={handleSendEmail}
                        type="primary"
                      >
                        {t('SmartJobs.ShareJobModal.Send')}
                      </Button>
                    </Space.Compact>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {formInstance => {
                const phoneNumberError = formInstance.getFieldError('phone').length > 0;
                return (
                  <Form.Item label={t('SmartJobs.ShareJobModal.PhoneTitle')}>
                    <Space.Compact block={true} wrap={true}>
                      <Form.Item noStyle name="country">
                        <Select
                          className={style.select}
                          defaultValue={
                            countriesPrefix.find(cp => cp.code === currentCompany.country)?.name ||
                            countriesPrefix[0].name
                          }
                          filterOption={phonePrefixSearch}
                          onChange={handleSelectPrefix}
                          optionLabelProp="label"
                          popupMatchSelectWidth={false}
                          showSearch
                        >
                          {countriesPrefix.map(e => (
                            <Select.Option value={e.name} label={e.emoji.concat(' ', e.dial_code)}>
                              <Space>
                                <span role="img">{e.emoji}</span>
                                {e.name}
                              </Space>
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="phone"
                        noStyle
                        rules={[
                          () => ({
                            validator() {
                              if (phoneNumber) {
                                if (!phoneNumber.match(/^\d+$/)) {
                                  return Promise.reject(
                                    new Error(t('SmartJobs.ShareJobModal.PhoneNonNumerical'))
                                  );
                                }

                                if (phoneNumber.length < 2) {
                                  return Promise.reject(t('SmartJobs.ShareJobModal.PhoneTooShort'));
                                }

                                if (phoneNumber.length > 17) {
                                  return Promise.reject(t('SmartJobs.ShareJobModal.PhoneTooLong'));
                                }

                                const statusPhoneValidation = parsePhoneNumber(
                                  `${phonePrefix}${phoneNumber}`,
                                  phoneCountryCode
                                ).isValid();

                                return statusPhoneValidation
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      new Error(t('SmartJobs.ShareJobModal.PhoneError'))
                                    );
                              }
                            }
                          })
                        ]}
                      >
                        <Input
                          className={style.inputPhoneNumber}
                          onChange={e => setPhoneNumber(e.target.value.replace(/\s/g, ''))}
                          value={phoneNumber}
                        />
                      </Form.Item>
                      <Button
                        className={style.buttonInGroup}
                        disabled={!phoneNumber || (phoneNumberError && phoneNumber)}
                        onClick={handleSendSms}
                        type="primary"
                      >
                        {t('SmartJobs.ShareJobModal.Send')}
                      </Button>
                    </Space.Compact>
                  </Form.Item>
                );
              }}
            </Form.Item>
            <Alert
              description={t('SmartJobs.ShareJobModal.InformativeText')}
              showIcon
              type="info"
            />
            <Button onClick={handleModalClose} type="primary">
              {t('SmartJobs.ShareJobModal.Done')}
            </Button>
          </Form>
        </>
      )}
    </Modal>
  );
};
