import request from 'superagent';
import { API_PATH } from 'config';

import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

// POST and PUT methods
const post = (path, payload, body = {}, method = 'POST', text = false, preffixURL) => {
  // Add the query params if any
  let url = `${preffixURL || API_PATH}${path}`;
  if (payload?.query) {
    url += flattenQuery(payload.query);
  }

  let postRequest = (method === 'POST' ? request.post(url) : request.put(url)).send(body);
  const { authKey, headers, authKeyTellius } = payload;

  // Add the authorization key
  if (authKey) {
    postRequest.set('Authorization', `Token token="${authKey}"`);
  }

  if (authKeyTellius) {
    postRequest.set('Authorization', authKeyTellius);
  }

  // Add extra headers
  if (headers && Array.isArray(headers)) {
    headers.forEach(header => {
      postRequest.set(header.name, header.value);
    });
  }

  if (text) {
    return postRequest
      .parse(({ parseResponseText }) => {
        let parsedText = (parseResponseText || '').replaceAll("'", '"');
        return JSON.parse(parsedText);
      })
      .catch(err => err);
  }

  return postRequest;
};

// PUT method
const put = (path, payload, body) => post(path, payload, body, 'PUT');

// GET method
const get = (path, payload, text = false, preffixURL) => {
  const { authKey, headers, query, authKeyTellius } = payload;
  let url = `${preffixURL || API_PATH}${path}`;
  if (query) {
    url += flattenQuery(query);
  }
  let getRequest = request.get(url);

  // Add the authorization key
  if (authKey) {
    getRequest.set('Authorization', `Token token="${authKey}"`);
  }

  if (authKeyTellius) {
    getRequest.set('Authorization', authKeyTellius);
  }

  // Add extra headers
  if (headers && Array.isArray(headers)) {
    headers.forEach(header => {
      getRequest.set(header.name, header.value);
    });
  }

  if (text) {
    return getRequest
      .parse(({ text }) => {
        let parsedText = (text || '').replaceAll("'", '"');
        return JSON.parse(parsedText);
      })
      .catch(err => err);
  }

  return getRequest;
};

// DELETE method
const deleteMethod = (url, payload, body, preffixURL) => {
  const requestURLPreffix = preffixURL || API_PATH;
  let deleteRequest = body
    ? request.delete(`${requestURLPreffix}${url}`).send(body)
    : request.delete(`${requestURLPreffix}${url}`);
  const { authKey, headers, authKeyTellius } = payload;

  // Add the authorization key
  if (authKey) {
    deleteRequest.set('Authorization', `Token token="${authKey}"`);
  }

  if (authKeyTellius) {
    deleteRequest.set('Authorization', authKeyTellius);
  }

  // Add extra headers
  if (headers && Array.isArray(headers)) {
    headers.forEach(header => {
      deleteRequest.set(header.name, header.value);
    });
  }

  return deleteRequest;
};

/**
 * Experimental fetch wrapper
 */
export const fetch = (type, payload = {}, callbacks = {}) => async (_, getState) => {
  // Start fetch
  if (typeof callbacks.start === 'function') {
    callbacks.start();
  }

  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;

  const method = {
    post: api.post,
    get: api.get
  };

  if (!authKey) {
    return;
  }

  try {
    const request = method[type];
    const { url, body, query } = payload;
    const response = await request(
      url,
      {
        authKey,
        query
      },
      body
    );

    // Success case
    if (response?.body) {
      const { status, body } = response;
      // Success
      if (typeof callbacks.success === 'function') {
        callbacks.success({ response, currentCompany });
      }
      return { status, body };
    }
  } catch (error) {
    // Success
    if (typeof callbacks.error === 'function') {
      callbacks.error(error.response);
    }
    return {
      status: error.response?.status,
      body: error.response?.body || error.message
    };
  }
};

export const api = {
  get,
  post,
  put,
  delete: deleteMethod
};

// HELPERS
export function flattenQuery(queryObject) {
  const queryObjectKeys = Object.keys(queryObject);
  return queryObjectKeys.reduce((queryString, queryKey, index) => {
    queryString += `${queryKey}=${encodeURIComponent(queryObject[queryKey])}`;

    if (index < queryObjectKeys.length - 1) {
      queryString += '&';
    }
    return queryString;
  }, '?');
}

function formatDates(query = {}) {
  const { from, to } = query;
  if (!from && !to) {
    return;
  }

  const momentFrom = moment(from).startOf('day');
  const momentTo = moment(to).endOf('day');

  const isoFrom = momentFrom.toISOString();
  const isoTo = momentTo.toISOString();

  return {
    from: isoFrom,
    to: isoTo
  };
}

// async thunk creator
export const asyncThunk = (action, url, verb = 'get', callback) =>
  createAsyncThunk(action, async (args = {}, { getState, dispatch, rejectWithValue }) => {
    const { user, companies } = getState();
    const { query, callbackQuery = {}, body, id, successToaster, errorToaster } = args;

    const authKey = user?.current?.auth?.key;
    const company_id = companies?.current?.id;

    // Methods map
    const method = api[verb];
    const dates = formatDates(query);
    try {
      const response = await method(
        id ? `${url}/${id}` : url,
        {
          authKey,
          query: {
            company_id,
            ...query,
            ...(dates && {
              from: dates.from,
              to: dates.to
            })
          }
        },
        body
      );

      // After reponse callback
      if (typeof callback === 'function') {
        dispatch(callback(callbackQuery));
      }
      if (typeof successToaster === 'function') {
        dispatch(successToaster());
      }
      // Show toast
      return response?.body;
    } catch (err) {
      if (typeof errorToaster === 'function') {
        dispatch(errorToaster(err));
      }
      return rejectWithValue(err.response?.body);
    }
  });
