import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import moment from 'moment';

import { Row, Col, Space } from 'antd';
import { DateRangePicker } from 'components/ant';
import { CardContainer } from 'components/card-container';
import { CardInfoRow } from 'components/card-info-row';
import { CardEventType } from 'components/card-event-type';
import { StarFilled } from '@ant-design/icons';
import { PieChart } from 'components/ant/Charts/pie-chart';
import { LineChart } from 'components/ant/Charts/line-chart';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';
import { useUsers } from 'features/users/usersSlice';
import { useVehicles } from 'features/fleets/fleetsSlice';
import { LoadingCentered } from 'components/loading/Loading';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';

import {
  formatDateDifference,
  disabledDate,
  getRangeColor,
  prepareDataForLineChart,
  prepareDataForTable,
  getEnabledEventTypes
} from './helpers';
import { ColorRanges, EventTypes, EntityTypes, TabKeys, Paths } from './constants';
import { getRoundValue } from 'utils/methods';
import { clearScore } from 'features/scorecard/reducers';

import styles from './Scorecard.module.scss';
import { useConfig, useSummary, fetchSummary, useEnabledEventTypes } from 'features/scorecard';
import { Can, useCan, services, entities } from 'features/permissions';
import dayjs from 'dayjs';

export const ScorecardView = () => {
  const { id, tab, dateFromSV, dateToSV, selectedCompany } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const can = useCan();

  const [dateFrom, setDateFrom] = useState(dateFromSV);
  const [dateTo, setDateTo] = useState(dateToSV);
  const [datePickerOpen, setDatePickerOpen] = useState();

  const datePickerRef = useRef();

  const localization = useLocalization();
  const config = useConfig(selectedCompany);
  const summary = useSummary(dateFrom, dateTo, selectedCompany, tab, id);

  const users = useUsers();
  const vehicles = useVehicles();

  const period = formatDateDifference(dateFrom, dateTo, t, localization);
  const history = useHistory();

  const entityType = tab === TabKeys.drivers ? EntityTypes.Location : EntityTypes.Fleet;
  const eventTypes = useEnabledEventTypes(selectedCompany, entityType, 0);

  const { data, group, average } = useMemo(() => {
    const entities = tab === TabKeys.vehicles ? vehicles : users;

    const tableInfo = prepareDataForTable({
      data: summary,
      entities,
      activeTab: tab,
      hasGroup: true,
      localization
    });

    return {
      data: tableInfo.data.find(d => Number(d.entityId) === Number(id)) || {},
      group: tableInfo.group || {},
      average: tableInfo.average
    };
  }, [tab, users, vehicles, summary, id]);

  const pieData = useMemo(() => {
    const color = getRangeColor(data.normalizedScore, config.colorBands);

    const pieLabel =
      tab === TabKeys.drivers ? t('Scorecard.DriverScore') : t('Scorecard.VehicleScore');

    const eventData = {
      name: <div className={styles.pieLabel}>{pieLabel}</div>,
      value: getRoundValue(data.normalizedScore, 1),
      color: ColorRanges[color],
      percentage: `${t('Scorecard.PreviousScore')}: ${data.normalizedScoreForTrends}`,
      diff: data.normalizedScore - data.normalizedScoreForTrends,
      key: 1
    };
    const totalData = {
      value: getRoundValue(100 - data.normalizedScore, 1),
      percentage: `${t('Scorecard.PreviousScore')}: ${data.normalizedScoreForTrends}`,
      diff: data.normalizedScore - data.normalizedScoreForTrends,
      key: 2
    };
    return [eventData, totalData];
  }, [data, average, config, t]);

  const chartData = useMemo(() => {
    return prepareDataForLineChart(summary, eventTypes, Number(id), localization, t);
  }, [summary, eventTypes, id, localization, t]);

  useEffect(() => {
    datePickerRef.current?.resetDates([dayjs(dateFrom), dayjs(dateTo)]);
  }, [dateTo]);

  useEffect(() => {
    data.name
      ? dispatch(setPageTitle(`${t(`Common.Scorecard`)} - ${data.name}`))
      : dispatch(setPageTitle(t(`Common.Scorecard`)));

    dispatch(setBackButton(true));
  }, [data, t, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearScore());
    };
  }, [dispatch]);

  useEffect(() => {
    if (datePickerOpen === false) {
      const params = {
        startAt: dateFrom,
        endAt: dateTo,
        companyId: selectedCompany,
        entityName: tab,
        entityId: id
      };
      dispatch(fetchSummary(params));
    }
  }, [datePickerOpen, dispatch]);

  const handleDateRangeChange = dates => {
    if (!dates || !dates[0] || !dates[1]) {
      return;
    }
    const startAt = moment(dates[0].format('YYYY-MM-DD'));
    const endAt = moment(dates[1].format('YYYY-MM-DD'));
    setDateFrom(startAt.format('YYYY-MM-DD'));
    setDateTo(endAt.format('YYYY-MM-DD'));
  };

  const handleDateRangeClose = isOpen => {
    setDatePickerOpen(isOpen);
  };

  const handleCardEventTypeClick = eventType => {
    history.push(
      `${Paths.MAIN_PAGE}/events/${tab}/id/${id}/type/${eventType}/${dateFrom}/${dateTo}`
    );
  };

  // Filter the event types we show in the UI by the ones that are enabled
  const enabledEventTypes = getEnabledEventTypes(
    Object.values(EventTypes(localization)),
    eventTypes
  );

  const canViewEventsPage = can({
    oneOfEntities: [entities.SCORECARD]
  });

  let eventCardClass = styles.cardEventType;
  if (!canViewEventsPage) {
    eventCardClass = styles.cardEventTypeNoClick;
  }

  // Don't use functions localization.formatDistance()/formatFuel() because values are already converted
  const distanceDisplay =
    parseFloat(data.distanceTravelled || 0).toLocaleString(localization.locale, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    }) +
    ' ' +
    (data.distanceTravelled <= 1
      ? localization.formats.speed.unit
      : localization.formats.speed.unit_pluralize);

  const fuelUseDisplay =
    (data.fuelConsumed || 0).toLocaleString(localization.locale, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1
    }) +
    ' ' +
    localization.formats.fuel.usage;

  return (
    <>
      <div className={`${styles.filterWrapper} ${styles.spaceAround} ${styles.rightElement}`}>
        <DateRangePicker
          className={styles.dateRangePicker}
          size="small"
          format={localization.formats.time.formats.dby.toUpperCase()}
          maxDayRange={30}
          defaultDates={[dayjs(dateFrom), dayjs(dateTo)]}
          disabledDate={current => disabledDate(current, dayjs(dateFrom))}
          onDateRangeChanged={dates => {
            handleDateRangeChange(dates);
          }}
          availableDatesRange={[0, moment().endOf('day')]}
          onOpenChange={handleDateRangeClose}
          datePickerRef={datePickerRef}
        />
      </div>
      <Row wrap={false}>
        <Col flex="480px">
          <CardContainer title={t('Scorecard.Details')}>
            <div className={styles.detailsContainer}>
              <Row>
                <Col span={13}>
                  <CardInfoRow label={t('Scorecard.Period')} value={period} />
                  <CardInfoRow label={t('Scorecard.CompanyRank')} value={data.rank} />
                  {tab === TabKeys.drivers && (
                    <CardInfoRow label={t('Scorecard.BranchRank')} value={data.groupRank} />
                  )}
                  <CardInfoRow label={t('Scorecard.activeDays')} value={data.activeDays} />
                  <CardInfoRow
                    label={t('Scorecard.TripsNumber')}
                    value={data.trips}
                    className={styles.nowrap}
                  />
                  <CardInfoRow
                    label={t('Scorecard.Distance')}
                    value={distanceDisplay}
                    className={styles.nowrap}
                  />
                  <CardInfoRow
                    label={t('Scorecard.Time')}
                    value={`${getRoundValue(data.duration / 3600, 1)} h`}
                  />
                  <Can everyService={services.VPM}>
                    <CardInfoRow
                      label={t('Scorecard.FuelUse')}
                      value={fuelUseDisplay}
                      className={styles.nowrap}
                    />
                  </Can>
                  <Can everyService={services.VPM}>
                    <CardInfoRow
                      label={t('Scorecard.FuelEconomy')}
                      value={`${localization.convertFuelEconomy(
                        data.fuelConsumed || 0,
                        parseFloat(data.distanceTravelled || 0),
                        true
                      )} ${localization.formats.fuel.economy}`}
                      className={styles.nowrap}
                    />
                  </Can>
                </Col>
                <Col span={11}>
                  <PieChart data={pieData} />
                  <div className={styles.infoLabel}>{t('Scorecard.CompanyScore')}</div>
                  <div className={styles.infoValue}>
                    <b>{average.normalizedScore}</b>
                  </div>
                  <div className={styles.infoLabel}>
                    {t('Scorecard.CompanyTargetScore')}
                    {data.metTargetScore && (
                      <StarFilled className={styles.metCompanyTargetIconView} />
                    )}
                  </div>
                  <div className={styles.infoValue}>
                    <b>{config.companyTarget}</b>
                  </div>
                </Col>
              </Row>
            </div>
          </CardContainer>
        </Col>
        <Col flex="auto">
          <CardContainer title={t('Scorecard.Trends')}>
            {chartData.lines.length > 0 && (
              <LineChart
                height={372}
                data={chartData}
                properties={{
                  dataKey: 'timeAt',
                  domain: [0, 100],
                  type: 'monotone'
                }}
              />
            )}
            {chartData.lines.length === 0 && <LoadingCentered />}
          </CardContainer>
        </Col>
      </Row>
      <Space size={24} className={styles.cardEventTypeContainer}>
        {enabledEventTypes.map(event => {
          const entityColor = getRangeColor(data[event.dataKey], config.colorBands);
          const averageColor = getRangeColor(average[event.dataKey], config.colorBands);
          const groupColor = getRangeColor(group[event.dataKey], config.colorBands);
          return (
            <div
              key={event.key}
              className={eventCardClass}
              onClick={canViewEventsPage ? () => handleCardEventTypeClick(event.dataKey) : null}
            >
              <CardEventType
                title={t(event.name)}
                scores={{
                  entityValue: data[event.dataKey],
                  companyValue: average[event.dataKey],
                  groupValue: group[event.dataKey],
                  previousValue: data[`${event.dataKey}ForTrends`]
                }}
                colors={{
                  entity: ColorRanges[entityColor],
                  company: ColorRanges[averageColor],
                  group: ColorRanges[groupColor]
                }}
                tab={tab}
              />
            </div>
          );
        })}
      </Space>
    </>
  );
};
