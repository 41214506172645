import React from 'react';
import { Collapse } from 'antd';
import styles from './Accordion.module.scss';

const { Panel } = Collapse;

export const Accordion = ({ icon, onChange, title, body, isOpen = false }) => {
  const defaultActiveKey = isOpen ? [title] : [];

  return (
    <Collapse
      accordion={true}
      className={styles.collapse}
      defaultActiveKey={defaultActiveKey}
      onChange={onChange}
    >
      <Panel
        showArrow={false}
        header={
          <div className={styles.cardHeader}>
            <span>{title}</span>
            {icon ? (
              <i className={`tn-i-chevron-up ${styles.icon}`} />
            ) : (
              <i className={`tn-i-chevron-down ${styles.icon}`} />
            )}
          </div>
        }
        key={title}
      >
        <div className={styles.panelBody}>{body}</div>
      </Panel>
    </Collapse>
  );
};
