import React, { useState, useMemo, useCallback } from 'react';
import { useGeoSuggestableGeofences } from 'features/geofences/geofencesSlice';
import { boldKeywordsInText } from 'utils/strings';
import { toLower } from 'lodash';

export const useGeofenceSuggests = () => {
  const [geoSearchText, setGeoSearchText] = useState('');

  const searchableGeofences = useGeoSuggestableGeofences();

  const geofenceFixtures = useMemo(
    () =>
      (searchableGeofences || []).filter(geofence =>
        toLower(geofence.label).includes(toLower(geoSearchText))
      ),
    [geoSearchText, searchableGeofences]
  );

  const skipGeoSuggest = useCallback(
    suggest => {
      if (!geoSearchText || !geoSearchText.trim()) {
        return true;
      } else {
        return suggest.isGeofence && !toLower(suggest.label).includes(toLower(geoSearchText));
      }
    },
    [geoSearchText]
  );

  const clearGeoSearchText = useCallback(() => setGeoSearchText(''), []);
  const renderSuggestItem = useCallback((suggest, inputText) => {
    try {
      if (suggest.isFixture) {
        const textSegements = boldKeywordsInText(inputText, suggest.label);
        return (
          <span>
            {textSegements.map((textSegement, index) =>
              textSegement.bold ? (
                <strong key={index}>{textSegement.word}</strong>
              ) : (
                <span key={index}>{textSegement.word}</span>
              )
            )}
          </span>
        );
      } else {
        const boldStr = suggest.description.slice(
          suggest.matchedSubstrings.offset,
          suggest.matchedSubstrings.offset + suggest.matchedSubstrings.length
        );
        return suggest.description.split(boldStr).map((segment, index, segments) => (
          <span key={`addressSegment-${index}`}>
            <span key={index}>{segment}</span>
            {index !== segments.length - 1 && <strong key={`bold-${index}`}>{boldStr}</strong>}
          </span>
        ));
      }
    } catch (error) {
      return <span>{suggest.description || suggest.label}</span>;
    }
  }, []);

  return {
    geoSuggestProps: {
      fixtures: geofenceFixtures,
      skipSuggest: skipGeoSuggest,
      onChange: setGeoSearchText,
      renderSuggestItem
    },
    clearSuggests: clearGeoSearchText
  };
};
