import { toTitleCase } from 'utils/strings';

import { confirm } from './confirm';

export const confirmDeleteEntity = ({
  t,
  preContent,
  postContent,
  onConfirm,
  onCancel,
  entityType,
  entityName,
  ...props
}) => {
  return confirm({
    title: t('TN.Modal.Confirm.DeleteEntity.Title', { entityType: toTitleCase(entityType) }),
    content: (
      <>
        <div>{preContent}</div>
        <div>
          {t('TN.Modal.Confirm.DeleteEntity.Message', {
            entityType: entityType.toLowerCase(),
            entityName
          })}
        </div>
        <div>{postContent}</div>
      </>
    ),
    confirmButtonText: t('TN.Modal.Confirm.DeleteEntity.DeleteButton'),
    cancelButtonText: t('TN.Modal.CancelButton'),
    onConfirm,
    onCancel,
    buttonType: 'danger',
    ...props
  });
};
