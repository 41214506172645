import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useCan, entities } from 'features/permissions';

const DeviceName = ({ id, name, canViewDevice }) =>
  canViewDevice ? <Link to={`/settings/devices/id/${id}`}>{name}</Link> : <span>{name}</span>;

export const DeviceNameCell = ({ id, name, errMessage }) => {
  const can = useCan();
  return (
    <Space wrap size={2}>
      <DeviceName
        canViewDevice={can({ everyEntity: [entities.DEVICE_VIEW] })}
        id={id}
        name={name}
      />
      {errMessage && (
        <Tooltip title={errMessage} placement="rightTop">
          <FontAwesomeIcon style={{ fontSize: '18px', color: '#ffbf00' }} icon={faInfoCircle} />
        </Tooltip>
      )}
    </Space>
  );
};
