import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

//components
import { Formik, Form } from 'formik';
import { Row, Col, DatePicker, Select, Checkbox } from 'antd';
import { FormLabel } from 'react-bootstrap';
import FormInput from 'components/form/form-input/FormInput';
import FormSelect from 'components/form/form-select/FormSelect';
import { DocumentsDisplay } from 'components/Documents';
import { FileUpload } from 'components/FileUpload';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { Button } from 'components/ant';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import RescheduleModal from './rescheduleModal';
import { LoadingCentered } from 'components/loading/Loading';

// slices
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { useVehicleConfig } from 'features/vehicles/vehiclesConfigsSlice';
import { useFleets, useVehicles } from 'features/fleets/fleetsSlice';
import { useUser } from 'features/user/userSlice';
import { useVehicleMntTypes } from 'features/vehicleMntTypes/vehicleMntTypesSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import {
  attachFiles,
  fetchTrips,
  refetchSchedules
} from 'features/vehicleMaintenance/schedulesSlice';

//helpers
import { renderUploadedFiles, handleFileAttachment } from '../DriverManagement/helpers';
import { api } from 'utils/api';
import { format } from 'utils/dates';
import { parseErrorMessage } from 'utils/strings';
import { LocalizationUtil, DATE_FORMAT } from 'features/localization/localization';
import { canHistoryGoBack, getRoundValue } from 'utils/methods';
import { getMeterValue } from 'features/meters';

//constants
import { validationSchemaComplete, initialValuesComplete, Paths, STATUS } from './utils/constants';
import { ToastType } from 'components/notifications/toasts/Toast';
import { METER_TYPES, VehicleConfig } from '../Administration/Vehicles/constants';

// styles
import styles from './VehicleMaintenanceSchedules.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useCurrentCompany } from 'features/company/companySlice';
import { getConfig, useConfig } from 'features/easydocs/documentsSlice';

const { Option } = Select;

const ScheduleComplete = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();
  const fleets = useFleets();
  const vehicles = useVehicles();
  const types = useVehicleMntTypes();
  const uploadConfig = useConfig();
  const [formikInitialValues, setFormikInitialValues] = useState(initialValuesComplete);
  const [files, saveFiles] = useState([]);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  const [schedule, setSchedule] = useState();
  const company = useCurrentCompany();
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventsForDropdown, setEventsForDropdown] = useState([]);
  const [fetchingEvents, setFetchingEvents] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [serviceDate, setServiceDate] = useState(null);
  const dateFormat = localization.formats.time.formats.dby_imp.toUpperCase().replace(':MM', ':mm');
  const vehicleConfig = useVehicleConfig(schedule?.vehicle?.id, VehicleConfig.OdometerForMnt.value);
  const [isCertifyCompleted, setIsCertifyCompleted] = useState(false);
  const [gpioType, setGPIOType] = useState(null);

  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );

  const isCompletingSchedule = window.location.pathname.indexOf('complete') > -1;
  const isScheduleDVIR = schedule?.name?.startsWith('DVIR', 0);

  const showCertifyCheckbox = (isCompletingSchedule && isScheduleDVIR) || false;

  const SCHEDULE_BY_ID_URL = `/schedules/vehiclemaintenance/${id}/`;
  const COMPLETE_SCHEDULE_PUT = `/schedules/vehiclemaintenance/complete/${id}`;

  useEffect(() => {
    dispatch(setPageTitle(t('VehicleMntSchedules.View.CompleteScheduledMaintenance')));
    dispatch(setBackButton(true));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(getConfig(company?.id));
  }, [company]);

  useEffect(() => {
    if (!schedule && user) {
      api
        .get(SCHEDULE_BY_ID_URL, {
          authKey: user.auth.key,
          query: { embed: 'vehicle,user', pruning: 'APP' }
        })
        .then(res => {
          if (res && res.body) {
            const { completionParameters } = res.body;
            if (completionParameters) {
              try {
                const params = JSON.parse(completionParameters);
                if (params && params.documents) {
                  const documentsForScheduleView = params.documents.map(document => ({
                    ...document,
                    name: document.filename,
                    size: document.filesize,
                    isSaved: true
                  }));
                  saveFiles([...files, ...documentsForScheduleView]);
                }
              } catch (error) {
                console.error(error);
              }
            }
            setSchedule(res.body);
          }
        });
    }
  }, [schedule, user, SCHEDULE_BY_ID_URL]);

  useEffect(() => {
    if (schedule && fleets.length > 0 && vehicles.length > 0) {
      const vehicleId = schedule.vehicle && schedule.vehicle.id;
      const fleets = vehicleId && vehicles.find(vehicle => vehicle.id === vehicleId)?.fleets;
      const fleetId = fleets && fleets.length > 0 && fleets[0].id;

      let completionParameters = {};
      try {
        completionParameters = JSON.parse(schedule?.completionParameters || '{}');
      } catch (e) {
        console.error(e);
      }

      let parameters = {};
      try {
        parameters = JSON.parse(schedule?.parameters || '{}');
      } catch (e) {
        console.error(e);
      }

      parameters?.gpio_meters?.[0] && setGPIOType(parameters?.gpio_meters?.[0]);

      const raised_by = `${schedule?.user?.firstName} ${schedule?.user?.lastName}`;
      const completedServiceDate =
        (completionParameters &&
          completionParameters.service_on &&
          moment(completionParameters.service_on, DATE_FORMAT.DEFAULT)) ||
        moment();
      if (completedServiceDate) {
        setServiceDate(completedServiceDate);
        getTripEvents(completedServiceDate);
      }

      if (completionParameters?.odometer) {
        completionParameters.odometer = localization.convertDistance(completionParameters.odometer);
      }

      setFormikInitialValues({
        ...formikInitialValues,
        ...completionParameters,
        raised_by,
        service_on: serviceDate,
        fleet: fleetId,
        vehicle: vehicleId,
        type: schedule.manageTypeId,
        saveAndReschedule: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedule, fleets, vehicles]);

  const updateFilesCb = payload => {
    saveFiles(payload);
  };

  const handleSubmit = async (values, actions) => {
    const { setSubmitting } = actions;
    const {
      vehicle,
      vehicleId,
      fleet,
      fleetId,
      raised_by,
      type,
      documents,
      saveAndReschedule,
      ...newCompletionParameters
    } = values;

    Mixpanel.sendTrackEvent(MPTrackingEvents.VehicleMaintenance.Schedule.complete, {
      typeName: types.find(t => `${t.id}` === `${type}`)?.name,
      action:
        MPTrackingEvents.VehicleMaintenance.Schedule.completeAction[
          saveAndReschedule ? 'completeWithReschedule' : 'complete'
        ],
      serviceName: schedule?.name
    });

    const odometer =
      values.odometer &&
      (localization.formats.speed.unit_per_hour === 'mph'
        ? LocalizationUtil.miletokm(parseFloat(values.odometer))
        : values.odometer);
    const service_on = format(new Date(serviceDate), DATE_FORMAT.DEFAULT);
    const submitBody = {
      updatedAt: moment().format(),
      completionParameters: JSON.stringify({ ...newCompletionParameters, service_on, odometer })
    };
    try {
      const filesToAttach = files.filter(file => !file.isSaved);
      await dispatch(attachFiles(filesToAttach, schedule.id));

      const response = await api.put(
        COMPLETE_SCHEDULE_PUT,
        {
          authKey: user.auth.key
        },
        submitBody
      );

      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('VehicleMntSchedules.Notifications.Complete', {
            name: schedule.name
          })
        })
      );
      setPromptModalWhenLeaving(false);
      dispatch(
        refetchSchedules(response.body || { id }, [STATUS.pending, STATUS.dueNow, STATUS.overdue])
      );
      setSubmitting(false);
      // Save and reschedule action
      if (values.saveAndReschedule) {
        updateRescheduleModalVisibility(true);
      } else {
        // Save action
        canHistoryGoBack(history, `${Paths.VEHICLEMAINTENANCE_VIEW}/${id}`);
      }
    } catch (error) {
      console.error(error);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t('VehicleMntSchedules.Notifications.CompleteError', {
            name: schedule.name
          })}. ${parseErrorMessage(error)}`
        })
      );
    }
  };

  const handleCancel = dirty => () => {
    if (!dirty) {
      return history.goBack();
    }
    setPromptModalWhenLeaving(false);
    confirmationModal(
      t('Common.Modal.SureTitle'),
      t('Common.Modal.SureQuestionChangesLost'),
      t('Common.Modal.CancelChanges'),
      t('Stay'),
      history.goBack
    );
  };

  const handleAttachment = event => {
    const {
      target: { files: uploadFiles }
    } = event;
    handleFileAttachment({
      uploadFiles,
      files,
      dispatch,
      updateFilesCb,
      maxFileSizeBytes: uploadConfig?.maxFileSizeBytes
    });
  };

  const updateRescheduleModalVisibility = bool => {
    setShowRescheduleModal(bool);
  };

  const handleEventChange = setFieldsValues => eventId => {
    const selectedEvent = events.find(event => event.id === eventId);
    if (selectedEvent?.id) {
      setSelectedEvent(selectedEvent.id);
      const vehicleMeters =
        selectedEvent.metersSnapshot && JSON.parse(selectedEvent.metersSnapshot);
      const odometerMeters = vehicleMeters?.find(meters => meters.type === METER_TYPES.ODOMETER);
      const engineHoursMeters = vehicleMeters?.find(meters => meters.type === METER_TYPES.HOURS);
      const gpioHoursMeter = vehicleMeters?.find(meters => meters.type === gpioType);

      const odometer = getRoundValue(
        localization.convertDistance(getMeterValue(odometerMeters)),
        1
      );
      const engine_hours = getRoundValue(getMeterValue(engineHoursMeters), 1);
      const gpio_hours = gpioHoursMeter ? getRoundValue(getMeterValue(gpioHoursMeter), 1) : '';

      setFieldsValues({ odometer, engine_hours, ...(gpioType && { [gpioType]: gpio_hours }) });
    } else {
      setSelectedEvent(null);
      setFieldsValues({ odometer: '', engine_hours: '', ...(gpioType && { [gpioType]: '' }) });
    }
  };

  const handleDateChange = setFieldsValues => async date => {
    setSelectedEvent(null);
    setFieldsValues({ odometer: '', engine_hours: '', ...(gpioType && { [gpioType]: '' }) });
    if (!date) {
      return;
    }
    setServiceDate(date);
    getTripEvents(date);
  };

  const handleCheckboxChange = event => {
    setIsCertifyCompleted(event.target.checked);
  };

  const getTripEvents = async date => {
    const fromDate = date.subtract(1, 'days').toISOString();
    const toDate = date.add(1, 'days').toISOString();
    setFetchingEvents(true);
    const trips = await dispatch(
      fetchTrips(fromDate, toDate, schedule.vehicle.id, localization, t)
    );
    const ignitionOffTrips = trips?.filter(trip => trip.IgnOffGPS) || [];
    const formattedEvents = ignitionOffTrips.map(event => ({
      value: event.id,
      label: `${t('Vehicles.IgnitionOffEvent')} (${format(
        new Date(event.timeAt),
        localization.formats.time.formats.dby_imp
      )})`
    }));
    setEventsForDropdown(formattedEvents);
    setEvents(ignitionOffTrips);
    setFetchingEvents(false);
  };

  if (!schedule) {
    return <LoadingCentered />;
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{ ...formikInitialValues }}
        validationSchema={validationSchemaComplete(gpioType)}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, dirty, setFieldValue, setValues, values }) => (
          <>
            <RescheduleModal
              scheduleId={id}
              show={showRescheduleModal}
              updateRescheduleModalVisibility={updateRescheduleModalVisibility}
              from={'completeSchedule'}
            />
            <EditRouteGuard when={dirty && promptModalWhenLeaving} navigate={history.push} />
            <Form id="ScheduleForm">
              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>
                  <div className={styles.formContainer}>
                    <Row>
                      <Col span={8}>
                        <FormSelect
                          label={t('VehicleMntSchedules.View.Fleet')}
                          name="fleet"
                          isDisabled
                          values={fleets.map(fleet => ({
                            label: fleet.name,
                            value: fleet.id
                          }))}
                        />
                      </Col>
                      <Col span={8}>
                        <FormSelect
                          label={t('VehicleMntSchedules.View.Vehicle')}
                          name="vehicle"
                          isDisabled
                          values={vehicles.map(vehicle => ({
                            label: vehicle.name,
                            value: vehicle.id
                          }))}
                        />
                      </Col>
                      <Col span={8}>
                        <FormSelect
                          label={t('VehicleMntSchedules.View.Type')}
                          name="type"
                          isDisabled
                          values={types.map(type => ({
                            label: type.name,
                            value: type.id
                          }))}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <div className={styles.datePicker}>
                          <FormLabel> {t('VehicleMntSchedules.View.ServiceDate')}</FormLabel>
                          <DatePicker
                            value={serviceDate}
                            onChange={handleDateChange(fields =>
                              setValues({ ...values, ...fields }, true)
                            )}
                            showTime
                            format={dateFormat}
                            allowClear={false}
                          />
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className={styles.eventSelect}>
                          <FormLabel>{t('VehicleMntSchedules.View.IgnitionEvents')}</FormLabel>
                          <Select
                            disabled={vehicleConfig?.isUsingRUCOdometerForMnt}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                            loading={fetchingEvents}
                            onChange={handleEventChange(fields =>
                              setValues({ ...values, ...fields }, true)
                            )}
                            placeholder={t('Vehicles.SelectOption')}
                            showSearch
                            value={selectedEvent}
                          >
                            {eventsForDropdown.map((event, index) => (
                              <Option value={event.value} key={`event-${index}`}>
                                {event.label}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </Col>
                      <Col span={8}>
                        <FormInput
                          name="odometer"
                          type="number"
                          isRequired
                          label={`${t('VehicleMntSchedules.View.Odometer')} (${
                            localization.formats.speed.unit
                          })`}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <FormInput
                          name="service_cost"
                          label={`${t('VehicleMntSchedules.View.CostOfService')} (${
                            localization.formats.currency.unit
                          })`}
                        />
                      </Col>
                      <Col span={8}>
                        <FormInput
                          name="invoice_number"
                          label={t('VehicleMntSchedules.View.InvoiceNumber')}
                        />
                      </Col>
                      <Col span={8}>
                        <FormInput
                          name="engine_hours"
                          type="number"
                          isRequired
                          label={t('VehicleMntSchedules.View.EngineHours')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <FormInput
                          name="signed_off_by"
                          label={t('VehicleMntSchedules.View.MaintenanceSignedOffBy')}
                        />
                      </Col>
                      <Col span={8}>
                        <FormInput
                          name="completed_by"
                          label={t('VehicleMntSchedules.View.MaintenanceCompletedBy')}
                        />
                      </Col>
                      {gpioType && (
                        <Col span={8}>
                          <FormInput
                            name={gpioType}
                            type="number"
                            isRequired
                            label={`${t('VehicleMntSchedules.View.GPIOType', {
                              type: t(`Vehicles.GPIO.${gpioType}`)
                            })}`}
                          />
                        </Col>
                      )}
                      {!gpioType && (
                        <Col span={8}>
                          <FormInput
                            name="order_number"
                            label={t('VehicleMntSchedules.View.InternalOrderNumber')}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col span={16}>
                        <FormInput
                          name="notes"
                          label={t('VehicleMntSchedules.View.Notes')}
                          as="textarea"
                          rows={5}
                        />
                      </Col>
                      <Col span={8}>
                        {gpioType && (
                          <FormInput
                            name="order_number"
                            label={t('VehicleMntSchedules.View.InternalOrderNumber')}
                          />
                        )}
                        <FormInput
                          name="raised_by"
                          label={t('VehicleMntSchedules.View.MaintenanceRaisedBy')}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row>
                      <FormLabel className={styles.customFormLabel}>
                        {t('VehicleMntSchedules.View.Documents')}
                      </FormLabel>
                    </Row>
                    <Row>
                      <Col span={16}>
                        <DocumentsDisplay
                          rows={renderUploadedFiles({
                            files,
                            currentUser: user,
                            dateFormat,
                            updateFilesCb,
                            dispatch
                          })}
                        />
                      </Col>
                      <Col span={8}>
                        <span className={styles.fileUpload}>
                          <FileUpload onAdd={handleAttachment} />
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.formFooter} ${showCertifyCheckbox ? styles.completeForm : ''}`}
              >
                {showCertifyCheckbox && (
                  <div className={styles.certifyCheckboxWrapper}>
                    <Checkbox checked={isCertifyCompleted} onChange={handleCheckboxChange}>
                      {t('Inspections.ConfirmationModal.ModalCertifyRepair')}
                    </Checkbox>
                  </div>
                )}
                <div>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    onClick={() => {
                      setFieldValue('saveAndReschedule', false);
                    }}
                    disabled={
                      !isValid || isSubmitting || (showCertifyCheckbox && !isCertifyCompleted)
                    }
                    id={BUTTON_IDS.vehicleMntSchedulesComplete}
                  >
                    {t('VehicleMntSchedules.View.Complete')}
                  </Button>

                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    onClick={() => {
                      setFieldValue('saveAndReschedule', true);
                    }}
                    disabled={
                      !isValid || isSubmitting || (showCertifyCheckbox && !isCertifyCompleted)
                    }
                    id={BUTTON_IDS.vehicleMntScheduleResch}
                  >
                    {t('VehicleMntSchedules.View.CompleteAndReschedule')}
                  </Button>
                  <Button
                    size="large"
                    onClick={handleCancel(dirty)}
                    id={BUTTON_IDS.vehicleMntScheduleCancel}
                  >
                    {t('Common.CancelButton')}
                  </Button>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default ScheduleComplete;
