import { useSelector } from 'react-redux';
import { ewdAPI } from 'features/ewd/endpoints/ewdAPI';
import { fetchEWDUserStart, fetchEWDUserSuccess, fetchEWDUserFailure } from './userSlice';
import { fetchKeepers } from 'features/ewd/components/keepers/keepersSlice';
import { useCan, services } from 'features/permissions';
import { useMemo } from 'react';
import { useUserData } from 'containers/Administration/Users/APICalls';

export const fetchEWDUser = () => (dispatch, getState) => {
  const userAssociations = getState().user.currentUserInfo?.associations;
  let ewdUserAssoc = userAssociations?.find(
    assoc => assoc.domain === 'EWD' && assoc.externalId.includes('user_id')
  );
  if (ewdUserAssoc) {
    try {
      ewdUserAssoc = JSON.parse(ewdUserAssoc.externalId);
      dispatch(fetchEWDUserStart());
      const req = ewdAPI.login(ewdUserAssoc.user_id, ewdUserAssoc.accessToken);
      req.then(user => {
        dispatch(fetchEWDUserSuccess(user));
        dispatch(fetchKeepers());
      });
    } catch (err) {
      dispatch(fetchEWDUserFailure(err.toString()));
    }
  }
};

export const useEWDUser = () => {
  const ewdUser = useSelector(state => state.user.currentEWDUser);
  return ewdUser;
};

export const useIsEWDDriver = driverId => {
  const can = useCan();
  const driverDetail = useUserData({ id: driverId, embedParam: 'associations' })?.userViewData;
  const isEWDDriver = useMemo(
    () =>
      can({ everyCompanyService: [services.EWD] }) &&
      !!driverDetail?.associations?.find(
        assoc => assoc?.domain === 'EWD' && assoc?.externalId?.includes('driver_id')
      ),
    [driverDetail]
  );
  return isEWDDriver;
};
