import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faCamera } from '@fortawesome/free-solid-svg-icons';
import { CameraDiagnosis } from './CameraDiagnosis';
import { DailySnapshotsLink } from './DailySnapshotsLink';
import { canDeviceBeDiagnosed } from '../../helpers';

const _SnapshotInfoCard = ({
  driverLoginStatus,
  deviceId,
  vehicleId,
  deviceName,
  nodeType,
  viewSnapshotHistoryTitle,
  deviceModelName,
  deviceTypeCode,
  devices
}) => (
  <>
    <div></div>
    <div>
      <>
        <span>
          <FontAwesomeIcon
            style={{ fontSize: '20px' }}
            color="#64748b"
            icon={nodeType === 'Vehicle' ? faCar : faCamera}
          />
        </span>
        <span>
          <div title={viewSnapshotHistoryTitle}>
            <DailySnapshotsLink
              entityId={nodeType === 'Vehicle' ? vehicleId : deviceId}
              entityName={deviceName}
              entityType={nodeType}
            />
          </div>
          <div>{driverLoginStatus}</div>
          <div>
            <CameraDiagnosis
              entityType={nodeType}
              diagnosableDevices={devices?.filter(
                device =>
                  device?.id && canDeviceBeDiagnosed(device?.model?.name, device?.type?.code)
              )}
              diagnosableDeviceId={
                canDeviceBeDiagnosed(deviceModelName, deviceTypeCode) ? deviceId : null
              }
            />
          </div>
        </span>
      </>
    </div>
  </>
);

export const SnapshotInfoCard = React.memo(_SnapshotInfoCard);
