import { API_PATH } from 'config';

import { AuthUtil } from 'features/auth/authUtil';

const resolveApi = async () => {
  const store = await import('store');
  const url = API_PATH + '/ewd';
  const jwt = store.default.getState().user?.currentEWDUser?.auth?.token;
  return {
    url: url,
    jwt: jwt,
    defaultParams: {
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`
      },
      redirect: 'follow',
      referrer: 'no-referrer'
    }
  };
};

const postData = async ({ url, defaultParams, data }) => {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    ...defaultParams
  });
  return response.json();
};

export const putData = async ({ url, defaultParams, data }) => {
  const response = await fetch(url, {
    ...defaultParams,
    method: 'PUT',
    body: JSON.stringify(data)
  });
  return response.json();
};

export const deleteData = async ({ url, defaultParams, data }) => {
  const response = await fetch(url, {
    method: 'DELETE',
    body: JSON.stringify(data),
    ...defaultParams
  });
  return response.json();
};

const get = async ({ url, defaultParams, data }, abortSignal) => {
  const response = await fetch(url, {
    method: 'GET',
    ...defaultParams,
    signal: abortSignal
  });
  if (!response.ok) {
    throw response.json();
  }
  return response.json();
};

export const ewdAPI = {
  login: async (userId, key) => {
    const api = await resolveApi();
    const uri = AuthUtil.generateEWDAuthUri(key, userId);

    return await get({
      url: `${api.url}/${uri}`,
      defaultParams: {}
    });
  },

  drivers: async (embed, abortController, keeperId) => {
    const api = await resolveApi();
    return await get(
      {
        url:
          `${api.url}/drivers?sort=first_name` +
          (embed ? '&embed=' + embed : '') +
          (keeperId ? '&keeper_id=' + keeperId : ''),
        defaultParams: api.defaultParams
      },
      abortController
    );
  },

  driver: async driverId => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/drivers/${driverId}`,
      defaultParams: api.defaultParams
    });
  },

  driverUpsert: async driver => {
    const api = await resolveApi();
    const response = await fetch(`${api.url}/drivers`, {
      method: 'POST',
      body: JSON.stringify(driver),
      ...api.defaultParams
    });
    const json = await response.json();
    if (response.status === 201) {
      return { message: 'New User', ...json };
    }
    return { message: 'Existing User', ...json };
  },
  driverUpdate: async driver => {
    const api = await resolveApi();
    const response = await fetch(`${api.url}/drivers/${driver.id}`, {
      method: 'PUT',
      body: JSON.stringify(driver),
      ...api.defaultParams
    });
    let json = null;
    try {
      json = await response.json();
    } catch (ex) {
      //
    }

    return { message: 'Updated', ...json };
  },
  userUpsert: async user => {
    const api = await resolveApi();
    const response = await fetch(`${api.url}/users`, {
      method: 'POST',
      body: JSON.stringify(user),
      ...api.defaultParams
    });
    const json = await response.json();
    if (response.status === 201) {
      return { message: 'New User', ...json };
    }
    return { message: 'Existing User', ...json };
  },

  changeDriverPassword: async user => {
    const api = await resolveApi();
    const response = await fetch(`${api.url}/auth/pwd?type=driver`, {
      method: 'POST',
      body: JSON.stringify(user),
      ...api.defaultParams
    });
    if (response.status === 200) {
      return { message: 'Password updated' };
    }
    return response.json();
  },

  // changeUserPassword: params => {
  //   params.referrer = 'teletracnavman.com';
  //   return API.EWD_API()
  //     .post('auth/pwd?type=user', params)
  //     .then(response => response.data);
  // };

  driverEvents: async (driverId, from, to) => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/drivers/${driverId}/events?from=${new Date(
        from
      ).toISOString()}&to=${new Date(to).toISOString()}&leading=true`,
      defaultParams: api.defaultParams
    });
  },

  driverViolations: async (driverId, from, to, checkpoint) => {
    const api = await resolveApi();
    //?userId=131006&fromDate=2020-08-19T14:00:00.000Z&toDate=2020-08-20T14:00:00.000Z&checkpointDate=0&incRests=true&incTotals=true
    return await get({
      url: `${api.url}/drivers/${driverId}/execute?from=${new Date(
        from
      ).toISOString()}&to=${new Date(to).toISOString()}${
        checkpoint !== 0 ? `&checkpointDate=${checkpoint}` : ''
      }&incRests=true&incTotals=true&predicted=true`,
      defaultParams: api.defaultParams
    });
  },

  driverAnnotations: async (driverId, from, to) => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/drivers/${driverId}/annotations?from=${new Date(
        from
      ).toISOString()}&to=${new Date(to).toISOString()}`,
      defaultParams: api.defaultParams
    });
  },

  getAudits: async (driverId, eventId) => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/drivers/${driverId}/events/${eventId}/audits`,
      defaultParams: api.defaultParams
    });
  },

  driverStatus: async (driverId, abortSignal) => {
    const api = await resolveApi();
    return await get(
      {
        //?c=1&ruleset=STD1
        url: `${api.url}/drivers/${driverId}/status`,
        defaultParams: api.defaultParams
      },
      abortSignal
    );
  },

  getCheckpoint: async driverId => {
    const api = await resolveApi();
    const response = await fetch(`${api.url}/drivers/${driverId}/checkpoint`, {
      method: 'GET',
      ...api.defaultParams
    });
    if (response.status === 204) {
      return 'No Checkpoint';
    }
    return response.json();
  },

  driverSearch: async searchString => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/drivers/search?query=${searchString}`,
      defaultParams: api.defaultParams
    });
  },

  keeperExport: async (keeperId, driverId, from, to, format) => {
    const config = { responseType: 'blob' };
    const api = await resolveApi();
    const response = await fetch(
      `${api.url}/keepers/${keeperId}/export?driver=${driverId}&from=${from}&to=${to}&format=${format}`,
      {
        method: 'GET',
        ...api.defaultParams,
        ...config
      }
    );
    return response;
  },

  driverExport: async (driverId, from, to, format) => {
    const config = { responseType: 'blob' };
    const api = await resolveApi();
    return await get({
      url: `${api.url}/drivers/${driverId}/export?from=${from}&to=${to}&format=${format}`,
      defaultParams: { ...api.defaultParams, ...config }
    });
  },

  addEvent: async (driverId, event) => {
    const api = await resolveApi();
    return await postData({
      url: `${api.url}/drivers/${driverId}/events`,
      defaultParams: api.defaultParams,
      data: event
    });
  },

  userDetails: async userId => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/users/${userId}`,
      defaultParams: api.defaultParams
    });
  },

  users: async () => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/users`,
      defaultParams: api.defaultParams
    });
  },

  keepers: async () => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/keepers`,
      defaultParams: api.defaultParams
    });
  },

  keepersForDriver: async driveId => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/keepers?driver=${driveId}`,
      defaultParams: api.defaultParams
    });
  },

  keeper: async keeperId => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/keepers/${keeperId}`,
      defaultParams: api.defaultParams
    });
  },

  providers: async () => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/providers`,
      defaultParams: api.defaultParams
    });
  },
  keeperRulesets: async keeperId => {
    const api = await resolveApi();
    return await get({
      url: `${api.url}/keepers/${keeperId}/rulesets`,
      defaultParams: api.defaultParams
    });
  }
};
