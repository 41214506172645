/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Role from '../model/Role';

/**
* Roles service.
* @module api/RolesApi
* @version 1.0
*/
export default class RolesApi {

    /**
    * Constructs a new RolesApi. 
    * @alias module:api/RolesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the create operation.
     * @callback module:api/RolesApi~createCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Role} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add a new role
     * A token key is required for this call.
     * @param {Object} opts Optional parameters
     * @param {module:model/Role} opts.body 
     * @param {module:api/RolesApi~createCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Role}
     */
    create(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Role;
      return this.apiClient.callApi(
        '/roles', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteRole operation.
     * @callback module:api/RolesApi~deleteRoleCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * A user token key is required for this call.
     * @param {Number} id 
     * @param {module:api/RolesApi~deleteRoleCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteRole(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteRole");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/roles/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getRole operation.
     * @callback module:api/RolesApi~getRoleCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Role} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * A user token key is required for this call.
     * @param {Number} id 
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'APP')
     * @param {module:api/RolesApi~getRoleCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Role}
     */
    getRole(id, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getRole");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'pruning': opts['pruning']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Role;
      return this.apiClient.callApi(
        '/roles/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getRoles operation.
     * @callback module:api/RolesApi~getRolesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Role>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * A user token key is required for this call.
     * @param {Object} opts Optional parameters
     * @param {String} opts.orderBy sort result by: name, company_id. default: name
     * @param {String} opts.direction sort direction: asc, desc. default: asc
     * @param {module:model/String} opts.pruning  (default to 'APP')
     * @param {module:api/RolesApi~getRolesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Role>}
     */
    getRoles(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'order_by': opts['orderBy'],
        'direction': opts['direction'],
        'pruning': opts['pruning']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Role];
      return this.apiClient.callApi(
        '/roles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the update operation.
     * @callback module:api/RolesApi~updateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Role} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a role
     * A token key is required for this call.
     * @param {Number} id 
     * @param {Object} opts Optional parameters
     * @param {module:model/Role} opts.body 
     * @param {module:api/RolesApi~updateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Role}
     */
    update(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling update");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Role;
      return this.apiClient.callApi(
        '/roles/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
