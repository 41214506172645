import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocalization } from 'features/localization/localizationSlice';
import { Layout } from 'antd';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import {
  fetchInspectionSummary,
  useInspectionSummary,
  useIsInspectionsFetching
} from 'features/inspectionChecklist/inspectionChecklistSlice';
import { LoadingSpinner } from 'components/tn/grid/LoadingSpinner';
import { InspectionSummaryTable } from './InspectionSummaryTable';
import { InspectionSummaryToolbar } from './InspectionSummaryToolbar';
import { ExportToExcel } from './ExportToExcel';
import { DateRangePicker } from 'components/ant';
import styles from '../VehicleMaintenanceSchedules.module.scss';
import dayjs from 'dayjs';

export const InspectionSummary = () => {
  const localization = useLocalization();
  const dateFormat = localization.formats.time.formats.dby;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const defaultDates = [dayjs().subtract(1, 'day'), dayjs()];
  const [selectedDates, setSelectedDates] = useState(defaultDates);
  const [dateFrom, setDateFrom] = useState(selectedDates[0].format(dateFormat));
  const [dateTo, setDateTo] = useState(selectedDates[1].format(dateFormat));
  const [datePicker, setDatePicker] = useState({
    isOpen: false,
    hasBeenOpened: false,
    haveDatesBeenChanged: false
  });

  const inspectionSummary = useInspectionSummary(dateFrom, dateTo);
  const isFetching = useIsInspectionsFetching();

  useEffect(() => {
    dispatch(setPageTitle(t('VehicleMntSchedules.Title')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(fetchInspectionSummary(dateFrom, dateTo));
  }, []);

  //date picker functionality, summary fetch
  useEffect(() => {
    const { isOpen, hasBeenOpened, haveDatesBeenChanged } = datePicker;
    if (!isOpen && hasBeenOpened && haveDatesBeenChanged) {
      dispatch(fetchInspectionSummary(dateFrom, dateTo));
      setDatePicker(prev => {
        return { ...prev, haveDatesBeenChanged: false };
      });
    }
  }, [datePicker, dispatch]);

  const handleDateRangeChange = newRange => {
    setSelectedDates(newRange);
    const formattedDates = newRange.map(date => date.format('YYYY-MM-DD'));
    setDateFrom(formattedDates[0]);
    setDateTo(formattedDates[1]);
    setDatePicker(prev => {
      return { ...prev, haveDatesBeenChanged: true };
    });
  };

  const handleDateRangeClose = isOpen => {
    setDatePicker(prev => {
      return { ...prev, isOpen, hasBeenOpened: true };
    });
  };

  return (
    <>
      {!isFetching ? (
        <>
          <div className={styles.datePickerStyle}>
            <DateRangePicker
              size="large"
              maxDayRange={7}
              availableDatesRange={[0, moment().endOf('day')]}
              format={localization.formats.time.formats.dby.toUpperCase()}
              defaultDates={[dayjs(dateFrom), dayjs(dateTo)]}
              onDateRangeChanged={dates => {
                handleDateRangeChange(dates);
              }}
              onOpenChange={handleDateRangeClose}
            />
            <ExportToExcel
              data={inspectionSummary}
              localization={localization}
              dateRange={[dayjs(dateFrom).format(dateFormat), dayjs(dateTo).format(dateFormat)]}
            />
          </div>
          <div>
            <InspectionSummaryToolbar filteredInspectionsCount={inspectionSummary?.length} />
          </div>
          <Layout>
            <InspectionSummaryTable inspectionData={inspectionSummary} />
          </Layout>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
