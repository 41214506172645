import { createAsyncThunk } from '@reduxjs/toolkit';
import { FETCH_CODES, UPDATE_CODE, ADD_CODE } from '../actions';
import { api } from 'utils/api';
import { parseUserErrorMessage } from 'utils/strings';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

export const fetchSmartJobsCodes = createAsyncThunk(
  FETCH_CODES,
  async (arg, { getState, rejectWithValue }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const company_id = state?.companies?.current?.id;

    try {
      const response = await api.get('/smartjobs/codes', {
        authKey,
        query: {
          company_id
        }
      });
      // Show toast
      return { body: response.body, companyId: company_id };
    } catch (error) {
      return rejectWithValue({ companyId: company_id });
    }
  }
);

export const updateCode = createAsyncThunk(
  UPDATE_CODE,
  async (payload = {}, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const company_id = state?.companies?.current?.id;
    const { id, body } = payload;
    try {
      const response = await api.put(
        `/smartjobs/codes/${id}`,
        {
          authKey,
          query: { company_id }
        },
        body
      );

      // update codes
      await dispatch(fetchSmartJobsCodes());

      return response.body;
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseUserErrorMessage(err)
        })
      );

      return rejectWithValue(parseUserErrorMessage(err));
    }
  }
);

export const addCode = createAsyncThunk(
  ADD_CODE,
  async (payload = {}, { getState, dispatch, rejectWithValue }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const company_id = state?.companies?.current?.id;
    const { body } = payload;

    try {
      const response = await api.post(
        `/smartjobs/codes`,
        {
          authKey,
          query: { company_id }
        },
        body
      );

      // update codes
      await dispatch(fetchSmartJobsCodes());
      return response.body;
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseUserErrorMessage(err)
        })
      );

      return rejectWithValue(parseUserErrorMessage(err));
    }
  }
);

export const deleteCode = createAsyncThunk(ADD_CODE, async (id, { getState, dispatch }) => {
  const state = getState();
  const authKey = state?.user?.current?.auth?.key;

  const response = await api.delete(`/smartjobs/codes/${id}`, {
    authKey
    // query: { company_id }
  });

  // update codes
  await dispatch(fetchSmartJobsCodes());

  return response.body;
});
