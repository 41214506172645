// https://github.com/awslabs/amazon-quicksight-embedding-sdk#-locale-string-optional

export const awsLocales = {
  en: 'en-US', // (English)
  da: 'da-DK', // (Dansk
  de: 'de-DE', // (Deutsch)
  es: 'es-ES', // (Español)
  fr: 'fr-FR', // (Français)
  it: 'it-IT', // (Italiano)
  nl: 'nl-NL', // (Nederlands)
  nb: 'nb-NO', // (Norsk)
  pt: 'pt-BR', // (Português)
  fi: 'fi-FI', // (Suomi)
  sv: 'sv-SE', // (Svenska)
  ja: 'ja-JP', // (日本語)
  ko: 'ko-KR', // (한국어)
  zh: 'zh-CN', // (中文 (简体))
  zht: 'zh-TW' // (中文 (繁體)
};

export const getAwsLocale = locale => {
  try {
    const language = locale.split('-')[0];
    return awsLocales[language];
  } catch {
    return locale;
  }
};
