/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Device from "./Device";
import Location from "./Location";
import VehicleType from "./VehicleType";

/**
 * The Vehicle model module.
 * @module model/Vehicle
 * @version 1.0
 */
class Vehicle {
  /**
   * Constructs a new <code>Vehicle</code>.
   * @alias module:model/Vehicle
   * @param registration {String}
   * @param registrationState {String}
   */
  constructor(registration, registrationState) {
    Vehicle.initialize(this, registration, registrationState);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, registration, registrationState) {
    obj["registration"] = registration;
    obj["registrationState"] = registrationState;
  }

  /**
   * Constructs a <code>Vehicle</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Vehicle} obj Optional instance to populate.
   * @return {module:model/Vehicle} The populated <code>Vehicle</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Vehicle();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("path")) {
        obj["path"] = ApiClient.convertToType(data["path"], "String");
      }
      if (data.hasOwnProperty("version")) {
        obj["version"] = ApiClient.convertToType(data["version"], "Number");
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("companyId")) {
        obj["companyId"] = ApiClient.convertToType(data["companyId"], "Number");
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = VehicleType.constructFromObject(data["type"]);
      }
      if (data.hasOwnProperty("registration")) {
        obj["registration"] = ApiClient.convertToType(
          data["registration"],
          "String"
        );
      }
      if (data.hasOwnProperty("registrationState")) {
        obj["registrationState"] = ApiClient.convertToType(
          data["registrationState"],
          "String"
        );
      }
      if (data.hasOwnProperty("registrationCountry")) {
        obj["registrationCountry"] = ApiClient.convertToType(
          data["registrationCountry"],
          "String"
        );
      }
      if (data.hasOwnProperty("vin")) {
        obj["vin"] = ApiClient.convertToType(data["vin"], "String");
      }
      if (data.hasOwnProperty("engineNumber")) {
        obj["engineNumber"] = ApiClient.convertToType(
          data["engineNumber"],
          "String"
        );
      }
      if (data.hasOwnProperty("make")) {
        obj["make"] = ApiClient.convertToType(data["make"], "String");
      }
      if (data.hasOwnProperty("model")) {
        obj["model"] = ApiClient.convertToType(data["model"], "String");
      }
      if (data.hasOwnProperty("note")) {
        obj["note"] = ApiClient.convertToType(data["note"], "String");
      }
      if (data.hasOwnProperty("externalId")) {
        obj["externalId"] = ApiClient.convertToType(
          data["externalId"],
          "String"
        );
      }
      if (data.hasOwnProperty("externalReference")) {
        obj["externalReference"] = ApiClient.convertToType(
          data["externalReference"],
          "String"
        );
      }
      if (data.hasOwnProperty("phone")) {
        obj["phone"] = ApiClient.convertToType(data["phone"], "String");
      }
      if (data.hasOwnProperty("engineSummarySource")) {
        obj["engineSummarySource"] = ApiClient.convertToType(
          data["engineSummarySource"],
          "String"
        );
      }
      if (data.hasOwnProperty("location")) {
        obj["location"] = Location.constructFromObject(data["location"]);
      }
      if (data.hasOwnProperty("devices")) {
        obj["devices"] = ApiClient.convertToType(data["devices"], [Device]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Vehicle.prototype["id"] = undefined;

/**
 * @member {String} name
 */
Vehicle.prototype["name"] = undefined;

/**
 * @member {String} path
 */
Vehicle.prototype["path"] = undefined;

/**
 * @member {Number} version
 */
Vehicle.prototype["version"] = undefined;

/**
 * @member {module:model/Vehicle.StatusEnum} status
 */
Vehicle.prototype["status"] = undefined;

/**
 * @member {Number} companyId
 */
Vehicle.prototype["companyId"] = undefined;

/**
 * @member {module:model/VehicleType} type
 */
Vehicle.prototype["type"] = undefined;

/**
 * @member {String} registration
 */
Vehicle.prototype["registration"] = undefined;

/**
 * @member {String} registrationState
 */
Vehicle.prototype["registrationState"] = undefined;

/**
 * @member {String} registrationCountry
 */
 Vehicle.prototype["registrationCountry"] = undefined;

/**
 * @member {String} vin
 */
Vehicle.prototype["vin"] = undefined;

/**
 * @member {String} engineNumber
 */
Vehicle.prototype["engineNumber"] = undefined;

/**
 * @member {String} make
 */
Vehicle.prototype["make"] = undefined;

/**
 * @member {String} model
 */
Vehicle.prototype["model"] = undefined;

/**
 * @member {String} note
 */
Vehicle.prototype["note"] = undefined;

/**
 * @member {String} externalId
 */
Vehicle.prototype["externalId"] = undefined;

/**
 * @member {String} externalReference
 */
Vehicle.prototype["externalReference"] = undefined;

/**
 * @member {String} phone
 */
Vehicle.prototype["phone"] = undefined;

/**
 * @member {module:model/Vehicle.EngineSummarySourceEnum} engineSummarySource
 */
Vehicle.prototype["engineSummarySource"] = undefined;

/**
 * @member {module:model/Location} location
 */
Vehicle.prototype["location"] = undefined;

/**
 * @member {Array.<module:model/Device>} devices
 */
Vehicle.prototype["devices"] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Vehicle["StatusEnum"] = {
  /**
   * value: "ENABLED"
   * @const
   */
  ENABLED: "ENABLED",

  /**
   * value: "DISABLED"
   * @const
   */
  DISABLED: "DISABLED",

  /**
   * value: "READY_FOR_DOWNLOAD"
   * @const
   */
  READY_FOR_DOWNLOAD: "READY_FOR_DOWNLOAD",

  /**
   * value: "PENDING"
   * @const
   */
  PENDING: "PENDING",

  /**
   * value: "AVAILABLE"
   * @const
   */
  AVAILABLE: "AVAILABLE",

  /**
   * value: "UNAVAILABLE"
   * @const
   */
  UNAVAILABLE: "UNAVAILABLE",

  /**
   * value: "FAILED"
   * @const
   */
  FAILED: "FAILED"
};

/**
 * Allowed values for the <code>engineSummarySource</code> property.
 * @enum {String}
 * @readonly
 */
Vehicle["EngineSummarySourceEnum"] = {
  /**
   * value: "CAN"
   * @const
   */
  CAN: "CAN",

  /**
   * value: "CAN_DIFF"
   * @const
   */
  CAN_DIFF: "CAN_DIFF",

  /**
   * value: "GPS_DIFF"
   * @const
   */
  GPS_DIFF: "GPS_DIFF"
};

export default Vehicle;
