import React, { useEffect, useState } from 'react';
import { Card, Tag, Space, Input, Skeleton, Alert } from 'antd';
import styles from './Marketplace.module.scss';
import { useHistory } from 'react-router';
import useDebounce from 'utils/hooks/useDebounce';
import { SearchOutlined } from '@ant-design/icons';
import { buildIcon, cardLoaderDimensions, searchLoaderDimensiunes } from './../constants';
import { useTranslation } from 'react-i18next';

const { Meta } = Card;

export const Marketplace = ({ cardsData, isLoading, activeTab, activeIntegrationsTab = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const cardsCategories = [...new Set(cardsData?.map(card => card.categories).flat())];
  const [filteredCardsData, setFilteredCardsData] = useState(cardsData);
  const [searchValue, setSearchValue] = useState(null);
  const debouncedSearchValue = useDebounce(searchValue, 300);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleOverviewButton = adapter => () => {
    history.push(`/integrations/configuration/documentation/${adapter.type}`, { activeTab });
  };

  const handleFilteredData = (cardsData, selectedCategories, debouncedSearchValue) => {
    if (!cardsData) {
      return;
    }

    const filteredData = cardsData.reduce((acc, curr) => {
      if (selectedCategories.length === 0) {
        acc.push(curr);
      }

      // filter by category
      if (selectedCategories.some(sc => curr.categories.includes(sc))) {
        acc.push(curr);
      }

      // filter by search
      if (debouncedSearchValue) {
        acc = acc.filter(
          data =>
            data.name?.toLowerCase().includes(debouncedSearchValue.toLowerCase()) ||
            data.description?.toLowerCase().includes(debouncedSearchValue.toLowerCase())
        );
      }

      return acc;
    }, []);

    setFilteredCardsData(filteredData);
  };

  const handleCategoryChange = (category, checked) => {
    if (category === 'All') {
      setSelectedCategories([]);
    } else {
      const nextSelectedCategories = checked
        ? [...selectedCategories, category]
        : selectedCategories.filter(c => c !== category);
      setSelectedCategories(nextSelectedCategories);
    }
  };

  const onSearch = value => setSearchValue(value);

  useEffect(() => {
    handleFilteredData(cardsData, selectedCategories, debouncedSearchValue);
  }, [selectedCategories, debouncedSearchValue]);

  useEffect(() => {
    setFilteredCardsData(cardsData);
  }, [cardsData]);

  if (isLoading) {
    return (
      <div className={styles.skeletonLoader}>
        <div>
          <Skeleton.Input style={searchLoaderDimensiunes} active />
        </div>
        {[...Array(9)].map((_, key) => (
          <Skeleton.Input key={key} style={cardLoaderDimensions} active />
        ))}
      </div>
    );
  }

  if (activeIntegrationsTab && !cardsData?.length) {
    return (
      <div className={styles.noActiveIntegrations}>
        <Alert type="info" message={t('Integrations.NoActiveIntegrations')} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Input
        className={styles.searchInput}
        prefix={<SearchOutlined className={styles.searchIcon} />}
        placeholder={t('Common.Search')}
        onChange={e => onSearch(e.target.value)}
      />
      <Space className={styles.categoriesList} size={8}>
        <Tag.CheckableTag
          checked={selectedCategories?.length === 0}
          onChange={checked => handleCategoryChange('All', checked)}
        >
          {t('Common.All')}
        </Tag.CheckableTag>
        {cardsCategories.map(category => (
          <Tag.CheckableTag
            checked={selectedCategories?.includes(category)}
            onChange={checked => handleCategoryChange(category, checked)}
            key={category}
          >
            {category}
          </Tag.CheckableTag>
        ))}
      </Space>
      <Space className={styles.cardsList} size={16}>
        {filteredCardsData &&
          filteredCardsData.map(card => {
            return (
              <Card hoverable onClick={handleOverviewButton(card)} key={card.type}>
                <Meta
                  description={
                    <div className={styles.card}>
                      <div className={styles.header}>
                        <div className={styles.logo}>
                          {card.icon && <img alt="cardIcon" src={buildIcon(card.icon)} />}
                        </div>
                      </div>
                      <div className={styles.details}>
                        <div className={styles.title} title={card.name}>
                          {card.name}
                        </div>
                        <div className={styles.description}>{card.description}</div>
                      </div>
                      <div className={styles.footer}>
                        <div>
                          {Array.isArray(card.categories) &&
                            card.categories?.map(category => <Tag key={category}>{category}</Tag>)}
                        </div>
                        <Tag
                          className={
                            card.inbound.length > 0 || card.outbound.length > 0
                              ? styles.active
                              : styles.inactive
                          }
                        >
                          {card.inbound.length > 0 || card.outbound.length > 0
                            ? t('Integrations.Active')
                            : t('Integrations.Inactive')}
                        </Tag>
                      </div>
                    </div>
                  }
                ></Meta>
              </Card>
            );
          })}
      </Space>
    </div>
  );
};
