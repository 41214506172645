import React, { useMemo } from 'react';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { VisualizationCard } from './VisualizationCard';

import styles from './Glossary.module.scss';

export const Glossary = ({ category }) => {
  const { t } = useTranslation();
  const { title, items } = useMemo(() => {
    const categoryGuides = t(`CameraHealth.Guides.${category}`, {
      returnObjects: true
    });
    if (typeof categoryGuides !== 'object') {
      return { title: null, items: [] };
    }
    const visibleCategoryGuides =
      categoryGuides?.categoryTroubleshootingGuides?.filter?.(guide => !guide.hide) || [];
    return {
      title: categoryGuides?.categoryTitle?.[0],
      items: visibleCategoryGuides.map((guide, index) => {
        const getText = summary => {
          if (summary instanceof Array) {
            return summary.map((sum, idx) => (
              <React.Fragment key={idx}>{getText(sum)}</React.Fragment>
            ));
          } else if (typeof summary === 'object') {
            const [subTitle, ...contents] = Object.values(summary);
            return (
              <div className={styles.subText}>
                <h4 className={styles.subText}>{subTitle}</h4>
                {contents.map((content, idx) => (
                  <p key={`${subTitle}-${idx}`}>{getText(content)}</p>
                ))}
              </div>
            );
          }
          return summary;
        };
        return {
          key: index,
          label: guide.guide,
          children:
            index === 0 ? (
              <div>
                {guide.summary.map((sum, idx) => (
                  <React.Fragment key={`${guide.guide}-${idx}`}>{getText(sum)}</React.Fragment>
                ))}
              </div>
            ) : (
              <ol>
                {guide.summary.map((sum, idx) => (
                  <li className={styles.subText} key={`${guide.guide}-${idx}`}>
                    {getText(sum)}
                  </li>
                ))}
              </ol>
            )
        };
      })
    };
  }, [t, category]);

  return title ? (
    <VisualizationCard
      title={t('CameraHealth.Glossary')}
      subTitle={title}
      content={
        <div className={styles.glossary}>
          <Collapse accordion ghost expandIconPosition={'end'} items={items} />
        </div>
      }
    />
  ) : null;
};
