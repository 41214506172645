import { createAsyncThunk } from '@reduxjs/toolkit';

import { REMOVE_JOB, DELETE_ITEM } from '../actions';

import { NODE_API_PATH } from 'config';

import { api } from 'utils/api';
import { getRunsheetById } from '../smartJobsSlice';
import { DEFAULT_PREFERENCES } from 'containers/Settings/constants';

const removeJobUrl = (runsheetId, jobId) => `/runsheets/${runsheetId}/jobs/${jobId}/remove`;

export const removeJob = createAsyncThunk(REMOVE_JOB, async (payload, { getState, dispatch }) => {
  const state = getState();
  const authKey = state?.user?.current?.auth?.key;
  const company_id = state?.companies?.current?.id;
  const { runsheetId, jobId } = payload;

  const response = await api.post(
    removeJobUrl(runsheetId, jobId),
    {
      authKey,
      query: {
        company_id
      }
    },
    {}
  );

  // refetch the runsheet
  await dispatch(getRunsheetById(runsheetId));

  return response.body;
});

export const fetchJobPdf = (job, localization = {}) => async (_, getState) => {
  const pdfUrl = `${NODE_API_PATH || ''}pdf`;
  const userKey = getState().user.current.auth.key;
  const currentUserTimezone = getState().user.currentUserInfo.timeZone;
  const companyId = getState().companies.current.id;
  const lang = localization.locale || DEFAULT_PREFERENCES.language;

  const response = await fetch(
    `${pdfUrl}?company_id=${companyId}&lang=${lang}&runsheetId=${job?.runsheet?.id}&jobId=${job?.id}&dateFormat=${localization.formats.time.formats.dby_imsp}&userTimezone=${currentUserTimezone}`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token token="${userKey}"`,
        'Base-Api-Url': process.env.REACT_APP_API_PATH
      },
      redirect: 'follow',
      referrer: 'no-referrer'
    }
  );

  return response.blob();
};

export const deleteItem = createAsyncThunk(DELETE_ITEM, async (payload, { getState, dispatch }) => {
  const state = getState();
  const authKey = state?.user?.current?.auth?.key;
  const { runsheetId, itemId, jobId } = payload;

  const response = await api.delete(`/runsheets/${runsheetId}/jobs/${jobId}/items/${itemId}`, {
    authKey
  });

  // refetch the runsheet
  await dispatch(getRunsheetById(runsheetId));

  return response.body;
});
