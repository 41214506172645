import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { VehicleGPIO } from 'containers/Administration/Vehicles/VehicleGPIO';

export const GPIOModal = ({ visible, showModal, vehicleId, vehicleName }) => {
  const { t } = useTranslation();

  const handleCancel = () => {
    showModal(false);
  };

  return (
    <Modal
      title={`${t('Common.EllipsisButton.GPIO')} - ${vehicleName}`}
      open={visible}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText={t('Common.Modal.Close')}
      onCancel={handleCancel}
      maskClosable={false}
      centered
      width={1300}
      bodyStyle={{ padding: '0', maxHeight: '795px', overflow: 'scroll' }}
    >
      <VehicleGPIO id={vehicleId} isModal={true} />
    </Modal>
  );
};
