import React from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import { setViolationId } from 'features/sentinel/components/events/eventsSlice';
import debounce from 'lodash/debounce';

class SentinelViolations extends React.Component {
  debouncedHover = debounce((action, Id) => {
    if (action === 'enter') {
      this.props.setViolationId(`show#!${Id}`);
    }

    if (action === 'leave') {
      this.props.setViolationId(`hide#!${Id}`);
    }
  }, 100);

  hoverHandler = (action, Id) => {
    this.debouncedHover(action, Id);
  };

  render() {
    const violations = this.props.violations;
    const checkpoint = this.props.checkpoint;
    const violationsList = violations ? violations.violations : [];
    return (
      <Card>
        <Card.Header>
          <h4 className="h5 m-0 p-2">Violations</h4>
        </Card.Header>
        <Card.Body className="card-body-violation">
          {violationsList.length > 0 &&
            violationsList.map((violation, index) => {
              const checkpointOrNow = checkpoint ? checkpoint : new Date().valueOf();
              const alertLevel =
                violation.start < checkpointOrNow ? 'alert-danger' : 'alert-warning';
              return (
                <div
                  key={index}
                  className={'card-body-violation-item alert ' + alertLevel}
                  onMouseEnter={() => this.hoverHandler('enter', index)}
                  onMouseLeave={() => this.hoverHandler('leave', index)}
                >
                  <h6>{violation.rule.description}</h6>
                  <p>
                    <small>{violation.rule.id}</small>
                    <br />
                    <span className="vdetail">{violation.detail}</span>
                  </p>
                </div>
              );
            })}
          {!violationsList.length && (
            <Row className="justify-content-md-center">
              <Col className="p-3" xs="auto">
                <p>No violations in this time period!</p>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  violations: state.sentinel.violations,
  checkpoint: state.sentinel.checkpoint,
  sentinel: state.sentinel
});

export default connect(mapStateToProps, { setViolationId })(SentinelViolations);
