import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './salesforceBar.module.scss';

export function SalesforceBar({ ...props }) {
  const timeHandle = useRef(null);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [init, setInit] = useState(false);
  const [tick, setTick] = useState(0);

  const handleSaleforceAfterMaximize = useCallback(() => {
    setShow(false);
    setUnreadMessageCount(0);
  }, []);

  const handleSaleforceAfterMinimize = useCallback(() => {
    setShow(true);
  }, []);

  const attachingSalesforce = useCallback(() => {
    if (!init) {
      if (window.embedded_svc) {
        window.embedded_svc.addEventHandler('afterMaximize', handleSaleforceAfterMaximize);
        window.embedded_svc.addEventHandler('afterMinimize', handleSaleforceAfterMinimize);
        setInit(true);
      } else {
        setTick(Date.now());
      }
    }
  }, [init]);

  const handleAgentMessage = useCallback(
    data => {
      if (show) {
        setUnreadMessageCount(prev => prev + 1);
      }
    },
    [show]
  );

  const handleResumeChat = useCallback(() => {
    const saleforceMinButton = document.querySelector('.minButton.cChatSalesSupport');
    if (saleforceMinButton) {
      saleforceMinButton.click();
    }
  }, []);

  useEffect(() => {
    timeHandle.current = setTimeout(attachingSalesforce, 2000);
    return () => {
      if (timeHandle.current) {
        clearTimeout(timeHandle.current);
        timeHandle.current = null;
      }
    };
  }, [attachingSalesforce, tick]);

  useEffect(() => {
    window.onAgentMessage = handleAgentMessage;
    return () => {
      window.onAgentMessage = null;
    };
  }, [handleAgentMessage]);

  const style = {
    display: show ? 'block' : 'none'
  };

  return (
    <div className={styles.salesforceBar} style={style} onClick={handleResumeChat}>
      {unreadMessageCount > 0
        ? t('Messaging.UnreadMessagesCount', { count: unreadMessageCount })
        : t('Common.Resume Chat')}
    </div>
  );
}
