import React from 'react';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import { useVehicle } from 'features/fleets/fleetsSlice';
import { useTranslation } from 'react-i18next';

export function VehicleMaintenanceScheduleRender({ data, alertData, ...props }) {
  const vehicle = useVehicle(data?.event?.schedule?.vehicle?.id);
  const localization = useLocalization();
  const { t } = useTranslation();
  return (
    <>
      <span> {t('Notifications.ForVehicle')} </span>
      <strong>{vehicle?.name}</strong>
      <span>. {t('Notifications.ScheduleName')}: </span>
      <strong>{data?.event?.schedule?.name}</strong>
      <span>.</span>
      <p>
        <span> {t('Notifications.ScheduledBy')} </span>
        {data?.event?.user && data?.event?.user && (
          <strong> {data.event.user.firstName + ' ' + data?.event.user.lastName} </strong>
        )}
        {!data?.event?.user && data?.event?.schedule?.source && (
          <strong> {data.event.schedule.source} </strong>
        )}

        {data?.event?.schedule?.schedulesOn && (
          <>
            <span> {t('Notifications.ForThe')} </span>
            <span>
              {format(
                new Date(data?.event?.schedule?.schedulesOn),
                localization.formats.time.formats.dmY
              )}
            </span>
          </>
        )}
      </p>
    </>
  );
}
