import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

export const confirmNavigateAway = (conditional, onLeave, onStay, t) => {
  conditional
    ? confirmationModal(
        t('RouteGuard.Title'),
        t('RouteGuard.Message'),
        t('RouteGuard.LeavePage'),
        t('RouteGuard.StayOnPage'),
        onLeave,
        'warning',
        onStay
      )
    : onLeave();
};
