import React, { useState } from 'react';
import EWDAddEditFormik from './EWDAddEditFormik';
import EWDAnnotations from './annotations/EWDAnnotations';
import EWDTable from './events/EWDTable';
import { Button, Card, Badge } from 'antd';
import { AutoSizer } from 'react-virtualized';
import { useAnnotations } from './annotations/annotationsSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

export const EventsAndAnnotationsTabs = () => {
  const [activeTab, setActiveTab] = useState('events');
  const [openAddForm, _setOpenAddForm] = useState(false);
  const annotationsCount = useAnnotations()?.length ?? 0;

  const setOpenAddForm = openAddForm => {
    _setOpenAddForm(openAddForm);
    if (openAddForm) {
      setActiveTab('events');
    }
  };

  const AnnotationsLabel = (
    <Badge count={annotationsCount} offset={[10, 0]}>
      Annotations
    </Badge>
  );

  const tabList = [
    {
      key: 'events',
      tab: 'Events'
    },
    {
      key: 'annotations',
      tab: AnnotationsLabel
    }
  ];

  const tabContent = {
    events: (
      <AutoSizer>
        {({ height, width }) => (
          <div
            className="showScrollbarsOnHover"
            style={{ height, width, overflow: 'scroll', padding: '24px' }}
          >
            {openAddForm && <EWDAddEditFormik setOpen={open => setOpenAddForm(open)} />}{' '}
            <EWDTable />
          </div>
        )}
      </AutoSizer>
    ),
    annotations: (
      <AutoSizer>
        {({ height, width }) => (
          <div
            className="showScrollbarsOnHover"
            style={{ height, width, overflow: 'scroll', padding: '24px' }}
          >
            <EWDAnnotations />
          </div>
        )}
      </AutoSizer>
    )
  };
  return (
    <Card
      style={{ width: '100%', height: '100%', overflow: 'hidden' }}
      tabList={tabList}
      activeTabKey={activeTab}
      tabBarExtraContent={
        <Button
          onClick={() => setOpenAddForm(!openAddForm)}
          aria-expanded={openAddForm}
          id={BUTTON_IDS.eventsAnnotationsAdd}
        >
          Add Event
        </Button>
      }
      onTabChange={key => {
        setActiveTab(key);
      }}
      bodyStyle={{
        width: '100%',
        height: 'calc(100% - 56px)',
        overflow: 'hidden',
        padding: '0'
      }}
    >
      {tabContent[activeTab]}
    </Card>
  );
};
