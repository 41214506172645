import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { Button, Space, Affix } from 'antd';
import { Table } from 'components/ant';
import InfoRow from 'components/form/info-row/InfoRow';
import FormTitle from 'components/form/form-title/FormTitle';
import { LoadingCentered } from 'components/loading/Loading';
import { DriverManagementCancelModal } from './DriverManagementCancelModal';

import {
  useScheduleById,
  fetchScheduleById,
  deleteSchedule,
  restoreSchedule,
  useIsScheduleByIdFetching
} from 'features/driverManagement';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { getIDFromPathname } from 'utils/methods';
import { useLocalization } from 'features/localization/localizationSlice';
import { downloadDocument } from 'features/easydocs/documentsSlice';
import { useCan, Can, entities } from 'features/permissions';
import {
  getUser,
  getMntType,
  getScheduleDate,
  formatCompletionDate,
  prepareColumnsForEvents,
  prepareDataForEvents,
  showButton,
  handleDeleteScheduleModal
} from './helpers';
import { formatStatus } from '../VehicleMaintenance/utils/helpers';
import { Paths, BUTTON_ACTIONS, STATUS } from './constants';

import styles from './DriverManagement.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const DriverManagementView = ({ users, mgtTypes, subCompanies, drivers, branches }) => {
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const dispatch = useDispatch();
  const can = useCan();
  const isFetching = useIsScheduleByIdFetching();
  const history = useHistory();
  const localization = useLocalization();
  const scheduleData = useScheduleById(id);
  const company = subCompanies.find(company => company.id === scheduleData?.companyId);
  const driver = drivers.find(driver => driver.id === scheduleData?.entityId);
  const branch = driver?.location?.id && branches.find(branch => branch.id === driver.location.id);
  const scheduledFor = getScheduleDate(scheduleData?.schedulesOn, localization);
  const driverName = driver && `${driver.firstName || ''} ${driver.lastName || ''}`;
  const completionParams = scheduleData?.completionParameters
    ? JSON.parse(scheduleData.completionParameters)
    : null;
  const cancellationParams = scheduleData?.cancellationParameters
    ? JSON.parse(scheduleData.cancellationParameters)
    : null;
  const [cancelModalVisibility, setCancelModalVisibility] = useState(false);

  useEffect(() => {
    dispatch(setPageTitle(isFetching ? '' : scheduleData?.name));
    dispatch(setBackButton(true));
  }, [isFetching, scheduleData?.name, dispatch]);

  const handleButtonAction = ({ action }) => () => {
    switch (action) {
      case 'delete':
        dispatch(deleteSchedule({ data: scheduleData, id: scheduleData.id, history }));
        break;
      case 'restore':
        dispatch(restoreSchedule({ data: scheduleData, refetchById: true }));
        break;
      default:
    }
  };

  useEffect(() => {
    dispatch(fetchScheduleById({ id, query: { embed: 'events' } }));
  }, [id, dispatch]);

  const renderViewHeader = () => {
    return (
      <Affix offsetTop={63}>
        <div className={styles.scheduleHeader}>
          <div className={styles.statusWrapper}>
            <div className={styles.scheduleName}>{scheduleData.name}</div>
            <div>
              <span
                className={`${styles.scheduleStatus} ${
                  styles[`status_${(scheduleData?.status || '').toLowerCase()}`]
                }`}
              ></span>
              {t(`VehicleMntSchedules.${formatStatus(scheduleData.status)}`, '')}
            </div>
          </div>
          <div>
            {scheduleData?.status && (
              <Space size={16}>
                {showButton({ buttonType: BUTTON_ACTIONS.COMPLETE, data: scheduleData }) && (
                  <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_UPDATE]}>
                    <Button size="large" id={BUTTON_IDS.driverMgtViewComplete}>
                      <Link to={`${Paths.COMPLETE_DRIVERMANAGEMENTSCHEDULE}/${scheduleData.id}`}>
                        {scheduleData?.status === STATUS.completed
                          ? t('Common.EllipsisButton.EditCompletedInfo')
                          : t('Common.EllipsisButton.Complete')}
                      </Link>
                    </Button>
                  </Can>
                )}
                {showButton({ buttonType: BUTTON_ACTIONS.EDIT, data: scheduleData }) && (
                  <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_UPDATE]}>
                    <Button size="large" id={BUTTON_IDS.driverMgtViewEdit}>
                      <Link to={`${Paths.EDIT_DRIVERMANAGEMENTSCHEDULE}/${scheduleData.id}`}>
                        {t('Common.EditButton')}
                      </Link>
                    </Button>
                  </Can>
                )}
                {showButton({ buttonType: BUTTON_ACTIONS.CANCEL, data: scheduleData }) && (
                  <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_UPDATE]}>
                    <Button
                      size="large"
                      danger
                      onClick={() => setCancelModalVisibility(!cancelModalVisibility)}
                      id={BUTTON_IDS.driverMgtViewCancel}
                    >
                      {scheduleData?.status === STATUS.cancelled
                        ? t('VehicleMntSchedules.View.EditCancelledInfo')
                        : t('Common.Cancel')}
                    </Button>
                  </Can>
                )}
                {showButton({ buttonType: BUTTON_ACTIONS.AUDIT, data: scheduleData }) && (
                  <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_AUDIT]}>
                    <Button size="large" id={BUTTON_IDS.driverMgtViewAudits}>
                      <Link to={`${Paths.AUDITS_DRIVERMANAGEMENTSCHEDULE}/${scheduleData.id}`}>
                        {t('Common.AuditsButton')}
                      </Link>
                    </Button>
                  </Can>
                )}
                {showButton({ buttonType: BUTTON_ACTIONS.RESTORE, data: scheduleData }) && (
                  <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_RESTORE]}>
                    <Button
                      size="large"
                      id={BUTTON_IDS.driverMgtViewRestore}
                      onClick={handleButtonAction({ action: 'restore' })}
                    >
                      {t('Common.RestoreButton')}
                    </Button>
                  </Can>
                )}
                {showButton({ buttonType: BUTTON_ACTIONS.DELETE, data: scheduleData }) && (
                  <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_DESTROY]}>
                    <Button
                      size="large"
                      danger
                      id={BUTTON_IDS.driverMgtViewDelete}
                      onClick={() => {
                        handleDeleteScheduleModal({ scheduleData, handleButtonAction });
                      }}
                    >
                      {t('Common.DeleteButton')}
                    </Button>
                  </Can>
                )}
              </Space>
            )}
          </div>
        </div>
      </Affix>
    );
  };

  const handleCancelModal = () => {
    setCancelModalVisibility(false);
  };

  const handleDocumentDownload = document => () => dispatch(downloadDocument(document.id));

  // Get the documents attached to this schedule
  const getScheduleDocuments = () => {
    const { documents } = completionParams;

    if (!documents || !documents.length) return;

    return documents.map(document => (
      <div
        className={styles.linkStyle}
        key={document.id}
        onClick={handleDocumentDownload(document)}
      >
        {document.filename || document.id}
      </div>
    ));
  };

  if (isFetching) {
    return <LoadingCentered />;
  }

  return (
    <>
      <DriverManagementCancelModal
        isVisible={cancelModalVisibility}
        handleCancel={handleCancelModal}
        scheduleData={scheduleData}
        cancelReason={cancellationParams?.reason || ''}
      />
      {renderViewHeader()}
      <div className={styles.container}>
        <FormTitle title={t('DriverManagement.Form.TitleInfo')} underlined />
        <InfoRow
          label={t('DriverManagement.Form.Name')}
          value={scheduleData?.name || '-'}
          styles={styles}
        />
        <InfoRow label={t('Common.Company')} value={company?.name || '-'} styles={styles} />
        <InfoRow
          label={t('DriverManagement.Form.Driver')}
          value={driverName || '-'}
          styles={styles}
        />
        <InfoRow label={t('Common.Branch')} value={branch?.name || '-'} styles={styles} />
        <InfoRow
          label={t('Common.Type')}
          value={getMntType(mgtTypes, scheduleData?.manageTypeId, can) || '-'}
          styles={styles}
        />
        <InfoRow
          label={t('DriverManagement.Form.ScheduledFor')}
          value={scheduledFor}
          styles={styles}
        />
        <InfoRow
          label={t('DriverManagement.Form.ScheduledBy')}
          value={getUser(users, scheduleData?.userId) || '-'}
          styles={styles}
        />
        {completionParams && (
          <>
            <FormTitle title={t('DriverManagement.Form.TitleCompleted')} underlined />
            <InfoRow
              label={t('DriverManagement.Form.CompletionDate')}
              value={formatCompletionDate(completionParams?.completed_on, localization)}
              styles={styles}
            />
            <InfoRow
              label={`${t('DriverManagement.Form.Cost')} (${localization.formats.currency.unit})`}
              value={completionParams?.cost}
              styles={styles}
            />
            <InfoRow
              label={t('DriverManagement.Form.InvoiceNumber')}
              value={completionParams?.invoice_number}
              styles={styles}
            />
            <InfoRow
              label={t('DriverManagement.Form.RaisedBy')}
              value={getUser(users, scheduleData?.userId) || '-'}
              styles={styles}
            />
            <InfoRow
              label={t('DriverManagement.Form.SignedOffBy')}
              value={completionParams?.signed_off_by}
              styles={styles}
            />
            <InfoRow
              label={t('DriverManagement.Form.CompletedBy')}
              value={completionParams?.completed_by}
              styles={styles}
            />
            <InfoRow
              label={t('DriverManagement.Form.Notes')}
              value={completionParams?.notes}
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.Documents')}
              value={getScheduleDocuments(scheduleData)}
              styles={styles}
            />
          </>
        )}
        {cancellationParams && (
          <>
            <FormTitle title={t('DriverManagement.Form.TitleCancelled')} underlined />
            <InfoRow
              label={t('DriverManagement.Form.CancelledAt')}
              value={getScheduleDate(cancellationParams?.time_at, localization)}
              styles={styles}
            />
            <InfoRow
              label={t('DriverManagement.Form.CancelledBy')}
              value={getUser(users, cancellationParams?.user_id) || '-'}
              styles={styles}
            />
            <InfoRow
              label={t('DriverManagement.Form.Reason')}
              value={cancellationParams?.reason}
              styles={styles}
            />
          </>
        )}

        <FormTitle title={t('DriverManagement.Form.TitleEvents')} underlined />
        <Table
          dataSource={prepareDataForEvents({
            events: scheduleData?.events || [],
            t,
            localization
          })}
          columns={prepareColumnsForEvents(t)}
          pagination={false}
        />
      </div>
    </>
  );
};
