import React from 'react';
import { Table } from 'antd';
import { LogExportGridColumns, LogExportSubGridColumns } from './GridColumns';
import styles from './LogExport.module.scss';

export function LogExportExpandIcon(props) {}
export function LogExportStatusGrid(record) {
  return (
    <Table
      dataSource={record.transferStatus}
      tableLayout="fixed"
      columns={LogExportSubGridColumns}
      pagination={false}
      sticky={true}
    />
  );
}

export function LogExportGrid({ data, ...props }) {
  return (
    <Table
      className={styles.logExportGrid + ' showScrollbarsOnHover'}
      showSorterTooltip={false}
      dataSource={data}
      columns={LogExportGridColumns}
      pagination={false}
      sticky={true}
    />
  );
}
