export const getMapObjectByProperty = (array, property) => {
  return array.reduce((accumulator, element) => {
    if (element[property]) {
      accumulator[element[property]] = element;
    }
    return accumulator;
  }, {});
};

export const getEnum = (array, key, value) => {
  return array.reduce((accumulator, element) => {
    accumulator[element[key]] = element[value];
    return accumulator;
  }, {});
};
