import React, { useState, useRef, useLayoutEffect } from 'react';
import { TreeSelect as AntTreeSelect } from 'antd';
import styles from './TreeSelect.module.scss';

export const { SHOW_PARENT, SHOW_CHILD } = AntTreeSelect;

export const TreeSelect = ({
  treeData,
  treeCheckable,
  placeholder,
  showCheckedStrategy,
  ...props
}) => {
  const timeoutRef = useRef();
  const dropdownContainer = React.createRef();

  const [showScrollBar, setShowScrollBar] = useState(false);
  const checkScrollBar = dropdownContainer => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const wrapper = dropdownContainer.current?.getElementsByClassName(
        'ant-select-tree-list-holder'
      )[0];
      const wrapperHeight = wrapper && wrapper.clientHeight;
      if (wrapperHeight) {
        const inner = wrapper.firstElementChild;
        const innerHeight = inner && inner.clientHeight;
        if (innerHeight) {
          setShowScrollBar(innerHeight > wrapperHeight);
        }
      }
    });
  };
  useLayoutEffect(() => {
    return () => {
      if (timeoutRef.current != null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  return (
    <AntTreeSelect
      {...props}
      treeData={treeData || []}
      popupClassName={showScrollBar ? styles.treeSelect : ''}
      showCheckedStrategy={showCheckedStrategy || SHOW_PARENT}
      treeCheckable={treeCheckable || true}
      placeholder={placeholder || ''}
      dropdownRender={originNode => (
        <div
          ref={dropdownContainer}
          onClick={e => checkScrollBar(dropdownContainer)}
          onMouseOver={e => checkScrollBar(dropdownContainer)}
        >
          {props.dropdownRender(originNode)}
        </div>
      )}
    />
  );
};
