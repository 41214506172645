import React, { useCallback, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import { ApiClient, UsersApi } from 'nextgen_api';

import { useUser } from 'features/user/userSlice';
import { openToast } from 'features/toasts/toastsSlice';

import { ToastType } from 'components/notifications/toasts/Toast';
import { TextButton } from 'components/buttons/TextButton';
import { validationSchema } from 'containers/Administration/Users/constants';

import { API_PATH } from 'config';

import styles from './ChangePassword.module.scss';

function ChangePasswordForm({
  onChangePasswordSucceeded,
  onChangePasswordFailed,
  onCancelChangePassword,
  props
}) {
  const [oldPwd, setOldPwd] = useState('');
  const [newPwd, setNewPwd] = useState('');
  const [confirmNewPwd, setConfirmNewPwd] = useState('');
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const userInfo = useUser();
  const [initial, setInitial] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validatePassword = useCallback(
    async (oldPwd, newPwd) => {
      if (newPwd === oldPwd) {
        setErrorMsg(t('ChangePassword.SamePasswordError'));
        return;
      }
      try {
        await validationSchema(userInfo?.type?.name).fields.password.validate(newPwd);
        setErrorMsg(null);
      } catch (err) {
        setErrorMsg(err.message || t('ChangePassword.InCorrectNewPwd'));
      }
    },
    [t]
  );
  const handleChangePassword = useCallback(
    async evt => {
      setInitial(false);
      if (oldPwd == null || oldPwd === '') {
        return null;
      }

      if (newPwd == null || newPwd === '' || newPwd.length < 4) {
        return;
      }

      if (confirmNewPwd == null || confirmNewPwd === '' || newPwd.length < 4) {
        return;
      }

      if (newPwd !== confirmNewPwd) {
        return;
      }

      const promise = new Promise((resolve, reject) => {
        const apiClient = new ApiClient();
        apiClient.basePath = API_PATH;
        apiClient.defaultHeaders = {
          Authorization: 'Token token="' + userInfo.auth.key + '"'
        };
        const userApi = new UsersApi(apiClient);
        userApi.changePassword(
          userInfo.auth.key,
          {
            body: {
              referrer: 'app.teletracnavman.com',
              username: userInfo.username,
              oldPassword: oldPwd,
              newPassword: newPwd
            }
          },
          (err, data, resp) => {
            if (err || resp.status !== 200) {
              reject(t('ChangePassword.UpdatePwdFailed'));
            } else {
              resolve(resp.body);
            }
          }
        );
      });
      try {
        await promise;
        if (onChangePasswordSucceeded) {
          dispatch(
            openToast({
              type: ToastType.Success,
              title: t('ChangePassword.PwdChanged')
            })
          );
          onChangePasswordSucceeded();
        }
      } catch (err) {
        setError(err);
      }
    },
    [
      oldPwd,
      newPwd,
      confirmNewPwd,
      userInfo.auth.key,
      userInfo.username,
      t,
      onChangePasswordSucceeded,
      dispatch
    ]
  );

  return (
    <div className={styles.changePasswordForm}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {error && <Alert variant="danger">{error}</Alert>}
        <div className={styles.formRow} style={{ display: 'flex' }}>
          <label htmlFor="old-password">{t('ChangePassword.OldPwd')}</label>
          <Input
            id="old-password"
            placeholder={t('ChangePassword.TypeOldPwd')}
            type="password"
            maxLength="25"
            autoComplete="no-pwd-store"
            required
            value={oldPwd}
            onChange={evt => {
              setOldPwd(evt.target.value);
              setInitial(false);
              setError(null);
              validatePassword(evt.target.value, newPwd);
            }}
            tabIndex={1}
          />
          {!initial && (oldPwd == null || oldPwd === '') && (
            <Alert variant="danger">{t('ChangePassword.RequiredOldPwd')}</Alert>
          )}
        </div>

        <div className={styles.formRow} style={{ display: 'flex' }}>
          <label htmlFor="new-password">{t('ChangePassword.NewPwd')}</label>
          <Input
            id="new-password"
            placeholder={t('ChangePassword.TypeNewPwd')}
            type="password"
            maxLength="25"
            autoComplete="no-pwd-store"
            required
            value={newPwd}
            onChange={evt => {
              setNewPwd(evt.target.value);
              setInitial(false);
              setError(null);
              validatePassword(oldPwd, evt.target.value);
            }}
            tabIndex={2}
          />
          {!initial && (newPwd == null || newPwd === '') && (
            <Alert variant="danger">{t('ChangePassword.RequiredNewPwd')}</Alert>
          )}
          {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
        </div>

        <div className={styles.formRow} style={{ display: 'flex' }}>
          <label htmlFor="new-password-confirm">{t('ChangePassword.ConfirmNewPwd')}</label>
          <Input
            id="new-password-confirm"
            type="password"
            placeholder={t('ChangePassword.ConfirmNewPwdAgain')}
            maxLength="25"
            autoComplete="no-pwd-store"
            required
            value={confirmNewPwd}
            onChange={evt => {
              setConfirmNewPwd(evt.target.value);
              setInitial(false);
              setError(null);
            }}
            tabIndex={3}
          />
          {!initial && newPwd !== confirmNewPwd && (
            <Alert variant="danger">{t('ChangePassword.NewOldPwdNotSame')}</Alert>
          )}
        </div>

        <div className={styles.formFooter} style={{ display: 'flex' }}>
          <TextButton
            variant="outline-secondary"
            onClick={onCancelChangePassword}
            text={t('Common.CancelButton')}
            tabIndex={5}
            id="btn_changePasswordCancel"
          />
          <TextButton
            disabled={initial || errorMsg != null || newPwd === '' || newPwd !== confirmNewPwd}
            variant="primary"
            text={t('ChangePassword.Confirm')}
            onClick={handleChangePassword}
            tabIndex={4}
            id="btn_changePasswordConfirm"
          />
        </div>
      </div>
    </div>
  );
}

export function ChangePasswordModal({ show, onHide, ...props }) {
  const { t } = useTranslation();
  return (
    <Modal centered={true} show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title className={styles.modalTitle}>{t('ChangePassword.ChangePwd')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <ChangePasswordForm onChangePasswordSucceeded={onHide} onCancelChangePassword={onHide} />
      </Modal.Body>
    </Modal>
  );
}
