import React from 'react';
import { Route, Switch, useLocation } from 'react-router';

import { ENTITIES } from 'components/alertStatusBar/constants';

import AlertsView from '../AlertsView';
import { AlertForm } from '../AlertForm';
import NewCompanyAlerts from '../NewCompanyAlerts';
import { ALERT_LINKS, Paths } from '../constants';
import { Can, entities } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { useAlertPermissions } from '../helpers/useAlertPermissions';
import { getTypeFromLink } from '../helpers/linkManipulationFunctions';

export const CompanyAlertsRouter = () => {
  const location = useLocation();
  const linkArray = Object.values(ALERT_LINKS);
  const pathParams = location.pathname.split('/');

  const link = pathParams.find(param => (linkArray.find(link => link === param) ? param : ''));

  const alertPermissions = useAlertPermissions();
  const alertType = getTypeFromLink(link);
  const alertPermission = alertPermissions?.find(alert => alert?.type === alertType) || {};
  const { services, featureHide } = alertPermission;

  return (
    <Switch>
      <Route path={Paths.CLONE_ALERT}>
        <Can
          oneOfServices={services}
          oneOfEntities={[entities.COMPANYALERT_CREATE]}
          featureHide={featureHide}
          onFail={<NoAccessBody />}
        >
          <AlertForm action="add" clone entityType={ENTITIES.COMPANY} />
        </Can>
      </Route>
      <Route path={Paths.NEW_ALERT}>
        <Can
          oneOfServices={services}
          oneOfEntities={[entities.COMPANYALERT_CREATE]}
          featureHide={featureHide}
          onFail={<NoAccessBody />}
        >
          <AlertForm action="add" entityType={ENTITIES.COMPANY} />
        </Can>
      </Route>
      <Route path={Paths.EDIT_ALERT}>
        <Can oneOfServices={services} featureHide={featureHide} onFail={<NoAccessBody />}>
          <AlertForm action="edit" entityType={ENTITIES.COMPANY} />
        </Can>
      </Route>
      <Route path={Paths.ALERT_VIEW}>
        <Can
          oneOfServices={services}
          oneOfEntities={[entities.COMPANYALERT_VIEW]}
          featureHide={featureHide}
          onFail={<NoAccessBody />}
        >
          <AlertsView />
        </Can>
      </Route>
      <Route path={Paths.ALERTS_DEFAULT}>
        <NewCompanyAlerts />
      </Route>
    </Switch>
  );
};
