import React from 'react';
import { Select as AntSelect } from 'antd';
import i18n from 'i18next';

import style from './Select.module.scss';

export const Select = ({
  data,
  width,
  height,
  onChange,
  onSelect,
  placeholder,
  autoComplete = 'chrome-off',
  optionGroup = false,
  showSearch = true,
  valueProperty = 'id',
  status,
  errorMessage = null,
  notFoundContent,
  filterOption = (input, option) =>
    (option?.children || '').toLowerCase().indexOf(input?.toLowerCase()) >= 0,
  disabled = false,
  ...props
}) => {
  const { Option, OptGroup } = AntSelect;

  return (
    <>
      <AntSelect
        autoComplete={autoComplete}
        className={style.control}
        filterOption={filterOption}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        listHeight={height}
        onChange={onChange}
        onSelect={onSelect}
        placeholder={placeholder}
        showSearch={showSearch}
        style={{ width: width }}
        status={status}
        notFoundContent={notFoundContent}
        disabled={disabled}
        {...props}
      >
        {!optionGroup &&
          (data || []).map((value, index) => (
            <Option key={`${value.id}${index}`} value={value[valueProperty]}>
              {value.label ? value.label : value.name}
            </Option>
          ))}
        {optionGroup &&
          (data || []).map(optGroup => (
            <OptGroup label={optGroup.labelOptGroup} key={optGroup.labelOptGroup}>
              {optGroup.options.map(value => (
                <Option key={value.id} value={value[valueProperty]}>
                  {value.label ? value.label : value.name}
                </Option>
              ))}
            </OptGroup>
          ))}
      </AntSelect>
      {status === 'error' && (
        <div className={'fieldError error has'}>
          {errorMessage || i18n.t('Forms.FormValidation.Required')}
        </div>
      )}
    </>
  );
};
