import React, { useState, useEffect, useCallback } from 'react';
import { LoadingTable } from 'components/grid/LoadingTable';
import {
  useLocations,
  useDeletedLocations,
  deleteLocationApi,
  restoreLocationApi,
  useIsLocationsFetched,
  useIsDeletedLocationsFetched
} from 'features/locations/locationsSlice';
import InfoRow from 'components/form/info-row/InfoRow';
import InfoTable from 'components/form/info-table/InfoTable';
import FormTitle from 'components/form/form-title/FormTitle';
import FormButton from 'components/form/form-button/FormButton';
import { format } from 'utils/dates';
import {
  useCompanies,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import Map, { MapMode } from 'components/map/Map';
import { geofenceLinkCellRenderer } from './CellRenderers';
import { useHistory, matchPath } from 'react-router';
import { useLocalization } from 'features/localization/localizationSlice';
import { Can, useCan, entities } from 'features/permissions';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import { locationHelpers } from './helpers';
import { useTranslation } from 'react-i18next';
import { useContacts, useIsFetching as useIsFetchingContacts } from 'features/contacts';
import { Table } from 'components/ant';
import { getGeofenceShape } from '../Geofences/helpers';
import { prepareContactsDataForTable, prepareColumnsForTable } from './helpers';
import { ContactFormModal } from './ContactFormModal';
import { TYPES_TO_EXCLUDE_GEOFENCE_IN_VIEW, TYPES, PATHS } from './constants';
import { DeletableEntityType, DeletedEntityAlert } from 'features/common/deletedEntityAlert';

import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import styles from './Locations.module.scss';
import './Locations.scss';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

export const LocationView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const can = useCan();
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );

  const handleFetchError = useCallback(() => {
    if (history.location.pathname !== PATHS.DEFAULT) {
      history.replace(PATHS.DEFAULT);
    }
  }, [history]);

  const currentLocations = useLocations();
  const deletedLocations = useDeletedLocations(true);
  const [locations, setLocations] = useState([]);
  const [locationData, setLocationData] = useState(null);

  const localization = useLocalization();
  const contacts = useContacts(id);
  const [editContactData, setEditContactData] = useState(null);
  const isLoadingContacts = useIsFetchingContacts();
  const [isAddContactOpen, setIsAddContactOpen] = useState(false);
  const hasLocationsFetched = useIsLocationsFetched();
  const hasDeletedLocationsFetched = useIsDeletedLocationsFetched();

  useRedirectToMainFeaturePageOnCompanyChange('/settings/locations');

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    setLocations(currentLocations.concat(deletedLocations));
  }, [currentLocations, deletedLocations]);

  useEffect(() => {
    if (locations?.length > 0) {
      const location = locations.find(loc => parseInt(loc.id, 10) === parseInt(id, 10));
      setLocationData(location);
    }
  }, [locations, id]);

  useEffect(() => {
    dispatch(setPageTitle(`${locationData?.name || ''}`));
  }, [locationData, dispatch]);

  useEffect(() => {
    const parsedId = parseInt(id);
    if (
      matchPath(history.location.pathname, { path: PATHS.VIEW_LOCATION, exact: true }) &&
      (parsedId <= 0 ||
        isNaN(parsedId) ||
        (hasLocationsFetched &&
          !currentLocations?.some(l => l.id === parsedId) &&
          hasDeletedLocationsFetched &&
          !deletedLocations?.some(l => l.id === parsedId)))
    ) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [
    t,
    id,
    dispatch,
    handleFetchError,
    hasLocationsFetched,
    currentLocations,
    hasDeletedLocationsFetched,
    deletedLocations,
    history
  ]);

  const companies = useCompanies();

  // If no location data - render loading table
  if (!locationData) {
    return <LoadingTable columnSizes={[400]} />;
  }

  const company =
    locationData.companyId && companies.find(comp => comp.id === locationData.companyId);
  const type = locationData.type?.name;
  const address = locationHelpers.getFormattedAddress(locationData.address);
  const { gpsPosition, mapCoordinates } = locationHelpers.getCoordinates(locationData.GPS);
  const createdAt =
    locationData.createdAt &&
    format(new Date(locationData.createdAt), localization.formats.time.formats.dby_imp);
  const updatedAt =
    locationData.updatedAt &&
    format(new Date(locationData.updatedAt), localization.formats.time.formats.dby_imp);
  const geofences = locationData.geofences;

  const geofencesColumns = [
    {
      label: `${t('Locations.View.Name')}`,
      width: 300,
      cellDataGetter: ({ rowData }) => rowData.name,
      cellRenderer: geofenceLinkCellRenderer
    },
    {
      label: `${t('Locations.View.Shape')}`,
      width: 300,
      cellDataGetter: ({ rowData }) => getGeofenceShape(rowData)
    },
    {
      label: `${t('Locations.View.Area')} (${localization.formats.area.unit})`,
      width: 300,
      cellDataGetter: ({ rowData }) =>
        rowData.area_sqm ? localization.convertArea(rowData.area_sqm) : ''
    }
  ];

  const handleOpenContactForm = () => {
    setEditContactData(null);
    setIsAddContactOpen(true);
  };

  const handleOpenEditContactForm = editContactData => {
    setEditContactData(editContactData);
    setIsAddContactOpen(true);
  };

  const closeContactForm = () => {
    setEditContactData(null);
    setIsAddContactOpen(false);
  };

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        dispatch(deleteLocationApi(locationData, history));
        break;
      case 'restore':
        dispatch(restoreLocationApi(locationData));
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={{ entityName: AUDIT_ENTITY.LOCATION, ...locationData }}
        canUse="LOCATION"
        auditPath={`${PATHS.AUDIT_LOCATION}/${id}`}
        handleButtonAction={handleButtonAction}
        {...(!locationData.externalId &&
          (type || '').toLowerCase() !== TYPES.journey && {
            editPath: `${PATHS.EDIT_LOCATION}/${id}`
          })}
        typeOfEntityToDelete={t('Common.location')}
      />
      <ContactFormModal
        company={company}
        isOpen={isAddContactOpen}
        onCancel={closeContactForm}
        editContactData={editContactData}
      />
      <div
        style={{
          display: 'flex',
          flex: '1 0 0',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '32px',
            color: '#181c21'
          }}
          className="vehicleView"
        >
          {
            <DeletedEntityAlert
              entity={DeletableEntityType.Location}
              entityStatus={locationData?.status}
            />
          }
          <InfoRow
            label={t('Locations.View.Company')}
            value={company ? company.name : ''}
            styles={styles}
          />
          <InfoRow label={t('Locations.View.Type')} value={type} styles={styles} />
          <InfoRow label={t('Locations.View.Address')} value={address} styles={styles} />
          <InfoRow label={t('Locations.View.GPSPosition')} value={gpsPosition} styles={styles} />
          <InfoRow
            label={t('Locations.View.SiteNotes')}
            value={locationData.siteNotes || ''}
            styles={styles}
          />
          <InfoRow label={t('Locations.View.CreatedAt')} value={createdAt} styles={styles} />
          <InfoRow label={t('Locations.View.UpdatedAt')} value={updatedAt} styles={styles} />
          <InfoRow
            label={t('Locations.View.LocationId')}
            value={locationData?.id}
            styles={styles}
          />
          {((geofences && geofences.length > 0) || mapCoordinates) && (
            <Row style={{ height: '400px', margin: '32px 0 0 0' }}>
              <Map
                mode={MapMode.Geofence}
                location={mapCoordinates}
                geofences={geofences}
                containerElement={<div style={{ height: `100%`, width: `100%` }} />}
                mapElement={<div style={{ height: `100%`, width: `100%` }} />}
              />
            </Row>
          )}
          {!TYPES_TO_EXCLUDE_GEOFENCE_IN_VIEW(t).includes(type) && (
            <>
              <FormTitle title={t('Locations.View.Geofences')} underlined />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
                <FormButton
                  label={t('Locations.View.SelectGeofence')}
                  handleClick={() => {
                    history.push(`/settings/locations/geofences/${locationData.id}`);
                  }}
                  customStyling={{ marginRight: '10px' }}
                />
                <Can everyEntity={[entities.GEOFENCE_CREATE]}>
                  <FormButton
                    label={t('Locations.View.AddNewGeofence')}
                    handleClick={() => {
                      history.push({
                        pathname: '/settings/geofences/newGeofence',
                        state: { location: locationData }
                      });
                    }}
                  />
                </Can>
              </div>
              {geofences && geofences.length === 0 && (
                <InfoRow
                  label={t('Locations.Notifications.NoGeofence')}
                  labelWidth={'100%'}
                  styles={styles}
                />
              )}
              {geofences && geofences.length > 0 && (
                <InfoRow
                  label=""
                  labelWidth={'0%'}
                  hideBorder
                  value={
                    <InfoTable
                      data={geofences || []}
                      columns={geofencesColumns}
                      styles={styles}
                      customRowStyle={{ alignItems: 'center' }}
                    />
                  }
                  styles={styles}
                  sxValue={{
                    width: '100%',
                    minHeight: `${geofences ? geofences.length * 64 + 32 : 0}px`
                  }}
                />
              )}
            </>
          )}
          <Can everyEntity={[entities.CONTACT]}>
            <div className={styles.contactsContainer}>
              <div className={styles.headerContainer}>
                <h4 className={styles.formTitle}>{t('Locations.View.Contacts')}</h4>
                <Can everyEntity={[entities.CONTACT_CREATE]}>
                  <div className="addButtonContainer">
                    <FormButton
                      label={t('Locations.View.AddNewContact')}
                      handleClick={handleOpenContactForm}
                    />
                  </div>
                </Can>
              </div>
              <div className={styles.divider} />
              {Array.isArray(contacts) && !contacts.length && (
                <InfoRow
                  label={t('Locations.Notifications.NoContacts')}
                  labelWidth={'100%'}
                  styles={styles}
                />
              )}
              {Array.isArray(contacts) && !!contacts.length && (
                <Table
                  dataSource={prepareContactsDataForTable({
                    contacts,
                    id,
                    can,
                    entities,
                    handleOpenEditContactForm,
                    t
                  })}
                  columns={prepareColumnsForTable(t)}
                  pagination={false}
                  loading={isLoadingContacts}
                />
              )}
            </div>
          </Can>
        </div>
      </div>
    </React.Fragment>
  );
};
