import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toLower } from 'lodash';
import { SortDirection } from 'react-virtualized';
import { useDevices, useVehicles, useVehiclesFromFleetsBoth } from 'features/fleets/fleetsSlice';
import useDebounce from 'utils/hooks/useDebounce';
import { BULK_DUTY_TYPE, FLEET, TYPE, ENTITY_TABS, CameraSensitivityOptions } from './helper';
import { useCompanies } from 'features/company/companySlice';
import { useFleets, useIsFetching as isFleetsFetching } from 'features/fleets/fleetsSlice';
import { useIQCameraDevicesConfig } from 'features/company_config';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { isIQCamera } from 'features/camera/CameraModelConfig';

const isStandaloneDevice = device => !device?.vehicleInfo || !device.vehicleInfo.id;

const mergeFleets = (fleets, baseFleets) => {
  const ret = baseFleets || [];
  if (!ret.some(f => f.id === FLEET.ALL.id)) {
    ret.push(FLEET.ALL);
  }
  for (const fleet of fleets) {
    if (!fleet.id) {
      if (!ret.some(f => f.id === FLEET.NO_FLEET.id)) {
        ret.push(FLEET.NO_FLEET);
      }
    } else if (!ret.some(f => f.id === fleet.id)) {
      ret.splice(1, 0, fleet);
    }
  }
  return ret;
};

const mergeEntityTypes = (entityType, baseTypes) => {
  const ret = baseTypes || [];
  if (!ret.some(type => type.id === TYPE.ALL.id)) {
    ret.push(TYPE.ALL);
  }
  if (!ret.some(type => type.id === entityType.id)) {
    ret.splice(1, 0, entityType);
  }
  return ret;
};
const getFleetNamesWithCompanyName = ({ fleets, companies }) =>
  fleets
    ?.filter(fleet => !!fleet.id)
    ?.map(fleet => {
      const companyName = companies?.find(company => fleet?.companyId === company.id)?.name;
      return `${fleet?.name || ''} ${companyName ? `(${companyName})` : ''}`;
    }) || [];

const getDeviceFleetNames = ({ deviceFleets, companies, fleets }) =>
  getFleetNamesWithCompanyName({
    fleets: deviceFleets?.map(deviceFleet => ({
      ...deviceFleet,
      companyId: fleets?.find(fleet => fleet.id === deviceFleet.id)?.company?.id
    })),
    companies
  });

export const useIQCameraBulkEdit = ({ onFetchError, form }) => {
  const [filters, setFilters] = useState({
    currentTab: ENTITY_TABS.VEHICLE.entity,
    currentDutyType: BULK_DUTY_TYPE.all,
    searchText: '',
    fleets: [],
    entityTypes: [],
    cameraSensitivities: []
  });

  const [sort, setSort] = useState({ sortBy: 'name', sortDirection: SortDirection.ASC });

  const sorter = useCallback(
    (a, b) => {
      const entityCol = ENTITY_TABS[filters.currentTab].cols.find(
        col => col.dataKey === sort.sortBy
      );
      const sign = sort.sortDirection === SortDirection.ASC ? -1 : 1;
      if (entityCol?.formFieldProps?.getFieldName) {
        const sortBy = entityCol.formFieldProps?.sortBy || (value => value?.toUpperCase());
        const valueA = sortBy(
          form.getFieldValue(
            entityCol.formFieldProps.getFieldName({ rowData: a }, filters.currentTab)
          )
        );
        const valueB = sortBy(
          form.getFieldValue(
            entityCol.formFieldProps.getFieldName({ rowData: b }, filters.currentTab)
          )
        );
        if (valueA < valueB) {
          return sign;
        }
        if (valueA > valueB) {
          return -1 * sign;
        }
        return 0;
      } else {
        const sortBy = entityCol?.sortBy || (entity => entity?.[sort.sortBy]?.toUpperCase());
        const nameA = sortBy(a);
        const nameB = sortBy(b);
        if (nameA < nameB) {
          return sign;
        }
        if (nameA > nameB) {
          return -1 * sign;
        }
        return 0;
      }
    },
    [form, sort, filters.currentTab]
  );

  const debouncedSearchText = useDebounce(filters.searchText, 300);

  const {
    setCurrentTab,
    onSearch,
    setDutyType,
    onFleetsSelect,
    onEntityTypesSelect,
    onCameraSensitivitiesSelect,
    resetFilters
  } = useMemo(
    () => ({
      setCurrentTab: currentTab => setFilters(prev => ({ ...prev, currentTab })),
      onSearch: searchText => setFilters(prev => ({ ...prev, searchText })),
      setDutyType: currentDutyType => setFilters(prev => ({ ...prev, currentDutyType })),
      onFleetsSelect: fleets => setFilters(prev => ({ ...prev, fleets })),
      onEntityTypesSelect: entityTypes => setFilters(prev => ({ ...prev, entityTypes })),
      onCameraSensitivitiesSelect: cameraSensitivities =>
        setFilters(prev => ({ ...prev, cameraSensitivities })),
      resetFilters: currentTab =>
        setFilters({
          currentTab,
          currentDutyType: BULK_DUTY_TYPE.all,
          searchText: '',
          fleets: [],
          entityTypes: [],
          cameraSensitivities: []
        })
    }),
    []
  );

  const devices = useDevices();
  const _vehicles = useVehicles();
  const vehicles = useMemo(() => (_vehicles || []).filter(v => !!v.id), [_vehicles]);
  const vehiclesBoth = useVehiclesFromFleetsBoth();
  const companies = useCompanies();
  const fleets = useFleets();
  const isFetchingFleets = isFleetsFetching();
  const currentCompanyId = useCurrentCompanyId();
  const { t } = useTranslation();

  const { iqCameras, fleetsWithIQCameras, deviceTypes } = useMemo(() => {
    let fleetsWithIQCameras = [],
      deviceTypes = [];
    const iqCameras = (
      devices?.filter(device => isStandaloneDevice(device) && isIQCamera(device)) || []
    ).map(device => {
      const deviceFleets = device.fleetInfo || [];
      fleetsWithIQCameras = mergeFleets(deviceFleets, fleetsWithIQCameras);
      deviceTypes = mergeEntityTypes(device.type, deviceTypes);
      return {
        ...device,
        fleets: deviceFleets,
        fleetNames: getDeviceFleetNames({ deviceFleets, fleets, companies })
      };
    });
    return {
      iqCameras,
      fleetsWithIQCameras,
      deviceTypes
    };
  }, [devices, companies, fleets]);

  const { vehiclesWithIQCamera, fleetsWithIQCameraVehicles, vehicleTypes } = useMemo(() => {
    let fleetsWithIQCameraVehicles = [],
      vehicleTypes = [];

    const vehiclesWithIQCamera =
      vehicles
        ?.filter(vehicle => vehicle?.devices?.some(isIQCamera))
        .map(vWithIQCamera => {
          const fleets =
            vehiclesBoth.find(vBoth => parseInt(vBoth.id, 10) === parseInt(vWithIQCamera.id, 10))
              ?.fleets || [];
          fleetsWithIQCameraVehicles = mergeFleets(
            !fleets.length ? [FLEET.NO_FLEET] : fleets,
            fleetsWithIQCameraVehicles
          );
          vehicleTypes = mergeEntityTypes(vWithIQCamera.type, vehicleTypes);
          const iqCamera = vWithIQCamera.devices.find(isIQCamera);
          return {
            ...vWithIQCamera,
            fleets,
            fleetNames: getFleetNamesWithCompanyName({ fleets, companies }),
            iqCamera
          };
        }) || [];

    return {
      fleetsWithIQCameraVehicles,
      vehiclesWithIQCamera,
      vehicleTypes
    };
  }, [vehicles, vehiclesBoth, companies]);

  const allIQCameraDeviceIds = useMemo(
    () =>
      Array.from(
        new Set(
          [
            ...vehiclesWithIQCamera?.map(vehicle => vehicle.iqCamera.id),
            ...iqCameras?.map(device => device.id)
          ].filter(id => !!id)
        )
      ),
    [vehiclesWithIQCamera, iqCameras]
  );

  const { iqCameraDevicesConfig, isFetching } = useIQCameraDevicesConfig({
    companyId: currentCompanyId,
    onError: onFetchError,
    deviceIds: allIQCameraDeviceIds
  });

  const isLaodingEntityData = useMemo(() => isFetching || isFetchingFleets, [
    isFetchingFleets,
    isFetching
  ]);

  const entityData = useMemo(() => {
    const isMatchSearch = entityNames =>
      entityNames.some(value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1);

    const isMatchFleets = (entityFleets = []) => {
      const allChecked =
        filters.fleets.length === 0 || filters.fleets.some(f => f.id === FLEET.ALL.id && f.checked);
      const checkedFleets = filters.fleets.filter(fleet => fleet.checked);
      return allChecked
        ? true
        : entityFleets.some(
            vFleet =>
              (!vFleet.id && filters.fleets.some(f => f.id === FLEET.NO_FLEET.id && f.checked)) ||
              checkedFleets.some(
                checkedFleet => parseInt(checkedFleet.id, 10) === parseInt(vFleet.id, 10)
              )
          );
    };

    const isMatchTypes = typeId => {
      const allChecked =
        filters.entityTypes.length === 0 ||
        filters.entityTypes.some(entityType => entityType.id === TYPE.ALL.id && entityType.checked);
      const checkedTypes = filters.entityTypes.filter(type => type.checked);
      return allChecked
        ? true
        : checkedTypes.some(checkedType => parseInt(checkedType.id, 10) === parseInt(typeId, 10));
    };

    const isMatchCameraSensitivities = device => {
      const allChecked =
        filters.cameraSensitivities.length === 0 ||
        filters.cameraSensitivities.some(
          entityType => entityType.id === TYPE.ALL.id && entityType.checked
        );
      const checkedTypes = filters.cameraSensitivities.filter(type => type.checked);
      const cameraSensitivityFieldValue = form.getFieldValue(
        ENTITY_TABS[filters.currentTab].cols
          .find(col => col.dataKey === 'cameraSensitivity')
          ?.formFieldProps?.getFieldName({ rowData: device }, filters.currentTab)
      );
      return allChecked
        ? true
        : checkedTypes.some(
            checkedType =>
              CameraSensitivityOptions.find(
                opt => parseInt(opt.id, 10) === parseInt(checkedType.id, 10)
              )?.value === cameraSensitivityFieldValue
          );
    };

    if (filters.currentTab === ENTITY_TABS.VEHICLE.entity) {
      return vehiclesWithIQCamera
        .filter(vehicle => {
          const matchSearch = isMatchSearch([vehicle.name, vehicle.registration]);
          const matchedFleets = isMatchFleets(vehicle.fleets);
          const matchedTypes = isMatchTypes(vehicle?.type?.id);
          return (
            matchSearch && matchedFleets && matchedTypes && isMatchCameraSensitivities(vehicle)
          );
        })
        .sort(sorter);
    } else {
      return iqCameras
        .filter(device => {
          const matchSearch = isMatchSearch([device.name, device?.imei, device?.serialNumber]);
          const matchedFleets = isMatchFleets(device.fleets);
          return matchSearch && matchedFleets && isMatchCameraSensitivities(device);
        })
        .sort(sorter);
    }
  }, [
    filters.fleets,
    filters.entityTypes,
    filters.cameraSensitivities,
    form,
    filters.currentTab,
    vehiclesWithIQCamera,
    iqCameras,
    debouncedSearchText,
    sorter
  ]);

  const formData = useMemo(
    () =>
      iqCameraDevicesConfig && {
        [ENTITY_TABS.VEHICLE.entity]: vehiclesWithIQCamera.reduce((a, vehicle) => {
          return {
            ...a,
            [vehicle.iqCamera.id]: {
              cameraSensitivity:
                iqCameraDevicesConfig?.[vehicle.iqCamera.id]?.config?.dutyType ||
                BULK_DUTY_TYPE.light
            }
          };
        }, {}),
        [ENTITY_TABS.DEVICE.entity]: iqCameras.reduce((a, device) => {
          return {
            ...a,
            [device.id]: {
              cameraSensitivity:
                iqCameraDevicesConfig?.[device.id]?.config?.dutyType || BULK_DUTY_TYPE.light
            }
          };
        }, {})
      },
    [vehiclesWithIQCamera, iqCameras, iqCameraDevicesConfig]
  );

  const multiSelections = useMemo(() => {
    const getFleetOptions = fleets => {
      if (filters.fleets.length === 0) {
        return {
          options: fleets.map(fleet => ({
            label:
              fleet.id === FLEET.ALL.id || fleet.id === FLEET.NO_FLEET.id
                ? t(fleet.name)
                : fleet.name,
            id: fleet.id,
            checked: true
          })),
          title: t('Common.AllFleets')
        };
      } else {
        return {
          options: fleets.map(fleet => ({
            label:
              fleet.id === FLEET.ALL.id || fleet.id === FLEET.NO_FLEET.id
                ? t(fleet.name)
                : fleet.name,
            id: fleet.id,
            checked: filters.fleets.find(f => f.id === fleet.id)?.checked
          })),
          title: filters.fleets.some(f => f.id === FLEET.ALL.id && f.checked)
            ? t('Common.AllFleets')
            : t('Common.Fleets')
        };
      }
    };

    const getEntityTypeOptions = types => {
      if (filters.entityTypes.length === 0) {
        return {
          options: types.map(type => ({
            label: type.id === TYPE.ALL.id ? t(type.name) : type.name,
            id: type.id,
            checked: true
          })),
          title:
            filters.currentTab === ENTITY_TABS.VEHICLE.entity
              ? t('Fleets.Form.AllVehicleTypes')
              : t('Fleets.Form.AllDeviceTypes')
        };
      } else {
        const allChecked = filters.entityTypes.some(
          entityType => entityType.id === TYPE.ALL.id && entityType.checked
        );
        const entityTypeTitle =
          filters.currentTab === ENTITY_TABS.VEHICLE.entity
            ? allChecked
              ? t('Fleets.Form.AllVehicleTypes')
              : t('VehicleTypes.VehicleTypesTitle')
            : allChecked
            ? t('Fleets.Form.AllDeviceTypes')
            : t('Devices.DeviceTypes');
        return {
          options: types.map(type => ({
            label: type.id === TYPE.ALL.id ? t(type.name) : type.name,
            id: type.id,
            checked: filters.entityTypes.find(entityType => entityType.id === type.id)?.checked
          })),
          title: entityTypeTitle
        };
      }
    };

    const { options: fleetOptions, title: fleetTitle } = getFleetOptions(
      filters.currentTab === ENTITY_TABS.VEHICLE.entity
        ? fleetsWithIQCameraVehicles
        : fleetsWithIQCameras
    );

    const fleetsSelection = {
      title: fleetTitle,
      data: fleetOptions,
      onSelect: onFleetsSelect
    };

    const getCameraSensitivityOptions = types => {
      if (filters.cameraSensitivities.length === 0) {
        return {
          options: types.map(type => ({
            label: type.id === TYPE.ALL.id ? t(type.name) : type.name,
            id: type.id,
            checked: true
          })),
          title: `${t('Common.All')} ${t('CompanyConfig.IQCamera.BULK_EDIT.CameraSensitivities')}`
        };
      } else {
        const allChecked = filters.cameraSensitivities.some(
          entityType => entityType.id === TYPE.ALL.id && entityType.checked
        );
        const entityTypeTitle = allChecked
          ? `${t('Common.All')} ${t('CompanyConfig.IQCamera.BULK_EDIT.CameraSensitivities')}`
          : t('CompanyConfig.IQCamera.BULK_EDIT.CameraSensitivities');
        return {
          options: types.map(type => ({
            label: type.id === TYPE.ALL.id ? t(type.name) : type.name,
            id: type.id,
            checked: filters.cameraSensitivities.find(entityType => entityType.id === type.id)
              ?.checked
          })),
          title: entityTypeTitle
        };
      }
    };

    const {
      options: sensitivityTitleOptions,
      title: sensitivityTitle
    } = getCameraSensitivityOptions([
      { id: TYPE.ALL.id, name: t('Common.All') },
      ...CameraSensitivityOptions.map(opt => ({
        name: t(opt.label),
        ...opt
      }))
    ]);

    const cameraSensitivitiesSelection = {
      title: sensitivityTitle,
      data: sensitivityTitleOptions,
      onSelect: onCameraSensitivitiesSelect
    };

    if (filters.currentTab === ENTITY_TABS.VEHICLE.entity) {
      const { options: entityTypeOptions, title: entityTypeTitle } = getEntityTypeOptions(
        vehicleTypes
      );
      const typesSelection = {
        title: entityTypeTitle,
        data: entityTypeOptions,
        onSelect: onEntityTypesSelect
      };
      return [fleetsSelection, typesSelection, cameraSensitivitiesSelection];
    }
    return [fleetsSelection, cameraSensitivitiesSelection];
  }, [
    t,
    filters.currentTab,
    filters.fleets,
    filters.entityTypes,
    filters.cameraSensitivities,
    fleetsWithIQCameraVehicles,
    fleetsWithIQCameras,
    vehicleTypes,
    deviceTypes,
    onFleetsSelect,
    onEntityTypesSelect,
    onCameraSensitivitiesSelect
  ]);

  return {
    filters,
    entityData,
    setCurrentTab,
    onSearch,
    setDutyType,
    formData,
    multiSelections,
    resetFilters,
    isLaodingEntityData,
    sort,
    setSort
  };
};
