import i18next from 'i18next';

import { ToastType } from 'components/notifications/toasts/Toast';

import { openToast } from 'features/toasts/toastsSlice';
import { fetchInspectionChecklists } from 'features/inspectionChecklist/inspectionChecklistSlice';
import { canHistoryGoBack } from 'utils/methods';
import { api } from 'utils/api';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';

export const handleDeleteAction = (data, history) => async (dispatch, getState) => {
  const { id, name } = data;
  const authKey = getState().user.current.auth.key;
  Mixpanel.sendTrackEvent(MPTrackingEvents.Settings.PreTripChecklist.Update, {
    typeOfUpdate: 'delete'
  });
  try {
    const response = await api.delete(`/pretripchecklists/${id}`, { authKey });

    if (!response.ok && !response.redirect) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('PreTripChecklist.Notifications.FailedDeleteNotification', {
            name: name
          })
        })
      );
    }

    if (response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('PreTripChecklist.Notifications.DeleteNotification', {
            name: name
          })
        })
      );
      dispatch(fetchInspectionChecklists());
      history && canHistoryGoBack(history, '/settings/inspectionChecklist');
    }
  } catch (err) {
    console.error(err);
  }
};

export const handleEditAction = (history, id, inspectionData) => () => {
  history.push(`/settings/inspectionChecklist/edit/id/${id}`, { dataToEdit: inspectionData });
};

export const handleEditDvirAction = (history, id, inspectionData) => () => {
  history.push(`/settings/inspectionChecklist/editDvir/id/${id}`, {
    dataToEdit: inspectionData
  });
};
