/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Company from './Company';
import Device from './Device';
import DeviceStats from './DeviceStats';
import Item from './Item';
import VehicleStats from './VehicleStats';

/**
 * The DeviceHealth model module.
 * @module model/DeviceHealth
 * @version 1.0
 */
class DeviceHealth {
    /**
     * Constructs a new <code>DeviceHealth</code>.
     * @alias module:model/DeviceHealth
     */
    constructor() { 
        
        DeviceHealth.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DeviceHealth</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DeviceHealth} obj Optional instance to populate.
     * @return {module:model/DeviceHealth} The populated <code>DeviceHealth</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DeviceHealth();

            if (data.hasOwnProperty('company')) {
                obj['company'] = Company.constructFromObject(data['company']);
            }
            if (data.hasOwnProperty('device')) {
                obj['device'] = Device.constructFromObject(data['device']);
            }
            if (data.hasOwnProperty('deviceStats')) {
                obj['deviceStats'] = DeviceStats.constructFromObject(data['deviceStats']);
            }
            if (data.hasOwnProperty('vehicleStats')) {
                obj['vehicleStats'] = VehicleStats.constructFromObject(data['vehicleStats']);
            }
            if (data.hasOwnProperty('items')) {
                obj['items'] = ApiClient.convertToType(data['items'], [Item]);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/Company} company
 */
DeviceHealth.prototype['company'] = undefined;

/**
 * @member {module:model/Device} device
 */
DeviceHealth.prototype['device'] = undefined;

/**
 * @member {module:model/DeviceStats} deviceStats
 */
DeviceHealth.prototype['deviceStats'] = undefined;

/**
 * @member {module:model/VehicleStats} vehicleStats
 */
DeviceHealth.prototype['vehicleStats'] = undefined;

/**
 * @member {Array.<module:model/Item>} items
 */
DeviceHealth.prototype['items'] = undefined;






export default DeviceHealth;

