import React from 'react';
import { TabNavLink } from 'components/nav/NavLinks';

export const TabsFilters = ({ tabs = [] }) =>
  tabs.map(tab => (
    <TabNavLink
      key={tab.key}
      to={{
        pathname: tab.to.pathname,
        state: tab.to.history
      }}
      isActive={tab.isActive}
      onClick={tab.onClick}
      disabled={tab.disabled}
    >
      {tab.name}
    </TabNavLink>
  ));
