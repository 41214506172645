/* global google */
import React, { useRef, useState } from 'react';
import { GoogleMap, withGoogleMap, Marker, Polygon, InfoWindow } from 'react-google-maps';
import MapDirections from './MapDirections';
import { journeyHelpers } from './helpers';
import { Button } from 'components/ant';
import { useTranslation } from 'react-i18next';
import {
  getGeofencePoints,
  getGeofenceStyle,
  isDrawablePolygonGeofence
} from 'features/geofences/geofencesUtil';
import { useCompanyGeofenceProviders } from 'features/company/companySlice';
import { BUTTON_IDS } from 'utils/globalConstants';

const GeofenceOnMap = ({ geofence }) => {
  const geofenceProviders = useCompanyGeofenceProviders();
  if (!(isDrawablePolygonGeofence(geofence) && geofence.type === 'USER')) {
    return null;
  }

  const { color, weight } = getGeofenceStyle(geofenceProviders, geofence);

  return (
    <Polygon
      paths={(getGeofencePoints(geofence) || []).map(pointSet =>
        pointSet.map(point => ({
          lat: point.Lat,
          lng: point.Lng
        }))
      )}
      key={geofence.id}
      options={{
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: weight,
        fillColor: color,
        fillOpacity: 0.3
      }}
    />
  );
};

const JourneyMap = withGoogleMap(
  React.forwardRef(
    (
      {
        waypoints,
        onMapDoubleClick,
        handleZoomChanged,
        newMarker,
        geofences,
        readonly,
        onNoDirections = () => {},
        onDirectionsSucceed = () => {},
        onDirectionError = () => {},
        setIsLoading = () => {},
        mapCenter,
        zoomLevel,
        handleInfoWindowAdd,
        onMapLoaded
      },
      ref
    ) => {
      const mapRef = useRef();
      const map = ref || mapRef;
      const { t } = useTranslation();
      const [showInfoWindow, setShowInfoWindow] = useState(false);
      const [infoWindowPosition, setInfoWindowPosition] = useState(null);
      const onToggleOpen = marker => {
        setShowInfoWindow(prev => !prev);
        marker && setInfoWindowPosition(marker?.location?.location);
      };

      return (
        <GoogleMap
          ref={map}
          onDblClick={onMapDoubleClick}
          onZoomChanged={handleZoomChanged}
          defaultOptions={{
            disableDoubleClickZoom: true
          }}
          zoom={zoomLevel}
          center={mapCenter}
          onTilesLoaded={onMapLoaded}
        >
          {waypoints && (
            <MapDirections
              places={waypoints}
              travelMode={google.maps.TravelMode.DRIVING}
              map={map}
              onNoDirections={onNoDirections}
              setIsLoading={setIsLoading}
              onDirectionsSucceed={onDirectionsSucceed}
              onDirectionError={onDirectionError}
              readonly={readonly}
            />
          )}
          {waypoints &&
            waypoints.map(marker => {
              if (marker.seq) {
                return (
                  <Marker
                    key={marker.seq}
                    position={marker.position}
                    label={{
                      text: journeyHelpers.numberToLetter(Number(marker.seq) - 1),
                      color: 'white',
                      fontWeight: 'normal'
                    }}
                  />
                );
              } else {
                return (
                  <Marker
                    position={marker?.location?.location}
                    zIndex={-101}
                    label={{
                      text: '!',
                      color: 'white',
                      fontWeight: 'bold'
                    }}
                    onClick={() => {
                      onToggleOpen(marker);
                    }}
                  ></Marker>
                );
              }
            })}
          {showInfoWindow && infoWindowPosition && (
            <InfoWindow onCloseClick={onToggleOpen} position={infoWindowPosition}>
              <div>
                <div>{t('JourneyPlanner.Form.PinManualChangeRoute')}</div>
                <div>{t('JourneyPlanner.Form.PinChange')}</div>
              </div>
            </InfoWindow>
          )}
          {newMarker && (
            <Marker
              position={newMarker.position}
              label={{
                text: journeyHelpers.numberToLetter(Number(newMarker.label) - 1),
                color: 'white',
                fontWeight: 'normal'
              }}
            >
              <InfoWindow>
                <Button id={BUTTON_IDS.journeyMapAdd} onClick={handleInfoWindowAdd}>
                  {t('JourneyPlanner.Form.AddStop')}
                </Button>
              </InfoWindow>
            </Marker>
          )}
          {newMarker && newMarker.geofence && <GeofenceOnMap geofence={newMarker.geofence} />}
          {geofences &&
            geofences.map(geofence => {
              return <GeofenceOnMap geofence={geofence} key={geofence.id} />;
            })}
        </GoogleMap>
      );
    }
  )
);

export default JourneyMap;
