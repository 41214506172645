import React from 'react';
import P from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Table } from 'components/ant';
import { prepareItemsForTable } from '../utils/helpers';
import { getItemsTableColumns } from '../utils/translation';
import { useLocalization } from 'features/localization/localizationSlice';
import { useItemTypes } from 'features/smartJobs';

export const LoadedItems = ({ runsheet }) => {
  const { t } = useTranslation();
  const allItems = runsheet?.jobs
    .map(job => job.jobItems.map(item => ({ ...item, type: job.type, job })))
    .flat();
  const TABLE_RV_JOBS_ITEMS_COLUMNS = getItemsTableColumns(t);
  const localization = useLocalization();
  const itemTypes = useItemTypes();

  return (
    <Table
      columns={TABLE_RV_JOBS_ITEMS_COLUMNS}
      dataSource={prepareItemsForTable(allItems, null, t, localization, {}, itemTypes)}
      pagination={false}
    />
  );
};

LoadedItems.propTypes = {
  runsheetData: P.object
};
