import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import moment from 'moment';
import { api } from 'utils/api';
import { VehicleConfig } from 'containers/Administration/Vehicles/constants';
import { EDRDevicesSortFun } from 'containers/VehicleMaintenance/utils/helpers';
import { useVehiclesFromFleets } from 'features/fleets/fleetsSlice';
import { getMeterValue, getMeterBySource, MeterSource } from 'features/meters';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { useDevicesMeters } from 'features/devices/devicesMetersSlice';
import {
  useDevicesStatsEmbedDeviceMeters,
  useIsFetchingDevicesStats
} from 'features/devices/devicesStatsSlice';
import { t_error } from 'i18nextConfig';

const initialState = {
  configs: {},
  meta: {
    companyKey: null
  }
};

const vehiclesConfigsSlice = createSlice({
  name: 'vehiclesConfigs',
  initialState: initialState,
  reducers: {
    fetchConfigStart(state, { payload }) {
      state.configs[payload.id] = {
        lastFetched: null,
        isFetching: true,
        error: null,
        config: null
      };
    },
    fetchConfigSuccess(state, { payload }) {
      state.configs[payload?.id] = {
        lastFetched: moment().format(),
        isFetching: false,
        error: null,
        config: payload.value
      };
      state.meta.companyKey = payload.companyKey;
    },
    fetchConfigFailure(state, { payload }) {
      state.configs[payload?.id] = {
        lastFetched: moment().format(),
        isFetching: false,
        error: payload?.err,
        config: {}
      };
      state.meta.companyKey = payload.companyKey;
    },
    resetConfig(state, { payload }) {
      state.configs = {};
    }
  }
});

const {
  fetchConfigStart,
  fetchConfigSuccess,
  fetchConfigFailure,
  resetConfig
} = vehiclesConfigsSlice.actions;

const vechileConfigResponseFormater = (queryParam, responseBody) => {
  if (queryParam === VehicleConfig.OdometerForMnt.value) {
    return { isUsingRUCOdometerForMnt: responseBody[queryParam] };
  }
  return responseBody;
};

export const getVehicleConfig = (id, queryParam, onError, onNotFound) => async (
  dispatch,
  getState
) => {
  try {
    if (!id) {
      throw new Error('No id');
    }
    dispatch(fetchConfigStart({ id }));
    const authKey = getState().user.current.auth.key;
    await api
      .get(`/vehicles/${id}/config?q=${queryParam}`, { authKey })
      .then(res => {
        if (res?.status === 200) {
          return vechileConfigResponseFormater(queryParam, res?.body);
        } else {
          if (onNotFound) {
            onNotFound();
          }
        }
        return {};
      })
      .catch(err => {
        if (onError) {
          onError(t_error(err.response?.body || err.toString()));
        }
        return {};
      })
      .then(config => {
        dispatch(fetchConfigSuccess({ value: config, id }));
      });
  } catch (err) {}
};

export const getVehiclesConfigs = (ids, queryParam, force) => async (dispatch, getState) => {
  try {
    const companyKey = getState().companies.current.api_key;
    const userKey = getState().user.current.auth.key;
    if (!companyKey || !userKey) {
      return;
    }
    ids = force
      ? ids
      : ids.filter(
          id =>
            !getState().vehiclesConfigs.configs[id] &&
            !getState().vehiclesConfigs.configs[id]?.isFetching
        );
    if (force) {
      dispatch(resetConfig());
    }
    ids.map(id => dispatch(fetchConfigStart({ id })));
    await Promise.all(
      ids.map(id =>
        api
          .get(`/vehicles/${id}/config?q=${queryParam}`, { authKey: userKey })
          .then(res => {
            if (res?.status === 200) {
              dispatch(
                fetchConfigSuccess({
                  value: vechileConfigResponseFormater(queryParam, res?.body),
                  id,
                  companyKey
                })
              );
            } else {
              dispatch(
                fetchConfigSuccess({
                  value: {},
                  id,
                  companyKey
                })
              );
            }
          })
          .catch(err => {
            dispatch(fetchConfigFailure({ err: err.toString(), id, companyKey }));
          })
      )
    );
  } catch (err) {}
};

export const useCompanyKey = () => useSelector(state => state.vehiclesConfigs.meta.companyKey);
const useIsCompanyKeyDifferent = () => useCompanyKey() !== useCurrentCompanyKey();

export const useVehiclesConfigs = (ids = [], queryParam) => {
  const configs = useSelector(state => state.vehiclesConfigs.configs);
  const dispatch = useDispatch();
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();

  const vehicleIdsNeedConfig = useMemo(() => {
    return ids.filter(id => !configs[id]);
  }, [ids, configs]);

  const vehiclesConfigs = useMemo(() => {
    return ids.reduce((a, c) => ({ ...a, [c]: configs[c] }), {});
  }, [ids, configs]);

  if (vehicleIdsNeedConfig.length) {
    dispatch(getVehiclesConfigs(ids, queryParam, isCompanyKeyDifferent));
  }

  const isFetching = useMemo(() => {
    return ids && !!ids.length && ids.some(id => configs[id] && configs[id].isFetching);
  }, [ids, configs]);

  return { vehiclesConfigs, isFetching };
};

export const useVehicleConfig = (vehicleId, queryParam, onError, onNotFound) => {
  const dispatch = useDispatch();
  const configs = useSelector(state => state.vehiclesConfigs.configs);
  const devicesStats = useDevicesStatsEmbedDeviceMeters('EDR');
  const isFetchingDevicesStats = useIsFetchingDevicesStats();
  const { vehicles } = useVehiclesFromFleets();

  const vehicleDevices = useMemo(() => {
    return vehicles?.find(v => String(v.id) === String(vehicleId))?.devices || [];
  }, [vehicles, vehicleId]);

  const { EDRDevices, edrDeviceIds } = useMemo(() => {
    const EDRDevices = vehicleDevices?.filter(device => device?.type?.code === 'EDR') || [];
    return {
      EDRDevices: EDRDevices.map(EDRDevice => {
        const deviceMeters =
          devicesStats?.find(device => String(device.deviceId) === String(EDRDevice?.id))?.meters ||
          EDRDevice?.meters ||
          [];
        return {
          ...EDRDevice,
          meters: deviceMeters
        };
      }),
      edrDeviceIds: Array.from(
        new Set(EDRDevices.map(EDRDevice => EDRDevice.id).filter(id => !!id))
      )
    };
  }, [vehicleDevices, devicesStats]);

  const edrDeviceIdsNeedMeters = useMemo(() => {
    return isFetchingDevicesStats
      ? []
      : edrDeviceIds.filter(edrDeviceId =>
          devicesStats.every(device => device.deviceId !== edrDeviceId)
        );
  }, [devicesStats, edrDeviceIds]);

  const { devicesMeters: extraEDRDevicesMeters } = useDevicesMeters(edrDeviceIdsNeedMeters);

  const edrDevicesWithMeters = useMemo(() => {
    return EDRDevices.map(device => {
      const edrDeviceMeters =
        (extraEDRDevicesMeters && extraEDRDevicesMeters[device?.id]) || device?.meters || [];
      return {
        ...device,
        meters: edrDeviceMeters
      };
    });
  }, [EDRDevices, extraEDRDevicesMeters]);

  const { rucDevice, rucDeviceMeter, rucOdometer } = useMemo(() => {
    const rucDevice =
      edrDevicesWithMeters.length > 0 ? edrDevicesWithMeters.sort(EDRDevicesSortFun)[0] : null;
    const rucDeviceMeter = rucDevice && getMeterBySource(rucDevice, MeterSource.Ruc);
    const deviceRucMeterValue = rucDeviceMeter ? getMeterValue(rucDeviceMeter) : 0;
    return { rucDevice, rucDeviceMeter, rucOdometer: deviceRucMeterValue };
  }, [edrDevicesWithMeters]);

  const shouldFetchConfig = useMemo(() => {
    return (
      vehicleId &&
      !configs[vehicleId]?.isFetching &&
      !configs[vehicleId]?.error &&
      !configs[vehicleId]?.config
    );
  }, [configs, vehicleId]);

  if (shouldFetchConfig) {
    const parsedId = parseInt(vehicleId);
    if (parsedId > 0 && !isNaN(parsedId)) {
      dispatch(getVehicleConfig(vehicleId, queryParam, onError, onNotFound));
    }
  }

  const vehicleConfig = useMemo(() => {
    const ret = configs[vehicleId]?.config || {};
    return {
      ...ret,
      EDRDevices: edrDevicesWithMeters,
      hasEDRDevice: edrDevicesWithMeters && edrDevicesWithMeters.length > 0,
      rucOdometer,
      rucDevice,
      rucDeviceMeter
    };
  }, [vehicleId, edrDevicesWithMeters, configs, rucOdometer, rucDevice, rucDeviceMeter]);

  return vehicleConfig;
};
export default vehiclesConfigsSlice.reducer;
