import React, { useEffect, useState, useRef } from 'react';
import { Steps, Row, Col, Button, Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { Select, Input } from 'components/ant';
import { confirmNavigateAway } from './confirmNavigateAway';

import { jurisdictions } from '../Data';
import { massSchemes } from '../Data';
import { MassSchemesTable } from '../Tables';
import { VehicleVis, MatchingVehicleVis } from '../Vis';
import { useVehicleCombinations } from 'features/massManagement/vehicleCombinationsSlice';

import styles from './Modal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const LABEL_ALIGN = 'left';

export const MassConfigurationModal = ({ data, isOpen = false, isNameUnique, onSave, onClose }) => {
  const { t } = useTranslation();
  const vehicleCombinations = useVehicleCombinations();
  const [massConfigurationForm] = Form.useForm();
  const massSchemesTableRef = useRef(null);

  const [progressCount, setProgressCount] = useState(0);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [vehicleCombination, setVehicleCombination] = useState(null);
  const [massSchemesData, setMassSchemesData] = useState([]);

  useEffect(() => {
    massConfigurationForm.resetFields();
    data?.vehicleCombination && setVehicleCombination(data.vehicleCombination);
    setMassSchemesData(
      data?.massSchemes
        ? data.massSchemes
        : massSchemes.filter(
            massScheme =>
              massScheme.jurisdiction === massConfigurationForm.getFieldValue('jurisdiction')
          )
    );

    const formData = {
      ...data,
      selectedMassSchemes: data?.massSchemes?.filter(scheme => scheme.selected)
    };
    massConfigurationForm.setFieldsValue(formData);
    setIsFormDirty(false);
  }, [data, massConfigurationForm]);

  const clearForm = () => {
    massConfigurationForm.resetFields();
    massSchemesTableRef.current && massSchemesTableRef.current.clearForm();
    setVehicleCombination(null);
    setMassSchemesData([]);
    setProgressCount(0);
    setIsSaveEnabled(false);
  };

  const onCancel = () => {
    confirmNavigateAway(
      isFormDirty,
      () => {
        onClose();
        clearForm();
      },
      null,
      t
    );
  };

  const onFormSave = values => {
    onSave(values);
    onClose();
    clearForm();
  };

  const onMassSchemesSave = massSchemes => {
    massConfigurationForm.setFieldsValue({
      massSchemes: massSchemes,
      selectedMassSchemes: massSchemes.filter(scheme => scheme.selected)
    });
    onFormChanged();
  };

  const validateForm = async () => {
    try {
      const values = await massConfigurationForm.validateFields();
      setIsSaveEnabled(true);
    } catch (errorInfo) {
      setIsSaveEnabled(errorInfo?.errorFields?.length === 0);
    }
  };

  const onFormChanged = data => {
    if (data && Object.keys(data).includes('jurisdiction')) {
      setMassSchemesData(
        massSchemes.filter(
          massScheme =>
            massScheme.jurisdiction === massConfigurationForm.getFieldValue('jurisdiction')
        )
      );
      massConfigurationForm.setFieldsValue({
        massSchemes: [],
        selectedMassSchemes: []
      });
    }

    validateForm();
    setIsFormDirty(true);
  };

  const onChangeVehicleCombination = vehicleCombinationId => {
    setVehicleCombination(
      vehicleCombinations.find(vehicleCombo => vehicleCombo.id === vehicleCombinationId)
    );
  };

  return (
    <Modal
      className={styles.modal}
      title={
        data?.name
          ? `${t('Common.Edit')} ${t('MassManagement.MassConfiguration')}`
          : `${t('Vehicles.Form.AddNew')} ${t('MassManagement.MassConfiguration')}`
      }
      open={isOpen}
      centered={true}
      onCancel={onCancel}
      width={1200}
      footer={
        <>
          <Button
            type="primary"
            size="large"
            form="massConfigurationForm"
            key="submit"
            htmlType="submit"
            disabled={!isSaveEnabled}
            id={BUTTON_IDS.massConfigurationModalSave}
          >
            {t('Common.Save')}
          </Button>
          <Button id={BUTTON_IDS.massConfigurationModalCancel} size="large" onClick={onCancel}>
            {t('Common.Cancel')}
          </Button>
        </>
      }
    >
      <Form
        layout="vertical"
        initialValues={data || {}}
        form={massConfigurationForm}
        name="massConfigurationForm"
        onFinish={onFormSave}
        onValuesChange={onFormChanged}
        onKeyPress={event => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Steps
          progressDot
          current={progressCount}
          direction="vertical"
          items={[
            {
              className: styles.step,
              title: t('Common.Select'),
              onClick: _ => setProgressCount(0),
              description: (
                <>
                  <Row align="middle" gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        name="vehicleCombinationId"
                        label={t('MassManagement.VehicleCombination')}
                        labelAlign={LABEL_ALIGN}
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: t('MassManagement.Validation.Required', {
                              config: t('MassManagement.VehicleCombination')
                            })
                          }
                        ]}
                      >
                        <Select
                          placeholder={t('MassManagement.VehicleCombination')}
                          data={vehicleCombinations}
                          valueProperty={'id'}
                          size={'large'}
                          onChange={onChangeVehicleCombination}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="jurisdiction"
                        label={t('MassManagement.Jurisdiction')}
                        labelAlign={LABEL_ALIGN}
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: t('MassManagement.Validation.Required', {
                              config: t('MassManagement.Jurisdiction')
                            })
                          }
                        ]}
                      >
                        <Select
                          placeholder={t('MassManagement.Jurisdiction')}
                          data={jurisdictions}
                          valueProperty={'name'}
                          size={'large'}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )
            },
            {
              className: styles.step,
              title: t('Home.Configure'),
              onClick: _ => setProgressCount(1),
              description: (
                <>
                  <Row align="middle">
                    <Col span={24}>
                      <Form.Item
                        name="name"
                        label={t('MassManagement.Field.ConfigurationName')}
                        labelAlign={LABEL_ALIGN}
                        colon={false}
                        rules={[
                          {
                            required: true,
                            message: t('MassManagement.Validation.NameRequired', {
                              name: t('MassManagement.Field.ConfigurationName')
                            })
                          },
                          {
                            validator: (rule, value) => {
                              const valid =
                                isNameUnique &&
                                value?.toLowerCase()?.trim() !== data?.name?.toLowerCase()
                                  ? isNameUnique(value)
                                  : true;
                              return valid ? Promise.resolve([]) : Promise.reject(valid);
                            },
                            message: t('MassManagement.Validation.NameUnique', {
                              name: t('MassManagement.Field.ConfigurationName')
                            })
                          }
                        ]}
                      >
                        <Input
                          size={'large'}
                          placeholder={t('MassManagement.Field.ConfigurationName')}
                          maxLength={50}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row align="middle" style={{ marginBottom: '32px' }}>
                    <Col span={24}>
                      {vehicleCombination?.visualization ? (
                        <VehicleVis visualization={vehicleCombination.visualization} />
                      ) : (
                        <MatchingVehicleVis
                          vehicleCombination={vehicleCombination}
                          showVisName={true}
                        />
                      )}
                    </Col>
                  </Row>
                  <Row align="middle">
                    <Col span={24}>
                      <label>{`${t('MassManagement.Field.MassSchemeLimits')} (t)`}</label>
                      <MassSchemesTable
                        t={t}
                        ref={massSchemesTableRef}
                        data={massSchemesData}
                        axleGroups={vehicleCombination?.axleGroups}
                        onSave={onMassSchemesSave}
                      />
                      <Form.Item
                        name="selectedMassSchemes"
                        className={styles.hideFormItemInput}
                        rules={[
                          {
                            required: true,
                            message: t('MassManagement.Validation.MassSchemeEnableRequired')
                          },
                          {
                            required: true,
                            message: t('MassManagement.Validation.MassSchemeRequired')
                          }
                        ]}
                      ></Form.Item>
                    </Col>
                  </Row>
                </>
              )
            }
          ]}
        />
        <Form.Item hidden name="id">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item hidden name="massSchemes">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
