import React, { useCallback, useEffect } from 'react';

import { TripMarker } from '../markers/TripMarker';
import { TripInfoMarker } from '../markers/TripInfoMarker';
import { Route } from './Route';
import { NonHDRoute } from './NonHDRoute';
import TripInfoWindow from '../info-windows/TripInfoWindow';
import { getPathLength } from 'utils/geo';

// Get average time gap of first 30 points, if over 30 seconds then consider it non HD data
const getIsHDRoute = replay => {
  if (replay && replay.length > 1) {
    let totalTimeDiff = 0,
      index = 1,
      count = 0;
    for (; index < replay.length && index < 30; index++) {
      if (replay[index].hasOwnProperty('At') && replay[index - 1].hasOwnProperty('At')) {
        const timeDiff = Number(replay[index].At) - Number(replay[index - 1].At);
        totalTimeDiff += timeDiff;
        count++;
      }
    }
    if (count > 0) {
      const averageTimeDiff = totalTimeDiff / count;
      if (averageTimeDiff > 30000) {
        // 30 seconds
        return false;
      }
    }
  }

  return true;
};

export const Trip = ({
  trip,
  isSelected,
  onSelected,
  selectedTripSegment,
  selectedReplay,
  onSetSelectedReplay,
  pathColor,
  showIgnitionOnOffEvents = true,
  showInfoWindowTrigger = [],
  cameraDeviceId
}) => {
  const isHDRoute = getIsHDRoute(trip.replay);

  const onTripPointClicked = useCallback(
    coord => {
      if (!coord?.lat || !coord?.lng || !showInfoWindowTrigger?.includes('click')) {
        return;
      }
      const dataPoints = trip.replay?.map(wayPoint => ({
        tripId: trip.id,
        position: { lat: wayPoint.Lat, lng: wayPoint.Lng },
        data: wayPoint
      }));
      const totalTripLength = dataPoints?.length
        ? getPathLength(dataPoints.map(p => p.position))
        : 0;
      if (totalTripLength !== 'Error') {
        if (totalTripLength === 0) {
          onSetSelectedReplay && onSetSelectedReplay(dataPoints?.[0]);
        } else {
          const { dataPoint } = dataPoints.reduce(
            (a, dataPoint) => {
              const length = getPathLength([coord, dataPoint.position]);
              return length !== 'Error' && length < a.length ? { dataPoint, length } : a;
            },
            { length: getPathLength([coord, dataPoints[0].position]), dataPoint: dataPoints[0] }
          );
          onSetSelectedReplay && onSetSelectedReplay(dataPoint);
        }
      } else {
        onSetSelectedReplay && onSetSelectedReplay(null);
      }
    },
    [trip, showInfoWindowTrigger]
  );

  return (
    <>
      {((isSelected && selectedTripSegment) || !selectedTripSegment) && showIgnitionOnOffEvents && (
        <>
          <TripMarker trip={trip} event={'IgnOnGPS'} />
          <TripMarker trip={trip} event={'IgnOffGPS'} />
        </>
      )}
      {isHDRoute ? (
        <Route
          origin={trip.IgnOnGPS ? { lat: trip.IgnOnGPS.Lat, lng: trip.IgnOnGPS.Lng } : {}}
          destination={trip.IgnOffGPS ? { lat: trip.IgnOffGPS.Lat, lng: trip.IgnOffGPS.Lng } : {}}
          waypoints={trip.replay}
          isSelected={isSelected}
          onSelected={({ latLng }) => {
            onTripPointClicked(latLng?.toJSON ? latLng.toJSON() : null);
            onSelected && onSelected(trip);
          }}
          pathColor={pathColor}
        />
      ) : (
        <NonHDRoute
          waypoints={trip.replay}
          isSelected={isSelected}
          onSelected={({ latLng }) => {
            onTripPointClicked(latLng?.toJSON ? latLng.toJSON() : null);
            onSelected && onSelected(trip);
          }}
          pathColor={pathColor}
        />
      )}
      {/* only show if selectedReplay is for this trip so it only renders once */}
      {selectedReplay && selectedReplay?.tripId === trip?.id && (
        <>
          <TripInfoMarker position={selectedReplay.position} isSelected={true} />
          <TripInfoWindow
            data={selectedReplay.data}
            position={selectedReplay.position}
            deviceId={trip.device?.id}
            camereDeviceId={cameraDeviceId}
            onCloseClick={_ => onSetSelectedReplay && onSetSelectedReplay(null)}
          />
        </>
      )}
    </>
  );
};
