import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useUserInfo, useUserKey } from 'features/user/userSlice';
import { useCan, entities } from 'features/permissions';

import { Button, Tooltip } from 'antd';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';

import { api } from 'utils/api';
import { isDefaultDriverOfVehicle } from 'containers/Administration/Vehicles/DefaultDriver';
import { useIsEWDDriver } from 'features/user/EWDUserHooks';
import { useDrivers } from '../usersSlice';
import { BUTTON_IDS } from 'utils/globalConstants';
const ELD_TACHO_DRIVER_PATTERN = /^ELD|UKT/gi;

const getNoUpdateTooltip = ({
  t,
  canVehicleUpdate,
  vehicleName,
  canUserUpdate,
  driverName,
  isDefaultDriver,
  isEWDDriver
}) => {
  if (!canVehicleUpdate) {
    return t(`Users.ForceLogoff.NoVehiclePermission`, { vehicleName });
  } else if (!canUserUpdate) {
    return t(`Users.ForceLogoff.NoUserPermission`, { driverName });
  } else if (isDefaultDriver) {
    return t(`Users.ForceLogoff.NoDefaultDriverLogoffWithIndication`, { driverName, vehicleName });
  } else if (isEWDDriver) {
    return t('Users.ForceLogoff.EWDNote');
  }
};

const getDriverName = driver => `${driver?.firstName} ${driver?.lastName}`;
const getVehicleName = vehicle => vehicle?.name;

export const getIsFatigueComplianceDriver = driverEntity =>
  driverEntity?.rulesets?.some(r => !r.expiresAt && r.ruleset.match(ELD_TACHO_DRIVER_PATTERN));

export const useForceLogoff = (driver, vehicle, Entity, onForceLogoff) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const can = useCan();
  const drivers = useDrivers();
  const currentUser = useUserInfo();
  const authKey = useUserKey();
  const isEWDDriver = useIsEWDDriver(driver?.id);
  const driverEntity = drivers?.find(d => d.id === driver?.id);

  const { canView, canUpdate, noUpdateTooltip } = useMemo(() => {
    const isFatigueComplianceDriver = getIsFatigueComplianceDriver(driverEntity);
    const hasIds = driver?.id && vehicle?.id,
      driverName = getDriverName(driver),
      vehicleName = getVehicleName(vehicle),
      isDefaultDriver = isDefaultDriverOfVehicle(driver, vehicle);
    let canVehicleView = can({
        everyEntity: [entities.VEHICLE_VIEW]
      }),
      canUserView = can({
        everyEntity: [entities.USER_VIEW]
      }),
      canVehicleUpdate = can({
        everyEntity: [entities.VEHICLE_UPDATE]
      }),
      canUserUpdate = can({
        everyEntity: [entities.USER_UPDATE]
      });
    switch (Entity) {
      case entities.USER:
        //Backend returns userData.currentVehicles visible to loginUser, currentVehicle.editable indicates a vehicle can be updated or not
        canVehicleUpdate = canVehicleUpdate && !!vehicle?.editable;
        return {
          canView: !isFatigueComplianceDriver && hasIds && canVehicleView && canUserView,
          canUpdate:
            hasIds && canVehicleUpdate && canUserUpdate && !isDefaultDriver && !isEWDDriver,
          noUpdateTooltip: getNoUpdateTooltip({
            t,
            canVehicleUpdate,
            canUserUpdate,
            driverName,
            vehicleName,
            isDefaultDriver,
            isEWDDriver
          })
        };
      case entities.VEHICLE:
        return {
          canView: !isFatigueComplianceDriver && hasIds && canVehicleView,
          canUpdate:
            hasIds && canVehicleUpdate && canUserUpdate && !isDefaultDriver && !isEWDDriver,
          noUpdateTooltip: getNoUpdateTooltip({
            t,
            canVehicleUpdate,
            canUserUpdate,
            driverName,
            vehicleName,
            isDefaultDriver,
            isEWDDriver
          })
        };
      default:
        break;
    }
  }, [t, currentUser, Entity, driver, vehicle, driverEntity, isEWDDriver]);

  const onConfirm = useCallback(
    async (driver, vehicle) => {
      let loggedOff = false;
      const driverName = getDriverName(driver),
        vehicleName = getVehicleName(vehicle);
      try {
        loggedOff = await api
          .post(`/users/${driver?.id}/force_logoff`, { authKey }, { vehicleIds: [vehicle?.id] })
          .then(response => {
            if (response?.ok) {
              dispatch(
                openToast({
                  type: ToastType.Success,
                  message: t('Users.ForceLogoff.Success', { driverName, vehicleName })
                })
              );
              return true;
            } else {
              dispatch(
                openToast({
                  type: ToastType.Error,
                  message: t('Users.ForceLogoff.Failed', { driverName, vehicleName })
                })
              );
            }
          });
      } catch (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Users.ForceLogoff.Failed', { driverName, vehicleName })
          })
        );
      }
      if (onForceLogoff) {
        onForceLogoff(loggedOff);
      }
    },
    [t, dispatch, onForceLogoff, authKey]
  );

  const ForceLogoffBtn = useMemo(() => {
    const onBtnClick = () => {
      const btnStyles = {
        okButtonStyles: { backgroundColor: '#ffc53d', borderColor: '#ffc53d', color: '#2b323c' },
        iconStyles: { color: '#ffc53d' }
      };
      confirmationModal(
        t('Common.Modal.SureTitle'),
        t('Users.ForceLogoff.AreYouSure', {
          driverName: getDriverName(driver),
          vehicleName: getVehicleName(vehicle)
        }),
        t('Users.ForceLogoff.Title'),
        t('Common.Modal.Cancel'),
        () => onConfirm(driver, vehicle),
        'warning',
        () => {},
        'forceLogoffModal',
        null,
        btnStyles
      );
    };
    return () =>
      canView && (
        <Tooltip title={!canUpdate ? noUpdateTooltip : null}>
          <Button
            size="small"
            id={BUTTON_IDS.forceLogoff}
            onClick={onBtnClick}
            disabled={!canUpdate}
          >
            {t('Users.ForceLogoff.Title')}
          </Button>
        </Tooltip>
      );
  }, [t, canView, canUpdate, driver, vehicle, onConfirm]);

  return {
    canForceLoggoff: canView,
    ForceLogoffBtn
  };
};
