import React from 'react';
import { useTranslation } from 'react-i18next';

import { PieChart } from 'components/ant/Charts/pie-chart';
import { Skeleton } from 'antd';
import cn from 'classnames';
import { getStatistics } from './utils/helpers';
import { capitalize } from 'utils/strings';

import { TRIP_STATE } from './utils';
import { useLocalization } from 'features/localization/localizationSlice';

import styles from './FBTManager.module.scss';

const FBTStatisticsInfo = ({ data }) => {
  const { t } = useTranslation();
  const localization = useLocalization();

  const typeOfUseClasses = cn(styles.category, {
    [styles.privateUse]: data?.purpose === TRIP_STATE.PRIVATE,
    [styles.businessUse]: data?.purpose === TRIP_STATE.BUSINESS
  });

  return (
    <div className={styles.statisticsInfoWrapper}>
      <div className={typeOfUseClasses}>
        <div className={styles.bulletPoint}></div>
        <div className={styles.title}>
          <h3>{data?.percentage}</h3>
          <p>{data?.name}</p>
        </div>
      </div>
      <p>
        {t('FBTManager.Statistics.TripsNumber')}: <b>{data?.trips}</b>
      </p>
      <p>
        {capitalize(localization.formats.speed.unit_pluralize)}:{' '}
        <b>{+localization.convertDistance(data?.km)}</b>
      </p>
      <p>
        {t('FBTManager.Statistics.Hours')}: <b>{data?.hours}</b>
      </p>
    </div>
  );
};

export const FBTStatisticsCard = ({ trips, loading, period }) => {
  const statistics = getStatistics(trips, period);
  const privateStatistics = statistics.find(statistic => statistic.purpose === TRIP_STATE.PRIVATE);
  const businessStatistics = statistics.find(
    statistic => statistic.purpose === TRIP_STATE.BUSINESS
  );

  return (
    <div className={styles.statisticsCardWrapper}>
      {loading ? (
        <Skeleton.Input active />
      ) : (
        <>
          <PieChart data={statistics} />
          {!isNaN(businessStatistics.value) && <FBTStatisticsInfo data={businessStatistics} />}
          {!isNaN(privateStatistics.value) && <FBTStatisticsInfo data={privateStatistics} />}
        </>
      )}
    </div>
  );
};
