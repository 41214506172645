export const STORE_DATE_FORMAT = 'YYYY-MM-DD';
export const MessagingRenderAction = {
  listing: 'listing',
  compose: 'compose',
  view: 'view'
};
export const DEFAULT_VEHICLE_ICON = 'tn-i-flatbed';
export const DEFAULT_DEVICE_ICON = 'tn-i-device';
export const DEFAULT_DRIVER_ICON = 'tn-i-driver';
export const DEFAULT_GROUP_MESSAGE_ICON = 'tn-i-users';
export const MESSAGE_DATE_FORMAT = 'D/M/YY';
export const SORT = {
  DESC: 'DESC',
  ASC: 'ASC'
};

export const RECIPIENT_TYPE = {
  DRIVER: 'DRIVER',
  DEVICE: 'DEVICE',
  VEHICLE: 'VEHICLE',
  GROUP_MESSAGE: 'GROUP_MESSAGE'
};

export const MESSAGE_TYPE = {
  'MSG-NEW': 'MSG-NEW',
  'MSG-REPLY': 'MSG-REPLY',
  'MSG-RECV': 'MSG-RECV',
  'MSG-READ': 'MSG-READ',
  'MSG-ACK': 'MSG-ACK'
};
export const MESSAGE_STATUE = {
  READ: 'READ',
  RECEIVED: 'RECEIVED',
  DELIVERED: 'DELIVERED',
  SENT: 'SENT'
};

export const MESSAGE_NOTIFICATION_TYPE = {
  ALERT: 'ALERT',
  NORMAL: 'NORMAL',
  ROUTE_TO: 'ROUTE_TO'
};

export const MESSAGE_SENDER_TYPE = {
  WEB_DISPATCHER: 'WEB_DISPATCHER', //sent by TN360 User
  OTHER: 'OTHER' //sent by devices
};

export const MESSAGE_STYLES = {
  WEB_DISPATCHER: {
    borderColor: '#9cca55',
    backgroundColor: '#e9face',
    cssCls: 'webDispatcherMessage'
  },
  ROUTE_TO: {
    borderColor: '#875BB0',
    backgroundColor: '#E7DEEF',
    cssCls: 'routeToMessage'
  },
  OTHER: {
    borderColor: '#dadee3',
    backgroundColor: '#FFFFFF',
    cssCls: 'otherMessage'
  }
};

export const MESSAGE_DIR_ICON = {
  SENT: 'tn-i-chat-sent',
  RECEIVED: 'tn-i-chat-received'
};

export const MESSAGE_DELETE_MODE = {
  ALL: 'ALL',
  SINGLE: 'SINGLE'
};

export const MESSAGE_PERMISSION_ENTITY_TYPE = {
  Message: 'Message',
  MessageTemplate: 'MessageTemplate'
};

export const NextGen_Message_Event_Status = {
  sucess: 'Success',
  failed: 'Failed'
};

export const RECIPIENT_TREE_NODE_TYPE = {
  BRANCH: 'BRANCH',
  ALL_BRANCH: 'ALL_BRANCH',
  FLEET: 'FLEET',
  ALL_FLEET: 'ALL_FLEET',
  VEHICLE: 'VEHICLE',
  DEVICE: 'DEVICE',
  DRIVER: 'DRIVER'
};

export const ENTITY_DIVIDER = ', ';
