import { createSlice } from '@reduxjs/toolkit';
import { fetchWifi, deleteWifi, addWifi, updateWifi } from '../thunks';

const initialState = {
  list: [],
  isFetching: false,
  error: null,
  success: null,
  isUpdatingWifi: false,
  meta: {
    companyKey: null
  }
};

export const wifiSlice = createSlice({
  name: 'wifi',
  initialState,
  extraReducers: {
    [fetchWifi.pending]: state => {
      state.isFetching = true;
      state.success = false;
      state.error = false;
    },
    [fetchWifi.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.success = true;
      state.error = false;
      state.meta.companyKey = payload.companyKey;
      state.list = payload.list.sort((wifiA, wifiB) =>
        wifiA.name.toLowerCase() > wifiB.name.toLowerCase() ? 1 : -1
      );
    },
    [fetchWifi.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.success = false;
      state.error = true;
      state.meta.companyKey = payload.companyKey;
    },
    [deleteWifi.pending]: state => {
      state.isUpdatingWifi = true;
    },
    [deleteWifi.fulfilled]: state => {
      state.isUpdatingWifi = false;
    },
    [deleteWifi.rejected]: state => {
      state.isUpdatingWifi = false;
    },
    [addWifi.pending]: state => {
      state.isUpdatingWifi = true;
    },
    [addWifi.fulfilled]: state => {
      state.isUpdatingWifi = false;
    },
    [addWifi.rejected]: state => {
      state.isUpdatingWifi = false;
    },
    [updateWifi.pending]: state => {
      state.isUpdatingWifi = true;
    },
    [updateWifi.fulfilled]: state => {
      state.isUpdatingWifi = false;
    },
    [updateWifi.rejected]: state => {
      state.isUpdatingWifi = false;
    }
  }
});
