export const FbtColumnsKeys = {
  VEHICLE: 'vehicle',
  DRIVER: 'driver',
  BUSINESS_USES_KM: 'businessUsage',
  BUSINESS_USES_PERCENTAGE: 'businessUsagePercentage',
  PRIVATE_USES_KM: 'privateUsage',
  PRIVATE_USES_PERCENTAGE: 'privateUsagePercentage'
};

export const FbtItemColumnKeys = {
  START_DATE: 'startedAt',
  END_DATE: 'endedAt',
  VEHICLE_NAME: 'vehicleName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  START_LOCATION: 'startLocation',
  END_LOCATION: 'endLocation',
  TOTAL_KM: 'distanceKm',
  TYPE_OF_USE: 'purpose',
  ACTIONS: 'actions',
  VEHICLE_ID: 'vehicleId',
  DRIVER_ID: 'driverId'
};
export const FBT = {
  HOURS: 'durationSecs'
};

export const vehiclesKeys = {
  id: FbtItemColumnKeys.VEHICLE_ID,
  name: FbtItemColumnKeys.VEHICLE_NAME
};

export const driversKeys = {
  id: FbtItemColumnKeys.DRIVER_ID,
  firstName: FbtItemColumnKeys.FIRST_NAME,
  lastName: FbtItemColumnKeys.LAST_NAME
};

export const { VEHICLE_ID, DRIVER_ID, LAST_NAME, ...FBTRows } = FbtItemColumnKeys;

export const TRIP_STATE = {
  PRIVATE: 'Non-Business',
  BUSINESS: 'Business'
};
