/* global google */
import { GOOGLE_MAPS_API_KEY } from 'config';

export const changeMapLanguage = (locale, oldLocale) => {
  if (locale === oldLocale) return;

  let oldScript = document.getElementById('google-maps-script');
  if (oldScript) {
    oldScript.parentNode.removeChild(oldScript);
    delete google.maps;
  }

  let localeTokens = locale.split('-');
  let language = localeTokens[0];
  let region = localeTokens[1];
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.src =
    'https://maps.googleapis.com/maps/api/js?key=' +
    GOOGLE_MAPS_API_KEY +
    '&language=' +
    language +
    '&region=' +
    region +
    '&libraries=places,drawing';
  script.id = 'google-maps-script';
  document.body.appendChild(script);
};

export const getConfigLocale = (mapsLanguage, mapsRegion) => {
  return (mapsLanguage + '-' + mapsRegion).toLowerCase();
};

export const arePrefsAndConfigLocalesEqual = (prefsLanguage, mapsLanguage, mapsRegion) => {
  return (
    prefsLanguage
      .split('-')
      .join('-')
      .toLowerCase() === (mapsLanguage + '-' + mapsRegion).toLowerCase()
  );
};
