import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const EditRouteGuard = ({
  when,
  navigate,
  confirmModalProps,
  isExcludedPath = (nextLocation, action) => false,
  onConfirmLeave = ({ lastAction, lastLocation, setConfirmedNavigation }) => {}
}) => {
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(when);
  const [lastAction, setLastAction] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  const handleConfirmNavigationClick = () => {
    setConfirmedNavigation(true);
  };

  const handleBlockedNavigation = (nextLocation, action) => {
    if (!confirmedNavigation && !isExcludedPath(nextLocation, action)) {
      const modalSelf = confirmationModal(
        confirmModalProps?.title || t('RouteGuard.Title'),
        confirmModalProps?.message || t('RouteGuard.Message'),
        confirmModalProps?.leaveBtnText || t('RouteGuard.LeavePage'),
        confirmModalProps?.stayBtnText || t('RouteGuard.StayOnPage'),
        handleConfirmNavigationClick,
        null,
        null,
        null,
        null,
        confirmModalProps?.getFooter
          ? {
              footer: (_, { OkBtn, CancelBtn }) =>
                confirmModalProps?.getFooter({
                  OkBtn,
                  CancelBtn,
                  modalSelf,
                  handleConfirmNavigationClick
                })
            }
          : null
      );
      setLastLocation(nextLocation);
      setLastAction(action);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      if (lastAction === 'POP') {
        history.goBack();
        if (onConfirmLeave) {
          onConfirmLeave({ lastAction, lastLocation, setConfirmedNavigation });
        }
      } else {
        navigate(lastLocation);
        setConfirmedNavigation(false);
        if (onConfirmLeave) {
          onConfirmLeave({ lastAction, lastLocation, setConfirmedNavigation });
        }
      }
    }
  }, [confirmedNavigation, lastLocation, lastAction, navigate, history, when, onConfirmLeave]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
    </>
  );
};

export default EditRouteGuard;
