import React, { useState, useCallback } from 'react';
import { ExpandButton } from 'components/ant/Button/ExpandButton';
import { Pill, PillColor } from 'components/visualization/Pill';
import styles from './CellRenderer.module.scss';
import { Link } from 'react-router-dom';
import { Can, services } from 'features/permissions';
import { EllipsisMenu } from 'components/tables/EllipsisMenu';
import { MessageComposeModal } from 'containers/Messaging/MessageComposeModal';
import { RECIPIENT_TYPE } from 'containers/Messaging/constants';
import {
  Reseting,
  RestDueTooltip,
  TimeTooltip,
  Tooltip,
  Violations,
  ViolationsTooltip
} from 'features/fatigue/Components';
import { durationFormat, formatTime } from 'features/fatigue/time_util';
import moment from 'moment';

export const LinkCell = ({ rowData }) => {
  function handleClick(id) {}
  return (
    <Link to={'/fatigue/ewd/' + rowData.id} onClick={() => handleClick(rowData.id)}>
      {rowData.firstName + ' ' + rowData.lastName}
    </Link>
  );
};

const meatballMenuItems = (rowData, showMessageModal) => {
  const canMessaging = rowData.canMessaging;
  return [
    canMessaging && {
      label: 'Message Driver',
      renderProp: () => (
        <Can oneOfServices={[services.MESSAGING]}>
          <Link onClick={showMessageModal}>Message Driver</Link>
        </Can>
      )
    },
    {
      label: 'View Driver Details',
      link: `/settings/users/id/${rowData.id}`,
      id: 'btn_ewdViewDriver'
    },
    {
      label: 'Edit Driver Detail',
      link: `/settings/users/edit/id/${rowData.id}`,
      id: 'btn_ewdEditDriver'
    }
  ].filter(menuItem => !!menuItem);
};

export const MeatballsMenu = ({ rowData }) => {
  const [showPopup, setShowPopup] = useState(false);
  const recipient = { recipientType: RECIPIENT_TYPE.DRIVER, recipientId: rowData.id };
  const menuItems = useCallback(() => {
    return meatballMenuItems({ id: rowData.id, canMessaging: rowData.canMessaging }, () =>
      setShowPopup(prev => !prev)
    );
  }, [rowData.id, rowData.canMessaging]);

  return (
    <>
      <EllipsisMenu menuItems={menuItems} />
      {showPopup && (
        <MessageComposeModal
          visible={showPopup}
          showModal={setShowPopup}
          recipients={[recipient]}
        />
      )}
    </>
  );
};

const CellPill = ({ max, worked, period }) => {
  const legend = useCallback(() => {
    return (
      <div className={styles.hoursworked}>
        <span>{formatTime(worked || 0) + ' / '}</span>
        <span>{formatTime(max || 0)}</span>
      </div>
    );
  }, [max, worked]);

  const tooltip = useCallback(() => {
    if (period) {
      return <Tooltip type={'Period'} startAt={period.startAt} endAt={period.finishAt} />;
    } else {
      return null;
    }
  }, [period]);

  return (
    <Pill
      value={worked}
      max={max}
      pillColor={worked >= max ? PillColor.Red : PillColor.Green}
      legend={legend}
      tooltip={tooltip}
    />
  );
};

export function availableCellRenderer({ dataKey, rowData, ...props }) {
  if (
    !props.cellProps?.isScrolling &&
    rowData?.ewdData &&
    rowData.ewdData.totals?.period?.finishAt > moment().valueOf()
  ) {
    const max = rowData.ewdData.totals?.available + rowData.ewdData.totals?.worked;
    return (
      <CellPill
        max={max}
        worked={rowData.ewdData.totals?.worked}
        period={rowData.ewdData.totals?.period}
      />
    );
  }
  return '';
}

export function hoursWorkedCellRenderer({ dataKey, rowData, ...props }) {
  if (rowData?.ewdData?.work24?.work) {
    return durationFormat(rowData?.ewdData.work24.work * 1000);
  }
  return '';
}

export function majorRestDueCellRenderer({ dataKey, rowData, ...props }) {
  const restFieldKeys = ['24HrRest', '2NightRest', 'nightRest'];
  const RestDesc = {
    '24HrRest': '24 Hours Rest',
    '2NightRest': 'Two Night Rest',
    nightRest: 'Night Rest'
  };

  if (!props.cellProps?.isScrolling && rowData?.ewdData && rowData.ewdData.lastStatusEvent) {
    const nextRestDueKey = restFieldKeys.reduce((key, currKey) => {
      if (rowData.ewdData[key]?.nextStartAt > rowData.ewdData[currKey]?.nextStartAt) {
        return currKey;
      } else {
        return key;
      }
    });

    const isInactive =
      rowData?.ewdData &&
      (rowData?.ewdData.lastStatusEvent == null ||
        moment()
          .subtract(7, 'day')
          .isAfter(moment(rowData.ewdData.lastStatusEvent.timeAt)));

    if (rowData?.ewdData?.[nextRestDueKey] && !isInactive) {
      const startAt = rowData.ewdData[nextRestDueKey].nextStartAt;
      const tooltip = () =>
        rowData?.ewdData?.[nextRestDueKey].nextPeriod && (
          <Tooltip
            type={RestDesc[nextRestDueKey]}
            startAt={startAt}
            endAt={rowData.ewdData[nextRestDueKey].nextFinishAt}
          />
        );
      return <TimeTooltip time={startAt} tooltip={tooltip} />;
    }
  }
  return '';
}

export function nextRestDueCellRenderer({ dataKey, rowData, ...props }) {
  if (props.cellProps?.isScrolling) return '';

  //always check currentViolations
  if (rowData?.ewdData?.currentViolations?.length > 0) {
    const isPotentialViolation = rowData.ewdData.currentViolations.every(
      v => v.startAt > rowData.ewdData.checkpointAt
    );

    const violtionTooltips = <ViolationsTooltip violations={rowData.ewdData.currentViolations} />;
    return (
      <Violations
        tooltip={violtionTooltips}
        className={styles.otherViolation}
        isPotentialViolation={isPotentialViolation}
      />
    );
  }

  //if no lastStatus event or last status event is 7 days prior
  if (
    rowData?.ewdData &&
    (rowData?.ewdData.lastStatusEvent == null ||
      moment()
        .subtract(7, 'day')
        .isAfter(moment(rowData.ewdData.lastStatusEvent.timeAt)))
  ) {
    return <span style={{ whiteSpace: 'normal' }}>No Events In the Prior 7 Days</span>;
  }

  //if is resting
  const isResting = [/rest/i, /offduty/i, /sleep/i].some(reg =>
    rowData?.ewdData?.lastStatusEvent?.type?.match(reg)
  );
  if (isResting) {
    return <Reseting />;
  }

  const isInactive =
    rowData?.ewdData &&
    (rowData?.ewdData.lastStatusEvent == null ||
      moment()
        .subtract(7, 'day')
        .isAfter(moment(rowData.ewdData.lastStatusEvent.timeAt)));

  if (rowData?.ewdData?.nextViolation && !isInactive) {
    const startAt = rowData.ewdData.nextViolation?.startAt;

    const tooltip = () =>
      rowData.ewdData.nextViolation?.rule && (
        <RestDueTooltip nextViolation={rowData.ewdData.nextViolation} />
      );
    return (
      <TimeTooltip
        time={startAt}
        tooltip={tooltip}
        status={rowData.ewdData.lastRelevantEvent?.type}
        className={
          rowData.ewdData.nextViolation?.type === 'PREDICTED'
            ? styles.predictedViolation
            : styles.otherViolation
        }
      />
    );
  }
  return '';
}

const ExpandButtonCell = ({ rowIndex, rowData, ...props }) => {
  const style = { width: '100%', textAlign: 'right' };
  const handleExpand = useCallback(
    expand => {
      return rowData.onExpand(rowIndex, expand);
    },
    [rowIndex, rowData]
  );

  return (
    <div style={style}>
      <ExpandButton onExpand={handleExpand} defaultExpand={rowData.expand} />
    </div>
  );
};

export function expandCellRenderer({ dataKey, rowData, rowIndex, ...props }) {
  return <ExpandButtonCell rowIndex={rowIndex} rowData={rowData} />;
}
