import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Input, Select, DatePicker, Table } from 'components/ant';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { Button, Divider, Form } from 'antd';

import {
  rowRenderer,
  isFormValid,
  prepareItemsForRunsheetTable,
  isRunsheetInProgress
} from './utils/helpers';
import { jobsToServer, jobsFromServer } from './utils/api';
import { compareByNumberProps } from 'utils/sorting';
import { JobForm, reorderSequence } from './';
import { RunsheetItems, LocationType, Paths, RunsheetStatus, ColumnKeys } from './utils/constants';
import { getRunsheetJobsTableColumns, getRunsheetItemsTableColumns } from './utils/translation';

import {
  saveRunsheet,
  useIsRunsheetSaving,
  removeJob,
  useRunsheet,
  useIsCompanyKeyDifferent
} from 'features/smartJobs';
import { useLocations } from 'features/locations/locationsSlice';
import { useVehicles } from 'features/fleets/fleetsSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useItemTypes } from 'features/smartJobs/smartJobsSlice';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';

import moment from 'moment';

import style from './scss/Runsheet.module.scss';
import FormFooter from 'components/form-footer/FormFooter';
import { SortableTable } from './SortableTable';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useCan } from 'features/permissions/canHooks';
import entities from 'features/permissions/entities';

const getFormColumn = (
  config,
  required,
  validationMessage,
  data,
  span = 11,
  t,
  editing,
  localization,
  disabled = false
) => ({
  span,
  style: { textAlign: 'right' },
  item: {
    label: t(`SmartJobs.Input.Label.${config.key}`),
    labelAlign: 'left',
    key: config.key,
    component: columnComponents(data, t, editing, localization, disabled)[config.key],
    other: {
      ...(required && { rules: [{ required: true, message: validationMessage }] })
    }
  }
});

const columnComponents = (data, t, editing, localization, disabled = false) => ({
  [RunsheetItems.ID.key]: (
    <Input size="large" placeholder={t('SmartJobs.Input.Placeholder.Type')} disabled={editing} />
  ),
  [RunsheetItems.NAME.key]: (
    <Input size="large" placeholder={t('SmartJobs.Input.Placeholder.RunsheetName')} />
  ),
  [RunsheetItems.SCHEDULE.key]: (
    <DatePicker
      size="large"
      placeholder={t('SmartJobs.Input.Placeholder.Select')}
      allowClear
      format={localization.formats.time.formats.dmY}
    />
  ),
  [RunsheetItems.VEHICLE.key]: (
    <Select
      size="large"
      placeholder={t('SmartJobs.Input.Placeholder.SelectOptions')}
      data={data}
      disabled={disabled}
      allowClear
    />
  )
});

const getRowsForRunsheetForm = (
  vehicles,
  t,
  editing,
  localization,
  extraConfig = { runsheet: null }
) => {
  const rows = [
    {
      columns: [
        {
          item: RunsheetItems.ID,
          required: true,
          message: t('SmartJobs.Input.ValidationMessage.Type')
        },
        {
          item: RunsheetItems.NAME,
          required: true,
          message: t('SmartJobs.Input.ValidationMessage.Name')
        }
      ]
    },
    {
      columns: [
        {
          item: RunsheetItems.SCHEDULE,
          required: true,
          message: t('SmartJobs.Input.ValidationMessage.Date')
        },
        {
          item: RunsheetItems.VEHICLE,
          data: vehicles,
          disabled: editing && isRunsheetInProgress(extraConfig?.runsheet)
        }
      ]
    }
  ];

  return rows.map(row => ({
    columns: row.columns.map(column =>
      getFormColumn(
        column.item,
        column.required,
        column.message,
        column.data,
        11,
        t,
        editing,
        localization,
        column.disabled
      )
    )
  }));
};

const runsheetFromServer = (runsheet, action) => {
  const isCopy = action === 'Copy';
  return (
    runsheet && {
      ...runsheet,
      [RunsheetItems.VEHICLE.key]: isCopy ? null : runsheet[RunsheetItems.VEHICLE.key],
      [ColumnKeys.DEVICE]: isCopy ? null : runsheet[ColumnKeys.DEVICE],
      [ColumnKeys.DRIVER]: isCopy ? null : runsheet[ColumnKeys.DRIVER],
      externalId: isCopy ? `Copy-${runsheet.externalId}` : runsheet.externalId,
      jobs: jobsFromServer(runsheet, isCopy)
    }
  );
};

const runsheetFormInitialValues = runsheet => {
  if (!runsheet) return;

  return {
    [RunsheetItems.ID.key]: runsheet[RunsheetItems.ID.key],
    [RunsheetItems.NAME.key]: runsheet[RunsheetItems.NAME.key],
    [RunsheetItems.SCHEDULE.key]: moment(runsheet[RunsheetItems.SCHEDULE.key]),
    ...(runsheet[RunsheetItems.VEHICLE.key] && {
      [RunsheetItems.VEHICLE.key]: runsheet[RunsheetItems.VEHICLE.key].id
    })
  };
};

export const deleteRemoteJob = async (runsheetId, jobId, job, dispatch) => {
  if (!jobId) {
    return;
  }
  try {
    const deleteResponse = await dispatch(removeJob({ runsheetId, jobId }));
    unwrapResult(deleteResponse);

    dispatch(
      openToast({
        type: ToastType.Success,
        message: `${job.customerName} successfully deleted!`
      })
    );
    return true;
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `Something went wrong: ${err.message}!`
      })
    );
    return false;
  }
};

export const RunsheetForm = props => {
  const { state } = useLocation();

  // If edit or copy - get runsheet
  const usedRunsheet = useRunsheet(state?.runsheet?.id);
  const [currentRunsheet, setCurrentRunsheet] = useState({});
  const allLocations = useLocations([LocationType.CUSTOMER]);
  const locations = allLocations.filter(location => location.type?.code === LocationType.CUSTOMER);

  const localization = useLocalization();
  const [isDirty, setIsDirty] = useState(false);

  const [jobs, updateJobs] = useState(currentRunsheet?.jobs || []);
  const [editing, setEditing] = useState(null);
  const [isAddJobOpen, setIsAddJobOpen] = useState(false);
  const [runsheetForm] = Form.useForm();
  const dispatch = useDispatch();
  const vehicles = useVehicles();
  const vehiclesForForm = vehicles
    .filter(v => !!v?.id)
    .map(vehicle => ({ id: vehicle.id, label: vehicle.name }));
  const history = useHistory();
  const isRunsheetSaving = useIsRunsheetSaving();
  const { t } = useTranslation();
  const isEdit = props.action === 'Edit';
  const itemTypes = useItemTypes();
  const can = useCan();
  const hasCompanyChanged = useIsCompanyKeyDifferent();

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useRedirectToMainFeaturePageOnCompanyChange('/smartjobs');

  useEffect(() => {
    const parsedCurrentRunsheet = runsheetFromServer(usedRunsheet, props.action);
    setCurrentRunsheet(parsedCurrentRunsheet);
    updateJobs(parsedCurrentRunsheet?.jobs?.map(reorderSequence) || []);

    runsheetForm.setFieldsValue(runsheetFormInitialValues(parsedCurrentRunsheet));
  }, [usedRunsheet]);

  useEffect(() => {
    // Set page title
    dispatch(
      setPageTitle(
        `${
          currentRunsheet
            ? `${props.action} ${t('SmartJobs.Runsheet')} ${currentRunsheet.name || ''}`
            : t('SmartJobs.addNewRunsheet')
        }`
      )
    );
  }, [currentRunsheet, props.action]);

  const handleAddJob = job => {
    const updatedJobs = jobs.filter(j =>
      j.id ? j.id !== job.id : j.externalId !== job.externalId
    );
    updateJobs(
      [...updatedJobs, { ...job, seq: editing ? job.seq : jobs.length + 1 }].sort((a, b) =>
        compareByNumberProps(a, b, 'seq')
      )
    );
    // Close the modal
    setIsAddJobOpen(false);
    setIsDirty(true);
  };

  // SAVE THE RUNSHEET
  const handleSaveRunsheet = async () => {
    const runsheetFormMainValues = runsheetForm.getFieldsValue();

    const isRunsheetFormValid = isFormValid(runsheetFormMainValues, [
      RunsheetItems.ID.key,
      RunsheetItems.NAME.key
    ]);

    if (!isRunsheetFormValid) {
      runsheetForm.validateFields();
      return;
    }

    // Get the main values (runsheetId, name, scheduledAt, vehicle)
    if (currentRunsheet) {
      const vehicleToServer = { id: runsheetFormMainValues.vehicle };
      const { fleet, ...runsheetItems } = currentRunsheet;

      const values = {
        ...runsheetItems,
        ...runsheetFormMainValues,
        jobs: jobsToServer(jobs, locations, state?.runsheet?.jobs),
        vehicle: { id: vehicleToServer.id || -1 },
        status: RunsheetStatus.CREATED
      };
      const isVehicleChanged =
        (!!vehicleToServer?.id &&
          !!currentRunsheet?.vehicle?.id &&
          parseInt(vehicleToServer.id, 10) !== parseInt(currentRunsheet.vehicle.id, 10)) ||
        (!vehicleToServer?.id && !!currentRunsheet?.vehicle?.id) ||
        (!!vehicleToServer?.id && !currentRunsheet?.vehicle?.id);

      if (isVehicleChanged) {
        //The device and driver of an existing runsheet are no longer applicable to the re-assigned vehicle
        if (isEdit) {
          values.device = { id: -1 };
          values.user = { id: -1 };
        } else {
          delete values.device;
          delete values.user;
        }
      }
      // Testing if where are editing a runsheet or copying to a new one
      if (isEdit) {
        const updateRunsheet = await dispatch(saveRunsheet(values, true, t));
        if (updateRunsheet) {
          setIsDirty(false);
          history.push(`${Paths.SMARTJOBS_VIEW}/${updateRunsheet.id}`);
        }
        return;
      } else {
        const isRunsheetSaved = await dispatch(saveRunsheet(values, false, t));
        if (isRunsheetSaved) {
          setIsDirty(false);
          history.push('/smartjobs/runsheets');
        }
        return;
      }
    } else {
      const formValues = {
        ...runsheetFormMainValues,
        vehicle: {
          id: runsheetFormMainValues.vehicle ? runsheetFormMainValues?.vehicle : -1
        },
        jobs: jobsToServer(jobs, locations, [])
      };

      // Get the jobs and add them to the main values object
      const isRunsheetSaved = await dispatch(saveRunsheet(formValues, false, t));
      if (isRunsheetSaved) {
        setIsDirty(false);
        history.push('/smartjobs/runsheets');
      }
      return;
    }
  };

  const closeRunsheetForm = () => {
    setIsDirty(false);

    confirmationModal(
      t('SmartJobs.Confirmation.confirm'),
      t('SmartJobs.Confirmation.changes'),
      t('SmartJobs.Confirmation.cancel'),
      t('SmartJobs.Confirmation.stay'),
      () => {
        history.push('/smartjobs/runsheets');
      },
      null,
      () => {
        setIsDirty(true);
      }
    );
  };

  const closeJobForm = () => {
    setIsAddJobOpen(false);
  };

  const handleOpenJob = job => () => {
    setEditing(job);
    setIsAddJobOpen(true);
  };

  // DELETE JOB
  const handleDeleteJob = async (runsheetId, jobId, jobToDelete) => {
    const isLocal = !jobId;
    // delete local job
    if (isLocal) {
      updateJobs(
        [...jobs.filter(job => job.externalId !== jobToDelete.externalId)]
          .sort((a, b) => compareByNumberProps(a, b, 'seq'))
          .map(reorderSequence)
      );

      dispatch(
        openToast({
          type: ToastType.Success,
          message: `${jobToDelete.customerName} successfully deleted!`
        })
      );
    } else {
      const deleted = await deleteRemoteJob(runsheetId, jobId, jobToDelete, dispatch);
      if (deleted) {
        updateJobs(
          [...jobs.filter(job => job.id !== jobToDelete.id)]
            .sort((a, b) => compareByNumberProps(a, b, 'seq'))
            .map(reorderSequence)
        );
      }
    }
  };

  const handleFormFieldsChange = (_, all) => {
    if (all.some(field => field.touched)) {
      setIsDirty(true);
    }
  };

  return (
    <>
      <div className={style.wrapper}>
        <EditRouteGuard when={isDirty && !hasCompanyChanged} navigate={history.push} />

        <JobForm
          onAddJob={handleAddJob}
          isOpen={isAddJobOpen}
          onCancel={closeJobForm}
          locations={locations}
          editing={editing}
          localization={localization}
          itemTypes={itemTypes}
        />
        <div className={style.formContainer}>
          <Form
            initialValues={runsheetFormInitialValues(currentRunsheet)}
            form={runsheetForm}
            onFieldsChange={handleFormFieldsChange}
            layout="vertical"
          >
            {rowRenderer(
              getRowsForRunsheetForm(vehiclesForForm, t, isEdit, localization, {
                runsheet: currentRunsheet
              })
            )}
          </Form>
        </div>
        {can && can({ everyEntity: [entities.JOB_CREATE, entities.JOB] }) && (
          <Button
            type="secondary"
            className={style.addNewJob}
            onClick={handleOpenJob(null)}
            disabled={currentRunsheet?.status === RunsheetStatus.OPENED}
            id={BUTTON_IDS.runsheetFormAdd}
          >
            {t('SmartJobs.addNewJob')}
          </Button>
        )}
        {can && can({ everyEntity: [entities.JOB_VIEW, entities.JOB] }) && (
          <>
            <Divider className={style.divider} orientation="left" plain>
              {t('SmartJobs.Jobs')}
            </Divider>
            {!!jobs?.length && (
              <SortableTable
                jobs={jobs}
                setJobs={updateJobs}
                isEdit={isEdit}
                rowProps={{
                  runsheet: state?.runsheet,
                  locations,
                  handlers: {
                    onEditJob: handleOpenJob,
                    onDeleteJob: handleDeleteJob,
                    onShareJob: null
                  },
                  translation: t
                }}
                columns={getRunsheetJobsTableColumns(t)}
                pagination={false}
                expandable={{
                  expandedRowRender: record => (
                    <Table
                      dataSource={prepareItemsForRunsheetTable(
                        (record.expandedItems || []).map(item => ({
                          ...item,
                          type:
                            itemTypes.find(itemType => itemType.code === item.type)?.description ||
                            item.type
                        })),
                        t
                      )}
                      columns={getRunsheetItemsTableColumns(t)}
                      rowKey="id"
                      pagination={false}
                    />
                  ),
                  rowExpandable: record => record.expandedItems && record.name !== 'Not Expandable',
                  expandIcon: ({ expanded, onExpand, record }) =>
                    record.expandedItems ? (
                      expanded ? (
                        <UpOutlined onClick={e => onExpand(record, e)} />
                      ) : (
                        <DownOutlined onClick={e => onExpand(record, e)} />
                      )
                    ) : null
                }}
              />
            )}
          </>
        )}
      </div>
      <FormFooter
        handleSave={handleSaveRunsheet}
        handleCancel={closeRunsheetForm}
        isSaving={isRunsheetSaving}
      />
    </>
  );
};
