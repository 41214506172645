const states = {
  ACT: 'ACT',
  NSW: 'NSW',
  NT: 'NT',
  QLD: 'QLD',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA'
};

export const EMPTY_EVENT = {
  action: '',
  ruleset: '',
  timeZone: '',
  location: '',
  eventAt: '',
  keeperId: ''
};

export const loginTypes = [
  { label: 'Driver', value: 'DRIVER' },
  { label: 'Record Keeper', value: 'RECORD_KEEPER' }
];

export const TIMEZONE_OPTIONS = [
  { value: 'Australia/Canberra', label: states.ACT },
  { value: 'Australia/Sydney', label: states.NSW },
  { value: 'Australia/Darwin', label: states.NT },
  { value: 'Australia/Brisbane', label: states.QLD },
  { value: 'Australia/Adelaide', label: states.SA },
  { value: 'Australia/Hobart', label: states.TAS },
  { value: 'Australia/Melbourne', label: states.VIC },
  { value: 'Australia/Perth', label: states.WA }
];

export const ACTION_OPTIONS = [
  { value: 'Work', label: 'Work' },
  { value: 'Rest', label: 'Rest' }
];

export const RULESET_OPTIONS = [
  { value: 'Standard', label: 'Standard' },
  { value: 'BFM', label: 'BFM' },
  { value: 'BUS', label: 'Bus' }
];

export const TZ2STATE = {
  'Australia/Canberra': 'ACT',
  'Australia/Sydney': 'NSW',
  'Australia/Darwin': 'NT',
  'Australia/Brisbane': 'QLD',
  'Australia/Adelaide': 'SA',
  'Australia/Hobart': 'TAS',
  'Australia/Melbourne': 'VIC',
  'Australia/Perth': 'WA'
};

export const STATE2TZ = {
  ACT: 'Australia/Canberra',
  NSW: 'Australia/Sydney',
  NT: 'Australia/Darwin',
  QLD: 'Australia/Brisbane',
  SA: 'Australia/Adelaide',
  TAS: 'Australia/Hobart',
  VIC: 'Australia/Melbourne',
  WA: 'Australia/Perth'
};

export const EVENT_STATUSES = {
  M: 'Modified',
  E: 'Enabled',
  L: 'Locked'
};

export const RulesetNames = {
  STD1: 'STD1',
  STD2: 'STD2',
  STD3: 'STD3',
  BUS1: 'BUS1',
  BFM1: 'BFM1',
  BFM2: 'BFM2',
  BFM3: 'BFM3',
  WA14: 'WA14',
  WA28: 'WA28',
  LFS1: 'LFS1',
  EWD1: 'EWD1',
  EWD2: 'EWD2',
  EWD3: 'EWD3',
  EWD4: 'EWD4',
  EWD5: 'EWD5',
  NZEDL1: 'NZEDL1',
  NZEDL2: 'NZEDL2',
  CGE1: 'CGE1',
  ELG1: 'ELG1',
  ELG2: 'ELG2',
  ELG3: 'ELG3',
  ELG4: 'ELG4',
  GRY1: 'GRY1',
  MBH1: 'MBH1',
  MTN1: 'MTN1',
  MTN2: 'MTN2',
  SVN1: 'SVN1',
  SVN2: 'SVN2'
};
