import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';
import { useDispatch } from 'react-redux';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import InfoRow from 'components/form/info-row/InfoRow';
import {
  fetchSummaryByDriver,
  fetchSummaryByVehicle,
  useSummaryByDriver,
  useSummaryByVehicle
} from 'features/support/supportELDSlice';
import styles from 'components/tn/grid/grid.module.scss';
import { Button } from 'antd';
import { useCurrentCompany } from 'features/company/companySlice';
import moment from 'moment';

export function SummaryView() {
  const dispatch = useDispatch();
  const summaryDataByDriver = useSummaryByDriver();
  const summaryDataByVehicle = useSummaryByVehicle();
  const company = useCurrentCompany();
  const [selectedCompany, setSelectedCompany] = useState(company?.id);
  const { t } = useTranslation();
  const localization = useLocalization();
  const history = useHistory();
  const [summaryData, setSummaryData] = useState({});

  const { state } = useLocation();
  const entity = state?.data?.entity;
  const entityId = state?.data?.entityId;
  const entityName = state?.data?.entityName;

  useEffect(() => {
    if (summaryDataByDriver == null || summaryDataByVehicle == null) {
      dispatch(fetchSummaryByDriver());
      dispatch(fetchSummaryByVehicle());
      setSelectedCompany(company.id);
    }
  }, [dispatch, company, selectedCompany]);

  useEffect(() => {
    if (entity === 'vehicle') {
      const item = summaryDataByVehicle?.find(s => s.id === parseInt(entityId, 10));
      setSummaryData({ ...item });
    }
  }, [summaryDataByVehicle, entityId]);

  useEffect(() => {
    if (entity === 'driver') {
      const item = summaryDataByDriver?.find(s => s.id === parseInt(entityId, 10));
      setSummaryData({ ...item });
    }
  }, [summaryDataByDriver, entityId]);

  //console.log(summaryData);

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(
      setPageTitle(
        t('SupportToolsELD.ViewTitle', {
          entityName: entityName,
          entity: t(entity),
          time: moment().format(localization.formats.time.formats.dby_imp)
        })
      )
    );
  }, [dispatch, entityName]);

  const handleSummaryDetail = () => {
    history.push(`/supportTools/ELD/Summary/details/${entity}/${entityId}`, {
      data: { entity: entity, entityId: entityId, entityName: entityName }
    });
  };

  const handleButtonAction = action => () => {
    switch (action) {
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 32px',
          color: '#181c21'
        }}
      >
        <ViewHeaderWrapper data={summaryData} handleButtonAction={handleButtonAction}>
          <Button size="large" onClick={handleSummaryDetail} id={'btn_summaryDetails'}>
            {t('SupportToolsELD.Details')}
          </Button>
        </ViewHeaderWrapper>
        {summaryData && (
          <>
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.Driver')}
              value={
                summaryData?.driver
                  ? (summaryData?.driver?.firstName || '') +
                    ' ' +
                    (summaryData?.driver?.lastName || '')
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={
                entity === 'driver'
                  ? t('SupportToolsELD.SummaryColumns.DriverLastLoginStatus')
                  : t('SupportToolsELD.SummaryColumns.VehicleLastLoginStatus')
              }
              value={
                summaryData?.driver?.lastLogonStatus ? summaryData?.driver?.lastLogonStatus : ''
              }
              styles={styles}
            />
            <InfoRow
              label={
                entity === 'driver'
                  ? t('SupportToolsELD.SummaryColumns.DriverLastLoginDate')
                  : t('SupportToolsELD.SummaryColumns.VehicleLastLoginDate')
              }
              value={
                summaryData?.driver?.lastLogonDate
                  ? format(
                      new Date(summaryData?.driver?.lastLogonDate),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.Vehicle')}
              value={summaryData?.vehicle?.name ? summaryData?.vehicle?.name : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.VehicleVIN')}
              value={summaryData?.vehicle?.vin ? summaryData?.vehicle?.vin : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.HermesIMEI')}
              value={summaryData?.hermes?.imei ? summaryData?.hermes?.imei : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.HermesSIM')}
              value={summaryData?.hermes?.sim ? summaryData?.hermes?.sim : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.HermesType')}
              value={summaryData?.hermes?.type?.name ? summaryData?.hermes?.type?.name : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.HermesModel')}
              value={summaryData?.hermes?.model?.name ? summaryData?.hermes?.model?.name : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.HermesReleaseFWVersion')}
              value={summaryData?.hermes?.releaseVersion ? summaryData?.hermes?.releaseVersion : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.HermesCurrentFWVersion')}
              value={summaryData?.hermes?.currentVersion ? summaryData?.hermes?.currentVersion : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.HermesLocation')}
              value={summaryData?.hermes?.location ? summaryData?.hermes?.location : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.HermesEventTime')}
              value={
                summaryData?.hermes?.lastEventTime
                  ? format(
                      new Date(summaryData?.hermes?.lastEventTime),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.HermesServerTime')}
              value={
                summaryData?.hermes?.lastServerTime
                  ? format(
                      new Date(summaryData?.hermes?.lastServerTime),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.EMSOdometer', {
                unit: localization.formats.speed.unit.toUpperCase()
              })}
              value={
                summaryData?.ems?.odometer
                  ? localization.convertDistance(summaryData?.ems?.odometer)
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.EMSEngineHours')}
              value={summaryData?.ems?.engineHour ? summaryData?.ems?.engineHour : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.EMSEventTime')}
              value={
                summaryData?.ems?.lastEventTime
                  ? format(
                      new Date(summaryData?.ems?.lastEventTime),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.EMSServerTime')}
              value={
                summaryData?.ems?.lastServerTime
                  ? format(
                      new Date(summaryData?.ems?.lastServerTime),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.MobileIMEI')}
              value={summaryData?.mobile?.imei ? summaryData?.mobile?.imei : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.MobileSerialNumber')}
              value={summaryData?.mobile?.serialNumber ? summaryData?.mobile?.serialNumber : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.MobileSIM')}
              value={summaryData?.mobile?.sim ? summaryData?.mobile?.sim : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.MobileType')}
              value={summaryData?.mobile?.type?.name ? summaryData?.mobile?.type?.name : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.MobileModel')}
              value={summaryData?.mobile?.model?.name ? summaryData?.mobile?.model?.name : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.MobileReleaseFWVersion')}
              value={summaryData?.mobile?.releaseVersion ? summaryData?.mobile?.releaseVersion : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.MobileCurrentFWVersion')}
              value={summaryData?.mobile?.currentVersion ? summaryData?.mobile?.currentVersion : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.MobileLocation')}
              value={summaryData?.mobile?.location ? summaryData?.mobile?.location : ''}
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.MobileEventTime')}
              value={
                summaryData?.mobile?.lastEventTime
                  ? format(
                      new Date(summaryData?.mobile?.lastEventTime),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.MobileServerTime')}
              value={
                summaryData?.mobile?.lastServerTime
                  ? format(
                      new Date(summaryData?.mobile?.lastServerTime),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.PowerDataDiagnostics')}
              value={
                summaryData?.diagnostic?.powerData
                  ? format(
                      new Date(summaryData?.diagnostic?.powerData),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.EngineSyncDiagnostics')}
              value={
                summaryData?.diagnostic?.engineSynchronization
                  ? format(
                      new Date(summaryData?.diagnostic?.engineSynchronization),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.MissingDataElementsDiagnostics')}
              value={
                summaryData?.diagnostic?.missingDataElement
                  ? format(
                      new Date(summaryData?.diagnostic?.missingDataElement),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.DataTransferDiagnostics')}
              value={
                summaryData?.diagnostic?.dataTransfer
                  ? format(
                      new Date(summaryData?.diagnostic?.dataTransfer),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.UDTDiagnostics')}
              value={
                summaryData?.diagnostic?.unidentifiedDriving
                  ? format(
                      new Date(summaryData?.diagnostic?.unidentifiedDriving),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.OtherELDDiagnostics')}
              value={
                summaryData?.diagnostic?.other
                  ? format(
                      new Date(summaryData?.diagnostic?.other),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.PowerMalfunction')}
              value={
                summaryData?.malfunction?.powerData
                  ? format(
                      new Date(summaryData?.malfunction?.powerData),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.EngineSyncMalfunction')}
              value={
                summaryData?.malfunction?.engineSynchronization
                  ? format(
                      new Date(summaryData?.malfunction?.engineSynchronization),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.TimingMalfunction')}
              value={
                summaryData?.malfunction?.timing
                  ? format(
                      new Date(summaryData?.malfunction?.timing),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.PositioningMalfunction')}
              value={
                summaryData?.malfunction?.positioning
                  ? format(
                      new Date(summaryData?.malfunction?.positioning),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.DataRecordingMalfunction')}
              value={
                summaryData?.malfunction?.dataRecording
                  ? format(
                      new Date(summaryData?.malfunction?.dataRecording),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.DataTransferMalfunction')}
              value={
                summaryData?.malfunction?.dataTransfer
                  ? format(
                      new Date(summaryData?.malfunction?.dataTransfer),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('SupportToolsELD.SummaryColumns.OtherELDMalfunction')}
              value={
                summaryData?.malfunction?.other
                  ? format(
                      new Date(summaryData?.malfunction?.other),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
          </>
        )}
      </div>
    </React.Fragment>
  );
}
