import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useFleeetsByCompanyId } from 'features/fleets/fleetsSlice';
import { useCan, entities } from 'features/permissions';

import styles from '../DevicesBulkEdit.module.scss';

export const DeviceVehicleCell = ({ companyId, vehicleId }) => {
  const { t } = useTranslation();

  const can = useCan();
  const canViewVehicle = can({ everyEntity: [entities.VEHICLE_VIEW] });

  const { vehicles: vehicleByCompanyId } = useFleeetsByCompanyId({ companyId });
  const deviceVehicle = useMemo(
    () => vehicleByCompanyId?.find(v => String(v.id) === String(vehicleId)),
    [vehicleByCompanyId, vehicleId]
  );

  return (
    <div className={styles.cellMultilineContainer}>
      {deviceVehicle?.id ? (
        <>
          <div className={styles.cellSubtitle}>{t('Devices.Name')}</div>
          {canViewVehicle ? (
            <Link to={`/settings/vehicles/id/${deviceVehicle.id}`}>{deviceVehicle.name}</Link>
          ) : (
            <span>{deviceVehicle.name}</span>
          )}
          <div className={styles.cellSubtitle}>{t('Devices.Registration')}</div>
          <div>{`${deviceVehicle?.registrationState} | ${deviceVehicle.registration}`}</div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};
