import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const vehicleGridSlice = createSlice({
  name: 'vehicleGrid',
  initialState: {},
  reducers: {
    reset(state) {
      state.searchText = '';
      state.filterFleets = undefined;
      state.filterTreeColumns = undefined;
      state.sortColumn = undefined;
      state.sortDirection = undefined;
    },
    setSearchText(state, { payload }) {
      state.searchText = payload;
    },
    setFilterFleets(state, { payload }) {
      state.filterFleets = payload;
    },
    setFilterTreeColumns(state, { payload }) {
      state.filterTreeColumns = payload;
    },
    setSortColumn(state, { payload }) {
      state.sortColumn = payload;
    },
    setSortDirection(state, { payload }) {
      state.sortDirection = payload;
    }
  }
});

export const {
  reset,
  setSearchText,
  setFilterFleets,
  setFilterTreeColumns,
  setSortColumn,
  setSortDirection
} = vehicleGridSlice.actions;

export const useVehicleGrid = () => useSelector(state => state.vehicleGrid);

export default vehicleGridSlice.reducer;
