import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits } from 'features/audits';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { AuditsTable } from 'components/auditsTable/AuditsTable';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import { convertAuditPayloadToDisplay } from './helper';
import { parseErrorMessage } from 'utils/strings';

export const IQCameraConfigAudit = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localization = useLocalization();
  const currentCompanyId = useCurrentCompanyId();

  const onFetchError = useCallback(
    error => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Audits.AuditFetchError', { error: parseErrorMessage(error) })
        })
      );
    },
    [t]
  );

  const audits = useAudits(AUDIT_ENTITY.CAMERA_CONFIGURATION, currentCompanyId, onFetchError);

  useEffect(() => {
    dispatch(setPageTitle(t('CompanyConfig.IQCamera.Audit.Title')));
    dispatch(setBackButton(true));
  }, [t, dispatch]);

  const onRenderChangeItemValue = useCallback(
    (displayValue, { changeItem, colKey }) => {
      const item = colKey === 'from' ? changeItem[0] : changeItem[1];
      try {
        const parsed = JSON.parse(item);
        if (!Array.isArray(parsed) && Object.entries(parsed).length) {
          return Object.entries(parsed).map(([key, value]) => {
            const {
              displayName,
              displayValue: converedDisplayValue
            } = convertAuditPayloadToDisplay({
              t,
              localization,
              payloadName: key,
              payloadValue: value
            });
            return `${displayName} : ${converedDisplayValue}`;
          });
        }
      } catch (error) {
        return displayValue;
      }
      return displayValue;
    },
    [t, localization]
  );

  const onRenderChangeItemKey = useCallback(
    changeKey => {
      try {
        const dutyTypeKey = changeKey?.match(/^configuration.(light|medium|heavy)$/)?.[1];
        if (dutyTypeKey) {
          return t(`CompanyConfig.IQCamera.${dutyTypeKey}`);
        }
        return convertAuditPayloadToDisplay({ payloadName: changeKey, t, localization })
          .displayName;
      } catch (error) {
        return changeKey;
      }
    },
    [t, localization]
  );

  return (
    <AuditsTable
      audits={audits}
      entity={AUDIT_ENTITY.CAMERA_CONFIGURATION}
      onRenderChangeItemKey={onRenderChangeItemKey}
      onRenderChangeItemValue={onRenderChangeItemValue}
    />
  );
};
