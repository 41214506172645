import { useMemo } from 'react';
import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  status,
  loadingFailed,
  loadingStarted,
  loadingFinished,
  actionTypes
} from 'utils/reduxFetchingUtils';
import { ewdAPI } from 'features/ewd/endpoints/ewdAPI';
import { useEwdDriverId } from '../drivers/driversSlice';
import { RequestQueue } from 'features/requestQueue/RequestQueue';

export const initialState = {
  EWDStatus: {
    byDriverID: {},
    byDriverIDStatus: {},
    userSessionStatus: {}
  }
};

const EWDStatusSlice = createSlice({
  name: 'ewdStatus',
  initialState: initialState,
  reducers: {
    fetchEWDStatusFailure(state, { payload }) {
      state.EWDStatus.byDriverIDStatus[payload.driverId] = loadingFailed(state, payload.err);
    },
    fetchEWDStatusStart(state, { payload }) {
      state.EWDStatus.byDriverIDStatus[payload] = loadingStarted(state, payload);
    },
    fetchEWDStatusSuccess(state, { payload }) {
      state.EWDStatus.byDriverID[payload.driverId] = payload.EWDStatu;
      state.EWDStatus.byDriverIDStatus[payload.driverId] = loadingFinished(state, payload);
    },
    fetchEWDStatusCancelled(state, { payload }) {
      state.EWDStatus.byDriverIDStatus[payload.driverId] = status;
    },
    fetchUserSessionsFailure(state, { payload }) {
      state.EWDStatus.userSessionStatus[payload.companyId] = loadingFailed(state, payload.err);
    },
    fetchUserSessionsStart(state, { payload }) {
      state.EWDStatus.userSessionStatus[payload.companyId] = loadingStarted(state, payload);
    },
    fetchUserSessionsSuccess(state, { payload }) {
      for (let id in payload.data) {
        if (
          state.EWDStatus.byDriverID[id]?.pivotTimeAt == null ||
          payload.data[id].pivotTimeAt > state.EWDStatus.byDriverID[id]?.pivotTimeAt
        ) {
          state.EWDStatus.byDriverID[id] = payload.data[id];
        }
      }
      state.EWDStatus.userSessionStatus[payload.companyId] = loadingFinished(state, payload);
    },
    fetchUserSessionsCancelled(state, { payload }) {
      state.EWDStatus.userSessionStatus[payload.companyId] = status;
    }
  }
});

export const {
  fetchEWDStatusStart,
  fetchEWDStatusSuccess,
  fetchEWDStatusFailure,
  fetchEWDStatusCancelled,

  fetchUserSessionsStart,
  fetchUserSessionsSuccess,
  fetchUserSessionsFailure,
  fetchUserSessionsCancelled
} = EWDStatusSlice.actions;

export const fetchEWDStatusByDriverId = driverId => (dispatch, getState) => {
  const fetchingStatus = getState().ewd.EWDStatus.byDriverIDStatus[driverId];
  const jwt = getState().user?.currentEWDUser?.auth?.token;
  if (
    jwt &&
    fetchingStatus?.fetching !== actionTypes.processing &&
    moment().valueOf() - moment(fetchingStatus?.lastFetched || 0).valueOf() >= 60000
  ) {
    dispatch(fetchEWDStatusStart(driverId));
    let abortController = new AbortController();
    const reqHandle = RequestQueue.queueRequest(
      () => ewdAPI.driverStatus(driverId, abortController.signal),
      result => dispatch(fetchEWDStatusSuccess({ driverId, EWDStatu: result })),
      err => {
        if (err?.name !== 'AbortError') {
          console.error(err);
          dispatch(fetchEWDStatusFailure({ driverId, err: err.toString() }));
        }
      },
      () => {
        abortController.abort();
        dispatch(fetchEWDStatusCancelled({ driverId }));
      }
    );
    return reqHandle;
  }
};

export const fetchEWDStatus = (
  keeperId,
  companyId,
  drivers,
  cancelHandle,
  fetchSingleDriverStatus,
  subCompanies
) => async (dispatch, getState) => {
  const userSessionFetchStatus = getState().ewd.EWDStatus.userSessionStatus[companyId];
  if (
    userSessionFetchStatus?.fetching !== actionTypes.processing &&
    moment().valueOf() - moment(userSessionFetchStatus?.lastFetched || 0).valueOf() >= 60000
  ) {
    const driversDataMap = {};

    dispatch(fetchUserSessionsStart({ companyId }));

    let abortController = new AbortController();
    cancelHandle.current = () => {
      abortController.abort();
      dispatch(fetchUserSessionsCancelled({ companyId }));
    };

    try {
      let keeperIds = [];
      let data = [];
      keeperIds.push(keeperId);
      if (subCompanies) {
        keeperIds = keeperIds.concat(Object.values(subCompanies));
        for (let id of keeperIds) {
          const responseData = await ewdAPI.drivers('statusreport', abortController.signal, id);
          data = data.concat(responseData);
        }
      }

      const driverStatusData = {};

      drivers.forEach(({ id, ewdDriverId }) => {
        const driverData = data.find(d => d.id === ewdDriverId);
        if (driverData != null) {
          let lastStatusReport = driverData.lastStatusReport;
          driversDataMap[ewdDriverId] = Boolean(lastStatusReport);
          if (driversDataMap[ewdDriverId]) {
            driverStatusData[ewdDriverId] = JSON.parse(lastStatusReport);
          }
        }
      });
      dispatch(fetchUserSessionsSuccess({ companyId, data: driverStatusData }));
    } catch (err) {
      if (err?.name !== 'AbortError') {
        dispatch(fetchUserSessionsFailure({ companyId, err: err.toString() }));
        cancelHandle.current = null;
      } else {
        dispatch(fetchUserSessionsCancelled({ companyId }));
        cancelHandle.current = null;
      }
    }

    if (fetchSingleDriverStatus) {
      //check if there are drivers without status report
      const requestHandles = [];
      cancelHandle.current = () => {
        for (let reqHandle of requestHandles) {
          RequestQueue.removeRequest(reqHandle);
        }
      };

      for (let i = 0; i < drivers?.length && cancelHandle.current; i++) {
        const d = drivers[i];
        if (!driversDataMap[d.ewdDriverId]) {
          const req = dispatch(fetchEWDStatusByDriverId(d.ewdDriverId));
          if (req) requestHandles.push(req);
        }
      }
    }
  }
};

export const useEWDStatus = () => {
  const driverId = useEwdDriverId();
  const EWDStatus = useSelector(state => state.ewd.EWDStatus.byDriverID)[driverId];
  return EWDStatus;
};

export const useEWDDriversStatus = () => {
  return useSelector(state => state.ewd.EWDStatus.byDriverID);
};

export const useEWDFetchingStatus = companyId => {
  const ewdStatus = useSelector(state => state.ewd.EWDStatus);

  const { isFetchingDriverEWDStatus, isFetchingUserSession } = useMemo(() => {
    const userSessionStatus = ewdStatus.userSessionStatus;
    const byDriverIDStatus = ewdStatus.byDriverIDStatus;
    const isFetchingUserSession =
      !companyId || userSessionStatus[companyId]?.fetching === actionTypes.processing;
    return {
      isFetchingUserSession,
      isFetchingDriverEWDStatus: driverId =>
        isFetchingUserSession || byDriverIDStatus[driverId]?.fetching === actionTypes.processing
    };
  }, [companyId, ewdStatus]);

  return { isFetchingDriverEWDStatus, isFetchingUserSession };
};

export default EWDStatusSlice.reducer;
