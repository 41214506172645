import { BulkEditAuditsTable } from 'components/auditsTable/BulkEditAudits';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { ToastType } from 'components/notifications/toasts/Toast';
import { BulkEditAudit_Columns } from 'containers/Administration/Devices/BulkEdit/helper';
import { useVehicleDeviceAssociationAudits } from 'features/audits';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { parseErrorMessage } from 'utils/strings';

export const IQCameraAssociationAudit = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentCompanyId = useCurrentCompanyId();

  const [columns] = useState(() =>
    Object.values(BulkEditAudit_Columns).map(col => ({ ...col, title: t(col.title) }))
  );

  const onFetchError = useCallback(
    error => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Audits.AuditFetchError', { error: parseErrorMessage(error) })
        })
      );
    },
    [t, dispatch]
  );

  const { audits, isLoading } = useVehicleDeviceAssociationAudits(
    AUDIT_ENTITY.CAMERA_VEHICLE_DEVICE_ASSOCIATION,
    currentCompanyId,
    onFetchError
  );

  useEffect(() => {
    dispatch(setPageTitle(t('CompanyConfig.IQCamera.Audit.Title')));
    dispatch(setBackButton(true));
  }, [t, dispatch]);

  return (
    <BulkEditAuditsTable
      dataSource={audits}
      columns={columns}
      entity={AUDIT_ENTITY.CAMERA_VEHICLE_DEVICE_ASSOCIATION}
      isLoading={isLoading}
    />
  );
};
