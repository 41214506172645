import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Progress, Space, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';

import { CameraTabs } from 'containers/home/Camera/constant';

import styles from './RichNotification.module.scss';

const getNotificationMeta = ({ notification }) => {
  const isProgress =
    notification.progressCurrent || notification.progressError || notification.progressTotal;
  let linkTo;
  if (isProgress) {
    linkTo = PATHS.BULK_MANAGEMENT;
  } else {
    switch (notification.title) {
      case 'Video Request':
      case 'Video Requests':
        linkTo = Object.values(CameraTabs).find(t => t.title === 'Video Requests')?.path;
        break;
      case 'Automatic Upload Events':
        linkTo = Object.values(CameraTabs).find(t => t.title === 'Events')?.path;
        break;
      default:
        break;
    }
  }
  return { isProgress, linkTo };
};

export const RichNotification = ({
  t,
  notification,
  createdAt,
  handleDismissRichNotification = () => {},
  onViewEmbedDetail = () => {}
}) => {
  const { isProgress, linkTo } = useMemo(() => getNotificationMeta({ notification }), [
    notification
  ]);
  if (isProgress) {
    return (
      <ProgressNotification
        t={t}
        notification={notification}
        createdAt={createdAt}
        linkTo={linkTo}
        handleDismissRichNotification={handleDismissRichNotification}
        onViewEmbedDetail={onViewEmbedDetail}
      />
    );
  } else {
    return (
      <NormalRichNotification
        t={t}
        notification={notification}
        createdAt={createdAt}
        linkTo={linkTo}
        handleDismissRichNotification={handleDismissRichNotification}
        onViewEmbedDetail={onViewEmbedDetail}
      />
    );
  }
};

const ProgressNotification = ({
  t,
  notification,
  createdAt,
  linkTo,
  onViewEmbedDetail = () => {},
  handleDismissRichNotification = () => {}
}) => {
  const { progressError, isCompleted, progressStatus } = useMemo(() => {
    const progressCurrent = notification.progressCurrent || 0;
    const progressError = notification.progressError || 0;
    const progressTotal = notification.progressTotal || 0;
    const isCompleted = progressCurrent + progressError === notification.progressTotal;
    return {
      progressError,
      isCompleted,
      progressStatus: `${progressCurrent} ${t(
        'Notifications.RichNotifications.of'
      )} ${progressTotal} ${t('Notifications.RichNotifications.imported')}, `
    };
  }, [notification]);
  return (
    <div className={styles.row}>
      <div className={styles.rowLeft}>
        <div className={styles.rowTitle}>
          {notification.title} {createdAt && `- ${createdAt}`}
        </div>
        <div className={styles.progress}>
          {isCompleted ? (
            <Progress
              percent={100}
              success={{
                percent: notification.progressCurrent / (notification.progressTotal / 100),
                strokeColor: '#B7EB8F'
              }}
              strokeColor={'#ffa39e'}
              showInfo={false}
            />
          ) : (
            <Progress
              percent={
                (notification.progressCurrent + notification.progressError) /
                (notification.progressTotal / 100)
              }
              strokeColor={'#1790FF'}
              trailColor={'#E6F7FF'}
              showInfo={false}
            />
          )}
        </div>
        <div>
          {progressStatus}
          <span className={progressError > 0 ? styles.errorBold : ''}>{`${progressError} ${t(
            'Notifications.RichNotifications.failed'
          )}`}</span>
          {isCompleted && (
            <span className={styles.completedBold}>{` - ${t(
              'Notifications.RichNotifications.Completed'
            )}`}</span>
          )}
        </div>
      </div>
      <div className={styles.rowRight}>
        {linkTo && (
          <span className={styles.viewLink}>
            <Link onClick={onViewEmbedDetail} to={{ pathname: linkTo }}>
              {t('Notifications.RichNotifications.View')}
            </Link>
          </span>
        )}
        {isCompleted && (
          <Tooltip title={t('Notifications.DismissNotification')} placement="topRight">
            <div
              className={styles.dismissButton}
              onClick={handleDismissRichNotification(notification.id)}
            >
              <FontAwesomeIcon icon={faCheck} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

const NormalRichNotification = ({
  t,
  notification,
  createdAt,
  linkTo,
  onViewEmbedDetail = () => {},
  handleDismissRichNotification = () => {}
}) => (
  <div className={styles.row}>
    <div className={styles.rowLeft}>
      <Space direction="vertical" size={4}>
        {`${notification.title}${createdAt ? ` - ${createdAt}` : ''}`}
        <span>{notification.subtitle}</span>
      </Space>
    </div>
    <div className={styles.rowRight}>
      {linkTo && (
        <span className={styles.viewLink}>
          <Link onClick={onViewEmbedDetail} to={{ pathname: linkTo }}>
            {t('Notifications.RichNotifications.View')}
          </Link>
        </span>
      )}
      <Tooltip title={t('Notifications.DismissNotification')} placement="topRight">
        <div
          className={styles.dismissButton}
          onClick={handleDismissRichNotification(notification.id)}
        >
          <FontAwesomeIcon icon={faCheck} />
        </div>
      </Tooltip>
    </div>
  </div>
);
