import { Brands } from './brands';

export const useActiveBrand = () => {
  return Object.values(Brands).find(brand => brand.domains.includes(window.location.hostname));
};

export const useIsThirdPartyBrand = () => {
  const brand = useActiveBrand();
  return brand && brand.name !== Brands.telematics.name;
};
