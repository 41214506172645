import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, FormGroup, FormCheck } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import FormInput from 'components/form/form-input/FormInput';
import { FormDatePicker } from 'components/ant';
import ControlledListMultiSelect from 'components/form/searchable-list-multi-select/ControlledListMultiSelect';
import { initialValuesForAddDocument } from '../constants';
import {
  useGetFiles,
  saveFileDetails,
  useIsFileSaving,
  useGetInitialValuesForEdit,
  setDirtyForm,
  useDocuments
} from 'features/easydocs/documentsSlice';
import { useGetUploadFolders } from 'features/easydocs/foldersSlice';
import { LoadingOutlined } from '@ant-design/icons';
import './FormModal.scss';
import style from './EditFilesForm.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLocalization } from 'features/localization/localizationSlice';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { BUTTON_IDS } from 'utils/globalConstants';

export const EditFilesForm = ({ onHide }) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const files = useGetFiles();
  const allFiles = (files || []).filter(file => file.success);
  const successfulFiles = allFiles.filter((obj, index) => {
    return index === allFiles.findIndex(item => item.documentId === obj.documentId);
  });

  const initialValuesForEdit = useGetInitialValuesForEdit();
  const dispatch = useDispatch();
  const isFileSaving = useIsFileSaving();
  const [hasFolderSelectionChanged, setHasFolderSelectionChanged] = useState(false);
  const folders = useGetUploadFolders();
  const documents = useDocuments();
  const isEdit = !!Object.keys(initialValuesForEdit).length;

  const defaultFolders = files.reduce((acc, file) => {
    acc[file.documentId] = folders;
    return acc;
  }, {});
  const [filteredFolders, updateFolders] = useState(defaultFolders);
  const [searchValues, updateSearchValues] = useState({});

  const handleSave = values => {
    const data = successfulFiles.map((file, index) => {
      const { documentId, name: filename } = file;
      let name = values[`name_${documentId}`] || filename;
      let description = values[`description_${documentId}`];
      let acknowledge = values[`acknowledge_${documentId}`];

      let hasExpirationDate = values[`hasExpirationDate_${documentId}`];
      let autoRemoval = values[`autoRemoval_${documentId}`];
      let expiredAt = values[`expiredAt_${documentId}`];

      if (!isEdit) {
        name = values['name'][index];
        description = values['description'][index];
        acknowledge = values['acknowledge'][index];

        hasExpirationDate = values['hasExpirationDate'][index];
        autoRemoval = values['autoRemoval'][index];
        expiredAt = values['expiredAt'][index];
      }

      if (expiredAt) {
        expiredAt = moment(expiredAt)
          .startOf('day')
          .format();
      }

      const documentFolders = (filteredFolders[documentId] || [])
        .filter(folder => folder.checked)
        .map(folder => {
          const { value, label, checked, ...documentFolders } = folder;
          return documentFolders;
        });

      let fileData = {
        id: documentId,
        filename,
        description,
        name,
        acknowledge,
        folders: documentFolders,
        autoRemoval,
        expiredAt
      };

      return fileData;
    });
    Mixpanel.sendTrackEvent(MPTrackingEvents.Easydocs.UploadDocuments);
    dispatch(saveFileDetails(data));
    onHide();
  };

  const handleSelect = fieldName => options => {
    const updatedValues = options.map(option => option.value);
    const updatedFolders = filteredFolders[fieldName].map(folder => {
      // If it's updated return the updated value
      if (updatedValues.includes(folder.value)) {
        return options.find(option => option.value === folder.value);
      }

      return folder;
    });
    setHasFolderSelectionChanged(true);
    updateFolders({ ...filteredFolders, [fieldName]: updatedFolders });
  };

  let initialAddValues = {
    ids: [],
    name: [],
    description: [],
    folders: [],
    acknowledge: [],
    hasExpirationDate: [],
    autoRemoval: [],
    expiredAt: []
  };

  if (!isEdit) {
    for (let i = 0; i < successfulFiles.length; i++) {
      const sf = successfulFiles[i];
      const existingFile = documents.find(doc => doc.id === sf.documentId);
      if (existingFile) {
        if (initialAddValues.ids.includes(sf.documentId)) {
          continue;
        }

        initialAddValues.name.push(existingFile.name);
        initialAddValues.description.push(existingFile.description);
        initialAddValues.acknowledge.push(existingFile.acknowledge);
        initialAddValues.ids.push(sf.documentId);
        initialAddValues.hasExpirationDate.push(existingFile.hasExpirationDate);
        initialAddValues.autoRemoval.push(existingFile.autoRemoval);
        initialAddValues.expiredAt.push(existingFile.expiryDate);
      } else {
        // default name to filename during initial upload
        initialAddValues.name.push(sf.name || '');
        initialAddValues.description.push('');
        initialAddValues.acknowledge.push(false);
        initialAddValues.folders.push([]);
        initialAddValues.hasExpirationDate.push(false);
        initialAddValues.autoRemoval.push(false);
        initialAddValues.expiredAt.push(null);
      }
    }
  }

  useEffect(() => {
    const keysInitialValuesArray = Object.keys(initialValuesForEdit);
    if (keysInitialValuesArray.length) {
      //Update the filteredFolders variable with the values from the initial edit values that have have the key which starts with folders
      const folderKeys = keysInitialValuesArray.filter(key => key.includes('folders'));
      const editFolders = files.reduce((acc, curr, i) => {
        acc[curr.documentId] = initialValuesForEdit[folderKeys[i]];
        return acc;
      }, {});
      updateFolders(editFolders);
    }
  }, [initialValuesForEdit, files]);

  useEffect(() => {
    // Update folders when adding
    if (!isEdit && successfulFiles) {
      let updatedFolders = { ...filteredFolders };
      for (let i = 0; i < successfulFiles.length; i++) {
        const file = successfulFiles[i];
        const existingFile = documents.find(doc => doc.id === file.documentId);
        if (existingFile) {
          updatedFolders[file.documentId] = (updatedFolders[file.documentId] || []).map(folder => ({
            ...folder,
            checked: (existingFile.folders || []).some(f => f.id === folder.id)
          }));
        }
      }
      updateFolders(updatedFolders);
    }
  }, []);
  const handleSearch = fieldName => value => {
    updateSearchValues({ ...searchValues, [fieldName]: value });
  };

  const getOptions = fieldName => {
    // if there's a search query - filter the options based on that search query
    const query = searchValues[fieldName];
    const sortedOptions = (filteredFolders[fieldName] || []).slice().sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    if (query) {
      return sortedOptions.filter(folder => folder.name.toLowerCase().includes(query));
    }

    return sortedOptions;
  };

  const headers = [
    `${t('Easydocs.Filename')}`,
    `${t('Easydocs.Name')}`,
    `${t('Easydocs.Description')}`,
    `${t('Easydocs.Folder')}`,
    `${t('Easydocs.Ack')}`
  ];

  return (
    <div className={'formContainer'}>
      <div className={'formHeader'}>
        {headers.map(header => (
          <div key={header} className={'formHeader__text'}>
            {header}
          </div>
        ))}
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={isEdit ? initialValuesForEdit : initialAddValues}
        onSubmit={handleSave}
      >
        {({ setFieldValue, dirty, values }) => {
          // Hook into expiration date change checkbox handler so can clear expired at date when unchecked
          const handleHasExpirationDateChange = (field, e, file, index) => {
            field.onChange(e);
            const { checked } = e.target;
            if (!checked) {
              const expiredAtFieldName = isEdit
                ? `expiredAt_${file.documentId}`
                : `expiredAt[${index}]`;
              setFieldValue(expiredAtFieldName, null);

              const autoRemovalFieldName = isEdit
                ? `autoRemoval_${file.documentId}`
                : `autoRemoval[${index}]`;
              setFieldValue(autoRemovalFieldName, false);
            }
          };

          // save should be disabled if one of the hasExpirationDate values is enabled but expiredAt is not set yet
          let canSave = true;
          if (successfulFiles) {
            successfulFiles.forEach((file, index) => {
              if (isEdit) {
                let name = values[`name_${file.documentId}`];
                !name.trim().length && (canSave = false);
              } else {
                values.name.some(item => item.trim() === '') && (canSave = false);
              }
              const hasExpirationDate = isEdit
                ? values[`hasExpirationDate_${file.documentId}`]
                : values['hasExpirationDate'][index];

              const expiredAt = isEdit
                ? values[`expiredAt_${file.documentId}`]
                : values['expiredAt'][index];

              if (canSave && hasExpirationDate && !expiredAt) {
                canSave = false;
              }
            });
          }

          dispatch(setDirtyForm(dirty || hasFolderSelectionChanged));
          return (
            <div className={style.showScrollbarsOnHover}>
              <Form id="form">
                <div className={style.wrapper}>
                  {successfulFiles.map((file, index) => (
                    <div key={index} className={style.fileContainer}>
                      <div className={style.fileName}>{file.name}</div>
                      <div className={style.nameCol}>
                        <FormInput
                          name={isEdit ? `name_${file.documentId}` : `name[${index}]`}
                          label=""
                          placeholder=""
                        />
                        <Field
                          name={
                            isEdit
                              ? `hasExpirationDate_${file.documentId}`
                              : `hasExpirationDate[${index}]`
                          }
                          type="checkbox"
                          checked={false}
                        >
                          {({ field }) => {
                            return (
                              <FormGroup
                                controlId="formHasExpiry"
                                className={style.hasExpirationDate}
                              >
                                <FormCheck
                                  {...field}
                                  type="checkbox"
                                  onChange={e =>
                                    handleHasExpirationDateChange(field, e, file, index)
                                  }
                                  label={t('Easydocs.DocumentHasAnExpirationDate')}
                                />
                              </FormGroup>
                            );
                          }}
                        </Field>
                        <Field
                          name={isEdit ? `autoRemoval_${file.documentId}` : `autoRemoval[${index}]`}
                          type="checkbox"
                          checked={false}
                        >
                          {({ field }) => (
                            <FormGroup controlId="formAutoRemoval" className={style.autoRemoval}>
                              <FormCheck
                                {...field}
                                type="checkbox"
                                label={t('Easydocs.AutoRemoveFromMobile')}
                                disabled={
                                  isEdit
                                    ? !values[`hasExpirationDate_${file.documentId}`]
                                    : !values.hasExpirationDate[index]
                                }
                              />
                            </FormGroup>
                          )}
                        </Field>
                      </div>
                      <div className={style.descriptionCol}>
                        <FormInput
                          name={isEdit ? `description_${file.documentId}` : `description[${index}]`}
                          label=""
                          placeholder=""
                          rows="2"
                          as="textarea"
                        />
                        <FormDatePicker
                          name={isEdit ? `expiredAt_${file.documentId}` : `expiredAt[${index}]`}
                          className={style.expiryDate}
                          format={localization.formats.time.formats.dby}
                          placeholder={''}
                          allowClear={false}
                          setFieldValue={setFieldValue}
                          disabled={
                            isEdit
                              ? !values[`hasExpirationDate_${file.documentId}`]
                              : !values.hasExpirationDate[index]
                          }
                          disabledDate={current => {
                            if (isEdit) {
                              const names = Object.keys(initialValuesForEdit).filter(
                                val => typeof val === 'string' && val.includes('name')
                              );
                              if (names[index]) {
                                const result = documents.find(
                                  item => item.name === initialValuesForEdit[names[index]]
                                );
                                if (result) {
                                  const uploadedDate = result.uploadedAt;
                                  const resultDate = moment(uploadedDate).startOf('day');
                                  return current.startOf('day') < resultDate;
                                }
                              }
                              return false;
                            } else {
                              return current && current < moment().startOf('day');
                            }
                          }}
                          keepZone
                        />
                      </div>
                      <ControlledListMultiSelect
                        name={isEdit ? `folders_${file.documentId}` : `folders[${index}]`}
                        placeholder={t('Easydocs.SearchForFolder')}
                        hideSelectAll
                        setFieldValue={setFieldValue}
                        options={getOptions(file.documentId)}
                        height={200}
                        onCheck={handleSelect(file.documentId)}
                        onSearch={handleSearch(file.documentId)}
                      />
                      <Field
                        name={isEdit ? `acknowledge_${file.documentId}` : `acknowledge[${index}]`}
                        type="checkbox"
                        checked={initialValuesForAddDocument.acknowledge}
                      >
                        {({ field }) => (
                          <FormGroup controlId="formAcknowledge">
                            <FormCheck {...field} type="checkbox" label="" />
                          </FormGroup>
                        )}
                      </Field>
                    </div>
                  ))}
                </div>
                <Button
                  id={BUTTON_IDS.editFilesFormSave}
                  type="submit"
                  disabled={isFileSaving || !canSave}
                  className={style.saveButton}
                >
                  {isFileSaving ? (
                    <span className={style.loading}>
                      <LoadingOutlined />
                    </span>
                  ) : (
                    <span>{t('Alerts.SaveChanges')}</span>
                  )}
                </Button>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
