const states = {
  ACT: 'ACT',
  NSW: 'NSW',
  NT: 'NT',
  QLD: 'QLD',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC'
};

export const EMPTY_EVENT = {
  action: '',
  ruleset: '',
  timeZone: '',
  location: '',
  eventAt: '',
  keeperId: ''
};

export const loginTypes = [
  { label: 'Driver', value: 'DRIVER' },
  { label: 'Record Keeper', value: 'RECORD_KEEPER' }
];

export const TIMEZONE_OPTIONS = [
  { value: 'Australia/Perth', label: states.WA },
  { value: 'Australia/Darwin', label: states.NT },
  { value: 'Australia/Adelaide', label: states.SA },
  { value: 'Australia/Canberra', label: states.ACT },
  { value: 'Australia/Melbourne', label: states.VIC },
  { value: 'Australia/Sydney', label: states.NSW },
  { value: 'Australia/Brisbane', label: states.QLD },
  { value: 'Australia/Hobart', label: states.TAS }
];

export const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const ACTIVITY_FORMAT = 'MMM DD, h:mm A (Z)';
export const INPUT_FORMAT = 'DD/MM/YYYY';

export const ACTION_OPTIONS = [
  { value: 'Work', label: 'Work' },
  { value: 'Rest', label: 'Rest' }
];

export const RULESET_OPTIONS = [
  { value: 'Standard', label: 'Standard' },
  { value: 'BFM', label: 'BFM' },
  { value: 'BUS', label: 'Bus' }
];

export const TZ2STATE = {
  'Australia/Canberra': 'ACT',
  'Australia/Sydney': 'NSW',
  'Australia/Darwin': 'NT',
  'Australia/Brisbane': 'QLD',
  'Australia/Adelaide': 'SA',
  'Australia/Hobart': 'TAS',
  'Australia/Melbourne': 'VIC',
  'Australia/Perth': 'WA'
};

export const STATE2TZ = {
  ACT: 'Australia/Canberra',
  NSW: 'Australia/Sydney',
  NT: 'Australia/Darwin',
  QLD: 'Australia/Brisbane',
  SA: 'Australia/Adelaide',
  TAS: 'Australia/Hobart',
  VIC: 'Australia/Melbourne',
  WA: 'Australia/Perth'
};

export const EVENT_STATUSES = {
  M: 'Modified',
  E: 'Enabled',
  L: 'Locked'
};
