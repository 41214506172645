import React from 'react';
import ReactExport from 'react-data-export';
import P from 'prop-types';
import moment from 'moment';
import { format } from 'utils/dates';
import { Button } from 'antd';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useTranslation } from 'react-i18next';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const formatInformationTableForExcelExport = (data, localization, t) => {
  const CELL_STYLE = {
    font: {
      bold: true
    }
  };
  const TITLE_DATA_COLUMNS = [
    t('Common.Vehicle'),
    t('Common.Device'),
    t('Common.Company'),
    t('Common.Fleet'),
    t('Easydocs.Received'),
    t('Easydocs.AutoRemove')
  ];

  const excelData = [
    {
      columns: [
        {
          title: TITLE_DATA_COLUMNS[0],
          width: { wch: 25 },
          style: { font: CELL_STYLE.font }
        },
        {
          title: TITLE_DATA_COLUMNS[1],
          width: { wch: 25 },
          style: { font: CELL_STYLE.font }
        },
        {
          title: TITLE_DATA_COLUMNS[2],
          width: { wch: 25 },
          style: { font: CELL_STYLE.font }
        },
        {
          title: TITLE_DATA_COLUMNS[3],
          width: { wch: 25 },
          style: { font: CELL_STYLE.font }
        },
        {
          title: TITLE_DATA_COLUMNS[4],
          width: { wch: 25 },
          style: { font: CELL_STYLE.font }
        },
        {
          title: TITLE_DATA_COLUMNS[5],
          width: { wch: 25 },
          style: { font: CELL_STYLE.font }
        }
      ],
      data: []
    }
  ];

  const rows = data.map(row => {
    return [
      {
        value: row.vehicleName ? row.vehicleName : ''
      },
      {
        value: row.deviceName ? row.deviceName : ''
      },
      {
        value: row.company ? row.company.name : ''
      },
      {
        value: row.fleet ? row.fleet : ''
      },
      {
        value: row.received
          ? format(
              new Date(moment(new Date(row.received))),
              localization.formats.time.formats.dby_imsp
            )
          : ''
      },
      {
        value: row.autoremoved
          ? format(
              new Date(moment(new Date(row.autoremoved))),
              localization.formats.time.formats.dby_imsp
            )
          : ''
      }
    ];
  });
  excelData[0].data = rows;
  return excelData;
};

const _ExportToExcelInformation = ({ data, document, localization, size }) => {
  const { t } = useTranslation();
  return (
    <ExcelFile
      element={
        <Button onClick={() => {}} size={size} id={BUTTON_IDS.easydocsInformationExportExcel}>
          {t('Easydocs.ExportToExcel')}
        </Button>
      }
      filename={t('Easydocs.Information') + ' - ' + `${document && document.name}`}
    >
      <ExcelSheet
        dataSet={formatInformationTableForExcelExport(data, localization, t)}
        name="Easydocs"
      />
    </ExcelFile>
  );
};

export const ExportToExcelInformation = React.memo(_ExportToExcelInformation);

ExportToExcelInformation.propTypes = {
  element: P.node.isRequired,
  data: P.array.isRequired,
  document: P.object.isRequired
};
