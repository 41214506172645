import { useEffect, useMemo } from 'react';
import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  status,
  loadingFailed,
  loadingStarted,
  loadingFinished,
  actionTypes
} from 'utils/reduxFetchingUtils';
import { fetchStatus } from 'features/sentinel/endpoints/sentinelAPI';
import { RequestQueue } from 'features/requestQueue/RequestQueue';
import { ApiClient, UsersApi } from 'nextgen_api';
import { API_PATH } from 'config';

export const initialState = {
  SentinelStatus: {
    byDriverID: {},
    byDriverIDStatus: {},
    userSessionStatus: {},
    timer: {
      currentTime: 0,
      timeHandle: null,
      refCount: 0
    }
  }
};

const SentinelStatusSlice = createSlice({
  name: 'sentinelStatus',
  initialState: initialState,
  reducers: {
    fetchSentinelStatusFailure(state, { payload }) {
      state.SentinelStatus.byDriverIDStatus[payload.driverId] = loadingFailed(state, payload.err);
    },
    fetchSentinelStatusStart(state, { payload }) {
      state.SentinelStatus.byDriverIDStatus[payload] = loadingStarted(state, payload);
    },
    fetchSentinelStatusSuccess(state, { payload }) {
      state.SentinelStatus.byDriverID[payload.driverId] = payload.SentinelStatus;
      state.SentinelStatus.byDriverIDStatus[payload.driverId] = loadingFinished(state, payload);
    },
    fetchSentinelStatusCancelled(state, { payload }) {
      state.SentinelStatus.byDriverIDStatus[payload.driverId] = status;
    },
    fetchUserSessionsFailure(state, { payload }) {
      state.SentinelStatus.userSessionStatus[payload.companyId] = loadingFailed(state, payload.err);
    },
    fetchUserSessionsStart(state, { payload }) {
      state.SentinelStatus.userSessionStatus[payload.companyId] = loadingStarted(state, payload);
    },
    fetchUserSessionsSuccess(state, { payload }) {
      for (let id in payload.data) {
        if (
          !state.SentinelStatus.byDriverID[id]?.pivotTimeAt ||
          payload.data[id].pivotTimeAt > state.SentinelStatus.byDriverID[id]?.pivotTimeAt
        ) {
          state.SentinelStatus.byDriverID[id] = payload.data[id];
        }
      }
      state.SentinelStatus.userSessionStatus[payload.companyId] = loadingFinished(state, payload);
    },
    fetchUserSessionsCancelled(state, { payload }) {
      state.SentinelStatus.userSessionStatus[payload.companyId] = status;
    },
    updateDriverStatus(state, { payload }) {
      state.SentinelStatus.byDriverID[payload.driverId] = payload.SentinelStatus;
    },
    increaseTimerRef(state) {
      if (state.SentinelStatus.timer.refCount <= 0) {
        state.SentinelStatus.timer.refCount++;
      }
    },
    decreaseTimerRef(state) {
      if (state.SentinelStatus.timer.refCount > 0) {
        state.SentinelStatus.timer.refCount--;
        if (state.SentinelStatus.timer.refCount <= 0) {
          clearInterval(state.SentinelStatus.timer.timeHandle);
          state.SentinelStatus.timer.timeHandle = null;
        }
      }
    },
    timeUpdated(state) {
      state.SentinelStatus.timer.currentTime = moment().valueOf();
    },
    setSentinelTimeHandle(state, payload) {
      state.SentinelStatus.timer.timeHandle = payload;
    }
  }
});

export const {
  fetchSentinelStatusStart,
  fetchSentinelStatusSuccess,
  fetchSentinelStatusFailure,
  fetchSentinelStatusCancelled,

  fetchUserSessionsStart,
  fetchUserSessionsSuccess,
  fetchUserSessionsFailure,
  fetchUserSessionsCancelled,

  updateDriverStatus,

  increaseTimerRef,
  decreaseTimerRef,
  timeUpdated,
  setSentinelTimeHandle
} = SentinelStatusSlice.actions;

export const fetchSentinelStatusByDriverId = driverId => (dispatch, getState) => {
  const fetchingStatus = getState().sentinel.SentinelStatus.byDriverIDStatus[driverId];
  if (
    fetchingStatus?.fetching !== actionTypes.processing &&
    moment().valueOf() - moment(fetchingStatus?.lastFetched || 0).valueOf() >= 60000
  ) {
    dispatch(fetchSentinelStatusStart(driverId));
    const abortController = new AbortController();
    return RequestQueue.queueRequest(
      () => fetchStatus(driverId, null, abortController.signal),
      result => {
        dispatch(fetchSentinelStatusSuccess({ driverId, SentinelStatus: result }));
      },
      err => {
        if (err?.name !== 'AbortError') {
          console.error(err);
          dispatch(fetchSentinelStatusFailure({ driverId, err: err.toString() }));
        }
      },
      () => {
        abortController.abort();
        dispatch(fetchSentinelStatusCancelled({ driverId }));
      }
    );
  }
};

export const fetchSentinelStatus = (userKey, companyId, drivers, cancelHandle) => async (
  dispatch,
  getState
) => {
  const userSessionFetchStatus = getState().sentinel.SentinelStatus.userSessionStatus[companyId];
  if (
    userSessionFetchStatus?.fetching !== actionTypes.processing &&
    moment().valueOf() - moment(userSessionFetchStatus?.lastFetched || 0).valueOf() >= 60000
  ) {
    const driversDataMap = {};

    dispatch(fetchUserSessionsStart({ companyId }));

    const usersFetchingPromise = new Promise((resolve, reject) => {
      const apiClient = new ApiClient();
      const userApi = new UsersApi(apiClient);
      apiClient.basePath = API_PATH;
      apiClient.defaultHeaders = {
        Authorization: `Token token="${userKey}"`
      };
      const req = userApi.findByCompanyId(
        null,
        {
          embed: 'user_session',
          company_id: companyId
        },
        (err, data, resp) => {
          if (err && (resp == null || resp.status !== 200)) {
            console.error(err);
            reject(err);
          } else {
            resolve(resp.body);
          }
        }
      );
      cancelHandle.current = () => {
        dispatch(fetchUserSessionsCancelled({ companyId }));
        req.abort();
      };
    });

    try {
      const data = await usersFetchingPromise;
      const driverStatusData = {};
      data.forEach(d => {
        const lastStatusReport = d.userSession?.lastStatusReport;
        driversDataMap[d.id] = !!lastStatusReport;
        if (driversDataMap[d.id]) {
          driverStatusData[d.id] = JSON.parse(lastStatusReport);
        }
      });
      dispatch(fetchUserSessionsSuccess({ companyId, data: driverStatusData }));
    } catch (err) {
      if (err?.name !== 'AbortError') {
        dispatch(fetchUserSessionsFailure({ companyId, err: err.toString() }));
      } else {
        dispatch(fetchUserSessionsCancelled({ companyId }));
      }
    }

    //check if there are drivers without status report
    const requestHandles = [];

    cancelHandle.current = () => {
      for (let reqHandle of requestHandles) {
        RequestQueue.removeRequest(reqHandle);
      }
    };

    for (let i = 0; i < drivers?.length && cancelHandle.current; i++) {
      const id = drivers[i];
      if (!driversDataMap[id]) {
        const req = dispatch(fetchSentinelStatusByDriverId(id));
        if (req) requestHandles.push(req);
      }
    }
  }
};

export const useSentinelDriversStatus = () => {
  return useSelector(state => state.sentinel.SentinelStatus.byDriverID);
};

export const useSentinelFetchingStatus = companyId => {
  const sentinelStatus = useSelector(state => state.sentinel.SentinelStatus);

  const { isFetchingDriverSentinalStatus, isFetchingUserSession } = useMemo(() => {
    const userSessionStatus = sentinelStatus.userSessionStatus;
    const byDriverIDStatus = sentinelStatus.byDriverIDStatus;
    const isFetchingUserSession =
      !companyId || userSessionStatus[companyId]?.fetching === actionTypes.processing;
    return {
      isFetchingUserSession,
      isFetchingDriverSentinalStatus: driverId =>
        isFetchingUserSession || byDriverIDStatus[driverId]?.fetching === actionTypes.processing
    };
  }, [companyId, sentinelStatus]);

  return { isFetchingDriverSentinalStatus, isFetchingUserSession };
};

const startTimer = () => async (dispatch, getState) => {
  const timer = getState().sentinel.SentinelStatus.timer;
  if (timer.refCount > 0 && !timer.timeHandle) {
    const timeHandle = setInterval(() => dispatch(timeUpdated()), 1000);
    dispatch(setSentinelTimeHandle(timeHandle));
  }
};

export const useSentinelTimer = () => {
  const time = useSelector(s => s.sentinel.SentinelStatus.timer.currentTime);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(increaseTimerRef());
    dispatch(startTimer());
    return () => {
      dispatch(decreaseTimerRef());
    };
  }, [dispatch]);

  return time || moment().valueOf();
};

export default SentinelStatusSlice.reducer;
