import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

export const confirmNavigateAway = (t, conditional, onLeave, onStay) => {
  conditional
    ? confirmationModal(
        t('Common.Modal.SureTitle'),
        t('Common.Modal.SureQuestionDiscardChanges'),
        `${t('Common.Modal.Discard')}`,
        `${t('Common.Modal.Stay')}`,
        onLeave,
        'warning',
        onStay
      )
    : onLeave();
};
