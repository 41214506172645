import React from 'react';
import { Table, Typography } from 'antd';
import { format } from 'utils/dates';
import i18n from 'i18next';
import { sortStrings } from 'utils/strings';

import styles from '../../BulkManagement.module.scss';
import moment from 'moment';

export const ColumnKeys = {
  NAME: 'fileName',
  TYPE: 'type',
  COMPLETED_BY: 'fullName',
  COMPLETED_DATE: 'completed_date',
  STATUS: 'status',
  ACTION: 'action'
};

const columns = localization => {
  return [
    {
      title: i18n.t('CompanyConfig.BulkManagement.FileName'),
      dataIndex: ColumnKeys.NAME,
      key: ColumnKeys.NAME,
      sorter: (a, b) => (a.fileName > b.fileName ? 1 : -1)
    },
    {
      title: i18n.t('CompanyConfig.BulkManagement.ImportType'),
      dataIndex: ColumnKeys.TYPE,
      key: ColumnKeys.TYPE,
      sorter: (a, b) => (a.type > b.type ? 1 : -1)
    },
    {
      title: i18n.t('CompanyConfig.BulkManagement.CompletedBy'),
      dataIndex: ColumnKeys.COMPLETED_BY,
      key: ColumnKeys.COMPLETED_BY,
      sorter: {
        compare: (a, b) => sortStrings(a.fullName, b.fullName)
      }
    },
    {
      title: i18n.t('CompanyConfig.BulkManagement.CompletedDate'),
      dataIndex: ColumnKeys.COMPLETED_DATE,
      key: ColumnKeys.COMPLETED_DATE,
      sorter: (a, b) => (a[ColumnKeys.COMPLETED_DATE] > b[ColumnKeys.COMPLETED_DATE] ? 1 : -1),
      render: e => {
        return e !== '' ? format(new Date(e), localization.formats.time.formats.dby_imp) : '';
      }
    },
    {
      title: i18n.t('CompanyConfig.BulkManagement.Status'),
      dataIndex: ColumnKeys.STATUS,
      key: ColumnKeys.STATUS
    },
    {
      title: i18n.t('CompanyConfig.BulkManagement.Action'),
      dataIndex: ColumnKeys.ACTION,
      key: ColumnKeys.ACTION
    }
  ];
};

export const prepareDataForTable = ({ bulkData, localization, handleErrorFileDownload }) => {
  return bulkData?.map(entry => {
    return {
      [ColumnKeys.NAME]: entry.fileName,
      [ColumnKeys.TYPE]: entry.type
        ? i18n.t(`CompanyConfig.BulkManagement.${entry.type.replace('Ng::', '')}`, '')
        : '',
      [ColumnKeys.COMPLETED_BY]: entry?.fullName,
      [ColumnKeys.COMPLETED_DATE]:
        entry.updatedAt && entry?.status !== 'IN_PROGRESS' ? entry.updatedAt : '',
      [ColumnKeys.STATUS]: (
        <div>
          {`${entry?.totalProcessed} ${i18n.t('CompanyConfig.BulkManagement.of')} ${
            entry?.totalRecord
          } ${i18n.t('CompanyConfig.BulkManagement.Imported')}`}
          {entry?.status === 'SUCCESS' && (
            <div className={`${styles.status} ${styles.statusSuccess}`}>
              {i18n.t('CompanyConfig.BulkManagement.Successful')}
            </div>
          )}
          {entry?.status === 'FAILED' && (
            <div className={`${styles.status} ${styles.statusFailed}`}>
              {i18n.t('CompanyConfig.BulkManagement.Failed')}
            </div>
          )}
          {entry?.status === 'PARTIAL' && (
            <div className={`${styles.status} ${styles.statusPartial}`}>
              {i18n.t('CompanyConfig.BulkManagement.Partial')}
            </div>
          )}
          {entry?.status === 'IN_PROGRESS' && (
            <div className={`${styles.status} ${styles.statusInProgress}`}>
              {i18n.t('CompanyConfig.BulkManagement.InProgress')}
            </div>
          )}
        </div>
      ),
      [ColumnKeys.ACTION]: ['FAILED', 'PARTIAL'].includes(entry?.status) && (
        <Typography.Link
          className={styles.downloadLink}
          onClick={() => handleErrorFileDownload({ entry })}
        >
          {i18n.t('CompanyConfig.BulkManagement.DownloadErrorFile')}
        </Typography.Link>
      ),
      key: entry.id
    };
  });
};

export const BulkManagementTable = ({
  bulkData,
  localization,
  loading,
  handleErrorFileDownload
}) => (
  <Table
    className={styles.table}
    columns={columns(localization)}
    dataSource={prepareDataForTable({ bulkData, localization, handleErrorFileDownload })}
    loading={loading}
    pagination={false}
    scroll={{ y: 'calc(100vh - 300px)' }}
    showSorterTooltip={false}
  />
);
