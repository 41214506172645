import React, { Component } from 'react';
import 'react-vis/dist/style.css';
import { RadialChart } from 'react-vis';
import styles from './Donut.module.scss';
import { getRandomInt as getRandomArbitrary } from '../../_dummy_data';
import ContentLoader from 'react-content-loader';

function getTotalData(data, showPiePercentage, focusedDataID) {
  let result = 0;
  let focusedValue = 0;
  data.forEach(d => {
    result += d.angle;
    if (d.id === focusedDataID) {
      focusedValue = d.angle;
    }
  });

  if (result && showPiePercentage && data.some(d => d.id === focusedDataID)) {
    result = parseInt((focusedValue / result).toFixed(2) * 100);
  }

  return result;
}

const DonutPlaceholder = () => (
  <ContentLoader
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ width: '90%', height: '90%' }}
  >
    <circle cx="100" cy="65" r="30" />
    <rect x="218" y="9" rx="0" ry="0" width="247" height="46" />
    <rect x="220" y="84" rx="0" ry="0" width="240" height="48" />
  </ContentLoader>
);

class Donut extends Component {
  state = {
    focusedDataID: '',
    mouseHoverID: ''
  };

  debugUpdateData() {
    this.setState({
      data: [
        {
          id: 1,
          angle: getRandomArbitrary(1, 333),
          color: '#61C5C6',
          message: 'completed'
        },
        {
          id: 2,
          angle: getRandomArbitrary(1, 333),
          color: '#23AEE2',
          message: 'open'
        },
        {
          id: 3,
          angle: getRandomArbitrary(1, 333),
          color: '#F3BD37',
          message: 'closed as incomplete'
        }
      ]
    });
  }

  handleOnValueClick(id) {
    const currentFocusedDataId = this.state.focusedDataID;
    const nextFocusedDataId = currentFocusedDataId !== id ? id : '';

    this.setState({ focusedDataID: nextFocusedDataId });
    if (this.props.onSelection) {
      this.props.onSelection(id);
    }
  }

  handleMouseOver(data) {
    this.setState({
      ...this.state,
      mouseHoverID: data.id
    });
  }

  handleMouseOut() {
    this.setState({
      ...this.state,
      mouseHoverID: null
    });
  }

  decorate(data) {
    const { focusedDataID, mouseHoverID } = this.state;

    return data.map(d => {
      let curColor = focusedDataID ? (d.id === focusedDataID ? d.color : '#f7f8f9') : d.color;
      curColor = d.id === mouseHoverID ? d.mousehoverColor : curColor;
      return {
        ...d,
        color: curColor
      };
    });
  }

  render() {
    const { focusedDataID } = this.state;
    const { title, isLoading, data } = this.props;
    const showPiePercentage = this.props.showPiePercentage ?? true;

    if (isLoading) return <DonutPlaceholder />;

    return (
      <div className={styles.widgetWrapper}>
        <div className={styles.donutWrapper}>
          <RadialChart
            className={styles.frontDonut}
            innerRadius={50}
            radius={68}
            data={this.decorate(data)}
            onValueClick={v => {
              this.handleOnValueClick(v.id);
            }}
            onValueMouseOver={data => this.handleMouseOver(data)}
            onValueMouseOut={() => this.handleMouseOut()}
            colorType="literal"
            width={200}
            height={160}
          />
          <RadialChart
            className={styles.backDonut}
            innerRadius={50}
            radius={68}
            data={data.map(d => ({
              ...d,
              radius: d.id === focusedDataID ? 1.5 : 1
            }))}
            colorType="literal"
            width={200}
            height={160}
          />
          <div className={styles.donutLabel}>
            <h1>
              {getTotalData(data, showPiePercentage, focusedDataID) +
                (focusedDataID && showPiePercentage ? '%' : '')}
            </h1>
            <p>{title ? title : 'Inspections'}</p>
          </div>
        </div>
        <div className={styles.drilldownWrapper}>
          {data.map(d => {
            const isFocused = d.id === focusedDataID;
            let rowValue = d.angle;
            let rowStyle = { borderColor: d.color };

            if (isFocused) {
              if (!showPiePercentage) {
                rowValue = parseInt((rowValue / getTotalData(data)).toFixed(2) * 100);
              }

              rowStyle = {
                ...rowStyle,
                backgroundColor: d.color,
                color: '#fff'
              };
            }

            return (
              <div
                key={d.id}
                className={styles.drilldownRow}
                style={rowStyle}
                onClick={() => this.handleOnValueClick(d.id)}
              >
                <h1>
                  {rowValue}
                  {isFocused && !showPiePercentage && <span>%</span>}
                </h1>
                <p>{d.message}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Donut;
