import moment from 'moment';

const DAYS_OF_WEEK = moment.weekdays();

const getFormattedWeekDays = (value, t) => {
  const dateTime = moment(value);

  return DAYS_OF_WEEK.map((day, index) => ({
    name: t(`Weekdays.${day}`),
    number: dateTime.day(index).format('DD'),
    month: dateTime.day(index).format('MMMM'),
    year: dateTime.day(index).format('YYYY'),
    date: dateTime.day(index).format('YYYY-MM-DD')
  }));
};

export const helpers = {
  getFormattedWeekDays
};
