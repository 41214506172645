import React, { Fragment } from 'react';
import { FormGroup, FormCheck, FormLabel, FormControl } from 'react-bootstrap';
import styles from './SpecificProperties.module.scss';
import FormTitle from 'components/form/form-title/FormTitle';
import removeIcon from 'static/images/icons/remove.svg';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { Can } from 'features/permissions';

const SelectionElement = ({
  element,
  dropdown,
  options,
  setProperty,
  setFollowUpProperty,
  isFollowUpElement,
  isPretripChecklist,
  ElementDynamicFields = () => null,
  SubFailedQuestions,
  setFailedQuestionProperty
}) => {
  const dispatch = useDispatch();
  if (options.length === 0) {
    let emptyOptions = [{ Value: '' }, { Value: '' }];
    if (isPretripChecklist) {
      emptyOptions = [
        { Value: '', Pass: true },
        { Value: '', Pass: true }
      ];
    }
    setProperty('Options', emptyOptions);
  }
  const setOption = (option, Value) => {
    const updatedOptions = options.map(opt => {
      if (option === opt) {
        delete option.Invalid;
        return {
          ...option,
          Value
        };
      }
      return opt;
    });
    setProperty('Options', updatedOptions);
  };
  const setPassOption = option => {
    const updatedOptions = options.map(opt => {
      if (option === opt) {
        return {
          ...opt,
          Pass: !opt.Pass
        };
      }
      return opt;
    });
    setProperty('Options', updatedOptions);
  };
  const addFailedQuestion = () => {
    setProperty('SubFailedQuestions', [{ Text: '', Type: 'Text', AllowBlank: false }]);
  };
  const deleteFailedQuestion = () => {
    setProperty('SubFailedQuestions', null);
  };
  const addOption = () => {
    if (isPretripChecklist) {
      if (options.length === 3) {
        setProperty('Dropdown', true);
        dispatch(
          openToast({
            type: ToastType.Warning,
            message: i18n.t('PreTripChecklist.Form.AutomaticallyChangeToDropdown'),
            autohide: true
          })
        );
      }
      setProperty('Options', [...options, { Value: '', Pass: true }]);
    } else {
      setProperty('Options', [...options, { Value: '' }]);
    }
  };
  const deleteOption = option => {
    setProperty(
      'Options',
      options.filter(opt => opt !== option)
    );
  };
  const toggleFollowUp = option => {
    if (option.FollowUp) {
      setFollowUpProperty(option, 'remove');
    } else {
      setFollowUpProperty(option, 'init');
    }
  };
  const randId = `dropdown-${Math.random()}`;
  return (
    <>
      {isPretripChecklist && (
        <FormGroup>
          <FormCheck
            style={{ display: 'inline-block' }}
            checked={dropdown}
            disabled={options.length > 3}
            onChange={() => setProperty('Dropdown', !dropdown)}
            id={randId}
          />
          <FormLabel
            htmlFor={randId}
            style={{
              fontSize: '15px',
              color: '#3e4856',
              paddingLeft: '5px',
              position: 'relative',
              top: '-3px'
            }}
          >
            {i18n.t('PreTripChecklist.View.Dropdown')}
          </FormLabel>
        </FormGroup>
      )}
      <FormTitle
        underlined
        title={i18n.t('Forms.ActualForm.SelectionOptions')}
        customStyling={{ fontWeight: '400', fontSize: '15px' }}
      />
      <div className={styles.listOptions}>
        {options.map((option, index) => {
          let hasFollowUp = option.FollowUp !== undefined;
          return (
            <Fragment key={`key-${index}`}>
              <div style={{ display: 'flex' }}>
                <FormControl
                  className={`${styles.input} ${option.Invalid ? styles.invalid : ''}`}
                  value={option.Value}
                  onChange={e => setOption(option, e.target.value)}
                  style={isFollowUpElement || isPretripChecklist ? { height: '30px' } : {}}
                />
                {!isFollowUpElement && !isPretripChecklist && (
                  <div
                    className={`${styles.followUpButton} ${hasFollowUp &&
                      styles.followUpButtonActive}`}
                    onClick={() => toggleFollowUp(option)}
                  >
                    {i18n.t('Forms.ActualForm.FollowUpUpperCase')}
                  </div>
                )}
                <i
                  className={`${styles.deleteButton} ${options.length <= 2 && styles.disabled}`}
                  style={{
                    content: `url(${removeIcon})`,
                    paddingRight: isPretripChecklist ? '10px' : '0px'
                  }}
                  onClick={() => deleteOption(option)}
                  title={i18n.t('PreTripChecklist.Form.RemoveOption')}
                />
              </div>
              {isPretripChecklist && (
                <>
                  <FormCheck
                    style={{ display: 'inline-block' }}
                    checked={option.Pass}
                    onChange={() => setPassOption(option)}
                    value={option.Pass}
                    id={`key-${index}`}
                  />
                  <FormLabel
                    style={{
                      fontSize: '15px',
                      color: '#3e4856',
                      paddingLeft: '5px',
                      position: 'relative',
                      top: '-3px'
                    }}
                    htmlFor={`key-${index}`}
                  >
                    {i18n.t('Forms.ActualForm.PassAnswer')}
                  </FormLabel>
                </>
              )}
              {hasFollowUp && (
                <FollowUp option={option} setFollowUpProperty={setFollowUpProperty} />
              )}
            </Fragment>
          );
        })}
      </div>
      <div className={!isPretripChecklist ? styles.wrapperButtonMargin : styles.wrapperButton}>
        <div className={styles.addButton} onClick={addOption}>
          {isFollowUpElement && !isPretripChecklist
            ? i18n.t('Forms.ActualForm.AddNewFollowUpOption')
            : i18n.t('Forms.ActualForm.AddNewOption')}
        </div>
      </div>
      {isPretripChecklist && <ElementDynamicFields element={element} />}
      {isPretripChecklist && !isFollowUpElement && !SubFailedQuestions && (
        <div className={styles.wrapperButtonMargin}>
          <div className={styles.addFailedQuestionButton} onClick={addFailedQuestion}>
            {i18n.t('Forms.ActualForm.AddFailedQuestion')}
          </div>
        </div>
      )}
      {SubFailedQuestions && (
        <FailedQuestion
          element={element}
          setFailedQuestionProperty={setFailedQuestionProperty}
          deleteFailedQuestion={deleteFailedQuestion}
        />
      )}
    </>
  );
};
const TextElement = ({ allowBlank, setProperty }) => {
  const randId = `id-${Math.random()}`;
  return (
    <FormGroup>
      <FormCheck
        style={{ display: 'inline-block' }}
        checked={allowBlank}
        onChange={() => setProperty('AllowBlank', !allowBlank)}
        id={randId}
      />
      <FormLabel
        htmlFor={randId}
        style={{
          fontSize: '15px',
          color: '#3e4856',
          paddingLeft: '5px',
          position: 'relative',
          top: '-3px'
        }}
      >
        {i18n.t('Forms.ActualForm.AllowBlank')}
      </FormLabel>
    </FormGroup>
  );
};
const SectionElement = () => {
  return <></>;
};
const DescriptionElement = ({ description, invalid, setProperty }) => {
  return (
    <FormGroup>
      <FormLabel style={{ fontSize: '15px' }}>{i18n.t('Forms.ActualForm.Description')}</FormLabel>
      <FormControl
        as="textarea"
        value={description}
        className={invalid && styles.invalid}
        rows={3}
        onChange={e => {
          setProperty('Content', e.target.value);
        }}
      />
    </FormGroup>
  );
};
const YesNoElement = ({
  allowNA,
  allowBlank,
  options,
  setProperty,
  setFollowUpProperty,
  isFollowUpElement,
  isPretripChecklist,
  ElementDynamicFields = () => null,
  element,
  SubFailedQuestions,
  setFailedQuestionProperty
}) => {
  const abId = `id-${Math.random()}`;
  const anaId = `id-${Math.random()}`;
  const toggleFollowUp = option => {
    if (option.FollowUp) {
      setFollowUpProperty(option, 'remove');
    } else {
      setFollowUpProperty(option, 'init');
    }
  };
  const setPassOption = option => {
    const updatedOptions = options.map(opt => {
      if (option === opt) {
        return {
          ...opt,
          Pass: !opt.Pass
        };
      }
      return opt;
    });
    setProperty('Options', updatedOptions);
  };
  const addFailedQuestion = () => {
    setProperty('SubFailedQuestions', [{ Text: '', Type: 'Text', AllowBlank: false }]);
  };
  const deleteFailedQuestion = () => {
    setProperty('SubFailedQuestions', null);
  };
  const updateNA = allowNA => {
    let newOptions = [...options];
    if (allowNA) {
      //fix
      if (isPretripChecklist) {
        newOptions.push({ Value: 'N/A', Pass: true });
      } else {
        newOptions.push({ Value: 'N/A' });
      }
    } else {
      newOptions = newOptions.filter(option => option.Value !== 'N/A');
    }
    setProperty('Options', newOptions);
    setProperty('AllowNonApplicable', allowNA);
  };
  return (
    <>
      <FormGroup>
        <div>
          <FormCheck
            style={{ display: 'inline-block' }}
            checked={allowNA}
            onChange={() => updateNA(!allowNA)}
            value={allowNA}
            id={abId}
          />
          <FormLabel
            htmlFor={abId}
            style={{
              fontSize: '15px',
              color: '#3e4856',
              paddingLeft: '5px',
              position: 'relative',
              top: '-3px'
            }}
          >
            {i18n.t('Forms.ActualForm.AllowNAOption')}
          </FormLabel>
        </div>
        {!isPretripChecklist && (
          <div>
            <FormCheck
              style={{ display: 'inline-block' }}
              checked={allowBlank}
              onChange={() => setProperty('AllowBlank', !allowBlank)}
              id={anaId}
            />
            <FormLabel
              htmlFor={anaId}
              style={{
                fontSize: '15px',
                color: '#3e4856',
                paddingLeft: '5px',
                position: 'relative',
                top: '-3px'
              }}
            >
              {i18n.t('Forms.ActualForm.AllowBlank')}
            </FormLabel>
          </div>
        )}
        {isPretripChecklist && (
          <div>
            <div style={{ display: 'inline-block' }}>{`${i18n.t(
              'PreTripChecklist.View.PassAnswer'
            )}:`}</div>
            <div
              style={{
                display: 'inline-flex !important',
                width: 'calc(100% - 95px)',
                marginLeft: '10px',
                justifyContent: 'flex-start',
                top: '2px',
                position: 'relative'
              }}
            >
              {options.map((option, index) => (
                <div key={`formCheck${index}`} className={styles.followUpCheckboxWrapper}>
                  <FormCheck
                    id={`formCheck${option.Value}`}
                    style={{ display: 'inline-block' }}
                    checked={option.Pass}
                    onChange={() => {
                      setPassOption(option);
                    }}
                  />
                  <FormLabel
                    htmlFor={`formCheck${option.Value}`}
                    style={{
                      fontSize: '15px',
                      color: '#3e4856',
                      paddingLeft: '5px',
                      position: 'relative',
                      top: '-3px'
                    }}
                  >
                    {option.Value}
                  </FormLabel>
                </div>
              ))}
            </div>
            {<ElementDynamicFields element={element} />}
          </div>
        )}
        {isPretripChecklist && !isFollowUpElement && !SubFailedQuestions && (
          <div className={styles.wrapperButtonMargin}>
            <div className={styles.addFailedQuestionButton} onClick={addFailedQuestion}>
              {i18n.t('Forms.ActualForm.AddFailedQuestion')}
            </div>
          </div>
        )}
        {SubFailedQuestions && (
          <FailedQuestion
            element={element}
            setFailedQuestionProperty={setFailedQuestionProperty}
            deleteFailedQuestion={deleteFailedQuestion}
          />
        )}
        {!isFollowUpElement && !isPretripChecklist && (
          <div>
            <div style={{ display: 'inline-block' }}>{i18n.t('Forms.ActualForm.FollowUpFor')}</div>
            <div
              style={{
                display: 'inline-flex !important',
                width: 'calc(100% - 95px)',
                marginLeft: '10px',
                justifyContent: 'flex-start',
                top: '2px',
                position: 'relative'
              }}
            >
              {options.map(option => (
                <div key={`formCheck${option.Value}`} className={styles.followUpCheckboxWrapper}>
                  <FormCheck
                    id={`formCheck${option.Value}`}
                    style={{ display: 'inline-block' }}
                    checked={option.FollowUp ? true : false}
                    onChange={() => {
                      toggleFollowUp(option);
                    }}
                    value={allowBlank}
                  />
                  <FormLabel
                    htmlFor={`formCheck${option.Value}`}
                    style={{
                      fontSize: '15px',
                      color: '#3e4856',
                      paddingLeft: '5px',
                      position: 'relative',
                      top: '-3px'
                    }}
                  >
                    {i18n.t(`Forms.ActualForm.${option.Value}`)}
                  </FormLabel>
                </div>
              ))}
            </div>
          </div>
        )}
        {options.map((option, index) => {
          if (option.FollowUp) {
            return (
              <FollowUp
                key={`followUpOption-${index}`}
                option={option}
                setFollowUpProperty={setFollowUpProperty}
                // title={`"${option.Value}" follow up`}
                title={i18n.t(`Forms.ActualForm.${option.Value}FollowUp`)}
              />
            );
          }
          return '';
        })}
      </FormGroup>
    </>
  );
};

const FollowUpElement = ({ option, setFollowUpProperty }) => {
  const { Type, AllowBlank, AllowNonApplicable, Options = [] } = option.FollowUp
    ? option.FollowUp[0]
    : {};
  switch (Type) {
    case 'Yes/No':
    case 'Ok/Not Ok':
      return (
        <YesNoElement
          allowBlank={AllowBlank}
          allowNA={AllowNonApplicable}
          options={Options}
          setProperty={(property, value) => setFollowUpProperty(option, property, value)}
          setFollowUpProperty={setFollowUpProperty}
          isFollowUpElement
        />
      );
    case 'Selection':
      return (
        <SelectionElement
          options={Options}
          setProperty={(property, value) => setFollowUpProperty(option, property, value)}
          setFollowUpProperty={setFollowUpProperty}
          isFollowUpElement
        />
      );
    case 'Numeric':
    default:
      return (
        <TextElement
          allowBlank={AllowBlank}
          setProperty={(property, value) => setFollowUpProperty(option, property, value)}
          isFollowUpElement
        />
      );
  }
};

const FollowUp = ({ option, setFollowUpProperty, title }) => {
  const [{ Text, Type, Invalid }] = option.FollowUp;
  return (
    <div className={styles.followUpWrapper}>
      <FormGroup>
        <FormLabel style={{ fontSize: '15px' }}>
          {title || `${i18n.t('Forms.ActualForm.Label')}`}
        </FormLabel>
        <FormControl
          as="input"
          value={Text}
          className={Invalid && styles.invalid}
          onChange={e => setFollowUpProperty(option, 'Text', e.target.value)}
          style={{ height: '30px' }}
        />
      </FormGroup>
      <FormGroup>
        <FormLabel style={{ fontSize: '15px' }}>{i18n.t('Common.Type')}</FormLabel>
        <FormControl
          as="select"
          value={Type}
          onChange={e => setFollowUpProperty(option, 'Type', e.target.value)}
          style={{ height: '30px' }}
        >
          <option value="Text">{i18n.t('Forms.ActualForm.Text')}</option>
          <option value="Numeric">{i18n.t('Forms.ActualForm.Numeric')}</option>
          <option value="Selection">{i18n.t('Forms.ActualForm.Selection')}</option>
          <option value="Yes/No">{i18n.t('Forms.ActualForm.Yes/No')}</option>
          <option value="Ok/Not Ok">{i18n.t('Forms.ActualForm.Ok/Not Ok')}</option>
          <option value="Date">{i18n.t('Forms.ActualForm.Date')}</option>
          <option value="Date Time">{i18n.t('Forms.ActualForm.Date Time')}</option>
          <option value="Signature">{i18n.t('Forms.ActualForm.Signature')}</option>
          <option value="Photo">{i18n.t('Forms.ActualForm.Photo')}</option>
          <option value="Barcode">{i18n.t('Forms.ActualForm.Barcode')}</option>
        </FormControl>
        <FollowUpElement option={option} setFollowUpProperty={setFollowUpProperty} />
      </FormGroup>
    </div>
  );
};

const FailedQuestion = ({
  setFailedQuestionProperty,
  element,
  deleteFailedQuestion,
  isDVIR = false
}) => {
  const { Text, Type } = element.SubFailedQuestions[0];
  const { Invalid } = element;
  return (
    <div className={styles.failedQuestionWrapper}>
      <div className={styles.failedQuestionContent}>
        <FormGroup>
          <FormLabel style={{ fontSize: '15px' }}>{i18n.t('Forms.ActualForm.Label')}</FormLabel>
          <FormControl
            as="input"
            value={Text}
            className={Invalid && Text.length === 0 && styles.invalid}
            onChange={e => setFailedQuestionProperty('Text', e.target.value)}
            style={{ height: '30px' }}
          />
        </FormGroup>
        <FormGroup>
          <FormLabel style={{ fontSize: '15px' }}>{i18n.t('Forms.ActualForm.Type')}</FormLabel>
          <FormControl
            as="select"
            value={Type}
            onChange={e => setFailedQuestionProperty('Type', e.target.value)}
            style={{ height: '30px' }}
          >
            <option value={i18n.t('Forms.ActualForm.Text')}>
              {i18n.t('Forms.ActualForm.Text')}
            </option>
            <Can otherConditions={[() => element.Type !== 'Checkbox']}>
              <option value="Numeric">{i18n.t('Forms.ActualForm.Numeric')}</option>
              <option value="Selection">{i18n.t('Forms.ActualForm.Selection')}</option>
              <option value="Yes/No">{i18n.t('Forms.ActualForm.YesNo')}</option>
              <option value="Ok/Not Ok">{i18n.t('Forms.ActualForm.OkNotOk')}</option>
            </Can>
          </FormControl>
          <FailedQuestionElement
            setFailedQuestionProperty={setFailedQuestionProperty}
            element={element}
          />
        </FormGroup>
        <div className={styles.deleteWrapper}>
          <i
            className={styles.deleteButton}
            style={{
              content: `url(${removeIcon})`
            }}
            onClick={deleteFailedQuestion}
            title={i18n.t('PreTripChecklist.Form.RemoveFailedQuestion')}
          />
        </div>
      </div>
    </div>
  );
};

const FailedQuestionElement = ({ setFailedQuestionProperty, element }) => {
  const {
    Type,
    AllowBlank,
    AllowNonApplicable,
    Options = [],
    Dropdown
  } = element.SubFailedQuestions ? element.SubFailedQuestions[0] : {};
  switch (Type) {
    case 'Yes/No':
    case 'Ok/Not Ok':
      return (
        <YesNoElement
          allowBlank={AllowBlank}
          allowNA={AllowNonApplicable}
          options={Options}
          setProperty={(property, value) => setFailedQuestionProperty(property, value)}
          setFollowUpProperty={setFailedQuestionProperty}
          isFollowUpElement
          isPretripChecklist
        />
      );
    case 'Selection':
      return (
        <SelectionElement
          options={Options}
          dropdown={Dropdown}
          setProperty={(property, value) => setFailedQuestionProperty(property, value)}
          setFollowUpProperty={setFailedQuestionProperty}
          isFollowUpElement
          isPretripChecklist
        />
      );
    case 'Numeric':
    default:
      return (
        <TextElement
          allowBlank={AllowBlank}
          setProperty={(property, value) => setFailedQuestionProperty(property, value)}
          isFollowUpElement
        />
      );
  }
};

const CheckboxElement = ({
  allowBlank,
  options,
  setProperty,
  setFollowUpProperty,
  isFollowUpElement,
  isPretripChecklist,
  ElementDynamicFields = () => null,
  element,
  SubFailedQuestions,
  setFailedQuestionProperty
}) => {
  const toggleFollowUp = option => {
    if (option.FollowUp) {
      setFollowUpProperty(option, 'remove');
    } else {
      setFollowUpProperty(option, 'init');
    }
  };
  const addFailedQuestion = () => {
    setProperty('SubFailedQuestions', [{ Text: 'Note', Type: 'Text', AllowBlank: true }]);
  };
  const deleteFailedQuestion = () => {
    setProperty('SubFailedQuestions', null);
  };
  return (
    <>
      <FormGroup>
        {isPretripChecklist && !isFollowUpElement && !SubFailedQuestions && (
          <div className={styles.wrapperButtonMargin}>
            <div className={styles.addFailedQuestionButton} onClick={addFailedQuestion}>
              {i18n.t('Forms.ActualForm.AddFailedQuestion')}
            </div>
          </div>
        )}
        {SubFailedQuestions && (
          <FailedQuestion
            element={element}
            setFailedQuestionProperty={setFailedQuestionProperty}
            deleteFailedQuestion={deleteFailedQuestion}
          />
        )}
        {!isFollowUpElement && !isPretripChecklist && (
          <div>
            <div style={{ display: 'inline-block' }}>{i18n.t('Forms.ActualForm.FollowUpFor')}</div>
            <div
              style={{
                display: 'inline-flex !important',
                width: 'calc(100% - 95px)',
                marginLeft: '10px',
                justifyContent: 'flex-start',
                top: '2px',
                position: 'relative'
              }}
            >
              {options.map(option => (
                <div key={`formCheck${option.Value}`} className={styles.followUpCheckboxWrapper}>
                  <FormCheck
                    id={`formCheck${option.Value}`}
                    style={{ display: 'inline-block' }}
                    checked={option.FollowUp ? true : false}
                    onChange={() => {
                      toggleFollowUp(option);
                    }}
                    value={allowBlank}
                  />
                  <FormLabel
                    htmlFor={`formCheck${option.Value}`}
                    style={{
                      fontSize: '15px',
                      color: '#3e4856',
                      paddingLeft: '5px',
                      position: 'relative',
                      top: '-3px'
                    }}
                  >
                    {i18n.t(`Forms.ActualForm.${option.Value}`)}
                  </FormLabel>
                </div>
              ))}
            </div>
          </div>
        )}
        {options.map((option, index) => {
          if (option.FollowUp) {
            return (
              <FollowUp
                key={`followUpOption-${index}`}
                option={option}
                setFollowUpProperty={setFollowUpProperty}
                // title={`"${option.Value}" follow up`}
                title={i18n.t(`Forms.ActualForm.${option.Value}FollowUp`)}
              />
            );
          }
          return '';
        })}
      </FormGroup>
    </>
  );
};

const SpecificProperties = ({
  element,
  setProperty,
  setFollowUpProperty,
  isPretripChecklist,
  ElementDynamicFields = () => null,
  isFollowUpElement,
  setFailedQuestionProperty
}) => {
  const {
    AllowBlank = true,
    AllowNonApplicable,
    Text,
    Type,
    Options = [],
    Content = '',
    Dropdown,
    Invalid,
    SubFailedQuestions
  } = element;

  switch (Type) {
    case 'Yes/No':
    case 'Ok/Not Ok':
      return (
        <YesNoElement
          allowBlank={AllowBlank}
          options={Options}
          allowNA={AllowNonApplicable}
          setProperty={setProperty}
          setFollowUpProperty={setFollowUpProperty}
          isPretripChecklist={isPretripChecklist}
          ElementDynamicFields={ElementDynamicFields}
          isFollowUpElement={isFollowUpElement}
          SubFailedQuestions={SubFailedQuestions}
          element={element}
          setFailedQuestionProperty={setFailedQuestionProperty}
        />
      );
    case 'Checkbox':
      return (
        <CheckboxElement
          allowBlank={AllowBlank}
          options={Options}
          allowNA={AllowNonApplicable}
          setProperty={setProperty}
          setFollowUpProperty={setFollowUpProperty}
          isPretripChecklist={isPretripChecklist}
          ElementDynamicFields={ElementDynamicFields}
          isFollowUpElement={isFollowUpElement}
          SubFailedQuestions={SubFailedQuestions}
          element={element}
          setFailedQuestionProperty={setFailedQuestionProperty}
        />
      );
    case 'Section':
      return <SectionElement />;
    case 'Description':
      return (
        <DescriptionElement
          description={Content}
          invalid={Invalid && !Content.length && Text.length}
          setProperty={setProperty}
        />
      );
    case 'Selection':
      return (
        <SelectionElement
          element={element}
          options={Options}
          SubFailedQuestions={SubFailedQuestions}
          dropdown={Dropdown}
          setProperty={setProperty}
          setFollowUpProperty={setFollowUpProperty}
          setFailedQuestionProperty={setFailedQuestionProperty}
          isPretripChecklist={isPretripChecklist}
          ElementDynamicFields={ElementDynamicFields}
          isFollowUpElement={isFollowUpElement}
        />
      );
    case 'Numeric':
    default:
      return (
        <TextElement
          allowBlank={AllowBlank}
          setProperty={setProperty}
          isFollowUpElement={isFollowUpElement}
        />
      );
  }
};

export default SpecificProperties;
