/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */
import ApiClient from "../ApiClient";

/**
* Attachments service.
* @module api/AttachmentsApi
* @version 1.0
*/
export default class AttachmentsApi {

    /**
    * Constructs a new AttachmentsApi. 
    * @alias module:api/AttachmentsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getAnnouncements operation.
     * @callback module:api/AnnouncementsApi~getAnnouncementsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Announcement>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */
    getAttachmentContentById(id, thumbnail = false, year, month, presigned = false, callback) {
      let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getAttachmentContentById");
    }

      let pathParams = {
          'id': id
      };

      let queryParams = {
        'pruning': 'WEB',
        'thumbnail': thumbnail.toString(),
        'year': year,
        'month': month,
        'presigned': presigned
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = presigned ? "application/json" : "Blob";
      return this.apiClient.callApi(
        '/attachments/{id}/content', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }
}
