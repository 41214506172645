import React from 'react';
import { Layout as AntLayout } from 'antd';

import style from './Layout.module.scss';

export const Layout = props => <AntLayout {...props} />;
const Content = props => <AntLayout.Content className={style.content} {...props} />;
const Header = props => <AntLayout.Header className={style.header} {...props} />;
const Footer = props => <AntLayout.Header className={style.footer} {...props} />;

Layout.Content = Content;
Layout.Header = Header;
Layout.Footer = Footer;
