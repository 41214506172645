export const DeletableEntityType = {
  User: 'User',
  Device: 'Device',
  Vehicle: 'Vehicle',
  Fleet: 'Fleet',
  Location: 'Location',
  Company: 'Company',
  VehicleType: 'Vehicle Type',
  VehicleMntType: 'Vehicle Maintenance Type',
  ConfigurationTemplate: 'Configuration Template',
  DriverMgtType: 'Driver Management Type'
};
