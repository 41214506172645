import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from 'components/form/form-input/FormInput';
import FormTitle from 'components/form/form-title/FormTitle';
import { Row, Col, Alert } from 'antd';
import { CountrySelect } from 'features/common/CountrySelect';
import { StateSelect } from 'features/common/StateSelect';
import styles from './SPTab.module.scss';
import { FormLabel } from 'react-bootstrap';
import { Field, useFormikContext } from 'formik';

export function SPTab({ readOnlyStatus, ...props }) {
  const { t } = useTranslation();
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const form = useFormikContext();

  useEffect(() => {
    setCountry(form.values.licenceCountry);
    setState(form.values.licenceState);
  }, [form]);

  const handleCountryChanged = useCallback((value, field, form) => {
    setCountry(value);
    setState('');
    form.setFieldValue(field.name, value);
    form.setFieldValue('licenceState', '');
    form.setFieldValue('licenceNumber', '');
  }, []);

  const handleStateChanged = useCallback((value, field, form) => {
    setState(value);
    form.setFieldValue(field.name, value);
  }, []);

  return (
    <div className={styles.sptab}>
      <FormTitle title="Reason for Exemption" underlined />
      <Row>
        <Col span={24}>
          <Alert
            type="info"
            showIcon
            message="Please explain why this user is exempt from fatigue regulations."
          />
          <FormInput
            name="sentinelExemptionNotes"
            label="Exemption Comments"
            placeholder="E.g. This user is exempt from the regulations because xxxx."
            isRequired
            isReadonly={readOnlyStatus}
            as="textarea"
            rows={5}
          />
        </Col>
      </Row>

      <FormTitle title={t('Users.Form.LicenceInformation')} underlined />
      <Row className={styles.licenceContent} gutter={15}>
        <Col span={12}>
          <Field name="licenceCountry">
            {({ field, form }) => (
              <>
                <FormLabel>{t('Users.Form.LicenceCountry')}</FormLabel>
                <CountrySelect
                  {...field}
                  onChange={value => {
                    handleCountryChanged(value, field, form);
                  }}
                  size="large"
                  style={{ width: '100%' }}
                  disabled={readOnlyStatus}
                />
              </>
            )}
          </Field>
        </Col>
        <Col span={12}>
          <Field name="licenceState">
            {({ field, form }) => (
              <>
                <FormLabel>{t('Users.Form.LicenceState')}</FormLabel>
                <StateSelect
                  {...field}
                  country={country}
                  addCountryAsRegion={true}
                  size="large"
                  onChange={value => {
                    handleStateChanged(value, field, form);
                  }}
                  style={{ width: '100%' }}
                  disabled={readOnlyStatus}
                />
              </>
            )}
          </Field>
        </Col>
        <Col span={12}>
          <FormInput
            name="licenceNumber"
            label={t('Users.Form.LicenceNumber')}
            placeholder={
              state == null || state === ''
                ? 'Please select a country and state before typing in licence number'
                : t('Users.Form.LicenceNumberPlaceholder')
            }
            isValidated
            isReadonly={readOnlyStatus || state == null || state === ''}
          />
        </Col>
      </Row>
    </div>
  );
}
