import React from 'react';
import cn from 'classnames';
import style from './RowStatus.module.scss';

export const Status = {
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
  DONE: 'done',
  CREATED: 'created',
  RESTRICTED: 'restricted',
  UPDATED: 'updated',
  SELECTED: 'selected',
  ETA: 'eta',
  ARRIVED: 'arrived',
  STARTED: 'started',
  DEPARTED: 'departed',
  ATTACHMENT: 'attachment',
  PENDING: 'pending',
  DUE_NOW: 'due_now',
  CANCELLED: 'cancelled',
  OVERDUE: 'overdue',
  COMPLETED: 'completed',
  DELETED: 'deleted',
  INVALID: 'invalid',
  INPROGRESS: 'inprogress',
  CLOSED: 'closed',
  OPENED: 'opened',
  FAIL: 'fail',
  REPAIRED: 'repaired',
  PASS: 'pass'
};

export const RowStatus = ({ status = Status.ERROR, children, showIcon }) => {
  const statusClasses = cn(style.statusContainer, {
    [style.error]: status === Status.ERROR,
    [style.warning]: status === Status.WARNING,
    [style.success]: status === Status.SUCCESS,
    [style.done]: status === Status.DONE,
    [style.restricted]: status === Status.RESTRICTED,
    [style.created]: status === Status.CREATED,
    [style.updated]: status === Status.UPDATED,
    [style.selected]: status === Status.SELECTED,
    [style.eta]: status === Status.ETA,
    [style.arrived]: status === Status.ARRIVED,
    [style.started]: status === Status.STARTED,
    [style.departed]: status === Status.DEPARTED,
    [style.attachment]: status === Status.ATTACHMENT,
    [style.pending]: status === Status.PENDING,
    [style.due_now]: status === Status.DUE_NOW,
    [style.cancelled]: status === Status.CANCELLED,
    [style.overdue]: status === Status.OVERDUE,
    [style.completed]: status === Status.COMPLETED,
    [style.deleted]: status === Status.DELETED,
    [style.inprogress]: status === Status.INPROGRESS,
    [style.closed]: status === Status.CLOSED,
    [style.opened]: status === Status.OPENED,
    [style.fail]: status === Status.FAIL,
    [style.repaired]: status === Status.REPAIRED,
    [style.pass]: status === Status.PASS
  });

  return (
    <div className={style.container}>
      {showIcon && <div className={statusClasses} />}
      <div className={style.text}>{children}</div>
    </div>
  );
};
