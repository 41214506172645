import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import InfoRow from 'components/form/info-row/InfoRow';
import { Button, Space, Table } from 'antd';
import { Tooltip, Accordion } from 'components/ant';
import { useCurrentCompany } from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { BUTTON_IDS } from 'utils/globalConstants';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import styles from 'components/tn/grid/grid.module.scss';
import style from './InstallationLog.module.scss';
import { setInstallationLogsViewData } from 'features/installation_logs/InstallationLogsSlice';
import DownloadAuditLogsView from './DownloadAuditLogsView';
import { capitalize } from 'utils/strings';
import { formatMeterValue, formatOdometer } from 'features/meters';
import i18n from 'i18next';
import { format } from 'utils/dates';

export function InstallationAuditLogsView() {
  const dispatch = useDispatch();
  const company = useCurrentCompany();
  const [selectedCompany, setSelectedCompany] = useState(company?.id);
  const { t } = useTranslation();
  const localization = useLocalization();
  const history = useHistory();
  const [viewData, setViewData] = useState({});
  const [supportType, setSupportType] = useState('');
  const [testResults, setTestResults] = useState([]);
  const [flag, setFlag] = useState('details');

  const { state } = useLocation();

  // const capitalize = s => s && s[0].toUpperCase() + s.slice(1);
  const [icon, setIcon] = useState([false, false, false, false, false, false]);

  const cleanData = jsonString => {
    if (!jsonString || jsonString.length === 0) {
      return [];
    }

    const data = JSON.parse(jsonString);
    return (
      data &&
      data?.map(item => {
        const cleanedItem = {};
        cleanedItem.title = item.title;
        if (item.value !== undefined) {
          try {
            const parsedValue = JSON.parse(item.value); // Try parsing the value
            if (typeof parsedValue === 'object' && parsedValue !== null) {
              cleanedItem.value = parsedValue; // If it's a valid JSON object, assign it
            } else {
              cleanedItem.value = item.value; // If it's not an object, keep the original string
            }
          } catch (e) {
            cleanedItem.value = item.value; // If parsing fails, keep it as a string
          }
        }
        if (item.status !== undefined) cleanedItem.status = item.status;
        if (item.subtitle !== undefined) cleanedItem.subtitle = item.subtitle;
        return cleanedItem;
      })
    );
  };

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(t(state && state?.data?.entityName)));
    setViewData(state && state?.data?.rowData);
    setSupportType(state && state?.data?.supportType);
    setTestResults(
      JSON.stringify(cleanData((state && state?.data?.rowData?.testResults) || []), null, 2)
    );
    dispatch(
      setInstallationLogsViewData({
        ...(state && state?.data?.rowData),
        supportType: state && state?.data?.supportType
      })
    );
  }, [dispatch, state]);

  const handleFilterClick = value => {
    setFlag(value);
  };

  const findValueByTitle = (data, title) => {
    if (!data || data.length === 0) {
      return {
        title: '',
        subtitle: '',
        status: ''
      };
    }
    try {
      const parsedData = JSON.parse(data);
      const found = parsedData.filter(item => item.title === title);
      return (
        found && {
          title: (found[0]?.value && found[0]?.value) || '',
          subtitle: found[0]?.subtitle || '',
          status: (found[0]?.status && found[0]?.status) || ''
        }
      );
    } catch (error) {
      console.error('Error parsing JSON:', error.message);
      return {
        title: '',
        subtitle: '',
        status: ''
      };
    }
  };

  const [triggerButton, setTriggerButton] = useState(false);
  const handleExportToPdf = () => {
    setTriggerButton(true);
  };

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div style={{ display: 'flex' }}></div>
          <div style={{ marginLeft: 'auto' }}>
            <Space size={16}>
              <Button
                type="primary"
                size="medium"
                id={BUTTON_IDS.viewPhotosBtn}
                onClick={() => {
                  history.push('/supportTools/installationAuditLog/photos', {
                    data: state?.data
                  });
                }}
                // disabled={}
              >
                {t('SupportToolsELD.ViewPhotos')}
              </Button>
              <Button
                type="secondary"
                size="medium"
                id={BUTTON_IDS.exportToPDFBtn}
                onClick={() => handleExportToPdf()}
                // disabled={isFetchingFleets}
              >
                {t('SupportToolsELD.ExportToPdf')}
              </Button>
            </Space>
          </div>
        </HeaderPageWrapper>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0 32px',
            color: '#181c21'
          }}
        >
          {viewData && (
            <>
              <InfoRow
                label={t(
                  `SupportToolsELD.InstallationLogsColumns.${supportType &&
                    capitalize(supportType)}`
                )}
                value={viewData?.[supportType]?.name || ''}
                styles={styles}
              />
              <InfoRow
                label={t(`SupportToolsELD.InstallationLogsColumns.Company`)}
                value={viewData?.company?.id || ''}
                styles={styles}
              />
              <InfoRow
                label={t(`SupportToolsELD.InstallationLogsColumns.Fleet`)}
                value={viewData?.vehicle?.fleet || ''}
                styles={styles}
              />
              <InfoRow
                label={t(`SupportToolsELD.InstallationLogsColumns.Engineer`)}
                value={viewData?.vehicle?.engineer || ''}
                styles={styles}
              />
              <InfoRow
                label={t(`SupportToolsELD.InstallationLogsColumns.WorkOrder`)}
                value={viewData?.vehicle?.workorder || ''}
                styles={styles}
              />
              <InfoRow
                label={t(`SupportToolsELD.InstallationLogsColumns.OperationType`)}
                value={
                  viewData?.action === i18n.t('SupportToolsELD.InstallationLogsView.Test')
                    ? i18n.t('SupportToolsELD.InstallationLogsView.Validate')
                    : viewData?.action || ''
                }
                styles={styles}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  maxWidth: '100%',
                  borderBottom: '1px solid #EEE',
                  padding: '10px 0'
                }}
              >
                <div style={{ width: '100%' }}>
                  <Accordion
                    title={t('SupportToolsELD.InstallationLogsView.OperationDescription')}
                    body={
                      <>
                        <div style={{ display: 'flex', width: '70%' }}>
                          <p
                            style={{
                              border: '1px solid #EEE',
                              backgroundColor: flag == 'details' ? '#86b341' : '#EEE',
                              padding: '0px 5px 0px',
                              marginRight: '15px',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleFilterClick('details')}
                          >
                            {t(`SupportToolsELD.InstallationLogsView.Details`)}
                          </p>
                          <p
                            style={{
                              border: '1px solid #EEE',
                              backgroundColor: flag == 'odometer' ? '#86b341' : '#EEE',
                              padding: '0px 5px 0px',
                              marginRight: '15px',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleFilterClick('odometer')}
                          >
                            {t(`SupportToolsELD.InstallationLogsView.OdometerEngineHours`)}
                          </p>
                          <p
                            style={{
                              border: '1px solid #EEE',
                              backgroundColor: flag == 'accelerometer' ? '#86b341' : '#EEE',
                              padding: '0px 5px 0px',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleFilterClick('accelerometer')}
                          >
                            {t(`SupportToolsELD.InstallationLogsView.AccelerometerLastCalibrated`)}
                          </p>
                        </div>
                        {flag == 'details' && (
                          <div className={style.megaDropView}>
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.DateAndTime')}
                              value={
                                format(
                                  viewData?.[supportType]?.createdAt,
                                  localization.formats.time.formats.dby_imp
                                ) || ''
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.Operation')}
                              value={
                                viewData?.action ===
                                i18n.t('SupportToolsELD.InstallationLogsView.Test')
                                  ? i18n.t('SupportToolsELD.InstallationLogsView.Validate')
                                  : viewData?.action || ''
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.OperationStatus')}
                              value={viewData?.status || ''}
                              styles={styles}
                            />
                            {viewData?.action === t('SupportToolsELD.InstallationLogsView.Test') ||
                            viewData?.action ===
                              t('SupportToolsELD.InstallationLogsView.Validate') ? (
                              <div>
                                <InfoRow
                                  label={t(
                                    'SupportToolsELD.InstallationLogsColumns.OperationSubtype'
                                  )}
                                  value={viewData?.subType || ''}
                                  styles={styles}
                                />
                                <InfoRow
                                  label={t(
                                    'SupportToolsELD.InstallationLogsColumns.OperationSubtypeStatus'
                                  )}
                                  value={viewData?.[supportType]?.status || ''}
                                  styles={styles}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.Engineer')}
                              value={viewData?.vehicle?.engineer || ''}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.IMEI')}
                              value={viewData?.device?.imei || ''}
                              styles={styles}
                            />
                            <InfoRow
                              label={t(
                                'SupportToolsELD.InstallationLogsView.RegistrationOrLicence'
                              )}
                              value={viewData?.vehicle?.registration || ''}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.Vehicle')}
                              value={viewData?.vehicle?.name || ''}
                              styles={styles}
                            />
                            {viewData &&
                              viewData?.action ===
                                t('SupportToolsELD.InstallationLogsView.Install') && (
                                <div>
                                  <InfoRow
                                    label={t('SupportToolsELD.InstallationLogsColumns.Make')}
                                    value={viewData?.vehicle?.make || ''}
                                    styles={styles}
                                  />
                                  <InfoRow
                                    label={t('SupportToolsELD.InstallationLogsColumns.Model')}
                                    value={viewData?.vehicle?.model || ''}
                                    styles={styles}
                                  />
                                  <InfoRow
                                    label={t('SupportToolsELD.InstallationLogsColumns.Odometer')}
                                    value={
                                      formatOdometer(localization, viewData?.vehicle?.odometer) ||
                                      ''
                                    }
                                    styles={styles}
                                  />
                                  <InfoRow
                                    label={t('SupportToolsELD.InstallationLogsColumns.EngineHours')}
                                    value={viewData?.vehicle?.engineHours || ''}
                                    styles={styles}
                                  />
                                </div>
                              )}
                            {viewData &&
                              viewData?.action ===
                                t('SupportToolsELD.InstallationLogsView.Replace') && (
                                <div>
                                  <InfoRow
                                    label={t(
                                      'SupportToolsELD.InstallationLogsColumns.ReplacedIMEI'
                                    )}
                                    value={viewData?.device?.imei || ''}
                                    styles={styles}
                                  />
                                  <InfoRow
                                    label={t('SupportToolsELD.InstallationLogsColumns.Odometer')}
                                    value={
                                      formatOdometer(localization, viewData?.vehicle?.odometer) ||
                                      ''
                                    }
                                    styles={styles}
                                  />
                                  <InfoRow
                                    label={t('SupportToolsELD.InstallationLogsColumns.EngineHours')}
                                    value={viewData?.vehicle?.engineHours || ''}
                                    styles={styles}
                                  />
                                </div>
                              )}
                          </div>
                        )}
                        {flag == 'odometer' && (
                          <div className={style.megaDropView}>
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.DateAndTime')}
                              value={
                                format(
                                  viewData?.[supportType]?.createdAt,
                                  localization.formats.time.formats.dby_imp
                                ) || ''
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.EngineHours')}
                              value={viewData?.vehicle?.engineHours || ''}
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsColumns.Odometer')}
                              value={
                                formatOdometer(localization, viewData?.vehicle?.odometer) || ''
                              }
                              styles={styles}
                            />
                          </div>
                        )}
                        {flag == 'accelerometer' && (
                          <div className={style.megaDropView}>
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.DateAndTime')}
                              value={
                                format(
                                  viewData?.[supportType]?.createdAt,
                                  localization.formats.time.formats.dby_imp
                                ) || ''
                              }
                              styles={styles}
                            />
                            <InfoRow
                              label={t('SupportToolsELD.InstallationLogsView.Accelerometer')}
                              value={''}
                              styles={styles}
                            />
                          </div>
                        )}
                      </>
                    }
                    icon={icon[0]}
                    onChange={() =>
                      setIcon([!icon[0], icon[1], icon[2], icon[3], icon[4], icon[5]])
                    }
                  />
                  <Accordion
                    title={t('SupportToolsELD.InstallationLogsView.VPMInformation')}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.Charge')}
                          value={findValueByTitle(testResults, '').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.EngineLoad')}
                          value={findValueByTitle(testResults, '').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.FuelConsumption')}
                          value={findValueByTitle(testResults, '').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.FuelLevel')}
                          value={findValueByTitle(testResults, '').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.Gear')}
                          value={findValueByTitle(testResults, '').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPS')}
                          value={findValueByTitle(testResults, 'VPM Information: Gps')?.value?.At}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.Hours')}
                          value={findValueByTitle(testResults, 'VPM Information: Hours').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.ID')}
                          value={findValueByTitle(testResults, 'VPM Information: Id').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.Mile')}
                          value={findValueByTitle(testResults, 'VPM Information: Mil').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.Odometer')}
                          value={findValueByTitle(testResults, 'VPM Information: Odometer').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.OilPressure')}
                          value={
                            findValueByTitle(testResults, 'VPM Information: Oilpressure').title
                          }
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.OilTemp')}
                          value={findValueByTitle(testResults, 'VPM Information: Oiltemp').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.RPM')}
                          value={findValueByTitle(testResults, 'VPM Information: Rpm').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.Speed')}
                          value={findValueByTitle(testResults, 'VPM Information: Speed').title}
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[1]}
                    onChange={() =>
                      setIcon([icon[0], !icon[1], icon[2], icon[3], icon[4], icon[5]])
                    }
                  />
                  <Accordion
                    title={t('SupportToolsELD.InstallationLogsView.LastCommDetails')}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.LastCommValue')}
                          value={findValueByTitle(testResults, 'General: Last Comm').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.LastCommStatus')}
                          value={findValueByTitle(testResults, 'General: Last Comm').status}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.LastCommDetails')}
                          value={findValueByTitle(testResults, 'General: Last Comm').subtitle}
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[2]}
                    onChange={() =>
                      setIcon([icon[0], icon[1], !icon[2], icon[3], icon[4], icon[5]])
                    }
                  />
                  <Accordion
                    title={t('SupportToolsELD.InstallationLogsView.IgnitionDetails')}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.IgnitionValue')}
                          value={findValueByTitle(testResults, 'General: Ignition').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.IgnitionStatus')}
                          value={findValueByTitle(testResults, 'General: Ignition').status}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.IgnitionDetails')}
                          value={findValueByTitle(testResults, 'General: Ignition').subtitle}
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[3]}
                    onChange={() =>
                      setIcon([icon[0], icon[1], icon[2], !icon[3], icon[4], icon[5]])
                    }
                  />
                  <Accordion
                    title={t('SupportToolsELD.InstallationLogsView.DriverDetails')}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverValue')}
                          value={findValueByTitle(testResults, 'General: Driver').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverStatus')}
                          value={findValueByTitle(testResults, 'General: Driver').status}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.DriverDetails')}
                          value={findValueByTitle(testResults, 'General: Driver').subtitle}
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[4]}
                    onChange={() =>
                      setIcon([icon[0], icon[1], icon[2], icon[3], !icon[4], icon[5]])
                    }
                  />
                  <Accordion
                    title={t('SupportToolsELD.InstallationLogsView.GPSDetails')}
                    body={
                      <div className={style.megaDropView}>
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSTimeValue')}
                          value={findValueByTitle(testResults, 'GPS: GPS Time').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSTimeStatus')}
                          value={findValueByTitle(testResults, 'GPS: GPS Time').status}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSTimeDetails')}
                          value={findValueByTitle(testResults, 'GPS: GPS Time').subtitle}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSPositionValue')}
                          value={findValueByTitle(testResults, 'GPS: GPS Position').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSPositionDetails')}
                          value={findValueByTitle(testResults, 'GPS: GPS Position').subtitle}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSHDOPSATValue')}
                          value={findValueByTitle(testResults, 'GPS: GPS HDOP/SAT').title}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSHDOPSATStatus')}
                          value={findValueByTitle(testResults, 'GPS: GPS HDOP/SAT').status}
                          styles={styles}
                        />
                        <InfoRow
                          label={t('SupportToolsELD.InstallationLogsView.GPSHDOPSATDetails')}
                          value={findValueByTitle(testResults, 'GPS: GPS HDOP/SAT').subtitle}
                          styles={styles}
                        />
                      </div>
                    }
                    icon={icon[5]}
                    onChange={() =>
                      setIcon([icon[0], icon[1], icon[2], icon[3], icon[4], !icon[5]])
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </ContainerPageWrapper>
      <div style={{ display: 'none' }}>
        {triggerButton && (
          <DownloadAuditLogsView
            triggerButton={triggerButton}
            setTriggerButton={setTriggerButton}
            state={{
              ...(state && state?.data?.rowData),
              supportType: state && state?.data?.supportType
            }}
            findValueByTitle={findValueByTitle}
            testResults={testResults}
          />
        )}
      </div>
    </>
  );
}
