import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { DevicesToolbar } from './DevicesToolbar';
import { DevicesTable } from './DevicesTable';
import { useDispatch } from 'react-redux';
import { sortBy } from 'lodash';
import { prepareDataForMultiselect } from 'utils/filters';
import {
  fetchDevices,
  useEasydocsDevices,
  useIsFetching as isMobileDevicesFetching
} from 'features/easydocs/devicesSlice';
import { format } from 'utils/dates';

import { useCurrentCompany } from 'features/company/companySlice';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { ExportToExcel, ExportType } from '../ExportToExcel';
import { Space } from 'antd';
import { useUser } from 'features/user/userSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { LoadingSpinner } from 'components/tn/grid/LoadingSpinner';

export const EasydocsDevices = ({ setTableRef }) => {
  const localization = useLocalization();
  const { t } = useTranslation();
  const company = useCurrentCompany();
  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;

  const [searchText, setSearchText] = useState(null);
  const [companiesFilter, setCompaniesFilter] = useState([]);
  const [fleetsFilter, setFleetsFilter] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const dispatch = useDispatch();
  const devices = useEasydocsDevices();
  const mobileDevicesFetching = isMobileDevicesFetching();

  const FilterIds = {
    AllCompanies: 0,
    AllFleets: 0,
    NoCompany: -1,
    NoFleet: -1
  };

  useEffect(() => {
    let companiesList = [];
    let fleetsList = [];

    if (devices) {
      // Get lists of all unique companies and fleets from the devices list
      devices.forEach(device => {
        if (device.company && !companiesList.find(c => c.id === device.company.id)) {
          companiesList.push({ id: device.company.id, name: device.company.name });
        }

        if (device.fleets) {
          device.fleets.forEach(fleet => {
            if (fleet && !fleetsList.find(f => f.id === fleet.id)) {
              fleetsList.push({ id: fleet.id, name: fleet.name });
              fleetsList.push();
            }
          });
        }
      });

      // sort all the lists by name
      companiesList = sortBy(companiesList, [item => item.name.toLowerCase()]);
      fleetsList = sortBy(fleetsList, [item => item.name.toLowerCase()]);

      // Add option for no company/fleet to account for the items that are missing this value
      companiesList.push({ id: FilterIds.NoCompany, name: t('Common.NoCompany') });
      fleetsList.push({ id: FilterIds.NoFleet, name: t('Common.NoFleet') });

      setCompaniesFilter(prepareDataForMultiselect(companiesList, t('Common.AllCompanies'), null));
      setFleetsFilter(prepareDataForMultiselect(fleetsList, t('Common.AllFleets'), null));
    }
  }, [devices]);

  useEffect(() => {
    if (devices) {
      let newFilteredDevices = [];

      devices.forEach(row => {
        let matchSearch = true;

        // filter by search text for device, company, fleet, vehicle, driver

        if (matchSearch && searchText && searchText.trim()) {
          const searchTextLowerCase = searchText.trim().toLowerCase();
          matchSearch =
            row.device?.name?.toLowerCase().indexOf(searchTextLowerCase) >= 0 ||
            row.company.name.toLowerCase().includes(searchTextLowerCase) ||
            row.fleets?.some(f => f.name.toLowerCase().indexOf(searchTextLowerCase) >= 0) ||
            row.vehicle?.name?.toLowerCase().includes(searchTextLowerCase) ||
            row.company?.name?.toLowerCase().includes(searchTextLowerCase) ||
            row.inAppCount?.toString()?.includes(searchTextLowerCase) ||
            row.uploadedCount?.toString()?.includes(searchTextLowerCase) ||
            row.receivedCount?.toString()?.includes(searchTextLowerCase) ||
            row.removedCount?.toString()?.includes(searchTextLowerCase) ||
            format(new Date(row.lastAccessed), localization.formats.time.formats.dby_imsp)
              ?.toLowerCase()
              .indexOf(searchTextLowerCase) >= 0 ||
            (
              row.driver?.firstName?.toLowerCase() +
              ' ' +
              row.driver?.lastName?.toLowerCase()
            ).includes(searchTextLowerCase);
        }

        // Filter by checked companies (0 = all companies, -1 = no company)
        if (
          matchSearch &&
          companiesFilter &&
          !companiesFilter.find(f => f.id === FilterIds.AllCompanies)?.checked
        ) {
          const hasCompany = row.company;
          matchSearch = companiesFilter.some(
            f =>
              f.checked &&
              ((row.company && f.id === row.company.id) ||
                (f.id === FilterIds.NoCompany && !hasCompany))
          );
        }

        // Filter by checked fleets (0 = all fleets, -1 = no fleet)
        if (
          matchSearch &&
          fleetsFilter &&
          !fleetsFilter.find(f => f.id === FilterIds.AllFleets)?.checked
        ) {
          const hasFleet = row.fleets && row.fleets.length > 0;
          matchSearch = fleetsFilter.some(
            f =>
              f.checked &&
              ((row.fleets && row.fleets.some(fleet => f.id === fleet.id)) ||
                (f.id === FilterIds.NoFleet && !hasFleet))
          );
        }

        if (matchSearch) {
          newFilteredDevices.push(row);
        }
      });

      setFilteredDevices(newFilteredDevices);
    }
  }, [devices, searchText, companiesFilter, fleetsFilter]);

  useEffect(() => {
    dispatch(setPageTitle(`${t('Easydocs.Easydocs')}`));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(fetchDevices());
  }, [dispatch]);

  const handleSearchChange = useCallback(searchText => {
    setSearchText(searchText);
  }, []);

  const handleCompaniesFilterChange = useCallback(companiesFilter => {
    setCompaniesFilter(companiesFilter);
  }, []);

  const handleFleetsFilterChange = useCallback(fleetsFilter => {
    setFleetsFilter(fleetsFilter);
  }, []);

  return (
    <div>
      <HeaderPageWrapper>
        <Space size={16} style={{ marginLeft: 'auto' }}>
          <ExportToExcel
            data={devices}
            company={company}
            localization={localization}
            exportType={ExportType.DeviceList}
            isSiteAdmin={isSiteAdmin}
            folders={[]}
            fleets={[]}
            vehicles={[]}
            devices={[]}
            subCompanies={[]}
          />
        </Space>
      </HeaderPageWrapper>
      <div>
        <DevicesToolbar
          filteredDevicesCount={filteredDevices.length}
          searchText={searchText}
          companiesFilter={companiesFilter}
          fleetsFilter={fleetsFilter}
          onSearchChange={handleSearchChange}
          onCompaniesFilterChange={handleCompaniesFilterChange}
          onFleetsFilterChange={handleFleetsFilterChange}
        />
        {!mobileDevicesFetching ? (
          <div style={{ height: 'calc(100vh - 230px)' }}>
            <DevicesTable devices={filteredDevices} setTableRef={setTableRef} />
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
};
