import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'utils/api';

import * as actions from './actions';

const getAsyncThunk = (action, url, verb) =>
  createAsyncThunk(action, async (args = {}, { getState, dispatch, rejectWithValue }) => {
    const { body, id, companyId, toasters, callback } = args;

    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const company_id = companyId || state?.companies?.current?.id;

    const method = api[verb];

    try {
      const response = await method(
        id ? `${url}/${id}` : url,
        {
          authKey,
          query: {
            company_id
          }
        },
        body
      );

      if (callback) {
        dispatch(callback());
      }

      if (toasters?.success) {
        dispatch(toasters.success());
      }

      return { body: response.body, companyId: company_id };
    } catch (err) {
      if (toasters?.error) {
        dispatch(toasters.error(err));
      }

      return rejectWithValue({ companyId: company_id });
    }
  });

export const getEnrolements = getAsyncThunk(actions.GET_ENROLMENTS, '/enrolments', 'get');
export const getSchemes = getAsyncThunk(actions.GET_SCHEMES, '/enrolments/schemes', 'get');
export const addEnrolment = getAsyncThunk(actions.POST_ENROLMENT, '/enrolments/bulk', 'post');
export const editEnrolment = getAsyncThunk(actions.PUT_ENROLMENT, '/enrolments', 'put');
export const cancelEnrolment = getAsyncThunk(actions.CANCEL_ENROLMENT, '/enrolments', 'delete');
