import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../config';
import { ApiClient, AttachmentsApi } from '../../nextgen_api/index';
import { useDispatch, useSelector } from 'react-redux';
import { RequestQueue } from 'features/requestQueue/RequestQueue';
import { useEffect, useState } from 'react';

const actionTypes = {
  init: 0,
  processing: 1,
  error: 2,
  done: 3
};

export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState: {
    contents: {}
  },
  reducers: {
    fetchAttachmentContentStart(state, { payload }) {
      const { id, thumbnail } = payload;
      if (thumbnail) {
        state.contents[id] = {
          ...state.contents[id],
          thumb_status: {
            fetching: actionTypes.processing,
            fetchingTime: moment().valueOf(),
            error: null
          },
          thumb_rawContent: null,
          thumb_url: null
        };
      } else {
        state.contents[id] = {
          ...state.contents[id],
          status: {
            fetching: actionTypes.processing,
            fetchingTime: moment().valueOf(),
            error: null
          },
          rawContent: null,
          url: null
        };
      }
    },
    fetchAttachmentContentSucceeded(state, { payload }) {
      const { id, thumbnail, content } = payload;
      if (thumbnail) {
        state.contents[id].thumb_url = content;
        state.contents[id].thumb_status = {
          fetching: actionTypes.done,
          fetchingTime: moment().valueOf()
        };
      } else {
        state.contents[id].url = content;
        state.contents[id].status = {
          fetching: actionTypes.done,
          fetchingTime: moment().valueOf()
        };
      }
    },
    fetchAttachmentContentFailed(state, { payload }) {
      const { id, thumbnail, err } = payload;
      if (thumbnail) {
        state.contents[id].thumb_status = {
          fetching: actionTypes.error,
          error: err,
          fetchingTime: moment().valueOf()
        };
      } else {
        state.contents[id].status = {
          fetching: actionTypes.error,
          error: err,
          fetchingTime: moment().valueOf()
        };
      }
    },
    fetchAttachmentContentCancelled(state, { payload }) {
      const { id, thumbnail } = payload;
      const initStatus = {
        fetching: actionTypes.error,
        error: null,
        fetchingTime: null
      };
      if (thumbnail) {
        if (state.contents[id].thumb_status.fetching === actionTypes.processing) {
          state.contents[id].thumb_status = initStatus;
        }
      } else {
        if (state.contents[id].status.fetching === actionTypes.processing) {
          state.contents[id].status = initStatus;
        }
      }
    }
  }
});

export const {
  fetchAttachmentContentStart,
  fetchAttachmentContentSucceeded,
  fetchAttachmentContentFailed,
  fetchAttachmentContentCancelled
} = attachmentsSlice.actions;

export const fetchAttachmentContent = (
  attachmentId,
  userKey,
  thumbnail = false,
  year,
  month
) => async (dispatch, getState) => {
  const prevState = getState().attachments.contents[attachmentId];
  if (
    prevState != null &&
    ((!thumbnail &&
      ((prevState.url != null && prevState.status.fetching === actionTypes.done) ||
        (prevState.url == null &&
          moment().valueOf() - prevState.status?.fetchingTime <= 60 * 60 * 1000))) ||
      (thumbnail &&
        ((prevState.thumb_url != null && prevState.thumb_status.fetching === actionTypes.done) ||
          (prevState.thumb_url == null &&
            moment().valueOf() - prevState.thumb_status.fetchingTime <= 60 * 60 * 1000))))
  ) {
    return;
  }
  dispatch(fetchAttachmentContentStart({ id: attachmentId, thumbnail: thumbnail }));
  const requestObj = {
    req: null
  };

  const reqHandle = RequestQueue.queueRequest(
    () => {
      const apiClient = new ApiClient();
      apiClient.basePath = API_PATH;
      apiClient.defaultHeaders = {
        Authorization: `Token token="${userKey}"`
      };

      const attachmentsApi = new AttachmentsApi(apiClient);
      const promise = new Promise((resolve, reject) => {
        requestObj.req = attachmentsApi.getAttachmentContentById(
          attachmentId,
          thumbnail,
          year,
          month,
          true,
          (err, data, resp) => {
            if (err && resp.status !== 200) {
              console.log(err);
              reject(err);
            } else {
              try {
                let url = resp.body?.attachmentLink;
                if (url == null) {
                  url = URL.createObjectURL(resp.body);
                }
                resolve(url);
              } catch {
                resolve(null);
              }
            }
          }
        );
      });
      return promise;
    },
    data =>
      dispatch(
        fetchAttachmentContentSucceeded({ id: attachmentId, thumbnail: thumbnail, content: data })
      ),
    err =>
      dispatch(fetchAttachmentContentFailed({ id: attachmentId, thumbnail: thumbnail, err: err })),
    () => {
      if (requestObj.req != null) {
        requestObj.req.abort();
      }
      dispatch(fetchAttachmentContentCancelled({ id: attachmentId, thumbnail }));
    }
  );
  return reqHandle;
};

export const useAttachmentsContents = () => useSelector(state => state.attachments.contents);
export const useAttachmentContentById = (attachmentId, mimeType, userKey, year, month) => {
  const dispatch = useDispatch();
  const attachmentContent = useSelector(state => state.attachments.contents[attachmentId]);
  const [reqHandle, setReqHandle] = useState(null);

  useEffect(() => {
    return () => {
      if (reqHandle != null) {
        RequestQueue.removeRequest(reqHandle);
      }
    };
  }, [reqHandle]);

  if (
    attachmentContent == null ||
    (attachmentContent.status?.fetching !== actionTypes.processing &&
      attachmentContent.thumb_status?.fetching !== actionTypes.processing)
  ) {
    if (mimeType == null || mimeType.indexOf('video') < 0) {
      //skip video fetching
      dispatch(fetchAttachmentContent(attachmentId, userKey, false, year, month)).then(
        reqHandle => {
          setReqHandle(reqHandle);
        }
      );
    } else {
      //fetch video thumbnail
      dispatch(fetchAttachmentContent(attachmentId, userKey, true, year, month)).then(reqHandle => {
        setReqHandle(reqHandle);
      });
    }
  }
  return attachmentContent;
};
export default attachmentsSlice.reducer;
