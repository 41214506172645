import React, { useCallback, useEffect, useState } from 'react';
import { Tabs, Tab, Row, Col, FormGroup, FormLabel, FormCheck } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import FormInput from 'components/form/form-input/FormInput';
import FormSelect from 'components/form/form-select/FormSelect';
import moment from 'moment';
import {
  useCompanies,
  useCurrentCompany,
  useIsELDCompany,
  useRedirectToMainFeaturePageOnCompanyChange,
  useIsCompanyKeyDifferent
} from 'features/company/companySlice';
import SearchableListMultiSelect from 'components/form/searchable-list-multi-select/SearchableListMultiSelect';
import {
  useVehicles,
  useDevices,
  extractDevices,
  fetchFleets,
  useVehicle,
  fetchSingleVehicle,
  fetchFleetsBoth,
  useVehiclesFromFleetsBoth
} from 'features/fleets/fleetsSlice';
import {
  saveVehicleApi,
  associateDeviceToVehicleApi,
  associateFleetsToVehicleApi,
  attachFiles,
  removeFiles
} from 'features/vehicles/vehiclesSlice';
import { fetchVehicleTypesByCompany } from 'features/vehicles/vehicleTypesSlice';
import { useVehicleMaintenanceFields } from 'features/company_config/hooks';
import { useAllRegions } from 'features/regions/regionsSlice';
import { fetchVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import { useUser, useUserKey } from 'features/user/userSlice';
import { getFleetsByCompany } from 'containers/Administration/Fleets/APICalls';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useDispatch } from 'react-redux';
import { openToast } from 'features/toasts/toastsSlice';
import { useHistory } from 'react-router';
import {
  initialValues,
  validationSchema,
  ACTIONS,
  PATHS,
  MAXIMUM_USER_DEFINED_FIELD
} from './constants';
import { FormDatePicker } from 'components/ant';
import { uniqBy, sortBy } from 'lodash';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { getRegistrationTerritoryLabel } from 'features/localization/localization';
import { getRegistrationCountryLabel } from 'features/localization/localization';
import { PhoneInputField, usePhoneNumber } from 'components/form/phone-input-field';
import { useLocalization } from 'features/localization/localizationSlice';

import { List, Input } from 'antd';
import { Alert } from 'antd';
import FormTitle from 'components/form/form-title/FormTitle';
import { FileUpload } from 'components/FileUpload';
import { Tooltip } from 'components/ant';
import { DocumentsDisplay } from 'components/Documents';
import removeIcon from 'static/images/icons/remove-black.svg';
import { ImageUpload, Button } from 'components/ant';
import { saveImage, removeImage } from 'features/images/imagesSlice';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { prepareVehicleTypes } from './helpers';
import { useTranslation, Trans } from 'react-i18next';
import { canHistoryGoBack, formatBytes } from 'utils/methods';
import { format } from 'utils/dates';
import { objectsEqual, arraysEqual } from 'utils/objects';
import { filterFilesBy } from 'utils/files';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { VehicleOperationalMakeModelYearFormModal } from './VehicleOperationalMakeModelYearFormModal';
import {
  useDefaultDriverField,
  DEFAULT_DRIVER_FORM_FIELD_NAME
} from 'containers/Administration/Vehicles/DefaultDriver';
import { v4 as uuidv4 } from 'uuid';

import { VehicleMakeModelYearForm } from './VehicleMakeModelYearForm';
//styles
import styles from './Vehicles.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useCanOneOfCompanyServices, services } from 'features/permissions';
import { getConfig, useConfig } from 'features/easydocs/documentsSlice';

import { useCountryOptions, getCountryRegions } from 'utils/countryState';
const VEHICLE_EDIT_PATH = '/settings/vehicles/edit';

export const VehicleForm = ({ action }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
  const [registrationStateLabel, setRegistrationTerritoryLabel] = useState('');
  const [registrationCountryLabel, setRegistrationCountryLabel] = useState('');
  const path = window.location.pathname;
  const vehicleId = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const uploadConfig = useConfig();
  const { maxFileSizeBytes } = uploadConfig || {};
  const maxDocumentUploadSizeInBytes = maxFileSizeBytes || 5242880;
  const maxFileSize = formatBytes(maxDocumentUploadSizeInBytes);
  const allVehicles = useVehicles();
  const allVehicleNames = allVehicles.map(vehicle => vehicle?.name);
  const allVehicleExternalIds = allVehicles.reduce((acc, vehicle) => {
    vehicle.externalId && acc.push(vehicle.externalId);
    return acc;
  }, []);
  const { phoneNumber, phoneNumberWithoutDialCode, handlePhoneNumberChange } = usePhoneNumber(
    formikInitialValues?.phone
  );
  const localization = useLocalization();
  const history = useHistory();

  const [vehicleNotFound, setVehicleNotFound] = useState(false);
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.VEHICLE_DEFAULT);
  }, [history]);

  const handleNotFound = useCallback(() => {
    setVehicleNotFound(true);
  }, []);

  const vehicleInfo = useVehicle(vehicleId, undefined, undefined, handleFetchError, handleNotFound);
  const vehiclesBoth = useVehiclesFromFleetsBoth();
  const [assets, setAssets] = useState([]);
  const [newAsset, setNewAsset] = useState('');
  const [image, setImage] = useState(null);
  const [imageRemoved, setImageRemoved] = useState(false);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(vehicleInfo?.registrationCountry);
  const [showCompanyChangeWarning, setShowCompanyChangeWarning] = useState(false);
  const [isPhoneNumberDirty, setIsPhoneNumberDirty] = useState(false);
  const [fleetsLoaded, setFleetsLoaded] = useState(false);
  const [fleets, setFleets] = useState([]);
  const [userCheckedFleets, setUserCheckedFleets] = useState(fleets);
  const allDevices = useDevices();
  const [devices, setDevices] = useState([]);
  const [userCheckedDevices, setUserCheckedDevices] = useState(devices);

  const countriesOption = useCountryOptions();
  const [states, setStates] = useState([]);

  const [isEditFlowWithoutJustMMY, setIsEditFlowWithoutJustMMY] = useState(false);
  const [selectedVehicleModelDetail, setSelectedVehicleModelDetail] = useState();
  const [initialVehicleModelDetail, setInitialVehicleModelDetail] = useState();
  const [
    initialOperationalVehicleModelDetail,
    setInitialOperationalVehicleModelDetail
  ] = useState();
  const [
    selectedOperationalVehicleModelDetail,
    setSelectedOperationalVehicleModelDetail
  ] = useState();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isFormMakeModelYearDirty, setIsMakeModelYearDirty] = useState(false);
  const [isFormOperationalMakeModelYearDirty, setIsFormOperationalMakeModelYearDirty] = useState(
    false
  );
  const [isOperationalModalOpen, setIsOperationalModalOpen] = useState(false);
  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;
  const [files, setFiles] = useState([]);
  const {
    canDefaultDriver,
    DefaultDriverSelect,
    confirmDefaultDriverChange,
    setCompanyDevices,
    onCompanyFieldChange
  } = useDefaultDriverField({ vehicleInfo, formFieldName: DEFAULT_DRIVER_FORM_FIELD_NAME });

  const isMakeModelYearSelected =
    selectedVehicleModelDetail?.make &&
    selectedVehicleModelDetail?.model &&
    selectedVehicleModelDetail?.year;

  const isVehicleModelFilling =
    selectedVehicleModelDetail?.id === undefined &&
    (!!selectedVehicleModelDetail?.make ||
      !!selectedVehicleModelDetail?.model ||
      !!selectedVehicleModelDetail?.year);

  const isOperationalVehicleModelFilling =
    selectedOperationalVehicleModelDetail?.id === undefined &&
    (!!selectedOperationalVehicleModelDetail?.make ||
      !!selectedOperationalVehicleModelDetail?.model ||
      !!selectedOperationalVehicleModelDetail?.year ||
      !!selectedOperationalVehicleModelDetail?.source);

  const isMandatoryForServices = useCanOneOfCompanyServices([
    services.BPM,
    services.VPM,
    services.VPMHD
  ]);

  const isMakeModelYearMandatory = !isSiteAdmin && isMandatoryForServices;

  const hasCompanyChanged = useIsCompanyKeyDifferent('vehicles');
  useRedirectToMainFeaturePageOnCompanyChange('/settings/vehicles');

  const handleAPIVehicleDocs = vehicleInfo => {
    return vehicleInfo?.vehicleDocs?.map(doc => ({
      ...doc,
      isSaved: true
    }));
  };

  useEffect(() => {
    if (
      action === 'edit' &&
      (parseInt(vehicleId) <= 0 || isNaN(parseInt(vehicleId)) || (vehicleNotFound && !vehicleInfo))
    ) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [dispatch, action, vehicleId, handleFetchError, vehicleNotFound]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (action === 'edit') {
      dispatch(
        setPageTitle(
          formikInitialValues.name && `${t('Vehicles.Form.Edit')} ${formikInitialValues.name}`
        )
      );
    } else {
      dispatch(setPageTitle(`${t('Vehicles.Form.AddNewVehicle')}`));
    }
  }, [dispatch, formikInitialValues, action, t]);

  useEffect(() => {
    const vehiclePhone = vehicleInfo?.phone;
    if (
      (!vehiclePhone && !!phoneNumber !== !!formikInitialValues?.phone) ||
      (vehiclePhone && vehiclePhone !== phoneNumber)
    ) {
      setIsPhoneNumberDirty(true);
    } else {
      setIsPhoneNumberDirty(false);
    }
  }, [phoneNumber, vehicleInfo?.phone]);

  useEffect(() => {
    if (vehicleId) {
      const partialData = allVehicles.find(vehicle => vehicle.id === parseInt(vehicleId, 10));
      if (partialData && vehicleInfo) {
        setFiles(handleAPIVehicleDocs(vehicleInfo));
        setAssets(vehicleInfo.assets || []);
        const { installationEvents, ...partialVehicleData } = partialData;

        const data = {
          ...partialVehicleData,
          vehicleMaintenanceInfo: vehicleInfo.vehicleMaintenanceInfo,
          operationalVehicleModel: vehicleInfo.operationalVehicleModel
        };
        let { fleets, devices } = data;
        devices = devices || [];
        fleets = fleets || [];
        if (data.registrationCountry) onCountryChange(data.registrationCountry);

        const vehicleMaintenanceCustomField = {};
        Object.keys(data?.vehicleMaintenanceInfo?.customFields || {}).forEach(field => {
          vehicleMaintenanceCustomField[field] =
            data.vehicleMaintenanceInfo.customFields[field]?.date;
          vehicleMaintenanceCustomField[field + 'Notes'] =
            data.vehicleMaintenanceInfo.customFields[field]?.note;
        });
        setFormikInitialValues({
          ...initialValues,
          ...data,
          type: data.type.id,
          fleets: fleets.map(fleet => ({
            value: fleet.id,
            label: fleet.name,
            checked: true
          })),
          devices: devices.map(device => ({
            value: device.id,
            label: `${device.name} (${device.type && device.type.name})`,
            checked: true
          })),
          manufactureMonth: data.manufacturedOn
            ? parseInt(data.manufacturedOn.split('-')[1], 10)
            : '',
          manufactureYear: data.manufacturedOn ? data.manufacturedOn.split('-')[0] : '',
          ...vehicleMaintenanceCustomField
        });

        let vehicleModelData = {};
        vehicleModelData.id = data.vehicleModelId;

        if (data.make) {
          vehicleModelData.make = data.make;
        }
        if (data.model) {
          vehicleModelData.model = data.model;
        }
        if (data.manufacturedOn) {
          vehicleModelData.year = data.manufacturedOn.split('-')[0];
        }

        vehicleModelData.source = 'internal';

        setInitialVehicleModelDetail(vehicleModelData);

        if (data.operationalVehicleModel) {
          let operationalVehicleModelData = {};
          operationalVehicleModelData.id = data.operationalVehicleModel.id;
          operationalVehicleModelData.make = data.operationalVehicleModel.make;
          operationalVehicleModelData.model = data.operationalVehicleModel.model;
          operationalVehicleModelData.year = data.operationalVehicleModel.yearOfManufacture;
          operationalVehicleModelData.dbcFileKey = data.operationalVehicleModel.dbcFileKey;
          operationalVehicleModelData.source = data.operationalVehicleModel.displaySource
            ? data.operationalVehicleModel.displaySource
            : data.operationalVehicleModel.source;

          setInitialOperationalVehicleModelDetail(operationalVehicleModelData);
          setSelectedOperationalVehicleModelDetail(operationalVehicleModelData);
        }

        if (data.companyId) {
          fillListsByCompanyId(data.companyId);
          setSelectedCompany(data.companyId);
        }

        if (!data.make || !data.model || !data.manufacturedOn) {
          setIsEditFlowWithoutJustMMY(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allVehicles, vehicleId, vehicleInfo]);

  const regions = useAllRegions();
  const currentCompany = useCurrentCompany();
  const userKey = useUserKey();
  const companies = useCompanies();
  const isELDCompany = useIsELDCompany();
  const [selectedCompany, setSelectedCompany] = useState(currentCompany?.id);
  const userDefinedFields = useVehicleMaintenanceFields(selectedCompany);

  const fillListsByCompanyId = async companyId => {
    setFleetsLoaded(false);
    const embed = 'vehicles,devices';
    getFleetsByCompany(companyId, userKey, 'UP', embed).then(newFleets => {
      const formFleets = JSON.parse(JSON.stringify(newFleets))
        .filter(fleet => fleet.id)
        .map(fleet => {
          const vehicleFleets = vehiclesBoth?.find(v => v.id === Number(vehicleId))?.fleets;
          const companyName = companies?.find(company => fleet?.company?.id === company.id)?.name;
          return {
            value: fleet.id,
            label: `${fleet.name} ${companyName ? `(${companyName})` : ''}`,
            checked: !!vehicleFleets?.find(f => f.id === fleet.id) || false
          };
        });
      setFleets(formFleets);
      const newDevices = extractDevices(newFleets);
      setCompanyDevices(newDevices);

      const formDevices = [...newDevices]
        .filter(
          device =>
            device.id &&
            (!device.vehicle || device.vehicle.id === parseInt(vehicleId, 10)) &&
            device.companyId === companyId
        )
        .map(device => ({
          value: device.id,
          label: `${device.name} (${device.type && device.type.name})`,
          checked: false
        }));
      setDevices(formDevices);
      setFleetsLoaded(true);
    });

    // Set the vehicle types for the new company
    const types = await dispatch(fetchVehicleTypesByCompany(companyId));
    setVehicleTypes(prepareVehicleTypes(types, companyId));
  };

  const updateFormOnCompanyChange = (newCompanyId, setFieldValue) => {
    //remove all fleet and device associations when company id changes from the initial one on the edit proccess
    if (
      newCompanyId !== formikInitialValues.companyId &&
      action === ACTIONS.EDIT &&
      setFieldValue
    ) {
      setFieldValue('devices', []);
      setFieldValue('fleets', []);
    }

    // This should only be updated if we're not editing
    fillListsByCompanyId(newCompanyId);
  };

  useEffect(() => {
    dispatch(getConfig(currentCompany?.id));
  }, [currentCompany]);

  useEffect(() => {
    setRegistrationTerritoryLabel(getRegistrationTerritoryLabel(currentCompany?.country));
    // Do not update edit form when the company changes from the dropdown
    if (path && path.includes(VEHICLE_EDIT_PATH)) return;
    updateFormOnCompanyChange(currentCompany.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, currentCompany.id]);

  useEffect(() => {
    const formDevices = uniqBy([...allDevices], 'id')
      .filter(device => device.id && !device.vehicle && device.companyId === currentCompany.id)
      .map(device => ({
        value: device.id,
        label: `${device.name} (${device.type && device.type.name})`,
        checked: false
      }));
    setDevices(formDevices);
  }, [allDevices]);

  useEffect(() => {
    setRegistrationCountryLabel(getRegistrationCountryLabel(selectedCountry));
    setStates(getCountryRegions({ country: selectedCountry, addCountryAsRegion: true }));
  }, [selectedCountry]);

  const onCompanyChange = (company, setFieldValue) => {
    setFieldValue('companyId', company);
    setSelectedCompany(company);
    if (!company) {
      return;
    }
    if (formikInitialValues.companyId !== '' && company !== formikInitialValues.companyId) {
      setShowCompanyChangeWarning(true);
    } else {
      setShowCompanyChangeWarning(false);
    }
    updateFormOnCompanyChange(company, setFieldValue);
  };

  const showOperationalVehicleModal = () => {
    setIsOperationalModalOpen(true);
  };

  const disposeOperationalVehicleModal = () => {
    setIsOperationalModalOpen(false);
  };

  useEffect(() => {
    if (action === ACTIONS.ADD && countriesOption.length === 1) {
      const allRegions = getCountryRegions({
        country: countriesOption[0]?.value,
        addCountryAsRegion: true
      });
      setSelectedCountry(countriesOption[0]?.value);

      setFormikInitialValues({
        ...formikInitialValues,
        registrationCountry:
          action === 'add' && (countriesOption || []).length === 1
            ? countriesOption[0].value
            : formikInitialValues.registrationCountry,
        registrationState:
          action === 'add'
            ? allRegions.length === 1
              ? allRegions[0].value
              : ''
            : formikInitialValues.registrationState
      });
    }
  }, [countriesOption]);

  useEffect(() => {
    if (action === ACTIONS.EDIT && formikInitialValues.registrationState && regions) {
      let defaultSelected;
      regions.forEach(country => {
        if (country.code === formikInitialValues.registrationCountry) {
          const formStates = JSON.parse(country.states);
          Object.keys(formStates).forEach(label => {
            if (formStates[label] === formikInitialValues.registrationState) {
              defaultSelected = { label, value: formStates[label] };
            }
          });
        }
      });

      setStates(
        getCountryRegions({
          country: formikInitialValues.registrationCountry,
          addCountryAsRegion: true,
          defaultValue: defaultSelected
        })
      );
    }
  }, [countriesOption, regions, formikInitialValues]);

  const onCountryChange = (country, setValues) => {
    setSelectedCountry(country);
    if (setValues) {
      const regions = getCountryRegions({ country: country, addCountryAsRegion: true });
      setValues(prevValues => {
        return {
          ...prevValues,
          registrationCountry: country,
          registrationState: regions.length === 1 ? regions[0].value : ''
        };
      });
    }
  };

  const handleChangeImage = file => {
    setImage(file);
    setIsFormDirty(true);
  };

  const handlePromises = (
    vehicleId,
    submitValues,
    checkedDevices,
    setSubmitting,
    successMessage,
    handleVehicleSave = false,
    filesUploadStatus = []
  ) => {
    if (!vehicleId) return;
    setPromptModalWhenLeaving(false);
    const promiseArray = [];
    let promiseDelay = 10;
    if (checkedDevices.length > 0 || formikInitialValues.devices.length > 0) {
      checkedDevices.forEach(device => {
        let deviceAlreadyExists = false;
        formikInitialValues.devices.forEach(ivDevice => {
          if (ivDevice.value === device.id) {
            deviceAlreadyExists = true;
          }
        });
        !deviceAlreadyExists &&
          promiseArray.push(
            new Promise(resolve =>
              setTimeout(
                () => resolve(dispatch(associateDeviceToVehicleApi(device.id, vehicleId))),
                promiseDelay
              )
            )
          );
        promiseDelay += 10;
      });
      formikInitialValues.devices.forEach(ivDevice => {
        let deviceRemovedFromExistingVehicle = true;
        checkedDevices.forEach(device => {
          if (device.id === ivDevice.value) {
            deviceRemovedFromExistingVehicle = false;
          }
        });
        deviceRemovedFromExistingVehicle &&
          promiseArray.push(
            new Promise(resolve =>
              setTimeout(
                () => resolve(dispatch(associateDeviceToVehicleApi(ivDevice.value, null))),
                promiseDelay
              )
            )
          );
        promiseDelay += 10;
      });
      Promise.all(promiseArray)
        .then(async () => {
          if (handleVehicleSave) {
            performVehicleSave(
              vehicleId,
              submitValues,
              setSubmitting,
              successMessage,
              filesUploadStatus
            );
          } else {
            handleSaveSuccess(vehicleId, setSubmitting, successMessage, filesUploadStatus);
          }
        })
        .catch(err => {
          try {
            dispatch(
              openToast({
                type: ToastType.Error,
                message: err.response.text
              })
            );
          } catch (e) {
            dispatch(
              openToast({
                type: ToastType.Error,
                message: err.message
              })
            );
          }
          setSubmitting(false);
        });
    } else {
      if (handleVehicleSave) {
        performVehicleSave(
          vehicleId,
          submitValues,
          setSubmitting,
          successMessage,
          filesUploadStatus
        );
      } else {
        handleSaveSuccess(vehicleId, setSubmitting, successMessage, filesUploadStatus);
      }
    }
  };

  const performVehicleSave = async (
    vehicleId,
    submitValues,
    setSubmitting,
    successMessage,
    filesUploadStatus
  ) => {
    const saveVehicleResponse = await dispatch(saveVehicleApi('PUT', vehicleId, submitValues));
    if (saveVehicleResponse && saveVehicleResponse.id) {
      const { id } = saveVehicleResponse;
      handleSaveSuccess(id, setSubmitting, successMessage, filesUploadStatus);
    }
  };

  const onSubmitDeleteAction = fileObj => () => {
    dispatch(removeFiles(fileObj));
    setFiles(files.filter(file => file.filename !== fileObj.filename));
  };

  const handleImageRemove = () => {
    setImageRemoved(true);
  };

  const handleDeleteModal = file => {
    confirmationModal(
      `${t('Common.DeleteButton')} ${file.filename}`,
      `${t('Common.SureDelete')} ${file.filename}?`,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      onSubmitDeleteAction(file),
      'delete'
    );
  };

  const removeFile = fileObj => () => {
    // do not show modal confirmation for the files that are not yet uploaded, just delete them
    if (fileObj && fileObj.id) {
      //if the file has id, show modal and remove them from api
      handleDeleteModal(fileObj);
      return;
    }
    setFiles(files.filter(file => file.name !== fileObj.name));
  };

  const renderFileDetails = () =>
    files?.map(file => {
      const uploadDate = file.uploadedAt
        ? format(
            moment(file.uploadedAt.split('+')[0]).toDate(),
            localization.formats.time.formats.dby_imp
          )
        : format(moment().toDate(), localization.formats.time.formats.dby_imp);
      return {
        id: file.id || uuidv4(),
        file: file.filename ?? file.name,
        fileSize: file.filesize ?? file.size,
        uploadedBy:
          file.uploadedBy ||
          `${currentUser.firstName} ${currentUser.lastName}` ||
          `${vehicleInfo.firstName} ${vehicleInfo.lastName}`,
        uploadDate,
        actions: removeFile(file)
      };
    });

  const handleAttachment = event => {
    const {
      target: { files: uploadFiles }
    } = event;

    if (!uploadFiles || !uploadFiles.length) return;
    // Since files is a set - we need to convert it to an actual array
    const filesToAdd = [...uploadFiles];

    // Remove duplicates
    const currentFileNames = files.map(file => file.filename || file.name);
    const uniqueFiles = filesToAdd.filter(file => !currentFileNames.some(fn => fn === file.name));

    const filesToSave = filterFilesBy(uniqueFiles, {
      type: {
        values: ['application/pdf'],
        callback: name => {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('Vehicles.Notifications.OnlyPdf', {
                name: name
              })
            })
          );
        }
      },
      size: {
        value: maxDocumentUploadSizeInBytes,
        callback: name => {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('Vehicles.Notifications.FileSize', {
                name: name,
                fileSize: maxFileSize
              })
            })
          );
        }
      }
    });
    setIsFormDirty(true);
    setFiles([...files, ...filesToSave]);
  };

  const handleSaveSuccess = (vehicleId, setSubmitting, successMessage, filesUploadStatus) => {
    setSubmitting(false);
    dispatch(fetchFleets());
    setTimeout(() => {
      // Added timeout in order for the backend to give back correct data after Create/Update
      // Otherwise the associated fleets won't show in the grid
      dispatch(fetchFleetsBoth());
    }, 1000);
    dispatch(fetchVehiclesStats());
    dispatch(fetchSingleVehicle(vehicleId));
    dispatch(
      openToast({
        type: ToastType.Success,
        message: successMessage
      })
    );
    setIsPhoneNumberDirty(false);
    setIsMakeModelYearDirty(false);
    setIsFormOperationalMakeModelYearDirty(false);
    if (filesUploadStatus?.length > 0) {
      filesUploadStatus.some(elem => elem.statusCode === 200) &&
        canHistoryGoBack(history, '/settings/vehicles');
    } else {
      canHistoryGoBack(history, '/settings/vehicles');
    }
  };

  const onSubmit = async (values, actions) => {
    if (values.serviceOn) {
      values.serviceOn = moment(values.serviceOn).format('YYYY-MM-DD');
    }

    const checkedDevices = userCheckedDevices.reduce((accumulator, device) => {
      if (device.checked) {
        accumulator.push({ id: device.value });
      }
      return accumulator;
    }, []);

    const { confirmed, removeDefaultDriverField } = await confirmDefaultDriverChange({
      ...values,
      devices: checkedDevices
    });
    if (!confirmed) {
      return;
    }

    const checkedInitialDeviceIds = formikInitialValues.devices.map(device => {
      return { id: device.value };
    });
    const hasDeviceSelectionChanged = !arraysEqual(
      checkedInitialDeviceIds,
      checkedDevices.map(device => {
        return { id: device };
      })
    );

    const checkedFleets = userCheckedFleets.reduce((accumulator, fleet) => {
      if (fleet.checked) {
        accumulator.push(fleet.value);
      }
      return accumulator;
    }, []);
    const checkedInitialFleetIds = formikInitialValues.fleets.map(fleet => {
      return { id: fleet.value };
    });
    const hasFleetSelectionChanged = !arraysEqual(
      checkedInitialFleetIds,
      checkedFleets.map(fleet => {
        return { id: fleet };
      })
    );

    //The select input fields for fleets and devices don't influence the values object
    const isFormChanged =
      !objectsEqual(formikInitialValues, values) ||
      hasDeviceSelectionChanged ||
      hasFleetSelectionChanged;
    const { setSubmitting } = actions;
    const { company, ...saveValues } = values;

    let vehicleCustomMapping = {};

    for (let i = 1; i <= MAXIMUM_USER_DEFINED_FIELD; i++) {
      const dateVal = saveValues['field' + i];
      const noteVal = saveValues['field' + i + 'Notes'];

      if (dateVal) {
        vehicleCustomMapping['field' + i] = {
          date: dateVal ? moment(dateVal).format('YYYY-MM-DD') : '',
          note: noteVal ? noteVal : ''
        };
      }
    }

    const vehicleMaintenanceInfo = {
      customFields: vehicleCustomMapping
    };

    if (selectedVehicleModelDetail) {
      saveValues.vehicleModelId = selectedVehicleModelDetail.id;
      saveValues.make = selectedVehicleModelDetail.make;
      saveValues.model = selectedVehicleModelDetail.model;
      saveValues.manufactureYear = selectedVehicleModelDetail.year;
      saveValues.source = selectedVehicleModelDetail.source;
    } else {
      delete saveValues.vehicleModelId;
      saveValues.make = selectedVehicleModelDetail.make || '';
      saveValues.model = selectedVehicleModelDetail.model || '';
      saveValues.manufactureYear = selectedVehicleModelDetail.year || '';
      saveValues.source = selectedVehicleModelDetail.source || '';
    }

    delete saveValues.operationalVehicleModel;
    saveValues.operationalVehicleModelId = selectedOperationalVehicleModelDetail?.id || '';
    saveValues.dbcFileKey = selectedOperationalVehicleModelDetail?.dbcFileKey || '';

    const submitValues = {
      ...saveValues,
      externalId: values.externalId !== '' ? values.externalId : null,
      devices: checkedDevices,
      fleets: checkedFleets,
      phone: phoneNumberWithoutDialCode ? phoneNumber : '',
      type: { id: values.type },
      vehicleMaintenanceInfo,
      assets,
      manufacturedOn: saveValues.manufactureYear
        ? new Date(
            parseInt(saveValues.manufactureYear, 10),
            values.manufactureMonth ? parseInt(values.manufactureMonth, 10) : 0,
            0,
            0,
            0,
            0,
            0
          )
        : ''
    };

    if (removeDefaultDriverField) {
      delete submitValues?.[DEFAULT_DRIVER_FORM_FIELD_NAME];
    }

    let successMessage = t('Vehicles.Notifications.Added', {
      name: values.name
    });
    if (vehicleId && vehicleId !== 'newVehicle') {
      successMessage = t('Vehicles.Notifications.Updated', {
        name: values.name
      });
    }

    //Checking for duplicate registration state and registration number
    const identicalRegistrationVehicle = allVehicles
      .filter(vehicle => vehicle.id !== submitValues.id)
      .find(
        vehicle =>
          vehicle.registrationState === submitValues.registrationState &&
          vehicle.registration === submitValues.registration
      );
    if (identicalRegistrationVehicle) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Vehicles.Notifications.DuplicateRegistrationLabel', {
            registrationStateLabel: getRegistrationTerritoryLabel(
              currentCompany.country
            ).toLowerCase()
          }),
          autohide: true
        })
      );
      setSubmitting(false);
      return;
    }

    if (image) {
      const requestParams = !isNaN(vehicleId) && { vehicleId };

      const saveResponse = await dispatch(saveImage(image, requestParams));
      if (vehicleId !== 'newVehicle' && saveResponse.success) {
        submitValues['imageRef'] = saveResponse.imageRef;
      }
    }

    if (vehicleInfo?.imageUrl && imageRemoved) {
      const requestParams = !isNaN(vehicleId) && { vehicleId };

      await dispatch(removeImage(requestParams));
    }

    // prevent vehicle update if nothing has been changed
    if (!isFormChanged) {
      handleSaveSuccess(vehicleId, setSubmitting, successMessage);
      return;
    }

    const filesToAttach = files.filter(file => !file.isSaved);

    // save
    if (action === 'add') {
      const saveVehicleResponse = await dispatch(saveVehicleApi('POST', false, submitValues));
      if (saveVehicleResponse?.id) {
        const { id } = saveVehicleResponse;
        const filesUploadStatus = await dispatch(attachFiles(filesToAttach, id));
        handlePromises(
          id,
          submitValues,
          checkedDevices,
          setSubmitting,
          successMessage,
          '',
          filesUploadStatus
        );
      }
    }

    if (action === 'edit') {
      const handleVehicleSave = true;
      const filesUploadStatus = await dispatch(attachFiles(filesToAttach, vehicleId));
      handlePromises(
        vehicleId,
        submitValues,
        checkedDevices,
        setSubmitting,
        successMessage,
        handleVehicleSave,
        filesUploadStatus
      );
    }
  };

  const addNewAsset = () => {
    if (newAsset) {
      setAssets([...assets, newAsset]);
      setNewAsset('');
      setIsFormDirty(true);
    }
  };
  const deleteAsset = index => {
    const newAssets = [...assets];
    newAssets.splice(index, 1);
    setAssets(newAssets);
    setIsFormDirty(true);
  };

  const preSubmitMethod = (e, handleSubmit) => {
    e.preventDefault();
    if (showCompanyChangeWarning) {
      const initialDevices = allVehicles.find(v => v.id === Number(vehicleId))?.devices || [];
      const hasEDRDevice = initialDevices.some(d => d.type?.code === 'EDR');
      const content = hasEDRDevice ? (
        <div>
          {t('Vehicles.Form.ChangeCompanySaveWarningEDR')}
          <br />
          {t('Vehicles.Form.ChangeCompanySaveWarningEDR2')}
        </div>
      ) : (
        t('Vehicles.Form.ChangeCompanySaveWarning')
      );

      confirmationModal(
        t('Roles.ConfirmationModal.AreYouSure'),
        content,
        t('Common.Modal.OK'),
        t('Common.Modal.Cancel'),
        () => handleSubmit(),
        'delete'
      );
      document.body.click();
    } else {
      handleSubmit();
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const renderUserDefinedLabel = userDefinedField => {
    if (!userDefinedField) {
      return `${t('Vehicles.Form.UserDefined')}`;
    }
    return userDefinedField;
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...formikInitialValues,
          companyId: action === 'add' ? currentCompany.id : formikInitialValues.companyId,
          [DEFAULT_DRIVER_FORM_FIELD_NAME]: vehicleInfo?.[DEFAULT_DRIVER_FORM_FIELD_NAME]
        }}
        validationSchema={validationSchema(
          allVehicleNames,
          formikInitialValues,
          allVehicleExternalIds,
          t,
          isELDCompany,
          currentCompany.country
        )}
        onSubmit={onSubmit}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          values,
          setSubmitting,
          setFieldValue,
          initialValues,
          validateField,
          setValues
        }) => {
          return (
            <>
              <EditRouteGuard
                when={
                  ((dirty && promptModalWhenLeaving) ||
                    isPhoneNumberDirty ||
                    isFormMakeModelYearDirty ||
                    isFormOperationalMakeModelYearDirty) &&
                  !hasCompanyChanged
                }
                navigate={history.push}
              />

              <Form id="VehicleForm" className={styles.vehicleForm}>
                <Tabs defaultActiveKey="vehicle-details" transition={false}>
                  <Tab eventKey="vehicle-details" title={t('Vehicles.Form.VehicleDetails')}>
                    <div className={styles.formContainer}>
                      {isEditFlowWithoutJustMMY && isMakeModelYearMandatory && (
                        <div className={styles.warningContainer}>
                          <Alert
                            description={<Trans i18nKey="Vehicles.Form.EditMMYWarning" />}
                            type="warning"
                            showIcon
                          />
                        </div>
                      )}
                      <Row>
                        <FormInput
                          name="name"
                          label={t('Vehicles.Form.Name')}
                          placeholder={t('Vehicles.Form.NamePlaceholder')}
                          isRequired
                        />
                        <Field name="image-upload">
                          {() => (
                            <FormGroup as={Col}>
                              <FormLabel>{t('Vehicles.Form.Image')}</FormLabel>
                              <ImageUpload
                                onChange={handleChangeImage}
                                imageUrl={vehicleInfo?.imageUrl}
                                onRemoveImage={handleImageRemove}
                              />
                            </FormGroup>
                          )}
                        </Field>
                      </Row>

                      <Row>
                        <SearchableListMultiSelect
                          name="fleets"
                          label={t('Vehicles.Form.Fleet')}
                          placeholder={t('Vehicles.Form.FleetPlaceholder')}
                          allLabel={t('Common.AllFleets')}
                          values={fleets}
                          initialValues={formikInitialValues.fleets}
                          setFieldValue={setUserCheckedFleets}
                          height={250}
                          isLoading={!fleetsLoaded}
                          onCheckboxChanged={setIsFormDirty}
                        />
                        <Col>
                          <Row>
                            <FormSelect
                              name="companyId"
                              label={t('Vehicles.Form.Company')}
                              onChange={id => {
                                onCompanyChange(parseInt(id, 10), setFieldValue);
                                onCompanyFieldChange(id, initialValues, setFieldValue);
                              }}
                              placeholder=""
                              values={companies.map(company => ({
                                label: company.name,
                                value: company.id
                              }))}
                              isRequired
                              isDisabled={action === 'edit' && !currentUser.siteAdmin}
                            />
                          </Row>
                          <Row>
                            <FormSelect
                              name="type"
                              label={t('Vehicles.Form.Type')}
                              placeholder={t('Vehicles.Form.TypePlaceholder')}
                              values={vehicleTypes}
                              isRequired
                            />
                          </Row>
                          <Row>
                            <FormDatePicker
                              name="serviceOn"
                              label={t('Vehicles.Form.DateInService')}
                              format={localization.formats.time.formats.dby}
                              setFieldValue={setFieldValue}
                            />
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <FormSelect
                          name="registrationCountry"
                          label={registrationCountryLabel} //TODO Check how you can translate here
                          placeholder={`${t('Locations.View.Select')} ${registrationCountryLabel}`}
                          onChange={registrationCountry => {
                            onCountryChange(registrationCountry, setValues);
                          }}
                          values={countriesOption}
                          isRequired
                        />
                        <FormSelect
                          name="registrationState"
                          label={registrationStateLabel} //TODO Check how you can translate here
                          placeholder={`${t('Locations.View.Select')} ${registrationStateLabel}`}
                          values={states}
                          isRequired
                        />
                      </Row>
                      <Row>
                        <FormInput
                          name="registration"
                          label={t('Vehicles.Form.RegistrationNumber')}
                          placeholder={t('Vehicles.Form.RegistrationNumberPlaceholder')}
                          isRequired
                        />
                        <FormInput
                          name="engineNumber"
                          label={t('Vehicles.Form.EngineNumber')}
                          placeholder={t('Vehicles.Form.EngineNumberPlaceholder')}
                        />
                      </Row>
                      <Row>
                        <VINField t={t} hasELDValidation={isELDCompany} />
                        <FormInput
                          name="externalId"
                          label={t('Vehicles.Form.ExternalID')}
                          placeholder={t('Vehicles.Form.ExternalIDPlaceholder')}
                          isValidated
                        />
                      </Row>
                      <Row>
                        <SearchableListMultiSelect
                          name="devices"
                          label={t('Vehicles.Form.Device')}
                          placeholder={t('Vehicles.Form.DevicePlaceholder')}
                          values={devices}
                          initialValues={formikInitialValues.devices}
                          setFieldValue={setUserCheckedDevices}
                          height={250}
                          hideSelectAll
                          isLoading={!fleetsLoaded}
                          onCheckboxChanged={setIsFormDirty}
                        />
                        <VehicleMakeModelYearForm
                          showSourceSelection={false}
                          initialVehicleModel={initialVehicleModelDetail}
                          selectedOperationalVehicleModelDetail={
                            selectedOperationalVehicleModelDetail
                          }
                          isRequired={isMakeModelYearMandatory}
                          companyId={selectedCompany}
                          setShowOverwriteModal={showOperationalVehicleModal}
                          onChange={data => {
                            setValues({ ...values, ...data });
                            setIsMakeModelYearDirty(true);
                          }}
                          onModelSelected={setSelectedVehicleModelDetail}
                        />
                      </Row>
                      <Row>
                        <PhoneInputField
                          preferredCountryCodes={[localization.region, currentCompany.country]}
                          initialValue={phoneNumber}
                          label={t('Vehicles.Form.PhoneNumber')}
                          onChange={handlePhoneNumberChange}
                          countryCodeEditable={action === 'edit'}
                        />
                        <FormInput
                          name="note"
                          label={t('Vehicles.Form.Notes')}
                          placeholder={t('Vehicles.Form.NotesPlaceholder')}
                          as="textarea"
                        />
                      </Row>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="maintenance"
                    title={t('Vehicles.Form.VehicleMaintenanceInformation')}
                    className={styles.maintenanceTab}
                  >
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '100%' }}>
                        <Row>
                          {userDefinedFields && Object.keys(userDefinedFields).length !== 0 && (
                            <Col xs={12} md={12} lg={12} xl={6}>
                              <div className={styles.formContainer}>
                                {Object.keys(userDefinedFields).map((data, index) => {
                                  return (
                                    <Row key={index}>
                                      <FormDatePicker
                                        name={data}
                                        label={renderUserDefinedLabel(userDefinedFields[data])}
                                        format={localization.formats.time.formats.dby}
                                        placeholder={t('Vehicles.Form.SelectDate')}
                                        setFieldValue={setFieldValue}
                                        xs={5}
                                        drops="down"
                                        className={styles.vehicleMaintenanceDatePicker}
                                      />
                                      <FormInput name={data + 'Notes'} />
                                    </Row>
                                  );
                                })}
                              </div>
                            </Col>
                          )}
                          <Col xs={12} md={12} lg={12} xl={6}>
                            <div className={styles.formContainer}>
                              <Row>
                                <Col>
                                  <FormTitle
                                    underlined
                                    title={t('Vehicles.Form.Assets')}
                                    customStyling={{ fontWeight: '400', fontSize: '15px' }}
                                  />
                                  <div className={styles.addAssetForm}>
                                    <Input
                                      style={{ width: 'calc(100% - 110px)' }}
                                      size="large"
                                      value={newAsset}
                                      onChange={e => setNewAsset(e.target.value)}
                                      onPressEnter={e => {
                                        e.preventDefault();
                                        addNewAsset();
                                      }}
                                    />
                                    <Button
                                      size="large"
                                      type="primary"
                                      id={BUTTON_IDS.vehicleFormAddAsset}
                                      onClick={addNewAsset}
                                    >
                                      {t('Vehicles.Form.AddAsset')}
                                    </Button>
                                  </div>
                                  <List
                                    size="default"
                                    dataSource={assets}
                                    style={{
                                      maxHeight: '500px',
                                      overflow: 'scroll',
                                      marginTop: '16px'
                                    }}
                                    renderItem={(item, index) => (
                                      <List.Item
                                        style={{ margin: '0px 3px' }}
                                        actions={[
                                          <span
                                            key="list-delete"
                                            onClick={() => deleteAsset(index)}
                                          >
                                            <i style={{ content: `url(${removeIcon})` }} />
                                          </span>
                                        ]}
                                      >
                                        {item}
                                      </List.Item>
                                    )}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="upload"
                    title={t('Vehicles.Form.VehicleUpload')}
                    className={styles.maintenanceTab}
                  >
                    <FileUpload onAdd={handleAttachment} />
                    <DocumentsDisplay rows={renderFileDetails()} />
                  </Tab>
                  {canDefaultDriver({
                    ...values,
                    devices: userCheckedDevices
                      .filter(d => d.checked)
                      .map(d => ({ ...d, id: d.value }))
                  }) && (
                    <Tab
                      eventKey="driverMng"
                      title={t('Entity.Driver Management')}
                      disabled={!fleetsLoaded}
                    >
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '100%' }}>
                          <Row>
                            <Col xs={24} md={24} lg={24} xl={12}>
                              <DefaultDriverSelect />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Tab>
                  )}
                </Tabs>
                <div className={styles.formFooter}>
                  <Button
                    size="large"
                    type="primary"
                    disabled={
                      (!dirty &&
                        !isFormDirty &&
                        !isFormMakeModelYearDirty &&
                        !isFormOperationalMakeModelYearDirty &&
                        !isPhoneNumberDirty) ||
                      !fleetsLoaded ||
                      !isValid ||
                      (isMakeModelYearMandatory && !isMakeModelYearSelected) ||
                      isVehicleModelFilling ||
                      (isSiteAdmin && isOperationalVehicleModelFilling)
                    }
                    id={BUTTON_IDS.vehicleFormSave}
                    onClick={e => preSubmitMethod(e, handleSubmit)}
                  >
                    {t('Common.Save')}
                  </Button>
                  <Button size="large" id={BUTTON_IDS.vehicleFormCancel} onClick={handleCancel}>
                    {t('Common.Cancel')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
      <VehicleOperationalMakeModelYearFormModal
        handleCancel={disposeOperationalVehicleModal}
        isOpen={isOperationalModalOpen}
        companyId={selectedCompany}
        setSelectedOperationalVehicleModelDetail={e => {
          setIsFormOperationalMakeModelYearDirty(true);
          setSelectedOperationalVehicleModelDetail(e);
          disposeOperationalVehicleModal();
        }}
        initialOperationalVehicleModelDetail={initialOperationalVehicleModelDetail}
      />
    </>
  );
};

const VINField = ({ t, hasELDValidation }) => (
  <div className={styles.vinField}>
    <FormInput
      name="vin"
      label={t('Vehicles.Form.VIN')}
      placeholder={t('Vehicles.Form.VINPlaceholder')}
      isValidated
    />
    {hasELDValidation && (
      <Field name="eldVehicle">
        {({ field }) => (
          <FormCheck type="checkbox" className={styles.vinFieldExempt}>
            <Tooltip
              content={t('Vehicles.Form.ELDExemptVINCheckInfo')}
              target={
                <>
                  <FormCheck.Input
                    {...field}
                    type="checkbox"
                    id="eldVehicle"
                    checked={field.value}
                  />
                  <FormCheck.Label htmlFor="eldVehicle">
                    {t('Vehicles.Form.ELDExemptVINCheck')}
                  </FormCheck.Label>
                </>
              }
            />
          </FormCheck>
        )}
      </Field>
    )}
  </div>
);
