import { ApiClient, CompaniesApi } from '../../nextgen_api';
import { API_PATH } from '../../config';
import request from 'superagent';

const apiClient = new ApiClient();

function companiesApiClient(key) {
  apiClient.basePath = API_PATH;
  apiClient.defaultHeaders = {
    Authorization: 'Token token="' + key + '"'
  };
  return new CompaniesApi(apiClient);
}

const getUniqueCompanies = companies => {
  const uniqueCompanies = new Map();
  companies.forEach(
    company => !uniqueCompanies.has(company.id) && uniqueCompanies.set(company.id, company)
  );
  return [...uniqueCompanies.values()];
};

export async function fetchCompanies(key, active = true) {
  const companiesApi = companiesApiClient(key);
  const request = new Promise((resolve, reject) => {
    apiClient.defaultHeaders = {};
    companiesApi.list(
      key,
      { pruning: 'ALL', embed: 'services', active: active },
      (err, data, resp) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(getUniqueCompanies(resp.body));
        }
      }
    );
  });

  return request;
}

export async function fetchSubCompanies(
  key,
  companyId,
  embed = '',
  active = true,
  direction = 'DOWN'
) {
  const subCompaniesRequest = new Promise((resolve, reject) => {
    const url = `${API_PATH}/companies?company_id=${companyId}&direction=${direction}&active=${active}&pruning=ALL${embed &&
      `&embed=${embed}`}`;
    request('GET', url)
      .set('Authorization', `Token token = "${key}"`)
      .set('Content-Type', 'application/json')
      .then(resp => {
        resolve(getUniqueCompanies(resp.body));
      })
      .catch(err => {
        reject(err);
      });
  });
  return subCompaniesRequest;
}
