import React from 'react';
import moment from 'moment';
import { Space } from 'antd';

import { format } from 'utils/dates';

import { entities, services } from 'features/permissions';
import {
  DEVICE_DYNAMIC_LINKED_VEHICLE,
  VEHICLE_DYNAMIC_LINKED_DEVICE
} from 'features/vehicles/hooks';

import {
  DEFAULT_VEHICLE_ICON,
  DEFAULT_DEVICE_ICON,
  DEFAULT_DRIVER_ICON,
  RECIPIENT_TYPE,
  MESSAGE_TYPE,
  MESSAGE_STATUE,
  MESSAGE_SENDER_TYPE,
  MESSAGE_NOTIFICATION_TYPE,
  MESSAGE_PERMISSION_ENTITY_TYPE,
  NextGen_Message_Event_Status,
  RECIPIENT_TREE_NODE_TYPE,
  STORE_DATE_FORMAT,
  ENTITY_DIVIDER
} from './constants';
import { uniqWith } from 'lodash';

const uniqById = arr => uniqWith(arr || [], (x, y) => parseInt(x?.id, 10) === parseInt(y?.id, 10));

export const vehicleIconTitleFunc = vehicleName => (
  <Space>
    <i className={DEFAULT_VEHICLE_ICON} />
    {vehicleName}
  </Space>
);
export const deviceIconTitleFunc = deviceName => (
  <Space>
    <i className={DEFAULT_DEVICE_ICON} />
    {deviceName}
  </Space>
);
export const driverIconTitleFunc = driverName => (
  <Space>
    <i className={DEFAULT_DRIVER_ICON} />
    {driverName}
  </Space>
);

export const isMessagingDevice = device =>
  device &&
  device.type &&
  (device.type.code === 'IFACE' ||
    (device.type.features?.length && device.type.features.some(f => f === 'CAB')));

export const isMessagingEnabledDevice = device =>
  isMessagingDevice(device) && device?.services?.includes(services.MESSAGING);

export const isRouteToEnabledDevice = device =>
  isMessagingEnabledDevice(device) && device?.services?.includes(services.SMARTNAV3D);

const getFleetTree = (t, fleets, vehicleTitleFunc, deviceTitleFunc, recipientExtra) => {
  const fleetList = getFleetList(t, fleets, vehicleTitleFunc, deviceTitleFunc, recipientExtra);
  vehicleTitleFunc = vehicleTitleFunc || (vehicleName => vehicleName);
  deviceTitleFunc = deviceTitleFunc || (deviceName => deviceName);
  recipientExtra = recipientExtra || {};
  return {
    title: t('Common.AllFleets'),
    value: 'fleet-all',
    treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.ALL_FLEET,
    treeNodeEntityName: t('Common.AllFleets'),
    checkedValue: [
      ...fleetList
        .map(fleet => fleet.interactiveVehicles)
        .reduce((a, c) => a.concat(c), [])
        .map(vehicle =>
          (vehicle.devices || [])
            .map(vD => ({
              ...recipientExtra,
              vehicle: {
                id: vehicle.id
              },
              device: vD,
              eventEntityType: RECIPIENT_TYPE.VEHICLE,
              eventEntityId: vehicle.id
            }))
            .reduce((a, c) => a.concat(c), [])
        )
        .reduce((a, c) => a.concat(c), []),
      ...fleetList
        .map(fleet => fleet.interactiveStandaloneDevices)
        .reduce((a, c) => a.concat(c), [])
        .map(device => ({
          ...recipientExtra,
          device: device.id,
          eventEntityType: RECIPIENT_TYPE.DEVICE,
          eventEntityId: device.id
        }))
    ],
    children: fleetList.map(fleet => ({
      title: fleet.name,
      value: `fleet-${fleet.id}`,
      treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.FLEET,
      treeNodeEntityId: fleet.id,
      treeNodeEntityName: fleet.name,
      checkedValue: [
        ...fleet.interactiveVehicles
          .map(vehicle =>
            (vehicle.devices || [])
              .map(vD => ({
                ...recipientExtra,
                vehicle: {
                  id: vehicle.id
                },
                device: vD,
                eventEntityType: RECIPIENT_TYPE.VEHICLE,
                eventEntityId: vehicle.id
              }))
              .reduce((a, c) => a.concat(c), [])
          )
          .reduce((a, c) => a.concat(c), []),
        ...fleet.interactiveStandaloneDevices.map(device => ({
          ...recipientExtra,
          device: device.id,
          eventEntityType: RECIPIENT_TYPE.DEVICE,
          eventEntityId: device.id
        }))
      ],
      children: [
        ...fleet.interactiveVehicles.map(vehicle => ({
          title: vehicleTitleFunc(vehicle.name),
          value: `${fleet.id}-${vehicle.id}`,
          treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.VEHICLE,
          treeNodeEntityId: vehicle.id,
          treeNodeEntityName: vehicle.name,
          rawData: vehicle,
          checkedValue: (vehicle.devices || []).map(vD => ({
            ...recipientExtra,
            vehicle: {
              id: vehicle.id
            },
            device: vD,
            eventEntityType: RECIPIENT_TYPE.VEHICLE,
            eventEntityId: vehicle.id
          }))
        })),
        ...fleet.interactiveStandaloneDevices.map(device => ({
          title: deviceTitleFunc(device.name),
          value: `${fleet.id}-${device.id}`,
          treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.DEVICE,
          treeNodeEntityId: device.id,
          treeNodeEntityName: device.name,
          rawData: device,
          checkedValue: [
            {
              ...recipientExtra,
              device: { id: device.id },
              eventEntityType: RECIPIENT_TYPE.DEVICE,
              eventEntityId: device.id
            }
          ]
        }))
      ]
    })),
    disableCheckbox: !fleetList.length
  };
};

const getBranchTree = (t, branches, drivers, devicesStats, driverTitleFunc, recipientExtra) => {
  const branchList = getBranchList(
    t,
    branches,
    drivers,
    devicesStats,
    driverTitleFunc,
    recipientExtra
  );
  driverTitleFunc = driverTitleFunc || (driverName => driverName);
  recipientExtra = recipientExtra || {};
  return {
    title: t('Common.AllBranches'),
    value: 'branch-all',
    treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.ALL_BRANCH,
    treeNodeEntityName: t('Common.AllBranches'),
    checkedValue: branchList
      .map(branch => branch.interactiveDrivers)
      .reduce((a, c) => a.concat(c), [])
      .map(driver =>
        driver.interactiveDevices.map(driverDevice => ({
          ...recipientExtra,
          receiver: { id: driver.id },
          device: {
            id: driverDevice.deviceId
          },
          eventEntityType: RECIPIENT_TYPE.DRIVER,
          eventEntityId: driver.id
        }))
      )
      .reduce((a, c) => a.concat(c), []),
    children: branchList.map(branch => ({
      title: branch.name,
      value: `branch-${branch.id}`,
      treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.BRANCH,
      treeNodeEntityId: branch.id,
      treeNodeEntityName: branch.name,
      checkedValue: branch.interactiveDrivers
        .map(driver => {
          return driver.interactiveDevices.map(driverDevice => ({
            ...recipientExtra,
            receiver: { id: driver.id },
            device: {
              id: driverDevice.deviceId
            },
            eventEntityType: RECIPIENT_TYPE.DRIVER,
            eventEntityId: driver.id
          }));
        })
        .reduce((a, c) => a.concat(c), []),
      children: branch.interactiveDrivers.map(driver => ({
        title: driverTitleFunc(driver.name),
        value: `${branch.id}-${driver.id}`,
        treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.DRIVER,
        treeNodeEntityId: driver.id,
        treeNodeEntityName: driver.name,
        rawData: driver,
        checkedValue: driver.interactiveDevices
          .map(driverDevice => ({
            ...recipientExtra,
            receiver: { id: driver.id },
            device: {
              id: driverDevice.deviceId
            },
            eventEntityType: RECIPIENT_TYPE.DRIVER,
            eventEntityId: driver.id
          }))
          .reduce((a, c) => a.concat(c), [])
      }))
    })),
    disableCheckbox: !branchList.length
  };
};

const getFleetList = (t, fleets) =>
  fleets
    .map(fleet => {
      const isNoFleet = !fleet.id || !fleet.name;
      const interactiveVehicles = fleet.vehicles
        .filter(
          vehicle =>
            vehicle.name &&
            vehicle.id &&
            vehicle.devices &&
            vehicle.devices?.some(isMessagingDevice)
        )
        .map(vehicle => ({
          ...vehicle,
          devices: vehicle.devices.filter(isMessagingDevice)
        }))
        .filter(v => v.devices.length > 0);
      const interactiveStandaloneDevices = uniqById([
        ...(fleet.devices || []).filter(isMessagingDevice),
        ...fleet.vehicles
          .filter(vehicle => (!vehicle.name || !vehicle.id) && vehicle.devices)
          .map(noVehicle => noVehicle.devices)
          .filter(d => d && d.length)
          .reduce((a, c) => a.concat(c), [])
          .filter(isMessagingDevice)
      ]);
      const ret = {
        name: isNoFleet ? t('Messaging.NoFleet') : fleet.name,
        id: isNoFleet ? -1 : fleet.id,
        interactiveVehicles,
        interactiveStandaloneDevices
      };
      return ret && ret.interactiveVehicles.length
        ? ret
        : ret && ret.interactiveStandaloneDevices.length
        ? ret
        : null;
    })
    .filter(item => item !== null);

const getBranchList = (t, branches, drivers, devicesStats) => {
  const interactiveDevices = devicesStats.filter(deviceStat => deviceStat.currentUser);
  return branches
    .map(branch => {
      const isNoBranch =
        !branch.name || !branch.id || (branch.name === 'No Branch' && branch.id === -1);
      const ret = {
        name: isNoBranch ? t('Messaging.NoBranch') : branch.name,
        id: isNoBranch ? -1 : branch.id,
        interactiveDrivers: drivers
          .filter(driver => {
            const inBranch = isNoBranch
              ? !driver.location
              : driver.location && driver.location.id === branch.id;
            if (inBranch) {
              return (
                interactiveDevices.filter(device => device.currentUser?.id === driver.id).length > 0
              );
            }
            return false;
          })
          .map(driver => ({
            ...driver,
            name: `${driver.firstName} ${driver.lastName}`,
            interactiveDevices: interactiveDevices.filter(
              device => device.currentUser?.id === driver.id
            )
          }))
      };
      return ret.interactiveDrivers.length ? ret : null;
    })
    .filter(item => item !== null);
};

export const buildRecipientTree = (
  t,
  branches,
  drivers,
  fleets,
  devicesStats,
  vehicleTitleFunc,
  deviceTitleFunc,
  driverTitleFunc,
  recipientExtra
) => {
  vehicleTitleFunc = vehicleTitleFunc || (vehicleName => vehicleName);
  deviceTitleFunc = deviceTitleFunc || (deviceName => deviceName);
  driverTitleFunc = driverTitleFunc || (driverName => driverName);
  recipientExtra = recipientExtra || {};
  return [
    getBranchTree(t, branches, drivers, devicesStats, driverTitleFunc, recipientExtra),
    getFleetTree(t, fleets, vehicleTitleFunc, deviceTitleFunc, recipientExtra)
  ];
};

export const todayDateRange = {
  from: moment()
    .startOf('day')
    .format(STORE_DATE_FORMAT),
  to: moment()
    .endOf('day')
    .format(STORE_DATE_FORMAT)
};

export const getMessageDisplayDate = (t, msgLastUpdatedDateObj, dateTimeFormat) => {
  const { momentDate, isoStr } = msgLastUpdatedDateObj;
  return format(new Date(isoStr), dateTimeFormat);
};
export const isRouteToMessage = message => message.type?.id === 3;
const getMessageNotificationType = message =>
  isRouteToMessage(message)
    ? MESSAGE_NOTIFICATION_TYPE.ROUTE_TO
    : message.type?.id === 1
    ? MESSAGE_NOTIFICATION_TYPE.ALERT
    : MESSAGE_NOTIFICATION_TYPE.NORMAL;
//Message sent from NextGen, message event's status is either 'Success' or 'Failed'
//Message sent from TN360, message event either doesn't have status or status D stands for deleted, messages failed to be sent won't return from API
const isFailedEvt = evt => evt.status === NextGen_Message_Event_Status.failed;
export const chatEntityMessageSentFailed = (chatEntity, chatEntityMessage) => {
  return chatEntityMessage.recipients?.find(
    recipient =>
      recipient.chatEntityType === chatEntity.chatEntityType &&
      recipient.chatEntityName === chatEntity.chatEntityName &&
      recipient.chatEntityId === chatEntity.chatEntityId
  )?.chatMessage?.chatMessageEvt?.evtCreateFailed;
};
const isValidEvt = evt => evt.status !== 'D';
const isValidChatMsg = (chatMsg, chatEntity) => {
  const recipientRelated = (chatMsg.recipients || []).some(
    recipient =>
      recipient?.chatEntityType === chatEntity?.chatEntityType &&
      recipient?.chatEntityId === chatEntity?.chatEntityId &&
      recipient?.chatEntityName === chatEntity?.chatEntityName
  );
  const senderRelated =
    chatMsg.messageSender?.senderEntity === chatEntity?.chatEntityType &&
    chatMsg.messageSender?.senderId === chatEntity?.chatEntityId &&
    chatMsg?.messageSender?.senderName === chatEntity?.chatEntityName;
  const messageIdRelated =
    chatEntity.chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE &&
    chatEntity.chatEntityId === chatMsg.id;
  return recipientRelated || senderRelated || messageIdRelated;
};

const isValidMsg = message => {
  const msgFromDevice = !isRouteToMessage(message) && !!message.device;
  //if message send from tn360User, need has recipients
  if (!msgFromDevice) {
    return message.recipients && message.recipients.length;
  }
  return true;
};

export const chatMsgsSortFunc = (chatMsgA, chatMsgB) => {
  const sentUnixA =
    chatMsgA.messageStatus?.find(statusItem => statusItem.status === MESSAGE_STATUE.SENT)?.dateTime
      ?.unix || 0;
  const sentUnixB =
    chatMsgB.messageStatus?.find(statusItem => statusItem.status === MESSAGE_STATUE.SENT)?.dateTime
      ?.unix || 0;

  if (sentUnixA === sentUnixB) {
    const recvUnixA =
      chatMsgA.messageStatus?.find(statusItem => statusItem.status === MESSAGE_STATUE.RECEIVED)
        ?.dateTime?.unix || 0;
    const recvUnixB =
      chatMsgB.messageStatus?.find(statusItem => statusItem.status === MESSAGE_STATUE.RECEIVED)
        ?.dateTime?.unix || 0;
    if (recvUnixA === recvUnixB) {
      const readUnixA =
        chatMsgA.messageStatus?.find(statusItem => statusItem.status === MESSAGE_STATUE.READ)
          ?.dateTime?.unix || 0;
      const readUnixB =
        chatMsgB.messageStatus?.find(statusItem => statusItem.status === MESSAGE_STATUE.READ)
          ?.dateTime?.unix || 0;
      if (readUnixA === readUnixB) {
        return chatMsgA.lastUpdatedDate?.unix - chatMsgB.lastUpdatedDate?.unix;
      }
      return readUnixA - readUnixB;
    }
    return recvUnixA - recvUnixB;
  }
  return sentUnixA - sentUnixB;
};
const isMultiRecipientsMessage = updatedMsg =>
  updatedMsg.recipients && updatedMsg.recipients.length > 1;
const getMsgWithChildren = (message, messageList) => {
  let childrenMsgs = messageList.filter(
    messageListItem =>
      messageListItem.parent?.id === message.id && (messageListItem.events || []).length
  );
  if (childrenMsgs && childrenMsgs.length) {
    childrenMsgs = childrenMsgs.map(msg => getMsgWithChildren(msg, messageList));
  }
  return {
    ...message,
    ...messageList.find(messageListItem => messageListItem.id === message.id),
    childrenMsgs: childrenMsgs.filter(isValidMsg)
  };
};

const getMsgLastUpdatedDate = message => ({
  dateObj: moment(message.updatedAt ? message.updatedAt : message.createdAt).toDate(),
  unix: moment(message.updatedAt ? message.updatedAt : message.createdAt).unix(),
  isoStr: message.updatedAt ? message.updatedAt : message.createdAt,
  momentDate: moment(message.updatedAt ? message.updatedAt : message.createdAt)
});

const getDeviceFromAccessableFleets = (deviceId, accessableFleets) => {
  let ret = {};
  (accessableFleets || []).some(fleet =>
    [
      ...(fleet.devices || []),
      ...(fleet.vehicles || [])
        .filter(fV => fV.devices)
        .map(f => f.devices)
        .reduce((a, c) => a.concat(c), [])
    ].find(fD => {
      if (parseInt(fD.id) === parseInt(deviceId)) {
        ret = fD;
        return true;
      }
      return false;
    })
  );
  return ret;
};

const evtCreateTypes = [MESSAGE_TYPE['MSG-NEW'], MESSAGE_TYPE['MSG-REPLY']];
export const getChatMessageDriverName = (chatMessage, chatEntity) => {
  const event = (chatMessage?.events || []).find(evt =>
    evtCreateTypes.some(type => type === evt.eventType)
  );
  if (
    chatEntity.chatEntityType === RECIPIENT_TYPE.VEHICLE &&
    (event?.vehicle?.id === chatEntity.chatEntityId ||
      chatEntity?.[VEHICLE_DYNAMIC_LINKED_DEVICE]?.id === event?.device?.id)
  ) {
    return event?.receiver && `${event.receiver.firstName} ${event.receiver.lastName}`;
  } else if (
    chatEntity.chatEntityType === RECIPIENT_TYPE.DEVICE &&
    (event?.device?.id === chatEntity.chatEntityId ||
      chatEntity?.[DEVICE_DYNAMIC_LINKED_VEHICLE]?.id === event?.vehicle?.id)
  ) {
    return event?.receiver && `${event.receiver.firstName} ${event.receiver.lastName}`;
  }
};

//Process MirroringRecipient/ChatEntity - MsgEvent sent from/to driver also appears in vehicle/device chatHistories if it contains vehicle/device info
const adjustChatMessage = (chatMessage, chatEntity) => ({
  ...chatMessage,
  messageSender:
    chatMessage?.messageSender?.mirroingMessageSender &&
    chatMessage.messageSender.mirroingMessageSender.senderEntity === chatEntity.chatEntityType &&
    chatMessage.messageSender.mirroingMessageSender.senderName === chatEntity.chatEntityName &&
    chatMessage.messageSender.mirroingMessageSender.senderId === chatEntity.chatEntityId
      ? chatMessage.messageSender.mirroingMessageSender
      : chatMessage?.messageSender,
  recipients: (chatMessage?.recipients || []).map(recipient => {
    const shouldUseMirrorRecipient =
      recipient.mirroringRecipient &&
      recipient.mirroringRecipient.chatEntityType === chatEntity.chatEntityType &&
      recipient.mirroringRecipient.chatEntityName === chatEntity.chatEntityName &&
      recipient.mirroringRecipient.chatEntityId === chatEntity.chatEntityId;
    return shouldUseMirrorRecipient ? recipient.mirroringRecipient : recipient;
  })
});

export const categoryMessagesByChatEnitity = (messages, accessableDrivers, accessableFleets) => {
  const isAccessableDriver = driver => (accessableDrivers || []).some(d => d.id === driver.id);
  const isAccessableDevice = device =>
    (accessableFleets || []).some(fleet =>
      [
        ...(fleet.devices || []),
        ...(fleet.vehicles || [])
          .filter(fV => fV.devices)
          .map(f => f.devices)
          .reduce((a, c) => a.concat(c), [])
      ].some(fD => fD.id === device.id)
    );
  const isAccessableVehicle = vehicle =>
    (accessableFleets || []).some(fleet =>
      [...(fleet.vehicles || []).filter(fV => !!fV.id).reduce((a, c) => a.concat(c), [])].some(
        fV => fV.id === vehicle.id
      )
    );
  let allChatEntities = [];
  const addChatEntity = entity => {
    const existingChatEntity = allChatEntities.find(
      item =>
        item.chatEntityType === entity.chatEntityType &&
        item.chatEntityName === entity.chatEntityName &&
        item.chatEntityId === entity.chatEntityId
    );
    if (existingChatEntity) {
      if (existingChatEntity.chatMessages.every(msg => msg.id !== entity.chatMessage.id)) {
        existingChatEntity.chatMessages.push(entity.chatMessage);
      }
      if (
        entity.chatEntityType === RECIPIENT_TYPE.DEVICE &&
        entity[DEVICE_DYNAMIC_LINKED_VEHICLE]
      ) {
        if (existingChatEntity.chatEntityType === RECIPIENT_TYPE.VEHICLE) {
          existingChatEntity[VEHICLE_DYNAMIC_LINKED_DEVICE] = entity;
        } else if (existingChatEntity.chatEntityType === RECIPIENT_TYPE.DEVICE) {
          existingChatEntity[DEVICE_DYNAMIC_LINKED_VEHICLE] = entity[DEVICE_DYNAMIC_LINKED_VEHICLE];
        }
      } else if (
        entity.chatEntityType === RECIPIENT_TYPE.VEHICLE &&
        entity[VEHICLE_DYNAMIC_LINKED_DEVICE]
      ) {
        if (existingChatEntity.chatEntityType === RECIPIENT_TYPE.VEHICLE) {
          existingChatEntity[VEHICLE_DYNAMIC_LINKED_DEVICE] = entity[VEHICLE_DYNAMIC_LINKED_DEVICE];
        } else if (existingChatEntity.chatEntityType === RECIPIENT_TYPE.DEVICE) {
          existingChatEntity[DEVICE_DYNAMIC_LINKED_VEHICLE] = entity;
        }
      }
    } else {
      const _chatEntity = {
        chatEntityType: entity.chatEntityType,
        chatEntityName: entity.chatEntityName,
        chatEntityId: entity.chatEntityId,
        chatMessages: [entity.chatMessage]
      };
      if (entity[DEVICE_DYNAMIC_LINKED_VEHICLE]) {
        _chatEntity[DEVICE_DYNAMIC_LINKED_VEHICLE] = entity[DEVICE_DYNAMIC_LINKED_VEHICLE];
      }
      if (entity[VEHICLE_DYNAMIC_LINKED_DEVICE]) {
        _chatEntity[VEHICLE_DYNAMIC_LINKED_DEVICE] = entity[VEHICLE_DYNAMIC_LINKED_DEVICE];
      }
      allChatEntities.push(_chatEntity);
    }
  };
  const updatedMsgs = messages.map(message => {
    const msgFromDevice = !isRouteToMessage(message) && !!message.device;
    const recipients = [],
      //No matter sent by device or TN360 user, createdAt always be created
      msgCreatAt = {
        unix: message.createdAt ? moment(message.createdAt).unix() : null,
        isoStr: message.createdAt
      },
      //If sent by device, which means TN360 user need read, check readAt on message
      //else sent by TN360 user, need find MSG-READ evtType in evts which inserted by device
      msgReadAt = {
        unix: msgFromDevice ? (message.readAt ? moment(message.readAt).unix() : null) : null,
        isoStr: msgFromDevice ? message.readAt : null
      },
      msgReceivedAt = {
        unix: null,
        isoStr: null
      },
      messageStatus = [];
    const addRecipient = rec => {
      if (
        recipients.some(
          item =>
            item.chatEntityType === rec.chatEntityType &&
            item.chatEntityName === rec.chatEntityName &&
            item.chatEntityId === rec.chatEntityId
        )
      ) {
        return;
      } else {
        recipients.push(rec);
      }
    };
    const processVehicleChatEntity = (
      message,
      evt,
      isMirror = false,
      dynamicalLinkedDevice = null
    ) => {
      const vehicleChatEntity = {
        chatEntityType: RECIPIENT_TYPE.VEHICLE,
        chatEntityName: evt.vehicle.name,
        chatEntityId: evt.vehicle.id,
        chatMessage: {
          ...message,
          chatMessageEvt: { ...evt, evtCreateFailed: isFailedEvt(evt) }
        },
        ...(dynamicalLinkedDevice
          ? { [VEHICLE_DYNAMIC_LINKED_DEVICE]: dynamicalLinkedDevice }
          : null)
      };
      if (
        isAccessableDevice({ id: evt.device.id }) ||
        isAccessableVehicle({ id: evt.vehicle.id })
      ) {
        if (!isMirror) {
          const senderIsEvtCreator = message.device && evt.device.id === message.device.id;
          //RouteTo message's device doesn't stand for a device-sender
          if (isRouteToMessage(message)) {
            addRecipient(vehicleChatEntity);
          } else if (!senderIsEvtCreator) {
            addRecipient(vehicleChatEntity);
          }
        }
        addChatEntity(vehicleChatEntity);
        return vehicleChatEntity;
      }
    };
    const processDeviceChatEntity = (message, evt, isMirror = false) => {
      const deviceFromFleets = getDeviceFromAccessableFleets(evt.device.id, accessableFleets);
      if (deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE]) {
        const vehicle = deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE];
        return processVehicleChatEntity(message, { ...evt, vehicle }, isMirror, deviceFromFleets);
      }
      const deviceChatEntity = {
        chatEntityType: RECIPIENT_TYPE.DEVICE,
        chatEntityName: evt.device.name,
        chatEntityId: evt.device.id,
        chatMessage: {
          ...message,
          chatMessageEvt: { ...evt, evtCreateFailed: isFailedEvt(evt) }
        },
        ...(deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE]
          ? {
              [DEVICE_DYNAMIC_LINKED_VEHICLE]: deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE]
            }
          : {})
      };
      if (isAccessableDevice({ id: evt.device.id })) {
        if (!isMirror) {
          const senderIsEvtCreator = message.device && evt.device.id === message.device.id;
          //RouteTo message's device doesn't stand for a device-sender
          if (isRouteToMessage(message)) {
            addRecipient(deviceChatEntity);
          } else if (!senderIsEvtCreator) {
            addRecipient(deviceChatEntity);
          }
        }
        addChatEntity(deviceChatEntity);
        return deviceChatEntity;
      }
    };
    (message.events || []).filter(isValidEvt).forEach(evt => {
      if (evtCreateTypes.some(type => type === evt.eventType)) {
        //createdAt is the time Message saved to DB, messageEventAt is the actual createTime
        msgCreatAt.unix = moment(evt.eventAt).unix() || msgCreatAt.unix;
        msgCreatAt.isoStr = moment(evt.eventAt).toISOString() || msgCreatAt.isoStr;
      } else if (evt.eventType === MESSAGE_TYPE['MSG-READ'] && !msgFromDevice) {
        msgReadAt.unix = moment(evt.eventAt).unix();
        msgReadAt.isoStr = moment(evt.eventAt).toISOString();
      } else if (evt.eventType === MESSAGE_TYPE['MSG-RECV']) {
        msgReceivedAt.unix = moment(evt.eventAt).unix();
        msgReceivedAt.isoStr = moment(evt.eventAt).toISOString();
      }

      //Distinguish here! chatEntity(2 ways) and recipient(should not be sender)
      //Add recipient only when evt are createType
      if (evtCreateTypes.some(type => type === evt.eventType)) {
        if (evt.receiver) {
          const driverChatEntity = {
            chatEntityType: RECIPIENT_TYPE.DRIVER,
            chatEntityName: `${evt.receiver.firstName} ${evt.receiver.lastName}`,
            chatEntityId: evt.receiver.id,
            chatMessage: {
              ...message,
              chatMessageEvt: { ...evt, evtCreateFailed: isFailedEvt(evt) }
            }
          };
          if (isAccessableDriver({ id: evt.receiver.id })) {
            let mirroringRecipient = null;
            //Mirroring message sent to driver as vehicle/device chatEntity instead being regarded as GroupEntity/multiRecipients
            if (evt.vehicle) {
              mirroringRecipient = processVehicleChatEntity(message, evt, true);
            } else if (evt.device) {
              mirroringRecipient = processDeviceChatEntity(message, evt, true);
            }
            const receiverIsSender = message.sender && message.sender.id === evt.receiver.id;
            if (!receiverIsSender) {
              addRecipient({ ...driverChatEntity, mirroringRecipient });
            }
            addChatEntity(driverChatEntity);
          }
        } else if (evt.vehicle) {
          processVehicleChatEntity(message, evt);
        } else if (evt.device) {
          processDeviceChatEntity(message, evt);
        }
      }
    });

    messageStatus.push({
      status: MESSAGE_STATUE.SENT,
      dateTime: {
        momentDate: moment.unix(msgCreatAt.unix),
        ...msgCreatAt
      }
    });
    if (msgReceivedAt.unix) {
      messageStatus.push({
        status: MESSAGE_STATUE.RECEIVED,
        dateTime: {
          momentDate: moment.unix(msgReceivedAt.unix),
          ...msgReceivedAt
        }
      });
    }
    if (msgReadAt.unix) {
      messageStatus.push({
        status: MESSAGE_STATUE.READ,
        dateTime: {
          momentDate: moment.unix(msgReadAt.unix),
          ...msgReadAt
        }
      });
    }
    const messageSender = {
      senderName: '',
      senderEntity: null,
      senderId: null,
      senderType:
        !!message.sender?.id && !msgFromDevice
          ? MESSAGE_SENDER_TYPE.WEB_DISPATCHER
          : MESSAGE_SENDER_TYPE.OTHER
    };
    if (message.sender) {
      messageSender.senderName = `${message.sender.firstName} ${message.sender.lastName}`;
      messageSender.senderId = message.sender.id;
      if (msgFromDevice) {
        messageSender.senderType = MESSAGE_SENDER_TYPE.OTHER;
        messageSender.senderEntity = RECIPIENT_TYPE.DRIVER;
        //Mirroring message sent from driver as vehicle/device sender
        const event = message.events.find(
          event =>
            event.device &&
            event.device.id === message.device.id &&
            evtCreateTypes.some(type => type === event.eventType)
        );
        if (event?.vehicle) {
          const mirroingMessageSender = {
            senderName: event.vehicle.name,
            senderEntity: RECIPIENT_TYPE.VEHICLE,
            senderId: event.vehicle.id
          };
          messageSender.mirroingMessageSender = mirroingMessageSender;
        } else if (event?.device) {
          const deviceFromFleets = getDeviceFromAccessableFleets(event.device.id, accessableFleets);
          if (deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE]) {
            const vehicle = deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE];
            messageSender.mirroingMessageSender = {
              senderName: vehicle.name,
              senderEntity: RECIPIENT_TYPE.VEHICLE,
              senderId: vehicle.id
            };
          } else {
            const mirroingMessageSender = {
              senderName: event.device.name,
              senderEntity: RECIPIENT_TYPE.DEVICE,
              senderId: event.device.id
            };
            messageSender.mirroingMessageSender = mirroingMessageSender;
          }
        }
      }
    } else if (message.device) {
      const event = message.events.find(
        event => event.device && event.device.id === message.device.id
      );
      messageSender.senderType = MESSAGE_SENDER_TYPE.OTHER;
      if (event) {
        if (event.vehicle) {
          messageSender.senderName = event.vehicle.name;
          messageSender.senderEntity = RECIPIENT_TYPE.VEHICLE;
          messageSender.senderId = event.vehicle.id;
        } else if (event.device) {
          messageSender.senderName = event.device.name;
          messageSender.senderEntity = RECIPIENT_TYPE.DEVICE;
          messageSender.senderId = event.device.id;
        }
      }
    }

    return {
      ...message,
      messageStatus,
      messageSender,
      lastUpdatedDate: getMsgLastUpdatedDate(message),
      recipients,
      notificationType: getMessageNotificationType(message)
    };
  });

  //update chatMessages
  allChatEntities = allChatEntities.map(entity => {
    let updatedChatMessages = entity.chatMessages
      .map(msg => getMsgWithChildren(msg, updatedMsgs))
      .sort(chatMsgsSortFunc)
      .map(msg => adjustChatMessage(msg, entity));
    //At this time, updatedMsg has it's recipients list and sender, Need to filter deleted message which not related to the chatEntity anymore
    updatedChatMessages = updatedChatMessages.filter(chatMsg => isValidChatMsg(chatMsg, entity));
    return {
      ...entity,
      chatMessages: updatedChatMessages
    };
  });

  //get GroupMessage
  const groupChatEntities = updatedMsgs.filter(isMultiRecipientsMessage).map(groupMessage => ({
    chatEntityId: groupMessage.id,
    chatEntityName: groupMessage.recipients
      .map(recipient => recipient.chatEntityName)
      .join(ENTITY_DIVIDER),
    chatEntityType: RECIPIENT_TYPE.GROUP_MESSAGE,
    singleChatEntities: allChatEntities.filter(chatEntity =>
      chatEntity.chatMessages
        ?.filter(chatMsg => isValidChatMsg(chatMsg, chatEntity))
        .some(validChatMsg => validChatMsg.id === groupMessage.id)
    ),
    chatMessages: [getMsgWithChildren(groupMessage, updatedMsgs)],
    flattenChatMessages: flattenChildrenMsgsOfGroupMessageEntity([
      getMsgWithChildren(groupMessage, updatedMsgs)
    ]),
    lastChatMsg: getMsgWithChildren(groupMessage, updatedMsgs)
  }));

  const singleChatEntities = allChatEntities
    .map(entity => {
      const updatedChatMessages = entity.chatMessages.filter(
        chatMsg => !isMultiRecipientsMessage(chatMsg)
      );
      const lastChatMsg = updatedChatMessages[updatedChatMessages.length - 1];
      return {
        ...entity,
        chatMessages: updatedChatMessages,
        lastChatMsg,
        flattenChatMessages: updatedChatMessages
      };
    })
    .filter(chatEntity => chatEntity.lastChatMsg);

  return [...singleChatEntities, ...groupChatEntities];
};

export const feedMessages = (messages, chatEntity, accessableFleets) => {
  const updatedMsgs = messages.map(message => {
    const msgFromDevice = !isRouteToMessage(message) && !!message.device;
    const recipients = [],
      //No matter sent by device or TN360 user, createdAt always be created
      msgCreatAt = {
        unix: message.createdAt ? moment(message.createdAt).unix() : null,
        isoStr: message.createdAt
      },
      //If sent by device, which means TN360 user need read, check readAt on message
      //else sent by TN360 user, need find MSG-READ evtType in evts which inserted by device
      msgReadAt = {
        unix: msgFromDevice ? (message.readAt ? moment(message.readAt).unix() : null) : null,
        isoStr: msgFromDevice ? message.readAt : null
      },
      msgReceivedAt = {
        unix: null,
        isoStr: null
      },
      messageStatus = [];
    const addRecipient = rec => {
      if (
        recipients.some(
          item =>
            item.chatEntityType === rec.chatEntityType &&
            item.chatEntityName === rec.chatEntityName &&
            item.chatEntityId === rec.chatEntityId
        )
      ) {
        return;
      } else {
        recipients.push(rec);
      }
    };
    const processVehicleChatEntity = (
      message,
      evt,
      isMirror = false,
      dynamicalLinkedDevice = null
    ) => {
      const vehicleChatEntity = {
        chatEntityType: RECIPIENT_TYPE.VEHICLE,
        chatEntityName: evt.vehicle.name,
        chatEntityId: evt.vehicle.id,
        chatMessage: {
          ...message,
          chatMessageEvt: { ...evt, evtCreateFailed: isFailedEvt(evt) }
        }
      };
      if (!isMirror) {
        const senderIsEvtCreator = message.device && evt.device.id === message.device.id;
        //RouteTo message's device doesn't stand for a device-sender
        if (isRouteToMessage(message)) {
          addRecipient(vehicleChatEntity);
        } else if (!senderIsEvtCreator) {
          addRecipient(vehicleChatEntity);
        }
      }
      return vehicleChatEntity;
    };
    const processDeviceChatEntity = (message, evt, isMirror = false) => {
      const deviceFromFleets = getDeviceFromAccessableFleets(evt.device.id, accessableFleets);
      if (deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE]) {
        const vehicle = deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE];
        return processVehicleChatEntity(message, { ...evt, vehicle }, isMirror, deviceFromFleets);
      }
      const deviceChatEntity = {
        chatEntityType: RECIPIENT_TYPE.DEVICE,
        chatEntityName: evt.device.name,
        chatEntityId: evt.device.id,
        chatMessage: {
          ...message,
          chatMessageEvt: { ...evt, evtCreateFailed: isFailedEvt(evt) }
        },
        ...(deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE]
          ? {
              [DEVICE_DYNAMIC_LINKED_VEHICLE]: deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE]
            }
          : {})
      };
      if (!isMirror) {
        const senderIsEvtCreator = message.device && evt.device.id === message.device.id;
        //RouteTo message's device doesn't stand for a device-sender
        if (isRouteToMessage(message)) {
          addRecipient(deviceChatEntity);
        } else if (!senderIsEvtCreator) {
          addRecipient(deviceChatEntity);
        }
      }
      return deviceChatEntity;
    };
    (message.events || []).filter(isValidEvt).forEach(evt => {
      if (evtCreateTypes.some(type => type === evt.eventType)) {
        msgCreatAt.unix = moment(evt.eventAt).unix() || msgCreatAt.unix;
        msgCreatAt.isoStr = moment(evt.eventAt).toISOString() || msgCreatAt.isoStr;
      } else if (evt.eventType === MESSAGE_TYPE['MSG-READ'] && !msgFromDevice) {
        msgReadAt.unix = moment(evt.eventAt).unix();
        msgReadAt.isoStr = moment(evt.eventAt).toISOString();
      } else if (evt.eventType === MESSAGE_TYPE['MSG-RECV']) {
        msgReceivedAt.unix = moment(evt.eventAt).unix();
        msgReceivedAt.isoStr = moment(evt.eventAt).toISOString();
      }

      //Distinguish here! chatEntity(2 ways) and recipient(should not be sender)
      //Add recipient only when evt are createType
      if (evtCreateTypes.some(type => type === evt.eventType)) {
        if (evt.receiver) {
          const driverChatEntity = {
            chatEntityType: RECIPIENT_TYPE.DRIVER,
            chatEntityName: `${evt.receiver.firstName} ${evt.receiver.lastName}`,
            chatEntityId: evt.receiver.id,
            chatMessage: {
              ...message,
              chatMessageEvt: { ...evt, evtCreateFailed: isFailedEvt(evt) }
            }
          };
          const receiverIsSender = message.sender && message.sender.id === evt.receiver.id;
          if (!receiverIsSender) {
            let mirroringRecipient = null;
            //Mirroring message sent from/to driver to vehicle/device chatEntity instead being regarded as GroupEntity/multiRecipients
            if (evt.vehicle) {
              mirroringRecipient = processVehicleChatEntity(message, evt, true);
            } else if (evt.device) {
              mirroringRecipient = processDeviceChatEntity(message, evt, true);
            }
            addRecipient({ ...driverChatEntity, mirroringRecipient });
          }
        } else if (evt.vehicle) {
          processVehicleChatEntity(message, evt);
        } else if (evt.device) {
          processDeviceChatEntity(message, evt);
        }
      }
    });

    messageStatus.push({
      status: MESSAGE_STATUE.SENT,
      dateTime: {
        momentDate: moment.unix(msgCreatAt.unix),
        ...msgCreatAt
      }
    });
    if (msgReceivedAt.unix) {
      messageStatus.push({
        status: MESSAGE_STATUE.RECEIVED,
        dateTime: {
          momentDate: moment.unix(msgReceivedAt.unix),
          ...msgReceivedAt
        }
      });
    }
    if (msgReadAt.unix) {
      messageStatus.push({
        status: MESSAGE_STATUE.READ,
        dateTime: {
          momentDate: moment.unix(msgReadAt.unix),
          ...msgReadAt
        }
      });
    }
    const messageSender = {
      senderName: '',
      senderEntity: null,
      senderId: null,
      senderType:
        !!message.sender?.id && !msgFromDevice
          ? MESSAGE_SENDER_TYPE.WEB_DISPATCHER
          : MESSAGE_SENDER_TYPE.OTHER
    };
    if (message.sender) {
      messageSender.senderName = `${message.sender.firstName} ${message.sender.lastName}`;
      messageSender.senderId = message.sender.id;
      if (msgFromDevice) {
        messageSender.senderType = MESSAGE_SENDER_TYPE.OTHER;
        messageSender.senderEntity = RECIPIENT_TYPE.DRIVER;
        //Mirroring message sent from driver as vehicle/device sender
        const event = message.events.find(
          event =>
            event.device &&
            event.device.id === message.device.id &&
            evtCreateTypes.some(type => type === event.eventType)
        );
        if (event?.vehicle) {
          const mirroingMessageSender = {
            senderName: event.vehicle.name,
            senderEntity: RECIPIENT_TYPE.VEHICLE,
            senderId: event.vehicle.id,
            senderType: MESSAGE_SENDER_TYPE.OTHER
          };
          messageSender.mirroingMessageSender = mirroingMessageSender;
        } else if (event?.device) {
          const deviceFromFleets = getDeviceFromAccessableFleets(event.device.id, accessableFleets);
          if (deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE]) {
            const vehicle = deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE];
            messageSender.mirroingMessageSender = {
              senderName: vehicle.name,
              senderEntity: RECIPIENT_TYPE.VEHICLE,
              senderId: vehicle.id
            };
          } else {
            const mirroingMessageSender = {
              senderName: event.device.name,
              senderEntity: RECIPIENT_TYPE.DEVICE,
              senderId: event.device.id,
              senderType: MESSAGE_SENDER_TYPE.OTHER
            };
            messageSender.mirroingMessageSender = mirroingMessageSender;
          }
        }
      }
    } else if (message.device) {
      const event = message.events.find(
        event => event.device && event.device.id === message.device.id
      );
      if (event) {
        if (event.vehicle) {
          messageSender.senderName = event.vehicle.name;
          messageSender.senderEntity = RECIPIENT_TYPE.VEHICLE;
          messageSender.senderId = event.vehicle.id;
        } else if (event.device) {
          messageSender.senderName = event.device.name;
          messageSender.senderEntity = RECIPIENT_TYPE.DEVICE;
          messageSender.senderId = event.device.id;
        }
      }
    }

    return {
      ...message,
      messageStatus,
      messageSender,
      lastUpdatedDate: getMsgLastUpdatedDate(message),
      recipients,
      notificationType: getMessageNotificationType(message)
    };
  });
  //add parent/children
  let updatedChatMessages = updatedMsgs
    .map(chatMsg => getMsgWithChildren(chatMsg, updatedMsgs))
    .sort(chatMsgsSortFunc)
    .map(msg => adjustChatMessage(msg, chatEntity));
  //At this time, updatedMsg has it's recipients list and sender, Need to filter delected message which not related to the chatEntity anymore
  return updatedChatMessages.filter(chatMsg => isValidChatMsg(chatMsg, chatEntity));
};

export const shortName = name => {
  if (name) {
    if (name.split(/\s+/).length < 2) {
      return name.substr(0, 2);
    }
    return name
      .split(/\s+/)
      .map(str => str[0])
      .filter(str => !!str)
      .map(str => str.toUpperCase())
      .join('')
      .substr(0, 2);
  }
  return '';
};

export const messageCanMarkAsRead = message => {
  //Can be read byTN360 user : 1. sent by device(routeTo message's device doesn't mean it's from device); 2. no readAt property
  return !isRouteToMessage(message) && !!message.id && !message.readAt && message.device;
};

export const getCurrentUserMessagingPermissions = userPermissions => {
  return {
    [MESSAGE_PERMISSION_ENTITY_TYPE.Message]: {
      delete: userPermissions.some(permission => permission === entities.MESSAGE_DESTROY), //false will disable delete button on chat box
      send: userPermissions.some(permission => permission === entities.MESSAGE_CREATE),
      //false will 1.disable new-message modal in landing page 2.disable send button on chat form 3. disable reply option on chatBubble
      viewList:
        userPermissions.some(permission => permission === entities.MESSAGE) &&
        userPermissions.some(permission => permission === entities.MESSAGE_VIEW)
    },
    [MESSAGE_PERMISSION_ENTITY_TYPE.MessageTemplate]: {
      delete: userPermissions.some(permission => permission === entities.MESSAGETEMPLATE_DESTROY), //false will disable delete button on template action column
      viewList:
        userPermissions.some(permission => permission === entities.MESSAGETEMPLATE) &&
        userPermissions.some(permission => permission === entities.MESSAGETEMPLATE_VIEW), //false will disale message-template-option on dropdown in landing page
      create: userPermissions.some(permission => permission === entities.MESSAGETEMPLATE_CREATE), //false will disable create template modal on template page
      modify: userPermissions.some(permission => permission === entities.MESSAGETEMPLATE_UPDATE) //false will disable edit button on template action column
    }
  };
};

export const getMessageSubject = (t, message) => {
  let messageSubject = message.subject;
  let isRouteTo = isRouteToMessage(message);
  if (isRouteTo) {
    let body = null;
    try {
      body = JSON.parse(message.body);
    } catch (error) {
      body = null;
    }
    if (body) {
      messageSubject = `${t('Tracking.RouteTo')} : ${body.routeToAddress || ''}`;
    }
  }
  return messageSubject;
};

export const getMessageBody = message => {
  let messageBody = message.body;
  let isRouteTo = isRouteToMessage(message);
  if (isRouteTo) {
    let body = null;
    try {
      body = JSON.parse(message.body);
    } catch (error) {
      body = null;
    }
    if (body) {
      messageBody = body.message || '';
    }
  }
  return messageBody;
};
export const findTreeNode = (nodeCheckedValue, nodeType, treeNode) => {
  if (!treeNode) {
    return;
  }
  if (
    treeNode.checkedValue.every(
      checkedValueItem =>
        checkedValueItem.eventEntityType === nodeType &&
        checkedValueItem.eventEntityId === nodeCheckedValue
    )
  ) {
    return treeNode;
  }
  if (treeNode.children) {
    for (const child of treeNode.children) {
      if (findTreeNode(nodeCheckedValue, nodeType, child)) {
        return findTreeNode(nodeCheckedValue, nodeType, child);
      }
      continue;
    }
  }
  return null;
};

export const findTreeNodeCheckedValue = (nodeValue, treeNode) => {
  if (treeNode.value === nodeValue) {
    return treeNode.checkedValue;
  }
  if (treeNode.children) {
    for (const child of treeNode.children) {
      if (findTreeNodeCheckedValue(nodeValue, child)) {
        return findTreeNodeCheckedValue(nodeValue, child);
      }
      continue;
    }
  }
  return null;
};

export const mergedRecipientTreeNodes = (recipients, recipientTree) => {
  if (!recipientTree || recipientTree.length === 0) {
    return [];
  }
  const [branchTree, fleetTree] = recipientTree;
  const vDRecipients = recipients.filter(
      recipient =>
        recipient.chatEntityType === RECIPIENT_TYPE.VEHICLE ||
        recipient.chatEntityType === RECIPIENT_TYPE.DEVICE
    ),
    driverRecipients = recipients.filter(
      recipient => recipient.chatEntityType === RECIPIENT_TYPE.DRIVER
    );
  const fleetNodes = fleetTree.children || [];
  const branchNodes = branchTree.children || [];
  const topNodeChildMatched = (topNodeChild, chatRecipients) =>
    chatRecipients.some(
      chatRecipient =>
        chatRecipient.chatEntityType === topNodeChild.treeNodeEntityType &&
        chatRecipient.chatEntityId === topNodeChild.treeNodeEntityId
    );
  const getTreeNode = chatRecipient =>
    findTreeNode(
      chatRecipient.chatEntityId,
      chatRecipient.chatEntityType,
      chatRecipient.chatEntityType === RECIPIENT_TYPE.DRIVER ? branchTree : fleetTree
    );

  const matchedFleetNodes = fleetNodes.filter(fleetNode =>
    fleetNode.children.every(fleetVDChild => topNodeChildMatched(fleetVDChild, vDRecipients))
  );
  const matchedBranchNodes = branchNodes.filter(branchNode =>
    branchNode.children.every(branchDriverChild =>
      topNodeChildMatched(branchDriverChild, driverRecipients)
    )
  );
  const restVDRecipients = vDRecipients
    .filter(vDRecipient => {
      const vdNodes = matchedFleetNodes
        .map(matchedFleetNode => matchedFleetNode.children)
        .reduce((a, c) => a.concat(c), []);
      return (
        vdNodes?.length && vdNodes.every(vdNode => !topNodeChildMatched(vdNode, [vDRecipient]))
      );
    })
    .map(getTreeNode);
  const restDriverRecipients = driverRecipients
    .filter(driverRecipient => {
      const driverNodes = matchedBranchNodes
        .map(matchedBranchNode => matchedBranchNode.children)
        .reduce((a, c) => a.concat(c), []);
      return (
        driverNodes?.length &&
        driverNodes.every(driverNode => !topNodeChildMatched(driverNode, [driverRecipient]))
      );
    })
    .map(getTreeNode);

  return [
    ...matchedFleetNodes,
    ...matchedBranchNodes,
    ...restVDRecipients,
    ...restDriverRecipients
  ].filter(node => !!node);
};

export const flattenChildrenMsgsOfGroupMessageEntity = groupChatMessages => {
  let childrenMsgs = groupChatMessages
    .map(groupChatMessage => groupChatMessage.childrenMsgs)
    .filter(child => !!child)
    .reduce((a, c) => a.concat(c), []);
  if (childrenMsgs && childrenMsgs.length) {
    childrenMsgs = [...childrenMsgs, ...flattenChildrenMsgsOfGroupMessageEntity(childrenMsgs)];
  }
  const flattenMessages = [];
  [...groupChatMessages, ...childrenMsgs].forEach(msg => {
    if (flattenMessages.every(flattenMsg => flattenMsg.id !== msg.id)) {
      flattenMessages.push(msg);
    }
  });
  return flattenMessages.sort(chatMsgsSortFunc);
};

export const getWrappedChatEntity = chatEntity => {
  const dynamicLinkedVehicle =
    chatEntity.chatEntityType === RECIPIENT_TYPE.DEVICE &&
    chatEntity[DEVICE_DYNAMIC_LINKED_VEHICLE];
  return dynamicLinkedVehicle
    ? {
        ...chatEntity,
        chatEntityId: dynamicLinkedVehicle.id,
        chatEntityName: dynamicLinkedVehicle.name,
        chatEntityType: RECIPIENT_TYPE.VEHICLE
      }
    : chatEntity;
};

export const getMergedChatEntityName = (chatEntity, recipientTree, showRecipientCount) => {
  if (chatEntity.chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE) {
    const recipients = (chatEntity?.lastChatMsg?.recipients || []).map(getWrappedChatEntity);
    const rootNames = mergedRecipientTreeNodes(recipients, recipientTree);
    const recipientNames = !rootNames?.length
      ? recipients?.map(recipient => recipient.chatEntityName).join(ENTITY_DIVIDER)
      : rootNames.map(treeNode => treeNode.treeNodeEntityName).join(ENTITY_DIVIDER);
    return `${recipientNames || ''}${showRecipientCount ? '(' + recipients.length + ')' : ''}`;
  }
  return getWrappedChatEntity(chatEntity)?.chatEntityName;
};

export const buildChatEntityFilterTree = (
  t,
  filteredChatEntities,
  branches,
  drivers,
  fleets,
  vehicleTitleFunc = vehicleIconTitleFunc,
  deviceTitleFunc = deviceIconTitleFunc,
  driverTitleFunc = driverIconTitleFunc
) => {
  filteredChatEntities = filteredChatEntities || [];
  const driversChatEntities = [],
    vehiclesChatEntities = [],
    devicesChatEntities = [];
  const collectChatEntity = entity => {
    const existingChatEntity = (chatEntity, chatEntityList) =>
      chatEntityList.some(
        listItem =>
          listItem.chatEntityType === chatEntity.chatEntityType &&
          listItem.chatEntityName === chatEntity.chatEntityName &&
          listItem.chatEntityId === chatEntity.chatEntityId
      );

    if (entity.chatEntityType === RECIPIENT_TYPE.DRIVER) {
      !existingChatEntity(entity, driversChatEntities) && driversChatEntities.push(entity);
    } else if (entity.chatEntityType === RECIPIENT_TYPE.VEHICLE) {
      !existingChatEntity(entity, vehiclesChatEntities) && vehiclesChatEntities.push(entity);
    } else if (entity.chatEntityType === RECIPIENT_TYPE.DEVICE) {
      !existingChatEntity(entity, devicesChatEntities) && devicesChatEntities.push(entity);
    }
  };
  filteredChatEntities.forEach(entity => {
    if (entity.chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE) {
      entity.singleChatEntities.map(collectChatEntity);
    } else {
      collectChatEntity(entity);
    }
  });
  return [
    getBranchChatEntityFilterTree(t, branches, drivers, driversChatEntities, driverTitleFunc),
    getFleetChatEntityFilterTree(
      t,
      fleets,
      vehiclesChatEntities,
      devicesChatEntities,
      vehicleTitleFunc,
      deviceTitleFunc
    )
  ];
};

const getFleetChatEntityFilterTree = (
  t,
  fleets,
  vehiclesChatEntities,
  devicesChatEntities,
  vehicleTitleFunc,
  deviceTitleFunc,
  recipientExtra
) => {
  const fleetList = getFleetRecipientFilterList(
    t,
    fleets,
    vehiclesChatEntities,
    devicesChatEntities
  );
  vehicleTitleFunc = vehicleTitleFunc || (vehicleName => vehicleName);
  deviceTitleFunc = deviceTitleFunc || (deviceName => deviceName);
  recipientExtra = recipientExtra || {};
  return {
    title: t('Common.Fleets'),
    value: 'fleets',
    treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.ALL_FLEET,
    treeNodeEntityName: t('Common.AllFleets'),
    checkedValue: [
      ...fleetList
        .map(fleet => fleet.matchedVehicles)
        .reduce((a, c) => a.concat(c), [])
        .map(vehicle =>
          (vehicle.devices || [])
            .map(vD => ({
              ...recipientExtra,
              vehicle: {
                id: vehicle.id
              },
              device: vD
            }))
            .reduce((a, c) => a.concat(c), [])
        )
        .reduce((a, c) => a.concat(c), []),
      ...fleetList
        .map(fleet => fleet.matchedStandaloneDevices)
        .reduce((a, c) => a.concat(c), [])
        .map(device => ({
          ...recipientExtra,
          device: { id: device.id }
        }))
    ],
    children: fleetList.map(fleet => ({
      title: fleet.name,
      value: `fleet-${fleet.id}`,
      treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.FLEET,
      treeNodeEntityId: fleet.id,
      treeNodeEntityName: fleet.name,
      checkedValue: [
        ...fleet.matchedVehicles
          .map(vehicle =>
            (vehicle.devices || [])
              .map(vD => ({
                ...recipientExtra,
                vehicle: {
                  id: vehicle.id
                },
                device: vD
              }))
              .reduce((a, c) => a.concat(c), [])
          )
          .reduce((a, c) => a.concat(c), []),
        ...fleet.matchedStandaloneDevices.map(device => ({
          ...recipientExtra,
          device: { id: device.id }
        }))
      ],
      children: [
        ...fleet.matchedVehicles.map(vehicle => ({
          title: vehicleTitleFunc(vehicle.name),
          value: `${fleet.id}-${vehicle.id}`,
          treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.VEHICLE,
          treeNodeEntityId: vehicle.id,
          treeNodeEntityName: vehicle.name,
          rawData: { ...vehicle, recipientType: RECIPIENT_TYPE.VEHICLE },
          checkedValue: (vehicle.devices || []).map(vD => ({
            ...recipientExtra,
            vehicle: {
              id: vehicle.id
            },
            device: vD
          }))
        })),
        ...fleet.matchedStandaloneDevices.map(device => ({
          title: deviceTitleFunc(device.name),
          value: `${fleet.id}-${device.id}`,
          treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.DEVICE,
          treeNodeEntityId: device.id,
          treeNodeEntityName: device.name,
          rawData: { ...device, recipientType: RECIPIENT_TYPE.DEVICE },
          checkedValue: [
            {
              ...recipientExtra,
              device: { id: device.id }
            }
          ]
        }))
      ]
    })),
    disableCheckbox: !fleetList.length
  };
};

const getBranchChatEntityFilterTree = (
  t,
  branches,
  drivers,
  driversChatEntities,
  driverTitleFunc,
  recipientExtra
) => {
  const branchList = getBranchRecipientFilterList(t, branches, drivers, driversChatEntities);
  driverTitleFunc = driverTitleFunc || (driverName => driverName);
  recipientExtra = recipientExtra || {};
  return {
    title: t('Common.Branches'),
    value: 'branches',
    treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.ALL_BRANCH,
    treeNodeEntityName: t('Common.AllBranches'),
    checkedValue: branchList
      .map(branch => branch.matchedDrivers)
      .reduce((a, c) => a.concat(c), [])
      .map(driver => ({
        ...recipientExtra,
        receiver: { id: driver.id }
      })),
    children: branchList.map(branch => ({
      title: branch.name,
      value: `branch-${branch.id}`,
      treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.BRANCH,
      treeNodeEntityId: branch.id,
      treeNodeEntityName: branch.name,
      checkedValue: branch.matchedDrivers.map(driver => ({
        ...recipientExtra,
        receiver: { id: driver.id }
      })),
      children: branch.matchedDrivers.map(driver => ({
        title: driverTitleFunc(driver.name),
        value: `${branch.id}-${driver.id}`,
        treeNodeEntityType: RECIPIENT_TREE_NODE_TYPE.DRIVER,
        treeNodeEntityId: driver.id,
        treeNodeEntityName: driver.name,
        rawData: { ...driver, recipientType: RECIPIENT_TYPE.DRIVER },
        checkedValue: [
          {
            ...recipientExtra,
            receiver: { id: driver.id }
          }
        ]
      }))
    })),
    disableCheckbox: !branchList.length
  };
};

const getFleetRecipientFilterList = (t, fleets, vehiclesChatEntities, devicesChatEntities) => {
  return fleets
    .map(fleet => {
      const matchedVehicles = fleet.vehicles.filter(
        vehicle =>
          vehicle.id && vehiclesChatEntities.some(entity => entity.chatEntityId === vehicle.id)
      );
      const standaloneDevices = uniqById([
        ...(fleet.devices || []),
        ...fleet.vehicles
          .filter(v => !v.id && v.devices && v.devices.length)
          .map(v => v.devices)
          .reduce((a, b) => a.concat(b), [])
      ]);
      const matchedStandaloneDevices = standaloneDevices.filter(device =>
        devicesChatEntities.some(entity => entity.chatEntityId === device.id)
      );
      const isNoFleet = !fleet.id || !fleet.name;
      return {
        name: isNoFleet ? t('Messaging.NoFleet') : fleet.name,
        id: isNoFleet ? -1 : fleet.id,
        matchedVehicles,
        matchedStandaloneDevices
      };
    })
    .filter(fleet => fleet.matchedVehicles.length > 0 || fleet.matchedStandaloneDevices.length > 0);
};

const getBranchRecipientFilterList = (t, branches, drivers, driversChatEntities) => {
  const avaliableDrivers = driversChatEntities
    .map(entity => drivers.find(d => d.id === entity.chatEntityId))
    .filter(driver => !!driver);

  return branches
    .map(branch => {
      const isNoBranch =
        !branch.name || !branch.id || (branch.name === 'No Branch' && branch.id === -1);
      if (isNoBranch) {
        return {
          name: isNoBranch ? t('Messaging.NoBranch') : branch.name,
          id: isNoBranch ? -1 : branch.id,
          matchedDrivers: avaliableDrivers
            .filter(driver => !driver.location)
            .map(driver => ({
              ...driver,
              name: `${driver.firstName} ${driver.lastName}`
            }))
        };
      } else {
        return {
          ...branch,
          matchedDrivers: avaliableDrivers
            .filter(driver => driver.location && driver.location.id === branch.id)
            .map(driver => ({
              ...driver,
              name: `${driver.firstName} ${driver.lastName}`
            }))
        };
      }
    })
    .filter(branch => branch.matchedDrivers.length);
};

export const getChatEntityLastChatMessageLastUpdatedDate = chatEntity => {
  if (chatEntity.chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE) {
    let lastChatMsg = chatEntity.lastChatMsg;
    const allMsgs = chatEntity?.flattenChatMessages || []; //flattenChildrenMsgsOfGroupMessageEntity(chatEntity.chatMessages);
    lastChatMsg =
      allMsgs && allMsgs.length && allMsgs[allMsgs.length - 1]
        ? allMsgs[allMsgs.length - 1]
        : lastChatMsg;
    let lastUpdatedDate = lastChatMsg.lastUpdatedDate;
    const lastChatMsgStatus = lastChatMsg.messageStatus || [];
    if (lastChatMsgStatus.some(status => status.status === MESSAGE_STATUE.READ)) {
      lastUpdatedDate = lastChatMsgStatus.find(status => status.status === MESSAGE_STATUE.READ)
        .dateTime;
    } else if (lastChatMsgStatus.some(status => status.status === MESSAGE_STATUE.RECEIVED)) {
      lastUpdatedDate = lastChatMsgStatus.find(status => status.status === MESSAGE_STATUE.RECEIVED)
        .dateTime;
    } else {
      lastUpdatedDate =
        lastUpdatedDate ||
        lastChatMsgStatus.find(status => status.status === MESSAGE_STATUE.SENT)?.dateTime;
    }
    return lastUpdatedDate;
  } else {
    return chatEntity.lastChatMsg.lastUpdatedDate;
  }
};
