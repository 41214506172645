import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import { Layout, DateRangePicker } from 'components/ant';
import { Alert } from 'antd';
import moment from 'moment';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { disabledDate } from './helpers';
import { useScoreEvents, useSummary } from 'features/scorecard';
import {
  getEventTypeData,
  getScoresFromSummary,
  getScoreBandColorFromSummary,
  getMapSettingsFromEvents
} from './ScorecardEventsHelpers';
import { ColorRanges, TabKeys } from './constants';
import { CardEventType } from 'components/card-event-type';
import Map, { MapMode } from 'components/map/Map';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';

import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';

import style from './Scorecard.module.scss';
import { ScorecardEventsPane } from './ScorecardEventsPane';
import { useCurrentCompany } from 'features/company/companySlice';
import { useUsers } from 'features/users/usersSlice';
import { useVehicles } from 'features/fleets/fleetsSlice';
import { useGeofences } from 'features/geofences/geofencesSlice';
import { clearScore, clearScoreEvents } from 'features/scorecard/reducers';
import { ScorecardEventsModal } from './ScorecardEventsModal';
import dayjs from 'dayjs';

export const ScorecardEvents = () => {
  const { Content } = Layout;
  const localization = useLocalization();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const currentCompany = useCurrentCompany();
  const users = useUsers();
  const vehicles = useVehicles();
  const geofences = useGeofences();
  const { id, entityType, eventType, dateFromSV, dateToSV } = useParams();
  const isEWDevent = eventType === 'underRest' || eventType === 'overWorked' ? true : false;
  const [datePickerOpen, setDatePickerOpen] = useState();
  // Need to come up with a bettwer way to get a good default position for the user
  const [mapLocation, setMapLocation] = useState({
    lat: 37.688306,
    lng: -121.897962
  });
  const [mapZoom, setMapZoom] = useState(12);
  const [useCheckedEventsForModal, setUseCheckedEventsForModal] = useState(true);
  const [isExcludeEventsOperation, setIsExcludeEventsOperation] = useState(true);
  const [showScorecardEventsModal, setShowScorecardEventsModal] = useState(false);
  const [checkedEvents, setCheckedEvents] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dateFrom, setDateFrom] = useState(dateFromSV);
  const [dateTo, setDateTo] = useState(dateToSV);
  const [isInfowindowVisible, setIsInfoWindowVisible] = useState(false);
  const datePickerRef = useRef();
  const eventTypeData = getEventTypeData(eventType, localization);
  const events = useScoreEvents(
    dateFrom,
    dateTo,
    currentCompany?.id,
    entityType,
    id,
    eventTypeData.key
  );
  const summary = useSummary(dateFrom, dateTo, currentCompany?.id, entityType, id);

  const handleDateRangeChange = dates => {
    if (!dates || !dates[0] || !dates[1]) {
      return;
    }
    const startAt = moment(dates[0].format('YYYY-MM-DD'));
    const endAt = moment(dates[1].format('YYYY-MM-DD'));
    setDateFrom(startAt.format('YYYY-MM-DD'));
    setDateTo(endAt.format('YYYY-MM-DD'));
  };

  const handleDateRangeClose = isOpen => {
    setDatePickerOpen(isOpen);
  };

  const onScorecardMarkerClick = () => {
    setIsInfoWindowVisible(isInfowindowVisible ? false : true);
  };

  const onIncludeEventClick = ({ useCheckedEvents = true }) => {
    setUseCheckedEventsForModal(useCheckedEvents);
    setIsExcludeEventsOperation(false);
    setShowScorecardEventsModal(true);
  };

  const onExcludeEventClick = ({ useCheckedEvents = true }) => {
    setUseCheckedEventsForModal(useCheckedEvents);
    setIsExcludeEventsOperation(true);
    setShowScorecardEventsModal(true);
  };

  const onEventsModalSave = success => {
    if (success) {
      setCheckedEvents({});
      setSelectedEvent(null);
    }
    setShowScorecardEventsModal(false);
  };

  const onEventSelected = (e, event) => {
    setSelectedEvent(event);
    setIsInfoWindowVisible(true);

    if (event && event.GPS?.Lat && event.GPS?.Lng) {
      setMapLocation({ lat: event.GPS?.Lat, lng: event.GPS?.Lng });
    }
  };

  const handleMapUpdate = mapRef => {
    const { map } = mapRef.current.state;
    const center = map.getBounds() && map.getBounds().getCenter();
    const { zoom } = map;
    setMapLocation(center);
    setMapZoom(zoom);
  };

  useEffect(() => {
    datePickerRef.current?.resetDates([dayjs(dateFrom), dayjs(dateTo)]);
  }, [dateTo]);

  useEffect(() => {
    if (entityType === TabKeys.drivers) {
      const user = users?.find(user => user.id === Number(id)) || {};
      const name = `${user.firstName || ''} ${user.lastName || ''}`;

      dispatch(
        setPageTitle(`${t('Scorecard.DriverScorecard')} - ${name} / ${t(eventTypeData.name)}`)
      );
    } else {
      const vehicle = vehicles?.find(vehicle => vehicle.id === Number(id)) || {};
      const name = vehicle.name || '';

      dispatch(
        setPageTitle(`${t('Scorecard.VehicleScorecard')} - ${name} / ${t(eventTypeData.name)}`)
      );
    }

    dispatch(setBackButton(true));
  }, [vehicles, users, entityType, eventTypeData, t, dispatch, id]);

  useEffect(() => {
    return () => {
      dispatch(clearScoreEvents());
      dispatch(clearScore());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!datePickerOpen) {
      setSelectedEvent(null);
      dispatch(clearScoreEvents());
      dispatch(clearScore());
    }
  }, [datePickerOpen, dispatch]);

  const scores = getScoresFromSummary(summary, eventTypeData);
  const scoreBandColors = getScoreBandColorFromSummary(summary, eventTypeData);

  useEffect(() => {
    if (events && events.length > 0 && !isEWDevent) {
      if (mapRef) {
        const mapSettings = getMapSettingsFromEvents(events, mapRef);

        setMapLocation(mapSettings.location);
        setMapZoom(mapSettings.zoom);
      }
    }
  }, [events]);

  // Either use checked events, or the selected event for the Include/Exclude pop up
  const eventsForIncludeExclude = useCheckedEventsForModal
    ? Object.values(checkedEvents)
    : selectedEvent
    ? [selectedEvent]
    : [];

  // Pass a different callback function to the map dependind if selected event is included or excluded
  const onIncludeExcludeEventFromMap =
    selectedEvent && (selectedEvent.excluded || selectedEvent?.geofenceExcluded)
      ? onIncludeEventClick
      : onExcludeEventClick;

  return (
    <Layout>
      <Content className={style.content}>
        <div className={`${style.filterWrapper} ${style.spaceAround} ${style.rightElement}`}>
          <DateRangePicker
            className={style.dateRangePicker}
            size="small"
            format={localization.formats.time.formats.dby.toUpperCase()}
            maxDayRange={30}
            defaultDates={[dayjs(dateFrom), dayjs(dateTo)]}
            disabledDate={current => disabledDate(current, dayjs(dateFrom))}
            onDateRangeChanged={dates => {
              handleDateRangeChange(dates);
            }}
            availableDatesRange={[0, moment().endOf('day')]}
            onOpenChange={handleDateRangeClose}
            datePickerRef={datePickerRef}
          />
        </div>

        <ReflexContainer orientation="vertical">
          <ReflexElement className="leftPane" flex={0.4}>
            <CardEventType
              title={t(eventTypeData.name)}
              scores={{
                entityValue: scores.entityScore,
                companyValue: scores.companyScore,
                groupValue: scores.groupScore,
                previousValue: scores.previousEntityScore
              }}
              colors={{
                entity: ColorRanges[scoreBandColors.entityScoreBandColor],
                company: ColorRanges[scoreBandColors.companyScoreBandColor],
                group: ColorRanges[scoreBandColors.groupScoreBandColor]
              }}
              tab={entityType}
              eventDetailsMode={true}
            />
            <ScorecardEventsPane
              summary={summary}
              events={events}
              entityType={entityType}
              onIncludeEventClick={onIncludeEventClick}
              onExcludeEventClick={onExcludeEventClick}
              checkedEvents={checkedEvents}
              selectedEvent={selectedEvent}
              setCheckedEvents={setCheckedEvents}
              onEventSelected={onEventSelected}
              isEWDevent={isEWDevent}
            />
          </ReflexElement>
          <ReflexSplitter />
          <ReflexElement className="rightPane">
            {!isEWDevent && (
              <Map
                ref={mapRef}
                mode={MapMode.Scorecard}
                enableMapMenu={true}
                hideVehicleMenu={true}
                geofences={mapZoom >= 11 ? geofences : []}
                selectedTripEvent={selectedEvent}
                onZoomChanged={() => handleMapUpdate(mapRef)}
                onDragEnd={() => handleMapUpdate(mapRef)}
                onEventClicked={() => onScorecardMarkerClick()}
                enableInfoWindowActions={isInfowindowVisible}
                onIncludeExcludeEventClick={onIncludeExcludeEventFromMap}
                mapOptions={{
                  center: mapLocation,
                  zoom: mapZoom
                }}
                containerElement={
                  <div style={{ height: `95%`, width: `100%`, position: 'relative' }} />
                }
                mapElement={<div style={{ height: `100%`, width: `100%` }} />}
              />
            )}
            {isEWDevent && (
              <Alert
                description={t('Scorecard.mapMessage')}
                type="info"
                showIcon
                className={style.alertMessage}
              />
            )}
          </ReflexElement>
        </ReflexContainer>
        {showScorecardEventsModal && (
          <ScorecardEventsModal
            eventsForIncludeExclude={eventsForIncludeExclude}
            entityId={id}
            entityType={entityType}
            isExclude={isExcludeEventsOperation}
            isOpen={showScorecardEventsModal}
            currentCompany={currentCompany}
            onSave={onEventsModalSave}
            onCancel={() => {
              setShowScorecardEventsModal(false);
            }}
          />
        )}
      </Content>
    </Layout>
  );
};
