import React from 'react';
import { Button } from 'react-bootstrap';
import { BUTTON_IDS } from 'utils/globalConstants';

import styles from './Buttons.module.scss';

export const TextButton = ({ text, variant, active, onClick, id = BUTTON_IDS.text, ...props }) => {
  return (
    <Button
      className={styles.textButton}
      variant={variant}
      active={active}
      onClick={onClick}
      id={id}
      {...props}
    >
      {text}
    </Button>
  );
};
