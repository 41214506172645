import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import {
  clearAlerts,
  addAlert,
  editAlert,
  fetchAlerts,
  deleteAlertById,
  fetchGpioTypes
} from './thunks';

const initialState = {
  list: [],
  gpioTypes: [],
  isFetching: false,
  error: null,
  success: null,
  meta: {
    addAlert: {
      isSaving: false,
      error: null,
      success: false
    },
    editAlert: {
      isSaving: false,
      error: null,
      success: false
    },
    deleteAlert: {
      isDeleting: false,
      error: null,
      success: false
    },
    gpioTypes: {
      isFetching: false,
      error: null,
      success: false
    },
    companyId: null
  }
};

export const companyAlertsSlice = createSlice({
  name: 'companyAlerts',
  initialState,
  extraReducers: {
    [fetchAlerts.pending]: (state, action) => {
      state.isFetching = true;
      state.success = false;
      state.error = false;
    },
    [fetchAlerts.fulfilled]: (state, action) => {
      state.list = unwrapResult(action)?.alertSubscriptions;
      state.isFetching = false;
      state.success = true;
      state.error = false;
      state.meta.companyId = action?.meta?.arg?.query?.entity_id;
    },
    [fetchAlerts.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.success = false;
      state.meta.companyId = action?.meta?.arg?.query?.entity_id;
    },
    [addAlert.pending]: (state, action) => {
      state.meta.addAlert.isSaving = true;
    },
    [addAlert.rejected]: (state, action) => {
      state.meta.addAlert.isSaving = false;
      state.meta.addAlert.error = true;
      state.meta.addAlert.success = false;
    },
    [addAlert.fulfilled]: (state, action) => {
      state.meta.addAlert.isSaving = false;
      state.meta.addAlert.error = false;
      state.meta.addAlert.success = true;
    },
    [editAlert.pending]: (state, action) => {
      state.meta.editAlert.isSaving = true;
    },
    [editAlert.rejected]: (state, action) => {
      state.meta.editAlert.isSaving = false;
      state.meta.editAlert.error = true;
      state.meta.editAlert.success = false;
    },
    [editAlert.fulfilled]: (state, action) => {
      state.meta.editAlert.isSaving = false;
      state.meta.editAlert.error = false;
      state.meta.editAlert.success = true;
    },
    [deleteAlertById.pending]: (state, action) => {
      state.meta.deleteAlert.isDeleting = true;
    },
    [deleteAlertById.rejected]: (state, action) => {
      state.meta.deleteAlert.isDeleting = false;
      state.meta.deleteAlert.error = false;
      state.meta.deleteAlert.success = true;
    },
    [deleteAlertById.fulfilled]: (state, action) => {
      state.meta.deleteAlert.isDeleting = false;
      state.meta.deleteAlert.error = true;
      state.meta.deleteAlert.success = false;
    },
    [clearAlerts.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.success = false;
      state.error = false;
    },
    [fetchGpioTypes.pending]: (state, action) => {
      state.meta.gpioTypes.isFetching = true;
      state.meta.gpioTypes.success = false;
      state.meta.gpioTypes.error = false;
    },
    [fetchGpioTypes.fulfilled]: (state, action) => {
      state.meta.gpioTypes.isFetching = false;
      state.meta.gpioTypes.success = true;
      state.meta.gpioTypes.error = false;
      state.gpioTypes = unwrapResult(action);
    },
    [fetchGpioTypes.rejected]: (state, action) => {
      state.meta.gpioTypes.isFetching = false;
      state.meta.gpioTypes.success = false;
      state.meta.gpioTypes.error = true;
    }
  }
});
