import React from 'react';
import { Form as AntForm } from 'antd';
import style from './Form.module.scss';

export const Form = ({ colon = false, ...other }) => (
  <AntForm colon={colon} className={style.form} {...other} />
);
const Item = props => <AntForm.Item {...props} />;
const List = props => <AntForm.List {...props} />;

Form.Item = Item;
Form.List = List;
Form.useForm = AntForm.useForm;
Form.ErrorList = AntForm.ErrorList;
Form.useWatch = AntForm.useWatch;
