import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Can, entities, services } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import VehicleMntTypes from './VehicleMntTypes';
import { VehicleMntTypesView } from './VehicleMntTypesView';
import VehicleMntTypesForm from './VehicleMntTypesForm';
import { Paths } from './constants';

export const VehicleMntTypesRouter = () => {
  return (
    <Can
      everyEntity={[entities.VEHICLEMAINTENANCETYPE]}
      everyService={[services.VEHMNT]}
      onFail={<NoAccessBody />}
    >
      <Switch>
        <Route path={Paths.VIEW}>
          <Can everyEntity={[entities.VEHICLEMAINTENANCETYPE_VIEW]} onFail={<NoAccessBody />}>
            <VehicleMntTypesView />
          </Can>
        </Route>
        <Route path={Paths.ADD}>
          <Can everyEntity={[entities.VEHICLEMAINTENANCETYPE_CREATE]} onFail={<NoAccessBody />}>
            <VehicleMntTypesForm action="add" />
          </Can>
        </Route>
        <Route path={Paths.EDIT}>
          <Can everyEntity={[entities.VEHICLEMAINTENANCETYPE_UPDATE]} onFail={<NoAccessBody />}>
            <VehicleMntTypesForm action="edit" />
          </Can>
        </Route>
        <Route path={Paths.DEFAULT}>
          <Can everyEntity={[entities.VEHICLEMAINTENANCETYPE]} onFail={<NoAccessBody />}>
            <VehicleMntTypes />
          </Can>
        </Route>
      </Switch>
    </Can>
  );
};
