import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Space } from 'antd';
import { Button } from 'antd';
// import { formatDiginData, formatDigoutData } from './helpers';

export const BPM_VPM_KEYS = ['gpio.output', 'siren.events'];
export const SIREN_KEYS = [
  'warn.threshold',
  'warn.length',
  'gpio.output',
  'warn.threshold.1',
  'warn.length.1',
  'gpio.output.1'
];

export const PATHS = {
  SPEEDASSIST_DEFAULT: '/settings/company_config/speedassist',
  SPEEDASSIST_CONFIGURED: '/settings/company_config/speedassist/configured',
  SPEEDASSIST_CONFIGURED_ASSIGNED: '/settings/company_config/speedassist/configured/assigned',
  SPEEDASSIST_CONFIGURED_UNASSIGNED: '/settings/company_config/speedassist/configured/unassigned',
  SPEEDASSIST_DELETED: '/settings/company_config/speedassist/deleted',
  SPEEDASSIST_ADD: '/settings/company_config/speedassist/new',
  SPEEDASSIST_EDIT: '/settings/company_config/speedassist/edit/id',
  SPEEDASSIST_VIEW: '/settings/company_config/speedassist/id',
  SPEEDASSIST_AUDIT: '/settings/company_config/speedassist/audit/id'

  /* ,
  GPIO_ADD_REFACTOR: '/settings/company_config/gpio/new_refactor',
  GPIO_EDIT_REFACTOR: '/settings/company_config/gpio/edit_refactor/id'*/
};

export const gpioDiginColumns = (t, inBiasMap, inTriggerMap, inOccurrenceMap, ioDefinition) => {
  return [
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Channel'),
      width: '7.5%',
      dataIndex: 'channel',
      sorter: (a, b) => a.channel - b.channel,
      key: 'channel'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Bias'),
      dataIndex: 'bias',
      render: text => <span>{inBiasMap[text]}</span>,
      width: '12.5%',
      key: 'bias'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Trigger'),
      dataIndex: 'trigger',
      render: text => <span>{inTriggerMap[text]}</span>,
      width: '12.5%',
      key: 'trigger'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Occurrence'),
      dataIndex: 'occurrence',
      render: text => <span>{inOccurrenceMap[text]} </span>,
      width: '12.5%',
      key: 'occurence'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.I/O Definition'),
      width: '12.5%',
      dataIndex: 'io',
      key: 'io'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Custom Name'),
      width: '12.5%',
      dataIndex: 'custom_name',
      key: 'custom_name',
      render: (_, record) => record?.custom_name || ' - '
    }
    // {
    //   title: `${t('CompanyConfig.DeviceConfigurations.Table.IO Definition Detail')}`,
    //   width: '30%',
    //   render: (_, record) => {
    //     const ioDetail = formatDiginData(
    //       record,
    //       inBiasMap,
    //       inTriggerMap,
    //       inOccurrenceMap,
    //       ioDefinition
    //     )?.ioDetail;

    //     if (ioDetail && Object.keys(ioDetail).length !== 0) {
    //       return (
    //         <>
    //           {Object.keys(ioDetail).map(key => {
    //             return <div key={key}>{`${key}${ioDetail[key] ? ' : ' + ioDetail[key] : ''}`}</div>;
    //           })}
    //         </>
    //       );
    //     } else {
    //       return ' - ';
    //     }
    //   }
    // }
  ];
};

export const gpioDiginEditColumns = (
  t,
  inBiasMap,
  inTriggerMap,
  inOccurrenceMap,
  ioDefinition,
  handleEdit,
  handleDelete
) => {
  const columns = gpioDiginColumns(t, inBiasMap, inTriggerMap, inOccurrenceMap, ioDefinition);
  columns.push({
    title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.ActionsLabel'),
    dataIndex: '_action',
    key: '_action',
    width: '15%',
    align: 'center',
    render: (_, record) => (
      <Space size={0}>
        <Button type="link" onClick={() => handleEdit(record)}>
          <EditFilled />
        </Button>
        <Button type="link" onClick={() => handleDelete(record)}>
          <DeleteFilled style={{ color: 'red' }} />
        </Button>
      </Space>
    )
  });
  return columns;
};

export const gpioDigoutColumns = (
  t,
  outBiasMap,
  outTriggerMap,
  outOccurrenceMap,
  vpmData,
  bpmData,
  sirenData
) => {
  return [
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Channel'),
      dataIndex: 'channel',
      width: '7.5%',
      sorter: (a, b) => a.channel - b.channel,
      key: 'channel'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Initial State'),
      dataIndex: 'bias',
      render: text => <span>{outBiasMap[text]}</span>,
      width: '12.5%',
      key: 'bias'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Trigger'),
      dataIndex: 'trigger',
      render: text => <span>{outTriggerMap[text]}</span>,
      width: '12.5%',
      key: 'trigger'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Occurrence'),
      dataIndex: 'occurrence',
      render: text => <span>{outOccurrenceMap[text]} </span>,
      width: '12.5%',
      key: 'occurence'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.I/O Definition'),
      dataIndex: 'io',
      width: '12.5%',
      key: 'io'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Custom Name'),
      width: '12.5%',
      dataIndex: 'custom_name',
      key: 'custom_name',
      render: (_, record) => record?.custom_name || ' - '
    }
    // {
    //   title: `${t('CompanyConfig.DeviceConfigurations.Table.IO Definition Detail')}`,
    //   width: '30%',
    //   render: (_, record) => {
    //     const ioDetail = formatDigoutData(
    //       record,
    //       outBiasMap,
    //       outTriggerMap,
    //       outOccurrenceMap,
    //       vpmData,
    //       bpmData,
    //       sirenData
    //     )?.ioDetail;

    //     if (ioDetail && Object.keys(ioDetail).length !== 0) {
    //       return (
    //         <>
    //           {Object.keys(ioDetail).map(key => {
    //             return <div>{`${key}${ioDetail[key] ? ' : ' + ioDetail[key] : ''}`}</div>;
    //           })}
    //         </>
    //       );
    //     } else {
    //       return ' - ';
    //     }
    //   }
    // }
  ];
};

export const gpioDigoutEditColumns = (
  t,
  outBiasMap,
  outTriggerMap,
  outOccurrenceMap,
  vpmData,
  bpmData,
  sirenData,
  handleEdit,
  handleDelete
) => {
  const columns = gpioDigoutColumns(
    t,
    outBiasMap,
    outTriggerMap,
    outOccurrenceMap,
    vpmData,
    bpmData,
    sirenData
  );
  columns.push({
    title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.ActionsLabel'),
    dataIndex: '_action',
    key: '_action',
    width: '15%',
    align: 'center',
    render: (_, record) => (
      <Space size={0}>
        <Button type="link" onClick={() => handleEdit(record)}>
          <EditFilled />
        </Button>
        <Button type="link" onClick={() => handleDelete(record)}>
          <DeleteFilled style={{ color: 'red' }} />
        </Button>
      </Space>
    )
  });
  return columns;
};

export const gpioDetailColumns = t => {
  return [
    {
      title: `${t('CompanyConfig.DeviceConfigurations.Table.Channel')}`,
      width: '7.5%',
      render: (_, record) => record?.channel
    },
    {
      title: `${t('CompanyConfig.DeviceConfigurations.Table.Bias')}`,
      width: '12.5%',
      render: (_, record) => record?.bias
    },
    {
      title: `${t('CompanyConfig.DeviceConfigurations.Table.Trigger')}`,
      width: '10%',
      render: (_, record) => record?.trigger
    },
    {
      title: `${t('CompanyConfig.DeviceConfigurations.Table.Occurence')}`,
      width: '10%',
      render: (_, record) => record?.occurrence
    },
    {
      title: `${t('CompanyConfig.DeviceConfigurations.Table.IO Definition')}`,
      width: '10%',
      render: (_, record) => (!!record?.io ? t('Alerts.GPIO.' + record?.io) : ' - ')
    },
    {
      title: `${t('CompanyConfig.DeviceConfigurations.Table.Custom Name')}`,
      width: '10%',
      render: (_, record) => record?.custom_name || ' - '
    },
    {
      title: `${t('CompanyConfig.DeviceConfigurations.Table.IO Definition Detail')}`,
      width: '40%',
      render: (_, record) => {
        const ioDetail = record?.ioDetail;

        if (ioDetail && Object.keys(ioDetail).length !== 0) {
          return (
            <>
              {Object.keys(ioDetail).map(key => {
                return <div>{`${key}${ioDetail[key] ? ' : ' + ioDetail[key] : ''}`}</div>;
              })}
            </>
          );
        } else {
          return ' - ';
        }
      }
    }
  ];
};
