import React, { useMemo, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Select, Button, Dropdown, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import { sortStrings } from 'utils/strings';
import { DateRangePicker } from 'components/ant';
import { useLocalization } from 'features/localization/localizationSlice';

import { disableOutsideOfNDays } from 'containers/Tracking/helpers';
import { getEventAttributesByKey, useSupportedEventTypes } from 'containers/Tracking/EventTypes';
import { CompanyConfigValue } from 'features/company/companySlice';
import { useUsers } from 'features/users/usersSlice';
import { useBranches } from 'features/locations/locationsSlice';
import { downloadFile } from 'containers/Administration/Geofences/helpers';
import { FilterEventsSelector } from './Modals/FilterEventsModal/FilterEventsSelector';
import { prepareDataForCsvExport } from './Export/exportCSV';
import { prepareDataForGPXExport } from './Export/exportGPX';
import { prepareDataForKMLExport } from './Export/exportKML';

import { Icon } from 'components/tn/layout/nav/Icon';
import { BUTTON_IDS } from 'utils/globalConstants';

import styles from './HDDataToolbar.module.scss';
import dayjs from 'dayjs';
import { FeatureFlag, useCan } from 'features/permissions';

export const HDDataToolbar = ({
  trips,
  isLoading,
  devices,
  selectedDevice,
  hideNonBusinessTrips,
  dateRange,
  hdDataEventTypeKeys,
  setHDDataEventTypeKeys,
  onDateRangeClose,
  updateHDDataDevice,
  updateHDDataDateRange
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const history = useHistory();
  const users = useUsers();
  const branches = useBranches();
  const [csvData, setCsvData] = useState([]);
  const csvLinkRef = useRef(null);
  const can = useCan();

  // Get all event types with duplicates labels removed and sorted
  const {
    allEventTypesNoDuplicatesSorted: allEventTypesNoDuplicates,
    addEventTypesWithDuplicateLabels,
    removeEventTypesWithDuplicateLabels
  } = useSupportedEventTypes();

  const deviceList = useMemo(() => {
    let deviceList = devices?.map(device => ({
      value: device.id,
      label: device.vehicleName ? device.vehicleName : device.name
    }));
    const deviceListSorted = deviceList?.sort((a, b) => sortStrings(a?.label, b?.label));
    return deviceListSorted;
  }, [devices]);

  const { includeAllEventTypes, includedEventTypes } = useMemo(() => {
    let includedEventTypes = [];
    hdDataEventTypeKeys.forEach(eventTypeKey => {
      const eventType = getEventAttributesByKey(eventTypeKey);
      if (eventType) includedEventTypes.push(eventType);
    });

    const includedEventTypesNoDuplicates = removeEventTypesWithDuplicateLabels(includedEventTypes);

    const includedEventTypesSorted = includedEventTypesNoDuplicates.sort((a, b) =>
      sortStrings(a?.label, b?.label)
    );

    let includeAllEventTypes = false;
    if (includedEventTypesSorted.length === allEventTypesNoDuplicates.length) {
      includeAllEventTypes = true;
    }

    return {
      includedEventTypes: includedEventTypesSorted,
      includeAllEventTypes
    };
  }, [hdDataEventTypeKeys]);

  useEffect(() => {
    csvData?.length && csvLinkRef?.current?.link?.click();
  }, [csvData]);

  const getExportFilename = (device, dateRange) => {
    if (!device) {
      return;
    }

    // get vehicle name or device name if no vehicle
    const name = device.vehicleName ? device.vehicleName : device.name;

    // Include date range in file name
    const from = moment(dateRange.from);
    const fromDate = from.format('YYYYMMDD');
    const fromTime = from.format('HHmmss');

    const to = moment(dateRange.to);
    const toDate = to.format('YYYYMMDD');
    const toTime = to.format('HHmmss');

    return `HD_${name}_${fromDate}-${fromTime}__${toDate}-${toTime}`;
  };

  const handleExportToMenuClick = e => {
    if (e.key === 'CSV') {
      const newCsvData = prepareDataForCsvExport(
        selectedDevice,
        trips,
        users,
        branches,
        localization
      );
      setCsvData(newCsvData);
    } else if (e.key === 'GPX') {
      downloadFile({
        data: prepareDataForGPXExport(trips),
        fileName: getExportFilename(selectedDevice, dateRange) + '.gpx',
        fileType: 'application/gpx+xml'
      });
    } else if (e.key === 'KML') {
      downloadFile({
        data: prepareDataForKMLExport(trips),
        fileName: getExportFilename(selectedDevice, dateRange) + '.kml',
        fileType: 'application/vnd.google-earth.kml+xml'
      });
    }
  };

  const setIncludeAllEventTypes = includeAllEventTypes => {
    // Don't need to do anything here since not storing this state in this UI
  };

  const setIncludedEventTypes = includedEventTypes => {
    const includedEventTypesWithDuplicates = addEventTypesWithDuplicateLabels(includedEventTypes);
    const newHDDataEventTypeKeys = includedEventTypesWithDuplicates.map(e => e.key);

    setHDDataEventTypeKeys(newHDDataEventTypeKeys);
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledTime = (currentDate, type, maxDayRange) => {
    let disabledHours = [];
    let disabledMinutes = [];

    if (type === 'end') {
      const fromValue = document.querySelector('.ant-picker-input:first-child input')?.value;
      const fromDate = moment.utc(fromValue).tz(moment().tz(), true);

      if (fromDate && currentDate) {
        const maxEndDate = fromDate
          .clone()
          .add(maxDayRange, 'days')
          .subtract(1, 'minutes');

        if (currentDate.isSame(fromDate, 'day')) {
          disabledHours = range(0, fromDate.hour());
          if (currentDate.hour() === fromDate.hour()) {
            disabledMinutes = range(0, fromDate.minute() + 1);
          }
        } else if (currentDate.isAfter(maxEndDate, 'day')) {
          disabledHours = range(0, 24);
          disabledMinutes = range(0, 60);
        } else if (currentDate.isSame(maxEndDate, 'day')) {
          disabledHours = range(maxEndDate.hour() + 1, 24);
          if (currentDate.hour() === fromDate.hour()) {
            disabledMinutes = range(maxEndDate.minute() + 1, 60);
          }
        }
      }
    }

    return {
      disabledHours: () => disabledHours,
      disabledMinutes: () => disabledMinutes
    };
  };

  const numEvents = useMemo(() => {
    let numEvents = 0;

    if (selectedDevice && selectedDevice.trips) {
      selectedDevice.trips.forEach(trip => {
        const isNonBusinessTrip = hideNonBusinessTrips && trip?.attr === CompanyConfigValue.Private;
        if (!isNonBusinessTrip) {
          if (trip.events) {
            const filteredEvents = trip.events.filter(event =>
              hdDataEventTypeKeys.includes(event.eventType + event.subType)
            );
            numEvents += filteredEvents.length;
          }
        }
      });
    }

    return numEvents;
  }, [selectedDevice?.trips, hideNonBusinessTrips, hdDataEventTypeKeys]);

  const numEventsLabel = numEvents?.length === 1 ? t('Easydocs.Event') : t('SmartJobs.Events');

  const exportToMenuItems = [
    { label: 'CSV', key: 'CSV' },
    { label: 'GPX', key: 'GPX' },
    { label: 'KML', key: 'KML' }
  ];

  const exportToMenuProps = {
    items: exportToMenuItems,
    onClick: handleExportToMenuClick
  };

  const hasNoData = !isLoading && !trips?.length;

  return (
    <div className={styles.hdDataToolbar}>
      <div className={styles.row1}>
        <Dropdown trigger={['click']} menu={exportToMenuProps} disabled={hasNoData}>
          <Button className={styles.exportToButton} id={BUTTON_IDS.hdDataExportTo}>
            {t('Tracking.ExportTo')}
          </Button>
        </Dropdown>
        <CSVLink
          enclosingCharacter={``}
          data={csvData || []}
          className={styles.hidden}
          filename={getExportFilename(selectedDevice, dateRange) + '.csv'}
          ref={csvLinkRef}
        />
        <Tooltip title={t('Tracking.OpenInNewTab')}>
          <Button
            className={styles.openInNewTabButton}
            onClick={() => {
              window.open(history.location.pathname, '_blank');
            }}
            id={BUTTON_IDS.hdDataOpenInNewTab}
          >
            <Icon id="external-link" className={styles.openInNewTablIcon} />
          </Button>
        </Tooltip>
      </div>
      <div className={styles.row2}>
        <Select
          className={styles.vehicleSelect}
          showSearch
          filterOption={(input, option) =>
            option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          options={deviceList}
          value={selectedDevice?.id}
          onSelect={value => updateHDDataDevice(value)}
        />
        <DateRangePicker
          showTime={{
            format: 'HH:mm',
            defaultValue: [dayjs('00:00', 'HH:mm'), dayjs('23:59', 'HH:mm')]
          }}
          format={localization.formats.time.formats.dby.toUpperCase() + ' HH:mm'}
          maxDayRange={1}
          defaultDates={[moment(dateRange.from), moment(dateRange.to)]}
          disabledDate={current => disableOutsideOfNDays(current, 1, true)}
          disabledTime={(date, type) => disabledTime(date, type, 1)}
          onDateRangeChanged={dates => {
            updateHDDataDateRange({ from: moment(dates[0]), to: moment(dates[1]) });
          }}
          onOpenChange={onDateRangeClose}
          showToday={false}
          showYesterday={false}
          showWeekToDate={false}
          showPast7Days={false}
          showPast14Days={false}
          showLastMonth={false}
          className={styles.datePicker}
          renderExtraFooter={() => {
            if (can({ featureFlag: FeatureFlag.trackingEnhancement.flag })) {
              return (
                <div className={styles.customDatePickerFooter}>
                  {t('Tracking.HDDataTimePeriod')}
                </div>
              );
            }
          }}
        />
        <FilterEventsSelector
          className={styles.eventTypesSelect}
          includeAllEventTypes={includeAllEventTypes}
          setIncludeAllEventTypes={setIncludeAllEventTypes}
          includedEventTypes={includedEventTypes}
          setIncludedEventTypes={setIncludedEventTypes}
        />
        <span className={styles.numEventsLabel}>{`${numEvents} ${numEventsLabel}`}</span>
      </div>
    </div>
  );
};
