import React from 'react';
import { Spin } from 'antd';

import styles from './loading.module.scss';

export const LoadingOverlay = ({ isLoading = true }) => {
  return (
    isLoading && (
      <div className={styles.loadingOverlay}>
        <Spin />
        {/* <Loading /> */}
      </div>
    )
  );
};
