import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uploadRunsheet, useIsUploadingRunsheets } from 'features/smartJobs/smartJobsSlice';
import { Modal, Alert } from 'antd';
import { FileUpload } from 'components/FileUpload';
import { Button } from 'components/ant';
import { FormCard } from 'containers/Easydocs/FormModal/FormCard';
import englishFile from './sample/English.xlsx';
import spanishFile from './sample/Spanish.xlsx';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import style from './scss/UploadRunsheet.module.scss';
import { SPANISH_LOCALES } from 'features/localization/languages';
import { BUTTON_IDS } from 'utils/globalConstants';

export const UploadRunsheetModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();
  const isUploading = useIsUploadingRunsheets();
  const userPreferences = useUserPreferences();

  const [file, setFile] = useState(null);
  const [uploadResponse, setUploadResponse] = useState();
  const { t } = useTranslation();

  const handleAddFile = e => {
    setFile(e.target.files[0]);
  };

  const handleRemoveFile = () => () => {
    setFile(null);
  };

  const handleUpload = async () => {
    if (!file) {
      return;
    }

    if (uploadResponse) {
      setUploadResponse(null);
      setFile(null);

      return;
    }

    const response = await dispatch(uploadRunsheet(file));
    setUploadResponse(response);
  };

  const handleClose = () => {
    onCancel();
    setUploadResponse(null);
    setFile(null);
  };

  const getDescription = () => {
    const { body } = uploadResponse;
    if (!uploadResponse || !body) {
      return null;
    }

    const { error, errors = [], summary } = body;

    if (error) {
      return <div>{t('Preferences.Save.ErrorMessage')}</div>;
    }

    if (errors.length) {
      return (
        <div>
          <p>{t(`SmartJobs.UploadDescription.Error.Line1`)}</p>
          <p>{t(`SmartJobs.UploadDescription.Error.Line2`)}</p>
          {errors.map(error => (
            <p>{`${t(`SmartJobs.Column`)}: ${error.column}, ${t(`SmartJobs.row`)}: ${error.row} : ${
              error.details
            }`}</p>
          ))}
        </div>
      );
    }

    return (
      <div>
        {t('SmartJobs.UploadMessage.Prefix')}{' '}
        <strong>
          {summary?.jobs || 0} {t('SmartJobs.UploadMessage.jobs')}
        </strong>{' '}
        {t('SmartJobs.UploadMessage.and')}{' '}
        <strong>
          {summary?.items || 0} {t('SmartJobs.UploadMessage.items')}{' '}
        </strong>
        {t('SmartJobs.UploadMessage.Suffix')}
      </div>
    );
  };

  const renderUploadMessages = () => {
    const isSuccessful = uploadResponse.status === 200;
    const alertType = isSuccessful ? 'success' : 'error';

    return (
      <div className={style.responseContainer}>
        <Alert description={getDescription()} type={alertType} showIcon />
        {isSuccessful && (
          <Button
            type="primary"
            className={style.viewButton}
            onClick={handleClose}
            id={BUTTON_IDS.uploadRunsheetModalClose}
          >
            {t('Actions.Close')}
          </Button>
        )}
      </div>
    );
  };

  const renderUploadModalSection = () => {
    return (
      <div className={style.responseContainer}>
        <Alert
          description={
            <div>
              {t('SmartJobs.UploadDescription.Prefix')}{' '}
              <strong>{t('SmartJobs.UploadDescription.MultipleRunsheets')}</strong>,{' '}
              {t('SmartJobs.UploadDescription.Include')}{' '}
              <strong>{t('SmartJobs.UploadMessage.jobs')}</strong>{' '}
              {t('SmartJobs.UploadMessage.and')}{' '}
              <strong>{t('SmartJobs.UploadMessage.items')}</strong>{' '}
              {t('SmartJobs.UploadDescription.within')}. {t('SmartJobs.UploadDescription.Requires')}
              <strong>{t('SmartJobs.UploadDescription.ExcelFile')}</strong>{' '}
              {t('SmartJobs.UploadDescription.in')} <strong>xlsx</strong>{' '}
              {t('SmartJobs.UploadDescription.format')}. {t('SmartJobs.UploadDescription.Please')}{' '}
              <strong>{t('SmartJobs.UploadDescription.DownloadTemplate')}</strong>{' '}
              {t('SmartJobs.UploadDescription.before')}.
            </div>
          }
          type="info"
          showIcon
        />
        <a
          className={style.downloadTemplate}
          href={
            Object.values(SPANISH_LOCALES).some(esLang => userPreferences?.language === esLang)
              ? spanishFile
              : englishFile
          }
          download={`${t('SmartJobs.RunsheetUploadTemplate')}.xlsx`}
        >
          <Button id={BUTTON_IDS.uploadRunsheetModalTmpt}>
            {t('SmartJobs.DownloadTemplateBtn')}
          </Button>
        </a>
        <div className={style.uploadSectionContainer}>
          <FormCard
            additionalItems={
              <FileUpload
                uploadedFiles={[file]}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                multiple={false}
                onAdd={handleAddFile}
                onRemove={handleRemoveFile}
                secondary
              />
            }
            size="large"
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={t('SmartJobs.UploadTitle')}
      open={visible}
      onOk={handleUpload}
      onCancel={handleClose}
      okText={uploadResponse ? t('SmartJobs.NewUpload') : t('SmartJobs.Upload')}
      confirmLoading={isUploading}
      width={650}
      okButtonProps={{
        disabled: !file
      }}
    >
      {!uploadResponse ? renderUploadModalSection() : renderUploadMessages()}
    </Modal>
  );
};
