import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { toLower } from 'lodash';
import request from 'superagent';
import { API_PATH } from 'config';

const easydocsDevicesSlice = createSlice({
  name: 'easydocsDevices',
  initialState: {
    list: [],
    details: [],
    meta: {
      lastFetched: null,
      isFetching: false,
      error: null,
      isListEmpty: true, // Set initial state for isListEmpty to true
      companyKey: null
    }
  },
  reducers: {
    fetchDevicesStart(state) {
      state.meta.isFetching = true;
    },
    fetchDevicesSuccess(state, { payload }) {
      state.list = sortBy(payload.list, [
        function(o) {
          return toLower(o.device);
        }
      ]);
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
    },
    fetchDevicesFailure(state, { payload }) {
      state.meta.isFetching = false;
      state.meta.error = payload.err;
      state.meta.isListEmpty = true;
    },
    fetchDeviceDetailsStart(state) {
      state.meta.isFetching = true;
    },
    fetchDeviceDetailsSuccess(state, { payload }) {
      state.details = sortBy(payload.details, [
        function(o) {
          return toLower(o.name);
        }
      ]);
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
    },
    fetchDeviceDetailsFailure(state, { payload }) {
      state.meta.isFetching = false;
      state.meta.error = payload.err;
    }
  }
});

export const {
  fetchDevicesStart,
  fetchDevicesSuccess,
  fetchDevicesFailure,
  fetchDeviceDetailsStart,
  fetchDeviceDetailsSuccess,
  fetchDeviceDetailsFailure
} = easydocsDevicesSlice.actions;

export const fetchDevices = () => async (dispatch, getState) => {
  try {
    const companyId = getState().companies.current.id;
    dispatch(fetchDevicesStart());
    new Promise((resolve, reject) => {
      const userKey = getState().user.current.auth.key;

      request('GET', `${API_PATH}/documents/device/summary?company_id=${companyId}`)
        .set('Authorization', `Token token="${userKey}"`)
        .set('Content-Type', 'application/json')
        .then(res => {
          resolve({ list: res.body });
          dispatch(fetchDevicesSuccess({ list: res.body }));
        })
        .catch(err => {
          console.error(err);
          reject({ err });
          dispatch(fetchDevicesFailure({ err: err.toString() }));
        });
    });
  } catch (err) {
    dispatch(fetchDevicesFailure({ err: err.toString() }));
  }
};

export const useEasydocsDevices = () => {
  const devices = useSelector(state => state.easydocsDevices.list);
  return devices;
};

export const fetchDeviceDetails = deviceId => async (dispatch, getState) => {
  try {
    const companyId = getState().companies.current.id;
    const userKey = getState().user.current.auth.key;

    if (!userKey) {
      return;
    }
    // if (getState().fleets.meta.isFetching) {
    //   return;
    // }

    const response = await request
      .get(`${API_PATH}/documents/device/${deviceId}?company_id=${companyId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json');

    dispatch(fetchDeviceDetailsSuccess({ details: response.body }));
  } catch (err) {
    console.error(err);
    dispatch(fetchDeviceDetailsFailure({ err: err.toString() }));
  }
};

export const useEasydocsDeviceDetails = () => {
  const details = useSelector(state => state.easydocsDevices.details);
  return details;
};
export const useIsFetching = () => useSelector(state => state.easydocsDevices.meta.isFetching);

export default easydocsDevicesSlice.reducer;
