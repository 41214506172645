import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import request from 'superagent';
import moment from 'moment';
import { API_PATH } from 'config';
import { getBoundsZoomLevel, getPointsZoomLevel } from 'utils/geo';
import { MapMode } from 'components/map/Map';
import { useCurrentRegion } from 'features/regions/regionsSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';
import { LENS_STEPS, Mode } from './constants';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { getGeofencePoints } from 'features/geofences/geofencesUtil';
import { prepareDataForMultiselect } from 'utils/filters';
import { useTranslation } from 'react-i18next';

const initialValue = {
  location: null,
  geofence: null,
  map: {
    zoom: null,
    center: null
  },
  currentStep: LENS_STEPS.SELECT_LOCATION,
  defaultLocation: null
};
const initialLensDevices = {
  isFetching: false,
  hasFetched: false,
  list: [],
  selected: null
};
const useWithInGeofence = (
  trackingDevices,
  trackingFleets,
  dateRange,
  wrapperRef,
  searchMode,
  drawingOverlay
) => {
  const geoSuggestRef = useRef(null);
  const [withInGeofence, setWithInGeofence] = useState(initialValue);
  const [lensDevices, setLensDevices] = useState(initialLensDevices);
  const [filteredFleets, setFilteredFleets] = useState([]);
  const { t } = useTranslation();

  const userKey = useUserKey();
  const currentCompany = useCurrentCompany();
  const currentRegion = useCurrentRegion();
  const currentRegionDetails = useMemo(() => {
    const details = {
      geocode: null,
      config: null,
      maxSpeed: 140
    };
    try {
      details.geocode = JSON.parse(currentRegion?.geocode || '{}');
    } catch (e) {
      details.geocode = null;
    }
    try {
      details.config = JSON.parse(currentRegion?.config || '{}');
      details.maxSpeed = details.config?.max_speed_kmh || details.maxSpeed;
    } catch (e) {
      details.config = null;
      details.maxSpeed = 140;
    }
    return details;
  }, [currentRegion]);

  useEffect(() => {
    setWithInGeofence({
      ...initialValue,
      defaultLocation: currentRegionDetails.geocode
    });
    setLensDevices(initialLensDevices);
  }, [currentRegionDetails]);

  const currentStep = useMemo(() => withInGeofence.currentStep, [withInGeofence]);

  const mapConfig = useMemo(() => {
    const location = withInGeofence?.location;
    const zoom =
      withInGeofence?.map?.zoom ||
      withInGeofence?.location?.zoom ||
      withInGeofence?.defaultLocation?.zoom;
    const isValidLocation = location =>
      location && !isNaN(Number(location?.lat)) && !isNaN(Number(location?.lng));
    const geofences = withInGeofence?.geofence ? [withInGeofence?.geofence] : [];
    const selectedTripSegment = lensDevices?.selected?.selectedTripSegment;

    const defaultLocation = isValidLocation(withInGeofence?.defaultLocation)
      ? {
          lat: Number(withInGeofence.defaultLocation?.lat),
          lng: Number(withInGeofence.defaultLocation?.lng)
        }
      : null;
    const selectedLocation = isValidLocation(location)
      ? {
          lat: Number(location?.lat),
          lng: Number(location?.lng)
        }
      : null;
    const geofenceCenterLocation =
      withInGeofence?.geofence?.shape?.center &&
      isValidLocation({
        lat: withInGeofence?.geofence?.shape?.center?.lat(),
        lng: withInGeofence?.geofence?.shape?.center?.lng()
      })
        ? {
            lat: withInGeofence?.geofence?.shape?.center?.lat(),
            lng: withInGeofence?.geofence?.shape?.center?.lng()
          }
        : null;

    const mapOptions = {
      zoom:
        withInGeofence?.currentStep === LENS_STEPS.SELECT_LOCATION || selectedLocation
          ? zoom && Number(zoom)
          : null,
      center:
        selectedLocation ||
        (withInGeofence?.currentStep === LENS_STEPS.SELECT_LOCATION ? defaultLocation : null)
    };
    const showLocationOnMap = !geofenceCenterLocation && isValidLocation(location);
    return {
      enableMapMenu: true,
      enableGeofenceSuggest: true,
      devices: lensDevices?.selected ? [lensDevices.selected] : lensDevices.list,
      mode:
        withInGeofence?.currentStep === LENS_STEPS.DRAW_GEOFENCE
          ? MapMode.DrawGeofence
          : MapMode.Geofence,
      location: showLocationOnMap ? location : null,
      geofences,
      selectedTripSegment,
      onGeofenceDrawn: shape => {
        setWithInGeofence(prev => ({
          ...prev,
          map: {
            ...prev.map,
            zoom: shape.map.zoom
          },
          geofence: {
            shape: {
              ...shape,
              type: 'POLYGON'
            },
            type: 'USER'
          }
        }));
        setLensDevices(initialLensDevices);
      },
      mapOptions: geofenceCenterLocation ? null : mapOptions,
      drawingOptions: {
        drawingMode:
          withInGeofence.currentStep === LENS_STEPS.DRAW_GEOFENCE ? drawingOverlay : null,
        drawingControl: withInGeofence.currentStep === LENS_STEPS.DRAW_GEOFENCE
      },
      containerElement: <div style={{ height: `100%`, width: `100%` }} />,
      mapElement: <div style={{ height: `100%`, width: `100%` }} />
    };
  }, [withInGeofence, lensDevices]);

  const setCurrentStep = useCallback(currentStep => {
    setWithInGeofence(prev =>
      currentStep === prev.currentStep
        ? prev
        : {
            ...prev,
            currentStep
          }
    );
  }, []);

  useEffect(() => {
    const fleetsOptions = (trackingFleets || []).filter(fleet => fleet.id !== -1);
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilteredFleets(prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets'), null));
  }, [trackingFleets]);

  const handleFilteredFleetsChange = value => {
    setFilteredFleets(value);
  };

  const onLocationSelected = useCallback(
    suggest => {
      setLensDevices(initialLensDevices);
      if (!suggest) {
        setWithInGeofence({
          ...initialValue,
          defaultLocation: currentRegionDetails.geocode
        });
        return;
      }
      let location, zoom;
      if (!suggest.gmaps) {
        location = {
          lat: suggest.location.lat,
          lng: suggest.location.lng
        };
        if (suggest?.geofence) {
          zoom = getPointsZoomLevel({
            points: getGeofencePoints(suggest.geofence, true),
            mapDim: {
              height: wrapperRef.current?.clientHeight,
              width: wrapperRef.current?.clientWidth
            }
          });
        }
      } else {
        const lat = suggest.gmaps.geometry?.location?.lat();
        const lng = suggest.gmaps.geometry?.location?.lng();
        location = { lat, lng };
        const bounds = suggest.gmaps.geometry?.viewport;
        zoom =
          bounds && wrapperRef
            ? getBoundsZoomLevel(bounds, {
                height: wrapperRef.current?.clientHeight,
                width: wrapperRef.current?.clientWidth
              })
            : zoom;
      }
      setWithInGeofence(prev => ({
        ...prev,
        geofence: null,
        location,
        map: {
          ...prev?.map,
          mapMode: MapMode.DrawGeofence,
          zoom: zoom || prev?.map?.zoom
        },
        currentStep: LENS_STEPS.DRAW_GEOFENCE
      }));
    },
    [wrapperRef, currentRegionDetails]
  );

  const executeSearch = useCallback(() => {
    setLensDevices({ isFetching: true, hasFetched: false, list: [], selected: null });

    let devices = trackingDevices;
    const fleetIds = filteredFleets.filter(i => i.checked).map(i => i.id);
    devices = (trackingDevices || []).filter(i => fleetIds.includes(i.fleetId || -1));

    request('POST', `${API_PATH}/devices/proximity?company_id=${currentCompany.id}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('content-type', 'application/json')
      .send({
        deviceIds: devices?.map(device => device.id.toString()).join(', '),
        from: dateRange?.from,
        to: dateRange?.to,
        points: withInGeofence?.geofence?.shape?.points
      })
      .then(response => {
        setCurrentStep(LENS_STEPS.SHOW_RESULT);
        if (!response.body.length) {
          setLensDevices({ isFetching: false, hasFetched: true, list: [], selected: null });
          return {};
        }

        let devicesMap = {};
        response.body.forEach((device, index) => {
          devicesMap[device.did] = response.body[index];
        });

        let deviceIds = response.body.map(proximityDevice => proximityDevice.did);
        let filteredDevices = trackingDevices
          .filter(device => deviceIds.includes(device.id))
          .map(device => {
            const { interactiveDevices, ...tempDevice } = device;
            const newDevice = JSON.parse(JSON.stringify(tempDevice));

            newDevice.entry = JSON.parse(devicesMap[device.id].entry);
            // newDevice.entry.at = new Date(newDevice.entry.at.split(' ').join('T') + '-0000');
            newDevice.entry.at = new Date(newDevice.entry.at);
            newDevice.exit = JSON.parse(devicesMap[device.id].exit);
            // newDevice.exit.at = new Date(newDevice.exit.at.split(' ').join('T') + '-0000');
            newDevice.exit.at = new Date(newDevice.exit.at);
            newDevice.deviceStats.gps.Lat = newDevice.exit.lat;
            newDevice.deviceStats.gps.Lng = newDevice.exit.lng;

            return newDevice;
          });
        setLensDevices({
          isFetching: false,
          hasFetched: true,
          list: filteredDevices,
          selected: null
        });
        return filteredDevices;
      });
    Mixpanel.sendTrackEvent(MPTrackingEvents.Tracking.FindVehicle);
  }, [currentCompany, withInGeofence, trackingDevices, dateRange, setCurrentStep, filteredFleets]);

  const getDeviceReplay = useCallback(
    (deviceId, from, to) => {
      return request('GET', `${API_PATH}/devices/${deviceId}/positions`)
        .set('Authorization', `Token token="${userKey}"`)
        .query({
          from: from ? moment(from).format() : dateRange.from,
          to: to ? moment(to).format() : dateRange.to,
          orderBy: 'time_at',
          highDefinition: true
        })
        .then(response => response.body.GPS.filter(point => point.Lat && point.Lng))
        .sort((a, b) => Number(a?.At) - Number(b?.At));
    },
    [dateRange, userKey]
  );

  const onSearch = useCallback(() => {
    setWithInGeofence(prev => ({
      ...prev,
      currentStep: LENS_STEPS.SEARCHING,
      map: {
        ...prev.map,
        mapMode: MapMode.Geofence
      }
    }));
    executeSearch();
  }, [executeSearch]);

  const onClearSearch = useCallback(() => {
    setWithInGeofence({
      ...initialValue,
      defaultLocation: currentRegionDetails.geocode
    });
    geoSuggestRef.current && geoSuggestRef.current.clear();
    setLensDevices(initialLensDevices);
  }, [currentRegionDetails, geoSuggestRef]);

  const resetLensDevices = useCallback(() => setLensDevices(initialLensDevices), []);

  const onLensDeviceTripSelected = useCallback(
    (deviceId, startDate, endDate) => {
      getDeviceReplay(deviceId, startDate, endDate).then(positions => {
        setLensDevices(prev => ({
          ...prev,
          selected: {
            ...prev.list?.find(device => device.id === deviceId),
            selectedTripSegment: { replay: positions }
          }
        }));
      });
    },
    [getDeviceReplay]
  );

  const lensConfig = useMemo(() => {
    return {
      withInGeofence,
      lensDevices,
      geoSuggestRef,
      currentStep,
      setCurrentStep,
      onLocationSelected,
      onSearch,
      handleFilteredFleetsChange,
      onClearSearch,
      onLensDeviceTripSelected,
      resetLensDevices,
      fleets: filteredFleets,
      resultPaneStyle:
        withInGeofence?.currentStep === LENS_STEPS.SHOW_RESULT
          ? {
              padding: 0
            }
          : {}
    };
  }, [
    withInGeofence,
    lensDevices,
    geoSuggestRef,
    currentStep,
    filteredFleets,
    handleFilteredFleetsChange,
    setCurrentStep,
    onLocationSelected,
    onSearch,
    onClearSearch,
    onLensDeviceTripSelected
  ]);

  return { lensConfig, mapConfig, onClearSearch };
};
export default useWithInGeofence;
