import React from 'react';
import { Card, Checkbox, Form, Input, Radio, Select } from 'antd';
import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';

import styles from '../Configuration.module.scss';

const buildField = field => {
  const getInputType = field.type === 'password' ? 'password' : 'text';
  switch (field.type) {
    case 'select':
      return (
        <Select
          options={field.options.map(option => ({ label: option.value, value: option.key }))}
        />
      );
    case 'radio':
      return (
        <Radio.Group
          options={field.options.map(option => ({ label: option.value, value: option.key }))}
          defaultValue={field.options.find(option => option.default === true).key}
        ></Radio.Group>
      );
    case 'checkbox':
      return <Checkbox>{field.label}</Checkbox>;
    case 'password':
      return <Input.Password autoComplete="new-password" />;
    default:
      return <Input type={getInputType} />;
  }
};
export const buildFormItems = adapter => {
  return adapter?.fields?.map(field => {
    const valuePropNameCheckbox = field.type === 'checkbox' ? { valuePropName: 'checked' } : {};
    return (
      <Form.Item
        name={field.id}
        label={field.label}
        tooltip={field.help}
        {...valuePropNameCheckbox}
        key={field.id}
      >
        {buildField(field)}
      </Form.Item>
    );
  });
};

export const buildValidateResult = validateResult => {
  if (!validateResult) {
    return;
  }

  return (
    <>
      {validateResult.some(val => val.passed) && (
        <Card className={styles.validationCard}>
          <CheckCircleFilled className={styles.checkIcon} />
          {validateResult.map(
            d =>
              d.passed && (
                <div className={styles.validationInfoWrapper} key={d.name}>
                  <div className={styles.validationTitle}>{d.name}</div>
                  <div>{d.output}</div>
                </div>
              )
          )}
        </Card>
      )}
      {validateResult.some(val => !val.passed) && (
        <Card className={styles.validationCard}>
          <WarningFilled className={styles.warningIcon} />
          {validateResult.map(
            d =>
              !d.passed && (
                <div className={styles.validationInfoWrapper} key={d.name}>
                  <div className={styles.validationTitle}>{d.name}</div>
                  <div>{d.output}</div>
                </div>
              )
          )}
        </Card>
      )}
    </>
  );
};
