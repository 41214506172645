import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from 'components/ant';
import { Modal, Form } from 'antd';
import { completeRunsheet, useRunsheet } from 'features/smartJobs';

import { getIDFromPathname } from './utils/helpers';
import { useLocation } from 'react-router';

export const ForceCompleteModal = ({ visible, showModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state, pathname } = useLocation();
  const runsheetId = state?.id || getIDFromPathname(pathname);
  const runsheet = useRunsheet(runsheetId);

  const [form] = Form.useForm();
  const formIsInvalid = () => {
    return !form.getFieldValue('reason');
  };
  const [disableOKBtn, setDisableOKBtn] = useState(formIsInvalid());

  const handleOk = useCallback(() => {
    form.submit();
  }, [form]);
  const handleCancel = () => {
    showModal(false);
  };
  const checkFormValid = (changedValues, allValues) => {
    setDisableOKBtn(formIsInvalid());
  };
  const handleSubmit = values => {
    const isRunsheetCompleted = dispatch(completeRunsheet(runsheet, values.reason, t));
    if (isRunsheetCompleted) {
      showModal(false);
    }
  };

  return (
    <Modal
      title={`${t('SmartJobs.CompleteReason')}`}
      open={visible}
      okText={`${t('SmartJobs.Complete')}`}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: disableOKBtn }}
    >
      <Form
        layout="vertical"
        form={form}
        name="force_complete_form"
        style={{ width: '100%' }}
        onFieldsChange={checkFormValid}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="reason"
          label={t('VehicleMntSchedules.View.Reason')}
          rules={[
            { required: true, message: t('Messaging.Form.FormValidation.MessageRequired') },
            { max: 1000, message: t('Messaging.Form.FormValidation.MessageLength') }
          ]}
          hasFeedback
        >
          <Input.TextArea
            className={'showScrollbarsOnHover'}
            autoSize={{ minRows: 6, maxRows: 12 }}
            placeholder={t('Messaging.Form.FormValidation.WriteSomething')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
