import React from 'react';

import styles from '../Insights.module.scss';

export const Toolbar = ({ children, ...props }) => {
  return (
    <div className={styles.toolbar} {...props}>
      {children}
    </div>
  );
};
