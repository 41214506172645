/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Company from "./Company";
import Device from "./Device";
import DeviceEvent from "./DeviceEvent";
import Gps from "./Gps";
import LocalDateTime from "./LocalDateTime";
import User from "./User";
import Vehicle from "./Vehicle";

/**
 * The EngineMgtSummaryEvent model module.
 * @module model/EngineMgtSummaryEvent
 * @version 1.0
 */
class EngineMgtSummaryEvent {
  /**
   * Constructs a new <code>EngineMgtSummaryEvent</code>.
   * @alias module:model/EngineMgtSummaryEvent
   */
  constructor() {
    EngineMgtSummaryEvent.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>EngineMgtSummaryEvent</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EngineMgtSummaryEvent} obj Optional instance to populate.
   * @return {module:model/EngineMgtSummaryEvent} The populated <code>EngineMgtSummaryEvent</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EngineMgtSummaryEvent();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("device")) {
        obj["device"] = Device.constructFromObject(data["device"]);
      }
      if (data.hasOwnProperty("user")) {
        obj["user"] = User.constructFromObject(data["user"]);
      }
      if (data.hasOwnProperty("vehicle")) {
        obj["vehicle"] = Vehicle.constructFromObject(data["vehicle"]);
      }
      if (data.hasOwnProperty("company")) {
        obj["company"] = Company.constructFromObject(data["company"]);
      }
      if (data.hasOwnProperty("gpsOdoStart")) {
        obj["gpsOdoStart"] = ApiClient.convertToType(
          data["gpsOdoStart"],
          "Number"
        );
      }
      if (data.hasOwnProperty("gpsOdoEnd")) {
        obj["gpsOdoEnd"] = ApiClient.convertToType(data["gpsOdoEnd"], "Number");
      }
      if (data.hasOwnProperty("ignitionOn")) {
        obj["ignitionOn"] = ApiClient.convertToType(data["ignitionOn"], "Date");
      }
      if (data.hasOwnProperty("ignitionOff")) {
        obj["ignitionOff"] = ApiClient.convertToType(
          data["ignitionOff"],
          "Date"
        );
      }
      if (data.hasOwnProperty("plausible")) {
        obj["plausible"] = ApiClient.convertToType(
          data["plausible"],
          "Boolean"
        );
      }
      if (data.hasOwnProperty("fuelConsumptionStart")) {
        obj["fuelConsumptionStart"] = ApiClient.convertToType(
          data["fuelConsumptionStart"],
          "Number"
        );
      }
      if (data.hasOwnProperty("fuelConsumptionEnd")) {
        obj["fuelConsumptionEnd"] = ApiClient.convertToType(
          data["fuelConsumptionEnd"],
          "Number"
        );
      }
      if (data.hasOwnProperty("distanceStart")) {
        obj["distanceStart"] = ApiClient.convertToType(
          data["distanceStart"],
          "Number"
        );
      }
      if (data.hasOwnProperty("distanceEnd")) {
        obj["distanceEnd"] = ApiClient.convertToType(
          data["distanceEnd"],
          "Number"
        );
      }
      if (data.hasOwnProperty("engineHourStart")) {
        obj["engineHourStart"] = ApiClient.convertToType(
          data["engineHourStart"],
          "Number"
        );
      }
      if (data.hasOwnProperty("engineHourEnd")) {
        obj["engineHourEnd"] = ApiClient.convertToType(
          data["engineHourEnd"],
          "Number"
        );
      }
      if (data.hasOwnProperty("harshBrake")) {
        obj["harshBrake"] = ApiClient.convertToType(
          data["harshBrake"],
          "String"
        );
      }
      if (data.hasOwnProperty("excessiveAccel")) {
        obj["excessiveAccel"] = ApiClient.convertToType(
          data["excessiveAccel"],
          "String"
        );
      }
      if (data.hasOwnProperty("fuelComsumption")) {
        obj["fuelComsumption"] = ApiClient.convertToType(
          data["fuelComsumption"],
          "String"
        );
      }
      if (data.hasOwnProperty("overRevving")) {
        obj["overRevving"] = ApiClient.convertToType(
          data["overRevving"],
          "String"
        );
      }
      if (data.hasOwnProperty("idle")) {
        obj["idle"] = ApiClient.convertToType(data["idle"], "String");
      }
      if (data.hasOwnProperty("vehicleMaintenanceStatus")) {
        obj["vehicleMaintenanceStatus"] = ApiClient.convertToType(
          data["vehicleMaintenanceStatus"],
          "String"
        );
      }
      if (data.hasOwnProperty("oilTemperatureStatus")) {
        obj["oilTemperatureStatus"] = ApiClient.convertToType(
          data["oilTemperatureStatus"],
          "String"
        );
      }
      if (data.hasOwnProperty("coolantTemperatureStatus")) {
        obj["coolantTemperatureStatus"] = ApiClient.convertToType(
          data["coolantTemperatureStatus"],
          "String"
        );
      }
      if (data.hasOwnProperty("timeAt")) {
        obj["timeAt"] = ApiClient.convertToType(data["timeAt"], "Date");
      }
      if (data.hasOwnProperty("startLocation")) {
        obj["startLocation"] = ApiClient.convertToType(
          data["startLocation"],
          "String"
        );
      }
      if (data.hasOwnProperty("endLocation")) {
        obj["endLocation"] = ApiClient.convertToType(
          data["endLocation"],
          "String"
        );
      }
      if (data.hasOwnProperty("calculatedFuelConsumption")) {
        obj["calculatedFuelConsumption"] = ApiClient.convertToType(
          data["calculatedFuelConsumption"],
          "Number"
        );
      }
      if (data.hasOwnProperty("calculatedGpsDiffOdometer")) {
        obj["calculatedGpsDiffOdometer"] = ApiClient.convertToType(
          data["calculatedGpsDiffOdometer"],
          "Number"
        );
      }
      if (data.hasOwnProperty("calculatedCanDiffOdometer")) {
        obj["calculatedCanDiffOdometer"] = ApiClient.convertToType(
          data["calculatedCanDiffOdometer"],
          "Number"
        );
      }
      if (data.hasOwnProperty("calculatedGpsDiffEngineHour")) {
        obj["calculatedGpsDiffEngineHour"] = ApiClient.convertToType(
          data["calculatedGpsDiffEngineHour"],
          "Number"
        );
      }
      if (data.hasOwnProperty("calculatedCanDiffEngineHour")) {
        obj["calculatedCanDiffEngineHour"] = ApiClient.convertToType(
          data["calculatedCanDiffEngineHour"],
          "Number"
        );
      }
      if (data.hasOwnProperty("diagnosticTroubleCodes")) {
        obj["diagnosticTroubleCodes"] = ApiClient.convertToType(
          data["diagnosticTroubleCodes"],
          "String"
        );
      }
      if (data.hasOwnProperty("docId")) {
        obj["docId"] = ApiClient.convertToType(data["docId"], "String");
      }
      if (data.hasOwnProperty("path")) {
        obj["path"] = ApiClient.convertToType(data["path"], "String");
      }
      if (data.hasOwnProperty("view")) {
        obj["view"] = ApiClient.convertToType(data["view"], "String");
      }
      if (data.hasOwnProperty("extras")) {
        obj["extras"] = ApiClient.convertToType(data["extras"], "String");
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = LocalDateTime.constructFromObject(data["createdAt"]);
      }
      if (data.hasOwnProperty("updatedAt")) {
        obj["updatedAt"] = LocalDateTime.constructFromObject(data["updatedAt"]);
      }
      if (data.hasOwnProperty("deviceEvents")) {
        obj["deviceEvents"] = ApiClient.convertToType(data["deviceEvents"], [
          DeviceEvent
        ]);
      }
      if (data.hasOwnProperty("IgnOnGPS")) {
        obj["IgnOnGPS"] = Gps.constructFromObject(data["IgnOnGPS"]);
      }
      if (data.hasOwnProperty("IgnOffGPS")) {
        obj["IgnOffGPS"] = Gps.constructFromObject(data["IgnOffGPS"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
EngineMgtSummaryEvent.prototype["id"] = undefined;

/**
 * @member {module:model/Device} device
 */
EngineMgtSummaryEvent.prototype["device"] = undefined;

/**
 * @member {module:model/User} user
 */
EngineMgtSummaryEvent.prototype["user"] = undefined;

/**
 * @member {module:model/Vehicle} vehicle
 */
EngineMgtSummaryEvent.prototype["vehicle"] = undefined;

/**
 * @member {module:model/Company} company
 */
EngineMgtSummaryEvent.prototype["company"] = undefined;

/**
 * @member {Number} gpsOdoStart
 */
EngineMgtSummaryEvent.prototype["gpsOdoStart"] = undefined;

/**
 * @member {Number} gpsOdoEnd
 */
EngineMgtSummaryEvent.prototype["gpsOdoEnd"] = undefined;

/**
 * @member {Date} ignitionOn
 */
EngineMgtSummaryEvent.prototype["ignitionOn"] = undefined;

/**
 * @member {Date} ignitionOff
 */
EngineMgtSummaryEvent.prototype["ignitionOff"] = undefined;

/**
 * @member {Boolean} plausible
 */
EngineMgtSummaryEvent.prototype["plausible"] = undefined;

/**
 * @member {Number} fuelConsumptionStart
 */
EngineMgtSummaryEvent.prototype["fuelConsumptionStart"] = undefined;

/**
 * @member {Number} fuelConsumptionEnd
 */
EngineMgtSummaryEvent.prototype["fuelConsumptionEnd"] = undefined;

/**
 * @member {Number} distanceStart
 */
EngineMgtSummaryEvent.prototype["distanceStart"] = undefined;

/**
 * @member {Number} distanceEnd
 */
EngineMgtSummaryEvent.prototype["distanceEnd"] = undefined;

/**
 * @member {Number} engineHourStart
 */
EngineMgtSummaryEvent.prototype["engineHourStart"] = undefined;

/**
 * @member {Number} engineHourEnd
 */
EngineMgtSummaryEvent.prototype["engineHourEnd"] = undefined;

/**
 * @member {String} harshBrake
 */
EngineMgtSummaryEvent.prototype["harshBrake"] = undefined;

/**
 * @member {String} excessiveAccel
 */
EngineMgtSummaryEvent.prototype["excessiveAccel"] = undefined;

/**
 * @member {String} fuelComsumption
 */
EngineMgtSummaryEvent.prototype["fuelComsumption"] = undefined;

/**
 * @member {String} overRevving
 */
EngineMgtSummaryEvent.prototype["overRevving"] = undefined;

/**
 * @member {String} idle
 */
EngineMgtSummaryEvent.prototype["idle"] = undefined;

/**
 * @member {String} vehicleMaintenanceStatus
 */
EngineMgtSummaryEvent.prototype["vehicleMaintenanceStatus"] = undefined;

/**
 * @member {String} oilTemperatureStatus
 */
EngineMgtSummaryEvent.prototype["oilTemperatureStatus"] = undefined;

/**
 * @member {String} coolantTemperatureStatus
 */
EngineMgtSummaryEvent.prototype["coolantTemperatureStatus"] = undefined;

/**
 * @member {Date} timeAt
 */
EngineMgtSummaryEvent.prototype["timeAt"] = undefined;

/**
 * @member {String} startLocation
 */
EngineMgtSummaryEvent.prototype["startLocation"] = undefined;

/**
 * @member {String} endLocation
 */
EngineMgtSummaryEvent.prototype["endLocation"] = undefined;

/**
 * @member {Number} calculatedFuelConsumption
 */
EngineMgtSummaryEvent.prototype["calculatedFuelConsumption"] = undefined;

/**
 * @member {Number} calculatedGpsDiffOdometer
 */
EngineMgtSummaryEvent.prototype["calculatedGpsDiffOdometer"] = undefined;

/**
 * @member {Number} calculatedCanDiffOdometer
 */
EngineMgtSummaryEvent.prototype["calculatedCanDiffOdometer"] = undefined;

/**
 * @member {Number} calculatedGpsDiffEngineHour
 */
EngineMgtSummaryEvent.prototype["calculatedGpsDiffEngineHour"] = undefined;

/**
 * @member {Number} calculatedCanDiffEngineHour
 */
EngineMgtSummaryEvent.prototype["calculatedCanDiffEngineHour"] = undefined;

/**
 * @member {String} diagnosticTroubleCodes
 */
EngineMgtSummaryEvent.prototype["diagnosticTroubleCodes"] = undefined;

/**
 * @member {String} docId
 */
EngineMgtSummaryEvent.prototype["docId"] = undefined;

/**
 * @member {String} path
 */
EngineMgtSummaryEvent.prototype["path"] = undefined;

/**
 * @member {String} view
 */
EngineMgtSummaryEvent.prototype["view"] = undefined;

/**
 * @member {String} extras
 */
EngineMgtSummaryEvent.prototype["extras"] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
EngineMgtSummaryEvent.prototype["createdAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} updatedAt
 */
EngineMgtSummaryEvent.prototype["updatedAt"] = undefined;

/**
 * @member {Array.<module:model/DeviceEvent>} deviceEvents
 */
EngineMgtSummaryEvent.prototype["deviceEvents"] = undefined;

/**
 * @member {module:model/Gps} IgnOnGPS
 */
EngineMgtSummaryEvent.prototype["IgnOnGPS"] = undefined;

/**
 * @member {module:model/Gps} IgnOffGPS
 */
EngineMgtSummaryEvent.prototype["IgnOffGPS"] = undefined;

export default EngineMgtSummaryEvent;
