import React from 'react';
import { EWDAssociateDriverForm } from '../EWDAssociateDriverForm';
import { EWDAssociateUserForm } from '../EWDAssociateUserForm';

export const EWDTab = ({
  userData,
  timezones,
  licenceStateLabel,
  licenceStatePlaceholder,
  states,
  doRefetch,
  ewdDriverUpdateRef,
  onFormUpdated
}) => (
  <div style={{ display: 'flex', paddingBottom: 70 }}>
    <div style={{ width: '100%' }}>
      <EWDAssociateDriverForm
        userData={userData}
        timezones={timezones}
        licenceStateLabel={licenceStateLabel}
        licenceStatePlaceholder={licenceStatePlaceholder}
        states={states}
        doRefetch={doRefetch}
        ewdDriverUpdateRef={ewdDriverUpdateRef}
        onFormUpdated={onFormUpdated}
      />
      <EWDAssociateUserForm userData={userData} timezones={timezones} doRefetch={doRefetch} />
    </div>
  </div>
);
