import React, { Component } from 'react';

import 'react-vis/dist/style.css';
import { RadialChart } from 'react-vis';
import ContentLoader from 'react-content-loader';

import styles from './Gauge.module.scss';

export const DEFAULT_STATE = 'DEFAULT_STATE';
export const FOCUSED_STATE = 'FOCUSED_STATE';
export const UNFOCUSED_STATE = 'UNFOCUSED_STATE';

const GaugePlaceholder = () => (
  <ContentLoader
    height={140}
    width={400}
    speed={2}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    style={{ width: '100%', height: '100%' }}
  >
    <circle cx="206" cy="70" r="46" />
    <rect x="90" y="120" rx="0" ry="0" width="230" height="7" />
  </ContentLoader>
);
class Gauge extends Component {
  state = {
    isMouseHover: false,
    focusedLabelID: -1
  };

  handleMouseOver() {
    this.setState({
      ...this.state,
      isMouseHover: true
    });
  }

  handleMouseOut() {
    this.setState({
      ...this.state,
      isMouseHover: false
    });
  }

  handleClick() {
    let id = this.state.focusedLabelID;
    if (this.state.focusedLabelID === 1) {
      id = -1;
    } else {
      id = 1;
    }

    this.setState({ ...this.state, focusedLabelID: id });
    if (this.props.onLabelClicked) this.props.onLabelClicked(id);
  }

  decorate(data) {
    if (this.state.isMouseHover) {
      data[0].color = this.props.mousehoverColor;
    }
    return data;
  }

  getTotalCount() {
    let totalCount = 0.0;
    this.props.labelData.forEach(d => (totalCount += d.count));
    return totalCount;
  }

  handleOnValueClick(id) {
    if (this.state.focusedLabelID === id) {
      id = -1;
    }
    this.setState({ ...this.state, focusedLabelID: id });
    if (this.props.onLabelClicked) this.props.onLabelClicked(id);
  }

  render() {
    const { isLoading, labelData } = this.props;
    const { focusedLabelID } = this.state;
    let showPiePercentage = this.props.showPiePercentage ?? true;

    const data = [
      { id: 1, angle: this.props.value, color: this.props.color },
      {
        id: 2,
        angle: this.props.max - this.props.value,
        color: '#f7f8f9'
      },
      { id: 3, angle: this.props.max, color: 'transparent' }
    ];

    if (isLoading) return <GaugePlaceholder />;

    return (
      <div className={styles.widgetWrapper}>
        <div className={styles.gaugeWrapper} onClick={() => this.handleClick()}>
          <RadialChart
            className={styles.frontGauge}
            innerRadius={50}
            radius={68}
            data={this.decorate(data)}
            onValueMouseOver={() => this.handleMouseOver()}
            onValueMouseOut={() => this.handleMouseOut()}
            colorType="literal"
            width={200}
            height={160}
            animation={{ damping: 30, stiffness: 300 }}
          />
          <RadialChart
            className={styles.backGauge}
            innerRadius={50}
            radius={68}
            data={data.map(d => ({
              ...d,
              radius: d.id === focusedLabelID ? 1.5 : 1
            }))}
            colorType="literal"
            width={200}
            height={160}
          />
          <div className={styles.gaugeLabel}>
            <h3>
              {focusedLabelID !== -1 && showPiePercentage
                ? (isNaN(parseInt((data[0].angle / this.props.max).toFixed(2) * 100)) ||
                  !isFinite(parseInt((data[0].angle / this.props.max).toFixed(2) * 100))
                    ? 0
                    : parseInt((data[0].angle / this.props.max).toFixed(2) * 100)) + '%'
                : data[0].angle}
            </h3>
            <p>{this.props.message}</p>
          </div>
        </div>
        {labelData && (
          <div className={styles.drilldownWrapper}>
            {labelData.map(d => {
              let isFocused = d.id === focusedLabelID;
              let rowValue = d.count;
              let rowStyle = { borderColor: d.color };

              if (isFocused) {
                rowValue = (rowValue / this.getTotalCount()).toFixed(2) * 100;

                rowStyle = {
                  ...rowStyle,
                  backgroundColor: d.color,
                  color: '#fff'
                };
              }

              return (
                <div
                  key={d.id}
                  className={styles.drilldownRow}
                  style={rowStyle}
                  onClick={() => this.handleOnValueClick(d.id)}
                >
                  <h1>
                    {rowValue}
                    {isFocused && <span>%</span>}
                  </h1>
                  <p>{d.message}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default Gauge;
