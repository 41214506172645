import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import {
  status,
  loadingFailed,
  loadingStarted,
  loadingFinished,
  actionTypes
} from 'utils/reduxFetchingUtils';
import { ewdAPI } from 'features/ewd/endpoints/ewdAPI';
import { services, useCan } from 'features/permissions';
import { useEWDUser } from 'features/user/EWDUserHooks';
import { useEffect, useMemo } from 'react';
import { RULESET_OPTIONS } from 'features/fatigue/constants/Generals';

export const initialState = {
  keepers: {
    byID: {},
    byDrivers: {},
    currentId: null,
    status: status,
    withRulesets: {}
  }
};

const keepersSlice = createSlice({
  name: 'ewdKeepers',
  initialState: initialState,
  reducers: {
    fetchKeepersFailure(state, { payload }) {
      state.keepers.status = loadingFailed(state, payload);
    },
    fetchKeepersStart(state, { payload }) {
      state.keepers.status = loadingStarted(state, payload);
    },
    fetchKeepersSuccess(state, { payload }) {
      state.keepers.byID = keyBy(payload, 'id');
      state.keepers.currentId = payload[0]?.id;
      state.keepers.status = loadingFinished(state, payload);
    },
    fetchDriverKeeperStart(state, { payload }) {
      const driverId = payload;
      if (state.keepers.byDrivers[driverId] == null) {
        state.keepers.byDrivers[driverId] = {};
      }
      state.keepers.byDrivers[driverId].status = loadingStarted(state, payload);
    },
    fetchDriverKeeperSuccess(state, { payload }) {
      const { driverId, keepers } = payload;
      state.keepers.byDrivers[driverId].keepers = keepers;
      state.keepers.byDrivers[driverId].status = loadingFinished(state, payload);
    },
    fetchDriverKeeperFailure(state, { payload }) {
      const { driverId, err } = payload;
      state.keepers.byDrivers[driverId].status = loadingFailed(state, { payload: err });
    },
    fetchKeeperRulesetsStart(state, { payload }) {
      const keeperId = payload;
      if (state.keepers.withRulesets[keeperId] == null) {
        state.keepers.withRulesets[keeperId] = {};
      }
      state.keepers.withRulesets[keeperId].status = loadingStarted(state, payload);
    },
    fetchKeeperRulesetsSuccess(state, { payload }) {
      const { keeperId, rulesets } = payload;
      state.keepers.withRulesets[keeperId].rulesets = rulesets;
      state.keepers.withRulesets[keeperId].status = loadingFinished(state, payload);
    },
    fetchKeeperRulesetsFailure(state, { payload }) {
      const { keeperId, err } = payload;
      state.keepers.withRulesets[keeperId].status = loadingFailed(state, { payload: err });
    }
  }
});

export const {
  fetchKeepersStart,
  fetchKeepersSuccess,
  fetchKeepersFailure,
  fetchDriverKeeperStart,
  fetchDriverKeeperSuccess,
  fetchDriverKeeperFailure,
  fetchKeeperRulesetsStart,
  fetchKeeperRulesetsSuccess,
  fetchKeeperRulesetsFailure
} = keepersSlice.actions;

export const fetchKeepers = () => (dispatch, getState) => {
  const fetching = getState().ewd.keepers.status.fetching;
  const jwt = getState().user?.currentEWDUser?.auth?.token;
  if (jwt && fetching !== actionTypes.processing) {
    try {
      dispatch(fetchKeepersStart());
      const req = ewdAPI.keepers();
      req.then(result => {
        dispatch(fetchKeepersSuccess(result));
      });
    } catch (err) {
      console.error(err);
      dispatch(fetchKeepersFailure(err.toString()));
    }
  }
};

export const fetchEWDDriverKeeper = driverId => (dispatch, getState) => {
  const fetching = getState().ewd.keepers.byDrivers[driverId]?.status?.fetching;
  const jwt = getState().user?.currentEWDUser?.auth?.token;
  if (jwt && fetching !== actionTypes.processing) {
    try {
      dispatch(fetchDriverKeeperStart(driverId));
      const req = ewdAPI.keepersForDriver(driverId);
      req.then(result => {
        dispatch(fetchDriverKeeperSuccess({ driverId, keepers: result }));
      });
    } catch (err) {
      console.error(err);
      dispatch(fetchDriverKeeperFailure({ driverId, err: err.toString() }));
    }
  }
};

const fetchKeeperRulesets = keeperId => (dispatch, getState) => {
  const fetching = getState().ewd.keepers.withRulesets[keeperId]?.status?.fetching;
  const jwt = getState().user?.currentEWDUser?.auth?.token;
  if (jwt && fetching !== actionTypes.processing && !!keeperId) {
    try {
      dispatch(fetchKeeperRulesetsStart(keeperId));
      const req = ewdAPI.keeperRulesets(keeperId);
      req.then(rulesets => {
        dispatch(fetchKeeperRulesetsSuccess({ keeperId, rulesets }));
      });
    } catch (err) {
      console.error(err);
      dispatch(fetchKeeperRulesetsFailure({ keeperId, err: err?.toString() }));
    }
  }
};

export const useEWDKeeperRulesets = keeperId => {
  const can = useCan();
  const dispatch = useDispatch();

  const ewdUser = useEWDUser();
  const jwt = ewdUser?.auth?.token;
  const canEWD = can({ everyService: [services.EWD], extraCheck: !!jwt });

  const keepers = useSelector(state => state.ewd.keepers);
  const keepersRulesets = keepers.withRulesets;
  const shouldFetchKeepers = canEWD && jwt && keepers.status.fetching === actionTypes.init;
  const shouldFetchKeeperRulesets =
    canEWD &&
    jwt &&
    keeperId &&
    (!keepersRulesets?.[keeperId]?.status?.fetching ||
      ![actionTypes.error, actionTypes.done].some(
        state => state === keepersRulesets[keeperId].status.fetching
      ));

  useEffect(() => {
    if (shouldFetchKeepers) {
      dispatch(fetchKeepers());
    }
  }, [shouldFetchKeepers]);

  useEffect(() => {
    if (shouldFetchKeeperRulesets) {
      dispatch(fetchKeeperRulesets(keeperId));
    }
  }, [shouldFetchKeeperRulesets, keeperId]);

  const ewdRulesets = useMemo(() => {
    const rulesets = keepersRulesets?.[keeperId]?.rulesets?.map(ruleset => ({
      ...ruleset,
      value: ruleset.name,
      label: ruleset.name
    }));
    return rulesets || RULESET_OPTIONS;
  }, [keeperId, keepersRulesets]);

  return ewdRulesets;
};

export const useKeepers = () => {
  const keepers = useSelector(state => state.ewd.keepers.byID);
  return keepers;
};

export const useKeeperFetchStatus = () => useSelector(state => state.ewd.keepers.status);

export const useEWDKeeperId = () => {
  const keeperId = useSelector(state => state.ewd.keepers.currentId);
  return keeperId;
};

export const useDriverEWDKeeperId = (driverId, companySlug) => {
  const keeperId = useSelector(
    state =>
      state.ewd.keepers.byDrivers[driverId]?.keepers?.find(k => k.externalId === companySlug)?.id
  );
  return keeperId;
};

export default keepersSlice.reducer;
