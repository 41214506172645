import React from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './AntSearchbar.module.scss';

const { Search } = Input;

const AntSearchbar = ({ className, size = 'large', onFilter, ...props }) => {
  const { t } = useTranslation();

  return (
    <Search
      allowClear
      size={size}
      className={styles.searchbar + ' ' + className}
      placeholder={t('Common.SearchPlaceholder')}
      onChange={e => onFilter(e.target.value)}
      // onSearch={text => onFilter(text)}
      {...props}
    />
  );
};

export default AntSearchbar;
