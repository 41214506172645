import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Modal, Space, Typography } from 'antd';

import styles from '../DevicesBulkEdit.module.scss';

export const EntityInputModal = ({
  show,
  onOk,
  onCancel,
  label,
  placeholder,
  required,
  Component = Input,
  componentProps = {},
  maxLength,
  allowClear,
  validator = value => true
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  return (
    <Modal
      okText={t('Common.Save')}
      cancelText={t('Common.Modal.Cancel')}
      title={t('Devices.BulkEdit.EditColumnTitle')}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      okButtonProps={{
        disabled: (required && !value) || (validator && !validator(value))
      }}
      onOk={() => {
        onOk(value);
        setValue();
      }}
      onCancel={() => {
        setValue();
        onCancel();
      }}
    >
      <Space direction="vertical" size={0} className={styles.entitySelect}>
        <Typography.Title level={5}>{label}</Typography.Title>
        <Component
          allowClear={allowClear}
          value={value}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={e => {
            setValue(e.target.value);
          }}
          {...componentProps}
        />
      </Space>
    </Modal>
  );
};
