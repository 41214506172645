/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Address from "./Address";
import Contact from "./Contact";
import Geofence from "./Geofence";
import LocationType from "./LocationType";
import Position from "./Position";

/**
 * The Location model module.
 * @module model/Location
 * @version 1.0
 */
class Location {
  /**
   * Constructs a new <code>Location</code>.
   * @alias module:model/Location
   */
  constructor() {
    Location.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Location</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Location} obj Optional instance to populate.
   * @return {module:model/Location} The populated <code>Location</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Location();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("path")) {
        obj["path"] = ApiClient.convertToType(data["path"], "String");
      }
      if (data.hasOwnProperty("version")) {
        obj["version"] = ApiClient.convertToType(data["version"], "Number");
      }
      if (data.hasOwnProperty("companyId")) {
        obj["companyId"] = ApiClient.convertToType(data["companyId"], "Number");
      }
      if (data.hasOwnProperty("customerId")) {
        obj["customerId"] = ApiClient.convertToType(
          data["customerId"],
          "Number"
        );
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = LocationType.constructFromObject(data["type"]);
      }
      if (data.hasOwnProperty("address")) {
        obj["address"] = Address.constructFromObject(data["address"]);
      }
      if (data.hasOwnProperty("siteNotes")) {
        obj["siteNotes"] = ApiClient.convertToType(data["siteNotes"], "String");
      }
      if (data.hasOwnProperty("externalId")) {
        obj["externalId"] = ApiClient.convertToType(
          data["externalId"],
          "String"
        );
      }
      if (data.hasOwnProperty("verify")) {
        obj["verify"] = ApiClient.convertToType(data["verify"], "Boolean");
      }
      if (data.hasOwnProperty("sync")) {
        obj["sync"] = ApiClient.convertToType(data["sync"], "Boolean");
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("timeZone")) {
        obj["timeZone"] = ApiClient.convertToType(data["timeZone"], "String");
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = ApiClient.convertToType(data["createdAt"], "String");
      }
      if (data.hasOwnProperty("updatedAt")) {
        obj["updatedAt"] = ApiClient.convertToType(data["updatedAt"], "String");
      }
      if (data.hasOwnProperty("geofences")) {
        obj["geofences"] = ApiClient.convertToType(data["geofences"], [
          Geofence
        ]);
      }
      if (data.hasOwnProperty("contacts")) {
        obj["contacts"] = ApiClient.convertToType(data["contacts"], [Contact]);
      }
      if (data.hasOwnProperty("GPS")) {
        obj["GPS"] = Position.constructFromObject(data["GPS"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Location.prototype["id"] = undefined;

/**
 * @member {String} name
 */
Location.prototype["name"] = undefined;

/**
 * @member {String} path
 */
Location.prototype["path"] = undefined;

/**
 * @member {Number} version
 */
Location.prototype["version"] = undefined;

/**
 * @member {Number} companyId
 */
Location.prototype["companyId"] = undefined;

/**
 * @member {Number} customerId
 */
Location.prototype["customerId"] = undefined;

/**
 * @member {module:model/LocationType} type
 */
Location.prototype["type"] = undefined;

/**
 * @member {module:model/Address} address
 */
Location.prototype["address"] = undefined;

/**
 * @member {String} siteNotes
 */
Location.prototype["siteNotes"] = undefined;

/**
 * @member {String} externalId
 */
Location.prototype["externalId"] = undefined;

/**
 * @member {Boolean} verify
 */
Location.prototype["verify"] = undefined;

/**
 * @member {Boolean} sync
 */
Location.prototype["sync"] = undefined;

/**
 * @member {module:model/Location.StatusEnum} status
 */
Location.prototype["status"] = undefined;

/**
 * @member {String} timeZone
 */
Location.prototype["timeZone"] = undefined;

/**
 * @member {String} createdAt
 */
Location.prototype["createdAt"] = undefined;

/**
 * @member {String} updatedAt
 */
Location.prototype["updatedAt"] = undefined;

/**
 * @member {Array.<module:model/Geofence>} geofences
 */
Location.prototype["geofences"] = undefined;

/**
 * @member {Array.<module:model/Contact>} contacts
 */
Location.prototype["contacts"] = undefined;

/**
 * @member {module:model/Position} GPS
 */
Location.prototype["GPS"] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Location["StatusEnum"] = {
  /**
   * value: "ENABLED"
   * @const
   */
  ENABLED: "ENABLED",

  /**
   * value: "DISABLED"
   * @const
   */
  DISABLED: "DISABLED",

  /**
   * value: "READY_FOR_DOWNLOAD"
   * @const
   */
  READY_FOR_DOWNLOAD: "READY_FOR_DOWNLOAD",

  /**
   * value: "PENDING"
   * @const
   */
  PENDING: "PENDING",

  /**
   * value: "AVAILABLE"
   * @const
   */
  AVAILABLE: "AVAILABLE",

  /**
   * value: "UNAVAILABLE"
   * @const
   */
  UNAVAILABLE: "UNAVAILABLE",

  /**
   * value: "FAILED"
   * @const
   */
  FAILED: "FAILED"
};

export default Location;
