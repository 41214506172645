import { SortUtil } from 'components/tables/SortUtil';
import { normalCellRenderer, stateCellRenderer } from './CellRender';

import i18next from 'i18nextConfig';

export const LogExportGridColumns = [
  {
    title: '',
    key: '',
    width: 24,
    render: stateCellRenderer
  },
  {
    get title() {
      return i18next.t('ELD.Log Type');
    },
    key: 'logTypeDesc',
    dataIndex: 'logTypeDesc',
    render: normalCellRenderer,
    defaultSortColumn: false,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'logTypeDesc'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    canSearch: true,
    width: 100
  },
  {
    get title() {
      return i18next.t('ELD.Date Range of Export Logs');
    },
    key: 'dateRanges',
    dataIndex: 'dateRanges',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'periodBegin'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    canSearch: true
  },
  {
    get title() {
      return i18next.t('ELD.Driver/Vehicle');
    },
    key: 'driver_vehicle',
    dataIndex: 'driver_vehicle',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'driver_vehicle'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    canSearch: true
  },
  {
    get title() {
      return i18next.t('ELD.Comments');
    },
    key: 'comment',
    dataIndex: 'comment',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'comment'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    canSearch: true
  },
  {
    get title() {
      return i18next.t('ELD.Log Transfer To');
    },
    key: 'logExportToDesc',
    dataIndex: 'logExportToDesc',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'logExportToDesc'),
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    get title() {
      return i18next.t('ELD.Updated Date');
    },
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortDate(a, b, 'updatedAt'),
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    get title() {
      return i18next.t('ELD.Transfer Status');
    },
    key: 'transferStatus',
    dataIndex: 'transferStatus',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'transferStatus'),
    sortDirections: ['ascend', 'descend', 'ascend']
  }
  /*
      {
        title: expandableCellHeader,
        key: 'expand',
        dataIndex: 'expand',
        render: expandableCellRender,
        colSpan: 2,
        canSearch: false,
      }*/
];

export const LogExportSubGridColumns = [
  {
    title: '',
    key: '',
    width: 64,
    render: stateCellRenderer
  },
  {
    get title() {
      return i18next.t('ELD.Log Transfer To');
    },
    key: 'logExportToDesc',
    dataIndex: 'logExportToDesc',
    render: normalCellRenderer
  },
  {
    get title() {
      return i18next.t('ELD.Updated Date');
    },
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    render: normalCellRenderer
  },
  {
    get title() {
      return i18next.t('ELD.Transfer Status');
    },
    key: 'transferStatus',
    dataIndex: 'transferStatus',
    render: normalCellRenderer
  }
];
