import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import './NavLinks.scss';

export const ButtonNavLink = ({ ...props }) => (
  <RouterLink {...props} className={'tn-navlink-btn'} activeClassName={'tn-active'} />
);

export const TabNavLink = ({ ...props }) => (
  <RouterLink {...props} className={'tn-navlink-tab'} activeClassName={'tn-active'} />
);

export const MenuNavLink = ({ ...props }) => (
  <RouterLink {...props} className={'tn-menulink-tab'} activeClassName={'tn-active-menu'} />
);

export const IconNavLink = ({ icon, ...props }) => (
  <RouterLink {...props} className={'tn-icon-tab'} activeClassName={'tn-active'}>
    <span className="tab-icon">{icon}</span>
  </RouterLink>
);
