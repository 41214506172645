import React from 'react';

import styles from './MassManagement.module.scss';

const TabHeader = ({ tabs, toolbar, ...props }) => {
  return (
    <header className={styles.tabHeader} {...props}>
      <div className={styles.tabs}>{tabs}</div>

      <div className={styles.toolbar}>{toolbar}</div>
    </header>
  );
};

export default TabHeader;
