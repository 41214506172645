import React, { useState, useEffect, useRef } from 'react';
import { Button, Tooltip } from 'antd';
import { CopyOutlined, CheckCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styles from './CopyToClipboardButton.module.scss';

export const CopyToClipboardButton = ({ tooltip, value, ...props }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [tooltipToShow, setTooltipToShow] = useState(tooltip);
  const { t } = useTranslation();
  const copyTimerRef = useRef();
  const renameTimerRef = useRef();

  useEffect(() => {
    return () => {
      if (copyTimerRef?.current) {
        clearTimeout(copyTimerRef.current);
      }

      if (renameTimerRef?.current) {
        clearTimeout(renameTimerRef.current);
      }
    };
  }, []);

  const copyText = () => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);

    copyTimerRef.current = setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  useEffect(() => {
    if (isCopied) {
      setTooltipToShow(t('Common.Copied'));
    } else {
      setTooltipToShow(tooltip);
    }
  }, [isCopied]);

  const onTooltipVisibilityChanged = opened => {
    if (!opened) {
      //this to let tooltip disposed first before we change text
      renameTimerRef.current = setTimeout(() => {
        setIsCopied(false);
      }, 100);
    }
  };

  return (
    <Tooltip
      title={tooltipToShow}
      onOpenChange={onTooltipVisibilityChanged}
      mouseLeaveDelay={isCopied ? 0.5 : 0}
    >
      <Button
        disabled={value === undefined}
        type="link"
        onClick={copyText}
        className={styles.copyButton}
        icon={
          isCopied ? <CheckCircleFilled className={styles.copySuccessIcon} /> : <CopyOutlined />
        }
      />
    </Tooltip>
  );
};
