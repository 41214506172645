const ALERT_ACTIONS = {
  SWITCH_ACTIVE: 'ACTIVE_SWITCH',
  SWITCH_MOBILE: 'PHONE_SWITCH',
  SWITCH_MAIL: 'MAIL_SWITCH',
  SWITCH_DESKTOP: 'DESKTOP_SWITCH',
  SET_DEVICES: 'SET_DEVICES_NUMBER',
  SET_VEHICLES: 'SET_VEHICLES_NUMBER',
  SET_FLEETS: 'SET_FLEETS_NUMBER',
  SET_DRIVERS: 'SET_DRIVERS_NUMBER',
  SET_BRANCHES: 'SET_BRANCHES_NUMBER',
  SET_USERS: 'SET_USERS_NUMBER',
  SET_FORMS: 'SET_FORMS_NUMBER',
  SET_GEOFENCES: 'SET_GEOFENCES_NUMBER',
  SET_DOCUMENTEXPIRATIONFOLDERS: 'SET_DOCUMENTEXPIRATIONFOLDERS_NUMBER',
  SET_MANAGED_GEOFENCES: 'SET_MANAGED_GEOFENCES_NUMBER',
  SET_PRETRIPS: 'SET_PRETRIPS_NUMBER',
  SET_INSPECTIONS_CHECKBOX: 'SET_INSPECTIONS_CHECKBOX',
  SET_NAME: 'SET_NAME',
  SET_EXPIRY: 'SET_EXPIRY',
  SET_DEVICE_TYPE: 'SET_DEVICE_TYPE',
  SET_METER_TYPE: 'SET_METER_TYPE',
  SET_METER_ENTITY_TYPE: 'SET_METER_ENTITY_TYPE',
  SET_METER_SOURCE: 'SET_METER_SOURCE',
  SET_METER_OPERATION: 'SET_METER_OPERATION',
  SET_METER_VALUE: 'SET_METER_VALUE',
  SET_SPEED_LIMIT: 'SET_SPEED_LIMIT',
  SET_EVENT_CHECKBOX: 'SET_EVENT_CHECKBOX',
  SET_SECOND_ALERT_USERS: 'SET_SECOND_ALERT_USERS_NUMBER',
  SET_THIRD_ALERT_USERS: 'SET_THIRD_ALERT_USERS_NUMBER',
  SET_SECOND_ALERT_TIMER: 'SET_SECOND_ALERT_TIMER',
  SET_THIRD_ALERT_TIMER: 'SET_THIRD_ALERT_TIMER',
  SET_SECOND_ALERT_SWITCH: 'SET_SECOND_ALERT_SWITCH',
  SET_THIRD_ALERT_SWITCH: 'SET_THIRD_ALERT_SWITCH',
  SET_ROUTE_COMPLIANCE_CHECKBOX: 'SET_ROUTE_COMPLIANCE_CHECKBOX',
  SET_SENTINEL_CHECKBOX: 'SET_SENTINEL_CHECKBOX',
  SET_SPEED_CHECKBOX: 'SET_SPEED_CHECKBOX',
  SET_DRIVER_AND_VEHICLE_CHECKBOX: 'SET_DRIVER_AND_VEHICLE_CHECKBOX',
  SET_SMARTJOBS_CHECKBOX: 'SET_SMARTJOBS_CHECKBOX',
  SET_GEOFENCES_CHECKBOX: 'SET_GEOFENCES_CHECKBOX',
  SET_MANAGED_GEOFENCES_CHECKBOX: 'SET_MANAGED_GEOFENCES_CHECKBOX',
  SET_INPUT_NUMBER: 'SET_INPUT_NUMBER',
  SET_ELD_CHECKBOX_VIOLATIONS: 'SET_ELD_CHECKBOX_VIOLATIONS',
  SET_ELD_CHECKBOX_WARNINGS: 'SET_ELD_CHECKBOX_WARNINGS',
  SET_GPIO_TYPES: 'SET_GPIO_TYPES',
  SET_PAYLOAD: 'SET_PAYLOAD',
  SET_SCHEDULER_CELLS: 'SET_SCHEDULER_CELLS',
  SET_DURESS_TYPES: 'SET_DURESS_TYPES',
  SET_TIMEZONE: 'SET_TIMEZONE',
  SET_TACHO_CHECKBOX: 'SET_TACHO_CHECKBOX'
};

export default ALERT_ACTIONS;
