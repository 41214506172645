import React, { useMemo, useState } from 'react';
import { Marker, OverlayView } from 'react-google-maps';

import { getEventAttributesByType } from 'containers/Tracking/EventTypes';
import { useLocalization } from 'features/localization/localizationSlice';
import EventInfoWindow from '../info-windows/EventInfoWindow';
import moment from 'moment';
import { RequestFootageModal } from 'features/requestFootage/RequestFootageModal';

const findGPSByTimeAt = ({ timeAt, tripReplay = [] }) => {
  if (!tripReplay?.length || !timeAt) {
    return;
  }
  let matched,
    minAbsTimestampDiff = tripReplay.reduce(
      ({ endAt, startAt, duration }, replay) => {
        if (replay.Lat && replay.Lng && replay.At) {
          return {
            endAt: Math.max(endAt, replay.At),
            startAt: Math.min(startAt, replay.At),
            duration: Math.max(endAt, replay.At) - Math.min(startAt, replay.At)
          };
        }
        return {
          endAt,
          startAt,
          duration
        };
      },
      { duration: 0, startAt: tripReplay.find(r => r.At).At, endAt: tripReplay.find(r => r.At).At }
    ).duration;

  for (const replay of tripReplay) {
    if (replay.Lat && replay.Lng && replay.At) {
      if (Math.abs(replay.At - timeAt) < minAbsTimestampDiff) {
        minAbsTimestampDiff = Math.abs(replay.At - timeAt);
        matched = replay;
      }
    }
  }
  return matched;
};

export const EventMarker = ({
  event,
  onClicked,
  onFocused,
  onBlurred,
  onCloseClick,
  can,
  focusedEvent,
  selectedTripEvent,
  trip
}) => {
  const localization = useLocalization();
  const [showVRModal, setShowVRModal] = useState(false);

  const evtGPSWithFallback = useMemo(() => {
    if (!(event?.GPS?.Lat && event?.GPS?.Lng)) {
      if (event?.eventType === 'CAMERA') {
        return findGPSByTimeAt({ timeAt: event.timeAt, tripReplay: trip?.replay });
      }
    }
    return event?.GPS;
  }, [event, trip?.replay]);

  if (!(evtGPSWithFallback?.Lat && evtGPSWithFallback?.Lng)) return null;

  return (
    <>
      <Marker
        position={{
          lat: evtGPSWithFallback.Lat,
          lng: evtGPSWithFallback.Lng
        }}
        icon={{
          url: require('./images/' +
            getEventAttributesByType(event.eventType, event.subType).marker),
          anchor: { x: 16, y: 16 }
        }}
        zIndex={moment().valueOf()}
        clickable={onClicked ? true : false}
        onClick={() => {
          onClicked(event);
        }}
        onMouseOver={() => {
          onFocused && onFocused(event);
        }}
        onMouseOut={() => {
          onBlurred && onBlurred(event);
        }}
      >
        {(event === selectedTripEvent || event === focusedEvent) && (
          <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={() => ({ x: 42, y: 42 })}
            position={{
              lat: evtGPSWithFallback.Lat,
              lng: evtGPSWithFallback.Lng
            }}
          >
            <EventInfoWindow
              event={event}
              localization={localization}
              can={can}
              disableAutoPan={event === focusedEvent}
              onCloseClick={onCloseClick}
              onRequestEvtVideo={() => setShowVRModal(true)}
            />
          </OverlayView>
        )}
      </Marker>
      {showVRModal && (
        <RequestFootageModal
          showModal={showVRModal}
          deviceId={event?.device?.id}
          vehicleId={event?.vehicle?.id}
          dateTime={moment(event?.timeAt)}
          onClose={() => setShowVRModal(false)}
        />
      )}
    </>
  );
};
