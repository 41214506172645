import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'utils/api';
import {
  FETCH_CONTACTS,
  ADD_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  RESTORE_CONTACT
} from '../actions';
import { getLocationIDFromPathname } from 'containers/Administration/Locations/helpers';
import { getIDFromPathname } from 'utils/methods';

export const fetchContacts = createAsyncThunk(
  FETCH_CONTACTS,
  async (locationId, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const companyKey = getState().companies.current.api_key;

    const response = await api.get(`/locations/${locationId}/contacts`, {
      authKey
    });

    return { list: response.body, companyKey, locationId };
  }
);

export const addContact = createAsyncThunk(
  ADD_CONTACT,
  async (payload = {}, { getState, dispatch }) => {
    const locationId = getIDFromPathname(window.location.pathname);
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const { body } = payload;

    const response = await api.post(
      `/locations/${locationId}/contacts`,
      {
        authKey
      },
      body
    );

    // update contact
    await dispatch(fetchContacts(locationId));

    return response.body;
  }
);

export const updateContact = createAsyncThunk(
  UPDATE_CONTACT,
  async (payload = {}, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const { id, locationId, body } = payload;

    const response = await api.put(
      `/locations/${locationId}/contacts/${id}`,
      {
        authKey
      },
      body
    );

    // update contact
    await dispatch(fetchContacts(locationId));

    // Show toast
    return response.body;
  }
);

export const deleteContact = createAsyncThunk(
  DELETE_CONTACT,
  async (id, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const locationId = getLocationIDFromPathname(window.location.pathname);
    const response = await api.delete(`/locations/${locationId}/contacts/${id}`, {
      authKey
    });

    // update contact
    await dispatch(fetchContacts(locationId));

    return response.body;
  }
);

export const restoreContact = createAsyncThunk(
  RESTORE_CONTACT,
  async (payload = {}, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const { id, locationId, body } = payload;

    const response = await api.put(
      `/locations/${locationId}/contacts/${id}/restore`,
      {
        authKey
      },
      body
    );

    // update contact
    await dispatch(fetchContacts(locationId));

    // Show toast
    return response.body;
  }
);
