import React, { useMemo, useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { groupBy } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLocalization } from 'features/localization/localizationSlice';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { useVehicleMeters } from 'features/vehicles/vehiclesMetersSlice';
import { useDeviceMeters } from 'features/devices/devicesMetersSlice';
import { useUser } from 'features/user/userSlice';
import { api } from 'utils/api';
import { useDevices } from 'features/fleets/fleetsSlice';
import {
  MeterType,
  formatMeterValue,
  getMeterBySource,
  getVehicleOdometer,
  MeterSource
} from 'features/meters';

import { ViewDriverLink } from 'components/sentinel/ViewDriverLink';
import { format, formatTimeago } from 'utils/dates';

import styles from './Trips.module.scss';
import { DriverName } from '../Common/DiverName';
import { FeatureFlag, useCan, services } from 'features/permissions';
import { useGetDeviceGPIOQuery } from 'services/nextgen';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';

export const VehicleDetails = ({ device }) => {
  const [meters, setMeters] = useState();
  const [meta, setMeta] = useState();
  const [hasMeters, setHasMeters] = useState(false);
  const [hasMeterMetadata, setHasMeterMetadata] = useState(false);
  const [hasEdrDevice, setHasEdrDevice] = useState(false);
  const [rucOdometer, setRucOdometer] = useState();

  const { t } = useTranslation();
  const localization = useLocalization();
  const userPreferences = useUserPreferences();
  const vehicleMeters = useVehicleMeters(device?.vehicleId);
  const deviceMeters = useDeviceMeters(device?.id);
  const user = useUser();
  const devices = useDevices();
  const can = useCan();
  const types = useDeviceTypesList();

  const gpio = useGetDeviceGPIOQuery(
    { id: device?.id },
    {
      skip: !device?.id,
      pollingInterval: 30000
    }
  );

  const applyMeterMetadata = (meters, metadata) => {
    setMeta(metadata);
    metadata.monitoringPoints &&
      metadata.monitoringPoints.forEach(meta => {
        const source = [meta.name.replace(' ', '_'), meta.placement].join('_');
        const meter = meters.find(m => m.source === source);
        const meterSetPoint = meters.find(meter => meter.source === source + '_set_point');
        if (meter) {
          meter.meta = meta;
          meter.setPoint = meterSetPoint?.value;
          setHasMeterMetadata(true);
        }
      });
  };

  const lastGpioImmobilize = useMemo(() => {
    const isConfigured =
      gpio?.data?.outputs?.find(i => i.description === 'immobilise') !== undefined;
    const isHermes =
      types.find(type => type.id === device?.type?.id)?.name?.toUpperCase() === 'HERMES';

    if (
      device.deviceStats.lastGpio &&
      isConfigured &&
      device.services?.includes(services.GPIO) &&
      isHermes
    ) {
      try {
        let lastGpioJson = JSON.parse(device.deviceStats.lastGpio);

        const filteredData = Object.values(lastGpioJson).filter(item => item.noun === 'immobilise');
        const latestItem = filteredData.sort(
          (a, b) => new Date(b.eventat) - new Date(a.eventat)
        )[0];
        return latestItem;
      } catch (ex) {
        //parse json fail, leave it
      }
    }
  }, [device.deviceStats, gpio]);

  useEffect(() => {
    let metadata = {};
    let groupedMeters = vehicleMeters?.length
      ? groupBy(vehicleMeters, 'type')
      : deviceMeters?.length
      ? groupBy(deviceMeters, 'type')
      : null;

    if (!groupedMeters) {
      return;
    }

    try {
      groupedMeters = JSON.parse(JSON.stringify(groupedMeters)); // enable prop extension
      if (deviceMeters?.length) {
        groupedMeters.temperature = groupBy(deviceMeters, 'type').temperature; // use Device Meter Temperature data
      }
      groupedMeters = JSON.parse(JSON.stringify(groupedMeters)); // enable prop extension
      metadata = device.deviceStats?.auxInfo ? JSON.parse(device.deviceStats?.auxInfo) : null;
      if (metadata) {
        applyMeterMetadata(groupedMeters.temperature, metadata);
      }
    } catch (error) {
      console.log(error);
    }

    if (groupedMeters.temperature) {
      groupedMeters.temperature = groupedMeters.temperature.filter(
        // filter set-point Device Meters
        meter => meter.source && !meter.source.endsWith('set_point')
      );
    }

    setMeters(groupedMeters);

    if (Object.keys(groupedMeters).length > 0) {
      setHasMeters(true);
    }
  }, [vehicleMeters, deviceMeters]);

  const odometer = useMemo(() => {
    const meterOdometer = device?.vehicleStats?.meters?.find(
      meter => meter.type === MeterType.Odometer
    );

    const odometer = getVehicleOdometer(
      meterOdometer,
      device?.vehicle?.engineSummarySource,
      device?.vehicleStats?.canOdometer,
      device?.vehicleStats?.canDiffOdometer,
      device?.vehicleStats?.gpsOdometer
    );
    return odometer;
  }, [device?.vehicleStats, device?.vehicle?.engineSummarySource]);

  useEffect(() => {
    const vehicleDevices = async () => {
      if (device?.vehicle?.id) {
        try {
          await api
            .get(`/vehicles/${device.vehicle?.id}/devices`, { authKey: user.auth.key })
            .then(res => (res.status === 200 ? res.body || [] : []))
            .then(vehicleDevices => {
              vehicleDevices.forEach(device => {
                if (devices.some(d => d.id === device.id && d.type.code === 'EDR')) {
                  setHasEdrDevice(true);
                  api
                    .get(`/devices/${device.id}/meters`, { authKey: user.auth.key })
                    .then(response => {
                      if (response.status === 200) {
                        const meter = { meters: response.body.flat() || [] };
                        const rucDeviceMeter = getMeterBySource(meter, MeterSource.Ruc);
                        if (rucDeviceMeter) {
                          const deviceRucMeterValue = formatMeterValue(
                            localization,
                            rucDeviceMeter
                          );
                          setRucOdometer(deviceRucMeterValue);
                        }
                      }
                    });
                }
              });
            });
        } catch (error) {
          console.log(error);
        }
      }
    };
    vehicleDevices();
  }, [device, user, devices]);

  const getTemperatureWithMetadata = (meter, type) => {
    const meterZone = { ...meter?.meta?.zone };

    return {
      mode: meterZone?.mode && meterZone?.mode.toLowerCase() !== 'null' ? meterZone.mode : null,
      name: meter?.meta?.name,
      placement: meter?.meta?.placement,
      setPoint:
        meter?.setPoint !== 'undefined'
          ? localization.formatTemperature(meter?.setPoint) // use DeviceMeter value, if available
          : meterZone && meterZone['set-point']
          ? localization.formatTemperature(meterZone['set-point'])
          : null,
      type: meter?.customType || type,
      updatedAt: meter.updatedAt,
      value: formatMeterValue(localization, meter)
    };
  };

  const getTemperatureWithNoMetadata = (meter, type) => ({
    operatingModel: meta?.operatingModel,
    source: meter?.source,
    type: meter?.customType || type,
    unitModeDetail: meta?.unitModeDetail,
    updatedAt: meter?.updatedAt,
    value: formatMeterValue(localization, meter)
  });

  const getMetersData = (meter, type) => ({
    source: meter?.source,
    type: meter?.customType || type,
    updatedAt: meter?.updatedAt,
    value: formatMeterValue(localization, meter)
  });

  const simplifiedVehicleMeters =
    meters &&
    Object.keys(meters).reduce((simplifiedMeters, type) => {
      if (type === MeterType.Temperature && hasMeterMetadata) {
        meters[type].forEach(meter => {
          simplifiedMeters.push(getTemperatureWithMetadata(meter, type));
        });
      } else if (type === MeterType.Temperature && !hasMeterMetadata) {
        meters[type].forEach(meter => {
          simplifiedMeters.push(getTemperatureWithNoMetadata(meter, type));
        });
      } else {
        meters[type].forEach(meter => {
          simplifiedMeters.push(getMetersData(meter, type));
        });
      }
      return simplifiedMeters;
    }, []);

  if (!device) {
    return null;
  }

  const MetersDetails = props => {
    const { meters } = props;

    return (
      <>
        {meters.map(
          meter =>
            meter.type !== MeterType.Temperature && (
              <div className={styles.detailsRow} key={`${meter.source}_${meter.type}`}>
                <div className={styles.detailsRowLabel}>
                  {t(`Tracking.VehicleDetails.${meter.type}`, meter.type)}
                </div>
                <div>{`${meter.value} ${meter.source ? ` (${meter.source})` : ''}`}</div>
              </div>
            )
        )}
      </>
    );
  };

  const TemperatureDetails = props => {
    const { meters } = props;

    return (
      <div className={styles.detailsRow}>
        <div className={styles.detailsRowLabel}>{t(`Tracking.VehicleDetails.temperature`)}</div>
        <div className={styles.temperaturesDetails}>
          {meters.map(
            meter =>
              meter.type === MeterType.Temperature && (
                <div
                  key={
                    meter.name && meter.placement
                      ? `${meter.name}-${meter.placement}`
                      : `${meter.source}`
                  }
                >
                  <div>
                    <div>{formatTimeago(meter.updatedAt)}</div>
                    {moment.duration(moment().diff(meter.updatedAt)).asMilliseconds() >
                      userPreferences?.temperatureTimeout && (
                      <div className={styles['temperaturesDetails--temperatureTimeIconContainer']}>
                        <Tooltip
                          placement="bottom"
                          title={t('Tracking.VehicleDetails.TemperatureTimeoutTooltip')}
                          overlayInnerStyle={{ textAlign: 'center' }}
                        >
                          <InfoCircleOutlined
                            className={styles['temperaturesDetails--timeoutIcon']}
                          />
                        </Tooltip>
                        {t('Tracking.VehicleDetails.TemperatureTimeoutDescription')}
                      </div>
                    )}
                  </div>
                  <div className={styles['temperaturesDetails--meterDetails']}>
                    <div className={styles.tripFontWeight}>{meter.name || meter.source}</div>
                    <div>{meter.placement}</div>
                    <div>
                      {`${meter.value} ${
                        meter.mode || meter.setPoint
                          ? `(${meter.mode ? meter.mode : ''}${
                              meter.mode && meter.setPoint ? `, ` : ''
                            }${meter.setPoint ? meter.setPoint : ''})`
                          : ''
                      }`}
                    </div>
                  </div>
                </div>
              )
          )}
          {meta?.operatingMode1 && (
            <div className={styles['temperaturesDetails--nonValueMeterDetails']}>
              <div className={styles.tripFontWeight}>operatingModel</div>
              <div>{meta?.operatingMode1}</div>
            </div>
          )}
          {meta?.unitModeDetail && (
            <div className={styles['temperaturesDetails--nonValueMeterDetails']}>
              <div className={styles.tripFontWeight}>unitModeDetail</div>
              <div>{meta?.unitModeDetail}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={styles.detailsRowHeader}>
        <div>{t('Common.Vehicle')}</div>
      </div>
      {device.vehicle && (
        <div className={styles.detailsRow}>
          <div className={styles.detailsRowLabel}>{t('Common.Name')}</div>
          <Link to={'/settings/vehicles/id/' + device.vehicle.id}>{device.vehicle.name}</Link>
        </div>
      )}
      <div className={styles.detailsRow}>
        <div className={styles.detailsRowLabel}>{t('Common.Driver')}</div>
        <DriverName device={device}>
          {device.driver ? (
            <ViewDriverLink driverId={device.driver.id}>
              {({ viewDriverLink }) => <Link to={viewDriverLink}>{device.driverName}</Link>}
            </ViewDriverLink>
          ) : (
            <div>{device.driverName}</div>
          )}
        </DriverName>
      </div>
      <div
        className={`${
          !device?.vehicleStats ? styles['detailsRow--noBorderBottom'] : styles.detailsRow
        }`}
      >
        <div className={styles.detailsRowLabel}>
          {device.fleets.length > 1 ? t('Common.Fleets') : t('Common.Fleet')}
        </div>
        <div className={styles.tripRightContent}>
          {device.fleets.map(fleet => (
            <div key={fleet.id ?? 'No Fleet'}>
              {fleet.id && fleet.id !== -1 ? (
                <Link to={'/settings/fleets/id/' + fleet.id}>{fleet.name}</Link>
              ) : (
                fleet.name
              )}
            </div>
          ))}
        </div>
      </div>
      {device.vehicleStats && (
        <div className={styles['detailsRow--noBorderBottom']}>
          <div className={styles.detailsRowLabel}>{t('Tracking.Odometer')}</div>
          <div>{localization.formatDistance(odometer || 0)}</div>
        </div>
      )}

      <div className={styles.detailsRowHeader}>
        <div>{t('Common.Device')}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsRowLabel}>{t('Common.Name')}</div>
        <Link to={'/settings/devices/id/' + device.id}>{device.name}</Link>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsRowLabel}>{t('Common.Type')}</div>
        <div>{device.type.name}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsRowLabel}>{t('Devices.IMEI')}</div>
        <div>{device.imei}</div>
      </div>
      <div className={styles['detailsRow--noBorderBottom']}>
        <div className={styles.detailsRowLabel}>{t('Common.Model')}</div>
        <div>
          {device.model.name?.toLowerCase() === 'iqcamera' ? 'IQ Camera' : device.model.name}
        </div>
      </div>

      <div className={styles.detailsRowHeader}>
        <div className={styles.detailsRowLabel}>{t('Common.LastUpdate')}</div>
        <div>{formatTimeago(device.deviceStats.lastEventAt)}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsRowLabel}>{t('Tracking.LastKnownLocation')}</div>
        <div>{device.deviceStats.location}</div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsRowLabel}>{t('Tracking.LastContact')}</div>
        <div>
          {device.deviceStats.lastCommsAt
            ? format(
                moment(device.deviceStats.lastCommsAt).toDate(),
                localization.formats.time.formats.dby_imp
              )
            : '-'}
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsRowLabel}>{t('Tracking.LastEvent')}</div>
        <div>
          {device.deviceStats.lastEventAt
            ? format(
                moment(device.deviceStats.lastEventAt).toDate(),
                localization.formats.time.formats.dby_imp
              )
            : '-'}
        </div>
      </div>
      <div className={styles.detailsRow}>
        <div className={styles.detailsRowLabel}>{t('Tracking.IgnitionStatus')}</div>
        <div>{device.deviceStats.ignition}</div>
      </div>
      {can({ featureFlag: FeatureFlag.trackingEnhancement.flag }) && !!lastGpioImmobilize && (
        <div className={styles.detailsRow}>
          <div className={styles.detailsRowLabel}>
            {`${
              lastGpioImmobilize?.custom_noun
                ? lastGpioImmobilize?.custom_noun
                : t(`Alerts.GPIO.immobilizer`, lastGpioImmobilize?.noun || '')
            } ${t('Tracking.Status')}`}
          </div>
          <div>
            {lastGpioImmobilize?.custom_verb
              ? lastGpioImmobilize?.custom_verb?.toUpperCase()
              : t(
                  `Alerts.GPIO.${lastGpioImmobilize?.verb}`,
                  lastGpioImmobilize?.verb || ''
                ).toUpperCase()}
          </div>
        </div>
      )}
      {device.type && device.type.code === 'ATRACKER' && (
        <div className={styles.detailsRow}>
          <div className={styles.detailsRowLabel}>{t('Tracking.BatteryStatus')}</div>
          <div>
            {device.deviceStats.batteryStatus
              ? t('Tracking.Battery.' + device.deviceStats.batteryStatus)
              : '-'}
          </div>
        </div>
      )}
      <div
        className={`${
          !device.lastEdr && !hasEdrDevice
            ? styles['detailsRow--noBorderBottom']
            : styles.detailsRow
        }`}
      >
        <div className={styles.detailsRowLabel}>{t('Tracking.Speed')}</div>
        <div>
          {(localization.convertSpeed(
            device.deviceStats.gps.Spd < 0 ? 0 : device.deviceStats.gps.Spd
          ) || '-') +
            ' ' +
            localization.formats.speed.unit_per_hour}
        </div>
      </div>
      {device.lastRuc && (
        <div className={styles.detailsRow}>
          <div className={styles.detailsRowLabel}>{t('Tracking.RUCStatus')}</div>
          <div>
            {device.lastRuc +
              (device.lastRucAt &&
                ' (' +
                  format(
                    moment(device.lastRucAt).toDate(),
                    localization.formats.time.formats.dby_imp
                  ) +
                  ')')}
          </div>
        </div>
      )}
      {device.lastEdr && (
        <div
          className={`${hasEdrDevice ? styles.detailsRow : styles['detailsRow--noBorderBottom']}`}
        >
          <div className={styles.detailsRowLabel}>{t('Tracking.EDRStatus')}</div>
          <div>
            {device.lastEdr +
              (device.lastEdrAt &&
                ' (' +
                  format(
                    moment(device.lastEdrAt).toDate(),
                    localization.formats.time.formats.dby_imp
                  ) +
                  ')')}
          </div>
        </div>
      )}

      {hasEdrDevice && (
        <div className={styles['detailsRow--noBorderBottom']}>
          <div className={styles.detailsRowLabel}>{t('VehicleMntSchedules.Table.RucOdometer')}</div>
          <div>{rucOdometer}</div>
        </div>
      )}

      {hasMeters && meters && (
        <>
          <div className={styles.detailsRowHeader}>
            <div className={styles.detailsRowLabel}>
              {vehicleMeters?.length
                ? t('Tracking.VehicleDetails.VehicleMeters')
                : t('Tracking.VehicleDetails.DeviceMeters')}
            </div>
            {meta?.EventDateTime && <div>{formatTimeago(meta.EventDateTime)}</div>}
          </div>
          <MetersDetails meters={simplifiedVehicleMeters} />
          {simplifiedVehicleMeters.some(meter => meter.type === MeterType.Temperature) && (
            <TemperatureDetails meters={simplifiedVehicleMeters} />
          )}
        </>
      )}
    </>
  );
};
