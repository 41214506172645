import i18n from 'i18nextConfig';
import { ALERT_LINKS, ALERT_NAMES, ALERT_TYPES } from '../constants';

export const getTypeFromLink = link => {
  switch (link) {
    case ALERT_LINKS.CAMERA:
      return ALERT_TYPES.CAMERA;
    case ALERT_LINKS.HARSHDRIVING:
      return ALERT_TYPES.ENGINE;
    case ALERT_LINKS.FORM:
      return ALERT_TYPES.FORM;
    case ALERT_LINKS.GEOFENCE:
      return ALERT_TYPES.GEOFENCE;
    case ALERT_LINKS.PRETRIP:
      return ALERT_TYPES.PRETRIP;
    case ALERT_LINKS.ROUTE:
      return ALERT_TYPES.ROUTE;
    case ALERT_LINKS.SENTINEL:
      return ALERT_TYPES.SENTINEL;
    case ALERT_LINKS.SPEED:
      return ALERT_TYPES.SPEED;
    case ALERT_LINKS.VEHICLE_MAINTENANCE:
      return ALERT_TYPES.VEHICLE_MAINTENANCE;
    case ALERT_LINKS.DRIVER_MANAGEMENT:
      return ALERT_TYPES.DRIVER_MANAGEMENT;
    case ALERT_LINKS.DRIVER_AND_VEHICLE:
      return ALERT_TYPES.DRIVER_AND_VEHICLE;
    case ALERT_LINKS.SMARTJOBS:
      return ALERT_TYPES.SMARTJOBS;
    case ALERT_LINKS.DOCUMENTEXPIRATION:
      return ALERT_TYPES.DOCUMENTEXPIRATION;
    case ALERT_LINKS.OOH:
      return ALERT_TYPES.OOH;
    case ALERT_LINKS.ELD:
      return ALERT_TYPES.ELD;
    case ALERT_LINKS.MASS_MANAGEMENT:
      return ALERT_TYPES.MASS_MANAGEMENT;
    case ALERT_LINKS.METER:
      return ALERT_TYPES.METER;
    case ALERT_LINKS.GPIO:
      return ALERT_TYPES.GPIO;
    case ALERT_LINKS.DURESS:
      return ALERT_TYPES.DURESS;
    case ALERT_LINKS.TACHO:
      return ALERT_TYPES.TACHO;
    default:
      return '';
  }
};

export const getNameFromLink = link => {
  switch (link) {
    case ALERT_LINKS.CAMERA:
      return ALERT_NAMES.CAMERA;
    case ALERT_LINKS.HARSHDRIVING:
      return ALERT_NAMES.HARSHDRIVING;
    case ALERT_LINKS.FORM:
      return ALERT_NAMES.FORM;
    case ALERT_LINKS.GEOFENCE:
      return ALERT_NAMES.GEOFENCE;
    case ALERT_LINKS.PRETRIP:
      return ALERT_NAMES.PRETRIP;
    case ALERT_LINKS.ROUTE:
      return ALERT_NAMES.ROUTE;
    case ALERT_LINKS.SENTINEL:
      return ALERT_NAMES.SENTINEL;
    case ALERT_LINKS.SPEED:
      return ALERT_NAMES.SPEED;
    case ALERT_LINKS.VEHICLE_MAINTENANCE:
      return ALERT_NAMES.VEHICLE_MAINTENANCE;
    case ALERT_LINKS.DRIVER_MANAGEMENT:
      return ALERT_NAMES.DRIVER_MANAGEMENT;
    case ALERT_LINKS.DRIVER_AND_VEHICLE:
      return ALERT_NAMES.DRIVER_AND_VEHICLE;
    case ALERT_LINKS.OOH:
      return ALERT_NAMES.OOH;
    case ALERT_LINKS.SMARTJOBS:
      return ALERT_NAMES.SMARTJOBS;
    case ALERT_LINKS.DOCUMENTEXPIRATION:
      return ALERT_NAMES.DOCUMENTEXPIRATION;
    case ALERT_LINKS.ELD:
      return ALERT_NAMES.ELD;
    case ALERT_LINKS.MASS_MANAGEMENT:
      return ALERT_NAMES.MASS_MANAGEMENT;
    case ALERT_LINKS.METER:
      return ALERT_NAMES.METER;
    case ALERT_LINKS.GPIO:
      return ALERT_NAMES.GPIO;
    case ALERT_LINKS.DURESS:
      return ALERT_NAMES.DURESS;
    case ALERT_LINKS.TACHO:
      return ALERT_NAMES.TACHO;
    default:
      return '';
  }
};

export const getAddTitleFromLink = link => {
  switch (link) {
    case ALERT_LINKS.CAMERA:
      return i18n.t('Alerts.Add.CameraAlert');
    case ALERT_LINKS.HARSHDRIVING:
      return i18n.t('Alerts.Add.HarshDriving');
    case ALERT_LINKS.FORM:
      return i18n.t('Alerts.Add.FormAlert');
    case ALERT_LINKS.GEOFENCE:
      return i18n.t('Alerts.Add.GeofenceAlert');
    case ALERT_LINKS.PRETRIP:
      return i18n.t('Alerts.Add.PreTripChecklistAlert');
    case ALERT_LINKS.ROUTE:
      return i18n.t('Alerts.Add.RouteComplianceAlert');
    case ALERT_LINKS.SENTINEL:
      return i18n.t('Alerts.Add.SentinelAlert');
    case ALERT_LINKS.SPEED:
      return i18n.t('Alerts.Add.SpeedAlert');
    case ALERT_LINKS.VEHICLE_MAINTENANCE:
      return i18n.t('Alerts.Add.VehicleMaintenanceAlert');
    case ALERT_LINKS.DRIVER_AND_VEHICLE:
      return i18n.t('Alerts.Add.DriverAndVehicleAlert');
    case ALERT_LINKS.OOH:
      return i18n.t('Alerts.Add.OOH');
    case ALERT_LINKS.DRIVER_MANAGEMENT:
      return i18n.t('Alerts.Add.DriverManagementAlert');
    case ALERT_LINKS.SMARTJOBS:
      return i18n.t('Alerts.Add.SmartjobsAlert');
    case ALERT_LINKS.DOCUMENTEXPIRATION:
      return i18n.t('Alerts.Add.DocumentExpirationAlert');
    case ALERT_LINKS.ELD:
      return i18n.t('Alerts.Add.ELDAlert');
    case ALERT_LINKS.MASS_MANAGEMENT:
      return i18n.t('Alerts.Add.MassManagementAlert');
    case ALERT_LINKS.METER:
      return i18n.t('Alerts.Add.MeterAlert');
    case ALERT_LINKS.GPIO:
      return i18n.t('Alerts.Add.GpioAlert');
    case ALERT_LINKS.DURESS:
      return i18n.t('Alerts.Add.DuressAlert');
    case ALERT_LINKS.TACHO:
      return i18n.t('Alerts.Add.TachoAlert');
    default:
      return '';
  }
};
