/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Job from "./Job";

/**
 * The JobAttribute model module.
 * @module model/JobAttribute
 * @version 1.0
 */
class JobAttribute {
  /**
   * Constructs a new <code>JobAttribute</code>.
   * @alias module:model/JobAttribute
   */
  constructor() {
    JobAttribute.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>JobAttribute</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/JobAttribute} obj Optional instance to populate.
   * @return {module:model/JobAttribute} The populated <code>JobAttribute</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new JobAttribute();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("job")) {
        obj["job"] = Job.constructFromObject(data["job"]);
      }
      if (data.hasOwnProperty("key")) {
        obj["key"] = ApiClient.convertToType(data["key"], "String");
      }
      if (data.hasOwnProperty("value")) {
        obj["value"] = ApiClient.convertToType(data["value"], "String");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
JobAttribute.prototype["id"] = undefined;

/**
 * @member {module:model/Job} job
 */
JobAttribute.prototype["job"] = undefined;

/**
 * @member {String} key
 */
JobAttribute.prototype["key"] = undefined;

/**
 * @member {String} value
 */
JobAttribute.prototype["value"] = undefined;

export default JobAttribute;
