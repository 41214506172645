import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { Alert, Button, Divider } from 'antd';
import { Select } from 'components/ant';
import { BulkManagementModal } from './components/Modal/ImportModal';
import { BulkManagementTable } from './components/Table/BulkManagementTable';
import { ExportModal } from './components/Modal/ExportModal';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';

import useDebounce from 'utils/hooks/useDebounce';
import { entities, Can, useCan } from 'features/permissions';
import { fileDownload } from './utils/fileDownload';
import { STATUS_SELECT_PLACEHOLDER, TYPE_SELECT_PLACEHOLDER } from './utils/constants';
import { setBackButton, setPageTitle, addBreadcrumbs } from 'features/page/pageSlice';
import {
  getBulkErrors,
  useBulkData,
  useTemplates,
  useIsFetchingBulkData
} from 'features/bulk_management';
import { useCurrentCompany } from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { filterBulkData } from './utils/helpers';
import { PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';
import styles from './BulkManagement.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const BulkManagement = () => {
  const { t } = useTranslation();
  const currentCompany = useCurrentCompany();
  const dispatch = useDispatch();
  const localization = useLocalization();
  const location = useLocation();

  const bulkData = useBulkData();
  const templates = useTemplates();
  const isFetchingBulkData = useIsFetchingBulkData();

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 300);
  const [statusFilter, setStatusFilter] = useState(null);
  const [typeFilter, setTypeFilter] = useState(null);
  const [showImportModal, setShowImportModal] = useState(location?.state?.showImportModal || false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [filteredBulkData, setFilteredBulkData] = useState(null);
  const can = useCan();

  useEffect(() => {
    dispatch(setPageTitle(t('Common.BulkManagement')));
    dispatch(setBackButton(false));

    dispatch(
      addBreadcrumbs([
        {
          breadcrumbName: t('CompanyConfig.Title'),
          path: PATHS.COMPANY_CONFIG
        },
        {}
      ])
    );

    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (location?.state?.showImportModal) {
      dispatch(setBackButton(true));
    }
  }, [location]);

  useEffect(() => {
    setFilteredBulkData(
      filterBulkData({ bulkData, debouncedSearchText, typeFilter, statusFilter })
    );
  }, [bulkData, debouncedSearchText, statusFilter, typeFilter]);

  const onSearch = value => setSearchText(value);

  const onStatusChange = value => setStatusFilter(value);

  const onTypeChange = value => setTypeFilter(value);

  const handleOpenImportModal = () => {
    setShowImportModal(true);
  };

  const handleOpenExportModal = () => {
    setShowExportModal(true);
  };

  const handleErrorFileDownload = async ({ entry }) => {
    const errorFile = await dispatch(
      getBulkErrors({
        companyId: currentCompany?.id,
        apiEndpoint: 'errors',
        itemId: entry?.id,
        responseType: 'blob'
      })
    );
    fileDownload(errorFile.payload, `${entry.status}_${entry.fileName}`);
  };

  const availableTypes = TYPE_SELECT_PLACEHOLDER().filter(t => {
    return !t.permissions || can(t.permissions);
  });

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.buttonsWrapper}>
        <Can oneOfEntities={[entities.LOCATION_CREATE, entities.GEOFENCE_CREATE]}>
          <Button
            type="secondary"
            id={BUTTON_IDS.bulkManagementImportOpen}
            onClick={handleOpenImportModal}
          >
            {t('CompanyConfig.BulkManagement.ImportButton')}
          </Button>
        </Can>
        <Can oneOfEntities={[entities.LOCATION, entities.GEOFENCE]}>
          <Button
            type="secondary"
            id={BUTTON_IDS.bulkManagementExportOpen}
            onClick={handleOpenExportModal}
          >
            {t('CompanyConfig.BulkManagement.ExportButton')}
          </Button>
        </Can>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.infoWrapper}>
        <Alert
          message={
            <Trans
              i18nKey={'CompanyConfig.BulkManagement.BulkInfo'}
              values={{ error: 'Error File', status: 'Status' }}
            />
          }
          showIcon
        />
      </div>
      <div className={styles.filterWrapper}>
        <AntSearchbar className={styles.filterElement} size={'default'} onFilter={onSearch} />
        <Select
          className={styles.statusesSelect}
          data={STATUS_SELECT_PLACEHOLDER()}
          onSelect={onStatusChange}
          placeholder={t('CompanyConfig.BulkManagement.AllStatuses')}
        />
        <Select
          className={styles.statusesSelect}
          data={availableTypes}
          onSelect={onTypeChange}
          placeholder={t('CompanyConfig.BulkManagement.AllTypes')}
        />
        <div className={styles.counter}>{`${filteredBulkData?.length} ${
          filteredBulkData?.length === 1
            ? t('CompanyConfig.BulkManagement.CounterImport')
            : t('CompanyConfig.BulkManagement.CounterImports')
        }`}</div>
      </div>
      <div className={styles.tableWrapper}>
        <BulkManagementTable
          bulkData={filteredBulkData}
          handleErrorFileDownload={handleErrorFileDownload}
          loading={isFetchingBulkData}
          localization={localization}
        />
      </div>
      <BulkManagementModal
        handleShow={setShowImportModal}
        show={showImportModal}
        templates={templates}
      />
      <ExportModal show={showExportModal} handleShow={setShowExportModal} />
    </div>
  );
};
