import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventStatusToRequestStatus } from '../constant';
import styles from './RequestStatus.module.scss';

export function RequestStatus({ event, ...prop }) {
  const { t } = useTranslation();
  const makeStatus = useCallback(
    requestStatus => {
      const s = {
        desc: null,
        styleClass: null
      };

      if (EventStatusToRequestStatus[requestStatus]) {
        s.desc = t('Footage.RequestStatus.' + EventStatusToRequestStatus[requestStatus]);
        const mappedStatus = EventStatusToRequestStatus[requestStatus];
        switch (mappedStatus) {
          case EventStatusToRequestStatus.AVAILABLE:
          case EventStatusToRequestStatus.ENABLED:
            s.styleClass = styles.enabled;
            break;
          case EventStatusToRequestStatus.INPROGRESS:
          case EventStatusToRequestStatus.REQUESTED:
            s.styleClass = styles.pending;
            break;
          case EventStatusToRequestStatus.FAILED:
            s.styleClass = styles.failed;
            break;
          default:
            break;
        }
      } else {
        s.desc = t('Footage.RequestStatus.Pending');
        s.styleClass = styles.pending;
      }
      return s;
    },
    [t]
  );

  const [reqStatus, setRequStatus] = useState(makeStatus(event.videoRequestStatus));
  useEffect(() => {
    setRequStatus(makeStatus(event.videoRequestStatus));
  }, [makeStatus, event.videoRequestStatus]);
  return (
    <div>
      <FontAwesomeIcon className={reqStatus.styleClass} icon={faCircle} />
      <span>{reqStatus.desc}</span>
    </div>
  );
}
