import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Tabs, Tab, Badge, Table } from 'react-bootstrap';
import moment from 'moment';
import { useLocalization } from 'features/localization/localizationSlice';
import { setEventId } from 'features/sentinel/components/events/eventsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faPencilAlt,
  faShip,
  faLock
} from '@fortawesome/free-solid-svg-icons';
import { fetchEventAudits, useSentinelAudits } from './audits/auditsSlice';
import { useActiveRuleset } from './rulesets/rulesetsSlice';
import { useDriverTimezone } from './user/sentinelUserSlice';
import SentinelAddEditFormik from './SentinelAddEditFormik';
import { cloneDeep } from 'lodash';
import debounce from 'lodash/debounce';
import SentinelDeleteFormik from './SentinelDeleteFormik';
import { LoadingCentered } from 'components/loading/Loading';
import { useDevices, useVehicles } from 'features/fleets/fleetsSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

const DropdownButton = props => (
  <button
    id={BUTTON_IDS.sentinelTableDropdown}
    onClick={() => props.handleClick()}
    className="btn btn-none stretched-link"
  >
    <FontAwesomeIcon icon={props.open ? faAngleUp : faAngleDown} />
  </button>
);

const AuditLog = props => {
  const audits = props.audits;
  const driverTimezone = useDriverTimezone();
  const localization = useLocalization();
  if (audits && audits.isLoading) {
    return (
      <Row className="justify-content-md-center">
        <Col className="p-3" xs="auto">
          <LoadingCentered />
        </Col>
      </Row>
    );
  }

  if (audits && audits.length > 0) {
    let rows = [];
    const displayedChanges = [
      'DeviceId',
      'Action',
      'EventAt',
      'EntryAt',
      'Timezone',
      'DeclaredLocation',
      'Location',
      'DeclaredRegistration',
      'Odo',
      'DeclaredOdometer',
      'Notes'
    ];
    audits.forEach(audit => {
      const changes = JSON.parse(audit.parameters);
      let changeTable = [];
      changeTable.push(
        <tr>
          {displayedChanges.map(title => {
            let original = Array.isArray(changes[title]) ? changes[title][0] : ' ';
            let change = Array.isArray(changes[title]) ? changes[title][1] : false;
            if (title === 'EventAt' && changes.EventDateTime) {
              // munge data
              original = Array.isArray(changes.EventDateTime) ? changes.EventDateTime[0] : ' ';
              change = Array.isArray(changes.EventDateTime) ? changes.EventDateTime[1] : false;
            }

            if (title === 'EventAt' || title === 'EntryAt') {
              if (moment(original).isValid()) {
                original = moment(original)
                  .tz(driverTimezone)
                  .format(localization.formats.time.formats.bdo_hm_z);
              }
            }
            return (
              <td key={title}>
                <span className={change ? 'alert-danger px-1' : ''}>{original}&nbsp;</span>
              </td>
            );
          })}
        </tr>
      );
      changeTable.push(
        <tr>
          {displayedChanges.map(title => {
            let change = Array.isArray(changes[title]) ? changes[title][1] : changes[title];
            if (title === 'EventAt' || title === 'EntryAt') {
              if (moment(change).isValid()) {
                change = moment(change)
                  .tz(driverTimezone)
                  .format(localization.formats.time.formats.bdo_hm_z);
              }
            }
            return (
              <td>
                <span className={change ? 'alert-primary px-1' : ''}>{change}</span>
              </td>
            );
          })}
        </tr>
      );
      rows.push(
        <Row key={audit.id}>
          <Col>
            <Row>
              <Col className="pt-3" xs="auto">
                <p className="m-0">
                  <strong>User:</strong>{' '}
                  <a href={'/ng/admin/users/' + audit.user.id}>
                    {audit.user.firstName + ' ' + audit.user.lastName}
                  </a>
                  <br />
                  <strong>Action:</strong> {audit.action}
                  <br />
                  <strong>Changes:</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Table size="sm" className="change-log-table">
                <thead>
                  <tr>
                    <th width="6%">Device Id</th>
                    <th width="6%">Action</th>
                    <th width="13%">Event Date Time</th>
                    <th width="13%">EntryAt</th>
                    <th width="8%">Timezone</th>
                    <th width="10%">Declared Location</th>
                    <th width="10%">Location</th>
                    <th width="8%">Declared Registration</th>
                    <th width="8%">Odometer</th>
                    <th width="8%">Declared Odometer</th>
                    <th width="10%">Note</th>
                  </tr>
                </thead>
                <tbody>{changeTable}</tbody>
              </Table>
            </Row>
          </Col>
        </Row>
      );
    });
    return <>{rows}</>;
  }
  return (
    <Row className="justify-content-md-center">
      <Col className="p-3" xs="auto">
        <p>No Audits for this event</p>
      </Col>
    </Row>
  );
};

const SentinelEventTabs = ({ event }) => {
  const [key, setKey] = useState('auditLog');
  const audits = useSentinelAudits(event.id);
  const dispatch = useDispatch();
  const isEditabled = useActiveRuleset('editable');
  const isDeletable = useActiveRuleset('deletable');

  const setTabKey = key => {
    setKey(key);
  };

  useEffect(() => {
    if (key === 'auditLog') {
      dispatch(fetchEventAudits(event.id));
    }
  }, [dispatch, event.id, key]);

  return (
    <Tabs
      className="table-tabs"
      defaultActiveKey="auditLog"
      activeKey={key}
      onSelect={k => setTabKey(k)}
    >
      <Tab eventKey="auditLog" title="Audit Log">
        <AuditLog audits={audits} />
      </Tab>
      {isEditabled && (
        <Tab eventKey="edit" title="Edit">
          <Row className="justify-content-md-center">
            <Col className="p-3" xs="auto">
              <SentinelAddEditFormik initialValues={event} setOpen={() => {}} />
            </Col>
          </Row>
        </Tab>
      )}
      {isEditabled && isDeletable && (
        <Tab eventKey="delete" title="Delete">
          <Row className="justify-content-md-center">
            <Col className="p-3" xs="auto">
              <SentinelDeleteFormik initialValues={event} />
            </Col>
          </Row>
        </Tab>
      )}
    </Tabs>
  );
};

const EdittedIndicator = ({ event }) => {
  if (event.status === 'M') {
    return (
      <>
        {' '}
        <Badge variant="secondary">
          <FontAwesomeIcon icon={faPencilAlt} title="Edited" /> Edited
        </Badge>
      </>
    );
  }
  return <></>;
};
const FerryIndicator = ({ event }) => {
  if (event.params) {
    if (event.params.includes('{"nzta.ferry":true}')) {
      return (
        <>
          {' '}
          <Badge variant="primary">
            <FontAwesomeIcon icon={faShip} title="Ferry Crossing" /> Ferry
          </Badge>
        </>
      );
    }
  }

  return <></>;
};
const LockedIndicator = ({ event }) => {
  if (event.status === 'L') {
    return (
      <>
        {' '}
        <Badge variant="primary">
          <FontAwesomeIcon icon={faLock} title="Locked Record" /> Locked
        </Badge>
      </>
    );
  }

  return <></>;
};

const EventOdometer = ({ event }) => {
  if (event.declaredOdometer) {
    const declaredOdometer =
      event.declaredOdometer === 'NaN' ? null : parseInt(event.declaredOdometer, 10);
    let value = ' - ';
    if (declaredOdometer > 0) {
      value = declaredOdometer;
    }
    return <span>{value}</span>;
  }
  return <span> - </span>;
};

const eventSource = (event, vehicle, device) => {
  let source = '';
  if (vehicle != null) {
    source = vehicle.name + ' | ';
  } else if (device != null) {
    source = device.name + ' | ';
  }

  if (event.declaredRegistration) {
    source += event.declaredRegistration;
  } else {
    source += event.source;
  }

  return source;
};

const eventAction = event => {
  const ActionMap = {
    LogoffDriver: 'Driver Logoff',
    VehicleChangeover: 'Vehicle Change',
    LogonDriver: 'Driver Logon'
  };
  return ActionMap[event?.action] || event?.action;
};

const EventAt = (event, driverTimezone, localization, audits) => {
  const lastAudit =
    audits?.length > 0
      ? Array.from(audits).sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))[0]
      : null;
  if (event.entryAt && event.version > 0 && lastAudit) {
    return (
      <>
        {moment(event.eventAt)
          .tz(driverTimezone)
          .format(localization.formats.time.formats.bdo_hm_z)}
        <br />
        <small className="text-info">
          Recorded:{' '}
          {moment(lastAudit?.createdAt || event.updatedAt || event.entryAt)
            .tz(driverTimezone)
            .format(localization.formats.time.formats.bdo_hm_z)}
        </small>{' '}
        <small>{driverTimezone}</small>
      </>
    );
  }
  return (
    <>
      {moment(event.eventAt)
        .tz(driverTimezone)
        .format(localization.formats.time.formats.bdo_hm_z)}
      <br />
      <small>{driverTimezone}</small>
    </>
  );
};

const EventLocation = event => {
  if (!event.declaredLocation) {
    return <>{event.location}</>;
  }
  if (event.location && event.location !== event.declaredLocation) {
    return (
      <>
        {event.declaredLocation}
        <br />
        <small className="text-info">Recorded: {event.location}</small>
      </>
    );
  }
  return <>{event.declaredLocation}</>;
};

const EventRow = ({ event, driverTimezone, localization }) => {
  const vehicles = useVehicles();
  const devices = useDevices();
  const audits = useSentinelAudits(event.id);
  const vehicle = vehicles?.find(v => event?.vehicle?.id != null && v.id === event?.vehicle?.id);
  const device = devices?.find(d => d.id === event?.device?.id);
  const dispatch = useDispatch();

  useEffect(() => {
    if (event?.entryAt && event?.version > 0) {
      dispatch(fetchEventAudits(event.id));
    }
  }, [event, dispatch]);

  return useMemo(
    () => (
      <>
        <Col className="col-name">
          {eventAction(event)}
          <FerryIndicator event={event} />
          <EdittedIndicator event={event} />
          <LockedIndicator event={event} />
        </Col>
        <Col className="col">{EventAt(event, driverTimezone, localization, audits)}</Col>
        <Col>
          <EventOdometer event={event} />
        </Col>
        <Col className="col-location">{EventLocation(event)}</Col>
        <Col className="col-comments">{event.notes}</Col>
        <Col className="col-source">{eventSource(event, vehicle, device)}</Col>
      </>
    ),
    [event, driverTimezone, vehicle, device, audits]
  );
};

const SentinelTable = () => {
  const dispatch = useDispatch();
  const currentEvents = useSelector(state => state.sentinel.currentEvents);
  const driverTimezone = useDriverTimezone();
  const [open, setOpen] = useState({});
  const localization = useLocalization();

  const events = useMemo(() => {
    return cloneDeep(currentEvents).reverse();
  }, [currentEvents]);

  const debouncedHover = debounce((action, Id, dispatch) => {
    if (action === 'enter') {
      dispatch(setEventId('show#!event-' + Id));
    }
    if (action === 'leave') {
      dispatch(setEventId('hide#!event-' + Id));
    }
  }, 100);

  const hoverHandler = (action, Id) => {
    debouncedHover(action, Id, dispatch);
  };

  const handleClick = id => {
    let openClone = { ...open };
    openClone[id] = !openClone[id];
    setOpen(openClone);
  };

  return (
    <Row className="table-row">
      <Col className="table-row-col">
        <Row className="table-header-row">
          <Col className="col-name">Name</Col>
          <Col className="col-time-of-activity">Time of Activity</Col>
          <Col className="col">Odometer</Col>
          <Col className="col-location">Location</Col>
          <Col className="col-comments">Comments</Col>
          <Col className="col-source">Source</Col>
          <Col className="flex-shrink-1 col-actions"></Col>
        </Row>
        {events &&
          events.map((event, index) => (
            <Row
              className={
                open[event.id]
                  ? 'table-body-row table-body-row--active align-items-center'
                  : 'table-body-row align-items-center'
              }
              key={index}
              onMouseEnter={() => hoverHandler('enter', event.id)}
              onMouseLeave={() => hoverHandler('leave', event.id)}
            >
              <EventRow event={event} driverTimezone={driverTimezone} localization={localization} />
              <Col className="flex-shrink-1 col-actions" style={{ position: 'relative' }}>
                <DropdownButton
                  id={event.id}
                  handleClick={() => handleClick(event.id)}
                  open={open[event.id] ? true : false}
                />
              </Col>
              {open[event.id] && (
                <Col xs={12}>
                  <SentinelEventTabs event={event} />
                </Col>
              )}
            </Row>
          ))}
      </Col>
    </Row>
  );
};

export default SentinelTable;
