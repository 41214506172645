import React, { useEffect, useMemo, useState } from 'react';
import { Document, Page, Text, View, StyleSheet, pdf } from '@react-pdf/renderer';
import { Button } from 'antd';
import moment from 'moment';
import { MESSAGE_STATUE, ENTITY_DIVIDER } from './constants';
import {
  chatMsgsSortFunc,
  isRouteToMessage,
  getMessageBody,
  getMessageSubject,
  getWrappedChatEntity
} from './helpers';
import { format } from 'utils/dates';
import { BUTTON_IDS } from 'utils/globalConstants';

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    marginHorizontal: 10,
    fontSize: 12
  },
  header: {
    width: 575,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 13,
    marginVertical: 20
  },
  section: {
    width: 575,
    borderWidth: 1,
    borderColor: '#000000',
    borderStyle: 'solid',
    marginBottom: 15
  },
  sectionMeta: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#e8ebee',
    borderBottomStyle: 'solid'
  },
  sectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#e8ebee',
    borderBottomStyle: 'solid'
  },
  sectionTitleCol: {
    display: 'flex',
    flexDirection: 'column'
  },
  sectionTitleColDateTime: {
    display: 'flex',
    flexDirection: 'column',
    color: '#2b2a2a'
  },
  sectionTitleColItem: {
    marginVertical: 5
  },
  sectionBody: {
    padding: 10
  },
  footer: {
    color: '#5a5a5a',
    width: 575,
    marginVertical: 10,
    textAlign: 'center',
    fontSize: 9,
    bottom: 0,
    position: 'absolute'
  }
});
const company = 'Teletrac Navman';
const product = 'TN360';
const currentYear = moment().get('year');
const sortedMessages = messages => {
  return messages.sort(chatMsgsSortFunc);
};

// Create Document Component
const MsgsPDF = ({ t, chatEntity, messages, dateFormat, dateTimeFormat }) => {
  messages = sortedMessages(messages);
  const today = format(moment().toDate(), dateFormat);
  const dateTimeFormater = isoStr => {
    try {
      return format(new Date(isoStr), dateTimeFormat);
    } catch (error) {
      //console.error(error)
      return '';
    }
  };
  const messageCompanyName = msg => msg.company?.name || '';
  const recipients = msg => {
      const recipients = msg.recipients || []; //Recipient already filtered out the sender
      const recipientsNames =
        recipients.length > 0
          ? recipients.length > 2
            ? `${recipients
                .slice(0, 2)
                .map(recipient => getWrappedChatEntity(recipient).chatEntityName)
                .join(ENTITY_DIVIDER)} , ${t('Messaging.More', { count: recipients.length - 2 })}`
            : recipients
                .map(recipient => getWrappedChatEntity(recipient)?.chatEntityName)
                .join(ENTITY_DIVIDER)
          : '';
      const msgFromDevice = !isRouteToMessage(msg) && !!msg.device;
      const msgCompanyName = messageCompanyName(msg);
      //if message sent by device which means it was sent to the company
      return msgFromDevice ? msgCompanyName : `${recipientsNames || ''}`;
    },
    senderName = msg =>
      msg.messageSender?.senderName || `${msg.sender.firstName} ${msg.sender.lastName}`,
    messageStatus = msg => {
      const ret = [];
      if (msg.messageStatus.find(s => s.status === MESSAGE_STATUE.SENT)) {
        ret.push(
          <Text key={MESSAGE_STATUE.SENT} style={styles.sectionTitleColItem}>
            {`${t(`Messaging.STATUS.${MESSAGE_STATUE.SENT}`)} : ${dateTimeFormater(
              msg.messageStatus.find(s => s.status === MESSAGE_STATUE.SENT).dateTime.isoStr
            )}`}
          </Text>
        );
      }
      if (
        msg.messageStatus.find(s => s.status === MESSAGE_STATUE.READ) &&
        !msg.messageStatus.find(s => s.status === MESSAGE_STATUE.RECEIVED)
      ) {
        ret.push(
          <Text key={MESSAGE_STATUE.READ} style={styles.sectionTitleColItem}>
            {`${t(`Messaging.STATUS.${MESSAGE_STATUE.READ}`)} : ${dateTimeFormater(
              msg.messageStatus.find(s => s.status === MESSAGE_STATUE.READ).dateTime.isoStr
            )}`}
          </Text>
        );
        return ret;
      } else {
        const hasReceived = msg.messageStatus.find(s => s.status === MESSAGE_STATUE.RECEIVED);
        if (hasReceived) {
          ret.push(
            <Text key={MESSAGE_STATUE.RECEIVED} style={styles.sectionTitleColItem}>
              {`${t(`Messaging.STATUS.${MESSAGE_STATUE.RECEIVED}`)} : ${dateTimeFormater(
                hasReceived.dateTime.isoStr
              )}`}
            </Text>
          );
        } else {
          ret.push(
            <Text key={MESSAGE_STATUE.RECEIVED} style={styles.sectionTitleColItem}>{`${t(
              `Messaging.STATUS.${MESSAGE_STATUE.RECEIVED}`
            )} : ${t('Forms.ActualForm.No')}`}</Text>
          );
        }
        const hasRead = msg.messageStatus.find(s => s.status === MESSAGE_STATUE.READ);
        if (hasRead) {
          ret.push(
            <Text key={MESSAGE_STATUE.READ} style={styles.sectionTitleColItem}>
              {`${t(`Messaging.STATUS.${MESSAGE_STATUE.READ}`)} : ${dateTimeFormater(
                hasRead.dateTime.isoStr
              )}`}
            </Text>
          );
        } else {
          ret.push(
            <Text key={MESSAGE_STATUE.READ} style={styles.sectionTitleColItem}>{`${t(
              `Messaging.STATUS.${MESSAGE_STATUE.READ}`
            )} : ${t('Forms.ActualForm.No')}`}</Text>
          );
        }
        return ret;
      }
    };

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.header} fixed>
          <Text>{today}</Text>
          <Text>{product}</Text>
        </View>
        {messages.map(msg => (
          <View key={msg.id} style={styles.section} wrap={false}>
            <View style={styles.sectionMeta}>
              <Text>
                {t('Messaging.Recipients')}: {recipients(msg)}
              </Text>
            </View>
            <View style={styles.sectionTitle}>
              <View style={styles.sectionTitleCol}>
                <Text style={styles.sectionTitleColItem}>
                  {t('Messaging.Subject')} : {msg.subject}
                </Text>
                <Text style={styles.sectionTitleColItem}>
                  {`${t('Forms.View.Company')} : ${messageCompanyName(msg)}`}
                </Text>
                <Text style={styles.sectionTitleColItem}>
                  {t('Messaging.Sender')} : {senderName(msg)}
                </Text>
              </View>
              <View style={styles.sectionTitleColDateTime}>
                {messageStatus(msg).map(status => status)}
              </View>
            </View>
            <MessageBody {...{ message: msg, t }} />
          </View>
        ))}
        <View style={styles.footer} fixed>
          <Text>&#169; {`${company} - ${product} - ${currentYear}`}</Text>
        </View>
      </Page>
    </Document>
  );
};

export const PrintBtn = ({
  t,
  messages,
  chatEntity,
  onPrint,
  icon,
  dateFormat,
  dateTimeFormat
}) => {
  const [pdfDoc, setPDFDoc] = useState(
    <MsgsPDF {...{ t, chatEntity, messages, dateFormat, dateTimeFormat }} />
  );
  useEffect(() => {
    setPDFDoc(<MsgsPDF {...{ t, chatEntity, messages, dateFormat, dateTimeFormat }} />);
  }, [t, chatEntity, messages, dateFormat, dateTimeFormat]);

  const [pdfFile, setPDFFile] = useState(null);

  useEffect(() => {
    setPDFFile(null);
    const getPDFBlob = async () => {
      let blob = await pdf(pdfDoc).toBlob();
      setPDFFile({ blob, url: window.URL.createObjectURL(blob) });
    };
    getPDFBlob();
  }, [pdfDoc]);

  return (
    <Button
      disabled={!messages || messages.length === 0 || !pdfFile}
      type="text"
      icon={icon}
      onClick={e => onPrint(pdfFile)}
      id={BUTTON_IDS.printPdf}
    ></Button>
  );
};

const MessageBody = ({ t, message }) => {
  const { isRouteTo, body, subject } = useMemo(
    () => ({
      isRouteTo: isRouteToMessage(message),
      body: getMessageBody(message),
      subject: getMessageSubject(t, message)
    }),
    [t, message]
  );
  return (
    <View style={styles.sectionBody}>
      {isRouteTo && <Text>{subject}</Text>}
      <Text>{body}</Text>
    </View>
  );
};
