/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import DurationFieldType from "./DurationFieldType";

/**
 * The DurationField model module.
 * @module model/DurationField
 * @version 1.0
 */
class DurationField {
  /**
   * Constructs a new <code>DurationField</code>.
   * @alias module:model/DurationField
   */
  constructor() {
    DurationField.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>DurationField</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DurationField} obj Optional instance to populate.
   * @return {module:model/DurationField} The populated <code>DurationField</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DurationField();

      if (data.hasOwnProperty("precise")) {
        obj["precise"] = ApiClient.convertToType(data["precise"], "Boolean");
      }
      if (data.hasOwnProperty("unitMillis")) {
        obj["unitMillis"] = ApiClient.convertToType(
          data["unitMillis"],
          "Number"
        );
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = DurationFieldType.constructFromObject(data["type"]);
      }
      if (data.hasOwnProperty("supported")) {
        obj["supported"] = ApiClient.convertToType(
          data["supported"],
          "Boolean"
        );
      }
    }
    return obj;
  }
}

/**
 * @member {Boolean} precise
 */
DurationField.prototype["precise"] = undefined;

/**
 * @member {Number} unitMillis
 */
DurationField.prototype["unitMillis"] = undefined;

/**
 * @member {String} name
 */
DurationField.prototype["name"] = undefined;

/**
 * @member {module:model/DurationFieldType} type
 */
DurationField.prototype["type"] = undefined;

/**
 * @member {Boolean} supported
 */
DurationField.prototype["supported"] = undefined;

export default DurationField;
