import moment from 'moment';
import React from 'react';
import { Marker } from 'react-google-maps';

import svgMarker from './images/marker-gauge-blue.svg';

export const LocationMarker = ({
  location,
  onSelected,
  onDragEnd,
  useDefaultLocationMarker,
  draggable = false
}) => {
  if (!location) {
    return null;
  }

  return (
    <Marker
      position={{
        lat: location.lat,
        lng: location.lng
      }}
      icon={
        useDefaultLocationMarker
          ? null
          : {
              url: svgMarker,
              anchor: { x: 4, y: 2 }
            }
      }
      draggable={draggable}
      zIndex={moment().valueOf()}
      clickable={onSelected ? true : false}
      onClick={point => {
        if (onSelected) {
          onSelected({ lat: point.Lat, lng: point.Lng });
        }
      }}
      onDragEnd={onDragEnd}
      onMouseOver={() => {}}
      onMouseOut={() => {}}
    />
  );
};
