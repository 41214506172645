import React from 'react';
import { Can, entities } from 'features/permissions';
import './ListItems.scss';
import styles from './ListItems.module.scss';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';

const ListItems = ({
  listValues,
  onClickItem,
  header,
  direction,
  addItemIcon,
  onClickAddItem,
  activeItem,
  searchDocuments
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: direction || 'column',
        margin: '0 3px 0 0'
      }}
      className="list"
    >
      {header && (
        <div className="list__header list__item">
          {header}
          <Can everyEntity={[entities.FOLDER_CREATE]}>
            <img src={addItemIcon} alt="Add Item Icon" onClick={() => onClickAddItem()} />
          </Can>
        </div>
      )}
      <div className="list__item list__search">
        <AntSearchbar onFilter={searchDocuments} className={styles.searchDocuments} />
      </div>
      {listValues.map(item => (
        <div
          key={item.id}
          className={`list__item ${activeItem === item.id && 'active'}`}
          onClick={() => onClickItem(item)}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

export default ListItems;
