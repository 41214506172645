import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import {
  useCanFeatureFlag,
  FeatureFlag,
  services,
  useCanEveryCompanyService
} from 'features/permissions';
import { useMemo } from 'react';
import { fetchELDRuleset } from 'features/eld/eldSlice';

const rulesets = {
  list: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false,
    companyKey: null
  }
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.list = [];
  state.meta.companyKey = action.payload.companyKey;
}

const rulesetsSlice = createSlice({
  name: 'rulesets',
  initialState: rulesets,
  reducers: {
    fetchRulesetsStart: startLoading,
    fetchRulesetsSuccess(state, { payload }) {
      state.list = payload.list;
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchRulesetsFailure: loadingFailed
  }
});

const { fetchRulesetsStart, fetchRulesetsSuccess, fetchRulesetsFailure } = rulesetsSlice.actions;

export const fetchRulesets = includeRulesetPreRelease => (dispatch, getState) => {
  const companyKey = getState().companies?.current?.api_key;
  const companyId = getState().companies?.current?.id;
  const userKey = getState().user?.current?.auth?.key;
  try {
    if (!companyKey || !companyId || !userKey) {
      return;
    }
    var includePreRelease = includeRulesetPreRelease ? '&includePreRelease=true' : '';
    dispatch(fetchRulesetsStart());
    request('GET', `${API_PATH}/sentinel/rulesets?company_id=${companyId}${includePreRelease}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(res => {
        dispatch(fetchRulesetsSuccess({ list: res.body, companyKey }));
      })
      .catch(err => {
        dispatch(fetchRulesetsFailure({ err: err.toString(), companyKey }));
        console.warn('ERROR', err);
      });
  } catch (err) {
    dispatch(fetchRulesetsFailure({ err: err.toString(), companyKey }));
  }
};

export const useRulesets = () => {
  const dispatch = useDispatch();
  const rulesets = useSelector(state => state.rulesets.list);
  const isFetching = useSelector(state => state.rulesets.meta.isFetching);
  const isListEmpty = useSelector(state => state.rulesets.meta.isListEmpty);
  const isCompanyKeyDifferent = useCompanyKey() !== useCurrentCompanyKey();
  const eldAccess = useCanEveryCompanyService([services.ELD]);
  const eldRulesets = useSelector(state => state.eldData.eldRuleset);
  const includeRulesetPreRelease = useCanFeatureFlag({
    featureFlag: FeatureFlag.includeRulesetPreRelease.flag
  });

  const currentCompanyKey = useCurrentCompanyKey();
  const mergedRulesets = useMemo(() => {
    if (!isFetching && (isCompanyKeyDifferent || (rulesets.length === 0 && !isListEmpty))) {
      dispatch(fetchRulesets(includeRulesetPreRelease));
    }

    if (eldAccess) {
      if (eldRulesets === null || isCompanyKeyDifferent) {
        dispatch(fetchELDRuleset(includeRulesetPreRelease));
      } else {
        const newRulesets = [rulesets ?? [], eldRulesets].flat();
        return newRulesets;
      }
    }
    return rulesets;
  }, [
    dispatch,
    rulesets,
    isFetching,
    isListEmpty,
    isCompanyKeyDifferent,
    eldAccess,
    eldRulesets,
    currentCompanyKey
  ]);

  return mergedRulesets;
};

export const useIsFetching = () => useSelector(state => state.rulesets.meta.isFetching);
export const useCompanyKey = () => useSelector(state => state.rulesets.meta.companyKey);

export default rulesetsSlice.reducer;
