import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getBulkData, getTemplates, bulkImportSelectors } from '.';

import { useCurrentCompanyKey } from 'features/company/companySlice';
import { usePrevious } from 'utils/methods';

export const useBulkData = () => {
  const dispatch = useDispatch();
  const items = useSelector(bulkImportSelectors.bulkData);

  const isFetching = useSelector(bulkImportSelectors.isFetchingBulkData);
  const isSuccess = useSelector(bulkImportSelectors.isSuccessBulkData);
  const isError = useSelector(bulkImportSelectors.isErrorBulkData);
  const isFetchComplete = isSuccess || isError;

  const companyKey = useCurrentCompanyKey();
  const previousCompanyKey = usePrevious(companyKey);

  // Refetch if company changes (usecase: top nav company change)
  useEffect(() => {
    if ((!isFetching && !isFetchComplete) || (companyKey && companyKey !== previousCompanyKey)) {
      dispatch(getBulkData());
    }
  }, [companyKey]);
  return items || [];
};

export const useTemplates = () => {
  const dispatch = useDispatch();
  const templates = useSelector(bulkImportSelectors.templates);

  const isFetching = useSelector(bulkImportSelectors.isFetchingTemplates);
  const isSuccess = useSelector(bulkImportSelectors.isSuccessTemplates);
  const isError = useSelector(bulkImportSelectors.isErrorTemplates);
  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete) {
    dispatch(
      getTemplates({
        apiEndpoint: 'templates'
      })
    );
  }
  return templates || [];
};

export const useIsFetchingBulkData = () => useSelector(bulkImportSelectors.isFetchingBulkData);
export const useGetCurrentBulkItem = () => useSelector(bulkImportSelectors.currentBulkItem);
export const useIsFetchingTemplates = () => useSelector(bulkImportSelectors.isFetchingTemplates);
export const useTemplatesError = () => useSelector(bulkImportSelectors.isErrorTemplates);
export const useTemplatesSuccess = () => useSelector(bulkImportSelectors.isSuccessTemplates);
