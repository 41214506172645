import React, { useState } from 'react';
import SentinelComponent from 'features/sentinel';
import { Route, Switch } from 'react-router';
import { SentinelDriverTable } from 'features/sentinel/components/SentinelDriverTable';
import WorkDiaryView from 'features/sentinel/components/WorkDiaryView';
import { QuickFilterTable } from 'features/fatigue/Components';

const SentinelRoute = React.memo(() => {
  const [SentinelTableComponent] = useState(() => {
    return () => (
      <QuickFilterTable
        tableComponent={SentinelDriverTable}
        parentPath="/fatigue"
        basePath="/fatigue/sentinel"
      />
    );
  });

  return (
    <Switch>
      <Route
        exact
        path={'/fatigue(/sentinel)?(/All|/Working_Driving|/Resting)?'}
        component={SentinelTableComponent}
      />
      <Route exact path="*/sentinel/:id" component={SentinelComponent} />
      <Route exact path="*/sentinel/workdiary/:driverId" component={WorkDiaryView} />
    </Switch>
  );
});

export const Sentinel = props => {
  return <SentinelRoute />;
};
