import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Divider, Modal, Spin, Tag, Typography } from 'antd';
import { Link as LinkRouter } from 'react-router-dom';
import moment from 'moment';

import { Icon } from 'components/ant';

import {
  fetchInspection,
  useInspection,
  useIsInspectionFetching
} from 'features/vehicleMaintenanceInspections';
import { useLocalization } from 'features/localization/localizationSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';

import { getIDFromPathname } from 'utils/methods';
import { format } from 'utils/dates';

import ImagePreview from 'static/images/icons/image-preview.svg';
import styles from './InspectionsView.module.scss';

export const InspectionsView = () => {
  const { t } = useTranslation();
  const vehicleId = getIDFromPathname(window.location.pathname);
  const dispatch = useDispatch();
  const vehicleDetails = useInspection(vehicleId);
  const isLoading = useIsInspectionFetching();
  const localization = useLocalization();
  const [isModalOpen, setIsModalOpen] = useState(null);

  const { Text, Title, Link } = Typography;

  useEffect(() => {
    dispatch(setPageTitle(vehicleDetails?.checklistName));
    dispatch(setBackButton(true));
  }, [dispatch, vehicleDetails]);

  useEffect(() => {
    dispatch(fetchInspection({ id: vehicleId }));
  }, [vehicleId]);

  const showModal = id => {
    setIsModalOpen(id);
  };

  const handleCancel = () => {
    setIsModalOpen(null);
  };

  const statusRender = status => {
    switch (status) {
      case 'REPAIRED':
        return <Tag color="#DCE2F3">{t(`Status.${status.toLowerCase()}`)}</Tag>;
      case 'INPROGRESS':
        return <Tag color="#FFF1B8">{t(`Status.${status.toLowerCase()}`)}</Tag>;
      case 'CLOSED':
      case 'PASS':
        return <Tag color="#D6F3A9">{t(`Status.${status.toLowerCase()}`)}</Tag>;
      case 'FAIL':
        return <Tag color="#FFCCC7">{t(`Status.${status.toLowerCase()}`)}</Tag>;
      default:
        return null;
    }
  };

  return (
    <Spin spinning={isLoading}>
      {/* Vehicle Details section */}

      <div className={styles.dataRow}>
        <div className={styles.content}>
          <Text type="secondary" className={styles.title}>
            {t('Inspections.View.Status')}
          </Text>
          {vehicleDetails.status ? statusRender(vehicleDetails.status) : null}
        </div>
        <Divider className={styles.divider} />
      </div>
      <div className={styles.dataRow}>
        <div className={styles.content}>
          <Text type="secondary" className={styles.title}>
            {t('Inspections.View.CompletedOn')}
          </Text>
          {vehicleDetails.completedAt ? (
            <Text>
              {format(vehicleDetails.completedAt, localization.formats.time.formats.dby_imsp)}
            </Text>
          ) : null}
        </div>
        <Divider className={styles.divider} />
      </div>
      <div className={styles.dataRow}>
        <div className={styles.content}>
          <Text type="secondary" className={styles.title}>
            {t('Inspections.View.CompletedDuration')}
          </Text>
          {vehicleDetails.completionDurationSeconds ? (
            <Text>
              {moment.utc(vehicleDetails.completionDurationSeconds * 1000).format('HH:mm:ss')}
            </Text>
          ) : null}
        </div>
        <Divider className={styles.divider} />
      </div>
      <div className={styles.dataRow}>
        <div className={styles.content}>
          <Text type="secondary" className={styles.title}>
            {t('Inspections.View.Location')}
          </Text>
          {vehicleDetails.location && <Text>{vehicleDetails.location}</Text>}
        </div>
        <Divider className={styles.divider} />
      </div>
      <div className={styles.dataRow}>
        <div className={styles.content}>
          <Text type="secondary" className={styles.title}>
            {t('Inspections.View.ChecklistName')}
          </Text>
          {vehicleDetails.checklistName && <Text>{vehicleDetails.checklistName}</Text>}
        </div>
        <Divider className={styles.divider} />
      </div>
      <div className={styles.dataRow}>
        <div className={styles.content}>
          <Text type="secondary" className={styles.title}>
            {t('Inspections.View.DriversName')}
          </Text>
          {(vehicleDetails?.inspectionBy?.firstName || vehicleDetails?.inspectionBy?.lastName) && (
            <Text>{`${vehicleDetails?.inspectionBy?.firstName ?? ''} ${vehicleDetails?.inspectionBy
              ?.lastName ?? ''}`}</Text>
          )}
        </div>
        <Divider className={styles.divider} />
      </div>

      <div className={styles.dataRow}>
        <div className={styles.content}>
          <Text type="secondary" className={styles.title}>
            {t('Inspections.View.VehicleName')}
          </Text>
          {vehicleDetails?.vehicle?.name && <Text>{vehicleDetails?.vehicle?.name}</Text>}
        </div>
        <Divider className={styles.divider} />
      </div>
      <div className={styles.dataRow}>
        <div className={styles.content}>
          <Text type="secondary" className={styles.title}>
            {t('Inspections.View.VehicleRegistration')}
          </Text>
          {vehicleDetails?.vehicle?.registration && (
            <Text>{vehicleDetails?.vehicle?.registration}</Text>
          )}
        </div>
        <Divider className={styles.divider} />
      </div>
      <div className={styles.dataRow}>
        <div className={styles.content}>
          <Text type="secondary" className={styles.title}>
            {t('Inspections.View.VehicleRegistrationState')}
          </Text>
          {vehicleDetails?.vehicle?.registrationState && (
            <Text>{vehicleDetails?.vehicle?.registrationState}</Text>
          )}
        </div>
        <Divider className={styles.divider} />
      </div>

      {/* Questions section */}

      <div className={styles.dataRow}>
        <div className={styles.content}>
          <Title level={4} className={styles.title}>
            {t('Inspections.View.Question')}
          </Title>
        </div>
        <Divider className={styles.divider} />
      </div>
      {vehicleDetails.questions &&
        JSON.parse(vehicleDetails.questions).map((questionDetails, id) => (
          <div key={`${questionDetails?.Text}-title-${id}`} className={styles.dataRow}>
            <div className={styles.content}>
              <Text type="secondary" className={styles.title}>
                {questionDetails?.Text}
              </Text>
              <div className={styles['preview-container']}>
                {questionDetails.DVIR === 'Sig-New' || questionDetails.DVIR === 'Sig-Repaired' ? (
                  <div className={styles['preview-container']}>
                    <img
                      alt={t('Inspections.View.MissingImage')}
                      className={styles['preview--signature']}
                      loading="lazy"
                      src={`data:${questionDetails?.Attachment?.MimeType};base64,${questionDetails?.Attachment?.Content}`}
                    />
                  </div>
                ) : [true, false].includes(questionDetails?.Result) ? (
                  <Text type={`${questionDetails.Result ? 'success' : 'danger'}`}>
                    {questionDetails?.Answer}
                    {questionDetails?.Result === false &&
                      questionDetails?.SubFailedQuestions?.[0]?.Answer &&
                      ` - ${questionDetails?.SubFailedQuestions?.[0].Answer}`}
                  </Text>
                ) : (
                  <Text>{questionDetails?.Answer}</Text>
                )}
                {questionDetails?.SubFailedQuestions?.[0].Attachments && (
                  <>
                    <Link
                      className={styles.preview}
                      onClick={() => showModal(`${questionDetails?.Text}-${id}`)}
                    >
                      <Icon className={styles['preview--icon']} srcIcon={ImagePreview} />
                      {questionDetails?.SubFailedQuestions?.[0].Attachments?.[0].Attachment?.Name?.split(
                        '.'
                      )[0].substring(0, 30) ?? ''}
                    </Link>
                    <Modal
                      className={styles.modal}
                      title={
                        questionDetails?.SubFailedQuestions?.[0].Attachments?.[0].Attachment?.Name?.split(
                          '.'
                        )[0].substring(0, 30) ?? ''
                      }
                      open={isModalOpen === `${questionDetails?.Text}-${id}`}
                      onCancel={handleCancel}
                      footer={null}
                      maskClosable={false}
                      key={`${questionDetails?.Text}-modal-${id}`}
                    >
                      <img
                        alt={t('Inspections.View.MissingImage')}
                        width="400px"
                        loading="lazy"
                        src={`data:${questionDetails?.SubFailedQuestions?.[0].Attachments?.[0].Attachment?.MimeType};base64,${questionDetails?.SubFailedQuestions?.[0].Attachments?.[0].Attachment?.Content}`}
                      />
                    </Modal>
                  </>
                )}
                {questionDetails?.Attachments && (
                  <>
                    <Link
                      className={styles.preview}
                      onClick={() => showModal(`${questionDetails?.Text}-${id}`)}
                    >
                      <Icon className={styles['preview--icon']} srcIcon={ImagePreview} />
                      {questionDetails?.Attachments?.[0].Attachment?.Name?.split('.')[0].substring(
                        0,
                        30
                      ) ?? ''}
                    </Link>
                    <Modal
                      className={styles.modal}
                      title={
                        questionDetails?.Attachments?.[0].Attachment?.Name?.split('.')[0].substring(
                          0,
                          30
                        ) ?? ''
                      }
                      open={isModalOpen === `${questionDetails?.Text}-${id}`}
                      onCancel={handleCancel}
                      footer={null}
                      maskClosable={false}
                      key={`${questionDetails?.Text}-modal-${id}`}
                    >
                      <img
                        alt={t('Inspections.View.MissingImage')}
                        width="400px"
                        loading="lazy"
                        src={`data:${questionDetails?.Attachments?.[0].Attachment?.MimeType};base64,${questionDetails?.Attachments?.[0].Attachment?.Content}`}
                      />
                    </Modal>
                  </>
                )}
              </div>
            </div>
            <Divider className={styles.divider} />
          </div>
        ))}

      {/* Maintenance section */}
      {vehicleDetails.schedule && (
        <>
          <div className={styles.dataRow}>
            <div className={styles.content}>
              <Title level={4} className={styles.title}>
                {t('Inspections.View.Maintenance')}
              </Title>
            </div>
            <Divider className={styles.divider} />
          </div>
          <div className={styles.dataRow}>
            <div className={styles.content}>
              <Text type="secondary" className={styles.title}>
                {t('Inspections.View.LinkedSchedule')}
              </Text>

              <LinkRouter
                to={
                  vehicleDetails?.schedule?.id
                    ? `/vehiclemaintenance/schedules/id/${vehicleDetails.schedule.id}`
                    : null
                }
              >
                {vehicleDetails?.schedule?.name}
              </LinkRouter>
            </div>
            <Divider className={styles.divider} />
          </div>
          <div className={styles.dataRow}>
            <div className={styles.content}>
              <Text type="secondary" className={styles.title}>
                {t('Inspections.View.Notes')}
              </Text>
              {vehicleDetails?.schedule?.completionParameters && (
                <Text>{JSON.parse(vehicleDetails.schedule.completionParameters).notes}</Text>
              )}
            </div>
            <Divider className={styles.divider} />
          </div>
        </>
      )}

      {/* Signature */}

      {vehicleDetails.signature && (
        <>
          <div className={styles.dataRow}>
            <div className={styles.content}>
              <Title level={4} className={styles.title}>
                {t('Inspections.View.Signature')}
              </Title>
            </div>
            <Divider className={styles.divider} />
          </div>
          <div className={styles.dataRow}>
            <div className={styles.content}>
              <Text type="secondary" className={styles.title}>
                {t('Inspections.View.Signed')}
              </Text>
              <div className={styles['preview-container']}>
                <img
                  alt={t('Inspections.View.MissingImage')}
                  className={styles['preview--signature']}
                  loading="lazy"
                  src={`data:${vehicleDetails?.signature?.mimeType};base64,${vehicleDetails?.signature?.content}`}
                />
              </div>
            </div>
            <Divider className={styles.divider} />
          </div>
        </>
      )}
    </Spin>
  );
};
