import React from 'react';
import styled from '@emotion/styled';
import { ButtonNavLink } from 'components/nav/NavLinks';
import { connect } from 'react-redux';
import { softReset } from 'features/user/softReset';
import logo_grey_1 from 'static/images/logo_grey_1.svg';
import { BUTTON_IDS } from 'utils/globalConstants';

const Main = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  order: 2;
  flex: 1 0 0;
  flex-direction: column;
`;

const Subpage = styled.section`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  justify-content: center;
  align-items: center;
`;
const Image = styled.img`
  width: 320px;
  padding-bottom: 3rem;
`;

const ErrorPage = ({ clearError = () => {}, BackBtn }) => {
  return (
    <Main>
      <Subpage className="ErrorPage">
        <div>
          <Image src={logo_grey_1} alt="Telematics 360 Logo" />
        </div>
        <h2>Oops something went wrong</h2>
        <p>This error has been logged, we will review it as soon as possible.</p>
        {BackBtn ? (
          BackBtn
        ) : (
          <ButtonNavLink id={BUTTON_IDS.errorPageBackTo} onClick={() => clearError()} to="/">
            Back to Home
          </ButtonNavLink>
        )}
      </Subpage>
    </Main>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // New Relic here
  }

  doSoftReset = () => {
    this.props.softReset();
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage clearError={() => this.doSoftReset()} />;
    }
    return this.props.children;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    softReset: () => dispatch(softReset())
  };
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);

export const ErrorRoute = ({ history }) => (
  <ErrorPage
    BackBtn={
      <button
        onClick={history.goBack}
        id={BUTTON_IDS.errorPageBackTo}
        className={'tn-navlink-btn tn-active'}
      >
        Back to Home
      </button>
    }
  />
);
