import React from 'react';
import { Field } from 'formik';
import { FormGroup, Col, FormLabel, FormControl } from 'react-bootstrap';
import styles from './FormSelect.module.scss';
import './FormSelect.scss';

const FormSelect = ({
  name,
  label,
  isRequired,
  placeholder,
  classNames,
  defaultValue,
  values,
  isDisabled,
  onChange,
  isHidden,
  supressSorting = false
}) => {
  const groupClassNames = isHidden ? styles.isHidden : classNames;
  const selectValues = supressSorting
    ? values
    : values.sort((a, b) =>
        a?.label?.toString()?.toLowerCase() > b?.label?.toString()?.toLowerCase() ? 1 : -1
      );

  return (
    <Field name={name}>
      {({ field, meta }) => (
        <FormGroup as={Col} className={groupClassNames}>
          <FormLabel className={!label && styles.invisible}>{label || 'Label'}</FormLabel>
          {isRequired && <span className={styles.inputRequired}>*</span>}
          <div className={styles.formControlContainer}>
            <FormControl
              {...field}
              as="select"
              onChange={onChange ? e => onChange(e.target.value) : field.onChange}
              className={(!field.value || field.value === '') && styles.placeholder}
              disabled={isDisabled || false}
            >
              {placeholder && (
                <option value={defaultValue ? defaultValue : ''} key={-1}>
                  {placeholder}
                </option>
              )}
              {selectValues.map((value, index) => (
                <option key={`${value.label}${value.value}${index}`} value={value.value}>
                  {value.label}
                </option>
              ))}
            </FormControl>
          </div>
          {isRequired && (
            <div className={`fieldError ${meta.touched && meta.error ? 'error has' : 'error'}`}>
              {meta.touched && meta.error}
            </div>
          )}
        </FormGroup>
      )}
    </Field>
  );
};

export default FormSelect;
