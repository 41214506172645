import { Input, Switch } from 'antd';
import { fetchSubCompanies } from 'features/company/companySlice';
import { fetchDeviceStats } from 'features/devices/devicesStatsSlice';
import {
  updateDriverManagementFields,
  updateSmartJobsIntegrationConfig,
  updateNonBusinessConfig,
  useDriverManagementFields,
  useNonBusinessConfig,
  useSmartjobsIntegrationConfig,
  useSmartDashcamConfig,
  updateSmartDashcamConfig
} from 'features/company_config';

export const modalFormProps = {
  driverManagement: {
    fetchHook: useDriverManagementFields,
    formName: 'driverManagementForm',
    formElements: [
      {
        className: '',
        component: Input,
        label: '',
        name: 'field1',
        placeholder: 'CompanyConfig.UserDefinedFields.AddDefinition',
        props: {},
        rules: []
      },
      {
        className: '',
        component: Input,
        label: '',
        name: 'field2',
        placeholder: 'CompanyConfig.UserDefinedFields.AddDefinition',
        props: {},
        rules: []
      },
      {
        className: '',
        component: Input,
        label: '',
        name: 'field3',
        placeholder: 'CompanyConfig.UserDefinedFields.AddDefinition',
        props: {},
        rules: []
      }
    ],
    initialValues: {
      field1: '',
      field2: '',
      field3: ''
    },
    type: 'drivermng',
    updateAction: updateDriverManagementFields
  },
  smartjobsIntegration: {
    fetchHook: useSmartjobsIntegrationConfig,
    formElements: [
      {
        className: '',
        component: Switch,
        label: 'CompanyConfig.SmartjobsIntegration.Description',
        name: 'enabled',
        props: {},
        rules: [],
        formItemProps: {
          valuePropName: 'checked'
        }
      }
    ],
    updateAction: updateSmartJobsIntegrationConfig,
    hideTitle: true,
    onSuccessActions: [companyId => fetchSubCompanies(companyId, 'config')],
    type: 'smartjobs_forms_integration'
  },
  hideNonBusiness: {
    fetchHook: useNonBusinessConfig,
    formElements: [
      {
        className: '',
        component: Switch,
        label: 'CompanyConfig.HideNonBusiness.Title',
        name: 'field1',
        props: {},
        rules: [],
        formItemProps: {
          valuePropName: 'checked'
        }
      }
    ],
    updateAction: updateNonBusinessConfig,
    hideTitle: true,
    onSuccessActions: [
      companyId => fetchSubCompanies(companyId, 'config'),
      _ => fetchDeviceStats(true)
    ],
    type: 'hidenonbusiness'
  },
  smartDashcam: {
    fetchHook: useSmartDashcamConfig,
    formElements: [
      {
        className: '',
        component: Switch,
        label: 'CompanyConfig.SmartDashcam.EnableDES',
        name: 'driverEventSynch',
        props: {},
        rules: [],
        formItemProps: {
          valuePropName: 'checked'
        }
      },
      {
        className: '',
        component: Switch,
        label: 'CompanyConfig.SmartDashcam.EnableSSO',
        name: 'ssoAndUserSynch',
        props: {},
        rules: [],
        formItemProps: {
          valuePropName: 'checked'
        }
      }
    ],
    updateAction: updateSmartDashcamConfig,
    hideTitle: true,
    onSuccessActions: [
      companyId => fetchSubCompanies(companyId, 'config'),
      _ => fetchDeviceStats(true)
    ],
    type: 'netradyneSmartDashcam'
  }
};
