import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col, Button, Typography, Switch, Alert, Form, Modal } from 'antd';
import MultipleSelectTable from 'components/multipleSelectTable/MultipleSelectTable';
import { InfoCircleFilled } from '@ant-design/icons';
import { confirmNavigateAway } from './confirmNavigateAway';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import {
  useBranches,
  useIsFetching as useIsFetchingBranches
} from 'features/locations/locationsSlice';
import { useDrivers, useIsFetching as useIsFetchingDrivers } from 'features/users/usersSlice';
import { useCurrentCompanyId } from 'features/company/companySlice';

import styles from './VehicleLoginLimitModal.module.scss';
import { useDispatch } from 'react-redux';
import {
  updateVehicleLoginLimitConfig,
  useVehicleLoginLimitConfig,
  useVehicleLoginLimitConfigUpdateStatus
} from 'features/company_config';
import {
  ConfigFormSection as Section,
  toVehicleLoginLimitPayload,
  isFormDataSameAs
} from './helper';
import { BUTTON_IDS } from 'utils/globalConstants';

export const VehicleLoginLimitModal = ({ show = false, title, onClose = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompanyId = useCurrentCompanyId();
  const configFormData = useVehicleLoginLimitConfig(currentCompanyId);
  const updateStatus = useVehicleLoginLimitConfigUpdateStatus(currentCompanyId);

  const [form] = Form.useForm();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const formInitialValues = useMemo(() => {
    return configFormData && Object.keys(configFormData).length ? configFormData : null;
  }, [configFormData]);
  const updateIsFormDirty = useCallback(
    (changedValue, formValues) => {
      setIsFormDirty(!isFormDataSameAs(formValues, formInitialValues));
    },
    [formInitialValues]
  );

  const _branches = useBranches();
  const isFetchingBranches = useIsFetchingBranches();
  const _drivers = useDrivers();
  const isFetchingDrivers = useIsFetchingDrivers();

  const drivers = useMemo(() => {
    return isFetchingDrivers
      ? []
      : (_drivers || []).map(driver => ({
          ...driver,
          fullName: `${driver.firstName || ''} ${driver.lastName || ''}`,
          branches:
            driver?.location?.id && _branches?.filter(branch => branch.id === driver.location.id),
          licence:
            driver && driver?.licenceStat && driver?.licenceNumber
              ? `${driver?.licenceState} | ${driver?.licenceNumber}`
              : driver?.licenceStat
              ? driver?.licenceStat
              : driver?.licenceNumber || ''
        }));
  }, [_drivers, isFetchingDrivers, _branches]);

  const branches = useMemo(() => {
    return isFetchingBranches
      ? []
      : (_branches || []).map(branch => ({
          ...branch,
          drivers: _drivers
            ?.filter(
              driver => driver?.location?.id === branch.id || (branch.id === -1 && !driver.location)
            )
            .map(d => ({
              ...d,
              fullName: `${d.firstName || ''} ${d.lastName || ''}`
            }))
        }));
  }, [_drivers, isFetchingBranches, _branches]);

  const disableSubmit = useMemo(
    () => !currentCompanyId || !isFormDirty || updateStatus?.isUpdating,
    [currentCompanyId, isFormDirty, updateStatus?.isUpdating]
  );

  useEffect(() => {
    form.resetFields();
  }, [formInitialValues, form]);

  const onCancel = useCallback(() => {
    confirmNavigateAway(t, isFormDirty, () => {
      onClose();
      form.resetFields();
    });
  }, [t, isFormDirty, onClose, form]);

  const onFormSave = useCallback(
    values => {
      try {
        dispatch(
          updateVehicleLoginLimitConfig(
            currentCompanyId,
            toVehicleLoginLimitPayload(values, currentCompanyId)
          )
        ).then(({ updated, error }) => {
          if (updated) {
            form.resetFields();
            onClose();
            dispatch(
              openToast({
                type: ToastType.Success,
                message: t('CompanyConfig.Notifications.VehicleLoginLimitSuccess')
              })
            );
          } else {
            dispatch(
              openToast({
                type: ToastType.Error,
                message: t('CompanyConfig.Notifications.VehicleLoginLimitError', { error })
              })
            );
          }
        });
      } catch (error) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('CompanyConfig.Notifications.VehicleLoginLimitError', { error })
          })
        );
      }
    },
    [t, dispatch, currentCompanyId, form, onClose]
  );

  return (
    <Modal
      className={styles.vehicleLoginLimitModal}
      title={title}
      open={show}
      centered={true}
      onCancel={onCancel}
      width={1200}
      footer={
        <>
          <Button
            type="primary"
            key="submit"
            htmlType="submit"
            form="vehicleLoginLimitForm"
            loading={updateStatus?.isUpdating}
            disabled={disableSubmit}
            id={BUTTON_IDS.vehicleLoginLimitSave}
          >
            {t('Common.Save')}
          </Button>
          <Button id={BUTTON_IDS.vehicleLoginLimitCancel} onClick={onCancel}>
            {t('Common.Cancel')}
          </Button>
        </>
      }
    >
      <Form
        layout="vertical"
        name="vehicleLoginLimitForm"
        initialValues={formInitialValues}
        form={form}
        onFinish={onFormSave}
        onValuesChange={updateIsFormDirty}
        onKeyPress={event => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <FormSection section={Section.Company}>
          <div className={styles.formSwitch}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue, setFields }) => (
                <Form.Item name={Section.Company.formName}>
                  <Switch
                    size="small"
                    checked={getFieldValue(Section.Company.formName)}
                    onChange={checked =>
                      setFields([{ name: Section.Company.formName, value: checked }])
                    }
                  />
                </Form.Item>
              )}
            </Form.Item>
            <span>{t('CompanyConfig.VehicleLoginLimit.CompanyLimitDescription')}</span>
          </div>
        </FormSection>
        <FormSection section={Section.Exceptions}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[Section.Company.formName] !== currentValues[Section.Company.formName]
            }
          >
            {({ getFieldValue }) => (
              <Alert
                icon={<InfoCircleFilled style={{ fontSize: '20px', color: '#42C3F0' }} />}
                message={
                  <span className={styles.exceptionsDes}>
                    {t(
                      `CompanyConfig.VehicleLoginLimit.Exceptions.${
                        getFieldValue(Section.Company.formName) ? 'Unlimited' : 'Limited'
                      }.Start`
                    )}
                    <span style={{ fontWeight: 'bold' }}>{` ${t(
                      `CompanyConfig.VehicleLoginLimit.Exceptions.${
                        getFieldValue(Section.Company.formName) ? 'Unlimited' : 'Limited'
                      }.Slot`
                    )}`}</span>
                    {` ${t(
                      `CompanyConfig.VehicleLoginLimit.Exceptions.${
                        getFieldValue(Section.Company.formName) ? 'Unlimited' : 'Limited'
                      }.End`
                    )}`}
                  </span>
                }
                type="info"
                showIcon
              />
            )}
          </Form.Item>
        </FormSection>
        <FormSection
          section={Section.Branches}
          listConfig={{
            form,
            updateIsFormDirty,
            listProperties: {
              name: Section.Branches.formName,
              placeholder: t('Alerts.Placeholders.SelectBranches'),
              columnHeaderLabels: {
                firstColumnValue: t('Common.Name'),
                secondColumnValue: t('Common.Drivers')
              }
            },
            listOptions: branches.map(branch => ({
              id: branch.id,
              firstColumnValue: branch.name,
              secondColumnValue: branch.drivers?.map(d => d.fullName).join(', ')
            })),
            isLoading: isFetchingBranches
          }}
        />
        <FormSection
          section={Section.Drivers}
          listConfig={{
            form,
            updateIsFormDirty,
            listProperties: {
              name: Section.Drivers.formName,
              placeholder: t('Alerts.Placeholders.SelectDrivers'),
              columnHeaderLabels: {
                firstColumnValue: t('Common.Name'),
                secondColumnValue: t('Alerts.Licence'),
                thirdColumnValue: t('Common.Branches')
              }
            },
            listOptions: drivers.map(driver => ({
              id: driver.id,
              firstColumnValue: driver.fullName,
              secondColumnValue: driver.licence,
              thirdColumnValue: driver.branches?.map(branch => branch.name).join(', ')
            })),
            isLoading: isFetchingDrivers
          }}
        />
      </Form>
    </Modal>
  );
};

const FormSection = ({ section, children, listConfig }) => {
  const { t } = useTranslation();
  return (
    <Row align="middle">
      <Col span={24}>
        <Typography.Title
          level={5}
          className={listConfig ? styles.formListSectionTitle : styles.formSectionTitle}
        >
          {t(section.translation)}
        </Typography.Title>
        {listConfig ? <FormListSection {...listConfig}>{children}</FormListSection> : children}
      </Col>
    </Row>
  );
};

const FormListSection = ({
  form,
  listProperties = {},
  listOptions = [],
  isLoading,
  updateIsFormDirty
}) => {
  return (
    <Form.Item noStyle shouldUpdate>
      {({ getFieldValue, getFieldsValue }) => (
        <MultipleSelectTable
          style={{ width: '100%' }}
          form={form}
          selected={getFieldValue(listProperties.name)}
          options={listOptions}
          properties={{
            ...listProperties,
            mode: 'multiple',
            wrapperCol: {
              offset: 0,
              span: 24
            }
          }}
          isLoading={isLoading}
          onChange={value => updateIsFormDirty(value, getFieldsValue(true))}
        />
      )}
    </Form.Item>
  );
};
