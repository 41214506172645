/* global google */
import React, { useState, useEffect, useCallback } from 'react';
import { Polyline } from 'react-google-maps';

const antiThrottleBase = 600; // anti-throttle timeout interval
const antiThrottleMultiplier = 2; // anti-throttle timeout multiplier

const useDirectionSvcRoute = (
  isDirectionSvcRoute,
  origin,
  destination,
  waypoints,
  setDirections
) => {
  let timeout = antiThrottleBase;

  useEffect(() => {
    if (isDirectionSvcRoute) {
      getDirectionSvcRoute();
    }
  }, [origin, destination, waypoints]);

  const getDirectionSvcRoute = useCallback(() => {
    const directionsService = new google.maps.DirectionsService();

    directionsService.route(
      {
        origin,
        destination,
        waypoints:
          waypoints &&
          waypoints.map(waypoint => ({
            location: new google.maps.LatLng(waypoint.lat, waypoint.lng)
          })),
        travelMode: google.maps.TravelMode.DRIVING
      },
      (response, status) => {
        switch (status) {
          case google.maps.DirectionsStatus.OK:
            setDirections(response.routes[0].overview_path);
            break;
          case google.maps.DirectionsStatus.OVER_QUERY_LIMIT:
            console.error(status + ': Unable to retrieve route (timeout: ' + timeout + ').');
            setTimeout(() => {
              getDirectionSvcRoute();
            }, timeout);
            timeout *= antiThrottleMultiplier;
            break;
          default:
            console.error(status + ': Unable to retrieve route.');
        }
      }
    );
  }, [origin, destination, waypoints]);
};

export const Route = ({
  origin,
  destination,
  waypoints,
  isSelected,
  onSelected,
  pathColor = '#de6ff7', // #de70f7',
  isDirectionSvcRoute = false
}) => {
  const [directions, setDirections] = useState([]);

  useEffect(() => {
    if (waypoints) {
      setDirections([
        ...(origin.lat && origin.lng ? [origin] : []),
        ...(waypoints &&
          waypoints.map(point =>
            point.Lat && point.Lng ? { lat: point.Lat, lng: point.Lng } : null
          )),
        ...(destination.lat && destination.lng ? [destination] : [])
      ]);
    }
  }, [origin, destination, waypoints]);

  useDirectionSvcRoute(isDirectionSvcRoute, origin, destination, waypoints, setDirections);

  return (
    <Polyline
      path={directions}
      options={{
        strokeColor: pathColor,
        strokeOpacity: isSelected ? 1 : 0.4,
        strokeWeight: isSelected ? 8 : 6
      }}
      geodesic={true}
      onClick={onSelected}
    />
  );
};
