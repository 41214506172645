import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Row, Col, Form, Input, Select, DatePicker, Button, Modal, InputNumber } from 'antd';
import { VehicleSearchInput } from './VehicleSearchInput';
import { DriverSearchInput } from './DriverSearchInput';
import * as moment from 'moment-timezone';
import { CountrySelect } from 'features/common/CountrySelect';
import { StateSelect } from 'features/common/StateSelect';
import { DutyStatus, MapActionToChartStatus } from './util';
import { sortBy } from 'lodash';
import styles from './DriverLog.module.scss';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { useLocalization } from 'features/localization/localizationSlice';
import { useUser } from 'features/user/userSlice';
import { useUsers } from 'features/users/usersSlice';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import i18next from 'i18nextConfig';
import { LocalizationUtil } from 'features/localization/localization';
import {
  useDriverLogHeaderByDate,
  fetchDriverLogHeaderByDriverId
} from 'features/eld/driverLogSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { useDispatch } from 'react-redux';
import { BUTTON_IDS } from 'utils/globalConstants';
import { DutyStatusDescription } from '../util/dutystatus';

const NonCDLRulesetNames = ['ELD3', 'ELD4'];
const OilSpecRulesetNames = ['ELDCAN5', 'ELDCAN6', 'ELDOIL1', 'ELDOIL2'];

function StatusSelect({ value, onChange, disabled, driverDetail, date, ...props }) {
  const company = useCurrentCompany();
  const dispatch = useDispatch();

  const isShortHaulNonCDLDriver = useMemo(() => {
    if (driverDetail != null && date != null) {
      const epochTime = date.format('x');
      const rulesetIndex = driverDetail.rulesets?.findLastIndex(r => r.enabledAt <= epochTime);
      if (
        rulesetIndex >= 0 &&
        NonCDLRulesetNames.includes(driverDetail.rulesets[rulesetIndex].ruleset)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [driverDetail, date]);

  const hideOffDutyAtWell = useMemo(() => {
    if (driverDetail != null && date != null) {
      const epochTime = date.format('x');
      const rulesetIndex = driverDetail.rulesets?.findLastIndex(r => r.enabledAt <= epochTime);
      if (
        rulesetIndex >= 0 &&
        !OilSpecRulesetNames.includes(driverDetail.rulesets[rulesetIndex].ruleset)
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [driverDetail, date]);

  const driverLogHeader = useDriverLogHeaderByDate(
    company?.id,
    driverDetail?.id,
    date?.toDate() || new Date(),
    moment
      .tz(
        date || new Date(),
        driverDetail?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      .endOf('day')
      .toDate()
  );
  const filteredDutyStatus = useMemo(() => {
    let dutyStatusCloned = Object.assign({}, DutyStatus);
    if (isShortHaulNonCDLDriver) {
      delete dutyStatusCloned.SleeperBerth;
    }
    if (hideOffDutyAtWell) {
      delete dutyStatusCloned.OffDutyAtWell;
    }
    delete dutyStatusCloned.IntermediateLog;
    return dutyStatusCloned;
  }, [isShortHaulNonCDLDriver, hideOffDutyAtWell]);

  useEffect(() => {
    if (company == null || driverDetail?.id == null || date == null) {
      return;
    }

    dispatch(
      fetchDriverLogHeaderByDriverId(
        company,
        driverDetail.id,
        moment
          .tz(
            date || new Date(),
            driverDetail?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
          )
          .toDate(),
        moment
          .tz(
            date || new Date(),
            driverDetail?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
          )
          .endOf('day')
          .toDate()
      )
    );
  }, [dispatch, company, driverDetail, date]);

  return (
    <Select
      value={
        value === 'LogoffDriver' || value === 'LogonDriver' ? DutyStatusDescription[value] : value
      }
      onChange={onChange}
      disabled={disabled}
      getPopupContainer={triggerNode => triggerNode.parentNode}
    >
      {sortBy(Object.entries(filteredDutyStatus), [e => e[1]]).map((e, idx) => (
        <Select.Option value={e[0]} key={idx}>
          {i18next.t(`ELD.DutyStatus.${e[1]}`)}
        </Select.Option>
      ))}
    </Select>
  );
}

function LocalizedOdometerInput(props) {
  const locale = useLocalization();
  return <InputNumber {...props} suffix={locale.formats.speed.unit} />;
}

function EditByInput(props) {
  const { editByFirstName, editByLastName, ...otherProps } = props;
  let userLists = useUsers();
  if (userLists.find(u => u.id === props.value) == null) {
    userLists = Array.from(userLists);
    userLists.push({ id: props.value, firstName: editByFirstName, lastName: editByLastName });
  }
  return (
    <Select getPopupContainer={triggerNode => triggerNode.parentNode} {...otherProps}>
      {userLists?.map((u, idx) => (
        <Select.Option value={u.id} key={u.id}>
          {u.firstName + ' ' + u.lastName}
        </Select.Option>
      ))}
    </Select>
  );
}

const recordFields = [
  {
    name: 'time',
    get label() {
      return i18next.t('Home.Time');
    },
    rules: [
      {
        required: true,
        get message() {
          return i18next.t('ELD.Please select a time') + '.';
        }
      }
    ],
    placeholder: '',
    className: '',
    style: { minWidth: 'auto', width: '100%' },
    componentProp: {
      showTime: true,
      disabledDate: current => {
        return current > moment(); //.tz(driverDetail?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone);
      },
      format: 'MM/DD/YYYY hh:mm:ss A'
    },
    fieldProp: {
      required: true
    },
    span: 12,
    component: DatePicker
  },
  {
    name: 'vehicle_id',
    get label() {
      return i18next.t('Common.Vehicle');
    },
    rules: [
      form => {
        const isRequired = MapActionToChartStatus[form.getFieldValue('action')] === 'Driving';
        return {
          required: isRequired,
          get message() {
            return i18next.t('ELD.Please select a vehicle') + '.';
          }
        };
      }
    ],
    placeholder: '',
    className: '',
    style: {},
    componentProp: {},
    dynamicComponentProp: fieldValues => {
      return {
        allowClear: MapActionToChartStatus[fieldValues['action']] !== 'Driving'
      };
    },
    span: 12,
    component: VehicleSearchInput
  },
  {
    name: 'action',
    get label() {
      return i18next.t('ELD.Duty Status');
    },
    rules: [
      {
        required: true,
        get message() {
          return i18next.t('ELD.Please select a duty status') + '.';
        }
      }
    ],
    get placeholder() {
      return i18next.t('ELD.Select option');
    },
    className: '',
    style: {},
    componentProp: {},
    fieldProp: {
      required: true
    },
    span: 12,
    component: StatusSelect
  },
  {
    name: 'coDriver',
    get label() {
      return i18next.t('ELD.Co Driver');
    },
    rules: [],
    get placeholder() {
      return i18next.t('ELD.Select a Driver');
    },
    className: '',
    style: {},
    componentProp: {},
    span: 12,
    fieldProp: {
      required: false
    },
    component: DriverSearchInput
  },
  {
    name: 'declaredLocation',
    get label() {
      return i18next.t('Common.Address');
    },
    rules: [
      {
        required: true,
        get message() {
          return i18next.t('ELD.Address is required') + '.';
        }
      },
      {
        max: 60,
        get message() {
          return i18next.t('ELD.AddressLengthWarn') + '.';
        }
      }
    ],
    get placeholder() {
      return i18next.t('Common.Address');
    },
    className: '',
    style: {},
    fieldProp: {
      required: true
    },
    componentProp: {},
    span: 12,
    component: Input
  },
  {
    name: 'streetAddress',
    get label() {
      return i18next.t('ELD.Street Address');
    },
    rules: [
      {
        max: 60,
        get message() {
          return i18next.t('ELD.The maximum length of the street is 60') + '.';
        }
      },
      {
        pattern: /^(?!\s)([a-zA-Z0-9][A-Za-z0-9 ]*[A-Za-z0-9])$/,
        get message() {
          return (
            i18next.t('ELD.Leading or trailing spaces, and special characters are not allowed') +
            '.'
          );
        }
      }
    ],
    get placeholder() {
      return i18next.t('ELD.Street Address');
    },
    className: '',
    style: {},
    componentProp: {},
    span: 12,
    component: Input,
    inactive: true
  },
  {
    name: 'declaredOdometer',
    get label() {
      return i18next.t('ELD.Odometer');
    },
    rules: [
      form => {
        return {
          required:
            MapActionToChartStatus[form.getFieldValue('action')] === 'Driving' ||
            form.getFieldValue('action') === 'PersonalConveyance' ||
            form.getFieldValue('action') === 'YardMove',
          get message() {
            return i18next.t('ELD.Odometer is required') + '.';
          }
        };
      },
      {
        type: 'number',
        max: 9999999,
        get message() {
          return i18next.t('ELD.The maximum value of the odometer is 9999999') + '.';
        }
      }
    ],
    fieldProp: {},
    get placeholder() {
      return i18next.t('ELD.Odometer');
    },
    className: '',
    style: {},
    componentProp: { type: 'number', style: { width: '100%' }, min: 0 },
    span: 12,
    component: LocalizedOdometerInput
  },
  {
    name: 'shipper',
    get label() {
      return i18next.t('ELD.Shipper');
    },
    rules: [
      form => {
        const trailer = form.getFieldValue('trailer1Id') ?? null;
        return {
          required: trailer !== null && trailer.trim() !== '',
          get message() {
            return i18next.t('ELD.Shipper is required');
          }
        };
      },
      {
        max: 40,
        get message() {
          return i18next.t('ELD.The maximum length of the shipper is 40') + '.';
        }
      },
      {
        pattern: /^(?!\s)([a-zA-Z0-9]*[A-Za-z0-9])$/,
        get message() {
          return (
            i18next.t('ELD.Leading or trailing spaces, and special characters are not allowed') +
            '.'
          );
        }
      }
    ],
    get placeholder() {
      return i18next.t('ELD.Shipper');
    },
    className: '',
    style: {},
    componentProp: {},
    span: 12,
    component: Input
  },
  {
    name: 'city',
    get label() {
      return i18next.t('ELD.City');
    },
    rules: [
      {
        max: 60,
        get message() {
          return i18next.t('ELD.The maximum length of the city is 60') + '.';
        }
      },
      {
        pattern: /^(?!\s)([a-zA-Z0-9][A-Za-z0-9 ]*[A-Za-z0-9])$/,
        get message() {
          return (
            i18next.t('ELD.Leading or trailing spaces, and special characters are not allowed') +
            '.'
          );
        }
      }
    ],
    get placeholder() {
      return i18next.t('ELD.City');
    },
    className: '',
    style: {},
    componentProp: {},
    span: 12,
    component: Input,
    inactive: true
  },
  {
    name: 'trailer1Id',
    get label() {
      return i18next.t('VehicleTypes.Form.Trailer');
    },
    rules: [
      {
        pattern: /^[a-zA-Z0-9]{1,10}( [a-zA-Z0-9]{1,10}){0,2}$/,
        get message() {
          return i18next.t('ELD.TrailerValidationWarn');
        }
      }
    ],
    get placeholder() {
      return i18next.t('VehicleTypes.Form.Trailer');
    },
    className: '',
    style: {},
    componentProp: {},
    span: 12,
    component: Input
  },
  {
    name: 'manifest',
    get label() {
      return i18next.t('ELD.Manifest');
    },
    rules: [
      form => {
        const trailer = form.getFieldValue('trailer1Id') ?? null;
        return {
          required: trailer !== null && trailer.trim() !== '',
          get message() {
            return i18next.t('ELD.Manifest is required');
          }
        };
      },
      {
        max: 60,
        get message() {
          return i18next.t('ELD.The maximum length of the manifest is 60') + '.';
        }
      },
      {
        pattern: /^(?!\s)([a-zA-Z0-9]*[A-Za-z0-9])$/,
        get message() {
          return (
            i18next.t('ELD.Leading or trailing spaces, and special characters are not allowed') +
            '.'
          );
        }
      }
    ],
    get placeholder() {
      return i18next.t('ELD.Manifest');
    },
    className: '',
    style: {},
    componentProp: {},
    span: 12,
    component: Input
  },
  {
    name: 'country',
    get label() {
      return i18next.t('ELD.Country');
    },
    rules: [],
    get placeholder() {
      return i18next.t('ELD.Country');
    },
    className: '',
    style: {},
    componentProp: { otherCountry: true },
    span: 12,
    component: CountrySelect,
    inactive: true
  },
  {
    name: 'state',
    get label() {
      return i18next.t('ELD.State');
    },
    rules: [],
    get placeholder() {
      return i18next.t('ELD.Select State');
    },
    className: '',
    style: {},
    componentProp: {},
    span: 6,
    component: StateSelect,
    inactive: true,
    dynamicConfig: {
      component: Input,
      get placeholder() {
        return i18next.t('ELD.State');
      },
      rules: [
        {
          max: 60,
          get message() {
            return i18next.t('ELD.The maximum length of the state is 60') + '.';
          }
        },
        {
          pattern: /^(?!\s)([a-zA-Z0-9][A-Za-z0-9 ]*[A-Za-z0-9])$/,
          get message() {
            return (
              i18next.t('ELD.Leading or trailing spaces, and special characters are not allowed') +
              '.'
            );
          }
        }
      ]
    }
  },
  {
    name: 'zipCode',
    get label() {
      return i18next.t('ELD.Zipcode');
    },
    rules: [
      {
        max: 12,
        get message() {
          return i18next.t('ELD.The maximum length of the zipcode is 12') + '.';
        }
      },
      {
        pattern: /^(?!\s)([a-zA-Z0-9][A-Za-z0-9 ]*[A-Za-z0-9])$/,
        get message() {
          return (
            i18next.t('ELD.Leading or trailing spaces, and special characters are not allowed') +
            '.'
          );
        }
      }
    ],
    get placeholder() {
      return i18next.t('ELD.Zipcode');
    },
    className: '',
    style: {},
    componentProp: {},
    span: 6,
    component: Input,
    inactive: true
  },
  {
    name: 'commodity',
    get label() {
      return i18next.t('ELD.Commodity');
    },
    rules: [
      form => {
        const trailer = form.getFieldValue('trailer1Id') ?? null;
        return {
          required: trailer !== null && trailer.trim() !== '',
          get message() {
            return i18next.t('ELD.Commodity is required');
          }
        };
      },
      {
        max: 40,
        get message() {
          return i18next.t('ELD.The maximum length of the commodity is 40') + '.';
        }
      },
      {
        pattern: /^(?!\s)([a-zA-Z0-9]*[A-Za-z0-9])$/,
        get message() {
          return (
            i18next.t('ELD.Leading or trailing spaces, and special characters are not allowed') +
            '.'
          );
        }
      }
    ],
    get placeholder() {
      return i18next.t('ELD.Commodity');
    },
    className: '',
    style: {},
    componentProp: {},
    span: 12,
    component: Input
  },
  {
    name: 'notes',
    get label() {
      return i18next.t('ELD.Comments');
    },
    rules: [
      {
        required: true,
        get message() {
          return i18next.t('ELD.Comments is required') + '.';
        }
      },
      {
        min: 4,
        max: 60,
        get message() {
          return i18next.t('ELD.DriverPortal.CommentLength', { min: 4, max: 60 }) + '.';
        }
      }
    ],
    componentProp: { multiple: true, rows: 5 },
    fieldProp: {
      required: true
    },
    get placeholder() {
      return i18next.t('ELD.Comments');
    },
    className: '',
    style: {},
    span: 12,
    component: Input.TextArea,
    rowFieldsSpan: 2
  },
  {
    name: 'editByUserId',
    get label() {
      return i18next.t('ELD.Edited By');
    },
    rules: [],
    get placeholder() {
      return i18next.t('ELD.Edited By');
    },
    className: '',
    style: {},
    componentProp: { disabled: true, editByFirstName: '', editByLastName: '' },
    span: 12,
    fieldProp: {
      required: true
    },
    component: EditByInput
  }
];

function mapFormDataToEvent(formData, eventSrc, driverId, locale) {
  const convertDistanceToKM = distance => {
    if (locale.formats.speed.unit === 'km') {
      return distance;
    } else {
      if (distance == null) {
        return null;
      }
      return parseFloat(LocalizationUtil.miletokm(distance).toFixed(1));
    }
  };

  const eventData = {
    id: eventSrc?.id,
    EventDateTime: formData.time.toISOString(),
    //RecordType: null,
    DriverId: parseInt(driverId),
    Action: formData.action,
    Timezone: eventSrc?.timeZone,
    EntryAt: eventSrc?.entryAt
      ? new Date(eventSrc?.entryAt).toISOString()
      : formData.time.toISOString(),
    DeclaredRegistration: eventSrc?.declaredRegistration,
    DeclaredOdometer:
      eventSrc?.id && (eventSrc.engineHours !== null || eventSrc.engineHours !== undefined)
        ? eventSrc.declaredOdometer === 'NaN'
          ? null
          : eventSrc.declaredOdometer
        : convertDistanceToKM(formData?.declaredOdometer),
    DeclaredLocation: formData?.declaredLocation,
    Uuid: eventSrc?.uuid,
    Location: eventSrc?.location,
    Source: eventSrc?.source || 'Request',
    Notes: formData.notes,
    Parameters: eventSrc?.params == null || eventSrc.params === '' ? null : eventSrc.params,
    DeviceId: eventSrc?.device?.id,
    GPS: eventSrc?.gps,
    Odo:
      eventSrc?.id && eventSrc.engineHours
        ? eventSrc.declaredOdometer === 'NaN'
          ? null
          : eventSrc.declaredOdometer
        : convertDistanceToKM(formData?.declaredOdometer),
    Lat: null,
    Lng: null,
    Manifest: formData.manifest,
    Shipper: formData.shipper,
    Commodity: formData.commodity,
    CoDriverId: formData.coDriver,
    StreetAddress: formData.streetAddress,
    City: formData.city,
    Country: formData.country,
    State: formData.state,
    ZipCode: formData.zipCode,
    EditedBy: formData.user?.id,
    VehicleId: formData.vehicle_id,
    Trailer1Id: formData.trailer1Id,
    Status: formData.status
  };
  return eventData;
}

function mapAddressFieldToAddressObject(addr) {
  return {
    streetAddress: addr.StreetAddress,
    city: addr.City,
    state: addr.State,
    country: addr.Country,
    zipCode: addr.ZipCode
  };
}

const _RecordField = ({ field, ...props }) => {
  const Component = field.component;
  return (
    <Col span={field.span}>
      <Form.Item label={field.label} name={field.name} rules={field.rules} {...field.fieldProp}>
        <Component
          className={field.className}
          style={field.style}
          placeholder={field.placeholder}
          {...field.componentProp}
        />
      </Form.Item>
    </Col>
  );
};

const RecordField = React.memo(_RecordField);

const GroupFields = ({ fields, ...props }) => {
  return (
    <Col span={12}>
      {fields.map((field, idx) => {
        const Component = field.component;
        return (
          <Form.Item label={field.label} name={field.name} rules={field.rules} {...field.fieldProp}>
            <Component
              className={field.className}
              style={field.style}
              placeholder={field.placeholder}
              {...field.componentProp}
            />
          </Form.Item>
        );
      })}
    </Col>
  );
};

export function _LogRecord({ data, onFormChanged, editMode, driverId, driverDetail, ...props }) {
  const [form] = Form.useForm();
  const local = useLocalization();
  const user = useUser();
  const [mixedRecordFields, setMixedRecordFields] = useState(recordFields);
  const initData = useMemo(() => {
    const editByFirstName = data?.createdBy?.firstName || user.firstName;
    const editByLastName = data?.createdBy?.lastName || user.lastName;
    const address = data?.address != null ? JSON.parse(data?.address) : {};
    if (data?.eventAt) {
      let odo = parseFloat(local.convertDistance(data.declaredOdometer || data.odometer));
      if (isNaN(odo)) {
        odo = null;
      }
      return {
        ...mapAddressFieldToAddressObject(address),
        ...data,
        coDriver: data?.user2?.id,
        editByUserId: data?.createdBy?.id || user.id,
        editByFirstName,
        editByLastName,
        vehicle_id: data?.vehicle?.id,
        time: moment.tz(
          new Date(data.eventAt),
          driverDetail?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
        ),
        declaredOdometer: odo
      };
    }

    return {
      ...mapAddressFieldToAddressObject(address),
      ...data,
      editByUserId: data?.createdBy?.id || user.id,
      time: moment()
        .tz(driverDetail?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
        .set('millisecond', 0),
      editByFirstName,
      editByLastName,
      vehicle_id: data?.vehicle?.id
    };
  }, [data, user, local]);

  const handleCountryChanged = useCallback(
    value => {
      let fieldsValue = form.getFieldsValue();
      fieldsValue['state'] = '';
      form.setFieldsValue(fieldsValue);
    },
    [form]
  );

  const handleFormChanged = useCallback(
    async (fieldValues, allValues) => {
      if (onFormChanged) {
        let validating = false;

        try {
          validating = await form.validateFields();
        } catch (e) {
          if (e?.errorFields?.length <= 0) {
            validating = true;
          }
        }

        if (validating && allValues.editByUserId !== user.id) {
          allValues.editByUserId = user.id;
          form.setFieldsValue(allValues);
        }

        onFormChanged(fieldValues, allValues, validating);
        setMixedRecordFields(prevFieldsConfig => {
          let configChanged = false;
          const cloneFields = () => {
            if (!configChanged) {
              prevFieldsConfig = cloneDeep(prevFieldsConfig);
              configChanged = true;
            }
          };

          Object.keys(fieldValues).forEach(k => {
            if (k === 'country') {
              const stateFieldConfig = prevFieldsConfig.find(f => f.name === 'state');
              if (fieldValues[k] === 'OT') {
                cloneFields();
                Object.assign(stateFieldConfig, stateFieldConfig.dynamicConfig);
              }
            } else if (k === 'action') {
              cloneFields();
              const vehicleFieldConfig = prevFieldsConfig.find(f => f.name === 'vehicle_id');
              vehicleFieldConfig.componentProp = {
                ...vehicleFieldConfig.componentProp,
                ...vehicleFieldConfig.dynamicComponentProp(allValues)
              };
            } else if (k === 'time') {
              cloneFields();
              const actionConfig = prevFieldsConfig.find(f => f.name === 'action');
              actionConfig.componentProp = {
                ...actionConfig.componentProp,
                date: moment.tz(
                  allValues.time,
                  driverDetail?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                )
              };
            }
          });
          return prevFieldsConfig;
        });
      }
    },
    [form, user, onFormChanged]
  );

  useEffect(() => {
    let updatedRecordFields = recordFields
      .filter(f => !f.inactive)
      .map(f => {
        const fieldProp = cloneDeep(f);

        if (f.name === 'country') {
          fieldProp.componentProp = { ...fieldProp.componentProp, onChange: handleCountryChanged };
        } else if (f.name === 'state') {
          if (initData.country === 'OT') {
            Object.assign(fieldProp, fieldProp.dynamicConfig);
          } else {
            fieldProp.componentProp = { country: initData.country };
          }
        } else if (f.name === 'editByUserId') {
          fieldProp.componentProp.editByFirstName = initData.editByFirstName;
          fieldProp.componentProp.editByLastName = initData.editByLastName;
        } else if (f.name === 'time') {
          if (
            initData?.dutyStatus === 'Off Duty Deferral' ||
            initData?.dutyStatus === 'Zone Change' ||
            initData?.dutyStatus === 'Cycle Change' ||
            (['Device', 'Unassigned'].includes(initData?.source) &&
              initData?.dutyStatus === 'Driving')
          ) {
            fieldProp.componentProp.disabled = true;
          }
        } else if (f.name === 'declaredOdometer') {
          if (
            initData?.dutyStatus === 'Off Duty Deferral' ||
            initData?.dutyStatus === 'Zone Change' ||
            initData?.dutyStatus === 'Cycle Change' ||
            (['Device', 'Unassigned'].includes(initData?.source) &&
              initData?.dutyStatus === 'Driving')
          ) {
            fieldProp.componentProp.disabled = true;
          }
        } else if (f.name === 'coDriver') {
          fieldProp.componentProp.primaryDriverId = parseInt(driverId);
        } else if (f.name === 'vehicle_id') {
          if (
            initData?.dutyStatus === 'Off Duty Deferral' ||
            initData?.dutyStatus === 'Zone Change' ||
            initData?.dutyStatus === 'Cycle Change' ||
            (['Device', 'Unassigned'].includes(initData?.source) &&
              initData?.dutyStatus === 'Driving')
          ) {
            fieldProp.componentProp.disabled = true;
          }
          fieldProp.componentProp = {
            ...fieldProp.componentProp,
            ...fieldProp.dynamicComponentProp(initData)
          };
        } else if (f.name === 'action') {
          if (
            initData?.dutyStatus === 'Off Duty Deferral' ||
            initData?.dutyStatus === 'Zone Change' ||
            initData?.dutyStatus === 'Cycle Change' ||
            (['Device', 'Unassigned'].includes(initData?.source) &&
              initData?.dutyStatus === 'Driving')
          ) {
            fieldProp.componentProp.disabled = true;
          }
          fieldProp.componentProp.driverDetail = driverDetail;
          fieldProp.componentProp.date = moment.tz(
            initData.time,
            driverDetail?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
          );
        } else if (f.name === 'declaredLocation') {
          if (
            initData?.dutyStatus === 'Off Duty Deferral' ||
            initData?.dutyStatus === 'Zone Change' ||
            initData?.dutyStatus === 'Cycle Change' ||
            (['Device', 'Unassigned'].includes(initData?.source) &&
              initData?.dutyStatus === 'Driving')
          ) {
            fieldProp.componentProp.disabled = true;
          }
        }

        if (
          initData.status === 'P' ||
          initData?.action === 'LogoffDriver' ||
          initData?.action === 'LogonDriver'
        ) {
          fieldProp.componentProp.disabled = true;
        }
        return fieldProp;
      });

    let idx = 0;
    do {
      if (
        idx % 2 === 0 &&
        updatedRecordFields[idx].rowFieldsSpan &&
        idx + 2 < updatedRecordFields.length
      ) {
        updatedRecordFields.splice(idx + 1, 2, [
          updatedRecordFields[idx + 1],
          updatedRecordFields[idx + 2]
        ]);
      } else if (
        idx % 2 === 0 &&
        updatedRecordFields[idx + 1].rowFieldsSpan &&
        idx + 2 < updatedRecordFields.length
      ) {
        updatedRecordFields[idx] = [updatedRecordFields[idx], updatedRecordFields[idx + 2]];
        updatedRecordFields.splice(idx + 2, 1);
      }
      idx++;
    } while (idx < updatedRecordFields.length);

    setMixedRecordFields(updatedRecordFields);
  }, [initData, editMode, driverId, driverDetail]);

  useEffect(() => {
    form.setFieldsValue(initData);
  }, [initData, form]);
  return (
    <Form
      layout="vertical"
      onValuesChange={handleFormChanged}
      initialValues={initData}
      form={form}
      colon={false}
      name="suggestRecordForm"
      className="showScrollbarsOnHover"
    >
      <Row gutter={10}>
        {mixedRecordFields.map((field, idx) =>
          Array.isArray(field) ? (
            <GroupFields fields={field} key={idx} />
          ) : (
            <RecordField field={field} key={idx} />
          )
        )}
      </Row>
    </Form>
  );
}
export const LogRecord = React.memo(_LogRecord, (prev, next) => {
  if (
    prev.editMode === next.editMode &&
    prev.driverId === next.driverId &&
    JSON.stringify(prev.data) === JSON.stringify(next.data)
  ) {
    return true;
  } else {
    return false;
  }
});

function ModalTitle({
  title,
  showRecordNav,
  hasPrev,
  hasNext,
  onPrevRecord,
  onNextRecord,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <Row className={styles.eventModalHeader}>
      <Col span={24}>
        <span className={styles.eventModalTitle}>{title}</span>
      </Col>
      {showRecordNav && (
        <Col span={24}>
          <Row>
            <Col span={6}>
              <Button
                className={styles.prevButton}
                onClick={onPrevRecord}
                disabled={!hasPrev}
                type="text"
                id={BUTTON_IDS.logRecordPrev}
              >
                &lt; {t('ELD.Prev')}
              </Button>
            </Col>
            <Col span={12}></Col>
            <Col span={6}>
              <Button
                className={styles.nextButton}
                onClick={onNextRecord}
                disabled={!hasNext}
                type="text"
                id={BUTTON_IDS.logRecordNext}
              >
                {t('ELD.Next')} &gt;
              </Button>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
}

export function LogEventModal({
  event,
  onSave,
  onCancel,
  title = 'Suggested New Record',
  showRecordModal,
  editMode,
  driverId,
  showRecordNav,
  hasPrevRecord,
  hasNextRecord,
  onPrevRecord,
  onNextRecord,
  driverDetail,
  ...props
}) {
  const [formData, setFormData] = useState(event);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [isFormValidating, setIsFormValidating] = useState(false);
  const { t } = useTranslation();
  const locale = useLocalization();

  const handleFormChanged = useCallback((changedValues, allValues, validating) => {
    setIsFormValidating(validating);
    if (!validating) {
      return;
    }
    setIsFormUpdated(true);
    setFormData({
      ...allValues,
      ...{ user: { id: allValues.editByUserId }, user2: { id: allValues.coDriver } }
    });
  }, []);

  const handleSaveRecord = useCallback(
    closeModal => {
      formData.status = 'P';
      if (onSave) {
        const eventData = mapFormDataToEvent(formData, event, driverId, locale);
        onSave(eventData, closeModal);
      }
    },
    [formData, event, onSave, driverId, locale]
  );

  const handleCancel = useCallback(() => {
    if (isFormUpdated) {
      confirmationModal(
        t('RouteGuard.Title'),
        t('RouteGuard.Message'),
        t('Common.Yes'),
        t('Common.No'),
        onCancel,
        null,
        null
      );
    } else {
      if (onCancel) {
        onCancel();
      }
    }
  }, [t, onCancel, isFormUpdated]);

  const handleAccept = useCallback(() => {
    formData.status = 'A';
    if (onSave) {
      const eventData = mapFormDataToEvent(formData, event, driverId, locale);
      onSave(eventData, true);
    }
  }, [formData, event, onSave, driverId, locale]);

  const handleReject = useCallback(() => {
    formData.status = 'R';
    if (onSave) {
      const eventData = mapFormDataToEvent(formData, event, driverId, locale);
      onSave(eventData, true);
    }
  }, [formData, event, onSave, driverId, locale]);

  const footer = (
    <Row gutter={10}>
      {event.status === 'P' && driverId === event?.user?.id && (
        <Col>
          <Button
            id={BUTTON_IDS.logRecordAccept}
            disabled={!isFormValidating}
            onClick={handleAccept}
            type="primary"
          >
            {t('ELD.Accept')}
          </Button>
          <Button
            id={BUTTON_IDS.logRecordReject}
            disabled={!isFormValidating}
            onClick={handleReject}
            type="default"
          >
            {t('ELD.Reject')}
          </Button>
        </Col>
      )}
      {['L', 'E', 'M', undefined].some(v => v === event.status) && (
        <Col>
          <Button
            id={BUTTON_IDS.logRecordSuggestClose}
            disabled={!isFormValidating}
            onClick={handleSaveRecord}
            type="primary"
          >
            {t('ELD.Suggest and Close')}
          </Button>
          {event.status !== undefined && showRecordNav && (
            <Button
              disabled={!isFormValidating}
              onClick={() => handleSaveRecord(false)}
              type="primary"
              id={BUTTON_IDS.logRecordSuggest}
            >
              {t('ELD.Suggest')}
            </Button>
          )}
        </Col>
      )}
      <Col>
        <Button id={BUTTON_IDS.logRecordCancel} onClick={handleCancel} type="default">
          {t('Common.Cancel')}
        </Button>
      </Col>
    </Row>
  );

  const header = (
    <ModalTitle
      title={title}
      showRecordNav={showRecordNav}
      hasNext={hasNextRecord}
      hasPrev={hasPrevRecord}
      onNextRecord={() => onNextRecord && onNextRecord(event)}
      onPrevRecord={() => onPrevRecord && onPrevRecord(event)}
    />
  );
  return (
    <Modal
      title={header}
      className={styles.recordModal}
      open={showRecordModal}
      onOk={handleSaveRecord}
      footer={footer}
      closable={false}
    >
      <LogRecord
        data={event}
        onFormChanged={handleFormChanged}
        editMode={editMode}
        driverId={driverId}
        driverDetail={driverDetail}
      />
    </Modal>
  );
}
