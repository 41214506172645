import React, { useRef, useCallback, useEffect } from 'react';
import { GanntChart, predictStripePattern } from './GanntChart';

const getCanvasSize = (container, axisOptions) => {
  let canvasSize = { x: container.offsetWidth, y: container.offsetHeight };
  if (axisOptions?.leftAxis) {
    canvasSize.y =
      axisOptions?.leftAxis.catergories.length *
        (axisOptions?.leftAxis.height + axisOptions?.leftAxis.gutter) -
      axisOptions?.leftAxis.gutter +
      (axisOptions.paddingTop || 0) +
      (axisOptions.paddingBottom || 0) +
      (axisOptions.showBottomAxis ? 21 : 0);
  }
  return canvasSize;
};

function _GanntChartComponent({
  startPeriod,
  endPeriod,
  data,
  dataAction,
  canvasSize,
  dataRender,
  dataTypeRenderOptions,
  leftAxisRender,
  bottomAxisRender,
  axisOptions
}) {
  const containerRef = useRef();
  const chartRef = useRef(null);

  const hookOnContainer = useCallback(
    container => {
      if (containerRef.current === container || container == null) {
        return;
      }
      containerRef.current = container;
      if (canvasSize == null) {
        canvasSize = getCanvasSize(container, axisOptions);
      }
      chartRef.current = new GanntChart({
        startPeriod: startPeriod,
        endPeriod: endPeriod,
        patternDefs: [predictStripePattern],
        container: containerRef.current,
        axisRender: {
          bottomAxisRender: bottomAxisRender,
          leftAxisRender: leftAxisRender
        },
        axisOptions: axisOptions,
        canvasSize: canvasSize,
        data: data,
        dataRender: dataRender,
        dataTypeRenderOptions: dataTypeRenderOptions
      });
      chartRef.current.render();
    },
    [
      startPeriod,
      endPeriod,
      data,
      dataRender,
      dataTypeRenderOptions,
      leftAxisRender,
      bottomAxisRender,
      axisOptions
    ]
  );

  useEffect(() => {
    if (chartRef.current != null) {
      let canvasSize = null;
      if (containerRef.current) {
        canvasSize = getCanvasSize(containerRef.current, axisOptions);
      }

      chartRef.current.refresh({
        startPeriod: startPeriod,
        endPeriod: endPeriod,
        canvasSize: canvasSize,
        axisRender: {
          bottomAxisRender: bottomAxisRender,
          leftAxisRender: leftAxisRender
        },
        axisOptions: axisOptions,
        data: data,
        dataRender: dataRender,
        dataTypeRenderOptions: dataTypeRenderOptions
      });
    }
  }, [
    startPeriod,
    endPeriod,
    data,
    dataRender,
    dataTypeRenderOptions,
    leftAxisRender,
    bottomAxisRender,
    axisOptions
  ]);

  useEffect(() => {
    if (chartRef.current != null) {
      chartRef.current.onDataAction(dataAction);
    }
  }, [dataAction]);
  return <div className="ganntChartContainer" ref={hookOnContainer}></div>;
}

export const GanntChartComponent = React.memo(_GanntChartComponent);
