import React, { useState } from 'react';

import {
  LineChart as RechartsLineChart,
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line
} from 'recharts';
import { lineChartMargin, LINE_PROPERTIES } from './constants';
import { getDomainMargins, renderLegend } from './helpers';
import PropTypes from 'prop-types';

import styles from './LineChart.module.scss';

export const LineChart = ({ data, properties, height }) => {
  const [lines, setLines] = useState(data.lines);
  const { minDomain, maxDomain } = getDomainMargins(data);

  return (
    <div className={styles.chartContainer}>
      <ResponsiveContainer width="100%" height={height}>
        <RechartsLineChart
          width={properties?.width || LINE_PROPERTIES.width}
          height={properties?.height || LINE_PROPERTIES.height}
          data={data.chart}
          margin={lineChartMargin}
        >
          <CartesianGrid />
          <XAxis dataKey={properties?.dataKey || LINE_PROPERTIES.dataKey} tickMargin={20} />
          <YAxis domain={properties?.domain || [minDomain, maxDomain]} tickMargin={20} />
          <Tooltip />
          <Legend content={renderLegend(lines, setLines)} height={70} />
          {lines.map(
            line =>
              line.checked && (
                <Line
                  key={line.key}
                  type={properties?.type || LINE_PROPERTIES.type}
                  name={line.value}
                  isAnimationActive={false}
                  dataKey={line.key}
                  stroke={line.color}
                  strokeWidth={properties?.strokeWidth || LINE_PROPERTIES.strokeWidth}
                />
              )
          )}
        </RechartsLineChart>
      </ResponsiveContainer>
    </div>
  );
};

LineChart.propTypes = {
  data: PropTypes.object,
  legend: PropTypes.object,
  properties: PropTypes.object
};
