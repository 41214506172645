import { Col, Row } from 'antd';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { ModeSwitch } from './ModeSwitch';
import { Space, Button } from 'antd';
import moment from 'moment';
import { Loading } from 'components/loading/Loading';
import { GeoSuggest } from 'components/GeoSuggest';
import { DateRangePicker } from 'components/ant/DateTime/DateRangePicker';
import { disableNext30Days } from '../../helpers';
import styles from './Lens.module.scss';
import { LensResultPane } from './LensResultPane';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import { LENS_STEPS } from './constants';
import { useGeofenceSuggests } from 'components/GeoSuggest/useGeofenceSuggests';
import { BUTTON_IDS } from 'utils/globalConstants';
import dayjs from 'dayjs';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';

export function LensSearchPane({
  mode,
  onModeChange,
  lensDevices,
  withInGeofence,
  onLocationSelected,
  dateRange,
  onDateRangeChanged,
  onSearch,
  onClearSearch,
  geoSuggestRef,
  currentStep,
  setCurrentStep,
  onLensDeviceTripSelected,
  resetLensDevices,
  fleets,
  handleFilteredFleetsChange
}) {
  const { t } = useTranslation();
  const localization = useLocalization();

  const { isFetching, noDataFetched, dataFetched, devices } = useMemo(() => {
    return {
      isFetching: lensDevices?.isFetching,
      noDataFetched:
        !lensDevices?.isFetching && lensDevices?.hasFetched && !lensDevices.list?.length,
      dataFetched: !lensDevices?.isFetching && lensDevices?.hasFetched && !!lensDevices.list.length,
      devices: lensDevices.list || []
    };
  }, [lensDevices]);

  const isDrawGeofenceActive = useMemo(() => currentStep === 1, [currentStep]);
  const geofence = useMemo(() => withInGeofence?.geofence, [withInGeofence]);

  const getStepTitle = useCallback(
    (stepIndex, title) => (
      <>
        <span>{t('Tracking.Proximity.Steps', { num: stepIndex })}:</span>
        <span>{title}</span>
      </>
    ),
    []
  );

  const { geoSuggestProps } = useGeofenceSuggests();

  return (
    <>
      {dataFetched ? (
        <LensResultPane
          {...{
            t,
            localization,
            devices,
            onLensDeviceTripSelected,
            onClearSearch,
            fleets
          }}
        />
      ) : (
        <>
          <Row className={styles.nearestSearchPane} style={{ padding: '16px' }}>
            <Col span={24}>
              <Row className={styles.stepContainer}>
                <Col className={styles.step} span={24}>
                  <span>{t('Tracking.Proximity.Step1')}:</span>
                  <span>{t('Tracking.Proximity.SelectFleet')}</span>
                </Col>
                <Col span={24}>
                  <AntMultiselect
                    className={styles.fleetFilter}
                    size={'middle'}
                    title={
                      fleets?.some(value => !value.checked)
                        ? t('Common.Fleets')
                        : t('Common.AllFleets')
                    }
                    onFilter={handleFilteredFleetsChange}
                    data={fleets}
                  />
                </Col>
              </Row>
              <ModeSwitch mode={mode} onChange={onModeChange} />
              <LensSearchStep
                {...{
                  stepTitle: getStepTitle(3, t('Tracking.SelectLocation')),
                  StepContent: () => (
                    <>
                      <p>{t('Tracking.SelectLocationDescription')}</p>
                      <div className={styles.stepInput}>
                        <GeoSuggest
                          tabIndex={0}
                          ref={geoSuggestRef}
                          className={styles.geoSearchbox}
                          placeholder={t('Tracking.PlaceOrAddressOrGeofence')}
                          onSuggestSelect={onLocationSelected}
                          autoComplete="off"
                          onFocus={e => {
                            setCurrentStep(LENS_STEPS.SELECT_LOCATION);
                          }}
                          {...geoSuggestProps}
                        />
                      </div>
                    </>
                  )
                }}
              />
              <LensSearchStep
                {...{
                  stepTitle: getStepTitle(4, t('Tracking.DrawGeofence')),
                  onClick: () => setCurrentStep(LENS_STEPS.DRAW_GEOFENCE),
                  StepContent: () => (
                    <>
                      <div className={styles.description}>
                        {t('Tracking.DrawGeofenceDescription')}
                      </div>
                      <Button
                        size="large"
                        style={{ justifyContent: 'left', fontWeight: 400 }}
                        type={isDrawGeofenceActive ? 'primary' : 'default'}
                        onClick={() => {
                          setCurrentStep(LENS_STEPS.DRAW_GEOFENCE);
                        }}
                        id={BUTTON_IDS.lensSearchDraw}
                      >
                        {t('Tracking.DrawGeofence')}
                      </Button>
                    </>
                  )
                }}
              />
              <LensSearchStep
                {...{
                  stepTitle: getStepTitle(5, t('Tracking.SpecifyTime')),
                  onClick: () => setCurrentStep(LENS_STEPS.SPECIFY_TIME_PERIOD),
                  StepContent: () => (
                    <>
                      <div className={styles.description}>
                        {t('Tracking.SpecifyTimeDescription')}
                      </div>
                      <DateRangePicker
                        className={styles.dateRangePicker}
                        size="large"
                        showTime={{ format: 'HH:mm' }}
                        format={localization.formats.time.formats.dby.toUpperCase() + ' HH:mm'}
                        maxDayRange={30}
                        availableDatesRange={[dayjs().subtract(6, 'month'), dayjs()]}
                        defaultDates={[dayjs(dateRange.from), dayjs(dateRange.to)]}
                        disabledDate={current => disableNext30Days(current, dayjs(dateRange?.from))}
                        onDateRangeChanged={dates => {
                          onDateRangeChanged(dayjs(dates[0]), dayjs(dates[1]));
                          resetLensDevices();
                        }}
                        onFocus={() => {
                          setCurrentStep(LENS_STEPS.SPECIFY_TIME_PERIOD);
                        }}
                        showPast7Days={false}
                        showPast14Days={false}
                        showLastMonth={false}
                      />
                    </>
                  )
                }}
              />
              <div className={styles.fetching}>
                {isFetching && <Loading />}
                {noDataFetched && <p>{t('Tracking.ProximityNoVehicles')}</p>}
              </div>
            </Col>
            <Col span={24} className={styles.paneFooter}>
              <Space size={16}>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    setCurrentStep(LENS_STEPS.SEARCHING);
                    onSearch();
                  }}
                  disabled={!geofence || isFetching}
                  id={BUTTON_IDS.lensSearchFind}
                >
                  {t('Tracking.FindVehicles')}
                </Button>
                <Button
                  size="large"
                  disabled={isFetching}
                  onClick={onClearSearch}
                  id={BUTTON_IDS.lensSearchReset}
                >
                  {t('Common.Reset')}
                </Button>
              </Space>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

const LensSearchStep = ({ stepTitle, onClick, StepContent }) => {
  return (
    <Row className={styles.stepContainer} onClick={onClick}>
      <Col className={styles.step} span={24}>
        {stepTitle}
      </Col>
      <Col>{StepContent()}</Col>
    </Row>
  );
};
