import React from 'react';
import styles from './Forms.module.scss';
import { FormControl } from 'react-bootstrap';
import i18n from 'i18nextConfig';
import { dictionary, icons } from './constants';
import FakeCheckbox from 'components/form/fake-checkbox/FakeCheckbox';
import InfoRow from 'components/form/info-row/InfoRow';
import { format } from 'utils/dates';
import { prefilledSmartjobsData } from './constants';

export const getTitleRowAndFormElement = (element, type, id, input, style) => {
  const showSmartjobsValue = element.DynamicContent?.source;
  return (
    <div>
      <span className={styles.elementTypeContainer}>
        {id && <h5 className={styles.elementIndex}>{id}.</h5>}
        <i className={styles.icon} style={{ content: `url(${type})` }} />
        <span className={styles.elementType}>{i18n.t(`Forms.ActualForm.${element.Type}`)}</span>
      </span>
      <div className={styles.elementName}>{element.Text}</div>
      {input && (
        <FormControl
          as={input}
          readOnly
          className={style || styles.stockInput}
          rows={3}
          value={
            showSmartjobsValue
              ? showSmartjobsValue + ` (${i18n.t('Forms.ActualForm.FromSmartjobs')})`
              : ''
          }
        />
      )}
    </div>
  );
};

export const getFollowUpElement = element => {
  const randId = `randId-${Math.random()}`;
  return element?.Options?.map?.((option, index) => {
    if (option?.FollowUp) {
      const followUpElements = option?.FollowUp?.map?.((entry, entryIndex) => {
        const icon = icons.find(ic => ic.includes(entry.Type.toLowerCase()));
        const followUp = getTitleRowAndFormElement(entry, dictionary[icon], false, 'input');

        return (
          <React.Fragment key={`followUpEl-${index}-${entryIndex}`}>
            {option.FollowUp && entry.AllowNonApplicable ? (
              <div className={styles.marginTop10}>
                <FakeCheckbox checked={entry.AllowNonApplicable} disabled />
                <span className={styles.checkboxText}>{i18n.t('Forms.View.AllowNAOption')}</span>
              </div>
            ) : (
              ''
            )}
            {option.FollowUp && entry.AllowBlank ? (
              <div className={styles.marginTop10}>
                <FakeCheckbox checked={entry.AllowBlank} disabled />
                <span className={styles.checkboxText}>{i18n.t('Forms.View.AllowBlank')}</span>
              </div>
            ) : (
              ''
            )}
            <div className={styles.followUpElement}>{followUp}</div>
            {entry?.Options?.find?.(option => option.Value !== 'N/A') && getSelectionValue(entry)}
          </React.Fragment>
        );
      });
      return (
        <div className={styles.followUp} key={`div-${randId}-${index}`}>
          <h5>Follow Up</h5>
          <div>
            {i18n.t('Common.If')} "{option.Value}"
          </div>
          {followUpElements}
        </div>
      );
    } else {
      return null;
    }
  });
};

// Description Element
export const getDescriptionLabel = (element, type, id) => {
  return (
    <div className={styles.labelContainerWithLine}>
      {getTitleRowAndFormElement(element, type, id, 'textarea', styles.textArea)}
    </div>
  );
};

export const getDescriptionValue = element => {
  return (
    <div className={styles.flexRow}>
      <div className={styles.rowValueTitle} style={{ minWidth: '100px' }}>
        {i18n.t('Forms.ActualForm.Content')}
      </div>
      <div className={styles.rowValueContent}>{element.Content}</div>
    </div>
  );
};

export const getDescriptionElement = (element, type, id) => {
  return (
    <InfoRow
      labelWidth="40%"
      label={getDescriptionLabel(element, type, id)}
      value={getDescriptionValue(element)}
      key={`formElement-${id}`}
      sxValue={{
        width: '60%',
        marginLeft: '20px'
      }}
      styles={styles}
    />
  );
};

// Selection Element
const getSelectionLabel = (element, type, id) => {
  return (
    <div className={styles.labelContainerWithLine}>
      {getTitleRowAndFormElement(element, type, id, 'input', styles.selectionInput)}
    </div>
  );
};

const getSelectionValue = element => {
  const selectionOptions = element?.Options?.map?.(option => (
    <InfoRow
      key={`option-${element?.Options?.indexOf(option)}`}
      label={`${i18n.t('Forms.ActualForm.Option')} ${element?.Options?.indexOf(option) + 1}`}
      value={option.Value}
      styles={styles.optionRows}
      justifyContent={'space-between'}
      labelWidth="50%"
    />
  ));

  return (
    <div className={styles.flexRow}>
      <div className={styles.selectionOptions}>{selectionOptions}</div>
      <div className={styles.selectionFollowUp}>{getFollowUpElement(element)}</div>
    </div>
  );
};

const getSelectionElement = (element, type, id) => {
  return (
    <InfoRow
      labelWidth="40%"
      label={getSelectionLabel(element, type, id)}
      value={getSelectionValue(element)}
      key={`formElement-${id}`}
      sxValue={{
        width: '60%',
        marginLeft: '20px'
      }}
      styles={styles}
    />
  );
};

// Default Element
export const getDefaultLabel = (element, type, id) => {
  return (
    <div className={styles.radioLabel}>
      {getTitleRowAndFormElement(element, type, id, 'input', styles.textInput)}
    </div>
  );
};

export const getDefaultValue = element => {
  return (
    <div className={styles.flexRow}>
      <div className={styles.rowValueCheckboxContainer}>
        <FakeCheckbox checked={element.AllowBlank} disabled />
        <span className={styles.checkboxText}>{i18n.t('Forms.View.AllowBlank')}</span>
      </div>
    </div>
  );
};

export const getDefaultElement = (element, type, id) => {
  return (
    <InfoRow
      labelWidth="40%"
      label={getDefaultLabel(element, type, id)}
      value={element.Type !== 'Section' ? getDefaultValue(element) : ''}
      key={`formElement-${id}`}
      sxValue={{ width: '60%' }}
      styles={styles}
    />
  );
};

// Yes/No and Ok/Not Ok Element
export const getRadioLabel = (element, type, id) => {
  const randId = `RandId-${Math.random()}`;
  return (
    <div className={styles.radioLabel}>
      {getTitleRowAndFormElement(element, type, id)}
      <span className={styles.textArea}>
        {element?.Options?.map?.((option, index) => {
          return (
            <span key={`${randId}-${index}`}>
              <input className={styles.checkboxText} type="radio" readOnly disabled />
              <label className={styles.checkboxText}>
                {i18n.t(`Forms.ActualForm.${option.Value}`)}
              </label>
            </span>
          );
        })}
      </span>
    </div>
  );
};

export const getRadioValue = element => {
  const selectionOptions = element?.Options?.reduce?.((finalOptions, option) => {
    if (option.FollowUp) {
      const followUpElements = option?.FollowUp?.map?.(entry => {
        const icon = icons.find(ic => ic.includes(entry.Type.toLowerCase()));
        const followUp = getTitleRowAndFormElement(entry, dictionary[icon], false, 'input');

        return <div className={styles.followUpElement}>{followUp}</div>;
      });
      finalOptions.push(
        <div className={styles.followUp}>
          <h5>{i18n.t('Forms.View.FollowUp')}</h5>
          <div>
            {i18n.t('Common.If')} "{i18n.t(`Forms.ActualForm.${option.Value}`)}"
          </div>
          {followUpElements}
        </div>
      );
    }
    return finalOptions;
  }, []);
  return (
    <div className={styles.flexRow}>
      <span className={styles.rowValueCheckboxContainer}>
        <div className={styles.rowValueContent}>
          <FakeCheckbox checked={element.AllowBlank} disabled />
          <span className={styles.checkboxText}>{i18n.t('Forms.View.AllowBlank')}</span>
        </div>
        <div className={styles.rowValueContent}>
          <FakeCheckbox checked={element.AllowNonApplicable} disabled />
          <span className={styles.checkboxText}>{i18n.t('Forms.View.AllowNAOption')}</span>
        </div>
      </span>
      <div className={styles.selectionFollowUp}>{selectionOptions}</div>
    </div>
  );
};

export const getRadioElement = (element, type, id) => {
  return (
    <InfoRow
      labelWidth="40%"
      label={getRadioLabel(element, type, id)}
      value={getRadioValue(element)}
      key={`formElement-${id}`}
      sxValue={{ width: '60%' }}
      styles={styles}
    />
  );
};

export const getFormElements = elements => {
  const formElements = [];
  if (typeof elements !== 'undefined') {
    elements.forEach(element => {
      let icon = icons.find(
        icon =>
          element.Type.toLowerCase().includes(icon) ||
          icon.toLowerCase().includes(element.Type.toLowerCase())
      );
      switch (element.Type) {
        case 'Description':
          formElements.push(
            getDescriptionElement(element, dictionary[icon], formElements.length + 1)
          );
          break;
        case 'Selection':
          formElements.push(
            getSelectionElement(element, dictionary[icon], formElements.length + 1)
          );
          break;
        case 'Yes/No':
        case 'Ok/Not Ok':
          formElements.push(getRadioElement(element, dictionary[icon], formElements.length + 1));
          break;
        default:
          formElements.push(getDefaultElement(element, dictionary[icon], formElements.length + 1));
          break;
      }
    });
  }
  return formElements;
};

export const getFormDate = (date, localization) => {
  return date ? format(new Date(date), localization.formats.time.formats.dby_imp) : '';
};

export const handleEditAction = (history, id, formData) => () => {
  history.push(`/settings/forms/edit/id/${id}`, { dataToEdit: formData });
};

export const getValuesForPrefilledDropdown = ({ type }) => {
  switch (type) {
    case 'Numeric':
      return prefilledSmartjobsData
        .filter(entry => entry.type === 'number')
        .map(entry => ({ value: entry.name, label: entry.name }));
    default:
      return prefilledSmartjobsData
        .filter(entry => entry.type === 'string')
        .map(entry => ({ value: entry.name, label: entry.name }));
  }
};

export const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};
