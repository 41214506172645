/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";

/**
 * The Form model module.
 * @module model/Form
 * @version 1.0
 */
class Form {
  /**
   * Constructs a new <code>Form</code>.
   * @alias module:model/Form
   */
  constructor() {
    Form.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Form</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Form} obj Optional instance to populate.
   * @return {module:model/Form} The populated <code>Form</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Form();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("version")) {
        obj["version"] = ApiClient.convertToType(data["version"], "Number");
      }
      if (data.hasOwnProperty("companyId")) {
        obj["companyId"] = ApiClient.convertToType(data["companyId"], "Number");
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("content")) {
        obj["content"] = ApiClient.convertToType(data["content"], "String");
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("alertOnCompletion")) {
        obj["alertOnCompletion"] = ApiClient.convertToType(
          data["alertOnCompletion"],
          "Boolean"
        );
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Form.prototype["id"] = undefined;

/**
 * @member {Number} version
 */
Form.prototype["version"] = undefined;

/**
 * @member {Number} companyId
 */
Form.prototype["companyId"] = undefined;

/**
 * @member {String} name
 */
Form.prototype["name"] = undefined;

/**
 * @member {String} content
 */
Form.prototype["content"] = undefined;

/**
 * @member {module:model/Form.StatusEnum} status
 */
Form.prototype["status"] = undefined;

/**
 * @member {Boolean} alertOnCompletion
 */
Form.prototype["alertOnCompletion"] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Form["StatusEnum"] = {
  /**
   * value: "ENABLED"
   * @const
   */
  ENABLED: "ENABLED",

  /**
   * value: "DISABLED"
   * @const
   */
  DISABLED: "DISABLED",

  /**
   * value: "READY_FOR_DOWNLOAD"
   * @const
   */
  READY_FOR_DOWNLOAD: "READY_FOR_DOWNLOAD",

  /**
   * value: "PENDING"
   * @const
   */
  PENDING: "PENDING",

  /**
   * value: "AVAILABLE"
   * @const
   */
  AVAILABLE: "AVAILABLE",

  /**
   * value: "UNAVAILABLE"
   * @const
   */
  UNAVAILABLE: "UNAVAILABLE",

  /**
   * value: "FAILED"
   * @const
   */
  FAILED: "FAILED"
};

export default Form;
