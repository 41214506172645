import React from 'react';
import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUnreadMessages } from 'features/messaging/hooks';
import styles from './Messaging.module.scss';
export const MessagingLeftNavTitle = () => {
  const { t } = useTranslation();
  const { unreadMessageCount } = useUnreadMessages();
  return (
    <Badge
      className={styles.unreadMessageCount}
      size="small"
      offset={[9, -2]}
      count={unreadMessageCount}
    >{`${t('Common.Messaging')}`}</Badge>
  );
};
