import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Button, Table, Space, Row, Col, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import styles from './DriverLog.module.scss';
import DropdownTreeSelect from 'components/form/treeselect/DropdownTreeSelect';
import { selectAll, PATH_SPLIT } from 'components/form/treeselect/TreeSelect';
import { sortBy } from 'lodash';
import { LogEventModal } from './LogRecord';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import {
  DutyStatus,
  eldStatusMap,
  formatEventDuration,
  OriginDescripion,
  formatDate
} from './util';
import { saveEventRecord } from 'features/eld/eventsApi';
import { useDispatch } from 'react-redux';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import moment from 'moment-timezone';
import { useDrivers } from 'features/users/usersSlice';
import { useUserKey } from 'features/user/userSlice';
import { useTranslation } from 'react-i18next';
import i18next from 'i18nextConfig';
import { extractSuggestEditError } from 'features/eld/suggestEditErrorCodes';
import { useVehicles } from 'features/fleets/fleetsSlice';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { DutyStatusDescription } from '../util/dutystatus';
import { toLower } from 'lodash';
import { Can } from 'features/permissions';
import { BUTTON_IDS } from 'utils/globalConstants';

const ActionWithoutDuration = [
  'ExemptionAreaExited',
  'ZoneChange',
  'CycleChange',
  'OffDutyDeferral',
  'LogonDriver',
  'LogoffDriver'
];

class SortUtil {
  static compareStringIgnoreCase(a, b) {
    const a_i = a?.toLowerCase() || '';
    const b_i = b?.toLowerCase() || '';

    if (a_i > b_i) {
      return 1;
    } else if (a_i === b_i) {
      return 0;
    } else {
      return -1;
    }
  }

  static compareNumber(a, b) {
    if (a > b) {
      return 1;
    } else if (a === b) {
      return 0;
    } else {
      return -11;
    }
  }

  static compareDate(a, b) {
    const dateA = new Date(a).getTime();
    const dateB = new Date(b).getTime();
    if (dateA > dateB) {
      return 1;
    } else if (dateA === dateB) {
      return 0;
    } else {
      return -1;
    }
  }
}

const genAntSortColumnConfig = (sortDir, defDir, sortFn, dataKey) => {
  return {
    sortDirections: sortDir,
    defaultSortOrder: defDir,
    sorter: sortFn && ((a, b, sort) => sortFn(a[dataKey], b[dataKey], sort)),
    ellipsis: false
  };
};

const defaultSortConfig = (sortFn, dataKey) =>
  genAntSortColumnConfig(['ascend', 'descend', 'ascend'], 'descend', sortFn, dataKey);
const normalSortConfig = (sortFn, dataKey) =>
  genAntSortColumnConfig(['ascend', 'descend', 'ascend'], null, sortFn, dataKey);

function getEventStyle(status) {
  switch (status) {
    case 'P':
      return styles.pendingEvent;
    case 'A':
    case 'M':
      return styles.acceptedEvent;
    case 'L':
      return styles.signedEvent;
    default:
      break;
  }
  return '';
}

function OdometerHeader() {
  const localization = useLocalization();
  return <>{`${i18next.t('ELD.Odometer')} (${localization.formats.speed.unit.toUpperCase()})`}</>;
}

export const DriverLogGridColumns = [
  {
    title: '',
    dataIndex: 'status',
    key: 'status',
    canFilter: false,
    render: (_, record) =>
      record.status !== 'L' && (
        <Tooltip title={eldStatusMap[record.status]}>
          <span className={getEventStyle(record.status)}></span>
        </Tooltip>
      ),
    width: 15,
    fixed: 'left'
  },
  {
    get title() {
      return i18next.t('Home.Time');
    },
    dataIndex: 'formatedTime',
    key: 'time',
    canFilter: true,
    width: 220,
    fixed: 'left',
    ...defaultSortConfig(SortUtil.compareDate, 'formatedTime')
  },
  {
    get title() {
      return i18next.t('ELD.Duration');
    },
    dataIndex: 'duration',
    key: 'duration',
    canFilter: true,
    width: 140,
    ...normalSortConfig(SortUtil.compareNumber, 'duration'),
    render: (text, record) => {
      if (record.action !== null && !ActionWithoutDuration.includes(record.action)) {
        return text;
      }
    }
  },
  {
    get title() {
      return i18next.t('ELD.Duty Status');
    },
    dataIndex: 'dutyStatus',
    key: 'dutyStatus',
    canFilter: true,
    width: '130px',
    ...normalSortConfig(SortUtil.compareStringIgnoreCase, 'dutyStatus'),
    render: (text, record) => {
      if (record.params !== null && record.params.trim() !== '') {
        const params = JSON.parse(record.params);
        if (params.isIntermediate) {
          return DutyStatusDescription['IntermediateLog'];
        }
      }
      return DutyStatusDescription[text];
    }
  },
  {
    get title() {
      return i18next.t('Common.Location');
    },
    dataIndex: 'declaredLocation',
    key: 'declaredLocation',
    canFilter: true,
    ...normalSortConfig(SortUtil.compareStringIgnoreCase, 'declaredLocation'),
    render: (text, record) => {
      if (text) {
        return text;
      } else if (record.address) {
        try {
          const addr = JSON.parse(record.address);
          const loc = [];
          if (addr.streetAddress || addr.StreetAddress) {
            loc.push(addr.streetAddress || addr.StreetAddress);
          }
          if (addr.city || addr.City) {
            loc.push(addr.city || addr.City);
          }

          if ((addr.state && addr.zipCode == null) || (addr.State && addr.ZipCode == null)) {
            loc.push(addr.state || addr.State);
          } else if (
            (addr.state == null && addr.zipCode != null) ||
            (addr.State == null && addr.ZipCode != null)
          ) {
            loc.push(addr.zipCode || addr.ZipCode);
          } else if (
            (addr.state != null && addr.zipCode != null) ||
            (addr.State != null && addr.ZipCode != null)
          ) {
            loc.push((addr.state || addr.State) + ' ' + (addr.zipCode || addr.ZipCode));
          }

          if (addr.country || addr.Country) {
            loc.push(addr.country || addr.Country);
          }
          return loc.join(', ');
        } catch (e) {
          console.error(e);
        }
      }
      return '';
    }
  },
  {
    get title() {
      return i18next.t('ELD.Comments');
    },
    dataIndex: 'notes',
    key: 'comments',
    ...normalSortConfig(SortUtil.compareStringIgnoreCase, 'notes'),
    width: 200
  },
  {
    get title() {
      return i18next.t('Common.Vehicle');
    },
    dataIndex: 'vehicleName',
    key: 'vehicleName',
    ...normalSortConfig(SortUtil.compareStringIgnoreCase, 'vehicleName'),
    width: 150
  },
  {
    get title() {
      return i18next.t('ELD.Carrier_DOT#');
    },
    dataIndex: 'carrier',
    key: 'carrier',
    canFilter: true,
    width: '200px',
    ...normalSortConfig(SortUtil.compareStringIgnoreCase, 'carrier'),
    render: (text, record) => {
      if (record.params !== null && record.params.trim() !== '') {
        const params = JSON.parse(record.params);
        if (params.carrier) {
          return params.carrier.name + ' | ' + params.carrier.official_identifier;
        }
      }
      return '';
    }
  },
  {
    get title() {
      return <OdometerHeader />;
    },
    dataIndex: 'displayOdometer',
    key: 'displayOdometer',
    canFilter: true,
    width: 150,
    ...normalSortConfig(SortUtil.compareNumber, 'displayOdometer')
  },
  {
    get title() {
      return i18next.t('ELD.Engine Hours');
    },
    dataIndex: 'engineHours',
    key: 'engineHours',
    canFilter: true,
    width: 150,
    ...normalSortConfig(SortUtil.compareNumber, 'engineHours'),
    render: text => {
      return !text ? '' : parseFloat(text).toFixed(1);
    }
  },
  {
    get title() {
      return i18next.t('ELD.Origin');
    },
    dataIndex: 'source',
    key: 'origin',
    canFilter: true,
    width: 100,
    ...normalSortConfig(SortUtil.compareStringIgnoreCase, 'source'),
    render: text => {
      return OriginDescripion[text] || text;
    }
  },
  {
    get title() {
      return i18next.t('Common.TableColumns.Actions');
    },
    key: 'actions',
    width: 100,
    render: (_, record) => (
      <Space>
        <Can
          otherConditions={[
            () =>
              !record?.isExempt &&
              record?.action !== 'LogonDriver' &&
              record?.action !== 'LogoffDriver'
          ]}
        >
          <Tooltip title={i18next.t('ELD.Suggest Edit Record')}>
            <Button
              id={BUTTON_IDS.driverLogGridEdit}
              onClick={() => record?.onEdit?.(record)}
              type="link"
            >
              <FontAwesomeIcon icon={faPen} />{' '}
            </Button>
          </Tooltip>
        </Can>
      </Space>
    ),
    fixed: 'right'
  }
];

export function DriverLogGrid({
  data,
  company,
  onEventUpdated,
  driverId,
  onRowClick,
  onRowMouseEnter,
  onRowMouseLeave,
  driverDetail,
  refreshData
}) {
  const drivers = useDrivers();
  const vehicles = useVehicles();
  const userKey = useUserKey();
  const [searchText, setSearchText] = useState(null);
  const [generalFilters, setGeneralFilters] = useState();

  const [currentRecord, setCurrentRecord] = useState(null);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [hasPrevRecord, setHasPrevRecord] = useState(false);
  const [hasNextRecord, setHasNextRecord] = useState(false);

  const locale = useLocalization();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleEditEvent = useCallback(record => {
    setCurrentRecord(record);
    setShowRecordModal(true);
  }, []);

  const filterData = useMemo(() => data?.filter(d => !d.isPrevEvent && d.status !== 'M'), [data]);
  const driver = useMemo(() => drivers?.find(d => d.id === parseInt(driverId)), [
    driverId,
    drivers
  ]);
  const isExempt =
    driver?.rulesets?.filter(
      rs =>
        toLower(rs.ruleset) === 'eld0' ||
        toLower(rs.ruleset) === 'eldcan0' ||
        toLower(rs.ruleset) === 'eld99'
    )?.length > 0;

  const eventData = useMemo(() => {
    let rows = filterData?.map((d, idx) => {
      let row = Object.assign({}, d);
      row.onEdit = handleEditEvent;
      row.isExempt = isExempt;

      if (row.vehicle?.id) {
        row.vehicleName = vehicles.find(v => v.id === row.vehicle.id)?.name;
      }
      row.formatedTime = row.eventAt
        ? format(
            moment.tz(
              row.eventAt,
              driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
            ),
            locale.formats.time.formats.dby_imsp + ' zz',
            { timeZone: driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone }
          )
        : '';
      row.dutyStatus = DutyStatusDescription[row.action];
      if (row.params && row.params.trim() !== '') {
        const params = JSON.parse(row.params);
        if (params.carrier) {
          row.carrier = params.carrier.name + ' | ' + params.carrier.official_identifier;
        }
      }

      if (!ActionWithoutDuration.includes(row.action)) {
        let nextIndex = idx + 1;
        while (
          nextIndex < filterData.length &&
          ActionWithoutDuration.includes(filterData[nextIndex].action)
        ) {
          nextIndex++;
        }
        if (nextIndex < filterData.length) {
          let timeDiff = moment(filterData[nextIndex].eventAt)
            .startOf('second')
            .diff(
              row.isPrevEvent
                ? moment(filterData[nextIndex].eventAt).startOf('day')
                : moment(row.eventAt).startOf('second'),
              'second'
            );
          const duration = moment.duration(timeDiff, 'second');
          row.duration = formatEventDuration(duration);
        } else {
          if (row.isPrevEvent) {
            const duration = moment.duration(24 * 60 * 60, 'second');
            row.duration = formatEventDuration(duration);
          } else if (
            moment()
              .tz(driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
              .valueOf() >
            moment
              .tz(row.eventAt, driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
              .endOf('day')
              .toDate()
              .getTime()
          ) {
            const duration = moment.duration(
              moment
                .tz(
                  row.eventAt,
                  driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                )
                .endOf('day')
                .add(1, 'second')
                .toDate()
                .getTime() - row.eventAt
            );
            row.duration = formatEventDuration(duration);
          }
        }
      }

      row.key = row.id;
      let odo = row.declaredOdometer || row.odometer;
      if (isNaN(odo)) {
        row.displayOdometer = '';
      } else {
        row.displayOdometer = locale.convertDistance(odo);
      }

      return row;
    });
    return rows;
  }, [filterData, driver, locale, vehicles]);

  useEffect(() => {
    //generate filters
    const filters = {};
    const valueGroups = {};
    for (let idx = 0; idx < DriverLogGridColumns.length; idx++) {
      const column = DriverLogGridColumns[idx];
      if (column.canFilter) {
        let node = {
          label: column.title,
          function: undefined,
          nodeKey: (idx + 1).toString(),
          id: idx + 1,
          children: [
            {
              ...selectAll,
              label: t('Common.' + selectAll.name),
              nodeKey: idx + 1 + PATH_SPLIT + 0
            }
          ]
        };
        filters[node.id] = node;
        valueGroups[node.id] = {};

        const sortedList = sortBy(eventData, [o => o[column.dataIndex]]);

        for (let i = 0, l = sortedList.length; i < l; i++) {
          const item = sortedList[i];
          const parentNode = filters[idx + 1];
          var value = item[column.dataIndex];

          const params =
            item.params !== null && item.params.trim() !== '' ? JSON.parse(item.params) : '{}';
          if (item.params) {
            if (column.key === 'dutyStatus') {
              if (params.isIntermediate) {
                value = DutyStatus.IntermediateLog;
              }
            }
            if (column.key === 'carrier') {
              if (params.carrier) {
                value = params.carrier.name + ' | ' + params.carrier.official_identifier;
              }
            }
          }

          if (!value) {
            continue;
          }
          if (!valueGroups[parentNode.id][value]) {
            const nodeId = parentNode.children.length;
            var labelText = item[column.dataIndex];
            if (item.params) {
              if (column.key === 'dutyStatus') {
                if (params.isIntermediate) {
                  labelText = DutyStatus.IntermediateLog;
                }
              }
              if (column.key === 'carrier') {
                if (params.carrier) {
                  value = params.carrier.name + ' | ' + params.carrier.official_identifier;
                }
              }
            }
            const childNode = {
              id: nodeId,
              label: labelText,
              function: undefined,
              nodeKey: parentNode.nodeKey + PATH_SPLIT + nodeId,
              dataKey: column.dataIndex
            };
            parentNode.children.push(childNode);
            valueGroups[parentNode.id][value] = true;
          }
        }
      }
    }
    setGeneralFilters(filters);
  }, [eventData]);

  const gridData = useMemo(() => {
    if (!eventData) {
      return [];
    }

    let rows = eventData;
    if (!searchText || !searchText.trim()) {
      //no filter
    } else {
      rows = rows?.filter(d => {
        return DriverLogGridColumns.some(
          c =>
            d[c.key]
              ?.toString()
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) >= 0
        );
      });
    }

    if (rows && generalFilters) {
      rows = rows.filter(row => {
        let matchSearch = true;
        for (let key in generalFilters) {
          if (
            generalFilters[key].children[0].checked ||
            !generalFilters[key].children.some(c => c.checked)
          ) {
            continue;
          }

          for (let idx = 1; idx < generalFilters[key].children.length; idx++) {
            const node = generalFilters[key].children[idx];
            if (node.checked) {
              var labelText = row[node.dataKey];

              if (row.params && row.params.trim() !== '') {
                const params = JSON.parse(row.params);
                if (key === 4 && params.isIntermediate) {
                  labelText = DutyStatus.IntermediateLog;
                }
                if (key === 8 && params.carrier) {
                  labelText = params.carrier.name + ' | ' + params.carrier.official_identifier;
                }
              }
              if (labelText !== node.label) {
                matchSearch = false;
              } else {
                matchSearch = true;
                break;
              }
            }
          }

          if (matchSearch) {
            break;
          }
        }
        return matchSearch;
      });
    }

    setHasPrevRecord(false);
    if (rows?.length > 1) {
      setHasNextRecord(true);
    } else {
      setHasNextRecord(false);
    }

    return rows;
  }, [eventData, searchText, generalFilters, handleEditEvent, locale]);

  const handleSearchChange = useCallback(value => {
    setSearchText(value);
  }, []);

  const handleGeneralFilterChange = useCallback(filters => {
    Object.values(filters).forEach(f => {
      if (typeof f.label === 'object') {
        f.label = DriverLogGridColumns.find(c => c.dataIndex === 'displayOdometer').title;
      }
    });

    setGeneralFilters(filters);
  }, []);

  const handleEditRecord = useCallback(
    (record, closeModal) => {
      if (typeof record.Parameters === 'string') {
        try {
          record.Parameters = JSON.parse(record.Parameters);
        } catch (e) {
          console.error(e);
        }
      }

      record.Parameters = { ...record.Parameters, UserId: record.EditedBy };
      record.Source = 'Request';
      saveEventRecord(userKey, company?.id, record).then(
        () => {
          const message = record.id
            ? t('ELD.Update Record Succeeded')
            : t('ELD.Suggest Record Succeeded');
          dispatch(openToast({ type: ToastType.Success, message: message }));
          if (onEventUpdated) {
            onEventUpdated();
          }
          if (closeModal) {
            setShowRecordModal(false);
          }
          if (refreshData) {
            refreshData();
          }
        },
        ({ err, resp }) => {
          if (resp.statusCode === 412) {
            const extraMessage = extractSuggestEditError(resp.body, locale, {
              users: drivers,
              vehicles: vehicles
            });
            const message =
              (record.id ? t('ELD.Update Record failed') : t('ELD.Suggest Record failed')) +
              '. ' +
              extraMessage;
            dispatch(openToast({ type: ToastType.Warning, message: message }));
          } else {
            const message =
              (record.id ? t('ELD.Update Record failed') : t('ELD.Suggest Record failed')) +
              '. ' +
              err;
            dispatch(openToast({ type: ToastType.Error, message: message }));
          }
        }
      );
    },
    [company, dispatch, onEventUpdated, locale, drivers, vehicles, refreshData]
  );

  const handleCancelEditRecord = useCallback(() => {
    setShowRecordModal(false);
    setCurrentRecord(null);
  }, []);

  const handlePrevRecord = useCallback(
    record => {
      let recordIndex = gridData.indexOf(record);
      recordIndex = Math.max(0, --recordIndex);
      setCurrentRecord(gridData[recordIndex]);
    },
    [gridData]
  );

  const handleNextRecord = useCallback(
    record => {
      let recordIndex = gridData.indexOf(record);
      recordIndex = Math.min(gridData.length - 1, ++recordIndex);
      setCurrentRecord(gridData[recordIndex]);
    },
    [gridData]
  );

  useEffect(() => {
    let recordIndex = gridData.indexOf(currentRecord);
    if (recordIndex === 0 && gridData.length > 1) {
      setHasPrevRecord(false);
      setHasNextRecord(true);
    } else if (recordIndex > 0 && recordIndex + 1 < gridData.length) {
      setHasPrevRecord(true);
      setHasNextRecord(true);
    } else {
      setHasPrevRecord(true);
      setHasNextRecord(false);
    }
  }, [currentRecord, gridData]);

  return (
    <Row className={styles.driverLogGrid}>
      <Col span={24}>
        <Space className={styles.gridToolbar}>
          <AntSearchbar onFilter={handleSearchChange} />
          <DropdownTreeSelect
            title={t('Common.Filter')}
            showSelectAll={true}
            tree={generalFilters}
            onChange={handleGeneralFilterChange}
          />
          <span>
            {gridData?.length || 0} {t('ELD.Events')}
          </span>
        </Space>
      </Col>
      <Col span={24}>
        <Table
          className={styles.gridTable}
          dataSource={gridData}
          columns={DriverLogGridColumns}
          pagination={false}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => onRowClick && onRowClick(record, rowIndex, event),
              onMouseEnter: event => onRowMouseEnter && onRowMouseEnter(record, rowIndex, event),
              onMouseLeave: event => onRowMouseLeave && onRowMouseLeave(record, rowIndex, event)
            };
          }}
          scroll={{ x: 'max-content', y: 'calc(100vh - 279px - 62px - 64px - 55px - 56px - 37px)' }}
        />
        {showRecordModal && (
          <LogEventModal
            title={
              t('ELD.Suggest Edit Record') +
              ' - ' +
              formatDate(
                moment
                  .tz(
                    currentRecord.eventAt,
                    driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                  )
                  .toDate(),
                locale.formats.time.formats.dby_imsp + ' Z',
                driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
              )
            }
            showRecordModal={showRecordModal}
            onSave={handleEditRecord}
            onCancel={handleCancelEditRecord}
            event={currentRecord}
            onPrevRecord={handlePrevRecord}
            onNextRecord={handleNextRecord}
            hasPrevRecord={hasPrevRecord}
            hasNextRecord={hasNextRecord}
            showRecordNav={gridData && gridData.length > 1}
            editMode={true}
            driverId={driverId}
            driverDetail={driverDetail}
          />
        )}
      </Col>
    </Row>
  );
}
