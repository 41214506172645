import React, { useMemo, useEffect } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useCountryOptions } from 'utils/countryState';

export function CountrySelect({ disabled, otherCountry, value, ...props }) {
  const countrysOption = useCountryOptions(false);
  const { t } = useTranslation();

  const countryObject = useMemo(() => {
    const countryOptionList = countrysOption;

    if (!!value && countrysOption.find(i => i.value === value) === undefined) {
      countryOptionList.push({ label: value, value: value });
    }

    return countryOptionList.sort((a, b) => a.label.localeCompare(b.label));
  }, [countrysOption, value]);

  return (
    <Select
      {...props}
      value={value}
      disabled={disabled}
      showSearch
      getPopupContainer={triggerNode => triggerNode.parentNode}
      placeholder={t('Common.Select a country')}
    >
      {countryObject?.map(r => (
        <Select.Option key={r.label} value={r.value}>
          {r.label}
        </Select.Option>
      ))}
      {otherCountry && (
        <Select.Option key={'OT'} value={'OT'}>
          {t('Countries.Other Country')}
        </Select.Option>
      )}
    </Select>
  );
}
