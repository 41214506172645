import { AuthUtil } from 'features/auth/authUtil';

export const EventNamesMap = {
  'Ng::Event::AR': {
    name: (event, t) => {
      if (event.alarmCode != null && event.alarmCode.description != null) {
        return t(`Alerts.AlarmAlert.${event.alarmCode.description}`);
      } else {
        return t(`Alerts.AlarmAlert.${event.description}`);
      }
    },
    eventUrl: event => {
      return `events/${event.id}/alarm?time_at=${event.timeAt}`;
    }
  },
  'Ng::Event::Camera': {
    name: (event, t) => t('Events.CameraEvent'),
    eventUrl: event => `events/${event.id}/camera?time_at=${event.timeAt}`
  },
  'Ng::Event::DE': { name: event => event.action + ' ' + event.description, eventUrl: event => '' },
  'Ng::Event::FatigueComplianceViolation': {
    name: (event, t) => t('Events.FatigueComplianceViolation'),
    eventUrl: () => ``
  },
  'Ng::Event::FatiguePredictedViolation': {
    name: (event, t) => t('Events.FatiguePredictedViolation'),
    eventUrl: event => `events/${event.id}/fatigue_violation?time_at=${event.timeAt}`
  },
  'Ng::Event::FatigueViolation': {
    name: (event, t) => t('Events.FatigueViolation'),
    eventUrl: event => `events/${event.id}/fatigue_violation?time_at=${event.timeAt}`
  },
  'Ng::Event::EldViolation': {
    name: (event, t) => t('Events.ELDViolation'),
    eventUrl: event => `events/${event.id}/fatigue_violation?time_at=${event.timeAt}`
  },
  'Ng::Event::FatigueSubmit': { name: () => 'Enforcement Request', eventUrl: () => '' },
  'Ng::Event::Form': {
    name: (event, t) => t('Events.Form'),
    eventUrl: event => `events/${event.id}/form?time_at=${event.timeAt}`
  },
  'Ng::Event::GeofenceEntry': {
    name: (event, t) => t('Events.GeofenceEntry'),
    eventUrl: event => `events/${event.id}/geofence`
  },
  'Ng::Event::GeofenceOvertime': {
    name: (event, t) => t('Events.GeofenceOvertime'),
    eventUrl: event => `events/${event.id}/geofence`
  },
  'Ng::Event::GeofenceUndertime': {
    name: (event, t) => t('Events.GeofenceUndertime'),
    eventUrl: event => `events/${event.id}/geofence`
  },
  'Ng::Event::GeofenceSpeed': {
    name: (event, t) => t('Events.GeofenceSpeed'),
    eventUrl: event => `events/${event.id}/speed?time_at=${event.timeAt}`
  },
  'Ng::Event::GeofenceExit': {
    name: (event, t) => t('Events.GeofenceExit'),
    eventUrl: event => `events/${event.id}/geofence`
  },
  'Ng::Event::IOR': {
    name: event =>
      event.description != null && event.description.length != null
        ? event.description.join(', ')
        : event.eventType,
    eventUrl: event => ``
  },
  'Ng::Event::GPIO': {
    name: (event, t) =>
      `${event.which ? t(`Alerts.GPIO.${event.which}`, event.which) : ''} ${
        event.customNoun ? event.customNoun : t(`Alerts.GPIO.${event.noun}`, event.noun || '')
      } ${event.customVerb ? event.customVerb : t(`Alerts.GPIO.${event.verb}`, event.verb || '')}`,
    eventUrl: event => event.detailsUrl
  },
  'Ng::Event::NHVAS': {
    name: (event, t) => t('Events.MassManagement'),
    eventUrl: event => event.detailsUrl
  },
  'Ng::Event::Moving': {
    name: (event, t) => t('Events.Moving'),
    eventUrl: event => `events/${event.id}/movement?time_at=${event.timeAt}`
  },
  'Ng::Event::PTC': {
    name: (event, t) => t('Events.PTC'),
    eventUrl: event => `events/${event.id}/pretrip?time_at=${event.timeAt}`
  },
  'Ng::Event::RouteCompliance': {
    name: (event, t) => t('Events.RouteCompliance'),
    eventUrl: event => `events/${event.id}/route_compliance?time_at=${event.timeAt}`
  },
  'Ng::Event::SE': {
    name: (event, t) => (event.geofence_id != null ? t('Events.GeofenceSpeed') : t('Events.Speed')),
    eventUrl: event => `events/${event.id}/speed?time_at=${event.timeAt}`
  },
  'Ng::Event::Stationary': {
    name: (event, t) => t('Events.Stationary'),
    eventUrl: event => `events/${event.id}/movement?time_at=${event.timeAt}`
  },
  'Ng::Event::SystemLogBook': {
    name: (event, t) => t('Events.SystemLogBook'),
    eventUrl: event => `events/${event.id}/logbook?time_at=${event.timeAt}`
  },
  'Ng::Event::TripStarted': {
    name: (event, t) => t('Events.TripStarted'),
    eventUrl: event => `events/${event.id}/route_compliance?time_at=${event.timeAt}`
  },
  'Ng::Event::TripFinished': {
    name: (event, t) => t('Events.TripFinished'),
    eventUrl: event => `events/${event.id}/route_compliance?time_at=${event.timeAt}`
  },
  'Ng::Event::TripMarked': {
    name: (event, t) => t('Events.TripMarked'),
    eventUrl: event => `events/${event.id}/route_compliance?time_at=${event.timeAt}`
  },
  'Ng::Event::TripEntry': {
    name: (event, t) => t('Events.TripEntry'),
    eventUrl: event => `events/${event.id}/route_compliance?time_at=${event.timeAt}`
  },
  'Ng::Event::TripExit': {
    name: (event, t) => t('Events.TripExit'),
    eventUrl: event => `events/${event.id}/route_compliance?time_at=${event.timeAt}`
  },
  'Ng::Event::TpmsPressure': {
    name: (event, t) => t('Events.TpmsPressure'),
    eventUrl: event => `events/${event.id}/tpms?time_at=${event.timeAt}`
  },
  'Ng::Event::TpmsHighTemperature': {
    name: (event, t) => t('Events.TpmsHighTemperature'),
    eventUrl: event => `events/${event.id}/tpms?time_at=${event.timeAt}`
  },
  'Ng::Event::TpmsLowBattery': {
    name: (event, t) => t('Events.TpmsLowBattery'),
    eventUrl: event => `events/${event.id}/tpms?time_at=${event.timeAt}`
  },
  'Ng::Event::TpmsLostSensor': {
    name: (event, t) => t('Events.TpmsLostSensor'),
    eventUrl: event => `events/${event.id}/tpms?time_at=${event.timeAt}`
  },
  'Ng::Event::VPM_EA': {
    name: (event, t) => t('Events.VPM_EA'),
    eventUrl: event => `events/${event.id}/vpm_ea?time_at=${event.timeAt}`
  },
  'Ng::Event::VPM_HB': {
    name: (event, t) => t('Events.VPM_HB'),
    eventUrl: event => `events/${event.id}/vpm_hb?time_at=${event.timeAt}`
  },
  'Ng::Event::VPM_HC': {
    name: (event, t) => t('Events.VPM_HC'),
    eventUrl: event => `events/${event.id}/vpm_hc?time_at=${event.timeAt}`
  },
  'Ng::Event::VPM_OR': {
    name: (event, t) => t('Events.VPM_OR'),
    eventUrl: event => `events/${event.id}/vpm_or?time_at=${event.timeAt}`
  },
  'Ng::Event::VPM_IT': {
    name: (event, t) => t('Events.VPM_IT'),
    eventUrl: event => `events/${event.id}/vpm_it?time_at=${event.timeAt}`
  },
  'Ng::Event::VPM_ECT': {
    name: (event, t) => t('Events.VPM_ECT'),
    eventUrl: event => `events/${event.id}/vpm_ect?time_at=${event.timeAt}`
  },
  'Ng::Event::VPM_EOT': {
    name: (event, t) => t('Events.VPM_EOT'),
    eventUrl: event => `events/${event.id}/vpm_eot?time_at=${event.timeAt}`
  },
  'Ng::Event::VPM_EOP': {
    name: (event, t) => t('Events.VPM_EOP'),
    eventUrl: event => `events/${event.id}/vpm_eop?time_at=${event.timeAt}`
  },
  'Ng::Event::VPM_IM': {
    name: (event, t) => t('Tracking.Events.Impact'),
    eventUrl: event => `events/${event.id}/vpm_im?time_at=${event.timeAt}`
  },
  'Ng::Event::TachoFatigueAlert': {
    name: (_event, t) => t('Events.TachoAlert'),
    eventUrl: _event => `fatigue/tacho`
  },
  'Ng::ScheduleEvent': {
    name: (event, t) => {
      if (event.schedule.type === 'Ng::DriverManagementSchedule') {
        return t('Events.DriverManagementSchedule');
      } else if (event.schedule.type === 'Ng::VehicleMaintenanceSchedule') {
        return t('Events.VehicleMaintenanceSchedule');
      } else {
        return event.schedule.type;
      }
    },
    eventUrl: event => {
      if (event.schedule.type === 'Ng::DriverManagementSchedule') {
        return `ng/admin/driver_management_schedules/${event.schedule.id}`;
      } else if (event.schedule.type === 'Ng::VehicleMaintenanceSchedule') {
        return `ng/admin/vehicle_maintenance_schedules/${event.schedule.id}`;
      } else {
        return '';
      }
    }
  }
};

export const EventTypeMapToNgEventType = {
  CAMERA: 'Ng::Event::Camera'
};

export class EventUtil {
  static getEventType(event, t) {
    if (EventNamesMap[event.eventType] != null && event.event) {
      return EventNamesMap[event.eventType].name(event.event, t);
    } else {
      return event.eventType;
    }
  }

  static generateUrlForEventDetailPage(alertData, userId, userApi) {
    let url = AuthUtil.generateAuthUrl(userId, userApi);
    let urlPart = '';
    if (EventNamesMap[alertData.eventType] != null) {
      const eventData = {
        ...alertData.event,
        detailsUrl: alertData.detailsUrl?.substring(1),
        timeAt: 0
      };
      if (alertData.eventAt != null) {
        eventData.timeAt = parseInt(alertData.eventAt / 1000); //NextGen does not need the last 3 digits;
      }
      urlPart = encodeURI(`/${EventNamesMap[alertData.eventType].eventUrl(eventData)}`);
      /*
      if (
        OPEN_EVENT_DETAIL_PAGE === EventDetailPageOpenMethods.SimpleEmbed ||
        OPEN_EVENT_DETAIL_PAGE === EventDetailPageOpenMethods.SimpleTab
      ) {
        urlPart += '&show_header=false';
      } else {
        urlPart += '&show_header=true';
      }
      */
    } else {
      // use the detailsUrl as fallback provided by the notifications API
      urlPart = alertData.detailsUrl;
    }
    return url + '&url=' + encodeURIComponent(urlPart);
  }

  static generateEventUrl(event, userId, userApi) {
    const eventType = EventTypeMapToNgEventType[event.eventType];
    if (EventNamesMap[eventType]) {
      const url = AuthUtil.generateAuthUrl(userId, userApi);
      const timeAt = parseInt(event.timeAt / 1000); //NextGen does not need the last 3 digits;
      let urlPart = encodeURI(
        `/${EventNamesMap[eventType].eventUrl({ id: event.id, timeAt: timeAt })}`
      );
      return url + '&url=' + urlPart;
    } else {
      return null;
    }
  }
}
