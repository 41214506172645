import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Popover, Spin } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { Can } from 'features/permissions';
import style from './MoreMenu.module.scss';

export const MoreMenu = ({
  items = [],
  trigger = 'click',
  placement = 'bottomRight',
  size = 'xl',
  border = false,
  useNewIcon = false,
  preventClose = false,
  ...other
}) => {
  const [isVisible, setVisibility] = useState(false);
  const className = cn(style.moreContainer, { [style.xl]: size === 'xl', [style.border]: border });

  return (
    <Popover
      content={
        <ul className={style.list}>
          {items.map((item, index) => {
            return (
              <Can {...item.canPermissions} key={item.name}>
                {item.link ? (
                  <Link
                    key={`moreMenu-${index}`}
                    className={style.listItem}
                    to={{ pathname: item.link, state: item?.state }}
                    id={item.id}
                  >
                    {item.name}
                  </Link>
                ) : item.component ? (
                  <item.component
                    listItem={item.props.listItem}
                    enrolmentId={item.props.enrolmentId}
                    schemes={item.props.schemes}
                    enrolments={item.props.enrolments}
                  />
                ) : (
                  <li
                    key={`moreMenu-${index}`}
                    id={item.id || ''}
                    className={`${style.listItem} ${item.danger ? style.danger : ''} ${
                      item.isLoading || item.disabled ? style.disabled : ''
                    }`}
                    onClick={e => {
                      if (!preventClose) {
                        setVisibility(false);
                      }
                      if (item.onClick) {
                        item.onClick(e);
                      }
                    }}
                  >
                    {item.name}
                    {item.isLoading && <Spin size="small" className={style.buttonLoader} />}
                  </li>
                )}
              </Can>
            );
          })}
        </ul>
      }
      trigger={trigger}
      open={isVisible}
      placement={placement}
      onOpenChange={() => setVisibility(!isVisible)}
      {...other}
    >
      {/*TODO: useNewIcon is the new design standard for the MoreMenu
      Added it conditionally in order to keep the existing usage, to be deprecated in time*/}
      {useNewIcon ? (
        <div className={`${style.moreIcon} ${border ? style.hasBorder : ''}`}>
          <i style={{ fontSize: '26px' }} className={'tn-i-elipsis'} />
        </div>
      ) : (
        <MoreOutlined className={className} />
      )}
    </Popover>
  );
};
