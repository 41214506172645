import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { useFleeetsByCompanyId } from 'features/fleets/fleetsSlice';
import { useCan, entities } from 'features/permissions';
import { bulkEditCache } from '../helper';

import styles from '../DevicesBulkEdit.module.scss';

const RowVisibleFleetCount = 3;

const MoreIcon = ({
  count = 0,
  expanded,
  showLessTitle,
  onShowLess = () => {},
  onShowMore = () => {}
}) =>
  expanded ? (
    <Button size="small" onClick={onShowLess}>
      {showLessTitle}
    </Button>
  ) : (
    <Button size="small" icon={<FontAwesomeIcon size="xs" icon={faPlus} />} onClick={onShowMore}>
      {count}
    </Button>
  );

export const DeviceFleetsCell = ({ fleetIds, companyId, tableRef, getCompanyName, rowIndex }) => {
  const { t } = useTranslation();

  const { fleets: fleetsByCompanyId } = useFleeetsByCompanyId({ companyId });

  const [expanded, setExpended] = useState(false);

  const can = useCan();
  const canViewFleet = can({ everyEntity: [entities.FLEET_VIEW] });

  const { visibleFleets, expandableCount } = useMemo(() => {
    const validFleets =
      fleetsByCompanyId
        ?.filter(f => fleetIds?.some(fleetId => String(fleetId) === String(f.id)))
        ?.filter(fleet => fleet.id) || [];
    const expandableCount = validFleets.length - RowVisibleFleetCount;
    return {
      expandableCount,
      visibleFleets: validFleets.slice(
        0,
        expandableCount > 0 && !expanded ? RowVisibleFleetCount : validFleets.length
      )
    };
  }, [fleetsByCompanyId, fleetIds, expanded]);

  const onToggleExpand = useCallback(() => {
    setExpended(prev => !prev);
    if (tableRef) {
      bulkEditCache.clear(rowIndex);
      tableRef.recomputeRowHeights(rowIndex);
      tableRef.scrollToRow(rowIndex);
    }
  }, [tableRef, rowIndex]);

  useEffect(() => {
    return () => {
      if (expanded && tableRef) {
        bulkEditCache.clear(rowIndex);
        tableRef.recomputeRowHeights(rowIndex);
      }
    };
  }, [expanded, tableRef, rowIndex]);

  return (
    <div className={styles.cellMultilineContainer}>
      {visibleFleets.map((fleet, i) => (
        <div key={`row-fleets-${fleet.id}-${i}`} id={`row-fleets-${fleet.id}`}>
          {
            <span>
              {canViewFleet ? (
                <Link to={`/settings/fleets/id/${fleet.id}`}>{fleet.name}</Link>
              ) : (
                <span>{fleet.name}</span>
              )}
            </span>
          }{' '}
          {fleet.company?.id ? `(${getCompanyName(fleet.company.id)})` : ''}
        </div>
      ))}
      {expandableCount > 0 && (
        <MoreIcon
          expanded={expanded}
          count={expandableCount}
          showLessTitle={t('Common.ShowLess')}
          onShowLess={onToggleExpand}
          onShowMore={onToggleExpand}
        />
      )}
    </div>
  );
};
