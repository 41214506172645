import { Empty as AntEmpty, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './Empty.module.scss';

export const Empty = ({ image, description, ...props }) => {
  const { t } = useTranslation();

  return (
    <AntEmpty
      image={image || AntEmpty.PRESENTED_IMAGE_SIMPLE}
      description={
        <Typography.Text className={styles.description}>
          {description || t('NoData')}
        </Typography.Text>
      }
      {...props}
    />
  );
};
