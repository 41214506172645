import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useCompanyGeofenceProviders, useCurrentCompany } from 'features/company/companySlice';
import { useTrackingData } from 'features/tracking/trackingSlice';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import {
  fetchDeviceStats,
  applyUpdatesOnDevices,
  resetUpdates
} from 'features/devices/devicesStatsSlice';
import { useDeviceUpdatesAll } from 'features/mqtt/mqttSlice';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';

import { MapCard } from './MapCards';
import MapTilesPositioning, { availableMapTilesPositions } from './MapTilesPositioning';
import MapTilesExpand from './MapTilesExpand';
import { TrackingPage } from '../TrackingPage';
import { TrackingPaths, TrackingLens } from '../constants';
import { showGeofenceType } from 'containers/Settings/constants';
import { filterGeofencesByShowTypes } from 'features/geofences/geofencesUtil';

export const MapTiles = () => {
  const trackingData = useTrackingData();
  const deviceUpdates = useDeviceUpdatesAll();
  const userPreferences = useUserPreferences();
  const currentCompany = useCurrentCompany();
  const geofenceProviders = useCompanyGeofenceProviders();

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const initialMapTiles = JSON.parse(localStorage.getItem('mapTiles') || '{}');

  const [initialMapTilesSet, setInitialMapTilesSet] = useState(false);
  const [mapTiles, setMapTiles] = useState(
    (initialMapTiles && initialMapTiles.mapTiles) || [
      { searchText: '', devices: null },
      { searchText: '', devices: null },
      { searchText: '', devices: null }
    ]
  );
  const [mapTilesLayout, setMapTilesLayout] = useState(
    (initialMapTiles && initialMapTiles.mapTilesPosition) || 'horizontalTwoOne'
  );
  const [resetMapTilePosition, setResetMapTilePosition] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const trackingLens = TrackingLens(t).MapTiles;
  const [selectedInterval, setSelectedInterval] = useState(600);

  const path = window.location.pathname;

  let flexWrap = 'wrap';
  let minWidth = '50%';
  let minHeight = 'auto';
  let flexDirection = 'row';

  switch (mapTilesLayout) {
    case 'justOne':
      break;
    case 'horizontalTwo':
      minWidth = '100%';
      break;
    case 'verticalTwo':
      break;
    case 'verticalTwoOne':
      flexDirection = 'column';
      minHeight = '50%';
      break;
    case 'verticalOneTwo':
      flexWrap = 'wrap-reverse';
      flexDirection = 'column';
      minHeight = '50%';
      break;
    case 'horizontalTwoOne':
      break;
    case 'horizontalOneTwo':
      flexWrap = 'wrap-reverse';
      break;
    case 'allFour':
      break;
    default:
      break;
  }

  let nextRefreshTimeout;
  let nextUpdateTimeout;

  const refreshInterval = useCallback(() => {
    if (nextRefreshTimeout) {
      clearTimeout(nextRefreshTimeout);
    }
    nextRefreshTimeout = setTimeout(() => {
      dispatch(fetchDeviceStats(true));
      setInitialMapTilesSet(false);
      refreshInterval();
    }, selectedInterval * 1000);
  }, [selectedInterval, dispatch]);

  useEffect(() => {
    if (path !== TrackingPaths.Proximity) {
      if (selectedInterval !== -1) {
        if (nextUpdateTimeout) {
          clearInterval(nextUpdateTimeout);
          nextUpdateTimeout = null;
        }
        refreshInterval();
      } else {
        if (nextRefreshTimeout) {
          clearTimeout(nextRefreshTimeout);
          nextRefreshTimeout = null;
        }
        if (!nextUpdateTimeout) {
          nextUpdateTimeout = setInterval(() => {
            dispatch(applyUpdatesOnDevices());
          }, 15 * 1000);
        }
      }
    } else {
      if (nextRefreshTimeout) {
        clearTimeout(nextRefreshTimeout);
        nextRefreshTimeout = null;
      }
      if (nextUpdateTimeout) {
        clearInterval(nextUpdateTimeout);
        nextUpdateTimeout = null;
      }
    }
    return () => {
      if (nextRefreshTimeout) {
        clearTimeout(nextRefreshTimeout);
        nextRefreshTimeout = null;
      }
      if (nextUpdateTimeout) {
        clearInterval(nextUpdateTimeout);
        nextUpdateTimeout = null;
      }
    };
  }, [refreshInterval, path]);

  const getAberrationFreeDevices = devices =>
    (devices || []).reduce((validDevices, currentDevice) => {
      const currentDeviceTime = deviceUpdates[currentDevice.id]?.lastEventAt
        ? new Date(deviceUpdates[currentDevice.id].lastEventAt).getTime()
        : new Date(currentDevice.deviceStats.lastEventAt).getTime();
      const presentTime = new Date().getTime();
      // if lastEventAt is more than 5 minutes into the future, hide it
      if (currentDeviceTime > presentTime + 5 * 60 * 1000) {
        return validDevices;
      }
      if (currentDeviceTime > presentTime) {
        currentDevice.deviceStats.lastEventAt = new Date().toISOString();
      }

      validDevices.push(currentDevice);
      return validDevices;
    }, []);

  useEffect(() => {
    if (userPreferences?.refresh?.tracking !== -1) {
      dispatch(resetUpdates());
    }

    setSelectedInterval(userPreferences?.refresh?.tracking || 600);
  }, [userPreferences, dispatch]);

  useEffect(() => {
    dispatch(setPageTitle(t('Tracking.PageTitle')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  useEffect(() => {
    // reset if company changes
    if (initialMapTiles?.currentCompany?.id !== currentCompany?.id) {
      localStorage.setItem(
        'mapTiles',
        JSON.stringify({
          ...initialMapTiles,
          mapTiles: null,
          currentCompany
        })
      );
      setInitialMapTilesSet(false);
      setResetMapTilePosition(true);
      // dispatch(resetGridFilters());
    }
  }, [currentCompany]);

  useEffect(() => {
    const mapTilesSaved = mapTiles.map(tile => ({
      ...tile,
      devices: []
    }));
    localStorage.setItem(
      'mapTiles',
      JSON.stringify({
        mapTiles: mapTilesSaved,
        mapTilesPosition: mapTilesLayout,
        currentCompany
      })
    );
  }, [mapTiles, mapTilesLayout]);

  useEffect(() => {
    if (trackingData.devices.length > 0 && !initialMapTilesSet && !trackingData.isLoading) {
      const tilesWithDevices = mapTiles.map(tile => {
        const newState = tile;
        newState.devices = trackingData.devices.filter(device => {
          if (resetMapTilePosition) {
            return true;
          }
          if (tile.searchedDeviceId) {
            return parseInt(device.id, 10) === parseInt(tile.searchedDeviceId, 10);
          }
          if (tile.fleetId && !!!device.fleets.find(df => df.id === parseInt(tile.fleetId, 10))) {
            return false;
          }
          return (
            (device.vehicleName && device.vehicleName.toLowerCase().includes(tile.searchText)) ||
            device.name.toLowerCase().includes(tile.searchText)
          );
        });
        if (resetMapTilePosition) {
          newState.mapProps = {
            mapTypeId: tile?.mapProps?.mapTypeId
          };
          newState.searchText = '';
          newState.fleetId = null;
        }
        return newState;
      });
      setResetMapTilePosition(false);
      setMapTiles(tilesWithDevices);
      setInitialMapTilesSet(true);
    }
  }, [trackingData.devices, mapTiles, initialMapTilesSet, trackingData.isLoading]);

  const onDeviceSelected = device => {
    if (device) {
      const url = TrackingPaths.TrackSummary.replace(':deviceId', device.id);
      history.push(url);
    }
  };

  const onMapTilePositionChange = newPosition => {
    setMapTilesLayout(newPosition);
    Mixpanel.sendTrackEvent(MPTrackingEvents.Tracking.MapTilesPositionChange, {
      mapTilesPosition: newPosition
    });
    if (availableMapTilesPositions[newPosition].tiles >= mapTiles.length) {
      let currentMapTiles = mapTiles.length;
      let newState = [...mapTiles];
      while (availableMapTilesPositions[newPosition].tiles > currentMapTiles) {
        newState = [
          ...newState,
          {
            id: mapTiles.length,
            searchText: '',
            devices: trackingData.devices
          }
        ];
        currentMapTiles++;
      }
      setMapTiles(newState);
    } else {
      let currentMapTiles = mapTiles.length;
      let newState = [...mapTiles];
      while (availableMapTilesPositions[newPosition].tiles < currentMapTiles) {
        newState.pop();
        currentMapTiles--;
      }
      setMapTiles(newState);
    }
  };

  const onMapTileMapUpdate = ({ index, zoom, mapTypeId, center }) => {
    let newState = [...mapTiles];
    const newMapProps = {};
    if (zoom) {
      newMapProps.zoom = zoom;
    }
    if (mapTypeId) {
      newMapProps.mapTypeId = mapTypeId;
    }
    if (center) {
      newMapProps.center = JSON.parse(JSON.stringify(center));
    }
    newState[index] = {
      ...newState[index],
      mapProps: {
        ...newState[index].mapProps,
        ...newMapProps
      }
    };
    setMapTiles(newState);
  };

  const onMapTileSearch = (index, searchedDeviceId) => {
    let newState = [...mapTiles];
    let fleetId = newState[index].fleetId;
    const text = newState[index].searchText;
    newState[index] = {
      ...newState[index],
      mapProps: {},
      devices: trackingData.devices.filter(device => {
        if (searchedDeviceId) {
          return device.id === searchedDeviceId;
        }
        if (fleetId && !!!device.fleets.find(df => df.id === parseInt(fleetId, 10))) {
          return false;
        }
        return (
          (device.vehicleName && device.vehicleName.toLowerCase().includes(text)) ||
          device.name.toLowerCase().includes(text)
        );
      })
    };
    if (searchedDeviceId) {
      const device = trackingData.devices.find(device => device.id === searchedDeviceId);
      const searchText = device.vehicleName || device.driverName;
      newState[index].searchText = searchText;
      newState[index].searchedDeviceId = searchedDeviceId;
    }
    setMapTiles(newState);
  };

  const setMapTilesSearchText = (index, text) => {
    let newState = [...mapTiles];
    newState[index] = {
      ...newState[index],
      searchText: text
    };
    setMapTiles(newState);
  };

  const onMapTileFleetSelect = (index, fleetId) => {
    let newState = [...mapTiles];
    newState[index] = {
      searchText: '',
      fleetId,
      devices: trackingData.devices.filter(
        device => !fleetId || !!device?.fleets.find(df => df.id === parseInt(fleetId, 10))
      )
    };
    setMapTiles(newState);
  };

  return (
    <TrackingPage
      isFullScreen={isFullScreen}
      toolbar={
        <>
          <MapTilesPositioning
            onMapTilePositionChange={onMapTilePositionChange}
            mapTilesPosition={mapTilesLayout}
          />
          <MapTilesExpand setFullScreen={setIsFullScreen} fullScreen={isFullScreen} />
        </>
      }
    >
      <div
        style={{
          display: 'flex',
          flex: '1 0 0',
          flexDirection: 'column',
          maxHeight: '100%',
          overflowY: 'hidden',
          flexWrap: 'wrap'
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: '1 0 0',
            justifyContent: 'center',
            overflowY: 'auto',
            padding: '1rem',
            flexDirection,
            flexWrap
          }}
        >
          {mapTiles.map((card, index) => {
            return (
              <div
                key={index}
                style={{
                  padding: '0.5rem',
                  flex: '1',
                  minWidth,
                  minHeight
                }}
              >
                <MapCard
                  index={index}
                  tiles={mapTiles}
                  fleetId={card.fleetId}
                  devices={
                    card.devices ? card.devices : getAberrationFreeDevices(trackingData.devices)
                  }
                  allDevices={trackingData.devices}
                  fleets={trackingData.fleets}
                  isLoading={trackingData.isLoading}
                  geofences={filterGeofencesByShowTypes(
                    trackingData.geofences,
                    showGeofenceType(userPreferences),
                    geofenceProviders
                  )}
                  searchText={card.searchText ? card.searchText : ''}
                  onSearch={onMapTileSearch}
                  setSearchText={setMapTilesSearchText}
                  onFleetSelect={onMapTileFleetSelect}
                  onMapTileMapUpdate={onMapTileMapUpdate}
                  mapProps={card.mapProps}
                  onDeviceSelected={onDeviceSelected}
                />
              </div>
            );
          })}
        </div>
      </div>
    </TrackingPage>
  );
};
