import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Space, Typography } from 'antd';
import { Select } from 'antd';
import styles from '../GPIO/CloneGPIOModal.module.scss';
import { useCompanies } from 'features/company/companySlice';
import { useEffect } from 'react';
import { services } from 'features/permissions';
import { useDispatch } from 'react-redux';
import {
  useCopyDriverIdConfigurationTemplateMutation,
  executeCopyDriverIdConfigurationTemplate
} from 'services/nextgen/ngDriverIdConfigurationApi';

export const CloneDriverIdModal = ({ selectedTemplateId, showModal, onOk, onCancel }) => {
  const { t } = useTranslation();
  const companies = useCompanies();
  const dispatch = useDispatch();
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [
    copyDriverIdConfigurationTemplate,
    { isLoading }
  ] = useCopyDriverIdConfigurationTemplateMutation();

  useEffect(() => {
    if (!showModal) {
      setSelectedCompanies([]);
    }
  }, [showModal]);

  useEffect(() => {
    if (companies) {
      //companies - driver id servecies pls
      companies.forEach(comp => {
        comp.services.some(ser => ser === 'DRIVERPIN');
      });
      setFilterCompanies(
        companies
          //DRIVERID services
          .filter(i => (i.services || []).find(s => s === services.DRIVERPIN) !== undefined)
          .map(i => {
            return { value: i.id, label: i.name };
          })
      );
    }
  }, [companies, showModal]);

  const handleCopyDriverId = v => {
    executeCopyDriverIdConfigurationTemplate(
      { id: selectedTemplateId, companyIds: v },
      copyDriverIdConfigurationTemplate,
      companies,
      dispatch
    ).then(() => {
      onOk();
    });
  };

  return (
    <Modal
      okText={t('Common.Save')}
      cancelText={t('Common.Modal.Cancel')}
      title={t('CompanyConfig.DeviceConfigurations.GPIOCopyModal.Title')}
      centered
      okButtonProps={{
        disabled: selectedCompanies.length === 0 || isLoading
      }}
      open={showModal}
      onOk={() => {
        handleCopyDriverId(selectedCompanies);
      }}
      onCancel={onCancel}
    >
      <Space direction="vertical" size={0} className={styles.entitySelect}>
        <Typography.Title level={5}>{`${t(
          'CompanyConfig.DeviceConfigurations.GPIOCopyModal.Companies'
        )}`}</Typography.Title>
        <Select
          allowClear
          showSearch
          value={selectedCompanies}
          mode="multiple"
          filterOption={(input, option) =>
            (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          onChange={e => {
            setSelectedCompanies(e);
          }}
          options={filterCompanies}
          placeholder={t(
            'CompanyConfig.DeviceConfigurations.GPIOCopyModal.SelectCompaniesPlaceHolder'
          )}
        />
      </Space>
    </Modal>
  );
};
