import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAsyncThunk, createSlice, unwrapResult } from '@reduxjs/toolkit';
import { api } from 'utils/api';

import { useCurrentCompany } from 'features/company/companySlice';
import { API_PATH } from 'config';

const FETCH_REGIONS_ACTION = 'regions/FETCH_REGIONS';
const FETCH_REGIONS_URL = '/regions';

const initialState = {
  list: [],
  meta: {
    isFetching: false,
    key: null,
    error: null,
    success: null
  }
};

export const fetchRegions = createAsyncThunk(
  FETCH_REGIONS_ACTION,
  async (args = {}, { getState, rejectWithValue }) => {
    const { user } = getState();

    try {
      if (args.key !== getState().regions.meta.key && getState().regions.meta.isFetching) {
        return rejectWithValue({});
      }

      const response = await api.get(FETCH_REGIONS_URL, {
        authKey: user?.current?.auth?.key
      });

      return response.body;
    } catch (err) {
      return rejectWithValue(err.response?.body);
    }
  }
);

const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  extraReducers: builder => {
    builder.addCase(fetchRegions.pending, (state, action) => {
      state.meta.isFetching = true;

      if (!state.meta.key) {
        state.meta.key = action.meta?.arg?.key;
      }
    });

    builder.addCase(fetchRegions.fulfilled, (state, action) => {
      state.meta.isFetching = false;
      state.meta.success = true;
      state.meta.error = false;
      state.meta.key = null;
      state.list = unwrapResult(action);
    });

    builder.addCase(fetchRegions.rejected, (state, action) => {
      state.meta.isFetching = false;
      state.meta.success = false;
      state.meta.error = true;
      state.meta.key = null;
    });
  }
});

export const useRegions = () => {
  const dispatch = useDispatch();

  const regions = useSelector(state => state.regions.list);
  const isFetching = useSelector(state => state.regions.meta.isFetching);
  const isSuccess = useSelector(state => state.regions.meta.success);
  const isError = useSelector(state => state.regions.meta.error);

  const isFetchingComplete = isSuccess || isError;

  useEffect(() => {
    if (!isFetching && !isFetchingComplete) {
      dispatch(fetchRegions({ key: Date.now() }));
    }
  }, [isFetching, isFetchingComplete]);

  return regions;
};

export const useCurrentRegion = () => {
  const country = useCurrentCompany()?.country;
  const currentRegion = useSelector(state =>
    state.regions?.list?.find(region => region.code === country)
  );
  return currentRegion;
};

export const useEnvCode = () => {
  return API_PATH.substr(API_PATH.indexOf('api-') + 4, 2)
    .toUpperCase()
    .replace('LA', 'MX');
};

export const useEnvRegion = () => {
  const regionCode = useEnvCode().replace('UK', 'GB');
  const envRegion = useSelector(state =>
    state.regions?.list?.find(region => region.code === regionCode)
  );
  return {
    ...envRegion,
    envCode: envRegion?.code?.replace('GB', 'UK')
  };
};

export const useAllRegions = () => useSelector(state => state.regions.list);

export default regionsSlice.reducer;
