import { HEADINGS, MARKER_COLOR } from './constants';

export const constructDynamicSvg = (direction, color, content) => {
  const degree =
    Number(Object.entries(HEADINGS).find(([key, value]) => value === direction)?.[0]) + (90 % 360);
  const backgroundColor = Object.entries(MARKER_COLOR).find(([key, value]) => value === color)?.[0];

  let svg = `
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 24" transform="rotate(${degree})" style="filter: drop-shadow(-1.5px 0.5px 1px rgba(0, 0, 0, 0.5));">
      <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
        <path d="M14,7L9,12L14,17V7Z" fill="#ffffff" />
      </svg>
  
      <g transform="translate(20, 2)">
        <circle r="13" cx="10" cy="10" fill="${backgroundColor}" stroke="#fff" stroke-width="1" />
        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <g transform="rotate(${360 - degree} 12 12)">
            ${content}
          </g>
        </svg>
      </g>
    </svg>
  `;

  return svg;
};
