import React from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarOutlined, MenuOutlined } from '@ant-design/icons';
import { Tooltip } from 'components/ant';
import cn from 'classnames';
import { BUTTON_IDS } from 'utils/globalConstants';
import style from './CalendarSwitch.module.scss';

export const CalendarSwitch = ({ isCalendarView = false, toggleCalendarView }) => {
  const gridButtonClassName = cn(style.button, { [style.active]: !isCalendarView });
  const calendarButtonClassName = cn(style.button, { [style.active]: isCalendarView });
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <div className={gridButtonClassName} onClick={toggleCalendarView}>
        <Tooltip content={t('Common.GridView')} target={<MenuOutlined />} />
      </div>
      <div
        className={calendarButtonClassName}
        onClick={toggleCalendarView}
        id={BUTTON_IDS.calendarSwitchToView}
      >
        <Tooltip content={t('Common.CalendarView')} target={<CalendarOutlined />} />
      </div>
    </div>
  );
};
