import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import styles from './fatigue.module.scss';
import { useBranches } from 'features/locations/locationsSlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import { Dropdown } from 'components/form/dropdownselect/Dropdown';
import { FatigueGridColumns } from './columns';
import DropdownTreeSelect from 'components/form/treeselect/DropdownTreeSelect';
import { Button } from 'antd';
import doubleArrowDown from 'static/images/icons/doublearrow_down.svg';
import doubleArrowUp from 'static/images/icons/doublearrow_up.svg';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { prepareDataForMultiselect } from 'utils/filters';
import { useTranslation } from 'react-i18next';
import { BUTTON_IDS } from 'utils/globalConstants';

function ExpandButton({ onExpand, ...props }) {
  const [expand, setExpand] = useState(false);
  const handleExpand = useCallback(() => {
    setExpand(!expand);
    if (onExpand) {
      onExpand(!expand);
    }
  }, [expand]);

  const arrowStyle = {
    content: `url(${expand ? doubleArrowUp : doubleArrowDown})`
  };

  return (
    <Button type="default" onClick={handleExpand} id={BUTTON_IDS.fatigueToolbarExpand}>
      <span style={arrowStyle}></span>
    </Button>
  );
}

export function FatigueToolbar({
  onSearchChange,
  onFleetChange,
  onBranchChange,
  onSortChange,
  onGeneralFilterChange,
  onExpand,
  filters,
  showSort = true,
  ...props
}) {
  const [filterBranches, setFilterBranches] = useState([]);
  const [filterFleets, setFilterFleets] = useState([]);
  const [generalFilters, setGeneralFilters] = useState(filters);
  const [sortColumn, setSortColumn] = useState(() => {
    return FatigueGridColumns.find(c => c.defaultSortColumn).label;
  });

  const branches = useBranches();
  const fleets = useFleets();

  const timeoutHandler = useRef(-1);
  const sortColumns = useMemo(() => {
    return FatigueGridColumns.filter(c => c.sortable).map((c, idx) => {
      return {
        id: idx,
        key: idx,
        dataKey: c.dataKey,
        name: c.label
      };
    });
  }, []);

  const { t } = useTranslation();

  const handleSearchChange = useCallback(value => {
    if (timeoutHandler.current !== -1) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = -1;
    }

    timeoutHandler.current = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(value);
      }
      timeoutHandler.current = -1;
    }, 50);
  }, []);

  const handleSortItemSelected = useCallback(item => {
    setSortColumn(item.name);
    if (onSortChange) {
      onSortChange(item.dataKey);
    }
  }, []);

  const handleFleetsChange = useCallback(
    f => {
      setFilterFleets(f);
      if (onFleetChange) {
        onFleetChange(f);
      }
    },
    [onFleetChange]
  );

  const handleBranchesChange = useCallback(
    f => {
      setFilterBranches(f);
      if (onBranchChange) {
        onBranchChange(f);
      }
    },
    [onBranchChange]
  );

  const handleGeneralFilterChange = useCallback(filters => {
    //toggleItem(item, generalFilters, setGeneralFilters)
    setGeneralFilters(filters);
    if (onGeneralFilterChange) {
      onGeneralFilterChange(filters);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutHandler.current !== -1) {
        clearTimeout(timeoutHandler.current);
        timeoutHandler.current = -1;
      }
    };
  }, []);

  useEffect(() => {
    setGeneralFilters(filters);
  }, [filters]);

  useEffect(() => {
    const branchOptions = prepareDataForMultiselect(branches, t('Common.AllBranches'), null);
    const noBranch = branchOptions?.find(b => b.id === -1);
    if (noBranch) {
      noBranch.name = t('Users.NoBranch');
    }
    setFilterBranches(branchOptions);
  }, [branches, t]);

  useEffect(() => {
    const fleetsList = [];
    if (fleets != null && fleets.length > 0) {
      fleetsList.push(...fleets.filter(f => f.name != null));
    }
    fleetsList.push({ id: -1, name: t('Home.No Fleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsList, t('Common.AllFleets'), null));
  }, [fleets, t]);

  return (
    <div className={styles.fatigueToolbar}>
      <AntSearchbar onFilter={handleSearchChange} />

      <AntMultiselect
        title={
          filterFleets?.some(value => !value.checked) ? t('Common.Fleets') : t('Common.AllFleets')
        }
        data={filterFleets}
        onFilter={handleFleetsChange}
      />
      <AntMultiselect
        title={
          filterBranches?.some(value => !value.checked)
            ? t('Common.Branches')
            : t('Common.AllBranches')
        }
        onFilter={handleBranchesChange}
        data={filterBranches}
      />

      <DropdownTreeSelect
        title={'Filter'}
        showSelectAll={true}
        tree={generalFilters}
        onChange={handleGeneralFilterChange}
      />
      {showSort && (
        <Dropdown
          title={`Sort by ${sortColumn}`}
          data={sortColumns}
          onItemSelected={handleSortItemSelected}
        />
      )}

      <div className={styles.expandAllBtn}>
        <ExpandButton onExpand={onExpand} />
      </div>
    </div>
  );
}
