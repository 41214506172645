import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment-timezone';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { ACTION_OPTIONS, TIMEZONE_OPTIONS } from 'features/fatigue/constants/Generals';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik, useField } from 'formik';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import FormInput from 'components/form/form-input/FormInput';
import FormSelect from 'components/form/form-select/FormSelect';
import { fetchCheckpointAndViolations, fetchEvents, useEvents } from './events/eventsSlice';
import { useDriverTimezone, useEwdDriverId } from './drivers/driversSlice';
import { ewdAPI } from '../endpoints/ewdAPI';
import { fetchEWDStatusByDriverId } from './status/statusSlice';
import { DatePicker, Button } from 'antd';
import { useEWDKeeperId, useEWDKeeperRulesets } from './keepers/keepersSlice';
import { fetchAnnotations } from './annotations/annotationsSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

function disabledDate(current) {
  // Can not select times after now
  return current && current > moment();
}

const DateTimePicker = ({ ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;
  return (
    <div>
      <label htmlFor={field.name}>
        {props.label}{' '}
        {props.isRequired && <span style={{ paddingLeft: '3px', color: '#cf1322' }}>*</span>}
      </label>
      <DatePicker
        style={{ minWidth: '100%' }}
        format="DD/MM/YYYY hh:mm a"
        disabledDate={disabledDate}
        showTime={{ defaultValue: moment('12:00 am', 'hh:mm a') }}
        value={moment(value)}
        onChange={newDate =>
          setValue(
            moment(newDate)
              .tz(props.driverTimezone)
              .second(0)
              .format()
          )
        }
        allowClear={false}
      />
      {meta.touched && meta.error ? (
        <div className={`fieldError ${meta.touched && meta.error ? 'error has' : 'error'}`}>
          {meta.touched && meta.error}
        </div>
      ) : null}
    </div>
  );
};

const ewdSchema = Yup.object().shape({
  action: Yup.string().required('Required'),
  eventAt: Yup.date().required('Required'),
  location: Yup.string().required('Required'),
  ruleset: Yup.string().required('Required'),
  timeZone: Yup.string().required('Required'),
  notes: Yup.string().required('Required')
});

const useActiveRuleset = RULESET_OPTIONS => {
  const events = useEvents();
  const activeRuleset = useMemo(() => {
    let retValue = events[0]?.ruleset;
    if (retValue) {
      if (RULESET_OPTIONS.some(rulesetOpt => rulesetOpt.value === retValue)) {
        return retValue;
      } else if (retValue?.startsWith('AFM')) {
        //fallback-the ruleset name may be simply “AFM”, rather than “AFM (<accrediation number>)”
        return RULESET_OPTIONS.find(rulesetOpt => rulesetOpt.value?.startsWith('AFM')) || retValue;
      }
    }
    return retValue;
  }, [events, RULESET_OPTIONS]);

  return activeRuleset;
};

const EWDAddEditFormik = ({ setOpen }) => {
  const dispatch = useDispatch();
  const driverId = useEwdDriverId();
  const keeperId = useEWDKeeperId();
  const driverTimezone = useDriverTimezone();
  const RULESET_OPTIONS = useEWDKeeperRulesets(keeperId);
  const activeRuleset = useActiveRuleset(RULESET_OPTIONS);
  const action = activeRuleset && activeRuleset.actions ? activeRuleset.actions[0] : '';

  const [initialFormValues, setInitialFormValues] = useState({
    action: action,
    eventAt: moment()
      .tz(driverTimezone)
      .format(),
    location: '',
    notes: '',
    registration: '',
    odometer: '',
    ruleset: activeRuleset,
    timeZone: driverTimezone
  });

  useEffect(() => {
    setInitialFormValues(prev => {
      if (prev.action === '') {
        return { ...prev, action: action };
      }
      return prev;
    });
  }, [action]);

  useEffect(() => {
    setInitialFormValues(prev => {
      if (!prev.keeperId) {
        return { ...prev, keeperId: keeperId };
      }
      return prev;
    });
  }, [keeperId]);

  useEffect(() => {
    setInitialFormValues(prev => {
      if (!prev.activeRuleset) {
        return { ...prev, activeRuleset: activeRuleset };
      }
      return prev;
    });
  }, [activeRuleset]);

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={ewdSchema}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        const payload = {
          ...values,
          keeperId
        };
        ewdAPI
          .addEvent(driverId, payload)
          .then(resp => {
            if (resp?.error) {
              throw resp?.error;
            } else {
              return resp;
            }
          })
          .then(() => {
            dispatch(
              openToast({
                type: ToastType.Success,
                message: 'Event submitted'
              })
            );
            dispatch(fetchEvents(driverId));
            dispatch(fetchAnnotations(driverId));
            dispatch(fetchCheckpointAndViolations(driverId));
            dispatch(fetchEWDStatusByDriverId(driverId));
            actions.setSubmitting(false);
            setOpen(false);
          })
          .catch(error => {
            console.warn('ERROR', error);
            dispatch(
              openToast({
                type: ToastType.Error,
                message: 'Error submitting event.'
              })
            );
            actions.setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Field id="id" name="id" type="hidden" component="input" />
          <Row className="py-0 form-row align-items-start">
            <Col className="px-0">
              <FormSelect
                name="ruleset"
                label="Ruleset"
                placeholder="Active ruleset"
                values={RULESET_OPTIONS}
                isRequired
                supressSorting
              />
            </Col>
            <Col className="px-0">
              <FormSelect
                name="action"
                label="Action"
                placeholder="Work action"
                values={ACTION_OPTIONS}
                isRequired
              />
            </Col>
            <Col className="px-0">
              <Col className="form-group col">
                <DateTimePicker
                  name="eventAt"
                  label="Time"
                  id="AddDate"
                  driverTimezone={driverTimezone}
                  isRequired
                />
              </Col>
            </Col>
            <Col className="px-0">
              <FormSelect name="timeZone" label="Timezone" values={TIMEZONE_OPTIONS} isRequired />
            </Col>
          </Row>
          <Row className="py-2 form-row align-items-start">
            <Col className="px-0">
              <FormInput
                name="location"
                label="Location"
                placeholder="Address of event"
                isRequired
              />
            </Col>
            <Col className="px-0">
              <FormInput name="registration" label="Registration" />
            </Col>
            <Col className="px-0">
              <FormInput name="odometer" label="Odometer" />
            </Col>
            <Col className="px-0">
              <FormInput name="notes" label="Comment" isRequired />
            </Col>
          </Row>
          <Row className="py-0 form-row align-items-end justify-content-end">
            <Col className="col-3">
              <Col className="form-group col">
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                  disabled={!isValid}
                  id={BUTTON_IDS.ewdAddEditSave}
                >
                  Save Event
                </Button>
              </Col>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default EWDAddEditFormik;
