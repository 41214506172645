import React from 'react';
import { Link } from 'react-router-dom';

import { EnrolmentStatuses, ColumnKeys, ENROLMENTS_TABLE_COLUMNS, ActionMenu } from './';
import { Status, RowStatus } from 'components/ant';
import { Tooltip } from 'antd';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { cancelEnrolment, getEnrolements } from 'features/enrolments';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';

import { format } from 'utils/dates';

export const DeviceIMEITooltip = ({ placement, name, imei }) => {
  return (
    <Tooltip placement={placement || 'top'} title={name}>
      <span>{imei}</span>
    </Tooltip>
  );
};

export const convertEnrolmentIDWithPrefixAndZeroPadding = argId => {
  const prefixEnrolmentID = 'TTD0000000';
  const enrolmentID = `${prefixEnrolmentID.substring(
    0,
    prefixEnrolmentID.length - (argId || '').toString().length + 1
  )}${argId || ''}`;

  return enrolmentID;
};

export const prepareEnrolmentsDataForTable = (data = [], localization, translate, schemes) => {
  return data.map(enrolment => {
    return {
      [ColumnKeys.STATUS]: (
        <RowStatus
          status={enrolment.status === EnrolmentStatuses.APPROVED ? Status.COMPLETED : Status.ERROR}
          showIcon={true}
        >
          {enrolment.status === EnrolmentStatuses.APPROVED
            ? translate('TCAEnrolments.Approved')
            : translate('TCAEnrolments.Cancelled')}
        </RowStatus>
      ),
      [ColumnKeys.ENROLMENT_ID]: (
        <Link to={`/settings/enrolments/view/${enrolment.id}`}>
          {convertEnrolmentIDWithPrefixAndZeroPadding(enrolment.id)}
        </Link>
      ),
      [ColumnKeys.SCHEME]: (
        <Tooltip placement="left" title={enrolment.scheme?.name}>
          {enrolment.scheme?.id}
        </Tooltip>
      ),
      [ColumnKeys.COMPANY_NAME]: enrolment.company?.name,
      [ColumnKeys.DEVICE]: (
        <Link to={`/settings/devices/id/${enrolment.deviceId}`}>
          <DeviceIMEITooltip name={enrolment.deviceName} imei={enrolment.deviceDetails?.imei} />
        </Link>
      ),
      [ColumnKeys.VEHICLE_REGISTRATION]: enrolment.vehicleRegistration,
      [ColumnKeys.APPROVAL_DATE]:
        (enrolment.startedAt &&
          format(new Date(enrolment.startedAt), localization.formats.time.formats.dby_imp)) ||
        '',
      [ColumnKeys.PREVIOUS_ENROLMENT]: enrolment.previousId ? (
        <Link to={`/settings/enrolments/view/${enrolment.previousId}`}>{enrolment.previousId}</Link>
      ) : (
        ''
      ),
      [ColumnKeys.CANCELLATION_DATE]:
        (enrolment.cancelledAt &&
          format(new Date(enrolment.cancelledAt), localization.formats.time.formats.dby_imp)) ||
        '',
      [ColumnKeys.ACTIONS]: (
        <ActionMenu enrolment={enrolment} schemes={schemes} enrolments={data} />
      ),
      key: enrolment.id
    };
  });
};

export const prepareColumnsForTable = translate =>
  ENROLMENTS_TABLE_COLUMNS.map(column => ({
    ...column,
    title: translate(`TCAEnrolments.${column.key}`)
  }));

export const cancelEnrolmentModal = (dispatch, translate, enrolment) => {
  const onCancelEnrolment = () => {
    dispatch(
      cancelEnrolment({
        id: +enrolment.id,
        callback: getEnrolements,
        toasters: {
          success: () =>
            openToast({
              type: ToastType.Success,
              message: translate('TCAEnrolments.Form.Toasters.EnrolmentSuccessfullyCancelled')
            }),
          error: err =>
            openToast({
              type: ToastType.Error,
              message: parseErrorMessage(err)
            })
        }
      })
    );
  };

  return () => {
    confirmationModal(
      translate('TCAEnrolments.CancelConfirmationTitle'),
      translate('TCAEnrolments.CancelConfirmationBody'),
      translate('Common.Delete'),
      translate('Common.Cancel'),
      onCancelEnrolment,
      'delete'
    );
  };
};
