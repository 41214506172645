import { difference, isEqual, isEqualWith } from 'lodash';
export const ConfigFormSection = {
  Company: {
    translation: 'CompanyConfig.VehicleLoginLimit.CompanyLimit',
    formName: 'companyLimited',
    payloadName: 'company',
    toPayloadField(configFormData, companyId) {
      return [
        {
          id: companyId,
          limit: configFormData?.companyLimited ? 1 : 0
        }
      ];
    },
    toFormField(payloadData, companyId) {
      const companyLimit = payloadData?.[this.payloadName]?.find(
        c => parseInt(c?.id, 10) === parseInt(companyId, 10)
      )?.limit;
      return parseInt(companyLimit, 10) === 1 ? true : false;
    }
  },
  Exceptions: {
    translation: 'CompanyConfig.VehicleLoginLimit.Exceptions.Title'
  },
  Branches: {
    translation: 'Common.Branches',
    formName: 'branches',
    payloadName: 'branch',
    toPayloadField(configFormData) {
      return (configFormData?.branches || []).map(branchId => ({
        id: branchId,
        limit: configFormData?.companyLimited ? 0 : 1
      }));
    },
    toFormField(payloadData) {
      return (payloadData?.[this.payloadName] || []).map(branch => branch.id);
    }
  },
  Drivers: {
    translation: 'Common.Drivers',
    formName: 'drivers',
    payloadName: 'user',
    toPayloadField(configFormData) {
      return (configFormData?.[this.formName] || []).map(driverId => ({
        id: driverId,
        limit: configFormData?.companyLimited ? 0 : 1
      }));
    },
    toFormField(payloadData) {
      return (payloadData?.[this.payloadName] || []).map(driver => driver.id);
    }
  }
};

export const toVehicleLoginLimitFormData = (responseBody, companyId) => {
  const configPayload = responseBody?.value;
  try {
    const parsedConfigPayload = configPayload ? JSON.parse(configPayload) : null;
    if (parsedConfigPayload) {
      return {
        [ConfigFormSection.Company.formName]: ConfigFormSection.Company.toFormField(
          parsedConfigPayload,
          companyId
        ),
        [ConfigFormSection.Branches.formName]: ConfigFormSection.Branches.toFormField(
          parsedConfigPayload,
          companyId
        ),
        [ConfigFormSection.Drivers.formName]: ConfigFormSection.Drivers.toFormField(
          parsedConfigPayload,
          companyId
        )
      };
    }
  } catch (error) {}
};

export const toVehicleLoginLimitPayload = (configFormData, companyId) => {
  const config = JSON.stringify({
    [ConfigFormSection.Company.payloadName]: ConfigFormSection.Company.toPayloadField(
      configFormData,
      companyId
    ),
    [ConfigFormSection.Branches.payloadName]: ConfigFormSection.Branches.toPayloadField(
      configFormData,
      companyId
    ),
    [ConfigFormSection.Drivers.payloadName]: ConfigFormSection.Drivers.toPayloadField(
      configFormData,
      companyId
    )
  });
  return config;
};

export const isFormDataSameAs = (valuesA, valuesB) => {
  const isValuesASameAsValueB =
    isEqual(
      valuesA?.[ConfigFormSection.Company.formName] || false,
      valuesB?.[ConfigFormSection.Company.formName] || false
    ) &&
    [ConfigFormSection.Branches.formName, ConfigFormSection.Drivers.formName].every(property =>
      isEqualWith(valuesA?.[property] || [], valuesB?.[property] || [], (objValue, othValue) => {
        return (
          objValue?.length === othValue?.length &&
          !difference(objValue, othValue)?.length &&
          !difference(othValue, objValue)?.length
        );
      })
    );
  return isValuesASameAsValueB;
};
