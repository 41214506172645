import GlobalRoles from './GlobalRoles';
import { useCan } from './canHooks';

export const useIQCameraUser = () => {
  const can = useCan();

  const canAccessNonCameraFeatures = can({
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  });

  const canAccessCameraAdminFeatures = can({
    oneOfRoles: [GlobalRoles.IQCameraAdministrator]
  });

  return { canAccessNonCameraFeatures, canAccessCameraAdminFeatures };
};
