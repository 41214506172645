import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useDispatch } from 'react-redux';

import { Can, entities } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';

import { Wifi, WifiView, WifiForm, WifiAudits } from '../index';
import { useSubCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import { useWifi, fetchWifi, useIsFetchingFinished } from 'features/wifi';

//constants
import { PATHS, WIFI_FORM_ACTIONS } from '../utils/constants';

export const WifiRouter = () => {
  const currentCompany = useCurrentCompany();
  const dispatch = useDispatch();
  const fleets = useFleets();
  const isFetchingWifiFinished = useIsFetchingFinished();
  const subCompanies = useSubCompanies();
  const wifi = useWifi();

  useEffect(() => {
    if (isFetchingWifiFinished) {
      dispatch(fetchWifi());
    }
  }, [currentCompany.id]);

  return (
    <Switch>
      <Route path={PATHS.EDIT_WIFI}>
        <Can everyEntity={[entities.WIFI_UPDATE]} onFail={<NoAccessBody />}>
          <WifiForm
            wifi={wifi}
            subCompanies={subCompanies}
            fleets={fleets}
            action={WIFI_FORM_ACTIONS.EDIT}
          />
        </Can>
      </Route>
      <Route path={PATHS.ADD_NEW_WIFI}>
        <Can everyEntity={[entities.WIFI_CREATE]} onFail={<NoAccessBody />}>
          <WifiForm
            wifi={wifi}
            subCompanies={subCompanies}
            fleets={fleets}
            action={WIFI_FORM_ACTIONS.ADD}
          />
        </Can>
      </Route>
      <Route path={PATHS.VIEW_WIFI}>
        <Can everyEntity={[entities.WIFI_VIEW]} onFail={<NoAccessBody />}>
          <WifiView wifi={wifi} subCompanies={subCompanies} fleets={fleets} />
        </Can>
      </Route>
      <Route path={PATHS.AUDIT_WIFI}>
        <Can everyEntity={[entities.WIFI_AUDIT]} onFail={<NoAccessBody />}>
          <WifiAudits wifi={wifi} />
        </Can>
      </Route>
      <Route path={PATHS.WIFI_DEFAULT}>
        <Can everyEntity={[entities.WIFI]} onFail={<NoAccessBody />}>
          <Wifi wifi={wifi} subCompanies={subCompanies} fleets={fleets} />
        </Can>
      </Route>
    </Switch>
  );
};
