import React, { useMemo } from 'react';

import { Tag } from 'antd';

import { Grid, ExpandIcon } from 'components/tn';
import { ActionsMenu } from './ActionsMenu';
import { Comparators } from 'utils/sorting';
import { Can, entities } from 'features/permissions';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

export const ConfiguredVehiclesTable = ({ data, onEdit, onDelete, ...props }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('Tracking.Vehicle Name'),
      dataIndex: 'name',
      key: 'vehicleName',
      fixed: 'left',
      width: 150,
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.String('name'),
      defaultSortColumn: true,
      defaultSortOrder: 'ascend',
      render: (name, record) => {
        if (name && record.hasOwnProperty('type')) {
          return <span>{name}</span>;
        }
      }
    },
    {
      title: t('Tracking.Columns.Vehicle Type'),
      dataIndex: ['type', 'name'],
      key: 'vehicleType',
      width: 150,
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.String('type.name')
    },
    {
      title: t('MassManagement.MassConfiguration'),
      dataIndex: 'name',
      key: 'massConfiguration',
      width: 200,
      isSearchable: true,
      isFilterable: false,
      render: (name, record) => {
        if (name && !record.hasOwnProperty('type')) {
          return <span>{name}</span>;
        }
      }
    },
    {
      title: t('MassManagement.Jurisdiction'),
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      width: 150,
      isSearchable: true,
      isFilterable: false,
      render: jurisdiction => {
        if (!jurisdiction) {
          return;
        }

        let color = '';
        switch (jurisdiction) {
          case 'NHVR':
            color = 'blue';
            break;
          case 'MRWA':
          default:
            color = 'purple';
            break;
        }

        return (
          <Tag color={color} key={jurisdiction}>
            {jurisdiction.toUpperCase()}
          </Tag>
        );
      }
    },
    {
      title: t('MassManagement.VehicleCombination'),
      dataIndex: ['vehicleCombination', 'name'],
      key: 'vehicleCombination',
      width: 200,
      isSearchable: true,
      isFilterable: false
    },
    {
      title: t('MassManagement.Field.AxleGroups'),
      dataIndex: ['vehicleCombination', 'axleGroups'],
      key: 'axleGroups',
      width: 150,
      isSearchable: true,
      isFilterable: false
    },
    {
      title: t('MassManagement.Field.Axles'),
      dataIndex: ['vehicleCombination', 'axles'],
      key: 'axles',
      width: 150,
      isSearchable: true,
      isFilterable: false
    },
    {
      title: t('Common.TableColumns.Actions'),
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 100,
      render: record =>
        record.massConfigurations && (
          <Can everyEntity={[entities.VEHICLE_UPDATE]}>
            <ActionsMenu
              record={record}
              entityType={t('MassManagement.VehicleConfiguration')}
              onEdit={onEdit}
            />
          </Can>
        )
    },
    {
      title: '',
      dataIndex: 'expand',
      key: 'expand',
      fixed: 'right',
      width: 75
    }
  ];

  const dataWithKeys = useMemo(() => {
    const result = cloneDeep(data);
    result?.forEach(d => {
      d.key = d.id;
      d.massConfigurations?.forEach(configuration => {
        configuration.key = `${d.id}-${configuration.id}`;
      });
    });
    return result;
  }, [data]);

  return (
    <Grid
      data={dataWithKeys}
      columns={columns}
      childrenColumnName="massConfigurations"
      recordTypeSingular={t('MassManagement.ConfiguredVehicle')}
      recordTypePlural={t('MassManagement.ConfiguredVehicles')}
      expandIconColumnIndex={8}
      expandIconAsCell={true}
      expandIcon={props => {
        return props.record.massConfigurations && ExpandIcon(props);
      }}
      {...props}
    />
  );
};
