import React from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { MoreMenu } from 'components/ant';
import { ToastType } from 'components/notifications/toasts/Toast';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { Paths } from '../';

import { deleteCode } from 'features/smartJobs';
import { openToast } from 'features/toasts/toastsSlice';

export const ActionMenu = ({ code, typeOfEntityToDelete }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!code) {
    return null;
  }

  const deleteCodeModal = () => {
    confirmationModal(
      `${t('Common.DeleteButton')} ${code.name}`,
      `${t('Common.SureDelete')} ${typeOfEntityToDelete ?? ''} ${code.name}?`,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      handleDeleteCode,
      'delete'
    );
  };

  const handleDeleteCode = async () => {
    try {
      const deleteResponse = await dispatch(deleteCode(code.id));
      unwrapResult(deleteResponse);

      dispatch(
        openToast({
          type: ToastType.Success,
          message: `${t(`SmartJobsCodes.deleteSuccess`)} ${code.name}!`
        })
      );
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${err.message}`
        })
      );
    }
  };

  return (
    <MoreMenu
      useNewIcon={true}
      items={[
        {
          name: t(`Common.View`),
          onClick: () => {
            history.push(`${Paths.VIEW_CODE}/${code.id}`, { code });
          },
          id: 'btn_smartjobsCodesMenuView'
        },
        {
          name: t(`Common.Edit`),
          onClick: () => {
            history.push(`${Paths.EDIT_CODE}/${code.id}`, { code });
          },
          id: 'btn_smartjobsCodesMenuEdit'
        },
        {
          name: t(`Common.Delete`),
          onClick: deleteCodeModal,
          danger: true,
          id: 'btn_smartjobsCodesMenuDelete'
        }
      ]}
    />
  );
};
