import React from 'react';
import ReactExport from 'react-data-export';
import P from 'prop-types';
import i18n from 'i18next';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { BUTTON_IDS } from 'utils/globalConstants';
import { Button } from 'antd';
import moment from 'moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const formatDocumentsForExcelExport = data => {
  const CELL_STYLE = {
    font: {
      bold: true
    },
    border: {
      top: { style: 'thin', color: '#000' },
      right: { style: 'thin', color: '#000' },
      bottom: { style: 'thin', color: '#000' },
      left: { style: 'thin', color: '#000' }
    }
  };
  const summaryColumns = [
    i18n.t('InspectionSummary.TotalInspections'),
    i18n.t('InspectionSummary.Passed'),
    i18n.t('InspectionSummary.Failed'),
    i18n.t('InspectionSummary.VehicleWithoutInspections')
  ];

  const summaryDataSet = [
    {
      columns: [
        {
          title: summaryColumns[0],
          width: { wch: 20 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: summaryColumns[1],
          width: { wch: 15 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: summaryColumns[2],
          width: { wch: 15 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: summaryColumns[3],
          width: { wch: 50 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        }
      ],
      data: []
    }
  ];

  const summaryRows = data?.map(inspection => {
    return [
      { value: inspection.total || 0, style: { border: CELL_STYLE.border } },
      { value: inspection.total - inspection.failed || 0, style: { border: CELL_STYLE.border } }, // Pass count
      { value: inspection.failed || 0, style: { border: CELL_STYLE.border } },
      {
        value: inspection.total === 0 ? (inspection.vehicle ? inspection.vehicle.name : 'N/A') : '',
        style: { border: CELL_STYLE.border }
      }
    ];
  });

  summaryDataSet[0].data = summaryRows;
  return summaryDataSet;
};

const formatDocuments2ForExcelExport = (data, localization) => {
  const CELL_STYLE = {
    font: { bold: true },
    border: {
      top: { style: 'thin', color: '#000' },
      right: { style: 'thin', color: '#000' },
      bottom: { style: 'thin', color: '#000' },
      left: { style: 'thin', color: '#000' }
    }
  };

  const detailsColumns = [
    i18n.t('InspectionSummary.Date'),
    i18n.t('InspectionSummary.Vehicle'),
    i18n.t('InspectionSummary.Fleet'),
    i18n.t('InspectionSummary.Driver'),
    i18n.t('InspectionSummary.DayOfWeek'),
    i18n.t('InspectionSummary.DistanceDriven'),
    i18n.t('InspectionSummary.Completed'),
    i18n.t('InspectionSummary.Failed'),
    i18n.t('InspectionSummary.InspectionName', { number: 1 }),
    i18n.t('InspectionSummary.InspectionLocation', { number: 1 }),
    i18n.t('InspectionSummary.InspectionStatus', { number: 1 }),
    i18n.t('InspectionSummary.InspectionTime', { number: 1 }),
    i18n.t('InspectionSummary.InspectionName', { number: 2 }),
    i18n.t('InspectionSummary.InspectionLocation', { number: 2 }),
    i18n.t('InspectionSummary.InspectionStatus', { number: 2 }),
    i18n.t('InspectionSummary.InspectionTime', { number: 2 }),
    i18n.t('InspectionSummary.InspectionName', { number: 3 }),
    i18n.t('InspectionSummary.InspectionLocation', { number: 3 }),
    i18n.t('InspectionSummary.InspectionStatus', { number: 3 }),
    i18n.t('InspectionSummary.InspectionTime', { number: 3 }),
    i18n.t('InspectionSummary.InspectionName', { number: 4 }),
    i18n.t('InspectionSummary.InspectionLocation', { number: 4 }),
    i18n.t('InspectionSummary.InspectionStatus', { number: 4 }),
    i18n.t('InspectionSummary.InspectionTime', { number: 4 }),
    i18n.t('InspectionSummary.InspectionName', { number: 5 }),
    i18n.t('InspectionSummary.InspectionLocation', { number: 5 }),
    i18n.t('InspectionSummary.InspectionStatus', { number: 5 }),
    i18n.t('InspectionSummary.InspectionTime', { number: 5 }),
    i18n.t('InspectionSummary.InspectionName', { number: 6 }),
    i18n.t('InspectionSummary.InspectionLocation', { number: 6 }),
    i18n.t('InspectionSummary.InspectionStatus', { number: 6 }),
    i18n.t('InspectionSummary.InspectionTime', { number: 6 }),
    i18n.t('InspectionSummary.InspectionName', { number: 7 }),
    i18n.t('InspectionSummary.InspectionLocation', { number: 7 }),
    i18n.t('InspectionSummary.InspectionStatus', { number: 7 }),
    i18n.t('InspectionSummary.InspectionTime', { number: 7 }),
    i18n.t('InspectionSummary.InspectionName', { number: 8 }),
    i18n.t('InspectionSummary.InspectionLocation', { number: 8 }),
    i18n.t('InspectionSummary.InspectionStatus', { number: 8 }),
    i18n.t('InspectionSummary.InspectionTime', { number: 8 })
  ];

  const DetailsDataSet = [
    {
      columns: detailsColumns.map(column => ({
        title: column,
        width: { wch: 30 },
        style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
      })),
      data: []
    }
  ];

  const detailsRows = data?.map(inspection => {
    const inspections = inspection.inspections || [];
    const inspectionData = inspections
      .map((ins, index) => [
        { value: ins.checklistName || '', style: { border: CELL_STYLE.border } },
        { value: ins.location || '', style: { border: CELL_STYLE.border } },
        {
          value: ins.inspectionDate
            ? moment(ins.inspectionDate).format(localization.formats.time.formats.dby)
            : '',
          style: { border: CELL_STYLE.border }
        },
        {
          value:
            ins.status === 'FAIL'
              ? i18n.t('InspectionSummary.Failed')
              : i18n.t('InspectionSummary.Passed'),
          style: { border: CELL_STYLE.border }
        }
      ])
      .flat();

    // Filling with empty data if there are less than 8 inspections
    while (inspectionData.length < 32) {
      inspectionData.push(
        { value: '', style: { border: CELL_STYLE.border } },
        { value: '', style: { border: CELL_STYLE.border } },
        { value: '', style: { border: CELL_STYLE.border } },
        { value: '', style: { border: CELL_STYLE.border } }
      );
    }
    const convertedDistance = inspection.distance
      ? localization?.convertDistance(inspection.distance)
      : '';
    return [
      {
        value: moment(inspection.date).format(localization.formats.time.formats.dby) || '',
        style: { border: CELL_STYLE.border }
      },
      {
        value: inspection.vehicle ? inspection.vehicle.name : '',
        style: { border: CELL_STYLE.border }
      },
      {
        value: inspection.fleets ? inspection.fleets.map(fleet => fleet.name).join(', ') : '',
        style: { border: CELL_STYLE.border }
      },
      {
        value: inspection.drivers
          ? inspection.drivers
              .map(driver => (driver.firstName || '') + ' ' + (driver.lastName || ''))
              .join(', ')
          : i18n.t('InspectionSummary.NoDrivers'),
        style: { border: CELL_STYLE.border }
      },
      {
        value: i18n.t('Weekdays.' + inspection.dayOfWeek) || '',
        style: { border: CELL_STYLE.border }
      },
      {
        value: convertedDistance,
        style: { border: CELL_STYLE.border }
      },
      { value: inspection.total || '', style: { border: CELL_STYLE.border } },
      { value: inspection.failed || '', style: { border: CELL_STYLE.border } },
      ...inspectionData
    ];
  });

  DetailsDataSet[0].data = detailsRows;
  return DetailsDataSet;
};

const _ExportToExcel = ({ data, dateRange, localization }) => {
  let summaryDataSet = [];
  summaryDataSet = formatDocumentsForExcelExport(data, localization);
  let detailsDataSet = formatDocuments2ForExcelExport(data, localization);

  return (
    <ExcelFile
      element={
        <Button
          type="default"
          size="large"
          onClick={() => {
            Mixpanel.sendTrackEvent(MPTrackingEvents.Easydocs.ExportToExcel);
            document.body.click();
          }}
          id={BUTTON_IDS.btn_inspectionSummaryExportExcel}
        >
          {i18n.t('Common.Export')}
        </Button>
      }
      filename={`${i18n.t('InspectionSummary.Title')} ${dateRange[0]} - ${dateRange[1]}`}
    >
      <ExcelSheet dataSet={summaryDataSet} name="Summary" />
      <ExcelSheet dataSet={detailsDataSet} name="Inspection Details" />
    </ExcelFile>
  );
};

export const ExportToExcel = React.memo(_ExportToExcel);

ExportToExcel.propTypes = {
  data: P.array.isRequired,
  dateRange: P.arrayOf(P.string).isRequired,
  localization: P.object
};
