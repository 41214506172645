import React from 'react';

import { TabNavLink } from 'components/nav/NavLinks';
import { useTranslation } from 'react-i18next';

const Tabs = () => {
  const { t } = useTranslation();
  return (
    <>
      <TabNavLink
        to={'/settings/massmanagement/configured'}
        isActive={(match, location) => {
          return match || location.pathname === '/settings/massmanagement';
        }}
      >
        {t('MassManagement.ConfiguredVehicles')}
      </TabNavLink>
      <TabNavLink to={'/settings/massmanagement/configurations'}>
        {t('MassManagement.MassConfigurations')}
      </TabNavLink>
      <TabNavLink to={'/settings/massmanagement/combinations'}>
        {t('MassManagement.VehicleCombinations')}
      </TabNavLink>
    </>
  );
};

export default Tabs;
