import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Roles } from './Roles';
import RoleView from './RoleView';
import RolesForm from './RolesForm';
import { Can, entities } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { RoleAudits } from './RoleAudits';
import { PATHS } from './constants';

export const RolesRouter = () => {
  return (
    <Can everyEntity={[entities.ROLE]} onFail={<NoAccessBody />}>
      <Switch>
        <Route path={PATHS.VIEW_BY_ID}>
          <Can everyEntity={[entities.ROLE_VIEW]} onFail={<NoAccessBody />}>
            <RoleView />
          </Can>
        </Route>
        <Route path={PATHS.NEW_ROLE}>
          <Can everyEntity={[entities.ROLE_CREATE]} onFail={<NoAccessBody />}>
            <RolesForm action="add" />
          </Can>
        </Route>
        <Route path={PATHS.EDIT_BY_ID}>
          <Can everyEntity={[entities.ROLE_UPDATE]} onFail={<NoAccessBody />}>
            <RolesForm action="edit" />
          </Can>
        </Route>
        <Route path={PATHS.COPY_BY_ID}>
          <Can everyEntity={[entities.ROLE_COPY]} onFail={<NoAccessBody />}>
            <RolesForm action="copy" />
          </Can>
        </Route>
        <Route path={PATHS.AUDIT_BY_ID}>
          <Can everyEntity={[entities.ROLE_AUDIT]} onFail={<NoAccessBody />}>
            <RoleAudits />
          </Can>
        </Route>
        <Route path={PATHS.Default}>
          <Roles />
        </Route>
      </Switch>
    </Can>
  );
};
