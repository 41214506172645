import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from 'components/ant';
import { Space } from 'antd';

import styles from './FormFooter.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const FormFooter = ({
  handleSave,
  handleCancel,
  isSaving,
  primaryText,
  primaryLoadingText,
  disabledPrimatyButton = false
}) => {
  const { t } = useTranslation();

  return (
    <Space className={styles.container}>
      <Button
        size="large"
        htmlType="submit"
        type="primary"
        id={BUTTON_IDS.formFooterSave}
        onClick={handleSave}
        className={styles.save}
        loading={isSaving}
        disabled={disabledPrimatyButton}
      >
        {isSaving ? primaryLoadingText || t('Common.Saving') : primaryText || t('Common.Save')}
      </Button>
      <Button
        size="large"
        type="secondary"
        className={styles.cancel}
        id={BUTTON_IDS.formFooterCancel}
        onClick={handleCancel}
      >
        {t('Common.Cancel')}
      </Button>
    </Space>
  );
};

export default FormFooter;
