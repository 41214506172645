import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Select } from 'antd';
import { Button } from 'components/ant';
import { saveRunsheet, useIsRunsheetSaving } from 'features/smartJobs/smartJobsSlice';
import { ALLOCATE_MODAL, fullWidth } from '../utils/constants';
import { getFleetList, getVehiclesList } from '../utils/helpers';
import { geofenceToServer } from '../utils/api';
import { useLocations } from 'features/locations/locationsSlice';
import { LocationType } from '../utils/constants';
import styles from './AllocateModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const AllocateModal = ({ isOpen, onCancel, current, fleets: rawFleets, vehicles: rawVehicles }) => {
  const [selectedFleet, setSelectedFleet] = useState();
  const [selectedVehicleId, setSelectedVehicleId] = useState();
  const [allocatedVehicle, setAllocatedVehicle] = useState('');
  const [vehiclesList, setVehiclesList] = useState([]);
  const [updatedRunsheet, setUpdatedRunsheet] = useState({});
  const isSaving = useIsRunsheetSaving();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locations = useLocations([LocationType.CUSTOMER]);
  const NO_FLEET = useMemo(() => ({ id: -1, name: t('Common.NoFleet') }), [t]);
  const fleets = useMemo(
    () =>
      rawFleets.map(fleet => ({
        ...fleet,
        id: fleet.id || NO_FLEET.id,
        name: fleet.name || NO_FLEET.name
      })),
    [rawFleets, NO_FLEET]
  );
  const vehicles = useMemo(
    () =>
      rawVehicles.map(vehicle => ({
        ...vehicle,
        fleets: (vehicle.fleets || []).map(fleet => ({
          ...fleet,
          id: fleet.id || NO_FLEET.id,
          name: fleet.name || NO_FLEET.name
        }))
      })),
    [rawVehicles, NO_FLEET]
  );

  const allocate = () => async () => {
    const response = await dispatch(
      saveRunsheet(
        { ...updatedRunsheet, jobs: geofenceToServer(updatedRunsheet.jobs, locations) },
        true,
        t
      )
    );
    if (response) {
      onCancel();
    }
    handleOnCancel();
  };

  const removeAllocatedVehicle = () => {
    setSelectedFleet();
    setSelectedVehicleId();
    setAllocatedVehicle('');
    setUpdatedRunsheet({ ...current, vehicle: -1 });
  };

  const handleOnCancel = () => {
    onCancel();
    setSelectedVehicleId();
    setSelectedFleet();
  };

  useEffect(() => {
    setUpdatedRunsheet({ ...current });
    setAllocatedVehicle(current?.vehicle?.name || '');
  }, [current, isOpen]);

  useEffect(() => {
    const vehiclesFromFleet = vehicles.filter(vehicle =>
      vehicle.fleets.find(fleet => fleet.id === selectedFleet)
    );
    setVehiclesList(vehiclesFromFleet);
    setSelectedVehicleId();
  }, [selectedFleet]);

  useEffect(() => {
    if (current) {
      const vehicle =
        selectedVehicleId || selectedFleet
          ? vehicles.find(vehicle => vehicle.id === selectedVehicleId)
          : { id: -1 };

      setAllocatedVehicle(vehicle?.name || '');
      setUpdatedRunsheet({ ...current, vehicle });
    }
  }, [selectedVehicleId]);

  return (
    <Modal
      title={ALLOCATE_MODAL.TITLE}
      open={isOpen}
      onOk={allocate()}
      onCancel={handleOnCancel}
      okText={ALLOCATE_MODAL.SAVE_BUTTON}
      className={styles.allocateModal}
      bodyStyle={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}
      confirmLoading={isSaving}
      centered
      width={550}
    >
      <div className={styles.topRowContainer}>
        <p className={styles.mainHeader}>
          <span>{ALLOCATE_MODAL.MAIN_HEADER}</span>
          <span
            title={`${allocatedVehicle}`}
            className={styles.allocatedVehicle}
          >{`${allocatedVehicle}`}</span>
        </p>
        {allocatedVehicle && (
          <Button danger onClick={removeAllocatedVehicle} id={BUTTON_IDS.allocateModalRemove}>
            {ALLOCATE_MODAL.REMOVE_BUTTON}
          </Button>
        )}
      </div>
      <div className={styles.selectHeader}>{ALLOCATE_MODAL.FLEET_HEADER}</div>
      <Select
        getPopupContainer={triggerNode => triggerNode.parentNode}
        onChange={setSelectedFleet}
        placeholder={ALLOCATE_MODAL.SELECT_PLACEHOLDER}
        style={fullWidth}
        value={selectedFleet}
        size={'large'}
      >
        {getFleetList(fleets)}
      </Select>
      <div className={styles.selectHeader}>{ALLOCATE_MODAL.VEHICLE_HEADER}</div>
      <Select
        onChange={setSelectedVehicleId}
        placeholder={ALLOCATE_MODAL.SELECT_PLACEHOLDER}
        value={selectedVehicleId}
        style={fullWidth}
        size={'large'}
      >
        {getVehiclesList(vehiclesList)}
      </Select>
    </Modal>
  );
};

export default AllocateModal;
