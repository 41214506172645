import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { Modal, Form, Input, Row, Col, Button, Radio, Tag, Space, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'components/ant/DateTime/DateRangePicker';
import { useLocalization } from 'features/localization/localizationSlice';
import moment from 'moment';
import { BUTTON_IDS } from 'utils/globalConstants';
import styles from './EmailModal.module.scss';
import { PlusOutlined } from '@ant-design/icons';

export function EmailModal({ showModal, onSave, onCancel, driver, isUSELD, isCANELD, ...props }) {
  const { t } = useTranslation();
  const localization = useLocalization();
  const [form] = Form.useForm();
  var [dateRanges, setDateRanges] = useState([moment().subtract(7, 'days'), moment()]);
  var defaultDates = useMemo(() => [dateRanges[0], dateRanges[1]], []);
  var [emailSubject, setEmailSubject] = useState(
    t('ELD.DriverPortal.NewEmailSubject', { name: driver?.name })
  );
  var [emailBody, setEmailBody] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countrySelected, setCountrySelected] = useState(isUSELD ? 'US' : 'Canada');
  const { TextArea } = Input;

  //const { token } = theme.useToken();
  const [tags, setTags] = useState([driver?.email]);
  const [inputVisible, setInputVisible] = useState(false);
  const [allowNew, setAllowNew] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef(null);
  const editInputRef = useRef(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = removedTag => {
    const newTags = tags.filter(tag => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
    if (newTags.length < 11) {
      setAllowNew(true);
    }
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = e => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      if (form.getFieldError('Email')?.length === 0) {
        setTags([...tags, inputValue]);
      }
    }
    setInputVisible(false);
    setInputValue('');
    form.setFieldValue('Email', '');
    if (tags.length + 1 === 11) {
      setAllowNew(false);
    }
  };
  const handleEditInputChange = e => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue('');
  };
  const tagInputStyle = {
    width: 100,
    verticalAlign: 'top'
  };
  const tagPlusStyle = {
    //background: token.colorBgContainer,
    borderStyle: 'dashed'
  };

  const availableDateRange = useMemo(() => {
    return [
      moment()
        .subtract(6, 'month')
        .startOf('day'),
      moment().endOf('day')
    ];
  }, []);

  const handleSaveRecord = useCallback(
    closeModal => {
      setIsSubmitting(true);
      const emailRecipients = tags.join(';');
      if (onSave) {
        onSave(countrySelected, emailSubject, emailRecipients, emailBody, dateRanges, closeModal);
      }
      setIsSubmitting(false);
    },
    [onSave, form, emailSubject, emailBody, dateRanges, tags]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleCountryChange = e => {
    setCountrySelected(e.target.value);
  };

  const footer = (
    <Row gutter={10}>
      <Col>
        <Button
          loading={isSubmitting}
          onClick={handleSaveRecord}
          disabled={
            dateRanges.length < 2 ||
            emailSubject.trimStart().trimEnd().length < 1 ||
            tags.length < 1
          }
          type="primary"
          id={BUTTON_IDS.driverPortalEmailSend}
        >
          {t('Messaging.Send')}
        </Button>
      </Col>
      <Col>
        <Button
          id={BUTTON_IDS.driverPortalEmailCancel}
          disabled={isSubmitting}
          type="default"
          onClick={handleCancel}
        >
          {t('Common.Cancel')}
        </Button>
      </Col>
    </Row>
  );

  return (
    <Modal
      footer={footer}
      open={showModal}
      title={t('ELD.DriverPortal.Email')}
      closable={!isSubmitting}
      onCancel={handleCancel}
      className={styles.modalClass}
    >
      <Form form={form} layout="vertical">
        {isUSELD && isCANELD && (
          <Form.Item label={t('ELD.Country')} labelAlign={'left'} colon={false} required>
            <Radio.Group onChange={handleCountryChange} value={countrySelected}>
              <Radio value={'US'}>{t('Countries.codes.us')}</Radio>
              <Radio value={'Canada'}>{t('Countries.codes.ca')}</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item
          rules={[{ required: true, min: 1, max: 50 }]}
          label={t('Messaging.Subject')}
          labelAlign={'left'}
          colon={false}
          required
        >
          <Input
            onChange={evt => setEmailSubject(evt.target.value)}
            minLength={1}
            maxLength={50}
            value={emailSubject}
          ></Input>
        </Form.Item>

        <Form.Item label={t('Messaging.Recipients')} labelAlign={'left'} colon={false} required>
          <Space size={[0, 8]} wrap>
            {tags.map((tag, index) => {
              if (editInputIndex === index) {
                return (
                  <Input
                    ref={editInputRef}
                    key={tag}
                    type="text"
                    size="small"
                    style={tagInputStyle}
                    value={editInputValue}
                    onChange={handleEditInputChange}
                    onBlur={handleEditInputConfirm}
                    onPressEnter={handleEditInputConfirm}
                  />
                );
              }
              const isLongTag = tag.length > 30;
              const tagElem = (
                <Tag
                  key={tag}
                  closable={true}
                  style={{
                    userSelect: 'none'
                  }}
                  onClose={() => handleClose(tag)}
                >
                  <span>{isLongTag ? `${tag.slice(0, 30)}...` : tag}</span>
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
          </Space>
        </Form.Item>

        <Space size={[0, 8]} wrap style={{ marginBottom: 15 }}>
          {inputVisible ? (
            <Form.Item
              name="Email"
              rules={[
                { required: false },
                { type: 'email', message: t('Users.ValidationErrors.InvalidEmail') }
              ]}
            >
              <Input
                ref={inputRef}
                size="small"
                style={tagInputStyle}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            </Form.Item>
          ) : (
            allowNew && (
              <Tag style={tagPlusStyle} onClick={showInput}>
                <PlusOutlined /> {t('ELD.DriverPortal.NewEmail')}
              </Tag>
            )
          )}
        </Space>

        <Form.Item
          rules={[{ required: true }]}
          label={t('Forms.ActualForm.Content')}
          labelAlign={'left'}
          colon={false}
        >
          <TextArea
            rows={10}
            onChange={evt => setEmailBody(evt.target.value)}
            maxLength={500}
          ></TextArea>
        </Form.Item>

        <Form.Item label={t('ELD.Date Range')} labelAlign={'left'} colon={false} required>
          <DateRangePicker
            disabled={false}
            value={dateRanges}
            maxDayRange={30}
            defaultDates={defaultDates}
            format={localization.formats.time.formats.dby.toUpperCase()}
            availableDatesRange={availableDateRange}
            onDateRangeChanged={ranges => setDateRanges(ranges)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
