import * as Yup from 'yup';
import i18n from 'i18next';

export const EMPTY_ARRAY = [];

export const initialValues = {
  name: '',
  description: '',
  type: '',
  status: '',
  vehicleTypes: [],
  version: '',
  companyId: ''
};

export const VehicleMntTypesLens = {
  All: 'VehicleMaintenanceTypes.AllFilter',
  Deleted: 'VehicleMaintenanceTypes.DeletedFilter'
};

export const validationSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .required(i18n.t('VehicleMaintenanceTypes.FormValidation.NameRequired'))
      .matches(/\S/, i18n.t('VehicleMaintenanceTypes.FormValidation.NameRequired'))
      .max(50, i18n.t('VehicleMaintenanceTypes.FormValidation.NameMax')),
    companyId: Yup.string().required(
      i18n.t('VehicleMaintenanceTypes.FormValidation.CompanyRequired')
    )
  });
};

export const DELETED_VEHICLES_MNT_TYPES_PATH = '/settings/vehicleMntTypes/deleted';

export const TYPE_ACTION = {
  Delete: 'delete',
  Restore: 'restore'
};

export const Paths = {
  VIEW: '/settings/vehicleMntTypes/id',
  ADD: '/settings/vehicleMntTypes/newVehicleMntType',
  EDIT: '/settings/vehicleMntTypes/edit/id/',
  DEFAULT: '/settings/vehicleMntTypes'
};
