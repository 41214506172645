import React, { useMemo } from 'react';

import { Select, Row, Col, Checkbox, Tag, Button, Tooltip } from 'antd';

import { useTranslation } from 'react-i18next';

import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { SortableTable } from './SortableTable';

import { getColumnsForTable, sortColumns } from './ViewsConfigModalHelpers';

import styles from './ViewsConfigModal.module.scss';

const SELECT_ALL_KEY = 'Select_All';

export const GridColumnEditor = ({
  allColumns,
  includedColumns,
  setIncludedColumns,
  groupBy,
  updateGroupBy,
  onResetSelection,
  defaultColumnsLabels
}) => {
  const { t } = useTranslation();
  const defaultColumnLabelString = defaultColumnsLabels?.length
    ? defaultColumnsLabels.join(', ')
    : '';

  const { allSelectableColumns, allColumnKeys } = useMemo(() => {
    const allSelectableColumns = allColumns.filter(column => {
      return column.isConfigurable && !column.fixed;
    });
    const sortedAllSelectableColumns = sortColumns(allSelectableColumns);

    const allColumnKeys = [SELECT_ALL_KEY, ...allSelectableColumns.map(column => column.key)];

    return {
      allSelectableColumns: sortedAllSelectableColumns,
      allColumnKeys
    };
  }, [allColumns]);

  const { includedColumnKeys, includeAllColumns } = useMemo(() => {
    const includeAllColumns = includedColumns?.length === allSelectableColumns.length + 1;

    let includedColumnKeys = includedColumns
      ? includedColumns.filter(column => !column.fixed).map(column => column.key)
      : [];
    if (includeAllColumns) {
      includedColumnKeys.push(SELECT_ALL_KEY);
    }

    return {
      includedColumnKeys,
      includeAllColumns
    };
  }, [includedColumns]);

  const onRemove = column => {
    // Remove item from included columns
    const newIncludedColumns = includedColumns.filter(item => {
      return item.key !== column.key;
    });
    setIncludedColumns(newIncludedColumns);

    // Remove from group by if it is there
    const newGroupBy = groupBy.filter(groupBy => {
      return groupBy !== column.key;
    });
    updateGroupBy(newGroupBy);
  };

  const onSortEnd = (oldIndex, newIndex) => {
    let targetIndex = newIndex;

    // Since can't figure out how to prevent react-sortable-hoc from letting user drop
    // on column that isn't sortable, just don't allow it in this callback
    const targetItem = includedColumns[targetIndex];
    if (targetItem.fixed && targetIndex === 0) {
      targetIndex = 1;
    }

    let newIncludedColumns = [...includedColumns];
    if (oldIndex !== targetIndex) {
      const item = newIncludedColumns.splice(oldIndex, 1)[0];
      newIncludedColumns.splice(targetIndex, 0, item);

      setIncludedColumns(newIncludedColumns);
    }
  };

  const getSelectValues = () => {
    // Show intermediate checked state on header if some but not columns selected
    const indeterminate = !includeAllColumns && includedColumns && includedColumns.length > 1;

    // Add "Select all", "Deselect all" header
    const headerOption = {
      headerLabel: includeAllColumns
        ? t('Tracking.FilterEvents.DeselectAll')
        : t('Tracking.FilterEvents.SelectAll'),
      id: SELECT_ALL_KEY
    };
    const values = Array(
      <Select.Option
        className={includeAllColumns ? styles.selectChecked : ''}
        key={headerOption.id}
        value={headerOption.id}
        label={headerOption.headerLabel}
      >
        <div label={headerOption.headerLabel}>
          <Checkbox checked={includeAllColumns} indeterminate={indeterminate}></Checkbox>
          <span className={styles.columnSelectLabel}>{headerOption.headerLabel}</span>
        </div>
      </Select.Option>
    );

    // Add all columns
    if (allSelectableColumns?.length) {
      allSelectableColumns.forEach(column => {
        let isChecked = includedColumnKeys
          ? !!includedColumnKeys.find(includedColumnKey => includedColumnKey === column.key)
          : false;
        values.push(
          <Select.Option
            className={isChecked ? styles.selectChecked : ''}
            key={String(column.key)}
            value={column.key}
            label={column.label}
          >
            <div label={column.label}>
              <Checkbox checked={isChecked}></Checkbox>
              <span className={styles.columnSelectLabel}>{column.label}</span>
            </div>
          </Select.Option>
        );
      });
    }

    return values;
  };

  const handleSelectChange = selectedColumnKeys => {
    let newSelectedColumnKeys = selectedColumnKeys;

    // Check for select all and deselect all special cases
    if (includeAllColumns && !selectedColumnKeys.includes(SELECT_ALL_KEY)) {
      newSelectedColumnKeys = [];
    } else if (!includeAllColumns && selectedColumnKeys.includes(SELECT_ALL_KEY)) {
      newSelectedColumnKeys = allColumnKeys.filter(key => !(key === SELECT_ALL_KEY));
    }

    // Get keys of columns we need to add or remove
    let addedKeys = newSelectedColumnKeys.filter(x => includedColumnKeys.indexOf(x) === -1);
    let removedKeys = includedColumnKeys.filter(x => newSelectedColumnKeys.indexOf(x) === -1);

    // Remove from includedColumns
    let newIncludedColumns = includedColumns.filter(column => !removedKeys.includes(column.key));

    // Add to includedColumns
    if (addedKeys && addedKeys.length > 0) {
      addedKeys.forEach(key => {
        const columnToAdd = allColumns.find(column => column.key === key);
        if (columnToAdd) {
          newIncludedColumns.push(columnToAdd);
        }
      });
    }

    setIncludedColumns(newIncludedColumns);
  };

  const tagRender = props => {
    const { label, closable, onClose } = props;
    return (
      <Tag
        className={styles.tagContainer}
        onMouseDown={event => {
          event.preventDefault();
          event.stopPropagation();
        }}
        closable={closable}
        onClose={onClose}
      >
        {label?.props?.label}
      </Tag>
    );
  };

  // Disabling Group By since implementation on Table for AntD is not complete yet (ran into roadblocks)
  //
  // const includedColumnsForSelect = useMemo(() => {
  //   return includedColumns.map(column => {
  //     // If 3 are selected, disable other selections so user can't select more
  //     const disabled = groupBy.length >= MAX_GROUP_BY_ITEMS && !groupBy.includes(column.key);
  //     return {
  //       id: column.key,
  //       label: column.label,
  //       disabled: disabled
  //     };
  //   });
  // }, [includedColumns, groupBy]);

  const columnSelectValues = getSelectValues();

  const columns = getColumnsForTable(onRemove, t);

  return (
    <>
      <Row wrap={false} className={styles.viewsConfigRow}>
        <Col span={24}>
          <div className={styles.addColumnsMessageText}>{t('Tracking.ViewsConfig.AddColumns')}</div>
          <div className={styles.viewsConfigControlsContainer}>
            <Select
              className={styles.columnsSelect}
              filterOption={(inputValue, option) => {
                const result =
                  option?.label?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                return result;
              }}
              maxTagCount={2}
              menuItemSelectedIcon={<></>}
              mode="multiple"
              value={includedColumnKeys}
              onChange={handleSelectChange}
              placeholder={t('Tracking.ViewsConfig.AddColumns')}
              showSearch
              autoClearSearchValue={false}
              suffixIcon={<SearchOutlined className="ant-select-suffix" />}
              tagRender={tagRender}
            >
              {columnSelectValues}
            </Select>
            <Button size="medium" className={styles.resetButton} onClick={onResetSelection}>
              <span>{t('Tracking.ViewsConfig.ResetSelection')}</span>
              <Tooltip
                title={`${t(
                  'Tracking.ViewsConfig.ResetSelectionTooltipText'
                )} ${defaultColumnLabelString}`}
              >
                <InfoCircleOutlined className={styles.reorderInfoIcon} />
              </Tooltip>
            </Button>
          </div>
        </Col>
      </Row>
      <Row wrap={false} className={styles.viewsConfigRow}>
        <Col span={24}>
          <SortableTable
            className={styles.viewsConfigTable}
            columns={columns}
            dataSource={includedColumns}
            onSortEnd={onSortEnd}
            scroll={{ y: 'calc(260px - 18px)' }}
            pagination={false}
            size="small"
            bordered
          />
        </Col>
      </Row>
      <Row wrap={false} className={styles.viewsConfigRow} style={{ height: '40px' }}>
        <Col span={24}>
          {/* Disabling Group By since implementation on Table for AntD is not complete yet (ran into roadblocks) */}
          {/* <div>{t('Tracking.ViewsConfig.GroupBy')}</div>
          <Select
            allowClear
            className={styles.groupBySelect}
            placeholder={t('Tracking.ViewsConfig.SelectColumns')}
            data={includedColumnsForSelect}
            size="large"
            mode="multiple"
            showArrow
            value={groupBy}
            onChange={value => {
              updateGroupBy(value);
            }}
          >
            {(includedColumnsForSelect || []).map(value => (
              <Option key={value.id} value={value.id} disabled={value.disabled}>
                {value.label}
              </Option>
            ))}
          </Select>
          <div className={styles.groupByOrderMessage}>
            {t('Tracking.ViewsConfig.GroupByOrderMessage', { num: MAX_GROUP_BY_ITEMS })}
          </div> */}
        </Col>
      </Row>
    </>
  );
};
