import React from 'react';
import ReactExport from 'react-data-export';
import P from 'prop-types';
import { formatBytes } from 'utils/methods';
import { format } from 'utils/dates';
import i18n from 'i18next';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { BUTTON_IDS } from 'utils/globalConstants';
import { Button } from 'antd';
import moment from 'moment';

export const ExportType = {
  Folder: 0,
  DeviceList: 1,
  DeviceDetails: 2
};
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const formatDocumentsForExcelExport = (documents, folders, fleets, vehicles, devices) => {
  const CELL_STYLE = {
    font: {
      bold: true
    },
    border: {
      top: { style: 'thin', color: '#000' },
      right: { style: 'thin', color: '#000' },
      bottom: { style: 'thin', color: '#000' },
      left: { style: 'thin', color: '#000' }
    }
  };
  const TITLE_COLUMNS = [
    `${i18n.t('ExportToExcel.Name')}`,
    `${i18n.t('ExportToExcel.Description')}`,
    `${i18n.t('ExportToExcel.Acknowledge')}`,
    `${i18n.t('ExportToExcel.FileSize')}`,
    `${i18n.t('ExportToExcel.Folders')}`,
    `${i18n.t('ExportToExcel.FileName')}`,
    `${i18n.t('ExportToExcel.Associations')}`
  ];

  const documentsDataSet = [
    {
      columns: [
        {
          title: TITLE_COLUMNS[0],
          width: { wch: 40 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[1],
          width: { wch: 100 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[2],
          width: { wch: 15 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[3],
          width: { wch: 10 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[4],
          width: { wch: 20 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[5],
          width: { wch: 100 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[6],
          width: { wch: 80 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        }
      ],
      data: []
    }
  ];

  const rows = documents.map(document => {
    const foldersStringArray = folders
      .filter(folder => document.folders.map(docFolder => docFolder.id).includes(folder.id))
      .map(folder => folder.name);

    //Get de associations ids from the document and group them by fleet, vehicle or device
    const documentFleetAssociations = document.associations
      .filter(assoc => assoc.fleetId)
      .map(assoc => assoc.fleetId);
    const documentVehicleAssociations = document.associations
      .filter(assoc => assoc.vehicleId)
      .map(assoc => assoc.vehicleId);
    const documentDeviceAssociations = document.associations
      .filter(assoc => assoc.deviceId)
      .map(assoc => assoc.deviceId);

    //Make the Strings for the associatioted fleets, vehicles or devices using the ids from the associoations in the document
    const fleetAssociationsString =
      documentFleetAssociations.length > 0 &&
      `Fleet: ${fleets
        .filter(fleet => documentFleetAssociations.includes(fleet.id))
        .map(fleet => fleet.name)
        .join(', ')}`;
    const vehiclesAssociationsString =
      documentVehicleAssociations.length &&
      `Vehicle: ${vehicles
        .filter(vehicle => documentVehicleAssociations.includes(vehicle.id))
        .map(vehicle => vehicle.name)}`;
    const devicesAssociationsString =
      documentDeviceAssociations.length > 0 &&
      `Device: ${devices
        .filter(device => documentDeviceAssociations.includes(device.id))
        .map(device => device.name)}`;

    return [
      { value: `${document.name || ''}`, style: { border: CELL_STYLE.border } },
      { value: `${document.description || ''}`, style: { border: CELL_STYLE.border } },
      { value: `${document.acknowledge ? 'TRUE' : 'FALSE'}`, style: { border: CELL_STYLE.border } },
      {
        value: `${document.filesize ? formatBytes(document.filesize) : ''}`,
        style: { border: CELL_STYLE.border }
      },
      {
        value: `${foldersStringArray.length > 0 ? foldersStringArray.join(', ') : ''}`,
        style: { border: CELL_STYLE.border }
      },
      { value: `${document.filename || ''}`, style: { border: CELL_STYLE.border } },
      {
        //Do not render the undefined strings from the associations
        value: `${[fleetAssociationsString, vehiclesAssociationsString, devicesAssociationsString]
          .filter(assoc => assoc)
          .join(' || ') || ''}`,
        style: { border: CELL_STYLE.border }
      }
    ];
  });
  documentsDataSet[0].data = rows;
  return documentsDataSet;
};

const formatDocumentsForExcelExport_NEW = (
  documents,
  folders,
  fleets,
  vehicles,
  devices,
  localization,
  subCompanies
) => {
  const CELL_STYLE = {
    font: {
      bold: true
    },
    border: {
      top: { style: 'thin', color: '#000' },
      right: { style: 'thin', color: '#000' },
      bottom: { style: 'thin', color: '#000' },
      left: { style: 'thin', color: '#000' }
    }
  };
  const TITLE_COLUMNS = [
    `${i18n.t('ExportToExcel.Name')}`,
    `${i18n.t('ExportToExcel.Description')}`,
    `${i18n.t('ExportToExcel.Acknowledge')}`,
    `${i18n.t('ExportToExcel.FileSize')}`,
    `${i18n.t('ExportToExcel.Folders')}`,
    `${i18n.t('ExportToExcel.FileName')}`,
    `${i18n.t('ExportToExcel.Associations')}`,
    `${i18n.t('ExportToExcel.UploadDate')}`,
    `${i18n.t('ExportToExcel.ExpirationDate')}`,
    `${i18n.t('ExportToExcel.AutoRemove')}`
  ];

  const documentsDataSet = [
    {
      columns: [
        {
          title: TITLE_COLUMNS[0],
          width: { wch: 40 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[1],
          width: { wch: 100 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[2],
          width: { wch: 15 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[3],
          width: { wch: 10 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[4],
          width: { wch: 20 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[5],
          width: { wch: 100 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[6],
          width: { wch: 80 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[7],
          width: { wch: 20 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[8],
          width: { wch: 20 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[9],
          width: { wch: 20 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        }
      ],
      data: []
    }
  ];

  const rows = documents.map(document => {
    const foldersStringArray = folders
      .filter(folder => document.folders.map(docFolder => docFolder.id).includes(folder.id))
      .map(folder => folder.name);

    //Get de associations ids from the document and group them by fleet, vehicle or device
    const documentFleetAssociations = document.associations
      .filter(assoc => assoc.fleetId)
      .map(assoc => assoc.fleetId);
    const documentVehicleAssociations = document.associations
      .filter(assoc => assoc.vehicleId)
      .map(assoc => assoc.vehicleId);
    const documentDeviceAssociations = document.associations
      .filter(assoc => assoc.deviceId)
      .map(assoc => assoc.deviceId);

    //Make the Strings for the associatioted fleets, vehicles or devices using the ids from the associoations in the document
    const fleetAssociationsString =
      documentFleetAssociations.length > 0 &&
      `Fleet: ${fleets
        .filter(fleet => documentFleetAssociations.includes(fleet.id))
        .map(fleet => fleet.name)
        .join(', ')}`;
    const vehiclesAssociationsString =
      documentVehicleAssociations.length &&
      `Vehicle: ${vehicles
        .filter(vehicle => documentVehicleAssociations.includes(vehicle.id))
        .map(vehicle => vehicle.name)}`;
    const devicesAssociationsString =
      documentDeviceAssociations.length > 0 &&
      `Device: ${devices
        .filter(device => documentDeviceAssociations.includes(device.id))
        .map(device => device.name)}`;

    let subCompaniesAssociationsString = '';

    if (
      documentFleetAssociations.length > 0 ||
      documentVehicleAssociations.length > 0 ||
      documentDeviceAssociations.length > 0
    ) {
      const fleetsArray = fleets
        .filter(fleet => documentFleetAssociations.includes(fleet.id))
        .map(fleet => fleet.company.id);
      const vehiclesArray = vehicles
        .filter(vehicle => documentVehicleAssociations.includes(vehicle.id))
        .map(vehicle => vehicle.company.id);
      const deviceArray = devices
        .filter(device => documentDeviceAssociations.includes(device.id))
        .map(device => device.company.id);
      const companiesArray = subCompanies
        .filter(
          company =>
            fleetsArray.includes(company.id) ||
            vehiclesArray.includes(company.id) ||
            deviceArray.includes(company.id)
        )
        .map(company => company.name)
        .join(', ');
      subCompaniesAssociationsString = `Company: ${companiesArray}`;
    }

    return [
      { value: `${document.name || ''}`, style: { border: CELL_STYLE.border } },
      { value: `${document.description || ''}`, style: { border: CELL_STYLE.border } },
      { value: `${document.acknowledge ? 'TRUE' : 'FALSE'}`, style: { border: CELL_STYLE.border } },
      {
        value: `${document.filesize ? formatBytes(document.filesize) : ''}`,
        style: { border: CELL_STYLE.border }
      },
      {
        value: `${foldersStringArray.length > 0 ? foldersStringArray.join(', ') : ''}`,
        style: { border: CELL_STYLE.border }
      },
      { value: `${document.filename || ''}`, style: { border: CELL_STYLE.border } },
      {
        //Do not render the undefined strings from the associations
        value: `${[
          fleetAssociationsString,
          vehiclesAssociationsString,
          devicesAssociationsString,
          subCompaniesAssociationsString
        ]
          .filter(assoc => assoc)
          .join(' || ') || ''}`,
        style: { border: CELL_STYLE.border }
      },
      {
        value: `${
          document.uploadedAt
            ? format(document.uploadedAt, localization.formats.time.formats.default)
            : ''
        }`,
        style: { border: CELL_STYLE.border }
      },
      {
        value: `${
          document.expiredAt
            ? format(document.expiredAt, localization.formats.time.formats.default)
            : ''
        }`,
        style: { border: CELL_STYLE.border }
      },
      { value: `${document.autoRemoval ? 'Yes' : 'No'}`, style: { border: CELL_STYLE.border } }
    ];
  });
  documentsDataSet[0].data = rows;
  return documentsDataSet;
};

const formatDocumentsForExcelExport_DeviceListSiteAdmin = (documents, localization) => {
  const CELL_STYLE = {
    font: {
      bold: true
    },
    border: {
      top: { style: 'thin', color: '#000' },
      right: { style: 'thin', color: '#000' },
      bottom: { style: 'thin', color: '#000' },
      left: { style: 'thin', color: '#000' }
    }
  };
  const TITLE_COLUMNS = [
    `${i18n.t('ExportToExcel.Device')}`,
    `${i18n.t('ExportToExcel.Vehicle')}`,
    `${i18n.t('ExportToExcel.Company')}`,
    `${i18n.t('ExportToExcel.Fleet')}`,
    `${i18n.t('ExportToExcel.DocumentsInApp')}`,
    `${i18n.t('ExportToExcel.Uploaded')}`,
    `${i18n.t('ExportToExcel.Received')}`,
    `${i18n.t('ExportToExcel.Removed')}`,
    `${i18n.t('ExportToExcel.LastAccessed')}`,
    `${i18n.t('ExportToExcel.Driver')}`
  ];

  const documentsDataSet = [
    {
      columns: [
        {
          title: TITLE_COLUMNS[0],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[1],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[2],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[3],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[4],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[5],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[6],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[7],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[8],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[9],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        }
      ],
      data: []
    }
  ];

  const rows = [...documents]
    .sort((a, b) => {
      return a.device?.name?.toUpperCase() > b.device?.name?.toUpperCase() ? 1 : -1;
    })
    .map(document => {
      return [
        { value: `${document.device?.name || ''}`, style: { border: CELL_STYLE.border } },
        { value: `${document.vehicle?.name || ''}`, style: { border: CELL_STYLE.border } },
        { value: `${document.company?.name || ''}`, style: { border: CELL_STYLE.border } },
        {
          value: `${document.fleets
            ?.map(function(elem) {
              return elem.name;
            })
            .join() || ''}`,
          style: { border: CELL_STYLE.border }
        },
        { value: `${document.inAppCount || '0'}`, style: { border: CELL_STYLE.border } },
        { value: `${document.uploadedCount || '0'}`, style: { border: CELL_STYLE.border } },
        { value: `${document.receivedCount || '0'}`, style: { border: CELL_STYLE.border } },
        { value: `${document.removedCount || '0'}`, style: { border: CELL_STYLE.border } },
        {
          value: `${
            document.lastAccessed
              ? format(document.lastAccessed, localization.formats.time.formats.dby_imsp)
              : ''
          }`,
          style: { border: CELL_STYLE.border }
        },
        {
          value: `${
            document.driver ? document.driver?.firstName + ' ' + document.driver?.lastName : ''
          }`,
          style: { border: CELL_STYLE.border }
        }
      ];
    });

  documentsDataSet[0].data = rows;
  return documentsDataSet;
};

const formatDocumentsForExcelExport_DeviceList = (documents, localization) => {
  const CELL_STYLE = {
    font: {
      bold: true
    },
    border: {
      top: { style: 'thin', color: '#000' },
      right: { style: 'thin', color: '#000' },
      bottom: { style: 'thin', color: '#000' },
      left: { style: 'thin', color: '#000' }
    }
  };
  const TITLE_COLUMNS = [
    `${i18n.t('ExportToExcel.Device')}`,
    `${i18n.t('ExportToExcel.Vehicle')}`,
    `${i18n.t('ExportToExcel.Company')}`,
    `${i18n.t('ExportToExcel.Fleet')}`,
    `${i18n.t('ExportToExcel.LastAccessed')}`,
    `${i18n.t('ExportToExcel.Driver')}`
  ];

  const documentsDataSet = [
    {
      columns: [
        {
          title: TITLE_COLUMNS[0],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[1],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[2],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[3],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[4],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[5],
          width: { wch: 30 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        }
      ],
      data: []
    }
  ];

  const rows = [...documents]
    .sort((a, b) => {
      return a.device?.name?.toUpperCase() > b.device?.name?.toUpperCase() ? 1 : -1;
    })
    .map(document => {
      return [
        { value: `${document.device?.name || ''}`, style: { border: CELL_STYLE.border } },
        { value: `${document.vehicle?.name || ''}`, style: { border: CELL_STYLE.border } },
        { value: `${document.company?.name || ''}`, style: { border: CELL_STYLE.border } },
        {
          value: `${document.fleets
            ?.map(function(elem) {
              return elem.name;
            })
            .join() || ''}`,
          style: { border: CELL_STYLE.border }
        },
        {
          value: `${
            document.lastAccessed
              ? format(document.lastAccessed, localization.formats.time.formats.dby_imsp)
              : ''
          }`,
          style: { border: CELL_STYLE.border }
        },
        {
          value: `${
            document.driver ? document.driver?.firstName + ' ' + document.driver?.lastName : ''
          }`,
          style: { border: CELL_STYLE.border }
        }
      ];
    });

  documentsDataSet[0].data = rows;
  return documentsDataSet;
};

const formatDocumentsForExcelExport_DeviceDetails = (documents, localization) => {
  const CELL_STYLE = {
    font: {
      bold: true
    },
    border: {
      top: { style: 'thin', color: '#000' },
      right: { style: 'thin', color: '#000' },
      bottom: { style: 'thin', color: '#000' },
      left: { style: 'thin', color: '#000' }
    }
  };
  const TITLE_COLUMNS = [
    `${i18n.t('ExportToExcel.DocumentName')}`,
    `${i18n.t('ExportToExcel.DocumentDescription')}`,
    `${i18n.t('ExportToExcel.Folders')}`,
    `${i18n.t('ExportToExcel.FileSize')}`,
    `${i18n.t('ExportToExcel.Acknowledge')}`,
    `${i18n.t('ExportToExcel.UploadedDate')}`,
    `${i18n.t('ExportToExcel.ReceivedDate')}`,
    `${i18n.t('ExportToExcel.ExpirationDate')}`,
    `${i18n.t('ExportToExcel.RemovedDate')}`,
    `${i18n.t('ExportToExcel.LastRead')}`,
    `${i18n.t('ExportToExcel.Driver')}`
  ];

  const documentsDataSet = [
    {
      columns: [
        {
          title: TITLE_COLUMNS[0],
          width: { wch: 40 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[1],
          width: { wch: 40 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[2],
          width: { wch: 40 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[3],
          width: { wch: 15 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[4],
          width: { wch: 15 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[5],
          width: { wch: 22 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[6],
          width: { wch: 22 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[7],
          width: { wch: 15 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[8],
          width: { wch: 22 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[9],
          width: { wch: 22 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_COLUMNS[10],
          width: { wch: 20 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        }
      ],
      data: []
    }
  ];

  const rows = [...documents]
    .sort((a, b) => {
      return a.name?.toUpperCase() > b.name?.toUpperCase() ? 1 : -1;
    })
    .map(document => {
      return [
        { value: `${document.name || ''}`, style: { border: CELL_STYLE.border } },
        { value: `${document.description || ''}`, style: { border: CELL_STYLE.border } },
        {
          value: `${document.folders ? document.folders?.map(c => c.name).join(', ') : ''}`,
          style: { border: CELL_STYLE.border }
        },
        {
          value: `${document.filesize ? formatBytes(document.filesize) : ''}`,
          style: { border: CELL_STYLE.border }
        },
        {
          value: `${document.acknowledge ? i18n.t('Easydocs.True') : i18n.t('Easydocs.False')}`,
          style: { border: CELL_STYLE.border }
        },
        {
          value: `${
            document.uploadedAt
              ? format(document.uploadedAt, localization.formats.time.formats.dby_imsp)
              : ''
          }`,
          style: { border: CELL_STYLE.border }
        },
        {
          value: `${
            document.receivedAt
              ? format(document.receivedAt, localization.formats.time.formats.dby_imsp)
              : ''
          }`,
          style: { border: CELL_STYLE.border }
        },
        {
          value: `${
            document.expiredAt
              ? format(document.expiredAt, localization.formats.time.formats.dby)
              : ''
          }`,
          style: { border: CELL_STYLE.border }
        },
        {
          value: `${
            document.removedAt
              ? format(document.removedAt, localization.formats.time.formats.dby_imsp)
              : ''
          }`,
          style: { border: CELL_STYLE.border }
        },
        {
          value: `${
            document.lastReadAt
              ? format(document.lastReadAt, localization.formats.time.formats.dby_imsp)
              : ''
          }`,
          style: { border: CELL_STYLE.border }
        },
        {
          value: `${
            document.driver ? document.driver?.firstName + ' ' + document.driver?.lastName : ''
          }`,
          style: { border: CELL_STYLE.border }
        }
      ];
    });
  documentsDataSet[0].data = rows;
  return documentsDataSet;
};

const _ExportToExcel = ({
  data,
  company,
  folders,
  fleets,
  vehicles,
  devices,
  localization,
  subCompanies,
  exportType = ExportType.Folder,
  isSiteAdmin = false
}) => {
  let dataset = [];
  if (exportType == ExportType.Folder) {
    dataset = formatDocumentsForExcelExport_NEW(
      data,
      folders,
      fleets,
      vehicles,
      devices,
      localization,
      subCompanies
    );
  } else if (exportType == ExportType.DeviceList) {
    if (isSiteAdmin) {
      dataset = formatDocumentsForExcelExport_DeviceListSiteAdmin(data, localization);
    } else {
      dataset = formatDocumentsForExcelExport_DeviceList(data, localization);
    }
  } else {
    dataset = formatDocumentsForExcelExport_DeviceDetails(data, localization);
  }

  return (
    <ExcelFile
      element={
        <Button
          type={exportType == ExportType.Folder ? 'default' : 'primary'}
          size="large"
          onClick={() => {
            Mixpanel.sendTrackEvent(MPTrackingEvents.Easydocs.ExportToExcel);
            document.body.click();
          }}
          id={BUTTON_IDS.easydocsExportExcel}
        >
          {i18n.t('Easydocs.ExportToExcel')}
        </Button>
      }
      filename={
        exportType == ExportType.Folder
          ? `Easydocs Report_${company && company.name}_${format(
              new Date(),
              localization.formats.time.formats.default
            )}`
          : 'Easydocs - Device Details' +
            (exportType == ExportType.DeviceList ? '' : ' for ' + devices[0].name)
      }
    >
      <ExcelSheet dataSet={dataset} name="Easydocs" />
    </ExcelFile>
  );
};

export const ExportToExcel = React.memo(_ExportToExcel);

ExportToExcel.propTypes = {
  data: P.array.isRequired,
  company: P.object.isRequired,
  folders: P.array.isRequired,
  fleets: P.array.isRequired,
  vehicles: P.array.isRequired,
  devices: P.array.isRequired,
  subCompanies: P.array.isRequired
};
