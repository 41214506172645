import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { logOutAsUser } from 'features/user/userSlice';
import { fetchCompanies } from 'features/company/companySlice';
import { fetchPermissions } from 'features/permissions/permissionsSlice';
import { Button } from 'components/ant';
import styles from './LogInAsUserHeader.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useCan } from 'features/permissions/canHooks';

export const LogInAsUserHeader = ({ user }) => {
  const dispatch = useDispatch();
  const can = useCan();
  const { t } = useTranslation();
  const history = useHistory();

  const handleCloseLogInAsUser = () => {
    dispatch(logOutAsUser(fetchPermissions, fetchCompanies, history, can));
  };

  return (
    <div className={styles.logInAsUser}>
      {t('Users.LogInAsUser.LoggedInAs')}
      <span className={styles.logInAsUserText}>{`${user.firstName} ${user.lastName}`}</span>
      <Button
        size="small"
        id={BUTTON_IDS.loginAsUser}
        onClick={handleCloseLogInAsUser}
        className={styles.logOutAsUserButton}
      >
        <span className={styles.logOutAsUserButtonText}>{t('Users.LogInAsUser.LogOut')}</span>
      </Button>
    </div>
  );
};
