import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { useSubCompanies } from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';
import {
  useTachoInfo,
  fetchTachoCardByCompanyId,
  fetchFatigueStatusByCompanyId,
  useCompanyFetchingStatus
} from 'features/tacho/tachoSlice';
import { useDispatch } from 'react-redux';
import { useBranches } from 'features/locations/locationsSlice';
import { useUsers } from 'features/users/usersSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { FatigueToolbar } from './FatigueToolbar';
import { useFleets } from 'features/fleets/fleetsSlice';
import { FatigueGridColumns } from './columns';
import { sortBy } from 'lodash';
import { PATH_SPLIT, selectAll } from 'components/form/treeselect/TreeSelect';
import styles from './fatigue.module.scss';
import { Tabs } from 'antd';
import { useParams, useHistory } from 'react-router';
import { useTimer } from 'utils/hooks/useTimer';
import { FatigueTable } from './fatigueTable';

export function TachoTab() {
  const UPDATE_FREQUENCY = 120000;
  const dispatch = useDispatch();
  const subCompanies = useSubCompanies();
  const userKey = useUserKey();
  const tachoData = useTachoInfo();
  const fleets = useFleets();

  const isFetchingTachoData = useCompanyFetchingStatus(subCompanies);
  const branches = useBranches();
  const userList = useUsers();
  const localization = useLocalization();

  const [isFetching, setIsFetching] = useState(false);
  const [initTableData, setInitTableData] = useState([]);

  let { type } = useParams();
  const history = useHistory();

  const loadData = useCallback(() => {
    if (userKey && subCompanies) {
      subCompanies.forEach(c => {
        dispatch(fetchTachoCardByCompanyId(c.id, userKey));
        dispatch(fetchFatigueStatusByCompanyId(c.id, userKey));
      });
    }
  }, [dispatch, userKey, subCompanies]);

  useEffect(() => {
    setIsFetching(true);
    loadData();
  }, [loadData]);

  useTimer(UPDATE_FREQUENCY, loadData);

  useEffect(() => {
    if (isFetchingTachoData) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
    }
  }, [subCompanies, isFetchingTachoData]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    let data = [];
    if (subCompanies && branches) {
      subCompanies.forEach(c => {
        const companyTachoData = tachoData[c.id];
        if (!companyTachoData) {
          return;
        }
        const canAccessNoBranch = branches.some(b => b.id === -1);
        for (let userId in companyTachoData) {
          if (isNaN(parseInt(userId))) continue;

          //Only show drivers from branches that the current user could access to
          const userData = userList?.find(u => u.id === parseInt(userId));
          if (
            !userData ||
            !branches.some(
              b => b.id === userData.location?.id || (canAccessNoBranch && !userData.location?.id)
            )
          ) {
            continue;
          }

          const userTachoData = companyTachoData[userId];

          if (!userTachoData?.fatigueStatus) continue;

          const row = {
            user: userTachoData.card,
            fatigueStatus: userTachoData.fatigueStatus,
            vehicleName: 'No Vehicle Associated',
            location: 'Unknown',
            branch: '',
            fleet: 'No Fleet'
          };

          if (userTachoData.card) {
            if (userTachoData.card.last_activity) {
              row.lastActivity = Math.max(
                0,
                moment().valueOf() - moment(userTachoData.card.last_activity).valueOf()
              );
              row.lastUpdate = localization.formatRelativeTime(row.lastActivity);
            }
            row.driver = `${userTachoData.card.user_first_names} ${userTachoData.card.user_last_name}`;
            if (branches && userList) {
              const user = userList.find(u => u.id === userTachoData.card.user_id);
              if (user?.location) {
                const branch = branches.find(b => b.id === user.location.id);
                if (branch) {
                  row.branch = branch.name;
                }
              }
            }
          }

          if (userTachoData.fatigueStatus) {
            row.status = userTachoData.fatigueStatus.activity || 'Rest';
            row.alert = userTachoData.fatigueStatus.current_alert;
            row.location = userTachoData.fatigueStatus.location;
            //use fatigue's last event
            if (userTachoData.fatigueStatus.event_at) {
              row.lastUpdate = localization.formatRelativeTime(
                Math.max(
                  0,
                  moment().valueOf() - moment(userTachoData.fatigueStatus.event_at).valueOf()
                )
              );
            }

            if (userTachoData.fatigueStatus.vehicle_id) {
              for (let fleet of fleets) {
                let vehicle = null;
                if (fleet.vehicles) {
                  vehicle = fleet.vehicles.find(
                    v => v.id === userTachoData.fatigueStatus.vehicle_id
                  );
                }

                if (vehicle) {
                  row.vehicleName = vehicle.name;
                  row.fleet = fleet.name || 'No Fleet';
                  break;
                }
              }
            }
          }

          data.push(row);
        }
      });
    }

    setInitTableData(data);
  }, [isFetching, tachoData, fleets, subCompanies, branches, userList, localization]);

  const handleTabChanged = useCallback(
    key => {
      history.push('/fatigue/tacho/' + key);
    },
    [history]
  );

  if (!type) {
    type = 'All';
  }

  return (
    <Tabs
      className={styles.tab}
      defaultActiveKey={type}
      onChange={handleTabChanged}
      destroyInactiveTabPane
      items={[
        {
          label: 'All',
          key: 'All',
          children: <HoursOfService initTableData={initTableData} isFetching={isFetching} />
        },
        {
          label: 'Driving',
          key: 'Driving',
          children: (
            <HoursOfService
              initTableData={initTableData.filter(d => d.status?.toLowerCase() === 'driving')}
              isFetching={isFetching}
            />
          )
        },
        {
          label: 'Other Work',
          key: 'Work',
          children: (
            <HoursOfService
              initTableData={initTableData.filter(d => d.status?.toLowerCase() === 'working')}
              isFetching={isFetching}
            />
          )
        },
        {
          label: 'Rest Break',
          key: 'Break',
          children: (
            <HoursOfService
              initTableData={initTableData.filter(d => d.status?.toLowerCase() === 'resting')}
              isFetching={isFetching}
            />
          )
        },
        {
          label: 'POA',
          key: 'POA',
          children: (
            <HoursOfService
              initTableData={initTableData.filter(d => d.status?.toLowerCase() === 'poa')}
              isFetching={isFetching}
            />
          )
        }
      ]}
    />
  );
}

export function HoursOfService({ initTableData, isFetching }) {
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [fleetsFilter, setFleetsFilter] = useState(null);
  const [branchesFilter, setBranchesFilter] = useState(null);
  const [generalFilter, setGeneralFilter] = useState(null);
  const [initGeneralFilter, setInitGeneralFilter] = useState(null);
  const [sortColumn, setSortColumn] = useState(() => {
    return FatigueGridColumns.find(c => c.defaultSortColumn).dataKey;
  });

  const handleFleetsChanged = useCallback(fleets => {
    setFleetsFilter(fleets);
  }, []);

  const handleBranchChanged = useCallback(branches => {
    setBranchesFilter(branches);
  }, []);

  const handleGeneralFilterChange = useCallback(generalFilters => {
    setGeneralFilter(generalFilters);
  }, []);

  const handleSearchChange = useCallback(searchText => {
    setSearchText(searchText);
  }, []);

  const handleSortChanged = useCallback(column => {
    setSortColumn(column);
  }, []);

  const handleExpand = useCallback((idx, expand, record) => {
    setTableData(prevData => {
      return prevData.map((d, index) => {
        if (idx === index) {
          return {
            ...d,
            expand: expand
          };
        } else if (d.user?.id === record?.user?.id) {
          return {
            ...d,
            expand: expand
          };
        } else {
          return d;
        }
      });
    });
  }, []);

  const handleExpandAll = useCallback(expand => {
    setTableData(prevData => {
      return prevData.map(d => {
        return {
          ...d,
          expand: expand
        };
      });
    });
  }, []);

  useEffect(() => {
    //generate filters
    let generalFilters = {};
    let valueGroups = {};

    for (let idx = 0; idx < FatigueGridColumns.length; idx++) {
      const column = FatigueGridColumns[idx];
      if (column.generalFilter) {
        let node = {
          label: column.label,
          function: undefined,
          nodeKey: (idx + 1).toString(),
          id: idx + 1,
          children: [
            {
              ...selectAll,
              label: selectAll.name,
              nodeKey: idx + 1 + PATH_SPLIT + 0
            }
          ]
        };
        generalFilters[node.id] = node;
        valueGroups[node.id] = {};

        const sortedList = sortBy(initTableData, [o => o[column.dataKey]]);

        for (let i = 0, l = sortedList.length; i < l; i++) {
          const item = sortedList[i];
          const parentNode = generalFilters[idx + 1];
          const value = item[column.dataKey];
          if (!value) {
            continue;
          }
          if (!valueGroups[parentNode.id][value]) {
            const nodeId = parentNode.children.length;
            const childNode = {
              id: nodeId,
              label: item[column.dataKey],
              function: undefined,
              nodeKey: parentNode.nodeKey + PATH_SPLIT + nodeId,
              dataKey: column.dataKey
            };
            parentNode.children.push(childNode);
            valueGroups[parentNode.id][value] = true;
          }
        }
      }
    }
    //generate filters end
    setInitGeneralFilter(generalFilters);
  }, [initTableData]);

  const [FatigueTableTag] = useState(() => {
    return FatigueTable;
  });

  useEffect(() => {
    let data = [];
    for (let row of initTableData) {
      let matchSearch = true;
      if (
        searchText &&
        searchText.trim() &&
        (!row.driver || !(row.driver.toLowerCase().indexOf(searchText.toLowerCase()) > -1))
      ) {
        matchSearch = false;
      }

      if (
        matchSearch &&
        branchesFilter &&
        !branchesFilter.find(b => b.label === 'All Branches').checked
      ) {
        matchSearch = branchesFilter.some(f => f.checked && f.label === row.branch);
      }

      if (
        matchSearch &&
        fleetsFilter &&
        !fleetsFilter.find(f => f.label === 'All Fleets').checked
      ) {
        matchSearch = fleetsFilter.some(f => f.checked && f.label === row.fleet);
      }

      if (matchSearch && generalFilter) {
        for (let key in generalFilter) {
          if (
            generalFilter[key].children[0].checked ||
            !generalFilter[key].children.some(c => c.checked)
          ) {
            continue;
          }

          for (let idx = 1; idx < generalFilter[key].children.length; idx++) {
            const node = generalFilter[key].children[idx];
            if (node.checked) {
              if (row[node.dataKey] !== node.label) {
                matchSearch = false;
              } else {
                matchSearch = true;
                break;
              }
            }
          }

          if (!matchSearch) {
            break;
          }
        }
      }

      if (matchSearch) {
        row.onExpand = handleExpand;
        data.push(row);
      }
    }

    //sort
    if (sortColumn === 'restTime') {
      data = sortBy(data, [
        o => (o.fatigueStatus && o.fatigueStatus['mode']) || 0,
        o => (o.fatigueStatus && o.fatigueStatus.break_rest_taken) || 0,
        o => (o.fatigueStatus && o.fatigueStatus.next_rest_break_required_in) || 0
      ]);
    } else if (sortColumn === 'driveTime') {
      data = sortBy(data, [
        o => {
          if (!o.fatigueStatus) return 0;
          switch (o.fatigueStatus.mode) {
            case 0:
            case 3:
              return o.fatigueStatus.drive_continuous || 0;
            case 1:
            case 2:
              const workedTime = (o.fatigueStatus && o.fatigueStatus.drive_daily) || 0;
              return workedTime;
            default:
              return 0;
          }
        }
      ]);
    } else if (sortColumn === 'lastUpdate') {
      data = sortBy(data, [o => (o.user && -moment(o.user.last_activity).valueOf()) || 0]);
    } else if (sortColumn === 'remainedDriveTime') {
      data = sortBy(data, [
        o => {
          const remainingDrive =
            o.fatigueStatus?.max_daily_drive - (o.fatigueStatus?.drive_daily || 0);
          return remainingDrive;
        }
      ]);
    } else {
      data = sortBy(data, [
        o => (typeof o[sortColumn] === 'string' ? o[sortColumn].toLowerCase() : o[sortColumn])
      ]);
    }

    setTableData(oldDataStore => {
      return data.map(d => {
        const newData = { ...d };
        const oldData = oldDataStore.find(old => d.fatigueStatus?.id === old.fatigueStatus?.id);
        if (oldData != null) {
          newData.expand = oldData.expand;
        }
        return newData;
      });
    });
  }, [
    initTableData,
    searchText,
    branchesFilter,
    fleetsFilter,
    generalFilter,
    sortColumn,
    handleExpand
  ]);

  return (
    <div className={styles.fatigueManagement}>
      <FatigueToolbar
        onBranchChange={handleBranchChanged}
        onFleetChange={handleFleetsChanged}
        onSortChange={handleSortChanged}
        onGeneralFilterChange={handleGeneralFilterChange}
        onSearchChange={handleSearchChange}
        onExpand={handleExpandAll}
        filters={initGeneralFilter}
      />
      <FatigueTableTag data={tableData} isLoading={isFetching} sortColumn={sortColumn} />
    </div>
  );
}
