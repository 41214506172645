import React from 'react';
import { Space, Button } from 'antd';
import { MoreMenu } from 'components/ant';

/**
 * Buttons
 * @description Primary Action Button + MoreMenu; can be extended with a secondary action button
 * @param size Size of the primary button
 * @param padding Padding wrapper
 * @param primaryButton Object with name and onClick function - this can be extended to support size as well
 * @param moreButtons Array of objects with the buttons inside the MoreMenu
 * @param preventClose Boolean to prevent closing the Popover from the MoreMenu - used for showing button loaders
 */

export const Buttons = ({
  size = 'large',
  padding = 16,
  primaryButton = null,
  moreButtons = null,
  preventClose = false,
  id = ''
}) => {
  return (
    <Space size={padding}>
      {primaryButton && (
        <Button
          type={primaryButton?.type || 'primary'}
          size={size}
          onClick={primaryButton?.onClick}
          id={id}
          disabled={!!primaryButton?.disabled}
        >
          {primaryButton?.name}
        </Button>
      )}
      <MoreMenu size="xl" border items={moreButtons || []} useNewIcon preventClose={preventClose} />
    </Space>
  );
};
