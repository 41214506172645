import React, { useMemo } from 'react';
import { Table } from 'antd';
import { FatigueTableColumns } from './columns';
import moment from 'moment';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';
import styles from './fatigue.module.scss';
import { expandCellRenderer } from './CellRenders';

function formatDuration(duration) {
  let durationStr = '';
  if (duration.asHours() >= 1) {
    durationStr = parseInt(duration.asHours()) + (duration.asHours() > 1 ? ' hrs ' : ' hr ');
  }

  if (durationStr !== '' || duration.minutes() > 0) {
    durationStr += duration.minutes() + (duration.minutes() > 1 ? ' mins ' : ' min ');
  }

  if (duration.seconds() > 0) {
    durationStr += duration.seconds() + ' s ';
  }

  if (durationStr === '') {
    durationStr = 0;
  }
  return durationStr;
}

function timeRender(data, dataKey, localization) {
  return format(new Date(data[dataKey]), localization.formats.time.formats.dby_imp);
}

function durationRender(data, dataKey) {
  const duration = moment.duration(data[dataKey] || 0, 'seconds');
  return formatDuration(duration);
}

function timeDiffRender(data, dataKey) {
  const duration = moment.duration(
    moment(new Date(data[dataKey])).diff(moment(new Date(data.event_at)), 'second'),
    'seconds'
  );
  return formatDuration(duration);
}

function numberRender(data, dataKey) {
  return data[dataKey] || '-';
}

const nestedRowConfig = [
  [
    {
      title: 'Next break by',
      dataKey: 'next_rest_break_required_by',
      render: timeRender
    },
    {
      title: 'Next break minimum duration',
      dataKey: 'next_rest_break_duration',
      render: durationRender
    },
    {
      title: 'Next rest by',
      dataKey: 'next_daily_rest_due_at',
      render: timeRender
    }
  ],
  [
    {
      title: 'Drive time today',
      dataKey: 'drive_daily',
      render: durationRender
    },
    {
      title: 'Drive time this week',
      dataKey: 'drive_weekly',
      render: durationRender
    },
    {
      title: 'Drive time this fortnight',
      dataKey: 'drive_twoweekly',
      render: durationRender
    }
  ],
  [
    {
      title: 'Time until next break',
      dataKey: 'next_rest_break_required_in',
      render: durationRender
    },
    {
      title: 'Rest time this driving period',
      dataKey: 'break_rest_taken',
      render: durationRender
    },
    {
      title: 'Time until next daily rest',
      dataKey: 'next_daily_rest_due_at',
      render: timeDiffRender
    }
  ],
  [
    {
      title: 'Time until next weekly rest',
      dataKey: 'next_weekly_rest_due_at',
      render: timeDiffRender
    },
    {
      title: 'Available extended drive',
      dataKey: 'extended_drives_available',
      render: numberRender
    },
    {
      title: 'Available reduced rest',
      dataKey: 'reduced_rests_available',
      render: numberRender
    }
  ]
];

export function fatigueRowRender({ record, localization }) {
  if (record.expand) {
    return (
      <div
        className={styles.nestCell}
        style={{
          display: 'flex',
          flex: '1 1 0',
          flexDirection: 'row',
          height: '100%',
          width: '100%',
          padding: '16px 32px',
          background: '#f7f8f9'
        }}
      >
        {nestedRowConfig.map((g, idx) => (
          <div className={styles.nestCellsGroup} key={idx}>
            {g.map((c, iidx) => (
              <div key={iidx}>
                <span>{c.title}</span>
                <span>{c.render(record.fatigueStatus, c.dataKey, localization)}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  } else {
    return <div />;
  }
}

export function FatigueTable({ isLoading, data }) {
  const localization = useLocalization();
  const expandableConfig = useMemo(() => {
    return {
      defaultExpandAllRows: false,
      expandedRowRender: record => fatigueRowRender({ record, localization }),
      expandIcon: ({ onExpand, record }) =>
        expandCellRenderer({ rowData: record, onExpand, style: { width: '100%' } }),
      expandedRowKeys: data?.reduce((ar, c) => {
        if (c.expand && c.user?.id) {
          ar.push(c.user.id);
        }
        return ar;
      }, [])
    };
  }, [data]);

  return (
    <Table
      loading={isLoading}
      className={styles.narrowPadding}
      dataSource={data}
      columns={FatigueTableColumns}
      pagination={false}
      sticky={true}
      rowKey={record => record.user?.id}
      expandable={expandableConfig}
    />
  );
}
