import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePageTitle, useBackButton } from 'features/page/pageSlice';
import { useUserInfo } from 'features/user/userSlice';

import CanRoute from 'components/route/CanRoute';
import Page from 'components/pages/Page';
import { NoAccessBody } from 'components/pages/NoAccess';

import { supportToolPagesFeatures } from 'Pages';
import { useCanOneOfRoles, GlobalRoles } from 'features/permissions';

const Body = () => {
  const { t } = useTranslation();
  const user = useUserInfo();

  const hasSiteAdminOrResellerRole = useCanOneOfRoles([
    GlobalRoles.Reseller,
    GlobalRoles.SiteAdmin
  ]);

  const subPages = supportToolPagesFeatures(t, user, hasSiteAdminOrResellerRole);

  const subPageComponents = subPages.map(page => {
    const Component = page.component;
    return (
      <CanRoute
        key={page.path}
        path={page.path}
        exact={page.exact}
        everyService={page.everyService}
        everyEntity={page.everyEntity}
        oneOfServices={page.oneOfServices}
        oneOfEntities={page.oneOfEntities}
        everyRole={page.everyRole}
        oneOfRoles={page.oneOfRoles}
        companyConfigs={page.companyConfigs}
        featureFlag={page.featureFlag}
        featureHide={page.featureHide}
        otherConditions={page.otherConditions}
        onFail={<NoAccessBody />}
        render={() => {
          return <Component key={page.path} />;
        }}
      />
    );
  });

  return subPageComponents;
};

export const SupportTools = () => {
  const backButton = useBackButton();
  const title = usePageTitle();

  return (
    <Page
      page={{
        title,
        useBackButton: backButton,
        component: Body
      }}
    />
  );
};
