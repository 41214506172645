import { useRef, useEffect } from 'react';

export function useTimer(period, callback, isReady) {
  const timerRef = useRef(null);

  const clearTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const createTimer = () => {
    timerRef.current = setTimeout(async () => {
      if (callback) {
        await callback();
      }
      if (timerRef.current) {
        createTimer();
      }
    }, period);
  };

  useEffect(() => {
    clearTimer();
    if (isReady === true || isReady === undefined) {
      createTimer();
    }

    return () => {
      clearTimer();
    };
  }, [period, callback, isReady]);
}
