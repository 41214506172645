import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import {
  fetchBuckets,
  fetchBucketDefaults,
  fetchSummary,
  fetchConfig,
  fetchScoreEvents,
  updateConfig,
  includeExcludeEvents,
  fetchEnabledEventTypes,
  fetchEnabledEventTypesBranch,
  fetchEnabledEventTypesFleet
} from '../thunks';

const initialState = {
  summary: {},
  isFetchingSummary: false,
  errorSummary: false,
  successSummary: false,
  buckets: [],
  isFetchingBucket: false,
  errorBucket: null,
  successBucket: null,
  bucketDefaults: [],
  isFetchingBucketDefaults: false,
  errorBucketDefaults: null,
  successBucketDefaults: null,
  isUpdatingBucket: false,
  config: { colorBands: [] },
  isFetchingConfig: false,
  errorConfig: null,
  successConfig: null,
  isUpdatingConfig: false,
  scoreEvents: [],
  isFetchingScoreEvents: false,
  errorScoreEvents: false,
  successScoreEvents: false,
  isIncludeExcludeEventsSaving: false,
  enabledEventTypes: [],
  isFetchingEnabledEventTypes: false,
  errorEnabledEventTypes: null,
  successEnabledEventTypes: null,
  lastRequestEnabledEventTypes: null,
  enabledEventTypesBranch: [],
  isFetchingEnabledEventTypesBranch: false,
  errorEnabledEventTypesBranch: null,
  successEnabledEventTypesBranch: null,
  lastRequestEnabledEventTypesBranch: null,
  enabledEventTypesFleet: [],
  isFetchingEnabledEventTypesFleet: false,
  errorEnabledEventTypesFleet: null,
  successEnabledEventTypesFleet: null,
  lastRequestEnabledEventTypesFleet: null
};

export const scorecardSlice = createSlice({
  name: 'scorecard',
  initialState,
  reducers: {
    clearScore(state) {
      state.summary = {};
      state.successSummary = false;
      state.errorSummary = false;
    },
    clearBucket(state) {
      state.buckets = [];
      state.successBucket = false;
      state.errorBucket = false;
      state.isFetchingBucket = false;
    },
    clearScoreEvents(state) {
      state.scoreEvents = [];
      state.successScoreEvents = false;
      state.errorScoreEvents = false;
      state.isFetchingScoreEvents = false;
    }
  },
  extraReducers: {
    [fetchConfig.pending]: state => {
      state.isFetchingConfig = true;
      state.successConfig = false;
      state.errorConfig = false;
    },
    [fetchConfig.fulfilled]: (state, action) => {
      state.config = unwrapResult(action);
      state.isFetchingConfig = false;
      state.successConfig = true;
      state.errorConfig = false;
    },
    [fetchConfig.rejected]: state => {
      state.isFetchingConfig = false;
      state.successConfig = false;
      state.errorConfig = true;
    },
    [updateConfig.pending]: state => {
      state.isUpdatingConfig = true;
    },
    [updateConfig.fulfilled]: (state, action) => {
      state.isUpdatingConfig = false;
      state.config = unwrapResult(action);
    },
    [updateConfig.rejected]: state => {
      state.isUpdatingConfig = false;
    },
    [fetchSummary.pending]: state => {
      state.isFetchingSummary = true;
      state.successSummary = false;
      state.errorSummary = false;
    },
    [fetchSummary.fulfilled]: (state, action) => {
      state.summary = unwrapResult(action);
      state.isFetchingSummary = false;
      state.successSummary = true;
      state.errorSummary = false;
    },
    [fetchSummary.rejected]: state => {
      state.summary = {};
      state.isFetchingSummary = false;
      state.successSummary = false;
      state.errorSummary = true;
    },
    [fetchBuckets.pending]: state => {
      state.isFetchingBucket = true;
      state.successBucket = false;
      state.errorBucket = false;
    },
    [fetchBuckets.fulfilled]: (state, action) => {
      state.isFetchingBucket = false;
      state.successBucket = true;
      state.buckets = unwrapResult(action);
    },
    [fetchBuckets.rejected]: state => {
      state.isFetchingBucket = false;
      state.errorBucket = true;
    },
    [fetchBucketDefaults.pending]: state => {
      state.isFetchingBucketDefaults = true;
      state.successBucketDefaults = false;
      state.errorBucketDefaults = false;
    },
    [fetchBucketDefaults.fulfilled]: (state, action) => {
      state.isFetchingBucketDefaults = false;
      state.successBucketDefaults = true;
      state.bucketDefaults = unwrapResult(action);
    },
    [fetchBucketDefaults.rejected]: state => {
      state.isFetchingBucketDefaults = false;
      state.errorBucketDefaults = true;
    },
    [fetchScoreEvents.pending]: state => {
      state.isFetchingScoreEvents = true;
      state.successScoreEvents = false;
      state.errorScoreEvents = false;
    },
    [fetchScoreEvents.fulfilled]: (state, action) => {
      state.isFetchingScoreEvents = false;
      state.successScoreEvents = true;
      state.errorScoreEvents = false;
      state.scoreEvents = unwrapResult(action);
    },
    [fetchScoreEvents.rejected]: state => {
      state.isFetchingScoreEvents = false;
      state.errorScoreEvents = true;
      state.successScoreEvents = false;
    },
    [includeExcludeEvents.pending]: state => {
      state.isIncludeExcludeEventsSaving = true;
    },
    [includeExcludeEvents.fulfilled]: (state, action) => {
      state.isIncludeExcludeEventsSaving = false;
    },
    [includeExcludeEvents.rejected]: state => {
      state.isIncludeExcludeEventsSaving = false;
    },
    [fetchEnabledEventTypes.pending]: state => {
      state.isFetchingEnabledEventTypes = true;
      state.successEnabledEventTypes = false;
      state.errorEnabledEventTypes = false;
    },
    [fetchEnabledEventTypes.fulfilled]: (state, action) => {
      state.isFetchingEnabledEventTypes = false;
      state.successEnabledEventTypes = true;
      state.lastRequestEnabledEventTypes = action.meta.arg;
      state.enabledEventTypes = unwrapResult(action);
    },
    [fetchEnabledEventTypes.rejected]: state => {
      state.isFetchingEnabledEventTypes = false;
      state.errorEnabledEventTypes = true;
    },
    [fetchEnabledEventTypesBranch.pending]: state => {
      state.isFetchingEnabledEventTypesBranch = true;
      state.successEnabledEventTypesBranch = false;
      state.errorEnabledEventTypesBranch = false;
    },
    [fetchEnabledEventTypesBranch.fulfilled]: (state, action) => {
      state.isFetchingEnabledEventTypesBranch = false;
      state.successEnabledEventTypesBranch = true;
      state.lastRequestEnabledEventTypesBranch = action.meta.arg;
      state.enabledEventTypesBranch = unwrapResult(action);
    },
    [fetchEnabledEventTypesBranch.rejected]: state => {
      state.isFetchingEnabledEventTypesBranch = false;
      state.errorEnabledEventTypesBranch = true;
    },
    [fetchEnabledEventTypesFleet.pending]: state => {
      state.isFetchingEnabledEventTypesFleet = true;
      state.successEnabledEventTypesFleet = false;
      state.errorEnabledEventTypesFleet = false;
    },
    [fetchEnabledEventTypesFleet.fulfilled]: (state, action) => {
      state.isFetchingEnabledEventTypesFleet = false;
      state.successEnabledEventTypesFleet = true;
      state.lastRequestEnabledEventTypesFleet = action.meta.arg;
      state.enabledEventTypesFleet = unwrapResult(action);
    },
    [fetchEnabledEventTypesFleet.rejected]: state => {
      state.isFetchingEnabledEventTypesFleet = false;
      state.errorEnabledEventTypesFleet = true;
    }
  }
});

export const { clearBucket, clearScore, clearScoreEvents } = scorecardSlice.actions;
