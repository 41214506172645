import React, { useEffect, useState } from 'react';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';
import { Comparators } from 'utils/sorting';
import { useDispatch } from 'react-redux';
import { exportFile } from 'components/excelFile';
import moment from 'moment';
import i18n from 'i18next';
import { get } from 'lodash';
import styles from 'components/tn/grid/grid.module.scss';
import { Table, Button } from 'antd';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import { BUTTON_IDS } from 'utils/globalConstants';
import detailStyles from './Details.module.scss';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useLocation } from 'react-router-dom';
import { DatePicker } from 'components/ant/DateTime';
import {
  fetchSummaryDetailsByVehicle,
  useSummaryDetailsByVehicle,
  fetchSummaryDetailsByDriver,
  useSummaryDetailsByDriver
} from 'features/support/supportELDSlice';
import { ColumnSelectorModal } from '../utils/ColumnSelectorModal';

export const DetailColumns = localization => {
  return [
    {
      title: i18n.t('SupportToolsELD.DetailColumns.MessageType'),
      dataIndex: 'messageType',
      key: 'messageType',
      width: '150px',
      sorter: Comparators.String('messageType'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.messageType;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Origin'),
      dataIndex: 'origin',
      key: 'origin',
      width: '200px',
      sorter: Comparators.String('origin'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.origin;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Vehicle'),
      dataIndex: 'vehicleName',
      key: 'vehicleName',
      width: '200px',
      sorter: Comparators.String('vehicleName'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.vehicleName;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Driver'),
      dataIndex: 'userName',
      key: 'userName',
      width: '200px',
      sorter: Comparators.String('userName'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.userName;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.CoDriver'),
      dataIndex: 'user2Name',
      key: 'user2Name',
      width: '200px',
      sorter: Comparators.String('user2Name'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.user2Name;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Time'),
      dataIndex: 'eventTime',
      key: 'eventTime',
      width: '200px',
      sorter: Comparators.Date('eventTime'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.eventTime
          ? moment(rowData.eventTime).format(localization.formats.time.formats.dby_imsp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Recorded'),
      dataIndex: 'recorded',
      key: 'recorded',
      width: '200px',
      sorter: Comparators.Date('recorded'),
      isChecked: true,
      getValue: rowData => {
        return rowData.recorded
          ? moment(rowData.recorded).format(localization.formats.time.formats.dby_imsp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Delay'),
      dataIndex: 'delayMilliSecond',
      key: 'delayMilliSecond',
      width: '200px',
      sorter: Comparators.Number('delayMilliSecondNum'),
      isChecked: true,
      isSearchable: true,
      //render:,
      getValue: rowData => {
        return rowData.delayMilliSecond
          ? moment.utc(rowData.delayMilliSecond).format('HH:mm:ss')
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.DelayHermes'),
      dataIndex: 'delayMilliSecondHermes',
      key: 'delayMilliSecondHermes',
      width: '200px',
      sorter: Comparators.Number('delayMilliSecondHermesNum'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.delayMilliSecondHermes
          ? moment.utc(rowData.delayMilliSecondHermes).format('HH:mm:ss')
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Status'),
      dataIndex: 'status',
      key: 'status',
      width: '200px',
      sorter: Comparators.String('status'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.status;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Comments'),
      dataIndex: 'comments',
      key: 'comments',
      width: '400px',
      sorter: Comparators.String('comments'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.comments;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Odometer', {
        unit: localization.formats.speed.unit.toUpperCase()
      }),
      dataIndex: 'odometer',
      key: 'odometer',
      width: '200px',
      sorter: Comparators.Number('odometer'),
      isChecked: true,
      isSearchable: true,
      render: text => {
        return !text ? '' : parseFloat(text).toFixed(1);
      },
      getValue: rowData => {
        return rowData.odometer ? localization.convertDistance(rowData.odometer) : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.EngineHours'),
      dataIndex: 'engineHours',
      key: 'engineHours',
      width: '200px',
      sorter: Comparators.Number('engineHours'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.engineHours;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Location'),
      dataIndex: 'location',
      key: 'location',
      width: '400px',
      sorter: Comparators.String('location'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.location;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.DeclaredLocation'),
      dataIndex: 'declaredLocation',
      key: 'declaredLocation',
      width: '400px',
      sorter: Comparators.String('declaredLocation'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.declaredLocation;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Trailer'),
      dataIndex: 'trailer',
      key: 'trailer',
      width: '400px',
      sorter: Comparators.String('trailer'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.trailer;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.DeclaredOdometer', {
        unit: localization.formats.speed.unit.toUpperCase()
      }),
      dataIndex: 'declaredOdometer',
      key: 'declaredOdometer',
      width: '225px',
      sorter: Comparators.Number('declaredOdometer'),
      isChecked: true,
      isSearchable: true,
      render: text => {
        return !text ? '' : parseFloat(text).toFixed(1);
      },
      getValue: rowData => {
        return rowData.declaredOdometer
          ? localization.convertDistance(rowData.declaredOdometer)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.GPS'),
      dataIndex: 'gps',
      key: 'gps',
      width: '200px',
      sorter: Comparators.String('gps'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return (
          parseFloat(rowData.gps?.latitude).toFixed(2) +
          ', ' +
          parseFloat(rowData.gps?.longitude).toFixed(2)
        );
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.SequenceNumber'),
      dataIndex: 'sequenceNumber',
      key: 'sequenceNumber',
      width: '200px',
      sorter: Comparators.Number('sequenceNumber'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.sequenceNumber;
      }
    },
    {
      title: i18n.t('SupportToolsELD.DetailColumns.Updated'),
      dataIndex: 'updated',
      key: 'updated',
      width: '200px',
      sorter: Comparators.Date('updated'),
      isChecked: true,
      getValue: rowData => {
        return rowData.updated
          ? moment(rowData.updated).format(localization.formats.time.formats.dby_imsp)
          : '';
      }
    }
  ];
};

export const ColumnSelector = () => {
  return [
    {
      title: i18n.t('SupportToolsELD.SelectColumns'),
      dataIndex: 'colTitle',
      key: 'colKey'
    }
  ];
};

export const prepareFileForExcelExport = data => {
  const { tableData, localization } = data;
  const rows = tableData
    ? tableData.map(entry => {
        const row = {
          [i18n.t('SupportToolsELD.DetailColumns.MessageType')]: entry.messageType || '',
          [i18n.t('SupportToolsELD.DetailColumns.Origin')]: entry.origin || '',
          [i18n.t('SupportToolsELD.DetailColumns.Vehicle')]: entry.vehicleName || '',
          [i18n.t('SupportToolsELD.DetailColumns.Driver')]: entry.userName || '',
          [i18n.t('SupportToolsELD.DetailColumns.CoDriver')]: entry.user2Name || '',
          [i18n.t('SupportToolsELD.DetailColumns.Time')]: entry.eventTime
            ? moment(entry.eventTime).format(localization.formats.time.formats.dby_imsp)
            : '',
          [i18n.t('SupportToolsELD.DetailColumns.Recorded')]: entry.recorded
            ? moment(entry.recorded).format(localization.formats.time.formats.dby_imsp)
            : '',
          [i18n.t('SupportToolsELD.DetailColumns.Delay')]: entry.delayMilliSecond || '',
          [i18n.t('SupportToolsELD.DetailColumns.DelayHermes')]: entry.delayMilliSecondHermes || '',
          [i18n.t('SupportToolsELD.DetailColumns.Status')]: entry.status || '',
          [i18n.t('SupportToolsELD.DetailColumns.Comments')]: entry.comments || '',
          [i18n.t('SupportToolsELD.DetailColumns.Odometer', {
            unit: localization.formats.speed.unit.toUpperCase()
          })]: entry.odometer || '',
          [i18n.t('SupportToolsELD.DetailColumns.EngineHours')]: entry.engineHours || '',
          [i18n.t('SupportToolsELD.DetailColumns.Location')]: entry.location || '',
          [i18n.t('SupportToolsELD.DetailColumns.DeclaredLocation')]: entry.declaredLocation || '',
          [i18n.t('SupportToolsELD.DetailColumns.Trailer')]: entry.trailer || '',
          [i18n.t('SupportToolsELD.DetailColumns.DeclaredOdometer', {
            unit: localization.formats.speed.unit.toUpperCase()
          })]: entry.declaredOdometer || '',
          [i18n.t('SupportToolsELD.DetailColumns.GPS')]: entry.gps || '',
          [i18n.t('SupportToolsELD.DetailColumns.SequenceNumber')]: entry.sequenceNumber || '',

          [i18n.t('SupportToolsELD.DetailColumns.Updated')]: entry.updated
            ? moment(entry.updated).format(localization.formats.time.formats.dby_imsp)
            : ''
        };

        return row;
      })
    : [];

  return rows;
};

export const getValue = (row, column) => {
  if (column.getValue) {
    return column.getValue(row);
  } else {
    return getRowValue(row, column.dataIndex);
  }
};

export const getRowValue = (row, dataIndex) => {
  if (!dataIndex) {
    return null;
  }

  if (typeof dataIndex === 'string') {
    return row[dataIndex]?.toString();
  } else if (Array.isArray(dataIndex) && dataIndex.length > 0) {
    return get(row, dataIndex.join('.'))?.toString();
  } else {
    return row[dataIndex.toString()]?.toString();
  }
};

export function Details(props) {
  const { t } = useTranslation();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(null);
  const { state } = useLocation();
  const entity = state?.data?.entity;
  const entityId = state?.data?.entityId;
  const entityName = state?.data?.entityName;
  const summaryDetailsByVehicle = useSummaryDetailsByVehicle();
  const summaryDetailsByDriver = useSummaryDetailsByDriver();
  const [allData, setAllData] = useState([]);
  const [tableData, setTableData] = useState(allData);
  const [showColumnsFilterModal, setShowColumnsFilterModal] = useState(false);
  const [date, setDate] = useState(moment());
  function disabledDate(current) {
    // Can not select days before today and today
    return (
      current.valueOf() <
      moment()
        .subtract(6, 'month')
        .startOf('day')
    );
  }

  useEffect(() => {
    const begin = moment(date).startOf('day');
    const end = moment(date).endOf('day');
    if (entity === 'vehicle') dispatch(fetchSummaryDetailsByVehicle(entityId, begin, end));
    if (entity === 'driver') dispatch(fetchSummaryDetailsByDriver(entityId, begin, end));
  }, [dispatch, entity, entityId, date]);

  useEffect(() => {
    if (entity === 'vehicle') {
      setAllData(summaryDetailsByVehicle);
    }
  }, [summaryDetailsByVehicle]);

  useEffect(() => {
    if (entity === 'driver') {
      setAllData(summaryDetailsByDriver);
    }
  }, [summaryDetailsByDriver]);

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(
      setPageTitle(
        t('SupportToolsELD.DetailsTitle', {
          entityName: entityName,
          entity: t(entity),
          time: moment().format(localization.formats.time.formats.dby_imp)
        })
      )
    );
  }, [dispatch, entityName, entity, date]);

  const handleExportExcel = () => {
    const columnWidth = Array.apply(null, { length: 40 }).map(() => ({ wch: 25 }));

    const data = prepareFileForExcelExport({
      tableData,
      localization,
      dateFormat: localization.formats.time.formats.dby_imsp
    });
    dispatch(
      exportFile(data, {
        width: columnWidth,
        title: t('SupportToolsELD.Details'),
        dateFormat: localization.formats.time.formats.dby_imsp
      })
    );
  };

  const handleSearchChange = text => {
    setSearchText(text);
  };

  const PrepareDataForTable = ({ allData, searchText }) => {
    var allDataTemp = allData;

    const searchableColumns = DetailColumns(localization)?.filter(
      col => col.isSearchable && col.isChecked
    );
    let filtered = allDataTemp?.filter(row => {
      return searchText?.trim()
        ? searchableColumns.some(col =>
            getValue(row, col)
              ?.toString()
              ?.toLowerCase()
              .includes(searchText?.trim()?.toLowerCase())
          )
        : true;
    });

    const returnData = filtered?.map((entry, index) => {
      return {
        key: index,
        messageType: entry.messageType,
        origin: entry.origin,
        vehicleName: entry.vehicleName,
        userName: entry.userName,
        user2Name: entry.user2Name,
        eventTime: entry.eventTime
          ? moment(entry.eventTime).format(localization.formats.time.formats.dby_imsp)
          : '',
        recorded: entry.recorded
          ? moment(entry.recorded).format(localization.formats.time.formats.dby_imsp)
          : '',
        updated: entry.updated
          ? moment(entry.updated).format(localization.formats.time.formats.dby_imsp)
          : '',
        delayMilliSecondNum: entry.delayMilliSecond,
        delayMilliSecond: entry.delayMilliSecond
          ? moment.utc(entry.delayMilliSecond).format('HH:mm:ss')
          : '',
        delayMilliSecondHermesNum: entry.delayMilliSecondHermes,
        delayMilliSecondHermes: entry.delayMilliSecondHermes
          ? moment.utc(entry.delayMilliSecondHermes).format('HH:mm:ss')
          : '',
        comments: entry.comments,
        location: entry.location,
        declaredLocation: entry.declaredLocation,
        trailer: entry.trailer,
        odometer: entry.odometer ? localization.convertDistance(entry.odometer) : '',
        declaredOdometer: entry.declaredOdometer
          ? localization.convertDistance(entry.declaredOdometer)
          : '',
        engineHours: entry.engineHours ? parseFloat(entry.engineHours).toFixed(1) : '',
        gps:
          entry.gps?.latitude && entry.gps?.longitude
            ? parseFloat(entry.gps?.latitude).toFixed(2) +
              ', ' +
              parseFloat(entry.gps?.longitude).toFixed(2)
            : '',
        status: entry.status,
        sequenceNumber: entry.sequenceNumber
      };
    });
    return returnData;
  };

  const PrepareColumnsForSelector = () => {
    const allColumns = DetailColumns(localization);

    const columns = allColumns?.map((col, index) => ({
      key: index,
      colTitle: col.title,
      colKey: col.key,
      isChecked: col.isChecked ? col.isChecked : false
    }));

    return columns;
  };

  const allColumns = PrepareColumnsForSelector();
  const [filterColumns, setFilterColumns] = useState(
    allColumns.filter(x =>
      DetailColumns(localization)
        .filter(c => c.isChecked)
        .find(z => z.key === x.colKey)
    )
  );
  const [selectedColumns, setSelectedColumns] = useState({
    selectedRowKeys: filterColumns?.map(c => c.key),
    selectedRows: filterColumns
  });

  const handleOkClick = rows => {
    setFilterColumns(rows.selectedRows);
    setSelectedColumns(rows);
    setShowColumnsFilterModal(false);
  };

  const handleCancelClick = () => {
    setShowColumnsFilterModal(false);
  };

  useEffect(() => {
    const data = PrepareDataForTable({
      allData,
      searchText
    });
    setTableData(data);
  }, [allData, searchText]);

  const handleDateChange = selectedDate => {
    setDate(selectedDate);
  };

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              type="primary"
              size="large"
              id={BUTTON_IDS.supportSummaryExcelExport}
              onClick={handleExportExcel}
            >
              {t('Common.ExporttoExcel')}
            </Button>
          </div>
        </HeaderPageWrapper>

        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={handleSearchChange} />

            <DatePicker
              value={date}
              disabled={false}
              size={'large'}
              format={localization.formats.time.formats.dby}
              onChange={handleDateChange}
              disabledDate={disabledDate}
            />
            <Button
              className={detailStyles.detailColumnsButton}
              id={BUTTON_IDS.supportSummaryColumns}
              onClick={() => {
                setShowColumnsFilterModal(true);
              }}
            >
              <FontAwesomeIcon rotation={90} icon={faAlignJustify} />
            </Button>
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {t('SupportToolsELD.Records', { number: tableData?.length })}
          </label>
        </div>
        <div>
          <Table
            className={`${styles.grid} ${styles.gridShowScrollbars}`}
            columns={DetailColumns(localization).filter(c =>
              filterColumns.find(x => x.colKey === c.key)
            )}
            dataSource={tableData}
            pagination={false}
            scroll={{ y: 'calc(100vh - 250px)', x: 'max-content' }}
            showSorterTooltip={false}
            rowSelection={{ type: 'checkbox' }}
          />
        </div>
      </ContainerPageWrapper>

      {showColumnsFilterModal && (
        <ColumnSelectorModal
          title={t('SupportToolsELD.SelectColumns')}
          isOpen={showColumnsFilterModal}
          columns={ColumnSelector()}
          data={PrepareColumnsForSelector()}
          selectedColumns={selectedColumns}
          handleOk={handleOkClick}
          handleCancel={handleCancelClick}
        />
      )}
    </>
  );
}
