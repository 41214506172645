import React from 'react';
import { Spin } from 'antd';

const InfoRow = ({
  value,
  label,
  labelWidth,
  styles,
  sxValue,
  labelSxValue,
  hideBorder,
  borderColor,
  justifyContent,
  py,
  delimiter,
  direction = 'horizontal',
  isLoading = false
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: justifyContent ? justifyContent : 'flex-start',
        maxWidth: '100%',
        borderBottomStyle: 'solid',
        borderBottomWidth: hideBorder ? '0px' : '1px',
        borderBottomColor: borderColor ? 'green' : '#EEE',
        paddingTop: py ? py : '10px',
        paddingBottom: py ? py : '10px',
        lineHeight: '24px',
        wordWrap: 'break-word',
        flexDirection: direction === 'horizontal' ? 'row' : 'column'
      }}
    >
      <div
        style={labelSxValue ? labelSxValue : { width: labelWidth ? labelWidth : '25%' }}
        className={styles?.formLabel}
      >
        {label}
      </div>
      {isLoading ? (
        <Spin />
      ) : (
        <div style={sxValue} className={styles?.formValue}>
          {Array.isArray(value) && value.length !== 0 && delimiter
            ? value.reduce((prev, curr) => [prev, delimiter, curr])
            : value}
        </div>
      )}
    </div>
  );
};

export default InfoRow;
