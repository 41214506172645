import { LogTransferStatus } from 'features/eld/logExportSlice';
import React from 'react';
import styles from './LogExport.module.scss';
import { Tooltip, Button } from 'antd';
import i18next from 'i18nextConfig';
import { BUTTON_IDS } from 'utils/globalConstants';

export const LogTypeDescription = {
  get Driver() {
    return i18next.t('Common.Driver');
  },
  get Vehicle() {
    return i18next.t('Common.Vehicle');
  }
};

export const LogTransferELDService = {
  get ELDUS() {
    return i18next.t('ELD.ELDUS');
  },
  get ELDCAN() {
    return i18next.t('ELD.ELDCAN');
  }
};

function getLogTransferStatusClassName(status) {
  const logStatus = LogTransferStatus[status];
  switch (logStatus) {
    case 'In Progress':
      return styles.status_inprogress;
    case 'Error':
      return styles.status_error;
    case 'Succeeded':
      return styles.status_succeeded;
    case 'Undefined':
    default:
      return styles.status_undefined;
  }
}

export function ExpandButton({ expanded, onExpand, record }) {
  return (
    <Button
      id={BUTTON_IDS.logExportExpand}
      className={styles.expandBtn}
      type="text"
      onClick={e => onExpand(record, e)}
    >
      <span>
        <i className={expanded ? 'tn-i-chevron-up' : 'tn-i-chevron-down'} />
      </span>
    </Button>
  );
}

export function expandableCellRender(props) {
  return {
    props: {
      colSpan: 2
    },
    children: (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <ExpandButton />
      </div>
    )
  };
}

export function expandableCellHeader() {
  return <span>{i18next.t('ELD.Expand')}</span>;
}

export function stateCellRenderer(value, rowData, index) {
  const className = getLogTransferStatusClassName(rowData.status);
  return (
    <Tooltip title={rowData.transferStatus}>
      <div className={className}>
        <span></span>
      </div>
    </Tooltip>
  );
}

export function normalCellRenderer(value, rowData, index) {
  return (
    <div>
      <span>{value}</span>
    </div>
  );
}
