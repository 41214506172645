import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Button, Tooltip } from 'antd';
import moment from 'moment';

// components
import { LoadingTable } from 'components/grid/LoadingTable';
import InfoRow from 'components/form/info-row/InfoRow';
import { FormModal } from '../FormModal/FormModal';
import FakeCheckbox from 'components/form/fake-checkbox/FakeCheckbox';
import InfoTable from 'components/form/info-table/InfoTable';
import ResizeObserver from 'rc-resize-observer';
import { ExportToExcelAudit } from './ExportToExcelAudit';
import AuditTrailToolbar from './AuditTrailToolbar';
import InformationToolbar from './InformationToolbar';
import {
  deviceLinkCellRenderer,
  auditVehicleLink,
  auditDeviceLink,
  auditUserLink,
  stringCellRenderer_ANT,
  dateCellRenderer_ANT,
  vehicleCellRenderer_ANT,
  deviceCellRenderer_ANT
} from '../CellRenderers';
import { Tabs, Tab } from 'react-bootstrap';
import { Can, entities, useCanOneOfEntities } from 'features/permissions';

// slices
import {
  useSpecificDocument,
  useSpecificDocumentTrailSummaryData,
  downloadDocument,
  useSpecificDocumentAuditTrail,
  changeDocumentStep,
  uploadFileSuccess,
  addInitialValuesForEdit,
  clearEditFiles,
  useIsFetchingAudit,
  fetchDocumentAuditTrail
} from '../../../features/easydocs/documentsSlice';
import { useFolders } from '../../../features/easydocs/foldersSlice';
import { useFleets, useVehicles, useDevices } from 'features/fleets/fleetsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';

// constants
import { eventCodes } from '../constants';

// helpers
import { sortBy } from 'lodash';
import { format } from 'utils/dates';
import { Comparators } from 'utils/sorting';
import { formatBytes, usePrevious } from 'utils/methods';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { prepareDataForMultiselect } from 'utils/filters';
import { getValue } from 'components/tn/grid/Grid';

// styles
import styles from './DocumentView.module.scss';
import gridStyles from 'components/tn/grid/grid.module.scss';
import './DocumentView.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useSubCompanies } from 'features/company/companySlice';

const FilterIds = {
  AllEvents: 0,
  AllVehicles: 0,
  AllDevices: 0,
  AllUsers: 0,
  AllCompanies: 0,
  AllFleets: 0,
  AllReceived: 0,
  NoVehicle: -1,
  NoDevice: -1,
  NoUser: -1,
  NoCompany: -1,
  NoFleet: -1,
  Received: 1,
  NoReceived: -1
};

const DocumentView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // Get id and userkey for individual GET call
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );
  const history = useHistory();
  const localization = useLocalization();
  const document = useSpecificDocument(id);
  const { recipientDevices } = document ? document : [];

  var [dateRanges, setDateRanges] = useState([moment().subtract(30, 'days'), moment()]);

  const auditTrail = useSpecificDocumentAuditTrail(id, dateRanges);
  const isAuditTrailLoading = useIsFetchingAudit();
  const documentTrailSummary = useSpecificDocumentTrailSummaryData(id);
  const activeFolderPrev = useSelector(state => state.folders.activeFolder);

  const [auditGridOffsetTop, setAuditGridOffsetTop] = useState(380);
  const auditTableRef = useRef();

  const [informationGridOffsetTop, setInformationGridOffsetTop] = useState(380);
  const informationTableRef = useRef();

  const [uploadUser, setUploadUser] = useState();

  const [filteredAuditTrail, setFilteredAuditTrail] = useState([]);
  const [auditSearchText, setAuditSearchText] = useState(null);
  const [auditEventsFilter, setAuditEventsFilter] = useState([]);
  const [auditVehiclesFilter, setAuditVehiclesFilter] = useState([]);
  const [auditDevicesFilter, setAuditDevicesFilter] = useState([]);
  const [auditUsersFilter, setAuditUsersFilter] = useState([]);

  const [filteredInformation, setFilteredInformation] = useState([]);
  const [informationSearchText, setInformationSearchText] = useState(null);
  const [informationVehiclesFilter, setInformationVehiclesFilter] = useState([]);
  const [informationDevicesFilter, setInformationDevicesFilter] = useState([]);
  const [informationCompanyFilter, setInformationCompanyFilter] = useState([]);
  const [informationFleetFilter, setInformationFleetFilter] = useState([]);
  const [informationReceivedFilter, setInformationReceivedFilter] = useState([]);

  const folders = useFolders();
  const currentFolderIds = document && document.folders?.map(folder => folder.id);
  const currentFolders = folders.filter(
    folder => document && document.folders && currentFolderIds.includes(folder.id)
  );
  const currentFoldersString = currentFolders.map(folder => folder.name).join(', ');
  const fleets = useFleets();
  const subCompanies = useSubCompanies();
  const vehicles = useVehicles();
  const devices = useDevices();
  const [associations, setAssociations] = useState([]);
  //Form Modal
  const [showFormModal, setShowFormModal] = useState(false);
  const [foldersForForms, setFoldersForForms] = useState([]);
  //Permissions
  const canAuditDocument = useCanOneOfEntities(['DOCUMENT_AUDIT']);

  //constants
  const LOADING_ROWS = 3;
  const COLUMN_SIZES_ARRAY = [78, 350];

  //The module refreshes when we change the company selector, meaning that the folders change, except the first load
  const [foldersLength, setFolderLength] = useState(folders.length);
  const prevFoldersForFormsLength = usePrevious(foldersLength);

  const informationData = useMemo(() => {
    let rows = documentTrailSummary?.map((d, idx) => {
      let row = Object.assign({}, d);

      let vehicle = null;
      let device = null;
      let fleetList = [];

      if (row.vehicleDto && row.vehicleDto?.id) {
        vehicle = vehicles.find(v => v.id === row.vehicleDto?.id);
      }
      if (row.deviceDto && row.deviceDto?.id) {
        device = devices.find(d => d.id === row.deviceDto?.id);
      }
      if (row.fleetIds) {
        row.fleetIds.forEach(fleet => {
          if (!fleetList.find(f => f.id === fleet)) {
            fleetList.push({ id: fleet, name: fleets.find(f => f.id === fleet)?.name });
          }
        });
      }

      row.vehicleName = vehicle?.name;
      row.deviceName = device?.name;
      row.fleet = fleetList
        .filter(c => c.name)
        .map(c => c.name)
        .join(', ');
      if (vehicle) {
        const comp = subCompanies.find(c => c.id === vehicle.companyId);
        row.company = { id: comp?.id, name: comp?.name };
      } else if (device) {
        const comp = subCompanies.find(c => c.id === device.companyId);
        row.company = { id: comp?.id, name: comp?.name };
      }
      return row;
    });
    return rows;
  }, [documentTrailSummary, vehicles, devices, fleets, subCompanies]);

  const handleAuditSearchChange = useCallback(searchText => {
    setAuditSearchText(searchText);
  }, []);

  const handleAuditEventsFilterChange = useCallback(events => {
    setAuditEventsFilter(events);
  }, []);

  const handleAuditVehiclesFilterChange = useCallback(vehicles => {
    setAuditVehiclesFilter(vehicles);
  }, []);

  const handleAuditDevicesFilterChange = useCallback(devices => {
    setAuditDevicesFilter(devices);
  }, []);

  const handleAuditUsersFilterChange = useCallback(users => {
    setAuditUsersFilter(users);
  }, []);

  const handleInformationSearchChange = useCallback(searchText => {
    setInformationSearchText(searchText);
  }, []);

  const handleInformationCompanyFilterChange = useCallback(companies => {
    setInformationCompanyFilter(companies);
  }, []);

  const handleInformationVehiclesFilterChange = useCallback(vehicles => {
    setInformationVehiclesFilter(vehicles);
  }, []);

  const handleInformationDevicesFilterChange = useCallback(devices => {
    setInformationDevicesFilter(devices);
  }, []);

  const handleInformationFleetFilterChange = useCallback(fleets => {
    setInformationFleetFilter(fleets);
  }, []);

  const handleInformationReceivedFilterChange = useCallback(receivedData => {
    setInformationReceivedFilter(receivedData);
  }, []);

  const handleDateRangeChange = useCallback(dateRange => {
    setDateRanges(dateRange);
    dispatch(fetchDocumentAuditTrail(id, dateRange[0].startOf('day'), dateRange[1].endOf('day')));
  }, []);

  useEffect(() => {
    let eventsList = [];
    let vehiclesList = [];
    let devicesList = [];
    let usersList = [];

    if (auditTrail) {
      Object.entries(eventCodes).forEach(([key, value], index) => {
        eventsList.push({ id: key, name: value });
      });

      // Get lists of all unique vehicles, devices and users from the audit trail data
      auditTrail.forEach(item => {
        if (item.vehicle?.id && !vehiclesList.find(vehicle => vehicle.id === item.vehicle?.id)) {
          vehiclesList.push({ id: item.vehicle.id, name: item.vehicle.name });
        }
        if (item.device?.id && !devicesList.find(device => device.id === item.device?.id)) {
          devicesList.push({ id: item.device.id, name: item.device.name });
        }
        if (item.user?.id && !usersList.find(user => user.id === item.user?.id)) {
          usersList.push({
            id: item.user.id,
            name: `${item.user.firstName} ${item.user.lastName}`
          });
        }
      });

      // sort all the lists by name
      eventsList = sortBy(eventsList, [item => item.name.toLowerCase()]);
      vehiclesList = sortBy(vehiclesList, [item => item.name.toLowerCase()]);
      devicesList = sortBy(devicesList, [item => item.name.toLowerCase()]);
      usersList = sortBy(usersList, [item => item.name.toLowerCase()]);

      // Add option for no vehicle/device/user to account for the items that are missing this value
      vehiclesList.push({ id: -1, name: t('Common.NoVehicle') });
      devicesList.push({ id: -1, name: t('Common.NoDevice') });
      usersList.push({ id: -1, name: t('Common.NoUser') });

      setAuditEventsFilter(prepareDataForMultiselect(eventsList, t('Vehicles.AllEvents'), null));
      setAuditVehiclesFilter(
        prepareDataForMultiselect(vehiclesList, t('Common.AllVehicles'), null)
      );
      setAuditDevicesFilter(prepareDataForMultiselect(devicesList, t('Common.AllDevices'), null));
      setAuditUsersFilter(prepareDataForMultiselect(usersList, t('Common.AllUsers'), null));
    }
  }, [auditTrail]);

  useEffect(() => {
    let vehiclesList = [];
    let devicesList = [];
    let companyList = [];
    let fleetList = [];
    let receivedList = [];

    if (informationData) {
      // Get lists of all unique vehicles, devices and users from the audit trail data
      informationData.forEach(item => {
        if (
          item.vehicleDto &&
          item.vehicleDto?.id &&
          !vehiclesList.find(vehicle => vehicle.id === item.vehicleDto?.id)
        ) {
          const vehicle = vehicles.find(v => v.id === item.vehicleDto?.id);
          vehiclesList.push({ id: vehicle?.id, name: vehicle?.name });
          if (vehicle && !companyList.find(c => c.id === vehicle.companyId)) {
            companyList.push({
              id: vehicle?.companyId,
              name: subCompanies.find(c => c.id === vehicle?.companyId)?.name
            });
          }
        }
        if (
          item.deviceDto &&
          item.deviceDto?.id &&
          !devicesList.find(device => device.id === item.deviceDto?.id)
        ) {
          const device = devices.find(v => v.id === item.deviceDto?.id);
          devicesList.push({
            id: item.deviceDto?.id,
            name: devices.find(d => d.id === item.deviceDto?.id)?.name
          });
          if (device && !companyList.find(c => c.id === device.companyId)) {
            companyList.push({
              id: device?.companyId,
              name: subCompanies.find(c => c.id === device?.companyId)?.name
            });
          }
        }
        if (item.fleetIds) {
          item.fleetIds.forEach(fleet => {
            if (fleet && !fleetList.find(device => device.id === fleet)) {
              fleetList.push({ id: fleet, name: fleets.find(f => f.id === fleet)?.name });
            }
          });
        }
      });

      // sort all the lists by name
      vehiclesList = sortBy(vehiclesList, [item => item.name?.toLowerCase()]);
      devicesList = sortBy(devicesList, [item => item.name?.toLowerCase()]);
      companyList = sortBy(companyList, [item => item.name?.toLowerCase()]);
      fleetList = sortBy(fleetList, [item => item.name?.toLowerCase()]);

      // Add option for no vehicle/device/user to account for the items that are missing this value
      vehiclesList.push({ id: -1, name: t('Common.NoVehicle') });
      devicesList.push({ id: -1, name: t('Common.NoDevice') });
      companyList.push({ id: -1, name: t('Common.NoCompany') });
      fleetList.push({ id: -1, name: t('Common.NoFleet') });
      receivedList.push({ id: 1, name: t('Easydocs.Received') });
      receivedList.push({ id: -1, name: t('Easydocs.NotReceived') });

      setInformationVehiclesFilter(
        prepareDataForMultiselect(vehiclesList, t('Common.AllVehicles'), null)
      );
      setInformationDevicesFilter(
        prepareDataForMultiselect(devicesList, t('Common.AllDevices'), null)
      );
      setInformationCompanyFilter(
        prepareDataForMultiselect(companyList, t('Common.AllCompanies'), null)
      );
      setInformationFleetFilter(prepareDataForMultiselect(fleetList, t('Common.AllFleets'), null));
      setInformationReceivedFilter(
        prepareDataForMultiselect(receivedList, t('Easydocs.AllReceived'), null)
      );
    }
  }, [informationData]);

  useEffect(() => {
    if (auditTrail) {
      let newFilteredAuditTrail = [];

      auditTrail.forEach(row => {
        let matchSearch = true;

        // filter by search text for columns that are searchable
        if (matchSearch && auditSearchText && auditSearchText.trim()) {
          const searchTextLowerCase = auditSearchText.toLowerCase();
          matchSearch = auditColumns
            .filter(c => c.isSearchable)
            .some(c => {
              const value = getValue(row, c);
              return (
                value
                  ?.toString()
                  .toLowerCase()
                  .indexOf(searchTextLowerCase) >= 0
              );
            });
        }

        // Filter by checked event types
        if (
          matchSearch &&
          auditEventsFilter &&
          !auditEventsFilter.find(f => f.id === FilterIds.AllEvents)?.checked
        ) {
          matchSearch = auditEventsFilter.some(f => f.checked && f.id === row.type);
        }

        // Filter by checked vehicles (0 = all vehicles, -1 = no vehicle)
        if (
          matchSearch &&
          auditVehiclesFilter &&
          !auditVehiclesFilter.find(f => f.id === FilterIds.AllVehicles)?.checked
        ) {
          matchSearch = auditVehiclesFilter.some(
            f =>
              f.checked &&
              (f.id === row.vehicle?.id || (f.id === FilterIds.NoVehicle && !row.vehicle?.id))
          );
        }

        // Filter by checked devices (0 = all devices, -1 = no device)
        if (
          matchSearch &&
          auditDevicesFilter &&
          !auditDevicesFilter.find(f => f.id === FilterIds.AllDevices)?.checked
        ) {
          matchSearch = auditDevicesFilter.some(
            f =>
              f.checked &&
              (f.id === row.device?.id || (f.id === FilterIds.NoDevice && !row.device?.id))
          );
        }

        // Filter by checked users (0 = all users, -1 = no user)
        if (
          matchSearch &&
          auditUsersFilter &&
          !auditUsersFilter.find(f => f.id === FilterIds.AllUsers)?.checked
        ) {
          matchSearch = auditUsersFilter.some(
            f =>
              f.checked && (f.id === row.user?.id || (f.id === FilterIds.NoUser && !row.user?.id))
          );
        }

        if (matchSearch) {
          newFilteredAuditTrail.push(row);
        }
      });

      setFilteredAuditTrail(newFilteredAuditTrail);
    }
  }, [
    auditTrail,
    auditSearchText,
    auditEventsFilter,
    auditVehiclesFilter,
    auditDevicesFilter,
    auditUsersFilter
  ]);

  useEffect(() => {
    if (informationData) {
      let newFilteredInformation = [];

      informationData.forEach(row => {
        let matchSearch = true;

        // filter by search text for columns that are searchable
        if (matchSearch && informationSearchText && informationSearchText.trim()) {
          const searchTextLowerCase = informationSearchText.toLowerCase();
          matchSearch = informationColumns
            .filter(c => c.isSearchable)
            .some(c => {
              const value = getValue(row, c);
              return (
                value
                  ?.toString()
                  .toLowerCase()
                  .indexOf(searchTextLowerCase) >= 0
              );
            });
        }

        // Filter by checked vehicles (0 = all vehicles, -1 = no vehicle)
        if (
          matchSearch &&
          informationVehiclesFilter &&
          !informationVehiclesFilter.find(f => f.id === FilterIds.AllVehicles)?.checked
        ) {
          matchSearch = informationVehiclesFilter.some(
            f =>
              f.checked &&
              (f.id === row.vehicleDto?.id || (f.id === FilterIds.NoVehicle && !row.vehicleDto?.id))
          );
        }

        // Filter by checked devices (0 = all devices, -1 = no device)
        if (
          matchSearch &&
          informationDevicesFilter &&
          !informationDevicesFilter.find(f => f.id === FilterIds.AllDevices)?.checked
        ) {
          matchSearch = informationDevicesFilter.some(
            f =>
              f.checked &&
              (f.id === row.deviceDto?.id || (f.id === FilterIds.NoDevice && !row.deviceDto?.id))
          );
        }

        // Filter by checked users (0 = all users, -1 = no user)
        if (
          matchSearch &&
          informationCompanyFilter &&
          !informationCompanyFilter.find(f => f.id === FilterIds.AllCompanies)?.checked
        ) {
          matchSearch = informationCompanyFilter.some(
            f =>
              f.checked &&
              (f.id === row.company?.id || (f.id === FilterIds.NoCompany && !row.company?.id))
          );
        }

        if (
          matchSearch &&
          informationFleetFilter &&
          !informationFleetFilter.find(f => f.id === FilterIds.AllFleets)?.checked
        ) {
          matchSearch = informationFleetFilter.some(
            f =>
              f.checked &&
              (row.fleetIds?.find(fid => fid === f.id) ||
                (f === FilterIds.NoReceived && !row.fleetIds))
          );
        }

        if (
          matchSearch &&
          informationReceivedFilter &&
          !informationReceivedFilter.find(f => f.id === FilterIds.AllReceived)?.checked
        ) {
          matchSearch = informationReceivedFilter.some(
            f =>
              f.checked &&
              ((f.id === FilterIds.Received && row.received) ||
                (f.id === FilterIds.NoReceived && !row.received)) //  (f === row.received || (f === FilterIds.NoReceived && !row.received))
          );
        }

        if (matchSearch) {
          newFilteredInformation.push(row);
        }
      });

      setFilteredInformation(newFilteredInformation);
    }
  }, [
    informationData,
    informationSearchText,
    informationVehiclesFilter,
    informationDevicesFilter,
    informationCompanyFilter,
    informationFleetFilter,
    informationReceivedFilter
  ]);

  useEffect(() => {
    setFolderLength(foldersForForms.length);
  }, [foldersForForms]);

  useEffect(() => {
    if (prevFoldersForFormsLength && foldersForForms.length !== prevFoldersForFormsLength) {
      history.push('/easydocs');
    }
  }, [foldersForForms, history, prevFoldersForFormsLength]);

  // Find the upload user from the documents audit trail events
  useEffect(() => {
    const uploadEvent = auditTrail.find(event => event.type === 'DOC-UPL' && event.user);

    if (uploadEvent) {
      const { user } = uploadEvent;
      if (user) {
        const uploadUserInEffect = `${user.firstName} ${user.lastName}`;
        setUploadUser(uploadUserInEffect);
      }
    }
    if (!uploadEvent || !uploadEvent.user) {
      setUploadUser('-');
    }
  }, [auditTrail]);

  useEffect(() => {
    const associationsCopy = document && document.associations && [...document.associations];
    const fleetsDoc = fleets
      .filter(
        fleet =>
          fleet.id && associationsCopy && associationsCopy.find(assoc => fleet.id === assoc.fleetId)
      )
      .map(fleet => JSON.parse(JSON.stringify({ ...fleet, type: 'Fleet' })));
    const noFleet = associationsCopy?.find(assoc => assoc.fleetId === -1);
    if (noFleet) {
      fleetsDoc.push({ name: 'No Fleet', type: 'Fleet' });
    }
    const vehiclesDoc = vehicles
      .filter(
        vehicle =>
          vehicle.id &&
          associationsCopy &&
          associationsCopy.find(assoc => vehicle.id === assoc.vehicleId)
      )
      .map(vehicle => JSON.parse(JSON.stringify({ ...vehicle, type: 'Vehicle' })));
    const devicesDoc = devices
      .filter(
        device =>
          device.id &&
          associationsCopy &&
          associationsCopy.find(assoc => device.id === assoc.deviceId)
      )
      .map(dev =>
        JSON.parse(
          JSON.stringify({
            ...dev,
            type: 'Device',
            receivedAt: associationsCopy.find(assoc => dev.id === assoc.deviceId).createdAt
          })
        )
      );
    setAssociations([...fleetsDoc, ...vehiclesDoc, ...devicesDoc]);
  }, [document, fleets, vehicles, devices]);

  useEffect(() => {
    // Make the folder options for forms in select
    const formFolders = folders
      .filter(folder => folder.id)
      .map(folder => ({
        ...folder,
        value: folder.id,
        label: folder.name,
        //if in edit, check the ones that are already in the geofence
        checked: false
      }));
    setFoldersForForms(formFolders);
  }, [folders]);

  useEffect(() => {
    setShowFormModal(false);
    //Set the document folder in history at unmount
    return () => {
      if (document?.folders?.length > 1 && activeFolderPrev) {
        history.location.state = {
          folder: document.folders.find(doc => doc.id === activeFolderPrev.id)
        };
      } else {
        history.location.state = { folder: document?.folders.length && document.folders[0] };
      }
    };
  }, [document, activeFolderPrev]);

  const associationsColumns = [
    {
      label: `${t('Easydocs.Entity')}`,
      width: 300,
      cellDataGetter: ({ rowData }) => rowData.type
    },
    {
      label: `${t('Easydocs.Name')}`,
      width: 300,
      cellDataGetter: ({ rowData }) => rowData.name
    }
  ];

  const InDevicesColumns = [
    {
      label: `${t('Easydocs.Name')}`,
      width: 300,
      cellDataGetter: ({ rowData }) => rowData.name,
      cellRenderer: deviceLinkCellRenderer
    },
    {
      label: `${t('Easydocs.ReceivedAt')}`,
      width: 300,
      cellDataGetter: ({ rowData }) =>
        rowData.receivedAt &&
        format(new Date(rowData.receivedAt), localization.formats.time.formats.dby_imsp)
    }
  ];

  const handleAuditGridResize = () => {
    let offsetTop = 380;
    let headerHeight = 48;
    if (auditTableRef.current) {
      offsetTop = auditTableRef.current.offsetTop;
      const tableHeaderRef = auditTableRef.current.querySelector('.ant-table-header');
      if (tableHeaderRef) {
        headerHeight = tableHeaderRef.clientHeight;
      }
    }
    setAuditGridOffsetTop(offsetTop + headerHeight + 8);
  };

  const handleInformationGridResize = () => {
    let offsetTop = 380;
    let headerHeight = 48;
    if (informationTableRef.current) {
      offsetTop = informationTableRef.current.offsetTop;
      const tableHeaderRef = informationTableRef.current.querySelector('.ant-table-header');
      if (tableHeaderRef) {
        headerHeight = tableHeaderRef.clientHeight;
      }
    }
    setInformationGridOffsetTop(offsetTop + headerHeight + 8);
  };

  let auditColumns = null;
  let informationColumns = null;
  const getTimeAtValue = rowData => {
    return rowData.timeAt ? new Date(rowData.timeAt) : null;
  };

  const getReceivedValue = rowData => {
    return rowData.received ? new Date(rowData.received) : null;
  };

  const getAutoRemovedValue = rowData => {
    return rowData.autoremoved ? new Date(rowData.autoremoved) : null;
  };

  const getEventValue = rowData => {
    return rowData.type ? eventCodes[rowData.type] : '';
  };

  const getDeviceName = rowData => {
    const { device, deviceName } = rowData;
    return device?.name || deviceName;
  };

  const getUserNameValue = rowData => {
    if (!rowData) return '';
    const { userFirstname, userLastname, user } = rowData;
    const firstName = user?.firstName || userFirstname;
    const lastName = user?.lastName || userLastname;
    return `${firstName} ${lastName}`;
  };

  auditColumns = [
    {
      title: t('Easydocs.Time'),
      key: 'timeAt',
      dataIndex: 'timeAt',
      width: 200,
      defaultSortColumn: true,
      getValue: getTimeAtValue,
      sorter: Comparators.Date(getTimeAtValue),
      render: (value, rowData, rowIndex) => {
        if (!rowData.timeAt) {
          return;
        }
        return format(
          new Date(moment(new Date(rowData.timeAt))),
          localization.formats.time.formats.dby_imsp
        );
      }
    },
    {
      title: t('Easydocs.Event'),
      key: 'type',
      dataIndex: 'type',
      width: 450,
      isSearchable: true,
      getValue: getEventValue,
      sorter: Comparators.String(getEventValue),
      render: (value, rowData, rowIndex) => {
        return getEventValue(rowData);
      }
    },
    {
      title: t('Common.Vehicle'),
      key: 'vehicleName',
      dataIndex: ['vehicle', 'name'],
      width: 200,
      isSearchable: true,
      sorter: Comparators.String('vehicle.name'),
      render: (value, rowData, rowIndex) => {
        return auditVehicleLink({ rowData });
      }
    },
    {
      title: t('Easydocs.Device'),
      key: 'deviceName',
      dataIndex: ['device', 'name'],
      width: 200,
      isSearchable: true,
      sorter: Comparators.String('device.name'),
      render: (value, rowData, rowIndex) => {
        return auditDeviceLink({ rowData });
      }
    },
    {
      title: t('Easydocs.User'),
      key: 'userName',
      dataIndex: 'userName',
      width: 200,
      isSearchable: true,
      getValue: getUserNameValue,
      sorter: Comparators.String(getUserNameValue),
      render: (value, rowData, rowIndex) => {
        return auditUserLink({ rowData });
      }
    }
  ];

  informationColumns = [
    {
      title: t('Common.Vehicle'),
      key: 'vehicleName',
      dataIndex: ['vehicleName'],
      width: 200,
      isSearchable: true,
      sorter: Comparators.String('vehicleName'),
      render: (value, rowData, rowIndex) => {
        return vehicleCellRenderer_ANT({ rowData });
      }
    },
    {
      title: t('Easydocs.Device'),
      key: 'deviceName',
      dataIndex: ['deviceName'],
      width: 200,
      isSearchable: true,
      sorter: Comparators.String('deviceName'),
      render: (value, rowData, rowIndex) => {
        return deviceCellRenderer_ANT({ rowData });
      }
    },
    {
      title: t('Common.Company'),
      key: 'company.name',
      dataIndex: ['company', 'name'],
      width: 200,
      isSearchable: true,
      sorter: Comparators.String('company.name'),
      render: (value, rowData, rowIndex) => {
        const data = rowData?.company?.name;
        return stringCellRenderer_ANT(data);
      }
    },
    {
      title: t('Common.Fleet'),
      key: 'fleet',
      dataIndex: 'fleet',
      width: 200,
      isSearchable: true,
      render: (value, rowData, rowIndex) => {
        return stringCellRenderer_ANT(value);
      }
    },
    {
      title: t('Easydocs.Received'),
      key: 'received',
      dataIndex: 'received',
      width: 200,
      defaultSortColumn: true,
      getValue: getReceivedValue,
      sorter: Comparators.Date(getReceivedValue),
      render: (value, rowData, rowIndex) => {
        return dateCellRenderer_ANT({
          dateFormat: localization.formats.time.formats.dby_imsp,
          date: value
        });
      }
    },
    {
      title: t('Easydocs.AutoRemove'),
      key: 'autoremoved',
      dataIndex: 'autoremoved',
      width: 200,
      getValue: getAutoRemovedValue,
      sorter: Comparators.Date(getAutoRemovedValue),
      render: (value, rowData, rowIndex) => {
        return dateCellRenderer_ANT({
          dateFormat: localization.formats.time.formats.dby_imsp,
          date: value
        });
      }
    }
  ];

  if (!document || parseInt(id) !== parseInt(document.id)) {
    return <LoadingTable numberOfRows={25} columnSizes={[2000]} />;
  }

  if (document && parseInt(document.id) === parseInt(id)) {
    dispatch(setPageTitle(document.name || document.filename));
    dispatch(setBackButton(true));
  }

  // Edit form modal functions
  const handleShowUploadModal = () => {
    const foldersDocIds = document.folders.map(folder => folder.id);
    const foldersForEdit = foldersForForms.map(folder => {
      return { ...folder, checked: foldersDocIds.includes(folder.id) };
    });
    const uploadfile = { name: document.filename, documentId: document.id, success: true };
    let initialEditValues = {
      [`name_${document.id}`]: document.name,
      [`description_${document.id}`]: document.description,
      [`acknowledge_${document.id}`]: document.acknowledge,
      [`folders_${document.id}`]: foldersForEdit,
      [`hasExpirationDate_${document.id}`]: document.expiredAt ? true : false,
      [`autoRemoval_${document.id}`]: document.autoRemoval,
      [`expiredAt_${document.id}`]: document.expiredAt
    };

    dispatch(uploadFileSuccess(uploadfile));
    dispatch(addInitialValuesForEdit(initialEditValues));
    dispatch(changeDocumentStep(2));
    setShowFormModal(true);
  };

  const handleCloseUploadModal = () => {
    setShowFormModal(false);

    dispatch(changeDocumentStep(1));
    dispatch(clearEditFiles());
  };

  //Download
  const handleDownloadClick = () => {
    dispatch(downloadDocument(id));
  };

  return (
    <div className={styles.documentWrapper}>
      <FormModal
        show={showFormModal}
        title={t('Easydocs.EditDocument')}
        deleteDataOnExit={false}
        onHide={handleCloseUploadModal}
        folders={foldersForForms}
        companies={[]}
      />
      <div className={styles.documentHeaderWrapper}>
        <Can everyEntity={[entities.DOCUMENT_UPDATE]}>
          <div className={styles.documentViewButton}>
            <Button
              onClick={handleShowUploadModal}
              type="primary"
              size="large"
              id={BUTTON_IDS.documentViewEdit}
            >
              {t('Easydocs.Edit')}
            </Button>
          </div>
        </Can>
        <Button onClick={handleDownloadClick} size="large" id={BUTTON_IDS.documentViewDownload}>
          {t('Easydocs.Download')}
        </Button>
      </div>
      <div className={`${styles.document} document-view`}>
        <div>
          <div>{t('Easydocs.DocumentDetails')}</div>
        </div>

        <div className={styles.document__details}>
          <div className={styles.details__columnNew}>
            <Tooltip title={document.name ? document.name : '-'}>
              <>
                <InfoRow
                  label={t('Easydocs.Name') + ':'}
                  hideBorder
                  labelWidth={'30%'}
                  value={document.name ? document.name : '-'}
                  styles={styles}
                  py={'0px'}
                  sxValue={{
                    width: '70%',
                    fontSize: '15px',
                    display: 'inline-block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                />
              </>
            </Tooltip>
            <Tooltip title={document.description ? document.description : '-'}>
              <>
                <InfoRow
                  label={t('Easydocs.Description') + ':'}
                  hideBorder
                  labelWidth={'30%'}
                  value={document.description ? document.description : '-'}
                  styles={styles}
                  py={'0px'}
                  sxValue={{
                    width: '70%',
                    fontSize: '15px',
                    display: 'inline-block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                />
              </>
            </Tooltip>
            <Tooltip
              title={
                document.folders.length > 0 && currentFoldersString ? currentFoldersString : '-'
              }
            >
              <>
                <InfoRow
                  label={
                    (currentFolders.length > 1 ? t('Easydocs.Folders') : t('Easydocs.Folder')) + ':'
                  }
                  hideBorder
                  labelWidth={'30%'}
                  value={
                    document.folders.length > 0 && currentFoldersString ? currentFoldersString : '-'
                  }
                  styles={styles}
                  py={'0px'}
                  sxValue={{
                    width: '70%',
                    fontSize: '15px',
                    display: 'inline-block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                />
              </>
            </Tooltip>
            <Tooltip title={document.filename ? document.filename : '-'}>
              <>
                <InfoRow
                  label={t('Easydocs.Filename') + ':'}
                  hideBorder
                  labelWidth={'30%'}
                  value={document.filename ? document.filename : '-'}
                  styles={styles}
                  py={'0px'}
                  sxValue={{
                    width: '70%',
                    fontSize: '15px',
                    display: 'inline-block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                />
              </>
            </Tooltip>
          </div>
          <div className={styles.details__column}>
            <InfoRow
              label={t('Easydocs.DocumentSize')}
              hideBorder
              labelWidth={'45%'}
              value={document.filesize ? formatBytes(document.filesize) : ''}
              styles={styles}
              py={'0px'}
              sxValue={{ fontSize: '15px' }}
            />
            <InfoRow
              label={t('Easydocs.DocumentType')}
              hideBorder
              labelWidth={'45%'}
              value="PDF"
              styles={styles}
              py={'0px'}
              sxValue={{ fontSize: '15px' }}
            />
            <InfoRow
              label={t('Easydocs.Overwritten')}
              hideBorder
              labelWidth={'45%'}
              value={
                typeof document.overwritten === 'boolean'
                  ? document.overwritten
                    ? t('Common.Yes')
                    : t('Common.No')
                  : '-'
              }
              styles={styles}
              py={'0px'}
              sxValue={{ fontSize: '15px' }}
            />
            <InfoRow
              label={t('Easydocs.Acknowledge') + ':'}
              hideBorder
              labelWidth={'45%'}
              value={
                typeof document.acknowledge === 'boolean'
                  ? document.acknowledge
                    ? t('Common.Yes')
                    : t('Common.No')
                  : '-'
              }
              styles={styles}
              py={'0px'}
              sxValue={{ fontSize: '15px' }}
            />
          </div>
          <div className={styles.details__column}>
            <InfoRow
              label={t('Easydocs.Uploaded') + ':'}
              hideBorder
              labelWidth={'40%'}
              py={'0px'}
              value={
                document.uploadedAt
                  ? format(
                      new Date(document.uploadedAt),
                      localization.formats.time.formats.dby_imsp
                    )
                  : '-'
              }
              styles={styles}
              sxValue={{ fontSize: '15px' }}
            />
            <InfoRow
              label={t('Easydocs.UploadedBy')}
              hideBorder
              labelWidth={'40%'}
              py={'0px'}
              value={uploadUser ? uploadUser : '-'}
              styles={styles}
              sxValue={{ fontSize: '15px' }}
            />
            <InfoRow
              label={t('Easydocs.ExpirationDate')}
              hideBorder
              labelWidth={'40%'}
              py={'0px'}
              value={
                document.expiredAt
                  ? format(new Date(document.expiredAt), localization.formats.time.formats.dby)
                  : '-'
              }
              styles={styles}
              sxValue={{ fontSize: '15px' }}
            />
            <InfoRow
              label={t('Easydocs.AutoRemovalEnabled') + ':'}
              hideBorder
              labelWidth={'55%'}
              py={'0px'}
              value={
                document.expiredAt ? (document.autoRemoval ? t('Common.Yes') : t('Common.No')) : '-'
              }
              styles={styles}
              sxValue={{ fontSize: '15px' }}
            />
          </div>
        </div>
        <Tabs
          defaultActiveKey="information"
          id="uncontrolled-tab-example"
          className={styles.nav__tabs}
        >
          <Tab eventKey="information" title={t('Easydocs.Information')}>
            <InformationToolbar
              document={document}
              filteredEventCount={filteredInformation?.length}
              data={filteredInformation}
              searchText={informationSearchText}
              vehiclesFilter={informationVehiclesFilter}
              devicesFilter={informationDevicesFilter}
              companyFilter={informationCompanyFilter}
              fleetFilter={informationFleetFilter}
              receivedFilter={informationReceivedFilter}
              onSearchChange={handleInformationSearchChange}
              onVehiclesFilterChange={handleInformationVehiclesFilterChange}
              onDevicesFilterChange={handleInformationDevicesFilterChange}
              onCompanyFilterChange={handleInformationCompanyFilterChange}
              onFleetFilterChange={handleInformationFleetFilterChange}
              onReceivedFilterChange={handleInformationReceivedFilterChange}
            />
            <ResizeObserver onResize={handleInformationGridResize}>
              <Table
                className={`${gridStyles.grid} ${styles.auditTrailGrid}`}
                ref={informationTableRef}
                dataSource={filteredInformation}
                columns={informationColumns}
                rowKey={'id'}
                pagination={false}
                scroll={{ y: `calc(100vh - ${informationGridOffsetTop}px)`, x: 'max-content' }}
                showSorterTooltip={false}
                sortDirections={['ascend', 'descend', 'ascend']}
              />
            </ResizeObserver>
          </Tab>
          <Tab eventKey="audit" title={t('Easydocs.AuditTrailReport')} disabled={!canAuditDocument}>
            <AuditTrailToolbar
              document={document}
              filteredEventCount={filteredAuditTrail?.length}
              auditTrail={auditTrail}
              searchText={auditSearchText}
              eventsFilter={auditEventsFilter}
              vehiclesFilter={auditVehiclesFilter}
              devicesFilter={auditDevicesFilter}
              usersFilter={auditUsersFilter}
              onSearchChange={handleAuditSearchChange}
              onEventsFilterChange={handleAuditEventsFilterChange}
              onVehiclesFilterChange={handleAuditVehiclesFilterChange}
              onDevicesFilterChange={handleAuditDevicesFilterChange}
              onUsersFilterChange={handleAuditUsersFilterChange}
              dateRanges={dateRanges}
              onDateRangeChange={handleDateRangeChange}
            />
            <ResizeObserver onResize={handleAuditGridResize}>
              <Table
                className={`${gridStyles.grid} ${styles.auditTrailGrid}`}
                ref={auditTableRef}
                dataSource={filteredAuditTrail}
                columns={auditColumns}
                rowKey={'id'}
                pagination={false}
                scroll={{ y: `calc(100vh - ${auditGridOffsetTop}px)`, x: 'max-content' }}
                showSorterTooltip={false}
                sortDirections={['ascend', 'descend', 'ascend']}
              />
            </ResizeObserver>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default DocumentView;
