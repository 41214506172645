import { FuelFieldKey } from 'containers/Configuration/VehicleTypes/constants';

export const vehicleTypeMetrics = {
  harshAcceleration: 'acceleration.threshold',
  harshBrake: 'harshbrake.threshold',
  overRev: 'overrev.threshold',
  idleTime: 'idle.threshold',
  oilOverTemp: 'oiltemp.threshold',
  coolantOverTemp: 'coolanttemp.threshold',
  oilPressure: 'oilpressure.threshold',
  harshCornering: 'harshcornering.threshold',
  harshCorneringLeft: 'harshleft.threshold',
  harshCorneringRight: 'harshright.threshold',
  impact: 'impact.threshold',
  [FuelFieldKey.AVRFuelEfficiency]: 'averagefuelefficiency.threshold',
  [FuelFieldKey.Co2EmissionsFactor]: 'co2emissionsfactor.threshold'
};
