import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { UDT } from '../../UDT/UDT';
import styles from './UnidentifiedDriverProfileModal.module.scss';

export function UnidentifiedDriverProfileModal({ showModal, onSave, onCancel, date, ...props }) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setEditMode(false);
  }, []);

  const handleSaveRecord = useCallback(
    closeModal => {
      if (onSave) {
        onSave(closeModal);
      }
    },
    [onSave]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      const handleResize = () => setWindowDimensions(getWindowDimensions());

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
  };

  const { width, height } = useWindowDimensions();

  return (
    <Modal
      open={showModal}
      title={t('ELD.DriverPortal.UnidentifiedDriverProfile')}
      closable={true}
      onOk={handleSaveRecord}
      onCancel={handleCancel}
      style={{
        top: 20,
        width: (80 * width) / 100,
        minWidth: (80 * width) / 100,
        height: (80 * height) / 100,
        minHeight: (80 * height) / 100
      }}
      className={styles.udtModalClass}
      footer={null}
    >
      <div>
        <Row>
          <Col span={24}>
            <div className={styles.udt_toolbar}>
              <Button
                type="primary"
                style={{ margin: '5px', height: '40px' }}
                disabled={editMode}
                onClick={() => setEditMode(true)}
                //id={BUTTON_IDS.eldBulkAssignment}
              >
                {t('ELD.DriverPortal.BulkClaim')}
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <div
              style={{
                height: (80 * height) / 100,
                overflow: 'scroll'
              }}
            >
              <UDT editMode={editMode} restoreAssignMode={() => setEditMode(false)} />
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
}
