export const toTitleCase = (str, forceLowerCase = true) => {
  return str?.replace(/\w\S*/g, txt => {
    return (
      txt.charAt(0).toUpperCase() + (forceLowerCase ? txt.substr(1).toLowerCase() : txt.substr(1))
    );
  });
};

export const toLowerCaseDashDelimited = str => {
  return str.toLowerCase().replace(' ', '-');
};

export const toUnderScore = str => {
  return str.replace(/ /g, '_');
};

export const parseErrorMessage = error => {
  const message =
    error.response?.body?.localizedMessage ||
    error.response?.body?.message ||
    error.response?.body?.error ||
    error.response?.body[0]?.error ||
    error.message ||
    error.error ||
    error;

  if (!typeof message === 'string') {
    return 'Unknown error';
  }

  return message.replace('java.lang.Exception:', '');
};

export const parseUserErrorMessage = error => {
  try {
    const parsedError = JSON.parse(error.response.text);
    if (!parsedError) {
      return error;
    }
    const errorMsg = parsedError.localizedMessage || parsedError.message || parsedError.error;
    return errorMsg.replace('org.postgresql.util.PSQLException:', '');
  } catch (err) {
    return err;
  }
};

export const hasCharacters = text => {
  return /\S/.test(text);
};

export const camelize = str => {
  return str?.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
    if (+match === 0) return '';
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const capitalize = str => {
  return typeof str !== 'string' ? '' : str.charAt(0).toUpperCase() + str.slice(1);
};

export const sortStrings = (a = '', b = '') => {
  const A = a.toUpperCase();
  const B = b.toUpperCase();

  if (A < B) {
    return -1;
  }
  if (A > B) {
    return 1;
  }
  return 0;
};

export const sortNumbers = (a = '', b = '') => {
  const A = parseFloat(a);
  const B = parseFloat(b);

  if (isNaN(A) && isNaN(B)) {
    return 0;
  }
  if (isNaN(A)) {
    return -1;
  }
  if (isNaN(B)) {
    return 1;
  }
  if (A < B) {
    return -1;
  }
  if (A > B) {
    return 1;
  }
  return 0;
};

export const boldKeywordsInText = (keywords, text) => {
  if (keywords && keywords.trim()) {
    let reg;
    try {
      reg = new RegExp(keywords, 'gi');
    } catch (error) {
      return [{ word: text }];
    }
    const fragments = [];
    let m;
    do {
      m = reg.exec(text);
      if (m) {
        let index = m.index;
        for (const c of m[0]) {
          fragments.push({
            index: index,
            word: c
          });
          index += 1;
        }
      }
    } while (m);
    let words = [],
      spanCollecting;
    for (let index = 0; index < text.length; index++) {
      const char = text[index];
      if (fragments.every(frag => frag.index !== index)) {
        if (index === 0) {
          spanCollecting = true;
          words.push({
            word: char
          });
        } else {
          if (spanCollecting) {
            words[words.length - 1].word = words[words.length - 1].word.concat(char);
          } else {
            spanCollecting = true;
            words.push({
              word: char
            });
          }
        }
      } else {
        if (index === 0) {
          spanCollecting = false;
          words.push({
            word: char,
            bold: true
          });
        } else {
          if (spanCollecting) {
            spanCollecting = false;
            words.push({
              word: char,
              bold: true
            });
          } else {
            words[words.length - 1].word = words[words.length - 1].word.concat(char);
          }
        }
      }
    }
    return words;
  }
  return [{ word: text }];
};

export const formatForCsv = (value = '') => {
  let formattedValue = value;
  // escape string quotes in extra double quotes
  // According to https://en.wikipedia.org/wiki/Comma-separated_values
  if (value.match && value.match(/,|"/)) {
    formattedValue = value.replaceAll('"', '""');
  }
  // wrap the whole string into double quotes, if comma is present
  // this will keep the value into one cell
  if (formattedValue.includes(',')) {
    formattedValue = `"${formattedValue}"`;
  }
  return formattedValue.trim();
};

export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string' || str.length === 0) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const truncate = (str = '', length = 20) => {
  return str.length > length ? str.substring(0, length) + '...' : str;
};
