import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo } from 'react';
import { API_PATH } from 'config';
import request from 'superagent';
import { api } from 'utils/api';
import { Brands } from 'features/brands/brands';
import { DEFAULT_PREFERENCES } from 'containers/Settings/constants';

export const UserPrefsDefaultLocale = DEFAULT_PREFERENCES.language;

const preferences = {
  userPreferences: {},
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false
  }
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.userPreferences = {};
}

const preferencesSlice = createSlice({
  name: 'userPreferences',
  initialState: preferences,
  reducers: {
    fetchPreferencesStart: startLoading,
    fetchPreferencesSuccess(state, { payload }) {
      const brand = Object.values(Brands).find(brand =>
        brand.domains.includes(window.location.hostname)
      );
      const brandDefaultValues = {};
      if (!payload.preferences?.refresh?.tracking && brand?.refresh?.tracking) {
        brandDefaultValues.refresh = {
          ...preferences?.refresh,
          tracking: brand?.refresh?.tracking
        };
      }
      if (brandDefaultValues.refresh) {
        state.preferences = {
          ...brandDefaultValues,
          ...payload.preferences,
          refresh: {
            ...brandDefaultValues.refresh,
            ...payload.preferences
          }
        };
      } else {
        state.preferences = payload.preferences;
      }
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty =
        !payload.preferences || Object.keys(payload.preferences).length === 0;
    },
    fetchPreferencesFailure: loadingFailed
  }
});

export const {
  fetchPreferencesStart,
  fetchPreferencesSuccess,
  fetchPreferencesFailure
} = preferencesSlice.actions;

export const fetchPreferences = () => (dispatch, getState) => {
  const {
    user: { current: currentUser }
  } = getState();
  const userKey = currentUser?.auth?.key;
  const id = currentUser?.id;
  try {
    if (!userKey) {
      return;
    }
    dispatch(fetchPreferencesStart());
    request('GET', `${API_PATH}/users/${id}/config/settings`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(res => {
        dispatch(fetchPreferencesSuccess({ preferences: res.body }));
      })
      .catch(err => {
        dispatch(fetchPreferencesFailure({ err: err.toString() }));
        console.warn('ERROR', err);
      });
  } catch (err) {
    dispatch(fetchPreferencesFailure({ err: err.toString() }));
  }
};

const silentPostPreferences = userPreferences => async (dispatch, getState) => {
  const {
    user: { current: currentUser }
  } = getState();
  try {
    await api.put(
      `/users/${currentUser?.id}/config/settings`,
      { authKey: currentUser?.auth?.key },
      userPreferences
    );
    dispatch(fetchPreferences());
  } catch (err) {}
};

export const updatePreferences = preferences => dispatch => {
  dispatch(fetchPreferencesSuccess({ preferences }));
};

export const useUserPreferencesFetched = () => {
  const preferences = useSelector(state => state.userPreferences.preferences);
  const isFetching = useSelector(state => state.userPreferences.meta.isFetching);
  const isListEmpty = useSelector(state => state.userPreferences.meta.isListEmpty);

  return (
    !isFetching &&
    (isListEmpty || (typeof preferences === 'object' && Object.keys(preferences).length > 0))
  );
};

const useStablilizedUserPrefs = () => {
  //expose stablilized userPrefs after user authenticated/userInfo/userPrefs/companies/regions fetched
  const dispatch = useDispatch();
  const preferences = useSelector(state => state.userPreferences.preferences);
  const isFetching = useSelector(state => state.userPreferences.meta.isFetching);
  const isListEmpty = useSelector(state => state.userPreferences.meta.isListEmpty);

  const regions = useSelector(state => state.regions.list);
  const isRegionFetching = useSelector(state => state.regions.meta.isFetching);
  const isRegionsFetchesSuccess = useSelector(state => state.regions.meta.success);
  const isRegionsFetchedError = useSelector(state => state.regions.meta.error);
  const regionsFetched = useMemo(() => {
    const isFetchingComplete = isRegionsFetchesSuccess || isRegionsFetchedError;
    return !isRegionFetching && isFetchingComplete;
  }, [isRegionFetching, isRegionsFetchesSuccess, isRegionsFetchedError]);

  const companies = useSelector(state => state.companies.list);
  const companiesFetced = useSelector(
    state => !state.companies.meta.isLoading && !!state.companies.meta.lastFetched
  );
  const userInfo = useSelector(state => state.user.current);
  const userIsLogin = useSelector(state => !!state.user.current.auth.key);

  if (!isFetching && (!preferences || Object.keys(preferences).length === 0) && !isListEmpty) {
    dispatch(fetchPreferences());
  }

  const ready = useMemo(() => {
    const userPrefsFetched =
      !isFetching &&
      (isListEmpty || (typeof preferences === 'object' && Object.keys(preferences).length > 0));
    const readyToCheck = userIsLogin && userPrefsFetched && companiesFetced && regionsFetched;
    return readyToCheck;
  }, [
    preferences,
    userInfo,
    companies,
    regions,
    isFetching,
    isListEmpty,
    userIsLogin,
    companiesFetced,
    regionsFetched
  ]);

  const unifiedLocale = useCallback(locale => {
    try {
      return locale?.replace(
        /(\w+)-(\w+)/g,
        (...args) => (args && args[1] && args[2] && `${args[1]}-${args[2].toUpperCase()}`) || locale
      );
    } catch (error) {
      return locale;
    }
  }, []);

  useEffect(() => {
    const userPrefsFetched =
      !isFetching &&
      (isListEmpty || (typeof preferences === 'object' && Object.keys(preferences).length > 0));
    if (userPrefsFetched) {
      const userPresetLanguage = preferences?.language;
      const userChangedLanguage =
        sessionStorage.getItem('newLoginLanguageSelected') &&
        userPresetLanguage !== localStorage.getItem('i18nextLng');
      if (userChangedLanguage) {
        dispatch(
          silentPostPreferences({
            ...(preferences || {}),
            language: unifiedLocale(localStorage.getItem('i18nextLng'))
          })
        );
        //make sure only happend once,otherwise it will override subsequent language changing as long as page fresh
        sessionStorage.removeItem('newLoginLanguageSelected');
      }
    }
  }, [preferences, isFetching, isListEmpty]);

  return { ready, preferences, userInfo, userIsLogin, companies, regions, unifiedLocale };
};

export const useUserPreferences = () => {
  const dispatch = useDispatch();
  const {
    ready,
    preferences,
    userInfo,
    companies,
    regions,
    unifiedLocale
  } = useStablilizedUserPrefs();

  useEffect(() => {
    //Once userPref stabilized,modify/expose correct locale/language of userPrefs
    //language/locale selecting priority : userPrefs language from API;userCompany locale;first region(RegionAPI) local;static UserPrefsDefaultLocale;
    if (ready) {
      const userPresetLanguage = preferences?.language;
      if (userPresetLanguage) {
        if (userPresetLanguage !== unifiedLocale(userPresetLanguage)) {
          dispatch(
            fetchPreferencesSuccess({
              preferences: { ...(preferences || {}), language: unifiedLocale(userPresetLanguage) }
            })
          );
        }
      } else {
        const companyLocale = (companies || []).find(c => c.id === userInfo?.companyId)?.locale;
        const regionLocale = regions && regions[0]?.locale;
        const language = companyLocale || regionLocale || UserPrefsDefaultLocale;
        dispatch(
          fetchPreferencesSuccess({
            preferences: {
              ...DEFAULT_PREFERENCES,
              ...(preferences || {}),
              language: unifiedLocale(language)
            }
          })
        );
      }
    }
  }, [ready, preferences, companies, userInfo, regions, unifiedLocale]);

  return preferences;
};

export const setUserPreferences = async (userPreferences, id, userKey) => {
  const res = await api.put(`/users/${id}/config/settings`, { authKey: userKey }, userPreferences);
  return res;
};

export default preferencesSlice.reducer;
