import React from 'react';
import { Column, Table, AutoSizer } from 'react-virtualized';
import { Checkbox } from 'antd';

import { LoadingTable } from 'components/grid/LoadingTable';

const checkboxCellRenderer = ({ rowData, checkedItems, setCheckedItems }) => {
  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        onCheckAction(rowData.id, checkedItems, setCheckedItems);
      }}
    >
      <Checkbox checked={checkedItems && checkedItems.includes(rowData.id)} onChange={() => {}} />
    </div>
  );
};

export const onCheckAction = (id, items, action) => {
  const indexId = items.indexOf(id);
  let newCheckedValues;
  if (indexId !== -1) {
    newCheckedValues = items.filter(item => item !== items[indexId]);
  } else {
    newCheckedValues = [...items, id];
  }
  action(newCheckedValues);
};

const InfoTable = ({
  data,
  columns,
  styles,
  enableSelectColumn,
  checkedItems,
  setCheckedItems,
  numberOfLoadingRows,
  columnSizes,
  isLoading,
  customRowStyle,
  cellCache,
  setTableRef,
  infoTableCls = '',
  rowHeight
}) => {
  if (isLoading)
    return (
      <LoadingTable
        columnSizes={columnSizes || [73, 94, 57, 57, 94, 19]}
        numberOfRows={numberOfLoadingRows || 20}
      />
    );
  return (
    <AutoSizer>
      {({ height, width }) => {
        const dynamicColumnWidth = width - columns.reduce((acc, obj) => acc + obj.width, 0);
        return (
          <Table
            className={infoTableCls}
            width={width}
            height={height}
            headerHeight={32}
            headerClassName={styles.tableHeader}
            rowClassName={styles.tableRow}
            rowHeight={rowHeight || (cellCache && cellCache.rowHeight ? cellCache.rowHeight : 64)}
            rowCount={data.length || 0}
            rowGetter={({ index }) => data[index]}
            rowStyle={customRowStyle || { alignItems: 'flex-start' }}
            ref={setTableRef ? ref => setTableRef(ref) : ''}
            gridClassName="showScrollbarsOnHover"
          >
            {columns.map(column => {
              if (enableSelectColumn && column.type === 'select') {
                return (
                  <Column
                    key={column.label}
                    label={column.label}
                    dataKey=""
                    width={column.width > 0 ? column.width : dynamicColumnWidth}
                    cellRenderer={props =>
                      checkboxCellRenderer({
                        ...props,
                        checkedItems,
                        setCheckedItems
                      })
                    }
                    className={column.className}
                  />
                );
              }
              return (
                <Column
                  key={column.label}
                  label={column.label}
                  dataKey=""
                  width={column.width > 0 ? column.width : dynamicColumnWidth}
                  cellDataGetter={column.cellDataGetter}
                  cellRenderer={column.cellRenderer}
                  className={column.className}
                />
              );
            })}
          </Table>
        );
      }}
    </AutoSizer>
  );
};

export default InfoTable;
