import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

import { Table, Tag, Tooltip } from 'antd';
import { RowStatus, Status, TagStatus } from 'components/ant';
import { ActionMenu } from 'components/actionMenu';
import moment from 'moment';

import {
  InspectionsTabs,
  INSPECTION_KEY,
  MainColumns,
  MainColumnKeys,
  PATHS,
  VEHICLE_INSPECTIONS_TABS,
  GROUP_BY
} from './constants';

import { Paths } from '../../utils/constants';

import { formatStatus, statusMap } from '../../utils/helpers';
import { format } from 'utils/dates';

import styles from '../Inspections.module.scss';

export const getInspectionStatus = status => {
  const inspectionStatus = statusMap((status || '').toLowerCase());

  return (
    <RowStatus showIcon status={inspectionStatus}>
      {inspectionStatus ? i18n.t(`Status.${status.toLowerCase()}`) : i18n.t('Unknown')}
    </RowStatus>
  );
};

export const getInspectionsTabs = dvirPermission => {
  let tabs = [...InspectionsTabs];

  // Don't show "Pending Driver Sign Off" tab if company does not have DVIR Service
  if (!dvirPermission) {
    tabs = tabs.filter(tab => tab.key !== VEHICLE_INSPECTIONS_TABS.pendingdriversignoff);
  }

  return tabs;
};

const calculateStatusOccurencies = items => {
  return items.reduce((acc, current) => {
    acc[current.status] = acc[current.status]
      ? (acc[current.status] += 1)
      : (acc[current.status] = 1);
    return acc;
  }, {});
};

export const prepareAllDataForTable = (inspections = [], groupBy, fleetIds = []) => {
  let inspectionsClone = [...inspections];
  if (groupBy === 'inspections' && !fleetIds.includes(0)) {
    inspectionsClone.forEach(inspection => {
      if (inspection.groupedItems) {
        inspection.groupedItems = inspection.groupedItems?.filter(veh =>
          fleetIds?.some(id => veh.fleetIds && veh.fleetIds?.includes(id))
        );
      }
    });
  }

  return inspectionsClone.map(inspection => {
    const instances = calculateStatusOccurencies(inspection.groupedItems);
    return {
      [MainColumnKeys.INSPECTION_NAME]: getInspectionName(inspection, groupBy),
      [MainColumnKeys.INSTANCES]: (
        <div className={styles.inspectionInstances}>
          {Object.entries(instances).map(([key, value]) => {
            return <TagStatus status={formatStatus(key)} value={value} />;
          })}
        </div>
      ),
      key: inspection.key,
      groupedItems: inspection.groupedItems
    };
  });
};

export const prepareColumnsForTable = (t, groupBy, expandAllButton) => {
  const cols = MainColumns(groupBy).map(column => ({
    ...column,
    title: t(`Inspections.Table.${column.title}`)
  }));
  cols.unshift(Table.EXPAND_COLUMN, { title: expandAllButton, width: 1 });
  return cols;
};

const getInspectionMenuItems = ({
  inspection,
  t = null,
  history = null,
  openEditModal,
  openInspectionModal
}) => {
  switch (true) {
    case ['PASS', 'CLOSED', 'REPAIRED'].includes(inspection?.status):
      return [
        {
          name: t('Common.View'),
          onClick: () => {
            history.push(`${PATHS.VEHICLE_INSPECTIONS_VIEW}/${inspection.inspectionId}`);
          },
          entity: 'VIEW',
          id: 'btn_inspectionsView'
        }
      ];
    case ['FAIL', 'INPROGRESS'].includes(inspection?.status) && !inspection?.schedule:
      return [
        {
          name: t('Common.View'),
          onClick: () => {
            history.push(`${PATHS.VEHICLE_INSPECTIONS_VIEW}/${inspection.inspectionId}`);
          },
          entity: 'VIEW',
          id: 'btn_inspectionsView'
        },
        {
          name: t('VehicleMntSchedules.AddNewSchedule'),
          onClick: () => {
            history.push(`${Paths.VEHICLEMAINTENANCE_ADD}`, { inspection });
          },
          id: 'btn_inspectionsAdd'
        },
        {
          name: t('Actions.EditNotes'),
          onClick: () => openEditModal(inspection),
          entity: 'EDIT',
          id: 'btn_inspectionsEdit'
        },
        {
          name: t('Actions.Close'),
          onClick: () => {
            inspection?.schedule
              ? history.push(`${Paths.VEHICLEMAINTENANCE_COMPLETE}/${inspection?.schedule.id}`, {
                  inspection
                })
              : openInspectionModal(inspection);
          },
          entity: 'CLOSE',
          id: 'btn_inspectionsClose'
        }
      ];
    case ['FAIL', 'INPROGRESS'].includes(inspection?.status):
      return [
        {
          name: t('Common.View'),
          onClick: () => {
            history.push(`${PATHS.VEHICLE_INSPECTIONS_VIEW}/${inspection.inspectionId}`);
          },
          entity: 'VIEW',
          id: 'btn_inspectionsView'
        },
        {
          name: t('Actions.EditNotes'),
          onClick: () => openEditModal(inspection),
          entity: 'EDIT',
          id: 'btn_inspectionsEdit'
        },
        {
          name: t('Actions.Close'),
          onClick: () => {
            inspection?.schedule
              ? history.push(`${Paths.VEHICLEMAINTENANCE_COMPLETE}/${inspection?.schedule.id}`, {
                  inspection
                })
              : openInspectionModal(inspection);
          },
          entity: 'CLOSE',
          id: 'btn_inspectionsClose'
        }
      ];
    case inspection?.status === 'INPROGRESS' && !inspection?.schedule:
      return [
        {
          name: t('Common.View'),
          onClick: () => {
            history.push(`${PATHS.VEHICLE_INSPECTIONS_VIEW}/${inspection.inspectionId}`);
          },
          entity: 'VIEW',
          id: 'btn_inspectionsView'
        },
        {
          name: t('Actions.EditNotes'),
          onClick: () => openEditModal(inspection),
          entity: 'EDIT',
          id: 'btn_inspectionsEdit'
        },
        {
          name: t('Actions.Close'),
          onClick: () => {
            inspection?.schedule
              ? history.push(`${Paths.VEHICLEMAINTENANCE_COMPLETE}/${inspection?.schedule.id}`, {
                  inspection
                })
              : openInspectionModal(inspection);
          },
          entity: 'CLOSE',
          id: 'btn_inspectionsClose'
        }
      ];
    default:
      return [];
  }
};

const getSchedule = schedule => {
  if (!schedule?.name) {
    return;
  }

  return <Link to={`/vehiclemaintenance/schedules/id/${schedule.id}`}>{schedule?.name}</Link>;
};
const getInspectionName = (inspection, groupBy) => {
  if (groupBy === GROUP_BY.inspections) {
    return <span className={styles.inspectionName}>{inspection?.item?.checklistName}</span>;
  }
  return (
    <Link to={`/settings/vehicles/id/${inspection?.vehicle?.id}`}>
      <span className={styles.inspectionName}>{inspection?.item?.checklistName}</span>
    </Link>
  );
};

const getVehicleName = (item, groupBy) => {
  if (groupBy === GROUP_BY.vehicles) {
    return (
      <Link to={`/vehiclemaintenance/inspections/view/${item?.inspectionId}`}>{item?.name}</Link>
    );
  }
  return <Link to={`/settings/vehicles/id/${item?.id}`}>{item?.name}</Link>;
};

const getVehicleDetails = (vehicle, t) => {
  if (!vehicle?.details) {
    return;
  }

  const details = vehicle?.details.split(/[;,]+/);

  return details.length > 10 ? (
    <>
      {details.slice(0, 10).map(d => (
        <div>{d}</div>
      ))}
      <Tooltip title={details.slice(10, details.length).join('\n')} placement={'right'}>
        <Tag>{t('Inspections.Table.CountMore', { count: details.length })}</Tag>
      </Tooltip>
    </>
  ) : (
    details.map(d => <div>{d}</div>)
  );
};

export const prepareDataForInnerTable = ({
  groupedItems = [],
  fleetIds = [],
  groupBy,
  localization,
  openEditModal,
  openInspectionModal,
  history,
  t,
  dispatch
}) => {
  let groupedItemsClone = [...groupedItems];
  if (groupBy === 'inspections' && !fleetIds?.includes(0)) {
    groupedItemsClone = groupedItems?.filter(veh =>
      fleetIds?.some(id => veh.fleetIds?.includes(id))
    );
  }
  return groupedItemsClone.map(item => {
    return {
      [INSPECTION_KEY.STATUS]: getInspectionStatus(item?.status),
      [INSPECTION_KEY.VEHICLE]: getVehicleName(item, groupBy),
      [INSPECTION_KEY.INSPECTED_BY]: (
        <span>
          {item?.inspectionBy?.firstName || ''} {item?.inspectionBy?.lastName || ''}
        </span>
      ),
      [INSPECTION_KEY.INSPECTION_DATE]: (
        <span>
          {item?.inspectionDate &&
            format(item?.inspectionDate.split('+')[0], localization.formats.time.formats.dby_imp)}
        </span>
      ),
      [INSPECTION_KEY.REPAIRED_BY]: <span>{item?.repairedBy || ''}</span>,
      [INSPECTION_KEY.REPAIR_DATE]: (
        <span>
          {item?.repairedDate &&
            format(moment(item?.repairedDate, 'DD-MM-YYYY'), localization.formats.time.formats.dby)}
        </span>
      ),
      [INSPECTION_KEY.DETAILS]: getVehicleDetails(item, t),
      [INSPECTION_KEY.LINKED_SCHEDULE]: <span>{getSchedule(item?.schedule)}</span>,
      [INSPECTION_KEY.ACTIONS]: (
        <ActionMenu
          items={getInspectionMenuItems({
            inspection: item,
            history,
            t,
            openEditModal,
            openInspectionModal
          })}
          data={item}
        />
      ),
      [INSPECTION_KEY.NOTES]: <span>{item?.notes || ''}</span>,
      key: item.inspectionId
    };
  });
};

const filterByStatus = (items, status) => {
  return items?.filter(item => item.status === status);
};

const mapTabToStatus = activeTab => {
  switch (activeTab) {
    case VEHICLE_INSPECTIONS_TABS.passed:
      return Status.PASS;
    case VEHICLE_INSPECTIONS_TABS.failed:
      return Status.FAIL;
    case VEHICLE_INSPECTIONS_TABS.inprogress:
      return Status.INPROGRESS;
    case VEHICLE_INSPECTIONS_TABS.pendingdriversignoff:
      return Status.REPAIRED;
    case VEHICLE_INSPECTIONS_TABS.closed:
      return Status.CLOSED;

    default:
      return VEHICLE_INSPECTIONS_TABS.all;
  }
};

export const filterInspections = ({
  inspections = [],
  filters = {
    activeTab: 'all',
    searchText: '',
    companyIds: [],
    fleetIds: [],
    typeIds: [],
    rowFilterIds: []
  },
  setExpandAll
}) => {
  const { activeTab, searchText, companyIds, fleetIds, typeIds, rowFilterIds } = filters;

  //Filter by activeTab
  let filteredInspections = inspections;
  if (activeTab !== VEHICLE_INSPECTIONS_TABS.all) {
    filteredInspections = inspections.map(inspection => {
      const status = mapTabToStatus(activeTab).toUpperCase();
      const groupedItems = filterByStatus(inspection?.groupedItems, status);

      if (groupedItems.length === 0) return null;

      const filteredItems = Object.assign({}, inspection);
      filteredItems.groupedItems = groupedItems;

      return filteredItems;
    });
  }

  const searchedInspections = filteredInspections.reduce((prev, curr) => {
    const searchedGroupedItems = curr?.groupedItems?.filter(e =>
      Object.values(e)
        .map(p => p)
        .join(' ')
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );

    return [
      ...prev,
      {
        ...curr,
        groupedItems: searchedGroupedItems
      }
    ];
  }, []);

  return (searchedInspections || filteredInspections).filter(inspection => {
    if (inspection?.groupedItems?.length === 0) return false;

    let isValid = true;
    // Filter by companies
    isValid = isValid && companyIds.includes(inspection?.item?.company?.id);

    // Filter by Inspection Name / Vehicle Name
    isValid = isValid && rowFilterIds.includes(inspection?.item?.id);

    // Filter by fleets
    if (!fleetIds.includes(0)) {
      isValid =
        isValid &&
        fleetIds.some(
          id =>
            inspection.item?.vehicle?.fleetIds && inspection.item?.vehicle?.fleetIds?.includes(id)
        );
    }
    // Filter by types
    if (!typeIds.includes(0)) {
      isValid =
        isValid &&
        (typeIds.includes('isDVIR')
          ? inspection?.item?.isDVIR
          : typeIds.includes('nonDVIR') && !inspection?.item?.isDVIR);
    }

    //expand all rows if search or filters are applied
    if (
      searchText ||
      !filters.companyIds?.includes(0) ||
      !filters.fleetIds?.includes(0) ||
      !filters.typeIds?.includes(0)
    ) {
      setExpandAll(true);
    }

    return isValid;
  });
};

export const getFilterLabel = ({ groupBy, t, all }) => {
  switch (groupBy) {
    case GROUP_BY.inspections:
      if (all) {
        return t('Inspections.AllInspections');
      }
      return t('Inspections.InspectionsCounter');
    case GROUP_BY.vehicles:
      if (all) {
        return t('Inspections.AllVehicles');
      }
      return t('Inspections.VehiclesCounter');
    default:
      return '';
  }
};
