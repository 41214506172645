import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'utils/api';
import { FETCH_WIFI, ADD_WIFI, UPDATE_WIFI, DELETE_WIFI } from '../actions';

export const fetchWifi = createAsyncThunk(FETCH_WIFI, async (arg = {}, { getState, dispatch }) => {
  const state = getState();
  const authKey = state?.user?.current?.auth?.key;
  const company_id = state?.companies?.current?.id;
  const companyKey = state.companies?.current.api_key;

  const response = await api.get('/wifi', {
    authKey,
    query: { direction: 'DOWN', company_id }
  });

  return { list: response.body, companyKey: companyKey };
});

export const addWifi = createAsyncThunk(ADD_WIFI, async (payload = {}, { getState, dispatch }) => {
  const state = getState();
  const authKey = state?.user?.current?.auth?.key;
  const company_id = state?.companies?.current?.id;
  const { body } = payload;

  const response = await api.post(
    `/wifi`,
    {
      authKey,
      query: { company_id }
    },
    body
  );

  // update wifi
  await dispatch(fetchWifi());

  return response.body;
});

export const updateWifi = createAsyncThunk(
  UPDATE_WIFI,
  async (payload = {}, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;
    const company_id = state?.companies?.current?.id;
    const { id, body } = payload;

    const response = await api.put(
      `/wifi/${id}`,
      {
        authKey,
        query: { company_id }
      },
      body
    );

    // update wifi
    await dispatch(fetchWifi());

    // Show toast
    return response.body;
  }
);

export const deleteWifi = createAsyncThunk(DELETE_WIFI, async (id, { getState, dispatch }) => {
  const state = getState();
  const authKey = state?.user?.current?.auth?.key;

  const response = await api.delete(`/wifi/${id}`, {
    authKey
  });

  // update wifi
  await dispatch(fetchWifi());

  return response.body;
});
