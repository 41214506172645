import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { Button, Checkbox } from 'antd';

import { MARKER_TYPE } from 'components/map/markers/constants';
import { GeofencesTypes } from './constants';
import { toTitleCase } from 'utils/strings';

import { EllipsisButton } from 'components/tables/EllipsisButton';
import { Tooltip } from 'components/ant';

import styles from './Fleets.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const cacheVehicles = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const cacheDevices = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const cacheGeofences = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const fleetNameCellRenderer = ({ dataKey, parent, rowIndex, rowData, canViewFleet }) => {
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      <div>
        {rowData.name !== 'No Fleet' && canViewFleet ? (
          <Link
            to={{
              pathname: `/settings/fleets/id/${rowData.id}`,
              state: { pageTitle: rowData.name }
            }}
          >
            {rowData.name}
          </Link>
        ) : (
          <span>{rowData.name}</span>
        )}
      </div>
    </CellMeasurer>
  );
};

export const companyCellRenderer = ({ dataKey, parent, rowIndex, rowData, companies }) => {
  const fleet = rowData;
  const company = companies.find(comp => fleet.company && comp.id === fleet.company.id);
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      <>{company && company.name}</>
    </CellMeasurer>
  );
};

export const carrierCellRenderer = ({ dataKey, parent, rowIndex, rowData }) => {
  const carrier = rowData.carrier;
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      <>{carrier && carrier.name + ' | ' + carrier.officialIdentifer}</>
    </CellMeasurer>
  );
};

export const markerTypeCellRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      <div>{toTitleCase(rowData.iconType || MARKER_TYPE.asset)}</div>
    </CellMeasurer>
  );
};

export const markerColorCellRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      {!rowData.colour ? (
        <span>Status</span>
      ) : (
        <span
          style={{
            display: 'inline-block',
            width: '15px',
            height: '15px',
            backgroundColor: rowData.colour
          }}
        />
      )}
    </CellMeasurer>
  );
};

export const associationsCellRenderer = ({ rowData, dataKey, parent, rowIndex, t }) => {
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Link
          to={{
            pathname: '/settings/vehicles',
            state: { fleetId: rowData.id }
          }}
        >
          {(rowData.vehicles || []).filter(i => i.id).length + ' ' + t('Vehicles.Vehicles')}
        </Link>
        /
        <Link
          to={{
            pathname: '/settings/devices/standalone',
            state: { fleetId: rowData.id }
          }}
        >
          {(rowData.devices || []).length + ' ' + t('Devices.Devices')}
        </Link>
        /
        <Link
          to={{
            pathname: '/settings/geofences',
            state: { fleetId: rowData.id }
          }}
        >
          {(rowData.geofences || []).length + ' ' + t('GeofencesFeature.Geofences')}
        </Link>
      </div>
    </CellMeasurer>
  );
};
export const ellipsisButtonCellRenderer = ({
  rowData,
  dataKey,
  typeOfEntityToDelete,
  hideEdit,
  hideDelete,
  enableRestoreAction,
  handleAction,
  handleDeleteAction
}) => {
  return (
    <EllipsisButton
      dataKey={dataKey}
      data={rowData}
      canUse="FLEET"
      path={'/settings/fleets/'}
      typeOfEntityToDelete={typeOfEntityToDelete}
      hideDelete={hideDelete}
      hideEdit={hideEdit}
      enableRestoreAction={enableRestoreAction}
      handleAction={handleAction}
      handleDeleteAction={handleDeleteAction}
    />
  );
};
export const nameCellRenderer = ({ rowData }) => {
  return <div>{rowData.name ? rowData.name : ''}</div>;
};

export const checkboxCellRenderer = ({ rowData, checkedItems }) => {
  return (
    <div>
      <Checkbox
        value={rowData.id}
        checked={
          checkedItems &&
          (checkedItems.includes(rowData.id) || checkedItems.some(i => i?.id === rowData.id))
        }
        onChange={() => {}}
      />
    </div>
  );
};

export const vehicleInFleetCellRenderer = ({ rowData, dataKey, parent, rowIndex, companies }) => {
  const fleets = rowData?.fleets.map(fleet => {
    const companyName = companies?.find(company => fleet?.companyId === company.id)?.name;
    return (
      <div key={`fleet-${fleet.id}`}>{`${fleet?.name || ''} ${
        companyName ? `(${companyName})` : ''
      }`}</div>
    );
  });
  if (!rowData.devices || rowData.fleets.length > rowData.devices.length) {
    return (
      <CellMeasurer
        cache={cacheVehicles}
        columnIndex={0}
        key={`cell-measurer-${dataKey}-${rowData.id}`}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div
          style={{
            padding: '15px 0'
          }}
        >
          {fleets}
        </div>
      </CellMeasurer>
    );
  }
  return (
    <div
      style={{
        padding: '15px 0'
      }}
    >
      {fleets}
    </div>
  );
};

export const devicesInVehicle = ({ rowData, dataKey, parent, rowIndex }) => {
  if (rowData.devices) {
    return (
      <CellMeasurer
        cache={cacheVehicles}
        columnIndex={0}
        key={dataKey}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '15px 0',
            boxSizing: 'border-box'
          }}
        >
          {rowData.devices.map((device, index) => {
            return (
              <div key={`device-${device.name}`}>
                <Link
                  to={`/settings/devices/id/${device.id}`}
                  style={
                    index !== rowData.devices.length - 1
                      ? { margin: '0 0 5px 0', color: '#096dd9' }
                      : { color: '#096dd9' }
                  }
                >
                  {device.name ? device.name : ''}
                </Link>
              </div>
            );
          })}
        </div>
      </CellMeasurer>
    );
  } else {
    return <div />;
  }
};

export const deviceInFleetCellRenderer = ({
  rowData,
  dataKey,
  parent,
  rowIndex,
  companies,
  fleets
}) => {
  const fleetsRenderer = rowData?.fleetInfo.map(fleetRenderer => {
    const fleetFullInfo = fleets.find(fleet => fleet.id === fleetRenderer.id);
    const companyName = companies?.find(company => fleetFullInfo?.company?.id === company.id)?.name;
    return (
      <div
        key={`fleet-${fleetRenderer.id}`}
        className={styles.overflowAuto}
      >{`${fleetRenderer?.name || ''} ${companyName ? `(${companyName})` : ''}`}</div>
    );
  });

  return (
    <CellMeasurer
      cache={cacheDevices}
      columnIndex={0}
      key={dataKey}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div>{fleetsRenderer}</div>
    </CellMeasurer>
  );
};

export const vehicleRegistrationCellRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
  return (
    <CellMeasurer
      cache={cacheVehicles}
      columnIndex={0}
      key={dataKey}
      parent={parent}
      rowIndex={rowIndex}
    >
      {rowData.registration}
    </CellMeasurer>
  );
};

export const typeCellRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
  return (
    <CellMeasurer
      cache={cacheVehicles}
      columnIndex={0}
      key={dataKey}
      parent={parent}
      rowIndex={rowIndex}
    >
      {rowData.type ? rowData.type.name : ''}
    </CellMeasurer>
  );
};

export const imeiCellRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      {rowData.imei ? rowData.imei : ''}
    </CellMeasurer>
  );
};

export const serialNoCellRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      {rowData.serialNumber ? rowData.serialNumber : ''}
    </CellMeasurer>
  );
};

export const geofenceTypeCellRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
  return <div>{rowData.type ? GeofencesTypes[rowData.type] : ''}</div>;
};

function InlineContentRenderer({ items, rowIndex, measure, ...props }) {
  const [showExpand, setShowExpand] = useState(false);
  const [contentList, setContentList] = useState(null);
  const { t } = useTranslation();
  const handleClick = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    setShowExpand(prev => !prev);
  };

  useEffect(() => {
    setContentList(null);
    setShowExpand(false);
  }, [items]);

  useEffect(() => {
    setContentList(prev => {
      const list = Array.from(prev || []);
      if (list.length === 0) {
        for (let i = 0; i < items?.length && i < 5; i++) {
          list.push(
            <p className={styles.fleetListItem} key={items[i].id}>
              {items[i].name}
            </p>
          );
        }
      }
      if (showExpand) {
        for (let i = 5; i < items?.length; i++) {
          list.push(
            <p className={styles.fleetListItem} key={items[i].id}>
              {items[i].name}
            </p>
          );
        }
      } else if (list.length > 5) {
        list.splice(5, list.length - 5);
      }
      return list;
    });
  }, [items, showExpand]);

  useEffect(() => {
    measure();
  }, [contentList]);

  return (
    <div>
      {contentList}
      {items?.length > 5 && (
        <Tooltip
          content={t(showExpand ? 'Common.Hide Content' : 'Common.Show More Content')}
          target={
            <Button
              className={styles.fleetListItem}
              id={BUTTON_IDS.fleetsExpandList}
              type="link"
              onClick={handleClick}
            >
              {!showExpand ? '+' : '-'}
              {items.length - 5}
            </Button>
          }
        />
      )}
    </div>
  );
}

export const geofenceFleetsCellRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
  return (
    <CellMeasurer
      cache={cacheGeofences}
      columnIndex={0}
      key={dataKey}
      parent={parent}
      rowIndex={rowIndex}
    >
      {({ measure }) => (
        <InlineContentRenderer items={rowData.fleets} rowIndex={rowIndex} measure={measure} />
      )}
    </CellMeasurer>
  );
};
