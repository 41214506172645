/* global google */
import React, { useEffect, useRef, useState, useMemo, forwardRef, memo } from 'react';
import { GoogleMap, withGoogleMap, TrafficLayer, Circle } from 'react-google-maps';
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import useDebounce from 'utils/hooks/useDebounce';
import { getPointsZoomLevel, setCurrentRegionBounds } from 'utils/geo';

import { useCan } from 'features/permissions';
import { CompanyConfigValue, useCompanyGeofenceProviders } from 'features/company/companySlice';
import { useDeviceUpdatesAll } from 'features/mqtt/mqttSlice';
import { getGeofencePoints, extendGeofences } from 'features/geofences/geofencesUtil';

import { VEHICLE_MARKER_LABEL_TYPES } from 'containers/Settings/user/Preferences/Preferences';
import { RouteToModal, useRouteTo } from 'containers/Tracking/RouteTo';

import GeofenceInfoWindow from './info-windows/GeofenceInfoWindow';
import TripInfoWindow from './info-windows/TripInfoWindow';
import { MapMenu, DroneViewControl, TripSummaryControl } from './controls';
import { VehicleMarker } from './markers/VehicleMarker';
import { LocationMarker } from './markers/LocationMarker';
import { EventMarker } from './markers/EventMarker';
import { ScorecardMarker } from './markers/ScorecardMarker';
import { ClusterMarker } from './markers/ClusterMarker';
import { TripInfoMarker } from './markers/TripInfoMarker';
import { Trip } from './routes/Trip';
import { DroneViewRoute } from './routes/DroneViewRoute';
import { Route } from './routes/Route';
import { LoadingOverlay, DroneViewLoadingOverlay } from './overlays';
import { GeofenceLayer } from './GeofencesLayer';

import './Map.scss';
import { GeofenceDrawer } from 'containers/Administration/Geofences/GeofenceDrawer';
import { useDispatch } from 'react-redux';
import { setUseEmptyGoogleMapKey } from 'features/appState/appStateSlice';

export const MapMode = {
  Devices: 'Devices',
  Trips: 'Trips',
  Trip: 'Trip',
  Location: 'Location',
  Geofence: 'Geofence',
  GeofenceMapView: 'Geofence Map View',
  DrawGeofence: 'Draw Geofence',
  EditGeofence: 'Edit Geofence',
  Drone: 'Drone',
  RouteTo: 'RouteTo',
  Scorecard: 'Scorecard',
  NearestVehicle: 'NearestVehicle',
  Events: 'Events'
};
export const GEOFENCES_ZOOMLEVEL_THRESHOLD = 11;

const Map = forwardRef(
  (
    {
      mapOptions,
      defaultMapType,
      drawingOptions,
      mode = MapMode.Devices,
      devices,
      deviceGpsUpdates,
      geofences,
      location,
      eventTypes,
      focusedDevice,
      focusedEvent,
      clickedDevice,
      selectedDeviceId,
      selectedTripSegment,
      selectedTripEvent,
      selectedReplay,
      tripInfoWindowData,
      routePathColor,
      draggableMarker = false,
      showIgnitionOnOffEventsForTrips = true,
      useDefaultLocationMarker = false,
      filterGeofencesByType = true,
      hideVehicleMenu = false,
      customizedMenu,
      circleSelectionConfig,
      selectedGeofenceId,
      limitGeofencesDisplay = true, //via zoom level
      currentRegion = {},
      nonBusinessCompanyConfig = false,
      isLoading = false,
      onDeviceFocused,
      onDeviceBlurred,
      onDeviceClicked,
      onDeviceSelected,
      onDeviceGpsUpdated,
      onEventFocused,
      onEventBlurred,
      onEventClicked,
      onEventInfoWindowClosed,
      onTripSegmentSelected,
      onSetSelectedReplay,
      onGeofenceDrawn,
      onGeofenceEdited,
      onIncludeExcludeEventClick,
      onZoomChanged,
      onDragEnd,
      onLocationMarked,
      onGeofenceSelected,
      onMapModeChanged,
      enableMapMenu = false,
      enableInfoWindowActions = true,
      enableVehicleClustering = false,
      enableGeofenceSuggest = false,
      showDroneViewControl = false,
      showExtraGeofenceParams = true,
      supportGeofenceControl = false,
      setZoomLevel,
      setMapCenter,
      hideVehicleIfNotLatestTrip = false,
      showDevice = false,
      ...props
    },
    ref
  ) => {
    const clusterOptions = {
      size: 10,
      zoom: 10,
      minSize: 6
    };

    const defaultMapOptions = {
      keyboardShortcuts: false,
      draggable: true,
      scrollwheel: true,
      defaultZoom: 2,
      minZoom: 1,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM
      },
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      scaleControl: false
    };

    const defaultDrawingOptions = {
      drawingControl: true,
      drawingMode: null,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.CIRCLE,
          google.maps.drawing.OverlayType.RECTANGLE,
          google.maps.drawing.OverlayType.POLYGON
        ]
      },
      circleOptions: {
        clickable: false,
        draggable: true,
        editable: true
      },
      rectangleOptions: {
        clickable: false,
        draggable: true,
        editable: true
      },
      polygonOptions: {
        clickable: false,
        draggable: true,
        editable: true
      }
    };

    const mapRef = useRef();
    const { t } = useTranslation();
    const can = useCan();

    const map = ref || mapRef;
    let mapBounds = map.current?.getBounds();
    const canSupportGeofenceControl = supportGeofenceControl;

    const [currentShape, setCurrentShape] = useState(null);

    const [showMapMenu, setShowMapMenu] = useState(enableMapMenu);
    const [showRouteToModal, setShowRouteToModal] = useState(false);

    const [selectedGeofence, setSelectedGeofence] = useState(null);
    const [showGeofenceInfoWindow, setShowGeofenceInfoWindow] = useState(false);
    const [geofenceInfoWindowPosition, setGeofenceInfoWindowPosition] = useState({
      lat: 0,
      lng: 0
    });

    const [center, setCenter] = useState();
    const [zoom, setZoom] = useState();
    const [mapType, setMapType] = useState(defaultMapType);
    const [mapMenuLocation, setMapMenuLocation] = useState();
    const [vehicleLabelType, setVehicleLabelType] = useState();
    const [isDroneViewLoading, setIsDroneViewLoading] = useState(false);
    const [isDroneViewActive, setIsDroneViewActive] = useState(false);
    const [isTrafficLayerActive, setIsTrafficLayerActive] = useState(false);
    const [clusters, setClusters] = useState([]);
    const [routeData, setRouteData] = useState(null);
    const [boundsTimestamp, setBoundsTimestamp] = useState(moment().valueOf());
    const [droneViewUpdates, setDroneViewUpdates] = useState(deviceGpsUpdates);
    const [geofenceConfiguration, setGeofenceConfiguration] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
      if (defaultMapType) {
        setMapType(defaultMapType);
      }
    }, [defaultMapType]);

    useEffect(() => setDroneViewUpdates(deviceGpsUpdates), [deviceGpsUpdates]);
    const routeToProps = useRouteTo(routeData);
    const debouncedBoundsTimestamp = useDebounce(boundsTimestamp, 300);
    const devicesUpdates = useDeviceUpdatesAll();

    const options = useMemo(() => {
      return {
        ...defaultMapOptions,
        ...mapOptions,
        ...(enableMapMenu && { mapTypeControl: false }),
        ...(enableMapMenu && mapType && { mapTypeId: mapType }),
        ...(mode === MapMode.Drone && { streetViewControl: false }),
        ...(mode === MapMode.Drone && { defaultZoom: 18 }),
        ...(mode === MapMode.Drone && { rotateControl: true })
      };
    }, [mapOptions, mode, enableMapMenu, mapType]);

    useEffect(() => {
      if (!map || !map.current) {
        return;
      }

      try {
        google.maps.event.addListener(
          map.current.getStreetView(),
          'visible_changed',
          onStreetViewVisibilityChanged
        );
      } catch (e) {
        console.warn(e);
        dispatch(setUseEmptyGoogleMapKey(true));
      }

      if (
        ![MapMode.DrawGeofence, MapMode.EditGeofence].includes(mode) ||
        (mode === MapMode.EditGeofence && (!geofences || !geofences.length))
      ) {
        currentShape && currentShape.setMap(null);
      }

      fitBounds();
    }, [
      mode,
      devices.length,
      geofences,
      selectedTripSegment,
      droneViewUpdates,
      mapOptions?.center,
      dispatch
    ]);

    // useEffect triggered when the map has changed its bounds or we have device updates (a vehicle has moved).
    // Using debounce here to prevent unnecessary recalculations
    useEffect(() => {
      if (mapMenuLocation) {
        // console.debug('Map *** skip cluster');
        return;
      }

      const clusters = getClustersArray(mapBounds);
      setClusters(clusters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedBoundsTimestamp, devicesUpdates]);

    useEffect(() => {
      if (mapMenuLocation) {
        // console.debug('Map *** skip geofence');
        return;
      }

      if (selectedGeofenceId && geofences) {
        const geofence = geofences.find(g => g.id === selectedGeofenceId);
        if (geofence) {
          openGeofenceInfoWindow(geofence, null);
        }
      }
    }, [selectedGeofenceId, geofences]);

    // adjust viewport (center & zoom level) for map mode
    const fitBounds = () => {
      // console.debug('Map - mapMode', mode);
      // console.debug('Map - devices', devices);
      // console.debug('Map - selectedDeviceId', selectedDeviceId);
      // console.debug('Map - center', mapOptions?.center);
      // console.debug('Map - zoom', mapOptions?.zoom);
      // console.debug('Map - mapMenuLocation', mapMenuLocation);

      if (mapOptions?.center || mapMenuLocation) {
        // console.debug('Map *** skip fitbounds');
        return; // skip fitbounds
      }

      const bounds = new google.maps.LatLngBounds();

      switch (mode) {
        case MapMode.Trips:
        case MapMode.Trip:
          if (selectedTripSegment) {
            // selected trip
            // console.debug('Map - selectedTripSegment', selectedTripSegment);
            if (
              nonBusinessCompanyConfig &&
              selectedTripSegment?.attr === CompanyConfigValue.Private &&
              currentRegion?.geocode
            ) {
              // For Non-Business trips, show default region bounds
              setCurrentRegionBounds(currentRegion, bounds);
            }

            const isLatestTrip = devices[0]?.trips?.length - 1 === selectedTripSegment?.index;
            if (isLatestTrip) {
              // for the current trip extend the bounds with the vehicles position
              bounds.extend(
                new google.maps.LatLng(
                  devices[0].deviceStats.gps.Lat,
                  devices[0].deviceStats.gps.Lng
                )
              );
            }

            selectedTripSegment.IgnOnGPS &&
              selectedTripSegment.IgnOnGPS.Lat &&
              selectedTripSegment.IgnOnGPS.Lng &&
              bounds.extend(
                new google.maps.LatLng(
                  selectedTripSegment.IgnOnGPS.Lat,
                  selectedTripSegment.IgnOnGPS.Lng
                )
              );
            selectedTripSegment.IgnOffGPS &&
              selectedTripSegment.IgnOffGPS.Lat &&
              selectedTripSegment.IgnOffGPS.Lng &&
              bounds.extend(
                new google.maps.LatLng(
                  selectedTripSegment.IgnOffGPS.Lat,
                  selectedTripSegment.IgnOffGPS.Lng
                )
              );
            selectedTripSegment &&
              selectedTripSegment.replay &&
              selectedTripSegment.replay.map(point =>
                bounds.extend(new google.maps.LatLng(point.Lat, point.Lng))
              );
            selectedTripSegment &&
              selectedTripSegment.events &&
              selectedTripSegment.events
                .filter(
                  event =>
                    eventTypes.includes(event.eventType + event.subType) &&
                    event.GPS &&
                    event.GPS.Lat &&
                    event.GPS.Lat !== 0 &&
                    event.GPS.Lng &&
                    event.GPS.Lng !== 0
                )
                .map(event => bounds.extend(new google.maps.LatLng(event.GPS.Lat, event.GPS.Lng)));
          } else if (devices && devices[0]) {
            // trips
            if (
              nonBusinessCompanyConfig &&
              devices[0].vehicleStats?.attr === CompanyConfigValue.Private
            ) {
              // for non business vehicle, do not expose the location but show the default region bounds
              setCurrentRegionBounds(currentRegion, bounds);
            } else {
              bounds.extend(
                new google.maps.LatLng(
                  devices[0].deviceStats.gps.Lat,
                  devices[0].deviceStats.gps.Lng
                )
              );
            }
            if (devices[0].trips) {
              devices[0].trips.forEach(trip => {
                trip.IgnOnGPS &&
                  trip.IgnOnGPS.Lat &&
                  trip.IgnOnGPS.Lng &&
                  bounds.extend(new google.maps.LatLng(trip.IgnOnGPS.Lat, trip.IgnOnGPS.Lng));
                trip.IgnOffGPS &&
                  trip.IgnOffGPS.Lat &&
                  trip.IgnOffGPS.Lng &&
                  bounds.extend(new google.maps.LatLng(trip.IgnOffGPS.Lat, trip.IgnOffGPS.Lng));
              });
            }
          }
          break;

        case MapMode.Drone:
          droneViewUpdates && droneViewUpdates.length
            ? bounds.extend(
                new google.maps.LatLng(
                  droneViewUpdates[droneViewUpdates.length - 1].lat,
                  droneViewUpdates[droneViewUpdates.length - 1].lng
                )
              ) // follow vehicle
            : // ? droneViewUpdates.map(update => {
              //     bounds.extend(new google.maps.LatLng(update.lat, update.lng));
              //   }) // follow route
              devices &&
              devices[0] &&
              bounds.extend(
                new google.maps.LatLng(
                  devices[0].deviceStats.gps.Lat,
                  devices[0].deviceStats.gps.Lng
                )
              );
          break;

        case MapMode.Location:
          location && bounds.extend(new google.maps.LatLng(location.lat, location.lng));
          break;

        case MapMode.Scorecard:
        case MapMode.RouteTo:
        case MapMode.Geofence:
        case MapMode.EditGeofence:
          location && bounds.extend(new google.maps.LatLng(location.lat, location.lng));
          extendGeofences(geofences, point =>
            bounds.extend(new google.maps.LatLng(point.Lat, point.Lng))
          );
          break;

        case MapMode.Events:
          if (devices && devices[0]) {
            // Take device into account for bounds if not in business private mode
            if (
              nonBusinessCompanyConfig &&
              devices[0].vehicleStats?.attr === CompanyConfigValue.Private
            ) {
              // for non business vehicle, do not expose the location but show the default region bounds
              setCurrentRegionBounds(currentRegion, bounds);
            } else {
              bounds.extend(
                new google.maps.LatLng(
                  devices[0].deviceStats.gps.Lat,
                  devices[0].deviceStats.gps.Lng
                )
              );
            }

            // Take all trip data into account for bounds (replay tps points and non filtered device events)
            devices[0].trips &&
              devices[0].trips.forEach(trip => {
                trip.replay &&
                  trip.replay.map(point =>
                    bounds.extend(new google.maps.LatLng(point.Lat, point.Lng))
                  );

                trip.events &&
                  trip.events
                    .filter(
                      event =>
                        eventTypes.includes(event.eventType + event.subType) &&
                        event.GPS &&
                        event.GPS.Lat &&
                        event.GPS.Lat !== 0 &&
                        event.GPS.Lng &&
                        event.GPS.Lng !== 0
                    )
                    .map(event =>
                      bounds.extend(new google.maps.LatLng(event.GPS.Lat, event.GPS.Lng))
                    );
              });
          }
          break;

        case MapMode.Devices:
        default:
          // console.debug('Map *** fitbounds MapMode.Devices');
          if (devices) {
            if (selectedDeviceId) {
              const selectedDevice = devices.find(device => device.id === selectedDeviceId);
              selectedDevice &&
                bounds.extend(
                  new google.maps.LatLng(
                    selectedDevice.deviceStats.gps.Lat,
                    selectedDevice.deviceStats.gps.Lng
                  )
                );
            } else {
              devices.forEach(device => {
                bounds.extend(
                  new google.maps.LatLng(device.deviceStats.gps.Lat, device.deviceStats.gps.Lng)
                );
              });
            }
          }
          break;
      }

      if (bounds.getSouthWest().lat() !== 1 && bounds.getNorthEast().lat() !== -1) {
        map.current.fitBounds(bounds);
      }
    };

    const handleBoundsChanged = () => {
      // console.debug('Map - handleBoundsChanged');
      if (drawingOptions?.drawingMode === null || drawingOptions?.drawingMode === undefined) {
        setBoundsTimestamp(moment().valueOf());
      }
    };

    const handleZoomChanged = () => {
      // console.debug('Map - handleZoomChanged');
      onZoomChanged && onZoomChanged();
    };

    const handleDragEnd = () => {
      // console.debug('Map - handleDragEnd');
      onDragEnd && onDragEnd();
    };

    const handleLocationCleared = () => {
      handleLocationSelected(null, null);
    };

    const handleLocationSelected = (point, zoom) => {
      // console.debug('Map - onLocationSelected', point, zoom);
      if (typeof point?.lat === 'function') {
        point = { lat: point.lat(), lng: point.lng() };
      }
      setMapMenuLocation(point);
      setCenter(point);
      setZoom(zoom);

      if (setZoomLevel) {
        setZoomLevel(zoom);
      }

      if (setMapCenter) {
        setMapCenter(point);
      }
    };

    const handleLocationDragEnd = point => {
      // console.debug('Map - handleLocationDragEnd', point);
      point = { lat: point.latLng.lat(), lng: point.latLng.lng() };
      handleLocationSelected(point);
    };

    const getClustersArray = bounds => {
      if (!bounds) {
        return [];
      }
      const clusters = [];

      // determine the limits of the bounds
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      const north = ne.lat();
      const south = sw.lat();
      const west = sw.lng();
      const east = ne.lng();

      // determine the distance north - south, east - west
      const northSouthDiff = north - south;
      const eastWestDiff = east - west > 0 ? east - west : 360 + (east - west);

      // calculate the distance between 2 neighbouring clusters (north - south -> stepNS, east - west -> stepEW)
      const stepNS = northSouthDiff / clusterOptions.size;
      const stepEW = eastWestDiff / clusterOptions.size;

      // position clusters on map
      for (let i = 1; i <= clusterOptions.size; i++) {
        for (let j = 1; j <= clusterOptions.size; j++) {
          clusters.push({
            lat: south + stepNS * i,
            lng: west + stepEW * j,
            markers: 0,
            devicesPositions: [],
            devices: []
          });
        }
      }

      // go through each device and add it to its corresponding cluster on the map
      devices.forEach(device => {
        const deviceId = device?.id;
        const devicePosition = devicesUpdates[deviceId]
          ? {
              lat: devicesUpdates[deviceId].position.Lat,
              lng: devicesUpdates[deviceId].position.Lng
            }
          : {
              lat: device?.deviceStats?.gps.Lat,
              lng: device?.deviceStats?.gps.Lng
            };

        // given the current position, determine the closest cluster for each vehicle/device
        const closestClusterIndex = clusters.findIndex(
          cluster =>
            Math.abs(cluster.lat - devicePosition.lat) <= stepNS / 2 &&
            Math.abs(cluster.lng - devicePosition.lng) <= stepEW / 2
        );
        if (closestClusterIndex && clusters[closestClusterIndex]) {
          clusters[closestClusterIndex].markers += 1;
          clusters[closestClusterIndex].devicesPositions.push(devicePosition);
          clusters[closestClusterIndex].devices.push(device); // this is helpful for clusters with less than clusterOptions.minSize
        }
      });

      // refine the position of the cluster. Calculate the center of its devices positions and set it as the position for the cluster
      clusters.forEach(cluster => {
        const clusterBounds = new google.maps.LatLngBounds();
        cluster.devicesPositions.forEach(position => {
          clusterBounds.extend(new google.maps.LatLng(position));
        });
        cluster.lat = clusterBounds.getCenter().lat();
        cluster.lng = clusterBounds.getCenter().lng();
      });

      return clusters;
    };

    const handleClusterClick = center => {
      const currentZoom = map?.current?.getZoom() || 0;
      setZoomLevel(currentZoom + 2);
      setMapCenter(center);
    };

    const convertBoundsToLatLng = bounds => {
      return {
        lat: {
          north: bounds.getNorthEast().lat(),
          south: bounds.getSouthWest().lat()
        },
        lng: {
          west: bounds.getSouthWest().lng(),
          east: bounds.getNorthEast().lng()
        }
      };
    };

    const convertBoundsToPoints = bounds => {
      let latLng = convertBoundsToLatLng(bounds);

      return [
        { Lat: latLng.lat.north, Lng: latLng.lng.west },
        { Lat: latLng.lat.north, Lng: latLng.lng.east },
        { Lat: latLng.lat.south, Lng: latLng.lng.east },
        { Lat: latLng.lat.south, Lng: latLng.lng.west }
      ];
    };

    const convertCircleToPoints = (center, radius, numberOfPoints) => {
      let points = [],
        increment = 360 / numberOfPoints,
        degrees = 0;

      for (let i = 0; i < numberOfPoints; ++i, degrees += increment) {
        points.push(google.maps.geometry.spherical.computeOffset(center, radius, degrees));
      }

      return points;
    };

    const onShapeDrawn = e => {
      currentShape && currentShape.setMap(null);

      let shape = e.overlay;
      shape.type = e.type;

      switch (shape.type) {
        case 'polygon':
          shape.points = shape
            .getPath()
            .getArray()
            .map(point => {
              return {
                Lat: point.lat(),
                Lng: point.lng()
              };
            });
          break;
        case 'circle':
          shape.points = convertCircleToPoints(
            new google.maps.LatLng(shape.getCenter().lat(), shape.getCenter().lng()),
            shape.radius,
            360
          ).map(point => {
            return {
              Lat: point.lat(),
              Lng: point.lng()
            };
          });
          break;
        case google.maps.drawing.OverlayType.MARKER:
          if (onLocationMarked) {
            onLocationMarked(shape);
          }
          return;
        case 'rectangle':
        default:
          shape.points = convertBoundsToPoints(shape.getBounds());
          break;
      }

      setCurrentShape(shape);
      onGeofenceDrawn && onGeofenceDrawn(shape);
    };

    const onShapeEdited = (shape, style) => {
      onGeofenceEdited && onGeofenceEdited(shape, style);
    };

    const onMapTypeSelected = mapType => {
      setMapType(mapType);
    };

    const onMapLayerToggled = (layer, isActive) => {
      if (layer === 'traffic') {
        setIsTrafficLayerActive(isActive);
      }
    };

    const onVehicleLabelTypeSelected = labelType => {
      setVehicleLabelType(labelType || VEHICLE_MARKER_LABEL_TYPES.vehicleNameDriverName);
    };

    const showLabel = (devices, device) => {
      return devices.length === 1 || device.id === focusedDevice;
    };

    const onStreetViewVisibilityChanged = () => {
      const isStreetViewVisible = map?.current?.getStreetView()?.getVisible();
      setShowMapMenu(enableMapMenu && !isStreetViewVisible);
    };

    const openGeofenceInfoWindow = (geofence, event) => {
      if (!geofence?.name) {
        return;
      }
      setSelectedGeofence(geofence);
      const geofencePoints = getGeofencePoints(geofence, true);
      if (mode === MapMode.GeofenceMapView) {
        const bounds = new google.maps.LatLngBounds();
        geofencePoints?.forEach?.(point =>
          bounds.extend(new google.maps.LatLng(point.Lat, point.Lng))
        );

        map.current?.fitBounds(bounds, 200);

        if (onGeofenceSelected) {
          onGeofenceSelected(geofence.id);
        }

        if (!event) {
          if (geofencePoints?.length > 0) {
            const point = geofencePoints.reduce((northP, currP) =>
              northP.Lat > currP.Lat ? northP : currP
            );
            event = {
              latLng: new google.maps.LatLng(point.Lat, point.Lng)
            };
          } else {
            event = {
              latLng: bounds.getCenter()
            };
          }
        }
      }
      setShowGeofenceInfoWindow(true);
      setGeofenceInfoWindowPosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      });
    };

    const onRouteToClicked = device => {
      setShowRouteToModal(true);
      setRouteData(device);
    };

    const cancelRouteTo = () => {
      setShowRouteToModal(false);
      setRouteData(null);
    };

    const showCreateGeofenceDrawer = () => {
      setGeofenceConfiguration({
        title: t('GeofencesFeature.AddNewGeofence'),
        action: 'add',
        visible: true
      });
    };

    return (
      <>
        <GoogleMap
          ref={map}
          defaultCenter={
            location
              ? location
              : {
                  lat: 37.7576948,
                  lng: -122.4726192
                }
          }
          defaultOptions={defaultMapOptions}
          options={options}
          mapTypeId={mapType}
          center={center}
          zoom={zoom}
          onZoomChanged={handleZoomChanged}
          onDragEnd={handleDragEnd}
          onBoundsChanged={handleBoundsChanged}
          {...props}
        >
          {showMapMenu && (
            <MapMenu
              mapRef={map}
              defaultMapType={defaultMapType}
              enableGeofenceSuggest={enableGeofenceSuggest}
              hideVehicleMenu={hideVehicleMenu}
              onSearchLocationSelected={handleLocationSelected}
              onSearchLocationCleared={handleLocationCleared}
              onMapTypeSelected={onMapTypeSelected}
              onMapLayerToggled={onMapLayerToggled}
              onCreateGeofenceSelected={
                supportGeofenceControl && canSupportGeofenceControl
                  ? showCreateGeofenceDrawer
                  : null
              }
              onVehicleLabelSelected={onVehicleLabelTypeSelected}
            />
          )}

          {[MapMode.NearestVehicle].includes(mode) && customizedMenu}
          {isTrafficLayerActive && <TrafficLayer autoUpdate />}

          {showGeofenceInfoWindow && (
            <GeofenceInfoWindow
              geofence={selectedGeofence}
              position={geofenceInfoWindowPosition}
              showInfoWindowActions={enableInfoWindowActions}
              onGeofenceActionClicked={setGeofenceConfiguration}
              supportGeofenceControl={supportGeofenceControl}
              onClose={() => {
                setShowGeofenceInfoWindow(false);
                setSelectedGeofence(null);
              }}
              showExtraGeofenceParams={showExtraGeofenceParams}
            />
          )}

          {([MapMode.Devices, MapMode.Geofence, MapMode.NearestVehicle].includes(mode) ||
            showDevice) &&
            devices &&
            (enableVehicleClustering && options?.zoom < clusterOptions.zoom
              ? clusters.map((cluster, index) =>
                  cluster.markers < clusterOptions.minSize ? (
                    cluster.devices
                      // .filter(device => !clickedDevice || clickedDevice === device.id)
                      .map(device => (
                        <VehicleMarker
                          key={device.id}
                          device={device}
                          // mapBounds={mapBounds}
                          showInfoWindow={clickedDevice === device.id}
                          showInfoWindowActions={enableInfoWindowActions}
                          showLabel={showLabel(devices, device)}
                          labelType={vehicleLabelType}
                          onFocused={onDeviceFocused}
                          onBlurred={onDeviceBlurred}
                          onClicked={onDeviceClicked}
                          onDoubleClicked={onDeviceSelected}
                          onRouteToClicked={onRouteToClicked}
                        />
                      ))
                  ) : (
                    <ClusterMarker
                      key={`${cluster.lat}${cluster.lng}${index}`}
                      cluster={cluster}
                      onClusterClick={handleClusterClick}
                    />
                  )
                )
              : devices
                  // .filter(device => !clickedDevice || clickedDevice === device.id)
                  .map(device => (
                    <VehicleMarker
                      key={device.id}
                      device={device}
                      // mapBounds={mapBounds}
                      showInfoWindow={clickedDevice === device.id}
                      showInfoWindowActions={enableInfoWindowActions}
                      showLabel={showLabel(devices, device)}
                      labelType={vehicleLabelType}
                      onFocused={onDeviceFocused}
                      onBlurred={onDeviceBlurred}
                      onClicked={onDeviceClicked}
                      onDoubleClicked={onDeviceSelected}
                      onRouteToClicked={onRouteToClicked}
                    />
                  )))}

          {mode === MapMode.Trips &&
            // Prevent showing Trips, Markers, Events for Non-Business trips
            (nonBusinessCompanyConfig &&
            selectedTripSegment?.attr === CompanyConfigValue.Private ? (
              <></>
            ) : (
              <>
                {selectedTripSegment &&
                  devices &&
                  devices[0] &&
                  devices[0].trips &&
                  devices[0].trips
                    .filter(
                      trip =>
                        trip.index >= selectedTripSegment.index - 1 &&
                        trip.index <= selectedTripSegment.index + 1
                    )
                    .map((trip, index) => (
                      <Trip
                        key={trip.id ? trip.id : index}
                        trip={trip}
                        cameraDeviceId={devices[0].cameraDeviceId}
                        isSelected={selectedTripSegment && selectedTripSegment.id === trip.id}
                        onSelected={onTripSegmentSelected}
                        selectedTripSegment={selectedTripSegment}
                        selectedReplay={selectedReplay}
                        onSetSelectedReplay={onSetSelectedReplay}
                        showInfoWindowTrigger={['click']}
                        showIgnitionOnOffEvents={showIgnitionOnOffEventsForTrips}
                      />
                    ))}
                {devices &&
                  devices[0] &&
                  !(
                    nonBusinessCompanyConfig &&
                    devices[0]?.vehicleStats?.attr === CompanyConfigValue.Private
                  ) && (
                    <VehicleMarker
                      device={devices[0]}
                      labelType={vehicleLabelType}
                      showLabel={true}
                      mapBounds={mapBounds}
                    />
                  )}
                {selectedTripSegment &&
                  selectedTripSegment.events &&
                  selectedTripSegment.events
                    .filter(event => eventTypes.includes(event.eventType + event.subType))
                    .map(event => (
                      <EventMarker
                        key={event.id}
                        event={event}
                        focusedEvent={focusedEvent}
                        selectedTripEvent={selectedTripEvent}
                        onClicked={onEventClicked}
                        onSelected={onDeviceSelected}
                        onFocused={onEventFocused}
                        onBlurred={onEventBlurred}
                        onCloseClick={onEventInfoWindowClosed}
                        can={can}
                        trip={selectedTripSegment}
                      />
                    ))}
                {selectedTripEvent &&
                  selectedTripSegment?.events &&
                  !selectedTripSegment.events.includes(selectedTripEvent) &&
                  eventTypes.includes(selectedTripEvent.eventType + selectedTripEvent.subType) && (
                    <EventMarker
                      key={selectedTripEvent.id + 'eoi'}
                      event={selectedTripEvent}
                      focusedEvent={focusedEvent}
                      selectedTripEvent={selectedTripEvent}
                      onClicked={onEventClicked}
                      onSelected={onDeviceSelected}
                      onFocused={onEventFocused}
                      onBlurred={onEventBlurred}
                      onCloseClick={onEventInfoWindowClosed}
                      can={can}
                      trip={selectedTripSegment}
                    />
                  )}
                {tripInfoWindowData && (
                  <>
                    <TripInfoMarker
                      position={{
                        lat: tripInfoWindowData.Lat,
                        lng: tripInfoWindowData.Lng
                      }}
                      isSelected={false}
                    />
                    <TripInfoWindow
                      data={tripInfoWindowData}
                      position={{
                        lat: tripInfoWindowData.Lat,
                        lng: tripInfoWindowData.Lng
                      }}
                    />
                  </>
                )}
              </>
            ))}

          {mode === MapMode.Events && (
            // Events mode shows all trips segments and all events on the device for selected date range
            <>
              {devices &&
                devices[0] &&
                (!hideVehicleIfNotLatestTrip ||
                  devices[0].deviceStats?.tripId ===
                    devices[0].trips?.[devices[0].trips?.length - 1]?.tripId) && (
                  <VehicleMarker
                    device={devices[0]}
                    labelType={vehicleLabelType}
                    showLabel={true}
                    mapBounds={mapBounds}
                  />
                )}
              {devices &&
                devices[0] &&
                devices[0].trips &&
                devices[0].trips.map((trip, index) =>
                  nonBusinessCompanyConfig && trip?.attr === CompanyConfigValue.Private ? (
                    <></>
                  ) : (
                    <>
                      {trip.events &&
                        trip.events
                          .filter(event => eventTypes.includes(event.eventType + event.subType))
                          .map(event => (
                            <EventMarker
                              key={event.id}
                              event={event}
                              focusedEvent={focusedEvent}
                              selectedTripEvent={selectedTripEvent}
                              onClicked={onEventClicked}
                              onSelected={onDeviceSelected}
                              onFocused={onEventFocused}
                              onBlurred={onEventBlurred}
                              onCloseClick={onEventInfoWindowClosed}
                              can={can}
                              trip={trip}
                            />
                          ))}
                      <Trip
                        key={trip.id ? trip.id : index}
                        trip={trip}
                        cameraDeviceId={devices[0].cameraDeviceId}
                        isSelected={true}
                        selectedReplay={selectedReplay}
                        onSetSelectedReplay={onSetSelectedReplay}
                        showInfoWindowTrigger={['click']}
                        showIgnitionOnOffEvents={showIgnitionOnOffEventsForTrips}
                      />
                    </>
                  )
                )}
              {tripInfoWindowData && (
                <>
                  <TripInfoMarker
                    position={{
                      lat: tripInfoWindowData.Lat,
                      lng: tripInfoWindowData.Lng
                    }}
                    isSelected={false}
                  />
                  <TripInfoWindow
                    data={tripInfoWindowData}
                    position={{
                      lat: tripInfoWindowData.Lat,
                      lng: tripInfoWindowData.Lng
                    }}
                  />
                </>
              )}
            </>
          )}

          {[MapMode.DrawGeofence, MapMode.EditGeofence, MapMode.NearestVehicle].includes(mode) && (
            <DrawingManager
              defaultOptions={defaultDrawingOptions}
              options={{
                ...defaultDrawingOptions,
                ...drawingOptions
              }}
              onOverlayComplete={onShapeDrawn}
            />
          )}

          {[
            MapMode.Location,
            MapMode.Devices,
            MapMode.Trips,
            MapMode.Trip,
            MapMode.Geofence,
            MapMode.DrawGeofence,
            MapMode.EditGeofence,
            MapMode.RouteTo,
            MapMode.NearestVehicle
          ].includes(mode) &&
            (mapMenuLocation || location) && (
              <LocationMarker
                location={mapMenuLocation || location}
                draggable={draggableMarker}
                onDragEnd={handleLocationDragEnd}
                useDefaultLocationMarker={useDefaultLocationMarker}
              />
            )}

          {[MapMode.Scorecard].includes(mode) && selectedTripEvent && (
            <ScorecardMarker
              location={location}
              clickable={true}
              onSelected={onEventClicked}
              enableInfoWindowActions={enableInfoWindowActions}
              event={selectedTripEvent}
              onIncludeExcludeEventClick={onIncludeExcludeEventClick}
            />
          )}

          {[
            MapMode.Devices,
            MapMode.Trips,
            MapMode.Trip,
            MapMode.Geofence,
            MapMode.EditGeofence,
            MapMode.GeofenceMapView,
            MapMode.Scorecard,
            MapMode.Events
          ].includes(mode) &&
            geofences &&
            ([MapMode.Geofence, MapMode.EditGeofence, MapMode.GeofenceMapView].includes(mode) ||
              map?.current?.getZoom() >= GEOFENCES_ZOOMLEVEL_THRESHOLD) && (
              <GeofenceLayer
                geofences={geofences}
                openGeofenceInfoWindow={openGeofenceInfoWindow}
                selectedGeofence={selectedGeofence}
                mapMode={mode}
                onShapeEdited={onShapeEdited}
                mapBounds={mapBounds}
                boundsTimestamp={debouncedBoundsTimestamp}
              />
            )}

          {[MapMode.Trip, MapMode.Geofence].includes(mode) && selectedTripSegment && (
            <Trip trip={selectedTripSegment} isSelected={true} pathColor={routePathColor} />
          )}

          {[MapMode.Drone].includes(mode) && devices && devices[0] && (
            <>
              <DroneViewRoute device={devices[0]} path={droneViewUpdates} />
              <DroneViewLoadingOverlay isLoading={isDroneViewLoading} />
            </>
          )}

          {[MapMode.Trips, MapMode.Trip, MapMode.Drone, MapMode.Events].includes(mode) &&
            showDroneViewControl && (
              <>
                <DroneViewControl
                  device={devices[0]}
                  onDroneViewGpsUpdated={deviceGpsUpdates => {
                    setDroneViewUpdates(deviceGpsUpdates);
                    onDeviceGpsUpdated && onDeviceGpsUpdated(deviceGpsUpdates);
                  }}
                  onDroneViewToggled={isActive => {
                    setIsDroneViewActive(isActive);
                    onMapModeChanged && onMapModeChanged(isActive ? MapMode.Drone : MapMode.Trips);
                  }}
                  onIsDroneViewLoadingChanged={isLoading => {
                    setIsDroneViewLoading(isLoading);
                  }}
                />
                {!isDroneViewActive && selectedTripSegment && (
                  <TripSummaryControl device={devices[0]} selectedTrip={selectedTripSegment} />
                )}
              </>
            )}

          {[MapMode.NearestVehicle, MapMode.RouteTo].includes(mode) && selectedTripSegment && (
            <Route
              origin={selectedTripSegment.origin}
              destination={selectedTripSegment.destination}
              isSelected={true}
              isDirectionSvcRoute={true}
              pathColor={routePathColor}
            />
          )}

          {circleSelectionConfig && <Circle {...circleSelectionConfig} />}
        </GoogleMap>

        <LoadingOverlay isLoading={isLoading} />

        <RouteToModal
          title={t('Tracking.RouteTo')}
          isOpen={showRouteToModal}
          data={routeData}
          handleCancel={cancelRouteTo}
          {...routeToProps}
        />
        {canSupportGeofenceControl && (
          <GeofenceDrawer
            title={geofenceConfiguration?.title}
            action={geofenceConfiguration?.action}
            open={geofenceConfiguration?.visible === true}
            geofence={geofenceConfiguration?.geofence}
            mapOption={options}
            devices={devices}
            onGeofenceDelete={() => {
              setShowGeofenceInfoWindow(false);
              setSelectedGeofence(null);
              handleLocationCleared();
            }}
            onClose={value => {
              if (value) {
                handleLocationSelected(
                  {
                    lat: value.centroid?.lat,
                    lng: value.centroid?.lng
                  },
                  getPointsZoomLevel({
                    points: value.shape.points,
                    mapDim: {
                      height: mapRef.current?.getDiv().clientHeight,
                      width: mapRef.current?.getDiv().clientWidth
                    }
                  })
                );
              }

              setGeofenceConfiguration(prev => ({
                ...prev,
                visible: false
              }));
            }}
          />
        )}
      </>
    );
  }
);

Map.defaultProps = {
  devices: [],
  geofences: []
};

export default memo(withGoogleMap(Map));
