import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import InfoRow from 'components/form/info-row/InfoRow';
import { Input } from 'components/ant';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';

import { renderAssociationsForViewPage } from './utils/helpers';
import { PATHS, VIEW_PAGE_LABEL_WIDTH, DEFAULT_STATUS } from './utils/constants';

import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';
import { deleteWifi, useIsFetching } from 'features/wifi';
import { getIDFromPathname } from 'utils/methods';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';

import styles from './Wifi.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const WifiView = ({ wifi, subCompanies, fleets }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const id = getIDFromPathname(window.location.pathname);
  const dispatch = useDispatch();
  const localization = useLocalization();
  //state
  const [showPassword, setShowPassword] = useState(false);
  //variables
  const [deleteAction, setDeleteAction] = useState(false);
  const wifiData = wifi.find(wifi => wifi.id === parseInt(id, 10));
  const wifiCompany = subCompanies.find(comp => comp.id === wifiData?.company?.id);
  const wifiFleets = fleets.filter(fleet => wifiData?.fleetIds?.includes(fleet.id));
  const companyAssoc = subCompanies.find(c => wifiData?.companyIds?.includes(c.id));
  const isFetchingWifi = useIsFetching();

  useRedirectToMainFeaturePageOnCompanyChange('/settings/wifi');

  //methods
  const handleChangeShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleError = useCallback(
    err => {
      if (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err
          })
        );
      }
      if (history.location.pathname !== PATHS.WIFI_DEFAULT) {
        history.replace(PATHS.WIFI_DEFAULT);
      }
    },
    [history, dispatch]
  );

  const handleEditWifi = () => {
    history.push(`${PATHS.EDIT_WIFI}/${id}`, { data: { ...wifiData } });
  };

  const handleDeleteWifi = () => async () => {
    try {
      setDeleteAction(true);
      await dispatch(deleteWifi(wifiData.id));
      dispatch(
        openToast({
          type: ToastType.Success,
          message: `${t(`WiFi.deleteSuccess`)} ${wifiData.name}!`
        })
      );
      history.goBack();
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: err?.message
        })
      );
    }
  };

  useEffect(() => {
    if (
      !deleteAction &&
      (id <= 0 || isNaN(id) || (!isFetchingWifi && !wifi?.some(w => w.id === id)))
    ) {
      handleError(t('Common.Invalid Request ID'));
    }
  }, [t, id, handleError, wifi, isFetchingWifi, deleteAction]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPageTitle(wifiData?.name));
  }, [wifiData?.name, dispatch]);

  return (
    <>
      <ViewHeaderWrapper
        data={{ ...wifiData, status: DEFAULT_STATUS, entityName: AUDIT_ENTITY.WIFI }}
        editPath={`${PATHS.EDIT_WIFI}/${id}`}
        auditPath={`/settings/wifi/audit/id/${id}`}
        handleEditAction={handleEditWifi}
        canUse="WIFI"
        handleButtonAction={handleDeleteWifi}
        typeOfEntityToDelete={t('Common.wifi')}
      />
      <div className={styles.wifiViewContainer}>
        <h5 className={styles.wifiViewTitle}>{t('Roles.View.Details')}</h5>
        <InfoRow
          label={t('WiFi.ViewPage.Name')}
          value={wifiData?.name || '-'}
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('WiFi.ViewPage.Company')}
          value={wifiCompany?.name || '-'}
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('WiFi.ViewPage.Associations')}
          value={renderAssociationsForViewPage(companyAssoc, wifiFleets, t, styles, subCompanies)}
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('WiFi.ViewPage.SSID')}
          value={wifiData?.ssid || '-'}
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('WiFi.ViewPage.Password')}
          value={
            <div className={styles.passwordWrapper}>
              <Input
                size="small"
                value={wifiData?.password}
                type={showPassword ? 'text' : 'password'}
                disabled
                bordered={false}
              />
              <button
                className={styles.passwordButtonChange}
                id={BUTTON_IDS.wifiViewShowPassword}
                onClick={handleChangeShowPassword}
              >
                {t('WiFi.ViewPage.ShowPassword')}
              </button>
            </div>
          }
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('WiFi.ViewPage.CreatedAt')}
          value={
            (wifiData?.createdAt &&
              format(new Date(wifiData?.createdAt), localization.formats.time.formats.dby_imp)) ||
            ''
          }
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('WiFi.ViewPage.UpdatedAt')}
          value={
            (wifiData?.updatedAt &&
              format(new Date(wifiData?.updatedAt), localization.formats.time.formats.dby_imp)) ||
            ''
          }
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
      </div>
    </>
  );
};
