import { PermissionsTypes } from './constants';

export const filterEntityPermissionsBySubjectName = (permissions, name) =>
  permissions.filter(per => per.subject === name);

export const findIndexPermissionByName = (permissions, type, nameObject) => {
  if (type === PermissionsTypes.Entity) {
    return permissions.findIndex(
      per => per.subject === nameObject.subject && per.action === nameObject.action
    );
  } else {
    return permissions.findIndex(per => per.name === nameObject.module);
  }
};
