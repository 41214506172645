import {
  ALERT_TYPES,
  CHECKBOX_PROPERTIES,
  INPUT_PROPERTIES,
  SELECT_PROPERTIES,
  usesFleetsAndVehicles,
  usesDriversAndBranches
} from '../../constants';

const getArrayOfKeysWithValueTrue = obj =>
  obj
    ? Object.entries(obj).reduce((acc, inputValue) => {
        if (inputValue[1]) {
          acc.push(inputValue[0]);
        }
        return acc;
      }, [])
    : [];

export const getAlertFormInitialValues = (alertFormState, option) => {
  let filteredDevice = (alertFormState?.selectedDevices || []).filter(i =>
    (option.devices || []).some(k => k.id === i)
  );
  let filteredVehicle = (alertFormState?.selectedVehicles || []).filter(i =>
    (option.vehicles || []).some(k => k.id === i)
  );
  let filteredFleet = (alertFormState?.selectedFleets || []).filter(i =>
    (option.fleets || []).some(k => k.id === i)
  );
  let filteredUser = (alertFormState?.selectedUsers || [])?.filter(i =>
    (option.users || []).some(k => k.id === i)
  );
  let filteredSecondAlertUser = (alertFormState?.selectedSecondAlertUsers || [])?.filter(i =>
    (option.users || []).some(k => k.id === i)
  );
  let filteredThirdAlertUser = (alertFormState?.selectedThirdAlertUsers || [])?.filter(i =>
    (option.users || []).some(k => k.id === i)
  );

  return {
    [INPUT_PROPERTIES.NAME.name]: alertFormState?.name || '',
    [INPUT_PROPERTIES.SECOND_TIMER.name]: alertFormState?.secondAlertTimer || '',
    [INPUT_PROPERTIES.THIRD_TIMER.name]: alertFormState?.thirdAlertTimer || '',
    [CHECKBOX_PROPERTIES.SECOND_ALERT.name]: alertFormState?.isSecondAlertEnabled || false,
    [CHECKBOX_PROPERTIES.THIRD_ALERT.name]: alertFormState?.isThirdAlertEnabled || false,
    [SELECT_PROPERTIES.EXPIRATION.name]: alertFormState?.expiry || '',
    ...(ALERT_TYPES.OOH && {
      [SELECT_PROPERTIES.TIMEZONE.name]: alertFormState?.timezone || ''
    }),
    ...(usesFleetsAndVehicles(alertFormState.type) && {
      [SELECT_PROPERTIES.VEHICLES.name]:
        []
          .concat(filteredVehicle)
          .concat(filteredDevice)
          .filter(val => typeof val !== 'undefined') || [],
      [SELECT_PROPERTIES.FLEETS.name]: filteredFleet
    }),
    ...(usesDriversAndBranches(alertFormState.type) && {
      [SELECT_PROPERTIES.DRIVERS.name]: alertFormState?.selectedDrivers || [],
      [SELECT_PROPERTIES.BRANCHES.name]: alertFormState?.selectedBranches || []
    }),
    [SELECT_PROPERTIES.USERS.name]: filteredUser,
    [SELECT_PROPERTIES.SECOND_ALERT_USERS.name]: filteredSecondAlertUser,
    [SELECT_PROPERTIES.THIRD_ALERT_USERS.name]: filteredThirdAlertUser,
    [SELECT_PROPERTIES.USERS.name]: filteredUser,
    ...(ALERT_TYPES.SMARTJOBS && {
      [CHECKBOX_PROPERTIES.SMARTJOBS.name]: getArrayOfKeysWithValueTrue(
        alertFormState?.smartjobTypes
      ),
      [INPUT_PROPERTIES.NUMBER.name]: alertFormState?.inputNumberValue || 0
    }),
    ...(ALERT_TYPES.DOCUMENTEXPIRATION && {
      [SELECT_PROPERTIES.DOCUMENTEXPIRATIONFOLDERS.name]:
        alertFormState?.selectedDocumentExpirationFolders || [],
      [INPUT_PROPERTIES.DAYSTOEXPIRE.name]: alertFormState?.inputNumberValue || 0
    }),
    ...(ALERT_TYPES.TACHO && {
      [CHECKBOX_PROPERTIES.TACHO.name]: alertFormState?.tachoCodes || []
    }),
    ...(ALERT_TYPES.GEOFENCE && {
      [SELECT_PROPERTIES.GEOFENCES.name]: alertFormState?.selectedGeofences || [],
      [CHECKBOX_PROPERTIES.GEOFENCES.name]: getArrayOfKeysWithValueTrue(
        alertFormState?.geofencesTypes
      ),
      [SELECT_PROPERTIES.MANAGED_GEOFENCES.name]: alertFormState?.selectedManagedGeofences || [],
      [CHECKBOX_PROPERTIES.MANAGED_GEOFENCES.name]: getArrayOfKeysWithValueTrue(
        alertFormState?.managedGeofencesTypes
      ),
      [INPUT_PROPERTIES.SPEED_LIMIT_KM.name]: alertFormState?.speedLimit || 0
    }),
    ...(ALERT_TYPES.ROUTE && {
      [CHECKBOX_PROPERTIES.ROUTE_COMPLIANCE.name]: getArrayOfKeysWithValueTrue(
        alertFormState?.routeTypes
      )
    }),
    ...(ALERT_TYPES.SENTINEL && {
      [CHECKBOX_PROPERTIES.SENTINEL.name]: alertFormState?.fatigueViolationsNotif || []
    }),
    ...(ALERT_TYPES.SPEED && {
      [CHECKBOX_PROPERTIES.SPEED.name]: alertFormState?.signposted || [],
      [INPUT_PROPERTIES.SPEED_LIMIT_KM.name]: alertFormState?.speedLimit || 0
    }),
    ...(ALERT_TYPES.DRIVER_AND_VEHICLE && {
      [CHECKBOX_PROPERTIES.DRIVER_AND_VEHICLE.name]:
        alertFormState?.alarmCodes?.map(code => +code) || [],
      [CHECKBOX_PROPERTIES.ENGINE.name]: alertFormState?.eventTypes || []
    }),
    ...(ALERT_TYPES.ELD && {
      [CHECKBOX_PROPERTIES.ELD_VIOLATIONS.name]: !Array.isArray(alertFormState?.eldCodes)
        ? alertFormState?.eldCodes?.violations
        : alertFormState?.eldCodes || [],
      [CHECKBOX_PROPERTIES.ELD_WARNINGS.name]: alertFormState?.eldCodes?.warnings
    }),
    ...(ALERT_TYPES.ENGINE && {
      [CHECKBOX_PROPERTIES.ENGINE.name]: alertFormState?.eventTypes || []
    }),
    ...(ALERT_TYPES.FORM && {
      [SELECT_PROPERTIES.FORMS.name]: alertFormState?.selectedForms || []
    }),
    ...(ALERT_TYPES.PRETRIP && {
      [SELECT_PROPERTIES.PRETRIP_CHECKLISTS.name]: alertFormState?.selectedPretrips || [],
      [CHECKBOX_PROPERTIES.INSPECTIONS.name]: getArrayOfKeysWithValueTrue(
        alertFormState?.inspectionTypes
      )
    }),
    ...(ALERT_TYPES.METER && {
      [SELECT_PROPERTIES.METER_CONFIGURATION_TYPE.name.type]: alertFormState?.meterEntityType,
      [SELECT_PROPERTIES.METER_CONFIGURATION_TYPE.name.deviceType]: alertFormState?.deviceType,
      [SELECT_PROPERTIES.METER_CONFIGURATION_TYPE.name.meterType]: alertFormState?.meterType,
      [SELECT_PROPERTIES.METER_CONFIGURATION_TYPE.name.source]: alertFormState?.meterSource,
      [SELECT_PROPERTIES.METER_CONFIGURATION_TYPE.name.operation]: alertFormState?.meterOperation,
      [SELECT_PROPERTIES.METER_CONFIGURATION_TYPE.name.value]: alertFormState?.meterValue
    }),
    ...(ALERT_TYPES.GPIO && {
      [SELECT_PROPERTIES.GPIO_TYPE.name]: alertFormState?.selectedGpioTypes || []
    }),
    ...(ALERT_TYPES.DURESS && {
      [SELECT_PROPERTIES.DURESS_TYPE.name]: alertFormState?.selectedDuressTypes || []
    }),
    ...(ALERT_TYPES.OOH && {
      [CHECKBOX_PROPERTIES.OOH.name]: alertFormState?.eventTypes || []
    }),
    ...(ALERT_TYPES.OOH && {
      calendar: alertFormState?.calendar || {}
    })
  };
};
