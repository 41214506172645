import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useCallback } from 'react';
import { fetchFatigueStatusByCompanyId, useGetTachoInfoByCompanyId } from '../../tacho/tachoSlice';
import {
  fetchSentinelStatusByDriverId,
  fetchSentinelStatus,
  useSentinelDriversStatus
} from '../../sentinel/components/status/statusSlice';
import {
  fetchEWDStatusByDriverId,
  useEWDDriversStatus
} from '../../ewd/components/status/statusSlice';
import { fetchELDDataByDriverId, useDriverELDData } from '../../eld/eldSlice';
import {
  DUTY_STATUS,
  FATIGUE_TYPE,
  ELD_DRIVER_DUTY_STATUS,
  TACHO_DRIVER_DUTY_STATUS,
  SENTINEL_EWD_DRIVER_DUTY_STATUS,
  IGNITION_STATUS
} from '../../../containers/Tracking/constants';
import moment from 'moment';
import services from 'features/permissions/services';
import { useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';
import { api } from 'utils/api';

const REST_TIME = 60 * 1000;

const driverCardInitialState = {
  companies: {}
};

const driverCardSlice = createSlice({
  name: 'driverCard',
  initialState: driverCardInitialState,
  reducers: {
    setDriverFatigueType: (state, { payload }) => {
      const { companyId, driverId, fatigueType, fromDate, toDate } = payload;
      state.companies[companyId] = state.companies[companyId] || {};
      state.companies[companyId][driverId] = {
        dateRange: { fromDate, toDate },
        fatigueType,
        updatedAtTime: moment.now()
      };
    },
    setDriversFatigueTypes: (state, { payload }) => {
      const { companyId, fromDate, toDate, drivers } = payload;
      state.companies[companyId] = state.companies[companyId] || {};
      for (const driver of drivers) {
        const { driverId, fatigueType } = driver;
        state.companies[companyId][driverId] = {
          dateRange: { fromDate, toDate },
          fatigueType,
          updatedAtTime: moment.now()
        };
      }
    },
    fetchCompanyUsersStart(state, { payload }) {
      const { companyId } = payload;
      state.companies[companyId] = state.companies[companyId] || {};
      state.companies[companyId]['usersList'] = state.companies[companyId]['usersList'] || {};
      state.companies[companyId].usersList.data = [];
      state.companies[companyId].usersList.processing = true;
    },
    fetchCompanyUsersEnd(state, { payload }) {
      const { companyId, usersList } = payload;
      state.companies[companyId] = state.companies[companyId] || {};
      state.companies[companyId]['usersList'] = state.companies[companyId]['usersList'] || {};
      state.companies[companyId].usersList = {
        data: usersList,
        processing: false,
        isFetched: true,
        updatedAtTime: moment.now()
      };
    }
  }
});

const {
  setDriverFatigueType,
  setDriversFatigueTypes,
  fetchCompanyUsersStart,
  fetchCompanyUsersEnd
} = driverCardSlice.actions;
const defatulFromDate = moment()
    .startOf('day')
    .toDate(),
  defaultToDate = moment()
    .endOf('day')
    .toDate();
export const fetchDriverFatigueStatus = (
  driver,
  fatigueType,
  fromDate = defatulFromDate,
  toDate = defaultToDate
) => async (dispatch, getState) => {
  const currentCompany = getState().companies?.current;
  const userKey = getState().user.current?.auth?.key;
  const companyKey = currentCompany?.api_key,
    companyId = currentCompany?.id;
  const driverId = driver?.id,
    ruleSet = driver?.rulesets?.[0]?.ruleset;
  const driverCard = getState().driverCard.companies;
  const currentCompanyDriverCards = driverCard && driverCard[companyId];
  const driverCardFatigue = currentCompanyDriverCards && currentCompanyDriverCards[driverId];

  if (isValidDriverDutyStatus(driverCardFatigue, fromDate)) {
    return;
  }
  if (fatigueType === FATIGUE_TYPE.ELD) {
    dispatch(fetchELDDataByDriverId(driverId, companyKey, ruleSet, fromDate, toDate));
  } else if (fatigueType === FATIGUE_TYPE.EWD) {
    dispatch(fetchEWDStatusByDriverId(driverId));
  } else if (fatigueType === FATIGUE_TYPE.TACHO) {
    dispatch(fetchFatigueStatusByCompanyId(companyId, userKey));
  } else if (fatigueType === FATIGUE_TYPE.SENTINEL) {
    dispatch(fetchSentinelStatusByDriverId(driverId));
  }
};

const fetchCompanyUsersWithImgs = companyId => async (dispatch, getState) => {
  const authKey = getState().user?.current?.auth?.key;
  const driverCard = getState().driverCard.companies;
  const currentCompanyUsers = driverCard?.[companyId]?.usersList;

  if (!companyId || !authKey || isValidCompanyUsersWithImgs(currentCompanyUsers)) {
    return;
  }
  try {
    dispatch(fetchCompanyUsersStart({ companyId }));
    const response = await api.get(
      `/users?pruning=ALL&embed=associations,image_url&company_id=${companyId}`,
      { authKey }
    );
    dispatch(fetchCompanyUsersEnd({ usersList: response?.body || [], companyId }));
  } catch (err) {
    dispatch(fetchCompanyUsersEnd({ err: err?.toString(), companyId, usersList: [] }));
  }
};

export const useDriverFatigueStatus = (
  driver,
  fatigueType,
  fromDate = defatulFromDate,
  toDate = defaultToDate
) => {
  const dispatch = useDispatch();

  const driverId = useMemo(() => driver?.id, [driver]);

  const currentCompany = useCurrentCompany();
  const currentCompanyId = useMemo(() => currentCompany?.id, [currentCompany]);

  const driverCard = useSelector(state => state.driverCard.companies);

  const eldData = useDriverELDData(currentCompanyId, driverId, fromDate, toDate);

  const tachoCompanyData = useGetTachoInfoByCompanyId(currentCompanyId);
  const tachoData = useMemo(() => tachoCompanyData && tachoCompanyData[driverId]?.fatigueStatus, [
    tachoCompanyData,
    driverId
  ]);

  const sentinelDriversData = useSentinelDriversStatus();
  const sentinelData = useMemo(() => sentinelDriversData && sentinelDriversData[driverId], [
    sentinelDriversData,
    driverId
  ]);

  const ewdDriversData = useEWDDriversStatus();
  const ewdData = useMemo(() => ewdDriversData && ewdDriversData[driverId], [
    ewdDriversData,
    driverId
  ]);

  const driverDutyStatus = useMemo(
    () => getDriverDutyStatus(fatigueType, ewdData, eldData, tachoData, sentinelData),
    [fatigueType, ewdData, eldData, tachoData, sentinelData]
  );

  const isDriverNeedFetchFatigue = useMemo(() => {
    const currentCompanyDriverCards = (driverCard && driverCard[currentCompany.id]) || {};
    const notFetchYet = !currentCompanyDriverCards || !currentCompanyDriverCards[driver.id];
    return (
      notFetchYet ||
      !isValidDriverDutyStatus(currentCompanyDriverCards[driver.id], fromDate, toDate)
    );
  }, [currentCompany, driverCard, driver, fromDate, toDate]);

  useEffect(() => {
    if (isDriverNeedFetchFatigue) {
      dispatch(fetchDriverFatigueStatus(driver, driver.fatigueType, fromDate, toDate));
      dispatch(
        setDriverFatigueType({
          companyId: currentCompany.id,
          driverId,
          fromDate,
          toDate,
          fatigueType
        })
      );
    }
  }, [dispatch, isDriverNeedFetchFatigue, driver, fromDate, toDate, currentCompany]);

  return driverDutyStatus;
};
const isValidCompanyUsersWithImgs = companyUsersWithImgs => {
  return (
    companyUsersWithImgs &&
    (companyUsersWithImgs.processing ||
      (companyUsersWithImgs.isFetched &&
        companyUsersWithImgs.updatedAtTime &&
        Date.now() - companyUsersWithImgs.updatedAtTime < REST_TIME))
  );
};

export default driverCardSlice.reducer;

const isValidDriverDutyStatus = (driverCardFatigue, fromDate, toDate) => {
  const isValid =
    driverCardFatigue &&
    driverCardFatigue.dateRange?.fromDate &&
    moment(driverCardFatigue.dateRange.fromDate).isSame(moment(fromDate), 'day') &&
    driverCardFatigue.updatedAtTime &&
    Date.now() - driverCardFatigue.updatedAtTime < REST_TIME;
  return isValid;
};

const getDriverDutyStatus = (fatigueType, ewdData, eldData, tachoData, sentinelData) => {
  const getSentinelOrEWDDutyStatus = lastEvtType => {
    if (lastEvtType?.match(/drive/i)) {
      return SENTINEL_EWD_DRIVER_DUTY_STATUS.Driving;
    } else if (lastEvtType?.match(/rest/i)) {
      return SENTINEL_EWD_DRIVER_DUTY_STATUS.Rest;
    } else if (lastEvtType?.match(/work/i)) {
      return SENTINEL_EWD_DRIVER_DUTY_STATUS.Work;
    } else if (lastEvtType?.match(/offduty/i) || lastEvtType?.match(/sleep/i)) {
      return SENTINEL_EWD_DRIVER_DUTY_STATUS.OffDuty;
    } else {
      return SENTINEL_EWD_DRIVER_DUTY_STATUS.Unknown;
    }
  };
  if (fatigueType === FATIGUE_TYPE.EWD) {
    return {
      rawData: ewdData,
      dutyStatus: getSentinelOrEWDDutyStatus(sentinelData?.lastStatusEvent?.type)
    };
  } else if (fatigueType === FATIGUE_TYPE.ELD) {
    return {
      rawData: eldData,
      dutyStatus:
        ELD_DRIVER_DUTY_STATUS[eldData?.lastEvent?.type || eldData?.lastEvent?.action || 'OffDuty']
    };
  } else if (fatigueType === FATIGUE_TYPE.TACHO) {
    return {
      rawData: tachoData,
      dutyStatus: TACHO_DRIVER_DUTY_STATUS[tachoData?.mode] || DUTY_STATUS.Unknown
    };
  } else if (fatigueType === FATIGUE_TYPE.SENTINEL) {
    return {
      rawData: sentinelData,
      dutyStatus: getSentinelOrEWDDutyStatus(sentinelData?.lastStatusEvent?.type)
    };
  } else {
    return {
      rawData: null,
      dutyStatus: DUTY_STATUS.Unknown
    };
  }
};

const getVehicleIgnitionStatus = deviceStats => {
  const oorThreshhold = new Date();
  oorThreshhold.setHours(oorThreshhold.getHours() - 6);

  const isOutOfRange = new Date(deviceStats?.lastEventAt) < oorThreshhold;

  return deviceStats?.ignition === 'ON'
    ? isOutOfRange
      ? IGNITION_STATUS.OUT
      : IGNITION_STATUS.ON
    : IGNITION_STATUS.OFF;
};

const useCurrentCompanyUsersList = () => {
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();
  const currentCompanyId = useMemo(() => currentCompany?.id, [currentCompany]);

  const driverCard = useSelector(state => state.driverCard.companies);
  const usersList = driverCard?.[currentCompanyId]?.usersList;

  const isFetching = driverCard?.[currentCompanyId]?.usersList?.processing;
  const hasNotBeenFetched = !usersList?.updatedAtTime;

  if (currentCompanyId && !isValidCompanyUsersWithImgs(usersList)) {
    dispatch(fetchCompanyUsersWithImgs(currentCompanyId));
  }
  return { users: usersList?.data || [], isFetching, hasNotBeenFetched };
};

export const useDevicesWithDriversFatigueStatusAndIgnitionStatus = (
  devices = [],
  fetchRef,
  fromDate = defatulFromDate,
  toDate = defaultToDate
) => {
  const dispatch = useDispatch();

  const currentCompany = useCurrentCompany();
  const currentCompanyId = useMemo(() => currentCompany?.id, [currentCompany]);
  const userKey = useUserKey();
  const { users, isFetching: isFetchingUsers, hasNotBeenFetched } = useCurrentCompanyUsersList();

  const currentSubCompanies = useSubCompanies();
  const getDriverFatigueType = useCallback(
    driverId => {
      const driver = users?.find(d => d.id === driverId);
      const isInCurrentCompany = parseInt(currentCompany?.id) === parseInt(driver?.company?.id);
      const isInSubCompany = currentSubCompanies?.some(
        subCompany => parseInt(subCompany?.id, 10) === parseInt(driver?.company?.id, 10)
      );
      if (!driver || !(isInCurrentCompany || isInSubCompany)) {
        return FATIGUE_TYPE.Unknown;
      }
      if (
        driver.associations?.find(
          assoc => assoc.domain === 'EWD' && assoc.externalId.includes('driver_id')
        )
      ) {
        return FATIGUE_TYPE.EWD;
      }

      if (currentCompany.features?.find(f => f.code === services.ELD)) {
        return FATIGUE_TYPE.ELD;
      }

      if (currentCompany.features?.find(f => f.code === services.TACHO)) {
        return FATIGUE_TYPE.TACHO;
      }

      if (currentCompany.features?.find(f => f.code === services.SWD)) {
        return FATIGUE_TYPE.SENTINEL;
      }
      return FATIGUE_TYPE.Unknown;
    },
    [currentCompany, currentSubCompanies, users]
  );

  const drivers = useMemo(
    () =>
      devices
        .filter(device => device?.driver?.id)
        .map(c => ({
          ...c.driver,
          fatigueType: getDriverFatigueType(c.driver.id)
        })),
    [devices, getDriverFatigueType]
  );

  const driverCard = useSelector(state => state.driverCard.companies);

  const driversNeedFetchFatigue = useMemo(() => {
    if (!currentCompany?.id || !drivers?.length || isFetchingUsers) {
      return [];
    }
    const currentCompanyDriverCards = (driverCard && driverCard[currentCompany.id]) || {};
    return drivers.filter(driver => {
      //driver doesn't fetched fatigue
      const notFetchYet = !currentCompanyDriverCards || !currentCompanyDriverCards[driver.id];
      //driver fatigue invalid
      return (
        notFetchYet ||
        !isValidDriverDutyStatus(currentCompanyDriverCards[driver.id], fromDate, toDate)
      );
    });
  }, [currentCompany, driverCard, drivers, fromDate, toDate, isFetchingUsers]);

  const eldCompanies = useSelector(state => state.eldData.companies);
  const eldCompanyData = useMemo(() => eldCompanies[currentCompanyId], [
    eldCompanies,
    currentCompanyId
  ]);

  const tachoCompanyData = useGetTachoInfoByCompanyId(currentCompanyId);

  const sentinelDriversData = useSentinelDriversStatus();

  const ewdDriversData = useEWDDriversStatus();

  const driversDutyStatus = useMemo(() => {
    return drivers.map(driver => {
      const driverId = driver.id;
      const fatigueType = driver.fatigueType;
      const driverEWD = ewdDriversData[driverId];
      const driverELD = eldCompanyData?.[driverId]?.[fromDate.getTime() + '_' + toDate.getTime()];
      const driverTacho = tachoCompanyData?.[driverId]?.fatigueStatus;
      const driverSentinel = sentinelDriversData?.[driverId];
      return {
        driverId,
        fatigueType,
        fatigueStatus: getDriverDutyStatus(
          driver.fatigueType,
          driverEWD,
          driverELD,
          driverTacho,
          driverSentinel
        ).dutyStatus.status
      };
    });
  }, [drivers, eldCompanyData, tachoCompanyData, sentinelDriversData, ewdDriversData]);

  useEffect(() => {
    if (driversNeedFetchFatigue?.length) {
      const driversNeedsSentinelFatigue = driversNeedFetchFatigue.filter(
        driver => driver.fatigueType === FATIGUE_TYPE.SENTINEL
      );
      const driversNeedsOtherFatigue = driversNeedFetchFatigue.filter(
        driver => driver.fatigueType !== FATIGUE_TYPE.SENTINEL
      );
      if (driversNeedsSentinelFatigue.length) {
        dispatch(
          fetchSentinelStatus(
            userKey,
            currentCompany.id,
            driversNeedsSentinelFatigue.map(d => d.id),
            fetchRef
          )
        );
      }
      if (driversNeedsOtherFatigue?.length) {
        driversNeedsOtherFatigue.forEach(driver => {
          dispatch(fetchDriverFatigueStatus(driver, driver.fatigueType, fromDate, toDate));
        });
      }
      dispatch(
        setDriversFatigueTypes({
          companyId: currentCompany.id,
          fromDate,
          toDate,
          drivers: driversNeedFetchFatigue.map(driver => ({
            driverId: driver.id,
            fatigueType: driver.fatigueType
          }))
        })
      );
    }
  }, [dispatch, currentCompany, driversNeedFetchFatigue, fromDate, toDate, userKey, fetchRef]);

  const getDriverImgUrl = useCallback(
    driverId =>
      (driverId &&
        users &&
        users?.find(user => parseInt(user.id, 10) === parseInt(driverId, 10))?.imageUrl) ||
      '',
    [users]
  );

  const devicesWithIgnitionStatusAndFatigueStatus = useMemo(
    () =>
      devices.map(d => {
        const vehicleIgnitionStatus = getVehicleIgnitionStatus(d.deviceStats);
        const driverFatiguStatus =
          d?.driver?.id &&
          driversDutyStatus.find(
            fatigueStatus => String(fatigueStatus.driverId) === String(d.driver.id)
          );

        const driverImgUrl = getDriverImgUrl(d?.driver?.id);
        return {
          ...d,
          driver: d.driver
            ? {
                ...d.driver,
                driverFatiguStatus,
                imageUrl: driverImgUrl
              }
            : null,
          driverFatiguStatus,
          vehicleIgnitionStatus,
          driverImage: driverImgUrl
        };
      }),
    [devices, driversDutyStatus, getDriverImgUrl]
  );

  const isLoadingStatus = useMemo(() => !currentCompany?.id || hasNotBeenFetched, [
    hasNotBeenFetched,
    currentCompany
  ]);

  return { devicesWithIgnitionStatusAndFatigueStatus, isLoadingStatus };
};
