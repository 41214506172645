import { useEffect, useMemo } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import request from 'superagent';
import { useCurrentCompanyKey, useCompanies } from 'features/company/companySlice';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18n from 'i18next';
import { canHistoryGoBack } from 'utils/methods';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { parseErrorMessage } from 'utils/strings';

const vehicleMntTypes = {
  list: [],
  deletedList: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false,
    companyKey: null
  },
  vehicleMntType: {
    details: null,
    meta: {
      isFetching: false,
      error: null,
      companyKey: null
    }
  }
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.list = [];
  state.deletedList = [];
  state.meta.companyKey = action.payload.companyKey;
}

const vehicleMntTypesSlice = createSlice({
  name: 'vehicleMntTypes',
  initialState: vehicleMntTypes,
  reducers: {
    fetchVehicleMntTypesStart: startLoading,
    fetchVehicleMntTypesSuccess(state, { payload }) {
      state.list = payload.list.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      state.deletedList = payload.deletedList.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchVehicleMntTypesFailure: loadingFailed,
    fetchVehicleMntTypeStart(state, { payload }) {
      state.vehicleMntType = {
        details: null,
        meta: {
          isFetching: true,
          error: null,
          companyKey: null
        }
      };
    },
    fetchVehicleMntTypeSuccess(state, { payload }) {
      state.vehicleMntType.details = payload.details || { id: payload.id };
      state.vehicleMntType.meta.isFetching = false;
      state.vehicleMntType.meta.error = null;
      state.vehicleMntType.meta.companyKey = payload.companyKey;
    },
    fetchVehicleMntTypeFailure(state, { payload }) {
      state.vehicleMntType.details = payload.details || { id: payload.id };
      state.vehicleMntType.meta.isFetching = false;
      state.vehicleMntType.meta.error = payload.err;
      state.vehicleMntType.meta.companyKey = payload.companyKey;
    }
  }
});

const {
  fetchVehicleMntTypesStart,
  fetchVehicleMntTypesSuccess,
  fetchVehicleMntTypesFailure,
  fetchVehicleMntTypeStart,
  fetchVehicleMntTypeSuccess,
  fetchVehicleMntTypeFailure
} = vehicleMntTypesSlice.actions;

export const fetchVehicleMntTypes = () => (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies.current.id;
  const userKey = getState().user.current.auth.key;

  try {
    if (!companyKey || !userKey || !companyId) {
      return;
    }
    if (getState().vehicleMntTypes.meta.isFetching) {
      return;
    }

    dispatch(fetchVehicleMntTypesStart());

    const loadVehicleMntTypes = new Promise((resolve, reject) => {
      const url = `${API_PATH}/types/vehiclemaintenance/?embed=vehicletypes&direction=DOWN&pruning=ALL&company_id=${companyId}`;
      request('GET', url)
        .set('Authorization', `Token token="${userKey}"`)
        .set('Content-Type', 'application/json')
        .then(res => {
          resolve(res.body);
        })
        .catch(err => {
          reject(err);
        });
    });

    const loadDeletedVehicleMntTypes = new Promise((resolve, reject) => {
      const url = `${API_PATH}/types/vehiclemaintenance/?embed=vehicletypes&status=DELETED&pruning=ALL&company_id=${companyId}`;
      request('GET', url)
        .set('Authorization', `Token token="${userKey}"`)
        .set('Content-Type', 'application/json')
        .then(res => {
          resolve(res.body);
        })
        .catch(err => {
          reject(err);
        });
    });

    Promise.all([loadVehicleMntTypes, loadDeletedVehicleMntTypes])
      .then(([list, deletedList]) => {
        dispatch(fetchVehicleMntTypesSuccess({ list, deletedList, companyKey }));
      })
      .catch(err => {
        dispatch(fetchVehicleMntTypesFailure({ err: err.toString(), companyKey }));
      });
  } catch (err) {
    dispatch(fetchVehicleMntTypesFailure({ err: err.toString(), companyKey }));
  }
};

export const fetchVehicleMntType = vehicleMntTypeId => (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies.current.id;
  const userKey = getState().user.current.auth.key;
  try {
    if (!companyKey || !userKey || !companyId) {
      return;
    }
    dispatch(fetchVehicleMntTypeStart({ id: vehicleMntTypeId }));
    request('GET', `${API_PATH}/types/vehiclemaintenance/${vehicleMntTypeId}/?embed=vehicletypes`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(res => {
        dispatch(
          fetchVehicleMntTypeSuccess({ details: res.body, companyKey, id: vehicleMntTypeId })
        );
      })
      .catch(err => {
        dispatch(
          fetchVehicleMntTypeFailure({ err: err.toString(), companyKey, id: vehicleMntTypeId })
        );
      });
  } catch (err) {
    dispatch(fetchVehicleMntTypeFailure({ err: err.toString(), companyKey, id: vehicleMntTypeId }));
  }
};

export const useVehicleMntTypes = () => {
  const dispatch = useDispatch();
  const vehicleMntTypes = useVehicleMntTypesList();
  const isFetching = useIsFetching();
  const isListEmpty = useIsListEmpty();
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();

  if (!isFetching && (isCompanyKeyDifferent || (vehicleMntTypes.length === 0 && !isListEmpty))) {
    dispatch(fetchVehicleMntTypes());
  }
  return vehicleMntTypes;
};

export const useVehicleMntType = vehicleMntTypeId => {
  const dispatch = useDispatch();
  const vehicleMntTypes = useVehicleMntTypes();
  const deleteVehicleMntTypes = useDeletedVehicleMntTypes();

  const vehicleMntType = useSelector(state => state.vehicleMntTypes.vehicleMntType);
  const currentCompanyKey = useCurrentCompanyKey();
  const allCompanies = useCompanies();
  const details = useMemo(() => {
    let entity = null;
    if (Number(vehicleMntType.details?.id) === Number(vehicleMntTypeId)) {
      entity = vehicleMntType.details;
    } else {
      entity =
        vehicleMntTypes.find(type => Number(type.id) === Number(vehicleMntTypeId)) ||
        deleteVehicleMntTypes.find(type => Number(type.id) === Number(vehicleMntTypeId));
    }
    const company =
      allCompanies.find(company => Number(company.id) === Number(entity?.companyId)) || {};
    return entity
      ? {
          ...entity,
          company: {
            ...(entity.company || {}),
            ...company
          }
        }
      : null;
  }, [vehicleMntTypes, deleteVehicleMntTypes, vehicleMntType, allCompanies, vehicleMntTypeId]);

  const shouldFetch = useMemo(() => {
    if (!vehicleMntTypeId) {
      return false;
    }
    const isFetching = vehicleMntType.meta.isFetching;
    const isCompanyKeyDifferent = vehicleMntType.meta.companyKey !== currentCompanyKey;
    return isCompanyKeyDifferent ? !isFetching : !details && !isFetching;
  }, [vehicleMntType, details, currentCompanyKey, vehicleMntTypeId]);

  useEffect(() => {
    if (shouldFetch) {
      dispatch(fetchVehicleMntType(vehicleMntTypeId));
    }
  }, [dispatch, shouldFetch, vehicleMntTypeId]);

  return details;
};

export const useDeletedVehicleMntTypes = () =>
  useSelector(state => state.vehicleMntTypes.deletedList);
export const useVehicleMntTypesList = () => useSelector(state => state.vehicleMntTypes.list);
export const useCompanyKey = () => useSelector(state => state.vehicleMntTypes.meta.companyKey);
export const useIsFetching = () => useSelector(state => state.vehicleMntTypes.meta.isFetching);
export const useIsListEmpty = () => useSelector(state => state.vehicleMntTypes.meta.isListEmpty);
const useIsCompanyKeyDifferent = () => useCompanyKey() !== useCurrentCompanyKey();

export const deleteMntTypesApi = (data, history) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const { id, name } = data;
  const url = `/types/vehiclemaintenance/${id}`;
  Mixpanel.sendTrackEvent(MPTrackingEvents.Settings.VehicleMntTypes.Update, {
    typeOfUpdate: 'delete'
  });
  try {
    const response = await api.delete(url, { authKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('VehicleMaintenanceTypes.Notifications.DeleteNotification', { name })
        })
      );
      dispatch(fetchVehicleMntTypes());
      dispatch(fetchVehicleMntType(id));
      history && canHistoryGoBack(history, '/settings/vehicleMntTypes');
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${name} could not be deleted: ${err}`
      })
    );
  }
};

export const restoreMntTypesApi = data => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const { id, name } = data;
  const url = `${API_PATH}/types/vehiclemaintenance/${id}/restore`;
  request('PUT', url)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(resp => {
      if (resp.ok) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('VehicleMaintenanceTypes.Notifications.RestoreNotification', { name })
          })
        );
        dispatch(fetchVehicleMntType(id));
        dispatch(fetchVehicleMntTypes());
      }
    })
    .catch(err => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
      );
    });
};

export default vehicleMntTypesSlice.reducer;
