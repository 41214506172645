import React from 'react';
import i18n from 'i18next';

import InfoRow from 'components/form/info-row/InfoRow';
import { FBT, TRIP_STATE } from './constants';

import styles from '../FBTManager.module.scss';

import moment from 'moment';
import { formatListForMultiselect } from 'components/form/antMultiselect/AntMultiselect';
import hash from 'object-hash';
import { ENV } from 'features/permissions';

export const getInfoRow = (label, value) => (
  <InfoRow label={label} value={value} styles={styles} labelWidth="35%" />
);

export const getTypesOfUse = states => {
  const allValues = { id: 0, label: i18n.t('Common.AllTypesOfUse'), checked: true };

  if (!states?.tripStates) return [allValues];
  const typesOfUse = states.tripStates.map((trip, index) => ({
    id: index + 1,
    label: i18n.t(`FBTManager.${trip}`, trip),
    checked: true
  }));

  return [allValues, ...typesOfUse];
};

export const formatDateDifference = (dateFrom, dateTo, t, localization) => {
  const momentDateFrom = moment(dateFrom);
  const momentDateTo = moment(dateTo);
  const formatDate = localization.formats.time.formats.Md.toUpperCase();
  const nrOfDays = momentDateTo.diff(momentDateFrom, 'days') + 1;

  if (
    moment()
      .endOf('day')
      .diff(momentDateTo, 'days') === 0
  ) {
    if (nrOfDays === 1) {
      return t('Scorecard.Last1Day');
    }
    return t('Scorecard.LastDays', { days: nrOfDays });
  } else {
    return `${momentDateFrom.format(formatDate)} - ${momentDateTo.format(formatDate)}`;
  }
};

export const sortByName = (a, b) => {
  const fa = a.name.toLowerCase();
  const fb = b.name.toLowerCase();

  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
};

export const getFilterOptionsFromTrips = (trips, keys, initialValue, isDriver, filters, t) => {
  const values = [];
  const map = new Map();
  trips.forEach(trip => {
    if (!map.has(trip[keys.id])) {
      map.set(trip[keys.id], true);
      if (trip[keys.id] !== undefined) {
        values.push({
          id: trip[keys.id],
          name: isDriver ? getDriverName(trip) : trip[keys.name]
        });
      }
    }
  });

  values.sort(sortByName);
  values.unshift(initialValue);
  values.push({ id: -1, name: isDriver ? t('Common.NoDriver') : t('Common.NoVehicle') });

  return formatListForMultiselect(values).map((data, index) => {
    data.checked = filters === null || filters.includes(data.id);
    return data;
  });
};

const isFilteredText = (trip, query) => {
  const { firstName = '', lastName = '', vehicleName, startLocation, endLocation } = trip;
  const lowerCaseFilter = query?.toLowerCase();
  const conditionsArray = [
    `${firstName} ${lastName}`,
    vehicleName,
    startLocation,
    endLocation
  ].map(condition => (condition || '').toLowerCase());

  return conditionsArray.some(condition => condition.includes(lowerCaseFilter));
};
const isAllSelected = data => data.some(item => item.id === 0 && item.checked);

export const filterTrips = (
  trips = [],
  filters = {
    filterText: '',
    filteredVehicles: [],
    filteredDrivers: [],
    filteredTypesOfUse: [],
    filteredFleets: []
  }
) => {
  const {
    filterText,
    filteredVehicles,
    filteredDrivers,
    filteredTypesOfUse,
    filteredFleets
  } = filters;

  const isAllVehicles = isAllSelected(filteredVehicles);
  const isAllDrivers = isAllSelected(filteredDrivers);
  const isAllTypes = isAllSelected(filteredTypesOfUse);
  const isAllFleets = isAllSelected(filteredFleets);

  const filtered = trips.filter(trip => {
    const isText = filterText ? filterText && isFilteredText(trip, filterText) : true;

    const isVehicle =
      isAllVehicles ||
      filteredVehicles.some(
        vehicle =>
          vehicle?.checked &&
          (vehicle.id === trip.vehicleId || (!trip.vehicleId && vehicle.id === -1))
      );

    const isDriver =
      isAllDrivers ||
      filteredDrivers.some(
        driver =>
          driver?.checked && (driver.id === trip.driverId || (!trip.driverId && driver.id === -1))
      );

    const isTypeOfUse =
      isAllTypes || filteredTypesOfUse.some(type => type.label === trip.purpose && type.checked);

    const isFleet =
      isAllFleets ||
      filteredFleets.some(
        fleet =>
          fleet.checked &&
          (trip.fleets?.some(tripFleet => tripFleet.id === fleet.id) ||
            (!trip.fleets && fleet.id === -1))
      );
    return isText && isVehicle && isDriver && isTypeOfUse && isFleet;
  });

  return filtered.sort((a, b) => {
    const dateA = new Date(a.startedAt);
    const dateB = new Date(b.startedAt);

    return dateB - dateA;
  });
};

export const getStatistics = (trips, period) => {
  const driversStatistics = reduceTripsToDrivers(trips);

  const totalStatistics = driversStatistics?.length
    ? driversStatistics.reduce(
        (accumulator, trip) => {
          accumulator.privateHours += trip.privateHours;
          accumulator.businessHours += trip.businessHours;
          accumulator.privateTrips += trip.privateTrips;
          accumulator.businessTrips += trip.businessTrips;
          accumulator.privateKm += trip.privateUsage;
          accumulator.businessKm += trip.businessUsage;

          return accumulator;
        },
        {
          privateHours: 0,
          businessHours: 0,
          privateTrips: 0,
          businessTrips: 0,
          privateKm: 0,
          businessKm: 0
        }
      )
    : {
        privateHours: 0,
        businessHours: 0,
        privateTrips: 0,
        businessTrips: 0,
        privateKm: 0,
        businessKm: 0
      };

  const { privateKm, businessKm } = totalStatistics;
  const total = privateKm + businessKm;

  const businessPercentage =
    total > 0 ? Math.round((100 * totalStatistics?.businessKm) / total) : 0;

  return [
    {
      key: 1,
      name: i18n.t('FBTManager.privateUsage'),
      value: 100 - businessPercentage,
      color: '#26bd8b',
      percentage: totalStatistics.privateTrips === 0 ? `0%` : `${100 - businessPercentage}%`,
      trips: totalStatistics?.privateTrips,
      purpose: TRIP_STATE.PRIVATE,
      km: totalStatistics?.privateKm,
      hours: +(totalStatistics?.privateHours / 3600).toFixed(2),
      timelapse: period
    },
    {
      key: 2,
      name: i18n.t('FBTManager.businessUsage'),
      value: businessPercentage,
      color: '#f1c802',
      percentage: `${businessPercentage}%`,
      trips: totalStatistics?.businessTrips,
      purpose: TRIP_STATE.BUSINESS,
      km: totalStatistics?.businessKm,
      hours: +(totalStatistics?.businessHours / 3600).toFixed(2),
      timelapse: period
    }
  ];
};

export function getUniqueGPSEntries(gps) {
  if (!gps?.length || !gps?.some(value => value?.Lng && value?.Lat)) {
    return [];
  }

  const unique = new Set();

  return gps.reduce((accumulator, currentValue) => {
    if (currentValue?.Lat && currentValue?.Lng) {
      const currentEntry = {
        Lat: currentValue?.Lat,
        Lng: currentValue?.Lng
      };

      const currentEntryHash = hash(currentEntry);
      if (!unique.has(currentEntryHash)) {
        unique.add(currentEntryHash);
        accumulator.push(currentEntry);
      }
    }
    return accumulator;
  }, []);
}

export function findCenter(gps) {
  if (gps?.length > 0) {
    let center = gps.reduce(
      (accumulator, currentGPS) => {
        accumulator.Lat += currentGPS.Lat;
        accumulator.Lng += currentGPS.Lng;
        return accumulator;
      },
      { Lat: 0, Lng: 0 }
    );

    return { lat: center.Lat / gps.length, lng: center.Lng / gps.length };
  }
  return null;
}

/**
 * Util function that reduces trip data to an array of driver data
 */
export const reduceTripsToDrivers = (trips = [], devices = []) =>
  trips.reduce((acc, trip) => {
    let tripVehicle = acc.find(
      item => item.vehicleId === trip.vehicleId && item.driverId === trip.driverId
    );

    if (tripVehicle) {
      return acc.map(vehicle => {
        if (vehicle.vehicleId === trip.vehicleId && vehicle.driverId === trip.driverId) {
          return {
            ...tripVehicle,
            businessUsage: tripVehicle.businessUsage + getBusinessUsage(trip),
            privateUsage: tripVehicle.privateUsage + getPrivateUsage(trip),
            trips: [...tripVehicle.trips, trip],
            hours: tripVehicle.hours + trip[FBT.HOURS],
            privateTrips: tripVehicle.privateTrips + getPrivateTripCount(trip.purpose),
            businessTrips: tripVehicle.businessTrips + getBusinessTripCount(trip.purpose),
            privateHours: tripVehicle.privateHours + getPrivateHours(trip),
            businessHours: tripVehicle.businessHours + getBusinessHours(trip)
          };
        }
        return { ...vehicle };
      });
    } else {
      const device = devices.find(dev => dev.id === trip?.device?.id);
      // push first instance of vehicle
      acc.push({
        vehicleId: trip.vehicleId,
        driverId: trip.driverId,
        driver: getDriverName(trip),
        vehicle: trip.vehicleName || device?.name || '-',
        businessUsage: getBusinessUsage(trip),
        privateUsage: getPrivateUsage(trip),
        hours: trip[FBT.HOURS],
        trips: [trip],
        privateTrips: getPrivateTripCount(trip.purpose),
        businessTrips: getBusinessTripCount(trip.purpose),
        privateHours: getPrivateHours(trip),
        businessHours: getBusinessHours(trip)
      });
    }

    return acc;
  }, []);

const getPrivateTripCount = purpose => (purpose === TRIP_STATE.PRIVATE ? 1 : 0);
const getBusinessTripCount = purpose => (purpose !== TRIP_STATE.PRIVATE ? 1 : 0);
const getBusinessUsage = trip => (trip.purpose !== TRIP_STATE.PRIVATE ? trip.distanceKm : 0);
const getPrivateUsage = trip => (trip.purpose === TRIP_STATE.PRIVATE ? trip.distanceKm : 0);
const getPrivateHours = trip => (trip.purpose === TRIP_STATE.PRIVATE ? trip.durationSecs : 0);
const getBusinessHours = trip => (trip.purpose !== TRIP_STATE.PRIVATE ? trip.durationSecs : 0);

const getDriverName = trip => {
  if (!trip?.firstName && !trip?.lastName) {
    return '-';
  }

  return trip.firstName + ' ' + trip.lastName;
};

export const getResponseType = responses => {
  if (responses?.payload?.find(response => response.error)) {
    return {
      isValid: false,
      message: responses.payload.find(response => response.error).localizedMessage
    };
  }

  return {
    isValid: true
  };
};

export const sortTrips = trips =>
  [...trips].sort((a, b) => {
    // sort by vehicle name
    if (a.vehicleName > b.vehicleName) {
      return 1;
    } else if (a.vehicleName === b.vehicleName) {
      // sort by driver
      if (!a?.firstName && !a?.lastName) return -1;
      if (!b?.firstName && !b?.lastName) return 1;
      return `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1;
    } else {
      return -1;
    }
  });

//build array of date chunks based on the maximum number of api calls
export const getArrayChunksForDateRange = (arr, n) => {
  const result = [];
  for (let i = 0; i < arr.length; i += n) {
    result.push(arr.slice(i, i + n));
  }
  return result;
};

// create a function that pushes all the dates from a daterange into an array
export const buildDatesArray = (startDate, endDate) => {
  const datesArray = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    datesArray.push(currentDate.toISOString().split('T')[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return datesArray;
};

export const formatDatesToIso = ({ from, to }) => {
  const momentFrom = moment(from).startOf('day');
  const momentTo = moment(to).endOf('day');

  const isoFrom = momentFrom.toISOString();
  const isoTo = momentTo.toISOString();

  return {
    from: isoFrom,
    to: isoTo
  };
};

export const getNumberOfApiCalls = () => {
  switch (ENV) {
    case ENV.PROD_ANZ:
      return 8;
    case ENV.PROD_MX:
      return 2;
    default:
      return 8;
  }
};

export const splitDatesIntoChunks = ({ fromDate, toDate }) => {
  const numberOfApiCallsSplit = getNumberOfApiCalls() || 8;
  const allDatesArray = buildDatesArray(new Date(fromDate), new Date(toDate));
  const arrayPositionsSplitThreshold = allDatesArray.length / numberOfApiCallsSplit;

  //get array chunks and remove empty arrays [in case for < 7 days]
  const arrayChunkForDateRange = getArrayChunksForDateRange(
    allDatesArray,
    arrayPositionsSplitThreshold
  ).filter(a => a.length);
  const multipleDatesArray = arrayChunkForDateRange.map(a => {
    if (a.length > 1) {
      return { dateFrom: a[0], dateTo: a[a.length - 1] };
    } else {
      return { dateFrom: a[0], dateTo: a[0] };
    }
  });
  return multipleDatesArray;
};
