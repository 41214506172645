import React from 'react';
import styles from '../UDT.module.scss';
import * as ActionCellRenderer from './actionsCellRenderer';
import { Checkbox, Tooltip } from 'antd';
import { DriverSearchInput } from 'features/eld/DriverInputComponent';
import i18next from 'i18nextConfig';

export function getClassOfEventState(mode) {
  switch (mode) {
    case 'Assigned':
      return { className: styles.assigned, title: i18next.t('ELD.Assigned') };
    case 'Commented':
      return { className: styles.commented, title: i18next.t('ELD.Commented') };
    case 'Rejected': //rejected
      return { className: styles.rejected, title: i18next.t('ELD.Rejected') };
    case 'Pending': //pending
      return { className: styles.pending, title: i18next.t('ELD.Pending') };
    case 'Unassigned': //unassigned
      return { className: styles.unassigned, title: i18next.t('ELD.Unassigned') };
    default:
      return { className: '', title: '' };
  }
}

export function stateCellRenderer(value, rowData, index) {
  if (rowData.assignMode) {
    return (
      <div>
        <Tooltip
          title={i18next.t(
            'ELD.Check/Uncheck to select or unselect this row to be in the bulk update'
          )}
          placement="leftTop"
        >
          <Checkbox
            checked={rowData.checked}
            onChange={evt => rowData?.onRowChecked(rowData, evt.target.checked)}
          />
        </Tooltip>
      </div>
    );
  } else {
    const state = getClassOfEventState(rowData.status);
    return (
      <Tooltip title={state.title}>
        <div className={state.className}>
          <span></span>
        </div>
      </Tooltip>
    );
  }
}

export function driverCellRenderer(value, rowData, index) {
  let title = i18next.t('ELD.Driver for the assignment');
  if (rowData?.assignMode === 1) {
    title = i18next.t(
      'ELD.You can select this row, and pick up a driver to this row via the driver input box in the bulk update area'
    );
  }

  if (rowData.assignMode) {
    /*not allow edit manually*/
    return (
      <div>
        <Tooltip title={title}>
          <DriverSearchInput
            mode="text"
            defaultValue={rowData.driverId}
            disabled={true}
            value={rowData.driverId}
            onChange={value => rowData?.onDriverChanged?.(rowData, value)}
            style={{ width: '100%' }}
          />
        </Tooltip>
      </div>
    );
  }

  return <p>{value}</p>;
}

export function normalCellRenderer(value, rowData, index) {
  return (
    <div>
      <span>{value}</span>
    </div>
  );
}

export function durationCellRender(value, rowData, index) {
  if (value == null) {
    return '';
  }

  return (
    <div>
      <span>
        {Math.floor(value / 3600)
          .toString()
          .padStart(2, 0) +
          ':' +
          (Math.floor(value / 60) % 60).toString().padStart(2, 0) +
          ':' +
          Math.floor(value % 60)
            .toString()
            .padStart(2, 0)}
      </span>
    </div>
  );
}

export function commentsCellRenderer(value, rowData, index) {
  /* comment below function for change request
  if (rowData?.assignMode) {
    return (
      <Tooltip title={title}>
        <Input
          placeholder={i18next.t('ELD.Comments')}
          value={rowData.comments}
          defaultValue={rowData.comments}
          disabled={rowData?.assignMode === 1}
          onChange={evt => rowData?.onCommentChanged?.(rowData, evt.target.value)}
        />
      </Tooltip>
    );
  }*/
  return <span className={styles.overflowView}>{rowData.comments}</span>;
}

export function actionsCellRenderer(value, rowData, index) {
  if (rowData.assignMode) {
    return ActionCellRenderer.actionCell_AssignMode(rowData);
  } else {
    return ActionCellRenderer.actionCell_Default(rowData);
  }
}
