import React from 'react';
import { Route, Switch } from 'react-router';
import { Paths, Scorecard } from './';
import { MetricScoring } from './MetricScoring';
import { ScorecardView } from './ScorecardView';
import { ScorecardEvents } from './ScorecardEvents';
import { Geofences } from 'containers/Administration/Geofences/Geofences';

export const ScorecardRouter = () => {
  return (
    <Switch>
      <Route path={`${Paths.METRIC_SCORING}/:tab`}>
        <MetricScoring />
      </Route>

      <Route
        path={`${Paths.MAIN_PAGE}/events/:entityType/id/:id/type/:eventType/:dateFromSV/:dateToSV`}
      >
        <ScorecardEvents />
      </Route>

      <Route path={`${Paths.MAIN_PAGE}/:tab/id/:id/:dateFromSV/:dateToSV/:selectedCompany`}>
        <ScorecardView />
      </Route>

      <Route path={`${Paths.MAIN_PAGE}/:tab`}>
        <Scorecard />
      </Route>

      <Route path={`/settings/geofences/excludeScorecardEvents`}>
        <Geofences />
      </Route>

      <Route path={Paths.MAIN_PAGE}>
        <Scorecard />
      </Route>
    </Switch>
  );
};
