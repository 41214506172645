import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Steps, Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { Select } from 'components/ant';
import { confirmNavigateAway } from './confirmNavigateAway';
import MultipleSelectTable from 'components/multipleSelectTable/MultipleSelectTable';

import { useFleets, useVehicles, useIsFetching } from 'features/fleets/fleetsSlice';

import { MassSchemesTable } from '../Tables';
import {
  useIsFetching as useIsFetchingMassConfigs,
  useMassConfigurations
} from 'features/massManagement/massConfigurationsSlice';

import styles from './Modal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const { Step } = Steps;

const LABEL_ALIGN = 'left';

export const ConfigureVehiclesModal = ({ data, isOpen = false, onSave, onClose }) => {
  const { t } = useTranslation();
  const fleets = useFleets().map(fleet => ({
    ...fleet,
    name: fleet.name ? fleet.name : 'No Fleet',
    id: fleet.id ? fleet.id : -1
  }));
  const vehicles = useVehicles().filter(vehicle => vehicle.name);
  const isLoadingVehiclesFleets = useIsFetching();
  const massConfigurations = useMassConfigurations();
  const isLoadingMassConfigs = useIsFetchingMassConfigs();
  const [configureVehiclesForm] = Form.useForm();

  const [massConfiguration, setMassConfiguration] = useState();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isConfigured, setIsConfigured] = useState(false);
  const [progressCount, setProgressCount] = useState(0);
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedFleets, setSelectedFleets] = useState([]);

  useEffect(() => {
    configureVehiclesForm.resetFields();
    setMassConfiguration(data);
  }, [data, configureVehiclesForm]);

  const onMassConfigurationSelected = id => {
    let massConfig = massConfigurations.find(config => config.id === id);
    setMassConfiguration(massConfig);
    configureVehiclesForm.setFieldsValue({
      name: massConfig.name,
      jurisdiction: massConfig.vehicleCombination.jurisdiction,
      vehicles: massConfig.vehicles?.map(vehicle => vehicle.id),
      fleets: massConfig.fleets?.map(fleet => fleet.id)
    });
    setIsConfigured(massConfig.vehicles?.length || massConfig.fleets?.length);
    setSelectedVehicles(massConfig.vehicles?.map(vehicle => vehicle.id) || []);
    setSelectedFleets(massConfig.fleets?.map(fleet => fleet.id) || []);
  };

  const clearForm = () => {
    configureVehiclesForm.resetFields();
    setProgressCount(0);
    setMassConfiguration(null);
    setIsConfigured(false);
    setIsSaveEnabled(false);
    setSelectedVehicles([]);
    setSelectedFleets([]);
  };

  const onCancel = () => {
    confirmNavigateAway(
      configureVehiclesForm.isFieldsTouched(),
      () => {
        onClose();
        clearForm();
      },
      null,
      t
    );
  };

  const onFormSave = values => {
    onSave(values);
    clearForm();
    onClose();
  };

  const validateForm = async () => {
    try {
      const values = await configureVehiclesForm.validateFields();
      setIsSaveEnabled(true);
    } catch (errorInfo) {
      setIsSaveEnabled(!errorInfo.errorFields.length);
    }
  };

  const onConfigurationChanged = () => {
    const { vehicles, fleets } = configureVehiclesForm.getFieldsValue(['vehicles', 'fleets']);
    setIsConfigured(vehicles?.length > 0 || fleets?.length > 0);
    validateForm();
  };

  const onVehiclesChanged = value => {
    configureVehiclesForm.setFieldsValue({ vehicles: value });
    setSelectedVehicles(value);
    onConfigurationChanged();
  };

  const onFleetsChanged = value => {
    configureVehiclesForm.setFieldsValue({ fleets: value });
    setSelectedFleets(value);
    onConfigurationChanged();
  };

  return (
    <Modal
      className={styles.modal}
      title={
        data
          ? t('MassManagement.Action.EditVehicleConfiguration')
          : t('MassManagement.ConfigureVehiclesFleets')
      }
      open={isOpen}
      centered={true}
      onCancel={onCancel}
      width={1200}
      footer={
        <>
          <Button
            type="primary"
            size="large"
            form="massConfigurationForm"
            key="submit"
            htmlType="submit"
            disabled={!isSaveEnabled}
            id={BUTTON_IDS.configureVehiclesSave}
          >
            {t('Common.Save')}
          </Button>
          <Button id={BUTTON_IDS.configureVehiclesCancel} size="large" onClick={onCancel}>
            {t('Common.Cancel')}
          </Button>
        </>
      }
    >
      <Form
        layout="vertical"
        initialValues={data || {}}
        form={configureVehiclesForm}
        name="massConfigurationForm"
        onFinish={onFormSave}
        onValuesChange={validateForm}
        onKeyPress={event => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Steps progressDot current={progressCount} direction="vertical">
          <Step
            className={styles.step}
            title={t('Common.Select')}
            onClick={() => {
              setProgressCount(0);
            }}
            description={
              <>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="id"
                      label={t('MassManagement.MassConfiguration')}
                      labelAlign={LABEL_ALIGN}
                      colon={false}
                      rules={[
                        {
                          required: true,
                          message: t('MassManagement.Validation.Required', {
                            config: t('MassManagement.MassConfiguration')
                          })
                        }
                      ]}
                    >
                      <Select
                        placeholder={t(`Common.Search`)}
                        data={massConfigurations}
                        onSelect={onMassConfigurationSelected}
                        size={'large'}
                        loading={isLoadingMassConfigs}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle" style={{ marginBottom: '30px' }}>
                  <Col span={24}>
                    <label>{`${t('MassManagement.Field.MassSchemeLimits')} (t)`}</label>
                    <MassSchemesTable
                      t={t}
                      data={
                        massConfiguration?.massSchemes
                          ? massConfiguration.massSchemes.filter(scheme => scheme.selected)
                          : []
                      }
                      axleGroups={massConfiguration?.vehicleCombination?.axleGroups}
                      isReadOnly={true}
                    />
                  </Col>
                </Row>
              </>
            }
          />

          <Step
            className={styles.step}
            title={t('Home.Configure')}
            onClick={() => {
              setProgressCount(2);
            }}
            description={
              <>
                <Row align="middle" gutter={[16, 16]} style={{ marginBottom: '8px' }}>
                  <Col span={24}>
                    <Form.Item
                      name="vehicles"
                      label={t(`Common.Vehicles`)}
                      labelAlign={LABEL_ALIGN}
                      colon={false}
                      rules={[
                        {
                          required: !isConfigured,
                          message: t('MassManagement.Validation.VehicleFleetRequired')
                        }
                      ]}
                    >
                      <MultipleSelectTable
                        form={configureVehiclesForm}
                        selected={selectedVehicles}
                        options={vehicles.map(vehicle => ({
                          id: vehicle.id,
                          firstColumnValue: vehicle.name,
                          secondColumnValue: vehicle.registration,
                          thirdColumnValue: vehicle.fleets.map(fleet => fleet.name).join(', ')
                        }))}
                        properties={{
                          name: 'vehicles',
                          label: '',
                          mode: 'multiple',
                          placeholder: t('Alerts.Placeholders.SelectVehicles'),
                          labelCol: {
                            offset: 0,
                            span: 24
                          },
                          wrapperCol: {
                            offset: 0,
                            span: 24
                          },
                          columnHeaderLabels: {
                            firstColumnValue: t('Common.Name'),
                            secondColumnValue: t('Vehicles.View.Registration'),
                            thirdColumnValue: t('Fleets.Fleets')
                          },
                          actionType: 'SET_VEHICLES_NUMBER'
                        }}
                        isLoading={isLoadingVehiclesFleets}
                        onChange={onVehiclesChanged}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle" gutter={[16, 16]} style={{ marginBottom: '8px' }}>
                  <Col span={24}>
                    <Form.Item
                      name="fleets"
                      label={t(`Common.Fleets`)}
                      labelAlign={LABEL_ALIGN}
                      colon={false}
                      rules={[
                        {
                          required: !isConfigured,
                          message: t('MassManagement.Validation.VehicleFleetRequired')
                        }
                      ]}
                    >
                      <MultipleSelectTable
                        form={configureVehiclesForm}
                        selected={selectedFleets}
                        options={fleets.map(fleet => ({
                          id: fleet.id,
                          firstColumnValue: fleet.name,
                          secondColumnValue: fleet.description,
                          thirdColumnValue: fleet.vehicles.map(vehicle => vehicle.name).join(', ')
                        }))}
                        properties={{
                          name: 'fleets',
                          label: '',
                          mode: 'multiple',
                          placeholder: t('Alerts.Placeholders.SelectFleets'),
                          labelCol: {
                            offset: 0,
                            span: 24
                          },
                          wrapperCol: {
                            offset: 0,
                            span: 24
                          },
                          columnHeaderLabels: {
                            firstColumnValue: t('Common.Name'),
                            secondColumnValue: t('Common.Description'),
                            thirdColumnValue: t('Vehicles.Vehicles')
                          },
                          actionType: 'SET_FLEETS_NUMBER'
                        }}
                        isLoading={isLoadingVehiclesFleets}
                        onChange={onFleetsChanged}
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            }
          />
        </Steps>

        <Form.Item
          name="name"
          style={{
            display: 'none'
          }}
        />
      </Form>
    </Modal>
  );
};
