import { useCallback, useRef, useMemo } from 'react';

export function useParentCallback(events = []) {
  const childObserves = useRef({});

  const subscribeAction = useCallback(
    (parentEvent, childAction) => {
      if (!events.find(e => e === parentEvent) || typeof childAction !== 'function') {
        return false;
      } else {
        childObserves.current[parentEvent] = childObserves.current[parentEvent] || [];
        if (childObserves.current[parentEvent].indexOf(childAction) < 0) {
          childObserves?.current[parentEvent].push(childAction);
        }
      }
      return true;
    },
    [events, childObserves]
  );

  const parentActions = useMemo(() => {
    const actions = {};
    events &&
      events.forEach(e => {
        actions[e] = props => {
          if (childObserves.current[e]) {
            childObserves.current[e].forEach(f => f(props));
          }
        };
      });
    return actions;
  }, [events, childObserves]);

  if (!events?.length) {
    return null;
  }
  return [parentActions, subscribeAction];
}
