/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LocalDateTime from './LocalDateTime';

/**
 * The Audit model module.
 * @module model/Audit
 * @version 1.0
 */
class Audit {
    /**
     * Constructs a new <code>Audit</code>.
     * @alias module:model/Audit
     */
    constructor() { 
        
        Audit.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Audit</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Audit} obj Optional instance to populate.
     * @return {module:model/Audit} The populated <code>Audit</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Audit();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('entityId')) {
                obj['entityId'] = ApiClient.convertToType(data['entityId'], 'Number');
            }
            if (data.hasOwnProperty('entityVersion')) {
                obj['entityVersion'] = ApiClient.convertToType(data['entityVersion'], 'Number');
            }
            if (data.hasOwnProperty('entityType')) {
                obj['entityType'] = ApiClient.convertToType(data['entityType'], 'String');
            }
            if (data.hasOwnProperty('category')) {
                obj['category'] = ApiClient.convertToType(data['category'], 'String');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('userVersion')) {
                obj['userVersion'] = ApiClient.convertToType(data['userVersion'], 'Number');
            }
            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'Number');
            }
            if (data.hasOwnProperty('companyVersion')) {
                obj['companyVersion'] = ApiClient.convertToType(data['companyVersion'], 'Number');
            }
            if (data.hasOwnProperty('domain')) {
                obj['domain'] = ApiClient.convertToType(data['domain'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('action')) {
                obj['action'] = ApiClient.convertToType(data['action'], 'String');
            }
            if (data.hasOwnProperty('comment')) {
                obj['comment'] = ApiClient.convertToType(data['comment'], 'String');
            }
            if (data.hasOwnProperty('parameters')) {
                obj['parameters'] = ApiClient.convertToType(data['parameters'], 'String');
            }
            if (data.hasOwnProperty('ipAddress')) {
                obj['ipAddress'] = ApiClient.convertToType(data['ipAddress'], 'String');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = LocalDateTime.constructFromObject(data['createdAt']);
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = LocalDateTime.constructFromObject(data['updatedAt']);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
Audit.prototype['id'] = undefined;

/**
 * @member {Number} entityId
 */
Audit.prototype['entityId'] = undefined;

/**
 * @member {Number} entityVersion
 */
Audit.prototype['entityVersion'] = undefined;

/**
 * @member {module:model/Audit.EntityTypeEnum} entityType
 */
Audit.prototype['entityType'] = undefined;

/**
 * @member {module:model/Audit.CategoryEnum} category
 */
Audit.prototype['category'] = undefined;

/**
 * @member {Number} userId
 */
Audit.prototype['userId'] = undefined;

/**
 * @member {Number} userVersion
 */
Audit.prototype['userVersion'] = undefined;

/**
 * @member {Number} companyId
 */
Audit.prototype['companyId'] = undefined;

/**
 * @member {Number} companyVersion
 */
Audit.prototype['companyVersion'] = undefined;

/**
 * @member {String} domain
 */
Audit.prototype['domain'] = undefined;

/**
 * @member {String} url
 */
Audit.prototype['url'] = undefined;

/**
 * @member {module:model/Audit.ActionEnum} action
 */
Audit.prototype['action'] = undefined;

/**
 * @member {String} comment
 */
Audit.prototype['comment'] = undefined;

/**
 * @member {String} parameters
 */
Audit.prototype['parameters'] = undefined;

/**
 * @member {String} ipAddress
 */
Audit.prototype['ipAddress'] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
Audit.prototype['createdAt'] = undefined;

/**
 * @member {module:model/LocalDateTime} updatedAt
 */
Audit.prototype['updatedAt'] = undefined;





/**
 * Allowed values for the <code>entityType</code> property.
 * @enum {String}
 * @readonly
 */
Audit['EntityTypeEnum'] = {

    /**
     * value: "COMPANY"
     * @const
     */
    "COMPANY": "COMPANY",

    /**
     * value: "FLEET"
     * @const
     */
    "FLEET": "FLEET",

    /**
     * value: "VEHICLE"
     * @const
     */
    "VEHICLE": "VEHICLE",

    /**
     * value: "DEVICE"
     * @const
     */
    "DEVICE": "DEVICE",

    /**
     * value: "GEOFENCE"
     * @const
     */
    "GEOFENCE": "GEOFENCE",

    /**
     * value: "USER"
     * @const
     */
    "USER": "USER",

    /**
     * value: "CUSTOMER"
     * @const
     */
    "CUSTOMER": "CUSTOMER",

    /**
     * value: "SUBSCRIPTION"
     * @const
     */
    "SUBSCRIPTION": "SUBSCRIPTION",

    /**
     * value: "SUBSCRIPTION_PACK"
     * @const
     */
    "SUBSCRIPTION_PACK": "SUBSCRIPTION_PACK",

    /**
     * value: "LOCATION"
     * @const
     */
    "LOCATION": "LOCATION",

    /**
     * value: "RUNSHEET"
     * @const
     */
    "RUNSHEET": "RUNSHEET",

    /**
     * value: "JOB"
     * @const
     */
    "JOB": "JOB",

    /**
     * value: "ATTACHMENT"
     * @const
     */
    "ATTACHMENT": "ATTACHMENT",

    /**
     * value: "PRETRIP"
     * @const
     */
    "PRETRIP": "PRETRIP",

    /**
     * value: "MESSAGE"
     * @const
     */
    "MESSAGE": "MESSAGE",

    /**
     * value: "DRIVER_EVENT"
     * @const
     */
    "DRIVER_EVENT": "DRIVER_EVENT",

    /**
     * value: "FATIGUE_EVENT"
     * @const
     */
    "FATIGUE_EVENT": "FATIGUE_EVENT",

    /**
     * value: "PTC_EVENT"
     * @const
     */
    "PTC_EVENT": "PTC_EVENT",

    /**
     * value: "FORM"
     * @const
     */
    "FORM": "FORM",

    /**
     * value: "FORM_EVENT"
     * @const
     */
    "FORM_EVENT": "FORM_EVENT",

    /**
     * value: "VEHICLE_TYPE"
     * @const
     */
    "VEHICLE_TYPE": "VEHICLE_TYPE",

    /**
     * value: "CONTACT"
     * @const
     */
    "CONTACT": "CONTACT",

    /**
     * value: "SHIFT"
     * @const
     */
    "SHIFT": "SHIFT",

    /**
     * value: "REGISTRATION_CODE"
     * @const
     */
    "REGISTRATION_CODE": "REGISTRATION_CODE",

    /**
     * value: "ROLE"
     * @const
     */
    "ROLE": "ROLE"
};


/**
 * Allowed values for the <code>category</code> property.
 * @enum {String}
 * @readonly
 */
Audit['CategoryEnum'] = {

    /**
     * value: "ENTITY"
     * @const
     */
    "ENTITY": "ENTITY",

    /**
     * value: "MODULE"
     * @const
     */
    "MODULE": "MODULE"
};


/**
 * Allowed values for the <code>action</code> property.
 * @enum {String}
 * @readonly
 */
Audit['ActionEnum'] = {

    /**
     * value: "CREATE"
     * @const
     */
    "CREATE": "CREATE",

    /**
     * value: "UPDATE"
     * @const
     */
    "UPDATE": "UPDATE",

    /**
     * value: "DELETE"
     * @const
     */
    "DELETE": "DELETE",

    /**
     * value: "VIEW"
     * @const
     */
    "VIEW": "VIEW",

    /**
     * value: "PASSWORD_ERROR"
     * @const
     */
    "PASSWORD_ERROR": "PASSWORD_ERROR",

    /**
     * value: "PASSWORD_CHANGED"
     * @const
     */
    "PASSWORD_CHANGED": "PASSWORD_CHANGED",

    /**
     * value: "LOGIN"
     * @const
     */
    "LOGIN": "LOGIN",

    /**
     * value: "LOGOUT"
     * @const
     */
    "LOGOUT": "LOGOUT"
};



export default Audit;

