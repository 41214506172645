import { Table } from 'antd';
import React, { useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { DraggableBodyRow } from './DraggableBodyRow';
import { prepareJobsForRunsheetTable, reorderSequence } from '..';
import { compareByNumberProps } from 'utils/sorting';

const SortableTable = ({ rowProps, jobs, setJobs, ...children }) => {
  const components = {
    body: {
      row: DraggableBodyRow
    }
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = jobs[dragIndex];
      const reorderedJobs = update(jobs, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow]
        ]
      }).map(reorderSequence);

      const sortedReorderedJobs = jobs
        .map(job => ({
          ...job,
          seq: reorderedJobs.find(updatedJob => updatedJob.id === job.id).seq
        }))
        .sort((a, b) => compareByNumberProps(a, b, 'seq'));

      setJobs(sortedReorderedJobs);
    },
    [jobs]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        {...children}
        dataSource={prepareJobsForRunsheetTable(
          jobs,
          rowProps.runsheet,
          rowProps.locations,
          rowProps.handlers,
          rowProps.translation
        )}
        components={components}
        onRow={(record, index) => ({ index, moveRow })}
      />
    </DndProvider>
  );
};

export default SortableTable;
