import React from 'react';
import moment from 'moment';

class TimePicker extends React.Component {
  state = {
    time: moment(this.props.value).format('hh:mm A'),
    isError: false,
    isDirty: false
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.isDirty && props.value !== state.time) {
      return {
        time: moment(props.value).format('hh:mm A')
      };
    }
    return { time: state.time };
  }

  handleOnChange = event => {
    let value = event.target.value;
    if (value.length >= 3 && value.indexOf(':') === -1) {
      let start = value.slice(0, 2);
      let end = value.slice(2);
      value = start + ':' + end;
    }
    this.setState({
      time: value,
      isError: false,
      isDirty: true
    });
  };

  handleOnBlur = event => {
    const value = event.target.value;
    const newMoment = moment(value, 'hh:mm A');
    let momentObj = moment(this.props.value);
    if (newMoment.format('hh:mm A') === 'Invalid date') {
      this.setState({
        time: value,
        isError: true
      });
      return;
    }

    momentObj.set({ hours: newMoment.hour(), minutes: newMoment.minutes() });

    if (momentObj.valueOf() > moment().valueOf()) {
      this.setState({
        time: value,
        isError: true
      });
      return;
    }

    this.setState({
      time: momentObj.format('hh:mm A'),
      isError: false,
      isDirty: false
    });
    this.props.onChange(momentObj.format('hh:mm A'));
  };

  render() {
    return (
      <input
        className={
          this.state.isError
            ? 'datetime-time__input form-control is-invalid'
            : 'datetime-time__input form-control'
        }
        type="text"
        value={this.state.time}
        onChange={e => this.handleOnChange(e)}
        onBlur={e => this.handleOnBlur(e)}
      />
    );
  }
}

export default TimePicker;
