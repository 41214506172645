import React from 'react';
import ContentLoader from 'react-content-loader';
import { Spin, Space } from 'antd';

export const Loading = () => (
  <ContentLoader
    speed={2}
    width={500}
    height={500}
    viewBox="0 0 500 500"
    backgroundcolor="#f3f3f3"
    foregroundcolor="#ecebeb"
  >
    <rect x="5" y="5" width="98%" height="15" />
    <rect x="5" y="25" width="98%" height="25" />
    <rect x="5" y="55" width="98%" height="25" />
    <rect x="5" y="85" width="98%" height="25" />
    <rect x="5" y="115" width="98%" height="25" />
    <rect x="5" y="145" width="98%" height="25" />
    <rect x="5" y="175" width="98%" height="25" />
  </ContentLoader>
);

const textStyle = {
  small: { color: '#86b341', fontSize: '12px' },
  default: { color: '#86b341', fontSize: '12px' },
  large: { color: '#86b341', fontSize: '14px' }
};

export const Spinner = ({ size = 'default', desciption }) => (
  <Space direction="vertical" align="center">
    <Spin size={size} />
    {desciption && <div style={textStyle[size] || textStyle.default}>{desciption}</div>}
  </Space>
);
