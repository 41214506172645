import React from 'react';
import { JobDetailsLabels, JobAttributes } from '../utils/constans';
import {
  capitalizeFromUpperCase,
  timeColumns,
  getTableStatus,
  getTableDescription,
  getRunsheetLinkedDevice,
  getAddress,
  getAddressLabel,
  getEventDetails,
  getJobEventLinkedUser
} from '../../utils/helpers';
import { JOB_PNG_ATTACHMENT_PREFIX, JobViewTabsLabels } from './constans';
import { JobDetails } from '../JobDetails';
import { JobEvents } from '../JobEvents';
import { ColumnKeys } from './constans';
import moment from 'moment';
import { format } from 'utils/dates';
import { Link } from 'react-router-dom';

export const getLocation = job =>
  (job?.attributes || []).find(att => att.key === JobAttributes.LOCATION)?.value ||
  getAddressLabel(job);
export const getJobAddress = job =>
  (job?.attributes || []).find(att => att.key === JobAttributes.ADDRESS)?.value ||
  getAddress(job?.stop?.location?.address || {});

export const getInfoRowValue = (label, job, localization, raw) => {
  switch (label) {
    case JobDetailsLabels.CUSTOMER:
      return job?.customerName;
    case JobDetailsLabels.SEQ:
      return job?.seq;
    case JobDetailsLabels.TYPE:
      return capitalizeFromUpperCase(job?.type);
    case JobDetailsLabels.STATUS:
      return capitalizeFromUpperCase(job?.status);
    case JobDetailsLabels.START:
      return !raw
        ? timeColumns(job?.startAt, job?.actualStartAt, localization)
        : {
            ...(job?.startAt && {
              planned: format(
                moment(job.startAt).toDate(),
                localization.formats.time.formats.dby_imsp
              )
            }),
            ...(job?.actualStartAt && {
              actual: format(
                moment(job.actualStartAt).toDate(),
                localization.formats.time.formats.dby_imsp
              )
            })
          };
    case JobDetailsLabels.END:
      return !raw
        ? timeColumns(job?.endAt, job?.actualEndAt, localization)
        : {
            ...(job?.endAt && {
              planned: format(
                moment(job.endAt).toDate(),
                localization.formats.time.formats.dby_imsp
              )
            }),
            ...(job?.actualEndAt && {
              actual: format(
                moment(job.actualEndAt).toDate(),
                localization.formats.time.formats.dby_imsp
              )
            })
          };
    case JobDetailsLabels.JOB_NAME:
      return job?.customerName;
    case JobDetailsLabels.STOP_SEQ:
      return (job?.stop && 1) || 0;
    case JobDetailsLabels.LOCATION:
      return getLocation(job);
    case JobDetailsLabels.ADDRESS:
      return getJobAddress(job);
    case JobDetailsLabels.DESCRIPTION:
      return raw ? job?.notes : splitNotes(job?.notes);
    case JobDetailsLabels.REASON:
      return getJobCommentsKey(job);
    default:
      return;
  }
};

const getJobCommentsKey = job => {
  return job?.attributes?.find(e => e.key === 'jobCommentsKey')?.value;
};

function splitNotes(notes = '') {
  const pattern = 'Driver Added Note';
  const splitNotes = notes.split(pattern).map(instance => instance.replace(/^: /, `${pattern}: `));

  return splitNotes.map(note => <p key={note}>{note}</p>);
}

export const getActiveTab = (activeTab, job, jobAttach, jobEvents, loading = {}) => {
  switch (activeTab) {
    case JobViewTabsLabels.DETAILS:
      return (
        <JobDetails
          job={job}
          jobAttach={jobAttach}
          isLoadingJob={loading.job}
          isLoadingAttachments={loading.attachments}
        />
      );
    case JobViewTabsLabels.EVENTS:
      return <JobEvents jobEvents={jobEvents} />;
    default:
      return;
  }
};

export const getJobAttachments = args => {
  const { jobAttach, styles, showModal = () => {}, isSignatureFiltered = false } = args;
  const jobAttachments = isSignatureFiltered
    ? (jobAttach?.jobAttachments || []).filter(attachment => attachment.type === 'PHOTO')
    : jobAttach?.jobAttachments || [];

  return jobAttachments.map(jobAttach => (
    <div
      key={jobAttach.id}
      className={styles.imageContainer}
      onClick={() => showModal(jobAttach.id)}
    >
      <div
        style={{
          backgroundImage: `url(${JOB_PNG_ATTACHMENT_PREFIX}${jobAttach?.attachment?.content})`
        }}
        className={styles.imageAttachment}
      />
      <span className={styles.notesAttachment}>{jobAttach?.notes || ''}</span>
    </div>
  ));
};

export const prepareJobEventsForTable = (
  jobEvents,
  localization,
  devices,
  deletedDevices,
  users,
  translate
) => {
  return jobEvents.map(jobEvent => {
    const linkedDevice = getRunsheetLinkedDevice(devices, deletedDevices, jobEvent);
    const linkedUser = getJobEventLinkedUser(users, jobEvent);
    return {
      key: jobEvent?.id,
      [ColumnKeys.STATUS]: getTableStatus(jobEvent?.type, translate),
      [ColumnKeys.DESCRIPTION]: getTableDescription(jobEvent?.description, translate),
      [ColumnKeys.CREATED_AT]: format(
        moment(jobEvent.createdAt).toDate(),
        localization.formats.time.formats.dby_imsp
      ),
      [ColumnKeys.EVENT_AT]: format(
        moment(jobEvent.eventAt).toDate(),
        localization.formats.time.formats.dby_imsp
      ),
      [ColumnKeys.DEVICE]: (
        <Link to={`/settings/devices/id/${jobEvent?.device?.id}`}>{linkedDevice?.name ?? ''}</Link>
      ),
      [ColumnKeys.DRIVER]: (
        <Link to={`/settings/users/id/${jobEvent?.user?.id}`}>
          {linkedUser ? `${linkedUser?.firstName} ${linkedUser?.lastName}` : ''}
        </Link>
      ),
      [ColumnKeys.DESCRIPTION]: jobEvent?.description || '-',
      [ColumnKeys.DETAILS]: getEventDetails(jobEvent?.queuedEvent, translate, localization)
    };
  });
};
