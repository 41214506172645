export const DRIVER_ID_PATHS = {
  DEFAULT: '/settings/company_config/driverId',
  NEW_TEMPLATE: '/settings/company_config/driverId/createTemplate',
  DRIVER_ID_CONFIGURED: '/settings/company_config/driverId/configured',
  DRIVER_ID_CONFIGURED_ASSIGNED: '/settings/company_config/driverId/configured/assigned',
  DRIVER_ID_CONFIGURED_UNASSIGNED: '/settings/company_config/driverId/configured/unassigned',
  DRIVER_ID_DELETED: '/settings/company_config/driverId/deleted',
  DRIVER_ID_VIEW: '/settings/company_config/driverId/id',
  DRIVER_ID_AUDIT: '/settings/company_config/driverId/audit/id',
  DRIVER_ID_EDIT: '/settings/company_config/driverId/edit/id'
};
