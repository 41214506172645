import moment from 'moment';
import { useMemo } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../config';
import { ApiClient } from '../../nextgen_api/index';
import { useSelector } from 'react-redux';
import { useELDRuleset } from './eldSlice';
import { DriverStatusId } from 'containers/ELD/Constants';
import { toLower } from 'lodash';

const PointEvents = ['ZoneChange', 'CycleChange'];
const LogEvents = ['LogonDriver', 'LogoffDriver'];
export const actionTypes = {
  init: 0,
  pending: 1,
  processing: 2,
  error: 3,
  done: 4
};

const statusType = {
  DriverLogHeaderStatus: 0,
  DriverEvents: 1,
  DriverViolations: 2,
  DriverCheckpoint: 3
};

function getStatusId(type, id) {
  switch (type) {
    case statusType.DriverLogHeaderStatus:
      return `DriverLogHeaderStatus_${id}`;
    case statusType.DriverEvents:
      return `DriverEventsStatus_${id}`;
    case statusType.DriverViolations:
      return `DriverViolationsStatus_${id}`;
    case statusType.DriverCheckpoint:
      return `DriverCheckpointStatus_${id}`;
    default:
      return null;
  }
}

const REST_TIME = 20 * 1000;

export const driverLog_data = {
  companies: {
    /*structure
    [company id]: {
      [user id]: {
          'logHeader': {dateRange: {}},
          'events': {[dateRange]:{}},
          'violations': {[dataRange]:{}},
          'checkpoint': {}
        }
      }
    }
    */
  },
  status: {}
};

const driverLogSlice = createSlice({
  name: 'driverLogData',
  initialState: driverLog_data,
  reducers: {
    //fetch driver log header
    fetchDriverLogHeaderByUserIdStart(state, { payload }) {
      const { driverId, fromDate, toDate } = payload;
      const statusId = getStatusId(
        statusType.DriverLogHeaderStatus,
        driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
      );
      if (statusId != null) {
        state.status[statusId] = {
          fetching: actionTypes.processing,
          fetchingTime: moment().valueOf(),
          error: null
        };
      }
    },
    fetchDriverLogHeaderByUserIdSucceeded(state, { payload }) {
      const { companyId, driverId, fromDate, toDate, data } = payload;
      const statusId = getStatusId(
        statusType.DriverLogHeaderStatus,
        driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
      );

      if (data != null) {
        if (state.companies[companyId] == null) {
          state.companies[companyId] = {};
        }

        if (state.companies[companyId][driverId] == null) {
          state.companies[companyId][driverId] = {};
        }

        if (state.companies[companyId][driverId].logHeader == null) {
          state.companies[companyId][driverId].logHeader = {};
        }

        state.companies[companyId][driverId].logHeader[
          fromDate.getTime() + '_' + toDate.getTime()
        ] = data;
      }
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchDriverLogHeaderByUserIdFailed(state, { payload }) {
      const { driverId, fromDate, toDate, error } = payload;
      const statusId = getStatusId(
        statusType.DriverLogHeaderStatus,
        driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
      );
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.error,
        error: error
      };
    },
    //fetch driver events
    fetchDriverEventsStart(state, { payload }) {
      const { driverId, fromDate, toDate } = payload;
      const statusId = getStatusId(
        statusType.DriverEvents,
        driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
      );
      if (statusId != null) {
        state.status[statusId] = {
          fetching: actionTypes.processing,
          fetchingTime: moment().valueOf(),
          error: null
        };
      }
    },
    fetchDriverEventsSucceeded(state, { payload }) {
      const { companyId, driverId, fromDate, toDate, data } = payload;
      const statusId = getStatusId(
        statusType.DriverEvents,
        driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
      );

      if (data != null) {
        if (state.companies[companyId] == null) {
          state.companies[companyId] = {};
        }

        if (state.companies[companyId][driverId] == null) {
          state.companies[companyId][driverId] = {};
        }

        if (state.companies[companyId][driverId].events == null) {
          state.companies[companyId][driverId].events = {};
        }

        state.companies[companyId][driverId].events[
          fromDate.getTime() + '_' + toDate.getTime()
        ] = data;
      }
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchDriverEventsFailed(state, { payload }) {
      const { driverId, fromDate, toDate, error } = payload;
      const statusId = getStatusId(
        statusType.DriverEvents,
        driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
      );
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.error,
        error: error
      };
    },
    //fetch violations
    fetchDriverViolationsStart(state, { payload }) {
      const { driverId, fromDate, toDate } = payload;
      const statusId = getStatusId(
        statusType.DriverViolations,
        driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
      );
      if (statusId != null) {
        state.status[statusId] = {
          fetching: actionTypes.processing,
          fetchingTime: moment().valueOf(),
          error: null
        };
      }
    },
    fetchDriverViolationsSucceeded(state, { payload }) {
      const { companyId, driverId, fromDate, toDate, data } = payload;
      const statusId = getStatusId(
        statusType.DriverViolations,
        driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
      );

      if (data != null) {
        if (state.companies[companyId] == null) {
          state.companies[companyId] = {};
        }

        if (state.companies[companyId][driverId] == null) {
          state.companies[companyId][driverId] = {};
        }

        if (state.companies[companyId][driverId].violations == null) {
          state.companies[companyId][driverId].violations = {};
        }

        data.violations = data.violations?.filter(v => v.finish > fromDate.getTime());
        state.companies[companyId][driverId].violations[
          fromDate.getTime() + '_' + toDate.getTime()
        ] = data;
      }
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchDriverViolationsFailed(state, { payload }) {
      const { driverId, fromDate, toDate, error } = payload;
      const statusId = getStatusId(
        statusType.DriverViolations,
        driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
      );
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.error,
        error: error
      };
    },
    //fetch checkpoint
    fetchDriverCheckpointStart(state, { payload }) {
      const { driverId } = payload;
      const statusId = getStatusId(statusType.DriverCheckpoint, driverId);
      if (statusId != null) {
        state.status[statusId] = {
          fetching: actionTypes.processing,
          fetchingTime: moment().valueOf(),
          error: null
        };
      }
    },
    fetchDriverCheckpointSucceeded(state, { payload }) {
      const { companyId, driverId, data } = payload;
      const statusId = getStatusId(statusType.DriverCheckpoint, driverId);

      if (data != null) {
        if (state.companies[companyId] == null) {
          state.companies[companyId] = {};
        }
        if (state.companies[companyId][driverId] == null) {
          state.companies[companyId][driverId] = {};
        }
        state.companies[companyId][driverId].checkpoint = data;
      }
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchDriverCheckpointFailed(state, { payload }) {
      const { driverId, error } = payload;
      const statusId = getStatusId(statusType.DriverCheckpoint, driverId);
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.error,
        error: error
      };
    }
  }
});

export const {
  fetchDriverLogHeaderByUserIdStart,
  fetchDriverLogHeaderByUserIdSucceeded,
  fetchDriverLogHeaderByUserIdFailed,

  //Driver Events
  fetchDriverEventsStart,
  fetchDriverEventsSucceeded,
  fetchDriverEventsFailed,

  //Driver Violations
  fetchDriverViolationsStart,
  fetchDriverViolationsSucceeded,
  fetchDriverViolationsFailed,

  //Driver Checkpoint
  fetchDriverCheckpointStart,
  fetchDriverCheckpointSucceeded,
  fetchDriverCheckpointFailed
} = driverLogSlice.actions;

export const fetchDriverLogHeaderByDriverId = (
  company,
  driverId,
  fromDate,
  toDate,
  reload = false
) => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;
  if (!reload) {
    const statusId = getStatusId(
      statusType.DriverLogHeaderStatus,
      driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
    );
    const fetchInfo = getState().driverLogData.status[statusId];
    if (
      (fetchInfo != null &&
        (fetchInfo.fetching === actionTypes.processing ||
          moment().valueOf() - fetchInfo.fetchingTime < REST_TIME)) ||
      company?.api_key == null
    ) {
      return driverId;
    }
  }
  dispatch(
    fetchDriverLogHeaderByUserIdStart({ companyId: company.id, driverId, fromDate, toDate })
  );

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const apiQuery = `/sentinel/users/{driverId}/logheader?company_id=${companyId}`;
    apiClient.callApi(
      apiQuery,
      'GET',
      { driverId: driverId },
      { fromDate: fromDate.toISOString(), toDate: toDate.toISOString() },
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(
      fetchDriverLogHeaderByUserIdSucceeded({
        companyId: company.id,
        driverId,
        fromDate,
        toDate,
        data
      })
    );
  } catch (err) {
    const payload = { companyId: company.id, driverId, fromDate, toDate, error: err.toString() };
    dispatch(fetchDriverLogHeaderByUserIdFailed(payload));
  }
  return driverId;
};

export const fetchDriverEvents = (company, driverId, fromDate, toDate, reload) => async (
  dispatch,
  getState
) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;
  const statusId = getStatusId(
    statusType.DriverEvents,
    driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
  );
  const fetchInfo = getState().driverLogData.status[statusId];

  if (
    (fetchInfo != null &&
      (fetchInfo.fetching === actionTypes.processing ||
        (!reload && moment().valueOf() - fetchInfo.fetchingTime < REST_TIME))) ||
    company?.api_key == null
  ) {
    return driverId;
  }

  dispatch(fetchDriverEventsStart({ companyId: company.id, driverId, fromDate, toDate }));

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const apiQuery = `/sentinel/users/{driverId}/events?company_id=${companyId}`;
    apiClient.callApi(
      apiQuery,
      'GET',
      { driverId: driverId },
      {
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        pruning: 'B2B',
        embed: 'createdBy',
        active: true
      },
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(
      fetchDriverEventsSucceeded({
        companyId: company.id,
        driverId,
        fromDate,
        toDate,
        data
      })
    );
  } catch (err) {
    const payload = { companyId: company.id, driverId, fromDate, toDate, error: err.toString() };
    dispatch(fetchDriverEventsFailed(payload));
  }
  return driverId;
};

export const fetchDriverViolations = (
  company,
  driverId,
  ruleset,
  fromDate,
  toDate,
  checkpointDate
) => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;
  if (!ruleset?.length || toLower(ruleset) === 'eld99') return;
  const statusId = getStatusId(
    statusType.DriverViolations,
    driverId + '_' + fromDate.getTime() + '_' + toDate.getTime()
  );
  const fetchInfo = getState().driverLogData.status[statusId];
  if (
    (fetchInfo != null &&
      (fetchInfo.fetching === actionTypes.processing ||
        moment().valueOf() - fetchInfo.fetchingTime < REST_TIME)) ||
    company?.api_key == null
  ) {
    return driverId;
  }

  dispatch(fetchDriverViolationsStart({ companyId: company.id, driverId, fromDate, toDate }));

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const apiQuery = `/sentinel/users/{driverId}/execute?company_id=${companyId}`;
    apiClient.callApi(
      apiQuery,
      'GET',
      { driverId: driverId },
      {
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        checkpointDate: checkpointDate?.toISOString()
      },
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(
      fetchDriverViolationsSucceeded({
        companyId: company.id,
        driverId,
        fromDate,
        toDate,
        data
      })
    );
  } catch (err) {
    const payload = { companyId: company.id, driverId, fromDate, toDate, error: err.toString() };
    dispatch(fetchDriverViolationsFailed(payload));
  }
  return driverId;
};

export const fetchDriverCheckpoint = (company, driverId) => async (dispatch, getState) => {
  const statusId = getStatusId(statusType.DriverCheckpoint, driverId);
  const fetchInfo = getState().driverLogData.status[statusId];
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;
  if (
    (fetchInfo != null &&
      (fetchInfo.fetching === actionTypes.processing ||
        moment().valueOf() - fetchInfo.fetchingTime < REST_TIME)) ||
    company?.api_key == null
  ) {
    return driverId;
  }

  dispatch(fetchDriverCheckpointStart({ companyId: company.id, driverId }));

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const apiQuery = `/sentinel/users/{userId}/checkpoint?company_id=${companyId}`;
    apiClient.callApi(
      apiQuery,
      'GET',
      { userId: driverId },
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(
      fetchDriverCheckpointSucceeded({
        companyId: company.id,
        driverId,
        data
      })
    );
  } catch (err) {
    const payload = { companyId: company.id, driverId, error: err.toString() };
    dispatch(fetchDriverCheckpointFailed(payload));
  }
  return driverId;
};

export const useDriverLogHeader = (companyId, driverId) =>
  useSelector(state => state.driverLogData.companies[companyId]?.[driverId]);

export const useDriverLogHeaderByDate = (companyId, driverId, fromDate, toDate) =>
  useSelector(
    state =>
      state.driverLogData.companies[companyId]?.[driverId]?.logHeader?.[
        fromDate.getTime() + '_' + toDate.getTime()
      ]
  );

export const useDriverEventsByDate = (companyId, driver, fromDate, toDate) => {
  const driverId = driver?.id;

  const driverEvents = useSelector(
    state =>
      state.driverLogData.companies[companyId]?.[driverId]?.events?.[
        fromDate.getTime() + '_' + toDate.getTime()
      ]
  );

  const eldRuleset = useELDRuleset();
  const driverRulesetName = driver?.rulesets?.[0]?.ruleset;
  const driverRuleset = eldRuleset?.find(r => r.name === driverRulesetName);

  const startTime = fromDate.getTime();
  const endTime = toDate.getTime();

  return useMemo(() => {
    const events = driverEvents?.filter(
      e =>
        e.eventAt >= fromDate.getTime() &&
        e.eventAt <= toDate.getTime() &&
        (driverRuleset?.actions.includes(e.action) ||
          PointEvents.includes(e.action) ||
          LogEvents.includes(e.action)) &&
        e.status !== 'M'
    );

    //API now returns all events including Pending and Original events, need to remove original events which have Pending events
    let idx = 0;
    if (events?.length > 0) {
      while (idx < events?.length) {
        const e = events[idx];
        if (e.parentId != null) {
          let parentIdx = events.findIndex(ev => ev.id === e.parentId);
          if (parentIdx >= 0) {
            events.splice(parentIdx, 1);
            if (parentIdx < idx) {
              continue;
            }
          }
        }
        idx++;
      }
    }

    let prevEvent = null;
    if (events?.length === 0 && driverEvents?.length > 0) {
      for (let i = driverEvents.length - 1; i >= 0; i--) {
        if (
          driverEvents[i].eventAt <= fromDate.getTime() &&
          driverRuleset?.actions?.includes(driverEvents[i].action) &&
          driverEvents[i].status !== 'M' &&
          DriverStatusId[driverEvents[i].action] >= 0
        ) {
          prevEvent = { ...driverEvents[i] };
          break;
        }
      }

      if (prevEvent) {
        prevEvent.isPrevEvent = true;
        events.splice(0, 0, prevEvent);
      }
    } else if (events?.length > 0 && events[0].eventAt !== fromDate.getTime()) {
      const idx = driverEvents?.indexOf(events[0]);
      if (idx > 0) {
        for (let i = idx - 1; i >= 0; i--) {
          if (
            driverRuleset?.actions?.includes(driverEvents[i].action) &&
            driverEvents[i].status !== 'M' &&
            DriverStatusId[driverEvents[i].action] >= 0
          ) {
            prevEvent = { ...driverEvents[i] };
            break;
          }
        }
        if (prevEvent) {
          prevEvent.isPrevEvent = true;
          events.splice(0, 0, prevEvent);
        }
      }
    }

    return events;
  }, [companyId, driverEvents, startTime, endTime, driverRuleset]);
};

export const useDriverViolationsByDate = (companyId, driverId, fromDate, toDate) =>
  useSelector(state => {
    const driverViolations =
      state.driverLogData.companies[companyId]?.[driverId]?.violations?.[
        fromDate.getTime() + '_' + toDate.getTime()
      ];
    return driverViolations;
  });

export const useDriverCheckpoint = (companyId, driverId) =>
  useSelector(state => state.driverLogData.companies[companyId]?.[driverId]?.checkpoint);

export default driverLogSlice.reducer;
