/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Attachment from "./Attachment";
import Job from "./Job";

/**
 * The JobAttachment model module.
 * @module model/JobAttachment
 * @version 1.0
 */
class JobAttachment {
  /**
   * Constructs a new <code>JobAttachment</code>.
   * @alias module:model/JobAttachment
   */
  constructor() {
    JobAttachment.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>JobAttachment</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/JobAttachment} obj Optional instance to populate.
   * @return {module:model/JobAttachment} The populated <code>JobAttachment</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new JobAttachment();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("job")) {
        obj["job"] = Job.constructFromObject(data["job"]);
      }
      if (data.hasOwnProperty("attachment")) {
        obj["attachment"] = Attachment.constructFromObject(data["attachment"]);
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = ApiClient.convertToType(data["type"], "String");
      }
      if (data.hasOwnProperty("notes")) {
        obj["notes"] = ApiClient.convertToType(data["notes"], "String");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
JobAttachment.prototype["id"] = undefined;

/**
 * @member {module:model/Job} job
 */
JobAttachment.prototype["job"] = undefined;

/**
 * @member {module:model/Attachment} attachment
 */
JobAttachment.prototype["attachment"] = undefined;

/**
 * @member {module:model/JobAttachment.TypeEnum} type
 */
JobAttachment.prototype["type"] = undefined;

/**
 * @member {String} notes
 */
JobAttachment.prototype["notes"] = undefined;

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
JobAttachment["TypeEnum"] = {
  /**
   * value: "POD"
   * @const
   */
  POD: "POD",

  /**
   * value: "PHOTO"
   * @const
   */
  PHOTO: "PHOTO",

  /**
   * value: "BINS"
   * @const
   */
  BINS: "BINS"
};

export default JobAttachment;
