export const driverManagementSelectors = {
  filters: state => state.driverManagement.filters,
  calendarView: state => state.driverManagement.calendarView,
  calendarDate: state => state.driverManagement.calendarDate,
  getSchedules: state => state.driverManagement.schedules,
  getDeletedSchedules: state => state.driverManagement.deletedSchedules,
  isFetchingSchedules: state => state.driverManagement.meta.schedules.isFetching,
  isSchedulesError: state => state.driverManagement.meta.schedules.error,
  isSchedulesSuccess: state => state.driverManagement.meta.schedules.success,
  isFetchingDeletedSchedules: state => state.driverManagement.meta.deletedSchedules.isFetching,
  isSchedulesDeletedError: state => state.driverManagement.meta.deletedSchedules.error,
  isSchedulesDeletedSuccess: state => state.driverManagement.meta.deletedSchedules.success,
  getScheduleById: state => state.driverManagement.scheduleById,
  isFetchingScheduleById: state => state.driverManagement.meta.scheduleById.isFetching,
  isScheduleByIdError: state => state.driverManagement.meta.scheduleById.error,
  isScheduleByIdSuccess: state => state.driverManagement.meta.scheduleById.success,
  getTypes: state => state.driverManagement.types,
  isFetchingTypes: state => state.driverManagement.meta.types.isFetching,
  isTypesError: state => state.driverManagement.meta.types.error,
  isTypesSuccess: state => state.driverManagement.meta.types.success,
  getDeletedTypes: state => state.driverManagement.deletedTypes,
  isFetchingDeletedTypes: state => state.driverManagement.meta.deletedTypes.isFetching,
  isDeletedTypesError: state => state.driverManagement.meta.deletedTypes.error,
  isDeletedTypesSuccess: state => state.driverManagement.meta.deletedTypes.success
};
