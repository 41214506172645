import React from 'react';
//hooks
import { useTranslation } from 'react-i18next';
//components
import { Col, Form, Input, Row, Switch } from 'antd';

export const HeaderFormSection = ({
  formItemStyle,
  setCompanyLevel,
  customComponent,
  templateName
}) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          colon={false}
          label={t('CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.NameInputLabel')}
          rules={[
            {
              required: true,
              message: t(
                'CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.NameInputRequiredInput'
              )
            }
          ]}
          name="name"
        >
          <Input maxLength={255} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          label={t(
            'CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.DescriptionInputLabel'
          )}
          name="description"
        >
          <Input.TextArea maxLength={65535} />
        </Form.Item>
      </Col>
      {customComponent && <Col span={12}>{customComponent}</Col>}
      <Col span={12}>
        <Form.Item
          style={formItemStyle}
          label={t(
            'CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.CompanyLevelToggleLabel',
            { templateName: templateName }
          )}
          name="default"
          tooltip={t(
            'CompanyConfig.DeviceConfigurations.AddTemplateHeaderForm.CompanyLevelToggleTooltip',
            { templateName: templateName }
          )}
          valuePropName="checked"
        >
          <Switch onChange={checked => setCompanyLevel && setCompanyLevel(checked)} />
        </Form.Item>
      </Col>
    </Row>
  );
};
