import React from 'react';
import { LoadingTable } from 'components/grid/LoadingTable';
import { AutoSizer, Column, Table } from 'react-virtualized';
import {
  cache,
  nameCellRenderer,
  versionCellRenderer,
  associationsCellRenderer,
  companyCellRenderer,
  ellipsisButtonCellRenderer
} from './CellRenderers';
import { useTranslation } from 'react-i18next';
import { useCan, entities } from 'features/permissions';

export const InspectionChecklistTable = ({
  inspectionChecklists,
  companies,
  fleets,
  isLoading,
  setTableRef,
  typeOfEntityToDelete,
  handleDeleteAction
}) => {
  const { t } = useTranslation();
  const can = useCan();
  const canViewInspection = can && can({ everyEntity: [entities.PRETRIPCHECKLIST_VIEW] });
  if (isLoading) return <LoadingTable columnSizes={[73, 94, 57, 57, 94, 19]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 300,
          company: 150,
          version: 150,
          ellipsisButton: 60
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.name +
            fixedColumnsWidth.company +
            fixedColumnsWidth.version +
            fixedColumnsWidth.ellipsisButton);

        const columnStyle = {
          // padding: "12px 12px 12px 0"
        };
        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            headerStyle={{ paddingTop: '18px' }}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={inspectionChecklists ? inspectionChecklists.length : null}
            rowGetter={({ index }) => inspectionChecklists[index]}
            rowStyle={{ alignItems: 'center' }}
            ref={ref => setTableRef(ref)}
          >
            <Column
              style={columnStyle}
              label={t('PreTripChecklist.Table.Name')}
              dataKey="Name"
              width={fixedColumnsWidth.name}
              cellRenderer={props => nameCellRenderer({ ...props, canViewInspection })}
            />
            <Column
              style={columnStyle}
              label={t('PreTripChecklist.Table.Company')}
              dataKey=""
              width={fixedColumnsWidth.company}
              cellRenderer={props => companyCellRenderer({ ...props, companies: companies })}
            />
            <Column
              style={columnStyle}
              label={t('PreTripChecklist.Table.Version')}
              dataKey="Version"
              cellRenderer={versionCellRenderer}
              width={fixedColumnsWidth.version}
            />
            <Column
              style={columnStyle}
              label={t('PreTripChecklist.Table.Associations')}
              dataKey=""
              cellRenderer={props =>
                associationsCellRenderer({ ...props, companies: companies, fleets: fleets, t })
              }
              width={dynamicColumnWidth}
            />
            <Column
              label={t('PreTripChecklist.Table.Actions')}
              dataKey=""
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.ellipsisButton}
              cellRenderer={props =>
                ellipsisButtonCellRenderer({ ...props, typeOfEntityToDelete, handleDeleteAction })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
