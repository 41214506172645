import React, { useEffect } from 'react';
import { useUser } from 'features/user/userSlice';
import { useCurrentCompany } from 'features/company/companySlice';

import { CHURNZERO_URL, CHURNZERO_APIKEY } from 'config';

export const ChurnZero = () => {
  const company = useCurrentCompany();
  const user = useUser();

  const { salesforceId } = company;
  const { id: userId, siteAdmin } = user;

  const churnZeroScript = `
  var ChurnZero=ChurnZero || [];
    function stopChurnzeroInstance() {
      ChurnZero.push(['trackEvent', 'Logout']);
      ChurnZero.push(['stop']);
    }
    function startChurnzeroInstance() {
      ChurnZero.push(['setAppKey', '${CHURNZERO_APIKEY}']);
      ChurnZero.push(['setContact', '${salesforceId}', '${userId}']);
    }
    (function(a,b,c,d){var cz=a.createElement(b);cz.type=c;cz.async=true;cz.src=d;cz.id='czFunction'
    var s=a.getElementsByTagName(b)[0];s.parentNode.insertBefore(cz, s);})(document, "script",'text/javascript', '${CHURNZERO_URL}');`;

  useEffect(() => {
    // If CZ is not already loaded
    if (!document.querySelector('#churnzero')) {
      // Do not load CZ for siteAdmins and companies without salesforceId
      if (siteAdmin || !salesforceId) {
        return;
      }
      const scriptNode = document.createElement('script');
      scriptNode.id = 'churnzero';
      scriptNode.setAttribute('type', 'text/javascript');
      scriptNode.innerHTML = churnZeroScript;
      document.head.appendChild(scriptNode);
      window.startChurnzeroInstance();
    }

    // If CZ script is loaded -> Remove all scripts and references of ChurnZero
    if (document.querySelector('#churnzero')) {
      if (siteAdmin || !salesforceId) {
        window.stopChurnzeroInstance();
        const scriptNode = document.querySelector('#churnzero');
        const czFunction = document.querySelector('#czFunction');
        const czContainer = document.querySelector('#cz_devless_container');
        scriptNode && document.head.removeChild(scriptNode);
        czFunction && document.head.removeChild(czFunction);
        czContainer && document.body.removeChild(czContainer);
      }
    }
  }, [salesforceId, userId, siteAdmin]);

  return <></>;
};
