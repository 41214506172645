import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

//components
import { Input, Select } from 'components/ant';
import { Col, Row, Button, Form } from 'antd';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';

//slices
import { useCompanies, useCurrentCompany } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { addType, updateType, useIsTypesFetchingFinished } from 'features/driverManagement';

//helpers
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { getIDFromPathname } from 'utils/methods';
import { parseErrorMessage } from 'utils/strings';

//constants
import { ToastType } from 'components/notifications/toasts/Toast';
import { ACTIONS, LabelAlignLeft, InputSizeLarge, Paths, initialValues } from './constants';

//styles
import styles from './DriverMgtTypes.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const DriverMgtTypesForm = ({ action, types = [] }) => {
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const dispatch = useDispatch();
  const history = useHistory();
  const companies = useCompanies();
  const currentCompany = useCurrentCompany();
  const isEdit = action === ACTIONS.EDIT;
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(false);
  const companiesForSelect = (companies || []).map(company => ({
    id: company.id,
    label: company.name
  }));
  const typeData = isEdit && types.find(type => type.id === parseInt(id, 10));
  const isFetchingDone = useIsTypesFetchingFinished();

  const [form] = Form.useForm();

  const handleError = useCallback(
    err => {
      if (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err
          })
        );
      }
      if (history.location.pathname !== Paths.MAIN_PAGE) {
        history.replace(Paths.MAIN_PAGE);
      }
    },
    [history, dispatch]
  );

  useEffect(() => {
    if (isEdit && (id <= 0 || isNaN(id) || (isFetchingDone && !typeData))) {
      handleError(t('Common.Invalid Request ID'));
    }
  }, [t, id, handleError, typeData, isEdit, isFetchingDone]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        isEdit ? `${t('Common.Edit')} ${typeData?.name}` : t('DriverMgtTypes.Form.TitleAdd')
      )
    );
  }, [isEdit, typeData?.name, dispatch]);

  useEffect(() => {
    if (isEdit && isFetchingDone && typeData) {
      form.setFieldsValue(typeData);
    }
  }, [isEdit, isFetchingDone, form, typeData]);

  useEffect(() => {
    !isEdit &&
      form.setFieldsValue({
        companyId: currentCompany.id
      });
  }, [currentCompany, isEdit, form]);

  const handleSave = async () => {
    const values = form.getFieldsValue();

    try {
      const actionCreator = isEdit
        ? updateType({ body: { ...values, id: typeData?.id } })
        : addType({ body: values });
      const response = await dispatch(actionCreator);
      if (response.error) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: `${t(`DriverManagement.error${isEdit ? 'Edit' : 'Add'}Toast`, {
              entity: t('DriverManagement.Type'),
              name: typeData?.name || values.name
            })} ${parseErrorMessage(response.payload)}`
          })
        );
      } else {
        // success
        dispatch(
          openToast({
            type: ToastType.Success,
            message: `${t(`DriverManagement.success${isEdit ? 'Edit' : 'Add'}Toast`, {
              entity: t('DriverManagement.Type'),
              name: typeData?.name || values.name
            })}`
          })
        );
        // Go back to the table page
        setPromptModalWhenLeaving(false);
        history.push(Paths.MAIN_PAGE);
      }
    } catch (err) {
      console.error(err);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(`DriverManagement.error${isEdit ? 'Edit' : 'Add'}Toast`, {
            entity: t('DriverManagement.Type'),
            name: typeData?.name || values.name
          })} ${parseErrorMessage(err)}`
        })
      );
    }
  };

  const setDirtyForm = () => {
    setPromptModalWhenLeaving(true);
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <div className={styles.formContainer}>
      <EditRouteGuard when={promptModalWhenLeaving} navigate={history.push} />
      <Form
        initialValues={typeData || initialValues}
        layout="vertical"
        form={form}
        onFinish={handleSave}
        onValuesChange={setDirtyForm}
      >
        <div className={styles.formCard}>
          <Row>
            <Col span={12}>
              <Form.Item
                className={styles.inputLeft}
                name="name"
                labelAlign={LabelAlignLeft}
                label={t(`DriverMgtTypes.Form.Name`)}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t('DriverMgtTypes.Form.NameRequired'),
                    whitespace: true
                  }
                ]}
              >
                <Input
                  size={InputSizeLarge}
                  placeholder={t('DriverMgtTypes.Form.NamePlaceholder')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className={styles.inputRight}
                name="companyId"
                labelAlign={LabelAlignLeft}
                rules={[
                  {
                    required: true,
                    message: t('DriverMgtTypes.Form.CompanyRequired')
                  }
                ]}
                colon={false}
                label={t(`Common.Company`)}
              >
                <Select
                  size={'large'}
                  placeholder={t(`DriverMgtTypes.Form.SelectCompany`)}
                  data={companiesForSelect}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="description"
                labelAlign={LabelAlignLeft}
                colon={false}
                label={t(`DriverMgtTypes.Form.Description`)}
              >
                <Input
                  size={InputSizeLarge}
                  placeholder={t('DriverMgtTypes.Form.DescriptionPlaceholder')}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.formFooter}>
          <Button
            id={BUTTON_IDS.driverMgtTypeFormSave}
            size="large"
            htmlType="submit"
            type="primary"
            className={styles.saveButton}
          >
            {t(`Common.Save`)}
          </Button>
          <Button
            id={BUTTON_IDS.driverMgtTypeFormCancel}
            size="large"
            type="secondary"
            className={styles.cancelButton}
            onClick={handleCancel}
          >
            {t(`Common.Cancel`)}
          </Button>
        </div>
      </Form>
    </div>
  );
};
