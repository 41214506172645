/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import AddressAttribute from "./AddressAttribute";

/**
 * The Address model module.
 * @module model/Address
 * @version 1.0
 */
class Address {
  /**
   * Constructs a new <code>Address</code>.
   * @alias module:model/Address
   */
  constructor() {
    Address.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Address</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Address} obj Optional instance to populate.
   * @return {module:model/Address} The populated <code>Address</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Address();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("number")) {
        obj["number"] = ApiClient.convertToType(data["number"], "String");
      }
      if (data.hasOwnProperty("street")) {
        obj["street"] = ApiClient.convertToType(data["street"], "String");
      }
      if (data.hasOwnProperty("suburb")) {
        obj["suburb"] = ApiClient.convertToType(data["suburb"], "String");
      }
      if (data.hasOwnProperty("state")) {
        obj["state"] = ApiClient.convertToType(data["state"], "String");
      }
      if (data.hasOwnProperty("postcode")) {
        obj["postcode"] = ApiClient.convertToType(data["postcode"], "String");
      }
      if (data.hasOwnProperty("country")) {
        obj["country"] = ApiClient.convertToType(data["country"], "String");
      }
      if (data.hasOwnProperty("phone")) {
        obj["phone"] = ApiClient.convertToType(data["phone"], "String");
      }
      if (data.hasOwnProperty("fax")) {
        obj["fax"] = ApiClient.convertToType(data["fax"], "String");
      }
      if (data.hasOwnProperty("attributes")) {
        obj["attributes"] = ApiClient.convertToType(data["attributes"], [
          AddressAttribute
        ]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Address.prototype["id"] = undefined;

/**
 * @member {String} number
 */
Address.prototype["number"] = undefined;

/**
 * @member {String} street
 */
Address.prototype["street"] = undefined;

/**
 * @member {String} suburb
 */
Address.prototype["suburb"] = undefined;

/**
 * @member {String} state
 */
Address.prototype["state"] = undefined;

/**
 * @member {String} postcode
 */
Address.prototype["postcode"] = undefined;

/**
 * @member {String} country
 */
Address.prototype["country"] = undefined;

/**
 * @member {String} phone
 */
Address.prototype["phone"] = undefined;

/**
 * @member {String} fax
 */
Address.prototype["fax"] = undefined;

/**
 * @member {Array.<module:model/AddressAttribute>} attributes
 */
Address.prototype["attributes"] = undefined;

export default Address;
