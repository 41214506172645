import { ToastType } from 'components/notifications/toasts/Toast';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { openToast } from 'features/toasts/toastsSlice';
import { api } from 'utils/api';

export const sendRouteToMessage = ({ message, address, location, imei, t }) => async (
  dispatch,
  getState
) => {
  const userKey = getState().user.current.auth.key;
  const body = {
    deviceImei: imei,
    routeToAddress: address,
    message,
    GPS: {
      Lat: location.lat,
      Lng: location.lng
    }
  };
  try {
    const response = await api.post('/messages/routeTo', { authKey: userKey }, body);
    if (response?.body) {
      console.log(response.body);
      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('Messaging.MessageSent')
        })
      );
      Mixpanel.sendTrackEvent(MPTrackingEvents.Messaging.SendMessage, {
        sendSuccess: true,
        messageType: MPTrackingEvents.Messaging.MessageType.RouteTo
      });
    }
  } catch (err) {
    const message = err.response?.body?.error || err.message;
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${message}`
      })
    );
    Mixpanel.sendTrackEvent(MPTrackingEvents.Messaging.SendMessage, {
      sendSuccess: false,
      messageType: MPTrackingEvents.Messaging.MessageType.RouteTo
    });
  }
};
