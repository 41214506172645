import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import moment from 'moment';
import { Table } from 'components/ant';
import { Row, Col, Divider, Button, Spin } from 'antd';

import { GeneratePdf } from 'containers/Configuration/TCAEnrolments/GeneratePdf/GeneratePdf';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useCurrentCompany, useCompanies } from 'features/company/companySlice';
import { Can, FeatureFlag } from 'features/permissions';
import { useDevices, useIsFetching as useIsFetchingDevices } from 'features/fleets/fleetsSlice';
import {
  useSchemes,
  useIsSchemesFetching,
  useEnrolments,
  useIsEnrolmentsFetching
} from 'features/enrolments';
import { getCompanySchemes, parseEnrolment, getDeviceName } from '../EnrolmentsForm/helpers';
import { Paths } from '../';
import { ITEM_NAMES, ITEM_LABELS } from '../EnrolmentsForm/constants';
import {
  DeviceIMEITooltip,
  cancelEnrolmentModal,
  convertEnrolmentIDWithPrefixAndZeroPadding
} from '../helpers';
import { EnrolmentStatuses } from '../constants';
import { BUTTON_IDS } from 'utils/globalConstants';

import styles from './ViewEnrolment.module.scss';

export const ViewEnrolment = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const history = useHistory();
  const { id: enrolmentId } = useParams();
  const dispatch = useDispatch();

  const currentCompany = useCurrentCompany();
  const companies = useCompanies();
  const devices = useDevices();
  const enrolments = useEnrolments();
  const schemes = useSchemes(currentCompany?.id);
  const companySchemes = getCompanySchemes(schemes);

  const isDevicesFetching = useIsFetchingDevices();
  const isSchemesFetching = useIsSchemesFetching();
  const isEnrolmentsFetching = useIsEnrolmentsFetching();

  const isLoading = isDevicesFetching || isSchemesFetching || isEnrolmentsFetching;

  const getEnrolment = id => {
    return enrolments.find(enrolment => enrolment.id === +id);
  };
  const [enrolmentDetails, setEnrolmentDetails] = useState([]);
  const [selectedEnrolment, setSelectedEnrolment] = useState(null);

  const schemeTableColumns = [
    {
      title: t('TCAEnrolments.Form.SchemeCode'),
      dataIndex: 'schemeCode',
      key: 'schemeCode'
    },
    {
      title: t('TCAEnrolments.Form.SchemeName'),
      dataIndex: 'schemeName',
      key: 'schemeName'
    },
    {
      title: t('TCAEnrolments.Form.AuthorityCode'),
      dataIndex: 'authorityCode',
      key: 'authorityCode'
    }
  ];

  const getTableData = scheme => {
    return [
      {
        key: '1',
        schemeCode: scheme?.id,
        schemeName: scheme?.name,
        authorityCode: scheme?.authorityCode
      }
    ];
  };

  useEffect(() => {
    dispatch(setPageTitle(convertEnrolmentIDWithPrefixAndZeroPadding(selectedEnrolment?.id)));
    dispatch(setBackButton(true));
  }, [selectedEnrolment, dispatch, t]);

  useEffect(() => {
    const enrolment = parseEnrolment(getEnrolment(enrolmentId ?? null));
    setSelectedEnrolment(enrolment);

    const enrolmentScheme = companySchemes?.find(scheme => scheme.id === enrolment?.scheme);

    setEnrolmentDetails([
      {
        label: t(ITEM_LABELS[ITEM_NAMES.COMPANY_ID]),
        value:
          companies?.find(company => enrolment?.[ITEM_NAMES.COMPANY_ID] === company.id)?.name ||
          currentCompany.name
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.DEVICE_IMEI]]),
        value: (
          <DeviceIMEITooltip
            name={getDeviceName(enrolment?.deviceId, devices)}
            imei={enrolment?.deviceDetails?.imei}
          />
        )
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER]]),
        value: enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER]
      },
      {
        label: t(ITEM_LABELS[ITEM_NAMES.PBS]),
        value: enrolment?.vehicleDetails?.[ITEM_NAMES.PBS]?.join()
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.VEHICLE_REGISTRATION_STATE]]),
        value: enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_REGISTRATION_STATE]
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.VEHICLE_VIN]]),
        value:
          enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_VIN] ||
          enrolment?.vehicleDetails?.[ITEM_NAMES.VEHICLE_NON_VIN]
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.DEVICE_INSTALLATION_TIME]]),
        value: moment(enrolment?.deviceDetails?.[ITEM_NAMES.DEVICE_INSTALLATION_TIME]).format(
          localization.formats.time.formats.dby_imp
        )
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.DEVICE_INSTALLATION_LOCATION]]),
        value: enrolment?.deviceDetails?.[ITEM_NAMES.DEVICE_INSTALLATION_LOCATION]
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.SCHEME]]),
        value: (
          <Table
            dataSource={getTableData(enrolmentScheme)}
            columns={schemeTableColumns}
            pagination={false}
            loading={false}
            rowKey="key"
          />
        )
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.OPERATOR_NAME]]),
        value: enrolment?.companyDetails?.identity?.[ITEM_NAMES.OPERATOR_NAME]
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.OPERATOR_ABN]]),
        value: enrolment?.companyDetails?.identity?.[ITEM_NAMES.OPERATOR_ABN]
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE]]),
        value: enrolment?.companyDetails?.postalAddress?.[ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE]
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY]]),
        value: enrolment?.companyDetails?.postalAddress?.[ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY]
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.OPERATOR_ADDRESS_STATE]]),
        value: enrolment?.companyDetails?.postalAddress?.[ITEM_NAMES.OPERATOR_ADDRESS_STATE]
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE]]),
        value: enrolment?.companyDetails?.postalAddress?.[ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE]
      },
      {
        label: t(ITEM_LABELS[[ITEM_NAMES.OPERATOR_BUSINESS_HOURS]]),
        value: enrolment?.companyDetails?.[ITEM_NAMES.OPERATOR_BUSINESS_HOURS]
      }
    ]);
  }, [enrolments, currentCompany?.id, devices, schemes]);

  return (
    <>
      <div className={styles.actionButtons}>
        <Button
          type="secondary"
          onClick={() => {
            history.push(`${Paths.EDIT_ENROLMENT}/${enrolmentId}`);
          }}
          disabled={selectedEnrolment?.status === EnrolmentStatuses.CANCELLED}
          id={BUTTON_IDS.tcaEnrolmentsViewNew}
        >
          {t('Common.Edit')}
        </Button>
        <Can featureFlag={FeatureFlag.generateTCAPDF.flag}>
          <GeneratePdf enrolmentId={enrolmentId} enrolments={enrolments} schemes={schemes} />
        </Can>
        <Button
          type="secondary"
          danger
          onClick={cancelEnrolmentModal(dispatch, t, selectedEnrolment)}
          disabled={selectedEnrolment?.status === EnrolmentStatuses.CANCELLED}
          id={BUTTON_IDS.tcaEnrolmentsViewExportReport}
        >
          {t('TCAEnrolments.CancelEnrolment')}
        </Button>
      </div>
      <Divider className={styles.headerDivider} />
      <Spin spinning={isLoading}>
        <div className={styles.container}>
          {enrolmentDetails.map((item, itemIndex) => (
            <React.Fragment key={`details-item-${itemIndex}`}>
              {item.featureFlag ? (
                <Can featureFlag={item.featureFlag}>
                  <Row>
                    <Col span={8}>
                      <span className={styles.label}>{item.label}</span>
                    </Col>
                    <Col span={16}>
                      <span>{item.value}</span>
                    </Col>
                  </Row>
                  <Divider className={styles.rowDivider} />
                </Can>
              ) : (
                <>
                  <Row>
                    <Col span={8}>
                      <span className={styles.label}>{item.label}</span>
                    </Col>
                    <Col span={16}>
                      <span>{item.value}</span>
                    </Col>
                  </Row>
                  <Divider className={styles.rowDivider} />
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </Spin>
    </>
  );
};
