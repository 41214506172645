import React, { useState, useEffect, useCallback, useRef } from 'react';
import { OverviewGrid } from './OverviewGrid';
import { useCurrentCompany } from 'features/company/companySlice';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useBranches } from 'features/locations/locationsSlice';
import { useDrivers } from 'features/users/usersSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { OverviewToolbar } from './OverviewToolbar';
import { useFleets, useDevices, useVehicles } from 'features/fleets/fleetsSlice';
import { OverviewGridColumns } from './Grid/columns';
import { sortBy } from 'lodash';
import { PATH_SPLIT, selectAll } from 'components/form/treeselect/TreeSelect';
import styles from './Overview.module.scss';
import { OverviewChart } from './OverviewChart';
import { useGetDriversELDData, fetchOverviewELDData } from 'features/eld/eldSlice';
import { format } from 'utils/dates';
import { LoadingProgress } from 'components/loading/LoadingProgress';
import {
  DriverStatusById,
  DriverStatusId,
  ExemptionStatus,
  WorkStatus,
  UserTypeId
} from '../Constants';
import { useTimer } from 'utils/hooks/useTimer';
import moment from 'moment';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useTranslation } from 'react-i18next';
import { useUser } from 'features/user/userSlice';
import { DutyStatusDescription } from '../util/dutystatus';

const TWO_HOURS = 2 * 60 * 60;

function processDriverELDData({ eldData, branchesMap, driver, localization, vehiclesMap }) {
  const vehicle = vehiclesMap[eldData?.lastEvent?.vehicle?.id];
  const branch = branchesMap[driver?.location?.id];
  const fleet = vehicle && vehicle?.id ? vehicle?.fleets?.map(f => f.name).join(', ') : 'N/A';
  const lastUpdateTime =
    eldData?.checkpointAt ||
    eldData?.pivotTimeAt ||
    eldData?.lastEvent?.timeAt ||
    eldData?.lastEvent?.eventAt;

  const data = {
    key: driver?.id,
    driverName: (driver?.firstName || '') + ' ' + (driver?.lastName || ''),
    vehicleName: vehicle?.name || 'N/A',
    type: eldData?.lastStatusEvent?.action || eldData?.lastEvent?.type || 'OffDuty',
    status:
      DriverStatusId[eldData?.lastStatusEvent?.action || eldData?.lastEvent?.type || 'OffDuty'],
    branch: branch?.name || 'N/A',
    fleet: fleet || 'N/A',
    location: eldData?.lastEvent?.location || 'N/A',
    lastUpdate: lastUpdateTime
      ? format(lastUpdateTime, localization.formats.time.formats.dby_imp)
      : 'N/A',
    // unformatted last update time used for filtering
    lastUpdateTime: lastUpdateTime,
    driveTimeAvailable: eldData?.drive24?.available || 0,
    driveTimeLeft: eldData?.drive24?.remaining || 0,
    driveTime: eldData?.drive24?.work || 0,
    onDutyTimeAvailable: eldData?.work24?.available || 0,
    onDutyTimeLeft: eldData?.work24?.remaining || 0,
    onDutyTime: eldData?.work24?.work || 0,
    cycleTimeLeft: eldData?.totals?.available || 0,
    cycleTime: eldData?.totals?.worked || 0,
    hasViolations: false,
    hasRiskOfViolations: false,
    exemptStatus: false,
    isExemptDriver: driver?.type?.id === UserTypeId.Exempt || false
  };

  data.exemptStatus = ExemptionStatus[data.type];
  data.typeDesc = DutyStatusDescription[data.type];

  if (!data.isExemptDriver) {
    data.hasViolations =
      (eldData?.currentViolations?.length > 0 && data.status === DriverStatusId.Driving) || false;
    if (data.status === DriverStatusId.Driving && data.hasViolations) {
      data.driveTimeStatus = WorkStatus.InVio;
    }

    if (
      (data.status === DriverStatusId.OnDuty || data.onDutyTimeAvailable <= 0) &&
      data.hasViolations
    ) {
      data.onDutyTimeStatus = WorkStatus.InVio;
    }

    if (data.cycleTimeLeft <= 0 && data.hasViolations) {
      data.cycleTimeStatus = WorkStatus.InVio;
    }

    if (!data.hasViolations) {
      if (data.driveTimeAvailable < TWO_HOURS && eldData?.drive24) {
        data.driveTimeStatus = WorkStatus.AtRisk;
        data.hasRiskOfViolations = true;
      }

      if (data.onDutyTimeAvailable < TWO_HOURS && eldData?.work24) {
        data.onDutyTimeStatus = WorkStatus.AtRisk;
        data.hasRiskOfViolations = true;
      }

      if (data.cycleTimeLeft < TWO_HOURS && eldData?.totals) {
        data.cycleTimeStatus = WorkStatus.AtRisk;
        data.hasRiskOfViolations = true;
      }
    }
  }
  return data;
}

export function Overview() {
  const dispatch = useDispatch();
  const history = useHistory();
  const company = useCurrentCompany();
  const fleets = useFleets();
  const devices = useDevices();
  const vehicles = useVehicles();
  const vehiclesMap = vehicles.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
  const branches = useBranches();
  const branchesMap = branches.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
  const driverList = useDrivers();
  const localization = useLocalization();
  const fetchPercentage = 1;
  const eldDriverData = useGetDriversELDData(company.id);
  const isFetching = false;
  const [initTableData, setInitTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState(history.location.state?.searchText || null);
  const [fleetsFilter, setFleetsFilter] = useState(history.location.state?.fleetsFilter || null);
  const [branchesFilter, setBranchesFilter] = useState(
    history.location.state?.branchesFilter || null
  );
  const [generalFilter, setGeneralFilter] = useState(history.location.state?.generalFilter || null);
  const [initGeneralFilter, setInitGeneralFilter] = useState(
    history.location.state?.generalFilter || null
  );
  const [lastUpdateFilters, setLastUpdateFilters] = useState(
    history.location.state?.lastUpdateFilters || null
  );
  const [chartFilter, setChartFilter] = useState(null);
  const [chartData, setChartData] = useState(null);
  const isTimerAction = useRef(false);
  const { t } = useTranslation();
  const currentUser = useUser();
  const [loading, setLoading] = useState(false);

  const handleFleetsChanged = useCallback(fleets => {
    setFleetsFilter(fleets);

    // Save filter state to history so it can be resored when navigating back
    history.replace(history.location.pathname, { ...history.location.state, fleetsFilter: fleets });
  }, []);

  const handleBranchChanged = useCallback(branches => {
    setBranchesFilter(branches);

    // Save filter state to history so it can be resored when navigating back
    history.replace(history.location.pathname, {
      ...history.location.state,
      branchesFilter: branches
    });
  }, []);

  const handleGeneralFilterChange = useCallback(generalFilters => {
    setGeneralFilter(generalFilters);

    // Save filter state to history so it can be resored when navigating back
    history.replace(history.location.pathname, {
      ...history.location.state,
      generalFilter: generalFilters
    });
  }, []);

  const handleLastUpdateFiltersChange = useCallback(luFilters => {
    setLastUpdateFilters(luFilters);

    // Save filter state to history so it can be resored when navigating back
    history.replace(history.location.pathname, {
      ...history.location.state,
      lastUpdateFilters: luFilters
    });
  }, []);

  const handleSearchChange = useCallback(searchText => {
    setSearchText(searchText);

    // Save filter state to history so it can be resored when navigating back
    history.replace(history.location.pathname, {
      ...history.location.state,
      searchText: searchText
    });
  }, []);

  useEffect(() => {
    dispatch(setPageTitle(t('Common.ELDFatigue')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  useEffect(() => {
    if (!company || !currentUser || !driverList?.length) {
      setLoading(true);
      return;
    }
    isTimerAction.current = false;
    dispatch(
      fetchOverviewELDData(
        driverList.map(d => {
          return { id: d.id };
        }),
        company.api_key,
        company.id,
        currentUser.auth.key
      )
    ).then(() => {
      setLoading(false);
    });
  }, [dispatch, driverList, company, currentUser]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    const data = [];
    const generalFilters = {};
    const valueGroups = {};
    const chartData = {
      driving: 0,
      onDuty: 0,
      sleeperBerth: 0,
      offDuty: 0,
      riskVio: 0,
      inVio: 0,
      underExp: 0,
      totalDriver: 0
    };
    chartData.totalDriver = driverList.length;
    const fromDate = moment()
      .startOf('day')
      .toDate();
    const toDate = moment(fromDate)
      .endOf('day')
      .toDate();

    for (const driver of driverList) {
      const eldData = eldDriverData?.[driver.id]?.[fromDate.getTime() + '_' + toDate.getTime()];
      const row = processDriverELDData({
        eldData,
        branchesMap,
        driver,
        localization,
        vehiclesMap
      });
      switch (DriverStatusById[row.status]) {
        case 'Driving':
          chartData.driving++;
          break;
        case 'Work':
        case 'OnDuty':
          chartData.onDuty++;
          break;
        case 'SleeperBerth':
          chartData.sleeperBerth++;
          break;
        case 'Rest':
        case 'OffDuty':
          chartData.offDuty++;
          break;
        default:
          break;
      }
      if (row.hasViolations) {
        chartData.inVio++;
      }
      if (row.hasRiskOfViolations) {
        chartData.riskVio++;
      }
      if (row.exemptStatus) {
        chartData.underExp++;
      }
      data.push(row);
    }
    setChartData(chartData);

    // generate filters if they are not already there in history/page state
    if (!history.location.state?.generalFilter) {
      for (let idx = 0; idx < OverviewGridColumns.length; idx++) {
        const column = OverviewGridColumns[idx];
        if (column.generalFilter) {
          const node = {
            label: column.title,
            function: undefined,
            nodeKey: (idx + 1).toString(),
            id: idx + 1,
            children: [
              {
                ...selectAll,
                label: t('Common.' + selectAll.name),
                nodeKey: idx + 1 + PATH_SPLIT + 0
              }
            ]
          };
          generalFilters[node.id] = node;
          valueGroups[node.id] = {};

          const sortedList = sortBy(data, [o => o[column.dataIndex]]);

          for (let i = 0, l = sortedList.length; i < l; i++) {
            const item = sortedList[i];
            const parentNode = generalFilters[idx + 1];
            const value = item[column.dataIndex];
            if (!value) {
              continue;
            }
            if (!valueGroups[parentNode.id][value]) {
              const nodeId = parentNode.children.length;
              const childNode = {
                id: nodeId,
                label: item[column.dataIndex],
                function: undefined,
                nodeKey: parentNode.nodeKey + PATH_SPLIT + nodeId,
                dataKey: column.dataIndex
              };
              parentNode.children.push(childNode);
              valueGroups[parentNode.id][value] = true;
            }
          }
        }
      }
      setInitGeneralFilter(generalFilters);
    }

    setInitTableData(data);
  }, [
    isFetching,
    eldDriverData,
    fleets,
    branches,
    driverList,
    devices,
    localization,
    isTimerAction
  ]);

  useEffect(() => {
    const dateTimeNow = moment().startOf('day');
    const data = [];
    for (let row of initTableData) {
      let matchSearch = true;

      if (chartFilter) {
        if (chartFilter.selectedStatus?.selected) {
          matchSearch = DriverStatusById[row.status] === chartFilter.selectedStatus.id;
        }

        if (
          matchSearch &&
          (chartFilter.riskVioSelected || chartFilter.inVioSelected || chartFilter.underExp)
        ) {
          matchSearch =
            (chartFilter.riskVioSelected && row.hasRiskOfViolations) ||
            (chartFilter.inVioSelected && row.hasViolations) ||
            (chartFilter.underExp && row.exemptStatus);
        }
      }
      if (matchSearch && searchText && searchText.trim()) {
        const i_searchText = searchText.toLowerCase();
        matchSearch = OverviewGridColumns.filter(c => c.canSearch).some(c => {
          return (
            row?.[c.dataIndex]
              ?.toString()
              .toLowerCase()
              .indexOf(i_searchText) >= 0
          );
        });
      }

      if (
        matchSearch &&
        branchesFilter &&
        !branchesFilter.find(b => b.label === t('Users.All Branches'))?.checked
      ) {
        matchSearch = branchesFilter.some(
          f =>
            f.checked &&
            (f.label === row.branch || (f.label === t('Users.NoBranch') && row.branch === 'N/A'))
        );
      }

      if (
        matchSearch &&
        fleetsFilter &&
        !fleetsFilter.find(f => f.label === t('Common.AllFleets'))?.checked
      ) {
        const fleets = row.fleet?.split(', ');
        matchSearch = fleetsFilter.some(
          f =>
            f.checked &&
            (fleets.some(f1 => f1 !== 'N/A' && f1 === f.label) ||
              (f.label === t('Common.NoFleet') && fleets.some(f1 => f1 === 'N/A')))
        );
      }

      if (matchSearch && lastUpdateFilters) {
        // Apply last update filter
        const daysSinceUpdate = row.lastUpdateTime
          ? dateTimeNow.diff(moment(row.lastUpdateTime).startOf('day'), 'days')
          : 180; // if no lastUpdateTime, just put large number here so they only show for 'All' and'1 month +'

        matchSearch = lastUpdateFilters.some(f => {
          if (f.checked) {
            switch (f.id) {
              case '0_7_days':
                return daysSinceUpdate <= 7;
              case '8_14_days':
                return daysSinceUpdate >= 8 && daysSinceUpdate <= 14;
              case '15_30_days':
                return daysSinceUpdate >= 15 && daysSinceUpdate <= 30;
              case '1_month_plus':
                return daysSinceUpdate > 30;
              case 0:
              default:
                return true;
            }
          }
          return false;
        });
      }

      if (matchSearch && generalFilter) {
        for (let key in generalFilter) {
          if (
            generalFilter[key].children[0].checked ||
            !generalFilter[key].children.some(c => c.checked)
          ) {
            continue;
          }

          for (let idx = 1; idx < generalFilter[key].children.length; idx++) {
            const node = generalFilter[key].children[idx];
            if (node.checked) {
              if (row[node.dataKey] !== node.label) {
                matchSearch = false;
              } else {
                matchSearch = true;
                break;
              }
            }
          }

          if (!matchSearch) {
            break;
          }
        }
      }

      if (matchSearch) {
        data.push(row);
      }
    }

    setTableData(data);
  }, [
    initTableData,
    searchText,
    branchesFilter,
    fleetsFilter,
    lastUpdateFilters,
    generalFilter,
    chartFilter,
    eldDriverData,
    isTimerAction
  ]);

  const timerCallback = useCallback(() => {
    isTimerAction.current = true;
    if (!company || !currentUser) return;
    return dispatch(
      fetchOverviewELDData(
        driverList.map(d => {
          return { id: d.id };
        }),
        company.api_key,
        company.id,
        currentUser.auth.key
      )
    ).then(() => {
      isTimerAction.current = false;
    });
  }, [dispatch, driverList, company, currentUser]);

  useTimer(20 * 1000, timerCallback);
  return (
    <div className={styles.fatigueManagement}>
      {isFetching && (
        <LoadingProgress percent={parseInt(fetchPercentage * 100)} progressColor="#9cca55" />
      )}
      {!isFetching && (
        <>
          <OverviewChart data={chartData} onChartFilterChanged={setChartFilter} />
          <OverviewToolbar
            gridCount={tableData.length}
            filters={initGeneralFilter}
            searchText={searchText}
            onBranchChange={handleBranchChanged}
            onFleetChange={handleFleetsChanged}
            onGeneralFilterChange={handleGeneralFilterChange}
            onLastUpdateFiltersChange={handleLastUpdateFiltersChange}
            onSearchChange={handleSearchChange}
          />
          <OverviewGrid data={tableData} isLoading={loading} />
        </>
      )}
    </div>
  );
}
