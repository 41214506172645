import {
  stateCellRenderer,
  normalCellRenderer,
  commentsCellRenderer,
  actionsCellRenderer,
  durationCellRender
} from './CellRenders';
import { SortUtil } from 'components/tables/SortUtil';
import i18next from 'i18nextConfig';

export const UDTGridColumns = [
  {
    title: '',
    key: 'statusCell',
    width: 38,
    render: stateCellRenderer,
    fixed: 'left'
  },
  {
    get title() {
      return i18next.t('Common.Vehicle');
    },
    key: 'vehicleName',
    dataIndex: 'vehicleName',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'vehicleName'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true,
    canSearch: true,
    fixed: 'left',
    width: 150
  },
  {
    get title() {
      return i18next.t('Common.Fleet');
    },
    key: 'fleet',
    dataIndex: 'fleet',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'fleet'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: false,
    width: 100
  },
  {
    get title() {
      return i18next.t('ELD.Start Time');
    },
    key: 'startTime',
    dataIndex: 'startTime',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortDate(a, b, 'startTime'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    defaultSortOrder: 'ascend',
    generalFilter: true,
    canSearch: true,
    width: 175
  },
  {
    get title() {
      return i18next.t('ELD.End Time');
    },
    key: 'endtTime',
    dataIndex: 'endTime',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortDate(a, b, 'endTime'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true,
    canSearch: true,
    width: 175
  },
  {
    get title() {
      return i18next.t('ELD.Duration') + ' (' + i18next.t('ELD.DurationFormat') + ')';
    },
    key: 'duration',
    dataIndex: 'duration',
    render: durationCellRender,
    sorter: (a, b) => SortUtil.sortNumber(a, b, 'duration'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true,
    canSearch: true,
    width: 130,
    label: value =>
      value == null
        ? ''
        : Math.floor(value / 3600)
            .toString()
            .padStart(2, 0) +
          ':' +
          (Math.floor(value / 60) % 60).toString().padStart(2, 0) +
          ':' +
          Math.floor(value % 60)
            .toString()
            .padStart(2, 0)
  },
  {
    get title() {
      return i18next.t('ELD.Start Location');
    },
    key: 'startLocation',
    dataIndex: 'startLocation',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'startLocation'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true,
    canSearch: true,
    width: 200
  },
  {
    get title() {
      return i18next.t('ELD.End Location');
    },
    key: 'endLocation',
    dataIndex: 'endLocation',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'endLocation'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true,
    canSearch: true,
    width: 200
  },
  {
    get title() {
      return i18next.t('ELD.Distance');
    },
    key: 'distance',
    dataIndex: 'distance',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortNumber(a, b, 'distance'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: false,
    canSearch: false,
    width: 120
  },
  {
    get title() {
      return i18next.t('ELD.Comments');
    },
    key: 'comments',
    dataIndex: 'comments',
    render: commentsCellRenderer,
    canSearch: true,
    width: 200
  },
  {
    get title() {
      return i18next.t('Common.AssignedTo');
    },
    key: 'assignedTo',
    dataIndex: 'assignedTo',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'assignedTo'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true,
    canSearch: true,
    width: 150
  },
  {
    get title() {
      return i18next.t('Common.SuggestedBy');
    },
    key: 'suggestedBy',
    dataIndex: 'suggestedBy',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'suggestedBy'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true,
    canSearch: true,
    width: 150
  },
  {
    get title() {
      return i18next.t('Common.TableColumns.Actions');
    },
    key: 'actions',
    dataIndex: 'actions',
    render: actionsCellRenderer,
    sortDirections: ['ascend', 'descend', 'ascend'],
    fixed: 'right',
    width: 75
  }
];

export const UDTByVehicleGridColumns = [
  {
    get title() {
      return i18next.t('Common.Vehicle');
    },
    key: 'vehicleName',
    dataIndex: 'vehicleName',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'vehicleName'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    defaultSortOrder: 'descend',
    generalFilter: true,
    canSearch: true
  },
  {
    get title() {
      return i18next.t('ELD.Events');
    },
    key: 'numEvents',
    dataIndex: 'numEvents',
    render: normalCellRenderer,
    sorter: (a, b) => SortUtil.sortNumber(a, b, 'numEvents'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: false,
    canSearch: false,
    width: 200
  }
];
