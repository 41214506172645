import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import request from 'superagent';
import { API_PATH } from 'config';

import { useGetDeviceGPIOQuery } from 'services/nextgen';

import { useCurrentCompany } from 'features/company/companySlice';
import { useWithinConfig, useIsFetchingWithinConfig } from 'features/company_config/hooks';
import { useUser, useUserKey } from 'features/user/userSlice';
import { useCanEveryRole } from 'features/permissions/canHooks';
import { GlobalRoles } from 'features/permissions';
import { useVehicle } from 'features/fleets/fleetsSlice';
import {
  setBackButton,
  setPageTitle,
  addBreadcrumbs,
  addBackButtonLink
} from 'features/page/pageSlice';

import { GPInputsTable, GPOutputsTable } from './Tables';

export const VehicleGPIO = ({ id, isModal }) => {
  const [device, setDevice] = useState();
  const [disableImmobilise, setDisableImmobilise] = useState(null);

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { vehicleId } = useParams();
  const currentCompany = useCurrentCompany();
  const userKey = useUserKey();
  const user = useUser();
  const canAdminRole = useCanEveryRole([GlobalRoles.Admin]);
  const withinConfig = useWithinConfig(currentCompany?.id);
  const isFetchingWithinConfig = useIsFetchingWithinConfig(currentCompany?.id);
  const vehicle = useVehicle(id || vehicleId, 'devices', 'HERMES');
  const gpio = useGetDeviceGPIOQuery(device, {
    skip: !device?.id,
    pollingInterval: 30000
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (!isModal) {
      dispatch(setPageTitle(t('GPIO.GPIO')));
      dispatch(setBackButton(true));

      if (vehicle) {
        dispatch(
          addBreadcrumbs([
            {
              breadcrumbName: t('Vehicles.Vehicles'),
              path: '/settings/vehicles'
            },
            {
              breadcrumbName: vehicle?.name,
              path: `/settings/vehicles/id/${vehicle?.id}`
            },
            {}
          ])
        );
        addBackButtonLink({
          url: pathname,
          backLink: `/settings/vehicles/id/${vehicle?.id}`
        });
      }

      return () => {
        dispatch(addBreadcrumbs([]));
      };
    }
  }, [dispatch, vehicle]);

  useEffect(() => {
    console.debug('VehicleGPIO - devices', vehicle?.devices);
    setDevice(vehicle?.devices?.find(device => device.id > 0));

    if (vehicle?.id !== Number(vehicleId)) {
      // if vehicle changed, reset disableImmobilise back to null so it checks for new vehicle
      setDisableImmobilise(null);
    }
  }, [vehicle]);

  const getVehicleWithinUserGeofence = async (vehicleId, userId, userKey) =>
    request('GET', `${API_PATH}/vehicles/${vehicleId}/within`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .query({ location_type: 'branch', user_id: userId })
      .then(res => res.body || []);

  useEffect(() => {
    // Only check if it isn't set yet
    if (disableImmobilise === null) {
      // only check vehicle within api call if not siteAdmin or global Admin role
      if (!canAdminRole) {
        if (gpio.data) {
          // only check vehicle within api call if vehicle/device has gpio out of type immobilise
          const hasImmobilizer = gpio.data?.outputs?.find(
            item => item?.description === 'immobilise'
          );
          if (hasImmobilizer) {
            if (!isFetchingWithinConfig) {
              // Check if company has vehicle within check enabled
              if (
                withinConfig &&
                withinConfig.immobilizer?.enabled &&
                withinConfig.immobilizer?.location_type === 'branch'
              ) {
                getVehicleWithinUserGeofence(vehicle?.id, user?.id, userKey)
                  .then(locations => {
                    const newDisableImmobilise = !(locations && locations.length);
                    setDisableImmobilise(newDisableImmobilise);
                  })
                  .catch(err => {
                    setDisableImmobilise(true);
                  });
              } else {
                setDisableImmobilise(false);
              }
            }
          } else {
            setDisableImmobilise(false);
          }
        }
      } else {
        setDisableImmobilise(false);
      }
    }
  }, [
    withinConfig,
    isFetchingWithinConfig,
    disableImmobilise,
    gpio.data,
    vehicleId,
    user,
    userKey
  ]);

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: '1rem',
          color: '#515e71',
          fontSize: '1.3rem',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 0 0'
        }}
      >
        <div style={{ padding: '2rem 1rem' }}>
          <label>{t('GPIO.Tables.Inputs')}</label>
          <GPInputsTable
            style={{ overflow: 'scroll' }}
            data={gpio.data?.inputs}
            isLoading={gpio.isLoading}
          />
        </div>

        <div style={{ padding: '2rem 1rem' }}>
          <label>{t('GPIO.Tables.Outputs')}</label>
          <GPOutputsTable
            style={{ overflow: 'scroll' }}
            data={gpio.data?.outputs}
            isLoading={gpio.isLoading}
            deviceIMEI={device?.imei}
            disableImmoblise={disableImmobilise === null ? true : disableImmobilise}
          />
        </div>
      </div>
    </>
  );
};
