import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { NotificationsApi } from '../../nextgen_api';
import { API_PATH } from '../../config';
import { useSelector } from 'react-redux';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';
import i18n from 'i18next';
import { fetchFleets } from 'features/fleets/fleetsSlice';
import { fetchGeofences } from 'features/geofences/geofencesSlice';
import { fetchLocations } from 'features/locations/locationsSlice';
import { fetchUsers } from 'features/users/usersSlice';
import { TEMPLATE_TYPES } from 'containers/Administration/BulkManagement/utils/constants';
import { useMemo } from 'react';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';

const actionTypes = {
  init: 0,
  processing: 1,
  error: 2,
  done: 3
};

const RESET_TIME = 15000; //15 sec
const initialState = {
  userId: null,
  notifications: [],
  richNotifications: [],
  status: {
    get: {
      fetching: actionTypes.init,
      fetchingTime: null,
      error: null
    },
    dismiss: {
      /* data structure
                  id: {
                      dismissing: actionTypes.init,
                      error: null
                  }*/
    }
  }
};

const notificationsSlice = createSlice({
  initialState: initialState,
  name: 'notifications',
  reducers: {
    fetchNotificationsStart: (state, action) => {
      state.userId = action.payload;
      state.status.get.fetching = actionTypes.processing;
      state.status.get.fetchingTime = moment().valueOf();
    },
    fetchNotificationsSucceeded: (state, action) => {
      if (action.payload != null && Array.isArray(action.payload)) {
        state.notifications = state.notifications
          .concat(action.payload)
          .filter(n => !n.dismissedAt && n.eventType !== 'Ng::Event::SystemNotification')
          .sort((a, b) => {
            if (a.eventAt < b.eventAt) {
              return 1;
            } else if (a.eventAt === b.eventAt) {
              if (a.id < b.id) {
                return 1;
              } else if (a.id === b.id) {
                return 0;
              } else {
                return -1;
              }
            } else {
              return -1;
            }
          });
        // Rich Notifications - introduced in Bulk Import, different to the alerts notifications
        // Type: Ng::Event::SystemNotification
        const systemNotification = action?.payload?.filter(
          n => n.eventType === 'Ng::Event::SystemNotification'
        );

        //Grouped by 'groupId', use the latest groupdId found
        const groupedRichNotifications = [
          ...new Map(systemNotification.map(item => [item['groupId'], item])).values()
        ]?.filter(n => !n.dismissedAt);

        state.richNotifications = [...state.richNotifications, ...groupedRichNotifications];
      } else {
        state.notifications = action.payload;
      }
      state.status.get.fetching = actionTypes.done;
    },
    fetchNotificationsFailed: (state, action) => {
      state.status.get.fetching = actionTypes.error;
      state.status.get.error = action.payload;
    },
    dismissStart: (state, action) => {
      state.status.dismiss[action.payload] = {
        dismissing: actionTypes.init,
        error: null
      };
    },
    dismissSucceeded: (state, action) => {
      const id = action.payload;
      const idx = state.notifications.findIndex(n => n.id === id);
      if (idx >= 0) {
        state.notifications.splice(idx, 1);
      }

      if (state.status.dismiss[id] != null) {
        delete state.status.dismiss[id];
      }
    },
    dismissFailed: (state, action) => {
      const { id, error } = { ...action.payload };
      state.status.dismiss[id].dismissing = actionTypes.error;
      state.status.dismiss[id].error = error;
    },
    cleanNotificationsState: (state, action) => {
      return initialState;
    },
    clearAllNotifications: (state, action) => {
      state.notifications = [];
    },
    dismissRichNotiricationSucceeded: (state, action) => {
      const id = action.payload;
      const idx = state.richNotifications.findIndex(n => n.id === id);
      if (idx >= 0) {
        state.richNotifications.splice(idx, 1);
      }
    }
  }
});

export const {
  fetchNotificationsStart,
  fetchNotificationsSucceeded,
  fetchNotificationsFailed,
  dismissStart,
  dismissSucceeded,
  dismissFailed,
  cleanNotificationsState,
  clearAllNotifications,
  dismissRichNotiricationSucceeded
} = notificationsSlice.actions;

export const fetchNotifications = userId => async (dispatch, getState) => {
  if (userId == null || userId === '') {
    return;
  }

  const fetchingStatus = getState().notifications.status.get;
  if (fetchingStatus.fetching === actionTypes.processing) {
    return;
  }

  if (
    fetchingStatus.fetchingTime != null &&
    moment().valueOf() - fetchingStatus.fetchingTime < RESET_TIME
  ) {
    return;
  }

  dispatch(fetchNotificationsStart(userId));
  //604800000 = 7 * 24 * 60 * 60 * 1000
  let fetchStartDate = new Date(moment().valueOf() - 604800000);
  fetchStartDate.setHours(0, 0, 0, 0);
  if (fetchingStatus.fetchingTime != null) {
    fetchStartDate = new Date(fetchingStatus.fetchingTime);
  }
  const userKey = getState().user.current.auth.key;

  let dateFrom = fetchStartDate.toISOString();

  const promise = new Promise((resolve, reject) => {
    const api = new NotificationsApi();
    api.apiClient.basePath = API_PATH;
    api.apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    api.getNotifications(
      { from: dateFrom, pruning: 'APP', embed: 'events' },
      (error, data, resp) => {
        if (error) {
          console.error(error);
          reject(error);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const notifications = await promise;
    dispatch(fetchNotificationsSucceeded(notifications));
    if (fetchingStatus?.fetchingTime) {
      // only for new notifications - fetched after the initial fetchNotifications
      dispatch(refreshBulkImportedData(notifications));
    }
  } catch (err) {
    dispatch(fetchNotificationsFailed(err.toString()));
  }
};

const refreshBulkImportedData = notifications => dispatch => {
  const newRichNotifications = [
    ...new Map(notifications.map(item => [item['groupId'], item])).values()
  ];
  if (newRichNotifications?.length) {
    newRichNotifications.forEach(notification => {
      const isCompleted =
        notification.progressCurrent + notification.progressError === notification.progressTotal;
      if (isCompleted && notification.extras?.Type && notification.progressCurrent > 0) {
        switch (notification.extras.Type) {
          case TEMPLATE_TYPES.geofences:
            dispatch(fetchGeofences());
            break;
          case TEMPLATE_TYPES.locations:
            dispatch(fetchLocations());
            break;
          case TEMPLATE_TYPES.users:
            dispatch(fetchUsers());
            break;
          case TEMPLATE_TYPES.vehicles:
            dispatch(fetchFleets());
            break;
          default:
            break;
        }
      }
    });
  }
};

export const dismissNotification = id => async (dispatch, getState) => {
  if (id == null || id === '') {
    return;
  }

  const status = getState().notifications.status[id];
  if (
    status != null &&
    (status.dismissing === actionTypes.processing || status.dismissing === actionTypes.done)
  ) {
    return;
  }
  dispatch(dismissStart(id));
  const promise = new Promise((resolve, reject) => {
    const api = new NotificationsApi();
    const userKey = getState().user.current.auth.key;
    api.apiClient.basePath = API_PATH;
    api.apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    api.dismiss(id, { pruning: 'APP' }, (error, data, resp) => {
      if (error) {
        console.log(error);
        reject(error);
      } else {
        resolve(data);
      }
    });
  });

  try {
    await promise;
    dispatch(dismissSucceeded(id));
  } catch (error) {
    dispatch(dismissFailed({ id, error }));
  }
};

export const dismissNotifications = (from, to) => async (dispatch, getState) => {
  const promise = new Promise((resolve, reject) => {
    const api = new NotificationsApi();
    const userKey = getState().user.current.auth.key;
    api.apiClient.basePath = API_PATH;
    api.apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    api.dismissAll({ from, to }, (error, data, resp) => {
      if (error) {
        console.log(error);
        reject(resp?.body);
      } else {
        resolve();
      }
    });
  });

  try {
    await promise;
    dispatch(clearAllNotifications());
    return Promise.resolve();
  } catch (error) {
    Promise.reject(error);
  }
};

export const dismissRichNotification = id => async (dispatch, getState) => {
  if (!id) {
    return;
  }
  const userKey = getState().user.current.auth.key;
  try {
    const response = await api.post(`/notifications/${id}/dismiss`, { authKey: userKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('Notifications.RichNotifications.NotificationDismissed')
        })
      );
      dispatch(dismissRichNotiricationSucceeded(id));
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18n.t(
          'Notifications.RichNotifications.NotificationDismissed'
        )} ${parseErrorMessage(err)}`
      })
    );
  }
};

export const acknowledgeDuressAlert = (id, payload) => async (dispatch, getState) => {
  if (!id || !payload) {
    return;
  }
  const userKey = getState().user.current.auth.key;
  try {
    const response = await api.post(`/notifications/${id}/comment`, { authKey: userKey }, payload);
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('Alerts.DuressModal.AcknowledgeSuccess')
        })
      );
      dispatch(dismissSucceeded(id));
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18n.t('Alerts.DuressModal.AcknowledgeError', {
          error: parseErrorMessage(err)
        })}`
      })
    );
  }
};

export const dismissDuressAlert = id => async (dispatch, getState) => {
  if (!id) {
    return;
  }
  const userKey = getState().user.current.auth.key;
  try {
    const response = await api.post(`/notifications/${id}/dismiss`, { authKey: userKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('Alerts.DuressModal.DismissSuccess')
        })
      );
      dispatch(dismissSucceeded(id));
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18n.t('Alerts.DuressModal.DismissError', { error: parseErrorMessage(err) })}`
      })
    );
  }
};

export const getDuressAlertStatus = id => async (dispatch, getState) => {
  if (!id) {
    return;
  }
  const userKey = getState().user.current.auth.key;
  try {
    const response = await api.get(`/notifications/${id}`, { authKey: userKey });
    if (response?.body) {
      return response.body;
    }
  } catch (err) {
    console.error(err.response?.body?.error || err.message);
  }
};

export const useNotificationsData = () => useSelector(state => state.notifications.notifications);
export const useRichNotifications = () =>
  useSelector(state => state.notifications.richNotifications);

export const useLastNotificationFetchDate = () =>
  useSelector(state => state.notifications.status.get.fetchingTime);

export default notificationsSlice.reducer;
