import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits } from 'features/audits';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';
import { useVehicles } from 'features/fleets/fleetsSlice';

import { getIDFromPathname } from 'utils/methods';

import { AuditsTable } from 'components/auditsTable/AuditsTable';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { PATHS } from './constants';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useHistory } from 'react-router';

export const VehicleAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const history = useHistory();
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.VEHICLE_DEFAULT);
  }, [history]);
  const audits = useAudits(AUDIT_ENTITY.VEHICLE, id, handleFetchError);
  const vehicles = useVehicles();
  const vehicle = vehicles?.find(vehicle => vehicle.id === Number(id));
  const pageTitle = !vehicle
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: vehicle?.name });

  useRedirectToMainFeaturePageOnCompanyChange('/settings/vehicles');

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    dispatch(setBackButton(true));
  }, [pageTitle, dispatch]);

  useEffect(() => {
    if (id <= 0 || isNaN(id) || (vehicles?.length > 0 && !vehicle && !audits)) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [dispatch, id, vehicles, handleFetchError, vehicle, audits]);

  return <AuditsTable audits={audits} entity={AUDIT_ENTITY.VEHICLE} />;
};
