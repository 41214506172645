import React, { useState, useMemo } from 'react';
import P from 'prop-types';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useLocations } from 'features/locations/locationsSlice';

import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { Table } from 'components/ant';
import { Table as AntTable } from 'antd';

import { Row } from 'antd';

import { prepareJobsForTable, prepareItemsForTable } from '../../utils/helpers';
import { LocationType } from '../../utils/constants';
import { getJobsTableColumns, getItemsTableColumns } from '../../utils/translation';

import { useLocalization } from 'features/localization/localizationSlice';
import styles from './JobsTable.module.scss';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useItemTypes } from 'features/smartJobs';

import { deleteRemoteJob } from '../../RunsheetForm';
import { EditJobModal } from '../../Jobs/EditJob';
import { ShareJobModal } from '../../Jobs/ShareJob';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';

export const JobsTable = ({ runsheet, setShowAllocateModal }) => {
  const localization = useLocalization();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState(null);
  const [editJob, setEditJob] = useState(false);
  const [shareJob, setShareJob] = useState(false);
  const locations = useLocations([LocationType.CUSTOMER]);
  const itemTypes = useItemTypes();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSearch = value => {
    setSearchValue(value);
  };

  const renderItemsTable = record => {
    const data = runsheet.jobs.find(job => job.id === record.key);
    const TABLE_RV_JOBS_ITEMS_COLUMNS = getItemsTableColumns(t);
    const jobItems = data.jobItems.map(item => ({ ...item, type: data.type }));

    return (
      <Table
        columns={TABLE_RV_JOBS_ITEMS_COLUMNS}
        dataSource={prepareItemsForTable(jobItems, data, t, localization, {}, itemTypes)}
        pagination={false}
        rowKey="key"
      />
    );
  };

  const TABLE_RV_JOBS_COLUMNS = useMemo(() => {
    const jobColumns = getJobsTableColumns(t);
    return jobColumns;
  }, [t]);

  // Edit a job outside of Runsheet Form scope
  const handleEditJob = job => () => {
    setEditJob(job);
  };

  const handleShareJob = (runsheet, job) => () => {
    setShareJob({
      runsheet,
      job
    });
  };

  return (
    <>
      <Row className={styles.searchWrapper}>
        <FilterWrapper>
          <AntSearchbar onFilter={handleSearch} />
        </FilterWrapper>
      </Row>
      {!!editJob && (
        <EditJobModal
          isOpen={!!editJob}
          locations={locations}
          onCancel={() => setEditJob(false)}
          job={editJob}
          runsheet={runsheet}
        />
      )}
      <ShareJobModal
        isOpen={shareJob}
        job={shareJob?.job}
        jobOrVehicle="job"
        onCancel={() => setShareJob(false)}
      />
      <div className={styles.tableJobsWrapper}>
        <Table
          dataSource={prepareJobsForTable(
            runsheet?.jobs,
            { runsheet, searchValue, localization, path: location.pathname },
            {
              onEditJob: handleEditJob,
              onShareJob: handleShareJob,
              onDeleteJob: deleteRemoteJob,
              dispatch,
              setShowAllocateModal
            },
            t
          )}
          columns={[...TABLE_RV_JOBS_COLUMNS, AntTable.EXPAND_COLUMN]}
          pagination={false}
          rowKey="key"
          expandable={{
            expandedRowRender: renderItemsTable,
            rowExpandable: record => record.name !== 'Not Expandable',
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpOutlined onClick={e => onExpand(record, e)} />
              ) : (
                <DownOutlined onClick={e => onExpand(record, e)} />
              )
          }}
        />
      </div>
    </>
  );
};

JobsTable.propTypes = {
  runsheetData: P.shape({
    jobs: P.array
  })
};
