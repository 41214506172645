import { Document, Font, Image, Page, StyleSheet, Text, View, usePDF } from '@react-pdf/renderer';
import { Button } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useUserKey } from 'features/user/userSlice';

import logo from './assets/COE Blue Header.jpg';
import CalibriBold from './assets/calibri-bold.ttf';
import Calibri from './assets/calibri-regular.ttf';

import { API_PATH } from 'config';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';

import styles from './GeneratePdf.module.scss';

Font.register({
  family: 'Calibri',
  fonts: [
    {
      src: Calibri
    },
    {
      src: CalibriBold,
      fontWeight: 600
    }
  ]
});

const PdfBody = ({ companyDetails, vehicleDetails, schemes, applications, certificateNumber }) => {
  const { t } = useTranslation();

  const pdfStyles = StyleSheet.create({
    document: {
      padding: '20px 0px'
    },
    logo: {
      padding: '0 20px'
    },
    body: {
      padding: '0px 60px',
      fontSize: 14,
      color: '#012f40'
    },
    lineView: {
      display: 'flex',
      flexDirection: 'row',
      borderBottom: '1px solid #0c4c60',
      padding: '4px',
      marginTop: 4,
      fontFamily: 'Calibri'
    },
    pbsLineView: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: '1px solid #0c4c60',
      padding: '4px',
      marginTop: 4,
      fontFamily: 'Calibri'
    },
    lineTitle: {
      fontFamily: 'Calibri',
      fontWeight: 600
    },
    ifApplicablePBS: {
      marginLeft: 55,
      fontSize: 9,
      alignSelf: 'center'
    },
    ifApplicableNoPBS: {
      fontSize: 9,
      alignSelf: 'center'
    },
    pbsList: {
      backgroundColor: '#e2f4fd',
      padding: 4,
      marginLeft: 20,
      width: '100%',
      fontSize: 8
    },
    schemesListSmall: {
      backgroundColor: '#e2f4fd',
      padding: 8,
      marginLeft: 12,
      width: '100%',
      fontSize: 8
    },
    schemesListBig: {
      backgroundColor: '#e2f4fd',
      padding: 8,
      marginLeft: 12,
      width: '100%',
      fontSize: 12
    },
    noteTitle: {
      borderBottom: '2px solid green',
      marginTop: 16,
      padding: '2px 4px',
      width: 40,
      fontFamily: 'Calibri',
      fontWeight: 600
    },
    noteView: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 12,
      alignItems: 'flex-start',
      fontFamily: 'Calibri'
    },
    noteNumbers: {
      fontWeight: '600',
      color: 'green',
      fontSize: 10,
      fontFamily: 'Calibri'
    },
    noteText: {
      fontSize: 10,
      fontFamily: 'Calibri'
    },
    footNote: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 12,
      fontSize: 7
    },
    footTextBold: {
      fontFamily: 'Calibri',
      fontWeight: 600
    }
  });

  return (
    <Document
      title={`${companyDetails?.identity?.operatorName ||
        'invalidOperatorName'} - ${certificateNumber ||
        'invalidCertificateNumber'}  - ${vehicleDetails?.registration || 'invalidRegistration'}`}
    >
      <Page style={pdfStyles.document}>
        <View style={pdfStyles.logo}>
          <Image src={logo} />
        </View>
        <View style={pdfStyles.body}>
          <View style={pdfStyles.lineView}>
            <Text style={pdfStyles.lineTitle}>{t('Vehicles.EnrolmentsPDF.CertificateNumber')}</Text>
            <Text>{certificateNumber}</Text>
          </View>
          <View style={pdfStyles.lineView}>
            <Text style={pdfStyles.lineTitle}>{t('Vehicles.EnrolmentsPDF.DateOfIssue')}</Text>
            <Text>{moment(new Date()).format('DD/MM/YYYY')}</Text>
          </View>
          <View style={pdfStyles.lineView}>
            <Text style={pdfStyles.lineTitle}>
              {t('Vehicles.EnrolmentsPDF.TransportOperatorName')}
            </Text>
            <Text>{companyDetails ? companyDetails.identity.operatorName : ''}</Text>
          </View>
          <View style={pdfStyles.lineView}>
            <Text style={pdfStyles.lineTitle}>{t('Vehicles.EnrolmentsPDF.Application(s)')}</Text>
            <Text>{applications.join(' | ')}</Text>
          </View>
          <View style={pdfStyles.lineView}>
            <Text style={pdfStyles.lineTitle}>{t('Vehicles.EnrolmentsPDF.Scheme(s)')}</Text>
            <View
              style={schemes.length < 7 ? pdfStyles.schemesListBig : pdfStyles.schemesListSmall}
            >
              {schemes.map(s => (
                <Text> - {s}</Text>
              ))}
            </View>
          </View>
          <View style={pdfStyles.lineView}>
            <Text style={pdfStyles.lineTitle}>
              {t('Vehicles.EnrolmentsPDF.VehicleRegistrationNumber')}
            </Text>
            <Text>{vehicleDetails ? vehicleDetails.registration : ''}</Text>
          </View>
          <View style={pdfStyles.pbsLineView}>
            <Text style={pdfStyles.lineTitle}>{t('Vehicles.EnrolmentsPDF.PBSApprovalNumber')}</Text>
            <Text
              style={
                vehicleDetails?.pbsApprovalNumbers
                  ? pdfStyles.ifApplicablePBS
                  : pdfStyles.ifApplicableNoPBS
              }
            >
              {t('Vehicles.EnrolmentsPDF.IfApplicable')}
            </Text>
            {vehicleDetails?.pbsApprovalNumbers && (
              <Text style={pdfStyles.pbsList}>{vehicleDetails.pbsApprovalNumbers.join()}</Text>
            )}
          </View>
          <View style={pdfStyles.lineView}>
            <Text style={pdfStyles.lineTitle}>
              {t('Vehicles.EnrolmentsPDF.ApplicationServiceProvider')}
            </Text>
            <Text>Navman Wireless Australia Pty Ltd</Text>
          </View>
          <Text style={pdfStyles.noteTitle}>{t('Vehicles.EnrolmentsPDF.NoteTitle')}</Text>
          <View style={pdfStyles.noteView}>
            <Text style={pdfStyles.noteNumbers}>1. </Text>
            <Text style={pdfStyles.noteText}>{t('Vehicles.EnrolmentsPDF.FirstNote')}</Text>
          </View>
          <View style={pdfStyles.noteView}>
            <Text style={pdfStyles.noteNumbers}>2. </Text>
            <Text style={pdfStyles.noteText}>{t('Vehicles.EnrolmentsPDF.SecondNote')}</Text>
          </View>
          <View style={pdfStyles.noteView}>
            <Text style={pdfStyles.noteNumbers}>3. </Text>
            <Text style={pdfStyles.noteText}>{t('Vehicles.EnrolmentsPDF.ThirdNote')}</Text>
          </View>
          <View style={pdfStyles.noteView}>
            <Text style={pdfStyles.noteNumbers}>4. </Text>
            <Text style={pdfStyles.noteText}>{t('Vehicles.EnrolmentsPDF.FourthNote')}</Text>
          </View>
          <View style={pdfStyles.footNote}>
            <Text>
              <Trans
                i18nKey={'Vehicles.EnrolmentsPDF.FootNote'}
                components={{
                  1: <Text style={pdfStyles.footTextBold} />
                }}
              />
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export const GeneratePdf = ({ enrolmentId, enrolments, schemes, listItem = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userKey = useUserKey();

  const enrolment = enrolments?.find(enrolment => enrolment.id === +enrolmentId);
  const vehicleDetails = enrolment && JSON.parse(enrolment.vehicleDetails);
  const companyDetails = enrolment && JSON.parse(enrolment.companyDetails);
  const vehicle = enrolment?.vehicleId;

  const [certificateNumber, setCertificateNumber] = useState('');
  const [updatedPdf, setUpdatedPdf] = useState(false);

  const filteredEnrolments = (enrolments || []).filter(
    e => e.status === 'APPROVED' && e.vehicleId === vehicle
  );
  const filteredSchemes = (schemes || []).filter(s =>
    filteredEnrolments.some(fe => fe.scheme === s.schemeCode || fe.scheme?.id === s.schemeCode)
  );

  const schemeNames = filteredSchemes.map(s => s.schemeName);
  const applications = [...new Set(filteredSchemes.map(s => s.application) || [])].reverse();

  const isGenerateAvailable =
    enrolments?.length > 0 && schemes?.length > 0 && enrolment?.status === 'APPROVED' && vehicle;

  const [instance, update] = usePDF({
    document: (
      <PdfBody
        applications={applications}
        certificateNumber={certificateNumber}
        companyDetails={companyDetails}
        schemes={schemeNames}
        vehicleDetails={vehicleDetails}
      />
    )
  });

  const handleCertificateNumber = () => {
    fetch(`${API_PATH}/enrolments/generate/certificateId`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token token="${userKey}"`
      }
    })
      .then(resp => resp.text())
      .then(resp => {
        setCertificateNumber(resp);
      })
      .catch(err =>
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(err?.message)
          })
        )
      );
  };

  useEffect(() => {
    if (certificateNumber) {
      update(
        <PdfBody
          applications={applications}
          certificateNumber={certificateNumber}
          companyDetails={companyDetails}
          schemes={schemeNames}
          vehicleDetails={vehicleDetails}
        />
      );

      setUpdatedPdf(true);
    }

    return () => {
      setUpdatedPdf(false);
    };
  }, [enrolments, schemes, certificateNumber]);

  useEffect(() => {
    if (updatedPdf && instance.blob && instance.loading !== true) {
      const url = window.URL.createObjectURL(instance.blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${companyDetails?.identity?.operatorName || 'invalidOperatorName'} - ${certificateNumber ||
          'invalidCertificateNumber'}  - ${vehicleDetails?.registration || 'invalidRegistration'}`
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setCertificateNumber(null);
      setUpdatedPdf(false);
    }
  }, [instance, updatedPdf]);

  if (listItem && isGenerateAvailable) {
    return (
      <li key="moreMenu-GeneratePdf" className={styles.listItem} onClick={handleCertificateNumber}>
        {t('Vehicles.EnrolmentsPDF.GeneratePdf')}
      </li>
    );
  } else if (listItem) {
    return null;
  }

  return (
    <Button disabled={!isGenerateAvailable} onClick={handleCertificateNumber}>
      {t('Vehicles.EnrolmentsPDF.GeneratePdf')}
    </Button>
  );
};
