export const massSchemes = [
  {
    id: 1,
    jurisdiction: 'MRWA',
    selected: false,
    name: 'AMMS1',
    grossMass: 0,
    steer: 0,
    drive: 0,
    group3: 0,
    group4: 0,
    group5: 0,
    group6: 0,
    group7: 0,
    group8: 0
  },
  {
    id: 2,
    jurisdiction: 'MRWA',
    selected: false,
    name: 'AMMS2',
    grossMass: 0,
    steer: 0,
    drive: 0,
    group3: 0,
    group4: 0,
    group5: 0,
    group6: 0,
    group7: 0,
    group8: 0
  },
  {
    id: 3,
    jurisdiction: 'MRWA',
    selected: false,
    name: 'AMMS3',
    grossMass: 0,
    steer: 0,
    drive: 0,
    group3: 0,
    group4: 0,
    group5: 0,
    group6: 0,
    group7: 0,
    group8: 0
  },
  {
    id: 4,
    jurisdiction: 'MRWA',
    selected: false,
    name: 'GML',
    grossMass: 0,
    steer: 0,
    drive: 0,
    group3: 0,
    group4: 0,
    group5: 0,
    group6: 0,
    group7: 0,
    group8: 0
  },
  {
    id: 5,
    jurisdiction: 'MRWA',
    selected: false,
    name: 'Custom',
    grossMass: 0,
    steer: 0,
    drive: 0,
    group3: 0,
    group4: 0,
    group5: 0,
    group6: 0,
    group7: 0,
    group8: 0
  },
  {
    id: 6,
    jurisdiction: 'NHVR',
    selected: false,
    name: 'GML',
    grossMass: 0,
    steer: 0,
    drive: 0,
    group3: 0,
    group4: 0,
    group5: 0,
    group6: 0,
    group7: 0,
    group8: 0
  },
  {
    id: 7,
    jurisdiction: 'NHVR',
    selected: false,
    name: 'CML',
    grossMass: 0,
    steer: 0,
    drive: 0,
    group3: 0,
    group4: 0,
    group5: 0,
    group6: 0,
    group7: 0,
    group8: 0
  },
  {
    id: 8,
    jurisdiction: 'NHVR',
    selected: false,
    name: 'HML',
    grossMass: 0,
    steer: 0,
    drive: 0,
    group3: 0,
    group4: 0,
    group5: 0,
    group6: 0,
    group7: 0,
    group8: 0
  },
  {
    id: 9,
    jurisdiction: 'NHVR',
    selected: false,
    name: 'Custom',
    grossMass: 0,
    steer: 0,
    drive: 0,
    group3: 0,
    group4: 0,
    group5: 0,
    group6: 0,
    group7: 0,
    group8: 0
  }
];
