import React, { useState, useEffect, Fragment, useMemo } from 'react';
import P from 'prop-types';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { Col, Row, Button } from 'antd';
import InfoRow from 'components/form/info-row/InfoRow';
import { Loading } from 'components/loading/Loading';

import { useLocalization } from 'features/localization/localizationSlice';
import { fetchJobPdf, deleteItem, useItemTypes } from 'features/smartJobs';

import styles from './JobView.module.scss';
import { Table } from 'components/ant';
import { isRunsheetFrozen, prepareItemsForTable } from '../utils/helpers';
import { JobStatus, ColumnKeys } from '../utils/constants';
import { getInfoRowValue, getJobAttachments } from './utils/helpers';

import {
  TABLE_JV_JOBS_ITEMS_COLUMNS,
  ViewJobLabelsLeft,
  ViewJobLabelsRight,
  JobDetailsLabels
} from './utils/constans';
import { startCase, upperCase } from 'lodash';
import { useCanNPI } from 'features/permissions/canHooks';
import { BUTTON_IDS } from 'utils/globalConstants';

function AdHocList({ adHocList, ...props }) {
  const { t } = useTranslation();
  function convertValue(v) {
    if (v && typeof v === 'boolean') {
      return v ? t('Common.Yes') : t('Common.No');
    }
    return v;
  }

  if (adHocList?.length > 0) {
    return (
      <div className={styles.adHocContainer}>
        {adHocList.map((a, idx) => (
          <Row className={styles.adHocList} key={idx}>
            <Col className={styles.adHocHead} span={24}>
              {a.type}
            </Col>
            <Row className={styles.adHocBody}>
              {Object.entries(a.value).map((e, e_idx) => (
                <Fragment key={e_idx}>
                  <Col span={12}>{startCase(e[0])}</Col>
                  <Col span={12}>{convertValue(e[1])}</Col>
                </Fragment>
              ))}
            </Row>
          </Row>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
}

export const JobDetails = ({ job, jobAttach, isLoadingAttachments, isLoadingJob }) => {
  const [jobItems, setItems] = useState(job?.jobItems);
  const [adHocList, setAdHocList] = useState(null);

  const localization = useLocalization();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const itemTypes = useItemTypes();
  const hasNPI = useCanNPI();

  useEffect(() => {
    if (!jobItems && job?.jobItems) {
      setItems(job?.jobItems);
    }

    if (job?.attributes) {
      const adHocItems = [];
      job.attributes.forEach(a => {
        if ('adhoc' === a.key?.toLowerCase() && a.value) {
          try {
            let adHocJson = JSON.parse(a.value);
            const adHocType = adHocJson.type;
            delete adHocJson.type;
            adHocItems.push({
              type: adHocType,
              value: adHocJson
            });
          } catch (e) {
            console.debug(e.toString());
          }
        } else if (['adhocreasoncomment', 'adhocreasoncode'].includes(a.key?.toLowerCase())) {
          const reason = adHocItems.find(a => a.type === t('SmartJobs.AdHoc Reason'));
          let newProp = null;
          switch (a.key.toLowerCase()) {
            case 'adhocreasoncomment':
              newProp = { [t('ELD.Comments')]: a.value };
              break;
            case 'adhocreasoncode':
              newProp = JSON.parse(a.value);
              break;
            default:
              break;
          }

          if (newProp) {
            if (!reason) {
              adHocItems.push({
                type: t('SmartJobs.AdHoc Reason'),
                value: newProp
              });
            } else {
              Object.assign(reason.value, newProp);
            }
          }
        }
      });
      if (adHocItems.length > 0) {
        setAdHocList(adHocItems);
      }
    }
  }, [job]);

  // If job is completed - the items table should not have any actions
  const ItemsTableColumns = useMemo(() => {
    const isJobComplete = [JobStatus.COMPLETED, JobStatus.CANCELLED].includes(
      upperCase(job?.status)
    );
    const isFrozen = isRunsheetFrozen(job?.runsheet);
    return (isJobComplete || isFrozen
      ? TABLE_JV_JOBS_ITEMS_COLUMNS.filter(column => column.key !== ColumnKeys.ACTIONS)
      : TABLE_JV_JOBS_ITEMS_COLUMNS
    ).map(column => ({
      ...column,
      title: t(`SmartJobs.ColumnList.Columns.${column.key}`)
    }));
  }, [t, job]);

  if (isLoadingJob) {
    return <Loading />;
  }

  const handleItemDelete = itemId => async () => {
    const {
      runsheet: { id: runsheetId },
      id: jobId
    } = job;

    try {
      await dispatch(deleteItem({ runsheetId, jobId, itemId }));
      setItems(jobItems.filter(item => item?.item?.id !== itemId));
    } catch (err) {
      console.error(err);
    }
  };
  const generatePDF = async () => {
    const result = await dispatch(fetchJobPdf(job, localization));

    const file = new Blob([result], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);

    const link = document.createElement('a');
    link.style = 'display: none';
    link.href = fileURL;

    link.setAttribute('download', `${job?.runsheet?.name || t('SmartJobs.Runsheet')}.pdf`);
    link.click();
  };

  return (
    <>
      <Button
        style={{ float: 'right', marginRight: 50 }}
        disabled={isLoadingJob || isLoadingAttachments}
        onClick={generatePDF}
        id={BUTTON_IDS.jobDetailsDownloadPdf}
      >
        {t('SmartJobs.DownloadPdf')}
      </Button>

      <Row>
        <Col span={12}>
          <div className={styles.infoRow}>
            {ViewJobLabelsLeft.map(label => {
              let value = getInfoRowValue(label, job, localization);
              if (label === JobDetailsLabels.TYPE) {
                value = t(`SmartJobs.Input.JobType.${value}`, '');
              }
              if (label === JobDetailsLabels.STATUS) {
                value = t(`SmartJobs.Status.${(value || '').toLowerCase()}`, '');
              }

              return <InfoRow key={label} label={t(`SmartJobs.PDF.${label}`)} value={value} />;
            })}
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.infoRow}>
            {ViewJobLabelsRight.map(label => (
              <InfoRow
                key={label}
                sxValue={{ width: '100%' }}
                label={t(`SmartJobs.PDF.${label}`)}
                value={getInfoRowValue(label, job, localization)}
              />
            ))}
          </div>
        </Col>
      </Row>

      {hasNPI && adHocList && (
        <Row className={styles.section}>
          <h5 className={styles.sectionTitle}>{t('SmartJobs.AdHoc Information')}</h5>
          <AdHocList adHocList={adHocList} />
        </Row>
      )}

      <Row className={styles.section}>
        <h5 className={styles.sectionTitle}>{t('SmartJobs.Items')}</h5>
        {job && (
          <Table
            columns={ItemsTableColumns}
            dataSource={prepareItemsForTable(
              jobItems,
              job,
              t,
              localization,
              {
                onDelete: handleItemDelete
              },
              itemTypes
            )}
            pagination={false}
          />
        )}
      </Row>
      <Row className={styles.section}>
        {isLoadingAttachments ? (
          <Loading />
        ) : (
          <>
            <h5 className={styles.sectionTitle}>{t('SmartJobs.Attachments')}</h5>
            <div className={styles.imagesWrapper}>
              {getJobAttachments({
                jobAttach: jobAttach,
                styles: styles,
                isSignatureFiltered: false
              })}
            </div>
          </>
        )}
      </Row>
    </>
  );
};
export default JobDetails;
JobDetails.propTypes = {
  job: P.object,
  jobAttach: P.object
};
