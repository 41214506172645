import { eventTypes, eventScheduleTypes } from '../../components/notifications/alerts/constants';
import { Paths } from 'containers/VehicleMaintenance/utils/constants';
import i18n from 'i18next';

export const getEventUrl = alert => {
  switch (true) {
    case alert.eventType === eventTypes.TachoEvent:
      return 'fatigue/tacho';
    case alert.eventType === eventTypes.ScheduleEvent &&
      alert.event?.schedule?.type === eventScheduleTypes.VehicleMaintenanceSchedule:
      return `${Paths.VEHICLEMAINTENANCE_VIEW}/${alert.event?.schedule?.id}`;
    case alert.event?.schedule?.type === eventScheduleTypes.DriverManagementSchedule:
      return '/driverManagement/id/' + alert.event?.schedule?.id;
    case !!alert.detailsUrl:
      return alert.detailsUrl;
    case alert.eventType === eventTypes.DocumentExpire:
      return '/easydocs/documents/id/' + alert.eventId;
    default:
      return '/events/' + alert.id;
  }
};

export const getBreachName = ({ type, name }) => {
  switch (type) {
    case 2:
      return i18n.t('Alerts.TachoAlert.ContinuousDrivingTime');
    case 4:
      return i18n.t('Alerts.TachoAlert.DailyDriving');
    default:
      return name || 'Unknown';
  }
};
