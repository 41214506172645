/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Position from "./Position";

/**
 * The Shape model module.
 * @module model/Shape
 * @version 1.0
 */
class Shape {
  /**
   * Constructs a new <code>Shape</code>.
   * @alias module:model/Shape
   */
  constructor() {
    Shape.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Shape</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Shape} obj Optional instance to populate.
   * @return {module:model/Shape} The populated <code>Shape</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Shape();

      if (data.hasOwnProperty("type")) {
        obj["type"] = ApiClient.convertToType(data["type"], "String");
      }
      if (data.hasOwnProperty("points")) {
        obj["points"] = ApiClient.convertToType(data["points"], [Position]);
      }
    }
    return obj;
  }
}

/**
 * @member {String} type
 */
Shape.prototype["type"] = undefined;

/**
 * @member {Array.<module:model/Position>} points
 */
Shape.prototype["points"] = undefined;

export default Shape;
