import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { Button, Space, Table, Col, Row, Tooltip } from 'antd';
import SubTopNav from 'components/nav/SubTopNav';
import { useUserKey } from 'features/user/userSlice';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import {
  deleteMessageTemplate,
  fetchMessageTemplates,
  useIsMessageTemplatesFetching,
  useMessageTemplates,
  useIsDeletingMessageTemplate,
  useIsDeleteMessageTemplateSuccess,
  useIsDeleteMessageTemplateFailed,
  useDeleteMessageTemplateFailedError,
  setInitailDeleteMessageTemplateStatus,
  useUserMessagingPermissions
} from 'features/messaging/messagingSlice';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import styles from './Messaging.module.scss';
import { MessageTemplateModal } from './MessageTemplateModal';
import { EllipsisMenu } from 'components/tables/EllipsisMenu';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import styled from '@emotion/styled';
import { MESSAGE_PERMISSION_ENTITY_TYPE } from './constants';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { BUTTON_IDS } from 'utils/globalConstants';

const TemplateTableWrapper = styled.div`
  .ant-table-container table > thead > tr:first-of-type th:first-of-type {
    padding-left: 30px !important;
  }
  .ant-table-tbody > tr > td {
    font-size: 14px;
    line-height: 21px;
  }
  .ant-table-tbody > tr > td:first-of-type {
    padding-left: 36px !important;
  }
`;

export const MessageTemplates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const localization = useLocalization();
  const userKey = useUserKey();
  const companyKey = useCurrentCompanyKey();

  const userMsgPermission = useUserMessagingPermissions();

  const templates = useMessageTemplates();
  const isFetchingTemplates = useIsMessageTemplatesFetching();
  const isDeleting = useIsDeletingMessageTemplate();
  const isDeleteSuccess = useIsDeleteMessageTemplateSuccess();
  const isDeleteFailed = useIsDeleteMessageTemplateFailed();
  const failedDeleteErrorStr = useDeleteMessageTemplateFailedError();

  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    dispatch(setPageTitle(t('Messaging.Message Templates')));
    dispatch(setBackButton(true));
  }, [dispatch, t]);

  useEffect(() => {
    if (!companyKey || !userKey) {
      return;
    }
    dispatch(fetchMessageTemplates());
  }, [companyKey, userKey, dispatch]);

  useEffect(() => {
    if (!showTemplateModal) {
      setEditingTemplate(null);
    }
  }, [showTemplateModal]);

  useEffect(() => {
    if (isDeleteSuccess) {
      dispatch(setInitailDeleteMessageTemplateStatus());
      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('Messaging.Notifications.Deleted', { entity: t('Entity.Message Template') })
        })
      );
    } else if (isDeleteFailed) {
      dispatch(setInitailDeleteMessageTemplateStatus());
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Messaging.Notifications.DeleteFailed', {
            entity: t('Entity.Message Template'),
            error: failedDeleteErrorStr
          })
        })
      );
    }
  }, [isDeleteSuccess, isDeleteFailed, failedDeleteErrorStr, dispatch, t]);

  const handleAddButton = () => {
    setShowTemplateModal(true);
  };
  const handleDelete = record => {
    return () => {
      dispatch(deleteMessageTemplate(record));
    };
  };
  const handleDeleteButton = record => {
    return () => {
      confirmationModal(
        `${t('Common.Modal.SureTitle')}`,
        `${t('Messaging.ConfirmationModal.SureQuestion')}`,
        `${t('Common.Modal.Delete')}`,
        `${t('Common.Modal.Cancel')}`,
        handleDelete(record),
        'delete'
      );
    };
  };
  const handleEditButton = record => {
    return () => {
      setShowTemplateModal(true);
      setEditingTemplate(record);
    };
  };
  const tableHeaderTitle = text => (
    <span className={styles.messageTemplateTableHeader}>{text}</span>
  );

  const columns = [
    {
      title: tableHeaderTitle(t('Messaging.Subject')),
      dataIndex: 'subject',
      width: '30%',
      sorter: (a, b) => {
        const subjectA = a.subject.toLowerCase(),
          subjectB = b.subject.toLowerCase();
        if (subjectA < subjectB) {
          return -1;
        } else if (subjectA > subjectB) {
          return 1;
        }
        return 0;
      },
      render: text => <div>{text}</div>
    },
    {
      title: tableHeaderTitle(t('Messaging.Message')),
      dataIndex: 'body',
      sorter: (a, b) => {
        const bodyA = a.subject.toLowerCase(),
          bodyB = b.subject.toLowerCase();
        if (bodyA < bodyB) {
          return -1;
        } else if (bodyA > bodyB) {
          return 1;
        }
        return 0;
      },
      render: text => <div>{text}</div>
    },
    {
      title: tableHeaderTitle(t('Common.details')),
      dataIndex: 'details',
      render: (_, record) => {
        let createdDate = '',
          updatedDate = '';
        if (record.createdAt) {
          try {
            createdDate = record.createdAt
              ? format(new Date(record.createdAt), localization.formats.time.formats.dby_imp)
              : '';
            updatedDate = record.updatedAt
              ? format(new Date(record.updatedAt), localization.formats.time.formats.dby_imp)
              : '';
          } catch (e) {}
        }
        return (
          <Col>
            <Row>
              <span style={{ fontWeight: 'bold', marginRight: '1em' }}>
                {t('Common.CreatedBy')}
              </span>
              <span>{`${record.createdBy.firstName} ${record.createdBy.lastName} , ${createdDate}`}</span>
            </Row>
            <Row>
              <span style={{ fontWeight: 'bold', marginRight: '1em' }}>
                {t('Common.ModifiedBy')}
              </span>
              <span>{`${record.updatedBy.firstName} ${record.updatedBy.lastName} , ${updatedDate}`}</span>
            </Row>
          </Col>
        );
      }
    },
    {
      title: tableHeaderTitle(t('Forms.Table.Actions')),
      dataIndex: 'action',
      width: 90,
      align: 'center',
      render: (_, record) => {
        return (
          <EllipsisMenu
            menuItems={[
              {
                label: `${t('Common.EllipsisButton.Edit')}`,
                onClick: handleEditButton(record),
                entity: 'UPDATE',
                disabled: !userMsgPermission[MESSAGE_PERMISSION_ENTITY_TYPE.MessageTemplate].modify,
                id: 'btn_messageTemplatesEdit'
              },
              {
                label: `${t('Common.EllipsisButton.Delete')}`,
                onClick: handleDeleteButton(record),
                entity: 'DESTROY',
                danger: true,
                disabled: !userMsgPermission[MESSAGE_PERMISSION_ENTITY_TYPE.MessageTemplate].delete,
                id: 'btn_messageTemplatesDelete'
              }
            ]}
          />
        );
      }
    }
  ];
  const filteredTemplates = filterText => {
    return templates
      .map(temp => ({ ...temp, key: temp.id }))
      .filter(template => {
        if (!!filterText) {
          const reg = new RegExp(filterText, 'gi');
          return (
            (template.subject && reg.test(template.subject)) ||
            (template.body && reg.test(template.body))
          );
        }
        return true;
      });
  };
  return (
    <>
      {showTemplateModal && (
        <MessageTemplateModal
          visible={showTemplateModal}
          showModal={setShowTemplateModal}
          template={editingTemplate}
        />
      )}
      <SubTopNav
        right={() => (
          <Space size={16}>
            <Tooltip
              title={
                userMsgPermission[MESSAGE_PERMISSION_ENTITY_TYPE.MessageTemplate].create
                  ? null
                  : t('Messaging.NoPermission')
              }
            >
              <Button
                type="primary"
                size="large"
                onClick={handleAddButton}
                disabled={!userMsgPermission[MESSAGE_PERMISSION_ENTITY_TYPE.MessageTemplate].create}
                id={BUTTON_IDS.messageTemplatesCreate}
              >
                {t('Messaging.Create Template')}
              </Button>
            </Tooltip>
          </Space>
        )}
      />
      <div className={styles.messageTemplateToolbar}>
        <AntSearchbar onFilter={value => setFilterText(value)} value={filterText} />
        {`${templates.length} ${t('Messaging.Message Templates')}`}
      </div>
      <TemplateTableWrapper>
        <Table
          className={styles.gridTable + ' showScrollbarsOnHover'}
          dataSource={filteredTemplates(filterText)}
          columns={columns}
          loading={isFetchingTemplates || isDeleting}
          scroll={{ x: false, y: true }}
          pagination={false}
          sticky={true}
          size="small"
        />
      </TemplateTableWrapper>
    </>
  );
};
