/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Code from "./Code";
import Item from "./Item";
import Job from "./Job";

/**
 * The JobItem model module.
 * @module model/JobItem
 * @version 1.0
 */
class JobItem {
  /**
   * Constructs a new <code>JobItem</code>.
   * @alias module:model/JobItem
   */
  constructor() {
    JobItem.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>JobItem</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/JobItem} obj Optional instance to populate.
   * @return {module:model/JobItem} The populated <code>JobItem</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new JobItem();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("job")) {
        obj["job"] = Job.constructFromObject(data["job"]);
      }
      if (data.hasOwnProperty("item")) {
        obj["item"] = Item.constructFromObject(data["item"]);
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("originalQuantity")) {
        obj["originalQuantity"] = ApiClient.convertToType(
          data["originalQuantity"],
          "Number"
        );
      }
      if (data.hasOwnProperty("loadedQuantity")) {
        obj["loadedQuantity"] = ApiClient.convertToType(
          data["loadedQuantity"],
          "Number"
        );
      }
      if (data.hasOwnProperty("unloadedQuantity")) {
        obj["unloadedQuantity"] = ApiClient.convertToType(
          data["unloadedQuantity"],
          "Number"
        );
      }
      if (data.hasOwnProperty("loadedVarianceCode")) {
        obj["loadedVarianceCode"] = Code.constructFromObject(
          data["loadedVarianceCode"]
        );
      }
      if (data.hasOwnProperty("unloadedVarianceCode")) {
        obj["unloadedVarianceCode"] = Code.constructFromObject(
          data["unloadedVarianceCode"]
        );
      }
      if (data.hasOwnProperty("loadedCubics")) {
        obj["loadedCubics"] = ApiClient.convertToType(
          data["loadedCubics"],
          "Number"
        );
      }
      if (data.hasOwnProperty("unloadedCubics")) {
        obj["unloadedCubics"] = ApiClient.convertToType(
          data["unloadedCubics"],
          "Number"
        );
      }
      if (data.hasOwnProperty("loadedWeight")) {
        obj["loadedWeight"] = ApiClient.convertToType(
          data["loadedWeight"],
          "Number"
        );
      }
      if (data.hasOwnProperty("unloadedWeight")) {
        obj["unloadedWeight"] = ApiClient.convertToType(
          data["unloadedWeight"],
          "Number"
        );
      }
      if (data.hasOwnProperty("loadedTemperature")) {
        obj["loadedTemperature"] = ApiClient.convertToType(
          data["loadedTemperature"],
          "Number"
        );
      }
      if (data.hasOwnProperty("unloadedTemperature")) {
        obj["unloadedTemperature"] = ApiClient.convertToType(
          data["unloadedTemperature"],
          "Number"
        );
      }
      if (data.hasOwnProperty("loadedTime")) {
        obj["loadedTime"] = ApiClient.convertToType(
          data["loadedTime"],
          "Number"
        );
      }
      if (data.hasOwnProperty("unloadedTime")) {
        obj["unloadedTime"] = ApiClient.convertToType(
          data["unloadedTime"],
          "Number"
        );
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
JobItem.prototype["id"] = undefined;

/**
 * @member {module:model/Job} job
 */
JobItem.prototype["job"] = undefined;

/**
 * @member {module:model/Item} item
 */
JobItem.prototype["item"] = undefined;

/**
 * @member {module:model/JobItem.StatusEnum} status
 */
JobItem.prototype["status"] = undefined;

/**
 * @member {Number} originalQuantity
 */
JobItem.prototype["originalQuantity"] = undefined;

/**
 * @member {Number} loadedQuantity
 */
JobItem.prototype["loadedQuantity"] = undefined;

/**
 * @member {Number} unloadedQuantity
 */
JobItem.prototype["unloadedQuantity"] = undefined;

/**
 * @member {module:model/Code} loadedVarianceCode
 */
JobItem.prototype["loadedVarianceCode"] = undefined;

/**
 * @member {module:model/Code} unloadedVarianceCode
 */
JobItem.prototype["unloadedVarianceCode"] = undefined;

/**
 * @member {Number} loadedCubics
 */
JobItem.prototype["loadedCubics"] = undefined;

/**
 * @member {Number} unloadedCubics
 */
JobItem.prototype["unloadedCubics"] = undefined;

/**
 * @member {Number} loadedWeight
 */
JobItem.prototype["loadedWeight"] = undefined;

/**
 * @member {Number} unloadedWeight
 */
JobItem.prototype["unloadedWeight"] = undefined;

/**
 * @member {Number} loadedTemperature
 */
JobItem.prototype["loadedTemperature"] = undefined;

/**
 * @member {Number} unloadedTemperature
 */
JobItem.prototype["unloadedTemperature"] = undefined;

/**
 * @member {Number} loadedTime
 */
JobItem.prototype["loadedTime"] = undefined;

/**
 * @member {Number} unloadedTime
 */
JobItem.prototype["unloadedTime"] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
JobItem["StatusEnum"] = {
  /**
   * value: "CREATED"
   * @const
   */
  CREATED: "CREATED",

  /**
   * value: "LOADED"
   * @const
   */
  LOADED: "LOADED",

  /**
   * value: "DELIVERED"
   * @const
   */
  DELIVERED: "DELIVERED",

  /**
   * value: "DAMAGED"
   * @const
   */
  DAMAGED: "DAMAGED",

  /**
   * value: "CHECKED"
   * @const
   */
  CHECKED: "CHECKED"
};

export default JobItem;
