import { LoremIpsum } from 'lorem-ipsum';

import pfpHaroldOlsen from './static/images/drivers/pfp-harold-olsen.png';
import pfpJasonKhan from './static/images/drivers/pfp-jason-khan.png';
import pfpJoeleneBrown from './static/images/drivers/pfp-joelene-brown.png';
import pfpJordanChu from './static/images/drivers/pfp-jordan-chu.png';
import pfpKyleAnderson from './static/images/drivers/pfp-kyle-anderson.png';

import imageVehicle1 from './static/images/vehicles/vehicle1.png';
import imageVehicle2 from './static/images/vehicles/vehicle2.png';
import imageVehicle3 from './static/images/vehicles/vehicle3.png';
import imageVehicle4 from './static/images/vehicles/vehicle4.png';

const lorem = new LoremIpsum();

const ignitionStatuses = ['on', 'idle', 'off'];

const vehicleIcons = ['tn-i-flatbed', 'tn-i-tanker'];

const names = [
  { first: 'Hedda', last: 'Gardner' },
  { first: 'Cheryl', last: 'Manning' },
  { first: 'Sylvia', last: 'Graves' },
  { first: 'Francis', last: 'Park' },
  { first: 'Nathaniel', last: 'Spears' },
  { first: 'Thaddeus', last: 'Bright' },
  { first: 'Cameran', last: 'Gonzalez' },
  { first: 'Ebony', last: 'Thomas' },
  { first: 'Tanisha', last: 'Sargent' },
  { first: 'Bree', last: 'Ryan' },
  { first: 'Guy', last: 'Rush' },
  { first: 'Lunea', last: 'Wilcox' },
  { first: 'Barrett', last: 'Delacruz' },
  { first: 'Wade', last: 'Guthrie' },
  { first: 'Yuri', last: 'Hale' },
  { first: 'Alvin', last: 'Noel' },
  { first: 'Pandora', last: 'Coleman' },
  { first: 'Caesar', last: 'Cruz' },
  { first: 'Leo', last: 'Thornton' },
  { first: 'Quinn', last: 'Atkinson' },
  { first: 'Sarah', last: 'Allison' },
  { first: 'Barclay', last: 'Bauer' },
  { first: 'Zena', last: 'Mullen' },
  { first: 'Holmes', last: 'Love' },
  { first: 'Clark', last: 'Watkins' },
  { first: 'Jacob', last: 'Rose' },
  { first: 'Caleb', last: 'Robertson' },
  { first: 'Thomas', last: 'Acevedo' },
  { first: 'Lacota', last: 'Hewitt' },
  { first: 'Lacey', last: 'Carpenter' },
  { first: 'Frances', last: 'Guerrero' },
  { first: 'Sierra', last: 'Farley' },
  { first: 'Ima', last: 'Ward' },
  { first: 'Murphy', last: 'Suarez' },
  { first: 'Ray', last: 'Mendez' },
  { first: 'Patrick', last: 'Avery' },
  { first: 'Lara', last: 'Hodge' },
  { first: 'Carissa', last: 'Grimes' },
  { first: 'Lynn', last: 'Pruitt' },
  { first: 'Summer', last: 'Collins' },
  { first: 'Cassandra', last: 'Carr' },
  { first: 'Kermit', last: 'Fowler' },
  { first: 'Constance', last: 'Garrett' },
  { first: 'Sigourney', last: 'Owens' },
  { first: 'Summer', last: 'Young' },
  { first: 'Uma', last: 'Johns' },
  { first: 'Reuben', last: 'Avila' },
  { first: 'Francesca', last: 'Mccormick' },
  { first: 'Scott', last: 'Hammond' },
  { first: 'Reagan', last: 'Weaver' },
  { first: 'Raja', last: 'Bell' },
  { first: 'Perry', last: 'Salas' },
  { first: 'Oliver', last: 'Webb' },
  { first: 'Ali', last: 'Gonzales' },
  { first: 'Bethany', last: 'Herrera' },
  { first: 'Randall', last: 'Keith' },
  { first: 'Berk', last: 'Medina' },
  { first: 'Ella', last: 'Vinson' },
  { first: 'Tanner', last: 'Logan' },
  { first: 'Penelope', last: 'Delaney' },
  { first: 'Hedy', last: 'Knox' },
  { first: 'Linda', last: 'Lowery' },
  { first: 'Aphrodite', last: 'Oneill' },
  { first: 'Lionel', last: 'Bradshaw' },
  { first: 'Dominique', last: 'Ochoa' },
  { first: 'Kylan', last: 'Ramirez' },
  { first: 'Sigourney', last: 'Tate' },
  { first: 'Zeus', last: 'Solomon' },
  { first: 'Cole', last: 'Roach' },
  { first: 'Hiram', last: 'Burt' },
  { first: 'Alisa', last: 'Parker' },
  { first: 'Derek', last: 'Everett' },
  { first: 'Jada', last: 'Guerrero' },
  { first: 'Britanney', last: 'Barnes' },
  { first: 'Chastity', last: 'Hoover' },
  { first: 'Melissa', last: 'Mcpherson' },
  { first: 'Russell', last: 'Poole' },
  { first: 'Clark', last: 'Barr' },
  { first: 'Cassandra', last: 'Kerr' },
  { first: 'William', last: 'Santos' },
  { first: 'Cyrus', last: 'Dennis' },
  { first: 'Emerald', last: 'Ferrell' },
  { first: 'Amber', last: 'Bird' },
  { first: 'Xander', last: 'Sullivan' },
  { first: 'Drew', last: 'Green' },
  { first: 'Harper', last: 'Adkins' },
  { first: 'Hyacinth', last: 'Doyle' },
  { first: 'Keaton', last: 'Hyde' },
  { first: 'Cheyenne', last: 'Grimes' },
  { first: 'Felicia', last: 'Buchanan' },
  { first: 'Kylynn', last: 'Trevino' },
  { first: 'Aristotle', last: 'Shields' },
  { first: 'Susan', last: 'Newman' },
  { first: 'Lenore', last: 'Reyes' },
  { first: 'Troy', last: 'Sweeney' },
  { first: 'Theodore', last: 'Dawson' },
  { first: 'Kai', last: 'Mcbride' },
  { first: 'Tate', last: 'Gillespie' },
  { first: 'Shea', last: 'Huber' },
  { first: 'Vaughan', last: 'Simpson' }
];

const fleet = [
  { company: 'Lobortis Quis Pede Foundation' },
  { company: 'Mauris Institute' },
  { company: 'Egestas Fusce Aliquet Corp.' },
  { company: 'Proin LLP' },
  { company: 'Dignissim Magna A Institute' },
  { company: 'Aenean Egestas Hendrerit Associates' },
  { company: 'Vivamus Corp.' },
  { company: 'Ultricies Dignissim LLC' },
  { company: 'Facilisis Suspendisse Commodo Inc.' },
  { company: 'Magna Corporation' },
  { company: 'Quam Vel Industries' },
  { company: 'Habitant Morbi Corp.' },
  { company: 'Tempor Consulting' },
  { company: 'Elit Industries' },
  { company: 'Cum Sociis LLC' },
  { company: 'Ipsum Non Corporation' },
  { company: 'Ultrices A Industries' },
  { company: 'Tempus Risus Donec Corp.' },
  { company: 'Elit Associates' },
  { company: 'Non Industries' },
  { company: 'Vitae Diam Inc.' },
  { company: 'Luctus Ipsum Institute' },
  { company: 'Sed Incorporated' },
  { company: 'Ultrices A Ltd' },
  { company: 'In Corp.' },
  { company: 'Neque Nullam PC' },
  { company: 'In Ornare Limited' },
  { company: 'Sed Consequat Auctor Company' },
  { company: 'Vitae Inc.' },
  { company: 'Id Risus LLP' },
  { company: 'Phasellus Industries' },
  { company: 'Blandit Company' },
  { company: 'Arcu LLC' },
  { company: 'Placerat Orci Lacus Consulting' },
  { company: 'Dolor Tempus Non PC' },
  { company: 'Ante Ipsum Corporation' },
  { company: 'Urna Nullam Lobortis Inc.' },
  { company: 'Semper Ltd' },
  { company: 'Neque Sed Sem Institute' },
  { company: 'Mollis Vitae Posuere Foundation' },
  { company: 'Eu Odio Tristique Incorporated' },
  { company: 'Donec Nibh Quisque Consulting' },
  { company: 'Purus Gravida PC' },
  { company: 'Gravida Mauris Ut Corp.' },
  { company: 'Malesuada Corp.' },
  { company: 'Mi PC' },
  { company: 'Vitae Odio Sagittis Ltd' },
  { company: 'Metus Inc.' },
  { company: 'Laoreet Libero Inc.' },
  { company: 'Vestibulum Lorem Foundation' },
  { company: 'Nunc Commodo Auctor Consulting' },
  { company: 'Nunc Sed Corp.' },
  { company: 'Adipiscing Lacus Ut Limited' },
  { company: 'Vivamus Sit Amet Limited' },
  { company: 'Ullamcorper Nisl Arcu Corporation' },
  { company: 'Vitae Posuere Consulting' },
  { company: 'Erat LLC' },
  { company: 'Blandit Incorporated' },
  { company: 'Donec Porttitor Tellus Corporation' },
  { company: 'Et Netus Et Company' },
  { company: 'Maecenas Ornare Corp.' },
  { company: 'Nascetur Ridiculus Mus Corp.' },
  { company: 'Ornare Egestas Ligula LLC' },
  { company: 'Tristique Pellentesque LLC' },
  { company: 'Lorem Eu Foundation' },
  { company: 'Imperdiet Erat Nonummy Company' },
  { company: 'Sed LLC' },
  { company: 'Mollis Non Inc.' },
  { company: 'Ante Dictum Associates' },
  { company: 'Scelerisque Neque Foundation' },
  { company: 'Vitae Inc.' },
  { company: 'Enim Incorporated' },
  { company: 'Duis Ac Consulting' },
  { company: 'Donec Tempus Industries' },
  { company: 'Eleifend Vitae Corporation' },
  { company: 'Lorem Eget Mollis Associates' },
  { company: 'Proin Ultrices Institute' },
  { company: 'Vitae Nibh Donec Institute' },
  { company: 'Proin Eget Inc.' },
  { company: 'Dignissim Lacus Corporation' },
  { company: 'Sagittis Nullam Vitae Inc.' },
  { company: 'Penatibus Et Limited' },
  { company: 'Diam Pellentesque Consulting' },
  { company: 'Vel Nisl Quisque Foundation' },
  { company: 'Mauris A Nunc Corp.' },
  { company: 'Vitae Institute' },
  { company: 'Taciti Sociosqu Ad Foundation' },
  { company: 'Adipiscing Ltd' },
  { company: 'Luctus Ipsum Leo LLC' },
  { company: 'Gravida Limited' },
  { company: 'Odio A Purus Incorporated' },
  { company: 'Vel Nisl Quisque Institute' },
  { company: 'Vulputate Lacus Corporation' },
  { company: 'Urna Suscipit Industries' },
  { company: 'Ornare Fusce Mollis PC' },
  { company: 'At Inc.' },
  { company: 'Enim Diam Vel Inc.' },
  { company: 'Sem Magna Nec Ltd' },
  { company: 'Eu Corp.' },
  { company: 'Etiam Laoreet Limited' }
];

const addresses = [
  {
    street: 'P.O. Box 490, 3634 Augue Rd.',
    city: 'Minneapolis',
    state: 'MN',
    zip: '78040'
  },
  { street: '166 Lectus. Street', city: 'Essex', state: 'VT', zip: '94902' },
  {
    street: 'Ap #436-2150 Malesuada Rd.',
    city: 'Oklahoma City',
    state: 'OK',
    zip: '78623'
  },
  { street: '6512 Magna St.', city: 'Kapolei', state: 'HI', zip: '66960' },
  {
    street: 'Ap #339-4176 Dolor Av.',
    city: 'Tulsa',
    state: 'Oklahoma',
    zip: '54452'
  },
  {
    street: 'Ap #509-5053 Vel, St.',
    city: 'Clarksville',
    state: 'Tennessee',
    zip: '72419'
  },
  {
    street: 'P.O. Box 210, 2870 Ac Avenue',
    city: 'Grand Island',
    state: 'Nebraska',
    zip: '88473'
  },
  {
    street: '927-8571 Tincidunt, Road',
    city: 'Topeka',
    state: 'KS',
    zip: '51084'
  },
  {
    street: 'Ap #242-4777 Aliquet Rd.',
    city: 'Burlington',
    state: 'Vermont',
    zip: '52655'
  },
  {
    street: 'Ap #324-545 Eu Avenue',
    city: 'Biloxi',
    state: 'Mississippi',
    zip: '46154'
  },
  {
    street: '879-2404 Eu, Ave',
    city: 'Birmingham',
    state: 'AL',
    zip: '35641'
  },
  {
    street: '536-9015 Nec Rd.',
    city: 'Rockford',
    state: 'Illinois',
    zip: '62829'
  },
  {
    street: 'P.O. Box 917, 4631 Imperdiet Avenue',
    city: 'Lawton',
    state: 'OK',
    zip: '72761'
  },
  {
    street: '724-5431 Maecenas Rd.',
    city: 'Baton Rouge',
    state: 'LA',
    zip: '35157'
  },
  {
    street: 'Ap #270-5856 Lorem Ave',
    city: 'Bellevue',
    state: 'Nebraska',
    zip: '46998'
  },
  { street: '4987 Cum St.', city: 'Toledo', state: 'Ohio', zip: '83926' },
  {
    street: '231-8358 Aenean Rd.',
    city: 'Cleveland',
    state: 'OH',
    zip: '49375'
  },
  {
    street: '3601 Pellentesque Rd.',
    city: 'Reading',
    state: 'Pennsylvania',
    zip: '12730'
  },
  { street: '3950 Quam. St.', city: 'Tampa', state: 'Florida', zip: '94167' },
  {
    street: '2453 Nulla Road',
    city: 'Joliet',
    state: 'Illinois',
    zip: '95173'
  },
  {
    street: 'P.O. Box 448, 6628 Diam Road',
    city: 'Dallas',
    state: 'TX',
    zip: '16335'
  },
  { street: '5251 Luctus St.', city: 'Pocatello', state: 'ID', zip: '54456' },
  {
    street: 'Ap #346-8841 Risus. Road',
    city: 'Biloxi',
    state: 'Mississippi',
    zip: '65674'
  },
  {
    street: 'P.O. Box 162, 5226 Sem Avenue',
    city: 'Sterling Heights',
    state: 'Michigan',
    zip: '10081'
  },
  {
    street: 'Ap #379-8132 Nec Rd.',
    city: 'Columbia',
    state: 'MO',
    zip: '19328'
  },
  { street: '3307 Malesuada Road', city: 'Fresno', state: 'CA', zip: '96766' },
  {
    street: '969-4653 Orci Avenue',
    city: 'Fort Worth',
    state: 'Texas',
    zip: '14294'
  },
  { street: '8799 Eu Avenue', city: 'Bear', state: 'Delaware', zip: '29860' },
  {
    street: 'P.O. Box 947, 4006 Nec, Road',
    city: 'Baltimore',
    state: 'Maryland',
    zip: '76965'
  },
  { street: '3331 Nam Rd.', city: 'Boise', state: 'Idaho', zip: '71724' },
  {
    street: 'P.O. Box 949, 6696 Proin Rd.',
    city: 'Hattiesburg',
    state: 'Mississippi',
    zip: '37836'
  },
  {
    street: 'Ap #776-6352 Sed Av.',
    city: 'Nashville',
    state: 'Tennessee',
    zip: '63377'
  },
  {
    street: 'Ap #572-8348 Molestie Ave',
    city: 'Orlando',
    state: 'FL',
    zip: '50832'
  },
  { street: '6710 Etiam St.', city: 'Mobile', state: 'Alabama', zip: '36740' },
  {
    street: 'P.O. Box 823, 7764 Ornare, Rd.',
    city: 'Orlando',
    state: 'Florida',
    zip: '86171'
  },
  {
    street: '852-8315 Amet St.',
    city: 'Olathe',
    state: 'Kansas',
    zip: '23305'
  },
  {
    street: '921-5831 Hendrerit St.',
    city: 'Bellevue',
    state: 'Nebraska',
    zip: '25454'
  },
  {
    street: 'Ap #604-9357 Neque Road',
    city: 'Salt Lake City',
    state: 'UT',
    zip: '80358'
  },
  {
    street: 'Ap #741-4694 Sed Street',
    city: 'Orlando',
    state: 'FL',
    zip: '61136'
  },
  {
    street: 'Ap #601-4088 Non Av.',
    city: 'Cleveland',
    state: 'Ohio',
    zip: '74512'
  },
  {
    street: '248-6051 Lobortis Avenue',
    city: 'Newport News',
    state: 'Virginia',
    zip: '67556'
  },
  {
    street: 'Ap #494-8855 Libero Rd.',
    city: 'Austin',
    state: 'Texas',
    zip: '42942'
  },
  {
    street: 'P.O. Box 727, 4755 Blandit Avenue',
    city: 'Salem',
    state: 'Oregon',
    zip: '90979'
  },
  {
    street: 'Ap #984-2313 Pellentesque, Road',
    city: 'Sioux City',
    state: 'IA',
    zip: '88725'
  },
  {
    street: '1943 Aliquam Ave',
    city: 'Birmingham',
    state: 'AL',
    zip: '36667'
  },
  {
    street: 'Ap #449-9506 Praesent Avenue',
    city: 'Covington',
    state: 'KY',
    zip: '64283'
  },
  { street: '4289 Ornare. Rd.', city: 'Gulfport', state: 'MS', zip: '77866' },
  {
    street: '8237 Euismod Av.',
    city: 'Erie',
    state: 'Pennsylvania',
    zip: '37706'
  },
  { street: '991-2881 Amet, St.', city: 'Norfolk', state: 'VA', zip: '15747' },
  {
    street: 'Ap #458-2290 Vestibulum. Av.',
    city: 'Worcester',
    state: 'MA',
    zip: '50567'
  },
  {
    street: '324-7541 Velit Ave',
    city: 'Meridian',
    state: 'ID',
    zip: '84137'
  },
  { street: '709-6382 Mauris St.', city: 'Tulsa', state: 'OK', zip: '34363' },
  {
    street: 'Ap #950-2869 Tristique St.',
    city: 'Mobile',
    state: 'AL',
    zip: '35918'
  },
  {
    street: 'P.O. Box 126, 1842 Phasellus Ave',
    city: 'Gulfport',
    state: 'Mississippi',
    zip: '81396'
  },
  {
    street: 'Ap #744-2004 Arcu. St.',
    city: 'Kearney',
    state: 'Nebraska',
    zip: '30402'
  },
  {
    street: '3759 Vel Avenue',
    city: 'Racine',
    state: 'Wisconsin',
    zip: '23123'
  },
  { street: '7032 Nisl Rd.', city: 'Wyoming', state: 'WY', zip: '14453' },
  {
    street: 'Ap #184-2611 Malesuada Av.',
    city: 'Biloxi',
    state: 'MS',
    zip: '85269'
  },
  {
    street: '619-2570 Est. St.',
    city: 'Hartford',
    state: 'Connecticut',
    zip: '64065'
  },
  {
    street: '776-1202 Purus Avenue',
    city: 'Gaithersburg',
    state: 'MD',
    zip: '76231'
  },
  {
    street: '7050 Viverra. Street',
    city: 'Springfield',
    state: 'Illinois',
    zip: '92876'
  },
  { street: '2553 Non St.', city: 'West Jordan', state: 'Utah', zip: '45208' },
  {
    street: 'P.O. Box 572, 6848 Dapibus St.',
    city: 'Tucson',
    state: 'AZ',
    zip: '86197'
  },
  {
    street: '875 Rutrum. Avenue',
    city: 'Bellevue',
    state: 'WA',
    zip: '24448'
  },
  {
    street: 'Ap #248-4897 Vestibulum, Av.',
    city: 'Grand Rapids',
    state: 'MI',
    zip: '27269'
  },
  { street: '3577 Nullam Rd.', city: 'Laramie', state: 'WY', zip: '85441' },
  {
    street: '892-9302 Imperdiet Rd.',
    city: 'Helena',
    state: 'MT',
    zip: '61192'
  },
  {
    street: 'Ap #648-3012 Dolor St.',
    city: 'Detroit',
    state: 'MI',
    zip: '72950'
  },
  {
    street: 'P.O. Box 852, 7797 Ligula. St.',
    city: 'Houston',
    state: 'TX',
    zip: '89173'
  },
  {
    street: 'Ap #118-147 Vitae Avenue',
    city: 'Covington',
    state: 'KY',
    zip: '32897'
  },
  {
    street: '2937 Quam, Avenue',
    city: 'Nashville',
    state: 'Tennessee',
    zip: '25988'
  },
  {
    street: 'P.O. Box 782, 2339 Pellentesque Rd.',
    city: 'Columbia',
    state: 'Missouri',
    zip: '69180'
  },
  {
    street: '6341 Vitae Avenue',
    city: 'Portland',
    state: 'Maine',
    zip: '17150'
  },
  {
    street: 'P.O. Box 323, 6697 Vestibulum. St.',
    city: 'Cincinnati',
    state: 'OH',
    zip: '90451'
  },
  {
    street: 'P.O. Box 827, 5021 Proin Street',
    city: 'Reno',
    state: 'NV',
    zip: '76443'
  },
  {
    street: 'P.O. Box 365, 9863 Varius Rd.',
    city: 'Carson City',
    state: 'NV',
    zip: '18674'
  },
  {
    street: 'P.O. Box 632, 8693 Ligula. Rd.',
    city: 'Detroit',
    state: 'MI',
    zip: '14468'
  },
  {
    street: '6554 Urna Rd.',
    city: 'Frederick',
    state: 'Maryland',
    zip: '71808'
  },
  {
    street: '734-4728 Nec, Street',
    city: 'Lowell',
    state: 'MA',
    zip: '15057'
  },
  {
    street: 'P.O. Box 808, 5894 Quam, Rd.',
    city: 'Cheyenne',
    state: 'Wyoming',
    zip: '30370'
  },
  {
    street: 'P.O. Box 509, 3957 Nec St.',
    city: 'New Orleans',
    state: 'Louisiana',
    zip: '79492'
  },
  {
    street: 'Ap #896-3596 Lectus, Road',
    city: 'Mesa',
    state: 'AZ',
    zip: '85260'
  },
  {
    street: 'P.O. Box 398, 8031 Mauris. Street',
    city: 'Memphis',
    state: 'TN',
    zip: '31014'
  },
  {
    street: '675-8932 Ante. Rd.',
    city: 'Fort Wayne',
    state: 'IN',
    zip: '39509'
  },
  {
    street: 'Ap #841-1912 Eleifend Rd.',
    city: 'South Portland',
    state: 'ME',
    zip: '79512'
  },
  {
    street: '5755 In Rd.',
    city: 'Worcester',
    state: 'Massachusetts',
    zip: '60918'
  },
  { street: '8292 Arcu. Street', city: 'Austin', state: 'TX', zip: '22317' },
  {
    street: 'Ap #410-3304 Adipiscing Rd.',
    city: 'Biloxi',
    state: 'MS',
    zip: '88126'
  },
  {
    street: '724-4037 Laoreet Rd.',
    city: 'Hillsboro',
    state: 'OR',
    zip: '58845'
  },
  {
    street: 'P.O. Box 890, 4472 Non, Rd.',
    city: 'Southaven',
    state: 'MS',
    zip: '75605'
  },
  {
    street: 'P.O. Box 375, 6174 In Avenue',
    city: 'Richmond',
    state: 'Virginia',
    zip: '51075'
  },
  { street: '9467 Vivamus Road', city: 'Georgia', state: 'GA', zip: '74422' },
  {
    street: '2615 Ipsum Street',
    city: 'Bellevue',
    state: 'Nebraska',
    zip: '50584'
  },
  { street: 'Ap #792-7210 Ut Rd.', city: 'Helena', state: 'MT', zip: '17067' },
  {
    street: 'Ap #354-3349 Amet, Ave',
    city: 'Cleveland',
    state: 'OH',
    zip: '16896'
  },
  {
    street: 'P.O. Box 954, 5930 Magna. Street',
    city: 'Racine',
    state: 'WI',
    zip: '94910'
  },
  {
    street: '539-972 Tortor. Rd.',
    city: 'Covington',
    state: 'Kentucky',
    zip: '78403'
  },
  { street: '1593 Sem. Street', city: 'Kenosha', state: 'WI', zip: '44929' },
  {
    street: '434-8187 Cursus Rd.',
    city: 'Virginia Beach',
    state: 'Virginia',
    zip: '13551'
  },
  {
    street: 'Ap #658-7703 Ipsum Ave',
    city: 'Dallas',
    state: 'TX',
    zip: '78122'
  }
];

const hours = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23'
];

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
  //The maximum is exclusive and the minimum is inclusive
};

export const trackerTripsData = amount => {
  let i,
    resultArray = [];
  for (i = 0; i < amount; i++) {
    const currentAddress = addresses[getRandomInt(0, addresses.length)];

    resultArray.push({
      ignitionStatus: `${ignitionStatuses[getRandomInt(0, ignitionStatuses.length)]}`,
      vehicleIcon: `${vehicleIcons[getRandomInt(0, vehicleIcons.length)]}`,
      vehicleNumber: `${getRandomInt(100, 200)}X`,
      driverName: `${names[getRandomInt(0, names.length)].first} ${
        names[getRandomInt(0, names.length)].last
      }`,
      lastUpdate: `${hours[getRandomInt(0, hours.length)]}:${getRandomInt(10, 59)}`,
      lastKnownAddress: `${currentAddress.street} ${currentAddress.city},  ${currentAddress.state}`
    });
  }
  return resultArray;
};

export const dashboardData = amount => {
  let i,
    resultArray = [];
  for (i = 0; i < amount; i++) {
    const currentAddress = addresses[getRandomInt(0, addresses.length)];

    resultArray.push({
      vehicleName: `${getRandomInt(100, 200)}X`,
      driver: `${names[getRandomInt(0, names.length)].first} ${
        names[getRandomInt(0, names.length)].last
      }`,
      fleet: `${fleet[getRandomInt(0, fleet.length)].company}`,
      commsStatus: `${getRandomInt(0, 6) === 1 ? 'On' : 'Off'}`,
      massD: `${getRandomInt(100, 200)}`,
      massA: `${getRandomInt(100, 200)}`,
      lastLocation: `${currentAddress.street} ${currentAddress.city},  ${currentAddress.state}, ${currentAddress.zip}`
    });
  }

  return resultArray;
};

export const messagingData = amount => {
  let i,
    resultArray = [];
  for (i = 0; i < amount; i++) {
    const sent = getRandomInt(0, 2) === 0;

    resultArray.push({
      driver: `${names[getRandomInt(0, names.length)].first} ${
        names[getRandomInt(0, names.length)].last
      }`,
      direction: `${sent ? 'From:' : 'To:'}`,
      directionIcon: `${sent ? 'tn-i-arrow-forward' : 'tn-i-arrow-backward'}`,
      message: `${lorem.generateWords(getRandomInt(5, 15))}`
    });
  }

  return resultArray;
};

export const mapData = [
  {
    title: 'Device',
    name: '358683067947599 - Tysons TD',
    did: 17049,
    vid: null,
    compass: 'W',
    lat: -37.84678,
    lng: 145.06255,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_green_W-b604510b788a01b9036252ec18606163.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Vehicle',
    name: 'Prashant Vehicle',
    did: 26148,
    vid: 46190,
    compass: 'N',
    lat: -36.80615,
    lng: 174.74293,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_green_N-5072478a2906795d23083587a3cf7e69.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Device',
    name: '359739072217748',
    did: 26341,
    vid: null,
    compass: 'W',
    lat: -37.84667205810547,
    lng: 145.06280517578125,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_blue_W-4fd26cf12b965e076f24a3a902330899.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Vehicle',
    name: 'AL1234',
    did: 50288,
    vid: 33015,
    compass: 'SW',
    lat: -37.84786,
    lng: 145.06358,
    icon_type: 'vehicle',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/vehicle_green_SW-d112a4985023821cb8864783d1fc98db.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Vehicle',
    name: 'Armans car',
    did: 31893,
    vid: 60892,
    compass: 'NW',
    lat: -37.84697341918945,
    lng: 145.0637664794922,
    icon_type: 'vehicle',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/vehicle_green_NW-f63e1139842a6b6fac92a416e3419829.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Vehicle',
    name: 'Avinash Vehicle',
    did: 50574,
    vid: 67642,
    compass: 'N',
    lat: -37.84696,
    lng: 145.06416,
    icon_type: 'vehicle',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/vehicle_green_N-ff12e6ac9913cc360ae80bee465e6cb4.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Device',
    name: 'BYOD Test Device',
    did: 17387,
    vid: null,
    compass: 'NE',
    lat: -32.86627960205078,
    lng: 151.70135498046875,
    icon_type: 'device',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/device_green_NE-019807f7c5443a2e8dc4a11d3faf1552.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Device',
    name: 'Demo',
    did: 35453,
    vid: null,
    compass: 'NW',
    lat: -37.84709,
    lng: 145.06386,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_blue_NW-b65d28d5bebb2fe292490f25f9f03c00.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Device',
    name: 'Goughy MiTAC',
    did: 15569,
    vid: null,
    compass: 'SW',
    lat: -38.05421829223633,
    lng: 144.5832977294922,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_blue_SW-6423a7ca37234ea40caed0efc160817e.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Vehicle',
    name: 'Ibrahims_car',
    did: 29657,
    vid: 66673,
    compass: 'E',
    lat: -37.84738,
    lng: 145.06485,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_green_E-2b5d12a3f2b3fdd283ccd48ca9554e4b.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Vehicle',
    name: 'WRM712',
    did: 4668,
    vid: 6887,
    compass: 'N',
    lat: -37.84722,
    lng: 145.06432,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_green_N-5072478a2906795d23083587a3cf7e69.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Vehicle',
    name: 'JLG575',
    did: 14713,
    vid: 2521,
    compass: 'NE',
    lat: -19.3634,
    lng: 146.83894,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_green_NE-523343909264343396c3519bab5fc168.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Vehicle',
    name: 'F150 Raptor',
    did: 37236,
    vid: 7368,
    compass: 'N',
    lat: -37.847293853759766,
    lng: 145.06385803222656,
    icon_type: 'vehicle',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/vehicle_yellow_N-31f3c64d27fc7f5cb92949fde6fcaaa8.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Device',
    name: 'QA-PDA-2',
    did: 3612,
    vid: null,
    compass: 'N',
    lat: -37.84667,
    lng: 145.06448,
    icon_type: 'device',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/device_aqua_N-296e42f50e4c3cf1c2100b33f0aa0cc8.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Device',
    name: 'QA-PDA-3',
    did: 4196,
    vid: null,
    compass: 'W',
    lat: -37.8469,
    lng: 145.06405,
    icon_type: 'device',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/device_aqua_W-dd7e11f29af08630bca8e84415aef9e0.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Vehicle',
    name: 'PDQ Test Vehicle_4',
    did: 4197,
    vid: 2010,
    compass: 'N',
    lat: -37.69026,
    lng: 145.14799,
    icon_type: 'vehicle',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/vehicle_yellow_N-31f3c64d27fc7f5cb92949fde6fcaaa8.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Vehicle',
    name: 'PDA_TEST_PK',
    did: 3969,
    vid: 3561,
    compass: 'N',
    lat: -37.84718,
    lng: 145.06418,
    icon_type: 'vehicle',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/vehicle_yellow_N-31f3c64d27fc7f5cb92949fde6fcaaa8.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Device',
    name: 'QA-iFace-1',
    did: 44,
    vid: null,
    compass: 'N',
    lat: -37.863,
    lng: 144.815845,
    icon_type: 'device',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/device_yellow_N-c1ddde3fc19670486f4c77b6708b58b9.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Device',
    name: 'Sid Phone',
    did: 49366,
    vid: null,
    compass: 'N',
    lat: 33.97633,
    lng: -117.71685,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_green_N-5072478a2906795d23083587a3cf7e69.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Vehicle',
    name: 'PortableKitVehicle',
    did: 811,
    vid: 2392,
    compass: 'NE',
    lat: -37.77463,
    lng: 145.12632,
    icon_type: 'vehicle',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/vehicle_aqua_NE-008baddc3cf7e3650ba77848859cd9ee.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Device',
    name: 'Test-GPS-358108070347082',
    did: 39154,
    vid: null,
    compass: 'E',
    lat: -37.84704,
    lng: 145.06384,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_blue_E-abeba5fc5db86e0377e3e03d5b4029e1.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Device',
    name: 'Trent Rugged iFace',
    did: 21596,
    vid: null,
    compass: 'N',
    lat: -37.84732,
    lng: 145.06384,
    icon_type: 'device',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/device_yellow_N-c1ddde3fc19670486f4c77b6708b58b9.png',
    anchors: {
      marker: {
        x: 12,
        y: 12
      },
      text_marker: {
        x: -20,
        y: 6
      }
    }
  },
  {
    title: 'Device',
    name: 'Vi iFace',
    did: 23908,
    vid: null,
    compass: 'E',
    lat: -37.84713,
    lng: 145.063865,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_green_E-2b5d12a3f2b3fdd283ccd48ca9554e4b.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Device',
    name: 'Vi iFace 2',
    did: 24543,
    vid: null,
    compass: 'W',
    lat: -37.84694,
    lng: 145.06369,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_blue_W-4fd26cf12b965e076f24a3a902330899.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Device',
    name: 'Vi iFace Nexus',
    did: 3401,
    vid: null,
    compass: 'N',
    lat: -31.085693,
    lng: 150.912249,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_green_N-5072478a2906795d23083587a3cf7e69.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  },
  {
    title: 'Device',
    name: 'ViFace',
    did: 458,
    vid: null,
    compass: 'SW',
    lat: -37.847537,
    lng: 145.063314,
    icon_type: 'arrow',
    icon:
      '//d39offdwf5t4y2.cloudfront.net/assets/map/arrow_green_SW-6afa6a3745d4d79cf7acb9e454863cc0.png',
    anchors: {
      marker: {
        x: 8,
        y: 8
      },
      text_marker: {
        x: -10,
        y: 10
      }
    }
  }
];

export const notifications = [
  {
    id: 37773888,
    filtered: false,
    eventId: 44431,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574607464819,
    event: {
      id: 44431,
      device: {
        id: 68484,
        name: '22404561',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '22404561'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145744,819527+0000',
      type: 'IO_INPUT',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145735,127104+0000","9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145735,166210+0000"],"gps":{"heading":114.9,"latitude":-45.88595333333333,"time":"2019-11-24T14:57:44.819527Z","speed":5.9,"longitude":170.463085,"mileage":294},"id":"9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145744,819527+0000","type":"input1","aid":"9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145735,067941+0000","timestamp":"2019-11-24T14:57:44.819527Z"}}',
      videoIds: [
        '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145735,127104+0000',
        '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145735,166210+0000'
      ],
      gps: {
        Lat: -45.88595,
        Lng: 170.46309,
        Spd: 5.9,
        Dir: 114.9,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Kaikorai Valley Road, Kenmure, Dunedin',
      timeAt: '2019-11-24T14:57:44.819Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T02:02:42.794+11:00'
    }
  },
  {
    id: 37773882,
    filtered: false,
    eventId: 44431,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574607464819,
    event: {
      id: 44431,
      device: {
        id: 68484,
        name: '22404561',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '22404561'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145744,819527+0000',
      type: 'IO_INPUT',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145735,127104+0000","9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145735,166210+0000"],"gps":{"heading":114.9,"latitude":-45.88595333333333,"time":"2019-11-24T14:57:44.819527Z","speed":5.9,"longitude":170.463085,"mileage":294},"id":"9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145744,819527+0000","type":"input1","aid":"9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145735,067941+0000","timestamp":"2019-11-24T14:57:44.819527Z"}}',
      videoIds: [
        '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145735,127104+0000',
        '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T145735,166210+0000'
      ],
      gps: {
        Lat: -45.88595,
        Lng: 170.46309,
        Spd: 5.9,
        Dir: 114.9,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Kaikorai Valley Road, Kenmure, Dunedin',
      timeAt: '2019-11-24T14:57:44.819Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T02:02:42.794+11:00'
    }
  },
  {
    id: 37773887,
    filtered: false,
    eventId: 44432,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574609784491,
    event: {
      id: 44432,
      device: {
        id: 68486,
        name: '22404564',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '22404564'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153624,491510+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153614,619239+0000","68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153614,738311+0000"],"gps":{"heading":22.1,"latitude":-45.69616666666667,"time":"2019-11-24T15:36:24.491510Z","speed":73.3,"longitude":170.05026,"mileage":39952},"id":"68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153624,491510+0000","type":"high","aid":"68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153614,389278+0000","timestamp":"2019-11-24T15:36:24.491510Z"}}',
      videoIds: [
        '68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153614,619239+0000',
        '68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153614,738311+0000'
      ],
      gps: {
        Lat: -45.69617,
        Lng: 170.05026,
        Spd: 73.3,
        Dir: 22.1,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Sutton Clarks Junction Road, Clarks Junction',
      timeAt: '2019-11-24T15:36:24.491Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T02:39:34.514+11:00'
    }
  },
  {
    id: 37773885,
    filtered: false,
    eventId: 44432,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574609784491,
    event: {
      id: 44432,
      device: {
        id: 68486,
        name: '22404564',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '22404564'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153624,491510+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153614,619239+0000","68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153614,738311+0000"],"gps":{"heading":22.1,"latitude":-45.69616666666667,"time":"2019-11-24T15:36:24.491510Z","speed":73.3,"longitude":170.05026,"mileage":39952},"id":"68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153624,491510+0000","type":"high","aid":"68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153614,389278+0000","timestamp":"2019-11-24T15:36:24.491510Z"}}',
      videoIds: [
        '68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153614,619239+0000',
        '68p8HeSn0gRagNaF86dbdHqRjQH_20191124T153614,738311+0000'
      ],
      gps: {
        Lat: -45.69617,
        Lng: 170.05026,
        Spd: 73.3,
        Dir: 22.1,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Sutton Clarks Junction Road, Clarks Junction',
      timeAt: '2019-11-24T15:36:24.491Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T02:39:34.514+11:00'
    }
  },
  {
    id: 37773896,
    filtered: false,
    eventId: 44433,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574612603660,
    event: {
      id: 44433,
      device: {
        id: 40874,
        name: '23600297',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600297'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162323,660878+0000',
      type: 'IO_INPUT',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162314,541498+0000","2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162314,340780+0000"],"gps":{},"id":"2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162323,660878+0000","type":"input1","aid":"2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162313,986772+0000","timestamp":"2019-11-24T16:23:23.660878Z"}}',
      videoIds: [
        '2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162314,541498+0000',
        '2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162314,340780+0000'
      ],
      gps: {},
      timeAt: '2019-11-24T16:23:23.660Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T03:24:21.453+11:00'
    }
  },
  {
    id: 37773889,
    filtered: false,
    eventId: 44433,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574612603660,
    event: {
      id: 44433,
      device: {
        id: 40874,
        name: '23600297',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600297'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162323,660878+0000',
      type: 'IO_INPUT',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162314,541498+0000","2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162314,340780+0000"],"gps":{},"id":"2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162323,660878+0000","type":"input1","aid":"2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162313,986772+0000","timestamp":"2019-11-24T16:23:23.660878Z"}}',
      videoIds: [
        '2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162314,541498+0000',
        '2ozbpCE5DqMr4o5XAlV0DKiiSME_20191124T162314,340780+0000'
      ],
      gps: {},
      timeAt: '2019-11-24T16:23:23.660Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T03:24:21.453+11:00'
    }
  },
  {
    id: 37773991,
    filtered: false,
    eventId: 44434,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574614853165,
    event: {
      id: 44434,
      device: {
        id: 41006,
        name: '23600234',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600234'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170053,165266+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170043,587037+0000","BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170043,466242+0000"],"gps":{"heading":153.1,"latitude":-37.509906666666666,"time":"2019-11-24T17:00:53.165266Z","speed":30.9,"longitude":175.525065,"mileage":111107},"id":"BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170053,165266+0000","type":"high","aid":"BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170043,043773+0000","timestamp":"2019-11-24T17:00:53.165266Z"}}',
      videoIds: [
        'BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170043,587037+0000',
        'BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170043,466242+0000'
      ],
      gps: {
        Lat: -37.50991,
        Lng: 175.52507,
        Spd: 30.9,
        Dir: 153.1,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'State Highway 27, Springdale',
      timeAt: '2019-11-24T17:00:53.165Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T04:46:31.271+11:00'
    }
  },
  {
    id: 37773893,
    filtered: false,
    eventId: 44434,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574614853165,
    event: {
      id: 44434,
      device: {
        id: 41006,
        name: '23600234',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600234'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170053,165266+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170043,587037+0000","BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170043,466242+0000"],"gps":{"heading":153.1,"latitude":-37.509906666666666,"time":"2019-11-24T17:00:53.165266Z","speed":30.9,"longitude":175.525065,"mileage":111107},"id":"BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170053,165266+0000","type":"high","aid":"BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170043,043773+0000","timestamp":"2019-11-24T17:00:53.165266Z"}}',
      videoIds: [
        'BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170043,587037+0000',
        'BJqAyazDyBGCwRlkb1h1rQIomk4_20191124T170043,466242+0000'
      ],
      gps: {
        Lat: -37.50991,
        Lng: 175.52507,
        Spd: 30.9,
        Dir: 153.1,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'State Highway 27, Springdale',
      timeAt: '2019-11-24T17:00:53.165Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T04:46:31.271+11:00'
    }
  },
  {
    id: 37774076,
    filtered: false,
    eventId: 44435,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574626022952,
    event: {
      id: 44435,
      device: {
        id: 68467,
        name: '23600029',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600029'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'UajiVJzCXbxrFnG8KZxdoyEPTeX_20191124T200702,952881+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["UajiVJzCXbxrFnG8KZxdoyEPTeX_20191124T200652,928312+0000"],"gps":{"heading":162.5,"latitude":-46.29995,"time":"2019-11-24T20:07:02.952881Z","speed":26.6,"longitude":168.744205,"mileage":8794},"id":"UajiVJzCXbxrFnG8KZxdoyEPTeX_20191124T200702,952881+0000","type":"high","aid":"UajiVJzCXbxrFnG8KZxdoyEPTeX_20191124T200652,792228+0000","timestamp":"2019-11-24T20:07:02.952881Z"}}',
      videoIds: ['UajiVJzCXbxrFnG8KZxdoyEPTeX_20191124T200652,928312+0000'],
      gps: {
        Lat: -46.29995,
        Lng: 168.74421,
        Spd: 26.6,
        Dir: 162.5,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Downs Road South, Edendale',
      timeAt: '2019-11-24T20:07:02.952Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T07:09:54.977+11:00'
    }
  },
  {
    id: 37774068,
    filtered: false,
    eventId: 44435,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574626022952,
    event: {
      id: 44435,
      device: {
        id: 68467,
        name: '23600029',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600029'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'UajiVJzCXbxrFnG8KZxdoyEPTeX_20191124T200702,952881+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["UajiVJzCXbxrFnG8KZxdoyEPTeX_20191124T200652,928312+0000"],"gps":{"heading":162.5,"latitude":-46.29995,"time":"2019-11-24T20:07:02.952881Z","speed":26.6,"longitude":168.744205,"mileage":8794},"id":"UajiVJzCXbxrFnG8KZxdoyEPTeX_20191124T200702,952881+0000","type":"high","aid":"UajiVJzCXbxrFnG8KZxdoyEPTeX_20191124T200652,792228+0000","timestamp":"2019-11-24T20:07:02.952881Z"}}',
      videoIds: ['UajiVJzCXbxrFnG8KZxdoyEPTeX_20191124T200652,928312+0000'],
      gps: {
        Lat: -46.29995,
        Lng: 168.74421,
        Spd: 26.6,
        Dir: 162.5,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Downs Road South, Edendale',
      timeAt: '2019-11-24T20:07:02.952Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T07:09:54.977+11:00'
    }
  },
  {
    id: 37774077,
    filtered: false,
    eventId: 44436,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574626154301,
    event: {
      id: 44436,
      device: {
        id: 40329,
        name: '23600123',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600123'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200914,301449+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200904,524833+0000","NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200904,723886+0000"],"gps":{"heading":275.2,"latitude":-46.53836166666667,"time":"2019-11-24T20:09:14.301449Z","speed":10.3,"longitude":168.62548666666666,"mileage":18314},"id":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200914,301449+0000","type":"high","aid":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200904,207522+0000","timestamp":"2019-11-24T20:09:14.301449Z"}}',
      videoIds: [
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200904,524833+0000',
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200904,723886+0000'
      ],
      gps: {
        Lat: -46.53836,
        Lng: 168.62549,
        Spd: 10.3,
        Dir: 275.2,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Hanson Road, Kapuka South',
      timeAt: '2019-11-24T20:09:14.301Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T07:12:12.606+11:00'
    }
  },
  {
    id: 37774072,
    filtered: false,
    eventId: 44436,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574626154301,
    event: {
      id: 44436,
      device: {
        id: 40329,
        name: '23600123',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600123'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200914,301449+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200904,524833+0000","NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200904,723886+0000"],"gps":{"heading":275.2,"latitude":-46.53836166666667,"time":"2019-11-24T20:09:14.301449Z","speed":10.3,"longitude":168.62548666666666,"mileage":18314},"id":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200914,301449+0000","type":"high","aid":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200904,207522+0000","timestamp":"2019-11-24T20:09:14.301449Z"}}',
      videoIds: [
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200904,524833+0000',
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T200904,723886+0000'
      ],
      gps: {
        Lat: -46.53836,
        Lng: 168.62549,
        Spd: 10.3,
        Dir: 275.2,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Hanson Road, Kapuka South',
      timeAt: '2019-11-24T20:09:14.301Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T07:12:12.606+11:00'
    }
  },
  {
    id: 37774094,
    filtered: false,
    eventId: 44438,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574626526335,
    event: {
      id: 44438,
      device: {
        id: 40329,
        name: '23600123',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600123'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201526,335261+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201516,526649+0000","NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201516,485841+0000"],"gps":{"heading":9.1,"latitude":-46.53626166666667,"time":"2019-11-24T20:15:26.335261Z","speed":15.9,"longitude":168.62529166666667,"mileage":255},"id":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201526,335261+0000","type":"high","aid":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201516,335261+0000","timestamp":"2019-11-24T20:15:26.335261Z"}}',
      videoIds: [
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201516,526649+0000',
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201516,485841+0000'
      ],
      gps: {
        Lat: -46.53626,
        Lng: 168.62529,
        Spd: 15.9,
        Dir: 9.1,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Hanson Road, Kapuka South',
      timeAt: '2019-11-24T20:15:26.335Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T07:20:34.514+11:00'
    }
  },
  {
    id: 37774082,
    filtered: false,
    eventId: 44438,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574626526335,
    event: {
      id: 44438,
      device: {
        id: 40329,
        name: '23600123',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600123'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201526,335261+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201516,526649+0000","NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201516,485841+0000"],"gps":{"heading":9.1,"latitude":-46.53626166666667,"time":"2019-11-24T20:15:26.335261Z","speed":15.9,"longitude":168.62529166666667,"mileage":255},"id":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201526,335261+0000","type":"high","aid":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201516,335261+0000","timestamp":"2019-11-24T20:15:26.335261Z"}}',
      videoIds: [
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201516,526649+0000',
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T201516,485841+0000'
      ],
      gps: {
        Lat: -46.53626,
        Lng: 168.62529,
        Spd: 15.9,
        Dir: 9.1,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Hanson Road, Kapuka South',
      timeAt: '2019-11-24T20:15:26.335Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T07:20:34.514+11:00'
    }
  },
  {
    id: 37774159,
    filtered: false,
    eventId: 44442,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574628739520,
    event: {
      id: 44442,
      device: {
        id: 40520,
        name: '23600122',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600122'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205219,520789+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205207,401533+0000","6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205207,520631+0000"],"gps":{},"id":"6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205219,520789+0000","type":"high","aid":"6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205207,043533+0000","timestamp":"2019-11-24T20:52:19.520789Z"}}',
      videoIds: [
        '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205207,401533+0000',
        '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205207,520631+0000'
      ],
      gps: {},
      timeAt: '2019-11-24T20:52:19.520Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T08:10:12.986+11:00'
    }
  },
  {
    id: 37774150,
    filtered: false,
    eventId: 44442,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574628739520,
    event: {
      id: 44442,
      device: {
        id: 40520,
        name: '23600122',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600122'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205219,520789+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205207,401533+0000","6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205207,520631+0000"],"gps":{},"id":"6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205219,520789+0000","type":"high","aid":"6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205207,043533+0000","timestamp":"2019-11-24T20:52:19.520789Z"}}',
      videoIds: [
        '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205207,401533+0000',
        '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T205207,520631+0000'
      ],
      gps: {},
      timeAt: '2019-11-24T20:52:19.520Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T08:10:12.986+11:00'
    }
  },
  {
    id: 37774098,
    filtered: false,
    eventId: 44444,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574629968247,
    event: {
      id: 44444,
      device: {
        id: 40583,
        name: '23600340',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600340'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211248,247211+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211236,213497+0000","ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211236,092721+0000"],"gps":{"heading":178,"latitude":-45.811726666666665,"time":"2019-11-24T21:12:48.247211Z","speed":8.1,"longitude":168.41591666666667,"mileage":58212},"id":"ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211248,247211+0000","type":"high","aid":"ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211236,084033+0000","timestamp":"2019-11-24T21:12:48.247211Z"}}',
      videoIds: [
        'ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211236,213497+0000',
        'ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211236,092721+0000'
      ],
      gps: {
        Lat: -45.81173,
        Lng: 168.41592,
        Spd: 8.1,
        Dir: 178.0,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Cunningham Road, Josephville',
      timeAt: '2019-11-24T21:12:48.247Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T08:18:05.466+11:00'
    }
  },
  {
    id: 37774090,
    filtered: false,
    eventId: 44444,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574629968247,
    event: {
      id: 44444,
      device: {
        id: 40583,
        name: '23600340',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600340'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211248,247211+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211236,213497+0000","ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211236,092721+0000"],"gps":{"heading":178,"latitude":-45.811726666666665,"time":"2019-11-24T21:12:48.247211Z","speed":8.1,"longitude":168.41591666666667,"mileage":58212},"id":"ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211248,247211+0000","type":"high","aid":"ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211236,084033+0000","timestamp":"2019-11-24T21:12:48.247211Z"}}',
      videoIds: [
        'ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211236,213497+0000',
        'ASRswHWa9fuahlcSxVXRJTXghg5_20191124T211236,092721+0000'
      ],
      gps: {
        Lat: -45.81173,
        Lng: 168.41592,
        Spd: 8.1,
        Dir: 178.0,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Cunningham Road, Josephville',
      timeAt: '2019-11-24T21:12:48.247Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T08:18:05.466+11:00'
    }
  },
  {
    id: 37774091,
    filtered: false,
    eventId: 44443,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574630071077,
    event: {
      id: 44443,
      device: {
        id: 68484,
        name: '22404561',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '22404561'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211431,077103+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211421,284521+0000","9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211421,483547+0000"],"gps":{"heading":63,"latitude":-45.010578333333335,"time":"2019-11-24T21:14:31.077103Z","speed":4.6,"longitude":170.14772,"mileage":17957},"id":"9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211431,077103+0000","type":"high","aid":"9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211421,077103+0000","timestamp":"2019-11-24T21:14:31.077103Z"}}',
      videoIds: [
        '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211421,284521+0000',
        '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211421,483547+0000'
      ],
      gps: {
        Lat: -45.01058,
        Lng: 170.14772,
        Spd: 4.6,
        Dir: 63.0,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Broom Street, Naseby',
      timeAt: '2019-11-24T21:14:31.077Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T08:17:15.393+11:00'
    }
  },
  {
    id: 37774087,
    filtered: false,
    eventId: 44443,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574630071077,
    event: {
      id: 44443,
      device: {
        id: 68484,
        name: '22404561',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '22404561'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211431,077103+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211421,284521+0000","9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211421,483547+0000"],"gps":{"heading":63,"latitude":-45.010578333333335,"time":"2019-11-24T21:14:31.077103Z","speed":4.6,"longitude":170.14772,"mileage":17957},"id":"9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211431,077103+0000","type":"high","aid":"9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211421,077103+0000","timestamp":"2019-11-24T21:14:31.077103Z"}}',
      videoIds: [
        '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211421,284521+0000',
        '9C3kWOXIfQ0rVWvzl6s4cD2Nv71_20191124T211421,483547+0000'
      ],
      gps: {
        Lat: -45.01058,
        Lng: 170.14772,
        Spd: 4.6,
        Dir: 63.0,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Broom Street, Naseby',
      timeAt: '2019-11-24T21:14:31.077Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T08:17:15.393+11:00'
    }
  },
  {
    id: 37774095,
    filtered: false,
    eventId: 44445,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574630694809,
    event: {
      id: 44445,
      device: {
        id: 40329,
        name: '23600123',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600123'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212454,809511+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212444,991699+0000","NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212444,870952+0000"],"gps":{"heading":262.2,"latitude":-46.527831666666664,"time":"2019-11-24T21:24:54.809511Z","speed":28.1,"longitude":168.63404833333334,"mileage":3276},"id":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212454,809511+0000","type":"high","aid":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212444,809511+0000","timestamp":"2019-11-24T21:24:54.809511Z"}}',
      videoIds: [
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212444,991699+0000',
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212444,870952+0000'
      ],
      gps: {
        Lat: -46.52783,
        Lng: 168.63405,
        Spd: 28.1,
        Dir: 262.2,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Waituna Lagoon Road, Kapuka South',
      timeAt: '2019-11-24T21:24:54.809Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T08:26:19.400+11:00'
    }
  },
  {
    id: 37774092,
    filtered: false,
    eventId: 44445,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574630694809,
    event: {
      id: 44445,
      device: {
        id: 40329,
        name: '23600123',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600123'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: 'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212454,809511+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212444,991699+0000","NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212444,870952+0000"],"gps":{"heading":262.2,"latitude":-46.527831666666664,"time":"2019-11-24T21:24:54.809511Z","speed":28.1,"longitude":168.63404833333334,"mileage":3276},"id":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212454,809511+0000","type":"high","aid":"NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212444,809511+0000","timestamp":"2019-11-24T21:24:54.809511Z"}}',
      videoIds: [
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212444,991699+0000',
        'NHASTUgy3M0fG0QdWrqJok4hOLS_20191124T212444,870952+0000'
      ],
      gps: {
        Lat: -46.52783,
        Lng: 168.63405,
        Spd: 28.1,
        Dir: 262.2,
        NSat: 10.0,
        HDOP: 1.0
      },
      location: 'Waituna Lagoon Road, Kapuka South',
      timeAt: '2019-11-24T21:24:54.809Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T08:26:19.400+11:00'
    }
  },
  {
    id: 37774170,
    filtered: false,
    eventId: 44451,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574631587759,
    event: {
      id: 44451,
      device: {
        id: 40520,
        name: '23600122',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600122'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213947,759584+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213937,974683+0000","6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213938,013873+0000"],"gps":{},"id":"6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213947,759584+0000","type":"high","aid":"6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213937,675272+0000","timestamp":"2019-11-24T21:39:47.759584Z"}}',
      videoIds: [
        '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213937,974683+0000',
        '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213938,013873+0000'
      ],
      gps: {},
      timeAt: '2019-11-24T21:39:47.759Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T08:48:26.429+11:00'
    }
  },
  {
    id: 37774161,
    filtered: false,
    eventId: 44451,
    eventType: 'Ng::Event::Camera',
    eventAt: 1574631587759,
    event: {
      id: 44451,
      device: {
        id: 40520,
        name: '23600122',
        type: {
          code: 'CAMERA',
          name: 'Camera',
          features: ['BES', 'BEG']
        },
        model: {},
        imei: '23600122'
      },
      company: {
        id: 1,
        version: 6
      },
      status: 'ENABLED',
      uuid: '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213947,759584+0000',
      type: 'ACC_HIGH',
      provider: 'TRAJET',
      context:
        '{"event":{"vids":["6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213937,974683+0000","6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213938,013873+0000"],"gps":{},"id":"6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213947,759584+0000","type":"high","aid":"6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213937,675272+0000","timestamp":"2019-11-24T21:39:47.759584Z"}}',
      videoIds: [
        '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213937,974683+0000',
        '6R4RrOQBvo9Ls5m4dc06mxxs3Qz_20191124T213938,013873+0000'
      ],
      gps: {},
      timeAt: '2019-11-24T21:39:47.759Z',
      timeZone: '+00:00',
      createdAt: '2019-11-25T08:48:26.429+11:00'
    }
  },
  {
    id: 37774969,
    filtered: false,
    eventId: 337643188,
    eventType: 'Ng::Event::FatigueViolation',
    eventAt: 1574817008870,
    notificationType: 'violation',
    event: {
      id: 337643188,
      company: {
        id: 1,
        version: 6
      },
      user: {
        id: 678,
        firstName: 'Afzal',
        lastName: 'Zulfiqar Ali',
        mobile: ''
      },
      device: {
        id: 40336,
        name: 'Afzal Chiron Pro',
        type: {
          code: 'IFACE',
          name: 'iFace',
          features: ['CAB', 'ODO'],
          priority: 80
        },
        model: {},
        imei: '861727040020881'
      },
      groupId: '',
      type: 'FE-CV',
      status: 'E',
      timeBeforeViolation: '',
      ruleset: 'BFM3',
      rule: 'BFM3-2',
      timeAt: 1574816999861,
      ruleAt: 1574410598000,
      ruleEndAt: 1605946598000,
      description: 'Must not work more than 8½h in any 9h period',
      createdAt: '2019-11-22T19:16:40.584+11:00',
      updatedAt: '2019-11-27T12:10:08.285+11:00',
      location: 'Toorak Road, Camberwell, VIC',
      GPS: {
        Spd: -1.0,
        NSat: 0.0,
        HDOP: 99.0
      }
    }
  },
  {
    id: 37774971,
    filtered: false,
    eventId: 337643103,
    eventType: 'Ng::Event::FatigueViolation',
    eventAt: 1574817008893,
    notificationType: 'violation',
    event: {
      id: 337643103,
      company: {
        id: 1,
        version: 6
      },
      user: {
        id: 678,
        firstName: 'Afzal',
        lastName: 'Zulfiqar Ali',
        mobile: ''
      },
      device: {
        id: 40336,
        name: 'Afzal Chiron Pro',
        type: {
          code: 'IFACE',
          name: 'iFace',
          features: ['CAB', 'ODO'],
          priority: 80
        },
        model: {},
        imei: '861727040020881'
      },
      groupId: '',
      type: 'FE-CV',
      status: 'E',
      timeBeforeViolation: '',
      ruleset: 'BFM3',
      rule: 'BFM3-1',
      timeAt: 1574816999819,
      ruleAt: 1574401598000,
      ruleEndAt: 1605937598000,
      description: 'Must not work more than 6h in any 6¼h period',
      createdAt: '2019-11-22T16:46:40.473+11:00',
      updatedAt: '2019-11-27T12:10:07.792+11:00',
      location: 'Toorak Road, Camberwell, VIC',
      GPS: {
        Spd: -1.0,
        NSat: 0.0,
        HDOP: 99.0
      }
    }
  },
  {
    id: 37774977,
    filtered: false,
    eventId: 337643345,
    eventType: 'Ng::Event::FatigueViolation',
    eventAt: 1574817009009,
    notificationType: 'violation',
    event: {
      id: 337643345,
      company: {
        id: 1,
        version: 6
      },
      user: {
        id: 678,
        firstName: 'Afzal',
        lastName: 'Zulfiqar Ali',
        mobile: ''
      },
      device: {
        id: 40336,
        name: 'Afzal Chiron Pro',
        type: {
          code: 'IFACE',
          name: 'iFace',
          features: ['CAB', 'ODO'],
          priority: 80
        },
        model: {},
        imei: '861727040020881'
      },
      groupId: '',
      type: 'FE-CV',
      status: 'E',
      timeBeforeViolation: '',
      ruleset: 'BFM3',
      rule: 'BFM3-3',
      timeAt: 1574816999909,
      ruleAt: 1574419598000,
      ruleEndAt: 1605955598000,
      description: 'Must not work more than 11h in any 12h period',
      createdAt: '2019-11-22T21:46:42.829+11:00',
      updatedAt: '2019-11-27T12:10:08.508+11:00',
      location: 'Toorak Road, Camberwell, VIC',
      GPS: {
        Spd: -1.0,
        NSat: 0.0,
        HDOP: 99.0
      }
    }
  },
  {
    id: 37774978,
    filtered: false,
    eventId: 337655852,
    eventType: 'Ng::Event::FatigueViolation',
    eventAt: 1574817009012,
    notificationType: 'violation',
    event: {
      id: 337655852,
      company: {
        id: 1,
        version: 6
      },
      user: {
        id: 678,
        firstName: 'Afzal',
        lastName: 'Zulfiqar Ali',
        mobile: ''
      },
      device: {
        id: 40336,
        name: 'Afzal Chiron Pro',
        type: {
          code: 'IFACE',
          name: 'iFace',
          features: ['CAB', 'ODO'],
          priority: 80
        },
        model: {},
        imei: '861727040020881'
      },
      groupId: '',
      type: 'FE-CV',
      status: 'E',
      timeBeforeViolation: '',
      ruleset: 'BFM3',
      rule: 'BFM3-9',
      timeAt: 1574816999939,
      ruleAt: 1574621592000,
      ruleEndAt: 1606157592000,
      description: 'Must not work more than 84h following any 24+ hour break',
      createdAt: '2019-11-25T15:33:23.037+11:00',
      updatedAt: '2019-11-27T12:10:08.555+11:00',
      GPS: {
        Spd: -1.0,
        NSat: 0.0,
        HDOP: 99.0
      }
    }
  }
];

export const billboardData = () => [
  {
    id: 1,
    driverName: 'Harold Olsen',
    driverStatus: {
      dutyStatus: 'off duty',
      dutyColor: 'steel.500',
      hosTimeAllotted: 8,
      hosTimeUsed: 1.5,
      alerts: false
    },
    driverImage: pfpHaroldOlsen,
    vehicleName: 'KEN.100.423',
    vehicleStatus: 'steel.500',
    vehicleImage: imageVehicle1,
    isPinned: false
  },
  {
    id: 2,
    driverName: 'Jason Khan',
    driverStatus: {
      dutyStatus: 'on duty',
      dutyColor: 'sapphire.500',
      hosTimeAllotted: 8,
      hosTimeUsed: 6,
      alerts: false
    },
    driverImage: pfpJasonKhan,
    vehicleName: 'ACE.001.103',
    vehicleStatus: 'steel.500',
    vehicleImage: imageVehicle2,
    isPinned: false
  },
  {
    id: 3,
    driverName: 'Joelene Brown',
    driverStatus: {
      dutyStatus: 'on duty',
      dutyColor: 'sapphire.500',
      hosTimeAllotted: 8,
      hosTimeUsed: 3,
      alerts: true
    },
    driverImage: pfpJoeleneBrown,
    vehicleName: 'STR.020.100',
    vehicleStatus: 'emerald.500',
    vehicleImage: imageVehicle4,
    isPinned: false
  },
  {
    id: 4,
    driverName: 'Jordan Chu',
    driverStatus: {
      dutyStatus: 'Driving',
      dutyColor: 'emerald.500',
      hosTimeAllotted: 8,
      hosTimeUsed: 5,
      alerts: false
    },
    driverImage: pfpJordanChu,
    vehicleName: 'STR.020.200',
    vehicleStatus: 'emerald.500',
    vehicleImage: imageVehicle4,
    isPinned: true
  },
  {
    id: 5,
    driverName: 'Kyle Anderson',
    driverStatus: {
      dutyStatus: 'Driving',
      dutyColor: 'emerald.500',
      hosTimeAllotted: 8,
      hosTimeUsed: 2.3,
      alerts: true
    },
    driverImage: pfpKyleAnderson,
    vehicleName: 'DRK.900.500',
    vehicleStatus: 'tomato.500',
    vehicleImage: null,
    isPinned: true
  },
  {
    id: 6,
    driverName: 'Gabe Ramos',
    driverStatus: {
      dutyStatus: 'Off Duty',
      dutyColor: 'steel.500',
      hosTimeAllotted: 8,
      hosTimeUsed: 0.5,
      alerts: false
    },
    driverImage: null,
    vehicleName: 'EIC.204.426',
    vehicleStatus: 'tomato.500',
    vehicleImage: imageVehicle3,
    isPinned: true
  },
  {
    id: 7,
    driverName: 'Nick DeAngelo',
    driverStatus: {
      dutyStatus: 'Driving',
      dutyColor: 'emerald.500',
      hosTimeAllotted: 8,
      hosTimeUsed: 7.9,
      alerts: true
    },
    driverImage: null,
    vehicleName: 'STR.949.714',
    vehicleStatus: 'tomato.500',
    vehicleImage: imageVehicle4,
    isPinned: true
  },
  {
    id: 8,
    driverName: 'Rolan Fairweather',
    driverStatus: {
      dutyStatus: 'Off Duty',
      dutyColor: 'steel.500',
      hosTimeAllotted: 8,
      hosTimeUsed: 5.75,
      alerts: false
    },
    driverImage: null,
    vehicleName: 'KEN.200.765',
    vehicleStatus: 'tomato.500',
    vehicleImage: imageVehicle1,
    isPinned: false
  },
  {
    id: 9,
    driverName: 'Dylan Paige',
    driverStatus: {
      dutyStatus: 'Driving',
      dutyColor: 'emerald.500',
      hosTimeAllotted: 8,
      hosTimeUsed: 6,
      alerts: false
    },
    driverImage: null,
    vehicleName: 'ACE.002.103',
    vehicleStatus: 'tomato.500',
    vehicleImage: imageVehicle2,
    isPinned: false
  }
];
