export const ISODaysOfWeek = {
  7: {
    label: 'Weekdays.Sunday',
    shortLabel: 'Common.DaysOfTheWeek.Su',
    isoWeekday: 7
  },
  1: {
    label: 'Weekdays.Monday',
    shortLabel: 'Common.DaysOfTheWeek.Mo',
    isoWeekday: 1
  },
  2: {
    label: 'Weekdays.Tuesday',
    shortLabel: 'Common.DaysOfTheWeek.Tu',
    isoWeekday: 2
  },
  3: {
    label: 'Weekdays.Wednesday',
    shortLabel: 'Common.DaysOfTheWeek.We',
    isoWeekday: 3
  },
  4: {
    label: 'Weekdays.Thursday',
    shortLabel: 'Common.DaysOfTheWeek.Th',
    isoWeekday: 4
  },
  5: {
    label: 'Weekdays.Friday',
    shortLabel: 'Common.DaysOfTheWeek.Fr',
    isoWeekday: 5
  },
  6: {
    label: 'Weekdays.Saturday',
    shortLabel: 'Common.DaysOfTheWeek.Sa',
    isoWeekday: 6
  }
};

export const getDaysOfWeek = t => {
  const days = Object.values(ISODaysOfWeek).map(day => ({
    ...day,
    label: t(day.label),
    shortLabel: t(day.shortLabel)
  }));
  const sunday = days.pop();
  return [sunday, ...days];
};

const hourMinSplit = ':';

export const getFormatedHoursMins = hours =>
  `${hours > 9 ? (hours === 24 ? 23 : hours) : `0${hours}`}${hourMinSplit}${
    hours === 24 ? '59' : '00'
  }`;

export const getHoursMins = formatedHoursMins => {
  const result = formatedHoursMins.split(hourMinSplit);
  return {
    hours: parseInt(result[0], 10),
    mins: parseInt(result[1], 10)
  };
};

export const isEndOfDay = formatedTime => {
  const { hours: endHours, mins: endMins } = getHoursMins(formatedTime);
  return endHours === 23 && endMins === 59;
};

export const gethoursOfDay = () => {
  const ret = [];
  for (let hours = 0; hours < 24; hours++) {
    ret.push({
      label: `${hours > 9 ? hours : `0${hours}`}:00`,
      start: hours,
      end: hours + 1,
      time: hours
    });
  }
  return ret;
};
