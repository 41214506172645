export const FETCH_SCHEDULES = 'driverManagement/FETCH_SCHEDULES';
export const FETCH_DELETED_SCHEDULES = 'driverManagement/FETCH_DELETED_SCHEDULES';
export const FETCH_SCHEDULE_BY_ID = 'driverManagement/FETCH_SCHEDULE_BY_ID';
export const ADD_SCHEDULE = 'driverManagement/ADD_SCHEDULE';
export const UPDATE_SCHEDULE = 'driverManagement/UPDATE_SCHEDULE';
export const COMPLETE_SCHEDULE = 'driverManagement/COMPLETE_SCHEDULE';
export const DELETE_SCHEDULE = 'driverManagement/DELETE_SCHEDULE';
export const RESTORE_SCHEDULE = 'driverManagement/RESTORE_SCHEDULE';
export const CANCEL_SCHEDULE = 'driverManagement/CANCEL_SCHEDULE';

export const FETCH_TYPES = 'driverManagement/FETCH_TYPES';
export const FETCH_DELETEDTYPES = 'driverManagement/FETCH_DELETEDTYPES';
export const ADD_TYPE = 'driverManagement/ADD_TYPE';
export const UPDATE_TYPE = 'driverManagement/UPDATE_TYPE';
export const DELETE_TYPE = 'driverManagement/DELETE_TYPE';
export const RESTORE_TYPE = 'driverManagement/RESTORE_TYPE';
