import React from 'react';
import CanRoute from 'components/route/CanRoute';
import services from 'features/permissions/services';
import entities from 'features/permissions/entities';
import { CameraTab } from './CameraTab';

export function CameraRoute({ subscribeActions, actions, ...props }) {
  return (
    <CanRoute
      path={'/home/cameras'}
      everyService={[services.CAMERA]}
      oneOfEntities={[entities.VIDEO, entities.VIDEO_VIEW]}
      render={props => <CameraTab subscribeActions={subscribeActions} actions={actions} />}
    />
  );
}
