import React, { useCallback, useRef, useMemo } from 'react';

import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';

import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { ExportToExcelAudit } from './ExportToExcelAudit';
import { DateRangePicker } from 'components/ant/DateTime/DateRangePicker';

import styles from './DocumentView.module.scss';

const AuditTrailToolbar = ({
  document,
  filteredEventCount,
  auditTrail,
  searchText,
  eventsFilter,
  vehiclesFilter,
  devicesFilter,
  usersFilter,
  onSearchChange,
  onEventsFilterChange,
  onVehiclesFilterChange,
  onDevicesFilterChange,
  onUsersFilterChange,
  dateRanges,
  onDateRangeChange
}) => {
  const localization = useLocalization();
  const { t } = useTranslation();
  const timeoutHandler = useRef(-1);
  var defaultDates = useMemo(() => [dateRanges[0].startOf('day'), dateRanges[1].endOf('day')], []);

  const handleSearchChange = useCallback(value => {
    if (timeoutHandler.current !== -1) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = -1;
    }
    timeoutHandler.current = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(value);
      }
      timeoutHandler.current = -1;
    }, 100);
  }, []);

  return (
    <div className={styles.auditTrailToolbar}>
      <AntSearchbar defaultValue={searchText} onFilter={handleSearchChange} />
      <AntMultiselect
        title={
          eventsFilter?.some(value => !value.checked)
            ? t('Vehicles.Events')
            : t('Vehicles.AllEvents')
        }
        data={eventsFilter}
        onFilter={onEventsFilterChange}
      />
      <AntMultiselect
        title={
          vehiclesFilter?.some(value => !value.checked)
            ? t('Common.Vehicles')
            : t('Common.AllVehicles')
        }
        data={vehiclesFilter}
        onFilter={onVehiclesFilterChange}
      />
      <AntMultiselect
        title={
          devicesFilter?.some(value => !value.checked)
            ? t('Common.Devices')
            : t('Common.AllDevices')
        }
        data={devicesFilter}
        onFilter={onDevicesFilterChange}
      />
      <AntMultiselect
        title={
          usersFilter?.some(value => !value.checked) ? t('Common.Users') : t('Common.AllUsers')
        }
        data={usersFilter}
        onFilter={onUsersFilterChange}
      />
      <DateRangePicker
        disabled={false}
        value={dateRanges}
        maxDayRange={90}
        defaultDates={defaultDates}
        format={localization.formats.time.formats.dby.toUpperCase()}
        onDateRangeChanged={ranges => {
          if (onDateRangeChange) {
            onDateRangeChange(ranges);
          }
        }}
      />

      <div>
        <span className={styles.auditEventsCount}>
          {filteredEventCount + ' ' + t('Vehicles.Events')}
        </span>
        <ExportToExcelAudit
          data={auditTrail}
          document={document}
          localization={localization}
          size="large"
        />
      </div>
    </div>
  );
};

export default AuditTrailToolbar;
