import { useCallback, useMemo } from 'react';

import * as moment from 'moment';
import { orderBy } from 'lodash';
import { useSnapshotsByDeviceIds } from 'features/dashboard/dashboardSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';

import { useCompanySnapshotsEntities } from './useCompanySnapshotsEntities';
import { useSnapshotsFilter } from './useSnapshotsFilter';
import { getAvailablePositionedFootages, getPositionedFootages } from '../helpers';
import { useSnapshotEntityCameraStatus } from './useSnapshotEntityCameraStatus';

export const useDailySnapshots = ({ showNonCameraVehicle = false, entity, entityId }) => {
  const localization = useLocalization();

  const {
    isLoading: isDataFetching,
    fleets: allFleets,
    vehiclesAndDevices: allVehicles,
    companies: allCompanies,
    companyId,
    allDeviceIds,
    snapshotsEntity
  } = useCompanySnapshotsEntities({
    showNonCameraVehicle,
    entity,
    entityId
  });

  const {
    sortOption,
    filterText,
    selectedYear,
    selectedMonth,
    from,
    to,
    filterCompanies,
    filterFleets,
    orderField,
    orderDirection,
    rowOffset,
    setRowOffset,
    ...filterProps
  } = useSnapshotsFilter({ allCompanies, allFleets, allVehicles, companyId });

  const { isLoading: isLoadingSnapshots, data, allLoaded } = useSnapshotsByDeviceIds({
    companyId,
    deviceIds: allDeviceIds,
    rowOffset,
    from,
    to,
    orderField,
    orderDirection
  });

  const { availablePositions, isDeviceFailure } = useSnapshotEntityCameraStatus(snapshotsEntity);

  const { maxDailySnapshotsCount, snapshots, snapshotsCount } = useMemo(() => {
    //Filter
    const isFilteredDateTime = formatedDateTime => {
      if (!filterText || !filterText.trim()) {
        return true;
      }
      try {
        return formatedDateTime
          .replace(/\s/g, '')
          .match(new RegExp(filterText.replace(/\s/g, ''), 'gi'));
      } catch (error) {}
      return true;
    };
    const snapshotsByDates = {};
    for (const deviceSnapshots of Object.values(data || {})) {
      for (const deviceSnapshot of deviceSnapshots || []) {
        const dateTime = moment(deviceSnapshot.timeAt)
          .startOf('day')
          .valueOf();
        //Search By Date
        if (
          isFilteredDateTime(
            format(moment(Number(dateTime)).valueOf(), localization.formats.time.formats.dby)
          )
        ) {
          //Filter By Company/Fleet
          if (
            !(
              filterFleets.every(fleet => !fleet.checked) ||
              filterCompanies.every(comp => !comp.checked)
            )
          ) {
            snapshotsByDates[dateTime] = snapshotsByDates[dateTime] || [];
            snapshotsByDates[dateTime].push(deviceSnapshot);
          }
        }
      }
    }
    //Sort
    return orderBy(Object.keys(snapshotsByDates), [Number], [sortOption.order]).reduce(
      (a, snapshotDate) => {
        const positionedFootages = getAvailablePositionedFootages(
          getPositionedFootages(snapshotsByDates[snapshotDate]),
          availablePositions
        );
        return {
          maxDailySnapshotsCount: Math.max(
            a.maxDailySnapshotsCount,
            Object.values(positionedFootages || {}).length
          ),
          snapshotsCount:
            a.snapshotsCount + Object.values(positionedFootages || {}).filter(f => !!f).length,
          snapshots: [
            ...a.snapshots,
            {
              snapshotDateId: snapshotDate,
              positionedFootages: positionedFootages,
              driverId: snapshotsEntity?.driverId,
              isDeviceFailure
            }
          ]
        };
      },
      { maxDailySnapshotsCount: 0, snapshots: [], snapshotsCount: 0 }
    );
  }, [
    data,
    sortOption,
    filterText,
    localization,
    snapshotsEntity,
    filterCompanies,
    filterFleets,
    availablePositions,
    isDeviceFailure
  ]);

  const onLoadMore = useCallback(() => setRowOffset(row => row + 100), []);

  return {
    snapshotsEntity,
    isDataFetching,
    filters: {
      snapshotsCount,
      filterCompanies,
      filterFleets,
      filterText,
      selectedYear,
      selectedMonth,
      sortOption,
      ...filterProps
    },
    snapshots: {
      isLoading: isLoadingSnapshots,
      maxDailySnapshotsCount,
      snapshots,
      snapshotsCount,
      sortBy: sortOption,
      onLoadMore,
      allLoaded,
      snapshotYear: selectedYear,
      snapshotMonth: selectedMonth
    }
  };
};
