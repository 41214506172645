import React from 'react';
import { useDispatch } from 'react-redux';
import { Card, List, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useUserKey } from 'features/user/userSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { Can, entities, services } from 'features/permissions';

import { TILES, TILES_DIRECTION, TILES_GRID_SYSTEM, TYLE_LIST_STYLE } from './constants';
import { redirectToKonetik, setKonetikData } from './helpers';
import imgDefaultTile from './Images/mixed-energy.svg';

import styles from './MixedEnergy.module.scss';

const { Meta } = Card;

export const MixedEnergy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const currentCompany = useCurrentCompany();
  const { id: currentCompanyID } = currentCompany;

  const viewKonetikData = () => {
    redirectToKonetik(userKey, dispatch, t);
  };

  const updateKonetikData = () => {
    setKonetikData(userKey, currentCompanyID, dispatch, t);
  };

  return (
    <List
      style={TYLE_LIST_STYLE}
      grid={TILES_GRID_SYSTEM}
      itemLayout={TILES_DIRECTION}
      dataSource={TILES}
      renderItem={tile => (
        <List.Item key={tile.translationKey}>
          <Can
            everyService={[services.EVREADY]}
            oneOfEntities={[entities.KONETIK_VIEW, entities.KONETIK_UPDATE]}
          >
            <Card
              className={styles.dashboardCard}
              hoverable
              cover={<img alt={tile.title} src={tile.img || imgDefaultTile} />}
            >
              <Meta
                title={t(tile.translationKey)}
                description={
                  <div className={styles.dashboardCardDescriptionContainer}>
                    <div className={styles.dashboardCardActions}>
                      <Can everyEntity={[entities.KONETIK_UPDATE]}>
                        <Button onClick={updateKonetikData}>{t('MixedEnergy.Update')}</Button>
                      </Can>
                      <Can everyEntity={[entities.KONETIK_VIEW]}>
                        <Button type="primary" onClick={viewKonetikData}>
                          {t('MixedEnergy.ViewData')}
                        </Button>
                      </Can>
                    </div>
                  </div>
                }
              />
            </Card>
          </Can>
        </List.Item>
      )}
    ></List>
  );
};
