import { Row } from 'antd';
import { useLocalization } from 'features/localization/localizationSlice';
import P from 'prop-types';
import React from 'react';
import styles from './JobView.module.scss';
import { Table } from 'components/ant';
import { prepareJobEventsForTable } from './utils/helpers';
import { TABLE_JV_EVENTS_COLUMNS } from './utils/constans';
import {
  useDeletedDevices,
  useIsFetching as useIsFetchingDeletedDevices
} from 'features/devices/devicesDeletedSlice';
import { useDevices, useIsFetching as useIsFetchingDevices } from 'features/fleets/fleetsSlice';
import { useUsers, useIsFetching as useIsFetchingUsers } from 'features/users/usersSlice';
import { useTranslation } from 'react-i18next';

export const JobEvents = ({ jobEvents }) => {
  const localization = useLocalization();
  const devices = useDevices();
  const deletedDevices = useDeletedDevices();
  const users = useUsers();
  const isFetchingDeletedDevices = useIsFetchingDeletedDevices();
  const isFetchingDevices = useIsFetchingDevices();
  const isFetchingUsers = useIsFetchingUsers();
  const { t } = useTranslation();

  return (
    <Row className={styles.section}>
      {jobEvents?.length && (
        <Table
          columns={TABLE_JV_EVENTS_COLUMNS.map(column => ({
            ...column,
            title: t(`SmartJobs.ColumnList.Columns.${column.key}`)
          }))}
          dataSource={prepareJobEventsForTable(
            jobEvents,
            localization,
            devices,
            deletedDevices,
            users,
            t
          )}
          pagination={false}
          loading={isFetchingDevices || isFetchingDeletedDevices || isFetchingUsers}
        />
      )}
    </Row>
  );
};

JobEvents.propTypes = {
  jobEvents: P.array
};
