import { Comparators } from 'utils/sorting';
import {
  stateCellRenderer,
  driverCellRenderer,
  normalCellRenderer,
  driveTimeCellRenderer,
  restTimeCellRenderer,
  rdtCellRenderer,
  expandCellRenderer,
  stateCellRendererForAntTable
} from './CellRenders';
import styles from './fatigue.module.scss';
import { Table } from 'antd';

export const FatigueGridColumns = [
  {
    label: '',
    dataKey: '',
    width: 64,
    cellRenderer: stateCellRenderer
  },
  {
    label: 'Driver',
    dataKey: 'driver',
    cellRenderer: driverCellRenderer,
    defaultSortColumn: true,
    sortable: true,
    generalFilter: true
  },
  {
    label: 'Status',
    dataKey: 'status',
    width: 64,
    cellRenderer: normalCellRenderer,
    sortable: true,
    generalFilter: true
  },
  {
    label: 'Vehicle',
    dataKey: 'vehicleName',
    cellRenderer: normalCellRenderer,
    sortable: true,
    generalFilter: true
  },
  {
    label: 'Branch',
    dataKey: 'branch',
    cellRenderer: normalCellRenderer,
    sortable: true
  },
  {
    label: 'Current Location',
    dataKey: 'location',
    cellRenderer: normalCellRenderer,
    sortable: true,
    generalFilter: true
  },
  {
    label: 'Current Alert',
    dataKey: 'alert',
    cellRenderer: normalCellRenderer,
    sortable: true,
    generalFilter: true
  },
  {
    label: 'Last Update',
    dataKey: 'lastUpdate',
    cellRenderer: normalCellRenderer,
    sortable: true,
    generalFilter: true
  },
  {
    label: 'RDT',
    dataKey: 'remainedDriveTime',
    cellRenderer: rdtCellRenderer,
    sortable: true
  },
  {
    label: 'Next Break Due',
    dataKey: 'driveTime',
    cellRenderer: driveTimeCellRenderer,
    sortable: true
  },
  {
    label: 'Rest Time',
    dataKey: 'restTime',
    cellRenderer: restTimeCellRenderer,
    sortable: true
  },
  {
    label: '',
    dataKey: 'expand',
    cellRenderer: expandCellRenderer,
    sortable: true
  }
];

export const FatigueTableColumns = [
  {
    title: '',
    dataIndex: 'state',
    width: 64,
    render: (text, record, index) => stateCellRendererForAntTable({ rowData: record }),
    className: styles.statusColumn
  },
  {
    title: 'Driver',
    dataIndex: 'driver',
    render: (text, record, index) => driverCellRenderer({ rowData: record }),
    defaultSortColumn: true,
    sorter: Comparators.String('driver'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 96,
    //render: (text, record, index) => normalCellRenderer({rowData: record, dataKey: 'status'}),
    sorter: Comparators.String('status'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true
  },
  {
    title: 'Vehicle',
    dataIndex: 'vehicleName',
    //render: (text, record, index) => normalCellRenderer({rowData: record, dataKey: 'vehicleName'}),
    sorter: Comparators.String('vehicleName'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true
  },
  {
    title: 'Branch',
    dataIndex: 'branch',
    //render: normalCellRenderer,
    sorter: Comparators.String('branch'),
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Current Location',
    dataIndex: 'location',
    //render: normalCellRenderer,
    sorter: Comparators.String('location'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true
  },
  {
    title: 'Current Alert',
    dataIndex: 'alert',
    //render: normalCellRenderer,
    sorter: Comparators.String('alert'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true
  },
  {
    title: 'Last Update',
    dataIndex: 'lastUpdate',
    //render: normalCellRenderer,
    sorter: Comparators.Number('lastActivity'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true
  },
  {
    title: 'RDT',
    dataIndex: 'remainedDriveTime',
    render: (text, record, index) => rdtCellRenderer({ rowData: record }),
    sorter: Comparators.Number(rowData => {
      const maxDriveTime = rowData.fatigueStatus?.max_daily_drive || 1;
      const remainingDrive = maxDriveTime - (rowData.fatigueStatus?.drive_daily || 0);
      return remainingDrive;
    }),
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Next Break Due',
    dataIndex: 'driveTime',
    render: (text, record, index) => driveTimeCellRenderer({ rowData: record }),
    sorter: Comparators.Number(rowData => {
      const mode = rowData.fatigueStatus?.mode;
      if (mode == null) return 0;
      switch (mode) {
        case 0:
        case 3:
          return rowData.fatigueStatus?.drive_continuous || 0;
        case 1:
        case 2:
          return rowData.fatigueStatus?.drive_daily || 0;
        default:
          return 0;
      }
    }),
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  {
    title: 'Rest Time',
    dataIndex: 'restTime',
    render: (text, record, index) => restTimeCellRenderer({ rowData: record }),
    sorter: (a, b) => {
      const modeA = a.fatigueStatus?.mode || 0;
      const modeB = b.fatigueStatus?.mode || 0;
      if (modeA === modeB) {
        const breakRestTakenA = a.fatigueStatus?.break_rest_taken || 0;
        const breakRestTakenB = b.fatigueStatus?.break_rest_taken || 0;
        if (breakRestTakenA === breakRestTakenB) {
          const restBreakA = a.fatigueStatus?.next_rest_break_required_in || 0;
          const restBreakB = a.fatigueStatus?.next_rest_break_required_in || 0;
          return restBreakA - restBreakB;
        } else {
          return breakRestTakenA - breakRestTakenB;
        }
      } else {
        return modeA - modeB;
      }
    },
    sortDirections: ['ascend', 'descend', 'ascend']
  },
  Table.EXPAND_COLUMN
];
