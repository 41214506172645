import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { pageStyle } from './constants';

import MapToPrint from './MapToPrint';

const JourneyPlannerExportView = () => {
  const { t } = useTranslation();
  const componentRef = React.useRef(null);
  const [journeyName, setJourneyName] = useState(null);

  const setMapLoaded = () => handlePrint();
  const setName = name => setJourneyName(name);
  const handleCloseTab = () => window.close();

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${t('JourneyPlanner.Title')} - ${journeyName || ''}`,
    onAfterPrint: handleCloseTab,
    pageStyle
  });

  return (
    <>
      <MapToPrint ref={componentRef} setMapLoaded={setMapLoaded} setName={setName} />
    </>
  );
};

export default JourneyPlannerExportView;
