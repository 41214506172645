import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { OverlayTrigger, Popover, ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Can, services } from 'features/permissions';
import { VehicleFootageRequestMenuButtons } from 'features/requestFootage/RequestFootageButton';
import { useCurrentCompany } from 'features/company/companySlice';
import { ForceLogoffMenuButton } from 'features/users/forceLogoff/forceLogoffMenuButton';

import { ViewDriverLink } from 'components/sentinel/ViewDriverLink';

import styles from './controlpanel.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const ElipsisButton = ({
  deviceInfo,
  onViewVehicleDevice,
  onMessageVehicle,
  onRouteTo,
  onRequestVideo
}) => {
  const { t } = useTranslation();
  const company = useCurrentCompany();
  const [isSmartNav3D, setIsSmartNav3D] = useState(false);

  useEffect(() => {
    setIsSmartNav3D(company.features?.some(feature => feature.code === services.SMARTNAV3D));
  }, [company]);

  const onViewVehicleDeviceClick = useCallback(
    evt => {
      evt.stopPropagation();
      if (onViewVehicleDevice) {
        onViewVehicleDevice(deviceInfo);
      }
    },
    [deviceInfo]
  );

  const onMessageVehicleClick = useCallback(
    evt => {
      evt.stopPropagation();
      if (onMessageVehicle) {
        onMessageVehicle(deviceInfo);
      }
    },
    [deviceInfo]
  );

  const onRouteToClick = useCallback(
    evt => {
      evt.stopPropagation();
      onRouteTo(deviceInfo);
    },
    [deviceInfo]
  );

  const onRequestFootageClick = useCallback(
    (vehicleId, deviceId) => {
      if (onRequestVideo) {
        onRequestVideo(vehicleId, deviceId);
      }
    },
    [onRequestVideo]
  );

  const ContextMenu = useMemo(
    () => (
      <Popover className={styles.contextMenu} id={`${deviceInfo.id}_grid-popover`}>
        <Popover.Content className={styles.contextMenuContent}>
          <ListGroup variant="flush">
            <ListGroup.Item action id="btn_proximityViewVehDev" onClick={onViewVehicleDeviceClick}>
              {deviceInfo.vehicleId ? t('Home.View Vehicle') : t('Home.View Device')}
            </ListGroup.Item>
            {deviceInfo.messagingDevice && (
              <Can oneOfServices={[services.MESSAGING]}>
                <ListGroup.Item
                  id="btn_proximityMessageVehDev"
                  action
                  onClick={onMessageVehicleClick}
                >
                  {deviceInfo.vehicleId
                    ? t('Tracking.MessageVehicle')
                    : t('Tracking.MessageDevice')}
                </ListGroup.Item>
                {isSmartNav3D && deviceInfo.routeToDevice && (
                  <ListGroup.Item id="btn_proximityRouteTo" action onClick={onRouteToClick}>
                    {t('Tracking.RouteTo')}
                  </ListGroup.Item>
                )}
              </Can>
            )}
            {deviceInfo.driverId && (
              <ViewDriverLink driverId={deviceInfo.driverId}>
                {({ onClick }) => (
                  <ListGroup.Item action id="btn_proximityViewDriver" onClick={onClick}>
                    {t('Tracking.ViewDriver')}
                  </ListGroup.Item>
                )}
              </ViewDriverLink>
            )}
            <VehicleFootageRequestMenuButtons
              vehicleId={deviceInfo.vehicleId}
              onMenuClicked={onRequestFootageClick}
            />
            <ForceLogoffMenuButton
              vehicleId={deviceInfo.vehicleId}
              deviceId={!deviceInfo.vehicleId && deviceInfo.deviceId}
              driverId={deviceInfo.driverId}
            />
          </ListGroup>
        </Popover.Content>
      </Popover>
    ),
    [deviceInfo, isSmartNav3D, onRequestFootageClick]
  );

  return (
    <OverlayTrigger trigger="click" placement="left" rootClose={true} overlay={ContextMenu}>
      <button
        style={{ border: 'none' }}
        className={'tn-btn-elipsis'}
        id={BUTTON_IDS.controlPanelEllipsis}
      >
        <i style={{ fontSize: '26px' }} className={'tn-i-elipsis'} />
      </button>
    </OverlayTrigger>
  );
};
