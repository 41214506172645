import React from 'react';
import P from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Modal, Button } from 'antd';
import { ColumnLists } from './ColumnLists';

//styles
import styles from './ManageColumns.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const ManageColumnsModal = ({
  title,
  show,
  onSave,
  onHide,
  hiddenColumns,
  setHiddenColumns,
  visibleColumns,
  setVisibleColumns,
  resetColumns
}) => {
  const { t } = useTranslation();

  const handleConfirmClose = () => {
    if (!onHide) {
      return;
    }

    onHide();
  };

  const renderFooterModal = () => {
    return (
      <div className={styles.manageColumnsFooter}>
        <Button type="primary" onClick={onSave} id={BUTTON_IDS.manageColumnsModalSave}>
          {t('Common.Save')}
        </Button>
        <Button type="secondary" onClick={resetColumns} id={BUTTON_IDS.manageColumnsModalReset}>
          {t(`Common.Reset`)}
        </Button>
        <Button
          type="secondary"
          onClick={handleConfirmClose}
          id={BUTTON_IDS.manageColumnsModalCancel}
        >
          {t(`Common.Cancel`)}
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        title={title}
        open={show}
        width={917}
        onCancel={handleConfirmClose}
        className={`${styles.manageColumnsModal} manage-columns-modal`}
        footer={null}
      >
        <div className={styles.infoContainer}>{t('SmartJobs.ManageColumnsDescription')}</div>
        <ColumnLists
          hiddenColumns={hiddenColumns}
          setHiddenColumns={setHiddenColumns}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          resetColumns={resetColumns}
        />
        {renderFooterModal()}
      </Modal>
    </>
  );
};

ManageColumnsModal.propTypes = {
  title: P.string,
  show: P.bool,
  onSave: P.func,
  onHide: P.func,
  hiddenColumns: P.arrayOf(
    P.shape({
      key: P.string,
      label: P.string
    })
  ),
  setHiddenColumns: P.func,
  visibleColumns: P.arrayOf(
    P.shape({
      key: P.string,
      label: P.string
    })
  ),
  setVisibleColumns: P.func,
  resetColumns: P.func
};
