import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Can, entities } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import VehicleTypes from './VehicleTypes';
import { VehicleTypesView } from './VehicleTypesView';
import VehicleTypesForm from './VehicleTypesForm';
import { Paths } from './constants';

export const VehicleTypesRouter = () => {
  return (
    <Can everyEntity={[entities.VEHICLETYPE]} onFail={<NoAccessBody />}>
      <Switch>
        <Route path={Paths.VIEW_BY_ID}>
          <Can everyEntity={[entities.VEHICLETYPE_VIEW]} onFail={<NoAccessBody />}>
            <VehicleTypesView />
          </Can>
        </Route>
        <Route path={Paths.ADD}>
          <Can everyEntity={[entities.VEHICLETYPE_CREATE]} onFail={<NoAccessBody />}>
            <VehicleTypesForm action="add" />
          </Can>
        </Route>
        <Route path={Paths.EDIT_BY_ID}>
          <Can everyEntity={[entities.VEHICLETYPE_UPDATE]} onFail={<NoAccessBody />}>
            <VehicleTypesForm action="edit" />
          </Can>
        </Route>
        <Route path={Paths.COPY_BY_ID}>
          <Can everyEntity={[entities.VEHICLETYPE_COPY]} onFail={<NoAccessBody />}>
            <VehicleTypesForm action="copy" />
          </Can>
        </Route>
        <Route path={Paths.DEFAULT}>
          <Can everyEntity={[entities.VEHICLETYPE]} onFail={<NoAccessBody />}>
            <VehicleTypes />
          </Can>
        </Route>
      </Switch>
    </Can>
  );
};
