import moment from 'moment';
const RequestStatus = {
  Pending: 0,
  Processing: 1,
  Done: 2,
  Cancel: 3
};

export class RequestQueue {
  static #requestPromiseQueue = {};
  static #requestWorker = null;

  static startWorker() {
    const script = `
            const requests = [];
            const requestsPool = {};
            const requestLimit = 10;
            let idleTimes = 0;
            let timeoutHandle = null;

            self.onmessage = function(msg) {
                if (msg != null) {
                    if (msg.data.status === ${RequestStatus.Pending}) {
                        requests.push(msg.data);
                    } else if (msg.data.status === ${RequestStatus.Done}) {
                        delete requestsPool[msg.data.id];
                    } else if (msg.data.status === ${RequestStatus.Cancel}) {
                        const idxRequest = requests.findIndex(r => r.id === msg.data.id);
                        if (idxRequest >= 0) {
                            requests.splice(idxRequest, 1);
                        }
                        
                        delete requestsPool[msg.data.id];
                    }
                }

                if (timeoutHandle == null) {
                    timeoutHandle = setTimeout(handleProcess, 25);
                }
            }

            function handleProcess() {
                const procReqLength = Object.keys(requestsPool).length;
                if (procReqLength >= requestLimit) {
                    timeoutHandle = setTimeout(handleProcess, 25);
                    return;
                } else if (requests.length <= 0) {
                    if (++idleTimes >= 3) {
                        clearTimeout(timeoutHandle);
                        timeoutHandle = null;
                        idleTimes = 0;
                        return;
                    }
                }

                for (let i = requestLimit - procReqLength; i > 0 && requests.length > 0; i--) {
                    const request = requests.shift();
                    requestsPool[request.id] = request;
                    request.status = ${RequestStatus.Processing};
                    postMessage({id: request.id, status: ${RequestStatus.Processing}});
                }

                timeoutHandle = setTimeout(handleProcess, 25);
            }
        `;
    const blobObj = new Blob([script]);
    const urlObj = URL.createObjectURL(blobObj);
    this.#requestWorker = new Worker(urlObj);
    this.#requestWorker.onmessage = RequestQueue.handleMessage;
  }

  static async handleMessage(evt) {
    const request = RequestQueue.#requestPromiseQueue[evt?.data?.id];
    if (request != null) {
      if (evt?.data?.status === RequestStatus.Processing) {
        request.status = RequestStatus.Processing;
        if (request.requestAction) {
          try {
            const response = request.requestAction();
            let data = null;
            if (response instanceof Promise) {
              data = await response;
            } else {
              data = response;
            }

            if (request.onSuccess) {
              request.onSuccess(data);
            }
          } catch (ex) {
            if (request.onFail) {
              request.onFail(ex);
            }
          }
        }
      }
      RequestQueue.#requestWorker.postMessage({
        id: request.id,
        status: RequestStatus.Done
      });

      delete RequestQueue.#requestPromiseQueue[evt?.data?.id];
    }
  }

  static queueRequest(requestAction, onSuccess, onFail, onCancel) {
    if (this.#requestWorker == null) {
      this.startWorker();
    }
    const id = moment().valueOf() + '_' + Math.floor(Math.random() * 100);
    this.#requestPromiseQueue[id] = {
      id: id,
      status: RequestStatus.Pending,
      requestAction,
      onSuccess,
      onFail,
      onCancel
    };
    this.#requestWorker.postMessage({
      id: id,
      status: RequestStatus.Pending
    });
    return id;
  }

  static removeRequest(id) {
    const request = this.#requestPromiseQueue[id];
    if (request?.onCancel != null) {
      try {
        request.onCancel();
      } catch (e) {
        console.debug(e);
      }
    }
    this.#requestWorker.postMessage({
      id: id,
      status: RequestStatus.Cancel
    });
    delete this.#requestPromiseQueue[id];
  }

  static clearAllRequests() {
    const allHandles = Object.keys(this.#requestPromiseQueue);
    allHandles.forEach(handle => this.removeRequest(handle));
  }
}
