import i18n from 'i18next';
import moment from 'moment';

import { escapeXml } from './exportGPX';
import { getEventAttributesByType } from 'containers/Tracking/EventTypes';

export const prepareDataForKMLExport = trips => {
  // Header
  let result =
    '<?xml version="1.0" encoding="UTF-8"?>\n<kml xmlns="http://www.opengis.net/kml/2.2">\n  <Document>\n';

  // Styles
  result +=
    '    <Style id="pathline">\n      <LineStyle>\n        <color>ffde6ff7</color>\n        <width>6</width>\n      </LineStyle>\n    </Style>\n';
  result +=
    '    <Style id="activity">\n      <IconStyle>\n        <color>ff00ffff</color>\n      </IconStyle>\n    </Style>\n';

  if (trips) {
    trips.forEach(trip => {
      if (trip.events && trip.events.length) {
        // Events as Placemarks with a Point
        trip.events.forEach(event => {
          const eventAttributes = getEventAttributesByType(event?.eventType, event?.subType);
          const eventType = i18n.t(`Tracking.Events.${eventAttributes?.label}`);
          const gps = event.GPS;
          const timeAtUtc = moment(event.timeAt).utc();
          const timeUtcFormatted = timeAtUtc.format('YYYY-MM-DD HH:mm:ss');

          // Not sure if we need this, but to be safe replace any characters in address that might break the xml file formatting
          const location = event.location ? escapeXml(event.location) : '';

          let placemark = '    <Placemark>\n';
          placemark += `      <name>Event: ${eventType}</name>\n`;
          placemark += `      <description>Latitude: ${gps?.Lat}&lt;br&gt;Longitude: ${gps?.Lng}&lt;br&gt;Location: ${location}&lt;br&gt;Date &amp; Time (UTC): ${timeUtcFormatted}</description>\n`;
          placemark += '      <styleUrl>#activity</styleUrl>\n';
          placemark += '      <Point>\n';

          // Use default altitudeMode "clampToGround" so that data always renders perfectly on the ground elevation.
          // Can be changed to "absolute" mode if we want to use the true GPS Altitude data.
          // placemark += '        <altitudeMode>clampToGround</altitudeMode>';

          placemark += `        <coordinates>${gps?.Lng},${gps?.Lat},${gps?.Alt ||
            0}</coordinates>\n`;
          placemark += '      </Point>\n';
          placemark += '    </Placemark>\n';

          result += placemark;
        });
      }

      // Trip replay as Placemark with LineString
      if (trip.replay && trip.replay.length) {
        let name = '';
        if (trip.id && trip.ignitionOn) {
          const start = moment(trip.ignitionOn)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
          name = 'Trip: ' + start;
          if (trip.ignitionOff) {
            const end = moment(trip.ignitionOff)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss');
            name += ' - ' + end;
          }
        }

        result += '    <Placemark>\n';
        result += `      <name>${name}</name>\n`;
        result += '      <styleUrl>#pathline</styleUrl>\n';
        result += '      <LineString>\n';

        // Use default altitudeMode "clampToGround" so that data always renders perfectly on the ground elevation.
        // Can be changed to "absolute" mode if we want to use the true GPS Altitude data.
        // result += '        <altitudeMode>clampToGround</altitudeMode>';

        result += '        <coordinates>\n';

        trip.replay.forEach(point => {
          result += `          ${point?.Lng},${point?.Lat},${point?.Alt || 0}\n`;
        });

        result += '        </coordinates>\n';
        result += '      </LineString>\n';
        result += '    </Placemark>\n';
      }
    });
  }

  result += '  </Document>\n</kml>';

  return result;
};
