import { memo, useCallback } from 'react';
import { useDrop } from 'react-dnd';
import update from 'immutability-helper';
import { cloneDeep, debounce } from 'lodash';
import { DraggableItem } from './DraggableItem.js';
import { DropBoxType } from './DropBoxType.js';

import styles from './ManageColumns.module.scss';

export const DropBox = memo(function Container({ type, items, setItems, findItem }) {
  const [{ handlerId, isOver }, drop] = useDrop(
    () => ({
      accept: Object.values(DropBoxType).filter(t => t !== type),
      drop(_item, monitor) {
        const dragType = monitor.getItem().type,
          hoverType = type;
        const { item: dragItem, index: dragIndex } = findItem(
          monitor.getItem().type,
          monitor.getItem().id
        );
        const sourceItem = cloneDeep(dragItem);
        setItems(prev =>
          update(prev, {
            $apply: collection => {
              const sourceItems = cloneDeep(collection[dragType]);
              const targetItems = cloneDeep(collection[hoverType]);
              sourceItems.splice(dragIndex, 1);
              if (!targetItems.length) {
                targetItems.push(sourceItem);
                return {
                  [dragType]: sourceItems.filter(item => !!item),
                  [hoverType]: targetItems.filter(item => !!item)
                };
              }
              let lastDraggableIndex = targetItems.length - 1;
              for (let index = targetItems.length - 1; index > 0; index--) {
                const targetItem = targetItems[index];
                if (targetItem.canDrag) {
                  lastDraggableIndex = index;
                  break;
                }
              }
              if (targetItems[lastDraggableIndex].canDrag) {
                targetItems.splice(lastDraggableIndex + 1, 0, sourceItem);
              } else {
                targetItems.splice(lastDraggableIndex, 0, sourceItem);
              }
              return {
                [dragType]: sourceItems.filter(item => !!item),
                [hoverType]: targetItems.filter(item => !!item)
              };
            }
          })
        );
        return undefined;
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        handlerId: monitor.getHandlerId()
      })
    }),
    [items, findItem, type]
  );

  const moveItem = useCallback(
    debounce(({ dragId, dragType, hoverId, hoverType }) => {
      const { item: dragItem, index: dragIndex } = findItem(dragType, dragId);
      const { item: hoverItem, index: hoverIndex } = findItem(hoverType, hoverId);
      const sourceItem = cloneDeep(dragItem);
      const targetItem = cloneDeep(hoverItem);
      if (!targetItem?.canDrag) {
        return;
      }
      setItems(prev =>
        update(prev, {
          [dragType]: {
            $apply: dragItems => {
              const sourceItems = cloneDeep(dragItems);
              sourceItems.splice(dragIndex, 1);
              sourceItems.splice(hoverIndex, 0, sourceItem);
              return sourceItems.filter(item => !!item);
            }
          }
        })
      );
    }, 50),
    [findItem]
  );

  return (
    <div
      ref={drop}
      data-handler-id={handlerId}
      className={isOver ? styles.activeDropBox : styles.dropBox}
    >
      {items
        ?.filter(item => item?.id)
        ?.map((item, i) => (
          <DraggableItem
            key={`${type}-${item.id}-${i}`}
            type={type}
            index={i}
            id={item.id}
            title={item.title}
            moveItem={moveItem}
            canDrag={item.canDrag}
          />
        ))}
    </div>
  );
});
