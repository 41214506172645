import React, { useState, useMemo } from 'react';
import { Button } from 'antd';
import { RequestFootageModal } from './RequestFootageModal';
import { Can, entities, services } from 'features/permissions';
import { useDevices } from 'features/devices/devicesSlice';
import { ListGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BUTTON_IDS } from 'utils/globalConstants';
import { sortBy } from 'lodash';

export function RequestFootageButton({ deviceId, vehicleId, disabled = false }) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  return (
    <Can everyService={[services.CAMERA]} everyEntity={[entities.VIDEO, entities.VIDEO_CREATE]}>
      <Button
        size="large"
        onClick={() => setShowModal(true)}
        id={BUTTON_IDS.requestFootage}
        disabled={disabled}
      >
        {t('Home.Request Footage')}
      </Button>
      {showModal && (
        <RequestFootageModal
          deviceId={deviceId}
          vehicleId={vehicleId}
          showModal={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </Can>
  );
}

export function VehicleFootageRequestMenuButtons({
  deviceId,
  vehicleId,
  onMenuClicked,
  menuType,
  propCameraDevices
}) {
  const devices = useDevices();
  const { t } = useTranslation();

  const cameraDevices = useMemo(() => {
    const cameraDevices = new Map();

    if (deviceId && devices) {
      if (devices[deviceId]?.type.name === 'Camera') {
        cameraDevices.set(deviceId, devices[deviceId]);
      }
    }

    if (devices && vehicleId) {
      Object.entries(devices).forEach(([devicId, device]) => {
        if (device.type?.name === 'Camera' && device.vehicle?.id === vehicleId) {
          cameraDevices.set(devicId, device);
        }
      });
    }

    return Array.from(cameraDevices.values());
  }, [deviceId, vehicleId, devices]);

  const sortedCameraDevices = useMemo(() =>
    sortBy(!!propCameraDevices?.length ? propCameraDevices : cameraDevices, ['name'])
  );

  return (
    <>
      {sortedCameraDevices.map((c, idx) =>
        menuType === 'ant' ? (
          <span
            key={`request-footage-btn-${idx}`}
            eventKey={`request-footage-btn-${idx}`}
            onClick={evt => {
              if (onMenuClicked) onMenuClicked(vehicleId, c.id);
            }}
          >
            {t('Home.Request Video')} - {c.name}
          </span>
        ) : (
          <ListGroup.Item
            key={idx}
            action
            onClick={evt => {
              evt.stopPropagation();
              if (onMenuClicked) onMenuClicked(vehicleId, c.id);
            }}
          >
            {t('Home.Request Video')} - {c.name}
          </ListGroup.Item>
        )
      )}
    </>
  );
}
