import React from 'react';
import { useTranslation } from 'react-i18next';

//Components
import { Checkbox, Tooltip } from 'antd';
//styles
import styles from './EntityPermissions.module.scss';

const EntityPermissions = ({
  permissions,
  permissionsMetadata,
  index,
  handleClickEntity,
  handleClickAllEntity,
  entityName,
  viewPage
}) => {
  const permissionNamesAndTags = permissionsMetadata.map(per => {
    return {
      name: per.action,
      tag: per.tag,
      description: per.description,
      isEnabled: per.isEnabled
    };
  });
  const { t } = useTranslation();

  const disableCheckAll = !viewPage && !!permissionNamesAndTags.find(per => !per.isEnabled);

  return (
    <div className={styles.entityPermissions} style={{ top: `${(index + 1) * 60 - 20}px` }}>
      <div className={styles.entityPermissions__item}>
        <Tooltip title={disableCheckAll ? t('Roles.EntityTable.PermissionNotAvailable') : ''}>
          <Checkbox
            disabled={viewPage || disableCheckAll}
            checked={permissions.length === permissionNamesAndTags.length}
            onChange={handleClickAllEntity}
            entityName={entityName}
          />
        </Tooltip>
        <span className={disableCheckAll ? styles.entityPermissions__nameDisabled : ''}>
          {t('Permission.AccessToEverything')}
        </span>
      </div>
      {permissionNamesAndTags.map(permission => {
        const disablePermission = !viewPage && !permission.isEnabled;
        return (
          <div className={styles.entityPermissions__item} key={permission.name}>
            <div className={styles.entityPermission__name}>
              <Tooltip
                title={disablePermission ? t('Roles.EntityTable.PermissionNotAvailable') : ''}
              >
                <Checkbox
                  disabled={viewPage || !permission.isEnabled}
                  checked={permissions.includes(permission.name)}
                  onChange={handleClickEntity}
                  permissionName={permission.name}
                  entityName={entityName}
                />
              </Tooltip>
              <span className={disablePermission ? styles.entityPermissions__nameDisabled : ''}>
                {t(
                  `Permission.${permission?.name?.replace('Destroy', 'Delete')}`,
                  permission?.name || ''
                )}
              </span>
            </div>
            <div>
              <span className={disablePermission ? styles.entityPermissions__nameDisabled : ''}>
                {t(
                  `Roles.EntityDescriptionValueWithKeyFromTagField.${permission?.tag}`,
                  permission?.description || ''
                )}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EntityPermissions;
