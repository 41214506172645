import mixpanel from 'mixpanel-browser';
import { MIXPANEL_PROXY_URL, MIXPANEL_TOKEN, MIXPANEL_ACTIVE } from 'config';
import { MPTrackingEvents } from './mixpanelTrackEventNames';

export const MPTrackingFunctions = {
  userIdentify: userId => {
    mixpanel.identify(`userId_${userId}`);
  },
  track: (eventName, eventProps, ...args) => {
    mixpanel.track(eventName, eventProps, ...args);
  },
  time_event: eventName => {
    mixpanel.time_event(eventName);
  },
  addUserToCompany: companyId => {
    mixpanel.add_group('companyId', `company_id_${companyId}`); //add new company if not exist
    mixpanel.set_group('companyId', `company_id_${companyId}`); //set user belongs to this company
  },
  updateCompanyProfile: (companyId, companyProfile, updateOnce) => {
    if (mixpanel.get_group('companyId', `company_id_${companyId}`)) {
      if (updateOnce) {
        mixpanel.get_group('companyId', `company_id_${companyId}`).set_once(companyProfile || {});
      } else {
        mixpanel.get_group('companyId', `company_id_${companyId}`).set(companyProfile || {});
      }
    }
  },
  updateUserProfile: (userId, userProfile, setOnce) => {
    if (mixpanel.people) {
      if (setOnce) {
        mixpanel.people.set_once(userProfile);
      } else {
        mixpanel.people.set(userProfile);
      }
      mixpanel.identify(`userId_${userId}`);
    }
  },
  registerSystemLocation: () => {
    mixpanel.register({ system_location: 'TN360WEB' });
  },
  registerSuperProperty: (superProps, registerOnce) => {
    if (registerOnce) {
      mixpanel.register_once(superProps);
    } else {
      mixpanel.register(superProps);
    }
  },
  loginTrack: userProfile => {
    mixpanel.track('Login', userProfile);
  },
  logOffTrack: () => {
    mixpanel.track('Log Off', { method: 'logoff' });
    mixpanel.reset();
  },
  timeoutLogOffTrack: () => {
    mixpanel.track('Log Off', { method: 'timeout' });
    mixpanel.reset();
  }
};

const processTrackEvent = (trackingMethod, trackingMethodArguments) => {
  try {
    trackingMethod(...trackingMethodArguments);
  } catch (error) {
    //console.log(error)
  }
};

const isEnabled = localStorage => {
  const automationTestFlag = localStorage?.getItem('AutomationTest');
  return MIXPANEL_ACTIVE && !automationTestFlag;
};
class MP {
  constructor() {
    this.mpInit = false;
    this.companyName = null;
    this.cachedTrackEvents = []; //cache events until mpInit and get companyName from login user
    if (isEnabled(window.localStorage)) {
      mixpanel.init(MIXPANEL_TOKEN, {
        api_host: MIXPANEL_PROXY_URL,
        persistence: 'localStorage',
        loaded: () => {
          this.mpInit = true;
          //process event if get companyName already
          this.cachedTrackEvents = this.cachedTrackEvents.filter(catchedEvent => {
            if (!!this.companyName) {
              processTrackEvent(catchedEvent.trackingMethod, catchedEvent.trackingMethodArguments);
            }
            return !this.companyName;
          });
        }
      });
    } else {
      console.log('Mixpanel Disabled');
    }
  }
  registerCompanyName(companyName) {
    if (isEnabled(window.localStorage)) {
      this.companyName = companyName;
      if (this.mpInit) {
        //register company as superprop and clear cachedEvents
        MPTrackingFunctions.registerSuperProperty({ company: companyName });
        for (const catchedEvent of this.cachedTrackEvents) {
          processTrackEvent(catchedEvent.trackingMethod, catchedEvent.trackingMethodArguments);
        }
        this.cachedTrackEvents = [];
      } else {
        //cache register company as superprop will be processed once MP init
        this.cachedTrackEvents.unshift({
          trackingMethod: MPTrackingFunctions.registerSuperProperty,
          trackingMethodArguments: { company: companyName }
        });
      }
    }
  }
  sendToMixpanel(trackingMethod, ...args) {
    if (isEnabled(window.localStorage)) {
      if (this.mpInit && this.companyName) {
        processTrackEvent(trackingMethod, args);
      } else {
        this.cachedTrackEvents.push({
          trackingMethod,
          trackingMethodArguments: args
        });
      }
    }
  }
  sendTrackEvent(eventName, eventProps) {
    eventProps = eventProps || {};
    this.sendToMixpanel(MPTrackingFunctions.track, eventName, {
      eventTime: new Date().toISOString(),
      pathName: window.location.pathname,
      ...eventProps
    });
  }
  sendDateRangePickEvent(momentDates) {
    this.sendTrackEvent(MPTrackingEvents.General.DateRange.DateRangeSelected, {
      pathName: window.location.pathname,
      dateRange: MPTrackingEvents.General.DateRange.DateRangeProps.GetDateRangeOption([
        momentDates[0],
        momentDates[1]
      ])
    });
  }
}

export let Mixpanel = new MP();
