import * as d3 from 'd3';
import moment from 'moment';

import { getPointsDistance } from 'utils/geo';
import { getEventAttributesByType } from 'containers/Tracking/EventTypes';

import defaultEventImg from 'components/map/markers/images/grey.png';

import './svg-graph-track.scss';
import colors from 'static/scss/_colors.scss';

const ZOOM_LIMIT_MS = 15 * 1000; // zoom limit of 15 seconds
const SECONDS_LABEL_THRESHOLD = 15 * 60 * 1000; // 15 minutes

export const velocityAltitudeBrushGraphHDData = (
  selector,
  size,
  tripsInBounds,
  trips,
  startDate,
  endDate,
  localization,
  timeZone,
  focusedEvent,
  selectedEvent,
  selectedReplay,
  onSetSelectedReplay,
  onMouseOver,
  onMouseMove,
  onMouseOut,
  onEventClicked,
  updateCurrentBounds,
  t
) => {
  const { width: fullWidth, height: fullHeight } = size;
  document.querySelector(selector).innerHTML = '';

  let data = [];
  tripsInBounds.forEach(trip => {
    // Get replay data in combined array too
    data = [...data, ...trip.replay];
  });

  let allData = [];
  trips.forEach(trip => {
    allData = [...allData, ...trip.replay];
  });

  // canvas / graph dimensions
  let margin = { top: 54, right: 60, bottom: 26, left: 60 };
  let width = fullWidth - margin.left - margin.right;
  let height = fullHeight - margin.top - margin.bottom;
  let hasAltitudeData = allData && allData[0] && allData[0].Alt;

  var bisectDate = d3.bisector(function(d) {
    return d.date;
  }).left;

  function drawTooltip(d) {
    focus
      .select('circle.y0')
      .attr('transform', 'translate(' + x(d.date) + ',' + y0(d.velocity) + ')');

    focus
      .select('.x')
      .attr('transform', 'translate(' + x(d.date) + ',' + y0(d.velocity) + ')')
      .attr('y2', height - y0(d.velocity));
  }

  // set the ranges
  let x = d3.time.scale().range([0, width]);
  let y0 = d3.scale.linear().range([height, 0]);
  let y1 = d3.scale.linear().range([height, 0]);

  // Take width and height of UI into account for ticks setting
  let xTicks = width > 0 ? Math.floor(width / 70) : 10;
  const yTicks = height > 0 ? Math.floor(height / 36) : 5;
  let xFormat = 'HH:mm';

  // If zoomed in time range is less than threshold, show seconds on time labels
  const timeRange = endDate - startDate;
  if (timeRange < SECONDS_LABEL_THRESHOLD) {
    xFormat = 'HH:mm:ss';

    // different ticks to account for bigger labels
    xTicks = width > 0 ? Math.floor(width / 120) + 2 : 15;

    // To prevent duplicate seconds ticks on graph if zoomed in to max
    if (timeRange < ZOOM_LIMIT_MS && xTicks > 15) xTicks = 15;
  }

  // define the axes
  var xAxis = d3.svg
    .axis()
    .scale(x)
    .orient('bottom')
    .ticks(xTicks)
    .tickFormat(function(d) {
      return moment(d)
        .tz(timeZone)
        .format(xFormat);
    });

  // For x axis grid lines
  var xAxisGrid = d3.svg
    .axis()
    .scale(x)
    .orient('bottom')
    .innerTickSize(-height)
    .ticks(xTicks)
    .tickFormat('');

  var yAxisLeft = d3.svg
    .axis()
    .scale(y0)
    .orient('left')
    .ticks(yTicks);

  var yAxisRight = d3.svg
    .axis()
    .scale(y1)
    .orient('right')
    .ticks(yTicks);

  var velocityLine = d3.svg
    .line()
    .x(function(d) {
      return x(d.date);
    })
    .y(function(d) {
      return y0(d.velocity);
    });

  // svg canvas
  var svg = d3
    .select(selector)
    .classed('svg-container', true)
    .append('svg')
    .classed('svg-content-responsive', true)
    .attr('width', fullWidth)
    .attr('height', fullHeight)
    .attr('viewBox', '0 0 ' + fullWidth + ' ' + fullHeight)
    .attr('preserveAspectRatio', 'none')
    .append('g')
    .style('font', '16px')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

  var brush = d3.svg
    .brush()
    .x(x)
    .on('brushstart', function() {
      context.select('.extent').style('display', 'block');
    })
    .on('brushend', function() {
      // use brush to trigger zoom in
      const extent = brush.extent();
      if (extent && extent.length > 0) {
        const time1 = extent[0].getTime();
        const time2 = extent[1].getTime();

        // Check for case to trigger zoom
        if (time1 < time2 && time2 - time1 > ZOOM_LIMIT_MS) {
          updateCurrentBounds({ startDate: time1, endDate: time2 });
        } else {
          // If the times are the same, count it as a click event
          if (time1 === time2) {
            mouseClick(d3.mouse(this));
          }

          // Have to manually hide brush selection and elements if zoom doesn't trigger
          context.selectAll('.resize').style('display', 'none');
          context.select('.extent').style('display', 'none');
        }
      }
    });

  trips.forEach(trip => {
    trip.replay.forEach(d => {
      d.tripId = trip.id;
      d.date = d.At;
      d.velocity = +localization.convertSpeed(d.Spd);
      d.altitude = +localization.convertAltitude(d.Alt);
    });
  });

  // Scale the range of the data
  x.domain([startDate, endDate]);
  y0.domain([
    0,
    allData.length
      ? d3.max(allData, function(d) {
          return Math.max(d.velocity);
        })
      : 10
  ]);
  y1.domain([
    allData.length
      ? d3.min(allData, function(d) {
          return Math.min(d.altitude);
        })
      : 0,
    allData.length
      ? d3.max(allData, function(d) {
          return Math.max(d.altitude);
        })
      : 10
  ]);

  // date (x) axis grid lines
  svg
    .append('g')
    .attr('class', 'x axis-grid')
    .attr('transform', 'translate(0,' + height + ')')
    .call(xAxisGrid);

  // date (x) axis
  svg
    .append('g')
    .attr('class', 'x axis')
    .attr('transform', 'translate(0,' + height + ')')
    .style('fill', colors.neutral8)
    .call(xAxis);

  // velocity (y0) axis
  svg
    .append('g')
    .attr('class', 'y0 axis')
    .style('fill', colors.neutral8)
    .call(yAxisLeft);

  // velocity (y0) axis text
  svg
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('transform', 'translate(-' + margin.left / 2 + ', ' + height / 2 + ') rotate(-90)')
    .style('fill', colors.red6)
    .text(t('Tracking.Speed') + ' (' + localization.formats.speed.unit_per_hour + ')');

  if (hasAltitudeData) {
    var altitudeLine = d3.svg
      .line()
      .x(function(d) {
        return x(d.date);
      })
      .y(function(d) {
        return y1(d.altitude);
      });

    var altitudeArea = d3.svg
      .area()
      .x(function(d) {
        return x(d.date);
      })
      .y0(height)
      .y1(function(d) {
        return y1(d.altitude);
      });

    // separate altitude line for each trip
    tripsInBounds.forEach(trip => {
      svg
        .append('path')
        .data([trip.replay])
        .attr('d', altitudeLine(trip.replay))
        .attr('class', 'line')
        .style('fill', 'none')
        .style('stroke', colors.blue7)
        .style('stroke-width', '2px');

      // altitude area
      svg
        .append('path')
        .data([trip.replay])
        .attr('d', altitudeArea)
        .attr('class', 'area')
        .style('fill', colors.blue1)
        .attr('fill-opacity', '0.5');
    });

    // altitude (y1) axis
    svg
      .append('g')
      .attr('class', 'y1 axis')
      .attr('transform', 'translate(' + width + ' ,0)')
      .style('fill', colors.neutral8)
      .call(yAxisRight);

    // altitude (y1) axis text
    svg
      .append('text')
      .attr('text-anchor', 'middle')
      .attr(
        'transform',
        'translate(+' + (width + margin.right / 2) + ', ' + height / 2 + ') rotate(90)'
      )
      .style('fill', colors.blue7)
      .text(t('Tracking.Altitude') + ' (' + localization.formats.altitude.unit + ')');
  }

  // separate velocity line for each trip
  tripsInBounds.forEach(trip => {
    svg
      .append('path')
      .data([trip.replay])
      .attr('d', velocityLine(trip.replay))
      .attr('class', 'line')
      .style('fill', 'none')
      .style('stroke', colors.red6)
      .style('stroke-width', '2px');
  });

  // Selected point from click on graph or trip on map
  if (selectedReplay) {
    const d = selectedReplay.data;
    var selectedReplayElement = svg.append('g');

    // line
    selectedReplayElement
      .append('line')
      .attr('class', 'x')
      .style('stroke', colors.primaryLamp9)
      .style('stroke-width', '2px')
      .attr('x1', x(d.date))
      .attr('y1', y0(d.velocity))
      .attr('x2', x(d.date))
      .attr('y2', height);

    // circle
    selectedReplayElement
      .append('circle')
      .attr('class', 'y0')
      .style('fill', colors.primaryLamp6)
      .style('stroke', colors.primaryLamp9)
      .style('stroke-width', '2px')
      .attr('r', 5)
      .attr('cx', x(d.date))
      .attr('cy', y0(d.velocity));
  }

  // selected Event
  if (selectedEvent) {
    const gps = selectedEvent.GPS;
    if (gps) {
      var selectedEventElement = svg.append('g');

      // line
      selectedEventElement
        .append('line')
        .style('stroke', colors.primaryLamp9)
        .style('stroke-width', '2px')
        .attr('x1', x(selectedEvent.timeAt))
        .attr('y1', y0(gps.Spd))
        .attr('x2', x(selectedEvent.timeAt))
        .attr('y2', height);

      // circle
      selectedEventElement
        .append('circle')
        .style('fill', colors.primaryLamp6)
        .style('stroke', colors.primaryLamp9)
        .style('stroke-width', '2px')
        .attr('r', 5)
        .attr('cx', x(selectedEvent.timeAt))
        .attr('cy', y0(gps.Spd));
    }
  }

  // focused Event
  if (focusedEvent) {
    const gps = focusedEvent.GPS;
    if (gps) {
      var focusedEventElement = svg.append('g');

      // line
      focusedEventElement
        .append('line')
        .style('stroke', colors.red6)
        .style('stroke-width', '2px')
        .style('stroke-dasharray', '5,5')
        .style('opacity', 0.6)
        .attr('x1', x(focusedEvent.timeAt))
        .attr('y1', y0(gps.Spd))
        .attr('x2', x(focusedEvent.timeAt))
        .attr('y2', height);

      // circle
      focusedEventElement
        .append('circle')
        .style('fill', 'white')
        .style('stroke', colors.red6)
        .style('stroke-width', '2px')
        .attr('r', 5)
        .attr('cx', x(focusedEvent.timeAt))
        .attr('cy', y0(gps.Spd));
    }
  }

  // Indicator that moves on the graph with the mouse
  var focus = svg.append('g').style('display', 'none');

  // date (x) guage-line
  focus
    .append('line')
    .attr('class', 'x')
    .style('stroke', colors.red6)
    .style('stroke-width', '2px')
    .style('stroke-dasharray', '5,5')
    .style('opacity', 0.6)
    .attr('y1', 0)
    .attr('y2', height / 2);

  // velocity (y0) intersection circle
  focus
    .append('circle')
    .attr('class', 'y0')
    .style('fill', 'white')
    .style('stroke', colors.red6)
    .style('stroke-width', '2px')
    .attr('r', 5);

  // Draw event icons on top
  tripsInBounds.forEach(trip => {
    if (trip.events && trip.events.length) {
      trip.events.forEach(event => {
        svg
          .append('svg:image')
          .attr('class', 'event')
          .attr('x', -16)
          .attr('y', -16)
          .attr(
            'xlink:href',
            getEventAttributesByType(event.eventType, event.subType)?.markerSvg || defaultEventImg
          )
          .attr('transform', 'translate(' + x(event.timeAt) + ',' + -20 + ')')
          .on('click', function(d) {
            onEventClicked(event);
          });
      });
    }
  });

  // rectangle to capture mouse
  var context = svg.append('g');

  context.call(brush);

  context
    .selectAll('.resize')
    .append('path')
    .attr('d', 'M0,2V' + (height - 2))
    .style('stroke', 'black')
    .style('stroke-width', '2px');

  context
    .select('.extent')
    .attr('height', height - 2)
    .attr('fill', 'black')
    .attr('fill-opacity', '0.1');

  context
    .select('.background')
    .attr('height', height)
    .on('mouseover.tooltip', function() {
      if (data && data.length) {
        focus.style('display', null);
        mouseMove(d3.mouse(this));
        onMouseOver && onMouseOver();
      }
    })
    .on('mousemove.tooltip', function() {
      if (data && data.length) {
        mouseMove(d3.mouse(this));
      }
    })
    .on('mouseout.tooltip', function() {
      focus.style('display', 'none');
      onMouseOut && onMouseOut();
    });

  function getDateAndIndexFromMousePos(mouse) {
    var x0 = x.invert(mouse[0]),
      i = bisectDate(data, x0, 1),
      d0 = data[i - 1],
      d1 = data[i];
    var chosenD = d0,
      chosenIndex = i - 1;
    if (x0 - d0?.date > d1?.date - x0) {
      chosenD = d1;
      chosenIndex = i;
    }
    return { chosenD, chosenIndex };
  }

  function mouseMove(mouse) {
    const { chosenD, chosenIndex } = getDateAndIndexFromMousePos(mouse);

    // Calculate distance relative to each trip since this graph displays multiple trips
    let distance = 0;
    const dataPoint = data[chosenIndex];
    let trip = trips.find(trip => trip.id === dataPoint.tripId);
    if (trip && trip.replay && trip.replay.length) {
      distance = getTotalDistance(trip.replay, dataPoint.date);
    }

    drawTooltip(chosenD);
    onMouseMove && onMouseMove({ ...data[chosenIndex] }, distance);
  }

  function mouseClick(mouse) {
    const { chosenIndex } = getDateAndIndexFromMousePos(mouse);
    const dataPoint = data[chosenIndex];
    const newSelectedReplay = {
      tripId: dataPoint.tripId,
      position: { lat: dataPoint.Lat, lng: dataPoint.Lng },
      data: dataPoint
    };
    onSetSelectedReplay(newSelectedReplay);
  }

  function getTotalDistance(totalData, date) {
    const distanceInKM = getPointsDistance(
      totalData?.reduce((a, b) => {
        if (b.At <= date) {
          a.push({
            lat: b.Lat,
            lng: b.Lng
          });
        }
        return a;
      }, [])
    );
    return distanceInKM;
  }
};
