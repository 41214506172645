import React from 'react';

import PropTypes from 'prop-types';

import styles from './ChartTooltip.module.scss';

export const ChartTooltip = ({ payload }) => {
  return (
    !!payload[0]?.payload?.percentage && (
      <div className={styles.customTooltip}>{payload[0].payload.percentage}</div>
    )
  );
};

ChartTooltip.propTypes = {
  payload: PropTypes.array
};
