import { useState, useEffect } from 'react';
import { uniqueId } from 'lodash';

class MsgClient {
  id = null;
  msgHost = null;
  messageEventListener = null;

  constructor(id, msgHost) {
    this.id = id;
    this.msgHost = msgHost;
  }

  addOnMessageEventListener(callback) {
    this.messageEventListener = callback;
  }

  sendMsg(msg) {
    if (this.msgHost) {
      this.msgHost.dispatchMsg(this, msg);
    }
  }

  async onMessage(msg) {
    if (this.messageEventListener) {
      this.messageEventListener(msg);
    }
  }
}

class MsgHost {
  constructor() {
    this.clients = {};
  }

  newClient() {
    const client = new MsgClient(uniqueId(), this);
    this.clients[client.id] = client;
    return client;
  }

  removeClient(id) {
    delete this.clients[id];
  }

  async dispatchMsg(fromClient, msg) {
    Object.keys(this.clients).forEach(
      id => id !== fromClient?.id && this.clients[id]?.onMessage(msg)
    );
  }
}

export function useMsgChannel() {
  const [msgChannel] = useState(() => {
    const msgHost = new MsgHost();
    return msgHost;
  });
  return msgChannel;
}

export function useMsgClient(msgHost) {
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (msgHost && !client) {
      const newClient = msgHost.newClient();
      setClient(newClient);
    }

    return () => {
      if (client && msgHost) {
        msgHost.removeClient(client.id);
      }
    };
  }, [msgHost, client]);

  return client;
}
