/* eslint-disable import/prefer-default-export */
import { nanoid } from 'nanoid';

export const ALERT_STATUS_BAR_ID = nanoid();

export const ENTITIES = {
  USER: 'Ng::User',
  COMPANY: 'Ng::Company'
};

export const PAGE_TYPES = {
  VIEW: 'view',
  EDIT: 'edit'
};
