import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { useForm } from 'antd/lib/form/Form';
import BaseAlertForm from 'components/baseAlertForm/BaseAlertForm';

import alertFormReducer from './reducer/alertFormReducer';

import services from 'features/permissions/services';

import { useLocalization } from 'features/localization/localizationSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import {
  useUsers,
  useDrivers,
  useIsFetching as useIsFetchingUsers
} from 'features/users/usersSlice';
import { useForms, useIsFetching as isFormsFetching } from 'features/forms/formsSlice';
import { useServicePermissons } from 'features/permissions/permissionsSlice';
import {
  useFleets,
  useVehicles,
  useDevices,
  useIsFetching as isFleetsFetching
} from 'features/fleets/fleetsSlice';
import {
  usePretripChecklists,
  useIsFetching as isPretripsFetching
} from 'features/pretripChecklist/pretripChecklistSlice';
import {
  useClassifiedGeofences,
  useIsFetching as isGeofencesFetching
} from 'features/geofences/geofencesSlice';
import {
  useBranches,
  useIsFetching as isBranchesFetching
} from 'features/locations/locationsSlice';
import { useAlerts, useGpioTypes } from 'features/company_alerts';
import {
  useMeterThresholds,
  useMeterThresholdById,
  useIsAlertMeterThresholdsFetching,
  useIsMeterThresholdsSourcesFetching,
  fetchMeterThresholds
} from 'features/company_alerts/meter_alert';
import {
  useCompanies,
  useCompanyGeofenceProviders,
  useCurrentCompany
} from 'features/company/companySlice';

import { useIsUserAlertsFetched, useUserAlerts } from 'features/user_alerts';

import { getAlertFormInitialValues } from './reducer/state/getAlertFormInitialValues';
import { mapCompanyAlertsAPIResponseToState } from './reducer/state/mapCompanyAlertsAPIResponseToState';
import { mapUserAlertsAPIResponseToState } from './reducer/state/mapUserAlertsAPIResponseToState';

import getAlertConfig, { getMultiTieredAlertConfig } from './config';

import getEventTypes, { getEventTypesForVehicleAlerts } from './helpers/getEventTypes';
import { getAddTitleFromLink } from './helpers/linkManipulationFunctions';
import { hasSpeedLimit } from './helpers/hasSpeedLImit';

import {
  routeTypes,
  fatigueViolationsNotif,
  alarmCodesLabels,
  duressTypes,
  geofenceOptionsLabels,
  managedGeofenceOptionsLabels,
  signposted,
  smartjobsOptions,
  companyAlertForms,
  oohOptionsLabels,
  tachoLabels,
  eldUSAlertViolationLabels,
  eldCANAlertViolationLabels,
  eldUSAlertWarningLabels,
  eldCANAlertWarningLabels,
  inspectionDvirOptionsLabels,
  ALERT_TYPES,
  ALERT_LINKS
} from './constants';
import { ENTITIES } from 'components/alertStatusBar/constants';
import { useUserInfo } from '../../../features/user/userSlice';
import { nanoid } from 'nanoid';
import { useAllRegions } from '../../../features/regions/regionsSlice';
import { useCanOneOfCompanyServices, useCan } from 'features/permissions/canHooks';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';
import { openToast } from 'features/toasts/toastsSlice';
import { Paths as CompanyPaths } from './constants';
import { Paths as UserPaths } from 'containers/Settings/user/Alerts/constants';

import { ToastType } from 'components/notifications/toasts/Toast';
import {
  useFolders,
  useIsFetching as isEasyDocsFoldersFetching
} from 'features/easydocs/foldersSlice';

const initialAlertId = nanoid();

export const AlertForm = ({ action, entityType, clone }) => {
  const { id, type } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const localization = useLocalization();
  const [form] = useForm();
  const vehicles = useVehicles();
  const devices = useDevices();
  const users = useUsers();
  const currentUser = useUserInfo();
  const [timezones, setTimezones] = useState([]);
  const [currentRegion, setCurrentRegion] = useState(null);
  const fleets = useFleets();
  const drivers = useDrivers();
  const branches = useBranches();
  const forms = useForms();
  const pretripChecklists = usePretripChecklists();
  const classifiedGeofences = useClassifiedGeofences();
  const documentExpirationFolders = useFolders();
  const companies = useCompanies();
  const gpioTypes = useGpioTypes();
  const companyMeterThresholds = useMeterThresholds();
  const { t } = useTranslation();
  const currentCompany = useCurrentCompany();
  const isUSELDEnabled = useCanOneOfCompanyServices([services.ELDUS]);
  const isCanELDEnabled = useCanOneOfCompanyServices([services.ELDCAN]);

  const inspectionFeatureFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.dvir_inspection.flag
  });
  // remove NPI inspectionFeatureFlag when DVIR is release to Production
  const isDVIRServiceEnabled = useCanOneOfCompanyServices([services.DVIR]) && inspectionFeatureFlag;

  const eldAlertWarningsFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.eldAlertWarnings.flag
  });

  const can = useCan();
  const geofenceProviders = useCompanyGeofenceProviders();

  const isFetchingFleets = isFleetsFetching();
  const isFetchingUsers = useIsFetchingUsers();
  const isFetchingForms = isFormsFetching();
  const isFetchingPretrips = isPretripsFetching();
  const isFetchingGeofences = isGeofencesFetching();
  const isFetchingBranches = isBranchesFetching();
  const isFetchingDocumentExpirationFolders = isEasyDocsFoldersFetching();

  const regions = useAllRegions();

  const servicesPermissions = useServicePermissons();
  const eventTypes = getEventTypes(servicesPermissions.includes(services.VPM));
  const eventTypesForDriverVehicleAlerts = getEventTypesForVehicleAlerts(
    servicesPermissions.includes(services.VPM)
  );

  const alertType = companyAlertForms.find(alert => alert.link === type)?.type;

  const handleDispatch = obj => {
    dispatchAlert(obj);
  };

  const alerts = useAlerts(alertType);
  const userAlerts = useUserAlerts();
  const isFetchingCompanyAlerts = useSelector(state => state.companyAlerts.isFetching);
  const isFetchingUserAlerts = useSelector(state => state?.userAlerts?.isFetching);
  const isFetchedUserAlerts = useIsUserAlertsFetched();

  const [alertFormState, setAlertFormState] = useState();
  const [multiselectFleets, setMultiselectFleets] = useState([]);
  const [alertState, dispatchAlert] = useReducer(alertFormReducer, {
    active: true,
    desktop: false,
    expiry: 5259490,
    id: initialAlertId,
    link: null,
    mail: false,
    phone: false,
    selectedBranches: [],
    selectedDevices: [],
    selectedDrivers: [],
    selectedFleets: [],
    selectedUsers: [],
    selectedSecondAlertUsers: [],
    selectedThirdAlertUsers: [],
    selectedVehicles: [],
    secondAlertTimer: 0,
    thirdAlertTimer: 0,
    isSecondAlertEnabled: false,
    isThirdAlertEnabled: false,
    timezone: currentUser.timeZone,
    ...(hasSpeedLimit(type) && { speedLimit: 3 }),
    localization,
    type: alertType,
    isDVIRServiceEnabled: isDVIRServiceEnabled,
    eldAlertWarningsFlag: eldAlertWarningsFlag
  });

  const handleError = useCallback(
    err => {
      if (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err
          })
        );
      }

      let redirectPath = '';
      if (ENTITIES.COMPANY === entityType) {
        if (Object.values(ALERT_LINKS).some(l => l === type)) {
          redirectPath = CompanyPaths.ALERT_VIEW.replace(':type', type);
        } else {
          redirectPath = CompanyPaths.ALERTS_DEFAULT;
        }
      } else {
        redirectPath = UserPaths.USER_ALERTS_DEFAULT;
      }

      if (history.location.pathname !== redirectPath) {
        history.replace(redirectPath);
      }
    },
    [history, dispatch, type, entityType]
  );

  const isAlertMeterThresholdsFetching = useIsAlertMeterThresholdsFetching(alertState.id);
  const isMeterThresholdsSourcesFetching = useIsMeterThresholdsSourcesFetching();
  const alertMeterThresholds = useMeterThresholdById(alertState.meterThresholdId, alertState.id);

  useEffect(() => {
    if (!currentRegion) return;
    const companyTimezones = Object.values(JSON.parse(currentRegion.timezone)).map(timezone => ({
      id: String(nanoid()),
      value: timezone,
      label: timezone
    }));
    setTimezones(companyTimezones);
  }, [currentRegion]);

  useEffect(() => {
    if (alertType === ALERT_TYPES.METER) {
      dispatch(
        fetchMeterThresholds(
          entityType === ENTITIES.USER
            ? {
                query: {
                  all_companies: true
                }
              }
            : {}
        )
      );
    }
  }, [currentCompany]);

  useEffect(() => {
    if (!Object.values(ALERT_LINKS).some(l => l === type)) {
      handleError(t('Common.Invalid Alert Type'));
      return;
    }

    if (action === 'edit') {
      const parsedId = parseInt(id);
      if (
        parsedId <= 0 ||
        isNaN(parsedId) ||
        (entityType === ENTITIES.COMPANY &&
          !isFetchingCompanyAlerts &&
          !alerts.some(a => a.id === parsedId)) ||
        (entityType === ENTITIES.USER &&
          !isFetchingUserAlerts &&
          isFetchedUserAlerts &&
          !userAlerts.some(a => a.id === parsedId))
      ) {
        handleError(t('Common.Invalid Request ID'));
      }
    }
  }, [
    alerts,
    userAlerts,
    id,
    t,
    handleError,
    isFetchingCompanyAlerts,
    isFetchingUserAlerts,
    isFetchedUserAlerts,
    entityType,
    action,
    type
  ]);

  useEffect(() => {
    if (entityType === ENTITIES.COMPANY) {
      const mappedState = mapCompanyAlertsAPIResponseToState(
        alerts,
        clone,
        localization,
        alertMeterThresholds,
        classifiedGeofences
      ).find(alert => +alert.id === +id);
      setAlertFormState(mappedState);
    } else {
      const mappedState = mapUserAlertsAPIResponseToState(
        userAlerts,
        localization,
        alertMeterThresholds,
        classifiedGeofences
      ).find(alert => +alert.id === +id);
      setAlertFormState(mappedState);
    }
  }, [alerts.length, alertType, localization, alertMeterThresholds, classifiedGeofences]);

  useEffect(() => {
    if (alertFormState) {
      dispatchAlert({ type: 'SET_PAYLOAD', payload: alertFormState });
    }
  }, [alertFormState]);

  useEffect(() => {
    if (fleets?.length) {
      setMultiselectFleets([
        ...fleets,
        {
          id: -1,
          get name() {
            return t('Common.NoFleets');
          },
          vehicles: vehicles.filter(vehicle => !vehicle?.fleets?.find(fleet => fleet)?.id)
        }
      ]);
    }
  }, [fleets]);

  useEffect(() => {
    const companyCountry = companies.find(comp => comp.id === currentCompany.id)?.country;
    const currentRegion = regions.find(region => region.code === companyCountry);

    setCurrentRegion(currentRegion);
  }, [currentCompany, regions, companies]);

  const options = {
    vehicles,
    devices,
    users,
    fleets: multiselectFleets,
    drivers,
    branches,
    forms,
    managedGeofences: classifiedGeofences.managedGeofences,
    geofences: classifiedGeofences.geofences,
    companies,
    eventTypes: eventTypes.filter(e => !e.can || can(e.can)),
    eventTypesForDriverVehicleAlerts: eventTypesForDriverVehicleAlerts.filter(
      e => !e.can || can(e.can)
    ),
    routeTypes,
    pretripChecklists,
    fatigueViolationsNotif,
    alarmCodesLabels: alarmCodesLabels.filter(f => !f.can || can(f.can)),
    managedGeofenceOptionsLabels: managedGeofenceOptionsLabels.map(label => ({
      ...label,
      disabled: label.disable(classifiedGeofences.managedGeofences)
    })),
    geofenceOptionsLabels,
    oohOptionsLabels,
    smartjobsOptions,
    tachoLabels,
    signposted,
    eldCodesLabels: isCanELDEnabled
      ? Array.from(eldUSAlertViolationLabels).concat(eldCANAlertViolationLabels)
      : isUSELDEnabled
      ? eldUSAlertViolationLabels
      : [],
    eldAlertWarningLabels: eldAlertWarningsFlag
      ? isCanELDEnabled
        ? Array.from(eldUSAlertWarningLabels).concat(eldCANAlertWarningLabels)
        : isUSELDEnabled
        ? eldUSAlertWarningLabels
        : []
      : [],
    inspectionDvirOptionsLabels: isDVIRServiceEnabled
      ? pretripChecklists?.filter(
          ptc => alertState?.selectedPretrips?.includes(ptc.id) && ptc.isDVIR
        )?.length > 0
        ? inspectionDvirOptionsLabels
        : []
      : [],
    gpioTypes,
    companyMeterThresholds,
    alertMeterThresholds,
    duressTypes,
    timezones,
    documentExpirationFolders
  };

  const loaders = {
    isFetchingFleets,
    isFetchingUsers,
    isFetchingGeofences,
    isFetchingForms,
    isFetchingPretrips,
    isFetchingBranches,
    isAlertMeterThresholdsFetching,
    isMeterThresholdsSourcesFetching,
    isFetchingDocumentExpirationFolders
  };

  const alert = {
    handleDispatch,
    type: alertType,
    state: alertState
  };

  const initialValues = getAlertFormInitialValues(alertState, options);
  const alertConfig = getAlertConfig(
    alert,
    options,
    loaders,
    entityType,
    localization,
    can,
    initialValues,
    geofenceProviders
  );

  const multiTieredAlertConfig = getMultiTieredAlertConfig(alert, options, loaders, entityType);

  useEffect(() => {
    switch (action) {
      case 'edit':
        dispatch(setPageTitle(`${t('Alerts.EditNameAlert', { name: alertState?.name })}`));
        dispatch(setBackButton(true));
        break;
      default:
        dispatch(setPageTitle(getAddTitleFromLink(type)));
        dispatch(setBackButton(true));
        break;
    }
  }, [action, alertState, dispatch, type, t]);

  return (
    <BaseAlertForm
      form={form}
      alerts={alerts}
      initialValues={initialValues}
      alertState={alertState}
      config={alertConfig}
      multiTieredConfig={multiTieredAlertConfig}
      action={action}
      entityType={entityType}
    />
  );
};

AlertForm.propTypes = {
  action: PropTypes.string.isRequired
};

export default AlertForm;
