/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Notification from '../model/Notification';

/**
* Notifications service.
* @module api/NotificationsApi
* @version 1.0
*/
export default class NotificationsApi {

    /**
    * Constructs a new NotificationsApi. 
    * @alias module:api/NotificationsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the dismiss operation.
     * @callback module:api/NotificationsApi~dismissCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Notification} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * A token key is required for this call.
     * @param {Number} id 
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'IDS')
     * @param {module:api/NotificationsApi~dismissCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Notification}
     */
    dismiss(id, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling dismiss");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'pruning': opts['pruning']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Notification;
      return this.apiClient.callApi(
        '/notifications/{id}/dismiss', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getNotifications operation.
     * @callback module:api/NotificationsApi~getNotificationsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Notification>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * A user token key is required for this call.
     * @param {Object} opts Optional parameters
     * @param {String} opts.embed What needs to be included in response
     * @param {String} opts.from filter range from, yyyy-MM-ddTHH:mm:ss. default to 7 days
     * @param {String} opts.to filter range to, yyyy-MM-ddTHH:mm:ss. default to now
     * @param {module:model/String} opts.pruning  (default to 'APP')
     * @param {module:api/NotificationsApi~getNotificationsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Notification>}
     */
    getNotifications(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'embed': opts['embed'],
        'from': opts['from'],
        'to': opts['to'],
        'pruning': opts['pruning']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Notification];
      return this.apiClient.callApi(
        '/notifications', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * A token key is required for this call.
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.from  (default to 7 days)
     * @param {module:model/String} opts.to  (default to today)
     */
    dismissAll(opts, callback) {
      opts = opts || {};
      let postBody = null;
      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'to': opts['to']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/notifications/dismiss', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }
}
