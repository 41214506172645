import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, DatePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import fr from 'antd/es/date-picker/locale/fr_FR';
import es from 'antd/es/date-picker/locale/es_ES';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import styles from './DateRangePicker.module.scss';
import { FRENCH_LOCALES, SPANISH_LOCALES } from 'features/localization/languages';
import { BUTTON_IDS } from 'utils/globalConstants';
import { FeatureFlag, useCan } from 'features/permissions';

const PrevIcon = <i className="tn-i-caret-left" />;
const ForwardIcon = <i className="tn-i-caret-right" />;

/**
 * A date range picker wrapped ant design's date range picker.
 * @param {defaultDates} defaultDates the default start and end dates. the values should be moment objects.
 * @param {availableDatesRange} availableDatesRange an array of two moment objects for start and end dates to limit
 * the selectable date range.
 * @param {otherPresetDates} otherPresetDates other preset dates to be displaying. It should
 * an object with shape of {$PresetDateName$: $PresetDatesRangeArray$}.
 * @param {maxDayRange} maxDayRange the maximum days allowed to be selected in the available date range.
 * It should be an integer above 0.
 */
export function DateRangePicker({
  showTime,
  defaultDates,
  disabled,
  maxDayRange,
  availableDatesRange,
  showToday = true,
  showYesterday = true,
  showWeekToDate = true,
  showPast7Days = true,
  showPast14Days = true,
  showLastMonth = true,
  otherPresetDates,
  onDateRangeChanged,
  showNavigation = false,
  onOpenChange = null,
  datePickerRef,
  useStartOfDayForStartDay = false,
  adjustStartEndByXDays = 0,
  ...props
}) {
  const [dates, setDates] = useState(defaultDates);
  const [calendarDates, setCalendarDates] = useState(defaultDates);
  const userPreferences = useUserPreferences();
  const { t } = useTranslation();
  const can = useCan();
  const [locale, setLocale] = useState();
  const [datePickerId] = useState(() => {
    return 'datepicker-' + Date.now();
  });

  useEffect(() => {
    if (datePickerRef) {
      datePickerRef.current = {
        resetDates: (dates = []) => {
          setCalendarDates(dates);
          setDates(dates);
        }
      };
    }
  }, [datePickerRef]);

  useEffect(() => {
    if (Object.values(FRENCH_LOCALES).some(frLang => userPreferences?.language === frLang)) {
      setLocale(fr);
    }
    if (Object.values(SPANISH_LOCALES).some(esLang => userPreferences?.language === esLang)) {
      setLocale(es);
    }
  }, [userPreferences]);

  const presetRanges = useMemo(() => {
    const defaultRanges = [
      ...(showToday
        ? [
            {
              label: t('Common.Today'),
              value: [moment().startOf('day'), moment().endOf('day')]
            }
          ]
        : []),
      ...(showYesterday
        ? [
            {
              label: t('Common.Yesterday'),
              value: [
                moment()
                  .subtract(1, 'day')
                  .startOf('day'),
                moment()
                  .subtract(1, 'day')
                  .endOf('day')
              ]
            }
          ]
        : []),
      ...(showWeekToDate
        ? [
            {
              label: t('Common.WeekToDate'),
              value: [moment().startOf('week'), moment().endOf('day')]
            }
          ]
        : []),
      ...(showPast7Days
        ? [
            {
              label: t('Common.Past7Days'),
              value: [
                moment()
                  .subtract(6, 'day')
                  .startOf('day'),
                moment().endOf('day')
              ]
            }
          ]
        : []),
      ...(showPast14Days
        ? [
            {
              label: t('Common.Past14Days'),
              value: [
                moment()
                  .subtract(13, 'day')
                  .startOf('day'),
                moment().endOf('day')
              ]
            }
          ]
        : []),
      ...(showLastMonth
        ? [
            {
              label: t('Common.LastMonth'),
              value: [
                moment()
                  .subtract(1, 'month')
                  .startOf('month'),
                moment()
                  .subtract(1, 'month')
                  .endOf('month')
              ]
            }
          ]
        : []),
      ...(otherPresetDates || [])
    ];

    return defaultRanges;
  }, [
    showToday,
    showYesterday,
    showWeekToDate,
    showPast7Days,
    showPast14Days,
    showLastMonth,
    otherPresetDates
  ]);

  const disabledDate = useCallback(
    current => {
      let disableDate = false;
      if (current && availableDatesRange) {
        disableDate = current < availableDatesRange[0] || current > availableDatesRange[1];
      }
      if (!disableDate && maxDayRange && dates && dates.length) {
        if (showTime) {
          const tooLate = dates[0] && current.diff(dates[0], 'minutes') > maxDayRange * 24 * 60 - 1;
          const tooEarly =
            dates[1] && dates[1].diff(current, 'minutes') > maxDayRange * 24 * 60 - 1;
          disableDate = tooEarly || tooLate;
        } else {
          const tooLate = dates[0] && current.diff(dates[0], 'days') > maxDayRange;
          const tooEarly = dates[1] && dates[1].diff(current, 'days') > maxDayRange;
          disableDate = tooEarly || tooLate;
        }
      }
      return disableDate;
    },
    [dates, maxDayRange, availableDatesRange]
  );

  const adjustedEndDate = (startDate, endDate, availableDatesRange, maxDayRange, showTime) => {
    const timeUnit = showTime ? 'minutes' : 'days';
    const maxDuration = showTime ? maxDayRange * 24 * 60 - 1 : maxDayRange + adjustStartEndByXDays;

    if (endDate != null && endDate.diff(startDate, timeUnit) > maxDuration) {
      if (showTime) {
        endDate = startDate.clone().add(maxDuration, 'minutes');
      } else {
        endDate = startDate
          .clone()
          .add(maxDuration, 'days')
          .endOf('day');
      }

      if (
        availableDatesRange &&
        availableDatesRange.length >= 2 &&
        availableDatesRange[1] &&
        endDate > availableDatesRange[1]
      ) {
        endDate = availableDatesRange[1];
      }
    }

    if (startDate && !endDate && maxDayRange) {
      if (showTime) {
        endDate = startDate.clone().add(maxDuration, 'minutes');
      } else {
        endDate = findValidEndDate(startDate, maxDuration);
      }

      if (
        availableDatesRange &&
        availableDatesRange.length >= 2 &&
        availableDatesRange[1] &&
        endDate > availableDatesRange[1]
      ) {
        endDate = availableDatesRange[1];
      }
    }

    return endDate;
  };

  const findValidEndDate = (startDate, maxDuration) => {
    let endDate = startDate
      .clone()
      .add(maxDuration, 'days')
      .endOf('day');

    const checkIsDisabled = props.disabledDate || disabledDate;
    // Check if the initial end date is disabled, if it is then minus one and check again
    while (checkIsDisabled(endDate) && endDate.isAfter(startDate)) {
      endDate = endDate.subtract(1, 'days');
    }

    return endDate;
  };

  const adjustedStartDate = (startDate, endDate, availableDatesRange, maxDayRange, showTime) => {
    const timeUnit = showTime ? 'minutes' : 'days';
    const maxDuration = showTime ? maxDayRange * 24 * 60 - 1 : maxDayRange + adjustStartEndByXDays;

    if (startDate != null && endDate.diff(startDate, timeUnit) > maxDuration) {
      startDate = endDate.clone().subtract(maxDuration, timeUnit);
      if (availableDatesRange && availableDatesRange[0] && startDate < availableDatesRange[0]) {
        startDate = availableDatesRange[0];
      }
    } else if (!startDate) {
      startDate = moment(endDate).subtract(maxDuration, timeUnit);
    }
    return startDate;
  };

  const handleCalendarChange = useCallback(
    (values, dateStrs, info) => {
      if (info?.range === 'start') {
        useStartOfDayForStartDay && (values[0] = showTime ? values[0] : values[0].startOf('day'));
        values[1] = adjustedEndDate(
          values[0],
          values[1],
          availableDatesRange,
          maxDayRange,
          showTime
        );
      }
      if (info?.range === 'end') {
        values[1] = showTime ? values[1] : values[1].endOf('day');
        values[0] = adjustedStartDate(
          values[0],
          values[1],
          availableDatesRange,
          maxDayRange,
          showTime
        );
      }
      setDates(values);
      setCalendarDates(values);
      //not matter selected from start or end, should always emit valid values
      if (values[0] && values[1] && onDateRangeChanged) {
        onDateRangeChanged(values, info);
      }
    },
    [maxDayRange, availableDatesRange, showTime]
  );

  const handleCalendarPanelState = useCallback(
    open => {
      if (onOpenChange) {
        onOpenChange(open);
      }

      if (open) {
        setDates([null, null]);
      } else {
        const startDateDOM = document.querySelector('#' + datePickerId);
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          'value'
        ).set;
        const changeEvent = new InputEvent('input', {
          bubbles: true
        });
        setDates(prevDates => {
          if (startDateDOM) {
            const startDateStr = prevDates && moment(prevDates[0]).format(props.format);
            if (startDateStr !== startDateDOM.value) {
              nativeInputValueSetter.call(startDateDOM, startDateStr);
              startDateDOM.dispatchEvent(changeEvent);
            }
          }

          return null;
        });
      }
    },
    [onOpenChange, props.format, datePickerId]
  );

  const handleBackCalendar = useCallback(() => {
    const diffDays = Math.ceil(moment.duration(calendarDates[1] - calendarDates[0]).asDays());
    const newDates = [
      moment(calendarDates[0]).subtract(diffDays, 'd'),
      moment(calendarDates[1]).subtract(diffDays, 'd')
    ];
    handleCalendarChange(newDates);
  }, [calendarDates, handleCalendarChange]);

  const handleForwardCalendar = useCallback(() => {
    const diffDays = Math.ceil(moment.duration(calendarDates[1] - calendarDates[0]).asDays());
    const newDates = [
      moment(calendarDates[0]).add(diffDays, 'd'),
      moment(calendarDates[1]).add(diffDays, 'd')
    ];
    handleCalendarChange(newDates);
  }, [calendarDates, handleCalendarChange]);

  const diffDays = Math.ceil(moment.duration(calendarDates[1] - calendarDates[0]).asDays());

  return (
    <>
      {showNavigation && (
        <Button
          title={t('Common.Backward Days', { day: diffDays })}
          className={styles.navigationBtn}
          onClick={handleBackCalendar}
          icon={PrevIcon}
          id={BUTTON_IDS.dateRangeBackwardDays}
          disabled={disabled || disabledDate(moment(calendarDates[0]).subtract(diffDays, 'day'))}
        />
      )}

      <DatePicker.RangePicker
        id={datePickerId}
        showTime={showTime}
        disabled={disabled}
        allowClear={false}
        defaultValue={defaultDates}
        disabledDate={disabledDate}
        presets={presetRanges}
        onCalendarChange={handleCalendarChange}
        onOpenChange={handleCalendarPanelState}
        locale={locale}
        {...props}
        className={[props.className, showNavigation && styles.navigationPicker].join(' ')}
        value={dates || calendarDates}
      />
      {showNavigation && (
        <Button
          title={t('Common.Forward Days', { day: diffDays })}
          className={showNavigation && styles.navigationBtn}
          onClick={handleForwardCalendar}
          icon={ForwardIcon}
          id={BUTTON_IDS.dateRangeForwardDays}
          disabled={disabled || disabledDate(moment(calendarDates[1]).add(diffDays, 'day'))}
        />
      )}
    </>
  );
}
