import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import { getBulkData, getTemplates, uploadBulkFile } from './thunks';

const initialState = {
  bulkData: {
    items: [],
    meta: {
      isFetching: false,
      error: null,
      success: false
    }
  },
  templates: {
    meta: {},
    items: []
  },
  currentBulkItem: {
    meta: {},
    data: null
  }
};

export const bulkImportSlice = createSlice({
  name: 'bulkImport',
  initialState,
  extraReducers: {
    [getBulkData.pending]: state => {
      state.bulkData.meta.isFetching = true;
      state.bulkData.meta.success = false;
      state.bulkData.meta.error = false;
    },
    [getBulkData.fulfilled]: (state, action) => {
      state.bulkData.meta.isFetching = false;
      state.bulkData.meta.success = true;
      state.bulkData.meta.error = false;
      state.bulkData.items = unwrapResult(action);
    },
    [getBulkData.rejected]: state => {
      state.bulkData.meta.isFetching = false;
      state.bulkData.meta.success = false;
      state.bulkData.meta.error = true;
      state.bulkData.items = [];
    },
    [getTemplates.pending]: state => {
      state.templates.meta.isFetching = true;
      state.templates.meta.success = false;
      state.templates.meta.error = false;
    },
    [getTemplates.fulfilled]: (state, action) => {
      state.templates.meta.isFetching = false;
      state.templates.meta.success = true;
      state.templates.meta.error = false;
      state.templates.items = unwrapResult(action);
    },
    [getTemplates.rejected]: state => {
      state.templates.meta.isFetching = false;
      state.templates.meta.success = false;
      state.templates.meta.error = true;
    },
    [uploadBulkFile.pending]: state => {
      state.currentBulkItem.meta.isFetching = true;
      state.currentBulkItem.meta.success = false;
      state.currentBulkItem.meta.error = false;
    },
    [uploadBulkFile.fulfilled]: (state, action) => {
      state.currentBulkItem.meta.isFetching = false;
      state.currentBulkItem.meta.success = true;
      state.currentBulkItem.meta.error = false;
      state.currentBulkItem.data = unwrapResult(action);
    },
    [uploadBulkFile.rejected]: state => {
      state.currentBulkItem.meta.isFetching = false;
      state.currentBulkItem.meta.success = true;
      state.currentBulkItem.meta.error = true;
      state.currentBulkItem.data = null;
    }
  }
});
