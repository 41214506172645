import { useDispatch, useSelector } from 'react-redux';
import {
  auditsSelectors,
  bulkEditAuditsSelectors,
  vehicleDeviceAssociationAuditsSelectors
} from '../selectors';
import { fetchVehicleDeviceAssociationAudits, fetchAudits, fetchBulkEditAudits } from '../thunks';
import { useEffect } from 'react';

export const useAudits = (entityName, entityId, onError) => {
  const dispatch = useDispatch();
  const audits = useSelector(auditsSelectors.getAudits);
  const isFetching = useSelector(auditsSelectors.isFetching);
  const isSuccess = useSelector(auditsSelectors.isSuccess);
  const isError = useSelector(auditsSelectors.isError);
  const isFetchingFinished = isSuccess || isError;
  const lastFetched = useSelector(auditsSelectors.lastFetched);
  const isEntityIdChanged =
    lastFetched && (lastFetched?.entityName !== entityName || lastFetched?.entityId !== entityId);

  useEffect(() => {
    if (!isFetching && (!isFetchingFinished || isEntityIdChanged) && entityId) {
      dispatch(fetchAudits({ entityName, entityId }))
        .unwrap()
        .catch(err => {
          if (onError) {
            onError(err);
          }
        });
    }
  }, [dispatch, isFetching, isFetchingFinished, entityId, onError, entityName, isEntityIdChanged]);

  return audits;
};

export const useVehicleDeviceAssociationAudits = (entityName, entityId, onError) => {
  const dispatch = useDispatch();
  const audits = useSelector(vehicleDeviceAssociationAuditsSelectors.getAudits);
  const isFetching = useSelector(vehicleDeviceAssociationAuditsSelectors.isFetching);
  const isSuccess = useSelector(vehicleDeviceAssociationAuditsSelectors.isSuccess);
  const isError = useSelector(vehicleDeviceAssociationAuditsSelectors.isError);
  const isFetchingFinished = isSuccess || isError;
  const lastFetched = useSelector(vehicleDeviceAssociationAuditsSelectors.lastFetched);
  const isEntityIdChanged =
    lastFetched && (lastFetched?.entityName !== entityName || lastFetched?.entityId !== entityId);

  useEffect(() => {
    if (!isFetching && (!isFetchingFinished || isEntityIdChanged) && entityId) {
      dispatch(fetchVehicleDeviceAssociationAudits({ entityName, entityId }))
        .unwrap()
        .catch(err => {
          if (onError) {
            onError(err);
          }
        });
    }
  }, [dispatch, isFetching, isFetchingFinished, entityId, onError, entityName, isEntityIdChanged]);

  return { audits, isLoading: isFetching };
};

export const useIsAuditsFetching = () => useSelector(auditsSelectors.isFetching);

export const useBulkEditAudits = (entityName, onError) => {
  const dispatch = useDispatch();
  const audits = useSelector(bulkEditAuditsSelectors.getAudits);
  const list = audits[entityName]?.list;
  const isFetching = audits[entityName]?.isFetching;
  const isSuccess = audits[entityName]?.success;
  const isError = audits[entityName]?.error;
  const isFetchingFinished = isSuccess || isError;

  useEffect(() => {
    if (!isFetching && !isFetchingFinished) {
      dispatch(fetchBulkEditAudits({ entityName }))
        .unwrap()
        .catch(err => {
          if (onError) {
            onError();
          }
        });
    }
  }, [dispatch, isFetching, isFetchingFinished, onError, entityName]);

  return list;
};

export const useIsBulkAuditsFetching = entityName => {
  const audits = useSelector(bulkEditAuditsSelectors.getAudits);
  return !audits?.[entityName] || audits[entityName].isFetching;
};
