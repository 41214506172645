import React from 'react';

import { Loading } from 'components/loading/Loading';

import styles from './loading.module.scss';

export const DroneViewLoadingOverlay = ({ isLoading = true }) => {
  return (
    isLoading && (
      <div className={[styles.loadingOverlay, styles.droneView].join(' ')}>
        <Loading />
      </div>
    )
  );
};
