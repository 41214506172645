import React from 'react';
import { Switch } from 'react-router';
import CanRoute from 'components/route/CanRoute';

import { FeatureFlag } from 'features/permissions';
import { useUserInfo } from 'features/user/userSlice';
import { TCAEnrolments, Paths, EnrolmentsForm, ViewEnrolment } from '.';

export const TCAEnrolmentsRouter = () => {
  const user = useUserInfo();
  const isSiteAdmin = () => user.siteAdmin;

  return (
    <Switch>
      <CanRoute
        path={Paths.ADD_NEW_ENROLMENT}
        featureFlag={FeatureFlag.TCAEnrolments.flag}
        render={<EnrolmentsForm />}
        otherConditions={[isSiteAdmin]}
      />
      <CanRoute
        path={`${Paths.EDIT_ENROLMENT}/:id`}
        featureFlag={FeatureFlag.TCAEnrolments.flag}
        render={<EnrolmentsForm />}
        otherConditions={[isSiteAdmin]}
      />
      <CanRoute
        path={`${Paths.VIEW_ENROLEMNT}/:id`}
        featureFlag={FeatureFlag.TCAEnrolments.flag}
        render={<ViewEnrolment />}
        otherConditions={[isSiteAdmin]}
      />
      <CanRoute
        path={Paths.ENROLMENTS_TABLE}
        featureFlag={FeatureFlag.TCAEnrolments.flag}
        render={<TCAEnrolments />}
        otherConditions={[isSiteAdmin]}
      />
    </Switch>
  );
};
