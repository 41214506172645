import { Select, Tooltip } from 'antd';
import i18n from 'i18next';
import { DeviceIMEITooltip } from '../helpers';
import { ITEM_NAMES } from './constants';

import styles from './EnrolmentsForm.module.scss';

export const getVehicleRegistrationStates = (companyId, companies, regions) => {
  const formRegions = [];
  const companyCountry = companies.find(comp => comp.id === companyId)?.country;

  regions.forEach(country => {
    if (country.code === companyCountry) {
      const formStates = JSON.parse(country.states);

      Object.keys(formStates).forEach(name => {
        formRegions.push({ name, id: formStates[name] });
      });
    }
  });

  return formRegions.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const getCompanySchemes = schemes =>
  schemes?.map(scheme => ({
    name: scheme['schemeName'],
    id: scheme['schemeCode'],
    authorityCode: scheme['authorityCode']
  }));

export const getCompanyScheme = scheme => ({
  name: scheme['schemeName'],
  id: scheme['schemeCode'],
  authorityCode: scheme['authorityCode']
});

export const getSchemeOption = value => {
  return (
    <Select.Option key={value.id}>
      <Tooltip placement="left" title={value.name}>
        <div className={styles.schemeOptionContainer}>
          <div className={styles.schemeName}>{value.id}</div>
          <div className={styles.schemeCode}>
            {i18n.t('TCAEnrolments.Form.AuthorityCode')}: {value.authorityCode}
          </div>
        </div>
      </Tooltip>
    </Select.Option>
  );
};

export const getDeviceOption = value => {
  return (
    <Select.Option key={value.id} value={value.imei}>
      <DeviceIMEITooltip placement="left" name={value.name} imei={value.imei} />
    </Select.Option>
  );
};

export const resetFieldsOnCompanyChange = (form, newCompanyId) => {
  form.setFieldsValue({
    [ITEM_NAMES.COMPANY_ID]: newCompanyId,
    [ITEM_NAMES.DEVICE_IMEI]: undefined,
    [ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER]: undefined,
    [ITEM_NAMES.VEHICLE_REGISTRATION_STATE]: undefined,
    [ITEM_NAMES.VEHICLE_VIN]: undefined,
    [ITEM_NAMES.SCHEME]: undefined
  });
};

export const resetOperatorDetailsFields = form => {
  form.setFieldsValue({
    [ITEM_NAMES.OPERATOR_NAME]: undefined,
    [ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE]: undefined,
    [ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY]: undefined,
    [ITEM_NAMES.OPERATOR_ADDRESS_STATE]: undefined,
    [ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE]: undefined,
    [ITEM_NAMES.OPERATOR_BUSINESS_HOURS]: undefined
  });
};

export const getBodyFromValues = (values, device, isVIN) => {
  if (typeof values[ITEM_NAMES.SCHEME] === 'string') {
    return {
      [ITEM_NAMES.COMPANY_ID]: values[ITEM_NAMES.COMPANY_ID],
      [ITEM_NAMES.DEVICE_ID]: device.id,
      vehicleId: device?.vehicle?.id,
      domain: 'TCA',
      status: 'APPROVED',
      [ITEM_NAMES.SCHEME]: values[ITEM_NAMES.SCHEME],
      companyDetails: JSON.stringify({
        identity: {
          [ITEM_NAMES.OPERATOR_NAME]: values[ITEM_NAMES.OPERATOR_NAME],
          [ITEM_NAMES.OPERATOR_ABN]: values[ITEM_NAMES.OPERATOR_ABN]
        },
        postalAddress: {
          [ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE]: values[ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE],
          [ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY]: values[ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY],
          [ITEM_NAMES.OPERATOR_ADDRESS_STATE]: values[ITEM_NAMES.OPERATOR_ADDRESS_STATE],
          [ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE]: values[ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE]
        },
        [ITEM_NAMES.OPERATOR_BUSINESS_HOURS]: values[ITEM_NAMES.OPERATOR_BUSINESS_HOURS]
      }),
      deviceDetails: JSON.stringify({
        id: device.id,
        imei: values[ITEM_NAMES.DEVICE_IMEI],
        [ITEM_NAMES.DEVICE_INSTALLATION_TIME]: values[ITEM_NAMES.DEVICE_INSTALLATION_TIME]?.format(
          'YYYY-MM-DD HH:mm:ss.SSSZ'
        ),
        [ITEM_NAMES.DEVICE_INSTALLATION_LOCATION]: values[ITEM_NAMES.DEVICE_INSTALLATION_LOCATION]
      }),
      vehicleDetails: JSON.stringify({
        [ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER]: values[ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER],
        [ITEM_NAMES.VEHICLE_REGISTRATION_STATE]: values[ITEM_NAMES.VEHICLE_REGISTRATION_STATE],
        ...(values[ITEM_NAMES.PBS] && { [ITEM_NAMES.PBS]: values[ITEM_NAMES.PBS]?.split(',') }),
        ...(isVIN && { [ITEM_NAMES.VEHICLE_VIN]: values[ITEM_NAMES.VEHICLE_VIN] }),
        ...(!isVIN && { [ITEM_NAMES.VEHICLE_NON_VIN]: values[ITEM_NAMES.VEHICLE_VIN] })
      })
    };
  }

  return values[ITEM_NAMES.SCHEME].filter(Boolean).map(scheme => ({
    [ITEM_NAMES.COMPANY_ID]: values[ITEM_NAMES.COMPANY_ID],
    [ITEM_NAMES.DEVICE_ID]: device.id,
    vehicleId: device?.vehicle?.id,
    domain: 'TCA',
    status: 'APPROVED',
    scheme,
    companyDetails: JSON.stringify({
      identity: {
        [ITEM_NAMES.OPERATOR_NAME]: values[ITEM_NAMES.OPERATOR_NAME],
        [ITEM_NAMES.OPERATOR_ABN]: values[ITEM_NAMES.OPERATOR_ABN]
      },
      postalAddress: {
        [ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE]: values[ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE],
        [ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY]: values[ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY],
        [ITEM_NAMES.OPERATOR_ADDRESS_STATE]: values[ITEM_NAMES.OPERATOR_ADDRESS_STATE],
        [ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE]: values[ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE]
      },
      [ITEM_NAMES.OPERATOR_BUSINESS_HOURS]: values[ITEM_NAMES.OPERATOR_BUSINESS_HOURS]
    }),
    deviceDetails: JSON.stringify({
      id: device.id,
      imei: values[ITEM_NAMES.DEVICE_IMEI],
      [ITEM_NAMES.DEVICE_INSTALLATION_TIME]: values[ITEM_NAMES.DEVICE_INSTALLATION_TIME]?.format(
        'YYYY-MM-DD HH:mm:ss.SSSZ'
      ),
      [ITEM_NAMES.DEVICE_INSTALLATION_LOCATION]: values[ITEM_NAMES.DEVICE_INSTALLATION_LOCATION]
    }),
    vehicleDetails: JSON.stringify({
      [ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER]: values[ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER],
      [ITEM_NAMES.VEHICLE_REGISTRATION_STATE]: values[ITEM_NAMES.VEHICLE_REGISTRATION_STATE],
      ...(values[ITEM_NAMES.PBS] && { [ITEM_NAMES.PBS]: values[ITEM_NAMES.PBS]?.split(',') }),
      ...(isVIN && { [ITEM_NAMES.VEHICLE_VIN]: values[ITEM_NAMES.VEHICLE_VIN] }),
      ...(!isVIN && { [ITEM_NAMES.VEHICLE_NON_VIN]: values[ITEM_NAMES.VEHICLE_VIN] })
    })
  }));
};

export const parseEnrolment = enrolment =>
  enrolment
    ? {
        ...enrolment,
        companyDetails: JSON.parse(enrolment.companyDetails),
        deviceDetails: JSON.parse(enrolment.deviceDetails),
        vehicleDetails: JSON.parse(enrolment.vehicleDetails)
      }
    : null;

export const getDeviceName = (id, devices) =>
  (devices.find(device => device.id === id) || {})?.name;
