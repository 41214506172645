export const COLORS = {
  SELECTIVE_YELLOW: '#ffb405',
  SILVER: '#bcc3cd',
  EMPEROR: '#515e71',
  EBONY_CLAY: '#2b323c',
  TURQUOISE: '#40a9ff',
  BONDI_BLUE: '#003a8c',
  DARK_CYAN: '#14946a',
  MULBERY: '#47c79c',
  ZEST: '#d48806',
  PICTON_BLUE: '#aa60cd',
  MUSTARD: '#d48806'
};

export const eventTypeValueStyle = {
  fontWeight: 500,
  fontSize: '24px',
  fill: COLORS.EMPEROR,
  fontFamily: 'Roboto'
};

export const eventTypeLabelStyle = {
  fontSize: '13px',
  fill: COLORS.EBONY_CLAY,
  fontFamily: 'Roboto'
};

export const PIE_PROPERTIES = {
  width: 180,
  height: 180,
  activeIndex: 0,
  cx: 90,
  cy: 85,
  value: 'value',
  dataKey: 'value',
  innerRadius: 65,
  outerRadius: 80,
  paddingAngle: 1,
  startAngle: 450,
  endAngle: 90
};
