import React, { useEffect, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useGetQsConsoleUrlQuery } from 'services/nextgen';

import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';

import { Iframe } from 'components/iframe/Iframe';
import { LoadingCentered } from 'components/loading/Loading';

const Console = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localization = useLocalization();
  const consoleFrameRef = useRef(null);
  const consoleUrl = useGetQsConsoleUrlQuery();

  useEffect(() => {
    dispatch(setPageTitle(t('Common.Insights')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  const onFrameLoaded = () => {
    console.debug('onFrameLoaded', {
      consoleUrl,
      automationId: document.querySelectorAll('[data-automation-id]')
    });
  };

  const isLoading = useMemo(() => consoleUrl.isLoading || consoleUrl.isFetching, [
    consoleUrl.isLoading,
    consoleUrl.isFetching
  ]);

  return (
    <>
      {isLoading ? (
        <LoadingCentered />
      ) : (
        <Iframe
          id="dashboards"
          title="Dashboards"
          width={'100%'}
          style={{ border: 'none', height: '100%' }}
          src={consoleUrl.data + `&locale=${localization.locale}`}
          ref={consoleFrameRef}
          onLoad={onFrameLoaded}
        />
      )}
    </>
  );
};

export default Console;
