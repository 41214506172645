import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import DropdownTreeSelect from 'components/form/treeselect/DropdownTreeSelect';
import { PATH_SPLIT } from 'components/form/treeselect/TreeSelect';

import styles from './grid.module.scss';

export const GridHeader = ({
  filters,
  count,
  recordTypeSingular,
  recordTypePlural,
  showSearch,
  showFilters,
  showCount,
  onSearchChange,
  onFiltersChange,
  onSortChange,
  ...props
}) => {
  const [selectedFilters, setSelectedFilters] = useState(filters);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedFilters(prevFilters => {
      if (prevFilters) {
        for (let key in prevFilters) {
          if (prevFilters[key].children[0].checked) {
            filters[key].children[0].checked = true;
          }

          for (let i = 1; i < prevFilters[key].children.length; i++) {
            const node = prevFilters[key].children[i];
            if (node.checked) {
              let filterNode = filters[key].children.find(cn => cn.label === node.label);
              if (!filterNode) {
                filterNode = Object.assign(prevFilters[key].children[i]);
                filterNode.id = filters[key].children.length;
                filterNode.nodeKey = filters[key].nodeKey + PATH_SPLIT + filterNode.id;
                filters[key].children.push(filterNode);
              }
              filterNode.checked = true;
            }
          }
        }
      }
      return filters;
    });
  }, [filters]);

  const handleSearchChange = text => {
    if (onSearchChange) {
      onSearchChange(text);
    }
  };

  const handleFiltersChange = filters => {
    setSelectedFilters(filters);
    if (onFiltersChange) {
      onFiltersChange(filters);
    }
  };

  return (
    <header className={styles.gridHeader} {...props}>
      {showSearch && <AntSearchbar onFilter={handleSearchChange} />}
      {showFilters && (
        <DropdownTreeSelect
          style={{ width: '200px' }}
          title={t('Common.Filter')}
          showSelectAll={true}
          tree={selectedFilters}
          onChange={handleFiltersChange}
        />
      )}
      {showCount && (
        <span>{count + ' ' + (count === 1 ? recordTypeSingular : recordTypePlural)}</span>
      )}
    </header>
  );
};
