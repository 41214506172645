import moment from 'moment';

export const initialValues = {
  schedulesOn: moment()
};

export const ACTIONS = {
  ADD: 'add',
  EDIT: 'edit'
};

export const STATUS = {
  completed: 'COMPLETED',
  dueNow: 'DUE_NOW',
  pending: 'PENDING',
  cancelled: 'CANCELLED',
  deleted: 'DELETED',
  overdue: 'OVERDUE'
};

export const LabelAlignLeft = 'left';
export const SizeLarge = 'large';

export const Paths = {
  MAIN_PAGE: '/drivermanagement',
  VIEW_DRIVERMANAGEMENTSCHEDULE: '/drivermanagement/id',
  ADD_DRIVERMANAGEMENTSCHEDULE: '/drivermanagement/add',
  EDIT_DRIVERMANAGEMENTSCHEDULE: '/drivermanagement/edit/id',
  COMPLETE_DRIVERMANAGEMENTSCHEDULE: '/drivermanagement/complete/id',
  AUDITS_DRIVERMANAGEMENTSCHEDULE: '/drivermanagement/audit/id',
  DRIVER_MGT_TYPES: '/settings/driverMgtTypes'
};

export const TabKeys = {
  all: 'all',
  scheduled: 'scheduled',
  duenow: 'duenow',
  completed: 'completed',
  cancelled: 'cancelled',
  deleted: 'deleted'
};

export const Tabs = [
  {
    pathame: '/drivermanagement',
    key: TabKeys.all
  },
  {
    pathame: `/drivermanagement/${TabKeys.scheduled}`,
    key: TabKeys.scheduled
  },
  {
    pathame: `/drivermanagement/${TabKeys.duenow}`,
    key: TabKeys.duenow
  },
  {
    pathame: `/drivermanagement/${TabKeys.completed}`,
    key: TabKeys.completed
  },
  {
    pathame: `/drivermanagement/${TabKeys.cancelled}`,
    key: TabKeys.cancelled
  },
  {
    pathame: `/drivermanagement/${TabKeys.deleted}`,
    key: TabKeys.deleted
  }
];

export const MainColumnKeys = {
  DRIVER: 'driver',
  USERNAME: 'username',
  BRANCH: 'branch',
  LICENCE: 'licence',
  SCHEDULES: 'schedules'
};

export const MainColumns = [
  {
    title: 'Driver',
    dataIndex: MainColumnKeys.DRIVER,
    key: MainColumnKeys.NAME
  },
  {
    title: 'Username',
    dataIndex: MainColumnKeys.USERNAME,
    key: MainColumnKeys.DRIVER
  },
  {
    title: 'Branch',
    dataIndex: MainColumnKeys.BRANCH,
    key: MainColumnKeys.BRANCH
  },
  {
    title: 'Licence',
    dataIndex: MainColumnKeys.LICENCE,
    key: MainColumnKeys.LICENCE
  },
  {
    title: 'Schedules',
    width: 110,
    dataIndex: MainColumnKeys.SCHEDULES,
    key: 'counter'
  }
];

export const InnerColumnKeys = {
  STATUS: 'status',
  NAME: 'name',
  TYPE_ID: 'manageTypeId',
  SCHEDULED_BY: 'userId',
  SCHEDULED_DATE: 'schedulesOn',
  ACTIONS: 'actions'
};

export const InnerColumns = [
  {
    title: 'Status',
    dataIndex: InnerColumnKeys.STATUS,
    key: InnerColumnKeys.STATUS
  },
  {
    title: 'Name',
    dataIndex: InnerColumnKeys.NAME,
    key: InnerColumnKeys.NAME
  },
  {
    title: 'Management type',
    dataIndex: InnerColumnKeys.TYPE_ID,
    key: InnerColumnKeys.TYPE_ID
  },
  {
    title: 'Scheduled by',
    dataIndex: InnerColumnKeys.SCHEDULED_BY,
    key: InnerColumnKeys.SCHEDULED_BY
  },
  {
    title: 'Scheduled date',
    dataIndex: InnerColumnKeys.SCHEDULED_DATE,
    key: InnerColumnKeys.SCHEDULED_DATE
  },
  {
    title: 'Actions',
    dataIndex: InnerColumnKeys.ACTIONS,
    key: InnerColumnKeys.ACTIONS,
    width: 95
  }
];

export const EventColumnKeys = {
  DATE: 'date',
  STATUS: 'status'
};

export const EventsColumns = [
  {
    title: 'Date',
    dataIndex: EventColumnKeys.DATE,
    key: EventColumnKeys.DATE
  },
  {
    title: 'Status',
    dataIndex: EventColumnKeys.STATUS,
    key: EventColumnKeys.STATUS
  }
];

export const BUTTON_ACTIONS = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  RESTORE: 'RESTORE',
  COMPLETE: 'COMPLETE',
  DELETE: 'DELETE',
  CANCEL: 'CANCEL',
  AUDIT: 'AUDIT'
};
