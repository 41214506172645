import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import {
  auditsReducer,
  bulkEditAuditsReducer,
  vehicleDeviceAssociationAuditsReducer
} from 'features/audits';
import { companyAlertsReducer } from 'features/company_alerts';
import { contactsReducer } from 'features/contacts';
import { driverManagementReducer } from 'features/driverManagement';
import { ewdStore } from 'features/ewd';
import { fbtReducer } from 'features/fbt';
import geofencesReducer from 'features/geofences/geofencesSlice';
import { scorecardReducer } from 'features/scorecard';
import { sentinelStore } from 'features/sentinel';
import { smartJobsCodesReducer } from 'features/smartJobs';
import { enrolmentsReducer } from 'features/enrolments';
import { SOFT_RESET } from 'features/user/softReset';
import { userAlertsReducer } from 'features/user_alerts';
import { UserAuthToken } from 'features/user/userAuthToken';
import { wifiReducer } from 'features/wifi';
import appStateReducer from 'features/appState/appStateSlice';
import attachmentReducer from 'features/attachments/attachmentSlice';
import cameraInfoReducer from 'features/camera/cameraSlice';
import cameraSnapshotConfigReducer from 'features/cameraSnapshot_config/cameraSnapshotConfigSlice';
import companyReducer from 'features/company/companySlice';
import configurationReducer from 'features/configuration/configurationSlice';
import dashboardReducer from 'features/dashboard/dashboardSlice';
import deviceModelsReducer from 'features/device_models/deviceModelsSlice';
import devicesDeletedReducer from 'features/devices/devicesDeletedSlice';
import devicesMetersReducer from 'features/devices/devicesMetersSlice';
import devicesReducer from 'features/devices/devicesSlice';
import devicesStatsReducer from 'features/devices/devicesStatsSlice';
import deviceTypesReducer from 'features/device_types/deviceTypesSlice';
import documentsReducer from 'features/easydocs/documentsSlice';
import driverCardReducer from 'features/tracking/cardView/driverCardSlice';
import driverLogReducer from 'features/eld/driverLogSlice';
import eldReducer from 'features/eld/eldSlice';
import supportELDReducer from 'features/support/supportELDSlice';
import installationLogsReducer from 'features/installation_logs/InstallationLogsSlice';
import fleetsReducers from 'features/fleets/fleetsSlice';
import foldersReducer from 'features/easydocs/foldersSlice';
import easydocsDevicesReducer from 'features/easydocs/devicesSlice';
import formsReducer from 'features/forms/formsSlice';
import insightsReducer from 'features/insights/insightsSlice';
import journeysReducer from 'features/journeyPlanner/journeysSlice';
import locationsReducer from 'features/locations/locationsSlice';
import locationTypesReducer from 'features/locations/locationTypesSlice';
import logExportReducer from 'features/eld/logExportSlice';
import massConfigurationsReducer from 'features/massManagement/massConfigurationsSlice';
import messagingReducer from 'features/messaging/messagingSlice';
import mqttReducer from 'features/mqtt/mqttSlice';
import notificationsReducer, {
  cleanNotificationsState
} from 'features/notifications/notificationsSlice';
import pageReducer from 'features/page/pageSlice';
import permissionsReducer from 'features/permissions/permissionsSlice';
import pretripChecklistReducer from 'features/pretripChecklist/pretripChecklistSlice';
import inspectionChecklistReducer from 'features/inspectionChecklist/inspectionChecklistSlice';
import regionsReducer from 'features/regions/regionsSlice';
import rolesReducer from 'features/roles/rolesSlice';
import rulesetsReducer from 'features/rulesets/rulesetsSlice';
import schedulesReducer from 'features/vehicleMaintenance/schedulesSlice';
import smartJobs from 'features/smartJobs/smartJobsSlice';
import tachoReducer from 'features/tacho/tachoSlice';
import toastsReducer from 'features/toasts/toastsSlice';
import udtReducer from 'features/eld/udtSlice';
import userGridSettingsReducer from 'features/user/userGridSettingsSlice';
import userPreferencesReducer from 'features/user/userPreferencesSlice';
import userReducer, { logout, initialUser } from 'features/user/userSlice';
import usersReducer from 'features/users/usersSlice';
import vehicleCombinationsReducer from 'features/massManagement/vehicleCombinationsSlice';
import VehicleConfigurationsReducer from 'features/massManagement/vehicleConfigurationsSlice';
import vehicleGridReducer from 'features/tracking/vehicleGridSlice';
import vehicleMntTypesReducer from 'features/vehicleMntTypes/vehicleMntTypesSlice';
import { vehicleModelsReducer } from 'features/vehicles/vehicleModels';
import vehiclesConfigsReducer from 'features/vehicles/vehiclesConfigsSlice';
import vehiclesDeletedReducer from 'features/vehicles/vehiclesDeletedSlice';
import vehiclesMetersReducer from 'features/vehicles/vehiclesMetersSlice';
import vehiclesStatsReducer from 'features/vehicles/vehiclesStatsSlice';
import vehicleTypesReducer from 'features/vehicles/vehicleTypesSlice';
import { meterAlertsReducer } from 'features/company_alerts/meter_alert';
import { companyConfigReducer } from 'features/company_config';
import { bulkImportReducer } from 'features/bulk_management';
import { vehicleMaintenanceInspectionsReducer } from 'features/vehicleMaintenanceInspections';

import { nextgenReducers, nextgenMiddleware } from 'services/nextgen';

const loadState = () => {
  try {
    const localState = localStorage.getItem('state');
    const isLoggedOut = localStorage.getItem('logout');
    const authObj = UserAuthToken.get();

    if (isLoggedOut || authObj == null) {
      return undefined;
    }

    if (localState === null) {
      const initUserObj = Object.assign({}, initialUser);
      initUserObj.current = authObj;
      return {
        user: initUserObj
      };
    }
    const state = JSON.parse(localState);
    state.user.current = authObj;
    return state;
  } catch (err) {
    return undefined;
  }
};

const saveState = state => {
  try {
    const localState = JSON.stringify({
      ...state,
      user: { ...state.user, current: initialUser.current }
    }); //don't store user info
    localStorage.setItem('state', localState);
  } catch (err) {
    console.error(err);
  }
};

const sentinelReducer = sentinelStore.reducer;
const ewdReducer = ewdStore.reducer;

const appReducer = combineReducers({
  ...nextgenReducers,
  appState: appStateReducer,
  attachments: attachmentReducer,
  audits: auditsReducer,
  bulkEditAudits: bulkEditAuditsReducer,
  vehicleDeviceAssociationAudits: vehicleDeviceAssociationAuditsReducer,
  bulkImport: bulkImportReducer,
  cameraInfo: cameraInfoReducer,
  cameraSnapshotConfig: cameraSnapshotConfigReducer,
  companies: companyReducer.companies,
  deletedSubCompanies: companyReducer.deletedSubCompanies,
  companyAlerts: companyAlertsReducer,
  companyConfig: companyConfigReducer,
  meterAlerts: meterAlertsReducer,
  configuration: configurationReducer,
  contacts: contactsReducer,
  dashboardData: dashboardReducer,
  deviceModels: deviceModelsReducer,
  devices: fleetsReducers.devices,
  devicesDeleted: devicesDeletedReducer,
  devicesMeters: devicesMetersReducer,
  devicesStats: devicesStatsReducer,
  deviceTypes: deviceTypesReducer,
  documents: documentsReducer,
  driverCard: driverCardReducer,
  driverLogData: driverLogReducer,
  driverManagement: driverManagementReducer,
  eldData: eldReducer,
  supportELDData: supportELDReducer,
  installationLogsData: installationLogsReducer,
  entities: combineReducers({ devices: devicesReducer }),
  ewd: ewdReducer,
  fbt: fbtReducer,
  fleets: fleetsReducers.fleets,
  deletedFleets: fleetsReducers.deletedFleets,
  folders: foldersReducer,
  easydocsDevices: easydocsDevicesReducer, // Add easydocsDevicesReducer here
  forms: formsReducer,
  insights: insightsReducer,
  geofences: geofencesReducer,
  journeys: journeysReducer,
  locations: locationsReducer,
  locationTypes: locationTypesReducer,
  logExportData: logExportReducer,
  massConfigurations: massConfigurationsReducer,
  messages: messagingReducer,
  mqtt: mqttReducer,
  notifications: notificationsReducer,
  page: pageReducer,
  permissions: permissionsReducer,
  pretripChecklists: pretripChecklistReducer,
  inspectionChecklists: inspectionChecklistReducer,
  regions: regionsReducer,
  roles: rolesReducer,
  rulesets: rulesetsReducer,
  schedules: schedulesReducer,
  inspections: vehicleMaintenanceInspectionsReducer,
  scorecard: scorecardReducer,
  sentinel: sentinelReducer,
  smartJobs,
  smartJobsCodes: smartJobsCodesReducer,
  enrolments: enrolmentsReducer,
  tachoData: tachoReducer,
  toasts: toastsReducer,
  udtData: udtReducer,
  user: userReducer,
  userAlerts: userAlertsReducer,
  userGridSettings: userGridSettingsReducer,
  userPreferences: userPreferencesReducer,
  users: usersReducer,
  vehicleCombinations: vehicleCombinationsReducer,
  vehicleConfigurations: VehicleConfigurationsReducer,
  vehicleGrid: vehicleGridReducer,
  vehicleMntTypes: vehicleMntTypesReducer,
  vehicleModels: vehicleModelsReducer,
  vehicles: fleetsReducers.vehicles,
  vehiclesConfigs: vehiclesConfigsReducer,
  vehiclesDeleted: vehiclesDeletedReducer,
  vehiclesMeters: vehiclesMetersReducer,
  vehiclesStats: vehiclesStatsReducer,
  vehicleTypes: vehicleTypesReducer,
  wifi: wifiReducer
});

export const rootReducer = (state, action) => {
  if (action.type === logout?.type) {
    // Empty all state on log out.
    state = undefined;
  }
  if (action.type === SOFT_RESET) {
    // Empty all state except user
    state = {
      user: Object.assign(
        {},
        {
          ...initialUser,
          current: state.user.current,
          meta: { ...state.user.meta, fetchingUserInfo: false },
          currentAdminLoggedInUser: state.user.currentAdminLoggedInUser,
          currentAdminLoggedInUserInfo: state.user.currentAdminLoggedInUserInfo
        }
      )
    };
  }

  return appReducer(state, action);
};

const logoutMiddleware = store => next => action => {
  if (action.type === logout.type) {
    next(action);
    store.dispatch(cleanNotificationsState());
  } else {
    return next(action);
  }
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
  middleware: [
    ...getDefaultMiddleware({ thunk: true, immutableCheck: false, serializableCheck: false }),
    ...nextgenMiddleware,
    logoutMiddleware
  ]
});

setupListeners(store.dispatch);

store.subscribe(() => {
  const { appState, companies, user, permissions } = store.getState();
  saveState({ appState, companies, user, permissions });
});

export const setupTestStore = (preloadedState, reducer) => {
  return configureStore({
    reducer: reducer || rootReducer,
    preloadedState,
    middleware: [
      ...getDefaultMiddleware({ thunk: true, immutableCheck: false, serializableCheck: false }),
      ...nextgenMiddleware,
      logoutMiddleware
    ]
  });
};

export default store;
