import { useCallback, useState } from 'react';
import moment from 'moment';
import { services, useCan, entities } from 'features/permissions';
import { useDevices } from 'features/fleets/fleetsSlice';

export function useRequestFootage() {
  const can = useCan();
  const devices = useDevices();
  const canRequest = useCallback(
    deviceId => {
      const device = deviceId && devices?.find(d => String(d.id) === String(deviceId));
      return (
        !!device?.id &&
        device.type?.code === 'CAMERA' &&
        can({
          everyService: [services.CAMERA],
          everyEntity: [entities.VIDEO, entities.VIDEO_CREATE]
        })
      );
    },
    [devices]
  );

  const [requetVideo, setRequestVideo] = useState({
    showModal: false,
    deviceId: null,
    vehicleId: null,
    dateTime: null
  });

  const onRequest = useCallback(
    (deviceId, timeAt) => {
      const device = devices?.find(d => String(d.id) === String(deviceId));
      if (device) {
        setRequestVideo({
          showModal: true,
          deviceId: device?.id,
          vehicleId: device?.vehicle?.id,
          dateTime: timeAt && moment.isMoment(timeAt) ? timeAt : null
        });
      }
    },
    [devices]
  );

  const resetRequest = useCallback(
    () =>
      setRequestVideo({
        showModal: false,
        deviceId: null,
        vehicleId: null
      }),
    []
  );

  return {
    canRequest,
    onRequest,
    onClose: resetRequest,
    ...requetVideo
  };
}
