import React, { useState, useEffect } from 'react';
import { InfoWindow } from 'react-google-maps';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Link } from 'react-router-dom';
import moment from 'moment';

import { Divider, Button } from 'antd';
import { services } from 'features/permissions';
import { toDirectionString } from 'utils/geo';
import { format, formatTimeago } from 'utils/dates';
import { BUTTON_IDS } from 'utils/globalConstants';

import styles from './InfoWindows.module.scss';

export const VehicleInfoWindow = ({
  canUseMessaging,
  canUseTacho,
  canUseSmartJobs,
  company,
  device,
  runsheets,
  onClose,
  onViewDetailsClicked,
  onRouteToClicked,
  localization,
  showInfoWindowActions,
  deviceUpdates,
  userPreferences,
  userPreferencesFetched,
  vehicleStats,
  ...props
}) => {
  const { t } = useTranslation();

  const [speed, setSpeed] = useState();
  const [direction, setDirection] = useState();
  const [location, setLocation] = useState();
  const [lastEventAt, setLastEventAt] = useState();
  const [remainingDriveTime, setRemainingDriveTime] = useState();
  const [driverStatus, setDriverStatus] = useState();
  const [nextBreakBy, setNextBreakBy] = useState();
  const [isSmartNav3D, setIsSmartNav3d] = useState(false);
  const [evSoc, setEvSoc] = useState(null);
  const [evRange, setEvRange] = useState(null);

  const tags = device.vehicle?.tags?.split(',') || [];

  // useEffect(() => {
  //   console.debug('VehicleInfoWindow - runsheets', runsheets);
  // }, [runsheets]);

  useEffect(() => {
    if (!!deviceUpdates) {
      setSpeed(deviceUpdates.speed);
      setDirection(deviceUpdates.dir);
      setLocation(deviceUpdates.location);
      setLastEventAt(deviceUpdates.lastEventAt);
    } else {
      setSpeed(device?.deviceStats?.gps?.Spd);
      setDirection(device?.deviceStats?.gps?.Dir);
      setLocation(device?.deviceStats?.location);
      setLastEventAt(device?.deviceStats?.lastEventAt);
    }

    setIsSmartNav3d(company.features?.some(feature => feature.code === services.SMARTNAV3D));
    setRemainingDriveTime(
      vehicleStats?.tachoFatigueStatusEvent?.maxDailyDrive -
        (vehicleStats?.tachoFatigueStatusEvent?.driveDaily || 0)
    );
    setDriverStatus(vehicleStats?.tachoFatigueStatusEvent?.activity);
    setNextBreakBy(
      Math.min(
        moment().add(new Date(), vehicleStats?.tachoFatigueStatusEvent?.remainingDriveTime),
        vehicleStats?.tachoFatigueStatusEvent?.nextDailyRestDueAt,
        vehicleStats?.tachoFatigueStatusEvent?.nextWeeklyRestDueAt
      )
    );
    setEvSoc(vehicleStats?.evDeviceMeters?.find(e => e.type === 'hv_soc').value?.toFixed(1));
    setEvRange(vehicleStats?.evDeviceMeters?.find(e => e.type === 'hv_range').value);
  }, [device, vehicleStats, deviceUpdates]);

  return (
    <InfoWindow onCloseClick={onClose} {...props}>
      <BrowserRouter>
        <div className={styles.infoWindow}>
          <div className={styles.infoWindowTitle}>{t('Tracking.QuickView')}</div>
          <div>
            <label>{t('Common.Vehicle')}</label>
            <span>{device.vehicleName ? device.vehicleName : '-'}</span>
          </div>
          <div>
            <label>{t('Common.Device')}</label>
            <span>{device.name ? device.name : '-'}</span>
          </div>
          <div>
            <label>{t('Common.Driver')}</label>
            <span>{device.driverName}</span>
          </div>
          <div>
            <label>{t('Common.Location')}</label>
            <span>{location}</span>
          </div>
          <div>
            <label>{t('Tracking.Speed')}</label>
            <span>
              {speed
                ? localization.convertSpeed(speed) + ' ' + localization.formats.speed.unit_per_hour
                : '-'}
            </span>
          </div>
          <div>
            <label>{t('Tracking.Direction')}</label>
            <span>{direction ? toDirectionString(direction) : '-'}</span>
          </div>
          <div>
            <label>{t('Common.LastUpdate')}</label>
            <span>{lastEventAt ? formatTimeago(lastEventAt) : '-'}</span>
          </div>
          {evSoc >= 0 && (
            <div>
              <label>{t('Tracking.EV.Soc')}</label>
              <span>{`${evSoc}%`}</span>
            </div>
          )}
          {evRange >= 0 && (
            <div>
              <label>{`${t('Tracking.EV.Range')} (${localization.formats.speed.unit})`}</label>
              <span>{localization.formatDistance(evRange)}</span>
            </div>
          )}
          {device.lastRuc && (
            <div>
              <label>{t('Tracking.RUCStatus')}</label>
              <span>{device.lastRuc}</span>
            </div>
          )}
          {device.lastEdr && (
            <div>
              <label>{t('Tracking.EDRStatus')}</label>
              <span>{device.lastEdr}</span>
            </div>
          )}
          {tags.length > 0 && (
            <div>
              <label>{t('Tracking.Tags')}</label>
              <span>
                {tags.map(tag => (
                  <div className={styles.tag}>{tag}</div>
                ))}
              </span>
            </div>
          )}
          {canUseTacho && (!!remainingDriveTime || !!driverStatus || !!nextBreakBy) && (
            <>
              <Divider className={styles.divider} />
              {!!remainingDriveTime && (
                <div>
                  <label>{t('Common.RdtToday')}</label>
                  <span>{moment.utc(remainingDriveTime * 1000).format('h [hrs] m [mins]')}</span>
                </div>
              )}
              {!!driverStatus && (
                <div>
                  <label>{t('Common.DriverStatus')}</label>
                  <span className={styles[`driveStatus-${driverStatus?.toLowerCase()}`]}>
                    <i />
                    {driverStatus}
                  </span>
                </div>
              )}
              {!!nextBreakBy && (
                <div>
                  <label>{t('Common.NextBreakBy')}</label>
                  <span>{format(nextBreakBy, localization.formats.time.formats.dby_imp)}</span>
                </div>
              )}
            </>
          )}
          {canUseSmartJobs && runsheets.length > 0 && (
            <div>
              <label>
                {runsheets.length === 1 ? t('SmartJobs.Runsheet') : t('SmartJobs.Runsheets')}
              </label>
              <div style={{ display: 'block' }}>
                {runsheets.map(runsheet => (
                  <Link
                    style={{ display: 'block' }}
                    target="_blank"
                    to={`/smartjobs/id/${runsheet.id}`}
                  >
                    {`${runsheet.name ? runsheet.name : ''}${
                      runsheet.name ? ' (' + runsheet.externalId + ')' : runsheet.externalId
                    }`}
                  </Link>
                ))}
              </div>
            </div>
          )}
          {showInfoWindowActions && (
            <footer className={styles.footer}>
              <Button
                className={styles.viewDetailsBtn}
                type="primary"
                onClick={onViewDetailsClicked}
                id={BUTTON_IDS.vehicleInfoView}
              >
                {t('Tracking.ViewDetails')}
              </Button>
              {!!device.messagingDevice &&
                !!device.routeToDevice &&
                isSmartNav3D &&
                canUseMessaging && (
                  <Button
                    className={styles.viewDetailsBtn}
                    type="secondary"
                    onClick={() => {
                      onRouteToClicked(device);
                    }}
                    id={BUTTON_IDS.vehicleInfoRouteTo}
                  >
                    {t('Tracking.RouteTo')}
                  </Button>
                )}
            </footer>
          )}
        </div>
      </BrowserRouter>
    </InfoWindow>
  );
};
