import React from 'react';
import styles from './Icon.module.scss';

export const Icon = ({ srcIcon, cb, className = '' }) => {
  return (
    <div onClick={cb} className={className || styles.icon}>
      <img src={srcIcon} alt="Icon" />
    </div>
  );
};
