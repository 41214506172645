/* global google */
import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Alert,
  Tooltip as AntDTooltip,
  Switch,
  Row as AntRow,
  Col as AntCol,
  Form as AntForm,
  Space
} from 'antd';

import FormInput from 'components/form/form-input/FormInput';
import FormColor from 'components/form/form-color/FormColor';
import FormTitle from 'components/form/form-title/FormTitle';
import FormSelect from 'components/form/form-select/FormSelect';
import SearchableListMultiSelect from 'components/form/searchable-list-multi-select/SearchableListMultiSelect';
import { GeoSuggest } from 'components/GeoSuggest';
import { WeeklyScheduleSelector } from 'components/ScheduleSelector/WeeklyScheduleSelector';
import { getFleetsByCompany } from 'containers/Administration/Fleets/APICalls';
import Map, { MapMode } from 'components/map/Map';
import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { Button, Tooltip, TimePicker, Select } from 'components/ant';
import { LoadingTable } from 'components/grid/LoadingTable';

import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import {
  useCompanies,
  useCurrentCompany,
  useRedirectToMainFeaturePageOnCompanyChange,
  useIsCompanyKeyDifferent
} from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';
import {
  useGeofences,
  addGeofence,
  updateGeofence,
  useIsFetching
} from 'features/geofences/geofencesSlice';
import { useLocations } from 'features/locations/locationsSlice';
import { usePretripChecklists } from 'features/pretripChecklist/pretripChecklistSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { LocalizationUtil } from 'features/localization/localization';
import { useDocuments } from 'features/easydocs/documentsSlice';
import { useServicePermissons } from 'features/permissions/permissionsSlice';
import { MPTrackingEvents, useFormUpdatedFields } from 'features/mixpanel';
import { useCan } from 'features/permissions/canHooks';
import { useEnabledEventTypesCombined } from 'features/scorecard/hooks';
import { Can, services } from 'features/permissions';
import { GEOFENCE_DEFAULT_COLORS, getGeofencePoints } from 'features/geofences/geofencesUtil';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';

import {
  initialValues,
  validationSchema,
  GEOFENCE_SHAPES,
  TOOLPTIP_TEXTS,
  TOOLTIP_ICON_CLASS,
  EMPTY_ARRAY,
  Paths,
  modeGeofenceEntryOptions
} from './constants';
import iconPalm from '../../../static/images/icons/palm.svg';
import iconCircle from '../../../static/images/icons/circle.svg';
import iconRectangle from '../../../static/images/icons/rectangle.svg';
import iconPolygon from '../../../static/images/icons/polygon.svg';
import { multiplyIfValue } from 'utils/methods';
import { helpers } from './helpers';
import { getRoundValue } from 'utils/methods';
import { canHistoryGoBack } from 'utils/methods';
import {
  fetchLocationsOnCompanyChange,
  setLocationFromCoordinateSearch,
  toActivePeriodFormField,
  toActivePeriodPayload
} from './helpers';
import {
  getEventTypesListForSelect,
  getScorecardEventsFromFeatures,
  getNonScorecardEventsFromFeatures
} from '../../Scorecard/helpers';

import './Geofences.scss';
import styles from './Geofences.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const submitData = (
  values,
  userCheckedFleets,
  actions,
  dispatch,
  action,
  geofenceId,
  formCheckEventParameters,
  t,
  localization,
  setFormValue
) => {
  const ignitionWithinAGeofence = formCheckEventParameters[3];
  const geofenceEntry = formCheckEventParameters[4];
  if (values.points.length < 3) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: t('GeofencesFeature.ToastMessages.PleaseFinishDrawing')
      })
    );
    actions.setSubmitting(false);
    return;
  }
  let driverNotificationArray = [];
  if (
    geofenceEntry.value &&
    (values.pretripEntry ||
      values.document ||
      values.driverMessageEntry ||
      values.mode ||
      values.timeFrom ||
      values.timeTo)
  ) {
    driverNotificationArray.push({
      TriggerEvent: 'GEO-EN',
      Ptc: {
        Id: values.pretripEntry
      },
      Document: {
        Id: values.document
      },
      Message: values.driverMessageEntry,
      Mode: values.mode,
      ActivePeriods: {
        All: [
          values.timeFrom ? moment(values.timeFrom, 'hh:mm a').format('hh:mm a') : '12:00 am',
          values.timeTo ? moment(values.timeTo, 'hh:mm a').format('hh:mm a') : '12:00 am'
        ]
      }
    });
  }

  if (ignitionWithinAGeofence.value && (values.pretripIgnition || values.driverMessageIgnition)) {
    driverNotificationArray.push({
      TriggerEvent: 'IOR',
      Ptc: {
        Id: values.pretripIgnition
      },
      Message: values.driverMessageIgnition
    });
  }

  if (ignitionWithinAGeofence.value && !values.pretripIgnition && !values.driverMessageIgnition) {
    driverNotificationArray.push({
      TriggerEvent: 'IOR',
      Message: ''
    });
  }

  const submitValues = {
    name: values.name,
    companyId: values.companyId,
    type: values.type,
    status: 'ENABLED',
    ...(values.locationId && { location: { id: values.locationId } }),
    fleets: userCheckedFleets.reduce((accumulator, fleet) => {
      if (fleet.checked) {
        accumulator.push(fleet.value);
      }
      return accumulator;
    }, []),
    thresholdOvertime: formCheckEventParameters[2].value
      ? multiplyIfValue(values.thresholdOvertime, 60)
      : -1,
    thresholdUndertime: formCheckEventParameters[1].value
      ? multiplyIfValue(values.thresholdUndertime, 60)
      : -1,
    thresholdSpeed:
      formCheckEventParameters[0].value && values.thresholdSpeed
        ? localization.formats.speed.unit_per_hour === 'mph'
          ? getRoundValue(LocalizationUtil.miletokm(parseFloat(values.thresholdSpeed)))
          : values.thresholdSpeed
        : -1,
    offsetSpeed:
      formCheckEventParameters[0].value && values.offsetSpeed
        ? localization.formats.speed.unit_per_hour === 'mph'
          ? getRoundValue(LocalizationUtil.miletokm(parseFloat(values.offsetSpeed)))
          : values.offsetSpeed
        : -1,
    thresholdDurationSpeed:
      formCheckEventParameters[0].value && values.thresholdDurationSpeed
        ? values.thresholdDurationSpeed
        : -1,
    speedAssist: values.speedAssist,
    driverNotification:
      driverNotificationArray.length > 0 ? JSON.stringify(driverNotificationArray) : null,
    style: JSON.stringify({ colour: values.colour, weight: values.weight }),
    shape: {
      type: 'POLYGON',
      points: values.points
    },
    features: values.excludedScorecardEvents?.concat(values.otherFeatureItems ?? []) || [],
    updatedAt: moment().format(),
    ...(action === 'add' && { createdAt: moment().format() }),
    ...(action === 'edit' && { id: geofenceId }),
    ...toActivePeriodPayload(values?.activeTime)
  };

  const bounds = new google.maps.LatLngBounds();
  const fullPoints = getGeofencePoints(submitValues, true);

  (fullPoints || []).forEach(p => {
    bounds.extend(new google.maps.LatLng(p.Lat, p.Lng));
  });

  submitValues.centroid = {
    lat: bounds.getCenter().lat(),
    lng: bounds.getCenter().lng()
  };

  setFormValue({ ...submitValues, submit: true });
  action === 'add' ? dispatch(addGeofence(submitValues)) : dispatch(updateGeofence(submitValues));

  return submitValues;
};

export const GeofenceForm = ({ action, coordinates, drawerView, id, onFinish, ...props }) => {
  const path = window.location.pathname;
  const geofenceId =
    action === 'add'
      ? 'newGeofence'
      : id || path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const can = useCan();
  const currentCompany = useCurrentCompany();
  const companies = useCompanies();
  const geofences = useGeofences();
  const isFetchingGeofence = useIsFetching();
  const geofenceNames = geofences.map(geo => geo.name);
  const localization = useLocalization();
  const [geofencesForMap, setGeofencesForMap] = useState([]);
  const [mapZoom, setMapZoom] = useState(8);
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
  const [initialGeofencesForMap, setInitialGeofencesForMap] = useState([]);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [fleetsLoaded, setFleetsLoaded] = useState(true);
  const userKey = useUserKey();
  const servicesPermissions = useServicePermissons();
  const allowedTypes = helpers.getAllowedTypes(servicesPermissions);
  const colWidth = drawerView ? 24 : 12;

  const hasCompanyChanged = useIsCompanyKeyDifferent('geofences');
  useRedirectToMainFeaturePageOnCompanyChange('/settings/geofences');

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  const changeLocation = id => {
    const selectedLocation = locations.find(loc => loc.id === id);
    if (selectedLocation && selectedLocation.GPS) {
      setSelectedLocation(selectedLocation);
    } else {
      if (action === 'edit') {
        setSelectedLocation(undefined);
      } else {
        //need to bring back to original view when unselect location
        setSelectedLocation({
          GPS: coordinates[currentCompany.country],
          isMarkerHidden: drawerView && selectedLocation === undefined
        });
      }
    }
  };

  const locations = useLocations();
  const [selectedLocation, setSelectedLocation] = useState({
    GPS: coordinates[currentCompany.country],
    isMarkerHidden: true
  });
  const [isReset, setIsReset] = useState(true);

  const [filteredLocationsByCompanyId, setFilteredLocations] = useState([]);
  useEffect(() => {
    setFilteredLocations(helpers.getFilteredLocations(locations));
  }, [locations.length]);

  const pretripChecklist = usePretripChecklists();
  const formPretripOptions = pretripChecklist
    .filter(pre => {
      return pre.company.id === currentCompany.id;
    })
    .map(pre => {
      return { label: pre.name.trim(), value: pre.id };
    })
    .sort(function(a, b) {
      if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
      }
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      return 0;
    });

  const easyDocs = useDocuments();
  const formDocsOptions = easyDocs
    .filter(doc => doc.companyId === currentCompany.id)
    .map(doc => {
      return {
        label: doc.name,
        value: doc.id
      };
    })
    .sort((a, b) => a.name?.localeCompare(b.name));

  const allFleets = useFleets();
  const [fleets, setFleets] = useState([]);
  const [userCheckedFleets, setUserCheckedFleets] = useState(fleets);

  // Can only view and edit excluded scorecard events with proper permissions
  const canViewScorecardItems = helpers.getCanViewScorecardItems(can);
  const canEditExludedScorecardEvents = helpers.getCanEditExludedScorecardEvents(can);

  const useScorecardEventTypes = useEnabledEventTypesCombined(currentCompany.id);
  const scorecardEventTypesFiltered = getEventTypesListForSelect(
    useScorecardEventTypes,
    localization,
    t
  );

  useEffect(() => {
    const formFleets = [...allFleets]
      .filter(fleet => fleet?.id)
      .map(fleet => {
        const companyFleet = companies.find(company => company.id === fleet.company?.id);
        return {
          value: fleet.id,
          label: fleet?.name ? `${fleet.name} (${companyFleet?.name || ''})` : '',
          //if in edit, check the ones that are already in the geofence
          checked: formikInitialValues.fleets.map(editFleet => editFleet.id).includes(fleet.id)
        };
      });
    formFleets.push({
      value: -1,
      label: t('Common.NoFleet'),
      checked: formikInitialValues.fleets.map(editFleet => editFleet.id).includes(-1)
    });
    setFleets(formFleets);
  }, [allFleets, formikInitialValues]);

  const [formCheckEventParameters, setFormCheckEventParameters] = useState([
    { label: t('GeofencesFeature.Speed'), value: false, id: 'speed' },
    { label: t('GeofencesFeature.Undertime'), value: false, id: 'undertime' },
    { label: t('GeofencesFeature.Overtime'), value: false, id: 'overtime' },
    { label: t('GeofencesFeature.IgnitionOn'), value: false, id: 'ignition' },
    { label: t('GeofencesFeature.GeofenceEntry'), value: false, id: 'entry' }
  ]);
  const onCheckBoxChange = (id, setFieldValue) => {
    const formCheckEventParametersCopy = [...formCheckEventParameters];
    formCheckEventParametersCopy.forEach(checkBox => {
      if (checkBox.id === id) {
        checkBox.value = checkBox.value ? false : true;
      }
    });
    if (id === 'entry' && formCheckEventParametersCopy[4].value) {
      setFieldValue('mode', 'DISMISS');
    }
    if (id === 'entry' && !formCheckEventParametersCopy[4].value) {
      setFieldValue('mode', null);
    }
    setFormCheckEventParameters(formCheckEventParametersCopy);
  };

  //Update checkboxes on edit scenario
  useEffect(() => {
    const formCheckEventParametersCopy = [...formCheckEventParameters];
    const {
      thresholdSpeed,
      offsetSpeed,
      thresholdDurationSpeed,
      thresholdUndertime,
      thresholdOvertime,
      driverNotification,
      driverMessageIgnition,
      driverMessageEntry,
      mode,
      timeFrom,
      pretripEntry,
      document,
      timeTo
    } = formikInitialValues;
    const driverNotificationEdit = driverNotification ? JSON.parse(driverNotification) : [];
    const driverIgnition = driverNotificationEdit.find(
      ({ TriggerEvent }) => TriggerEvent === 'IOR'
    );
    if (thresholdSpeed || offsetSpeed || thresholdDurationSpeed) {
      formCheckEventParametersCopy[0].value = true;
    }
    if (thresholdUndertime) {
      formCheckEventParametersCopy[1].value = true;
    }
    if (thresholdOvertime) {
      formCheckEventParametersCopy[2].value = true;
    }
    if (driverMessageIgnition || driverIgnition) {
      formCheckEventParametersCopy[3].value = true;
    }
    if (driverMessageEntry || mode || timeFrom || timeTo || document || pretripEntry) {
      formCheckEventParametersCopy[4].value = true;
    }
    setFormCheckEventParameters(formCheckEventParametersCopy);
  }, [formikInitialValues]);

  const history = useHistory();
  const locationId =
    typeof history.location.state !== 'undefined' && history.location.state.location
      ? history.location.state.location.id
      : '';

  const handleError = useCallback(
    err => {
      if (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err
          })
        );
      }
      if (history.location.pathname !== Paths.Default) {
        history.replace(Paths.Default);
      }
    },
    [history, dispatch]
  );

  const onCompanyChange = (company, setFieldValue) => {
    setFleetsLoaded(false);
    setFieldValue && setFieldValue('companyId', company);
    if (!company) {
      return;
    }
    // update the map
    const selectedCompany = companies.find(c => Number(c.id) === Number(company));
    if (selectedCompany?.country) {
      setSelectedLocation({
        ...selectedLocation,
        GPS: coordinates[selectedCompany.country]
      });
    }
    //update locations in form
    dispatch(fetchLocationsOnCompanyChange(company, setFilteredLocations));

    getFleetsByCompany(company, userKey).then(newFleets => {
      const formFleets = [...newFleets]
        .filter(fleet => fleet?.id)
        .map(fleet => {
          const companyFleet = companies.find(company => company.id === fleet.company?.id);
          return {
            value: fleet.id,
            label: fleet?.name ? `${fleet.name} (${companyFleet?.name || ''})` : '',
            checked: false
          };
        });
      setFleets(formFleets);
      setFleetsLoaded(true);
    });
  };

  const onFieldChange = (field, value, setFieldValue) => {
    if (field !== 'locationId') {
      setIsReset(false);
    }
    setFieldValue(field, value);
  };

  const onEditModeChangeGeofenceStyle = (field, value) => {
    if (action !== 'edit' && geofencesForMap.length !== 1) {
      return;
    }
    let styleGeofenceCopy = geofencesForMap[0].style;
    const styleJSON = JSON.parse(styleGeofenceCopy);
    styleJSON[field] = value;
    styleGeofenceCopy = JSON.stringify(styleJSON);
    geofencesForMap[0].style = styleGeofenceCopy;
  };

  // Styles for map drawing
  const optionsForThickness = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 }
  ];

  const [shapeDrawn, setShapeDrawn] = useState(React.createRef());
  const [shouldDrawingShapeChange, setShouldDrawingShapeChange] = useState(true);

  useEffect(() => {
    if (shouldDrawingShapeChange) {
      return;
    }
    const rootElement = document.getElementById('root');
    rootElement.addEventListener('click', eventListenerMethod);
    return () => {
      rootElement.removeEventListener('click', eventListenerMethod);
    };
  });

  const onGeofenceDrawn = shape => {
    if (shape.type === 'polygon') {
      const isIntersecting = helpers.anyOverlap(shape.getPath().getArray());
      if (isIntersecting) {
        helpers.notificationOverlap(dispatch);
      }
    }

    setShapeDrawn(shape);
    setShouldDrawingShapeChange(true);

    changeValueFormMethod('shape', null);
    changeValueFormMethod('points', shape.points);
    setGeofencesForMap([
      {
        id: -1,
        shape: {
          type: 'POLYGON',
          points: shape.points
        },
        type: 'USER',
        style: JSON.stringify({ colour: shape.strokeColor, weight: shape.strokeWeight }),
        isEditable: true
      }
    ]);
    shape.setMap(null);
  };

  const onGeofenceEdited = (shape, previousStyle) => {
    const isIntersecting = helpers.anyOverlap(shape.getPath().getArray());
    if (isIntersecting) {
      helpers.notificationOverlap(dispatch);
    }
    const points = shape
      .getPath()
      .getArray()
      .map(point => {
        return {
          Lat: point.lat(),
          Lng: point.lng()
        };
      });
    setShapeDrawn(shape);
    changeValueFormMethod('points', points);
    setGeofencesForMap([
      {
        id: -1,
        shape: {
          type: 'POLYGON',
          points: points
        },
        type: 'USER',
        style: JSON.stringify({ colour: previousStyle.colour, weight: previousStyle.weight }),
        isEditable: true
      }
    ]);
  };

  const setNewLocationFromSearch = place => {
    setIsReset(true);
    setSelectedLocation({ GPS: { Lat: place.lat, Lng: place.lng }, isMarkerHidden: false });
    setMapZoom(20);
  };

  const resetDraw = () => {
    if (action === 'edit') {
      const { dataToEdit } = history.location.state;
      if (dataToEdit.location.id) {
        changeLocation(dataToEdit.location.id);
      } else {
        setSelectedLocation(selectedLocation);
      }
      changeValueFormMethod('points', formikInitialValues.points);
      changeValueFormMethod('shape', formikInitialValues.shape);
      changeValueFormMethod('colour', formikInitialValues.colour);
      changeValueFormMethod('weight', formikInitialValues.weight);
    } else {
      setIsReset(true);
      setSelectedLocation(selectedLocation);
      changeValueFormMethod('points', []);
      changeValueFormMethod('shape', null);
      changeValueFormMethod('colour', GEOFENCE_DEFAULT_COLORS.NON_MANAGED);
      changeValueFormMethod('weight', 2);
    }
    setGeofencesForMap(initialGeofencesForMap);

    changeValueFormMethod('lat', '');
    changeValueFormMethod('lng', '');
    if (shapeDrawn.current !== null) {
      shapeDrawn.setMap(null);
    }
  };

  // save setFieldValue function
  let changeValueFormMethod;

  useEffect(() => {
    const parsedId = parseInt(geofenceId);
    if (action === 'edit' && (parsedId <= 0 || isNaN(parsedId))) {
      handleError(t('Common.Invalid Request ID'));
      return;
    }

    if (action === 'edit' && isFetchingGeofence) return;

    const data = geofences.find(geofence => geofence.id === parsedId);
    if (action === 'edit' && !data) {
      handleError(t('Common.Invalid Request ID'));
      return;
    }
    let driverNotificationEdit;
    let driverIgnition;
    let driverGeofenceEntry;
    let diverGeofenceEntryMode;
    let driverGeofenceEntryPeriods;
    let styleEdit;
    let colorEdit;
    let weightEdit;
    if (data && data.driverNotification) {
      driverNotificationEdit = JSON.parse(data.driverNotification);
      driverIgnition = driverNotificationEdit.find(({ TriggerEvent }) => TriggerEvent === 'IOR');
      driverGeofenceEntry = driverNotificationEdit.find(
        ({ TriggerEvent }) => TriggerEvent === 'GEO-EN'
      );
      if (driverGeofenceEntry) {
        diverGeofenceEntryMode = driverGeofenceEntry.Mode;
      }
      if (
        driverGeofenceEntry &&
        driverGeofenceEntry.ActivePeriods &&
        driverGeofenceEntry.ActivePeriods.All &&
        driverGeofenceEntry.ActivePeriods.All.length > 0
      ) {
        driverGeofenceEntryPeriods = driverGeofenceEntry.ActivePeriods.All;
      }
    }
    const newShapePoints = getGeofencePoints(data, true).map(
      p => new google.maps.LatLng(p.Lat, p.Lng)
    );

    if (data && data.style) {
      styleEdit = JSON.parse(data.style);
      if (styleEdit.colour) {
        colorEdit = styleEdit.colour;
      }
      if (styleEdit.weight) {
        weightEdit = styleEdit.weight;
      }
    }
    if (data && data.style && data.shape) {
      setGeofencesForMap([
        {
          id: data.id,
          shape: data.shape,
          style: data.style,
          type: data.type,
          isEditable: true
        }
      ]);
      setInitialGeofencesForMap([
        {
          id: data.id,
          shape: data.shape,
          style: data.style,
          type: data.type,
          isEditable: true
        }
      ]);
    }
    if (data) {
      if (data.location.id) {
        changeLocation(data.location.id);
      } else {
        setSelectedLocation(undefined);
      }
      setFormikInitialValues({
        ...initialValues,
        locationId: (data.location && data.location.id) || '',
        ...data,
        type: data.type || 'USER',
        thresholdSpeed:
          data.thresholdSpeed !== -1
            ? localization.formats.speed.unit_per_hour === 'mph'
              ? Math.round(LocalizationUtil.kmtomile(parseFloat(data.thresholdSpeed)))
              : data.thresholdSpeed
            : '',
        offsetSpeed:
          data.offsetSpeed !== -1
            ? localization.formats.speed.unit_per_hour === 'mph'
              ? Math.round(LocalizationUtil.kmtomile(parseFloat(data.offsetSpeed)))
              : data.offsetSpeed
            : '',
        thresholdDurationSpeed:
          data.thresholdDurationSpeed !== -1 ? data.thresholdDurationSpeed : '',
        thresholdUndertime:
          data.thresholdUndertime !== -1 && !isNaN(parseInt(data.thresholdUndertime))
            ? data.thresholdUndertime / 60
            : '',
        thresholdOvertime:
          data.thresholdOvertime !== -1 && !isNaN(parseInt(data.thresholdOvertime))
            ? data.thresholdOvertime / 60
            : '',
        pretripIgnition:
          driverIgnition && driverIgnition.Ptc && driverIgnition.Ptc.Id
            ? parseInt(driverIgnition.Ptc.Id, 10)
            : '',
        driverMessageIgnition: driverIgnition ? driverIgnition.Message : '',
        pretripEntry:
          driverGeofenceEntry && driverGeofenceEntry.Ptc && driverGeofenceEntry.Ptc.Id
            ? parseInt(driverGeofenceEntry.Ptc.Id, 10)
            : '',
        document:
          driverGeofenceEntry && driverGeofenceEntry.Document && driverGeofenceEntry.Document.Id
            ? parseInt(driverGeofenceEntry.Document.Id, 10)
            : '',
        driverMessageEntry: driverGeofenceEntry ? driverGeofenceEntry.Message : '',
        mode: diverGeofenceEntryMode ? diverGeofenceEntryMode : '',
        timeFrom: driverGeofenceEntryPeriods ? driverGeofenceEntryPeriods[0] : '',
        timeTo: driverGeofenceEntryPeriods ? driverGeofenceEntryPeriods[1] : '',
        shape: null,
        colour: colorEdit ? colorEdit : GEOFENCE_DEFAULT_COLORS.NON_MANAGED,
        weight: weightEdit ? parseInt(weightEdit, 10) : 1,
        points: data.shape ? data.shape.points : [],
        excludedScorecardEvents: getScorecardEventsFromFeatures(data.features),
        otherFeatureItems: getNonScorecardEventsFromFeatures(data.features),
        fleets: data.fleets.map(fleet => ({
          value: fleet.id,
          label: fleet.name,
          checked: true
        })),
        ...toActivePeriodFormField(data)
      });
      onGeofenceEdited(new google.maps.Polygon({ path: newShapePoints }), {
        colour: colorEdit,
        weight: weightEdit
      });
      // on edit, update company related fields if edit company is diff than the topNav company
      if (data && data.companyId !== currentCompany?.id) {
        onCompanyChange(data.companyId);
      }
    }
  }, [geofences, geofenceId, dispatch, action, t, handleError, isFetchingGeofence]);

  // When a geofence is added from Location View, set that location by default
  useEffect(() => {
    if (locationId) {
      changeLocation(parseInt(locationId, 10));
      changeValueFormMethod('locationId', parseInt(locationId, 10));
    }
  }, [locationId]);

  useEffect(() => {
    if (!drawerView) {
      if (action === 'edit') {
        dispatch(
          setPageTitle(
            formikInitialValues.name &&
              `${t('Common.EllipsisButton.Edit')} ${formikInitialValues.name}`
          )
        );
      } else {
        dispatch(setPageTitle(t('GeofencesFeature.AddNewGeofence')));
      }
    }
  }, [dispatch, formikInitialValues, action, drawerView]);

  const changeShapeDrawingMode = (shape, setFieldValue) => {
    if (!shouldDrawingShapeChange) {
      return;
    }
    onFieldChange('shape', shape, setFieldValue);
  };

  const hasParrentMapId = (node, id) => {
    if (node.id === id || node?.className?.split(' ').indexOf('toast') > 0) {
      return true;
    }
    return node.parentNode && hasParrentMapId(node.parentNode, id);
  };

  const eventListenerMethod = function(e) {
    if (hasParrentMapId(e.target, 'mapGeofence')) {
      return;
    }
    if (!shouldDrawingShapeChange) {
      e.preventDefault();
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('GeofencesFeature.ToastMessages.PleaseFinishDrawingPolygon'),
          autohide: true
        })
      );
    }
  };

  const changeDrawingModaToPolygon = setFieldValue => {
    onFieldChange('shape', 'POLYGON', setFieldValue);
    setShouldDrawingShapeChange(false);
  };

  const handleClickOnShowGeofences = e => {
    helpers.showAllGeofences({
      event: e,
      geofencesForMap,
      shapeDrawn,
      setGeofencesForMap,
      initialGeofencesForMap,
      geofences
    });
  };

  const [formValue, setFormValue] = useState({});
  useEffect(() => {
    setFormValue({
      ...formikInitialValues,
      companyId: formikInitialValues?.companyId || currentCompany.id
    });
  }, [formikInitialValues, currentCompany]);

  useFormUpdatedFields(formValue, {
    eventName: MPTrackingEvents.Settings.Geofences.Update,
    eventProps: { typeOfUpdate: action },
    trackFields: MPTrackingEvents.Settings.Geofences.TrackFormFields,
    eventPropsOnSubmitFunc: MPTrackingEvents.Settings.Geofences.OnSubmitTrackEventPropsFunc
  });

  if (action === 'edit' && isFetchingGeofence) {
    return <LoadingTable columnSizes={[91, 60, 60, 68, 63, 49]} />;
  }

  const renderEventParamPanel = (setFieldValue, values, isSubmitting) => {
    return (
      <div
        style={{
          background: '#f7f8f9',
          padding: '0 32px 32px 32px',
          margin: drawerView ? 0 : '32px 0 0 0'
        }}
      >
        <AntRow>
          <AntCol span={24}>
            <Space size={drawerView ? 12 : 50} direction={'vertical'} className={styles.fullWidth}>
              <AntRow>
                <AntCol span={colWidth} className={styles.eventParamCol}>
                  <FormTitle
                    title={t('GeofencesFeature.Form.EventParameters')}
                    underlined
                    fullWidth
                    customStyling={drawerView ? { paddingTop: 0 } : {}}
                  />
                  <ActiveTimePeriodField
                    title={t('GeofencesFeature.ActiveTimes')}
                    tooltip={t('GeofencesFeature.ActiveTimesTooltip')}
                    initialPeriod={formikInitialValues?.activeTime?.period}
                    period={values?.activeTime?.period}
                    disabled={isSubmitting}
                    drawerView={drawerView}
                    onPeriodFieldChange={period => {
                      setFieldValue('activeTime.period', period);
                    }}
                  />
                </AntCol>
              </AntRow>
              <AntRow className={styles.eventParamRow}>
                <AntCol span={colWidth} className={styles.eventParamCol}>
                  <div
                    style={{ display: 'flex', cursor: 'pointer', alignItems: 'baseline' }}
                    onClick={
                      shouldDrawingShapeChange
                        ? () => {
                            onCheckBoxChange('speed');
                          }
                        : () => {}
                    }
                  >
                    <Checkbox
                      checked={formCheckEventParameters[0].value}
                      onChange={() => {}}
                      id={formCheckEventParameters[0].id}
                    />
                    <label
                      className="checkbox"
                      htmlFor="speed-checkbox"
                      style={{
                        cursor: 'pointer',
                        display: 'inline !important',
                        marginLeft: 8
                      }}
                    >
                      {formCheckEventParameters[0].label}
                    </label>
                  </div>
                </AntCol>
                {formCheckEventParameters[0].value && (
                  <AntCol span={colWidth}>
                    <FormInput
                      wrapperClassName={styles.eventParamInput}
                      className={styles.fullWidth}
                      name="thresholdSpeed"
                      label={`${t('GeofencesFeature.SpeedLimit')} (${
                        localization.formats.speed.unit_per_hour
                      })`}
                      tooltipZindex={10000}
                      placeholder=""
                      tooltip={`${
                        TOOLPTIP_TEXTS(t, localization.formats.speed.unit_per_hour).THRESHOLD
                      }`}
                      tooltipIconClass={TOOLTIP_ICON_CLASS}
                      isValidated
                      disabled={!shouldDrawingShapeChange}
                    />
                    <FormInput
                      name="offsetSpeed"
                      wrapperClassName={styles.eventParamInput}
                      className={styles.fullWidth}
                      label={`${t('GeofencesFeature.Tolerance')} (${
                        localization.formats.speed.unit_per_hour
                      })`}
                      placeholder=""
                      tooltip={`${
                        TOOLPTIP_TEXTS(t, localization.formats.speed.unit_per_hour).OFFSET
                      }`}
                      tooltipZindex={10000}
                      tooltipIconClass={TOOLTIP_ICON_CLASS}
                      isValidated
                      disabled={!shouldDrawingShapeChange}
                    />
                    <FormInput
                      name="thresholdDurationSpeed"
                      wrapperClassName={styles.eventParamInput}
                      className={styles.fullWidth}
                      label={`${t('GeofencesFeature.Duration')} (${t(
                        'GeofencesFeature.Seconds'
                      ).toLowerCase()})`}
                      placeholder=""
                      tooltip={TOOLPTIP_TEXTS(t, localization.formats.speed.unit_per_hour).DURATION}
                      tooltipIconClass={TOOLTIP_ICON_CLASS}
                      tooltipZindex={10000}
                      isValidated
                      disabled={!shouldDrawingShapeChange}
                    />
                    <div
                      style={{ display: 'flex', cursor: 'pointer' }}
                      onClick={
                        shouldDrawingShapeChange
                          ? () => {
                              setFieldValue('speedAssist', !values.speedAssist);
                            }
                          : () => {}
                      }
                    >
                      <Checkbox
                        checked={values.speedAssist}
                        onChange={() => {}}
                        id={formCheckEventParameters[0].id}
                      />
                      <label
                        className="checkbox"
                        htmlFor="speed-checkbox"
                        style={{
                          cursor: 'pointer',
                          display: 'inline !important',
                          marginLeft: 8
                        }}
                      >
                        {t('GeofencesFeature.Form.UseInSpeedAssist')}
                        <Tooltip
                          content={
                            TOOLPTIP_TEXTS(t, localization.formats.speed.unit_per_hour)
                              .USEINNSPEEDASSIST
                          }
                          zIndex={10000}
                          target={<i className={`${TOOLTIP_ICON_CLASS} ${styles.tooltip}`} />}
                        />
                      </label>
                    </div>
                  </AntCol>
                )}
              </AntRow>
              <AntRow className={styles.eventParamRow}>
                <AntCol span={colWidth} className={styles.eventParamCol}>
                  <div
                    style={{ display: 'flex', cursor: 'pointer', alignItems: 'baseline' }}
                    onClick={
                      shouldDrawingShapeChange ? () => onCheckBoxChange('undertime') : () => {}
                    }
                  >
                    <Checkbox
                      checked={formCheckEventParameters[1].value}
                      onChange={() => {}}
                      id={formCheckEventParameters[1].id}
                    />
                    <label
                      className="checkbox"
                      htmlFor="undertime-checkbox"
                      style={{
                        cursor: 'pointer',
                        display: 'inline !important',
                        marginLeft: 8
                      }}
                    >
                      {formCheckEventParameters[1].label}
                    </label>
                  </div>
                </AntCol>
                {formCheckEventParameters[1].value && (
                  <AntCol span={colWidth}>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <AntForm.Item
                        label={t('GeofencesFeature.Form.Mins').toLowerCase()}
                        className={styles.fullWidth}
                        style={{ marginBottom: 0 }}
                      >
                        <FormInput
                          name="thresholdUndertime"
                          wrapperClassName={styles.eventParamInput}
                          className={styles.fullWidth}
                          label=""
                          placeholder=""
                          isValidated
                          disabled={!shouldDrawingShapeChange}
                        />
                      </AntForm.Item>
                    </div>
                  </AntCol>
                )}
              </AntRow>
              <AntRow className={styles.eventParamRow}>
                <AntCol span={colWidth} className={styles.eventParamCol}>
                  <div
                    style={{ display: 'flex', cursor: 'pointer', alignItems: 'baseline' }}
                    onClick={
                      shouldDrawingShapeChange ? () => onCheckBoxChange('overtime') : () => {}
                    }
                  >
                    <Checkbox
                      checked={formCheckEventParameters[2].value}
                      onChange={() => {}}
                      id={formCheckEventParameters[2].id}
                    />
                    <label
                      className="checkbox"
                      htmlFor="overtime-checkbox"
                      style={{
                        cursor: 'pointer',
                        display: 'inline !important',
                        marginLeft: 8
                      }}
                    >
                      {formCheckEventParameters[2].label}
                    </label>
                  </div>
                </AntCol>
                {formCheckEventParameters[2].value && (
                  <AntCol span={colWidth}>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                      <AntForm.Item
                        label={t('GeofencesFeature.Form.Mins').toLowerCase()}
                        className={styles.fullWidth}
                        style={{ marginBottom: 0 }}
                      >
                        <FormInput
                          name="thresholdOvertime"
                          wrapperClassName={styles.eventParamInput}
                          className={styles.fullWidth}
                          label=""
                          placeholder=""
                          isValidated
                          disabled={!shouldDrawingShapeChange}
                        />
                      </AntForm.Item>
                    </div>
                  </AntCol>
                )}
              </AntRow>
              <AntRow className={styles.eventParamRow}>
                <AntCol span={colWidth} className={styles.eventParamCol}>
                  <div
                    style={{ display: 'flex', cursor: 'pointer', alignItems: 'baseline' }}
                    onClick={
                      shouldDrawingShapeChange ? () => onCheckBoxChange('ignition') : () => {}
                    }
                  >
                    <Checkbox
                      checked={formCheckEventParameters[3].value}
                      onChange={() => {}}
                      id={formCheckEventParameters[3].id}
                    />
                    <label
                      className="checkbox"
                      htmlFor="ignition-checkbox"
                      style={{
                        cursor: 'pointer',
                        display: 'inline !important',
                        marginLeft: 8
                      }}
                    >
                      {formCheckEventParameters[3].label}
                    </label>
                  </div>
                </AntCol>
                {formCheckEventParameters[3].value && (
                  <AntCol span={colWidth}>
                    <FormSelect
                      name="pretripIgnition"
                      classNames={styles.eventParamInput}
                      label={t('GeofencesFeature.Form.PreTripChecklist')}
                      onChange={id =>
                        onFieldChange('pretripIgnition', parseInt(id, 10), setFieldValue)
                      }
                      placeholder={t('GeofencesFeature.Form.PleaseSelectAPretrip')}
                      values={formPretripOptions}
                      isDisabled={!shouldDrawingShapeChange}
                    />
                    <FormInput
                      name="driverMessageIgnition"
                      wrapperClassName={styles.eventParamInput}
                      label={t('GeofencesFeature.Form.DriverMessage')}
                      placeholder=""
                      autocomplete="off"
                      disabled={!shouldDrawingShapeChange}
                    />
                  </AntCol>
                )}
              </AntRow>
              <AntRow className={styles.eventParamRow}>
                <AntCol span={colWidth} className={styles.eventParamCol}>
                  <div
                    style={{ display: 'flex', cursor: 'pointer', alignItems: 'baseline' }}
                    onClick={
                      shouldDrawingShapeChange
                        ? () => {
                            onCheckBoxChange('entry', setFieldValue);
                          }
                        : () => {}
                    }
                  >
                    <Checkbox
                      checked={formCheckEventParameters[4].value}
                      onChange={() => {}}
                      id={formCheckEventParameters[4].id}
                    />
                    <label
                      className="checkbox"
                      htmlFor="geofence-checkbox"
                      style={{
                        cursor: 'pointer',
                        display: 'inline !important',
                        marginLeft: 8
                      }}
                    >
                      {formCheckEventParameters[4].label}
                    </label>
                  </div>
                </AntCol>
                {formCheckEventParameters[4].value && (
                  <AntCol span={colWidth}>
                    <FormSelect
                      name="pretripEntry"
                      classNames={styles.eventParamInput}
                      label={t('GeofencesFeature.Form.PreTripChecklist')}
                      onChange={id =>
                        onFieldChange('pretripEntry', parseInt(id, 10), setFieldValue)
                      }
                      placeholder={t('GeofencesFeature.Form.PleaseSelectAPretrip')}
                      values={formPretripOptions}
                      isDisabled={!shouldDrawingShapeChange}
                    />
                    <FormInput
                      name="driverMessageEntry"
                      wrapperClassName={styles.eventParamInput}
                      label={t('GeofencesFeature.Form.DriverMessage')}
                      placeholder=""
                      disabled={!shouldDrawingShapeChange}
                    />
                    <FormSelect
                      name="mode"
                      label={t('GeofencesFeature.Mode')}
                      classNames={styles.eventParamInput}
                      onChange={id => onFieldChange('mode', id, setFieldValue)}
                      values={modeGeofenceEntryOptions()}
                      isDisabled={!shouldDrawingShapeChange}
                    />
                    <FormSelect
                      name="document"
                      label={t('Easydocs.Document')}
                      classNames={styles.eventParamInput}
                      placeholder={t('Easydocs.SelectTheDocument')}
                      onChange={id => onFieldChange('document', id, setFieldValue)}
                      values={formDocsOptions}
                      isDisabled={!shouldDrawingShapeChange}
                    />
                    <Col className={styles.eventParamInput}>
                      <AntRow className="header-entry" justify="start" gutter={[8, 0]}>
                        <AntCol span={4}>
                          <span>{t('GeofencesFeature.Period')}</span>
                          <span>
                            <Tooltip
                              content={t('GeofencesFeature.PeriodTooltip')}
                              zIndex={10000}
                              target={<i className={`tn-i-info ${styles.tooltip}`} />}
                            />
                          </span>
                        </AntCol>
                        <AntCol span={drawerView ? 10 : 6}>{t('GeofencesFeature.From')}</AntCol>
                        <AntCol span={drawerView ? 10 : 6}>{t('GeofencesFeature.To')}</AntCol>
                      </AntRow>
                      <AntRow className="body-entry" justify="start" gutter={[8, 0]}>
                        <AntCol span={4}>{t('GeofencesFeature.Daily')}</AntCol>
                        <AntCol span={drawerView ? 10 : 6}>
                          <TimePicker
                            use12Hours
                            popupStyle={{ zIndex: 10000 }}
                            value={values.timeFrom}
                            onChange={value => onFieldChange('timeFrom', value, setFieldValue)}
                            style={{ width: '100%' }}
                            placeholder={t('GeofencesFeature.Form.SelectATime')}
                            disabled={!shouldDrawingShapeChange}
                          />
                        </AntCol>
                        <AntCol span={drawerView ? 10 : 6}>
                          <TimePicker
                            use12Hours
                            popupStyle={{ zIndex: 10000 }}
                            value={values.timeTo}
                            onChange={value => onFieldChange('timeTo', value, setFieldValue)}
                            style={{ width: '100%' }}
                            placeholder={t('GeofencesFeature.Form.SelectATime')}
                            disabled={!shouldDrawingShapeChange}
                          />
                        </AntCol>
                      </AntRow>
                    </Col>
                  </AntCol>
                )}
              </AntRow>
            </Space>
            {canViewScorecardItems && (
              <AntRow justify="start">
                <AntCol span={colWidth}>
                  <FormTitle
                    customStyling={{ margin: '20px 0 0 0' }}
                    title={t('GeofencesFeature.Form.ManageScorecardEvents')}
                    underlined
                    fullWidth
                    tooltipZIndex={10000}
                    icon={'tn-i-info'}
                    tooltipMsg={t('Scorecard.geofenceMessage')}
                  />
                  <div style={{ display: 'flex', margin: '36px 0 0 0' }}>
                    <div style={{ display: 'flex', paddingRight: '32px' }}>
                      <div>{t('GeofencesFeature.Form.ScorecardExcludeInformation')}</div>
                    </div>
                  </div>
                  <div style={{ display: 'flex', margin: '36px 0 0 0' }}>
                    <div style={{ display: 'flex', paddingRight: '32px' }}>
                      <label>{t('GeofencesFeature.Form.ExcludedEvents')}</label>
                    </div>
                  </div>
                  <div style={{ display: 'flex', paddingRight: '32px' }}>
                    <AntDTooltip
                      overlayStyle={{ maxWidth: '500px' }}
                      title={
                        canEditExludedScorecardEvents
                          ? ''
                          : t('GeofencesFeature.Form.FieldPermissionMessage')
                      }
                    >
                      <Select
                        allowClear
                        disabled={canEditExludedScorecardEvents ? false : true}
                        style={{ width: '100%' }}
                        placeholder={t('GeofencesFeature.Form.SelectExcludedEvents')}
                        data={scorecardEventTypesFiltered}
                        mode="multiple"
                        value={values.excludedScorecardEvents}
                        onChange={value => {
                          onFieldChange('excludedScorecardEvents', value, setFieldValue);
                        }}
                      />
                    </AntDTooltip>
                  </div>
                </AntCol>
                <AntCol span={colWidth} />
              </AntRow>
            )}
          </AntCol>
        </AntRow>
      </div>
    );
  };

  const renderMap = (setFieldValue, values) => {
    let position = 'left';
    let mainStyle = {
      background: '#f7f8f9'
    };

    let rowStyle = { height: '700px', margin: '30px 0 20px 0', position: 'relative' };

    if (!drawerView) {
      mainStyle.margin = '32px 0 0 0';
      mainStyle.padding = '0 32px 32px 32px';
    } else {
      position = 'right';
      mainStyle.height = '100%';
      rowStyle = { height: '100%', margin: '0 0 20px 0', position: 'relative' };
    }

    return (
      <div style={mainStyle}>
        <AntRow style={{ height: '100%' }}>
          <AntCol span={24} style={{ height: '100%' }}>
            {!drawerView && (
              <FormTitle title={t('GeofencesFeature.Form.CreateGeofence')} underlined fullWidth />
            )}
            <Row style={rowStyle} className="map-row">
              <div
                style={{
                  position: 'absolute',
                  top: '5px',
                  [position]: '5px',
                  zIndex: 1,
                  background: '#fff',
                  borderRadius: '8px',
                  padding: '16px'
                }}
              >
                <div
                  style={{ display: 'flex', cursor: 'pointer', width: '170px' }}
                  onClick={shouldDrawingShapeChange ? handleClickOnShowGeofences : null}
                >
                  <Checkbox
                    checked={
                      action === 'edit' && formikInitialValues.points.length > 0
                        ? geofencesForMap.length - 1
                        : geofencesForMap.length
                    }
                    onChange={() => {}}
                    id={'show-geofences'}
                  />
                  <label
                    className="checkbox"
                    htmlFor="show-geofences"
                    style={{ color: '#000', cursor: 'pointer', marginLeft: 8 }}
                  >
                    {t('GeofencesFeature.Form.ShowGeofences')}
                  </label>
                </div>
                <div className="separator" />
                <div style={{ margin: '16px 0 0 0' }}>
                  <div>
                    <label>{t('Locations.Form.Address')}</label>
                    <GeoSuggest
                      inputClassName="search-location"
                      placeholder=""
                      disabled={!shouldDrawingShapeChange}
                      onSuggestSelect={suggest => {
                        suggest && setNewLocationFromSearch(suggest.location);
                      }}
                    />
                  </div>
                  <div
                    style={{ display: 'flex', margin: '16px 0 0 0' }}
                    className="search-by-coordinates"
                  >
                    <FormInput
                      name="lat"
                      label={t('Common.Latitude')}
                      disabled={!shouldDrawingShapeChange}
                    />
                    <FormInput
                      name="lng"
                      label={t('Common.Longitude')}
                      disabled={!shouldDrawingShapeChange}
                    />
                    <button
                      type="button"
                      width="69px"
                      className="reset-draw"
                      id={BUTTON_IDS.geofenceFormSearch}
                      onClick={
                        shouldDrawingShapeChange
                          ? () => {
                              setLocationFromCoordinateSearch({
                                place: { lat: values.lat, lng: values.lng },
                                setNewLocationFromSearch,
                                dispatch,
                                t
                              });
                            }
                          : () => {}
                      }
                    >
                      {t('Common.Search')}
                    </button>
                  </div>
                </div>
                <div className="separator" />
                <Row style={{ margin: '16px 0 0 0', justifyContent: 'space-between' }}>
                  <div>
                    <label>{t('Common.TableColumns.Actions')}</label>
                    <div style={{ display: 'flex', margin: '10px 0 0 0' }}>
                      <img
                        src={iconPalm}
                        alt="Icon-Palm"
                        onClick={() => changeShapeDrawingMode(null, setFieldValue)}
                        title={t('GeofencesFeature.Form.MoveTheMap')}
                        style={
                          values.shape === null
                            ? { border: '1px solid rgba(0, 0, 0, 0.25)' }
                            : { opacity: '0.5' }
                        }
                      />
                      <img
                        src={iconCircle}
                        alt="Icon-Circle"
                        title={t('GeofencesFeature.Form.DrawACircle')}
                        onClick={() => changeShapeDrawingMode('CIRCLE', setFieldValue)}
                        style={
                          values.shape === 'CIRCLE'
                            ? { border: '1px solid rgba(0, 0, 0, 0.25)' }
                            : { opacity: '0.5' }
                        }
                      />
                      <img
                        src={iconRectangle}
                        alt="Icon-Rectangle"
                        title={t('GeofencesFeature.Form.DrawARectangle')}
                        onClick={() => changeShapeDrawingMode('RECTANGLE', setFieldValue)}
                        style={
                          values.shape === 'RECTANGLE'
                            ? { border: '1px solid rgba(0, 0, 0, 0.25)' }
                            : { opacity: '0.5' }
                        }
                      />
                      <img
                        src={iconPolygon}
                        alt="Icon-Polygon"
                        onClick={() => changeDrawingModaToPolygon(setFieldValue)}
                        title={t('GeofencesFeature.Form.DrawAPolygon')}
                        style={
                          values.shape === 'POLYGON'
                            ? { border: '1px solid rgba(0, 0, 0, 0.25)' }
                            : { opacity: '0.5' }
                        }
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', width: '95px', position: 'relative' }}>
                    <FormColor
                      name="colour"
                      label={t('Common.Colour')}
                      value={values}
                      onChange={id => {
                        onFieldChange('colour', id, setFieldValue);
                        onEditModeChangeGeofenceStyle('colour', id);
                      }}
                      disabled={!shouldDrawingShapeChange}
                    />
                  </div>

                  <div style={{ display: 'flex', margin: '0 0 0 16px' }}>
                    <FormSelect
                      name="weight"
                      label={t('GeofencesFeature.Form.Thickness')}
                      onChange={id => {
                        onFieldChange('weight', parseInt(id, 10), setFieldValue);
                        onEditModeChangeGeofenceStyle('weight', id);
                      }}
                      values={optionsForThickness}
                      isDisabled={!shouldDrawingShapeChange}
                    />
                  </div>
                </Row>
                {!shouldDrawingShapeChange && (
                  <div className={styles.polygonDrawWarning}>
                    <Alert
                      message={t('GeofencesFeature.ToastMessages.PleaseFinishDrawingPolygon')}
                      banner
                    />
                  </div>
                )}
                <div className="separator" />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <button
                    type="button"
                    width="69px"
                    className="reset-draw"
                    onClick={shouldDrawingShapeChange ? resetDraw : null}
                    disabled={!shouldDrawingShapeChange}
                    id={BUTTON_IDS.geofenceFormReset}
                  >
                    {t('Common.Reset')}
                  </button>
                </div>
              </div>
              <ParsedMap
                onGeofenceDrawn={onGeofenceDrawn}
                onGeofenceEdited={onGeofenceEdited}
                geofencesForMap={geofencesForMap}
                isReset={isReset}
                geofenceId={geofenceId}
                shouldDrawingShapeChange={shouldDrawingShapeChange}
                selectedLocation={selectedLocation}
                mapZoom={mapZoom}
                values={values}
                isFullScreen={drawerView}
                {...props}
              />
            </Row>
          </AntCol>
        </AntRow>
      </div>
    );
  };

  const renderDescriptionPanel = setFieldValue => {
    return (
      <div style={{ background: '#f7f8f9', padding: '32px' }}>
        <AntRow gutter={[64, 0]}>
          <AntCol span={colWidth}>
            <div className="formContainer" style={{ padding: '0 0 14px 0' }}>
              <Row>
                <FormInput
                  name="name"
                  label={t('GeofencesFeature.Form.GeofenceName')}
                  placeholder={t('GeofencesFeature.Form.TypeInGeofenceName')}
                  isRequired
                  disabled={!shouldDrawingShapeChange}
                />
              </Row>
              <Row>
                <FormSelect
                  name="companyId"
                  label={t('Common.Company')}
                  onChange={id => onCompanyChange(id, setFieldValue)}
                  values={companies.map(company => ({
                    label: company.name,
                    value: company.id
                  }))}
                  isRequired
                  isDisabled={!shouldDrawingShapeChange}
                />
              </Row>
              <Row>
                <FormSelect
                  name="locationId"
                  label={t('Locations.Location')}
                  onChange={id => {
                    onFieldChange('locationId', parseInt(id, 10), setFieldValue);
                    changeLocation(parseInt(id, 10));
                  }}
                  placeholder={t('GeofencesFeature.Form.SelectLocation')}
                  values={filteredLocationsByCompanyId.map(loc => ({
                    label: loc.name,
                    value: loc.id
                  }))}
                  isDisabled={!shouldDrawingShapeChange}
                />
              </Row>
              <Row>
                <FormSelect
                  name="type"
                  label={t('VehicleTypes.Type')}
                  onChange={id => {
                    onFieldChange('type', id, setFieldValue);
                  }}
                  values={allowedTypes}
                  isRequired
                  isDisabled={!shouldDrawingShapeChange}
                />
              </Row>
            </div>
          </AntCol>
          <AntCol span={colWidth}>
            <div className="formContainer" style={{ padding: '0 0 14px 0' }}>
              <SearchableListMultiSelect
                name="fleets"
                label={t('Fleets.Fleet')}
                placeholder={t('GeofencesFeature.Form.SearchForFleet')}
                allLabel={t('Common.AllFleets')}
                initialValues={formikInitialValues.fleets}
                values={fleets}
                setFieldValue={setUserCheckedFleets}
                height={250}
                formStyle={{ padding: 0 }}
                isDisabled={!shouldDrawingShapeChange}
                isLoading={!fleetsLoaded}
                onCheckboxChanged={setIsFormDirty}
              />
            </div>
          </AntCol>
        </AntRow>
      </div>
    );
  };

  const onCancel = () => {
    if (onFinish) {
      onFinish();
    } else {
      history.goBack();
    }
  };

  const renderFooter = (dirty, isValid, isSubmitting) => {
    return (
      <div className={styles.formFooter}>
        <Button
          size="large"
          type="primary"
          disabled={(!dirty && !isFormDirty) || !isValid || isSubmitting}
          htmlType="submit"
          id={BUTTON_IDS.geofenceFormSave}
        >
          {t('Common.SaveButton')}
        </Button>
        <Button size="large" id={BUTTON_IDS.geofenceFormCancel} onClick={onCancel}>
          {t('Common.CancelButton')}
        </Button>
      </div>
    );
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...formikInitialValues,
          companyId: formikInitialValues?.companyId || currentCompany.id
        }}
        validateOnMount
        validationSchema={validationSchema(
          formCheckEventParameters[0].value,
          geofenceNames,
          formikInitialValues?.name
        )}
        onSubmit={(values, actions) => {
          if (values.shape === GEOFENCE_SHAPES.POLYGON) {
            const isIntersecting =
              !!shapeDrawn?.points?.length && helpers.anyOverlap(shapeDrawn?.getPath().getArray());
            if (isIntersecting) {
              helpers.notificationOverlap(dispatch);
              actions.setSubmitting(false);
              return;
            }
          }
          const submittedGeofenceData = submitData(
            values,
            userCheckedFleets,
            actions,
            dispatch,
            action,
            geofenceId,
            formCheckEventParameters,
            t,
            localization,
            setFormValue
          );
          if (values.points.length > 2) {
            setPromptModalWhenLeaving(false);

            if (!drawerView) {
              canHistoryGoBack(history, '/settings/geofences');
            } else if (onFinish) {
              onFinish({
                geofenceName: submittedGeofenceData?.name,
                shape: submittedGeofenceData?.shape,
                centroid: submittedGeofenceData.centroid
              });
            }
          }
        }}
      >
        {({ isSubmitting, isValid, setFieldValue, dirty, values }) => {
          if (!changeValueFormMethod) {
            changeValueFormMethod = setFieldValue;
          }
          return (
            <>
              <EditRouteGuard
                when={dirty && promptModalWhenLeaving && !hasCompanyChanged && !drawerView}
                navigate={history.push}
              />
              <Form id="GeofenceForm">
                {drawerView ? (
                  <ReflexContainer orientation="vertical">
                    <ReflexElement flex={0.275} className={styles.scrollBarPanel}>
                      {renderDescriptionPanel(setFieldValue)}
                      {renderEventParamPanel(setFieldValue, values, isSubmitting)}
                      <div style={{ marginBottom: 60 }}>
                        {renderFooter(dirty, isValid, isSubmitting)}
                      </div>
                    </ReflexElement>
                    <ReflexSplitter className={styles.gridSplitter} />
                    <ReflexElement>{renderMap(setFieldValue, values)}</ReflexElement>
                  </ReflexContainer>
                ) : (
                  <>
                    {renderDescriptionPanel(setFieldValue)}
                    {renderEventParamPanel(setFieldValue, values, isSubmitting)}
                    {renderMap(setFieldValue, values)}
                    {renderFooter(dirty, isValid, isSubmitting)}
                  </>
                )}
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const ParsedMap = React.memo(
  ({
    geofencesForMap,
    selectedLocation,
    values,
    onGeofenceDrawn,
    shouldDrawingShapeChange,
    onGeofenceEdited,
    mapZoom,
    isReset,
    geofenceId,
    isFullScreen,
    ...props
  }) => {
    let combinedMapOptions =
      isReset && geofenceId === 'newGeofence'
        ? {
            mapTypeControlOptions: {
              position: google.maps.ControlPosition.TOP_RIGHT
            },
            center: {
              lat: selectedLocation?.GPS?.Lat,
              lng: selectedLocation?.GPS?.Lng
            },
            zoom: mapZoom
          }
        : {
            mapTypeControlOptions: {
              position: google.maps.ControlPosition.TOP_RIGHT
            }
          };

    if (isFullScreen) {
      const { center, zoom, ...otherMapOption } = props.mapOption;

      combinedMapOptions = {
        ...combinedMapOptions,
        ...{
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false
        },
        ...otherMapOption,
        ...(selectedLocation?.isMarkerHidden === true && { center: center }),
        ...(selectedLocation?.isMarkerHidden === true && { zoom: zoom })
      };
    }

    return (
      <Map
        mode={geofencesForMap?.length > 0 ? MapMode.EditGeofence : MapMode.DrawGeofence}
        geofences={geofencesForMap}
        devices={props.devices || EMPTY_ARRAY}
        location={
          selectedLocation?.isMarkerHidden === false && {
            lat: selectedLocation.GPS.Lat,
            lng: selectedLocation.GPS.Lng
          }
        }
        showDevice={(props.devices || EMPTY_ARRAY).length !== 0}
        onGeofenceDrawn={onGeofenceDrawn}
        onGeofenceEdited={shouldDrawingShapeChange ? onGeofenceEdited : null}
        mapOptions={combinedMapOptions}
        drawingOptions={{
          drawingControl: false,
          drawingMode: values.shape ? google.maps.drawing.OverlayType[values.shape] : null,
          circleOptions: {
            clickable: true,
            editable: true,
            fillOpacity: 0.35,
            fillColor: values.colour || GEOFENCE_DEFAULT_COLORS.NON_MANAGED,
            strokeColor: values.colour || GEOFENCE_DEFAULT_COLORS.NON_MANAGED,
            strokeWeight: values.weight || 2
          },
          rectangleOptions: {
            clickable: true,
            editable: true,
            fillOpacity: 0.35,
            fillColor: values.colour || GEOFENCE_DEFAULT_COLORS.NON_MANAGED,
            strokeColor: values.colour || GEOFENCE_DEFAULT_COLORS.NON_MANAGED,
            strokeWeight: values.weight || 2
          },
          polygonOptions: {
            clickable: true,
            editable: true,
            fillOpacity: 0.35,
            fillColor: values.colour || GEOFENCE_DEFAULT_COLORS.NON_MANAGED,
            strokeColor: values.colour || GEOFENCE_DEFAULT_COLORS.NON_MANAGED,
            strokeWeight: values.weight || 2
          }
        }}
        containerElement={<div style={{ height: `100%`, width: `100%` }} />}
        mapElement={<div style={{ height: `100%`, width: `100%` }} id="mapGeofence" />}
      />
    );
  },
  (prevProps, nextProps) =>
    [
      'geofencesForMap',
      'selectedLocation',
      'isReset',
      'geofenceId',
      'shouldDrawingShapeChange',
      'mapZoom',
      'isFullScreen'
    ].every(key => prevProps[key] === nextProps[key]) &&
    prevProps.values.shape === nextProps.values.shape
);

const ActiveTimePeriodField = ({
  title,
  tooltip,
  initialPeriod,
  period,
  onPeriodFieldChange = () => {},
  disabled,
  drawerView
}) => {
  const [toggleOn, setToggleOn] = useState(!!initialPeriod);

  useEffect(() => setToggleOn(!!initialPeriod), [initialPeriod]);

  return (
    <Can everyService={[services.NPI]}>
      <AntRow align="middle" className={drawerView ? styles.toggleDrawer : styles.toggle}>
        <Switch
          disabled={disabled}
          checked={toggleOn}
          onChange={checked => {
            setToggleOn(checked);
            if (!checked) {
              onPeriodFieldChange({});
            }
          }}
        />
        <span className={styles.toggleTitle}>{title}</span>
        <Tooltip
          content={tooltip}
          zIndex={10000}
          target={<i className={`tn-i-info ${styles.tooltip}`} />}
        />
      </AntRow>
      {toggleOn && (
        <AntRow className={styles.timeTable}>
          <WeeklyScheduleSelector
            period={period}
            onPeriodChange={onPeriodFieldChange}
            disabled={disabled}
          />
        </AntRow>
      )}
    </Can>
  );
};
