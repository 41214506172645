import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { TabNavLink } from 'components/nav/NavLinks';
import { useTranslation } from 'react-i18next';

//components
import { Summary } from './Summary';
import { MobileLogs } from './MobileLogs';
import { NoAccessBody } from 'components/pages/NoAccess';
import { Can } from 'features/permissions';
import { SummaryView } from './SummaryView';
import { Details } from './Details';

//slices

//constants

export const ELDRouter = () => {
  const { t } = useTranslation();
  return (
    <Can onFail={<NoAccessBody />}>
      <Switch>
        <Route>
          <div
            style={{
              display: 'flex',
              flex: '1 0 0',
              flexDirection: 'column'
            }}
          >
            <Switch>
              <Route exact path={['/supportTools/ELD', '/supportTools/ELD/Summary']}>
                <Summary />
              </Route>

              <Route exact path={['/supportTools/ELD/MobileLogs']}>
                <MobileLogs />
              </Route>
              <Route path={['/supportTools/ELD/Summary/view']}>
                <SummaryView />
              </Route>
              <Route path={['/supportTools/ELD/Summary/details']}>
                <Details />
              </Route>
            </Switch>
          </div>
        </Route>
      </Switch>
    </Can>
  );
};
