import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  Paths,
  DriverMgtTypes,
  DriverMgtTypesAudits,
  DriverMgtTypesForm,
  DriverMgtTypesView
} from './';
import { NoAccessBody } from 'components/pages/NoAccess';
import { Can, entities } from 'features/permissions';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { useSubCompanies } from 'features/company/companySlice';
import {
  fetchTypes,
  fetchDeletedTypes,
  useDriverMgtTypes,
  useDeletedDriverMgtTypes,
  useIsTypesFetchingFinished
} from 'features/driverManagement';
import { getTypesQuery, getDeletedTypesQuery, ACTIONS } from './constants';

export const DriverMgtTypesRouter = () => {
  const dispatch = useDispatch();
  const companyKey = useCurrentCompanyKey();
  const subCompanies = useSubCompanies();
  const types = useDriverMgtTypes();
  const deletedTypes = useDeletedDriverMgtTypes();
  const allTypes = types.concat(deletedTypes);
  const isTypesFetched = useIsTypesFetchingFinished();

  // refresh data on company change
  useEffect(() => {
    if (isTypesFetched) {
      dispatch(fetchTypes(getTypesQuery));
      dispatch(fetchDeletedTypes(getDeletedTypesQuery));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyKey]);

  return (
    <Switch>
      <Route path={Paths.ADD_DRIVERMANAGEMENTTYPE}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTTYPE_CREATE]} onFail={<NoAccessBody />}>
          <DriverMgtTypesForm action={ACTIONS.ADD} />
        </Can>
      </Route>
      <Route path={Paths.EDIT_DRIVERMANAGEMENTTYPE}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTTYPE_UPDATE]} onFail={<NoAccessBody />}>
          <DriverMgtTypesForm action={ACTIONS.EDIT} types={types} />
        </Can>
      </Route>
      <Route path={Paths.VIEW_DRIVERMANAGEMENTTYPE}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTTYPE_VIEW]} onFail={<NoAccessBody />}>
          <DriverMgtTypesView types={allTypes} subCompanies={subCompanies} />
        </Can>
      </Route>
      <Route path={Paths.AUDIT_DRIVERMANAGEMENTTYPE}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTTYPE_AUDIT]} onFail={<NoAccessBody />}>
          <DriverMgtTypesAudits types={types} />
        </Can>
      </Route>
      <Route path={Paths.MAIN_PAGE}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTTYPE]} onFail={<NoAccessBody />}>
          <DriverMgtTypes types={types} subCompanies={subCompanies} />
        </Can>
      </Route>
    </Switch>
  );
};
