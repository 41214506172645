import React, { useEffect, useState } from 'react';
import { Space } from 'antd';

import { getVehicleVisualization } from '../Data';
import { VehicleVis } from '.';

export const MatchingVehicleVis = ({
  vehicleCombination,
  axleGroups,
  axles,
  showHeader = false,
  showVisName = false
}) => {
  const [vis, setVis] = useState(false);

  useEffect(() => {
    if (vehicleCombination) {
      setVis(getVehicleVisualization(vehicleCombination.axleGroups, vehicleCombination.axles));
    } else if (axleGroups && axles) {
      setVis(getVehicleVisualization(axleGroups, axles));
    }
  }, [vehicleCombination, axleGroups, axles]);

  return (
    vis?.length > 0 && (
      <Space
        className="showScrollbarsOnHover"
        direction="vertical"
        size="large"
        align="center"
        style={{
          width: '100%',
          height: '225px',
          overflowY: 'scroll'
        }}
      >
        {showHeader && <label>Matching V7 Visualizations:</label>}
        {vis?.map(visData => (
          <VehicleVis visualization={visData.visualization} showVisName={showVisName} />
        ))}
      </Space>
    )
  );
};
