import React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import AlertForm from 'containers/Configuration/CompanyAlerts/AlertForm';

import NewUserAlerts from '../NewUserAlerts';
import { ENTITIES } from 'components/alertStatusBar/constants';
import { ALERT_LINKS } from 'containers/Configuration/CompanyAlerts/constants';
import Can from 'features/permissions/Can';
import { NoAccessBody } from 'components/pages/NoAccess';
import { getTypeFromLink } from 'containers/Configuration/CompanyAlerts/helpers/linkManipulationFunctions';
import { useAlertPermissions } from 'containers/Configuration/CompanyAlerts/helpers/useAlertPermissions';
import { Paths } from '../constants';

export const UserAlertsRouter = () => {
  const location = useLocation();
  const linkArray = Object.values(ALERT_LINKS);
  const pathParams = location.pathname.split('/');

  const link = pathParams.find(param => (linkArray.find(link => link === param) ? param : ''));

  const alertPermissions = useAlertPermissions();
  const alertType = getTypeFromLink(link);

  const currentAlertPermissions = alertPermissions.find(alert => alert?.type === alertType)
    ?.services;

  return (
    <Switch>
      <Route path={Paths.EDIT_ALERT}>
        <Can oneOfServices={currentAlertPermissions} onFail={<NoAccessBody />}>
          <AlertForm action="edit" entityType={ENTITIES.USER} />
        </Can>
      </Route>
      <Route path={Paths.USER_ALERTS_DEFAULT}>
        <NewUserAlerts />
      </Route>
    </Switch>
  );
};
