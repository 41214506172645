import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List, Tag, Button } from 'antd';
import _ from 'lodash';

import { QsEntityType, useGetQsUserQuery, useSearchQsFoldersEntitiesQuery } from 'services/nextgen';
import { Comparators } from 'utils/sorting';
import { SortOrders, CategoryFilters } from '../constants';

import { useCan, services, useCanOneOfCompanyServices } from 'features/permissions';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';

import { Grid } from 'components/tn';
import ReportCard from '../Cards/ReportCard';
import {
  ReportsPageToolbar,
  isReportCategory,
  getReportCategoryLabels
} from '../Toolbars/ReportsPageToolbar';

import styles from './pages.module.scss';
import cardStyles from '../Cards/cards.module.scss';

import { ConfigureReportModal } from '../Modals';

export const ReportsCataloguePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const can = useCan();

  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchText, setSearchText] = useState();
  const [sortOrder, setSortOrder] = useState(SortOrders.Default);
  const [categories, setCategories] = useState();
  const [viewType, setViewType] = useState('tile');
  const [showConfigureReportModal, setShowConfigureReportModal] = useState(false);

  useEffect(() => {
    dispatch(setPageTitle(t('Common.Insights')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  useEffect(() => {
    const sampleReports = [
      {
        arn:
          'arn:aws:quicksight:ap-southeast-2:768269730846:dashboard/984f30a9-cec0-4108-95a4-583a5ca52dd4',
        reportId: '984f30a9-cec0-4108-95a4-583a5ca52dd4',
        name: 'Sample Report',
        createdTime: 1712226520.473,
        lastUpdatedTime: 1713192040.29,
        publishedVersionNumber: 27,
        lastPublishedTime: 1712226520.473,
        entityType: 'report',
        isCustom: false
      },
      {
        arn:
          'arn:aws:quicksight:ap-southeast-2:768269730846:dashboard/984f30a9-cec0-4108-95a4-583a5ca52dd4',
        reportId: '984f30a9-cec0-4108-95a4-583a5ca52dd5',
        name: 'Placeholder Report',
        createdTime: 1712226520.473,
        lastUpdatedTime: 1713192040.29,
        publishedVersionNumber: 27,
        lastPublishedTime: 1712226520.473,
        entityType: 'report',
        isCustom: false
      }
    ];

    setReports(sampleReports);
    setFilteredReports(sampleReports);
  }, []);

  useEffect(() => {
    if (
      categories !== undefined &&
      categories !== null &&
      reports !== undefined &&
      reports !== undefined
    ) {
      handleFilter(categories);
    }
  }, [categories, reports]);

  const handleSearch = value => {
    setSearchText(value);

    let filteredReports = search(reports, value);
    if (categories) {
      filteredReports = filter(filteredReports, categories);
    }
    if (sortOrder) {
      filteredReports = sort(filteredReports, sortOrder);
    }

    setFilteredReports(filteredReports);
  };

  const handleSort = order => {
    setSortOrder(order);

    const sortedReports = sort(filteredReports, order);
    setFilteredReports(sortedReports);
  };

  const handleFilter = categoryIds => {
    setCategories(categoryIds);

    let filteredReports = filter(reports, categoryIds);
    if (searchText) {
      filteredReports = search(filteredReports, searchText);
    }
    if (sortOrder) {
      filteredReports = sort(filteredReports, sortOrder);
    }
    setFilteredReports(filteredReports);
  };

  const handleDeleteCard = () => {
    handleFilter(categories);
  };

  const search = (reports, searchText) => {
    return reports.filter(
      report =>
        report.name.toLowerCase().includes(searchText.toLowerCase()) ||
        (!report.isCustom &&
          t(`Insights.DashboardInfo.${report.name}.Description`)
            .toLowerCase()
            .includes(searchText.toLowerCase()))
    );
  };

  const sort = (reports, order) => {
    const isDefault = order === SortOrders.Default;
    const isDescending = order === SortOrders.NameDesc;
    const sortedDashboards = isDefault
      ? _.orderBy(
          reports,
          ['isCustom', 'entityType', db => db.name.toLowerCase()],
          ['asc', 'desc', 'asc']
        )
      : reports.toSorted(Comparators.String('name', isDescending));
    return sortedDashboards;
  };

  const filter = (reports, categoryIds) => {
    return reports.filter(report =>
      categoryIds?.reduce(
        (accumulator, categoryId) => accumulator || isReportCategory(report, categoryId),
        false
      )
    );
  };

  const onViewChange = () => {
    viewType === 'tile' ? setViewType('grid') : setViewType('tile');
  };

  //*****GRID************//
  const nameCellRenderer = (value, rowData, rowIndex) => {
    return <div>{t(`Insights.ReportInfo.${rowData.name}.Name`)}</div>;
  };

  const descriptionCellRenderer = (value, rowData, rowIndex) => {
    return <div>{t(`Insights.ReportInfo.${rowData.name}.Description`)}</div>;
  };

  const categoryCellRenderer = (value, rowData, rowIndex) => {
    const categories = getReportCategoryLabels(rowData);

    return (
      Array.isArray(categories) &&
      categories?.map(category => (
        <Tag className={[cardStyles.categories, cardStyles.reportCard]} key={category}>
          {t(`Insights.Tags.${category}`)}
        </Tag>
      ))
    );
  };

  const actionCellRenderer = (value, rowData, rowIndex) => {
    return (
      <Button className={styles.generate} onClick={() => setShowConfigureReportModal(true)}>
        {' '}
        {t(`Insights.Generate`)}{' '}
      </Button>
    );
  };

  const columns = [
    {
      title: t(`Insights.Table.Name`),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 16,
      render: nameCellRenderer
    },
    {
      title: t(`Insights.Table.Description`),
      key: 'description',
      fixed: 'left',
      width: 32,
      render: descriptionCellRenderer
    },
    {
      title: t(`Insights.Table.Category`),
      key: 'tag',
      fixed: 'left',
      width: 8,
      render: categoryCellRenderer
    },
    {
      title: t(`Insights.Table.Action`),
      key: 'action',
      fixed: 'left',
      width: 8,
      render: actionCellRenderer
    }
  ];
  //*****GRID************//

  return (
    <>
      <ReportsPageToolbar
        count={filteredReports?.length}
        onSearch={handleSearch}
        onSort={handleSort}
        onFilter={handleFilter}
        onViewChange={onViewChange}
        viewType={viewType}
      />

      {viewType === 'tile' && (
        <List
          className={styles.dashboardList}
          grid={{
            gutter: 12,
            xs: 2,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 5
          }}
          itemLayout={'horizontal'}
          //loading={
          //  tnDashboardsQuery.isFetching ||
          //  companyDashboardsQuery.isFetching
          //}
          dataSource={filteredReports}
          renderItem={report => (
            <List.Item key={report.reportId}>
              <ReportCard
                report={report}
                categories={getReportCategoryLabels(report)}
                //image={}
                onDelete={handleDeleteCard}
              />
            </List.Item>
          )}
        />
      )}
      {viewType === 'grid' && <Grid columns={columns} data={filteredReports} showHeader={false} />}

      <ConfigureReportModal
        isOpen={showConfigureReportModal}
        onClose={() => {
          setShowConfigureReportModal(false);
        }}
      />
    </>
  );
};
