import React, { useMemo } from 'react';
import { Redirect, useHistory } from 'react-router';
import Login from 'containers/Login/Login';
import { useCompatibleLoginState } from 'features/user/userSlice';

export const CompatibleLogin = props => {
  const history = useHistory();
  const { canCompatibleLogin } = useCompatibleLoginState();

  const isRedirectFromSSO = useMemo(() => {
    let search = history.location.search;
    if (!search) {
      search = history.location.state?.from?.search;
    }
    let from;
    try {
      from =
        search.trim() !== '' && search.toLowerCase().indexOf('from') >= 0
          ? search
              .substring(1)
              .split('&')
              .find(k => k.toLowerCase().startsWith('from'))
              ?.split('=')[1]
          : null;
    } catch (error) {
      from = null;
    }
    return history.location.state?.referrer === '/sso' || from === 'sso';
  }, [history]);

  const assembleRedirectParams = (path, search) => {
    const params = new URLSearchParams(search);
    const realm = params.get('realm');
    params.delete('realm');

    const redirectParams = new URLSearchParams();
    redirectParams.set('from', `${path}${params.size ? `?${params.toString()}` : ''}`);

    if (realm) {
      redirectParams.set('realm', realm);
    }

    return `?${redirectParams.toString()}`;
  };

  if (canCompatibleLogin && !isRedirectFromSSO) {
    let redirectUri = '/sso';

    if (history.location.search) {
      redirectUri += assembleRedirectParams('/', history.location.search);
    } else if (
      history.location.state?.from &&
      (history.location.state.from.pathname !== '/' || history.location.state.from.search)
    ) {
      redirectUri += assembleRedirectParams(
        history.location.state.from.pathname,
        history.location.state.from.search
      );
    }
    return <Redirect to={redirectUri} />;
  }
  return <Login {...props} />;
};
