import i18n from 'i18next';

export const getDaysOfWeek = () => [
  i18n.t('Weekdays.Monday'),
  i18n.t('Weekdays.Tuesday'),
  i18n.t('Weekdays.Wednesday'),
  i18n.t('Weekdays.Thursday'),
  i18n.t('Weekdays.Friday'),
  i18n.t('Weekdays.Saturday'),
  i18n.t('Weekdays.Sunday')
];

export const hoursOfDay = [...Array(4 * 24)].map((val, index) => index);
