/* global google */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { Row, Col, Alert } from 'antd';
import { LoadingCentered } from 'components/loading/Loading';
// Hooks
import {
  useCompanies,
  useCompanyGeofenceProviders,
  useCurrentCompany
} from 'features/company/companySlice';

//Methods
import {
  initialValues,
  validationSchema,
  journeyTypes as journeyTypesConstant,
  stopTypes as allStopTypes,
  JOURNEY_TYPE,
  EMPTY_ARRAY
} from './constants';
import { GOOGLE_MAPS_API_KEY } from 'config';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import request from 'superagent';
import { API_PATH } from 'config';
import { useTranslation } from 'react-i18next';
import { journeyHelpers } from './helpers';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { isLatLngValid } from 'utils/geo';
import { secondsToHHMMSS, HHMMSSToSeconds } from 'utils/methods';

//Components
import { Button } from 'components/ant';
import FormInput from 'components/form/form-input/FormInput';
import FormTitle from 'components/form/form-title/FormTitle';
import FormSelect from 'components/form/form-select/FormSelect';
import JourneyMap from './JourneyMap';
import RoutePreview from './RoutePreview';

import {
  getFleetsByCompany,
  getGeofencesByCompany
} from 'containers/Administration/Fleets/APICalls';
import { useUserKey } from 'features/user/userSlice';
import SearchableListMultiSelect from 'components/form/searchable-list-multi-select/SearchableListMultiSelect';
import {
  useJourneyStops,
  fetchJourneys,
  getAddressFromLatLng,
  getJourneyById
} from 'features/journeyPlanner/journeysSlice';
import { useGeofences } from 'features/geofences/geofencesSlice';
import { GeoSuggest } from 'components/GeoSuggest';

import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { useHistory } from 'react-router';
import { useAllRegions } from 'features/regions/regionsSlice';
import timerIcon from 'static/images/icons/timer.svg';
import { canHistoryGoBack, getIDFromPathname } from 'utils/methods';
import { useCan } from 'features/permissions';

//Styles
import styles from './JourneyPlanner.module.scss';
import './JourneyPlanner.scss';
import { isManagedGeofence } from 'features/geofences/geofencesUtil';
import { BUTTON_IDS } from 'utils/globalConstants';
import { parseErrorMessage } from 'utils/strings';

export const JourneyPlannerForm = ({ localization, action, initialFleets, journeys }) => {
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const geocoder = new google.maps.Geocoder();
  const dispatch = useDispatch();
  const history = useHistory();
  const journeyStops = useJourneyStops();
  const companies = useCompanies();
  const currentCompany = useCurrentCompany();
  const userKey = useUserKey();
  const company = useCurrentCompany();
  const initialGeofences = useGeofences();
  const [fleets, setFleets] = useState([]);
  const [userCheckedFleets, setUserCheckedFleets] = useState([]);
  const [geofences, setGeofences] = useState([]);
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
  const [showFleets, setShowFleets] = useState(false);
  const [showStopDropdown, setShowStopDropdown] = useState(false);
  const [stopDropdownValues, setStopDropdownValues] = useState([]);
  const [disabledAddress, setDisabledAddress] = useState(true);
  const [showSearchableFleets, setShowSearchableFleets] = useState(false);
  const [stopId, setStopId] = useState('1');
  const [fleetId, setFleetId] = useState(0);
  const [journeyTypeId, setJourneyTypeId] = useState(1);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  const [fleetsLoaded, setFleetsLoaded] = useState(true);
  const journeyMapFormRef = useRef(null);
  const [newMarker, setNewMarker] = useState(null);
  const [waypoints, setWaypoints] = useState([]);
  const [journeyGeofences, setJourneyGeofences] = useState([]);
  const [stopTypes, setStopTypes] = useState(allStopTypes);
  const [journey, setJourney] = useState(null);
  const [mapDirections, setMapDirections] = useState(null);
  const [mapCenter, setMapCenter] = useState();
  const [zoomLevel, setZoomLevel] = useState();
  const [initiallyCheckedFleets, setInitiallyCheckedFleets] = useState();
  const regions = useAllRegions();
  const [plannedDuration, setPlannedDuration] = useState(null);
  const can = useCan();
  const journeyTypes = journeyTypesConstant.filter(item => can({ everyService: item.service }));
  const geofenceProviders = useCompanyGeofenceProviders();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isUpdatingMap, setIsUpdatingMap] = useState(false);
  const hasManualDragEdits = waypoints?.some(waypoint => waypoint.stopover === false);

  // Update the waypoints when dragging the route or delete a stop
  const updateWaypoints = mapWaypoints => {
    const newWaypoints = waypoints.filter(pt => pt.seq);
    mapWaypoints.forEach((waypt, index) => {
      if (waypt.stopover === false) {
        newWaypoints.splice(index + 1, 0, { ...waypt, stopover: false });
      }
    });
    setWaypoints(newWaypoints);
  };

  const updateWaypointsDelete = (index, mapWaypoints) => {
    const deletedWaypoints = waypoints
      .filter(stops => stops.seq && stops.seq !== index)
      .map((stop, index) => ({ ...stop, ...(stop.seq && { seq: (index + 1).toString() }) }));

    //update the waypoints with the map request waypoints
    if (mapWaypoints?.request?.waypoints) {
      const newWaypoints = deletedWaypoints.filter(pt => pt.seq);
      mapWaypoints.request.waypoints.forEach((waypt, index) => {
        if (waypt.stopover === false) {
          newWaypoints.splice(index + 1, 0, { ...waypt, stopover: false });
        }
      });
      setWaypoints(newWaypoints);
      return;
    }
    setWaypoints(deletedWaypoints);
  };

  const handleMapDirectionsChange = useMemo(
    () => ({
      onNoDirection() {
        setMapDirections(null);
      },
      setIsLoading: setIsUpdatingMap,
      onDirectionsSucceed: (e, redraw) => {
        setMapDirections(e);

        if (redraw) {
          setIsFormDirty(true);
        }
      },
      onDirectionError() {
        //adjust map zoom level by visibleMarkers
        journeyHelpers.adjustMapWithWaypoints(journeyMapFormRef, waypoints);
      }
    }),
    [journeyMapFormRef, waypoints]
  );

  const handleInfoWindowAdd = () => {
    dispatch(addStop());
  };

  const handleJourneyTypeChange = setFieldValue => id => {
    // do not allow selecting Geofence type, if there are non-geofence stops
    if (Number(id) === JOURNEY_TYPE.ID.GEOFENCE_TRIP) {
      if (waypoints.some(waypoint => !waypoint.geofence)) {
        dispatch(
          openToast({
            type: ToastType.Warning,
            message: t('JourneyPlanner.Form.GeofenceTypeWarning'),
            autohide: true
          })
        );
        return;
      }
      if (newMarker) {
        // reset marker and coordinates if any
        setNewMarker(null);
        setCoordinatesInForm(setFieldValue);
      }
    }
    setJourneyTypeId(id);
    setFieldValue('type', id);
    if (Number(id) === JOURNEY_TYPE.ID.GEOFENCE_TRIP) {
      setStopId('2'); //Geofence
      setStopTypes(allStopTypes.filter(type => Number(type.id) === 2));
    } else {
      setStopId('1');
      setStopTypes(allStopTypes);
      setFieldValue('stopType', 1);
    }
  };

  const handleStopTypeChange = setFieldValue => id => {
    setStopId(id);
    setFieldValue('stopType', id);
  };

  const handleReverse = () => {
    setWaypoints(journeyHelpers.getReversedJourneyOrder(waypoints));
    setIsFormDirty(true);
  };

  const handlePlannedDurationChange = (inputName, setFieldValue, values) => value => {
    let plannedDurationArray = [values.plannedHH, values.plannedMM, values.plannedSS];
    switch (inputName) {
      case 'plannedHH':
        if (value < 0) {
          return;
        }
        plannedDurationArray[0] = value;
        break;
      case 'plannedMM':
        if (value > 59 || value < 0) {
          return;
        }
        plannedDurationArray[1] = value;
        break;
      case 'plannedSS':
        if (value > 59 || value < 0) {
          return;
        }
        plannedDurationArray[2] = value;
        break;
      default:
    }
    setFieldValue(inputName, value);
    const formatDuration = HHMMSSToSeconds(
      `${plannedDurationArray[0]}:${plannedDurationArray[1]}:${plannedDurationArray[2]}`
    );
    setPlannedDuration(secondsToHHMMSS(formatDuration));
  };

  const handleCompanyChange = setFieldValue => company => {
    setFieldValue('companyId', company);
    if (!company) {
      return;
    }
    updateFormOnCompanyChange(company);
  };

  const handleFleetChange = setFieldValue => fleet => {
    setFieldValue('fleet', fleet);
    setFleetId(fleet);
  };

  const onGeosuggestSuccess = location => {
    dispatch(handleAddAddress(location.lat, location.lng));
  };

  const handleAddAddress = (latitude, longitude) => () => {
    const lat = Number(latitude);
    const lng = Number(longitude);
    if (lat && lng && isLatLngValid(lat, lng)) {
      const latLng = new google.maps.LatLng({ lat, lng });
      const marker = {
        position: latLng,
        label: (waypoints.filter(pt => pt.seq).length + 1).toString()
      };
      getAddressFromLatLng(geocoder, latLng).then(result => {
        const formattedAddress = result.formatted_address;
        const location = journeyHelpers.getAddressDetails(result);
        setNewMarker({ ...marker, address: formattedAddress, location: location });
        setMapCenter(latLng);
        setZoomLevel(10);
      });
    } else {
      dispatch(
        openToast({
          type: ToastType.Warning,
          message: 'Invalid coordinates.',
          autohide: true
        })
      );
    }
  };

  const handleClearButton = setFieldValue => () => {
    const route = mapDirections && mapDirections.routes[0];
    const totalDuration = route && journeyHelpers.computeTotalDuration(route);
    if (totalDuration) {
      setPlannedDuration(secondsToHHMMSS(totalDuration));
      const formattedPlannedDuration = secondsToHHMMSS(totalDuration);
      setFieldValue('plannedHH', formattedPlannedDuration.split(':')[0]);
      setFieldValue('plannedMM', formattedPlannedDuration.split(':')[1]);
      setFieldValue('plannedSS', formattedPlannedDuration.split(':')[2]);
    } else {
      setPlannedDuration(secondsToHHMMSS(0));
      setFieldValue('plannedHH', '00');
      setFieldValue('plannedMM', '00');
      setFieldValue('plannedSS', '00');
    }
  };

  // update the waypoints when the route is changed by drag. and update the waypoints on delete as well
  useEffect(() => {
    console.log('Directions have changed, updating the waypoints: ');
    if (mapDirections?.request?.waypoints) {
      updateWaypoints(mapDirections.request.waypoints);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapDirections]);

  useEffect(() => {
    if (initialGeofences) {
      setGeofences(initialGeofences?.filter(g => !isManagedGeofence(geofenceProviders, g)));
    }
  }, [initialGeofences]);

  useEffect(() => {
    if (initialFleets && currentCompany.id) {
      const newFleets = initialFleets
        .filter(fleet => fleet.id)
        .map(fleet => ({
          value: fleet.id,
          label: `${fleet?.name} (${journeyHelpers.getCompanyName(companies, fleet?.company?.id)})`,
          checked: !!initiallyCheckedFleets?.find(icf => icf.fleetId === fleet.id)
        }));
      setFleets([
        {
          value: 0,
          label: t('Common.AllFleets'),
          checked: !!initiallyCheckedFleets?.find(icf => icf.fleetId === 0)
        },
        ...newFleets,
        {
          value: -1,
          label: t('Common.NoFleet'),
          checked: !!initiallyCheckedFleets?.find(icf => icf.fleetId === -1)
        }
      ]);
    }
  }, [initialFleets, initiallyCheckedFleets, currentCompany, t, companies]);

  useEffect(() => {
    switch (stopId) {
      case '1': // Address
        setShowFleets(false);
        setShowStopDropdown(false);
        setDisabledAddress(false);
        break;
      case '2': // Geofence
        setShowFleets(true);
        setShowStopDropdown(true);
        setDisabledAddress(true);
        break;
      default:
        setShowFleets(false);
        setShowStopDropdown(true);
        setDisabledAddress(true);
        break;
    }
  }, [stopId]);

  useEffect(() => {
    switch (stopId) {
      case '2': //Geofence
        const filteredGeofences = geofences.filter(geofence => {
          const geoFleetsIds = geofence.fleets.map(fleet => fleet.id);
          if (Number(fleetId) === 0) {
            //All Fleets
            return true;
          }
          if (Number(fleetId) === -1) {
            //No Fleet
            if (geoFleetsIds.length === 0) {
              return true;
            }
          }
          if (geoFleetsIds.some(id => Number(fleetId) === id)) {
            return true;
          } else return false;
        });
        setStopDropdownValues(filteredGeofences);
        break;
      case '3': //Branch
        setStopDropdownValues(
          journeyStops
            .filter(stop => stop?.type?.name === 'Branch' && stop.GPS)
            .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
        );
        break;
      case '4': //Depot
        setStopDropdownValues(
          journeyStops
            .filter(stop => stop?.type?.name === 'Depot' && stop.GPS)
            .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
        );
        break;
      case '5': //Supplier
        setStopDropdownValues(
          journeyStops
            .filter(stop => stop?.type?.name === 'Supplier' && stop.GPS)
            .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
        );
        break;
      case '6': //Customer
        setStopDropdownValues(
          journeyStops.filter(stop => stop?.type?.name === 'Customer' && stop.GPS)
        );
        break;
      default:
    }
  }, [journeyStops, stopId, fleetId, geofences]);

  useEffect(() => {
    switch (journeyTypeId) {
      case '2':
      case 2:
        setShowSearchableFleets(true);
        break;
      default:
        setShowSearchableFleets(false);
        break;
    }
  }, [journeyTypeId]);

  useEffect(() => {
    const region = regions.find(
      region => region.code.toLowerCase() === currentCompany.country.toLowerCase()
    );
    try {
      const geocode = JSON.parse(region?.geocode || '{}');
      if (geocode.zoom) {
        setZoomLevel(geocode.zoom);
        setMapCenter({ lat: geocode.lat, lng: geocode.lng });
      }
    } catch (e) {
      console.error(e);
    }
  }, [regions, currentCompany]);

  const updateFormOnCompanyChange = newCompanyId => {
    setFleetsLoaded(false);
    // This should only be updated if we're not editing
    getFleetsByCompany(newCompanyId, userKey).then(newFleets => {
      const formFleets = JSON.parse(JSON.stringify(newFleets))
        .filter(fleet => fleet.id)
        .map(fleet => ({
          value: fleet.id,
          label: `${fleet?.name} (${journeyHelpers.getCompanyName(companies, fleet?.company?.id)})`,
          checked: false
        }));
      setFleets([
        { value: 0, label: t('Common.AllFleets') },
        ...formFleets,
        { value: -1, label: t('Common.NoFleet') }
      ]);
      setFleetsLoaded(true);
    });
    getGeofencesByCompany(newCompanyId, userKey).then(newGeofences => {
      setGeofences(newGeofences?.filter(ng => !isManagedGeofence(geofenceProviders, ng)));
    });
  };

  useEffect(() => {
    dispatch(setPageTitle(t('JourneyPlanner.Form.AddNewJourney')));
    dispatch(setBackButton(true));
    if (action === 'edit') {
      dispatch(setPageTitle(t('JourneyPlanner.Form.EditJourney')));
    }
    if (action === 'copy') {
      dispatch(setPageTitle(t('JourneyPlanner.Form.CopyJourney')));
    }
  }, [dispatch, action, t]);

  //EDIT and COPY
  useEffect(() => {
    if (action !== 'add') {
      if (id && userKey && !journey) {
        getJourneyById(id, userKey, company.id).then(journey => {
          dispatch(setPageTitle(`${t(`JourneyPlanner.Form.${action}`)} ${journey?.name}`));
          //draw old route
          if (journey.type === JOURNEY_TYPE.TYPE.GEOFENCE_TRIP) {
            setStopId('2'); //Geofence
            setStopTypes(allStopTypes.filter(type => Number(type.id) === 2));
          }
          const plannedDurationHHMMSS = secondsToHHMMSS(journey.estimatedDuration).split(':');
          setFormikInitialValues({
            name: journey.name,
            type: JOURNEY_TYPE.TYPEID[journey.type],
            companyId: journey.companyId,
            plannedHH: plannedDurationHHMMSS[0],
            plannedMM: plannedDurationHHMMSS[1],
            plannedSS: plannedDurationHHMMSS[2]
          });
          if (journey?.companyId !== currentCompany.id) {
            updateFormOnCompanyChange(journey?.companyId);
          }
          setPlannedDuration(secondsToHHMMSS(journey.estimatedDuration));
          setInitiallyCheckedFleets(journey.tripAssociations);
          setJourneyTypeId(JOURNEY_TYPE.TYPEID[journey.type]);

          const geofences = journey.stops.reduce((acc, stop) => {
            stop.geofence && acc.push({ ...stop.geofence });
            return acc;
          }, []);

          const filteredStops = journeyHelpers.getFilteredStops(journey.stops);

          setJourney(journey);

          if (journey.attr) {
            const directionsRequest = JSON.parse(journey.attr);
            if (directionsRequest.waypoints?.length) {
              const newWaypoints = [...filteredStops];
              directionsRequest.waypoints.forEach((waypt, index) => {
                if (waypt.stopover === false) {
                  newWaypoints.splice(index + 1, 0, { ...waypt, stopover: false });
                }
              });
              setWaypoints(newWaypoints);
            } else {
              setWaypoints(filteredStops);
            }
          } else {
            setWaypoints(filteredStops);
          }

          if (geofences) {
            setJourneyGeofences(geofences);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, id, journeys, userKey, dispatch, company.id]);

  const onStopChange = id => {
    switch (stopId) {
      case '2': //Geofence
        const geofence = geofences.find(g => g.id.toString() === id.toString());
        const lat = geofence.centroid.Lat;
        const lng = geofence.centroid.Lng;
        const geofenceMarker = {
          position: new google.maps.LatLng({ lat, lng }),
          label: (waypoints.filter(pt => pt.seq).length + 1).toString(),
          type: 'geofence',
          geofence: geofence
        };
        setNewMarker(geofenceMarker);
        setZoomLevel(10);
        setMapCenter({ lat, lng });
        break;
      case '3': //Branch
      case '4': //Depot
      case '5': //Supplier
      case '6': //Customer
        const location = journeyStops.find(loc => loc.id.toString() === id.toString());
        const locationMarker = {
          position: new google.maps.LatLng({
            lat: location.GPS.Lat,
            lng: location.GPS.Lng
          }),
          label: (waypoints.filter(pt => pt.seq).length + 1).toString(),
          type: 'location',
          location: location
        };
        setNewMarker(locationMarker);
        setMapCenter({
          lat: location.GPS.Lat,
          lng: location.GPS.Lng
        });
        break;
      default:
    }
  };

  const onMapDoubleClick = (event, setFieldValue) => {
    // prevent adding marker on the map if Geofence Trip type is selected
    if (Number(journeyTypeId) === JOURNEY_TYPE.ID.GEOFENCE_TRIP) {
      return;
    }
    const marker = {
      position: event.latLng,
      label: (waypoints.filter(pt => pt.seq).length + 1).toString()
    };

    getAddressFromLatLng(geocoder, event.latLng).then(result => {
      const formattedAddress = result.formatted_address;
      const location = journeyHelpers.getAddressDetails(result);
      setNewMarker({ ...marker, address: formattedAddress, location: location });
      setCoordinatesInForm(setFieldValue, event, formattedAddress);
    });
  };

  const setCoordinatesInForm = (setFieldValue, event = null, address = null) => {
    setFieldValue('latitude', event?.latLng?.lat()?.toFixed(5) || '');
    setFieldValue('longitude', event?.latLng?.lng()?.toFixed(5) || '');
    setFieldValue('address', address || '');
  };

  const handleStopDelete = data => () => {
    updateWaypointsDelete(data.seq, mapDirections);
    setIsFormDirty(true);
  };

  const handleSort = data => () => {
    const newWaypoints = [...data].map((d, index) => ({ ...d, seq: index + 1 }));
    setWaypoints(newWaypoints);
  };

  const addStop = setFieldValue => () => {
    if (!newMarker) return;

    const newData = {
      formattedAddress: newMarker.address,
      seq: (waypoints.filter(pt => pt.seq).length + 1).toString(),
      latitude: newMarker.position.lat(),
      longitude: newMarker.position.lng(),
      ...newMarker
    };

    const newWaypoints = [...waypoints, newData];
    setWaypoints(newWaypoints);

    if (newMarker.geofence) {
      const newGeofences = [...journeyGeofences, { ...newMarker.geofence }];
      setJourneyGeofences(newGeofences);
    }

    setIsFormDirty(true);
    //Reset the new marker (dropped pin);
    setNewMarker(null);
  };

  const removeDragDropChanges = () => {
    setWaypoints(waypoints.filter(w => w.seq && w.stopover === undefined));
    setIsFormDirty(true);
  };

  const submitData = (values, actions, plannedDurationInSeconds) => {
    actions.setSubmitting(true);
    const stops = waypoints
      .filter(stop => stop.seq)
      .map(stop => {
        if (stop.location?.id) {
          return {
            seq: stop.seq,
            location: {
              id: stop.location.id
            }
          };
        }
        if (stop.geofence) {
          return {
            seq: stop.seq,
            geofence: {
              id: stop.geofence.id
            }
          };
        }
        return {
          seq: stop.seq,
          location: {
            name: stop.location?.name,
            type: { id: stop.location?.type?.id || 12 }, // Journey
            address: stop?.location?.address,
            GPS: {
              Lat: stop.latitude,
              Lng: stop.longitude
            }
          }
        };
      });

    const legs = mapDirections.routes[0].legs;
    const formattedLegs = legs.map((leg, index) => {
      let steps = JSON.parse(JSON.stringify(leg.steps || []));
      steps = steps.map(step => {
        const newPath = step.path.map(pth => Object.values(pth));
        return {
          ...step,
          path: newPath
        };
      });
      return {
        startSeq: index + 1,
        endSeq: index + 2,
        routeDefinition: JSON.stringify(steps),
        distance: leg.distance.value / 1000,
        duration: leg.duration.value
      };
    });

    const type = journeyTypes.find(type => type.id.toString() === journeyTypeId.toString())
      ?.apiName;
    const payload = {
      name: values.name,
      type: type,
      estimatedDuration: plannedDurationInSeconds,
      stops: stops,
      legs: formattedLegs,
      ...(type === JOURNEY_TYPE.TYPE.ROUTE_COMPLIANCE && {
        tripAssociations: journeyHelpers.getTripAssociations(
          userCheckedFleets,
          journey?.tripAssociations
        )
      }),
      ...(mapDirections && { attr: JSON.stringify(mapDirections.request) })
    };

    let url = `${API_PATH}/journeys/?company_id=${values.companyId}`;
    let method = 'POST';
    let successMessage = t('JourneyPlanner.Notifications.Added', { name: values.name });

    if (action === 'edit') {
      url = `${API_PATH}/journeys/${id}`;
      method = 'PUT';
      successMessage = t('JourneyPlanner.Notifications.Updated', { name: values.name });
    }

    request(method, url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(payload)
      .then(res => {
        setPromptModalWhenLeaving(false);
        dispatch(
          openToast({
            type: ToastType.Success,
            message: successMessage
          })
        );
        dispatch(fetchJourneys());
        actions.setSubmitting(false);
        canHistoryGoBack(history, '/journeyplanner');
      })
      .catch(err => {
        actions.setSubmitting(false);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(err)
          })
        );
      });
  };

  const onSubmit = (values, actions) => {
    if (waypoints?.length < 2) {
      dispatch(
        openToast({
          type: ToastType.Warning,
          message: t('JourneyPlanner.Notifications.IncompleteJourney'),
          autohide: true
        })
      );
      actions.setSubmitting(false);
      return;
    }
    const legs = mapDirections.routes[0].legs;
    const legsDurationInSeconds = journeyHelpers.getLegsDuration(legs, 'seconds');
    const plannedDurationSeconds = HHMMSSToSeconds(
      `${values.plannedHH}:${values.plannedMM}:${values.plannedSS}`
    );
    if (plannedDurationSeconds < legsDurationInSeconds) {
      actions.setSubmitting(false);
      confirmationModal(
        t('JourneyPlanner.Modal.PlannedDuration'),
        t('JourneyPlanner.Modal.PlannedDurationQuestion'),
        t('Common.Modal.OK'),
        t('Common.Modal.Cancel'),
        () => submitData(values, actions, plannedDurationSeconds)
      );
    } else {
      submitData(values, actions, plannedDurationSeconds);
    }
  };

  if ((action === 'edit' || action === 'copy') && !journey) {
    return <LoadingCentered />;
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...formikInitialValues,
          ...(action === 'add' && { companyId: currentCompany.id })
        }}
        validationSchema={validationSchema()}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, dirty, setFieldValue, values }) => (
          <>
            <EditRouteGuard when={dirty && promptModalWhenLeaving} navigate={history.push} />
            <Form id="JourneyForm">
              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>
                  <Row>
                    <div className={styles.formContainer}>
                      <Col>
                        <FormTitle
                          title={
                            action === 'edit'
                              ? `${t('JourneyPlanner.Form.EditJourney')}`
                              : `${t('JourneyPlanner.Form.NewJourney')}`
                          }
                          underlined
                          customStylingUnderline={{ marginRight: '32px' }}
                        />
                        <Row>
                          <Col span={12}>
                            <FormInput
                              name="name"
                              label={t('JourneyPlanner.Form.Name')}
                              placeholder={t('JourneyPlanner.Form.NamePlaceholder')}
                              isRequired
                            />
                          </Col>
                          <Col span={12}>
                            <FormSelect
                              name="type"
                              label={t('JourneyPlanner.Form.Type')}
                              onChange={handleJourneyTypeChange(setFieldValue)}
                              values={journeyTypes.map(type => ({
                                label: t(`JourneyPlanner.JourneyType.${type.name}`),
                                value: type.id
                              }))}
                              supressSorting
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <FormSelect
                              name="companyId"
                              label={t('Common.Company')}
                              onChange={handleCompanyChange(setFieldValue)}
                              values={companies.map(type => ({
                                label: type.name,
                                value: type.id
                              }))}
                              isDisabled={waypoints?.length > 0}
                            />
                          </Col>
                          <FormSelect
                            name="network"
                            label="Network"
                            values={[]}
                            isDisabled
                            isHidden
                          />
                        </Row>
                      </Col>
                    </div>
                    {showSearchableFleets && (
                      <Col className={styles.fleetSearchWrapper}>
                        <SearchableListMultiSelect
                          name="fleets"
                          label={t('Common.Fleet')}
                          placeholder={t('JourneyPlanner.Form.SearchForFleetPlaceholder')}
                          allLabel={t('Common.AllFleets')}
                          values={fleets}
                          initialValues={EMPTY_ARRAY}
                          onCheckboxChanged={() => setIsFormDirty(true)}
                          setFieldValue={setUserCheckedFleets}
                          height={250}
                          width={'100%'}
                          isLoading={!fleetsLoaded}
                        />
                      </Col>
                    )}
                  </Row>

                  <div className={styles.formStopContainer}>
                    <FormTitle title={t('JourneyPlanner.Form.AddStops')} underlined />
                    <div className="infoContainer">{t('JourneyPlanner.Form.AddStopsTooltip')}</div>
                    <Row>
                      <Col span={6}>
                        <FormSelect
                          name="stopType"
                          label={t('JourneyPlanner.Form.StopType')}
                          onChange={handleStopTypeChange(setFieldValue)}
                          values={stopTypes.map(type => ({
                            label: t(`JourneyPlanner.StopType.${type.name}`),
                            value: type.id
                          }))}
                          supressSorting
                        />
                      </Col>
                      {showFleets && (
                        <Col span={6}>
                          <FormSelect
                            name="fleet"
                            label={t('Common.Fleets')}
                            values={fleets}
                            onChange={handleFleetChange(setFieldValue)}
                            supressSorting
                          />
                        </Col>
                      )}
                      {showStopDropdown && (
                        <Col span={6}>
                          <FormSelect
                            name="stopDropdown"
                            label={t('JourneyPlanner.Form.Stop')}
                            onChange={id => onStopChange(id, setFieldValue)}
                            placeholder={t('JourneyPlanner.Form.StopPlaceholder')}
                            values={stopDropdownValues.map(stop => ({
                              value: stop.id,
                              label: stop.name
                            }))}
                          />
                        </Col>
                      )}
                    </Row>
                    <Row align="bottom">
                      <Col span={3}>
                        <FormInput
                          disabled={disabledAddress}
                          name="latitude"
                          label={t('Common.Latitude')}
                          placeholder={t('Common.Latitude')}
                        />
                      </Col>
                      <Col span={3}>
                        <FormInput
                          disabled={disabledAddress}
                          name="longitude"
                          label={t('Common.Longitude')}
                          placeholder={t('Common.Longitude')}
                        />
                      </Col>
                      <Col span={6} className={styles.geosuggestWrapper}>
                        <GeoSuggest
                          disabled={disabledAddress}
                          inputClassName={styles.geosuggest}
                          placeholder={t('Common.Address')}
                          onSuggestSelect={suggest => {
                            suggest && onGeosuggestSuccess(suggest.location);
                          }}
                        />
                      </Col>
                      <Col className={styles.verifyCoordinatesBtn}>
                        <Button
                          disabled={disabledAddress || !(values.latitude && values.longitude)}
                          type="primary"
                          size="large"
                          onClick={handleAddAddress(values.latitude, values.longitude)}
                          id={BUTTON_IDS.journeyPlannerFormVerify}
                        >
                          {t('JourneyPlanner.Form.VerifyCoordinates')}
                        </Button>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Button
                        type="primary"
                        size="large"
                        id={BUTTON_IDS.journeyPlannerFormAdd}
                        onClick={addStop(setFieldValue)}
                      >
                        {t('JourneyPlanner.Form.AddNewStop')}
                      </Button>
                    </Row>
                    <FormTitle title="" underlined />
                    <div
                      className={styles.formMapContainer}
                      style={{ padding: '0', marginBottom: '0' }}
                    >
                      <Row className={styles.routeWrapper}>
                        <Col className={styles.routeLeft}>
                          <RoutePreview
                            waypoints={waypoints}
                            localization={localization}
                            onRemove={handleStopDelete}
                            onReverseClick={handleReverse}
                            mapDirections={mapDirections || null}
                            onSortCb={handleSort}
                          />
                        </Col>
                        <Col className={styles.routeRight}>
                          {hasManualDragEdits && (
                            <Alert
                              showIcon
                              type="warning"
                              message={
                                <div>
                                  {t('JourneyPlanner.Form.AlertRouteChanged')}
                                  <div>
                                    {t('JourneyPlanner.Form.AlertRemoveAction')}{' '}
                                    <Button onClick={removeDragDropChanges}>
                                      {t('JourneyPlanner.Form.Remove')}
                                    </Button>
                                  </div>
                                </div>
                              }
                            ></Alert>
                          )}
                          <JourneyMap
                            ref={journeyMapFormRef}
                            mapCenter={mapCenter}
                            zoomLevel={zoomLevel}
                            isMarkerShown
                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`}
                            loadingElement={<div className={styles.journeyMapLoadingElement} />}
                            containerElement={<div className={styles.journeyMapContainerElement} />}
                            mapElement={<div className={styles.journeyMapElement} />}
                            onMapDoubleClick={event => onMapDoubleClick(event, setFieldValue)}
                            waypoints={waypoints}
                            newMarker={newMarker}
                            geofences={journeyGeofences}
                            {...handleMapDirectionsChange}
                            handleInfoWindowAdd={handleInfoWindowAdd}
                          />
                        </Col>
                      </Row>
                    </div>
                    <FormTitle title="" underlined />
                    <Row
                      className={styles.routeWrapper}
                      style={{ height: 'auto', marginTop: '24px' }}
                    >
                      <Col className={styles.routeLeft}>
                        <div
                          className={styles.routeHeader}
                          style={{ justifyContent: 'space-between', marginBottom: '16px' }}
                        >
                          <FormTitle
                            title={t('JourneyPlanner.Form.PlannedDuration')}
                            customStyling={{ paddingTop: 0 }}
                          ></FormTitle>
                          <div className={styles.iconColumn}>
                            <span>
                              <i style={{ content: `url(${timerIcon})` }} />
                            </span>
                            {plannedDuration || '00:00:00'}
                          </div>
                        </div>
                        <Row className={styles.plannedDuration}>
                          <Col>
                            <FormInput
                              type="number"
                              label=""
                              name="plannedHH"
                              customStyle={{ width: '67px' }}
                              onChange={handlePlannedDurationChange(
                                'plannedHH',
                                setFieldValue,
                                values
                              )}
                            />
                          </Col>
                          <Col className={styles.plannedDurationDelimitator}>:</Col>
                          <Col>
                            <FormInput
                              type="number"
                              label=""
                              name="plannedMM"
                              customStyle={{ width: '67px' }}
                              onChange={handlePlannedDurationChange(
                                'plannedMM',
                                setFieldValue,
                                values
                              )}
                            />
                          </Col>
                          <Col className={styles.plannedDurationDelimitator}>:</Col>
                          <Col>
                            <FormInput
                              type="number"
                              label=""
                              name="plannedSS"
                              customStyle={{ width: '67px' }}
                              onChange={handlePlannedDurationChange(
                                'plannedSS',
                                setFieldValue,
                                values
                              )}
                            />
                          </Col>
                          <Col className={styles.clearButton}>
                            <Button
                              type="primary"
                              size="medium"
                              onClick={handleClearButton(setFieldValue)}
                              id={BUTTON_IDS.journeyPlannerFormClear}
                            >
                              {t('Common.Clear')}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col className={styles.routeRight}>
                        <div className="infoContainer">
                          {t('JourneyPlanner.Form.PlannedDurationTooltip')}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className={styles.formFooter}>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  disabled={(!dirty && !isFormDirty) || !isValid || isSubmitting || isUpdatingMap}
                  id={BUTTON_IDS.journeyPlannerFormSave}
                >
                  {t('Common.SaveButton')}
                </Button>
                <Button
                  onClick={history.goBack}
                  type="secondary"
                  size="large"
                  id={BUTTON_IDS.journeyPlannerFormCancel}
                >
                  {t('Common.CancelButton')}
                </Button>
              </div>
            </Form>
            {isSubmitting && (
              <div className={styles.loadingWrapper}>
                <LoadingCentered />
              </div>
            )}
          </>
        )}
      </Formik>
    </>
  );
};
