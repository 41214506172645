import React from 'react';
import { Modal as AntModal } from 'antd';

const Modal = ({ children, title, visible, onCancel, width, wrapClassName, ...props }) => {
  return (
    <AntModal
      title={title}
      open={visible}
      centered={true}
      onCancel={onCancel}
      width={width}
      wrapClassName={wrapClassName}
      {...props}
    >
      {children}
    </AntModal>
  );
};

export default Modal;
