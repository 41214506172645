import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18nextConfig';
import { startCase } from 'lodash';
import { sortStrings, formatForCsv } from 'utils/strings';
import { USER_STATUSES, USER_TYPES, PATHS } from './constants';
import { entities } from 'features/permissions';
import moment from 'moment';

export const buildFileName = selectedLens => {
  if (selectedLens === 'all') {
    return `${i18n.t('ExportToExcel.Users')}`;
  } else {
    return `${startCase(selectedLens)}s`;
  }
};

export const canLogInAsUser = (
  user,
  loggedInUserInfo,
  isELDCompany,
  hasSiteAdminOrResellerRole
) => {
  return (
    (loggedInUserInfo.siteAdmin || hasSiteAdminOrResellerRole) &&
    !user.siteAdmin &&
    loggedInUserInfo.id !== user.id &&
    user.status === USER_STATUSES.ENABLED &&
    !(isELDCompany && [USER_TYPES.DRIVER, USER_TYPES.SUPPORT_PERSONNEL].includes(user?.type?.name))
  );
};

export const hyperlinkToLocation = (
  branch,
  userData,
  LOCATION_VIEW_URL,
  canAccessNonCameraFeatures = true
) =>
  branch && branch?.id !== -1 && canAccessNonCameraFeatures ? (
    <Link to={`${LOCATION_VIEW_URL}${userData?.location?.id}`}>{branch.name || ''}</Link>
  ) : (
    i18n.t('Users.NoBranch')
  );

export const getPermissionsQueryParams = permissionsArray => {
  if (!permissionsArray?.length) {
    // none selected
    return 'NONE';
  }
  if (permissionsArray?.length) {
    // at least one value is selected
    if (permissionsArray.find(value => value.id === 0)) {
      return 'ALL';
    } else {
      return 'SELECTED';
    }
  }
};

export const getExternalPins = (users = [], companyId) =>
  users.reduce((acc, user) => {
    user?.company?.id === Number(companyId) && user.externalPin && acc.push(user.externalPin);
    return acc;
  }, []);

export const getElectronicPins = (users = [], companyId) =>
  users.reduce((acc, user) => {
    user?.company?.id === Number(companyId) && user.electronicPin && acc.push(user.electronicPin);
    return acc;
  }, []);

export const getElectronicPin2s = (users = [], companyId) =>
  users.reduce((acc, user) => {
    user?.company?.id === Number(companyId) && user.electronicPin2 && acc.push(user.electronicPin2);
    return acc;
  }, []);

export const getDefaultTimezoneValue = ({ company, regions }) => {
  if (!company || !regions.length) {
    return;
  }
  const region = regions.find(region => region.code === company.country);
  try {
    return JSON.parse(region?.config)?.default_time_zone;
  } catch (err) {
    return company?.statisticsTz || '';
  }
};

export const getPrimaryButton = ({ can, history }) => {
  if (!can({ everyEntity: [entities.USER_CREATE] })) {
    return;
  }
  return {
    name: i18n.t('Users.AddNewUser'),
    onClick: () => {
      history.push(`${PATHS.USER_ADD}`);
    }
  };
};

export const getMoreButtons = ({
  handleExportExcel,
  handleBulkImport,
  handleExportCSV,
  isLoadingCsv,
  isLoadingExcel,
  isExportAllowed = true
}) => {
  return [
    {
      name: i18n.t('Common.BulkImport'),
      onClick: handleBulkImport,
      canPermissions: {
        everyEntity: [entities.USER_CREATE]
      },
      id: 'btn_bulkImport'
    },
    {
      name: i18n.t('Common.ExportToCSV'),
      onClick: handleExportCSV,
      isLoading: isLoadingCsv,
      disabled: !isExportAllowed,
      id: 'btn_exportCsv'
    },
    {
      name: i18n.t('Common.ExporttoExcel'),
      onClick: handleExportExcel,
      isLoading: isLoadingExcel,
      disabled: !isExportAllowed,
      id: 'btn_exportExcel'
    }
  ].sort((a, b) => sortStrings(a.name, b.name));
};

export const prepareFileForExcelExport = data => {
  const { filteredUsers, companies, locations, dateFormat, newTimezones } = data;
  let rows = [];
  if (filteredUsers) {
    rows = filteredUsers.map(user => {
      const company = companies.find(comp => comp.id === user.companyId);
      const location = locations.find(
        location => user.location && location.id === user.location?.id
      );

      const globalRoles = user.roles
        ?.filter(role => role.global)
        .map(r => r.name)
        .join('; ');
      const companyRoles = user.roles
        ?.filter(role => !role.global)
        .map(r => r.name)
        .join('; ');
      const noFleet = user?.fleets?.length === 1 && user?.fleets?.find(f => f.id === -1);
      const fleetPermissions = user.fleets ? user.fleets?.map(f => f.name)?.join('; ') : 'ALL';
      const noBranch = user?.locations?.length === 1 && user?.locations?.find(l => l.id === -1);
      const branchPermissions = user.locations
        ? user.locations?.map(l => l.name)?.join('; ')
        : 'ALL';
      const row = {
        [i18n.t('ExportToExcel.Firstname')]: user.firstName || '',
        [i18n.t('ExportToExcel.Lastname')]: user.lastName || '',
        [i18n.t('ExportToExcel.Email')]: user.email || '',
        [i18n.t('ExportToExcel.Username')]: user.username || '',
        [i18n.t('ExportToExcel.Password')]: '',
        [i18n.t('ExportToExcel.PIN')]: user.externalPin || '',
        [i18n.t('ExportToExcel.ElectronicID1')]: user.electronicPin || '',
        [i18n.t('ExportToExcel.ElectronicID2')]: user.electronicPin2 || '',
        [i18n.t('ExportToExcel.MobileNumber')]: user.mobile || '',
        [i18n.t('ExportToExcel.TimeZone')]:
          newTimezones.find(t => t.id === user.timeZone)?.label || user.timeZone,
        [i18n.t('ExportToExcel.UserType')]: user.type && user.type?.code ? user.type?.code : '',
        [i18n.t('ExportToExcel.LocationBranch')]: location?.name || '',
        [i18n.t('ExportToExcel.LicenseCountry')]: user.licenceCountry || '',
        [i18n.t('ExportToExcel.LicenseState')]: user.licenceStateName || '',
        [i18n.t('ExportToExcel.LicenseNumber')]: user.licenceNumber || '',
        [i18n.t('ExportToExcel.Company')]: company?.name || '',
        [i18n.t('ExportToExcel.GlobalRole')]: globalRoles && formatForCsv(globalRoles),
        [i18n.t('ExportToExcel.CompanyRole')]: companyRoles && formatForCsv(companyRoles),
        [i18n.t('ExportToExcel.FleetPermissions')]: noFleet
          ? 'NONE'
          : fleetPermissions && formatForCsv(fleetPermissions),
        [i18n.t('ExportToExcel.BranchPermissions')]: noBranch
          ? 'NONE'
          : branchPermissions && formatForCsv(branchPermissions),
        [i18n.t('ExportToExcel.Ruleset')]:
          user.rulesets && user.rulesets[0] ? user.rulesets[0]?.ruleset : '',
        [i18n.t('ExportToExcel.RulesetValidFrom')]:
          user.rulesets && user.rulesets[0]
            ? moment(user.rulesets[0]?.enabledAt).format(dateFormat)
            : '',
        [i18n.t('ExportToExcel.ExternalID')]: user.externalId || '',
        [i18n.t('ExportToExcel.SignInCount')]: user?.userSession?.signInCount || '',
        [i18n.t('ExportToExcel.LastSignIn')]: user.userSession?.lastSignInAt
          ? moment(user.userSession?.lastSignInAt).format(dateFormat)
          : '',
        [i18n.t('ExportToExcel.LastDriverEvent')]: user.userSession?.lastFatigueEventAt
          ? moment(user.userSession?.lastFatigueEventAt).format(dateFormat)
          : '',
        [i18n.t('ExportToExcel.CreatedAt')]: moment(user.createdAt).format(dateFormat),
        [i18n.t('ExportToExcel.UpdatedAt')]: moment(user.updatedAt).format(dateFormat)
      };

      return row;
    });
  }
  return rows;
};

export const prepareDataForCsvExport = ({
  users,
  branches,
  newTimezones,
  companies,
  dateFormat
}) => {
  return users.map(entry => {
    const company = companies.find(comp => comp.id === entry.companyId);
    const location = branches.find(
      location => entry.location && location.id === entry.location?.id
    );
    const globalRoles = entry.roles
      ?.filter(role => role.global)
      .map(r => r.name)
      .join('; ');
    const companyRoles = entry.roles
      ?.filter(role => !role.global)
      .map(r => r.name)
      .join('; ');
    const noFleet = entry?.fleets?.length === 1 && entry?.fleets?.find(f => f.id === -1);
    const fleetPermissions = entry.fleets ? entry.fleets?.map(f => f.name)?.join('; ') : 'ALL';
    const noBranch = entry?.locations?.length === 1 && entry?.locations?.find(l => l.id === -1);
    const branchPermissions = entry.locations
      ? entry.locations?.map(l => l.name)?.join('; ')
      : 'ALL';
    return {
      [i18n.t('Users.CSV.Firstname')]: entry.firstName && formatForCsv(entry.firstName),
      [i18n.t('Users.CSV.Lastname')]: entry.lastName && formatForCsv(entry.lastName),
      [i18n.t('Users.CSV.Email')]: entry.email && formatForCsv(entry.email),
      [i18n.t('Users.CSV.Username')]: entry.username && formatForCsv(entry.username),
      [i18n.t('Users.CSV.Password')]: '',
      [i18n.t('Users.CSV.PIN')]: entry.externalPin && formatForCsv(entry.externalPin),
      [i18n.t('Users.CSV.ElectronicID1')]: entry.electronicPin && formatForCsv(entry.electronicPin),
      [i18n.t('Users.CSV.ElectronicID2')]:
        entry.electronicPin2 && formatForCsv(entry.electronicPin2),
      [i18n.t('Users.CSV.Mobilenumber')]: entry.mobile && formatForCsv(entry?.mobile),
      [i18n.t('Users.CSV.Timezone')]: formatForCsv(
        newTimezones.find(t => t.id === entry.timeZone)?.label || entry.timeZone
      ),
      [i18n.t('Users.CSV.UserType')]: entry?.type?.name && formatForCsv(entry?.type?.name),
      [i18n.t('Users.CSV.Location')]: location?.name && formatForCsv(location?.name),
      [i18n.t('Users.CSV.LicenseCountry')]:
        entry.licenceCountry && formatForCsv(entry.licenceCountry),
      [i18n.t('Users.CSV.LicenseState')]:
        entry.licenceStateName && formatForCsv(entry.licenceStateName),
      [i18n.t('Users.CSV.LicenseNumber')]: entry.licenceNumber && formatForCsv(entry.licenceNumber),
      [i18n.t('Users.CSV.Company')]: company?.name && formatForCsv(company.name),
      [i18n.t('Users.CSV.GlobalRole')]: globalRoles && formatForCsv(globalRoles),
      [i18n.t('Users.CSV.CompanyRole')]: companyRoles && formatForCsv(companyRoles),
      [i18n.t('Users.CSV.FleetPermissions')]: noFleet
        ? 'NONE'
        : fleetPermissions && formatForCsv(fleetPermissions),
      [i18n.t('Users.CSV.BranchPermissions')]: noBranch
        ? 'NONE'
        : branchPermissions && formatForCsv(branchPermissions),

      [i18n.t('Users.CSV.Ruleset')]:
        entry.rulesets && entry.rulesets[0] ? formatForCsv(entry.rulesets[0]?.ruleset) : '',
      [i18n.t('Users.CSV.RulesetValidFrom')]:
        entry.rulesets && entry.rulesets[0]
          ? formatForCsv(moment(entry.rulesets[0]?.enabledAt).format(dateFormat))
          : '',
      [i18n.t('Users.CSV.ExternalID')]: entry.externalId && formatForCsv(entry.externalId),
      [i18n.t('Users.CSV.SignInCount')]: entry?.userSession?.signInCount,
      [i18n.t('Users.CSV.LastSignIn')]: entry.userSession?.lastSignInAt
        ? formatForCsv(moment(entry.userSession?.lastSignInAt).format(dateFormat))
        : '',
      [i18n.t('Users.CSV.LastDriverEvent')]: entry.userSession?.lastFatigueEventAt
        ? formatForCsv(moment(entry.userSession?.lastFatigueEventAt).format(dateFormat))
        : '',
      [i18n.t('Users.CSV.CreatedAt')]: entry.createdAt
        ? formatForCsv(moment(entry.createdAt).format(dateFormat))
        : '',
      [i18n.t('Users.CSV.UpdatedAt')]: entry.updatedAt
        ? formatForCsv(moment(entry.updatedAt).format(dateFormat))
        : ''
    };
  });
};
