import React from 'react';
import styles from './FormTitle.module.scss';
import { Tooltip } from 'antd';

// Usage: <FormTitle title="custom title" underlined />
const FormTitle = ({
  title,
  underlined,
  fullWidth,
  customStyling = {},
  customStylingUnderline = {},
  icon,
  tooltipMsg,
  tooltipZIndex
}) => {
  return (
    <div style={{ width: fullWidth ? '100%' : 'auto' }}>
      <div className={styles.formTitle} style={{ ...customStyling }}>
        {title}
        {icon && tooltipMsg && (
          <Tooltip title={tooltipMsg} zIndex={tooltipZIndex}>
            <i className={`${icon} ${styles.icon}`} />
          </Tooltip>
        )}
      </div>
      {underlined && (
        <div className={styles.formUnderline} style={{ ...customStylingUnderline }}></div>
      )}
    </div>
  );
};

export default FormTitle;
