import { useTranslation } from 'react-i18next';
import { useCan } from '../permissions';
import { CameraModelConfig } from '../camera/CameraModelConfig';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useCameraChannel({ device, cameraInfo }) {
  const { t } = useTranslation();
  const can = useCan();
  const dispatch = useDispatch();

  const cameraChannels = useSelector(state => state.cameraInfo.cameraChannels?.[device?.imei]);

  const cameraConfig = CameraModelConfig[device?.model?.name];
  useEffect(() => {
    if (cameraConfig?.asyncChannelFetch && !cameraChannels?.data) {
      cameraConfig.getCameraChannelOptions({ t, deviceImei: device.imei, dispatch });
    }
  }, [device]);

  const channelInfo = useMemo(() => {
    const cameraModel = device?.model.name;
    const cameraConfig = CameraModelConfig[cameraModel];
    const canSelectChannel = cameraConfig?.canSelectCameraChannel?.(can);
    const channelOptions =
      cameraChannels?.data?.map(({ channel, name }) => ({
        label: name,
        value: channel
      })) ||
      (!cameraConfig?.asyncChannelFetch &&
        cameraConfig?.getCameraChannelOptions?.({ t, cameraInfo })) ||
      [];

    if (!canSelectChannel || !channelOptions.length) {
      return {
        supportChannelSelect: false
      };
    }

    return {
      supportChannelSelect: true,
      supportMultiChannel: cameraConfig?.supportMultiChannel,
      options: channelOptions
    };
  }, [t, cameraInfo, device, can, cameraChannels]);

  return channelInfo;
}
