import React from 'react';
import {
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell
} from 'recharts';
import styles from './BarChart.module.scss';

const COLOR = ['#8884d8'];

const MARGIN = {
  top: 10,
  right: 10,
  bottom: 10,
  left: 10
};

const DATA = [];

export const BarChart = ({
  layout = 'horizontal',
  data = DATA,
  xAxisDataKey,
  yAxisDataKey,
  xAxisDataLabel = value => value,
  yAxisDataLabel = value => value,
  yAxisLabel,
  xAxisLabel,
  barColor = COLOR,
  barSize = 20,
  height = 100,
  margin = MARGIN,
  activeBarIndex,
  onBarClick
}) => (
  <ResponsiveContainer width="100%" height={height}>
    <ReBarChart
      data={data}
      margin={margin}
      layout={layout}
      barSize={barSize}
      barCategoryGap={0.5}
      barGap={0.5}
    >
      {layout === 'horizontal' && <CartesianGrid vertical={false} stroke="#ebebeb" />}
      <XAxis
        dataKey={xAxisDataKey}
        {...(layout === 'horizontal'
          ? {
              type: 'category',
              interval: 0,
              tick: ({ payload, index, x, y }) => (
                <g transform={`translate(${x},${y})`}>
                  <text x={0} y={0} dy={16} alignmentBaseline="middle" textAnchor="middle">
                    {xAxisDataLabel(payload.value)}
                  </text>
                  <text x={-4} y={16} dy={16} alignmentBaseline="middle" textAnchor="middle">
                    {`(${yAxisDataLabel(data[index][yAxisDataKey])})`}
                  </text>
                </g>
              )
            }
          : {
              label: { value: xAxisLabel, position: 'insideBottom', offset: -10 },
              type: 'number',
              interval: 0,
              allowDecimals: false,
              domain: [0, 'dataMax']
            })}
      />
      {layout === 'horizontal' ? (
        <YAxis
          type="number"
          label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }}
          allowDecimals={false}
          interval={0}
          domain={[0, 'dataMax']}
        />
      ) : (
        <YAxis
          type="category"
          label={{ value: yAxisLabel, angle: -90, position: 'insideLeft' }}
          hide={true}
        />
      )}
      <Tooltip
        shared={false}
        content={({ active, payload: activePayload }) => {
          if (active && activePayload?.[0]) {
            const { color, payload } = activePayload[0];
            const catDataKey = layout === 'horizontal' ? xAxisDataKey : yAxisDataKey;
            const numDataKey = layout === 'horizontal' ? yAxisDataKey : xAxisDataKey;
            const catDataLabel = layout === 'horizontal' ? xAxisDataLabel : yAxisDataLabel;
            const numDataLabel = layout === 'horizontal' ? yAxisDataLabel : xAxisDataLabel;
            return (
              <div className={styles.tooltip}>
                <div className={styles.xAxisData}>{catDataLabel(payload[catDataKey])}</div>
                <div>
                  <span className={styles.yAxisData} style={{ backgroundColor: color }}></span>
                  <span>{numDataLabel(payload[numDataKey])}</span>
                </div>
              </div>
            );
          }
          return null;
        }}
      />
      <Bar
        fill={barColor[0]}
        {...(onBarClick ? { onClick: onBarClick } : {})}
        {...(layout === 'horizontal'
          ? {
              dataKey: yAxisDataKey
            }
          : {
              dataKey: xAxisDataKey,
              label: ({ index, y, height, offset }) => (
                <g transform={`translate(${margin.left + offset},${y - barSize - height})`}>
                  <text x={-4} y={16} dy={16}>
                    {yAxisDataLabel(data[index][yAxisDataKey])}
                  </text>
                </g>
              )
            })}
      >
        {data.map((entry, index) => (
          <Cell
            {...(onBarClick ? { cursor: 'pointer' } : {})}
            fill={index === activeBarIndex ? barColor[1] || barColor[0] : barColor[0]}
            key={`cell-${index}`}
          />
        ))}
      </Bar>
    </ReBarChart>
  </ResponsiveContainer>
);
