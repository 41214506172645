import React, { useEffect, useState } from 'react';
import { useLocalization } from 'features/localization/localizationSlice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { TabNavLink } from 'components/nav/NavLinks';
import { BUTTON_IDS } from 'utils/globalConstants';
import i18n from 'i18next';
import {
  fetchSummaryByDriver,
  fetchSummaryByVehicle,
  useSummaryByDriver,
  useSummaryByVehicle
} from 'features/support/supportELDSlice';
import styles from 'components/tn/grid/grid.module.scss';
import { useCurrentCompany } from 'features/company/companySlice';
import { useFleets, useIsFetching as isFleetsFetching } from 'features/fleets/fleetsSlice';
import { useBranches } from 'features/locations/locationsSlice';
import { prepareDataForMultiselect } from 'utils/filters';
import { Table, Button } from 'antd';
import { Select } from 'components/ant';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignJustify } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';
import { exportFile } from 'components/excelFile';
import summaryStyles from './Summary.module.scss';
import { ActionMenu } from 'components/actionMenu';
import { useHistory } from 'react-router';
import { Comparators } from 'utils/sorting';
import { LoadingTable } from 'components/grid/LoadingTable';
import { ColumnSelectorModal } from '../utils/ColumnSelectorModal';

export const SupportTypes = () => {
  return [
    { value: 'driver', label: i18n.t('Common.Driver') },
    { value: 'vehicle', label: i18n.t('Common.Vehicle') }
  ];
};

export const ColumnKeys = {
  DRIVER: 'driverName',
  DRIVERLASTSTATUS: 'driverLastLoginStatus',
  DRIVERLASTLOGIN: 'driverLastLoginDate',
  VEHICLE: 'vehicleName',
  VEHICLEVIN: 'vehicleVin',

  HERMESIMEI: 'hermesImei',
  HERMESSIM: 'hermesSim',
  HERMESTYPE: 'hermesTypeName',
  HERMESMODEL: 'hermesModelName',
  HERMESReleaseFWVersion: 'hermesReleaseVersion',
  HERMESCurrentFWVersion: 'hermesCurrentVersion',
  HERMESLocation: 'hermesLocation',
  HERMESEventTime: 'hermesLastEventTime',
  HERMESServerTime: 'hermesLastServerTime',
  EMSOdometer: 'emsOdometer',
  EMSEngineHour: 'emsEngineHour',
  EMSEventTime: 'emsLastEventTime',
  EMSServerTime: 'emsLastServerTime',
  MOBILEIMEI: 'mobileImei',
  MOBILESerialNumber: 'mobileSerialNumber',
  MOBILESIM: 'mobileSim',
  MOBILEType: 'mobileTypeName',
  MOBILEModel: 'mobileModelName',
  MOBILEReleaseFWVersion: 'mobileReleaseVersion',
  MOBILECurrentFWVersion: 'mobileCurrentVersion',
  MOBILELocation: 'mobileLocation',
  MOBILEEventTime: 'mobileLastEventTime',
  MOBILEServerTime: 'mobileLastServerTime',
  PowerDataDiagnostics: 'diagnosticPowerData',
  EngineSyncDiagnostics: 'diagnosticEngineSynchronization',
  MissingDataElementsDiagnostics: 'diagnosticMissingDataElement',
  DataTransferDiagnostics: 'diagnosticDataTransfer',
  UDTDiagnostics: 'diagnosticUnidentifiedDriving',
  OtherELDDiagnostics: 'diagnosticOther',
  PowerMalfunction: 'malfunctionPowerData',
  EngineSyncMalfunction: 'malfunctionEngineSynchronization',
  TimingMalfunction: 'malfunctionTiming',
  PositioningMalfunction: 'malfunctionPositioning',
  DataRecordingMalfunction: 'malfunctionDataRecording',
  DataTransferMalfunction: 'malfunctionDataTransfer',
  OtherELDMalfunction: 'malfunctionOther',
  ACTIONS: 'actions'
};

export const SummaryColumns = (localization, supportType) => {
  return [
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.Driver'),
      dataIndex: ColumnKeys.DRIVER,
      key: ColumnKeys.DRIVER,
      width: '150px',
      sorter: Comparators.String('driverNameValue'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.driver?.firstName + ' ' + rowData.driver?.lastName;
      }
    },
    {
      title:
        supportType === 'driver'
          ? i18n.t('SupportToolsELD.SummaryColumns.DriverLastLoginStatus')
          : i18n.t('SupportToolsELD.SummaryColumns.VehicleLastLoginStatus'),
      dataIndex: ColumnKeys.DRIVERLASTSTATUS,
      key: ColumnKeys.DRIVERLASTSTATUS,
      width: '200px',
      sorter: Comparators.String(ColumnKeys.DRIVERLASTSTATUS),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.driver?.lastLogonStatus;
      }
    },
    {
      title:
        supportType === 'driver'
          ? i18n.t('SupportToolsELD.SummaryColumns.DriverLastLoginDate')
          : i18n.t('SupportToolsELD.SummaryColumns.VehicleLastLoginDate'),
      dataIndex: ColumnKeys.DRIVERLASTLOGIN,
      key: ColumnKeys.DRIVERLASTLOGIN,
      width: '200px',
      sorter: Comparators.Date(ColumnKeys.DRIVERLASTLOGIN),
      isChecked: true,
      isSearchable: true,
      defaultSortColumn: true,
      defaultSortOrder: 'descend',
      getValue: rowData => {
        return rowData.driver?.lastLogonDate
          ? moment(rowData.driver?.lastLogonDate).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.Vehicle'),
      dataIndex: ColumnKeys.VEHICLE,
      key: ColumnKeys.VEHICLE,
      width: '200px',
      sorter: Comparators.String('vehicleNameValue'),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.vehicle?.name;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.VehicleVIN'),
      dataIndex: ColumnKeys.VEHICLEVIN,
      key: ColumnKeys.VEHICLEVIN,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.VEHICLEVIN),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.vehicle?.vin;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.HermesIMEI'),
      dataIndex: ColumnKeys.HERMESIMEI,
      key: ColumnKeys.HERMESIMEI,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.HERMESIMEI),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.hermes?.imei;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.HermesSIM'),
      dataIndex: ColumnKeys.HERMESSIM,
      key: ColumnKeys.HERMESSIM,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.HERMESSIM),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.hermes?.sim;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.HermesType'),
      dataIndex: ColumnKeys.HERMESTYPE,
      key: ColumnKeys.HERMESTYPE,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.HERMESTYPE),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.hermes?.type?.name;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.HermesModel'),
      dataIndex: ColumnKeys.HERMESMODEL,
      key: ColumnKeys.HERMESMODEL,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.HERMESMODEL),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.hermes?.model?.name;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.HermesReleaseFWVersion'),
      dataIndex: ColumnKeys.HERMESReleaseFWVersion,
      key: ColumnKeys.HERMESReleaseFWVersion,
      width: '250px',
      sorter: Comparators.String(ColumnKeys.HERMESReleaseFWVersion),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.hermes?.releaseVersion;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.HermesCurrentFWVersion'),
      dataIndex: ColumnKeys.HERMESCurrentFWVersion,
      key: ColumnKeys.HERMESCurrentFWVersion,
      width: '250px',
      sorter: Comparators.String(ColumnKeys.HERMESCurrentFWVersion),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.hermes?.currentVersion;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.HermesLocation'),
      dataIndex: ColumnKeys.HERMESLocation,
      key: ColumnKeys.HERMESLocation,
      width: '400px',
      sorter: Comparators.String(ColumnKeys.HERMESLocation),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.hermes?.location;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.HermesEventTime'),
      dataIndex: ColumnKeys.HERMESEventTime,
      key: ColumnKeys.HERMESEventTime,
      width: '200px',
      sorter: Comparators.Date(ColumnKeys.HERMESEventTime),
      isSearchable: true,
      getValue: rowData => {
        return rowData.hermes?.lastEventTime
          ? moment(rowData.hermes?.lastEventTime).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.HermesServerTime'),
      dataIndex: ColumnKeys.HERMESServerTime,
      key: ColumnKeys.HERMESServerTime,
      width: '200px',
      sorter: Comparators.Date(ColumnKeys.HERMESServerTime),
      isSearchable: true,
      getValue: rowData => {
        return rowData.hermes?.lastServerTime
          ? moment(rowData.hermes?.lastServerTime).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.EMSOdometer', {
        unit: localization.formats.speed.unit.toUpperCase()
      }),
      dataIndex: ColumnKeys.EMSOdometer,
      key: ColumnKeys.EMSOdometer,
      width: '200px',
      sorter: Comparators.Number(ColumnKeys.EMSOdometer),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.ems?.odometer ? localization.convertDistance(rowData.ems?.odometer) : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.EMSEngineHours'),
      dataIndex: ColumnKeys.EMSEngineHour,
      key: ColumnKeys.EMSEngineHour,
      width: '200px',
      sorter: Comparators.Number(ColumnKeys.EMSEngineHour),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.ems?.engineHour;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.EMSEventTime'),
      dataIndex: ColumnKeys.EMSEventTime,
      key: ColumnKeys.EMSEventTime,
      width: '200px',
      sorter: Comparators.Date(ColumnKeys.EMSEventTime),
      isSearchable: true,
      getValue: rowData => {
        return rowData.ems?.lastEventTime
          ? moment(rowData.ems?.lastEventTime).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.EMSServerTime'),
      dataIndex: ColumnKeys.EMSServerTime,
      key: ColumnKeys.EMSServerTime,
      width: '200px',
      sorter: Comparators.Date(ColumnKeys.EMSServerTime),
      isSearchable: true,
      getValue: rowData => {
        return rowData.ems?.lastServerTime
          ? moment(rowData.ems?.lastServerTime).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.MobileIMEI'),
      dataIndex: ColumnKeys.MOBILEIMEI,
      key: ColumnKeys.MOBILEIMEI,
      width: '300px',
      sorter: Comparators.String(ColumnKeys.MOBILEIMEI),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.mobile?.imei;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.MobileSerialNumber'),
      dataIndex: ColumnKeys.MOBILESerialNumber,
      key: ColumnKeys.MOBILESerialNumber,
      width: '200px',
      sorter: Comparators.String(ColumnKeys.MOBILESerialNumber),
      isChecked: true,
      isSearchable: true,
      getValue: rowData => {
        return rowData.mobile?.serialNumber;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.MobileSIM'),
      dataIndex: ColumnKeys.MOBILESIM,
      key: ColumnKeys.MOBILESIM,
      width: '200px',
      sorter: Comparators.String(ColumnKeys.MOBILESIM),
      isSearchable: true,
      getValue: rowData => {
        return rowData.mobile?.sim;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.MobileType'),
      dataIndex: ColumnKeys.MOBILEType,
      key: ColumnKeys.MOBILEType,
      width: '150px',
      sorter: Comparators.String(ColumnKeys.MOBILEType),
      isSearchable: true,
      getValue: rowData => {
        return rowData.mobile?.type?.name;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.MobileModel'),
      dataIndex: ColumnKeys.MOBILEModel,
      key: ColumnKeys.MOBILEModel,
      width: '200px',
      sorter: Comparators.String(ColumnKeys.MOBILEModel),
      isSearchable: true,
      getValue: rowData => {
        return rowData.mobile?.model?.name;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.MobileReleaseFWVersion'),
      dataIndex: ColumnKeys.MOBILEReleaseFWVersion,
      key: ColumnKeys.MOBILEReleaseFWVersion,
      width: '300px',
      sorter: Comparators.String(ColumnKeys.MOBILEReleaseFWVersion),
      isSearchable: true,
      getValue: rowData => {
        return rowData.mobile?.releaseVersion;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.MobileCurrentFWVersion'),
      dataIndex: ColumnKeys.MOBILECurrentFWVersion,
      key: ColumnKeys.MOBILECurrentFWVersion,
      width: '250px',
      sorter: Comparators.String(ColumnKeys.MOBILECurrentFWVersion),
      isSearchable: true,
      getValue: rowData => {
        return rowData.mobile?.currentVersion;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.MobileLocation'),
      dataIndex: ColumnKeys.MOBILELocation,
      key: ColumnKeys.MOBILELocation,
      width: '400px',
      sorter: Comparators.String(ColumnKeys.MOBILELocation),
      isSearchable: true,
      getValue: rowData => {
        return rowData.mobile?.location;
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.MobileEventTime'),
      dataIndex: ColumnKeys.MOBILEEventTime,
      key: ColumnKeys.MOBILEEventTime,
      width: '200px',
      sorter: Comparators.Date(ColumnKeys.MOBILEEventTime),
      isSearchable: true,
      getValue: rowData => {
        return rowData.mobile?.lastEventTime
          ? moment(rowData.mobile?.lastEventTime).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.MobileServerTime'),
      dataIndex: ColumnKeys.MOBILEServerTime,
      key: ColumnKeys.MOBILEServerTime,
      width: '200px',
      sorter: Comparators.Date(ColumnKeys.MOBILEServerTime),
      isSearchable: true,
      getValue: rowData => {
        return rowData.mobile?.lastServerTime
          ? moment(rowData.mobile?.lastServerTime).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.PowerDataDiagnostics'),
      dataIndex: ColumnKeys.PowerDataDiagnostics,
      key: ColumnKeys.PowerDataDiagnostics,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.PowerDataDiagnostics),
      isSearchable: true,
      getValue: rowData => {
        return rowData.diagnostic?.powerData
          ? moment(rowData.diagnostic?.powerData).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.EngineSyncDiagnostics'),
      dataIndex: ColumnKeys.EngineSyncDiagnostics,
      key: ColumnKeys.EngineSyncDiagnostics,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.EngineSyncDiagnostics),
      isSearchable: true,
      getValue: rowData => {
        return rowData.diagnostic?.engineSynchronization
          ? moment(rowData.diagnostic?.engineSynchronization).format(
              localization.formats.time.formats.dby_imp
            )
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.MissingDataElementsDiagnostics'),
      dataIndex: ColumnKeys.MissingDataElementsDiagnostics,
      key: ColumnKeys.MissingDataElementsDiagnostics,
      width: '350px',
      sorter: Comparators.Date(ColumnKeys.MissingDataElementsDiagnostics),
      isSearchable: true,
      getValue: rowData => {
        return rowData.diagnostic?.missingDataElement
          ? moment(rowData.diagnostic?.missingDataElement).format(
              localization.formats.time.formats.dby_imp
            )
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.DataTransferDiagnostics'),
      dataIndex: ColumnKeys.DataTransferDiagnostics,
      key: ColumnKeys.DataTransferDiagnostics,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.DataTransferDiagnostics),
      isSearchable: true,
      getValue: rowData => {
        return rowData.diagnostic?.dataTransfer
          ? moment(rowData.diagnostic?.dataTransfer).format(
              localization.formats.time.formats.dby_imp
            )
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.UDTDiagnostics'),
      dataIndex: ColumnKeys.UDTDiagnostics,
      key: ColumnKeys.UDTDiagnostics,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.UDTDiagnostics),
      isSearchable: true,
      getValue: rowData => {
        return rowData.diagnostic?.unidentifiedDriving
          ? moment(rowData.diagnostic?.unidentifiedDriving).format(
              localization.formats.time.formats.dby_imp
            )
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.OtherELDDiagnostics'),
      dataIndex: ColumnKeys.OtherELDDiagnostics,
      key: ColumnKeys.OtherELDDiagnostics,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.OtherELDDiagnostics),
      isSearchable: true,
      getValue: rowData => {
        return rowData.diagnostic?.other
          ? moment(rowData.diagnostic?.other).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.PowerMalfunction'),
      dataIndex: ColumnKeys.PowerMalfunction,
      key: ColumnKeys.PowerMalfunction,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.PowerMalfunction),
      isSearchable: true,
      getValue: rowData => {
        return rowData.malfunction?.powerData
          ? moment(rowData.malfunction?.powerData).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.EngineSyncMalfunction'),
      dataIndex: ColumnKeys.EngineSyncMalfunction,
      key: ColumnKeys.EngineSyncMalfunction,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.EngineSyncMalfunction),
      isSearchable: true,
      getValue: rowData => {
        return rowData.malfunction?.engineSynchronization
          ? moment(rowData.malfunction?.engineSynchronization).format(
              localization.formats.time.formats.dby_imp
            )
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.TimingMalfunction'),
      dataIndex: ColumnKeys.TimingMalfunction,
      key: ColumnKeys.TimingMalfunction,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.TimingMalfunction),
      isSearchable: true,
      getValue: rowData => {
        return rowData.malfunction?.timing
          ? moment(rowData.malfunction?.timing).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.PositioningMalfunction'),
      dataIndex: ColumnKeys.PositioningMalfunction,
      key: ColumnKeys.PositioningMalfunction,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.PositioningMalfunction),
      isSearchable: true,
      getValue: rowData => {
        return rowData.malfunction?.positioning
          ? moment(rowData.malfunction?.positioning).format(
              localization.formats.time.formats.dby_imp
            )
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.DataRecordingMalfunction'),
      dataIndex: ColumnKeys.DataRecordingMalfunction,
      key: ColumnKeys.DataRecordingMalfunction,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.DataRecordingMalfunction),
      isSearchable: true,
      getValue: rowData => {
        return rowData.malfunction?.dataRecording
          ? moment(rowData.malfunction?.dataRecording).format(
              localization.formats.time.formats.dby_imp
            )
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.DataTransferMalfunction'),
      dataIndex: ColumnKeys.DataTransferMalfunction,
      key: ColumnKeys.DataTransferMalfunction,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.DataTransferMalfunction),
      isSearchable: true,
      getValue: rowData => {
        return rowData.malfunction?.dataTransfer
          ? moment(rowData.malfunction?.dataTransfer).format(
              localization.formats.time.formats.dby_imp
            )
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.OtherELDMalfunction'),
      dataIndex: ColumnKeys.OtherELDMalfunction,
      key: ColumnKeys.OtherELDMalfunction,
      width: '250px',
      sorter: Comparators.Date(ColumnKeys.OtherELDMalfunction),
      isSearchable: true,
      getValue: rowData => {
        return rowData.malfunction?.other
          ? moment(rowData.malfunction?.other).format(localization.formats.time.formats.dby_imp)
          : '';
      }
    },
    {
      title: i18n.t('SupportToolsELD.SummaryColumns.Actions'),
      dataIndex: ColumnKeys.ACTIONS,
      key: ColumnKeys.ACTIONS,
      width: '100px',
      fixed: 'right',
      isChecked: true
    }
  ];
};

export const ColumnSelector = () => {
  return [
    {
      title: i18n.t('SupportToolsELD.SelectColumns'),
      dataIndex: 'colTitle',
      key: 'colKey'
    }
  ];
};

export const getValue = (row, column) => {
  if (column.getValue) {
    return column.getValue(row);
  } else {
    return getRowValue(row, column.dataIndex);
  }
};

export const getRowValue = (row, dataIndex) => {
  if (!dataIndex) {
    return null;
  }

  if (typeof dataIndex === 'string') {
    return row[dataIndex]?.toString();
  } else if (Array.isArray(dataIndex) && dataIndex.length > 0) {
    return get(row, dataIndex.join('.'))?.toString();
  } else {
    return row[dataIndex.toString()]?.toString();
  }
};

export const prepareFileForExcelExport = (data, supportType) => {
  const { tableData, localization } = data;
  const rows = tableData
    ? tableData.map(entry => {
        const row = {
          [i18n.t('SupportToolsELD.SummaryColumns.Driver')]: entry.driverNameValue || '',
          [supportType === 'driver'
            ? i18n.t('SupportToolsELD.SummaryColumns.DriverLastLoginStatus')
            : i18n.t('SupportToolsELD.SummaryColumns.VehicleLastLoginStatus')]:
            entry.driverLastLoginStatus || '',
          [supportType === 'driver'
            ? i18n.t('SupportToolsELD.SummaryColumns.DriverLastLoginDate')
            : i18n.t(
                'SupportToolsELD.SummaryColumns.VehicleLastLoginDate'
              )]: entry.driverLastLoginDate
            ? moment(entry.driverLastLoginDate).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t('SupportToolsELD.SummaryColumns.Vehicle')]: entry.vehicleNameValue || '',
          [i18n.t('SupportToolsELD.SummaryColumns.VehicleVIN')]: entry.vehicleVin || '',
          [i18n.t('SupportToolsELD.SummaryColumns.HermesIMEI')]: entry.hermesImei || '',
          [i18n.t('SupportToolsELD.SummaryColumns.HermesSIM')]: entry.hermesSim || '',
          [i18n.t('SupportToolsELD.SummaryColumns.HermesType')]: entry.hermesTypeName || '',
          [i18n.t('SupportToolsELD.SummaryColumns.HermesModel')]: entry.hermesModelName || '',
          [i18n.t('SupportToolsELD.SummaryColumns.HermesReleaseFWVersion')]:
            entry.hermesReleaseVersion || '',
          [i18n.t('SupportToolsELD.SummaryColumns.HermesCurrentFWVersion')]:
            entry.hermesCurrentVersion || '',
          [i18n.t('SupportToolsELD.SummaryColumns.HermesLocation')]: entry.hermesLocation || '',
          [i18n.t('SupportToolsELD.SummaryColumns.HermesEventTime')]: entry.hermesLastEventTime
            ? moment(entry.hermesLastEventTime).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t('SupportToolsELD.SummaryColumns.HermesServerTime')]: entry.hermesLastServerTime
            ? moment(entry.hermesLastServerTime).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t('SupportToolsELD.SummaryColumns.EMSOdometer', {
            unit: localization.formats.speed.unit.toUpperCase()
          })]: entry.emsOdometer || '',
          [i18n.t('SupportToolsELD.SummaryColumns.EMSEngineHours')]: entry.emsEngineHour || '',
          [i18n.t('SupportToolsELD.SummaryColumns.EMSEventTime')]: entry.emsLastEventTime
            ? moment(entry.emsLastEventTime).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t('SupportToolsELD.SummaryColumns.EMSServerTime')]: entry.emsLastServerTime
            ? moment(entry.emsLastServerTime).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t('SupportToolsELD.SummaryColumns.MobileIMEI')]: entry.mobileImei || '',
          [i18n.t('SupportToolsELD.SummaryColumns.MobileSerialNumber')]:
            entry.mobileSerialNumber || '',
          [i18n.t('SupportToolsELD.SummaryColumns.MobileSIM')]: entry.mobileSim || '',
          [i18n.t('SupportToolsELD.SummaryColumns.MobileType')]: entry.mobileTypeName || '',
          [i18n.t('SupportToolsELD.SummaryColumns.MobileModel')]: entry.mobileModelName || '',
          [i18n.t('SupportToolsELD.SummaryColumns.MobileReleaseFWVersion')]:
            entry.mobileReleaseVersion || '',
          [i18n.t('SupportToolsELD.SummaryColumns.MobileCurrentFWVersion')]:
            entry.mobileCurrentVersion || '',
          [i18n.t('SupportToolsELD.SummaryColumns.MobileLocation')]: entry.mobileLocation || '',
          [i18n.t('SupportToolsELD.SummaryColumns.MobileEventTime')]: entry.mobileLastEventTime
            ? moment(entry.mobileLastEventTime).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t('SupportToolsELD.SummaryColumns.MobileServerTime')]: entry.mobileLastServerTime
            ? moment(entry.mobileLastServerTime).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t('SupportToolsELD.SummaryColumns.PowerDataDiagnostics')]: entry.diagnosticPowerData
            ? moment(entry.diagnosticPowerData).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t(
            'SupportToolsELD.SummaryColumns.EngineSyncDiagnostics'
          )]: entry.diagnosticEngineSynchronization
            ? moment(entry.diagnosticEngineSynchronization).format(
                localization.formats.time.formats.dby_imp
              )
            : '',
          [i18n.t(
            'SupportToolsELD.SummaryColumns.MissingDataElementsDiagnostics'
          )]: entry.diagnosticMissingDataElement
            ? moment(entry.diagnosticMissingDataElement).format(
                localization.formats.time.formats.dby_imp
              )
            : '',
          [i18n.t(
            'SupportToolsELD.SummaryColumns.DataTransferDiagnostics'
          )]: entry.diagnosticDataTransfer
            ? moment(entry.diagnosticDataTransfer).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t(
            'SupportToolsELD.SummaryColumns.UDTDiagnostics'
          )]: entry.diagnosticUnidentifiedDriving
            ? moment(entry.diagnosticUnidentifiedDriving).format(
                localization.formats.time.formats.dby_imp
              )
            : '',
          [i18n.t('SupportToolsELD.SummaryColumns.OtherELDDiagnostics')]: entry.diagnosticOther
            ? moment(entry.diagnosticOther).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t('SupportToolsELD.SummaryColumns.PowerMalfunction')]: entry.malfunctionPowerData
            ? moment(entry.malfunctionPowerData).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t(
            'SupportToolsELD.SummaryColumns.EngineSyncMalfunction'
          )]: entry.malfunctionEngineSynchronization
            ? moment(entry.malfunctionEngineSynchronization).format(
                localization.formats.time.formats.dby_imp
              )
            : '',
          [i18n.t('SupportToolsELD.SummaryColumns.TimingMalfunction')]: entry.malfunctionTiming
            ? moment(entry.malfunctionTiming).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t(
            'SupportToolsELD.SummaryColumns.PositioningMalfunction'
          )]: entry.malfunctionPositioning
            ? moment(entry.malfunctionPositioning).format(localization.formats.time.formats.dby_imp)
            : '',
          [i18n.t(
            'SupportToolsELD.SummaryColumns.DataRecordingMalfunction'
          )]: entry.malfunctionDataRecording
            ? moment(entry.malfunctionDataRecording).format(
                localization.formats.time.formats.dby_imp
              )
            : '',
          [i18n.t(
            'SupportToolsELD.SummaryColumns.DataTransferMalfunction'
          )]: entry.malfunctionDataTransfer
            ? moment(entry.malfunctionDataTransfer).format(
                localization.formats.time.formats.dby_imp
              )
            : '',
          [i18n.t('SupportToolsELD.SummaryColumns.OtherELDMalfunction')]: entry.malfunctionOther
            ? moment(entry.malfunctionOther).format(localization.formats.time.formats.dby_imp)
            : ''
        };

        return row;
      })
    : [];

  return rows;
};

export function Summary(props) {
  const { t } = useTranslation();
  const localization = useLocalization();
  const company = useCurrentCompany();
  const fleets = useFleets();
  const isFetchingFleets = isFleetsFetching();
  const branches = useBranches();

  const [selectedCompany, setSelectedCompany] = useState(company?.id);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState(null);
  const [supportType, setSupportType] = useState('vehicle');
  const [filterFleets, setFilterFleets] = useState([]);
  const [filterBranches, setFilterBranches] = useState([]);
  const [showColumnsFilterModal, setShowColumnsFilterModal] = useState(false);

  const summaryDataByDriver = useSummaryByDriver();
  const summaryDataByVehicle = useSummaryByVehicle();
  const [summaryDataInit, setSummaryDataInit] = useState(false);
  const [allData, setAllData] = useState(summaryDataByVehicle);
  const [tableData, setTableData] = useState(allData);

  const history = useHistory();
  const PATHS = {
    SUMMARY_VIEW: '/supportTools/ELD/Summary/view',
    SUMMARY_DETAILS: '/supportTools/ELD/Summary/details'
  };

  useEffect(() => {
    if (!summaryDataInit || company.id !== selectedCompany) {
      dispatch(fetchSummaryByVehicle());
      setSummaryDataInit(true);
      setSelectedCompany(company.id);
      dispatch(fetchSummaryByDriver());
    }
  }, [dispatch, company, summaryDataInit, selectedCompany]);

  useEffect(() => {
    setAllData(summaryDataByVehicle);
  }, [summaryDataByVehicle]);

  // Set page title and back button
  useEffect(() => {
    dispatch(
      setPageTitle(
        t('SupportToolsELD.ELDSummaryTitle', {
          time: moment().format(localization.formats.time.formats.dby_imp)
        })
      )
    );
    dispatch(setBackButton(false));
  }, [dispatch]);

  const handleSupportTypeChange = value => {
    if (value === 'driver') {
      setAllData(summaryDataByDriver);
      setSupportType('driver');
    } else {
      setAllData(summaryDataByVehicle);
      setSupportType('vehicle');
    }
  };

  const handleSearchChange = text => {
    //if(text.length > 0 && text.length < 3) return;
    setSearchText(text);
  };

  useEffect(() => {
    const fleetsOptions = fleets.filter(
      fleet => fleet.id && fleet.company && fleet.company.id === company?.id
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets'), null));
  }, [fleets, t]);

  useEffect(() => {
    const companyBranches = branches.filter(
      branch =>
        branch.id && (branch.id === -1 || (branch.companyId && branch.companyId === company?.id))
    );

    const branchOptions = prepareDataForMultiselect(companyBranches, t('Common.AllBranches'), null);
    const noBranch = branchOptions?.find(b => b.id === -1);
    if (noBranch) {
      noBranch.name = t('Users.NoBranch');
    }
    setFilterBranches(branchOptions);
  }, [branches, t]);

  const PrepareDataForTable = ({ allData, searchText, filterFleets, filterBranches }) => {
    var allDataTemp = allData;
    var checkedFleets = [];
    var checkedBranches = [];

    if (Array.isArray(filterFleets)) {
      checkedFleets = filterFleets.filter(f => f.checked === true);
    }

    if (Array.isArray(filterBranches)) {
      checkedBranches = filterBranches.filter(f => f.checked === true);
    }

    if (checkedFleets.length > 0 && checkedFleets.filter(f => f.id === 0).length === 0) {
      let res = [];
      res = allDataTemp.filter(el => {
        return checkedFleets.find(element => {
          if (element.id === -1 && !el.vehicle?.fleets) return true;

          if (el.vehicle?.fleets) {
            return el.vehicle?.fleets.find(f => {
              return element.id === f.id || (element.id === -1 && !f.id);
            });
          }
        });
      });

      allDataTemp = res;
    } else if (checkedFleets.length === 0) {
      allDataTemp = [];
    }

    if (checkedBranches.length > 0 && checkedBranches.filter(f => f.id === 0).length === 0) {
      let res = [];
      res = allDataTemp.filter(el => {
        return checkedBranches.find(element => {
          if (element.id === -1 && !el.driver?.locations) return true;

          if (el.driver?.locations) {
            return el.driver?.locations.find(b => {
              return element.id === b.id || (element.id === -1 && !b.id);
            });
          }
        });
      });

      allDataTemp = res;
    } else if (checkedBranches.length === 0) {
      allDataTemp = [];
    }

    const searchableColumns = SummaryColumns(localization, supportType)?.filter(
      col =>
        col.isSearchable &&
        selectedColumns.selectedRows.findIndex(sc => sc.colKey === col.dataIndex) > -1
    );
    let filtered = allDataTemp?.filter(row => {
      return searchText?.trim()
        ? searchableColumns.some(col =>
            getValue(row, col)
              ?.toString()
              ?.toLowerCase()
              .includes(searchText?.trim()?.toLowerCase())
          )
        : true;
    });

    const getSummaryMenuItems = ({ index, item, t = null, history = null }) => {
      const entity = item.byEntity;
      const entityId = item.id;
      const entityName =
        item.byEntity === 'driver'
          ? item.driver?.firstName + ' ' + item.driver?.lastName
          : item.vehicle?.name;

      return [
        {
          name: t('Common.EllipsisButton.View'),
          onClick: () => {
            history.push(`${PATHS.SUMMARY_VIEW}/${item.byEntity}/${item.id}`, {
              data: { entity: entity, entityId: entityId, entityName: entityName }
            });
          },
          entity: 'VIEW',
          id: 'btn_summaryView'
        },
        {
          name: t('SupportToolsELD.Details'),
          onClick: () => {
            history.push(`${PATHS.SUMMARY_DETAILS}/${item.byEntity}/${item.id}`, {
              data: { entity: entity, entityId: entityId, entityName: entityName }
            });
          },
          entity: 'DETAILS',
          id: 'btn_summaryDetails'
        }
      ];
    };
    var minDate = moment.utc('0001-01-01');
    const returnData = filtered?.map((entry, index) => {
      var drivername = entry.driver.firstName ? entry.driver.firstName : '';
      if (drivername.length > 0) {
        drivername = drivername + ' ' + (entry.driver.lastName ? entry.driver.lastName : '');
      } else {
        drivername = entry.driver.lastName ? entry.driver.lastName : '';
      }
      return {
        key: entry.id,
        driverNameValue: drivername,
        [ColumnKeys.DRIVER]:
          supportType === 'driver' ? (
            <Link
              value={drivername}
              onClick={() => {
                history.push(`/supportTools/ELD/Summary/view/driver/${entry.driver?.id}`, {
                  data: { entity: 'driver', entityId: entry.driver?.id, entityName: drivername }
                });
              }}
            >
              {drivername}
            </Link>
          ) : (
            drivername
          ),
        [ColumnKeys.DRIVERLASTSTATUS]: entry.driver?.lastLogonStatus,
        [ColumnKeys.DRIVERLASTLOGIN]: entry.driver?.lastLogonDate
          ? moment(entry.driver?.lastLogonDate) > minDate
            ? moment(entry.driver?.lastLogonDate).format(localization.formats.time.formats.dby_imp)
            : ''
          : '',
        vehicleNameValue: entry.vehicle?.name,
        [ColumnKeys.VEHICLE]:
          supportType === 'vehicle' ? (
            <Link
              value={entry.vehicle?.name}
              onClick={() => {
                history.push(`/supportTools/ELD/Summary/view/vehicle/${entry.vehicle?.id}`, {
                  data: {
                    entity: 'vehicle',
                    entityId: entry.vehicle?.id,
                    entityName: entry.vehicle?.name
                  }
                });
              }}
            >
              {entry.vehicle?.name}
            </Link>
          ) : (
            entry.vehicle?.name
          ),
        [ColumnKeys.VEHICLEVIN]: entry.vehicle?.vin,
        [ColumnKeys.HERMESIMEI]: entry.hermes?.imei,
        [ColumnKeys.HERMESSIM]: entry.hermes?.sim,
        [ColumnKeys.HERMESTYPE]: entry.hermes?.type?.name,
        [ColumnKeys.HERMESMODEL]: entry.hermes?.model?.name,
        [ColumnKeys.HERMESReleaseFWVersion]: entry.hermes?.releaseVersion,
        [ColumnKeys.HERMESCurrentFWVersion]: entry.hermes?.currentVersion,
        [ColumnKeys.HERMESLocation]: entry.hermes?.location,
        [ColumnKeys.HERMESEventTime]: entry.hermes?.lastEventTime
          ? moment(entry.hermes?.lastEventTime).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.HERMESServerTime]: entry.hermes?.lastServerTime
          ? moment(entry.hermes?.lastServerTime).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.EMSOdometer]: entry.ems?.odometer
          ? localization.convertDistance(entry.ems?.odometer)
          : '',
        [ColumnKeys.EMSEngineHour]: entry.ems?.engineHour
          ? parseFloat(entry.ems?.engineHour).toFixed(1)
          : '',
        [ColumnKeys.EMSEventTime]: entry.ems?.lastEventTime
          ? moment(entry.ems?.lastEventTime).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.EMSServerTime]: entry.ems?.lastServerTime
          ? moment(entry.ems?.lastServerTime).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.MOBILEIMEI]: entry.mobile?.imei,
        [ColumnKeys.MOBILESerialNumber]: entry.mobile?.serialNumber,
        [ColumnKeys.MOBILESIM]: entry.mobile?.sim,
        [ColumnKeys.MOBILEType]: entry.mobile?.type?.name,
        [ColumnKeys.MOBILEModel]: entry.mobile?.model?.name,
        [ColumnKeys.MOBILEReleaseFWVersion]: entry.mobile?.releaseVersion,
        [ColumnKeys.MOBILECurrentFWVersion]: entry.mobile?.currentVersion,
        [ColumnKeys.MOBILELocation]: entry.mobile?.location,
        [ColumnKeys.MOBILEEventTime]: entry.mobile?.lastEventTime
          ? moment(entry.mobile?.lastEventTime).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.MOBILEServerTime]: entry.mobile?.lastServerTime
          ? moment(entry.mobile?.lastServerTime).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.PowerDataDiagnostics]: entry.diagnostic?.powerData
          ? moment(entry.diagnostic?.powerData).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.EngineSyncDiagnostics]: entry.diagnostic?.engineSynchronization
          ? moment(entry.diagnostic?.engineSynchronization).format(
              localization.formats.time.formats.dby_imp
            )
          : '',
        [ColumnKeys.MissingDataElementsDiagnostics]: entry.diagnostic?.missingDataElement
          ? moment(entry.diagnostic?.missingDataElement).format(
              localization.formats.time.formats.dby_imp
            )
          : '',
        [ColumnKeys.DataTransferDiagnostics]: entry.diagnostic?.dataTransfer
          ? moment(entry.diagnostic?.dataTransfer).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.UDTDiagnostics]: entry.diagnostic?.unidentifiedDriving
          ? moment(entry.diagnostic?.unidentifiedDriving).format(
              localization.formats.time.formats.dby_imp
            )
          : '',
        [ColumnKeys.OtherELDDiagnostics]: entry.diagnostic?.other
          ? moment(entry.diagnostic?.other).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.PowerMalfunction]: entry.malfunction?.powerData
          ? moment(entry.malfunction?.powerData).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.EngineSyncMalfunction]: entry.malfunction?.engineSynchronization
          ? moment(entry.malfunction?.engineSynchronization).format(
              localization.formats.time.formats.dby_imp
            )
          : '',
        [ColumnKeys.TimingMalfunction]: entry.malfunction?.timing
          ? moment(entry.malfunction?.timing).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.PositioningMalfunction]: entry.malfunction?.positioning
          ? moment(entry.malfunction?.positioning).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.DataRecordingMalfunction]: entry.malfunction?.dataRecording
          ? moment(entry.malfunction?.dataRecording).format(
              localization.formats.time.formats.dby_imp
            )
          : '',
        [ColumnKeys.DataTransferMalfunction]: entry.malfunction?.dataTransfer
          ? moment(entry.malfunction?.dataTransfer).format(
              localization.formats.time.formats.dby_imp
            )
          : '',
        [ColumnKeys.OtherELDMalfunction]: entry.malfunction?.other
          ? moment(entry.malfunction?.other).format(localization.formats.time.formats.dby_imp)
          : '',
        [ColumnKeys.ACTIONS]: (
          <ActionMenu
            items={getSummaryMenuItems({
              index: index,
              item: entry,
              history,
              t
            })}
            data={entry}
          />
        )
      };
    });
    return returnData;
  };

  const PrepareColumnsForSelector = () => {
    const allColumns = SummaryColumns(localization, supportType);

    const columns = allColumns?.map((col, index) => ({
      key: index,
      colTitle: col.title,
      colKey: col.key,
      isChecked: col.isChecked ? col.isChecked : false
    }));

    return columns.filter(c => c.colKey !== ColumnKeys.ACTIONS);
  };

  const allColumns = PrepareColumnsForSelector();

  const [filterColumns, setFilterColumns] = useState(
    allColumns.filter(x =>
      SummaryColumns(localization, supportType)
        .filter(c => c.isChecked)
        .find(z => z.key === x.colKey)
    )
  );

  const [selectedColumns, setSelectedColumns] = useState({
    selectedRowKeys: filterColumns?.map(c => c.key),
    selectedRows: filterColumns
  });

  useEffect(() => {
    const columnData = PrepareColumnsForSelector();
    if (filterColumns.length === 0) {
      setFilterColumns(columnData.filter(c => c.isChecked));
    }
  }, [filterColumns]);

  useEffect(() => {
    const data = PrepareDataForTable({
      allData,
      searchText,
      filterFleets,
      filterBranches
    });
    setTableData(data);
  }, [allData, searchText, supportType, filterFleets, filterBranches]);

  const handleExportExcel = () => {
    const columnWidth = Array.apply(null, { length: 40 }).map(() => ({ wch: 25 }));

    const data = prepareFileForExcelExport(
      {
        tableData,
        localization,
        dateFormat: localization.formats.time.formats.dby_imsp
      },
      supportType
    );
    dispatch(
      exportFile(data, {
        width: columnWidth,
        title: supportType === 'driver' ? t('Common.Drivers') : t('Common.Vehicles'),
        dateFormat: localization.formats.time.formats.dby_imsp
      })
    );
  };

  const handleOkClick = rows => {
    setFilterColumns(rows.selectedRows);
    setSelectedColumns(rows);
    setShowColumnsFilterModal(false);
  };

  const handleCancelClick = () => {
    setShowColumnsFilterModal(false);
  };

  if (!allData || allData.length === 0)
    return <LoadingTable nrOfRows={1} columnSizes={[100, 100, 100, 100, 100, 100]} />;
  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div style={{ display: 'flex', width: '100%' }}>
            <TabNavLink
              to={'/supportTools/ELD/Summary'}
              isActive={(match, location) =>
                match || location.pathname.toLowerCase() === '/supportTools/ELD'
              }
            >
              {t('SupportToolsELD.ELDSummary')}
            </TabNavLink>

            {/* <TabNavLink to={'/supportTools/ELD/MobileLogs'} isActive={(match, location) => match}>
              {t('SupportToolsELD.ELDMobileLogs')}
            </TabNavLink> */}
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Button
              type="primary"
              size="large"
              id={BUTTON_IDS.supportSummaryExcelExport}
              onClick={handleExportExcel}
              disabled={isFetchingFleets}
            >
              {t('Common.ExporttoExcel')}
            </Button>
          </div>
        </HeaderPageWrapper>

        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={handleSearchChange} />
            <AntMultiselect
              title={
                filterFleets?.some(value => !value.checked)
                  ? t('Common.Fleets')
                  : t('Common.AllFleets')
              }
              data={filterFleets}
              onFilter={v => setFilterFleets(v)}
            />
            <AntMultiselect
              title={
                filterBranches?.some(value => !value.checked)
                  ? t('Common.Branches')
                  : t('Common.AllBranches')
              }
              onFilter={v => setFilterBranches(v)}
              data={filterBranches}
            />
            <Select
              options={SupportTypes()}
              value={supportType}
              onChange={handleSupportTypeChange}
              className={summaryStyles.supportTypeSelector}
            />
            <Button
              className={summaryStyles.summaryColumnsButton}
              id={BUTTON_IDS.supportSummaryColumns}
              onClick={() => {
                //setShowViewsConfigModal(true);
                setShowColumnsFilterModal(true);
              }}
            >
              <FontAwesomeIcon rotation={90} icon={faAlignJustify} />
            </Button>
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {supportType === 'driver'
              ? tableData?.length > 1
                ? t('Alerts.Assigned.MultipleDrivers', { number: tableData?.length })
                : t('Alerts.Assigned.SingleDriver', { number: tableData?.length })
              : tableData?.length > 1
              ? t('Alerts.Assigned.MultipleVehicles', { number: tableData.length })
              : t('Alerts.Assigned.SingleVehicle', { number: tableData?.length })}
          </label>
        </div>
        <div>
          <Table
            className={`${styles.grid} ${styles.gridShowScrollbars}`}
            columns={SummaryColumns(localization, supportType).filter(
              c => filterColumns.find(x => x.colKey === c.key) || c.key === ColumnKeys.ACTIONS
            )}
            dataSource={tableData}
            pagination={{ pageSize: 25, showSizeChanger: false }}
            scroll={{ y: 'calc(100vh - 275px)', x: 'max-content' }}
            showSorterTooltip={false}
            rowSelection={{ type: 'checkbox' }}
          />
        </div>
      </ContainerPageWrapper>

      {showColumnsFilterModal && (
        <ColumnSelectorModal
          title={t('SupportToolsELD.SelectColumns')}
          isOpen={showColumnsFilterModal}
          columns={ColumnSelector()}
          data={PrepareColumnsForSelector()}
          selectedColumns={selectedColumns}
          handleOk={handleOkClick}
          handleCancel={handleCancelClick}
        />
      )}
    </>
  );
}
