import React from 'react';
import { useTranslation } from 'react-i18next';
import { duration } from 'moment';
import i18next from 'i18nextConfig';
import styles from './AlertData.module.scss';

function formatDuration(seconds) {
  const d = duration(seconds, 'seconds');
  let formatStr = '';

  if (d.asHours() >= 1) {
    formatStr +=
      ' ' +
      parseInt(d.asHours()) +
      ' ' +
      (parseInt(d.asHours()) > 1 ? i18next.t('Common.hours') : i18next.t('Common.hour'));
  }

  if (d.minutes() > 0) {
    formatStr +=
      ' ' +
      d.minutes() +
      ' ' +
      (d.minutes() > 0 ? i18next.t('Common.Mins') : i18next.t('Common.min'));
  }

  if (formatStr === '') {
    return '0';
  }

  return formatStr;
}

export function EngineIdleAlertContentRender({ alertData }) {
  const { t } = useTranslation();
  return (
    <>
      {alertData.vehicleId !== 'Unknown' && (
        <>
          <> {t('Notifications.FromVehicle')} - </>
          <strong>{alertData.vehicleName}</strong>.{' '}
        </>
      )}
      {alertData.vehicleId === 'Unknown' && (
        <>
          <> {t('Notifications.FromDevice')} - </>
          <strong>{alertData.deviceName}</strong>.{' '}
        </>
      )}
      {alertData.duration && alertData.idleThreshold && (
        <>
          {t('Tracking.Events.Duration')}:{' '}
          <strong>{formatDuration(alertData.duration + alertData.idleThreshold)}</strong>.{' '}
        </>
      )}
      {t('Notifications.AtLocation')}:<span className={styles.location}> {alertData.location}</span>
    </>
  );
}
