import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Tabs } from 'antd';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { GlobalRoles, useCanOneOfRoles } from 'features/permissions';
import { useGetAdaptersQuery } from 'services/nextgen';
import { insertIf } from 'utils/methods';
import { showHideAdapter } from './constants';

//components
import { Marketplace } from './Marketplace/Marketplace';
import { Events } from './Events/Events';

import styles from './Integrations.module.scss';
import { useCurrentCompanyId } from '../../features/company/companySlice';

export const Integrations = ({ match, history }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useCurrentCompanyId();
  const { data: initialAdapters, isLoading } = useGetAdaptersQuery({ companyId });
  const [activeAdapters, setActiveAdapters] = useState([]);
  const [activeTab, setActiveTab] = useState(match?.params?.tab);
  const canAdminRole = useCanOneOfRoles([GlobalRoles.Admin]);
  const canSiteAdminRole = useCanOneOfRoles([GlobalRoles.SiteAdmin]);

  useEffect(() => {
    dispatch(setPageTitle(t('Common.Integrations')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  useEffect(() => {
    const adapters = initialAdapters?.filter(adapter => {
      return showHideAdapter(adapter) && adapter;
    });
    setActiveAdapters(adapters);
  }, [initialAdapters]);

  return (
    <div className={styles.tabs}>
      <Tabs
        defaultActiveKey={match?.params?.tab}
        onChange={key => {
          history.push(`/integrations/${key}`);
          setActiveTab(key);
        }}
        items={[
          {
            label: t('Integrations.MarketplaceTab'),
            key: 'marketplace',
            children: (
              <Marketplace cardsData={activeAdapters} isLoading={isLoading} activeTab={activeTab} />
            )
          },
          ...insertIf(canAdminRole || canSiteAdminRole, {
            label: t('Integrations.ActiveIntegrationsTab'),
            key: 'activeIntegrations',
            children: (
              <Marketplace
                cardsData={activeAdapters?.filter(
                  adapter => adapter?.inbound?.length > 0 || adapter?.outbound?.length > 0
                )}
                isLoading={isLoading}
                activeTab={activeTab}
                activeIntegrationsTab={true}
              />
            )
          }),
          // {
          //   label: t('Integrations.MonitoringTab'),
          //   key: 'monitoring',
          //   children: <span>{t('Integrations.MonitoringTab')}</span>
          // },
          ...insertIf(canSiteAdminRole, {
            label: t('Integrations.EventsTab.TabName'),
            key: 'events',
            children: <Events />
          })
        ]}
      />
    </div>
  );
};
