import React, { useCallback } from 'react';
import { Row, Col, Checkbox, Alert } from 'antd';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { TimePicker } from 'components/ant';
import { useCan, services, FeatureFlag } from 'features/permissions';
import moment from 'moment';
import styles from './ELDRulesetTab.module.scss';
import { useHistory } from 'react-router';
import { Link as LinkRouter } from 'react-router-dom';
import { BUTTON_IDS } from 'utils/globalConstants';

export function ELDRulesetTab({
  pendingChange,
  pendingStartOfDay,
  missingCarrier,
  companyIsMultiCarrier = false,
  ...props
}) {
  const { t } = useTranslation();
  const can = useCan();
  const form = useFormikContext();
  const carrierUrl = companyIsMultiCarrier
    ? `/settings/company_config/eldCarrier`
    : `/settings/company_config`;

  const history = useHistory();
  const onFieldChange = useCallback((time, field, form) => {
    const momentTime = moment(time, 'hh:mm A').format('HH:mm');
    form.setFieldValue(field.name, momentTime);
  }, []);

  return (
    <div>
      <Row style={{ marginBottom: '10px' }}>
        {missingCarrier && (
          <Row>
            <Alert
              message={t('Users.Form.NoCarrierSetup')}
              description={t('Users.Form.SetupCarrierMessage')}
              type="warning"
              showIcon
              style={{ marginBottom: '16px' }}
              action={
                <LinkRouter
                  id={BUTTON_IDS.userCarrierLink}
                  onClick={() =>
                    companyIsMultiCarrier
                      ? history.push(`/settings/company_config/eldCarrier`, {
                          forceShow: false
                        })
                      : history.push(`/settings/company_config`, {
                          forceShow: true
                        })
                  }
                >
                  {t('Common.Carriers')}
                </LinkRouter>
              }
            />
          </Row>
        )}

        <Row style={{ width: '100%' }}>
          <Col span={24}>
            <h5>{t('ELD.HOS Rulesets')}</h5>
            <hr />
          </Col>
        </Row>
        <Row style={{ width: '100%' }}>
          <Col>
            <h6 style={{ padding: 0, margin: 0, color: '#818ea1' }}>
              {t('ELD.Additional Driving Conditions')}
            </h6>
            <Field name="personalConveyance">
              {({ field }) => (
                <Checkbox {...field} checked={field.value} disabled={missingCarrier}>
                  {t('ELD.DutyStatus.Personal Conveyance')}
                </Checkbox>
              )}
            </Field>
            <Field name="yardMoves">
              {({ field }) => (
                <Checkbox {...field} checked={field.value} disabled={missingCarrier}>
                  {t('ELD.DutyStatus.Yard Move')}
                </Checkbox>
              )}
            </Field>
          </Col>
          {can({
            everyCompanyService: [services.ELDCAN],
            featureFlag: FeatureFlag.startOfDay.flag
          }) && (
            <Col style={{ marginLeft: '10px' }}>
              <h6 style={{ padding: 0, margin: 0, color: '#818ea1' }}>
                {t('ELD.Canada ELD start of day')}
              </h6>

              <div style={{ float: 'left' }}>
                <Field name="startOfDay">
                  {({ field }) => (
                    <TimePicker
                      {...field}
                      use12Hours
                      format="hh:mm A"
                      value={field.value ?? '00:00'}
                      onChange={time => {
                        onFieldChange(time, field, form);
                      }}
                      style={{ minWidth: 120 }}
                      placeholder={t('Common.SelectTime')}
                      showNow={false}
                      allowClear={false}
                    />
                  )}
                </Field>
              </div>
              {pendingChange && (
                <div className={styles.pendingChange}>
                  {t('ELD.PendingCycleChange', { name: pendingStartOfDay })}
                </div>
              )}
            </Col>
          )}
        </Row>
      </Row>
    </div>
  );
}
