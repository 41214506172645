import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Locations } from './Locations';
import { LocationView } from './LocationView';
import { LocationForm } from './LocationForm';
import { LocationAudits } from './LocationAudits';
import { SelectGeofences } from './SelectGeofences';
import { ContactView } from './ContactView';
import { Can, entities } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { PATHS } from './constants';

export const LocationsRouter = () => {
  return (
    <Can everyEntity={[entities.LOCATION]} onFail={<NoAccessBody />}>
      <Switch>
        <Route path={PATHS.VIEW_CONTACT_PATH}>
          <Can everyEntity={[entities.CONTACT_VIEW]} onFail={<NoAccessBody />}>
            <ContactView />
          </Can>
        </Route>
        <Route path={PATHS.VIEW_LOCATION}>
          <Can everyEntity={[entities.LOCATION_VIEW]} onFail={<NoAccessBody />}>
            <LocationView />
          </Can>
        </Route>
        <Route path={PATHS.SELECT_GEOFENCE}>
          <SelectGeofences />
        </Route>
        <Route path={PATHS.NEW_LOCATION}>
          <Can everyEntity={[entities.LOCATION_CREATE]} onFail={<NoAccessBody />}>
            <LocationForm action="add" />
          </Can>
        </Route>
        <Route path={PATHS.EDIT_LOCATION}>
          <Can everyEntity={[entities.LOCATION_UPDATE]} onFail={<NoAccessBody />}>
            <LocationForm action="edit" />
          </Can>
        </Route>
        <Route path={PATHS.AUDIT_LOCATION}>
          <Can everyEntity={[entities.LOCATION_AUDIT]} onFail={<NoAccessBody />}>
            <LocationAudits />
          </Can>
        </Route>
        <Route path={PATHS.DEFAULT}>
          <Locations />
        </Route>
      </Switch>
    </Can>
  );
};
