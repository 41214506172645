import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Radio, Row } from 'antd';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import DropdownTreeSelect from 'components/form/treeselect/DropdownTreeSelect';
import { Select } from 'components/ant/Select/Select';
import { PATH_SPLIT, selectAll, checkedListItems } from 'components/form/treeselect/TreeSelect';
import { CardViewLens, FilterSortField, OrderBy, DEFAULT_SORT_BY } from './constants';
import { isObject as _isObject, sortBy as _sortBy } from 'lodash';

const getObjValue = (key, obj) => {
  try {
    if (key) {
      const props = key.split('.');
      let ret = obj;
      for (const prop of props) {
        ret = ret && ret[prop];
      }
      return ret;
    }
  } catch (error) {
    console.error(error);
  }
};

const mergeFilter = (prev, curr) => {
  //keep prev selected node if it exists in curr nodes
  //re-check selectAll node's checked state
  const isEqualNode = (prevNode, curNode) => prevNode.name === curNode.name;
  return curr.map(filterItem => {
    const prevFilterItem = prev?.find(item => isEqualNode(item, filterItem));
    if (prevFilterItem) {
      const _children = filterItem.children.map(child => {
        const prevFilterItemChild = prevFilterItem.children?.find(item => isEqualNode(item, child));
        return prevFilterItemChild
          ? {
              ...prevFilterItemChild,
              ...child
            }
          : child;
      });
      const updatedSelectAll =
        checkedListItems(_children).length ===
        _children.filter(child => child.name !== selectAll.name).length;
      return {
        ...prevFilterItem,
        ...filterItem,
        children:
          _children.length > 1
            ? _children.map(child =>
                child.name === selectAll.name ? { ...child, checked: updatedSelectAll } : child
              )
            : _children
      };
    } else {
      return filterItem;
    }
  });
};

export default function useCardViewFilterBar(
  pinnedCards,
  unpinnedDriverCards,
  unpinnedVehicleCards
) {
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(CardViewLens.Pinned);
  const [searchValue, setSearchValue] = useState('');

  const [filters, setFilters] = useState(null);
  const [sorts, setSorts] = useState(null);

  const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY);
  const [isSortDropOpen, setIsSortDropOpen] = useState(false);
  const [orderBy, setOrderBy] = useState(OrderBy.Ascending);

  const checkedFilters = useMemo(() => filters && checkedListItems(filters), [filters]);

  const getListFromCards = useCallback(
    (key, lensType) => {
      switch (lensType) {
        case CardViewLens.Pinned:
          return Array.from(new Set(pinnedCards.map(card => getObjValue(key, card)))).filter(
            v => !!v
          );
        case CardViewLens.Drivers:
          return Array.from(
            new Set(unpinnedDriverCards.map(card => getObjValue(key, card)))
          ).filter(v => !!v);
        case CardViewLens.Vehicles:
          return Array.from(
            new Set(unpinnedVehicleCards.map(card => getObjValue(key, card)))
          ).filter(v => !!v);
        default:
          return [];
      }
    },
    [pinnedCards, unpinnedDriverCards, unpinnedVehicleCards]
  );
  //console.log({pinnedCards,unpinnedDriverCards,unpinnedVehicleCards})
  //console.log(filters,sorts,sortBy,orderBy)
  const getInitialFilterSorter = useCallback(
    currentTab => {
      const buildChildren = (parentId, children, propKey) => [
        {
          ...selectAll,
          label: t('Common.Select All'),
          nodeKey: `${parentId}${PATH_SPLIT}${selectAll.id}`,
          id: `${parentId}${PATH_SPLIT}${selectAll.id}`
        },
        ..._sortBy(children, child => (_isObject(child) ? child.label : child)).map(
          (child, childIndex) =>
            _isObject(child)
              ? {
                  ...child,
                  label: t(child.label),
                  nodeKey: `${parentId}${PATH_SPLIT}${childIndex + 1}`,
                  id: `${parentId}${PATH_SPLIT}${childIndex + 1}`,
                  propKey
                }
              : {
                  label: child,
                  name: child,
                  nodeKey: `${parentId}${PATH_SPLIT}${childIndex + 1}`,
                  id: `${parentId}${PATH_SPLIT}${childIndex + 1}`,
                  propKey
                }
        )
      ];

      return {
        filter: FilterSortField[currentTab].Filter.map((f, fIdx) => ({
          label: t(f.label),
          name: f.name,
          nodeKey: `${fIdx}`,
          id: `${fIdx}`,
          propKey: f.key,
          children: f.options
            ? buildChildren(fIdx, f.options, f.key)
            : buildChildren(fIdx, getListFromCards(f.key, currentTab), f.key)
        })),
        sorter: FilterSortField[currentTab].Sort.map(s => ({ ...s, label: t(s.label) }))
      };
    },
    [t, getListFromCards]
  );

  useEffect(() => {
    const { filter, sorter } = getInitialFilterSorter(currentTab);
    setFilters(prev => mergeFilter(prev, filter));
    setSorts(sorter);
    setSortBy(prevSortBy =>
      sorter?.every(s => s.key !== prevSortBy.key) ? DEFAULT_SORT_BY : prevSortBy
    );
  }, [getInitialFilterSorter, currentTab]);

  const resetFilter = useCallback(() => {
    const { filter, sorter } = getInitialFilterSorter(currentTab);
    setFilters(filter);
    setSorts(sorter);
    setSortBy(DEFAULT_SORT_BY);
    setOrderBy(OrderBy.Ascending);
    setSearchValue('');
    setIsSortDropOpen(false);
  }, [getInitialFilterSorter, currentTab]);

  const filterCards = useCallback(
    card => {
      if (searchValue) {
        if (
          !Object.keys(card).some(
            key =>
              typeof card[key] === 'string' &&
              card[key].toLowerCase().includes(searchValue.toLowerCase())
          )
        ) {
          return false;
        }
      }
      if (checkedFilters?.length) {
        return checkedFilters.some(checkedFilter => {
          const kvMatcher =
            checkedFilter.matcher || (propValue => propValue === checkedFilter.name);
          return checkedFilter.propKey && kvMatcher(getObjValue(checkedFilter.propKey, card), card);
        });
      }
      return true;
    },
    [searchValue, checkedFilters]
  );

  const handleFilterChanged = useCallback(filters => {
    setFilters(prev => mergeFilter(prev, filters));
  }, []);

  const handleSortChanged = useCallback(val => {
    setSortBy(val);
  }, []);

  const handleOrderByChanged = useCallback(e => {
    setIsSortDropOpen(false);
    setOrderBy(e.target.value);
  }, []);

  const sortCards = useCallback(
    (a, b) => {
      const sign = orderBy === OrderBy.Ascending ? 1 : -1;
      const aValue = getObjValue(sortBy.key, a);
      const bValue = getObjValue(sortBy.key, b);
      if (!aValue) return 1 * sign;
      if (!bValue) return -1 * sign;
      if (aValue === bValue) return 0;

      return `${aValue}`.toLowerCase() > `${bValue}`.toLowerCase() ? 1 * sign : -1 * sign;
    },
    [sortBy, orderBy]
  );

  const FilterBar = useCallback(
    ({ t, checkedFilters, filters, sorts, sortBy, orderBy, isSortDropOpen }) => {
      const filterTitle = checkedFilters?.length
        ? `${t('Common.Filter')} • ${checkedFilters?.length || 0}`
        : t('Common.Filter');
      return (
        <>
          <Row style={{ width: '100%' }} gutter={8} justify="end">
            <Col span={7}>
              <AntSearchbar style={{ width: '100%' }} onFilter={value => setSearchValue(value)} />
            </Col>
            <Col span={7}>
              <DropdownTreeSelect
                style={{ width: '100%' }}
                title={filterTitle}
                showSelectAll={true}
                tree={filters}
                onChange={handleFilterChanged}
              />
            </Col>
            <Col span={7}>
              <Select
                style={{ width: '100%' }}
                open={isSortDropOpen}
                onDropdownVisibleChange={open => setIsSortDropOpen(open)}
                size="large"
                value={sortBy}
                placeholder={t('ELD.Sort By')}
                data={sorts}
                title={t('ELD.Sort By')}
                labelInValue
                onChange={handleSortChanged}
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '5px 0px' }} />
                    <div>
                      <p style={{ marginLeft: '10px' }}>{t('Common.Order')}</p>
                      <Radio.Group
                        size="large"
                        defaultValue={orderBy || OrderBy.Ascending}
                        onChange={handleOrderByChanged}
                      >
                        <Radio key={OrderBy.Ascending} value={OrderBy.Ascending}>
                          {t(`Common.${OrderBy.Ascending}`)}
                        </Radio>
                        <Radio key={OrderBy.Descending} value={OrderBy.Descending}>
                          {t(`Common.${OrderBy.Descending}`)}
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                )}
              />
            </Col>
          </Row>
        </>
      );
    },
    []
  );

  return {
    filterConfig: {
      t,
      checkedFilters,
      filters,
      sorts,
      sortBy,
      orderBy,
      isSortDropOpen
    },
    setCurrentTab,
    FilterBar,
    filterCards,
    sortCards,
    resetFilter
  };
}
