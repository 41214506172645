/* eslint-disable */
import * as $ from 'jquery';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setEvents,
  setPeriod,
  setViolations,
  refetchSentinelEvents,
  setCheckpoint,
  setGPSDataLoaded
} from 'features/sentinel/components/events/eventsSlice';
import { setUser } from './user/sentinelUserSlice';
import { API_PATH } from 'config';
import moment from 'moment';
import { updateDriverStatus } from './status/statusSlice';
import { useUserKey } from 'features/user/userSlice';
import { useCurrentCompanyId } from 'features/company/companySlice';

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const SentinelJquery = React.memo(
  React.forwardRef((props, ref) => (
    <>
      {console.log('rendering svg. If you see this repeatedly in console something is wrong.')}
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        id="sentineld3"
        width="500"
        height="400"
        ref={ref}
      />
    </>
  ))
);

const SentinelD3 = ({ svg }) => {
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const [$senObject, setSenObject] = useState(null);
  const url = API_PATH + '/sentinel';
  const user = useSelector(state => state.sentinel.user);
  const companyId = useCurrentCompanyId();
  const toDate = useSelector(state => state.sentinel.toDate);
  const fromDate = useSelector(state => state.sentinel.fromDate);
  const violationId = useSelector(state => state.sentinel.violationId);
  const eventId = useSelector(state => state.sentinel.eventId);
  const refetchEvents = useSelector(state => state.sentinel.refetchEvents);
  //const GPSDataLoaded = useSelector(state => state.sentinel.GPSDataLoaded);
  const activeRuleset = useSelector(state => state.sentinel.activeRuleset?.name);

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      if (!$senObject) {
        return;
      }
      $senObject.$element.attr('width', $senObject.$element.parent().width());
      $senObject.redraw();
    }, 250);

    window.addEventListener('resize', debouncedHandleResize);

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  $.ajaxSetup({
    headers: { Authorization: `Token token="${userKey}"` },
    data: {
      company_id: companyId
    }
  });

  useEffect(() => {
    if (!svg.current) {
      return;
    }
    if ($senObject) {
      $senObject.$element.data('sentinel-from', fromDate);
      $senObject.$element.data('sentinel-to', toDate);
      return;
    }
    const $svg = $(svg.current);
    const senObj = $svg.sentinel({
      url: url,
      embed: 'devices,vehicles',
      show_speed: true,
      show_driver: false,
      show_trace: false,
      show_rests: true,
      show_nights: true,
      show_totals: true,
      show_violations: true,
      show_control: false, //show date period control
      show_dials: true,
      show_status: true,
      show_tooltips: true,
      show_predicted: true,
      show_checkpoint: true,
      show_titles: true,
      // Each of these set Data to the Redux Store
      on_user_details: user => {
        dispatch(setUser(user));
      },
      //event callback fired after getUserDetails()
      on_events: events => {
        dispatch(setEvents(events));
      }, //event callback fired after getUserEvents()
      on_violations: violations => {
        dispatch(setViolations(violations));
      }, //event callback fired after getViolations()
      on_period: (fromDate, toDate) => {
        dispatch(setPeriod({ fromDate: fromDate.valueOf(), toDate: toDate.valueOf() }));
      }, //event callback fired after user modifies control slider
      on_status: data => {
        dispatch(updateDriverStatus({ driverId: user.id, SentinelStatus: data }));
      }, //event callback fired after getUserStatus()
      on_checkpoint: checkpoint => {
        dispatch(setCheckpoint(checkpoint));
      }, //event callback fired after getUserCheckpoint()
      on_gps: data => {
        dispatch(setGPSDataLoaded(!!data));
      } //event callback fired after getUserCheckpoint()
    });
    $svg.attr('width', $svg.parent().width());
    senObj.$element.data('sentinel-from', fromDate);
    senObj.$element.data('sentinel-to', toDate);
    setSenObject(senObj);
  }, [svg, user, dispatch, fromDate, toDate]);

  useEffect(() => {
    if (!$senObject) {
      return;
    }
    $senObject.user(user.id);
  }, [user.id, $senObject]);

  useEffect(() => {
    if (!$senObject || !user.timeZone) {
      return;
    }
    const midnight = moment(toDate)
      .add(1, 'day')
      .startOf('day')
      .valueOf();
    const morning = moment(fromDate)
      .startOf('day')
      .valueOf();
    $senObject.period(morning, midnight);
    $senObject.options.show_speed = midnight - morning === 86400000;
  }, [fromDate, toDate, $senObject, user]);

  useEffect(() => {
    if (!$senObject || !user.timeZone) {
      return;
    }
    $senObject.ruleset(activeRuleset);
  }, [activeRuleset, $senObject, user]);

  useEffect(() => {
    if (!$senObject || !violationId) {
      return;
    }
    const [action, id] = violationId.split('#!');
    if (action === 'show') {
      $senObject.chart.highlightViolation(id);
    }
    if (action === 'hide') {
      $senObject.chart.resetHighlight(id);
    }
  }, [violationId, $senObject]);

  useEffect(() => {
    if (!$senObject || !eventId) {
      return;
    }
    const [action, id] = eventId.split('#!');
    if (action === 'show') {
      $senObject.chart.showEventTooltipsy(id);
    }
    if (action === 'hide') {
      $senObject.chart.hideEventTooltipsy(id);
    }
  }, [eventId, $senObject]);

  useEffect(() => {
    if (!$senObject || !user.timeZone) {
      return;
    }
    if (refetchEvents) {
      dispatch(refetchSentinelEvents(false));
      $senObject.reload();
    }
  }, [refetchEvents, $senObject, user]);

  return useMemo(() => <></>);
};

export default SentinelD3;
