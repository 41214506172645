import React from 'react';
import { Link } from 'react-router-dom';
import { ActionMenu } from 'components/actionMenu';
import { deleteType, restoreType } from 'features/driverManagement';
import { format } from 'utils/dates';
import { Paths, Columns, ColumnKeys } from './constants';

export const filterTypes = (
  types = [],
  filters = { searchText: '', companyIds: [], fleetIds: [] }
) => {
  const { searchText, companyIds } = filters;

  return types.filter(type => {
    let isValid = true;
    // Filter by search field
    if (searchText) {
      isValid = isValid && (type?.name || '').toLowerCase().includes(searchText.toLowerCase());
    }
    // Filter by companies
    isValid = isValid && companyIds.includes(type.companyId);
    return isValid;
  });
};

export const prepareColumnsForTable = t =>
  Columns.map(column => ({
    ...column,
    title: t(`DriverMgtTypes.Table.Columns.${column.title}`)
  }));

export const prepareDataForTable = (
  types = [],
  companies = [],
  localization = {},
  can = () => {},
  entities = [],
  translate = () => {}
) => {
  return types.map(type => {
    const company = companies.find(comp => comp.id === type.companyId);
    return {
      [ColumnKeys.NAME]: can({ everyEntity: [entities.DRIVERMANAGEMENTTYPE_VIEW] }) ? (
        <Link to={`/settings/driverMgtTypes/id/${type.id}`}>{type[ColumnKeys.NAME]}</Link>
      ) : (
        <span>{type[ColumnKeys.NAME]}</span>
      ),
      [ColumnKeys.COMPANY]: company?.name || '',
      [ColumnKeys.DESCRIPTION]: type?.description || '',
      [ColumnKeys.CREATED_AT]:
        (type?.createdAt &&
          format(new Date(type.createdAt), localization.formats.time.formats.dby_imp)) ||
        '',
      [ColumnKeys.ACTIONS]: (
        <ActionMenu
          data={type}
          paths={Paths}
          dataType={'DRIVERMANAGEMENTTYPE'}
          dataTypeMessages={'DriverMgtTypes'}
          deleteAction={() => deleteType({ id: type?.id })}
          restoreAction={() => restoreType({ id: type?.id })}
          modalType={'delete'}
          typeOfEntityToDelete={translate('Common.driverType')}
        />
      ),
      key: type.id
    };
  });
};
