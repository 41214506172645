import React from 'react';
import { Tooltip, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Comparators } from 'utils/sorting';

import { useLocalization } from 'features/localization/localizationSlice';

import { GPIOStatus } from 'containers/Administration/Vehicles/constants';
import { InfoTooltipHeaderCell } from 'components/tn/grid';
import styles from 'components/tn/grid/grid.module.scss';

export const GPInputsTable = ({ data, isLoading, ...props }) => {
  const { t } = useTranslation();
  const localization = useLocalization();

  const getDescription = rowData => {
    const displayValue =
      t(
        `Alerts.GPIO.${rowData.customDescription || rowData.description}`,
        rowData.customDescription || rowData.description
      ) +
      (rowData.disambiguator
        ? ' (' + t(`Alerts.GPIO.${rowData.disambiguator}`, rowData.disambiguator) + ')'
        : '');

    return displayValue ? displayValue : '-';
  };

  const statusCellRenderer = value => {
    const gpioStatus = value?.toLowerCase() === 'on' ? GPIOStatus.On : GPIOStatus.Off;

    return value ? (
      <>
        <Tooltip title={t(gpioStatus.key)} color={gpioStatus.color}>
          <div
            className={styles.cellStatus}
            style={{
              backgroundColor: gpioStatus.color
            }}
          />
        </Tooltip>
        <span
          style={{
            marginLeft: '10px'
          }}
        >
          {t(gpioStatus.key)}
        </span>
      </>
    ) : (
      '-'
    );
  };

  const dateTimeCellRenderer = value => {
    return value ? moment(value).format(localization.formats.time.formats.dby_imp) : '-';
  };

  const columns = [
    {
      title: t('GPIO.Tables.Status'),
      key: 'status',
      dataIndex: 'status',
      fixed: 'left',
      width: 200,
      sorter: Comparators.String('status'),
      render: statusCellRenderer
    },
    {
      title: t('GPIO.Tables.Channel'),
      key: 'channel',
      dataIndex: 'channel',
      fixed: 'left',
      width: 200,
      defaultSortColumn: true,
      defaultSortOrder: 'ascend',
      sorter: Comparators.String('channel')
    },
    {
      title: t('GPIO.Tables.Description'),
      key: 'description',
      dataIndex: 'description',
      width: 200,
      sorter: Comparators.String(getDescription),
      render: (value, rowData, rowIndex) => getDescription(rowData)
    },
    {
      title: (
        <InfoTooltipHeaderCell
          label={t('GPIO.Tables.LastStatusChange')}
          tooltip={t('GPIO.Tables.LastStatusChangeTooltip')}
        />
      ),
      key: 'lastStatusChange',
      dataIndex: 'lastStatusChange',
      width: 200,
      sorter: Comparators.Date('lastStatusChange'),
      render: dateTimeCellRenderer
    }
  ];

  return (
    <Table
      className={styles.grid}
      dataSource={data}
      columns={columns}
      rowKey={'key'}
      loading={isLoading}
      showSorterTooltip={false}
      sortDirections={['ascend', 'descend', 'ascend']}
      pagination={false}
      sticky={true}
      scroll={{ x: 'max-content' }}
      {...props}
    />
  );
};
