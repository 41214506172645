import React, { useRef, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LoadingTable } from 'components/grid/LoadingTable';
import { Column, Table, AutoSizer } from 'react-virtualized';
import { useLocalization } from 'features/localization/localizationSlice';
import { useCan } from 'features/permissions';
import {
  cache,
  vehicleCellRenderer,
  lastChecklistCellRenderer,
  maintenanceTypeCellRenderer,
  serviceDueCellRenderer,
  nextDueCellRenderer,
  currentOdometerCellRenderer,
  currentEngineHoursCellRenderer,
  ellipsisButtonCellRenderer,
  statusCellRenderer,
  serviceNameCellRenderer,
  notesCellRenderer,
  registrationCellRenderer
} from './utils/CellRenderers';

import './VehicleMaintenanceSchedules.scss';

export const FlattenTable = ({ schedules, isLoading, handleDeleteAction }) => {
  const { t } = useTranslation();
  const can = useCan();
  const tableRef = useRef();
  const localization = useLocalization();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (tableRef && tableRef.current) {
      tableRef.current.recomputeRowHeights();
    }
  }, [schedules, tableRef]);

  if (isLoading) return <LoadingTable columnSizes={[73, 94, 57, 57, 94, 19]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          status: 120,
          serviceName: 200,
          maintenanceType: 200,
          lastPreTrip: 100,
          vehicle: 150,
          registration: 200,
          serviceDue: 300,
          nextDue: 300,
          currentOdometer: 200,
          currentEngineHours: 200,
          notes: 150,
          ellipsisButton: 80
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.status +
            fixedColumnsWidth.serviceName +
            fixedColumnsWidth.maintenanceType +
            fixedColumnsWidth.lastPreTrip,
          fixedColumnsWidth.vehicle +
            fixedColumnsWidth.registration +
            fixedColumnsWidth.serviceDue +
            fixedColumnsWidth.nextDue +
            fixedColumnsWidth.currentOdometer +
            fixedColumnsWidth.notes +
            fixedColumnsWidth.ellipsisButton);

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={40}
            headerStyle={{ paddingTop: '5px' }}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={schedules.length}
            rowGetter={({ index }) => schedules[index]}
            ref={tableRef}
          >
            <Column
              label={t('VehicleMntSchedules.Table.Status')}
              dataKey="status"
              width={fixedColumnsWidth.status}
              cellRenderer={statusCellRenderer}
            />
            <Column
              label={t('VehicleMntSchedules.Table.ServiceName')}
              dataKey="name"
              width={fixedColumnsWidth.serviceName}
              cellRenderer={props => serviceNameCellRenderer({ ...props, history, can })}
            />
            <Column
              label={t('VehicleMntSchedules.Table.MaintenanceType')}
              dataKey="type"
              width={fixedColumnsWidth.maintenanceType}
              cellRenderer={maintenanceTypeCellRenderer}
            />
            <Column
              label={t('VehicleMntSchedules.Table.LastPreTripChecklist')}
              dataKey="lastPretrip"
              width={fixedColumnsWidth.lastPreTrip}
              cellRenderer={lastChecklistCellRenderer}
            />
            <Column
              label={t('VehicleMntSchedules.Table.Vehicle')}
              dataKey="vehicle"
              width={fixedColumnsWidth.vehicle}
              cellRenderer={props =>
                vehicleCellRenderer({ ...props, rowData: props.rowData?.vehicle })
              }
            />
            <Column
              label={t('VehicleMntSchedules.Table.Registration')}
              dataKey="registration"
              width={fixedColumnsWidth.registration}
              cellRenderer={props =>
                registrationCellRenderer({ ...props, rowData: props.rowData?.vehicle })
              }
            />
            <Column
              label={t('VehicleMntSchedules.Table.ServiceDue')}
              dataKey="serviceDue"
              width={fixedColumnsWidth.serviceDue}
              cellRenderer={props => serviceDueCellRenderer({ ...props, localization, t })}
            />
            <Column
              label={t('VehicleMntSchedules.Table.NextDue')}
              dataKey="NextDue"
              width={fixedColumnsWidth.nextDue}
              cellRenderer={props => nextDueCellRenderer({ ...props, localization, t })}
            />
            <Column
              label={t('VehicleMntSchedules.Table.CurrentOdometer')}
              dataKey="currentOdometer"
              width={fixedColumnsWidth.currentOdometer}
              cellRenderer={props =>
                currentOdometerCellRenderer({
                  ...props,
                  rowData: props.rowData?.vehicle,
                  localization
                })
              }
            />
            <Column
              label={t('VehicleMntSchedules.Table.CurrentEngineHours')}
              dataKey="currentEngineHours"
              width={dynamicColumnWidth}
              cellRenderer={props =>
                currentEngineHoursCellRenderer({
                  ...props,
                  rowData: props.rowData?.vehicle,
                  localization
                })
              }
            />
            <Column
              label={t('VehicleMntSchedules.View.Notes')}
              dataKey="notes"
              width={dynamicColumnWidth}
              cellRenderer={props => notesCellRenderer(props)}
            />
            <Column
              label={t('VehicleMntSchedules.Table.Actions')}
              dataKey=""
              width={fixedColumnsWidth.ellipsisButton}
              cellRenderer={props =>
                ellipsisButtonCellRenderer({ ...props, handleDeleteAction, t, history, dispatch })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
