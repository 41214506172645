import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//components
import { AuditsTable } from 'components/auditsTable/AuditsTable';

//slices
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits, fetchAudits } from 'features/audits';
import { useSchedules } from 'features/vehicleMaintenance/schedulesSlice';

// helpers
import { getIDFromPathname } from 'utils/methods';

//constants
import { AUDIT_ENTITY } from 'components/auditsTable/constants';

export const ScheduleAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const audits = useAudits(AUDIT_ENTITY.VEHICLE_MAINTENANCE_SCHEDULE, id);
  const schedules = useSchedules();
  const schedule = schedules?.find(schedule => schedule.id === Number(id));
  const pageTitle = !schedule
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: schedule?.name });

  useEffect(() => {
    dispatch(fetchAudits({ entityName: AUDIT_ENTITY.VEHICLE_MAINTENANCE_SCHEDULE, entityId: id }));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    dispatch(setBackButton(true));
  }, [pageTitle, dispatch]);

  return <AuditsTable audits={audits} entity={AUDIT_ENTITY.VEHICLE_MAINTENANCE_SCHEDULE} />;
};
