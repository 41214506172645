import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import {
  fetchMeterThresholds,
  fetchAlertMeterThresholds,
  fetchAlertMeterThresholdsById,
  addMeterThresholds,
  updateAlertMeterThreshold,
  associateMeterThreshold,
  deleteMeterThresholdById
} from './thunks';

const initialState = {
  meterThresholds: [],
  meterThresholdsSources: [],
  meterThresholdsArr: {},
  meta: {
    meterThresholds: {
      isFetching: false,
      error: null,
      success: false,
      add: {
        isFetching: false,
        error: null,
        success: false
      },
      update: {
        isFetching: false,
        error: null,
        success: false
      },
      associate: {
        isFetching: false,
        error: null,
        success: false
      },
      delete: {
        isDeleting: false,
        error: null,
        success: false
      },
      meterThresholdsArr: {},
      alert: {
        isFetching: false,
        error: null,
        success: false
      }
    }
  }
};

export const meterAlertsSlice = createSlice({
  name: 'meterAlerts',
  initialState,
  extraReducers: {
    [fetchMeterThresholds.pending]: state => {
      state.meta.meterThresholds.isFetching = true;
      state.meta.meterThresholds.success = false;
      state.meta.meterThresholds.error = false;
    },
    [fetchMeterThresholds.fulfilled]: (state, action) => {
      state.meterThresholdsSources = unwrapResult(action);
      state.meta.meterThresholds.isFetching = false;
      state.meta.meterThresholds.success = true;
      state.meta.meterThresholds.error = false;
    },
    [fetchMeterThresholds.rejected]: state => {
      state.meta.meterThresholds.isFetching = false;
      state.meta.meterThresholds.error = true;
      state.meta.meterThresholds.success = false;
    },
    [updateAlertMeterThreshold.pending]: state => {
      state.meta.meterThresholds.update.isFetching = true;
      state.meta.meterThresholds.update.success = false;
      state.meta.meterThresholds.update.error = false;
    },
    [updateAlertMeterThreshold.fulfilled]: (state, action) => {
      state.meta.meterThresholds.update.isFetching = false;
      state.meta.meterThresholds.update.success = true;
      state.meta.meterThresholds.update.error = false;
    },
    [updateAlertMeterThreshold.rejected]: state => {
      state.meta.meterThresholds.update.isFetching = false;
      state.meta.meterThresholds.update.error = true;
      state.meta.meterThresholds.update.success = false;
    },
    [fetchAlertMeterThresholds.pending]: state => {
      state.meta.meterThresholds.alert.isFetching = true;
      state.meta.meterThresholds.alert.success = false;
      state.meta.meterThresholds.alert.error = false;
    },
    [fetchAlertMeterThresholds.fulfilled]: (state, action) => {
      state.meterThresholds = unwrapResult(action);
      state.meta.meterThresholds.alert.isFetching = false;
      state.meta.meterThresholds.alert.success = true;
      state.meta.meterThresholds.alert.error = false;
    },
    [fetchAlertMeterThresholds.rejected]: state => {
      state.meta.meterThresholds.alert.isFetching = false;
      state.meta.meterThresholds.alert.error = true;
      state.meta.meterThresholds.alert.success = false;
    },
    [addMeterThresholds.pending]: (state, action) => {
      state.meta.meterThresholds.add.isSaving = true;
    },
    [addMeterThresholds.rejected]: (state, action) => {
      state.meta.meterThresholds.add.isSaving = false;
      state.meta.meterThresholds.add.error = true;
      state.meta.meterThresholds.add.success = false;
    },
    [addMeterThresholds.fulfilled]: (state, action) => {
      state.meta.meterThresholds.add.isSaving = false;
      state.meta.meterThresholds.add.error = false;
      state.meta.meterThresholds.add.success = true;
    },
    [associateMeterThreshold.pending]: (state, action) => {
      state.meta.meterThresholds.associate.isSaving = true;
    },
    [associateMeterThreshold.rejected]: (state, action) => {
      state.meta.meterThresholds.associate.isSaving = false;
      state.meta.meterThresholds.associate.error = true;
      state.meta.meterThresholds.associate.success = false;
    },
    [associateMeterThreshold.fulfilled]: (state, action) => {
      state.meta.meterThresholds.associate.isSaving = false;
      state.meta.meterThresholds.associate.error = false;
      state.meta.meterThresholds.associate.success = true;
    },
    [fetchAlertMeterThresholdsById.pending]: (state, action) => {
      state.meta.meterThresholds.meterThresholdsArr[action.meta.arg.alertId] = {
        isFetching: true,
        error: false,
        success: false
      };
    },
    [fetchAlertMeterThresholdsById.rejected]: (state, action) => {
      state.meta.meterThresholds.meterThresholdsArr[action.meta.arg.alertId] = {
        isFetching: true,
        error: false,
        success: false
      };
    },
    [fetchAlertMeterThresholdsById.fulfilled]: (state, action) => {
      const unwrappedResult = unwrapResult(action);
      state.meta.meterThresholds.meterThresholdsArr[unwrappedResult.id] = {
        isFetching: false,
        error: false,
        success: true
      };
      state.meterThresholdsArr[unwrappedResult.id] = unwrappedResult.response;
    },
    [deleteMeterThresholdById.pending]: (state, action) => {
      state.meta.meterThresholds.delete.isDeleting = true;
    },
    [deleteMeterThresholdById.rejected]: (state, action) => {
      state.meta.meterThresholds.delete.isDeleting = false;
      state.meta.meterThresholds.delete.error = true;
      state.meta.meterThresholds.delete.success = false;
    },
    [deleteMeterThresholdById.fulfilled]: (state, action) => {
      state.meta.meterThresholds.delete.isDeleting = false;
      state.meta.meterThresholds.delete.error = false;
      state.meta.meterThresholds.delete.success = true;
    }
  }
});
