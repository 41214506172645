import React from 'react';
import ContentLoader from 'react-content-loader';

export const LoadingTable = ({ columnSizes, numberOfRows = 20 }) => {
  let accumulatedColumnSize = 0;

  const TableRow = props => {
    return (
      <ContentLoader
        speed={0.5}
        primaryColor="#f7f8f9"
        secondaryColor="#eeeeee"
        style={{ height: '40px', width: '100%' }}
        {...props}
      >
        {columnSizes.map((size, i) => {
          if (i === 0) {
            accumulatedColumnSize = 0;
          }
          const rect = (
            <rect
              key={`key-${size}-${i}`}
              x={accumulatedColumnSize}
              y="15"
              width={size}
              height="100%"
            />
          );
          accumulatedColumnSize += size + 2;
          return rect;
        })}
      </ContentLoader>
    );
  };

  const Table = () => (
    <>
      {Array(numberOfRows)
        .fill('')
        .map((e, i) => (
          <TableRow key={i} />
        ))}
    </>
  );

  return <Table />;
};
