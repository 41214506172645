import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import { useCan, entities } from 'features/permissions';
import { clearBulkEditError, useIsBulkEditing } from 'features/devices/devicesSlice';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

export const BulkEditRouteGuard = ({
  when,
  silentExit = false,
  exclude = (location, action) => false
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const can = useCan();
  const isBulkEditing = useIsBulkEditing();

  const confirm = useRef({
    discardConfirmed: false,
    exitBulkEditConfirmed: false
  });

  const onNavigating = useCallback(
    (location, action) => {
      const navigate = () => {
        if (location) {
          if (action === 'POP') {
            history.goBack();
          } else {
            history.push(location);
          }
        }
      };

      const needBulkEditGuard =
        !exclude(location) &&
        can({
          oneOfEntities: [entities.DEVICE_UPDATE, entities.DEVICE_DESTROY]
        }) &&
        isBulkEditing &&
        !confirm.current?.exitBulkEditConfirmed;

      const exitBulkEdit = () => {
        if (silentExit) {
          confirm.current.exitBulkEditConfirmed = true;
          navigate();
          dispatch(clearBulkEditError());
        } else {
          confirmationModal(
            t('RouteGuard.Title'),
            t('Devices.BulkEdit.RouteGuard'),
            t('RouteGuard.LeavePage'),
            t('RouteGuard.StayOnPage'),
            () => {
              confirm.current.exitBulkEditConfirmed = true;
              navigate();
              dispatch(clearBulkEditError());
            },
            null,
            () => {
              confirm.current.discardConfirmed = false;
              confirm.current.exitBulkEditConfirmed = false;
            }
          );
        }
      };

      if (when && !confirm.current?.discardConfirmed) {
        confirmationModal(
          t('RouteGuard.Title'),
          t('RouteGuard.Message'),
          t('RouteGuard.LeavePage'),
          t('RouteGuard.StayOnPage'),
          () => {
            confirm.current.discardConfirmed = true;
            if (needBulkEditGuard) {
              exitBulkEdit(location, action);
            } else {
              navigate();
            }
          }
        );
        return false;
      } else if (needBulkEditGuard) {
        exitBulkEdit(location, action);
        return false;
      }
      return true;
    },
    [t, dispatch, history, when, silentExit, exclude, confirm, isBulkEditing]
  );

  return <Prompt message={onNavigating} />;
};
