import React, { useState, useRef } from 'react';
import { Table as AntTable } from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import ResizeObserver from 'rc-resize-observer';

import '../Table.scss';

export const VirtualTable = React.forwardRef((props, tableRef) => {
  const { columnsWidth, components, ...propsMinusComponents } = props;

  const [gridWith, setGridWidth] = useState(null);

  const [vtSettings, setVTSettings] = useState({
    scroll: {
      y: 600,
      x: columnsWidth ? columnsWidth : 'max-content'
    }
  });

  const [vt] = useVT(() => vtSettings, [vtSettings]);

  const handleResize = ({ width, height, columnsWidth }) => {
    // Take ViewsGridHeader height into account
    let offsetTop = 0;
    let headerHeight = 55; // default if no columns yet
    if (tableRef.current) {
      offsetTop = tableRef.current.offsetTop;

      // Also take into account header height since it can change depending on content
      const tableHeaderRef = tableRef.current.querySelector('.ant-table-header');
      if (tableHeaderRef && props?.columns?.length) {
        headerHeight = tableHeaderRef.clientHeight;
      }
    }

    setGridWidth(width);

    const newVTSettings = {
      scroll: {
        y: height - headerHeight - offsetTop + 1,
        x: columnsWidth ? columnsWidth : 'max-content'
      }
    };

    setVTSettings(newVTSettings);
  };

  const style = columnsWidth && gridWith < columnsWidth ? { width: columnsWidth } : null;

  // vt overrides body and table to get virtual scrolling working and components passed in override
  // header to get resizable headers working so need to combine them to get all features working
  const combinedComponents = { ...vt, ...components };

  return (
    <ResizeObserver onResize={({ width, height }) => handleResize({ width, height, columnsWidth })}>
      <AntTable
        style={style}
        ref={tableRef}
        scroll={vtSettings.scroll}
        components={combinedComponents}
        {...propsMinusComponents}
      />
    </ResizeObserver>
  );
});
