//libraries
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
//hooks
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
//components
import InfoRow from 'components/form/info-row/InfoRow';
import NotAvailableLink from 'containers/SmartJobs/JobView/NotAvailableLink';
import logoTNLg from 'components/tn/assets/images/layout/nav/tn-logo-green.svg';
import Map, { MapMode } from 'components/map/Map';
import { API_PATH } from 'config';
//constants
import { LIVE_UPDATE_PUBLIC_PAGE_INTERVAL } from './constants';
import {
  MARKER_TYPE,
  MARKER_COLOR_TYPE,
  MARKER_SIZE,
  IGNITION_STATUS
} from 'components/map/markers/constants';
//styles
import styles from './PublicTrackingView.module.scss';

const getSpeed = (speedParam, localization) => {
  const speed = localization.formatSpeed(
    (!speedParam && speedParam !== 0) || speedParam < 0 ? 0 : speedParam
  );
  return speed;
};

const PublicTrackingPageStatuses = {
  NOT_VERIFIED: 'not_verified',
  AVAILABLE: 'available',
  NOT_AVAILABLE: 'not_available',
  FAILED_DETAILS_CALL: 'failed_details_calls'
};

const PublicTrackingView = () => {
  const { t } = useTranslation();

  const url = window.location.search
    .replace(/^\?/, '')
    .split('&')
    .map(str => str.split('='));
  const hParam = url[1][1];
  const pParam = url[0][1];
  const [pageStatus, setPageStatus] = useState(PublicTrackingPageStatuses.NOT_VERIFIED);
  const [vehicleDetails, setVehicleDetails] = useState([
    { label: t('Tracking.VehicleName'), value: '-' },
    { label: t('Common.LastUpdate'), value: '-' },
    { label: t('Tracking.Speed'), value: '-' },
    { label: t('Tracking.LinkExpiration'), value: '-' }
  ]);
  const [vehicleLocation, setVehicleLocation] = useState(null);
  const [vehicleCompany, setVehicleCompany] = useState(null);
  const [vehicleSpeed, setVehicleSpeed] = useState('-');
  const localization = useLocalization(!!vehicleCompany, vehicleCompany);

  const vehicleStatsRequestMethod = async verifyLinkData => {
    try {
      const vehicleDetails = await fetch(
        `${API_PATH}/vehicles/stats?id=${verifyLinkData?.vehicles[0]?.id}&embed=gps&gps=true`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token token="${verifyLinkData?.apiKey?.accessToken}"`
          }
        }
      );
      const vehicleDetailsData = await vehicleDetails.json();

      if (!vehicleDetailsData.ok && vehicleDetailsData.error && !vehicleDetailsData.length) {
        setPageStatus(PublicTrackingPageStatuses.FAILED_DETAILS_CALL);
        return;
      }

      const dateUpdateAt = new Date(vehicleDetailsData[0]?.updatedAt);
      const lastUpdateString = moment(dateUpdateAt).fromNow();
      const calculateRemainingTime = moment.duration(
        moment(verifyLinkData?.apiKey?.expiresAt) - moment(),
        'milliseconds'
      );

      setVehicleDetails([
        { label: t('Tracking.VehicleName'), value: verifyLinkData?.vehicles[0]?.name || '-' },
        { label: t('Common.LastUpdate'), value: lastUpdateString || '-' },
        { label: t('Tracking.Speed'), value: '-' },
        {
          label: t('Tracking.LinkExpiration'),
          value: (
            <>
              {calculateRemainingTime.humanize(true)}
              <Tooltip
                title={t('Tracking.LinkExpirationTooltip', {
                  date: moment(verifyLinkData?.apiKey?.expiresAt).format(
                    localization.formats.time.formats.dby_imsp
                  )
                })}
              >
                <InfoCircleOutlined className={styles.linkExpirationTooltip} />
              </Tooltip>
            </>
          )
        }
      ]);
      setVehicleLocation(null);
      setVehicleLocation({
        lat: vehicleDetailsData[0].GPS.Lat,
        lng: vehicleDetailsData[0].GPS.Lng
      });
      setVehicleCompany(verifyLinkData?.company);
      setVehicleSpeed(vehicleDetailsData[0].GPS.Spd);
    } catch (err) {
      console.log(err);
      setPageStatus(PublicTrackingPageStatuses.FAILED_DETAILS_CALL);
      return;
    }
  };

  useEffect(() => {
    if (vehicleSpeed !== '-') {
      const newDetails = [...vehicleDetails];
      newDetails[2] = { ...newDetails[2], value: getSpeed(vehicleSpeed, localization) };
      setVehicleDetails(newDetails);
    }
  }, [vehicleSpeed, vehicleCompany, vehicleLocation]);

  useEffect(async () => {
    const verifyLinkResponse = await fetch(`${API_PATH}/links/verify?p=${pParam}&h=${hParam}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const verifyLinkData = await verifyLinkResponse.json();

    if (!verifyLinkResponse.ok && verifyLinkData.error) {
      setPageStatus(PublicTrackingPageStatuses.NOT_AVAILABLE);
      return;
    }

    vehicleStatsRequestMethod(verifyLinkData);
    const interval = setInterval(
      () => vehicleStatsRequestMethod(verifyLinkData),
      LIVE_UPDATE_PUBLIC_PAGE_INTERVAL
    );

    ![PublicTrackingPageStatuses.NOT_AVAILABLE, PublicTrackingPageStatuses.NOT_AVAILABLE].includes(
      pageStatus
    ) && setPageStatus(PublicTrackingPageStatuses.AVAILABLE);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const isAvailableLinkContent = () => {
    switch (pageStatus) {
      case PublicTrackingPageStatuses.NOT_AVAILABLE:
        return <NotAvailableLink failedDetailsCall={false} styles={styles} />;
      case PublicTrackingPageStatuses.FAILED_DETAILS_CALL:
        return <NotAvailableLink failedDetailsCall={true} styles={styles} />;
      case PublicTrackingPageStatuses.AVAILABLE:
        return (
          <div className={styles.publicTrackingViewContent}>
            <div className={styles.publicTrackingViewDetails}>
              {vehicleDetails.map(detail => (
                <InfoRow
                  key={detail.label}
                  label={detail.label}
                  value={detail.value}
                  styles={styles}
                />
              ))}
            </div>
            <div className={styles.publicTrackingViewMap}>
              {vehicleLocation && (
                <Map
                  mode={MapMode.Devices}
                  devices={[
                    {
                      id: 1,
                      deviceStats: {
                        gps: { Lat: vehicleLocation.lat, Lng: vehicleLocation.lng },
                        ignition: IGNITION_STATUS.ON
                      },
                      vehicle: { id: 1 },
                      icon: '',
                      markerType: MARKER_TYPE.asset,
                      markerColor: MARKER_COLOR_TYPE.status,
                      markerSize: MARKER_SIZE.small,
                      overwriteLabel: vehicleDetails[0].value,
                      driverName: null
                    }
                  ]}
                  containerElement={<div style={{ height: `100%`, width: `100%` }} />}
                  mapElement={<div style={{ height: `100%`, width: `100%` }} />}
                  mapOptions={{
                    center: vehicleLocation,
                    mapTypeControl: false,
                    zoomControl: true,
                    streetViewControl: false,
                    fullscreenControl: false,
                    scaleControl: false,
                    zoom: 16,
                    defaultZoom: 16
                  }}
                />
              )}
            </div>
          </div>
        );
      default:
        return <div className={styles.notVerifiedContent}></div>;
    }
  };

  return (
    <div className={styles.publicTrackingViewContainer}>
      <header className={styles.publicTrackingViewHeader}>
        <div className={styles.logoWrapper}>
          {' '}
          <img src={logoTNLg} alt="TN360" className={styles.publicTrackingViewLogo} />
        </div>
      </header>
      {isAvailableLinkContent()}
      <footer className={styles.publicTrackingViewFooter}>
        <span className={styles.publicTrackingViewFooterText}>
          {t('SmartJobs.PublicPageFooterText')}
        </span>
      </footer>
    </div>
  );
};

export default PublicTrackingView;
