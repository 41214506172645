import * as Yup from 'yup';
import i18n from 'i18next';

export const initialValues = {
  CompanyId: '',
  Fleets: [],
  Name: '',
  CreateMaintenance: ['on'],
  VehicleRegistration: ['on'],
  DriverSignature: 'on'
};

export const validationSchema = () => {
  return Yup.object().shape({
    Name: Yup.string().required(i18n.t('InspectionChecklist.FormValidation.NameRequired')),
    CompanyId: Yup.string().required(i18n.t('InspectionChecklist.FormValidation.CompanyRequired'))
  });
};

export const PERMISSION = {
  PRETRIP: 'PRETRIPCHECKLIST'
};

export const STATUS = {
  ENABLED: 'ENABLED'
};
