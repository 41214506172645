import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { t_error } from 'i18nextConfig';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { ApiClient } from 'nextgen_api';
import { API_PATH } from 'config';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import { Switch, Button, Tooltip } from 'antd';

import { ELDConfigModal } from '../ELDConfigModal';
import { MulticarrierDefaultSelectModal } from '../Modals/MulticarrierDefaultSelectModal';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { CarrierTable } from './Table/CarrierTable';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { useFleets } from 'features/fleets/fleetsSlice';
import { preparFleetsForSelect } from './utils/helpers';
import useDebounce from 'utils/hooks/useDebounce';
import { Can, GlobalRoles } from 'features/permissions';
import { setBackButton, setPageTitle, addBreadcrumbs } from 'features/page/pageSlice';
import { useRunsheets } from 'features/smartJobs/smartJobsSlice';

import {
  useIsFetchingCarrierData,
  useMulticarrierFields,
  updateMultiCarrierFields
} from 'features/company_config';
import { useUserKey } from 'features/user/userSlice';
import {
  useCurrentCompany,
  useCompanyConfig,
  fetchCompanyConfig,
  useCompanyConfigMeta
} from 'features/company/companySlice';
import { filterCarrierData } from './utils/helpers';
import { PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';
import styles from './CarrierList.module.scss';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { BUTTON_IDS } from 'utils/globalConstants';

const DataStatus = {
  Init: 'Init',
  Fetching: 'Fetching',
  Fetched: 'Fetched',
  Changed: 'Changed',
  Submitting: 'Submitting',
  Submitted: 'Submitted',
  Error: 'Error'
};

const TableFilterTypes = {
  FLEET: 'fleet'
};

export const ELDCarrier = () => {
  const [dataStatus, setDataStatus] = useState(DataStatus.Init);
  const companyConfigFetchStatus = useCompanyConfigMeta();
  const { t } = useTranslation();
  const currentCompany = useCurrentCompany();
  var multicarrierEnabled = useMulticarrierFields(currentCompany.id);
  const userKey = useUserKey();
  const dispatch = useDispatch();
  const location = useLocation();
  var carrierData = useCompanyConfig();

  const isFetchingCarrierData = useIsFetchingCarrierData();

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 300);
  const statusFilter = null;
  const typeFilter = null;
  const [showELDConfigModal, setShowELDConfigModal] = useState(
    location?.state?.showELDConfigModal || false
  );
  const [showMulticarrierDefaultSelectModal, setShowMulticarrierDefaultSelectModal] = useState(
    location?.state?.showMulticarrierDefaultSelectModal || false
  );
  const [filteredCarrierData, setFilteredCarrierData] = useState(null);
  const [selectedCarrierForEdit, setCarrierForEdit] = useState([]);

  const fleets = useFleets();

  const defaultAll = {
    id: 0,
    checked: true
  };
  const selectDefaults = {
    FLEETS: {
      ...defaultAll,
      label: t('SmartJobs.AllFleets')
    }
  };
  const [selectedFleets, setSelectedFleets] = useState([]);
  const runsheets = useRunsheets();

  useEffect(() => {
    dispatch(setPageTitle(t('Common.ELDFatigue')));
    dispatch(setBackButton(false));

    dispatch(
      addBreadcrumbs([
        {
          breadcrumbName: t('CompanyConfig.Title'),
          path: PATHS.COMPANY_CONFIG
        },
        {}
      ])
    );

    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (dataStatus === DataStatus.Init) {
      if (currentCompany == null) return;

      setDataStatus(DataStatus.Fetching);
      dispatch(fetchCompanyConfig(currentCompany, userKey, true));
    }
  }, [userKey, currentCompany, dispatch]);

  useEffect(() => {
    if (!companyConfigFetchStatus.isFetching) {
      if (!companyConfigFetchStatus.error && companyConfigFetchStatus.lastFetched != null) {
        setDataStatus(DataStatus.Fetched);
      } else if (companyConfigFetchStatus.error) {
        setDataStatus(DataStatus.Error);
      }
    }
  }, [companyConfigFetchStatus]);

  useEffect(() => {
    if (location?.state?.showELDConfigModal) {
      dispatch(setBackButton(true));
    }
  }, [location, dispatch]);

  useEffect(() => {
    setFilteredCarrierData(
      filterCarrierData({ carrierData, debouncedSearchText, typeFilter, statusFilter })
    );
  }, [carrierData, debouncedSearchText, statusFilter, typeFilter]);

  const onSearch = value => setSearchText(value);

  const handleOpenELDConfigModal = () => {
    setCarrierForEdit(null);
    setShowELDConfigModal(true);
  };

  const handleEditClick = ({ entry }) => {
    if (entry?.id > 0) {
      setCarrierForEdit(entry);
    }

    setShowELDConfigModal(true);
  };

  const handleModalClose = () => {
    dispatch(fetchCompanyConfig(currentCompany, userKey, true));
  };

  // set the active filters for the tab
  const handleChange = type => items => {
    const selectItems = {
      [TableFilterTypes.FLEET]: { list: selectedFleets, fn: setSelectedFleets }
    };

    selectItems[type].fn(items);
  };

  useEffect(() => {
    // Filter Runsheet based on current active filters
    const defaultFleets = preparFleetsForSelect(fleets, selectedFleets, t);
    setSelectedFleets([
      { ...selectDefaults.FLEETS, checked: defaultFleets.every(df => df.checked) },
      ...defaultFleets
    ]);
  }, [runsheets, fleets]);

  const handleMulticarrierChange = checked => {
    if (!checked) {
      //if disabling multicarrier then we need to
      // -show the modal to select the default carrier
      // -disable the new carrier button
      setShowMulticarrierDefaultSelectModal(true);
    } else {
      //if enabling multicarrier then we need to
      // -enabled the new carrier button
      dispatch(
        updateMultiCarrierFields({
          companyId: currentCompany.id,
          body: { Config: 'true' },
          type: 'multicarrierenable'
        })
      );
      //handleMultiCarrierEnableChange(checked);
    }
    multicarrierEnabled = checked;
  };

  const handleMulticarrierSwitch = useCallback(
    checked => {
      handleMulticarrierChange(checked);
    },
    [handleMulticarrierChange]
  );

  const handleSwitchClickForSingleCarrier = (entry, checked) => {
    //if disabling carrier
    //- if company multicarrier enabled
    //  - if not last carrier, show confirmation //A: Show Confirmation
    //  - if last carrier, show message //A: Show message

    let values = { ...entry };
    if (checked) {
      //Update the database
      values.status = 'ENABLED';
      handleCarrierEnableChange(values);
    } else {
      values.status = 'DISABLED';
      var enabledCarriersCount = carrierData?.filter(c => c.status === 'ENABLED');
      if (enabledCarriersCount.length <= 1) {
        //If this is the last carrier, show a message and do nothing.

        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('CompanyConfig.ELDCarrier.DisableLastCarrier')
          })
        );
      } else {
        //If this is not the last carrier, show a confirmation message
        confirmationModal(
          `${t('Common.DisableButton')}`,
          t('CompanyConfig.ELDCarrier.DisableConfirmation'),
          t('Common.DisableButton'),
          t('Common.CancelButton'),
          () => handleDisableAction(values),
          'delete'
        );
      }
    }
  };

  const handleDisableAction = values => {
    handleCarrierEnableChange(values);
  };

  const handleCarrierEnableChange = entry => {
    setDataStatus(DataStatus.Submitting);
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const apiQuery = '/carrier';
    var values = entry;
    const promise = new Promise((resolve, reject) => {
      apiClient.callApi(
        apiQuery,
        'POST',
        { companyId: currentCompany.id },
        {},
        {},
        {},
        values,
        [],
        [],
        [],
        null,
        null,
        (err, data, resp) => {
          if (err && (resp == null || resp.status !== 200)) {
            console.error(err);
            reject(resp?.body || err);
          } else {
            resolve(resp.body);
          }
        }
      );
    });

    promise.then(
      () => {
        dispatch(openToast({ type: ToastType.Success, message: 'ELD Carrier Saved.' }));
        setDataStatus(DataStatus.Submitted);
        dispatch(fetchCompanyConfig(currentCompany, userKey, true));
      },
      err => {
        setDataStatus(DataStatus.Error);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t_error(err).toString()
          })
        );
      }
    );
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flex: '1 0 0',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            display: 'flex',
            paddingLeft: '8px',
            paddingRight: '16px',
            zIndex: '5',
            minHeight: '64px',
            background: 'white',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
          }}
        >
          <div style={{ paddingTop: '8px' }}>
            <Can everyRole={[GlobalRoles.SiteAdmin]}>
              <Switch
                title={t('CompanyConfig.ELDCarrier.EnableMultiCarrier')}
                onChange={checked => {
                  handleMulticarrierSwitch(checked);
                }}
                checked={multicarrierEnabled}
              ></Switch>
              <span className={styles.switchText}>
                {t('CompanyConfig.ELDCarrier.EnableMultiCarrier')}
              </span>
            </Can>
          </div>

          <div>
            <Tooltip
              title={
                multicarrierEnabled
                  ? t('CompanyConfig.ELDCarrier.NewCarrier')
                  : t('CompanyConfig.ELDCarrier.Disabled')
              }
            >
              <Button
                type="primary"
                onClick={handleOpenELDConfigModal}
                className={styles.newCarrierButton}
                disabled={multicarrierEnabled ? false : true}
                id={BUTTON_IDS.eldCarrierNew}
              >
                {t('CompanyConfig.ELDCarrier.NewCarrier')}
              </Button>
            </Tooltip>
          </div>
        </div>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar className={styles.filterElement} onFilter={onSearch} />

            <AntMultiselect
              title={
                !selectedFleets.some(fleet => !fleet?.checked)
                  ? t('Common.AllFleets')
                  : t('Common.Fleets')
              }
              data={selectedFleets}
              onFilter={handleChange('fleet')}
              //className={style.multiselect}
              loading={false}
            />
          </FilterWrapper>
        </div>

        <div>
          {
            <CarrierTable
              carrierData={Array.isArray(filteredCarrierData) ? filteredCarrierData : []}
              loading={isFetchingCarrierData}
              handleEditClick={handleEditClick}
              selectedFleetsFromFilter={selectedFleets}
              multiCarrierEnabled={multicarrierEnabled}
              handleSwitchClick={handleSwitchClickForSingleCarrier}
            />
          }
        </div>
      </div>
      {showELDConfigModal && (
        <ELDConfigModal
          handleShow={setShowELDConfigModal}
          show={showELDConfigModal}
          data={selectedCarrierForEdit}
          onClose={handleModalClose}
        />
      )}
      <MulticarrierDefaultSelectModal
        handleShow={setShowMulticarrierDefaultSelectModal}
        show={showMulticarrierDefaultSelectModal}
        carriers={
          Array.isArray(carrierData) ? carrierData?.filter(c => c.status === 'ENABLED') : []
        }
        onClose={handleModalClose}
      />
    </>
  );
};
