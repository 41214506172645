import { LANGUAGE_LOCALES } from 'features/localization/languages';

export const DEFAULT_PREFERENCES = {
  refresh: {
    tracking: 600,
    messaging: 60,
    sessionTimeout: 1800000
  },
  language: LANGUAGE_LOCALES.EN_AU,
  mapType: 'roadmap',
  mapLayerTraffic: false,
  vehicleMarker: 'vehicleNameDriverName',
  vehicleMarkerVisibility: 'onHover',
  clustering: false,
  temperatureTimeout: 7200000,
  trackingOutOfCoverageThreshold: 21600000,
  trackingTimeType: 'months',
  trackingTimeValue: 3,
  trackingPositionUpdateInterval: 300000,
  showGeofences: true,
  showManagedGeofences: false,
  calculateDistance: true
};

export const ShowGeofenceTypes = {
  None: 0,
  OnlyUser: 1,
  OnlyManaged: 2,
  All: 3
};

export function showGeofenceType(pref) {
  let showUserGeo = DEFAULT_PREFERENCES.showGeofences;
  let showManagedGeo = DEFAULT_PREFERENCES.showManagedGeofences;

  if (typeof pref?.showGeofences === 'boolean') {
    showUserGeo = pref.showGeofences;
  }

  if (typeof pref?.showManagedGeofences === 'boolean') {
    showManagedGeo = pref.showManagedGeofences;
  }

  if (showUserGeo && showManagedGeo) {
    return ShowGeofenceTypes.All;
  } else if (showUserGeo) {
    return ShowGeofenceTypes.OnlyUser;
  } else if (showManagedGeo) {
    return ShowGeofenceTypes.OnlyManaged;
  }
  return ShowGeofenceTypes.None;
}
