/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Company from "./Company";
import Device from "./Device";
import LocalDateTime from "./LocalDateTime";
import User from "./User";

/**
 * The VoipEvent model module.
 * @module model/VoipEvent
 * @version 1.0
 */
class VoipEvent {
  /**
   * Constructs a new <code>VoipEvent</code>.
   * @alias module:model/VoipEvent
   */
  constructor() {
    VoipEvent.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>VoipEvent</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/VoipEvent} obj Optional instance to populate.
   * @return {module:model/VoipEvent} The populated <code>VoipEvent</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new VoipEvent();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("device")) {
        obj["device"] = Device.constructFromObject(data["device"]);
      }
      if (data.hasOwnProperty("user")) {
        obj["user"] = User.constructFromObject(data["user"]);
      }
      if (data.hasOwnProperty("company")) {
        obj["company"] = Company.constructFromObject(data["company"]);
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = ApiClient.convertToType(data["type"], "String");
      }
      if (data.hasOwnProperty("channelType")) {
        obj["channelType"] = ApiClient.convertToType(
          data["channelType"],
          "String"
        );
      }
      if (data.hasOwnProperty("channel")) {
        obj["channel"] = ApiClient.convertToType(data["channel"], "String");
      }
      if (data.hasOwnProperty("state")) {
        obj["state"] = ApiClient.convertToType(data["state"], "String");
      }
      if (data.hasOwnProperty("extra")) {
        obj["extra"] = ApiClient.convertToType(data["extra"], "String");
      }
      if (data.hasOwnProperty("linkedId")) {
        obj["linkedId"] = ApiClient.convertToType(data["linkedId"], "String");
      }
      if (data.hasOwnProperty("context")) {
        obj["context"] = ApiClient.convertToType(data["context"], "String");
      }
      if (data.hasOwnProperty("accountId")) {
        obj["accountId"] = ApiClient.convertToType(data["accountId"], "String");
      }
      if (data.hasOwnProperty("destination")) {
        obj["destination"] = ApiClient.convertToType(
          data["destination"],
          "String"
        );
      }
      if (data.hasOwnProperty("timeAt")) {
        obj["timeAt"] = ApiClient.convertToType(data["timeAt"], "Date");
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = LocalDateTime.constructFromObject(data["createdAt"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
VoipEvent.prototype["id"] = undefined;

/**
 * @member {module:model/Device} device
 */
VoipEvent.prototype["device"] = undefined;

/**
 * @member {module:model/User} user
 */
VoipEvent.prototype["user"] = undefined;

/**
 * @member {module:model/Company} company
 */
VoipEvent.prototype["company"] = undefined;

/**
 * @member {String} type
 */
VoipEvent.prototype["type"] = undefined;

/**
 * @member {String} channelType
 */
VoipEvent.prototype["channelType"] = undefined;

/**
 * @member {String} channel
 */
VoipEvent.prototype["channel"] = undefined;

/**
 * @member {String} state
 */
VoipEvent.prototype["state"] = undefined;

/**
 * @member {String} extra
 */
VoipEvent.prototype["extra"] = undefined;

/**
 * @member {String} linkedId
 */
VoipEvent.prototype["linkedId"] = undefined;

/**
 * @member {String} context
 */
VoipEvent.prototype["context"] = undefined;

/**
 * @member {String} accountId
 */
VoipEvent.prototype["accountId"] = undefined;

/**
 * @member {String} destination
 */
VoipEvent.prototype["destination"] = undefined;

/**
 * @member {Date} timeAt
 */
VoipEvent.prototype["timeAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
VoipEvent.prototype["createdAt"] = undefined;

export default VoipEvent;
