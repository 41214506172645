import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { useCurrentCompany, useCompanies, useSubCompanies } from 'features/company/companySlice';
import { cache } from './CellRenderers';
import { useFleets } from 'features/fleets/fleetsSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import {
  fetchInspectionChecklists,
  useInspectionChecklists
} from 'features/inspectionChecklist/inspectionChecklistSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { prepareDataForMultiselect } from 'utils/filters';
import { InspectionChecklistTable } from './InspectionChecklistTable';
import { API_PATH } from 'config';
import request from 'superagent';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import useDebounce from 'utils/hooks/useDebounce';
import { toLower } from 'lodash';
import { Can, entities, services } from 'features/permissions';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { Button, Space, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useUserKey } from 'features/user/userSlice';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { BUTTON_IDS } from 'utils/globalConstants';
import { Dropdown } from 'components/form/dropdownselect/Dropdown';
import { useCanFeatureFlag, FeatureFlag } from 'features/permissions';
import { useNewDVIRInspectionTemplates } from 'features/inspectionChecklist/inspectionChecklistSlice';

const InspectionChecklist = () => {
  const { t } = useTranslation();
  const allInspectionChecklists = useInspectionChecklists();
  const company = useCurrentCompany();
  const userKey = useUserKey();
  const companies = useCompanies();
  const subCompanies = useSubCompanies();
  const fleets = useFleets();
  const [inspectionChecklists, setInspectionChecklists] = useState([]);
  const dispatch = useDispatch();
  const { isFetching } = useSelector(state => state.inspectionChecklists.meta);
  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(filterText, 300);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [filterFleets, setFilterFleets] = useState([]);
  const [isOpen, setModalOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('InspectionChecklist.ChecklistTitle')));
  }, [dispatch, t]);

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
  }, [subCompanies, t]);

  useEffect(() => {
    const subCompaniesIdsFromFilter = filterCompanies
      .filter(comp => comp.checked)
      .map(comp => comp.id);

    const fleetsOptions = fleets.filter(
      fleet => fleet.id && fleet.company && subCompaniesIdsFromFilter.includes(fleet.company.id)
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets'), null));
  }, [fleets, filterCompanies, t]);

  const [tableRef, setTableRef] = useState(null);
  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [inspectionChecklists, tableRef]);
  const history = useHistory();

  useEffect(() => {
    setInspectionChecklists(
      [...allInspectionChecklists].filter(inspectionChecklist => {
        let validInspectionChecklist = true;
        let inspectionContentName;
        let associationFleetIds = [];
        if (inspectionChecklist.associations) {
          associationFleetIds = JSON.parse(inspectionChecklist.associations).fleet_id;
          if (associationFleetIds) {
            associationFleetIds = associationFleetIds.map(fleetId => parseInt(fleetId, 10));
          }
        }

        if (inspectionChecklist.content) {
          inspectionContentName = JSON.parse(inspectionChecklist.content).ChecklistName;
        }

        // Filter by search field
        if (debouncedSearchText) {
          validInspectionChecklist =
            validInspectionChecklist &&
            toLower(inspectionContentName).indexOf(toLower(debouncedSearchText)) > -1;
        }
        // Filter by companies
        const checkedCompaniesIds = filterCompanies
          .filter(company => company.checked)
          .map(company => parseInt(company.id, 10));
        if (!(checkedCompaniesIds.indexOf(0) > -1)) {
          validInspectionChecklist =
            validInspectionChecklist &&
            checkedCompaniesIds.indexOf(parseInt(inspectionChecklist.company.id, 10)) > -1;
        }

        // Filter by fleets
        const checkedFleetsIds = filterFleets
          .filter(fleet => fleet.checked)
          .map(fleet => parseInt(fleet.id, 10));

        if (!(checkedFleetsIds.indexOf(0) > -1)) {
          let isInFleet = false;
          (associationFleetIds || []).forEach(fleet => {
            isInFleet = isInFleet || checkedFleetsIds.indexOf(parseInt(fleet, 10)) > -1;
          });
          if (!isInFleet && checkedFleetsIds.indexOf(-1) > -1) {
            isInFleet = associationFleetIds && !associationFleetIds.length;
          }
          validInspectionChecklist = validInspectionChecklist && isInFleet;
        }

        return validInspectionChecklist;
      })
    );
  }, [
    allInspectionChecklists,
    history.location.state,
    filterText,
    fleets,
    filterCompanies,
    filterFleets,
    debouncedSearchText,
    companies
  ]);

  const handleDeleteAction = data => () => {
    const { id, name } = data;
    let url = `${API_PATH}/pretripchecklists/${id}`;
    let method = 'DELETE';
    Mixpanel.sendTrackEvent(MPTrackingEvents.Settings.PreTripChecklist.Update, {
      typeOfUpdate: 'delete'
    });
    request(method, url)
      .set('Authorization', `Token token="${userKey}"`)
      .then(() => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18next.t('InspectionChecklist.Notifications.DeleteNotification', {
              name: name
            })
          })
        );
        dispatch(fetchInspectionChecklists());
      });
  };

  const hasNewDvirInspection = useCanFeatureFlag({
    featureFlag: FeatureFlag.dvirInspection.flag
  });

  const goToAddForm = () => {
    history.push('/settings/inspectionChecklist/newInspectionChecklist');
  };

  const goToAddDvirForm = () => {
    history.push('/settings/inspectionChecklist/newDvirInspection');
  };

  const handleInspectionCreation = () => {
    const inspectionType = selectedType || { name: 'New Inspection' };
    if (selectedTemplate) {
      JSON.parse(selectedTemplate).IsDVIR &&
        history.push({
          pathname: '/settings/inspectionChecklist/newDvirInspection',
          state: {
            selectedType: inspectionType,
            selectedTemplate: selectedTemplate
          }
        });
    } else {
      history.push('/settings/inspectionChecklist/newInspectionChecklist');
    }
  };
  const DVIRInspection = useNewDVIRInspectionTemplates();

  const handleItemSelected = type => {
    setSelectedType(type);
    const temp = DVIRInspection.templates.find(
      template => JSON.parse(template).ChecklistName === type.name
    );
    setSelectedTemplate(temp);
  };

  return (
    <>
      <Modal
        title={t('InspectionChecklist.Form.AddNew')}
        open={isOpen}
        centered={true}
        closable={false}
        width={500}
        footer={null}
      >
        <>
          <p className="inspectionFontBold">Select an inspection type</p>
          <label>{t('PreTripChecklist.Inspection')}</label>
          <Dropdown
            style={{ width: '100%' }}
            dropDownStyle={{ width: '100%' }}
            title={selectedType ? selectedType.name : 'New Inspection'}
            data={DVIRInspection.inspectionTypes.map(item => ({
              key: item.name,
              name: t(item.name)
            }))}
            onItemSelected={handleItemSelected}
          />
          <div className="pt-5">
            <Button
              type="primary"
              size="large"
              onClick={handleInspectionCreation}
              id={BUTTON_IDS.createInspection}
            >
              {t('InspectionChecklist.Form.CreateInspection')}
            </Button>
            <Button
              className="ml-2"
              type="secondary"
              size="large"
              onClick={() => setModalOpen(false)}
              id={BUTTON_IDS.cancelCreateInspection}
            >
              {t('VehicleMntSchedules.View.Cancel')}
            </Button>
          </div>
        </>
      </Modal>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <Space size={16} style={{ marginLeft: 'auto' }}>
            {/*  {!hasNewDvirInspection && (
              <Can
                everyEntity={[entities.PRETRIPCHECKLIST_CREATE]}
                oneOfCompanyServices={[services.DVIR]}
              >
                <Button
                  type="primary"
                  size="large"
                  onClick={goToAddDvirForm}
                  id={BUTTON_IDS.inspectionChecklistAddDvir}
                >
                  {t('InspectionChecklist.Form.AddDvirInspection')}
                </Button>
              </Can>
            )} */}
            <Can everyEntity={[entities.PRETRIPCHECKLIST_CREATE]}>
              <Button
                type="primary"
                size="large"
                onClick={() => (hasNewDvirInspection ? setModalOpen(true) : goToAddForm())}
                id={BUTTON_IDS.inspectionChecklistAdd}
              >
                {t('InspectionChecklist.Form.AddNew')}
              </Button>
            </Can>
          </Space>
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} />
            <AntMultiselect
              title={
                filterCompanies?.some(value => !value.checked)
                  ? t('Common.Companies')
                  : t('Common.AllCompanies')
              }
              onFilter={v => setFilterCompanies(v)}
              data={filterCompanies}
            />
            <AntMultiselect
              title={
                filterFleets?.some(value => !value.checked)
                  ? t('Common.Fleets')
                  : t('Common.AllFleets')
              }
              data={filterFleets}
              onFilter={v => setFilterFleets(v)}
            />
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {inspectionChecklists.length}{' '}
            {inspectionChecklists.length === 1
              ? `${t('PreTripChecklist.Inspection')}`
              : `${t('PreTripChecklist.Inspections')}`}
          </label>
        </div>
        <div style={{ flex: '1 0 0' }}>
          <InspectionChecklistTable
            inspectionChecklists={inspectionChecklists}
            company={company}
            companies={companies}
            fleets={fleets}
            isLoading={isFetching}
            setTableRef={setTableRef}
            typeOfEntityToDelete={t('PreTripChecklist.Inspection')}
            handleDeleteAction={handleDeleteAction}
          />
        </div>
      </ContainerPageWrapper>
    </>
  );
};

export default InspectionChecklist;
