export const PATHS = {
  SUBCOMPANY_DEFAULT: '/settings/subcompanies',
  SUBCOMPANY_ADD: '/settings/subcompanies/newcompany',
  SUBCOMPANY_VIEW: '/settings/subcompanies/id',
  SUBCOMPANY_EDIT: '/settings/subcompanies/edit/id',
  SUBCOMPANY_AUDITS: '/settings/subcompanies/audit/id'
};

export const TABS = {
  all: 'all',
  deleted: 'deleted'
};

export const ACTIONS = {
  ADD: 'add',
  EDIT: 'edit'
};

export const initialValues = {
  name: '',
  slug: '',
  externalId: '',
  parent: '',
  country: '',
  statisticsTz: '',
  locale: ''
};
