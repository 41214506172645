import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import P from 'prop-types';

import { MoreMenu } from 'components/ant';
import { useCan } from 'features/permissions/canHooks';

import { deleteModal, handleDelete, handleRestore } from './helpers';
import { EDIT_LAYOUT_MODAL, STATUS } from './constants';

export const ActionMenu = ({
  items = null,
  data,
  dataType,
  paths,
  deleteAction,
  dataTypeMessages,
  placement = 'bottomRight',
  modalType,
  formLayout,
  handleOpenModalForm,
  restoreAction,
  typeOfEntityToDelete
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const can = useCan();

  if (!data) {
    return null;
  }

  const defaultMenuItems = [
    {
      name: t(`Common.View`),
      onClick: () => {
        history.push(`${paths[`VIEW_${dataType}`]}/${data.id}`, { data });
      },
      entity: 'VIEW',
      id: `btn_${dataType?.toLowerCase().slice(0, 15)}View`
    },
    (data.status === STATUS.CREATED || data.status === STATUS.ENABLED) && {
      name: t(`Common.Edit`),
      onClick: () => {
        formLayout !== EDIT_LAYOUT_MODAL
          ? history.push(`${paths[`EDIT_${dataType}`]}/${data.id}`, { data })
          : handleOpenModalForm(data);
      },
      entity: 'UPDATE',
      id: `btn_${dataType?.toLowerCase().slice(0, 15)}Edit`
    },
    (data.status === STATUS.CREATED || data.status === STATUS.ENABLED) && {
      name: t(`Common.Delete`),
      danger: true,
      onClick: deleteModal({
        t,
        data,
        typeOfEntityToDelete,
        handleDelete,
        modalType,
        deleteAction,
        dataTypeMessages,
        dispatch
      }),
      entity: 'DESTROY',
      id: `btn_${dataType?.toLowerCase().slice(0, 15)}Delete`
    },
    (data.status === STATUS.DELETED || data.status === STATUS.DISABLED) && {
      name: t('Common.RestoreButton'),
      onClick: handleRestore({ restoreAction, data, t, dataTypeMessages, dispatch }),
      entity: 'RESTORE',
      id: `btn_${dataType?.toLowerCase().slice(0, 15)}Restore`
    }
  ];

  const menuItems = items || defaultMenuItems;

  const allowedMenuItems = dataType
    ? menuItems.filter(item => can({ everyEntity: `${dataType}_${item?.entity}` }))
    : menuItems;

  return <MoreMenu placement={placement} items={allowedMenuItems} useNewIcon />;
};

ActionMenu.propTypes = {
  data: P.object,
  dataType: P.string,
  paths: P.object,
  deleteAction: P.func,
  dataTypeMessages: P.string,
  placement: P.string,
  modalType: P.string,
  formLayout: P.string,
  handleOpenModalForm: P.func,
  restoreAction: P.func
};
