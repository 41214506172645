import React from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { openToast } from 'features/toasts/toastsSlice';
import { useUser } from 'features/user/userSlice';
import * as sentinelAPI from 'features/sentinel/endpoints/sentinelAPI';
import { refetchSentinelEvents } from 'features/sentinel/components/events/eventsSlice';

import { ToastType } from 'components/notifications/toasts/Toast';
import FormInput from 'components/form/form-input/FormInput';
import { BUTTON_IDS } from 'utils/globalConstants';

const sentinelSchema = Yup.object().shape({
  Comment: Yup.string().required('Required')
});

const SentinelDeleteFormik = ({ initialValues, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  let initialFormValues = {
    Comment: ''
  };

  if (initialValues) {
    initialFormValues = {
      id: initialValues.id ? initialValues.id : '',
      Comment: ''
    };
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={sentinelSchema}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        const payload = {
          ...values,
          Source: 'TN360',
          Parameters: {
            UserId: user.id,
            Comment: values.Comment
          }
        };
        sentinelAPI
          .deleteEvent(payload)
          .then(response => {
            dispatch(
              openToast({
                type: ToastType.Success,
                message: 'Event deleted'
              })
            );
            console.log(response);
            dispatch(refetchSentinelEvents(true));
            actions.setSubmitting(false);
          })
          .catch(error => {
            debugger;
            console.warn('ERROR', error);
            dispatch(
              openToast({
                type: ToastType.Error,
                message: 'Error deleting event.'
              })
            );
            actions.setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form key="delete-form" id="delete-form">
          <Field id="id" name="id" type="hidden" component="input" />
          <Row className="py-2 form-row align-items-end">
            <Col>
              <FormInput name="Comment" label="Comment" isRequired />
            </Col>
            <Col className="col-auto px-2 mb-3">
              <Button
                type="primary"
                htmlType="submit"
                danger={true}
                disabled={!isValid || isSubmitting}
                id={BUTTON_IDS.sentinelDeleteEvent}
              >
                {t('Delete Event')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default SentinelDeleteFormik;
