import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const mqttInitialState = {
  isMqttConnected: false,
  updates: {},
  updatesAutoHeli: {},
  meta: {
    error: null
  }
};

const mqttSlice = createSlice({
  name: 'mqtt',
  initialState: mqttInitialState,
  reducers: {
    setUpdates(state, { payload }) {
      const { deviceId, location, lastEventAt, ignition } = payload;
      const position = {
        Lat: payload.Lat,
        Lng: payload.Lng
      };
      const dir = payload.Dir;
      const speed = payload.Spd;
      state.updates[deviceId] = {
        position,
        dir,
        speed,
        location,
        lastEventAt,
        ignition
      };
    },
    setUpdatesAutoHeli(state, { payload }) {
      const { deviceId, gpsUpdate } = payload;
      if (!state.updatesAutoHeli[deviceId]) {
        state.updatesAutoHeli[deviceId] = [gpsUpdate];
      } else {
        state.updatesAutoHeli[deviceId].push(gpsUpdate);
      }
    },
    setBulkUpdates(state, { payload }) {
      payload &&
        payload.forEach(stats => {
          const data = {
            dir: stats?.gps?.Dir,
            ignition: stats?.ignition,
            position: {
              Lat: stats?.gps?.Lat,
              Lng: stats?.gps?.Lng
            },
            speed: stats?.gps?.Spd,
            location: stats?.location,
            lastEventAt: stats?.lastEventAt
          };
          state.updates[stats?.deviceId] = data;
        });
    },
    resetUpdates(state) {
      state.updates = {};
    },
    setIsMqttConnected(state, { payload }) {
      state.isMqttConnected = payload;
    }
  }
});

export const {
  setUpdates,
  setBulkUpdates,
  resetUpdates,
  setIsMqttConnected,
  setUpdatesAutoHeli
} = mqttSlice.actions;

export const setMqttSubscription = (slug, mqttClient) => dispatch => {
  mqttClient.subscribe(`+/${slug}/device/+`, () => {
    console.log('subscribed to', `+/${slug}/device/+`);
    mqttClient.on('message', (topic, message) => {
      if (message?.toString() && topic.indexOf('/heli') === -1) {
        try {
          const payload = JSON.parse(message.toString()).Content;
          const { Lat, Lng, Dir, Spd } = payload.GPS;
          const deviceId = payload.device.id;
          const { location } = payload?.result || payload || {};
          const lastEventAt = payload.when;
          const ignition = payload.ignition;
          console.debug('mqtt payload', payload);
          console.debug('mqtt ignition', ignition);
          dispatch(setUpdates({ deviceId, Lat, Lng, Dir, Spd, location, lastEventAt, ignition }));
        } catch (e) {
          console.log('eeee', e);
        }
      }
    });
  });
};

export const unsetMqttSubscription = (slug, mqttClient) => dispatch => {
  try {
    mqttClient.unsubscribe(`+/${slug}/device/+`, () => {
      console.log('unsubscribed from', slug);
    });
    dispatch(resetUpdates());
  } catch (e) {
    console.error(e);
  }
};

export const useMqttConnection = () => useSelector(state => state.mqtt.client);

export const useDeviceUpdates = id => useSelector(state => state.mqtt.updates[id]);

export const useDeviceUpdatesAll = () => useSelector(state => state.mqtt.updates);

export const useAutoHeliUpdates = () => useSelector(state => state.mqtt.updatesAutoHeli);

export const useIsMqttConnected = () => useSelector(state => state.mqtt.isMqttConnected);

export default mqttSlice.reducer;
