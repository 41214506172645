import moment from 'moment';

import { CompanyConfigValue } from 'features/company/companySlice';

import { EventTypes } from 'containers/Tracking/EventTypes';
import { TrackingPaths, HDDATA_URL_DATE_FORMAT } from './constants';

export const IgnitionOnOffEventKeys = [EventTypes.IgnitionOn.key, EventTypes.IgnitionOff.key];

export const disableOutsideOfNDays = (current, numDays, showTime) => {
  const timeUnit = showTime ? 'minutes' : 'days';
  const maxDuration = showTime ? numDays * 24 * 60 - 1 : numDays;

  // Can not select days after today or farther than N days from the start date
  const isDateToSelected = document.querySelector(
    '.ant-picker-input:first-child.ant-picker-input-active'
  );
  const inputValue = document.querySelector('.ant-picker-input:first-child input')?.value;
  const dateFrom = inputValue ? moment.utc(inputValue).tz(moment().tz(), true) : moment();
  if (isDateToSelected) {
    return current && current > moment().endOf('day');
  }
  return (
    current &&
    (current > moment().endOf('day') || moment(current).diff(dateFrom, timeUnit) > maxDuration)
  );
};

export const disableNext30Days = current => {
  // Can not select days after today or farther than 30 days from the start date
  const isDateToSelected = document.querySelector(
    '.ant-picker-input:first-child.ant-picker-input-active'
  );
  const inputValue = document.querySelector('.ant-picker-input:first-child input')?.value;
  const dateFrom = inputValue ? moment(inputValue) : moment();
  if (isDateToSelected) {
    return current && current > moment().endOf('day');
  }
  return current && (current > moment().endOf('day') || current > dateFrom.add(30, 'day'));
};

//pairwise comparison for picking up the active device of a vehicle associated with multiple devices
export const vehicleActiveDeviceComparison = (deviceA, deviceB) => {
  const aIsBBDevice = deviceA.type?.features?.includes('BB'),
    bIsBBDevice = deviceB.type?.features?.includes('BB');
  let aGPSAt = 1,
    bGPSAt = 0;
  const deviceAStats = deviceA.deviceStats,
    deviceBStats = deviceB.deviceStats;

  if ((aIsBBDevice && bIsBBDevice) || (!aIsBBDevice && !bIsBBDevice)) {
    if (deviceAStats) {
      aGPSAt = new Date(deviceAStats.gpsAt || null).getTime();
    }
    if (deviceBStats) {
      bGPSAt = new Date(deviceBStats.gpsAt || null).getTime();
    }
    return bGPSAt - aGPSAt;
  } else if (aIsBBDevice) {
    return -1; //pick up deviceA
  } else if (bIsBBDevice) {
    return 1; //pick up deviceB
  }
  return 0;
};

export const getSegmentCommonStyles = (trip, isTripActive, isEvtSegment) => ({
  segmentWrapper: {
    borderColor: '#dadee3',
    borderWidth: isEvtSegment ? '0 1px' : '0',
    cursor: trip ? 'pointer' : 'initial',
    background: isTripActive ? '#f7f8f9' : '#ffffff',
    '&:hover': {
      background: trip ? '#f7f8f9' : 'initial'
    },
    pointerEvents: trip ? 'initial' : 'none'
  },
  segmentDivider: {
    margin: '0',
    color: '#9fa9b7',
    borderLeft: '1px solid #dadee3',
    borderRight: '1px solid #dadee3',
    position: 'absolute',
    bottom: `calc(1rem - ${isEvtSegment ? 14 : 24}px)`,
    left: '0',
    zIndex: '1000'
  }
});

export const filterNonBusinessTripDevices = ({ devices, nonBusinessCompanyConfig }) => {
  return !nonBusinessCompanyConfig
    ? devices
    : devices.filter(d => d.deviceStats?.attr !== CompanyConfigValue.Private);
};

// filter out anomolous trips (no id, ignition on/off, events)
export const filterAnomolousTrips = (trips, addMissingIgnitionOnOffEvents) => {
  // console.debug('---------------------------------------------------------------------------------------------------');
  // console.debug('filterAnomolousTrips - trips input', trips);

  let filteredTrips = trips.filter((trip, index) => {
    if (addMissingIgnitionOnOffEvents) {
      // Find ignition on/off events in the list
      let ignitionOnEvent = null;
      let ignitionOffEvent = null;
      if (trip.events) {
        ignitionOnEvent = (trip?.events || []).find(
          event => event.eventType + event.subType === EventTypes.IgnitionOn.key
        );
        ignitionOffEvent = (trip?.events || []).find(
          event => event.eventType + event.subType === EventTypes.IgnitionOff.key
        );
      }

      // Add ignition on/off events to the list if they are missing and the trip specifies them
      // so that trip will display correctly in group by trips mode
      if (trip.id && trip.ignitionOn && trip.IgnOnGPS && !ignitionOnEvent) {
        const ignOnEvent = {
          GPS: trip.IgnOnGPS,
          device: trip.device,
          driverName: trip.driverName,
          eventType: 'IOR',
          id: -1,
          location: trip.startLocation,
          subType: 'ON',
          timeAt: trip.ignitionOn,
          user: trip.driver,
          vehicle: trip.vehicle
        };

        trip.events = [ignOnEvent, ...(trip.events || [])];
      }
      if (trip.id && trip.ignitionOff && trip.IgnOffGPS && !ignitionOffEvent) {
        const ignOffEvent = {
          GPS: trip.IgnOffGPS,
          device: trip.device,
          driverName: trip.driverName,
          eventType: 'IOR',
          id: -2,
          location: trip.endLocation,
          subType: 'OFF',
          timeAt: trip.ignitionOff,
          user: trip.driver,
          vehicle: trip.vehicle
        };

        //in case it doesn't have events or device event
        trip.events = [...(trip?.events || [])];

        trip.events.push(ignOffEvent);
      }
    }

    const idIgnOnOff = trip.id || trip.IgnOnGPS || trip.IgnOffGPS;
    const hasEvents = trip.events?.length > 0;

    // console.debug('filterAnomolousTrips - trip',
    //   index,
    //   trip.id,
    //   idIgnOnOff,
    //   hasEvents,
    //   uniqueEventTypes,
    // );

    return idIgnOnOff || hasEvents;
  });

  // console.debug('filterAnomolousTrips - trips output', filteredTrips);

  return filteredTrips;
};

export const inDateRange = (timeAt, dateRange) => {
  return timeAt <= dateRange.to && timeAt >= dateRange.from;
};

export const checkAndConvertToPartialTrip = (trip, dateRange) => {
  // Remove events that are outside date range
  if (trip.events) {
    const originalEventsCount = trip.events?.length;
    trip.events = trip.events.filter(event => inDateRange(event.timeAt, dateRange));
    trip.events = trip.events;
    trip.isPartialTrip = trip.isPartialTrip || trip.events.length !== originalEventsCount;
  }

  // Remove ignitionOn/Off if are out of date range
  if (trip.ignitionOn && !inDateRange(trip.ignitionOn, dateRange)) {
    trip.expandDateRange = { from: moment(trip.ignitionOn).startOf('day'), to: dateRange.to };
    trip.isPartialTrip = true;
    trip.ignitionOn = null;
    trip.IgnOnGPS = null;
  }
  if (trip.ignitionOff && !inDateRange(trip.ignitionOff, dateRange)) {
    trip.expandDateRange = { from: dateRange.from, to: moment(trip.ignitionOff).endOf('day') };
    trip.isPartialTrip = true;
    trip.ignitionOff = null;
    trip.IgnOffGPS = null;
  }

  // Remove second by second replay data that is out of range
  if (trip.replay) {
    trip.replay = trip.replay.filter(gps => inDateRange(gps.At, dateRange));
  }
};

export const buildHDDataUrl = (deviceId, dateFrom, dateTo) => {
  const url = TrackingPaths.TrackHDData.replace(':deviceId', deviceId)
    .replace(':dateFrom', encodeURIComponent(moment(dateFrom).format(HDDATA_URL_DATE_FORMAT)))
    .replace(':dateTo', encodeURIComponent(moment(dateTo).format(HDDATA_URL_DATE_FORMAT)));
  return url;
};
