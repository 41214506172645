import moment from 'moment';
import React from 'react';
import { Tooltip } from 'antd';
import { format, formatRelative } from 'utils/dates';

const pad0 = (s, n) => {
  var s1 = '' + s;
  return s1.length < n ? '0'.repeat(Math.max(n - s1.length, -1)) + s1 : s1;
};
const hours = function(n) {
  return n * 1000 * 60 * 60;
};
const minutes = function(n) {
  return n * 1000 * 60;
};
export const durationFormat = function(t) {
  if (t == null) return null;
  if (t === 0 || isNaN(t)) return 0;

  let day = Math.floor(t / hours(24));
  let hrs = Math.floor((t % hours(24)) / hours(1));
  let min = Math.floor(((t % hours(24)) % hours(1)) / minutes(1));
  let secR = Math.floor((((t % hours(24)) % hours(1)) % minutes(1)) / 1000);

  let s = '';
  if (day > 0) hrs = hrs + day * 24;
  s = s + pad0(hrs, 2) + ':' + pad0(min, 2) + ':' + pad0(secR, 2);

  if (s.length === 0) s = '0';
  if (s.substr(s.length - 1, 1) === ' ' || s.substr(s.length - 1, 1) === ':')
    return s.substr(0, s.length - 1);

  return s;
};

export const toNowStamp = (timestamp, tooltipFormat, outputFormat) => {
  if (!timestamp) {
    return null;
  }
  return (
    <Tooltip placement="bottom" mouseEnterDelay={0.25} title={format(timestamp, tooltipFormat)}>
      {Math.abs(timestamp - moment().valueOf()) >= 6 * 24 * 60 * 60 * 1000
        ? format(timestamp, outputFormat)
        : formatRelative(timestamp)}
    </Tooltip>
  );
};

export function formatTime(time) {
  return `${Math.floor(time / 3600)} hrs ${Math.floor((time % 3600) / 60)} mins`;
}
