import i18n from 'i18next';

// This is duplicated and used in 3 places, we need to move it to a generic place
// like containers/Configuration/CompanyConfig/Utils/helpers and reusit everywhere
// or to containers/Configuration/CompanyConfig/DeviceConfiguration/Utils/helpers
export const inputValidator = item => {
  return {
    number: [
      {
        validator: (_, value) => {
          const num = Number(value);
          const min = item?.min || 0;
          const max = item?.max || 999;

          if (!value) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.NumberTypeError')
            );
          }

          if (!Number.isInteger(num)) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.IntegerValue')
            );
          }

          if (num < min) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MinValue', {
                min: `${min}`
              })
            );
          }
          if (num > max) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MaxValue', {
                max: `${max || 999}`
              })
            );
          }

          return Promise.resolve();
        }
      }
    ],
    double: [
      {
        validator: (_, value) => {
          const num = Number(value);
          const min = item?.min || 0;
          const max = item?.max || 999;

          if (!value) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.NumberTypeError')
            );
          }

          if (num < min) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MinValue', {
                min: `${min}`
              })
            );
          }
          if (num > max) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MaxValue', {
                max: `${max || 999}`
              })
            );
          }

          return Promise.resolve();
        }
      }
    ]
  };
};
