import React, { useMemo, useCallback } from 'react';
import { useUsers } from 'features/users/usersSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import services from 'features/permissions/services';
import { useHistory } from 'react-router';
import Can from 'features/permissions/Can';
import { Spin } from 'antd';

const DriverFatigueType = {
  Unknown: 0,
  Sentinel: 1,
  EWD: 2,
  TACHO: 3,
  ELD: 4
};

export function ViewDriverLink({ driverId, ...props }) {
  const children = props.children;
  const users = useUsers();
  const company = useCurrentCompany();
  const history = useHistory();

  const isDataReady = useMemo(() => users?.length === 0 || company?.features == null, [
    users,
    company
  ]);

  const fatigueType = useMemo(() => {
    let type = DriverFatigueType.Unknown;
    const driver = users?.find(d => d.id === driverId);
    const isInCurrentcompany = company?.id === driver?.company?.id;

    if (driver == null || !isInCurrentcompany) {
      return type;
    }

    if (
      driver.associations?.find(
        assoc => assoc.domain === 'EWD' && assoc.externalId.includes('driver_id')
      )
    ) {
      type = DriverFatigueType.EWD;
      return type;
    }

    if (company.features?.find(f => f.code === services.ELD)) {
      type = DriverFatigueType.ELD;
      return type;
    }

    if (company.features?.find(f => f.code === services.TACHO)) {
      type = DriverFatigueType.TACHO;
      return type;
    }

    if (company.features?.find(f => f.code === services.SWD)) {
      type = DriverFatigueType.Sentinel;
      return type;
    }

    return type;
  }, [users, company, driverId]);

  const viewDriverLink = useMemo(() => {
    switch (fatigueType) {
      case DriverFatigueType.Sentinel:
        return `/fatigue/sentinel/${driverId}`;
      case DriverFatigueType.EWD:
        return `/fatigue/ewd/${driverId}`;
      case DriverFatigueType.TACHO:
        return `/fatigue/tacho`;
      case DriverFatigueType.ELD:
        return `/fatigue/eld/driverlog/${driverId}`;
      case DriverFatigueType.Unknown:
      default:
        return `/settings/users/id/${driverId}`;
    }
  }, [fatigueType, driverId]);

  const handleFailClick = useCallback(
    evt => {
      evt?.stopPropagation && evt.stopPropagation();
      history.push(`/settings/users/id/${driverId}`);
    },
    [driverId, history]
  );

  const handleClick = useCallback(
    evt => {
      evt?.stopPropagation && evt.stopPropagation();
      history.push(viewDriverLink);
    },
    [history, viewDriverLink]
  );

  const loader = (
    <div style={{ textAlign: 'center' }} onClick={evt => evt.stopPropagation()}>
      <Spin />
    </div>
  );
  return (
    <Can
      everyService={[services.SWD]}
      onFail={
        isDataReady
          ? loader
          : children({ onClick: handleFailClick, viewDriverLink: `/settings/users/id/${driverId}` })
      }
    >
      {isDataReady ? loader : children({ onClick: handleClick, viewDriverLink: viewDriverLink })}
    </Can>
  );
}
