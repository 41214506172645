/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";

/**
 * The Position model module.
 * @module model/Position
 * @version 1.0
 */
class Position {
  /**
   * Constructs a new <code>Position</code>.
   * @alias module:model/Position
   */
  constructor() {
    Position.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Position</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Position} obj Optional instance to populate.
   * @return {module:model/Position} The populated <code>Position</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Position();

      if (data.hasOwnProperty("Lat")) {
        obj["Lat"] = ApiClient.convertToType(data["Lat"], "Number");
      }
      if (data.hasOwnProperty("Lng")) {
        obj["Lng"] = ApiClient.convertToType(data["Lng"], "Number");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} Lat
 */
Position.prototype["Lat"] = undefined;

/**
 * @member {Number} Lng
 */
Position.prototype["Lng"] = undefined;

export default Position;
