import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Radio, Row, Col } from 'antd';
import moment from 'moment';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { DateRangePicker } from 'components/ant';
import { exportFile } from 'components/excelFile';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';

import { prepareFileForExcelExport } from './helpers';
import { columnWidth, ENROLMENTS_XLSX_FILE_NAME } from '../constants';

import style from './ExportEnrolmentsModal.module.scss';
import dayjs from 'dayjs';

export const ExportEnrolmentsModal = ({
  visible,
  onCloseExportModal,
  filterStatuses,
  mappedEnrolments
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const dispatch = useDispatch();

  const [exportStatusFilter, setExportStatusFilter] = useState(filterStatuses);
  const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
  const [period, setPeriod] = useState('allTime');
  const [isExportingExcel, setIsExportingExcel] = useState(false);
  const [filteredEnrolments, setFilteredEnrolments] = useState(mappedEnrolments);

  const handleDateRangeChange = dates => {
    if (!dates || !dates[0] || !dates[1]) {
      return;
    }
    const startAt = moment(dates[0].format('YYYY-MM-DD'));
    const endAt = moment(dates[1].format('YYYY-MM-DD'));
    setDateFrom(startAt.format('YYYY-MM-DD'));
    setDateTo(endAt.format('YYYY-MM-DD'));
  };
  const isDateDisabled = current => {
    const today = moment().endOf('day');
    return current > today;
  };
  const onChangePeriodRadio = e => {
    setPeriod(e.target.value);
  };

  useEffect(() => {
    const enrolmentsToFilter = mappedEnrolments;
    setFilteredEnrolments(
      enrolmentsToFilter
        .filter(enrolment => {
          let validEnrolment = true;
          //Filter by status
          const checkedStatuses = exportStatusFilter
            .filter(status => status.checked)
            .map(status => status.id);
          if (!(checkedStatuses.indexOf(0) > -1)) {
            validEnrolment = validEnrolment && checkedStatuses.indexOf(enrolment.status) > -1;
          }
          //Filter by date
          if (period === 'dateRange') {
            const enrolmentDate = moment(enrolment.startedAt).format('YYYY-MM-DD');
            validEnrolment =
              validEnrolment &&
              moment(enrolmentDate) >= moment(dateFrom) &&
              moment(enrolmentDate) <= moment(dateTo);
          }

          return validEnrolment;
        })
        .sort((a, b) => (a.id > b.id ? 1 : -1))
    );
  }, [exportStatusFilter, period, dateFrom, dateTo, mappedEnrolments]);

  const handleExportExcel = () => {
    setIsExportingExcel(true);
    const data = prepareFileForExcelExport({
      filteredEnrolments,
      localization,
      t
    });

    dispatch(
      exportFile(data, {
        title: ENROLMENTS_XLSX_FILE_NAME,
        width: columnWidth,
        dateFormat: localization.formats.time.formats.dby_imsp
      }),
      setIsExportingExcel(false)
    );
    onCloseExportModal();
  };

  return (
    <Modal
      title={t('TCAEnrolments.ExportReport')}
      open={visible}
      onOk={handleExportExcel}
      onCancel={onCloseExportModal}
      okText={t('TCAEnrolments.ExportButton')}
      okButtonProps={{
        disabled: !exportStatusFilter?.length || exportStatusFilter.every(state => !state.checked)
      }}
      confirmLoading={isExportingExcel}
      centered
    >
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <label className={style.label} htmlFor="state">
            {`${t('TCAEnrolments.State')}`}
          </label>
          <AntMultiselect
            id={'state'}
            className={style.statusSelect}
            title={
              exportStatusFilter?.some(value => !value.checked)
                ? t('TCAEnrolments.Statuses')
                : t('TCAEnrolments.AllStatuses')
            }
            data={exportStatusFilter}
            onFilter={value => {
              setExportStatusFilter(value);
            }}
          />
        </Col>
        <Col span={24}>
          <label className={style.label} htmlFor="period">
            {`${t('TCAEnrolments.Period')}`}
          </label>
          <Radio.Group id="period" onChange={e => onChangePeriodRadio(e)} defaultValue="allTime">
            <Radio className={style.radioButton} value={'dateRange'}>
              {t('TCAEnrolments.SpecifyPeriod')}
            </Radio>
            <Radio className={style.radioButton} value={'allTime'}>
              {t('TCAEnrolments.AllTimeEnrolments')}
            </Radio>
          </Radio.Group>
        </Col>
        {period === 'dateRange' && (
          <Col span={24}>
            <DateRangePicker
              className={style.dateRangePicker}
              size="small"
              format={localization.formats.time.formats.dby.toUpperCase()}
              defaultDates={[dayjs(dateFrom), dayjs(dateTo)]}
              disabledDate={current => isDateDisabled(current)}
              onDateRangeChanged={dates => {
                handleDateRangeChange(dates);
              }}
            />
          </Col>
        )}
      </Row>
    </Modal>
  );
};
