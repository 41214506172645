import React, { useCallback, useMemo } from 'react';
import { entities } from 'features/permissions';
import { useTranslation } from 'react-i18next';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { useDrivers } from '../usersSlice';
import { useDevices, useVehicles } from 'features/fleets/fleetsSlice';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { useDispatch } from 'react-redux';
import { useUserKey } from 'features/user/userSlice';
import { ListGroup } from 'react-bootstrap';
import { ToastType } from 'components/notifications/toasts/Toast';
import { fetchVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import { fetchUserDetails } from 'containers/Administration/Users/APICalls';

export function ForceLogoffMenuButton({
  itemKey,
  vehicleId,
  deviceId,
  driverId,
  onMenuClicked,
  menuType,
  showName = true,
  ...props
}) {
  const { t } = useTranslation();
  const drivers = useDrivers();
  const vehicles = useVehicles();
  const devices = useDevices();
  const dispatch = useDispatch();
  const authKey = useUserKey();

  const logonDriver = drivers?.find(d => d.id === driverId);
  const { selectedEntity, isDefaultDriver } = useMemo(() => {
    const entityType = Boolean(vehicleId) ? entities.VEHICLE : entities.DEVICE;
    const selectedVehicle = vehicles?.find(v => v.id === vehicleId);
    const selectedDevice = devices?.find(d => d.id === deviceId);
    const isDefaultDriver =
      entityType === entities.VEHICLE &&
      selectedVehicle &&
      logonDriver &&
      selectedVehicle.defaultDriver === logonDriver.id;
    return {
      selectedEntity: {
        name: entityType === entities.DEVICE ? selectedDevice?.name : selectedVehicle?.name,
        id: entityType === entities.DEVICE ? deviceId : vehicleId,
        type: entityType,
        isvalid:
          (Boolean(vehicleId) && Boolean(selectedVehicle)) ||
          (Boolean(deviceId) && Boolean(selectedDevice))
      },
      isDefaultDriver
    };
  }, [deviceId, vehicleId, vehicles, devices, logonDriver]);

  const handleForceLogoff = useCallback(
    async (driver, entity) => {
      let loggedOff = false;
      const driverName = `${driver?.firstName} ${driver?.lastName}`;
      const vehicleName = entity.name;
      const params =
        entity.type === entities.DEVICE ? { deviceIds: [entity.id] } : { vehicleIds: [entity.id] };
      try {
        loggedOff = await api
          .post(`/users/${driver?.id}/force_logoff`, { authKey }, params)
          .then(response => {
            if (response?.ok) {
              dispatch(
                openToast({
                  type: ToastType.Success,
                  message: t('Users.ForceLogoff.Success', { driverName, vehicleName })
                })
              );
              return true;
            } else {
              throw response?.error;
            }
          });
      } catch (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Users.ForceLogoff.Failed', { driverName, vehicleName })
          })
        );
      }

      if (loggedOff) {
        dispatch(fetchVehiclesStats());
        dispatch(fetchUserDetails(driver.id));
      }
    },
    [dispatch, t]
  );

  const handleMenuClick = useCallback(
    evt => {
      if (evt?.stopPropagation) evt.stopPropagation();

      const btnStyles = {
        okButtonStyles: { backgroundColor: '#ffc53d', borderColor: '#ffc53d', color: '#2b323c' },
        iconStyles: { color: '#ffc53d' }
      };
      confirmationModal(
        t('Common.Modal.SureTitle'),
        t('Users.ForceLogoff.AreYouSure', {
          driverName: `${logonDriver?.firstName} ${logonDriver?.lastName}`,
          vehicleName: selectedEntity.name
        }),
        t('Users.ForceLogoff.Title'),
        t('Common.Modal.Cancel'),
        () => handleForceLogoff(logonDriver, selectedEntity),
        'warning',
        null,
        'forceLogoffModal',
        null,
        btnStyles
      );
      if (onMenuClicked) {
        onMenuClicked();
      }
    },
    [t, logonDriver, selectedEntity, handleForceLogoff, onMenuClicked]
  );

  if (!selectedEntity.isvalid || !Boolean(driverId) || !Boolean(logonDriver)) {
    return '';
  }

  return menuType === 'ant' ? (
    <span key={itemKey} eventKey={itemKey} onClick={handleMenuClick}>
      {t('Users.ForceLogoff.Title')}
      {showName && ` ${logonDriver?.firstName} ${logonDriver?.lastName}`}
    </span>
  ) : (
    <ListGroup.Item
      action
      onClick={isDefaultDriver ? evt => evt.stopPropagation() : handleMenuClick}
      title={isDefaultDriver ? t('Users.ForceLogoff.NoDefaultDriverLogoff') : ''}
      style={(isDefaultDriver && { cursor: 'default' }) || {}}
    >
      {t('Users.ForceLogoff.Title')}
      {showName && ` ${logonDriver?.firstName} ${logonDriver?.lastName}`}
    </ListGroup.Item>
  );
}
