import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { Space } from 'antd';
import { DateRangePicker, Layout, MoreMenu, Select, Table, TabsFilters } from 'components/ant';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { CardInfoRow } from 'components/card-info-row';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import ResizeObserver from 'rc-resize-observer';
import { StarFilled } from '@ant-design/icons';
import { PieChart } from 'components/ant/Charts/pie-chart';
import {
  Tabs,
  MoreMenu as moreMenu,
  TabKeys,
  Paths,
  ColumnKeys,
  EventTypes,
  EntityTypes,
  ColorRanges
} from './constants';

import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import moment from 'moment';
import { useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import { useUsers, useIsFetching as useIsUsersFetching } from 'features/users/usersSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import {
  fetchConfig,
  fetchSummary,
  fetchEnabledEventTypes,
  useIsFetchingFinished,
  useIsScorecardFetching,
  useConfig,
  useSummary,
  useEnabledEventTypes
} from 'features/scorecard';
import { useLocalization } from 'features/localization/localizationSlice';
import {
  useBranches,
  useIsFetching as isLocationsFetching,
  useIsBranchesFetched
} from 'features/locations/locationsSlice';
import {
  useFleets,
  useIsFetching,
  useIsFetchingFinished as useIsFleetsFetchingFinished,
  useVehicles
} from 'features/fleets/fleetsSlice';
import { getTabs } from 'utils/tabs';
import useDebounce from 'utils/hooks/useDebounce';

import {
  disabledDate,
  getEnabledEventTypes,
  filterEntities,
  formatDateDifference,
  getRangeColor,
  getRowClassName,
  prepareColumnsForTable,
  prepareDataForTable,
  prepareFileForExcelExport,
  toggleAllEntities
} from './helpers';
import { getRoundValue } from 'utils/methods';
import { clearBucket, clearScore, clearScoreEvents } from 'features/scorecard/reducers';

import style from './Scorecard.module.scss';
import { ScoreConfigurationModal } from 'components/scoreConfiguration/ScoreConfiguration';
import { useCanEveryService, services } from 'features/permissions';
import dayjs from 'dayjs';
export const Scorecard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tab: initialTab } = useParams();
  const { Header, Content } = Layout;
  const localization = useLocalization();
  const currentCompany = useCurrentCompany();
  const allBranches = useBranches();
  const isBranchesFetching = isLocationsFetching();
  const isBranchesFetched = useIsBranchesFetched();
  const allFleets = useFleets();
  const isFleetsFetching = useIsFetching();
  const isFleetsFetchingFinished = useIsFleetsFetchingFinished();
  const companies = useSubCompanies();
  const allUsers = useUsers();
  const isUsersFetching = useIsUsersFetching();
  const isScoreFetched = useIsFetchingFinished();
  const allVehicles = useVehicles();
  const history = useHistory();
  const isScorecardFetching = useIsScorecardFetching();
  const datePickerRef = useRef();

  const [selectedCompany, setSelectedCompany] = useState(currentCompany?.id);
  const [activeTab, setActiveTab] = useState(initialTab || TabKeys.drivers);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [filteredFleets, setFilteredFleets] = useState([]);
  const [showScoreConfigurationModal, setShowScoreConfigurationModal] = useState(false);
  const [dateFrom, setDateFrom] = useState(
    history.location.state?.dateFrom ||
      moment()
        .add(-6, 'days')
        .format('YYYY-MM-DD')
  );
  const [dateTo, setDateTo] = useState(
    history.location.state?.dateTo || moment().format('YYYY-MM-DD')
  );
  const [datePickerOpen, setDatePickerOpen] = useState();
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(50);
  const [scoresTableHeight, setScoresTableHeight] = useState(600);

  useEffect(() => {
    datePickerRef.current?.resetDates([dayjs(dateFrom), dayjs(dateTo)]);
  }, [dateTo]);

  const getSelectedGroupsForSummary = selectedGroups => {
    let groups = selectedGroups
      ? selectedGroups
      : activeTab === TabKeys.drivers
      ? filteredBranches
      : filteredFleets;

    // TN360SPT-2985/TN360WEB-5378: Send all groups for summary in case users have different access
    // Also filter out All Branches/Fleets (keep No Branches/Fleets which is valid)
    return groups.filter(group => group.id !== 0 && group.checked).map(group => group.id);
  };

  const getSelectedGroupsForEventTypes = selectedGroups => {
    let groups = selectedGroups
      ? selectedGroups
      : activeTab === TabKeys.drivers
      ? filteredBranches
      : filteredFleets;

    // Return null if All Branches/Fleets selected so we get enabled/disabled event types for company
    // Otherwise filter out All Branches/Fleets (keep No Branches/Fleets which is valid)
    return !groups.some(group => group.id === 0 && group.checked)
      ? groups.filter(group => group.id !== 0 && group.checked).map(group => group.id)
      : null;
  };

  const getEntityType = () => {
    return activeTab === TabKeys.drivers ? EntityTypes.Location : EntityTypes.Fleet;
  };

  const debouncedSearchText = useDebounce(searchText, 300);
  const entityType = getEntityType();
  const eventTypes = useEnabledEventTypes(selectedCompany, entityType, 0, null, true);
  const summary = useSummary(dateFrom, dateTo, selectedCompany, activeTab, null, null, true);
  const config = useConfig(selectedCompany);

  const companiesForSelect = companies.map(company => ({ id: company.id, label: company.name }));
  const VPMSupport = useCanEveryService(services.VPM);

  const fetchSummaryData = async (selectedGroups = null, tab = null) => {
    const result = await dispatch(
      fetchSummary({
        startAt: dateFrom,
        endAt: dateTo,
        companyId: selectedCompany,
        entityName: tab ? tab : activeTab,
        groups: getSelectedGroupsForSummary(selectedGroups)
      })
    );

    if (result.error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t(`Scorecard.LoadError`)
        })
      );
    }
  };

  const fetchEventTypeData = (selectedGroups = null) => {
    dispatch(
      fetchEnabledEventTypes({
        companyId: selectedCompany,
        entityType: getEntityType(),
        entityId: 0,
        groups: getSelectedGroupsForEventTypes(selectedGroups)
      })
    );
  };

  const handleTabChange = tab => {
    setActiveTab(tab);
    fetchSummaryData(tab === TabKeys.drivers ? filteredBranches : filteredFleets, tab);
  };

  const handleRefresh = () => {
    fetchSummaryData();
  };

  const handleMetricScoring = () => {
    history.push(`${Paths.METRIC_SCORING}/${activeTab}`);
  };

  const handleManageGeofences = () => {
    history.push(`/settings/geofences/excludeScorecardEvents`);
  };

  const handleCompanyChange = companyId => {
    setSelectedCompany(companyId);
  };

  const handleBranchChange = newBranches => {
    setFilteredBranches(newBranches);
  };

  const handleFleetChange = newFleets => {
    setFilteredFleets(newFleets);
  };

  const handleDateRangeChange = dates => {
    if (!dates || !dates[0] || !dates[1]) {
      return;
    }
    const tempStartAt = moment(dates[0].format('YYYY-MM-DD'));
    const tempEndAt = moment(dates[1].format('YYYY-MM-DD'));

    const startAt = tempStartAt;
    const endAt = tempEndAt;

    setDateFrom(startAt.format('YYYY-MM-DD'));
    setDateTo(endAt.format('YYYY-MM-DD'));

    history.replace(history.location.path, {
      dateFrom: startAt.format('YYYY-MM-DD'),
      dateTo: endAt.format('YYYY-MM-DD')
    });
  };

  const handleDateRangeClose = isOpen => {
    setDatePickerOpen(isOpen);
  };

  const handleSearchTable = text => {
    setSearchText(text);
  };

  const handleScoreConfiguration = () => {
    setShowScoreConfigurationModal(true);
  };

  const handleCancelScoreConfigurationModal = () => {
    setShowScoreConfigurationModal(false);
  };

  const handlePaginationChange = (page, pageSize) => {
    setPageSize(pageSize);
  };

  const handleResize = ({ width, height }) => {
    let headerHeight = 66;
    const tableHeight = height - headerHeight;

    setScoresTableHeight(tableHeight);
  };

  const moreMenuItems = [
    {
      name: t(`Scorecard.${moreMenu.Refresh}`),
      onClick: handleRefresh,
      id: 'btn_scorecardMenuRefresh'
    },
    {
      name: t(`Scorecard.${moreMenu.MetricScoring}`),
      onClick: handleMetricScoring,
      id: 'btn_scorecardMenuMetric'
    },
    {
      name: t(`Scorecard.${moreMenu.ScoreConfiguration}`),
      onClick: handleScoreConfiguration,
      id: 'btn_scorecardScoreConfig'
    },
    {
      name: t(`Scorecard.${moreMenu.ManageGeofences}`),
      onClick: handleManageGeofences,
      id: 'btn_scorecardManageGeo'
    },
    {
      name: t(`Scorecard.${moreMenu.ExcelExport}`),
      onClick: () =>
        prepareFileForExcelExport(
          dataForTable,
          {
            company: currentCompany?.name,
            scope: activeTab,
            isDriverScope: activeTab === TabKeys.drivers,
            selection: activeTab === TabKeys.drivers ? filteredBranches : filteredFleets,
            dateFrom,
            dateTo
          },
          average,
          t,
          localization,
          eventTypes
        ),
      id: 'btn_scorecardExcelExport'
    }
  ];

  // Select all branches. Triggered when we get all the branches from APIs.
  useEffect(() => {
    toggleAllEntities(
      selectedCompany,
      allBranches,
      setFilteredBranches,
      t('Users.AllBranches'),
      t('Users.NoBranch')
    );
  }, [allBranches]);

  // Select all fleets. Triggered when we all fleets from APIs.
  useEffect(() => {
    toggleAllEntities(
      selectedCompany,
      allFleets,
      setFilteredFleets,
      t('Common.AllFleets'),
      t('Common.NoFleet')
    );
  }, [allFleets]);

  useEffect(() => {
    if (activeTab === TabKeys.drivers) {
      // Onces branches are fully fetched and converted to filteredBranches
      // then we can trigger calls to get summary and eventType data
      if (
        filteredBranches &&
        filteredBranches.length > 0 &&
        !isBranchesFetching &&
        isBranchesFetched
      ) {
        fetchSummaryData(filteredBranches);
        fetchEventTypeData(filteredBranches);
      }
    }
  }, [filteredBranches]);

  useEffect(() => {
    if (activeTab === TabKeys.vehicles) {
      // Onces fleets are fully fetched and converted to filteredFleets
      // then we can trigger calls to get summary and eventType data
      if (
        filteredFleets &&
        filteredFleets.length > 0 &&
        !isFleetsFetching &&
        isFleetsFetchingFinished
      ) {
        fetchSummaryData(filteredFleets);
        fetchEventTypeData(filteredFleets);
      }
    }
  }, [filteredFleets]);

  // Set default selected company. Triggered when we get the currentCompany.
  useEffect(() => {
    setSelectedCompany(currentCompany.id);
  }, [currentCompany]);

  const { users, vehicles } = useMemo(() => {
    return filterEntities(allUsers, allVehicles, selectedCompany, filteredBranches, filteredFleets);
  }, [selectedCompany, filteredFleets, filteredBranches, allUsers, allVehicles]);

  useEffect(() => {
    if (!datePickerOpen) {
      if (
        (activeTab === TabKeys.drivers &&
          filteredBranches &&
          filteredBranches.length > 0 &&
          !isBranchesFetching &&
          isBranchesFetched) ||
        (activeTab === TabKeys.vehicles &&
          filteredFleets &&
          filteredFleets.length > 0 &&
          !isFleetsFetching &&
          isFleetsFetchingFinished)
      )
        fetchSummaryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datePickerOpen]);

  const { dataForTable, average, liveAverage } = useMemo(() => {
    const entities = activeTab === TabKeys.vehicles ? vehicles : users;

    const dataForTable = prepareDataForTable({
      data: summary,
      entities,
      activeTab,
      debouncedSearchText,
      hasGroup: true,
      localization
    });

    return {
      dataForTable: dataForTable.data,
      average: dataForTable.group || dataForTable.average,
      liveAverage: dataForTable.liveAverage
    };
  }, [summary, vehicles, users, activeTab, debouncedSearchText, localization]);

  useEffect(() => {
    if (isScoreFetched) {
      dispatch(fetchConfig({ companyId: selectedCompany }));

      // Since company changed, update branches/fleets to be onces in company
      if (activeTab === TabKeys.drivers) {
        toggleAllEntities(
          selectedCompany,
          allBranches,
          setFilteredBranches,
          t('Users.AllBranches'),
          t('Users.NoBranch')
        );
      } else {
        toggleAllEntities(
          selectedCompany,
          allFleets,
          setFilteredFleets,
          t('Common.AllFleets'),
          t('Common.NoFleet')
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany]);

  useEffect(() => {
    return () => {
      dispatch(clearBucket());
      dispatch(clearScore());
      dispatch(clearScoreEvents());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPageTitle(t('Common.Scorecard')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  const period = formatDateDifference(dateFrom, dateTo, t, localization);

  // Filter the event types we show in the UI by the ones that are enabled
  const enabledEventTypes = getEnabledEventTypes(
    Object.values(EventTypes(localization)),
    eventTypes
  );

  return (
    <Layout>
      <Header>
        <div className={style.spaceAround}>
          <div>
            <TabsFilters tabs={getTabs(Tabs, activeTab, handleTabChange, t)} />
          </div>
          <div style={{ marginRight: '16px' }}>
            <MoreMenu
              size="xl"
              border
              items={moreMenuItems}
              placement="bottomRight"
              useNewIcon={true}
            />
          </div>
        </div>
      </Header>
      <Content className={style.content}>
        <div className={`${style.filterWrapper} ${style.spaceAround}`}>
          <Space size={16}>
            <AntSearchbar
              className={style.searchBar}
              size={'medium'}
              onFilter={handleSearchTable}
            />
            <Select
              size="default"
              className={style.select}
              placeholder={t(`Common.Company`)}
              data={companiesForSelect}
              value={selectedCompany}
              onChange={handleCompanyChange}
            />
            {activeTab === TabKeys.drivers && (
              <AntMultiselect
                title={
                  !filteredBranches.find(branch => !branch.checked)
                    ? t('Users.AllBranches')
                    : t('Common.Branches')
                }
                data={filteredBranches}
                onFilter={handleBranchChange}
                className={style.multiselect}
                loading={isBranchesFetching}
              />
            )}
            {activeTab === TabKeys.vehicles && (
              <AntMultiselect
                title={
                  !filteredFleets.find(branch => !branch.checked)
                    ? t('Common.AllFleets')
                    : t('Common.Fleets')
                }
                data={filteredFleets}
                onFilter={handleFleetChange}
                className={style.multiselect}
                loading={isFleetsFetching}
              />
            )}
          </Space>
          <DateRangePicker
            className={style.dateRangePicker}
            size="small"
            format={localization.formats.time.formats.dby.toUpperCase()}
            maxDayRange={30}
            defaultDates={[dayjs(dateFrom), dayjs(dateTo)]}
            disabledDate={current => disabledDate(current, dayjs(dateFrom))}
            onDateRangeChanged={dates => {
              handleDateRangeChange(dates);
            }}
            availableDatesRange={[0, moment().endOf('day')]}
            onOpenChange={handleDateRangeClose}
            datePickerRef={datePickerRef}
          />
        </div>
        <div className={style.totalsWrapper}>
          <div className={style.summaries}>
            <Space size={40}>
              <CardInfoRow label={t('Scorecard.Period')} value={period} />
              <CardInfoRow
                label={
                  activeTab === TabKeys.drivers
                    ? t('Scorecard.TotalDrivers')
                    : t('Scorecard.TotalVehicles')
                }
                value={dataForTable.length}
              />
              <CardInfoRow label={t('Scorecard.TotalScore')} value={average.normalizedScore} />
              <CardInfoRow label={t('Scorecard.CompanyTargetScore')} value={config.companyTarget}>
                {average.metTargetScore && <StarFilled className={style.metCompanyTargetIcon} />}
              </CardInfoRow>
            </Space>
          </div>
          <div className={style.chartsContainer}>
            <Space size={18}>
              {enabledEventTypes.map((event, index) => {
                const currentScore = average[event.dataKey];
                const previousScore = average[`${event.dataKey}ForTrends`];
                const color = getRangeColor(currentScore, config.colorBands);
                const eventData = {
                  name: t(event.name),
                  value: getRoundValue(currentScore, 2),
                  color: ColorRanges[color],
                  percentage: `${t('Scorecard.PreviousScore')}: ${previousScore}`,
                  diff: currentScore - previousScore,
                  key: 1
                };
                const totalData = {
                  value: getRoundValue(100 - currentScore, 2),
                  percentage: `${t('Scorecard.PreviousScore')}: ${previousScore}`,
                  diff: currentScore - previousScore,
                  key: 2
                };
                return (
                  <PieChart
                    key={`pieChart-${index}`}
                    data={[eventData, totalData]}
                    properties={{
                      height: 167,
                      width: 167,
                      innerRadius: 58,
                      outerRadius: 71,
                      startAngle: 450,
                      endAngle: 90
                    }}
                  />
                );
              })}
            </Space>
          </div>
        </div>
        <div className={style.scoresTableWrapper}>
          <ResizeObserver onResize={({ width, height }) => handleResize({ width, height })}>
            <Table
              dataSource={dataForTable}
              className={style.scoresTable}
              columns={prepareColumnsForTable({
                average: liveAverage,
                style,
                t,
                localization,
                dateFrom,
                dateTo,
                tab: activeTab,
                selectedCompany,
                VPMSupport,
                eventTypesConfig: eventTypes
              })}
              loading={isScorecardFetching || isUsersFetching || isFleetsFetching}
              pagination={{
                pageSize,
                onChange: handlePaginationChange
              }}
              scroll={{ y: scoresTableHeight }}
              rowKey={ColumnKeys.ENTITY_ID}
              rowClassName={record => getRowClassName(record, config.colorBands, style)}
            />
          </ResizeObserver>
        </div>
      </Content>
      <ScoreConfigurationModal
        handleCancel={handleCancelScoreConfigurationModal}
        isOpen={showScoreConfigurationModal}
        title={t('Scorecard.ScoreConfiguration')}
      />
    </Layout>
  );
};
