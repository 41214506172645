import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { useDriverFatigueStatus } from 'features/tracking/cardView/driverCardSlice';
import { useVehicle } from 'features/fleets/fleetsSlice';

import { EllipsisButtonContextMenu } from './EllipsisButtonContextMenu';
import { DriverName } from 'containers/Tracking/Common/DiverName';
import { CardViewMap } from './CardViewMap';

import HideNonBusinessStatus from '../../Common/HideNonBusinessStatus';

import { DUTY_STATUS } from '../../constants';
import { BUTTON_IDS } from 'utils/globalConstants';

export const DriverCard = ({
  card,
  geofences = [],
  isPinned,
  onPinClick,
  showMap,
  toggleMapView,
  onMessageVehicle,
  cardKey,
  hideNonBusiness
}) => {
  const { t } = useTranslation();
  const driverStatus = useDriverFatigueStatus(
    card.driver,
    card.driver?.driverFatiguStatus?.fatigueType
  );
  const defaultDriverDutyStatus = DUTY_STATUS.OffDuty;
  const vehicleInfo = useVehicle(card.vehicle?.id, 'image_url');

  return (
    <div
      style={{
        cursor: 'grab',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        color: 'black',
        borderRadius: '4px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        transition: 'box-shadow .1s ease-in-out',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateColumns: '15px 64px auto 32px 32px',
        gridTemplateRows: '30px 1px 24px 6px auto',
        paddingTop: '8px',
        '&:hover': {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.35)'
        }
      }}
    >
      {/* Duty Status Bar */}
      <div
        style={{
          gridRowStart: 1,
          gridRowEnd: 4,
          gridColumnStart: 1,
          gridColumnEnd: 1,
          backgroundColor: hideNonBusiness
            ? DUTY_STATUS.NonBusiness.color
            : driverStatus?.dutyStatus?.color ?? defaultDriverDutyStatus.color,
          borderRadius: 4,
          minWidth: 6,
          justifySelf: 'flex-end'
        }}
      />
      {/* Driver Name */}
      <div
        style={{
          display: 'flex',
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 2,
          gridColumnEnd: 4,
          padding: '0 4px',
          color: '#515e71',
          alignItems: 'center'
        }}
      >
        <span
          style={{
            margin: 0,
            fontSize: '20px',
            fontWeight: '500',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          <DriverName device={card} />
        </span>
      </div>

      {/* Pin Button */}
      <div
        style={{
          display: 'flex',
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 4,
          gridColumnEnd: 5,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          onClick={() => onPinClick(card)}
          style={{
            padding: 0,
            minHeight: 'unset',
            height: '20px',
            width: '20px',
            backgrounColor: 'transparent',
            color: isPinned ? '#26bd8b' : '#969696',
            '&:hover': {
              backgrounColor: 'transparent'
            }
          }}
          id={BUTTON_IDS.driverCardPin}
        >
          <i style={{ fontSize: '20px' }} className={isPinned ? 'tn-i-pin' : 'tn-i-unpin'} />
        </Button>
      </div>

      {/* Elipsis Button */}
      <div
        style={{
          display: 'flex',
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 5,
          gridColumnEnd: 6,
          padding: '4px 8px 0 0',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <EllipsisButtonContextMenu
          iconStyle={{ fontSize: '16px' }}
          iconHorizontal
          card={card}
          isCardPinned={isPinned}
          showMap={showMap}
          setShowMap={toggleMapView}
          onMessageVehicle={onMessageVehicle}
          cardKey={cardKey}
          hideNonBusiness={hideNonBusiness}
        />
      </div>

      {/* Duty Status */}
      <div
        style={{
          display: 'flex',
          gridRowStart: 2,
          gridRowEnd: 4,
          gridColumnStart: 2,
          gridColumnEnd: 4,
          padding: '0 8px 0 4px',
          alignItems: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        {hideNonBusiness ? (
          <HideNonBusinessStatus />
        ) : (
          <div
            style={{
              padding: '2px 16px',
              backgroundColor: driverStatus?.dutyStatus?.color ?? defaultDriverDutyStatus.color,
              color: 'white',
              fontSize: '13px',
              fontWeight: '500',
              borderRadius: '3px'
            }}
          >
            {t(
              `Tracking.DutyStatus.${driverStatus?.dutyStatus?.status ??
                defaultDriverDutyStatus.status}`
            )}
          </div>
        )}
      </div>

      {/* Vehicle Name */}
      <div
        style={{
          gridRowStart: 3,
          gridRowEnd: 3,
          gridColumnStart: 3,
          gridColumnEnd: 6,
          textAlign: 'right',
          alignItems: 'right',
          justifyContent: 'right',
          padding: '0 1rem',
          color: '#515e71'
        }}
      >
        <span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            textAlign: 'right',
            alignItems: 'right',
            justifyContent: 'right'
          }}
        >
          {card.vehicleName}
        </span>
      </div>

      {/* Driver Image */}
      <div
        style={{
          display: 'flex',
          gridRowStart: 5,
          gridRowEnd: 6,
          gridColumnStart: 1,
          gridColumnEnd: 6,
          backgrounColor: 'white',
          backgroundImage: `url(${vehicleInfo?.imageUrl})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#515e71'
        }}
      >
        {showMap ? (
          <CardViewMap card={card} geofences={geofences} />
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                borderStyle: 'solid',
                borderWidth: '8px',
                borderColor: 'white',
                backgrounColor: '#dadee3',
                boxShadow: '0px 5px 8px rgba(0, 0, 0, 0.25)',
                borderRadius: 99999,
                height: '150px',
                width: '150px',
                background: `#969696 url(${card.driverImage}) no-repeat center center/cover`,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {!card.driverImage && (
                <div
                  style={{
                    fontSize: '5rem',
                    color: '#fff',
                    fontWeight: '500'
                  }}
                >
                  {card.driverName === 'Not Logged In'
                    ? ''
                    : card.driverName
                        .split(/\s+/g)
                        .map(word => word && word[0])
                        .filter(char => !!char)
                        .join('')
                        .toUpperCase()
                        .slice(0, 2)}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
