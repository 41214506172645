import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { format } from 'utils/dates';
import { ViewPage } from 'components/ant';
import { ToastType } from 'components/notifications/toasts/Toast';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { Table } from 'antd';
import { KEY_MAPPINGS, Paths, CodeLabels, CodeKeys } from '../';

import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { deleteCode, useIsFetchingCompleted, useSmartJobsCodes } from 'features/smartJobs';
import { useLocalization } from 'features/localization/localizationSlice';

const formatValue = (key, value, dateFormat) => {
  if (!value) {
    return;
  }

  if (key === CodeKeys.codeType) {
    return KEY_MAPPINGS[value];
  }

  if ([CodeKeys.updatedAt, CodeKeys.createdAt].includes(key)) {
    return format(new Date(value), dateFormat);
  }

  if (key === CodeKeys.codes) {
    return (
      <Table
        columns={[
          {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            width: 100
          },
          {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'
          }
        ]}
        dataSource={value.map((current, index) => ({
          key: index,
          code: current.code,
          description: current.description
        }))}
        pagination={false}
      />
    );
  }

  return value;
};

export const ViewCode = () => {
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );
  const codes = useSmartJobsCodes();
  const history = useHistory();
  const [code, setCode] = useState({});
  const [viewData, setViewData] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localization = useLocalization();
  const dateFormat = localization.formats.time.formats.dby_imp;
  const isFetchingCompleted = useIsFetchingCompleted();

  const handleError = useCallback(
    err => {
      if (history.location.pathname !== Paths.CODES_TABLE) {
        if (err) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: err
            })
          );
        }
        history.replace(Paths.CODES_TABLE);
      }
    },
    [history, dispatch]
  );

  useEffect(() => {
    const parsedId = parseInt(id);
    if (
      parsedId <= 0 ||
      parsedId > Number.MAX_SAFE_INTEGER ||
      isNaN(parsedId) ||
      (isFetchingCompleted && !codes?.some(c => c.id === parsedId) && code?.id !== parsedId)
    ) {
      handleError(t('Common.Invalid Request ID'));
    }
  }, [t, id, handleError, codes, isFetchingCompleted, code]);

  useEffect(() => {
    if (codes?.length > 0) {
      const locatedCode = codes.find(c => parseInt(c.id, 10) === parseInt(id, 10));
      setCode(locatedCode);
    }
  }, [codes, id]);

  useEffect(() => {
    if (code) {
      dispatch(setPageTitle(code?.name || ''));
      setViewData(
        CodeLabels.map(({ label, key }) => {
          return {
            label: t(`SmartJobsCodes.${key}`),
            value: formatValue(key, code[key], dateFormat)
          };
        })
      );
    }
  }, [code, dispatch]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  const deleteCodeModal = () => {
    confirmationModal(
      `${t('Common.DeleteButton')} ${code.name}`,
      `${t('Common.SureDelete')} ${t('Common.smartjobCode')} ${code.name}?`,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      handleDeleteCode,
      'delete'
    );
  };

  const handleDeleteCode = async () => {
    try {
      const deleteResponse = await dispatch(deleteCode(code.id));
      unwrapResult(deleteResponse);

      dispatch(
        openToast({
          type: ToastType.Success,
          message: `${t(`SmartJobsCodes.deleteSuccess`)} ${code.name}!`
        })
      );
      // Go back to the table page
      history.push(Paths.CODES_TABLE);
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${err.message}`
        })
      );
    }
  };

  return (
    <ViewPage
      data={viewData}
      title={t(`SmartJobsCodes.Details`)}
      onEdit={() => history.push(`${Paths.EDIT_CODE}/${code.id}`, { code })}
      onDelete={deleteCodeModal}
    />
  );
};
