import React from 'react';
import { Switch } from 'react-router-dom';

import { useGetQsUserQuery } from 'services/nextgen';

import { FeatureFlag, services } from 'features/permissions';

import { useSSOGuard, SSO_GUARD_FEATURE } from 'containers/SSO/ssoHook';

import CanRoute from 'components/route/CanRoute';
import InsightsPage from './Pages/InsightsPage';
import Console from './Console';
import DashboardPage from './Pages/DashboardPage';
import AnalysisPage from './Pages/AnalysisPage';
import { DashboardsPage } from './Pages/DashboardsPage';
import { AnalysisToolbar } from './Toolbars/AnalysisToolbar';
import { DashboardToolbar } from './Toolbars/DashboardToolbar';
import { DashboardsToolbar } from './Toolbars/DashboardsToolbar';
import { ReportsToolbar } from './Toolbars/ReportsToolbar';
import ReportsPage from './Pages/ReportsPage';
import { ReportsCataloguePage } from './Pages/ReportsCataloguePage';
import { MyReportsPage } from './Pages/MyReportsPage';
import { ReportsScheduledPage } from './Pages/ReportsScheduledPage';

export const InsightsRouter = () => {
  const { ssoAuthed } = useSSOGuard(SSO_GUARD_FEATURE.Insights);
  const qsUser = useGetQsUserQuery()?.data;
  const isQsUserAuthor = qsUser?.role?.toUpperCase() === 'AUTHOR';

  return (
    <Switch>
      <CanRoute
        path={'/insights/console'}
        render={<InsightsPage SubPage={Console} />}
        otherConditions={[() => ssoAuthed]}
        featureFlag={FeatureFlag.insightsQSConsole.flag}
      />
      <CanRoute
        path={'/insights/reports/myreports'}
        render={<ReportsPage SubPage={MyReportsPage} />}
        oneOfCompanyServices={[services.INSIGHTSNPI]}
        otherConditions={[() => ssoAuthed]}
      />
      <CanRoute
        path={'/insights/reports/catalogue'}
        render={<ReportsPage SubPage={ReportsCataloguePage} />}
        oneOfCompanyServices={[services.INSIGHTSNPI]}
        otherConditions={[() => ssoAuthed]}
      />
      <CanRoute
        path={'/insights/reports/scheduled'}
        render={<ReportsPage SubPage={ReportsScheduledPage} />}
        oneOfCompanyServices={[services.INSIGHTSNPI]}
        otherConditions={[() => ssoAuthed]}
      />
      <CanRoute
        path={'/insights/dashboard/:dashboardId'}
        render={<InsightsPage toolbar={<DashboardToolbar />} SubPage={DashboardPage} />}
        otherConditions={[() => ssoAuthed]}
      />
      <CanRoute
        path={'/insights/analysis/:analysisId'}
        render={<InsightsPage toolbar={<AnalysisToolbar />} SubPage={AnalysisPage} />}
        everyService={[services.INSIGHTSBUILDER]}
        everyCompanyService={[services.INSIGHTSBUILDER]}
        otherConditions={[() => ssoAuthed, () => isQsUserAuthor]}
      />
      <CanRoute
        path={['/insights/dashboards', '/insights']}
        render={<InsightsPage toolbar={<DashboardsToolbar />} SubPage={DashboardsPage} />}
        otherConditions={[() => ssoAuthed]}
      />
    </Switch>
  );
};
