/* global google */

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';
import Map, { MapMode } from 'components/map/Map';
import { Loading } from 'components/loading/Loading';

import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { useIsPositionsFetching, usePositions } from 'features/fbt';
import { GOOGLE_MAPS_API_KEY } from 'config';

import { ReactComponent as ChevronLeft } from 'static/images/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'static/images/icons/chevron-right.svg';
import styles from './FBTManager.module.scss';
import { getUniqueGPSEntries } from './utils/helpers';

import InfoRow from 'components/form/info-row/InfoRow';

import { FbtItemColumnKeys, FBTRows } from './utils';
import { Select } from 'components/ant';
import { getBoundsFromLatLng, getBoundsZoomLevel } from '../../utils/geo';
import { FileUnknownOutlined } from '@ant-design/icons';

export const FBTModal = ({
  isModalVisible,
  data = {},
  onNext,
  onPrevious,
  tripStates = [],
  onSelectType,
  onCancel,
  editable
}) => {
  const userPreferences = useUserPreferences();
  const { t } = useTranslation();
  const mapRef = useRef();
  const events = {
    startAt: data.originalStartedAt,
    endAt: data.originalEndedAt
  };
  const positions = usePositions(data.device?.id, events, data.id);
  const isPositionsLoading = useIsPositionsFetching(data.id);
  const [uniqueLatLngArray, setUniqueLatLngArray] = useState();
  const [bounds, setBounds] = useState();

  const getMap = () => {
    if (!bounds || !positions.length) {
      return (
        <div className={styles.noRouteMessageContainer}>
          <FileUnknownOutlined className={styles.noRouteIcon} />
          <div>{t('FBTManager.Modal.NoTripRouteFound')}</div>
        </div>
      );
    }

    return (
      <Map
        ref={mapRef}
        mode={MapMode.Trip}
        selectedTripSegment={{
          replay: uniqueLatLngArray,
          IgnOnGPS: uniqueLatLngArray?.[0],
          IgnOffGPS: uniqueLatLngArray?.[uniqueLatLngArray?.length - 1]
        }}
        defaultZoom={12}
        zoom={bounds && getBoundsZoomLevel(bounds, { height: 700, width: 550 }) - 1}
        center={bounds && bounds?.getCenter()}
        mapTypeId={google.maps.MapTypeId.ROADMAP}
        mapOptions={{
          mapTypeId: userPreferences?.mapType || google.maps.MapTypeId.ROADMAP
        }}
        enableMapMenu={false}
        enableInfoWindowActions={false}
        enableVehicleClustering={false}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: '550px', width: `100%` }} />}
        mapElement={<div style={{ height: `100%`, width: `100%` }} />}
      />
    );
  };

  useEffect(() => {
    const unique = getUniqueGPSEntries(positions);
    setUniqueLatLngArray(unique);
    const newBounds = getBoundsFromLatLng(unique);
    setBounds(newBounds);
  }, [positions]);

  const rowKeys = Object.keys(FBTRows).filter(row => row !== 'ACTIONS');

  const getInfoRowLabel = rowKey => {
    switch (rowKey) {
      case 'vehicleName':
        return t('FBTManager.vehicle');
      case 'firstName':
        return t('FBTManager.driver');
      default:
        return t(`FBTManager.${rowKey}`);
    }
  };

  const getInfoRowValue = rowKey => {
    switch (rowKey) {
      case 'purpose':
        return (
          <Select
            key={`${data.createdAt}`}
            width={180}
            data={tripStates.map?.(tripState => ({
              id: tripState,
              label: t(`FBTManager.${tripState}`, tripState)
            }))}
            placeholder={data[rowKey]}
            onChange={onSelectType(data.id)}
            disabled={!editable}
          />
        );
      case FbtItemColumnKeys.FIRST_NAME:
        return data[rowKey] ? `${data[rowKey]} ${data[FbtItemColumnKeys.LAST_NAME]}` : '-';
      default:
        return data[rowKey] || '-';
    }
  };

  const renderRows = () =>
    rowKeys.map(key => {
      const rowKey = FBTRows[key];

      return (
        <InfoRow
          key={rowKey}
          label={getInfoRowLabel(rowKey)}
          value={getInfoRowValue(rowKey)}
          styles={styles}
          labelWidth="35%"
          sxValue={{ width: 180 }}
        />
      );
    });

  return (
    <Modal
      className={styles.modalContainer}
      title={t('FBTManager.Modal.Trip')}
      open={isModalVisible}
      width={1024}
      footer={null}
      onCancel={onCancel}
    >
      <div className={styles.navButtonsContainer}>
        <div className={styles.navButton} onClick={onPrevious}>
          <ChevronLeft />
          <div className={styles.text}>{t('FBTManager.Modal.PreviousTrip').toUpperCase()}</div>
        </div>
        <div className={styles.navButton} onClick={onNext}>
          <div className={styles.text}>{t('FBTManager.Modal.NextTrip').toUpperCase(0)}</div>
          <ChevronRight />
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.infoRowsContainer}>
          <>{renderRows()}</>
        </div>
        <div className={styles.mapContainer}>
          {isPositionsLoading ? (
            <div className={styles.loaderContainer}>
              <Loading />
            </div>
          ) : (
            getMap()
          )}
        </div>
      </div>
    </Modal>
  );
};
