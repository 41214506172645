import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { prepareB2BIntegrationsAuthHeaders } from 'services/util';

import { API_PATH_WITHOUT_V1 } from 'config';

export const ngB2bIntegrationsApi = createApi({
  reducerPath: 'ngB2bIntegrationsApi',
  baseQuery: retry(
    fetchBaseQuery(
      {
        baseUrl: `${API_PATH_WITHOUT_V1}`,
        prepareHeaders: prepareB2BIntegrationsAuthHeaders
      },
      { maxRetries: 0 }
    )
  ),
  tagTypes: ['AdaptersById', 'ResendEvent'],
  endpoints: builder => ({
    getAdapters: builder.query({
      query: ({ companyId }) => ({
        url: 'b2b/v2/adapters',
        params: { company_id: companyId }
      }),
      extraOptions: { maxRetries: 2 }
    }),
    getAdapterById: builder.query({
      query: ({ id, companyId }) => ({
        url: `b2b/v2/adapters/${id}`,
        params: { company_id: companyId }
      }),
      extraOptions: { maxRetries: 2 },
      providesTags: ['AdaptersById'],
      async onQueryStarted(arg, { dispatch, getState, queryFulfilled }) {
        try {
          const { data: updatedAdapter } = await queryFulfilled;
          const companyId = getState().companies.current.id;

          dispatch(
            ngB2bIntegrationsApi.util.updateQueryData('getAdapters', { companyId }, draft => {
              const manipulatedAdapters = draft.reduce((acc, curr) => {
                if (curr.type === updatedAdapter.type) {
                  Object.assign(curr, updatedAdapter);
                  acc.push(curr);
                } else {
                  acc.push(curr);
                }

                return acc;
              }, []);
              Object.assign(draft, manipulatedAdapters);
            })
          );
        } catch {}
      }
    }),
    addInbound: builder.mutation({
      query: ({ type, companyId, body }) => ({
        url: `b2b/v2/adapters/${type}/inbound`,
        params: { company_id: companyId },
        method: 'POST',
        body
      }),
      invalidatesTags: ['AdaptersById']
    }),
    addOutbound: builder.mutation({
      query: ({ type, companyId, body, editId }) => ({
        url: `b2b/v2/adapters/${type}/outbound${editId ? `/${editId}` : ''}`,
        params: { company_id: companyId },
        method: editId ? 'PUT' : 'POST',
        body
      }),
      invalidatesTags: ['AdaptersById']
    }),
    disableInbound: builder.mutation({
      query: ({ adapterId, companyId, keyId }) => ({
        url: `b2b/v2/adapters/${adapterId}/inbound/${keyId}`,
        params: { company_id: companyId },
        method: 'DELETE'
      }),
      invalidatesTags: ['AdaptersById']
    }),
    deleteOutbound: builder.mutation({
      query: ({ type, companyId, id }) => ({
        url: `b2b/v2/adapters/${type}/outbound/${id}`,
        params: { company_id: companyId },
        method: 'DELETE'
      }),
      invalidatesTags: ['AdaptersById']
    }),
    validateAdapter: builder.mutation({
      query: ({ type, body }) => ({
        url: `b2b/v2/adapters/${type}/validate`,
        method: 'POST',
        body
      })
    }),
    getEvents: builder.query({
      query: ({ companyId, adapterType, selectedDates }) => ({
        url: 'b2b/v2/events',
        params: {
          company_id: companyId,
          adapter_type: adapterType,
          from: selectedDates[0],
          to: selectedDates[1]
        }
      }),
      providesTags: ['ResendEvent']
    }),
    getEventById: builder.mutation({
      query: ({ eventId, companyId, timeAt }) => ({
        url: `b2b/v2/events/${eventId}`,
        params: { company_id: companyId, time_at: timeAt }
      })
    }),
    resendEvent: builder.mutation({
      query: ({ eventId, companyId, timeAt, body }) => ({
        url: `b2b/v2/events/${eventId}/resend`,
        params: { company_id: companyId, time_at: timeAt },
        method: 'POST',
        body
      }),
      invalidatesTags: ['ResendEvent']
    }),
    bulkResend: builder.mutation({
      query: ({ companyId, adapterType, to, from }) => ({
        url: 'b2b/v2/events/resend',
        params: {
          company_id: companyId,
          adapter_type: adapterType,
          from: from,
          to: to
        },
        method: 'POST'
      }),
      invalidatesTags: ['ResendEvent']
    })
  })
});

export const {
  useGetAdaptersQuery,
  useAddInboundMutation,
  useAddOutboundMutation,
  useDisableInboundMutation,
  useDeleteOutboundMutation,
  useValidateAdapterMutation,
  useGetAdapterByIdQuery,
  useGetEventsQuery,
  useGetEventByIdMutation,
  useLazyGetEventsQuery,
  useResendEventMutation,
  useBulkResendMutation
} = ngB2bIntegrationsApi;
