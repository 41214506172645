import P from 'prop-types';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Table } from 'components/ant';

import { prepareEventsForTable } from '../utils/helpers';
import { getRunsheetEventsTableColumns } from '../utils/translation';
import {
  useDeletedDevices,
  useIsFetching as useIsFetchingDeletedDevices
} from 'features/devices/devicesDeletedSlice';
import { useDevices, useIsFetching as useIsFetchingDevices } from 'features/fleets/fleetsSlice';
import { useUsers, useIsFetching as useIsFetchingUsers } from 'features/users/usersSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import {
  useBranches,
  useIsFetching as useIsFetchingBranches
} from 'features/locations/locationsSlice';
import { useIsFetchingRunsheetEvents } from 'features/smartJobs';

export const EventsTable = ({ events }) => {
  const localization = useLocalization();
  const devices = useDevices();
  const deletedDevices = useDeletedDevices();
  const users = useUsers();
  const branches = useBranches();
  const isFetchingDeletedDevices = useIsFetchingDeletedDevices();
  const isFetchingDevices = useIsFetchingDevices();
  const isFetchingUsers = useIsFetchingUsers();
  const isFetchingBranches = useIsFetchingBranches();
  const isFetchingEvents = useIsFetchingRunsheetEvents();
  const { t } = useTranslation();

  const TABLE_RV_EVENTS_COLUMNS = getRunsheetEventsTableColumns(t);

  return (
    <Table
      columns={TABLE_RV_EVENTS_COLUMNS}
      dataSource={prepareEventsForTable(
        events,
        devices,
        deletedDevices,
        users,
        branches,
        localization,
        t
      )}
      pagination={false}
      loading={
        isFetchingDevices ||
        isFetchingDeletedDevices ||
        isFetchingUsers ||
        isFetchingBranches ||
        isFetchingEvents
      }
    />
  );
};

EventsTable.propTypes = {
  runsheetData: P.object,
  events: P.array
};
