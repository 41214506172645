import React from 'react';
import { Table, Typography, Switch } from 'antd';
import i18n from 'i18next';

import styles from '../CarrierList.module.scss';
import { Comparators } from 'utils/sorting';
import { SortUtil } from 'components/tables/SortUtil';

export const ColumnKeys = {
  STATUS: 'status',
  NAME: 'name',
  DOT_NUMBER: 'dotNumber',
  ASSOCIATED_FLEET: 'fleet.name',
  ADDRESS: 'address1',
  CITY: 'city',
  STATE: 'state',
  COUNTRY: 'country',
  ACTIONS: 'actions'
};

const columns = () => {
  return [
    {
      title: i18n.t('CompanyConfig.ELDCarrier.Status'),
      dataIndex: ColumnKeys.STATUS,
      key: ColumnKeys.STATUS
    },
    {
      title: i18n.t('CompanyConfig.ELDCarrier.CarrierName'),
      dataIndex: ColumnKeys.NAME,
      key: ColumnKeys.NAME,
      defaultSortColumn: true,
      defaultSortOrder: 'ascend',
      sorter: Comparators.String(ColumnKeys.NAME)
    },
    {
      title: i18n.t('CompanyConfig.ELDCarrier.DOTNumber'),
      dataIndex: ColumnKeys.DOT_NUMBER,
      key: ColumnKeys.DOT_NUMBER,
      sorter: Comparators.Number(ColumnKeys.DOT_NUMBER)
    },
    {
      title: i18n.t('CompanyConfig.ELDCarrier.AssociatedFleet'),
      dataIndex: ColumnKeys.ASSOCIATED_FLEET,
      key: ColumnKeys.ASSOCIATED_FLEET,
      sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, ColumnKeys.ASSOCIATED_FLEET)
    },
    {
      title: i18n.t('CompanyConfig.ELDCarrier.Address'),
      dataIndex: ColumnKeys.ADDRESS,
      key: ColumnKeys.ADDRESS,
      sorter: Comparators.String(ColumnKeys.ADDRESS)
    },
    {
      title: i18n.t('CompanyConfig.ELDCarrier.City'),
      dataIndex: ColumnKeys.CITY,
      key: ColumnKeys.CITY,
      sorter: Comparators.String(ColumnKeys.CITY)
    },
    {
      title: i18n.t('CompanyConfig.ELDCarrier.State'),
      dataIndex: ColumnKeys.STATE,
      key: ColumnKeys.STATE,
      sorter: Comparators.String(ColumnKeys.STATE)
    },
    {
      title: i18n.t('CompanyConfig.ELDCarrier.Country'),
      dataIndex: ColumnKeys.COUNTRY,
      key: ColumnKeys.COUNTRY,
      sorter: Comparators.String(ColumnKeys.COUNTRY)
    },
    {
      title: i18n.t('CompanyConfig.ELDCarrier.Actions'),
      dataIndex: ColumnKeys.ACTIONS,
      key: ColumnKeys.ACTIONS
    }
  ];
};

export const PrepareDataForTable = ({
  carrierData,
  selectedFleetsFromFilter,
  multiCarrierEnabled,
  handleEditClick = () => {},
  handleSwitchClick = () => {}
}) => {
  var filteredCarriers = carrierData;
  var checkedFleets = [];
  if (Array.isArray(selectedFleetsFromFilter)) {
    checkedFleets = selectedFleetsFromFilter.filter(f => f.checked === true);
  }

  if (checkedFleets.length > 0 && checkedFleets.filter(f => f.id === 0).length === 0) {
    let res = [];
    res = carrierData.filter(el => {
      return checkedFleets.find(element => {
        return element.id === el.fleet?.id || (element.id === -1 && !el.fleet?.id);
      });
    });

    filteredCarriers = res;
  }

  return filteredCarriers?.map(entry => {
    var checkedStatus = entry?.status === 'ENABLED';
    return {
      [ColumnKeys.STATUS]: (
        <Switch
          checked={checkedStatus}
          onChange={checked => {
            checkedStatus = !checkedStatus;
            handleSwitchClick(entry, checked);
          }}
          disabled={multiCarrierEnabled ? false : true}
        ></Switch>
      ),
      [ColumnKeys.NAME]: entry.name,
      [ColumnKeys.DOT_NUMBER]: entry.dotNumber,
      [ColumnKeys.ASSOCIATED_FLEET]: entry.fleet?.name,
      [ColumnKeys.ADDRESS]: entry.address1,
      [ColumnKeys.CITY]: entry.city,
      [ColumnKeys.STATE]: entry.state,
      [ColumnKeys.COUNTRY]: entry.country,
      [ColumnKeys.ACTIONS]: (
        <Typography.Link className={styles.downloadLink} onClick={() => handleEditClick({ entry })}>
          {i18n.t('Alerts.Edit')}
        </Typography.Link>
      ),
      key: entry.id
    };
  });
};

export const CarrierTable = ({
  carrierData,
  selectedFleetsFromFilter,
  loading,
  multiCarrierEnabled,
  handleEditClick,
  handleSwitchClick
}) => (
  <Table
    className={styles.table}
    columns={columns()}
    dataSource={PrepareDataForTable({
      carrierData,
      selectedFleetsFromFilter,
      multiCarrierEnabled,
      handleEditClick,
      handleSwitchClick
    })}
    loading={loading}
    pagination={false}
    scroll={{ y: 'calc(100vh - 300px)' }}
    showSorterTooltip={false}
  />
);
