import { useMemo } from 'react';
import { useCamerasStatus } from 'features/camera/cameraSlice';
import { CAMERA_DIAGNOSTIC_STATUS } from '../helpers';

export const useSnapshotEntityCameraStatus = snapshotsEntity => {
  const cameraIds = useMemo(
    () =>
      (snapshotsEntity?.nodeType === 'Vehicle'
        ? snapshotsEntity?.devices?.filter(
            device => device?.id && device?.type?.code === 'CAMERA'
          ) || []
        : snapshotsEntity?.deviceId && snapshotsEntity?.deviceTypeCode === 'CAMERA'
        ? [{ id: snapshotsEntity?.deviceId }]
        : []
      ).map(c => c.id),
    [snapshotsEntity]
  );

  const camerasStatus = useCamerasStatus(cameraIds);

  const { availablePositions, isDeviceFailure } = useMemo(
    () => ({
      availablePositions: Object.values(camerasStatus || {}).reduce(
        (a, cameraStatus) => Array.from(new Set([...a, ...(cameraStatus?.cameraPositions || [])])),
        []
      ),
      isDeviceFailure: cameraIds.some(
        deviceId => camerasStatus?.[deviceId]?.status === CAMERA_DIAGNOSTIC_STATUS.RED
      )
    }),
    [camerasStatus, snapshotsEntity]
  );

  return {
    availablePositions,
    isDeviceFailure
  };
};
