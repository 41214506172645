import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { sortBy } from 'lodash';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18n from 'i18next';
import { canHistoryGoBack } from 'utils/methods';
import { BRANCH_TYPE } from '../../containers/Administration/Users/constants';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { parseErrorMessage } from 'utils/strings';

// Endpoint URLS
export const LOCATIONS_URL = '/locations';

const locations = {
  locationsList: [],
  deletedLocationsList: [],
  branches: [],
  meta: {
    lastFetched: {
      locations: null,
      deletedLocations: null,
      branches: null
    },
    isFetching: {
      locations: false,
      deletedLocations: false,
      branches: false
    },
    error: {
      locations: null,
      deletedLocations: null,
      branches: null
    },
    isListEmpty: {
      locations: false,
      deletedLocations: false
    },
    isBranchesListEmpty: false,
    companyKey: {
      locations: null,
      deletedLocations: null,
      branches: null
    }
  }
};

function startLoading(state, { payload }) {
  state.meta.isFetching[payload] = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching[action.payload.type] = false;
  state.meta.lastFetched[action.payload.type] = 'now';
  state.meta.error[action.payload.type] = action.payload.err;
  state.meta.isListEmpty[action.payload.type] = true;
  state.meta[`${action.payload.type}List`] = [];
  state.meta.companyKey[action.payload.type] = action.payload.companyKey;
}

function loadingFailedBranches(state, action) {
  state.meta.isFetching.branches = false;
  state.meta.lastFetched.branches = 'now';
  state.meta.error.branches = action.payload.err;
  state.meta.isBranchesListEmpty = true;
  state.branches = [];
  state.meta.companyKey.branches = action.payload.companyKey;
}

const locationsSlice = createSlice({
  name: 'locations',
  initialState: locations,
  reducers: {
    fetchLocationsStart: startLoading,
    fetchLocationsSuccess(state, { payload }) {
      const locations = payload.locationsList
        .filter(location => location.id !== BRANCH_TYPE.NO_BRANCH)
        .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));

      state.locationsList = locations;
      state.meta.isFetching.locations = false;
      state.meta.lastFetched.locations = 'now';
      state.meta.error.locations = null;
      state.meta.isListEmpty.locations = locations.length === 0;
      state.meta.companyKey.locations = payload.companyKey;
    },
    fetchDeletedLocationsSuccess(state, { payload }) {
      const deletedLocations = payload.deletedLocationsList
        .filter(location => location.id !== BRANCH_TYPE.NO_BRANCH)
        .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
      state.deletedLocationsList = deletedLocations;
      state.meta.isFetching.deletedLocations = false;
      state.meta.lastFetched.deletedLocations = 'now';
      state.meta.error.deletedLocations = null;
      state.meta.isListEmpty.deletedLocations = deletedLocations.length === 0;
      state.meta.companyKey.deletedLocations = payload.companyKey;
    },
    fetchLocationsFailure: loadingFailed,
    fetchBranchesStart: startLoading,
    fetchBranchesSuccess(state, { payload }) {
      state.branches = payload.branches;
      state.meta.isFetching.branches = false;
      state.meta.lastFetched.branches = 'now';
      state.meta.error.branches = null;
      state.meta.isBranchesListEmpty = payload.branches.length === 0;
      state.meta.companyKey.branches = payload.companyKey;
    },
    fetchBranchesFailure: loadingFailedBranches
  }
});

const {
  fetchLocationsStart,
  fetchLocationsSuccess,
  fetchDeletedLocationsSuccess,
  fetchLocationsFailure,
  fetchBranchesStart,
  fetchBranchesSuccess,
  fetchBranchesFailure
} = locationsSlice.actions;

export const fetchLocations = (types = []) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies?.current?.id;

  if (!companyKey || !companyId || !authKey) {
    return;
  }

  // add types to the url
  const locationTypes =
    types.length &&
    types.reduce((acc, current) => {
      acc += `&type[]=${current}`;
      return acc;
    }, '');

  const urlForLocations = `${LOCATIONS_URL}?direction=DOWN&embed=address,geofences,type${locationTypes ||
    ''}&company_id=${companyId}`;

  dispatch(fetchLocationsStart('locations'));

  try {
    const response = await api.get(urlForLocations, { authKey });
    const { body } = response;
    dispatch(fetchLocationsSuccess({ locationsList: body, companyKey }));
  } catch (err) {
    console.error(err);
    dispatch(fetchLocationsFailure({ err: err.toString(), companyKey, type: 'locations' }));
  }
};

export const fetchDeletedLocations = () => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies?.current?.id;

  if (!companyKey || !companyId || !authKey) {
    return;
  }

  const urlForDeletedLocations = `${LOCATIONS_URL}?direction=DOWN&status=DELETED&embed=address,geofences,type&company_id=${companyId}`;

  dispatch(fetchLocationsStart('deletedLocations'));

  try {
    const response = await api.get(urlForDeletedLocations, { authKey });
    const { body } = response;
    dispatch(fetchDeletedLocationsSuccess({ deletedLocationsList: body, companyKey }));
  } catch (err) {
    console.error(err);
    dispatch(fetchLocationsFailure({ err: err.toString(), companyKey, type: 'deletedLocations' }));
  }
};

export const getSortedBranches = (_branches, isSiteAdmin) => {
  //Filter the branches API response alphabetically
  const sortedResponse = _branches ? sortBy(_branches, [b => b.name.toUpperCase()]) : [];

  // Threat branches as it follows:
  // 1. API provides "No Branch (id: -1) value"
  //   Yes -> Move it as the last value and translate it
  //   No -> If the user is siteAdmin, add "No Branch" manually as last value
  // Ref: TN360WEB-2369 comments

  // Check if API returns "No branch" (id: -1)
  const isNoBranchFound = sortedResponse?.find(branch => branch.id === BRANCH_TYPE.NO_BRANCH);

  // Move the No Branch as last value, if provided
  const branches = [
    ...sortedResponse.filter(branch => branch.id !== BRANCH_TYPE.NO_BRANCH),
    ...(isNoBranchFound ? [{ ...isNoBranchFound, name: i18n.t('Users.NoBranch') }] : [])
  ];

  // if No branch is not provided but user is siteAdmin, add it manually
  if (isSiteAdmin && !isNoBranchFound) {
    branches.push({
      id: BRANCH_TYPE.NO_BRANCH,
      name: i18n.t('Users.NoBranch'),
      status: 'ENABLED',
      type: {
        id: 1,
        name: 'Branch'
      },
      addedManually: true //Used by messaging to hide branch user doesn't have permission to access
    });
  }
  return branches;
};

export const fetchBranches = () => (dispatch, getState) => {
  const company = getState().companies?.current;
  const userKey = getState().user?.current?.auth?.key;
  const isSiteAdmin = getState().user.currentUserInfo?.siteAdmin;
  try {
    if (company == null || company.api_key == null || userKey == null) {
      return;
    }
    dispatch(fetchBranchesStart('branches'));
    let url = `${API_PATH}/locations?direction=DOWN&type[]=BRANCH&company_id=${company.id}`;

    request('GET', url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(resp => {
        dispatch(
          fetchBranchesSuccess({
            branches: getSortedBranches(resp.body, isSiteAdmin),
            companyKey: company.api_key
          })
        );
      })
      .catch(err => {
        dispatch(fetchBranchesFailure({ err: err.toString(), companyKey: company.api_key }));
      });
  } catch (err) {
    dispatch(fetchBranchesFailure({ err: err.toString(), companyKey: company.api_key }));
  }
};

export const useLocations = types => {
  const dispatch = useDispatch();
  const locations = useSelector(state => state.locations.locationsList);
  const isFetching = useSelector(state => state.locations.meta.isFetching.locations);
  const isListEmpty = useSelector(state => state.locations.meta.isListEmpty.locations);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent('locations');

  if (!isFetching && (isCompanyKeyDifferent || (locations.length === 0 && !isListEmpty))) {
    dispatch(fetchLocations(types));
  }
  return locations;
};

export const useDeletedLocations = flagForAPICall => {
  const dispatch = useDispatch();
  const isFetching = useSelector(state => state.locations.meta.isFetching.deletedLocations);
  const isListEmpty = useSelector(state => state.locations.meta.isListEmpty.deletedLocations);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent('deletedLocations');
  const deletedLocations = useSelector(state => state.locations.deletedLocationsList);
  if (
    !isFetching &&
    (isCompanyKeyDifferent || (deletedLocations.length === 0 && !isListEmpty)) &&
    flagForAPICall
  ) {
    dispatch(fetchDeletedLocations());
  }

  return deletedLocations;
};

export const useBranches = () => {
  const dispatch = useDispatch();
  const branches = useSelector(state => state.locations.branches);
  const isFetching = useSelector(state => state.locations.meta.isFetching.branches);
  const isBranchesListEmpty = useSelector(state => state.locations.meta.isBranchesListEmpty);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent('branches');

  if (!isFetching && (isCompanyKeyDifferent || (branches.length === 0 && !isBranchesListEmpty))) {
    dispatch(fetchBranches());
  }

  return branches;
};
export const useIsFetching = (type = 'locations') =>
  useSelector(state => state.locations.meta.isFetching[type]);
export const useCompanyKey = type => useSelector(state => state.locations.meta.companyKey[type]);

export const useIsCompanyKeyDifferent = type => useCompanyKey(type) !== useCurrentCompanyKey();

export const deleteLocationApi = (data, history) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const { id, name } = data;
  const url = `/locations/${id}`;
  Mixpanel.sendTrackEvent(MPTrackingEvents.Settings.Locations.Update, { typeOfUpdate: 'delete' });
  try {
    const response = await api.delete(url, { authKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('Locations.Notifications.Delete', { name })
        })
      );
      dispatch(fetchLocations());
      dispatch(fetchDeletedLocations());
      history && canHistoryGoBack(history, '/settings/locations');
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${name} could not be deleted: ${err}`
      })
    );
  }
};

export const restoreLocationApi = data => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const { id, name } = data;
  const url = `${API_PATH}/locations/${id}/restore`;
  request('PUT', url)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(resp => {
      if (resp.ok) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('Locations.Notifications.Restore', { name })
          })
        );
        dispatch(fetchLocations());
        dispatch(fetchDeletedLocations());
      }
    })
    .catch(err => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
      );
    });
};

export const useIsBranchesFetched = () =>
  useSelector(state => state.locations.meta.lastFetched.branches === 'now');

export const useIsLocationsFetched = () =>
  useSelector(
    state =>
      state.locations.meta.lastFetched.locations && !state.locations.meta.isFetching.locations
  );
export const useIsDeletedLocationsFetched = () =>
  useSelector(
    state =>
      state.locations.meta.lastFetched.deletedLocations &&
      !state.locations.meta.isFetching.deletedLocations
  );
export default locationsSlice.reducer;
