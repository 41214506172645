import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './FilterWrapper.module.scss';

const FilterWrapper = ({ rowStyle, children }) => {
  return (
    <Container className={styles.container}>
      <Row className={rowStyle ? rowStyle : styles.row}>
        {React.Children.map(children, (child, index) => {
          return (
            <Col bsPrefix={styles.col} key={index}>
              {child}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default FilterWrapper;
