import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Forms from './Forms';
import { FormsView } from './FormsView';
import FormsForm from './FormsForm';
import { Can, entities, services } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { ROUTE_PATHS } from './constants';

export const FormsRouter = () => {
  return (
    <Can everyEntity={[entities.FORM]} everyService={[services.FORM]} onFail={<NoAccessBody />}>
      <Switch>
        <Route path={ROUTE_PATHS.view}>
          <Can everyEntity={[entities.FORM_VIEW]} onFail={<NoAccessBody />}>
            <FormsView />
          </Can>
        </Route>
        <Route path={ROUTE_PATHS.ngAdmin}>
          <Can everyEntity={[entities.FORM_VIEW]} onFail={<NoAccessBody />}>
            <FormsView />
          </Can>
        </Route>
        <Route path={ROUTE_PATHS.add}>
          <Can everyEntity={[entities.FORM_CREATE]} onFail={<NoAccessBody />}>
            <FormsForm action="add" />
          </Can>
        </Route>
        <Route path={`${ROUTE_PATHS.edit}/:id`}>
          <Can everyEntity={[entities.FORM_UPDATE]} onFail={<NoAccessBody />}>
            <FormsForm action="edit" />
          </Can>
        </Route>
        <Route path={`${ROUTE_PATHS.copy}/:id`}>
          <Can everyEntity={[entities.FORM_COPY]} onFail={<NoAccessBody />}>
            <FormsForm action="copy" />
          </Can>
        </Route>
        <Route path={ROUTE_PATHS.main}>
          <Forms />
        </Route>
      </Switch>
    </Can>
  );
};
