import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SubTopNav from 'components/nav/SubTopNav';
import moment from 'moment';
import { useUserPreferences, useUserPreferencesFetched } from 'features/user/userPreferencesSlice';
import {
  fetchMessagesByDateRange,
  setDateRangeSelected,
  useMessageFilterDateRange,
  useChattingEntity,
  useUserMessagingPermissions,
  setChattingEntity,
  useIsUnreadMessagesFetching,
  useChatEntities
} from 'features/messaging/messagingSlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { Button, Space, Row, Col, Tooltip } from 'antd';
import { EllipsisMenu } from 'components/tables/EllipsisMenu';
import { MessageComposeModal } from './MessageComposeModal';
import MessagingChatBox from './MessagingChatBox';
import MessagingList from './MessagingList';
import { DateRangePicker } from 'components/ant/DateTime/DateRangePicker';
import { useLocalization } from 'features/localization/localizationSlice';
import { useUserKey } from 'features/user/userSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import {
  MESSAGE_PERMISSION_ENTITY_TYPE,
  SORT,
  RECIPIENT_TYPE,
  STORE_DATE_FORMAT
} from './constants';
import { useRecipientTree } from 'features/messaging/hooks';
import {
  getMergedChatEntityName,
  buildChatEntityFilterTree,
  findTreeNodeCheckedValue,
  getChatEntityLastChatMessageLastUpdatedDate,
  isMessagingDevice
} from './helpers';
import { useBranches } from 'features/locations/locationsSlice';
import { useDevicesStats } from 'features/devices/devicesStatsSlice';
import { useVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import { BUTTON_IDS } from 'utils/globalConstants';
import dayjs from 'dayjs';

const FilterAllValue = t => [
  { label: t('Common.Branches'), value: 'branches' },
  { label: t('Common.Fleets'), value: 'fleets' }
];

const useMessagingSubNav = (t, dispatch, setShowMessageComposeModal, resetFilter) => {
  const selectedDateRange = useMessageFilterDateRange();
  const dateRangeRef = useRef(selectedDateRange);
  const localization = useLocalization();
  const userMsgPermission = useUserMessagingPermissions();
  const onDateRangeSelected = useCallback(
    ([startDate, endDate]) => {
      dispatch(
        setDateRangeSelected({
          dateRange: {
            from: startDate?.format(STORE_DATE_FORMAT),
            to: endDate?.format(STORE_DATE_FORMAT)
          }
        })
      );
      resetFilter();
    },
    [resetFilter, dispatch]
  );

  const refreshMessages = () => {
    dispatch(fetchMessagesByDateRange());
  };

  const RightComponent = useMemo(() => {
    return () => (
      <Space size={16}>
        <DateRangePicker
          size="large"
          format={localization.formats.time.formats.dby.toUpperCase()}
          maxDayRange={30}
          availableDatesRange={[dayjs().subtract(6, 'month'), dayjs().endOf('day')]}
          defaultDates={[dayjs(dateRangeRef.current?.from), dayjs(dateRangeRef.current?.to)]}
          onDateRangeChanged={onDateRangeSelected}
          {...{
            showYesterday: false,
            showPast7Days: false,
            showPast14Days: false,
            showLastMonth: false,
            showWeekToDate: false,
            otherPresetDates: [
              {
                label: t('Messaging.LastDays', { count: 2 }),
                value: [
                  dayjs()
                    .subtract(1, 'day')
                    .startOf('day'),
                  dayjs().endOf('day')
                ]
              },
              {
                label: t('Messaging.LastDays', { count: 3 }),
                value: [
                  dayjs()
                    .subtract(2, 'day')
                    .startOf('day'),
                  dayjs().endOf('day')
                ]
              },
              {
                label: t('Messaging.LastDays', { count: 7 }),
                value: [
                  dayjs()
                    .subtract(6, 'day')
                    .startOf('day'),
                  dayjs().endOf('day')
                ]
              },
              {
                label: t('Messaging.LastDays', { count: 30 }),
                value: [
                  dayjs()
                    .subtract(29, 'day')
                    .startOf('day'),
                  dayjs().endOf('day')
                ]
              }
            ]
          }}
        />
        <Tooltip
          title={
            !userMsgPermission[MESSAGE_PERMISSION_ENTITY_TYPE.Message].send
              ? t('Messaging.NoPermission')
              : null
          }
        >
          <Button
            type="primary"
            size="large"
            onClick={() => {
              setShowMessageComposeModal(true);
            }}
            disabled={!userMsgPermission[MESSAGE_PERMISSION_ENTITY_TYPE.Message].send}
            id={BUTTON_IDS.messagingNew}
          >
            {`${t('Messaging.Form.New')} ${t('Entity.Message')}`}
          </Button>
        </Tooltip>
        <EllipsisMenu
          size="large"
          placement="bottomRight"
          menuItems={[
            {
              label: `${t('Common.Refresh')}`,
              onClick: refreshMessages,
              id: 'btn_messagingRefresh'
            },
            {
              label: `${t('Entity.Message Template')}`,
              link: `/messaging/templates`,
              disabled: !userMsgPermission[MESSAGE_PERMISSION_ENTITY_TYPE.MessageTemplate].viewList,
              id: 'btn_messagingTemplate'
            }
          ]}
        />
      </Space>
    );
  }, [localization, userMsgPermission, dateRangeRef]);

  return {
    RightComponent,
    selectedDateRange
  };
};

export const Messaging = () => {
  const messagingStyle = {
    container: { display: 'flex', flexDirection: 'column', height: '100%' },
    columns: {
      flex: 1,
      padding: '20px',
      margin: '20px',
      backgroundColor: '#f3f3f3',
      borderRadius: '8px',
      maxHeight: 'calc(100% - 100px)'
    },
    listColumn: { maxHeight: '100%', paddingRight: '12px' },
    boxColumn: { maxHeight: '100%', paddingLeft: '12px' }
  };

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const recipientTree = useRecipientTree();
  const userPreferences = useUserPreferences();
  const userPreferencesFetched = useUserPreferencesFetched();

  const [showMessageComposeModal, setShowMessageComposeModal] = useState(false);
  const chattingEntity = useChattingEntity();
  const currentCompany = useCurrentCompany();
  const userKey = useUserKey();

  const {
    chatEntities: allChatEntities,
    isFetching: isFetchingAllChatEntities,
    fleets,
    drivers,
    messagesIsFetching
  } = useChatEntities();

  const devicesStats = useDevicesStats();
  const listOfDriverVehicleWithMessagingDevices = useMemo(() => {
    if (!devicesStats || !fleets || !drivers) {
      return {};
    }
    const interactiveDevices = devicesStats.filter(deviceStat => deviceStat.currentUser);
    const vehicles = fleets.map(fleet => fleet.vehicles).reduce((a, c) => a.concat(c), []);
    return {
      drivers: drivers.map(driver => {
        return {
          ...driver,
          interactiveDevices: interactiveDevices.filter(
            device => device.currentUser?.id === driver.id
          )
        };
      }),
      vehicles: vehicles.map(vehicle => {
        return {
          ...vehicle,
          interactiveDevices: (vehicle.devices || []).filter(isMessagingDevice)
        };
      })
    };
  }, [fleets, drivers, devicesStats]);

  const unreadMessagesIsFetching = useIsUnreadMessagesFetching();
  const messageIsRefreshing = useMemo(() => {
    return messagesIsFetching || unreadMessagesIsFetching;
  }, [messagesIsFetching, unreadMessagesIsFetching]);

  const branches = useBranches();

  const [treeDataSelectValue, setTreeSelectValue] = useState(FilterAllValue(t));

  const [chatEntityFilter, setChatEntityFilter] = useState(null);
  const [chatEntitySort, setChatEntitySort] = useState(SORT.DESC);

  const treeData = useMemo(() => {
    const tree = buildChatEntityFilterTree(
      t,
      getFilteredChatEntities(allChatEntities, null, SORT.DESC),
      branches || [],
      drivers || [],
      fleets || []
    );
    return !tree ? null : tree.every(node => !!node.disableCheckbox) ? [] : tree;
  }, [t, allChatEntities, branches, drivers, fleets]);

  const filteredChatEntities = useMemo(() => {
    return getFilteredChatEntities(allChatEntities, chatEntityFilter, chatEntitySort) || [];
  }, [allChatEntities, chatEntityFilter, chatEntitySort]);

  const handleRecipientFilter = useCallback(
    recipients => {
      setTreeSelectValue(recipients);
      setChatEntityFilter(getRecipientFilter(treeData, recipients));
    },
    [treeData]
  );

  const handleRecipientSort = useCallback(() => {
    const sort = chatEntitySort === SORT.DESC ? SORT.ASC : SORT.DESC;
    setChatEntitySort(sort);
  }, [chatEntitySort]);

  const resetFilter = useCallback(() => {
    setTreeSelectValue(FilterAllValue(t));
    setChatEntityFilter(null);
  }, [t]);

  const { RightComponent, selectedDateRange } = useMessagingSubNav(
    t,
    dispatch,
    setShowMessageComposeModal,
    resetFilter
  );

  const getChatEntityName = useCallback(
    (chatEntity, showRecipientCount) =>
      getMergedChatEntityName(chatEntity, recipientTree, showRecipientCount),
    [recipientTree]
  );

  const vehiclesStats = useVehiclesStats();
  const getChatEntityMeta = useCallback(
    chatEntity => {
      let currentDriver = null,
        currentDriverName = null;
      if (chatEntity?.chatEntityType === RECIPIENT_TYPE.DEVICE) {
        currentDriver = devicesStats?.find(
          vStats => parseInt(vStats.deviceId, 10) === parseInt(chatEntity?.chatEntityId, 10)
        )?.currentUser;
        currentDriverName = currentDriver?.id
          ? `${currentDriver?.firstName} ${currentDriver?.lastName}`
          : t('Tracking.NotLoggedIn');
      } else if (chatEntity?.chatEntityType === RECIPIENT_TYPE.VEHICLE) {
        currentDriver = vehiclesStats?.find(
          vStats => parseInt(vStats.vehicleId, 10) === parseInt(chatEntity?.chatEntityId, 10)
        )?.currentUser;
        currentDriverName = currentDriver?.id
          ? `${currentDriver?.firstName} ${currentDriver?.lastName}`
          : t('Tracking.NotLoggedIn');
      }
      return {
        currentDriver,
        currentDriverName
      };
    },
    [t, vehiclesStats, devicesStats]
  );

  useEffect(() => {
    dispatch(setChattingEntity(null));
  }, [currentCompany, dispatch]);

  useEffect(() => {
    dispatch(setPageTitle(`${t('Messaging.Messages')}`));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    if (userPreferencesFetched) {
      dispatch(fetchMessagesByDateRange());
    }
  }, [
    selectedDateRange,
    currentCompany,
    userKey,
    userPreferences,
    dispatch,
    userPreferencesFetched
  ]);

  return (
    <>
      {showMessageComposeModal && (
        <MessageComposeModal
          visible={showMessageComposeModal}
          showModal={setShowMessageComposeModal}
        />
      )}
      <Col style={messagingStyle.container}>
        <SubTopNav right={RightComponent} />
        <Row style={messagingStyle.columns}>
          <Col span={chattingEntity ? 8 : 24} style={messagingStyle.listColumn}>
            <MessagingList
              {...{
                getChatEntityMeta,
                getChatEntityName,
                treeData,
                filteredChatEntities,
                isFetchingfilteredChatEntities: isFetchingAllChatEntities,
                treeDataSelectValue,
                chatEntitySort,
                handleRecipientFilter,
                handleRecipientSort,
                messageIsRefreshing
              }}
            />
          </Col>
          {chattingEntity && (
            <Col span={16} style={messagingStyle.boxColumn}>
              <MessagingChatBox
                getChatEntityName={getChatEntityName}
                listOfDriverVehicleWithMessagingDevices={listOfDriverVehicleWithMessagingDevices}
              />
            </Col>
          )}
        </Row>
      </Col>
    </>
  );
};

export default Messaging;

const getFilteredChatEntities = (chatEntities, filter, sort) => {
  let ret = [];
  if (filter) {
    const { filterDriverIds, filterVehicleIds, filterDeviceIds } = filter;
    ret = [
      ...(filterDriverIds.length
        ? chatEntities.filter(
            entity =>
              entity.chatEntityType === RECIPIENT_TYPE.DRIVER &&
              filterDriverIds.some(driverId => driverId === entity.chatEntityId)
          )
        : []),
      ...(filterVehicleIds.length
        ? chatEntities.filter(
            entity =>
              entity.chatEntityType === RECIPIENT_TYPE.VEHICLE &&
              filterVehicleIds.some(driverId => driverId === entity.chatEntityId)
          )
        : []),
      ...(filterDeviceIds.length
        ? chatEntities.filter(
            entity =>
              entity.chatEntityType === RECIPIENT_TYPE.DEVICE &&
              filterDeviceIds.some(driverId => driverId === entity.chatEntityId)
          )
        : []),
      ...chatEntities
        .filter(chatEntity => chatEntity.chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE)
        .filter(groupMessageEntity => {
          const driverEntities = groupMessageEntity.singleChatEntities.filter(
              singleChatEntity => singleChatEntity.chatEntityType === RECIPIENT_TYPE.DRIVER
            ),
            vehicleEntities = groupMessageEntity.singleChatEntities.filter(
              singleChatEntity => singleChatEntity.chatEntityType === RECIPIENT_TYPE.VEHICLE
            ),
            deviceEntities = groupMessageEntity.singleChatEntities.filter(
              singleChatEntity => singleChatEntity.chatEntityType === RECIPIENT_TYPE.DEVICE
            );
          const entityMatched = (entity, filterEntityIds) =>
            filterEntityIds.some(filterId => filterId === entity.chatEntityId);
          return (
            driverEntities.some(entity => entityMatched(entity, filterDriverIds)) ||
            vehicleEntities.some(entity => entityMatched(entity, filterVehicleIds)) ||
            deviceEntities.some(entity => entityMatched(entity, filterDeviceIds))
          );
        })
    ];
  } else {
    ret = chatEntities;
  }
  return sortedFilteredChatEntities(ret, sort);
};

const sortedFilteredChatEntities = (chatEntities, sort) => {
  if (!chatEntities.length) {
    return [];
  }
  const sortFunc =
    sort === SORT.DESC
      ? (a, b) => {
          const lastUpdatedA = getChatEntityLastChatMessageLastUpdatedDate(a)?.unix || 0;
          const lastUpdatedB = getChatEntityLastChatMessageLastUpdatedDate(b)?.unix || 0;
          return lastUpdatedB - lastUpdatedA;
        }
      : (a, b) => {
          const lastUpdatedA = getChatEntityLastChatMessageLastUpdatedDate(a)?.unix || 0;
          const lastUpdatedB = getChatEntityLastChatMessageLastUpdatedDate(b)?.unix || 0;
          return lastUpdatedA - lastUpdatedB;
        };
  return chatEntities.sort(sortFunc);
};

const getRecipientFilter = (treeData, selectedRecipients) => {
  if (!treeData || !treeData.length) {
    return null;
  }
  //construct vehicle/devices recipients
  const branchTree = treeData[0],
    fleetTree = treeData[1];
  const selectedRecipientsCheckedValue = [
    ...selectedRecipients.map(nodeValue => findTreeNodeCheckedValue(nodeValue.value, fleetTree)),
    ...selectedRecipients.map(nodeValue => findTreeNodeCheckedValue(nodeValue.value, branchTree))
  ]
    .filter(recipient => !!recipient && recipient.length > 0)
    .reduce((a, c) => a.concat(c), []);

  const filterDriverIds = [],
    filterDeviceIds = [],
    filterVehicleIds = [];
  selectedRecipientsCheckedValue.forEach(recipient => {
    if (recipient.receiver && recipient.receiver.id) {
      if (filterDriverIds.every(id => id !== recipient.receiver.id)) {
        return filterDriverIds.push(recipient.receiver.id);
      }
    }
    if (recipient.vehicle && recipient.vehicle.id) {
      if (filterVehicleIds.every(id => id !== recipient.vehicle.id)) {
        return filterVehicleIds.push(recipient.vehicle.id);
      }
    }
    if (recipient.device && recipient.device.id) {
      if (filterDeviceIds.every(id => id !== recipient.device.id)) {
        return filterDeviceIds.push(recipient.device.id);
      }
    }
  });
  return {
    filterDriverIds,
    filterVehicleIds,
    filterDeviceIds
  };
};
