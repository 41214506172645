import moment from 'moment';

export const ColumnTypes = {
  None: 'none',
  Date: 'date',
  Ignition: 'ignition',
  Number: 'number',
  String: 'string',
  OperationType: 'operationtype',
  OperationSubType: 'operationsubtype',
  OperationSubTypeStatus: 'operationsubtypestatus',
  OperationStatus: 'Operationstatus'
};

export const StringConditions = {
  Is: 'Is',
  IsNot: 'IsNot',
  Contains: 'Contains',
  DoesNotContain: 'DoesNotContain',
  StartsWith: 'StartsWith',
  EndsWith: 'EndsWith',
  IsEmpty: 'IsEmpty',
  IsNotEmpty: 'IsNotEmpty'
};

export const NumberConditions = {
  IsEqualTo: 'IsEqualTo',
  IsNotEqualTo: 'IsNotEqualTo',
  GreaterThan: 'GreaterThan',
  LessThan: 'LessThan',
  GreaterThanOrEqualTo: 'GreaterThanOrEqualTo',
  LessThanOrEqualTo: 'LessThanOrEqualTo',
  IsEmpty: 'IsEmpty',
  IsNotEmpty: 'IsNotEmpty'
};

export const DateConditions = {
  Is: 'Is',
  IsBefore: 'IsBefore',
  IsAfter: 'IsAfter',
  IsOnOrBefore: 'IsOnOrBefore',
  IsOnOrAfter: 'IsOnOrAfter',
  IsWithin: 'IsWithin',
  IsEmpty: 'IsEmpty',
  IsNotEmpty: 'IsNotEmpty'
};

export const IgnitionConditions = {
  Is: 'Is',
  IsNot: 'IsNot'
};

export const IgnitionValues = {
  IgnitionOn: 'IgnitionOn',
  IgnitionOff: 'IgnitionOff',
  OutOfCoverage: 'OutOfCoverage',
  BatteryPowered: 'BatteryPowered'
};

export const EmptyConditions = [
  StringConditions.IsEmpty,
  StringConditions.IsNotEmpty,
  NumberConditions.IsEmpty,
  NumberConditions.IsNotEmpty,
  DateConditions.IsEmpty,
  DateConditions.IsNotEmpty
];

export const getStringConditions = t => {
  return [
    {
      id: StringConditions.Is,
      label: t(`Tracking.FilterColumns.${StringConditions.Is}`)
    },
    {
      id: StringConditions.IsNot,
      label: t(`Tracking.FilterColumns.${StringConditions.IsNot}`)
    },
    {
      id: StringConditions.Contains,
      label: t(`Tracking.FilterColumns.${StringConditions.Contains}`)
    },
    {
      id: StringConditions.DoesNotContain,
      label: t(`Tracking.FilterColumns.${StringConditions.DoesNotContain}`)
    },
    {
      id: StringConditions.StartsWith,
      label: t(`Tracking.FilterColumns.${StringConditions.StartsWith}`)
    },
    {
      id: StringConditions.EndsWith,
      label: t(`Tracking.FilterColumns.${StringConditions.EndsWith}`)
    },
    {
      id: StringConditions.IsEmpty,
      label: t(`Tracking.FilterColumns.${StringConditions.IsEmpty}`)
    },
    {
      id: StringConditions.IsNotEmpty,
      label: t(`Tracking.FilterColumns.${StringConditions.IsNotEmpty}`)
    }
  ];
};

export const getNumberConditions = t => {
  return [
    {
      id: NumberConditions.IsEqualTo,
      label: t(`Tracking.FilterColumns.${NumberConditions.IsEqualTo}`)
    },
    {
      id: NumberConditions.IsNotEqualTo,
      label: t(`Tracking.FilterColumns.${NumberConditions.IsNotEqualTo}`)
    },
    {
      id: NumberConditions.GreaterThan,
      label: t(`Tracking.FilterColumns.${NumberConditions.GreaterThan}`)
    },
    {
      id: NumberConditions.LessThan,
      label: t(`Tracking.FilterColumns.${NumberConditions.LessThan}`)
    },
    {
      id: NumberConditions.GreaterThanOrEqualTo,
      label: t(`Tracking.FilterColumns.${NumberConditions.GreaterThanOrEqualTo}`)
    },
    {
      id: NumberConditions.LessThanOrEqualTo,
      label: t(`Tracking.FilterColumns.${NumberConditions.LessThanOrEqualTo}`)
    },
    {
      id: NumberConditions.IsEmpty,
      label: t(`Tracking.FilterColumns.${NumberConditions.IsEmpty}`)
    },
    {
      id: NumberConditions.IsNotEmpty,
      label: t(`Tracking.FilterColumns.${NumberConditions.IsNotEmpty}`)
    }
  ];
};

export const getDateConditions = t => {
  return [
    {
      id: DateConditions.Is,
      label: t(`Tracking.FilterColumns.${DateConditions.Is}`)
    },
    {
      id: DateConditions.IsBefore,
      label: t(`Tracking.FilterColumns.${DateConditions.IsBefore}`)
    },
    {
      id: DateConditions.IsAfter,
      label: t(`Tracking.FilterColumns.${DateConditions.IsAfter}`)
    },
    {
      id: DateConditions.IsOnOrBefore,
      label: t(`Tracking.FilterColumns.${DateConditions.IsOnOrBefore}`)
    },
    {
      id: DateConditions.IsOnOrAfter,
      label: t(`Tracking.FilterColumns.${DateConditions.IsOnOrAfter}`)
    },
    {
      id: DateConditions.IsWithin,
      label: t(`Tracking.FilterColumns.${DateConditions.IsWithin}`)
    },
    {
      id: DateConditions.IsEmpty,
      label: t(`Tracking.FilterColumns.${DateConditions.IsEmpty}`)
    },
    {
      id: DateConditions.IsNotEmpty,
      label: t(`Tracking.FilterColumns.${DateConditions.IsNotEmpty}`)
    }
  ];
};

export const getIgnitionConditions = t => {
  return [
    {
      id: IgnitionConditions.Is,
      label: t(`Tracking.FilterColumns.${IgnitionConditions.Is}`)
    },
    {
      id: IgnitionConditions.IsNot,
      label: t(`Tracking.FilterColumns.${IgnitionConditions.IsNot}`)
    }
  ];
};

export const getIgnitionValues = t => {
  return [
    {
      id: IgnitionValues.IgnitionOn,
      label: t(`Tracking.DeviceStatus.${IgnitionValues.IgnitionOn}`)
    },
    {
      id: IgnitionValues.IgnitionOff,
      label: t(`Tracking.DeviceStatus.${IgnitionValues.IgnitionOff}`)
    },
    {
      id: IgnitionValues.OutOfCoverage,
      label: t(`Tracking.DeviceStatus.${IgnitionValues.OutOfCoverage}`)
    },
    {
      id: IgnitionValues.BatteryPowered,
      label: t(`Tracking.DeviceStatus.${IgnitionValues.BatteryPowered}`)
    }
  ];
};

export const getConditionsForType = (type, t) => {
  switch (type) {
    case ColumnTypes.Date:
      return getDateConditions(t);
    case ColumnTypes.Ignition:
      return getIgnitionConditions(t);
    case ColumnTypes.Number:
      return getNumberConditions(t);
    case ColumnTypes.String:
    default:
      return getStringConditions(t);
  }
};

export const getDateValue = filter => {
  let dateValue = null;
  let dateValueTo = null;

  if (filter.type === ColumnTypes.Date && filter.value !== null) {
    if (filter.condition === DateConditions.IsWithin) {
      const dateParts = filter.value.split('|');
      if (dateParts.length > 1) {
        dateValue = moment(dateParts[0]);
        dateValueTo = moment(dateParts[1]);
      } else {
        dateValue = moment(filter.value);
      }
    } else {
      dateValue = moment(filter.value);
    }
  }

  return {
    dateValue,
    dateValueTo
  };
};

export const getDefaultConditionAndValueForType = (type, t) => {
  switch (type) {
    case ColumnTypes.Date:
      return {
        condition: getDateConditions(t)[0].id,
        value: moment().format()
      };
    case ColumnTypes.Ignition:
      return {
        condition: getIgnitionConditions(t)[0].id,
        value: getIgnitionValues(t)[0].id
      };
    case ColumnTypes.Number:
      return {
        condition: getNumberConditions(t)[0].id,
        value: null
      };
    case ColumnTypes.String:
    default:
      return {
        condition: getStringConditions(t)[0].id,
        value: null
      };
  }
};

export const applyViewsGridFilters = (filters, data, columns, getRowValue) => {
  let filteredData =
    data &&
    data?.filter(row => {
      const filterResult = filterRow(row, filters, columns, getRowValue);
      return filterResult;
    });
  return filteredData;
};

const filterRow = (row, filters, columns, getRowValue) => {
  let result = true;

  filters.forEach(filter => {
    const foundColumn = columns.find(column => column.key === filter.columnKey);
    if (foundColumn) {
      let rowValue = null;
      if (foundColumn.getValue) {
        // If custom function to get value is present, use that
        if (filter.type === ColumnTypes.Ignition) {
          const value = getRowValue(row, foundColumn.dataIndex);
          rowValue = foundColumn.getValue(value, row);
        } else rowValue = foundColumn.getValue(row);
      } else {
        // Else use the generic lookup based on dataIndex
        rowValue = getRowValue(row, foundColumn.dataIndex);
      }

      switch (filter.type) {
        case ColumnTypes.Date:
          result &= checkDateFilter(rowValue, filter);
          break;
        case ColumnTypes.Ignition:
          result &= checkIgnitionFilter(rowValue, filter);
          break;
        case ColumnTypes.Number:
          result &= checkNumberFilter(rowValue, filter, foundColumn.numDecimals);
          break;
        case ColumnTypes.String:
          result &= checkStringFilter(rowValue, filter);
          break;
        case ColumnTypes.OperationType:
          result &= checkOperationTypeFilter(rowValue, filter);
          break;
        case ColumnTypes.OperationSubType:
          result &= checkOperationSubTypeFilter(rowValue, filter);
          break;
        case ColumnTypes.OperationSubTypeStatus:
          result &= checkOperationSubTypeStatusFilter(rowValue, filter);
          break;
        case ColumnTypes.OperationStatus:
          result &= checkOperationStatusFilter(rowValue, filter);
          break;
        default:
          break;
      }
    }
  });

  return result;
};

const checkDateFilter = (value, filter) => {
  // convert to moment dates before comparing
  const dateValue = moment(value);

  let filterDateValue = filter.condition !== DateConditions.IsWithin ? moment(filter.value) : null;

  switch (filter.condition) {
    case DateConditions.Is:
      return dateValue.isSame(filterDateValue);
    case DateConditions.IsBefore:
      return dateValue.isBefore(filterDateValue);
    case DateConditions.IsAfter:
      return dateValue.isAfter(filterDateValue);
    case DateConditions.IsOnOrBefore:
      return dateValue.isSameOrBefore(filterDateValue);
    case DateConditions.IsOnOrAfter:
      return dateValue.isSameOrAfter(filterDateValue);
    case DateConditions.IsWithin:
      if (filter.value) {
        const dateParts = filter.value.split('|');
        if (dateParts.length > 1) {
          filterDateValue = moment(dateParts[0]);
          const filterDateValueTo = moment(dateParts[1]);
          return dateValue.isBetween(filterDateValue, filterDateValueTo);
        }
      }
      return true;
    case DateConditions.IsEmpty:
      return value === undefined || value === null;
    case DateConditions.IsNotEmpty:
      return value !== undefined && value !== null;
    default:
      return true;
  }
};

const checkIgnitionFilter = (value, filter) => {
  const rowValue = value?.value;
  const filterValue = filter.value;

  switch (filter.condition) {
    case IgnitionConditions.Is:
      return rowValue === filterValue;
    case IgnitionConditions.IsNot:
      return rowValue !== filterValue;
    default:
      return true;
  }
};

const checkNumberFilter = (value, filter, numDecimals = null) => {
  let numberValue = Number(value);
  let filterValue = Number(filter.value);

  // If number of decimals is specified then round before comparing
  if (numDecimals) {
    numberValue = parseFloat(numberValue.toFixed(numDecimals));
    filterValue = parseFloat(filterValue.toFixed(numDecimals));
  }

  switch (filter.condition) {
    case NumberConditions.IsEqualTo:
      return numberValue === filterValue;
    case NumberConditions.IsNotEqualTo:
      return numberValue !== filterValue;
    case NumberConditions.GreaterThan:
      return numberValue > filterValue;
    case NumberConditions.LessThan:
      return numberValue < filterValue;
    case NumberConditions.GreaterThanOrEqualTo:
      return numberValue >= filterValue;
    case NumberConditions.LessThanOrEqualTo:
      return numberValue <= filterValue;
    case NumberConditions.IsEmpty:
      return value === undefined || value === null;
    case NumberConditions.IsNotEmpty:
      return value !== undefined && value !== null;
    default:
      return true;
  }
};

const checkStringFilter = (value, filter) => {
  const valueLowerCase = value?.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
  const filterValue = filter?.value?.includes(',')
    ? filter?.value
        ?.toLowerCase()
        .replace(/[^a-zA-Z0-9,]/g, '')
        .split(',')
    : filter?.value?.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');

  switch (filter.condition) {
    case StringConditions.Is:
      return filterValue?.includes(valueLowerCase);
    case StringConditions.IsNot:
      return !filterValue?.includes(valueLowerCase);
    case StringConditions.Contains:
      return Array.isArray(filterValue)
        ? filterValue?.some(word => valueLowerCase?.includes(word)) ?? false
        : valueLowerCase?.includes(filterValue) ?? false;
    case StringConditions.DoesNotContain:
      return Array.isArray(filterValue)
        ? !filterValue?.some(word => valueLowerCase?.includes(word)) ?? false
        : !valueLowerCase?.includes(filterValue) ?? false;
    case StringConditions.StartsWith:
      return valueLowerCase?.startsWith(filterValue) ?? false;
    case StringConditions.EndsWith:
      return valueLowerCase?.endsWith(filterValue) ?? false;
    case StringConditions.IsEmpty:
      return !value || value === '';
    case StringConditions.IsNotEmpty:
      return value && value !== '';
    default:
      return true;
  }
};

const checkOperationTypeFilter = (value, filter) => {
  const rowValue = value?.toUpperCase();
  const filterValue = filter.value?.toUpperCase();

  switch (filter.condition) {
    case IgnitionConditions.Is:
      return rowValue === filterValue;
    case IgnitionConditions.IsNot:
      return rowValue !== filterValue;
    default:
      return true;
  }
};

const checkOperationSubTypeFilter = (value, filter) => {
  const rowValue = value?.toUpperCase();
  const filterValue = filter.value?.toUpperCase();

  switch (filter.condition) {
    case IgnitionConditions.Is:
      return rowValue === filterValue;
    case IgnitionConditions.IsNot:
      return rowValue !== filterValue;
    default:
      return true;
  }
};

const checkOperationSubTypeStatusFilter = (value, filter) => {
  const rowValue = value?.toUpperCase();
  const filterValue = filter.value?.toUpperCase();

  switch (filter.condition) {
    case IgnitionConditions.Is:
      return rowValue === filterValue;
    case IgnitionConditions.IsNot:
      return rowValue !== filterValue;
    default:
      return true;
  }
};

const checkOperationStatusFilter = (value, filter) => {
  const rowValue = value?.toUpperCase();
  const filterValue = filter.value?.toUpperCase();

  switch (filter.condition) {
    case IgnitionConditions.Is:
      return rowValue === filterValue;
    case IgnitionConditions.IsNot:
      return rowValue !== filterValue;
    default:
      return true;
  }
};
