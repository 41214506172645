import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as moment from 'moment';
import { sortBy } from 'lodash';

import { prepareDataForMultiselect } from 'utils/filters';
import useDebounce from 'utils/hooks/useDebounce';
import { SortInitValue } from '../helpers';

export const useSnapshotsFilter = ({
  allFleets = [],
  allVehicles = [],
  allCompanies = [],
  companyId,
  vehiclesAndDevicesIds = {}
}) => {
  const { t } = useTranslation();

  const [rowOffset, setRowOffset] = useState(0);
  const [period, setPeriod] = useState({
    from: moment()
      .startOf('month')
      .startOf('day'),
    to: moment()
      .endOf('month')
      .endOf('day')
  });

  const { selectedYear, selectedMonth, from, to } = useMemo(
    () => ({
      selectedYear: period.from.get('year'),
      selectedMonth: period.from.get('month') + 1,
      from: period.from.format(),
      to: period.to.format()
    }),
    [period]
  );

  const [filterEntities, setFilterEntities] = useState({
    companies: [],
    fleets: [],
    vehicles: []
  });
  const [searchText, setSearchText] = useState(null);
  const filterText = useDebounce(searchText, 300);

  const { filterCompanies, filterFleets, filterVehicles, filteredDeviceIds } = useMemo(() => {
    const allCompanyOptions = prepareDataForMultiselect(
      allCompanies,
      t('Common.AllCompanies'),
      null
    );
    const allFleetOptions = prepareDataForMultiselect(
      sortBy(allFleets, 'name'),
      t('Common.AllFleets'),
      null
    );
    const allVehicleOptions = prepareDataForMultiselect(
      sortBy(allVehicles, 'name'),
      t('Common.AllVehicles'),
      null
    );

    const { companies, fleets, vehicles } = filterEntities;
    const filterCompanies = companies.length === 0 ? allCompanyOptions : companies;
    const filterFleets = fleets.length === 0 ? allFleetOptions : fleets;
    const filterVehicles = vehicles.length === 0 ? allVehicleOptions : vehicles;

    const deviceIds = new Set();
    for (const v of filterVehicles) {
      if (!v.checked || !v?.id) {
        continue;
      }
      const id = String(v.id);
      if (id.startsWith('vehicle_')) {
        for (const deviceId of Object.keys(vehiclesAndDevicesIds[id] || {})) {
          deviceIds.add(deviceId);
        }
      } else if (id.startsWith('device_')) {
        deviceIds.add(id.replace('device_', ''));
      }
    }
    return {
      filterFleets,
      filterCompanies,
      filterVehicles,
      filteredDeviceIds: Array.from(deviceIds)
    };
  }, [t, allFleets, allCompanies, allVehicles, filterEntities, vehiclesAndDevicesIds]);

  useEffect(() => {
    setFilterEntities({
      companies: [],
      fleets: [],
      vehicles: []
    });
    setRowOffset(0);
  }, [companyId]);

  const [sortOption, setSortOption] = useState(SortInitValue);

  const { orderField, orderDirection } = useMemo(() => {
    const { item: sortBy, order: orderBy } = sortOption;
    return {
      orderField: sortBy === 'date' ? 'EventTime' : 'Vehicle',
      orderDirection: orderBy
    };
  }, [sortOption]);

  const handleFilterCompanies = useCallback(
    pCompanyOps => {
      const noFleet = allFleets.find(f => f.id === -1);
      let fleets = [];
      if (noFleet) {
        const noFleetCompanies = Object.assign({}, noFleet.companyId);
        pCompanyOps.forEach(c => {
          if (!c.checked) delete noFleetCompanies[c.id];
          let companyFleets = allFleets.filter(f => c.checked && f.companyId === c.id);
          if (companyFleets.length > 0) {
            fleets = fleets.concat(companyFleets);
          }

          if (noFleetCompanies[c.id] && !fleets.find(f => f.id === -1)) {
            let fleet = Object.assign({}, noFleet);
            fleet.companyId = noFleetCompanies;
            fleets.push(fleet);
          }
        });
      }
      setFilterEntities(entities => {
        const fleetsOptions = prepareDataForMultiselect(
          sortBy(fleets, 'name'),
          t('Common.AllFleets'),
          null
        );
        return {
          ...entities,
          companies: pCompanyOps,
          fleets: fleetsOptions,
          vehicles: prepareDataForMultiselect(
            sortBy(
              allVehicles.filter(
                v =>
                  fleetsOptions.some(i => v.fleetIds[i.id] && i.checked) &&
                  pCompanyOps.find(c => c.id === v.companyId)?.checked
              ),
              'name'
            ),
            t('Common.AllVehicles'),
            null
          )
        };
      });
    },
    [allFleets, allVehicles, t]
  );

  const handleFilterFleets = useCallback(
    fleets => {
      const vehicles = allVehicles.filter(
        v =>
          fleets.some(i => v.fleetIds[i.id] && i.checked) &&
          filterCompanies?.find(c => c.id === v.companyId)?.checked
      );
      setFilterEntities(entities => {
        const vehiclesOptions = prepareDataForMultiselect(
          sortBy(vehicles, 'name'),
          t('Common.AllVehicles'),
          null
        );
        return {
          ...entities,
          fleets,
          vehicles: vehiclesOptions
        };
      });
    },
    [allVehicles, filterCompanies, t]
  );

  const handleFilterVehicles = useCallback(vehicles => {
    setFilterEntities(entities => {
      return {
        ...entities,
        vehicles
      };
    });
  }, []);

  const handleSearch = useCallback(e => {
    setSearchText(e);
    setRowOffset(0);
  }, []);

  const handleMonthSelected = useCallback(e => {
    setPeriod({
      from: moment(e).startOf('month'),
      to: moment(e).endOf('month')
    });
    setRowOffset(0);
  }, []);

  return {
    sortOption,
    filterText,
    filterCompanies,
    onFilterCompanies: handleFilterCompanies,
    filterFleets,
    onFilterFleets: handleFilterFleets,
    filterVehicles,
    filteredDeviceIds,
    onFilterVehicles: handleFilterVehicles,
    onSearch: handleSearch,
    onMonthSelected: handleMonthSelected,
    onSortChange: setSortOption,
    selectedYear,
    selectedMonth,
    period,
    from,
    to,
    orderField,
    orderDirection,
    rowOffset,
    setRowOffset
  };
};
