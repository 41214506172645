import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';

//components
import { Input, Select, DatePicker } from 'components/ant';
import { Col, Row, Button, Form } from 'antd';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { FormLabel } from 'react-bootstrap';
import { DocumentsDisplay } from 'components/Documents';
import { FileUpload } from 'components/FileUpload';
import { LoadingCentered } from 'components/loading/Loading';

//slices
import { useLocalization } from 'features/localization/localizationSlice';
import { useUser } from 'features/user/userSlice';
import { openToast } from 'features/toasts/toastsSlice';
import {
  completeSchedule,
  useScheduleById,
  fetchScheduleById,
  useIsScheduleByIdFetching
} from 'features/driverManagement';
import { attachFiles } from 'features/vehicleMaintenance/schedulesSlice';

//helpers
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { getIDFromPathname, prepareDataForSelect } from 'utils/methods';
import { parseErrorMessage } from 'utils/strings';

//constants
import { ToastType } from 'components/notifications/toasts/Toast';
import {
  prepareScheduleData,
  getRaisedBy,
  renderUploadedFiles,
  handleFileAttachment
} from './helpers';
import { Paths, LabelAlignLeft, SizeLarge } from './constants';

//styles
import styles from './DriverManagement.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { getConfig, useConfig } from 'features/easydocs/documentsSlice';
import { useCurrentCompany } from 'features/company/companySlice';

export const DriverManagementComplete = ({
  users = [],
  types = [],
  branches = [],
  drivers = []
}) => {
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const dispatch = useDispatch();
  const history = useHistory();
  const localization = useLocalization();
  const user = useUser();
  const currentCompany = useCurrentCompany();
  const isFetching = useIsScheduleByIdFetching();
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(false);
  const [files, saveFiles] = useState([]);
  const uploadConfig = useConfig();
  const branchesForSelect = prepareDataForSelect([
    { id: 0, name: `${t('Common.AllBranches')}` },
    ...branches
  ]);
  const typesForSelect = prepareDataForSelect(types);
  const driversForSelect = prepareDataForSelect(drivers);
  const schedule = useScheduleById();
  const scheduleData =
    !isFetching &&
    schedule &&
    schedule.id === id &&
    prepareScheduleData(schedule, drivers, branches);
  const completionParameters = scheduleData?.completionParameters
    ? JSON.parse(scheduleData?.completionParameters)
    : null;
  const [form] = Form.useForm();
  const dateFormat = localization.formats.time.formats.dmY;

  useEffect(() => {
    dispatch(setPageTitle(t('DriverManagement.Form.CompleteTitle')));
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchScheduleById({ id, query: { embed: 'events' } }));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getConfig(currentCompany?.id));
  }, [currentCompany]);

  // get the documents for edit completed info if any
  useEffect(() => {
    if (!scheduleData || !completionParameters || !!files?.length) {
      return;
    }
    if (completionParameters.documents) {
      const documentsForScheduleView = completionParameters.documents.map(document => ({
        ...document,
        name: document.filename,
        size: document.filesize,
        isSaved: true
      }));
      saveFiles([...files, ...documentsForScheduleView]);
    }
  }, [scheduleData]);

  const updateFilesCb = payload => {
    if (payload.length === 0) {
      //this mean it is remove, then we need to fetch again to get latest schedule else the
      //current schedule object will still contain file data
      dispatch(fetchScheduleById({ id, query: { embed: 'events' } }));
    }
    saveFiles(payload);
  };

  const handleSave = async () => {
    const values = form.getFieldsValue();
    const parameters = {
      completed_on: moment().format('DD-MM-YYYY'),
      cost: values.cost,
      invoice_number: values.invoice_number,
      signed_off_by: values.signed_off_by,
      completed_by: values.completed_by,
      notes: values.notes
    };
    const payload = {
      completionParameters: JSON.stringify(parameters)
    };

    try {
      const filesToAttach = files.filter(file => !file.isSaved);
      await dispatch(attachFiles(filesToAttach, scheduleData.id));

      const response = await dispatch(completeSchedule({ id: scheduleData?.id, body: payload }));
      unwrapResult(response);

      dispatch(
        openToast({
          type: ToastType.Success,
          message: `${t(`DriverManagement.successCompleteToast`, {
            entity: t('DriverManagement.Type'),
            name: scheduleData?.name || values.name
          })}`
        })
      );

      // Go back to the table page
      setPromptModalWhenLeaving(false);
      history.push(Paths.VIEW_DRIVERMANAGEMENTSCHEDULE.concat(`/${scheduleData.id}`));
    } catch (err) {
      console.error(err);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(`DriverManagement.errorCompleteToast`, {
            entity: t('DriverManagement.Type'),
            name: scheduleData?.name || values.name
          })} ${parseErrorMessage(err)}`
        })
      );
    }
  };

  const setDirtyForm = () => {
    setPromptModalWhenLeaving(true);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleAttachment = event => {
    const {
      target: { files: uploadFiles }
    } = event;
    handleFileAttachment({
      uploadFiles,
      files,
      dispatch,
      updateFilesCb,
      maxFileSizeBytes: uploadConfig?.maxFileSizeBytes
    });
  };

  if (isFetching) {
    return <LoadingCentered />;
  }

  return (
    <div className={styles.formContainer}>
      <EditRouteGuard when={promptModalWhenLeaving} navigate={history.push} />
      <Form
        layout="vertical"
        initialValues={{
          ...scheduleData,
          ...completionParameters
        }}
        form={form}
        onValuesChange={setDirtyForm}
      >
        <div className={styles.formCard}>
          <Row>
            <Col span={8}>
              <Form.Item
                className={styles.inputLeft}
                name="branchId"
                labelAlign={LabelAlignLeft}
                colon={false}
                label={t(`Common.Branch`)}
              >
                <Select
                  size={SizeLarge}
                  placeholder={t(`DriverManagement.Form.SelectBranch`)}
                  data={branchesForSelect}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className={styles.inputLeft}
                name="entityId"
                labelAlign={LabelAlignLeft}
                colon={false}
                label={t(`Common.Driver`)}
              >
                <Select
                  size={SizeLarge}
                  placeholder={t(`DriverManagement.Form.SelectDriver`)}
                  data={driversForSelect}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className={styles.inputLeft}
                name="manageTypeId"
                labelAlign={LabelAlignLeft}
                colon={false}
                label={t(`Common.Type`)}
                rules={[
                  {
                    required: true,
                    message: t('DriverManagement.Form.TypeRequired')
                  }
                ]}
              >
                <Select
                  size={SizeLarge}
                  placeholder={t(`DriverManagement.Form.SelectType`)}
                  data={typesForSelect}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8}>
              <Form.Item
                className={styles.inputLeft}
                name="schedulesOn"
                labelAlign={LabelAlignLeft}
                label={t(`DriverManagement.Form.Date`)}
                rules={[
                  {
                    required: true,
                    message: t('DriverManagement.Form.DateRequired')
                  }
                ]}
              >
                <DatePicker
                  size={SizeLarge}
                  placeholder={t('Common.SelectDate')}
                  controlClass={styles.datePicker}
                  disabled
                  format={localization.formats.time.formats.dby}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className={styles.inputLeft}
                name="cost"
                labelAlign={LabelAlignLeft}
                label={`${t('DriverManagement.Form.Cost')} (${localization.formats.currency.unit})`}
              >
                <Input size={SizeLarge} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className={styles.inputLeft}
                name="invoice_number"
                labelAlign={LabelAlignLeft}
                label={t('DriverManagement.Form.InvoiceNumber')}
              >
                <Input size={SizeLarge} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item
                className={styles.inputLeft}
                labelAlign={LabelAlignLeft}
                label={t('DriverManagement.Form.RaisedBy')}
              >
                <Input
                  size={SizeLarge}
                  defaultValue={getRaisedBy(users, scheduleData.userId)}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className={styles.inputLeft}
                name="signed_off_by"
                labelAlign={LabelAlignLeft}
                label={t('DriverManagement.Form.SignedOffBy')}
              >
                <Input size={SizeLarge} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className={styles.inputLeft}
                name="completed_by"
                labelAlign={LabelAlignLeft}
                label={t(`DriverManagement.Form.CompletedBy`)}
              >
                <Input size={SizeLarge} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Form.Item
                className={styles.inputLeft}
                name="notes"
                labelAlign={LabelAlignLeft}
                label={t('DriverManagement.Form.Notes')}
                colon={false}
              >
                <Input.TextArea size={SizeLarge} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <FormLabel className={styles.customFormLabel}>
              {t('VehicleMntSchedules.View.Documents')}
            </FormLabel>
          </Row>
          <Row>
            <Col span={16}>
              <div className={styles.documentsDisplay}>
                <DocumentsDisplay
                  rows={renderUploadedFiles({
                    files,
                    currentUser: user,
                    dateFormat,
                    updateFilesCb,
                    dispatch
                  })}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.fileUpload}>
                <FileUpload onAdd={handleAttachment} />
              </div>
            </Col>
          </Row>
        </div>
      </Form>
      <div className={styles.formFooter}>
        <Button
          size={SizeLarge}
          htmlType="submit"
          type="primary"
          onClick={handleSave}
          className={styles.saveButton}
          id={BUTTON_IDS.driverMgtCompleteSave}
        >
          {t(`VehicleMntSchedules.View.Complete`)}
        </Button>
        <Button
          size={SizeLarge}
          id={BUTTON_IDS.driverMgtCompleteCancel}
          type="secondary"
          className={styles.cancelButton}
          onClick={handleCancel}
        >
          {t(`Common.Cancel`)}
        </Button>
      </div>
    </div>
  );
};
