import React, { useMemo, useCallback } from 'react';
import { Row, Col, Form, Select } from 'antd';
import { CountryRegionData } from 'react-country-region-selector';
import { useTranslation } from 'react-i18next';
import { getStateLabelByCountry } from '../helper';
import { useCountryOptions, getCountryRegions } from 'utils/countryState';

const { Option } = Select;

export const CountryRegionSelector = ({ layout, selectedCountry, onCountryChange }) => {
  const { t } = useTranslation();

  const Layout = useMemo(() => (layout === 'col' ? Col : Row), [layout]);

  const CountryOptions = useCountryOptions(false);

  const getRegions = useCallback(
    country => {
      return getCountryRegions({ country, checkLabel: true, addCountryAsRegion: true });
    },
    [CountryRegionData]
  );

  const regionMeta = useMemo(() => {
    return {
      label: getStateLabelByCountry(selectedCountry),
      placeholder: `${t('Common.Select')} ${getStateLabelByCountry(selectedCountry)}`
    };
  }, [selectedCountry, t]);

  return (
    <Layout span={12}>
      <Form.Item name="state" label={regionMeta.label}>
        <Select
          filterOption={(input, option) =>
            option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          getPopupContainer={triggerNode => triggerNode.parentNode}
          placeholder={regionMeta.placeholder}
          showSearch
          size="large"
        >
          {getRegions(selectedCountry).map(region => (
            <Option key={region.value} value={region.value}>
              {region.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="country" label={t('Locations.Form.Country')}>
        <Select
          filterOption={(input, option) =>
            option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          getPopupContainer={triggerNode => triggerNode.parentNode}
          onChange={onCountryChange}
          placeholder={t('Locations.Form.CountryPlaceholder')}
          showSearch
          size="large"
        >
          {CountryOptions.map(CountryOption => (
            <Option key={CountryOption.value} value={CountryOption.value}>
              {CountryOption.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Layout>
  );
};
