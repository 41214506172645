import React, { useEffect, useRef, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import request from 'superagent';
import mqtt from 'mqtt';

import { MqttContext } from 'App';
import { useCountdownTimer } from 'utils/hooks/useCountdownTimer';

import { useLocalization } from 'features/localization/localizationSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useCanOneOfServices, services } from 'features/permissions';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import {
  setUpdatesAutoHeli,
  useAutoHeliUpdates,
  useIsMqttConnected
} from 'features/mqtt/mqttSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';
import { useTrackingData } from 'features/tracking/trackingSlice';

import { ToggleButton } from 'components/tn';
import { ToastType } from 'components/notifications/toasts/Toast';

import { AUTO_HELI_THRESHOLD, DRONE_VIEW_AUTO_END } from 'containers/Tracking/constants';
import { API_PATH, HELICOPTER_URL, HELICOPTER_USER, HELICOPTER_PW } from 'config';

import styles from './DroneViewControl.module.scss';

const cbor = require('cbor');

export const DroneViewControl = ({
  device,
  onDroneViewGpsUpdated,
  onDroneViewToggled,
  onIsDroneViewLoadingChanged
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localization = useLocalization();
  const ref = useRef();
  const userKey = useUserKey();
  const userPreferences = useUserPreferences();
  const trackingData = useTrackingData();
  const isMqttConnected = useIsMqttConnected();
  const autoHeliUpdates = useAutoHeliUpdates();
  const { startCountdown, clearCountdown } = useCountdownTimer(DRONE_VIEW_AUTO_END);
  const { mqttClient: client, setMqttClient } = useContext(MqttContext);
  const canGTT = useCanOneOfServices(services.GTT);
  const currentCompany = useCurrentCompany();

  const [isDroneViewActive, setIsDroneViewActive] = useState(false);
  const [droneViewLoading, setDroneViewLoading] = useState(false);
  const [deviceUpdates, setDeviceUpdates] = useState([]);
  const [isAutoHeliSubscribed, setIsAutoHeliSubscribed] = useState(false);
  const [isAutoHeliMode, setIsAutoHeliMode] = useState(false);

  const lastUpdate =
    device && autoHeliUpdates[device.id]
      ? autoHeliUpdates[device.id][autoHeliUpdates[device.id].length - 1]
      : null;
  const lastUpdateSecondsAgo = device
    ? (new Date().getTime() - lastUpdate?.at?.getTime()) / 1000
    : 0;

  let onConnectionClose;
  let onMessageReceived;
  let onMessagesError;
  let processMessagesInterval;

  useEffect(() => {
    setIsDroneViewActive(false);
    setDeviceGpsUpdates([]);
    setIsAutoHeliSubscribed(false);
    setIsAutoHeliMode(false);
    // console.debug('DroneViewControl', device, isDroneViewActive);
  }, [device?.id]); // object updates constantly due to changes in trackingSlice so only trigger if device id changes

  useEffect(() => {
    if (!canGTT) {
      return;
    }
    if (!client && !isMqttConnected && trackingData?.fleets?.length > 0) {
      setMqttClient(
        mqtt.connect(HELICOPTER_URL, {
          username: HELICOPTER_USER,
          password: HELICOPTER_PW
        })
      );
    }
    if (client && isMqttConnected && !isAutoHeliSubscribed && trackingData?.fleets?.length > 0) {
      setIsAutoHeliSubscribed(true);
      client.subscribe([`+/s:${currentCompany.slug}/+/+/+/heli`, `+/+/+/+/heli`]);
      client.on('message', onAutoHeliMessageReceived);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canGTT, isMqttConnected, client, trackingData]);

  useEffect(() => {
    return () => {
      if (client) {
        client.unsubscribe([`+/s:${currentCompany.slug}/+/+/+/heli`, `+/+/+/+/heli`]);
        client.off('message', onAutoHeliMessageReceived);
      }
    };
  }, [client]);

  useEffect(() => {
    setIsAutoHeliMode(lastUpdate && lastUpdateSecondsAgo < AUTO_HELI_THRESHOLD);
  }, [lastUpdateSecondsAgo, lastUpdate]);

  useEffect(() => {
    if (device && autoHeliUpdates[device.id]) {
      setDeviceGpsUpdates([...deviceUpdates, lastUpdate]);
      isAutoHeliMode && !isDroneViewActive && handleDroneViewToggled(true);
    }
  }, [device?.id, autoHeliUpdates]);

  useEffect(() => {
    if (
      device?.type?.features?.includes('HELI') &&
      userPreferences?.refresh?.tracking !== -1 &&
      !client
    ) {
      setMqttClient(
        mqtt.connect(HELICOPTER_URL, {
          username: HELICOPTER_USER,
          password: HELICOPTER_PW
        })
      );
    }

    return () => {
      if (
        device?.type?.features?.includes('HELI') &&
        userPreferences?.refresh?.tracking !== -1 &&
        client
      ) {
        client.end();
        setMqttClient(null);
      }
      disconnectDroneView();
    };
  }, [device?.id, device?.type?.features?.join(''), client, userPreferences]);

  const setDeviceGpsUpdates = deviceGpsUpdates => {
    setDeviceUpdates(deviceGpsUpdates);
    onDroneViewGpsUpdated && onDroneViewGpsUpdated(deviceGpsUpdates);
  };

  const setIsDroneViewLoading = isLoading => {
    setDroneViewLoading(isLoading);
    onIsDroneViewLoadingChanged && onIsDroneViewLoadingChanged(isLoading);
  };

  const onAutoHeliMessageReceived = (topic, message) => {
    if (topic.indexOf('/heli') > -1) {
      cbor.decodeAll(message, (_, gps) => {
        if (gps) {
          const gpsUpdate = {
            at: gps[0][0],
            lat: gps[0][1],
            lng: gps[0][2],
            speed: gps[0][3],
            alt: gps[0][4],
            dir: gps[0][5]
          };
          const imei = topic.substring(topic.lastIndexOf('/i:') + 3, topic.lastIndexOf('/f:'));
          const device = trackingData.devices.find(d => d.imei === imei);

          if (gpsUpdate.lat && gpsUpdate.lng && device?.id) {
            dispatch(setUpdatesAutoHeli({ gpsUpdate, deviceId: device.id }));
          }
        }
      });
    }
  };

  const deactiveDroneView = () => {
    disconnectDroneView();
    onDroneViewToggled && onDroneViewToggled(false);
    clearCountdown(() => setIsDroneViewActive(false));
  };

  const handleDroneViewToggled = isActive => {
    if (isActive) {
      getDroneView(device);
      startCountdown(deactiveDroneView);
    } else {
      deactiveDroneView();
    }

    Mixpanel.sendTrackEvent(MPTrackingEvents.Tracking.Trip.Toggle.DroneViewToggle, {
      droneViewActive: isActive
    });
    setIsDroneViewActive(isActive);
    onDroneViewToggled && onDroneViewToggled(isActive);
  };

  const getDroneView = device => {
    ref.current = {
      gpsUpdates: [],
      processedIndex: 0,
      interval: null
    };

    processMessagesInterval = () => {
      if (ref?.current?.interval) {
        clearInterval(ref.current.interval);
      }
      ref.current.interval = setInterval(() => {
        if (ref.current.processedIndex < ref.current.gpsUpdates.length) {
          setDeviceGpsUpdates(ref.current.gpsUpdates.slice(0, ref.current.processedIndex));
          // console.debug(ref.current.gpsUpdates.slice(0, ref.current.processedIndex));
          // console.debug('mqtt - processed index - ' + ref.current.processedIndex);
          ref.current.processedIndex = ref.current.processedIndex + 1;
        }
      }, 1000);
    };

    onMessageReceived = (_, message) => {
      // console.debug('mqtt - message: ' + message);

      cbor.decodeAll(message, (_, gps) => {
        // console.debug('mqtt - received index - ' + messageIndex);
        if (!gps) {
          return;
        }

        setIsDroneViewLoading(false);
        const gpsUpdate = {
          at: gps[0][0],
          lat: gps[0][1],
          lng: gps[0][2],
          speed: gps[0][3],
          alt: gps[0][4],
          dir: gps[0][5]
        };

        if (gpsUpdate.lat && gpsUpdate.lng) {
          ref.current.gpsUpdates = [...ref.current.gpsUpdates, gpsUpdate];
        }
      });
    };

    onMessagesError = err => {
      // console.error('mqtt - error: ', err);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('UnableDrone')
        })
      );
      setIsDroneViewLoading(false);
      clearInterval(ref.current.interval);
    };

    onConnectionClose = () => {
      // console.debug('mqtt - close: ' + moment().valueOf());
      setIsDroneViewLoading(false);
      clearInterval(ref.current.interval);
    };

    setDeviceGpsUpdates([]);
    setIsDroneViewLoading(true);
    dispatch(
      openToast({
        type: ToastType.Success,
        message: t('Tracking.DeployingDrone')
      })
    );
    if (client) {
      client.on('connect', processMessagesInterval);
      // isMqttConnected && console.debug('mqtt - connected: ' + moment().valueOf());

      // console.debug('subscribe', device.imei);
      client.subscribe(
        ['+/i:' + device.imei + '/#', '+/+/i:' + device.imei + '/#'],
        (err, granted) => {
          // console.debug('mqtt - subscribed to topic: "+/i:' + device.imei + '/#": ' + moment().valueOf());
          // err && console.error('mqtt - err:', err);
          // granted && console.debug('mqtt - granted:', granted);

          if (!ref?.current?.connected) {
            request('POST', API_PATH + '/devices/' + device.id + '/mdm/helicopter')
              .set('Authorization', `Token token="${userKey}"`)
              .set('content-type', 'application/json')
              .send({
                timeout: 300,
                interval: 1
              })
              .then(
                response => {
                  ref.current.connected = true;
                  return response.body;
                },
                err => {
                  setIsDroneViewLoading(false);
                  dispatch(
                    openToast({
                      type: ToastType.Error,
                      message: err.rawResponse
                    })
                  );
                }
              );
          }

          // animate
          processMessagesInterval();

          client.on('message', onMessageReceived);
        }
      );

      client.on('error', onMessagesError);

      client.on('close', onConnectionClose);
    }
  };

  const disconnectDroneView = () => {
    ref?.current?.interval && clearInterval(ref.current.interval);
    if (client && device?.imei) {
      client.unsubscribe(['+/i:' + device.imei + '/#', '+/+/i:' + device.imei + '/#'], err => {
        // console.debug('mqtt - unsubscribed, error:' + err);
      });
    }
    !!onConnectionClose && !!client && client.off('close', onConnectionClose);
    !!onMessageReceived && !!client && client.off('message', onMessageReceived);
    !!onMessagesError && !!client && client.off('error', onMessagesError);
    !!processMessagesInterval && !!client && client.off('connect', processMessagesInterval);

    setIsDroneViewLoading(false);
    setIsDroneViewActive(false);
  };

  return (
    <div className={styles.droneView}>
      <Row className={styles.row} align="middle" gutter={[16, 32]}>
        <Col
          style={{
            maxWidth: '50%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          {device?.vehicle?.id ? (
            <Link to={'/settings/vehicles/id/' + device?.vehicle?.id} className={styles.deviceName}>
              {device?.vehicle?.name}
            </Link>
          ) : device?.id ? (
            <Link to={'/settings/devices/id/' + device?.id} className={styles.deviceName}>
              {device?.name}
            </Link>
          ) : (
            <span className={styles.deviceName}>{device?.name}</span>
          )}
        </Col>
        <Col flex="auto" />
        <Col>
          <ToggleButton
            label={t('Tracking.DroneView')}
            isActive={isDroneViewActive}
            isDisabled={isAutoHeliMode}
            isLoading={droneViewLoading}
            onToggle={isActive => {
              handleDroneViewToggled(isActive);
            }}
          />
        </Col>
      </Row>

      {isDroneViewActive && deviceUpdates.length > 0 && (
        <Row align="middle">
          <Col span={12} className={styles.speed}>
            <label className={styles.label}>{t('Map.DroneViewControl.Speed')}</label>
            <div className={styles.value}>
              {deviceUpdates[deviceUpdates.length - 1].speed !== null
                ? localization.formatSpeed(deviceUpdates[deviceUpdates.length - 1].speed)
                : '-'}
            </div>
          </Col>
          <Col className={styles.altitude}>
            <label className={styles.label}>{t('Map.DroneViewControl.Altitude')}</label>
            <div className={styles.value}>
              {deviceUpdates[deviceUpdates.length - 1].alt !== null
                ? localization.formatAltitude(deviceUpdates[deviceUpdates.length - 1].alt)
                : '-'}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
