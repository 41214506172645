import React, { memo } from 'react';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { rowAction } from './VehiclesTableAddFleet';
import {
  cacheGeofences,
  checkboxCellRenderer,
  nameCellRenderer,
  geofenceTypeCellRenderer,
  geofenceFleetsCellRenderer
} from './CellRenderers';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './Fleets.module.scss';

export const GeofenceTableComponent = ({
  geofences,
  checkedItems,
  setCheckedItems,
  setTableRef
}) => {
  const { t } = useTranslation();
  const fixedColumnsWidth = {
    checkbox: 48,
    name: 250,
    type: 100,
    fleet: 300
  };

  const handleCheckboxChange = evt => {
    if (evt.target.checked) {
      setCheckedItems(geofences.map(g => g.id));
    } else {
      setCheckedItems([]);
    }
  };

  const headerCheckboxRender = () => {
    return (
      <Checkbox
        onChange={handleCheckboxChange}
        checked={
          !(
            geofences?.length !== checkedItems?.length ||
            geofences?.some(g => !checkedItems?.includes(g.id))
          )
        }
      />
    );
  };

  const handleRowClick = event => {
    rowAction(event.rowData.id, checkedItems, setCheckedItems);
  };

  return (
    <AutoSizer>
      {({ height, width }) => {
        return (
          <Table
            deferredMeasurementCache={cacheGeofences}
            width={width}
            height={height}
            headerClassName={styles.tableHeaderText}
            headerHeight={52}
            rowHeight={cacheGeofences.rowHeight}
            rowCount={geofences.length}
            rowGetter={({ index }) => geofences[index]}
            rowClassName="tableRow"
            onRowClick={handleRowClick}
            ref={ref => setTableRef(ref)}
            gridClassName="showScrollbarsOnHover fleetsTablesBottomMargin"
          >
            <Column
              label=""
              dataKey="id"
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.checkbox}
              headerRenderer={headerCheckboxRender}
              cellRenderer={props =>
                checkboxCellRenderer({
                  ...props,
                  checkedItems,
                  setCheckedItems
                })
              }
            />
            <Column
              label={t('Common.Name')}
              dataKey="name"
              width={fixedColumnsWidth.name}
              cellRenderer={nameCellRenderer}
            />
            <Column
              label={t('VehicleTypes.Type')}
              dataKey="type"
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.type}
              cellRenderer={geofenceTypeCellRenderer}
            />
            <Column
              label={t('Common.Fleets')}
              dataKey="fleets"
              style={{ padding: '5px 0px' }}
              cellRenderer={geofenceFleetsCellRenderer}
              width={fixedColumnsWidth.fleet}
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};

export const GeofenceTable = memo(GeofenceTableComponent);
