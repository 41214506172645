import React from 'react';

import { Layout } from 'components/ant';

import TabHeader from '../TabHeader';

const InsightsPage = ({ SubPage, toolbar }) => {
  return (
    <Layout>
      <TabHeader toolbar={toolbar} />
      <SubPage />
    </Layout>
  );
};

export default InsightsPage;
