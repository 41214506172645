import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EWDJquery } from './EWDD3';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import {
  setPeriod,
  fetchEvents,
  useCheckpoint,
  fetchCheckpointAndViolations,
  useIsFetchingEWDDriverRecords
} from './events/eventsSlice';
import styles from './EWD.module.scss';
import { Card } from 'react-bootstrap';
import { Row, Col } from 'antd';
import { useEwdDriverId, setEWDDriver } from './drivers/driversSlice';
import { useDrivers } from 'features/users/usersSlice';
import { fetchEWDUser } from 'features/user/EWDUserHooks';
import { EWDExport } from './EWDExport';
import { EventsAndAnnotationsTabs } from './EventsAndAnnotationsTabs';
import { StatusAndViolationsTabs } from './StatusAndViolationsTabs';
import { fetchEWDStatusByDriverId } from './status/statusSlice';
import { fetchAnnotations } from './annotations/annotationsSlice';
import { useLocation } from 'react-router';
import { format } from 'utils/dates';
import { fetchEWDDriverKeeper, useDriverEWDKeeperId } from './keepers/keepersSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { DateRangePicker } from 'components/ant';
import moment from 'moment';
import { useLocalization } from 'features/localization/localizationSlice';

const DatePicker = ({ initFrom, ...props }) => {
  const dispatch = useDispatch();
  const fromDate = useSelector(state => state.ewd.period.fromDate);
  const toDate = useSelector(state => state.ewd.period.toDate);
  const driverId = useEwdDriverId();
  const checkpoint = useCheckpoint();
  const location = useLocation();
  const localization = useLocalization();
  const [defaultDate] = useState(() => {
    let d1 = fromDate,
      d2 = toDate;
    if (d1 === null) {
      d1 = moment().startOf('day');
    } else {
      d1 = moment(d1);
    }

    if (d2 === null) {
      d2 = moment().endOf('day');
    } else {
      d2 = moment(d2);
    }
    return [d1, d2];
  });

  const [availableDatesRange] = useState([
    moment().subtract(10, 'year'),
    moment()
      .add(2, 'month')
      .endOf('day')
  ]);

  if (
    location?.search?.indexOf('from') >= 0 &&
    fromDate === format(new Date().setHours(0, 0, 0, 0), 'YYYY-MM-DD')
  ) {
    const params = location.search.split('&');
    let fromDateStr = params.find(p => p.indexOf('from=') >= 0);
    fromDateStr = fromDateStr.substring(fromDateStr.indexOf('from=') + 5);
    if (fromDateStr.trim() !== '' && !isNaN(parseInt(fromDateStr))) {
      if (fromDateStr.length <= 10) {
        fromDateStr += '000';
      }

      let queryFromDate = new Date(parseInt(fromDateStr));
      if (!isNaN(queryFromDate.getTime())) {
        queryFromDate.setHours(0, 0, 0, 0);
        let queryToDate = new Date(parseInt(fromDateStr));
        queryToDate.setHours(23, 59, 59);
        dispatch(
          setPeriod({
            fromDate: format(queryFromDate, 'YYYY-MM-DD'),
            toDate: format(queryToDate, 'YYYY-MM-DD')
          })
        );
      }
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const isFetching = useIsFetchingEWDDriverRecords();

  const fetch = useCallback(() => {
    if (driverId) {
      dispatch(fetchEvents(driverId));
      dispatch(fetchAnnotations(driverId));
      dispatch(fetchCheckpointAndViolations(driverId, checkpoint));
    }
  }, [dispatch, driverId, checkpoint]);

  const handleDateRangeChanged = useCallback(
    dates => {
      dispatch(setPeriod({ fromDate: dates[0].format(), toDate: dates[1].format() }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (fromDate && toDate && !isOpen) {
      fetch();
    }
  }, [fetch, fromDate, toDate, isOpen]);

  return (
    <DateRangePicker
      onOpenChange={setIsOpen}
      disabled={isFetching}
      defaultDates={defaultDate}
      maxDayRange={90}
      onDateRangeChanged={handleDateRangeChanged}
      showNavigation={true}
      availableDatesRange={availableDatesRange}
      format={localization.formats.date.formats.default}
      showTime={false}
      useStartOfDayForStartDay={true}
    />
  );
};

const Header = ({ userID }) => {
  const dispatch = useDispatch();
  const driver = useDrivers().find(driver => driver.id === parseInt(userID, 10));

  useEffect(() => {
    dispatch(setEWDDriver(driver));
  }, [dispatch, driver]);

  useEffect(() => {
    if (driver?.firstName) {
      dispatch(
        setPageTitle(driver.firstName ? `EWD - ${driver.firstName} ${driver.lastName}` : 'EWD')
      );
    }
    dispatch(setBackButton(true));
  }, [dispatch, driver]);

  return (
    <Row className="mb-3">
      <Col span={24}>
        <Row>
          <Col className="col-md-auto">
            <strong>Licence:</strong>
            <br /> {driver?.licenceNumber}
          </Col>
          <Col className="col-md-auto">
            <strong>State:</strong>
            <br /> {driver?.licenceState}
          </Col>
          <Col className="col-md-auto">
            <strong>Driver Timezone:</strong>
            <br /> {driver?.timeZone}
          </Col>
          <Col sm="auto" className="pt-1 ml-md-auto">
            <EWDExport />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const EWD = props => {
  const dispatch = useDispatch();
  const ewdUser = useSelector(state => state.user.currentEWDUser);
  const driverId = useEwdDriverId();
  const currentCompany = useCurrentCompany();
  const driverEWDKeeperId = useDriverEWDKeeperId(driverId, currentCompany?.slug);

  useEffect(() => {
    dispatch(
      setPeriod({
        fromDate: moment()
          .startOf('day')
          .format(),
        toDate: moment()
          .endOf('day')
          .format()
      })
    );
    return () => {
      dispatch(
        setPeriod({
          fromDate: null,
          toDate: null
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if (!ewdUser?.auth?.expiry) {
      dispatch(fetchEWDUser());
    }
    if (ewdUser?.auth?.expiry) {
      const isJWTExpired = ewdUser.auth.expiry * 1000 < new Date().valueOf();
      if (isJWTExpired) {
        dispatch(fetchEWDUser());
      }
    }
  }, [dispatch, ewdUser]);

  useEffect(() => {
    if (ewdUser.auth.expiry && driverId) {
      dispatch(fetchEvents(driverId));
      dispatch(fetchCheckpointAndViolations(driverId));
      dispatch(fetchEWDStatusByDriverId(driverId));
    }
  }, [dispatch, driverId, ewdUser]);

  useEffect(() => {
    if (ewdUser.auth.expiry && driverId && driverEWDKeeperId == null) {
      dispatch(fetchEWDDriverKeeper(driverId));
    }
  }, [dispatch, driverId, ewdUser, driverEWDKeeperId]);

  return (
    <Row style={{ flex: '1' }}>
      <div className={styles.EWDWrapper}>
        <div className={styles.EWDTop}>
          <Header userID={props.match.params.id} />
          <Card>
            <Card.Header className="p-2">
              <Row>
                <Col sm="auto">
                  <h2 className="h5 m-0 p-2">Activity</h2>
                </Col>
                <Col>
                  <Row style={{ alignContent: 'center', height: '100%' }}>
                    <DatePicker />
                  </Row>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="card-body p-0">
              <Row>
                <Col span={24} id="activity-widget" className="ewd-chart">
                  <EWDJquery />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>
        <div className={styles.EWDBottom}>
          <div className={styles.EWDBottomleft}>
            <EventsAndAnnotationsTabs />
          </div>
          <div className={styles.EWDBottomRight}>
            <StatusAndViolationsTabs />
          </div>
        </div>
      </div>
    </Row>
  );
};

export default EWD;
