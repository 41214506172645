import { createSlice } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { ToastType } from 'components/notifications/toasts/Toast';
import { getIDFromPathname, getRunsheetIDFromPathname } from 'containers/SmartJobs/utils/helpers';
import {
  useCurrentCompanyKey,
  useIsFetchingCurrentCompanyServices
} from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { api, fetch } from 'utils/api';
import { getIntervalFromDate } from 'utils/dates';
import { parseErrorMessage } from 'utils/strings';
import moment from 'moment';
import { RunsheetStatus } from '../../containers/SmartJobs/utils/constants';
import services from 'features/permissions/services';
import i18n from 'i18next';

// Endpoint URLS
export const RUNSHEETS = '/runsheets';
export const RUNSHEET_CODES = '/runsheets/codes';
export const RUNSHEET = '/runsheets/';
export const USERS_URL = '/users';
export const VEHICLES = '/vehicles/';
export const DEVICES = '/devices/';
export const JOB = '/jobs/';
export const ATTACHMENTS = '/attach';
export const EVENTS = '/events';
export const UPLOAD_RUNSHEETS = '/runsheets/upload';
export const COMPLETE_RUNSHEET = '/complete?force=true';

const initialState = {
  runsheet: {},
  runsheetsList: [],
  columnsList: [],
  filtersList: [],
  runsheetEvents: {},
  positions: {},
  devicePosition: {},
  jobData: {},
  jobAttachmentData: {},
  jobEventsData: {},
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    success: false,
    companyKey: null,
    columns: {
      hasReturnedSucces: false,
      isFetching: false,
      hasReturnedError: false,
      error: null
    },
    filters: {
      hasReturnedSucces: false,
      isFetching: false,
      hasReturnedError: false,
      error: null
    },
    runsheet: {
      isFetching: false,
      error: null,
      success: false
    },
    positions: {
      isFetching: false,
      error: null,
      success: false
    },
    events: {
      isFetching: false,
      error: null,
      success: false
    },
    itemTypes: { isFetching: false, error: null, success: false },
    job: {
      isFetching: false,
      error: null,
      success: false,
      companyKey: null
    },
    jobAttachment: {
      meta: {
        isFetching: false,
        error: null,
        success: false,
        companyKey: null
      }
    },
    jobEvents: {
      meta: {
        isFetching: false,
        error: null,
        success: false,
        companyKey: null
      }
    },
    save: { isFetching: false, error: null, success: false },
    upload: { isFetching: false, error: null, success: false }
  },
  calendarView: false,
  calendarDate: undefined,
  date: undefined
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function startPositionLoading(state) {
  state.meta.positions.isFetching = true;
}

function startRunsheetLoading(state) {
  state.meta.runsheet.isFetching = true;
}

function startEventsLoading(state) {
  state.meta.events.isFetching = true;
}

function startLoadingSmartJobsManageColumns(state) {
  state.meta.columns.isFetching = true;
}

function startLoadingSmartJobsManageFilters(state) {
  state.meta.filters.isFetching = true;
}

function clearErrorState(state) {
  state.meta.jobAttachment.error = null;
  state.meta.job.error = null;
  state.meta.jobEvents.error = null;
}

function startLoadingJob(state) {
  state.meta.job.isFetching = true;
}

function startLoadingJobAttachment(state) {
  state.meta.jobAttachment.isFetching = true;
}

function startLoadingJobEvents(state) {
  state.meta.jobEvents.isFetching = true;
}

function loadingSmartJobsManageColumnsFailed(state, action) {
  state.meta.columns.isFetching = false;
  state.meta.columns.hasReturnedError = true;
  state.meta.columns.error = action.payload.error;
  state.columnsList = [];
}

function loadingSmartJobsManageFiltersFailed(state, action) {
  state.meta.filters.isFetching = false;
  state.meta.filters.hasReturnedError = true;
  state.meta.filters.error = action.payload.error;
  state.filtersList = [];
}

export const smartJobsSlice = createSlice({
  name: 'smartJobs',
  initialState,
  reducers: {
    fetchRunsheetsStart: startLoading,
    fetchRunsheetsSuccess(state, { payload }) {
      state.runsheetsList = payload.list;
      state.meta.isFetching = false;
      state.meta.error = null;
      state.meta.success = true;
      state.meta.companyKey = payload.companyKey;
    },
    fetchSmartJobsManageColumnsStart: startLoadingSmartJobsManageColumns,
    fetchSmartJobsManageColumnsSuccess(state, { payload }) {
      state.columnsList = payload.columns || [];
      state.meta.columns.isFetching = false;
      state.meta.columns.error = null;
      state.meta.columns.hasReturnedSucces = true;
    },
    fetchSmartJobsManageColumnsFailure: loadingSmartJobsManageColumnsFailed,
    fetchSmartJobsManageFiltersStart: startLoadingSmartJobsManageFilters,
    fetchSmartJobsManageFiltersSuccess(state, { payload }) {
      state.filtersList = payload.filters || [];
      state.meta.filters.isFetching = false;
      state.meta.filters.error = null;
      state.meta.filters.hasReturnedSucces = true;
    },
    fetchSmartJobsManageFiltersFailure: loadingSmartJobsManageFiltersFailed,
    fetchRunsheetsFailure(state, { payload }) {
      state.meta.isFetching = false;
      state.meta.error = true;
      state.meta.success = false;
      state.list = [];
      state.meta.companyKey = payload.companyKey;
    },
    fetchItemTypesStart(state) {
      state.meta.itemTypes.isFetching = true;
      state.meta.itemTypes.success = true;
    },
    fetchItemTypesSuccess(state, { payload }) {
      state.itemTypes = payload;
      state.meta.itemTypes.isFetching = false;
    },
    fetchItemTypesFailure(state, { payload }) {
      state.meta.itemTypes.isFetching = false;
      state.meta.itemTypes.error = payload;
    },
    fetchRunsheetIdStart: startRunsheetLoading,
    fetchRunsheetIdSuccess(state, { payload }) {
      state.runsheet[payload.runsheet.id] = payload.runsheet;
      state.meta.runsheet.isFetching = false;
      state.meta.runsheet.success = true;
      state.meta.runsheet.error = null;
      state.meta.companyKey = payload.companyKey;
    },
    fetchRunsheetIdFailure(state, { payload }) {
      state.meta.runsheet.isFetching = false;
      state.runsheet = [];
      state.meta.runsheet.success = false;
      state.meta.runsheet.error = payload.error;
      state.meta.companyKey = payload.companyKey;
    },
    deleteRunsheetId(state, { payload }) {
      delete state.runsheet[payload];
    },
    deleteJobId(state, { payload }) {
      delete state.jobData[payload];
    },
    clearJobError: clearErrorState,
    fetchJobIDStart: startLoadingJob,
    fetchJobIDSuccess(state, { payload }) {
      state.jobData[payload.job.id] = payload.job;
      state.meta.job.isFetching = false;
      state.meta.job.success = true;
      state.meta.companyKey = payload.companyKey;
    },
    fetchJobIDFailure(state, { payload }) {
      state.meta.job.isFetching = false;
      state.meta.job.error = payload.error;
      state.meta.companyKey = payload.companyKey;
    },
    fetchJobAttachmentStart: startLoadingJobAttachment,
    fetchJobAttachmentSuccess(state, { payload }) {
      state.jobAttachmentData[payload.job.id] = payload.job;
      state.meta.jobAttachment.isFetching = false;
      state.meta.jobAttachment.success = true;
      state.meta.companyKey = payload.companyKey;
    },
    fetchJobAttachmentFailure(state, { payload }) {
      state.meta.jobAttachment.isFetching = false;
      state.meta.jobAttachment.error = payload.error;
      state.meta.companyKey = payload.companyKey;
    },
    fetchJobEventsStart: startLoadingJobEvents,
    fetchJobEventsSuccess(state, { payload }) {
      state.jobEventsData[payload.id] = payload.events;
      state.meta.jobEvents.isFetching = false;
      state.meta.jobEvents.success = true;
      state.meta.companyKey = payload.companyKey;
    },
    fetchJobEventsFailure(state, { payload }) {
      state.meta.jobEvents.isFetching = false;
      state.jobEventsData[payload.id] = null;
      state.meta.jobEvents.error = payload.error;
      state.meta.companyKey = payload.companyKey;
    },
    fetchRunsheetEventsStart: startEventsLoading,
    fetchRunsheetEventsSuccess(state, { payload }) {
      state.runsheetEvents[payload.runsheetEvents.id] = payload.runsheetEvents.response;
      state.meta.events.isFetching = false;
      state.meta.companyKey = payload.companyKey;
      state.meta.events.error = null;
      state.meta.events.success = true;
    },
    fetchRunsheetEventsFailure(state, { payload }) {
      state.meta.events.isFetching = false;
      state.runsheetEvents[payload.id] = null;
      state.meta.companykey = payload.companyKey;
      state.meta.events.error = payload.error;
      state.meta.events.success = false;
    },
    fetchPositionStart: startPositionLoading,
    fetchPositionSuccess(state, { payload }) {
      state.positions = Array.isArray(payload.positions)
        ? payload.positions.sort((a, b) => Number(a?.At) - Number(b?.At))
        : payload.positions?.GPS && Array.isArray(payload.positions.GPS)
        ? {
            ...payload.positions,
            GPS: payload.positions.GPS.sort((a, b) => Number(a?.At) - Number(b?.At))
          }
        : payload.positions;
      state.meta.positions.isFetching = false;
      state.meta.companyKey = payload.companyKey;
      state.meta.positions.error = null;
      state.meta.positions.success = true;
    },
    fetchPositionFailure(state, { payload }) {
      state.meta.positions.isFetching = false;
      state.positions = {};
      state.meta.companykey = payload.companyKey;
      state.meta.positions.error = payload.error;
      state.meta.positions.success = false;
    },
    startSavingRunsheet(state) {
      state.meta.save.isFetching = true;
    },
    finishSavingRunsheet(state, { payload }) {
      state.meta.save.isFetching = false;
      state.meta.save.success = false;
      state.meta.save.error = payload;
    },
    startUploadingRunsheet(state) {
      state.meta.upload.isFetching = true;
    },
    finishUploadingRunsheet(state) {
      state.meta.upload.isFetching = false;
    },
    setRunsheetSuccess(state) {
      state.meta.runsheet.success = false;
    },
    changeCalendarView(state) {
      state.calendarView = !state.calendarView;
    },
    startRunsheetById(state, { payload }) {
      state.meta.runsheet[payload?.id] = {
        isFetching: true,
        success: false,
        error: null
      };
    },
    finishRunsheetById(state, { payload }) {
      state.runsheet[payload.id] = payload.runsheet;

      state.meta.runsheet[payload?.id] = {
        isFetching: false,
        success: true,
        error: null
      };

      state.runsheetsList = (state.runsheetsList || []).map(existing =>
        String(existing?.id) === String(payload?.id)
          ? {
              ...existing,
              ...payload.runsheet
            }
          : existing
      );
    },
    errorRunsheetById(state, { payload }) {
      state.meta.runsheet[payload?.id] = {
        isFetching: false,
        success: false,
        error: true
      };
    },
    setDate(state, { payload }) {
      const date = payload ? moment(payload) : moment();
      state.date = date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString();
    },
    setCalendarDate(state) {
      const { calendarView, date } = state;
      state.calendarDate = calendarView ? date : undefined;
    },
    updateCalendarDate(state, { payload }) {
      state.calendarDate = payload
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISOString();
    }
  }
});

export const {
  fetchRunsheetsStart,
  fetchRunsheetsSuccess,
  fetchRunsheetsFailure,
  fetchItemTypesStart,
  fetchItemTypesSuccess,
  fetchItemTypesFailure,
  fetchRunsheetIdStart,
  fetchRunsheetIdSuccess,
  fetchRunsheetIdFailure,
  deleteRunsheetId,
  deleteJobId,
  fetchJobIDStart,
  fetchJobIDSuccess,
  fetchJobIDFailure,
  fetchJobAttachmentStart,
  fetchJobAttachmentSuccess,
  fetchJobAttachmentFailure,
  fetchJobEventsStart,
  fetchJobEventsSuccess,
  fetchJobEventsFailure,
  fetchSmartJobsManageColumnsStart,
  fetchSmartJobsManageColumnsSuccess,
  fetchSmartJobsManageColumnsFailure,
  fetchSmartJobsManageFiltersStart,
  fetchSmartJobsManageFiltersSuccess,
  fetchSmartJobsManageFiltersFailure,
  fetchRunsheetEventsStart,
  fetchRunsheetEventsSuccess,
  fetchRunsheetEventsFailure,
  fetchPositionStart,
  fetchPositionSuccess,
  fetchPositionFailure,
  startSavingRunsheet,
  finishSavingRunsheet,
  startUploadingRunsheet,
  finishUploadingRunsheet,
  setRunsheetSuccess,
  changeCalendarView,
  startRunsheetById,
  errorRunsheetById,
  finishRunsheetById,
  setDate,
  setCalendarDate,
  updateCalendarDate,
  clearJobError
} = smartJobsSlice.actions;

export const fetchRunsheets = () => async (dispatch, getState) => {
  const {
    companies: { current: currentCompany },
    user: { current: currentUser },
    smartJobs: { date, calendarView, calendarDate }
  } = getState();
  const args = calendarView ? [calendarDate, 'week'] : [date];
  const { start, end } = getIntervalFromDate(...args);
  const authKey = currentUser?.auth?.key;
  if (!authKey || !currentCompany || !currentCompany?.id) {
    return;
  }

  dispatch(fetchRunsheetsStart());
  let runsheets = [];
  const query_mode = currentCompany.features?.some(f => f.code === services.TASKMANAGER)
    ? { mode: 'TM' }
    : {};
  const query = {
    company_id: currentCompany.id,
    // embed: 'user,vehicle,carrier,jobs,location,locationType',
    embed: '**',
    ...query_mode,
    pruning: 'ALL',
    scheduledFromDate: start,
    scheduledToDate: end
  };
  try {
    const response = await api.get(RUNSHEETS, {
      authKey,
      query
    });

    if (!response?.body) {
      dispatch(
        fetchRunsheetsFailure({
          error: 'No response received!',
          companyKey: currentCompany.api_key
        })
      );
    }
    runsheets.push(...response.body);
  } catch (err) {
    dispatch(fetchRunsheetsFailure({ error: err, companyKey: currentCompany.api_key }));
  }

  dispatch(fetchRunsheetsSuccess({ list: runsheets, companyKey: currentCompany.api_key }));
};

export const fetchItemTypes = () => async (dispatch, getState) => {
  dispatch(fetchItemTypesStart());
  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;

  if (!authKey || !currentCompany) {
    return;
  }

  try {
    const response = await api.get(RUNSHEET_CODES, {
      authKey,
      query: {
        company_id: currentCompany?.id,
        type: 'item.type'
      }
    });

    if (!response?.body) {
      dispatch(fetchItemTypesFailure('No response!'));
    }

    dispatch(fetchItemTypesSuccess(response.body));
  } catch (err) {
    dispatch(fetchItemTypesFailure(err));
  }
};

export const saveRunsheet = (runsheet, update = false, translate = name => name) => async (
  dispatch,
  getState
) => {
  dispatch(startSavingRunsheet());
  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;

  if (!authKey || !currentCompany) {
    return;
  }

  try {
    const url = update ? `/runsheets/${runsheet.id}` : '/runsheets/import';
    const apiCall = update ? api.put : api.post;

    const response = await apiCall(
      url,
      {
        authKey,
        query: {
          company_id: currentCompany?.id
        }
      },
      { ...runsheet, category: 'DEFAULT' }
    );

    if (response?.body) {
      await dispatch(fetchRunsheets());

      dispatch(
        openToast({
          type: ToastType.Success,
          message: translate('SmartJobs.Toasts.runsheetSaveSuccess', {
            name: response.body.externalId
          })
        })
      );
      dispatch(getRunsheetById(runsheet?.id));
      dispatch(finishSavingRunsheet());

      return response.body;
    }

    dispatch(finishSavingRunsheet());
    return false;
  } catch (err) {
    dispatch(finishSavingRunsheet(err));

    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseErrorMessage(err)
      })
    );
  }
};

export const completeRunsheet = (runsheet, reason, translate = name => name) => async (
  dispatch,
  getState
) => {
  dispatch(startRunsheetById({ id: runsheet.id }));
  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;

  if (!authKey || !currentCompany) {
    return;
  }

  try {
    const payload = {
      runsheet: { id: runsheet.id },
      user: { id: currentUser?.id },
      description: reason,
      type: RunsheetStatus.COMPLETED
    };

    const response = await api.post(
      RUNSHEET.concat(runsheet.id).concat(COMPLETE_RUNSHEET),
      {
        authKey
      },
      payload
    );

    if (response?.body) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: translate('SmartJobs.Toasts.runsheetCompleteSuccess', {
            name: response.body.externalId
          })
        })
      );
      dispatch(getRunsheetById(runsheet.id));
      return runsheet.id;
    }
    dispatch(finishRunsheetById({ id: runsheet.id, runsheet: runsheet }));

    return false;
  } catch (err) {
    dispatch(errorRunsheetById({ id: runsheet.id }));

    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseErrorMessage(err)
      })
    );
  }
};

export const fetchSmartJobsManageColumns = () => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const id = getState().user.current.id;
  const urlForManageColumnsSmartJobs = `${USERS_URL}/${id}/config/smartjobs.table_columns`;

  if (!authKey) {
    return;
  }

  dispatch(fetchSmartJobsManageColumnsStart());
  try {
    const response = await api.get(urlForManageColumnsSmartJobs, { authKey });
    const { body } = response;
    dispatch(fetchSmartJobsManageColumnsSuccess({ columns: body }));
  } catch (err) {
    dispatch(fetchSmartJobsManageColumnsFailure({ err: err.toString() }));
  }
};

export const fetchSmartJobsManageFilters = () => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const id = getState().user.current.id;
  const urlForManageSmartJobsFilters = `${USERS_URL}/${id}/config/smartjobs.filters`;

  if (!authKey) {
    return;
  }

  dispatch(fetchSmartJobsManageFiltersStart());
  try {
    const response = await api.get(urlForManageSmartJobsFilters, { authKey });
    const { body } = response;
    dispatch(fetchSmartJobsManageFiltersSuccess({ filters: body }));
  } catch (err) {
    dispatch(fetchSmartJobsManageFiltersFailure({ err: err.toString() }));
  }
};
export const useIsCompanyKeyDifferent = () =>
  useSelector(state => state.smartJobs.meta.companyKey) !== useCurrentCompanyKey();

// @TODO - moved to thunks folder
export const fetchRunsheetId = pathname => async (dispatch, getState) => {
  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;
  const runsheetId = getIDFromPathname(pathname);

  if (!authKey || !currentCompany || !runsheetId) {
    return;
  }

  dispatch(fetchRunsheetIdStart());
  try {
    const response = await api.get(`${RUNSHEET}${runsheetId}`, {
      authKey,
      query: {
        company_id: currentCompany?.id,
        embed: '**',
        pruning: 'ALL'
      }
    });

    if (!response?.body) {
      dispatch(fetchRunsheetIdFailure());
    }

    dispatch(
      fetchRunsheetIdSuccess({ runsheet: response.body, companyKey: currentCompany.api_key })
    );
  } catch (err) {
    dispatch(fetchRunsheetIdFailure(err.toString()));
  }
};

export const fetchRunsheetEvents = pathname => async (dispatch, getState) => {
  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();

  const authKey = currentUser?.auth?.key;

  if (!authKey || !currentCompany) {
    return;
  }

  dispatch(fetchRunsheetEventsStart());
  try {
    const id = getIDFromPathname(pathname);
    const response = await api.get(RUNSHEET.concat(id).concat('/events'), {
      authKey,
      query: {
        company_id: currentCompany?.id,
        embed: 'user,vehicle,carrier,location,jobs.jobItems.item',
        pruning: 'ALL'
      }
    });

    if (!response?.body) {
      dispatch(fetchRunsheetEventsFailure());
    }

    const runsheetEvents = {
      response: response?.body,
      id
    };

    dispatch(
      fetchRunsheetEventsSuccess({
        runsheetEvents,
        companyKey: currentCompany.api_key
      })
    );
  } catch (err) {
    dispatch(
      fetchRunsheetEventsFailure({ error: err.toString(), companyKey: currentCompany.api_key })
    );
  }
};

export const fetchPosition = (ids, events) => async (dispatch, getState) => {
  const { vehicleId, deviceVehicleId, deviceId } = ids;
  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();

  const authKey = currentUser?.auth?.key;

  if (!authKey || !currentCompany) {
    return;
  }

  dispatch(fetchPositionStart());
  let positions;
  try {
    const query_from = moment(new Date(events.startEventTime));
    const query_to = moment(new Date(events.endEventTime));
    if (!(query_from.isValid() && query_to.isValid())) {
      return dispatch(
        fetchPositionSuccess({
          positions: [],
          companyKey: currentCompany.api_key
        })
      );
    }
    const from = query_from.format(),
      to = query_to.format();

    const response = await api.get(VEHICLES.concat(vehicleId).concat('/positions'), {
      authKey,
      query: {
        from,
        to,
        highDefinition: true,
        orderBy: 'time_at',
        company_id: currentCompany?.id,
        pruning: 'ALL',
        filter: 'rdp',
        limit: 'off'
      }
    });
    positions = response?.body;

    if ((!Array.isArray(positions) || !positions.length) && deviceVehicleId) {
      const response = await api.get(VEHICLES.concat(deviceVehicleId).concat('/positions'), {
        authKey,
        query: {
          from,
          to,
          highDefinition: true,
          company_id: currentCompany?.id,
          pruning: 'ALL',
          filter: 'rdp',
          limit: 'off'
        }
      });
      positions = response?.body;
    }

    if ((!Array.isArray(positions) || !positions.length) && deviceId) {
      const response = await api.get(DEVICES.concat(deviceId).concat('/positions'), {
        authKey,
        query: {
          from,
          to,
          highDefinition: true,
          company_id: currentCompany?.id,
          pruning: 'ALL'
        }
      });
      positions = response?.body;
    }

    if (!positions) {
      dispatch(fetchPosition());
    }

    dispatch(
      fetchPositionSuccess({
        positions: positions,
        companyKey: currentCompany.api_key
      })
    );
  } catch (err) {
    dispatch(fetchPositionFailure(err.toString()));
  }
};

export const useRunsheets = () => {
  const dispatch = useDispatch();
  const runsheets = useSelector(state => state.smartJobs.runsheetsList);

  const isFetching = useIsFetchingRunsheets();
  const isSuccess = useSelector(state => state.smartJobs.meta.success);
  const isError = useSelector(state => state.smartJobs.meta.error) !== null;
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();
  const isFetchComplete = isSuccess || isError;
  const isFetchingCompanyServices = useIsFetchingCurrentCompanyServices();
  const shouldFetch = useMemo(
    () => !isFetching && (!isFetchComplete || isCompanyKeyDifferent) && !isFetchingCompanyServices,
    [isFetching, isFetchComplete, isCompanyKeyDifferent, isFetchingCompanyServices]
  );
  if (shouldFetch) {
    dispatch(fetchRunsheets());
  }
  return runsheets;
};

export const getRunsheetById = runsheetId => async (dispatch, getState) => {
  dispatch(startRunsheetById({ id: runsheetId }));
  const state = getState();
  const authKey = state?.user?.current?.auth?.key;
  const company_id = state?.companies?.current?.id;

  try {
    const response = await api.get(`${RUNSHEET}${runsheetId}`, {
      authKey,
      query: {
        company_id,
        embed: '**',
        pruning: 'ALL'
      }
    });
    dispatch(finishRunsheetById({ id: runsheetId, runsheet: response.body }));

    return response.body;
  } catch (err) {
    dispatch(errorRunsheetById({ id: runsheetId }));
  }
};

export const useItemTypes = () => {
  const dispatch = useDispatch();
  const itemTypes = useSelector(state => state.smartJobs.itemTypes) || [];

  const isFetching = useSelector(state => state.smartJobs.meta.itemTypes.isFetching);
  const isSuccess = useSelector(state => state.smartJobs.meta.itemTypes.success);
  const isError = useSelector(state => state.smartJobs.meta.itemTypes.error) !== null;
  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete) {
    dispatch(fetchItemTypes());
  }

  return itemTypes;
};

export const useUserSmartJobsColumns = () => {
  const dispatch = useDispatch();
  const columns = useSelector(state => state.smartJobs.columnsList);
  const isFetching = useSelector(state => state.smartJobs.meta.columns.isFetching);
  const hasReturnedSucces = useSelector(state => state.smartJobs.meta.columns.hasReturnedSucces);
  const hasReturnedError = useSelector(state => state.smartJobs.meta.columns.hasReturnedError);

  if (!isFetching && !hasReturnedSucces && !hasReturnedError) {
    dispatch(fetchSmartJobsManageColumns());
  }

  return columns;
};

export const useUserSmartJobsFilters = forceFetch => {
  const dispatch = useDispatch();
  const filters = useSelector(state => state.smartJobs.filtersList);
  const isFetching = useSelector(state => state.smartJobs.meta.filters.isFetching);

  if (!isFetching && forceFetch) {
    dispatch(fetchSmartJobsManageFilters());
  }

  return filters;
};

export const useIsFetchingFilters = () =>
  useSelector(state => state.smartJobs.meta.filters.isFetching);

export const updateColumns = (columns, translate) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const id = getState().user.current.id;
  const urlForManageColumnsSmartJobs = `${USERS_URL}/${id}/config/smartjobs.table_columns`;
  if (!authKey) {
    return;
  }
  try {
    const updateResponse = await api.put(
      urlForManageColumnsSmartJobs,
      {
        authKey
      },
      columns
    );

    if (!updateResponse.ok) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: translate('SmartJobs.Toasts.columnsUpdateError')
        })
      );
    } else {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: translate('SmartJobs.Toasts.columnsUpdateSuccess')
        })
      );
      dispatch(fetchSmartJobsManageColumns());
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: translate('SmartJobs.Toasts.columnsUpdateErrorWithMessage', { error: err })
      })
    );
  }
};

export const updateFilters = filters => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const id = getState().user.current.id;
  const urlForManageSmartJobsFilters = `${USERS_URL}/${id}/config/smartjobs.filters`;
  if (!authKey) {
    return;
  }
  try {
    const updateResponse = await api.put(
      urlForManageSmartJobsFilters,
      {
        authKey
      },
      filters
    );
  } catch (err) {
    dispatch(fetchSmartJobsManageFiltersFailure(err.toString()));
  }
};

export const onRunsheetViewLeave = dispatch => {
  dispatch(setRunsheetSuccess());
};

export const useRunsheetEvents = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const runsheetEvents = useSelector(state => state.smartJobs.runsheetEvents);

  const isFetching = useSelector(state => state.smartJobs.meta.events.isFetching);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();
  const runsheetEventsKeys = Object.keys(runsheetEvents);
  const id = getIDFromPathname(location.pathname);

  if ((!isFetching && !runsheetEventsKeys.includes(id)) || isCompanyKeyDifferent) {
    dispatch(fetchRunsheetEvents(location.pathname));
  }
  return runsheetEvents[id];
};

export const useIsFetchingRunsheetEvents = () =>
  useSelector(state => state.smartJobs.meta.events.isFetching);

export const usePositions = () => {
  const positions = useSelector(state => state.smartJobs.positions);

  return positions;
};

export const useJob = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const job = useSelector(state => state.smartJobs.jobData);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();
  const jobKeys = Object.keys(job);

  const isFetching = useSelector(state => state.smartJobs.meta.job.isFetching);
  const isSuccess = useSelector(state => state.smartJobs.meta.job.success);
  const isError = useSelector(state => state.smartJobs.meta.job.error) !== null;
  const isFetchComplete = isSuccess || isError;

  const id = getIDFromPathname(location.pathname);

  if (
    !isFetching &&
    (!isFetchComplete || (!isError && !jobKeys.includes(id)) || isCompanyKeyDifferent)
  ) {
    dispatch(fetchJobId(location.pathname));
  }

  return job[id];
};

export const useJobAttachments = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const jobAttachment = useSelector(state => state.smartJobs.jobAttachmentData);
  const jobAttachmentKeys = Object.keys(jobAttachment);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();

  const isFetching = useSelector(state => state.smartJobs.meta.jobAttachment.isFetching);
  const isSuccess = useSelector(state => state.smartJobs.meta.job.success);
  const isError = useSelector(state => state.smartJobs.meta.job.error) !== null;
  const isFetchComplete = isSuccess || isError;
  const id = getIDFromPathname(location.pathname);

  if (
    !isFetching &&
    (!isFetchComplete || (!isError && !jobAttachmentKeys.includes(id)) || isCompanyKeyDifferent)
  ) {
    dispatch(fetchJobAttachments(location.pathname));
  }
  return jobAttachment[id];
};

export const useJobEvents = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const jobEvents = useSelector(state => state.smartJobs.jobEventsData);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();
  const jobEventsKeys = Object.keys(jobEvents);

  const isFetching = useSelector(state => state.smartJobs.meta.jobEvents.isFetching);
  const isSuccess = useSelector(state => state.smartJobs.meta.job.success);
  const isError = useSelector(state => state.smartJobs.meta.job.error) !== null;
  const isFetchComplete = isSuccess || isError;

  const id = getIDFromPathname(location.pathname);

  if (
    !isFetching &&
    (!isFetchComplete || (!isError && !jobEventsKeys.includes(id)) || isCompanyKeyDifferent)
  ) {
    dispatch(fetchJobEvents(location.pathname));
  }
  return jobEvents[id];
};

export const fetchJobId = pathname => async (dispatch, getState) => {
  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;

  if (!authKey) {
    return;
  }

  dispatch(fetchJobIDStart());
  try {
    const response = await api.get(
      RUNSHEET.concat(getRunsheetIDFromPathname(pathname))
        .concat(JOB)
        .concat(getIDFromPathname(pathname)),
      {
        authKey,
        query: {
          // @TO DO - same as in next gen, but maybe it can be improved
          // embed: 'jobItems.item,jobAttachments.attachment.content,attributes,runsheet,location',
          embed: '**',
          pruning: 'ALL',
          company_id: currentCompany?.id
        }
      }
    );

    if (!response?.body) {
      dispatch(
        fetchJobIDFailure({ error: 'No response received!', companyKey: currentCompany.api_key })
      );
    }

    dispatch(fetchJobIDSuccess({ job: response.body, companyKey: currentCompany.api_key }));
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        get message() {
          return `${i18n.t('Common.SomethingWentWrong')} - ${parseErrorMessage(err)}`;
        }
      })
    );
    dispatch(fetchJobIDFailure({ error: err.toString(), companyKey: currentCompany.api_key }));
  }
};

export const fetchJobAttachments = pathname => async (dispatch, getState) => {
  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;

  if (!authKey) {
    return;
  }

  dispatch(fetchJobAttachmentStart());
  try {
    const response = await api.get(
      RUNSHEET.concat(getRunsheetIDFromPathname(pathname))
        .concat(JOB)
        .concat(getIDFromPathname(pathname))
        .concat(ATTACHMENTS),
      {
        authKey,
        query: {
          embed: 'jobItems.item,jobAttachments.attachment.content,attributes, runsheet',
          pruning: 'ALL',
          company_id: currentCompany?.id
        }
      }
    );

    if (!response?.body) {
      dispatch(
        fetchJobAttachmentFailure({
          error: 'No response received!',
          companyKey: currentCompany.api_key
        })
      );
    }

    dispatch(fetchJobAttachmentSuccess({ job: response.body, companyKey: currentCompany.api_key }));
  } catch (err) {
    dispatch(
      fetchJobAttachmentFailure({ error: err.toString(), companyKey: currentCompany.api_key })
    );
  }
};

export const fetchJobEvents = pathname => async (dispatch, getState) => {
  const {
    companies: { current: currentCompany },
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;

  if (!authKey) {
    return;
  }

  dispatch(fetchJobEventsStart());
  try {
    const response = await api.get(
      RUNSHEET.concat(getRunsheetIDFromPathname(pathname))
        .concat(JOB)
        .concat(getIDFromPathname(pathname))
        .concat(EVENTS),
      {
        authKey,
        query: {
          embed: 'device,user,job,vehicle.fleet',
          pruning: 'ALL',
          company_id: currentCompany?.id
        }
      }
    );

    if (!response?.body) {
      dispatch(
        fetchJobEventsFailure({
          error: 'No response received!',
          id: getIDFromPathname(pathname),
          companyKey: currentCompany.api_key
        })
      );
    }

    dispatch(
      fetchJobEventsSuccess({
        events: response.body,
        id: getIDFromPathname(pathname),
        companyKey: currentCompany.api_key
      })
    );
  } catch (err) {
    dispatch(
      fetchJobEventsFailure({
        error: err.toString(),
        id: getIDFromPathname(pathname),
        companyKey: currentCompany.api_key
      })
    );
  }
};

/**
 * Upload .xlsx files
 *
 */
export const uploadRunsheet = file => async dispatch => {
  const formData = new FormData();
  formData.append('file', file);

  return await dispatch(
    fetch(
      'post',
      {
        url: UPLOAD_RUNSHEETS,
        body: formData
      },
      {
        start: () => {
          dispatch(startUploadingRunsheet());
        },
        error: () => {
          dispatch(finishUploadingRunsheet());
        },
        success: () => {
          dispatch(finishUploadingRunsheet());
        }
      }
    )
  );
};

export const useIsRunsheetSaving = () => useSelector(state => state.smartJobs.meta.save.isFetching);
export const useIsFetchingRunsheets = () => useSelector(state => state.smartJobs.meta.isFetching);
export const useIsUploadingRunsheets = () =>
  useSelector(state => state.smartJobs.meta.upload.isFetching);

export default smartJobsSlice.reducer;

export const handleRunsheetDeleteAction = (id, name, translate) => async (dispatch, getState) => {
  const {
    companies: { current: currentCompany },
    user: { current: currentUser },
    smartJobs
  } = getState();
  const authKey = currentUser?.auth?.key;

  if (!authKey || !currentCompany) {
    return;
  }

  const { runsheet } = smartJobs;
  const runsheetKeys = Object.keys(runsheet);

  try {
    const response = await api.delete(RUNSHEETS.concat(`/${id}`), { authKey });
    if (response && (response.ok || response.noContent)) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: translate('SmartJobs.Toasts.runsheetDeleteSuccess', {
            name
          })
        })
      );
      if (runsheetKeys.find(key => parseInt(key) === parseInt(id))) {
        dispatch(deleteRunsheetId(id.toString()));
      }
      dispatch(fetchRunsheets());
    }
  } catch (e) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: translate('SmartJobs.Toasts.runsheetDeleteError', {
          name
        })
      })
    );
  }
};

export const handleJobDeleteAction = (runsheetId, job, path, translate = name => name) => async (
  dispatch,
  getState
) => {
  const {
    companies: { current: currentCompany },
    user: { current: currentUser },
    smartJobs
  } = getState();
  const authKey = currentUser?.auth?.key;

  if (!authKey || !currentCompany) {
    return;
  }

  const { jobData } = smartJobs;
  const jobsKeys = Object.keys(jobData);

  try {
    const response = await api.delete(
      `${RUNSHEET}${runsheetId}${JOB}${job.id}`,
      { authKey },
      { id: runsheetId, job: { id: job.id, type: job.type } }
    );
    if (response && (response.ok || response.noContent)) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: translate('SmartJobs.Toasts.jobDeleteSuccess', {
            customerName: job.customerName
          })
        })
      );
      if (jobsKeys.find(key => parseInt(key) === parseInt(job.id))) {
        dispatch(deleteJobId(job.id.toString()));
      }
      dispatch(fetchRunsheetId(path));
    }
  } catch (e) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: translate('SmartJobs.Toasts.jobDeleteError', {
          customerName: job.customerName,
          error: e
        })
      })
    );
  }
};
