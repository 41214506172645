import { createSlice } from '@reduxjs/toolkit';
import { batch, useDispatch, useSelector } from 'react-redux';

import { api } from 'utils/api';

import { useUserKey } from 'features/user/userSlice';
import { METER_TYPES, VehicleConfig } from '../../containers/Administration/Vehicles/constants';
import { useCallback, useMemo } from 'react';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { t_error } from 'i18nextConfig';

const initialState = {
  meters: {},
  meta: {
    isFetching: false,
    isFetchingConfig: false,
    error: null,
    companyKey: null
  },
  vehiclesMetersMeta: {},
  vehicleCalibrateMeta: {
    vehicleId: null,
    meterType: null,
    isCalibrating: false,
    error: null
  }
};

const vehiclesMetersSlice = createSlice({
  name: 'vehiclesMeters',
  initialState: initialState,
  reducers: {
    fetchMetersStart(state, { payload }) {
      state.vehiclesMetersMeta[payload?.id] = {
        isFetching: true,
        error: null
      };
    },
    fetchMetersSuccess(state, { payload }) {
      state.meters[payload?.id] = payload.meters;
      state.vehiclesMetersMeta[payload?.id] = {
        isFetching: false,
        error: null
      };
    },
    fetchMetersFailure(state, { payload }) {
      state.vehiclesMetersMeta[payload?.id] = {
        isFetching: false,
        error: payload.error
      };
      state.meters[payload?.id] = [];
    },
    fetchVehiclesMetersStart(state, { payload }) {
      state.vehiclesMetersMeta[payload?.id] = {
        isFetching: true,
        error: null
      };
    },
    fetchVehiclesMetersSuccess(state, { payload }) {
      state.vehiclesMetersMeta[payload?.id] = {
        isFetching: false,
        error: null
      };
      state.meters[payload?.id] = payload.meters;
      state.meta.companyKey = payload.companyKey;
    },
    fetchVehiclesMetersFailure(state, { payload }) {
      state.vehiclesMetersMeta[payload?.id] = {
        isFetching: false,
        error: payload.error
      };
      state.meters[payload?.id] = [];
      state.meta.companyKey = payload.companyKey;
    },
    resetVehiclesMeters(state, { payload }) {
      state.vehiclesMetersMeta = {};
      state.meters = {};
    },
    vehicleCalibrateStart(state, { payload }) {
      state.vehicleCalibrateMeta = {
        isCalibrating: true,
        error: null,
        vehicleId: payload.vehicleId,
        meterType: payload.meterType
      };
    },
    vehicleCalibrateSuccess(state, { payload }) {
      state.vehicleCalibrateMeta = {
        isCalibrating: false,
        error: null,
        vehicleId: payload.vehicleId,
        meterType: payload.meterType
      };
    },
    vehicleCalibrateFailure(state, { payload }) {
      state.vehicleCalibrateMeta = {
        isCalibrating: false,
        error: payload.error,
        vehicleId: payload.vehicleId,
        meterType: payload.meterType
      };
    }
  }
});

const {
  fetchMetersStart,
  fetchMetersSuccess,
  fetchMetersFailure,
  resetVehiclesMeters,
  fetchVehiclesMetersStart,
  fetchVehiclesMetersSuccess,
  fetchVehiclesMetersFailure,
  vehicleCalibrateStart,
  vehicleCalibrateSuccess,
  vehicleCalibrateFailure
} = vehiclesMetersSlice.actions;

export const fetchVehicleMeters = (id, userKey, onError, onNotFound) => async (
  dispatch,
  getState
) => {
  try {
    if (!id || !userKey) {
      return;
    }
    dispatch(fetchMetersStart({ id }));

    const res = await api.get(`/vehicles/${id}/meters`, { authKey: userKey });
    if (res.status === 200) {
      dispatch(fetchMetersSuccess({ meters: res.body, id }));
    } else {
      console.log(res);
      if (onNotFound) {
        onNotFound();
      }
    }
  } catch (err) {
    batch(() => {
      dispatch(fetchMetersFailure({ err: err.toString(), id }));
      if (onError) {
        onError(t_error(err.response?.body || err.toString()));
      }
    });
  }
};

export const fetchVehiclesMeters = (ids, force) => async (dispatch, getState) => {
  try {
    const userKey = getState().user.current.auth.key;
    const companyKey = getState().companies.current.api_key;
    if (!userKey || !companyKey) {
      return;
    }
    ids = force
      ? ids
      : ids.filter(
          id =>
            !getState().vehiclesMeters.vehiclesMetersMeta[id] &&
            !getState().vehiclesMeters.vehiclesMetersMeta[id]?.isFetching
        );
    if (force) {
      dispatch(resetVehiclesMeters());
    }
    ids.map(id => dispatch(fetchVehiclesMetersStart({ id })));
    await Promise.all(
      ids.map(id =>
        api
          .get(`/vehicles/${id}/meters`, { authKey: userKey })
          .then(res => {
            if (res?.status === 200) {
              dispatch(fetchVehiclesMetersSuccess({ meters: res.body, id, companyKey }));
            } else {
              dispatch(fetchVehiclesMetersSuccess({ meters: [], id, companyKey }));
            }
          })
          .catch(err => {
            dispatch(fetchVehiclesMetersFailure({ err: err.toString(), id, companyKey }));
          })
      )
    );
  } catch (err) {}
};

export const useIsCalibratingVehicleEngineHours = () => {
  const meta = useSelector(state => state.vehiclesMeters.vehicleCalibrateMeta);
  return meta.meterType === METER_TYPES.HOURS && meta.isCalibrating;
};

export const useIsCalibratingVehicleOdometer = vehicleId => {
  const meta = useSelector(state => state.vehiclesMeters.vehicleCalibrateMeta);
  return meta.meterType === METER_TYPES.ODOMETER && meta.isCalibrating;
};

export const calibrateVehicleMeter = (vehicleId, meterType, syncISODateTime, value) => async (
  dispatch,
  getState
) => {
  const vehicleRebase = {
      meter: meterType,
      rebaseAt: syncISODateTime,
      base: value
    },
    vehicleUpdate = {
      [meterType === METER_TYPES.HOURS ? 'engineHours' : meterType]: value,
      readingsOn: syncISODateTime
    };
  try {
    dispatch(vehicleCalibrateStart({ meterType, vehicleId }));
    const authKey = getState().user.current.auth.key;
    return Promise.all([
      api
        .post(`/vehicles/${vehicleId}/rebase`, { authKey }, vehicleRebase)
        .then(res => +res?.status === 200)
        .catch(err => false),
      api
        .put(`/vehicles/${vehicleId}`, { authKey }, vehicleUpdate)
        .then(res => +res?.status === 200)
        .catch(err => false)
    ]).then(([vehicleRebased, vehicleUpdated]) => {
      dispatch(vehicleCalibrateSuccess({ meterType, vehicleId }));
      return vehicleRebased && vehicleUpdated;
    });
  } catch (err) {
    dispatch(vehicleCalibrateFailure({ error: err, meterType, vehicleId }));
    return false;
  }
};

export const setVehicleMeterSource = async (
  vehicleId,
  deviceId,
  deviceSource,
  userKey,
  sourceHours,
  calculatedByHours,
  calculatedByOdometer,
  hasOdometerCanMeter,
  toggleRucOdometerForMnt
) => {
  const sh = sourceHours?.split(';') || [];
  const deviceEngineHours = sh[0];
  const sourceEngineHours = sh[1];
  let body = [
    {
      vehicle: {
        id: vehicleId
      },
      device: {
        id: deviceId
      },
      type: METER_TYPES.ODOMETER,
      source: deviceSource,
      useDifferential: calculatedByOdometer === 'elapsed'
    },
    {
      vehicle: {
        id: vehicleId
      },
      device: {
        id: Number(deviceEngineHours)
      },
      type: METER_TYPES.HOURS,
      source: sourceEngineHours,
      useDifferential: calculatedByHours === 'elapsed'
    }
  ];
  body = body.filter(meter => !!meter.device.id);
  let engineSummarySource;
  switch (deviceSource) {
    case 'GPS':
      engineSummarySource = 'GPS_DIFF';
      break;
    case 'CAN':
      if (calculatedByOdometer === 'actual') {
        engineSummarySource = 'CAN';
      } else {
        engineSummarySource = 'CAN_DIFF';
      }
      break;
    default:
      if (hasOdometerCanMeter) {
        engineSummarySource = 'CAN';
      } else {
        engineSummarySource = 'GPS_DIFF';
      }
      break;
  }
  if (deviceSource?.indexOf('GPS') > -1) {
    engineSummarySource = 'GPS_DIFF';
  } else if (calculatedByOdometer === 'actual') {
    engineSummarySource = 'CAN';
  } else {
    engineSummarySource = 'CAN_DIFF';
  }
  const bodyVehicle = {
    engineSummarySource
  };
  try {
    const setMeters = await api.post(
      `/vehicles/${vehicleId}/meters`,
      {
        authKey: userKey
      },
      body
    );
    const setVehicle = await api.put(
      `/vehicles/${vehicleId}`,
      {
        authKey: userKey
      },
      bodyVehicle
    );

    let setConfig = true;
    if (toggleRucOdometerForMnt) {
      setConfig = await api
        .put(
          `/vehicles/${vehicleId}/config`,
          {
            authKey: userKey
          },
          {
            key: VehicleConfig.OdometerForMnt.key,
            value: toggleRucOdometerForMnt.value ? 'RUC' : 'false'
          }
        )
        .then(response => response?.status === 200)
        .catch(err => err?.rawResponse === 'Success');
    }
    if (setMeters.status === 200 && setVehicle.status === 200 && setConfig) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

export const useVehicleMeters = (id, onError, onNotFound) => {
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const meters = useSelector(state => state.vehiclesMeters.meters[id]);
  const isFetching = useSelector(state => state.vehiclesMeters.vehiclesMetersMeta[id]?.isFetching);
  const isError = useSelector(state => state.vehiclesMeters.vehiclesMetersMeta[id]?.error);

  const parsedId = parseInt(id);

  if (
    parsedId > 0 &&
    !isNaN(parsedId) &&
    !isFetching &&
    (!meters || Object.keys(meters).length === 0) &&
    !(meters?.length === 0) &&
    !isError
  ) {
    dispatch(fetchVehicleMeters(id, userKey, onError, onNotFound));
  }

  return meters;
};

export const useCompanyKey = () => useSelector(state => state.vehiclesMeters.meta.companyKey);
const useIsCompanyKeyDifferent = () => useCompanyKey() !== useCurrentCompanyKey();

export const useVehiclesMeters = ids => {
  const dispatch = useDispatch();
  const meters = useSelector(state => state.vehiclesMeters.meters);
  const vehiclesMetersMeta = useSelector(state => state.vehiclesMeters.vehiclesMetersMeta);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();

  const vehiclesMeters = useMemo(() => {
    return ids
      ?.map(id => ({ id, meters: meters[id] }))
      ?.reduce((a, c) => ({ ...a, [c.id]: c.meters }), {});
  }, [ids, meters]);

  const vehicleNeedMeters = useCallback(
    id => {
      return !meters[id] && !vehiclesMetersMeta[id];
    },
    [meters, vehiclesMetersMeta]
  );

  const vehicleIdsNeedMeters = useMemo(() => {
    return ids.filter(vehicleNeedMeters);
  }, [ids, meters, vehicleNeedMeters]);

  if (vehicleIdsNeedMeters?.length) {
    dispatch(fetchVehiclesMeters(ids, isCompanyKeyDifferent));
  }

  const isFetching = useMemo(() => {
    return (
      ids &&
      !!ids.length &&
      ids.some(id => vehiclesMetersMeta[id] && vehiclesMetersMeta[id].isFetching)
    );
  }, [ids, vehiclesMetersMeta]);

  return { vehiclesMeters, isFetching };
};

export default vehiclesMetersSlice.reducer;
