import { useEffect, useState, useCallback } from 'react';
import { saleforceStatus, saleforceStyle } from './saleforceConfig';
import { useAllRegions } from 'features/regions/regionsSlice';
import { useUser } from 'features/user/userSlice';
import { useActiveBrand } from 'features/brands/useActiveBrand';

const valNodes = [
  'input.SuppliedName',
  'input.SuppliedEmail',
  'input.SuppliedCompany',
  'input.SuppliedPhone',
  'input.Subject'
];

export function useSaleforce(btnRef) {
  const StyleNodeId = 'saleforce_styles';
  const NodeId = 'saleforce_hook';
  const regions = useAllRegions();
  const user = useUser();
  const [config, setConfig] = useState(null);
  const [status, setStatus] = useState(saleforceStatus.Init);
  const brand = useActiveBrand();

  const handleOpenSaleforceChat = useCallback(() => {
    const $saleforceEmbedBtn = document.querySelector(
      '.embeddedServiceHelpButton .helpButtonEnabled'
    );
    if ($saleforceEmbedBtn) {
      $saleforceEmbedBtn.click();
    }
  }, []);

  const handleReOpenSaleforceChat = useCallback(() => {
    const saleforceMinButton = document.querySelector('.minButton.cChatSalesSupport');
    if (saleforceMinButton) {
      saleforceMinButton.click();
    }
  }, []);

  const handleSaleforceBeforeUnload = useCallback(() => {}, []);

  const handleSaleforceUnload = useCallback(() => {}, []);

  const handleSaleforceSidebarDestroyed = useCallback(() => {
    if (btnRef?.current) {
      btnRef.current.formData = null;
      btnRef.current.style.display = 'flex';
      btnRef.current.removeEventListener('click', handleOpenSaleforceChat);
      btnRef.current.removeEventListener('click', handleReOpenSaleforceChat);
      btnRef.current.addEventListener('click', handleOpenSaleforceChat);
    }
  }, [btnRef, handleOpenSaleforceChat, handleReOpenSaleforceChat]);

  const handleAgentMessage = useCallback(data => {
    console.log(data);
    if (window.onAgentMessage) {
      window.onAgentMessage(data);
    }
  }, []);

  const handleSaleforceReady = useCallback(() => {
    setStatus(saleforceStatus.Running);
  }, []);

  const handleSaleforeBeforeCreate = useCallback(() => {}, []);

  const handleSaleforceFeatureLoaded = useCallback(() => {
    if (btnRef?.current) {
      btnRef.current.style.display = 'flex';
    }
    const $saleforceEmbedBtnContainer = document.querySelector('.embeddedServiceHelpButton');
    // this loads two times
    const helpButtonContainer = document.getElementsByClassName('helpButton')[0];
    if (!helpButtonContainer) {
      return;
    } else {
      $saleforceEmbedBtnContainer.style.display = 'none';
      if (btnRef?.current) {
        btnRef.current.addEventListener('click', handleOpenSaleforceChat);
      }
    }
  }, []);

  const handleScriptLoaded = useCallback(() => {
    if (btnRef?.current) {
      btnRef.current.style.display = 'flex';
    }
    window.embedded_svc.settings.displayHelpButton = true; //Or false
    window.embedded_svc.settings.language = ''; //For example, enter 'en' or 'en-US'

    // Settings for Chat
    window.embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
      // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
      // Returns a valid button ID.
      switch (prechatFormData[3].value) {
        case 'Support':
          return config.SFchatFormSupport; //"5732g00000000VQ";
        case 'Sales':
          return config.SFchatFormSales;
        default:
          return '';
      }
    };
    /*** set field online prepopulation start***/
    window.embedded_svc.settings.prepopulatedPrechatFields = {
      FirstName: user.firstName,
      LastName: user.lastName,
      Email: user.email
    }; /*** set field online prepopulation end***/
    //Sets the auto-population of pre-chat form fields

    //Connect to existing Contact or create new if none are found
    window.embedded_svc.settings.defaultMinimizedText = ' ';
    window.embedded_svc.settings.disabledMinimizedText = ' ';
    window.embedded_svc.settings.offlineSupportMinimizedText = ' ';
    window.embedded_svc.settings.extraPrechatInfo = [
      {
        entityFieldMaps: [
          {
            doCreate: true,
            doFind: true,
            fieldName: 'LastName',
            isExactMatch: true,
            label: 'Last Name'
          },
          {
            doCreate: true,
            doFind: true,
            fieldName: 'FirstName',
            isExactMatch: true,
            label: 'First Name'
          },
          {
            doCreate: true,
            doFind: true,
            fieldName: 'Email',
            isExactMatch: true,
            label: 'Email'
          }
        ],
        entityName: 'Contact'
      }
    ];

    window.embedded_svc.addEventHandler('beforeunload', handleSaleforceBeforeUnload);
    window.embedded_svc.addEventHandler('unload', handleSaleforceUnload);
    window.embedded_svc.addEventHandler('ready', handleSaleforceReady);
    window.embedded_svc.addEventHandler('featureLoaded', handleSaleforceFeatureLoaded);
    window.embedded_svc.addEventHandler('beforeCreate', handleSaleforeBeforeCreate);
    window.embedded_svc.addEventHandler('afterMaximize', handleSaleforceAfterMaximize);
    window.embedded_svc.addEventHandler('afterDestroy', handleSaleforceSidebarDestroyed);
    window.embedded_svc.addEventHandler('onAgentMessage', handleAgentMessage);

    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';
    window.embedded_svc.init(
      config.svcFirstParam, //'https://teletracnavman--TNStaging.my.salesforce.com',
      config.svcSecondParam, //https://tnstaging-teletracnavman.cs35.force.com/customercommunity',
      config.SFServiceURL,
      config.svcThirdParam, //'00D2g0000000Xap',
      config.svcFourthParam, //'Customer_Support_Sales',
      {
        baseLiveAgentContentURL: config.liveAgentURL, //'https://c.la3-c1cs-ph2.salesforceliveagent.com/content',
        deploymentId: config.chatDeploymentID, //'5722g00000000VB',
        buttonId: config.buttonId || config.buttonID, //'5732g00000000VL',
        baseLiveAgentURL: config.liveAgentChatURL, //'https://d.la3-c1cs-ph2.salesforceliveagent.com/chat',
        eswLiveAgentDevName: config.eswLiveAgentDevname, //'EmbeddedServiceLiveAgent_Parent04I2g0000004CCREA2_17035a97ac7',
        isOfflineSupportEnabled: true
      }
    );

    //availability checking
    if (!window._laq) {
      window._laq = [];
    }
    window._laq.push(function() {
      window.liveagent.showWhenOnline(
        config.SFchatFormSupport,
        document.getElementById('liveagent_button_online_' + config.SFchatFormSupport)
      );
      window.liveagent.showWhenOffline(
        config.SFchatFormSupport,
        document.getElementById('liveagent_button_offline_' + config.SFchatFormSupport)
      );
    });

    if (window.liveagent) {
      window.liveagent.init(config.liveAgentChatURL, config.chatDeploymentID, config.svcThirdParam);
      window.liveagent.addButtonEventHandler(config.SFchatFormSupport, e => {
        if (e === window.liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
          //support agent online
          window.embedded_svc.settings.buttonId = config.SFchatFormSupport;
        } else if (e === window.liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE) {
          //default to checking sales agent availability
          if (config.SFchatFormSales) {
            window.embedded_svc.settings.buttonId = config.SFchatFormSales;
          }
        }
        window.hasRun = true;
      });
    }
  }, [user, config]);

  const handleSaleforceAfterMaximize = useCallback(() => {
    setStatus(saleforceStatus.Opened);
    if (btnRef?.current) {
      btnRef.current.style.display = 'none';
    }
    let sideBar = document.querySelector('embeddedservice-chat-header');
    if (!sideBar) {
      sideBar = document.querySelector('.dockableContainer');
    }
    if (sideBar) {
      let btns = sideBar.getElementsByClassName('minimizeButton');
      if (btns.length > 0) {
        const saleforceMinimizeBtn = btns[0];
        if (!saleforceMinimizeBtn.getAttribute('attachedMinimizeEvent')) {
          saleforceMinimizeBtn.setAttribute('attachedMinimizeEvent', true);

          saleforceMinimizeBtn.addEventListener('click', () => {
            btnRef.current.style.display = 'none';
            btnRef.current.formData = {};
            valNodes.forEach(n => {
              const node = document.querySelector(n);
              if (node) {
                const propName = n.split('.')[1];
                btnRef.current.formData[propName] = node.value;
              }
            });

            btnRef.current.removeEventListener('click', handleOpenSaleforceChat);
            btnRef.current.removeEventListener('click', handleReOpenSaleforceChat);
            btnRef.current.addEventListener('click', handleReOpenSaleforceChat);
            setStatus(saleforceStatus.Running);
          });
        }
      }
      btns = sideBar.getElementsByClassName('closeButton');
      if (btns.length > 0) {
        const saleforceCloseBtn = btns[0];
        if (!saleforceCloseBtn.getAttribute('attachedCloseEvent')) {
          saleforceCloseBtn.setAttribute('attachedCloseEvent', true);
          saleforceCloseBtn.addEventListener('click', () => {
            btnRef.current.style.display = 'flex';
            btnRef.current.removeEventListener('click', handleOpenSaleforceChat);
            btnRef.current.removeEventListener('click', handleReOpenSaleforceChat);
            btnRef.current.addEventListener('click', handleOpenSaleforceChat);
            setStatus(saleforceStatus.Running);
          });
        }
      }

      btns = sideBar.getElementsByClassName('dialog-button-0');
      if (btns.length > 0) {
        const saleforceDoneBtn = btns[0];
        if (!saleforceDoneBtn.getAttribute('attachedDoneEvent')) {
          saleforceDoneBtn.setAttribute('attachedDoneEvent', true);
          saleforceDoneBtn.addEventListener('click', () => {
            btnRef.current.style.display = 'flex';
            btnRef.current.removeEventListener('click', handleOpenSaleforceChat);
            btnRef.current.removeEventListener('click', handleReOpenSaleforceChat);
            btnRef.current.addEventListener('click', handleOpenSaleforceChat);
            setStatus(saleforceStatus.Running);
          });
        }
      }

      const chatOptions = document.querySelector('.chatOptions');
      if (chatOptions) {
        chatOptions.addEventListener('click', () => {
          btnRef.current.style.display = 'flex';
        });
      }
    } else {
      //non active agent scenario
    }

    let consentNode = document.querySelector('.consent');
    if (consentNode) {
      consentNode.parentNode.removeChild(consentNode);
    }
    const consentSection = document.createElement('div');
    consentSection.classList.add('consent');
    consentSection.innerHTML = `The personal data collected in this form will be used by Teletrac Navman \
to provide you with information relating to your enquiry. \
By submitting this form, you agree that this personal data will be processed in accordance with our
<a target='_blank' class='consentlink' href='${config.consentLink}'> Privacy Notice </a>`;
    /*** set field UI***/
    //rename fields
    const isOfflineChat = document.getElementsByClassName('offlineSupportUI').length > 0;
    const fieldList = document.getElementsByClassName('fieldList')[0];
    if (fieldList && isOfflineChat) {
      const mapFieldAndLabel = [
        { label: 'SuppliedName', value: 'Name' },
        { label: 'SuppliedEmail', value: 'Email Address' },
        { label: 'SuppliedPhone', value: 'Contact Number' },
        { label: 'SuppliedCompany', value: 'Company Name' },
        { label: 'Subject', value: 'Subject of your inquiry' }
      ];
      mapFieldAndLabel.forEach(function(fld) {
        const field = fieldList.querySelectorAll("[for='" + fld.label + "'] span")[0];
        if (field) {
          field.innerText = fld.value;
        }
      });
      fieldList
        .querySelectorAll("[for='Chat_Assignment__c']")[0]
        .parentElement.setAttribute('style', 'display:none;');
      /*** set field events***/
      //set the behavior when inquiry field is changed
      const { extraFieldVal } = config;
      document.getElementById('Department_Offline_Chat__c').onchange = function() {
        const chatAssignment = fieldList.querySelectorAll('input.Chat_Assignment__c')[0];
        chatAssignment.value = extraFieldVal + ' - ' + this.value;
        fieldList.querySelectorAll('input.Chat_Assignment__c')[0].dispatchEvent(event);
      };
      /*** set field offline prepopulation start***/
      const event = new Event('change');
      if (btnRef.current.formData) {
        valNodes.forEach(n => {
          const propName = n.split('.')[1];
          const node = fieldList.querySelector(n);
          if (node) {
            node.value = btnRef.current.formData[propName];
            node.dispatchEvent(event);
          }
        });
      }

      /*** set field offline prepopulation end***/
      fieldList.querySelectorAll('input.Chat_Assignment__c')[0].value = config.chatAssignment[0]; // Do not update
      fieldList.querySelectorAll('input.Chat_Assignment__c')[0].dispatchEvent(event);

      fieldList.appendChild(consentSection);
    } else if (fieldList && !isOfflineChat) {
      //fieldList.appendChild(consentSection);
    }
  }, [btnRef, config]);

  useEffect(() => {
    if (btnRef?.current) {
      btnRef.current.style.display = 'none';
    }
  }, [btnRef]);

  useEffect(() => {
    if (!regions || !regions?.length || !user || !!brand?.name) {
      return;
    }

    if (!user.timeZone || !user.timeZone.trim()) {
      console.error("Can't find time zone information.");
      return;
    }

    const region = regions.find(r => r.timezone.includes(user.timeZone));
    if (!region?.config) {
      console.debug("Can't find region information.");
      return;
    }
    const regionConfig = JSON.parse(region.config);
    if (!regionConfig.salesforce_chat) {
      console.debug("Can't find saleforce chat config.");
      return;
    }
    setConfig(regionConfig.salesforce_chat);
  }, [brand, regions, user]);

  useEffect(() => {
    if (!config) {
      return;
    }

    if (status === saleforceStatus.Init) {
      setStatus(saleforceStatus.Loading);
      //create style node
      const styleNode = document.createElement('style');
      styleNode.id = StyleNodeId;
      styleNode.innerText = saleforceStyle;
      document.head.appendChild(styleNode);

      //create live chat script node
      const liveChatscriptNode = document.createElement('script');
      liveChatscriptNode.setAttribute('src', config.SFLiveChatScriptLink);
      document.head.appendChild(liveChatscriptNode);

      //create saleforce script node
      const scriptNode = document.createElement('script');
      scriptNode.setAttribute('src', config.SFInstanceESWscript);
      scriptNode.id = NodeId;
      scriptNode.addEventListener('load', handleScriptLoaded);
      document.head.appendChild(scriptNode);
    }
  }, [status, handleScriptLoaded, config]);
}
