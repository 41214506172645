import React from 'react';
import { useHistory } from 'react-router';
import { MoreMenu } from 'components/ant';
import { Paths, ColumnKeys, JOB_STATUS } from './utils/constants';
import { isRunsheetInProgress, isRunsheetFrozen } from './utils/helpers';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { handleRunsheetDeleteAction } from 'features/smartJobs/smartJobsSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCan } from 'features/permissions';

export const RunsheetActions = ({
  type,
  data,
  callbacks = { onEditJob: () => {}, onShareJob: () => {}, onDeleteJob: () => {} }
}) => {
  const can = useCan();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const deleteRunsheet = (id, name) => () => {
    dispatch(handleRunsheetDeleteAction(id, name, t));
  };
  const { job, runsheet } = data;
  const isJobComplete = [JOB_STATUS.COMPLETED, JOB_STATUS.CANCELLED].includes(
    job?.[ColumnKeys.STATUS]
  );
  const isFrozen = isRunsheetFrozen(runsheet);

  const items = {
    runsheet: [
      {
        name: t('Actions.ViewDetails'),
        onClick: () => {
          history.push(`${Paths.SMARTJOBS_VIEW}/${runsheet?.id}`, { id: runsheet?.id });
        },
        id: 'btn_smartJobView',
        entity: 'VIEW'
      },
      ...(!isFrozen
        ? [
            {
              name: t('Actions.Edit'),
              onClick: () => {
                history.push(`${Paths.EDIT_RUNSHEET}/${runsheet?.id}`, { runsheet: runsheet });
              },
              id: 'btn_smartJobEditS',
              entity: 'UPDATE'
            }
          ]
        : []),
      {
        name: t('Actions.Copy'),
        onClick: () => {
          history.push(`${Paths.COPY_RUNSHEET}/${runsheet?.id}`, { runsheet: runsheet });
        },
        edit: 'btn_smartJobCopy',
        entity: 'COPY'
      },
      ...(!isFrozen && !isRunsheetInProgress(runsheet)
        ? [
            {
              name: t('Actions.Allocate'),
              onClick: () => {
                callbacks.setRunsheetForAlloc(runsheet);
                callbacks.setAllocateModal(true);
              },
              id: 'btn_smartJobAllocateS',
              entity: 'UPDATE'
            }
          ]
        : []),
      ...(!isFrozen
        ? [
            {
              name: t('Actions.Delete'),
              danger: true,
              onClick: () => {
                confirmationModal(
                  t('SmartJobs.Confirmation.confirm'),
                  `${t('SmartJobs.Confirmation.deleteRunsheet')} ${runsheet?.name}?`,
                  t('SmartJobs.Confirmation.delete'),
                  t('SmartJobs.Confirmation.cancel'),
                  deleteRunsheet(runsheet?.id, runsheet?.name),
                  'delete'
                );
              },
              entity: 'DESTROY',
              id: 'btn_smartJobDeleteS'
            }
          ]
        : [])
    ],
    job: [
      // The second condition is for the scenario when a runsheet is created
      ...(!(isJobComplete || isFrozen) || !runsheet?.status
        ? [
            {
              name: t('Actions.Edit'),
              onClick: callbacks?.onEditJob(data?.job),
              id: 'btn_smartJobEdit',
              entity: 'UPDATE'
            }
          ]
        : []),
      callbacks?.onShareJob && {
        name: 'Share job',
        onClick: callbacks?.onShareJob(data?.runsheet, data?.job),
        id: 'btn_smartJobShare',
        entity: 'UPDATE'
      },
      // The second condition is for the scenario when a runsheet is created
      ...(!(isJobComplete || isFrozen) || !runsheet?.status
        ? [
            {
              name: t('Actions.Delete'),
              onClick: () => {
                confirmationModal(
                  t('SmartJobs.Confirmation.confirm'),
                  `${t('SmartJobs.Confirmation.deleteJob')} ${data?.job?.customerName}?`,
                  t('SmartJobs.Confirmation.delete'),
                  t('SmartJobs.Confirmation.cancel'),
                  () =>
                    callbacks?.onDeleteJob(data?.runsheet?.id, data?.job?.id, data?.job, dispatch),
                  'delete'
                );
              },
              id: 'btn_smartJobDelete',
              entity: 'DESTROY'
            }
          ]
        : [])
    ]
  };

  const allowedMenuItems = items[type].filter(item =>
    can({
      everyEntity: `${type.toUpperCase()}_${item?.entity}`,
      featureFlag: `${item?.featureFlag}`
    })
  );

  return <MoreMenu items={allowedMenuItems} useNewIcon={true} />;
};
