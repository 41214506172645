import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Fleets from './Fleets';
import FleetView from './FleetView';
import NewFleet from './FleetForm';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useDispatch } from 'react-redux';
import { Can, entities } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { PATHS } from './constants';

export const FleetsRouter = () => {
  const toastMessage = null;
  const dispatch = useDispatch();

  const handleToastMessage = (msg, type) => {
    dispatch(
      openToast({
        type: type || ToastType.Success,
        message: msg
      })
    );
  };

  return (
    <Can everyEntity={[entities.FLEET]} onFail={<NoAccessBody />}>
      <Switch>
        <Route exact path={PATHS.FLEET_ADD}>
          <Can everyEntity={[entities.FLEET_CREATE]} onFail={<NoAccessBody />}>
            <NewFleet setToastMessage={handleToastMessage} action="add" />
          </Can>
        </Route>
        <Route path={PATHS.FLEET_EDIT}>
          <Can everyEntity={[entities.FLEET_UPDATE]} onFail={<NoAccessBody />}>
            <NewFleet setToastMessage={handleToastMessage} action="edit" />
          </Can>
        </Route>
        <Route path={PATHS.FLEET_VIEW}>
          <Can everyEntity={[entities.FLEET_VIEW]} onFail={<NoAccessBody />}>
            <FleetView />
          </Can>
        </Route>
        <Route path={PATHS.FLEETS_DEFAULT}>
          <Fleets toastMessage={toastMessage} setToastMessage={msg => handleToastMessage(msg)} />
        </Route>
      </Switch>
    </Can>
  );
};
