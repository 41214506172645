import styled from '@emotion/styled';

import { rgba } from '../../static/js/styles';

import { Action as ActionButton } from '../buttons/Buttons.style';

export const TopActions = styled.header`
  color: ${props => props.theme.topActions.color};
  background: ${props => props.theme.topActions.background};
  padding: 8px 16px;
  font-weight: 400;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  z-index: 10;
`;

export const LeftActions = styled.div`
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
`;

export const RightActions = styled(LeftActions)`
  order: 1;
`;

export const Title = styled.h1`
  font-weight: 400;
`;

export const FleetDropdown = styled(ActionButton)`
    background: transparent;
    color: ${props => props.theme.buttons.fleetDropdown.color};
    font-size: 14px;
    margin-left: 12px;
    width: initial;

    &:after{
        font-family: 'nextgen-ttn2';
        content: '\\e901';
        font-size: 20px;
        margin-left: 12px;
        color: ${props => props.theme.buttons.fleetDropdown.color};
    }

    &:focus, &:hover {
        box-shadow: none;
        background: transparent;
        color: ${props =>
          props.theme.buttons.fleetDropdown.color &&
          `rgba(${rgba(`${props.theme.buttons.fleetDropdown.color}`, 0.56)});`}

        &:after{
            color: ${props =>
              props.theme.buttons.fleetDropdown.color &&
              `rgba(${rgba(`${props.theme.buttons.fleetDropdown.color}`, 0.56)});`}
        }
    }
`;

export const IconButton = styled(ActionButton)`
  background: transparent;
  color: ${props => props.theme.topActions.color};
  font-size: 20px;
  margin-left: 12px;
  margin-right: 0;
  transition: color 0.2s ease-in-out;
  &:focus,
  &:hover {
    box-shadow: none;
    background: transparent;
    color: ${props =>
      props.theme.buttons.action.background &&
      `rgba(${rgba(`${props.theme.buttons.action.background}`, 0.3)});`};
  }
`;

export const BackButton = styled(IconButton)`
  margin: 0;
  margin-left: -8px;
  width: 24px;
  font-size: 24px;
`;

export const Profile = styled(IconButton)`
    position: relative;
    margin-left: 16px;


    &:before{
        position: absolute;
        content: ' ';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: ${props => props.theme.topActions.color};

        height: 28px;
        width: 28px;
        border-radius: 16px;

        transition: background .3s ease-in-out;
    }

    &:focus, &:hover {
        box-shadow: none;
        background: transparent;

        &:before {
            background: ${props =>
              props.theme.buttons.profile.background &&
              `rgba(${rgba(`${props.theme.buttons.profile.background}`, 0.3)});`}

        }
    }

    &:after{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        content: '${props => (props.initials ? `${props.initials}` : `TN`)}';
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
        border-radius: 12px;
    }
`;
