import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { useTranslation } from 'react-i18next';

import { Modal } from 'antd';
import moment from 'moment';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

import { TIMEOUTS } from './constants';

import { compatibleLogout } from '../../features/user/userSlice';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { Mixpanel, MPTrackingFunctions } from 'features/mixpanel';
import { useCan } from 'features/permissions';

const IdleTimerContainer = () => {
  const [modalIsShown, setModalIsShown] = useState(false);
  const [startCountdown, setStartCountdown] = useState(false);
  const [countdownRefreshInterval, setCountdownRefreshInterval] = useState(null);
  const [userPreferredTimeout, setUserPreferredTimeout] = useState();
  const [loggedOff, setLoggedOff] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const can = useCan();
  const [storageHandles] = useState(() => {
    const handleStorageChange = () => {
      const s_userPreferredTimeout = localStorage.getItem('userPreferredTimeout');
      const s_userActionTime = localStorage.getItem('userActionTime');
      const now = moment().format('X');

      if (now - s_userActionTime < s_userPreferredTimeout / 1000) {
        idleTimerRef.current && idleTimerRef.current.reset();
        staySignedInActionWrapper();
        Modal.destroyAll();
      }
    };

    const subscribeUserActionChange = () => {
      window.addEventListener('storage', handleStorageChange);
    };

    const unsubscribeUserActionChange = () => {
      window.removeEventListener('storage', handleStorageChange);
    };

    return {
      subscribeUserActionChange,
      unsubscribeUserActionChange
    };
  });

  const dispatch = useDispatch();
  const userPreferences = useUserPreferences();
  const { t } = useTranslation();

  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);

  const getModalContentMessage = () => {
    if (minutes === 1) {
      return t('Common.IdleMinuteMessage');
    }
    if (minutes === 0 && seconds === 1) {
      return t('Common.IdleSecondMessage');
    }
    return minutes < 1
      ? t('Common.IdleSecondsMessage', {
          time: seconds
        })
      : t('Common.IdleMinutesMessage', {
          time: minutes
        });
  };

  const modalContentMessage = getModalContentMessage();

  const logoutActionWrapper = () => {
    setModalIsShown(false);
    setStartCountdown(false);

    Modal.destroyAll();

    clearTimeout(sessionTimeoutRef.current);
    dispatch(compatibleLogout(can));
    Mixpanel.sendToMixpanel(MPTrackingFunctions.timeoutLogOffTrack);
  };

  const staySignedInActionWrapper = () => {
    setModalIsShown(false);
    setStartCountdown(false);
    storageHandles.unsubscribeUserActionChange();

    clearTimeout(sessionTimeoutRef.current);
    setTimeout(() => {
      setMinutes(1);
      setSeconds(0);
    }, 1000);
  };

  useEffect(() => {
    handleUserOfflineInactivity();
  }, []);

  useEffect(() => {
    if (userPreferences?.refresh?.sessionTimeout) {
      setUserPreferredTimeout(userPreferences?.refresh?.sessionTimeout);
    }
    setModalIsShown(false);
    setStartCountdown(false);
    clearTimeout(sessionTimeoutRef.current);

    return () => {
      setModalIsShown(false);
      setStartCountdown(false);
      clearTimeout(sessionTimeoutRef.current);
      Modal.destroyAll();
      storageHandles.unsubscribeUserActionChange();
    };
  }, [userPreferences, storageHandles]);

  useEffect(() => {
    if (startCountdown) {
      let mins = minutes;
      let secs = seconds;
      setCountdownRefreshInterval(
        setInterval(() => {
          if (secs > 0) {
            setSeconds(secs - 1);
            secs = secs - 1;
          }
          if (secs === 0) {
            if (mins === 0) {
              clearInterval(countdownRefreshInterval);
            } else {
              setMinutes(mins - 1);
              setSeconds(59);
              mins = mins - 1;
              secs = 59;
            }
          }
        }, 1000)
      );
    } else {
      clearInterval(countdownRefreshInterval);
    }
  }, [startCountdown]);

  useEffect(() => {
    return () => clearInterval();
  }, []);

  useEffect(() => {
    if (loggedOff === true) {
      logoutActionWrapper();
      localStorage.setItem('loggedOff', false);
      setLoggedOff(false);
    }
  }, [loggedOff]);

  useEffect(() => {
    if (idleTimerRef?.current) {
      idleTimerRef.current.reset();
    }
  }, [userPreferredTimeout]);

  useEffect(() => {
    const x = document.querySelector('.idle-timer-container .ant-modal-confirm-content');
    if (x) {
      x.innerHTML = modalContentMessage;
    }
  }, [seconds]);

  const getIdleTime = () => {
    if (userPreferredTimeout === undefined || userPreferredTimeout === null) {
      return `${TIMEOUTS.DEFAULT / TIMEOUTS.MINUTE_IN_MILISECONDS} ${t('Common.minutes')}`;
    } else if (
      userPreferredTimeout / TIMEOUTS.MINUTE_IN_MILISECONDS <
      2 * TIMEOUTS.HOUR_IN_MINUTES
    ) {
      return `${
        userPreferredTimeout
          ? userPreferredTimeout / TIMEOUTS.MINUTE_IN_MILISECONDS
          : TIMEOUTS.DEFAULT / TIMEOUTS.MINUTE_IN_MILISECONDS
      } ${t('Common.minutes')}`;
    } else {
      return `${userPreferredTimeout / TIMEOUTS.HOUR_IN_MILISECONDS} ${t('Common.hours')}`;
    }
  };

  const resetLocalVariables = () => {
    localStorage.setItem('userActionTime', moment().format('X'));
    if (userPreferredTimeout) {
      localStorage.setItem('userPreferredTimeout', userPreferredTimeout);
    }
  };

  const handleUserOfflineInactivity = () => {
    const mostRecentActivityTime = localStorage.getItem('userActionTime');
    const localUserPref = localStorage.getItem('userPreferredTimeout');
    const now = moment().format('X');
    const idleLimit = localUserPref
      ? (+localUserPref + TIMEOUTS.MINUTE_IN_MILISECONDS) / 1000
      : (TIMEOUTS.DEFAULT + TIMEOUTS.MINUTE_IN_MILISECONDS) / 1000;

    now - mostRecentActivityTime >= idleLimit
      ? dispatch(compatibleLogout(can)) &&
        Mixpanel.sendToMixpanel(MPTrackingFunctions.timeoutLogOffTrack)
      : resetLocalVariables();
  };

  const onAction = () => {
    handleUserOfflineInactivity();
    setLoggedOff(JSON.parse(localStorage.getItem('loggedOff')));
  };

  const onIdle = () => {
    const userActionTime = localStorage.getItem('userActionTime');
    const now = moment().format('X');

    setLoggedOff(JSON.parse(localStorage.getItem('loggedOff')));

    if (now - userActionTime < userPreferredTimeout / 1000) {
      idleTimerRef.current && idleTimerRef.current.reset();
      if (modalIsShown) {
        staySignedInActionWrapper();
        Modal.destroyAll();
      }
      return;
    }

    if (!modalIsShown && now - userActionTime >= 5) {
      storageHandles.subscribeUserActionChange();
      setModalIsShown(true);
      confirmationModal(
        //`You have been idle for the past ${getIdleTime()}`,
        t('Common.Idle', { time: getIdleTime() }),
        modalContentMessage,
        t('Common.Logout'),
        t('Common.KeepSignedIn'),
        logoutActionWrapper,
        'primary',
        staySignedInActionWrapper,
        'idle-timer-container'
      );
      setStartCountdown(true);
      sessionTimeoutRef.current = setTimeout(logoutActionWrapper, TIMEOUTS.MINUTE_IN_MILISECONDS);
    }
  };

  return (
    <IdleTimer
      ref={idleTimerRef}
      timeout={userPreferredTimeout || TIMEOUTS.DEFAULT}
      key={userPreferredTimeout}
      onAction={onAction}
      onIdle={onIdle}
    />
  );
};

export default IdleTimerContainer;
