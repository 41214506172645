import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { AuditsTable } from 'components/auditsTable/AuditsTable';

import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useRoles } from 'features/roles/rolesSlice';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';
import { useAudits } from 'features/audits';

import { getIDFromPathname } from 'utils/methods';

import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { PATHS } from './constants';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

export const RoleAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const history = useHistory();
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.Default);
  }, [history]);
  const audits = useAudits(AUDIT_ENTITY.ROLE, id, handleFetchError);
  const roles = useRoles();
  useRedirectToMainFeaturePageOnCompanyChange('/settings/roles');
  const role = roles?.find(role => role.id === Number(id));
  const pageTitle = role
    ? t('Audits.AuditHistoryFor', { name: role?.name })
    : t('Audits.AuditHistory');

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    dispatch(setBackButton(true));
  }, [pageTitle, dispatch]);

  if (id <= 0 || isNaN(id) || (roles?.length > 0 && !role && !audits)) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: t('Common.Invalid Request ID')
      })
    );
    handleFetchError();
  }

  return <AuditsTable audits={audits} entity={AUDIT_ENTITY.ROLE} />;
};
