import React from 'react';
import { Link } from 'react-router-dom';

const _DailySnapshotsLink = ({ entityType, entityId, entityName, linkTitle }) =>
  entityType && entityId ? (
    <Link to={`/daily-snapshots/${entityType.toLowerCase()}/${entityId}`}>
      {linkTitle || entityName}
    </Link>
  ) : (
    <span>{entityName}</span>
  );

export const DailySnapshotsLink = React.memo(_DailySnapshotsLink);
