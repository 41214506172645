/* global google */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import moment from 'moment';

import { useTrackingData } from 'features/tracking/trackingSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useAllRegions } from 'features/regions/regionsSlice';
import { useUserKey, useUser } from 'features/user/userSlice';
import { openToast } from 'features/toasts/toastsSlice';
import useWithInGeofence from './ControlPanel/useLensSearch';
import { useMsgChannel } from 'utils/hooks/msgChannel';
import { Mode } from './ControlPanel/constants';
import {
  useUserPreferences,
  setUserPreferences,
  updatePreferences
} from 'features/user/userPreferencesSlice';

import { TrackingPage } from '../TrackingPage';
import { ShowNearestVehicleMap } from './ShowNearestVehicleMap';
import { ProximityControlPanel } from './ProximityControlPanel';
import { ToastType } from 'components/notifications/toasts/Toast';
import Map from 'components/map/Map';

import styles from './Proximity.module.scss';
import 'react-reflex/styles.css';

export const Proximity = () => {
  const trackingData = useTrackingData();
  const dispatch = useDispatch();
  const regions = useAllRegions();
  const currentCompany = useCurrentCompany();
  const wrapperRef = useRef(null);
  const { t } = useTranslation();
  const mapRefProximity = useRef();
  const msgChannel = useMsgChannel();
  const userPreferences = useUserPreferences();
  const user = useUser();
  const userKey = useUserKey();

  const [focusedDevice, setFocusedDevice] = useState(null);
  const [clickedDevice, setClickedDevice] = useState(null);
  const [regionGeoDetails, setRegionGeoDetails] = useState({});
  const [dateRange, setDateRange] = useState({
    from: moment()
      .startOf('day')
      .format(),
    to: moment()
      .endOf('day')
      .format()
  });
  const [mode, setMode] = useState(Mode.Lens);
  const { lensConfig, mapConfig, onClearSearch } = useWithInGeofence(
    trackingData.devices,
    trackingData.fleets,
    dateRange,
    wrapperRef,
    mode,
    google.maps.drawing.OverlayType.CIRCLE
  );

  const getRegionDetails = useCallback(() => {
    const region = regions.find(
      region => region?.code?.toLowerCase() === currentCompany?.country?.toLowerCase()
    );

    try {
      const geocode = JSON.parse(region?.geocode || '{}');
      setRegionGeoDetails(geocode);
    } catch (e) {
      console.error(e);
      setRegionGeoDetails({});
    }
  }, [regions, currentCompany]);

  useEffect(() => {
    dispatch(setPageTitle(t('Tracking.PageTitle')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  useEffect(() => {
    getRegionDetails();
  }, [regions, currentCompany]);

  const onMapTypeIdChanged = mapType => {
    const newPreferences = {
      ...userPreferences,
      mapType
    };
    setUserPreferences(newPreferences, user.id, userKey)
      .then(() => {
        dispatch(updatePreferences(newPreferences));
      })
      .catch(() => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: `${t('Preferences.Save.ErrorMessage')}.`
          })
        );
      });
  };

  const onDateRangeSelected = (startDate, endDate) => {
    setDateRange({
      from: startDate.toISOString(),
      to: endDate.toISOString()
    });
  };

  const onDeviceFocused = deviceId => {
    setFocusedDevice(deviceId);
  };

  const onDeviceBlurred = () => {
    setFocusedDevice(null);
  };

  const onDeviceClicked = deviceId => {
    setClickedDevice(deviceId !== clickedDevice ? deviceId : null);
  };

  const onDeviceSelected = device => {
    if (!device) {
      return;
    }
  };

  return (
    <TrackingPage>
      <ReflexContainer orientation="vertical" style={{ flex: '1 0 0', overflow: 'hidden' }}>
        <ReflexElement className="leftPane" flex={0.3}>
          <ProximityControlPanel
            mode={mode}
            lensConfig={{
              dateRange,
              onDateRangeChanged: onDateRangeSelected,
              ...lensConfig
            }}
            onChange={mode => {
              setMode(mode);
              onClearSearch();
            }}
            msgChannel={msgChannel}
            trackingDevices={trackingData.devices}
            trackingFleets={trackingData.fleets}
          />
        </ReflexElement>
        <ReflexSplitter />
        <ReflexElement className="rightPane">
          <div
            ref={wrapperRef}
            style={{ height: '100%', width: '100%' }}
            className={mode === Mode.NearestVehicle ? styles.noCenterPoint : ''}
          >
            {mode === Mode.Lens && (
              <Map
                ref={mapRefProximity}
                {...mapConfig}
                wrapperRef={wrapperRef}
                drawingOverlay={google.maps.drawing.OverlayType.CIRCLE}
                focusedDevice={focusedDevice}
                clickedDevice={clickedDevice}
                onDeviceFocused={onDeviceFocused}
                onDeviceBlurred={onDeviceBlurred}
                onDeviceClicked={onDeviceClicked}
                onDeviceSelected={onDeviceSelected}
                onMapTypeIdChanged={() =>
                  onMapTypeIdChanged(mapRefProximity.current.state.map.mapTypeId)
                }
              />
            )}

            {mode === Mode.NearestVehicle && (
              <ShowNearestVehicleMap regionGeoDetails={regionGeoDetails} msgChannel={msgChannel} />
            )}
          </div>
        </ReflexElement>
      </ReflexContainer>
    </TrackingPage>
  );
};
