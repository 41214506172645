/*
 * Edit Color Palette Here:
 * http://mcg.mbitson.com/#!?steel=%23969696&sapphire=%235893d8&aquamarine=%2342c3f0&emerald=%2326bd8b&tomato=%23da6161&tangerine=%23f5a623&topaz=%23f0c600&charcoal=%235f5f5f&brand=%2386b341&themename=geode
 *
 * Export as MATERIAL U NEXT (REACT)
 */

export const steel = {
  // 50: '#f7f8f9',
  50: '#f7f8f9',
  100: '#dadee3',
  200: '#cbcbcb',
  300: '#b6b6b6',
  400: '#a6a6a6',
  500: '#969696',
  600: '#8e8e8e',
  700: '#838383',
  800: '#64748b',
  900: '#686868',
  A100: '#fce5e5',
  A200: '#f7b7b7',
  A400: '#ff7c7c',
  A700: '#ff6363',
  contrastDefaultColor: 'dark'
};

export const sapphire = {
  50: '#ebf2fa',
  100: '#cddff3',
  200: '#acc9ec',
  300: '#8ab3e4',
  400: '#71a3de',
  500: '#1890ff',
  600: '#508bd4',
  700: '#4780ce',
  800: '#3d76c8',
  900: '#2d64bf',
  A100: '#ffffff',
  A200: '#cedfff',
  A400: '#9bbeff',
  A700: '#81aeff',
  contrastDefaultColor: 'dark'
};

export const aquamarine = {
  50: '#e8f8fd',
  100: '#c6edfb',
  200: '#a1e1f8',
  300: '#7bd5f5',
  400: '#5eccf2',
  500: '#42c3f0',
  600: '#3cbdee',
  700: '#33b5ec',
  800: '#2baee9',
  900: '#1da1e5',
  A100: '#ffffff',
  A200: '#e7f6ff',
  A400: '#b4e4ff',
  A700: '#9bdaff',
  contrastDefaultColor: 'dark'
};

export const emerald = {
  50: '#e5f7f1',
  100: '#beebdc',
  200: '#93dec5',
  300: '#67d1ae',
  400: '#47c79c',
  500: '#26bd8b',
  600: '#22b783',
  700: '#1cae78',
  800: '#17a66e',
  900: '#0d985b',
  A100: '#c8ffe4',
  A200: '#95ffcc',
  A400: '#62ffb3',
  A700: '#48ffa7',
  contrastDefaultColor: 'dark'
};

export const tomato = {
  50: '#fbecec',
  100: '#f4d0d0',
  200: '#edb0b0',
  300: '#e59090',
  400: '#e07979',
  500: '#ff4d4f',
  600: '#d65959',
  700: '#d04f4f',
  800: '#cb4545',
  900: '#c23333',
  A100: '#ffffff',
  A200: '#ffd6d6',
  A400: '#ffa3a3',
  A700: '#ff8a8a',
  contrastDefaultColor: 'dark'
};

export const tangerine = {
  50: '#fef4e5',
  100: '#fce4bd',
  200: '#fad391',
  300: '#f8c165',
  400: '#f7b344',
  500: '#f5a623',
  600: '#f49e1f',
  700: '#f2951a',
  800: '#f08b15',
  900: '#ee7b0c',
  A100: '#ffffff',
  A200: '#fff1e5',
  A400: '#ffd4b2',
  A700: '#ffc698',
  contrastDefaultColor: 'dark'
};

export const topaz = {
  50: '#fdf8e0',
  100: '#fbeeb3',
  200: '#f8e380',
  300: '#f5d74d',
  400: '#f2cf26',
  500: '#ffc53d',
  600: '#eec000',
  700: '#ecb900',
  800: '#e9b100',
  900: '#e5a400',
  A100: '#ffffff',
  A200: '#fff3d9',
  A400: '#ffe2a6',
  A700: '#ffda8c',
  contrastDefaultColor: 'dark'
};

export const charcoal = {
  50: '#ececec',
  100: '#cfcfcf',
  200: '#afafaf',
  300: '#8f8f8f',
  400: '#777777',
  500: '#515e71',
  600: '#575757',
  700: '#4d4d4d',
  800: '#434343',
  900: '#323232',
  A100: '#f39494',
  A200: '#ee6666',
  A400: '#ff2222',
  A700: '#ff0808',
  contrastDefaultColor: 'light'
};

export const brand = {
  50: '#f0f6e8',
  100: '#d6f3a9',
  200: '#c3d9a0',
  300: '#aaca7a',
  400: '#86b341',
  500: '#86b341',
  600: '#7eac3b',
  700: '#73a332',
  800: '#699a2a',
  900: '#568b1c',
  A100: '#e1ffc4',
  A200: '#c8ff91',
  A400: '#aeff5e',
  A700: '#a1ff45',
  contrastDefaultColor: 'dark'
};

export const white = '#ffffff';

export const black = '#231F20';

export const absoluteBlack = '#000000';
