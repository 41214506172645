import React from 'react';
import ReactExport from 'react-data-export';
import i18n from 'i18next';
import { Button } from 'antd';
import { BUTTON_IDS } from 'utils/globalConstants';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const formatUsersForExcelExport = (fleets, companies) => {
  const cellStyle = {
    font: {
      bold: true
    },
    border: {
      top: { style: 'thin', color: '#000' },
      right: { style: 'thin', color: '#000' },
      bottom: { style: 'thin', color: '#000' },
      left: { style: 'thin', color: '#000' }
    }
  };
  const fleetsDataSet = [
    {
      columns: [
        {
          title: `${i18n.t('ExportToExcel.Name')}`,
          width: { wch: 20 },
          style: { font: cellStyle.font, border: cellStyle.border }
        },
        {
          title: `${i18n.t('ExportToExcel.Company')}`,
          width: { wch: 20 },
          style: { font: cellStyle.font, border: cellStyle.border }
        },
        {
          title: `${i18n.t('ExportToExcel.Description')}`,
          width: { wch: 35 },
          style: { font: cellStyle.font, border: cellStyle.border }
        },
        {
          title: `${i18n.t('ExportToExcel.Colour')}`,
          width: { wch: 15 },
          style: { font: cellStyle.font, border: cellStyle.border }
        },
        {
          title: `${i18n.t('ExportToExcel.IconType')}`,
          width: { wch: 20 },
          style: { font: cellStyle.font, border: cellStyle.border }
        }
      ],
      data: []
    }
  ];

  const rows = fleets.map(fleet => {
    const company = companies.find(comp => fleet.company && comp.id === fleet.company.id);
    return [
      { value: `${fleet.name || ''}`, style: { border: cellStyle.border } },
      { value: company ? company.name : '', style: { border: cellStyle.border } },
      { value: `${fleet.description || ''}`, style: { border: cellStyle.border } },
      {
        value: `${fleet.colour || ''}`,
        style: {
          fill: {
            patternType: 'solid',
            fgColor: {
              rgb: `FF${
                fleet.colour && fleet.colour.length === 7 ? fleet.colour.substring(1, 7) : 'FFFFFF'
              }`
            }
          },
          border: cellStyle.border
        }
      },
      { value: `${fleet.iconType || ''}`, style: { border: cellStyle.border } }
    ];
  });
  fleetsDataSet[0].data = rows;
  return fleetsDataSet;
};

const _ExportToExcel = props => {
  return (
    <ExcelFile
      element={
        <Button
          type="default"
          size="large"
          id={BUTTON_IDS.fleetsExportExcel}
          onClick={() => {
            document.body.click();
          }}
        >
          {i18n.t('Common.ExporttoExcel')}
        </Button>
      }
      filename={i18n.t('Fleets.Fleets')}
    >
      <ExcelSheet
        dataSet={formatUsersForExcelExport(props.data, props.companies, props.locations)}
        name="Fleets"
      />
    </ExcelFile>
  );
};

export const ExportToExcel = React.memo(_ExportToExcel);
