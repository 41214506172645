import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useCallback, useMemo } from 'react';
import request from 'superagent';
import { API_PATH } from '../../config';
import {
  DEVICE_DYNAMIC_LINKED_VEHICLE,
  useFleetsWithDynamicLinkVehicleDevice,
  VEHICLE_DYNAMIC_LINKED_DEVICE
} from 'features/vehicles/hooks';
import { useUsers, useIsFetching as useIsDriverFetching } from 'features/users/usersSlice';
import {
  todayDateRange,
  categoryMessagesByChatEnitity,
  getCurrentUserMessagingPermissions,
  flattenChildrenMsgsOfGroupMessageEntity,
  feedMessages,
  messageCanMarkAsRead
} from 'containers/Messaging/helpers';
import {
  MESSAGE_DELETE_MODE,
  MESSAGE_TYPE,
  RECIPIENT_TYPE,
  STORE_DATE_FORMAT
} from 'containers/Messaging/constants';
import { parseErrorMessage } from 'utils/strings';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { fetchDevicesStatsSuccess } from 'features/devices/devicesStatsSlice';

const actionTypes = {
  init: 0,
  processing: 1,
  error: 2,
  done: 3
};

const messagesInitialState = {
  list: [],
  dateRange: {
    from: todayDateRange.from,
    to: todayDateRange.to
  },
  status: {
    get: {
      fetching: actionTypes.init,
      error: null
    },
    getTemplates: {
      fetching: actionTypes.init,
      error: null
    },
    getHistoricalMessagesByEntity: {
      fetching: actionTypes.init,
      error: null
    },
    getUnreadMessagesCount: {
      fetching: actionTypes.init,
      companyId: null
    },
    deleteTemplate: {
      fetching: actionTypes.init,
      error: null
    },
    submitTemplate: {
      fetching: actionTypes.init,
      error: null
    },
    postMessage: {
      fetching: actionTypes.init,
      error: null
    },
    deleteMessages: {
      fetching: actionTypes.init,
      error: null
    },
    chatting: {
      fetching: actionTypes.init,
      error: null
    }
  },
  templates: [],
  chatBox: {
    chatEntity: {
      chatEntityId: null,
      chatEntityType: null,
      chatEntityName: null
    },
    historicalMessages: []
  },
  unreadMessagesMeta: {}
};

const messagngSlice = createSlice({
  initialState: messagesInitialState,
  name: 'messages',
  reducers: {
    fetchMessagesByDateRangeStart: (state, action) => {
      state.status.get.fetching = actionTypes.processing;
    },
    fetchMessagesByDateRangeSucceeded: (state, action) => {
      state.list = action.payload;
      state.status.get.fetching = actionTypes.done;
    },
    fetchMessagesByDateRangeFailed: (state, action) => {
      state.status.get.fetching = actionTypes.error;
      state.status.get.error = action.payload;
    },
    fetchUnreadMessagesCountStart: (state, { payload }) => {
      state.status.getUnreadMessagesCount.fetching = actionTypes.processing;
      state.status.getUnreadMessagesCount.companyId = payload.companyId;
    },
    fetchUnreadMessagesCountFinished: (state, { payload }) => {
      state.status.getUnreadMessagesCount.fetching = actionTypes.done;
      state.status.getUnreadMessagesCount.companyId = payload.companyId;
    },
    setDateRangeSelected: (state, action) => {
      state.dateRange = action.payload.dateRange;
    },
    setInitailSubmitMessageTemplateStatus: (state, action) => {
      state.status.submitTemplate.fetching = actionTypes.init;
      state.status.submitTemplate.error = null;
    },
    setInitailDeleteMessageTemplateStatus: (state, action) => {
      state.status.deleteTemplate.fetching = actionTypes.init;
      state.status.deleteTemplate.error = null;
    },
    setInitailDeleteMessagesStatus: (state, action) => {
      state.status.deleteMessages.fetching = actionTypes.init;
      state.status.deleteMessages.error = null;
    },
    setInitailPostMessageStatus: (state, action) => {
      state.status.postMessage.fetching = actionTypes.init;
      state.status.postMessage.error = null;
    },
    setInitailChattingStatus: (state, action) => {
      state.status.chatting.fetching = actionTypes.init;
      state.status.chatting.error = null;
    },
    fetchMessageTemplatesStart: (state, action) => {
      state.status.getTemplates.fetching = actionTypes.processing;
    },
    fetchMessageTemplatesSucceeded: (state, action) => {
      const sortFunc = (tempA, tempB) => {
        const timeA = new Date(tempA?.updatedAt).getTime();
        const timeB = new Date(tempB?.updatedAt).getTime();
        return timeB - timeA;
      };
      state.templates = (action.payload || []).sort(sortFunc);
      state.status.getTemplates.fetching = actionTypes.done;
    },
    fetchMessageTemplatesFailed: (state, action) => {
      state.status.getTemplates.fetching = actionTypes.error;
      state.status.getTemplates.error = action.payload;
    },
    fetchHistoricalMessagesByEntityStart: (state, action) => {
      state.status.getHistoricalMessagesByEntity.fetching = actionTypes.processing;
    },
    fetchHistoricalMessagesByEntitySucceeded: (state, action) => {
      state.chatBox.historicalMessages = action.payload;
      state.status.getHistoricalMessagesByEntity.fetching = actionTypes.done;
    },
    fetchHistoricalMessagesByEntityFailed: (state, action) => {
      state.status.getHistoricalMessagesByEntity.fetching = actionTypes.error;
      state.status.getHistoricalMessagesByEntity.error = action.payload;
    },
    deleteMessageTemplateStart: (state, action) => {
      state.status.deleteTemplate.fetching = actionTypes.processing;
    },
    deleteMessageTemplateSucceeded: (state, action) => {
      state.status.deleteTemplate.fetching = actionTypes.done;
    },
    deleteMessageTemplateFailed: (state, action) => {
      state.status.deleteTemplate.fetching = actionTypes.error;
      state.status.deleteTemplate.error = action.payload;
    },
    deleteMessagesStart: (state, action) => {
      state.status.deleteMessages.fetching = actionTypes.processing;
    },
    deleteMessagesSucceeded: (state, action) => {
      state.status.deleteMessages.fetching = actionTypes.done;
    },
    deleteMessagesFailed: (state, action) => {
      state.status.deleteMessages.fetching = actionTypes.error;
      state.status.deleteMessages.error = action.payload;
    },
    submitMessageTemplateStart: (state, action) => {
      state.status.submitTemplate.fetching = actionTypes.processing;
    },
    submitMessageTemplateSucceeded: (state, action) => {
      state.status.submitTemplate.fetching = actionTypes.done;
    },
    submitMessageTemplateFailed: (state, action) => {
      state.status.submitTemplate.fetching = actionTypes.error;
      state.status.submitTemplate.error = action.payload;
    },
    postMessageStart: (state, action) => {
      state.status.postMessage.fetching = actionTypes.processing;
    },
    postMessageSucceeded: (state, action) => {
      state.status.postMessage.fetching = actionTypes.done;
    },
    postMessageFailed: (state, action) => {
      state.status.postMessage.fetching = actionTypes.error;
      state.status.postMessage.error = action.payload;
    },
    setChattingEntity: (state, action) => {
      state.chatBox.chatEntity = action.payload;
    },
    chattingStart: (state, action) => {
      state.status.chatting.fetching = actionTypes.processing;
    },
    chattingSucceeded: (state, action) => {
      state.status.chatting.fetching = actionTypes.done;
    },
    chattingFailed: (state, action) => {
      state.status.chatting.fetching = actionTypes.error;
      state.status.chatting.error = action.payload;
    },
    markAsReadStart: (state, { payload }) => {
      state.unreadMessagesMeta[payload.id] = {
        isMarkingAsRead: true,
        error: null,
        readAt: null
      };
    },
    markAsReadSucceeded: (state, { payload }) => {
      state.unreadMessagesMeta[payload.id] = {
        isMarkingAsRead: false,
        error: null,
        readAt: payload.readAt
      };
    },
    markAsReadFailed: (state, { payload }) => {
      state.unreadMessagesMeta[payload.id] = {
        isMarkingAsRead: false,
        error: payload.error,
        readAt: null
      };
    }
  }
});

export const {
  fetchMessagesByDateRangeStart,
  fetchMessagesByDateRangeSucceeded,
  fetchMessagesByDateRangeFailed,
  setDateRangeSelected,
  setInitailSubmitMessageTemplateStatus,
  setInitailDeleteMessageTemplateStatus,
  setInitailDeleteMessagesStatus,
  fetchMessageTemplatesStart,
  fetchMessageTemplatesSucceeded,
  fetchMessageTemplatesFailed,
  fetchHistoricalMessagesByEntityStart,
  fetchHistoricalMessagesByEntitySucceeded,
  fetchHistoricalMessagesByEntityFailed,
  deleteMessageTemplateStart,
  deleteMessageTemplateSucceeded,
  deleteMessageTemplateFailed,
  submitMessageTemplateStart,
  submitMessageTemplateSucceeded,
  submitMessageTemplateFailed,
  postMessageStart,
  postMessageSucceeded,
  postMessageFailed,
  deleteMessagesStart,
  deleteMessagesSucceeded,
  deleteMessagesFailed,
  setInitailPostMessageStatus,
  setInitailChattingStatus,
  setChattingEntity,
  chatting,
  chattingFailed,
  chattingSucceeded,
  chattingStart,
  markAsReadStart,
  markAsReadSucceeded,
  markAsReadFailed,
  fetchUnreadMessagesCountStart,
  fetchUnreadMessagesCountFinished
} = messagngSlice.actions;

const GET_MESSAGES_PATH = `${API_PATH}/messages?direction=down`;
const POST_MESSAGE_PATH = `${API_PATH}/messages/app`;
const getISODateRange = formattedDateRange => {
  return {
    from: moment(formattedDateRange.from, STORE_DATE_FORMAT)
      .startOf('day')
      .toISOString(),
    to: moment(formattedDateRange.to, STORE_DATE_FORMAT)
      .endOf('day')
      .toISOString()
  };
};

export const fetchMessagesByDateRange = () => async (dispatch, getState) => {
  dispatch(fetchMessagesByDateRangeStart());
  const dateRange = getISODateRange(getState().messages.dateRange);
  const company_id = getState().companies.current.id;
  const userKey = getState().user.current.auth.key;
  const promise = new Promise((resolve, reject) => {
    request('GET', GET_MESSAGES_PATH)
      .set('Authorization', `Token token="${userKey}"`)
      .query({
        embed: 'MessageEvents',
        //embed: 'MessageEvents,vehicles,devices,users',//vehicles,devices,users will be ignored
        pruning: 'APP',
        message_types: `${MESSAGE_TYPE['MSG-NEW']},${MESSAGE_TYPE['MSG-REPLY']},${MESSAGE_TYPE['MSG-RECV']},${MESSAGE_TYPE['MSG-READ']},${MESSAGE_TYPE['MSG-ACK']}`,
        company_id,
        from: dateRange.from,
        to: dateRange.to
      })
      .then((resp, err) => {
        if (err || resp.status !== 200) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      });
  });
  try {
    const messages = await promise;
    const companies = getState().companies.list;
    const messagesWithCompanyInfo = (messages || []).map(msg => ({
      ...msg,
      company: (companies || []).find(comp => comp.id === msg.company?.id)
        ? {
            ...msg.company,
            ...companies.find(comp => comp.id === msg.company.id)
          }
        : msg.company
    }));
    dispatch(fetchMessagesByDateRangeSucceeded(messagesWithCompanyInfo));
  } catch (err) {
    dispatch(fetchMessagesByDateRangeFailed(err));
  }
};

export const useIsUnreadMessagesFetching = () =>
  useSelector(
    state => state.messages.status.getUnreadMessagesCount.fetching === actionTypes.processing
  );

export const fetchUnreadMessageCount = () => async (dispatch, getState) => {
  const dateRange = getISODateRange(getState().messages.dateRange);
  const companyId = getState().companies.current.id;
  const userKey = getState().user.current.auth.key;
  const isFetching =
    getState().messages.status.getUnreadMessagesCount.fetching === actionTypes.processing;
  const isCompanyIdDiff = companyId !== getState().messages.status.getUnreadMessagesCount.companyId;
  if (!isCompanyIdDiff && isFetching) {
    return;
  }
  dispatch(fetchUnreadMessagesCountStart({ companyId }));
  const promise = new Promise((resolve, reject) => {
    request('GET', GET_MESSAGES_PATH)
      .set('Authorization', `Token token="${userKey}"`)
      .query({
        embed: 'MessageEvents',
        pruning: 'APP',
        message_types: `${MESSAGE_TYPE['MSG-NEW']},${MESSAGE_TYPE['MSG-REPLY']},${MESSAGE_TYPE['MSG-RECV']},${MESSAGE_TYPE['MSG-READ']},${MESSAGE_TYPE['MSG-ACK']}`,
        company_id: companyId,
        from: dateRange.from,
        to: dateRange.to
      })
      .then((resp, err) => {
        if (err || resp.status !== 200) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      });
  });
  try {
    const messages = await promise;
    const companies = getState().companies.list;
    const messagesWithCompanyInfo = (messages || []).map(msg => ({
      ...msg,
      company: (companies || []).find(comp => comp.id === msg.company?.id)
        ? {
            ...msg.company,
            ...companies.find(comp => comp.id === msg.company.id)
          }
        : msg.company
    }));
    dispatch(fetchMessagesByDateRangeSucceeded(messagesWithCompanyInfo));
    dispatch(fetchUnreadMessagesCountFinished({ companyId }));
  } catch (err) {
    console.log(err);
    dispatch(fetchUnreadMessagesCountFinished({ companyId }));
  }
};

export const fetchMessageTemplates = () => async (dispatch, getState) => {
  dispatch(fetchMessageTemplatesStart());
  const companyId = getState().companies.current.id;
  const userKey = getState().user.current.auth.key;
  const promise = new Promise((resolve, reject) => {
    request('GET', `${API_PATH}/messages/template/company/${companyId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .then((resp, err) => {
        if (err || resp.status !== 200) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      });
  });
  try {
    const templates = await promise;
    dispatch(fetchMessageTemplatesSucceeded(templates));
  } catch (err) {
    dispatch(fetchMessageTemplatesFailed(err));
  }
};

export const deleteMessageTemplate = template => async (dispatch, getState) => {
  dispatch(deleteMessageTemplateStart());
  const promise = new Promise((resolve, reject) => {
    request('DELETE', `${API_PATH}/messages/template/${template.id}`)
      .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
      .end((err, response) => {
        if (err || response.status !== 200) {
          const _defaultErrMessage = response?.body?.message || `${err}`;
          const errStr = response
            ? parseErrorMessage({ response, message: _defaultErrMessage })
            : _defaultErrMessage;
          reject(errStr);
        } else {
          resolve(response.body);
        }
      });
  });
  try {
    const res = await promise;
    dispatch(fetchMessageTemplates());
    dispatch(deleteMessageTemplateSucceeded(res));
  } catch (err) {
    dispatch(deleteMessageTemplateFailed(err));
  }
};

export const submitMessageTemplate = template => async (dispatch, getState) => {
  dispatch(submitMessageTemplateStart());
  const verb = template.id ? 'PUT' : 'POST';
  const payload = {
    company: { id: getState().companies.current.id },
    subject: template.subject,
    body: template.body,
    id: template.id
  };
  const promise = new Promise((resolve, reject) => {
    request(verb, `${API_PATH}/messages/template/company`)
      .set('Authorization', `Token token="${getState().user.current.auth.key}"`)
      .set('Content-Type', 'application/json')
      .send(payload)
      .end((err, response) => {
        if (err || response.status !== 200) {
          const _defaultErrMessage = response?.body?.message || `${err}`;
          const errStr = response
            ? parseErrorMessage({ response, message: _defaultErrMessage })
            : _defaultErrMessage;
          reject(errStr);
        } else {
          resolve(response.body);
        }
      });
  });
  try {
    const template_id = await promise;
    dispatch(fetchMessageTemplates());
    dispatch(submitMessageTemplateSucceeded(template_id));
  } catch (err) {
    dispatch(submitMessageTemplateFailed(err));
  }
};

const fetchHistoricalMessagesByDynamicalLinkedChatEntity = async ({
  dispatch,
  vehicleId,
  deviceId,
  userKey,
  dateRange
}) => {
  const devicePromise = new Promise((resolve, reject) => {
    request('GET', API_PATH + `/messages/${RECIPIENT_TYPE.DEVICE}/${deviceId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .query({
        embed: 'MessageEvents',
        pruning: 'APP',
        message_types: `${MESSAGE_TYPE['MSG-NEW']},${MESSAGE_TYPE['MSG-REPLY']},${MESSAGE_TYPE['MSG-RECV']},${MESSAGE_TYPE['MSG-READ']},${MESSAGE_TYPE['MSG-ACK']}`,
        from: dateRange.from,
        to: dateRange.to
      })
      .then((resp, err) => {
        if (err || resp.status !== 200) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      });
  });
  const vehiclePromise = new Promise((resolve, reject) => {
    request('GET', API_PATH + `/messages/${RECIPIENT_TYPE.VEHICLE}/${vehicleId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .query({
        embed: 'MessageEvents',
        pruning: 'APP',
        message_types: `${MESSAGE_TYPE['MSG-NEW']},${MESSAGE_TYPE['MSG-REPLY']},${MESSAGE_TYPE['MSG-RECV']},${MESSAGE_TYPE['MSG-READ']},${MESSAGE_TYPE['MSG-ACK']}`,
        from: dateRange.from,
        to: dateRange.to
      })
      .then((resp, err) => {
        if (err || resp.status !== 200) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      });
  });
  try {
    const historicalMessages = await Promise.all([devicePromise, vehiclePromise]);
    const filteredMsgs = historicalMessages
      .reduce((a, c) => a.concat(c), [])
      .reduce((a, c) => {
        if (a.every(msg => msg.id !== c.id)) {
          a.push(c);
        }
        return a;
      }, []);
    dispatch(fetchHistoricalMessagesByEntitySucceeded(filteredMsgs));
  } catch (err) {
    dispatch(fetchHistoricalMessagesByEntityFailed(err));
  }
};

export const fetchHistoricalMessagesByEntity = () => async (dispatch, getState) => {
  dispatch(fetchHistoricalMessagesByEntityStart());
  const chatEntity = getState().messages.chatBox.chatEntity;
  const userKey = getState().user.current.auth.key;
  const dateRange = getISODateRange(getState().messages.dateRange);
  if (chatEntity.chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE) {
    return dispatch(fetchHistoricalMessagesByEntitySucceeded([chatEntity.lastChatMsg]));
  }
  if (
    chatEntity.chatEntityType === RECIPIENT_TYPE.VEHICLE &&
    chatEntity[VEHICLE_DYNAMIC_LINKED_DEVICE]?.id
  ) {
    return await fetchHistoricalMessagesByDynamicalLinkedChatEntity({
      dispatch,
      deviceId: chatEntity[VEHICLE_DYNAMIC_LINKED_DEVICE].id,
      vehicleId: chatEntity.chatEntityId,
      userKey,
      dateRange
    });
  } else if (
    chatEntity.chatEntityType === RECIPIENT_TYPE.DEVICE &&
    chatEntity[DEVICE_DYNAMIC_LINKED_VEHICLE]?.id
  ) {
    return await fetchHistoricalMessagesByDynamicalLinkedChatEntity({
      dispatch,
      deviceId: chatEntity.chatEntityId,
      vehicleId: chatEntity[DEVICE_DYNAMIC_LINKED_VEHICLE].id,
      userKey,
      dateRange
    });
  }
  const promise = new Promise((resolve, reject) => {
    request('GET', API_PATH + `/messages/${chatEntity.chatEntityType}/${chatEntity.chatEntityId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .query({
        embed: 'MessageEvents',
        pruning: 'APP',
        message_types: `${MESSAGE_TYPE['MSG-NEW']},${MESSAGE_TYPE['MSG-REPLY']},${MESSAGE_TYPE['MSG-RECV']},${MESSAGE_TYPE['MSG-READ']},${MESSAGE_TYPE['MSG-ACK']}`,
        from: dateRange.from,
        to: dateRange.to
      })
      .then((resp, err) => {
        if (err || resp.status !== 200) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      });
  });
  try {
    const historicalMessages = await promise;
    const filteredMsgs = historicalMessages.reduce((a, c) => {
      if (a.every(msg => msg.id !== c.id)) {
        a.push(c);
      }
      return a;
    }, []);
    dispatch(fetchHistoricalMessagesByEntitySucceeded(filteredMsgs));
  } catch (err) {
    dispatch(fetchHistoricalMessagesByEntityFailed(err));
  }
};

export const deleteMessages = (messages, deleteMode, chatEntity) => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const url = message => {
    const isMultipleRecipientMsg = message.recipients && message.recipients.length > 1;
    if (isMultipleRecipientMsg) {
      return deleteMode === MESSAGE_DELETE_MODE.ALL
        ? `${API_PATH}/messages/${message.id}`
        : `${API_PATH}/messages/${message.id}/${chatEntity.chatEntityType}/${chatEntity.chatEntityId}`;
    } else {
      return `${API_PATH}/messages/${message.id}`;
    }
  };
  dispatch(deleteMessagesStart());
  const allPromises = messages.map(
    message =>
      new Promise((resolve, reject) => {
        request('DELETE', url(message))
          .set('Authorization', `Token token="${userKey}"`)
          .set('Content-Type', 'application/json')
          .end((err, response) => {
            if (err || response.status !== 204) {
              const _defaultErrMessage = response?.body?.message || `${err}`;
              const errStr = response
                ? parseErrorMessage({ response, message: _defaultErrMessage })
                : _defaultErrMessage;
              reject(errStr);
            } else {
              resolve({
                id: message.id,
                deleted: true,
                resp: response.body
              });
            }
          });
      })
  );
  try {
    const res = await Promise.all(allPromises);
    dispatch(fetchHistoricalMessagesByEntity());
    dispatch(fetchMessagesByDateRange());
    dispatch(deleteMessagesSucceeded(res));
  } catch (err) {
    dispatch(deleteMessagesFailed(err));
  }
};

const readAtMessage = messageId => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;
  dispatch(markAsReadStart({ id: messageId }));
  const promise = new Promise((resolve, reject) => {
    request('POST', API_PATH + `/messages/${messageId}/read?company_id=${companyId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .then((resp, err) => {
        if (err || resp.status !== 200) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      });
  });
  try {
    const msg = await promise;
    dispatch(markAsReadSucceeded({ msg, id: messageId, readAt: msg?.readAt }));
  } catch (err) {
    dispatch(markAsReadFailed({ id: messageId, error: err || 'mark failed' }));
  }
};

export const postMessage = formValue => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;
  dispatch(postMessageStart());
  const postBody = {
    id: null,
    parent: null,
    type: {
      id: !!formValue.requireAck ? 1 : 2
    },
    device: null,
    sender: {
      id: getState().user.current.id
    },
    subject: formValue.subject,
    body: formValue.body,
    events: formValue.events
  };
  const promise = new Promise((resolve, reject) => {
    request('POST', `${POST_MESSAGE_PATH}?company_id=${companyId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(postBody)
      .end((err, response) => {
        if (err || response.status !== 200) {
          const _defaultErrMessage = response?.body?.message || `${err}`;
          const errStr = response
            ? parseErrorMessage({ response, message: _defaultErrMessage })
            : _defaultErrMessage;
          reject(errStr);
        } else {
          resolve(response.body);
        }
      });
  });
  try {
    const res = await promise;
    dispatch(setChattingEntity(null));
    dispatch(fetchMessagesByDateRange());
    dispatch(postMessageSucceeded(res));
    Mixpanel.sendTrackEvent(MPTrackingEvents.Messaging.SendMessage, {
      sendSuccess: true,
      messageType: !!formValue.requireAck
        ? MPTrackingEvents.Messaging.MessageType.Alert
        : MPTrackingEvents.Messaging.MessageType.Normal
    });
  } catch (err) {
    dispatch(postMessageFailed(err));
    Mixpanel.sendTrackEvent(MPTrackingEvents.Messaging.SendMessage, {
      sendSuccess: false,
      messageType: !!formValue.requireAck
        ? MPTrackingEvents.Messaging.MessageType.Alert
        : MPTrackingEvents.Messaging.MessageType.Normal
    });
  }
};

export const chat = (formValue, latestDevicesStats = []) => async (dispatch, getState) => {
  dispatch(chattingStart());
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies.current.id;
  const postBody = {
    id: null,
    parent: formValue.parent || null,
    type: {
      id: formValue.requireAck ? 1 : 2
    },
    device: null,
    sender: {
      id: getState().user.current.id
    },
    subject: formValue.subject,
    body: formValue.body,
    events: formValue.events
  };
  const promise = new Promise((resolve, reject) => {
    request('POST', `${POST_MESSAGE_PATH}?company_id=${companyId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(postBody)
      .end((err, response) => {
        if (err || response.status !== 200) {
          const _defaultErrMessage = response?.body?.message || `${err}`;
          const errStr = response
            ? parseErrorMessage({ response, message: _defaultErrMessage })
            : _defaultErrMessage;
          reject(errStr);
        } else {
          resolve(response.body);
        }
      });
  });
  try {
    const res = await promise;
    dispatch(fetchMessagesByDateRange());
    dispatch(chattingSucceeded(res));
    if (latestDevicesStats?.length) {
      dispatch(
        fetchDevicesStatsSuccess({
          list: latestDevicesStats,
          companyKey: getState().companies.current.api_key
        })
      );
    }
    Mixpanel.sendTrackEvent(MPTrackingEvents.Messaging.SendMessage, {
      sendSuccess: true,
      messageType: !!formValue.requireAck
        ? MPTrackingEvents.Messaging.MessageType.Alert
        : MPTrackingEvents.Messaging.MessageType.Normal
    });
  } catch (err) {
    dispatch(chattingFailed(err));
    Mixpanel.sendTrackEvent(MPTrackingEvents.Messaging.SendMessage, {
      sendSuccess: false,
      messageType: !!formValue.requireAck
        ? MPTrackingEvents.Messaging.MessageType.Alert
        : MPTrackingEvents.Messaging.MessageType.Normal
    });
  }
};

export const getDriverLatestInteractiveDevices = driverId => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies.current.id;
  const devicesStats = await request(
    'GET',
    `${API_PATH}/devices/stats?embed=users&pruning=ALL&direction=DOWN&company_id=${companyId}`
  )
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(resp => resp.body || [])
    .catch(err => []);
  return {
    devicesStats,
    devices: devicesStats.filter(
      deviceStat =>
        deviceStat.currentUser?.id &&
        parseInt(deviceStat.currentUser?.id, 10) === parseInt(driverId, 10)
    )
  };
};

export const useMessages = () => {
  const messages = useSelector(state => state.messages.list);
  const unreadMessagesMeta = useUnreadMessagesMeta();
  const updatedMessages = useMemo(() => {
    return messages?.map(message => {
      const readAt =
        (unreadMessagesMeta && unreadMessagesMeta[message.id]?.readAt) || message?.readAt;
      return { ...message, ...(!!readAt ? { readAt } : {}) };
    });
  }, [messages, unreadMessagesMeta]);
  return updatedMessages;
};

export const useIsMessagesFetching = () =>
  useSelector(state => state.messages.status.get.fetching === actionTypes.processing);

export const useIsMessageTemplatesFetching = () =>
  useSelector(state => state.messages.status.getTemplates.fetching === actionTypes.processing);

export const useIsHistoricalMessagesbyEntityFetching = () =>
  useSelector(
    state => state.messages.status.getHistoricalMessagesByEntity.fetching === actionTypes.processing
  );

export const useIsDeletingMessageTemplate = () =>
  useSelector(state => state.messages.status.deleteTemplate.fetching === actionTypes.processing);

export const useIsDeleteMessageTemplateSuccess = () =>
  useSelector(state => state.messages.status.deleteTemplate.fetching === actionTypes.done);

export const useIsDeleteMessageTemplateFailed = () =>
  useSelector(state => state.messages.status.deleteTemplate.fetching === actionTypes.error);

export const useDeleteMessageTemplateFailedError = () =>
  useSelector(state => state.messages.status.deleteTemplate.error);

export const useIsSubmittingMessageTemplate = () =>
  useSelector(state => state.messages.status.submitTemplate.fetching === actionTypes.processing);

export const useIsSubmitMessageTemplateSuccess = () =>
  useSelector(state => state.messages.status.submitTemplate.fetching === actionTypes.done);

export const useIsSubmitMessageTemplateFailed = () =>
  useSelector(state => state.messages.status.submitTemplate.fetching === actionTypes.error);

export const useSubmitMessageTemplateFailedError = () =>
  useSelector(state => state.messages.status.submitTemplate.error);

export const useIsPostingMessage = () =>
  useSelector(state => state.messages.status.postMessage.fetching === actionTypes.processing);

export const useIsPostMessageSuccess = () =>
  useSelector(state => state.messages.status.postMessage.fetching === actionTypes.done);

export const useIsPostMessageFailed = () =>
  useSelector(state => state.messages.status.postMessage.fetching === actionTypes.error);

export const usePostMessageFailedError = () =>
  useSelector(state => state.messages.status.postMessage.error);

export const useIsDeletingMessages = () =>
  useSelector(state => state.messages.status.deleteMessages.fetching === actionTypes.processing);

export const useIsDeleteMessagesSuccess = () =>
  useSelector(state => state.messages.status.deleteMessages.fetching === actionTypes.done);

export const useIsDeleteMessagesFailed = () =>
  useSelector(state => state.messages.status.deleteMessages.fetching === actionTypes.error);

export const useDeleteMessageFailedError = () =>
  useSelector(state => state.messages.status.deleteMessages.error);

export const useIsChatting = () =>
  useSelector(state => state.messages.status.chatting.fetching === actionTypes.processing);

export const useIsChattingSuccess = () =>
  useSelector(state => state.messages.status.chatting.fetching === actionTypes.done);

export const useIsChattingFailed = () =>
  useSelector(state => state.messages.status.chatting.fetching === actionTypes.error);

export const useChattingFailedError = () =>
  useSelector(state => state.messages.status.chatting.error);

export const useUnreadMessagesMeta = () => useSelector(state => state.messages.unreadMessagesMeta);

export const useChatEntities = () => {
  const messages = useMessages();
  const { fleets, isFetchingFleets: fleetIsFetching } = useFleetsWithDynamicLinkVehicleDevice();
  const messagesIsFetching = useIsMessagesFetching();
  const drivers = useUsers();

  const driverIsFetching = useIsDriverFetching();

  const isFetching = useMemo(() => {
    return fleetIsFetching || driverIsFetching || messagesIsFetching;
  }, [driverIsFetching, fleetIsFetching, messagesIsFetching]);
  const chatEntities = useMemo(() => {
    if (isFetching || !messages || !messages.length) {
      return [];
    }
    return categoryMessagesByChatEnitity(messages, drivers, fleets);
  }, [messages, drivers, fleets, isFetching]);

  return {
    chatEntities,
    fleets,
    drivers,
    isFetching,
    messages,
    fleetIsFetching,
    driverIsFetching,
    messagesIsFetching
  };
};

export const useChatHistoricalMessages = () => {
  const dispatch = useDispatch();
  const unreadMessagesMeta = useUnreadMessagesMeta();
  const { chatEntities, fleets } = useChatEntities();
  const rawHistoricalMessages = useSelector(state => state.messages.chatBox.historicalMessages);
  const companies = useSelector(state => state.companies.list);
  const _historicalMessages = useMemo(() => {
    //mark historicalMessage is also from a groupChat or not
    const groupChatEntities =
      chatEntities?.filter(
        chatEntity => chatEntity.chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE
      ) || [];
    const allMessagesFromGroupChatEntities = groupChatEntities
      .map(
        chatEntity => flattenChildrenMsgsOfGroupMessageEntity(chatEntity.chatMessages || []) || []
      )
      .reduce((a, b) => a.concat(b), []);
    return rawHistoricalMessages.map(msg => {
      return {
        ...msg,
        isGroupSeriesMessage: allMessagesFromGroupChatEntities.some(
          groupMsg => parseInt(groupMsg?.id, 10) === parseInt(msg?.id, 10)
        ),
        company: (companies || []).find(comp => comp.id === msg.company?.id)
          ? {
              ...msg.company,
              ...companies.find(comp => comp.id === msg.company.id)
            }
          : msg.company
      };
    });
  }, [rawHistoricalMessages, companies, chatEntities]);
  const historicalMessages = useMemo(() => {
    return _historicalMessages?.map(_historicalMessage => {
      const readAt =
        (unreadMessagesMeta && unreadMessagesMeta[_historicalMessage.id]?.readAt) ||
        _historicalMessage?.readAt;
      return { ..._historicalMessage, ...(!!readAt ? { readAt } : {}) };
    });
  }, [_historicalMessages, unreadMessagesMeta]);
  const chatEntity = useChattingEntity();

  const isGroupMessageChatEntity = useMemo(() => {
    return chatEntity.chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE;
  }, [chatEntity]);

  const flattenHistoricalMessages = useMemo(() => {
    return chatEntity
      ? isGroupMessageChatEntity
        ? flattenChildrenMsgsOfGroupMessageEntity(chatEntity.chatMessages)
        : feedMessages(historicalMessages, chatEntity, fleets)
      : [];
  }, [chatEntity, isGroupMessageChatEntity, historicalMessages, fleets]);

  const { unreadMessages, hasUnreadMessages } = useMemo(() => {
    const unreadMessages = (flattenHistoricalMessages || []).filter(
      msg =>
        messageCanMarkAsRead(msg) &&
        (!unreadMessagesMeta[msg?.id] ||
          (!unreadMessagesMeta[msg?.id]?.isMarkingAsRead &&
            !unreadMessagesMeta[msg?.id]?.readAt &&
            !unreadMessagesMeta[msg?.id]?.error))
    );
    return {
      unreadMessages,
      hasUnreadMessages: unreadMessages && unreadMessages.length > 0
    };
  }, [flattenHistoricalMessages, unreadMessagesMeta]);

  const markAsRead = useCallback(() => {
    if (unreadMessages?.length) {
      unreadMessages.map(msg => dispatch(readAtMessage(msg.id)));
    }
  }, [unreadMessages, dispatch]);

  return {
    chatEntity,
    historicalMessages,
    flattenHistoricalMessages,
    isGroupMessageChatEntity,
    hasUnreadMessages,
    markAsRead,
    unreadMessages
  };
};

export const useMessageTemplates = () => useSelector(state => state.messages.templates);

export const useMessageFilterDateRange = () => {
  const formattedDateRange = useSelector(state => state.messages.dateRange);
  return useMemo(() => getISODateRange(formattedDateRange), [formattedDateRange]);
};

export const useChattingEntity = () =>
  useSelector(state => {
    return state.messages.chatBox.chatEntity &&
      state.messages.chatBox.chatEntity.chatEntityId &&
      state.messages.chatBox.chatEntity.chatEntityName &&
      state.messages.chatBox.chatEntity.chatEntityType
      ? state.messages.chatBox.chatEntity
      : null;
  });

export const useUserMessagingPermissions = () => {
  const permissions = useSelector(state => state.permissions?.entities || []);
  return useMemo(() => getCurrentUserMessagingPermissions(permissions), [permissions]);
};

export default messagngSlice.reducer;
