export const MeterType = {
  Battery: 'battery',
  Charge: 'charge',
  Distance: 'distance',
  Fuel: 'fuel',
  FuelLevel: 'fuel_level',
  Hours: 'hours',
  Humidity: 'humidity',
  Odometer: 'odometer',
  Temperature: 'temperature',
  Soc: 'hv_soc',
  Range: 'hv_range',
  Gpio: 'gpio',
  OilLifeRemaining: 'oil_life_remaining'
};

export const MeterSource = {
  Ruc: 'RUC',
  Can: 'CAN',
  Gps: 'GPS',
  Main: 'Main',
  Backup: 'Backup'
};

export const MeterSourceCalculatedBy = {
  Elapsed: 'elapsed',
  Actual: 'actual'
};
