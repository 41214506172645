/**
 * TachoService APIs
 * A set of APIs for accessing the TachoCard feature and data
 *
 * The version of the OpenAPI document: v1
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";

/**
* TachoCardUsersApi service.
* @module api/TachoCardUsersApiApi
* @version v1
*/
export default class TachoCardUsersApiApi {

    /**
    * Constructs a new TachoCardUsersApiApi. 
    * @alias module:api/TachoCardUsersApiApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the tachoCardUsersApiDelete operation.
     * @callback module:api/TachoCardUsersApiApi~tachoCardUsersApiDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/TachoCardUsersApiApi~tachoCardUsersApiDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    tachoCardUsersApiDelete(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling tachoCardUsersApiDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/tacho/tachocardusers/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the tachoCardUsersApiGet operation.
     * @callback module:api/TachoCardUsersApiApi~tachoCardUsersApiGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TachoCardUserDbModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} cardId 
     * @param {module:api/TachoCardUsersApiApi~tachoCardUsersApiGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TachoCardUserDbModel}
     */
    tachoCardUsersApiGet(cardId, callback) {
      let postBody = null;
      // verify the required parameter 'cardId' is set
      if (cardId === undefined || cardId === null) {
        throw new Error("Missing the required parameter 'cardId' when calling tachoCardUsersApiGet");
      }

      let pathParams = {
        'cardId': cardId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/tachocardusers/{cardId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the tachoCardUsersApiGetByCardNumber operation.
     * @callback module:api/TachoCardUsersApiApi~tachoCardUsersApiGetByCardNumberCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TachoCardUserDbModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} cardnumber 
     * @param {module:api/TachoCardUsersApiApi~tachoCardUsersApiGetByCardNumberCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TachoCardUserDbModel}
     */
    tachoCardUsersApiGetByCardNumber(cardnumber, callback) {
      let postBody = null;
      // verify the required parameter 'cardnumber' is set
      if (cardnumber === undefined || cardnumber === null) {
        throw new Error("Missing the required parameter 'cardnumber' when calling tachoCardUsersApiGetByCardNumber");
      }

      let pathParams = {
        'cardnumber': cardnumber
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/tachocardusers/cardnumber/{cardnumber}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the tachoCardUsersApiGetByCompany operation.
     * @callback module:api/TachoCardUsersApiApi~tachoCardUsersApiGetByCompanyCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/TachoCardUserDbModel>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} companyId 
     * @param {module:api/TachoCardUsersApiApi~tachoCardUsersApiGetByCompanyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/TachoCardUserDbModel>}
     */
    tachoCardUsersApiGetByCompany(companyId, callback) {
      let postBody = null;
      // verify the required parameter 'companyId' is set
      if (companyId === undefined || companyId === null) {
        throw new Error("Missing the required parameter 'companyId' when calling tachoCardUsersApiGetByCompany");
      }

      let pathParams = {
        'companyId': companyId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/tachocardusers/company/{companyId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the tachoCardUsersApiGetByUser operation.
     * @callback module:api/TachoCardUsersApiApi~tachoCardUsersApiGetByUserCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TachoCardUserDbModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} userId 
     * @param {module:api/TachoCardUsersApiApi~tachoCardUsersApiGetByUserCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TachoCardUserDbModel}
     */
    tachoCardUsersApiGetByUser(userId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling tachoCardUsersApiGetByUser");
      }

      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/tachocardusers/user/{userid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the tachoCardUsersApiPost operation.
     * @callback module:api/TachoCardUsersApiApi~tachoCardUsersApiPostCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/TachoCardUserDbModel} value 
     * @param {module:api/TachoCardUsersApiApi~tachoCardUsersApiPostCallback} callback The callback function, accepting three arguments: error, data, response
     */
    tachoCardUsersApiPost(value, callback) {
      let postBody = value;
      // verify the required parameter 'value' is set
      if (value === undefined || value === null) {
        throw new Error("Missing the required parameter 'value' when calling tachoCardUsersApiPost");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/tacho/tachocardusers', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the tachoCardUsersApiPut operation.
     * @callback module:api/TachoCardUsersApiApi~tachoCardUsersApiPutCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:model/TachoCardUserDbModel} value 
     * @param {module:api/TachoCardUsersApiApi~tachoCardUsersApiPutCallback} callback The callback function, accepting three arguments: error, data, response
     */
    tachoCardUsersApiPut(id, value, callback) {
      let postBody = value;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling tachoCardUsersApiPut");
      }
      // verify the required parameter 'value' is set
      if (value === undefined || value === null) {
        throw new Error("Missing the required parameter 'value' when calling tachoCardUsersApiPut");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/tacho/tachocardusers/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
