import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useLocalization } from 'features/localization/localizationSlice';
import { formatDistance } from 'utils/dates';

import styles from './TripSummaryControl.module.scss';

export const TripSummaryControl = ({ device, selectedTrip }) => {
  const { t } = useTranslation();
  const localization = useLocalization();

  const [distance, setDistance] = useState();
  const [duration, setDuration] = useState();

  useEffect(() => {
    setDistance(getDistance(selectedTrip));
    setDuration(getDuration(selectedTrip));
    // console.debug('TripSummaryControl', device, selectedTrip, getDistance(selectedTrip), getDuration(selectedTrip));
  }, [device, selectedTrip]);

  const getDistance = trip => {
    let distance = 0;

    if (trip) {
      if (trip.odometerInfo?.statusCode === 0 && trip.odometerInfo?.distance) {
        distance = trip.odometerInfo?.distance;
      } else if (
        trip.distanceStart &&
        trip.distanceEnd &&
        device &&
        (device.engineSummarySource === 'CAN' || device.engineSummarySource === 'CAN_DIFF')
      ) {
        distance = trip.distanceEnd - trip.distanceStart;
      } else if (trip.gpsOdoEnd && trip.gpsOdoStart) {
        distance = trip.gpsOdoEnd - trip.gpsOdoStart;
      }
    }

    return localization.formatDistance(Math.max(distance, 0));
  };

  const getDuration = trip => {
    let duration = 0;

    if (trip?.ignitionOn) {
      duration = formatDistance(
        moment(trip?.ignitionOn).toDate(),
        trip?.ignitionOff ? moment(trip.ignitionOff).toDate() : moment().toDate(),
        'seconds'
      );
    }

    return duration;
  };

  return (
    <div className={styles.tripSummary}>
      <Row align="middle">
        <Col span={12} className={styles.distance}>
          <label className={styles.label}>
            {selectedTrip
              ? t('Map.DroneViewControl.TripDistance')
              : t('Map.DroneViewControl.TotalDistance')}
          </label>
          <div className={styles.value}>{distance !== null ? distance : '-'}</div>
        </Col>
        <Col span={12} className={styles.duration}>
          <label className={styles.label}>
            {selectedTrip
              ? t('Map.DroneViewControl.TripDuration')
              : t('Map.DroneViewControl.TotalDuration')}
          </label>
          <div className={styles.value}>{duration !== null ? duration : '-'}</div>
        </Col>
      </Row>
    </div>
  );
};
