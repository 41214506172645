import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  modals: {
    publishDashboard: {
      isOpen: false,
      analysisId: null
    },
    addDashboard: {
      isOpen: false
    }
  }
};

const insightsSlice = createSlice({
  name: 'insightsSlice',
  initialState,
  reducers: {
    openPublishDashboardModal(state, { payload }) {
      state.modals.publishDashboard.isOpen = true;
      state.modals.publishDashboard.analysisId = payload?.id;
    },
    closePublishDashboardModal(state) {
      state.modals.publishDashboard.isOpen = false;
    },
    openAddDashboardModal(state) {
      state.modals.addDashboard.isOpen = true;
    },
    closeAddDashboardModal(state) {
      state.modals.addDashboard.isOpen = false;
    }
  }
});

export const {
  openPublishDashboardModal,
  closePublishDashboardModal,
  openAddDashboardModal,
  closeAddDashboardModal
} = insightsSlice.actions;

export const useIsPublishDashboardModalOpen = id =>
  useSelector(
    state =>
      (!id || state?.insights?.modals?.publishDashboard?.analysisId === id) &&
      state?.insights?.modals?.publishDashboard?.isOpen
  );
export const useIsAddDashboardModalOpen = () =>
  useSelector(state => state?.insights?.modals?.addDashboard?.isOpen);

export default insightsSlice.reducer;
