import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import cn from 'classnames';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { helpers } from './';
import { format } from 'utils/dates';
import { Spin } from 'antd';
import { useIsFetchingRunsheets } from 'features/smartJobs/smartJobsSlice';
import style from './CalendarView.module.scss';
import { useLocalization } from 'features/localization/localizationSlice';

export const CalendarView = ({ value, onChange, renderer }) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const weekDays = useMemo(() => {
    return helpers.getFormattedWeekDays(value, t);
  }, [value, t]);
  const isFetchingRunsheets = useIsFetchingRunsheets();

  const currentInterval = useMemo(() => {
    const days = weekDays || [];
    const start = days[0];
    const end = days[days.length - 1];
    let range = {
      from: '',
      to: ''
    };
    const isCA = localization?.region?.toLowerCase() === 'ca';
    if (start.year !== end.year) {
      range = {
        from: format(start.date, localization.formats.time.formats.dby),
        to: format(end.date, localization.formats.time.formats.dby)
      };
    } else {
      range = isCA
        ? {
            from: format(start.date, localization.formats.time.formats.dby),
            to: format(end.date, localization.formats.time.formats.Md)
          }
        : {
            from: format(start.date, localization.formats.time.formats.Md),
            to: format(end.date, localization.formats.time.formats.dby)
          };
    }
    return Object.values(range).join(' - ');
  }, [weekDays, localization]);

  const renderCellData = (data, index) => {
    const renderedCells = renderer(data);
    const cellClassName = cn(style.cell, { [style.active]: !!(renderedCells || []).length });

    return (
      <div key={index} className={cellClassName}>
        <span className={style.cellHeader}>{data.number}</span>
        {renderedCells}
      </div>
    );
  };

  const handlePrevious = () => onChange(moment(value).subtract(1, 'weeks'));
  const handleNext = () => onChange(moment(value).add(1, 'weeks'));

  return (
    <div className={style.container}>
      <div className={style.weekPickerContainer}>
        <LeftOutlined className={style.leftOutlined} onClick={handlePrevious} />
        <span>{currentInterval}</span>
        <RightOutlined className={style.rightOutlined} onClick={handleNext} />
      </div>
      <div className={style.weekDaysContainer}>
        {weekDays.map((day, index) => (
          <span key={index} className={style.dayHeader}>
            {day.name}
          </span>
        ))}
      </div>
      <div className={style.cellsContainer}>
        {isFetchingRunsheets && (
          <div className={style.loaderContainer}>
            <Spin />
          </div>
        )}
        {weekDays.map(renderCellData)}
      </div>
    </div>
  );
};
