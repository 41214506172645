import React from 'react';
import styles from './Preview.module.scss';
import { FormGroup, FormLabel, FormControl, Row, Col } from 'react-bootstrap';
import FormTitle from 'components/form/form-title/FormTitle';
import i18next from 'i18next';

const ElementPreview = ({ element }) => {
  const { Type, Text, Options = [], Content } = element;
  const showSmartjobsValue = element.DynamicContent?.source;
  const TextElement = () => {
    return (
      <FormGroup className={styles.formGroup}>
        <FormLabel>{Text || `${i18next.t('Forms.ActualForm.Label')}`}</FormLabel>
        <FormControl
          className={styles.input}
          as="input"
          disabled={showSmartjobsValue}
          readOnly={!showSmartjobsValue}
          value={
            showSmartjobsValue
              ? showSmartjobsValue + ` (${i18next.t('Forms.ActualForm.FromSmartjobs')})`
              : ''
          }
        />
      </FormGroup>
    );
  };
  const SelectionElement = () => {
    const height = 10 + 20 * Options.length;
    return (
      <FormGroup className={styles.formGroup}>
        <FormLabel>{Text || `${i18next.t('Forms.ActualForm.Label')}`}</FormLabel>
        <FormControl
          className={styles.multipleSelect}
          style={{ height: `${height}px` }}
          as="select"
          multiple
        >
          {Options.map((option, index) => (
            <option disabled key={`${option.value}-${index}`}>
              {option.Value}
            </option>
          ))}
        </FormControl>
      </FormGroup>
    );
  };
  const DescriptionElement = () => {
    return (
      <FormGroup className={styles.formGroup}>
        <FormLabel>{Text || `${i18next.t('Forms.ActualForm.Label')}`}</FormLabel>
        <FormControl
          className={styles.input}
          as="textarea"
          value={
            showSmartjobsValue
              ? showSmartjobsValue + ` (${i18next.t('Forms.ActualForm.FromSmartjobs')})`
              : Content
          }
          rows={2}
          disabled={showSmartjobsValue}
          readOnly={!showSmartjobsValue}
        />
      </FormGroup>
    );
  };
  const YesNoElement = () => {
    return (
      <>
        <FormLabel>{Text || `${i18next.t('Forms.ActualForm.Label')}`}</FormLabel>
        <Row>
          <Col className={styles.radioWrapper}>
            <input type="radio" disabled />
            <label>{i18next.t('Forms.ActualForm.Yes')}</label>
          </Col>
          <Col className={styles.radioWrapper}>
            <input type="radio" disabled />
            <label>{i18next.t('Forms.ActualForm.No')}</label>
          </Col>
        </Row>
      </>
    );
  };
  const OkNotOkElement = () => {
    return (
      <>
        <FormLabel>{Text || `${i18next.t('Forms.ActualForm.Label')}`}</FormLabel>
        <Row>
          <Col className={styles.radioWrapper}>
            <input type="radio" disabled />
            <label>{i18next.t('Forms.ActualForm.Ok')}</label>
          </Col>
          <Col className={styles.radioWrapper}>
            <input type="radio" disabled />
            <label>{i18next.t('Forms.ActualForm.NotOk')}</label>
          </Col>
        </Row>
      </>
    );
  };
  const CheckboxElement = () => {
    return (
      <>
        <FormLabel>{Text || `${i18next.t('Forms.ActualForm.Label')}`}</FormLabel>
        <Row>
          <Col className={styles.checkboxWrapper}>
            <input type="checkbox" disabled />
            <label>{i18next.t('Forms.ActualForm.Defect')}</label>
          </Col>
        </Row>
      </>
    );
  };
  const SectionElement = () => {
    return (
      <FormTitle
        underlined
        title={Text || `${i18next.t('Forms.ActualForm.Label')}`}
        customStyling={{ paddingTop: '0px' }}
      />
    );
  };

  switch (Type) {
    case 'Yes/No':
      return <YesNoElement />;
    case 'Ok/Not Ok':
      return <OkNotOkElement />;
    case 'Checkbox':
      return <CheckboxElement />;
    case 'Selection':
      return <SelectionElement />;
    case 'Description':
      return <DescriptionElement />;
    case 'Section':
      return <SectionElement />;
    default:
      return <TextElement />;
  }
};

export default ElementPreview;
