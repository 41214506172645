import { Button, Col, Row, Affix } from 'antd';
import AntFilterWrapper from 'components/form/ant-filter-wrapper/AntFilterWrapper';
import AntMultiselect, { // TODO: Modify after release with Ant component
  formatListForMultiselect
} from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar'; // TODO: Modify after release with Ant component
import { useCompanies, useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { TabNavLink } from 'components/nav/NavLinks';
import { Can, entities } from 'features/permissions';
import {
  useVehicleMntTypes,
  useDeletedVehicleMntTypes,
  deleteMntTypesApi,
  restoreMntTypesApi,
  useIsFetching as isVehicleMntTypesFetching
} from 'features/vehicleMntTypes/vehicleMntTypesSlice';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { toLower } from 'lodash';
import { cache } from './CellRenderers';
import useDebounce from 'utils/hooks/useDebounce';
import { VehicleMntTypesTable } from './VehicleMntTypesTable';
import { VehicleMntTypesLens, DELETED_VEHICLES_MNT_TYPES_PATH, TYPE_ACTION } from './constants';
import './VehicleMntTypes.scss';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';
import { prepareDataForMultiselect } from 'utils/filters';
import { BUTTON_IDS } from 'utils/globalConstants';

const VehicleMntTypes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const [filterTab, setFilterTab] = useState(filterPath);

  const companies = useCompanies();
  const subCompanies = useSubCompanies();
  const localization = useLocalization();
  const allVehicleMntTypes = useVehicleMntTypes();
  const deletedVehicleMntTypes = useDeletedVehicleMntTypes();
  const company = useCurrentCompany();
  const history = useHistory();
  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(filterText, 300);

  const [filterCompanies, setFilterCompanies] = useState(
    formatListForMultiselect([{ id: 0, name: `${t('Common.AllCompanies')}` }, ...subCompanies])
  );

  const filteredVehicleMntTypes = useMemo(() => {
    const vehicleMntTypesToFilter =
      (filterTab === 'deleted' ? deletedVehicleMntTypes : allVehicleMntTypes) || [];
    return vehicleMntTypesToFilter.filter(type => {
      let validVehicleMntType = true;
      //Filter by search field
      if (debouncedSearchText) {
        validVehicleMntType =
          validVehicleMntType && toLower(type.name).includes(toLower(debouncedSearchText));
      }

      //Filter by companies
      const checkedCompaniesIds = filterCompanies
        .filter(company => company.checked)
        .map(company => parseInt(company.id, 10));
      validVehicleMntType =
        validVehicleMntType && checkedCompaniesIds.indexOf(parseInt(type.companyId, 10)) > -1;
      return validVehicleMntType;
    });
  }, [allVehicleMntTypes, debouncedSearchText, deletedVehicleMntTypes, filterCompanies, filterTab]);

  const typeCounter = useMemo(() => {
    return `${filteredVehicleMntTypes.length} ${
      filteredVehicleMntTypes.length === 1
        ? t('VehicleMaintenanceTypes.Type')
        : t('VehicleMaintenanceTypes.Types')
    }`;
  }, [filteredVehicleMntTypes, t]);

  const [tableRef, setTableRef] = useState(null);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [filteredVehicleMntTypes, tableRef]);

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('VehicleMaintenanceTypes.VehicleMaintenanceTypesTitle')));
  }, [dispatch, t]);

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
  }, [subCompanies, t]);

  // TODO: Modify after release with Ant component
  // function handleFilteredCompaniesChange(newFilter) {
  //   setFilterCompanies(newFilter);
  // }

  const handleAction = useCallback(
    actionObject => {
      if (actionObject.action === TYPE_ACTION.Restore) {
        dispatch(restoreMntTypesApi(actionObject.data));
      }
    },
    [dispatch]
  );

  const handleDeleteAction = useCallback(
    data => () => {
      dispatch(deleteMntTypesApi(data));
    },
    [dispatch]
  );

  return (
    <React.Fragment>
      <Affix offsetTop={63}>
        <div>
          <Row
            justify="end"
            align="middle"
            style={{
              padding: '0 16px',
              zIndex: '5',
              minHeight: '56px',
              background: '#fff'
            }}
          >
            <Col span="auto" style={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  padding: '0',
                  zIndex: '5',
                  minHeight: '56px',
                  background: 'white',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #e8ebee'
                }}
              >
                <div>
                  <TabNavLink
                    to="/settings/vehicleMntTypes/all"
                    isActive={(match, location) => {
                      return [
                        '/',
                        '/settings',
                        '/settings/vehicleMntTypes',
                        '/settings/vehicleMntTypes/all'
                      ].includes(location.pathname);
                    }}
                    onClick={() => {
                      setFilterTab('all');
                    }}
                  >
                    {t(VehicleMntTypesLens.All)}
                  </TabNavLink>
                  <TabNavLink
                    exact
                    to={DELETED_VEHICLES_MNT_TYPES_PATH}
                    onClick={() => {
                      setFilterTab('deleted');
                    }}
                  >
                    {t(VehicleMntTypesLens.Deleted)}
                  </TabNavLink>
                </div>
                <Can everyEntity={[entities.VEHICLEMAINTENANCETYPE_CREATE]}>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      history.push('/settings/vehicleMntTypes/newVehicleMntType');
                    }}
                    id={BUTTON_IDS.vehicleMntTypesAddNew}
                  >
                    {t('VehicleMaintenanceTypes.AddNewType')}
                  </Button>
                </Can>
              </div>
            </Col>
          </Row>
          <Row style={{ background: '#f7f8f9' }}>
            <Col span={24}>
              <AntFilterWrapper>
                <div className="flexRow">
                  <AntSearchbar onFilter={setFilterText} />
                  <AntMultiselect
                    title={
                      filterCompanies?.some(value => !value.checked)
                        ? t('Common.Companies')
                        : t('Common.AllCompanies')
                    }
                    onFilter={setFilterCompanies}
                    data={filterCompanies}
                  />
                </div>
                <div className="typeCounter">{typeCounter}</div>
              </AntFilterWrapper>
            </Col>
          </Row>
        </div>
      </Affix>
      <Row style={{ height: '100%' }}>
        <Col span="auto" style={{ width: '100%' }}>
          <VehicleMntTypesTable
            vehicleMntTypes={filteredVehicleMntTypes}
            companies={companies}
            localization={localization}
            isLoading={isVehicleMntTypesFetching()}
            company={company}
            typeOfEntityToDelete={t('Common.vehicle maintainance type')}
            handleDeleteAction={handleDeleteAction}
            handleAction={handleAction}
            setTableRef={setTableRef}
            filterTab={filterTab}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default VehicleMntTypes;
