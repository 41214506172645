import React, { useState, useCallback } from 'react';
import styles from './UDT.module.scss';
import { Row, Col, Input, Button, Tooltip } from 'antd';
import { DriverSearchInput } from 'features/eld/DriverInputComponent';
import { useTranslation } from 'react-i18next';
import { BUTTON_IDS } from 'utils/globalConstants';

export function UDTAssignCommponent({
  assignMode,
  assignValue,
  isDriverPortal = false,
  hasCheckedRows,
  onSuggestAssign,
  onCancelAssign,
  submitTitle,
  ...props
}) {
  const [comments, setComments] = useState('');
  const [driver, setDriver] = useState(isDriverPortal ? assignValue : null);
  const { t } = useTranslation();

  const handleAssign = useCallback(() => {
    if (onSuggestAssign) {
      onSuggestAssign(comments, driver);
    }
  }, [onSuggestAssign, comments, driver]);
  return (
    <Row className={styles.endOfToolbar} gutter={15}>
      <Col span={12}>
        <Tooltip title={t('ELD.Type in comments for bulk update')}>
          <Input
            minLength={4}
            maxLength={60}
            placeholder={t('ELD.DriverPortal.CommentLength', { min: 4, max: 60 })}
            onChange={evt => setComments(evt.target.value)}
          />
        </Tooltip>
      </Col>
      <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip title={t('ELD.Select a driver for bulk update')}>
          <DriverSearchInput
            onChange={value => setDriver(value)}
            mode={isDriverPortal ? 'text' : ''}
            value={driver}
            defaultValue={driver}
          />
        </Tooltip>
      </Col>
      <Col span={6}>
        <Tooltip
          title={submitTitle(
            hasCheckedRows,
            comments !== null && comments.trim().length > 3 && comments.trim().length < 61,
            isDriverPortal
          )}
        >
          <Button
            type="primary"
            size="large"
            onClick={handleAssign}
            disabled={
              !(
                hasCheckedRows &&
                comments !== null &&
                comments.trim().length > 3 &&
                comments.trim().length < 61
              )
            }
            id={BUTTON_IDS.udtAssignBulkUpdate}
          >
            {isDriverPortal ? t('ELD.DriverPortal.BulkClaim') : t('ELD.Bulk Update')}
          </Button>
        </Tooltip>
        <Button id={BUTTON_IDS.udtAssignCancel} size="large" onClick={onCancelAssign}>
          {t('Common.Cancel')}
        </Button>
      </Col>
    </Row>
  );
}
