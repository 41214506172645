import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LANGUAGE_LOCALES } from 'features/localization/languages';
import { config } from 'utils/i18nConfig';
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(config);

/**
 * extract localed error message
 * @param {Object} error the error json payload returned from API
 * @param {module:model/String} error.id
 * @param {module:model/String} error.error the key of error message
 * @param {module:model/String} error.message the error message
 * @param {module:model/String} error.locale the language code of the error message
 * @param {module:model/String} error.localizedMessage the translated message
 */
export function t_error(error) {
  if (error == null) {
    return '';
  }

  // the error maybe not generated by API, so just return give the error back
  if (!(error.id && error.error && error.message && error.locale)) {
    return error?.error || error;
  }

  // per the API team, the default locale is hardcoded as en, and use the message field
  // if the localized message is not there, or not the same as the current language, fallback to message field
  if (
    i18n.language === LANGUAGE_LOCALES.EN_US ||
    (i18n.language !== error.locale && error.locale.indexOf(i18n.language) < 0)
  ) {
    return error.message;
  } else {
    return error.localizedMessage;
  }
}

export default i18n;

export const i18nConfig = i18n;
