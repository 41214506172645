import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, resetRemoteSignIn, useCurrentAdminLoggedIn } from '../../features/user/userSlice';
import { UserAuthToken } from 'features/user/userAuthToken';

const Logout = props => {
  const dispatch = useDispatch();
  const currentUser = UserAuthToken.get();
  const currentAdminUser = useCurrentAdminLoggedIn();
  const keycloakConfig =
    currentUser?.auth?.keycloakConfig || currentAdminUser?.auth?.keycloakConfig;

  let redirectUrl = '/login';
  if (keycloakConfig != null) {
    redirectUrl = '/sso';
    if (keycloakConfig?.realm) {
      redirectUrl += '?realm=' + keycloakConfig.realm + '&logoutType=1';
    }
    if (window.keycloak?.logout) {
      window.keycloak.logout({
        redirectUri: window.location.origin + redirectUrl
      });
    }
  }

  useEffect(() => {
    dispatch(logout());
    dispatch(resetRemoteSignIn());
  }, []);

  console.log('redirect url is ' + redirectUrl);
  return <Redirect to={redirectUrl} />;
};

export default Logout;
