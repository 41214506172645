import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { API_PATH } from 'config';
import { prepareAuthHeaders } from 'services/util';

export const ngSharedLinksApi = createApi({
  reducerPath: 'ngSharedLinksApi',
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: `${API_PATH}`,
      prepareHeaders: prepareAuthHeaders
    })
  ),
  endpoints: builder => ({
    getSharedLinks: builder.query({
      query: ({ companyId }) => ({
        url: 'links',
        params: { company_id: companyId }
      }),
      providesTags: ['SharedLinks']
    }),
    deleteSharedLink: builder.mutation({
      query: ({ keyId }) => ({
        url: `links/${keyId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['SharedLinks']
    })
  })
});

export const { useGetSharedLinksQuery, useDeleteSharedLinkMutation } = ngSharedLinksApi;
