import React from 'react';
import i18n from 'i18next';

import hideNonBusinessSVG from 'static/images/icons/incognito-circle.svg';

import styles from './Common.module.scss';

const HideNonBusinessStatus = ({ alignLeft = false, bold = false, customStyle }) => (
  <div className={`${styles.hideNonBusiness} ${alignLeft ? styles.hideNonBusinessLeftAlign : ''}`}>
    <i style={{ width: '25px', content: `url(${hideNonBusinessSVG})` }} />
    <span className={`${bold ? styles.bold : ''} ${customStyle || ''}`}>
      {i18n.t('CompanyConfig.HideNonBusiness.NonBusinessTrip')}
    </span>
  </div>
);

export default HideNonBusinessStatus;
