import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { useEffect, useRef } from 'react';

const pretripChecklists = {
  list: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false
  }
};

function startLoading(state, { payload }) {
  state.meta.isFetching = true;
  state.meta.companyKey = payload.companyKey;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.list = [];
  state.meta.companyKey = action.payload.companyKey;
}

const pretripChecklistsSlice = createSlice({
  name: 'pretripChecklists',
  initialState: pretripChecklists,
  reducers: {
    fetchPretripChecklistsStart: startLoading,
    fetchPretripChecklistsSuccess(state, { payload }) {
      state.list = payload.list.sort((a, b) => a.name?.localeCompare(b.name));
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchPretripChecklistsCancelled(state) {
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.isListEmpty = true;
      state.list = [];
    },
    fetchPretripChecklistsFailure: loadingFailed
  }
});

const {
  fetchPretripChecklistsStart,
  fetchPretripChecklistsSuccess,
  fetchPretripChecklistsCancelled,
  fetchPretripChecklistsFailure
} = pretripChecklistsSlice.actions;

export const fetchPretripChecklists = fetchRef => async (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  const userKey = getState().user.current.auth.key;
  const companyId = getState().companies?.current?.id;

  const assignRef = value => {
    if (fetchRef) {
      fetchRef.current = value;
    }
  };

  try {
    dispatch(fetchPretripChecklistsStart({ companyKey }));
    const getRequest = request('GET', `${API_PATH}/pretripchecklists?company_id=${companyId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json');

    assignRef(getRequest);

    getRequest.on('abort', () => {
      assignRef(null);
      dispatch(fetchPretripChecklistsCancelled());
    });

    const response = await getRequest;
    assignRef(null);

    const { body } = response;
    dispatch(fetchPretripChecklistsSuccess({ list: body, companyKey }));
  } catch (err) {
    console.warn('ERROR', err);
    assignRef(null);
    dispatch(fetchPretripChecklistsFailure({ err: err.toString(), companyKey }));
  }
};

export const useIsFetching = () => useSelector(state => state.pretripChecklists.meta.isFetching);

export const usePretripChecklists = () => {
  const dispatch = useDispatch();
  const pretripChecklists = useSelector(state => state.pretripChecklists.list);
  const isFetching = useIsFetching();
  const isListEmpty = useSelector(state => state.pretripChecklists.meta.isListEmpty);
  const companyKey = useSelector(state => state.pretripChecklists.meta.companyKey);
  const currentCompanyKey = useCurrentCompanyKey();
  const isCompanyKeyDifferent = (currentCompanyKey && companyKey !== currentCompanyKey) || false;
  const couldFetch = pretripChecklists.length === 0 && !isFetching && !isListEmpty;

  const fetchRef = useRef(null);

  useEffect(() => {
    if ((couldFetch || isCompanyKeyDifferent) && !fetchRef.current) {
      dispatch(fetchPretripChecklists(fetchRef));
    }
  }, [dispatch, couldFetch, isCompanyKeyDifferent]);

  useEffect(() => {
    return () => {
      if (fetchRef.current) {
        fetchRef.current.abort();
        fetchRef.current = null;
      }
    };
  }, [currentCompanyKey]);

  return pretripChecklists;
};

export default pretripChecklistsSlice.reducer;
