import React from 'react';
import { Route, Switch } from 'react-router';
import { SmartJobs, RunsheetForm } from '../index';
import RunsheetView from '../RunsheetView';
import JobView from '../JobView/JobView';
import { Paths } from '../utils/constants';
import { Can, entities } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';

export const SmartJobsRouter = props => {
  return (
    <Switch>
      <Route path={Paths.JOBS_VIEW}>
        <Can everyEntity={[entities.RUNSHEET_VIEW]} onFail={<NoAccessBody />}>
          <JobView />
        </Can>
      </Route>
      <Route path={Paths.NEW_RUNSHEET}>
        <Can everyEntity={[entities.RUNSHEET_CREATE]} onFail={<NoAccessBody />}>
          <RunsheetForm />
        </Can>
      </Route>
      <Route path={Paths.EDIT_RUNSHEET}>
        <Can everyEntity={[entities.RUNSHEET_UPDATE]} onFail={<NoAccessBody />}>
          <RunsheetForm action="Edit" />
        </Can>
      </Route>
      <Route path={Paths.COPY_RUNSHEET}>
        <Can everyEntity={[entities.RUNSHEET_COPY]} onFail={<NoAccessBody />}>
          <RunsheetForm action="Copy" />
        </Can>
      </Route>
      <Route path={Paths.SMARTJOBS_VIEW}>
        <Can everyEntity={[entities.RUNSHEET_VIEW]} onFail={<NoAccessBody />}>
          <RunsheetView />
        </Can>
      </Route>
      <Route path={Paths.SMARTJOBS_DEFAULT}>
        <Can everyEntity={[entities.RUNSHEET]} onFail={<NoAccessBody />}>
          <SmartJobs />
        </Can>
      </Route>
    </Switch>
  );
};
