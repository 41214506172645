import { useCurrentCompany } from 'features/company/companySlice';
import { fetchLogExport, useGetLogExports } from 'features/eld/logExportSlice';
import React, { useEffect, useState } from 'react';
import { LogExportToolbar } from './LogExportToolbar';
import { LogExportGrid } from './LogExportGrid';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import { useDrivers } from 'features/users/usersSlice';
import { useVehicles } from 'features/fleets/fleetsSlice';
import { LogExportGridColumns } from './GridColumns';
import { useUserKey } from 'features/user/userSlice';
import { useDispatch } from 'react-redux';
import styles from './LogExport.module.scss';
import i18next from 'i18nextConfig';
import { LogTypeDescription } from './CellRender';
import { useTranslation } from 'react-i18next';

const MapStatusToDesc = {
  get New() {
    return i18next.t('ELD.Log Transfer Created');
  },
  get GenerateFileSuccessful() {
    return i18next.t('ELD.Generate Log Transfer File Succeeded');
  },
  get GenerateFileFailed() {
    return i18next.t('ELD.Generate Log Transfer File Failed');
  },
  get EmailSendSuccessful() {
    return i18next.t('ELD.Log Transfer via Email Succeeded');
  },
  get EmailSendFailed() {
    return i18next.t('ELD.Log Transfer via Email Failed');
  },
  get WebServiceSendSuccessful() {
    return i18next.t('ELD.Log Transfer via Web Service Succeeded');
  },
  get WebServiceSendFailed() {
    return i18next.t('ELD.Log Transfer via Web Service Failed');
  },
  get EmailSentPending() {
    return i18next.t('ELD.Log Transfer via Email Sent Pending');
  },
  get ValidationFailed() {
    return i18next.t('ELD.Log Transfer Validation Failed');
  }
};

export function LogExport(props) {
  const company = useCurrentCompany();
  const userKey = useUserKey();
  const logExports = useGetLogExports(company?.id);
  const [search, setSearch] = useState('');
  const localization = useLocalization();
  const drivers = useDrivers();
  const vehicles = useVehicles();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchLogExport(company?.id, userKey));
  }, [company, userKey, dispatch]);

  useEffect(() => {
    let logs = Array.from(logExports || [], r => {
      const transformedData = {
        key: r.id,
        logType: r.userId ? 'Driver' : 'Vehicle',
        logTypeDesc: LogTypeDescription[r.userId ? 'Driver' : 'Vehicle'],
        dateRanges: `${format(
          new Date(r.periodBegin),
          localization.formats.time.formats.dby
        )} - ${format(new Date(r.periodEnd), localization.formats.time.formats.dby)}`,
        driver_vehicle: '',
        comment: r.comment,
        periodBegin: r.periodBegin
      };
      if (r.userId != null) {
        const driver = drivers?.find(d => d.id === r.userId);
        transformedData.driver_vehicle = driver ? driver.firstName + ' ' + driver.lastName : '';
      } else if (r.vehicleId != null) {
        const vehicle = vehicles?.find(d => d.id === r.vehicleId);
        transformedData.driver_vehicle = vehicle?.name || '';
      }

      transformedData.transferStatus = [];
      if (r.sendEmail) {
        Object.assign(transformedData, {
          status: r.emailStatus,
          logExportTo: 'Email',
          logExportToDesc: t('ELD.Email'),
          transferStatus: MapStatusToDesc[r.emailStatus],
          updatedAt: r.emailStatusAt
            ? format(new Date(r.emailStatusAt), localization.formats.time.formats.dby_imp)
            : ''
        });
      }

      if (r.sendToWebService) {
        Object.assign(transformedData, {
          status: r.webServiceStatus,
          logExportTo: 'Web Service',
          logExportToDesc: t('ELD.Web Service'),
          transferStatus: MapStatusToDesc[r.webServiceStatus],
          updatedAt: r.webServiceStatusAt
            ? format(new Date(r.webServiceStatusAt), localization.formats.time.formats.dby_imp)
            : ''
        });
      }

      return transformedData;
    });
    if (search.trim() !== '') {
      logs = logs.filter(l => {
        return LogExportGridColumns.filter(c => c.canSearch).some(c =>
          l[c.dataIndex]
            ?.toString()
            ?.toLowerCase()
            .includes(search.toLowerCase())
        );
      });
    }
    setData(logs);
  }, [logExports, search, localization, drivers, vehicles, t]);

  return (
    <div className={styles.fatigueManagement}>
      <LogExportToolbar gridCount={data.length} onSearchChanged={value => setSearch(value)} />
      <LogExportGrid data={data} />
    </div>
  );
}
