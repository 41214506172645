export const auditsSelectors = {
  getAudits: state => state.audits.list,
  isFetching: state => state.audits.isFetching,
  isSuccess: state => state.audits.success,
  isError: state => state.audits.error,
  lastFetched: state => state.audits.lastFetched
};

export const vehicleDeviceAssociationAuditsSelectors = {
  getAudits: state => state.vehicleDeviceAssociationAudits.list,
  isFetching: state => state.vehicleDeviceAssociationAudits.isFetching,
  isSuccess: state => state.vehicleDeviceAssociationAudits.success,
  isError: state => state.vehicleDeviceAssociationAudits.error,
  lastFetched: state => state.vehicleDeviceAssociationAudits.lastFetched
};

export const bulkEditAuditsSelectors = {
  getAudits: state => state.bulkEditAudits
};
