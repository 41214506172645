import React from 'react';
import { useTranslation } from 'react-i18next';
import XLSX from 'xlsx';
import { Button } from 'components/ant';
import { useLocalization } from 'features/localization/localizationSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

const lower = (str = '') => str.toLowerCase();

export const exportFile = (data, properties) => () => {
  const { width, title, dateFormat } = properties;

  const workbook = XLSX.utils.book_new();
  var worksheet = XLSX.utils.json_to_sheet(
    data.sort((a, b) =>
      lower(a?.Name) > lower(b?.Name) ? 1 : lower(b?.Name) > lower(a?.Name) ? -1 : 0
    ),
    {
      cellDates: true,
      dateNF: dateFormat.replace('A', '')
    }
  );
  worksheet['!cols'] = width;

  XLSX.utils.book_append_sheet(workbook, worksheet, title);
  worksheet['!autoFilter'] = { ref: 'A1:T102' };

  XLSX.writeFile(workbook, `${title}.xlsx`);
};

export const ExcelFile = ({ data, width, title }) => {
  const localization = useLocalization();
  const { t } = useTranslation();

  return (
    <Button
      size="large"
      onClick={exportFile(data, {
        width,
        title,
        dateFormat: localization.formats.time.formats.dby_imsp
      })}
      id={BUTTON_IDS.exportExcel}
    >
      {t('Common.ExporttoExcel')}
    </Button>
  );
};
