/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import DeviceStatusEvent from "./DeviceStatusEvent";
import Gps from "./Gps";
import Runsheet from "./Runsheet";
import User from "./User";

/**
 * The DeviceStats model module.
 * @module model/DeviceStats
 * @version 1.0
 */
class DeviceStats {
  /**
   * Constructs a new <code>DeviceStats</code>.
   * @alias module:model/DeviceStats
   */
  constructor() {
    DeviceStats.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>DeviceStats</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DeviceStats} obj Optional instance to populate.
   * @return {module:model/DeviceStats} The populated <code>DeviceStats</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DeviceStats();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("deviceId")) {
        obj["deviceId"] = ApiClient.convertToType(data["deviceId"], "Number");
      }
      if (data.hasOwnProperty("lastCommsAt")) {
        obj["lastCommsAt"] = ApiClient.convertToType(
          data["lastCommsAt"],
          "String"
        );
      }
      if (data.hasOwnProperty("lastEvent")) {
        obj["lastEvent"] = ApiClient.convertToType(data["lastEvent"], "String");
      }
      if (data.hasOwnProperty("lastEventAt")) {
        obj["lastEventAt"] = ApiClient.convertToType(
          data["lastEventAt"],
          "String"
        );
      }
      if (data.hasOwnProperty("odometer")) {
        obj["odometer"] = ApiClient.convertToType(data["odometer"], "Number");
      }
      if (data.hasOwnProperty("ignition")) {
        obj["ignition"] = ApiClient.convertToType(data["ignition"], "String");
      }
      if (data.hasOwnProperty("obm")) {
        obj["obm"] = ApiClient.convertToType(data["obm"], "String");
      }
      if (data.hasOwnProperty("obmAt")) {
        obj["obmAt"] = ApiClient.convertToType(data["obmAt"], "String");
      }
      if (data.hasOwnProperty("sdmTcm")) {
        obj["sdmTcm"] = ApiClient.convertToType(data["sdmTcm"], "Number");
      }
      if (data.hasOwnProperty("sdmAt")) {
        obj["sdmAt"] = ApiClient.convertToType(data["sdmAt"], "String");
      }
      if (data.hasOwnProperty("gpsAt")) {
        obj["gpsAt"] = ApiClient.convertToType(data["gpsAt"], "String");
      }
      if (data.hasOwnProperty("gps")) {
        obj["gps"] = Gps.constructFromObject(data["gps"]);
      }
      if (data.hasOwnProperty("currentUserId")) {
        obj["currentUserId"] = ApiClient.convertToType(
          data["currentUserId"],
          "Number"
        );
      }
      if (data.hasOwnProperty("location")) {
        obj["location"] = ApiClient.convertToType(data["location"], "String");
      }
      if (data.hasOwnProperty("devAppVersion")) {
        obj["devAppVersion"] = ApiClient.convertToType(
          data["devAppVersion"],
          "String"
        );
      }
      if (data.hasOwnProperty("devAppInfo")) {
        obj["devAppInfo"] = ApiClient.convertToType(
          data["devAppInfo"],
          "String"
        );
      }
      if (data.hasOwnProperty("devVersion")) {
        obj["devVersion"] = ApiClient.convertToType(
          data["devVersion"],
          "String"
        );
      }
      if (data.hasOwnProperty("devInfo")) {
        obj["devInfo"] = ApiClient.convertToType(data["devInfo"], "String");
      }
      if (data.hasOwnProperty("storageInfo")) {
        obj["storageInfo"] = ApiClient.convertToType(
          data["storageInfo"],
          "String"
        );
      }
      if (data.hasOwnProperty("squarellVersion")) {
        obj["squarellVersion"] = ApiClient.convertToType(
          data["squarellVersion"],
          "String"
        );
      }
      if (data.hasOwnProperty("squarellInfo")) {
        obj["squarellInfo"] = ApiClient.convertToType(
          data["squarellInfo"],
          "String"
        );
      }
      if (data.hasOwnProperty("lastDuressId")) {
        obj["lastDuressId"] = ApiClient.convertToType(
          data["lastDuressId"],
          "Number"
        );
      }
      if (data.hasOwnProperty("lastDuressAt")) {
        obj["lastDuressAt"] = ApiClient.convertToType(
          data["lastDuressAt"],
          "String"
        );
      }
      if (data.hasOwnProperty("deviceStatusEvent")) {
        obj["deviceStatusEvent"] = DeviceStatusEvent.constructFromObject(
          data["deviceStatusEvent"]
        );
      }
      if (data.hasOwnProperty("isShutdown")) {
        obj["isShutdown"] = ApiClient.convertToType(
          data["isShutdown"],
          "Boolean"
        );
      }
      if (data.hasOwnProperty("shutdownOdo")) {
        obj["shutdownOdo"] = ApiClient.convertToType(
          data["shutdownOdo"],
          "Number"
        );
      }
      if (data.hasOwnProperty("shutdownAt")) {
        obj["shutdownAt"] = ApiClient.convertToType(
          data["shutdownAt"],
          "String"
        );
      }
      if (data.hasOwnProperty("runsheetId")) {
        obj["runsheetId"] = ApiClient.convertToType(
          data["runsheetId"],
          "Number"
        );
      }
      if (data.hasOwnProperty("dynamicFeatures")) {
        obj["dynamicFeatures"] = ApiClient.convertToType(
          data["dynamicFeatures"],
          ["String"]
        );
      }
      if (data.hasOwnProperty("smartNavInfo")) {
        obj["smartNavInfo"] = ApiClient.convertToType(
          data["smartNavInfo"],
          "String"
        );
      }
      if (data.hasOwnProperty("currentUser")) {
        obj["currentUser"] = User.constructFromObject(data["currentUser"]);
      }
      if (data.hasOwnProperty("runsheet")) {
        obj["runsheet"] = Runsheet.constructFromObject(data["runsheet"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
DeviceStats.prototype["id"] = undefined;

/**
 * @member {Number} deviceId
 */
DeviceStats.prototype["deviceId"] = undefined;

/**
 * @member {String} lastCommsAt
 */
DeviceStats.prototype["lastCommsAt"] = undefined;

/**
 * @member {String} lastEvent
 */
DeviceStats.prototype["lastEvent"] = undefined;

/**
 * @member {String} lastEventAt
 */
DeviceStats.prototype["lastEventAt"] = undefined;

/**
 * @member {Number} odometer
 */
DeviceStats.prototype["odometer"] = undefined;

/**
 * @member {String} ignition
 */
DeviceStats.prototype["ignition"] = undefined;

/**
 * @member {String} obm
 */
DeviceStats.prototype["obm"] = undefined;

/**
 * @member {String} obmAt
 */
DeviceStats.prototype["obmAt"] = undefined;

/**
 * @member {Number} sdmTcm
 */
DeviceStats.prototype["sdmTcm"] = undefined;

/**
 * @member {String} sdmAt
 */
DeviceStats.prototype["sdmAt"] = undefined;

/**
 * @member {String} gpsAt
 */
DeviceStats.prototype["gpsAt"] = undefined;

/**
 * @member {module:model/Gps} gps
 */
DeviceStats.prototype["gps"] = undefined;

/**
 * @member {Number} currentUserId
 */
DeviceStats.prototype["currentUserId"] = undefined;

/**
 * @member {String} location
 */
DeviceStats.prototype["location"] = undefined;

/**
 * @member {String} devAppVersion
 */
DeviceStats.prototype["devAppVersion"] = undefined;

/**
 * @member {String} devAppInfo
 */
DeviceStats.prototype["devAppInfo"] = undefined;

/**
 * @member {String} devVersion
 */
DeviceStats.prototype["devVersion"] = undefined;

/**
 * @member {String} devInfo
 */
DeviceStats.prototype["devInfo"] = undefined;

/**
 * @member {String} storageInfo
 */
DeviceStats.prototype["storageInfo"] = undefined;

/**
 * @member {String} squarellVersion
 */
DeviceStats.prototype["squarellVersion"] = undefined;

/**
 * @member {String} squarellInfo
 */
DeviceStats.prototype["squarellInfo"] = undefined;

/**
 * @member {Number} lastDuressId
 */
DeviceStats.prototype["lastDuressId"] = undefined;

/**
 * @member {String} lastDuressAt
 */
DeviceStats.prototype["lastDuressAt"] = undefined;

/**
 * @member {module:model/DeviceStatusEvent} deviceStatusEvent
 */
DeviceStats.prototype["deviceStatusEvent"] = undefined;

/**
 * @member {Boolean} isShutdown
 */
DeviceStats.prototype["isShutdown"] = undefined;

/**
 * @member {Number} shutdownOdo
 */
DeviceStats.prototype["shutdownOdo"] = undefined;

/**
 * @member {String} shutdownAt
 */
DeviceStats.prototype["shutdownAt"] = undefined;

/**
 * @member {Number} runsheetId
 */
DeviceStats.prototype["runsheetId"] = undefined;

/**
 * @member {Array.<String>} dynamicFeatures
 */
DeviceStats.prototype["dynamicFeatures"] = undefined;

/**
 * @member {String} smartNavInfo
 */
DeviceStats.prototype["smartNavInfo"] = undefined;

/**
 * @member {module:model/User} currentUser
 */
DeviceStats.prototype["currentUser"] = undefined;

/**
 * @member {module:model/Runsheet} runsheet
 */
DeviceStats.prototype["runsheet"] = undefined;

export default DeviceStats;
