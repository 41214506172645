import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DriverLog.module.scss';
import { DutyStatus } from './util';

export function DriverInfoCard({ data, ...props }) {
  const { userName, vehicleName, status } = data;
  const { t } = useTranslation();
  return (
    <div className={styles.driverInfoCard}>
      <div className={styles.infoCard}>
        <div>
          <span className={styles.userName}>{userName || ''}</span>
        </div>
        <div>
          <span className={styles.vehicleName}>{vehicleName || ''}</span>
        </div>
        <div>
          <span className={styles.status}>{t(`ELD.DutyStatus.${DutyStatus[status]}`)}</span>
        </div>
      </div>
    </div>
  );
}
