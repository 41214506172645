import React, { useMemo } from 'react';
import { Table } from 'antd';
import { getChangesByKeyTableData, CHANGE_WIDTH, MAX_CELL_HEIGHT } from '../helpers';
import { ChangeCell } from './Cell/ChangeCell';
import { EntityListCell } from './Cell/EntityListCell';

import styles from '../BulkEditAuditsTable.module.scss';

export const ChangesByKeyTable = ({ changeKey, changesByKey = [], entity }) => {
  const dataSource = useMemo(
    () => getChangesByKeyTableData({ changesByKey, entity, idPrefix: changeKey }),
    [changesByKey, entity, changeKey]
  );
  return (
    <Table
      dataSource={dataSource}
      pagination={false}
      showHeader={false}
      className={styles.nestedTable}
      rowKey={record => String(record.id)}
      size="small"
    >
      <Table.Column
        key="from"
        width={CHANGE_WIDTH.FROM}
        onCell={record => ({
          style: { height: record.isPairs ? `${MAX_CELL_HEIGHT}px` : `${MAX_CELL_HEIGHT / 3}px` }
        })}
        render={(change, record, index) => (
          <ChangeCell
            change={record}
            changeKey={`${changeKey}-${index}`}
            isPairs={record.isPairs}
            isFrom
          />
        )}
      />
      <Table.Column
        key="to"
        width={CHANGE_WIDTH.TO}
        onCell={record => ({
          style: { height: record.isPairs ? `${MAX_CELL_HEIGHT}px` : `${MAX_CELL_HEIGHT / 3}px` }
        })}
        render={(change, record, index) => (
          <ChangeCell
            change={record}
            changeKey={`${changeKey}-${index}`}
            isPairs={record.isPairs}
          />
        )}
      />
      <Table.Column
        dataIndex="entities"
        width={CHANGE_WIDTH.ENTITY}
        render={entities => <EntityListCell entity={entity} entities={entities} />}
      />
    </Table>
  );
};
