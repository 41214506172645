import { createSlice } from '@reduxjs/toolkit';
import { fetchContacts, deleteContact, addContact, updateContact } from '../thunks';

const initialState = {
  list: {},
  meta: {},
  isUpdatingContact: false
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  extraReducers: {
    [fetchContacts.pending]: (state, { meta }) => {
      state.meta[meta.arg] = {
        isFetching: true,
        success: false,
        error: false
      };
    },
    [fetchContacts.fulfilled]: (state, { payload, meta }) => {
      state.meta[meta.arg] = {
        isFetching: false,
        success: true,
        error: false
      };
      state.list[payload.locationId] = payload.list.sort((contactA, contactB) =>
        contactA.name.toLowerCase() > contactB.name.toLowerCase() ? 1 : -1
      );
    },
    [fetchContacts.rejected]: (state, { meta }) => {
      state.meta[meta.arg] = {
        isFetching: false,
        success: false,
        error: true
      };
    },
    [deleteContact.pending]: state => {
      state.isUpdatingContact = true;
    },
    [deleteContact.fulfilled]: state => {
      state.isUpdatingContact = false;
    },
    [deleteContact.rejected]: state => {
      state.isUpdatingContact = false;
    },
    [addContact.pending]: state => {
      state.isUpdatingContact = true;
    },
    [addContact.fulfilled]: state => {
      state.isUpdatingContact = false;
    },
    [addContact.rejected]: state => {
      state.isUpdatingContact = false;
    },
    [updateContact.pending]: state => {
      state.isUpdatingContact = true;
    },
    [updateContact.fulfilled]: state => {
      state.isUpdatingContact = false;
    },
    [updateContact.rejected]: state => {
      state.isUpdatingContact = false;
    }
  }
});
