import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Modal, Checkbox, Card, Collapse, Radio } from 'antd';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { DateRangePicker, Select } from 'components/ant';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { uniqBy, sortBy, toLower } from 'lodash';
import { useLocalization } from 'features/localization/localizationSlice';
import dayjs from 'dayjs';
import moment from 'moment';
import { prepareDataForMultiselect, FilterTypes } from 'utils/filters';
import { useSubCompanies } from 'features/company/companySlice';
import { useBranches } from 'features/locations/locationsSlice';
import {
  useVehiclesFromFleets,
  useFleets,
  useIsFetching as isFleetsFetching,
  useDevices
} from 'features/fleets/fleetsSlice';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import { useUsers } from 'features/users/usersSlice';
import { useGetTimezonesQuery } from 'services/nextgen';

import styles from './modals.module.scss';

export const ConfigureReportModal = ({ isOpen, onSave, onClose }) => {
  const { t } = useTranslation();

  //const dispatch = useDispatch();
  const localization = useLocalization();
  const CheckboxGroup = Checkbox.Group;
  const plainOptions = [
    t('Company Name'),
    t('Vehicle Name'),
    t('Driver Name'),
    t('Fleet'),
    t('Branch'),
    t('Device Name'),
    t('Vehicle Type'),
    t('Device Type')
  ];
  const defaultCheckedList = [
    t('Company Name'),
    t('Vehicle Name'),
    t('Driver Name'),
    t('Fleet'),
    t('Branch'),
    t('Device Name'),
    t('Vehicle Type'),
    t('Device Type')
  ];
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const checkAll = plainOptions.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length;
  const history = useHistory();
  const datePickerRef = useRef();
  const [datePickerOpen, setDatePickerOpen] = useState();
  const [filterCompanies, setFilterCompanies] = useState([]);
  const subCompanies = useSubCompanies();
  const [filterText, setFilterText] = useState('');
  const branches = useBranches();
  const [filterBranches, setFilterBranches] = useState([]);
  const { vehicles } = useVehiclesFromFleets();
  const [filterVehicles, setFilterVehicles] = useState([]);
  const fleets = useFleets();
  const [filteredFleets, setFilteredFleets] = useState([]);
  const [filterVehicleType, setFilterVehicleType] = useState([]);
  const deviceTypes = useDeviceTypesList();
  const [filterDeviceTypes, setFilterDeviceTypes] = useState([]);
  const allUsers = useUsers();
  const [filterDrivers, setFilterDrivers] = useState([]);
  const { data: newTimezones } = useGetTimezonesQuery();
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const allDevices = useDevices('vehicles,devices,services');
  const [filterDevices, setFilterDevices] = useState(allDevices);

  const BRANCH_TYPE = {
    NO_BRANCH: -1,
    ALL_BRANCHES: 0
  };

  const [dateFrom, setDateFrom] = useState(
    history.location.state?.dateFrom ||
      moment()
        .add(-6, 'days')
        .format('YYYY-MM-DD')
  );
  const [dateTo, setDateTo] = useState(
    history.location.state?.dateTo || moment().format('YYYY-MM-DD')
  );

  useEffect(() => {
    datePickerRef.current?.resetDates([dayjs(dateFrom), dayjs(dateTo)]);
  }, [dateTo]);

  const handleDateRangeChange = dates => {
    if (!dates || !dates[0] || !dates[1]) {
      return;
    }
    const tempStartAt = moment(dates[0].format('YYYY-MM-DD'));
    const tempEndAt = moment(dates[1].format('YYYY-MM-DD'));

    const startAt = tempStartAt;
    const endAt = tempEndAt;

    setDateFrom(startAt.format('YYYY-MM-DD'));
    setDateTo(endAt.format('YYYY-MM-DD'));

    history.replace(history.location.path, {
      dateFrom: startAt.format('YYYY-MM-DD'),
      dateTo: endAt.format('YYYY-MM-DD')
    });
  };

  const handleDateRangeClose = isOpen => {
    setDatePickerOpen(isOpen);
  };

  const onChange = list => {
    setCheckedList(list);
  };
  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? plainOptions : []);
  };

  useEffect(() => {
    datePickerRef.current?.resetDates([dayjs(dateFrom), dayjs(dateTo)]);
  }, [dateTo]);

  const disabledDate = current => {
    // Can not select days after today or farther than 30 days from the start date
    const isDateToSelected = document.querySelector(
      '.ant-picker-input:first-child.ant-picker-input-active'
    );
    const inputValue = document.querySelector('.ant-picker-input:first-child input')?.value;
    const dateFrom = inputValue ? moment(inputValue) : moment();
    if (isDateToSelected) {
      return current && current > moment().endOf('day');
    }
    return current && (current > moment().endOf('day') || current > dateFrom.add(30, 'day'));
  };

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
    setFilterText('');
  }, [subCompanies, t]);

  // Update branches based on the company filter
  useEffect(() => {
    let checkedCompaniesIds = filterCompanies
      .filter(company => company.checked)
      .map(company => parseInt(company.id, 10));

    const branchOptions = branches.filter(branch => {
      let validBranch = true;
      if (branch.id !== BRANCH_TYPE.ALL_BRANCHES && branch.id !== BRANCH_TYPE.NO_BRANCH) {
        validBranch = checkedCompaniesIds.indexOf(parseInt(branch.companyId, 10)) > -1;
      }
      return validBranch;
    });

    setFilterBranches(prepareDataForMultiselect(branchOptions, t('Common.AllBranches'), null));
  }, [filterCompanies, branches, t]);

  useEffect(() => {
    const formattedBranches = prepareDataForMultiselect(branches, t('Common.AllBranches'), null);
    setFilterBranches(formattedBranches);
  }, [branches, t]);

  // Update vehicles based on the company filter
  useMemo(() => {
    let checkedCompaniesIds = filterCompanies
      .filter(company => company.checked)
      .map(company => parseInt(company.id, 10));

    const vehicleOptions = vehicles.filter(vehicle => {
      let validVehicle = true;
      //if (branch.id !== BRANCH_TYPE.ALL_BRANCHES && branch.id !== BRANCH_TYPE.NO_BRANCH) {
      validVehicle = checkedCompaniesIds.indexOf(parseInt(vehicle.companyId, 10)) > -1;
      //};
      return validVehicle;
    });

    setFilterVehicles(prepareDataForMultiselect(vehicleOptions, t('Common.AllVehicles'), null));
  }, [filterCompanies, vehicles, t]);

  useMemo(() => {
    const formattedVehicles = prepareDataForMultiselect(
      vehicles.filter(vehicle => !!vehicle.id),
      t('Common.AllVehicles'),
      null
    );
    setFilterVehicles(formattedVehicles);
  }, [vehicles, t]);

  // Update fleets based on the company filter
  useEffect(() => {
    let checkedCompaniesIds = filterCompanies
      .filter(company => company.checked)
      .map(company => parseInt(company.id, 10));

    const fleetOptions = fleets.filter(fleets => {
      let validFleet = true;
      validFleet = checkedCompaniesIds.indexOf(parseInt(fleets.companyId, 10)) > -1;
      return validFleet;
    });

    setFilteredFleets(prepareDataForMultiselect(fleetOptions, t('Common.AllFleets'), null));
  }, [filterCompanies, fleets, t]);

  useEffect(() => {
    const formattedFleets = prepareDataForMultiselect(fleets, t('Common.AllFleets'), null);
    setFilteredFleets(formattedFleets);
  }, [fleets, t]);

  useEffect(() => {
    const subCompaniesIdsFromFilter = filterCompanies
      .filter(comp => comp.checked)
      .map(comp => comp.id);

    const vehicleTypes = sortBy(
      uniqBy(
        vehicles
          .filter(
            vehicle =>
              vehicle.id !== undefined &&
              vehicle.companyId &&
              subCompaniesIdsFromFilter.includes(vehicle.companyId)
          )
          .map(vehicle => ({ id: vehicle.type.id, name: vehicle.type.name })),
        'id'
      ),
      'name'
    );

    setFilterVehicleType(prepareDataForMultiselect(vehicleTypes, t('Common.AllTypes'), null));
  }, [history, vehicles, filterCompanies, t]);

  useEffect(() => {
    setFilterDeviceTypes(
      prepareDataForMultiselect(
        deviceTypes.slice().sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        t('Fleets.Form.AllDeviceTypes'),
        null
      )
    );
  }, [deviceTypes, subCompanies, t]);

  // Filter users to get Drivers
  useEffect(() => {
    const subCompaniesIdsFromFilter = filterCompanies
      .filter(comp => comp.checked)
      .map(comp => comp.id);

    const drivers = sortBy(
      uniqBy(
        allUsers
          .filter(
            user =>
              user.id !== undefined &&
              user.companyId &&
              subCompaniesIdsFromFilter.includes(user.companyId) &&
              toLower(user.type.code) === 'driver'
          )
          .map(user => ({ id: user.id, name: user.name })),
        'id'
      ),
      'name'
    );

    setFilterDrivers(prepareDataForMultiselect(drivers, t('Common.AllDrivers'), null));
  }, [allUsers, filterCompanies, t]);

  useEffect(() => {
    const formattedDrivers = prepareDataForMultiselect(
      allUsers.filter(user => !!user.id),
      t('Common.AllDrivers'),
      null
    );
    setFilterDrivers(formattedDrivers);
  }, [allUsers, t]);

  const handleTimezoneChange = val => {
    setSelectedTimezone(val);
    //setIsFormDirty(true);
  };

  useEffect(() => {
    const subCompaniesIdsFromFilter = filterCompanies
      .filter(comp => comp.checked)
      .map(comp => comp.id);

    const devices = sortBy(
      uniqBy(
        allDevices
          .filter(
            device =>
              device.id !== undefined &&
              device.companyId &&
              subCompaniesIdsFromFilter.includes(device.companyId)
          )
          .map(device => ({ id: device.id, name: device.name })),
        'id'
      ),
      'name'
    );

    setFilterDevices(prepareDataForMultiselect(devices, t('Common.AllDevices'), null));
  }, [allDevices, filterCompanies, t]);

  const items = [
    {
      key: '1',
      label: <h5>{t('Scorecard.Period')}</h5>,
      children: (
        <Card>
          <DateRangePicker
            className={styles.dateRangePicker}
            //size="small"
            width={300}
            format={localization.formats.time.formats.dby.toUpperCase()}
            maxDayRange={30}
            defaultDates={[dayjs(dateFrom), dayjs(dateTo)]}
            disabledDate={current => disabledDate(current, dayjs(dateFrom))}
            onDateRangeChanged={dates => {
              handleDateRangeChange(dates);
            }}
            availableDatesRange={[0, moment().endOf('day')]}
            onOpenChange={handleDateRangeClose}
            datePickerRef={datePickerRef}
          />
          <br></br>
          <br></br>
          <h6>{t('ExportToExcel.TimeZone')}</h6>
          <Select
            width={300}
            name="newTimezones"
            data={newTimezones || []}
            value={selectedTimezone || null}
            status={!selectedTimezone ? 'error' : null}
            errorMessage={t('Users.ValidationErrors.RequiredTimezone')}
            onSelect={handleTimezoneChange}
            placeholder={t('ExportToExcel.TimeZone')}
          ></Select>
        </Card>
      )
    },
    {
      key: '2',
      label: <h5>{t('Common.Filter')}</h5>,
      children: (
        <Card>
          <h6>{t('Common.Companies')}</h6>
          <AntMultiselect
            title={
              filterCompanies?.some(value => !value.checked)
                ? t('Common.Companies')
                : t('Common.AllCompanies')
            }
            onFilter={v => setFilterCompanies(v)}
            data={filterCompanies}
          />
          <br></br>
          <h6>{t('Common.Vehicles')}</h6>
          <AntMultiselect
            key="vehicles"
            title={
              !filterVehicles?.some(value => !value.checked)
                ? t('Common.AllVehicles')
                : t('Common.Vehicles')
            }
            data={filterVehicles}
            onFilter={v => setFilterVehicles(v)}
          />
          <br></br>
          <h6>{t('Common.Drivers')}</h6>
          <AntMultiselect
            key="drivers"
            title={
              !filterDrivers?.some(value => !value.checked)
                ? t('Common.AllDrivers')
                : t('Common.Drivers')
            }
            data={filterDrivers}
            onFilter={v => setFilterDrivers(v)}
          />
          <br></br>
          <h6>{t('Common.Fleets')}</h6>
          <AntMultiselect
            key="fleets"
            title={
              !filteredFleets?.some(value => !value.checked)
                ? t('Common.AllFleets')
                : t('Common.Fleets')
            }
            data={filteredFleets}
            onFilter={v => setFilteredFleets(v)}
          />
          <br></br>
          <h6>{t('Common.Branches')}</h6>
          <AntMultiselect
            title={
              filterBranches?.some(value => !value.checked)
                ? t('Common.Branches')
                : t('Common.AllBranches')
            }
            onFilter={v => setFilterBranches(v)}
            data={filterBranches}
          />
          <br></br>
          <h6>{t('Common.Devices')}</h6>
          <AntMultiselect
            title={
              filterDevices?.some(value => !value.checked)
                ? t('Common.Devices')
                : t('Common.AllDevices')
            }
            onFilter={v => setFilterDevices(v)}
            data={filterDevices}
          />
          <br></br>
          <h6>{t('VehicleTypes.VehicleTypesTitle')}</h6>
          <AntMultiselect
            title={
              filterVehicleType?.some(value => !value.checked)
                ? t('VehicleTypes.VehicleTypesTitle')
                : t('Common.AllVehicleTypes')
            }
            onFilter={v => setFilterVehicleType(v)}
            data={filterVehicleType}
          />
          <br></br>
          <h6>{t('Devices.DeviceTypes')}</h6>
          <AntMultiselect
            title={
              filterDeviceTypes?.some(value => !value.checked)
                ? t('Devices.DeviceTypes')
                : t('Fleets.Form.AllDeviceTypes')
            }
            onFilter={v => setFilterDeviceTypes(v)}
            data={filterDeviceTypes}
          />
        </Card>
      )
    },
    {
      key: '3',
      label: <h5>{t('Common.Dimensions')}</h5>,
      children: (
        <Card>
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            Select all
          </Checkbox>
          <br></br>
          <br></br>
          <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
        </Card>
      )
    },
    {
      key: '4',
      label: <h5>{t('Insights.SummarizeData')}</h5>,
      children: (
        <Card>
          <Radio.Group defaultValue="minuteByMinute" buttonStyle="solid">
            <Radio.Button value="minuteByMinute">Minute-by-minute</Radio.Button>
            <Radio.Button value="event">Event</Radio.Button>
            <Radio.Button value="trip">Trip</Radio.Button>
            <Radio.Button value="day">Day</Radio.Button>
            <Radio.Button value="reportingPeriod">Reporting Period</Radio.Button>
          </Radio.Group>
        </Card>
      )
    }
  ];

  return (
    <Modal
      className={styles.configureReportModal}
      title={t('Home.Configure')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      width={600}
    >
      <Collapse defaultActiveKey={['1']} ghost items={items} />
    </Modal>
  );
};
