import React from 'react';
import { VehicleSelectModal } from './VehicleSelectModal';
import { EntitySelectModal } from './EntitySelectModal';
import { ModelSelectModal } from './ModelSelectModal';
import { TypeSelectModal } from './TypeSelectModal';
import { GpioSelectModal } from './GpioSelectModal';
import { PhoneNumberModal } from './PhoneNumberModal';
import { EntityInputModal } from './EntityInputModal';
import TextArea from 'antd/lib/input/TextArea';
import { BULK_EDIT_ENTITY } from '../helper';

export const EntityEditModal = ({
  show,
  onOk,
  onCancel,
  label,
  placeholder,
  entity,
  companyId,
  devices,
  deviceTypeId,
  required
}) => {
  switch (entity) {
    case BULK_EDIT_ENTITY.VEHICLE.entity:
      return (
        <VehicleSelectModal
          devices={devices}
          show={show}
          onOk={onOk}
          onCancel={onCancel}
          label={label}
        />
      );
    case BULK_EDIT_ENTITY.TYPE.entity:
      return <TypeSelectModal show={show} onOk={onOk} onCancel={onCancel} />;
    case BULK_EDIT_ENTITY.GPIO_CONFIGURATION.entity:
      return <GpioSelectModal show={show} onOk={onOk} onCancel={onCancel} />;
    case BULK_EDIT_ENTITY.MODEL.entity:
      return (
        <ModelSelectModal
          devices={devices}
          show={show}
          onOk={onOk}
          onCancel={onCancel}
          label={label}
          deviceTypeId={deviceTypeId}
        />
      );
    case BULK_EDIT_ENTITY.PHONE_NUMBER.entity:
      return <PhoneNumberModal companyId={companyId} show={show} onOk={onOk} onCancel={onCancel} />;
    case BULK_EDIT_ENTITY.NOTES.entity:
      return (
        <EntityInputModal
          label={label}
          placeholder={placeholder}
          Component={TextArea}
          componentProps={{ autoSize: { minRows: 5, maxRows: 10 } }}
          validator={value => !!(value && value.trim())}
          show={show}
          onOk={onOk}
          onCancel={onCancel}
          required
        />
      );
    default:
      return (
        <EntitySelectModal
          show={show}
          label={label}
          entity={entity}
          onOk={onOk}
          onCancel={onCancel}
          required={required}
          companyId={companyId}
        />
      );
  }
};
