import React, { useCallback, useEffect, useMemo } from 'react';
import { Row, Col, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import './address.scss';
import { CountryRegionSelector } from './countryRegionSelector/CountryRegionSelector';
import {
  initialAddress,
  getFormattedAddressStr,
  getSuburbLabelByCountry,
  getSuburbPlaceholderByCountry
} from './helper';

export const Address = ({ name, initialValues, onChange, asForm }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const formInitialValues = useMemo(() => {
    return {
      ...initialAddress,
      ...(initialValues || {})
    };
  }, [initialValues]);

  useEffect(() => {
    form.resetFields();
  }, [formInitialValues]);

  const selectedCountry = useMemo(() => {
    return form.getFieldValue('country');
  });
  const selectedRegion = useMemo(() => {
    return form.getFieldValue('state');
  });

  const onCountryChange = useCallback(() => {
    if (selectedRegion) {
      form.setFieldsValue({ state: '' });
    }
  }, [selectedRegion]);

  return (
    <Form
      className="custom-address-form"
      form={form}
      name={name}
      layout="vertical"
      initialValues={formInitialValues}
      onValuesChange={(changedValues, allValues) =>
        onChange({ [name]: allValues, formated: getFormattedAddressStr(allValues) })
      }
      component={asForm ? 'form' : 'div'}
    >
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item name="number" label={t('Locations.Form.Number')}>
            <Input size="large" placeholder={t('Locations.Form.NumberPlaceholder')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="street" label={t('Locations.Form.Street')}>
            <Input size="large" placeholder={t('Locations.Form.StreetPlaceholder')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item name="suburb" label={getSuburbLabelByCountry(form.value?.country)}>
            <Input size="large" placeholder={getSuburbPlaceholderByCountry(form.value?.country)} />
          </Form.Item>
          <Form.Item name="postcode" label={t('Locations.Form.Postcode')}>
            <Input
              size="large"
              placeholder={`${t('Locations.Form.TypeInPlaceholder')} ${t(
                'Locations.Form.Postcode'
              )}`}
            />
          </Form.Item>
        </Col>
        <CountryRegionSelector
          selectedCountry={selectedCountry}
          layout={'col'}
          onCountryChange={onCountryChange}
        />
      </Row>
    </Form>
  );
};
