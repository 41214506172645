import React, { useCallback, useRef } from 'react';

import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';

import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { ExportToExcelInformation } from './ExportToExcelInformation';

import styles from './DocumentView.module.scss';

const InformationToolbar = ({
  document,
  filteredEventCount,
  data,
  searchText,
  vehiclesFilter,
  devicesFilter,
  companyFilter,
  fleetFilter,
  receivedFilter,
  onSearchChange,
  onVehiclesFilterChange,
  onDevicesFilterChange,
  onCompanyFilterChange,
  onFleetFilterChange,
  onReceivedFilterChange
}) => {
  const localization = useLocalization();
  const { t } = useTranslation();
  const timeoutHandler = useRef(-1);

  const handleSearchChange = useCallback(value => {
    if (timeoutHandler.current !== -1) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = -1;
    }
    timeoutHandler.current = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(value);
      }
      timeoutHandler.current = -1;
    }, 100);
  }, []);

  return (
    <div className={styles.auditTrailToolbar}>
      <AntSearchbar defaultValue={searchText} onFilter={handleSearchChange} />
      {/* <AntMultiselect
        title={
          vehiclesFilter?.some(value => !value.checked)
            ? t('Common.Vehicles')
            : t('Common.AllVehicles')
        }
        data={vehiclesFilter}
        onFilter={onVehiclesFilterChange}
      />
      <AntMultiselect
        title={
          devicesFilter?.some(value => !value.checked)
            ? t('Common.Devices')
            : t('Common.AllDevices')
        }
        data={devicesFilter}
        onFilter={onDevicesFilterChange}
      /> */}
      <AntMultiselect
        title={
          companyFilter?.some(value => !value.checked)
            ? t('Common.Company')
            : t('Common.AllCompanies')
        }
        data={companyFilter}
        onFilter={onCompanyFilterChange}
      />
      <AntMultiselect
        title={
          fleetFilter?.some(value => !value.checked) ? t('Common.Fleet') : t('Common.AllFleets')
        }
        data={fleetFilter}
        onFilter={onFleetFilterChange}
      />
      <AntMultiselect
        title={
          receivedFilter?.some(value => !value.checked)
            ? t('Easydocs.Received')
            : t('Easydocs.AllReceived')
        }
        data={receivedFilter}
        onFilter={onReceivedFilterChange}
      />
      <div>
        <span className={styles.auditEventsCount}>
          {filteredEventCount + ' ' + t('Common.Vehicles')}
        </span>
        <ExportToExcelInformation
          data={data}
          document={document}
          localization={localization}
          size="large"
        />
      </div>
    </div>
  );
};

export default InformationToolbar;
