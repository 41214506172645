import React, { useState } from 'react';
import EWDComponent from 'features/ewd';
import { Route, Switch } from 'react-router';
import WorkDiaryView from 'features/sentinel/components/WorkDiaryView';
import { EWDDriverTable } from 'features/ewd/components/drivers/EWDDriverTable';
import { QuickFilterTable } from 'features/fatigue/Components';

const EWDRoute = React.memo(() => {
  const [EWDTableComponent] = useState(() => {
    return () => (
      <QuickFilterTable
        tableComponent={EWDDriverTable}
        basePath="/fatigue/ewd"
        parentPath="/fatigue/ewd"
      />
    );
  });

  return (
    <Switch>
      <Route exact path="*/ewd(/All|/Working_Driving|/Resting)?" component={EWDTableComponent} />
      <Route exact path="*/ewd/:id" component={EWDComponent} />
      <Route exact path="*/ewd/workdiary/:driverId" component={WorkDiaryView} />
    </Switch>
  );
});

export const EWD = props => {
  return <EWDRoute />;
};
