import React, { useEffect, useCallback } from 'react';
import {
  useVehicleMntType,
  deleteMntTypesApi,
  restoreMntTypesApi,
  useIsFetching
} from 'features/vehicleMntTypes/vehicleMntTypesSlice';
import { useDispatch } from 'react-redux';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import InfoRow from 'components/form/info-row/InfoRow';
import { format } from 'utils/dates';
import styles from './VehicleMntTypes.module.scss';
import { useLocalization } from 'features/localization/localizationSlice';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Paths, TYPE_ACTION } from './constants';
import { DeletedEntityAlert, DeletableEntityType } from 'features/common/deletedEntityAlert';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

export const VehicleMntTypesView = () => {
  const indexBeginningId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginningId + 1,
    window.location.pathname.length - 1
  );
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const localization = useLocalization();

  const vehicleMntType = useVehicleMntType(id);
  const isFetchingData = useIsFetching();

  const handleError = useCallback(
    err => {
      if (history.location.pathname !== Paths.DEFAULT) {
        if (err) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: err
            })
          );
        }
        history.replace(Paths.DEFAULT);
      }
    },
    [history, dispatch]
  );

  useEffect(() => {
    const parsedId = parseInt(id);
    if (
      parsedId <= 0 ||
      parsedId > Number.MAX_SAFE_INTEGER ||
      isNaN(parsedId) ||
      (!isFetchingData && !vehicleMntType)
    ) {
      handleError(t('Common.Invalid Request ID'));
    }
  }, [t, id, handleError, isFetchingData, vehicleMntType]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPageTitle(vehicleMntType && vehicleMntType.name));
  }, [dispatch, vehicleMntType]);

  const handleButtonAction = useCallback(
    action => () => {
      switch (action) {
        case TYPE_ACTION.Delete:
          dispatch(deleteMntTypesApi(vehicleMntType, history));
          break;
        case TYPE_ACTION.Restore:
          dispatch(restoreMntTypesApi(vehicleMntType));
          break;
        default:
      }
    },
    [dispatch, vehicleMntType, history]
  );

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={vehicleMntType}
        editPath={`/settings/vehicleMntTypes/edit/id/${id}`}
        canUse="VEHICLEMAINTENANCETYPE"
        handleButtonAction={handleButtonAction}
        typeOfEntityToDelete={t('Common.vehicleMntType')}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '32px',
          color: '#181c21'
        }}
        className="deviceView"
      >
        {
          <DeletedEntityAlert
            entity={DeletableEntityType.VehicleMntType}
            entityStatus={vehicleMntType?.status}
          />
        }
        <InfoRow
          label={t('VehicleMaintenanceTypes.View.Name')}
          value={vehicleMntType && vehicleMntType.name ? vehicleMntType.name : ''}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMaintenanceTypes.View.Company')}
          value={vehicleMntType?.company?.name || ''}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMaintenanceTypes.View.Description')}
          value={vehicleMntType?.description || ''}
          styles={styles}
        />

        <InfoRow
          label={t('VehicleMaintenanceTypes.View.VehicleTypes')}
          value={
            vehicleMntType && vehicleMntType.vehicleTypes
              ? vehicleMntType.vehicleTypes.map(type => (
                  <div key={`vehicleType-${type.id}`}>{type.name}</div>
                ))
              : ''
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMaintenanceTypes.View.CreatedAt')}
          value={
            vehicleMntType && vehicleMntType.createdAt
              ? format(
                  new Date(vehicleMntType.createdAt),
                  localization.formats.time.formats.dby_imp
                )
              : ''
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMaintenanceTypes.View.UpdatedAt')}
          value={
            vehicleMntType && vehicleMntType.updatedAt
              ? format(
                  new Date(vehicleMntType.updatedAt),
                  localization.formats.time.formats.dby_imp
                )
              : ''
          }
          styles={styles}
        />
      </div>
    </React.Fragment>
  );
};
