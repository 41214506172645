import React from 'react';
import { Tooltip } from 'antd';
import styles from './StateRenderer.module.scss';

export function getClassOfDriverState(mode) {
  const pickUpStyleFns = [
    () => (mode?.match(/drive/i) ? { className: styles.driveState, title: 'Driving' } : false),
    () => (mode?.match(/rest/i) ? { className: styles.rest, title: 'Resting' } : false),
    () => (mode?.match(/work/i) ? { className: styles.work, title: 'Working' } : false),
    () =>
      mode?.match(/offduty/i) || mode?.match(/sleep/i)
        ? { className: styles.offDuty, title: 'Off Duty' }
        : false,
    () => {
      return { className: styles.unknown, title: 'Unknown' };
    }
  ];

  let classType = null;
  for (let i = 0; i < pickUpStyleFns.length; i++) {
    classType = pickUpStyleFns[i]();
    if (classType) {
      break;
    }
  }
  return classType;
}

export function stateRenderer(rowData, fieldName) {
  const classType = getClassOfDriverState(rowData?.[fieldName]?.lastStatusEvent?.type);
  return (
    <Tooltip title={classType.title}>
      <span className={styles.stateRenderer + ' ' + classType.className}></span>
    </Tooltip>
  );
}
