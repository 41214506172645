import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { UDTGrid } from './UDTGrid';
import { UDTByVehicleGrid } from './UDTByVehicleGrid';
import { useCurrentCompany } from 'features/company/companySlice';
import { useUser } from 'features/user/userSlice';

import { useDispatch } from 'react-redux';
import { useDrivers } from 'features/users/usersSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { UDTToolbar } from './UDTToolbar';
import { useFleets, useVehicles, useIsFetching } from 'features/fleets/fleetsSlice';
import { UDTGridColumns } from './Grid/columns';
import { orderBy, sortBy } from 'lodash';
import { PATH_SPLIT, selectAll } from 'components/form/treeselect/TreeSelect';
import styles from './UDT.module.scss';
import { UDTChart } from './UDTChart';
import {
  fetchUDTDataByCompanyId,
  useIsFetchingCompanyUDTData,
  useGetCompanyUDTData,
  updateUDT
} from 'features/eld/udtSlice';
import { format } from 'utils/dates';
import { UDTAssignmentMode, UDTStatusCategory } from '../Constants';
import { Row, Col, Modal } from 'antd';
import { UDTRoutePreviewModal } from './Modal/UDTRoutePreviewModal';
import { AssignLogModal, AssignLogModalMode } from './Modal/AssignLogModal';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Loading } from 'components/loading/Loading';
import { openToast } from 'features/toasts/toastsSlice';
import moment from 'moment';
import i18next from 'i18nextConfig';
import { useTranslation } from 'react-i18next';
import { UDTAssignCommponent } from './UDTAssignCommponent';
import { useTimer } from 'utils/hooks/useTimer';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { FeatureFlag, useCan, services, entities } from 'features/permissions';

function processUDTData(udtData, fleets, driverList, vehicleList, localization) {
  const vehicle = vehicleList?.find(v => v.id === udtData?.vehicle?.id);
  const fleet = vehicle?.fleets?.[0];
  const driver = driverList?.find(d => d.id === udtData.driverId);

  return {
    ...udtData,
    key: udtData.id,
    driverName: (driver?.firstName || '') + ' ' + (driver?.lastName || ''),
    vehicleName: vehicle?.name || 'N/A',
    assignedTo: udtData?.assignedUser?.name || '',
    suggestedBy: udtData?.updatedBy?.name || '',
    status: UDTStatusCategory[udtData?.status],
    fleet: fleet?.name || 'N/A',
    startTime: udtData?.startAt
      ? format(new Date(udtData?.startAt), localization.formats.time.formats.dby_imp)
      : 'N/A',
    endTime: udtData?.endAt
      ? format(new Date(udtData?.endAt), localization.formats.time.formats.dby_imp)
      : 'N/A',
    otherStatus: 0,
    orgStatus: udtData?.status,
    orgDriver: udtData.driverId,
    orgComments: udtData.comments,
    distance: localization.convertDistance(udtData.distance)
  };
}

function getSubmitTitle(hasCheckedRows, hasComments, isDriverPortal = false) {
  if (!hasComments && !hasCheckedRows) {
    return i18next.t('ELD.UDPNoCommentNoSelection');
  } else if (!hasComments && hasCheckedRows) {
    return i18next.t('ELD.UDPNoComment');
  } else if (hasComments && !hasCheckedRows) {
    return i18next.t('ELD.UDPNoSelection');
  } else {
    if (isDriverPortal) {
      return i18next.t('ELD.DriverPortal.SubmitClaims');
    } else {
      return i18next.t('ELD.Submit Assignments');
    }
  }
}

export function UDT({ editMode, restoreAssignMode }) {
  const dispatch = useDispatch();
  const company = useCurrentCompany();
  const fleets = useFleets();
  const vehicles = useVehicles();
  const user = useUser();
  const driverList = useDrivers();
  const localization = useLocalization();
  const userPreference = useUserPreferences();
  const can = useCan();

  const isFetchingUDTData = useIsFetchingCompanyUDTData(company.id);
  const isFleetFetching = useIsFetching();
  const udtData = useGetCompanyUDTData(company.id);
  const [isFetching, setIsFetching] = useState(true);
  const [initTableData, setInitTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const sortedTableDataRef = useRef(null);
  const byVehicleSortedTableDataRef = useRef({});
  const defaultDates = useMemo(() => [moment().subtract(8, 'days'), moment()], []);
  const [udtDates, setUDTDates] = useState(defaultDates);
  const [searchText, setSearchText] = useState(null);
  const [fleetsFilter, setFleetsFilter] = useState(null);
  const [generalFilter, setGeneralFilter] = useState(null);
  const [initGeneralFilter, setInitGeneralFilter] = useState(null);
  const [groupByFilter, setGroupByFilter] = useState('none');
  const [durationFilter, setDurationFilter] = useState(null);
  const [chartFilter, setChartFilter] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [checkedRows, setCheckedRows] = useState({});
  const [assignMode, setAssignMode] = useState(
    editMode ? UDTAssignmentMode.BulkAssignment : UDTAssignmentMode.None
  );
  const { t } = useTranslation();

  const isDriverPortal = can({
    everyCompanyService: [services.ELD],
    featureFlag: [FeatureFlag.driverPortal.flag],
    everyEntity: [entities.DRIVERPORTAL],
    otherConditions: [() => user?.type?.code === 'DRIVER']
  });

  const [routePreviewState, setRoutePreviewState] = useState({ showModal: false, rowData: null });
  const [assignLogState, setAssignLogState] = useState({
    showModal: false,
    mode: 0,
    logData: null
  });
  const [showPorgressingModal, setShowProgressingModal] = useState(false);

  const fetchUDTData = useCallback(() => {
    dispatch(
      fetchUDTDataByCompanyId(
        company?.id,
        company?.api_key,
        udtDates?.[0]
          ? moment(udtDates[0])
              .startOf('day')
              .toISOString()
          : null,
        udtDates?.[1]
          ? moment(udtDates[1])
              .endOf('day')
              .toISOString()
          : null
      )
    );
  }, [dispatch, company, udtDates]);

  const autoFetch = useCallback(() => {
    if (isFetching || assignMode === UDTAssignmentMode.BulkAssignment || assignLogState.showModal) {
      return;
    }
    fetchUDTData();
  }, [isFetching, assignMode, assignLogState, fetchUDTData]);

  const handleFleetsChanged = useCallback(fleets => {
    setFleetsFilter(fleets);
  }, []);

  const handleGeneralFilterChange = useCallback(generalFilters => {
    setGeneralFilter(generalFilters);
  }, []);

  const handleSearchChange = useCallback(searchText => {
    setSearchText(searchText);
  }, []);

  const handleDateChange = useCallback(dates => {
    setUDTDates(dates);
  }, []);

  const handleDurationFilterChange = useCallback(filter => {
    setDurationFilter(filter);
  }, []);

  const handleGroupByFilterChange = useCallback(filter => {
    setGroupByFilter(filter);
  }, []);

  const handleRowChecked = useCallback((rowData, checked) => {
    if (checked) {
      setCheckedRows(rows => {
        return { ...rows, [rowData.id]: true };
      });
      setTableData(rows => {
        const targetRow = rows.find(r => r.id === rowData.id);
        targetRow.checked = true;
        return Array.from(rows);
      });
    } else {
      setCheckedRows(rows => {
        delete rows[rowData.id];
        return { ...rows };
      });
      setTableData(rows => {
        const targetRow = rows.find(r => r.id === rowData.id);
        targetRow.checked = false;
        return Array.from(rows);
      });
    }
  }, []);

  const getSortedTableData = rowData => {
    if (groupByFilter === 'vehicle') {
      return rowData?.vehicle?.id ? byVehicleSortedTableDataRef.current[rowData?.vehicle?.id] : [];
    } else {
      return sortedTableDataRef.current;
    }
  };

  const handleSortedTableDataChanged = useCallback(
    currentData => {
      if (currentData) {
        if (groupByFilter === 'vehicle') {
          // save sorted table data per vehicle since in group by vehicles mode
          const vehicleId = currentData?.length > 0 ? currentData[0].vehicle?.id : null;
          if (vehicleId) {
            byVehicleSortedTableDataRef.current[vehicleId] = currentData;
          }
        } else {
          // use ref so we don't trigger state updates and re-render calls when getting sorted table data
          // we just need this data for navigating next/prev in UDTRoutePreviewModal
          sortedTableDataRef.current = currentData;
        }
      }
    },
    [groupByFilter]
  );

  const handleSelectAllChecked = useCallback(evt => {
    if (evt.target.checked) {
      setTableData(rows => {
        const checkedRows = {};
        rows.forEach(r => {
          r.checked = true;
          checkedRows[r.id] = true;
        });

        setCheckedRows(checkedRows);
        return Array.from(rows);
      });
    } else {
      setCheckedRows({});
      setTableData(rows => {
        rows.forEach(r => {
          r.checked = false;
        });
        return Array.from(rows);
      });
    }
  }, []);

  const handleSelectAllVehicleChecked = useCallback(
    (evt, rowData) => {
      if (evt.target.checked) {
        setTableData(rows => {
          const checkedRows = {};
          rows.forEach(r => {
            // Add checked if vehicle id matches key, else keep existing checked state for other vehicles
            r.checked = rowData.key === r.vehicle?.id || r.checked;
            if (r.checked) {
              checkedRows[r.id] = true;
            }
          });

          setCheckedRows(checkedRows);
          return Array.from(rows);
        });
      } else {
        setTableData(rows => {
          const checkedRows = {};
          rows.forEach(r => {
            // Remove checked state if vehicle id matches key, else keep existing checked state for other vehicles
            r.checked = !(rowData.key === r.vehicle?.id) && r.checked;
            if (r.checked) {
              checkedRows[r.id] = true;
            }
          });

          setCheckedRows(checkedRows);
          return Array.from(rows);
        });
      }
    },
    [tableData]
  );

  const handleSuggestAssign = useCallback(
    async (comments, driverId) => {
      if (checkedRows && Object.keys(checkedRows).length > 0) {
        setShowProgressingModal(true);
        const requests = [];
        for (let id in checkedRows) {
          let updateDispatch = null;
          if (isDriverPortal) {
            updateDispatch = dispatch(
              updateUDT(id, company.api_key, {
                Status: 'A',
                Comments: comments,
                AssignedUserId: user.id,
                UpdatedBy: user.id
              })
            );
          } else if (driverId) {
            updateDispatch = dispatch(
              updateUDT(id, company.api_key, {
                Status: 'P',
                Comments: comments,
                AssignedUserId: driverId,
                UpdatedBy: user.id
              })
            );
          } else {
            updateDispatch = dispatch(
              updateUDT(id, company.api_key, {
                Status: 'C',
                Comments: comments,
                UpdatedBy: user.id
              })
            );
          }
          updateDispatch.then(
            () => {
              return;
            },
            error => {
              dispatch(
                openToast({
                  type: ToastType.Error,
                  message: t('ELD.Updating one of UDT events failed') + '. ' + error
                })
              );
            }
          );
          requests.push(updateDispatch);
        }
        await Promise.all(requests);
        fetchUDTData();
        setShowProgressingModal(false);
        dispatch(
          openToast({ type: ToastType.Info, message: t('ELD.Updating UDT events finished') })
        );
      }
      setCheckedRows({});
      restoreAssignMode();
    },
    [dispatch, restoreAssignMode, checkedRows, user, company, fetchUDTData]
  );

  const handleCancelAssign = useCallback(() => {
    setCheckedRows({});
    setChartFilter(filter => {
      return { ...filter };
    });
    restoreAssignMode();
  }, [restoreAssignMode]);

  const handlePreviousRoute = useCallback(() => {
    const sortedTableData = getSortedTableData(routePreviewState.rowData);

    if (routePreviewState.rowData && sortedTableData) {
      const rowIndex = sortedTableData.findIndex(r => r.id === routePreviewState.rowData.id);
      if (rowIndex !== -1 && rowIndex > 0) {
        const newRowData = sortedTableData[rowIndex - 1];
        setRoutePreviewState({ showModal: true, rowData: newRowData });
      }
    }
  }, [routePreviewState]);

  const handleNextRoute = useCallback(() => {
    const sortedTableData = getSortedTableData(routePreviewState.rowData);

    if (routePreviewState.rowData && sortedTableData) {
      const rowIndex = sortedTableData.findIndex(r => r.id === routePreviewState.rowData.id);
      if (rowIndex !== -1 && rowIndex < sortedTableData.length - 1) {
        const newRowData = sortedTableData[rowIndex + 1];
        setRoutePreviewState({ showModal: true, rowData: newRowData });
      }
    }
  }, [routePreviewState]);

  const handleCloseRoutePreviewModal = useCallback(() => {
    setRoutePreviewState({ showModal: false, rowData: null });
  }, []);

  const handlePreviewRoute = useCallback(rowData => {
    setRoutePreviewState({ showModal: true, rowData });
  }, []);

  const handlePreviewRouteUpdate = useCallback(
    rowData => {
      fetchUDTData();
      let updatedData = udtData.find(d => d?.assignedUser?.id === rowData?.udtValues?.driver);
      let assignedDriver =
        updatedData?.assignedUser?.firstName + ' ' + updatedData?.assignedUser?.lastName;
      rowData.udt.assignedTo = assignedDriver;
      rowData.udt.status =
        updatedData.status === 'P'
          ? 'Pending'
          : updatedData.status === 'D'
          ? 'Rejected'
          : 'Commented';
      rowData.udt.id = rowData.udt.id;
      rowData.udt.assignedUser = {
        ...updatedData?.assignedUser
      };
      const sortedTableData = getSortedTableData(rowData);
      let prev = sortedTableData && sortedTableData.length > 1;
      let next = sortedTableData && sortedTableData.length < 1;
      if (!isDriverPortal) {
        if (prev || next) {
          setRoutePreviewState({ showModal: true, rowData: rowData?.udt });
        } else {
          setRoutePreviewState({ showModal: false, rowData: null });
        }
      } else if (isDriverPortal) {
        setRoutePreviewState({ showModal: false, rowData: null });
      }
    },
    [fetchUDTData, isFetching]
  );

  const handleAssignLogUpdate = useCallback(() => {
    fetchUDTData();

    setAssignLogState({
      showModal: false,
      mode: 0,
      logData: null,
      onUpdate: null
    });
  }, [fetchUDTData]);

  const handleEditComment = useCallback(
    rowData => {
      setAssignLogState({
        showModal: true,
        mode: AssignLogModalMode.EditComment,
        logData: rowData,
        onUpdate: handleAssignLogUpdate
      });
    },
    [handleAssignLogUpdate]
  );

  const handleAssignEvent = useCallback(
    rowData => {
      setAssignLogState({
        showModal: true,
        mode: isDriverPortal ? AssignLogModalMode.DriverPortal : AssignLogModalMode.AssignLog,
        logData: rowData,
        onUpdate: handleAssignLogUpdate
      });
    },
    [handleAssignLogUpdate]
  );

  const handleCloseAssignLogModal = useCallback(() => {
    setAssignLogState({
      showModal: false,
      mode: 0,
      logData: null,
      onUpdate: null
    });
  }, []);

  const handleAcceptEvent = useCallback(
    rowData => {
      setAssignLogState({
        showModal: true,
        mode: AssignLogModalMode.Accept,
        logData: rowData,
        onUpdate: handleAssignLogUpdate
      });
    },
    [handleAssignLogUpdate]
  );

  const handleRowDriverChanged = useCallback((rowData, newValue) => {
    setTableData(rows => {
      const targetRow = rows.find(r => r.id === rowData.id);
      targetRow.driverId = newValue;
      return Array.from(rows);
    });
  }, []);

  const handleRowCommentChanged = useCallback((rowData, newValue) => {
    setTableData(rows => {
      const targetRow = rows.find(r => r.id === rowData.id);
      targetRow.comments = newValue;
      return Array.from(rows);
    });
  }, []);

  const handleRowUndoAssignment = useCallback(
    rowData => {
      setAssignLogState({
        showModal: true,
        mode: AssignLogModalMode.UndoAssignment,
        logData: rowData,
        onUpdate: handleAssignLogUpdate
      });
    },
    [handleAssignLogUpdate]
  );

  useEffect(() => {
    fetchUDTData();
  }, [fetchUDTData]);

  useEffect(() => {
    if (isFetchingUDTData || isFleetFetching) {
      setIsFetching(true);
    } else {
      setIsFetching(false);
    }
  }, [isFetchingUDTData, isFleetFetching]);

  useEffect(() => {
    if (editMode) {
      setAssignMode(UDTAssignmentMode.BulkAssignment);
    } else {
      setAssignMode(UDTAssignmentMode.None);
    }
  }, [checkedRows, editMode]);

  useEffect(() => {
    if (isFetching) {
      return;
    }

    let data = [];
    let generalFilters = {};
    let valueGroups = {};
    let chartData = {
      deletedEvents: 0,
      pendingEvents: 0,
      unassignedEvents: 0,
      eventsWithComments: 0,
      eventsWithCommentsIncludeCompleted: 0,
      assignedEvents: 0,
      completedEvents: 0,
      commentedEvents: 0,
      totalEvents: 0
    };
    chartData.totalEvents = udtData?.length || 0;
    udtData &&
      orderBy(udtData, ['startAt'], 'desc').forEach(udt => {
        const row = processUDTData(udt, fleets, driverList, vehicles, localization);
        switch (row.status) {
          case 'Pending':
            chartData.pendingEvents++;
            break;
          case 'Deleted':
            chartData.deletedEvents++;
            break;
          case 'Unassigned':
            chartData.unassignedEvents++;
            break;
          case 'Commented':
            chartData.commentedEvents++;
            chartData.completedEvents++;
            break;
          case 'Assigned':
            chartData.assignedEvents++;
            chartData.completedEvents++;
            break;
          default:
            break;
        }
        row.onRowChecked = handleRowChecked;
        row.onPreviewRoute = handlePreviewRoute;
        row.onEditComment = handleEditComment;
        row.onAssignEvent = handleAssignEvent;
        row.onAcceptEvent = handleAcceptEvent;
        row.onCommentChanged = handleRowCommentChanged;
        row.onDriverChanged = handleRowDriverChanged;
        row.onUndoAssignEvent = handleRowUndoAssignment;
        data.push(row);
      });
    setChartData(chartData);

    //generate filters
    for (let idx = 0; idx < UDTGridColumns.length; idx++) {
      const column = UDTGridColumns[idx];
      if (column.generalFilter) {
        let node = {
          label: column.title,
          function: undefined,
          nodeKey: (idx + 1).toString(),
          id: idx + 1,
          children: [
            {
              ...selectAll,
              label: t('Common.' + selectAll.name),
              nodeKey: idx + 1 + PATH_SPLIT + 0
            }
          ]
        };
        generalFilters[node.id] = node;
        valueGroups[node.id] = {};

        const sortedList = sortBy(data, [o => o[column.dataIndex]]);

        for (let i = 0, l = sortedList.length; i < l; i++) {
          const item = sortedList[i];
          const parentNode = generalFilters[idx + 1];
          const value = item[column.dataIndex];
          if (!value) {
            continue;
          }
          if (!valueGroups[parentNode.id][value]) {
            const nodeId = parentNode.children.length;
            const childNode = {
              id: nodeId,
              label: column.label ? column.label(item[column.dataIndex]) : item[column.dataIndex],
              function: undefined,
              nodeKey: parentNode.nodeKey + PATH_SPLIT + nodeId,
              dataKey: column.dataIndex,
              labelFn: column.label
            };
            parentNode.children.push(childNode);
            valueGroups[parentNode.id][value] = true;
          }
        }
      }
    }
    setInitGeneralFilter(generalFilters);
    setInitTableData(data);
  }, [
    isFetching,
    udtData,
    fleets,
    driverList,
    localization,
    handleRowChecked,
    handlePreviewRoute,
    handleEditComment,
    handleAssignEvent,
    handleAcceptEvent,
    handleRowDriverChanged,
    handleRowCommentChanged
  ]);

  useEffect(() => {
    const data = [];
    for (let row of initTableData) {
      let matchSearch = true;

      if (chartFilter) {
        if (chartFilter.selectedStatus?.selected) {
          matchSearch = row.status === chartFilter.selectedStatus.id;
        }
      }

      if (
        matchSearch &&
        !chartFilter?.showCompletedEvents &&
        (row.status === 'Assigned' || row.status === 'Commented')
      ) {
        matchSearch = false;
      }

      if (matchSearch && searchText && searchText.trim() !== '') {
        const i_searchText = searchText.toLowerCase();
        matchSearch = UDTGridColumns.filter(c => c.canSearch).some(c => {
          const value = c.label
            ? c.label(row?.[c.dataIndex]).toString()
            : row?.[c.dataIndex]?.toString().toLowerCase();
          return value?.indexOf(i_searchText) >= 0;
        });
      }

      if (
        matchSearch &&
        fleetsFilter &&
        !fleetsFilter.find(f => f.label === t('Common.AllFleets')).checked
      ) {
        matchSearch = fleetsFilter.some(
          f =>
            f.checked &&
            (f.label === row.fleet ||
              (f.label === t('Common.NoFleet') && (row.fleet === null || row.fleet === '')))
        );
      }

      if (matchSearch && generalFilter) {
        for (let key in generalFilter) {
          if (
            generalFilter[key].children[0].checked ||
            !generalFilter[key].children.some(c => c.checked)
          ) {
            continue;
          }

          const datakeyLabelFns = {};
          UDTGridColumns.filter(c => c.label).forEach(c => {
            datakeyLabelFns[c.dataIndex] = c.label;
          });
          for (let idx = 1; idx < generalFilter[key].children.length; idx++) {
            const node = generalFilter[key].children[idx];
            if (node.checked) {
              const labelFn = datakeyLabelFns[node.dataKey];
              const value = labelFn ? labelFn(row[node.dataKey]) : row[node.dataKey];
              if (value !== node.label) {
                matchSearch = false;
              } else {
                matchSearch = true;
                break;
              }
            }
          }

          if (!matchSearch) {
            break;
          }
        }
      }

      if (matchSearch && durationFilter) {
        const durationMin = row.duration > 0 ? Math.floor(row.duration / 60) : 0;
        matchSearch = durationMin >= durationFilter;
      }

      if (matchSearch) {
        data.push({ ...row });
      }
    }

    setTableData(data);
  }, [initTableData, searchText, fleetsFilter, generalFilter, chartFilter, durationFilter]);

  useEffect(() => {
    setTableData(rows => {
      if (rows) {
        rows = rows.filter(
          row => !assignMode || (assignMode && ['U', 'C'].indexOf(row.orgStatus) >= 0)
        );
        rows.forEach(row => {
          row.assignMode = assignMode;
        });
      }
      return Array.from(rows);
    });
  }, [initTableData, assignMode, searchText, fleetsFilter, generalFilter, udtDates, fetchUDTData]);

  useTimer(Math.max(userPreference?.refresh?.tracking || 30, 30) * 1000, autoFetch);

  const sortedTableData = getSortedTableData(routePreviewState?.rowData);
  const previewRouteIndex = sortedTableData?.findIndex(
    r => r.id === routePreviewState?.rowData?.id
  );

  return (
    <div className={styles.fatigueManagement}>
      <UDTChart assignMode={assignMode} data={chartData} onChartFilterChanged={setChartFilter} />
      {assignMode === UDTAssignmentMode.BulkAssignment && (
        <>
          <Row className={styles.assignmentFooter}>
            <Col span={24}>
              <UDTAssignCommponent
                assignMode={assignMode}
                isDriverPortal={isDriverPortal}
                assignValue={isDriverPortal ? user?.id : ''}
                hasCheckedRows={tableData?.some(d => d.checked)}
                onCancelAssign={handleCancelAssign}
                onSuggestAssign={handleSuggestAssign}
                submitTitle={getSubmitTitle}
              />
            </Col>
          </Row>
        </>
      )}
      <UDTToolbar
        selectedCount={tableData?.filter(d => d.checked)?.length || 0}
        assignMode={assignMode}
        gridCount={tableData.length}
        onFleetChange={handleFleetsChanged}
        onGeneralFilterChange={handleGeneralFilterChange}
        onSearchChange={handleSearchChange}
        onDateChange={handleDateChange}
        onDurationFilterChange={handleDurationFilterChange}
        onGroupByFilterChange={handleGroupByFilterChange}
        groupByFilter={groupByFilter}
        durationFilter={durationFilter}
        filters={initGeneralFilter}
      />
      {groupByFilter === 'vehicle' ? (
        <UDTByVehicleGrid
          assignMode={assignMode}
          data={tableData}
          isLoading={isFetching}
          onSortedTableDataChanged={handleSortedTableDataChanged}
          onSelectAllChecked={handleSelectAllChecked}
          onSelectAllVehicleChecked={handleSelectAllVehicleChecked}
        />
      ) : (
        <UDTGrid
          assignMode={assignMode}
          data={tableData}
          isLoading={isFetching}
          onSortedTableDataChanged={handleSortedTableDataChanged}
          onSelectAllChecked={handleSelectAllChecked}
        />
      )}
      {routePreviewState.showModal && (
        <UDTRoutePreviewModal
          visible={true}
          isDriverPortal={isDriverPortal}
          rowData={routePreviewState.rowData}
          onPrevious={previewRouteIndex > 0 ? handlePreviousRoute : null}
          onNext={previewRouteIndex < sortedTableData?.length - 1 ? handleNextRoute : null}
          onUpdate={handlePreviewRouteUpdate}
          onClose={handleCloseRoutePreviewModal}
        />
      )}
      {assignLogState.showModal && (
        <AssignLogModal
          visible={true}
          mode={assignLogState.mode}
          logData={assignLogState.logData}
          onUpdate={handleAssignLogUpdate}
          onClose={handleCloseAssignLogModal}
        />
      )}
      {showPorgressingModal && (
        <Modal
          closable={false}
          title="Updating UDT events"
          open={showPorgressingModal}
          footer={null}
        >
          <Row style={{ height: '100%', width: '100%' }}>
            <Col
              span={24}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Loading />
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
}
