import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Button, Table, Checkbox, Row, Col, Tooltip } from 'antd';
import { Select } from 'components/ant';
import doubleArrowDown from 'static/images/icons/doublearrow_down.svg';
import doubleArrowUp from 'static/images/icons/doublearrow_up.svg';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { FBTModal } from '../FBTModal';
import { saveTripPurpose, bulkUploadTripPurposes, fetchTrips, useDates } from 'features/fbt';
import styles from '../FBTManager.module.scss';
import {
  setBulkUpdateState,
  useBulkUpdateMode,
  useFiltersModified,
  setFilterUpdates
} from 'features/toasts/toastsSlice';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';

import {
  prepareColumnsForTable,
  prepareSubRowColumns,
  prepareTripsData,
  prepareTripsForTable,
  getNextAndPrevTrips,
  parseTripForTable,
  getBulkAssignTitle,
  handleSelectAll,
  handleSelectRow,
  handleSelectTrips
} from './helpers';

import { useDevices } from 'features/fleets/fleetsSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

const FBTTable = ({
  trips = [],
  localization,
  tripStates,
  loading,
  editable,
  fbtMultipleCallsFeatureFlag
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [activeTrip, setActiveTrip] = useState();
  const [rowTrips, setRowTrips] = useState([]);
  const [selectedTrips, setSelectedTrips] = useState([]);
  const [selectedTripState, setSelectedTripState] = useState(null);
  const [selectedTripsNumber, setSelectedTripsNumber] = useState(0);
  const [actualTripsNumber, setActualTripsNumber] = useState(0);
  const handleTripStateChange = value => {
    setSelectedTripState(value);
  };

  const dispatch = useDispatch();
  const devices = useDevices();
  const tableRef = useRef();
  const [scrollSettings, setScrollSettings] = useState({ y: 0 });
  const bulkUpdateMode = useBulkUpdateMode();
  const filtersUpdated = useFiltersModified();
  const fbtBulkAssignment = useCanFeatureFlag({
    featureFlag: FeatureFlag.fbtBulkAssignment.flag
  });

  const { dateFrom, dateTo } = useDates();

  const { t } = useTranslation();
  const tableColumns = prepareColumnsForTable(t, fbtBulkAssignment);
  const childColumns = prepareSubRowColumns(t, localization, fbtBulkAssignment);

  const handleOpenTrip = rowTrips => current => () => {
    //Set Current Row Trips
    setRowTrips(rowTrips);
    setActiveTrip(current);
  };
  const rowData = prepareTripsForTable(trips, localization, devices);

  // Add Expand all arrow to table columns
  const allRowKeys = rowData.map(row => row.key);
  const isAllExpanded = expandedRows.length === allRowKeys.length;

  // Select Type In Table
  const handleTypeSelect = id => value => {
    dispatch(
      saveTripPurpose({
        query: {
          tripId: id,
          tripState: value,
          from: dateFrom,
          to: dateTo,
          fbtMultipleCallsFeatureFlag
        }
      })
    );
  };

  const handleBulkAssign = () => {
    const tripsModified = selectedTrips.flatMap(({ rowid, trips }) =>
      trips.map(tripId => ({
        tripId,
        tripState: selectedTripState
      }))
    );
    dispatch(bulkUploadTripPurposes(tripsModified))
      .then(() => {
        dispatch(setBulkUpdateState());
        setSelectedTrips([]);
        setSelectedTripState(null);
        dispatch(
          openToast({ type: ToastType.Info, message: t('FBTManager.Updating FBT events finished') })
        );
        dispatch(
          fetchTrips({ query: { from: dateFrom, to: dateTo, fbtMultipleCallsFeatureFlag } })
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  //checkbox selections handles
  const handleRowSelection = (row, tripId) => {
    return handleSelectRow(row, tripId, setSelectedTrips);
  };

  const handleTripSelection = record => {
    return handleSelectTrips(record, selectedTrips, setSelectedTrips);
  };

  //added parent checkbox
  bulkUpdateMode &&
    tableColumns.unshift({
      title: (
        <>
          <Tooltip
            placement="rightTop"
            title={
              selectedTrips.some(trip => trip.trips.length > 0)
                ? t('ELD.Un-select all selected rows')
                : t('ELD.Select all rows for bulk assignment')
            }
          >
            <Checkbox
              checked={selectedTripsNumber !== 0 && selectedTripsNumber === actualTripsNumber}
              onChange={() => {
                handleSelectAll(rowData, selectedTrips, setSelectedTrips);
              }}
            />
            <span
              className={
                selectedTripsNumber !== 0 && selectedTripsNumber < actualTripsNumber
                  ? styles.indeterminate
                  : ''
              }
            ></span>
          </Tooltip>
        </>
      ),
      dataIndex: 'selectAll',
      key: 'selectAll',
      width: 40,
      align: 'center',
      render: (_, record) => {
        const isChecked = !!selectedTrips.find(
          item => item.rowid === record.key && item.trips.length === record.trips.length
        );
        const isIndeterminate = !!selectedTrips.find(
          item =>
            item.rowid === record.key &&
            item.trips.length > 0 &&
            item.trips.length < record.trips.length
        );
        return (
          <>
            <Checkbox
              checked={isChecked}
              indeterminate={isIndeterminate}
              onChange={handleTripSelection(record)}
            />
            <span className={isIndeterminate ? styles.indeterminate : ''}></span>
          </>
        );
      }
    });

  tableColumns.push({
    title: (
      <Button
        id={BUTTON_IDS.fbtTableExpand}
        type="default"
        onClick={() => setExpandedRows(isAllExpanded ? [] : allRowKeys)}
      >
        <span
          style={{
            content: `url(${isAllExpanded ? doubleArrowUp : doubleArrowDown})`,
            marginTop: 5
          }}
        ></span>
      </Button>
    ),
    render: (_, record) =>
      expandedRows.includes(record.key) ? (
        <UpOutlined
          onClick={() => {
            setExpandedRows(expandedRows.filter(row => row !== record.key));
          }}
        />
      ) : (
        <DownOutlined
          onClick={() => {
            setExpandedRows([...expandedRows, record.key]);
          }}
        />
      ),
    dataIndex: 'expandAll',
    key: 'expandAll',
    align: 'center'
  });

  const parsedRowTrips = rowTrips.map((trip, index) =>
    parseTripForTable(trip, index, localization, handleTypeSelect, t)
  );

  // handlers for next and previous in modal
  const handleNext = () => {
    const { nextTrip } = getNextAndPrevTrips(activeTrip?.index, parsedRowTrips);
    setActiveTrip(nextTrip);
  };

  const handlePrev = () => {
    const { prevTrip } = getNextAndPrevTrips(activeTrip?.index, parsedRowTrips);
    setActiveTrip(prevTrip);
  };

  const handleModalClose = () => {
    setActiveTrip(null);
  };

  const handleResize = entries => {
    const { height } = entries[0].contentRect;
    // account for header in table height
    const header = tableRef.current.querySelector('.ant-table-thead');
    const headerHeight = header?.getBoundingClientRect()?.height || 0;
    const newTableHeight = height - headerHeight;
    setScrollSettings({ y: newTableHeight });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    const tableElement = document.querySelector(`.${CSS.escape(styles.fbtByVehicleGrid)}`);
    resizeObserver.observe(tableElement);
    return () => {
      resizeObserver.unobserve(tableElement);
    };
  }, []);

  useEffect(() => {
    const tempTrips = selectedTrips.reduce((acc, trip) => acc + trip.trips.length, 0);
    const actualTrips = rowData.reduce((acc, row) => acc + row.trips.length, 0);
    setSelectedTripsNumber(tempTrips);
    setActualTripsNumber(actualTrips);
  }, [selectedTrips, rowData]);

  useEffect(() => {
    setSelectedTrips([]);
    setSelectedTripState(null);
    dispatch(setFilterUpdates(false));
  }, [filtersUpdated]);

  return (
    <>
      {fbtBulkAssignment && bulkUpdateMode && (
        <div className={styles.bulkAssignToolbar}>
          <Row className={styles.Toolbar} gutter={15}>
            <Col span={8}>
              <Tooltip title={t('FBTManager.Select Type of Business Use')}>
                <Select
                  data={tripStates?.map?.(tripState => ({
                    id: tripState,
                    label: t(`FBTManager.${tripState}`, tripState)
                  }))}
                  value={selectedTripState}
                  placeholder={t('FBTManager.Types of Use')}
                  onChange={handleTripStateChange}
                />
              </Tooltip>
            </Col>
            <Col span={4}>
              <Tooltip title={t(getBulkAssignTitle(selectedTrips?.length, selectedTripState))}>
                <Button
                  type="primary"
                  size="large"
                  onClick={handleBulkAssign}
                  disabled={!(selectedTrips?.length && selectedTripState)}
                  id={BUTTON_IDS.fbtBulkUpdate}
                >
                  {t('ELD.Bulk Update')}
                </Button>
              </Tooltip>
              <Button
                id={BUTTON_IDS.fbtAssignCancel}
                className={styles.cancelButton}
                size="large"
                onClick={() => {
                  dispatch(setBulkUpdateState());
                  setSelectedTrips([]);
                  setSelectedTripState(null);
                }}
              >
                {t('Common.Cancel')}
              </Button>
            </Col>
          </Row>
        </div>
      )}

      {activeTrip && (
        <FBTModal
          isModalVisible={!!activeTrip}
          showModal={trip => {
            setActiveTrip(false);
          }}
          data={activeTrip}
          onNext={handleNext}
          onPrevious={handlePrev}
          onSelectType={handleTypeSelect}
          tripStates={tripStates}
          onCancel={handleModalClose}
          editable={editable}
        />
      )}

      <Table
        className={styles.fbtByVehicleGrid}
        showSorterTooltip={false}
        dataSource={rowData}
        columns={tableColumns}
        pagination={false}
        loading={loading}
        expandable={{
          expandedRowRender: row => {
            const childTableColumns =
              fbtBulkAssignment && bulkUpdateMode
                ? [
                    {
                      dataIndex: 'childSelectAll',
                      key: 'childSelectAll',
                      width: 40,
                      align: 'center',
                      render: (_, __, index) => {
                        const tripId = row.trips[index].id;
                        const isChecked = selectedTrips.some(
                          item => item.rowid === row.key && item.trips.includes(tripId)
                        );
                        return (
                          <>
                            <Tooltip
                              title={t(
                                'ELD.Check/Uncheck to select or unselect this row to be in the bulk update'
                              )}
                              placement="leftTop"
                            >
                              <Checkbox
                                checked={isChecked}
                                onChange={handleRowSelection(row, tripId)}
                              />
                            </Tooltip>
                          </>
                        );
                      }
                    },
                    ...childColumns.map(col => ({
                      ...col,
                      render: (text, record) => record[col.dataIndex] // Assuming dataIndex is correct for each column
                    }))
                  ]
                : childColumns;
            return (
              <Table
                className={styles.fbtGrid}
                showSorterTooltip={false}
                dataSource={prepareTripsData(
                  row.trips,
                  localization,
                  handleOpenTrip(row.trips),
                  handleTypeSelect,
                  tripStates,
                  t,
                  editable
                )}
                columns={childTableColumns}
                pagination={false}
                rowKey={'id'}
                virtual={true}
                scroll={{ x: 'max-content', y: 'calc(100vh - 549px)' }}
              />
            );
          },
          expandedRowKeys: expandedRows,
          showExpandColumn: false
        }}
        ref={tableRef}
        scroll={scrollSettings}
        virtual={true}
      />
    </>
  );
};

export { FBTTable };
