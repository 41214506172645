// import preset from "@rebass/preset"
import {
  charcoal,
  steel,
  //tomato,
  sapphire,
  aquamarine,
  emerald,
  tangerine,
  topaz,
  white,
  black,
  absoluteBlack,
  brand
} from './_rebassColors';

// Preset from @rebass/preset
// DO NOT EDIT, EXTEND defaultTheme below
const preset = {
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#07c',
    secondary: '#30c',
    muted: '#f6f6f9',
    gray: '#dddddf',
    highlight: 'hsla(205, 100%, 40%, 0.125)'
  },
  fonts: {
    body: 'system-ui, sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    avatar: 48
  },
  radii: {
    default: 4,
    circle: 99999
  },
  shadows: {
    card: '0 0 4px rgba(0, 0, 0, .125)'
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading'
    },
    display: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      fontSize: [5, 6, 7]
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em'
    }
  },
  variants: {
    avatar: {
      width: 'avatar',
      height: 'avatar',
      borderRadius: 'circle'
    },
    card: {
      p: 2,
      bg: 'background',
      boxShadow: 'card'
    },
    link: {
      color: 'primary'
    },
    nav: {
      fontSize: 1,
      fontWeight: 'bold',
      display: 'inline-block',
      p: 2,
      color: 'inherit',
      textDecoration: 'none',
      ':hover,:focus,.active': {
        color: 'primary'
      }
    }
  },
  buttons: {
    primary: {
      fontSize: 2,
      fontWeight: 'bold',
      color: 'background',
      bg: 'primary',
      borderRadius: 'default'
    },
    outline: {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'transparent',
      boxShadow: 'inset 0 0 2px'
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'background',
      bg: 'secondary'
    }
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body'
    }
  }
};

// EXTEND preset HERE
export const defaultTheme = {
  ...preset,
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Roboto',
    monospace: 'Menlo, monospace'
  },
  fontSizes: [11, 13, 15, 19, 23, 30, 45, 60, 90],
  radii: {
    default: 2
  },
  colors: {
    primary: brand[500],
    highlight: brand[100],
    background: white,
    brand: { ...brand },
    steel: { ...steel },
    charcoal: { ...charcoal },
    sapphire: { ...sapphire },
    aquamarine: { ...aquamarine },
    emerald: { ...emerald },
    tangerine: { ...tangerine },
    topaz: { ...topaz },
    white: white,
    black: black,
    absoluteBlack: absoluteBlack
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'body',
      color: 'charcoal.500'
    }
  },
  buttons: {
    primary: {
      cursor: 'pointer',
      fontWeight: 'medium',
      // padding: 4,
      color: 'white',
      backgroundColor: 'primary',
      borderRadius: 'default',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'transparent',
      ':hover': {
        backgroundColor: 'brand.400'
      }
    },
    danger: {
      cursor: 'pointer',
      fontWeight: 'medium',
      // padding: 4,
      color: 'black',
      backgroundColor: 'tangerine.400',
      borderRadius: 'default',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'transparent',
      ':hover': {
        backgroundColor: 'tangerine.600'
      }
    },
    secondary: {
      variant: 'buttons.primary',
      color: 'white',
      backgroundColor: 'black',
      borderColor: 'transparent',
      ':hover': {
        backgroundColor: '#2b323c'
      }
    },
    outline: {
      variant: 'buttons.primary',
      backgroundColor: 'white',
      color: 'charcoal.500',
      borderColor: 'steel.100',
      ':hover': {
        backgroundColor: 'charcoal.50'
      }
    },
    text: {
      variant: 'buttons.primary',
      backgroundColor: 'transparent',
      color: 'charcoal.500',
      ':hover': {
        backgroundColor: 'steel.50'
      }
    }
  },
  forms: {
    input: {
      backgroundColor: 'white',
      borderColor: 'steel.100',
      color: 'charcoal.500',
      borderRadius: 'default',
      '&::placeholder': {
        color: 'steel.400'
      }
    },
    required: {
      variant: 'forms.input'
    },
    textarea: {
      variant: 'forms.input',
      resize: 'vertical'
    },
    select: {
      variant: 'forms.input',
      paddingRight: 4,
      width: 'initial',
      caret: {
        color: 'charcoal.500'
      }
    },
    label: {
      alignItems: 'center',
      fontWeight: '400',
      color: 'steel.800',
      secondary: {
        alignItems: 'center',
        fontWeight: 'body',
        fontSize: 1
      }
    },
    secondaryLabel: {
      variant: 'forms.label',
      fontWeight: 'body'
    },
    switch: {
      color: 'highlight',
      borderColor: 'steel.300',
      backgroundColor: 'steel.300',
      '&[aria-checked=true]': {
        borderColor: 'primary',
        backgroundColor: 'primary'
      }
    }
  }
};
