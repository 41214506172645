import React from 'react';
import styles from './CardInfoRow.module.scss';

export const CardInfoRow = ({ label, value, className, children }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <span className={styles.label}>{label}:</span>
      <span className={styles.value}>{value}</span>
      <span>{children}</span>
    </div>
  );
};
