import React, { useEffect, useRef, useMemo } from 'react';
import { Iframe } from 'components/iframe/Iframe';
import { useUser } from 'features/user/userSlice';
import { AuthUtil } from 'features/auth/authUtil';
import { useLocation, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { setPageTitle, setBackButton, addBackButtonLink } from 'features/page/pageSlice';
import { useWalkMeInFrame } from 'features/walkme/walkmeHooks';
import { useDrivers } from 'features/users/usersSlice';

const WorkDiaryView = () => {
  const userInfo = useUser();
  const location = useLocation();
  const dispatch = useDispatch();
  const drivers = useDrivers();
  const frameRef = useRef(null);
  const { driverId } = useParams();

  const reportUrl = useMemo(() => {
    return (
      AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
      `&url=${encodeURIComponent(`/ng/sentinel/users/${driverId}/log_book${location.search}`)}`
    );
  }, [userInfo, driverId, location]);

  const driver = useMemo(() => {
    if (driverId == null || drivers == null) return null;

    return drivers.find(d => d.id === parseInt(driverId));
  }, [driverId, drivers]);

  useEffect(() => {
    let pageTitle = 'Sentinel';
    if (driver?.firstName) {
      pageTitle += ` - ${driver.firstName} ${driver.lastName}`;
    }
    dispatch(setPageTitle(pageTitle + ' Work Diary'));
    dispatch(setBackButton(true));
    dispatch(
      addBackButtonLink({
        url: `/fatigue/sentinel/workdiary/${driverId}/`,
        backLink: `/fatigue/sentinel/${driverId}`
      })
    );
    dispatch(
      addBackButtonLink({
        url: `/fatigue/sentinel/${driverId}`,
        backLink: `/fatigue/sentinel/`
      })
    );
  }, [dispatch, driver, driverId]);

  useWalkMeInFrame(frameRef);
  return (
    <Iframe
      ref={frameRef}
      style={{ width: '100%', height: '100%', border: 'none' }}
      src={reportUrl}
    ></Iframe>
  );
};

export default WorkDiaryView;
