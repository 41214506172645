import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { ENTITIES, PAGE_TYPES } from 'components/alertStatusBar/constants';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

import AlertStatusBar from 'components/alertStatusBar/AlertStatusBar';
import {
  editUserAlert,
  fetchUserAlerts,
  useIsUserAlertFetching,
  useIsUserAlertsEnabled,
  useIsUserAlertsEnabledFetching,
  useIsUserAlertsToggleSaving,
  useUserAlerts
} from 'features/user_alerts';
import { useCurrentCompany } from 'features/company/companySlice';
import { mapUserAlertsAPIResponseToState } from 'containers/Configuration/CompanyAlerts/reducer/state/mapUserAlertsAPIResponseToState';
import { useAlertPermissions } from 'containers/Configuration/CompanyAlerts/helpers/useAlertPermissions';

import styles from './NewUserAlerts.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'antd';
import { useUser } from 'features/user/userSlice';
import { useCan } from 'features/permissions';
import { formatISO } from 'date-fns';
import { useLocalization } from 'features/localization/localizationSlice';

const NO_EXPIRY = 5259490;

const NewUserAlerts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userAlertsPayload = useUserAlerts();
  const isTableDataLoading = useIsUserAlertFetching();
  const isUserAlertsEnabledFetching = useIsUserAlertsEnabledFetching();
  const isUserAlertsToggleSaving = useIsUserAlertsToggleSaving();
  const isAlertActive = useIsUserAlertsEnabled();
  const [isLoading, setIsLoading] = useState(false);
  const currentCompany = useCurrentCompany();
  const alertPermissions = useAlertPermissions();
  const user = useUser();
  const can = useCan();

  useEffect(() => {
    setIsLoading(isTableDataLoading || isUserAlertsEnabledFetching || isUserAlertsToggleSaving);
  }, [isTableDataLoading, isUserAlertsEnabledFetching, isUserAlertsToggleSaving]);

  const localization = useLocalization();
  const userAlerts = useMemo(() => {
    return mapUserAlertsAPIResponseToState(userAlertsPayload, localization);
  }, [localization, userAlertsPayload]);

  useEffect(() => {
    const initializeUserAlert = alert => {
      const payload = {
        type: alert?.type,
        userId: user?.id,
        expiry: NO_EXPIRY,
        active: false,
        extras: '',
        associations: []
      };

      payload.createdAt = formatISO(new Date());
      payload.updatedAt = formatISO(new Date());

      return dispatch(
        editUserAlert({
          body: payload
        })
      );
    };

    if (userAlertsPayload?.length && userAlertsPayload.some(alert => !alert?.id)) {
      const userAlertsToSetup = userAlertsPayload.reduce((accumulator, currentValue) => {
        const userAlertPermission = alertPermissions.find(
          alertPermission => alertPermission?.type === currentValue?.type
        );
        if (
          !currentValue?.id &&
          userAlertPermission?.permission &&
          can({ featureFlag: userAlertPermission?.featureFlag })
        ) {
          accumulator.push(initializeUserAlert(currentValue));
        }
        return accumulator;
      }, []);
      if (userAlertsToSetup?.length) {
        setIsLoading(true);
        Promise.all(userAlertsToSetup)
          .then(response => {
            setIsLoading(false);
            dispatch(fetchUserAlerts());
          })
          .catch(error => {
            setIsLoading(false);
            console.warn('ERROR', error);
          });
      }
    }
  }, [userAlertsPayload]);

  const listOfAlerts = {
    entries: userAlerts.filter(alert => {
      const userAlertPermission = alertPermissions.find(
        alertPermission => alertPermission.type === alert.type
      );
      return (
        userAlertPermission?.permission && can({ featureFlag: userAlertPermission?.featureFlag })
      );
    }),
    pageType: PAGE_TYPES.VIEW,
    entityType: ENTITIES.USER,
    currentCompanyId: currentCompany.id
  };

  useEffect(() => {
    dispatch(setBackButton(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPageTitle(t('Alerts.Alerts')));
  }, [t]);

  const getAlertMessage = () => {
    return (
      <>
        <span>{t('Alerts.AlertsDisabledMessage')}</span>
        <span>{t('Notifications.RecentNotification')}</span>
        <FontAwesomeIcon className={styles.arrowIcon} icon={faLongArrowAltRight} />
        <span>{t('Notifications.EnableAlerts')}.</span>
      </>
    );
  };

  return (
    <div className={styles.userAlertsContainer}>
      {!isAlertActive && (
        <div className={styles.warningContainer}>
          <Alert description={getAlertMessage()} type="warning" showIcon />
        </div>
      )}
      {listOfAlerts?.entries && (
        <AlertStatusBar
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          listOfAlerts={listOfAlerts}
        />
      )}
    </div>
  );
};

export default NewUserAlerts;
