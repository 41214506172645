import React from 'react';
import { Switch } from 'react-router-dom';

import { useUserPreferences, useUserPreferencesFetched } from 'features/user/userPreferencesSlice';

import CanRoute from 'components/route/CanRoute';
import { Proximity } from './Proximity/Proximity';
import { MapTiles } from './MapTiles/MapTiles';
import { CardView } from './CardView/CardView';
import { Track } from './Track/Track';
//import { Trips } from './Trips/Trips';
//import { TripSummaryDetails } from './Trips/TripSummaryDetails';
import { TrackingPaths } from './constants';
//import { Tabs } from './Trips/TripSummaryDetailsPane';
import { TrackEventsTabs } from './Track/TrackEvents';
import GlobalRoles from 'features/permissions/GlobalRoles';
import { LoadingCentered } from 'components/loading/Loading';
import { services } from 'features/permissions';

export const TrackingRouter = () => {
  const userPreferences = useUserPreferences();
  const userPreferencesFetched = useUserPreferencesFetched();

  return !userPreferencesFetched ? (
    <LoadingCentered />
  ) : (
    <Switch>
      <CanRoute path={TrackingPaths.TrackSummary} render={<Track tab={TrackEventsTabs.Events} />} />
      <CanRoute
        path={TrackingPaths.TrackDetails}
        beyondRoles={[GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]}
        render={<Track tab={TrackEventsTabs.Details} />}
      />
      <CanRoute
        path={TrackingPaths.TrackParameters}
        render={<Track tab={TrackEventsTabs.Parameters} />}
      />
      <CanRoute
        path={TrackingPaths.TrackHDData}
        everyService={[services.TRACKINGHD]}
        everyCompanyService={[services.TRACKINGHD]}
        // Setting key to force reset of state for hddata version of page
        render={<Track tab={TrackEventsTabs.Events} hddata={true} key="hddata" />}
      />
      {/* Removed as per TN360-6352 */}
      {/* <CanRoute
        path={TrackingPaths.Summary}
        otherConditions={[() => !userPreferences?.trackingBeta]}
        render={<TripSummaryDetails tab={Tabs.TripSummary} />}
      />
      <CanRoute
        path={TrackingPaths.Details}
        otherConditions={[() => !userPreferences?.trackingBeta]}
        render={<TripSummaryDetails tab={Tabs.VehicleDetails} />}
      />
      <CanRoute
        path={TrackingPaths.Parameters}
        otherConditions={[() => !userPreferences?.trackingBeta]}
        render={<TripSummaryDetails tab={Tabs.VehicleParameters} />}
      /> */}
      <CanRoute path={TrackingPaths.Proximity} render={<Proximity />} />
      <CanRoute path={TrackingPaths.MapTiles} render={<MapTiles />} />
      <CanRoute path={TrackingPaths.CardView} render={<CardView />} />
      <CanRoute path={[TrackingPaths.Track, TrackingPaths.Default]} render={<Track />} />
      {/* Removed as per TN360-6352 */}
      {/* {userPreferences?.trackingBeta ? (
        <CanRoute
          path={[TrackingPaths.Track, TrackingPaths.Default]}
          otherConditions={[() => userPreferences?.trackingBeta]}
          render={<Track />}
        />
      ) : (
        <CanRoute
          path={TrackingPaths.Lens}
          otherConditions={[() => !userPreferences?.trackingBeta]}
          render={<Trips />}
        />
      )} */}
    </Switch>
  );
};
