import React from 'react';

export class SortUtil {
  static sortStringIgnoreCase(a, b, dataKey) {
    const a_i = a?.[dataKey]?.toLowerCase();
    const b_i = b?.[dataKey]?.toLowerCase();
    if (a_i > b_i) {
      return -1;
    } else if (a_i === b_i) {
      return 0;
    } else {
      return 1;
    }
  }

  static sortNumber(a, b, dataKey) {
    const a_i = parseFloat(a?.[dataKey] ?? -1);
    const b_i = parseFloat(b?.[dataKey] ?? -1);
    if (a_i > b_i) {
      return -1;
    } else if (a_i === b_i) {
      return 0;
    } else {
      return 1;
    }
  }

  static sortDate(a, b, dataKey) {
    const a_d = new Date(a?.[dataKey]);
    const b_d = new Date(b?.[dataKey]);
    const isAInvalid = isNaN(a_d);
    const isBInvalid = isNaN(b_d);
    if (isAInvalid && isBInvalid) {
      return 0;
    } else if (isAInvalid) {
      return 1;
    } else if (isBInvalid) {
      return -1;
    }
    if (a_d > b_d) {
      return -1;
    } else if (a_d === b_d) {
      return 0;
    } else {
      return 1;
    }
  }

  static sortNumbersandStrings(a, b, dataKey) {
    const valueA = a?.[dataKey];
    const valueB = b?.[dataKey];

    const extractValue = value => {
      if (React.isValidElement(value)) {
        return value.props.children;
      }
      return value;
    };

    const parseDate = value => {
      const date = new Date(value);
      return isNaN(date) ? null : date;
    };

    const extractedA = extractValue(valueA);
    const extractedB = extractValue(valueB);

    const a_d = parseDate(extractedA);
    const b_d = parseDate(extractedB);

    if (a_d && b_d) {
      // Both values are valid dates
      if (a_d > b_d) {
        return -1;
      } else if (a_d === b_d) {
        return 0;
      } else {
        return 1;
      }
    }

    const a_n = parseFloat(extractedA);
    const b_n = parseFloat(extractedB);

    const isNumA = !isNaN(a_n);
    const isNumB = !isNaN(b_n);

    if (isNumA && isNumB) {
      // Both values are numbers
      if (a_n > b_n) {
        return -1;
      } else if (a_n === b_n) {
        return 0;
      } else {
        return 1;
      }
    }

    // Both values are strings or mixed types
    const a_s = extractedA?.toLowerCase();
    const b_s = extractedB?.toLowerCase();

    if (a_s > b_s) {
      return -1;
    } else if (a_s === b_s) {
      return 0;
    } else {
      return 1;
    }
  }
}
