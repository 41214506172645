import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//components
import { AuditsTable } from 'components/auditsTable/AuditsTable';

//slices
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits } from 'features/audits';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';

// helpers
import { getIDFromPathname } from 'utils/methods';

//constants
import { PATHS } from './constants';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { useHistory } from 'react-router';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import { useLocations } from 'features/locations/locationsSlice';

export const LocationAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const audits = useAudits(AUDIT_ENTITY.LOCATION, id);
  const history = useHistory();
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.DEFAULT);
  }, [history]);
  const locations = useLocations();
  const location = locations?.find(location => location.id === Number(id));
  const pageTitle = !location
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: `${location?.name}` });

  useRedirectToMainFeaturePageOnCompanyChange('/settings/locations');

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle, dispatch]);

  useEffect(() => {
    if (id <= 0 || isNaN(id) || (locations?.length > 0 && !location && !audits)) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [dispatch, id, locations, handleFetchError, location, audits]);

  return <AuditsTable audits={audits} entity={AUDIT_ENTITY.LOCATION} />;
};
