import React from 'react';
import { Skeleton } from 'antd';

const alertCardDimensions = {
  width: 290,
  height: 140,
  margin: 12
};

export const getSkeletonLoaders = validPermissions =>
  validPermissions.map(permission => (
    <Skeleton.Input key={permission.type} style={alertCardDimensions} active />
  ));
