import React, { useState } from 'react';
import { Tabs, Modal } from 'antd';
import i18next from 'i18nextConfig';

import { HealthCheck } from './/HealthCheck';
import { LiveDeviceActivity } from './LiveDeviceActivity';
import { NetworkUsage } from './NetworkUsage';

import styles from './DiagnosticsModal.module.scss';

const TABS = [
  {
    key: '1',
    get label() {
      return i18next.t('CameraHealth.Health Check');
    },
    children: <HealthCheck className={styles.tabContent} />
  },
  {
    key: '2',
    get label() {
      return i18next.t('CameraHealth.Live Device Activity');
    },
    children: <LiveDeviceActivity />
  },
  {
    key: '3',
    get label() {
      return i18next.t('CameraHealth.Network Usage');
    },
    children: <NetworkUsage className={styles.tabContent} />
  }
];

export function DiagnosticsModal({ open, onClose }) {
  const [activeKey, setActiveKey] = useState('1');
  return (
    <Modal
      open={open}
      maskClosable={false}
      style={{
        top: 60
      }}
      onCancel={() => {
        setActiveKey('1');
        onClose();
      }}
      width={'calc(100vw - 450px)'}
      className={styles.diagnosticsModal}
      bodyStyle={{
        paddingTop: 0
      }}
      footer={null}
      destroyOnClose
    >
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey="1"
        type="card"
        items={TABS}
        tabBarGutter={16}
        activeKey={activeKey}
        onChange={key => setActiveKey(key)}
      />
    </Modal>
  );
}
