import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import {
  associationsCellRenderer,
  cache,
  fleetNameCellRenderer,
  markerColorCellRenderer,
  companyCellRenderer,
  ellipsisButtonCellRenderer,
  carrierCellRenderer,
  markerTypeCellRenderer
} from './CellRenderers';
import { useTranslation } from 'react-i18next';

import { LoadingTable } from 'components/grid/LoadingTable';
import { useCan, entities } from 'features/permissions';
import { Empty } from 'components/ant';

export const FleetsTable = ({
  fleets,
  companies,
  userKey,
  isLoading,
  setTableRef,
  typeOfEntityToDelete,
  handleAction,
  handleDeleteAction,
  filterTab
}) => {
  const can = useCan();
  const canViewFleet = can && can({ everyEntity: [entities.FLEET_VIEW] });
  const { t } = useTranslation();
  if (isLoading) return <LoadingTable columnSizes={[70, 42, 91, 58, 58, 43, 29]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 250,
          company: 150,
          carrier: 150,
          description: 300,
          markerType: 150,
          markerColor: 138,
          ellipsisButton: 80
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.name +
            fixedColumnsWidth.company +
            fixedColumnsWidth.carrier +
            fixedColumnsWidth.description +
            fixedColumnsWidth.markerType +
            fixedColumnsWidth.markerColor +
            fixedColumnsWidth.ellipsisButton);

        const hideEdit = filterTab === 'deleted';
        const hideDelete = filterTab === 'deleted';
        const enableRestoreAction = filterTab === 'deleted';
        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={fleets.length}
            rowGetter={({ index }) => fleets[index]}
            ref={ref => setTableRef(ref)}
            noRowsRenderer={() => <Empty />}
          >
            <Column
              label={t('Common.Name')}
              dataKey="name"
              width={fixedColumnsWidth.name}
              cellRenderer={props => fleetNameCellRenderer({ ...props, canViewFleet })}
            />
            <Column
              label={t('Common.Company')}
              dataKey="companyId"
              width={fixedColumnsWidth.company}
              cellRenderer={props => companyCellRenderer({ ...props, companies: companies })}
            />
            <Column
              label={t('ELD.Carrier_DOT#')}
              dataKey="carrierId"
              width={fixedColumnsWidth.carrier}
              cellRenderer={carrierCellRenderer}
            />
            <Column
              label={t('Common.Description')}
              dataKey="description"
              width={fixedColumnsWidth.description}
            />
            <Column
              label={t('Fleets.MarkerType')}
              dataKey="iconType"
              width={fixedColumnsWidth.markerType}
              cellRenderer={markerTypeCellRenderer}
            />
            <Column
              label={t('Fleets.MarkerColor')}
              dataKey="colour"
              width={fixedColumnsWidth.markerColor}
              cellRenderer={markerColorCellRenderer}
            />
            <Column
              label={t('Common.TableColumns.Associations')}
              dataKey="associations"
              width={dynamicColumnWidth}
              cellRenderer={props => associationsCellRenderer({ ...props, t })}
            />
            <Column
              label={t('Common.TableColumns.Actions')}
              dataKey=""
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.ellipsisButton}
              cellRenderer={props =>
                ellipsisButtonCellRenderer({
                  ...props,
                  userKey,
                  typeOfEntityToDelete,
                  hideEdit,
                  hideDelete,
                  enableRestoreAction,
                  handleAction,
                  handleDeleteAction
                })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
