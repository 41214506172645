import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AlertData.module.scss';

export function FatigueAlertContentRender({ alertData }) {
  const { t } = useTranslation();
  return (
    <>
      {alertData.driverId !== 'Unknown' && (
        <>
          <> {t('Notifications.FromDriver')} - </>
          <strong>{alertData.driverName}</strong>.{' '}
        </>
      )}
      {t('Notifications.AtLocation')}:<span className={styles.location}> {alertData.location}</span>
    </>
  );
}
