import React, { useMemo } from 'react';
import Map, { MapMode } from 'components/map/Map';
import { GOOGLE_MAPS_API_KEY } from 'config';

export const CardViewMap = ({ card, geofences = [] }) => {
  const devices = useMemo(() => [card], [card?.id, card?.deviceStats, card?.trips]);
  return (
    <Map
      mode={MapMode.Trips}
      devices={devices}
      geofences={geofences}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div className="cardViewMap" style={{ height: `100%`, width: `100%` }} />}
      mapElement={<div style={{ height: `100%`, width: `100%` }} />}
    />
  );
};
