import React from 'react';
import { List, Spin } from 'antd';

import {
  useCurrentCompany,
  useSubCompanyEntityConfig,
  CompanyConfigKey,
  CompanyConfigValue
} from 'features/company/companySlice';

import { useTranslation } from 'react-i18next';

import { TrackEventsList } from './TrackEventsList';
import { OutOfTripEventsItem } from './OutOfTripEventsItem';
import { useSupportedEventsFilter } from './Modals/FilterEventsModal/FilterEvents';
import { EventTypes } from 'containers/Tracking/EventTypes';

import eventStyles from './TrackEvents.module.scss';

const getTripSummaryEvents = (trip, trips, allEvents) => {
  let newAllEvents = allEvents;
  let tripSummaryEvents = [];

  // If it has Ignition On data, add Ignition On event for display
  if (trip.ignitionOn && trip.IgnOnGPS) {
    // get ignition on event from list
    let tripStartEvent = (trip?.events || []).find(
      event => event.eventType + event.subType === EventTypes.IgnitionOn.key
    );

    // If it is missing (ex: filtered out) manually add it back, trip list always shows ignition on/off if trip has it
    if (!tripStartEvent) {
      tripStartEvent = {
        GPS: trip.IgnOnGPS,
        device: trip.device,
        driverName: trip.driverName,
        eventType: 'IOR',
        id: -1,
        location: trip.startLocation,
        subType: 'ON',
        timeAt: trip.ignitionOn,
        user: trip.driver,
        vehicle: trip.vehicle
      };
    }

    if (
      !(allEvents || []).find(
        event => event.eventType + event.subType === EventTypes.IgnitionOn.key
      )
    ) {
      newAllEvents = [tripStartEvent, ...newAllEvents];
    }

    tripSummaryEvents.push(tripStartEvent);
  } else if (trip.isPartialTrip) {
    if (allEvents && allEvents.length > 0) {
      tripSummaryEvents.push(allEvents[0]);
    }
  }

  // If it has Ignition Off data add Ignition Off event for display
  if (trip.ignitionOff && trip.IgnOffGPS) {
    // get ignition off event from list
    let tripEndEvent = (trip?.events || []).find(
      event => event.eventType + event.subType === EventTypes.IgnitionOff.key
    );

    // If it is missing (ex: filtered out) manually add it back, trip list always shows ignition on/off if trip has it
    if (!tripEndEvent) {
      tripEndEvent = {
        GPS: trip.IgnOffGPS,
        device: trip.device,
        driverName: trip.driverName,
        eventType: 'IOR',
        id: -2,
        location: trip.endLocation,
        subType: 'OFF',
        timeAt: trip.ignitionOff,
        user: trip.driver,
        vehicle: trip.vehicle
      };
    }

    if (
      !(allEvents || []).find(
        event => event.eventType + event.subType === EventTypes.IgnitionOff.key
      )
    ) {
      newAllEvents = [...newAllEvents, tripEndEvent];
    }

    tripSummaryEvents.push(tripEndEvent);
  } else if (trip.isPartialTrip) {
    if (allEvents && allEvents.length > 0) {
      tripSummaryEvents.push(allEvents[allEvents.length - 1]);
    }
  } else {
    // If it is the first trip and is missing trip end event then put "Current Trip" event on
    if (trip.ignitionOn && trip.index === trips.length - 1) {
      const currentTripEvent = {
        isCurrentTripEvent: true,
        device: trip.device,
        driverName: trip.driverName,
        id: -3,
        location: trip.lastLocation,
        timeAt: '', // should we get last update time from device stats?
        user: trip.driver,
        vehicle: trip.vehicle
      };
      tripSummaryEvents.push(currentTripEvent);

      // Also add current trip to regular events for when expanded
      newAllEvents = [...newAllEvents, currentTripEvent];
    }
  }

  return { tripSummaryEvents, allEvents: newAllEvents };
};

const TripListItem = ({
  device,
  trip,
  trips,
  index,
  userGridSettings,
  selectedTrip,
  selectedEvent,
  t,
  onTripClicked,
  onDateRangeSelected,
  onEventClicked,
  onDeviceClicked,
  hideNonBusinessTrips
}) => {
  const onRowClick = e => {
    if (trip?.id !== selectedTrip?.id) {
      onTripClicked(trip);
    }
  };

  let isExpanded = trip.id === selectedTrip?.id;
  const isFirstTrip = index === 0;
  const isLastTrip = index === trips.length - 1;
  const isNonBusinessTrip = hideNonBusinessTrips && trip?.attr === CompanyConfigValue.Private;

  const tripListItemClass = isLastTrip ? eventStyles.tripListItemLast : eventStyles.tripListItem;

  const { getFilteredEventsForTrip } = useSupportedEventsFilter();
  const filteredEvents = getFilteredEventsForTrip(userGridSettings, trip, hideNonBusinessTrips);

  const { tripSummaryEvents, allEvents } = getTripSummaryEvents(trip, trips, filteredEvents);

  const groupIndicatorClass = isExpanded
    ? eventStyles.groupIndicatorExpanded
    : eventStyles.groupIndicatorCollapsed;

  const isOutOfTripEvents = !trip.id && trip.events && trip.events.length > 0 && !trip.IgnOnGPS;

  let nextTrip = null;
  if (!isOutOfTripEvents) {
    nextTrip = index < trips.length - 1 ? trips[index + 1] : null;
    if (!nextTrip?.id && index < trips.length - 2) {
      nextTrip = trips[index + 2];
    }
  }

  return (
    <>
      {isOutOfTripEvents ? (
        <OutOfTripEventsItem
          device={device}
          allEvents={allEvents}
          tripSummaryEvents={tripSummaryEvents}
          trip={trip}
          selectedEvent={selectedEvent}
          t={t}
          onEventClicked={onEventClicked}
          onDeviceClicked={onDeviceClicked}
        />
      ) : (
        <List.Item
          style={{
            cursor: isNonBusinessTrip ? 'default' : 'pointer'
          }}
          className={tripListItemClass}
          key={trip.id ? trip.id : trip.index}
          onClick={!isNonBusinessTrip ? onRowClick : null}
        >
          <TrackEventsList
            device={device}
            allEvents={allEvents}
            tripSummaryEvents={tripSummaryEvents}
            trip={trip}
            nextTrip={nextTrip}
            groupByTripsEnabled={true}
            isOutOfTripEvents={false}
            isLoading={false}
            isExpanded={isExpanded}
            selectedEvent={selectedEvent}
            showScroll={false}
            isFirstTrip={isFirstTrip}
            isLastTrip={isLastTrip}
            onDateRangeSelected={onDateRangeSelected}
            onEventClicked={onEventClicked}
            onDeviceClicked={onDeviceClicked}
          />
          <div className={groupIndicatorClass} />
        </List.Item>
      )}
    </>
  );
};

export const TrackEventsTripList = ({
  device,
  trips,
  userGridSettings,
  isLoading,
  selectedTrip,
  selectedEvent,
  onTripClicked,
  onDateRangeSelected,
  onEventClicked,
  onDeviceClicked
}) => {
  const { t } = useTranslation();
  const currentCompany = useCurrentCompany();
  const hideNonBusinessTrips = useSubCompanyEntityConfig(
    currentCompany?.id,
    CompanyConfigKey.HideNonBusiness
  );

  return isLoading ? (
    <Spin className={eventStyles.eventListLoading} />
  ) : trips?.length > 0 ? (
    <List
      className={eventStyles.tripList}
      dataSource={trips}
      loading={isLoading}
      renderItem={(item, index) =>
        TripListItem({
          device,
          trip: item,
          trips,
          index,
          userGridSettings,
          selectedTrip,
          selectedEvent,
          t,
          onTripClicked,
          onDateRangeSelected,
          onEventClicked,
          onDeviceClicked,
          hideNonBusinessTrips
        })
      }
    />
  ) : (
    <div className={eventStyles.eventListNoDataMessage}>{t('Tracking.NoTrips')}</div>
  );
};
