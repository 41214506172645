import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import styles from '../modal.module.scss';

export const confirm = ({
  className,
  title,
  content,
  confirmButtonText,
  confirmButtonStyles,
  confirmButtonProps,
  onConfirm,
  cancelButtonText,
  cancelButtonProps,
  onCancel,
  iconStyles,
  buttonType,
  width
}) => {
  return Modal.warning({
    className: `${styles.modal} ${className ? className : ''}`,
    title: title,
    content: content,
    okText: confirmButtonText,
    okButtonProps: {
      danger: buttonType === 'danger',
      style: {
        ...(buttonType === 'danger'
          ? { backgroundColor: 'rgba(218, 97, 97, 255)' }
          : { backgroundColor: 'rgba(134, 179, 65, 1)' }),
        ...confirmButtonStyles
      },
      type: 'primary',
      ...confirmButtonProps
    },
    onOk: () => onConfirm(),
    confirmLoading: true,
    cancelText: cancelButtonText,
    cancelButtonProps: {
      type: 'secondary',
      ...cancelButtonProps
    },
    onCancel: () => (onCancel ? onCancel() : Modal.destroyAll()),
    icon: (
      <ExclamationCircleOutlined
        style={{
          ...(buttonType === 'danger'
            ? { color: 'rgba(218, 97, 97, 255)' }
            : { color: 'rgba(250, 173, 20, 1)' }),
          ...iconStyles
        }}
      />
    ),
    centered: true,
    okCancel: true,
    destroyOnClose: true,
    autoFocusButton: null,
    width: width || 'max-content'
  });
};
