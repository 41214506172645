import i18n from 'i18next';
import { capitalize } from 'utils/strings';
import { TYPE } from './constants';

const getDeviceMetersOptions = (deviceType, deviceMetersOptions) => {
  const meterTypes = [];
  const options = deviceMetersOptions.get(deviceType);
  if (options) {
    options.forEach((_, meterType) => {
      meterTypes.push({
        id: meterType,
        value: meterType,
        label: i18n.t(`MeterTypes.${meterType}`, capitalize(meterType))
      });
    });
  }

  return meterTypes;
};

const getVehicleMetersOptions = vehicleMetersOptions => {
  const meterTypes = [];

  vehicleMetersOptions.forEach((_, key) =>
    meterTypes.push({
      id: key,
      value: key,
      label: i18n.t(`MeterTypes.${key}`, capitalize(key))
    })
  );

  return meterTypes;
};

export const getMeterTypeOptions = (deviceType, meterEntityType, options) => {
  if (options) {
    switch (meterEntityType) {
      case TYPE.DEVICE:
        return getDeviceMetersOptions(deviceType, options);
      case TYPE.VEHICLE:
        return getVehicleMetersOptions(options);
      default:
        return;
    }
  }
};

const getDeviceMetersSources = (deviceType, meterType, deviceMetersOptions) =>
  deviceMetersOptions
    .get(deviceType)
    ?.get(meterType)
    ?.map(source => ({
      id: source,
      value: source,
      label: source
    }));

const getVehicleMetersSources = (meterType, vehicleMetersOptions) =>
  vehicleMetersOptions.get(meterType)?.map(source => ({
    id: source,
    value: source,
    label: source
  }));

export const getMeterConfigurationOptions = (
  deviceType,
  meterType,
  meterEntityType,
  deviceMetersOptions,
  vehicleMetersOptions
) => {
  if (meterEntityType === TYPE.DEVICE && deviceMetersOptions) {
    return getDeviceMetersSources(deviceType, meterType, deviceMetersOptions);
  }
  if (meterEntityType === TYPE.VEHICLE && vehicleMetersOptions) {
    return getVehicleMetersSources(meterType, vehicleMetersOptions);
  }
};

export const getMeterConfigurationProperties = (properties, label, placeholder, name, rules) => {
  const meterTypeProperties = {
    ...properties,
    label: label.meterType,
    placeholder: placeholder.meterType,
    name: name.meterType,
    rules: rules.meterType
  };
  const deviceTypeProperties = {
    ...properties,
    label: label.deviceType,
    placeholder: placeholder.deviceType,
    name: name.deviceType,
    rules: rules.deviceType
  };

  const sourceProperties = {
    placeholder: placeholder.source,
    name: name.source,
    rules: rules.source
  };

  const operationProperties = {
    placeholder: placeholder.operation,
    name: name.operation,
    rules: rules.operation
  };

  const valueProperties = {
    placeholder: placeholder.value,
    name: name.value,
    rules: rules.value
  };

  return {
    meterTypeProperties,
    deviceTypeProperties,
    sourceProperties,
    operationProperties,
    valueProperties
  };
};

export const getSpinDynamicStyle = action => ({
  height: 90,
  width: 265,
  ...(action === 'add' && { position: 'relative' })
});
