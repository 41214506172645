import React from 'react';

import { Tooltip } from 'components/ant';

import style from './Scorecard.module.scss';

export const Paths = {
  MAIN_PAGE: '/scorecard',
  USER_PAGE: '/scorecard/drivers/id',
  METRIC_SCORING: '/scorecard/metric'
};

export const Entities = {
  COMPANY: 'company',
  BRANCH: 'branch',
  FLEET: 'fleet'
};

export const EntityTypes = {
  Company: 'Ng::Company',
  User: 'Ng::User',
  Vehicle: 'Ng::Vehicle',
  Location: 'Ng::Location',
  Fleet: 'Ng::Fleet'
};

export const TabKeys = {
  drivers: 'drivers',
  vehicles: 'vehicles'
};

export const Tabs = [
  { pathame: '/scorecard/drivers', history: {}, key: TabKeys.drivers },
  {
    pathame: '/scorecard/vehicles',
    history: {},
    key: TabKeys.vehicles
  }
];

export const TabsMetricScoring = [
  { pathame: '/scorecard/metric/drivers', history: {}, key: TabKeys.drivers },
  { pathame: '/scorecard/metric/vehicles', history: {}, key: TabKeys.vehicles }
];

export const MoreMenu = {
  Refresh: 'Refresh',
  MetricScoring: 'MetricScoring',
  ExcelExport: 'ExcelExport',
  ScoreConfiguration: 'ScoreConfiguration',
  ManageGeofences: 'ManageGeofences'
};

export const Buckets = [
  'Scorecard.Bucket1',
  'Scorecard.Bucket2',
  'Scorecard.Bucket3',
  'Scorecard.Bucket4',
  'Scorecard.Bucket5',
  'Scorecard.Bucket6',
  'Scorecard.Bucket7',
  'Scorecard.Bucket8',
  'Scorecard.Bucket9',
  'Scorecard.Bucket10',
  'Scorecard.Bucket11',
  'Scorecard.Bucket12'
];

export const ScorecardEventType = {
  TRIP_SUMMARY: 'TRIP_SUMMARY',
  HARSH_ACCELERATION: 'HARSH_ACCELERATION',
  HARSH_BRAKING: 'HARSH_BRAKING',
  HARSH_CORNERING: 'HARSH_CORNERING',
  EXCESSIVE_IDLE: 'EXCESSIVE_IDLE',
  OVERREV: 'OVERREV',
  SPEED: 'SPEED',
  ADJUSTMENT: 'ADJUSTMENT',
  SEAT_BELT_NON_USAGE: 'SEAT_BELT_NON_USAGE',
  FATIGUE_UNDER_REST: 'FATIGUE_UNDER_REST',
  FATIGUE_OVER_WORKED: 'FATIGUE_OVER_WORKED'
};

// Non localized unit lables for scorecard UI
export const ScorecardUnitLabels = {
  ACCELERATION: 'm/s²',
  RPM: 'rpm',
  MINUTES_SHORT: 'min',
  PER_COUNT: 'PerCount',
  PERCENT_VARIANCE: '%',
  SECONDS: 'sec'
};

// Prefix on EventTyps for geofence.features array in DB (ie excluded scorecard events)
// Events will looks like this: SC_SPEED, SC_HARSH_BRAKING
export const GEOFENCE_EXCLUDE_PREFIX = 'SC_';

export const EventTypes = (localization, event = null) => ({
  SPEED: {
    name: 'Scorecard.SpeedEvent',
    englishTitle: 'Speed', // for csv export - english only
    key: ScorecardEventType.SPEED,
    dataKey: 'speed',
    eventType: 'SPEED', // for lookup in EventTypesMap
    subType: 'END',
    buckets: [
      [{ max: 5 }, { max: 5 }],
      [{ max: 5 }, { min: 5, max: 10 }],
      [{ max: 5 }, { min: 10 }],
      [{ min: 5, max: 10 }, { max: 5 }],
      [
        { min: 5, max: 10 },
        { min: 5, max: 10 }
      ],
      [{ min: 5, max: 10 }, { min: 10 }],
      [{ min: 10, max: 15 }, { max: 5 }],
      [
        { min: 10, max: 15 },
        { min: 5, max: 10 }
      ],
      [{ min: 10, max: 15 }, { min: 10 }],
      [{ min: 15 }, { max: 5 }],
      [{ min: 15 }, { min: 5, max: 10 }],
      [{ min: 15 }, { min: 10 }]
    ],
    unit: [localization?.formats?.speed?.unit_per_hour, 'sec'],
    color: '#40a9ff',
    order: 1
  },
  HARSH_ACCELERATION: {
    name: 'Scorecard.HarshAcceleration',
    englishTitle: 'Harsh Acceleration',
    key: ScorecardEventType.HARSH_ACCELERATION,
    dataKey: 'harshAcceleration',
    eventType: event?.eventType === 'Ng::Event::Camera' ? 'CAMERA' : 'VPM', // for lookup in EventTypesMap
    subType: event?.eventType === 'Ng::Event::Camera' ? 'HARSH_USAGE' : 'EA',
    buckets: [
      [{ max: 2 }],
      [{ min: 2, max: 3 }],
      [{ min: 3, max: 4 }],
      [{ min: 4, max: 5 }],
      [{ min: 5, max: 6 }],
      [{ min: 6, max: 7 }],
      [{ min: 7, max: 8 }],
      [{ min: 8 }]
    ],
    emptyBuckets: new Array(4).fill('-'),
    unit: [ScorecardUnitLabels.ACCELERATION],
    color: '#14946a',
    order: 2
  },
  HARSH_BRAKING: {
    name: 'Scorecard.HarshBraking',
    englishTitle: 'Harsh Braking',
    key: ScorecardEventType.HARSH_BRAKING,
    dataKey: 'harshBraking',
    eventType: event?.eventType === 'Ng::Event::Camera' ? 'CAMERA' : 'VPM', // for lookup in EventTypesMap
    subType: event?.eventType === 'Ng::Event::Camera' ? 'HARSH_USAGE' : 'HB',
    buckets: [
      [{ max: 2 }],
      [{ min: 2, max: 3 }],
      [{ min: 3, max: 4 }],
      [{ min: 4, max: 5 }],
      [{ min: 5, max: 6 }],
      [{ min: 6, max: 7 }],
      [{ min: 7, max: 8 }],
      [{ min: 8 }]
    ],
    emptyBuckets: new Array(4).fill('-'),
    unit: [ScorecardUnitLabels.ACCELERATION],
    color: '#47c79c',
    order: 3
  },
  HARSH_CORNERING: {
    name: 'Scorecard.HarshCornering',
    englishTitle: 'Harsh Cornering',
    key: ScorecardEventType.HARSH_CORNERING,
    dataKey: 'harshCornering',
    eventType: event?.eventType === 'Ng::Event::Camera' ? 'CAMERA' : 'VPM', // for lookup in EventTypesMap
    subType: event?.eventType === 'Ng::Event::Camera' ? 'HARSH_USAGE' : 'HC',
    buckets: [[{ max: 4 }], [{ min: 4, max: 4.5 }], [{ min: 4.5, max: 5 }], [{ min: 5 }]],
    emptyBuckets: new Array(8).fill('-'),
    unit: [ScorecardUnitLabels.ACCELERATION],
    color: '#d48806',
    order: 4
  },
  OVERREV: {
    name: 'Scorecard.OverRevving',
    englishTitle: 'Over Revving',
    key: ScorecardEventType.OVERREV,
    dataKey: 'overRev',
    eventType: 'VPM', // for lookup in EventTypesMap
    subType: 'OR',
    buckets: [
      [{ min: 0, max: 10 }, { max: 5 }],
      [{ min: 0, max: 10 }, { min: 5 }],
      [{ min: 10, max: 20 }, { max: 5 }],
      [{ min: 10, max: 20 }, { min: 5 }],
      [{ min: 20, max: 30 }, { max: 5 }],
      [{ min: 20, max: 30 }, { min: 5 }],
      [{ min: 30 }, { max: 5 }],
      [{ min: 30 }, { min: 5 }]
    ],
    emptyBuckets: new Array(4).fill('-'),
    unit: [ScorecardUnitLabels.PERCENT_VARIANCE, 'sec'],
    color: '#d48806',
    order: 5
  },
  EXCESSIVE_IDLE: {
    name: 'Scorecard.IdleTime',
    englishTitle: 'Idle Time',
    key: ScorecardEventType.EXCESSIVE_IDLE,
    dataKey: 'excessiveIdle',
    eventType: 'VPM', // for lookup in EventTypesMap
    subType: 'IT',
    buckets: [
      [{ max: 3 }],
      [{ min: 3, max: 5 }],
      [{ min: 5, max: 10 }],
      [{ min: 10, max: 15 }],
      [{ min: 15, max: 20 }],
      [{ min: 20, max: 30 }],
      [{ min: 30, max: 60 }],
      [{ min: 60 }]
    ],
    emptyBuckets: new Array(4).fill('-'),
    unit: [ScorecardUnitLabels.MINUTES_SHORT],
    color: '#AA60CD',
    order: 6
  },
  SEAT_BELT_NON_USAGE: {
    name: 'Scorecard.seatbeltNonUsage',
    englishTitle: 'Seatbelt Non Usage',
    key: ScorecardEventType.SEAT_BELT_NON_USAGE,
    dataKey: 'seatbeltNonUsage',
    eventType: 'ALARM', // for lookup in EventTypesMap
    subType: 'SB',
    buckets: [[{}]],
    emptyBuckets: new Array(11).fill('-'),
    unit: [ScorecardUnitLabels.PER_COUNT],
    color: '#ff77bc',
    order: 7
  },
  FATIGUE_UNDER_REST: {
    name: 'Scorecard.underRest',
    englishTitle: 'Under Rest',
    key: ScorecardEventType.FATIGUE_UNDER_REST,
    dataKey: 'underRest',
    eventType: 'FATIGUE', // for lookup in EventTypesMap
    subType: 'BR',
    buckets: [[{}]],
    emptyBuckets: new Array(11).fill('-'),
    unit: [ScorecardUnitLabels.PER_COUNT],
    color: '#003a8c',
    order: 8
  },
  FATIGUE_OVER_WORKED: {
    name: 'Scorecard.overWorked',
    englishTitle: 'Over Worked',
    key: ScorecardEventType.FATIGUE_OVER_WORKED,
    dataKey: 'overWorked',
    eventType: 'ALARM', // for lookup in EventTypesMap
    subType: 'DR',
    buckets: [[{}]],
    emptyBuckets: new Array(11).fill('-'),
    unit: [ScorecardUnitLabels.PER_COUNT],
    color: '#ff4d4f',
    order: 9
  }
});

export const ColumnKeys = {
  RANK: 'rank',
  GROUP_RANK: 'groupRank',
  NAME: 'name',
  ACTIVE_DAYS: 'activeDays',
  DISTANCE: 'distanceTravelled',
  FUEL_USE: 'fuelConsumed',
  MET_TARGET_SCORE: 'metTargetScore',
  SCORE: 'normalizedScore',
  FATIGUE_VIOLATIONS: 'fatigue',
  HARSH_ACCELERATION: 'harshAcceleration',
  HARSH_BRAKING: 'harshBraking',
  HARSH_CORNERING: 'harshCornering',
  OVERREV: 'overRev',
  EXCESSIVE_IDLE: 'excessiveIdle',
  SPEED: 'speed',
  ACTIONS: 'actions',
  ENTITY_ID: 'entityId',
  SEAT_BELT_NON_USAGE: 'seatbeltNonUsage',
  FATIGUE_UNDER_REST: 'underRest',
  FATIGUE_OVER_WORKED: 'overWorked'
};

export const Columns = (t, localization, tab) => [
  {
    dataIndex: ColumnKeys.RANK,
    key: ColumnKeys.RANK,
    title: t(`Scorecard.CompanyRank`),
    width: 100,
    sortable: true,
    isNumber: true,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.GROUP_RANK,
    key: ColumnKeys.GROUP_RANK,
    title: t(`Scorecard.BranchRank`),
    width: 90,
    sortable: true,
    isNumber: true,
    isEnabled: tab === TabKeys.drivers,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.GROUP_RANK,
    key: ColumnKeys.GROUP_RANK,
    title: t(`Scorecard.FleetRank`),
    width: 90,
    sortable: true,
    isNumber: true,
    isEnabled: tab === TabKeys.vehicles && false, // disabled pending design
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.NAME,
    key: ColumnKeys.NAME,
    width: 120,
    sortable: true,
    isNumber: false
  },
  {
    dataIndex: ColumnKeys.MET_TARGET_SCORE,
    key: ColumnKeys.MET_TARGET_SCORE,
    width: 120,
    sortable: true,
    isNumber: false,
    isBoolean: true
  },
  {
    dataIndex: ColumnKeys.SCORE,
    key: ColumnKeys.SCORE,
    width: 90,
    sortable: true,
    isNumber: true,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.SPEED,
    key: ColumnKeys.SPEED,
    eventType: ScorecardEventType.SPEED,
    width: 90,
    sortable: true,
    isNumber: true,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.HARSH_ACCELERATION,
    key: ColumnKeys.HARSH_ACCELERATION,
    eventType: ScorecardEventType.HARSH_ACCELERATION,
    width: 130,
    sortable: true,
    isNumber: true,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.HARSH_BRAKING,
    key: ColumnKeys.HARSH_BRAKING,
    eventType: ScorecardEventType.HARSH_BRAKING,
    width: 100,
    sortable: true,
    isNumber: true,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.HARSH_CORNERING,
    key: ColumnKeys.HARSH_CORNERING,
    eventType: ScorecardEventType.HARSH_CORNERING,
    width: 110,
    sortable: true,
    isNumber: true,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.OVERREV,
    key: ColumnKeys.OVERREV,
    eventType: ScorecardEventType.OVERREV,
    width: 90,
    sortable: true,
    isNumber: true,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.EXCESSIVE_IDLE,
    key: ColumnKeys.EXCESSIVE_IDLE,
    eventType: ScorecardEventType.EXCESSIVE_IDLE,
    width: 90,
    sortable: true,
    isNumber: true,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.SEAT_BELT_NON_USAGE,
    key: ColumnKeys.SEAT_BELT_NON_USAGE,
    eventType: ScorecardEventType.SEAT_BELT_NON_USAGE,
    width: 100,
    sortable: true,
    isNumber: true,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.FATIGUE_UNDER_REST,
    key: ColumnKeys.FATIGUE_UNDER_REST,
    eventType: ScorecardEventType.FATIGUE_UNDER_REST,
    width: 90,
    sortable: true,
    isNumber: true,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.FATIGUE_OVER_WORKED,
    key: ColumnKeys.FATIGUE_OVER_WORKED,
    eventType: ScorecardEventType.FATIGUE_OVER_WORKED,
    width: 90,
    sortable: true,
    isNumber: true,
    showTrends: true
  },
  {
    dataIndex: ColumnKeys.ACTIVE_DAYS,
    title: (
      <>
        <span>{t(`Scorecard.${ColumnKeys.ACTIVE_DAYS}`)}</span>
        <Tooltip
          overlayInnerStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}
          content={t('Scorecard.activeDaysTooltip')}
          target={
            <i
              className={`${'tn-i-info'} ${style.colHeaderTooltip}`}
              onClick={e => {
                e.stopPropagation();
              }}
            />
          }
        />
      </>
    ),
    key: ColumnKeys.ACTIVE_DAYS,
    width: 90,
    sortable: true,
    isNumber: true
  },
  {
    dataIndex: ColumnKeys.DISTANCE,
    key: ColumnKeys.DISTANCE,
    title:
      t(`Scorecard.${ColumnKeys.DISTANCE}`) +
      ' (' +
      localization?.formats?.speed?.unit_pluralize +
      ')',
    width: 100,
    sortable: true,
    isNumber: true
  },
  {
    dataIndex: ColumnKeys.FUEL_USE,
    key: ColumnKeys.FUEL_USE,
    title: t(`Scorecard.${ColumnKeys.FUEL_USE}`) + ' (' + localization?.formats?.fuel?.usage + ')',
    width: 100,
    sortable: true,
    isNumber: true
  },
  {
    dataIndex: ColumnKeys.ENTITY_ID,
    key: ColumnKeys.ACTIONS,
    width: 100,
    sortable: false,
    isNumber: false,
    actions: true
  }
];

export const DefaultScore = 0;
export const PerfectScore = 100;
export const DefaultBandColor = 'Blue';

export const EmptyRow = {
  name: '',
  activeDays: 0,
  metTargetScore: false,
  metTargetScoreTrends: false,
  normalizedScore: 100,
  normalizedScoreForTrends: 100,
  distanceTravelled: 0,
  distanceTravelledForTrends: 0,
  fuelConsumed: 0,
  fuelConsumedForTrends: 0,
  tripDistance: 0,
  tripDistanceForTrends: 0,
  tripDuration: 0,
  tripDurationForTrends: 0,
  harshAcceleration: 100,
  harshBraking: 100,
  overRev: 100,
  excessiveIdle: 100,
  harshCornering: 100,
  speed: 100,
  harshAccelerationForTrends: 100,
  harshBrakingForTrends: 100,
  overRevForTrends: 100,
  excessiveIdleForTrends: 100,
  harshCorneringForTrends: 100,
  speedForTrends: 100,
  seatbeltNonUsage: 0,
  underRest: 0,
  overWorked: 0
};

export const xlsRows = {
  rankForTrends: 'rankForTrends',
  rank: 'rank',
  groupRankForTrends: 'groupRankForTrends',
  groupRank: 'groupRank',
  name: 'name',
  metTargetScoreForTrends: 'metTargetScoreForTrends',
  metTargetScore: 'metTargetScore',
  normalizedScoreForTrends: 'normalizedScoreForTrends',
  normalizedScore: 'normalizedScore',
  speedForTrends: 'speedForTrends',
  speed: 'speed',
  harshAccelerationForTrends: 'harshAccelerationForTrends',
  harshAcceleration: 'harshAcceleration',
  harshBrakingForTrends: 'harshBrakingForTrends',
  harshBraking: 'harshBraking',
  harshCorneringForTrends: 'harshCorneringForTrends',
  harshCornering: 'harshCornering',
  overRevForTrends: 'overRevForTrends',
  overRev: 'overRev',
  idleTimeForTrends: 'idleTimeForTrends',
  idleTime: 'idleTime',
  seatbeltNonUsageForTrends: 'seatbeltNonUsageForTrends',
  seatbeltNonUsage: 'seatbeltNonUsage',
  underRestForTrends: 'underRestForTrends',
  underRest: 'underRest',
  overWorkedForTrends: 'overWorkedForTrends',
  overWorked: 'overWorked',
  activeDaysForTrends: 'activeDaysForTrends',
  activeDays: 'activeDays',
  distanceTravelledForTrends: 'distanceTravelledForTrends',
  distanceTravelled: 'distanceTravelled',
  fuelConsumedForTrends: 'fuelConsumedForTrends',
  fuelConsumed: 'fuelConsumed'
};

export const NO_OF_COLUMNS = 28;
export const columnWidth = Array.apply(null, { length: NO_OF_COLUMNS }).map(entry => ({ wch: 20 }));

export const ColorRanges = {
  Red: '#ff4d4f',
  Yellow: '#ffc53d',
  Green: '#26bd8b',
  Blue: '#1890ff'
};
