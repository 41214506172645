import i18n from 'i18next';
import { LANGUAGE_LOCALES } from 'features/localization/languages';
import { getRoundValue } from 'utils/methods';

export const DATE_FORMAT = {
  DEFAULT: 'DD-MM-YYYY'
};

export const UNITS = {
  TEMPERATURE_CELSIUS: '˚C',
  TEMPERATURE_FAHRENHEIT: '˚F',
  PRESSURE_KPA: 'kPa',
  PRESSURE_PSI: 'psi',
  M_SQUARE: 'm/s²',
  FT_SQUARE: 'ft/s²',
  G_FORCE: 'g',
  KM: 'km',
  KM_SQUARE: 'km²',
  M: 'm',
  FT: 'ft',
  MILE: 'mi',
  MILE_PH_PS: 'mph/sec'
};

export const WEIGHT_UNITS = {
  IMPERIAL_TON: 'imperial_ton',
  US_TON: 'us_ton',
  TON: 'tonnes',
  POUND: 'lb',
  KG: 'kg'
};

const CURRENCY_SIGN = {
  POUND: {
    name: 'pound',
    unit: '£'
  },
  DOLLAR: {
    name: 'dollar',
    unit: '$'
  },
  EURO: {
    name: 'euro',
    unit: '€'
  },
  DANISH_KRONE: {
    name: 'danish krone',
    unit: 'Kr.'
  },
  CHILEAN_PESO: {
    name: 'chilean peso',
    unit: '$'
  }
};

export const FUEL_EFFICIENCY_UNITS = {
  MilePerGal: 'mi/gal',
  KGPerGal: 'kg/gal',
  KMPerLiter: 'km/L',
  KGPerLiter: 'kg/L',
  LiterPer100KM: 'L/100km'
};

const CO2_FUEL_LOCALE = {
  US: {
    unit_avr_efficiency: FUEL_EFFICIENCY_UNITS.MilePerGal,
    unit_co2_emission_factor: FUEL_EFFICIENCY_UNITS.KGPerGal
  },
  GB: {
    unit_avr_efficiency: FUEL_EFFICIENCY_UNITS.MilePerGal,
    unit_co2_emission_factor: FUEL_EFFICIENCY_UNITS.KGPerGal
  },
  ANZ: {
    unit_avr_efficiency: FUEL_EFFICIENCY_UNITS.LiterPer100KM,
    unit_co2_emission_factor: FUEL_EFFICIENCY_UNITS.KGPerLiter
  },
  MX: {
    unit_avr_efficiency: FUEL_EFFICIENCY_UNITS.LiterPer100KM,
    unit_co2_emission_factor: FUEL_EFFICIENCY_UNITS.KGPerLiter
  },
  CA: {
    unit_avr_efficiency: FUEL_EFFICIENCY_UNITS.LiterPer100KM,
    unit_co2_emission_factor: FUEL_EFFICIENCY_UNITS.KGPerLiter
  }
};

export const REGION_LOCALE = {
  US: {
    region: 'us',
    locale: LANGUAGE_LOCALES.EN_US
  },
  AU: {
    region: 'au',
    locale: LANGUAGE_LOCALES.EN_AU
  },
  GB: {
    region: 'gb',
    locale: LANGUAGE_LOCALES.EN_GB
  },
  NZ: {
    region: 'nz',
    locale: LANGUAGE_LOCALES.EN_NZ
  },
  MX: {
    region: 'mx',
    locale: LANGUAGE_LOCALES.EN_MX
  },
  CA: {
    region: 'ca',
    locale: LANGUAGE_LOCALES.EN_CA
  },
  FR: {
    region: 'fr',
    locale: LANGUAGE_LOCALES.FR_FR
  },
  DK: {
    region: 'dk',
    locale: LANGUAGE_LOCALES.DA_DK
  },
  CL: {
    region: 'cl',
    locale: LANGUAGE_LOCALES.ES_CL
  }
};

const baseDateConfig = {
  abbr_day_names: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  abbr_month_names: [
    null,
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ],
  day_names: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  formats: {
    default: 'DD-MM-YYYY'
  },
  month_names: [
    null,
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  order: ['day', 'month', 'year']
};
const baseDatetimeConfig = {
  distance_in_words: {
    about_x_hours: {
      one: 'about 1 hour',
      other: 'about %{count} hours'
    },
    about_x_months: {
      one: 'about 1 month',
      other: 'about %{count} months'
    },
    about_x_years: {
      one: 'about 1 year',
      other: 'about %{count} years'
    },
    almost_x_years: {
      one: 'almost 1 year',
      other: 'almost %{count} years'
    },
    half_a_minute: 'half a minute',
    less_than_x_seconds: {
      one: 'less than 1 second',
      other: 'less than %{count} seconds'
    },
    less_than_x_minutes: {
      one: 'less than a minute',
      other: 'less than %{count} minutes'
    },
    over_x_years: {
      one: 'over 1 year',
      other: 'over %{count} years'
    },
    x_seconds: {
      one: '1 second',
      other: '%{count} seconds'
    },
    x_minutes: {
      one: '1 minute',
      other: '%{count} minutes'
    },
    x_days: {
      one: '1 day',
      other: '%{count} days'
    },
    x_months: {
      one: '1 month',
      other: '%{count} months'
    },
    x_years: {
      one: '1 year',
      other: '%{count} years'
    }
  },
  prompts: {
    second: 'Second',
    minute: 'Minute',
    hour: 'Hour',
    day: 'Day',
    month: 'Month',
    year: 'Year'
  }
};
const baseTimeConfig = {
  pm: 'PM',
  am: 'AM',
  formats: {
    default: 'DD-MM-YYYY',
    dby_hm: 'DD-MM-YYYY HH:mm',
    dby_hms_z: 'DD-MM-YYYYTHH:mm:ssZ',
    dby_imp: 'DD MMM YYYY hh:mm A',
    dby_imsp: 'DD MMM YYYY hh:mm:ss A',
    dby_ims: 'DD MMM YYYY hh:mm:ss',
    dby_imsp_moment: 'DD MMM YYYY hh:mm:ss',
    dby: 'DD MMM YYYY',
    bd_hm: 'MMM DD, h:mm A',
    bdo_hm: 'MMM Do, h:mm A',
    bdo_hm_z: 'MMM DD, h:mm A (Z)',
    dby_hm_z: 'DD MMM YYYY HH:mm ZZ',
    dmY_imsp: 'DD/MM/YYYY hh:mm:ss A',
    dmY_imp: 'DD/MM/YYYY hh:mm A',
    dmY: 'DD/MM/YYYY',
    AdBY: 'dddd, DD MMMM YYYY',
    dMy: 'D M Y',
    Md: 'DD MMM'
  }
};
//country/default_time_zone/support_email/support_doc_link/geocode will be overwriten by RegionsAPI
//locale will be override by userPrefs locale
export const LocalizationConfig = {
  US: {
    country: 'United States',
    ...REGION_LOCALE.US,
    default_time_zone: 'America/Los_Angeles',
    support_email: 'TN360help.us@teletracnavman.com',
    support_doc_link: 'https://learn.teletracnavman.net/Help/TN360/en-US/Default.htm',
    iq_camera_support_doc_link:
      'https://learn.teletracnavman.net/Help/TN360/en-US/Content/Hardware/IQ-Camera/IQ-Camera.htm',
    geocode: {
      lat: 37.2582673,
      lng: -104.6540533
    },
    altitude: {
      unit: 'ft'
    },
    area: {
      unit: 'mi²'
    },
    speed: {
      unit: 'mi',
      unit_pluralize: 'miles',
      unit_per_hour: 'mph'
    },
    acceleration: {
      unit: UNITS.M_SQUARE
    },
    fuel: {
      unit: 'gallon(us)',
      economy: 'mpg',
      rate: 'mpg',
      usage: 'gal',
      default: 2.64172,
      ...CO2_FUEL_LOCALE.US
    },
    temperature: {
      unit: UNITS.TEMPERATURE_FAHRENHEIT,
      engine_oil_over_temp: 251.6,
      engine_coolant_over_temp: 224.6
    },
    pressure: {
      oil: UNITS.PRESSURE_PSI
    },
    date: {
      ...baseDateConfig,
      formats: {
        default: 'MM-DD-YYYY'
      },
      order: ['month', 'day', 'year']
    },
    time: {
      pm: 'PM',
      am: 'AM',
      formats: {
        default: 'MM-DD-YYYY',
        dby_hm: 'MM-DD-YYYY HH:mm',
        dby_hms_z: 'MM-DD-YYYYTHH:mm:ssZ',
        dby_imp: 'MMM DD YYYY hh:mm A',
        dby_imsp: 'MMM DD YYYY hh:mm:ss A',
        dby_imsp_moment: 'MMM DD YYYY hh:mm:ss',
        dby: 'MMM DD YYYY',
        bd_hm: 'MMM DD, h:mm A',
        bdo_hm: 'MMM Do, h:mm A',
        bdo_hm_z: 'MMM DD, h:mm A (Z)',
        dby_hm_z: 'MMM DD YYYY HH:mm ZZ',
        dmY_imsp: 'MM/DD/YYYY hh:mm:ss A',
        dmY_imp: 'MM/DD/YYYY hh:mm A',
        dmY: 'MM/DD/YYYY',
        AdBY: 'dddd, MMMM DD YYYY',
        dMy: 'M D Y',
        mdy_iso8061: 'MM-DD-YYYY',
        Md: 'MMM DD'
      }
    },
    datetime: baseDatetimeConfig,
    currency: CURRENCY_SIGN.DOLLAR,
    description: 'All-in-one compliance and fleet reporting portal',
    author: 'Teletrac Navman',
    weight: {
      kg: {
        unit: WEIGHT_UNITS.POUND,
        toKg: 0.453592
      },
      ton: {
        unit: WEIGHT_UNITS.US_TON,
        toTon: 0.907185
      }
    }
  },
  AU: {
    country: 'Australia',
    ...REGION_LOCALE.AU,
    default_time_zone: 'Australia/Sydney',
    support_email: 'nextgensupport@teletracnavman.com',
    support_doc_link: 'https://learn.teletracnavman.net/Help/TN360/en-AU/Default.htm',
    iq_camera_support_doc_link:
      'https://learn.teletracnavman.net/Help/TN360/en-AU/Content/Hardware/IQ-Camera/IQ-Camera.htm',
    geocode: {
      lat: -27.8338922,
      lng: 133.7955454
    },
    altitude: {
      unit: 'm'
    },
    area: {
      unit: 'km²'
    },
    speed: {
      unit: 'km',
      unit_pluralize: 'km',
      unit_per_hour: 'km/h'
    },
    acceleration: {
      unit: UNITS.M_SQUARE
    },
    fuel: {
      unit: 'litre',
      economy: 'L/100km',
      rate: 'L/km',
      usage: 'L',
      default: 10,
      ...CO2_FUEL_LOCALE.ANZ
    },
    temperature: {
      unit: UNITS.TEMPERATURE_CELSIUS,
      engine_oil_over_temp: 122,
      engine_coolant_over_temp: 107
    },
    pressure: {
      oil: UNITS.PRESSURE_KPA
    },
    currency: CURRENCY_SIGN.DOLLAR,
    date: baseDateConfig,
    time: baseTimeConfig,
    datetime: baseDatetimeConfig,
    description: 'All-in-one compliance and fleet reporting portal',
    author: 'Teletrac Navman',
    weight: {
      kg: {
        unit: WEIGHT_UNITS.KG,
        toKg: 0.453592,
        toLb: 2.20462
      },
      ton: {
        unit: WEIGHT_UNITS.TON,
        toTon: 0.907185,
        toImperialTon: 0.984205357094237,
        toUsTon: 1.10231
      }
    }
  },
  GB: {
    country: 'United Kingdom',
    ...REGION_LOCALE.GB,
    default_time_zone: 'Europe/London',
    support_email: 'tn360support-uk@teletracnavman.com',
    support_doc_link: 'https://learn.teletracnavman.net/Help/TN360/en-GB/Default.htm',
    iq_camera_support_doc_link:
      'https://learn.teletracnavman.net/Help/TN360/en-GB/Content/Hardware/IQ-Camera/IQ-Camera.htm',
    geocode: {
      lat: 55.3780518,
      lng: -3.4359729
    },
    altitude: {
      unit: 'm'
    },
    area: {
      unit: 'mi²'
    },
    speed: {
      unit: 'mi',
      unit_pluralize: 'miles',
      unit_per_hour: 'mph'
    },
    acceleration: {
      unit: UNITS.M_SQUARE
    },
    fuel: {
      unit: 'gallon',
      economy: 'mpg',
      rate: 'mpg',
      usage: 'gal',
      default: 2.19969,
      ...CO2_FUEL_LOCALE.GB
    },
    temperature: {
      unit: UNITS.TEMPERATURE_CELSIUS
    },
    pressure: {
      oil: UNITS.PRESSURE_PSI
    },
    date: baseDateConfig,
    time: baseTimeConfig,
    datetime: baseDatetimeConfig,
    currency: CURRENCY_SIGN.POUND,
    description: 'All-in-one compliance and fleet reporting portal',
    author: 'Teletrac Navman',
    weight: {
      kg: {
        unit: WEIGHT_UNITS.POUND,
        toKg: 0.453592
      },
      ton: {
        unit: WEIGHT_UNITS.IMPERIAL_TON,
        toTon: 1.01605
      }
    }
  },
  NZ: {
    country: 'New Zealand',
    ...REGION_LOCALE.NZ,
    default_time_zone: 'Pacific/Auckland',
    support_doc_link: 'https://learn.teletracnavman.net/Help/TN360/en-NZ/Default.htm',
    iq_camera_support_doc_link:
      'https://learn.teletracnavman.net/Help/TN360/en-NZ/Content/Hardware/IQ-Camera/IQ-Camera.htm',
    geocode: {
      lat: -40.4095427,
      lng: 172.6966775
    },
    altitude: {
      unit: 'm'
    },
    area: {
      unit: 'km²'
    },
    speed: {
      unit: 'km',
      unit_pluralize: 'km',
      unit_per_hour: 'km/h'
    },
    acceleration: {
      unit: UNITS.M_SQUARE
    },
    fuel: {
      unit: 'litre',
      economy: 'L/100km',
      rate: 'L/km',
      usage: 'L',
      default: 10,
      ...CO2_FUEL_LOCALE.ANZ
    },
    temperature: {
      unit: UNITS.TEMPERATURE_CELSIUS,
      engine_oil_over_temp: 122,
      engine_coolant_over_temp: 107
    },
    pressure: {
      oil: UNITS.PRESSURE_KPA
    },
    currency: CURRENCY_SIGN.DOLLAR,
    date: baseDateConfig,
    time: baseTimeConfig,
    datetime: baseDatetimeConfig,
    description: 'All-in-one compliance and fleet reporting portal',
    author: 'Teletrac Navman',
    weight: {
      kg: {
        unit: WEIGHT_UNITS.KG,
        toKg: 0.453592,
        toLb: 2.20462
      },
      ton: {
        unit: WEIGHT_UNITS.TON,
        toTon: 0.907185,
        toImperialTon: 0.984205357094237,
        toUsTon: 1.10231,
        imperialTonToTon: 1.01605
      }
    }
  },
  MX: {
    country: 'Mexico',
    ...REGION_LOCALE.MX,
    default_time_zone: 'America/Mexico_City',
    // support_email: 'TN360help.mx@teletracnavman.com', // currently not used
    support_doc_link: 'https://learn.teletracnavman.net/Help/TN360/es-MX/Default.htm',
    iq_camera_support_doc_link:
      'https://learn.teletracnavman.net/Help/TN360/es-MX/Content/Hardware/IQ-Camera/IQ-Camera.htm',
    geocode: {
      lat: 23.634501,
      lng: -102.552784
    },
    altitude: {
      unit: 'm'
    },
    area: {
      unit: 'km²'
    },
    speed: {
      unit: 'km',
      unit_pluralize: 'km',
      unit_per_hour: 'km/h'
    },
    acceleration: {
      unit: UNITS.M_SQUARE
    },
    fuel: {
      unit: 'litre',
      economy: 'L/100km',
      rate: 'L/km',
      usage: 'L',
      default: 10,
      ...CO2_FUEL_LOCALE.MX
    },
    temperature: {
      unit: UNITS.TEMPERATURE_CELSIUS,
      engine_oil_over_temp: 122,
      engine_coolant_over_temp: 107
    },
    pressure: {
      oil: UNITS.PRESSURE_KPA
    },
    date: baseDateConfig,
    time: baseTimeConfig,
    datetime: baseDatetimeConfig,
    currency: CURRENCY_SIGN.DOLLAR,
    description: 'All-in-one compliance and fleet reporting portal',
    author: 'Teletrac Navman',
    weight: {
      kg: {
        unit: WEIGHT_UNITS.KG,
        toKg: 0.453592,
        toLb: 2.20462
      },
      ton: {
        unit: WEIGHT_UNITS.TON,
        toTon: 0.907185,
        toImperialTon: 0.984205357094237,
        toUsTon: 1.10231
      }
    }
  },
  CA: {
    country: 'Canada',
    ...REGION_LOCALE.CA,
    default_time_zone: 'Canada/Eastern',
    support_email: 'tn360support-au@teletracnavman.com',
    support_doc_link: 'https://learn.teletracnavman.net/Help/TN360/en-CA/Default.htm',
    iq_camera_support_doc_link:
      'https://learn.teletracnavman.net/Help/TN360/en-CA/Content/Hardware/IQ-Camera/IQ-Camera.htm',
    geocode: {
      lat: 50,
      lng: -90
    },
    altitude: {
      unit: 'm'
    },
    area: {
      unit: 'km²'
    },
    speed: {
      unit: 'km',
      unit_pluralize: 'km',
      unit_per_hour: 'km/h'
    },
    acceleration: {
      unit: UNITS.M_SQUARE
    },
    fuel: {
      unit: 'litre',
      economy: 'L/100km',
      rate: 'L/km',
      usage: 'L',
      default: 10,
      ...CO2_FUEL_LOCALE.CA
    },
    temperature: {
      unit: UNITS.TEMPERATURE_CELSIUS,
      engine_oil_over_temp: 122,
      engine_coolant_over_temp: 107
    },
    pressure: {
      oil: UNITS.PRESSURE_KPA
    },
    date: {
      ...baseDateConfig,
      formats: {
        default: 'YYYY-MM-DD'
      },
      order: ['year', 'month', 'day']
    },
    time: {
      pm: 'PM',
      am: 'AM',
      formats: {
        default: 'YYYY-MM-DD',
        dby_hm: 'YYYY-MM-DD HH:mm',
        dby_hms_z: 'YYYY-MM-DDTHH:mm:ssZ',
        dby_imp: 'YYYY MMM DD hh:mm A',
        dby_imsp: 'YYYY MMM DD hh:mm:ss A',
        dby_imsp_moment: 'YYYY MMM DD hh:mm:ss',
        dby: 'YYYY MMM DD',
        bd_hm: 'MMM DD, h:mm A',
        bdo_hm: 'MMM Do, h:mm A',
        bdo_hm_z: 'MMM DD, h:mm A (Z)',
        dby_hm_z: 'YYYY MMM DD HH:mm ZZ',
        dmY_imsp: 'YYYY/MM/DD hh:mm:ss A',
        dmY_imp: 'YYYY/MM/DD hh:mm A',
        dmY: 'YYYY/MM/DD',
        AdBY: 'dddd, YYYY MMMM DD',
        dMy: 'Y M D',
        Md: 'MMM DD'
      }
    },
    datetime: baseDatetimeConfig,
    currency: CURRENCY_SIGN.DOLLAR,
    description: 'All-in-one compliance and fleet reporting portal',
    author: 'Teletrac Navman',
    weight: {
      kg: {
        unit: WEIGHT_UNITS.KG,
        toKg: 0.453592,
        toLb: 2.20462
      },
      ton: {
        unit: WEIGHT_UNITS.TON,
        toTon: 0.907185,
        toImperialTon: 0.984205357094237,
        toUsTon: 1.10231
      }
    }
  },
  FR: {
    country: 'France',
    ...REGION_LOCALE.FR,
    default_time_zone: 'Europe/Paris',
    support_email: 'tn360support-au@teletracnavman.com',
    support_doc_link: 'https://learn.teletracnavman.net/Help/TN360/en-GB/Default.htm',
    iq_camera_support_doc_link:
      'https://learn.teletracnavman.net/Help/TN360/en-GB/Content/Hardware/IQ-Camera/IQ-Camera.htm',
    geocode: {
      lat: 46.227638,
      lng: 2.213749
    },
    altitude: {
      unit: 'm'
    },
    area: {
      unit: 'km²'
    },
    speed: {
      unit: 'km',
      unit_pluralize: 'km',
      unit_per_hour: 'km/h'
    },
    acceleration: {
      unit: UNITS.M_SQUARE
    },
    fuel: {
      unit: 'litre',
      economy: 'L/100km',
      rate: 'L/km',
      usage: 'L',
      default: 10,
      ...CO2_FUEL_LOCALE.ANZ
    },
    temperature: {
      unit: UNITS.TEMPERATURE_CELSIUS,
      engine_oil_over_temp: 122,
      engine_coolant_over_temp: 107
    },
    pressure: {
      oil: UNITS.PRESSURE_KPA
    },
    currency: CURRENCY_SIGN.EURO,
    date: baseDateConfig,
    time: baseTimeConfig,
    datetime: baseDatetimeConfig,
    description: 'All-in-one compliance and fleet reporting portal',
    author: 'Teletrac Navman',
    weight: {
      kg: {
        unit: WEIGHT_UNITS.KG,
        toKg: 0.453592,
        toLb: 2.20462
      },
      ton: {
        unit: WEIGHT_UNITS.TON,
        toTon: 0.907185,
        toImperialTon: 0.984205357094237,
        toUsTon: 1.10231
      }
    }
  },
  DK: {
    country: 'Denmark',
    ...REGION_LOCALE.DK,
    default_time_zone: 'Europe/Paris',
    support_email: 'tn360support-au@teletracnavman.com',
    support_doc_link: 'https://learn.teletracnavman.net/Help/TN360/en-GB/Default.htm',
    iq_camera_support_doc_link:
      'https://learn.teletracnavman.net/Help/TN360/en-GB/Content/Hardware/IQ-Camera/IQ-Camera.htm',
    geocode: {
      lat: 56.26392,
      lng: 9.501785
    },
    altitude: {
      unit: 'm'
    },
    area: {
      unit: 'km²'
    },
    speed: {
      unit: 'km',
      unit_pluralize: 'km',
      unit_per_hour: 'km/h'
    },
    acceleration: {
      unit: UNITS.M_SQUARE
    },
    fuel: {
      unit: 'litre',
      economy: 'L/100km',
      rate: 'L/km',
      usage: 'L',
      default: 10,
      ...CO2_FUEL_LOCALE.ANZ
    },
    temperature: {
      unit: UNITS.TEMPERATURE_CELSIUS,
      engine_oil_over_temp: 122,
      engine_coolant_over_temp: 107
    },
    pressure: {
      oil: UNITS.PRESSURE_KPA
    },
    currency: CURRENCY_SIGN.DANISH_KRONE,
    date: baseDateConfig,
    time: baseTimeConfig,
    datetime: baseDatetimeConfig,
    description: 'All-in-one compliance and fleet reporting portal',
    author: 'Teletrac Navman',
    weight: {
      kg: {
        unit: WEIGHT_UNITS.KG,
        toKg: 0.453592,
        toLb: 2.20462
      },
      ton: {
        unit: WEIGHT_UNITS.TON,
        toTon: 0.907185,
        toImperialTon: 0.984205357094237,
        toUsTon: 1.10231
      }
    }
  },
  CL: {
    country: 'Chile',
    ...REGION_LOCALE.CL,
    default_time_zone: 'America/Santiago',
    support_email: 'tn360support-au@teletracnavman.com',
    support_doc_link: 'https://learn.teletracnavman.net/Help/TN360/es-MX/Default.htm',
    iq_camera_support_doc_link:
      'https://learn.teletracnavman.net/Help/TN360/es-MX/Content/Hardware/IQ-Camera/IQ-Camera.htm',
    geocode: {
      lat: -36.739055,
      lng: -71.0574941
    },
    altitude: {
      unit: 'm'
    },
    area: {
      unit: 'km²'
    },
    speed: {
      unit: 'km',
      unit_pluralize: 'km',
      unit_per_hour: 'km/h'
    },
    acceleration: {
      unit: UNITS.M_SQUARE
    },
    fuel: {
      unit: 'litre',
      economy: 'L/100km',
      rate: 'L/km',
      usage: 'L',
      default: 10,
      ...CO2_FUEL_LOCALE.MX
    },
    temperature: {
      unit: UNITS.TEMPERATURE_CELSIUS,
      engine_oil_over_temp: 122,
      engine_coolant_over_temp: 107
    },
    pressure: {
      oil: UNITS.PRESSURE_KPA
    },
    currency: CURRENCY_SIGN.CHILEAN_PESO,
    date: baseDateConfig,
    time: baseTimeConfig,
    datetime: baseDatetimeConfig,
    description: 'All-in-one compliance and fleet reporting portal',
    author: 'Teletrac Navman',
    weight: {
      kg: {
        unit: WEIGHT_UNITS.KG,
        toKg: 0.453592,
        toLb: 2.20462
      },
      ton: {
        unit: WEIGHT_UNITS.TON,
        toTon: 0.907185,
        toImperialTon: 0.984205357094237,
        toUsTon: 1.10231
      }
    }
  }
};

export class LocalizationUtil {
  static miletometer(mile) {
    if (typeof mile !== 'number') {
      return 0;
    }

    return parseFloat((mile * 1609.344).toFixed(2));
  }

  static metertomile(meter) {
    if (typeof meter !== 'number') {
      return 0;
    }

    return parseFloat((meter / 1609.344).toFixed(5));
  }

  static kmtomile(kilometer, precision = 2) {
    if (typeof kilometer !== 'number') {
      return 0.0;
    }

    return parseFloat(((kilometer * 1000) / 1609.344).toFixed(precision));
  }

  static kmtokm(kilometer, precision = 2) {
    if (typeof kilometer !== 'number') {
      return 0.0;
    }

    return parseFloat(kilometer.toFixed(precision));
  }

  static miletokm(mile) {
    return parseFloat((LocalizationUtil.miletometer(mile) / 1000.0).toFixed(5));
  }

  static sqmtosqmile(area) {
    if (typeof area !== 'number') {
      return 0;
    }
    return parseFloat((area / 1609.344 / 1609.344).toFixed(5));
  }

  static sqmtosqkm(area) {
    if (typeof area !== 'number') {
      return 0;
    }
    return parseFloat((area / 1000.0 / 1000.0).toFixed(5));
  }

  static CtoF(temperature) {
    if (typeof temperature !== 'number') {
      return 0;
    }
    return parseFloat((temperature * 9) / 5 + 32).toFixed(1);
  }

  static FtoC(temperature) {
    if (typeof temperature !== 'number') {
      return 0;
    }
    return parseFloat((temperature - 32) * (5 / 9)).toFixed(1);
  }

  static CtoC(temperature) {
    if (typeof temperature !== 'number') {
      return 0;
    }
    return parseFloat(temperature).toFixed(1);
  }

  static litresToGallonsUS(fuel, precision = 1) {
    if (typeof fuel !== 'number' || fuel === 0) {
      return 0;
    }
    return getRoundValue(parseFloat(fuel / 3.785), precision);
  }

  static litresToGallons(fuel, precision = 1) {
    if (typeof fuel !== 'number' || fuel === 0) {
      return 0;
    }
    return getRoundValue(parseFloat(fuel / 4.546), precision);
  }

  static litresToLitres(fuel, precision = 1) {
    if (typeof fuel !== 'number' || fuel === 0) {
      return 0;
    }
    return getRoundValue(parseFloat(fuel), precision);
  }

  static mtoft(m, precision = 1) {
    if (typeof m !== 'number') {
      return 0;
    }
    return getRoundValue(parseFloat(m * 3.280839), precision);
  }

  static fttom(m, precision = 1) {
    if (typeof m !== 'number') {
      return 0;
    }
    return getRoundValue(parseFloat(m / 3.280839), precision);
  }

  static mtog(m, precision = 2) {
    if (typeof m !== 'number') {
      return 0;
    }
    return parseFloat((m / 9.80665).toFixed(precision));
  }

  static gtom(m, precision = 1) {
    if (typeof m !== 'number') {
      return 0;
    }
    return getRoundValue(parseFloat(m * 9.80665), precision);
  }

  static kmtokft(km, precision = 1) {
    if (typeof km !== 'number') {
      return 0;
    }
    return getRoundValue(parseFloat(km * 3.280839), precision);
  }

  static kmtoft(km, precision = 1) {
    if (typeof km !== 'number') {
      return 0;
    }
    return getRoundValue(parseFloat(km * 3.280839 * 1000), precision);
  }

  static kpaToPsi(pressure) {
    if (typeof pressure !== 'number') {
      return 0;
    }
    return parseFloat((pressure * 0.1450377377).toFixed(1));
  }

  static psiToKpa(pressure) {
    if (typeof pressure !== 'number') {
      return 0;
    }
    return parseFloat((pressure * 6.8947572932).toFixed(1));
  }
  static LiterPer100KMToMilePerGal(LiterPer100KM, toUSGal = true) {
    LiterPer100KM = Number(LiterPer100KM);
    if (isNaN(LiterPer100KM)) {
      return 0;
    }
    const RATE = toUSGal ? 235.215 : 282.481;
    let ret = RATE / LiterPer100KM;
    return isFinite(ret) && !isNaN(ret) ? ret : 0;
  }
  static MilePerGalToLiterPer100KM(MilePerGal, isUSGal = true) {
    MilePerGal = Number(MilePerGal);
    if (isNaN(MilePerGal)) {
      return 0;
    }
    const RATE = isUSGal ? 235.215 : 282.481;
    let ret = RATE / MilePerGal;
    return isFinite(ret) && !isNaN(ret) ? ret : 0;
  }
  static LiterPer100KMToKMPerLiter(LiterPer100KM) {
    LiterPer100KM = Number(LiterPer100KM);
    if (isNaN(LiterPer100KM)) {
      return 0;
    }
    let ret = 100 / LiterPer100KM;
    return isFinite(ret) && !isNaN(ret) ? ret : 0;
  }
  static KMPerLiterToLiterPer100KM(KMPerLiter) {
    KMPerLiter = Number(KMPerLiter);
    if (isNaN(KMPerLiter)) {
      return 0;
    }
    let ret = 100 / KMPerLiter;
    return isFinite(ret) && !isNaN(ret) ? ret : 0;
  }
  static KMPerLiterToMilePerGal(KMPerLiter, toUSGal = true) {
    KMPerLiter = Number(KMPerLiter);
    if (isNaN(KMPerLiter)) {
      return 0;
    }
    const RATE = toUSGal ? 2.352 : 2.825;
    return KMPerLiter * RATE;
  }
  static MilePerGalToKMPerLiter(MilePerGal, isUSGal = true) {
    MilePerGal = Number(MilePerGal);
    if (isNaN(MilePerGal)) {
      return 0;
    }
    const RATE = isUSGal ? 2.352 : 2.825;
    return MilePerGal / RATE;
  }
  static KGPerLiterToKGPerGal(KMPerLiter, toUSGal = true) {
    KMPerLiter = Number(KMPerLiter);
    if (isNaN(KMPerLiter)) {
      return 0;
    }
    const RATE = toUSGal ? 3.785 : 4.546;
    return KMPerLiter * RATE;
  }
  static KGPerGalToKGPerLiter(MilePerGal, isUSGal = true) {
    MilePerGal = Number(MilePerGal);
    if (isNaN(MilePerGal)) {
      return 0;
    }
    const RATE = isUSGal ? 3.785 : 4.546;
    return MilePerGal / RATE;
  }
  static MilePerHourPerSecToMeterPerSecSquare(mph_per_sec) {
    mph_per_sec = Number(mph_per_sec);
    if (isNaN(mph_per_sec)) {
      return 0;
    }
    const RATE = 0.44704;
    return parseFloat((mph_per_sec * RATE).toFixed(1));
  }
}

export const getPhoneNumberRegion = countryCode => {
  switch (countryCode) {
    case 'GB':
      return 'europe';
    default:
      return;
  }
};

export const getPhoneNumberCountries = countryCode => {
  switch (countryCode) {
    case 'US':
      return ['us', 'ca', 'mx'];
    case 'CA':
      return ['ca', 'us', 'mx'];
    case 'AU':
    case 'NZ':
      return ['au', 'nz'];
    case 'MX':
      return ['mx', 'us', 'ca'];
    case 'GB':
      return ['gb'];
    default:
      return;
  }
};

export const showPhoneNumberSearch = countryCode => {
  switch (countryCode) {
    case 'GB':
      return true;
    default:
      return false;
  }
};

export const getSuburbCityLabel = countryCode => {
  switch (countryCode) {
    case 'GB':
      return `${i18n.t('Locations.Form.CityTown')}`;
    case 'US':
    case 'MX':
    case 'CA':
      return `${i18n.t('Locations.Form.City')}`;
    default:
      return `${i18n.t('Locations.Form.Suburb')}`;
  }
};

export const getLicenceStateLabel = countryCode => {
  switch (countryCode) {
    case 'GB':
      return `${i18n.t('Users.ValidationErrors.IssuingAuthority')}`;
    default:
      return `${i18n.t('Users.ValidationErrors.LicenceState')}`;
  }
};

export const getLicenceStatePlaceholder = countryCode => {
  switch (countryCode) {
    case 'GB':
      return i18n.t('Users.EWDTab.SelectAnIssuingAuthority');
    default:
      return i18n.t('Users.EWDTab.SelectALicenceState');
  }
};

export const getRegistrationStateLabel = (countryCode, required) => {
  switch (countryCode) {
    case 'GB':
      return `${i18n.t(
        required
          ? 'ExportToExcel.RegistrationAuthorityIsRequired'
          : 'ExportToExcel.RegistrationAuthority'
      )}`;
    default:
      return `${i18n.t(
        required ? 'ExportToExcel.RegistrationStateIsRequired' : 'ExportToExcel.RegistrationState'
      )}`;
  }
};

export const getRegistrationTerritoryLabel = (countryCode, required) => {
  switch (countryCode) {
    case 'GB':
      return `${i18n.t(
        required ? 'ExportToExcel.RegistrationCountyIsRequired' : 'ExportToExcel.RegistrationCounty'
      )}`;
    case 'AU':
    case 'US':
      return `${i18n.t(
        required ? 'ExportToExcel.RegistrationStateIsRequired' : 'ExportToExcel.RegistrationState'
      )}`;
    default:
      return `${i18n.t(
        required
          ? 'ExportToExcel.RegistrationTerritoryIsRequired'
          : 'ExportToExcel.RegistrationTerritory'
      )}`;
  }
};

export const getRegistrationCountryLabel = (countryCode, required) => {
  switch (countryCode) {
    case 'GB':
      return `${i18n.t(
        required
          ? 'ExportToExcel.RegistrationCountryIsRequired'
          : 'ExportToExcel.RegistrationCountry'
      )}`;
    default:
      return `${i18n.t(
        required
          ? 'ExportToExcel.RegistrationCountryIsRequired'
          : 'ExportToExcel.RegistrationCountry'
      )}`;
  }
};

export const getLocalizedDate = countryCode => {
  switch (countryCode) {
    case 'US':
      return 'MMM DD, YYYY - hh:mm A';
    default:
      return 'DD MMM YYYY, hh:mm A';
  }
};
