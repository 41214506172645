import { Spin } from 'antd';
import React from 'react';
import styles from './LoadingSpinner.module.scss';

function combineClassNames(defaultClassName, customClassName) {
  if (!customClassName) {
    return defaultClassName;
  }

  return `${defaultClassName} ${customClassName}`;
}

export function LoadingSpinner(props) {
  const combinedClassName = combineClassNames(styles.loadingSpinner, props.className);

  return (
    <div className={combinedClassName}>
      <Spin />
    </div>
  );
}
