import moment from 'moment';
import * as d3 from 'd3/d3.min.js';
import './GanntChart.scss';
import { formatDate, MapChartStatusToAction } from 'containers/ELD/DriverLog/util';
import i18next from 'i18nextConfig';
import { DutyStatusDescription } from 'containers/ELD/util/dutystatus';
import { LANGUAGES } from 'features/localization/languages';

const DAY_MILLSECONDS = 24 * 60 * 60 * 1000;
const MIN_HOURS_TICK = [2, 3, 4, 6, 8, 12];

export class GanntChartTooltip {
  static ID = 0;
  static showTooltip({ text, html }) {
    if (d3.event) {
      d3.selectAll('.d3tooltip').remove();
      let tooltipId = d3.select(d3.event.currentTarget).attr('tooltipId');
      if (!tooltipId) {
        tooltipId = 'tooltip_' + ++this.ID;
        let tooltipNode = d3
          .select('body')
          .append('div')
          .attr('class', 'd3tooltip')
          .attr('id', tooltipId);
        if (text) {
          tooltipNode.text(text);
        } else if (html) {
          tooltipNode.html(html);
        }
        d3.select(d3.event.currentTarget).attr('tooltipId', tooltipId);
        const boundRect = d3
          .select(d3.event.currentTarget)
          .node()
          .getBoundingClientRect();
        const selfBoundRect = tooltipNode.node().getBoundingClientRect();
        let leftPos = boundRect.x + boundRect.width / 2 - selfBoundRect.width / 2;
        if (leftPos + selfBoundRect.width > window.screen.width) {
          leftPos = window.screen.width - selfBoundRect.width;
        }
        let topPos = boundRect.y - selfBoundRect.height - 8;
        tooltipNode.style('left', leftPos + 'px');
        tooltipNode.style('top', topPos + 'px');
      }
      d3.select('#' + tooltipId).style('visibility', 'visible');
    }
  }

  static hideTooltip() {
    if (d3.event) {
      let tooltipId = d3.select(d3.event.currentTarget).attr('tooltipId');
      if (tooltipId) {
        d3.select('#' + tooltipId).remove();
        d3.select(d3.event.currentTarget).attr('tooltipId', null);
      }
    }
  }
}
export class GanntChart {
  constructor({
    container,
    startPeriod = moment(),
    endPeriod = moment(),
    canvasSize = { x: 1000, y: 400 },
    titles = {},
    data = [],
    dataRender = {},
    dataTypeRenderOptions = {},
    patternDefs = [],
    colorScheme = {},
    axisOptions = {
      showVerticleLine: true,
      showHorizontalLine: true,
      showLeftAxis: true,
      showBottomAxis: true,
      showBottomTicks: false,
      showTopAxis: false,
      showRightAxis: false,
      leftAxis: {
        gutter: 2,
        padding: 20,
        catergories: []
      },
      paddingTop: 30,
      paddingBottom: 30
    },
    axisRender = {
      leftAxisRender: null,
      bottomAxisRender: null,
      topAxisRender: null,
      rightAxisRender: null
    }
  }) {
    this.container = container;
    this.startPeriod = moment(startPeriod).startOf('day');
    this.endPeriod = moment(endPeriod).endOf('day');
    this.canvasSize = canvasSize;
    this.titles = titles;
    this.data = data;
    this.dataRender = dataRender;
    this.dataTypeRenderOptions = dataTypeRenderOptions;
    this.colorScheme = colorScheme;
    this.axisRender = axisRender;
    this.axisOptions = axisOptions;
    this.axisOptions.startPeriod = this.startPeriod;
    this.axisOptions.endPeriod = this.endPeriod;

    this.canvas = d3
      .select(container)
      .append('svg')
      .attr('viewBox', [0, 0, canvasSize.x, canvasSize.y])
      .attr('xmlns:xhtml', 'http://www.w3.org/1999/xhtml');
    this.calculateLayout();
    if (patternDefs?.length > 0) {
      const defSel = this.canvas.append('defs');
      patternDefs.forEach(p => p(defSel));
    }
  }

  render() {
    this.renderAxes();
    this.renderData();
  }

  refresh({
    startPeriod,
    endPeriod,
    canvasSize,
    axisRender,
    axisOptions,
    data,
    dataRender,
    dataTypeRenderOptions
  }) {
    this.startPeriod = startPeriod;
    this.endPeriod = endPeriod;
    this.axisRender = axisRender;
    this.axisOptions = axisOptions;
    this.axisOptions.startPeriod = this.startPeriod;
    this.axisOptions.endPeriod = this.endPeriod;
    this.data = data;
    this.dataRender = dataRender;
    this.dataTypeRenderOptions = dataTypeRenderOptions;
    if (this.canvas) {
      if (canvasSize) {
        this.canvasSize = canvasSize;
        this.canvas.attr('viewBox', [0, 0, canvasSize.x, canvasSize.y]);
      }

      this.canvas.selectAll('g').remove();
      this.calculateLayout();
      this.render();
    }
  }

  calculateLayout() {
    const width = parseInt(this.canvas.property('viewBox').baseVal.width);
    const height = parseInt(this.canvas.property('viewBox').baseVal.height);
    if (
      this.axisOptions.showLeftAxis &&
      this.axisRender.leftAxisRender &&
      this.axisOptions.leftAxis?.catergories?.length > 0
    ) {
      let cellWidth = this.axisOptions.leftAxis.width;
      if (!cellWidth) {
        const maxLength = this.axisOptions.leftAxis.catergories.reduce((prev, cur) =>
          prev.length > cur.length ? prev : cur
        ).length;
        const fontSize = parseFloat(
          window.getComputedStyle(this.canvas.node()).getPropertyValue('font-size')
        );
        cellWidth = Math.min(
          200,
          fontSize * maxLength + (this.axisOptions.leftAxis.padding || 1) * 2
        );
      }
      this.axisOptions.originX = cellWidth;
      this.axisOptions.leftAxis.renderOptions = {
        cellWidth: cellWidth
      };
    } else {
      this.axisOptions.originX = 0;
    }

    if (this.axisOptions.showBottomAxis && this.axisRender.bottomAxisRender) {
      this.axisOptions.originY = height - (this.axisOptions?.paddingBottom || 0) - 21;
    } else {
      this.axisOptions.originY = height - (this.axisOptions?.paddingBottom || 0);
    }

    if (this.startPeriod && this.endPeriod) {
      const secondsDiff = (this.endPeriod - this.startPeriod) / 1000;
      this.axisOptions.widthPerSecond = (width - this.axisOptions.originX) / secondsDiff;
      if (this.axisOptions.widthPerSecond < 0) {
        this.axisOptions.widthPerSecond = 0;
      }
    }

    if (this.axisOptions.showLeftAxis && this.axisOptions.originX > 0) {
      const gutter = this.axisOptions.leftAxis?.gutter || 0;
      const areaHeight = this.axisOptions.originY - this.axisOptions.paddingTop;
      const cellHeight =
        (areaHeight - (this.axisOptions.leftAxis.catergories.length - 1) * gutter) /
        this.axisOptions.leftAxis.catergories.length;
      this.axisOptions.leftAxis.renderOptions = {
        ...this.axisOptions.leftAxis.renderOptions,
        cellHeight: cellHeight,
        gutter: gutter
      };
    }
  }

  renderAxes() {
    if (this.axisOptions.showLeftAxis && this.axisRender.leftAxisRender) {
      this.axisRender.leftAxisRender(
        this.canvas,
        this.axisOptions,
        this.startPeriod,
        this.endPeriod
      );
    }

    if (this.axisOptions.showTopAxis && this.axisRender.topAxisRender) {
      this.axisRender.topAxisRender(
        this.canvas,
        this.axisOptions,
        this.startPeriod,
        this.endPeriod
      );
    }

    if (this.axisOptions.showRightAxis && this.axisRender.rightAxisRender) {
      this.axisRender.rightAxisRender(
        this.canvas,
        this.axisOptions,
        this.startPeriod,
        this.endPeriod
      );
    }

    if (this.axisOptions.showBottomAxis && this.axisRender.bottomAxisRender) {
      this.axisRender.bottomAxisRender(
        this.canvas,
        this.axisOptions,
        this.startPeriod,
        this.endPeriod
      );
    }
  }

  renderData() {
    if (this.data) {
      const width =
        parseInt(this.canvas.property('viewBox').baseVal.width) - this.axisOptions.originX;
      const height = this.axisOptions.originY;
      const dataCanvas = this.canvas.append('g').attr('class', 'dataCanvas');
      this.data.forEach(d => {
        if (d.renderType && this.dataRender?.[d.renderType]) {
          if (!this.dataRender[d.renderType].render) {
            this.dataRender[d.renderType](
              dataCanvas,
              this.axisOptions,
              this.dataTypeRenderOptions,
              d,
              width,
              height
            );
          } else {
            this.dataRender[d.renderType].render(
              dataCanvas,
              this.axisOptions,
              this.dataTypeRenderOptions,
              d,
              width,
              height
            );
          }
        }
      });
    }
  }

  onDataAction(rowActions) {
    if (rowActions) {
      Object.keys(rowActions).forEach(id => {
        let manualEvent = null;
        if (rowActions[id] === 'mouseEnter') {
          manualEvent = new Event('mouseover');
        } else if (rowActions[id] === 'mouseLeave') {
          manualEvent = new Event('mouseout');
        }
        const sel = d3.select('#' + id);
        if (manualEvent && !sel.empty()) {
          sel.node().dispatchEvent(manualEvent);
          const existingCls = sel.attr('class') || '';
          if (rowActions[id] === 'mouseEnter') {
            sel.attr('class', existingCls + ' hover');
          } else if (rowActions[id] === 'mouseLeave') {
            sel.attr('class', existingCls.replace(' hover', '') || '');
          }
        }
      });
    }
  }
}

export function bottomAxisRender(canvas, axisOptions, startPeriod, endPeriod, tickFn) {
  let width = parseInt(canvas.property('viewBox').baseVal.width) - axisOptions.originX;
  let height = axisOptions.originY;
  let timeScale = d3.time
    .scale()
    .domain([startPeriod, endPeriod])
    .range([0, width]);
  let axis = d3.svg.axis().scale(timeScale);
  const timeDiff = endPeriod - startPeriod;
  let isSameDay = false;
  try {
    isSameDay = moment(startPeriod).isSame(moment(endPeriod), 'day');
  } catch (error) {
    isSameDay = false;
  }
  if (tickFn) {
    axis.ticks(timeScale.ticks(tickFn));
  } else {
    if (timeDiff <= DAY_MILLSECONDS || isSameDay) {
      axis.ticks(d3.time.hours, 1);
    } else {
      //divde the time diff to 24 time frames if possible
      if (
        timeDiff / DAY_MILLSECONDS / 24 >= 1 ||
        timeDiff / DAY_MILLSECONDS / MIN_HOURS_TICK[MIN_HOURS_TICK.length - 1] > 1
      ) {
        //tick based on days
        const days = Math.ceil(timeDiff / DAY_MILLSECONDS / 24);
        axis.ticks(d3.time.days, days);
      } else {
        //find the suitable min hours based on 24 time frames
        let hours = -1;
        for (let minHours of MIN_HOURS_TICK) {
          if (timeDiff / DAY_MILLSECONDS / minHours <= 24) {
            hours = minHours;
            break;
          }
        }
        axis.ticks(d3.time.hours, hours);
      }
    }
  }

  if (axisOptions.timeZone) {
    axis.tickFormat(date => {
      const dateformat = Intl.DateTimeFormat(LANGUAGES.EN, {
        timeZone: axisOptions.timeZone,
        dateStyle: 'short',
        timeStyle: 'short',
        hour12: true,
        hourCycle: 'h24'
      });
      const dateParts = dateformat.formatToParts(date);
      const month = dateParts.find(d => d.type === 'month');
      const day = dateParts.find(d => d.type === 'day');
      const hour = dateParts.find(d => d.type === 'hour');
      const minutes = dateParts.find(d => d.type === 'minute');
      const dayPeriod = dateParts.find(d => d.type === 'dayPeriod');
      if (hour.value === '12' && minutes.value === '00' && dayPeriod.value === 'AM') {
        return `${month.value}-${day.value}`;
      } else {
        const timeArray = [hour.value];
        if (minutes.value !== '00') {
          timeArray.push(minutes.value);
        }
        timeArray.push(dayPeriod.value);
        return timeArray.join(' ');
      }
    });
  }
  canvas
    .append('g')
    .attr('transform', `translate(${axisOptions.originX}, ${axisOptions.originY})`)
    .attr('class', 'bottomAxis')
    .call(axis);

  const stepLength = timeScale.ticks(axis.ticks()[0], axis.ticks()[1]).length;
  const step = width / stepLength;
  //draw verticle lines
  canvas
    .append('g')
    .attr('class', 'axisVerticalLine')
    .attr('transform', `translate(${axisOptions.originX}, 0)`)
    .selectAll('line')
    .data(d3.range(0, width + step, step))
    .enter()
    .append('line')
    .attr('x1', x => x)
    .attr('y1', axisOptions.paddingTop)
    .attr('x2', x => x)
    .attr('y2', height)
    .attr('stroke', 'black');

  if (axisOptions.showBottomTicks && (timeDiff <= DAY_MILLSECONDS || isSameDay)) {
    const diffHour = Math.floor((timeDiff + 1) / 3600000);
    const tickWidth = width / diffHour / 4;
    canvas
      .append('g')
      .attr('class', 'axisTicks')
      .attr('transform', `translate(${axisOptions.originX}, ${axisOptions.originY})`)
      .selectAll('line')
      .data(d3.range(0, diffHour * 3, 1))
      .enter()
      .append('line')
      .attr('x1', x => (x + 1 + parseInt(x / 3)) * tickWidth)
      .attr('y1', x => ((x - 1) % 3 === 0 ? -15 : -10))
      .attr('x2', x => (x + 1 + parseInt(x / 3)) * tickWidth)
      .attr('y2', 0);
  }
}

export function leftAxisRender(canvas, axisOptions) {
  let width = parseInt(canvas.property('viewBox').baseVal.width);
  if (axisOptions.leftAxis?.catergories?.length > 0) {
    const gutter = axisOptions.leftAxis.renderOptions.gutter;
    const cellWidth = axisOptions.leftAxis.renderOptions.cellWidth;
    const cellHeight = axisOptions.leftAxis.renderOptions.cellHeight;
    //const fontSize = canvas.node().getComputedStyleMap().get("font-size").value;

    const cellCanvas = canvas
      .append('g')
      .attr('class', 'axisLeft')
      .selectAll('g')
      .data(axisOptions.leftAxis.catergories)
      .enter();
    const cells = cellCanvas.append('g');
    cells
      .append('rect')
      .attr('x', 0)
      .attr('y', (d, i) => i * (cellHeight + gutter) + axisOptions.paddingTop)
      .attr('width', cellWidth)
      .attr('height', cellHeight);

    const txtNodes = cells
      .append('text')
      .attr('x', cellWidth / 2)
      .attr('y', (d, i) => i * (cellHeight + gutter) + cellHeight / 2 + axisOptions.paddingTop)
      .attr('dy', '-0.5em')
      .text(d => i18next.t('ELD.' + d));

    txtNodes[0].forEach(node => {
      const n = d3.select(node);
      n.attr('y', parseFloat(n.attr('y')) + n.node().getBoundingClientRect().height / 2 - 4);
    });

    const totalAmountNodes = cells
      .append('text')
      .attr('class', 'totalAmount')
      .attr('x', cellWidth / 2)
      .attr('y', (d, i) => i * (cellHeight + gutter) + cellHeight / 2 + axisOptions.paddingTop)
      .attr('dy', '0.5em')
      .text(d => axisOptions.leftAxis.totalAmount[MapChartStatusToAction[d]]);

    totalAmountNodes[0].forEach(node => {
      const n = d3.select(node);
      n.attr('y', parseFloat(n.attr('y')) + n.node().getBoundingClientRect().height / 2 - 4);
    });

    if (axisOptions.showHorizontalLine) {
      canvas
        .append('g')
        .attr('class', 'axisLeftHorizontalLine')
        .selectAll('line')
        .data(d3.range(0, axisOptions.leftAxis.catergories.length))
        .enter()
        .append('line')
        .attr('x1', cellWidth)
        .attr(
          'y1',
          (d, i) => i * cellHeight + (Math.max(i, 1) - 0.5) * gutter + axisOptions.paddingTop
        )
        .attr('x2', width)
        .attr(
          'y2',
          (d, i) => i * cellHeight + (Math.max(i, 1) - 0.5) * gutter + axisOptions.paddingTop
        );
    }
  }
}

export function horizontalBarRender(
  canvas,
  axisOptions,
  dataTypeRenderOptions,
  data,
  width,
  height
) {
  const settings = dataTypeRenderOptions?.[data.renderType]?.(axisOptions, data, width, height);
  const rectSelection = canvas.append('g').append('rect');
  Object.keys(settings).forEach(a =>
    a.startsWith('on')
      ? rectSelection.on(a.substring(2), settings[a])
      : rectSelection.attr(a, settings[a])
  );
}

export const MapActionToChartStatus = {
  Driving: 'Driving',
  OnDuty: 'On Duty',
  OffDuty: 'Off Duty',
  OffDutyAtWell: 'Off Duty',
  SleeperBerth: 'Sleeper Berth',
  PersonalConveyance: 'Off Duty',
  YardMove: 'On Duty',
  AdverseConditions: 'Driving',
  ExemptionAreaExited: 'Driving',
  ZoneChange: 'Zone Change',
  CycleChange: 'Cycle Change',
  OffDutyDeferral: 'Off Duty Deferral',
  IntermediateLog: 'Intermediate Log'
};

export const DutyStatus = {
  Driving: 'Driving',
  OnDuty: 'On Duty',
  OffDuty: 'Off Duty',
  OffDutyAtWell: 'Off Duty At Well',
  SleeperBerth: 'Sleeper Berth',
  PersonalConveyance: 'Personal Conveyance',
  YardMove: 'Yard Move',
  AdverseConditions: 'Adverse Conditions',
  ExemptionAreaExited: 'Exemption Area Exited',
  ZoneChange: 'Zone Change',
  CycleChange: 'Cycle Change',
  OffDutyDeferral: 'Off Duty Deferral',
  IntermediateLog: 'Intermediate Log',
  LogonDriver: 'Logon Driver',
  LogoffDriver: 'Logoff Driver'
};

export function eventRenderOptionGenerator(axisOptions, data) {
  const goldenRatio = 0.618;
  const settings = {
    id: 'event_' + data.id,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    class: 0,
    onclick: null,
    onmouseover: null,
    onmouseout: null
  };
  settings.x =
    ((data.startPeriod - axisOptions.startPeriod) / 1000) * axisOptions.widthPerSecond +
    axisOptions.originX;
  settings.width = ((data.endPeriod - data.startPeriod) / 1000) * axisOptions.widthPerSecond;
  settings.y =
    axisOptions.leftAxis.catergories.indexOf(MapActionToChartStatus[data.action]) *
      (axisOptions.leftAxis.renderOptions.cellHeight + axisOptions.leftAxis.renderOptions.gutter) +
    (axisOptions.leftAxis.renderOptions.cellHeight * (1 - goldenRatio)) / 2 +
    axisOptions.paddingTop;
  settings.height = axisOptions.leftAxis.renderOptions.cellHeight * goldenRatio;
  settings.onmouseover = () => {
    GanntChartTooltip.showTooltip({
      html:
        `<p>${DutyStatusDescription[DutyStatus[data.actionText]]}</p>` +
        (data.duration ? `<p>${data.duration}</p>` : '') +
        `<p><i>${i18next.t('ELD.Start')}</i> ${formatDate(
          data.isPrevEvent ? data.originStartPeriod : data.startPeriod,
          data.dateFormat,
          data.timeZone
        )}</p>` +
        (data.isPredict || data.onlyPrevEvent
          ? ''
          : `<p><i>${i18next.t('ELD.End')}</i> ${formatDate(
              data.endPeriod,
              data.dateFormat,
              data.timeZone
            )}</p>`)
    });
  };
  settings.onmouseout = () => {
    GanntChartTooltip.hideTooltip();
  };

  switch (data.action) {
    case 'Driving':
    case 'AdverseConditions':
      settings.class = 'drivingBarchart';
      break;
    case 'OnDuty':
      settings.class = 'workingBarchart';
      break;
    case 'YardMove':
      settings.class = 'yardMovesBarchart';
      break;
    case 'PersonalConveyance':
      settings.class = 'personalConveyanceBarchart';
      break;
    case 'OffDuty':
      settings.class = 'offBarchart';
      break;
    case 'SleeperBerth':
      settings.class = 'sleepBarchart';
      break;
    default:
      break;
  }

  if (!data.isPrevEvent && data.isPredict) {
    settings.class = 'predictBarchart';
    settings.fill = 'url(#predictStripe)';
  }

  if (data.isPrevEvent) {
    settings.class += ' prevEvent';
  }

  //TN360ELD-2719: suggested edit will be the same style for previous day.
  if (!data.isPredict && data.status === 'P') {
    settings.class = 'pendingBarchart';
  }
  return settings;
}

export function flagRender(
  canvas,
  axisOptions,
  dataTypeRenderOptions,
  data,
  width,
  height,
  config = {}
) {
  const flagConfig = config.flag || {};
  const flagText = flagConfig.title || i18next.t('ELD.Checkpoint');
  const flagTextAbbr = flagConfig.titleAbbr || 'C';
  const flagTextWidth = flagConfig.sizeforFullText || DefaultFlagSize.sizeforFullText;
  let hasOverlappedFlag = false;
  let overlappedFlagY = 0;
  let overlappedFlagWidth = 0;
  let overlappedFlagX = 0;
  const flagNodes = canvas.selectAll('g.flagChart');
  if (flagNodes[0].length > 0) {
    hasOverlappedFlag = true;
    const lastFlag = d3.select(flagNodes[flagNodes.length - 1][0]);
    overlappedFlagX = Number(lastFlag.select('.flagChart.head.rect').attr('x'));
    overlappedFlagY = Number(lastFlag.select('.flagChart.head.rect').attr('y'));
    overlappedFlagWidth = Number(lastFlag.select('.flagChart.head.rect').attr('width'));
  }
  const settings = dataTypeRenderOptions?.[data.renderType]?.(axisOptions, data, width, height, {
    hasOverlappedFlag,
    overlappedFlagX,
    overlappedFlagY,
    overlappedFlagWidth
  });
  const checkpointGraph = canvas.append('g');
  Object.keys(settings).forEach(a => {
    if (['head', 'body', 'foot'].indexOf(a) >= 0) {
      let graph = null;
      switch (a) {
        case 'head':
          graph = checkpointGraph.append('g');
          graph.on('mouseover.tooltip', settings[a].onmouseover);
          graph.on('mouseout.tooltip', settings[a].onmouseout);

          const rectGraph = graph.append('rect');
          const headId = settings[a]?.id;
          if (headId) {
            graph.attr('id', headId);
          }
          const textGraph = graph.append('text').text(flagTextAbbr);
          let subsettings = settings[a].text;
          Object.keys(subsettings).forEach(sa =>
            sa.startsWith('on')
              ? textGraph.on(sa.substring(2), subsettings[sa])
              : textGraph.attr(sa, subsettings[sa])
          );

          subsettings = settings[a].rect;
          Object.keys(subsettings).forEach(sa =>
            sa.startsWith('on')
              ? rectGraph.on(sa.substring(2), subsettings[sa])
              : rectGraph.attr(sa, subsettings[sa])
          );

          graph.on('mouseover.flag', () => {
            rectGraph.attr('width', `${flagTextWidth}`);
            textGraph.style('text-anchor', 'start');
            textGraph.text(flagText);
          });

          graph.on('mouseout.flag', () => {
            let subsettings = settings[a].text;
            Object.keys(subsettings)
              .filter(sa => !sa.startsWith('on'))
              .forEach(sa => textGraph.attr(sa, subsettings[sa]));

            subsettings = settings[a].rect;
            Object.keys(subsettings)
              .filter(sa => !sa.startsWith('on'))
              .forEach(sa => rectGraph.attr(sa, subsettings[sa]));
            textGraph.text(flagTextAbbr);
            textGraph.style('text-anchor', '');
          });
          return;
        case 'body':
          graph = checkpointGraph.append('line');
          break;
        case 'foot':
          graph = checkpointGraph.append('circle');
          break;
        default:
          break;
      }
      if (graph) {
        const subsettings = settings[a];
        Object.keys(subsettings).forEach(sa =>
          sa.startsWith('on')
            ? graph.on(sa.substring(2), subsettings[sa])
            : graph.attr(sa, subsettings[sa])
        );
      }
    } else {
      a.startsWith('on')
        ? checkpointGraph.on(a.substring(2), settings[a])
        : checkpointGraph.attr(a, settings[a]);
    }
  });
}

const FlagTooltipTemplate = {
  default(data, { tooltipTitle, tooltipContent, tooltipExtra }) {
    return (
      `<p>${tooltipTitle || i18next.t('ELD.Latest checkpoint')}</p>` +
      (tooltipContent || '') +
      '<p>' +
      formatDate(data.startPeriod, data.dateFormat, data.timeZone) +
      '</p>' +
      (tooltipExtra || '')
    );
  },
  period(data, { tooltipTitle, from, to, tooltipExtra }) {
    const tooltipContent = `<p><i>${from.label}</i> ${from.value} </p><p><i>${to.label}</i> ${to.value} </p>`;
    return this.default(data, { tooltipTitle, tooltipContent, tooltipExtra });
  },
  current(data, { tooltipTitle, to, tooltipExtra }) {
    const tooltipContent = `<p><i>${to.label}</i> ${to.value} </p>`;
    return this.default(data, { tooltipTitle, tooltipContent, tooltipExtra });
  }
};

const DefaultFlagSize = { sizeforAbbrText: 20, sizeforFullText: 80 };

export function flagRenderOptionGenerator(
  axisOptions,
  data,
  canvasWidth,
  canvasHeight,
  config = {}
) {
  const tooltipConfig = config.tooltip || {};
  const settings = {
    class: 'flagChart',
    head: {
      onmouseover: () => {
        GanntChartTooltip.showTooltip({
          html: FlagTooltipTemplate[tooltipConfig.tooltipTempl || 'default'](data, tooltipConfig)
        });
      },
      onmouseout: () => {
        GanntChartTooltip.hideTooltip();
      },
      rect: {
        class: 'flagChart head rect',
        x: 0,
        y: 0,
        width: config.flag?.sizeforAbbrText || DefaultFlagSize.sizeforAbbrText,
        height: 16
      },
      text: {
        class: 'flagChart head text',
        x: 0,
        y: 0,
        dy: '4px'
      },
      ...(config.id ? { id: `flag_${config.id}` } : {})
    },
    body: {
      class: 'flagChart body',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 16
    },
    foot: {
      class: 'flagChart foot',
      cx: 0,
      cy: 0,
      r: 3
    }
  };

  const startPoint =
    ((data.startPeriod - axisOptions.startPeriod) / 1000) * axisOptions.widthPerSecond +
    axisOptions.originX;
  settings.head.rect.x = settings.body.x1 = settings.body.x2 = settings.foot.cx = startPoint;
  settings.body.y2 = settings.foot.cy = axisOptions.originY;
  settings.head.text.x = settings.head.rect.x + settings.head.rect.width / 2;
  settings.head.text.y = settings.head.rect.y + settings.head.rect.height / 2;
  if (config?.hasOverlappedFlag) {
    if (
      settings.head.rect.x >= config.overlappedFlagX &&
      settings.head.rect.x <= config.overlappedFlagX + config.overlappedFlagWidth
    ) {
      if (config.overlappedFlagY === 0) {
        settings.head.rect.y += 16;
        settings.head.text.y += 16;
        settings.body.y1 += 16;
      }
    }
  }
  return settings;
}

export class ViolationRender {
  static renderProps = {
    marginTop: 20,
    triangleSideLength: 10
  };

  static generateRenderOptions(axisOptions, data, canvasWidth, canvasHeight) {
    const settings = {
      class: 'violationChart',
      violationArea: {
        id: data.id,
        class: 'violationChart area',
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        onmouseover: () => {
          if (
            d3
              .select(d3.event.target)
              .attr('class')
              .indexOf('active') < 0
          ) {
            d3.select(d3.event.target).attr('class', 'violationChart area active');
          }
          d3.select(d3.event.target.parentNode)
            .select('.violationDateRange')
            .style('display', 'inherit');
          GanntChartTooltip.showTooltip({
            html:
              '<p>' +
              data.rule +
              '</p>' +
              `<p><i>${i18next.t('ELD.Start')}</i> ${formatDate(
                data.startPeriod,
                data.dateFormat,
                data.timeZone
              )} </p>` +
              `<p><i>${i18next.t('ELD.End')}</i> ${formatDate(
                data.endPeriod,
                data.dateFormat,
                data.timeZone
              )} </p>`
          });
        },
        onmouseout: () => {
          if (
            d3
              .select(d3.event.target)
              .attr('class')
              .indexOf('active') >= 0
          ) {
            d3.select(d3.event.target).attr('class', 'violationChart area');
          }
          d3.select(d3.event.target.parentNode)
            .select('.violationDateRange')
            .style('display', 'none');
          GanntChartTooltip.hideTooltip();
        }
      },
      leftTriangle: {
        class: 'violationChart leftTriangle',
        points: ''
      },
      leftDateText: {
        class: 'violationChart leftDateText',
        date: '',
        x: 0,
        y: 0
      },
      connectionLine: {
        class: 'violationChart connectionLine',
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
      },
      rightTriangle: {
        class: 'violationChart rightTriangle',
        points: ''
      },
      rightDateText: {
        class: 'violationChart rightDateText',
        date: '',
        x: 0,
        y: 0
      }
    };

    const areaStartPoint =
      Math.max(0, (data.startPeriod - axisOptions.startPeriod) / 1000) *
        axisOptions.widthPerSecond +
      axisOptions.originX;
    const areaEndPoint =
      (Math.min(
        axisOptions.endPeriod - axisOptions.startPeriod,
        data.endPeriod - axisOptions.startPeriod
      ) /
        1000) *
        axisOptions.widthPerSecond +
      axisOptions.originX;
    settings.violationArea.x = areaStartPoint;
    settings.violationArea.width = areaEndPoint - areaStartPoint;
    settings.violationArea.y = axisOptions.paddingTop;
    settings.violationArea.height = axisOptions.originY - axisOptions.paddingTop;

    const dateRangeStartPoint =
      Math.max(0, (data.dateRangeStartPeriod - axisOptions.startPeriod) / 1000) *
        axisOptions.widthPerSecond +
      axisOptions.originX;
    const dateRangeEndPoint =
      (Math.min(
        axisOptions.endPeriod - axisOptions.startPeriod,
        data.dateRangeEndPeriod - axisOptions.startPeriod
      ) /
        1000) *
        axisOptions.widthPerSecond +
      axisOptions.originX;

    if (data.dateRangeStartPeriod >= axisOptions.startPeriod) {
      settings.leftTriangle.points = [
        [dateRangeStartPoint, axisOptions.originY + this.renderProps.marginTop],
        [
          dateRangeStartPoint,
          axisOptions.originY + this.renderProps.marginTop + this.renderProps.triangleSideLength
        ],
        [
          dateRangeStartPoint + this.renderProps.triangleSideLength,
          axisOptions.originY + this.renderProps.marginTop + this.renderProps.triangleSideLength
        ]
      ].join(' ');
    }

    settings.leftDateText.date = formatDate(
      data.dateRangeStartPeriod,
      data.dateFormat,
      data.timeZone
    );
    settings.leftDateText.x = dateRangeStartPoint;
    settings.leftDateText.y =
      axisOptions.originY + this.renderProps.marginTop + this.renderProps.triangleSideLength;
    settings.leftDateText.dy = '1em';

    settings.connectionLine.x1 =
      data.dateRangeStartPeriod < axisOptions.startPeriod
        ? dateRangeStartPoint
        : dateRangeStartPoint + this.renderProps.triangleSideLength - 1;
    settings.connectionLine.y1 = settings.connectionLine.y2 =
      axisOptions.originY + this.renderProps.marginTop + this.renderProps.triangleSideLength - 1;
    settings.connectionLine.x2 =
      data.dateRangeEndPeriod > axisOptions.endPeriod
        ? dateRangeEndPoint
        : dateRangeEndPoint - this.renderProps.triangleSideLength + 1;

    if (data.dateRangeEndPeriod <= axisOptions.endPeriod) {
      settings.rightTriangle.points = [
        [dateRangeEndPoint, axisOptions.originY + this.renderProps.marginTop],
        [
          dateRangeEndPoint,
          axisOptions.originY + this.renderProps.marginTop + this.renderProps.triangleSideLength
        ],
        [
          dateRangeEndPoint - this.renderProps.triangleSideLength,
          axisOptions.originY + this.renderProps.marginTop + this.renderProps.triangleSideLength
        ]
      ].join(' ');
    }

    settings.rightDateText.date = formatDate(
      data.dateRangeEndPeriod,
      data.dateFormat,
      data.timeZone
    );
    settings.rightDateText.x = dateRangeEndPoint;
    settings.rightDateText.y =
      axisOptions.originY + this.renderProps.marginTop + this.renderProps.triangleSideLength;
    settings.rightDateText.dy = '1em';

    if (
      Math.abs(settings.leftDateText.x - settings.rightDateText.x) <=
      settings.leftDateText.date.length * 12
    ) {
      settings.leftDateText.class += ' endAnchor';
      settings.rightDateText.class += ' startAnchor';
    }
    return settings;
  }

  static render(canvas, axisOptions, dataTypeRenderOptions, data, width, height) {
    const settings = this.generateRenderOptions(axisOptions, data, width, height);
    const violationGraph = canvas.append('g');
    const violationDateRangeGraph = violationGraph
      .insert('g', ':first-child')
      .attr('class', 'violationDateRange');

    Object.keys(settings).forEach(a => {
      if (
        [
          'violationArea',
          'leftTriangle',
          'leftDateText',
          'connectionLine',
          'rightTriangle',
          'rightDateText'
        ].indexOf(a) >= 0
      ) {
        let graph = null;
        const subsettings = settings[a];
        switch (a) {
          case 'violationArea':
            graph = violationGraph.append('rect');
            break;
          case 'leftTriangle':
          case 'rightTriangle':
            graph = violationDateRangeGraph.append('polygon');
            break;
          case 'leftDateText':
          case 'rightDateText':
            graph = violationDateRangeGraph.append('text');
            graph.text(subsettings.date);
            break;
          case 'connectionLine':
            graph = violationDateRangeGraph.append('line');
            break;
          default:
            break;
        }
        if (graph) {
          Object.keys(subsettings).forEach(sa =>
            sa.startsWith('on')
              ? graph.on(sa.substring(2), subsettings[sa])
              : graph.attr(sa, subsettings[sa])
          );
        }
      } else {
        a.startsWith('on')
          ? violationGraph.on(a.substring(2), settings[a])
          : violationGraph.attr(a, settings[a]);
      }
    });
  }
}

export class TimezoneRender {
  static generateRenderOptions(axisOptions, data, width, height) {
    const settings = {
      text: {
        text: data.timezone,
        x: 0,
        y: height + 2,
        width: 160,
        height: 20
      },
      rect: {
        x: 0,
        y: height + 1,
        width: 160,
        height: 20,
        rx: 12
      }
    };
    return settings;
  }

  static render(canvas, axisOptions, dataTypeRenderOptions, data, width, height) {
    const settings = this.generateRenderOptions(axisOptions, data, width, height);
    const timezoneGraph = canvas.append('g').attr('class', 'timezoneGraph');

    const rectGraph = timezoneGraph.append('rect');
    const rectSettings = settings['rect'];
    Object.keys(rectSettings).forEach(s =>
      s.startsWith('on')
        ? rectGraph.on(s.substring(2), rectSettings[s])
        : rectGraph.attr(s, rectSettings[s])
    );

    const textSettings = settings['text'];
    let textNode = timezoneGraph.append('foreignObject');
    Object.keys(textSettings).forEach(s => {
      if (s === 'text') {
        textNode.append('xhtml:div').text(textSettings[s]);
      } else {
        s.startsWith('on')
          ? textNode.on(s.substring(2), textSettings[s])
          : textNode.attr(s, textSettings[s]);
      }
    });
  }
}

export function predictStripePattern(defSel) {
  defSel
    .append('pattern')
    .attr({
      id: 'predictStripe',
      patternUnits: 'userSpaceOnUse',
      width: 4,
      height: 4,
      patternTransform: 'rotate(45)'
    })
    .append('rect')
    .attr({ width: 2, height: 4, class: 'predictStripe' });
}
