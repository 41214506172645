/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Attachment from '../model/Attachment';
import Device from '../model/Device';
import DeviceHealth from '../model/DeviceHealth';
import DeviceModel from '../model/DeviceModel';
import DeviceStats from '../model/DeviceStats';
import DeviceType from '../model/DeviceType';
import Gps from '../model/Gps';

/**
* Devices service.
* @module api/DevicesApi
* @version 1.0
*/
export default class DevicesApi {

    /**
    * Constructs a new DevicesApi. 
    * @alias module:api/DevicesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the deviceStats operation.
     * @callback module:api/DevicesApi~deviceStatsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/DeviceStats>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieve a list of device stats updated
     * A token key is required for this call.
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {String} opts.embed What needs to be included in response, options: users, runsheets
     * @param {String} opts.lastUpdated from datetime, yyyy-MM-ddTHH:mm:ss. default to year 2010
     * @param {module:model/String} opts.pruning  (default to 'APP')
     * @param {module:api/DevicesApi~deviceStatsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/DeviceStats>}
     */
    deviceStats(key, opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'embed': opts['embed'],
        'last_updated': opts['lastUpdated'],
        'pruning': opts['pruning'],
        'key': key,
        'company_id': opts['companyId'],
        'direction':opts['direction']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [DeviceStats];
      return this.apiClient.callApi(
        '/devices/stats', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the disableDevice operation.
     * @callback module:api/DevicesApi~disableDeviceCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Disable a device
     * A token key is required for this call.
     * @param {String} id 
     * @param {String} key API key for a company
     * @param {module:api/DevicesApi~disableDeviceCallback} callback The callback function, accepting three arguments: error, data, response
     */
    disableDevice(id, key, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling disableDevice");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling disableDevice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/devices/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the geofenceProximity operation.
     * @callback module:api/DevicesApi~geofenceProximityCallback
     * @param {String} error Error message, if any.
     * @param {Array.<Object>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add a new device
     * A token key is required for this call.
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/DevicesApi~geofenceProximityCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<Object>}
     */
    geofenceProximity(key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling geofenceProximity");
      }

      let pathParams = {
      };
      let queryParams = {
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [Object];
      return this.apiClient.callApi(
        '/devices/proximity', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getDevice operation.
     * @callback module:api/DevicesApi~getDeviceCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Device} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Look up device by ID or IMEI
     * A token key is required for this call.
     * @param {String} imei 
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {String} opts.embed What needs to be included in response, supports vehicles, devicestats, runsheets (default to 'devicestats')
     * @param {module:model/String} opts.pruning  (default to 'LIST')
     * @param {module:api/DevicesApi~getDeviceCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Device}
     */
    getDevice(imei, key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'imei' is set
      if (imei === undefined || imei === null) {
        throw new Error("Missing the required parameter 'imei' when calling getDevice");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getDevice");
      }

      let pathParams = {
        'imei': imei
      };
      let queryParams = {
        'embed': opts['embed'],
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Device;
      return this.apiClient.callApi(
        '/devices/{imei}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getDeviceModels operation.
     * @callback module:api/DevicesApi~getDeviceModelsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/DeviceModel>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * A token key is required for this call.
     * @param {String} key API key for user/company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'APP')
     * @param {module:api/DevicesApi~getDeviceModelsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/DeviceModel>}
     */
    getDeviceModels(key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getDeviceModels");
      }

      let pathParams = {
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [DeviceModel];
      return this.apiClient.callApi(
        '/devices/models', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getDeviceTypes operation.
     * @callback module:api/DevicesApi~getDeviceTypesCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/DeviceType>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * A token key is required for this call.
     * @param {String} key API key for user/company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'APP')
     * @param {module:api/DevicesApi~getDeviceTypesCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/DeviceType>}
     */
    getDeviceTypes(key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getDeviceTypes");
      }

      let pathParams = {
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [DeviceType];
      return this.apiClient.callApi(
        '/devices/types', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getHealth operation.
     * @callback module:api/DevicesApi~getHealthCallback
     * @param {String} error Error message, if any.
     * @param {module:model/DeviceHealth} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Return health info by ID or IMEI
     * A token key is required for this call.
     * @param {String} imei 
     * @param {String} key API key for user/company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'ALL')
     * @param {module:api/DevicesApi~getHealthCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/DeviceHealth}
     */
    getHealth(imei, key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'imei' is set
      if (imei === undefined || imei === null) {
        throw new Error("Missing the required parameter 'imei' when calling getHealth");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getHealth");
      }

      let pathParams = {
        'imei': imei
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = DeviceHealth;
      return this.apiClient.callApi(
        '/devices/{imei}/health', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPosRec operation.
     * @callback module:api/DevicesApi~getPosRecCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Gps>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * A token key is required for this call.
     * @param {Number} id 
     * @param {String} key API key for user/company
     * @param {Object} opts Optional parameters
     * @param {String} opts.from from datetime, yyyy-MM-ddTHH:mm:ss. default to 6 hours
     * @param {String} opts.to to datetime, yyyy-MM-ddTHH:mm:ss, default to now
     * @param {Number} opts.limit max number of records, default to 100
     * @param {module:model/String} opts.pruning  (default to 'ALL')
     * @param {module:api/DevicesApi~getPosRecCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Gps>}
     */
    getPosRec(id, key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getPosRec");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getPosRec");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'from': opts['from'],
        'to': opts['to'],
        'limit': opts['limit'],
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Gps];
      return this.apiClient.callApi(
        '/devices/{id}/pr', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getSnapshots operation.
     * @callback module:api/DevicesApi~getSnapshotsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Attachment>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get the latest snapshots attachments of a device in a given month. A token key is required for this call.
     * @param {String} id 
     * @param {String} key API key for user/company
     * @param {Object} opts Optional parameters
     * @param {Number} opts.month The month between 1 and 12 of current year.
     * @param {module:model/String} opts.pruning  (default to 'APP')
     * @param {module:api/DevicesApi~getSnapshotsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Attachment>}
     */
    getSnapshots(id, key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getSnapshots");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getSnapshots");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'year': opts['year'],
        'month': opts['month'],
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Attachment];
      return this.apiClient.callApi(
        '/devices/{id}/snapshots', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the insertDevice operation.
     * @callback module:api/DevicesApi~insertDeviceCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Device} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add a new device
     * A token key is required for this call.
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {module:model/Device} opts.body 
     * @param {module:api/DevicesApi~insertDeviceCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Device}
     */
    insertDevice(key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling insertDevice");
      }

      let pathParams = {
      };
      let queryParams = {
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Device;
      return this.apiClient.callApi(
        '/devices', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the list operation.
     * @callback module:api/DevicesApi~listCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Device>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Find a list of devices
     * A token key is required for this call. All devices in the company hierarchy (down direction only) are included. Response is enriched to include device stats, device model and type when applicable.
     * @param {String} key API key for a company
     * @param {module:api/DevicesApi~listCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Device>}
     */
    list(key, callback) {
      let postBody = null;
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling list");
      }

      let pathParams = {
      };
      let queryParams = {
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Device];
      return this.apiClient.callApi(
        '/devices', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the mdmHelicopter operation.
     * @callback module:api/DevicesApi~mdmHelicopterCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Turn on helicopter follow mode
     * A token key is required for this call.
     * @param {String} id 
     * @param {String} key API key for user/company
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/DevicesApi~mdmHelicopterCallback} callback The callback function, accepting three arguments: error, data, response
     */
    mdmHelicopter(id, key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling mdmHelicopter");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling mdmHelicopter");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/devices/{id}/mdm/helicopter', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the mergeDevice operation.
     * @callback module:api/DevicesApi~mergeDeviceCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/Device} opts.body 
     * @param {module:api/DevicesApi~mergeDeviceCallback} callback The callback function, accepting three arguments: error, data, response
     */
    mergeDevice(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/devices/merge', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the registerDevice operation.
     * @callback module:api/DevicesApi~registerDeviceCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Device} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Register a new device
     * A registration token key is mandatory in the payload JSON.
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/DevicesApi~registerDeviceCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Device}
     */
    registerDevice(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Device;
      return this.apiClient.callApi(
        '/devices/register', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the replaceDevice operation.
     * @callback module:api/DevicesApi~replaceDeviceCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Replace existing device with new device
     * A token key is required for this call. The agreements for the old device will be removed
     * @param {String} id 
     * @param {String} key API key for user/company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'ALL')
     * @param {String} opts.body 
     * @param {module:api/DevicesApi~replaceDeviceCallback} callback The callback function, accepting three arguments: error, data, response
     */
    replaceDevice(id, key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling replaceDevice");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling replaceDevice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/devices/{id}/replace', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateDevice operation.
     * @callback module:api/DevicesApi~updateDeviceCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a device
     * A token key is required for this call.
     * @param {String} id 
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/DevicesApi~updateDeviceCallback} callback The callback function, accepting three arguments: error, data, response
     */
    updateDevice(id, key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateDevice");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling updateDevice");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/devices/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
