/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Location from "./Location";
import Role from "./Role";
import UserRuleset from "./UserRuleset";
import UserType from "./UserType";

/**
 * The User model module.
 * @module model/User
 * @version 1.0
 */
class User {
  /**
   * Constructs a new <code>User</code>.
   * @alias module:model/User
   */
  constructor() {
    User.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>User</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/User} obj Optional instance to populate.
   * @return {module:model/User} The populated <code>User</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new User();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("email")) {
        obj["email"] = ApiClient.convertToType(data["email"], "String");
      }
      if (data.hasOwnProperty("encryptedPassword")) {
        obj["encryptedPassword"] = ApiClient.convertToType(
          data["encryptedPassword"],
          "String"
        );
      }
      if (data.hasOwnProperty("resetPasswordToken")) {
        obj["resetPasswordToken"] = ApiClient.convertToType(
          data["resetPasswordToken"],
          "String"
        );
      }
      if (data.hasOwnProperty("companyId")) {
        obj["companyId"] = ApiClient.convertToType(data["companyId"], "Number");
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = UserType.constructFromObject(data["type"]);
      }
      if (data.hasOwnProperty("firstName")) {
        obj["firstName"] = ApiClient.convertToType(data["firstName"], "String");
      }
      if (data.hasOwnProperty("lastName")) {
        obj["lastName"] = ApiClient.convertToType(data["lastName"], "String");
      }
      if (data.hasOwnProperty("username")) {
        obj["username"] = ApiClient.convertToType(data["username"], "String");
      }
      if (data.hasOwnProperty("mobile")) {
        obj["mobile"] = ApiClient.convertToType(data["mobile"], "String");
      }
      if (data.hasOwnProperty("path")) {
        obj["path"] = ApiClient.convertToType(data["path"], "String");
      }
      if (data.hasOwnProperty("docId")) {
        obj["docId"] = ApiClient.convertToType(data["docId"], "String");
      }
      if (data.hasOwnProperty("version")) {
        obj["version"] = ApiClient.convertToType(data["version"], "Number");
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("siteAdmin")) {
        obj["siteAdmin"] = ApiClient.convertToType(
          data["siteAdmin"],
          "Boolean"
        );
      }
      if (data.hasOwnProperty("timeZone")) {
        obj["timeZone"] = ApiClient.convertToType(data["timeZone"], "String");
      }
      if (data.hasOwnProperty("externalId")) {
        obj["externalId"] = ApiClient.convertToType(
          data["externalId"],
          "String"
        );
      }
      if (data.hasOwnProperty("licenceState")) {
        obj["licenceState"] = ApiClient.convertToType(
          data["licenceState"],
          "String"
        );
      }
      if (data.hasOwnProperty("licenceNumber")) {
        obj["licenceNumber"] = ApiClient.convertToType(
          data["licenceNumber"],
          "String"
        );
      }
      if (data.hasOwnProperty("location")) {
        obj["location"] = Location.constructFromObject(data["location"]);
      }
      if (data.hasOwnProperty("externalPin")) {
        obj["externalPin"] = ApiClient.convertToType(
          data["externalPin"],
          "String"
        );
      }
      if (data.hasOwnProperty("rulesets")) {
        obj["rulesets"] = ApiClient.convertToType(data["rulesets"], [
          UserRuleset
        ]);
      }
      if (data.hasOwnProperty("roles")) {
        obj["roles"] = ApiClient.convertToType(data["roles"], [Role]);
      }
      if (data.hasOwnProperty("externalReference")) {
        obj["externalReference"] = ApiClient.convertToType(
          data["externalReference"],
          "String"
        );
      }
      if (data.hasOwnProperty("callable")) {
        obj["callable"] = ApiClient.convertToType(data["callable"], "Boolean");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
User.prototype["id"] = undefined;

/**
 * @member {String} email
 */
User.prototype["email"] = undefined;

/**
 * @member {String} encryptedPassword
 */
User.prototype["encryptedPassword"] = undefined;

/**
 * @member {String} resetPasswordToken
 */
User.prototype["resetPasswordToken"] = undefined;

/**
 * @member {Number} companyId
 */
User.prototype["companyId"] = undefined;

/**
 * @member {module:model/UserType} type
 */
User.prototype["type"] = undefined;

/**
 * @member {String} firstName
 */
User.prototype["firstName"] = undefined;

/**
 * @member {String} lastName
 */
User.prototype["lastName"] = undefined;

/**
 * @member {String} username
 */
User.prototype["username"] = undefined;

/**
 * @member {String} mobile
 */
User.prototype["mobile"] = undefined;

/**
 * @member {String} path
 */
User.prototype["path"] = undefined;

/**
 * @member {String} docId
 */
User.prototype["docId"] = undefined;

/**
 * @member {Number} version
 */
User.prototype["version"] = undefined;

/**
 * @member {module:model/User.StatusEnum} status
 */
User.prototype["status"] = undefined;

/**
 * @member {Boolean} siteAdmin
 */
User.prototype["siteAdmin"] = undefined;

/**
 * @member {String} timeZone
 */
User.prototype["timeZone"] = undefined;

/**
 * @member {String} externalId
 */
User.prototype["externalId"] = undefined;

/**
 * @member {String} licenceState
 */
User.prototype["licenceState"] = undefined;

/**
 * @member {String} licenceNumber
 */
User.prototype["licenceNumber"] = undefined;

/**
 * @member {module:model/Location} location
 */
User.prototype["location"] = undefined;

/**
 * @member {String} externalPin
 */
User.prototype["externalPin"] = undefined;

/**
 * @member {Array.<module:model/UserRuleset>} rulesets
 */
User.prototype["rulesets"] = undefined;

/**
 * @member {Array.<module:model/Role>} roles
 */
User.prototype["roles"] = undefined;

/**
 * @member {String} externalReference
 */
User.prototype["externalReference"] = undefined;

/**
 * @member {Boolean} callable
 */
User.prototype["callable"] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
User["StatusEnum"] = {
  /**
   * value: "ENABLED"
   * @const
   */
  ENABLED: "ENABLED",

  /**
   * value: "DISABLED"
   * @const
   */
  DISABLED: "DISABLED",

  /**
   * value: "READY_FOR_DOWNLOAD"
   * @const
   */
  READY_FOR_DOWNLOAD: "READY_FOR_DOWNLOAD",

  /**
   * value: "PENDING"
   * @const
   */
  PENDING: "PENDING",

  /**
   * value: "AVAILABLE"
   * @const
   */
  AVAILABLE: "AVAILABLE",

  /**
   * value: "UNAVAILABLE"
   * @const
   */
  UNAVAILABLE: "UNAVAILABLE",

  /**
   * value: "FAILED"
   * @const
   */
  FAILED: "FAILED"
};

export default User;
