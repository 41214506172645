import React from 'react';
import { Link } from 'react-router-dom';

import {
  ColumnKeys,
  WIFI_TABLE_COLUMNS,
  DELETE_PARAM_TO_MODAL,
  ACTION_MENU_PLACEMENT,
  DATA_TYPE_MESSAGE_FOR_AM,
  DATA_TYPE_FOR_AM,
  DEFAULT_STATUS
} from './constants';
import { ActionMenu } from 'components/actionMenu';

import { deleteWifi } from 'features/wifi';
import { PATHS } from '../utils/constants';

//For grid page

export const filteredWifisForTable = (
  wifis = [],
  filters = { searchText: '', companyIds: [], fleetIds: [] }
) => {
  const { searchText, companyIds, fleetIds } = filters;

  return wifis.filter(wifi => {
    let isValid = true;

    // Filter by search field
    if (searchText) {
      isValid = isValid && (wifi.name || '').toLowerCase().includes(searchText.toLocaleLowerCase());
    }
    // Filter by companies
    isValid = isValid && companyIds.includes(wifi.company.id);
    // Filter by fleets
    const wifiFleets = fleetIds.includes(-1) && !wifi.fleetIds ? [-1] : wifi.fleetIds;
    if (!fleetIds.includes(0)) {
      isValid = isValid && fleetIds.find(id => wifiFleets?.find(fleetId => fleetId === id));
    }
    return isValid;
  });
};

export const filterFleetsBySelectedCompanies = (
  fleets = [],
  filterCompanies = [],
  formatListForMultiselect = () => {},
  translate = () => {}
) => {
  const subCompaniesIdsFromFilter = filterCompanies
    .filter(comp => comp.checked)
    .map(comp => comp.id);
  const filteredFleets = fleets
    .filter(
      fleet => fleet.id && fleet.company && subCompaniesIdsFromFilter.includes(fleet.company.id)
    )
    .map(fleet => {
      return {
        name: fleet.name,
        checked: true,
        id: fleet.id
      };
    });
  const formattedFleets = formatListForMultiselect([
    { id: 0, name: `${translate('Common.AllFleets')}` },
    ...filteredFleets,
    { id: -1, name: `${translate('Common.NoFleets')}` }
  ]);
  return formattedFleets;
};

export const setTitleForDropdownsFilters = (
  filteredArray = [],
  initialArray = [],
  trueOption = '',
  falseOption = ''
) => {
  return filteredArray.filter(comp => comp.checked).length === initialArray.length + 1
    ? trueOption
    : falseOption;
};

export const prepareWiFisDataForTable = (
  wifis = [],
  companies = [],
  fleets = [],
  translate = () => {},
  can = () => {},
  initialFleetObjectArray = [],
  entities = []
) => {
  return wifis.map(wifi => {
    const company = companies.find(comp => comp.id === wifi.company.id);
    const companyAssoc = companies.find(c => (wifi.companyIds || []).includes(c.id));
    const wifiFleets = fleets.filter(fleet => wifi?.fleetIds?.includes(fleet.id));
    return {
      [ColumnKeys.ID]: wifi.id,
      [ColumnKeys.NAME]: can({ everyEntity: [entities.WIFI_VIEW] }) ? (
        <Link to={`/settings/wifi/id/${wifi.id}`}>{wifi[ColumnKeys.NAME]}</Link>
      ) : (
        <span>{wifi[ColumnKeys.NAME]}</span>
      ),
      [ColumnKeys.COMPANY_NAME]: company?.name || '',
      [ColumnKeys.ASSOCIATIONS]: renderAssociations(
        companyAssoc,
        wifiFleets,
        translate,
        companies,
        initialFleetObjectArray
      ),
      [ColumnKeys.ACTIONS]: (
        <ActionMenu
          data={{ ...wifi, status: DEFAULT_STATUS }}
          paths={PATHS}
          dataType={DATA_TYPE_FOR_AM}
          dataTypeMessages={DATA_TYPE_MESSAGE_FOR_AM}
          deleteAction={deleteWifi}
          placement={ACTION_MENU_PLACEMENT}
          modalType={DELETE_PARAM_TO_MODAL}
          typeOfEntityToDelete={translate('Common.wifi')}
        />
      )
    };
  });
};

function renderAssociations(company, wifiFleets, translate, companies, initialFleetObjectArray) {
  return (
    <div>
      {!!company && (
        <div style={{ paddingBottom: !!wifiFleets.length ? '10px' : 0 }}>
          <div>{`${translate('PreTripChecklist.Table.Company')}:`}</div>
          <div>{company?.name}</div>
        </div>
      )}
      {!!wifiFleets.length && (
        <div>
          <div>{`${translate('Forms.Table.Fleets')}:`}</div>
          {wifiFleets.map(fleet => {
            const initialFleetObject = initialFleetObjectArray.find(
              fleetObj => fleet.label === fleetObj.name
            );
            const companyFleet = companies.find(
              company => company.id === initialFleetObject?.company?.id
            );
            return (
              <div key={fleet.id}>
                {fleet.label}
                {companyFleet?.name ? ` (${companyFleet.name})` : ''}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export const prepareColumnsForTable = translate =>
  WIFI_TABLE_COLUMNS.map(column => ({
    ...column,
    title: translate(`WiFi.GridPage.Tabel.Columns.${column.key}`)
  }));

// For view page

export const renderAssociationsForViewPage = (
  companyAssoc,
  fleetsAssoc,
  translate,
  styles,
  companies
) => (
  <div className={styles.associationsContainer}>
    {companyAssoc && (
      <div className={styles.companyAssociationContainer}>
        <span className={styles.companyAssociationLabel}>{`${translate(
          'WiFi.ViewPage.Company'
        )}:`}</span>
        <span>{companyAssoc?.name}</span>
      </div>
    )}
    <div>
      <span>{`${translate('PreTripChecklist.Fleets')}:`}</span>
      <ul className={styles.fleetsList}>
        {fleetsAssoc?.map((fleet, index) => {
          const companyFleet = companies.find(company => company.id === fleet?.company?.id);
          return (
            <li key={fleet.id} className={styles.fleetsListItem}>{`${fleet.name}${
              companyFleet?.name ? ` (${companyFleet.name})` : ''
            }${index !== fleetsAssoc.length - 1 ? ',' : ''}`}</li>
          );
        })}
      </ul>
    </div>
  </div>
);

//For form page

export const setFormFleets = args => {
  const { fleets, subCompanies, currentCompany, state, t } = args;

  const formFleets = fleets
    .filter(fleet => fleet.id)
    .map(fleet => {
      const companyFleet = subCompanies.find(company => company?.id === fleet?.company?.id);
      return {
        value: fleet.id,
        label: `${fleet.name} (${companyFleet?.name || ''})`,
        checked: state?.data?.fleetIds?.includes(fleet.id)
      };
    });

  formFleets.push({
    value: -1,
    label: t('Common.NoFleet'),
    checked: false
  });

  formFleets.unshift(
    {
      label: `${t('PreTripChecklist.Form.Company')}`
    },
    {
      value: `c-${currentCompany.id}`,
      label: currentCompany.name,
      checked: state?.data?.companyIds?.includes(currentCompany.id)
    },
    {
      label: `${t('PreTripChecklist.Form.Fleet')}`
    }
  );

  return formFleets;
};

export const setAssociations = values => {
  return values?.filter(valueObj => valueObj.checked)?.map(valueObj => valueObj.value);
};
