import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Modal, Button, Alert, Row, Col, Typography } from 'antd';
import { isEqual, orderBy } from 'lodash';

import { DropBox } from './DropBox';
import { DropBoxType } from './DropBoxType';
import { BUTTON_IDS } from 'utils/globalConstants';

import styles from './ManageColumns.module.scss';

export const ManageColumnsModal = ({
  show,
  columns,
  isSaving,
  onSave = visibleColKeys => visibleColKeys,
  onCancel
}) => {
  const { t } = useTranslation();

  const [items, setItems] = useState({ [DropBoxType.Visible]: [], [DropBoxType.Hidden]: [] });
  const { visibleColKeys, isPristine } = useMemo(() => {
    const visibleColKeys = items[DropBoxType.Visible].map(item => item.id);
    return {
      visibleColKeys,
      isPristine: isEqual(
        orderBy(columns?.filter(item => item.visible) || [], ['order']).map(item => item.id),
        visibleColKeys
      )
    };
  }, [columns, items]);

  useEffect(() => {
    setItems({
      [DropBoxType.Visible]: orderBy(columns?.filter(item => item.visible) || [], ['order']),
      [DropBoxType.Hidden]: orderBy(columns?.filter(item => !item.visible) || [], ['order'])
    });
  }, [columns]);

  const findItem = useCallback(
    (itemType, itemId) => {
      const item = items[itemType]?.find(item => item?.id === itemId);
      return {
        item,
        index: items[itemType]?.indexOf(item)
      };
    },
    [items]
  );

  const handleReset = useCallback(
    () =>
      setItems({
        [DropBoxType.Visible]: orderBy(columns?.filter(item => item.visible) || [], ['order']),
        [DropBoxType.Hidden]: orderBy(columns?.filter(item => !item.visible) || [], ['order'])
      }),
    [columns]
  );

  const handleSave = useCallback(() => onSave(visibleColKeys.filter(key => !!key)), [
    onSave,
    visibleColKeys
  ]);

  return (
    <>
      <Modal
        title={t('SmartJobs.manageColumns')}
        open={show}
        width={900}
        onCancel={onCancel}
        className={`${styles.userManageColumnsModal} user-manage-columns-modal`}
        footer={
          <div className={styles.modalFooter}>
            <Button
              type="primary"
              onClick={handleSave}
              id={BUTTON_IDS.userManageColumnsModalSave}
              disabled={isPristine}
              loading={isSaving}
              className={styles.submit}
            >
              {t('Common.Save')}
            </Button>
            <Button
              type="secondary"
              onClick={handleReset}
              id={BUTTON_IDS.userManageColumnsModalReset}
              disabled={isPristine}
              className={styles.reset}
            >
              {t(`Common.Reset`)}
            </Button>
            <Button
              type="secondary"
              onClick={onCancel}
              id={BUTTON_IDS.userManageColumnsModalCancel}
            >
              {t(`Common.Cancel`)}
            </Button>
          </div>
        }
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Alert message={t('SmartJobs.ManageColumnsDescription')} type="info" />
          </Col>
          <Col span={24}>
            <DndProvider backend={HTML5Backend}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Typography.Title level={5}>
                        {t('SmartJobs.ColumnList.HiddenColumns')}
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <DropBox
                        type={DropBoxType.Hidden}
                        items={items[DropBoxType.Hidden]}
                        setItems={setItems}
                        findItem={findItem}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <Typography.Title level={5}>
                        {t('SmartJobs.ColumnList.VisibleColumns')}
                      </Typography.Title>
                    </Col>
                    <Col span={24}>
                      <DropBox
                        type={DropBoxType.Visible}
                        items={items[DropBoxType.Visible]}
                        setItems={setItems}
                        findItem={findItem}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </DndProvider>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
