import React, { useEffect, useState } from 'react';
import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TreeSelect } from 'antd';

import { useVehicleTypes } from 'features/vehicles/vehicleTypesSlice';
import { useSubCompanies } from 'features/company/companySlice';

const { SHOW_CHILD } = TreeSelect;

export const CompanyVehicleTypeTreeSelect = ({ onChange }) => {
  const { t } = useTranslation();
  const vehicleTypes = useVehicleTypes();
  const subCompanies = useSubCompanies();

  const [selectVehicleTypes, setSelectVehicleTypes] = useState();

  const getCompanyVehicleTypes = vehicleTypes => {
    let types = groupBy(vehicleTypes, 'companyId');
    return types;
  };

  const getSubCompanyMap = subCompanies => {
    let companyMap = subCompanies.reduce((map, obj) => {
      map[obj.id] = obj;
      return map;
    }, {});
    return companyMap;
  };

  useEffect(() => {
    if (!vehicleTypes?.length || !subCompanies) {
      return;
    }

    const companyVehicleTypes = getCompanyVehicleTypes(vehicleTypes);
    const subCompanyMap = getSubCompanyMap(subCompanies);
    let selVehicleTypes = [];

    Object.keys(companyVehicleTypes).forEach(companyId => {
      selVehicleTypes.push({
        title: subCompanyMap[companyId].name,
        value: companyId.toString(),
        children: companyVehicleTypes[companyId].map(vehicleType => ({
          ...vehicleType,
          value: [companyId, vehicleType.id].join('.'),
          title: vehicleType.name
        }))
      });
    });
    setSelectVehicleTypes(selVehicleTypes);
  }, [vehicleTypes, subCompanies]);

  return (
    <TreeSelect
      placeholder={t('Vehicle Type')}
      treeData={selectVehicleTypes}
      size={'large'}
      onChange={onChange}
      treeCheckable={true}
      showCheckedStrategy={SHOW_CHILD}
    />
  );
};
