import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Map, { MapMode } from 'components/map/Map';
import styles from './MapModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const MapModal = props => {
  return (
    <Modal {...props} size="lg" aria-labelledby="map-modal-container" centered>
      <Modal.Header closeButton>
        <Modal.Title id="map-modal-title">{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.mapContainer}>
          <Map
            mode={MapMode.Location}
            zoom={4}
            location={props.coordinates}
            containerElement={<div style={{ height: `100%`, width: `100%` }} />}
            mapElement={<div style={{ height: `100%`, width: `100%` }} />}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button id={BUTTON_IDS.mapModalFooterClose} onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
