import { api } from 'utils/api';
import i18n from 'i18next';
import { asyncThunk } from 'utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  FETCH_SCHEDULE_BY_ID,
  FETCH_SCHEDULES,
  FETCH_DELETED_SCHEDULES,
  ADD_SCHEDULE,
  UPDATE_SCHEDULE,
  COMPLETE_SCHEDULE,
  DELETE_SCHEDULE,
  RESTORE_SCHEDULE,
  CANCEL_SCHEDULE,
  FETCH_TYPES,
  FETCH_DELETEDTYPES,
  ADD_TYPE,
  UPDATE_TYPE,
  DELETE_TYPE,
  RESTORE_TYPE
} from './actions';
import {
  getSchedulesQuery,
  getDeletedSchedulesQuery,
  getTypesQuery,
  getDeletedTypesQuery
} from '../../containers/Configuration/DriverMgtTypes/constants';
import { parseErrorMessage } from 'utils/strings';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

const TYPES_URL = '/types/drivermanagement';
const SCHEDULES_URL = '/schedules/drivermanagement';

export const fetchSchedules = asyncThunk(FETCH_SCHEDULES, SCHEDULES_URL);
export const fetchDeletedSchedules = asyncThunk(FETCH_DELETED_SCHEDULES, SCHEDULES_URL);
export const fetchScheduleById = asyncThunk(FETCH_SCHEDULE_BY_ID, SCHEDULES_URL);
export const addSchedule = asyncThunk(ADD_SCHEDULE, SCHEDULES_URL, 'post', () =>
  fetchSchedules(getSchedulesQuery)
);
export const updateSchedule = asyncThunk(UPDATE_SCHEDULE, SCHEDULES_URL, 'put', () =>
  fetchSchedules(getSchedulesQuery)
);
export const completeSchedule = asyncThunk(
  COMPLETE_SCHEDULE,
  SCHEDULES_URL.concat('/complete'),
  'put',
  () => fetchSchedules(getSchedulesQuery)
);

export const cancelSchedule = asyncThunk(
  CANCEL_SCHEDULE,
  SCHEDULES_URL.concat('/cancel'),
  'put',
  () => fetchSchedules(getSchedulesQuery)
);

export const fetchTypes = asyncThunk(FETCH_TYPES, TYPES_URL);
export const fetchDeletedTypes = asyncThunk(FETCH_DELETEDTYPES, TYPES_URL);
export const addType = asyncThunk(ADD_TYPE, TYPES_URL, 'post', () => fetchTypes(getTypesQuery));
export const updateType = asyncThunk(UPDATE_TYPE, TYPES_URL, 'put', () =>
  fetchTypes(getTypesQuery)
);

export const deleteType = createAsyncThunk(
  DELETE_TYPE,
  async (payload = {}, { getState, dispatch, rejectWithValue }) => {
    const authKey = getState()?.user?.current?.auth?.key;
    const { id } = payload;
    try {
      const response = await api.delete(`${TYPES_URL}/${id}`, {
        authKey
      });

      // update types
      dispatch(fetchTypes(getTypesQuery));
      dispatch(fetchDeletedTypes(getDeletedTypesQuery));

      return response.body;
    } catch (err) {
      return rejectWithValue(err.response?.body);
    }
  }
);

export const restoreType = createAsyncThunk(
  RESTORE_TYPE,
  async (payload = {}, { getState, dispatch, rejectWithValue }) => {
    const authKey = getState()?.user?.current?.auth?.key;
    const { id, body } = payload;

    try {
      const response = await api.put(
        `${TYPES_URL}/${id}/restore`,
        {
          authKey
        },
        body
      );

      // update types
      dispatch(fetchTypes(getTypesQuery));
      dispatch(fetchDeletedTypes(getDeletedTypesQuery));

      return response?.body;
    } catch (err) {
      return rejectWithValue(err.response?.body);
    }
  }
);

export const deleteSchedule = createAsyncThunk(
  DELETE_SCHEDULE,
  async (payload = {}, { getState, dispatch }) => {
    const authKey = getState()?.user?.current?.auth?.key;
    const { data, history } = payload;
    try {
      const response = await api.delete(`${SCHEDULES_URL}/${data.id}`, {
        authKey
      });

      if (response?.ok) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('DriverManagement.deleteSuccess', { name: data?.name })
          })
        );
        dispatch(fetchSchedules(getSchedulesQuery));
        if (history) {
          history.push('/drivermanagement');
        }
      }
      return response.body;
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${i18n.t(`DriverManagement.deleteError`, {
            name: data?.name
          })} ${parseErrorMessage(err)}`
        })
      );
    }
  }
);

export const restoreSchedule = createAsyncThunk(
  RESTORE_SCHEDULE,
  async (payload = {}, { getState, dispatch }) => {
    const authKey = getState()?.user?.current?.auth?.key;
    const { data, refetchById } = payload;

    try {
      const response = await api.put(`${SCHEDULES_URL}/restore/${data.id}`, { authKey });
      if (response?.ok) {
        dispatch(fetchSchedules(getSchedulesQuery));
        dispatch(fetchDeletedSchedules(getDeletedSchedulesQuery));
        if (refetchById) {
          dispatch(fetchScheduleById({ id: data.id, query: { embed: 'events' } }));
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('DriverManagement.restoreSuccess', { name: data?.name })
          })
        );
      }

      return response?.body;
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${i18n.t(`DriverManagement.restoreError`, {
            name: data?.name
          })} ${parseErrorMessage(err)}`
        })
      );
    }
  }
);
