import { useDispatch, useSelector } from 'react-redux';
import { fetchInspection, fetchInspections } from './thunks';
import { inspectionsSelectors } from './';
import { GROUP_BY } from 'containers/VehicleMaintenance/Inspections/utils/constants';

export const useInspections = ({ dateFrom, dateTo, delayFetch = false }) => {
  const dispatch = useDispatch();
  const inspections = useSelector(inspectionsSelectors.getInspections);

  const isFetching = useSelector(inspectionsSelectors.isFetching);
  const isSuccess = useSelector(inspectionsSelectors.isSuccess);
  const isError = useSelector(inspectionsSelectors.isError);
  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete && !delayFetch) {
    dispatch(fetchInspections({ query: { from: dateFrom, to: dateTo } }));
  }

  return inspections || [];
};

export const useInspection = inspectionId => {
  const dispatch = useDispatch();
  const inspection = useSelector(inspectionsSelectors.getInspection);

  const isFetching = useSelector(inspectionsSelectors.isInspectionFetching);
  const isSuccess = useSelector(inspectionsSelectors.isInspectionSuccess);
  const isError = useSelector(inspectionsSelectors.isInspectionError);
  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete) {
    dispatch(fetchInspection({ id: inspectionId }));
  }

  return inspection || {};
};

export const useGroupedInspections = (type = GROUP_BY.inspections) => {
  const groupedByInspections = useSelector(inspectionsSelectors.groupByInspections);
  const gropupedByVehicles = useSelector(inspectionsSelectors.groupByVehicles);

  return type === GROUP_BY.inspections ? groupedByInspections : gropupedByVehicles || [];
};

export const useIsInspectionsFetching = () => useSelector(inspectionsSelectors.isFetching);
export const useIsInspectionFetching = () => useSelector(inspectionsSelectors.isInspectionFetching);

export const useFilters = () => useSelector(inspectionsSelectors.filters);
