import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import InfoRow from 'components/form/info-row/InfoRow';
import FormTitle from 'components/form/form-title/FormTitle';

import { openToast } from 'features/toasts/toastsSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { getIDFromPathname } from 'utils/methods';
import { parseErrorMessage } from 'utils/strings';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';
import { deleteType, restoreType, useIsTypesFetchingFinished } from 'features/driverManagement';

import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { Paths } from './constants';
import { ToastType } from 'components/notifications/toasts/Toast';

import styles from './DriverMgtTypes.module.scss';
import { DeletedEntityAlert, DeletableEntityType } from 'features/common/deletedEntityAlert';
import { useHistory } from 'react-router';

export const DriverMgtTypesView = ({ types, subCompanies }) => {
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const dispatch = useDispatch();
  const localization = useLocalization();
  const history = useHistory();
  const isFetchingDone = useIsTypesFetchingFinished();
  const [deleteAction, setDeleteAction] = useState(null);

  const handleError = useCallback(
    err => {
      if (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err
          })
        );
      }
      if (history.location.pathname !== Paths.MAIN_PAGE) {
        history.replace(Paths.MAIN_PAGE);
      }
    },
    [history, dispatch]
  );

  const typeData = types.find(type => type.id === parseInt(id, 10));
  const company = subCompanies.find(company => company.id === typeData?.companyId);

  useEffect(() => {
    if (!deleteAction && (id <= 0 || isNaN(id) || (isFetchingDone && !typeData))) {
      handleError(t('Common.Invalid Request ID'));
    }
  }, [t, id, handleError, typeData, isFetchingDone, deleteAction]);
  //methods on load

  const handleButtonAction = action => async () => {
    if (action === 'delete') {
      setDeleteAction(true);
    }
    const response = await dispatch(
      action === 'delete' ? deleteType({ id: typeData.id }) : restoreType({ id: typeData.id })
    );
    if (response.error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(`DriverMgtTypes.${action}Error`, {
            name: typeData?.name
          })} ${parseErrorMessage(response?.payload)}`
        })
      );
    } else {
      // success
      dispatch(
        openToast({
          type: ToastType.Success,
          message: `${t(`DriverMgtTypes.${action}Success`)} ${typeData?.name}!`
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setPageTitle(typeData?.name));
    dispatch(setBackButton(true));
  }, [typeData?.name, dispatch]);

  return (
    <>
      <ViewHeaderWrapper
        data={{ ...typeData, entityName: AUDIT_ENTITY.DRIVER_MANAGEMENT_TYPES }}
        editPath={`${Paths.EDIT_DRIVERMANAGEMENTTYPE}/${id}`}
        auditPath={`/settings/driverMgtTypes/audit/id/${id}`}
        canUse="DRIVERMANAGEMENTTYPE"
        handleButtonAction={handleButtonAction}
        typeOfEntityToDelete={t('Common.driverType')}
      />
      <div className={styles.container}>
        {
          <DeletedEntityAlert
            entity={DeletableEntityType.DriverMgtType}
            entityStatus={typeData?.status}
            style={{ marginTop: '16px' }}
          />
        }
        <FormTitle title={t('DriverMgtTypes.Form.Details')} underlined />
        <InfoRow
          label={t('DriverMgtTypes.Form.Name')}
          value={typeData?.name || '-'}
          styles={styles}
        />
        <InfoRow label={t('Common.Company')} value={company?.name || '-'} styles={styles} />
        <InfoRow
          label={t('DriverMgtTypes.Form.Description')}
          value={typeData?.description || '-'}
          styles={styles}
        />
        <InfoRow
          label={t('Common.CreatedAt')}
          value={
            (typeData?.createdAt &&
              format(new Date(typeData?.createdAt), localization.formats.time.formats.dby_imp)) ||
            ''
          }
          styles={styles}
        />
        <InfoRow
          label={t('Common.UpdatedAt')}
          value={
            (typeData?.updatedAt &&
              format(new Date(typeData?.updatedAt), localization.formats.time.formats.dby_imp)) ||
            ''
          }
          styles={styles}
        />
      </div>
    </>
  );
};
