import React, { useState } from 'react';
import { Table, Modal } from 'antd';
import styles from 'components/tn/grid/grid.module.scss';

export const ColumnSelectorModal = ({
  title,
  isOpen = false,
  columns,
  data,
  selectedColumns,
  handleOk,
  handleCancel
}) => {
  const [selectedColumnsInternal, setSelectedColumnsInternal] = useState(selectedColumns);
  const { selectedRowKeys, selectedRows } = selectedColumns;
  const onSelectChange = (record, selected) => {
    setSelectedColumnsInternal({ selectedRowKeys: record, selectedRows: selected });
  };

  const rowSelection = {
    selectedRows,
    onChange: onSelectChange
  };

  return (
    <>
      <Modal
        title={title}
        open={isOpen}
        centered={true}
        closable={true}
        width={500}
        onCancel={() => {
          handleCancel();
        }}
        onOk={() => {
          handleOk(selectedColumnsInternal);
        }}
      >
        <Table
          className={`${styles.grid} ${styles.gridShowScrollbars}`}
          columns={columns}
          dataSource={data}
          rowSelection={{
            selectedRowKeys: selectedColumnsInternal.selectedRowKeys,
            type: 'checkbox',
            ...rowSelection
          }}
          pagination={false}
          scroll={{ y: 'calc(100vh - 400px)', x: 'max-content' }}
        />
      </Modal>
    </>
  );
};
