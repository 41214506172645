import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AlertCard from 'components/alertCard/AlertCard';

import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useCompanyAlerts, useIsAlertFetching as isFetchingAlerts } from 'features/company_alerts';
import { Can, entities, useCanEveryEntity } from 'features/permissions';

import { sortStrings } from 'utils/strings';

import { getSkeletonLoaders } from './helpers/getSkeletonLoaders';
import { getAlertStatus } from './helpers/getAlertStatus';
import { useAlertPermissions } from './helpers/useAlertPermissions';

import { companyAlertForms } from './constants';

import styles from './CompanyAlerts.module.scss';

const NewCompanyAlerts = () => {
  const dispatch = useDispatch();
  const companyAlerts = useCompanyAlerts();
  const isFetching = isFetchingAlerts();
  const alertPermissions = useAlertPermissions();
  const validPermissions = alertPermissions.filter(val => val.permission);
  const canViewAlert = useCanEveryEntity([entities.COMPANYALERT_VIEW]);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('Entity.Company Alerts')));
  }, [t, dispatch]);

  companyAlertForms.sort((formA, formB) => sortStrings(formA?.name, formB?.name));

  return (
    <div className={styles.alertsContainer}>
      {isFetching ? (
        <>{getSkeletonLoaders(validPermissions)}</>
      ) : (
        companyAlertForms.reduce((accumulator, form) => {
          const formAlertPermission = alertPermissions?.find(
            alertPermission => alertPermission?.type === form?.type
          );
          if (formAlertPermission?.permission) {
            const alertStatus = getAlertStatus(form, companyAlerts);
            accumulator.push(
              <Can
                key={form?.type}
                oneOfServices={formAlertPermission.services}
                everyEntity={entities.COMPANYALERT}
                featureFlag={formAlertPermission.featureFlag}
              >
                <div className={styles.linkContainer}>
                  {canViewAlert ? (
                    <Link
                      key={form.type}
                      to={{
                        pathname: `/settings/alerts/view/${form.link}`
                      }}
                      className={styles.link}
                    >
                      <AlertCard
                        title={form.name}
                        active={alertStatus.active}
                        inactive={alertStatus.inactive}
                      />
                    </Link>
                  ) : (
                    <AlertCard
                      title={form.name}
                      active={alertStatus.active}
                      inactive={alertStatus.inactive}
                      disabled={true}
                    />
                  )}
                </div>
              </Can>
            );
          }
          return accumulator;
        }, [])
      )}
    </div>
  );
};

export default NewCompanyAlerts;
