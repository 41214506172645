import { CloseCircleOutlined, EditFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Tooltip, Tag } from 'antd';
import { confirmationModal } from '../../../components/ant/Button/confirmationModal/confirmationModal';
import moment from 'moment/moment';
import styles from './Configuration.module.scss';

export const ColumnKeys = {
  NAME: 'name',
  KEY: 'access_token',
  STATUS: 'status',
  ACTIONS: 'actions'
};

export const Columns = [
  {
    title: 'Name',
    dataIndex: ColumnKeys.NAME,
    key: ColumnKeys.NAME,
    sorter: (a, b) => (a.name > b.name ? 1 : -1)
  },
  {
    title: 'Key',
    dataIndex: ColumnKeys.KEY,
    key: ColumnKeys.KEY,
    sorter: (a, b) => (a.key > b.key ? 1 : -1)
  },
  {
    title: 'Status',
    dataIndex: ColumnKeys.STATUS,
    key: ColumnKeys.STATUS,
    sorter: (a, b) => (a.enabled > b.enabled ? 1 : -1)
  },
  {
    title: 'Actions',
    dataIndex: ColumnKeys.ACTIONS,
    key: ColumnKeys.ACTIONS,
    width: 50
  }
];

export const prepareColumnsForInboundTable = t =>
  Columns.map(column => ({
    ...column,
    title: t(`Integrations.InboundTable.${column.title}`)
  }));

export const prepareDataForInboundTable = (inboundArray = [], apiActions = {}, t = null) => {
  return inboundArray
    .slice()
    .sort((a, b) => b.enabled - a.enabled)
    .map(inbound => {
      return {
        [ColumnKeys.NAME]: inbound.label,
        [ColumnKeys.KEY]: inbound.accessToken,
        [ColumnKeys.STATUS]: (
          <div>
            {inbound.enabled ? (
              <>
                <Tag className={styles.tagValid}>Valid</Tag>
                {inbound.createdAt && moment().diff(moment(inbound.createdAt), 'months') > 6 && (
                  <span className={styles.tagRenewal}>
                    <ExclamationCircleFilled />
                    {t('Integrations.InboundTable.NeedsRenewal')}
                  </span>
                )}
              </>
            ) : (
              <Tag className={styles.tagExpired}>{t('Integrations.InboundTable.NeedsRenewal')}</Tag>
            )}
            {inbound.createdAt && (
              <div className={styles.statusSince}>
                {t('Integrations.InboundTable.Since')}{' '}
                {moment(inbound.createdAt).format('DD/MM/YYYY')}
              </div>
            )}
          </div>
        ),
        [ColumnKeys.ACTIONS]: inbound.enabled && (
          <div className={styles.actionsWrapper}>
            <Tooltip title={t('Integrations.Tooltip.DisableInbound')}>
              <CloseCircleOutlined
                onClick={() =>
                  confirmationModal(
                    `${t('Integrations.InboundTable.Disable')} ${inbound.label}`,
                    t('Integrations.InboundTable.AreYouSure', { name: inbound.label }),
                    t('Common.DisableButton'),
                    t('Common.CancelButton'),
                    () => apiActions?.handleDisableInbound(inbound.adapterId, inbound.id),
                    'delete'
                  )
                }
              />
            </Tooltip>
          </div>
        ),
        key: inbound?.id
      };
    });
};

export const prepareColumnsForOutboundTable = fields => {
  const columns = fields
    .filter(field => field.id !== 'password')
    .map(field => ({
      ...field,
      title: field.label,
      dataIndex: field.id,
      key: field.id,
      sorter: (a, b) => (a.field > b.field ? 1 : -1)
    }));
  const newData = [
    ...columns,
    {
      title: 'Actions',
      dataIndex: ColumnKeys.ACTIONS,
      width: 70
    }
  ];
  return newData;
};

export const prepareDataForOutboundTable = (adapter, actions = {}, apiActions = {}, t = null) => {
  const outboundArray = adapter.outbound;
  const fields = adapter.fields;
  const fieldsColumns = fields.map(f => f.id);

  return outboundArray.map(outbound => {
    const obj = {};
    fieldsColumns.map(field => {
      Object.assign(obj, { [field]: outbound[field] });
    });

    return Object.assign(obj, {
      [ColumnKeys.ACTIONS]: (
        <div className={styles.actionsWrapper}>
          <Tooltip title={t('Integrations.Tooltip.EditOutbound')}>
            <EditFilled onClick={() => actions.handleOutboundEdit(outbound)} />
          </Tooltip>
          <Tooltip title={t('Integrations.Tooltip.DeleteOutbound')}>
            <CloseCircleOutlined
              onClick={() =>
                confirmationModal(
                  `${t('Common.DeleteButton')} ${outbound.url ||
                    outbound.endpoint ||
                    outbound.target ||
                    t('Integrations.outbound')}`,
                  `${t('Common.SureDelete')} ${outbound.url ||
                    outbound.endpoint ||
                    outbound.target ||
                    t('Integrations.outbound')}?`,
                  t('Common.DeleteButton'),
                  t('Common.CancelButton'),
                  () => apiActions?.handleDeleteOutbound(outbound.type, outbound.id),
                  'delete'
                )
              }
            />
          </Tooltip>
        </div>
      ),
      key: outbound.id
    });
  });
};
