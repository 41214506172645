import { sortStrings } from 'utils/strings';
import { eventType, detailsStatusForSorting } from './helpers';

export const ColumnKeys = {
  ID: 'id',
  EVENT_TYPE: 'eventType',
  CREATED_AT: 'createdAt',
  SENT_AT: 'sentAt',
  STATUS: 'status',
  DETAILS: 'details',
  ACTIONS: 'actions'
};

export const columns = t => {
  return [
    {
      title: t('Integrations.EventsTab.TableColumns.ID'),
      dataIndex: ColumnKeys.ID,
      key: ColumnKeys.ID,
      width: 80,
      sorter: (a, b) => (a.id > b.id ? 1 : -1)
    },
    {
      title: t('Integrations.EventsTab.TableColumns.EventType'),
      dataIndex: ColumnKeys.EVENT_TYPE,
      key: ColumnKeys.EVENT_TYPE,
      width: 200,
      sorter: {
        compare: (a, b) => sortStrings(eventType(a), eventType(b))
      }
    },
    {
      title: t('Integrations.EventsTab.TableColumns.CreatedAt'),
      dataIndex: ColumnKeys.CREATED_AT,
      key: ColumnKeys.CREATED_AT,
      width: 200,
      sorter: (a, b) => (a.createdAt_api > b.createdAt_api ? 1 : -1),
      defaultSortOrder: 'descend'
    },
    {
      title: t('Integrations.EventsTab.TableColumns.SentAt'),
      dataIndex: ColumnKeys.SENT_AT,
      key: ColumnKeys.SENT_AT,
      width: 150,
      sorter: (a, b) => (a.sentAt > b.sentAt ? 1 : -1)
    },
    {
      title: t('Integrations.EventsTab.TableColumns.Status'),
      dataIndex: ColumnKeys.STATUS,
      key: ColumnKeys.STATUS,
      width: 110,
      sorter: {
        compare: (a, b) => sortStrings(detailsStatusForSorting(a, t), detailsStatusForSorting(b, t))
      }
    },
    {
      title: t('Integrations.EventsTab.TableColumns.Details'),
      dataIndex: ColumnKeys.DETAILS,
      key: ColumnKeys.DETAILS,
      sorter: {
        compare: (a, b) => sortStrings(detailsStatusForSorting(a, t), detailsStatusForSorting(b, t))
      },
      width: 200
    },
    {
      title: t('Integrations.EventsTab.TableColumns.Actions'),
      dataIndex: ColumnKeys.ACTIONS,
      width: 55,
      key: ColumnKeys.ACTIONS
    }
  ];
};
