import { API_PATH } from 'config';
import request from 'superagent';

export const getFleetsByCompany = async (companyId, userKey, direction = 'DOWN', embed = '') => {
  return new Promise((resolve, reject) => {
    const url = `${API_PATH}/fleets?direction=${direction}${
      embed ? `&embed=${embed}` : ''
    }&company_id=${companyId}`;
    request('GET', url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(resp => {
        const sortedFleets = resp.body.sort((a, b) =>
          (a.name || '').toUpperCase() > (b.name || '').toUpperCase() ? 1 : -1
        );
        resolve(sortedFleets);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getGeofencesByCompany = async (companyId, userKey, spec) => {
  return new Promise((resolve, reject) => {
    const url =
      `${API_PATH}/geofences?direction=DOWN&embed=fleets&pruning=ALL&company_id=${companyId}` +
      (spec
        ? '&types=ALL' + (spec.geofenceProviders ? ',' + spec.geofenceProviders.join(',') : '')
        : '');
    request('GET', url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(resp => {
        const sortedGeofences = resp.body.sort((a, b) =>
          (a.name || '').toUpperCase() > (b.name || '').toUpperCase() ? 1 : -1
        );
        resolve(sortedGeofences);
      })
      .catch(err => {
        reject(err);
      });
  });
};
