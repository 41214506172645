import React from 'react';
import { MessageComposeModal } from 'containers/Messaging/MessageComposeModal';
import { RequestFootageModal } from 'features/requestFootage/RequestFootageModal';
import { SnapshotsContainer } from './Snapshots/SnapshotsContainer.js';
import { useSnapshots } from './Snapshots/hooks/useSnapshots.js';
import { Loading } from '../Components/Loading.js';

export const SnapshotsTab = ({ showNonCameraVehicle = false }) => {
  const {
    isDataFetching,
    filters,
    snapshots,
    messagingProps: { showPopup, ...otherMessagingProps },
    requestVideoProps: { showFootageModal, ...otherRequestVideoProps }
  } = useSnapshots({ showNonCameraVehicle });

  return (
    <>
      {isDataFetching ? (
        <Loading />
      ) : (
        <SnapshotsContainer filters={filters} snapshots={snapshots} />
      )}
      {showPopup && <MessageComposeModal {...otherMessagingProps} />}
      {showFootageModal && <RequestFootageModal {...otherRequestVideoProps} />}
    </>
  );
};
