export const VehicleParameter = {
  Odometer: 'odometer',
  RPM: 'rpm',
  Speed: 'speed',
  Hours: 'hours',
  OilTemperature: 'oiltemp',
  OilPressure: 'oilpressure',
  EngineLoad: 'engineload',
  Gear: 'gear',
  FuelLevel: 'fuellevel',
  FuelConsumption: 'fuelconsumption',
  Mil: 'mil',
  Charge: 'charge',
  Id: 'id',
  GPS: 'gps'
};

export const formatVehicleParameterValue = (t, localization, type, value) => {
  if (value) {
    switch (type.toLowerCase()) {
      case VehicleParameter.Odometer:
        return localization.formatDistance(value);
      case VehicleParameter.RPM:
        return value.toFixed(1) + ' rpm';
      case VehicleParameter.Speed:
        return localization.formatSpeed(value);
      case VehicleParameter.Hours:
        return value.toFixed(1) + ' hrs';
      case VehicleParameter.OilTemperature:
        return localization.formatTemperature(value);
      case VehicleParameter.OilPressure:
        return value.toFixed(1);
      case VehicleParameter.EngineLoad:
        return value.toFixed(1) + ' %';
      case VehicleParameter.Gear:
        return value;
      case VehicleParameter.FuelLevel:
        return value.toFixed(1) + ' %';
      case VehicleParameter.FuelConsumption:
        return localization.formatFuel(value);
      case VehicleParameter.Mil:
        return value ? t('Common.Active') : t('Common.Inactive');
      case VehicleParameter.Charge:
        return value.toFixed(1) + ' %';
      default:
        return isNaN(value) ? '' : value.toFixed(1);
    }
  }

  return '';
};
