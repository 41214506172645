import React from 'react';
import { Alert, Checkbox, Form } from 'antd';

import styles from './FormCheckboxes.module.scss';
import { useTranslation } from 'react-i18next';

export const FormCheckboxes = ({ properties, options, onChange, setIsDirty, initialValues }) => {
  const { name, label, showInfoAlert, style } = properties;
  const { t } = useTranslation();

  const handleOnChange = e => {
    if (typeof setIsDirty === 'function') {
      setIsDirty(true);
    }

    onChange(e);
  };

  return (
    <>
      {showInfoAlert && (
        <Form.Item
          labelCol={{
            offset: 0.5,
            span: 24
          }}
          wrapperCol={{
            offset: 1,
            span: 8
          }}
        >
          <div className={styles.infoAlert}>
            <Alert description={t('Alerts.NotAllAlertsAvailable')} type="info" showIcon />
          </div>
        </Form.Item>
      )}
      <Form.Item
        labelCol={{
          offset: 1,
          span: 24
        }}
        name={name}
        wrapperCol={{
          offset: 1,
          span: 8
        }}
        label={label}
      >
        <Checkbox.Group style={style} options={options || []} onChange={handleOnChange} />
      </Form.Item>
    </>
  );
};
