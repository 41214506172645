import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//components
import { AuditsTable } from 'components/auditsTable/AuditsTable';

//slices
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits } from 'features/audits';
import { useDevices, useIsFetchingDevicesFinished } from 'features/fleets/fleetsSlice';
import {
  useCompanies,
  useIsFetchingCompany,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';

// helpers
import { getIDFromPathname } from 'utils/methods';

//constants
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { useHistory } from 'react-router';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { PATHS } from './constants';

export const SubCompaniesAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const history = useHistory();
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.SUBCOMPANY_AUDITS);
  }, [history]);
  const audits = useAudits(AUDIT_ENTITY.COMPANY, id);
  const companies = useCompanies();
  const companyData = companies?.find(c => c.id === Number(id));
  const isCompanyFetched = useIsFetchingCompany();
  const pageTitle = !companyData
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: companyData?.name });

  useRedirectToMainFeaturePageOnCompanyChange('/settings/subcompanies');

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    dispatch(setBackButton(true));
  }, [pageTitle, dispatch]);

  if (id <= 0 || isNaN(id) || (isCompanyFetched && !companyData && !audits)) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: t('Common.Invalid Request ID')
      })
    );
    handleFetchError();
  }

  return <AuditsTable audits={audits} entity={AUDIT_ENTITY.COMPANY} />;
};
