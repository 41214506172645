import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Col, Row } from 'antd';
import FormTitle from 'components/form/form-title/FormTitle';
import InfoRow from 'components/form/info-row/InfoRow';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';

import styles from './Forms.module.scss';
import { PERMISSION, STATUS, ROUTE_PATHS } from './constants';
import { getFormDate, getFormElements, handleEditAction } from './helpers';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import { handleDeleteAction, useForms, useIsFetched } from 'features/forms/formsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useSubCompanies } from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';

export const FormsView = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const forms = useForms();
  const isFetched = useIsFetched();
  const allFleets = useFleets();
  const localization = useLocalization();
  const subCompanies = useSubCompanies();
  const userKey = useUserKey();

  const { id, isInvalidRequestId, formData, handleError } = useMemo(() => {
    const idReg = /(?:(?:\/settings\/forms\/id)|(?:\/admin\/ng\/forms))\/(\d+)/;
    const id = Number(history.location.pathname.match(idReg)?.[1]);
    const formData = forms.find(form => parseInt(form.Id, 10) === id);
    const isInvalidRequestId = isNaN(id) || id <= 0 || (isFetched && !formData);
    return {
      id,
      isInvalidRequestId,
      formData,
      handleError(err) {
        if (err) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: err
            })
          );
        }
        if (history.location.pathname !== ROUTE_PATHS.main) {
          history.replace(ROUTE_PATHS.main);
        }
      }
    };
  }, [history, isFetched, forms, dispatch]);

  useEffect(() => {
    if (isInvalidRequestId) {
      handleError(t('Common.Invalid Request ID'));
    }
  }, [t, handleError, isInvalidRequestId]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (formData?.Name) {
      dispatch(setPageTitle(formData.Name));
    }
  }, [formData, dispatch]);

  const fleets = useMemo(
    () =>
      [...allFleets, { id: -1, name: `${t('Forms.View.NoFleet')}` }]
        .filter(fleet =>
          (formData?.Associations || []).find(association => association.fleetId === fleet.id)
        )
        .map((fleet, i) => (
          <div key={`row-${formData.id}-${i}`}>
            {fleet.name}
            {i < formData?.Associations.length - 1 ? ',' : ''}
          </div>
        )),
    [t, allFleets, formData]
  );

  const handleButtonAction = action => () => {
    if (action === 'delete') {
      dispatch(handleDeleteAction(formData, history, userKey, dispatch));
    }
  };

  return (
    <>
      <ViewHeaderWrapper
        data={{
          ...formData,
          status: STATUS.ENABLED
        }}
        editPath={`${ROUTE_PATHS.edit}/${id}`}
        canUse={PERMISSION.FORM}
        handleButtonAction={handleButtonAction}
        handleEditAction={handleEditAction(history, id, formData)}
        typeOfEntityToDelete={t('Common.form')}
      />
      <Row className={styles.formViewContainer}>
        <Col span={24}>
          <FormTitle title={t('Forms.View.Details')} underlined />
          <InfoRow label={t('Forms.View.Name')} value={formData?.Name} styles={styles} />
          <InfoRow
            label={t('Forms.View.Company')}
            value={subCompanies.find(c => c.id === parseInt(formData?.CompanyId, 10))?.name}
            styles={styles}
          />
          <InfoRow label={t('Forms.View.Version')} value={formData?.Version} styles={styles} />
          <InfoRow
            label={t('Forms.View.AlertOnCompletion')}
            value={formData?.AlertOnCompletion ? t('Common.Yes') : t('Common.No')}
            styles={styles}
          />
          <InfoRow label={t('Forms.View.Association')} value={fleets} styles={styles} />
          <InfoRow
            label={t('Forms.View.CreatedAt')}
            value={getFormDate(formData?.CreatedAt, localization).replace(/[-]/g, ' ')}
            styles={styles}
          />
          <InfoRow
            label={t('Forms.View.UpdatedAt')}
            value={getFormDate(formData?.UpdatedAt, localization).replace(/[-]/g, ' ')}
            styles={styles}
          />
          <FormTitle title={t('Forms.View.FormElements')} underlined />
          {getFormElements(formData?.Elements)}
        </Col>
      </Row>
    </>
  );
};
