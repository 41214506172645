import React, { useState, useEffect, useCallback } from 'react';
import { Select, Skeleton } from 'antd';

import { Tooltip } from 'components/ant';
import cn from 'classnames';
import styles from './AntMultiselect.module.scss';

const { Option } = Select;

export const formatListForMultiselect = list => {
  let updatedList = [];

  list.forEach(val => {
    const listItem = {
      label: val.name,
      checked: true,
      id: val.id
    };
    updatedList.push(listItem);
  });

  return updatedList;
};

const AntMultiselect = ({ title, data, onFilter, loading, tooltip, ...props }) => {
  const [singleSelectedOption, setSingleSelectedOption] = useState(null);
  const [selectedValues, setSelectedValues] = useState(
    (data || []).filter(value => value.checked).map(value => value.id)
  );
  const [focus, setFocus] = useState(false);

  function getSelectedValues(values) {
    setSelectedValues(data.filter(option => values.includes(option)).map(option => option.id));
  }

  useEffect(() => {
    const selectedOptions = (data || []).filter(value => value.checked);
    setSelectedValues(selectedOptions.map(value => value.id));
    if (selectedOptions?.length === 1) {
      setSingleSelectedOption(selectedOptions[0].label);
    }
  }, [data]);

  const getPlaceholder = useCallback(() => {
    if (focus) {
      return;
    }

    let _placeholder = '',
      _placeholderCls = '';
    if (selectedValues.length === (data || []).length) {
      _placeholder = title;
    } else if (selectedValues.length === 1) {
      _placeholderCls = styles.singleSelection;
      _placeholder = singleSelectedOption;
    } else {
      _placeholder = `${selectedValues.length} \u2022 ${title}`;
    }

    return <span className={_placeholderCls}>{_placeholder}</span>;
  }, [focus, selectedValues, singleSelectedOption, title]);

  function handleChange(value) {
    let fullOptions = data.filter(option => value.includes(option.id));
    if (fullOptions.length === 1) {
      setSingleSelectedOption(fullOptions[0].label);
    }

    let selectedOptions = null;

    if (fullOptions.find(option => option.id === 0)) {
      if (fullOptions.length === data.length - 1) {
        if (data.filter(val => val.checked).find(value => value.id === 0)) {
          selectedOptions = fullOptions.filter(option => option.id !== 0);
        } else {
          selectedOptions = data;
        }
      } else {
        selectedOptions = data;
      }
    } else {
      if (fullOptions.length === data.length - 1) {
        if (data.filter(val => val.checked).find(value => value.id === 0)) {
          selectedOptions = [];
        } else {
          selectedOptions = data;
        }
      } else if (fullOptions.length < data.length) {
        selectedOptions = fullOptions;
      } else {
        selectedOptions = data;
      }
    }

    getSelectedValues(selectedOptions);
    onFilter(
      data.map(option =>
        selectedOptions.includes(option)
          ? {
              label: option.label,
              checked: true,
              id: option.id
            }
          : {
              label: option.label,
              checked: false,
              id: option.id
            }
      )
    );
  }

  const multiselectStyle = {
    minWidth: '180px',
    marginRight: '10px',
    fontSize: '14px',
    color: '#818ea1'
  };

  const multiselectClasses = cn({
    [styles.multiselectFocused]: focus,
    [styles.multiselect]: !focus
  });

  return (
    <div className={multiselectClasses}>
      <Select
        mode="multiple"
        size="large"
        maxTagCount={0}
        maxTagPlaceholder={getPlaceholder}
        placeholder={title}
        value={selectedValues}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        title={title}
        listHeight={400}
        style={multiselectStyle}
        onChange={handleChange}
        filterOption={(input, option) =>
          option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        getPopupContainer={triggerNode => triggerNode.parentNode}
        {...props}
      >
        {loading ? (
          <Option className={styles.loaderOptionContainer} disabled key={-1} value={-1}>
            <Skeleton.Input active />
          </Option>
        ) : (
          (data || []).map(value => (
            <Option key={value.id} value={value.id}>
              {value.label}
            </Option>
          ))
        )}
      </Select>
      {tooltip && <Tooltip content={tooltip} target={<i className={'tn-i-info'} />} />}
    </div>
  );
};

export default AntMultiselect;
