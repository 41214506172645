import React from 'react';
import FormInput from 'components/form/form-input/FormInput';
import './FormColor.scss';

const FormColor = ({ name, label, values, onChange, disabled }) => {
  return (
    <div className="color-wrapper">
      <FormInput
        name={name}
        label={label}
        placeholder={(values && values[name]) || ''}
        onChange={onChange}
        disabled={disabled}
      />
      <div className="color-wrapper-input">
        <FormInput name={name} type="color" label="" onChange={onChange} disabled={disabled} />
      </div>
    </div>
  );
};

export default FormColor;
