import { vehicleTypeMetrics } from 'features/configuration/constants';
import moment from 'moment';
import { Mixpanel } from './mixpanel';
export const MPTrackingEvents = {
  Insights: {
    SearchLanding: 'Insights Search Page Land',
    Auth: 'Insights Auth',
    AnalyticsReports: 'Insights Analytics Reports Land',
    AnalyticsReport: 'Insights Analytics Report Land'
  },
  Tracking: {
    FindVehicle: 'Find Vehicle',
    MapTilesPositionChange: 'Map Tiles Position Change',
    Map: {
      ViewVehicleDetails: 'View Vehicle Details via Map',
      Geofence: {
        View: 'View Geofence Details via Map',
        Add: 'Add Geofence Details via Map',
        Edit: 'Edit Geofence Details via Map'
      }
    },
    Trip: {
      TripSummary: 'Trip Summary View',
      VehicleDetails: 'Vehicle Details View',
      VehicleParameters: 'Vehicle Parameters View',
      Toggle: {
        DroneViewToggle: 'Drone View Toggle',
        LiveViewToggle: 'Live View Toggle'
      }
    }
  },
  VehicleMaintenance: {
    Schedule: {
      add: 'Add Schedule',
      edit: 'Edit Schedule',
      reschedule: 'Reschedule Schedule',
      cancel: 'Cancel Schedule',
      delete: 'Delete Schedule',
      getScheduleSelectors: (odometerChecked, engineHoursChecked, dateChecked) =>
        [
          odometerChecked && 'Odometer',
          engineHoursChecked && 'Engine Hours',
          dateChecked && 'Date'
        ].filter(selector => !!selector),
      complete: 'Complete Schedule',
      completeAction: {
        complete: 'complete',
        completeWithReschedule: 'complete with reschedule'
      }
    }
  },
  Easydocs: {
    UploadDocuments: 'Upload Documents',
    ExportToExcel: 'Export to Excel'
  },
  Reports: {
    ReportSelected: 'Report Selected'
  },
  Settings: {
    Locations: {
      Update: 'Locations Update',
      TrackFormFields: {
        GPS: {
          NameMapping: 'GPS Position'
        },
        address: {
          NameMapping: 'Address'
        },
        companyId: {
          NameMapping: 'Company'
        },
        name: {
          NameMapping: 'Location Name'
        },
        type: {
          NameMapping: 'Location Type',
          FieldEqualFun: (prev, curr) => {
            try {
              const prevId = prev?.id || prev;
              const currId = curr?.id || curr;
              return JSON.stringify(prevId) === JSON.stringify(currId);
            } catch (error) {}
          }
        },
        siteNotes: {
          NameMapping: 'Site Notes'
        }
      }
    },
    Geofences: {
      Update: 'GeoFences Update',
      TrackFormFields: {
        name: {
          NameMapping: 'Geofence Name'
        },
        type: {
          NameMapping: 'Geofence Type'
        },
        companyId: {
          NameMapping: 'Company'
        },
        fleets: {
          NameMapping: 'Associated Fleets',
          FieldEqualFun: (initial, current) => {
            try {
              const initialFleets = initial?.map(fleet => Number(fleet.id));
              const currentFleets = current?.map(fleetId => Number(fleetId));
              return JSON.stringify(initialFleets) === JSON.stringify(currentFleets);
            } catch (error) {}
          }
        },
        thresholdSpeed: {
          NameMapping: 'Speed Limit(km/h)',
          FieldEqualFun: (initial, current) => {
            const unifyNum = num => (isNaN(Number(num)) ? 0 : Number(num) < 0 ? 0 : Number(num));
            initial = unifyNum(initial);
            current = unifyNum(current);
            try {
              return initial === current;
            } catch (error) {}
          }
        },
        offsetSpeed: {
          NameMapping: 'Speed Tolerance(km/h)',
          FieldEqualFun: (initial, current) => {
            const unifyNum = num => (isNaN(Number(num)) ? 0 : Number(num) < 0 ? 0 : Number(num));
            initial = unifyNum(initial);
            current = unifyNum(current);
            try {
              return initial === current;
            } catch (error) {}
          }
        },
        thresholdDurationSpeed: {
          NameMapping: 'Speed Duration(seconds)',
          FieldEqualFun: (initial, current) => {
            const unifyNum = num => (isNaN(Number(num)) ? 0 : Number(num) < 0 ? 0 : Number(num));
            initial = unifyNum(initial);
            current = unifyNum(current);
            try {
              return initial === current;
            } catch (error) {}
          }
        },
        speedAssist: {
          NameMapping: 'Use in SpeedAssist'
        },
        thresholdUndertime: {
          NameMapping: 'Undertime(mins)',
          FieldEqualFun: (initial, current) => {
            try {
              const unifyNum = num => (isNaN(Number(num)) ? 0 : Number(num) < 0 ? 0 : Number(num));
              initial = unifyNum(initial);
              current = unifyNum(current);
              return initial === current || initial * 60 === current;
            } catch (error) {}
          }
        },
        thresholdOvertime: {
          NameMapping: 'Overtime(mins)',
          FieldEqualFun: (initial, current) => {
            const unifyNum = num => (isNaN(Number(num)) ? 0 : Number(num) < 0 ? 0 : Number(num));
            initial = unifyNum(initial);
            current = unifyNum(current);
            try {
              return initial === current || initial * 60 === current;
            } catch (error) {}
          }
        },
        driverNotification: {
          NameMapping: 'Driver Notification',
          getUpdatedSubFields: (prev, curr) => {
            try {
              const prevDriverNotification = (prev && JSON.parse(prev)) || [];
              const currDriverNotification = (curr && JSON.parse(curr)) || [];
              const driverIgnitionFields = {
                Message: {
                  NameMapping: 'Driver Message(Ignition On)'
                },
                Ptc: {
                  NameMapping: 'Inspections(Ignition On)'
                }
              };
              const driverGeofenceEntryFields = {
                ActivePeriods: {
                  NameMapping: 'Active Periods(Geofance Entry)'
                },
                Document: {
                  NameMapping: 'Document(Geofance Entry)'
                },
                Message: {
                  NameMapping: 'Driver Message(Geofance Entry)'
                },
                Mode: {
                  NameMapping: 'Mode(Geofance Entry)'
                },
                Ptc: {
                  NameMapping: 'Inspections(Geofance Entry)'
                }
              };
              const driverIgnition = driverNotification =>
                driverNotification.find(({ TriggerEvent }) => TriggerEvent === 'IOR');
              const driverGeofenceEntry = driverNotification =>
                driverNotification.find(({ TriggerEvent }) => TriggerEvent === 'GEO-EN');
              const getUpdateFields = (fieldsMapping, fieldsFunc) => {
                const fieldNames = Object.keys(fieldsMapping);
                const prevFields = fieldsFunc(prevDriverNotification) || {};
                const currFields = fieldsFunc(currDriverNotification) || {};
                return fieldNames
                  .map(fieldName => {
                    const isEqual =
                      (!prevFields[fieldName] && !currFields[fieldName]) ||
                      (!prevFields[fieldName] && !currFields[fieldName]?.id) ||
                      (!prevFields[fieldName]?.id && !currFields[fieldName]?.id) ||
                      JSON.stringify(prevFields[fieldName]) ===
                        JSON.stringify(currFields[fieldName]);
                    return isEqual ? null : fieldsMapping[fieldName]?.NameMapping || fieldName;
                  })
                  .filter(fieldName => !!fieldName);
              };
              const driverIgnitionUpdatedFields = getUpdateFields(
                  driverIgnitionFields,
                  driverIgnition
                ),
                driverGeofenceEntryUpdatedFields = getUpdateFields(
                  driverGeofenceEntryFields,
                  driverGeofenceEntry
                );
              const ignitionOnChanged =
                (!!driverIgnition(prevDriverNotification) &&
                  !driverIgnition(currDriverNotification)) ||
                (!driverIgnition(prevDriverNotification) &&
                  !!driverIgnition(currDriverNotification));
              const geofenceEntryChanged =
                (!!driverGeofenceEntry(prevDriverNotification) &&
                  !driverGeofenceEntry(currDriverNotification)) ||
                (!driverGeofenceEntry(prevDriverNotification) &&
                  !!driverGeofenceEntry(currDriverNotification));
              const ret = [...driverIgnitionUpdatedFields, ...driverGeofenceEntryUpdatedFields];
              if (ignitionOnChanged) {
                ret.push('Ignition On');
              }
              if (geofenceEntryChanged) {
                ret.push('Geofence Entry');
              }
              return ret;
            } catch (error) {}
          }
        }
      },
      OnSubmitTrackEventPropsFunc: submitValue => {
        const unifyNum = num => (isNaN(Number(num)) ? 0 : Number(num) < 0 ? 0 : Number(num));
        return {
          'Speed Limit(km/h)': unifyNum(submitValue?.thresholdSpeed),
          'Speed Tolerance(km/h)': unifyNum(submitValue?.offsetSpeed),
          'Speed Duration(seconds)': unifyNum(submitValue?.thresholdDurationSpeed),
          'Use in SpeedAssist': submitValue?.speedAssist,
          'Undertime(mins)': unifyNum(submitValue?.thresholdUndertime / 60),
          'Overtime(mins)': unifyNum(submitValue?.thresholdOvertime / 60)
        };
      }
    },
    Forms: {
      Update: 'Forms Update',
      TrackFormFields: {
        Name: {
          NameMapping: 'Form Name'
        },
        CompanyId: {
          NameMapping: 'Company'
        },
        AlertOnCompletion: {
          NameMapping: 'Alert On Completion',
          FieldEqualFun: (initial, current) => {
            try {
              if (current) {
                return initial[0] && initial[0] === 'on';
              } else {
                return !(initial[0] && initial[0] === 'on');
              }
            } catch (error) {}
          }
        },
        Associations: {
          NameMapping: 'Form Associations(Assign form to Fleets)',
          FieldEqualFun: (initial, current) => {
            try {
              const initialFleets = initial?.map(fleet => Number(fleet.fleetId));
              const currentFleets = current?.map(fleet => Number(fleet.fleetId));
              return JSON.stringify(initialFleets) === JSON.stringify(currentFleets);
            } catch (error) {}
          }
        }
      },
      OnSubmitTrackEventPropsFunc: submitValue => ({
        formElementTypes: Array.from(new Set((submitValue?.Elements || []).map(el => el?.Type))),
        alertOnCompletion: !!submitValue?.AlertOnCompletion
      })
    },
    PreTripChecklist: {
      Update: 'Inspections Update',
      TrackFormFields: {
        Name: {
          NameMapping: 'Checklist Name'
        },
        CompanyId: {
          NameMapping: 'Company'
        },
        VehicleRegistration: {
          NameMapping: 'Vehicle Registration',
          FieldEqualFun: (initial, current) => {
            try {
              if (current) {
                return initial[0] && initial[0] === 'on';
              } else {
                return !(initial[0] && initial[0] === 'on');
              }
            } catch (error) {}
          }
        },
        CreateMaintenance: {
          NameMapping: 'Create Maintenance',
          FieldEqualFun: (initial, current) => {
            try {
              if (current) {
                return initial[0] && initial[0] === 'on';
              } else {
                return !(initial[0] && initial[0] === 'on');
              }
            } catch (error) {}
          }
        },
        DriverSignature: {
          NameMapping: 'Driver Signature',
          FieldEqualFun: (initial, current) => {
            try {
              if (current) {
                return initial[0] && initial[0] === 'on';
              } else {
                return !(initial[0] && initial[0] === 'on');
              }
            } catch (error) {}
          }
        },
        Associations: {
          NameMapping: 'Associations(Assign inspections to Company/Fleets)',
          FieldEqualFun: (initial, current) => {
            try {
              const initial_company_id = initial
                ?.filter(item => /c-\d+/.test(item?.value))
                .map(item => String(item.value.replace('c-', '')));
              const initial_fleet_id = initial
                ?.filter(item => !/c-\d+/.test(item?.value))
                .map(item => String(item.value));
              const current_company_id = (current?.company_id || []).map(id => String(id));
              const current_fleet_id = (current?.fleet_id || []).map(id => String(id));

              return (
                JSON.stringify({ fleet: initial_fleet_id, company: initial_company_id }) ===
                JSON.stringify({ fleet: current_fleet_id, company: current_company_id })
              );
            } catch (error) {}
          }
        },
        Content: {
          NameMapping: 'Pre-trip Question',
          FieldEqualFun: (initial, current) => {
            try {
              const initialQs = {
                FailedQuestions: initial?.FailedQuestions || [],
                Questions: initial?.Questions || []
              };
              const currentQs = {
                FailedQuestions: current?.FailedQuestions || [],
                Questions: current?.Questions || []
              };
              return JSON.stringify(initialQs) === JSON.stringify(currentQs);
            } catch (error) {}
          }
        }
      },
      OnSubmitTrackEventPropsFunc: submitValue => ({
        vehicleRegistration: !!submitValue?.VehicleRegistration,
        CreateMaintenance: !!submitValue?.CreateMaintSchedule,
        driverSignature: !!submitValue?.DriverSignature
      })
    },
    VehicleMntTypes: {
      Update: 'Vehicle Mnt Types Update',
      TrackFormFields: {
        name: {
          NameMapping: 'Vehicle Mnt Type Name'
        },
        companyId: {
          NameMapping: 'Company'
        },
        vehicleTypes: {
          NameMapping: 'Vehicle Types',
          FieldEqualFun: (initial, current) => {
            try {
              const initialIds = initial?.map(type => String(type.id));
              const currentIds = current?.map(type => String(type.id));
              return JSON.stringify(initialIds) === JSON.stringify(currentIds);
            } catch (error) {}
          }
        }
      },
      OnSubmitTrackEventPropsFunc: submitValue => ({
        vehicleMntTypeName: submitValue?.name,
        vehicleTypes: submitValue?.vehicleTypes?.map(type => type?.name).filter(name => !!name)
      })
    },
    VehicleTypes: {
      Update: 'Vehicle Types Update',
      TrackFormFields: {
        name: {
          NameMapping: 'Vehicle Type Name'
        },
        code: {
          NameMapping: 'Vehicle Type Code'
        },
        speedLimitClass: {
          NameMapping: 'Vehicle Speed Limit Class'
        },
        ...Object.keys(vehicleTypeMetrics)
          .filter(metric =>
            ['averagefuelefficiency', 'co2emissionsfactor'].every(co2Metric => co2Metric !== metric)
          )
          .map(metric => {
            return {
              [metric]: {
                NameMapping: metric
              }
            };
          })
          .reduce((a, c) => ({ ...a, ...c }), {})
      },
      OnSubmitTrackEventPropsFunc: submitValue => {
        return {
          vehicleTypeName: submitValue?.name,
          speedLimitClass: submitValue?.speedLimitClass,
          ...Object.keys(vehicleTypeMetrics)
            .filter(metric =>
              ['averagefuelefficiency', 'co2emissionsfactor'].every(
                co2Metric => co2Metric !== metric
              )
            )
            .map(metric => ({
              [metric]:
                submitValue && Number(submitValue[metric]) ? Number(submitValue[metric]) : -10
            }))
            .reduce((a, c) => ({ ...a, ...c }), {})
        };
      }
    },
    Alerts: {
      sendAlerstUpdateEvent: (submitValue, typeOfUpdate) => {
        try {
          const notificationEntity =
            submitValue?.entityType && submitValue?.entityType.replace(/^Ng::/, '');
          if (notificationEntity) {
            const alertType = submitValue?.type && submitValue?.type.replace(/^Ng::/, '');
            const notificationTypes = ['email', 'onscreen', 'sms'].filter(
              option => !!submitValue?.notificationTypes && submitValue.notificationTypes[option]
            );
            const alertActive = !!submitValue?.active;
            const eventProps = {
              typeOfUpdate,
              alertType,
              alertActive,
              notificationTypes
            };
            Mixpanel.sendTrackEvent(`${notificationEntity} Alerts Update`, eventProps);
          }
        } catch (error) {}
      }
    }
  },
  Help: {
    AskforHelp: 'Ask for Help',
    HelpType: {
      HelpCenter: 'Help Center',
      SupportCenter: 'Support Center',
      ShowMeHow: 'Show Me How'
    }
  },
  General: {
    DateRange: {
      DateRangeSelected: 'Date Range Selected',
      DateRangeProps: {
        GetDateRangeOption: dateRange => {
          const options = {
            Today: [moment().startOf('day'), moment().endOf('day')],
            Yesterday: [
              moment()
                .subtract(1, 'day')
                .startOf('day'),
              moment()
                .subtract(1, 'day')
                .endOf('day')
            ],
            WeekToDate: [moment().startOf('week'), moment().endOf('day')],
            Past7Days: [
              moment()
                .subtract(6, 'day')
                .startOf('day'),
              moment().endOf('day')
            ],
            Past14Days: [
              moment()
                .subtract(13, 'day')
                .startOf('day'),
              moment().endOf('day')
            ],
            LastMonth: [
              moment()
                .subtract(1, 'month')
                .startOf('month'),
              moment()
                .subtract(1, 'month')
                .endOf('month')
            ]
          };

          let presetRange = 'Custom';
          for (const option in options) {
            try {
              if (
                dateRange[0].isSame(options[option][0], 'day') &&
                dateRange[1].isSame(options[option][1], 'day')
              ) {
                presetRange = option;
                break;
              }
            } catch (error) {
              console.log(error);
            }
          }
          return presetRange;
        }
      }
    }
  },
  Messaging: {
    SendMessage: 'Send Message',
    MessageType: {
      Normal: 'Normal',
      Alert: 'Alert',
      RouteTo: 'RouteTo'
    }
  }
};
