import moment from 'moment';
import forge from 'node-forge';
import { NEXTGEN_BASE_URL } from '../../config';

export class AuthUtil {
  static computeHMAC(secretKey, rawStr) {
    const hmac = forge.hmac.create();
    hmac.start('sha1', secretKey);
    hmac.update(rawStr);
    const hmac_raw = hmac.digest().toHex();
    return forge.util.encode64(hmac_raw);
  }

  static generateEWDHMAC(secretKey, rawStr) {
    const hmac = forge.hmac.create();
    hmac.start('sha256', secretKey);
    hmac.update(rawStr);
    const hmac_raw = hmac.digest().getBytes();
    return forge.util.encode64(hmac_raw);
  }

  static generateNonce() {
    const nonce = new Uint16Array(8);
    crypto.getRandomValues(nonce);
    return forge.util.encode64(nonce.toString());
  }

  static generateEWDAuthUri(secretKey, userId) {
    const timestamp = Math.floor(moment().valueOf() / 1000);
    const nonce = this.generateNonce();
    const data = `${userId}:${timestamp}:${nonce}`;
    const hmac = encodeURIComponent(this.generateEWDHMAC(secretKey, data));
    const urlPieces = {
      nonce: encodeURIComponent(nonce),
      hmac: encodeURIComponent(hmac)
    };
    return `auth/sso?user_id=${userId}&nonce=${urlPieces.nonce}&timestamp=${timestamp}&hmac=${urlPieces.hmac}&format=jwt&type=user`;
  }

  static generateAuthUrl(userId, userApi) {
    const timestamp = Math.floor(moment().valueOf() / 1000);
    const nonce = new Uint16Array(8);
    crypto.getRandomValues(nonce);
    const data = `${userId}:${timestamp}:${nonce}`;
    const hmac = this.computeHMAC(userApi, data);

    let url =
      NEXTGEN_BASE_URL +
      `auth?mode=tn360&user_id=${userId}&nonce=${nonce}&timestamp=${timestamp}&hmac=${hmac}`;
    return url;
  }
}
