import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/util';

import { API_PATH } from 'config';

export const ngDevicesGpioApi = createApi({
  reducerPath: 'ngDevicesGpioApi',
  tagTypes: ['GPIO'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  endpoints: builder => ({
    getDeviceGPIO: builder.query({
      query: ({ id }) => `devices/${id}/gpio`,
      providesTags: ['GPIO']
    }),
    updateDeviceGPOutput: builder.mutation({
      query: ({ imei, body }) => ({
        url: `devices/${imei}/gpio/output`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['GPIO']
    })
  })
});

export const { useGetDeviceGPIOQuery, useUpdateDeviceGPOutputMutation } = ngDevicesGpioApi;
