import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';
import { sortBy } from 'lodash';
import { API_PATH } from 'config';
import request from 'superagent';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18next from 'i18next';
import { canHistoryGoBack } from 'utils/methods';
import { parseErrorMessage } from 'utils/strings';
import { DEFAULT_DRIVER_FORM_FIELD_NAME } from 'containers/Administration/Vehicles/DefaultDriver/constants';

// Endpoint URLS
export const FLEETS_URL = '/fleets';

const deletedFleets = {
  list: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    success: false,
    isListEmpty: false,
    companyKey: null
  }
};

const fleets = {
  list: [],
  fleetsBoth: [], //fleets with direction=BOTH
  vehiclesFromFleetsBoth: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    success: false,
    isListEmpty: false,
    companyKey: null,
    fleetsBothLastFetched: null,
    isFleetsBothFetching: false,
    fleetsBothError: null,
    fleetsBothSuccess: false,
    isFleetsBothEmpty: false,
    isFetchingSpecificedFleet: false
  },
  specificFleet: {},
  specificFleetId: null,
  fleetsByCompanyId: {}
};

const vehicles = {
  list: [],
  vehicles: {},
  meta: {
    lastFetched: null,
    isFetching: false,
    isListEmpty: false,
    error: null,
    companyKey: null
  }
};

const devices = {
  list: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    isListEmpty: false,
    error: null,
    companyKey: null
  }
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function startLoadingFleetsBoth(state) {
  state.meta.isFleetsBothFetching = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.success = true;
  state.meta.isListEmpty = true;
  state.list = [];
  state.meta.companyKey = action.payload.companyKey;
}

function loadingFleetsBothFailed(state, action) {
  state.meta.isFleetsBothFetching = false;
  state.meta.fleetsBothLastFetched = 'now';
  state.meta.fleetsBothError = action.payload.err;
  state.meta.fleetsBothSuccess = true;
  state.meta.isFleetsBothEmpty = true;
  state.fleetsBoth = [];
  state.meta.companyKey = action.payload.companyKey;
}

function loadingSingleVehicleFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.companyKey = action.payload.companyKey;
}

export function extractVehiclesMap(fleetsList) {
  const vehicles = {};
  fleetsList.forEach(fleet => {
    const fleetInfo = { id: fleet.id, name: fleet.name, companyId: fleet?.company?.id };
    for (const fV of fleet.vehicles) {
      const existingVehicle = vehicles[fV.id];
      if (existingVehicle) {
        existingVehicle.fleets.push(fleetInfo);
        continue;
      } else {
        const fleetVehicle = Object.assign({}, fV);
        vehicles[fleetVehicle.id] = fleetVehicle;
        if (fleetVehicle.devices) {
          fleetVehicle.devices = fleetVehicle.devices.map(device => ({
            ...device,
            fleet: fleetInfo
          }));
        }

        fleetVehicle.fleets = [fleetInfo];
      }
    }
  });
  return vehicles;
}

export function extractVehicles(fleetsList) {
  const vehicles = extractVehiclesMap(fleetsList);
  return Object.values(vehicles).sort((a, b) => a.name?.localeCompare(b.name));
}

export function extractDevicesMap(fleetsList) {
  const devices = {};
  fleetsList.forEach(fleet => {
    const fleetObject = { id: fleet.id, name: fleet.name };
    if (fleet.vehicles) {
      fleet.vehicles.forEach(vehicle => {
        const vehicleInfo = { id: vehicle.id, name: vehicle.name };
        if (vehicle.devices) {
          vehicle.devices.forEach(device => {
            const existingDevice = devices[device.id];
            if (existingDevice) {
              const fleetAlreadyExists = existingDevice.fleetInfo.some(
                fleet => fleet.id === fleetObject.id
              );
              if (!fleetAlreadyExists) {
                existingDevice.fleetInfo.push(fleetObject);
              }
            } else {
              devices[device.id] = { ...device, fleetInfo: [fleetObject], vehicleInfo };
            }
          });
        }
      });
    }
  });
  return devices;
}

export function extractDevices(fleetsList) {
  const devices = extractDevicesMap(fleetsList);
  return Object.values(devices).sort((a, b) => a.name?.localeCompare(b.name));
}

const fleetsSlice = createSlice({
  name: 'fleets',
  initialState: fleets,
  reducers: {
    fetchFleetsStart(state, { payload }) {
      state.meta.companyKey = payload.companyKey;
      state.meta.isFetching = true;
    },
    fetchFleetsBothStart: startLoadingFleetsBoth,
    fetchFleetsSuccess(state, { payload }) {
      state.list = payload.list.sort((a, b) => a.name?.localeCompare(b.name));
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.success = true;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchFleetsBothSuccess(state, { payload }) {
      state.fleetsBoth = payload.list.sort((a, b) => a.name?.localeCompare(b.name));
      state.vehiclesFromFleetsBoth = extractVehicles(state.fleetsBoth);
      state.meta.isFleetsBothFetching = false;
      state.meta.fleetsBothLastFetched = 'now';
      state.meta.fleetsBothError = null;
      state.meta.fleetsBothSuccess = true;
      state.meta.isFleetsBothEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchFleetDataStart(state, { payload }) {
      state.meta.isFetchingSpecificedFleet = true;
    },
    fetchFleetDataSuccess(state, { payload }) {
      state.specificFleet[payload.id] = payload;
      state.specificFleetId = payload.id;
      state.meta.isFetchingSpecificedFleet = false;
    },
    fetchFleetDataFailure(state, { payload }) {
      state.meta.isFetchingSpecificedFleet = false;
    },
    removeIdFleetData(state, { payload }) {
      delete state.specificFleet[payload];
    },
    fetchFleetsFailure: loadingFailed,
    fetchFleetsBothFailure: loadingFleetsBothFailed,
    fetchFleetsCancelled(state, { payload }) {
      state.meta.isFetching = false;
      state.meta.error = false;
      state.meta.lastFetched = null;
      state.meta.success = null;
    },
    fetchFleetsByCompanyIdStart(state, { payload }) {
      state.fleetsByCompanyId[payload.companyId] = state.fleetsByCompanyId[payload.companyId] || {};
      state.fleetsByCompanyId[payload.companyId].isFetching = true;
      state.fleetsByCompanyId[payload.companyId].embed = payload.embed;
      state.fleetsByCompanyId[payload.companyId].direction = payload.direction;
      state.fleetsByCompanyId[payload.companyId].data = null;
    },
    fetchFleetsByCompanyIdSuccess(state, { payload }) {
      state.fleetsByCompanyId[payload.companyId].isFetching = false;
      state.fleetsByCompanyId[payload.companyId].data = payload.data || [];
      state.fleetsByCompanyId[payload.companyId].error = null;
    },
    fetchFleetsByCompanyIdFailure(state, { payload }) {
      state.fleetsByCompanyId[payload.companyId].isFetching = false;
      state.fleetsByCompanyId[payload.companyId].data = [];
      state.fleetsByCompanyId[payload.companyId].error = payload.error;
    }
  }
});

const fetchFleetsByCompanyId = (companyId, direction = 'UP', embed = 'vehicles,devices') => async (
  dispatch,
  getState
) => {
  const userKey = getState().user.current.auth.key;
  try {
    if (!companyId || !userKey || getState().fleets.fleetsByCompanyId?.[companyId]?.isFetching) {
      return;
    }
    dispatch(fetchFleetsByCompanyIdStart({ companyId, embed, direction }));
    return request(
      'GET',
      `${API_PATH}/fleets?direction=${direction}${
        embed ? `&embed=${embed}` : ''
      }&company_id=${companyId}`
    )
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(resp => {
        const data = resp.body.sort((a, b) =>
          (a.name || '').toUpperCase() > (b.name || '').toUpperCase() ? 1 : -1
        );
        dispatch(fetchFleetsByCompanyIdSuccess({ data, companyId }));
        return data;
      })
      .catch(error => {
        dispatch(fetchFleetsByCompanyIdFailure({ error, companyId }));
      });
  } catch (error) {
    dispatch(fetchFleetsByCompanyIdFailure({ error, companyId }));
  }
};

export const useFleeetsByCompanyId = ({
  companyId,
  direction = 'UP',
  embed = 'vehicles,devices'
}) => {
  const dispatch = useDispatch();
  const companyFleetsData = useSelector(state => state.fleets.fleetsByCompanyId[companyId]);
  const isFetching = useMemo(() => !companyFleetsData || companyFleetsData.isFetching, [
    companyFleetsData
  ]);
  if (
    !companyFleetsData ||
    (!companyFleetsData.isFetching &&
      ((!companyFleetsData.data && !companyFleetsData.error) ||
        companyFleetsData.embed !== embed ||
        companyFleetsData.direction !== direction))
  ) {
    dispatch(fetchFleetsByCompanyId(companyId, direction, embed));
  }
  return {
    isFetching,
    fleets: companyFleetsData?.data,
    vehicles: extractVehicles(companyFleetsData?.data || []).filter(
      vehicle => vehicle.id && String(vehicle.companyId) === String(companyId)
    )
  };
};

const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState: vehicles,
  reducers: {
    fetchVehiclesStart: startLoading,
    fetchVehiclesSucces(state, { payload }) {
      const fleets = sortBy(payload.list, [
        function(o) {
          return o.name;
        }
      ]);
      const vehicleList = extractVehicles(fleets);
      state.list = vehicleList;
      state.meta.isFetching = false;
      state.meta.isListEmpty = vehicleList.length === 0;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.companyKey = payload.companyKey;
    },
    fetchVehiclesFailure: loadingFailed,
    fetchSingleVehicleStart: startLoading,
    fetchSingleVehicleSuccess(state, { payload }) {
      //API doesn't return defaultDriver after it's been removed, need to overwrite previous value
      state.vehicles[payload?.id] = {
        ...state.vehicles[payload?.id],
        ...payload.vehicle,
        [DEFAULT_DRIVER_FORM_FIELD_NAME]: payload?.vehicle?.[DEFAULT_DRIVER_FORM_FIELD_NAME],
        lastFetchedMeta: { embed: payload?.embed }
      };
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
    },
    fetchSingleVehicleFailure: loadingSingleVehicleFailed
  }
});

const devicesSlice = createSlice({
  name: 'devices',
  initialState: devices,
  reducers: {
    fetchDevicesStart: startLoading,
    fetchDevicesSuccess(state, { payload }) {
      const fleets = sortBy(payload.list, [
        function(o) {
          return o.name;
        }
      ]);
      const devicesList = extractDevices(fleets);
      state.list = devicesList;
      state.meta.isFetching = false;
      state.meta.isListEmpty = devicesList.length === 0;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.success = true;
      state.meta.companyKey = payload.companyKey;
    },
    fetchDevicesFailure: loadingFailed
  }
});

const deletedFleetsSlice = createSlice({
  name: 'deletedFleets',
  initialState: deletedFleets,
  reducers: {
    fetchDeletedFleetsStart: startLoading,
    fetchDeletedFleetsSuccess(state, { payload }) {
      state.list = payload.list.sort((a, b) => a.name?.localeCompare(b.name));
      state.meta.isFetching = false;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.success = true;
      state.meta.companyKey = payload.companyKey;
    },
    fetchDeletedFleetsFailure: loadingFailed
  }
});

export const {
  fetchFleetsStart,
  fetchFleetsBothStart,
  fetchFleetsSuccess,
  fetchFleetsBothSuccess,
  fetchFleetDataStart,
  fetchFleetDataSuccess,
  fetchFleetDataFailure,
  removeIdFleetData,
  fetchFleetsFailure,
  fetchFleetsBothFailure,
  fetchFleetsCancelled,
  fetchFleetsByCompanyIdStart,
  fetchFleetsByCompanyIdSuccess,
  fetchFleetsByCompanyIdFailure
} = fleetsSlice.actions;

export const {
  fetchVehiclesStart,
  fetchVehiclesSucces,
  fetchVehiclesFailure,
  fetchSingleVehicleFailure,
  fetchSingleVehicleStart,
  fetchSingleVehicleSuccess
} = vehiclesSlice.actions;

export const {
  fetchDeletedFleetsStart,
  fetchDeletedFleetsSuccess,
  fetchDeletedFleetsFailure
} = deletedFleetsSlice.actions;

export const { fetchDevicesStart, fetchDevicesSuccess, fetchDevicesFailure } = devicesSlice.actions;

export const fetchFleets = (
  embed = 'vehicles,devices,services,gpioTemplates',
  onFetchError,
  requestRef
) => async (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  const userKey = getState().user.current.auth.key;
  const companyId = getState().companies.current.id;
  try {
    if (!companyKey || !companyId || !userKey) {
      return;
    }
    if (getState().fleets.meta.isFetching) {
      return;
    }
    dispatch(fetchFleetsStart({ companyKey }));
    dispatch(fetchDevicesStart());
    dispatch(fetchVehiclesStart());

    const fleetsReq = request('GET', `${API_PATH}/fleets`)
      .query({
        embed: embed,
        pruning: 'ALL',
        direction: 'DOWN',
        company_id: companyId
      })
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json');

    if (requestRef) {
      requestRef.current = {
        fleetsReq
      };
    }

    fleetsReq.on('abort', () => {
      if (requestRef?.current) {
        requestRef.current.fleetsReq = null;
      }
      dispatch(fetchFleetsCancelled({ err: 'Aborted' }));
    });

    try {
      const resp = await fleetsReq;
      const { body } = resp;
      dispatch(
        fetchFleetsSuccess({
          list: body,
          userKey: userKey,
          companyKey: companyKey
        })
      );
      dispatch(
        fetchVehiclesSucces({
          list: body,
          userKey: userKey,
          companyKey: companyKey
        })
      );
      dispatch(
        fetchDevicesSuccess({
          list: body,
          userKey: userKey,
          companyKey: companyKey
        })
      );
    } catch (err) {
      if (onFetchError) {
        onFetchError(err.err ? err.err?.toString() : err?.toString());
      }
      dispatch(fetchFleetsFailure({ err: err.toString(), companyKey }));
      dispatch(fetchVehiclesFailure({ err: err.toString(), companyKey }));
      dispatch(fetchDevicesFailure({ err: err.toString(), companyKey }));
    }
  } catch (err) {
    if (onFetchError) {
      onFetchError(err.err ? err.err?.toString() : err?.toString());
    }
    dispatch(fetchFleetsFailure({ err: err.toString(), companyKey }));
    dispatch(fetchVehiclesFailure({ err: err.toString(), companyKey }));
    dispatch(fetchDevicesFailure({ err: err.toString(), companyKey }));
  }

  if (requestRef?.current) {
    requestRef.current.fleetsReq = null;
  }
};

export const fetchDeletedFleets = onFetchError => async (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  const userKey = getState().user.current.auth.key;
  const companyId = getState().companies.current.id;
  try {
    if (!companyKey || !companyId || !userKey) {
      return;
    }
    if (getState().deletedFleets.meta.isFetching) {
      return;
    }
    dispatch(fetchDeletedFleetsStart({ companyKey }));

    const deletedFleetsReq = request('GET', `${API_PATH}/fleets`)
      .query({
        status: 'DELETED',
        embed: 'vehicles,devices',
        pruning: 'ALL',
        direction: 'DOWN',
        company_id: companyId
      })
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json');

    try {
      const resp = await deletedFleetsReq;
      const { body } = resp;

      dispatch(
        fetchDeletedFleetsSuccess({
          list: body,
          companyKey: companyKey
        })
      );
    } catch (err) {
      dispatch(fetchDeletedFleetsFailure({ err: err.toString(), companyKey }));
    }
  } catch (err) {
    if (onFetchError) {
      onFetchError(err.err ? err.err?.toString() : err?.toString());
    }
    dispatch(fetchDeletedFleetsFailure({ err: err.toString(), companyKey }));
  }
};

export const fetchFleetsBoth = (direction = 'ANCESTOR') => (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies.current.id;
  const userKey = getState().user.current.auth.key;
  try {
    if (!companyKey || !userKey || !companyId) {
      return;
    }
    if (getState().fleets.meta.isFleetsBothFetching) {
      return;
    }
    dispatch(fetchFleetsBothStart());
    request(
      'GET',
      `${API_PATH}/fleets?direction=${direction}&embed=vehicles&company_id=${companyId}`
    )
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(res => {
        dispatch(fetchFleetsBothSuccess({ list: res.body, companyKey }));
      })
      .catch(err => {
        dispatch(fetchFleetsBothFailure({ err: err.toString(), companyKey }));
        console.warn('ERROR', err);
      });
  } catch (err) {
    dispatch(fetchFleetsBothFailure({ err: err.toString(), companyKey }));
  }
};

const SINGLE_VEHICLE_EMEBED = 'maintenance_info,vehicle_assets,image_url,document,source';
export const fetchSingleVehicle = (
  id,
  embed = SINGLE_VEHICLE_EMEBED,
  deviceTypes = '',
  onError,
  onNotFound
) => async (dispatch, getState) => {
  try {
    const userKey = getState().user?.current?.auth?.key;
    if (!userKey) {
      return;
    }
    dispatch(fetchSingleVehicleStart());

    const res = await api.get(`/vehicles/${id}`, {
      authKey: userKey,
      query: {
        embed: embed,
        ...(deviceTypes ? { device_types: deviceTypes } : {})
      }
    });
    if (res.status === 200) {
      dispatch(fetchSingleVehicleSuccess({ vehicle: res.body, id, embed }));
    } else {
      console.error(res);
      batch(() => {
        dispatch(fetchSingleVehicleFailure({ err: res.statusCode }));
        if (onNotFound) {
          onNotFound();
        }
      });
    }
  } catch (err) {
    batch(() => {
      dispatch(fetchSingleVehicleFailure({ err: err.toString() }));
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${i18next.t('Roles.ToastMessages.ServerReturnedError')} ${err}`
        })
      );
      if (onError) {
        onError();
      }
    });
  }
};

const fleetsReducers = {
  fleets: fleetsSlice.reducer,
  vehicles: vehiclesSlice.reducer,
  devices: devicesSlice.reducer,
  deletedFleets: deletedFleetsSlice.reducer
};

export default fleetsReducers;

export const useFleets = embed => {
  const dispatch = useDispatch();
  const fleets = useFleetsList();
  const isFetching = useIsFetching();
  const lastFetched = useSelector(state => state.fleets.meta.lastFetched);
  const lastFetchFailed = useSelector(state => state.fleets.meta.error);
  const companyKey = useCompanyKey();
  const currentCompanyKey = useCurrentCompanyKey();
  const couldFetch = !isFetching && ((lastFetched && lastFetchFailed) || !lastFetched);
  const isCompanyKeyDifferent = currentCompanyKey && companyKey !== currentCompanyKey;
  const fetchRef = useRef(null);

  useEffect(() => {
    if ((couldFetch || isCompanyKeyDifferent) && !fetchRef.current) {
      dispatch(fetchFleets(embed, null, fetchRef));
    }
  }, [dispatch, embed, isCompanyKeyDifferent, couldFetch]);

  useEffect(() => {
    return () => {
      if (fetchRef.current != null && !!companyKey) {
        fetchRef.current.fleetsReq?.abort();
        fetchRef.current = null;
      }
    };
  }, [companyKey]);

  return fleets;
};

export const useDeletedFleets = () => {
  const dispatch = useDispatch();
  const fleetDeleted = useSelector(state => state.deletedFleets.list);
  const isFetching = useSelector(state => state.deletedFleets.meta.isFetching);
  const isListEmpty = useSelector(state => state.deletedFleets.meta.isListEmpty);
  const companyKey = useCompanyKey();
  const currentCompanyKey = useCurrentCompanyKey();
  if (
    companyKey !== currentCompanyKey ||
    (fleetDeleted.length === 0 && !isFetching && !isListEmpty)
  ) {
    dispatch(fetchDeletedFleets());
  }
  return fleetDeleted;
};

export const useFleetsBoth = direction => {
  const dispatch = useDispatch();
  const fleetsBoth = useSelector(state => state.fleets.fleetsBoth);
  const isFetching = useIsFleetsBothFetching();
  const isListEmpty = useIsFleetsBothEmpty();
  const companyKey = useCompanyKey();
  const currentCompanyKey = useCurrentCompanyKey();

  if (
    companyKey !== currentCompanyKey ||
    (fleetsBoth.length === 0 && !isFetching && !isListEmpty)
  ) {
    dispatch(fetchFleetsBoth(direction));
  }

  return fleetsBoth;
};

export const useVehiclesFromFleetsBoth = () => {
  const dispatch = useDispatch();
  const fleetsBoth = useSelector(state => state.fleets.fleetsBoth);
  const vehiclesFromFleetsBoth = useSelector(state => state.fleets.vehiclesFromFleetsBoth);
  const isFetching = useIsFleetsBothFetching();
  const isListEmpty = useIsFleetsBothEmpty();
  const companyKey = useCompanyKey();
  const currentCompanyKey = useCurrentCompanyKey();

  useEffect(() => {
    if (
      companyKey !== currentCompanyKey ||
      (fleetsBoth.length === 0 && !isFetching && !isListEmpty)
    ) {
      dispatch(fetchFleetsBoth());
    }
  }, [companyKey, currentCompanyKey, fleetsBoth.length, isFetching, isListEmpty, dispatch]);

  return vehiclesFromFleetsBoth;
};

export const useVehicles = handleFetchError => {
  const dispatch = useDispatch();
  const vehicles = useSelector(state => state.vehicles.list);
  const fleets = useFleetsList();
  const companyKey = useSelector(state => state.fleets.meta.companyKey);
  const isFleetsFetching = useIsFetching();
  const isFleetsListEmpty = useIsListEmpty();
  const isVehiclesListEmpty = useSelector(state => state.vehicles.meta.isListEmpty);
  if (vehicles.length === 0 && !isVehiclesListEmpty && !isFleetsFetching) {
    if (fleets.length === 0) {
      if (!isFleetsListEmpty) {
        dispatch(fetchFleets('vehicles,devices,services,gpioTemplates', handleFetchError));
      }
    } else {
      dispatch(fetchVehiclesSucces({ list: fleets, companyKey }));
    }
  }
  return vehicles;
};

export const useVehicle = (id, embed, deviceTypes, onError, onNotFound) => {
  const dispatch = useDispatch();
  const vehicle = useSelector(state => state.vehicles.vehicles[id]);
  const isFetching = useSelector(state => state.vehicles.meta.isFetching);

  const isContain = (embedA, embedB) => {
    try {
      return embedA?.includes(embedB);
    } catch (error) {
      return false;
    }
  };
  const isEmbedChanged =
    (!!vehicle?.lastFetchedMeta?.embed &&
      !embed &&
      vehicle?.lastFetchedMeta?.embed !== SINGLE_VEHICLE_EMEBED) ||
    (!!vehicle?.lastFetchedMeta?.embed &&
      !!embed &&
      vehicle?.lastFetchedMeta?.embed !== embed &&
      !isContain(vehicle?.lastFetchedMeta?.embed, embed));

  if (!id || id === 'newVehicle') {
    return {};
  }
  if (!isFetching && (!vehicle || Object.keys(vehicle).length === 0)) {
    dispatch(fetchSingleVehicle(id, embed, deviceTypes, onError, onNotFound));
  } else if (!isFetching && isEmbedChanged) {
    dispatch(fetchSingleVehicle(id, embed, deviceTypes, onError, onNotFound));
  }
  return vehicle;
};

export const useDevices = () => {
  const dispatch = useDispatch();
  const devices = useSelector(state => state.devices.list);
  const fleets = useSelector(state => state.fleets.list);
  const companyKey = useSelector(state => state.fleets.meta.companyKey);
  const isFleetsFetching = useSelector(state => state.fleets.meta.isFetching);
  const isFleetsListEmpty = useSelector(state => state.fleets.meta.isListEmpty);
  const isDevicesListEmpty = useSelector(state => state.devices.meta.isListEmpty);

  useEffect(() => {
    if (devices.length === 0 && !isDevicesListEmpty && !isFleetsFetching) {
      if (fleets.length === 0) {
        if (!isFleetsListEmpty) {
          dispatch(fetchFleets());
        }
      } else {
        dispatch(fetchDevicesSuccess({ list: fleets, companyKey }));
      }
    }
  }, [devices, isDevicesListEmpty, isFleetsFetching, isFleetsListEmpty, fleets, companyKey]);

  return devices;
};

export const useFleetViewData = (id, onError) => {
  const dispatch = useDispatch();
  const isFetching = useSelector(state => state.fleets.meta.isFetchingSpecificedFleet);
  const fleetData = useSelector(state => state.fleets.specificFleet);
  const fleetKeys = Object.keys(fleetData);

  if (id && id > 0 && !fleetKeys.includes(id) && !isFetching) {
    dispatch(fetchFleetDataStart());
    dispatch(fetchSpecificFleetData(id, onError));
  }

  return fleetData[id];
};

const fetchSpecificFleetData = (id, onError) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const urlForFleet = `${FLEETS_URL}/${id}?embed=vehicles,devices&pruning=ALL`;
  if (!authKey) {
    return;
  }
  try {
    const response = await api.get(urlForFleet, { authKey });
    const { body } = response;
    if (response.statusCode !== 200) {
      throw i18next.t('Common.Invalid Request ID');
    }
    dispatch(fetchFleetDataSuccess(body));
  } catch (err) {
    console.error(err);
    batch(() => {
      dispatch(fetchFleetDataFailure());
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${i18next.t('Roles.ToastMessages.ServerReturnedError')} ${err}`
        })
      );
      if (onError) {
        onError();
      }
    });
  }
};

export const addFleet = (data, authKey) => async (dispatch, getState) => {
  if (!authKey) {
    return;
  }
  try {
    const uploadResponse = await api.post(
      FLEETS_URL,
      {
        authKey
      },
      data
    );

    if (!uploadResponse.ok) {
      return [false, 'Something went wrong. Please try again later.'];
    } else {
      // Fetch fleet
      dispatch(fetchFleets());
      dispatch(fetchFleetsBoth());

      return [true, null];
    }
  } catch (err) {
    return [false, err];
  }
};

export const updateFleet = async (id, data, authKey, dispatch) => {
  const urlForUpdateFleet = `${FLEETS_URL}/${id}`;
  if (!authKey) {
    return;
  }
  try {
    const uploadResponse = await api.put(
      urlForUpdateFleet,
      {
        authKey
      },
      data
    );

    if (!uploadResponse.ok) {
      return [false, 'Something went wrong. Please try again later.'];
    } else {
      dispatch(removeIdFleetData(id));
      // Fetch fleet
      dispatch(fetchFleets());
      dispatch(fetchFleetsBoth());
      return [true, null];
    }
  } catch (err) {
    return [false, err];
  }
};

export const deleteFleetApi = (fleet, history) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;

  try {
    const response = await api.delete(`${FLEETS_URL}/${fleet.id}`, { authKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('Fleets.Notifications.FleetDeletedSucces', {
            name: fleet?.name
          })
        })
      );
      dispatch(fetchFleets());
      dispatch(fetchDeletedFleets());
      dispatch(removeIdFleetData(fleet.id));
      history && canHistoryGoBack(history, '/settings/fleets');
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18next.t('Fleets.Notifications.FleetDeletedError', {
          name: fleet?.name
        })}: ${parseErrorMessage(err)}`
      })
    );
  }
};

export const restoreFleetApi = data => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const { id, name } = data;
  const url = `${API_PATH}${FLEETS_URL}/${id}/restore`;
  request('PUT', url)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(resp => {
      if (resp.ok) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18next.t('Fleets.Notifications.FleetRestored', { name })
          })
        );
        dispatch(fetchFleets());
        dispatch(fetchDeletedFleets());
        dispatch(removeIdFleetData(id));
      }
    })
    .catch(err => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
      );
    });
};

export const useCompanyKey = () => useSelector(state => state.fleets.meta.companyKey);
export const useIsFetching = () => useSelector(state => state.fleets.meta.isFetching);
export const useIsFetchingDevice = () => useSelector(state => state.devices.meta.isFetching);
export const useIsFetchingFinished = () =>
  useSelector(state => state.fleets.meta.error || state.fleets.meta.success);
export const useIsListEmpty = () => useSelector(state => state.fleets.meta.isListEmpty);
export const useFleetsList = () => useSelector(state => state.fleets.list);
const useIsFleetsBothFetching = () => useSelector(state => state.fleets.meta.isFleetsBothFetching);
const useIsFleetsBothEmpty = () => useSelector(state => state.fleets.meta.isFleetsBothEmpty);

export const useVehiclesFromFleets = () => {
  //Can not useVehicles, otherwise there will be a state inconsistency between useVehicles and useIsFetchingFleets
  const fleets = useFleets();
  const isFetchingVehicles = useIsFetching();
  const vehicles = useMemo(() => {
    return extractVehicles(
      sortBy(fleets, [
        function(o) {
          return o.name;
        }
      ])
    );
  }, [fleets]);
  return { isFetchingVehicles, vehicles };
};

export const useIsFetchingDevicesFinished = () =>
  useSelector(state => state.devices.meta.error || state.devices.meta.success);
