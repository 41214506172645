import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretDown, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/fontawesome-free-regular';
import styles from './TreeSelect.module.scss';
import { cloneDeep } from 'lodash';

const TreeSelectIcon = props => <FontAwesomeIcon className={styles.treeSelectIcon} {...props} />;
const tick = <TreeSelectIcon className={styles.treeSelectIconChecked} icon={faCheckSquare} />;
const cross = <TreeSelectIcon className={styles.treeSelectIcon} icon={faSquare} />;

export const selectAll = {
  id: 0,
  function: 'SelectAll',
  name: 'Select All',
  label: 'Select All'
};

export const PATH_SPLIT = '%#';

export const formatNodesForTreeSelect = (list, parentId = '') => {
  let updatedList = {};
  for (const id in list) {
    if (list.hasOwnProperty(id)) {
      updatedList[id] = list[id];
      updatedList[id].nodeKey = parentId + id;
      if (updatedList[id].children) {
        updatedList[id].children = formatNodesForTreeSelect(
          updatedList[id].children,
          updatedList[id].nodeKey + PATH_SPLIT
        );
      }
    }
  }
  return updatedList;
};

export const checkedListItems = (list, returnList = []) => {
  for (const id in list) {
    if (list.hasOwnProperty(id)) {
      if (list[id].checked && list[id].function === undefined) {
        returnList.push(list[id]);
      }
      if (list[id].children) {
        checkedListItems(list[id].children, returnList);
      }
    }
  }
  return returnList;
};

const TreeSelect = ({
  tree,
  onChange,
  showSelectAll,
  clearSelected,
  setClearSelected,
  ...props
}) => {
  const updateNode = (node, tree) => {
    const nodePath = node.nodeKey.split(PATH_SPLIT);
    const branch = JSON.parse(JSON.stringify(tree)); // copy and unfreeze
    updateBranch(branch, nodePath);
    return branch;
  };

  const checkAllNodes = (branch, checkState) => {
    for (const id in branch) {
      if (branch.hasOwnProperty(id)) {
        let node = branch[id];
        let checkedPropDesc = Object.getOwnPropertyDescriptor(node, 'checked');
        if ((checkedPropDesc == null && Object.isExtensible(node)) || checkedPropDesc?.writable) {
          // todo: guarded against non-writable prop ex.  debug clear filter issue on tracking.
          node.checked = checkState;
        }
        if (node.children) {
          checkAllNodes(node.children, checkState);
        }
      }
    }
  };

  const updateSelectAll = branch => {
    let selectAllId = null;
    let selectAllChecked = [];
    for (const id in branch) {
      if (branch.hasOwnProperty(id)) {
        const node = branch[id];
        if (node.function === 'SelectAll') {
          selectAllId = id;
          continue;
        }
        selectAllChecked.push(node.checked);
      }
    }
    if (selectAllId) {
      branch[selectAllId].checked = selectAllChecked.every(c => c);
    }
  };

  const updateBranch = (branch, nodePath) => {
    const id = nodePath.shift();
    if (!branch[id]) {
      return;
    }
    const node = branch[id];

    if (node.function === 'SelectAll') {
      node.checked = !node.checked;
      checkAllNodes(branch, node.checked);
      return;
    }

    if (node.children) {
      node.expanded = !node.expanded;
      if (nodePath.length) {
        node.expanded = true;
        updateBranch(node.children, nodePath);
      }
      return;
    }
    if (!node.children) {
      node.checked = !node.checked;
      updateSelectAll(branch);
    }
  };

  const onClickItem = (e, node) => {
    onChange(updateNode(node, tree));
  };

  const BuildTree = ({ subtree }) => {
    let list = [];
    if (!subtree) {
      return list;
    }

    for (const nodeId in subtree) {
      if (subtree.hasOwnProperty(nodeId)) {
        const node = subtree[nodeId];

        let item = (
          <li key={node.nodeKey}>
            <span onClick={e => onClickItem(e, node)}>
              {node.checked && !node.children ? tick : cross}
              {node.label}
            </span>
          </li>
        );

        if (node.children && Object.entries(node.children).length > 0) {
          item = (
            <li key={node.nodeKey}>
              <span onClick={e => onClickItem(e, node)}>
                <TreeSelectIcon icon={faCaretRight} />
                {node.label}
              </span>
            </li>
          );
          if (node.expanded) {
            const children = Object.keys(node.children)
              .sort()
              .reduce((result, key) => {
                result[key] = node.children[key];
                return result;
              }, {});
            item = (
              <li key={node.nodeKey}>
                <span onClick={e => onClickItem(e, node)}>
                  <TreeSelectIcon icon={faCaretDown} />
                  {node.label}
                </span>
                <BuildTree subtree={children} />
              </li>
            );
          }
        }

        list.push(item);
      }
    }

    return <ul className={styles.treeSelectUl}>{list}</ul>;
  };

  if (clearSelected) {
    const newTree = cloneDeep(tree);
    checkAllNodes(newTree, false);
    onChange(newTree);
    setClearSelected(false);
  }

  return <BuildTree subtree={tree} />;
};

export default TreeSelect;
