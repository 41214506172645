/* eslint-disable */
import * as jQuery from 'jquery';
import moment from 'moment-timezone';
import * as d3 from 'd3/d3.min.js';

/**
 * Created by agough on 9/09/14. core
 */

/** TOOLTIPSY START */

/* tooltipsy by Brian Cray
 * Lincensed under GPL2 - http://www.gnu.org/licenses/gpl-2.0.html
 * Option quick reference:
 * - alignTo: "element" or "cursor" (Defaults to "element")
 * - offset: Tooltipsy distance from element or mouse cursor, dependent on alignTo setting. Set as array [x, y] (Defaults to [0, -1])
 * - content: HTML or text content of tooltip. Defaults to "" (empty string), which pulls content from target element's title attribute
 * - show: function(event, tooltip) to show the tooltip. Defaults to a show(100) effect
 * - hide: function(event, tooltip) to hide the tooltip. Defaults to a fadeOut(100) effect
 * - delay: A delay in milliseconds before showing a tooltip. Set to 0 for no delay. Defaults to 200
 * - css: object containing CSS properties and values. Defaults to {} to use stylesheet for styles
 * - className: DOM class for styling tooltips with CSS. Defaults to "tooltipsy"
 * - showEvent: Set a custom event to bind the show function. Defaults to mouseenter
 * - hideEvent: Set a custom event to bind the show function. Defaults to mouseleave
 * Method quick reference:
 * - $('element').data('tooltipsy').show(): Force the tooltip to show
 * - $('element').data('tooltipsy').hide(): Force the tooltip to hide
 * - $('element').data('tooltipsy').destroy(): Remove tooltip from DOM
 * More information visit http://tooltipsy.com/
 */
(function(a) {
  a.tooltipsy = function(c, b) {
    this.options = b;
    this.$el = a(c);
    this.title = this.$el.attr('title') || '';
    this.$el.attr('title', '');
    this.random = parseInt(Math.random() * 10000);
    this.ready = false;
    this.shown = false;
    this.width = 0;
    this.height = 0;
    this.delaytimer = null;
    this.$el.data('tooltipsy', this);
    this.init();
  };
  a.tooltipsy.prototype = {
    init: function() {
      var e = this,
        d,
        b = e.$el,
        c = b[0];
      e.settings = d = a.extend({}, e.defaults, e.options);
      d.delay = +d.delay;
      if (typeof d.content === 'function') {
        e.readify();
      }
      if (d.showEvent === d.hideEvent && d.showEvent === 'click') {
        b.toggle(
          function(f) {
            if (d.showEvent === 'click' && c.tagName == 'A') {
              f.preventDefault();
            }
            if (d.delay > 0) {
              e.delaytimer = window.setTimeout(function() {
                e.show(f);
              }, d.delay);
            } else {
              e.show(f);
            }
          },
          function(f) {
            if (d.showEvent === 'click' && c.tagName == 'A') {
              f.preventDefault();
            }
            window.clearTimeout(e.delaytimer);
            e.delaytimer = null;
            e.hide(f);
          }
        );
      } else {
        b.bind(d.showEvent, function(f) {
          if (d.showEvent === 'click' && c.tagName == 'A') {
            f.preventDefault();
          }
          e.delaytimer = window.setTimeout(function() {
            e.show(f);
          }, d.delay || 0);
        }).bind(d.hideEvent, function(f) {
          if (d.showEvent === 'click' && c.tagName == 'A') {
            f.preventDefault();
          }
          window.clearTimeout(e.delaytimer);
          e.delaytimer = null;
          e.hide(f);
        });
      }
    },
    show: function(i) {
      if (this.ready === false) {
        this.readify();
      }
      var b = this,
        f = b.settings,
        h = b.$tipsy,
        k = b.$el,
        d = k[0],
        g = b.offset(d);
      if (b.shown === false) {
        if (
          (function(m) {
            var l = 0,
              e;
            for (e in m) {
              if (m.hasOwnProperty(e)) {
                l++;
              }
            }
            return l;
          })(f.css) > 0
        ) {
          b.$tip.css(f.css);
        }
        b.width = h.outerWidth();
        b.height = h.outerHeight();
      }
      if (f.alignTo === 'cursor' && i) {
        var j = [i.clientX + f.offset[0], i.clientY + f.offset[1]];
        if (j[0] + b.width > a(window).width()) {
          var c = { top: j[1] + 'px', right: j[0] + 'px', left: 'auto' };
        } else {
          var c = { top: j[1] + 'px', left: j[0] + 'px', right: 'auto' };
        }
      } else {
        var j = [
          (function() {
            if (f.offset[0] < 0) {
              return g.left - Math.abs(f.offset[0]) - b.width;
            } else {
              if (f.offset[0] === 0) {
                return g.left - (b.width - k.outerWidth()) / 2;
              } else {
                return g.left + k.outerWidth() + f.offset[0];
              }
            }
          })(),
          (function() {
            if (f.offset[1] < 0) {
              return g.top - Math.abs(f.offset[1]) - b.height;
            } else {
              if (f.offset[1] === 0) {
                return g.top - (b.height - b.$el.outerHeight()) / 2;
              } else {
                return g.top + b.$el.outerHeight() + f.offset[1];
              }
            }
          })()
        ];
      }
      h.css({ top: j[1] + 'px', left: j[0] + 'px' });
      b.settings.show(i, h.stop(true, true));
    },
    hide: function(c) {
      var b = this;
      if (b.ready === false) {
        return;
      }
      if (c && c.relatedTarget === b.$tip[0]) {
        b.$tip.bind('mouseleave', function(d) {
          if (d.relatedTarget === b.$el[0]) {
            return;
          }
          b.settings.hide(d, b.$tipsy.stop(true, true));
        });
        return;
      }
      b.settings.hide(c, b.$tipsy.stop(true, true));
    },
    readify: function() {
      this.ready = true;
      this.$tipsy = a(
        '<div class="tooltipsy-wrapper" id="tooltipsy' +
          this.random +
          '" style="position:fixed;z-index:2147483647;display:none">'
      ).appendTo('body');
      this.$tip = a('<div class="' + this.settings.className + '">').appendTo(this.$tipsy);
      this.$tip.data('rootel', this.$el);
      var c = this.$el;
      var b = this.$tip;
      this.$tip.html(
        this.settings.content != ''
          ? typeof this.settings.content == 'string'
            ? this.settings.content
            : this.settings.content(c, b)
          : this.title
      );
    },
    offset: function(b) {
      return this.$el[0].getBoundingClientRect();
    },
    destroy: function() {
      if (this.$tipsy) {
        this.$tipsy.remove();
        a.removeData(this.$el, 'tooltipsy');
      }
    },
    defaults: {
      alignTo: 'element',
      offset: [0, -1],
      content: '',
      show: function(c, b) {
        b.fadeIn(100);
      },
      hide: function(c, b) {
        b.fadeOut(100);
      },
      css: {},
      className: 'tooltipsy',
      delay: 200,
      showEvent: 'mouseenter',
      hideEvent: 'mouseleave'
    }
  };
  a.fn.tooltipsy = function(b) {
    return this.each(function() {
      new a.tooltipsy(this, b);
    });
  };
})(jQuery);

/** TOOLTIPSY END */

/** HELPER FUNCTIONS START */

var ewd = (function() {
  var ACTIONS = {
    Logon: { type: 'work' },
    Logoff: { type: 'rest' },
    StopWork: { type: 'rest' },
    StartRest: { type: 'rest' },
    StartWork: { type: 'work' },
    StopRest: { type: 'work' },
    EndRest: { type: 'work' },
    StartDrive: { type: 'drive' },
    StopDrive: { type: 'work' },
    work: { type: 'work' },
    drive: { type: 'drive' },
    rest: { type: 'rest' },
    Work: { type: 'work' },
    Drive: { type: 'drive' },
    Rest: { type: 'rest' }
  };

  // This function will transform '2015-01-11 00:00:00+10:00' to '2015-01-11 00:00:00+08:00' (i.e. keeps time the same)
  var copyInTz = function(now, tz) {
    var other = now.clone().tz(tz);
    other.add(now.utcOffset() - other.utcOffset(), 'minutes');

    return other;
  };

  var dateFormat = function(date, timezone) {
    if (!date) return '';

    if (typeof date === 'string') return date.replace('T', ' ').replace(':00.000', '');

    if (typeof date === 'number') {
      date = moment(date);
      if (moment.tz.zone(timezone) != null) date = date.tz(timezone);
    }

    return date.format('YYYY-MM-DD HH:mm:ss');
  };

  var customTimeFormat = function(timezone) {
    return timeFormat(
      [
        [
          timeFormatter('YYYY'),
          function() {
            return true;
          }
        ],
        [
          timeFormatter('MMM'),
          function(d) {
            return d.get('month');
          }
        ],
        [
          timeFormatter('D/M'),
          function(d) {
            return d.get('date') != 1;
          }
        ],
        [
          timeFormatter('D/M'),
          function(d) {
            return d.get('day') && d.get('date') != 1;
          }
        ],
        [
          timeFormatter('HH:00'),
          function(d) {
            return d.get('hours');
          }
        ],
        [
          timeFormatter('HH:mm'),
          function(d) {
            return d.get('minutes');
          }
        ],
        [
          timeFormatter('ss'),
          function(d) {
            return d.get('seconds');
          }
        ]
      ],
      timezone
    );
  };

  var calculateTimeTicks = function(min, max) {
    var hoursDiv = [3, 6, 12, 24];
    var diff = max - min;
    var maxSteps = 24;
    var stepSize = days(1); // default step size

    if (diff <= hours(25)) {
      stepSize = hours(1);
    } else {
      var found = false;

      // Is it divisible by hours ?
      for (var i = 0; i < hoursDiv.length; i++) {
        if (Math.ceil(diff / hours(hoursDiv[i])) <= maxSteps) {
          stepSize = hours(hoursDiv[i]);
          found = true;
          break;
        }
      }

      // Then divide by days
      if (!found) {
        // find out the least about
        var nDays = 1;
        while (Math.ceil(diff / days(nDays)) > maxSteps) {
          nDays = nDays + 1;
        }
        stepSize = days(nDays);
      }
    }

    var value = min;
    var values = [];

    while (value <= max) {
      values.push(value);
      if (stepSize < days(1))
        value = moment(value)
          .add(stepSize / hours(1), 'hours')
          .valueOf();
      else
        value = moment(value)
          .add(stepSize / days(1), 'days')
          .valueOf();
    }
    return values;
  };

  var isInfiniteViolation = function(v) {
    return (
      !v.hasOwnProperty('finish') ||
      v.finish == null ||
      v.finish == 0 ||
      v.finish - moment().valueOf() > days(350)
    );
  };

  var violationClass = function(show, v, now) {
    var cls = 'violation';
    if (v && show) {
      if (v.start) {
        if (v.start < now) cls += ' current';
        if (v.start > now) cls += ' predicted';
      }

      if (!isInfiniteViolation(v) && v.finish < now) cls += ' historical';
    }

    return cls;
  };

  var needToShowPredicted = function(from, to, checkpoint, events) {
    var isResting = false;
    if (events) {
      var lastEvent = events[events.length - 1];
      isResting = ewd.isRest(lastEvent.action);
    }

    return !(checkpoint == null || checkpoint >= to || from - checkpoint > days(3) || isResting);
  };

  var days = function(n) {
    return n * 24 * 1000 * 60 * 60;
  };

  var hours = function(n) {
    return n * 1000 * 60 * 60;
  };

  var minutes = function(n) {
    return n * 1000 * 60;
  };

  var durFormat = function(t, short) {
    if (!t) return '-';

    var day = Math.floor(t / hours(24));
    var hrs = Math.floor((t % hours(24)) / hours(1));
    var min = Math.floor(((t % hours(24)) % hours(1)) / minutes(1));
    var secR = Math.floor(((t % hours(24)) % hours(1)) % minutes(1)) / 1000;

    var s = '';
    if (short) {
      // seconds not shown to avoid confusion with mm:ss
      //        if (day == 0 && hrs == 0 && min == 0 && secR > 0)
      //            s = pad0(secR, 2) + "s";
      if (day > 0) s = day + 'd ';

      if (hrs == 0 && min > 0) s = s + pad0(min, 2) + 'm';
      else if (hrs > 0 && min == 0) s = s + hrs + 'h';
      else {
        if (hrs > 0) s = s + hrs + ':';
        if (min > 0) s = s + pad0(min, 2);
      }
      if (s.length === 0) s = '00m';
    } else {
      //todo: 1 day(s), 1 hr(s)?
      if (day > 0) s = s + day + (day > 1 ? 'days ' : 'day ');
      if (hrs > 0) s = s + hrs + (hrs > 1 ? 'hrs ' : 'hr ');
      if (min > 0) s = s + pad0(min, 2) + (min > 1 ? 'mins ' : 'min ');
      if (secR > 0) s = s + pad0(secR, 2) + (secR > 1 ? 'secs' : 'sec');
      if (s.length === 0) s = '0secs';
    }

    if (s.substr(s.length - 1, 1) == ' ' || s.substr(s.length - 1, 1) == ':')
      return s.substr(0, s.length - 1);

    return s;
  };

  var apiFormat = function(date) {
    if (typeof date == 'string') return date;

    if (typeof date == 'number') date = new Date(date);

    return date.toISOString();
  };

  var restFormat = function(theDate) {
    var month = theDate.getMonth() < 9 ? '0' + (theDate.getMonth() + 1) : theDate.getMonth() + 1;
    var date = theDate.getDate() < 10 ? '0' + theDate.getDate() : theDate.getDate();

    return date + '-' + month + '-' + theDate.getFullYear();
  };

  var duration = function(t) {
    if (!t) return '-';

    var day = Math.floor(t / hours(24));
    var hrs = Math.floor((t % hours(24)) / hours(1));
    var min = Math.floor(((t % hours(24)) % hours(1)) / minutes(1));
    var secR = Math.floor(((t % hours(24)) % hours(1)) % minutes(1)) / 1000;

    var s = '';
    if (day > 0) s = s + day + 'd ';
    if (hrs > 0) s = s + hrs + 'h:';
    if (min > 0) s = s + pad0(min, 2) + 'm';
    if (secR > 0) s = s + pad0(secR, 2) + 's';

    if (s.length == 0) s = '0';

    if (s.substr(s.length - 1, 1) == ' ' || s.substr(s.length - 1, 1) == ':')
      return s.substr(0, s.length - 1);

    return s;
  };

  var dateFormat2 = function(date) {
    if (!date) return '';

    if (typeof date == 'string') return date.replace('T', ' ').replace(':00.000', '');

    if (typeof date == 'number') date = new Date(date);

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return (
      pad0(date.getDate(), 2) +
      '/' +
      pad0(date.getMonth() + 1, 2) +
      '/' +
      date.getFullYear() +
      ' ' +
      pad0(hours, 2) +
      ':' +
      pad0(minutes, 2) +
      ampm
    );
  };

  var durFormat2 = function(t) {
    if (!t) return '-';

    var day = Math.floor(t / hours(24));
    var hrs = Math.floor((t % hours(24)) / hours(1));
    var min = Math.floor(((t % hours(24)) % hours(1)) / minutes(1));
    var secR = Math.floor((((t % hours(24)) % hours(1)) % minutes(1)) / 1000);

    var s = '';
    if (day > 0) hrs = hrs + day * 24;
    s = s + pad0(hrs, 2) + ':' + pad0(min, 2) + ':' + pad0(secR, 2);

    if (s.length === 0) s = '0';
    if (s.substr(s.length - 1, 1) == ' ' || s.substr(s.length - 1, 1) == ':')
      return s.substr(0, s.length - 1);

    return s;
  };

  function timeFormat(formats, timezone) {
    return function(date) {
      date = moment.tz(moment(date), timezone);
      var i = formats.length - 1,
        f = formats[i];
      while (!f[1](date)) f = formats[--i];
      return f[0](date);
    };
  }

  function timeFormatter(format) {
    return function(date) {
      return date.format(format);
    };
  }

  function pad0(s, n) {
    var s1 = '' + s;
    return s1.length < n ? '0'.repeat(Math.max(n - s1.length, -1)) + s1 : s1;
  }

  function isAction(act) {
    return ACTIONS.hasOwnProperty(act);
  }

  function isDrive(act) {
    return act !== null && ACTIONS.hasOwnProperty(act) && ACTIONS[act].type == 'drive';
  }

  function isWork(act) {
    return (
      act !== null &&
      ACTIONS.hasOwnProperty(act) &&
      (ACTIONS[act].type == 'work' || ACTIONS[act].type == 'drive')
    );
  }

  function isRest(act) {
    return act !== null && !isWork(act);
  }

  function eventType(act) {
    return ACTIONS.hasOwnProperty(act) ? ACTIONS[act].type : null;
  }

  return {
    copyInTz: copyInTz,
    dateFormat: dateFormat,
    dateFormat2: dateFormat2,
    customTimeFormat: customTimeFormat,
    calculateTimeTicks: calculateTimeTicks,
    isInfiniteViolation: isInfiniteViolation,
    violationClass: violationClass,
    needToShowPredicted: needToShowPredicted,
    days: days,
    hours: hours,
    minutes: minutes,
    durFormat: durFormat,
    durFormat2: durFormat2,
    apiFormat: apiFormat,
    restFormat: restFormat,
    duration: duration,
    isAction: isAction,
    isDrive: isDrive,
    isWork: isWork,
    isRest: isRest,
    eventType: eventType
  };
})();

/** HELPER FUNCTIONS END */

/** SENTINEL CORE START */

(function($) {
  var DEFAULT_TZ = 'UTC';

  String.prototype.repeat = function(num) {
    return new Array(num + 1).join(this);
  };

  function EWD(element, options) {
    this.$element = $(element);
    this.options = options;
    this.enabled = true;
    this.chart = ewdChart(element, options);
  }

  EWD.prototype = {
    user: function(id) {
      if (!id) return this.$element.data('ewd-userid');

      this.$element.data('ewd-userid', id);
      this.clear();
      return this;
    },
    period: function(from, to, internal) {
      // When setting, assumes from and to is in local (browser) timezone so ENSURE this is the case.
      if (from) this.$element.data('ewd-from', from);

      if (to) this.$element.data('ewd-to', to);

      //clear current data
      this.clear();

      return this;
    },
    ruleset: function(rs) {
      if (!rs) return this.$element.data('ewd-ruleset');

      if (rs !== this.$element.data('ewd-ruleset')) this.options.show_violations = true;

      this.$element.data('ewd-ruleset', rs);
    },
    redraw: function() {
      var user = this.$element.data('ewd-user');
      var from = this.from();
      var to = this.to();

      //console.log("From: " + from + ", To: " + to);

      var userEvents = this.events();
      var userViolations = this.$element.data('ewd-violations');
      var userCheckpoint = this.$element.data('ewd-checkpoint');
      var userStatus = this.$element.data('ewd-status');
      var gps = this.$element.data('ewd-gps');
      const ewdDriversList = this.ewdDrivers();

      if (user && (userEvents || userViolations))
        this.chart({
          user: user,
          events: userEvents,
          violations: userViolations,
          checkpoint: userCheckpoint,
          status: userStatus,
          gps: gps,
          fromDate: from,
          toDate: to,
          ewdDrivers: ewdDriversList
        });

      return this;
    },
    reload: function() {
      var userid = this.$element.data('ewd-userid');
      if (userid) this.user(userid);
      var ruleset = this.$element.data('ewd-ruleset');
      if (ruleset) this.ruleset(ruleset);

      return this;
    },
    clear: function() {
      //clear current data
      this.$element.data('ewd-gps', null);
      this.$element.data('ewd-events', null);
      this.$element.data('ewd-violations', null);
      //this.$element.data("ewd-checkpoint", null);
      //this.$element.data("ewd-ruleset", null);
    },
    clearRuleset: function() {
      if (this.$element.data('ewd-ruleset') != null) {
        this.$element.data('ewd-ruleset', null);
        this.$element.data('ewd-violations', null);
        this.$element.data('ewd-checkpoint', null);
        this.redraw();
      }

      return this;
    },
    options: function() {
      return this.options;
    },
    removeChart: function() {
      this.clear();

      //remove the graph data
      this.$element.children().remove();
    },
    events: function(arg) {
      if (!arg) return this.$element.data('ewd-events');
      this.$element.data('ewd-events', arg);
    },
    checkpoint: function(arg) {
      if (!arg) return this.$element.data('ewd-checkpoint');

      this.$element.data('ewd-checkpoint', arg);
    },
    violations: function(arg) {
      if (!arg) return this.$element.data('ewd-violations');

      this.$element.data('ewd-violations', arg);
    },
    userData: function(arg) {
      if (!arg) return this.$element.data('ewd-user');

      this.$element.data('ewd-user', arg);
    },
    from: function(arg) {
      if (!arg) {
        //var user = this.$element.data("ewd-user");
        var timezone = this.timezone();
        var internalFrom = this.$element.data('ewd-from');

        return ewd.copyInTz(moment(internalFrom), timezone).valueOf();
      }

      this.$element.data('ewd-from', arg);
    },
    to: function(arg) {
      if (!arg) {
        //var user = this.$element.data("ewd-user");
        var timezone = this.timezone();
        var internalTo = this.$element.data('ewd-to');

        return ewd.copyInTz(moment(internalTo), timezone).valueOf();
      }
      this.$element.data('ewd-to', arg);
    },
    timezone: function() {
      var tz = this.userData() == null ? this.options.timezone : this.userData().timeZone;

      if (!this.isSupportedTimezone(tz)) tz = DEFAULT_TZ;

      return tz;
    },
    isSupportedTimezone: function(tz) {
      if (tz == null || typeof tz === 'undefined') return false;

      return moment.tz.zone(tz) != null;
    },
    gps: function(g) {
      if (!g) {
        return this.$element.data('ewd-gps');
      }
      this.$element.data('ewd-gps', g);
    },
    ewdDrivers: function(drivers) {
      if (!drivers) return this.$element.data('ewd-drivers');
      this.$element.data('ewd-drivers', drivers);
    }
  };

  //usage: $('#svg_element').ewd({ user: 123 });
  $.fn.ewd = function(options) {
    if (this.length > 1) {
      console.error(
        'Cannot process multiple ewd selections simultaneously.  Use a selector to match an individual element'
      );
      return null;
    }

    var ewd = $.data(this[0], 'ewd');
    if (ewd) return ewd;

    options = $.extend({}, $.fn.ewd.defaults, options);

    ewd = new EWD(this[0], options);
    $.data(this[0], 'ewd', ewd);
    return ewd;
  };

  $.fn.ewd.defaults = {
    url: '/ng/ewd',
    embed: null,
    show_driver: true, //display driver name
    show_rests: true, //request & show rest periods
    show_nights: true, //request & show night rests
    show_totals: false, //display totals
    show_trace: false, //request (& show) rule execution trace
    show_violations: false, //show/calculate violations
    show_predicted: false, //show/calculate predicted violations also
    show_checkpoint: false, //show checkpoint marker on activity chart
    show_animation: true, // show chart animation
    show_tooltips: false, // show tooltips on activity chart
    show_titles: true, //show title data (work/rest)
    show_speed: false, //show PR speed graph
    timezone: DEFAULT_TZ, // default timezone
    margins: { top: 0, bottom: 0, left: 0, right: 0 }
  };

  function ewdChart(ele, options) {
    var margins = options.margins;

    //Get current sizes...
    var width = 1200 - margins.right - margins.left;
    var height = 256 - margins.bottom;

    function violationTitle(v, timezone) {
      var periodText;
      if (ewd.isInfiniteViolation(v))
        periodText = '<br/>Violation from ' + ewd.dateFormat(v.start, timezone) + ' indefinitely';
      else
        periodText =
          '<br/>Violation from ' +
          ewd.dateFormat(v.start, timezone) +
          ' to ' +
          ewd.dateFormat(v.finish, timezone) +
          ' (' +
          ewd.durFormat(v.finish - v.start) +
          ')';

      if (v.period)
        periodText =
          periodText +
          '<br/>Occurred in ' +
          v.period.type +
          ' period between ' +
          ewd.dateFormat(v.period.start, timezone) +
          ' and ' +
          ewd.dateFormat(v.period.finish, timezone);

      return '<b>[' + v.rule.id + '] ' + v.rule.description + '</b>' + periodText;
    }

    function eventTitle(e1, e2, isLeadin, user, timezone, secondDriver) {
      var title = '';
      if (secondDriver) {
        title += secondDriver.firstName + ' ' + secondDriver.lastName;
        if (e2) {
          title += '<br/>2-up for ' + ewd.durFormat(e2.eventAt - e1.eventAt);
          title += '<br/>From ' + ewd.dateFormat(e1.eventAt, timezone);
          title += '<br/>To ' + ewd.dateFormat(e2.eventAt, timezone);
        } else {
          title += '<br/>2-up and start from ' + ewd.dateFormat(e1.eventAt, timezone);
        }

        return title;
      }
      if (ewd.isDrive(e1.action)) title = title + '<b>DRIVE</b> period';
      else if (ewd.isWork(e1.action)) title = title + '<b>WORK</b> period';
      else title = title + '<b>REST</b> period';

      if (e1 && isLeadin === true && e2) {
        title = title + ' for ' + ewd.durFormat(e2.eventAt - e1.eventAt);
        title = title + '<br/>Lead in at ' + ewd.dateFormat(e1.eventAt, timezone);
      } else if (e2) {
        title = title + ' for ' + ewd.durFormat(e2.eventAt - e1.eventAt);
        title = title + '<br/><b>' + e1.action + '</b> at ' + ewd.dateFormat(e1.eventAt, timezone);
        title = title + '<br/><b>' + e2.action + '</b> at ' + ewd.dateFormat(e2.eventAt, timezone);
      } else {
        title = title + '<br/>Lead out at ' + ewd.dateFormat(e1.eventAt, timezone);
      }
      return title;
    }

    var minE, maxE;

    //this.chart({user: user, events: userEvents, violations: userViolations, checkpoint: userCheckpoint, fromDate: from, toDate: to});
    function chart(args) {
      // hide all tooltips when about to draw chart
      if ($.fn.tooltipsy && options.show_tooltips) $('.tooltipsy').hide();

      var user = args.user;
      var violations = args.violations;
      var events = args.events;
      var checkpoint = args.checkpoint;
      var status = args.status;
      var gps = args.gps;
      const ewdDrivers = args.ewdDrivers;
      const show2DriverLane = events?.some?.(
        e => e.driver2Id != null && e.eventAt >= this.from() && e.eventAt <= this.to()
      );
      const secondUpLaneHeight = 60;
      if (!user) return;

      // console.log("D3 version " + d3.version);
      var workChart = d3.select(ele);
      width = +workChart.attr('width');
      height = +workChart.attr('height');
      if (show2DriverLane) {
        if (!workChart.attr('updatedHeight')) {
          height += secondUpLaneHeight;
          workChart.attr('height', height);
          workChart.attr('updatedHeight', true);
        }
      } else if (workChart.attr('updatedHeight')) {
        height -= secondUpLaneHeight;
        workChart.attr('height', height);
        workChart.attr('updatedHeight', null);
      }

      var adjLeft = options.show_titles ? margins.left + 105 : margins.left;
      var xscale = d3.scale.linear().rangeRound([adjLeft, width - margins.right]);

      workChart.selectAll('*').remove();

      var defs = workChart.append('defs');
      defs
        .append('pattern')
        .attr({
          id: 'pattern-stripe',
          patternUnits: 'userSpaceOnUse',
          width: 4,
          height: 4,
          patternTransform: 'rotate(45)'
        })
        .append('rect')
        .attr({ width: 2, height: 4, fill: 'white' });
      defs
        .append('mask')
        .attr('id', 'mask-stripe')
        .append('rect')
        .attr({
          x: 0,
          y: 0,
          width: '100%',
          height: '100%',
          fill: 'url(#pattern-stripe)'
        });
      defs
        .append('pattern')
        .attr({
          id: 'pattern-stars',
          patternUnits: 'userSpaceOnUse',
          width: 16,
          height: 16
        })
        .append('circle')
        .attr({
          cx: 8,
          cy: 8,
          r: 10,
          style: 'stroke: none; fill: #8C92AC; fill-opacity: .6'
        });

      var chart = workChart.append('g').attr('class', 'ewd');
      var gutter_height = 28; //amount of space below activity events for violation period info (or other stuff)
      var control_height = 20;

      var adjTop = margins.top + control_height;
      var adjBottom = height - 32;
      var adjHeight = adjBottom - adjTop - (show2DriverLane ? secondUpLaneHeight : 0);
      var adjWidth = width - margins.right;
      var bar_height = adjHeight / 5;
      var title_height = adjHeight / 2;

      //top horizontal bar over chart data
      chart
        .append('g')
        .attr('class', 'title')
        .append('rect')
        .attr({
          x: 2,
          y: adjTop - control_height,
          width: adjWidth,
          height: control_height - 1
        });

      //TITLES
      if (options.show_titles) {
        let title_Y = adjTop;
        //draw 2-up driver lane first
        if (show2DriverLane) {
          var twoUpGraph = chart.append('g').attr('class', 'title');

          twoUpGraph
            .append('rect')
            .attr({ x: 2, y: title_Y, width: adjLeft - 3, height: secondUpLaneHeight });
          twoUpGraph
            .append('text')
            .attr({
              x: 2 + (adjLeft - 3) / 2,
              y: title_Y + secondUpLaneHeight / 2,
              dy: '.5em',
              'text-anchor': 'middle'
            })
            .text('2-Up');
          title_Y += secondUpLaneHeight + 1;
        }

        var tWork = chart.append('g').attr('class', 'title');
        var yWork = title_Y + title_height / 2 - 16;

        tWork.append('rect').attr({ x: 2, y: title_Y, width: adjLeft - 3, height: title_height });

        /** This path is the "steering wheel" icon **/
        tWork
          .append('g')
          .append('path')
          .attr({
            d:
              'M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0zM16,4c5.207,0,9.605,3.354,11.266,8H4.734C6.395,7.354,10.793,4,16,4z M16,18c-1.105,0-2-0.895-2-2s0.895-2,2-2s2,0.895,2,2S17.105,18,16,18zM4,16c5.465,0,9.891,5.266,9.984,11.797C8.328,26.828,4,21.926,4,16zM18.016,27.797C18.109,21.266,22.535,16,28,16C28,21.926,23.672,26.828,18.016,27.797z',
            fill: '#78ce3f',
            transform: 'translate(8,' + yWork + ') scale(1.0)'
          });

        tWork
          .append('text')
          .attr({
            x: adjLeft - 60,
            y: yWork + (options.show_totals ? 0 : 5),
            dy: '1em'
          })
          .text('Work');

        var workTot = tWork.append('text');
        workTot.attr({
          x: adjLeft - 59,
          y: yWork + (options.show_totals ? 0 : 5) + 18,
          dy: '1em',
          class: 'title2'
        });

        title_Y += title_height + 1;
        var tRest = chart.append('g').attr('class', 'title');
        var yRest = title_Y + title_height / 2 - 16;
        tRest.append('rect').attr({
          x: 2,
          y: title_Y,
          width: adjLeft - 3,
          height: title_height
        });

        /** This path is the "sleepy face" icon **/
        tRest
          .append('g')
          .append('path')
          .attr({
            d:
              'M15.516,90.547c10.341,10.342,23.927,15.513,37.513,15.512s27.172-5.173,37.517-15.517c20.686-20.684,20.684-54.341,0.002-75.024C80.202,5.174,66.615,0.001,53.029,0.001c-13.587,0-27.173,5.17-37.515,15.513C-5.173,36.199-5.171,69.858,15.516,90.547z M21.301,21.3c8.748-8.747,20.238-13.12,31.728-13.12s22.98,4.374,31.729,13.123c17.494,17.494,17.492,45.962-0.002,63.455c-8.747,8.746-20.237,13.12-31.728,13.121s-22.98-4.372-31.728-13.119c-2.188-2.187-4.101-4.546-5.741-7.032C4.078,60.317,5.993,36.608,21.301,21.3z M40.642,80.552H65.42c1.242,0,2.252,1.557,2.252,3.479c0,1.92-1.01,3.478-2.252,3.478H40.642c-1.244,0-2.253-1.558-2.253-3.478C38.389,82.108,39.398,80.552,40.642,80.552z M24.688,52.314c-1.212-1.134-1.274-3.035-0.142-4.247c1.132-1.213,3.018-1.291,4.247-0.143c3.251,3.052,6.589,0.241,6.959-0.089c1.105-0.99,2.741-1.011,3.867-0.119c0.133,0.105,0.259,0.224,0.376,0.354c1.106,1.236,1.001,3.136-0.235,4.244C37.096,54.698,30.552,57.797,24.688,52.314zM66.193,52.279c-1.212-1.134-1.273-3.035-0.142-4.247c1.132-1.213,3.018-1.291,4.247-0.143c3.251,3.052,6.589,0.241,6.959-0.089c1.104-0.99,2.741-1.011,3.867-0.119c0.133,0.105,0.259,0.224,0.376,0.354c1.105,1.236,1.001,3.136-0.235,4.244C78.602,54.663,72.058,57.762,66.193,52.279z',
            fill: '#6fabec',
            transform: 'translate(8 ' + yRest + ') scale(0.3)'
          });

        tRest
          .append('text')
          .attr({
            x: adjLeft - 60,
            y: yRest + (options.show_totals ? 0 : 5),
            dy: '1em'
          })
          .text('Rest');

        var restTot = tRest.append('text');
        restTot.attr({
          x: adjLeft - 59,
          y: yRest + (options.show_totals ? 0 : 5) + 18,
          dy: '1em',
          class: 'title2'
        });
      }

      //now select the dynamic, data driven part of the chart
      var ch = chart.append('g').attr('class', 'ewd-data');

      var timezone = this.timezone();
      minE = args.fromDate ? moment.tz(args.fromDate, timezone).valueOf() : null;
      maxE = args.toDate ? moment.tz(args.toDate, timezone).valueOf() : null;

      xscale.domain([minE, maxE]);

      //TOTALS
      if (options.show_totals && options.show_titles) {
        if (violations && violations.totals) {
          workTot.text(
            ewd.durFormat(
              (violations.totals.work + (violations.totals.rest - violations.totals.rested)) * 1000,
              true
            )
          );
          restTot.text(ewd.durFormat(violations.totals.rested * 1000, true));
        } else {
          workTot.text('-');
          restTot.text('-');
        }
      }

      //VIOLATIONS
      //process the violations first so they're at a lower z-order
      if (violations) {
        var v_area = ch.selectAll('.violation').data(violations.violations);
        var now = checkpoint ? checkpoint : moment.tz(timezone).valueOf();

        var v_area1 = v_area
          .enter()
          .append('g')
          .attr({ class: 'violation' });

        var vrect = v_area1
          .append('rect')
          .attr({
            class: function(d, i) {
              return 'ewd-tip ' + ewd.violationClass(options.show_predicted, d, now);
            },
            id: function(d, i) {
              return 'violation-' + d.id;
            },
            x: function(d, i) {
              return xscale(Math.max(d.start, minE));
            },
            y: (show2DriverLane ? secondUpLaneHeight : 0) + adjTop,
            width: function(d, i) {
              var actFinish = ewd.isInfiniteViolation(d) ? maxE : d.finish;
              var w = xscale(Math.min(actFinish, maxE)) - xscale(Math.max(d.start, minE));
              return w < 0 ? 0 : w;
            },
            height: adjHeight,
            'data-violation-index': function(d, i) {
              return i;
            },
            title: function(d, i) {
              return violationTitle(d, timezone);
            }
          })
          .on('mouseover', function(d, i) {
            d3.select(ele)
              .selectAll("g.violation .period[data-violation-index='" + i + "']")
              .attr('visibility', 'visible');
          })
          .on('mouseout', function(d, i) {
            d3.select(ele)
              .selectAll('g.violation .period[data-violation-index]')
              .attr('visibility', 'hidden');
          });

        // Chrome 43 https://code.google.com/p/chromium/issues/detail?id=479548
        if (/chrome/.test(navigator.userAgent.toLowerCase())) {
          vrect.style('width', function(d, i) {
            var actFinish = ewd.isInfiniteViolation(d) ? maxE : d.finish;
            var w = xscale(Math.min(actFinish, maxE)) - xscale(Math.max(d.start, minE));
            return w < 0 ? 0 : w;
          });
        }

        var ph = 12; //period triangle height
        v_area1.append('path').attr({
          d: function(d, i) {
            if (!d.period) return '';
            var x1 = xscale(d.period.start);
            var y1 = adjBottom + 16;
            return 'M' + x1 + ',' + y1 + 'V' + (y1 + ph) + 'H' + (x1 + ph) + 'z';
          },
          class: 'period',
          visibility: 'hidden',
          'data-violation-index': function(d, i) {
            return i;
          }
        });
        v_area1.append('path').attr({
          d: function(d, i) {
            if (!d.period) return '';
            var x1 = xscale(d.period.start);
            var x2 = xscale(d.period.finish);
            var y1 = adjBottom + 16;
            return 'M' + (x1 + ph) + ',' + (y1 + ph) + 'H' + (x2 - ph);
          },
          class: 'period',
          visibility: 'hidden',
          'data-violation-index': function(d, i) {
            return i;
          }
        });
        v_area1.append('path').attr({
          d: function(d, i) {
            if (!d.period) return '';
            var x2 = xscale(d.period.finish);
            var y1 = adjBottom + 16;
            return 'M' + x2 + ',' + y1 + 'V' + (y1 + ph) + 'H' + (x2 - ph) + 'z';
          },
          class: 'period',
          visibility: 'hidden',
          'data-violation-index': function(d, i) {
            return i;
          }
        });

        v_area1
          .append('text')
          .attr({
            x: function(d, i) {
              return d.period ? xscale(d.period.start) - 104 : 0;
            },
            y: adjBottom + gutter_height,
            class: 'period',
            visibility: 'hidden',
            'data-violation-index': function(d, i) {
              return i;
            }
          })
          .text(function(d, i) {
            return d.period ? ewd.dateFormat(d.period.start, timezone) : '';
          });

        v_area1
          .append('text')
          .attr({
            x: function(d, i) {
              return d.period ? xscale(d.period.finish) + 4 : 0;
            },
            y: adjBottom + gutter_height,
            class: 'period',
            visibility: 'hidden',
            'data-violation-index': function(d, i) {
              return i;
            }
          })
          .text(function(d, i) {
            return d.period ? ewd.dateFormat(d.period.finish, timezone) : '';
          });

        v_area.exit().remove();
      }

      //NIGHT RESTS
      //Night rest periods
      if (
        maxE - minE < ewd.days(28) &&
        options.show_nights &&
        violations &&
        violations.rests &&
        violations.rests['night']
      ) {
        var rline3 = ch.selectAll('.rest.night').data(violations.rests['night']);

        rline3
          .enter()
          .append('g')
          .attr('class', 'rest night')
          .append('rect')
          .attr({
            x: function(d, i) {
              return Math.max(adjLeft, xscale(d.start));
            },
            y: (show2DriverLane ? secondUpLaneHeight : 0) + adjTop,
            width: function(d, i) {
              return xscale(d.finish) - Math.max(adjLeft, xscale(d.start));
            },
            height: adjHeight,
            title: function(d, i) {
              if (d.finish - d.start >= ewd.hours(7))
                return (
                  '<b>Night rest</b><br/>' +
                  ewd.dateFormat(d.start, timezone) +
                  ' - ' +
                  ewd.dateFormat(d.finish, timezone)
                );
              return (
                '<b>Night rest (<7h)</b><br/>' +
                ewd.dateFormat(d.start, timezone) +
                ' - ' +
                ewd.dateFormat(d.finish, timezone)
              );
            },
            class: 'ewd-tip',
            id: function(d, i) {
              return 'nightrest-' + d.start;
            },
            style: function(d, i) {
              if (d.finish - d.start >= ewd.hours(7)) return 'stroke: none; fill: #8C92AC;';
              return 'stroke: none; fill: #8C92AC; fill-opacity: 0.4';
            }
          });

        rline3.exit().remove();
      }

      var ticks = ewd.calculateTimeTicks(minE, maxE);
      ch.append('g')
        .attr('class', 'x axis')
        .attr('transform', 'translate(0,' + adjBottom + ')')
        .call(
          d3.svg
            .axis()
            .scale(xscale)
            .tickValues(ticks)
            .orient('bottom')
            .tickFormat(ewd.customTimeFormat(timezone))
        );

      var grid = ch
        .append('g')
        .attr('class', 'grid')
        .selectAll('line.major')
        .data(ticks);
      grid
        .enter()
        .append('line')
        .attr({
          x1: function(d) {
            return xscale(d);
          },
          x2: function(d) {
            return xscale(d);
          },
          y1: adjTop - control_height,
          y2: adjBottom,
          class: 'major'
        });
      grid.exit().remove();

      if (maxE - minE <= ewd.hours(25)) {
        var tick15 = function() {
          var vals = [];
          for (var i = 0; i < ticks.length; i++) {
            vals.push(ticks[i] + ewd.minutes(15));
            vals.push(ticks[i] + ewd.minutes(30));
            vals.push(ticks[i] + ewd.minutes(45));
          }
          console.log('Added ' + vals.length + ' grid entries');
          return vals;
        };
        var grid2 = ch
          .append('g')
          .attr('class', 'grid')
          .selectAll('line.minor')
          .data(tick15());
        grid2
          .enter()
          .append('line')
          .attr({
            x1: function(d) {
              return xscale(d);
            },
            x2: function(d) {
              return xscale(d);
            },
            y1: function(d, i) {
              return adjBottom - (i % 3 == 1 ? 40 : 20);
            },
            y2: adjBottom,
            class: 'minor'
          });
        grid2
          .enter()
          .append('line')
          .attr({
            x1: function(d) {
              return xscale(d);
            },
            x2: function(d) {
              return xscale(d);
            },
            y1: function(d, i) {
              return adjTop + (i % 3 == 1 ? 40 : 20);
            },
            y2: adjTop,
            class: 'minor'
          });
        grid2.exit().remove();
      }

      if (violations && violations.rests) {
        var keys = Object.keys(violations.rests).sort(function cmp(k1, k2) {
          var k1x = parseInt(k1.replace(/[^\d]+/g, ''));
          var k2x = parseInt(k2.replace(/[^\d]+/g, ''));
          if (isNaN(k1x)) return 1;
          if (isNaN(k2x)) return -1;
          return k1x < k2x ? -1 : k1x > k2x ? 1 : 0;
        });
        for (var i = 0; i < keys.length; i++) {
          var key = keys[i];
          var key1 = key.replace(/[^\d]+/g, '');
          if (key1.trim().length == 0)
            //should filter nights
            continue;

          var tooltipFn = function(d, i) {
            if (d === undefined || d === null) return;

            return '<b>' + key1 + ' hour break</b><br/>' + ewd.dateFormat(d.finish, timezone);
          };

          var isBig = parseInt(key1) > 20;
          var classN = 'rest' + key1;

          var rline1 = ch.selectAll('.rest.' + classN).data(violations.rests[key]);

          var period = rline1
            .enter()
            .append('g')
            .attr('class', 'rest ' + classN);
          var rh = isBig ? 14 : 11;
          var rw = isBig ? 21 : 13;
          var lo = key1.length == 1 ? 4 : isBig ? 2 : 1;

          period.append('line').attr({
            x1: function(d, i) {
              return xscale(d.finish);
            },
            y1: adjTop - 1,
            x2: function(d, i) {
              return xscale(d.finish);
            },
            y2: adjBottom,
            title: tooltipFn,
            class: 'ewd-tip'
          });

          period.append('rect').attr({
            x: function(d, i) {
              return xscale(d.finish) - (isBig ? 1 : 0);
            },
            y: adjTop - (rh + 2),
            width: rw,
            height: rh,
            title: tooltipFn,
            class: 'ewd-tip'
          });

          period
            .append('text')
            .attr({
              x: function(d, i) {
                return xscale(d.finish) + lo;
              },
              y: adjTop - 3,
              title: tooltipFn,
              class: 'ewd-tip'
            })
            .text(key1);

          rline1.exit().remove();
        }
      }

      //EVENTS
      if (events) {
        var bars = ch.selectAll('.ewd-event').data(events);
        bars
          .enter()
          .append('g')
          .attr('class', function(d, i) {
            var ret = 'ewd-event ' + ewd.eventType(d.action);
            if (i === 0 && xscale(d.eventAt) < adjLeft) ret = ret + ' leadin'; //it only a lead in if its off-screen
            if (i == events.length - 1) ret = ret + ' leadout';
            return ret;
          })
          .append('rect')
          .attr({
            class: 'ewd-tip',
            id: function(d, i) {
              return 'event-' + d.id;
            },
            x: function(d, i) {
              var x = Math.max(adjLeft, xscale(d.eventAt));
              return !isNaN(x) ? x : adjLeft;
            },
            y: function(d, i) {
              var y =
                adjTop +
                (show2DriverLane ? secondUpLaneHeight : 0) +
                1 +
                title_height / 2 -
                bar_height / 2 +
                (ewd.isRest(d.action) ? adjHeight / 2 : 0);
              return !isNaN(y) ? y : adjTop;
            },
            width: function(d, i) {
              var next = i < events.length ? events[i + 1] : null;
              var w = NaN;
              if (next == null) w = width - margins.right - Math.max(adjLeft, xscale(d.eventAt));
              else w = xscale(next.eventAt) - Math.max(adjLeft, xscale(d.eventAt));

              return !isNaN(w) && w > 0 ? w : 0;
            },
            height: bar_height,
            index: function(d, i) {
              return i;
            },
            title: function(d, i) {
              var next = i < events.length ? events[i + 1] : null;
              return eventTitle(d, next, i === 0 ? true : false, args.user, timezone);
            },
            style: function(d, i) {
              if (i === 0 || i == events.length - 1) return 'opacity: 0.4;';
            }
          });
        bars.exit().remove();

        //draw 2-up events
        if (show2DriverLane) {
          let eventStartIndex = -1;
          let eventStartDriver2Id = -1;

          for (let i = 0; i < events.length; i++) {
            const e = events[i];
            if (e.driver2Id != null && e.driver2Id !== -1) {
              if (eventStartIndex === -1) {
                eventStartIndex = i;
                eventStartDriver2Id = e.driver2Id;
              }
              if (i !== events.length - 1 && e.driver2Id === eventStartDriver2Id) continue;
            }

            if (eventStartIndex !== -1) {
              const startEvent = events[eventStartIndex];
              ch.append('g')
                .attr('class', 'ewd-2up')
                .append('rect')
                .attr({
                  class: 'ewd-tip ewd-2up-bar',
                  id: 'event-2up-' + startEvent.id,
                  startId: startEvent.id,
                  endId: e.id,
                  includeLast: i === events.length - 1 && e.driver2Id === startEvent.driver2Id,
                  x: () => {
                    var x = Math.max(adjLeft, xscale(startEvent.eventAt));
                    return !isNaN(x) ? x : adjLeft;
                  },
                  y: () => {
                    var y = adjTop + secondUpLaneHeight / 2 - bar_height / 2;
                    return !isNaN(y) ? y : adjTop;
                  },
                  width: () => {
                    var next =
                      i === events.length - 1 && e.driver2Id === startEvent.driver2Id ? null : e;
                    var w = NaN;
                    if (next == null)
                      w = width - margins.right - Math.max(adjLeft, xscale(startEvent.eventAt));
                    else w = xscale(next.eventAt) - Math.max(adjLeft, xscale(startEvent.eventAt));

                    return !isNaN(w) && w > 0 ? w : 0;
                  },
                  height: bar_height,
                  index: () => {
                    return eventStartIndex;
                  },
                  title: () => {
                    var next =
                      i === events.length - 1 && e.driver2Id === startEvent.driver2Id ? null : e;
                    const secondDriver = ewdDrivers?.find(
                      driver => startEvent.driver2Id === driver.id
                    );
                    return eventTitle(
                      startEvent,
                      next,
                      eventStartIndex === 0 ? true : false,
                      args.user,
                      timezone,
                      secondDriver
                    );
                  },
                  style: () => {
                    return 'opacity: 0.4;';
                  }
                });
              eventStartIndex = -1;
              eventStartDriver2Id = -1;

              if (e.driver2Id != null && e.driver2Id !== startEvent.driver2Id) {
                i--;
              }
            }
          }
        }
      }

      //CHECKPOINT
      if (options.show_checkpoint && checkpoint) {
        var cpAt = checkpoint;
        if (cpAt && cpAt >= minE && cpAt <= maxE) {
          var title = '<b>Latest checkpoint</b><br/>' + ewd.dateFormat(cpAt, timezone);

          var lCheckpoint = ch.append('g').attr('class', 'checkpoint');
          lCheckpoint.append('circle').attr({
            cx: xscale(cpAt),
            cy: adjBottom,
            r: 3,
            title: title,
            class: 'ewd-tip'
          });
          lCheckpoint.append('line').attr({
            x1: xscale(cpAt),
            y1: adjTop - 1,
            x2: xscale(cpAt),
            y2: adjBottom - 3,
            title: title
          });
          lCheckpoint.append('rect').attr({
            x: xscale(cpAt),
            y: adjTop - 16,
            width: 21,
            height: 14,
            title: tooltipFn,
            class: 'ewd-tip'
          });
          lCheckpoint
            .append('text')
            .attr({
              x: xscale(cpAt) + 5,
              y: adjTop - 4,
              title: title,
              class: 'ewd-tip fsize13'
            })
            .text('C');
        }
      }

      if ($.fn.tooltipsy && options.show_tooltips) $('svg .ewd-tip').tooltipsy();
    }

    chart.highlightViolation = function(idx) {
      if (idx === null) {
        return;
      }
      $('.tooltipsy-wrapper').hide();
      $('.violation .period').attr({ visibility: 'hidden', opacity: 0 });
      var workChart = d3.select(ele);
      // Hide violation time marks
      workChart.selectAll('rect[data-violation-index]').attr('visibility', 'hidden');
      // Show the violation timeframe lines for this violation
      workChart
        .selectAll("rect[data-violation-index='" + idx + "']")
        .attr({ visibility: 'visible', opacity: 1 });
      workChart
        .selectAll("g.violation .period[data-violation-index='" + idx + "']")
        .attr('visibility', 'visible');
      // Show the tooltipsy
      var vioRect = $("svg g rect[data-violation-index='" + idx + "']");
      if (
        $.fn.tooltipsy &&
        options.show_tooltips &&
        vioRect &&
        typeof vioRect.data('tooltipsy') != 'undefined'
      ) {
        vioRect.data('tooltipsy').show();
      }
    };

    chart.resetHighlight = function(idx) {
      if (idx === null) {
        return;
      }
      var workChart = d3.select(ele);
      // Show all the violation time marks
      workChart.selectAll('rect[data-violation-index]').attr('visibility', 'visible');
      // Hide the violation timeframe lines for this violation
      workChart.selectAll('g.violation .period[data-violation-index]').attr('visibility', 'hidden');
      // Hide the tooltipsy
      var vioRect = $("svg g rect[data-violation-index='" + idx + "']");
      if (
        $.fn.tooltipsy &&
        options.show_tooltips &&
        vioRect &&
        typeof vioRect.data('tooltipsy') != 'undefined'
      )
        vioRect.data('tooltipsy').hide();
    };

    chart.showEventTooltipsy = function(id) {
      $('.tooltipsy-wrapper').hide();
      var event = $('#' + id);
      if (event && event.data('tooltipsy')) {
        event.data('tooltipsy').show();
      }

      const events2up = $('[id^=event-2up]');
      const eventId = parseInt(id.replace('event-', ''));
      const bar = events2up.filter(
        (idx, e) =>
          parseInt($(e).attr('startId')) <= eventId &&
          (parseInt($(e).attr('endId')) > eventId || $(e).attr('includeLast') === 'true')
      );
      if (bar && bar.length > 0) {
        bar
          .first()
          .data('tooltipsy')
          .show();
      }
    };

    chart.hideEventTooltipsy = function(id) {
      $('.tooltipsy-wrapper').hide();
      if (id !== null) {
        var event = $('#' + id);
        if (event && event.data('tooltipsy')) {
          event.data('tooltipsy').hide();
        }

        const events2up = $('[id^=event-2up]');
        const eventId = parseInt(id.replace('event-', ''));
        const bar = events2up.filter(
          (idx, e) =>
            parseInt($(e).attr('startId')) <= eventId &&
            (parseInt($(e).attr('endId')) > eventId || $(e).attr('includeLast') === 'true')
        );
        if (bar && bar.length > 0) {
          bar
            .first()
            .data('tooltipsy')
            .hide();
        }
      }
    };

    return chart;
  }
})(jQuery);

/** SENTINEL CORE END */
