import { useRef, useEffect } from 'react';

export function useCountdownTimer(period) {
  const timerRef = useRef(null);

  const clearTimer = onClear => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
      if (onClear) {
        onClear();
      }
    }
  };

  const createTimer = onTimeup => {
    clearTimer();
    timerRef.current = setTimeout(() => {
      if (onTimeup) {
        onTimeup();
      }
    }, period);
  };

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  return {
    startCountdown: createTimer,
    clearCountdown: clearTimer
  };
}
