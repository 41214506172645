import React, { useState, useMemo } from 'react';
import { FlexibleXYPlot, ArcSeries } from 'react-vis';
import { Row, Col } from 'antd';
import styles from './DriverPortal.module.scss';
import { sortBy } from 'lodash';
import { formatDuration } from './util';
import { useTranslation } from 'react-i18next';

export function DriverStatusChart({ data, isExempt }) {
  const PI = Math.PI;
  const [focusedChart, setFocusedChart] = useState(false);
  const [selectedChart, setSelectedChart] = useState(false);
  const { t } = useTranslation();
  const renderData = useMemo(() => {
    return [
      {
        color: '#42C3F0',
        focusedColor: '#42C3F0',
        radius0: 0,
        radius: 2.5,
        requiredBreaksLeft: 1,
        totalBreaks: 1,
        drillValue: '5',
        drillTitle: t('ELD.Required Breaks Left'),
        drillDisplayOrder: 4,
        drillSelectedValue: '50%',
        show: false
      },
      {
        color: '#9fa9b7',
        focusedColor: '#9fa9b7',
        radius0: 17,
        radius: 21,
        angle: 0,
        angle0: Math.min(1, data?.cycleTimeLeft / data?.totalCycleTime) * 2 * PI,
        title: t('ELD.Cycle Time Left'),
        drillValue: formatDuration(data?.cycleTimeLeft),
        drillTitle: t('ELD.Cycle Time Left'),
        drillDisplayOrder: 3,
        drillSelectedValue:
          parseInt((data?.cycleTimeLeft / (data?.totalCycleTime || 1)) * 100) + '%',
        show: true
      },
      {
        color: '#1890ff',
        focusedColor: '#1890ff',
        radius0: 12,
        radius: 16,
        angle: 0,
        angle0: Math.min(1, data?.onDutyTimeLeft / data?.totalOnDutyTime) * 2 * PI,
        drillValue: formatDuration(data?.onDutyTimeLeft),
        drillTitle: t('ELD.On Duty Time Left'),
        drillDisplayOrder: 2,
        drillSelectedValue:
          parseInt((data?.onDutyTimeLeft / (data?.totalOnDutyTime || 1)) * 100) + '%',
        show: true
      },
      {
        color: '#26bd8b',
        focusedColor: '#26bd8b',
        radius0: 7,
        radius: 11,
        angle: 0,
        angle0: Math.min(1, data?.driveTimeLeft / data?.totalDriveTime) * 2 * PI,
        drillValue: formatDuration(data?.driveTimeLeft),
        drillTitle: t('ELD.Drive Time Left'),
        drillDisplayOrder: 1,
        drillSelectedValue:
          parseInt((data?.driveTimeLeft / (data?.totalDriveTime || 1)) * 100) + '%',
        show: true
      }
    ];
  }, [data]);

  return (
    <Row className={styles.driverStatusChart}>
      <Col xs={16} xl={14}>
        <FlexibleXYPlot className={styles.chart} xDomain={[0, 30]} yDomain={[0, 30]}>
          <ArcSeries
            center={{ x: 12, y: 13 }}
            data={renderData.slice(1).map(row => {
              if (focusedChart?.color === row.color) {
                return {
                  ...row,
                  style: { fill: row.focusedColor }
                };
              } else if (focusedChart !== false && focusedChart?.color !== row.color) {
                return {
                  ...row,
                  style: { stroke: 'none', opacity: 0.56, fill: row.color }
                };
              }

              if (selectedChart && selectedChart.color !== row.color) {
                return {
                  ...row,
                  style: { stroke: 'none', fill: 'lightgray' }
                };
              }
              return row;
            })}
            onValueMouseOver={row => setFocusedChart(row)}
            onSeriesMouseOut={() => setFocusedChart(false)}
            onValueClick={row =>
              row.color === selectedChart.color ? setSelectedChart(false) : setSelectedChart(row)
            }
            colorType={'literal'}
          />
          {[...new Array(renderData[0].requiredBreaksLeft)].map((_, idx) => (
            <ArcSeries
              style={{ display: renderData[0].show ? 'inherit' : 'none' }}
              key={idx}
              animation
              colorType={'literal'}
              center={{
                x:
                  renderData[0].totalBreaks > 1
                    ? 12 +
                      0.3 * Math.cos(PI / 2 - ((idx + 0.5) * 2 * PI) / renderData[0].totalBreaks)
                    : 12,
                y:
                  renderData[0].totalBreaks > 1
                    ? 13 +
                      0.3 * Math.sin(PI / 2 - ((idx + 0.5) * 2 * PI) / renderData[0].totalBreaks)
                    : 13
              }}
              data={[
                {
                  color: renderData[0].color,
                  radius0: renderData[0].radius0,
                  radius: renderData[0].radius,
                  angle: (idx * 2 * PI) / renderData[0].totalBreaks,
                  angle0: ((idx + 1) * 2 * PI) / renderData[0].totalBreaks,
                  style:
                    focusedChart?.color === renderData[0].color
                      ? {
                          stroke: 'lightgray',
                          strokeWidth: '1px',
                          fill: renderData[0].focusedColor
                        }
                      : selectedChart && selectedChart.color !== renderData[0].color
                      ? { fill: 'lightgray', stroke: 'lightgray', strokeWidth: '1px' }
                      : null
                }
              ]}
              onValueMouseOver={row => renderData[0].show && setFocusedChart(row)}
              onSeriesMouseOut={() => renderData[0].show && setFocusedChart(false)}
              onSeriesClick={() =>
                renderData[0].show && renderData[0].color === selectedChart.color
                  ? setSelectedChart(false)
                  : setSelectedChart(renderData[0])
              }
            />
          ))}
        </FlexibleXYPlot>
      </Col>
      <Col xs={8} xl={10}>
        <div className={styles.drilldownWrapper}>
          {sortBy(
            renderData.filter(d => d.show),
            ['drillDisplayOrder']
          ).map((d, idx) => {
            let isFocused = d.color === focusedChart.color;
            let isSelected = d.color === selectedChart.color;

            let rowStyle = { borderColor: d.color };
            let rowValue = d.drillValue;

            if (isFocused) {
              rowStyle.backgroundColor = d.focusedColor;
              rowStyle.filter = 'brightness(1.25)';
            }
            if (isSelected) {
              rowValue = d.drillSelectedValue;

              rowStyle = {
                ...rowStyle,
                backgroundColor: d.color,
                color: '#fff'
              };
            }
            if (!isFocused && !isSelected && selectedChart) {
              rowStyle.opacity = 0.56;
            }

            return (
              <div
                key={idx}
                className={styles.drilldownRow}
                style={rowStyle}
                onMouseOut={() => setFocusedChart(false)}
                onMouseOver={() => setFocusedChart(d)}
                onClick={() =>
                  selectedChart.color === d.color ? setSelectedChart(false) : setSelectedChart(d)
                }
              >
                <p hidden={isExempt}>{rowValue}</p>
                <p>{d.drillTitle}</p>
              </div>
            );
          })}
        </div>
      </Col>
    </Row>
  );
}
