import React, { useMemo, useEffect, useRef, useState } from 'react';
import { Table, Checkbox, Tooltip } from 'antd';
import { UDTGridColumns } from './Grid/columns';
import ResizeObserver from 'resize-observer-polyfill';
import styles from './UDT.module.scss';
import { useLocalization } from 'features/localization/localizationSlice';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

export function UDTGrid({
  isLoading,
  data,
  assignMode,
  onSelectAllChecked,
  onSortedTableDataChanged,
  ...props
}) {
  const localization = useLocalization();
  const { t } = useTranslation();
  const [scrollSettings, setScrollSettings] = useState({ x: 'max-content' });
  const tableRef = useRef();
  const gridData = useMemo(() => cloneDeep(data), [data]);

  const columns = useMemo(() => {
    let columns = null;
    if (assignMode) {
      columns = cloneDeep(UDTGridColumns);
      const nonChecked = !data?.some(d => d.checked);
      const allChecked = data?.filter(d => d.checked)?.length === data?.length;
      columns[0].title = (
        <Tooltip
          placement="rightTop"
          title={
            nonChecked
              ? t('ELD.Select all rows for bulk assignment')
              : t('ELD.Un-select all selected rows')
          }
        >
          <Checkbox
            checked={!nonChecked}
            indeterminate={!allChecked && !nonChecked}
            onChange={onSelectAllChecked}
          />
        </Tooltip>
      );
    } else {
      columns = cloneDeep(UDTGridColumns.filter(c => !c.assignModeCell));
      columns[0].title = '';
    }

    const distanceColumn = columns.find(c => c.key === 'distance');
    distanceColumn.title =
      distanceColumn?.title + ' (' + localization.formats.speed.unit.toLowerCase() + ')';
    return columns;
  }, [assignMode, data, localization]);

  const handleResize = entries => {
    const { height } = entries[0].contentRect;

    // account for header in table height
    const header = tableRef.current.querySelector('.ant-table-thead');
    const headerHeight = header?.getBoundingClientRect()?.height || 0;
    const newTableHeight = height - headerHeight;

    setScrollSettings({
      x: 'max-content',
      y: newTableHeight
    });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    const tableElement = document.querySelector(`.${CSS.escape(styles.udtGrid)}`);
    resizeObserver.observe(tableElement);
    return () => {
      resizeObserver.unobserve(tableElement);
    };
  }, []);

  return (
    <Table
      className={styles.udtGrid}
      showSorterTooltip={false}
      pagination={false}
      dataSource={gridData}
      columns={columns}
      summary={currentData => {
        // Hack to get table's sorted and filtered table data since onUpdate only triggers on user actions
        // and not when we filter the dataSource externally
        onSortedTableDataChanged(currentData);
        return null;
      }}
      ref={tableRef}
      sticky={true}
      scroll={scrollSettings}
    />
  );
}
