import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Affix } from 'antd';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar'; //TODO: Modify after release with Ant component
import AntFilterWrapper from 'components/form/ant-filter-wrapper/AntFilterWrapper';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useDispatch } from 'react-redux';
import {
  useVehicleTypes,
  useDeletedVehicleTypes,
  deleteVehicleTypeApi,
  restoreVehicleTypeApi,
  useIsFetching as isVehicleTypesFetching
} from 'features/vehicles/vehicleTypesSlice';
import { VehicleTypesTable } from './VehicleTypesTable';
import { useCompanies, useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import { cache } from './CellRenderers';
import useDebounce from 'utils/hooks/useDebounce';
import { toLower } from 'lodash';
import AntMultiselect, { //TODO: Modify after release with Ant component
  formatListForMultiselect
} from 'components/form/antMultiselect/AntMultiselect';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Can, entities } from 'features/permissions';
import { TabNavLink } from 'components/nav/NavLinks';
import { useHistory } from 'react-router';
import {
  VehicleTypesLens,
  DELETED_VEHICLES_TYPES_PATH,
  TABS,
  VEHICLE_TYPES_LIST
} from './constants';
import './VehicleTypes.scss';
import { useTranslation } from 'react-i18next';
import { useVehicles } from 'features/fleets/fleetsSlice';
import i18next from 'i18next';
import { prepareDataForMultiselect } from 'utils/filters';
import { BUTTON_IDS } from 'utils/globalConstants';

const VehicleTypes = () => {
  const vehicles = useVehicles();
  const allVehicleTypes = useVehicleTypes();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const [filterTab, setFilterTab] = useState(filterPath);
  const deletedVehicleTypes = useDeletedVehicleTypes(filterTab === TABS.DELETED);
  const subCompanies = useSubCompanies();
  const companies = useCompanies();
  const company = useCurrentCompany();
  const [filteredVehicleTypes, setFilteredVehicleTypes] = useState([]);
  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(filterText, 300);
  const [filterCompanies, setFilterCompanies] = useState(
    formatListForMultiselect([{ id: 0, name: `${t('Common.AllCompanies')}` }, ...subCompanies])
  );
  const [tableRef, setTableRef] = useState(null);
  const history = useHistory();

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('VehicleTypes.VehicleTypesTitle')));
  }, [dispatch, t]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [filteredVehicleTypes, tableRef]);

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
  }, [subCompanies, t]);

  // TODO: Modify after release with Ant component
  // function handleFilteredCompaniesChange(newFilter) {
  //   setFilterCompanies(newFilter);
  // }

  function handleFilterTextChange(newFilter) {
    setFilterText(newFilter);
  }

  const handleAction = actionObject => {
    if (actionObject.action === 'restore') {
      dispatch(restoreVehicleTypeApi(actionObject.data));
    }
  };

  useEffect(() => {
    let vehicleTypesToFilter = allVehicleTypes;
    if (filterTab === TABS.DELETED) {
      vehicleTypesToFilter = deletedVehicleTypes;
    }
    setFilteredVehicleTypes(
      [...vehicleTypesToFilter].filter(type => {
        let validVehicleType = true;
        //Filter by search field
        if (debouncedSearchText) {
          validVehicleType =
            validVehicleType &&
            [type.name, type.code?.toString()].some(
              value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1
            );
        }

        //Filter by companies
        const checkedCompaniesIds = filterCompanies
          .filter(company => company.checked)
          .map(company => parseInt(company.id, 10));
        validVehicleType =
          validVehicleType && checkedCompaniesIds.indexOf(parseInt(type.companyId, 10)) > -1;
        return validVehicleType;
      })
    );
  }, [
    allVehicleTypes,
    companies,
    debouncedSearchText,
    deletedVehicleTypes,
    filterCompanies,
    filterTab
  ]);

  const deleteIsPossibleCheck = vehicleTypeId => {
    const matchingVehicle = vehicles.find(vehicle => vehicle?.type?.id === vehicleTypeId);
    if (matchingVehicle) return false;
    return true;
  };

  const handleDeleteAction = data => () => {
    const { id } = data;
    if (deleteIsPossibleCheck(id)) {
      dispatch(deleteVehicleTypeApi(data));
    } else {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('VehicleTypes.Notifications.VehicleAssigned')
        })
      );
    }
  };

  return (
    <React.Fragment>
      <Affix offsetTop={63}>
        <div>
          <Row
            justify="end"
            align="middle"
            style={{
              padding: '0 16px',
              zIndex: '5',
              minHeight: '56px',
              background: '#fff'
            }}
          >
            <Col span="auto" style={{ width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  zIndex: '5',
                  minHeight: '56px',
                  background: 'white',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: '1px solid #e8ebee'
                }}
              >
                <div>
                  <TabNavLink
                    to="/settings/vehicleTypes/all"
                    isActive={(match, location) => {
                      return [
                        '/',
                        '/settings',
                        '/settings/vehicleTypes',
                        '/settings/vehicleTypes/all'
                      ].includes(location.pathname);
                    }}
                    onClick={() => {
                      setFilterTab('all');
                    }}
                  >
                    {t(VehicleTypesLens.All)}
                  </TabNavLink>
                  <TabNavLink
                    exact
                    to={DELETED_VEHICLES_TYPES_PATH}
                    onClick={() => {
                      setFilterTab('deleted');
                    }}
                  >
                    {t(VehicleTypesLens.Deleted)}
                  </TabNavLink>
                </div>
                <Can everyEntity={[entities.VEHICLETYPE_CREATE]}>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      history.push('/settings/vehicleTypes/newVehicleType');
                    }}
                    id={BUTTON_IDS.vehicleTypesAddNew}
                  >
                    {t('VehicleTypes.AddNewType')}
                  </Button>
                </Can>
              </div>
            </Col>
          </Row>
          <Row style={{ background: '#f7f8f9' }}>
            <Col span={24}>
              <AntFilterWrapper>
                <div className="flexRow">
                  <AntSearchbar onFilter={handleFilterTextChange} />
                  <AntMultiselect
                    title={
                      filterCompanies?.some(value => !value.checked)
                        ? t('Common.Companies')
                        : t('Common.AllCompanies')
                    }
                    onFilter={v => setFilterCompanies(v)}
                    data={filterCompanies}
                  />
                </div>
                <div className="typeCounter">
                  {filteredVehicleTypes.length}
                  {filteredVehicleTypes.length === 1
                    ? `${t('VehicleTypes.Type')}`
                    : `${t('VehicleTypes.Types')}`}
                </div>
              </AntFilterWrapper>
            </Col>
          </Row>
        </div>
      </Affix>
      <Row style={{ height: '100%' }}>
        <Col span="auto" style={{ width: '100%' }}>
          <VehicleTypesTable
            vehicleTypes={filteredVehicleTypes}
            companies={companies}
            isLoading={isVehicleTypesFetching(
              filterTab === TABS.DELETED
                ? VEHICLE_TYPES_LIST.DELETED_LIST
                : VEHICLE_TYPES_LIST.NORMAL_LIST
            )}
            company={company}
            handleDeleteAction={handleDeleteAction}
            typeOfEntityToDelete={t('Common.vehicle type')}
            handleAction={action => handleAction(action)}
            setTableRef={setTableRef}
            filterTab={filterTab}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default VehicleTypes;
