import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'components/ant';

import styles from './MultipleSelectTableOption.module.scss';
import { Checkbox } from 'antd';

const MultipleSelectTableOption = ({ option, columnsNumber, selected }) => {
  const entitiesInThirdColumn = option?.thirdColumnValue?.split(',')?.length;

  return (
    <div className={styles.optionsContainer}>
      <Checkbox
        checked={
          selected ? !!selected.find(selectedOption => selectedOption === option?.id) : false
        }
      ></Checkbox>
      <span className={styles.option}>{option.firstColumnValue || ''}</span>
      <span className={styles.option}>{option.secondColumnValue || ''}</span>
      {columnsNumber > 2 &&
        (entitiesInThirdColumn < 5 ? (
          <span className={styles.option}>{option?.thirdColumnValue || ''}</span>
        ) : (
          <span className={styles.optionWithoutExpand}>
            <Tooltip
              content={option?.thirdColumnValue}
              target={
                <span>
                  <span>{option?.thirdColumnValue}</span>
                  <span className={styles.counter}>{`(${entitiesInThirdColumn})`}</span>
                </span>
              }
              placement={'left'}
            />
          </span>
        ))}
    </div>
  );
};

MultipleSelectTableOption.propTypes = {
  option: PropTypes.shape({
    firstColumnValue: PropTypes.string,
    secondColumnValue: PropTypes.string,
    thirdColumnValue: PropTypes.string
  }).isRequired,
  columnsNumber: PropTypes.number.isRequired
};

export default MultipleSelectTableOption;
