import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import arrayMove from 'array-move';

import { useTrackingData } from 'features/tracking/trackingSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import {
  useUserPreferences,
  setUserPreferences,
  updatePreferences
} from 'features/user/userPreferencesSlice';
import { openToast } from 'features/toasts/toastsSlice';
import {
  useCurrentCompany,
  useSubCompanyEntityConfig,
  CompanyConfigKey,
  CompanyConfigValue
} from 'features/company/companySlice';
import { useUserKey, useUser } from 'features/user/userSlice';
import { useCardViewDevices, useCompanyCardViewSettings } from 'features/tracking/cardView';

import { ToastType } from 'components/notifications/toasts/Toast';
import { CardViewPanel } from './CardViewPanel';
import { TrackingPage } from '../TrackingPage';
import { CardType } from './Cards/CardType';

const isValidPinnedCard = card => {
  if (card?.cardType === CardType.Driver) {
    return card?.isPinned && card?.driver?.id;
  } else if (card?.cardType === CardType.Vehicle) {
    return card?.isPinned && card?.vehicleId;
  }
};

export const CardView = () => {
  const trackingData = useTrackingData();
  const userKey = useUserKey();
  const user = useUser();
  const company = useCurrentCompany();
  const nonBusinessCompanyConfig = useSubCompanyEntityConfig(
    company?.id,
    CompanyConfigKey.HideNonBusiness
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userPreferences = useUserPreferences();
  const fetchRef = useRef();
  const { cardViewDevices, isFetching } = useCardViewDevices(trackingData.devices, fetchRef);
  const companyCardViewSettings = useCompanyCardViewSettings(userPreferences, company);

  const [cards, setCards] = useState(cardViewDevices);

  const { pinnedCards, unpinnedDriverCards, unpinnedVehicleCards } = useMemo(
    () => ({
      pinnedCards: cards.filter(isValidPinnedCard),
      unpinnedDriverCards: cards.filter(card => card?.driver?.id),
      unpinnedVehicleCards: cards.filter(card => card?.vehicleName)
    }),
    [cards]
  );

  const isLoading = useMemo(
    () => trackingData.isLoading || !companyCardViewSettings || isFetching,
    [trackingData.isLoading, companyCardViewSettings, isFetching]
  );

  useEffect(() => {
    dispatch(setPageTitle(t('Tracking.PageTitle')));
    dispatch(setBackButton(false));
    return () => {
      if (fetchRef.current != null) {
        fetchRef.current(); //call the assigned cancel funciton
        fetchRef.current = null;
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const { companyPinnedCards, companyCardSettings } = companyCardViewSettings;
    const _cards = (cardViewDevices || []).map(card => {
      const deviceStats = { ...card.deviceStats };
      const shouldHideNonBusiness =
        nonBusinessCompanyConfig && deviceStats.attr === CompanyConfigValue.Private;

      let isPinned = companyPinnedCards && !!companyPinnedCards[card.id],
        cardType =
          companyPinnedCards && companyPinnedCards[card.id] && companyPinnedCards[card.id].cardType,
        showMap = !shouldHideNonBusiness
          ? companyCardSettings &&
            companyCardSettings[card.id] &&
            companyCardSettings[card.id]?.mapMode
          : false;

      deviceStats.location = !shouldHideNonBusiness
        ? deviceStats.location
        : t('Tracking.NonBusiness');

      return { ...card, isPinned, cardType, showMap, deviceStats };
    });
    setCards(_cards);
  }, [companyCardViewSettings, cardViewDevices, isLoading]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    let newCardStates = [...cards];
    setCards(arrayMove(newCardStates, oldIndex, newIndex));
  };

  const onPinClick = useCallback(
    card => {
      card.isPinned = !card.isPinned;
      const updatedCards = cards.map(c =>
        c.id === card.id ? { ...c, isPinned: card.isPinned } : c
      );
      setCards(updatedCards);

      let updatedCompanyPinnedCards = { ...(companyCardViewSettings?.companyPinnedCards || {}) };
      if (!card.isPinned && updatedCompanyPinnedCards[card.id]) {
        delete updatedCompanyPinnedCards[card.id];
      } else if (card.isPinned) {
        updatedCompanyPinnedCards[card.id] = {
          deviceId: card.id,
          cardType: card.cardType
        };
      }

      const cardViewPinnedCards = { ...(userPreferences?.cardViewPinnedCards || {}) };
      let pinnedCards = Object.values(updatedCompanyPinnedCards || {});
      pinnedCards = (pinnedCards && pinnedCards.filter(pinnedCard => !!pinnedCard)) || [];
      //Remove duplicated and clean up un-associated device-driver/vehicle
      const validPinnedCards = [];
      pinnedCards.forEach(pinnedCard => {
        if (
          validPinnedCards.every(
            existing =>
              !(
                existing.deviceId === pinnedCard.deviceId &&
                existing.cardType === pinnedCard.cardType
              )
          ) &&
          isValidPinnedCard({
            ...(updatedCards.find(c => c.id === pinnedCard.deviceId) || {}),
            ...pinnedCard
          })
        ) {
          validPinnedCards.push(pinnedCard);
        }
      });
      cardViewPinnedCards[company.id] = JSON.stringify(validPinnedCards);

      saveUserPrefs({
        ...userPreferences,
        ...{ cardViewPinnedCards }
      });
    },
    [cards, company, companyCardViewSettings, userPreferences]
  );

  const toggleMapView = useCallback(
    card => {
      card.showMap = !card.showMap;
      setCards(cards.map(c => (c.id === card.id ? { ...c, showMap: card.showMap } : c)));

      const cardViewSettings = { ...(userPreferences?.cardViewSettings || {}) };
      let updatedCompanyCardSettings = { ...(companyCardViewSettings?.companyCardSettings || {}) };
      if (
        !card.showMap &&
        updatedCompanyCardSettings[card.id] &&
        updatedCompanyCardSettings[card.id].mapMode
      ) {
        delete updatedCompanyCardSettings[card.id].mapMode;
        if (Object.keys(updatedCompanyCardSettings[card.id].length === 0)) {
          delete updatedCompanyCardSettings[card.id];
        }
      } else if (card.showMap) {
        updatedCompanyCardSettings = {
          ...updatedCompanyCardSettings,
          ...{ [card.id]: { ...updatedCompanyCardSettings[card.id], mapMode: true } }
        };
      }

      cardViewSettings[company.id] = JSON.stringify(updatedCompanyCardSettings);

      saveUserPrefs(
        {
          ...userPreferences,
          ...{ cardViewSettings }
        },
        true
      );
    },
    [cards, company, companyCardViewSettings, userPreferences]
  );

  const saveUserPrefs = useCallback(
    (updatedUserPreferences, silenceFailure) => {
      setUserPreferences(updatedUserPreferences, user.id, userKey)
        .then(res => {
          dispatch(updatePreferences(updatedUserPreferences));
        })
        .catch(e => {
          if (silenceFailure) {
            //For settings should not prevent user doing so even save failed at this point.
            dispatch(updatePreferences(updatedUserPreferences));
          } else {
            dispatch(
              openToast({
                type: ToastType.Error,
                message: `${t('Preferences.Save.ErrorMessage')}.`
              })
            );
          }
        });
    },
    [user, userKey]
  );

  return (
    <TrackingPage>
      <CardViewPanel
        cards={cards}
        geofences={trackingData.geofences}
        axis="xy"
        pressDelay={200}
        toggleMapView={toggleMapView}
        pinnedCards={pinnedCards}
        unpinnedDriverCards={unpinnedDriverCards}
        unpinnedVehicleCards={unpinnedVehicleCards}
        onPinClick={onPinClick}
        onSortEnd={onSortEnd}
        isLoading={isLoading}
        shouldCancelStart={e => {
          return (e?.path || []).some(path => path?.classList?.contains('cardViewMap'));
        }}
      />
    </TrackingPage>
  );
};
