import React, { useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Radio, Row, Col, Button } from 'antd';
import { GeoSuggest } from 'components/GeoSuggest';
import styles from './SwitchStatusModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export function SwitchStatusModal({ driver, showModal, onSave, onCancel, ...props }) {
  const { t } = useTranslation();
  const [statusValue, setStatusValue] = useState('OnDuty');
  const [locationValue, setLocationValue] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const geoSuggestRef = useRef(null);

  const handleSaveRecord = useCallback(
    closeModal => {
      setIsSubmitting(true);
      if (onSave) {
        const eventData = {
          id: null,
          EventDateTime: new Date().toISOString(),
          //RecordType: null,
          DriverId: parseInt(driver?.id),
          Action: statusValue,
          Timezone: driver?.Timezone,
          EntryAt: new Date().toISOString(),
          DeclaredRegistration: null,
          DeclaredOdometer: null,
          DeclaredLocation: locationValue,
          Uuid: null,
          Location: locationValue,
          Source: 'Driver',
          Notes: null,
          Parameters: null,
          DeviceId: null,
          GPS: null,
          Odo: null,
          Lat: null,
          Lng: null,
          Manifest: null,
          Shipper: null,
          Commodity: null,
          CoDriverId: null,
          StreetAddress: null,
          City: null,
          Country: null,
          State: null,
          ZipCode: null,
          EditedBy: driver?.id,
          VehicleId: null,
          Trailer1Id: null,
          Trailer2Id: null,
          Status: 'E',
          IsDriverType: true
        };

        onSave(eventData, closeModal);
      }
      setIsSubmitting(false);
    },
    [onSave, statusValue, locationValue]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleStatusChange = e => {
    setStatusValue(e.target.value);
  };

  const handleAddressChanged = useCallback(suggest => {
    if (suggest != null) {
      const val = suggest.label;
      setLocationValue(val);
    }
  }, []);

  const footer = (
    <Row gutter={10}>
      <Col>
        <Button
          loading={isSubmitting}
          onClick={handleSaveRecord}
          disabled={locationValue.trimStart().trimEnd().length < 5}
          type="primary"
          id={BUTTON_IDS.driverPortalSwitchStatusSave}
        >
          {t('Common.Save')}
        </Button>
      </Col>
      <Col>
        <Button
          disabled={isSubmitting}
          id={BUTTON_IDS.driverPortalSwitchStatusCancel}
          type="default"
          onClick={handleCancel}
        >
          {t('Common.Cancel')}
        </Button>
      </Col>
    </Row>
  );

  return (
    <Modal
      open={showModal}
      title={t('ELD.DriverPortal.SwitchStatus')}
      closable={true}
      onOk={handleSaveRecord}
      onCancel={handleCancel}
      footer={footer}
      className={styles.modalClass}
    >
      <div>
        <div>{t('Common.Status')}</div>
        <Radio.Group onChange={handleStatusChange} value={statusValue}>
          <Radio value={'OnDuty'}>{t('ELD.DutyStatus.On Duty')}</Radio>
          <Radio value={'OffDuty'}>{t('ELD.DutyStatus.Off Duty')}</Radio>
        </Radio.Group>
        <div>{t('Common.Location')}</div>
        <GeoSuggest
          tabIndex={0}
          placeholder={t('Tracking.Proximity.PlaceOrAddressPlaceholder')}
          ref={geoSuggestRef}
          onSuggestSelect={handleAddressChanged}
          autoComplete="off"
          onChange={val => setLocationValue(val)}
        />
      </div>
    </Modal>
  );
}
