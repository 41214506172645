import React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Table } from 'antd';

import styles from './ViewsConfigModal.module.scss';

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

export const SortableTable = ({ dataSource, columns, onSortEnd, ...restProps }) => {
  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass={styles.rowDragging}
      onSortEnd={({ oldIndex, newIndex }) => onSortEnd(oldIndex, newIndex)}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, isFixed, ...restProps }) => {
    const index = dataSource.findIndex(x => x.key === restProps['data-row-key']);
    const item = dataSource.find(x => x.key === restProps['data-row-key']);
    return (
      <SortableItem
        index={index}
        disabled={item?.isFixed}
        className={styles.sortableRow}
        {...restProps}
      />
    );
  };

  return (
    <Table
      {...restProps}
      dataSource={dataSource}
      columns={columns}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow
        }
      }}
    />
  );
};
