import { Progress } from 'antd';
import cn from 'classnames';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { FileUpload } from 'components/FileUpload';
import FormSelect from 'components/form/form-select/FormSelect';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useCurrentCompany } from 'features/company/companySlice';
import {
  changeDocumentStep,
  changeUploadCompanyId,
  getConfig,
  removeUploadedFile,
  uploadFile,
  useConfig,
  useGetFiles,
  useGetUploadCompanyId,
  useIsFileUploading,
  useUploadInProgress
} from 'features/easydocs/documentsSlice';
import { fetchCompanyFolders } from 'features/easydocs/foldersSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { formatBytes } from 'utils/methods';
import { initialValuesForAddDocument } from '../constants';
import { FormCard } from './FormCard';
import styles from './UploadFilesForm.module.scss';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { BUTTON_IDS } from 'utils/globalConstants';

// CONSTANTS
const PROGRESS_TRAIL_COLOR = '#dadee3';
const PROGRESS_STROKE_COLOR_GREEN = '#26bd8b';
const PROGRESS_STROKE_COLOR_YELLOW = '#ffae42';
const PROGRESS_STROKE_COLOR_RED = 'red';

const UPLOAD_COMPANY_SELECT_NAME = 'uploadCompanySelect';

export const UploadFilesForm = props => {
  const { t } = useTranslation();
  const { companies } = props;
  const dispatch = useDispatch();
  const isFileUploading = useIsFileUploading();
  const currentlyUploading = useUploadInProgress();
  const currentCompany = useCurrentCompany();
  const uploadedFiles = useGetFiles();
  const uploadConfig = useConfig();
  const uploadCompanyId = useGetUploadCompanyId();
  const selectCompanies = (companies || []).map(company => ({
    label: company.name,
    value: company.id
  }));
  const [uploadProgress, setUploadProgress] = useState(30);
  const [filesToOverwrite, setOverwriteFiles] = useState([]);
  const { totalUploadBytesThisMonth, totalUploadLimitBytes, maxFileSizeBytes } = uploadConfig || {};
  const maxFileSize = formatBytes(maxFileSizeBytes);
  const [uploadPer, setUploadPercentage] = useState(0);

  useEffect(() => {
    dispatch(getConfig(uploadCompanyId));
    dispatch(fetchCompanyFolders(uploadCompanyId));
  }, [uploadCompanyId, filesToOverwrite]);

  useEffect(() => {
    if (!currentlyUploading) return;

    const interval = setInterval(() => {
      if (uploadProgress < 100) setUploadProgress(uploadProgress + 5);
    }, 1000);

    return function cleanup() {
      clearInterval(interval);
    };
  }, [uploadProgress, currentlyUploading]);

  // Handlers
  const handleClick = step => () => {
    dispatch(changeDocumentStep(step));
  };

  async function handleChange(event) {
    const {
      target: { files }
    } = event;
    const existingFiles = [];

    for (const file of files) {
      if (!file) continue;
      const { size, type } = file;

      if (type !== 'application/pdf') {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: i18next.t('Easydocs.Notifications.OnlyPdf', {
              name: file.name
            })
          })
        );
        continue;
      }

      // Check for the size of the file
      if (size > maxFileSizeBytes) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: i18next.t('Easydocs.Notifications.HigherSize', {
              name: file.name,
              maxSize: maxFileSize
            })
          })
        );
        continue;
      }

      const response = await dispatch(uploadFile(file));

      if (response?.fileExists) {
        existingFiles.push(file);
      }
    }

    setOverwriteFiles(existingFiles);
    if (existingFiles.length) {
      confirmationModal(
        `${t('Easydocs.Notifications.AreYouSure')}`,
        `${t('Easydocs.Notifications.FollowingFiles')} ${existingFiles
          .map(f => f.name)
          .join(', ')} ${t('Easydocs.Notifications.AlreadyExists')}`,
        `${t('Easydocs.Notifications.Yes')}`,
        `${t('Easydocs.Notifications.No')}`,
        handleFileOverwrite(existingFiles)
      );
    }
  }

  const handleFileOverwrite = existingFiles => async () => {
    const fileList = [...existingFiles];
    setOverwriteFiles([]);
    for (const file of fileList) {
      await dispatch(uploadFile(file, true));
      await dispatch(getConfig(uploadCompanyId));
    }
  };

  const handleFileRemove = id => () => {
    dispatch(removeUploadedFile(id));
  };

  const handleCompanySelect = setFieldValue => id => {
    setFieldValue(UPLOAD_COMPANY_SELECT_NAME, id);
    dispatch(changeUploadCompanyId(id));
  };

  const renderProgressFooter = () => {
    const uploadPercentage = (totalUploadBytesThisMonth / totalUploadLimitBytes) * 100;
    setUploadPercentage(uploadPercentage);

    const strokeColor =
      uploadPercentage < 50
        ? PROGRESS_STROKE_COLOR_GREEN
        : uploadPercentage < 90
        ? PROGRESS_STROKE_COLOR_YELLOW
        : PROGRESS_STROKE_COLOR_RED;

    return !isNaN(totalUploadBytesThisMonth) && !isNaN(totalUploadLimitBytes) ? (
      <div>
        {`${t('Easydocs.TotalUploaded')} ${formatBytes(totalUploadBytesThisMonth)} of ${formatBytes(
          totalUploadLimitBytes
        )} (${parseFloat(uploadPercentage || 0)?.toFixed(2)}%)`}
        <Progress
          percent={uploadPercentage}
          strokeColor={strokeColor}
          trailColor={PROGRESS_TRAIL_COLOR}
          showInfo={false}
        />
      </div>
    ) : (
      <div>Loading usage stats...</div>
    );
  };

  const buttonClasses = cn(styles.nextButton, {
    [styles.disabled]:
      isFileUploading ||
      !uploadedFiles ||
      !uploadedFiles.length ||
      !uploadedFiles.some(uf => uf.success)
  });

  const CARD_BODY = (
    <>
      <span>{t('Easydocs.ChooseDocuments')}</span>
      <br />
      <span>{t('Easydocs.OnlyPdf')}</span> <br />
      {!isNaN(maxFileSizeBytes) && <span>{`${t('Easydocs.OnlyPdf')} ${maxFileSize};`}</span>}
    </>
  );
  const filesToUpload = uploadedFiles.filter(file => !(!file.success && file.fileExists));

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...initialValuesForAddDocument, uploadCompanySelect: currentCompany.id }}
      >
        {({ setFieldValue }) => (
          <Form id="form">
            <div className={styles.container}>
              <div className={styles.companySelect}>
                <span className={styles.companySelectLabel}>{t('Easydocs.SelectCompany')}</span>{' '}
                <FormSelect
                  name={UPLOAD_COMPANY_SELECT_NAME}
                  values={selectCompanies}
                  classNames={styles.companySelectDropdown}
                  onChange={handleCompanySelect(setFieldValue)}
                />
              </div>
              <div className={styles.cards}>
                <FormCard
                  header={t('Easydocs.Instructions')}
                  body={CARD_BODY}
                  footer={renderProgressFooter()}
                />
                <FormCard
                  header={t('Easydocs.UploadedDocuments')}
                  additionalItems={
                    <FileUpload
                      uploadedFiles={filesToUpload}
                      uploading={currentlyUploading}
                      progress={uploadProgress}
                      onAdd={handleChange}
                      onRemove={handleFileRemove}
                      isUploadCapacityFull={uploadPer >= 100 ? true : false}
                      secondary
                    />
                  }
                  size="large"
                />
              </div>
              <div className={styles.card}> </div>
              <Button
                id={BUTTON_IDS.easydocsFormUploadNext}
                className={buttonClasses}
                onClick={handleClick(2)}
                style={{ width: 'auto' }}
              >
                {t('Easydocs.Next')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
