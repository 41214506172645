import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Menu } from 'antd';
import { GeoSuggest } from 'components/GeoSuggest';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';

import {
  useUserPreferences,
  setUserPreferences,
  updatePreferences
} from 'features/user/userPreferencesSlice';
import { getGeofencePoints } from 'features/geofences/geofencesUtil';
import { useCompanyGeofenceProviders } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useUserKey, useUser } from 'features/user/userSlice';

import { VEHICLE_MARKER_LABEL_TYPES } from 'containers/Settings/user/Preferences/Preferences';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useGeofenceSuggests } from 'components/GeoSuggest/useGeofenceSuggests';

import { getBoundsZoomLevel, getPointsZoomLevel } from 'utils/geo';

import styles from './MapMenu.module.scss';
import { DEFAULT_PREFERENCES } from 'containers/Settings/constants';
import { entities, useCanEveryEntity } from 'features/permissions';

export const MapMenu = ({
  mapRef,
  defaultMapType,
  enableGeofenceSuggest,
  hideVehicleMenu,
  onSearchLocationSelected,
  onMapTypeSelected,
  onMapLayerToggled,
  onVehicleLabelSelected,
  onVehicleClusteringToggled,
  onSearchLocationCleared,
  onCreateGeofenceSelected
}) => {
  const { t } = useTranslation();
  const userPreferences = useUserPreferences();
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const user = useUser();
  const userPrefsMenuItems = [];
  const geofenceProviders = useCompanyGeofenceProviders();
  const { clearSuggests, geoSuggestProps } = useGeofenceSuggests();

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(userPrefsMenuItems);
  const [isTrafficLayerActive, setIsTrafficLayerActive] = useState(true);
  const [openItems, setOpenItems] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const canGeofence = useCanEveryEntity([entities.GEOFENCE_CREATE]);

  const previousPresetMapType = useRef(defaultMapType);

  useEffect(() => {
    if (userPreferences && isInitialLoad) {
      loadUserPrefs(userPreferences);
      setIsInitialLoad(false);
    } else if (previousPresetMapType.current !== defaultMapType) {
      loadUserPrefs(userPreferences);
      previousPresetMapType.current = defaultMapType;
    }
  }, [userPreferences, defaultMapType, isInitialLoad]);

  useEffect(() => {
    if (selectedItems.indexOf('map.type.satellite') > -1) {
      setIsTrafficLayerActive(false);
    } else {
      setIsTrafficLayerActive(true);
    }
  }, [selectedItems]);

  const clearLocationSearch = useCallback(() => {
    console.debug('MapMenu - clearLocationSearch', openItems);
    setIsSearchOpen(false);
    setShowSearchResults(false);
    if (enableGeofenceSuggest) {
      clearSuggests();
    }
  }, [enableGeofenceSuggest, clearSuggests]);

  const loadUserPrefs = userPreferences => {
    const mapType = `map.type.${defaultMapType ||
      userPreferences?.mapType ||
      DEFAULT_PREFERENCES.mapType}`;
    userPrefsMenuItems.push(mapType);

    if (userPreferences?.mapLayerTraffic) {
      userPrefsMenuItems.push('map.layers.traffic');
      onMapLayerToggled && onMapLayerToggled('traffic', true);
    }

    if (userPreferences?.vehicleMarker) {
      let vehicleLabelType = userPreferences?.vehicleMarker.substring(
        userPreferences?.vehicleMarker.lastIndexOf('.') + 1
      );
      userPrefsMenuItems.push('vehicle.label.' + vehicleLabelType);
      onVehicleLabelSelected && onVehicleLabelSelected(vehicleLabelType);
    } else {
      userPrefsMenuItems.push('vehicle.label.' + VEHICLE_MARKER_LABEL_TYPES.vehicleNameDriverName);
      onVehicleLabelSelected &&
        onVehicleLabelSelected(VEHICLE_MARKER_LABEL_TYPES.vehicleNameDriverName);
    }

    userPreferences?.clustering && userPrefsMenuItems.push('vehicle.clustering');

    userPreferences?.showGeofences && userPrefsMenuItems.push('geofence.showGeofences');
    userPreferences?.showManagedGeofences &&
      userPrefsMenuItems.push('geofence.showManagedGeofences');

    setSelectedItems(userPrefsMenuItems);
  };

  const saveUserPrefs = (menuGroup, menuItem, isActive) => {
    const newUserPreferences = {
      ...userPreferences,
      ...(menuGroup === 'map.type' && { mapType: menuItem }),
      ...(menuGroup === 'map.layers' && menuItem === 'traffic' && { mapLayerTraffic: isActive }),
      ...(menuGroup === 'map.type' && menuItem === 'satellite' && { mapLayerTraffic: false }),
      ...(menuGroup === 'vehicle' && menuItem === 'clustering' && { clustering: isActive }),
      ...(menuGroup === 'vehicle.label' && { vehicleMarker: menuItem }),
      ...(menuGroup === 'geofence' && menuItem === 'showGeofences' && { showGeofences: isActive }),
      ...(menuGroup === 'geofence' &&
        menuItem === 'showManagedGeofences' && { showManagedGeofences: isActive })
    };

    setUserPreferences(newUserPreferences, user.id, userKey)
      .then(res => {
        dispatch(updatePreferences(newUserPreferences));
      })
      .catch(e => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: `${t('Preferences.Save.ErrorMessage')}.`
          })
        );
      });
  };

  const onMenuItemClicked = key => {
    if (!key) {
      return;
    }

    const lastDotIndex = key.lastIndexOf('.');
    const menuGroup = key.substring(0, lastDotIndex);
    const menuItem = key.substring(lastDotIndex + 1);
    const isActive = !selectedItems.some(item => item === key);

    let items =
      key.split('.').length > 2
        ? selectedItems.filter(item => !item.startsWith(menuGroup)) // enforce radio button behavior on menu item groups
        : selectedItems;

    if (key === 'map.type.satellite') {
      items = items.filter(item => !item.startsWith('map.layers'));
    }

    if (key !== 'geofence.addNewGeofence') {
      if (isActive) {
        // add selection
        setSelectedItems([...items, ...[key]]);
      } else {
        // remove selection
        if (items.includes(key)) {
          items.splice(items.indexOf(key), 1);
        }
        setSelectedItems(items);
      }

      saveUserPrefs(menuGroup, menuItem, isActive);
    }

    if (onMapTypeSelected && menuGroup === 'map.type') {
      onMapTypeSelected(menuItem);
    }
    if (onMapLayerToggled && menuGroup === 'map.layers') {
      onMapLayerToggled(menuItem, isActive);
    }
    if (onVehicleClusteringToggled && key === 'vehicle.clustering') {
      onVehicleClusteringToggled(isActive);
    }
    if (onVehicleLabelSelected && menuGroup === 'vehicle.label') {
      onVehicleLabelSelected(menuItem);
    }
    if (key === 'geofence.addNewGeofence') {
      onCreateGeofenceSelected(menuItem);
    }
  };

  const onSuggestSelect = suggest => {
    if (suggest) {
      clearLocationSearch();

      const bounds = suggest.gmaps?.geometry?.viewport;
      if (bounds) {
        const zoom = getBoundsZoomLevel(bounds, {
          height: mapRef.current?.getDiv().clientHeight,
          width: mapRef.current?.getDiv().clientWidth
        });
        onSearchLocationSelected &&
          onSearchLocationSelected(
            {
              lat: suggest.location.lat,
              lng: suggest.location.lng
            },
            zoom
          );
      } else {
        onSearchLocationSelected &&
          onSearchLocationSelected(
            {
              lat: suggest.location.lat,
              lng: suggest.location.lng
            },
            getPointsZoomLevel({
              points: suggest?.geofence ? getGeofencePoints(suggest.geofence, true) : [],
              mapDim: {
                height: mapRef.current?.getDiv().clientHeight,
                width: mapRef.current?.getDiv().clientWidth
              }
            })
          );
      }
    } else if (onSearchLocationCleared) {
      onSearchLocationCleared();
    }
  };

  const mapMenuItems = [
    {
      key: 'search',
      icon: <FontAwesomeIcon icon={faSearch} />,
      children: [
        {
          key: 'search.result',
          className: showSearchResults ? styles.searchbarResults : styles.searchbar,
          label: (
            <GeoSuggest
              inputClassName={styles.searchbarInput}
              placeholder={t(
                enableGeofenceSuggest
                  ? 'Tracking.PlaceOrAddressOrGeofence'
                  : 'Tracking.PlaceOrAddress'
              )}
              onSuggestSelect={onSuggestSelect}
              onUpdateSuggests={(suggests, activeSuggest) => {
                setShowSearchResults(suggests.length > 0);
              }}
              onSuggestNoResults={input => {
                setShowSearchResults(false);
              }}
              onClick={e => {
                e.stopPropagation();
              }}
              {...(enableGeofenceSuggest && geoSuggestProps)}
            />
          )
        }
      ]
    },
    {
      key: t('Common.Map'),
      label: t('Common.Map'),
      children: [
        {
          type: 'group',
          label: t('Common.Type'),
          key: 'map.type',
          children: [
            {
              key: 'map.type.roadmap',
              label: t('Preferences.MapSettings.Map.Type.Road')
            },
            {
              key: 'map.type.terrain',
              label: t('Preferences.MapSettings.Map.Type.Terrain')
            },
            {
              key: 'map.type.satellite',
              label: t('Preferences.MapSettings.Map.Type.Satellite')
            },
            {
              key: 'map.type.hybrid',
              label: t('Preferences.MapSettings.Map.Type.Hybrid')
            }
          ]
        },
        {
          type: 'group',
          label: t('Map.Layers'),
          key: 'map.layers',
          children: [
            {
              key: 'map.layers.traffic',
              label: t('Map.Traffic'),
              disabled: !isTrafficLayerActive
            }
          ]
        }
      ]
    }
  ];

  !hideVehicleMenu &&
    mapMenuItems.push({
      key: t('Common.Vehicle'),
      label: t('Common.Vehicle'),
      children: [
        { label: t('Map.Clustering'), key: 'vehicle.clustering' },
        {
          type: 'group',
          label: t('Common.Label'),
          key: 'vehicle.label',
          children: [
            {
              label: t('Map.VehicleName'),
              key: 'vehicle.label.' + VEHICLE_MARKER_LABEL_TYPES.vehicleName
            },
            {
              label: t('Map.DriverName'),
              key: 'vehicle.label.' + VEHICLE_MARKER_LABEL_TYPES.driverName
            },
            {
              label: t('Map.VehicleDriverName'),
              key: 'vehicle.label.' + VEHICLE_MARKER_LABEL_TYPES.vehicleNameDriverName
            },
            {
              label: t('Map.RegistrationNumber'),
              key: 'vehicle.label.' + VEHICLE_MARKER_LABEL_TYPES.registrationNumber
            }
          ]
        }
      ]
    });

  const geofenceSubMenuItem = {
    key: 'GeofenceSubmenu',
    label: t('Common.Geofence'),
    children: [
      {
        key: 'geofence.showGeofences',
        label: t('Preferences.TrackingSettings.ShowGeofences')
      }
    ]
  };

  geofenceProviders?.length &&
    geofenceSubMenuItem?.children.push({
      key: 'geofence.showManagedGeofences',
      label: t('Preferences.TrackingSettings.ShowManagedGeofences')
    });

  canGeofence &&
    onCreateGeofenceSelected &&
    geofenceSubMenuItem?.children.push({
      key: 'geofence.addNewGeofence',
      label: t('Preferences.TrackingSettings.AddNewGeofence')
    });

  mapMenuItems.push(geofenceSubMenuItem);

  return (
    <div className={cn(styles.mapMenuContainer, { [styles.collapsed]: !isMenuExpanded })}>
      <span
        className={styles.menuIcon}
        onClick={e => {
          setIsMenuExpanded(!isMenuExpanded);
        }}
      >
        <FontAwesomeIcon icon={faBars} />
      </span>

      {isMenuExpanded && (
        <Menu
          mode="horizontal"
          className={styles.mapMenu}
          selectedKeys={selectedItems}
          items={mapMenuItems}
          openKeys={openItems}
          onClick={item => {
            // console.debug('MapMenu - onClick:', item.key);
            onMenuItemClicked(item.key);
          }}
          onSelect={(e, item) => {
            clearLocationSearch();
          }}
          onDeselect={item => {}}
          onOpenChange={openKeys => {
            if (
              (openKeys.includes('search') && openKeys.length === 1) ||
              (isSearchOpen && !openKeys.length)
            ) {
              setOpenItems(['search']);
              setIsSearchOpen(true);
            } else {
              const index = openKeys.indexOf('search');
              if (index > -1) {
                openKeys.splice(openKeys.indexOf('search'), 1);
              }
              setOpenItems(openKeys);
              clearLocationSearch();
            }
          }}
        />
      )}
    </div>
  );
};
