/* global google */
import { useCompanyGeofenceProviders } from 'features/company/companySlice';
import {
  getGeofencePoints,
  isManagedGeofence,
  getGeofenceStyle,
  isDrawableGeofence,
  GEOFENCE_DEFAULT_COLORS
} from 'features/geofences/geofencesUtil';
import React, { useCallback, useMemo } from 'react';
import { Polygon } from 'react-google-maps';
import { MultiPolygon } from './shapes/MultiPolygon';

const PolygonType = {
  Polygon: 0,
  Multipolygon: 1
};

const SELECTED_GEOFENCE_STROKE_COLOR = 'red';

export function _GeofenceLayer({
  geofences,
  mapBounds,
  openGeofenceInfoWindow,
  onShapeEdited,
  selectedGeofence,
  mapMode,
  boundsTimestamp
}) {
  const geofenceProviders = useCompanyGeofenceProviders();

  const handlePolygonClick = useCallback(
    (geofence, event) => {
      if (mapMode !== 'Edit Geofence' && event) {
        openGeofenceInfoWindow(geofence, event);
      }
    },
    [mapMode]
  );

  const handleMouseUp = useCallback((geofence, style, ref) => {
    if (geofence.isEditable && onShapeEdited) {
      onShapeEdited(ref.current, style);
    }
  }, []);

  const processedGeofences = useMemo(() => {
    const geos = [];
    for (const geofence of geofences) {
      if (!isDrawableGeofence(geofence)) {
        continue;
      }

      let polygonBounds = null;
      if (mapMode !== 'Geofence Map View') {
        polygonBounds = new google.maps.LatLngBounds();
      }
      if (isManagedGeofence(geofenceProviders, geofence)) {
        const points = getGeofencePoints(geofence);
        const paths = (points || []).map(pointSet =>
          pointSet.map(p => {
            const point = {
              lat: p.Lat,
              lng: p.Lng
            };

            if (polygonBounds) polygonBounds.extend(point);
            return point;
          })
        );
        if (polygonBounds && !mapBounds?.intersects(polygonBounds)) {
          continue;
        }

        geos.push({
          type: PolygonType.Multipolygon,
          dashedBorder: true,
          id: geofence.id,
          paths: paths,
          editable: geofence.isEditable,
          fillColor: getGeofenceStyle(geofenceProviders, geofence).color,
          onClick: event => handlePolygonClick(geofence, event),
          onMouseUp: event => handleMouseUp(geofence, null)
        });
      } else {
        const ref = React.createRef(null);
        const style = geofence.style ? JSON.parse(geofence.style) : {};
        let strokeColor = style.colour || GEOFENCE_DEFAULT_COLORS.NON_MANAGED;
        const fillColor = strokeColor;
        const weight = style.weight || 2;

        const latLngArray = geofence?.shape?.points?.map(p => {
          const point = {
            lat: p.Lat,
            lng: p.Lng
          };
          if (polygonBounds) polygonBounds.extend(point);
          return point;
        });

        if (polygonBounds && !mapBounds?.intersects(polygonBounds)) {
          continue;
        }

        geos.push({
          ref: ref,
          type: PolygonType.Polygon,
          id: geofence.id,
          path: latLngArray,
          onClick: event => handlePolygonClick(geofence, event),
          onMouseUp: event => handleMouseUp(geofence, style, ref),
          editable: geofence.isEditable,
          options: {
            strokeColor: strokeColor,
            strokeOpacity: 0.8,
            strokeWeight: weight,
            fillColor: fillColor,
            fillOpacity: 0.3
          }
        });
      }
    }
    return geos;
  }, [geofences, mapBounds, mapMode, handlePolygonClick, geofenceProviders]);

  return (
    processedGeofences?.map(geofence =>
      geofence.type === PolygonType.Polygon ? (
        <Polygon
          key={geofence.id}
          {...geofence}
          options={
            selectedGeofence?.id === geofence.id
              ? { ...geofence.options, strokeColor: SELECTED_GEOFENCE_STROKE_COLOR }
              : geofence.options
          }
        />
      ) : (
        <MultiPolygon
          key={geofence.id}
          {...geofence}
          selected={geofence.id === selectedGeofence?.id}
        />
      )
    ) || ''
  );
}

export const GeofenceLayer = React.memo(
  _GeofenceLayer,
  (prevProps, nextProps) =>
    prevProps.geofences === nextProps.geofences &&
    prevProps.selectedGeofence === nextProps.selectedGeofence &&
    prevProps.mapMode === nextProps.mapMode &&
    (prevProps.mapMode === 'Geofence Map View' ||
      prevProps.boundsTimestamp === nextProps.boundsTimestamp)
);
