import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { Alert, InputNumber, Space, Tooltip, Checkbox } from 'antd';
import { Layout, Select, TabsFilters, Button } from 'components/ant';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import {
  Buckets,
  Entities,
  EntityTypes,
  EventTypes,
  Paths,
  TabKeys,
  TabsMetricScoring
} from './constants';

import { setBackButton, setPageTitle, addBackButtonLink } from 'features/page/pageSlice';
import { useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import {
  useBranches,
  useIsFetching as isLocationsFetching
} from 'features/locations/locationsSlice';
import {
  fetchFleets,
  useFleetsList,
  useIsFetching,
  useIsFetchingFinished
} from 'features/fleets/fleetsSlice';
import {
  fetchBuckets,
  updateBuckets,
  useBuckets,
  useBucketDefaults,
  fetchEnabledEventTypes
} from 'features/scorecard';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { getTabs } from 'utils/tabs';
import {
  confirmationModalMetricScoring,
  prepareEventsForValues,
  setNewValues,
  toggleAllEntities,
  getEnabledEventTypesForMetricScoring,
  resetEnabledEventTypes
} from './helpers';
import { useLocalization } from 'features/localization/localizationSlice';
import { asyncForEach } from 'utils/methods';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { clearScore } from 'features/scorecard/reducers';
import { Can, useCanOneOfEntities, entities } from 'features/permissions';

import style from './Scorecard.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const MetricScoring = () => {
  const { Header, Content, Footer } = Layout;
  const { t } = useTranslation();
  const { tab: initialTab } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const currentCompany = useCurrentCompany();
  const allBranches = useBranches();
  const isBranchesFetching = isLocationsFetching();
  const allFleets = useFleetsList();
  const isFleetsFetching = useIsFetching();
  const isFleetsFetchingFinished = useIsFetchingFinished();
  const companies = useSubCompanies();
  const companiesForSelect = companies.map(company => ({ id: company.id, label: company.name }));
  const localization = useLocalization(true);
  const canUpdateBuckets = useCanOneOfEntities([entities.SCORECARD_UPDATE]);
  const buckets = useBuckets(
    initialTab === TabKeys.drivers ? Entities.BRANCH : Entities.FLEET,
    0,
    currentCompany?.id
  );
  const bucketDefaults = useBucketDefaults();

  const [activeTab, setActiveTab] = useState(initialTab);
  const [filteredBranches, setFilteredBranches] = useState([]);
  const [filteredFleets, setFilteredFleets] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedBranch, setSelectedBranch] = useState(0);
  const [selectedFleet, setSelectedFleet] = useState(0);
  const [selectedEntity, setSelectedEntity] = useState(
    initialTab === TabKeys.drivers ? Entities.BRANCH : Entities.FLEET
  );
  const [selectedEntityType, setSelectedEntityType] = useState(
    initialTab === TabKeys.drivers ? EntityTypes.Location : EntityTypes.Fleet
  );
  const [values, setValues] = useState(prepareEventsForValues(EventTypes(localization)));
  const [isUpdatingBuckets, setIsUpdatingBuckets] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [dirtyTab, setDirtyTab] = useState();
  const [enabledEventTypes, setEnabledEventTypes] = useState([]);

  const handleTabChange = useCallback(
    tab => {
      const changeTab = () => {
        setActiveTab(tab);
        setSelectedBranch(0);
        setSelectedFleet(0);
        if (tab === TabKeys.drivers) {
          setSelectedEntity(Entities.BRANCH);
          setSelectedEntityType(EntityTypes.Location);
          dispatch(
            fetchBuckets({ entityName: Entities.BRANCH, entityId: 0, companyId: selectedCompany })
          );
        } else {
          setSelectedEntity(Entities.FLEET);
          setSelectedEntityType(EntityTypes.Fleet);
          dispatch(
            fetchBuckets({ entityName: Entities.FLEET, entityId: 0, companyId: selectedCompany })
          );
        }
      };
      if (!dirty) {
        changeTab();
        setDirtyTab();
      } else {
        setDirtyTab(tab);
      }
    },
    [dirty, selectedCompany, dispatch]
  );

  const updateEntitiesAndBuckets = companyId => {
    toggleAllEntities(companyId, allBranches, setFilteredBranches, t('Users.AllBranches'));
    toggleAllEntities(companyId, allFleets, setFilteredFleets, t('Common.AllFleets'));
    dispatch(fetchBuckets({ entityName: selectedEntity, entityId: 0, companyId }));
    setSelectedCompany(companyId);
    setSelectedBranch(0);
    setSelectedFleet(0);
    setDirty(false);
  };

  useEffect(() => {
    updateEntitiesAndBuckets(currentCompany.id);
  }, [currentCompany]);

  const handleCompanyChange = companyId => {
    const changeCompany = () => {
      updateEntitiesAndBuckets(companyId);
    };

    confirmationModalMetricScoring(confirmationModal, changeCompany, t('Common.Company'), dirty, t);
  };

  const handleBranchChange = branchId => {
    const changeBranch = () => {
      if (branchId === 0) {
        dispatch(
          fetchBuckets({ entityName: selectedEntity, entityId: 0, companyId: selectedCompany })
        );
      } else {
        dispatch(
          fetchBuckets({
            entityName: selectedEntity,
            entityId: branchId,
            companyId: selectedCompany
          })
        );
      }
      setSelectedBranch(branchId);
      setDirty(false);
    };

    confirmationModalMetricScoring(confirmationModal, changeBranch, t('Common.Branch'), dirty, t);
  };

  const handleFleetChange = fleetId => {
    const changeFleet = () => {
      if (fleetId === 0) {
        dispatch(
          fetchBuckets({ entityName: selectedEntity, entityId: 0, companyId: selectedCompany })
        );
      } else {
        dispatch(
          fetchBuckets({
            entityName: selectedEntity,
            entityId: fleetId,
            companyId: selectedCompany
          })
        );
      }
      setSelectedFleet(fleetId);
      setDirty(false);
    };

    confirmationModalMetricScoring(confirmationModal, changeFleet, t('Common.Fleet'), dirty, t);
  };

  const handleValueChange = (newValues, key, index) => {
    const oldValues = JSON.parse(JSON.stringify(values));
    oldValues[key][index] = Number(newValues);

    setValues(oldValues);
    setDirty(true);
  };

  const handleSave = async () => {
    const result = [];
    let index = 0;
    setIsUpdatingBuckets(true);
    await asyncForEach(Object.keys(values), async key => {
      result[index] = await dispatch(
        updateBuckets({
          entityType: selectedEntityType,
          entityId: selectedEntity === Entities.BRANCH ? selectedBranch : selectedFleet,
          type: key,
          values: values[key],
          companyId: selectedCompany,
          enabled: enabledEventTypes?.find(x => x.key === key)?.enabled
        })
      );
      index += 1;
    });
    const hasErrors = result.some(res => !!res.error);
    if (!hasErrors) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('Scorecard.SuccessEdit')
        })
      );
    } else {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Scorecard.ErrorEdit')
        })
      );
    }
    setDirty(false);
    setIsUpdatingBuckets(false);
    fetchBuckets({
      entityName: selectedEntity,
      entityId: selectedEntity === Entities.BRANCH ? selectedBranch : selectedFleet,
      companyId: selectedCompany
    });
    dispatch(
      fetchEnabledEventTypes({
        companyId: selectedCompany,
        entityType: selectedEntity,
        entityId: 0
      })
    );
  };

  //TODO: uncomment when undo action is fixed
  // const handleUndo = () => {
  //   setNewValues({ values, buckets, setValues, localization });
  //   setDirty(true);
  // };

  const handleResetToDefault = () => {
    setNewValues({ values, buckets: bucketDefaults, setValues, localization });
    resetEnabledEventTypes(enabledEventTypes);
    setDirty(true);
  };

  const onEventCheckedChange = event => {
    var newEnabledEventTypes = [...enabledEventTypes];
    newEnabledEventTypes.forEach(ev => {
      if (ev.key === event.key) ev.enabled = !event.enabled;
    });

    setEnabledEventTypes(newEnabledEventTypes);
    setDirty(true);
  };

  useEffect(() => {
    dispatch(setPageTitle(t('Scorecard.MetricScoring')));
    dispatch(setBackButton(true));
    dispatch(
      addBackButtonLink({
        url: `${location.pathname}`,
        backLink: `${Paths.MAIN_PAGE}/${TabKeys[activeTab]}`
      })
    );
  }, [dispatch]);

  // Set values when buckets are loaded from the API
  useEffect(() => {
    setNewValues({ values, buckets, setValues, localization });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buckets]);

  // Select all branches & all fleets. Triggered when we get currentCompany, all the branches or all fleets from APIs.
  useEffect(() => {
    toggleAllEntities(currentCompany.id, allBranches, setFilteredBranches, t('Users.AllBranches'));
    toggleAllEntities(currentCompany.id, allFleets, setFilteredFleets, t('Common.AllFleets'));
  }, [allBranches, currentCompany, allFleets, t]);

  // Set default selected company. Triggered when we get the currentCompany.
  useEffect(() => {
    setSelectedCompany(currentCompany.id);
    setSelectedBranch(0);
    setSelectedFleet(0);
  }, [currentCompany]);

  // Get fleets if activeTab is Vehicles
  useEffect(() => {
    if (activeTab === TabKeys.vehicles && !isFleetsFetching && !isFleetsFetchingFinished) {
      dispatch(fetchFleets());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    return () => {
      dispatch(clearScore());
    };
  }, [dispatch]);

  // Next 2 effects triggered when changing the tab
  useEffect(() => {
    if (dirtyTab) {
      setDirty(false);
    }
  }, [location]);

  useEffect(() => {
    if (dirtyTab && !dirty) {
      handleTabChange(dirtyTab);
    }
  }, [dirtyTab, dirty, handleTabChange]);

  useEffect(() => {
    const events = getEnabledEventTypesForMetricScoring(
      Object.values(EventTypes(localization)),
      buckets
    );

    setEnabledEventTypes(events);
  }, [buckets]);

  return (
    <Layout>
      <Header>
        <div className={style.spaceAround}>
          <div>
            <TabsFilters tabs={getTabs(TabsMetricScoring, activeTab, handleTabChange, t)} />
          </div>
        </div>
      </Header>
      <Content className={style.content}>
        <EditRouteGuard when={dirty} navigate={history.push} />
        <Alert
          message={t('Scorecard.Warning')}
          description={t('Scorecard.MetricWarningV2')}
          type="warning"
          showIcon
          className={style.alertMessage}
          closable
        />
        <div className={`${style.filterWrapper} ${style.spaceAround}`}>
          <Space size={16}>
            <Select
              size="default"
              className={style.select}
              placeholder={t(`Common.Company`)}
              data={companiesForSelect}
              value={selectedCompany}
              onChange={handleCompanyChange}
            />
            {activeTab === TabKeys.drivers && (
              <Select
                data={filteredBranches}
                onChange={handleBranchChange}
                className={style.select}
                loading={isBranchesFetching}
                value={selectedBranch}
              />
            )}
            {activeTab === TabKeys.vehicles && (
              <Select
                data={filteredFleets}
                onChange={handleFleetChange}
                className={style.select}
                loading={isFleetsFetching}
                value={selectedFleet}
              />
            )}
          </Space>
        </div>
        <div className={style.buckets + ' showScrollbarsOnHover'}>
          <div className={`${style.bucketsHeader} ${style.bucketRow}`}>
            <div className={style.metricName}>{t('Scorecard.MetricName').toUpperCase()}</div>
            {Buckets.map((bucket, index) => (
              <div key={`b-${index}`} className={style.bucket}>
                {t(bucket)}
              </div>
            ))}
          </div>
          {enabledEventTypes.map((event, index) => (
            <div key={`event-${index}`} className={style.bucketRow}>
              <Checkbox
                checked={event?.enabled ? true : false}
                onChange={() => onEventCheckedChange(event)}
                disabled={!canUpdateBuckets}
              />
              <div className={style.metricName}>{t(event.name)}</div>
              {event.buckets.map((bucket, bucketIndex) => (
                <div key={`bucket-${index}-${bucketIndex}`} className={style.bucket}>
                  <Tooltip
                    title={!canUpdateBuckets || !event.enabled ? t('Scorecard.DisabledMetric') : ''}
                  >
                    <span>
                      <InputNumber
                        className={style.bucketInput}
                        min={0}
                        formatter={value => parseInt(value, 10)}
                        parser={value => parseInt(value, 10)}
                        onChange={newValue => handleValueChange(newValue, event.key, bucketIndex)}
                        value={values[event.key][bucketIndex]}
                        disabled={!canUpdateBuckets || !event.enabled}
                      />
                    </span>
                  </Tooltip>
                  <div className={style.bucketInterval}>
                    (
                    {bucket.map((interval, index) => {
                      const separator = index < bucket.length - 1 ? ',' : '';
                      if (!interval.min && !interval.max) {
                        return `${t(`Scorecard.${event.unit[index]}`)}${separator}`;
                      } else {
                        if (!interval.min) {
                          return `<${interval.max}${event.unit[index]}${separator}`;
                        }
                        if (!interval.max) {
                          return `${interval.min}+${event.unit[index]}${separator}`;
                        }
                        return `${interval.min}-${interval.max}${event.unit[index]}${separator}`;
                      }
                    })}
                    )
                  </div>
                </div>
              ))}
              {event.emptyBuckets?.map((_, i) => (
                <div key={`i-${i}`} className={style.bucket}>
                  -
                </div>
              ))}
            </div>
          ))}
        </div>
      </Content>
      <Footer>
        <Can oneOfEntities={[entities.SCORECARD_UPDATE]}>
          <Space size={16}>
            <Button
              type="primary"
              size="large"
              onClick={handleSave}
              loading={isUpdatingBuckets}
              id={BUTTON_IDS.metricScoringSave}
            >
              {t('Common.Save')}
            </Button>
            {/*<Tooltip title={t('Scorecard.UndoTooltip')}>*/}
            {/*  <Button type="secondary" size="large" onClick={handleUndo}>*/}
            {/*    {t('Scorecard.Undo')}*/}
            {/*  </Button>*/}
            {/*</Tooltip>*/}
            <Tooltip title={t('Scorecard.ResetToDefaultTooltip')}>
              <Button
                type="secondary"
                size="large"
                onClick={handleResetToDefault}
                id={BUTTON_IDS.metricScoringReset}
              >
                {t('Scorecard.ResetToDefault')}
              </Button>
            </Tooltip>
          </Space>
        </Can>
      </Footer>
    </Layout>
  );
};
