import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//components
import { AuditsTable } from 'components/auditsTable/AuditsTable';

//slices
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits } from 'features/audits';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';

// helpers
import { getIDFromPathname } from 'utils/methods';
import { useUserData } from './APICalls';

//constants
import { AUDITS_ACTIONS, AUDIT_COMMENTS, PATHS } from './constants';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { useHistory } from 'react-router';

export const UserAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const audits = useAudits(AUDIT_ENTITY.USER, id);
  const history = useHistory();
  const handleUserFetchError = useCallback(() => {
    history.replace(PATHS.USERS_DEFAULT);
  }, [history]);
  const user = useUserData({
    id: id,
    refetch: false,
    onError: handleUserFetchError,
    embedParam: ''
  })?.userViewData;
  const pageTitle = !user
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: `${user?.firstName} ${user?.lastName}` });

  const filteredAudits = audits.filter(
    audit =>
      !(
        audit.action === AUDITS_ACTIONS.LOGIN &&
        (audit.comment === AUDIT_COMMENTS.LOGIN_SUCCESSFULLY ||
          audit.comment === AUDIT_COMMENTS.INTERNAL_LOGIN)
      ) && audit.action !== AUDITS_ACTIONS.LOGOUT
  );

  useRedirectToMainFeaturePageOnCompanyChange('/settings/users');

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle, dispatch]);

  return <AuditsTable audits={filteredAudits} entity={AUDIT_ENTITY.USER} />;
};
