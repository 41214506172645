/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import LocalDateTime from "./LocalDateTime";
import User from "./User";

/**
 * The Notification model module.
 * @module model/Notification
 * @version 1.0
 */
class Notification {
  /**
   * Constructs a new <code>Notification</code>.
   * @alias module:model/Notification
   */
  constructor() {
    Notification.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Notification</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Notification} obj Optional instance to populate.
   * @return {module:model/Notification} The populated <code>Notification</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Notification();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("user")) {
        obj["user"] = User.constructFromObject(data["user"]);
      }
      if (data.hasOwnProperty("filtered")) {
        obj["filtered"] = ApiClient.convertToType(data["filtered"], "Boolean");
      }
      if (data.hasOwnProperty("sms")) {
        obj["sms"] = ApiClient.convertToType(data["sms"], "Boolean");
      }
      if (data.hasOwnProperty("email")) {
        obj["email"] = ApiClient.convertToType(data["email"], "Boolean");
      }
      if (data.hasOwnProperty("onscreen")) {
        obj["onscreen"] = ApiClient.convertToType(data["onscreen"], "Boolean");
      }
      if (data.hasOwnProperty("popup")) {
        obj["popup"] = ApiClient.convertToType(data["popup"], "Boolean");
      }
      if (data.hasOwnProperty("eventId")) {
        obj["eventId"] = ApiClient.convertToType(data["eventId"], "Number");
      }
      if (data.hasOwnProperty("eventType")) {
        obj["eventType"] = ApiClient.convertToType(data["eventType"], "String");
      }
      if (data.hasOwnProperty("docId")) {
        obj["docId"] = ApiClient.convertToType(data["docId"], "String");
      }
      if (data.hasOwnProperty("eventAt")) {
        obj["eventAt"] = ApiClient.convertToType(data["eventAt"], "Date");
      }
      if (data.hasOwnProperty("dismissedAt")) {
        obj["dismissedAt"] = LocalDateTime.constructFromObject(
          data["dismissedAt"]
        );
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = LocalDateTime.constructFromObject(data["createdAt"]);
      }
      if (data.hasOwnProperty("updatedAt")) {
        obj["updatedAt"] = LocalDateTime.constructFromObject(data["updatedAt"]);
      }
      if (data.hasOwnProperty("notificationType")) {
        obj["notificationType"] = ApiClient.convertToType(
          data["notificationType"],
          "String"
        );
      }
      if (data.hasOwnProperty("description")) {
        obj["description"] = ApiClient.convertToType(
          data["description"],
          "String"
        );
      }
      if (data.hasOwnProperty("event")) {
        obj["event"] = ApiClient.convertToType(data["event"], Object);
      }
      if (data.hasOwnProperty("detailsUrl")) {
        obj["detailsUrl"] = ApiClient.convertToType(
          data["detailsUrl"],
          "String"
        );
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Notification.prototype["id"] = undefined;

/**
 * @member {module:model/User} user
 */
Notification.prototype["user"] = undefined;

/**
 * @member {Boolean} filtered
 */
Notification.prototype["filtered"] = undefined;

/**
 * @member {Boolean} sms
 */
Notification.prototype["sms"] = undefined;

/**
 * @member {Boolean} email
 */
Notification.prototype["email"] = undefined;

/**
 * @member {Boolean} onscreen
 */
Notification.prototype["onscreen"] = undefined;

/**
 * @member {Boolean} popup
 */
Notification.prototype["popup"] = undefined;

/**
 * @member {Number} eventId
 */
Notification.prototype["eventId"] = undefined;

/**
 * @member {module:model/Notification.EventTypeEnum} eventType
 */
Notification.prototype["eventType"] = undefined;

/**
 * @member {String} docId
 */
Notification.prototype["docId"] = undefined;

/**
 * @member {Date} eventAt
 */
Notification.prototype["eventAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} dismissedAt
 */
Notification.prototype["dismissedAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
Notification.prototype["createdAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} updatedAt
 */
Notification.prototype["updatedAt"] = undefined;

/**
 * @member {String} notificationType
 */
Notification.prototype["notificationType"] = undefined;

/**
 * @member {String} description
 */
Notification.prototype["description"] = undefined;

/**
 * @member {Object} event
 */
Notification.prototype["event"] = undefined;

/**
 * @member {String} detailsUrl
 */
Notification.prototype["detailsUrl"] = undefined;

/**
 * Allowed values for the <code>eventType</code> property.
 * @enum {String}
 * @readonly
 */
Notification["EventTypeEnum"] = {
  /**
   * value: "ALARM"
   * @const
   */
  ALARM: "ALARM",

  /**
   * value: "PRETRIP"
   * @const
   */
  PRETRIP: "PRETRIP",

  /**
   * value: "SPEED"
   * @const
   */
  SPEED: "SPEED",

  /**
   * value: "SUMMARY"
   * @const
   */
  SUMMARY: "SUMMARY",

  /**
   * value: "GEO_ENTRY"
   * @const
   */
  GEO_ENTRY: "GEO_ENTRY",

  /**
   * value: "GEO_EXIT"
   * @const
   */
  GEO_EXIT: "GEO_EXIT",

  /**
   * value: "GEO_UNDERTIME"
   * @const
   */
  GEO_UNDERTIME: "GEO_UNDERTIME",

  /**
   * value: "GEO_OVERTIME"
   * @const
   */
  GEO_OVERTIME: "GEO_OVERTIME",

  /**
   * value: "GEO_SPEED"
   * @const
   */
  GEO_SPEED: "GEO_SPEED",

  /**
   * value: "TRIP_ENTRY"
   * @const
   */
  TRIP_ENTRY: "TRIP_ENTRY",

  /**
   * value: "TRIP_EXIT"
   * @const
   */
  TRIP_EXIT: "TRIP_EXIT",

  /**
   * value: "TRIP_STARTED"
   * @const
   */
  TRIP_STARTED: "TRIP_STARTED",

  /**
   * value: "TRIP_FINISHED"
   * @const
   */
  TRIP_FINISHED: "TRIP_FINISHED",

  /**
   * value: "FATIGUE_VIOLATION"
   * @const
   */
  FATIGUE_VIOLATION: "FATIGUE_VIOLATION",

  /**
   * value: "FATIGUE_PREDICTED"
   * @const
   */
  FATIGUE_PREDICTED: "FATIGUE_PREDICTED",

  /**
   * value: "IO"
   * @const
   */
  IO: "IO",

  /**
   * value: "SCHEDULE"
   * @const
   */
  SCHEDULE: "SCHEDULE",

  /**
   * value: "MESSAGE"
   * @const
   */
  MESSAGE: "MESSAGE",

  /**
   * value: "COMPANY"
   * @const
   */
  COMPANY: "COMPANY",

  /**
   * value: "TRIP"
   * @const
   */
  TRIP: "TRIP",

  /**
   * value: "DRIVER"
   * @const
   */
  DRIVER: "DRIVER",

  /**
   * value: "VEHICLE"
   * @const
   */
  VEHICLE: "VEHICLE",

  /**
   * value: "RUNSHEET"
   * @const
   */
  RUNSHEET: "RUNSHEET",

  /**
   * value: "JOB"
   * @const
   */
  JOB: "JOB",

  /**
   * value: "TPMS"
   * @const
   */
  TPMS: "TPMS",

  /**
   * value: "VPM_EA"
   * @const
   */
  VPM_EA: "VPM_EA",

  /**
   * value: "VPM_IT"
   * @const
   */
  VPM_IT: "VPM_IT",

  /**
   * value: "VPM_HB"
   * @const
   */
  VPM_HB: "VPM_HB",

  /**
   * value: "VPM_OR"
   * @const
   */
  VPM_OR: "VPM_OR",

  /**
   * value: "VPM_HC"
   * @const
   */
  VPM_HC: "VPM_HC",

  /**
   * value: "VPM_EOT"
   * @const
   */
  VPM_EOT: "VPM_EOT",

  /**
   * value: "VPM_EOP"
   * @const
   */
  VPM_EOP: "VPM_EOP",

  /**
   * value: "VPM_ECT"
   * @const
   */
  VPM_ECT: "VPM_ECT",

  /**
   * value: "FORM"
   * @const
   */
  FORM: "FORM",

  /**
   * value: "POSITION"
   * @const
   */
  POSITION: "POSITION",

  /**
   * value: "CAMERA"
   * @const
   */
  CAMERA: "CAMERA",

  /**
   * value: "REALTIME"
   * @const
   */
  REALTIME: "REALTIME",

  /**
   * value: "MOVING"
   * @const
   */
  MOVING: "MOVING",

  /**
   * value: "STATIONARY"
   * @const
   */
  STATIONARY: "STATIONARY",
  
  /**
   * value: "ELD"
   * @const
   */
   ELD_VIOLATION: "ELD_Violation"
};

export default Notification;
