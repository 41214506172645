import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Table, Button, Checkbox, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { fetchUnsignedLogs, useUnsignedLogs } from 'features/eld/eldSlice';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';
import styles from './UnsignedLogsModal.module.scss';
import { Link } from 'react-router-dom';
import i18next from 'i18nextConfig';
import { BUTTON_IDS } from 'utils/globalConstants';

export function normalCellRenderer(value, rowData, index) {
  return (
    <div>
      <span>{value}</span>
    </div>
  );
}

class SortUtil {
  static compareDate(a, b) {
    const dateA = new Date(a).getTime();
    const dateB = new Date(b).getTime();
    if (dateA > dateB) {
      return 1;
    } else if (dateA === dateB) {
      return 0;
    } else {
      return -1;
    }
  }
  static compareNumber(a, b) {
    if (a > b) {
      return 1;
    } else if (a === b) {
      return 0;
    } else {
      return -11;
    }
  }
}
const genAntSortColumnConfig = (sortDir, defDir, sortFn, dataKey) => {
  return {
    sortDirections: sortDir,
    defaultSortOrder: defDir,
    sorter: sortFn && ((a, b, sort) => sortFn(a[dataKey], b[dataKey], sort)),
    ellipsis: false
  };
};
const defaultSortConfig = (sortFn, dataKey) =>
  genAntSortColumnConfig(['ascend', 'descend', 'ascend'], 'descend', sortFn, dataKey);

export function UnsignedLogsModal({
  driver,
  currentDate,
  showModal,
  onSave,
  onCancel,
  handleUnsignedLogClick,
  ...props
}) {
  const localization = useLocalization();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const logs = useUnsignedLogs();
  const [selectedDates, setSelectedDates] = useState([]);
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (driver.id) {
      dispatch(fetchUnsignedLogs(driver.id));
    }
  }, [dispatch, driver, onSave]);

  const logsData = useMemo(() => {
    let rows = logs?.map((d, idx) => {
      let row = Object.assign({}, d);

      row.dateUnformatted = row?.date;
      row.date = row?.date ? format(row.date, localization.formats.time.formats.dby) : '';

      let odo = row?.mileage;
      if (isNaN(odo)) {
        row.displayOdometer = '';
      } else {
        row.displayOdometer = localization.convertDistance(odo);
      }
      return row;
    });
    return rows;
  }, [logs]);

  const handleSaveRecord = useCallback(
    closeModal => {
      if (onSave) {
        const record = {
          DriverId: driver.id,
          Dates: selectedDates
        };
        onSave(record, closeModal);
      }
    },
    [onSave, selectedDates, driver]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleLogClick = useCallback(date => {
    if (handleUnsignedLogClick) {
      handleUnsignedLogClick(date);
    }
  }, []);

  const handleCheckboxClick = useCallback(
    (row, evt) => {
      if (evt?.target?.checked) {
        setSelectedDates([...selectedDates, row?.dateUnformatted]);
      } else {
        var index = selectedDates.indexOf(row?.dateUnformatted);
        if (index !== -1) {
          setSelectedDates(selectedDates.filter(a => a !== row?.dateUnformatted));
        }
      }
    },
    [selectedDates]
  );

  const footer = (
    <Row gutter={10}>
      <Col>
        <Button
          onClick={handleSaveRecord}
          disabled={selectedDates.length < 1 || !confirmed}
          type="primary"
          id={BUTTON_IDS.driverPortalUnsignedLogsSave}
        >
          {t('ELD.DriverPortal.SignDates')}
        </Button>
      </Col>
      <Col>
        <Button
          id={BUTTON_IDS.driverPortalUnsignedLogsCancel}
          type="default"
          onClick={handleCancel}
        >
          {t('Common.Cancel')}
        </Button>
      </Col>
    </Row>
  );

  function OdometerHeader() {
    const localization = useLocalization();
    return <>{`${t('ELD.Distance')} (${localization.formats.speed.unit.toUpperCase()})`}</>;
  }
  const columns = useMemo(() => {
    let columns = [
      {
        get title() {
          return i18next.t('ELD.DriverPortal.UnsignedLogsColumns.Date');
        },
        key: 'date',
        dataIndex: 'date',
        width: 100,
        render: normalCellRenderer,
        ...defaultSortConfig(SortUtil.compareDate, 'date')
      },
      {
        get title() {
          return <OdometerHeader />;
        },
        key: 'displayOdometer',
        dataIndex: 'displayOdometer',
        width: 150,
        render: normalCellRenderer,
        ...defaultSortConfig(SortUtil.compareNumber, 'displayOdometer')
      },
      {
        get title() {
          return i18next.t('ELD.DriverPortal.UnsignedLogsColumns.Status');
        },
        key: 'status',
        width: 100,
        render: () => {
          return i18next.t('ELD.DriverPortal.UnsignedLogsColumns.Unsigned');
        }
      },
      {
        get title() {
          return i18next.t('ELD.DriverPortal.UnsignedLogsColumns.Details');
        },
        key: 'details',
        width: 100,
        render: (text, row) => {
          return (
            <div>
              <Link onClick={() => handleLogClick(row?.date)} to="#">
                {i18next.t('ELD.DriverPortal.UnsignedLogsColumns.ViewLog')}
              </Link>
            </div>
          );
        }
      },
      {
        get title() {
          return (
            <Checkbox
              checked={!nonChecked}
              indeterminate={!allChecked && !nonChecked}
              onChange={onSelectAllChecked}
            />
          );
        },
        key: 'signall',
        render: (text, row) => {
          return (
            <div style={{ cursor: 'pointer' }} onClick={() => {}}>
              <Checkbox
                checked={selectedDates && selectedDates.includes(row?.dateUnformatted)}
                onChange={evt => {
                  handleCheckboxClick(row, evt);
                }}
              />
            </div>
          );
        }
      }
    ];

    const nonChecked = !selectedDates || selectedDates?.length === 0;
    const allChecked = selectedDates && selectedDates?.length === logs?.length;

    return columns;
  }, [logs, localization, selectedDates]);

  const onSelectAllChecked = useCallback(
    evt => {
      if (evt.target.checked) {
        let newArray = [];
        logs?.forEach(l => newArray.push(l.date));
        setSelectedDates(newArray);
      } else {
        setSelectedDates([]);
      }
    },
    [logs]
  );

  return (
    <Modal
      open={showModal}
      title={t('ELD.DriverPortal.UnsignedLogs')}
      closable={true}
      footer={footer}
      onOk={handleSaveRecord}
      onCancel={handleCancel}
      width={600}
    >
      <Table
        className={styles.unsignedLogsGrid}
        dataSource={logsData}
        columns={columns}
        pagination={false}
        scroll={{ y: 400 }}
        rowKey="date"
      />
      <Checkbox
        checked={confirmed}
        onChange={() => setConfirmed(!confirmed)}
        style={{ marginTop: '20px' }}
      >
        <span style={{ fontWeight: 'bold' }}>{t('ELD.DriverPortal.SignatureCertification')}</span>
      </Checkbox>
    </Modal>
  );
}
