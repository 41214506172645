import { useTranslation } from 'react-i18next';

import styles from './Common.module.scss';

export const DriverName = ({ device, children, ...props }) => {
  const { t } = useTranslation();
  return (
    <span {...props}>
      {children ? children : <span>{device.driverName}</span>}
      {device?.driver?.isVehicleDefaultDriver && (
        <span className={styles.parenthesis}>{t('Default')}</span>
      )}
    </span>
  );
};
