import React from 'react';
import styles from './DriverLog.module.scss';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';

export function ViolationView({ data, onMouseEnter, onMouseLeave, ruleSet, ...props }) {
  const locale = useLocalization();
  const { t } = useTranslation();
  const violationStyle = '';
  const startPeriod =
    data?.period?.start != null
      ? format(new Date(data?.period?.start), locale.formats.time.formats.dby_imsp)
      : t('Common.Unknown');
  const endPeriod =
    data?.period?.finish != null
      ? format(new Date(data?.period?.finish), locale.formats.time.formats.dby_imsp)
      : t('Common.Unknown');
  const subDetail = data?.detail?.substring(data?.detail?.indexOf(': ') + 2);
  const section = ruleSet.find(r => r.id === data?.rule?.id)?.section || '';
  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={styles.violationView}>
      <div className={violationStyle}></div>
      <div>
        <p>{section}</p>
        <p>{data?.rule?.description}</p>
      </div>
      <div>
        <p>
          <span>
            {t('ELD.Period') + ' ' + startPeriod} &rarr; {endPeriod}
          </span>
        </p>
        <p>{subDetail}</p>
      </div>
    </div>
  );
}
