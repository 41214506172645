import React, { useState, useEffect, useCallback } from 'react';
import { LoadingTable } from 'components/grid/LoadingTable';
import InfoRow from 'components/form/info-row/InfoRow';
import { format } from 'utils/dates';
import {
  deleteCompanyApi,
  restoreCompanyApi,
  useCompanies,
  useDeletedSubCompanies,
  useIsFetchingCompany,
  useIsFetchingDeletedSubCompany,
  useRedirectToMainFeaturePageOnCompanyChange,
  useSubCompanies
} from 'features/company/companySlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useDispatch } from 'react-redux';
import { useHistory, matchPath } from 'react-router';
import { useLocalization } from 'features/localization/localizationSlice';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import { useTranslation } from 'react-i18next';
import { PATHS } from './constants';
import { DeletableEntityType, DeletedEntityAlert } from 'features/common/deletedEntityAlert';

import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import styles from './SubCompanies.module.scss';
import { useRegions } from 'features/regions/regionsSlice';

export const SubCompaniesView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const regions = useRegions();
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );

  const handleFetchError = useCallback(() => {
    if (history.location.pathname !== PATHS.DEFAULT) {
      history.replace(PATHS.DEFAULT);
    }
  }, [history]);

  const currentSubCompanies = useSubCompanies();
  const deletedCompanies = useDeletedSubCompanies(true);
  const [companyList, setCompanyList] = useState([]);
  const [companyData, setCompanyData] = useState({});

  const localization = useLocalization();
  const hasCompaniesFetched = useIsFetchingCompany();
  const hasDeletedCompaniesFetched = useIsFetchingDeletedSubCompany();

  useRedirectToMainFeaturePageOnCompanyChange('/settings/subcompanies');

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    setCompanyList(currentSubCompanies.concat(deletedCompanies));
  }, [currentSubCompanies, deletedCompanies]);

  useEffect(() => {
    if (companyList?.length > 0) {
      const company = companyList.find(loc => parseInt(loc.id, 10) === parseInt(id, 10));
      setCompanyData(company);
    }
  }, [companyList, id]);

  useEffect(() => {
    dispatch(setPageTitle(`${companyData?.name || ''}`));
  }, [companyData, dispatch]);

  useEffect(() => {
    const parsedId = parseInt(id);
    if (
      matchPath(history.location.pathname, { path: PATHS.SUBCOMPANY_VIEW, exact: true }) &&
      (parsedId <= 0 ||
        isNaN(parsedId) ||
        (hasCompaniesFetched &&
          !currentSubCompanies?.some(l => l.id === parsedId) &&
          hasDeletedCompaniesFetched &&
          !deletedCompanies?.some(l => l.id === parsedId)))
    ) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [
    t,
    id,
    dispatch,
    handleFetchError,
    hasCompaniesFetched,
    currentSubCompanies,
    hasDeletedCompaniesFetched,
    deletedCompanies,
    history
  ]);

  // If no location data - render loading table
  if (!companyData) {
    return <LoadingTable columnSizes={[400]} />;
  }

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        dispatch(deleteCompanyApi(companyData, history));
        break;
      case 'restore':
        dispatch(restoreCompanyApi(companyData));
        break;
      default:
    }
  };

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={{ entityName: AUDIT_ENTITY.COMPANY, ...companyData }}
        editPath={`/settings/subcompanies/edit/id/${id}`}
        auditPath={`${PATHS.SUBCOMPANY_AUDITS}/${id}`}
        canUse="SUBCOMPANY"
        handleButtonAction={handleButtonAction}
        typeOfEntityToDelete={t('Common.company')}
      />
      <div
        style={{
          display: 'flex',
          flex: '1 0 0',
          flexDirection: 'column'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '32px',
            color: '#181c21'
          }}
          className="vehicleView"
        >
          {
            <DeletedEntityAlert
              entity={DeletableEntityType.Company}
              entityStatus={companyData?.status}
            />
          }
          <InfoRow
            label={t('SubCompanies.View.Name')}
            value={companyData ? companyData.name : ''}
            styles={styles}
          />
          <InfoRow
            label={t('SubCompanies.View.ParentCompany')}
            value={companyList.find(i => i.id === companyData.parent?.id)?.name}
            styles={styles}
          />
          <InfoRow
            label={t('SubCompanies.View.Identifier')}
            value={companyData.slug}
            styles={styles}
          />
          <InfoRow
            label={t('SubCompanies.View.ExternalId')}
            value={companyData.externalId}
            styles={styles}
          />
          <InfoRow
            label={t('SubCompanies.View.Timezone')}
            value={companyData.statisticsTz}
            styles={styles}
          />
          <InfoRow
            label={t('SubCompanies.View.Country')}
            value={regions.find(i => i.code === companyData.country)?.name || ''}
            styles={styles}
          />
          <InfoRow
            label={t('SubCompanies.View.CreatedAt')}
            value={
              companyData.createdAt &&
              format(companyData.createdAt, localization.formats.time.formats.dby_imp)
            }
            styles={styles}
          />
          <InfoRow
            label={t('SubCompanies.View.UpdatedAt')}
            value={
              companyData.updatedAt &&
              format(companyData.updatedAt, localization.formats.time.formats.dby_imp)
            }
            styles={styles}
          />
          <InfoRow
            label={t('SubCompanies.View.Language')}
            value={t(`Preferences.ChangeLanguage.${companyData?.locale}`)}
            styles={styles}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
