import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Can, entities, services } from 'features/permissions';

import { NoAccessBody } from 'components/pages/NoAccess';
import ConfiguredVehicles from './ConfiguredVehicles';
import MassConfigurations from './MassConfigurations';
import VehicleCombinations from './VehicleCombinations';

export const Router = () => {
  return (
    <Can everyEntity={[entities.VEHICLE]} everyService={[services.MASS]} onFail={<NoAccessBody />}>
      <Switch>
        <Route path={'/settings/massmanagement/combinations'}>
          <VehicleCombinations />
        </Route>
        <Route path={'/settings/massmanagement/configurations'}>
          <MassConfigurations />
        </Route>
        <Route path={['/settings/massmanagement/configured', '/settings/massmanagement']}>
          <ConfiguredVehicles />
        </Route>
      </Switch>
    </Can>
  );
};
