import { createSlice } from '@reduxjs/toolkit';
import * as sentinelAPI from 'features/sentinel/endpoints/sentinelAPI';
import { useSelector } from 'react-redux';

export const initialState = {
  audits: {},
  error: null
};

const audits = createSlice({
  name: 'audits',
  initialState,
  reducers: {
    getAuditsSuccess(state, action) {
      if (action.payload.length) {
        const eventId = action.payload[0].entityId;
        state.audits[eventId] = action.payload;
      }
      state.error = null;
    },
    getAuditsFailed(state, action) {
      state.audits = {};
      state.error = action.payload;
    }
  }
});

export const { getAuditsSuccess, getAuditsFailed } = audits.actions;

export default audits.reducer;

export const fetchEventAudits = eventId => async dispatch => {
  try {
    const auditsData = await sentinelAPI.auditEvent(eventId);
    dispatch(getAuditsSuccess(auditsData));
  } catch (err) {
    dispatch(getAuditsFailed(err.toString()));
  }
};

export const useSentinelAudits = eventID => {
  const audits = useSelector(state => state.sentinel.audits);
  return audits[eventID];
};
