import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import arrayMove from 'array-move';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import request from 'superagent';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useLocation, useHistory } from 'react-router';
import { useUserKey } from 'features/user/userSlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import { openToast } from 'features/toasts/toastsSlice';
import {
  fetchInspectionChecklists,
  useInspectionChecklists,
  useDVIRInspectionTemplate
} from 'features/inspectionChecklist/inspectionChecklistSlice';
import { MPTrackingEvents, useFormUpdatedFields } from 'features/mixpanel';

import FormElement from 'components/form-builder/form-element/Element';
import {
  SortableItem,
  SortableWrapper,
  SortableFailedItem,
  SortableFailedWrapper
} from 'components/form-builder/form-preview/Preview';
import FormInput from 'components/form/form-input/FormInput';
import FormRadio from 'components/form/form-radio/FormRadio';
import FormSelect from 'components/form/form-select/FormSelect';
import { ToastType } from 'components/notifications/toasts/Toast';
import { getFleetsByCompany } from 'containers/Administration/Fleets/APICalls';
import SearchableListMultiSelect from 'components/form/searchable-list-multi-select/SearchableListMultiSelect';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { QuestionExtraFields } from './QuestionExtraFields';

import { initialValues, validationSchema } from './constants';

import yesnoIcon from 'static/images/icons/formBuilder/yesno.svg';
import textIcon from 'static/images/icons/formBuilder/text.svg';
import selectionIcon from 'static/images/icons/formBuilder/selection.svg';
import numericIcon from 'static/images/icons/formBuilder/numeric.svg';
import okIcon from 'static/images/icons/formBuilder/ok.svg';
import signatureIcon from 'static/images/icons/formBuilder/signature.svg';
import checkboxIcon from 'static/images/icons/check_box_outline_blank.svg';

import { canHistoryGoBack } from 'utils/methods';
import { API_PATH } from 'config';

import styles from './InspectionChecklist.module.scss';
import './InspectionChecklist.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { elementIsVisibleInViewport } from '../Forms/helpers';
import { useCanFeatureFlag, FeatureFlag } from 'features/permissions';

const DvirInspectionForm = ({ action, isDvir }) => {
  const { t } = useTranslation();
  const companies = useCompanies();
  const currentCompany = useCurrentCompany();
  const location = useLocation();
  const checklistId = useMemo(() => location.state?.dataToEdit?.id, [location]);
  const history = useHistory();
  const allInspections = useInspectionChecklists();
  const template = useDVIRInspectionTemplate();
  const [formCompany, setFormCompany] = useState(
    companies.find(c => c.id === location?.state?.dataToEdit?.company?.id) || {}
  );
  const [initialLoad, setInitialLoad] = useState(true);
  const [fleetsLoaded, setFleetsLoaded] = useState(true);
  useEffect(() => {
    setInitialLoad(false);
    if (!initialLoad) {
      setFormCompany(currentCompany);
    }
  }, [currentCompany]);
  const userKey = useUserKey();

  const allFleets = useFleets();
  const [fleets, setFleets] = useState([]);
  const [userChecked, setUserChecked] = useState(fleets);
  const [isFirstFormContainerVisible, setIsFirstFormContainerVisible] = useState(true);

  const firstFormContainer = document.querySelector(`#firstFormContainer`);
  const formPreviewContainer = document.querySelector(`.${styles.formPreview}`);
  const formPreviewContainerHeight = formPreviewContainer?.offsetHeight;

  const checkFirstFormContainerVisibility = () => {
    if (firstFormContainer) {
      setIsFirstFormContainerVisible(elementIsVisibleInViewport(firstFormContainer, true));
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', checkFirstFormContainerVisibility);
    return () => {
      window.removeEventListener('wheel', checkFirstFormContainerVisibility);
    };
  });

  useEffect(() => {
    const company = formCompany.id ? formCompany : currentCompany;
    const formFleets = [...allFleets]
      .filter(fleet => fleet.id)
      .map(fleet => {
        const companyFleet = companies.find(company => company.id === fleet.company?.id);
        return {
          value: fleet.id,
          label: fleet?.name ? `${fleet.name} (${companyFleet?.name || ''})` : '',
          checked: false
        };
      });
    formFleets.push({
      value: -1,
      label: `${t('PreTripChecklist.Form.NoFleet')}`,
      checked: false
    });
    formFleets.unshift(
      {
        label: `${t('PreTripChecklist.Form.Company')}`
      },
      {
        value: `c-${company.id}`,
        label: company.name,
        checked: false
      },
      {
        label: `${t('PreTripChecklist.Form.Fleet')}`
      }
    );

    setFleets(formFleets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFleets]);

  const dispatch = useDispatch();
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
  const [elements, setElements] = useState([]);
  const [failedElements, setFailedElements] = useState([]);
  const [activeElement, setActiveElement] = useState();
  const emptyElement = {
    Type: '',
    Icon: '',
    Text: ''
  };
  const [isDraggable, setisDraggable] = useState(false);
  const [initialAssociations, setInitialAssociations] = useState([]);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  let allElementsAreValid = true;

  const hasNewDvirInspection = useCanFeatureFlag({
    featureFlag: FeatureFlag.dvirInspection.flag
  });
  const { selectedType, selectedTemplate } = hasNewDvirInspection ? location.state : {};

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (action === 'edit') {
      const editableForm = { ...location.state.dataToEdit };

      const content = JSON.parse(editableForm.content);
      dispatch(
        setPageTitle(
          content.ChecklistName && `${t('PreTripChecklist.Form.Edit')} ${content.ChecklistName}`
        )
      );
    } else {
      if (isDvir) {
        dispatch(
          hasNewDvirInspection
            ? setPageTitle(`${t(selectedType.name)}`)
            : setPageTitle(`${t('InspectionChecklist.Form.AddDvirInspection')}`)
        );
      } else dispatch(setPageTitle(`${t('InspectionChecklist.Form.AddNew')}`));
    }
  }, [dispatch, action, location.state]);

  useEffect(() => {
    const parsedData = {};
    var content = {};
    content = action === 'edit' || !hasNewDvirInspection ? template : JSON.parse(selectedTemplate);

    var editableForm = {};
    if (action === 'edit') {
      editableForm = { ...location.state.dataToEdit };
      content = JSON.parse(editableForm.content);
    }
    const { Questions, FailedQuestions } = content;
    let elements = [];
    let failedElements = [];

    // Parse data for Questions
    (Questions || []).forEach(question => {
      const element = { ...question };
      if (element.PossibleAnswers) {
        element.Options = [...element.PossibleAnswers];
        delete element.PossibleAnswers;
        if (element.Options.length === 2) {
          if (element.Options[0].Value === 'Yes' && element.Options[1].Value === 'No') {
            element.Type = 'Yes/No';
            element.AllowNonApplicable = false;
          }
          if (element.Options[0].Value === 'Ok' && element.Options[1].Value === 'Not Ok') {
            element.Type = 'Ok/Not Ok';
            element.AllowNonApplicable = false;
          }
        }
        if (element.Options.length === 3) {
          if (
            element.Options[0].Value === 'Yes' &&
            element.Options[1].Value === 'No' &&
            element.Options[2].Value === 'N/A'
          ) {
            element.Type = 'Yes/No';
            element.AllowNonApplicable = true;
          }
          if (
            element.Options[0].Value === 'Ok' &&
            element.Options[1].Value === 'Not Ok' &&
            element.Options[2].Value === 'N/A'
          ) {
            element.Type = 'Ok/Not Ok';
            element.AllowNonApplicable = true;
          }
        }
      }

      if (element.Type === 'Selection') {
        element.Dropdown = false;
      }
      if (element.Type === 'MultiSelection') {
        element.Type = 'Selection';
        element.Dropdown = true;
      }
      if (element.Type === 'Numerical') {
        element.Type = 'Numeric';
      }
      if (element.SubFailedQuestions && element.SubFailedQuestions[0]) {
        if (element.SubFailedQuestions[0].PossibleAnswers) {
          element.SubFailedQuestions[0].Options = [
            ...element.SubFailedQuestions[0].PossibleAnswers
          ];
          delete element.SubFailedQuestions[0].PossibleAnswers;

          if (element.SubFailedQuestions[0].Options.length === 2) {
            if (
              element.SubFailedQuestions[0].Options[0].Value === 'Yes' &&
              element.SubFailedQuestions[0].Options[1].Value === 'No'
            ) {
              element.SubFailedQuestions[0].Type = 'Yes/No';
              element.SubFailedQuestions[0].AllowNonApplicable = false;
            }
            if (
              element.SubFailedQuestions[0].Options[0].Value === 'Ok' &&
              element.SubFailedQuestions[0].Options[1].Value === 'Not Ok'
            ) {
              element.SubFailedQuestions[0].Type = 'Ok/Not Ok';
              element.SubFailedQuestions[0].AllowNonApplicable = false;
            }
          }
          if (element.SubFailedQuestions[0].Options.length === 3) {
            if (
              element.SubFailedQuestions[0].Options[0].Value === 'Yes' &&
              element.SubFailedQuestions[0].Options[1].Value === 'No' &&
              element.SubFailedQuestions[0].Options[2].Value === 'N/A'
            ) {
              element.SubFailedQuestions[0].Type = 'Yes/No';
              element.SubFailedQuestions[0].AllowNonApplicable = true;
            }
            if (
              element.SubFailedQuestions[0].Options[0].Value === 'Ok' &&
              element.SubFailedQuestions[0].Options[1].Value === 'Not Ok' &&
              element.SubFailedQuestions[0].Options[2].Value === 'N/A'
            ) {
              element.SubFailedQuestions[0].Type = 'Ok/Not Ok';
              element.SubFailedQuestions[0].AllowNonApplicable = true;
            }
          }
        }
        if (element.SubFailedQuestions[0].Type === 'Selection') {
          element.SubFailedQuestions[0].Dropdown = false;
        }
        if (element.SubFailedQuestions[0].Type === 'MultiSelection') {
          element.SubFailedQuestions[0].Type = 'Selection';
          element.SubFailedQuestions[0].Dropdown = true;
        }
        if (element.SubFailedQuestions[0].Type === 'Numerical') {
          element.SubFailedQuestions[0].Type = 'Numeric';
        }
      }
      elements.push(element);
    });
    elements = elements.map(element => {
      switch (element.Type) {
        case 'Text':
          element.Icon = textIcon;
          break;
        case 'Numeric':
          element.Icon = numericIcon;
          break;
        case 'Selection':
          element.Icon = selectionIcon;
          break;
        case 'Yes/No':
          element.Icon = yesnoIcon;
          break;
        case 'Ok/Not Ok':
          element.Icon = okIcon;
          break;
        case 'Checkbox':
          element.Icon = checkboxIcon;
          break;
        case 'Signature':
          element.Icon = signatureIcon;
          break;
        default:
          break;
      }
      return element;
    });
    setElements(elements);

    //Parse data to Failed Questions
    (FailedQuestions || []).forEach(question => {
      const element = { ...question };
      if (element.PossibleAnswers) {
        element.Options = [...element.PossibleAnswers];
        delete element.PossibleAnswers;
        if (element.Options.length === 2) {
          if (element.Options[0].Value === 'Yes' && element.Options[1].Value === 'No') {
            element.Type = 'Yes/No';
            element.AllowNonApplicable = false;
          }
          if (element.Options[0].Value === 'Ok' && element.Options[1].Value === 'Not Ok') {
            element.Type = 'Ok/Not Ok';
            element.AllowNonApplicable = false;
          }
        }
        if (element.Options.length === 3) {
          if (
            element.Options[0].Value === 'Yes' &&
            element.Options[1].Value === 'No' &&
            element.Options[2].Value === 'N/A'
          ) {
            element.Type = 'Yes/No';
            element.AllowNonApplicable = true;
          }
          if (
            element.Options[0].Value === 'Ok' &&
            element.Options[1].Value === 'Not Ok' &&
            element.Options[2].Value === 'N/A'
          ) {
            element.Type = 'Ok/Not Ok';
            element.AllowNonApplicable = true;
          }
        }
      }
      if (element.Type === 'Selection') {
        element.Dropdown = false;
      }

      if (element.Type === 'MultiSelection') {
        element.Type = 'Selection';
        element.Dropdown = true;
      }
      if (element.Type === 'Numerical') {
        element.Type = 'Numeric';
      }
      failedElements.push(element);
    });
    failedElements = failedElements.map(element => {
      switch (element.Type) {
        case 'Text':
          element.Icon = textIcon;
          break;
        case 'Numeric':
          element.Icon = numericIcon;
          break;
        case 'Selection':
          element.Icon = selectionIcon;
          break;
        case 'Yes/No':
          element.Icon = yesnoIcon;
          break;
        case 'Ok/Not Ok':
          element.Icon = okIcon;
          break;
        default:
          break;
      }
      return element;
    });
    setFailedElements(failedElements);

    //Parse data to fill Associations
    const associations = JSON.parse(
      editableForm.associations || '{"company_id": [], "fleet_id": []}'
    );
    let initialAssociations = [];
    if (associations.company_id && associations.company_id.length === 1) {
      initialAssociations.push({ value: `c-${associations.company_id[0]}` });
    }
    initialAssociations = initialAssociations.concat(
      (associations.fleet_id || []).map(id => ({ value: parseInt(id, 10) }))
    );
    setInitialAssociations(initialAssociations);

    //Parse data to fill generic fields
    if (action === 'edit') {
      parsedData.Name = content.ChecklistName;
      parsedData.CreateMaintenance = editableForm.createMaintSchedule ? ['on'] : 'on';
    } else {
      parsedData.Name = 'DVIR ';
      parsedData.CreateMaintenance = ['on'];
    }
    parsedData.CompanyId = editableForm.company?.id ?? currentCompany.id;
    parsedData.VehicleRegistration = content.RequireVehicleRegistration ? ['on'] : 'on';
    setFormikInitialValues(parsedData);
  }, [location.state, action]);

  const checkIfElementsAreValid = () => {
    allElementsAreValid = true;
    let validationMessage = '';
    elements.forEach(element => {
      if (element.Text === '') {
        allElementsAreValid = false;
        setActiveElement(element);
        validationMessage = `${t('PreTripChecklist.FormValidation.TitleValidation')}`;
        setElements(
          elements.map(el => {
            if (el === element) {
              el.Invalid = true;
            }
            return el;
          })
        );
        return;
      }
      if (element.Options) {
        element.Options.forEach(option => {
          if (option.Value === '') {
            allElementsAreValid = false;
            setActiveElement(element);
            validationMessage = `${t('PreTripChecklist.FormValidation.SelectionMandatory')}`;
            setElements(
              elements.map(el => {
                if (el === element) {
                  el.Options = element.Options.map(opt => {
                    if (opt === option) {
                      opt.Invalid = true;
                    }
                    return opt;
                  });
                }
                return el;
              })
            );
            return;
          }
        });
      }
      if (element.SubFailedQuestions && element.SubFailedQuestions[0]) {
        if (element.SubFailedQuestions[0].Text === '') {
          allElementsAreValid = false;
          setActiveElement(element);
          validationMessage = `${t('PreTripChecklist.FormValidation.TitleFailed')}`;
          setElements(
            elements.map(el => {
              if (el === element) {
                el.Invalid = true;
              }
              return el;
            })
          );
          return;
        }
        if (element.SubFailedQuestions[0].Options) {
          element.SubFailedQuestions[0].Options.forEach(option => {
            if (option.Value === '') {
              allElementsAreValid = false;
              setActiveElement(element);
              validationMessage = `${t('PreTripChecklist.FormValidation.SelectionMandatory')}`;
              setElements(
                elements.map(el => {
                  if (el === element) {
                    el.SubFailedQuestions[0].Options = element.SubFailedQuestions[0].Options.map(
                      opt => {
                        if (opt === option) {
                          opt.Invalid = true;
                        }
                        return opt;
                      }
                    );
                  }
                  return el;
                })
              );
              return;
            }
          });
        }
      }
    });
    if (!allElementsAreValid) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: validationMessage,
          autohide: true
        })
      );
      return;
    }
  };

  const checkIfFailedElementsAreValid = () => {
    if (!allElementsAreValid) {
      return;
    }
    let validationMessage = '';
    failedElements.forEach(element => {
      if (element.Text === '') {
        allElementsAreValid = false;
        setActiveElement(element);
        validationMessage = `${t('PreTripChecklist.FormValidation.TitleValidation')}`;
        setFailedElements(
          failedElements.map(el => {
            if (el === element) {
              el.Invalid = true;
            }
            return el;
          })
        );
        return;
      }
      if (element.Options) {
        element.Options.forEach(option => {
          if (option.Value === '') {
            allElementsAreValid = false;
            setActiveElement(element);
            validationMessage = `${t('PreTripChecklist.FormValidation.SelectionMandatory')}`;
            setFailedElements(
              failedElements.map(el => {
                if (el === element) {
                  el.Options = element.Options.map(opt => {
                    if (opt === option) {
                      opt.Invalid = true;
                    }
                    return opt;
                  });
                }
                return el;
              })
            );
            return;
          }
        });
      }
    });
    if (!allElementsAreValid) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: validationMessage,
          autohide: true
        })
      );
      return;
    }
  };

  const addElement = (Type, Icon) => {
    checkIfElementsAreValid();
    checkIfFailedElementsAreValid();
    setTimeout(() => checkFirstFormContainerVisibility(), 400);
    if (!allElementsAreValid) {
      return;
    }
    const newElement = { ...emptyElement, Type, Icon };
    newElement.AvailableInState = template.Questions[0].AvailableInState;
    switch (Type) {
      case 'Text':
      case 'Numeric':
        newElement.AllowBlank = false;
        break;
      case 'Checkbox':
        newElement.Options = [{ Value: t('Forms.ActualForm.Defect'), Pass: false }];
        newElement.AllowNonApplicable = false;
        newElement.AllowBlank = true;
        newElement.FailChecklist = false;
        break;
      default:
        newElement.Dropdown = false;
        break;
    }
    setElements([...elements, newElement]);
    setActiveElement(newElement);
  };

  const removeElement = element => {
    setElements(elements.filter(el => el !== element));
  };

  const removeFailedElement = element => {
    setFailedElements(failedElements.filter(el => el !== element));
  };

  const setProperty = (element, property, value, isFailedQuestion) => {
    let setEl = setElements;
    let els = elements;
    if (isFailedQuestion) {
      setEl = setFailedElements;
      els = failedElements;
    }
    setEl(
      els.map(el => {
        delete el.Invalid;
        if (el.Options) {
          el.Options = el.Options.map(opt => {
            delete opt.Invalid;
            return opt;
          });
        }
        if (el === element) {
          if (value === null) {
            delete el[property];
          } else {
            el[property] = value;
          }
        }
        return el;
      })
    );
  };
  const setFailedQuestionProperty = (element, property, value) => {
    setElements(
      elements.map(el => {
        delete el.Invalid;
        if (el === element) {
          el.SubFailedQuestions[0][property] = value;
          if (property === 'Type') {
            switch (value) {
              case 'Yes/No':
                el.SubFailedQuestions[0].Options = [
                  { Value: 'Yes', Pass: true },
                  { Value: 'No', Pass: false }
                ];
                el.SubFailedQuestions[0].AllowBlank = false;
                el.SubFailedQuestions[0].AllowNonApplicable = false;
                break;
              case 'Ok/Not Ok':
                el.SubFailedQuestions[0].Options = [
                  { Value: 'Ok', Pass: true },
                  { Value: 'Not Ok', Pass: false }
                ];
                el.SubFailedQuestions[0].AllowBlank = false;
                el.SubFailedQuestions[0].AllowNonApplicable = false;
                break;
              case 'Selection':
                el.SubFailedQuestions[0].Options = [
                  { Value: '', Pass: true },
                  { Value: '', Pass: true }
                ];
                el.SubFailedQuestions[0].AllowBlank = false;
                delete el.SubFailedQuestions[0].AllowNonApplicable;
                break;
              default:
                el.SubFailedQuestions[0].AllowBlank = false;
                delete el.SubFailedQuestions[0].AllowNonApplicable;
                delete el.SubFailedQuestions[0].Options;
                break;
            }
          }
        }

        return el;
      })
    );
  };

  const [formValue, setFormValue] = useState({});
  useEffect(() => {
    let initialContent = {};
    try {
      const editableForm = { ...location.state.dataToEdit };
      initialContent = JSON.parse(editableForm.content);
    } catch (error) {}
    setFormValue({
      ...formikInitialValues,
      CompanyId: formikInitialValues.CompanyId
        ? companies.find(c => c.id === formikInitialValues.CompanyId)?.slug
        : currentCompany.slug,
      Associations: initialAssociations,
      Content: initialContent
    });
  }, [companies, formikInitialValues, currentCompany, initialAssociations, location.state]);

  useFormUpdatedFields(formValue, {
    eventName: MPTrackingEvents.Settings.PreTripChecklist.Update,
    eventProps: { typeOfUpdate: action },
    trackFields: MPTrackingEvents.Settings.PreTripChecklist.TrackFormFields,
    eventPropsOnSubmitFunc: MPTrackingEvents.Settings.PreTripChecklist.OnSubmitTrackEventPropsFunc
  });

  const onSubmit = (values, actions) => {
    const { setSubmitting } = actions;
    checkIfElementsAreValid();
    checkIfFailedElementsAreValid();
    if (!allElementsAreValid) {
      setSubmitting(false);
      return;
    }
    if (elements.length === 0) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t('InspectionChecklist.Notifications.OneElement')}`
        })
      );
      setSubmitting(false);
      return;
    }

    if (!values.Name.startsWith('DVIR')) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('InspectionChecklist.FormValidation.DVIRNameRequired', {
            name: values.Name
          }),
          autohide: true
        })
      );
      actions.setSubmitting(false);
      return;
    }
    const identicalNameForm = allInspections.find(form => form.name === values.Name);
    if (
      (action === 'add' && identicalNameForm) ||
      (action === 'edit' &&
        identicalNameForm &&
        identicalNameForm.name !== location.state.dataToEdit.name)
    ) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('InspectionChecklist.Notifications.IdenticalName', {
            name: identicalNameForm.name
          }),
          autohide: true
        })
      );
      actions.setSubmitting(false);
      return;
    }
    const newElements = elements.map(element => {
      const newElement = { ...element };
      delete newElement.Icon;
      delete newElement.AllowNonApplicable;
      if (newElement.Options) {
        newElement.PossibleAnswers = [...newElement.Options];
        if (newElement.Dropdown) {
          newElement.Type = 'MultiSelection';
        }
        delete newElement.Dropdown;
        delete newElement.Options;
      } else {
        newElement.PossibleAnswers = null;
      }
      if (newElement.SubFailedQuestions?.length > 0) {
        if (
          newElement.SubFailedQuestions[0].Type === 'Yes/No' ||
          newElement.SubFailedQuestions[0].Type === 'Ok/Not Ok'
        ) {
          newElement.SubFailedQuestions[0].Type = 'Selection';
        }
        if (newElement.SubFailedQuestions[0].Type === 'Numeric') {
          newElement.SubFailedQuestions[0].Type = 'Numerical';
        }
        if (newElement.SubFailedQuestions[0].Options) {
          if (newElement.SubFailedQuestions[0].Dropdown) {
            newElement.SubFailedQuestions[0].Type = 'MultiSelection';
          }
          newElement.SubFailedQuestions[0].PossibleAnswers = newElement.SubFailedQuestions[0].Options.map(
            el => ({ Value: el.Value, Pass: el.Pass || false })
          );
          delete newElement.SubFailedQuestions[0].Dropdown;
          delete newElement.SubFailedQuestions[0].Options;
        }
        delete newElement.SubFailedQuestions[0].AllowNonApplicable;
      }
      if (
        newElement.Type === 'Yes/No' ||
        newElement.Type === 'Ok/Not Ok' ||
        newElement.Type === 'Checkbox'
      ) {
        newElement.Type = 'Selection';
      }
      if (newElement.Type === 'Numeric') {
        newElement.Type = 'Numerical';
      }
      return newElement;
    });
    const newFailedElements = failedElements.map(element => {
      const newElement = { ...element };
      newElement.AllowBlank = element.AllowBlank || false;
      delete newElement.Icon;
      delete newElement.AllowNonApplicable;
      if (newElement.Options) {
        newElement.PossibleAnswers = [...newElement.Options];
        if (newElement.Dropdown) {
          newElement.Type = 'MultiSelection';
        }
        delete newElement.Dropdown;
        delete newElement.Options;
      } else {
        newElement.PossibleAnswers = null;
      }
      if (newElement.Type === 'Yes/No' || newElement.Type === 'Ok/Not Ok') {
        newElement.Type = 'Selection';
      }
      if (newElement.Type === 'Numeric') {
        newElement.Type = 'Numerical';
      }
      return newElement;
    });
    let Associations = {
      company_id: [],
      fleet_id: []
    };
    if (userChecked) {
      userChecked.forEach(fleet => {
        if (fleet.checked) {
          if (fleet.value[0] === 'c') {
            Associations.company_id.push(fleet.value.replace('c-', ''));
          } else {
            Associations.fleet_id.push(fleet.value.toString());
          }
        }
      });
    } else {
      if (location.state.dataToEdit && location.state.dataToEdit.associations) {
        Associations = JSON.parse(location.state.dataToEdit.associations);
      }
    }

    const submitValues = {
      Name: values.Name,
      Company: values.CompanyId.toString(),
      CreateMaintSchedule: values.CreateMaintenance[0] === 'on',
      IsDVIR: true,
      Content: {
        ChecklistName: values.Name,
        RequireVehicleRegistration: values.VehicleRegistration[0] === 'on',
        RequireSignature: template.RequireSignature,
        FailedQuestions: newFailedElements,
        TemplateVersion: template.TemplateVersion,
        IsDVIR: template.IsDVIR,
        States: template.States,
        RequiresDriverStatus: template.RequiresDriverStatus,
        DisplayOrder: template.DisplayOrder,
        NotifyOnState: template.NotifyOnState,
        Questions: newElements,
        ChecklistVersion: 1
      },
      Associations
    };
    let companyId = companies.find(c => c.slug === submitValues.Company)?.id;
    let url = `${API_PATH}/pretripchecklists?company_id=${companyId}`;
    let method = 'POST';
    let successMessage = i18next.t('InspectionChecklist.Notifications.AddedNotification', {
      name: values.Name
    });
    if (action === 'edit') {
      url = `${API_PATH}/pretripchecklists/${checklistId}?company_id=${companyId}`;
      method = 'PUT';
      successMessage = i18next.t('InspectionChecklist.Notifications.UpdateNotification', {
        name: values.Name
      });
    }
    setFormValue({
      ...submitValues,
      CompanyId: submitValues.Company,
      CreateMaintenance: submitValues?.CreateMaintSchedule,
      VehicleRegistration: submitValues?.Content?.RequireVehicleRegistration,
      DriverSignature: false,
      submit: true
    });
    request(method, url)
      .set('authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(submitValues)
      .then(res => {
        setPromptModalWhenLeaving(false);
        dispatch(
          openToast({
            type: ToastType.Success,
            message: successMessage
          })
        );
        dispatch(fetchInspectionChecklists());
        canHistoryGoBack(history, '/settings/inspectionChecklist');
        setSubmitting(false);
      })
      .catch(err => {
        actions.setSubmitting(false);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err.response.text
          })
        );
      });
  };

  const onCompanyChange = (companySlug, setFieldValue) => {
    setFleetsLoaded(false);
    const company = companies.find(comp => comp.slug === companySlug);

    getFleetsByCompany(company.id, userKey).then(newFleets => {
      const formCompany = companies.find(comp => comp.id === parseInt(company.id, 10));
      const formFleets = [...newFleets]
        .filter(fleet => fleet.id)
        .map(fleet => {
          const companyFleet = companies.find(company => company.id === fleet.company?.id);
          return {
            value: fleet.id,
            label: fleet?.name ? `${fleet.name} (${companyFleet?.name || ''})` : '',
            checked: false
          };
        });
      formFleets.push({
        value: -1,
        label: `${t('PreTripChecklist.Form.NoFleet')}`,
        checked: false
      });
      formFleets.unshift(
        {
          label: `${t('PreTripChecklist.Form.Company')}`
        },
        {
          value: `c-${formCompany.id}`,
          label: formCompany.name,
          checked: false
        },
        {
          label: `${t('PreTripChecklist.Form.Fleet')}`
        }
      );
      setFleets(formFleets);
      setFleetsLoaded(true);
    });

    const formCompany = companies.find(comp => comp.id === parseInt(company.id, 10));
    setFormCompany(formCompany);
    setFieldValue('CompanyId', company.slug);
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...formikInitialValues,
          CompanyId: formikInitialValues.CompanyId
            ? companies.find(c => c.id === formikInitialValues.CompanyId)?.slug
            : currentCompany.slug
        }}
        validationSchema={validationSchema()}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, dirty, setFieldValue }) => (
          <>
            <EditRouteGuard when={dirty && promptModalWhenLeaving} navigate={history.push} />
            <Form id="FormsForm">
              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%' }}>
                  <div className={styles.formContainer} id="firstFormContainer">
                    <Row>
                      <Col>
                        <Row>
                          <FormInput
                            name="Name"
                            label={t('InspectionChecklist.Form.InspectionName')}
                            placeholder={t('InspectionChecklist.Form.InspectionNamePlaceholder')}
                            isRequired
                          />
                        </Row>
                        <Row>
                          <FormSelect
                            name="CompanyId"
                            label={t('PreTripChecklist.Form.Company')}
                            placeholder=""
                            values={companies.map(company => ({
                              label: company.name,
                              value: company.slug
                            }))}
                            onChange={company => onCompanyChange(company, setFieldValue)}
                            isRequired
                          />
                        </Row>
                        <Row>
                          <FormRadio
                            type="checkbox"
                            name="VehicleRegistration"
                            values={[
                              {
                                value: 'on',
                                label: `${t('PreTripChecklist.Form.VehicleRegistration')}`
                              }
                            ]}
                          />
                          <FormRadio
                            type="checkbox"
                            name="CreateMaintenance"
                            values={[
                              {
                                value: 'on',
                                label: `${t('PreTripChecklist.Form.CreateMaintenance')}`
                              }
                            ]}
                          />
                        </Row>
                      </Col>
                      <Col>
                        <SearchableListMultiSelect
                          name="Fleets"
                          label={t('InspectionChecklist.Form.AssignInspection')}
                          placeholder={t('InspectionChecklist.Form.AssignInspectionPlaceholder')}
                          allLabel={t('PreTripChecklist.Form.AllFleets')}
                          values={fleets}
                          initialValues={initialAssociations}
                          setFieldValue={setUserChecked}
                          height={270}
                          isLoading={!fleetsLoaded}
                        />
                      </Col>
                    </Row>
                  </div>
                  <div
                    className={styles.formContainer}
                    style={{
                      maxWidth: '732px',
                      height: `${
                        activeElement
                          ? `${formPreviewContainerHeight + 200}px`
                          : `${formPreviewContainerHeight + 98}px`
                      }`,
                      minHeight: 'calc(100dvh - 376px)'
                    }}
                  >
                    <Row>
                      <Col
                        className={styles.formElements}
                        style={{
                          position: isFirstFormContainerVisible ? 'static' : 'fixed',
                          top: isFirstFormContainerVisible ? '0' : '36px'
                        }}
                      >
                        <div className={styles.formBuilderHeader}>
                          {t('InspectionChecklist.Form.InspectionQuestions')}
                        </div>
                        <FormElement
                          icon={textIcon}
                          label={t('PreTripChecklist.Form.Text')}
                          onAction={() => {
                            addElement('Text', textIcon);
                          }}
                        />
                        <FormElement
                          icon={numericIcon}
                          label={t('PreTripChecklist.Form.Numeric')}
                          onAction={() => {
                            addElement('Numeric', numericIcon);
                          }}
                        />

                        {
                          <FormElement
                            icon={checkboxIcon}
                            label={t('PreTripChecklist.Form.Checkbox')}
                            onAction={() => {
                              addElement('Checkbox', checkboxIcon);
                            }}
                          />
                        }
                      </Col>
                      <Col
                        className={styles.formPreview}
                        style={{ paddingRight: '16px', maxWidth: '433px' }}
                      >
                        <div className={styles.formBuilderHeader}>
                          {t('PreTripChecklist.Form.QuestionsPreview')}
                        </div>
                        <SortableWrapper
                          updateBeforeSortStart={() => setisDraggable(true)}
                          onSortEnd={({ oldIndex, newIndex }) => {
                            setElements(arrayMove(elements, oldIndex, newIndex));
                            setisDraggable(false);
                          }}
                          useDragHandle
                        >
                          {elements.map((element, index) => (
                            <SortableItem
                              key={`${element.type}-${index}`}
                              index={index}
                              element={element}
                              activeElement={activeElement}
                              isDraggable={isDraggable}
                              removeElement={removeElement}
                              setActiveElement={setActiveElement}
                              setProperty={setProperty}
                              setFailedQuestionProperty={setFailedQuestionProperty}
                              isPretripChecklist
                              ElementDynamicFields={({ element }) => (
                                <QuestionExtraFields
                                  inspectionChecklistId={checklistId}
                                  question={element}
                                  setQuestionOption={(property, value) => {
                                    setProperty(element, property, value);
                                  }}
                                />
                              )}
                            />
                          ))}
                        </SortableWrapper>
                        {elements.length === 0 && (
                          <div className="emptyPlaceholder" style={{ display: 'flex' }}>
                            {t('PreTripChecklist.Form.AddElementHere')}
                          </div>
                        )}
                        {failedElements.length > 0 && (
                          <SortableFailedWrapper
                            updateBeforeSortStart={() => setisDraggable(true)}
                            onSortEnd={({ oldIndex, newIndex }) => {
                              setFailedElements(arrayMove(failedElements, oldIndex, newIndex));
                              setisDraggable(false);
                            }}
                            useDragHandle
                          >
                            {failedElements.map((element, index) => (
                              <SortableFailedItem
                                key={`${element.type}-${index}`}
                                index={index}
                                element={element}
                                activeElement={activeElement}
                                isDraggable={isDraggable}
                                removeElement={removeFailedElement}
                                setActiveElement={setActiveElement}
                                setProperty={setProperty}
                                setFailedQuestionProperty={setFailedQuestionProperty}
                                isPretripChecklist
                              />
                            ))}
                          </SortableFailedWrapper>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              <div className={styles.formFooter}>
                <Button
                  type="primary"
                  htmlType="submit"
                  id={BUTTON_IDS.dvirFormSave}
                  disabled={!isValid || isSubmitting}
                >
                  {t('Common.SaveButton')}
                </Button>
                <Button onClick={history.goBack} id={BUTTON_IDS.dvirFormCancel}>
                  {t('Common.CancelButton')}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default DvirInspectionForm;
