import React from 'react';
import { isArray } from 'lodash';
import { EllipsisContent } from './EllipsisContent';

import styles from '../../BulkEditAuditsTable.module.scss';

export const ChangeCell = ({ change, changeKey, isFrom, isPairs }) =>
  isPairs ? (
    (change?.pairs || []).map((pair, pairIndex, pairs) => (
      <ChangeValue
        cellStyleProps={{
          className:
            pairIndex !== pairs.length - 1
              ? `${styles.borderBottom} ${styles.scrollableCell}`
              : styles.scrollableCel,
          style: {
            height: `${(1 / pairs.length) * 100}%`
          }
        }}
        key={`${changeKey}-${pairIndex}`}
        change={isFrom ? pair?.from : pair?.to}
        changeKey={`${changeKey}-${pairIndex}`}
      />
    ))
  ) : (
    <ChangeValue change={isFrom ? change?.from : change?.to} changeKey={changeKey} />
  );

const ChangeValue = ({ change, changeKey, cellStyleProps }) =>
  isArray(change) ? (
    <EllipsisContent {...cellStyleProps}>
      {change.map((changeValue, index) => (
        <div key={`${changeKey}-${index}`}>{changeValue}</div>
      ))}
    </EllipsisContent>
  ) : (
    <EllipsisContent {...cellStyleProps}>{change}</EllipsisContent>
  );
