import React, { useCallback, useState } from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare } from '@fortawesome/fontawesome-free-regular';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { ViewMode } from '../constant';
import styles from './ViewTypeSwitch.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export function ViewTypeSwitch({ onGridViewClicked, onListViewClicked, defaultValue, ...props }) {
  const [selectedType, setSelectedType] = useState(
    defaultValue || defaultValue === undefined ? ViewMode.GridView : ViewMode.ListView
  );
  const handleGridClick = useCallback(() => {
    setSelectedType(p => {
      if (p !== ViewMode.GridView && onGridViewClicked) {
        onGridViewClicked();
      }
      return ViewMode.GridView;
    });
  }, [onGridViewClicked]);

  const handleListClick = useCallback(() => {
    setSelectedType(p => {
      if (p !== ViewMode.ListView && onListViewClicked) {
        onListViewClicked();
      }
      return ViewMode.ListView;
    });
  }, [onListViewClicked]);

  return (
    <div className={styles.switch}>
      <Button
        className={
          styles.gridButton + ' ' + (selectedType === ViewMode.GridView ? styles.selected : '')
        }
        onClick={handleGridClick}
        type="text"
        id={BUTTON_IDS.viewTypeSwitchGrid}
      >
        <FontAwesomeIcon fontWeight="bold" size="xs" icon={faSquare} />
        <FontAwesomeIcon size="xs" icon={faSquare} />
        <FontAwesomeIcon size="xs" icon={faSquare} />
        <FontAwesomeIcon size="xs" icon={faSquare} />
      </Button>
      <Button
        className={
          styles.iconButton + ' ' + (selectedType === ViewMode.ListView ? styles.selected : '')
        }
        onClick={handleListClick}
        type="text"
        icon={<FontAwesomeIcon icon={faBars} />}
        id={BUTTON_IDS.viewTypeSwitchList}
      ></Button>
    </div>
  );
}
