import React, { useEffect, useState, createRef } from 'react';
import T from 'prop-types';

import { Upload, message } from 'antd';
import { LoadingOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import style from './ImageUpload.module.scss';
import cn from 'classnames';
import ImgCrop from 'antd-img-crop';
import i18n from 'i18next';

const Status = {
  UPLOADING: 'uploading',
  DONE: 'done'
};

const Types = {
  JPEG: 'image/jpeg',
  PNG: 'image/png'
};

const beforeUpload = file => {
  const isJpgOrPng = [Types.JPEG, Types.PNG].includes(file.type);

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  return isJpgOrPng;
};

function getBase64(img, callback) {
  if (img instanceof File || img instanceof Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
}

export const ImageUpload = props => {
  const [loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState(props.imageUrl);
  const [hovered, setHovered] = useState(false);
  const { onChange, round } = props;
  const aspectRatio = round ? 1 : 2.6;
  const shape = round ? 'round' : 'rect';
  const listType = round ? 'picture-circle' : 'picture-card';

  const uploadRef = createRef();

  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (props.imageUrl) {
      setImageURL(props.imageUrl);
      setFileList([
        {
          url: props.imageUrl
        }
      ]);
    } else {
      setFileList([]);
    }
  }, [props.imageUrl]);

  const uploaderClass = cn('ant-upload-text', round ? style.uploader : style.uploaderRect, {
    disabled: props.disabled
  });

  const uploaderClassDisabled = cn(
    'ant-upload-text',
    round ? style.uploader : style.uploaderRect,
    style.disabled
  );

  const uploadButton = (
    <div ref={uploadRef}>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div className={uploaderClass}>{i18n.t('Vehicles.Form.ImagePlaceholder')}</div>
      )}
    </div>
  );

  const containerClasses = cn(style.uploadContainer, {
    [style.round]: !!round
  });

  const handleChange = info => {
    if (info.file.status === Status.UPLOADING) {
      setLoading(true);
    }
  };

  const handleModalOk = file => {
    // Set the cropped image when the user clicks "OK" in the modal
    if (file.status === Status.UPLOADING) {
      setLoading(true);
    }

    if (file) {
      getBase64(file, imageUrl => {
        setImageURL(imageUrl);
        setLoading(false);
        setHovered(false);
        onChange(file);
      });
    }
  };

  const handleRemove = () => {
    setFileList([]);
    setImageURL(null);
    setHovered(false);
    onChange(null);
    props.onRemoveImage();
    return true;
  };

  const handleUpload = () => {
    if (!uploadRef.current) return;

    uploadRef.current.click();
  };

  return (
    <div className={containerClasses}>
      <ImgCrop
        cropShape={shape}
        aspect={aspectRatio}
        modalTitle={i18n.t('Vehicles.View.EditImage')}
        modalOk={i18n.t('Common.Modal.OK')}
        modalCancel={i18n.t('Common.CancelButton')}
        onModalOk={handleModalOk}
      >
        <Upload
          accept="image/*"
          name="avatar"
          listType={listType}
          className={containerClasses}
          showUploadList={{ showPreviewIcon: false }}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          disabled={!!props.disabled}
          onRemove={handleRemove}
          fileList={fileList}
        >
          {!props.disabled && uploadButton}
          {props.disabled && !imageURL && (
            <div className={uploaderClassDisabled}>{i18n.t('Vehicles.View.NoPhoto')}</div>
          )}
        </Upload>
      </ImgCrop>

      {imageURL && (
        <div
          className={round ? style.croppedImageContainer : style.croppedImageRectContainer}
          onMouseEnter={() => !props.disabled && setHovered(true)}
          onMouseLeave={() => !props.disabled && setHovered(false)}
        >
          <img src={imageURL} alt="" />

          {loading && (
            <div className={round ? style.loading : style.loadingRect}>
              <LoadingOutlined />
            </div>
          )}

          {hovered && (
            <div className={round ? style.preview : style.previewRect}>
              <div>
                <UploadOutlined className={style.uploadIcon} onClick={handleUpload} />
                <DeleteOutlined className={style.deleteIcon} onClick={handleRemove} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ImageUpload.propTypes = {
  imageUrl: T.string,
  round: T.bool,
  onChange: T.func,
  onRemoveImage: T.func
};
