import { useEffect, useState } from 'react';
import usePrevious from 'features/common/hooks/previous';
import { Mixpanel, MPTrackingFunctions } from 'features/mixpanel';
import { toTitleCase } from 'utils/strings';

const getMixpanelPVEvtName = (path, started) => {
  return `${path
    .split('/')
    .filter(seg => /[a-zA-Z]+/.test(seg))
    .map(toTitleCase)
    .join(' ') || 'Home'} Page View ${started ? 'Start' : 'End'}`;
};
export function useMixpanelPVEventTrack(locationPathname) {
  const [currentLocation, setCurrentLocation] = useState({
    pathname: locationPathname,
    previousPath: locationPathname
  });
  const previousPathObj = usePrevious(currentLocation);
  const previousPath = usePrevious(locationPathname);

  useEffect(() => {
    const currentPath = locationPathname;
    if (previousPathObj) {
      const prevPVEventName = getMixpanelPVEvtName(previousPathObj.pathname);
      Mixpanel.sendTrackEvent(prevPVEventName, {
        previousPath: previousPathObj.previousPath || currentPath
      }); //track end-evt
    }
    setCurrentLocation({ pathname: locationPathname, previousPath });
    Mixpanel.sendToMixpanel(MPTrackingFunctions.time_event, getMixpanelPVEvtName(currentPath));
    Mixpanel.sendTrackEvent(getMixpanelPVEvtName(currentPath, true), {
      previousPath: previousPath || currentPath
    }); //track start-evt
  }, [locationPathname]);
}

export function useDurationEventTrack(eventName, indicator) {
  useEffect(() => {
    if (indicator) {
      Mixpanel.sendToMixpanel(MPTrackingFunctions.time_event, eventName);
    } else {
      Mixpanel.sendToMixpanel(MPTrackingFunctions.track, eventName);
    }
  }, [indicator]);
}

export function useFormUpdatedFields(formValue, formTrack, eventPropsFuncOnSubmit) {
  const [initialValue, setInitialValue] = useState(formValue || {});
  const defaultFieldEqualFunc = (initialFieldValue, currentFieldValue) => {
    try {
      return JSON.stringify(initialFieldValue) === JSON.stringify(currentFieldValue);
    } catch (error) {}
  };
  useEffect(() => {
    const formSubmit = formValue.submit;
    if (formSubmit) {
      const formTrackEventName = formTrack.eventName;
      const trackFields = formTrack.trackFields || {};
      const fields = Object.keys(trackFields);
      const extraEventProps = formTrack.eventProps || {};
      const eventPropsOnSubmitFunc = formTrack.eventPropsOnSubmitFunc;
      const updatedFields = [];
      (fields || []).forEach(fieldName => {
        const initialFieldValue = initialValue[fieldName];
        const currentFieldValue = formValue[fieldName];
        const fieldEqualFun = trackFields[fieldName]?.FieldEqualFun || defaultFieldEqualFunc;
        const isEqual = fieldEqualFun(initialFieldValue, currentFieldValue);
        if (!isEqual) {
          if (trackFields[fieldName]?.getUpdatedSubFields) {
            const flattenUpdatedFields =
              trackFields[fieldName]?.getUpdatedSubFields(initialFieldValue, currentFieldValue) ||
              [];
            updatedFields.push(...flattenUpdatedFields);
          } else {
            updatedFields.push(trackFields[fieldName]?.NameMapping || fieldName);
          }
        }
      });
      const extraEventPropsFromSubmit = eventPropsOnSubmitFunc
        ? eventPropsOnSubmitFunc(formValue)
        : {};
      return Mixpanel.sendTrackEvent(formTrackEventName, {
        ...extraEventProps,
        updatedFields,
        ...extraEventPropsFromSubmit
      });
    } else {
      setInitialValue(formValue || {});
    }
  }, [formValue]);
}

export function useMixpanelProfiles(user, company, companies, currentAdminLoggedIn) {
  useEffect(() => {
    if (user?.id) {
      const isLoginAsAnotherUser =
        user && currentAdminLoggedIn && user.id !== currentAdminLoggedIn.id;
      const userCompany = companies?.find(company => company?.id === user?.companyId);
      if (!isLoginAsAnotherUser) {
        //update user profile
        Mixpanel.sendToMixpanel(MPTrackingFunctions.updateUserProfile, user.id, {
          $first_name: user?.firstName,
          $last_name: user?.lastName,
          username: user?.username,
          $email: user?.email,
          user_type: user?.keys?.[0]?.type,
          id: user?.id,
          user_roles: user?.roles?.map(role => role?.name),
          siteAdmin: !!user?.siteAdmin
        });

        if (userCompany && userCompany.name) {
          //update user prpfile
          Mixpanel.sendToMixpanel(MPTrackingFunctions.updateUserProfile, user.id, {
            company: userCompany.name
          });

          //update group profile
          Mixpanel.sendToMixpanel(MPTrackingFunctions.updateCompanyProfile, userCompany.id, {
            $name: userCompany.name,
            $country_code: userCompany.country,
            slug: userCompany.slug
          });

          Mixpanel.registerCompanyName(userCompany.name);
        }
        if (company && company.id === user?.companyId) {
          Mixpanel.sendToMixpanel(MPTrackingFunctions.updateCompanyProfile, company.id, {
            features: company?.features?.map(feature => feature?.name)
          });
          Mixpanel.registerCompanyName(company.name);
        }
      }
    }
  }, [user, company, companies, currentAdminLoggedIn]);
}
