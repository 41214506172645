import React, { useCallback, useMemo, useState } from 'react';
import { Menu, Button, Popover } from 'antd';
import { Link } from 'react-router-dom';
import styles from './PopUpMenu.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const menu = ({ menuItems, onVisibleChange }) => {
  const renderedMenuItems = menuItems.reduce((accItems, currentMenuItem, currentIndex) => {
    const { id, label, link, onClick, renderProp, disabled, danger } = currentMenuItem;

    if (renderProp) {
      accItems.push({
        key: currentIndex,
        disabled: disabled || false,
        onClick: () => onVisibleChange(false),
        label: renderProp(),
        id
      });
    }

    if (!renderProp && onClick) {
      accItems.push({
        key: currentIndex,
        disabled: disabled || false,
        onClick: e => {
          e.domEvent.stopPropagation();
          onVisibleChange(false);
          onClick(e.domEvent);
        },
        label: (
          <span id={id} className={danger ? styles.dangerLabel : ''}>
            {label}
          </span>
        ),
        id
      });
    }

    if (!renderProp && !onClick && link) {
      accItems.push({
        key: currentIndex,
        id,
        disabled: disabled || false,
        label: (
          <Link id={id} onClick={() => onVisibleChange(false)} to={link}>
            <span className={danger ? styles.dangerLabel : ''}>{label}</span>
          </Link>
        )
      });
    }

    return accItems;
  }, []);

  return <Menu selectable={false} items={renderedMenuItems} />;
};

export const PopUpMenu = ({ icon, menuItems, placement, size, ...props }) => {
  const [visible, onVisibleChange] = useState(false);
  const _placement = placement ? placement : 'left';
  const _size = size ? size : 'middle';
  const overlayStyle = { padding: 0 };

  const handleVisibleChange = useCallback(v => {
    onVisibleChange(v);
  }, []);

  const handleClick = useCallback(e => {
    e.stopPropagation();
    onVisibleChange(prev => !prev);
  }, []);

  const btnIcon = useMemo(() => {
    const newIcon = icon ? icon : <i style={{ fontSize: '26px' }} className={'tn-i-elipsis'} />;
    return newIcon;
  }, [icon]);

  return (
    <Popover
      placement={_placement}
      content={
        visible
          ? menu({
              menuItems: typeof menuItems === 'function' ? menuItems() : menuItems,
              onVisibleChange
            })
          : null
      }
      trigger={'click'}
      overlayStyle={overlayStyle}
      overlayClassName={styles.PopUpMenu}
      open={visible}
      onOpenChange={handleVisibleChange}
      {...props}
    >
      <Button
        type="text"
        size={_size}
        icon={btnIcon}
        id={BUTTON_IDS.popupMenu}
        onClick={handleClick}
      />
    </Popover>
  );
};
