import React, { useState, useEffect, useMemo } from 'react';
//hooks
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import { useItemTypes } from 'features/smartJobs';
//components
import { Table, Modal } from 'antd';
import InfoRow from 'components/form/info-row/InfoRow';
import logoTNLg from 'components/tn/assets/images/layout/nav/tn-logo-green.svg';
import Map, { MapMode } from 'components/map/Map';
import NotAvailableLink from './NotAvailableLink';
import { DownloadPdf } from './DownloadPdf/DownloadPdf';
//helpers
import { getInfoRowValue, getJobAttachments } from './utils/helpers';
import { prepareItemsForTable } from '../utils/helpers';
//constants
import {
  PublicViewJobLabels,
  JobDetailsLabels,
  TABLE_JV_JOBS_ITEMS_COLUMNS,
  PublicJobPageStatuses,
  JOB_PNG_ATTACHMENT_PREFIX
} from './utils/constans';
import { API_PATH } from 'config';
import { ColumnKeys } from '../utils/constants';
//styles
import styles from './PublicJobView.module.scss';

const PublicJobView = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const itemTypes = useItemTypes();
  const url = window.location.search
    .replace(/^\?/, '')
    .split('&')
    .map(str => str.split('='));
  const hParam = url[1][1];
  const pParam = url[0][1];
  const [job, setJob] = useState({});
  const [jobAttach, setJobAttach] = useState({});
  const [jobSignature, setJobSignature] = useState(null);
  const [jobDestinationCoordinates, setJobDestinationCoordinates] = useState(null);
  const [isLinkAvailable, setIsLinkAvailable] = useState(PublicJobPageStatuses.NOT_VERIFIED);
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(null);
  const [itemsTableHeaderHeight, setItemsTableHeaderHeight] = useState(0);
  const [itemsTableBodyHeight, setItemsTableBodyHeight] = useState(0);

  useEffect(() => {
    const tableHeaderHeight = document.getElementsByClassName('ant-table-thead')[0]?.offsetHeight;
    const tableBodyHeight = document.getElementsByClassName('ant-table-tbody')[0]?.offsetHeight;
    const tableHeaderHeightIsDifferent = tableHeaderHeight !== itemsTableHeaderHeight;
    const tableBodyHeightIsDifferent = tableBodyHeight !== itemsTableBodyHeight;

    if (
      tableHeaderHeight &&
      tableBodyHeight &&
      (tableHeaderHeightIsDifferent || tableBodyHeightIsDifferent)
    ) {
      tableHeaderHeightIsDifferent && setItemsTableHeaderHeight(tableHeaderHeight);
      tableBodyHeightIsDifferent && setItemsTableBodyHeight(tableBodyHeight);
    }
  });

  const showModal = id => {
    setIsAttachmentModalOpen(id);
  };

  const handleCancel = () => {
    setIsAttachmentModalOpen(null);
  };

  useEffect(async () => {
    const veryfiLinkResponse = await fetch(`${API_PATH}/links/verify?p=${pParam}&h=${hParam}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const veryfiLinkData = await veryfiLinkResponse.json();
    if (!veryfiLinkResponse.ok && veryfiLinkData.error) {
      setIsLinkAvailable(PublicJobPageStatuses.NOT_AVAILABLE);
      return;
    }

    try {
      const jobResponse = await fetch(
        `${API_PATH}/runsheets/${veryfiLinkData?.runsheet?.id}/jobs/${veryfiLinkData?.job?.id}?embed=**&pruning=ALL`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token token="${veryfiLinkData?.apiKey?.accessToken}"`
          }
        }
      );
      const jobData = await jobResponse.json();
      if (!jobData.ok && jobData.error) {
        setIsLinkAvailable(PublicJobPageStatuses.FAILED_DETAILS_CALL);
        return;
      }
      setJob(jobData);
      if (jobData?.stop?.location?.GPS) {
        setJobDestinationCoordinates({
          lat: jobData?.stop?.location?.GPS.Lat,
          lng: jobData?.stop?.location?.GPS.Lng
        });
      }
    } catch (err) {
      console.log(err);
      setIsLinkAvailable(PublicJobPageStatuses.FAILED_DETAILS_CALL);
      return;
    }
    try {
      const jobResponse = await fetch(
        `${API_PATH}/runsheets/${veryfiLinkData?.runsheet?.id}/jobs/${veryfiLinkData?.job?.id}/attach?embed=jobItems.item,jobAttachments.attachment.content,attributes,runsheet&pruning=ALL`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token token="${veryfiLinkData?.apiKey?.accessToken}"`
          }
        }
      );
      const jobAttachData = await jobResponse.json();

      if (!jobAttachData.ok && jobAttachData.error) {
        setIsLinkAvailable(PublicJobPageStatuses.FAILED_DETAILS_CALL);
        return;
      }

      const signatureAttachment = (jobAttachData?.jobAttachments || []).find(
        attachment => attachment.type === 'POD'
      );

      if (signatureAttachment) {
        setJobSignature(signatureAttachment);
      }

      setJobAttach(jobAttachData);
    } catch (err) {
      console.log(err);
      setIsLinkAvailable(PublicJobPageStatuses.FAILED_DETAILS_CALL);
      return;
    }

    ![PublicJobPageStatuses.NOT_AVAILABLE, PublicJobPageStatuses.FAILED_DETAILS_CALL].includes(
      isLinkAvailable
    ) && setIsLinkAvailable(PublicJobPageStatuses.AVAILABLE);
  }, []);

  // We don't want to show the acctions column in this public job page
  const ItemsTableColumns = useMemo(() => {
    return TABLE_JV_JOBS_ITEMS_COLUMNS.filter(column => column.key !== ColumnKeys.ACTIONS).map(
      column => ({
        ...column,
        title: t(`SmartJobs.ColumnList.Columns.${column.key}`)
      })
    );
  }, [t, job]);

  const isAvailableLinkContent = () => {
    switch (isLinkAvailable) {
      case PublicJobPageStatuses.NOT_AVAILABLE:
        return <NotAvailableLink failedDetailsCall={false} styles={styles} />;
      case PublicJobPageStatuses.FAILED_JOB_CALLS:
        return <NotAvailableLink failedDetailsCall={true} styles={styles} />;
      case PublicJobPageStatuses.AVAILABLE:
        return (
          <div className={styles.publicJobViewContent}>
            <div className={styles.publicJobViewDetailsMap}>
              <div
                className={styles.publicJobViewDetails}
                style={{ width: jobDestinationCoordinates ? '50%' : '100%' }}
              >
                {PublicViewJobLabels.map(label => {
                  let value = getInfoRowValue(label, job, localization);
                  if (label === JobDetailsLabels.TYPE) {
                    value = t(`SmartJobs.Input.JobType.${value}`, '');
                  }
                  if (label === JobDetailsLabels.STATUS) {
                    value = t(`SmartJobs.Status.${(value || '').toLowerCase()}`, '');
                  }

                  return (
                    <InfoRow
                      key={label}
                      label={t(`SmartJobs.PDF.${label}`)}
                      value={value}
                      styles={styles}
                    />
                  );
                })}
              </div>
              {jobDestinationCoordinates && (
                <div className={styles.publicJobViewMap}>
                  <Map
                    mode={MapMode.Location}
                    location={jobDestinationCoordinates}
                    containerElement={<div style={{ height: `100%`, width: `100%` }} />}
                    mapElement={<div style={{ height: `100%`, width: `100%` }} />}
                    mapOptions={{
                      mapTypeControl: false,
                      zoomControl: false,
                      streetViewControl: false,
                      fullscreenControl: false,
                      scaleControl: false,
                      zoom: 15
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className={styles.publicJobViewTable}
              //We need this container to have absolut positioning for the responsiveness,
              //therefor we calculate the height based on the number of items
              style={{
                height: job?.jobItems?.length
                  ? `${itemsTableHeaderHeight + itemsTableBodyHeight + 49}px`
                  : '290px'
              }}
            >
              <h5 className={styles.sectionTitle}>{t('SmartJobs.Items')}</h5>
              <Table
                columns={ItemsTableColumns}
                dataSource={prepareItemsForTable(
                  job?.jobItems || [],
                  job,
                  t,
                  localization,
                  null,
                  itemTypes
                )}
                pagination={false}
              />
            </div>
            <div className={styles.publicJobViewAttachments}>
              <h5 className={styles.sectionTitle}>{t('SmartJobs.Attachments')}</h5>
              <div className={styles.imagesWrapper}>
                {jobAttach?.jobAttachments?.length ? (
                  getJobAttachments({
                    jobAttach: jobAttach,
                    styles: styles,
                    showModal: showModal,
                    isSignatureFiltered: true
                  })
                ) : (
                  <span className={styles.noImagesText}>{t('SmartJobs.NoImagesAttached')}</span>
                )}
              </div>
            </div>
            <div className={styles.publicJobViewSignature}>
              <h5 className={styles.sectionTitle}>{t('SmartJobs.Signature')}</h5>
              <InfoRow
                key="signatureName"
                label={t('SmartJobs.Name')}
                value={jobSignature?.notes || '-'}
                styles={styles}
              />
              <div className={styles.signatureImageContainer}>
                <div className={styles.formLabel}>{t('SmartJobs.Signature')}</div>
                {jobSignature?.attachment?.content ? (
                  <div
                    key={jobSignature?.id || 'jobSignature'}
                    className={styles.imageContainer}
                    onClick={() => showModal(jobSignature.id)}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${JOB_PNG_ATTACHMENT_PREFIX}${jobSignature?.attachment?.content})`
                      }}
                      className={styles.imageAttachment}
                    />
                  </div>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </div>
        );
      default:
        return <div className={styles.notVerifiedContent}></div>;
    }
  };

  return (
    <div className={styles.publicJobViewContainer}>
      <Modal
        className={styles.modal}
        title={
          (jobAttach?.jobAttachments || []).find(
            attachment => attachment.id === isAttachmentModalOpen
          )?.attachment?.name
        }
        open={isAttachmentModalOpen}
        onCancel={handleCancel}
        footer={null}
        maskClosable={true}
      >
        <img
          alt={t('Inspections.View.MissingImage')}
          loading="lazy"
          src={`${JOB_PNG_ATTACHMENT_PREFIX}${
            (jobAttach?.jobAttachments || []).find(
              attachment => attachment.id === isAttachmentModalOpen
            )?.attachment?.content
          }`}
        />
      </Modal>
      <header className={styles.publicJobViewHeader}>
        <div className={styles.logoWrapper}>
          {' '}
          <img src={logoTNLg} alt="TN360" className={styles.publicJobViewLogo} />
        </div>

        <DownloadPdf
          isLinkAvailable={isLinkAvailable}
          job={job}
          jobAttach={jobAttach}
          jobSignature={jobSignature}
          jobDestinationCoordinates={jobDestinationCoordinates}
        />
      </header>
      {isAvailableLinkContent()}
      <footer className={styles.publicJobViewFooter}>
        <span className={styles.publicJobViewFooterText}>
          {t('SmartJobs.PublicPageFooterText')}
        </span>
      </footer>
    </div>
  );
};

export default PublicJobView;
