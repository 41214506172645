import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { cache, fleetNameCellRenderer } from '../Fleets/CellRenderers';
import { useTranslation } from 'react-i18next';

export const FleetsTable = ({ fleets }) => {
  const { t } = useTranslation();

  // Can't figure out why this control is not properly resizing to the content depending on
  // the number of rows so resorting to manually calculating the size as a work around
  const headerHeight = 40;
  const rowHeight = 42;
  const height = (fleets?.length || 0) * rowHeight + headerHeight;

  return (
    <div style={{ height: `${height}px`, marginBottom: '16px' }}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <Table
              deferredMeasurementCache={cache}
              width={width}
              height={height}
              headerHeight={headerHeight}
              rowHeight={rowHeight}
              rowCount={fleets.length}
              rowGetter={({ index }) => fleets[index]}
            >
              <Column
                label={t('Common.Name')}
                dataKey="name"
                width={width / 2}
                cellRenderer={fleetNameCellRenderer}
              />
              <Column label={t('Common.Description')} dataKey="description" width={width / 2} />
            </Table>
          );
        }}
      </AutoSizer>
    </div>
  );
};
