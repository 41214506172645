/* global google */
import React from 'react';
import moment from 'moment';
import { Marker } from 'react-google-maps';
import colors from 'static/scss/_colors.scss';

export const TripInfoMarker = ({ position, isSelected = false }) => {
  if (!(position && position.lat && position.lng)) return null;

  return (
    <Marker
      position={position}
      icon={{
        path: google.maps.SymbolPath.CIRCLE,
        scale: 7,
        strokeWeight: 3,
        strokeColor: isSelected ? colors.primaryLamp9 : colors.red6,
        fillColor: isSelected ? colors.primaryLamp6 : 'white',
        fillOpacity: 1
      }}
      zIndex={moment().valueOf()}
    />
  );
};
