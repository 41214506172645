import React, { useRef } from 'react';
import { FloatButton } from 'components/buttons/FloatButton';
import { useSaleforce } from 'features/saleforce/saleforceHook';
import { SalesforceBar } from './salesforceBar';

export const SalesForceButton = () => {
  const saleforceBtnRef = useRef(null);
  useSaleforce(saleforceBtnRef);

  return (
    <>
      <FloatButton iconClass="tn-i-chat" ref={saleforceBtnRef} />
      <SalesforceBar />
    </>
  );
};
