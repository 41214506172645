import React from 'react';
import ReactExport from 'react-data-export';
import P from 'prop-types';
import { formatBytes } from 'utils/methods';
import { eventCodes, uploadEventCode } from '../constants';
import moment from 'moment';
import { format } from 'utils/dates';
import { Button } from 'antd';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useTranslation } from 'react-i18next';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const formatAuditTableForExcelExport = (audits, document, localization, t) => {
  const uploadEventWithUser = audits.find(audit => audit.type === uploadEventCode && audit.user);
  const TITLE_STYLE = {
    font: {
      bold: true,
      sz: '15'
    }
  };
  const SUBTITLE_STYLE = {
    font: {
      bold: true,
      sz: 13
    },
    border: {
      right: { style: 'thin', color: '#000' },
      bottom: { style: 'thin', color: '#000' },
      left: { style: 'thin', color: '#000' }
    }
  };
  const EVENTS_DETAIS_STYLE = {
    border: {
      top: { style: 'thin', color: '#000' },
      right: { style: 'thin', color: '#000' },
      bottom: { style: 'thin', color: '#000' },
      left: { style: 'thin', color: '#000' }
    }
  };
  const CELL_STYLE = {
    font: {
      bold: true
    },
    border: {
      top: { style: 'thin', color: '#000' },
      right: { style: 'thin', color: '#000' },
      bottom: { style: 'thin', color: '#000' },
      left: { style: 'thin', color: '#000' }
    }
  };
  const TITLE_SHEET = t('Easydocs.AuditTrailReport');
  const SUBTITLE_SHEET = t('Easydocs.Events') + ' - ' + `${document && document.name}`;
  const FILE_DETAILS_TITLE = t('Easydocs.FileDetails');
  let TITLE_DETAILS = [
    t('Easydocs.FileSize'),
    t('Easydocs.FileType'),
    t('Easydocs.Overwritten'),
    t('Easydocs.Uploaded'),
    t('Easydocs.UploadedBy')
  ];
  let TITLE_DATA_COLUMNS = [
    t('Easydocs.Time'),
    t('Easydocs.Event'),
    t('Common.Vehicle'),
    t('Easydocs.Device'),
    t('Easydocs.User')
  ];

  const multiDataSet = [
    {
      columns: [
        {
          title: TITLE_SHEET,
          width: { wch: 25 },
          style: { font: TITLE_STYLE.font }
        }
      ],
      data: []
    },
    {
      columns: [],
      data: []
    },
    {
      columns: [
        {
          title: SUBTITLE_SHEET,
          width: { wch: 25 },
          style: { font: SUBTITLE_STYLE.font, border: SUBTITLE_STYLE.border }
        }
      ],
      data: []
    },
    {
      columns: [],
      data: []
    },
    {
      columns: [
        {
          title: FILE_DETAILS_TITLE,
          width: { wch: 25 },
          style: { font: SUBTITLE_STYLE.font, border: SUBTITLE_STYLE.border }
        }
      ],
      data: []
    },
    {
      columns: [
        {
          title: TITLE_DETAILS[0],
          width: { wch: 25 },
          style: { border: EVENTS_DETAIS_STYLE.border }
        },
        {
          title: document.filesize ? formatBytes(document.filesize) : '',
          width: { wch: 25 },
          style: { border: EVENTS_DETAIS_STYLE.border }
        }
      ],
      data: []
    },
    {
      columns: [
        {
          title: TITLE_DETAILS[1],
          width: { wch: 25 },
          style: { border: EVENTS_DETAIS_STYLE.border }
        },
        {
          title: document.documentTypeId === 1 ? 'PDF' : '',
          width: { wch: 25 },
          style: { border: EVENTS_DETAIS_STYLE.border }
        }
      ],
      data: []
    },
    {
      columns: [
        {
          title: TITLE_DETAILS[2],
          width: { wch: 25 },
          style: { border: EVENTS_DETAIS_STYLE.border }
        },
        {
          title: document.overwritten ? t('Common.Yes') : t('Common.No'),
          width: { wch: 25 },
          style: { border: EVENTS_DETAIS_STYLE.border }
        }
      ],
      data: []
    },
    {
      columns: [
        {
          title: TITLE_DETAILS[3],
          width: { wch: 25 },
          style: { border: EVENTS_DETAIS_STYLE.border }
        },
        {
          title: document.uploadedAt
            ? format(new Date(document.uploadedAt), localization.formats.time.formats.dby_imsp)
            : '',
          width: { wch: 25 },
          style: { border: EVENTS_DETAIS_STYLE.border }
        }
      ],
      data: []
    },
    {
      columns: [
        {
          title: TITLE_DETAILS[4],
          width: { wch: 25 },
          style: { border: EVENTS_DETAIS_STYLE.border }
        },
        {
          title:
            uploadEventWithUser?.user?.firstName && uploadEventWithUser?.user?.lastName
              ? `${uploadEventWithUser.user.firstName} ${uploadEventWithUser.user.lastName}`
              : '',
          width: { wch: 25 },
          style: { border: EVENTS_DETAIS_STYLE.border }
        }
      ],
      data: []
    },
    {
      columns: [],
      data: []
    },
    {
      columns: [
        {
          title: TITLE_DATA_COLUMNS[0],
          width: { wch: 25 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_DATA_COLUMNS[1],
          width: { wch: 25 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_DATA_COLUMNS[2],
          width: { wch: 25 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_DATA_COLUMNS[3],
          width: { wch: 25 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        },
        {
          title: TITLE_DATA_COLUMNS[4],
          width: { wch: 25 },
          style: { font: CELL_STYLE.font, border: CELL_STYLE.border }
        }
      ],
      data: []
    }
  ];

  const rows = audits.map(audit => {
    return [
      {
        value: `${
          audit.timeAt
            ? format(
                new Date(moment(new Date(audit.timeAt))),
                localization.formats.time.formats.dby_imsp
              )
            : ''
        }`,
        style: { border: CELL_STYLE.border }
      },
      {
        value: audit.type && eventCodes[audit.type],
        style: { border: CELL_STYLE.border }
      },
      {
        value: audit.vehicle?.name ? audit.vehicle.name : '',
        style: { border: CELL_STYLE.border }
      },
      {
        value: audit.device?.name ? audit.device.name : '',
        style: { border: CELL_STYLE.border }
      },
      {
        value:
          audit.user?.firstName && audit.user?.lastName
            ? `${audit.user.firstName} ${audit.user.lastName}`
            : '',
        style: { border: CELL_STYLE.border }
      }
    ];
  });
  multiDataSet[multiDataSet.length - 1].data = rows;
  return multiDataSet;
};

const _ExportToExcelAudit = ({ data, document, localization, size }) => {
  const { t } = useTranslation();
  return (
    <ExcelFile
      element={
        <Button onClick={() => {}} size={size} id={BUTTON_IDS.documentViewExportExcel}>
          {t('Easydocs.ExportToExcel')}
        </Button>
      }
      filename={t('Easydocs.AuditTrailReport') + ' - ' + `${document && document.name}`}
    >
      <ExcelSheet
        dataSet={formatAuditTableForExcelExport(data, document, localization, t)}
        name="Easydocs"
      />
    </ExcelFile>
  );
};

export const ExportToExcelAudit = React.memo(_ExportToExcelAudit);

ExportToExcelAudit.propTypes = {
  element: P.node.isRequired,
  data: P.array.isRequired,
  document: P.object.isRequired
};
