import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import {
  cache,
  nameSubjectRenderer,
  selectedCellRenderer,
  actionButtonCellRenderer
} from './CellRenderers';

import { LoadingTable } from 'components/grid/LoadingTable';
import styles from './Roles.module.scss';
import { useTranslation } from 'react-i18next';

export const EntityTable = ({
  subjects,
  isLoading,
  setTableRef,
  handleClickPermissions,
  isForm,
  buttonActionText,
  minHeight = 0
}) => {
  const { t } = useTranslation();
  if (isLoading) return <LoadingTable columnSizes={[80, 60, 60, 68, 63, 49]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          selected: 150,
          actionButton: 155
        };

        const dynamicColumnWidth =
          width - (fixedColumnsWidth.selected + fixedColumnsWidth.actionButton);

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={Math.max(height, minHeight)}
            headerHeight={52}
            rowClassName={({ index }) => {
              return subjects[index]?.viewPermissions
                ? `${styles.tableRow} ${styles.active}`
                : styles.tableRow;
            }}
            rowHeight={cache.rowHeight}
            rowCount={subjects.length}
            rowGetter={({ index }) => subjects[index]}
            ref={ref => setTableRef(ref)}
          >
            <Column
              label={t('Roles.EntityTable.Name')}
              dataKey="name"
              width={dynamicColumnWidth}
              cellRenderer={props => nameSubjectRenderer({ ...props, t })}
            />
            <Column
              label=""
              dataKey="selected"
              width={fixedColumnsWidth.selected}
              cellRenderer={selectedCellRenderer}
            />
            <Column
              label=""
              dataKey="button"
              width={fixedColumnsWidth.actionButton}
              cellRenderer={props =>
                actionButtonCellRenderer({
                  ...props,
                  handleClickPermissions,
                  isForm,
                  buttonActionText
                })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
