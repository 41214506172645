import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Table, Checkbox, Tooltip, Button } from 'antd';
import { UDTGridColumns, UDTByVehicleGridColumns } from './Grid/columns';
import ResizeObserver from 'resize-observer-polyfill';
import { useLocalization } from 'features/localization/localizationSlice';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import doubleArrowDown from 'static/images/icons/doublearrow_down.svg';
import doubleArrowUp from 'static/images/icons/doublearrow_up.svg';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import styles from './UDT.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export function UDTByVehicleGrid({
  isLoading,
  data,
  assignMode,
  onSelectAllChecked,
  onSelectAllVehicleChecked,
  onSortedTableDataChanged,
  ...props
}) {
  const localization = useLocalization();
  const { t } = useTranslation();
  const [scrollSettings, setScrollSettings] = useState({});
  const tableRef = useRef();
  const [expandedRows, setExpandedRows] = useState(null);

  const udtGridColumns = useMemo(() => {
    let columns = null;
    columns = cloneDeep(UDTGridColumns.filter(c => !c.assignModeCell));
    columns[0].title = '';

    const distanceColumn = columns.find(c => c.key === 'distance');
    distanceColumn.title =
      distanceColumn?.title + ' (' + localization.formats.speed.unit.toLowerCase() + ')';
    return columns;
  }, [assignMode, data, localization]);

  const groupByVehicleData = useMemo(() => {
    let byVehicleData = [];

    data.forEach(udt => {
      const foundItem = byVehicleData.find(d => d.vehicleName === udt.vehicleName);
      if (foundItem) {
        foundItem.numEvents++;
        foundItem.data.push(udt);
      } else {
        byVehicleData.push({
          key: udt.vehicle?.id,
          vehicleName: udt.vehicleName,
          numEvents: 1,
          data: [udt]
        });
      }
    });

    return byVehicleData;
  }, [data]);

  const handleResize = entries => {
    const { height } = entries[0].contentRect;

    // account for header in table height
    const header = tableRef.current.querySelector('.ant-table-thead');
    const headerHeight = header?.getBoundingClientRect()?.height || 0;
    const newTableHeight = height - headerHeight;

    setScrollSettings({
      y: newTableHeight
    });
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    const tableElement = document.querySelector(`.${CSS.escape(styles.udtByVehicleGrid)}`);
    resizeObserver.observe(tableElement);
    return () => {
      resizeObserver.unobserve(tableElement);
    };
  }, []);

  useEffect(() => {
    // Only expand all rows on initial load, not whenever data is changed due to
    // date picker selection or due to filtering
    if (expandedRows === null && groupByVehicleData) {
      setExpandedRows(groupByVehicleData.map(row => row.key));
    }
  }, [groupByVehicleData]);

  // To support expand all button on grid header
  const allRowKeys = groupByVehicleData.map(row => row.key);
  const isAllExpanded = expandedRows?.length === allRowKeys.length;

  const udtByVehicleGridColumns = useMemo(() => {
    let udtByVehicleGridColumns = cloneDeep(UDTByVehicleGridColumns);

    if (assignMode) {
      const nonChecked = !data?.some(d => d.checked);
      const allChecked = data?.filter(d => d.checked)?.length === data?.length;

      udtByVehicleGridColumns.unshift({
        title: (
          <Tooltip
            placement="rightTop"
            title={
              nonChecked
                ? t('ELD.Select all rows for bulk assignment')
                : t('ELD.Un-select all selected rows')
            }
          >
            <Checkbox
              checked={!nonChecked}
              indeterminate={!allChecked && !nonChecked}
              onChange={onSelectAllChecked}
            />
          </Tooltip>
        ),
        render: (value, rowData, index) => {
          const allForVehicleNonChecked = !rowData?.data?.some(d => d.checked);
          const allForVehicleChecked =
            rowData?.data?.filter(d => d.checked)?.length === rowData?.data?.length;

          return (
            <Checkbox
              checked={!allForVehicleNonChecked}
              indeterminate={!allForVehicleChecked && !allForVehicleNonChecked}
              onChange={evt => onSelectAllVehicleChecked(evt, rowData)}
            />
          );
        },
        width: 40,
        key: 'checkAll',
        align: 'center'
      });
    }

    udtByVehicleGridColumns.unshift({
      title: (
        <Button
          id={BUTTON_IDS.udtByVehicleTableExpand}
          type="default"
          size="small"
          onClick={() => setExpandedRows(isAllExpanded ? [] : allRowKeys)}
        >
          <span
            style={{
              content: `url(${isAllExpanded ? doubleArrowUp : doubleArrowDown})`,
              marginTop: 5
            }}
          ></span>
        </Button>
      ),
      render: (_, record) =>
        expandedRows?.includes(record.key) ? (
          <UpOutlined
            onClick={() => {
              setExpandedRows(expandedRows.filter(row => row !== record.key));
            }}
          />
        ) : (
          <DownOutlined
            onClick={() => {
              setExpandedRows([...expandedRows, record.key]);
            }}
          />
        ),
      width: 50,
      key: 'expandAll',
      align: 'center'
    });

    return udtByVehicleGridColumns;
  }, [allRowKeys, isAllExpanded, assignMode, data, localization]);

  // offset grid when in assignMode so that all the checkboxes line up vertically
  const marginLeft = assignMode ? '38px' : '0px';

  return (
    <Table
      className={styles.udtByVehicleGrid}
      showSorterTooltip={false}
      pagination={false}
      dataSource={groupByVehicleData}
      columns={udtByVehicleGridColumns}
      expandable={{
        expandedRowRender: row => (
          <Table
            className={styles.udtGrid}
            style={{ overflow: 'hidden', marginLeft: marginLeft }}
            showSorterTooltip={false}
            pagination={false}
            dataSource={row.data}
            columns={udtGridColumns}
            summary={currentData => {
              // Hack to get table's sorted and filtered table data since onUpdate only triggers on user actions
              // and not when we filter the dataSource externally
              if (currentData?.length > 0 && expandedRows?.includes(currentData[0].vehicle?.id)) {
                onSortedTableDataChanged(currentData);
              }
              return null;
            }}
            sticky={true}
            scroll={{ x: 'max-content' }}
          />
        ),
        expandedRowKeys: expandedRows || [],
        showExpandColumn: false
      }}
      ref={tableRef}
      sticky={true}
      scroll={scrollSettings}
    />
  );
}
