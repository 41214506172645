export const folderlessFolderFile = t => ({
  name: t('Easydocs.FolderlessDocuments'),
  description: ' ',
  id: -1
});

export const initialValuesForAddDocument = {
  name: [],
  description: [],
  folders: [],
  acknowledge: []
};

//Button styles
export const buttonStyle = {
  default: {
    fontWeight: 700,
    borderRadius: '4px',
    minHeight: '32px',
    fontSize: '13px',
    minWidth: '102px',
    padding: '5px 16px',
    background: '#fff',
    boxSizing: 'border-box'
  },
  standardBtn: {
    margin: '0 16px 0 auto',
    border: '1px solid #dadee3',
    color: '#2b323c'
  },
  deleteBtn: {
    margin: '0 16px 0 auto',
    border: '1px solid #ff4d4f',
    color: '#ff4d4f'
  },
  disabledStandardBtn: {
    backgroundColor: '#e8ebee',
    color: '#9fa9b7'
  },
  disabledDeleteBtn: {
    backgroundColor: '#e8ebee'
  }
};

export const eventCodes = {
  'DOC-RECV': 'RECEIVED',
  'DOC-UPL': 'UPLOADED',
  'DOC-ACK': 'ACKNOWLEDGED',
  'DOC-READ': 'READ',
  'DOC-DEL': 'AUTO-REMOVAL',
  'DOC-EXPIRED': 'EXPIRED',
  'DOC-EXPIRE-SET': 'EXPIRATION SET',
  'DOC-EXPIRE-CHANGED': 'EXPIRATION CHANGE'
};

export const uploadEventCode = 'DOC-UPL';

export const opaqueArrowStyle = {
  strokeColor: '#FFF',
  opacity: 1,
  transparency: 0.5,
  scale: 1,
  strokeWeight: 2
};

export const polylineStyle = {
  strokeOpacity: 1,
  strokeWeight: 6
};

export const polylineArrowAnimationSpeed = 600;
