import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { toString } from 'lodash';

export const toastsSlice = createSlice({
  initialState: {
    toastIdSeq: 0,
    toasts: [],
    bulkUpdateMode: false,
    filterUpdates: false
  },
  name: 'toasts',
  reducers: {
    addToast: (state, { payload }) => {
      state.toastIdSeq++;
      payload.toastId = state.toastIdSeq;
      state.toasts.push(payload);
    },
    removeToast: (state, { payload }) => {
      const index = state.toasts.findIndex(toast => toast.toastId === payload);
      if (index >= 0) {
        state.toasts.splice(index, 1);
      }
    },
    setBulkUpdateMode: state => {
      state.bulkUpdateMode = !state.bulkUpdateMode;
    },
    setFilterUpdates: (state, action) => {
      state.filterUpdates = action.payload;
    }
  }
});

export const openToast = ({ type, message, ...props }) => dispatch => {
  if (props.body) {
    message = props.body + ' ' + toString(message);
  }
  if (props.title) {
    message = props.title + ' ' + toString(message);
  }
  dispatch(toastsSlice.actions.addToast({ type, message, ...props }));
};

export const closeToast = toastId => dispatch => {
  dispatch(toastsSlice.actions.removeToast(toastId));
};
export const setBulkUpdateState = () => dispatch => {
  dispatch(toastsSlice.actions.setBulkUpdateMode());
};

export const setFilterUpdates = value => dispatch => {
  dispatch(toastsSlice.actions.setFilterUpdates(value));
};

export const useToast = () => useSelector(state => state.toasts.toasts);
export const useBulkUpdateMode = () => useSelector(state => state.toasts.bulkUpdateMode);
export const useFiltersModified = () => useSelector(state => state.toasts.filterUpdates);

export default toastsSlice.reducer;
