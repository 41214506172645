import { sortStrings } from 'utils/strings';

export const Paths = {
  ENROLMENTS_TABLE: '/settings/enrolments',
  ADD_NEW_ENROLMENT: '/settings/enrolments/new',
  VIEW_ENROLEMNT: '/settings/enrolments/view',
  EDIT_ENROLMENT: '/settings/enrolments/edit'
};

export const EnrolmentStatuses = {
  APPROVED: 'APPROVED',
  CANCELLED: 'CANCELLED'
};

export const ColumnKeys = {
  STATUS: 'status',
  ENROLMENT: 'enrolment',
  ENROLMENT_ID: 'enrolmentId',
  SCHEME: 'scheme',
  COMPANY_NAME: 'companyName',
  OPERATOR_NAME: 'operatorName',
  DEVICE: 'device',
  VEHICLE_REGISTRATION: 'vehicleRegistration',
  APPROVAL_DATE: 'approvalDate',
  PREVIOUS_ENROLMENT: 'previousEnrolment',
  CANCELLATION_DATE: 'cancellationDate',
  ACTIONS: 'actions'
};

export const ENROLMENTS_TABLE_COLUMNS = [
  {
    title: 'Status',
    label: 'Status',
    dataIndex: ColumnKeys.STATUS,
    key: ColumnKeys.STATUS,
    sorter: (a, b) => sortStrings(a?.status?.props?.status, b?.status?.props?.status)
  },
  {
    title: 'Enrolment ID',
    label: 'Enrolment ID',
    dataIndex: ColumnKeys.ENROLMENT_ID,
    key: ColumnKeys.ENROLMENT_ID,
    sorter: (a, b) => a.key - b.key
  },
  {
    title: 'Scheme',
    label: 'Scheme',
    dataIndex: ColumnKeys.SCHEME,
    key: ColumnKeys.SCHEME,
    sorter: (a, b) => sortStrings(a?.scheme?.props?.children, b?.scheme?.props?.children)
  },
  {
    title: 'Company',
    label: 'Company',
    dataIndex: ColumnKeys.COMPANY_NAME,
    key: ColumnKeys.COMPANY_NAME,
    sorter: (a, b) => sortStrings(a?.companyName, b?.companyName)
  },
  {
    title: 'Device',
    label: 'Device',
    dataIndex: ColumnKeys.DEVICE,
    key: ColumnKeys.DEVICE,
    sorter: (a, b) =>
      sortStrings(a?.device?.props?.children?.props?.imei, b?.device?.props?.children?.props?.imei)
  },
  {
    title: 'Vehicle Registration',
    label: 'Vehicle Registration',
    dataIndex: ColumnKeys.VEHICLE_REGISTRATION,
    key: ColumnKeys.VEHICLE_REGISTRATION,
    sorter: (a, b) => sortStrings(a?.vehicleRegistration, b?.vehicleRegistration)
  },
  {
    title: 'Approval Date/Time',
    label: 'Approval Date/Time',
    dataIndex: ColumnKeys.APPROVAL_DATE,
    key: ColumnKeys.APPROVAL_DATE,
    sorter: (a, b) => {
      const dateA = new Date(a?.approvalDate);
      const dateB = new Date(b?.approvalDate);

      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    }
  },
  {
    title: 'Previous Enrolment',
    label: 'Previous Enrolment',
    dataIndex: ColumnKeys.PREVIOUS_ENROLMENT,
    key: ColumnKeys.PREVIOUS_ENROLMENT
  },
  {
    title: 'Cancellation Date/Time',
    label: 'Cancellation Date/Time',
    dataIndex: ColumnKeys.CANCELLATION_DATE,
    key: ColumnKeys.CANCELLATION_DATE,
    sorter: (a, b) => {
      const dateA = new Date(a?.cancellationDate);
      const dateB = new Date(b?.cancellationDate);

      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    }
  },
  {
    title: 'Actions',
    label: 'Actions',
    dataIndex: ColumnKeys.ACTIONS,
    key: ColumnKeys.ACTIONS,
    width: 100
  }
];

export const ENROLMENTS_XLSX_FILE_NAME = 'Enrolments';

const NO_OF_COLUMNS = 9;
export const columnWidth = Array.apply(null, { length: NO_OF_COLUMNS }).map(() => ({ wch: 25 }));
