import { Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const AntFormInput = ({ properties, onChange, setIsDirty, alerts, alertId, noStyle }) => {
  const { labelCol, wrapperCol, name, label, tooltip, placeholder, rules } = properties;
  const { t } = useTranslation();

  const handleOnChange = event => {
    if (typeof setIsDirty === 'function') {
      setIsDirty(true);
    }
    onChange(event);
  };

  const validateNameDuplicates = {
    validator: (_, value) => {
      const filteredAlerts = alertId ? alerts.filter(alert => alert.id !== alertId) : alerts;
      if (!filteredAlerts.some(alert => alert.name === value)) {
        return Promise.resolve();
      }

      return Promise.reject(new Error(t('Alerts.Duplicate name')));
    }
  };

  const updatedRules = [...rules, name === 'alertNameInput' ? validateNameDuplicates : null];

  return (
    <Form.Item
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name={name}
      label={label}
      tooltip={tooltip}
      rules={name === 'alertNameInput' ? updatedRules : rules}
      noStyle={noStyle}
    >
      <Input placeholder={placeholder} onChange={handleOnChange} />
    </Form.Item>
  );
};

AntFormInput.propTypes = {
  properties: PropTypes.shape({
    labelCol: PropTypes.shape({
      offset: PropTypes.number,
      span: PropTypes.number
    }),
    wrapperCol: PropTypes.shape({
      offset: PropTypes.number,
      span: PropTypes.number
    }),
    defaultValue: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    rules: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default AntFormInput;
