import React, { useEffect, useMemo } from 'react';
import {
  leftAxisRender,
  bottomAxisRender,
  ViolationRender,
  flagRender,
  eventRenderOptionGenerator,
  horizontalBarRender,
  flagRenderOptionGenerator,
  TimezoneRender,
  DutyStatus
} from 'components/sentinel/GanntChart';
import { GanntChartComponent } from 'components/sentinel/GanntChartComponent';
import moment from 'moment-timezone';
import {
  fetchDriverCheckpoint,
  useDriverCheckpoint,
  useDriverEventsByDate,
  useDriverViolationsByDate
} from 'features/eld/driverLogSlice';
import { useDispatch } from 'react-redux';
import { useLocalization } from 'features/localization/localizationSlice';
import { formatEventDuration, formatZoneCycle, formatDeferral } from './util';
import { useDrivers } from 'features/users/usersSlice';
import i18next from 'i18nextConfig';
import { displayUSTimezone } from 'features/regions/timezones';
import { sortBy } from 'lodash';

const DutyStatusKey = new Proxy(DutyStatus, {
  get(target, prop, receiver) {
    return prop;
  }
});

const MapActionToCategory = {
  Driving: 'Driving',
  OnDuty: 'OnDuty',
  OffDuty: 'OffDuty',
  OffDutyAtWell: 'OffDuty',
  SleeperBerth: 'SleeperBerth',
  PersonalConveyance: 'OffDuty',
  YardMove: 'OnDuty',
  ExemptionAreaExited: 'Driving',
  AdverseConditions: 'Driving'
};

export function DriverHistoryStatusLineChart({
  logDate,
  driverId,
  company,
  dataAction,
  rowActions,
  ...props
}) {
  const dispatch = useDispatch();
  const localization = useLocalization();
  const drivers = useDrivers();
  const driver = useMemo(() => drivers?.find(d => d.id === parseInt(driverId)), [
    driverId,
    drivers
  ]);
  const dateRange = useMemo(() => {
    return {
      startDate: moment
        .tz(
          logDate || new Date(),
          driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
        )
        .startOf('day')
        .toDate(),
      endDate: moment
        .tz(
          logDate || new Date(),
          driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
        )
        .endOf('day')
        .toDate()
    };
  }, [driver, logDate]);

  const driverEvents = useDriverEventsByDate(
    company?.id,
    driver,
    moment(logDate || new Date())
      .startOf('day')
      .toDate(),
    moment(logDate || new Date())
      .endOf('day')
      .toDate()
  );

  const driverViolations = useDriverViolationsByDate(
    company.id,
    driverId,
    moment(logDate || new Date())
      .startOf('day')
      .toDate(),
    moment(logDate || new Date())
      .endOf('day')
      .toDate()
  );

  const driverCheckpoint = useDriverCheckpoint(company?.id, driverId);

  useEffect(() => {
    dispatch(fetchDriverCheckpoint(company, driverId));
  }, [dispatch, company, driverId, logDate]);

  const data = useMemo(() => {
    const data = [];
    const timeZone = driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
    data.push({
      renderType: 'timezoneRender',
      timezone: displayUSTimezone(timeZone) + ' ' + i18next.t('Home.Time')
    });

    if (driverViolations != null && driverViolations.violations != null) {
      driverViolations.violations
        .filter(
          v => v.finish > dateRange.startDate.getTime() || v.start > dateRange.startDate.getTime()
        )
        .forEach(d => {
          const violationData = {
            id: 'violation_' + d.rule.id,
            renderType: 'violationRender',
            startPeriod: new Date(d.start),
            endPeriod: new Date(
              d.finish ||
                Math.min(
                  moment()
                    .tz(driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .valueOf(),
                  d.period.finish
                )
            ),
            dateRangeStartPeriod: new Date(d.period.start),
            dateRangeEndPeriod: new Date(d.period.finish),
            rule:
              '<b>[' +
              ////driverViolations.ruleset.name +
              d.rule.id.split('-')?.[0] +
              '/' +
              ////(driverViolations.ruleset.rules.find(r => r.id === d.rule.id)?.section ||
              (d.rule.section || i18next.t('Unknown')) +
              '] ' +
              d.rule.description +
              '</b>',
            dateFormat: localization.formats.time.formats.dby_imsp,
            timeZone: timeZone,
            isPredict: d.finish == null
          };
          /*
          if (violationData.startPeriod.getTime() < d.start) {
            violationData.startPeriod = new Date(d.start);
          }
          if (violationData.dateRangeStartPeriod.getTime() < d.period.start) {
            violationData.dateRangeStartPeriod = new Date(d.period.start);
          }
          */
          data.push(violationData);
        });
    }

    if (
      driverCheckpoint != null &&
      dateRange.startDate.getTime() <= driverCheckpoint &&
      dateRange.endDate.getTime() >= driverCheckpoint
    ) {
      data.push({
        renderType: 'checkpointRender',
        startPeriod: new Date(driverCheckpoint),
        dateFormat: localization.formats.time.formats.dby_imsp,
        timeZone: timeZone
      });
    }

    if (driverEvents != null) {
      const filteredEvents = [];
      driverEvents.forEach(d => {
        switch (d.action) {
          case DutyStatusKey.ExemptionAreaExited:
          case DutyStatusKey.LogonDriver:
          case DutyStatusKey.LogoffDriver:
            break;
          case DutyStatusKey.OffDutyDeferral:
            let deferralDays, deferralDuration;
            try {
              const params = JSON.parse(d.params);
              const formatedDeferral = formatDeferral(
                params.deferredSeconds,
                params.deferralDayType
              );
              deferralDays = formatedDeferral.deferralDays;
              deferralDuration = formatedDeferral.deferralDuration;
            } catch (error) {
              deferralDuration = '';
              deferralDays = i18next.t('ELD.None');
            }
            data.push({
              id: d.id,
              renderType: 'changePointRender',
              startPeriod: new Date(d.eventAt),
              dateFormat: localization.formats.time.formats.dby_imsp,
              timeZone: timeZone,
              action: d.action,
              tooltip: {
                tooltipExtra: `<p><i>${i18next.t(
                  'ELD.OffDutyTimeDeferred'
                )}</i> ${deferralDuration} </p>
                <p><i>${i18next.t('ELD.DeferralDay')}</i> ${deferralDays} </p>`
              },
              flag: {
                sizeforAbbrText: 40,
                sizeforFullText: 120
              }
            });
            break;
          case DutyStatusKey.ZoneChange:
          case DutyStatusKey.CycleChange:
            const isZoneChange = d.action === DutyStatusKey.ZoneChange;
            let from = {},
              to = {};
            try {
              const params = JSON.parse(d.params);
              from = {
                label: i18next.t(`ELD.Previous ${isZoneChange ? 'Zone' : 'Cycle'}`),
                value: formatZoneCycle(
                  isZoneChange,
                  isZoneChange ? params.oldZone : params.oldCycle
                )
              };
              to = {
                label: i18next.t(`ELD.Current ${isZoneChange ? 'Zone' : 'Cycle'}`),
                value: formatZoneCycle(
                  isZoneChange,
                  isZoneChange ? params.newZone : params.newCycle,
                  d?.ruleset
                )
              };
            } catch (error) {
              from = {};
              to = {};
            }
            data.push({
              id: d.id,
              renderType: 'changePointRender',
              startPeriod: new Date(d.eventAt),
              dateFormat: localization.formats.time.formats.dby_imsp,
              timeZone: timeZone,
              action: d.action,
              flag: {
                sizeforFullText: 90
              },
              tooltip: {
                from,
                to,
                tooltipTempl: isZoneChange ? 'period' : 'current'
              }
            });
            break;
          default:
            if (d.status !== 'M') {
              filteredEvents.push(d);
            }
            break;
        }
      });
      filteredEvents.forEach((d, idx) => {
        const eventData = {
          id: d.id,
          action: MapActionToCategory[d.action],
          actionText: d.action,
          renderType: 'eventRender',
          startPeriod: new Date(d.eventAt),
          endPeriod: null,
          status: d.status,
          duration: '',
          dateFormat: localization.formats.time.formats.dby_imsp,
          timeZone: timeZone,
          isPrevEvent: d.isPrevEvent
        };

        if (d.isPrevEvent) {
          /*a leading in event will show the original start date*/
          eventData.originStartPeriod = eventData.startPeriod;
          eventData.startPeriod = dateRange.startDate; //this is for render in the current selected date
        }

        if (driverCheckpoint != null && d.eventAt > driverCheckpoint) {
          eventData.isPredict = true;
        }

        if (idx + 1 < filteredEvents.length) {
          eventData.endPeriod = new Date(filteredEvents[idx + 1].eventAt);
        } else {
          eventData.endPeriod = moment
            .tz(
              eventData.startPeriod,
              driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
            )
            .endOf('day')
            .add(1, 'second')
            .toDate();
          if (d.isPrevEvent) {
            eventData.onlyPrevEvent = true;
          }
        }

        if (
          !eventData.isPredict &&
          eventData.endPeriod.getTime() > driverCheckpoint &&
          !d.isPrevEvent
        ) {
          //create predict event
          const predictEvent = Object.assign({}, eventData);
          predictEvent.id = '';
          predictEvent.startPeriod = new Date(driverCheckpoint);
          predictEvent.isPredict = true;

          eventData.endPeriod = new Date(driverCheckpoint);
          data.push(predictEvent);
        }
        eventData.duration = formatEventDuration(
          moment.duration(
            (Math.floor(eventData.endPeriod / 1000) - Math.floor(eventData.startPeriod / 1000)) *
              1000,
            'milliseconds'
          )
        );
        data.push(eventData);
      });
    }

    return sortBy(data, 'startPeriod');
  }, [driverEvents, driverViolations, driverCheckpoint, driver, dateRange, localization]);

  const axisOptions = useMemo(() => {
    const totalAmount = { Driving: 0, OnDuty: 0, OffDuty: 0, SleeperBerth: 0 };
    const categories = ['Off Duty', 'Sleeper Berth', 'Driving', 'On Duty'];
    data
      .filter(d => d.renderType === 'eventRender')
      .forEach(d => {
        if (d.isPredict) return;
        const cat = MapActionToCategory[d.action];
        totalAmount[cat] += d.endPeriod - d.startPeriod;
      });
    for (let action in totalAmount) {
      totalAmount[action] = formatEventDuration(
        moment.duration(totalAmount[action], 'millisecond')
      );
    }
    return {
      showBottomAxis: true,
      showBottomTicks: true,
      showLeftAxis: true,
      showHorizontalLine: true,
      timeZone: driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      leftAxis: {
        gutter: 2,
        padding: 20,
        height: 48,
        catergories: categories,
        totalAmount: totalAmount
      },
      paddingTop: 30,
      paddingBottom: 30
    };
  }, [driver, data]);

  const dataRender = useMemo(() => {
    return {
      eventRender: horizontalBarRender,
      checkpointRender: flagRender,
      violationRender: ViolationRender,
      timezoneRender: TimezoneRender,
      changePointRender: (canvas, axisOptions, dataTypeRenderOptions, data, width, height) => {
        flagRender(canvas, axisOptions, dataTypeRenderOptions, data, width, height, {
          flag: {
            title: i18next.t(`ELD.${data.action}`),
            titleAbbr: i18next.t(`ELD.${data.action}Abbr`),
            ...data.flag
          }
        });
      }
    };
  }, []);

  const dataTypeRenderOptions = useMemo(() => {
    return {
      eventRender: eventRenderOptionGenerator,
      checkpointRender: flagRenderOptionGenerator,
      changePointRender: (axisOptions, data, canvasWidth, canvasHeight, config) => {
        return flagRenderOptionGenerator(axisOptions, data, canvasWidth, canvasHeight, {
          ...config,
          id: data.id,
          tooltip: {
            tooltipTitle: i18next.t(`ELD.${data.action}`),
            ...data.tooltip
          },
          flag: data.flag || {}
        });
      }
    };
  }, []);

  return (
    <GanntChartComponent
      startPeriod={dateRange.startDate}
      endPeriod={dateRange.endDate}
      data={data}
      axisOptions={axisOptions}
      leftAxisRender={leftAxisRender}
      bottomAxisRender={bottomAxisRender}
      dataRender={dataRender}
      dataAction={rowActions}
      dataTypeRenderOptions={dataTypeRenderOptions}
    />
  );
}
