import { ShowGeofenceTypes } from 'containers/Settings/constants.js';

export const GEOFENCE_DEFAULT_COLORS = {
  MANAGED: '#6A0DAD',
  NON_MANAGED: '#000000'
};

export function filterGeofencesByShowTypes(geofences, showTypes, availableManagedGeofences) {
  if (showTypes === ShowGeofenceTypes.All) {
    return geofences;
  } else if (showTypes === ShowGeofenceTypes.OnlyUser) {
    return geofences?.filter(g => ['USER', 'TRAFFIC', 'LOAD'].includes(g.type));
  } else if (showTypes === ShowGeofenceTypes.OnlyManaged) {
    return geofences?.filter(g => availableManagedGeofences?.includes(g.type));
  }
  return [];
}

export const isMultiPolygonGeofence = geofence =>
  geofence?.shape2?.type === 'MULTIPOLYGON' && geofence?.shape2?.multiPoints.length;

export const isManagedGeofence = (geofenceProviders, geofence) =>
  geofenceProviders?.some(gType => gType === geofence.type);

export const getNonManagedGeofences = (geofenceProviders, geofences = []) =>
  geofences.filter(g => !isManagedGeofence(geofenceProviders, g));

export const getGeofenceStyle = (geofenceProviders, geofence) => {
  const color = isManagedGeofence(geofenceProviders, geofence)
    ? GEOFENCE_DEFAULT_COLORS.MANAGED
    : GEOFENCE_DEFAULT_COLORS.NON_MANAGED;
  let ret = {
    color,
    weight: 2
  };
  try {
    const style = geofence.style ? JSON.parse(geofence.style) : {};
    ret.color = style.colour || color;
    ret.weight = style.weight || 2;
  } catch (error) {
    ret = {
      color,
      weight: 2
    };
  }
  return ret;
};

export const getGeofencePoints = (geofence, flatten) => {
  let geofencePoints = isMultiPolygonGeofence(geofence)
    ? geofence?.shape2?.multiPoints
    : geofence?.shape?.multiPoints || [geofence?.shape?.points];
  geofencePoints = geofencePoints?.filter(pSet => !!pSet?.length) || [];
  return flatten ? geofencePoints.reduce((a, c) => a.concat(c), []) : geofencePoints;
};

export const isDrawableGeofence = geofence =>
  isDrawablePolygonGeofence(geofence) || isDrawableMultiPolygonGeofence(geofence);

export const isDrawablePolygonGeofence = geofence =>
  (geofence?.shape?.multiPoints?.[0]?.length || geofence?.shape?.points?.length) &&
  geofence?.shape?.type === 'POLYGON';
const isDrawableMultiPolygonGeofence = geofence =>
  geofence?.shape2?.multiPoints?.[0].length && geofence?.shape2?.type === 'MULTIPOLYGON';

export const extendGeofences = (geofences = [], extender = () => {}) => {
  for (const geofence of geofences || []) {
    geofence.shape?.points?.map?.(extender);
    geofence.shape?.multiPoints?.map?.(multiPoint => multiPoint.map(extender));
    geofence.shape2?.multiPoints?.map?.(multiPoint => multiPoint.map(extender));
  }
};

export const getGeofenceShapeType = geofence => geofence?.shape2?.type || geofence?.shape?.type;
