import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Row, Col, InputNumber } from 'antd';
import styles from './CompanyConfig.module.scss';
import { Loading } from 'components/loading/Loading';
import { ApiClient } from 'nextgen_api';
import { API_PATH } from 'config';
import { useUserKey } from 'features/user/userSlice';
import {
  fetchCompanyConfig,
  useCompanyConfig,
  useCompanyConfigMeta,
  useCurrentCompany
} from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useDispatch } from 'react-redux';
import { t_error } from 'i18nextConfig';
import { StateSelect } from 'features/common/StateSelect';
import { CountrySelect } from 'features/common/CountrySelect';
import { cloneDeep } from 'lodash';
import {
  services,
  useCanOneOfCompanyServices,
  useCanEveryCompanyService
} from 'features/permissions';
import { BUTTON_IDS } from 'utils/globalConstants';

const eldFields = [
  {
    label: 'Carrier name',
    name: 'name',
    component: Input,
    rules: [
      { required: true, message: 'The carrier name is required.' },
      {
        max: 120,
        message: 'The maximum length of carrier name is 120.'
      },
      {
        min: 4,
        message: 'The minimal length of carrier name is 4.'
      },
      {
        pattern: /^(?!\s)([a-zA-Z0-9][A-Za-z0-9 ]*[A-Za-z0-9])$/,
        message: 'Leading or trailing spaces, and special characters are not allowed.'
      }
    ],
    placeholder: 'Type carrier name here',
    componentProp: {},
    span: 12
  },
  {
    label: 'DOT Number',
    name: 'dotNumber',
    component: Input,
    rules: [
      { required: true, message: 'The DOT number is required.' },
      {
        min: 1,
        message: 'The minimal DOT number is 1.'
      },
      {
        pattern: /^\d{1,9}$/,
        message: 'DOT Number must be between 0 and 999999999.'
      }
    ],
    placeholder: 'Type DOT number here',
    componentProp: {},
    span: 12
  },
  {
    label: 'Country',
    name: 'country',
    component: CountrySelect,
    rules: [{ required: true, message: 'The country is required.' }],
    placeholder: '',
    componentProp: {},
    span: 12
  },
  {
    label: 'State',
    name: 'state',
    component: StateSelect,
    rules: [{ required: true, message: 'The state is required.' }],
    placeholder: '',
    componentProp: {},
    span: 12
  },
  {
    label: 'City',
    name: 'city',
    component: Input,
    rules: [
      { required: true, message: 'The city is required.' },
      { max: 60, message: 'The maximum length of the city is 60.' },
      {
        pattern: /^(?!\s)([a-zA-Z0-9][A-Za-z0-9 ]*[A-Za-z0-9])$/,
        message: 'Leading or trailing spaces, and special characters are not allowed.'
      }
    ],
    placeholder: 'Type city here.',
    componentProp: {},
    span: 12
  },
  {
    label: 'Post Code',
    name: 'postcode',
    component: Input,
    rules: [
      { required: true, message: 'The zip code is required.' },
      { max: 12, message: 'The maximum length of the zipcode is 12.' },
      {
        pattern: /^(?!\s)([a-zA-Z0-9][A-Za-z0-9 ]*[A-Za-z0-9])$/,
        message: 'Leading or trailing spaces, and special characters are not allowed.'
      }
    ],
    placeholder: 'Type zip code here',
    componentProp: {},
    span: 12
  },
  {
    label: 'Address',
    name: 'address1',
    component: Input.TextArea,
    rules: [
      { required: true, message: 'The address is required.' },
      {
        pattern: /^(?!\s)([a-zA-Z0-9][A-Za-z0-9 ]*[A-Za-z0-9])$/,
        message: 'Leading or trailing spaces, and special characters are not allowed.'
      },
      { max: 500, message: 'The maximum length of the address is 500.' }
    ],
    placeholder: 'Type address here.',
    componentProp: {},
    span: 12
  },
  {
    label: 'Address 2',
    name: 'address2',
    component: Input.TextArea,
    rules: [
      {
        pattern: /^(?!\s)([a-zA-Z0-9][A-Za-z0-9 ]*[A-Za-z0-9])$/,
        message: 'Leading or trailing spaces, and special characters are not allowed.'
      },
      { max: 500, message: 'The maximum length of the address2 is 500.' }
    ],
    placeholder: 'Type secondary address here.',
    componentProp: {},
    span: 12
  }
];

const DataStatus = {
  Init: 'Init',
  Fetching: 'Fetching',
  Fetched: 'Fetched',
  Changed: 'Changed',
  Submitting: 'Submitting',
  Submitted: 'Submitted',
  Error: 'Error'
};

const CustomInputComponent = ({ dataProp }) => {
  const [isTouched, setIsTouched] = useState(false);
  const suffixProp = { suffix: <span /> };
  const componentProp = { ...dataProp.componentProp, ...(!isTouched && suffixProp) };

  return (
    <Form.Item
      key={dataProp.label}
      name={dataProp.name}
      rules={dataProp.rules}
      label={dataProp.label}
      hasFeedback
    >
      {
        <dataProp.component
          placeholder={dataProp.placeholder}
          onChange={e => setIsTouched(true)}
          {...componentProp}
        />
      }
    </Form.Item>
  );
};

export function ELDConfigModal({ handleShow = () => {}, show, onClose, data = null, ...props }) {
  const [dataStatus, setDataStatus] = useState(DataStatus.Init);
  const [form] = Form.useForm();
  const userKey = useUserKey();
  const company = useCurrentCompany();
  const [country, setCountry] = useState('');
  const companyConfigFetchStatus = useCompanyConfigMeta();
  const canUseELDCAN = useCanOneOfCompanyServices(services.ELDCAN);
  const isELDUS = useCanEveryCompanyService([services.ELDUS]);
  const selectedCarrierForEditData = data;
  const companyConfig = useCompanyConfig();
  const eldConfigValues = !isELDUS ? companyConfig : {};

  const dispatch = useDispatch();

  const handleCountryChanged = useCallback(
    value => {
      form.setFieldValue('state', null);
      setCountry(value);
    },
    [form]
  );

  const handleSave = useCallback(() => {
    form.submit();
  }, [form]);

  const handleCancel = useCallback(() => {
    if (dataStatus === DataStatus.Submitting) return;
    //On cancel reset the data status
    setDataStatus(DataStatus.Init);
    if (onClose) {
      onClose();
    }
    handleShow(false);
  }, [onClose, dataStatus]);

  const handleSubmit = useCallback(
    values => {
      setDataStatus(DataStatus.Submitting);
      const apiClient = new ApiClient();
      apiClient.basePath = API_PATH;

      apiClient.defaultHeaders = {
        Authorization: `Token token="${userKey}"`
      };
      const apiQuery = '/carrier';

      if (!values.company) {
        values.company = { id: company.id };
      }
      if (!values.status) {
        values.status = 'ENABLED';
      }
      if (!values.id) {
        values.id = form.getFieldValue('id');
      }
      values.officialIdentifer = values.dotNumber?.toString();

      const promise = new Promise((resolve, reject) => {
        apiClient.callApi(
          apiQuery,
          'POST',
          { companyId: company.id },
          {},
          {},
          {},
          values,
          [],
          [],
          [],
          null,
          null,
          (err, data, resp) => {
            if (err && (resp == null || resp.status !== 200)) {
              console.error(err);
              reject(resp?.body || err);
            } else {
              resolve(resp.body);
            }
          }
        );
      });

      promise.then(
        () => {
          dispatch(openToast({ type: ToastType.Success, message: 'ELD Configuration Saved.' }));
          setDataStatus(DataStatus.Submitted);
          if (onClose) {
            onClose();
          }
          handleShow(false);
        },
        err => {
          setDataStatus(DataStatus.Error);
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t_error(err).toString()
            })
          );
        }
      );
    },
    [dispatch, onClose]
  );

  useEffect(() => {
    if (dataStatus === DataStatus.Init && !isELDUS) {
      if (userKey == null || company == null || selectedCarrierForEditData) return;
      setDataStatus(DataStatus.Fetching);
      dispatch(fetchCompanyConfig(company, userKey, isELDUS));
    }
  }, [dataStatus, userKey, company, dispatch, isELDUS]);

  useEffect(() => {
    if (!companyConfigFetchStatus.isFetching) {
      if (!companyConfigFetchStatus.error && companyConfigFetchStatus.lastFetched != null) {
        setDataStatus(DataStatus.Fetched);
        if (Object.keys(eldConfigValues).length) {
          setCountry(eldConfigValues?.country);
          form.setFieldsValue(eldConfigValues);
        }
      } else if (companyConfigFetchStatus.error) {
        setDataStatus(DataStatus.Error);
        if (onClose) {
          onClose();
        }
      }
    }
  }, [companyConfigFetchStatus, eldConfigValues, onClose, form]);

  const mixedFormFields = useMemo(() => {
    let updatedRecordFields = eldFields.map(f => {
      const fieldProp = cloneDeep(f);
      if (f.name === 'country') {
        fieldProp.componentProp = { onChange: handleCountryChanged };
      } else if (f.name === 'state') {
        //Check country from form on the minute refresh.
        if (!country) {
          var formCountry = form.getFieldValue('country');
          if (formCountry) {
            setCountry(form.getFieldValue('country'));
          }
        }
        fieldProp.componentProp = { country: country };
      } else if (f.name === 'dotNumber' || f.name === 'officialIdentifier') {
        fieldProp.rules[0].required = !canUseELDCAN;
      }
      return fieldProp;
    });
    return updatedRecordFields;
  }, [handleCountryChanged, country, eldConfigValues]);

  const modalFooter = useMemo(() => {
    return (
      <Row gutter={10}>
        <Col>
          <Button
            type="primary"
            disabled={dataStatus === DataStatus.Init}
            loading={dataStatus === DataStatus.Submitting}
            onClick={handleSave}
            id={BUTTON_IDS.eldConfigSave}
          >
            Save
          </Button>
        </Col>
        <Col>
          <Button
            id={BUTTON_IDS.eldConfigCancel}
            disabled={dataStatus === DataStatus.Submitting}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
      </Row>
    );
  }, [dataStatus, handleCancel, handleSave]);

  return (
    <Modal
      className={styles.configModal}
      title="ELD Configuration"
      open={show}
      onCancel={handleCancel}
      footer={modalFooter}
    >
      {[DataStatus.Init, DataStatus.Fetching].indexOf(dataStatus) >= 0 && <Loading />}
      {[DataStatus.Init, DataStatus.Fetching].indexOf(dataStatus) < 0 && (
        <Form
          layout="vertical"
          form={form}
          name="eld_config_form"
          initialValues={isELDUS && data ? selectedCarrierForEditData : eldConfigValues}
          className={styles.configForm}
          onFinish={handleSubmit}
        >
          {mixedFormFields.map(d => (
            <CustomInputComponent dataProp={d} />
          ))}
        </Form>
      )}
    </Modal>
  );
}
