import * as moment from 'moment-timezone';
import { format } from 'utils/dates';
import i18next from 'i18nextConfig';

export function formatDuration(duration) {
  const d = moment.duration(duration, 'seconds');
  let formatStr = '';
  /*
  if (d.days() >= 1) {
    formatStr = d.days() + (d.days() > 1 ? ' days' : ' day');
  }
  */
  if (d.asHours() > 0) {
    formatStr +=
      ' ' +
      parseInt(d.asHours()) +
      ' ' +
      (parseInt(d.asHours()) > 1 ? i18next.t('Common.hours') : i18next.t('Common.hour'));
  }

  if (d.minutes() > 0) {
    formatStr +=
      ' ' +
      d.minutes() +
      ' ' +
      (d.minutes() > 0 ? i18next.t('Common.Mins') : i18next.t('Common.min'));
  }

  if (formatStr === '') {
    return '0';
  }

  return formatStr;
}

export const driverLogData = {
  statusChart: {
    requiredBreaksLeft: 5,
    totalBreaks: 5,
    driveTimeLeft: 0,
    totalDriveTime: 0,
    onDutyTimeLeft: 0,
    totalOnDutyTime: 0,
    cycleTimeLeft: 0,
    totalCycleTime: 0
  },
  statusCard: {},
  infoCard: {
    userName: '',
    vehicleName: '',
    status: 'Unknown'
  },
  lineChart: {}
};

export const eldStatusMap = {
  get A() {
    return i18next.t('ELD.Assigned');
  },
  get C() {
    return i18next.t('ELD.Commented');
  },
  get U() {
    return i18next.t('ELD.Unassigned');
  },
  get P() {
    return i18next.t('ELD.Pending');
  },
  get R() {
    return i18next.t('ELD.Rejected');
  },
  get M() {
    return i18next.t('ELD.Accepted');
  },
  get E() {
    return i18next.t('ELD.Normal');
  },
  get L() {
    return i18next.t('ELD.Signed');
  },
  get D() {
    return i18next.t('ELD.Deleted');
  }
};

export const DutyStatus = Object.defineProperties(
  //### Used for the dropdown on New/Edit Status Record ###
  {
    Driving: 'Driving',
    OnDuty: 'On Duty',
    OffDuty: 'Off Duty',
    OffDutyAtWell: 'Off Duty At Well',
    SleeperBerth: 'Sleeper Berth',
    PersonalConveyance: 'Personal Conveyance',
    YardMove: 'Yard Move',
    AdverseConditions: 'Adverse Conditions',
    IntermediateLog: 'Intermediate Log'
  },
  //########################################################
  {
    'Logon Driver': {
      value: 'Logon Driver',
      enumerable: false
    },
    'Logoff Driver': {
      value: 'Logoff Driver',
      enumerable: false
    },
    LogonDriver: {
      value: 'Logon Driver',
      enumerable: false
    },
    LogoffDriver: {
      value: 'Logoff Driver',
      enumerable: false
    },
    'Intermediate Log': {
      value: 'Intermediate Log',
      enumerable: false
    },
    'On Duty': {
      value: 'On Duty',
      enumerable: false
    },
    'Off Duty': {
      value: 'Off Duty',
      enumerable: false
    },
    'Off Duty At Well': {
      value: 'Off Duty At Well',
      enumerable: false
    },
    'Sleeper Berth': {
      value: 'Sleeper Berth',
      enumerable: false
    },
    'Personal Conveyance': {
      value: 'Personal Conveyance',
      enumerable: false
    },
    'Yard Move': {
      value: 'Yard Move',
      enumerable: false
    },
    'Adverse Conditions': {
      value: 'Adverse Conditions',
      enumerable: false
    },
    ExemptionAreaExited: {
      value: 'Exemption Area Exited',
      enumerable: false
    },
    'Exemption Area Exited': {
      value: 'Exemption Area Exited',
      enumerable: false
    },
    Unknown: {
      value: 'Unknown',
      enumerable: false
    },
    ZoneChange: {
      value: 'Zone Change',
      enumerable: false
    },
    'Zone Change': {
      value: 'Zone Change',
      enumerable: false
    },
    CycleChange: {
      value: 'Cycle Change',
      enumerable: false
    },
    'Cycle Change': {
      value: 'Cycle Change',
      enumerable: false
    },
    OffDutyDeferral: {
      value: 'Off Duty Deferral',
      enumerable: false
    },
    'Off Duty Deferral': {
      value: 'Off Duty Deferral',
      enumerable: false
    }
  }
);

export const MapActionToChartStatus = {
  Driving: 'Driving',
  OnDuty: 'On Duty',
  OffDuty: 'Off Duty',
  OffDutyAtWell: 'Off Duty',
  SleeperBerth: 'Sleeper Berth',
  PersonalConveyance: 'Off Duty',
  YardMove: 'On Duty',
  AdverseConditions: 'Driving',
  IntermediateLog: 'Intermediate Log'
};

export const MapChartStatusToAction = {
  Driving: 'Driving',
  'On Duty': 'OnDuty',
  'Off Duty': 'OffDuty',
  'Sleeper Berth': 'SleeperBerth'
};

export function formatEventDuration(duration) {
  let durationStr = '';
  if (duration.asHours() >= 1) {
    durationStr =
      parseInt(duration.asHours()) +
      (duration.asHours() > 1 ? i18next.t('Common.hours') : i18next.t('Common.hour')) +
      ' ';
  }

  if (durationStr !== '' || duration.minutes() > 0) {
    durationStr +=
      duration.minutes() +
      (duration.minutes() > 1 ? i18next.t('Common.Mins') : i18next.t('Common.Min')) +
      ' ';
  }

  if (duration.seconds() > 0) {
    durationStr += duration.seconds() + 's ';
  }

  if (durationStr === '') {
    durationStr = 0;
  }
  return durationStr;
}

export function formatDate(date, formatStr, timeZone) {
  if (timeZone != null) {
    // return format(datefn.utcToZonedTime(date, timeZone), formatStr);
    return format(moment.tz(date, timeZone), formatStr);
  }
  return format(date, formatStr);
}

export const OriginDescripion = {
  get Request() {
    return i18next.t('ELD.Request');
  },
  get Device() {
    return i18next.t('Tracking.Device');
  },
  get Driver() {
    return i18next.t('Common.Driver');
  }
};

export function formatZoneCycle(isZoneChange, value, currentRuleSet) {
  if (!value) {
    return i18next.t('ELD.None');
  }
  try {
    if (isZoneChange) {
      return `${i18next.t(`ELD.Zone.${value}`)}`;
    } else {
      switch (currentRuleSet) {
        case 'ELDCAN1':
          return i18next.t('ELD.Cycle.South Cycle 1');
        case 'ELDCAN2':
          return i18next.t('ELD.Cycle.South Cycle 2');
        case 'ELDCAN3':
          return i18next.t('ELD.Cycle.North Cycle 1');
        case 'ELDCAN4':
          return i18next.t('ELD.Cycle.North Cycle 2');
        default:
          return `${i18next.t(`ELD.Cycle.${value}`)}`;
      }
    }
  } catch (error) {
    return value;
  }
}

export function formatDeferral(deferredSeconds, deferralDayType) {
  const deferralDuration = moment
    .utc(
      moment
        .duration(!isNaN(Number(deferredSeconds)) ? deferredSeconds : 0, 'seconds')
        .as('milliseconds')
    )
    .format('HH:mm');
  const deferralDays =
    Number(deferralDayType) === 1 || Number(deferralDayType) === 2
      ? `${deferralDayType}`
      : i18next.t('ELD.None');
  return {
    deferralDuration,
    deferralDays
  };
}
