import React, { useRef } from 'react';
import styles from './Preview.module.scss';
import { Row, Col } from 'react-bootstrap';
import './Preview.scss';
import removeIcon from 'static/images/icons/remove.svg';
import removeRedIcon from 'static/images/icons/remove-red.svg';
import dragHandleIcon from 'static/images/icons/drag-handle.svg';
import dragHandleRedIcon from 'static/images/icons/drag-handle-red.svg';
import ElementProperties from 'components/form-builder/element-properties/Properties';
import { SortableHandle, SortableElement, SortableContainer } from 'react-sortable-hoc';
import ElementPreview from './Element';
import { Can } from 'features/permissions';
import { useScrollIntoView } from 'utils/hooks/useScrollIntoView';

const DragHandle = SortableHandle(() => <i style={{ content: `url(${dragHandleIcon})` }} />);
const DragHandleRed = SortableHandle(() => <i style={{ content: `url(${dragHandleRedIcon})` }} />);
const FormPreview = ({
  element,
  onDelete,
  setProperty,
  setFollowUpProperty,
  setActiveElement,
  isActive,
  isDraggable,
  setFailedQuestionProperty,
  isPretripChecklist,
  ElementDynamicFields = () => null,
  isFailedQuestion
}) => {
  const { Icon } = element;
  const ref = useRef();

  useScrollIntoView(ref, isActive, [56, 0, 65, 0]);

  return (
    <div
      ref={ref}
      style={{ display: 'flex', position: 'relative' }}
      className={`${styles.wrapper} formPreviewWrapper ${isActive && 'active'} ${isFailedQuestion &&
        'failedQuestion'}`}
      onClick={() => setActiveElement(element)}
    >
      <Row className={styles.row}>
        <Col className={styles.handler}>
          {!isFailedQuestion && <DragHandle />}
          {isFailedQuestion && <DragHandleRed />}
        </Col>
        <Col className={styles.icon}>
          <i style={{ content: `url(${Icon})` }} />
        </Col>
        <Col className={styles.elementWrapper}>
          <ElementPreview element={element} />
        </Col>
        <Can otherConditions={[() => element.DVIR == null]}>
          <Col className={styles.delete} onClick={onDelete}>
            <i style={{ content: `url(${isFailedQuestion ? removeRedIcon : removeIcon})` }} />
          </Col>
        </Can>
      </Row>
      {isActive && (
        <>
          <div className={isFailedQuestion ? styles.linkLineWarning : styles.linkLine}></div>
          <ElementProperties
            relElRef={ref}
            isFailedQuestion={isFailedQuestion}
            element={element}
            setProperty={setProperty}
            setFollowUpProperty={setFollowUpProperty}
            isDraggable={isDraggable}
            isPretripChecklist={isPretripChecklist}
            ElementDynamicFields={ElementDynamicFields}
            setFailedQuestionProperty={setFailedQuestionProperty}
            setActiveElement={setActiveElement}
          />
        </>
      )}
    </div>
  );
};

export const SortableItem = SortableElement(
  ({
    element,
    activeElement,
    removeElement,
    setActiveElement,
    setProperty,
    setFollowUpProperty,
    isDraggable,
    isPretripChecklist,
    ElementDynamicFields = () => null,
    setFailedQuestionProperty
  }) => (
    <FormPreview
      isActive={element === activeElement && element.DVIR == null}
      isDraggable={isDraggable}
      element={element}
      onDelete={() => removeElement(element)}
      setActiveElement={setActiveElement}
      setProperty={(property, value) => setProperty(element, property, value)}
      setFollowUpProperty={(option, property, value) =>
        setFollowUpProperty(element, option, property, value)
      }
      setFailedQuestionProperty={(property, value) =>
        setFailedQuestionProperty(element, property, value)
      }
      isPretripChecklist={isPretripChecklist}
      ElementDynamicFields={ElementDynamicFields}
    />
  )
);

export const SortableFailedItem = SortableElement(
  ({
    element,
    activeElement,
    removeElement,
    setActiveElement,
    setProperty,
    setFollowUpProperty,
    isDraggable,
    isPretripChecklist,
    setFailedQuestionProperty
  }) => (
    <FormPreview
      isActive={element === activeElement}
      isFailedQuestion
      isDraggable={isDraggable}
      element={element}
      onDelete={() => removeElement(element)}
      setActiveElement={setActiveElement}
      setProperty={(property, value) => setProperty(element, property, value, true)}
      setFollowUpProperty={(option, property, value) =>
        setFollowUpProperty(element, option, property, value)
      }
      setFailedQuestionProperty={(property, value) =>
        setFailedQuestionProperty(element, property, value)
      }
      isPretripChecklist={isPretripChecklist}
    />
  )
);

export const SortableWrapper = SortableContainer(({ children }) => <div>{children}</div>);

export const SortableFailedWrapper = SortableContainer(({ children }) => (
  <div className={styles.SortableFailedWrapper}>{children}</div>
));

export default FormPreview;
