import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';

//components
import { Layout, TabsFilters, Table, CalendarSwitch, ExpandAllButton } from 'components/ant';
import { Space, Button } from 'antd';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { DriverManagementCalendarView } from './DriverManagementCalendarView';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
//slices
import {
  useIsSchedulesFetching,
  useDriverManagementDeletedSchedules,
  useIsCalendarView,
  useCalendarDate,
  useFilters
} from 'features/driverManagement';
import {
  updateFilters,
  updateCalendarDate,
  changeCalendarView
} from 'features/driverManagement/reducers';
import { useIsFetching as useIsUsersFetching } from 'features/users/usersSlice';
import { entities, Can, useCan } from 'features/permissions';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';

//hooks
import useDebounce from 'utils/hooks/useDebounce';

//helpers
import { getTabs } from 'utils/tabs';

//constants
import { FilterTypes, getCheckedFilterIds, prepareDataForMultiselect } from 'utils/filters';
import { Paths, Tabs, TabKeys } from './constants';
import {
  filterSchedules,
  prepareDataForTable,
  prepareColumnsForTable,
  prepareDataForInnerTable,
  prepareColumnsForInnerTable,
  getBranchId
} from './helpers';

import styles from './DriverManagement.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const DriverManagement = ({
  schedules,
  users,
  subCompanies,
  branches,
  drivers,
  mgtTypes
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const can = useCan();
  const canViewDriver = can && can({ everyEntity: [entities.USER_VIEW] });
  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const localization = useLocalization();
  const history = useHistory();
  const isSchedulesFetching = useIsSchedulesFetching();
  const isUsersFetching = useIsUsersFetching();
  const [activeTab, setActiveTab] = useState(filterPath);
  const isCalendarView = useIsCalendarView();
  const storedCalendarDate = useCalendarDate();
  const [calendarDate, setCalendarDate] = useState(
    storedCalendarDate ? moment(storedCalendarDate) : moment()
  );
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 300);
  const [filteredSchedules, setFilteredSchedules] = useState(schedules);
  const [groupedData, setGroupedData] = useState([]);
  const [expandAll, setExpandAll] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [allExpandedRowKeys, setAllExpandedRowKeys] = useState([]);
  const uniqueDriverIds = [...new Set(schedules.map(s => s.entityId))];
  const uniqueDrivers = drivers && drivers.filter(d => uniqueDriverIds.includes(d.id));
  const deletedSchedules = useDriverManagementDeletedSchedules(activeTab === TabKeys.deleted);
  const filters = useFilters();

  const filterCompanies =
    subCompanies &&
    prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), filters?.companies);
  const filterBranches =
    branches && prepareDataForMultiselect(branches, t('Common.AllBranches'), filters?.branches);
  const filterDrivers =
    uniqueDrivers &&
    prepareDataForMultiselect(uniqueDrivers, t('Common.AllDrivers'), filters?.drivers);
  const filterMgtTypes =
    mgtTypes && prepareDataForMultiselect(mgtTypes, t('Common.AllTypes'), filters?.types);
  const companyIds = getCheckedFilterIds(filterCompanies);
  const branchIds = getCheckedFilterIds(filterBranches);
  const typeIds = getCheckedFilterIds(filterMgtTypes);
  const driverIds = getCheckedFilterIds(filterDrivers);

  const handleTabChange = tab => {
    setActiveTab(tab);
  };

  const handleSearchTable = text => {
    setSearchText(text);
  };

  const handleFilterChange = type => newFilterValues => {
    const filterIds = getCheckedFilterIds(newFilterValues);
    dispatch(updateFilters({ type, filterIds }));
  };

  useEffect(() => {
    dispatch(setPageTitle(t('DriverManagement.Title')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  useEffect(() => {
    setExpandAll(false);
    const schedulesToFilter = activeTab === TabKeys.deleted ? deletedSchedules : schedules;
    const schedulesWithDetails = schedulesToFilter.map(s => ({
      ...s,
      branchId: getBranchId(s, drivers, branches)
    }));
    setFilteredSchedules(
      filterSchedules(
        schedulesWithDetails,
        {
          activeTab,
          searchText: debouncedSearchText,
          companyIds,
          branchIds,
          driverIds,
          typeIds
        },
        setExpandAll
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText, schedules, deletedSchedules, filters, activeTab, drivers, branches]);

  useEffect(() => {
    const groupedData = filteredSchedules.reduce((acc = [], item) => {
      const { entityId, ...info } = item;
      const index = acc.findIndex(x => x.entityId === entityId);
      if (index === -1) {
        acc.push({
          entityId,
          key: info.id,
          info: [info]
        });
      } else {
        acc[index].info.push(info);
      }
      return acc;
    }, []);
    const allRowKeys = groupedData.map(item => item.key); // keys from the rows, we'll use this later for expand all/collapse all
    setGroupedData(groupedData);
    setAllExpandedRowKeys(allRowKeys); // store all the row keys based on the groupedData
  }, [filteredSchedules]);

  useEffect(() => {
    if (path === Paths.MAIN_PAGE) {
      setActiveTab(TabKeys.all);
    }
  }, [path]);

  const renderItemsTable = record => {
    return (
      <Table
        dataSource={prepareDataForInnerTable({
          schedules: record.info,
          users,
          mgtTypes,
          localization,
          history,
          can,
          t,
          dispatch
        })}
        tableLayout="fixed"
        columns={prepareColumnsForInnerTable(t)}
        pagination={false}
        sticky={true}
      />
    );
  };

  const Header = () => {
    return (
      <Layout.Header>
        <div className={styles.spaceAround}>
          <div className={styles.tabFilters}>
            <TabsFilters tabs={getTabs(Tabs, activeTab, handleTabChange, t)} />
          </div>
          <div className={styles.headerButtons}>
            <Buttons />
          </div>
        </div>
      </Layout.Header>
    );
  };

  const Buttons = () => {
    return (
      <Space size={16}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_CREATE]}>
          <Button
            type="primary"
            size="large"
            id={BUTTON_IDS.driverMgtAdd}
            onClick={() => {
              history.push(Paths.ADD_DRIVERMANAGEMENTSCHEDULE);
            }}
          >
            {t('DriverManagement.AddButton')}
          </Button>
        </Can>
        <Can everyEntity={[entities.DRIVERMANAGEMENTTYPE]}>
          <Button
            size="large"
            id={BUTTON_IDS.driverMgtManageTypes}
            onClick={() => {
              history.push(Paths.DRIVER_MGT_TYPES);
            }}
          >
            {t('DriverManagement.ManageTypesButton')}
          </Button>
        </Can>
      </Space>
    );
  };

  const ExpandButton = ({ expanded, onExpand, record }) => {
    return expanded ? (
      <UpOutlined className={styles.expandButton} onClick={e => onExpand(record, e)} />
    ) : (
      <DownOutlined className={styles.expandButton} onClick={e => onExpand(record, e)} />
    );
  };

  //toggle between expand all/collapse all boolean
  const handleExpandCollapseAll = () => {
    if (!expandAll) {
      setExpandedRowKeys([]);
    }
    setExpandAll(!expandAll);
  };

  const expandAllRenderer = (
    <ExpandAllButton
      handleOnClick={handleExpandCollapseAll}
      expandAll={expandAll}
      customStyle={styles.expandAllButton}
    />
  );

  const columnsRender = prepareColumnsForTable(t, expandAllRenderer);

  const handleExpandedRowsChange = expandedRows => {
    setExpandAll(false); // reset the expand all button
    setExpandedRowKeys(expandedRows);
  };

  const toggleCalendarView = () => {
    dispatch(changeCalendarView());
  };

  const handleCalendarDateChange = value => {
    const date = moment(value).format();
    dispatch(updateCalendarDate(date));
    setCalendarDate(value);
  };

  return (
    <Layout>
      <Header />
      <Layout.Content className={styles.content}>
        <div className={`${styles.filterWrapper} ${styles.spaceAround}`}>
          <Space size={16} className={styles.wrap}>
            <AntSearchbar
              className={styles.searchBar}
              size={'medium'}
              onFilter={handleSearchTable}
            />
            <AntMultiselect
              title={
                !filterCompanies.find(company => !company.checked)
                  ? t('Users.AllCompanies')
                  : t('Common.Companies')
              }
              data={filterCompanies}
              onFilter={handleFilterChange(FilterTypes.companies)}
              className={styles.multiselect}
            />
            <AntMultiselect
              title={
                !filterBranches.find(branch => !branch.checked)
                  ? t('Common.AllBranches')
                  : t('Common.Branches')
              }
              data={filterBranches}
              onFilter={handleFilterChange(FilterTypes.branches)}
              className={styles.multiselect}
            />
            <AntMultiselect
              title={
                !filterDrivers.find(driver => !driver.checked)
                  ? t('Common.AllDrivers')
                  : t('Common.Drivers')
              }
              data={filterDrivers}
              onFilter={handleFilterChange(FilterTypes.drivers)}
              className={styles.multiselect}
            />
            <AntMultiselect
              title={
                !filterMgtTypes.find(type => !type.checked)
                  ? t('Common.AllTypes')
                  : t('Common.Types')
              }
              data={filterMgtTypes}
              onFilter={handleFilterChange(FilterTypes.types)}
              className={styles.multiselect}
            />
          </Space>
          <div className={styles.counter}>{`${filteredSchedules.length} ${
            filteredSchedules.length === 1
              ? t('DriverManagement.Schedule')
              : t('DriverManagement.Schedules')
          }`}</div>
          <div className={styles.switchContainer}>
            <CalendarSwitch
              isCalendarView={isCalendarView}
              toggleCalendarView={toggleCalendarView}
            />
          </div>
        </div>
        {isCalendarView ? (
          <DriverManagementCalendarView
            schedules={filteredSchedules}
            users={users}
            branches={branches}
            mgtTypes={mgtTypes}
            onDateChange={handleCalendarDateChange}
            value={calendarDate}
          />
        ) : (
          <Table
            className={styles.table}
            dataSource={prepareDataForTable(groupedData, drivers, branches, canViewDriver)}
            columns={columnsRender}
            loading={isSchedulesFetching || isUsersFetching}
            pagination={false}
            expandedRowKeys={expandAll ? allExpandedRowKeys : expandedRowKeys}
            expandable={{
              expandedRowRender: renderItemsTable,
              expandIcon: ExpandButton,
              expandRowByClick: true
            }}
            rowClassName={styles.mainTableRowClass}
            onExpandedRowsChange={handleExpandedRowsChange}
            scroll={{ y: 'calc(100vh - 230px)' }}
          />
        )}
      </Layout.Content>
    </Layout>
  );
};
