import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Row, Col, DatePicker, Popover, Button, Spin } from 'antd';
import styles from './DriverLog.module.scss';
import { LogEventModal } from './LogRecord';
import { useCurrentCompany } from 'features/company/companySlice';
import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { DriverLogPageBody } from './DriverLogPageBody';
import moment from 'moment-timezone';
import { MessageComposeModal } from 'containers/Messaging/MessageComposeModal';
import { RECIPIENT_TYPE } from 'containers/Messaging/constants';
import { useEntityCanMessaging } from 'features/messaging/hooks';
import { Can, services, useCanFeatureFlag, FeatureFlag } from 'features/permissions';
import { useDrivers } from 'features/users/usersSlice';
import { useUserKey } from 'features/user/userSlice';
import { PageHeader } from './DriverLogPageHeader';
import { saveEventRecord } from 'features/eld/eventsApi';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { fetchELDRuleset } from 'features/eld/eldSlice';
import { useTranslation } from 'react-i18next';
import { extractSuggestEditError } from 'features/eld/suggestEditErrorCodes';
import { useLocalization } from 'features/localization/localizationSlice';
import { useVehicles } from 'features/fleets/fleetsSlice';
import { fetchDriverEvents } from 'features/eld/driverLogSlice';
import { useUserData } from 'containers/Administration/Users/APICalls';
import { toLower } from 'lodash';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useTimer } from 'utils/hooks/useTimer';

const PointEvents = ['ZoneChange', 'CycleChange', 'OffDutyDeferral'];

function MoreButton({ driverId, ...props }) {
  const history = useHistory();
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();

  const handleViewDriver = useCallback(() => {
    const driverDetailLink = `/settings/users/id/${driverId}`;
    history.push(driverDetailLink);
  }, [driverId, history]);

  const handleMessageDriver = useCallback(() => {
    setShowMoreMenu(false);
    setShowPopup(true);
  }, []);

  const canMessaging = useEntityCanMessaging(RECIPIENT_TYPE.DRIVER, driverId);

  const moreMenu = useMemo(() => {
    return (
      <div className={styles.driverLogMoreMenu}>
        <Row>
          <Col span={24}>
            <Button type="text" onClick={handleViewDriver} id={BUTTON_IDS.driverLogView}>
              {t('ELD.View Driver')}
            </Button>
          </Col>
        </Row>
        <Can oneOfServices={[services.MESSAGING]}>
          {canMessaging === null && (
            <div style={{ textAlign: 'center' }}>
              {' '}
              <Spin />
            </div>
          )}
          {canMessaging && (
            <Row>
              <Col span={24}>
                <Button type="text" id={BUTTON_IDS.driverLogMessage} onClick={handleMessageDriver}>
                  {t('ELD.Message Driver')}
                </Button>
              </Col>
            </Row>
          )}
        </Can>
      </div>
    );
  }, [driverId, canMessaging]);

  const recipient = useMemo(() => {
    return { recipientType: RECIPIENT_TYPE.DRIVER, recipientId: driverId };
  }, [driverId]);

  return (
    <>
      <Popover
        placement="leftBottom"
        trigger="click"
        open={showMoreMenu}
        content={moreMenu}
        overlayClassName={styles.moreButtonPopup}
        onOpenChange={visible => setShowMoreMenu(visible)}
      >
        <Button
          type="text"
          className={styles.moreButton}
          id={BUTTON_IDS.driverLogShowMoreMenu}
          onClick={() => setShowMoreMenu(true)}
        >
          <i className={'tn-i-elipsis'} />
        </Button>
      </Popover>
      {showPopup && (
        <MessageComposeModal
          visible={showPopup}
          showModal={setShowPopup}
          recipients={[recipient]}
        />
      )}
    </>
  );
}

export function DriverLog({ ...props }) {
  const [showRecordModal, setShowRecordModal] = useState(false);
  const company = useCurrentCompany();
  const userKey = useUserKey();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const drivers = useDrivers();
  const vehicles = useVehicles();
  const { t } = useTranslation();
  const locale = useLocalization();
  const includeRulesetPreRelease = useCanFeatureFlag({
    featureFlag: FeatureFlag.includeRulesetPreRelease.flag
  });

  const [rowActions, setRowActions] = useState({});
  const driverDetail = useUserData({ id: id, embedParam: '' })?.userViewData;
  const driver = drivers?.find(d => d.id === parseInt(id));
  const [logDate, setLogDate] = useState(moment());
  const isExempt =
    driver?.rulesets?.filter(
      rs =>
        toLower(rs.ruleset) === 'eld0' ||
        toLower(rs.ruleset) === 'eldcan0' ||
        toLower(rs.ruleset) === 'eld99'
    )?.length > 0;

  const [reloadData, setReloadData] = useState(false);

  const handleSaveRecord = useCallback(
    event => {
      const record = event;
      saveEventRecord(userKey, company?.id, record).then(
        updatedRecord => {
          const message = record.id
            ? t('ELD.Update Record Succeeded')
            : t('ELD.Suggest Record Succeeded');
          dispatch(openToast({ type: ToastType.Success, message: message }));
          setShowRecordModal(false);
          //trigger reload
          dispatch(
            fetchDriverEvents(
              company,
              id,
              moment(logDate)
                .startOf('day')
                .toDate(),
              moment(logDate)
                .endOf('day')
                .toDate(),
              true
            )
          );
        },
        ({ err, resp }) => {
          if (resp.statusCode === 412) {
            const extraMessage = extractSuggestEditError(resp.body, locale, {
              users: drivers,
              vehicles: vehicles
            });
            const message =
              (record.id ? t('ELD.Update Record failed') : t('ELD.Suggest Record failed')) +
              '. ' +
              extraMessage;
            dispatch(openToast({ type: ToastType.Warning, message: message }));
          } else {
            const message =
              (record.id ? t('ELD.Update Record failed') : t('ELD.Suggest Record failed')) +
              '. ' +
              err;
            dispatch(openToast({ type: ToastType.Error, message: message }));
          }
        }
      );
    },
    [company, id, dispatch, locale, drivers, vehicles, logDate]
  );

  const handleCancelEditRecord = useCallback(() => {
    setShowRecordModal(false);
  }, []);

  const handleAddRecord = useCallback(() => {
    setShowRecordModal(true);
  }, []);

  const disabledDate = useCallback(current => {
    const AvailableDateRange = [
      moment()
        .tz(driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
        .startOf('day')
        .subtract(6, 'month'),
      moment()
        .tz(driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
        .endOf('day')
    ];
    let disableDate = false;
    if (current && AvailableDateRange) {
      disableDate = current < AvailableDateRange[0] || current > AvailableDateRange[1];
    }

    return disableDate;
  }, []);

  const handleEventRowMouseEnter = useCallback((row, index, event) => {
    const prefix = PointEvents.includes(row.action) ? 'flag_' : 'event_';
    setRowActions(prevState => {
      return {
        ...prevState,
        [`${prefix}${row.id}`]: 'mouseEnter'
      };
    });
  }, []);

  const handleEventRowMouseLeave = useCallback((row, index, event) => {
    const prefix = PointEvents.includes(row.action) ? 'flag_' : 'event_';
    setRowActions(prevState => {
      return {
        ...prevState,
        [`${prefix}${row.id}`]: 'mouseLeave'
      };
    });
  }, []);

  const handleViolationRowMouseEnter = useCallback((row, index, event) => {
    setRowActions(prevState => {
      return {
        ...prevState,
        ['violation_' + row.rule?.id]: 'mouseEnter'
      };
    });
  }, []);

  const handleViolationRowMouseLeave = useCallback((row, index, event) => {
    setRowActions(prevState => {
      return {
        ...prevState,
        ['violation_' + row.rule?.id]: 'mouseLeave'
      };
    });
  }, []);

  const backOneDay = useCallback(() => {
    setLogDate(prev => moment(prev).subtract(1, 'day'));
  }, []);

  const forwardOneDay = useCallback(() => {
    setLogDate(prev => moment(prev).add(1, 'day'));
  }, []);

  useEffect(() => {
    dispatch(setBackButton(true));
    if (driver != null) {
      dispatch(setPageTitle(driver.firstName + ' ' + driver.lastName));
    }
  }, [dispatch, id, drivers]);

  useEffect(() => {
    if (company && company.api_key) {
      dispatch(fetchELDRuleset(includeRulesetPreRelease));
    }
  }, [dispatch, company]);

  const refreshData = useCallback(() => {
    // dispatch(fetchUnsignedLogs(driver.id));
    // dispatch(fetchSuggestedDriverEvents(driver.id));
    // dispatch(fetchSuggestedUDT(driver.id));
    setReloadData(true);
    setReloadData(false);
  }, [reloadData, driver, logDate]);
  useTimer(60000, refreshData, true);

  if (id === null || id === '') {
    history.goBack();
    return;
  }

  const PrevIcon = <i className="tn-i-caret-left" />;
  const ForwardIcon = <i className="tn-i-caret-right" />;

  return (
    <Row
      style={{
        flexDirection: 'column',
        minHeight: 'calc(100vh - 62px)',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        overflowY: 'scroll'
      }}
    >
      <Row
        style={{
          paddingRight: 20,
          paddingLeft: 3,
          zIndex: '5',
          minHeight: '64px',
          background: '#fff',
          justifyContent: 'flex-end',
          alignItems: 'center',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Col span={24}>
          <Row className={styles.headerbar} gutter={6} justify="end">
            <Col>
              <Can otherConditions={[() => !isExempt]}>
                <Button
                  type="primary"
                  onClick={handleAddRecord}
                  id={BUTTON_IDS.driverLogSuggestRecord}
                >
                  {t('ELD.Suggest Record')}
                </Button>
              </Can>
            </Col>
            <Col className={styles.datePickerContainer}>
              <Button
                onClick={backOneDay}
                icon={PrevIcon}
                id={BUTTON_IDS.driverLogBackDay}
                disabled={disabledDate(
                  moment
                    .tz(
                      logDate,
                      driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                    )
                    .subtract(1, 'day')
                )}
              ></Button>
              <DatePicker
                value={logDate}
                className={styles.datePicker}
                onChange={date => setLogDate(date)}
                disabledDate={disabledDate}
                allowClear={false}
              />
              <Button
                onClick={forwardOneDay}
                icon={ForwardIcon}
                id={BUTTON_IDS.driverLogForwardDay}
                disabled={disabledDate(
                  moment
                    .tz(
                      logDate,
                      driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                    )
                    .add(1, 'day')
                )}
              ></Button>
            </Col>
            <Col>
              <MoreButton driverId={id} />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ height: '100%', overflow: 'scroll' }}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <PageHeader
                driverId={id}
                company={company}
                logDate={logDate}
                rowActions={rowActions}
                refreshData={() => dispatch(refreshData)}
                reloadData={reloadData}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <DriverLogPageBody
                onEventRowMouseEnter={handleEventRowMouseEnter}
                onEventRowMouseLeave={handleEventRowMouseLeave}
                onViolationRowMouseEnter={handleViolationRowMouseEnter}
                onViolationRowMouseLeave={handleViolationRowMouseLeave}
                company={company}
                driverId={id}
                logDate={logDate}
                driverDetail={driverDetail}
                reloadData={reloadData}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {showRecordModal && (
        <LogEventModal
          event={{ source: 'Request', eventAt: logDate }}
          onSave={handleSaveRecord}
          onCancel={handleCancelEditRecord}
          showRecordModal={showRecordModal}
          editMode={false}
          driverId={id}
          title={t('ELD.Suggest Record')}
          driverDetail={driverDetail}
        />
      )}
    </Row>
  );
}
