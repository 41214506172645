import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import { fetchAudits, fetchBulkEditAudits, fetchVehicleDeviceAssociationAudits } from '../thunks';

const initialState = {
  list: [],
  isFetching: false,
  error: null,
  success: null,
  lastFetched: {
    entityName: null,
    entityId: null
  }
};

export const auditsSlice = createSlice({
  name: 'audits',
  initialState,
  extraReducers: {
    [fetchAudits.pending]: state => {
      state.isFetching = true;
      state.success = false;
      state.error = false;
    },
    [fetchAudits.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.success = true;
      state.lastFetched = {
        entityName: action.meta.arg.entityName,
        entityId: action.meta.arg.entityId
      };
      state.list = unwrapResult(action) || [];
    },
    [fetchAudits.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.lastFetched = {
        entityName: action.meta.arg.entityName,
        entityId: action.meta.arg.entityId
      };
    }
  }
});

export const vehicleDeviceAssociationAuditsSlice = createSlice({
  name: 'vehicleDeviceAssociationAudits',
  initialState,
  extraReducers: {
    [fetchVehicleDeviceAssociationAudits.pending]: state => {
      state.isFetching = true;
      state.success = false;
      state.error = false;
    },
    [fetchVehicleDeviceAssociationAudits.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.success = true;
      state.lastFetched = {
        entityName: action.meta.arg.entityName,
        entityId: action.meta.arg.entityId
      };

      const result = unwrapResult(action);

      state.list = formatDataForVehicleDeviceAssociation(
        filterDutyType(result.data, result.entityId)
      );
    },
    [fetchVehicleDeviceAssociationAudits.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.lastFetched = {
        entityName: action.meta.arg.entityName,
        entityId: action.meta.arg.entityId
      };
    }
  }
});

export const bulkEditAuditsSlice = createSlice({
  name: 'bulkEditAudits',
  initialState: {},
  extraReducers: {
    [fetchBulkEditAudits.pending]: (state, action) => {
      const entityName = action.meta.arg.entityName;
      state[entityName] = {};
      state[entityName].isFetching = true;
      state[entityName].success = false;
      state[entityName].error = false;
    },
    [fetchBulkEditAudits.fulfilled]: (state, action) => {
      const entityName = action.meta.arg.entityName;
      state[entityName].isFetching = false;
      state[entityName].success = true;
      state[entityName].error = false;
      state[entityName].list = unwrapResult(action);
    },
    [fetchBulkEditAudits.rejected]: (state, action) => {
      const entityName = action.meta.arg.entityName;
      state[entityName].isFetching = false;
      state[entityName].error = true;
    }
  }
});

const parseDateToMinute = date => {
  const d = new Date(date);
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes());
};

export const formatDataForVehicleDeviceAssociation = data => {
  const lookup = {};
  const result = [];

  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    const minuteKey = parseDateToMinute(item.createdAt).toISOString();
    const key = `${minuteKey}-${item.userId}-${item.action}`;

    if (!lookup[key]) {
      lookup[key] = {
        id: item.id,
        action: item.action,
        user: item.user,
        createdAt: item.createdAt,
        changes: []
      };
      result.push(lookup[key]);
    }

    const convertNullToString = value => {
      if (value === null) {
        return 'null';
      } else if (Array.isArray(value)) {
        return value.map(v => (v === null ? 'null' : v));
      } else if (typeof value === 'object' && value !== null) {
        return Object.fromEntries(
          Object.entries(value).map(([k, v]) => [k, v === null ? 'null' : v])
        );
      } else {
        return value;
      }
    };

    const parameters = JSON.parse(item.parameters);
    for (const [paramKey, value] of Object.entries(parameters)) {
      let changeObj = lookup[key].changes.find(change => change.key === paramKey);
      if (!changeObj) {
        changeObj = {
          key: paramKey,
          title: item.comment,
          changes: []
        };
        lookup[key].changes.push(changeObj);
      }

      changeObj.changes.push({
        association_configuration: {
          [item.entityId]: {
            id: item.entityId,
            companyId: item.companyId
          }
        },
        change: convertNullToString(value)
      });
    }
  }

  return result;
};

export const filterDutyType = (data, entityId) =>
  data.filter(d => d.comment?.includes('dutyType') && d.companyId === parseInt(entityId));
