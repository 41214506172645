import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Typography, Tag, Button } from 'antd';

import { toLowerCaseDashDelimited } from 'utils/strings';
import { getReportCategoryByType, getReportNameByType } from 'containers/Reports/constants';

import styles from './cards.module.scss';

import { ConfigureReportModal } from '../Modals';

const { Meta } = Card;
const { Text } = Typography;

const ReportCard = ({
  report,
  categories,
  image,
  onDelete,
  onViewReportClick,
  showTags = true,
  ...props
}) => {
  const { t } = useTranslation();
  //const [showConfigureReportModal, setShowConfigureReportModal] = useState(false);

  const handleViewReportClick = e => {
    onViewReportClick && onViewReportClick(report);
  };

  const getReportImage = report => {
    const reportType = getReportCategoryByType(report?.type);

    let imgReport = null;

    try {
      imgReport = require(`../Images/reports/${toLowerCaseDashDelimited(reportType)}.svg`);
    } catch (e) {
      imgReport = require('../Images/reports/sample-report.svg').default;
    }

    return imgReport;
  };

  return (
    <Card
      id={report.reportId}
      className={styles.reportCard}
      cover={<img alt={report.Name} src={getReportImage(report)} />}
      {...props}
    >
      <Meta
        title={
          <Text style={{ width: '90%' }} ellipsis={{ tooltip: report.name }}>
            {t(`Reports.${report.name}`)}
          </Text>
        }
        description={t(`Reports.${report.description}`)}
      />
      {showTags && (
        <footer className={styles.footer}>
          {Array.isArray(categories) &&
            categories?.map(category => (
              <Tag
                className={[styles.categories, styles.reportCard]}
                key={category - report.reportId}
              >
                {t(`Insights.Tags.${category}`)}
              </Tag>
            ))}

          <Button className={styles.generate} onClick={handleViewReportClick}>
            {t(`Insights.Criteria`)}
          </Button>
        </footer>
      )}

      {/*<ConfigureReportModal
        isOpen={showConfigureReportModal}
        onClose={() => {
          setShowConfigureReportModal(false);
        }}
      />*/}
    </Card>
  );
};

export default ReportCard;
