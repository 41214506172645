import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { setPageTitle, setBackButton } from 'features/page/pageSlice';

import { Layout } from 'components/ant';
import { TabNavLink } from 'components/nav/NavLinks';

import TabHeader from './TabHeader';
import Tabs from './Tabs';
import { ConfiguredVehiclesTable, ConfiguredFleetsTable } from './Tables';

import {
  useIsFetching,
  useConfiguredVehicles,
  useConfiguredFleets,
  updateConfigurations,
  updateVehicleConfiguration
} from 'features/massManagement/vehicleConfigurationsSlice';

import { ConfigureVehiclesModal, VehicleConfigurationModal } from './Modals';
import { BUTTON_IDS } from 'utils/globalConstants';

const ConfiguredVehicles = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const configuredVehicles = useConfiguredVehicles();
  const configuredFleets = useConfiguredFleets();
  const isLoading = useIsFetching();

  const [data, setData] = useState([]);
  const [editRecord, setEditRecord] = useState(null);
  const [showConfigureVehiclesModal, setShowConfigureVehiclesModal] = useState(false);
  const [showVehicleConfigurationModal, setShowVehicleConfigurationModal] = useState(false);

  useEffect(() => {
    dispatch(setPageTitle(t('Common.MassManagement')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  const onAdd = () => {
    setEditRecord(null);
    setShowConfigureVehiclesModal(true);
  };

  const onAddSave = record => {
    dispatch(updateConfigurations(record));
  };

  const onEdit = record => {
    setEditRecord(record);
    setShowVehicleConfigurationModal(true);
  };

  const onEditSaveVehicleConfiguration = record => {
    dispatch(updateVehicleConfiguration(record));
  };

  const onDelete = record => {
    setData([...data].filter(row => row.key !== record.key));
  };

  return (
    <Layout>
      <TabHeader
        tabs={<Tabs />}
        toolbar={
          <Button
            type="primary"
            size="large"
            onClick={onAdd}
            id={BUTTON_IDS.massManagementConfigure}
          >
            {t('MassManagement.ConfigureVehiclesFleets')}
          </Button>
        }
      />
      <TabHeader
        tabs={
          <>
            <TabNavLink
              to={'/settings/massmanagement/configured/vehicles'}
              isActive={(match, location) => {
                return (
                  match ||
                  location.pathname === '/settings/massmanagement' ||
                  location.pathname === '/settings/massmanagement/configured'
                );
              }}
            >
              {t('Tabs.vehicles')}
            </TabNavLink>
            <TabNavLink to={'/settings/massmanagement/configured/fleets'}>
              {t('Fleets.Fleets')}
            </TabNavLink>
          </>
        }
      />

      <Switch>
        <Route path={'/settings/massmanagement/configured/fleets'}>
          <ConfiguredFleetsTable
            data={configuredFleets}
            onEdit={onEdit}
            onDelete={onDelete}
            isLoading={isLoading}
          />
        </Route>
        <Route
          path={[
            '/settings/massmanagement/configured/vehicles',
            '/settings/massmanagement/configured',
            '/settings/massmanagement'
          ]}
        >
          <ConfiguredVehiclesTable
            data={configuredVehicles}
            onEdit={onEdit}
            onDelete={onDelete}
            isLoading={isLoading}
          />
        </Route>
      </Switch>

      <ConfigureVehiclesModal
        data={editRecord}
        isOpen={showConfigureVehiclesModal}
        onSave={record => {
          onAddSave(record);
        }}
        onClose={() => {
          setShowConfigureVehiclesModal(false);
        }}
      />
      <VehicleConfigurationModal
        data={editRecord}
        isOpen={showVehicleConfigurationModal}
        onSave={onEditSaveVehicleConfiguration}
        onClose={() => {
          setShowVehicleConfigurationModal(false);
        }}
      />
    </Layout>
  );
};

export default ConfiguredVehicles;
