import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import {
  fetchTrips,
  saveTripPurpose,
  fetchTripStates,
  fetchPositions,
  addTripStates,
  deleteTripStates,
  setTripStateDefault
} from './thunks';
import moment from 'moment';
import { sortTrips } from '../../containers/FBTManager/utils';

const initialState = {
  trips: [],
  isFetching: false,
  error: null,
  success: null,
  tripStates: [],
  positions: {},
  meta: {
    purpose: {
      isFetching: false,
      error: null,
      success: false
    },
    tripState: {
      isFetching: false,
      error: null,
      success: false
    },
    positions: {},
    addTripState: {
      isFetching: false,
      error: null,
      success: false
    },
    deleteTripState: {
      isFetching: false,
      error: null,
      success: false
    },
    setTripStateDefault: { isFetching: false, error: null, success: false }
  },
  dates: {
    dateFrom: moment()
      .add(-6, 'days')
      .format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD')
  },
  filters: {
    drivers: null,
    fleets: null,
    vehicles: null,
    typeOfUses: null
  }
};

export const fbtSlice = createSlice({
  name: 'fbt',
  initialState,
  reducers: {
    setDates(state, { payload: { dateFrom, dateTo } }) {
      state.dates = { ...state.dates, dateFrom, dateTo };
    },
    setFilters(state, { payload }) {
      state.filters[payload.type] = payload.filteredIds;
    },
    resetFilters(state) {
      state.filters = {
        drivers: null,
        fleets: null,
        vehicles: null,
        typeOfUses: null
      };
    }
  },
  extraReducers: {
    [fetchTrips.pending]: state => {
      state.isFetching = true;
    },
    [fetchTrips.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.success = true;
      state.trips = sortTrips(unwrapResult(action));
    },
    [fetchTrips.rejected]: state => {
      state.isFetching = false;
      state.error = true;
      state.trips = [];
    },
    [saveTripPurpose.pending]: state => {
      state.meta.purpose.isFetching = true;
    },
    [saveTripPurpose.fulfilled]: state => {
      state.meta.purpose.isFetching = false;
      state.meta.purpose.error = false;
      state.meta.purpose.success = true;
    },
    [saveTripPurpose.rejected]: state => {
      state.meta.purpose.isFetching = false;
      state.meta.purpose.error = true;
      state.meta.purpose.success = false;
    },
    [fetchTripStates.pending]: state => {
      state.meta.tripState.isFetching = true;
      state.meta.tripState.error = false;
      state.meta.tripState.success = false;
    },
    [fetchTripStates.fulfilled]: (state, action) => {
      state.meta.tripState.isFetching = false;
      state.meta.tripState.success = true;
      state.tripStates = unwrapResult(action);
    },
    [fetchTripStates.rejected]: state => {
      state.meta.tripState.isFetching = false;
      state.meta.tripState.error = true;
      state.meta.tripState.success = false;
    },
    [fetchPositions.pending]: (state, action) => {
      state.meta.positions[action.meta.arg.tripId] = {
        isFetching: true,
        error: false,
        success: false
      };
    },
    [fetchPositions.fulfilled]: (state, action) => {
      const unwrappedResult = unwrapResult(action);
      state.meta.positions[unwrappedResult.id] = {
        isFetching: false,
        error: false,
        success: true
      };
      state.positions[unwrappedResult.id] = unwrappedResult.response.GPS?.sort(
        (a, b) => Number(a?.At) - Number(b?.At)
      );
    },
    [fetchPositions.rejected]: (state, action) => {
      state.meta.positions[action.meta.arg.tripId] = {
        isFetching: false,
        error: true,
        success: false
      };
    },
    [addTripStates.pending]: state => {
      state.meta.addTripState.isFetching = true;
      state.meta.addTripState.error = false;
      state.meta.addTripState.success = false;
    },
    [addTripStates.rejected]: state => {
      state.meta.addTripState.isFetching = false;
      state.meta.addTripState.error = true;
      state.meta.addTripState.success = false;
    },
    [addTripStates.fulfilled]: (state, action) => {
      state.tripStates = unwrapResult(action);
      state.meta.addTripState.isFetching = false;
      state.meta.addTripState.error = false;
      state.meta.addTripState.success = true;
    },
    [deleteTripStates.pending]: state => {
      state.meta.addTripState.isFetching = true;
      state.meta.addTripState.error = false;
      state.meta.addTripState.success = false;
    },
    [deleteTripStates.rejected]: state => {
      state.meta.addTripState.isFetching = false;
      state.meta.addTripState.error = true;
      state.meta.addTripState.success = false;
    },
    [deleteTripStates.fulfilled]: (state, action) => {
      state.tripStates = unwrapResult(action);
      state.meta.addTripState.isFetching = false;
      state.meta.addTripState.error = false;
      state.meta.addTripState.success = true;
    },
    [setTripStateDefault.pending]: state => {
      state.meta.setTripStateDefault.isFetching = true;
      state.meta.setTripStateDefault.error = false;
      state.meta.setTripStateDefault.success = false;
    },
    [setTripStateDefault.rejected]: state => {
      state.meta.setTripStateDefault.isFetching = false;
      state.meta.setTripStateDefault.error = true;
      state.meta.setTripStateDefault.success = false;
    },
    [setTripStateDefault.fulfilled]: (state, action) => {
      if (state.tripStates?.defaultTripState) {
        state.tripStates.defaultTripState = unwrapResult(action)?.defaultTripState;
      }
      state.meta.setTripStateDefault.isFetching = false;
      state.meta.setTripStateDefault.error = false;
      state.meta.setTripStateDefault.success = true;
    }
  }
});

// Actions
export const { setDates, setFilters, resetFilters } = fbtSlice.actions;
