import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Table, Radio, Button, Row, Col, Tooltip } from 'antd';
import { SortUtil } from 'components/tables/SortUtil';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import {
  fetchSuggestedDriverEvents,
  useSuggestedDriverEvents,
  fetchSuggestedUDT,
  useSuggestedUDT
} from 'features/eld/eldSlice';
import styles from 'components/tn/grid/grid.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { DutyStatus } from 'containers/ELD/DriverLog/util';
import { format } from 'utils/dates';

export function normalCellRenderer(value, rowData, index) {
  return (
    <div>
      <span>{value}</span>
    </div>
  );
}

const genAntSortColumnConfig = (sortDir, defDir, sortFn, dataKey) => {
  return {
    sortDirections: sortDir,
    defaultSortOrder: defDir,
    sorter: sortFn && ((a, b, sort) => sortFn(a[dataKey], b[dataKey], sort)),
    ellipsis: false
  };
};
const defaultSortConfig = (sortFn, dataKey) =>
  genAntSortColumnConfig(['ascend', 'descend', 'ascend'], 'descend', sortFn, dataKey);

export function SuggestedLogsModal({
  driver,
  handleLogNavigationClick,
  handleUDTLinkClick,
  showModal,
  onSave,
  onCancel,
  ...props
}) {
  const localization = useLocalization();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const driverEvents = useSuggestedDriverEvents();
  const udtEvents = useSuggestedUDT();
  const [acceptedLogs, setAcceptedLogs] = useState([]);
  const [rejectedLogs, setRejectedLogs] = useState([]);

  useEffect(() => {
    if (driver.id) {
      dispatch(fetchSuggestedDriverEvents(driver.id));
      dispatch(fetchSuggestedUDT(driver.id));
    }
  }, [dispatch, driver, onSave]);

  const tableData = useMemo(() => {
    let driverEventRows =
      driverEvents?.map((d, idx) => {
        let row = Object.assign({}, d);
        row.Type = 'driverEvent';
        row.TimeAt = row.TimeAt
          ? moment(new Date(row.TimeAt)).format(localization.formats.time.formats.dby_imsp)
          : '';

        row.SuggestedByName = row?.suggestedBy?.name;
        row.Differences = [];
        return row;
      }) || [];

    let udtRows =
      udtEvents?.map((d, idx) => {
        let row = Object.assign({}, d);
        row.Type = 'udt';
        row.TimeAt = row.startAt
          ? moment(new Date(row.startAt)).format(localization.formats.time.formats.dby_imsp)
          : '';
        row.SuggestedByName = row?.suggestedBy?.name;
        row.Differences = [];

        return row;
      }) || [];

    const result = [...driverEventRows, ...udtRows];
    return result;
  }, [driverEvents, udtEvents]);

  const handleSaveRecord = useCallback(
    closeModal => {
      if (onSave) {
        let driverEventRecords = driverEvents
          ?.filter((d, idx) => {
            let row = Object.assign({}, d);
            if (rejectedLogs.includes(row.id) || acceptedLogs.includes(row.id)) {
              return row;
            }
          })
          .map((d, idx) => {
            let row = Object.assign({}, d);

            if (rejectedLogs.includes(row.id)) {
              return { id: row.id, action: 'reject' };
            }
            if (acceptedLogs.includes(row.id)) {
              return { id: row.id, action: 'approve' };
            }
          });

        let UDTRecords = udtEvents
          ?.filter((d, idx) => {
            let row = Object.assign({}, d);
            if (rejectedLogs.includes(row.id) || acceptedLogs.includes(row.id)) {
              return row;
            }
          })
          .map((d, idx) => {
            let row = Object.assign({}, d);

            if (rejectedLogs.includes(row.id)) {
              return { id: row.id, action: 'reject', comment: row.comments };
            }
            if (acceptedLogs.includes(row.id)) {
              return { id: row.id, action: 'approve', comment: row.comments };
            }
          });

        onSave(driverEventRecords, UDTRecords, closeModal);
      }
    },
    [onSave, rejectedLogs, acceptedLogs]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleLogClick = useCallback(TimeAt => {
    if (handleLogNavigationClick) {
      handleLogNavigationClick(TimeAt);
    }
  }, []);

  const handleUDTClick = useCallback(row => {
    const udtRow = { ...row };
    udtRow.driverName = (driver?.firstName || '') + ' ' + (driver?.lastName || '');
    udtRow.vehicleName = row?.vehicle?.name || 'N/A';
    udtRow.assignedTo = udtRow?.assignedUser?.name || '';
    udtRow.status = 'Pending';
    udtRow.startTime = udtRow?.startAt
      ? format(new Date(udtRow?.startAt), localization.formats.time.formats.dby_imp)
      : 'N/A';
    udtRow.endTime = udtRow?.endAt
      ? format(new Date(udtRow?.endAt), localization.formats.time.formats.dby_imp)
      : 'N/A';
    udtRow.distance = localization.convertDistance(udtRow.distance);
    handleUDTLinkClick(udtRow);
  }, []);

  const handleRejectClick = useCallback(
    (row, evt) => {
      setRejectedLogs([...rejectedLogs, row?.id]);
      if (acceptedLogs.indexOf(row?.id) > -1) {
        setAcceptedLogs(acceptedLogs.filter(a => a !== row?.id));
      }
    },
    [rejectedLogs, acceptedLogs]
  );

  const handleIgnoreClick = useCallback(
    (row, evt) => {
      var rejectIndex = rejectedLogs.indexOf(row?.id);
      if (rejectIndex !== -1) {
        setRejectedLogs(rejectedLogs.filter(a => a !== row?.id));
      }

      var approveIndex = acceptedLogs.indexOf(row?.id);
      if (approveIndex !== -1) {
        setAcceptedLogs(acceptedLogs.filter(a => a !== row?.id));
      }
    },
    [rejectedLogs, acceptedLogs]
  );

  const handleApproveClick = useCallback(
    (row, evt) => {
      setAcceptedLogs([...acceptedLogs, row?.id]);
      if (rejectedLogs.indexOf(row?.id) > -1) {
        setRejectedLogs(rejectedLogs.filter(a => a !== row?.id));
      }
    },
    [rejectedLogs, acceptedLogs]
  );

  function editDriverEventRender({ rowData, ...props }) {
    let differences = [];
    const row = props;
    let allDifferences = [];
    let showAdditionalCount = 0;
    if (row.parent) {
      const timeAtOld = moment(new Date(row.parent.TimeAt)).format(
        localization.formats.time.formats.dby_imsp
      );
      const timeAtNew = moment(new Date(row.TimeAt)).format(
        localization.formats.time.formats.dby_imsp
      );
      if (timeAtOld !== timeAtNew) {
        differences.push(
          t('ELD.DriverPortal.SuggestedLogsColumns.Time') + ': ' + timeAtOld + '  >  ' + timeAtNew
        );
        allDifferences.push(
          t('ELD.DriverPortal.SuggestedLogsColumns.Time') + ': ' + timeAtOld + '  >  ' + timeAtNew
        );
      }
      if (row.parent.action !== row.action) {
        differences.push(
          t('ELD.Duty Status') +
            ': ' +
            (row.parent.action !== undefined ? DutyStatus[row.parent.action] : '') +
            '  >  ' +
            DutyStatus[row.action]
        );
        allDifferences.push(
          t('ELD.Duty Status') +
            ': ' +
            (row.parent.action !== undefined ? DutyStatus[row.parent.action] : '') +
            '  >  ' +
            DutyStatus[row.action]
        );
      }

      if (row.parent.declaredLocation !== row.declaredLocation) {
        showAdditionalCount++;
        allDifferences.push(
          t('Common.Address') +
            ': ' +
            (row.parent.declaredLocation !== undefined ? row.parent.declaredLocation : '') +
            '  >  ' +
            row.declaredLocation
        );
      }
      if (row.parent.shipper !== row.shipper) {
        showAdditionalCount++;
        allDifferences.push(
          t('ELD.Shipper') +
            ': ' +
            (row.parent.shipper !== undefined ? row.parent.shipper : '') +
            '  >  ' +
            row.shipper
        );
      }
      if (row.parent.manifest !== row.manifest) {
        showAdditionalCount++;
        allDifferences.push(
          t('ELD.Manifest') +
            ': ' +
            (row.parent.manifest !== undefined ? row.parent.manifest : '') +
            '  >  ' +
            row.manifest
        );
      }
      if (row.parent.commodity !== row.commodity) {
        showAdditionalCount++;
        allDifferences.push(
          t('ELD.Commodity') +
            ': ' +
            (row.parent.commodity !== undefined ? row.parent.commodity : '') +
            '  >  ' +
            row.commodity
        );
      }
      if (row.parent.vehicle?.name !== row.vehicle?.name) {
        showAdditionalCount++;
        allDifferences.push(
          t('Common.Vehicle') +
            ': ' +
            (row.parent.vehicle?.name !== undefined ? row.parent.vehicle?.name : '') +
            '  >  ' +
            row.vehicle?.name
        );
      }
      if (row.parent.declaredOdometer !== row.declaredOdometer) {
        showAdditionalCount++;
        allDifferences.push(
          t('ELD.Odometer') +
            ': ' +
            (row.parent.declaredOdometer !== undefined ? row.parent.declaredOdometer : '') +
            '  >  ' +
            row.declaredOdometer
        );
      }
      if (row.parent.coDriver?.name !== row.coDriver?.name) {
        showAdditionalCount++;
        allDifferences.push(
          t('ELD.Co-Driver') +
            ': ' +
            (row.parent.coDriver?.name !== undefined ? row.parent.coDriver?.name : '') +
            '  >  ' +
            row.coDriver?.name
        );
      }
      if (row.parent.trailer1Id !== row.trailer1Id) {
        showAdditionalCount++;
        allDifferences.push(
          t('ELD.Trailer 1') +
            ': ' +
            (row.parent.trailer1Id !== undefined ? row.parent.trailer1Id : '') +
            '  >  ' +
            row.trailer1Id
        );
      }
      if (row.parent.trailer2Id !== row.trailer2Id) {
        showAdditionalCount++;
        allDifferences.push(
          t('ELD.Trailer 2') +
            ': ' +
            (row.parent.trailer2Id !== undefined ? row.parent.trailer2Id : '') +
            '  >  ' +
            row.trailer2Id
        );
      }
      if (row.parent.notes !== row.notes) {
        showAdditionalCount++;
        allDifferences.push(
          t('ELD.Comments') +
            ': ' +
            (row.parent.notes !== undefined ? row.parent.notes : '') +
            '  >  ' +
            row.notes
        );
      }
    }

    return (
      <div>
        <Tooltip
          title={allDifferences.map(allDiff => (
            <div>
              <span>{allDiff}</span>
            </div>
          ))}
        >
          {differences.map(diff => (
            <div>
              <span>{diff}</span>
            </div>
          ))}
          {showAdditionalCount > 0 && (
            <div>
              <span>{t('ELD.DriverPortal.MoreChanges', { count: showAdditionalCount })}</span>
            </div>
          )}
        </Tooltip>
      </div>
    );
  }

  function addDriverEventRender({ ...props }) {
    let differences = [];
    const row = props;
    let allDifferences = [];
    let showAdditionalCount = 0;
    if (row.TimeAt) {
      const toTime = moment(new Date(row.TimeAt)).format(
        localization.formats.time.formats.dby_imsp
      );
      differences.push(t('ELD.DriverPortal.SuggestedLogsColumns.Time') + ':  >  ' + toTime);
      allDifferences.push(t('ELD.DriverPortal.SuggestedLogsColumns.Time') + ':  >  ' + toTime);
    }
    if (row.action) {
      differences.push(t('ELD.Duty Status') + ':  >  ' + DutyStatus[row.action]);
      allDifferences.push(t('ELD.Duty Status') + ':  >  ' + DutyStatus[row.action]);
    }

    if (row.declaredLocation) {
      showAdditionalCount++;
      allDifferences.push(t('Common.Address') + ':  >  ' + row.declaredLocation);
    }
    if (row.shipper) {
      showAdditionalCount++;
      allDifferences.push(t('ELD.Shipper') + ':  >  ' + row.shipper);
    }
    if (row.manifest) {
      showAdditionalCount++;
      allDifferences.push(t('ELD.Manifest') + ':  >  ' + row.manifest);
    }
    if (row.commodity) {
      showAdditionalCount++;
      allDifferences.push(t('ELD.Commodity') + ':  >  ' + row.commodity);
    }
    if (row.vehicle?.name) {
      showAdditionalCount++;
      allDifferences.push(t('Common.Vehicle') + ':  >  ' + row.vehicle?.name);
    }
    if (row.declaredOdometer) {
      showAdditionalCount++;
      allDifferences.push(t('ELD.Odometer') + ':  >  ' + row.declaredOdometer);
    }
    if (row.coDriver?.name) {
      showAdditionalCount++;
      allDifferences.push(t('ELD.Co-Driver') + ':  >  ' + row.coDriver?.name);
    }
    if (row.trailer1Id) {
      showAdditionalCount++;
      allDifferences.push(t('ELD.Trailer 1') + ':  >  ' + row.trailer1Id);
    }
    if (row.trailer2Id) {
      showAdditionalCount++;
      allDifferences.push(t('ELD.Trailer 2') + ':  >  ' + row.trailer2Id);
    }
    if (row.notes) {
      showAdditionalCount++;
      allDifferences.push(t('ELD.Comments') + ':  >  ' + row.notes);
    }
    return (
      <div>
        <Tooltip
          title={allDifferences.map(allDiff => (
            <div>
              <span>{allDiff}</span>
            </div>
          ))}
        >
          {differences.map(diff => (
            <div>
              <span>{diff}</span>
            </div>
          ))}
          {showAdditionalCount > 0 && (
            <div>
              <span>{t('ELD.DriverPortal.MoreChanges', { count: showAdditionalCount })}</span>
            </div>
          )}
        </Tooltip>
      </div>
    );
  }

  function udtRender({ ...props }) {
    const row = props;

    const fromTime = moment(new Date(row?.startAt)).format(
      localization.formats.time.formats.dby_imsp
    );

    const toTime = moment(new Date(row?.endAt)).format(localization.formats.time.formats.dby_imsp);
    let details = [];
    details.push(
      t('ELD.DriverPortal.SuggestedLogsColumns.Time') + ': ' + fromTime + '   >   ' + toTime
    );
    details.push(t('Common.Vehicle') + ': ' + row?.vehicle?.name);
    details.push(t('ELD.Distance') + ': ' + localization.convertDistance(row?.distance));
    return (
      <div>
        <Tooltip
          title={details.map(allDiff => (
            <div>
              <span>{allDiff}</span>
            </div>
          ))}
        >
          {details.map(diff => (
            <div>
              <span>{diff}</span>
            </div>
          ))}
        </Tooltip>
      </div>
    );
  }
  const handleApproveAllClick = useCallback(
    evt => {
      if (evt.target.checked) {
        let newArray = [];
        setRejectedLogs([]);
        tableData?.forEach(l => newArray.push(l.id));
        setAcceptedLogs(newArray);
      }
    },
    [rejectedLogs, acceptedLogs]
  );

  const handleRejectAllClick = useCallback(
    evt => {
      if (evt.target.checked) {
        let newArray = [];
        setAcceptedLogs([]);
        tableData?.forEach(l => newArray.push(l.id));
        setRejectedLogs(newArray);
      }
    },
    [rejectedLogs, acceptedLogs]
  );

  const handleIgnoreAllClick = useCallback(
    evt => {
      if (evt.target.checked) {
        setAcceptedLogs([]);
        setRejectedLogs([]);
      }
    },
    [rejectedLogs, acceptedLogs]
  );

  const columns = useMemo(() => {
    let columns = [
      {
        get title() {
          return t('ELD.DriverPortal.SuggestedLogsColumns.Time');
        },
        key: 'TimeAt',
        dataIndex: 'TimeAt',
        render: normalCellRenderer,
        ...defaultSortConfig(SortUtil.sortDate, 'TimeAt'),
        width: 200
      },
      {
        get title() {
          return t('ELD.DriverPortal.SuggestedLogsColumns.SuggestedBy');
        },
        key: 'SuggestedByName',
        dataIndex: 'SuggestedByName',
        render: text => {
          return text;
        },
        width: 175
      },
      {
        get title() {
          return t('ELD.DriverPortal.SuggestedLogsColumns.Suggestion');
        },
        key: 'Differences',
        render: (text, row) => {
          if (row.Type === 'driverEvent') {
            if (row.parent) {
              return editDriverEventRender(row);
            } else {
              return addDriverEventRender(row);
            }
          } else {
            return udtRender(row);
          }
        },
        width: 450
      },
      {
        get title() {
          return t('ELD.DriverPortal.SuggestedLogsColumns.Details');
        },
        key: 'details',
        render: (text, row) => {
          if (row.Type === 'driverEvent') {
            return (
              <div>
                <Link onClick={() => handleLogClick(row?.TimeAt)} to="#">
                  {t('ELD.DriverPortal.SuggestedLogsColumns.ViewLog')}
                </Link>
              </div>
            );
          } else {
            return (
              <div>
                <Link onClick={() => handleUDTClick(row)} to="#">
                  {t('ELD.DriverPortal.SuggestedLogsColumns.ViewUDP')}
                </Link>
              </div>
            );
          }
        },
        width: 95
      },
      {
        get title() {
          return (
            <Radio checked={allApproved} onChange={handleApproveAllClick}>
              {t('ELD.DriverPortal.SuggestedLogsColumns.Accept')}
            </Radio>
          );
        },
        key: 'approveall',
        width: 120,
        render: (text, row) => {
          return (
            <div style={{ cursor: 'pointer' }} onClick={() => {}}>
              <Radio
                checked={acceptedLogs && acceptedLogs.includes(row?.id)}
                onChange={evt => {
                  handleApproveClick(row, evt);
                }}
              ></Radio>
            </div>
          );
        }
      },
      {
        get title() {
          return (
            <Radio checked={allRejected} onChange={handleRejectAllClick}>
              {t('ELD.DriverPortal.SuggestedLogsColumns.Reject')}
            </Radio>
          );
        },
        key: 'rejectall',
        width: 120,
        render: (text, row) => {
          return (
            <div style={{ cursor: 'pointer' }} onClick={() => {}}>
              <Radio
                checked={rejectedLogs && rejectedLogs.includes(row?.id)}
                onChange={evt => {
                  handleRejectClick(row, evt);
                }}
              ></Radio>
            </div>
          );
        }
      },
      {
        get title() {
          return (
            <Radio checked={allIgnored} onChange={handleIgnoreAllClick}>
              {t('ELD.DriverPortal.SuggestedLogsColumns.Ignore')}
            </Radio>
          );
        },
        key: 'ignoreall',
        render: (text, row) => {
          return (
            <div style={{ cursor: 'pointer' }} onClick={() => {}}>
              <Radio
                checked={
                  rejectedLogs &&
                  !rejectedLogs.includes(row?.id) &&
                  acceptedLogs &&
                  !acceptedLogs.includes(row?.id)
                }
                onChange={evt => {
                  handleIgnoreClick(row, evt);
                }}
              ></Radio>
            </div>
          );
        }
      }
    ];

    const allApproved = acceptedLogs?.length === tableData?.length;
    const allRejected = rejectedLogs?.length === tableData?.length;
    const allIgnored = rejectedLogs?.length === 0 && acceptedLogs?.length === 0;

    return columns;
  }, [tableData, localization, acceptedLogs, rejectedLogs]);

  const footer = (
    <Row gutter={10}>
      <Col>
        <Button
          onClick={handleSaveRecord}
          disabled={rejectedLogs.length < 1 && acceptedLogs.length < 1}
          type="primary"
          id={BUTTON_IDS.driverPortalSuggestedLogsSave}
        >
          {t('Common.Save')}
        </Button>
      </Col>
      <Col>
        <Button id={BUTTON_IDS.driverPortalSuggestedLogsSave} type="default" onClick={handleCancel}>
          {t('Common.Cancel')}
        </Button>
      </Col>
    </Row>
  );

  return (
    <Modal
      open={showModal}
      title={t('ELD.DriverPortal.SuggestedLogs')}
      closable={true}
      footer={footer}
      onCancel={handleCancel}
      width={1400}
    >
      <Table
        className={styles.gridShowScrollbars}
        dataSource={tableData}
        columns={columns}
        pagination={false}
        scroll={{ y: 500 }}
        rowKey="id"
      />
    </Modal>
  );
}
