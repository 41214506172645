import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Modal, Form, Radio, Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'components/ant/DateTime/DateRangePicker';
import { useLocalization } from 'features/localization/localizationSlice';
import moment from 'moment';
import { BUTTON_IDS } from 'utils/globalConstants';
import styles from './PrintModal.module.scss';

export function PrintModal({ showModal, isUSELD, isCANELD, onSave, onCancel, ...props }) {
  const { t } = useTranslation();
  const localization = useLocalization();
  const [form] = Form.useForm();
  var [dateRanges, setDateRanges] = useState([moment().subtract(7, 'days'), moment()]);
  var defaultDates = useMemo(() => [dateRanges[0], dateRanges[1]], []);
  const availableDateRange = useMemo(() => {
    return [
      moment()
        .subtract(6, 'month')
        .startOf('day'),
      moment().endOf('day')
    ];
  }, []);
  const [countrySelected, setCountrySelected] = useState(isUSELD ? 'US' : 'Canada');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSaveRecord = useCallback(
    closeModal => {
      setIsSubmitting(true);
      if (onSave) {
        onSave(countrySelected, dateRanges, closeModal);
      }
      setIsSubmitting(false);
    },
    [onSave, form, countrySelected, dateRanges]
  );

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleCountryChange = e => {
    setCountrySelected(e.target.value);
  };

  const footer = (
    <Row gutter={10}>
      <Col>
        <Button
          loading={isSubmitting}
          onClick={handleSaveRecord}
          disabled={dateRanges.length < 2}
          type="primary"
          id={BUTTON_IDS.driverPortalPrintSave}
        >
          {t('ELD.DriverPortal.Print')}
        </Button>
      </Col>
      <Col>
        <Button
          id={BUTTON_IDS.driverPortalPrintCancel}
          disabled={isSubmitting}
          type="default"
          onClick={handleCancel}
        >
          {t('Common.Cancel')}
        </Button>
      </Col>
    </Row>
  );

  return (
    <Modal
      open={showModal}
      title={t('ELD.DriverPortal.Print')}
      footer={footer}
      onCancel={handleCancel}
      okText={t('Common.Export')}
      closable={!isSubmitting}
      className={styles.modalClass}
    >
      <Form form={form}>
        {isUSELD && isCANELD && (
          <>
            <div>{t('ELD.Country')}</div>
            <Radio.Group onChange={handleCountryChange} value={countrySelected}>
              <Radio value={'US'}>{t('Countries.codes.us')}</Radio>
              <Radio value={'Canada'}>{t('Countries.codes.ca')}</Radio>
            </Radio.Group>
          </>
        )}

        <div>{t('ELD.Date Range')}</div>
        <DateRangePicker
          disabled={false}
          value={dateRanges}
          maxDayRange={30}
          defaultDates={defaultDates}
          format={localization.formats.time.formats.dby.toUpperCase()}
          availableDatesRange={availableDateRange}
          onDateRangeChanged={ranges => setDateRanges(ranges)}
        />
      </Form>
    </Modal>
  );
}
