import React, { useEffect } from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

import { getEntries, getTableValues, tagRender } from './helpers';
import styles from './MultipleSelectTable.module.scss';
import { useState } from 'react';

const MultipleSelectTable = ({
  form,
  selected,
  options,
  properties,
  isLoading = false,
  disabled = false,
  onChange,
  setIsDirty,
  id,
  ...props
}) => {
  const { name, label, mode, placeholder, labelCol, wrapperCol, columnHeaderLabels } = properties;
  const [checkboxStatus, setCheckboxStatus] = useState(false);
  const { setFieldsValue } = form;

  const handleCheckboxChange = event => {
    setCheckboxStatus(event.target.checked);
    if (event.target.checked === true) {
      setFieldsValue({
        [name]: options.map(option => +option?.id)
      });
      handleOnChange(options.map(option => +option?.id));
    } else {
      setFieldsValue({
        [name]: []
      });
      handleOnChange([]);
    }
  };

  const values = getTableValues(options, columnHeaderLabels, selected, {
    checkboxStatus: checkboxStatus,
    handleCheckboxChange
  });

  useEffect(() => {
    if (selected?.length === values?.length - 1) {
      setCheckboxStatus(true);
    } else {
      setCheckboxStatus(false);
    }
  }, [selected, values]);

  const handleOnChange = val => {
    if (typeof setIsDirty === 'function') {
      setIsDirty(true);
    }

    if (val.length === values.length - 1) {
      setCheckboxStatus(true);
    } else {
      setCheckboxStatus(false);
    }
    onChange(val);
  };

  const handleSelectChange = value => {
    if (value.includes('all')) {
      values.forEach(val => handleOnChange(val));
    } else {
      handleOnChange(value);
    }
  };

  return (
    <Form.Item
      id={id}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name={name}
      label={label}
      style={{ marginBottom: '0' }}
    >
      <Select
        allowClear
        className={styles.selectContainer}
        disabled={isLoading || disabled}
        filterOption={(input, option) => getEntries(input, option)}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        loading={isLoading}
        maxTagCount={10}
        menuItemSelectedIcon={<></>}
        mode={mode}
        onChange={handleSelectChange}
        placeholder={placeholder}
        tagRender={tagRender}
        {...props}
      >
        {isLoading ? [] : <>{values}</>}
      </Select>
    </Form.Item>
  );
};

MultipleSelectTable.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      firstColumnValue: PropTypes.string,
      secondColumnValue: PropTypes.string,
      thirdColumnValue: PropTypes.string
    })
  ).isRequired,
  properties: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    mode: PropTypes.string,
    placeholder: PropTypes.string,
    labelCol: PropTypes.shape({
      offset: PropTypes.number,
      span: PropTypes.number
    }),
    wrapperCol: PropTypes.shape({
      offset: PropTypes.number,
      span: PropTypes.number
    }),
    columnHeaderLabels: PropTypes.shape({
      firstColumnValue: PropTypes.string,
      secondColumnValue: PropTypes.string,
      thirdColumnValue: PropTypes.string
    }).isRequired
  }).isRequired,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default MultipleSelectTable;
