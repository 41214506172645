import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import request from 'superagent';
import { Button } from 'antd';

import arrayMove from 'array-move';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import FormElement from 'components/form-builder/form-element/Element';
import { SortableItem, SortableWrapper } from 'components/form-builder/form-preview/Preview';
import FormInput from 'components/form/form-input/FormInput';
import FormRadio from 'components/form/form-radio/FormRadio';
import FormSelect from 'components/form/form-select/FormSelect';
import SearchableListMultiSelect from 'components/form/searchable-list-multi-select/SearchableListMultiSelect';
import { ToastType } from 'components/notifications/toasts/Toast';
import { API_PATH } from 'config';
import { getFleetsByCompany } from 'containers/Administration/Fleets/APICalls';
import { useCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import { fetchForms, useForms, useIsFetched } from 'features/forms/formsSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useUserKey } from 'features/user/userSlice';
import { Form, Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import barcodeIcon from 'static/images/icons/formBuilder/barcode.svg';
import dateIcon from 'static/images/icons/formBuilder/date.svg';
import descriptionIcon from 'static/images/icons/formBuilder/description.svg';
import numericIcon from 'static/images/icons/formBuilder/numeric.svg';
import okIcon from 'static/images/icons/formBuilder/ok.svg';
import photoIcon from 'static/images/icons/formBuilder/photo.svg';
import sectionIcon from 'static/images/icons/formBuilder/section.svg';
import selectionIcon from 'static/images/icons/formBuilder/selection.svg';
import signatureIcon from 'static/images/icons/formBuilder/signature.svg';
import textIcon from 'static/images/icons/formBuilder/text.svg';
import timeIcon from 'static/images/icons/formBuilder/time.svg';
import yesnoIcon from 'static/images/icons/formBuilder/yesno.svg';
import { EMPTY_ARRAY, initialValues, validationSchema, ROUTE_PATHS } from './constants';
import { canHistoryGoBack } from 'utils/methods';
import { parseErrorMessage } from 'utils/strings';
import { MPTrackingEvents, useFormUpdatedFields } from 'features/mixpanel';

import styles from './Forms.module.scss';
import './Forms.scss';
import { elementIsVisibleInViewport } from './helpers';
import { cloneDeep } from 'lodash';
import { BUTTON_IDS } from 'utils/globalConstants';

const FormsForm = ({ action }) => {
  const companies = useCompanies();
  const currentCompany = useCurrentCompany();
  const history = useHistory();
  const { id } = useParams();
  let globalSetFieldValue;
  const [initialLoad, setInitalLoad] = useState(true);
  useEffect(() => {
    if (!initialLoad) {
      onCompanyChange(currentCompany.id, globalSetFieldValue);
      setInitalLoad(false);
    }
  }, [currentCompany]);

  const userKey = useUserKey();
  const { t } = useTranslation();

  const allFleets = useFleets();
  const allForms = useForms();
  const isFetched = useIsFetched();
  const [fleets, setFleets] = useState([]);
  const [userCheckedFleets, setUserCheckedFleets] = useState([]);
  const dispatch = useDispatch();
  const [elements, setElements] = useState([]);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  const [fleetsLoaded, setFleetsLoaded] = useState(true);
  const [isFirstFormContainerVisible, setIsFirstFormContainerVisible] = useState(true);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  const firstFormContainer = document.querySelector(`#firstFormContainer`);
  const formPreviewContainer = document.querySelector(`.${styles.formPreview}`);
  const formPreviewContainerHeight = formPreviewContainer?.offsetHeight;

  const checkFirstFormContainerVisibility = () => {
    if (firstFormContainer) {
      setIsFirstFormContainerVisible(elementIsVisibleInViewport(firstFormContainer, true));
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', checkFirstFormContainerVisibility);
    return () => {
      window.removeEventListener('wheel', checkFirstFormContainerVisibility);
    };
  });

  const {
    formikInitialValues,
    dataToEdit,
    originElements,
    isInvalidRequestId,
    handleError
  } = useMemo(() => {
    const formData = allForms?.find(form => form.Id === Number(id));
    const isInvalidRequestId =
      (action === 'edit' || action === 'copy') && isFetched && (isNaN(id) || id <= 0 || !formData);
    let formikInitialValues = {
        ...initialValues,
        CompanyId: currentCompany.id
      },
      dataToEdit = {},
      originElements = [];

    if ((action === 'edit' || action === 'copy') && formData) {
      dataToEdit = cloneDeep(formData);
      const { Elements, ...restFormData } = cloneDeep(formData);
      originElements = Elements.map(element => {
        switch (element.Type) {
          case 'Text':
            element.Icon = textIcon;
            if (!element.AllowBlank) {
              element.AllowBlank = false;
            }
            break;
          case 'Numeric':
            element.Icon = numericIcon;
            if (!element.AllowBlank) {
              element.AllowBlank = false;
            }
            break;
          case 'Description':
            element.Icon = descriptionIcon;
            break;
          case 'Selection':
            element.Icon = selectionIcon;
            break;
          case 'Yes/No':
            element.Icon = yesnoIcon;
            if (!element.AllowBlank) {
              element.AllowBlank = false;
            }
            if (!element.AllowNonApplicable) {
              element.AllowNonApplicable = false;
            }
            break;
          case 'Ok/Not Ok':
            element.Icon = okIcon;
            if (!element.AllowBlank) {
              element.AllowBlank = false;
            }
            if (!element.AllowNonApplicable) {
              element.AllowNonApplicable = false;
            }
            break;
          case 'Date':
            element.Icon = dateIcon;
            if (!element.AllowBlank) {
              element.AllowBlank = false;
            }
            break;
          case 'Date Time':
            element.Icon = timeIcon;
            if (!element.AllowBlank) {
              element.AllowBlank = false;
            }
            break;
          case 'Signature':
            element.Icon = signatureIcon;
            if (!element.AllowBlank) {
              element.AllowBlank = false;
            }
            break;
          case 'Photo':
            element.Icon = photoIcon;
            if (!element.AllowBlank) {
              element.AllowBlank = false;
            }
            break;
          case 'Barcode':
            element.Icon = barcodeIcon;
            if (!element.AllowBlank) {
              element.AllowBlank = false;
            }
            break;
          default:
            element.Icon = sectionIcon;
            if (!element.AllowBlank) {
              element.AllowBlank = false;
            }
            break;
        }
        return element;
      });

      formikInitialValues = {
        ...initialValues,
        ...restFormData,
        CompanyId: action === 'copy' ? currentCompany.id : formData.CompanyId,
        AlertOnCompletion: restFormData.AlertOnCompletion ? ['on'] : 'on',
        Name: `${action === 'copy' ? `${t('Common.Copy of')} ` : ''}${formData.Name}`
      };
    }
    return {
      formikInitialValues,
      dataToEdit,
      originElements,
      isInvalidRequestId,
      handleError(err) {
        if (err) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: err
            })
          );
        }
        if (history.location.pathname !== ROUTE_PATHS.main) {
          history.replace(ROUTE_PATHS.main);
        }
      }
    };
  }, [id, currentCompany, action, allForms, isFetched, history]);

  useEffect(() => {
    if (isInvalidRequestId) {
      handleError(t('Common.Invalid Request ID'));
    }
  }, [t, handleError, isInvalidRequestId]);

  useEffect(() => {
    setElements(originElements);
  }, [originElements.length]);

  useEffect(() => {
    if (action === 'edit') {
      dispatch(
        setPageTitle(
          formikInitialValues.Name &&
            `${t('Forms.ActualForm.EditTitle')} ${formikInitialValues.Name}`
        )
      );
    } else if (action === 'copy') {
      const parsedId = parseInt(id);
      const form = allForms.find(t => t.id === parsedId);

      dispatch(setPageTitle(`${t('Forms.ActualForm.CopyTitle')} ${form?.name}`));
    } else {
      dispatch(setPageTitle(`${t('Forms.ActualForm.AddNewFormTitle')}`));
    }
  }, [dispatch, action, formikInitialValues, id, allForms]);

  useEffect(() => {
    let formFleets = [...allFleets]
      .filter(fleet => fleet.id)
      .map(fleet => {
        const companyFleet = companies.find(company => company.id === fleet.company?.id);
        return {
          value: fleet.id,
          label: fleet?.name ? `${fleet.name} (${companyFleet?.name || ''})` : '',
          checked: false
        };
      });

    formFleets.push({
      value: -1,
      label: t('Common.NoFleet'),
      checked: false
    });

    if ((action === 'edit' || action === 'copy') && dataToEdit && dataToEdit.Associations) {
      formFleets = formFleets.map(fleet => {
        if (dataToEdit.Associations.find(association => association.fleetId === fleet.value)) {
          fleet.checked = true;
        }

        return fleet;
      });
    }
    setFleets(formFleets);
  }, [allFleets, dataToEdit.Id, action, t]);

  const [activeElement, setActiveElement] = useState();
  const emptyElement = {
    Type: '',
    Icon: '',
    Text: ''
  };
  const [isDraggable, setisDraggable] = useState(false);
  let allElementsAreValid = true;

  const checkIfElementsAreValid = () => {
    allElementsAreValid = true;
    let validationMessage = '';
    elements.forEach(element => {
      if (element.Text === '') {
        allElementsAreValid = false;
        setActiveElement(element);
        validationMessage = `${t('Forms.Notifications.TitleElement')}`;
        setElements(
          elements.map(el => {
            if (el === element) {
              el.Invalid = true;
            }
            return el;
          })
        );
        return;
      }
      if (element.Type === 'Description' && !element.Content) {
        if (element.DynamicContent?.source) {
          return;
        }
        allElementsAreValid = false;
        setActiveElement(element);
        validationMessage = `${t('Forms.Notifications.ContentElement')}`;
        setElements(
          elements.map(el => {
            if (el === element) {
              el.Invalid = true;
            }
            return el;
          })
        );
        return;
      }
      if (element.Options) {
        element.Options.forEach(option => {
          if (option.Value === '') {
            allElementsAreValid = false;
            setActiveElement(element);
            validationMessage = `${t('Forms.Notifications.SelectionValue')}`;
            setElements(
              elements.map(el => {
                if (el === element) {
                  el.Options = element.Options.map(opt => {
                    if (opt === option) {
                      opt.Invalid = true;
                    }
                    return opt;
                  });
                }
                return el;
              })
            );
            return;
          }
          if (option.FollowUp && option.FollowUp[0]) {
            const followUp = option.FollowUp[0];
            if (followUp.Text === '') {
              allElementsAreValid = false;
              setActiveElement(element);
              validationMessage = `${t('Forms.Notifications.FollowUpTitle')}`;
              setElements(
                elements.map(el => {
                  if (el === element) {
                    el.Options = element.Options.map(opt => {
                      if (opt === option) {
                        opt.FollowUp[0].Invalid = true;
                      }
                      return opt;
                    });
                  }
                  return el;
                })
              );
              return;
            }
            if (followUp.Options) {
              followUp.Options.forEach(opt => {
                if (opt.Value === '') {
                  allElementsAreValid = false;
                  setActiveElement(element);
                  validationMessage = `${t('Forms.Notifications.FollowUpOption')}`;
                  setElements(
                    elements.map(el => {
                      if (el === element) {
                        el.Options = element.Options.map(op => {
                          if (op === option) {
                            op.FollowUp[0].Options = op.FollowUp[0].Options.map(followUpOption => {
                              if (followUpOption === opt) {
                                followUpOption.Invalid = true;
                              }
                              return followUpOption;
                            });
                          }
                          return op;
                        });
                      }
                      return el;
                    })
                  );
                  return;
                }
              });
            }
          }
        });
      }
    });
    if (!allElementsAreValid) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: validationMessage,
          autohide: true
        })
      );
      return;
    }
  };
  const addElement = (Type, Icon) => {
    checkIfElementsAreValid();
    setTimeout(() => checkFirstFormContainerVisibility(), 400);
    if (!allElementsAreValid) {
      return;
    }
    const newElement = { ...emptyElement, Type, Icon };
    switch (Type) {
      case 'Yes/No':
        newElement.Options = [{ Value: 'Yes' }, { Value: 'No' }];
        newElement.AllowBlank = true;
        newElement.AllowNonApplicable = false;
        break;
      case 'Ok/Not Ok':
        newElement.Options = [{ Value: 'Ok' }, { Value: 'Not Ok' }];
        newElement.AllowBlank = true;
        newElement.AllowNonApplicable = false;
        break;
      case 'Section':
      case 'Description':
      case 'Selection':
        break;
      default:
        newElement.AllowBlank = true;
        break;
    }
    setElements([...elements, newElement]);
    setActiveElement(newElement);
  };

  const removeElement = element => {
    setElements(elements.filter(el => el !== element));
  };

  const setProperty = (element, property, value) => {
    setElements(
      elements.map(el => {
        delete el.Invalid;
        if (el.Options) {
          el.Options = el.Options.map(opt => {
            delete opt.Invalid;
            return opt;
          });
        }
        if (el === element) {
          el[property] = value;
        }
        return el;
      })
    );
  };
  const setFollowUpProperty = (element, option, property, value) => {
    setElements(
      elements.map(el => {
        delete el.Invalid;
        if (el === element) {
          const updatedOptions = element.Options.map(opt => {
            delete opt.Invalid;
            if (opt.FollowUp && opt.FollowUp[0]) {
              delete opt.FollowUp[0].Invalid;
              if (opt.FollowUp[0].Options) {
                opt.FollowUp[0].Options = opt.FollowUp[0].Options.map(op => {
                  delete op.Invalid;
                  return op;
                });
              }
            }
            if (opt === option) {
              switch (property) {
                case 'remove':
                  delete opt.FollowUp;
                  break;
                case 'init':
                  opt.FollowUp = [
                    {
                      Text: '',
                      Type: 'Text',
                      AllowBlank: false
                    }
                  ];
                  break;
                case 'Type':
                  delete opt.FollowUp[0].Options;
                  delete opt.FollowUp[0].AllowNonApplicable;
                  delete opt.FollowUp[0].AllowBlank;
                  switch (value) {
                    case 'Yes/No':
                      opt.FollowUp[0].AllowBlank = false;
                      opt.FollowUp[0].AllowNonApplicable = false;
                      opt.FollowUp[0].Options = [{ Value: 'Yes' }, { Value: 'No' }];
                      break;
                    case 'Ok/Not Ok':
                      opt.FollowUp[0].AllowBlank = false;
                      opt.FollowUp[0].AllowNonApplicable = false;
                      opt.FollowUp[0].Options = [{ Value: 'Ok' }, { Value: 'Not Ok' }];
                      break;
                    default:
                      opt.FollowUp[0].AllowBlank = false;
                      break;
                  }
                  opt.FollowUp[0][property] = value;
                  break;
                default:
                  opt.FollowUp[0][property] = value;
              }
            }
            return opt;
          });
          el.Options = updatedOptions;
        }
        return el;
      })
    );
  };

  const [formValue, setFormValue] = useState({});
  useEffect(() => {
    setFormValue({
      ...formikInitialValues,
      CompanyId: currentCompany.id
    });
  }, [currentCompany.id]);

  useFormUpdatedFields(formValue, {
    eventName: MPTrackingEvents.Settings.Forms.Update,
    eventProps: { typeOfUpdate: action },
    trackFields: MPTrackingEvents.Settings.Forms.TrackFormFields,
    eventPropsOnSubmitFunc: MPTrackingEvents.Settings.Forms.OnSubmitTrackEventPropsFunc
  });

  const onSubmit = (values, actions) => {
    const { setSubmitting, resetForm } = actions;
    checkIfElementsAreValid();
    if (!allElementsAreValid) {
      setSubmitting(false);
      return;
    }
    if (elements.length === 0) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t('Forms.Notifications.OneElement')}`
        })
      );
      setSubmitting(false);
      return;
    }
    const processedElements = elements.map(element => {
      const newElement = { ...element };
      delete newElement.Icon;
      if (element?.DynamicContent && element.DynamicContent.source === undefined) {
        delete newElement.DynamicContent;
      }
      return newElement;
    });
    const processedValues = JSON.parse(JSON.stringify(values));
    delete processedValues.CreatedAt;
    delete processedValues.UpdatedAt;
    delete processedValues.Version;
    delete processedValues.Associations;
    delete processedValues.Fleets;
    action === 'copy' && delete processedValues.Id;
    const Fleets = userCheckedFleets
      .filter(fleet => fleet.checked)
      .map(fleet => ({ id: fleet.value }));
    const submitValues = {
      RecordType: 'FORM',
      Form: {
        ...processedValues,
        Elements: processedElements,
        AlertOnCompletion: processedValues.AlertOnCompletion[0] === 'on'
      },
      Fleets
    };
    let url = `${API_PATH}/forms?company_id=${values.CompanyId}`;
    let method = 'POST';
    let successMessage = i18next.t('Forms.Notifications.AddedNotification', {
      name: processedValues.Name
    });
    if (action === 'edit') {
      const formId = dataToEdit.Id;
      url = `${API_PATH}/forms/${formId}?company_id=${values.CompanyId}`;
      method = 'PUT';
      successMessage = i18next.t('Forms.Notifications.UpdateNotification', {
        name: processedValues.Name
      });
    }

    const identicalNameForm = allForms.find(form => form.Name === submitValues.Form.Name);
    if (
      (action !== 'edit' && identicalNameForm) ||
      (action === 'edit' && identicalNameForm && identicalNameForm.Name !== dataToEdit.Name)
    ) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('Forms.Notifications.IdenticalName', {
            name: identicalNameForm.Name
          }),
          autohide: true
        })
      );
      actions.setSubmitting(false);
      return;
    }
    setFormValue({
      ...submitValues.Form,
      Associations: submitValues.Fleets.map(fleet => ({ fleetId: fleet.id })),
      submit: true
    });
    // submitValues.Form.Elements[0].Options[1].FollowUp[0].Type = 'OkNotOk';
    request(method, url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(submitValues)
      .then(res => {
        setPromptModalWhenLeaving(false);
        dispatch(
          openToast({
            type: ToastType.Success,
            message: successMessage
          })
        );
        dispatch(fetchForms());
        canHistoryGoBack(history, ROUTE_PATHS.main);
        setSubmitting(false);
        resetForm();
      })
      .catch(err => {
        actions.setSubmitting(false);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(err)
          })
        );
      });
  };
  const onCompanyChange = (company, setFieldValue) => {
    setFleetsLoaded(false);
    setFieldValue('CompanyId', Number(company));

    if (!company) {
      return;
    }

    getFleetsByCompany(company, userKey).then(newFleets => {
      let formFleets = [...newFleets]
        .filter(fleet => fleet.id)
        .map(fleet => {
          const companyFleet = companies.find(company => company.id === fleet.company?.id);
          return {
            value: fleet.id,
            label: fleet?.name ? `${fleet.name} (${companyFleet?.name || ''})` : '',
            checked: false
          };
        });
      formFleets.push({
        value: -1,
        label: t('Common.NoFleet'),
        checked: false
      });
      if ((action === 'edit' || action === 'copy') && dataToEdit?.Associations) {
        formFleets = formFleets.map(fleet => {
          if (dataToEdit.Associations.find(association => association.fleetId === fleet.value)) {
            fleet.checked = true;
          }
          return fleet;
        });
      }
      setFleets(formFleets);
      setFleetsLoaded(true);
    });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...formikInitialValues }}
        validationSchema={validationSchema()}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, dirty, setFieldValue }) => {
          globalSetFieldValue = setFieldValue;
          return (
            <>
              <EditRouteGuard when={dirty && promptModalWhenLeaving} navigate={history.push} />
              <Form id="FormsForm">
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '100%' }}>
                    <div className={styles.formContainer} id="firstFormContainer">
                      <Row>
                        <Col>
                          <Row>
                            <FormInput
                              name="Name"
                              label={t('Forms.ActualForm.Name')}
                              placeholder={t('Forms.ActualForm.NamePlaceholder')}
                              isRequired
                            />
                          </Row>
                          <Row>
                            <FormSelect
                              name="CompanyId"
                              label={t('Forms.ActualForm.Company')}
                              placeholder=""
                              values={companies.map(company => ({
                                label: company.name,
                                value: company.id
                              }))}
                              onChange={id => onCompanyChange(id, setFieldValue)}
                              isRequired
                            />
                          </Row>
                          <Row>
                            <FormRadio
                              type="checkbox"
                              name="AlertOnCompletion"
                              values={[
                                { value: 'on', label: `${t('Forms.ActualForm.AlertOnCompletion')}` }
                              ]}
                            />
                          </Row>
                        </Col>
                        <Col>
                          <SearchableListMultiSelect
                            name="Fleets"
                            label={t('Forms.ActualForm.AssignFormTo')}
                            placeholder={t('Forms.ActualForm.SearchForFleetPlaceholder')}
                            allLabel={t('Common.AllFleets')}
                            values={fleets}
                            initialValues={EMPTY_ARRAY}
                            setFieldValue={setUserCheckedFleets}
                            hideSelectAll
                            height={250}
                            isLoading={!fleetsLoaded}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div
                      className={styles.formContainer}
                      style={{
                        maxWidth: '732px',
                        height: `${
                          activeElement
                            ? `${formPreviewContainerHeight + 200}px`
                            : `${formPreviewContainerHeight + 98}px`
                        }`,
                        minHeight: 'calc(100dvh - 276px)'
                      }}
                    >
                      <Row>
                        <Col
                          className={styles.formElements}
                          style={{
                            position: isFirstFormContainerVisible ? 'static' : 'fixed',
                            top: isFirstFormContainerVisible ? '0' : '36px'
                          }}
                        >
                          <div className={styles.formBuilderHeader}>
                            {t('Forms.ActualForm.FormElements')}
                          </div>
                          <FormElement
                            icon={sectionIcon}
                            label={t('Forms.ActualForm.Section')}
                            onAction={() => {
                              addElement('Section', sectionIcon);
                            }}
                          />
                          <FormElement
                            icon={textIcon}
                            label={t('Forms.ActualForm.Text')}
                            onAction={() => {
                              addElement('Text', textIcon);
                            }}
                          />
                          <FormElement
                            icon={numericIcon}
                            label={t('Forms.ActualForm.Numeric')}
                            onAction={() => {
                              addElement('Numeric', numericIcon);
                            }}
                          />
                          <FormElement
                            icon={descriptionIcon}
                            label={t('Forms.ActualForm.Description')}
                            onAction={() => {
                              addElement('Description', descriptionIcon);
                            }}
                          />
                          <FormElement
                            icon={selectionIcon}
                            label={t('Forms.ActualForm.Selection')}
                            onAction={() => {
                              addElement('Selection', selectionIcon);
                            }}
                          />
                          <FormElement
                            icon={yesnoIcon}
                            label={t('Forms.ActualForm.YesNo')}
                            onAction={() => {
                              addElement('Yes/No', yesnoIcon);
                            }}
                          />
                          <FormElement
                            icon={okIcon}
                            label={t('Forms.ActualForm.OkNotOk')}
                            onAction={() => {
                              addElement('Ok/Not Ok', okIcon);
                            }}
                          />
                          <FormElement
                            icon={dateIcon}
                            label={t('Forms.ActualForm.Date')}
                            onAction={() => {
                              addElement('Date', dateIcon);
                            }}
                          />
                          <FormElement
                            icon={timeIcon}
                            label={t('Forms.ActualForm.DateAndTime')}
                            onAction={() => {
                              addElement('Date Time', timeIcon);
                            }}
                          />
                          <FormElement
                            icon={signatureIcon}
                            label={t('Forms.ActualForm.Signature')}
                            onAction={() => {
                              addElement('Signature', signatureIcon);
                            }}
                          />
                          <FormElement
                            icon={photoIcon}
                            label={t('Forms.ActualForm.Photo')}
                            onAction={() => {
                              addElement('Photo', photoIcon);
                            }}
                          />
                          <FormElement
                            icon={barcodeIcon}
                            label={t('Forms.ActualForm.Barcode')}
                            onAction={() => {
                              addElement('Barcode', barcodeIcon);
                            }}
                          />
                        </Col>
                        <Col
                          className={styles.formPreview}
                          style={{ paddingRight: '16px', maxWidth: '433px' }}
                        >
                          <div className={styles.formBuilderHeader}>
                            {t('Forms.ActualForm.FormPreview')}
                          </div>
                          <SortableWrapper
                            updateBeforeSortStart={() => setisDraggable(true)}
                            onSortEnd={({ oldIndex, newIndex }) => {
                              setElements(arrayMove(elements, oldIndex, newIndex));
                              setisDraggable(false);
                            }}
                            useDragHandle
                          >
                            {elements.map((element, index) => (
                              <SortableItem
                                key={`${element.type}-${index}`}
                                index={index}
                                element={element}
                                activeElement={activeElement}
                                isDraggable={isDraggable}
                                removeElement={removeElement}
                                setActiveElement={setActiveElement}
                                setProperty={setProperty}
                                setFollowUpProperty={setFollowUpProperty}
                              />
                            ))}
                          </SortableWrapper>
                          <div className="emptyPlaceholder" style={{ display: 'flex' }}>
                            {t('Forms.ActualForm.AddElementHere')}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className={styles.formFooter}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    id={BUTTON_IDS.formsFormSave}
                    disabled={!isValid || isSubmitting}
                  >
                    {t('Common.SaveButton')}
                  </Button>
                  <Button id={BUTTON_IDS.formsFormCancel} onClick={history.goBack}>
                    {t('Common.CancelButton')}
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default FormsForm;
