import { Button, Modal } from 'antd';
import { EllipsisMenu } from 'components/tables/EllipsisMenu';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AttachmentStatus, ErrorMessageTypes, EventStatusToRequestStatus } from '../constant';
import styles from './MenuItem.module.scss';
import { useIQCameraUser, useCan } from 'features/permissions';
import { useDevices, useIsFetching } from 'features/fleets/fleetsSlice';
import {
  useDeviceModelsList,
  useIsFetchingDeviceModels
} from 'features/device_models/deviceModelsSlice';
import { useCameraDiagnosticStatus } from 'features/camera/cameraSlice';
import { GlobalRoles } from 'features/permissions';
import { CameraModelConfig, isIQCamModelId } from 'features/camera/CameraModelConfig';
import { useCurrentRegion } from 'features/regions/regionsSlice';

function isRetryable(extraStr) {
  let retryable = false;
  if (extraStr) {
    try {
      const extraJson = JSON.parse(extraStr);
      if (
        extraJson.Errors?.length &&
        !extraJson.Errors.some(e =>
          [ErrorMessageTypes.MEDIA_NOT_AVAILABLE, ErrorMessageTypes.BAD_MEDIA_REQUEST].includes(
            e.MessageType
          )
        )
      ) {
        retryable = true;
      }
    } catch {
      //
    }
  }
  return retryable;
}

const useEventVideoRequest = ({ t, event, onRequestVideo }) => {
  const [vrPriorCheckModal, vrPriorCheckModalCtx] = Modal.useModal();
  const can = useCan();
  const currentRegion = useCurrentRegion();
  const devices = useDevices();
  const isFetchingDevices = useIsFetching();
  const deviceModels = useDeviceModelsList();
  const isFetchingModels = useIsFetchingDeviceModels();
  const { cameraStatus, isFetching: isFetchingCamStatus } = useCameraDiagnosticStatus(
    event?.deviceId
  );

  const isFetching = useMemo(() => isFetchingDevices || isFetchingModels || isFetchingCamStatus, [
    isFetchingDevices,
    isFetchingModels,
    isFetchingCamStatus
  ]);

  const canPriorCheck = useMemo(() => {
    const device = event?.deviceId && devices?.find(d => String(d.id) === String(event.deviceId));
    const isIQCamera = !!device?.id && isIQCamModelId(device?.model?.id, deviceModels);
    return !can({ oneOfRoles: [GlobalRoles.SiteAdmin] }) && isIQCamera;
  }, [event?.deviceId, devices, deviceModels]);

  const handleRequestVideo = useCallback(
    (forRetry = false) => {
      if (onRequestVideo) {
        if (canPriorCheck) {
          const {
            isExhausted,
            max,
            monthlyRenewOn,
            remaining
          } = CameraModelConfig.IQCamera.getDataPlanUsage({
            dataPlan: cameraStatus?.dataPlan,
            planKeys: ['DVR_UPLOADED'],
            currentRegionCode: currentRegion?.code
          });
          if (isExhausted) {
            vrPriorCheckModal.error({
              content: t('Devices.View.DataPlan.Exhausted', {
                max,
                date: t(`Devices.View.DataPlan.MonthlyRenew.Next.${monthlyRenewOn}`)
              }),
              okText: t('Common.Modal.OK'),
              width: 600,
              centered: true,
              wrapClassName: styles.confirmModal
            });
          } else {
            vrPriorCheckModal.confirm({
              title: t('Common.Modal.DoYouWishToContinue'),
              content: t('Footage.RequestPriorConfirm', { max, remaining }),
              okText: t('Common.Modal.OK'),
              cancelText: t('Common.Modal.Cancel'),
              onOk: () => {
                onRequestVideo(event, forRetry);
              },
              width: 600,
              centered: true,
              wrapClassName: styles.confirmModal
            });
          }
        } else {
          onRequestVideo(event, forRetry);
        }
      }
    },
    [canPriorCheck, event, cameraStatus, onRequestVideo, currentRegion, vrPriorCheckModal]
  );

  return {
    disableRequest: !can({ oneOfRoles: [GlobalRoles.SiteAdmin] }) && isFetching,
    handleRequestVideo,
    vrPriorCheckModalCtx
  };
};

export function EventMenuItem({
  event,
  showViewDevice = true,
  showViewVehicle = true,
  showViewDriver = true,
  showViewEvent = true,
  showVideoRequest = true,
  showRetry = true,
  onRequestVideo,
  onViewEvent,
  ...props
}) {
  const { canAccessNonCameraFeatures } = useIQCameraUser();

  const history = useHistory();
  const { t } = useTranslation();
  const { disableRequest, handleRequestVideo, vrPriorCheckModalCtx } = useEventVideoRequest({
    t,
    event,
    onRequestVideo
  });

  const menuIcon = useMemo(() => {
    return <i style={{ fontSize: '26px' }} className={'tn-i-elipsis-vert'} />;
  }, []);

  const handleViewEvent = useRef();

  const handleViewVehicle = useCallback(
    evt => {
      history.push('/settings/vehicles/id/' + evt.vehicleId);
    },
    [history]
  );

  const handleViewDevice = useCallback(
    evt => {
      history.push('/settings/devices/id/' + evt.deviceId);
    },
    [history]
  );

  const handleViewDriver = useCallback(
    evt => {
      history.push('/settings/users/id/' + evt.driverId);
    },
    [history]
  );

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const items = [];
    if (
      showViewEvent &&
      event.attachmentCount &&
      [AttachmentStatus.AVAILABLE, AttachmentStatus.ENABLED].includes(event.attachmentStatus)
    ) {
      items.push({
        label: t('Tracking.ActionsMenu.ViewEvent'),
        onClick: () => handleViewEvent.current(event),
        id: 'btn_menuViewEvent'
      });
    }

    if (canAccessNonCameraFeatures && showViewVehicle && event.vehicleId) {
      items.push({
        label: t('Tracking.ActionsMenu.ViewVehicle'),
        onClick: () => handleViewVehicle(event),
        id: 'btn_menuViewVehicle'
      });
    }

    if (canAccessNonCameraFeatures && showViewDevice) {
      items.push({
        label: t('Tracking.ActionsMenu.ViewDevice'),
        onClick: () => handleViewDevice(event),
        id: 'btn_menuViewDevice'
      });
    }

    if (canAccessNonCameraFeatures && showViewDriver && event.driverId) {
      items.push({
        label: t('Tracking.ActionsMenu.ViewDriver'),
        onClick: () => handleViewDriver(event),
        id: 'btn_menuViewDriver'
      });
    }

    if (showVideoRequest && event.attachmentStatus === AttachmentStatus.AVAILABLE_ON_REQUEST) {
      items.push({
        label: t('Tracking.ActionsMenu.RequestVideo'),
        id: 'btn_menuRequestVideo',
        disabled: disableRequest,
        renderProp: () => (
          <Button
            className={styles.requestVideoBtn}
            type="text"
            onClick={() => handleRequestVideo()}
            loading={event.requestVideoUpload}
            disabled={disableRequest}
          >
            {t('Tracking.ActionsMenu.RequestVideo')}
          </Button>
        )
      });
    }

    if (
      showRetry &&
      !event.requestVideoUpload &&
      isRetryable(event.extras) &&
      event.videoRequestStatus?.toLowerCase() === EventStatusToRequestStatus.FAILED.toLowerCase()
    ) {
      items.push({
        label: t('Tracking.ActionsMenu.RetryVideoRequest'),
        id: 'btn_menuVideoRequestRetry',
        renderProp: () => (
          <Button
            className={styles.requestVideoBtn}
            type="text"
            onClick={() => handleRequestVideo(true)}
            loading={event.requestVideoUpload}
          >
            {t('Tracking.ActionsMenu.RetryVideoRequest')}
          </Button>
        )
      });
    }
    setMenuItems(items);
  }, [
    canAccessNonCameraFeatures,
    event,
    showViewDevice,
    showViewVehicle,
    showViewDriver,
    showViewEvent,
    showVideoRequest,
    showRetry,
    handleViewVehicle,
    handleViewDevice,
    handleViewDriver,
    disableRequest,
    handleRequestVideo
  ]);

  useEffect(() => {
    handleViewEvent.current = () => {
      if (onViewEvent) {
        onViewEvent(event);
      }
    };
  }, [onViewEvent]);
  if (menuItems.length > 0) {
    return (
      <>
        {vrPriorCheckModalCtx}
        <EllipsisMenu menuItems={menuItems} icon={menuIcon} />
      </>
    );
  } else {
    return <div></div>;
  }
}
