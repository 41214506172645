import i18n from 'i18next';
import moment from 'moment';
import { sortBy } from 'lodash';

import { formatForCsv } from 'utils/strings';
import { getPointsDistance } from 'utils/geo';

import { getEventAttributesByType } from 'containers/Tracking/EventTypes';

const getUser = (event, users) => {
  let user = null;
  if (event.user?.id && users) user = users.find(u => u.id === event.user.id);
  return user;
};

const getBranch = (user, branches) => {
  let branch = '';
  if (user && branches) branch = branches.find(b => b.id === user.location?.id)?.name || '';
  return branch;
};

export const prepareDataForCsvExport = (device, trips, users, branches, localization) => {
  let csvData = [];

  if (trips) {
    trips.forEach(trip => {
      // Get all events and replay data combined in on array and sorted by time
      let tripData = [...(trip.replay ? trip.replay : []), ...(trip.events ? trip.events : [])];

      const sortedTripData = sortBy(tripData, [
        o => {
          return o.eventType ? o.timeAt : o.At;
        }
      ]);

      if (sortedTripData) {
        // for calculating distance within trips using lat,lng
        let gpsPoints = [];

        sortedTripData.forEach(event => {
          let time, eventType, gps, location, user, driverName, branch;

          // return slightly different data depending on if it is a replay data point vs a device event
          if (event.eventType) {
            user = getUser(event, users);
            driverName = user?.name || '';
            branch = getBranch(user, branches);
            const eventAttributes = getEventAttributesByType(event?.eventType, event?.subType);
            eventType = i18n.t(`Tracking.Events.${eventAttributes?.label}`);
            time = moment(event.timeAt);
            gps = event.GPS;
            location = event.location;
          } else {
            driverName = '';
            branch = '';
            eventType = i18n.t('Tracking.HighDefinition');
            time = moment(event.At);
            gps = event;
            location = `${gps.Lat},${gps.Lng}`;
          }

          const fleetNames = device.fleetNames?.join('; ');

          // Only compute trip distance for trips with ids (not for out of trip events)
          let tripDistance = 0;
          if (trip.id && gps?.Lat && gps?.Lat !== 0 && gps?.Lng && gps?.Lng !== 0) {
            gpsPoints.push({ lat: gps?.Lat, lng: gps?.Lng });
            tripDistance = getPointsDistance(gpsPoints);
          }

          const csvRow = {
            [i18n.t('Tracking.CSV.Vehicle')]: formatForCsv(device.vehicleName),
            [i18n.t('Tracking.CSV.Device')]: formatForCsv(device.name),
            [i18n.t('Tracking.CSV.Fleet')]: formatForCsv(fleetNames),
            [i18n.t('Tracking.CSV.Branch')]: formatForCsv(branch),
            [i18n.t('Tracking.CSV.Driver')]: formatForCsv(driverName),
            [i18n.t('Tracking.CSV.EventType')]: eventType,
            [i18n.t('Tracking.CSV.EventDate')]: time.format('YYYY-MM-DD'),
            [i18n.t('Tracking.CSV.EventTime')]: time.format('HH:mm:ss'),
            [i18n.t('Tracking.CSV.Speed')]: localization.convertSpeed(gps?.Spd, 1),
            [i18n.t('Tracking.CSV.SpeedUnit')]: localization.formats.speed.unit_per_hour,
            [i18n.t('Tracking.CSV.TripDistanceGPS')]: localization.convertDistance(tripDistance, 1),
            [i18n.t('Tracking.CSV.TripUnit')]: localization.formats.speed.unit,
            [i18n.t('Tracking.CSV.Direction')]: gps?.Dir,
            [i18n.t('Tracking.CSV.Location')]: formatForCsv(location),
            [i18n.t('Tracking.CSV.Latitude')]: gps?.Lat,
            [i18n.t('Tracking.CSV.Longitude')]: gps?.Lng,
            HDOP: gps?.HDOP,
            [i18n.t('Tracking.CSV.NumberOfSatellites')]: gps?.NSat
          };

          csvData.push(csvRow);
        });
      }
    });
  }

  return csvData;
};
