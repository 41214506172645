import React, { memo } from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import {
  cacheVehicles as cache,
  checkboxCellRenderer,
  nameCellRenderer,
  vehicleInFleetCellRenderer,
  vehicleRegistrationCellRenderer,
  typeCellRenderer,
  devicesInVehicle
} from './CellRenderers';
import { Checkbox } from 'antd';
import { Loading } from 'components/loading/Loading';
import { useTranslation } from 'react-i18next';
import styles from './Fleets.module.scss';

export const rowAction = (id, items, action) => {
  const newCheckedValues = (items || []).includes(id)
    ? items.filter(item => item !== id)
    : [...(items || []), id];

  action(newCheckedValues);
};

export const VehiclesTableAddFleetComponent = ({
  checkedItems,
  setCheckedItems,
  vehicles,
  vehiclesIDs,
  companies,
  setTableRef,
  isLoading
}) => {
  const { t } = useTranslation();

  const handleCheckboxChange = () => {
    setCheckedItems(vehicles?.length !== checkedItems?.length ? vehiclesIDs : []);
  };

  const headerCheckboxRender = () => {
    return (
      <Checkbox
        onChange={handleCheckboxChange}
        checked={vehicles?.length === checkedItems?.length}
      />
    );
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          checkbox: 48,
          name: 200,
          fleetIn: 250,
          registration: 180,
          type: 170,
          device: 230
        };

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerClassName={styles.tableHeaderText}
            headerHeight={52}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={vehicles.length}
            rowGetter={({ index }) => vehicles[index]}
            onRowClick={event => {
              rowAction(event.rowData.id, checkedItems, setCheckedItems);
            }}
            ref={ref => setTableRef(ref)}
            gridClassName="showScrollbarsOnHover fleetsTablesBottomMargin"
          >
            <Column
              label=""
              dataKey="id"
              width={fixedColumnsWidth.checkbox}
              headerRenderer={headerCheckboxRender}
              cellRenderer={props =>
                checkboxCellRenderer({
                  ...props,
                  checkedItems,
                  setCheckedItems
                })
              }
            />
            <Column
              label={t('Common.Name')}
              dataKey="name"
              width={fixedColumnsWidth.name}
              cellRenderer={nameCellRenderer}
            />
            <Column
              label={t('Fleets.Form.Fleet(s)CurrentlyIn')}
              dataKey="fleetsIn"
              width={fixedColumnsWidth.fleetIn}
              cellRenderer={props => vehicleInFleetCellRenderer({ ...props, companies })}
            />
            <Column
              label={t('Common.Registration')}
              dataKey="registration"
              width={fixedColumnsWidth.registration}
              cellRenderer={vehicleRegistrationCellRenderer}
            />
            <Column
              label={t('VehicleTypes.Type')}
              dataKey="type"
              width={fixedColumnsWidth.type}
              cellRenderer={typeCellRenderer}
            />
            <Column
              label={t('Devices.Device')}
              dataKey="device"
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.device}
              cellRenderer={devicesInVehicle}
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};

export const VehiclesTableAddFleet = memo(VehiclesTableAddFleetComponent);
