import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';
import {
  addAlert,
  addAlertForms,
  addAlertPretrips,
  addAlertGeofence,
  editAlert,
  fetchAlerts
} from 'features/company_alerts';
import {
  addMeterThresholds,
  associateMeterThreshold,
  updateAlertMeterThreshold,
  fetchAlertMeterThresholdsById
} from 'features/company_alerts/meter_alert';
import { ALERT_TYPES } from 'containers/Configuration/CompanyAlerts/constants';
import { ENTITIES } from 'components/alertStatusBar/constants';
import { fetchUserAlerts } from 'features/user_alerts';
import { buildRequestPayloadFromState } from 'containers/Configuration/CompanyAlerts/reducer/state/buildRequestPayloadFromState';
import i18n from 'i18nextConfig';
import { MPTrackingEvents } from 'features/mixpanel';

export const showAlertToaster = (action, type, name, err, alertType) =>
  openToast({
    type,
    message: (function() {
      switch (type) {
        case ToastType.Success:
          return action === 'edit'
            ? alertType === 'Ng::Company'
              ? i18n.t('Alerts.Toast.CompanyAlertSuccessfullyUpdated', { name: name })
              : i18n.t('Alerts.Toast.UserAlertSuccessfullyUpdated', { name: name })
            : i18n.t('Alerts.Toast.AlertSuccessfullyAdded', { name: name });
        default:
          return action === 'edit'
            ? i18n.t('Alerts.Toast.ErrorWhenUpdatingAlert', { name: name })
            : parseErrorMessage(err);
      }
    })()
  });

const handleAlertAPICall = (action, alertToSend) => {
  switch (action) {
    case 'edit':
      MPTrackingEvents.Settings.Alerts.sendAlerstUpdateEvent(alertToSend, 'edit');
      return editAlert({
        body: alertToSend,
        successToaster: () => showAlertToaster(action, ToastType.Success, alertToSend.name),
        errorToaster: err => showAlertToaster(action, ToastType.Error, alertToSend.name, err)
      });
    case 'add':
    default:
      MPTrackingEvents.Settings.Alerts.sendAlerstUpdateEvent(alertToSend, 'add');
      return addAlert({
        body: alertToSend,
        successToaster: () => showAlertToaster(action, ToastType.Success, alertToSend.name),
        errorToaster: err => showAlertToaster(action, ToastType.Error, alertToSend.name, err)
      });
  }
};

const handleAlertFormsAPICall = (dispatch, alertCallResponse, alertToSend) => {
  dispatch(
    addAlertForms({
      body: {
        alertSubscriptionId: alertCallResponse.payload?.id,
        associations: alertToSend?.formAlertAssociations || [],
        createdAt: new Date(),
        updatedAt: new Date()
      }
    })
  );
};

const handlePretripsAPICall = (dispatch, alertCallResponse, alertToSend) => {
  dispatch(
    addAlertPretrips({
      body: {
        alertSubscriptionId: alertCallResponse.payload?.id,
        associations: alertToSend?.preTripAlertAssociations || [],
        createdAt: new Date(),
        updatedAt: new Date()
      }
    })
  );
};

const handleAlertGeofencesAPICall = (dispatch, alertCallResponse, alertToSend) => {
  dispatch(
    addAlertGeofence({
      body: {
        alertSubscriptionId: alertCallResponse.payload.id,
        associations: alertToSend?.geofenceAlertAssociations || [],
        createdAt: new Date(),
        updatedAt: new Date()
      }
    })
  );
};

const handleMeterThresholdsAPICall = (action, dispatch, requestPayload, alertEntityType) => {
  switch (action) {
    case 'edit':
      if (alertEntityType === ENTITIES.USER && !requestPayload?.body?.id) {
        return dispatch(addMeterThresholds(requestPayload));
      }
      return dispatch(updateAlertMeterThreshold(requestPayload));
    default:
    case 'add':
      return dispatch(addMeterThresholds(requestPayload));
  }
};

const handleMeterThreshcoldAssociation = (dispatch, alertId, meterId) => {
  return dispatch(
    associateMeterThreshold({
      body: {
        alertSubscriptionId: alertId,
        associations: [{ meterThresholdId: meterId }]
      }
    })
  );
};

export const saveAlert = (alertState, payloadProperties, setIsDirty, type) => async () => {
  const { dispatch, action, history, activeStatus, entityType, id } = payloadProperties;
  const alertToSend = buildRequestPayloadFromState(alertState, payloadProperties, activeStatus);

  if (typeof setIsDirty === 'function') {
    setIsDirty(false);
  }
  const alertCallResponse = await dispatch(handleAlertAPICall(action, alertToSend));

  if (alertToSend.type === ALERT_TYPES.FORM) {
    await handleAlertFormsAPICall(dispatch, alertCallResponse, alertToSend);
  }

  if (alertToSend.type === ALERT_TYPES.PRETRIP) {
    await handlePretripsAPICall(dispatch, alertCallResponse, alertToSend);
  }

  if (alertToSend.type === ALERT_TYPES.GEOFENCE) {
    await handleAlertGeofencesAPICall(dispatch, alertCallResponse, alertToSend);
  }

  if (alertToSend.type === ALERT_TYPES.METER) {
    const meterThresholdsPayload = {
      body: {
        ...(alertState?.meterThresholdId && { id: alertState.meterThresholdId }),
        companyId: id,
        entityType: alertState.meterEntityType,
        name:
          entityType === ENTITIES.USER ? `${alertState.name}-${alertState.id}` : alertState.name,
        description: '',
        deviceType: alertState.deviceType,
        type: alertState.meterType,
        source: alertState.meterSource,
        expression: `curr.value ${alertState.meterOperation} ${alertState.meterValue}`
      }
    };

    const association = await handleMeterThresholdsAPICall(
      action,
      dispatch,
      meterThresholdsPayload,
      alertToSend.entityType
    );

    if (action === 'add' || (entityType === ENTITIES.USER && !alertState?.meterThresholdId)) {
      const associationResponse = await handleMeterThreshcoldAssociation(
        dispatch,
        alertCallResponse?.payload?.id,
        association?.payload?.id
      );

      if (entityType === ENTITIES.USER && !alertState?.meterThresholdId) {
        await dispatch(
          fetchAlertMeterThresholdsById({
            query: {
              meterThresholdId: associationResponse.payload.associations[0].meterThresholdId
            },
            alertId: alertState.id
          })
        );
      }
    }
  }

  if (alertCallResponse?.meta?.requestStatus !== 'rejected') {
    if (entityType === ENTITIES.USER) {
      history.goBack();
      await dispatch(fetchUserAlerts());
    } else {
      history.push(`/settings/alerts/view/${type}`);
      await dispatch(
        fetchAlerts({
          query: {
            entity_type: ENTITIES.COMPANY,
            entity_id: id
          }
        })
      );
    }

    if (alertToSend.type === ALERT_TYPES.METER && alertState.meterThresholdId) {
      await dispatch(
        fetchAlertMeterThresholdsById({
          query: {
            meterThresholdId: alertState.meterThresholdId
          },
          alertId: alertState.id
        })
      );
    }
  }
};
