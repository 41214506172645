import React, { useEffect, useCallback, useMemo } from 'react';
import { Tabs } from 'antd';
import { DriverLogGrid } from './DriverLogGrid';
import styles from './DriverLog.module.scss';
import { ViolationList } from './ViolationList';
import {
  useDriverEventsByDate,
  useDriverViolationsByDate,
  fetchDriverEvents,
  fetchDriverViolations
} from 'features/eld/driverLogSlice';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import { useDrivers } from 'features/users/usersSlice';
import { useTranslation } from 'react-i18next';
import { useDriverELDData } from 'features/eld/eldSlice';

export function DriverLogPageBody({
  logDate,
  driverId,
  company,
  onEventRowMouseEnter,
  onEventRowMouseLeave,
  onViolationRowMouseEnter,
  onViolationRowMouseLeave,
  driverDetail,
  refreshData,
  reloadData,
  ...props
}) {
  const dispatch = useDispatch();
  const drivers = useDrivers();
  const { t } = useTranslation();

  const driver = useMemo(() => drivers?.find(d => d.id === parseInt(driverId)), [
    driverId,
    drivers
  ]);

  const driverEvents = useDriverEventsByDate(
    company.id,
    driver,
    moment(logDate || new Date())
      .startOf('day')
      .toDate(),
    moment(logDate || new Date())
      .endOf('day')
      .toDate()
  );

  const driverEldData = useDriverELDData(
    company?.id,
    driverId,
    moment(logDate || new Date())
      .startOf('day')
      .toDate(),
    moment(logDate || new Date())
      .endOf('day')
      .toDate()
  );

  const driverViolations = useDriverViolationsByDate(
    company.id,
    driverId,
    moment(logDate || new Date())
      .startOf('day')
      .toDate(),
    moment(logDate || new Date())
      .endOf('day')
      .toDate()
  );

  const loadEvents = useCallback(
    reload => {
      if (logDate != null && driverId != null && company != null) {
        dispatch(
          fetchDriverEvents(
            company,
            driverId,
            moment(logDate)
              .startOf('day')
              .toDate(),
            moment(logDate)
              .endOf('day')
              .toDate(),
            reload
          )
        );
      }
    },
    [logDate, driverId, company, dispatch]
  );

  const reloadEvents = useCallback(() => {
    loadEvents(true);
    loadViolations();
  }, [loadEvents]);

  const loadViolations = useCallback(() => {
    if (logDate != null && driver != null && company != null) {
      const startDate = moment(logDate)
        .startOf('day')
        .toDate();
      const endDate = moment(logDate)
        .endOf('day')
        .toDate();

      const checkpointDate = moment();
      dispatch(
        fetchDriverViolations(
          company,
          driver.id,
          driver.rulesets?.[0]?.ruleset,
          startDate,
          endDate,
          checkpointDate?.toDate()
        )
      );
    }
  }, [logDate, driver, company, driverViolations, dispatch]);

  useEffect(() => {
    loadEvents();
    loadViolations();
  }, [loadEvents, loadViolations]);

  if (reloadData) {
    reloadEvents();
  }
  return (
    <Tabs size="large" className={styles.driverLogBodyTab}>
      <Tabs.TabPane tab={t('Tabs.events')} key="1">
        <DriverLogGrid
          data={driverEvents}
          company={company}
          onEventUpdated={reloadEvents}
          driverId={driverId}
          onRowMouseEnter={onEventRowMouseEnter}
          onRowMouseLeave={onEventRowMouseLeave}
          driverDetail={driverDetail}
          refreshData={refreshData}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('ELD.Violations')} key="2">
        <ViolationList
          dateRange={logDate}
          onRowMouseEnter={onViolationRowMouseEnter}
          onRowMouseLeave={onViolationRowMouseLeave}
          data={driverViolations}
        />
      </Tabs.TabPane>
    </Tabs>
  );
}
