import React from 'react';
import { Row, Col, Divider, Button } from 'antd';

import style from './ViewPage.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const ViewPage = ({ title, data = [], onEdit, onDelete }) => {
  return (
    <>
      <div className={style.actionButtons}>
        <Button
          type="secondary"
          onClick={onEdit}
          id={BUTTON_IDS.viewPageEdit}
          className={style.edit}
        >
          Edit
        </Button>
        <Button
          type="secondary"
          onClick={onDelete}
          id={BUTTON_IDS.viewPageDelete}
          className={style.delete}
        >
          Delete
        </Button>
      </div>
      <Divider style={{ margin: 0 }} />
      <div className={style.container}>
        <div className={style.title}>{title}</div>
        <Divider style={{ margin: '16px 0' }} />
        {data.map(item => (
          <>
            <Row>
              <Col span={18} push={6}>
                <div className={style.value}>{item.value}</div>
              </Col>
              <Col span={6} pull={18}>
                <div className={style.label}>{item.label}</div>
              </Col>
            </Row>
            <Divider style={{ margin: '16px 0' }} />
          </>
        ))}
      </div>
    </>
  );
};
