import React, { useEffect, useState } from 'react';

import { vehicleLayoutType, axleLayoutType } from '../Data';

import imgCab from './images/cab.png';
import imgRigidCab from './images/rigid_cab.png';
import imgTrailer from './images/trailer.png';
import imgAxle from './images/axle.png';
import imgAxleLeft from './images/axle_left.png';
import imgAxleLeftHitch from './images/axle_left_hitch.png';
import imgAxleMiddle from './images/axle_middle.png';
import imgAxleRight from './images/axle_right.png';
import imgHitchA from './images/hitch_a.png';
import imgHitchB from './images/hitch_b.png';

import styles from './vis.module.scss';

const VisWidths = {
  Trailer: 60,
  TrailerSpace: 30,
  Hitch: 46,
  AxleSpace: 48
};

export const VehicleVis = ({ visualization, showVisName = false, visNameSuffix }) => {
  const [vis, setVis] = useState();

  let isAfterHitch = false;

  useEffect(() => {
    if (visualization && visualization !== '{}') {
      setVis(typeof visualization === 'string' ? JSON.parse(visualization) : visualization);
    }
  }, [visualization]);

  if (!vis) {
    return null;
  }

  return (
    <div className={styles.vehicleVisContainer}>
      <div className={styles.vehicleVis}>
        <div
          className={`${styles.vehicleLayout} ${vis?.vehicleLayouts[0].type ===
            vehicleLayoutType.rigidCab && styles.rigidCab}`}
        >
          {vis.vehicleLayouts?.map((vehicleLayout, vLayoutIndex) => {
            const key = `vLayout-element-${vLayoutIndex}`;
            switch (vehicleLayout.type) {
              case vehicleLayoutType.cab:
              case vehicleLayoutType.twinCab:
                return <img key={key} src={imgCab} alt="cab" />;
              case vehicleLayoutType.rigidCab:
                return <img key={key} src={imgRigidCab} alt="rigid cab" />;
              case vehicleLayoutType.trailer:
                return (
                  <img
                    key={key}
                    src={imgTrailer}
                    alt="trailer"
                    width={vehicleLayout.width * VisWidths.Trailer}
                    height="152"
                  />
                );
              default:
                return (
                  <span
                    key={key}
                    className={styles.space}
                    style={{
                      display: 'inline-block',
                      width:
                        (vehicleLayout.hitchMultiples > 1
                          ? vehicleLayout.hitchMultiples
                          : Math.abs(vehicleLayout.width)) * VisWidths.TrailerSpace
                    }}
                  />
                );
            }
          })}
        </div>
        <div
          className={`${styles.axleLayout} ${vis.vehicleLayouts[0].type ===
            vehicleLayoutType.rigidCab && styles.rigidCab}`}
        >
          {vis.axleLayouts?.map((axleLayout, index) => {
            const key = `axleLayout-element-${index}`;
            switch (axleLayout.type) {
              case axleLayoutType.steerWheel:
                isAfterHitch = false;
                return (
                  <img
                    key={key}
                    src={imgAxle}
                    className={styles.wheel}
                    alt={axleLayout.label + ' wheel'}
                  />
                );
              case axleLayoutType.twinSteerWheel:
                isAfterHitch = false;
                return (
                  <React.Fragment key={key}>
                    <img
                      key={`${key}-0`}
                      src={imgAxle}
                      className={styles.wheel}
                      alt={axleLayout.label + ' wheel'}
                    />
                    <img
                      key={`${key}-1`}
                      src={imgAxle}
                      className={styles.wheel}
                      alt={axleLayout.label + ' wheel'}
                    />
                  </React.Fragment>
                );
              case axleLayoutType.wheel:
                isAfterHitch = false;
                return (
                  <React.Fragment key={key}>
                    {/* render axleLayout.width number of wheels */}
                    {[...Array(axleLayout.width)].map((elem, index) => (
                      <img
                        key={`${key}-${index}`}
                        className={styles.wheel}
                        src={imgAxle}
                        alt={axleLayout.label + ' wheel'}
                      />
                    ))}
                  </React.Fragment>
                );
              case axleLayoutType.wheels:
                return (
                  <React.Fragment key={key}>
                    {/* render axleLayout.width number of wheels */}
                    {[...Array(axleLayout.width)].map((elem, index) => {
                      let img;
                      let className = styles.wheel;

                      if (index === 0) {
                        img = isAfterHitch ? imgAxleLeftHitch : imgAxleLeft;
                        className += isAfterHitch ? ' ' + styles.afterHitch : '';
                      } else if (index === axleLayout.width - 1) {
                        img = imgAxleRight;
                      } else {
                        img = imgAxleMiddle;
                      }

                      isAfterHitch = false;
                      return (
                        <img
                          key={`${key}-${index}`}
                          className={className}
                          src={img}
                          alt={axleLayout.label + ' wheels'}
                        />
                      );
                    })}
                  </React.Fragment>
                );
              case axleLayoutType.hitchA:
              case axleLayoutType.hitchB:
                isAfterHitch = true;
                return (
                  <img
                    key={key}
                    src={axleLayout.type === axleLayoutType.hitchA ? imgHitchA : imgHitchB}
                    alt={axleLayout.type === axleLayoutType.hitchA ? 'hitch A' : 'hitch B'}
                    className={styles.hitch}
                    width={Math.abs(axleLayout.width) * VisWidths.Hitch}
                    height="79"
                  />
                );
              default:
                isAfterHitch = false;
                return (
                  index > 0 && (
                    <span
                      key={key}
                      className={styles.space}
                      style={{
                        display: 'inline-block',
                        width: Math.abs(axleLayout.width) * VisWidths.AxleSpace
                      }}
                    />
                  )
                );
            }
          })}
        </div>
        {showVisName && (
          <div className={styles.visName}>
            <span>{vis.name.replace(' - FUPS', '')}</span>
            <span>{visNameSuffix && ' ' + visNameSuffix}</span>
          </div>
        )}
      </div>
    </div>
  );
};
