import React, { useEffect, useRef, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useGetQsAnalysisQuery, useGetQsConsoleUrlQuery } from 'services/nextgen';

import { setBackButton, setPageTitle, addBreadcrumbs } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { openToast } from 'features/toasts/toastsSlice';

import { Iframe } from 'components/iframe/Iframe';
import { LoadingCentered } from 'components/loading/Loading';
import { ToastType } from 'components/notifications/toasts/Toast';

const AnalysisPage = () => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const history = useHistory();
  const { analysisId } = useParams();
  const dashboardFrameRef = useRef(null);
  const analysis = useGetQsAnalysisQuery({ analysisId: analysisId });
  const analysisUrl = useGetQsConsoleUrlQuery({ analysisId: analysisId });

  useEffect(() => {
    console.debug('analysis', analysis);
    dispatch(setPageTitle(analysis?.data?.name ? analysis?.data?.name : ''));
    dispatch(setBackButton(true));
    dispatch(
      addBreadcrumbs([
        {
          breadcrumbName: t('Common.Insights'),
          path: '/insights'
        },
        {}
      ])
    );

    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [dispatch, analysis.data]);

  useEffect(() => {
    console.debug('analysisUrl', analysisUrl);
    if (analysisUrl.error) {
      console.error('analysisUrl - error', analysisUrl.error.data);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Insights.Toasts.LoadAnalysisError')
        })
      );
      history.push('/insights');
    }
  }, [analysisUrl]);

  const isLoading = useMemo(
    () =>
      analysis.isLoading || analysis.isFetching || analysisUrl.isLoading || analysisUrl.isFetching,
    [analysis.isLoading, analysis.isFetching, analysisUrl.isLoading, analysisUrl.isFetching]
  );

  return (
    <>
      {isLoading ? (
        <LoadingCentered />
      ) : (
        <Iframe
          id="analysis"
          title="Analysis"
          width={'100%'}
          style={{ border: 'none', height: '100%', backgroundColor: '#eee' }}
          src={analysisUrl.data ? analysisUrl.data + `&locale=${localization.locale}` : ''}
          ref={dashboardFrameRef}
        />
      )}
    </>
  );
};

export default AnalysisPage;
