import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Badge, Table } from 'react-bootstrap';
import moment from 'moment';
import { useLocalization } from 'features/localization/localizationSlice';
import { setEventId, useEvents, fetchEvents } from 'features/ewd/components/events/eventsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faLock,
  faPencilAlt,
  faShip
} from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash';
import debounce from 'lodash/debounce';
import { LoadingCentered } from 'components/loading/Loading';
import { useAudit } from '../audits/auditsSlice';
import { useDriverTimezone, useEWDDriverById, useEwdDriverId } from '../drivers/driversSlice';
import styles from './EWDTable.module.scss';
import { actionTypes } from 'utils/reduxFetchingUtils';
import { TZ2STATE } from 'features/sentinel/constants/Generals';
import { BUTTON_IDS } from 'utils/globalConstants';

const DropdownButton = props => (
  <button
    id={BUTTON_IDS.ewdTableDropdown}
    onClick={() => props.handleClick()}
    className="btn btn-none stretched-link"
  >
    <FontAwesomeIcon icon={props.open ? faAngleUp : faAngleDown} />
  </button>
);

const AuditLog = ({ audits, event, localization }) => {
  if (audits && audits.isLoading) {
    return (
      <Row className="justify-content-md-center">
        <Col className="p-3" xs="auto">
          <LoadingCentered />
        </Col>
      </Row>
    );
  }

  if (audits && audits.length > 0) {
    const displayedChanges = [
      'action',
      'entryAt',
      'eventAt',
      'location',
      'notes',
      'odometer',
      'updatedAt'
    ];
    let changeTable = [];
    //The audit log doesn't include the current event state. Pushing the current event onto the stack means it can be compared against
    [cloneDeep(event), ...audits].forEach((audit, index, fullAuditList) => {
      if (index === 0) {
        return;
      }
      let hasChange = false;
      const changeData = displayedChanges.map(title => {
        let current = fullAuditList[index - 1][title] ? fullAuditList[index - 1][title] : ' ';
        let previous = audit[title] ? audit[title] : ' ';
        if (current !== previous) {
          hasChange = hasChange || title !== 'updatedAt';
          if (['entryAt', 'eventAt', 'updatedAt'].includes(title)) {
            current =
              current !== ' '
                ? moment(current).format(localization.formats.time.formats.bdo_hm)
                : ' ';
            previous =
              previous !== ' '
                ? moment(previous).format(localization.formats.time.formats.bdo_hm)
                : ' ';
          }
          if (title === 'updatedAt') {
            return <td key={title}>{previous}</td>;
          }
          return (
            <td key={title}>
              <p className={'alert-primary p-1'}>{current}&nbsp;</p>
              <p className={'alert-danger p-1'}>{previous}&nbsp;</p>
            </td>
          );
        }
        return <td key={title}>&nbsp;</td>;
      });

      if (hasChange) {
        changeTable.push(<tr>{changeData}</tr>);
      }
    });

    if (changeTable.length > 0) {
      return (
        <Row>
          <Col>
            <Row>
              <Col className="pt-3" xs="auto">
                <p className="m-0">
                  <strong>Changes:</strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Table size="sm" className="change-log-table">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Entry At</th>
                    <th>Event At</th>
                    <th>Location</th>
                    <th>Notes</th>
                    <th>Odometer</th>
                    <th>Updated At</th>
                  </tr>
                </thead>
                <tbody>{changeTable}</tbody>
              </Table>
            </Row>
          </Col>
        </Row>
      );
    }
  }
  return (
    <Row className="justify-content-md-center">
      <Col className="p-3" xs="auto">
        <p>No Audits for this event</p>
      </Col>
    </Row>
  );
};

const EWDEventTabs = ({ event, localization }) => {
  const audits = useAudit(event.id);
  return <AuditLog audits={audits} event={event} localization={localization} />;
};

const EdittedIndicator = ({ event }) => {
  if (event.status === 'M' || (event.status === 'L' && event.numAudit > 1)) {
    return (
      <>
        {' '}
        <Badge variant="secondary">
          <FontAwesomeIcon icon={faPencilAlt} title="Edited" /> Edited
        </Badge>
      </>
    );
  }
  return <></>;
};
const FerryIndicator = ({ event }) => {
  if (event.params) {
    if (event.params.includes('{"nzta.ferry":true}')) {
      return (
        <>
          {' '}
          <Badge variant="primary">
            <FontAwesomeIcon icon={faShip} title="Ferry Crossing" /> Ferry
          </Badge>
        </>
      );
    }
  }

  return <></>;
};
const LockedIndicator = ({ event }) => {
  if (event.status === 'L') {
    return (
      <>
        {' '}
        <Badge variant="primary">
          <FontAwesomeIcon icon={faLock} title="Locked Record" /> Locked
        </Badge>
      </>
    );
  }

  return <></>;
};

const EventOdometer = ({ event }) => {
  let value = ' - ';
  if (event.odometer) {
    const odometer = event.odometer === 'NaN' ? null : parseInt(event.odometer, 10);
    if (odometer > 0) {
      value = odometer;
    }
    if (event.odometerAuto) {
      const odometerAuto = event.odometerAuto === 'NaN' ? null : parseInt(event.odometerAuto, 10);
      if (odometerAuto > 0) {
        value = odometerAuto;
      }
    }
  }
  if (event.registration) {
    return (
      <span>
        {event.registration}
        <br />
        {value}
      </span>
    );
  }
  return <span>{value}</span>;
};

const eventSource = event => {
  if (event.source === 'Historic') {
    return event.source;
  }
  if (event.registration) {
    return event.registration;
  }
  if (event.vehicle && event.vehicle.registration) {
    return event.vehicle.registration;
  }
  if (event.device && event.device.name) {
    return event.device.name;
  }
  return event.source;
};

const eventAction = event => {
  const ActionMap = {
    LogoffDriver: 'Driver Logoff',
    VehicleChangeover: 'Vehicle Change',
    LogonDriver: 'Driver Logon'
  };
  return ActionMap[event?.action] || event?.action;
};

const EventAt = (event, driverTimezone, localization) => {
  if (event.entryAt && event.entryAt !== event.eventAt) {
    return (
      <>
        {moment(event.eventAt)
          .tz(driverTimezone)
          .format(localization.formats.time.formats.bdo_hm)}{' '}
        ({TZ2STATE[driverTimezone]})
        <br />
        <small className="text-info">
          Recorded:{' '}
          {moment(event.entryAt)
            .tz(event.timeZone)
            .format(localization.formats.time.formats.bdo_hm)}{' '}
          ({TZ2STATE[event.timeZone]})
        </small>
      </>
    );
  }
  return (
    <>
      {moment(event.eventAt)
        .tz(driverTimezone)
        .format(localization.formats.time.formats.bdo_hm)}{' '}
      ({TZ2STATE[driverTimezone]})
    </>
  );
};

const EventLocation = event => {
  if (!event.location) {
    return <>{event.locationAuto}</>;
  }
  if (
    event.locationAuto !== 'Unable to get Location' &&
    event.locationAuto !== 'Error getting location'
  ) {
    if (event.locationAuto && event.location !== event.locationAuto) {
      return (
        <>
          {event.location}
          <br />
          <small className="text-info">Recorded: {event.locationAuto}</small>
        </>
      );
    }
  }
  return <>{event.location}</>;
};

const EventRow = ({ event, driverTimezone, localization }) => {
  const secondDriver = useEWDDriverById(event?.driver2Id);
  return useMemo(() => {
    const secondDriverDesc = secondDriver
      ? secondDriver.firstName + ' ' + secondDriver.lastName
      : 'None';
    return (
      <>
        <Col className={styles.ColName}>
          {eventAction(event)}
          <LockedIndicator event={event} />
          <FerryIndicator event={event} />
          <EdittedIndicator event={event} />
        </Col>
        <Col className={styles.ColSecondDriver}>{secondDriverDesc}</Col>
        <Col className={styles.ColTimeOfActivity}>
          {EventAt(event, driverTimezone, localization)}
        </Col>
        <Col className={styles.ColRegoOdo}>
          <EventOdometer event={event} />
        </Col>
        <Col className={styles.ColWorkRest}>{event.ruleset}</Col>
        <Col className={styles.ColLocation}>{EventLocation(event)}</Col>
        <Col className={styles.ColComments}>{event.notes}</Col>
        <Col className={styles.ColSource}>{eventSource(event)}</Col>
      </>
    );
  }, [event, driverTimezone, secondDriver]);
};

const EWDTable = () => {
  const dispatch = useDispatch();
  const ewdEvents = useEvents();
  const driverTimezone = useDriverTimezone();
  const [open, setOpen] = useState({});
  const fetching = useSelector(state => state.ewd.events.status.fetching);
  const userAuth = useSelector(state => state.user.currentEWDUser)?.auth?.token;
  const EWDDriverId = useEwdDriverId();
  const localization = useLocalization();

  const fromDate = useSelector(state => state.ewd.period.fromDate);
  const toDate = useSelector(state => state.ewd.period.toDate);

  const events = useMemo(() => {
    if (Array.isArray(ewdEvents)) {
      return cloneDeep(ewdEvents)
        .reverse()
        .filter(
          event =>
            event.eventAt >=
              moment(fromDate)
                .startOf('day')
                .valueOf() &&
            event.eventAt <
              // We check midnight to midnight so add a day then zero it
              moment(toDate)
                .add(1, 'day')
                .startOf('day')
                .valueOf()
        );
    }
    return [];
  }, [ewdEvents, fromDate, toDate]);

  useEffect(() => {
    if (EWDDriverId > 0 && userAuth && events?.length === 0 && fetching === actionTypes.init) {
      dispatch(fetchEvents(EWDDriverId));
    }
  }, [EWDDriverId, dispatch, events, fetching, userAuth]);

  const debouncedHover = debounce((action, Id, dispatch) => {
    if (action === 'enter') {
      dispatch(setEventId('show#!event-' + Id));
    }
    if (action === 'leave') {
      dispatch(setEventId('hide#!event-' + Id));
    }
  }, 100);

  const hoverHandler = (action, Id) => {
    debouncedHover(action, Id, dispatch);
  };

  const handleClick = id => {
    let openClone = { ...open };
    openClone[id] = !openClone[id];
    setOpen(openClone);
  };

  return (
    <>
      <Row className="table-row">
        <Col className="table-row-col">
          <Row className="table-header-row">
            <Col className={styles.ColName}>Activity</Col>
            <Col className={styles.ColSecondDriver}>Second Driver</Col>
            <Col className={styles.ColTimeOfActivity}>Time of Activity</Col>
            <Col className={styles.ColRegoOdo}>
              Registration
              <br /> & Odometer
            </Col>
            <Col className={styles.ColWorkRest}>
              Work & Rest
              <br /> Option
            </Col>
            <Col className={styles.ColLocation}>Location</Col>
            <Col className={styles.ColComments}>Comments</Col>
            <Col className={styles.ColSource}>Origin</Col>
            <Col className={styles.ColActions}></Col>
          </Row>
        </Col>
      </Row>
      <Row className="table-row">
        <Col className="table-row-col">
          {events &&
            events.map((event, index) => (
              <Row
                className={
                  open[event.id]
                    ? 'table-body-row table-body-row--active align-items-center'
                    : 'table-body-row align-items-center'
                }
                key={index}
                onMouseEnter={() => hoverHandler('enter', event.id)}
                onMouseLeave={() => hoverHandler('leave', event.id)}
              >
                <EventRow
                  event={event}
                  driverTimezone={driverTimezone}
                  localization={localization}
                />
                <Col className={styles.ColActions}>
                  <DropdownButton
                    id={event.id}
                    handleClick={() => handleClick(event.id)}
                    open={open[event.id] ? true : false}
                  />
                </Col>
                {open[event.id] && (
                  <Col xs={12}>
                    <EWDEventTabs event={event} localization={localization} />
                  </Col>
                )}
              </Row>
            ))}
        </Col>
      </Row>
    </>
  );
};

export default EWDTable;
