import React from 'react';
import * as Yup from 'yup';
import { TabNavLink } from 'components/nav/NavLinks';
import { getRegistrationTerritoryLabel } from 'features/localization/localization';
import { getRegistrationCountryLabel } from 'features/localization/localization';
import i18n from 'i18next';
import { format } from 'utils/dates';
import { Link } from 'react-router-dom';

export const PATHS = {
  VEHICLE_DEFAULT: '/settings/vehicles',
  VEHICLE_ADD: '/settings/vehicles/newVehicle',
  VEHICLE_VIEW: '/settings/vehicles/id',
  VEHICLE_EDIT: '/settings/vehicles/edit/id',
  VEHICLE_AUDITS: '/settings/vehicles/audit/id'
};

export const GPIOStatus = {
  On: { id: 1, key: 'GPIO.Status.On', color: '#52C41A' },
  Off: { id: 2, key: 'GPIO.Status.Off', color: '#A1A1A1' }
};

export const GPIOOccurrence = {
  Always: { id: 1, key: 'GPIO.Occurrence.Always' },
  IgnitionOn: { id: 2, key: 'GPIO.Occurrence.IgnitionOn' },
  IgnitionOff: { id: 3, key: 'GPIO.Occurrence.IgnitionOff' },
  Disable: { id: 4, key: 'GPIO.Occurrence.Disable' }
};

export const VehicleConfig = {
  OdometerForMnt: {
    key: 'maintenance.odometer.source', //key for PUT/POST
    value: 'maintenance.odometer' //value for GET
  }
};

export const VehicleTypesStatus = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED'
};

export const TABS = {
  all: 'all',
  assigned: 'assigned',
  standalone: 'standalone',
  deleted: 'deleted'
};

export const initialValues = {
  name: '',
  companyId: '',
  fleets: [],
  type: '',
  manufactureMonth: '',
  manufactureYear: '',
  registrationState: '',
  registrationCountry: '',
  registration: '',
  vin: '',
  make: '',
  model: '',
  devices: [],
  engineProfile: {},
  externalId: '',
  note: '',
  engineSummarySource: 'GPS_DIFF',
  serviceOn: '',
  engineHours: '',
  engineNumber: '',
  odometer: '',
  readingsOn: '',
  event: '',
  lastRWCDate: '',
  lastRWCNotes: '',
  registrationRenewalDate: '',
  registrationRenewalNotes: '',
  insuranceDate: '',
  insuranceNotes: '',
  permitDate: '',
  permitNotes: '',
  iacDate: '',
  iacNotes: '',
  field1Date: '',
  field1Notes: '',
  field2Date: '',
  field2Notes: '',
  field3Date: '',
  field3Notes: ''
};

export const TabsFilters = ({ setFilterTab }) => {
  const VehiclesTabs = {
    All: `${i18n.t('Vehicles.All')}`,
    Assigned: `${i18n.t('Vehicles.Assigned')}`,
    Standalone: `${i18n.t('Vehicles.Standalone')}`,
    Deleted: `${i18n.t('Vehicles.Deleted')}`
  };
  return (
    <>
      <TabNavLink
        to={`/settings/vehicles/${TABS.all}`}
        isActive={(match, location) => {
          return ['/', '/settings', '/settings/vehicles', '/settings/vehicles/all'].includes(
            location.pathname
          );
        }}
        onClick={() => {
          setFilterTab(TABS.all);
        }}
      >
        {VehiclesTabs.All}
      </TabNavLink>
      <TabNavLink
        exact
        to={`/settings/vehicles/${TABS.assigned}`}
        onClick={() => {
          setFilterTab(TABS.assigned);
        }}
      >
        {VehiclesTabs.Assigned}
      </TabNavLink>
      <TabNavLink
        exact
        to={`/settings/vehicles/${TABS.standalone}`}
        onClick={() => {
          setFilterTab(TABS.standalone);
        }}
      >
        {VehiclesTabs.Standalone}
      </TabNavLink>
      <TabNavLink
        exact
        to={`/settings/vehicles/${TABS.deleted}`}
        onClick={() => {
          setFilterTab(TABS.deleted);
        }}
      >
        {VehiclesTabs.Deleted}
      </TabNavLink>
    </>
  );
};

export const validationSchema = (
  allVehicleNames,
  initialValues,
  allVehicleExternalIds,
  t,
  eld,
  countryCode
) => {
  let validations = Yup.object().shape({
    name: Yup.string()
      .required(t('Vehicles.VehicleNameRequired'))
      .matches(/\S/, i18n.t('Vehicles.VehicleNameRequired'))
      .max(50, t('Vehicles.VehicleNameTooLong'))
      .test(
        'Unique',
        i18n.t('Vehicles.Notifications.IdenticalName'),
        values => !allVehicleNames.includes(values) || values === initialValues?.name
      ),
    companyId: Yup.string().required(t('Vehicles.CompanyRequired')),
    type: Yup.string().required(t('Vehicles.VehicleTypeRequired')),
    externalId: Yup.string().test(
      'Unique',
      i18n.t('Vehicles.Notifications.IdenticalExternalId'),
      values =>
        !allVehicleExternalIds.includes(values) ||
        values === initialValues?.externalId ||
        values === undefined
    ),
    registrationCountry: Yup.lazy(val =>
      Yup.string().required(`${getRegistrationCountryLabel(val, true)}`)
    ),
    registrationState: Yup.string().when('registrationCountry', (val, schema) => {
      return schema.required(`${getRegistrationTerritoryLabel(countryCode || val, true)}`);
    }),
    registration: Yup.string()
      .required(t('Vehicles.RNRequired'))
      .matches(/\S/, i18n.t('Vehicles.RNRequired'))
  });
  if (eld) {
    validations = validations.shape({
      eldVehicle: Yup.boolean(),
      vin: Yup.string().when('eldVehicle', {
        is: eldVehicle => !!eldVehicle,
        then: Yup.string(),
        otherwise: Yup.string()
          .matches(/(^-?((?=[^IOQ])[a-zA-Z0-9]){17}$)|^$|^$/, t('Vehicles.VINMatches'))
          .test('validVin', t('Vehicles.InvalidVIN'), val => {
            if (!val) {
              return true;
            }
            let vinStr = val.toUpperCase();
            if (vinStr[0] === '-') {
              vinStr = vinStr.substr(1);
            }
            let checkDigit = 0;
            let checkSum = 0;
            let weight = 0;
            for (let i = 0; i < vinStr.length; i++) {
              let result = 0;
              let curChar = vinStr[i];
              let index = i + 1;
              if (!isNaN(parseInt(curChar))) {
                result = parseInt(curChar);
              } else {
                switch (curChar) {
                  case 'A':
                  case 'J':
                    result = 1;
                    break;
                  case 'B':
                  case 'K':
                  case 'S':
                    result = 2;
                    break;
                  case 'C':
                  case 'L':
                  case 'T':
                    result = 3;
                    break;
                  case 'D':
                  case 'M':
                  case 'U':
                    result = 4;
                    break;
                  case 'E':
                  case 'N':
                  case 'V':
                    result = 5;
                    break;
                  case 'F':
                  case 'W':
                    result = 6;
                    break;
                  case 'G':
                  case 'P':
                  case 'X':
                    result = 7;
                    break;
                  case 'H':
                  case 'Y':
                    result = 8;
                    break;
                  case 'R':
                  case 'Z':
                    result = 9;
                    break;
                  default:
                    break;
                }
              }

              if ((index >= 1 && index <= 7) || index === 9) {
                weight = 9 - index;
              } else if (index === 8) {
                weight = 10;
              } else if (index >= 10 && index <= 17) {
                weight = 19 - index;
              }

              if (index === 9) {
                checkDigit = curChar === 'X' ? 10 : curChar.charCodeAt(0) - 48;
              }

              checkSum += result * weight;
            }
            return checkSum % 11 === checkDigit;
          })
      })
    });
  }
  return validations;
};

export const months = t => {
  return [
    { label: t('Common.January'), value: 1 },
    { label: t('Common.February'), value: 2 },
    { label: t('Common.March'), value: 3 },
    { label: t('Common.April'), value: 4 },
    { label: t('Common.May'), value: 5 },
    { label: t('Common.June'), value: 6 },
    { label: t('Common.July'), value: 7 },
    { label: t('Common.August'), value: 8 },
    { label: t('Common.September'), value: 9 },
    { label: t('Common.October'), value: 10 },
    { label: t('Common.November'), value: 11 },
    { label: t('Common.December'), value: 12 }
  ];
};

export const years = [];
for (let year = new Date().getFullYear(); year > 1990; year--) {
  years.push({ label: year, value: year });
}

export const vehicleMetersColumns = t => {
  return [
    {
      title: t('Devices.Meters.MeterType'),
      dataIndex: 'meterType'
    },
    {
      title: t('Devices.Meters.Source'),
      dataIndex: 'source'
    },
    {
      title: t('Common.Type'),
      dataIndex: 'type'
    },
    {
      title: t('Devices.Meters.Value'),
      dataIndex: 'value'
    },
    {
      title: t('Vehicles.LastReadingAt'),
      dataIndex: 'lastReadingAt'
    },
    {
      title: t('Devices.Meters.RebasedAt'),
      dataIndex: 'rebasedAt'
    },
    {
      title: t('Devices.Meters.RebasedBy'),
      dataIndex: 'rebasedBy'
    }
  ];
};

export const METER_TYPES = {
  ODOMETER: 'odometer',
  HOURS: 'hours',
  FUEL: 'fuel',
  TEMPERATURE: 'temperature',
  BATTERY: 'battery',
  CHARGE: 'charge',
  GPIO: 'gpio'
};

export const NO_OF_COLUMNS = 23;
export const MAXIMUM_USER_DEFINED_FIELD = 8;
export const columnWidth = Array.apply(null, { length: NO_OF_COLUMNS }).map(() => ({ wch: 25 }));

export const ACTIONS = {
  ADD: 'add',
  EDIT: 'edit'
};

export const getMakeModalFormConfig = translate => {
  return [
    {
      name: 'make',
      label: translate('Vehicles.Form.Make'),
      rules: [
        {
          required: true,
          message: translate('Vehicles.VehicleMakeRequired')
        },
        {
          pattern: new RegExp(/^[^!@#$%^&*()_+=\-[\]{}|\\:;"'<>,.?/~` ]/),
          message: translate('Vehicles.InvalidVehicleMakeFormat')
        }
      ],
      childProp: {
        type: 'input',
        placeholder: translate('WiFi.Form.TypeHere')
      }
    },
    {
      name: 'model',
      label: translate('Vehicles.Form.Model'),
      rules: [
        {
          required: true,
          message: translate('Vehicles.VehicleModelRequired')
        },
        {
          pattern: new RegExp(/^[^!@#$%^&*()_+=\-[\]{}|\\:;"'<>,.?/~` ]/),
          message: translate('Vehicles.InvalidVehicleModelFormat')
        }
      ],
      childProp: {
        type: 'input',
        placeholder: translate('WiFi.Form.TypeHere')
      }
    },
    {
      name: 'yearOfManufacture',
      label: translate('Vehicles.Form.Year'),
      rules: [
        {
          required: true,
          message: translate('Vehicles.ManufactureYearValid')
        },
        {
          pattern: new RegExp(/^[1-9]\d*$/),
          message: translate('Vehicles.InvalidManufactureYearFormat')
        }
      ],
      childProp: {
        type: 'inputNumber',
        placeholder: translate('WiFi.Form.TypeHere')
      }
    }
  ];
};

export const vehicleAgreementColumns = (t, localization) => {
  return [
    {
      title: t('Agreement.WorkOrder'),
      dataIndex: 'workOrder'
    },
    {
      title: t('Agreement.ContractEndDate'),
      dataIndex: 'contractEndAt',
      render: (id, record) => (
        <span>{format(new Date(record.contractEndAt), localization.formats.time.formats.dby)}</span>
      )
    },
    {
      title: t('Agreement.Description'),
      dataIndex: 'description'
    },
    {
      title: t('Agreement.SubscriptionPack'),
      dataIndex: ['subscriptionPack', 'name']
    },
    {
      title: t('Agreement.Device'),
      dataIndex: ['device', 'imei'],
      render: (id, record) => (
        <Link to={`/settings/devices/id/${record.device.id}`}>{record.device.imei}</Link>
      )
    }
  ];
};
