import React from 'react';

import { useActiveBrand } from 'features/brands/useActiveBrand';

import styles from './Login.module.scss';
import { Image } from 'antd';

export const LoginLogo = ({ ...props }) => {
  const brand = useActiveBrand();
  const product = brand?.product ? brand.product : 'TN360';

  return (
    <div className={styles.logo} {...props}>
      {!!brand?.name ? (
        <Image
          width={brand?.logos?.login?.width}
          height={brand?.logos?.login?.height}
          src={brand?.logos?.login?.source}
          preview={false}
        />
      ) : (
        <div className={styles.img} />
      )}
      {(!brand || brand?.showProductName) && <p>{product}</p>}
    </div>
  );
};
