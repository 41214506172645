import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { keyBy } from 'lodash';
import {
  status,
  loadingFailed,
  loadingStarted,
  loadingFinished,
  actionTypes
} from 'utils/reduxFetchingUtils';
import { ewdAPI } from 'features/ewd/endpoints/ewdAPI';
import moment from 'moment';

export const initialState = {
  annotations: {
    byID: {},
    status: status
  }
};

const annotationsSlice = createSlice({
  name: 'ewdAnnotations',
  initialState: initialState,
  reducers: {
    fetchAnnotationsFailure(state, { payload }) {
      state.annotations.status = loadingFailed(state, payload);
    },
    fetchAnnotationsStart(state, { payload }) {
      state.annotations.status = loadingStarted(state, payload);
    },
    fetchAnnotationsSuccess(state, { payload }) {
      state.annotations.byID = keyBy(payload, 'id');
      state.annotations.status = loadingFinished(state, payload);
    }
  }
});

export const {
  fetchAnnotationsStart,
  fetchAnnotationsSuccess,
  fetchAnnotationsFailure
} = annotationsSlice.actions;

export const fetchAnnotations = driverId => (dispatch, getState) => {
  const fetching = getState().ewd.annotations.status.fetching;
  const jwt = getState().user?.currentEWDUser?.auth?.token;
  const from = getState().ewd.period.fromDate;
  const to = getState().ewd.period.toDate;
  const driverTimezone = getState().ewd.currentDriver?.timeZone || 'Australia/Sydney';

  if (jwt && fetching !== actionTypes.processing && from && to) {
    try {
      const dateFrom = moment
        .tz(moment(from).format('YYYY-MM-DDTHH:mm:ss'), driverTimezone)
        .format();
      const dateTo = moment.tz(moment(to).format('YYYY-MM-DDTHH:mm:ss'), driverTimezone).format();

      dispatch(fetchAnnotationsStart());
      const req = ewdAPI.driverAnnotations(driverId, dateFrom, dateTo);
      req
        .then(result => {
          dispatch(fetchAnnotationsSuccess(result));
        })
        .catch(error => {
          dispatch(fetchAnnotationsFailure(error.toString()));
        });
    } catch (err) {
      debugger;
      dispatch(fetchAnnotationsFailure(err.toString()));
    }
  }
};

export const useAnnotations = () => Object.values(useSelector(state => state.ewd.annotations.byID));

export default annotationsSlice.reducer;
