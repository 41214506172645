import React, { useState } from 'react';
import { Card, Badge } from 'antd';
import EWDStatus from './status/EWDStatus';
import EWDViolations from './violations/EWDViolations';
import { useViolations } from './violations/violationsSlice';

export const StatusAndViolationsTabs = () => {
  const [activeTab, setActiveTab] = useState('status');
  const violationsCount = useViolations()?.violations?.length ?? 0;

  const ViolationsLabel = (
    <Badge count={violationsCount} offset={[10, 0]}>
      Violations
    </Badge>
  );

  const tabList = [
    {
      key: 'status',
      tab: 'Current Status'
    },
    {
      key: 'violations',
      tab: ViolationsLabel
    }
  ];

  const tabContent = {
    status: <EWDStatus />,
    violations: <EWDViolations />
  };
  return (
    <Card
      style={{ width: '100%', height: '100%', overflow: 'hidden' }}
      tabList={tabList}
      activeTabKey={activeTab}
      onTabChange={key => {
        setActiveTab(key);
      }}
      bodyStyle={{
        width: '100%',
        height: 'calc(100% - 56px)',
        overflow: 'hidden',
        padding: '0'
      }}
    >
      {tabContent[activeTab]}
    </Card>
  );
};
