import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import { PATHS } from './constants';
import { SPEEDASSIST } from './SPEEDASSIST';
import { Devices } from './Devices';
import { SpeedAssistForm } from './SpeedAssistForm';
import { SpeedAssistView } from './SpeedAssistView';
import { SpeedAssistAudits } from './SpeedAssistAudits';

export const SPEEDASSISTRouter = () => {
  const history = useHistory();
  const pathnameSegments = history.location.pathname.split('/');
  const id = pathnameSegments[pathnameSegments.length - 1];
  return (
    <Switch>
      <Route path={PATHS.SPEEDASSIST_CONFIGURED_ASSIGNED}>
        <Devices tab="assigned" />
      </Route>
      <Route path={PATHS.SPEEDASSIST_CONFIGURED_UNASSIGNED}>
        <Devices tab="unassigned" />
      </Route>
      <Route path={PATHS.SPEEDASSIST_CONFIGURED}>
        <Devices />
      </Route>
      <Route path={PATHS.SPEEDASSIST_VIEW}>
        <SpeedAssistView />
      </Route>
      <Route path={PATHS.SPEEDASSIST_DELETED}>
        <SPEEDASSIST tab="deleted" />
      </Route>
      <Route path={PATHS.SPEEDASSIST_ADD}>
        <SpeedAssistForm action="add" />
      </Route>{' '}
      <Route path={PATHS.SPEEDASSIST_EDIT}>
        <SpeedAssistForm action="edit" templateId={parseInt(id)} />
      </Route>
      <Route path={PATHS.SPEEDASSIST_AUDIT}>
        <SpeedAssistAudits />
      </Route>
      <Route path={PATHS.SPEEDASSIST_DEFAULT}>
        <SPEEDASSIST />
      </Route>
    </Switch>
  );
};
