import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useLayoutEffect,
  useReducer
} from 'react';

import styles from './ReportsStandard.module.scss';
import { ListGroup, Card } from 'react-bootstrap';
import { AuthUtil } from 'features/auth/authUtil';
import { Tooltip } from 'antd';
import { useUser } from 'features/user/userSlice';
import { Switch, Route, useHistory } from 'react-router-dom';
import { Iframe } from 'components/iframe/Iframe';
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle } from 'features/page/pageSlice';
import { useWalkMeInFrame } from 'features/walkme/walkmeHooks';
import { useDevices } from 'features/fleets/fleetsSlice.js';
import i18n from '../../i18nextConfig';
import { useCurrentCompany } from 'features/company/companySlice';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { Can, services, GlobalRoles, companyFeatures } from 'features/permissions';

const ReportMaps = [
  {
    get categoryTitle() {
      return i18n.t('Reports.General');
    },
    sectionLink: '#generalSection',
    requireServices: [
      services.COMMON,
      services.IAP,
      services.SWD,
      services.OBM,
      services.VOIP,
      services.CAMERA,
      services.TRACKING,
      services.QUICKJOBS,
      services.MASS,
      services.PRETRIP,
      services.DMGT,
      services.DURESS,
      services.FORM,
      services.IAP,
      services.MESSAGING,
      services.TOLL,
      services.RUC
    ],
    reports: [
      {
        get title() {
          return i18n.t('Reports.Agreement');
        },
        get description() {
          return i18n.t('Reports.AgreementDescription');
        },
        link: '/ng/reports/agreement',
        requireServices: [services.COMMON],
        requireRoles: [GlobalRoles.SiteAdmin]
      },
      {
        get title() {
          return i18n.t('Reports.Alarm');
        },
        get description() {
          return i18n.t('Reports.AlarmDescription');
        },
        link: '/ng/reports/alarm',
        requireServices: [services.COMMON, services.IAP, services.SWD, services.EWD, services.EDL],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
      },
      {
        get title() {
          return i18n.t('Reports.Alert Notification');
        },
        get description() {
          return i18n.t('Reports.Alert NotificationDescription');
        },
        link: '/ng/reports/alert_notification',
        requireServices: [services.COMMON],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
      },
      {
        get title() {
          return i18n.t('Reports.Automated Mass');
        },
        get description() {
          return i18n.t('Reports.Automated MassDescription');
        },
        link: '/ng/reports/automated_mass',
        requireServices: [services.OBM]
      },
      {
        get title() {
          return i18n.t('Reports.CallMate Usage');
        },
        get description() {
          return i18n.t('Reports.CallMate UsageDescription');
        },
        link: '/ng/reports/callmate',
        requireServices: [services.VOIP]
      },
      {
        get title() {
          return i18n.t('Reports.Camera Event');
        },
        get description() {
          return i18n.t('Reports.Camera EventDescription');
        },
        link: '/ng/reports/camera',
        requireServices: [services.CAMERA]
      },
      {
        get title() {
          return i18n.t('Reports.Data Pack Usage');
        },
        get description() {
          return i18n.t('Reports.Data Pack UsageDescription');
        },
        link: '/ng/reports/data_pack_usage',
        requireServices: [services.COMMON],
        requireRoles: [GlobalRoles.SiteAdmin]
      },
      {
        get title() {
          return i18n.t('Reports.Distance Trip');
        },
        get description() {
          return i18n.t('Reports.Distance TripDescription');
        },
        link: '/ng/reports/distance_trip',
        requireServices: [services.TRACKING],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Distance Trip Detail');
        },
        get description() {
          return i18n.t('Reports.Distance Trip DetailDescription');
        },
        link: '/ng/reports/distance_trip_detail',
        requireServices: [services.TRACKING],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Driver Action');
        },
        get description() {
          return i18n.t('Reports.Driver ActionDescription');
        },
        link: '/ng/reports/driver_action',
        requireServices: [
          services.COMMON,
          services.QUICKJOBS,
          services.SWD,
          services.EWD,
          services.EDL,
          services.MASS,
          services.PRETRIP,
          services.TACHO
        ],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
      },
      {
        get title() {
          return i18n.t('Reports.Driver Management Summary');
        },
        get description() {
          return i18n.t('Reports.Driver Management SummaryDescription');
        },
        link: '/ng/reports/driver_management_summary',
        requireServices: [services.DMGT]
      },
      {
        get title() {
          return i18n.t('Reports.Driver Summary');
        },
        get description() {
          return i18n.t('Reports.Driver SummaryDescription');
        },
        link: '/ng/reports/driver_summary',
        requireServices: [services.DMGT]
      },
      {
        get title() {
          return i18n.t('Reports.Duress');
        },
        get description() {
          return i18n.t('Reports.DuressDescription');
        },
        link: '/ng/reports/duress',
        requireServices: [services.TRACKING],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Form');
        },
        get description() {
          return i18n.t('Reports.FormDescription');
        },
        link: '/ng/reports/form',
        requireServices: [services.FORM],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
      },
      {
        get title() {
          return i18n.t('Reports.GPIO');
        },
        get description() {
          return i18n.t('Reports.GpioDescription');
        },
        link: '/ng/reports/gpio',
        requireServices: [services.TRACKING, services.GPIO],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.IAP Mass Declaration');
        },
        get description() {
          return i18n.t('Reports.IAP Mass DeclarationDescription');
        },
        link: '/ng/reports/iap_mass_declaration',
        requireServices: [services.IAP],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
      },
      {
        get title() {
          return i18n.t('Reports.IAP Comment Declaration');
        },
        get description() {
          return i18n.t('Reports.IAP Comment DeclarationDescription');
        },
        link: '/ng/reports/iap_comment_declaration',
        requireServices: [services.IAP],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
      },
      {
        get title() {
          return i18n.t('Reports.Last Contact');
        },
        get description() {
          return i18n.t('Reports.Last ContactDescription');
        },
        link: '/ng/reports/last_contact',
        requireServices: [services.COMMON],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
      },
      {
        get title() {
          return i18n.t('Reports.Message History');
        },
        get description() {
          return i18n.t('Reports.Message HistoryDescription');
        },
        link: '/ng/reports/message_history',
        requireServices: [services.MESSAGING]
      },
      {
        get title() {
          return i18n.t('Reports.Movement');
        },
        get description() {
          return i18n.t('Reports.MovementDescription');
        },
        get tooltip() {
          return i18n.t('Reports.NotAvailableForAllDevices');
        },
        link: '/ng/reports/movement',
        requireServices: [services.TRACKING],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.OutOfHours');
        },
        get description() {
          return i18n.t('Reports.OutOfHoursDescription');
        },
        link: '/ng/reports/out_of_hours',
        requireServices: [services.TRACKING],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Road Network Usage');
        },
        get description() {
          return i18n.t('Reports.Road Network UsageDescription');
        },
        link: '/ng/reports/road_network_usage',
        requireServices: [services.TOLL]
      },
      {
        get title() {
          return i18n.t('Reports.RUC Distance');
        },
        get description() {
          return i18n.t('Reports.RUC DistanceDescription');
        },
        link: '/ng/reports/ruc_distance',
        requireServices: [services.RUC]
      },
      {
        get title() {
          return i18n.t('Reports.Scorecard Progressive Trend');
        },
        get description() {
          return i18n.t('Reports.Scorecard Progressive Trend Description');
        },
        link: '/ng/reports/scorecard_progressive_trend',
        requireServices: [services.SCORECARD],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Scorecard Summary');
        },
        get description() {
          return i18n.t('Reports.Scorecard Summary Description');
        },
        link: '/ng/reports/scorecard_summary',
        requireServices: [services.SCORECARD],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Security Events Audit');
        },
        get description() {
          return i18n.t('Reports.Security Events AuditDescription');
        },
        link: '/ng/reports/security_events_audit',
        requireServices: [services.COMMON],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
      },
      {
        get title() {
          return i18n.t('Reports.State Mileage');
        },
        get description() {
          return i18n.t('Reports.State MileageDescription');
        },
        link: '/ng/reports/state_mileage',
        requireServices: [services.TRACKING],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
      },
      {
        get title() {
          return i18n.t('Reports.Timesheet');
        },
        get description() {
          return i18n.t('Reports.Timesheet Report Description');
        },
        link: '/ng/reports/timesheet',
        requireServices: [services.TRACKING],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
      },
      {
        get title() {
          return i18n.t('Reports.Vehicle Activity');
        },
        get description() {
          return i18n.t('Reports.Vehicle ActivityDescription');
        },
        link: '/ng/reports/vehicle_activity',
        requireServices: [services.TRACKING],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Vehicle Daily Summary');
        },
        get description() {
          return i18n.t('Reports.Vehicle Daily SummaryDescription');
        },
        link: '/ng/reports/vehicle_daily_summary',
        requireServices: [services.TRACKING],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.User Alert Subscription');
        },
        get description() {
          return i18n.t('Reports.User Alert SubscriptionDescription');
        },
        link: '/ng/reports/user_alert',
        requireServices: [services.COMMON],
        beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Reports.Compliance');
    },
    sectionLink: '#complianceSection',
    requireServices: [
      services.COMPMGMT,
      services.MASS,
      services.ROUTECOMPLIANCE,
      services.PRETRIP,
      services.SPEED
    ],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    reports: [
      {
        get title() {
          return i18n.t('Reports.Compliance Management');
        },
        get description() {
          return i18n.t('Reports.Compliance ManagementDescription');
        },
        link: '/ng/reports/compliances',
        requireServices: [services.COMPMGMT]
      },
      {
        get title() {
          return i18n.t('Reports.DVIR');
        },
        get description() {
          return i18n.t('Reports.DVIR Description');
        },
        link: '/ng/reports/dvir',
        requireServices: [services.DVIR]
      },
      {
        get title() {
          return i18n.t('Reports.Mass Manager');
        },
        get description() {
          return i18n.t('Reports.Mass ManagerDescription');
        },
        link: '/ng/reports/mass_manager',
        requireServices: [services.MASS]
      },
      {
        get title() {
          return i18n.t('Reports.Completed Route Compliance');
        },
        get description() {
          return i18n.t('Reports.Completed Route ComplianceDescription');
        },
        link: '/ng/reports/route_compliance_completed',
        requireServices: [services.ROUTECOMPLIANCE]
      },
      {
        get title() {
          return i18n.t('Reports.Route Compliance Breach');
        },
        get description() {
          return i18n.t('Reports.Route Compliance BreachDescription');
        },
        link: '/ng/reports/route_compliance_breach',
        requireServices: [services.ROUTECOMPLIANCE]
      },
      {
        get title() {
          return i18n.t('Reports.Route Compliance Mass');
        },
        get description() {
          return i18n.t('Reports.Route Compliance MassDescription');
        },
        hide: companyServices => {
          if (
            companyServices?.some(s => s.code === services.ROUTECOMPLIANCE) &&
            companyServices?.some(s => s.code === services.MASS)
          ) {
            return false;
          }
          return true;
        },
        link: '/ng/reports/route_compliance_mass',
        requireServices: [services.ROUTECOMPLIANCE, services.MASS]
      },
      {
        get title() {
          return i18n.t('Reports.Pre-Trip Checklist');
        },
        get description() {
          return i18n.t('Reports.Pre-Trip ChecklistDescription');
        },
        link: '/ng/reports/pretrip_checklist',
        requireServices: [services.PRETRIP]
      },
      {
        get title() {
          return i18n.t('Reports.Speed Event Management');
        },
        get description() {
          return i18n.t('Reports.Speed Event ManagementDescription');
        },
        link: '/ng/reports/speed',
        requireServices: [services.SPEED]
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Users.Sentinel');
    },
    sectionLink: '#sentinelSection',
    requireServices: [services.SWD],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    reports: [
      {
        get title() {
          return i18n.t('Reports.Fatigue Violation');
        },
        get description() {
          return i18n.t('Reports.Fatigue ViolationDescription');
        },
        link: '/ng/reports/fatigue_violation',
        requireServices: [services.SWD, services.EDL]
      },
      {
        get title() {
          return i18n.t('Reports.Fatigue Management Summary');
        },
        get description() {
          return i18n.t('Reports.Fatigue Management SummaryDescription');
        },
        link: '/ng/reports/fatigue_management_summary',
        requireServices: [services.SWD, services.EDL]
      },
      {
        get title() {
          return i18n.t('Reports.Driver Daily Summary');
        },
        get description() {
          return i18n.t('Reports.Driver Daily SummaryDescription');
        },
        link: '/ng/reports/driver_daily_summary',
        requireServices: [services.SWD, services.EDL]
      },
      {
        get title() {
          return i18n.t('Reports.Driver Action Audit');
        },
        get description() {
          return i18n.t('Reports.Driver Action AuditDescription');
        },
        link: '/ng/reports/driver_action_audit',
        requireServices: [services.SWD, services.EDL]
      },
      {
        get title() {
          return i18n.t('Reports.Log Book Check');
        },
        get description() {
          return i18n.t('Reports.Log Book CheckDescription');
        },
        link: '/ng/reports/log_book_check',
        requireServices: [services.SWD, services.EDL]
      },
      {
        get title() {
          return i18n.t('Reports.Driver Hours/Distance');
        },
        get description() {
          return i18n.t('Reports.Driver Hours/DistanceDescription');
        },
        link: '/ng/reports/driver_hours_distance',
        requireServices: [services.SWD, services.EDL]
      },
      {
        get title() {
          return i18n.t('Reports.Enforcement Request');
        },
        get description() {
          return i18n.t('Reports.Enforcement RequestDescription');
        },
        link: '/ng/reports/enforcement_request',
        requireServices: [services.EDL]
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Common.ELDFatigue');
    },
    sectionLink: '#eldSection',
    requireServices: [services.ELD, services.ELDUS, services.ELDCAN],
    reports: [
      {
        get title() {
          return i18n.t('Reports.Driver HOS Available Hours');
        },
        get description() {
          return i18n.t('Reports.Driver HOS Available Hours Description');
        },
        link: '/ng/reports/driver_hos_available_hours',
        requireServices: [services.ELD, services.ELDUS, services.ELDCAN]
      },
      {
        get title() {
          return i18n.t('Reports.Driver HOS Violation');
        },
        get description() {
          return i18n.t('Reports.Driver HOS Violation Description');
        },
        link: '/ng/reports/driver_hos_violation',
        requireServices: [services.ELD, services.ELDUS, services.ELDCAN]
      },
      {
        get title() {
          return i18n.t('Reports.Duty Status Summary');
        },
        get description() {
          return i18n.t('Reports.Duty Status Summary Description');
        },
        link: '/ng/reports/duty_status_summary',
        requireServices: [services.ELD, services.ELDUS, services.ELDCAN]
      },
      {
        get title() {
          return i18n.t('Reports.HOS Compliance (US)');
        },
        get description() {
          return i18n.t('Reports.HOS Compliance Description (US)');
        },
        link: '/ng/reports/hos_compliance',
        requireServices: [services.ELDUS]
      },
      {
        get title() {
          return i18n.t('Reports.HOS Compliance (Canada)');
        },
        get description() {
          return i18n.t('Reports.HOS Compliance Description (Canada)');
        },
        link: '/ng/reports/hos_compliance_ca',
        requireServices: [services.ELDCAN]
      },
      {
        get title() {
          return i18n.t('Reports.Log Edit History');
        },
        get description() {
          return i18n.t('Reports.Log Edit History Description');
        },
        link: '/ng/reports/log_edit_history',
        requireServices: [services.ELD, services.ELDUS, services.ELDCAN]
      },
      {
        get title() {
          return i18n.t('Reports.Unassigned Events Chain of Custody');
        },
        get description() {
          return i18n.t('Reports.Unassigned Events Chain of CustodyDescription');
        },
        link: '/ng/reports/unassigned_events_chain_of_custody',
        requireServices: [services.ELD]
      },
      {
        get title() {
          return i18n.t('Reports.Unsigned Logs');
        },
        get description() {
          return i18n.t('Reports.Unsigned Logs Description');
        },
        link: '/ng/reports/unsigned_logs',
        requireServices: [services.ELD, services.ELDUS, services.ELDCAN]
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Reports.EWD');
    },
    sectionLink: '#ewdSection',
    requireServices: [services.EWD],
    reports: [
      {
        get title() {
          return i18n.t('Reports.EWD Fatigue Violation');
        },
        get description() {
          return i18n.t('Reports.EWD Fatigue ViolationDescription');
        },
        link: '/ng/reports/ewd_fatigue_violation',
        requireServices: [services.EWD]
      },
      {
        get title() {
          return i18n.t('Reports.EWD Fatigue Management Summary');
        },
        get description() {
          return i18n.t('Reports.EWD Fatigue Management SummaryDescription');
        },
        link: '/ng/reports/ewd_fatigue_management_summary',
        requireServices: [services.EWD]
      },
      {
        get title() {
          return i18n.t('Reports.EWD Driver Daily Summary');
        },
        get description() {
          return i18n.t('Reports.EWD Driver Daily SummaryDescription');
        },
        link: '/ng/reports/ewd_driver_daily_summary',
        requireServices: [services.EWD]
      },
      {
        get title() {
          return i18n.t('Reports.EWD Driver Event Audit');
        },
        get description() {
          return i18n.t('Reports.EWD Driver Event AuditDescription');
        },
        link: '/ng/reports/ewd_driver_event_audit',
        requireServices: [services.EWD]
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Reports.Smart Jobs');
    },
    sectionLink: '#smartJobSection',
    requireServices: [services.SMARTJOBS, services.TASKMANAGER],
    reports: [
      {
        get title() {
          return i18n.t('Reports.Runsheet Summary');
        },
        get description() {
          return i18n.t('Reports.Runsheet SummaryDescription');
        },
        link: '/ng/reports/runsheet_summary',
        requireServices: [services.SMARTJOBS]
      },
      {
        get title() {
          return i18n.t('Reports.Non-Delivery');
        },
        get description() {
          return i18n.t('Reports.Non-DeliveryDescription');
        },
        link: '/ng/reports/non_delivery',
        requireServices: [services.SMARTJOBS]
      },
      {
        get title() {
          return i18n.t('Reports.Job Statistic');
        },
        get description() {
          return i18n.t('Reports.Job StatisticDescription');
        },
        link: '/ng/reports/job_statistic',
        requireServices: [services.SMARTJOBS]
      },
      {
        get title() {
          return i18n.t('Reports.Job Notification Usage');
        },
        get description() {
          return i18n.t('Reports.Job Notification UsageDescription');
        },
        link: '/ng/reports/job_notification_usage',
        requireServices: [services.SMARTJOBS]
      },
      {
        get title() {
          return i18n.t('Reports.Drop/Adhoc Summary');
        },
        get description() {
          return i18n.t('Reports.Drop/Adhoc SummaryDescription');
        },
        link: '/ng/reports/job_summary',
        requireServices: [services.SMARTJOBS]
      },
      {
        get title() {
          return i18n.t('Reports.Job KPI');
        },
        get description() {
          return i18n.t('Reports.JobKPIDescription');
        },
        link: '/ng/reports/job_kpi',
        requireServices: [services.SMARTJOBS]
      },
      {
        get title() {
          return i18n.t('Reports.Runsheet Detail');
        },
        get description() {
          return i18n.t('Reports.Runsheet DetailDescription');
        },
        link: '/ng/reports/task_manager_runsheets',
        requireServices: [services.SMARTJOBS]
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Reports.Timesheets');
    },
    sectionLink: '#timesheetsSection',
    requireServices: [services.TIMESHEETS],
    reports: [
      {
        get title() {
          return i18n.t('Reports.Timesheet Audit');
        },
        get description() {
          return i18n.t('Reports.Timesheet AuditDescription');
        },
        link: '/ng/reports/timesheet_audit',
        requireServices: [services.TIMESHEETS]
      },
      {
        get title() {
          return i18n.t('Reports.Timesheet Summary');
        },
        get description() {
          return i18n.t('Reports.Timesheet SummaryDescription');
        },
        link: '/ng/reports/timesheet_summary',
        requireServices: [services.TIMESHEETS]
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Reports.Basic Performance');
    },
    hide: companyServices => {
      if (
        companyServices?.find(s => s.code === services.BPM) &&
        companyServices?.find(s => s.code === services.VPM) === undefined
      ) {
        return false;
      }
      return true;
    },
    sectionLink: '#bpmSection',
    requireServices: [services.BPM, services.VPM],
    reports: [
      {
        get title() {
          return i18n.t('Reports.Excessive Acceleration');
        },
        get description() {
          return i18n.t('Reports.Excessive AccelerationDescription');
        },
        link: '/ng/reports/excessive_acceleration',
        requireServices: [services.BPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Harsh Braking');
        },
        get description() {
          return i18n.t('Reports.Harsh BrakingDescription');
        },
        link: '/ng/reports/harsh_braking',
        requireServices: [services.BPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Idle Time');
        },
        get description() {
          return i18n.t('Reports.Idle TimeDescription');
        },
        link: '/ng/reports/idle_time',
        requireServices: [services.VPM, services.BPM],
        featureHide: companyFeatures.HTF
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Reports.Vehicle Performance');
    },
    sectionLink: '#vpmSection',
    requireServices: [services.VPM, services.PTO],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    featureHide: companyFeatures.HTF,
    reports: [
      {
        get title() {
          return i18n.t('Reports.Diagnostic Trouble Codes');
        },
        get description() {
          return i18n.t('Reports.Diagnostic Trouble CodesDescription');
        },
        link: '/ng/reports/diagnostic_trouble_code',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Excessive Acceleration');
        },
        get description() {
          return i18n.t('Reports.Excessive AccelerationDescription');
        },
        link: '/ng/reports/excessive_acceleration',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Fuel Rate');
        },
        get description() {
          return i18n.t('Reports.Fuel RateDescription');
        },
        link: '/ng/reports/fuel_rate',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Fuel Usage');
        },
        get description() {
          return i18n.t('Reports.Fuel UsageDescription');
        },
        link: '/ng/reports/fuel_usage',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Harsh Braking');
        },
        get description() {
          return i18n.t('Reports.Harsh BrakingDescription');
        },
        link: '/ng/reports/harsh_braking',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Harsh Cornering');
        },
        get description() {
          return i18n.t('Reports.Harsh CorneringDescription');
        },
        get tooltip() {
          return i18n.t('Reports.NotAvailableForAllDevices');
        },
        link: '/ng/reports/harsh_cornering',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Idle Time');
        },
        get description() {
          return i18n.t('Reports.Idle TimeDescription');
        },
        link: '/ng/reports/idle_time',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Impact');
        },
        get description() {
          return i18n.t('Reports.Impact Description');
        },
        link: '/ng/reports/impact',
        requireServices: [services.VPM]
      },
      {
        get title() {
          return i18n.t('Reports.MixedEnergyPerformance');
        },
        get description() {
          return i18n.t('Reports.MixedEnergyPerformance Description');
        },
        link: '/ng/reports/mixed_energy_performance',
        requireServices: [services.VPM]
      },
      {
        get title() {
          return i18n.t('Reports.Over Revving');
        },
        get description() {
          return i18n.t('Reports.Over RevvingDescription');
        },
        link: '/ng/reports/over_revving',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Tyre Pressure');
        },
        get description() {
          return i18n.t('Reports.Tyre PressureDescription');
        },
        link: '/ng/reports/tyre_pressure',
        requireServices: [services.TPMS],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Engine Coolant Over Temperature');
        },
        get description() {
          return i18n.t('Reports.Engine Coolant Over TemperatureDescription');
        },
        link: '/ng/reports/engine_coolant_over_temperature',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Engine Oil Over Temperature');
        },
        get description() {
          return i18n.t('Reports.Engine Oil Over TemperatureDescription');
        },
        link: '/ng/reports/engine_oil_over_temperature',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Engine Low Oil Pressure');
        },
        get description() {
          return i18n.t('Reports.Engine Low Oil PressureDescription');
        },
        link: '/ng/reports/engine_low_oil_pressure',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Power Take Off');
        },
        get description() {
          return i18n.t('Reports.Power Take OffDescription');
        },
        link: '/ng/reports/power_take_off',
        requireServices: [services.PTO],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Trip Odometer');
        },
        get description() {
          return i18n.t('Reports.Trip OdometerDescription');
        },
        link: '/ng/reports/trip_odometer',
        requireServices: [services.VPM],
        requireOtherConditions: [() => !useNotTnDevice()],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.VPM Trip');
        },
        get description() {
          return i18n.t('Reports.VPM TripDescription');
        },
        get tooltip() {
          return i18n.t('Reports.NotAvailableForAllDevices');
        },
        link: '/ng/reports/vpm_trip',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.VPM Period');
        },
        get description() {
          return i18n.t('Reports.VPM PeriodDescription');
        },
        get tooltip() {
          return i18n.t('Reports.NotAvailableForAllDevices');
        },
        link: '/ng/reports/vpm_period',
        requireServices: [services.VPM],
        featureHide: companyFeatures.HTF
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Reports.Geofence');
    },
    sectionLink: '#geofenceSection',
    requireServices: [
      services.GEOFENCE,
      services.GEOFENCETRIP,
      services.SPEED,
      services.SAFERTOGETHER
    ],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    featureHide: companyFeatures.HTF,
    reports: [
      {
        get title() {
          return i18n.t('Reports.Geofence Entry/Exit');
        },
        get description() {
          return i18n.t('Reports.Geofence Entry/ExitDescription');
        },
        link: '/ng/reports/geofence_entry_exit',
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Geofence Overtime');
        },
        get description() {
          return i18n.t('Reports.Geofence OvertimeDescription');
        },
        link: '/ng/reports/geofence_overtime',
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Geofence Undertime');
        },
        get description() {
          return i18n.t('Reports.Geofence UndertimeDescription');
        },
        link: '/ng/reports/geofence_undertime',
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Geofence Speed');
        },
        get description() {
          return i18n.t('Reports.Geofence SpeedDescription');
        },
        link: '/ng/reports/geofence_speed',
        requireServices: [services.SPEED],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Geofence Trip');
        },
        get description() {
          return i18n.t('Reports.Geofence TripDescription');
        },
        link: '/ng/reports/geofence_trip',
        requireServices: [services.GEOFENCETRIP],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Geofence Trip Mass');
        },
        get description() {
          return i18n.t('Reports.Geofence Trip MassDescription');
        },
        link: '/ng/reports/geofence_trip_mass',
        requireServices: [services.GEOFENCETRIP],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Safer Together Event Performance');
        },
        get description() {
          return i18n.t('Reports.Safer Together Event Performance Description');
        },
        link: '/ng/reports/geofence_safertogether',
        requireServices: [services.SAFERTOGETHER],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Safer Together Monthly Performance');
        },
        get description() {
          return i18n.t('Reports.Safer Together Monthly Performance Description');
        },
        link: '/ng/reports/geofence_safertogether_monthly',
        requireServices: [services.SAFERTOGETHER],
        featureHide: companyFeatures.HTF
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Reports.Quick Jobs');
    },
    sectionLink: '#jobReportSection',
    requireServices: [services.QUICKJOBS],
    reports: [
      {
        get title() {
          return i18n.t('Reports.QuickJobs Trip');
        },
        get description() {
          return i18n.t('Reports.QuickJobs TripDescription');
        },
        link: '/ng/reports/quickjobs_trip',
        requireServices: [services.QUICKJOBS]
      },
      {
        get title() {
          return i18n.t('Reports.QuickJobs Delay');
        },
        get description() {
          return i18n.t('Reports.QuickJobs DelayDescription');
        },
        link: '/ng/reports/quickjobs_delay',
        requireServices: [services.QUICKJOBS]
      },
      {
        get title() {
          return i18n.t('Reports.QuickJobs Combined');
        },
        get description() {
          return i18n.t('Reports.QuickJobs CombinedDescription');
        },
        link: '/ng/reports/quickjobs_combined',
        requireServices: [services.QUICKJOBS]
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Reports.FBT');
    },
    sectionLink: '#fbtSection',
    requireServices: [services.FBTMANAGER],
    reports: [
      {
        get title() {
          return i18n.t('Reports.FBT Trip');
        },
        get description() {
          return i18n.t('Reports.FBT Trip SummaryDescription');
        },
        link: '/ng/reports/fbt_trip',
        requireServices: [services.FBTMANAGER]
      },
      {
        get title() {
          return i18n.t('Reports.FBT ELB Summary');
        },
        get description() {
          return i18n.t('Reports.FBT ELB Summary SummaryDescription');
        },
        link: '/ng/reports/fbt_elb_summary',
        requireServices: [services.FBTMANAGER]
      },
      {
        get title() {
          return i18n.t('Reports.FBT Audit');
        },
        get description() {
          return i18n.t('Reports.FBT Audit SummaryDescription');
        },
        link: '/ng/reports/fbt_audit',
        requireServices: [services.FBTMANAGER]
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Reports.Vehicle Maintenance');
    },
    sectionLink: '#vmrSection',
    requireServices: [services.VEHMNT],
    featureHide: companyFeatures.HTF,
    reports: [
      {
        get title() {
          return i18n.t('Reports.Inspection Status');
        },
        get description() {
          return i18n.t('Reports.Inspection Status Description');
        },
        link: '/ng/reports/inspection_status',
        requireServices: [services.DVIR, services.PRETRIP]
      },
      {
        get title() {
          return i18n.t('Reports.Vehicle Maintenance Summary');
        },
        get description() {
          return i18n.t('Reports.Vehicle Maintenance SummaryDescription');
        },
        link: '/ng/reports/vehicle_maintenance_summary',
        requireServices: [services.VEHMNT],
        featureHide: companyFeatures.HTF
      },
      {
        get title() {
          return i18n.t('Reports.Vehicle Summary');
        },
        get description() {
          return i18n.t('Reports.Vehicle SummaryDescription');
        },
        link: '/ng/reports/vehicle_summary',
        requireServices: [services.VEHMNT],
        featureHide: companyFeatures.HTF
      }
    ]
  },
  {
    get categoryTitle() {
      return i18n.t('Reports.Equipment');
    },
    sectionLink: '#equipmentSection',
    requireServices: [services.TRACKING],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    reports: [
      {
        get title() {
          return i18n.t('Reports.Equipment Utilization Report');
        },
        get description() {
          return i18n.t('Reports.Equipment Utilization Report Description');
        },
        link: '/ng/reports/equipment_utilization',
        requireServices: [services.TRACKING]
      },
      {
        get title() {
          return i18n.t('Reports.Work vs Idle RPM Report ');
        },
        get description() {
          return i18n.t('Reports.Work vs Idle RPM Report Description');
        },
        link: '/ng/reports/work_idle_rpm',
        requireServices: [services.VPMHD]
      }
    ]
  }
];

function _ReportSection({ report, className }) {
  const userInfo = useUser();
  const history = useHistory();
  const currentCompany = useCurrentCompany();
  const [reports] = useState(() => {
    const diffLen = 4 - (report.reports.length % 4);
    if (diffLen !== 4) {
      return Array.apply(null, report.reports).concat(new Array(diffLen).fill({}));
    } else {
      return report.reports;
    }
  });

  const handleClick = useCallback(
    report => {
      Mixpanel.sendTrackEvent(MPTrackingEvents.Reports.ReportSelected, {
        reportName: report?.title
      });
      history.push(report.link.replace('/ng/reports', '/reports/catalog'));
    },
    [userInfo]
  );

  return (
    <div id={report.sectionLink.substr(1)} className={className}>
      {reports.map(
        (r, idx) =>
          !r.hide?.(currentCompany?.features) &&
          (r.title ? (
            <Can
              key={idx}
              oneOfServices={r.requireServices}
              everyRole={r.requireRoles}
              everyEntity={r.requireEntities}
              beyondRoles={r.beyondRoles}
              companyConfigs={r.requireCompanyConfigs}
              featureFlag={r.requireFeature}
              featureHide={r.featureHide}
              otherConditions={userInfo.siteAdmin ? null : r.requireOtherConditions}
            >
              <Tooltip title={r.tooltip} placement="top">
                <Card className={styles.card} onClick={() => handleClick(r)}>
                  <Card.Header className={styles.title}>
                    <span>{r.title}</span>
                  </Card.Header>
                  <Card.Body className={styles.body}>
                    <span>{r.description}</span>
                  </Card.Body>
                </Card>
              </Tooltip>
            </Can>
          ) : (
            <Card key={idx} className={styles.emptyCard} />
          ))
      )}
    </div>
  );
}

function _ReportFrame({ type }) {
  const userInfo = useUser();
  const history = useHistory();
  const listGroupRef = useRef(null);
  const frameRef = useRef(null);
  const currentCompany = useCurrentCompany();

  const [clickCount, increaseCount] = useReducer(
    state => {
      return { count: state.count + 1 };
    },
    { count: 0 }
  );

  const url = useMemo(() => {
    const url =
      AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
      `&url=${encodeURIComponent('/ng/reports/' + type)}`;
    return url;
  }, [type, userInfo, clickCount]);

  const handleClick = useCallback(
    rp => {
      Mixpanel.sendTrackEvent(MPTrackingEvents.Reports.ReportSelected, { reportName: rp?.title });
      const link = rp.link;
      const newLink = `/reports/catalog/${link.replace('/ng/reports/', '')}`;
      if (history.location.pathname === newLink) {
        increaseCount();
      } else {
        history.push(newLink);
      }
    },
    [history, increaseCount]
  );

  useLayoutEffect(() => {
    if (listGroupRef.current) {
      const active_node = listGroupRef.current.querySelector('button[class*="active"]');
      if (
        active_node &&
        listGroupRef.current.scrollTop + listGroupRef.current.offsetHeight < active_node.offsetTop
      ) {
        listGroupRef.current.scrollTo(
          0,
          active_node.offsetTop - listGroupRef.current.offsetHeight / 2
        );
      }
    }
  }, [type]);

  useWalkMeInFrame(frameRef);
  return (
    <>
      <div className={styles.nav}>
        <ListGroup className={styles.leftSubNav} ref={listGroupRef}>
          {ReportMaps.map(
            (r, idx) =>
              !r.hide?.(currentCompany?.features) && (
                <Can
                  key={idx}
                  oneOfServices={r.requireServices}
                  beyondRoles={r.beyondRoles}
                  featureHide={r.featureHide}
                >
                  <React.Fragment>
                    <ListGroup.Item className={styles.list_cat_link}>
                      {r.categoryTitle}
                    </ListGroup.Item>
                    {r.reports.map((rp, rp_idx) => (
                      <Can
                        oneOfServices={rp.requireServices}
                        everyRole={rp.requireRoles}
                        everyEntity={rp.requireEntities}
                        beyondRoles={rp.beyondRoles}
                        featureFlag={rp.requireFeature}
                        featureHide={rp.featureHide}
                        key={rp_idx}
                        companyConfigs={rp.requireCompanyConfigs}
                        otherConditions={userInfo.siteAdmin ? false : rp.requireOtherConditions}
                      >
                        <ListGroup.Item
                          action
                          className={
                            styles.list_item_link +
                            ' ' +
                            (rp.link.endsWith(type) ? styles.active : '')
                          }
                          onClick={() => handleClick(rp)}
                        >
                          {rp.title}
                        </ListGroup.Item>
                      </Can>
                    ))}
                  </React.Fragment>
                </Can>
              )
          )}
        </ListGroup>
      </div>
      <Iframe
        ref={frameRef}
        className={styles.reportFrame}
        src={url}
        width="100%"
        height="100%"
      ></Iframe>
    </>
  );
}

function _ReportLanding({ showCategory }) {
  const reportListRef = useRef(null);
  const sectionRef = useRef(null);
  const timeoutHandleRef = useRef(null);

  const handleClick = useCallback(e => {
    e.preventDefault();
    const targetId = e.target.hash;
    const scrollTarget = reportListRef.current.querySelector(targetId);
    if (sectionRef.current) {
      let translateY = 0;
      const translateMatches = reportListRef.current.style.transform.match(
        /(?:translate\(.*?,)(.*?)\)/
      );
      if (translateMatches && translateMatches.length > 1) {
        translateY = parseFloat(translateMatches[1]);
      }

      const selfOffsetTop = reportListRef.current.offsetTop;
      if (translateY !== 0) {
        if (Math.abs(translateY) > selfOffsetTop) {
          translateY = -selfOffsetTop;
        }
      }

      const scrollY =
        scrollTarget.offsetTop - e.target.offsetTop - reportListRef.current.offsetTop - translateY;

      const transformLen =
        sectionRef.current.offsetHeight - (sectionRef.current.scrollHeight - scrollY);

      if (transformLen > 0) {
        reportListRef.current.style.transform = `translate(0, ${-transformLen}px`;
        sectionRef.current.scrollTo(0, scrollY);
      } else {
        reportListRef.current.style.transform = '';
        sectionRef.current.scrollTo(0, scrollY);
      }
    }

    setActiveSection(targetId);
  }, []);
  const [activeSection, setActiveSection] = useState(ReportMaps[0].sectionLink);
  const currentCompany = useCurrentCompany();

  useEffect(() => {
    const nodes = document.getElementsByTagName('section');
    if (nodes.length > 0) {
      sectionRef.current = nodes[0];
      nodes[0].onwheel = e => {
        let translateY = 0,
          prevTranslateY = 0;
        const translateMatches = reportListRef.current.style.transform.match(
          /(?:translate\(.*?,)(.*?)\)/
        );
        if (translateMatches && translateMatches.length > 1) {
          prevTranslateY = translateY = parseFloat(translateMatches[1]);
        }

        let offsetY = translateY;
        const selfOffsetTop = reportListRef.current.offsetTop;
        if (offsetY !== 0) {
          if (Math.abs(offsetY) > selfOffsetTop) {
            offsetY = -selfOffsetTop;
          }
        }

        const lastLink = document.querySelector(`.${styles.nav} .list-group :last-child`);
        const lastSection = document.querySelector(`.${styles.reportList} > div:last-child`);
        let translateLimit =
          lastSection.offsetTop - reportListRef.current.offsetTop - offsetY - lastLink.offsetTop;
        if (
          sectionRef.current.offsetHeight === sectionRef.current.scrollHeight &&
          document.querySelectorAll(`.${styles.nav} .list-group a`).length <= 1
        ) {
          return;
        }

        if (translateY !== 0) {
          translateY -= e.deltaY;
          if (translateY >= 0) {
            reportListRef.current.style.transform = '';
            translateY = 0;
          } else {
            e.preventDefault();
            translateY = -Math.min(Math.abs(translateY), translateLimit);
            reportListRef.current.style.transform = `translate(0, ${translateY}px)`;
          }
        } else if (
          sectionRef.current.scrollTop + sectionRef.current.offsetHeight + e.deltaY >=
          sectionRef.current.scrollHeight
        ) {
          reportListRef.current.style.transform = `translate(0, ${-e.deltaY}px)`;
        }

        if (timeoutHandleRef?.current) {
          clearTimeout(timeoutHandleRef.current);
        }

        timeoutHandleRef.current = setTimeout(() => {
          timeoutHandleRef.current = null;
          for (let i = 0; i < ReportMaps.length; i++) {
            const linkSectionId = ReportMaps[i].sectionLink;
            const reportSectionNode = document.querySelector(linkSectionId);
            const linkNode = document.querySelector(`a[href='${linkSectionId}']`);
            if (reportSectionNode && linkNode) {
              let top = linkNode.getBoundingClientRect().y;
              let bottom = top + linkNode.offsetHeight;

              let sectionTop = reportSectionNode.getBoundingClientRect().y;
              if (Math.abs(translateY) < translateLimit || prevTranslateY !== translateY) {
              } else {
                setActiveSection(lastLink.hash);
                lastLink.focus();
                break;
              }

              let sectionBottom = sectionTop + reportSectionNode.offsetHeight;
              if ((sectionTop >= top && sectionTop <= bottom) || sectionBottom >= bottom) {
                setActiveSection(ReportMaps[i].sectionLink);
                linkNode.focus();
                break;
              }
            }
          }
        }, 150);
      };

      nodes[0].ontouchend = () => {
        reportListRef.current.style.transform = '';
      };
    }
    return () => {
      const nodes = document.getElementsByTagName('section');
      nodes[0].onwheel = null;
      nodes[0].ontouchend = null;
      sectionRef.current = null;
      if (timeoutHandleRef?.current) {
        clearTimeout(timeoutHandleRef.current);
        timeoutHandleRef.current = null;
      }
    };
  }, []);

  return (
    <>
      <div className={styles.nav}>
        <ListGroup activeKey="">
          {showCategory && (
            <ListGroup.Item className={styles.category}>
              <strong>Category</strong>
            </ListGroup.Item>
          )}
          {ReportMaps.map(
            (r, idx) =>
              !r.hide?.(currentCompany?.features) && (
                <Can
                  key={idx}
                  oneOfServices={r.requireServices}
                  beyondRoles={r.beyondRoles}
                  featureHide={r.featureHide}
                >
                  <ListGroup.Item
                    className={
                      styles.link + ' ' + (activeSection === r.sectionLink ? styles.active : '')
                    }
                    action
                    href={r.sectionLink}
                    onClick={handleClick}
                  >
                    {r.categoryTitle}
                  </ListGroup.Item>
                </Can>
              )
          )}
        </ListGroup>
      </div>
      <div className={styles.reportList} ref={reportListRef}>
        {ReportMaps.map(
          (r, idx) =>
            !r.hide?.(currentCompany?.features) && (
              <Can key={idx} oneOfServices={r.requireServices} beyondRoles={r.beyondRoles}>
                <ReportSection
                  className={
                    styles.section + ' ' + (activeSection === r.sectionLink ? styles.active : '')
                  }
                  report={r}
                />
              </Can>
            )
        )}
      </div>
    </>
  );
}

const ReportLanding = React.memo(_ReportLanding);
const ReportSection = React.memo(_ReportSection);
const ReportFrame = React.memo(_ReportFrame);

export function ReportsStandard({ showCategory = false }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageTitle('Reports'));
  }, [dispatch]);

  return (
    <div className={styles.reportContainer}>
      <Switch>
        <Route path="/reports/catalog/:type">
          {({ match }) => <ReportFrame type={match.params.type} />}
        </Route>
        <Route exact path={['/reports', '/reports/catalog']}>
          <ReportLanding showCategory={showCategory} />
        </Route>
      </Switch>
    </div>
  );
}

function useNotTnDevice() {
  const devices = useDevices();
  const companyConfigs = useSelector(state => state.permissions.companyConfig);
  const types = ['Qube', 'Qtanium', 'TM'];
  if (typeof companyConfigs === 'undefined' || companyConfigs['director_qube_customer'] === null) {
    return devices.some(
      device => types.some(t => t === device.type.name) && device.status === 'ENABLED'
    );
  }
  return companyConfigs['director_qube_customer'] === '1';
}
