import React, { useCallback } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { useState } from 'react';
import { AuthApi, ApiClient } from 'nextgen_api';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

import { TextButton } from 'components/buttons/TextButton';
import { USER_TYPES, validationSchema } from 'containers/Administration/Users/constants';

import { API_PATH } from 'config';

import styles from './UpdatePassword.module.scss';
import logo from '../../static/images/tn-logo.png';

const UpdatePasswordStatus = {
  Init: 0,
  Succeeded: 1,
  Failed: 2
};

const UpdatePasswordForm = props => {
  const [newPwd, setNewPwd] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);
  const [confirmNewPwd, setConfirmNewPwd] = useState('');
  const [initial, setInitial] = useState(true);
  const { t } = useTranslation();
  const location = useLocation();

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();

      if (props.onSubmit) {
        props.onSubmit(newPwd.trim());
      }
    },
    [newPwd, confirmNewPwd]
  );

  return (
    <div className={styles.updatePasswordForm}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={styles.formRow} style={{ display: 'flex' }}>
          <label>{t('Login.ResetPassword.NewPassword')}</label>
          <Input
            placeholder={t('Login.ResetPassword.TypePassword')}
            maxlength="25"
            type="password"
            required
            value={newPwd}
            autoComplete="no-pwd-store"
            onChange={async evt => {
              setNewPwd(evt.target.value);
              setInitial(false);
              try {
                const searches = location?.search?.split('&') || [];
                const isELD = searches.some(s => s.toLowerCase() === 'has_eld_service=true');
                let userType = searches
                  .find(s => s.toLowerCase().startsWith('user_type'))
                  ?.split('=')[1]
                  .toUpperCase()
                  .replace(' ', '_');
                userType = USER_TYPES[userType] || USER_TYPES.USER;
                if (evt.target.value.trim() === '') {
                  throw errorMsg;
                }
                await validationSchema(userType, null, isELD).fields.password.validate(
                  evt.target.value
                );
                setErrorMsg(null);
              } catch (err) {
                setErrorMsg(err.message || t('Login.ResetPassword.NewPasswordInCorrect'));
              }
            }}
            tabIndex={1}
          />
          {!initial && newPwd != null && errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
        </div>

        <div className={styles.formRow} style={{ display: 'flex' }}>
          <label>Confirm New Password</label>
          <Input
            type="password"
            placeholder={t('Login.ResetPassword.TypePasswordAgain')}
            maxlength="25"
            required
            value={confirmNewPwd}
            autoComplete="no-pwd-store"
            onChange={evt => {
              setConfirmNewPwd(evt.target.value);
              setInitial(false);
            }}
            tabIndex={2}
          />

          {!initial && newPwd !== confirmNewPwd && (
            <Alert variant="danger">{t('Login.ResetPassword.NewPwdAndConfirmNewPwdNotSame')}</Alert>
          )}
        </div>

        <div className={styles.formFooter} style={{ display: 'flex' }}>
          <TextButton
            disabled={initial || newPwd !== confirmNewPwd || errorMsg != null}
            variant="primary"
            text="Update"
            onClick={handleSubmit}
            tabIndex={3}
            id="btn_submitUpdatePassword"
          />
        </div>
      </div>
    </div>
  );
};

const UpdatePassword = props => {
  const [error, setError] = useState(null);
  const [resetStatus, setResetStatus] = useState(UpdatePasswordStatus.Init);
  const location = useLocation();
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async password => {
      const tokenMatch = location.search.match(/reset_password_token=([0-9a-zA-Z]+)(?:&|$)/);
      if (tokenMatch.length < 2) {
        return;
      }

      let apiClient = new ApiClient();
      apiClient.basePath = API_PATH;
      const authApi = new AuthApi(apiClient);

      const promise = new Promise((resolve, reject) => {
        authApi.resetPassword(
          {
            body: {
              token: tokenMatch[1],
              newPassword: password
            }
          },
          (err, data, resp) => {
            if (err) {
              console.error(resp.body);
              reject(resp.body);
            } else {
              resolve(data);
            }
          }
        );
      });

      try {
        await promise;
        setResetStatus(UpdatePasswordStatus.Succeeded);
      } catch (err) {
        setError(err.message);
        setResetStatus(UpdatePasswordStatus.Failed);
      }
    },
    [location]
  );

  function getTitle() {
    if (resetStatus === UpdatePasswordStatus.Init) {
      return t('Login.ResetPassword.UpdatePassword');
    }
    if (resetStatus === UpdatePasswordStatus.Succeeded) {
      return t('Login.ResetPassword.PasswordUpdated');
    }
    if (resetStatus === UpdatePasswordStatus.Failed) {
      return t('Login.ResetPassword.PasswordUpdateFailed');
    }
  }

  if (!location.search.match(/reset_password_token=[a-zA-Z0-9]+/)) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.commonContainer}>
      <div className={styles.logo}>
        <img src={logo} alt="Telematics 360 Logo" />
        <p>TN360</p>
      </div>
      <p>{getTitle()}</p>
      <div className={styles.form}>
        {error && (
          <Alert variant="danger" className={styles.errorMessage}>
            {error}
          </Alert>
        )}
        {resetStatus !== UpdatePasswordStatus.Succeeded && (
          <UpdatePasswordForm onSubmit={handleSubmit} />
        )}
        <div className={styles.back}>
          <span>{t('Login.ResetPassword.BackTo')} </span>
          <Link to="/login">{t('Sign In').toLowerCase()}</Link>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
