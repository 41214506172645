import { DUTY_STATUS, IGNITION_STATUS } from '../constants';

export const CARD_SIZE = 350;

export const CardViewLens = {
  Pinned: 'Pinned',
  Drivers: 'Drivers',
  Vehicles: 'Vehicles'
};

export const OrderBy = {
  Ascending: 'Ascending',
  Descending: 'Descending'
};

export const DEFAULT_SORT_BY = {
  key: 'deviceStats.location',
  id: 'deviceStats.location',
  name: 'deviceStats.location'
};

const IgnitionOptions = [
  {
    name: 'Ignition.ON',
    label: 'Tracking.Ignition.ON',
    matcher(cardValue) {
      return cardValue === IGNITION_STATUS.ON.status;
    }
  },
  {
    name: 'Ignition.OFF',
    label: 'Tracking.Ignition.OFF',
    matcher(cardValue) {
      return cardValue === IGNITION_STATUS.OFF.status;
    }
  },
  {
    name: 'Ignition.OUT',
    label: 'Tracking.Ignition.OUT',
    matcher(cardValue) {
      return cardValue === IGNITION_STATUS.OUT.status;
    }
  }
];

const DutyStatusOptions = [
  {
    name: 'DutyStatus.On Duty',
    label: 'Tracking.DutyStatus.On Duty',
    matcher(cardValue) {
      return cardValue === DUTY_STATUS.OnDuty.status;
    }
  },
  {
    name: 'DutyStatus.Driving',
    label: 'Tracking.DutyStatus.Driving',
    matcher(cardValue) {
      return cardValue === DUTY_STATUS.Driving.status;
    }
  },
  {
    name: 'DutyStatus.Sleeper Berth',
    label: 'Tracking.DutyStatus.Sleeper Berth',
    matcher(cardValue) {
      return cardValue === DUTY_STATUS.SleeperBerth.status;
    }
  },
  {
    name: 'DutyStatus.Off Duty',
    label: 'Tracking.DutyStatus.Off Duty',
    matcher(cardValue) {
      return cardValue === DUTY_STATUS.OffDuty.status;
    }
  }
];

const CommonFilter = [
  {
    key: 'vehicleName',
    label: 'Tracking.Columns.Vehicle Name',
    name: 'Vehicle Name'
  },
  {
    key: 'driverName',
    label: 'Tracking.Columns.Driver',
    name: 'Driver'
  },
  {
    key: 'deviceStats.location',
    label: 'Tracking.Columns.Last Location',
    name: 'Last Location'
  }
];

const CommonSort = [
  {
    key: 'vehicleType.name',
    id: 'vehicleType.name',
    label: 'Tracking.Columns.Vehicle Type',
    name: 'Vehicle Type'
  },
  {
    key: 'vehicleName',
    id: 'vehicleName',
    label: 'Tracking.Columns.Vehicle Name',
    name: 'Vehicle Name'
  },
  {
    key: 'driverName',
    id: 'driverName',
    label: 'Tracking.Columns.Driver',
    name: 'Driver'
  },
  {
    key: 'deviceStats.lastEventAt',
    id: 'deviceStats.lastEventAt',
    label: 'Tracking.Columns.Last Update',
    name: 'Last Update'
  },
  {
    key: 'deviceStats.location',
    id: 'deviceStats.location',
    label: 'Tracking.Columns.Last Location',
    name: 'Last Location'
  }
];

export const FilterSortField = {
  [CardViewLens.Pinned]: {
    Filter: CommonFilter,
    Sort: CommonSort
  },
  [CardViewLens.Drivers]: {
    Filter: [
      {
        key: 'driverFatiguStatus.fatigueStatus',
        label: 'ELD.Duty Status',
        name: 'Duty Status',
        options: DutyStatusOptions
      },
      ...CommonFilter
    ],
    Sort: [
      {
        key: 'driverFatiguStatus.fatigueStatus',
        id: 'driverFatiguStatus.fatigueStatus',
        label: 'ELD.Duty Status',
        name: 'Duty Status'
      },
      ...CommonSort
    ]
  },
  [CardViewLens.Vehicles]: {
    Filter: [
      {
        key: 'vehicleIgnitionStatus.status',
        label: 'Tracking.Columns.Ignition Status',
        name: 'Ignition Status',
        options: IgnitionOptions
      },
      ...CommonFilter
    ],
    Sort: [
      {
        key: 'vehicleIgnitionStatus.status',
        id: 'vehicleIgnitionStatus.status',
        label: 'Tracking.Columns.Ignition Status',
        name: 'Ignition Status'
      },
      ...CommonSort
    ]
  }
};
