import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../config';
import { ApiClient } from '../../nextgen_api/index';
import { useSelector } from 'react-redux';

export const actionTypes = {
  init: 0,
  pending: 1,
  processing: 2,
  error: 3,
  done: 4
};

const statusType = {
  UDTCompanyStatus: 0
};

function getStatusId(type, id) {
  switch (type) {
    case statusType.UDTCompanyStatus:
      return `udt_status_${id}`;
    default:
      return null;
  }
}

const REST_TIME = 5 * 1000;

export const udt_data = {
  companies: {
    /*structure
    [company id]: {
      [event id]: {
          id: 0,
          driverId: 0,
          deviceId: 0,
          vehicleId: 0,
          startTime: '2020-07-07',
          endTime: '2020-07-07',
          startLocation: '',
          endLocation: ''
          status: 'pending/rejected/unassigned/completed',
          duration: 0,
          comments: '',
          assignmentComments: '',
          route: [
              {
                "valid" : true,
                "Lat" : 39.206809997558594,
                "Lng" : -77.27352142333984,
                "Dir" : 118.0,
                "HDOP" : 1.2998046875,
              }
          ]
      }
    */
  },
  status: {}
};

const udtSlice = createSlice({
  name: 'udtData',
  initialState: udt_data,
  reducers: {
    //fetch udt data by company id
    fetchUDTByCompanyIdStart(state, { payload }) {
      const companyId = payload;
      const statusId = getStatusId(statusType.UDTCompanyStatus, companyId);
      if (statusId != null) {
        state.status[statusId] = {
          fetching: actionTypes.processing,
          fetchingTime: moment().valueOf(),
          error: null
        };
      }
    },
    fetchUDTByCompanyIdSucceeded(state, { payload }) {
      const { companyId, data } = payload;
      const statusId = getStatusId(statusType.UDTCompanyStatus, companyId);
      if (data != null) {
        state.companies[companyId] = data;
      }
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchUDTByCompanyIdFailed(state, { payload }) {
      const { companyId, error } = payload;
      const statusId = getStatusId(statusType.UDTCompanyStatus, companyId);
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.error,
        error: error
      };
    }
  }
});

export const {
  fetchUDTByCompanyIdStart,
  fetchUDTByCompanyIdSucceeded,
  fetchUDTByCompanyIdFailed
} = udtSlice.actions;

export const fetchUDTDataByCompanyId = (companyId, companyKey, fromDate, toDate) => async (
  dispatch,
  getState
) => {
  const statusId = getStatusId(statusType.UDTCompanyStatus, companyId);
  const fetchInfo = getState().udtData.status[statusId];
  const userKey = getState().user?.current?.auth?.key;
  if (
    // TN360WEB-7100: removed 5 seconds validation for fetching time
    // to reload data when user selects different end date.
    (fetchInfo != null && fetchInfo.fetching === actionTypes.processing) ||
    companyKey == null
  ) {
    return;
  }

  dispatch(fetchUDTByCompanyIdStart(companyId));

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const udtUrl = `/sentinel/udt/${companyId}?company_id=${companyId}`;
    apiClient.callApi(
      udtUrl,
      'GET',
      {},
      {
        ...(fromDate && { fromDate: fromDate }),
        ...(toDate && { toDate: toDate })
      },
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchUDTByCompanyIdSucceeded({ companyId: companyId, data: data }));
  } catch (err) {
    const payload = { companyId: companyId, error: err.toString() };
    dispatch(fetchUDTByCompanyIdFailed(payload));
  }
};

export const updateUDT = (
  udtId,
  companyKey,
  udtUpdateObj = { status: '', comments: '', updatedBy: '', assignedUserId: '' }
) => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;

  if (udtId == null || udtId === '' || companyKey == null || companyKey === '') {
    return;
  }

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const udtUrl = `/sentinel/udt/update/${udtId}?company_id=${companyId}`;
    apiClient.callApi(
      udtUrl,
      'POST',
      {},
      {},
      {},
      {},
      {
        ...udtUpdateObj
      },
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    if (data === true) {
      return data;
    } else {
      throw new Error('Update UDT failed.');
    }
  } catch (err) {
    throw err;
  }
};

export const useUDTDataStatus = () => useSelector(state => state.udtData.status);
export const useAllUDTData = () => useSelector(state => state.udtData.companies);

export const useIsFetchingCompanyUDTData = companyId => {
  const udtFetchingStatus = useUDTDataStatus();
  if (companyId == null) return false;

  const fetchingStatus = udtFetchingStatus[getStatusId(statusType.UDTCompanyStatus, companyId)];
  if (
    fetchingStatus?.fetching === actionTypes.pending ||
    fetchingStatus?.fetching === actionTypes.processing
  ) {
    return true;
  }
};

export const useGetCompanyUDTData = companyId => {
  const udtData = useSelector(state => state.udtData.companies[companyId]);
  return udtData;
};

export default udtSlice.reducer;
