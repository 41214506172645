export const prefixObjectKeys = (prefix = '', object = {}, stringify = false) => {
  const prefixed = Object.keys(object).reduce((acc, curr) => {
    acc[`${prefix}${curr}`] = object[curr];
    return acc;
  }, {});

  if (stringify) {
    try {
      return JSON.stringify(prefixed);
    } catch (err) {
      console.error(err);
    }
  }

  return prefixed;
};

export const objectsEqual = (firstObject, secondObject) =>
  firstObject &&
  secondObject &&
  typeof firstObject === 'object' &&
  Object.keys(firstObject).length > 0
    ? Object.keys(firstObject).length === Object.keys(secondObject).length &&
      Object.keys(firstObject).every(p => objectsEqual(firstObject[p], secondObject[p]))
    : firstObject === secondObject;

export const arraysEqual = (firstArray, secondArray) => {
  const firstSortedArrayById = [...firstArray].sort(
    (firstElem, secondElem) => firstElem.id - secondElem.id
  );

  const secondSortedArrayById = [...secondArray].sort(
    (firstElem, secondElem) => firstElem.id - secondElem.id
  );

  return (
    firstSortedArrayById.length === secondSortedArrayById.length &&
    firstSortedArrayById.every((item, index) => objectsEqual(item, secondSortedArrayById[index]))
  );
};
