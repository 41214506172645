import React from 'react';
import { Modal, Form, Col, Select, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import request from 'superagent';
import { API_PATH } from 'config';
import { useUserKey } from 'features/user/userSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

export const ActivateRecoveryModeModel = ({ visible, setShowModal, deviceId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const userKey = useUserKey();
  const dispatch = useDispatch();
  const IQ_B2B_API_PATH = API_PATH.replace('/v1', '/b2b');

  const handleCancel = () => {
    setShowModal(false);
  };

  const onActivate = () => {
    const url = `${IQ_B2B_API_PATH}/digitalmatter/${deviceId}/recovery/activate`;
    request
      .post(url)
      .send(form.getFieldsValue())
      .set('Accept', 'application/json')
      .set('Authorization', `Token token="${userKey}"`)
      .end((err, res) => {
        if (err) {
          let errorMessage = `${t('Tracking.RecoveryMode.FailedToSubmitRecoveryModeActivation')}`;
          if (err.status === 400) {
            errorMessage = `${t('Tracking.RecoveryMode.BadRequest')}`;
          } else if (err.status === 401) {
            errorMessage = `${t('Tracking.RecoveryMode.Unauthorized')}`;
          }
          dispatch(
            openToast({
              message: errorMessage,
              type: ToastType.Error
            })
          );
        } else {
          dispatch(
            openToast({
              message: `${t('Tracking.RecoveryMode.RecoveryModeActivationSubmittedSuccessfully')}`,
              type: ToastType.Success
            })
          );
        }
        setShowModal(false);
      });
  };

  const options = [
    { value: 2, text: 'Tracking.RecoveryMode.2Hours' },
    { value: 6, text: 'Tracking.RecoveryMode.6Hours' },
    { value: 24, text: 'Tracking.RecoveryMode.24Hours' }
  ];

  return (
    <Modal
      title={t('Tracking.RecoveryMode.ActivateRecoveryMode')}
      open={visible}
      okText={t('Tracking.RecoveryMode.Activate')}
      onOk={onActivate}
      cancelButtonProps={{ style: { display: 'none' } }}
      cancelText={t('Common.Modal.Close')}
      onCancel={handleCancel}
      centered
    >
      <label>{t('Tracking.RecoveryMode.ActivateRecoveryDescription')}</label>
      <Form layout="vertical" form={form}>
        <Col>
          <Form.Item initialValue={2} label={t('Tracking.RecoveryMode.Duration')} name="hours">
            <Select>
              {options.map((option, index) => (
                <Select.Option key={index} value={option.value}>
                  {t(option.text)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col>
          <Alert description={t('Tracking.RecoveryMode.WarnMessage')} type="warning" showIcon />
        </Col>
      </Form>
    </Modal>
  );
};
