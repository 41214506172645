import moment from 'moment';
import React from 'react';
import { Marker } from 'react-google-maps';

export const TripMarker = ({ trip, event, onClicked }) => {
  if (!(trip[event] && trip[event].Lat && trip[event].Lng)) return null;

  return (
    <Marker
      position={{
        lat: trip[event].Lat,
        lng: trip[event].Lng
      }}
      icon={{
        url: require('./images/' +
          (event === 'IgnOnGPS' ? 'ignition-on' : 'ignition-off') +
          '.svg'),
        anchor: { x: 16, y: 16 }
      }}
      zIndex={moment().valueOf()}
      clickable={onClicked ? true : false}
      onClick={() => {
        onClicked(trip);
      }}
    />
  );
};
