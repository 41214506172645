import React from 'react';
//hooks
import { useTranslation } from 'react-i18next';
//components
import { Button } from 'antd';
import unavailableLinkImage from 'static/images/link-unavailable.png';

const NotAvailableLink = ({ failedDetailsCall, styles }) => {
  const { t } = useTranslation();

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className={styles.expiredSharedLinkContainer}>
      <div
        className={`${styles.expiredSharedLinkText} ${
          failedDetailsCall
            ? styles.marginBottomWithApiFailure
            : styles.marginBottomWithoutApiFailure
        }`}
      >
        {!failedDetailsCall && (
          <h1 className={styles.expiredSharedLinkTitle}>
            {t('SmartJobs.UnavailableLinkComponent.Title')}
          </h1>
        )}
        {failedDetailsCall ? (
          <span className={styles.expiredSharedLinkDescription}>
            {t('SmartJobs.UnavailableLinkComponent.DescriptionAPIFail')}
          </span>
        ) : (
          <span className={styles.expiredSharedLinkDescription}>
            {t('SmartJobs.UnavailableLinkComponent.Description')}
          </span>
        )}
      </div>
      {failedDetailsCall && (
        <Button onClick={handleRefresh} className={styles.refreshButton}>
          {t('SmartJobs.UnavailableLinkComponent.Refresh')}
        </Button>
      )}
      <div className={styles.expiredSharedLinkText}>
        <img src={unavailableLinkImage} alt="hour-glass" />
      </div>
    </div>
  );
};

export default NotAvailableLink;
