import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import { DriverCard } from './DriverCard';
import { VehicleCard } from './VehicleCard';
import { CardType } from './CardType';

export const SortableCardViewCard = SortableElement(
  ({
    card,
    geofences,
    isPinned,
    onPinClick,
    showMap,
    toggleMapView,
    onMessageVehicle,
    cardKey,
    hideNonBusiness
  }) => {
    return card.cardType === CardType.Driver ? (
      <DriverCard
        card={card}
        geofences={geofences}
        isPinned={isPinned}
        showMap={showMap}
        onPinClick={onPinClick}
        toggleMapView={toggleMapView}
        onMessageVehicle={onMessageVehicle}
        cardKey={cardKey}
        hideNonBusiness={hideNonBusiness}
      />
    ) : (
      <VehicleCard
        card={card}
        geofences={geofences}
        isPinned={isPinned}
        showMap={showMap}
        onPinClick={onPinClick}
        toggleMapView={toggleMapView}
        onMessageVehicle={onMessageVehicle}
        cardKey={cardKey}
        hideNonBusiness={hideNonBusiness}
      />
    );
  }
);
