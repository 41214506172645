import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InputNumber, Button } from 'antd';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';
import { getCalculatedBy, formatMeterValue, formatMeterBase, getMeterValue } from 'features/meters';
import { METER_TYPES } from '../constants';
import './VehicleCalibrate.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const InformationRow = ({ label, content }) => {
  return (
    <div className="calibration__element--value-row">
      <div className="calibration__element--value-label">{label}</div>
      <div className="calibration__element--value-content">{content}</div>
    </div>
  );
};

const CalibrationElement = ({
  title,
  type,
  vehicleData,
  onCalibrate,
  value,
  onCalibrateValueChange,
  calibratingInProgress
}) => {
  const MAXIMUM_CALIBRATION_NUMBER = 999999;
  const MINIMUM_CALIBRATION_NUMBER = 0;
  const { t } = useTranslation();
  const localization = useLocalization();
  const meter = vehicleData?.meters?.find(m => m.type === type);
  const source = meter?.source || '';
  const isEditable =
    vehicleData?.meters?.find(m => m.type === type)?.rebaseable ||
    !vehicleData?.meters ||
    source === METER_TYPES.GPIO;
  const calculatedBy = meter ? getCalculatedBy(meter) : '';
  const lastReadingAt = meter?.updatedAt
    ? format(new Date(meter.updatedAt), localization.formats.time.formats.dby_imp)
    : '';
  const lastCalibratedAt = meter?.rebasedAt
    ? format(new Date(meter.rebasedAt), localization.formats.time.formats.dby_imp)
    : '';
  const lastReading = meter
    ? source === METER_TYPES.GPIO
      ? `${getMeterValue(meter).toFixed(1)} ${t('Common.hrs')}`
      : formatMeterValue(localization, meter)
    : '-';
  const lastCalibratedValue = meter
    ? source === METER_TYPES.GPIO
      ? `${getMeterValue(meter).toFixed(1)} ${t('Common.hrs')}`
      : formatMeterBase(localization, meter)
    : '-';
  const isActual = !meter?.useDifferential;

  const isCalibrating = useMemo(() => {
    switch (type) {
      case METER_TYPES.HOURS:
        return calibratingInProgress.calibratingEngionHoursInProgress;
      case METER_TYPES.ODOMETER:
        return calibratingInProgress.calibratingOdometerInProgress;
      default:
        return false;
    }
  }, [type, calibratingInProgress]);

  return (
    <div className="calibration__element-container">
      <h5 className="calibration__element--title">{title}</h5>
      <div className="calibration__element--wrapper">
        <InformationRow label={t('Vehicles.Source')} content={source} />
        <InformationRow label={t('Vehicles.CalculatedBy')} content={calculatedBy} />
        <InformationRow label={t('Vehicles.LastReadingAt')} content={lastReadingAt} />
        <InformationRow label={t('Vehicles.LastReading')} content={lastReading} />
        {!isActual && (
          <InformationRow label={t('Vehicles.LastCalibratedAt')} content={lastCalibratedAt} />
        )}
        {!isActual && (
          <InformationRow label={t('Vehicles.LastCalibratedValue')} content={lastCalibratedValue} />
        )}
      </div>
      <div className="inputNumber--title">
        {t('Vehicles.CalibrateTo')}{' '}
        {type === METER_TYPES.ODOMETER ? `(${localization.formats.speed.unit_pluralize})` : '(hrs)'}
      </div>
      <InputNumber
        style={{
          minWidth: 300
        }}
        value={value}
        onChange={value => onCalibrateValueChange(value, type)}
        disabled={!isEditable}
        min={MINIMUM_CALIBRATION_NUMBER}
        max={source === METER_TYPES.GPIO ? MAXIMUM_CALIBRATION_NUMBER : Number.MAX_SAFE_INTEGER}
        maxLength={source === METER_TYPES.GPIO && 9}
      />
      <Button
        style={{
          minWidth: '50px',
          marginTop: '15px'
        }}
        disabled={!isEditable}
        onClick={() => onCalibrate(type)}
        loading={isCalibrating}
        id={BUTTON_IDS.calibrationElement}
      >
        {t('Vehicles.Calibrate')}
      </Button>
    </div>
  );
};

export default CalibrationElement;
