import { createSlice } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { sortBy, uniqBy } from 'lodash';
import { API_PATH } from 'config';
import request from 'superagent';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { api } from 'utils/api';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import i18next from 'i18next';

// Endpoint URLS
export const DOCUMENTS_UPLOAD_URL = '/documents/upload';
export const DOCUMENTS_URL = '/documents';
export const DOCUMENTS_PUT_ASSCOCIATIONS_URL = '/documents/associations';
const DOCUMENTS_CONFIG = '/documents/config';

const DEFAULT_CONFIG = {
  downloadLinkExpiryMs: 0,
  maxDownloadsPerPayload: 0,
  maxFileSizeBytes: 0,
  totalDownloadLimitBytes: 0,
  totalUploadBytesThisMonth: 0,
  totalUploadLimitBytes: 0
};

const documents = {
  list: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    isFetchingAudit: false,
    isFetchingTrailSummary: false,
    error: null,
    isListEmpty: false,
    isAuditEmpty: false,
    isTrailSummaryEmpty: false,
    companyKey: null
  },
  specificDocument: {},
  specificDocumentAuditTrail: [],
  specificDocumentTrailSummary: [],
  specificDocumentId: null,
  uploadDocumentStep: 1,
  isFileUploading: false,
  files: [],
  uploadInProgress: null,
  filesSaveSuccess: false,
  uploadCompanyId: null,
  config: {},
  associationsFlag: false,
  initialEditValues: {},
  dirtyForm: false
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.list = [];
  state.meta.companyKey = action.payload.companyKey;
}

function loadingAuditStart(state) {
  state.meta.isFetchingAudit = true;
}

function loadingAuditFailed(state, action) {
  state.meta.isFetchingAudit = false;
  state.meta.isAuditEmpty = true;
  state.meta.error = action.payload;
}

function loadingTrailSummaryStart(state) {
  state.meta.isFetchingTrailSummary = true;
}

function loadingTrailSummaryFailed(state, action) {
  state.meta.isFetchingTrailSummary = false;
  state.meta.isTrailSummaryEmpty = true;
  state.meta.error = action.payload;
}

export const documentsSlice = createSlice({
  name: 'documents',
  initialState: documents,
  reducers: {
    fetchDocumentsStart: startLoading,
    fetchDocumentsSuccess(state, { payload }) {
      state.list = sortBy(payload.list, [
        function(o) {
          return o.name;
        }
      ]);
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchDocumentsFailure: loadingFailed,
    fetchDocumentDataSuccess(state, { payload }) {
      state.specificDocument[payload.id] = payload;
      state.specificDocumentId = payload.id;
      state.meta.isFetching = false;
    },
    fetchDocumentAuditTrailStart: loadingAuditStart,
    fetchDocumentAuditTrailSuccess(state, { payload }) {
      state.specificDocumentAuditTrail = sortBy(payload.body, [o => o.timeAt]).reverse();
      state.meta.isFetchingAudit = false;
      state.specificDocumentId = payload.id;
    },
    fetchDocumentAuditTrailFailure: loadingAuditFailed,
    fetchDocumentTrailSummaryStart: loadingTrailSummaryStart,
    fetchDocumentTrailSummarySuccess(state, { payload }) {
      state.specificDocumentTrailSummary = sortBy(payload.body, [o => o.timeAt]).reverse();
      state.meta.isFetchingTrailSummary = false;
      state.specificDocumentId = payload.id;
    },
    fetchDocumentTrailSummaryFailure: loadingTrailSummaryFailed,
    changeUploadDocumentStep(state, { payload }) {
      state.uploadDocumentStep = payload;
    },
    uploadFileStart(state, { payload }) {
      state.isFileUploading = true;
      state.uploadInProgress = payload;
    },
    uploadFileFailure(state, { payload }) {
      state.isFileUploading = false;
      state.uploadInProgress = null;
      state.fileError = payload;
    },
    uploadFileSuccess(state, { payload }) {
      state.files = [...state.files, payload];
      state.isFileUploading = false;
      state.uploadInProgress = null;
    },
    saveFileStart(state) {
      state.isFileSaving = true;
    },
    saveFileSuccess(state) {
      state.isFileSaving = false;
      state.filesSaveSuccess = true;
      state.uploadDocumentStep = 1;
      state.files = [];
    },
    saveFileError(state) {
      state.isFileSaving = false;
      state.filesSaveSuccess = false;
      state.uploadDocumentStep = 1;
      state.files = [];
    },
    removeIdsDocumentData(state, { payload }) {
      const stateSpecificDocumentObjCopy = state.specificDocument;
      payload.forEach(id => {
        delete stateSpecificDocumentObjCopy[id];

        if (String(id) === String(state.specificDocumentId)) {
          state.specificDocumentId = null;
        }
      });
      state.specificDocument = stateSpecificDocumentObjCopy;
    },
    removeUploadFile(state, { payload }) {
      state.files = state.files.filter(file => file.documentId !== payload);
    },
    clearEditFiles(state) {
      state.files = [];
      state.initialEditValues = {};
    },
    changeUploadCompanyId(state, { payload }) {
      state.uploadCompanyId = payload;
    },
    updateConfig(state, { payload }) {
      state.config = payload;
    },
    addInitialValuesForEdit(state, { payload }) {
      Object.assign(state.initialEditValues, payload);
    },
    setDirtyForm(state, { payload }) {
      state.dirtyForm = payload;
    }
  }
});

export const {
  fetchDocumentsStart,
  fetchDocumentsSuccess,
  fetchDocumentsFailure,
  fetchDocumentDataSuccess,
  fetchDocumentAuditTrailStart,
  fetchDocumentAuditTrailSuccess,
  fetchDocumentAuditTrailFailure,
  fetchDocumentTrailSummaryStart,
  fetchDocumentTrailSummarySuccess,
  fetchDocumentTrailSummaryFailure,
  changeUploadDocumentStep,
  uploadFileStart,
  uploadFileSuccess,
  uploadFileFailure,
  saveFileStart,
  saveFileSuccess,
  saveFileError,
  removeIdsDocumentData,
  removeUploadFile,
  clearEditFiles,
  changeUploadCompanyId,
  updateConfig,
  addInitialValuesForEdit,
  setDirtyForm
} = documentsSlice.actions;

export const fetchDocuments = () => async (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  try {
    if (!companyKey) {
      return;
    }
    dispatch(fetchDocumentsStart());
    new Promise((resolve, reject) => {
      let userKey = getState().user.current.auth.key;
      let company_id = getState().companies.current.id;
      if (company_id === undefined || company_id === null) {
        return;
      }
      request(
        'GET',
        `${API_PATH}/documents?embed=folders,associations,uploadedBy&pruning=ALL&company_id=${company_id}`
      )
        .set('Authorization', `Token token="${userKey}"`)
        .set('Content-Type', 'application/json')
        .then(res => {
          resolve({ list: res.body, userKey, companyKey });
          dispatch(fetchDocumentsSuccess({ list: res.body, companyKey }));
        })
        .catch(err => {
          console.error(err);
          reject({ err, companyKey });
          dispatch(fetchDocumentsFailure({ err: err.toString(), companyKey }));
        });
    });
  } catch (err) {
    dispatch(fetchDocumentsFailure({ err: err.toString(), companyKey }));
  }
};

export const useDocuments = () => {
  const dispatch = useDispatch();
  const documents = useSelector(state => state.documents.list);
  const isFetching = useSelector(state => state.documents.meta.isFetching);
  const isListEmpty = useSelector(state => state.documents.meta.isListEmpty);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();

  if (!isFetching && (isCompanyKeyDifferent || (documents.length === 0 && !isListEmpty))) {
    dispatch(fetchDocuments());
  }

  return documents;
};

export const useCompanyKey = () => useSelector(state => state.documents.meta.companyKey);
export const useIsCompanyKeyDifferent = () => useCompanyKey() !== useCurrentCompanyKey();

export const useSpecificDocument = id => {
  const dispatch = useDispatch();
  const isFetching = useSelector(state => state.documents.meta.isFetching);
  const documentData = useSelector(state => state.documents.specificDocument);
  const documentKeys = Object.keys(documentData);

  if (!documentKeys.includes(id) && !isFetching) {
    dispatch(fetchDocumentsStart());
    dispatch(fetchSpecificDocumentData(id));
  }

  return documentData[id];
};

const fetchSpecificDocumentData = id => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const urlForDocument = `${DOCUMENTS_URL}/${id}?embed=folders,associations,devices&pruning=ALL`;
  if (!authKey) {
    return;
  }
  try {
    const response = await api.get(urlForDocument, { authKey });
    const { body } = response;
    dispatch(fetchDocumentDataSuccess(body));
  } catch (err) {
    console.log(err);
  }
};

export const useSpecificDocumentAuditTrail = (id, dateRanges) => {
  const dispatch = useDispatch();
  const documentAuditTrail = useSelector(state => state.documents.specificDocumentAuditTrail);
  const isFetching = useSelector(state => state.documents.meta.isFetchingAudit);
  const isEmpty = useSelector(state => state.documents.meta.isAuditEmpty);
  const documentId = useSelector(state => state.documents.specificDocumentId);

  if (parseInt(documentId) !== parseInt(id) && !isFetching && !isEmpty) {
    dispatch(fetchDocumentAuditTrail(id, dateRanges[0], dateRanges[1]));
  }

  return documentAuditTrail;
};
export const useIsFetchingAudit = () => useSelector(state => state.documents.meta.isFetchingAudit);

export const deleteDocuments = ids => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const promiseArray = ids.map(id => {
    return new Promise((resolve, reject) => {
      request('DELETE', `${API_PATH}/documents/${id}`)
        .set('Authorization', `Token token="${userKey}"`)
        .set('Content-Type', 'application/json')
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  });

  Promise.all(promiseArray)
    .then(() => {
      dispatch(fetchDocuments());
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('Easydocs.Notifications.DocsDeleteSuccess')
        })
      );
    })
    .catch(err => {
      console.log(err);
    });
};

// Upload Files
export const uploadFile = (file, overwrite = false) => async (dispatch, getState) => {
  dispatch(uploadFileStart(file.name));
  const {
    companies: { current },
    user: { current: currentUser },
    documents
  } = getState();
  const authKey = currentUser?.auth?.key;

  const formData = new FormData();
  formData.append('file', file);
  formData.append('uploadParams', JSON.stringify({ overwrite }));

  try {
    const response = await api.post(
      DOCUMENTS_UPLOAD_URL,
      {
        authKey,
        query: {
          company_id: documents.uploadCompanyId || current.id
        }
      },
      formData
    );
    const { body } = response;

    if (!body) {
      dispatch(uploadFileFailure({ name: file.name }));
    }
    const fileToStore = { ...body, name: file.name };

    // Error (file exists)
    if (!body.success) {
      dispatch(uploadFileFailure(fileToStore));
    }

    dispatch(uploadFileSuccess(fileToStore));

    return fileToStore;
  } catch (err) {
    console.error(err);
  }
};

export const saveFileDetails = data => async (dispatch, getState) => {
  dispatch(saveFileStart());
  const {
    companies,
    user: { current: currentUser },
    documents
  } = getState();

  const authKey = currentUser?.auth?.key;

  try {
    const response = await api.put(
      DOCUMENTS_URL,
      {
        authKey,
        query: {
          company_id: documents.uploadCompanyId || companies?.current?.id
        }
      },
      data
    );
    const { body } = response;
    dispatch(saveFileSuccess(body));
    const fileNamesArray = body.map(doc => doc.name);
    dispatch(
      openToast({
        type: ToastType.Success,
        message:
          fileNamesArray.length > 1
            ? i18next.t('Easydocs.Notifications.MultipleDocsUpdateSuccess', {
                firstDocs: fileNamesArray.slice(0, -1).join(', '),
                lastDoc: fileNamesArray.slice(-1)
              })
            : i18next.t('Easydocs.Notifications.SingleDocUpdatedSuccess', {
                docName: fileNamesArray[0]
              })
      })
    );
    const fileIdsArray = body.map(doc => doc.id.toString());
    if (window.location.pathname === '/easydocs') {
      dispatch(fetchDocuments());
      dispatch(removeIdsDocumentData(fileIdsArray));
    } else {
      dispatch(fetchDocuments());
      dispatch(fetchSpecificDocumentData(body[0].id));
    }
  } catch (err) {
    dispatch(saveFileError());
    dispatch(
      openToast({
        type: ToastType.Error,
        message: err.message
      })
    );
  }
};

export const removeUploadedFile = id => async (dispatch, getState) => {
  const {
    documents: { files }
  } = getState();
  const authKey = getState().user.current.auth.key;
  const fileToDelete = (files || []).find(file => file.documentId === id);

  try {
    const response = await api.delete(`${DOCUMENTS_URL}/${id}`, { authKey });
    if (response && response.ok) {
      dispatch(removeUploadFile(id));
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('Easydocs.Notifications.RemovedFileSuccess', {
            docName: fileToDelete?.name
          })
        })
      );
    }
  } catch (e) {
    console.error(e);
  }
};

export const getConfig = uploadCompanyId => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const {
    companies: { current: currentCompany }
  } = getState();
  try {
    const response = await api.get(DOCUMENTS_CONFIG, {
      authKey,
      query: {
        company_id: uploadCompanyId || currentCompany?.id
      }
    });
    const { body } = response;
    dispatch(updateConfig(body));
  } catch (err) {
    dispatch(updateConfig(DEFAULT_CONFIG));
    console.error(err);
  }
};

export const changeDocumentStep = step => changeUploadDocumentStep(step);

export const useUploadDocumentsStep = () => {
  return useSelector(state => state.documents.uploadDocumentStep);
};
export const useGetFiles = () => useSelector(state => state.documents.files);
export const useGetAuditTrail = () => useSelector(state => state.specificDocument.auditTrail);
export const useIsFileUploading = () => useSelector(state => state.documents.isFileUploading);
export const useUploadInProgress = () => useSelector(state => state.documents.uploadInProgress);
export const useIsFileSaving = () => useSelector(state => state.documents.isFileSaving);
export const useGetFileError = () => useSelector(state => state.documents.fileError);
export const useIsFileSaveSuccess = () => useSelector(state => state.documents.filesSaveSuccess);
export const useGetUploadCompanyId = () => useSelector(state => state.documents.uploadCompanyId);
export const useConfig = () => useSelector(state => state.documents.config);
export const useGetInitialValuesForEdit = () =>
  useSelector(state => state.documents.initialEditValues);
export const useGetIsFormDirty = () => useSelector(state => state.documents.dirtyForm);

export const downloadDocument = id => async (_, getState) => {
  const authKey = getState().user.current.auth.key;
  const urlforDownload = `${DOCUMENTS_URL}/${id}/download_url`;
  if (!authKey) {
    return;
  }

  try {
    const response = await api.get(urlforDownload, { authKey }).parse(({ resp }) => resp);
    const { text } = response;
    window.open(text, '_blank');
  } catch (err) {
    console.log(err);
  }
};

export const fetchDocumentAuditTrail = (id, fromDate, toDate) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const urlForAuditTrail =
    `${DOCUMENTS_URL}/${id}/audit_trail?from=` +
    fromDate.toISOString() +
    `&to=` +
    toDate.toISOString();
  if (!authKey) {
    return;
  }
  try {
    dispatch(fetchDocumentAuditTrailStart());
    const response = await api.get(urlForAuditTrail, { authKey });
    const { body } = response;
    dispatch(fetchDocumentAuditTrailSuccess({ body, id }));
  } catch (err) {
    dispatch(fetchDocumentAuditTrailFailure(err.message));
    dispatch(
      openToast({
        type: ToastType.Error,
        message: err.message
      })
    );
  }
};

export const useSpecificDocumentTrailSummaryData = id => {
  const dispatch = useDispatch();
  const documentTrailSummary = useSelector(state => state.documents.specificDocumentTrailSummary);
  const isFetching = useSelector(state => state.documents.meta.isFetchingTrailSummary);
  const isEmpty = useSelector(state => state.documents.meta.isTrailSummaryEmpty);
  const documentId = useSelector(state => state.documents.specificDocumentId);

  if (parseInt(documentId) !== parseInt(id) && !isFetching && !isEmpty) {
    dispatch(fetchDocumentTrailSummary(id));
  }

  return documentTrailSummary;
};

export const fetchDocumentTrailSummary = id => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const urlForTrailSummary = `${DOCUMENTS_URL}/${id}/trailsummary`;
  if (!authKey) {
    return;
  }
  try {
    dispatch(fetchDocumentTrailSummaryStart());
    const response = await api.get(urlForTrailSummary, { authKey });
    const { body } = response;
    dispatch(fetchDocumentTrailSummarySuccess({ body, id }));
  } catch (err) {
    dispatch(fetchDocumentTrailSummaryFailure(err.message));
    dispatch(
      openToast({
        type: ToastType.Error,
        message: err.message
      })
    );
  }
};

export const updateAssociations = documents => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;

  if (!authKey) {
    return;
  }
  try {
    await api.put(
      DOCUMENTS_PUT_ASSCOCIATIONS_URL,
      {
        authKey
      },
      documents
    );
    const docIds = uniqBy(documents, 'documentId').map(doc => doc.documentId.toString());
    dispatch(fetchDocuments());
    dispatch(removeIdsDocumentData(docIds));
    dispatch(
      openToast({
        type: ToastType.Success,
        message: i18next.t('Easydocs.Notifications.AssociationsUpdateSuccess')
      })
    );
  } catch (err) {
    console.log(err);
  }
};

export default documentsSlice.reducer;
