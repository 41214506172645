import React from 'react';
import { Row, Col, Skeleton, Space } from 'antd';

import styles from './Toolbar.module.scss';

export const Toolbar = ({ filterOptions, actionOptions }) => (
  <Row wrap={false} align={'middle'} className={styles.toolbar} gutter={[0, 8]}>
    <Col flex="auto" className={styles.filters}>
      {filterOptions?.map(c => (
        <React.Fragment key={c.key}>{c.component}</React.Fragment>
      ))}
    </Col>
    <Col flex="none">
      {actionOptions?.map(c => (
        <React.Fragment key={c.key}>{c.component}</React.Fragment>
      ))}
    </Col>
  </Row>
);

export const ToolbarSkeleton = ({ loading, filterCount = 7, actionCount = 1, hide, children }) =>
  !hide && loading ? (
    <div className={styles.skeleton}>
      <Space>
        {new Array(filterCount).fill(1).map((_, idx) => (
          <Skeleton.Input key={`input-${idx}`} active={true} style={{ width: 180, height: 40 }} />
        ))}
      </Space>
      <Space>
        {new Array(actionCount).fill(1).map((_, idx) => (
          <Skeleton.Button key={`button-${idx}`} active={true} style={{ width: 75, height: 40 }} />
        ))}
      </Space>
    </div>
  ) : (
    children
  );
