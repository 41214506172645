import React, { useEffect, useMemo, useState } from 'react';
import { Tree, Row, Col } from 'antd';
import styles from './AssociationsForm.module.scss';
import { useCanFeatureFlag, FeatureFlag } from 'features/permissions';
import { FormCard } from '../FormModal/FormCard';
import formCardStyles from '../FormModal/FormCard.module.scss';
import useDebounce from 'utils/hooks/useDebounce';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import P from 'prop-types';
import i18next from 'i18next';
import { cloneDeep } from 'lodash';
import {
  getChangedKeys,
  getCheckedKeysForDocument,
  initializeCheckedStatus,
  initializeDocumentIds,
  initializeHierarchyStructure,
  traverseHierarchy,
  getCountsFromCheckedKeys,
  getFilteredHierarchy
} from './util';

export const AssociationsForm = ({ documents, hierarchy, handleCheck }) => {
  const { TreeNode } = Tree;
  const [checkedStatusTree, setCheckedStatusTree] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredHierarchy, setFilteredHierarchy] = useState([]);
  const debouncedSearchText = useDebounce(searchText, 300);

  const updateSearchText = value => {
    setSearchText(value);
  };

  useEffect(() => {
    if (hierarchy?.length && documents?.length) {
      const hierarchyClone = cloneDeep(hierarchy);
      initializeHierarchyStructure(hierarchyClone);

      documents.forEach(document => {
        const checkedKeysForDoc = getCheckedKeysForDocument(document, hierarchyClone);
        initializeDocumentIds(hierarchyClone, checkedKeysForDoc, document.id);
      });

      initializeCheckedStatus(hierarchyClone, documents.length);
      setCheckedStatusTree(hierarchyClone);
      handleCheck(hierarchyClone);
    }
  }, [hierarchy, documents]);

  useEffect(() => {
    const newFilteredHierarchy = getFilteredHierarchy(hierarchy, debouncedSearchText);
    setFilteredHierarchy(newFilteredHierarchy);
  }, [hierarchy, debouncedSearchText]);

  const handleTreeCheck = newCheckedKeys => {
    const hierarchyClone = cloneDeep(checkedStatusTree);
    const changedKeys = getChangedKeys(checkedKeys, newCheckedKeys);
    traverseHierarchy(
      hierarchyClone,
      changedKeys,
      documents.map(document => document.id)
    );
    setCheckedStatusTree(hierarchyClone);

    handleCheck(hierarchyClone, true);
  };

  const checkedKeys = useMemo(() => {
    const result = { checked: [], halfChecked: [] };
    const addCheckedKeys = hierarchy => {
      hierarchy.forEach(h => {
        if (h.checked) {
          result.checked.push(h.key);
        } else if (h.halfChecked) {
          result.halfChecked.push(h.key);
        }

        if (h.treeData.length) {
          addCheckedKeys(h.treeData);
        }
      });
    };

    addCheckedKeys(checkedStatusTree);
    return result;
  }, [checkedStatusTree]);

  const { fleetsCount, vehicleDeviceCount } = useMemo(() => {
    return getCountsFromCheckedKeys(checkedKeys);
  }, [checkedKeys]);

  const CARD_BODY = (
    <div className={styles.instructionsText}>
      <span>{i18next.t('Easydocs.AssociationModal.FirstIntruction')}</span>
      <br />
      <span>{i18next.t('Easydocs.AssociationModal.SecondIntruction')}</span> <br />
      <span>{i18next.t('Easydocs.AssociationModal.ThirdInstruction')}</span> <br />
      <span className={styles.greyText}>{i18next.t('Easydocs.AssociationModal.SideNote')}</span>
    </div>
  );
  const renderTreeNodes = data =>
    data &&
    data.map(item => {
      if (item.treeData) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.treeData)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });

  return (
    <div id="form-associations">
      <div className={styles.container}>
        <div className={styles.documentsSelect}>
          <span className={styles.documentsLabel}>
            {`${i18next.t('Easydocs.AssociationModal.SelectedDocuments')}:`}
            <span className={styles.documentsWrapper}>
              {' '}
              {documents && documents.map(doc => doc.name).join(', ')}
            </span>
          </span>{' '}
        </div>
        <div className={styles.cards}>
          <FormCard
            header={`${i18next.t('Easydocs.AssociationModal.Instructions')}:`}
            body={CARD_BODY}
            size="large"
            footer=""
          />
          <div className={`${formCardStyles.container} ${formCardStyles.large} `}>
            <Row>
              <Col span={16}>
                <AntSearchbar
                  onFilter={updateSearchText}
                  className={styles.searchBar}
                  size={'medium'}
                  placeholder={i18next.t(
                    'Easydocs.AssociationModal.Searchforfleetsvehiclesordevices'
                  )}
                />
              </Col>
              <Col span={8}>
                <div className={styles.countLabel}>
                  {`${fleetsCount} ${
                    fleetsCount === 1 ? i18next.t('Common.Fleet') : i18next.t('Common.Fleets')
                  }`}
                </div>
                <div className={styles.countLabel}>
                  {`${vehicleDeviceCount} ${
                    vehicleDeviceCount === 1
                      ? i18next.t('Common.Vehicle')
                      : i18next.t('Common.Vehicles')
                  }`}
                </div>
              </Col>
            </Row>
            <Tree
              checkable
              checkStrictly
              checkedKeys={checkedKeys}
              onCheck={handleTreeCheck}
              className={styles.antTreeList}
            >
              {renderTreeNodes(filteredHierarchy)}
            </Tree>
          </div>
        </div>
        <div className={styles.card}> </div>
      </div>
    </div>
  );
};

AssociationsForm.propTypes = {
  documents: P.array,
  hierarchy: P.array,
  handleCheck: P.func
};
