import { ColumnKeys } from '../../../utils/constants';
import { Text, View } from '@react-pdf/renderer';
import {
  renderItemQuantities,
  renderItemWeights,
  capitalizeFromUpperCase,
  getDeliveryDetails,
  getItemDescription
} from '../../../utils/helpers';

export const renderItemData = ({
  jobItem,
  columnKey,
  pdfStyles,
  itemTypes,
  localization,
  job,
  t
}) => {
  const { item } = jobItem;
  const delivery = getDeliveryDetails(jobItem, job || jobItem.job, t);
  const translatedItemType =
    itemTypes.find(type => type.code === item?.type)?.description || item.type;
  const { weight, weightUom } = localization.convertWeight(item?.weight, item?.weightUom);
  const { weight: localizedLoadedWeight } = localization.convertWeight(
    jobItem.loadedWeight,
    item.weightUom
  );
  const { weight: localizedUnLoadedWeight } = localization.convertWeight(
    jobItem.unloadedWeight,
    item.weightUom
  );
  const unit = weightUom ? t(`SmartJobs.Input.Unit.${weightUom}`, weightUom) : '';

  switch (columnKey) {
    case ColumnKeys.ITEM_ID:
      return <Text style={pdfStyles.itemData}>{jobItem?.itemId || jobItem?.id || ''}</Text>;
    case ColumnKeys.ITEM_ATTRS:
      return (
        <View style={pdfStyles.itemData}>
          {jobItem?.attributes?.map(attr => (
            <Text key={attr?.key}>
              {attr?.key}: {attr?.value}
            </Text>
          )) || <Text>{'-'}</Text>}{' '}
        </View>
      );
    case ColumnKeys.ITEM_TYPE:
      return <Text style={pdfStyles.itemData}>{translatedItemType}</Text>;
    case ColumnKeys.ITEM_QUANTITY:
      return (
        <Text style={pdfStyles.itemData}>
          {renderItemQuantities(
            jobItem?.item?.quantity,
            jobItem.loadedQuantity,
            jobItem.unloadedQuantity
          )}
        </Text>
      );
    case ColumnKeys.ITEM_WEIGHT:
      return (
        <Text style={pdfStyles.itemData}>
          {renderItemWeights(weight, unit, localizedLoadedWeight, localizedUnLoadedWeight)}
        </Text>
      );
    case ColumnKeys.JOB_STATUS:
      return <Text style={pdfStyles.itemData}>{capitalizeFromUpperCase(delivery.status)}</Text>;
    case ColumnKeys.ITEM_DESCRIPTION:
      return (
        <Text style={pdfStyles.itemData}>{getItemDescription(item) || `${delivery.reason}`}</Text>
      );
    case ColumnKeys.ITEM_TIME:
      return <Text style={pdfStyles.itemData}>{item.time || '-'}</Text>;
    case ColumnKeys.ITEM_REASON:
      return <Text style={pdfStyles.itemData}>{delivery?.reason}</Text>;
    default:
      return;
  }
};
