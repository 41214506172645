export const filterUsersByBranches = (user, companyId, branches) =>
  user.companyId === companyId &&
  branches.some(
    branch =>
      noAssociatedBranches(user, branches) || (branch.id === user.location?.id && branch.checked)
  );

export const filterVehiclesByFleets = (vehicle, companyId, fleets) =>
  vehicle.companyId === companyId &&
  fleets.some(
    fleet =>
      noAssociatedFleets(vehicle, fleets) ||
      vehicle.fleets?.some(f => f.id === fleet.id && fleet.checked)
  );

const noAssociatedFleets = (vehicle, fleets) => {
  if (!fleets.some(fleet => fleet.id === -1 && fleet.checked)) {
    return false;
  }

  if (vehicle.fleets && vehicle.fleets[0]?.id !== undefined) {
    return false;
  }

  return true;
};

const noAssociatedBranches = (user, branches) => {
  if (!branches.some(branch => branch.id === -1 && branch.checked)) {
    return false;
  }

  if (user.location) {
    return false;
  }

  return true;
};
