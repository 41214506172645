export function filterFilesBy(files, filterBy) {
  const filteredFiles = [];

  for (const file of files) {
    if (!file) continue;
    const { name, type, size } = file;

    // Check for the file type
    if (filterBy?.type && !filterBy.type.values.includes(type)) {
      filterBy.type.callback(name);
      continue;
    }

    // Check for the size of the file
    if (filterBy?.size && filterBy.size.value < size) {
      filterBy.size.callback(name);
      continue;
    }
    filteredFiles.push(file);
  }

  return filteredFiles;
}
