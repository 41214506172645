import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
//slices
import { addVehicleModel } from 'features/vehicles/vehicleModels';
//components
import { ToastType } from 'components/notifications/toasts/Toast';
import { AutoComplete, InputNumber } from 'components/ant';
import { Form, Button, Input } from 'antd';
import Modal from 'components/ant/Modal/Modal';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
//constants and helpers
import { getMakeModalFormConfig } from './constants';
import { initialYearModalValue } from './helpers';
//helpers and methods
import { openToast } from 'features/toasts/toastsSlice';
import { parseErrorMessage } from 'utils/strings';
// styles
import styles from './VehicleMakeModelYearFormModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

import { InfoCircleFilled } from '@ant-design/icons';
import { FeatureFlag, useCanFeatureFlag } from 'features/permissions';
import { useFuzzySearch } from './VehicleModelSearch.js';

export const VehicleMakeModelYearFormModal = ({
  isOpen,
  onCancel,
  initialValues,
  tempSearchedValue,
  updateMakeModelYearValuesAfterAdd,
  makeModelYearList,
  companyId
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [vehicleMakeForm] = Form.useForm();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormSaving, setIsFormSaving] = useState(false);
  const [isExistModalOpen, setIsExistModalOpen] = useState(false);
  const { performSearch } = useFuzzySearch(makeModelYearList);

  const formItems = getMakeModalFormConfig(t);

  const initialFormValues = {
    make:
      (tempSearchedValue.type === formItems[0].name && tempSearchedValue.value) || initialValues[0],
    model:
      (tempSearchedValue.type === formItems[1].name && tempSearchedValue.value) || initialValues[1],
    yearOfManufacture: initialYearModalValue({
      tempValue: tempSearchedValue.value,
      tempValueType: tempSearchedValue.type,
      searchedType: formItems[2].name,
      initialValue: initialValues[2]
    })
  };

  const [localTempSearchedValue, setLocalTempSearchedValue] = useState({
    make: initialFormValues.make,
    model: initialFormValues.model,
    yearOfManufacture: initialFormValues.yearOfManufacture
  });

  useEffect(() => {
    const isFormInitialValid = Object.values(initialFormValues).every(value => !!value);
    setIsFormValid(isFormInitialValid);
  }, []);

  const renderNotFoundPanel = type => {
    let possibleMatch = [];
    if (localTempSearchedValue.value) {
      const possibleMatchModel = performSearch(localTempSearchedValue.value || '', type);
      possibleMatch = Array.from(new Set(possibleMatchModel.map(i => i[type]))).slice(0, 5);
    }

    if ((possibleMatch || []).length !== 0) {
      return (
        <div style={{ padding: '0px 10px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {(possibleMatch || []).map((data, index) => (
              <a
                key={index}
                className={styles.makeModelYearSuggestionValue}
                onClick={() => {
                  vehicleMakeForm.setFieldValue(type, data);
                }}
              >
                {data}
              </a>
            ))}
          </div>
        </div>
      );
    }
  };

  const handleValueSearch = (value, type) => {
    value && setLocalTempSearchedValue({ value, type });
  };

  const renderFormItems = formItems => {
    return formItems.map((formItem, formItemIndex) => {
      const { name, label, rules, childProp } = formItem;
      return (
        <Form.Item
          key={`${formItemIndex}-${name}`}
          name={name}
          labelAlign="left"
          label={label}
          colon={false}
          rules={rules}
        >
          {renderFormItemChild(childProp, name)}
        </Form.Item>
      );
    });
  };

  const renderFormItemChild = (childProp, name) => {
    const { type, placeholder } = childProp;
    const options = Array.from(new Set(makeModelYearList.map(i => i[name])))
      .filter(i => i?.toString() !== undefined && i?.toString()?.length !== 0)
      .map(data => {
        return { label: data?.toString(), value: data?.toString() };
      });

    return (
      <AutoComplete
        defaultValue={initialFormValues[name]}
        onSelect={e => {
          vehicleMakeForm.setFieldValue(name, e);
        }}
        options={options}
        placeholder={placeholder}
        onFocus={() => {
          handleValueSearch(vehicleMakeForm.getFieldValue(name), name);
        }}
        onSearch={e => {
          handleValueSearch(e, name);
        }}
        notFoundContent={renderNotFoundPanel(name)}
      />
    );
  };

  const renderFooterContactFormModal = () => {
    return (
      <div className={styles.vehicleMakeFormFooter}>
        <Button
          htmlType="submit"
          type="primary"
          id={BUTTON_IDS.vehicleMakeModelYearSave}
          disabled={!isFormValid}
          loading={isFormSaving}
        >
          {t('Common.Save')}
        </Button>
        <Button
          type="secondary"
          id={BUTTON_IDS.vehicleMakeModelYearReset}
          onClick={handleResetFields}
        >
          {t(`Common.Reset`)}
        </Button>
        <Button type="secondary" id={BUTTON_IDS.vehicleMakeModelYearCancel} onClick={handleCancel}>
          {t(`Common.Cancel`)}
        </Button>
      </div>
    );
  };

  const handleResetFields = () => {
    setIsFormDirty(false);
    setIsFormValid(false);
    vehicleMakeForm.resetFields();
  };

  const handleCancel = () => {
    isFormDirty && ['name'].every(value => !!vehicleMakeForm.getFieldValue(value))
      ? confirmationModal(
          t('WiFi.confirmTitle'),
          t('WiFi.confirmDescription'),
          t('Common.Modal.CancelChanges'),
          t('Common.Modal.Stay'),
          closeModal
        )
      : closeModal();
  };

  const closeModal = () => {
    handleResetFields();
    onCancel();
  };

  const handleValuesChange = () => {
    const isValid = Object.values(vehicleMakeForm.getFieldsValue()).every(value => !!value);
    setIsFormDirty(true);
    setIsFormValid(isValid);
  };

  const handleUseExisting = () => {
    const valuesFromForm = vehicleMakeForm.getFieldsValue();
    const { ...payload } = valuesFromForm;
    const identicalMakeModelYearEntity = makeModelYearList.find(
      makeModelYear =>
        makeModelYear.make.toLowerCase() === payload.make.toLowerCase() &&
        makeModelYear.model.toLowerCase() === payload.model.toLowerCase() &&
        parseInt(makeModelYear.yearOfManufacture, 0) === parseInt(payload.yearOfManufacture, 0) &&
        makeModelYear.source?.toLowerCase() === 'internal'
    );
    const { make, model, yearOfManufacture, source } = identicalMakeModelYearEntity;
    updateMakeModelYearValuesAfterAdd(make, model, yearOfManufacture, source);
    setIsExistModalOpen(true);
    setIsFormSaving(false);
    closeModal();
  };

  const handleSave = async () => {
    //disable the save button when the saving process starts
    setIsFormSaving(true);
    if (isFormSaving) {
      return;
    }
    const valuesFromForm = vehicleMakeForm.getFieldsValue();
    const { ...payload } = valuesFromForm;
    const identicalMakeModelYearEntity = makeModelYearList.find(
      makeModelYear =>
        makeModelYear.make.toLowerCase() === payload.make.toLowerCase() &&
        makeModelYear.model.toLowerCase() === payload.model.toLowerCase() &&
        parseInt(makeModelYear.yearOfManufacture, 0) === parseInt(payload.yearOfManufacture, 0) &&
        makeModelYear.source?.toLowerCase() === 'internal'
    );

    if (identicalMakeModelYearEntity) {
      setIsExistModalOpen(true);
      setIsFormSaving(false);
      return;
    }

    // We need to save with the same make and/or model value from the database (avoid case sensitive)
    const identicalMakeEntity = makeModelYearList.find(
      makeModelYear => makeModelYear.make.toLowerCase() === payload.make.toLowerCase()
    );

    if (identicalMakeEntity) {
      payload.make = identicalMakeEntity.make;
    }

    const identicalModelEntity = makeModelYearList.find(
      makeModelYear => makeModelYear.model.toLowerCase() === payload.model.toLowerCase()
    );

    if (identicalModelEntity) {
      payload.model = identicalModelEntity.model;
    }

    payload.source = 'internal';

    try {
      const response = await dispatch(
        addVehicleModel({
          body: payload,
          query: { company_id: companyId },
          callbackQuery: { query: { company_id: companyId } }
        })
      );
      unwrapResult(response);

      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('Vehicles.Notifications.AddMakeModelYearSuccess')
        })
      );
      const { make, model, yearOfManufacture } = payload;
      const source = payload.displaySource ? payload.displaySource : payload.source;
      updateMakeModelYearValuesAfterAdd(make, model, yearOfManufacture, source);
      closeModal();
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
      );
    }

    //enable the save button when the saving process ends
    setIsFormSaving(false);
  };

  return (
    <>
      <Modal
        title={t('Vehicles.Form.AddModelFormModalTitle')}
        visible={isOpen}
        onCancel={handleCancel}
        width={478}
        wrapClassName={styles.vehicleMakeFormModal}
        footer={null}
      >
        <div className={styles.vehicleMakeFormContainer}>
          <Form
            form={vehicleMakeForm}
            onValuesChange={handleValuesChange}
            name="VehicleMakeForm"
            onFinish={handleSave}
            layout="vertical"
            initialValues={initialFormValues}
          >
            {renderFormItems(formItems)}
            {renderFooterContactFormModal()}
          </Form>
        </div>
      </Modal>
      <Modal
        wrapClassName={styles.duplicateVehicleMakeModal}
        title={
          <div className={styles.headerPanel}>
            <InfoCircleFilled className={styles.warningIcon} />
            {t('Vehicles.Form.AddModelFormModalAlreadyExist')}
          </div>
        }
        visible={isExistModalOpen}
        onCancel={() => {
          setIsExistModalOpen(false);
        }}
        footer={null}
      >
        <div className={styles.modalContainer}>
          {t('Vehicles.Form.AddModelFormModalAlreadyExistDescription')}
        </div>
        <div className={styles.modalFooter}>
          <Button
            htmlType="submit"
            type="primary"
            className={styles.confirmIcon}
            id={BUTTON_IDS.vehicleMakeModelYearUseExisting}
            onClick={handleUseExisting}
          >
            {t('Vehicles.Form.AddModelFormModalUseExistBtn')}
          </Button>
          <Button
            type="secondary"
            id={BUTTON_IDS.vehicleMakeModelYearUseExistingCancel}
            onClick={() => {
              setIsExistModalOpen(false);
            }}
          >
            {t(`Common.Cancel`)}
          </Button>
        </div>
      </Modal>
    </>
  );
};
