import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

//components
import { Users } from './Users';
import { UserForm } from './UserForm';
import { UserView } from './UserView';
import { UserAudits } from './UserAudits';
import { NoAccessBody } from 'components/pages/NoAccess';
import { Can, entities, services, useCanOneOfCompanyServices } from 'features/permissions';

//slices
import { fetchUsers } from 'features/users/usersSlice';
import { useRulesets } from 'features/rulesets/rulesetsSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';

//constants
import { PATHS, ACTIONS } from './constants';

export const UsersRouter = () => {
  const dispatch = useDispatch();
  const rulesets = useRulesets();
  const currentCompany = useCurrentCompany();
  const [rulesetsByRegion, setRulesetsByRegion] = useState([]);
  const canUseELDUS = useCanOneOfCompanyServices([services.ELDUS]);
  const canUseELDCAN = useCanOneOfCompanyServices([services.ELDCAN]);

  const filterRulesetsByCountry = useCallback(
    country => {
      var filteredRulesets = rulesets || [];
      if (canUseELDCAN && canUseELDUS) {
        filteredRulesets = filteredRulesets.filter(
          rule => rule.region === 'US' || rule.region === 'CA'
        );
      } else {
        filteredRulesets = filteredRulesets.filter(rule => rule.region === country);
      }

      setRulesetsByRegion(filteredRulesets);
    },
    [rulesets, canUseELDCAN, canUseELDUS]
  );

  useEffect(() => {
    if (rulesets && currentCompany?.country) {
      filterRulesetsByCountry(currentCompany.country);
    }
  }, [rulesets, currentCompany, filterRulesetsByCountry]);

  const handleToastMessage = msg => {
    if (msg && msg.text) {
      dispatch(
        openToast({
          type: msg.type,
          message: msg.text
        })
      );
      dispatch(fetchUsers());
    }
  };
  return (
    <Can everyEntity={[entities.USER]} onFail={<NoAccessBody />}>
      <Switch>
        <Route path={PATHS.USER_VIEW}>
          <Can everyEntity={[entities.USER_VIEW]} onFail={<NoAccessBody />}>
            <UserView rulesets={rulesetsByRegion} />
          </Can>
        </Route>
        <Route path={PATHS.USER_ADD}>
          <Can everyEntity={[entities.USER_CREATE]} onFail={<NoAccessBody />}>
            <UserForm
              action={ACTIONS.ADD}
              setToastMessageCb={msg => handleToastMessage(msg)}
              rulesets={rulesetsByRegion}
              setRulesetsByCountryCb={country => filterRulesetsByCountry(country)}
            />
          </Can>
        </Route>
        <Route path={PATHS.USER_EDIT}>
          <Can everyEntity={[entities.USER_UPDATE]} onFail={<NoAccessBody />}>
            <UserForm
              action={ACTIONS.EDIT}
              setToastMessageCb={msg => handleToastMessage(msg)}
              rulesets={rulesetsByRegion}
              setRulesetsByCountryCb={country => filterRulesetsByCountry(country)}
            />
          </Can>
        </Route>
        <Route path={PATHS.USER_AUDITS}>
          <Can everyEntity={[entities.USER_AUDIT]} onFail={<NoAccessBody />}>
            <UserAudits />
          </Can>
        </Route>
        <Route path={PATHS.USERS_DEFAULT}>
          <Users setToastMessage={msg => handleToastMessage(msg)} />
        </Route>
      </Switch>
    </Can>
  );
};
