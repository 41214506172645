import './components/jquery.sentinel.js';
import 'react-dates/lib/css/_datepicker.css';
import './scss/index.scss';
import Sentinel from './components/Sentinel';
import sentinelUserReducer, * as sentinelUserSlice from 'features/sentinel/components/user/sentinelUserSlice';
import rulesetsReducer, * as rulesets from 'features/sentinel/components/rulesets/rulesetsSlice';
import auditsReducer, * as audits from 'features/sentinel/components/audits/auditsSlice';
import statusReducer, * as sentinelStatus from 'features/sentinel/components/status/statusSlice';
import eventsReducer, * as events from 'features/sentinel/components/events/eventsSlice';
import reduceReducers from 'reduce-reducers';

const initialState = {
  ...events.initialState,
  ...rulesets.initialState,
  ...sentinelUserSlice.initialState,
  ...audits.initialState,
  ...sentinelStatus.initialState
};

const rootReducer = reduceReducers(
  initialState,
  eventsReducer,
  rulesetsReducer,
  sentinelUserReducer,
  auditsReducer,
  statusReducer
);

export const sentinelStore = {
  reducer: rootReducer
};

export default Sentinel;
