import React, { useRef, useCallback, useMemo } from 'react';

import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import styles from './AlertData.module.scss';
import { EventUtil } from '../../../features/events/eventUtil';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import { useTranslation } from 'react-i18next';
import { VehicleMaintenanceScheduleRender } from './VehicleMaintenanceScheduleRender';
import { DriverManagementScheduleRender } from './DriverManagementScheduleRender';
import { NormalAlertContentRender } from './NormalAlertContentRender';
import { MeterAlertContentRender } from './MeterAlertContentRender';
import { FatigueAlertContentRender } from './FatigueAlertContentRender';
import { TachoAlertContentRender } from './TachoAlertContentRender';
import { EngineIdleAlertContentRender } from './EngineIdleAlertContentRender';
import { DocumentExpireContentRender } from './DocumentExpireContentRender';
import { eventTypes, eventScheduleTypes, alertLevelDescription } from './constants';
import { OOHAlertRender } from './OOHAlertRender';
import { BUTTON_IDS } from 'utils/globalConstants';

export const AlertStatus = {
  Normal: 0,
  Processing: 1,
  Dismiss: 2,
  Error: 3,
  Invisible: 4,
  Plain: 5
};

function dataConverter(data, localization, t) {
  return {
    ...data?.event,
    title:
      (data.event?.device && data.event.device.imei) +
      ((data.event?.user &&
        ' | ' + (data.event.user.firstName || '') + ' ' + data.event.user.lastName) ||
        ''),
    time: format(new Date(data.eventAt), localization.formats.time.formats.dby_imp),
    type: EventUtil.getEventType(data, t),
    vehicleName: data.event?.vehicle?.name ?? t('Unknown'),
    deviceName: data.event?.device?.name ?? t('Unknown'),
    location: data.event?.endLocation || data.event?.location || t('Common.NA'),
    vehicleId: data.event?.vehicle?.id ?? 'Unknown',
    deviceId: data.event?.device?.id ?? 'Unknown',
    driverId: data.event?.user?.id ?? 'Unknown',
    alertLevel: data.extras?.AlertLevel,
    driverName:
      (data.event?.user && `${data.event.user.firstName} ${data.event.user.lastName}`) ||
      t('Unknown'),
    isFatigueAlert: [
      'Ng::Event::FatigueComplianceViolation',
      'Ng::Event::FatiguePredictedViolation',
      'Ng::Event::FatigueViolation',
      'Ng::Event::FatigueSubmit',
      'Ng::Event::EldViolation'
    ].some(rawType => rawType === data?.eventType),
    description:
      (data.event?.ruleset || '') +
      ((data.event?.description && ' | ' + data.event?.description) || t('Unknown'))
  };
}

function _AlertData({ onView, onDismiss, onEmbedView, status = AlertStatus.Normal, ...props }) {
  const handleDismissClick = useCallback(
    evt => {
      evt.preventDefault();
      evt.stopPropagation();
      if (onDismiss) {
        onDismiss(props.data);
      }
    },
    [props.data, onDismiss]
  );

  const handleEmbedViewClick = useCallback(() => {
    if (onEmbedView) {
      onEmbedView(props.data);
    }
  }, [props.data, onEmbedView]);

  const localization = useLocalization();
  const { t } = useTranslation();

  const alertData = useMemo(() => dataConverter(props.data, localization, t), [
    props.data,
    localization,
    t
  ]);

  const isMeterAlert =
    props.data?.eventType === 'Ng::Event::DeviceMeterAlert' ||
    props.data?.eventType === 'Ng::Event::VehicleMeterAlert';

  const isOOHAlert = props.data?.eventType === 'Ng::Event::CalendarException';
  const isDocumentExpireAlert = props.data?.eventType === 'Ng::Event::DocumentExpire';
  const isTachoAlert = props.data?.eventType === 'Ng::Event::TachoFatigueAlert';

  const btnRef = useRef(null);

  const renderContent = () => {
    if (
      props.data?.eventType === eventTypes.ScheduleEvent &&
      props.data?.event?.schedule?.type === eventScheduleTypes.VehicleMaintenanceSchedule
    ) {
      return <VehicleMaintenanceScheduleRender data={props.data} alertData={alertData} />;
    }

    if (
      props.data?.eventType === eventTypes.ScheduleEvent &&
      props.data?.event?.schedule?.type === eventScheduleTypes.DriverManagementSchedule
    ) {
      return <DriverManagementScheduleRender data={props.data} alertData={alertData} />;
    }

    if (alertData.isFatigueAlert) {
      return <FatigueAlertContentRender alertData={alertData} />;
    }

    if (isTachoAlert) {
      return <TachoAlertContentRender alertData={alertData} />;
    }

    if (props.data?.eventType === 'Ng::Event::VPM_IT') {
      return <EngineIdleAlertContentRender alertData={alertData} />;
    }

    if (isMeterAlert) {
      return <MeterAlertContentRender alertData={alertData} />;
    }

    if (isOOHAlert) {
      return <OOHAlertRender alertData={alertData} />;
    }

    if (isDocumentExpireAlert) {
      return <DocumentExpireContentRender data={props.data} />;
    }

    return <NormalAlertContentRender alertData={alertData} />;
  };

  return (
    <Card
      className={
        isMeterAlert
          ? styles.noHoverAlertPanel
          : styles.alertPanel +
            ' ' +
            (status === AlertStatus.Dismiss ? styles.dismiss : '') +
            (status === AlertStatus.Invisible ? styles.invisible : '')
      }
      title={isMeterAlert ? '' : t('Notifications.ViewDetail')}
      onClick={isMeterAlert ? () => {} : handleEmbedViewClick}
    >
      {status === AlertStatus.Processing && (
        <div className={styles.processingContainer}>
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      )}
      <Container className={status === AlertStatus.Processing && styles.blur}>
        <Row className={isMeterAlert ? styles.noHoverAlertBody : styles.alertBody}>
          <Col>
            <div className={styles.bodyHead}>
              <p className={styles.time}>{alertData.time}</p>
              <p className={styles.menu}>
                {status !== AlertStatus.Plain && (
                  <Button
                    ref={btnRef}
                    variant="link"
                    id={BUTTON_IDS.dismissAlertDataNotif}
                    title={t('Notifications.DismissNotification')}
                    onClick={handleDismissClick}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                )}
              </p>
            </div>
            <div className={styles.bodyContent}>
              <div>
                {!isMeterAlert && !isOOHAlert && !isDocumentExpireAlert && (
                  <span className={styles.type}>
                    {(alertLevelDescription[alertData.alertLevel]
                      ? t(alertLevelDescription[alertData.alertLevel]) + ' - '
                      : '') + alertData.type}
                  </span>
                )}
                {renderContent()}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

const AlertData = React.memo(_AlertData);
export { AlertData };
