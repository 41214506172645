import { Alert, DatePicker, Form, Input, Radio, Select, Spin } from 'antd';
import i18n from 'i18nextConfig';
import { ITEM_LABELS, ITEM_PLACEHOLDERS } from './constants';

import styles from './EnrolmentsForm.module.scss';

const lower = str => str.toLowerCase();
const isString = input => typeof input === 'string';
const basicRules = [{ required: true }];

function formItemBuilder(name, values) {
  return {
    showSearch: function() {
      this.showSearch = true;
      return this;
    },
    setLoading: function(value) {
      this.loading = value;
      return this;
    },
    disable: function(value) {
      this.isDisabled = value;
      return this;
    },
    setCustomOption: function(value) {
      this.getCustomOption = value;
      return this;
    },
    setOnChange: function(value) {
      this.onChange = value;
      return this;
    },
    setRules: function(value) {
      this.rules = value;
      return this;
    },
    setDependencies: function(value) {
      this.dependencies = value;
      return this;
    },
    setCustomCss: function(value) {
      this.customCss = value;
      return this;
    },
    buildAlert: function(message, type) {
      return (
        <div className={styles.formElementContainer}>
          <Alert message={message} type={type} className={styles.alert} />
        </div>
      );
    },
    buildSelect: function(notFoundComponent = null, props = {}) {
      return (
        <div className={styles.formElementContainer}>
          <Spin spinning={!!this.loading}>
            <Form.Item
              name={name}
              rules={this.rules || basicRules}
              label={i18n.t(ITEM_LABELS[name])}
              dependencies={this.dependencies || undefined}
            >
              <Select
                {...props}
                showSearch={this.showSearch}
                placeholder={i18n.t(ITEM_PLACEHOLDERS[name])}
                size="large"
                disabled={!!this.isDisabled}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                filterOption={(input, option) =>
                  lower(isString(option?.children) ? option.children : option.value).includes(
                    lower(input)
                  )
                }
                notFoundContent={this.loading ? <Spin size="small" /> : notFoundComponent}
                autoComplete="chrome-off"
              >
                {this.loading ? (
                  <></>
                ) : (
                  values?.map(value => {
                    if (this.getCustomOption) {
                      return this.getCustomOption(value);
                    }

                    return (
                      <Select.Option key={value.id} value={value.id}>
                        {value.name}
                      </Select.Option>
                    );
                  })
                )}
              </Select>
            </Form.Item>
          </Spin>
        </div>
      );
    },
    buildInput: function() {
      return (
        <div className={this.customCss ? styles[this.customCss] : styles.formElementContainer}>
          <Form.Item
            name={name}
            rules={this.rules || basicRules}
            label={i18n.t(ITEM_LABELS[name])}
            dependencies={this.dependencies || undefined}
          >
            <Input disabled={!!this.isDisabled} size="large" />
          </Form.Item>
        </div>
      );
    },
    buildRadioGroup: function() {
      return (
        <div className={styles.formElementContainer}>
          <Form.Item
            rules={this.rules || basicRules}
            name={name}
            label={i18n.t(ITEM_LABELS[name])}
            dependencies={this.dependencies || undefined}
          >
            <Radio.Group disabled={!!this.isDisabled} onChange={this.onChange}>
              {values.map(v => (
                <Radio key={v.value} value={v.value}>
                  {v.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
      );
    },
    buildCalendar: function() {
      return (
        <div className={styles.formElementContainer}>
          <Form.Item
            rules={this.rules || basicRules}
            name={name}
            label={i18n.t(ITEM_LABELS[name])}
            dependencies={this.dependencies || undefined}
          >
            <DatePicker
              disabled={!!this.isDisabled}
              showTime
              placeholder={i18n.t(ITEM_PLACEHOLDERS[name])}
              size="large"
              getPopupContainer={triggerNode => triggerNode.parentNode}
            />
          </Form.Item>
        </div>
      );
    }
  };
}

export default formItemBuilder;
