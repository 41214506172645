/* global google */
import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';

import Map, { MapMode } from 'components/map/Map';
import { GeoSuggest } from 'components/GeoSuggest';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/lib/input/TextArea';
import { Button, Modal } from 'antd';
import { sendRouteToMessage } from 'features/tracking/routeTo';
import { useDispatch } from 'react-redux';

import styles from './RouteTo.module.scss';
import { isLatLngValid, getBoundsZoomLevel } from 'utils/geo';
import { LocalizationConfig } from 'features/localization/localization';
import { useCurrentRegion } from 'features/regions/regionsSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

const defaultRegionGeo = { ...LocalizationConfig.AU.geocode, zoom: 4 };

const getZoomLevel = (
  bounds,
  extendPoints = [],
  container = {
    zoom: null,
    height: 640,
    width: 620
  }
) => {
  bounds = bounds || new google.maps.LatLngBounds();
  extendPoints.forEach(point => {
    if (point && isLatLngValid(point.lat, point.lng)) {
      bounds.extend(new google.maps.LatLng(point.lat, point.lng));
    }
  });
  const zoom = getBoundsZoomLevel(bounds, {
    height: container.height || 640,
    width: container.width || 620
  });
  if (isNaN(Number(zoom)) || !isFinite(Number(zoom))) {
    return container?.zoom;
  } else {
    return Number(zoom) > 1 ? Number(zoom) - 1 : Number(zoom);
  }
};

export const RouteToModal = ({
  isOpen = false,
  handleCancel,
  title,
  data,
  selectedLocation,
  setSelectedLocation,
  geoSuggestRef,
  locationName,
  geoSuggestWithGeofences,
  onModalClose
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const region = useCurrentRegion();
  const regionGeoLocation = useMemo(() => {
    let regionGeo = defaultRegionGeo;
    if (region?.geocode) {
      try {
        regionGeo = JSON.parse(region?.geocode);
        regionGeo = isLatLngValid(regionGeo.lat, regionGeo.lng) ? regionGeo : defaultRegionGeo;
      } catch (error) {
        regionGeo = defaultRegionGeo;
      }
    }
    return regionGeo;
  }, [region]);

  const { deviceGeoLocation, devicesOnMap } = useMemo(() => {
    const deviceGeoLocation =
      data?.deviceStats?.gps && data?.deviceStats?.gps?.Lat && data?.deviceStats?.gps?.Lng
        ? {
            lat: data.deviceStats.gps.Lat,
            lng: data.deviceStats.gps.Lng
          }
        : null;
    return {
      deviceGeoLocation,
      devicesOnMap: deviceGeoLocation ? [data] : []
    };
  }, [data]);

  const [message, setMessage] = useState('');

  const mapRef = useRef();
  const [mapOptions, setMapOptions] = useState({
    scrollwheel: false,
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.TOP_RIGHT
    }
  });

  const onGeosuggestSuccess = useCallback(
    suggest => {
      if (suggest?.location) {
        setSelectedLocation(
          suggest?.location,
          false,
          suggest?.description || suggest?.geofenceName
        );
      } else {
        setSelectedLocation(deviceGeoLocation);
      }
    },
    [deviceGeoLocation]
  );

  const selectedTripSegment = useMemo(() => {
    const origin = selectedLocation;
    const destination = deviceGeoLocation;
    if (
      origin &&
      destination &&
      isLatLngValid(origin.lat, origin.lng) &&
      isLatLngValid(destination.lat, destination.lng)
    ) {
      const isEqualLatLng =
        Number(origin.lat) === Number(destination.lat) &&
        Number(origin.lng) === Number(destination.lng);
      return !isEqualLatLng && { origin, destination };
    } else {
      return;
    }
  }, [deviceGeoLocation, selectedLocation]);

  const onCancel = () => {
    handleCancel();
    setSelectedLocation(null);
    setMessage('');
    geoSuggestRef.current.clear();
    onModalClose();
  };

  useEffect(() => {
    setMapOptions(prev => ({
      ...prev,
      zoom:
        !selectedLocation && !deviceGeoLocation
          ? regionGeoLocation?.zoom || 4
          : getZoomLevel(new google.maps.LatLngBounds(), [selectedLocation, deviceGeoLocation], {
              zoom: mapRef.current?.state?.map?.getZoom(),
              height: mapRef.current?.state?.map?.getDiv().clientHeight,
              width: mapRef.current?.state?.map?.getDiv().clientWidth
            }),
      center: selectedLocation || regionGeoLocation
    }));
  }, [deviceGeoLocation, selectedLocation, mapRef.current, regionGeoLocation]);

  const showLocationMarker = useMemo(
    () => selectedTripSegment || (selectedLocation && !deviceGeoLocation),
    [selectedTripSegment, selectedLocation]
  );

  const onSubmit = () => {
    dispatch(
      sendRouteToMessage({
        message,
        address: locationName,
        location: selectedLocation,
        imei: data.routeToDevice,
        t
      })
    );
    onCancel();
  };

  const handleChange = e => {
    setMessage(e.target.value);
  };

  const onDragEnd = useCallback(
    mapLocation => {
      if (mapLocation?.latLng) {
        const selected = {
          lat: mapLocation.latLng.lat(),
          lng: mapLocation.latLng.lng()
        };
        setSelectedLocation(selected, true);
      }
    },
    [deviceGeoLocation]
  );

  const disableSubmit = useMemo(() => {
    return !message || message.length === 0 || !locationName;
  }, [locationName, message]);

  return (
    <Modal
      title={title}
      open={isOpen}
      onOk={() => {}}
      onCancel={onCancel}
      width={1024}
      footer={null}
      wrapClassName={styles.modalWrapper}
    >
      <div className={styles.form}>
        <div className={styles.row}>
          <div className={styles.label}>{t('Common.Address')}</div>
          <GeoSuggest
            initialValue={locationName}
            ref={geoSuggestRef}
            inputClassName={styles.geosuggest}
            placeholder={t('Common.TypeHere')}
            onSuggestSelect={onGeosuggestSuccess}
            {...geoSuggestWithGeofences}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.label}>{t('Common.Message')}</div>
          <TextArea
            placeholder={t('Common.WriteSomething')}
            className={styles.textarea}
            value={message}
            onChange={handleChange}
            rows={5}
          ></TextArea>
        </div>
        <div className={styles.row}>
          <Button
            type="primary"
            size="large"
            onClick={onSubmit}
            disabled={disableSubmit}
            id={BUTTON_IDS.routeToSendMessage}
          >
            {t('Common.SendMessage')}
          </Button>
        </div>
      </div>
      <div className={styles.map}>
        <Map
          ref={mapRef}
          mode={MapMode.RouteTo}
          location={showLocationMarker && selectedLocation}
          mapOptions={mapOptions}
          selectedTripSegment={selectedTripSegment}
          devices={devicesOnMap}
          draggableMarker
          onDragEnd={onDragEnd}
          useDefaultLocationMarker={true}
          containerElement={<div style={{ height: `640px`, width: `100%` }} />}
          mapElement={<div style={{ height: `100%`, width: `100%` }} />}
        />
      </div>
    </Modal>
  );
};
