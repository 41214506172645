import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser as setSentinelUser } from './user/sentinelUserSlice';
import { Link } from 'react-router-dom';
import { fetchUserRulesets, clearRulesets } from './rulesets/rulesetsSlice';
import { EllipsisMenu } from 'components/tables/EllipsisMenu';
import moment from 'moment';
import { Can, services } from 'features/permissions';
import { MessageComposeModal } from 'containers/Messaging/MessageComposeModal';
import { RECIPIENT_TYPE } from 'containers/Messaging/constants';
import { Pill, PillColor } from 'components/visualization/Pill';
import { ExpandButton } from 'components/ant/Button/ExpandButton';
import styles from './CellRenderer.module.scss';
import {
  Reseting,
  RestDueTooltip,
  TimeTooltip,
  Tooltip,
  Violations,
  ViolationsTooltip
} from 'features/fatigue/Components';
import { durationFormat, formatTime } from 'features/fatigue/time_util';

export const LinkCell = ({ rowData }) => {
  const dispatch = useDispatch();
  function handleClick(id) {
    dispatch(setSentinelUser({ id: parseInt(id, 10) }));
    dispatch(clearRulesets());
    dispatch(fetchUserRulesets(parseInt(id, 10)));
  }
  return (
    <Link to={'/fatigue/sentinel/' + rowData.id} onClick={() => handleClick(rowData.id)}>
      {rowData.firstName + ' ' + rowData.lastName}
    </Link>
  );
};

const meatballMenuItems = (rowData, showMessageModal) => {
  const fromDate = moment()
    .subtract(24, 'hours')
    .valueOf();
  const canMessaging = rowData.canMessaging;
  return [
    {
      label: 'Work Diary View',
      link: `/fatigue/sentinel/workdiary/${rowData.id}/?date=${fromDate}`,
      id: 'btn_sentinelWorkDiary'
    },
    canMessaging && {
      label: 'Message Driver',
      renderProp: () => (
        <Can oneOfServices={[services.MESSAGING]}>
          <Link id="btn_sentinelMessageDriver" onClick={showMessageModal}>
            Message Driver
          </Link>
        </Can>
      )
    },
    {
      label: 'View Driver Details',
      link: `/settings/users/id/${rowData.id}`,
      id: 'btn_sentinelViewDriver'
    },
    {
      label: 'Edit Driver Detail',
      link: `/settings/users/edit/id/${rowData.id}`,
      id: 'btn_sentinelEditDriver'
    }
  ].filter(menuItem => !!menuItem);
};

export const MeatballsMenu = ({ rowData }) => {
  const [showPopup, setShowPopup] = useState(false);
  const recipient = { recipientType: RECIPIENT_TYPE.DRIVER, recipientId: rowData.id };
  const menuItems = useCallback(() => {
    return meatballMenuItems({ id: rowData.id, canMessaging: rowData.canMessaging }, () =>
      setShowPopup(prev => !prev)
    );
  }, [rowData.id, rowData.canMessaging]);

  return (
    <>
      <EllipsisMenu menuItems={menuItems} />
      {showPopup && (
        <MessageComposeModal
          visible={showPopup}
          showModal={setShowPopup}
          recipients={[recipient]}
        />
      )}
    </>
  );
};

const CellPill = ({ max, worked, period }) => {
  const legend = useCallback(() => {
    return (
      <div className={styles.hoursworked}>
        <span>{formatTime(worked || 0) + ' / '}</span>
        <span>{formatTime(max || 0)}</span>
      </div>
    );
  }, [max, worked]);

  const tooltip = useCallback(() => {
    if (period) {
      return <Tooltip type={'Period'} startAt={period.startAt} endAt={period.finishAt} />;
    } else {
      return null;
    }
  }, [period]);

  return (
    <Pill
      value={worked}
      max={max}
      pillColor={worked >= max ? PillColor.Red : PillColor.Green}
      legend={legend}
      tooltip={tooltip}
    />
  );
};

export function availableCellRenderer({ dataKey, rowData, ...props }) {
  if (
    !props.cellProps?.isScrolling &&
    rowData?.sentinelStatus &&
    rowData.sentinelStatus.totals?.period?.finishAt > moment().valueOf()
  ) {
    const max = rowData.sentinelStatus.totals?.available + rowData.sentinelStatus.totals?.worked;
    return (
      <CellPill
        max={max}
        worked={rowData.sentinelStatus.totals?.worked}
        period={rowData.sentinelStatus.totals?.period}
      />
    );
  }
  return '';
}

export function hoursWorkedCellRenderer({ dataKey, rowData, ...props }) {
  if (rowData?.sentinelStatus?.work24?.work) {
    return durationFormat(rowData?.sentinelStatus.work24.work * 1000);
  }
  return '';
}

const restFieldKeys = {
  '24HrRest': rowData => rowData.sentinelStatus['24HrRest']?.nextStartAt,
  '2NightRest': rowData => rowData.sentinelStatus['2NightRest']?.nextStartAt,
  nightRest: rowData => rowData.sentinelStatus['nightRest']?.nextStartAt
};

const RestDesc = {
  '24HrRest': '24 Hours Rest',
  '2NightRest': 'Two Night Rest',
  nightRest: 'Night Rest'
};

export function majorRestDueCellRenderer({ dataKey, rowData, ...props }) {
  if (
    !props.cellProps?.isScrolling &&
    rowData?.sentinelStatus &&
    rowData?.sentinelStatus.lastStatusEvent
  ) {
    const nextRestDueKey = Object.keys(restFieldKeys).reduce((key, currKey) => {
      if (restFieldKeys[key](rowData) > restFieldKeys[currKey](rowData)) {
        return currKey;
      } else {
        return key;
      }
    });

    const isInactive =
      rowData?.sentinelStatus &&
      (rowData?.sentinelStatus.lastStatusEvent == null ||
        moment()
          .subtract(7, 'day')
          .isAfter(moment(rowData.sentinelStatus.lastStatusEvent.timeAt)));

    if (rowData?.sentinelStatus?.[nextRestDueKey] && !isInactive) {
      const startAt = restFieldKeys[nextRestDueKey](rowData);
      const tooltip = () =>
        rowData?.sentinelStatus?.[nextRestDueKey].nextPeriod && (
          <Tooltip
            type={RestDesc[nextRestDueKey]}
            startAt={startAt}
            endAt={rowData.sentinelStatus[nextRestDueKey].nextFinishAt}
          />
        );
      return <TimeTooltip time={startAt} tooltip={tooltip} />;
    }
  }
  return '';
}

export function nextRestDueCellRenderer({ dataKey, rowData, ...props }) {
  if (props.cellProps?.isScrolling) return '';

  //always check currentViolations
  if (rowData?.sentinelStatus?.currentViolations?.length > 0) {
    const isPotentialViolation = rowData.sentinelStatus.currentViolations.every(
      v => v.startAt > rowData.sentinelStatus.checkpointAt
    );

    const violtionTooltips = (
      <ViolationsTooltip violations={rowData.sentinelStatus.currentViolations} />
    );
    return (
      <Violations
        tooltip={violtionTooltips}
        className={styles.otherViolation}
        isPotentialViolation={isPotentialViolation}
      />
    );
  }

  //if no lastStatus event or last status event is 7 days prior
  if (
    rowData?.sentinelStatus &&
    (rowData?.sentinelStatus.lastStatusEvent == null ||
      moment()
        .subtract(7, 'day')
        .isAfter(moment(rowData.sentinelStatus.lastStatusEvent.timeAt)))
  ) {
    return <span style={{ whiteSpace: 'normal' }}>No Events In the Prior 7 Days</span>;
  }

  //if is resting
  const isResting = [/rest/i, /offduty/i, /sleep/i].some(reg =>
    rowData?.sentinelStatus?.lastStatusEvent?.type?.match(reg)
  );
  if (isResting) {
    return <Reseting />;
  }

  const isInactive =
    rowData?.sentinelStatus &&
    (rowData?.sentinelStatus.lastStatusEvent == null ||
      moment()
        .subtract(7, 'day')
        .isAfter(moment(rowData.sentinelStatus.lastStatusEvent.timeAt)));

  if (rowData?.sentinelStatus?.nextViolation && !isInactive) {
    const startAt = rowData.sentinelStatus.nextViolation?.startAt;

    const tooltip = () =>
      rowData.sentinelStatus.nextViolation?.rule && (
        <RestDueTooltip nextViolation={rowData.sentinelStatus.nextViolation} />
      );
    return (
      <TimeTooltip
        time={startAt}
        tooltip={tooltip}
        status={rowData.sentinelStatus.lastRelevantEvent?.type}
        className={
          rowData.sentinelStatus.nextViolation?.type === 'PREDICTED'
            ? styles.predictedViolation
            : styles.otherViolation
        }
      />
    );
    /*
    return (
    <Pill value={rowData.sentinelStatus.nextViolation.rest}
      max={rowData.sentinelStatus.nextViolation.rested + rowData.sentinelStatus.nextViolation.rest}
      legend={formatTime(rowData.sentinelStatus.nextViolation.rest)}
      tooltip={<Tooltip
        type={rowData.sentinelStatus.nextViolation.type}
        startAt={rowData.sentinelStatus.nextViolation.start}
        endAt={rowData.sentinelStatus.nextViolation.start + rowData.sentinelStatus.nextViolation.rest * 1000} />}
    />);*/
  }
  return '';
}

const ExpandButtonCell = ({ rowIndex, rowData, ...props }) => {
  const style = { width: '100%', textAlign: 'right' };
  const handleExpand = useCallback(
    expand => {
      return rowData.onExpand(rowIndex, expand);
    },
    [rowIndex, rowData]
  );

  return (
    <div style={style}>
      <ExpandButton onExpand={handleExpand} defaultExpand={rowData.expand} />
    </div>
  );
};

export function expandCellRenderer({ dataKey, rowData, rowIndex, ...props }) {
  return <ExpandButtonCell rowIndex={rowIndex} rowData={rowData} />;
}
