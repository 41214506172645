import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const pageSlice = createSlice({
  initialState: {
    title: '',
    backButton: false,
    backButtonLinks: {},
    breadcrumbs: []
  },
  name: 'page',
  reducers: {
    setPageTitle: (state, { payload }) => {
      if (state.title === payload) {
        return;
      }
      state.title = payload;
    },
    setBackButton: (state, { payload }) => {
      if (state.backButton === payload) {
        return;
      }
      state.backButton = payload;
    },
    setBackButtonLinks: (state, { payload }) => {
      state.backButtonLinks = payload;
    },
    addBackButtonLink: (state, { payload }) => {
      state.backButtonLinks[payload.url] = payload.backLink;
    },
    addBreadcrumbs: (state, { payload }) => {
      state.breadcrumbs = payload;
    }
  }
});

export const usePageTitle = () => useSelector(state => state.page.title);
export const useBackButton = () => useSelector(state => state.page.backButton);
export const useBackButtonLinks = () => useSelector(state => state.page.backButtonLinks);
export const useBreadcrumbs = () => {
  const breadcrumbs = useSelector(state => state.page.breadcrumbs);
  return breadcrumbs.map(route => ({ ...route, path: route?.path || '#' }));
};

export const {
  setPageTitle,
  setBackButton,
  setBackButtonLink,
  addBackButtonLink,
  addBreadcrumbs
} = pageSlice.actions;
export default pageSlice.reducer;
