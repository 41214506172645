import React, { useState, useEffect, useMemo } from 'react';
import { Input, Select, Space, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetQsUserQuery } from 'services/nextgen';
import {
  SortOrderOptions,
  SortOrdersById,
  ReportsCategoryFilterOptions,
  ReportsCategoryFilters,
  ReportsCategoryFiltersById
} from '../constants';
import { getReportCategoryByType } from 'containers/Reports/constants';

import { useCurrentCompany } from 'features/company/companySlice';
import { useCan, services } from 'features/permissions';

import styles from './toolbars.module.scss';

const { Search } = Input;

export const isReportCategory = (report, categoryId) => {
  const type = report.type;
  //const reportCategory = getReportCategoryByType(type);
  //const hasConstCategory = ReportsCategoryFiltersById[reportCategory]?.reports?.includes(type);
  const hasConstCategory = ReportsCategoryFiltersById[categoryId]?.reports?.includes(type);

  return hasConstCategory;
};

export const getReportCategoryLabels = report => {
  return ReportsCategoryFilterOptions.filter(category =>
    /*category.value >*/ isReportCategory(report, category.value)
  ).map(filter => filter.label);
};

export const getReportCategoryLabel = report => {
  const firstMatch = ReportsCategoryFilterOptions.find(category =>
    isReportCategory(report, category.value)
  );
  return firstMatch ? firstMatch.label : '';
};

export const getReportCategoryIds = report => {
  return ReportsCategoryFilterOptions.filter(category =>
    /*category.value >*/ isReportCategory(report, category.value)
  ).map(filter => filter.value);
};

export const ReportsPageToolbar = ({
  count,
  onSearch,
  onSort,
  onFilter,
  onViewChange,
  viewType,
  showIframe,
  reports,
  ...props
}) => {
  const { t } = useTranslation();
  const currentCompany = useCurrentCompany();
  const can = useCan();
  const qsUser = useGetQsUserQuery()?.data;
  const isQsUserAuthor = qsUser?.role?.toUpperCase() === 'AUTHOR';

  /*const canCategoryOptions = ReportsCategoryFilterOptions.filter(
    option =>
      !(
        [ReportsCategoryFilters.Published, ReportsCategoryFilters.Unpublished].includes(
          option.value
        ) ||
        (!isQsUserAuthor && option.value === ReportsCategoryFilters.Unpublished)
      )
  );*/

  const handleGetCategories = reports => {
    const categories = [];

    reports.map(r => categories.push(getReportCategoryByType(r.type)));

    const cat2 = [...new Set(categories)];

    return ReportsCategoryFilterOptions.filter(
      option => cat2.includes(option.key) || option.value === 0
    );
  };

  const canCategoryOptions = handleGetCategories(reports);

  const canCategoryValues = canCategoryOptions.map(option => option.value);

  // const [isCategorySelectOpen, setIsCategorySelectOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [sortOrder, setSortOrder] = useState();
  const [categories, setCategories] = useState(canCategoryValues);

  useEffect(() => {
    setCategories(canCategoryValues);
  }, [reports]);

  let imgSource =
    viewType === 'grid'
      ? require('../Images/grid-view.svg').default
      : require('../Images/tile-view.svg').default;

  useEffect(() => {
    setSearchText('');
    setSortOrder();
    setCategories(canCategoryValues);
  }, [currentCompany]);

  useEffect(() => {
    setCategories(canCategoryValues);
  }, [isQsUserAuthor]);

  const handleSearchChange = e => {
    const input = e?.target?.value;

    setSearchText(input);
    onSearch && onSearch(input);
  };

  const handleSortChange = e => {
    const sortId = Number(e?.key || e);

    setSortOrder(SortOrdersById[sortId]);
    onSort && onSort(sortId);
  };

  const handleCategoryChange = (category, checked) => {
    const tags = checked
      ? [...categories, ...(!categories.includes(category) && [category])]
      : categories.filter(categoryId => categoryId !== category);

    handleCategoriesChange(tags);
  };

  const handleCategoriesChange = categoryIds => {
    let added = categoryIds?.filter(x => !categories?.includes(x));
    let removed = categories?.filter(x => !categoryIds?.includes(x));

    const newCategories =
      added.length && added[0] === 0 // select all
        ? canCategoryValues
        : removed.length && removed[0] === 0 // deselect all
        ? []
        : removed.length // removed item
        ? categoryIds.filter(id => id !== ReportsCategoryFilters.All)
        : added.length && categoryIds.length + 1 === canCategoryOptions.length // added last item
        ? [0, ...categoryIds]
        : categoryIds;

    setCategories(newCategories);
    onFilter && onFilter(newCategories);
  };

  let imgView;
  try {
    imgView = require(`../Images/tile-view.svg`);
  } catch {
    imgView = '';
  }

  return (
    <header className={styles.reportsToolbar}>
      <Search
        className={styles.searchbox}
        placeholder={t('Common.SearchPlaceholder')}
        value={searchText}
        onChange={handleSearchChange}
        allowClear
        {...props}
      />

      <Select
        className={styles.sortSelect}
        options={SortOrderOptions}
        value={sortOrder}
        onChange={handleSortChange}
        onDropdownVisibleChange={isVisible => {
          !sortOrder && isVisible && setSortOrder(SortOrdersById[0]);
        }}
        placeholder={'Sort'}
      />

      {/*<span className={styles.count}>
        {`${count === undefined ? 0 : count} ${
          count === 1 ? t('Insights.Report') : t('Insights.Reports')
        }`}
      </span>*/}

      <br />
      {!showIframe && (
        <>
          <Space className={styles.categoriesList} size={10}>
            {canCategoryOptions.map(category => (
              <Tag.CheckableTag
                checked={categories?.includes(category.value)}
                onChange={checked => handleCategoryChange(category.value, checked)}
                key={category.value}
                disabled
              >
                {t(`Insights.Tags.${category.label}`)}
              </Tag.CheckableTag>
            ))}
          </Space>

          {viewType && (
            <img
              className={styles.viewSwitch}
              alt={t(`Insights.TileView`)}
              src={imgSource}
              title={t(`Insights.SwitchView`)}
              onClick={onViewChange}
            />
          )}
        </>
      )}
    </header>
  );
};
