import React from 'react';
import { Marker } from 'react-google-maps';

export const ClusterMarker = ({ cluster, onClusterClick }) => {
  const { markers, ...position } = cluster;

  const labelStyle = {
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 'bold'
  };

  const getIcon = markers => {
    let iconId;
    switch (true) {
      case markers > 500:
        iconId = 5;
        break;
      case markers > 200:
        iconId = 4;
        break;
      case markers > 100:
        iconId = 3;
        break;
      case markers > 10:
        iconId = 2;
        break;
      default:
        iconId = 1;
        break;
    }
    return {
      url: require(`./images/m${iconId}.png`),
      anchor: { x: 16, y: 16 }
    };
  };

  const onClick = () => {
    onClusterClick(position);
  };

  return (
    <Marker
      position={position}
      icon={getIcon(markers)}
      label={{
        text: markers.toString(),
        ...labelStyle
      }}
      onClick={onClick}
    />
  );
};
