import React from 'react';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import styles from './Breadcrumb.module.scss';

export const Breadcrumb = props => {
  return (
    <div className={styles.breadcrumb}>
      <AntBreadcrumb {...props} />
    </div>
  );
};
