import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useEWDStatus, fetchEWDStatusByDriverId } from './statusSlice';
import { useEwdDriverId } from '../drivers/driversSlice';
import { useDispatch, useSelector } from 'react-redux';
import { actionTypes } from 'utils/reduxFetchingUtils';
import { Descriptions } from 'antd';
import { durationFormat, toNowStamp } from 'features/fatigue/time_util';
import { useLocalization } from 'features/localization/localizationSlice';

const StatusLineOrNull = (label, value) => {
  if (value) {
    return <Descriptions.Item label={label}>{value}</Descriptions.Item>;
  }
  return null;
};

const EWDStatus = () => {
  const ewdStatus = useEWDStatus();
  const dispatch = useDispatch();
  const driverId = useEwdDriverId();
  const EWDStatus = useSelector(state => state.ewd.EWDStatus.byDriverID)[driverId];
  const fetching = useSelector(state => state.ewd.EWDStatus.byDriverIDStatus?.[driverId]?.fetching);
  const localization = useLocalization();

  useEffect(() => {
    if (driverId > 0 && EWDStatus === undefined && fetching === actionTypes.init) {
      dispatch(fetchEWDStatusByDriverId(driverId));
    }
  }, [EWDStatus, dispatch, driverId, fetching]);

  if (!ewdStatus) {
    return (
      <div style={{ padding: '24px' }}>
        <Row className="justify-content-md-center">
          <Col className="p-3" xs="auto">
            <p>No status in this time period!</p>
          </Col>
        </Row>
      </div>
    );
  }

  const { totals, longNightTotals, nightRest } = ewdStatus;
  const rest24Hour = ewdStatus['24HrRest'];
  const rule84Hour = ewdStatus['84HrRule'];

  return (
    <>
      {ewdStatus && (
        <Descriptions layout="horizontal" column={1} bordered>
          {StatusLineOrNull('Hours worked', durationFormat(totals?.work * 1000))}

          {StatusLineOrNull('Hours available', durationFormat(totals?.available * 1000))}

          {StatusLineOrNull(
            `Long/night worked`,
            durationFormat((longNightTotals?.excess + longNightTotals?.night) * 1000)
          )}

          {StatusLineOrNull(
            'Last 24hr rest',
            toNowStamp(
              rest24Hour?.lastFinishAt,
              localization.formats.time.formats.dby_hms_z,
              localization.formats.time.formats.dmY_imp
            )
          )}

          {StatusLineOrNull(
            '24hr rest due',
            toNowStamp(
              rest24Hour?.nextStartAt,
              localization.formats.time.formats.dby_hms_z,
              localization.formats.time.formats.dmY_imp
            )
          )}

          {StatusLineOrNull('Work since 24hr rest', durationFormat(rule84Hour?.work * 1000))}

          {StatusLineOrNull('Night rests', nightRest?.count)}

          {StatusLineOrNull(
            'Night rest due',
            toNowStamp(
              nightRest?.nextStartAt,
              localization.formats.time.formats.dby_hms_z,
              localization.formats.time.formats.dmY_imp
            )
          )}
          {/* <pre>{JSON.stringify(ewdStatus, null, 2)}</pre> */}
        </Descriptions>
      )}
    </>
  );
};

export default EWDStatus;
