export const prepareAuthHeaders = (headers, { getState }) => {
  const token = getState().user.current.auth.key;

  if (token) {
    headers.set('authorization', `Token token="${token}"`);
  }

  return headers;
};

export const getAuthHeader = getState => {
  const token = getState().user.current.auth.key;

  return token
    ? {
        authorization: `Token token="${token}"`
      }
    : {};
};

//TEMPORARY method for the B2B Integrations, for the events query
export const prepareB2BIntegrationsAuthHeaders = (headers, { getState }) => {
  const token = getState().user.current.auth.key;

  if (token && !headers.has('authorization')) {
    headers.set('authorization', `Token token="${token}"`);
  }

  return headers;
};
