import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { CameraHealthOverview } from './CameraHealth/CameraHealthOverview';
import { DiagnosticsModal } from './CameraHealth/DiagnosticsModal';

import styles from './CameraHealthTab.module.scss';

export function CameraHealthTab() {
  const { t } = useTranslation();
  const [isViewMore, setIsViewMore] = useState(false);
  const toggleView = useCallback(() => setIsViewMore(prev => !prev), []);

  return (
    <div id="healthTab" className={styles.container}>
      <div className={styles.topBar}>
        <Button className={styles.viewMore} size="small" onClick={toggleView}>
          {t('Common.View More')}
        </Button>
      </div>
      <CameraHealthOverview />
      <DiagnosticsModal open={isViewMore} onClose={toggleView} />
    </div>
  );
}
