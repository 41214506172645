/* eslint-disable import/prefer-default-export */
import i18n from 'i18next';
import { MULTISELECT_TYPE } from '../constants';

const getJoinedValues = values =>
  values
    ?.reduce((accumulator, val) => {
      val?.name && accumulator.push(val.name);
      return accumulator;
    }, [])
    ?.join(', ');

export const getAlertFormOptions = (values, type, options) => {
  switch (type) {
    case MULTISELECT_TYPE.FORMS:
      return values?.map?.(form => ({
        id: form?.Id,
        firstColumnValue: form?.Name,
        secondColumnValue: form?.companyName,
        thirdColumnValue: getJoinedValues(form?.fleets)
      }));
    case MULTISELECT_TYPE.GEOFENCES:
    case MULTISELECT_TYPE.MANAGED_GEOFENCES:
      return values?.map?.(geofence => ({
        id: geofence?.id,
        firstColumnValue: geofence.name,
        secondColumnValue: getJoinedValues(geofence?.fleets)
      }));
    case MULTISELECT_TYPE.PRETRIP_CHECKLISTS:
      return values?.map?.(pretrip => ({
        id: pretrip?.id,
        firstColumnValue: pretrip?.name,
        secondColumnValue: pretrip?.companyName,
        thirdColumnValue: getJoinedValues(pretrip?.fleets)
      }));
    case MULTISELECT_TYPE.FLEETS:
      return values?.map?.(fleet => ({
        id: fleet?.id,
        firstColumnValue: fleet?.name,
        secondColumnValue: fleet?.description,
        thirdColumnValue: getJoinedValues(fleet?.vehicles)
      }));
    case MULTISELECT_TYPE.VEHICLES:
      return values?.map?.(vehicle => ({
        id: vehicle?.id,
        firstColumnValue: vehicle?.name,
        secondColumnValue: vehicle?.registration,
        thirdColumnValue: getJoinedValues(vehicle?.fleets)
      }));
    case MULTISELECT_TYPE.DRIVERS:
      return values?.map?.(driver => ({
        id: driver?.id,
        firstColumnValue: `${driver?.firstName} ${driver?.lastName}`,
        secondColumnValue: driver?.licenceNumber,
        thirdColumnValue: driver?.branch
      }));
    case MULTISELECT_TYPE.BRANCHES:
      return values?.map?.(branch => ({
        id: branch?.id,
        firstColumnValue: branch?.name,
        secondColumnValue: branch?.drivers?.join(', ')
      }));
    case MULTISELECT_TYPE.USERS:
    case MULTISELECT_TYPE.SECONDALERTUSERS:
    case MULTISELECT_TYPE.THIRDALERTUSERS:
      return values?.map?.(user => ({
        id: user?.id,
        firstColumnValue: `${user?.firstName} ${user?.lastName}`,
        secondColumnValue: options?.branches?.find(l => l.id === user?.location?.id)?.name,
        thirdColumnValue: user?.type?.name
      }));
    case MULTISELECT_TYPE.GPIO:
      return values?.map?.(gpio => ({
        value: gpio.key,
        label: i18n.t(`Alerts.GPIO.${gpio.key}`, gpio.key)
      }));
    case MULTISELECT_TYPE.DOCUMENTEXPIRATIONFOLDERS:
      return values?.map?.(folder => ({
        id: folder?.id,
        firstColumnValue: folder?.name
      }));
    default:
      return [];
  }
};
