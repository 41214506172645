import React, { useCallback } from 'react';
import { Select } from 'antd';
import { useDrivers } from 'features/users/usersSlice';
import { useTranslation } from 'react-i18next';

export function DriverSearchInput({ value, onChange, primaryDriverId, ...props }) {
  const drivers = useDrivers();
  const handleFilter = useCallback((input, option) => {
    return option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }, []);
  const { t } = useTranslation();

  return (
    <Select
      filterOption={handleFilter}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      onChange={onChange}
      optionFilterProp="children"
      placeholder={t('ELD.Select a Driver')}
      prefix="\e918"
      showSearch={true}
      value={value}
      {...props}
    >
      {drivers &&
        drivers
          .filter(d => d.id !== primaryDriverId)
          .map((d, idx) => (
            <Select.Option key={idx} value={d.id}>
              {d.firstName + ' ' + d.lastName}
            </Select.Option>
          ))}
    </Select>
  );
}
