import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';

import { Menu } from 'components/ant';
import { Button, Modal, Form } from 'antd';
import { rowRenderer, isFormValid, translateItemsTypes } from '../utils/helpers';
import { JobItems, LocationType } from '../utils/constants';
import { useItemTypes } from 'features/smartJobs/smartJobsSlice';
import { DocumentsDisplay as ItemsTable } from 'components/Documents';
import style from './EditJob.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { getFormRows, JobKeys, getLocationLabel, isFormDirty } from './helpers';
import { saveRunsheet } from 'features/smartJobs';

import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { BUTTON_IDS } from 'utils/globalConstants';

export const EditJobModal = ({ isOpen, onCancel, job = {}, locations, runsheet }) => {
  const { t } = useTranslation();
  const [isDirty, setIsDirty] = useState(false);

  const [jobForm] = Form.useForm();
  const [itemsForm] = Form.useForm();
  const localization = useLocalization();
  const dispatch = useDispatch();

  const jobToForm = () => {
    if (!job) {
      return {};
    }

    const location = getLocationLabel(job);
    return {
      id: job.id,
      customerName: job.customerName,
      jobType: job.type,
      locationType: job.stop?.location?.type?.code,
      notes: job.notes || '',
      jobItems: job.jobItems || [],
      location
    };
  };

  const initialValues = jobToForm();
  const [currentJob, setCurrentJob] = useState(initialValues);

  useEffect(() => {
    jobForm.setFieldsValue(currentJob);

    // If we have any changes set is form to be dirty
    const dirty = isFormDirty(initialValues, currentJob);

    if (dirty) {
      setIsDirty(true);
    }
  }, [currentJob]);

  const [selectedMenuItem, selectMenuItem] = useState(JobKeys.DETAILS);

  const itemTypes = useItemTypes();
  const formValues = jobForm.getFieldValue();

  const isFormDetailsValid =
    !Object.keys(formValues).length ||
    isFormValid(formValues, [
      JobItems.NAME.key,
      JobItems.LOCATION_TYPE.key,
      JobItems.LOCATION.key,
      JobItems.JOB_TYPE.key
    ]);

  const itemsTableColumns = [
    { ...JobItems.QUANTITY, name: t(`SmartJobs.Input.Label.${JobItems.QUANTITY.key}`) },
    { ...JobItems.ITEM_TYPE, name: t(`SmartJobs.Input.Label.${JobItems.ITEM_TYPE.key}`) },
    { ...JobItems.DESCRIPTION, name: t(`SmartJobs.Input.Label.${JobItems.DESCRIPTION.key}`) },
    { ...JobItems.WEIGHT, name: t(`SmartJobs.Input.Label.${JobItems.WEIGHT.key}`) },
    { ...JobItems.ACTIONS, name: t(`SmartJobs.Input.Label.${JobItems.ACTIONS.key}`) }
  ];

  // MODAL CONTROLS
  const handleNext = e => {
    e.preventDefault();

    selectMenuItem(JobKeys.ITEMS);
  };

  const formatAddressLocation = (stop, initialStop) => {
    if (!stop) {
      const { status, ...actualStop } = initialStop?.location || {};
      return actualStop;
    }

    const { location, label } = stop;
    return {
      GPS: {
        Lat: location?.lat,
        Lng: location?.lng
      },
      type: { code: LocationType.ADDRESS },
      name: label,
      externalId: uuidv4()
    };
  };

  const handleSaveJob = async e => {
    e.preventDefault();

    // Update the runsheet's edited job
    const updatedRunsheet = {
      ...runsheet,
      jobs: runsheet.jobs.map(job => {
        // @TO DO - moVe this to api
        if (job.id === currentJob.id) {
          const locationType = currentJob.locationType;
          const location =
            locationType === LocationType.CUSTOMER
              ? locations.find(l => l.id === currentJob.location)
              : formatAddressLocation(currentJob.realLocation, job.stop);

          return {
            ...job,
            customerName: currentJob.customerName,
            type: currentJob.jobType,
            jobItems: currentJob.jobItems,
            stop: { location },
            notes: currentJob.notes
          };
        }

        return job;
      })
    };
    const updateRunsheet = await dispatch(saveRunsheet(updatedRunsheet, true, t));

    if (!updateRunsheet?.error) {
      onCancel();
    }
  };
  const handleCancel = () => {
    isDirty
      ? confirmationModal(
          t('SmartJobs.Confirmation.confirm'),
          t('SmartJobs.Confirmation.changes'),
          t('SmartJobs.Confirmation.cancel'),
          t('SmartJobs.Confirmation.stay'),
          onCancel
        )
      : onCancel();
  };

  const updateLocation = location => {
    setCurrentJob({
      ...currentJob,
      realLocation: location,
      location: location.description || location
    });
  };

  const footer = () => {
    return (
      <>
        {selectedMenuItem === JobKeys.DETAILS ? (
          <Button
            type="primary"
            onClick={handleNext}
            disabled={!isFormDetailsValid}
            id={BUTTON_IDS.editJobNext}
          >
            {t('SmartJobs.Next')}
          </Button>
        ) : (
          <Button type="primary" onClick={handleSaveJob} id={BUTTON_IDS.editJobFinish}>
            {t('SmartJobs.Finish')}
          </Button>
        )}
        <Button type="secondary" onClick={handleCancel} id={BUTTON_IDS.editJobCancel}>
          {t('SmartJobs.Cancel')}
        </Button>
      </>
    );
  };

  const renderFormItems = t => {
    const defaultLocation = currentJob?.location || job?.stop?.location?.id;

    if (selectedMenuItem === JobKeys.DETAILS) {
      const detailsRows = getFormRows(
        JobKeys.DETAILS,
        {
          locations: locations.map(location => ({ id: location.id, label: location.name }))
        },
        t,
        localization,
        currentJob.locationType,
        { updateLocation },
        { location: defaultLocation, locationType: job?.locationType }
      );

      return rowRenderer(detailsRows);
    }
  };

  const handleValuesChange = (changed, all) => {
    if (changed.locationType) {
      setCurrentJob({ ...currentJob, ...all, location: undefined });
    } else {
      setCurrentJob({ ...currentJob, ...changed });
    }
  };

  const handleAddItem = values => {
    const jobItems = currentJob.jobItems.slice();

    setCurrentJob({
      ...currentJob,
      jobItems: [...jobItems, { item: values, uuid: uuidv4() }]
    });

    itemsForm.resetFields();
  };

  const handleDeleteItem = uuid => () => {
    setCurrentJob({
      ...currentJob,
      jobItems: currentJob.jobItems.filter(item =>
        item?.item?.id ? item.item.id !== uuid : item.uuid !== uuid
      )
    });
  };

  const getItemsTableRows = (items = []) => {
    let updatedItems = items.map(jobItem => {
      const { item, uuid } = jobItem;

      const currentItem = {
        ...item,
        actions: handleDeleteItem(uuid || item.id)
      };

      for (const itemKey in currentItem) {
        // In case we're rendering weight - combine weight with units for the table display
        if (itemKey === JobItems.WEIGHT.key) {
          currentItem[itemKey] = `${currentItem[itemKey] || ''} ${currentItem[JobItems.UNIT.key] ||
            ''}`;
        }
      }
      return currentItem;
    });

    return updatedItems;
  };

  const menuItems = useMemo(() => {
    return [
      {
        key: JobKeys.DETAILS,
        label: t('SmartJobs.addJobDetails')
      },
      {
        key: JobKeys.ITEMS,
        label: t('SmartJobs.addJobItems'),
        disabled: !isFormDetailsValid
      }
    ];
  }, [t, isFormDetailsValid]);

  return (
    <Modal
      title={t('SmartJobs.editJob')}
      open={isOpen}
      onOk={() => {}}
      onCancel={handleCancel}
      footer={footer()}
      width={914}
      centered
    >
      <div style={{ width: '100%' }}>
        <Menu
          onClick={e => selectMenuItem(e.key)}
          selectedKeys={[selectedMenuItem]}
          mode="horizontal"
          items={menuItems}
        />
        <div className={style.jobFormContainer}>
          <Form
            form={jobForm}
            onValuesChange={handleValuesChange}
            name="JobForm"
            initialValues={initialValues}
            layout="vertical"
          >
            {renderFormItems(t)}
          </Form>
        </div>
        {selectedMenuItem !== JobKeys.DETAILS && (
          <Form form={itemsForm} onFinish={handleAddItem} name="ItemForm" layout="vertical">
            {rowRenderer(
              getFormRows(
                JobKeys.ITEMS,
                {
                  types: itemTypes.map(itemType => ({
                    id: itemType.code,
                    label: itemType.description
                  }))
                },
                t,
                localization
              )
            )}
            <Button
              type="secondary"
              htmlType="submit"
              htmlFor="#ItemForm"
              id={BUTTON_IDS.editJobAdd}
            >
              {t('SmartJobs.addItem')}
            </Button>
            {job.jobItems && (
              <ItemsTable
                columns={itemsTableColumns}
                rows={getItemsTableRows(translateItemsTypes(currentJob?.jobItems, itemTypes))}
              />
            )}
          </Form>
        )}
      </div>
    </Modal>
  );
};
