/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";

/**
 * The MessageAck model module.
 * @module model/MessageAck
 * @version 1.0
 */
class MessageAck {
  /**
   * Constructs a new <code>MessageAck</code>.
   * @alias module:model/MessageAck
   */
  constructor() {
    MessageAck.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MessageAck</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MessageAck} obj Optional instance to populate.
   * @return {module:model/MessageAck} The populated <code>MessageAck</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MessageAck();

      if (data.hasOwnProperty("RecordType")) {
        obj["RecordType"] = ApiClient.convertToType(
          data["RecordType"],
          "String"
        );
      }
      if (data.hasOwnProperty("DeviceId")) {
        obj["DeviceId"] = ApiClient.convertToType(data["DeviceId"], "String");
      }
      if (data.hasOwnProperty("DeviceMessageId")) {
        obj["DeviceMessageId"] = ApiClient.convertToType(
          data["DeviceMessageId"],
          "String"
        );
      }
      if (data.hasOwnProperty("EventDateTime")) {
        obj["EventDateTime"] = ApiClient.convertToType(
          data["EventDateTime"],
          "Date"
        );
      }
      if (data.hasOwnProperty("MessageRef")) {
        obj["MessageRef"] = ApiClient.convertToType(
          data["MessageRef"],
          "String"
        );
      }
    }
    return obj;
  }
}

/**
 * @member {String} RecordType
 */
MessageAck.prototype["RecordType"] = undefined;

/**
 * @member {String} DeviceId
 */
MessageAck.prototype["DeviceId"] = undefined;

/**
 * @member {String} DeviceMessageId
 */
MessageAck.prototype["DeviceMessageId"] = undefined;

/**
 * @member {Date} EventDateTime
 */
MessageAck.prototype["EventDateTime"] = undefined;

/**
 * @member {String} MessageRef
 */
MessageAck.prototype["MessageRef"] = undefined;

export default MessageAck;
