import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Row, Col, Input, Button, Select, Tabs, Radio, Form } from 'antd';
import styles from './LogExport.module.scss';
import { DateRangePicker } from 'components/ant/DateTime/DateRangePicker';
import { DriverSearchInput } from '../DriverLog/DriverSearchInput';
import { VehicleSearchInput } from '../DriverLog/VehicleSearchInput';
import moment from 'moment';
import { ApiClient } from 'nextgen_api';
import { API_PATH } from 'config';
import { useUser, useUserKey } from 'features/user/userSlice';
import { useDispatch } from 'react-redux';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { fetchLogExport } from 'features/eld/logExportSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { useTranslation } from 'react-i18next';
import { LogTypeDescription, LogTransferELDService } from './CellRender';
import services from 'features/permissions/services';
import { BUTTON_IDS } from 'utils/globalConstants';

const LOG_TRANSFER_METHOD = {
  Email: 1,
  WebService: 2
};

export function LogTransferModal({ show, onClose, ...props }) {
  var [dateRanges, setDateRanges] = useState([moment().subtract(7, 'days'), moment()]);
  const [comments, setComments] = useState('');
  const [logType, setLogType] = useState('Driver');
  const [driver, setDriver] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const userKey = useUserKey();
  const user = useUser();
  const dispatch = useDispatch();
  const company = useCurrentCompany();

  const { t } = useTranslation();
  var defaultDates = useMemo(() => [dateRanges[0], dateRanges[1]], []);

  const companyELDServices = useMemo(() => {
    const _services = [];
    (company?.features || []).forEach(feature => {
      if (feature.code === services.ELDUS) {
        _services.push(services.ELDUS);
      } else if (feature.code === services.ELDCAN) {
        _services.push(services.ELDCAN);
      }
    });
    return Array.from(new Set(_services));
  }, [company]);

  const initialTransferType = useMemo(
    () => ({
      eldService: companyELDServices.some(s => s === services.ELDUS)
        ? services.ELDUS
        : companyELDServices.some(s => s === services.ELDCAN)
        ? services.ELDCAN
        : null,
      sendAs: LOG_TRANSFER_METHOD.Email,
      emailAddress: null,
      isValid: !!companyELDServices.some(s => s === services.ELDUS)
    }),
    [companyELDServices]
  );

  const [transferType, setTransferType] = useState(initialTransferType);
  const [transferTypeEmailForm] = Form.useForm();

  const {
    logTransferType,
    sendEmail,
    sendToWebService,
    AdditionalEmailees,
    isTransferTypeValid
  } = useMemo(
    () => ({
      logTransferType:
        transferType.eldService === services.ELDCAN
          ? 'Canada'
          : transferType.eldService === services.ELDUS
          ? 'US'
          : null,
      sendEmail: transferType.sendAs === LOG_TRANSFER_METHOD.Email,
      sendToWebService: transferType.sendAs === LOG_TRANSFER_METHOD.WebService,
      AdditionalEmailees: transferType.emailAddress,
      isTransferTypeValid: transferType.isValid
    }),
    [transferType]
  );

  useEffect(() => setTransferType(initialTransferType), [initialTransferType]);

  const handleClose = useCallback(() => {
    setDateRanges([dateRanges[0], dateRanges[1]]);
    setComments('');
    setLogType('Driver');
    setDriver(null);
    setVehicle(null);
    setTransferType(initialTransferType);
    transferTypeEmailForm.resetFields();
    if (onClose) {
      onClose();
    }
  }, [onClose, initialTransferType, transferTypeEmailForm]);

  const handleTransfer = useCallback(async () => {
    if (userKey == null) return;
    setIsSubmitting(true);
    const promise = new Promise((resolve, reject) => {
      const apiClient = new ApiClient();
      apiClient.basePath = API_PATH;

      apiClient.defaultHeaders = {
        Authorization: `Token token="${userKey}"`
      };
      const apiQuery = '/eld/logexport';
      apiClient.callApi(
        apiQuery,
        'POST',
        {},
        {},
        {},
        {},
        {
          companyId: company.id,
          comment: comments,
          logType: logType,
          userId: driver,
          vehicleId: vehicle,
          initiateAt: new Date(),
          initiatedByUserId: user.id,
          periodBegin: dateRanges[0].startOf('day'),
          periodEnd:
            dateRanges[1] && dateRanges[1].startOf('day').isSame(moment().startOf('day'))
              ? moment()
              : dateRanges[1].endOf('day'),
          sendEmail: sendEmail,
          sendToWebService: sendToWebService,
          logTransferType,
          AdditionalEmailees
        },
        [],
        [],
        [],
        null,
        null,
        (err, data, resp) => {
          if (err && (resp == null || resp.status !== 200)) {
            console.error(err);
            reject(err);
          } else {
            resolve(resp.body);
          }
        }
      );
    });

    try {
      await promise;
      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('ELD.Log Transfer request has been submitted')
        })
      );
      dispatch(fetchLogExport(company.id, userKey));
      handleClose();
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: err.toString()
        })
      );
    }

    setIsSubmitting(false);
  }, [
    comments,
    logType,
    driver,
    vehicle,
    user,
    userKey,
    company,
    logTransferType,
    AdditionalEmailees,
    sendEmail,
    sendToWebService,
    dateRanges,
    dispatch,
    handleClose
  ]);

  const availableDateRange = useMemo(() => {
    return [
      moment()
        .subtract(6, 'month')
        .startOf('day'),
      moment().endOf('day')
    ];
  }, []);

  const footer = (
    <Row gutter={10}>
      <Col>
        <Button
          loading={isSubmitting}
          onClick={handleTransfer}
          disabled={
            dateRanges.length < 2 ||
            (driver == null && logType === 'Driver') ||
            (vehicle == null && logType === 'Vehicle') ||
            !isTransferTypeValid ||
            (logTransferType === 'US' && (comments === '' || comments.trim() === ''))
          }
          type="primary"
          id={BUTTON_IDS.logTransfer}
        >
          {t('ELD.Transfer')}
        </Button>
      </Col>
      <Col>
        <Button
          id={BUTTON_IDS.logTransferCancel}
          disabled={isSubmitting}
          type="default"
          onClick={handleClose}
        >
          {t('Common.Cancel')}
        </Button>
      </Col>
    </Row>
  );

  return (
    <Modal
      open={show}
      footer={footer}
      onCancel={handleClose}
      title={t('ELD.Export Logs')}
      maskClosable={false}
      closable={!isSubmitting}
    >
      <div className={styles.logTransferModal}>
        <Row>
          <Col span={24}>
            <p>{t('ELD.Log Type')}</p>
            <Select
              defaultValue="Driver"
              disabled={isSubmitting}
              onChange={val => setLogType(val)}
              value={logType}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              <Select.Option key="driver" value="Driver">
                {t('Common.Driver')}
              </Select.Option>
              <Select.Option key="vehicle" value="Vehicle">
                {t('Common.Vehicle')}
              </Select.Option>
            </Select>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>{LogTypeDescription[logType]}</p>
            {logType === 'Driver' && (
              <DriverSearchInput
                value={driver}
                disabled={isSubmitting}
                onChange={driverId => setDriver(driverId)}
              />
            )}
            {logType === 'Vehicle' && (
              <VehicleSearchInput
                value={vehicle}
                disabled={isSubmitting}
                onChange={vehicleId => setVehicle(vehicleId)}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>{t('ELD.Date Range')}</p>
            <DateRangePicker
              disabled={isSubmitting}
              value={dateRanges}
              maxDayRange={30}
              defaultDates={defaultDates}
              format="MM/DD/YYYY"
              availableDatesRange={availableDateRange}
              onDateRangeChanged={ranges => setDateRanges(ranges)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>{t('ELD.Export Logs to')}:</p>
          </Col>
          <Col span={24}>
            <LogTransterType
              transferType={transferType}
              handleTransferTypeChange={setTransferType}
              disabled={isSubmitting}
              eldServices={companyELDServices}
              transferTypeEmailForm={transferTypeEmailForm}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p className={'Comment_' + logTransferType}>{t('ELD.Comments')}</p>
            <Input.TextArea
              disabled={isSubmitting}
              value={comments}
              rows={6}
              onChange={evt => setComments(evt.target.value)}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
}

function LogTransterType({
  transferType,
  handleTransferTypeChange = () => {},
  disabled,
  eldServices,
  transferTypeEmailForm
}) {
  const { t } = useTranslation();

  const handledELDServiceChanged = useCallback(
    eldService =>
      handleTransferTypeChange({
        eldService,
        sendAs: LOG_TRANSFER_METHOD.Email,
        emailAddress: null,
        isValid: eldService === services.ELDUS
      }),
    []
  );

  const USLogTransferType = ({ USSendAs }) => {
    const handleUSSendAsChanged = useCallback(
      e => handleTransferTypeChange(prev => ({ ...prev, sendAs: e.target.value, isValid: true })),
      []
    );
    return (
      <Col span={12}>
        <Radio.Group
          disabled={disabled}
          onChange={handleUSSendAsChanged}
          defaultValue={LOG_TRANSFER_METHOD.Email}
          value={USSendAs}
        >
          <Radio value={LOG_TRANSFER_METHOD.Email}>{t('ELD.Email')}</Radio>
          <Radio value={LOG_TRANSFER_METHOD.WebService}>{t('ELD.Web Service')}</Radio>
        </Radio.Group>
      </Col>
    );
  };

  const CALogTransferType = ({ show, form }) => {
    const handleEmailAddressChanged = useCallback(([emailField]) => {
      handleTransferTypeChange(prev => ({
        ...prev,
        emailAddress: emailField.value,
        isValid: !emailField?.errors?.length
      }));
    }, []);
    return (
      show && (
        <Form
          form={form}
          onFieldsChange={handleEmailAddressChanged}
          initialValues={{ emailAddress: null }}
          requiredMark={false}
        >
          <Form.Item
            name="emailAddress"
            label={t('ELD.Email')}
            rules={[
              { required: true, message: t('Users.ValidationErrors.RequiredEmail') },
              { type: 'email', message: t('Users.ValidationErrors.InvalidEmail') }
            ]}
          >
            <Input disabled={disabled} />
          </Form.Item>
        </Form>
      )
    );
  };

  const Comp = useMemo(() => {
    const USOnly = eldServices.length === 1 && eldServices[0] === services.ELDUS,
      CAOnly = eldServices.length === 1 && eldServices[0] === services.ELDCAN,
      bothUSAndUS = eldServices.includes(services.ELDUS) && eldServices.includes(services.ELDCAN);
    return ({ transferType }) =>
      USOnly ? (
        <USLogTransferType USSendAs={transferType.sendAs} />
      ) : CAOnly ? (
        <CALogTransferType
          show={transferType.eldService === services.ELDCAN}
          form={transferTypeEmailForm}
        />
      ) : (
        bothUSAndUS && (
          <Tabs
            defaultActiveKey={services.ELDUS}
            onChange={handledELDServiceChanged}
            size="small"
            moreIcon={null}
            activeKey={transferType.eldService}
          >
            <Tabs.TabPane tab={LogTransferELDService[services.ELDUS]} key={services.ELDUS}>
              <USLogTransferType USSendAs={transferType.sendAs} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={LogTransferELDService[services.ELDCAN]} key={services.ELDCAN}>
              <CALogTransferType
                show={transferType.eldService === services.ELDCAN}
                form={transferTypeEmailForm}
              />
            </Tabs.TabPane>
          </Tabs>
        )
      );
  }, [eldServices]);

  return <Comp transferType={transferType} transferTypeEmailForm={transferTypeEmailForm} />;
}
