import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import request from 'superagent';
import { useTranslation } from 'react-i18next';
import { Form, Formik, useFormikContext, Field } from 'formik';
import { Row, Col, FormLabel } from 'react-bootstrap';
import { upperCase } from 'lodash';
import { Space, Button } from 'antd';

import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';
import { openToast } from 'features/toasts/toastsSlice';
import {
  fetchVehicleTypes,
  useVehicleTypes,
  useIsFetching
} from 'features/vehicles/vehicleTypesSlice';
import {
  useDefaultConfiguration,
  useConfigurationValues,
  setConfigurationValues
} from 'features/configuration/configurationSlice';
import { vehicleTypeMetrics } from 'features/configuration/constants';
import {
  fetchFleets,
  useIsFetchingFinished,
  useVehiclesFromFleetsBoth
} from 'features/fleets/fleetsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useFuelEfficiencyPermission } from 'features/vehicles/hooks';
import Can from 'features/permissions/Can';
import { services, companyFeatures } from 'features/permissions';
import { MPTrackingEvents, useFormUpdatedFields } from 'features/mixpanel';
import { useCurrentRegion } from 'features/regions/regionsSlice';
import { REGION_LOCALE } from 'features/localization/localization';
import { useDevicesFeatures } from 'features/devices/devicesSlice';
import { deviceCapabilities } from 'features/permissions/deviceFeaturesByFirmware';

import FormInput from 'components/form/form-input/FormInput';
import FormSelect from 'components/form/form-select/FormSelect';
import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { Select } from 'components/ant';
import { Tooltip } from 'components/ant';

import {
  initialValues,
  validationSchema,
  iconSet,
  speedLimitClassSet,
  FUEL_PROFILES,
  isCo2FuelType,
  FuelFieldKey,
  Paths
} from './constants';
import { API_PATH } from 'config';

import { canHistoryGoBack } from 'utils/methods';
import { parseErrorMessage } from 'utils/strings';
import { setLocalizedValues, getLocalizedValue } from './helpers';

import styles from './VehicleTypes.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const useFuelFields = (t, localization, defaultConfiguration, action) => {
  const hasFuelEfficiencyPermission = useFuelEfficiencyPermission();
  const [showAsPlaceholder, setShowAsPlaceholder] = useState({
    [FuelFieldKey.AVRFuelEfficiency]: false,
    [FuelFieldKey.Co2EmissionsFactor]: false
  });

  const setFuelFieldDisplay = useCallback((fieldName, showAsPlaceholder) => {
    setShowAsPlaceholder(prev => ({ ...prev, [fieldName]: !!showAsPlaceholder }));
  }, []);

  const getFuelFieldCSSCls = useCallback(
    name => (showAsPlaceholder[name] ? styles.defaultFieldValue : ''),
    [showAsPlaceholder]
  );

  const getFuelFieldDefaultValue = useCallback(
    (fieldName, fuelType) => {
      const currentRegion = localization.region;
      const averageFuelEfficiencyConfigKey = vehicleTypeMetrics[FuelFieldKey.AVRFuelEfficiency];
      const co2EmissionsFactorConfigKey = vehicleTypeMetrics[FuelFieldKey.Co2EmissionsFactor];
      try {
        switch (vehicleTypeMetrics[fieldName]) {
          case averageFuelEfficiencyConfigKey:
            let averageFuelEfficiencyDefaultValue = JSON.parse(
              defaultConfiguration[averageFuelEfficiencyConfigKey]?.defaultValue || '{}'
            );
            averageFuelEfficiencyDefaultValue =
              averageFuelEfficiencyDefaultValue[FuelFieldKey.AVRFuelEfficiency]?.find(
                regionConfig => regionConfig?.region === currentRegion
              ) ||
              averageFuelEfficiencyDefaultValue[FuelFieldKey.AVRFuelEfficiency]?.find(
                regionConfig => regionConfig?.region === averageFuelEfficiencyDefaultValue?.default
              );
            return averageFuelEfficiencyDefaultValue?.default;
          case co2EmissionsFactorConfigKey:
            let co2EmissionsFactorDefaultValue = JSON.parse(
              defaultConfiguration[co2EmissionsFactorConfigKey]?.defaultValue || '{}'
            );
            co2EmissionsFactorDefaultValue =
              co2EmissionsFactorDefaultValue[FuelFieldKey.Co2EmissionsFactor]?.find(
                regionConfig => regionConfig?.region === currentRegion
              ) ||
              co2EmissionsFactorDefaultValue[FuelFieldKey.Co2EmissionsFactor]?.find(
                regionConfig => regionConfig?.region === co2EmissionsFactorDefaultValue?.default
              );
            return co2EmissionsFactorDefaultValue && co2EmissionsFactorDefaultValue[fuelType];
          default:
            return;
        }
      } catch (error) {}
    },
    [defaultConfiguration, localization]
  );

  const {
    FuelFields,
    descriptionRows,
    getValidFuelMetricFormValues,
    isApplicableVehicleTypeMetric
  } = useMemo(() => {
    const _FuelFields = FUEL_PROFILES(t, localization);
    return {
      FuelFields: Object.keys(_FuelFields)
        .map(fieldKey => ({
          [fieldKey]: {
            ..._FuelFields[fieldKey],
            showField: formValues =>
              hasFuelEfficiencyPermission && _FuelFields[fieldKey].canShow(formValues),
            name: fieldKey,
            action,
            getFuelFieldDefaultValue,
            setFuelFieldDisplay,
            getFuelFieldCSSCls
          }
        }))
        .reduce((a, c) => ({ ...a, ...c }), {}),
      descriptionRows: (formValues, variation = 0) =>
        hasFuelEfficiencyPermission
          ? !!isCo2FuelType(formValues[FuelFieldKey.FuelType])
            ? 14 + variation
            : 6 + variation
          : 10 + variation,
      getValidFuelMetricFormValues: formValues => {
        const formFuelType = formValues[FuelFieldKey.FuelType] || '';
        return {
          [FuelFieldKey.AVRFuelEfficiency]: isCo2FuelType(formFuelType)
            ? formValues[FuelFieldKey.AVRFuelEfficiency]
            : '',
          [FuelFieldKey.Co2EmissionsFactor]: isCo2FuelType(formFuelType)
            ? formValues[FuelFieldKey.Co2EmissionsFactor]
            : ''
        };
      },
      isApplicableVehicleTypeMetric: metricKey =>
        [FuelFieldKey.AVRFuelEfficiency, FuelFieldKey.Co2EmissionsFactor].some(
          key => key === metricKey
        )
          ? hasFuelEfficiencyPermission
          : true
    };
  }, [
    t,
    localization,
    hasFuelEfficiencyPermission,
    action,
    getFuelFieldDefaultValue,
    setFuelFieldDisplay,
    getFuelFieldCSSCls
  ]);

  return {
    FuelFields,
    descriptionRows,
    getValidFuelMetricFormValues,
    isApplicableVehicleTypeMetric,
    hasFuelEfficiencyPermission
  };
};

export const VehicleTypesForm = ({ action }) => {
  const path = window.location.pathname;
  const vehicleTypeId = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const { t } = useTranslation();
  const localization = useLocalization();

  const companies = useCompanies();
  const dispatch = useDispatch();
  const history = useHistory();
  const userKey = useUserKey();
  const currentCompany = useCurrentCompany();
  const allVehicleTypes = useVehicleTypes();
  const vehicles = useVehiclesFromFleetsBoth();
  const vehiclesFetched = useIsFetchingFinished();
  const [companyVehicleTypes, setCompanyVehicleTypes] = useState([]);
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  const [isCompanyDisabled, setIsCompanyDisabled] = useState(true);
  const defaultConfiguration = useDefaultConfiguration();
  const configurationValues = useConfigurationValues(vehicleTypeId, '', services.VPM);
  const [selectedCompanyId, setSelectedCompanyId] = useState();
  const deviceFeatures = useDevicesFeatures(selectedCompanyId);
  const isFetchingVehicleTypes = useIsFetching();

  const handleError = useCallback(
    err => {
      if (history.location.pathname !== Paths.DEFAULT) {
        if (err) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: err
            })
          );
        }
        history.replace(Paths.DEFAULT);
      }
    },
    [history, dispatch]
  );

  const { iconOptions, iconOptionFilterFunc } = useMemo(() => {
    const optionsWithCategories = iconSet(t);

    optionsWithCategories.sort((a, b) => {
      return a.labelOptGroup.localeCompare(b.labelOptGroup);
    });

    // Sort options within each category
    optionsWithCategories.forEach(category => {
      category.options.sort((a, b) => {
        return a.translatedLabel.localeCompare(b.translatedLabel);
      });
    });

    return {
      iconOptions: optionsWithCategories.map(category => ({
        ...category,
        options: category.options.map(option => ({
          ...option,
          label: (
            <>
              <i className={option.id} /> {option.translatedLabel}
            </>
          )
        }))
      })),
      iconOptionFilterFunc: (input, option) => {
        //check for item onlu
        if (option.options === undefined) {
          const filteredOption = optionsWithCategories
            .flatMap(category => category.options)
            .find(subOption => subOption.id === option.value);
          return filteredOption.translatedLabel.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
        }
      }
    };
  }, [t]);

  useEffect(() => {
    const parsedId = parseInt(vehicleTypeId);
    if (
      (action === 'edit' || action === 'copy') &&
      (parsedId <= 0 ||
        isNaN(parsedId) ||
        (!isFetchingVehicleTypes && !allVehicleTypes.some(t => t.id === parsedId)))
    ) {
      handleError(t('Common.Invalid Request ID'));
    }
  }, [t, action, vehicleTypeId, handleError, allVehicleTypes, isFetchingVehicleTypes]);

  const vehicleTypeEdit =
    allVehicleTypes.find(type => type.id === parseInt(vehicleTypeId, 10)) || initialValues;

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (vehiclesFetched) {
      setIsCompanyDisabled(
        vehicles.some(v => v.type?.id === Number(vehicleTypeId)) || action === 'copy'
      );
    }
  }, [vehicles, vehicleTypeId, vehiclesFetched, action]);

  useEffect(() => {
    if (action === 'edit') {
      dispatch(
        setPageTitle(
          formikInitialValues.name &&
            `${t('VehicleTypes.Form.EditTitle')} ${formikInitialValues.name}`
        )
      );
    } else if (action === 'copy') {
      const parsedId = parseInt(vehicleTypeId);
      const vehicleType = allVehicleTypes.find(t => t.id === parsedId);

      dispatch(setPageTitle(`${t('VehicleTypes.Form.CopyTitle')} ${vehicleType?.name}`));
    } else {
      dispatch(setPageTitle(`${t('VehicleTypes.Form.AddNewVehicleTypeTitle')}`));
    }
  }, [dispatch, action, formikInitialValues.name, t, allVehicleTypes, vehicleTypeId]);

  useEffect(() => {
    if (action === 'edit' || action === 'copy') {
      const initialConfigurationValues = {};
      Object.keys(vehicleTypeMetrics).forEach(metric => {
        const value = configurationValues?.find(cv => cv.key === vehicleTypeMetrics[metric]);
        if (value) {
          initialConfigurationValues[metric] = getLocalizedValue({
            localization,
            metricKey: vehicleTypeMetrics[metric],
            metricValue: value.value
          });
        }
      });
      setFormikInitialValues({
        ...vehicleTypeEdit,
        ...initialConfigurationValues,
        ...{
          name: `${action === 'copy' ? `${t('Common.Copy of')} ` : ''}${vehicleTypeEdit.name}`
        }
      });
    }
  }, [action, vehicleTypeEdit, configurationValues, localization]);

  useEffect(() => {
    const formVehicleTypes = allVehicleTypes.filter(
      vehicleType => vehicleType.companyId === currentCompany.id
    );
    setCompanyVehicleTypes(formVehicleTypes);
  }, [allVehicleTypes, currentCompany]);

  const updateFormOnCompanyChange = newCompanyId => {
    request('GET', `${API_PATH}/vehicletypes?company_id=${newCompanyId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(resp => {
        const newVehicleTypes = resp.body;
        const companyVehiclesTypes = newVehicleTypes.filter(
          vehicleType => vehicleType.companyId === parseInt(newCompanyId, 10)
        );
        setCompanyVehicleTypes(companyVehiclesTypes);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onCompanyChange = (company, setFieldValue) => {
    setFieldValue('companyId', company);

    setSelectedCompanyId(company);
    if (!company) {
      return;
    }
    updateFormOnCompanyChange(company);
  };

  const [formValue, setFormValue] = useState({});
  useEffect(() => {
    const metaData = Object.keys(vehicleTypeMetrics)
      .map(metric => {
        const existsValue = configurationValues?.find(cv => cv.key === vehicleTypeMetrics[metric])
          ?.value;
        const defaultValue = defaultConfiguration[vehicleTypeMetrics[metric]]?.defaultValue;
        return { [metric]: existsValue || defaultValue };
      })
      .reduce((a, c) => ({ ...a, ...c }), {});
    setFormValue({
      ...formikInitialValues,
      ...metaData,
      name: `${action === 'copy' ? `${t('Common.Copy Of')} ` : ''}${vehicleTypeEdit.name}`,
      companyId:
        action === 'add' || action === 'copy' ? currentCompany.id : formikInitialValues.companyId
    });
    setSelectedCompanyId(
      action === 'add' || action === 'copy' ? currentCompany.id : formikInitialValues.companyId
    );
  }, [formikInitialValues, currentCompany, action, defaultConfiguration, configurationValues]);

  useFormUpdatedFields(formValue, {
    eventName: MPTrackingEvents.Settings.VehicleTypes.Update,
    eventProps: { typeOfUpdate: action },
    trackFields: MPTrackingEvents.Settings.VehicleTypes.TrackFormFields,
    eventPropsOnSubmitFunc: MPTrackingEvents.Settings.VehicleTypes.OnSubmitTrackEventPropsFunc
  });

  const {
    hasFuelEfficiencyPermission,
    FuelFields,
    descriptionRows,
    getValidFuelMetricFormValues,
    isApplicableVehicleTypeMetric
  } = useFuelFields(t, localization, defaultConfiguration, action);

  const recalculateKeyValue = (metric, value) => {
    const metricMap = {
      idleTime: value => (value * 60).toFixed(1)
    };
    const method = metricMap[metric];
    return method ? method(value) : value;
  };

  const recalculateValues = (values = {}) => {
    return Object.keys(values).reduce((accum, key) => {
      accum[key] = recalculateKeyValue(key, values[key]);
      return accum;
    }, {});
  };

  const getValidVehicleTypeMetricValues = useCallback(
    metricValues =>
      Object.keys(metricValues).reduce(
        (a, metircKey) => ({ ...a, [metircKey]: metricValues[metircKey] }),
        {}
      ),
    []
  );

  const handleSubmit = (formValues, actions) => {
    const values = recalculateValues(formValues);
    let postBody = {
      name: values.name,
      description: values.description,
      speedLimitClass: values.speedLimitClass,
      code: values.code ? values.code : values.name.replace(/[^A-Z0-9]+/gi, '').toUpperCase(),
      companyId: values.companyId,
      icon: values.icon || initialValues.icon,
      ...(action !== 'edit' && { createdAt: new Date() }),
      ...(action === 'edit' && { id: vehicleTypeEdit.id, updatedAt: new Date() }),
      ...(hasFuelEfficiencyPermission
        ? { [FuelFieldKey.FuelType]: values[FuelFieldKey.FuelType] || '' }
        : {})
    };
    let method = 'POST';
    let URL = `${API_PATH}/vehicletypes`;
    let message = 'added';
    if (action === 'edit') {
      method = 'PUT';
      URL = `${API_PATH}/vehicletypes/${vehicleTypeEdit.id}`;
      message = 'updated';
    }
    //Checking for duplicates for vehicle type codes
    const identicalCodeVehicleType = companyVehicleTypes
      .filter(vehicleType => vehicleType.id !== values.id)
      .filter(vehicle => vehicle.status === 'ENABLED')
      .find(vehicleType => vehicleType.code === postBody.code);
    if (identicalCodeVehicleType) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('VehicleTypes.Notifications.SameCode'),
          autohide: false
        })
      );
      actions.setSubmitting(false);
      return;
    }
    const metaData = Object.keys(vehicleTypeMetrics)
      .map(metric => {
        const defaultValue = defaultConfiguration[vehicleTypeMetrics[metric]]?.defaultValue;
        const value = isNaN(Number(values[metric])) ? defaultValue : Number(values[metric]);
        return {
          [metric]: recalculateKeyValue(metric, value)?.toString() || ''
        };
      })
      .reduce((a, c) => ({ ...a, ...c }), {});
    setFormValue({
      ...postBody,
      ...metaData,
      submit: true
    });
    request(method, `${URL}?company_id=${values.companyId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(postBody)
      .then(response => {
        setPromptModalWhenLeaving(false);
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('VehicleTypes.Notifications.UpdateAddNotification', {
              name: values.name,
              message: message
            })
          })
        );
        dispatch(fetchVehicleTypes());
        dispatch(fetchFleets());
        if (response?.body?.id) {
          const configurationValues = setLocalizedValues({
            localization,
            metrics: Object.keys(vehicleTypeMetrics)
              .filter(isApplicableVehicleTypeMetric)
              .reduce((a, metircKey) => ({ ...a, [metircKey]: vehicleTypeMetrics[metircKey] }), {}),
            values: getValidVehicleTypeMetricValues({
              ...values,
              ...getValidFuelMetricFormValues(values)
            }),
            id: response.body.id,
            services: services.VPM,
            nullableMetrics: [FuelFieldKey.AVRFuelEfficiency, FuelFieldKey.Co2EmissionsFactor]
          });
          dispatch(setConfigurationValues(configurationValues));
        }
        actions.resetForm();
        actions.setSubmitting(false);
        canHistoryGoBack(history, '/settings/vehicleTypes');
      })
      .catch(err => {
        actions.setSubmitting(false);
        console.log('ERROR', err);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(err)
          })
        );
      });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...formikInitialValues,
          companyId:
            action === 'add' || action === 'copy'
              ? currentCompany.id
              : formikInitialValues.companyId
        }}
        validationSchema={validationSchema(localization)}
        onSubmit={handleSubmit}
      >
        {({
          isSubmitting,
          isValid,
          status,
          initialValues,
          setFieldValue,
          dirty,
          values,
          ...props
        }) => (
          <>
            <EditRouteGuard when={dirty && promptModalWhenLeaving} navigate={history.push} />
            <Form id="VehicleTypesForm">
              <div className={styles.formContainer}>
                <Row>
                  <Col>
                    <Row>
                      <FormInput
                        name="name"
                        rows="6"
                        label={t('VehicleTypes.Form.Name')}
                        placeholder={t('VehicleTypes.Form.NamePlaceholder')}
                        isRequired
                      />
                    </Row>
                    <Row>
                      <FormInput
                        name="code"
                        rows="6"
                        label={t('VehicleTypes.Form.Code')}
                        placeholder={t('VehicleTypes.Form.CodePlaceholder')}
                        isValidated
                        isRequired
                      />
                    </Row>
                    <Row>
                      <FormSelect
                        name="companyId"
                        label={t('VehicleTypes.Form.Company')}
                        rows="5"
                        onChange={id => onCompanyChange(parseInt(id, 10), setFieldValue)}
                        isDisabled={isCompanyDisabled}
                        placeholder=""
                        values={companies.map(company => ({
                          label: company.name,
                          value: company.id
                        }))}
                        isRequired
                      />
                    </Row>
                    <Row className={styles.iconWrapper}>
                      <Field
                        name="icon"
                        component={() => (
                          <>
                            <FormLabel className={styles.selectIconLabel}>
                              {t('VehicleTypes.Form.Icon')}
                            </FormLabel>
                            <Select
                              id="VehicleIconSelect"
                              size="large"
                              data={iconOptions}
                              value={values.icon}
                              optionGroup={true}
                              onChange={selectedIcon => setFieldValue('icon', selectedIcon)}
                              className={styles.selectIcon}
                              notFoundContent={
                                <div style={{ padding: '10px' }}>
                                  <span>{t('VehicleTypes.Form.NoVehicleIconFound')}</span>
                                </div>
                              }
                              popupClassName={styles.selectIconDropdown}
                              filterOption={iconOptionFilterFunc}
                            />
                          </>
                        )}
                      />
                    </Row>
                    <Row>
                      <FormSelect
                        name="speedLimitClass"
                        label={t('VehicleTypes.SpeedLimitClass')}
                        rows="5"
                        placeholder=""
                        values={speedLimitClassSet(t)}
                      />
                    </Row>
                    <FuelType {...FuelFields.fuelType} />
                    <Row>
                      <FormInput
                        name="description"
                        label={t('VehicleTypes.Form.Description')}
                        rows={descriptionRows(values, 2)}
                        placeholder={t('VehicleTypes.Form.DescriptionPlaceholder')}
                        as="textarea"
                      />
                    </Row>
                  </Col>
                  <Can featureHide={companyFeatures.HTF}>
                    <Col>
                      <Can oneOfServices={[services.VPM, services.BPM, services.SAFETYANALYTICS]}>
                        <Row>
                          <FormInput
                            name="harshAcceleration"
                            rows="6"
                            tooltip={t('VehicleTypes.Tooltip.HarshAcceleration', {
                              unit: localization.formats.acceleration.unit
                            })}
                            label={t('VehicleTypes.Form.HarshAcceleration', {
                              unit: localization.formats.acceleration.unit
                            })}
                            placeholder={
                              getLocalizedValue({
                                localization,
                                metricKey: vehicleTypeMetrics?.harshAcceleration,
                                metricValue:
                                  defaultConfiguration[vehicleTypeMetrics?.harshAcceleration]
                                    ?.defaultValue
                              }) || ''
                            }
                            isValidated
                          />
                        </Row>
                      </Can>
                      <Can oneOfServices={[services.VPM, services.BPM, services.SAFETYANALYTICS]}>
                        <Row>
                          <FormInput
                            name="harshBrake"
                            rows="6"
                            label={t('VehicleTypes.Form.HarshBrake', {
                              unit: localization.formats.acceleration.unit
                            })}
                            tooltip={t('VehicleTypes.Tooltip.HarshBrake', {
                              unit: localization.formats.acceleration.unit
                            })}
                            placeholder={
                              getLocalizedValue({
                                localization,
                                metricKey: vehicleTypeMetrics?.harshBrake,
                                metricValue:
                                  defaultConfiguration[vehicleTypeMetrics?.harshBrake]?.defaultValue
                              }) || ''
                            }
                            isValidated
                          />
                        </Row>
                      </Can>
                      {!deviceFeatures[deviceCapabilities.HarshManeuveurs] && (
                        <Can oneOfServices={[services.VPM, services.SAFETYANALYTICS, services.BPM]}>
                          <Row>
                            <FormInput
                              name="harshCornering"
                              rows="6"
                              label={t('VehicleTypes.Form.HarshCornering', {
                                unit: localization.formats.acceleration.unit
                              })}
                              tooltip={t('VehicleTypes.Tooltip.HarshCornering', {
                                unit: localization.formats.acceleration.unit
                              })}
                              placeholder={
                                getLocalizedValue({
                                  localization,
                                  metricKey: vehicleTypeMetrics?.harshCornering,
                                  metricValue:
                                    defaultConfiguration[vehicleTypeMetrics?.harshCornering]
                                      ?.defaultValue
                                }) || ''
                              }
                              isValidated
                            />
                          </Row>
                        </Can>
                      )}

                      {deviceFeatures[deviceCapabilities.HarshManeuveurs] && (
                        <HarshCorneringFormField
                          defaultConfiguration={defaultConfiguration}
                          formData={{ ...props, values }}
                        />
                      )}

                      {deviceFeatures[deviceCapabilities.Impact] && (
                        <Can oneOfServices={[services.VPM, services.BPM]}>
                          <Row>
                            <FormInput
                              name="impact"
                              rows="6"
                              label={t('VehicleTypes.Form.Impact', {
                                unit: localization.formats.acceleration.unit
                              })}
                              tooltip={t('VehicleTypes.Tooltip.Impact', {
                                unit: localization.formats.acceleration.unit
                              })}
                              placeholder={
                                getLocalizedValue({
                                  localization,
                                  metricKey: vehicleTypeMetrics?.impact,
                                  metricValue:
                                    defaultConfiguration[vehicleTypeMetrics?.impact]?.defaultValue
                                }) || ''
                              }
                              isValidated
                            />
                          </Row>
                        </Can>
                      )}

                      <Can oneOfServices={[services.VPM]}>
                        <Row>
                          <FormInput
                            name="overRev"
                            rows="6"
                            label={t('VehicleTypes.Form.OverRev')}
                            tooltip={t('VehicleTypes.Tooltip.OverRev')}
                            placeholder={
                              getLocalizedValue({
                                localization,
                                metricKey: vehicleTypeMetrics?.overRev,
                                metricValue:
                                  defaultConfiguration[vehicleTypeMetrics?.overRev]?.defaultValue
                              }) || ''
                            }
                            isValidated
                          />
                        </Row>
                      </Can>
                      <Can oneOfServices={[services.VPM, services.BPM]}>
                        <Row>
                          <FormInput
                            name="idleTime"
                            rows="6"
                            label={t('VehicleTypes.Form.IdleTime')}
                            tooltip={t('VehicleTypes.Tooltip.IdleTime')}
                            placeholder={
                              getLocalizedValue({
                                localization,
                                metricKey: vehicleTypeMetrics?.idleTime,
                                metricValue:
                                  defaultConfiguration[vehicleTypeMetrics?.idleTime]?.defaultValue
                              }) || ''
                            }
                            isValidated
                          />
                        </Row>
                      </Can>
                      <Can oneOfServices={[services.VPM]}>
                        <Row>
                          <FormInput
                            name="oilOverTemp"
                            rows="6"
                            label={t('VehicleTypes.Form.OilOverTemp', {
                              unit: localization.formats.temperature.unit
                            })}
                            tooltip={t('VehicleTypes.Tooltip.OilOverTemp', {
                              unit: localization.formats.temperature.unit
                            })}
                            placeholder={
                              getLocalizedValue({
                                localization,
                                metricKey: vehicleTypeMetrics?.oilOverTemp,
                                metricValue:
                                  defaultConfiguration[vehicleTypeMetrics?.oilOverTemp]
                                    ?.defaultValue
                              }) || ''
                            }
                            isValidated
                          />
                        </Row>
                      </Can>
                      <Can oneOfServices={[services.VPM]}>
                        <Row>
                          <FormInput
                            name="coolantOverTemp"
                            rows="6"
                            label={t('VehicleTypes.Form.CoolantOverTemp', {
                              unit: localization.formats.temperature.unit
                            })}
                            tooltip={t('VehicleTypes.Tooltip.CoolantOverTemp', {
                              unit: localization.formats.temperature.unit
                            })}
                            placeholder={
                              getLocalizedValue({
                                localization,
                                metricKey: vehicleTypeMetrics?.coolantOverTemp,
                                metricValue:
                                  defaultConfiguration[vehicleTypeMetrics?.coolantOverTemp]
                                    ?.defaultValue
                              }) || ''
                            }
                            isValidated
                          />
                        </Row>
                      </Can>
                      <Can oneOfServices={[services.VPM]}>
                        <Row>
                          <FormInput
                            name="oilPressure"
                            rows="6"
                            label={t('VehicleTypes.Form.OilPressure', {
                              unit: localization.formats.pressure.oil
                            })}
                            tooltip={t('VehicleTypes.Tooltip.OilPressure', {
                              unit: localization.formats.pressure.oil
                            })}
                            placeholder={
                              getLocalizedValue({
                                localization,
                                metricKey: vehicleTypeMetrics?.oilPressure,
                                metricValue:
                                  defaultConfiguration[vehicleTypeMetrics?.oilPressure]
                                    ?.defaultValue
                              }) || ''
                            }
                            isValidated
                          />
                        </Row>
                      </Can>
                      <FuelAverageEfficiency {...FuelFields.averagefuelefficiency} />
                      <FuelCO2EmissionFactor {...FuelFields.co2emissionsfactor} />
                    </Col>
                  </Can>
                </Row>
              </div>
              <div className={styles.formFooter}>
                <Button
                  id={BUTTON_IDS.vehicleTypesFormSave}
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid || isSubmitting}
                >
                  {t('Common.SaveButton')}
                </Button>
                <Button id={BUTTON_IDS.vehicleTypesFormCancel} onClick={history.goBack}>
                  {t('Common.CancelButton')}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default VehicleTypesForm;

const FuelType = ({
  showField,
  name,
  getFuelFieldDefaultValue,
  setFuelFieldDisplay,
  action,
  ...props
}) => {
  const {
    values,
    setFieldValue,
    initialValues,
    touched,
    setFieldTouched,
    validateForm
  } = useFormikContext();
  const show = useMemo(() => showField(values), [showField, values]);
  const isFieldPristine = useCallback(
    (fieldValue, fieldInitialValue, fieldTouched) =>
      (fieldTouched && !fieldValue) || !fieldInitialValue,
    []
  );
  const onTypeChange = useCallback(
    fuelType => {
      setFieldValue(name, fuelType);
      const isSelectingCo2FuelType = isCo2FuelType(fuelType);
      [FuelFieldKey.AVRFuelEfficiency, FuelFieldKey.Co2EmissionsFactor].forEach(fieldName => {
        if (isSelectingCo2FuelType) {
          const shouldSetDefaultValue = isFieldPristine(
            values[fieldName],
            initialValues[fieldName],
            touched[fieldName]
          );
          if (shouldSetDefaultValue) {
            setFieldValue(fieldName, getFuelFieldDefaultValue(fieldName, fuelType));
            setFieldTouched(fieldName, false);
            setFuelFieldDisplay(fieldName, true);
          }
        } else {
          setFieldValue(fieldName, initialValues[fieldName]);
          try {
            validateForm().then(errors => {
              if (errors && errors[fieldName]) {
                setFieldValue(fieldName, '');
              }
            });
          } catch (error) {}
        }
      });
    },
    [
      values,
      name,
      setFieldValue,
      getFuelFieldDefaultValue,
      initialValues,
      touched,
      setFieldTouched,
      action,
      setFuelFieldDisplay,
      isFieldPristine,
      validateForm
    ]
  );

  return (
    show && (
      <Row>
        <FormSelect
          {...props}
          name={name}
          values={props.options}
          rows="6"
          isValidated
          onChange={onTypeChange}
        />
      </Row>
    )
  );
};

const FuelAverageEfficiency = ({
  showField,
  name,
  setFuelFieldDisplay,
  getFuelFieldCSSCls,
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext();
  const show = useMemo(() => showField(values), [showField, values]);
  const className = useMemo(() => getFuelFieldCSSCls(name), [getFuelFieldCSSCls, name]);
  const onChange = useCallback(
    value => {
      setFieldValue(name, value);
      setFuelFieldDisplay(name, false);
    },
    [name, setFieldValue, setFuelFieldDisplay]
  );

  return (
    show && (
      <Row>
        <FormInput
          {...props}
          name={name}
          rows="6"
          className={className}
          isValidated
          onChange={onChange}
        />
      </Row>
    )
  );
};

const FuelCO2EmissionFactor = ({
  showField,
  name,
  setFuelFieldDisplay,
  getFuelFieldCSSCls,
  ...props
}) => {
  const { values, setFieldValue } = useFormikContext();
  const show = useMemo(() => showField(values), [showField, values]);
  const className = useMemo(() => getFuelFieldCSSCls(name), [getFuelFieldCSSCls, name]);
  const onChange = useCallback(
    value => {
      setFieldValue(name, value);
      setFuelFieldDisplay(name, false);
    },
    [name, setFieldValue, setFuelFieldDisplay]
  );

  return (
    show && (
      <Row>
        <FormInput
          {...props}
          name={name}
          rows="6"
          className={className}
          isValidated
          onChange={onChange}
        />
      </Row>
    )
  );
};

const HarshCorneringFormField = ({ defaultConfiguration, formData }) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const currentRegion = useCurrentRegion();
  const isAURegion = useMemo(
    () => currentRegion && upperCase(currentRegion.code) === upperCase(REGION_LOCALE.AU.region),
    [currentRegion]
  );

  const { fieldTouched, fieldError } = useMemo(() => {
    const leftMeta = formData.getFieldMeta('harshCorneringLeft');
    const rightMeta = formData.getFieldMeta('harshCorneringRight');
    return {
      fieldTouched: leftMeta.touched || rightMeta.touched,
      fieldError: leftMeta.error || rightMeta.error
    };
  }, [formData]);

  return (
    <Can oneOfServices={[services.VPM, services.BPM, services.SAFETYANALYTICS]}>
      <Row>
        <Col span={24} className="form-group">
          <FormLabel>
            {t(`VehicleTypes.Form.HarshCornering`, {
              unit: localization.formats.acceleration.unit
            })}
            <Tooltip
              content={t(`VehicleTypes.Tooltip.HarshCornering${isAURegion ? 'ForAU' : ''}`, {
                unit: localization.formats.acceleration.unit
              })}
              target={<i className={`tn-i-info ${styles.formFieldTooltip}`} />}
            />
          </FormLabel>
          <Row>
            <Space size={4}>
              <FormInput
                wrapperClassName={styles.subFormField}
                name="harshCorneringLeft"
                rows="6"
                label={t('Alerts.GPIO.left')}
                placeholder={
                  getLocalizedValue({
                    localization,
                    metricKey: vehicleTypeMetrics?.harshCorneringLeft,
                    metricValue:
                      defaultConfiguration[vehicleTypeMetrics?.harshCorneringLeft]?.defaultValue
                  }) || ''
                }
                isValidated
                hideError
              />
              <FormInput
                wrapperClassName={styles.subFormField}
                name="harshCorneringRight"
                rows="6"
                label={t('Alerts.GPIO.right')}
                placeholder={
                  getLocalizedValue({
                    localization,
                    metricKey: vehicleTypeMetrics?.harshCorneringRight,
                    metricValue:
                      defaultConfiguration[vehicleTypeMetrics?.harshCorneringRight]?.defaultValue
                  }) || ''
                }
                isValidated
                hideError
              />
            </Space>
          </Row>
          <Row>
            <Col>
              <div className={`fieldError ${fieldTouched && fieldError ? 'error has' : 'error'}`}>
                {fieldError === 'Required'
                  ? t('Forms.FormValidation.Required')
                  : fieldTouched && fieldError}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Can>
  );
};
