import React from 'react';
import * as TN from './TopNav.style';
import styles from './BadgeIconButton.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faBellSlash } from '@fortawesome/free-solid-svg-icons';

export const BadgeIconButton = React.forwardRef((props, ref) => {
  const { IconButton = TN.IconButton, count = 0, mutedAlarms = false } = {
    ...props
  };
  const handleOnClick = e => {
    if (props.onClick) props.onClick(e);
  };

  const handleOnBlur = e => {
    if (props.onBlur) props.onBlur(e);
  };

  return (
    <div ref={ref} className={styles.badgeContainer}>
      <IconButton onClick={handleOnClick} onBlur={handleOnBlur}>
        <FontAwesomeIcon icon={mutedAlarms ? faBell : faBellSlash} />
      </IconButton>
      {count > 0 && (
        <span onClick={handleOnClick} className={styles.badgeComponent}>
          {count}
        </span>
      )}
    </div>
  );
});
