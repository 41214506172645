import { CompanyConfigValue } from 'features/company/companySlice';

import { useCallback } from 'react';
import { useSupportedEventTypes, getEventAttributesByType } from 'containers/Tracking/EventTypes';

import { getIncludedEventTypes } from 'features/tracking/trackingGridSettings';

// Code copied from TripSegment.js, add extra fields needed on event
export const getExtraEventFields = (events, trip) => {
  let eventsWithExtraFields = [];

  if (events) {
    eventsWithExtraFields = events.map(event => {
      const eventAttributes = getEventAttributesByType(event.eventType, event.subType);
      if (eventAttributes && eventAttributes.hasOwnProperty('url')) {
        const url = eventAttributes?.url
          ?.replace(':id:', event.id)
          ?.replace(':timeAt:', event.timeAt);
        event.url = encodeURI(url);
      }

      event.driverName = trip.driverName;
      if (event.attributes?.endGps && !event.GPS) {
        event.GPS = event.attributes.endGps;
      }
      if (event.attributes?.endLocation && !event.location) {
        event.location = event.attributes.endLocation;
      }
      if (event.attributes?.ptoOff && event.attributes?.ptoOn && !event.attributes.duration) {
        event.attributes.duration = (event.attributes?.ptoOff - event.attributes?.ptoOn) / 1000;
      }
      return event;
    });
  }

  return eventsWithExtraFields;
};

export const useSupportedEventsFilter = () => {
  const {
    allEventTypesNoDuplicates,
    allSupportedEvtTypes,
    removeEventTypesWithDuplicateLabels
  } = useSupportedEventTypes();

  const getFilteredEvents = useCallback(
    (userGridSettings, events) => {
      let filteredEvents = events;

      if (userGridSettings && userGridSettings.config) {
        let result = getIncludedEventTypes(userGridSettings.config, allSupportedEvtTypes);

        if (!result.includeAllEventTypes) {
          filteredEvents = filteredEvents?.filter(event =>
            result.includedEventTypes.find(
              includedEventType => includedEventType.key === event.eventType + event.subType
            )
          );
        }
      }

      return filteredEvents?.filter(event =>
        allSupportedEvtTypes.some(supportedEvtType =>
          supportedEvtType.key.includes(`${event.eventType}${event.subType}`)
        )
      );
    },
    [allSupportedEvtTypes]
  );

  const getFilteredEventsForTrip = useCallback(
    (userGridSettings, trip, hideNonBusinessTrips = false) => {
      const isNonBusinessTrip = hideNonBusinessTrips && trip?.attr === CompanyConfigValue.Private;
      if (isNonBusinessTrip) {
        return [];
      }

      const events = trip.events;
      const filteredEvents = getFilteredEvents(userGridSettings, events);
      return filteredEvents;
    },
    [getFilteredEvents]
  );

  const getFilteredEventsForAllTrips = useCallback(
    (userGridSettings, trips, hideNonBusinessTrips) => {
      let events = [];
      if (trips) {
        trips.forEach(trip => {
          const filteredEventsForTrip = getFilteredEventsForTrip(
            userGridSettings,
            trip,
            hideNonBusinessTrips
          );
          events = [...events, ...filteredEventsForTrip];
        });
      }
      return events;
    },
    [getFilteredEventsForTrip]
  );

  return {
    getFilteredEventsForTrip,
    getFilteredEventsForAllTrips,
    getEventsFilterCount: userGridSettings => {
      let eventsFilterCount = allEventTypesNoDuplicates.length;
      if (userGridSettings && userGridSettings.config) {
        let result = getIncludedEventTypes(userGridSettings.config, allSupportedEvtTypes);
        const includedEventTypes = removeEventTypesWithDuplicateLabels(result.includedEventTypes);
        eventsFilterCount = includedEventTypes.length;
      }
      return eventsFilterCount;
    }
  };
};
