import React from 'react';

import { Grid } from 'components/tn';
import { ActionsMenu } from './ActionsMenu';
import { Comparators } from 'utils/sorting';
import { Can, entities } from 'features/permissions';
import { useTranslation } from 'react-i18next';

export const VehicleCombinationsTable = ({ data, isLoading, onEdit, onDelete, ...props }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('MassManagement.Field.CombinationName'),
      dataIndex: 'name',
      key: 'name',
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.String('name'),
      defaultSortColumn: true,
      defaultSortOrder: 'ascend'
    },
    {
      title: t('MassManagement.Field.AxleGroups'),
      dataIndex: 'axleGroups',
      key: 'axleGroups',
      width: 200,
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.Number('axleGroups')
    },
    {
      title: t('MassManagement.Field.Axles'),
      dataIndex: 'axles',
      key: 'axles',
      width: 200,
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.Number('axles')
    },
    {
      title: t('Common.TableColumns.Actions'),
      key: 'action',
      align: 'center',
      render: record => (
        <Can everyEntity={[entities.VEHICLE_UPDATE]}>
          <ActionsMenu
            record={record}
            entityType={t('MassManagement.VehicleCombination')}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Can>
      )
    }
  ];

  return (
    <Grid
      data={data}
      columns={columns}
      rowKey={'id'}
      recordTypeSingular={t('MassManagement.VehicleCombination')}
      recordTypePlural={t('MassManagement.VehicleCombinations')}
      isLoading={isLoading}
      {...props}
    />
  );
};
