import { useCallback } from 'react';
import { useMemo } from 'react';

/**
 * A hook to load and save the value of a key to local storage.
 * @param {String} key a string to present the key name.
 * @param {Object} context an object to for the context. It needs to have at least userId and pagePathname. It accepts other context values in key/pirmitive pattern.
 * @returns the value of the key in localStorage.
 */
export function useLocalCache(
  key,
  context = {
    userId: '',
    pagePathname: ''
  }
) {
  const hash = useMemo(() => {
    const contextValues = Object.keys(context)
      .map(k => context[k] ?? k)
      .join('-');
    return btoa(contextValues);
  }, [context]);

  const setValue = useCallback(
    val => {
      if (typeof val === 'function') {
        const contextString = localStorage.getItem(hash);
        const contextObject = contextString ? JSON.parse(contextString) : null;
        val = val(contextObject?.[key]);
      }
      let pathObject = localStorage.getItem(hash);
      if (!pathObject) {
        pathObject = {};
      } else {
        try {
          pathObject = JSON.parse(pathObject);
        } catch (e) {
          console.error(e);
          pathObject = {};
        }
      }
      pathObject[key] = val;
      localStorage.setItem(hash, JSON.stringify(pathObject));
    },
    [key, hash]
  );

  try {
    const contextString = localStorage.getItem(hash);
    const contextObject = contextString ? JSON.parse(contextString) : null;
    return [contextObject?.[key], setValue, context];
  } catch (err) {
    console.error(err);
    return [null, setValue, context];
  }
}
