import React, { useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { useGetQsUserQuery } from 'services/nextgen';

import { Can, services } from 'features/permissions';

import { AddDashboardModal } from '../Modals';
import { Toolbar } from './Toolbar';

export const DashboardsToolbar = ({ children, ...props }) => {
  const { t } = useTranslation();
  const qsUser = useGetQsUserQuery()?.data;
  const isQsUserAuthor = qsUser?.role?.toUpperCase() === 'AUTHOR';

  const [showAddDashboardModal, setShowAddDashboardModal] = useState(false);

  return (
    <Toolbar {...props}>
      <Can
        everyService={[services.INSIGHTSBUILDER]}
        everyCompanyService={[services.INSIGHTSBUILDER]}
        otherConditions={[() => isQsUserAuthor]}
      >
        <Button type="primary" size="large" onClick={() => setShowAddDashboardModal(true)}>
          {t('Insights.Actions.AddDashboard')}
        </Button>
      </Can>
      {children}

      <AddDashboardModal
        isOpen={showAddDashboardModal}
        onClose={() => {
          setShowAddDashboardModal(false);
        }}
      />
    </Toolbar>
  );
};
