import React, { useCallback } from 'react';
import { Select } from 'antd';
import { useVehicles } from 'features/fleets/fleetsSlice';
import { useTranslation } from 'react-i18next';

export function VehicleSearchInput({ value, onChange, showStandaloneDevices = false, ...props }) {
  const vehicles = useVehicles();
  const { t } = useTranslation();

  const handleFilter = useCallback(
    (input, option) => option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0,
    []
  );
  return (
    <Select
      filterOption={handleFilter}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      onChange={onChange}
      optionFilterProp="children"
      placeholder={t('ELD.Select a Vehicle')}
      prefix="\e918"
      showSearch={true}
      value={value}
      {...props}
    >
      {vehicles &&
        vehicles.flatMap((v, idx) =>
          v.name ? (
            <Select.Option key={idx} value={v.id}>
              {v.name}
            </Select.Option>
          ) : (
            (showStandaloneDevices &&
              v.devices?.flatMap(d => (
                <Select.Option key={d.id} value={d.id}>
                  {d.name}
                </Select.Option>
              ))) ||
            []
          )
        )}
    </Select>
  );
}
