import { Colors } from './constants';

export const getColor = (index, length) => {
  switch (true) {
    case index === 0:
      return Colors.Red;
    case index === 2 || (index === 1 && length === 2):
      return Colors.Green;
    case index === 3:
      return Colors.Blue;
    default:
      return Colors.Yellow;
  }
};

export const updateColors = ranges => {
  return ranges.map((range, index) => {
    const color = getColor(index, ranges.length);
    return {
      ...range,
      color
    };
  });
};

export const isValid = ranges => {
  let valid = true;
  let previousValue = -1;
  ranges.forEach(range => {
    if (previousValue >= range.value) {
      valid = false;
    }
    previousValue = range.value;
  });

  return valid;
};
