import { deviceNameCellRenderer, fleetsRenderer, vehicleCellRenderer } from './CellRenderers';
import { Button, Dropdown } from 'antd';
import { Link } from 'react-router-dom';

export const templateTypeEnum = {
  GPIO: 'GPIO',
  SpeedAssist: 'SPEEDASSIST',
  DriverId: 'DRIVERID'
};

export const deviceColumns = (t, handleViewTemplate, templateType) => {
  let columns = [
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Device Name'),
      dataIndex: 'name',
      render: (_, record) => deviceNameCellRenderer({ rowData: record, canViewDevice: true }),
      key: 'name'
    },
    {
      title: t('Common.Fleets'),
      dataIndex: 'fleet',
      render: (_, record) => fleetsRenderer({ rowData: record }),
      key: 'fleet'
    },
    {
      title: t('Devices.Table.TypeLabel'),
      dataIndex: 'type',
      key: 'type',
      render: type => <span>{type.name} </span>
    },
    {
      title: t('Devices.ActualForm.ModelLabel'),
      dataIndex: 'model',
      key: 'model',
      render: model => <span>{model.name} </span>
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Vehicle Name'),
      dataIndex: 'vehicle',
      render: (_, record) => vehicleCellRenderer({ rowData: record, canViewVehicle: true }),
      key: 'vehicle'
    },
    {
      title: t('Common.VehicleType'),
      dataIndex: 'vehicleType',
      render: (_, record) => <span>{record.vehicle?.type?.name} </span>,
      key: 'vehicleType'
    },
    {
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Template'),
      dataIndex: 'template',
      key: 'template',
      render: template => (
        <Link to={`/settings/company_config/${templateType.toLowerCase()}/id/${template?.id}`}>
          {template?.name}
        </Link>
      )
    }
  ];

  if (templateType === templateTypeEnum.GPIO) {
    columns.push({
      title: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Table.ActionsLabel'),
      dataIndex: '_action',
      key: 'action',
      render: (_, record) => {
        const items = [
          {
            //Leaving GPIO as is and making speed assist and future templates generic.
            label:
              templateType === templateTypeEnum.GPIO
                ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.View GPIO')
                : t('CompanyConfig.DeviceConfigurations.ViewTemplateInfo'),
            key: '0'
          }
        ];
        const onClick = () => {
          handleViewTemplate(record);
        };
        return (
          <Dropdown menu={{ items, onClick }} trigger={['click']} disabled={!record.template}>
            <Button type="link" onClick={e => e.preventDefault()} disabled={!record.template}>
              ...
            </Button>
          </Dropdown>
        );
      }
    });
  }
  return columns;
};
