import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
// import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import styles from '../../VehicleMaintenance/VehicleMaintenanceSchedules.module.scss';
// import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';

export const InspectionSummaryToolbar = ({ filteredInspectionsCount }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.datePickerStyle}>
      <div className={styles.documentsCount}>
        {filteredInspectionsCount + ' ' + t('InspectionSummary.Inspections')}
      </div>
    </div>
  );
};
