import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { VehicleFootageRequestMenuButtons } from 'features/requestFootage/RequestFootageButton';
import styles from '../../Snapshots.module.scss';
import { DailySnapshotsLink } from './DailySnapshotsLink';
import { entities, useIQCameraUser, useCanEveryEntity } from 'features/permissions';

function _SnapshotMenu({
  canRefresh,
  onRefresh,
  canMessaging,
  onMessageVehicle,
  onRequestFootage,
  devices,
  deviceId,
  vehicleId,
  deviceName,
  nodeType,
  open,
  onOpenChange = () => {},
  titles = {
    refresh: 'Refresh',
    messaging: 'Message Vehicle',
    viewVehicle: 'View Vehicle',
    viewDevice: 'View Device'
  }
}) {
  const { canAccessNonCameraFeatures } = useIQCameraUser();

  const canVideoEntities = useCanEveryEntity([entities.VIDEO, entities.VIDEO_CREATE]);

  const cameraDevices = useMemo(
    () => devices?.filter(device => device.type?.code === 'CAMERA') || [{ id: deviceId }],
    [devices]
  );

  return (
    <Dropdown
      overlayClassName={styles.dropdownMenu}
      menu={{
        items: [
          {
            label: (
              <Button onClick={onRefresh} type="text">
                {titles.refresh}
              </Button>
            ),
            visible: canRefresh,
            key: '0'
          },
          {
            label: (
              <Button onClick={onMessageVehicle} type="text">
                {titles.messaging}
              </Button>
            ),
            visible: canAccessNonCameraFeatures && canMessaging,
            key: '1'
          },
          {
            className: styles.vrBtns,
            label: (
              <VehicleFootageRequestMenuButtons
                deviceId={deviceId}
                vehicleId={vehicleId}
                onMenuClicked={onRequestFootage}
              />
            ),
            visible: canVideoEntities,
            key: '3'
          },
          {
            label: <Link to={`/settings/vehicles/id/${vehicleId}`}>{titles.viewVehicle}</Link>,
            visible: canAccessNonCameraFeatures && vehicleId,
            key: '4'
          },
          {
            ...(cameraDevices?.length === 1
              ? {
                  label: (
                    <Link to={`/settings/devices/id/${cameraDevices[0].id}`}>
                      {titles.viewDevice}
                    </Link>
                  )
                }
              : {
                  label: titles.viewDevice,
                  expandIcon: () => null,
                  popupOffset: [5, 0],
                  popupClassName: styles.dropdownMenu,
                  children: cameraDevices.map(camera => ({
                    key: camera.id,
                    label: (
                      <Link to={`/settings/devices/id/${camera.id}`}>
                        {camera.name || camera.id}
                      </Link>
                    )
                  }))
                }),
            visible: !!cameraDevices.length,
            key: '5'
          },
          {
            label: (
              <DailySnapshotsLink
                entityId={nodeType === 'Vehicle' ? vehicleId : deviceId}
                entityName={deviceName}
                entityType={nodeType}
                linkTitle={titles.viewSnapshotHistory}
              />
            ),
            visible: vehicleId || deviceId,
            key: '6'
          }
        ]
          .filter(item => item.visible)
          .map(item => ({ ...item, visible: 'true' }))
      }}
      trigger={['click']}
      open={open}
      onOpenChange={onOpenChange}
    >
      <Button icon={<FontAwesomeIcon icon={faEllipsisV} />} />
    </Dropdown>
  );
}
export const SnapshotMenu = React.memo(_SnapshotMenu);
