import { asyncThunk } from 'utils/api';
import {
  FETCH_USER_ALERTS,
  EDIT_ALERT,
  TOGGLE_USER_ALERTS,
  GET_TOGGLE_USER_ALERTS
} from './actions';

const GET_ALERTS_URL = '/alert';
const POST_ALERTS_URL = '/alert/config';
const TOGGLE_USER_ALERTS_URL = '/alert/globalsettings';

export const fetchUserAlerts = asyncThunk(FETCH_USER_ALERTS, GET_ALERTS_URL);
export const editUserAlert = asyncThunk(EDIT_ALERT, POST_ALERTS_URL, 'post');
export const toggleUserAlerts = asyncThunk(TOGGLE_USER_ALERTS, TOGGLE_USER_ALERTS_URL, 'post');
export const fetchToggleUserAlertsStatus = asyncThunk(
  GET_TOGGLE_USER_ALERTS,
  TOGGLE_USER_ALERTS_URL
);
