import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//components
import { AuditsTable } from 'components/auditsTable/AuditsTable';

//slices
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits, fetchAudits } from 'features/audits';
import { useJourneys } from 'features/journeyPlanner/journeysSlice';

// helpers
import { getIDFromPathname } from 'utils/methods';

//constants
import { AUDIT_ENTITY } from 'components/auditsTable/constants';

export const JourneyAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const audits = useAudits(AUDIT_ENTITY.JOURNEY, id);
  const journeys = useJourneys();
  const journey = journeys?.find(journey => journey.id === Number(id));
  const pageTitle = !journey
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: journey?.name });

  useEffect(() => {
    dispatch(fetchAudits({ entityName: AUDIT_ENTITY.JOURNEY, entityId: id }));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    dispatch(setBackButton(true));
  }, [pageTitle, dispatch]);

  return <AuditsTable audits={audits} entity={AUDIT_ENTITY.JOURNEY} />;
};
