import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import { useViolations, setViolationId } from './violationsSlice';
import { AutoSizer } from 'react-virtualized';
import { useDriverTimezone } from '../drivers/driversSlice';
import moment from 'moment';

const Violation = ({
  index,
  hoverHandler,
  violation,
  violationStart,
  violationEnd,
  periodStart
}) => (
  <div
    key={index}
    className={'card-body-violation-item alert alert-danger'}
    onMouseEnter={() => hoverHandler('enter', index)}
    onMouseLeave={() => hoverHandler('leave', index)}
  >
    {violation.breach && <h6>{violation.breach.desc}</h6>}
    <h6>{violation.rule.description}</h6>
    <small>{violation.rule.id}</small>
    <p className="vdetail">
      The driver is in violation from {violationStart.format('h:mm A')} on the{' '}
      {violationStart.format('DD/MM/YYYY')}{' '}
      {violationEnd ? (
        <>
          until {violationEnd.format('h:mm A')} on the {violationEnd.format('DD/MM/YYYY')}
        </>
      ) : (
        <>still currently in violation.</>
      )}
    </p>
    <p className="vdetail">
      The violation occurs in the period starting at {periodStart.format('h:mm A')} on the{' '}
      {periodStart.format('DD/MM/YYYY')}
    </p>
  </div>
);
const PotentialViolation = ({ index, hoverHandler, violation, violationStart, periodStart }) => (
  <div
    key={index}
    className={'card-body-violation-item alert alert-warning'}
    onMouseEnter={() => hoverHandler('enter', index)}
    onMouseLeave={() => hoverHandler('leave', index)}
  >
    {violation.breach && <h6>{violation.breach.desc}</h6>}
    <h6>{violation.rule.description}</h6>
    <small>{violation.rule.id}</small>
    <p className="vdetail">
      The driver will be in violation from {violationStart.format('h:mm A')} on the{' '}
      {violationStart.format('DD/MM/YYYY')}
    </p>
    <p className="vdetail">
      The violation will occur in the period starting at {periodStart.format('h:mm A')} on the{' '}
      {periodStart.format('DD/MM/YYYY')}
    </p>
  </div>
);

const EWDViolations = () => {
  const violations = useViolations();
  const checkpoint = useSelector(state => state.ewd.checkpoint);
  const violationsList = violations?.violations ? violations.violations : [];
  const dispatch = useDispatch();
  const driverTimeZone = useDriverTimezone();

  const debouncedHover = debounce((action, Id) => {
    if (action === 'enter') {
      dispatch(setViolationId(`show#!${Id}`));
    }

    if (action === 'leave') {
      dispatch(setViolationId(`hide#!${Id}`));
    }
  }, 100);

  const hoverHandler = (action, Id) => {
    debouncedHover(action, Id);
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div
          className="showScrollbarsOnHover"
          style={{ height, width, overflow: 'scroll', padding: '24px' }}
        >
          {violationsList.length > 0 &&
            violationsList.map((violation, index) => {
              const checkpointOrNow = checkpoint.checkpoint ?? new Date().valueOf();
              const violationStart = moment(violation.start).tz(driverTimeZone);
              const violationEnd = violation.finish
                ? moment(violation.finish).tz(driverTimeZone)
                : null;
              const periodStart = moment(violation.period.start).tz(driverTimeZone);

              return violation.start < checkpointOrNow ? (
                <Violation
                  index={index}
                  hoverHandler={hoverHandler}
                  violation={violation}
                  violationStart={violationStart}
                  violationEnd={violationEnd}
                  periodStart={periodStart}
                />
              ) : (
                <PotentialViolation
                  index={index}
                  hoverHandler={hoverHandler}
                  violation={violation}
                  violationStart={violationStart}
                  violationEnd={violationEnd}
                  periodStart={periodStart}
                />
              );
            })}
          {!violationsList.length && (
            <Row className="justify-content-md-center">
              <Col className="p-3" xs="auto">
                <p>No violations in this time period!</p>
              </Col>
            </Row>
          )}
        </div>
      )}
    </AutoSizer>
  );
};

export default EWDViolations;
