import React from 'react';
import { Route, Switch } from 'react-router';
import { FBTManager } from '../FBTManager';

import { Can, entities, FeatureFlag, useCan } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { useUserInfo } from 'features/user/userSlice';

export const paths = {
  FBTMANAGER_DEFAULT: '/fbtmanager'
};

export const FBTManagerRouter = props => {
  const user = useUserInfo();
  const can = useCan();
  const canAccessTripManager =
    user?.type?.code !== 'DRIVER' ||
    (user?.type?.code === 'DRIVER' &&
      can({ featureFlag: FeatureFlag.driverPortalTripManager.flag }))
      ? true
      : false;
  return (
    <Switch>
      <Route path={paths.FBTMANAGER_DEFAULT}>
        <Can
          oneOfEntities={[entities.TRIP_PURPOSE_ACCESS, entities.TRIP_VIEW, entities.TRIP]}
          otherConditions={[() => canAccessTripManager]}
          onFail={<NoAccessBody />}
        >
          <FBTManager />
        </Can>
      </Route>
    </Switch>
  );
};
