import React from 'react';
import { Route, Switch } from 'react-router';
import { SmartJobsCodes, AddNewCode, ViewCode, Paths } from './';
import { NoAccessBody } from 'components/pages/NoAccess';
import { Can, entities } from 'features/permissions';

export const SmartJobsCodesRouter = () => {
  return (
    <Switch>
      <Route path={Paths.ADD_NEW_CODE}>
        <AddNewCode />
      </Route>
      <Route path={Paths.EDIT_CODE}>
        <AddNewCode action="edit" />
      </Route>
      <Route path={Paths.VIEW_CODE}>
        <ViewCode />
      </Route>
      <Route path={Paths.CODES_TABLE}>
        <Can everyEntity={[entities.SMARTJOBSCODE]} onFail={<NoAccessBody />}>
          <SmartJobsCodes />
        </Can>
      </Route>
    </Switch>
  );
};
