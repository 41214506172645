import i18n from '../../i18nextConfig';

export const getReportNameByType = type => {
  switch (type) {
    case 'Ng::Report::Agreement':
      return i18n.t('Reports.Agreement');
    case 'Ng::Report::Alarm':
      return i18n.t('Reports.Alarm');
    case 'Ng::Report::AlertNotification':
      return i18n.t('Reports.Alert Notification');
    case 'Ng::Report::AutomatedMass':
      return i18n.t('Reports.Automated Mass');
    case 'Ng::Report::BinItems':
      return i18n.t('Reports.Bin Items');
    case 'Ng::Report::Callmate':
      return i18n.t('Reports.CallMate Usage');
    case 'Ng::Report::Camera':
      return i18n.t('Reports.Camera Event');
    case 'Ng::Report::DataPackUsage':
      return i18n.t('Reports.Data Pack Usage');
    case 'Ng::Report::DiagnosticTroubleCode':
      return i18n.t('Reports.Diagnostic Trouble Codes');
    case 'Ng::Report::DistanceTrip':
      return i18n.t('Reports.Distance Trip');
    case 'Ng::Report::DistanceTripDetail':
      return i18n.t('Reports.Distance Trip Detail');
    case 'Ng::Report::DriverAction':
      return i18n.t('Reports.Driver Action');
    case 'Ng::Report::DriverActionAudit':
      return i18n.t('Reports.Driver Action Audit');
    case 'Ng::Report::DriverDailySummary':
      return i18n.t('Reports.Driver Daily Summary');
    case 'Ng::Report::DriverHosAvailableHours':
      return i18n.t('Reports.Driver HOS Available Hours');
    case 'Ng::Report::DriverHosViolation':
      return i18n.t('Reports.Driver HOS Violation');
    case 'Ng::Report::DriverHoursDistance':
      return i18n.t('Reports.Driver Hours/Distance');
    case 'Ng::Report::DriverManagementSummary':
      return i18n.t('Reports.Driver Management Summary');
    case 'Ng::Report::DriverSummary':
      return i18n.t('Reports.Driver Summary');
    case 'Ng::Report::Duress':
      return i18n.t('Reports.Duress');
    case 'Ng::Report::DutyStatusSummary':
      return i18n.t('Reports.Duty Status Summary');
    case 'Ng::Report::ComplianceManagement':
      return i18n.t('Compliance Management');
    case 'Ng::Report::Dvir':
      return i18n.t('Reports.DVIR');
    case 'Ng::Report::EngineCoolantOverTemperature':
      return i18n.t('Reports.Engine Coolant Over Temperature');
    case 'Ng::Report::EngineLowOilPressure':
      return i18n.t('Reports.Engine Low Oil Pressure');
    case 'Ng::Report::EngineOilOverTemperature':
      return i18n.t('Reports.Engine Oil Over Temperature');
    case 'Ng::Report::EquipmentUtilization':
      return i18n.t('Reports.Equipment Utilization Report');
    case 'Ng::Report::EwdDriverDailySummary':
      return i18n.t('Reports.EWD Driver Daily Summary');
    case 'Ng::Report::EwdDriverEventAudit':
      return i18n.t('Reports.EWD Driver Event Audit');
    case 'Ng::Report::EwdFatigueManagementSummary':
      return i18n.t('Reports.EWD Fatigue Management Summary');
    case 'Ng::Report::EwdFatigueViolation':
      return i18n.t('Reports.EWD Fatigue Violation');
    case 'Ng::Report::ExcessiveAcceleration':
      return i18n.t('Reports.Excessive Acceleration');
    case 'Ng::Report::FatigueManagementSummary':
      return i18n.t('Reports.Fatigue Management Summary');
    case 'Ng::Report::FatigueSubmit':
      return i18n.t('Reports.Enforcement Request');
    case 'Ng::Report::FatigueViolation':
      return i18n.t('Reports.Fatigue Violation');
    case 'Ng::Report::FbtAudit':
      return i18n.t('Reports.FBT Audit');
    case 'Ng::Report::FbtElbSummary':
      return i18n.t('Reports.FBT ELB Summary');
    case 'Ng::Report::FbtTrip':
      return i18n.t('Reports.FBT Trip');
    case 'Ng::Report::Form':
      return i18n.t('Reports.Form');
    case 'Ng::Report::FuelRate':
      return i18n.t('Reports.Fuel Rate');
    case 'Ng::Report::FuelUsage':
      return i18n.t('Reports.Fuel Usage');
    case 'Ng::Report::GeofenceEntryExit':
      return i18n.t('Reports.Geofence Entry/Exit');
    case 'Ng::Report::GeofenceOvertime':
      return i18n.t('Reports.Geofence Overtime');
    case 'Ng::Report::GeofenceSafertogether':
      return i18n.t('Reports.Safer Together Event Performance');
    case 'Ng::Report::GeofenceSafertogetherMonthly':
      return i18n.t('Reports.Safer Together Monthly Performance');
    case 'Ng::Report::GeofenceSpeed':
      return i18n.t('Reports.Geofence Speed');
    case 'Ng::Report::GeofenceTrip':
      return i18n.t('Reports.Geofence Trip');
    case 'Ng::Report::GeofenceTripMass':
      return i18n.t('Reports.Geofence Trip Mass');
    case 'Ng::Report::GeofenceUndertime':
      return i18n.t('Reports.Geofence Undertime');
    case 'Ng::Report::GPIO':
      return i18n.t('Reports.GPIO');
    case 'Ng::Report::HarshBraking':
      return i18n.t('Reports.Harsh Braking');
    case 'Ng::Report::HarshCornering':
      return i18n.t('Reports.Harsh Cornering');
    case 'Ng::Report::HosCompliance':
      return i18n.t('Reports.HOS Compliance (US)');
    case 'Ng::Report::HosComplianceCa':
      return i18n.t('Reports.HOS Compliance (Canada)');
    case 'Ng::Report::IapCommentDeclaration':
      return i18n.t('Reports.IAP Comment Declaration');
    case 'Ng::Report::IapMassDeclaration':
      return i18n.t('Reports.IAP Mass Declaration');
    case 'Ng::Report::IdleTime':
      return i18n.t('Reports.Idle Time');
    case 'Ng::Report::Impact':
      return i18n.t('Reports.Impact');
    case 'Ng::Report::InspectionStatus':
      return i18n.t('Reports.Inspection Status');
    case 'Ng::Report::JobDriverFeedback':
      return i18n.t('Reports.Driver Feedback');
    case 'Ng::Report::JobKpi':
      return i18n.t('Reports.Job KPI');
    case 'Ng::Report::JobNotificationUsage':
      return i18n.t('Reports.Job Notification Usage');
    case 'Ng::Report::JobStatistic':
      return i18n.t('Reports.Job Statistic');
    case 'Ng::Report::JobSummary':
      return i18n.t('Reports.Drop/Adhoc Summary');
    case 'Ng::Report::LastContact':
      return i18n.t('Reports.Last Contact');
    case 'Ng::Report::LogBookCheck':
      return i18n.t('Reports.Log Book Check');
    case 'Ng::Report::LogEditHistory':
      return i18n.t('Reports.Log Edit History');
    case 'Ng::Report::MassManager':
      return i18n.t('Reports.Mass Manager');
    case 'Ng::Report::MessageHistory':
      return i18n.t('Reports.Message History');
    case 'Ng::Report::MixedEnergyPerformance':
      return i18n.t('Reports.MixedEnergyPerformance');
    case 'Ng::Report::Movement':
      return i18n.t('Reports.Movement');
    case 'Ng::Report::NonDelivery':
      return i18n.t('Reports.Non-Delivery');
    case 'Ng::Report::OutOfHours':
      return i18n.t('Reports.OutOfHoursDescription');
    case 'Ng::Report::OverRevving':
      return i18n.t('Reports.Over Revving');
    case 'Ng::Report::PowerTakeOff':
      return i18n.t('Reports.Power Take Off');
    case 'Ng::Report::PretripChecklist':
      return i18n.t('Reports.Pre-Trip Checklist');
    case 'Ng::Report::QuickjobsCombined':
      return i18n.t('Reports.QuickJobs Combined');
    case 'Ng::Report::QuickjobsDelay':
      return i18n.t('Reports.QuickJobs Delay');
    case 'Ng::Report::QuickjobsTrip':
      return i18n.t('Reports.QuickJobs Trip');
    case 'Ng::Report::RoadNetworkUsage':
      return i18n.t('Reports.Road Network Usage');
    case 'Ng::Report::RouteComplianceBreach':
      return i18n.t('Reports.Route Compliance Breach');
    case 'Ng::Report::RouteComplianceCompleted':
      return i18n.t('Reports.Completed Route Compliance');
    case 'Ng::Report::RouteComplianceMass':
      return i18n.t('Reports.Route Compliance Mass');
    case 'Ng::Report::RucDistance':
      return i18n.t('Reports.RUC Distance');
    case 'Ng::Report::RunsheetSummary':
      return i18n.t('Reports.Runsheet Summary');
    case 'Ng::Report::ScorecardProgressiveTrend':
      return i18n.t('Reports.Scorecard Progressive Trend');
    case 'Ng::Report::ScorecardSummary':
      return i18n.t('Reports.Scorecard Summary');
    case 'Ng::Report::SecurityEventsAudit':
      return i18n.t('Reports.Security Events Audit');
    case 'Ng::Report::Speed':
      return i18n.t('Reports.Speed Event Management');
    case 'Ng::Report::StateMileage':
      return i18n.t('Reports.State Mileage');
    case 'Ng::Report::TaskManagerRunsheets':
      return i18n.t('Reports.Runsheet Detail');
    case 'Ng::Report::Timesheet':
      return i18n.t('Reports.Timesheet');
    case 'Ng::Report::TimesheetAudit':
      return i18n.t('Reports.Timesheet Audit');
    case 'Ng::Report::TimesheetSummary':
      return i18n.t('Reports.Timesheet Summary');
    case 'Ng::Report::TripOdometer':
      return i18n.t('Reports.Trip Odometer');
    case 'Ng::Report::TyrePressure':
      return i18n.t('Reports.Tyre Pressure');
    case 'Ng::Report::UnassignedEventsChainOfCustody':
      return i18n.t('Reports.Unassigned Events Chain of Custody');
    case 'Ng::Report::UnsignedLogs':
      return i18n.t('Reports.Unsigned Logs');
    case 'Ng::Report::UserAlert':
      return i18n.t('Reports.User Alert Subscription');
    case 'Ng::Report::VehicleActivityRedshift':
      return i18n.t('Reports.Vehicle Activity');
    case 'Ng::Report::VehicleActivity':
      return i18n.t('Reports.Vehicle Activity legacy');
    case 'Ng::Report::VehicleDailySummary':
      return i18n.t('Reports.Vehicle Daily Summary');
    case 'Ng::Report::VehicleMaintenanceSummary':
      return i18n.t('Reports.Vehicle Maintenance Summary');
    case 'Ng::Report::VehicleSummary':
      return i18n.t('Reports.Vehicle Summary');
    case 'Ng::Report::VpmPeriod':
      return i18n.t('Reports.VPM Period');
    case 'Ng::Report::VpmTrip':
      return i18n.t('Reports.VPM Trip');
    case 'Ng::Report::WorkIdleRpm':
      return i18n.t('Reports.Work vs Idle RPM Report ');
    default:
      return '';
  }
};

export const getReportNameByTypeInRow = data => {
  switch (data?.type) {
    case 'Ng::Report::Agreement':
      return i18n.t('Reports.Agreement');
    case 'Ng::Report::Alarm':
      return i18n.t('Reports.Alarm');
    case 'Ng::Report::AlertNotification':
      return i18n.t('Reports.Alert Notification');
    case 'Ng::Report::AutomatedMass':
      return i18n.t('Reports.Automated Mass');
    case 'Ng::Report::BinItems':
      return i18n.t('Reports.Bin Items');
    case 'Ng::Report::Callmate':
      return i18n.t('Reports.CallMate Usage');
    case 'Ng::Report::Camera':
      return i18n.t('Reports.Camera Event');
    case 'Ng::Report::DataPackUsage':
      return i18n.t('Reports.Data Pack Usage');
    case 'Ng::Report::DiagnosticTroubleCode':
      return i18n.t('Reports.Diagnostic Trouble Codes');
    case 'Ng::Report::DistanceTrip':
      return i18n.t('Reports.Distance Trip');
    case 'Ng::Report::DistanceTripDetail':
      return i18n.t('Reports.Distance Trip Detail');
    case 'Ng::Report::DriverAction':
      return i18n.t('Reports.Driver Action');
    case 'Ng::Report::DriverActionAudit':
      return i18n.t('Reports.Driver Action Audit');
    case 'Ng::Report::DriverDailySummary':
      return i18n.t('Reports.Driver Daily Summary');
    case 'Ng::Report::DriverHosAvailableHours':
      return i18n.t('Reports.Driver HOS Available Hours');
    case 'Ng::Report::DriverHosViolation':
      return i18n.t('Reports.Driver HOS Violation');
    case 'Ng::Report::DriverHoursDistance':
      return i18n.t('Reports.Driver Hours/Distance');
    case 'Ng::Report::DriverManagementSummary':
      return i18n.t('Reports.Driver Management Summary');
    case 'Ng::Report::DriverSummary':
      return i18n.t('Reports.Driver Summary');
    case 'Ng::Report::Duress':
      return i18n.t('Reports.Duress');
    case 'Ng::Report::DutyStatusSummary':
      return i18n.t('Reports.Duty Status Summary');
    case 'Ng::Report::ComplianceManagement':
      return i18n.t('Compliance Management');
    case 'Ng::Report::Dvir':
      return i18n.t('Reports.DVIR');
    case 'Ng::Report::EngineCoolantOverTemperature':
      return i18n.t('Reports.Engine Coolant Over Temperature');
    case 'Ng::Report::EngineLowOilPressure':
      return i18n.t('Reports.Engine Low Oil Pressure');
    case 'Ng::Report::EngineOilOverTemperature':
      return i18n.t('Reports.Engine Oil Over Temperature');
    case 'Ng::Report::EquipmentUtilization':
      return i18n.t('Reports.Equipment Utilization Report');
    case 'Ng::Report::EwdDriverDailySummary':
      return i18n.t('Reports.EWD Driver Daily Summary');
    case 'Ng::Report::EwdDriverEventAudit':
      return i18n.t('Reports.EWD Driver Event Audit');
    case 'Ng::Report::EwdFatigueManagementSummary':
      return i18n.t('Reports.EWD Fatigue Management Summary');
    case 'Ng::Report::EwdFatigueViolation':
      return i18n.t('Reports.EWD Fatigue Violation');
    case 'Ng::Report::ExcessiveAcceleration':
      return i18n.t('Reports.Excessive Acceleration');
    case 'Ng::Report::FatigueManagementSummary':
      return i18n.t('Reports.Fatigue Management Summary');
    case 'Ng::Report::FatigueSubmit':
      return i18n.t('Reports.Enforcement Request');
    case 'Ng::Report::FatigueViolation':
      return i18n.t('Reports.Fatigue Violation');
    case 'Ng::Report::FbtAudit':
      return i18n.t('Reports.FBT Audit');
    case 'Ng::Report::FbtElbSummary':
      return i18n.t('Reports.FBT ELB Summary');
    case 'Ng::Report::FbtTrip':
      return i18n.t('Reports.FBT Trip');
    case 'Ng::Report::Form':
      return i18n.t('Reports.Form');
    case 'Ng::Report::FuelRate':
      return i18n.t('Reports.Fuel Rate');
    case 'Ng::Report::FuelUsage':
      return i18n.t('Reports.Fuel Usage');
    case 'Ng::Report::GeofenceEntryExit':
      return i18n.t('Reports.Geofence Entry/Exit');
    case 'Ng::Report::GeofenceOvertime':
      return i18n.t('Reports.Geofence Overtime');
    case 'Ng::Report::GeofenceSafertogether':
      return i18n.t('Reports.Safer Together Event Performance');
    case 'Ng::Report::GeofenceSafertogetherMonthly':
      return i18n.t('Reports.Safer Together Monthly Performance');
    case 'Ng::Report::GeofenceSpeed':
      return i18n.t('Reports.Geofence Speed');
    case 'Ng::Report::GeofenceTrip':
      return i18n.t('Reports.Geofence Trip');
    case 'Ng::Report::GeofenceTripMass':
      return i18n.t('Reports.Geofence Trip Mass');
    case 'Ng::Report::GeofenceUndertime':
      return i18n.t('Reports.Geofence Undertime');
    case 'Ng::Report::GPIO':
      return i18n.t('Reports.GPIO');
    case 'Ng::Report::HarshBraking':
      return i18n.t('Reports.Harsh Braking');
    case 'Ng::Report::HarshCornering':
      return i18n.t('Reports.Harsh Cornering');
    case 'Ng::Report::HosCompliance':
      return i18n.t('Reports.HOS Compliance (US)');
    case 'Ng::Report::HosComplianceCa':
      return i18n.t('Reports.HOS Compliance (Canada)');
    case 'Ng::Report::IapCommentDeclaration':
      return i18n.t('Reports.IAP Comment Declaration');
    case 'Ng::Report::IapMassDeclaration':
      return i18n.t('Reports.IAP Mass Declaration');
    case 'Ng::Report::IdleTime':
      return i18n.t('Reports.Idle Time');
    case 'Ng::Report::Impact':
      return i18n.t('Reports.Impact');
    case 'Ng::Report::InspectionStatus':
      return i18n.t('Reports.Inspection Status');
    case 'Ng::Report::JobDriverFeedback':
      return i18n.t('Reports.Driver Feedback');
    case 'Ng::Report::JobKpi':
      return i18n.t('Reports.Job KPI');
    case 'Ng::Report::JobNotificationUsage':
      return i18n.t('Reports.Job Notification Usage');
    case 'Ng::Report::JobStatistic':
      return i18n.t('Reports.Job Statistic');
    case 'Ng::Report::JobSummary':
      return i18n.t('Reports.Drop/Adhoc Summary');
    case 'Ng::Report::LastContact':
      return i18n.t('Reports.Last Contact');
    case 'Ng::Report::LogBookCheck':
      return i18n.t('Reports.Log Book Check');
    case 'Ng::Report::LogEditHistory':
      return i18n.t('Reports.Log Edit History');
    case 'Ng::Report::MassManager':
      return i18n.t('Reports.Mass Manager');
    case 'Ng::Report::MessageHistory':
      return i18n.t('Reports.Message History');
    case 'Ng::Report::MixedEnergyPerformance':
      return i18n.t('Reports.MixedEnergyPerformance');
    case 'Ng::Report::Movement':
      return i18n.t('Reports.Movement');
    case 'Ng::Report::NonDelivery':
      return i18n.t('Reports.Non-Delivery');
    case 'Ng::Report::OutOfHours':
      return i18n.t('Reports.OutOfHoursDescription');
    case 'Ng::Report::OverRevving':
      return i18n.t('Reports.Over Revving');
    case 'Ng::Report::PowerTakeOff':
      return i18n.t('Reports.Power Take Off');
    case 'Ng::Report::PretripChecklist':
      return i18n.t('Reports.Pre-Trip Checklist');
    case 'Ng::Report::QuickjobsCombined':
      return i18n.t('Reports.QuickJobs Combined');
    case 'Ng::Report::QuickjobsDelay':
      return i18n.t('Reports.QuickJobs Delay');
    case 'Ng::Report::QuickjobsTrip':
      return i18n.t('Reports.QuickJobs Trip');
    case 'Ng::Report::RoadNetworkUsage':
      return i18n.t('Reports.Road Network Usage');
    case 'Ng::Report::RouteComplianceBreach':
      return i18n.t('Reports.Route Compliance Breach');
    case 'Ng::Report::RouteComplianceCompleted':
      return i18n.t('Reports.Completed Route Compliance');
    case 'Ng::Report::RouteComplianceMass':
      return i18n.t('Reports.Route Compliance Mass');
    case 'Ng::Report::RucDistance':
      return i18n.t('Reports.RUC Distance');
    case 'Ng::Report::RunsheetSummary':
      return i18n.t('Reports.Runsheet Summary');
    case 'Ng::Report::ScorecardProgressiveTrend':
      return i18n.t('Reports.Scorecard Progressive Trend');
    case 'Ng::Report::ScorecardSummary':
      return i18n.t('Reports.Scorecard Summary');
    case 'Ng::Report::SecurityEventsAudit':
      return i18n.t('Reports.Security Events Audit');
    case 'Ng::Report::Speed':
      return i18n.t('Reports.Speed Event Management');
    case 'Ng::Report::StateMileage':
      return i18n.t('Reports.State Mileage');
    case 'Ng::Report::TaskManagerRunsheets':
      return i18n.t('Reports.Runsheet Detail');
    case 'Ng::Report::Timesheet':
      return i18n.t('Reports.Timesheet');
    case 'Ng::Report::TimesheetAudit':
      return i18n.t('Reports.Timesheet Audit');
    case 'Ng::Report::TimesheetSummary':
      return i18n.t('Reports.Timesheet Summary');
    case 'Ng::Report::TripOdometer':
      return i18n.t('Reports.Trip Odometer');
    case 'Ng::Report::TyrePressure':
      return i18n.t('Reports.Tyre Pressure');
    case 'Ng::Report::UnassignedEventsChainOfCustody':
      return i18n.t('Reports.Unassigned Events Chain of Custody');
    case 'Ng::Report::UnsignedLogs':
      return i18n.t('Reports.Unsigned Logs');
    case 'Ng::Report::UserAlert':
      return i18n.t('Reports.User Alert Subscription');
    case 'Ng::Report::VehicleActivityRedshift':
      return i18n.t('Reports.Vehicle Activity');
    case 'Ng::Report::VehicleActivity':
      return i18n.t('Reports.Vehicle Activity legacy');
    case 'Ng::Report::VehicleDailySummary':
      return i18n.t('Reports.Vehicle Daily Summary');
    case 'Ng::Report::VehicleMaintenanceSummary':
      return i18n.t('Reports.Vehicle Maintenance Summary');
    case 'Ng::Report::VehicleSummary':
      return i18n.t('Reports.Vehicle Summary');
    case 'Ng::Report::VpmPeriod':
      return i18n.t('Reports.VPM Period');
    case 'Ng::Report::VpmTrip':
      return i18n.t('Reports.VPM Trip');
    case 'Ng::Report::WorkIdleRpm':
      return i18n.t('Reports.Work vs Idle RPM Report ');
    default:
      return '';
  }
};

export const getReportDescriptionByType = type => {
  switch (type) {
    case 'Ng::Report::Agreement':
      return i18n.t('Reports.AgreementDescription');
    case 'Ng::Report::Alarm':
      return i18n.t('Reports.AlarmDescription');
    case 'Ng::Report::AlertNotification':
      return i18n.t('Reports.Alert NotificationDescription');
    case 'Ng::Report::AutomatedMass':
      return i18n.t('Reports.Automated MassDescription');
    case 'Ng::Report::BinItems':
      return i18n.t('Reports.Bin ItemsDescription');
    case 'Ng::Report::Callmate':
      return i18n.t('Reports.CallMate UsageDescription');
    case 'Ng::Report::Camera':
      return i18n.t('Reports.Camera EventDescription');
    case 'Ng::Report::DataPackUsage':
      return i18n.t('Reports.Data Pack UsageDescription');
    case 'Ng::Report::DiagnosticTroubleCode':
      return i18n.t('Reports.Diagnostic Trouble CodesDescription');
    case 'Ng::Report::DistanceTrip':
      return i18n.t('Reports.Distance TripDescription');
    case 'Ng::Report::DistanceTripDetail':
      return i18n.t('Reports.Distance Trip DetailDescription');
    case 'Ng::Report::DriverAction':
      return i18n.t('Reports.Driver ActionDescription');
    case 'Ng::Report::DriverActionAudit':
      return i18n.t('Reports.Driver Action AuditDescription');
    case 'Ng::Report::DriverDailySummary':
      return i18n.t('Reports.Driver Daily SummaryDescription');
    case 'Ng::Report::DriverHosAvailableHours':
      return i18n.t('Reports.Driver HOS Available Hours Description');
    case 'Ng::Report::DriverHosViolation':
      return i18n.t('Reports.Driver HOS Violation Description');
    case 'Ng::Report::DriverHoursDistance':
      return i18n.t('Reports.Driver Hours/DistanceDescription');
    case 'Ng::Report::DriverManagementSummary':
      return i18n.t('Reports.Driver Management SummaryDescription');
    case 'Ng::Report::DriverSummary':
      return i18n.t('Reports.Driver SummaryDescription');
    case 'Ng::Report::Duress':
      return i18n.t('Reports.DuressDescription');
    case 'Ng::Report::DutyStatusSummary':
      return i18n.t('Reports.Duty Status Summary Description');
    case 'Ng::Report::ComplianceManagement':
      return i18n.t('Reports.Compliance ManagementDescription');
    case 'Ng::Report::Dvir':
      return i18n.t('Reports.DVIR Description');
    case 'Ng::Report::EngineCoolantOverTemperature':
      return i18n.t('Reports.Engine Coolant Over TemperatureDescription');
    case 'Ng::Report::EngineLowOilPressure':
      return i18n.t('Reports.Engine Low Oil PressureDescription');
    case 'Ng::Report::EngineOilOverTemperature':
      return i18n.t('Reports.Engine Oil Over TemperatureDescription');
    case 'Ng::Report::EquipmentUtilization':
      return i18n.t('Reports.Equipment Utilization Report Description');
    case 'Ng::Report::EwdDriverDailySummary':
      return i18n.t('Reports.EWD Driver Daily SummaryDescription');
    case 'Ng::Report::EwdDriverEventAudit':
      return i18n.t('Reports.EWD Driver Event AuditDescription');
    case 'Ng::Report::EwdFatigueManagementSummary':
      return i18n.t('Reports.EWD Fatigue Management SummaryDescription');
    case 'Ng::Report::EwdFatigueViolation':
      return i18n.t('Reports.EWD Fatigue ViolationDescription');
    case 'Ng::Report::ExcessiveAcceleration':
      return i18n.t('Reports.Excessive AccelerationDescription');
    case 'Ng::Report::FatigueManagementSummary':
      return i18n.t('Reports.Fatigue Management SummaryDescription');
    case 'Ng::Report::FatigueSubmit':
      return i18n.t('Reports.Enforcement RequestDescription');
    case 'Ng::Report::FatigueViolation':
      return i18n.t('Reports.Fatigue ViolationDescription');
    case 'Ng::Report::FbtAudit':
      return i18n.t('Reports.FBT Audit SummaryDescription');
    case 'Ng::Report::FbtElbSummary':
      return i18n.t('Reports.FBT ELB Summary SummaryDescription');
    case 'Ng::Report::FbtTrip':
      return i18n.t('Reports.FBT Trip SummaryDescription');
    case 'Ng::Report::Form':
      return i18n.t('Reports.FormDescription');
    case 'Ng::Report::FuelRate':
      return i18n.t('Reports.Fuel RateDescription');
    case 'Ng::Report::FuelUsage':
      return i18n.t('Reports.Fuel UsageDescription');
    case 'Ng::Report::GeofenceEntryExit':
      return i18n.t('Reports.Geofence Entry/ExitDescription');
    case 'Ng::Report::GeofenceOvertime':
      return i18n.t('Reports.Geofence OvertimeDescription');
    case 'Ng::Report::GeofenceSafertogether':
      return i18n.t('Reports.Safer Together Event Performance Description');
    case 'Ng::Report::GeofenceSafertogetherMonthly':
      return i18n.t('Reports.Safer Together Monthly Performance Description');
    case 'Ng::Report::GeofenceSpeed':
      return i18n.t('Reports.Geofence SpeedDescription');
    case 'Ng::Report::GeofenceTrip':
      return i18n.t('Reports.Geofence TripDescription');
    case 'Ng::Report::GeofenceTripMass':
      return i18n.t('Reports.Geofence Trip MassDescription');
    case 'Ng::Report::GeofenceUndertime':
      return i18n.t('Reports.Geofence UndertimeDescription');
    case 'Ng::Report::GPIO':
      return i18n.t('Reports.GpioDescription');
    case 'Ng::Report::HarshBraking':
      return i18n.t('Reports.Harsh BrakingDescription');
    case 'Ng::Report::HarshCornering':
      return i18n.t('Reports.Harsh CorneringDescription');
    case 'Ng::Report::HosCompliance':
      return i18n.t('Reports.HOS Compliance Description (US)');
    case 'Ng::Report::HosComplianceCa':
      return i18n.t('Reports.HOS Compliance Description (Canada)');
    case 'Ng::Report::IapCommentDeclaration':
      return i18n.t('Reports.IAP Comment DeclarationDescription');
    case 'Ng::Report::IapMassDeclaration':
      return i18n.t('Reports.IAP Mass DeclarationDescription');
    case 'Ng::Report::IdleTime':
      return i18n.t('Reports.Idle TimeDescription');
    case 'Ng::Report::Impact':
      return i18n.t('Reports.Impact Description');
    case 'Ng::Report::InspectionStatus':
      return i18n.t('Reports.Inspection Status Description');
    case 'Ng::Report::JobDriverFeedback':
      return i18n.t('Reports.Driver FeedbackDescription');
    case 'Ng::Report::JobKpi':
      return i18n.t('Reports.JobKPIDescription');
    case 'Ng::Report::JobNotificationUsage':
      return i18n.t('Reports.Job Notification UsageDescription');
    case 'Ng::Report::JobStatistic':
      return i18n.t('Reports.Job StatisticDescription');
    case 'Ng::Report::JobSummary':
      return i18n.t('Reports.Drop/Adhoc SummaryDescription');
    case 'Ng::Report::LastContact':
      return i18n.t('Reports.Last ContactDescription');
    case 'Ng::Report::LogBookCheck':
      return i18n.t('Reports.Log Book CheckDescription');
    case 'Ng::Report::LogEditHistory':
      return i18n.t('Reports.Log Edit History Description');
    case 'Ng::Report::MassManager':
      return i18n.t('Reports.Mass ManagerDescription');
    case 'Ng::Report::MessageHistory':
      return i18n.t('Reports.Message HistoryDescription');
    case 'Ng::Report::MixedEnergyPerformance':
      return i18n.t('Reports.MixedEnergyPerformance Description');
    case 'Ng::Report::Movement':
      return i18n.t('Reports.MovementDescription');
    case 'Ng::Report::NonDelivery':
      return i18n.t('Reports.Non-DeliveryDescription');
    case 'Ng::Report::OutOfHours':
      return i18n.t('Reports.OutOfHoursDescription');
    case 'Ng::Report::OverRevving':
      return i18n.t('Reports.Over RevvingDescription');
    case 'Ng::Report::PowerTakeOff':
      return i18n.t('Reports.Power Take OffDescription');
    case 'Ng::Report::PretripChecklist':
      return i18n.t('Reports.Pre-Trip ChecklistDescription');
    case 'Ng::Report::QuickjobsCombined':
      return i18n.t('Reports.QuickJobs CombinedDescription');
    case 'Ng::Report::QuickjobsDelay':
      return i18n.t('Reports.QuickJobs DelayDescription');
    case 'Ng::Report::QuickjobsTrip':
      return i18n.t('Reports.QuickJobs TripDescription');
    case 'Ng::Report::RoadNetworkUsage':
      return i18n.t('Reports.Road Network UsageDescription');
    case 'Ng::Report::RouteComplianceBreach':
      return i18n.t('Reports.Route Compliance BreachDescription');
    case 'Ng::Report::RouteComplianceCompleted':
      return i18n.t('Reports.Completed Route ComplianceDescription');
    case 'Ng::Report::RouteComplianceMass':
      return i18n.t('Reports.Route Compliance MassDescription');
    case 'Ng::Report::RucDistance':
      return i18n.t('Reports.RUC DistanceDescription');
    case 'Ng::Report::RunsheetSummary':
      return i18n.t('Reports.Runsheet SummaryDescription');
    case 'Ng::Report::ScorecardProgressiveTrend':
      return i18n.t('Reports.Scorecard Progressive Trend Description');
    case 'Ng::Report::ScorecardSummary':
      return i18n.t('Reports.Scorecard Summary Description');
    case 'Ng::Report::SecurityEventsAudit':
      return i18n.t('Reports.Security Events AuditDescription');
    case 'Ng::Report::Speed':
      return i18n.t('Reports.Speed Event ManagementDescription');
    case 'Ng::Report::StateMileage':
      return i18n.t('Reports.State MileageDescription');
    case 'Ng::Report::TaskManagerRunsheets':
      return i18n.t('Reports.Runsheet DetailDescription');
    case 'Ng::Report::Timesheet':
      return i18n.t('Reports.Timesheet Report Description');
    case 'Ng::Report::TimesheetAudit':
      return i18n.t('Reports.Timesheet AuditDescription');
    case 'Ng::Report::TimesheetSummary':
      return i18n.t('Timesheet SummaryDescription');
    case 'Ng::Report::TripOdometer':
      return i18n.t('Reports.Trip OdometerDescription');
    case 'Ng::Report::TyrePressure':
      return i18n.t('Reports.Tyre PressureDescription');
    case 'Ng::Report::UnassignedEventsChainOfCustody':
      return i18n.t('Reports.Unassigned Events Chain of CustodyDescription');
    case 'Ng::Report::UnsignedLogs':
      return i18n.t('Reports.Unsigned Logs Description');
    case 'Ng::Report::UserAlert':
      return i18n.t('Reports.User Alert SubscriptionDescription');
    case 'Ng::Report::VehicleActivityRedshift':
      return i18n.t('Reports.Vehicle ActivityDescription');
    case 'Ng::Report::VehicleActivity':
      return i18n.t('Reports.Vehicle Activity legacyDescription');
    case 'Ng::Report::VehicleDailySummary':
      return i18n.t('Reports.Vehicle Daily SummaryDescription');
    case 'Ng::Report::VehicleMaintenanceSummary':
      return i18n.t('Reports.Vehicle Maintenance SummaryDescription');
    case 'Ng::Report::VehicleSummary':
      return i18n.t('Reports.Vehicle SummaryDescription');
    case 'Ng::Report::VpmPeriod':
      return i18n.t('Reports.VPM PeriodDescription');
    case 'Ng::Report::VpmTrip':
      return i18n.t('Reports.VPM TripDescription');
    case 'Ng::Report::WorkIdleRpm':
      return i18n.t('Reports.Work vs Idle RPM Report Description');
    default:
      return '';
  }
};

export const getReportCategoryByType = type => {
  switch (type) {
    case 'Ng::Report::Agreement':
      return 'General';
    case 'Ng::Report::Alarm':
      return 'General';
    case 'Ng::Report::AlertNotification':
      return 'General';
    case 'Ng::Report::AutomatedMass':
      return 'General';
    case 'Ng::Report::BinItems':
      return 'SmartJobs';
    case 'Ng::Report::Callmate':
      return 'General';
    case 'Ng::Report::Camera':
      return 'General';
    case 'Ng::Report::DataPackUsage':
      return 'General';
    case 'Ng::Report::DiagnosticTroubleCode':
      return 'Performance';
    case 'Ng::Report::DistanceTrip':
      return 'General';
    case 'Ng::Report::DistanceTripDetail':
      return 'General';
    case 'Ng::Report::DriverAction':
      return 'General';
    case 'Ng::Report::DriverActionAudit':
      return 'Sentinel';
    case 'Ng::Report::DriverDailySummary':
      return 'Sentinel';
    case 'Ng::Report::DriverHosAvailableHours':
      return 'ELD';
    case 'Ng::Report::DriverHosViolation':
      return 'ELD';
    case 'Ng::Report::DriverHoursDistance':
      return 'Sentinel';
    case 'Ng::Report::DriverManagementSummary':
      return 'General';
    case 'Ng::Report::DriverSummary':
      return 'General';
    case 'Ng::Report::Duress':
      return 'General';
    case 'Ng::Report::DutyStatusSummary':
      return 'ELD';
    case 'Ng::Report::ComplianceManagement':
      return 'Compliance';
    case 'Ng::Report::Dvir':
      return 'Compliance';
    case 'Ng::Report::EngineCoolantOverTemperature':
      return 'Performance';
    case 'Ng::Report::EngineLowOilPressure':
      return 'Performance';
    case 'Ng::Report::EngineOilOverTemperature':
      return 'Performance';
    case 'Ng::Report::EquipmentUtilization':
      return 'Equipment';
    case 'Ng::Report::EwdDriverDailySummary':
      return 'EWD';
    case 'Ng::Report::EwdDriverEventAudit':
      return 'EWD';
    case 'Ng::Report::EwdFatigueManagementSummary':
      return 'EWD';
    case 'Ng::Report::EwdFatigueViolation':
      return 'EWD';
    case 'Ng::Report::ExcessiveAcceleration':
      return 'Performance';
    case 'Ng::Report::FatigueManagementSummary':
      return 'Sentinel';
    case 'Ng::Report::FatigueSubmit':
      return 'Sentinel';
    case 'Ng::Report::FatigueViolation':
      return 'Sentinel';
    case 'Ng::Report::FbtAudit':
      return 'FBT';
    case 'Ng::Report::FbtElbSummary':
      return 'FBT';
    case 'Ng::Report::FbtTrip':
      return 'FBT';
    case 'Ng::Report::Form':
      return 'General';
    case 'Ng::Report::FuelRate':
      return 'Performance';
    case 'Ng::Report::FuelUsage':
      return 'Performance';
    case 'Ng::Report::GeofenceEntryExit':
      return 'Geofence';
    case 'Ng::Report::GeofenceOvertime':
      return 'Geofence';
    case 'Ng::Report::GeofenceSafertogether':
      return 'Geofence';
    case 'Ng::Report::GeofenceSafertogetherMonthly':
      return 'Geofence';
    case 'Ng::Report::GeofenceSpeed':
      return 'Geofence';
    case 'Ng::Report::GeofenceTrip':
      return 'Geofence';
    case 'Ng::Report::GeofenceTripMass':
      return 'Geofence';
    case 'Ng::Report::GeofenceUndertime':
      return 'Geofence';
    case 'Ng::Report::GPIO':
      return 'General';
    case 'Ng::Report::HarshBraking':
      return 'Performance';
    case 'Ng::Report::HarshCornering':
      return 'Performance';
    case 'Ng::Report::HosCompliance':
      return 'ELD';
    case 'Ng::Report::HosComplianceCa':
      return 'ELD';
    case 'Ng::Report::IapCommentDeclaration':
      return 'General';
    case 'Ng::Report::IapMassDeclaration':
      return 'General';
    case 'Ng::Report::IdleTime':
      return 'Performance';
    case 'Ng::Report::Impact':
      return 'Performance';
    case 'Ng::Report::InspectionStatus':
      return 'Maintenance';
    case 'Ng::Report::JobDriverFeedback':
      return 'SmartJobs';
    case 'Ng::Report::JobKpi':
      return 'SmartJobs';
    case 'Ng::Report::JobNotificationUsage':
      return 'SmartJobs';
    case 'Ng::Report::JobStatistic':
      return 'SmartJobs';
    case 'Ng::Report::JobSummary':
      return 'SmartJobs';
    case 'Ng::Report::LastContact':
      return 'General';
    case 'Ng::Report::LogBookCheck':
      return 'Sentinel';
    case 'Ng::Report::LogEditHistory':
      return 'ELD';
    case 'Ng::Report::MassManager':
      return 'Compliance';
    case 'Ng::Report::MessageHistory':
      return 'General';
    case 'Ng::Report::MixedEnergyPerformance':
      return 'Performance';
    case 'Ng::Report::Movement':
      return 'General';
    case 'Ng::Report::NonDelivery':
      return 'SmartJobs';
    case 'Ng::Report::OutOfHours':
      return 'General';
    case 'Ng::Report::OverRevving':
      return 'Performance';
    case 'Ng::Report::PowerTakeOff':
      return 'Performance';
    case 'Ng::Report::PretripChecklist':
      return 'Compliance';
    case 'Ng::Report::QuickjobsCombined':
      return 'QuickJobs';
    case 'Ng::Report::QuickjobsDelay':
      return 'QuickJobs';
    case 'Ng::Report::QuickjobsTrip':
      return 'QuickJobs';
    case 'Ng::Report::RoadNetworkUsage':
      return 'General';
    case 'Ng::Report::RouteComplianceBreach':
      return 'Compliance';
    case 'Ng::Report::RouteComplianceCompleted':
      return 'Compliance';
    case 'Ng::Report::RouteComplianceMass':
      return 'Compliance';
    case 'Ng::Report::RucDistance':
      return 'General';
    case 'Ng::Report::RunsheetSummary':
      return 'SmartJobs';
    case 'Ng::Report::ScorecardProgressiveTrend':
      return 'General';
    case 'Ng::Report::ScorecardSummary':
      return 'General';
    case 'Ng::Report::SecurityEventsAudit':
      return 'General';
    case 'Ng::Report::Speed':
      return 'Compliance';
    case 'Ng::Report::StateMileage':
      return 'General';
    case 'Ng::Report::TaskManagerRunsheets':
      return 'SmartJobs';
    case 'Ng::Report::Timesheet':
      return 'General';
    case 'Ng::Report::TimesheetAudit':
      return 'Timesheets';
    case 'Ng::Report::TimesheetSummary':
      return 'Timesheets';
    case 'Ng::Report::TripOdometer':
      return 'Performance';
    case 'Ng::Report::TyrePressure':
      return 'Performance';
    case 'Ng::Report::UnassignedEventsChainOfCustody':
      return 'ELD';
    case 'Ng::Report::UnsignedLogs':
      return 'ELD';
    case 'Ng::Report::UserAlert':
      return 'General';
    case 'Ng::Report::VehicleActivityRedshift':
      return 'General';
    case 'Ng::Report::VehicleActivity':
      return 'General';
    case 'Ng::Report::VehicleDailySummary':
      return 'General';
    case 'Ng::Report::VehicleMaintenanceSummary':
      return 'Maintenance';
    case 'Ng::Report::VehicleSummary':
      return 'Maintenance';
    case 'Ng::Report::VpmPeriod':
      return 'Performance';
    case 'Ng::Report::VpmTrip':
      return 'Performance';
    case 'Ng::Report::WorkIdleRpm':
      return 'Equipment';
    default:
      return '';
  }
};
