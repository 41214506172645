import { useDrag, useDrop } from 'react-dnd';
import { LockOutlined } from '@ant-design/icons';

import styles from './ManageColumns.module.scss';

export const DraggableItem = ({ id, type, title, index, moveItem, canDrag }) => {
  const [{ cursor, isDragging }, drag] = useDrag({
    type,
    canDrag,
    item: () => {
      return { id, index, type };
    },
    collect: monitor => ({
      cursor: canDrag ? 'move' : 'default',
      isDragging: monitor.isDragging() || monitor.getItem()?.id === id
    })
  });

  const [{ handlerId }, drop] = useDrop({
    accept: [type],
    collect: monitor => ({
      handlerId: monitor.getHandlerId()
    }),
    hover(item, monitor) {
      if (item.id !== id) {
        moveItem({
          dragId: item.id,
          dragIndex: item.index,
          dragType: item.type,
          hoverId: id,
          hoverIndex: index,
          hoverType: type
        });
      }
    }
  });

  return (
    <div
      ref={node => drag(drop(node))}
      role={type}
      data-handler-id={handlerId}
      className={isDragging ? styles.activeDragItem : styles.dragItem}
      style={{ cursor }}
    >
      <span>{title}</span>
      {!canDrag && <LockOutlined />}
    </div>
  );
};
