import { LoadingTable } from 'components/grid/LoadingTable';
import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import {
  cache,
  ellipsisButtonCellRenderer,
  nameCellRenderer,
  fleetsCellRenderer,
  plannedDurationCellRenderer,
  typeCellRenderer,
  stopsNumberCellRenderer,
  calculatedDistanceCellRenderer
} from './CellRenderers';
import { useTranslation } from 'react-i18next';
import { useCompanies } from 'features/company/companySlice';
import { useCan } from 'features/permissions';

export const JourneysTable = ({
  journeys,
  isLoading,
  setTableRef,
  fleets,
  localization,
  deleteModal,
  filterTab,
  restoreJourney
}) => {
  const { t } = useTranslation();
  const companies = useCompanies();
  const can = useCan();
  if (isLoading) return <LoadingTable columnSizes={[80, 60, 60, 68, 63, 49]} />;

  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 700,
          fleet: 230,
          type: 150,
          plannedDuration: 150,
          calculatedDistance: 170,
          noOfStops: 100,
          ellipsis: 70
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.name +
            fixedColumnsWidth.fleet +
            fixedColumnsWidth.type +
            fixedColumnsWidth.plannedDuration +
            fixedColumnsWidth.calculatedDistance +
            fixedColumnsWidth.ellipsis);

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={40}
            headerStyle={{ paddingTop: '5px' }}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={journeys.length}
            rowGetter={({ index }) => journeys[index]}
            ref={ref => setTableRef(ref)}
          >
            <Column
              label={t('JourneyPlanner.Table.Name')}
              dataKey="name"
              width={fixedColumnsWidth.name}
              cellRenderer={nameCellRenderer}
            />
            <Column
              label={t('JourneyPlanner.Table.Fleet')}
              dataKey="name"
              width={fixedColumnsWidth.fleet}
              cellRenderer={props =>
                fleetsCellRenderer({
                  ...props,
                  fleets: fleets.concat({ id: -1, name: t('Common.NoFleet') }),
                  companies,
                  t
                })
              }
            />
            <Column
              label={t('JourneyPlanner.Table.Type')}
              dataKey="type"
              width={fixedColumnsWidth.type}
              cellDataGetter={typeCellRenderer}
            />
            <Column
              label={t('JourneyPlanner.Table.PlannedDuration')}
              dataKey="estimatedDuration"
              width={fixedColumnsWidth.plannedDuration}
              cellDataGetter={plannedDurationCellRenderer}
            />
            <Column
              label={`${t('JourneyPlanner.Table.CalculatedDistance')} (${
                localization.formats.speed.unit
              })`}
              dataKey="calculatedDistance"
              width={fixedColumnsWidth.calculatedDistance}
              cellRenderer={props => calculatedDistanceCellRenderer({ ...props, localization })}
            />
            <Column
              label={t('JourneyPlanner.Table.Stops')}
              dataKey="stopsCount"
              width={dynamicColumnWidth}
              cellDataGetter={stopsNumberCellRenderer}
            />
            <Column
              label={t('JourneyPlanner.Table.Actions')}
              dataKey="ellipsisMenu"
              style={{ overflow: 'visible' }}
              width={52}
              flexShrink={0}
              cellRenderer={props =>
                ellipsisButtonCellRenderer({
                  ...props,
                  can,
                  deleteModal,
                  filterTab,
                  restoreJourney
                })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
