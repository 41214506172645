import * as Yup from 'yup';
import i18n from 'i18next';

export const initialValues = {
  name: '',
  companyId: '',
  type: '',
  externalId: '',
  address: {
    number: '',
    street: '',
    suburb: '',
    state: undefined,
    postcode: '',
    country: undefined
  },
  GPS: {
    Lat: '',
    Lng: ''
  },
  siteNotes: ''
};

export const validationSchema = ({ locationsByCompanyId, initialValues }) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(`${i18n.t('Locations.FormValidation.NameRequired')}`)
      .matches(/\S/, i18n.t('Locations.FormValidation.NameRequired'))
      .test(
        'Unique',
        i18n.t('Locations.FormValidation.IdenticalName'),
        value =>
          !locationsByCompanyId.find(loc => loc.name === value) || value === initialValues?.name
      ),
    companyId: Yup.string().required(`${i18n.t('Locations.FormValidation.CompanyRequired')}`),
    type: Yup.string().required(`${i18n.t('Locations.FormValidation.LocationTypeRequired')}`)
  });
};

export const TYPES = {
  branch: 'branch',
  kiosk: 'kiosk',
  depot: 'depot',
  job: 'job',
  supplier: 'supplier',
  customer: 'customer',
  journey: 'journey',
  pillar: 'pillar',
  pole: 'pole'
};

export const countries = [
  { name: 'Afghanistan' },
  { name: 'Åland Islands' },
  { name: 'Albania' },
  { name: 'Algeria' },
  { name: 'American Samoa' },
  { name: 'Andorra' },
  { name: 'Angola' },
  { name: 'Anguilla' },
  { name: 'Antarctica' },
  { name: 'Antigua and Barbuda' },
  { name: 'Argentina' },
  { name: 'Armenia' },
  { name: 'Aruba' },
  { name: 'Australia' },
  { name: 'Austria' },
  { name: 'Azerbaijan' },
  { name: 'Bahamas' },
  { name: 'Bahrain' },
  { name: 'Bangladesh' },
  { name: 'Barbados' },
  { name: 'Belarus' },
  { name: 'Belgium' },
  { name: 'Belize' },
  { name: 'Benin' },
  { name: 'Bermuda' },
  { name: 'Bhutan' },
  { name: 'Bolivia' },
  { name: 'Bonaire, Sint Eustatius and Saba' },
  { name: 'Bosnia and Herzegovina' },
  { name: 'Botswana' },
  { name: 'Bouvet Island' },
  { name: 'Brazil' },
  { name: 'British Indian Ocean Territory' },
  { name: 'Brunei' },
  { name: 'Bulgaria' },
  { name: 'Burkina Faso' },
  { name: 'Burundi' },
  { name: 'Cambodia' },
  { name: 'Cameroon' },
  { name: 'Canada' },
  { name: 'Cape Verde' },
  { name: 'Caribbean Netherlands' },
  { name: 'Cayman Islands' },
  { name: 'Central African Republic' },
  { name: 'Chad' },
  { name: 'Chile' },
  { name: 'China' },
  { name: 'Christmas Island' },
  { name: 'Cocos (Keeling) Islands' },
  { name: 'Colombia' },
  { name: 'Comoros' },
  { name: 'Congo' },
  { name: 'Congo (Democratic Republic of the)' },
  { name: 'Cook Islands' },
  { name: 'Costa Rica' },
  { name: 'Côte d’Ivoire' },
  { name: 'Croatia' },
  { name: 'Cuba' },
  { name: 'Curaçao' },
  { name: 'Cyprus' },
  { name: 'Czech Republic' },
  { name: 'Denmark' },
  { name: 'Djibouti' },
  { name: 'Dominica' },
  { name: 'Dominican Republic' },
  { name: 'Ecuador' },
  { name: 'Egypt' },
  { name: 'El Salvador' },
  { name: 'Equatorial Guinea' },
  { name: 'Eritrea' },
  { name: 'Estonia' },
  { name: 'Ethiopia' },
  { name: 'Falkland Islands (Malvinas)' },
  { name: 'Faroe Islands' },
  { name: 'Fiji' },
  { name: 'Finland' },
  { name: 'France' },
  { name: 'French Guiana' },
  { name: 'French Polynesia' },
  { name: 'French Southern Territories' },
  { name: 'Gabon' },
  { name: 'Gambia' },
  { name: 'Georgia' },
  { name: 'Germany' },
  { name: 'Ghana' },
  { name: 'Gibraltar' },
  { name: 'Greece' },
  { name: 'Greenland' },
  { name: 'Grenada' },
  { name: 'Guadeloupe' },
  { name: 'Guam' },
  { name: 'Guatemala' },
  { name: 'Guernsey' },
  { name: 'Guinea' },
  { name: 'Guinea-Bissau' },
  { name: 'Guyana' },
  { name: 'Haiti' },
  { name: 'Heard Island and McDonald Islands' },
  { name: 'Holy See' },
  { name: 'Honduras' },
  { name: 'Hong Kong' },
  { name: 'Hungary' },
  { name: 'Iceland' },
  { name: 'India' },
  { name: 'Indonesia' },
  { name: 'Iran' },
  { name: 'Iraq' },
  { name: 'Ireland' },
  { name: 'Isle of Man' },
  { name: 'Israel' },
  { name: 'Italy' },
  { name: 'Jamaica' },
  { name: 'Japan' },
  { name: 'Jersey' },
  { name: 'Jordan' },
  { name: 'Kazakhstan' },
  { name: 'Kenya' },
  { name: 'Kiribati' },
  { name: 'Kosovo' },
  { name: 'Kuwait' },
  { name: 'Kyrgyzstan' },
  { name: 'Laos' },
  { name: 'Latvia' },
  { name: 'Lebanon' },
  { name: 'Lesotho' },
  { name: 'Liberia' },
  { name: 'Libya' },
  { name: 'Liechtenstein' },
  { name: 'Lithuania' },
  { name: 'Luxembourg' },
  { name: 'Macau' },
  { name: 'Macedonia' },
  { name: 'Madagascar' },
  { name: 'Malawi' },
  { name: 'Malaysia' },
  { name: 'Maldives' },
  { name: 'Mali' },
  { name: 'Malta' },
  { name: 'Marshall Islands' },
  { name: 'Martinique' },
  { name: 'Mauritania' },
  { name: 'Mauritius' },
  { name: 'Mayotte' },
  { name: 'Mexico' },
  { name: 'Micronesia' },
  { name: 'Moldova' },
  { name: 'Monaco' },
  { name: 'Mongolia' },
  { name: 'Montenegro' },
  { name: 'Montserrat' },
  { name: 'Morocco' },
  { name: 'Mozambique' },
  { name: 'Myanmar' },
  { name: 'Namibia' },
  { name: 'Nauru' },
  { name: 'Nepal' },
  { name: 'Netherlands' },
  { name: 'New Caledonia' },
  { name: 'New Zealand' },
  { name: 'Nicaragua' },
  { name: 'Niger' },
  { name: 'Nigeria' },
  { name: 'Niue' },
  { name: 'Norfolk Island' },
  { name: 'North Korea' },
  { name: 'Northern Mariana Islands' },
  { name: 'Norway' },
  { name: 'Oman' },
  { name: 'Pakistan' },
  { name: 'Palau' },
  { name: 'Palestine' },
  { name: 'Panama' },
  { name: 'Papua New Guinea' },
  { name: 'Paraguay' },
  { name: 'Peru' },
  { name: 'Philippines' },
  { name: 'Pitcairn' },
  { name: 'Poland' },
  { name: 'Portugal' },
  { name: 'Puerto Rico' },
  { name: 'Qatar' },
  { name: 'Réunion' },
  { name: 'Romania' },
  { name: 'Russia' },
  { name: 'Rwanda' },
  { name: 'Saint Barthélemy' },
  { name: 'Saint Helena, Ascension and Tristan da Cunha' },
  { name: 'Saint Kitts and Nevis' },
  { name: 'Saint Lucia' },
  { name: 'Saint Martin (French part)' },
  { name: 'Saint Pierre and Miquelon' },
  { name: 'Saint Vincent and the Grenadines' },
  { name: 'Samoa' },
  { name: 'San Marino' },
  { name: 'Sao Tomé and Príncipe' },
  { name: 'Saudi Arabia' },
  { name: 'Senegal' },
  { name: 'Serbia' },
  { name: 'Seychelles' },
  { name: 'Sierra Leone' },
  { name: 'Singapore' },
  { name: 'Sint Maarten (Dutch part)' },
  { name: 'Slovakia' },
  { name: 'Slovenia' },
  { name: 'Solomon Islands' },
  { name: 'Somalia' },
  { name: 'South Africa' },
  { name: 'South Georgia and the South Sandwich Islands' },
  { name: 'South Korea' },
  { name: 'South Sudan' },
  { name: 'Spain' },
  { name: 'Sri Lanka' },
  { name: 'Sudan' },
  { name: 'Suriname' },
  { name: 'Svalbard and Jan Mayen' },
  { name: 'Swaziland' },
  { name: 'Sweden' },
  { name: 'Switzerland' },
  { name: 'Syria' },
  { name: 'Taiwan' },
  { name: 'Tajikistan' },
  { name: 'Tanzania' },
  { name: 'Thailand' },
  { name: 'Timor-Leste' },
  { name: 'Togo' },
  { name: 'Tokelau' },
  { name: 'Tonga' },
  { name: 'Trinidad and Tobago' },
  { name: 'Tunisia' },
  { name: 'Turkey' },
  { name: 'Turkmenistan' },
  { name: 'Turks and Caicos Islands' },
  { name: 'Tuvalu' },
  { name: 'Uganda' },
  { name: 'Ukraine' },
  { name: 'United Arab Emirates' },
  { name: 'United Kingdom' },
  { name: 'United States' },
  { name: 'United States Minor Outlying Islands' },
  { name: 'Uruguay' },
  { name: 'Uzbekistan' },
  { name: 'Vanuatu' },
  { name: 'Vatican City' },
  { name: 'Venezuela' },
  { name: 'Vietnam' },
  { name: 'Virgin Islands (British)' },
  { name: 'Virgin Islands (US)' },
  { name: 'Wallis and Futuna' },
  { name: 'Western Sahara' },
  { name: 'Yemen' },
  { name: 'Zambia' },
  { name: 'Zimbabwe' }
];

//CONTACTS

export const ColumnKeys = {
  NAME: 'name',
  EMAIL: 'email',
  EMAIL_NOTIFICATION: 'emailNotification',
  MOBILE: 'mobile',
  MOBILE_NOTIFICATION: 'mobileNotification',
  TIMEZONE: 'timeZone',
  STATUS: 'status',
  ACTIONS: 'actions'
};

export const CONTACTS_TABLE_COLUMNS = [
  {
    title: 'NAME',
    label: 'Name',
    dataIndex: ColumnKeys.NAME,
    key: ColumnKeys.NAME
  },
  {
    title: 'EMAIL',
    label: 'Email',
    dataIndex: ColumnKeys.EMAIL,
    key: ColumnKeys.EMAIL
  },
  {
    title: 'EMAIL NOTIFICATION',
    label: 'Email Notification',
    dataIndex: ColumnKeys.EMAIL_NOTIFICATION,
    key: ColumnKeys.EMAIL_NOTIFICATION
  },
  {
    title: 'MOBILE',
    label: 'Mobile',
    dataIndex: ColumnKeys.MOBILE,
    key: ColumnKeys.MOBILE
  },
  {
    title: 'MOBILE NOTIFICATION',
    label: 'Mobile Notification',
    dataIndex: ColumnKeys.MOBILE_NOTIFICATION,
    key: ColumnKeys.MOBILE_NOTIFICATION
  },
  {
    title: 'TIMEZONE',
    label: 'Timezone',
    dataIndex: ColumnKeys.TIMEZONE,
    key: ColumnKeys.TIMEZONE
  },
  {
    title: 'STATUS',
    label: 'status',
    dataIndex: ColumnKeys.STATUS,
    key: ColumnKeys.STATUS
  },
  {
    title: 'ACTIONS',
    label: 'Actions',
    dataIndex: ColumnKeys.ACTIONS,
    key: ColumnKeys.ACTIONS
  }
];

export const PATHS = {
  NEW_LOCATION: '/settings/locations/newLocation',
  ADD_NEW_CONTACT: '/contact/new',
  VIEW_CONTACT: '/contacts/id',
  EDIT_CONTACT: '/contacts/edit/id',
  VIEW_CONTACT_PATH: '/settings/locations/id/:id/contacts/id/:contactid',
  VIEW_LOCATION: '/settings/locations/id',
  SELECT_GEOFENCE: '/settings/locations/geofences',
  EDIT_LOCATION: '/settings/locations/edit/id',
  AUDIT_LOCATION: '/settings/locations/audit/id',
  DEFAULT: '/settings/locations'
};

export const DATA_TYPE_FOR_AM = 'CONTACT';

export const VIEW_PAGE_LABEL_WIDTH = '295px';

export const INDEX_PATHNAME_FOR_BEGINING_LOCATION_ID = 23;

export const LABEL_ALIGN_FOR_INPUTS = 'left';

export const EDIT_LAYOUT_MODAL = 'modal';

export const DISABLED_STATUS_STRING = 'DISABLED';

export const STATUSES_IN_GRID = t => ({
  ACTIVE: t('Common.Active'),
  DELETED: t('Vehicles.Deleted')
});

export const DATA_TYPE_MESSAGES = 'Locations.Contacts';

export const MODAL_TYPE_DELETE = 'delete';

export const notificationValues = translate => [
  {
    id: 'runsheet.loaded',
    label: translate('Locations.Contacts.Form.NotificationOptions.RunsheetLoaded')
  },
  {
    id: 'job.selected',
    label: translate('Locations.Contacts.Form.NotificationOptions.JobSelected')
  }
];

export const notificationsObject = t => ({
  'runsheet.loaded': t('Locations.Contacts.Form.NotificationOptions.RunsheetLoaded'),
  'job.selected': t('Locations.Contacts.Form.NotificationOptions.JobSelected')
});

export const getFormItemsConfigs = (translate, regions, getTimezones) => {
  return [
    {
      name: 'name',
      label: translate('Locations.Contacts.Table.Columns.name'),
      rules: [
        {
          required: true,
          message: translate('Locations.Contacts.Form.NameRequired')
        }
      ],
      childProp: {
        type: 'input',
        placeholder: translate('WiFi.Form.TypeHere')
      }
    },
    {
      name: 'email',
      label: translate('Locations.Contacts.Table.Columns.email'),
      rules: [],
      childProp: {
        type: 'input',
        placeholder: translate('WiFi.Form.TypeHere')
      }
    },
    {
      name: 'emailNotification',
      label: translate('Locations.Contacts.Table.Columns.emailNotification'),
      rules: [],
      childProp: {
        type: 'select',
        placeholder: translate('Locations.Contacts.Form.NotificationPlaceholder'),
        mode: 'multiple',
        data: notificationValues(translate)
      }
    },
    {
      name: 'mobile',
      label: translate('Locations.Contacts.Table.Columns.mobile'),
      rules: [],
      childProp: {
        type: 'input',
        placeholder: translate('WiFi.Form.TypeHere')
      }
    },
    {
      name: 'mobileNotification',
      label: translate('Locations.Contacts.Table.Columns.mobileNotification'),
      rules: [],
      childProp: {
        type: 'select',
        mode: 'multiple',
        placeholder: translate('Locations.Contacts.Form.NotificationPlaceholder'),
        data: notificationValues(translate)
      }
    },
    {
      name: 'timeZone',
      label: translate('Locations.Contacts.Table.Columns.timeZone'),
      rules: [],
      childProp: {
        type: 'select',
        data: getTimezones(regions)
      }
    }
  ];
};

export const XLSX_FILE_TITLE = 'Locations';

export const NO_OF_COLUMNS = 12;
export const columnWidth = Array.apply(null, { length: NO_OF_COLUMNS }).map(_ => ({ wch: 25 }));
export const TYPES_TO_EXCLUDE_GEOFENCE_IN_VIEW = translate => [
  translate('Smartjobs.Job'),
  translate('JourneyPlanner.Journey')
];

export const EMPTY_ARRAY = [];
