import BatteryIcon0 from './battery_icon_0.svg';
import BatteryIcon1 from './battery_icon_1.svg';
import BatteryIcon2 from './battery_icon_2.svg';
import BatteryIcon3 from './battery_icon_3.svg';
import BatteryIcon4 from './battery_icon_4.svg';
import BatteryIcon5 from './battery_icon_5.svg';

const BatteryIcons = {
  BatteryIcon0,
  BatteryIcon1,
  BatteryIcon2,
  BatteryIcon3,
  BatteryIcon4,
  BatteryIcon5
};

export default BatteryIcons;
