/* global google */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import request from 'superagent';
import { nanoid } from 'nanoid';
import ResizeObserver from 'rc-resize-observer';
import { uniqBy } from 'lodash';

import { useTrackingData } from 'features/tracking/trackingSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useDeviceUpdatesAll } from 'features/mqtt/mqttSlice';
import {
  useUserPreferences,
  setUserPreferences,
  updatePreferences
} from 'features/user/userPreferencesSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useUserGridSettings, updateUserGridSettings } from 'features/user/userGridSettingsSlice';
import {
  GRID_SETTING_KEY,
  DEFAULT_VEHICLE_VIEW_CONFIG,
  getGroupByTrips,
  updateGroupByTrips,
  getFilteredEventTypeKeys
} from 'features/tracking/trackingGridSettings';
import { useLocalization } from 'features/localization/localizationSlice';
import {
  useCompanyGeofenceProviders,
  useCurrentCompany,
  useSubCompanyEntityConfig,
  CompanyConfigKey,
  CompanyConfigValue
} from 'features/company/companySlice';
import { useUserKey, useUser } from 'features/user/userSlice';
import {
  fetchDeviceStats,
  applyUpdatesOnDevices,
  resetUpdates
} from 'features/devices/devicesStatsSlice';
import { fetchVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import { filterGeofencesByShowTypes } from 'features/geofences/geofencesUtil';
import { useDeviceCameraEvents } from 'features/camera/cameraSlice';
import { useCanFeatureFlag, FeatureFlag, useCan } from 'features/permissions';

import { TrackGrid } from '../Tables/TrackGrid';
import { ShareJobModal } from '../../SmartJobs/Jobs/ShareJob';
import Map, { MapMode } from 'components/map/Map';
import { TrackingPage } from '../TrackingPage';
import { VelocityAltitudeGraph } from './Graph/VelocityAltitudeGraph';
import { API_PATH } from 'config';
import { useSupportedEventTypes } from '../EventTypes';
import { getExtraEventFields } from './Modals/FilterEventsModal/FilterEvents';
import { HDDataToolbar } from './HDDataToolbar';
import { onlyUnique } from 'utils/filters';
import { TrackingLens, TrackingPaths } from '../constants';
import HideNonBusinessStatus from 'containers/Tracking/Common/HideNonBusinessStatus';

import styles from '../Tracking.module.scss';
import 'react-reflex/styles.css';

import { showGeofenceType } from 'containers/Settings/constants';
import {
  IgnitionOnOffEventKeys,
  filterNonBusinessTripDevices,
  filterAnomolousTrips,
  checkAndConvertToPartialTrip,
  buildHDDataUrl
} from 'containers/Tracking/helpers';
import { useUsers } from 'features/users/usersSlice';

const vaChartContainerClassName = 'velocity-altitude-chart-container';
const gridSplitterKey = 'trackingGridSplitterPos';
const chartSplitterKey = 'trackingChartSplitterPos';

export const NoTripDeviceTypes = ['COOLTRAX', 'THERMOKING'];

export const Track = ({ tab, hddata = false }) => {
  const trackingData = useTrackingData();
  const dispatch = useDispatch();
  const history = useHistory();
  const deviceUpdates = useDeviceUpdatesAll();
  const mapRef = useRef(null);
  const userPreferences = useUserPreferences();
  const { t } = useTranslation();
  const { deviceId, dateFrom, dateTo } = useParams();
  const localization = useLocalization();
  const currentCompany = useCurrentCompany();
  const user = useUser();
  const userKey = useUserKey();
  const users = useUsers();
  const userGridSettings = useUserGridSettings(GRID_SETTING_KEY);
  const geofenceProviders = useCompanyGeofenceProviders();
  const hideNonBusinessTrips = useSubCompanyEntityConfig(
    currentCompany?.id,
    CompanyConfigKey.HideNonBusiness
  );
  const can = useCan();
  const canTrackingByVehicleId = true;
  const { getEventApiKeys, allEventTypeKeys } = useSupportedEventTypes();

  const [dateRequest, setDateRequest] = useState(null);
  const [companyHasChanged, setCompanyHasChanged] = useState(false);
  const [focusedDeviceId, setFocusedDeviceId] = useState(null);
  const [clickedDeviceId, setClickedDeviceId] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [vehicleForSharing, setVehicleForSharing] = useState(null);
  const selectedDeviceRef = useRef(null); // for accessing latest selectedDevice in callbacks
  const [filteredDevices, setFilteredDevices] = useState(null);
  const [deviceGpsUpdates, setDeviceGpsUpdates] = useState([]);
  const [selectedDeviceTrips, setSelectedDeviceTrips] = useState(null);
  const [selectedTripSegment, setSelectedTripSegment] = useState(null);
  const [selectedTripEvent, setSelectedTripEvent] = useState(null);
  const [focusedEvent, setFocusedEvent] = useState(null);
  const [selectedReplay, setSelectedReplay] = useState(null);
  const [showTripInfoWindow, setShowTripInfoWindow] = useState(true);
  const [tripInfoWindowData, setTripInfoWindowData] = useState(null);
  const [mapMode, setMapMode] = useState(!hddata ? MapMode.Devices : MapMode.Events);
  const [mapCenter, setMapCenter] = useState();
  const [mapZoom, setMapZoom] = useState();
  const [dateRange, setDateRange] = useState(
    !hddata
      ? {
          from: moment()
            .startOf('day')
            .format(),
          to: moment()
            .endOf('day')
            .format()
        }
      : {
          from: moment(decodeURIComponent(dateFrom)).format(),
          to: moment(decodeURIComponent(dateTo)).format()
        }
  );
  const datePickerRef = useRef();
  const [datePickerOpen, setDatePickerOpen] = useState();
  const [velocityAltitudeGraphSize, setVelocityAltitudeGraphSize] = useState({
    width: 1250,
    height: 250
  });
  const [selectedInterval, setSelectedInterval] = useState(600);
  const [isTripsLoading, setIsTripsLoading] = useState(false);
  const [regionMaxSpeed, setRegionMaxSpeed] = useState(140);
  const [groupByTripsEnabled, setGroupByTripsEnabled] = useState(true);
  const [hdDataEventTypeKeys, setHDDataEventTypeKeys] = useState(IgnitionOnOffEventKeys);
  const [isShareLiveLocationModalOpen, setIsShareLiveLocationModalOpen] = useState(false);

  const path = window.location.pathname;

  let nextRefreshTimeout;
  let nextUpdateTimeout;

  const refreshInterval = useCallback(() => {
    if (hddata) return;

    if (nextRefreshTimeout) {
      clearTimeout(nextRefreshTimeout);
    }
    nextRefreshTimeout = setTimeout(() => {
      dispatch(fetchDeviceStats(true));
      dispatch(fetchVehiclesStats());
      refreshInterval();
    }, selectedInterval * 1000);
  }, [selectedInterval, dispatch]);

  const handleSetSelectedReplay = newSelectedReplay => {
    setSelectedReplay(newSelectedReplay);

    // unselect any selected event since only one can be selected at a time (event and replay point)
    if (selectedTripEvent) setSelectedTripEvent(null);
  };

  const handleGridSplitterResized = ({ component }) => {
    updateUserPreferences(gridSplitterKey, component.props.flex, user, userKey);
  };

  const handleChartSplitterResized = ({ component }) => {
    updateUserPreferences(chartSplitterKey, component.props.flex, user, userKey);
  };

  const updateUserPreferences = (key, value, user, userKey) => {
    const newPreferences = {
      ...userPreferences,
      trackingTimeType: userPreferences.trackingTimeType,
      [key]: value
    };
    setUserPreferences(newPreferences, user.id, userKey)
      .then(() => {
        dispatch(updatePreferences(newPreferences));
      })
      .catch(e => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: `${t('Preferences.Save.ErrorMessage')}.`
          })
        );
      });
  };

  const getDeviceTrips = useCallback(
    (device, eventTypes) => {
      const query = {
        from: dateRange.from,
        to: dateRange.to,
        method: canTrackingByVehicleId ? 'NEW' : 'OLD',
        embed: 'events,eventsOutsideTrips,meters',
        event_types: eventTypes.join(','),
        ...(device.vehicle?.id ? { vehicleId: device.vehicle.id } : { deviceId: device.id })
      };

      const tripsRequest = request('GET', API_PATH + '/trips')
        .set('Authorization', `Token token="${userKey}"`)
        .query(query);

      setDateRequest(tripsRequest);
      if (dateRequest) {
        dateRequest.abort();
      }

      return tripsRequest.then(response => {
        const filteredTrips = (response.body || []).filter(
          trip => !trip.vehicle?.id || !device.vehicle?.id || trip.vehicle.id === device.vehicle.id
        );
        return filterAnomolousTrips(filteredTrips, true);
      });
    },
    [dateRange, userKey, groupByTripsEnabled, hddata]
  );

  const getDevicePositions = useCallback(
    ({ device, from, to, trip }) => {
      const { queryFrom, queryTo } = formatDateRange({ trip, from, to, dateRange });
      const vehicleId = trip?.vehicle ? trip?.vehicle.id : device?.vehicle?.id;
      const entity =
        canTrackingByVehicleId && vehicleId ? `vehicles/${vehicleId}` : `devices/${device.id}`;
      const apiUrl = `${API_PATH}/${entity}/positions`;
      const query = {
        from: queryFrom,
        to: queryTo,
        limit: 'off',
        orderBy: 'time_at',
        highDefinition: true,
        lastHdgpsLocation: true,
        embed: 'driver'
      };

      return request('GET', apiUrl)
        .set('Authorization', `Token token="${userKey}"`)
        .query(query)
        .then(response => {
          console.debug('getDevicePositions', {
            device,
            vehicleId: device?.vehicle?.id,
            trip,
            query: query,
            body: response.body
          });
          const points = (response.body.GPS || response.body)
            .filter(point => point.Lat && point.Lng)
            .sort((a, b) => Number(a?.At) - Number(b?.At));
          console.debug('getDevicePositions', { points });
          return points;
        });
    },
    [dateRange, userKey]
  );

  const openShareLiveLocationModal = vehicle => {
    setVehicleForSharing(vehicle);
    setIsShareLiveLocationModalOpen(true);
  };

  const closeShareLiveLocationModal = () => {
    setIsShareLiveLocationModalOpen(false);
  };

  useEffect(() => {
    dispatch(setPageTitle(hddata ? t('Tracking.HDData') : t('Tracking.PageTitle')));

    // Only enable back button for HD Data page and when not opening in new tab
    const enableBackButton = hddata && history && history.length > 1;
    dispatch(setBackButton(enableBackButton));
  }, [dispatch, hddata]);

  useEffect(() => {
    let newSelectedDevice = trackingData.devices.find(device => device.id === parseInt(deviceId));

    // TN360WEB-6531: Unfortunately since trips are stored on selectedDevice and used in several components downstream and devices
    // are updated periodically on the tracking page, we need to preserve the trips we already loaded if it is the same device id
    if (newSelectedDevice && newSelectedDevice?.id === selectedDevice?.id) {
      newSelectedDevice = {
        ...newSelectedDevice,
        trips: selectedDevice?.trips
      };
    }

    setSelectedDevice(newSelectedDevice);
  }, [deviceId, trackingData.devices]);

  useEffect(() => {
    // Update ref so that latest selected device can be accessed in callbacks
    selectedDeviceRef.current = selectedDevice;
  }, [selectedDevice]);

  useEffect(() => {
    if (selectedTripSegment) {
      setMapZoom(null);
      setMapCenter(null);

      // If selected trip changed, clear selected replay point
      setSelectedReplay(null);
    }
  }, [selectedTripSegment]);

  useEffect(() => {
    if (selectedDevice?.trips && (!groupByTripsEnabled || hddata)) {
      setMapZoom(null);
      setMapCenter(null);
    }
  }, [selectedDevice?.trips]);

  useEffect(() => {
    setCompanyHasChanged(true);

    // reset the map
    setMapZoom(null);
    setMapCenter(null);
  }, [currentCompany]);

  useEffect(() => {
    if (companyHasChanged && !trackingData.isLoading) {
      setCompanyHasChanged(false);
      setMapZoom(null);
      setMapCenter(null);
    }
    if (!trackingData?.devices?.length) {
      setMapZoom(null);
      setMapCenter(null);
    }
  }, [trackingData]);

  useEffect(() => {
    if (deviceGpsUpdates.length > 0 && mapMode === MapMode.Drone) {
      setMapCenter({
        lat: deviceGpsUpdates[deviceGpsUpdates.length - 1].lat,
        lng: deviceGpsUpdates[deviceGpsUpdates.length - 1].lng
      });
    }
  }, [deviceGpsUpdates, mapMode]);

  useEffect(() => {
    if (
      selectedTripEvent &&
      selectedTripEvent.GPS?.Lat &&
      selectedTripEvent.GPS?.Lng &&
      mapMode !== MapMode.Drone
    ) {
      setMapCenter({
        lat: selectedTripEvent.GPS?.Lat,
        lng: selectedTripEvent.GPS?.Lng
      });
    } else {
      if (mapMode === MapMode.Drone) {
        // When changing into Drone view, we need to set the map center to null
        // so that the fitBounds method from the Map component determines the new bounds
        // and the new zoom level
        setMapCenter(null);
      }
    }
  }, [selectedTripEvent, mapMode]);

  useEffect(() => {
    if (userPreferences?.refresh?.tracking !== -1) {
      dispatch(resetUpdates());
    }

    setSelectedInterval(userPreferences?.refresh?.tracking || 600);
  }, [userPreferences, dispatch]);

  useEffect(() => {
    if (path !== TrackingPaths.Proximity && !hddata) {
      if (selectedInterval !== -1) {
        if (nextUpdateTimeout) {
          clearInterval(nextUpdateTimeout);
          nextUpdateTimeout = null;
        }
        refreshInterval();
      } else {
        if (nextRefreshTimeout) {
          clearTimeout(nextRefreshTimeout);
          nextRefreshTimeout = null;
        }
        if (!nextUpdateTimeout) {
          nextUpdateTimeout = setInterval(() => {
            dispatch(applyUpdatesOnDevices());
          }, 15 * 1000);
        }
      }
    } else {
      if (nextRefreshTimeout) {
        clearTimeout(nextRefreshTimeout);
        nextRefreshTimeout = null;
      }
      if (nextUpdateTimeout) {
        clearInterval(nextUpdateTimeout);
        nextUpdateTimeout = null;
      }
    }
    return () => {
      if (nextRefreshTimeout) {
        clearTimeout(nextRefreshTimeout);
        nextRefreshTimeout = null;
      }
      if (nextUpdateTimeout) {
        clearInterval(nextUpdateTimeout);
        nextUpdateTimeout = null;
      }
    };
  }, [refreshInterval, path]);

  useEffect(() => {
    if (userGridSettings && userGridSettings.lastFetched) {
      const groupByTripsEnabled = getGroupByTrips(userGridSettings);
      setGroupByTripsEnabled(groupByTripsEnabled);
    }
  }, [userGridSettings]);

  const onGroupByTripsChange = checked => {
    // Hack to try and make group by trips toggle smoother, let it animate in UI then trigger change
    setTimeout(() => {
      setIsTripsLoading(true);
      setGroupByTripsEnabled(checked);

      if (userGridSettings && userGridSettings.lastFetched) {
        const newUserGridSettings = updateGroupByTrips(userGridSettings, checked);
        dispatch(updateUserGridSettings(newUserGridSettings, GRID_SETTING_KEY, () => {}, false));
      }

      if (checked) {
        setMapMode(MapMode.Trips);
      } else {
        setMapMode(MapMode.Events);

        // Set date range to first day of current range
        const newDateRange = {
          from: moment(dateRange.from),
          to: moment(dateRange.from).endOf('day')
        };

        setDateRange({ from: newDateRange.from.format(), to: newDateRange.to.format() });
        if (datePickerRef?.current?.resetDates) {
          datePickerRef.current.resetDates([newDateRange.from, newDateRange.to]);
        }
      }

      // When toggling group by trips mode, remove selected trip from map
      // and clear selected device trip data so it doesn't show while loading during API calls
      const newSelectedDevice = { ...selectedDevice };
      newSelectedDevice.trips = [];
      newSelectedDevice.replay = null;
      setSelectedDevice(newSelectedDevice);
      setSelectedDeviceTrips([]);
      setSelectedTripSegment(null);
      setSelectedTripEvent(null);
    }, 200);
  };

  const onEventFocused = event => {
    setFocusedEvent(event);
  };

  const onEventBlurred = () => {
    setFocusedEvent(null);
  };

  const onDeviceFocused = deviceId => {
    setFocusedDeviceId(deviceId);
  };

  const onDeviceBlurred = () => {
    setFocusedDeviceId(null);
  };

  const onDeviceClicked = deviceId => {
    console.debug('Track - onDeviceClicked', deviceId);

    setFocusedDeviceId(deviceId);
    setClickedDeviceId(deviceId);
    if (deviceId) {
      setMapCenter(null);
      setMapZoom(null);
    }
    history.replace(TrackingPaths.Track);
  };

  const onDeviceSelected = device => {
    console.debug('Track - onDeviceSelected', device?.id);

    if (!device) {
      setSelectedTripSegment(null);
      setSelectedTripEvent(null);
      setSelectedDevice(null);
      setMapMode(MapMode.Devices);
      history.replace(TrackingPaths.Track);
      return;
    }

    // Don't update unless selectedDevice changed to a new one
    if (device.id !== selectedDevice?.id) {
      setSelectedTripSegment(null);
      setSelectedTripEvent(null);
      setSelectedDevice(device);
      setFocusedDeviceId(device.id);
      setClickedDeviceId(device.id);
      setMapMode(groupByTripsEnabled && !hddata ? MapMode.Trips : MapMode.Events);

      history.replace(TrackingPaths.TrackSummary.replace(':deviceId', device.id));
    }
  };

  const onDeviceGpsUpdated = gpsUpdates => {
    setDeviceGpsUpdates(gpsUpdates);
  };

  const loadNoTripDeviceData = fetchingDeviceId => {
    // replay - add all trip data
    return getDevicePositions({
      device: selectedDevice,
      from: dateRange.from,
      to: dateRange.to
    })
      .then(async replay => {
        if (fetchingDeviceId === selectedDevice.id) {
          const { interactiveDevices, ...selectedDeviceNonCircular } = selectedDevice;
          const newSelectedDevice = JSON.parse(JSON.stringify(selectedDeviceNonCircular));
          newSelectedDevice.replay = replay;

          // create fake trip to hold the events data
          const trip = {
            id: 1,
            replay,
            events: []
          };
          addPositionUpdatesToTrip(trip);
          newSelectedDevice.trips = [trip];

          setSelectedDevice(newSelectedDevice);
          setSelectedDeviceTrips(newSelectedDevice.trips);

          setIsTripsLoading(false);
        }
      })
      .catch(() => {
        if (fetchingDeviceId === selectedDevice.id) {
          setIsTripsLoading(false);
        }
      });
  };

  const addPositionUpdatesToTrip = trip => {
    if (trip && trip.replay) {
      const gpsWithLocation = (trip?.replay || []).filter(gps => gps.hasOwnProperty('location'));

      // get update interval from user preferences (ex: 5, 10, 15 min)
      const updateInterval = userPreferences?.trackingPositionUpdateInterval || 300000;

      // Reduce number of events to show based on time gap (ex: 5, 10, 15 min)
      let reducedList = [];
      for (let i = 0; i < gpsWithLocation.length; i++) {
        if (i === 0) {
          reducedList.push(gpsWithLocation[i]);
        } else {
          // Only add points that are past the required update interval
          const timeDiff = gpsWithLocation[i].At - reducedList[reducedList.length - 1].At;
          if (timeDiff >= updateInterval) {
            reducedList.push(gpsWithLocation[i]);
          }
        }
      }
      const usersMap = users.reduce((map, item) => {
        map[item.id] = {
          firstName: item.firstName,
          lastName: item.lastName,
          name: item.name,
          id: item.id
        };
        return map;
      }, {});

      // Create position events
      const positionUpdateEvents = reducedList.map(gps => {
        return {
          GPS: gps,
          attributes: null,
          createdAt: gps.At,
          device: trip.device,
          eventType: 'POSITION',
          id: nanoid(),
          location: gps.location,
          origin: gps.origin,
          path: null,
          subType: 'UPDATE',
          timeAt: gps.At,
          user: usersMap[gps.Extra?.userId],
          vehicle: trip.vehicle
        };
      });

      // If trip contains ALARMPR2 events, then don't add position events manually because they are already there
      const containsAlarmPositionEvents =
        trip.events?.find(e => e.eventType === 'ALARM' && e.subType === 'PR2') !== undefined;

      // Add position events to the trips and sort so they are in the right order
      if (positionUpdateEvents && positionUpdateEvents.length > 0 && !containsAlarmPositionEvents) {
        trip.events = [...(trip.events || []), ...positionUpdateEvents];
        trip.events.sort((a, b) => Number(a?.timeAt) - Number(b?.timeAt));
      }
    }
  };

  // find first trip with a valid ignition value (ie skip the out of trip event trips)
  const getFirstTrip = trips => {
    let firstTrip = null;
    if (trips && trips.length) {
      firstTrip = trips[0];
      if (!(firstTrip.ignitionOn || firstTrip.ignitionOff) && trips.length > 1) {
        firstTrip = trips[1];
      }
    }
    return firstTrip;
  };

  // find last trip with a valid ignition value (id skip out of trip event trips)
  const getLastTrip = trips => {
    let lastTrip = null;
    if (trips && trips.length) {
      lastTrip = trips[trips.length - 1];
      if (!(lastTrip.ignitionOn || lastTrip.ignitionOff) && trips.length > 1) {
        lastTrip = trips[trips.length - 2];
      }
    }
    return lastTrip;
  };

  useEffect(() => {
    if (!selectedDevice) return;
    if (datePickerOpen) return;

    const eventApiKeys = getEventApiKeys(TrackingLens().Trips)
      .concat(getEventApiKeys(TrackingLens().Safety))
      .concat(getEventApiKeys(TrackingLens().Track))
      .filter(onlyUnique);

    let fetchingDeviceId = selectedDevice?.id;
    setIsTripsLoading(true);

    // Special case for devices with no trips, skip trip loading and just load position data
    const isNoTripDevice =
      selectedDevice?.type?.code && NoTripDeviceTypes.includes(selectedDevice?.type?.code);
    if (isNoTripDevice) {
      loadNoTripDeviceData(fetchingDeviceId);
      return () => {
        fetchingDeviceId = null;
      };
    }

    getDeviceTrips(selectedDevice, eventApiKeys, fetchingDeviceId)
      .then(trips => {
        if (fetchingDeviceId === selectedDevice.id) {
          if (!trips?.length) {
            selectedDevice.trips = [];
            selectedDevice.replay = null;
            selectedDevice.trips.trackEventCount = 0;
            setSelectedDevice(selectedDevice);
            setSelectedDeviceTrips([]);
            setSelectedTripSegment(null);
            setMapMode(groupByTripsEnabled && !hddata ? MapMode.Trips : MapMode.Events);
            return;
          }

          setSelectedTripSegment(null);
          let trackEventCount = 0;
          const selectedTripSegmentId = 0;

          trips.forEach((trip, index) => {
            trip.index = index;

            if (trip.events) {
              // Remove events with duplicate ids (sometimes returned by trips API)
              trip.events = uniqBy(trip.events, 'id');
              trackEventCount += trip.events.length;
              trip.events = getExtraEventFields(trip.events, trip);
            }
          });

          selectedDevice.trips = trips;
          selectedDevice.trips.trackEventCount = trackEventCount;

          // replay - add last trip data
          const firstTrip = getFirstTrip(selectedDevice.trips);
          const lastTrip = getLastTrip(selectedDevice.trips);

          const currentTrip = lastTrip;
          const epochDateRange = {
            to: new Date(dateRange.to).getTime(),
            from: new Date(dateRange.from).getTime()
          };

          return getDevicePositions({
            device: selectedDevice,
            trip: currentTrip
          }).then(deviceTripReplay => {
            if (fetchingDeviceId === selectedDevice.id) {
              currentTrip.replay = deviceTripReplay;
              addPositionUpdatesToTrip(currentTrip);

              if (groupByTripsEnabled && !hddata && !selectedTripSegmentId) {
                checkAndConvertToPartialTrip(currentTrip, epochDateRange);
                setSelectedTripSegment(JSON.parse(JSON.stringify(currentTrip)));
                // console.debug('Track - selectedTripSegment', currentTrip);
              }

              // replay - add all trip data
              return getDevicePositions({
                device: selectedDevice,
                from: firstTrip.ignitionOn,
                to: lastTrip.ignitionOff
              }).then(async replay => {
                if (fetchingDeviceId === selectedDevice.id) {
                  const { interactiveDevices, ...selectedDeviceNonCircular } = selectedDevice;
                  const newSelectedDevice = JSON.parse(JSON.stringify(selectedDeviceNonCircular));
                  newSelectedDevice.replay = replay;
                  let i = 0;
                  let selectedTripIndex = null;

                  const isValidTripReplay = replay => {
                    const validStartTime = Number(firstTrip.ignitionOn ? firstTrip.ignitionOn : 0),
                      validEndTime = Number(
                        lastTrip.ignitionOff ? lastTrip.ignitionOff : moment().valueOf()
                      );
                    return (
                      Number(replay?.At) >= validStartTime && Number(replay?.At) <= validEndTime
                    );
                  };
                  const processData = async () => {
                    newSelectedDevice.trips.forEach((trip, index) => {
                      let tripGPSIndex = 0;
                      for (
                        trip.replay = (trip.replay || []).filter(isValidTripReplay);
                        i < replay.length &&
                        (trip.id || trip.IgnOnGPS) &&
                        replay[i].At <= (trip.ignitionOff ? trip.ignitionOff : moment().valueOf());
                        i++
                      ) {
                        const currentGPS = replay[i];
                        if (
                          currentGPS.At >= trip.ignitionOn &&
                          currentGPS.At <=
                            (trip.ignitionOff ? trip.ignitionOff : moment().valueOf())
                        ) {
                          for (; tripGPSIndex < trip.replay.length; tripGPSIndex++) {
                            const tripGPS = trip.replay[tripGPSIndex];
                            if (Number(tripGPS?.At) > Number(currentGPS.At)) {
                              trip.replay.push(currentGPS);
                              break;
                            } else if (Number(tripGPS?.At) === Number(currentGPS.At)) {
                              tripGPSIndex++;
                              break;
                            }
                          }

                          if (tripGPSIndex === trip.replay.length) {
                            trip.replay.push(currentGPS);
                            tripGPSIndex++;
                          }
                        }
                      }
                      if (trip.id === selectedTripSegmentId) {
                        selectedTripIndex = index;
                      }
                      trip.replay.sort((a, b) => Number(a?.At) - Number(b?.At));

                      // Don't add position update events to selected trip twice
                      if (trip.id !== currentTrip?.id) {
                        addPositionUpdatesToTrip(trip);
                      }

                      // At the end, filter trip date by selected date range to get any "partial" trips
                      checkAndConvertToPartialTrip(trip, epochDateRange);
                    });
                  };

                  await processData();

                  setSelectedDevice(newSelectedDevice);
                  setSelectedDeviceTrips(trips);

                  if (groupByTripsEnabled && !hddata && selectedTripIndex) {
                    setSelectedTripSegment(newSelectedDevice.trips[selectedTripIndex]);
                    // console.debug('Track', newSelectedDevice.trips[selectedTripIndex]);
                  }
                }
              });
            }
          });
        }
      })
      .then(() => {
        if (fetchingDeviceId === selectedDevice.id) {
          setIsTripsLoading(false);
        }
      })
      .catch(() => {
        if (fetchingDeviceId === selectedDevice.id) {
          setIsTripsLoading(false);
        }
      });

    return () => {
      fetchingDeviceId = null;
    };
  }, [userKey, selectedDevice?.id, dateRange, datePickerOpen, groupByTripsEnabled, hddata]);

  const velocityData = useMemo(() => {
    let startDate = null;
    let endDate = null;
    let vTrips = [];

    const eventTypeKeys = !hddata
      ? getFilteredEventTypeKeys(userGridSettings, allEventTypeKeys)
      : hdDataEventTypeKeys;

    const filterMaxSpeed = replay => {
      let filteredReplay = [];
      if (replay) {
        filteredReplay = replay.filter(gps => {
          if (gps.Spd > regionMaxSpeed) {
            console.debug('filtered speed spike: ' + localization.formatSpeed(gps.Spd));
          }

          const npiEnabled = can({ featureFlag: FeatureFlag.npi.flag });
          return gps.At && gps.Spd !== undefined && (gps.Spd < regionMaxSpeed || npiEnabled);
        });
      }
      return filteredReplay;
    };

    if (groupByTripsEnabled && !hddata && selectedTripSegment) {
      // for hd data graph in group by trips mode, just use the selected trip for velocity graph
      const vTrip = {
        id: selectedTripSegment.id,
        replay: filterMaxSpeed(selectedTripSegment?.replay),
        events: (selectedTripSegment?.events || []).filter(event =>
          eventTypeKeys.includes(event.eventType + event.subType)
        )
      };
      vTrips.push(vTrip);

      // Calculate start and end date from max/min of selected trip
      if (vTrip.replay && vTrip.replay.length) {
        startDate = !startDate ? vTrip.replay[0].At : Math.min(startDate, vTrip.replay[0].At);

        endDate = !endDate
          ? vTrip.replay[vTrip.replay.length - 1].At
          : Math.max(endDate, vTrip.replay[vTrip.replay.length - 1].At);
      }

      // Take events into account for start and end date too
      if (vTrip.events && vTrip.events.length) {
        startDate = !startDate
          ? vTrip.events[0].timeAt
          : Math.min(startDate, vTrip.events[0].timeAt);

        endDate = !endDate
          ? vTrip.events[vTrip.events.length - 1].timeAt
          : Math.max(endDate, vTrip.events[vTrip.events.length - 1].timeAt);
      }
    } else {
      if (selectedDevice?.trips && selectedDevice?.trips.length) {
        // For new hddata graph, pass separate trips to graph instead of replay events
        selectedDevice?.trips.forEach(trip => {
          let vTrip = {
            ...trip,
            replay: filterMaxSpeed(trip.replay),
            events: (trip?.events || []).filter(event =>
              eventTypeKeys.includes(event.eventType + event.subType)
            )
          };

          // Calculate start and end date from max/min of all trips
          if (vTrip.replay && vTrip.replay.length) {
            startDate = !startDate ? vTrip.replay[0].At : Math.min(startDate, vTrip.replay[0].At);

            endDate = !endDate
              ? vTrip.replay[vTrip.replay.length - 1].At
              : Math.max(endDate, vTrip.replay[vTrip.replay.length - 1].At);
          }

          // Take events into account for start and end date too
          if (vTrip.events && vTrip.events.length) {
            startDate = !startDate
              ? vTrip.events[0].timeAt
              : Math.min(startDate, vTrip.events[0].timeAt);

            endDate = !endDate
              ? vTrip.events[vTrip.events.length - 1].timeAt
              : Math.max(endDate, vTrip.events[vTrip.events.length - 1].timeAt);
          }

          vTrips.push(vTrip);
        });
      }
    }

    return { startDate, endDate, trips: vTrips };
  }, [
    selectedTripSegment,
    groupByTripsEnabled,
    hddata,
    hdDataEventTypeKeys,
    userGridSettings,
    selectedDevice?.trips
  ]);

  const getAberrationFreeDevices = devices =>
    (devices || []).reduce((validDevices, currentDevice) => {
      const currentDeviceTime = deviceUpdates[currentDevice.id]?.lastEventAt
        ? new Date(deviceUpdates[currentDevice.id].lastEventAt).getTime()
        : new Date(currentDevice.deviceStats.lastEventAt).getTime();
      const presentTime = new Date().getTime();
      // if lastEventAt is more than 5 minutes into the future, hide it
      if (currentDeviceTime > presentTime + 5 * 60 * 1000) {
        return validDevices;
      }
      if (currentDevice > presentTime) {
        currentDevice.deviceStats.lastEventAt = new Date().toISOString();
      }

      validDevices.push(currentDevice);
      return validDevices;
    }, []);

  const getFilteredDevices = () => {
    if (selectedDevice && mapMode !== MapMode.Devices) {
      return [selectedDevice];
    }

    const devices = filteredDevices ? filteredDevices : trackingData.devices;
    return getAberrationFreeDevices(devices); // hide lastEventAt aberrations
  };

  const updateHDDataDevice = selectedDeviceId => {
    let newSelectedDevice = null;
    if (selectedDeviceId !== selectedDevice?.id) {
      newSelectedDevice = trackingData.devices.find(device => device.id === selectedDeviceId);
      history.replace(buildHDDataUrl(selectedDeviceId, dateRange.from, dateRange.to));

      // If device changed, clear trip data to refresh it
      newSelectedDevice.trips = [];
      newSelectedDevice.replay = null;
      setSelectedDevice(newSelectedDevice);
      setSelectedDeviceTrips([]);
      setSelectedTripSegment(null);
      setSelectedTripEvent(null);
    }
  };

  const updateHDDataDateRange = selectedDateRange => {
    const dateRangeChanged =
      !moment(dateRange.from).isSame(selectedDateRange.from) ||
      !moment(dateRange.to).isSame(selectedDateRange.to);
    if (dateRangeChanged) {
      setDateRange({
        from: moment(selectedDateRange.from).format(),
        to: moment(selectedDateRange.to).format()
      });
      history.replace(
        buildHDDataUrl(selectedDeviceRef.current.id, selectedDateRange.from, selectedDateRange.to)
      );
    }
  };

  const onDateRangeSelected = (startDate, endDate, externalUpdate) => {
    setDateRange({
      from: startDate.startOf('day').toISOString(),
      to: endDate.endOf('day').toISOString()
    });
    setSelectedTripSegment(null);
    setSelectedTripEvent(null);

    // If updated externally and not from the UI contorl, we need to refresh the control too
    if (externalUpdate) {
      if (datePickerRef?.current?.resetDates) {
        datePickerRef.current.resetDates([startDate, endDate]);
      }
      setIsTripsLoading(true);
    }
  };

  const onDateRangeClose = isOpen => {
    setDatePickerOpen(isOpen);
  };

  const handleMapViewportChanged = mapRef => () => {
    if (!companyHasChanged) {
      const { map } = mapRef.current.state;
      const center = map.getBounds() && map.getBounds().getCenter();
      const { zoom } = map;
      setMapCenter(center);
      setMapZoom(zoom);
    }
  };

  const onMapBoundsChanged = bounds => {
    if (bounds.getSouthWest().lat() !== 1 && bounds.getNorthEast().lat() !== -1) {
      if (mapRef?.current?.state?.map) {
        mapRef.current.state.map.fitBounds(bounds);
      }
    }
  };

  const showTripInfoWindowData = data => {
    setTripInfoWindowData(data);
  };

  const showVelocityAltitudeChart = () => {
    // console.debug('showVelocityAltitudeChart', mapMode, velocityData.replay);
    return (
      [MapMode.Trips, MapMode.Trip, MapMode.Events].includes(mapMode) &&
      velocityData?.trips?.length > 0 &&
      !(
        hideNonBusinessTrips &&
        (selectedDevice?.deviceStats?.attr === CompanyConfigValue.Private ||
          selectedTripSegment?.attr === CompanyConfigValue.Private)
      )
    );
  };

  const onEventClicked = event => {
    setSelectedTripEvent(event);

    // unselect any selected replay point since only one can be selected at a time (event and replay point)
    if (selectedReplay) setSelectedReplay(null);
  };

  const handleEventInfoWindowClosed = event => {
    if (selectedTripEvent) setSelectedTripEvent(null);
  };

  const onTripSegmentSelected = trip => {
    if (!trip?.id && !trip?.IgnOnGPS) {
      return;
    }

    // only update if it changed
    if (trip?.id !== selectedTripSegment?.id) {
      setSelectedTripSegment(trip);
      setSelectedTripEvent(null);
    }

    // console.debug('Track - trip', trip);
  };

  const devicesForGrid = useMemo(() => {
    // putting this in a memo so devices doesn't change every render call
    return getAberrationFreeDevices(trackingData.devices);
  }, [trackingData.devices]);

  // get filtered event type keys if user has event types filtered, otherwise support all
  const eventTypeKeys = !hddata
    ? getFilteredEventTypeKeys(userGridSettings, allEventTypeKeys)
    : hdDataEventTypeKeys;

  const gridSplitterFlex = userPreferences?.[gridSplitterKey] ?? 0.45;
  const chartSplitterFlex = userPreferences?.[chartSplitterKey] ?? 0.71;

  const { getEventRequestVideoMeta } = useDeviceCameraEvents({
    deviceId: selectedTripEvent?.device?.id || focusedEvent?.device?.id || null,
    from: dateRange.from,
    to: dateRange.to,
    eventTypes: eventTypeKeys
      .filter(type => type.startsWith('CAMERA'))
      .map(type => type.slice('CAMERA'.length))
  });

  const selectedTripEventWithRequestVideo = useMemo(() => {
    if (selectedTripEvent) {
      selectedTripEvent.requestVideo = getEventRequestVideoMeta(selectedTripEvent);
    }
    return selectedTripEvent;
  }, [selectedTripEvent, getEventRequestVideoMeta]);

  const focusedEventWithRequestVideo = useMemo(() => {
    if (focusedEvent) {
      focusedEvent.requestVideo = getEventRequestVideoMeta(focusedEvent);
    }
    return focusedEvent;
  }, [focusedEvent, getEventRequestVideoMeta]);

  const trackingMap = ({ showDroneViewControl, hideVehicleIfNotLatestTrip }) => {
    const isLoading = trackingData.isLoading || isTripsLoading || companyHasChanged;

    return !isLoading && hddata && selectedDevice?.trips && selectedDevice?.trips.length === 0 ? (
      <div className={styles.noDataContainer}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('Tracking.NoData')} />
      </div>
    ) : (
      <>
        {hideNonBusinessTrips &&
          (selectedDevice?.deviceStats?.attr === CompanyConfigValue.Private ||
            selectedTripSegment?.attr === CompanyConfigValue.Private) && (
            <div className={styles.loadingWrapper}>
              <HideNonBusinessStatus customStyle={styles.hideNonBusiness} />
            </div>
          )}
        <Map
          ref={mapRef}
          mode={mapMode}
          mapOptions={{
            center: mapCenter,
            zoom: mapZoom,
            mapTypeId:
              mapMode === MapMode.Drone
                ? google.maps.MapTypeId.HYBRID
                : userPreferences?.mapType || google.maps.MapTypeId.ROADMAP
          }}
          devices={filterNonBusinessTripDevices({
            devices: getFilteredDevices(),
            nonBusinessCompanyConfig: hideNonBusinessTrips
          })}
          geofences={filterGeofencesByShowTypes(
            trackingData.geofences,
            showGeofenceType(userPreferences),
            geofenceProviders
          )}
          eventTypes={eventTypeKeys}
          focusedEvent={focusedEventWithRequestVideo}
          selectedTripSegment={selectedTripSegment}
          selectedTripEvent={selectedTripEventWithRequestVideo}
          selectedReplay={selectedReplay}
          tripInfoWindowData={showTripInfoWindow && tripInfoWindowData}
          clickedDevice={clickedDeviceId}
          selectedDeviceId={clickedDeviceId || selectedDevice?.id}
          isLoading={isLoading}
          enableMapMenu={true}
          hideVehicleIfNotLatestTrip={hideVehicleIfNotLatestTrip}
          enableGeofenceSuggest={true}
          enableVehicleClustering={userPreferences?.clustering}
          showDroneViewControl={showDroneViewControl}
          onMapModeChanged={mode => setMapMode(mode)}
          onEventFocused={onEventFocused}
          onEventBlurred={onEventBlurred}
          onDeviceClicked={onDeviceClicked}
          onDeviceSelected={onDeviceSelected}
          supportGeofenceControl={true}
          onDeviceGpsUpdated={onDeviceGpsUpdated}
          onTripSegmentSelected={onTripSegmentSelected}
          onSetSelectedReplay={handleSetSelectedReplay}
          onEventClicked={onEventClicked}
          onEventInfoWindowClosed={handleEventInfoWindowClosed}
          onZoomChanged={handleMapViewportChanged(mapRef)}
          onDragEnd={handleMapViewportChanged(mapRef)}
          setMapCenter={setMapCenter}
          setZoomLevel={setMapZoom}
          nonBusinessCompanyConfig={hideNonBusinessTrips}
          useDefaultLocationMarker={true}
          draggableMarker={true}
          showIgnitionOnOffEventsForTrips={false}
          containerElement={<div style={{ height: `100%`, width: `100%` }} />}
          mapElement={<div style={{ height: `100%`, width: `100%` }} />}
          // todo: add back once TrackGrid supports row hover events
          // focusedDevice={focusedDeviceId}
          // onDeviceFocused={onDeviceFocused}
          // onDeviceBlurred={onDeviceBlurred}
        />
      </>
    );
  };

  return !hddata ? (
    <TrackingPage deviceId={selectedDevice?.id}>
      <ShareJobModal
        isOpen={vehicleForSharing && isShareLiveLocationModalOpen}
        jobOrVehicle="vehicle"
        onCancel={closeShareLiveLocationModal}
        vehicle={vehicleForSharing}
      />
      <ReflexContainer orientation="vertical">
        <ReflexElement
          className={styles.trackLeftPane}
          flex={gridSplitterFlex}
          onStopResize={handleGridSplitterResized}
        >
          <TrackGrid
            deviceUpdates={deviceUpdates}
            data={devicesForGrid}
            trips={isTripsLoading ? [] : selectedDevice?.trips}
            fleets={trackingData.fleets.sort((a, b) => a.name.localeCompare(b.name))}
            tab={tab}
            gridSettingsKey={GRID_SETTING_KEY}
            defaultGridConfig={DEFAULT_VEHICLE_VIEW_CONFIG}
            isLoading={trackingData.isLoading || companyHasChanged}
            isTripsLoading={isTripsLoading}
            expandedDeviceId={deviceId}
            dateRange={dateRange}
            datePickerRef={datePickerRef}
            selectedTrip={selectedTripSegment}
            selectedEvent={selectedTripEvent}
            onFilteredDevicesChanged={setFilteredDevices}
            onDateRangeSelected={onDateRangeSelected}
            onDateRangeClose={onDateRangeClose}
            onEventClicked={onEventClicked}
            onDeviceClicked={onDeviceClicked}
            onDeviceExpanded={onDeviceSelected}
            onTripSegmentSelected={onTripSegmentSelected}
            groupByTripsEnabled={groupByTripsEnabled}
            onGroupByTripsChange={onGroupByTripsChange}
            openShareLiveLocationModal={openShareLiveLocationModal}
          />
        </ReflexElement>
        <ReflexSplitter className={styles.gridSplitter} />
        <ReflexElement
          className={styles.rightPane}
          onResize={({ domElement }) => {
            const vaChartEl = domElement.querySelector(`.${vaChartContainerClassName}`);
            if (showVelocityAltitudeChart() && vaChartEl) {
              const { width, height } = vaChartEl.getBoundingClientRect();
              setVelocityAltitudeGraphSize({
                width: parseInt(width, 10),
                height: parseInt(height, 10)
              });
            }
          }}
        >
          <ReflexContainer orientation="horizontal">
            <ReflexElement
              flex={showVelocityAltitudeChart() ? chartSplitterFlex : 1.0}
              onStopResize={handleChartSplitterResized}
            >
              {trackingMap({ showDroneViewControl: true })}
            </ReflexElement>
            <ReflexSplitter className={styles.chartSplitter} />
            {showVelocityAltitudeChart() && (
              <ReflexElement className={`bottom-pane ${vaChartContainerClassName}`}>
                <ResizeObserver
                  onResize={({ width, height }) => {
                    setVelocityAltitudeGraphSize({ width, height });
                  }}
                >
                  <VelocityAltitudeGraph
                    isLoading={trackingData.isLoading || isTripsLoading || companyHasChanged}
                    trips={velocityData.trips}
                    startDate={velocityData.startDate}
                    endDate={velocityData.endDate}
                    focusedEvent={focusedEventWithRequestVideo}
                    selectedEvent={selectedTripEventWithRequestVideo}
                    selectedReplay={selectedReplay}
                    onSetSelectedReplay={handleSetSelectedReplay}
                    onMouseOver={() => setShowTripInfoWindow(true)}
                    onMouseMove={data => {
                      showTripInfoWindowData(data);
                    }}
                    onMouseOut={() => setShowTripInfoWindow(false)}
                    onMapBoundsChanged={onMapBoundsChanged}
                    onEventClicked={onEventClicked}
                    size={velocityAltitudeGraphSize}
                  />
                </ResizeObserver>
              </ReflexElement>
            )}
          </ReflexContainer>
        </ReflexElement>
      </ReflexContainer>
    </TrackingPage>
  ) : (
    <>
      <HDDataToolbar
        trips={velocityData.trips}
        isLoading={trackingData.isLoading || isTripsLoading || companyHasChanged}
        selectedDevice={selectedDevice}
        devices={devicesForGrid}
        hideNonBusinessTrips={hideNonBusinessTrips}
        dateRange={dateRange}
        hdDataEventTypeKeys={hdDataEventTypeKeys}
        setHDDataEventTypeKeys={setHDDataEventTypeKeys}
        onDateRangeClose={onDateRangeClose}
        updateHDDataDevice={updateHDDataDevice}
        updateHDDataDateRange={updateHDDataDateRange}
      />
      <ReflexContainer orientation="horizontal">
        <ReflexElement flex={0.62}>
          {trackingMap({ showDroneViewControl: false, hideVehicleIfNotLatestTrip: true })}
        </ReflexElement>
        <ReflexSplitter className={styles.chartSplitter} />
        <ReflexElement className={`bottom-pane ${vaChartContainerClassName}`}>
          <ResizeObserver
            onResize={({ width, height }) => {
              setVelocityAltitudeGraphSize({ width, height });
            }}
          >
            <VelocityAltitudeGraph
              isLoading={trackingData.isLoading || isTripsLoading || companyHasChanged}
              trips={velocityData.trips}
              startDate={velocityData.startDate}
              endDate={velocityData.endDate}
              focusedEvent={focusedEventWithRequestVideo}
              selectedEvent={selectedTripEventWithRequestVideo}
              selectedReplay={selectedReplay}
              onSetSelectedReplay={handleSetSelectedReplay}
              onMouseOver={() => setShowTripInfoWindow(true)}
              onMouseMove={data => {
                showTripInfoWindowData(data);
              }}
              onMouseOut={() => setShowTripInfoWindow(false)}
              onMapBoundsChanged={onMapBoundsChanged}
              onEventClicked={onEventClicked}
              size={velocityAltitudeGraphSize}
            />
          </ResizeObserver>
        </ReflexElement>
      </ReflexContainer>
    </>
  );
};

export const formatDateRange = ({ trip, from, to, dateRange }) => {
  let queryFrom;
  if (trip?.ignitionOn) {
    queryFrom = moment(trip.ignitionOn).format();
  } else if (from) {
    queryFrom = moment(from).format();
  } else {
    queryFrom = moment(dateRange.from).format();
  }

  let queryTo;
  if (trip?.ignitionOff) {
    queryTo = moment(trip.ignitionOff).format();
  } else if (to) {
    queryTo = moment(to).format();
  } else {
    queryTo = moment(dateRange.to).format();
  }

  return { queryFrom, queryTo };
};
