import React from 'react';
import i18n from 'i18next';

import { Sector } from 'recharts';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ReactComponent as UpEventType } from 'static/images/icons/event-type-down-icon.svg';
import { ReactComponent as DownEventType } from 'static/images/icons/event-type-up-icon.svg';
import { ReactComponent as SameEventType } from 'static/images/icons/event-type-same-icon.svg';
import { eventTypeValueStyle, eventTypeLabelStyle } from './constants';
import { TRIP_STATE } from 'containers/FBTManager/utils/constants';

import styles from './PieChart.module.scss';

export const getEventTypeComponent = diff => {
  let component = <SameEventType />;
  switch (true) {
    case diff > 0:
      component = <UpEventType />;
      break;
    case diff < 0:
      component = <DownEventType />;
      break;
    default:
      break;
  }
  return component;
};

const getPieContent = (data, coords, diff) => {
  const { name, value, purpose, timelapse } = data;
  const arrowClass = cn(styles.arrow, {
    [styles.arrowAscending]: diff > 0,
    [styles.arrowDescending]: diff < 0
  });
  return purpose === TRIP_STATE.PRIVATE || purpose === TRIP_STATE.BUSINESS ? (
    <>
      <text x={coords.cx} y={coords.cy - 12} style={eventTypeLabelStyle} textAnchor="middle">
        {i18n.t('Common.TypeOfUse')}
      </text>
      <text x={coords.cx} y={coords.cy + 12} style={eventTypeLabelStyle} textAnchor="middle">
        {/* placeholder string */}
        {timelapse}
      </text>
    </>
  ) : (
    <>
      <text
        x={coords.cx}
        y={name ? coords.cy - 20 : coords.cy}
        style={eventTypeValueStyle}
        textAnchor="middle"
      >
        {value}
      </text>
      {name && (
        <foreignObject x={coords.cx - 62} y={80} width="125px" height="70px">
          <div className={styles.noBackground}>
            <div className={styles.eventTypeLabel}>{name}</div>
            <div className={arrowClass}>{getEventTypeComponent(diff)}</div>
          </div>
        </foreignObject>
      )}
    </>
  );
};

export const renderActiveShape = props => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload, fill, diff } = props;

  const pieContentData = {
    name: payload?.name,
    value: payload?.value,
    purpose: payload?.purpose,
    timelapse: payload?.timelapse
  };

  const pieContentCoords = {
    cx: cx,
    cy: cy
  };

  return (
    <g>
      {getPieContent(pieContentData, pieContentCoords, diff)}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

renderActiveShape.propTypes = {
  props: PropTypes.node.isRequired
};
