import React from 'react';

import { Cell, Pie, PieChart as RechartsPieChart, Tooltip } from 'recharts';
import { ChartTooltip } from '../chart-tooltip';
import PropTypes from 'prop-types';

import { COLORS, PIE_PROPERTIES } from './constants';
import { renderActiveShape } from './helpers';

export const PieChart = ({ data, properties = PIE_PROPERTIES }) => {
  return (
    <RechartsPieChart width={properties?.width} height={properties?.height}>
      <Pie
        activeIndex={PIE_PROPERTIES.activeIndex}
        activeShape={renderActiveShape}
        data={data}
        cx={properties?.cx}
        cy={properties?.cy}
        dataKey={properties?.dataKey}
        innerRadius={properties?.innerRadius}
        outerRadius={properties?.outerRadius}
        paddingAngle={properties?.paddingAngle}
        startAngle={properties?.startAngle}
        endAngle={properties?.endAngle}
      >
        {data.map(sector => (
          <Cell key={sector.key} fill={sector.color || COLORS.SILVER} />
        ))}
      </Pie>
      <Tooltip content={<ChartTooltip data={data} />} />
    </RechartsPieChart>
  );
};

PieChart.propTypes = {
  data: PropTypes.array,
  properties: PropTypes.object
};
