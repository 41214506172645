import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { MoreMenu } from 'components/ant';
import { Paths } from '../';
import { EnrolmentStatuses } from '../constants';
import { cancelEnrolmentModal } from '../helpers';
import { GeneratePdf } from '../GeneratePdf/GeneratePdf';

export const ActionMenu = ({ enrolment, schemes, enrolments }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!enrolment) {
    return null;
  }

  return (
    <>
      <MoreMenu
        useNewIcon={true}
        items={[
          {
            name: t(`Common.View`),
            onClick: () => {
              history.push(`${Paths.VIEW_ENROLEMNT}/${enrolment.id}`);
            },
            id: 'btn_tcaMenuView'
          },
          {
            name: t(`Common.Edit`),
            onClick: () => {
              history.push(`${Paths.EDIT_ENROLMENT}/${enrolment.id}`);
            },
            disabled: enrolment?.status === EnrolmentStatuses.CANCELLED,
            id: 'btn_tcaMenuEdit'
          },
          {
            name: t(`TCAEnrolments.CancelEnrolment`),
            danger: true,
            onClick: cancelEnrolmentModal(dispatch, t, enrolment),
            disabled: enrolment?.status === EnrolmentStatuses.CANCELLED,
            id: 'btn_tcaMenuCancel'
          },
          {
            component: GeneratePdf,
            props: {
              listItem: true,
              enrolmentId: enrolment.id,
              enrolments: enrolments,
              schemes: schemes
            }
          }
        ]}
      />
    </>
  );
};
