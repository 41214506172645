import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';

//containers
import { Integrations } from './Integrations';
import { Configuration } from './Configuration/Configuration';

// components
import { NoAccessBody } from 'components/pages/NoAccess';
import { Can, FeatureFlag, services } from 'features/permissions';

//constants
import { Paths } from './constants';

export const IntegrationsRouter = () => {
  return (
    <Switch>
      <Route
        path={`${Paths.CONFIGURATION}/:tab`}
        render={({ match, history }) => {
          return (
            <Can everyCompanyService={services.INTEGRATION} onFail={<NoAccessBody />}>
              <Configuration match={match} history={history} />
            </Can>
          );
        }}
      ></Route>
      <Route
        path={`${Paths.MAIN_PAGE}/:tab`}
        render={({ match, history }) => {
          return (
            <Can everyCompanyService={services.INTEGRATION} onFail={<NoAccessBody />}>
              <Integrations match={match} history={history} />
            </Can>
          );
        }}
      ></Route>
    </Switch>
  );
};
