import { useDispatch, useSelector } from 'react-redux';
import { fetchVehicleModels } from './thunks';
import { vehicleModelsSelectors } from './';
import { updateCompanyId } from './reducers';

export const useVehicleModels = companyId => {
  const dispatch = useDispatch();
  const vehicleModels = useSelector(vehicleModelsSelectors.getVehicleModels);
  const currentCompanyId = useSelector(vehicleModelsSelectors.getCompanyId);

  const isFetching = useSelector(vehicleModelsSelectors.isFetching);
  const isSuccess = useSelector(vehicleModelsSelectors.isSuccess);
  const isError = useSelector(vehicleModelsSelectors.isError);
  const isFetchComplete = isSuccess || isError;

  if ((!isFetchComplete || currentCompanyId != companyId) && !isFetching) {
    dispatch(fetchVehicleModels({ query: { company_id: companyId } }));
    dispatch(updateCompanyId(companyId));
  }

  return vehicleModels || [];
};

export const useIsFetching = () => useSelector(vehicleModelsSelectors.isFetching);
