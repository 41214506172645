import i18n from 'i18nextConfig';

export const ITEM_NAMES = {
  COMPANY_ID: 'companyId',
  DEVICE_ID: 'deviceId',
  DEVICE_IMEI: 'deviceImei',
  DEVICE_INSTALLATION_LOCATION: 'installationLocation',
  DEVICE_INSTALLATION_TIME: 'installationDateTime',
  OPERATOR_ABN: 'operatorABN',
  OPERATOR_ADDRESS_LINE_ONE: 'lineOne',
  OPERATOR_ADDRESS_LOCALITY: 'locality',
  OPERATOR_ADDRESS_POSTCODE: 'postCode',
  OPERATOR_ADDRESS_STATE: 'stateCode',
  OPERATOR_ALERTINFO: 'operatorAlertInfo',
  OPERATOR_BUSINESS_HOURS: 'businessHoursPhone',
  OPERATOR_NAME: 'operatorName',
  PBS: 'pbsApprovalNumbers',
  SCHEME: 'scheme',
  VEHICLE_NON_VIN: 'nonVinIdentifier',
  VEHICLE_REGISTRATION_NUMBER: 'registration',
  VEHICLE_REGISTRATION_STATE: 'registrationState',
  VEHICLE_VIN: 'VIN',
  VIN_RADIO_GROUP: 'vinRadioGroup'
};

export const ITEM_LABELS = {
  [ITEM_NAMES.COMPANY_ID]: 'TCAEnrolments.Form.Company',
  [ITEM_NAMES.DEVICE_IMEI]: 'TCAEnrolments.device',
  [ITEM_NAMES.VEHICLE_REGISTRATION_NUMBER]: 'TCAEnrolments.Form.VehicleRegistrationNumber',
  [ITEM_NAMES.VEHICLE_REGISTRATION_STATE]: 'TCAEnrolments.Form.VehicleRegistrationState',
  [ITEM_NAMES.VEHICLE_VIN]: 'TCAEnrolments.Form.VehicleIdentifier',
  [ITEM_NAMES.VIN_RADIO_GROUP]: 'TCAEnrolments.Form.VehicleIdentifierRadioGroup',
  [ITEM_NAMES.DEVICE_INSTALLATION_TIME]: 'TCAEnrolments.Form.DeviceInstallationTime',
  [ITEM_NAMES.DEVICE_INSTALLATION_LOCATION]: 'TCAEnrolments.Form.DeviceInstallationLocation',
  [ITEM_NAMES.SCHEME]: 'TCAEnrolments.Form.Schemes',
  [ITEM_NAMES.OPERATOR_NAME]: 'TCAEnrolments.Form.OperatorName',
  [ITEM_NAMES.OPERATOR_ABN]: 'TCAEnrolments.Form.OperatorABN',
  [ITEM_NAMES.OPERATOR_ADDRESS_LINE_ONE]: 'TCAEnrolments.Form.OperatorAddressLineOne',
  [ITEM_NAMES.OPERATOR_ADDRESS_LOCALITY]: 'TCAEnrolments.Form.OperatorAddressLocality',
  [ITEM_NAMES.OPERATOR_ADDRESS_STATE]: 'TCAEnrolments.Form.OperatorAddressState',
  [ITEM_NAMES.OPERATOR_ADDRESS_POSTCODE]: 'TCAEnrolments.Form.OperatorAddressPostcode',
  [ITEM_NAMES.OPERATOR_BUSINESS_HOURS]: 'TCAEnrolments.Form.OperatorBusinessHoursPhone',
  [ITEM_NAMES.PBS]: 'TCAEnrolments.Form.PBS'
};

export const ITEM_PLACEHOLDERS = {
  [ITEM_NAMES.DEVICE_IMEI]: 'TCAEnrolments.Form.Placeholders.SelectADevice',
  [ITEM_NAMES.SCHEME]: 'TCAEnrolments.Form.Placeholders.SelectSchemes',
  [ITEM_NAMES.DEVICE_INSTALLATION_TIME]: 'TCAEnrolments.Form.Placeholders.SelectDate',
  [ITEM_NAMES.VEHICLE_REGISTRATION_STATE]:
    'TCAEnrolments.Form.Placeholders.SelectVehicleRegistrationState'
};

export const vinRadioGroupValues = [
  { value: 'vin', label: 'VIN' },
  { value: 'nonVin', label: 'Non VIN' }
];

export const operatorStateAddress = [
  {
    id: 'NSW',
    name: 'NSW'
  },
  {
    id: 'VIC',
    name: 'VIC'
  },
  {
    id: 'QLD',
    name: 'QLD'
  },
  {
    id: 'SA',
    name: 'SA'
  },
  {
    id: 'NT',
    name: 'NT'
  },
  {
    id: 'ACT',
    name: 'ACT'
  },
  {
    id: 'WA',
    name: 'WA'
  },
  {
    id: 'TAS',
    name: 'TAS'
  }
];

export const operatorABNRules = [{ required: true }, { len: 11 }];
export const pbsRules = [
  { required: false },
  {
    validator: async (_, value) => {
      const valuesArr = value?.split(',');
      if (valuesArr?.length > 6) {
        return Promise.reject(new Error(i18n.t('TCAEnrolments.Form.PBSError')));
      }
    }
  }
];
export const vinRules = [{ required: true }, { len: 17 }];
export const nonVinRules = [{ required: true }, { min: 1 }, { max: 25 }];
export const registrationNumberRules = [{ required: true }, { min: 1 }, { max: 10 }];
export const registrationStateRules = [
  { required: true },
  {
    validator: (rule, value, callback) => {
      const validOptionsString = operatorStateAddress.map(state => state.name).join(', ');
      if (!validOptionsString.includes(value)) {
        callback(
          i18n.t('TCAEnrolments.Form.Validation.VehicleRegistrationState', {
            data: validOptionsString
          })
        );
      } else {
        callback();
      }
    }
  }
];

export const operatorBusinessHoursPhone = [
  { required: true },
  {
    pattern: /^[0-9 ]+$/,
    message: () => i18n.t('TCAEnrolments.Form.Validation.OnlyNumericCharacters')
  },
  { min: 6 },
  { max: 20 }
];
