import { API_PATH } from 'config';

const resolveApi = async () => {
  const store = await import('store');
  const url = API_PATH + '/sentinel';
  const userKey = store.default.getState().user?.current?.auth?.key;
  const companyId = store.default.getState().companies?.current?.id;
  return {
    url: url,
    userKey: userKey,
    companyId: companyId
  };
};

const resolveUser = async () => {
  const store = await import('store');
  return store.default.getState().sentinel.user.id;
};

const postData = async (url = '', data = {}) => {
  const api = await resolveApi();
  const userKey = api.userKey;
  const response = fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token token="${userKey}"`
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data)
  });
  // }).then(response => response.json());
  return response;
};

const putData = async (url = '', data = {}) => {
  const api = await resolveApi();
  const userKey = api.userKey;
  const response = fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token token="${userKey}"`
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data)
  });
  // }).then(response => response.json());
  return response;
};

const deleteData = async (url = '', data = {}) => {
  const api = await resolveApi();
  const userKey = api.userKey;
  const response = fetch(url, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token token="${userKey}"`
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    body: JSON.stringify(data)
  });
  // }).then(response => response.json());
  return response;
};

const get = async (url = '', abortSignal) => {
  const api = await resolveApi();
  const userKey = api.userKey;
  const response = fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token token="${userKey}"`
    },
    redirect: 'follow',
    referrer: 'no-referrer',
    signal: abortSignal
  }).then(response => response.json());
  return response;
};

export const submitEvent = async function(payload) {
  const userId = await resolveUser();
  const api = await resolveApi();
  const url = api.url + `/users/${userId}/events?company_id=${api.companyId}`;
  return await postData(url, payload);
};

export const updateEvent = async function(payload) {
  const userId = await resolveUser();
  const api = await resolveApi();
  const url = api.url + `/users/${userId}/events/${payload.id}?company_id=${api.companyId}`;
  return await putData(url, payload);
};

export const deleteEvent = async function(payload) {
  const userId = await resolveUser();
  const api = await resolveApi();
  const url = api.url + `/users/${userId}/events/${payload.id}?company_id=${api.companyId}`;
  return await deleteData(url, payload);
};

export const auditEvent = async function(id) {
  const userId = await resolveUser();
  const api = await resolveApi();
  const url = api.url + `/users/${userId}/events/${id}/audits?company_id=${api.companyId}`;
  return await get(url);
};
export const fetchUserRulesets = async function(userId) {
  const api = await resolveApi();
  const url = api.url + `/users/${userId}/rulesets?company_id=${api.companyId}`;
  return await get(url);
};

export const fetchRulesets = async function() {
  const api = await resolveApi();
  const url = api.url + `/rulesets?company_id=${api.companyId}`;
  return await get(url);
};

export const fetchStatus = async function(userId, ruleset, abortSignal) {
  const api = await resolveApi();
  const url =
    api.url +
    `/users/${userId}/status?persist=false&company_id=${api.companyId}` +
    (ruleset ? `&ruleset=${ruleset}` : '');
  return await get(url, abortSignal);
};
