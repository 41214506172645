import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Input, Form, Modal, Select, Checkbox, Spin } from 'antd';
import { TreeSelect, SHOW_PARENT, SHOW_CHILD } from 'components/ant';
import { useUserKey } from 'features/user/userSlice';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import {
  postMessage,
  useIsPostingMessage,
  useIsPostMessageSuccess,
  useIsPostMessageFailed,
  usePostMessageFailedError,
  setInitailPostMessageStatus,
  fetchMessageTemplates,
  useMessageTemplates
} from 'features/messaging/messagingSlice';
import { Loading } from 'components/loading/Loading';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { useRecipientTree } from 'features/messaging/hooks';
import { RECIPIENT_TYPE } from './constants';
import { DEVICE_DYNAMIC_LINKED_VEHICLE } from 'features/vehicles/hooks';
import { findTreeNodeCheckedValue, findTreeNode } from './helpers';
import { toLower, isObject } from 'lodash';
import styles from './Messaging.module.scss';

const defaultFormValue = {
  recipients: [],
  template: null,
  subject: '',
  body: '',
  requireAck: false
};
const findRecipientTreeNodes = (recipients, recipientTree) => {
  if (!recipientTree || !recipientTree.length || !recipients || !recipients.length) {
    return;
  }
  const [branchTree, fleetTree] = recipientTree;
  return recipients
    .map(recipient =>
      findTreeNode(
        recipient.recipientId,
        recipient.recipientType,
        recipient.recipientType === RECIPIENT_TYPE.DRIVER ? branchTree : fleetTree
      )
    )
    .filter(node => !!node);
};

export const MessageComposeModal = ({ visible, showModal, recipients }) => {
  const userKey = useUserKey();
  const companyKey = useCurrentCompanyKey();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const messageTemplates = useMessageTemplates();

  const recipientTree = useRecipientTree();

  const [form] = Form.useForm();

  const formIsInvalid = useCallback(() => {
    return (
      !form.getFieldValue('recipients') ||
      !form.getFieldValue('recipients').length ||
      !form.getFieldValue('subject') ||
      !form.getFieldValue('body') ||
      form.getFieldsError(['subject', 'body', 'recipients']).some(f => !!f.errors.length)
    );
  }, [form]);
  const [disableOKBtn, setDisableOKBtn] = useState(formIsInvalid());

  const [formInitialValue, setFormInitialValue] = useState(defaultFormValue);
  const [formValue, setFormValue] = useState(defaultFormValue);

  const [modalLoading, setModalLoading] = useState(!!recipients && !!recipients.length);
  const [recipientTreeShowCheckedStrategy, setRecipientTreeShowCheckedStrategy] = useState(
    !!recipients && !!recipients.length ? SHOW_CHILD : SHOW_PARENT
  );

  const timeoutRef = useRef();
  useEffect(() => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setDisableOKBtn(formIsInvalid());
    });
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [formValue, formIsInvalid]);

  const isPosting = useIsPostingMessage();
  const isPostSuccess = useIsPostMessageSuccess();
  const isPostFailed = useIsPostMessageFailed();
  const failedErrorStr = usePostMessageFailedError();

  useEffect(() => {
    setRecipientTreeShowCheckedStrategy(
      !!recipients && !!recipients.length ? SHOW_CHILD : SHOW_PARENT
    );
    if (recipients && recipients.length) {
      if (recipientTree && recipientTree.length) {
        const preSelectedRecipientNodes = findRecipientTreeNodes(recipients, recipientTree);
        setFormInitialValue({ ...defaultFormValue, recipients: preSelectedRecipientNodes });
        setFormValue({ ...defaultFormValue, recipients: preSelectedRecipientNodes });
        setModalLoading(false);
      }
    } else {
      setModalLoading(false);
    }
  }, [recipients, recipientTree]);

  useEffect(() => {
    if (isPostSuccess) {
      dispatch(setInitailPostMessageStatus());
      dispatch(openToast({ type: ToastType.Success, title: t('Messaging.MessageSent') }));
      showModal(false);
    } else if (isPostFailed) {
      dispatch(setInitailPostMessageStatus());
      dispatch(
        openToast({
          type: ToastType.Error,
          title: t('Messaging.MessageSendFailed', { error: failedErrorStr })
        })
      );
    }
  }, [isPostSuccess, isPostFailed, showModal, dispatch, t]);

  useEffect(() => {
    if (!companyKey || !userKey) {
      return;
    }
    dispatch(fetchMessageTemplates());
  }, [companyKey, userKey, dispatch]);

  const handleOk = useCallback(() => {
    form.submit();
  }, [form]);
  const handleCancel = () => {
    //check if isFieldsTouched, if touched check if all fields are empty
    if (form.isFieldsTouched()) {
      const formValue = form.getFieldsValue() || {};
      //recipients,body,subject
      const recipientsIsEmpty = !formValue['recipients'] || !formValue['recipients'].length;
      const bodyIsEmpty = !formValue['body'] || !formValue['body'].trim();
      const subjectIsEmpty = !formValue['subject'] || !formValue['subject'].trim();
      if (recipientsIsEmpty && bodyIsEmpty && subjectIsEmpty) {
        showModal(false);
      } else {
        confirmationModal(
          t('Common.Modal.SureTitle'),
          t('Common.Modal.SureQuestionChangesLost'),
          t('Common.Modal.CancelChanges'),
          t('Common.Modal.Stay'),
          () => showModal(false)
        );
      }
    } else {
      showModal(false);
    }
  };
  const handleSubmit = values => {
    const { recipients } = values,
      events = getRecipientsEvents(recipientTree, recipients);
    // console.log('handleSubmit', events, values, recipients);
    dispatch(postMessage({ ...values, events }));
  };
  const handleTemplateChange = templateId => {
    const selectedMessageTemplate = messageTemplates.find(template => template.id === templateId);
    form.setFieldsValue({
      body: selectedMessageTemplate.body,
      subject: selectedMessageTemplate.subject
    });
  };

  return (
    <Modal
      wrapClassName={styles.messagingModal}
      title={`${t('Messaging.Form.New')} ${t('Entity.Message')}`}
      open={visible}
      cancelText={t('Common.Cancel')}
      okText={`${t('Messaging.Send')} ${t('Entity.Message')}`}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: disableOKBtn, loading: isPosting }}
      maskClosable={false}
      centered
    >
      {modalLoading && <Spin></Spin>}
      {!modalLoading && (
        <Form
          layout="vertical"
          form={form}
          name="message_composing_form"
          style={{ width: '100%' }}
          onValuesChange={(changedValues, allValues) => {
            setFormValue(allValues);
          }}
          onFinish={handleSubmit}
          initialValues={formInitialValue}
        >
          <Form.Item
            name="recipients"
            label={t('Messaging.Recipients')}
            rules={[
              { required: true, message: t('Messaging.Form.FormValidation.RecipientRequired') }
            ]}
            hasFeedback
          >
            <TreeSelect
              disabled={recipients && recipients.length}
              labelInValue={true}
              listHeight={195}
              treeDefaultExpandAll
              treeData={recipientTree}
              showCheckedStrategy={recipientTreeShowCheckedStrategy}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              allowClear={true}
              placeholder={t('Messaging.Form.PleaseSelect')}
              treeNodeFilterProp="title"
              filterTreeNode={(inputValue, treeNode) => {
                if (isObject(treeNode.title)) {
                  return (
                    treeNode?.rawData?.name &&
                    toLower(treeNode.rawData?.name)?.includes(toLower(inputValue))
                  );
                } else {
                  return treeNode.title && toLower(treeNode.title)?.includes(toLower(inputValue));
                }
              }}
              dropdownRender={originNode => {
                return !recipientTree ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {' '}
                    <Loading />
                  </div>
                ) : (
                  originNode
                );
              }}
            />
          </Form.Item>
          <Form.Item name="template" label={t('Entity.Message Template')}>
            <Select
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              getPopupContainer={triggerNode => triggerNode.parentNode}
              loading={!messageTemplates?.length}
              onSelect={handleTemplateChange}
              optionFilterProp={'subject'}
              options={messageTemplates.map(template => ({
                label: template.subject,
                value: template.id
              }))}
              placeholder={
                <div>
                  <FontAwesomeIcon icon={faSearch} />
                  {t('Messaging.Form.SearchTemplate')}
                </div>
              }
              suffixIcon={null}
              showSearch
            ></Select>
          </Form.Item>
          <Form.Item
            name="subject"
            label={t('Messaging.Subject')}
            rules={[
              { required: true, message: t('Messaging.Form.FormValidation.SubjectRequired') },
              { max: 50, message: t('Messaging.Form.FormValidation.SubjectLength') }
            ]}
            hasFeedback
          >
            <Input placeholder={t('Messaging.Form.FormValidation.TypeHere')} />
          </Form.Item>
          <Form.Item
            name="body"
            label={t('Messaging.Message')}
            rules={[
              { required: true, message: t('Messaging.Form.FormValidation.MessageRequired') },
              { max: 1000, message: t('Messaging.Form.FormValidation.MessageLength') }
            ]}
            hasFeedback
          >
            <Input.TextArea
              className={'showScrollbarsOnHover'}
              rows={5}
              placeholder={t('Messaging.Form.FormValidation.WriteSomething')}
            />
          </Form.Item>
          <Form.Item name="requireAck" valuePropName="checked">
            <Checkbox>{t('Messaging.MessageRequiresDriverAcknowledgement')}</Checkbox>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

const getRecipientsEvents = (recipientTree, recipients) => {
  //construct vehicle/devices recipients
  const branchTree = recipientTree[0],
    fleetTree = recipientTree[1];
  const getCleanVDEvent = vdEvent => {
    if (vdEvent?.device?.id) {
      delete vdEvent.device?.vehicle;
      delete vdEvent.device?.vehicleInfo;
      //device with dynamic linked should not sending to this vehicle
      const dynamicLinked = Object.getOwnPropertySymbols(vdEvent.device)[0];
      if (dynamicLinked === DEVICE_DYNAMIC_LINKED_VEHICLE) {
        delete vdEvent.vehicle;
      }
    }
    return vdEvent;
  };
  const fleetsEvents = recipients
    .map(nodeValue => findTreeNodeCheckedValue(nodeValue.value, fleetTree))
    .filter(recipient => !!recipient && recipient.length > 0)
    .reduce((a, c) => a.concat(c), [])
    .map(getCleanVDEvent);
  const branchEvents = recipients
    .map(nodeValue => findTreeNodeCheckedValue(nodeValue.value, branchTree))
    .filter(recipient => !!recipient && recipient.length > 0)
    .reduce((a, c) => a.concat(c), []);
  return [...fleetsEvents, ...branchEvents];
};
