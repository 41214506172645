/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ApiKey from '../model/ApiKey';
import Company from '../model/Company';
import Fleet from '../model/Fleet';

/**
* Companies service.
* @module api/CompaniesApi
* @version 1.0
*/
export default class CompaniesApi {

    /**
    * Constructs a new CompaniesApi. 
    * @alias module:api/CompaniesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the createCameraSnapshotConfig operation.
     * @callback module:api/CompaniesApi~createCameraSnapshotConfigCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Fleet>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set camera dashboard config for the given company
     * A user token key is required for this call.
     * @param {Number} id 
     * @param {String} key API key for user
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/CompaniesApi~createCameraSnapshotConfigCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Fleet>}
     */
    createCameraSnapshotConfig(id, key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling createCameraSnapshotConfig");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling createCameraSnapshotConfig");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/companies/{id}/camera/config', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteCameraSnapshotConfig operation.
     * @callback module:api/CompaniesApi~deleteCameraSnapshotConfigCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Fleet>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete camera dashboard config for the given company and given id
     * A user token key is required for this call.
     * @param {Number} id 
     * @param {Number} configId 
     * @param {String} key API key for user
     * @param {module:api/CompaniesApi~deleteCameraSnapshotConfigCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Fleet>}
     */
    deleteCameraSnapshotConfig(id, configId, key, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteCameraSnapshotConfig");
      }
      // verify the required parameter 'configId' is set
      if (configId === undefined || configId === null) {
        throw new Error("Missing the required parameter 'configId' when calling deleteCameraSnapshotConfig");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling deleteCameraSnapshotConfig");
      }

      let pathParams = {
        'id': id,
        'configId': configId
      };
      let queryParams = {
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/companies/{id}/camera/config/{configId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the disableCompany operation.
     * @callback module:api/CompaniesApi~disableCompanyCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Disable a company
     * A token key is required for this call.
     * @param {String} id 
     * @param {String} key API key for a company
     * @param {module:api/CompaniesApi~disableCompanyCallback} callback The callback function, accepting three arguments: error, data, response
     */
    disableCompany(id, key, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling disableCompany");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling disableCompany");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/companies/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the findByIdOrSlugOrExternalId operation.
     * @callback module:api/CompaniesApi~findByIdOrSlugOrExternalIdCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Company} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieve a company by ID or SLUG
     * A token key is required for this call.
     * @param {String} id 
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {module:api/CompaniesApi~findByIdOrSlugOrExternalIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Company}
     */
    findByIdOrSlugOrExternalId(id, key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findByIdOrSlugOrExternalId");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling findByIdOrSlugOrExternalId");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Company;
      return this.apiClient.callApi(
        '/companies/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getApiKeys operation.
     * @callback module:api/CompaniesApi~getApiKeysCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/ApiKey>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieve a company's API keys by ID or SLUG
     * A token key is required for this call.
     * @param {String} id 
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.keyType Key Type (default to 'all')
     * @param {module:api/CompaniesApi~getApiKeysCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/ApiKey>}
     */
    getApiKeys(id, key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getApiKeys");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getApiKeys");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'key_type': opts['keyType'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ApiKey];
      return this.apiClient.callApi(
        '/companies/{id}/keys', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCameraSnapshotConfig operation.
     * @callback module:api/CompaniesApi~getCameraSnapshotConfigCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Fleet>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get camera dashboard config for the given company
     * A user token key is required for this call.
     * @param {Number} id 
     * @param {String} key API key for user
     * @param {Object} opts Optional parameters
     * @param {String} opts.provider Camera provider
     * @param {module:api/CompaniesApi~getCameraSnapshotConfigCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Fleet>}
     */
    getCameraSnapshotConfig(id, key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getCameraSnapshotConfig");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling getCameraSnapshotConfig");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'provider': opts['provider'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/companies/{id}/camera/config', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getServicesByCompanyId operation.
     * @callback module:api/CompaniesApi~getServicesByCompanyIdCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Fleet>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Lists service codes available for the company
     * A token key is required for this call.
     * @param {Number} id 
     * @param {String} key API key for user/company
     * @param {Object} opts Optional parameters
     * @param {String} opts.embed Options: features
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {module:api/CompaniesApi~getServicesByCompanyIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Fleet>}
     */
    getServicesByCompanyId(id, key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getServicesByCompanyId");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'embed': opts['embed'],
        'pruning': opts['pruning'],
        'key': key,
        'company_id': id
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Fleet];
      return this.apiClient.callApi(
        '/companies/{id}/services', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the insertCompany operation.
     * @callback module:api/CompaniesApi~insertCompanyCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Company} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add a new company
     * A token key is required for this call.
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {module:model/Company} opts.body 
     * @param {module:api/CompaniesApi~insertCompanyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Company}
     */
    insertCompany(key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling insertCompany");
      }

      let pathParams = {
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Company;
      return this.apiClient.callApi(
        '/companies', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the list operation.
     * @callback module:api/CompaniesApi~listCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Company>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Retrieve a list of companies
     * A token key is required for this call.
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'LIST')
     * @param {module:api/CompaniesApi~listCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Company>}
     */
    list(key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling list");
      }

      let pathParams = {
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'embed': opts['embed'],
        'active': opts['active'] ?? true,
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Company];
      return this.apiClient.callApi(
        '/companies', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the listByCompany operation.
     * @callback module:api/CompaniesApi~listByCompanyCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Fleet>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Lists fleets or look up by vehicle
     * A token key is required for this call.
     * @param {Number} id 
     * @param {String} key API key for user/company
     * @param {Object} opts Optional parameters
     * @param {String} opts.embed What needs to be included in response
     * @param {String} opts.deviceTypes Type of devices to be included
     * @param {String} opts.mode Mode of query
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {module:api/CompaniesApi~listByCompanyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Fleet>}
     */
    listByCompany(id, key, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling listByCompany");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'embed': opts['embed'],
        'device_types': opts['deviceTypes'],
        'mode': opts['mode'],
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Fleet];
      return this.apiClient.callApi(
        '/companies/{id}/fleets', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the mergeCompany operation.
     * @callback module:api/CompaniesApi~mergeCompanyCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {module:model/Company} opts.body 
     * @param {module:api/CompaniesApi~mergeCompanyCallback} callback The callback function, accepting three arguments: error, data, response
     */
    mergeCompany(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'pruning': opts['pruning']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/companies/merge', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateCompany operation.
     * @callback module:api/CompaniesApi~updateCompanyCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a company
     * A token key is required for this call.
     * @param {String} id 
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {String} opts.body 
     * @param {module:api/CompaniesApi~updateCompanyCallback} callback The callback function, accepting three arguments: error, data, response
     */
    updateCompany(id, key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateCompany");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling updateCompany");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/companies/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Retrieve the snapshots of a company
     * A token key is required for this call.
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'APP')
     * @param {module:model/number} opts.year  (default to current year)
     * @param {module:model/number} opts.month  (default to current month)
     * @param {module:api/CompaniesApi~getSnapshots} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Attachment>}
     */
     getSnapshots(companyId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'key' is set
      if (companyId === undefined || companyId === null) {
        throw new Error("Missing the required parameter 'id' when calling getSnapshots");
      }

      let pathParams = {
        id: companyId
      };
      let queryParams = {
        'pruning': opts['pruning'] || 'APP',
        'year': opts['year'],
        'month': opts['month']
      };

      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/companies/{id}/snapshots', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }
}
