import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

//components
import Map, { MapMode } from 'components/map/Map';
import InfoRow from 'components/form/info-row/InfoRow';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Row, Col, Form, Modal } from 'antd';
import { Input, Button } from 'components/ant';
import useInterval from 'features/common/hooks/interval';

//slices
import { useLocalization } from 'features/localization/localizationSlice';
import {
  acknowledgeDuressAlert,
  getDuressAlertStatus,
  dismissDuressAlert
} from 'features/notifications/notificationsSlice';

//constants
import { ReactComponent as ChevronLeft } from 'static/images/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'static/images/icons/chevron-right.svg';

//helpers
import { locationHelpers } from 'containers/Administration/Locations/helpers';
import { format } from 'utils/dates';
import { duressHelpers } from './helpers';

// styles
import styles from './DuressAlertModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const DuressAlertModal = ({ visible, notifications, updateModalVisibility }) => {
  const localization = useLocalization();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mapRef = useRef();
  const [activeNotification, setActiveNotification] = useState(null);
  const [form] = Form.useForm();

  // set the active alert that will be displayed in the Modal
  useEffect(() => {
    if (notifications?.length && !activeNotification) {
      setActiveNotification({ ...notifications[0], index: 0, status: null });
    }
  }, [notifications]);

  // initial status check
  useEffect(() => {
    if (activeNotification?.id && !activeNotification?.status) {
      dispatch(handleCheckAlert());
    }
  }, [activeNotification]);

  const handleCheckAlert = () => async () => {
    const response = await dispatch(getDuressAlertStatus(activeNotification.id));
    if (response?.eventActions?.length) {
      setActiveNotification({
        ...activeNotification,
        status: response?.eventActions[response?.eventActions.length - 1],
        ignition: response?.ignition
      });
      form.setFieldsValue({
        note: response?.eventActions[response?.eventActions.length - 1]?.comment
      });
      return;
    }
    if (!activeNotification?.ignition && response?.ignition) {
      setActiveNotification({
        ...activeNotification,
        ignition: response?.ignition
      });
    }
  };

  useInterval(handleCheckAlert(), !activeNotification?.status ? 5000 : null);

  const getNextAndPrevAlarm = dir => {
    form.resetFields();
    if (dir === 'next') {
      const nextIndex = activeNotification?.index + 1;
      if (nextIndex < notifications.length) {
        setActiveNotification({ ...notifications[nextIndex], index: nextIndex, status: null });
      }
    }
    if (dir === 'prev') {
      const nextIndex = activeNotification?.index - 1;
      if (nextIndex >= 0) {
        setActiveNotification({ ...notifications[nextIndex], index: nextIndex, status: null });
      }
    }
  };

  const handleSave = () => {
    if (!activeNotification?.status) {
      const payload = form.getFieldValue('note');
      dispatch(acknowledgeDuressAlert(activeNotification.id, payload));
      updateModalVisibility(false);
      return;
    }

    dispatch(dismissDuressAlert(activeNotification?.id));
    updateModalVisibility(false);
  };

  const getCounter = () => {
    if (notifications?.length < 2) {
      return;
    }
    return ` (${activeNotification?.index + 1}/${notifications?.length})`;
  };

  const { mapCoordinates } = useMemo(() => {
    return locationHelpers.getCoordinates(activeNotification?.event?.GPS);
  }, [activeNotification]);

  const timeAt = useMemo(() => {
    return (
      activeNotification?.event?.timeAt &&
      format(new Date(activeNotification?.event?.timeAt), localization.formats.time.formats.dby_imp)
    );
  }, [activeNotification]);

  return (
    <Modal
      className={styles.modalContainer}
      title={
        <span>
          <ExclamationCircleFilled />
          {t('Alerts.DuressModal.Title')}
          {getCounter()}
        </span>
      }
      open={visible}
      width={1024}
      type="warning"
      wrapClassName={styles.duressAlertModal}
      maskClosable={false}
      footer={null}
      closable={false}
    >
      <div className={styles.infoContainer}>
        {notifications.length > 1 && (
          <Row>
            <div className={styles.navButtonsContainer}>
              <div className={styles.navButton} onClick={() => getNextAndPrevAlarm('prev')}>
                <ChevronLeft />
                <div className={styles.text}>{t('Alerts.DuressModal.PrevAlert').toUpperCase()}</div>
              </div>
              <div className={styles.navButton} onClick={() => getNextAndPrevAlarm('next')}>
                <div className={styles.text}>{t('Alerts.DuressModal.NextAlert').toUpperCase()}</div>
                <ChevronRight />
              </div>
            </div>
          </Row>
        )}
        <Row>
          <Col className={styles.leftContainer} span={12}>
            <InfoRow
              label={t('Alerts.DuressModal.Event')}
              value={
                activeNotification?.event?.alarmCode
                  ? t(`Alerts.DuressModal.${activeNotification.event.alarmCode.description}`, '-')
                  : '-'
              }
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.Vehicle')}
              value={duressHelpers.getVehicleDetails(activeNotification?.event?.vehicle)}
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.VehiclePhone')}
              value={duressHelpers.getVehiclePhoneDetails(activeNotification?.event?.vehicle, t)}
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.Device')}
              value={activeNotification?.event?.device?.name || ''}
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.Driver')}
              value={duressHelpers.getUserDetails(activeNotification?.event?.user)}
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.DriverPhone')}
              value={duressHelpers.getUserPhoneDetails(activeNotification?.event?.user, t)}
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.DateTime')}
              value={timeAt}
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.Address')}
              value={activeNotification?.event?.location || ''}
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.Location')}
              value={
                activeNotification?.event?.GPS
                  ? `${activeNotification?.event?.GPS.Lat}, ${activeNotification?.event?.GPS.Lng}`
                  : ''
              }
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.SatsHDOP')}
              value={duressHelpers.getSatsHDOP(activeNotification?.event?.GPS, t)}
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.Speed')}
              value={duressHelpers.getSpeed(activeNotification?.event?.GPS?.Spd, localization)}
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.Ignition')}
              value={t(`Alerts.DuressModal.${activeNotification?.ignition}`, '-')}
              styles={styles}
              py={'4px'}
            />
            <InfoRow
              label={t('Alerts.DuressModal.Type')}
              value={activeNotification?.event?.source}
              styles={styles}
              py={'4px'}
            />
            <div>
              <Form
                layout="vertical"
                form={form}
                initialValues={{ note: null }}
                onFinish={handleSave}
              >
                <Form.Item
                  className={styles.noteTextarea}
                  name="note"
                  label={duressHelpers.getNoteLabel(activeNotification, t, localization)}
                  labelAlign="left"
                  rules={[
                    {
                      required: !activeNotification?.status,
                      message: t('Alerts.DuressModal.NoteRequired'),
                      whitespace: true
                    }
                  ]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 3, maxRows: 6 }}
                    placeholder={t('Alerts.DuressModal.NotePlaceholder')}
                    disabled={activeNotification?.status}
                  />
                </Form.Item>

                <Button
                  type={!activeNotification?.status ? 'primary' : 'secondary'}
                  htmlType="submit"
                  id={BUTTON_IDS.duressActiveNotif}
                >
                  {t(
                    `Alerts.DuressModal.${!activeNotification?.status ? 'Acknowledge' : 'Dismiss'}`
                  )}
                </Button>
              </Form>
            </div>
          </Col>
          <Col span={12}>
            <div className={styles.mapContainer}>
              <Map
                ref={mapRef}
                mode={MapMode.Location}
                location={mapCoordinates}
                containerElement={<div style={{ height: `100%`, width: `100%` }} />}
                mapElement={<div style={{ height: `100%`, width: `100%` }} />}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
