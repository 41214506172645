import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
//components
import { ToastType } from 'components/notifications/toasts/Toast';
import { Input, Select, Button } from 'components/ant';
import { Form, Modal } from 'antd';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
//slices
import { useAllRegions } from 'features/regions/regionsSlice';
//constants
import { LABEL_ALIGN_FOR_INPUTS, getFormItemsConfigs } from './constants';
//helpers and methods
import { addContact, updateContact } from 'features/contacts';
import { openToast } from 'features/toasts/toastsSlice';
import { getTimezones } from './helpers';
// styles
import styles from './ContactFormModal.module.scss';
import { MainUSTimeZones } from 'features/regions/timezones';
import { BUTTON_IDS } from 'utils/globalConstants';

export const ContactFormModal = ({ company, isOpen, onCancel, editContactData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contactForm] = Form.useForm();
  const regionsList = useAllRegions();
  const currentCompany = company;
  const [currentTimezone, setCurrentTimezone] = useState('');
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const regions = useMemo(() => {
    if (currentCompany == null || regionsList?.length <= 0) return [];

    return regionsList.filter(r => r.code === currentCompany.country);
  }, [currentCompany, regionsList]);

  useEffect(() => {
    if (currentCompany == null || regions?.length <= 0) return;

    const currentRegion = regions.find(r => r.code === currentCompany.country);
    if (currentRegion) {
      const timezone = JSON.parse(currentRegion?.config).default_time_zone;
      setCurrentTimezone(timezone);
    }
  }, [currentCompany, regions]);

  useEffect(() => {
    handleResetContact();
    if (editContactData?.name) {
      setIsFormValid(true);
    }
  }, [editContactData]);

  const renderFormItems = formItems => {
    return formItems.map((formItem, formItemIndex) => {
      const { name, label, rules, childProp } = formItem;
      return (
        <Form.Item
          key={`${formItemIndex}-${name}`}
          name={name}
          labelAlign={LABEL_ALIGN_FOR_INPUTS}
          label={label}
          colon={false}
          rules={rules}
        >
          {renderFormItemChild(childProp)}
        </Form.Item>
      );
    });
  };

  const renderFormItemChild = childProp => {
    const { type, placeholder, data, mode } = childProp;
    switch (type) {
      case 'select':
        return <Select placeholder={placeholder} data={data} mode={mode}></Select>;
      default:
        return <Input placeholder={placeholder} />;
    }
  };

  const renderFooterContactFormModal = () => {
    return (
      <div className={styles.contactFormFooter}>
        <Button
          htmlType="submit"
          id={BUTTON_IDS.contactFormSave}
          type="primary"
          disabled={!isFormValid}
        >
          {t('Common.Save')}
        </Button>
        <Button type="secondary" id={BUTTON_IDS.contactFormReset} onClick={handleResetContact}>
          {t(`Common.Reset`)}
        </Button>
        <Button type="secondary" id={BUTTON_IDS.contactFormCancel} onClick={handleCancel}>
          {t(`Common.Cancel`)}
        </Button>
      </div>
    );
  };

  const listTimezones = useCallback(
    regions => {
      if (company?.country === 'US') {
        return Object.keys(MainUSTimeZones).map(k => {
          return {
            label: k,
            id: MainUSTimeZones[k]
          };
        });
      } else {
        return getTimezones(regions);
      }
    },
    [company]
  );

  const handleResetContact = () => {
    setIsFormDirty(false);
    setIsFormValid(false);
    contactForm.resetFields();
  };

  const handleCancel = () => {
    isFormDirty
      ? confirmationModal(
          t('WiFi.confirmTitle'),
          t('WiFi.confirmDescription'),
          t('Common.Modal.CancelChanges'),
          t('Common.Modal.Stay'),
          closeModal
        )
      : closeModal();
  };

  const closeModal = () => {
    setIsFormDirty(false);
    handleResetContact();
    onCancel();
  };

  const handleValuesChange = () => {
    const isValid = ['name'].every(value => !!contactForm.getFieldValue(value));
    setIsFormDirty(true);
    setIsFormValid(isValid);
  };

  const handleSave = async () => {
    const valuesFromForm = contactForm.getFieldsValue();
    const { mobileNotification, emailNotification, ...payload } = valuesFromForm;
    payload.mobileNotification = Array.isArray(mobileNotification)
      ? mobileNotification
      : mobileNotification && [mobileNotification];
    payload.emailNotification = Array.isArray(emailNotification)
      ? emailNotification
      : emailNotification && [emailNotification];
    payload.companyId = currentCompany?.id;
    try {
      const actionCreator = editContactData
        ? updateContact({
            id: editContactData.id,
            locationId: editContactData.entityId,
            body: payload
          })
        : addContact({ body: payload });
      const response = await dispatch(actionCreator);
      unwrapResult(response);

      dispatch(
        openToast({
          type: ToastType.Success,
          message: t(
            `Locations.Contacts.Form.ToastNotifications.Contact${
              editContactData ? 'Updated' : 'Added'
            }Success`,
            {
              name: payload?.name
            }
          )
        })
      );
      closeModal();
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(
            `Locations.Contacts.Form.ToastNotifications.Contact${
              editContactData ? 'Updated' : 'Added'
            }Error`,
            {
              name: payload?.name
            }
          )} ${err.message}`
        })
      );
    }
  };

  return (
    <Modal
      title={
        editContactData
          ? t('Locations.Contacts.Form.EditContactFormTitle', { name: editContactData.name })
          : t('Locations.View.AddNewContact')
      }
      visible={isOpen}
      onCancel={handleCancel}
      width={478}
      wrapClassName={styles.contactFormModal}
      footer={null}
    >
      <div className={styles.contactFormContainer}>
        <Form
          layout="vertical"
          form={contactForm}
          onValuesChange={handleValuesChange}
          name="ContactForm"
          onFinish={handleSave}
          initialValues={editContactData || { timeZone: currentTimezone }}
        >
          {renderFormItems(getFormItemsConfigs(t, regions, listTimezones))}
          {renderFooterContactFormModal()}
        </Form>
      </div>
    </Modal>
  );
};
