import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { TabNavLink } from 'components/nav/NavLinks';
import { useCompanies, useSubCompanies } from 'features/company/companySlice';
import { Toast } from 'react-bootstrap';
import { FleetsTable } from './FleetsTable';
import { ExportToExcel } from './ExportToExcel';
import { toLower, trim } from 'lodash';
import useDebounce from 'utils/hooks/useDebounce';
import {
  useGeofences,
  useIsFetching as useIsFetchingGeofence
} from 'features/geofences/geofencesSlice';
import {
  useFleets,
  useDeletedFleets,
  deleteFleetApi,
  restoreFleetApi
} from 'features/fleets/fleetsSlice';
import { useSelector } from 'react-redux';
import { useUserKey } from '../../../features/user/userSlice';
import { useTranslation } from 'react-i18next';
import { cache } from './CellRenderers';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { Can, entities } from 'features/permissions';
import { useHistory } from 'react-router';
import { Button, Space } from 'antd';
import { prepareDataForMultiselect } from 'utils/filters';
import { useLocalization } from 'features/localization/localizationSlice';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { sortStrings } from 'utils/strings';
import { BUTTON_IDS } from 'utils/globalConstants';

const Fleets = ({ toastMessage, setToastMessage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allFleets = useFleets();
  const allDeletedFleets = useDeletedFleets();
  const [allFleetwithGeofences, setAllFleetwithGeofences] = useState([]);
  const [allDeletedFleetWithGeofences, setAllDeletedFleetWithGeofences] = useState([]);
  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const [filterTab, setFilterTab] = useState(filterPath);
  const userKey = useUserKey();
  let { isFetching } = useSelector(state => state.fleets.meta);
  let { isFetchingDeletedFleet } = useSelector(state => state.deletedFleets.meta);
  const companies = useCompanies();
  const subCompanies = useSubCompanies();
  const [fleets, setFleets] = useState([]);
  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(trim(filterText), 300);
  const [tableRef, setTableRef] = useState(null);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const history = useHistory();
  const localization = useLocalization();
  const geofences = useGeofences();
  const isFetchingGeofence = useIsFetchingGeofence();

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('Fleets.Fleets')));
  }, [dispatch]);

  useEffect(() => {
    if (
      !isFetching &&
      !isFetchingDeletedFleet &&
      !isFetchingGeofence &&
      geofences &&
      allFleets &&
      allDeletedFleets
    ) {
      setAllFleetwithGeofences(enrichFleetWithGeofence(allFleets || []));
      setAllDeletedFleetWithGeofences(enrichFleetWithGeofence(allDeletedFleets || []));
    }
  }, [
    dispatch,
    geofences,
    allFleets,
    isFetchingGeofence,
    isFetchingDeletedFleet,
    isFetching,
    allDeletedFleets
  ]);

  const enrichFleetWithGeofence = sourceFleets => {
    return (sourceFleets || []).map(data => {
      let newFleet = { ...data };
      newFleet.geofences = geofences.filter(i => (i.fleets || []).some(k => k.id === data.id));
      return newFleet;
    });
  };

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
  }, [subCompanies, t]);

  useEffect(() => {
    let fleetsToFilter = allFleetwithGeofences;
    if (filterTab === 'deleted') {
      fleetsToFilter = allDeletedFleetWithGeofences;
    }
    setFleets(
      fleetsToFilter
        .filter(fleet => {
          let validFleet = true;

          // Filter out vehicles without id
          if (!fleet.id) {
            return false;
          }

          // Filter by search field
          if (debouncedSearchText) {
            validFleet =
              (validFleet &&
                [fleet.name, fleet.description].some(
                  value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1
                )) ||
              (fleet.colour
                ? toLower(fleet.colour).indexOf(toLower(debouncedSearchText)) > -1
                : 'status'.indexOf(toLower(debouncedSearchText)) > -1) ||
              toLower(fleet.iconType).indexOf(toLower(debouncedSearchText)) > -1;
          }
          // Filter by companies
          const checkedCompaniesIds = filterCompanies
            .filter(company => company.checked)
            .map(company => parseInt(company.id, 10));
          if (!(checkedCompaniesIds.indexOf(0) > -1)) {
            validFleet =
              validFleet &&
              fleet.company &&
              checkedCompaniesIds.indexOf(parseInt(fleet.company.id, 10)) > -1;
          }

          return validFleet;
        })
        .sort((a, b) => sortStrings(a?.name, b?.name))
    );
  }, [
    allFleetwithGeofences,
    filterText,
    filterCompanies,
    debouncedSearchText,
    filterTab,
    companies,
    allDeletedFleetWithGeofences
  ]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [fleets, tableRef]);

  const handleAction = actionObject => {
    if (actionObject.action === 'restore') {
      dispatch(restoreFleetApi(actionObject.data));
    }
  };

  const handleDeleteAction = data => () => {
    dispatch(deleteFleetApi(data));
  };

  return (
    <>
      <Toast
        show={toastMessage ? true : false}
        onClose={() => {
          setToastMessage(null);
        }}
        style={{ position: 'fixed', right: '1rem', zIndex: `${toastMessage ? '6' : '2'}` }}
      >
        <Toast.Header className="p-2">
          <strong className="mr-4">{toastMessage}</strong>
        </Toast.Header>
      </Toast>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div>
            <TabNavLink
              to="/settings/fleets"
              isActive={(match, location) => {
                return '/settings/fleets' === location.pathname;
              }}
              onClick={() => {
                setFilterTab('all');
              }}
            >
              {t('Common.All')}
            </TabNavLink>
            <TabNavLink
              exact
              to={`/settings/fleets/deleted`}
              onClick={() => {
                setFilterTab('deleted');
              }}
            >
              {t('Common.Deleted')}
            </TabNavLink>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Space size={16}>
              <Can everyEntity={[entities.FLEET_CREATE]}>
                <Button
                  type="primary"
                  size="large"
                  id={BUTTON_IDS.fleetsAddNew}
                  onClick={() => {
                    history.push(`/settings/fleets/newFleet`);
                  }}
                >
                  {t('Fleets.AddNewFleet')}
                </Button>
              </Can>
              <ExportToExcel data={fleets} companies={companies} localization={localization} />
            </Space>
          </div>
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} />
            <AntMultiselect
              title={
                filterCompanies?.some(value => !value.checked)
                  ? t('Common.Companies')
                  : t('Common.AllCompanies')
              }
              onFilter={v => setFilterCompanies(v)}
              data={filterCompanies}
            />
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {fleets && fleets.length > 0 ? fleets.filter(fleet => fleet.id).length : 0}{' '}
            {fleets.length === 1 ? t('Fleets.Fleet') : t('Fleets.Fleets')}
          </label>
        </div>
        <div style={{ flex: '1 0 0' }}>
          <FleetsTable
            fleets={fleets.filter(item => item.id)}
            companies={companies}
            userKey={userKey}
            isLoading={isFetching || isFetchingGeofence}
            typeOfEntityToDelete={t('Common.fleet')}
            setTableRef={setTableRef}
            handleAction={action => handleAction(action)}
            handleDeleteAction={handleDeleteAction}
            filterTab={filterTab}
          />
        </div>
      </ContainerPageWrapper>
    </>
  );
};

export default Fleets;
