import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Select, Space, Typography, Spin } from 'antd';
import { useDeviceModels } from 'features/device_models/deviceModelsSlice';
import { useCan } from 'features/permissions';
import { IQCameraMetaSelect, getIQCameraMetaConfig } from './IQCameraMetaSelect';
import { isIQCamModelId } from 'features/camera/CameraModelConfig';
import styles from '../DevicesBulkEdit.module.scss';
import { useDispatch } from 'react-redux';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { fetchIQCamerDevicesConfig } from 'features/company_config';

export const ModelSelectModal = ({ show, onOk, onCancel, deviceTypeId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useCurrentCompanyId();
  const can = useCan();
  const { isValidIQCameraMeta } = getIQCameraMetaConfig(can);

  const [value, setValue] = useState({ modelId: null, meta: {} });

  const deviceModels = useDeviceModels();
  const options = useMemo(() => {
    const filteredTypeList = deviceTypeId
      ? deviceModels?.list?.filter(model => String(model.deviceType.id) === String(deviceTypeId))
      : deviceModels?.list;
    return filteredTypeList.map(model => ({
      label: model.name.toLowerCase() === 'iqcamera' ? 'IQ Camera' : model.name,
      value: model.id
    }));
  }, [deviceTypeId, deviceModels]);

  const isIqCamera = useCallback(modelId => isIQCamModelId(modelId, deviceModels?.list), [
    deviceModels
  ]);

  return (
    <Modal
      bodyStyle={{
        minHeight: isIqCamera(value.modelId) ? 325 : 180,
        display: 'block'
      }}
      okText={t('Common.Save')}
      okButtonProps={{
        disabled: !value?.modelId || (isIqCamera(value.modelId) && !isValidIQCameraMeta(value.meta))
      }}
      cancelText={t('Common.Modal.Cancel')}
      title={t('Devices.BulkEdit.EditColumnTitle')}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      onOk={() => {
        onOk(value, ({ allFailed, devices }) => {
          if (!allFailed && isIqCamera(value.modelId)) {
            dispatch(
              fetchIQCamerDevicesConfig({
                companyId,
                deviceIds: devices.map(d => d.id),
                forceFetch: true
              })
            );
          }
        });
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Space direction="vertical" size={0} className={styles.entitySelect}>
        <Typography.Title level={5}>{t('Vehicles.Form.Model')}</Typography.Title>
        <Select
          placeholder={t('Vehicles.Form.ModelPlaceholder')}
          showSearch
          allowClear
          filterOption={(input, option) =>
            option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          }
          notFoundContent={
            !deviceModels?.status?.fetching || deviceModels?.status?.fetching === 1 ? (
              <Spin size="small" />
            ) : null
          }
          value={value.modelId}
          onChange={modelId =>
            setValue(v => ({
              ...v,
              modelId,
              meta: v.meta
            }))
          }
          options={options}
        />
      </Space>
      {isIqCamera(value.modelId) && (
        <IQCameraMetaSelect
          t={t}
          meta={value.meta}
          onMetaChange={meta => setValue(v => ({ ...v, meta }))}
        />
      )}
    </Modal>
  );
};
