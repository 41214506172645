/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Customer from "./Customer";
import JobAttachment from "./JobAttachment";
import JobAttribute from "./JobAttribute";
import JobItem from "./JobItem";
import Runsheet from "./Runsheet";
import Stop from "./Stop";

/**
 * The Job model module.
 * @module model/Job
 * @version 1.0
 */
class Job {
  /**
   * Constructs a new <code>Job</code>.
   * @alias module:model/Job
   */
  constructor() {
    Job.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Job</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Job} obj Optional instance to populate.
   * @return {module:model/Job} The populated <code>Job</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Job();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("companyId")) {
        obj["companyId"] = ApiClient.convertToType(data["companyId"], "Number");
      }
      if (data.hasOwnProperty("runsheet")) {
        obj["runsheet"] = Runsheet.constructFromObject(data["runsheet"]);
      }
      if (data.hasOwnProperty("seq")) {
        obj["seq"] = ApiClient.convertToType(data["seq"], "Number");
      }
      if (data.hasOwnProperty("stop")) {
        obj["stop"] = Stop.constructFromObject(data["stop"]);
      }
      if (data.hasOwnProperty("actualStop")) {
        obj["actualStop"] = Stop.constructFromObject(data["actualStop"]);
      }
      if (data.hasOwnProperty("customer")) {
        obj["customer"] = Customer.constructFromObject(data["customer"]);
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = ApiClient.convertToType(data["type"], "String");
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("startAt")) {
        obj["startAt"] = ApiClient.convertToType(data["startAt"], "Date");
      }
      if (data.hasOwnProperty("endAt")) {
        obj["endAt"] = ApiClient.convertToType(data["endAt"], "Date");
      }
      if (data.hasOwnProperty("actualStartAt")) {
        obj["actualStartAt"] = ApiClient.convertToType(
          data["actualStartAt"],
          "Date"
        );
      }
      if (data.hasOwnProperty("actualEndAt")) {
        obj["actualEndAt"] = ApiClient.convertToType(
          data["actualEndAt"],
          "Date"
        );
      }
      if (data.hasOwnProperty("instructions")) {
        obj["instructions"] = ApiClient.convertToType(
          data["instructions"],
          "String"
        );
      }
      if (data.hasOwnProperty("notes")) {
        obj["notes"] = ApiClient.convertToType(data["notes"], "String");
      }
      if (data.hasOwnProperty("amount")) {
        obj["amount"] = ApiClient.convertToType(data["amount"], "Number");
      }
      if (data.hasOwnProperty("orderNumber")) {
        obj["orderNumber"] = ApiClient.convertToType(
          data["orderNumber"],
          "String"
        );
      }
      if (data.hasOwnProperty("customerOrderNumber")) {
        obj["customerOrderNumber"] = ApiClient.convertToType(
          data["customerOrderNumber"],
          "String"
        );
      }
      if (data.hasOwnProperty("customerName")) {
        obj["customerName"] = ApiClient.convertToType(
          data["customerName"],
          "String"
        );
      }
      if (data.hasOwnProperty("contactName")) {
        obj["contactName"] = ApiClient.convertToType(
          data["contactName"],
          "String"
        );
      }
      if (data.hasOwnProperty("contactNumber")) {
        obj["contactNumber"] = ApiClient.convertToType(
          data["contactNumber"],
          "String"
        );
      }
      if (data.hasOwnProperty("externalId")) {
        obj["externalId"] = ApiClient.convertToType(
          data["externalId"],
          "String"
        );
      }
      if (data.hasOwnProperty("attributes")) {
        obj["attributes"] = ApiClient.convertToType(data["attributes"], [
          JobAttribute
        ]);
      }
      if (data.hasOwnProperty("jobItems")) {
        obj["jobItems"] = ApiClient.convertToType(data["jobItems"], [JobItem]);
      }
      if (data.hasOwnProperty("jobAttachments")) {
        obj["jobAttachments"] = ApiClient.convertToType(
          data["jobAttachments"],
          [JobAttachment]
        );
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Job.prototype["id"] = undefined;

/**
 * @member {Number} companyId
 */
Job.prototype["companyId"] = undefined;

/**
 * @member {module:model/Runsheet} runsheet
 */
Job.prototype["runsheet"] = undefined;

/**
 * @member {Number} seq
 */
Job.prototype["seq"] = undefined;

/**
 * @member {module:model/Stop} stop
 */
Job.prototype["stop"] = undefined;

/**
 * @member {module:model/Stop} actualStop
 */
Job.prototype["actualStop"] = undefined;

/**
 * @member {module:model/Customer} customer
 */
Job.prototype["customer"] = undefined;

/**
 * @member {module:model/Job.TypeEnum} type
 */
Job.prototype["type"] = undefined;

/**
 * @member {module:model/Job.StatusEnum} status
 */
Job.prototype["status"] = undefined;

/**
 * @member {Date} startAt
 */
Job.prototype["startAt"] = undefined;

/**
 * @member {Date} endAt
 */
Job.prototype["endAt"] = undefined;

/**
 * @member {Date} actualStartAt
 */
Job.prototype["actualStartAt"] = undefined;

/**
 * @member {Date} actualEndAt
 */
Job.prototype["actualEndAt"] = undefined;

/**
 * @member {String} instructions
 */
Job.prototype["instructions"] = undefined;

/**
 * @member {String} notes
 */
Job.prototype["notes"] = undefined;

/**
 * @member {Number} amount
 */
Job.prototype["amount"] = undefined;

/**
 * @member {String} orderNumber
 */
Job.prototype["orderNumber"] = undefined;

/**
 * @member {String} customerOrderNumber
 */
Job.prototype["customerOrderNumber"] = undefined;

/**
 * @member {String} customerName
 */
Job.prototype["customerName"] = undefined;

/**
 * @member {String} contactName
 */
Job.prototype["contactName"] = undefined;

/**
 * @member {String} contactNumber
 */
Job.prototype["contactNumber"] = undefined;

/**
 * @member {String} externalId
 */
Job.prototype["externalId"] = undefined;

/**
 * @member {Array.<module:model/JobAttribute>} attributes
 */
Job.prototype["attributes"] = undefined;

/**
 * @member {Array.<module:model/JobItem>} jobItems
 */
Job.prototype["jobItems"] = undefined;

/**
 * @member {Array.<module:model/JobAttachment>} jobAttachments
 */
Job.prototype["jobAttachments"] = undefined;

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
Job["TypeEnum"] = {
  /**
   * value: "PICKUP"
   * @const
   */
  PICKUP: "PICKUP",

  /**
   * value: "DELIVERY"
   * @const
   */
  DELIVERY: "DELIVERY",

  /**
   * value: "REST_BREAK"
   * @const
   */
  REST_BREAK: "REST_BREAK",

  /**
   * value: "FUEL_STOP"
   * @const
   */
  FUEL_STOP: "FUEL_STOP",

  /**
   * value: "TASK"
   * @const
   */
  TASK: "TASK",

  /**
   * value: "BANKING"
   * @const
   */
  BANKING: "BANKING",

  /**
   * value: "DEPOT"
   * @const
   */
  DEPOT: "DEPOT",

  /**
   * value: "CUSTOMER_FEEDBACK"
   * @const
   */
  CUSTOMER_FEEDBACK: "CUSTOMER_FEEDBACK",

  /**
   * value: "TIMER"
   * @const
   */
  TIMER: "TIMER",

  /**
   * value: "ADHOC"
   * @const
   */
  ADHOC: "ADHOC",

  /**
   * value: "WAYPOINT"
   * @const
   */
  WAYPOINT: "WAYPOINT",

  /**
   * value: "OTHER"
   * @const
   */
  OTHER: "OTHER"
};

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Job["StatusEnum"] = {
  /**
   * value: "CREATED"
   * @const
   */
  CREATED: "CREATED",

  /**
   * value: "STARTED"
   * @const
   */
  STARTED: "STARTED",

  /**
   * value: "ARRIVED"
   * @const
   */
  ARRIVED: "ARRIVED",

  /**
   * value: "HELD"
   * @const
   */
  HELD: "HELD",

  /**
   * value: "DEPARTED"
   * @const
   */
  DEPARTED: "DEPARTED",

  /**
   * value: "FINISHED"
   * @const
   */
  FINISHED: "FINISHED",

  /**
   * value: "COMPLETED"
   * @const
   */
  COMPLETED: "COMPLETED",

  /**
   * value: "CANCELLED"
   * @const
   */
  CANCELLED: "CANCELLED",

  /**
   * value: "UNABLE_TO_COMPLETE"
   * @const
   */
  UNABLE_TO_COMPLETE: "UNABLE_TO_COMPLETE",

  /**
   * value: "DELETED"
   * @const
   */
  DELETED: "DELETED"
};

export default Job;
