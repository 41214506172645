export const companyConfigSelectors = {
  carrierData: state => state.companyConfig.carrierData.items,
  fetchingCameraFields: companyId => state => state.companyConfig.meta[companyId]?.isFetching,
  isFetchingCarrierData: companyId => state =>
    state.companyConfig.carrierData.meta[companyId]?.isFetching,
  fetchingDriverManagementFields: companyId => state =>
    state.companyConfig.driverManagementFields.meta[companyId]?.isFetching,
  fetchingVehicleMaintenanceFields: companyId => state =>
    state.companyConfig.vehicleMaintenanceFields.meta[companyId]?.isFetching,
  getCameraFields: companyId => state => state.companyConfig.cameraFields.fields[companyId],
  getCarrierData: companyId => state => state.companyConfig.carrierData.fields[companyId],
  getDriverManagementFields: companyId => state =>
    state.companyConfig.driverManagementFields.fields[companyId],
  getVehicleMaintenanceFields: companyId => state =>
    state.companyConfig.vehicleMaintenanceFields.fields[companyId],
  isErrorCameraFields: companyId => state =>
    state.companyConfig.cameraFields.meta[companyId]?.error,
  isErrorCarrierData: companyId => state => state.companyConfig.carrierData.meta[companyId]?.error,
  isErrorDriverManagementFields: companyId => state =>
    state.companyConfig.driverManagementFields.meta[companyId]?.error,
  isErrorVehicleMaintenanceFields: companyId => state =>
    state.companyConfig.vehicleMaintenanceFields.meta[companyId]?.error,
  isSuccessCameraFields: companyId => state =>
    state.companyConfig.cameraFields.meta[companyId]?.success,
  isSuccessCarrierData: companyId => state =>
    state.companyConfig.carrierData.meta[companyId]?.success,
  isSuccessDriverManagementFields: companyId => state =>
    state.companyConfig.driverManagementFields.meta[companyId]?.success,
  isSuccessVehicleMaintenanceFields: companyId => state =>
    state.companyConfig.vehicleMaintenanceFields.meta[companyId]?.success,
  getVehicleLoginLimitConfigStatus: companyId => state =>
    state.companyConfig.vehicleLoginLimit.companies[companyId],
  updateVehicleLoginLimitConfigStatus: companyId => state =>
    state.companyConfig.vehicleLoginLimit.companies[companyId]?.['updateMeta'],
  getVehicleLoginLimitConfig: companyId => state =>
    state.companyConfig.vehicleLoginLimit.companies[companyId]?.config,

  fetchingMulticarrierFields: companyId => state =>
    state.companyConfig.multicarrierFields.meta[companyId]?.isFetching,
  getMulticarrierFields: companyId => state =>
    state.companyConfig.multicarrierFields.fields[companyId],
  isErrorMulticarrierFields: companyId => state =>
    state.companyConfig.multicarrierFields.meta[companyId]?.error,
  isSuccessMulticarrierFields: companyId => state =>
    state.companyConfig.multicarrierFields.meta[companyId]?.success,

  getIQCameraConfigStatus: companyId => state =>
    state.companyConfig.iqCameraConfig.companies[companyId],
  updateIQCameraConfigStatus: companyId => state =>
    state.companyConfig.iqCameraConfig.companies[companyId]?.['updateMeta'],
  getIQCameraConfig: companyId => state =>
    state.companyConfig.iqCameraConfig.companies[companyId]?.config,
  bulkUpdateIQCamerasStatus: companyId => state =>
    state.companyConfig.iqCameraDevicesConfig.companies[companyId]?.['updateMeta'],
  getIQCameraDevicesConfig: companyId => state =>
    state.companyConfig.iqCameraDevicesConfig.companies[companyId]?.devicesConfig,
  getIQCameraDevicesConfigStatus: companyId => state =>
    state.companyConfig.iqCameraDevicesConfig.companies[companyId],

  getWithinConfig: companyId => state => state.companyConfig.withinConfig.companies[companyId],
  isFetchingWithinConfig: companyId => state =>
    state.companyConfig.withinConfig.meta[companyId]?.isFetching,
  isErrorWithinConfig: companyId => state =>
    state.companyConfig.withinConfig.meta[companyId]?.error,
  isSuccessWithinConfig: companyId => state =>
    state.companyConfig.withinConfig.meta[companyId]?.success
};
