/* global google */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import closeSearchBar from 'static/images/icons/close-search-bar.svg';
import { FormControl } from 'react-bootstrap';

import {
  useCurrentCompany,
  useSubCompanyEntityConfig,
  CompanyConfigKey,
  CompanyConfigValue
} from 'features/company/companySlice';
import { useUserPreferences } from 'features/user/userPreferencesSlice';

import Map from 'components/map/Map';
import Autocomplete from 'components/form/autocomplete/Autocomplete';
import { GeoSuggest } from 'components/GeoSuggest';
import { GOOGLE_MAPS_API_KEY } from 'config';

import { getBoundsZoomLevel, getPointsZoomLevel } from 'utils/geo';

import styles from '../CardView/Cards/Cards.module.scss';
import { useLocalization } from 'features/localization/localizationSlice';
import { useGeofenceSuggests } from 'components/GeoSuggest/useGeofenceSuggests';
import { getGeofencePoints } from 'features/geofences/geofencesUtil';

export const MapCard = ({
  index,
  fleets,
  fleetId,
  devices,
  allDevices,
  searchText,
  onSearch,
  setSearchText,
  onFleetSelect,
  onMapTileMapUpdate,
  mapProps = {},
  onDeviceSelected,
  geofences = [],
  isLoading = false,
  props
}) => {
  const mapRef = useRef(null);
  const wrapperRef = useRef(null);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  const [infoWindowDeviceId, setInfoWindowDeviceId] = useState(null);
  const [zoom, setZoom] = useState(3);
  const [companyHasChanged, setCompanyHasChanged] = useState(false);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const currentCompany = useCurrentCompany();
  const nonBusinessCompanyConfig = useSubCompanyEntityConfig(
    currentCompany?.id,
    CompanyConfigKey.HideNonBusiness
  );
  const mapTilesCompany = JSON.parse(localStorage.getItem('mapTiles') || '{}').currentCompany;
  const { t } = useTranslation();
  const userPreferences = useUserPreferences();
  const localization = useLocalization();

  useEffect(() => {
    if (nonBusinessCompanyConfig) {
      const newState = devices?.filter(
        device => !(device.deviceStats?.attr === CompanyConfigValue.Private)
      );
      setFilteredDevices(newState);
    } else {
      setFilteredDevices(devices);
    }
  }, [devices]);

  useEffect(() => {
    if (mapProps.zoom && mapProps.center) {
      setZoom(mapProps.zoom);
      setSelectedLocation(mapProps.center);
    }
  }, [mapProps]);

  useEffect(() => {
    if (currentCompany.id !== mapTilesCompany?.id) {
      setCompanyHasChanged(true);
    }
  }, [currentCompany]);

  useEffect(() => {
    if (companyHasChanged) {
      setSelectedLocation(null);
      setCompanyHasChanged(false);
    }
  }, [fleets]);

  const setNewLocationFromSearch = (place, suggest) => {
    if (!suggest.gmaps) {
      setSelectedLocation({ lat: place.lat, lng: place.lng });
      if (suggest?.geofence) {
        const zoom = getPointsZoomLevel({
          points: getGeofencePoints(suggest.geofence, true),
          mapDim: {
            height: wrapperRef.current?.clientHeight,
            width: wrapperRef.current?.clientWidth
          }
        });
        setZoom(zoom);
        return onMapTileMapUpdate({
          index,
          center: { lat: place.lat, lng: place.lng },
          zoom,
          mapTypeId: mapRef.current.state.map.mapTypeId
        });
      }
    } else {
      const lat = suggest.gmaps.geometry?.location?.lat();
      const lng = suggest.gmaps.geometry?.location?.lng();
      const bounds = suggest.gmaps.geometry?.viewport;
      if (bounds) {
        const zoom = getBoundsZoomLevel(bounds, {
          height: wrapperRef.current?.clientHeight,
          width: wrapperRef.current?.clientWidth
        });
        setZoom(zoom);
        setSelectedLocation(place);
      } else {
        setSelectedLocation({ lat, lng });
      }
    }
    handleMapUpdate();
  };

  const handleKeyPress = e => {
    if (e.charCode === 13) {
      onSearch(index);
    }
  };

  const handleDropdownSelect = deviceId => {
    onSearch(index, deviceId);
  };

  const handleMapUpdate = () => {
    const { map } = mapRef.current.state;
    const center = map.getBounds() && map.getBounds().getCenter();
    const { zoom, mapTypeId } = map;
    if (zoom && center) {
      setSelectedLocation({ lat: center.lat(), lng: center.lng() });
      setZoom(zoom);
      onMapTileMapUpdate({ index, center, zoom, mapTypeId });
    }
  };

  const autocompleteData = allDevices
    .filter(
      device =>
        !(nonBusinessCompanyConfig && device.deviceStats?.attr === CompanyConfigValue.Private)
    )
    .filter(device => device.driverName !== t('Tracking.NotLoggedIn') || device.vehicleName)
    .filter(device => !fleetId || device.fleetId === parseInt(fleetId, 10))
    .map(device => {
      const item = {
        id: device.id
      };

      let vehicleName = (
        <>
          <span>{t('Vehicles.Vehicle')}: </span>-
        </>
      );
      if (device.vehicleName || device.name) {
        vehicleName = (
          <>
            <span>{t('Vehicles.Vehicle')}: </span>
            {device.vehicleName || device.name}
          </>
        );
        item.name = device.vehicleName || device.name;
      }
      let driverName = (
        <>
          <span>{t('Vehicles.View.Driver')}: </span>-
        </>
      );
      if (device.driverName && device.driverName !== t('Tracking.NotLoggedIn')) {
        driverName = (
          <>
            <span>{t('Vehicles.View.Driver')}: </span>
            {device.driverName}
          </>
        );
        item.name = `${item.name}|${device.driverName}`;
      }
      item.specialName = (
        <>
          <div>{vehicleName}</div>
          <div>{driverName}</div>
        </>
      );
      return item;
    });

  const { geoSuggestProps } = useGeofenceSuggests();

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        color: 'black',
        borderRadius: '5px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        transition: 'box-shadow .1s ease-in-out',
        '&:hover': {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.35)'
        },
        overflow: 'hidden',
        position: 'relative'
      }}
      {...props}
    >
      {!showDetails && (
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            zIndex: 1,
            background: '#fff',
            borderRadius: '2px',
            width: '40px',
            height: '40px',
            cursor: 'pointer'
          }}
          onClick={() => setShowDetails(true)}
        >
          <FontAwesomeIcon className={styles.searchIcon} icon={faSearch} />
        </div>
      )}
      {showDetails && (
        <div
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            zIndex: 1,
            background: '#fff',
            borderRadius: '2px',
            width: '250px'
          }}
        >
          <GeoSuggest
            inputClassName={styles.inputSearchLocation}
            className={styles.searchLocation}
            placeholder={t('Tracking.SearchByAddressOrGeofence')}
            onSuggestSelect={suggest => {
              suggest && setNewLocationFromSearch(suggest.location, suggest);
            }}
            {...geoSuggestProps}
          />
          <div className={styles.separator} />
          <FormControl
            as="select"
            value={fleetId}
            className={styles.searchFleet}
            onChange={e => onFleetSelect(index, e.target.value)}
          >
            <option value="">{t('Common.AllFleets')}</option>
            {fleets.map((fleet, index) => (
              <option key={`fleet_${index}`} value={fleet.id}>
                {fleet.name}
              </option>
            ))}
          </FormControl>
          <Autocomplete
            styles={styles}
            placeholder={t('Tracking.SearchByDriverVehicle')}
            searchText={searchText}
            handleKeyPress={handleKeyPress}
            handleOnChange={e => setSearchText(index, e.target.value.toLowerCase())}
            handleDropdownSelect={handleDropdownSelect}
            data={autocompleteData}
          />
          <div className={styles.hideSearchBar} onClick={() => setShowDetails(false)}>
            <i
              style={{
                content: `url(${closeSearchBar})`
              }}
            />
            <span>{t('Tracking.HideSearchBar')}</span>
          </div>
        </div>
      )}
      <div
        ref={wrapperRef}
        style={{
          height: '100%'
        }}
      >
        <Map
          ref={mapRef}
          devices={filteredDevices}
          onDeviceSelected={onDeviceSelected}
          enableVehicleClustering={userPreferences?.clustering}
          geofences={geofences}
          isLoading={isLoading}
          mapOptions={{
            mapTypeControl: true,
            mapTypeControlOptions: {
              position: google.maps.ControlPosition.TOP_RIGHT,
              style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
            },
            zoomControl: true,
            zoomControlOptions: {
              position: google.maps.ControlPosition.RIGHT_BOTTOM,
              style: google.maps.ZoomControlStyle.SMALL
            },
            streetViewControl: false,
            streetViewControlOptions: {
              position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            fullscreenControl: true,
            fullscreenControlOptions: {
              position: google.maps.ControlPosition.TOP_RIGHT
            },
            scaleControl: false,
            center:
              (selectedLocation && {
                lat: selectedLocation.lat,
                lng: selectedLocation.lng
              }) ||
              localization.formats.geocode,
            zoom: zoom || null,
            mapTypeId: (mapProps && mapProps.mapTypeId) || 'roadmap'
          }}
          clickedDevice={infoWindowDeviceId}
          onDeviceClicked={deviceId => {
            setInfoWindowDeviceId(deviceId);
          }}
          onZoomChanged={handleMapUpdate}
          onDragEnd={handleMapUpdate}
          onMapTypeIdChanged={() =>
            onMapTileMapUpdate({ index, mapTypeId: mapRef.current.state.map.mapTypeId })
          }
          setMapCenter={setSelectedLocation}
          setZoomLevel={setZoom}
          showInfoWindowActions={false}
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%`, width: `100%` }} />}
          mapElement={<div style={{ height: `100%`, width: `100%` }} />}
        />
      </div>
    </div>
  );
};

export const SortableMapCard = ({ setMapTileStates, mapTileStates, cardId }) => (
  <div
    style={{
      p: 3,
      minWidth: '50%',
      flex: '1'
    }}
  >
    <MapCard setMapTileStates={setMapTileStates} mapTileStates={mapTileStates} cardId={cardId} />
  </div>
);
