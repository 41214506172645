import React from 'react';

import { Table as AntTable } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import './Table.scss';

export const Table = props => {
  const style = props.columnsWidth ? { width: props.columnsWidth } : null;

  return <AntTable sortDirections={['ascend', 'descend', 'ascend']} style={style} {...props} />;
};

export const filterData = data => formatter => {
  return [...new Set(data.map(formatter))].map(item => ({
    text: item,
    value: item
  }));
};

export const ExpandIcon = ({ expanded, onExpand, record }) => {
  return expanded ? (
    <UpOutlined
      onClick={e => {
        onExpand(record, e);
        e.stopPropagation();
      }}
    />
  ) : (
    <DownOutlined
      onClick={e => {
        onExpand(record, e);
        e.stopPropagation();
      }}
    />
  );
};
