import React, { useCallback, useEffect, useState } from 'react';
import { NearestSearchPane } from './NearestSearchPane';
import { NearestResultPane } from './NearestResultPane';
import { useMsgClient } from 'utils/hooks/msgChannel';
import { MsgType } from './constants';

const Mode = {
  SearchPane: 0,
  ResultPane: 1
};

export function NearestPane({
  mode,
  onModeChange,
  msgChannel,
  trackingDevices,
  fleets,
  handleFilteredFleetsChange,
  ...props
}) {
  const [nearestPaneMode, setNearestPaneMode] = useState(Mode.SearchPane);
  const [searchResult, setSearchResult] = useState(null);
  const msgClient = useMsgClient(msgChannel);
  const [centerLatLng, setCenterLatLng] = useState(null);

  const reset = useCallback(() => {
    setNearestPaneMode(Mode.SearchPane);
    setSearchResult(null);
    setCenterLatLng(null);
  }, []);

  const handleMessaging = useCallback(
    msg => {
      if (msg.type === MsgType.SearchResult) {
        setNearestPaneMode(Mode.ResultPane);
        setSearchResult(msg.value);
      } else if (msg.type === MsgType.AddressUpdated) {
        setCenterLatLng(msg.value.latLng || msg.value);
      } else if (msg.type === MsgType.Reset) {
        reset();
      }
    },
    [msgClient, reset]
  );

  const handleReset = useCallback(() => {
    reset();
    msgClient.sendMsg({
      type: MsgType.Reset
    });
  }, [reset, msgClient]);

  useEffect(() => {
    if (msgClient != null) {
      msgClient.addOnMessageEventListener(handleMessaging);
    }
  }, [msgClient, handleMessaging]);

  //return (<NearestResultPane devices={searchResult} center={centerLatLng} trackingDevices={trackingDevices}/>);

  if (nearestPaneMode === Mode.SearchPane) {
    return (
      <NearestSearchPane
        mode={mode}
        onModeChange={onModeChange}
        msgChannel={msgChannel}
        trackingDevices={trackingDevices}
        fleets={fleets}
        handleFilteredFleetsChange={handleFilteredFleetsChange}
      />
    );
  } else {
    return (
      <NearestResultPane
        devices={searchResult}
        centerLatLng={centerLatLng}
        onReset={handleReset}
        msgChannel={msgChannel}
        fleets={fleets}
        handleFilteredFleetsChange={handleFilteredFleetsChange}
      />
    );
  }
}
