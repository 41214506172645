//TODO: change imports from entire app for permissions constants to be on one line
//import {services, entities, companyFeatures, FeatureFlag } from 'features/permissions';
export const entities = {
  AGREEMENT: 'AGREEMENT',
  AGREEMENT_AUDIT: 'AGREEMENT_AUDIT',
  AGREEMENT_CREATE: 'AGREEMENT_CREATE',
  AGREEMENT_DESTROY: 'AGREEMENT_DESTROY',
  AGREEMENT_UPDATE: 'AGREEMENT_UPDATE',
  AGREEMENT_VIEW: 'AGREEMENT_VIEW',
  ANNOUNCEMENT: 'ANNOUNCEMENT',
  ANNOUNCEMENT_AUDIT: 'ANNOUNCEMENT_AUDIT',
  ANNOUNCEMENT_CREATE: 'ANNOUNCEMENT_CREATE',
  ANNOUNCEMENT_DESTROY: 'ANNOUNCEMENT_DESTROY',
  ANNOUNCEMENT_UPDATE: 'ANNOUNCEMENT_UPDATE',
  ANNOUNCEMENT_VIEW: 'ANNOUNCEMENT_VIEW',
  B2BCONFIGURATION: 'B2BCONFIGURATION',
  B2BCONFIGURATION_AUDIT: 'B2BCONFIGURATION_AUDIT',
  B2BCONFIGURATION_CREATE: 'B2BCONFIGURATION_CREATE',
  B2BCONFIGURATION_DESTROY: 'B2BCONFIGURATION_DESTROY',
  B2BCONFIGURATION_UPDATE: 'B2BCONFIGURATION_UPDATE',
  B2BCONFIGURATION_VIEW: 'B2BCONFIGURATION_VIEW',
  BULKIMPORT: 'BULKIMPORT',
  COMPANYALERT: 'COMPANYALERT',
  COMPANYALERT_AUDIT: 'COMPANYALERT_AUDIT',
  COMPANYALERT_COPY: 'COMPANYALERT_COPY',
  COMPANYALERT_CREATE: 'COMPANYALERT_CREATE',
  COMPANYALERT_DESTROY: 'COMPANYALERT_DESTROY',
  COMPANYALERT_UPDATE: 'COMPANYALERT_UPDATE',
  COMPANYALERT_VIEW: 'COMPANYALERT_VIEW',
  COMPANY: 'COMPANY',
  COMPANY_AUDIT: 'COMPANY_AUDIT',
  COMPANYCONFIG: 'COMPANYCONFIG',
  COMPANYCONFIG_AUDIT: 'COMPANYCONFIG_AUDIT',
  COMPANYCONFIG_CREATE: 'COMPANYCONFIG_CREATE',
  COMPANYCONFIG_DESTROY: 'COMPANYCONFIG_DESTROY',
  COMPANYCONFIG_UPDATE: 'COMPANYCONFIG_UPDATE',
  COMPANYCONFIG_VIEW: 'COMPANYCONFIG_VIEW',
  COMPANY_CREATE: 'COMPANY_CREATE',
  COMPANY_RESTORE: 'COMPANY_RESTORE',
  COMPANY_UPDATE: 'COMPANY_UPDATE',
  COMPANY_VIEW: 'COMPANY_VIEW',
  SUBCOMPANY: 'SUBCOMPANY',
  SUBCOMPANY_AUDIT: 'SUBCOMPANY_AUDIT',
  SUBCOMPANY_CREATE: 'SUBCOMPANY_CREATE',
  SUBCOMPANY_RESTORE: 'SUBCOMPANY_RESTORE',
  SUBCOMPANY_UPDATE: 'SUBCOMPANY_UPDATE',
  SUBCOMPANY_VIEW: 'SUBCOMPANY_VIEW',
  SUBCOMPANY_DESTROY: 'SUBCOMPANY_DESTROY',
  CONTACT: 'CONTACT',
  CONTACT_AUDIT: 'CONTACT_AUDIT',
  CONTACT_CREATE: 'CONTACT_CREATE',
  CONTACT_DESTROY: 'CONTACT_DESTROY',
  CONTACT_RESTORE: 'CONTACT_RESTORE',
  CONTACT_UPDATE: 'CONTACT_UPDATE',
  CONTACT_VIEW: 'CONTACT_VIEW',
  CUSTOMER: 'CUSTOMER',
  CUSTOMER_AUDIT: 'CUSTOMER_AUDIT',
  CUSTOMER_CREATE: 'CUSTOMER_CREATE',
  CUSTOMER_DESTROY: 'CUSTOMER_DESTROY',
  CUSTOMER_RESTORE: 'CUSTOMER_RESTORE',
  CUSTOMER_UPDATE: 'CUSTOMER_UPDATE',
  CUSTOMER_VIEW: 'CUSTOMER_VIEW',
  DEVICE: 'DEVICE',
  DEVICE_AUDIT: 'DEVICE_AUDIT',
  DEVICE_COPY: 'DEVICE_COPY',
  DEVICE_CREATE: 'DEVICE_CREATE',
  DEVICE_DESTROY: 'DEVICE_DESTROY',
  DEVICE_DISSOCIATE: 'DEVICE_DISSOCIATE',
  DEVICE_RESTORE: 'DEVICE_RESTORE',
  DEVICE_UPDATE: 'DEVICE_UPDATE',
  DEVICEVERSIONMANAGEMENT: 'DEVICEVERSIONMANAGEMENT',
  DEVICE_VIEW: 'DEVICE_VIEW',
  DOCUMENT: 'DOCUMENT',
  DOCUMENT_AUDIT: 'DOCUMENT_AUDIT',
  DOCUMENT_CREATE: 'DOCUMENT_CREATE',
  DOCUMENT_DESTROY: 'DOCUMENT_DESTROY',
  DOCUMENT_UPDATE: 'DOCUMENT_UPDATE',
  DOCUMENT_VIEW: 'DOCUMENT_VIEW',
  DRIVERMANAGEMENTSCHEDULE: 'DRIVERMANAGEMENTSCHEDULE',
  DRIVERMANAGEMENTSCHEDULE_AUDIT: 'DRIVERMANAGEMENTSCHEDULE_AUDIT',
  DRIVERMANAGEMENTSCHEDULE_CREATE: 'DRIVERMANAGEMENTSCHEDULE_CREATE',
  DRIVERMANAGEMENTSCHEDULE_DESTROY: 'DRIVERMANAGEMENTSCHEDULE_DESTROY',
  DRIVERMANAGEMENTSCHEDULE_UPDATE: 'DRIVERMANAGEMENTSCHEDULE_UPDATE',
  DRIVERMANAGEMENTSCHEDULE_VIEW: 'DRIVERMANAGEMENTSCHEDULE_VIEW',
  DRIVERMANAGEMENTSCHEDULE_RESTORE: 'DRIVERMANAGEMENTSCHEDULE_RESTORE',
  DRIVERMANAGEMENTTYPE: 'DRIVERMANAGEMENTTYPE',
  DRIVERMANAGEMENTTYPE_AUDIT: 'DRIVERMANAGEMENTTYPE_AUDIT',
  DRIVERMANAGEMENTTYPE_CREATE: 'DRIVERMANAGEMENTTYPE_CREATE',
  DRIVERMANAGEMENTTYPE_DESTROY: 'DRIVERMANAGEMENTTYPE_DESTROY',
  DRIVERMANAGEMENTTYPE_RESTORE: 'DRIVERMANAGEMENTTYPE_RESTORE',
  DRIVERMANAGEMENTTYPE_UPDATE: 'DRIVERMANAGEMENTTYPE_UPDATE',
  DRIVERMANAGEMENTTYPE_VIEW: 'DRIVERMANAGEMENTTYPE_VIEW',
  DRIVERPORTAL: 'DRIVERPORTAL',
  DRIVERPORTAL_CREATE: 'DRIVERPORTAL_CREATE',
  DRIVERPORTAL_UPDATE: 'DRIVERPORTAL_UPDATE',
  ENGINEPROFILE: 'ENGINEPROFILE',
  ENGINEPROFILE_AUDIT: 'ENGINEPROFILE_AUDIT',
  ENGINEPROFILE_CREATE: 'ENGINEPROFILE_CREATE',
  ENGINEPROFILE_DESTROY: 'ENGINEPROFILE_DESTROY',
  ENGINEPROFILE_UPDATE: 'ENGINEPROFILE_UPDATE',
  ENGINEPROFILE_VIEW: 'ENGINEPROFILE_VIEW',
  FLEET: 'FLEET',
  FLEET_AUDIT: 'FLEET_AUDIT',
  FLEET_CREATE: 'FLEET_CREATE',
  FLEET_DESTROY: 'FLEET_DESTROY',
  FLEET_RESTORE: 'FLEET_RESTORE',
  FLEET_UPDATE: 'FLEET_UPDATE',
  FLEET_VIEW: 'FLEET_VIEW',
  FOLDER: 'FOLDER',
  FOLDER_AUDIT: 'FOLDER_AUDIT',
  FOLDER_CREATE: 'FOLDER_CREATE',
  FOLDER_DESTROY: 'FOLDER_DESTROY',
  FOLDER_UPDATE: 'FOLDER_UPDATE',
  FORM: 'FORM',
  FORM_AUDIT: 'FORM_AUDIT',
  FORM_COPY: 'FORM_COPY',
  FORM_CREATE: 'FORM_CREATE',
  FORM_DESTROY: 'FORM_DESTROY',
  FORM_UPDATE: 'FORM_UPDATE',
  FORM_VIEW: 'FORM_VIEW',
  GEOFENCE: 'GEOFENCE',
  GEOFENCE_AUDIT: 'GEOFENCE_AUDIT',
  GEOFENCE_CREATE: 'GEOFENCE_CREATE',
  GEOFENCE_DESTROY: 'GEOFENCE_DESTROY',
  GEOFENCE_RESTORE: 'GEOFENCE_RESTORE',
  GEOFENCE_UPDATE: 'GEOFENCE_UPDATE',
  GEOFENCE_VIEW: 'GEOFENCE_VIEW',
  GPIO_ACCESS: 'GPIO_ACCESS',
  GPIO_AUDIT: 'GPIO_AUDIT',
  GPIO_UPDATE: 'GPIO_UPDATE',
  GPIO_VIEW: 'GPIO_VIEW',
  JOB: 'JOB',
  JOB_AUDIT: 'JOB_AUDIT',
  JOB_SYNC: 'JOB_SYNC',
  JOB_VIEW: 'JOB_VIEW',
  JOB_CREATE: 'JOB_CREATE',
  JOB_UPDATE: 'JOB_UPDATE',
  JOB_DESTROY: 'JOB_DESTROY',
  KONETIK_VIEW: 'KONETIK_VIEW',
  KONETIK_UPDATE: 'KONETIK_UPDATE',
  LOCATION: 'LOCATION',
  LOCATION_AUDIT: 'LOCATION_AUDIT',
  LOCATION_CREATE: 'LOCATION_CREATE',
  LOCATION_DESTROY: 'LOCATION_DESTROY',
  LOCATION_RESTORE: 'LOCATION_RESTORE',
  LOCATION_SYNC: 'LOCATION_SYNC',
  LOCATION_UPDATE: 'LOCATION_UPDATE',
  LOCATION_VIEW: 'LOCATION_VIEW',
  MESSAGE: 'MESSAGE',
  MESSAGE_AUDIT: 'MESSAGE_AUDIT',
  MESSAGE_CREATE: 'MESSAGE_CREATE',
  MESSAGE_DESTROY: 'MESSAGE_DESTROY',
  MESSAGETEMPLATE: 'MESSAGETEMPLATE',
  MESSAGETEMPLATE_AUDIT: 'MESSAGETEMPLATE_AUDIT',
  MESSAGETEMPLATE_CREATE: 'MESSAGETEMPLATE_CREATE',
  MESSAGETEMPLATE_DESTROY: 'MESSAGETEMPLATE_DESTROY',
  MESSAGETEMPLATE_UPDATE: 'MESSAGETEMPLATE_UPDATE',
  MESSAGETEMPLATE_VIEW: 'MESSAGETEMPLATE_VIEW',
  MESSAGE_UPDATE: 'MESSAGE_UPDATE',
  MESSAGE_VIEW: 'MESSAGE_VIEW',
  ODOMETERSOURCE_UPDATE: 'ODOMETERSOURCE_UPDATE',
  PRETRIPCHECKLIST: 'PRETRIPCHECKLIST',
  PRETRIPCHECKLIST_AUDIT: 'PRETRIPCHECKLIST_AUDIT',
  PRETRIPCHECKLIST_COPY: 'PRETRIPCHECKLIST_COPY',
  PRETRIPCHECKLIST_CREATE: 'PRETRIPCHECKLIST_CREATE',
  PRETRIPCHECKLIST_DESTROY: 'PRETRIPCHECKLIST_DESTROY',
  PRETRIPCHECKLIST_UPDATE: 'PRETRIPCHECKLIST_UPDATE',
  PRETRIPCHECKLIST_VIEW: 'PRETRIPCHECKLIST_VIEW',
  QUICKJOBSCONFIG: 'QUICKJOBSCONFIG',
  QUICKJOBSCONFIG_AUDIT: 'QUICKJOBSCONFIG_AUDIT',
  QUICKJOBSCONFIG_CREATE: 'QUICKJOBSCONFIG_CREATE',
  QUICKJOBSCONFIG_DESTROY: 'QUICKJOBSCONFIG_DESTROY',
  QUICKJOBSCONFIG_UPDATE: 'QUICKJOBSCONFIG_UPDATE',
  QUICKJOBSCONFIG_VIEW: 'QUICKJOBSCONFIG_VIEW',
  REPORTSCHEDULE: 'REPORTSCHEDULE',
  REPORTSCHEDULE_AUDIT: 'REPORTSCHEDULE_AUDIT',
  REPORTSCHEDULE_CREATE: 'REPORTSCHEDULE_CREATE',
  REPORTSCHEDULE_DESTROY: 'REPORTSCHEDULE_DESTROY',
  REPORTSCHEDULE_UPDATE: 'REPORTSCHEDULE_UPDATE',
  REPORTSCHEDULE_VIEW: 'REPORTSCHEDULE_VIEW',
  ROLE: 'ROLE',
  ROLE_AUDIT: 'ROLE_AUDIT',
  ROLE_COPY: 'ROLE_COPY',
  ROLE_CREATE: 'ROLE_CREATE',
  ROLE_DESTROY: 'ROLE_DESTROY',
  ROLE_UPDATE: 'ROLE_UPDATE',
  ROLE_VIEW: 'ROLE_VIEW',
  RUNSHEET: 'RUNSHEET',
  RUNSHEET_AUDIT: 'RUNSHEET_AUDIT',
  RUNSHEET_DESTROY: 'RUNSHEET_DESTROY',
  RUNSHEET_SYNC: 'RUNSHEET_SYNC',
  RUNSHEET_VIEW: 'RUNSHEET_VIEW',
  RUNSHEET_CREATE: 'RUNSHEET_CREATE',
  RUNSHEET_UPDATE: 'RUNSHEET_UPDATE',
  RUNSHEET_COPY: 'RUNSHEET_COPY',
  SCORECARD: 'SCORECARD',
  SCORECARD_AUDIT: 'SCORECARD_AUDIT',
  SCORECARD_UPDATE: 'SCORECARD_UPDATE',
  SCORECARDEVENT_UPDATE: 'SCORECARDEVENT_UPDATE',
  SCREEN: 'SCREEN',
  SCREEN_AUDIT: 'SCREEN_AUDIT',
  SCREEN_CREATE: 'SCREEN_CREATE',
  SCREEN_DESTROY: 'SCREEN_DESTROY',
  SCREEN_UPDATE: 'SCREEN_UPDATE',
  SCREEN_VIEW: 'SCREEN_VIEW',
  SHIFT: 'SHIFT',
  SHIFT_AUDIT: 'SHIFT_AUDIT',
  SHIFT_CREATE: 'SHIFT_CREATE',
  SHIFT_DESTROY: 'SHIFT_DESTROY',
  SHIFT_RESTORE: 'SHIFT_RESTORE',
  SHIFT_UPDATE: 'SHIFT_UPDATE',
  SHIFT_VIEW: 'SHIFT_VIEW',
  SMARTJOBSCODE: 'SMARTJOBSCODE',
  SMARTJOBSCODE_AUDIT: 'SMARTJOBSCODE_AUDIT',
  SMARTJOBSCODE_CREATE: 'SMARTJOBSCODE_CREATE',
  SMARTJOBSCODE_DESTROY: 'SMARTJOBSCODE_DESTROY',
  SMARTJOBSCODE_UPDATE: 'SMARTJOBSCODE_UPDATE',
  SMARTJOBSCODE_VIEW: 'SMARTJOBSCODE_VIEW',
  SUBSCRIPTION: 'SUBSCRIPTION',
  SUBSCRIPTION_AUDIT: 'SUBSCRIPTION_AUDIT',
  SUBSCRIPTION_CREATE: 'SUBSCRIPTION_CREATE',
  SUBSCRIPTION_DESTROY: 'SUBSCRIPTION_DESTROY',
  SUBSCRIPTIONPACK: 'SUBSCRIPTIONPACK',
  SUBSCRIPTIONPACK_AUDIT: 'SUBSCRIPTIONPACK_AUDIT',
  SUBSCRIPTIONPACK_VIEW: 'SUBSCRIPTIONPACK_VIEW',
  SUBSCRIPTION_UPDATE: 'SUBSCRIPTION_UPDATE',
  SUBSCRIPTION_VIEW: 'SUBSCRIPTION_VIEW',
  SUPPORTCENTER: 'SUPPORTCENTER',
  TRIP: 'TRIP',
  TRIP_AUDIT: 'TRIP_AUDIT',
  TRIP_CREATE: 'TRIP_CREATE',
  TRIP_DESTROY: 'TRIP_DESTROY',
  TRIP_EDIT_PURPOSE: 'TRIP_EDIT_PURPOSE',
  TRIP_PURPOSE_ACCESS: 'TRIP_PURPOSE_ACCESS',
  TRIP_PURPOSE_AUDIT: 'TRIP_PURPOSE_AUDIT',
  TRIP_RESTORE: 'TRIP_RESTORE',
  TRIP_UPDATE: 'TRIP_UPDATE',
  TRIP_VIEW: 'TRIP_VIEW',
  TRIP_VIEW_PRIVATE: 'TRIP_VIEW_PRIVATE',
  USER: 'USER',
  USER_AUDIT: 'USER_AUDIT',
  USER_CREATE: 'USER_CREATE',
  USER_DESTROY: 'USER_DESTROY',
  USER_RESTORE: 'USER_RESTORE',
  USER_SYNC: 'USER_SYNC',
  USER_UPDATE: 'USER_UPDATE',
  USER_VIEW: 'USER_VIEW',
  VEHICLE: 'VEHICLE',
  VEHICLE_AUDIT: 'VEHICLE_AUDIT',
  VEHICLE_CREATE: 'VEHICLE_CREATE',
  VEHICLE_SHARE: 'VEHICLE_SHARE',
  VEHICLE_DESTROY: 'VEHICLE_DESTROY',
  VEHICLEMAINTENANCESCHEDULE: 'VEHICLEMAINTENANCESCHEDULE',
  VEHICLEMAINTENANCESCHEDULE_AUDIT: 'VEHICLEMAINTENANCESCHEDULE_AUDIT',
  VEHICLEMAINTENANCESCHEDULE_CREATE: 'VEHICLEMAINTENANCESCHEDULE_CREATE',
  VEHICLEMAINTENANCESCHEDULE_DESTROY: 'VEHICLEMAINTENANCESCHEDULE_DESTROY',
  VEHICLEMAINTENANCESCHEDULE_UPDATE: 'VEHICLEMAINTENANCESCHEDULE_UPDATE',
  VEHICLEMAINTENANCESCHEDULE_VIEW: 'VEHICLEMAINTENANCESCHEDULE_VIEW',
  VEHICLEMAINTENANCESCHEDULE_RESTORE: 'VEHICLEMAINTENANCESCHEDULE_RESTORE',
  VEHICLEMAINTENANCETYPE: 'VEHICLEMAINTENANCETYPE',
  VEHICLEMAINTENANCETYPE_AUDIT: 'VEHICLEMAINTENANCETYPE_AUDIT',
  VEHICLEMAINTENANCETYPE_CREATE: 'VEHICLEMAINTENANCETYPE_CREATE',
  VEHICLEMAINTENANCETYPE_DESTROY: 'VEHICLEMAINTENANCETYPE_DESTROY',
  VEHICLEMAINTENANCETYPE_UPDATE: 'VEHICLEMAINTENANCETYPE_UPDATE',
  VEHICLEMAINTENANCETYPE_VIEW: 'VEHICLEMAINTENANCETYPE_VIEW',
  VEHICLEMAINTENANCETYPE_RESTORE: 'VEHICLEMAINTENANCETYPE_RESTORE',
  VEHICLE_RESTORE: 'VEHICLE_RESTORE',
  VEHICLE_SYNC: 'VEHICLE_SYNC',
  VEHICLETYPE: 'VEHICLETYPE',
  VEHICLETYPE_AUDIT: 'VEHICLETYPE_AUDIT',
  VEHICLETYPE_CREATE: 'VEHICLETYPE_CREATE',
  VEHICLETYPE_DESTROY: 'VEHICLETYPE_DESTROY',
  VEHICLETYPE_RESTORE: 'VEHICLETYPE_RESTORE',
  VEHICLETYPE_UPDATE: 'VEHICLETYPE_UPDATE',
  VEHICLETYPE_VIEW: 'VEHICLETYPE_VIEW',
  VEHICLETYPE_COPY: 'VEHICLETYPE_COPY',
  VEHICLE_UPDATE: 'VEHICLE_UPDATE',
  VEHICLE_VIEW: 'VEHICLE_VIEW',
  VIDEO: 'VIDEO',
  VIDEO_AUDIT: 'VIDEO_AUDIT',
  VIDEO_CREATE: 'VIDEO_CREATE',
  VIDEO_VIEW: 'VIDEO_VIEW',
  WIFI: 'WIFI',
  WIFI_AUDIT: 'WIFI_AUDIT',
  WIFI_CREATE: 'WIFI_CREATE',
  WIFI_DESTROY: 'WIFI_DESTROY',
  WIFI_UPDATE: 'WIFI_UPDATE',
  WIFI_VIEW: 'WIFI_VIEW'
};

export const entityUINames = {
  AGREEMENT: 'Agreement',
  ANNOUNCEMENT: 'Announcement',
  B2BCONFIGURATION: 'B2B Configuration',
  BULKIMPORT: 'Bulk Import',
  COMPANY: 'Company',
  COMPANYALERT: 'Company Alerts',
  COMPANYCONFIG: 'Company Configuration',
  CONTACT: 'Contact',
  CUSTOMER: 'Customer',
  DEVICE: 'Device',
  DEVICEVERSIONMANAGEMENT: 'Device Version Management',
  DRIVERMANAGEMENTSCHEDULE: 'Driver Management Schedule',
  DRIVERMANAGEMENTTYPE: 'Driver Management Type',
  DRIVERPORTAL: 'Driver Portal',
  DOCUMENT: 'Easydocs',
  FOLDER: 'Easydocs Folder',
  ENGINEPROFILE: 'Engine Profile',
  FLEET: 'Fleet',
  FORM: 'Forms',
  GEOFENCE: 'Geofence',
  GPIO: 'GPIO',
  JOB: 'Job',
  LOCATION: 'Location',
  MESSAGE: 'Message',
  MESSAGETEMPLATE: 'Message Template',
  ODOMETERSOURCE_UPDATE: 'Odometer Source',
  PRETRIPCHECKLIST: 'Inspections',
  QUICKJOBSCONFIG: 'Quickjobs',
  REPORTSCHEDULE: 'Report Schedule',
  ROLE: 'Role',
  RUNSHEET: 'Runsheet',
  SCORECARD: 'Scorecard Scores',
  SCREEN: 'Screen',
  SHIFT: 'Shift',
  SMARTJOBSCODE: 'SmartJobs Code',
  SUBSCRIPTION: 'Subscription',
  SUBSCRIPTIONPACK: 'Subscription Pack',
  SUPPORTCENTER: 'Support Center',
  TRIP: 'Trip',
  USER: 'User',
  VEHICLE: 'Vehicle',
  VEHICLEMAINTENANCESCHEDULE: 'Vehicle Maintenance Schedule',
  VEHICLEMAINTENANCETYPE: 'Vehicle Maintenance Type',
  VEHICLETYPE: 'Vehicle Type',
  VIDEO: 'Video',
  WIFI: 'Wifi'
};

export default Object.freeze(entities);
