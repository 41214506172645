import React from 'react';
import T from 'prop-types';
import { Tooltip as AntTooltip } from 'antd';

export const Tooltip = ({ target, content, placement, ...props }) => {
  return (
    <AntTooltip title={content} placement={placement} {...props}>
      {target}
    </AntTooltip>
  );
};

Tooltip.propTypes = {
  content: T.node,
  target: T.node,
  placement: T.string
};
