import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useDispatch } from 'react-redux';

// components
import {
  DriverManagement,
  DriverManagementForm,
  DriverManagementView,
  DriverManagementAudits,
  DriverManagementComplete
} from './';
import { NoAccessBody } from 'components/pages/NoAccess';
import { Can, entities } from 'features/permissions';

//slices
import { useSubCompanies, useCurrentCompanyKey } from 'features/company/companySlice';
import { useBranches } from 'features/locations/locationsSlice';
import { useDrivers, useUsers } from 'features/users/usersSlice';
import {
  useDriverManagementSchedules,
  useDriverMgtTypes,
  fetchSchedules,
  fetchTypes,
  useIsSchedulesFetchingFinished
} from 'features/driverManagement';

//helpers
import { buildDriverNames } from 'utils/methods';

//constants
import { Paths, ACTIONS } from './constants';
import { getTypesQuery, getSchedulesQuery } from '../Configuration/DriverMgtTypes/constants';

export const DriverManagementRouter = () => {
  const dispatch = useDispatch();
  const companyKey = useCurrentCompanyKey();
  const schedules = useDriverManagementSchedules();
  const mgtTypes = useDriverMgtTypes();
  const subCompanies = useSubCompanies();
  const branches = useBranches();
  const drivers = useDrivers();
  const users = useUsers();
  const driversWithName = buildDriverNames(drivers);
  const isSchedulesFetched = useIsSchedulesFetchingFinished();

  // refresh data on company change
  useEffect(() => {
    if (isSchedulesFetched) {
      dispatch(fetchSchedules(getSchedulesQuery));
      dispatch(fetchTypes(getTypesQuery));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyKey]);

  return (
    <Switch>
      <Route path={Paths.ADD_DRIVERMANAGEMENTSCHEDULE}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_CREATE]} onFail={<NoAccessBody />}>
          <DriverManagementForm
            action={ACTIONS.ADD}
            schedules={schedules}
            types={mgtTypes}
            branches={branches}
            drivers={driversWithName}
          />
        </Can>
      </Route>
      <Route path={Paths.EDIT_DRIVERMANAGEMENTSCHEDULE}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_UPDATE]} onFail={<NoAccessBody />}>
          <DriverManagementForm
            action={ACTIONS.EDIT}
            schedules={schedules}
            types={mgtTypes}
            branches={branches}
            drivers={driversWithName}
          />
        </Can>
      </Route>
      <Route path={Paths.COMPLETE_DRIVERMANAGEMENTSCHEDULE}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_UPDATE]} onFail={<NoAccessBody />}>
          <DriverManagementComplete
            users={users}
            types={mgtTypes}
            branches={branches}
            drivers={driversWithName}
          />
        </Can>
      </Route>
      <Route path={Paths.VIEW_DRIVERMANAGEMENTSCHEDULE}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_VIEW]} onFail={<NoAccessBody />}>
          <DriverManagementView
            users={users}
            mgtTypes={mgtTypes}
            subCompanies={subCompanies}
            drivers={drivers}
            branches={branches}
          />
        </Can>
      </Route>
      <Route path={Paths.AUDITS_DRIVERMANAGEMENTSCHEDULE}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE_AUDIT]} onFail={<NoAccessBody />}>
          <DriverManagementAudits schedules={schedules} />
        </Can>
      </Route>
      <Route path={Paths.MAIN_PAGE}>
        <Can everyEntity={[entities.DRIVERMANAGEMENTSCHEDULE]} onFail={<NoAccessBody />}>
          <DriverManagement
            schedules={schedules}
            users={users}
            subCompanies={subCompanies}
            branches={branches}
            drivers={driversWithName}
            mgtTypes={mgtTypes}
          />
        </Can>
      </Route>
    </Switch>
  );
};
