import React, { useEffect, useState } from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { useLocalization } from 'features/localization/localizationSlice';
import { useCanOneOfEntities } from 'features/permissions/canHooks';
import {
  cache,
  nameCellRenderer,
  createdAtCellRendered,
  checkboxCellRenderer,
  nameCellRenderer_ANT,
  dateCellRenderer_ANT,
  stringCellRenderer_ANT
} from '../CellRenderers';
import { Grid } from 'components/tn';
import { ColumnTypes } from 'components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';
import { useTranslation } from 'react-i18next';
import styles from './DocumentView.module.scss';
import { SortUtil } from 'components/tables/SortUtil';

export const rowAction = (id, items, action) => {
  const newCheckedValues = (items || []).includes(id)
    ? items.filter(item => item !== id)
    : [...(items || []), id];

  action(newCheckedValues);
};

export const DocumentsTable = ({ documents, setTableRef, checkedItems, setCheckedItems }) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const checkboxCell = props => checkboxCellRenderer({ ...props, checkedItems });
  const nameCell = props => nameCellRenderer({ ...props, checkedItems });
  const createdAtCell = props => createdAtCellRendered({ ...props, checkedItems, localization });
  const canViewDocument = useCanOneOfEntities(['DOCUMENT_VIEW']);

  const [selectedRowKeys, setSelectedRowKeys] = useState(checkedItems || []);

  useEffect(() => {
    setSelectedRowKeys(checkedItems || []);
  }, [documents, checkedItems]);

  const allColumns = [
    {
      title: t('Easydocs.Columns.name'),
      key: 'filename',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'filename',
      fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return nameCellRenderer_ANT({ rowData: rowData, canViewDocument: canViewDocument });
      },
      defaultSortColumn: false,
      sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'filename'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.Columns.uploadDate'),
      key: 'uploadedAt',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'uploadedAt',
      fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return dateCellRenderer_ANT({
          dateFormat: localization.formats.time.formats.dby_imsp,
          date: rowData.uploadedAt
        });
      },
      sorter: (a, b) => SortUtil.sortDate(a, b, 'uploadedAt'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.Columns.expirationDate'),
      key: 'expiredAt',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'expiredAt',
      fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return dateCellRenderer_ANT({
          dateFormat: localization.formats.time.formats.dby,
          date: rowData.expiredAt
        });
      },
      sorter: (a, b) => SortUtil.sortDate(a, b, 'expiredAt'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Common.Company'),
      key: 'company',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'company',
      fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        const companies = rowData?.company?.map(c => c.name).join(', ');
        return stringCellRenderer_ANT(companies || '');
      }
    },
    {
      title: t('Common.Fleet'),
      key: 'fleet',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'fleet',
      fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        const fleets = rowData?.fleet?.map(c => c.name).join(', ');
        return stringCellRenderer_ANT(fleets || '');
      }
    }
  ];

  const handleSelectChange = newSelectedRowKeys => {
    setSelectedRowKeys(newSelectedRowKeys);
    setCheckedItems(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
    type: 'checkbox',
    columnWidth: 0
  };

  return (
    <Grid
      className={styles.documentsGrid}
      data={documents}
      columns={allColumns}
      allColumns={allColumns}
      rowKey={'id'}
      showFilters={false}
      showSearch={false}
      showCount={false}
      hideSelectAll={true}
      enableMultipleExpansion={false}
      useViewsGridHeader={false}
      //tableLayout="fixed"
      rowSelection={rowSelection}
    ></Grid>
  );
};
