import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormCheck } from 'react-bootstrap';
import { Space } from 'antd';
import { FeatureFlag, useCan } from 'features/permissions';

const QUESTION_EXTRA_PROPS = {
  AllowPhotoOnPass: 'AllowPhotoOnPass',
  AllowPhotoOnFail: 'AllowPhotoOnFail'
};

//Provide allow-photo-on-Pass/Fail Answers checkbox for Applicable Question
export const QuestionExtraFields = ({
  inspectionChecklistId = 'ID',
  question,
  setQuestionOption
}) => {
  const { t } = useTranslation();
  const can = useCan();

  const { isSupport, questionHTMLId, allowPhotoOnPass, allowPhotoOnFail } = useMemo(() => {
    const questionType = question.Type;
    const questionHTMLId = `${inspectionChecklistId}${questionType}`;
    const SupportQuestionTypes = ['Yes/No', 'Ok/Not Ok', 'Selection'];
    return {
      questionHTMLId,
      isSupport:
        can({
          featureFlag: FeatureFlag.pretripChecklistPhotoOnPass.flag
        }) &&
        question &&
        SupportQuestionTypes.some(type => type === question.Type),
      allowPhotoOnPass: question?.[QUESTION_EXTRA_PROPS.AllowPhotoOnPass],
      allowPhotoOnFail: question?.[QUESTION_EXTRA_PROPS.AllowPhotoOnFail]
    };
  }, [question, inspectionChecklistId]);

  useEffect(() => {
    if (allowPhotoOnFail !== false && allowPhotoOnFail !== true) {
      //default check allowPhotoOnFail
      setQuestionOption(QUESTION_EXTRA_PROPS.AllowPhotoOnFail, true);
    }
  }, [allowPhotoOnFail]);

  return isSupport ? (
    <Space direction="vertical" className="form-allow-photo-group">
      <FormCheck
        inline
        id={`${questionHTMLId}-pass`}
        label={t('PreTripChecklist.Form.AllowPhotoOnPass')}
        checked={allowPhotoOnPass}
        onChange={e => setQuestionOption(QUESTION_EXTRA_PROPS.AllowPhotoOnPass, e.target.checked)}
      />
      <FormCheck
        inline
        id={`${questionHTMLId}-fail`}
        label={t('PreTripChecklist.Form.AllowPhotoOnFail')}
        checked={allowPhotoOnFail}
        onChange={e => setQuestionOption(QUESTION_EXTRA_PROPS.AllowPhotoOnFail, e.target.checked)}
      />
    </Space>
  ) : null;
};
