import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TabNavLink } from '../../components/nav/NavLinks';
import { Overview } from './Overview/Overview';
import { UDT } from './UDT/UDT';
import { Button } from 'antd';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import styles from './ELD.module.scss';
import { DriverLog } from './DriverLog/DriverLog';
import { DriverPortal } from './DriverPortal/DriverPortal';
import CanRoute from 'components/route/CanRoute';
import { LogExport } from './LogExport/LogExport';
import entities from 'features/permissions/entities';
import { LogTransferModal } from './LogExport/LogTransferModal';
import { useTranslation } from 'react-i18next';
import { BUTTON_IDS } from 'utils/globalConstants';

export function ELD(props) {
  const location = useLocation();
  const [editMode, setEditMode] = useState(false);
  const [showLogTransfer, setShowLogTransfer] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setEditMode(false);
  }, [location.pathname]);
  return (
    <Switch>
      <Route exact path={['/fatigue/eld/driverportal/:id']}>
        <DriverPortal />
      </Route>
      <Route exact path={['/fatigue/eld/driverlog/:id']}>
        <DriverLog />
      </Route>
      <Route>
        <div
          style={{
            display: 'flex',
            flex: '1 0 0',
            flexDirection: 'column'
          }}
        >
          <HeaderPageWrapper>
            <div style={{ display: 'flex', width: '100%' }}>
              <TabNavLink
                to={'/fatigue/eld/overview'}
                isActive={(match, location) =>
                  match || location.pathname.toLowerCase() === '/fatigue/eld'
                }
              >
                {t('ELD.Overview')}
              </TabNavLink>

              <TabNavLink to={'/fatigue/eld/time_management'} isActive={(match, location) => match}>
                {t('ELD.Unassigned Drive Time')}
              </TabNavLink>

              <TabNavLink to={'/fatigue/eld/log_export'} isActive={(match, location) => match}>
                {t('ELD.Log Export')}
              </TabNavLink>

              {location?.pathname === '/fatigue/eld/time_management' && (
                <div className={styles.udt_toolbar}>
                  <Button
                    type="primary"
                    style={{ margin: '5px', height: '40px' }}
                    disabled={editMode}
                    onClick={() => setEditMode(true)}
                    id={BUTTON_IDS.eldBulkAssignment}
                  >
                    {t('ELD.Bulk Assignment')}
                  </Button>
                </div>
              )}
              {location?.pathname === '/fatigue/eld/log_export' && (
                <div className={styles.udt_toolbar}>
                  <Button
                    type="primary"
                    style={{ margin: '5px', height: '40px' }}
                    onClick={() => setShowLogTransfer(true)}
                    id={BUTTON_IDS.eldExportLogs}
                  >
                    {t('ELD.Export Logs')}
                  </Button>
                </div>
              )}
            </div>
          </HeaderPageWrapper>
          <Switch>
            <Route exact path={['/fatigue/eld', '/fatigue/eld/overview']}>
              <Overview />
            </Route>

            <Route exact path={['/fatigue/eld/time_management']}>
              <UDT editMode={editMode} restoreAssignMode={() => setEditMode(false)} />
            </Route>

            <CanRoute
              everyEntity={[entities.USER_CREATE]}
              render={props => (
                <>
                  <LogTransferModal
                    show={showLogTransfer}
                    onClose={() => setShowLogTransfer(false)}
                  />
                  <LogExport />
                </>
              )}
            />
          </Switch>
        </div>
      </Route>
    </Switch>
  );
}
