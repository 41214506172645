import React from 'react';
import { Skeleton } from 'antd';
import styles from './VisualizationCard.module.scss';

export const VisualizationCard = ({
  title,
  titleExtra,
  subTitle,
  content,
  summary,
  loading,
  className
}) =>
  loading ? (
    <div className={styles.card}>
      <h3 className={styles.title}>{title}</h3>
      <Skeleton loading active />
    </div>
  ) : (
    <div className={`${styles.card} ${className || ''}`}>
      {titleExtra ? (
        <div className={styles.header}>
          <span>
            <h3 className={styles.title}>{title}</h3>
            {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
          </span>
          {titleExtra}
        </div>
      ) : (
        <>
          <h3 className={styles.title}>{title}</h3>
          {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
        </>
      )}
      {content}
      {summary && <div className={styles.summary}>{summary}</div>}
    </div>
  );
