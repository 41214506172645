import React, { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';

const type = 'draggableBodyRow';

export const DraggableBodyRow = ({ index, moveRow, className, style, ...rest }) => {
  const ref = useRef();

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) return {};
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? 'drop-over-downward' : 'drop-over-upward'
      };
    },
    drop: item => {
      moveRow(item.index, index);
    }
  });

  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...rest}
    />
  );
};
