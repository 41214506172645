import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useAnnotations } from './annotationsSlice';
import { TZ2STATE } from 'features/fatigue/constants/Generals';
import moment from 'moment';
import { useLocalization } from 'features/localization/localizationSlice';

const EWDAnnotations = () => {
  const localization = useLocalization();
  const annotations = useAnnotations().reverse();

  return (
    <>
      {annotations.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Annotation Flag</th>
              <th>Time of Intercept</th>
              <th>Location</th>
              <th>Annotation</th>
            </tr>
          </thead>
          <tbody>
            {annotations.map((annotation, index) => (
              <tr key={index}>
                <td>
                  {moment(annotation.flagAt)
                    .tz(annotation.timeZone)
                    .format(localization.formats.time.formats.bdo_hm)}{' '}
                  ({TZ2STATE[annotation.timeZone]}){' '}
                </td>
                <td>
                  {moment(annotation.interceptAt)
                    .tz(annotation.timeZone)
                    .format(localization.formats.time.formats.bdo_hm)}{' '}
                  ({TZ2STATE[annotation.timeZone]}){' '}
                </td>
                <td>{annotation.location}</td>
                <td>{annotation.content}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {!annotations.length && (
        <Row className="justify-content-md-center">
          <Col className="p-3" xs="auto">
            <p>No annotations in this time period!</p>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EWDAnnotations;
