import P from 'prop-types';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import dragHandleIcon from 'static/images/icons/drag-handle.svg';
import lockIcon from 'static/images/icons/lock.svg';
import { SortableList } from 'components/sortable-list';

//styles
import styles from './ManageColumns.module.scss';

export const ColumnLists = ({
  hiddenColumns,
  setHiddenColumns,
  visibleColumns,
  setVisibleColumns,
  resetColumns
}) => {
  const { t } = useTranslation();
  const [showPlaceholder, setShowPlaceholder] = useState(!!visibleColumns.length);

  useEffect(() => {
    showPlaceholder && visibleColumns.length > 0 && setShowPlaceholder(false);
  }, [showPlaceholder, visibleColumns.length]);

  return (
    <div className={styles.listsContainer}>
      <div className={styles.listWrapper}>
        <h5 className={styles.listTitle}>{t('SmartJobs.ColumnList.HiddenColumns')}</h5>
        <SortableList
          list={hiddenColumns}
          setList={setHiddenColumns}
          animation={200}
          group="columns"
          styles={styles}
          handle={`.${styles.handle}`}
          dragIconSrc={dragHandleIcon}
          setShowPlaceholder={setShowPlaceholder}
        />
      </div>

      <div className={styles.listWrapper}>
        <h5 className={styles.listTitle}>{t('SmartJobs.ColumnList.VisibleColumns')}</h5>
        <div className={styles.list}>
          <div className={`${styles.listItem} ${styles.disabled}`}>
            <span>{t('SmartJobs.ColumnList.Columns.status')}</span>
            <img src={lockIcon} className={styles.lockIcon} alt="lock icon" />
          </div>
          <SortableList
            list={visibleColumns}
            setList={setVisibleColumns}
            animation={200}
            group="columns"
            styles={styles}
            handle={`.${styles.handle}`}
            dragIconSrc={dragHandleIcon}
            setShowPlaceholder={setShowPlaceholder}
          />
          {showPlaceholder && (
            <div className={`${styles.listItem} ${styles.listItemPlaceholder} ${styles.disabled}`}>
              <span>{t('SmartJobs.ColumnList.DropColumns')}</span>
            </div>
          )}
          <div className={`${styles.listItem} ${styles.disabled}`}>
            <span>{t('SmartJobs.ColumnList.Columns.ownerCarrier')}</span>
            <img src={lockIcon} className={styles.lockIcon} alt="lock icon" />
          </div>
          <div className={`${styles.listItem} ${styles.disabled}`}>
            <span>{t('SmartJobs.ColumnList.Columns.actions')}</span>
            <img src={lockIcon} className={styles.lockIcon} alt="lock icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

ColumnLists.propTypes = {
  hiddenColumns: P.arrayOf(
    P.shape({
      key: P.string,
      label: P.string
    })
  ),
  setHiddenColumns: P.func,
  visibleColumns: P.arrayOf(
    P.shape({
      key: P.string,
      label: P.string
    })
  ),
  setVisibleColumns: P.func,
  resetColumns: P.func
};
