import React from 'react';
import { TabNavLink } from 'components/nav/NavLinks';
import * as Yup from 'yup';
import i18next from 'i18next';
import { Can, services } from 'features/permissions';

export const initialValues = {
  fleet: '',
  fleets: '',
  name: '',
  latitude: '',
  longitude: '',
  address: '',
  plannedHH: '00',
  plannedMM: '00',
  plannedSS: '00'
};

export const validationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required(i18next.t('JourneyPlanner.FormValidation.NameRequired'))
  });
};

export const EMPTY_ARRAY = [];

export const JOURNEY_TYPE = {
  TYPE: {
    ALL: '',
    JOURNEY: 'JOURNEY_PLANNED',
    GEOFENCE_TRIP: 'GEO_PLANNED',
    ROUTE_COMPLIANCE: 'ROUTE_PLANNED'
  },
  TAB: {
    ALL: 'all',
    JOURNEY: 'journey',
    GEOFENCE_TRIP: 'geofencetrip',
    ROUTE_COMPLIANCE: 'routecompliance',
    DELETED: 'deleted'
  },
  PATH: {
    ALL: '/journeyplanner/all',
    JOURNEY: '/journeyplanner/journey',
    GEOFENCE_TRIP: '/journeyplanner/geofencetrip',
    ROUTE_COMPLIANCE: '/journeyplanner/routecompliance',
    DELETED: '/journeyplanner/deleted'
  },
  ID: {
    JOURNEY: 1,
    ROUTE_COMPLIACE: 2,
    GEOFENCE_TRIP: 3
  },
  TYPEID: {
    JOURNEY_PLANNED: 1,
    ROUTE_PLANNED: 2,
    GEO_PLANNED: 3
  }
};

export const getJourneyType = type => {
  switch (type) {
    case JOURNEY_TYPE.TYPE.JOURNEY:
      return i18next.t('JourneyPlanner.JourneyType.Journey');
    case JOURNEY_TYPE.TYPE.GEOFENCE_TRIP:
      return i18next.t('JourneyPlanner.JourneyType.GeofenceTrip');
    case JOURNEY_TYPE.TYPE.ROUTE_COMPLIANCE:
      return i18next.t('JourneyPlanner.JourneyType.RouteCompliance');
    default:
      return type;
  }
};

export const journeyTypes = [
  {
    name: 'Journey',
    id: 1,
    apiName: JOURNEY_TYPE.TYPE.JOURNEY,
    service: 'JOURNEYPLANNER'
  },
  {
    name: 'RouteCompliance',
    id: 2,
    apiName: JOURNEY_TYPE.TYPE.ROUTE_COMPLIANCE,
    service: 'ROUTECOMPLIANCE'
  },
  {
    name: 'GeofenceTrip',
    id: 3,
    apiName: JOURNEY_TYPE.TYPE.GEOFENCE_TRIP,
    service: 'GEOFENCETRIP'
  }
];

export const stopTypes = [
  { name: 'Address', id: 1 },
  { name: 'Geofence', id: 2 },
  { name: 'Branch', id: 3 },
  { name: 'Depot', id: 4 },
  { name: 'Supplier', id: 5 },
  { name: 'Customer', id: 6 }
];

export const tooltip = {
  addStops: `${i18next.t('JourneyPlanner.Form.AddStopsInfo')}`,
  routeIndication: `${i18next.t('JourneyPlanner.Form.RouteIndicationInfo')}`,
  plannedDuration: `${i18next.t('JourneyPlanner.Form.PlannedDurationInfo')}`
};

export const status = {
  CREATED: 'CREATED',
  DELETED: 'DELETED'
};

export const ellipsisMenuActiveEntities = ['VIEW', 'UPDATE', 'CREATE', 'DESTROY'];

export const ellipsisMenuDeletedEntities = ['VIEW', 'RESTORE'];

export const TabsFilters = ({ setFilterTab, historyFiltersState }) => {
  const tabs = {
    All: `${i18next.t('JourneyPlanner.Tabs.All')}`,
    Journey: `${i18next.t('JourneyPlanner.Tabs.Journey')}`,
    GeofenceTrip: `${i18next.t('JourneyPlanner.Tabs.GeofenceTrip')}`,
    RouteCompliance: `${i18next.t('JourneyPlanner.Tabs.RouteCompliance')}`,
    Deleted: `${i18next.t('JourneyPlanner.Tabs.Deleted')}`
  };
  return (
    <>
      <TabNavLink
        to={{
          pathname: JOURNEY_TYPE.PATH.ALL,
          state: historyFiltersState
        }}
        isActive={(match, location) => {
          return ['/', '/journeyplanner', '/journeyplanner/all'].includes(location.pathname);
        }}
        onClick={() => {
          setFilterTab(JOURNEY_TYPE.TAB.ALL);
        }}
      >
        {tabs.All}
      </TabNavLink>
      <Can everyService={[services.JOURNEYPLANNER]}>
        <TabNavLink
          exact
          to={{
            pathname: JOURNEY_TYPE.PATH.JOURNEY,
            state: historyFiltersState
          }}
          onClick={() => {
            setFilterTab(JOURNEY_TYPE.TAB.JOURNEY);
          }}
        >
          {tabs.Journey}
        </TabNavLink>
      </Can>
      <Can everyService={[services.GEOFENCETRIP]}>
        <TabNavLink
          exact
          to={{
            pathname: JOURNEY_TYPE.PATH.GEOFENCE_TRIP,
            state: historyFiltersState
          }}
          onClick={() => {
            setFilterTab(JOURNEY_TYPE.TAB.GEOFENCE_TRIP);
          }}
        >
          {tabs.GeofenceTrip}
        </TabNavLink>
      </Can>
      <Can everyService={[services.ROUTECOMPLIANCE]}>
        <TabNavLink
          exact
          to={{
            pathname: JOURNEY_TYPE.PATH.ROUTE_COMPLIANCE,
            state: historyFiltersState
          }}
          onClick={() => {
            setFilterTab(JOURNEY_TYPE.TAB.ROUTE_COMPLIANCE);
          }}
        >
          {tabs.RouteCompliance}
        </TabNavLink>
      </Can>
      <TabNavLink
        exact
        to={{
          pathname: JOURNEY_TYPE.PATH.DELETED,
          state: historyFiltersState
        }}
        onClick={() => {
          setFilterTab(JOURNEY_TYPE.TAB.DELETED);
        }}
      >
        {tabs.Deleted}
      </TabNavLink>
    </>
  );
};

export const routeTitleStyle = {
  height: '100%',
  display: 'flex',
  padding: 0,
  alignItems: 'center'
};

export const pageStyle = `
  @page {
    size: 80mm 50mm;
    margin-top: 20mm;
    margin-bottom: 30mm;
    margin-left: 20mm;
    margin-right: 20mm;
  }
  @media print { 
    thead {display: table-header-group;} 
    body { -webkit-print-color-adjust: exact !important; } }
`;
