import React, { useEffect, useState } from 'react';
import { InfoWindow } from 'react-google-maps';
import { TextButton } from 'components/buttons/TextButton';

import { useLocalization } from 'features/localization/localizationSlice';
import { toTitleCase } from 'utils/strings';
import { getGeofenceShape } from 'containers/Administration/Geofences/helpers';

import styles from './InfoWindows.module.scss';
import { useTranslation } from 'react-i18next';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { getRoundValue } from 'utils/methods';
import { LocalizationUtil } from 'features/localization/localization';
import { GeofencesTypes } from 'containers/Administration/Fleets/constants';
import { useCompanyGeofenceProviders } from 'features/company/companySlice';
import { useCan } from 'features/permissions';

const GeofenceInfoWindow = ({
  geofence,
  position,
  showInfoWindowActions,
  onViewDetailsClicked,
  onGeofenceActionClicked = () => {},
  onClose,
  showExtraGeofenceParams,
  supportGeofenceControl,
  ...props
}) => {
  const { t } = useTranslation();
  const can = useCan();

  const canSupportGeofenceControl = supportGeofenceControl;
  const localization = useLocalization();
  const geofenceProviders = useCompanyGeofenceProviders();

  const hideAdd = geofenceProviders?.includes(geofence.type);
  const hideEdit = geofenceProviders?.includes(geofence.type);
  const [contentList, setContentList] = useState([]);

  useEffect(() => {
    const shape = getGeofenceShape(geofence);
    const content = [];
    content.push(
      {
        name: t('Common.Name'),
        value: toTitleCase(geofence.name, false)
      },
      {
        name: t('VehicleTypes.Type'),
        value: geofence.type ? GeofencesTypes[geofence.type] : t('Common.Unknown')
      },
      {
        name: t('Common.Shape'),
        value: shape
      },
      {
        name: t('Common.Area'),
        value: geofence.area_sqm
          ? localization.convertArea(geofence.area_sqm) + ' ' + localization.formats.area.unit
          : '-'
      }
    );
    if (showExtraGeofenceParams) {
      if (geofence.thresholdSpeed !== null && Number(geofence.thresholdSpeed) >= 0) {
        content.push({
          name: t('ExportToExcel.SpeedLimit'),
          value: `${
            localization.formats.speed.unit_per_hour === 'mph'
              ? getRoundValue(LocalizationUtil.kmtomile(parseFloat(geofence.thresholdSpeed)))
              : geofence.thresholdSpeed
          } ${localization.formats.speed.unit_per_hour}`
        });
      }

      if (geofence.offsetSpeed !== null && Number(geofence.offsetSpeed) >= 0) {
        content.push({
          name: t('ExportToExcel.SpeedTolerance'),
          value: `${
            localization.formats.speed.unit_per_hour === 'mph'
              ? getRoundValue(LocalizationUtil.kmtomile(parseFloat(geofence.offsetSpeed)))
              : geofence.offsetSpeed
          } ${localization.formats.speed.unit_per_hour}`
        });
      }

      if (
        geofence.thresholdDurationSpeed !== null &&
        Number(geofence.thresholdDurationSpeed) >= 0
      ) {
        content.push({
          name: t('ExportToExcel.SpeedDuration'),
          value: `${geofence.thresholdDurationSpeed} sec`
        });
      }

      if (geofence.thresholdUndertime !== null && Number(geofence.thresholdUndertime) >= 0) {
        content.push({
          name: t('ExportToExcel.Undertime'),
          value: `${geofence.thresholdUndertime / 60} minutes`
        });
      }

      if (geofence.thresholdOvertime !== null && Number(geofence.thresholdOvertime) >= 0) {
        content.push({
          name: t('ExportToExcel.Overtime'),
          value: `${geofence.thresholdOvertime / 60} minutes`
        });
      }
    }
    setContentList(content);
  }, [t, geofence, localization, showExtraGeofenceParams]);

  return (
    <>
      <InfoWindow position={position} onCloseClick={onClose} {...props}>
        <div className={styles.infoWindow}>
          <div className={styles.infoWindowTitle}>{t('Tracking.QuickView')}</div>
          {contentList?.map((c, i) => (
            <div key={i}>
              <label>{c.name}</label>
              <span>{c.value}</span>
            </div>
          ))}

          {showInfoWindowActions && (
            <footer>
              {!hideAdd && (
                <TextButton
                  style={{ width: '25%', marginLeft: '0px', marginRight: '10px' }}
                  text={t('Common.Add')}
                  variant="outline-secondary"
                  onClick={() => {
                    Mixpanel.sendTrackEvent(MPTrackingEvents.Tracking.Map.Geofence.Add, {
                      geofenceShapeType: getGeofenceShape(geofence)
                    });

                    if (canSupportGeofenceControl) {
                      onGeofenceActionClicked({
                        title: t('GeofencesFeature.AddNewGeofence'),
                        action: 'add',
                        visible: true
                      });
                    } else {
                      window.open('/settings/geofences/newGeofence', '_blank');
                    }
                  }}
                  id="btn_geofenceInfoAdd"
                />
              )}
              {!hideEdit && (
                <TextButton
                  style={{ width: '25%', marginLeft: '0px', marginRight: '10px' }}
                  text={t('Common.Edit')}
                  variant="outline-secondary"
                  onClick={() => {
                    Mixpanel.sendTrackEvent(MPTrackingEvents.Tracking.Map.Geofence.Edit, {
                      geofenceShapeType: getGeofenceShape(geofence)
                    });

                    if (canSupportGeofenceControl) {
                      onGeofenceActionClicked({
                        title: `${t('Common.EllipsisButton.Edit')} ${geofence.name}`,
                        action: 'edit',
                        visible: true,
                        geofence: geofence
                      });
                    } else {
                      window.open('/settings/geofences/edit/id/' + geofence.id, '_blank');
                    }
                  }}
                  id="btn_geofenceInfoEdit"
                />
              )}
              <TextButton
                style={{ width: '50%', marginLeft: '0px' }}
                text={t('Tracking.ViewDetails')}
                variant="primary"
                onClick={() => {
                  Mixpanel.sendTrackEvent(MPTrackingEvents.Tracking.Map.Geofence.View, {
                    geofenceShapeType: getGeofenceShape(geofence)
                  });

                  if (canSupportGeofenceControl) {
                    onGeofenceActionClicked({
                      title: `${geofence.name}`,
                      action: 'view',
                      visible: true,
                      geofence: geofence
                    });
                  } else {
                    window.open('/settings/geofences/id/' + geofence.id, '_blank');
                  }
                }}
                id="btn_geofenceInfoView"
              />
            </footer>
          )}
        </div>
      </InfoWindow>
    </>
  );
};

export default GeofenceInfoWindow;
