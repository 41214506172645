import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { toTitleCase, sortStrings } from 'utils/strings';
import { format } from 'utils/dates';
import { PATHS } from './constants';
import { MARKER_TYPE, MARKER_SIZE } from 'components/map/markers/constants';
import { Spin } from 'antd';

import {
  useCompanies,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import {
  useFleets,
  useIsFetching as useIsFetchingFleets,
  useFleetViewData,
  deleteFleetApi,
  restoreFleetApi
} from 'features/fleets/fleetsSlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { DeletedEntityAlert, DeletableEntityType } from 'features/common/deletedEntityAlert';
import { openToast } from 'features/toasts/toastsSlice';

import InfoRow from 'components/form/info-row/InfoRow';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import { LoadingTable } from 'components/grid/LoadingTable';
import { ToastType } from 'components/notifications/toasts/Toast';

import styles from '../Vehicles/Vehicles.module.scss';
import {
  useGeofences,
  useIsFetching as useIsFetchingGeofence
} from 'features/geofences/geofencesSlice';

const FleetView = () => {
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const { t } = useTranslation();
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const localization = useLocalization();
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.FLEETS_DEFAULT);
  }, [history]);
  const fleetData = useFleetViewData(id, handleFetchError);
  const fleetsData = useFleets();
  const isFetchingFleets = useIsFetchingFleets();
  const idFromData = fleetData ? fleetData.id : -1;
  const currentFleet = fleetsData?.find(fleet => fleet.id === idFromData) || {};
  const vehiclesFromCurrentFleet = currentFleet?.vehicles;
  const devices = currentFleet.devices
    ? [...currentFleet.devices]?.sort((a, b) => sortStrings(a.name, b.name))
    : [];
  const locationLink = useLocation();
  const { pageTitle } = locationLink.state || {};
  const geofences = useGeofences();
  const isFetchingGeofence = useIsFetchingGeofence();
  const currentGeofence = geofences?.filter(i => (i.fleets || []).some(k => k.id === idFromData));

  useRedirectToMainFeaturePageOnCompanyChange('/settings/fleets');

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(fleetData?.name || pageTitle));

    // console.debug('FleetView', { fleetData });
  }, [dispatch, fleetData]);

  // Get the company name by the id
  const companies = useCompanies();
  let company;
  if (fleetData?.company) {
    company = companies.find(comp => comp.id === fleetData.company.id);
  }

  useEffect(() => {
    if (id <= 0 || isNaN(id)) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [id, dispatch, handleFetchError]);

  if (!fleetData) {
    return <LoadingTable columnSizes={[400]} />;
  }

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        dispatch(deleteFleetApi(fleetData, history));
        break;
      case 'restore':
        dispatch(restoreFleetApi(fleetData));
        break;
      default:
    }
  };

  const renderVehicles = () => {
    if (!vehiclesFromCurrentFleet) {
      return;
    }
    return isFetchingFleets ? (
      <Spin />
    ) : (
      vehiclesFromCurrentFleet
        .filter(vehicle => vehicle.id)
        .sort((a, b) => sortStrings(a.name, b.name))
        .map(vehicle => {
          const companyName = companies?.find(company => vehicle?.companyId === company.id)?.name;
          return (
            <div key={`vehicle-${vehicle.name}`}>
              <Link to={`/settings/vehicles/id/${vehicle.id}`}>{`${vehicle.name}`}</Link>
              {companyName && ` (${companyName})`}
            </div>
          );
        })
    );
  };

  const renderDevices = () => {
    if (!devices) {
      return;
    }
    return isFetchingFleets ? (
      <Spin />
    ) : (
      devices.map(device => {
        const companyName = companies?.find(company => device?.companyId === company.id)?.name;
        return (
          <div key={`device-${device.id}`}>
            <Link to={`/settings/devices/id/${device.id}`}>{device.name}</Link>
            {companyName && ` (${companyName})`}
          </div>
        );
      })
    );
  };

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={fleetData}
        editPath={`/settings/fleets/edit/id/${id}`}
        canUse="FLEET"
        handleButtonAction={handleButtonAction}
        typeOfEntityToDelete={t('Common.fleet')}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '32px',
          color: '#181c21'
        }}
      >
        {<DeletedEntityAlert entity={DeletableEntityType.Fleet} entityStatus={fleetData?.status} />}
        <InfoRow label={t('Common.Company')} value={company ? company.name : ''} styles={styles} />
        <InfoRow
          label={t('Common.Description')}
          value={fleetData.description || ''}
          styles={styles}
        />

        <InfoRow
          label={t('Fleets.MarkerType')}
          value={toTitleCase(fleetData.iconType || MARKER_TYPE.asset)}
          styles={styles}
        />
        <InfoRow
          label={t('Fleets.MarkerColor')}
          value={
            fleetData.colour ? (
              <div style={{ width: '15px', height: '15px', backgroundColor: fleetData.colour }} />
            ) : (
              <span>{t('Fleets.Status')}</span>
            )
          }
          styles={styles}
        />
        {fleetData.iconType === MARKER_TYPE.arrow && (
          <InfoRow
            label={t('Fleets.MarkerSize')}
            value={toTitleCase(fleetData.iconSize || MARKER_SIZE.small)}
            styles={styles}
          />
        )}

        <InfoRow label={t('Vehicles.Vehicles')} value={renderVehicles()} styles={styles} />
        <InfoRow label={t('Devices.Devices')} value={renderDevices()} styles={styles} />
        <InfoRow
          label={t('GeofencesFeature.Geofences')}
          value={isFetchingGeofence ? <Spin /> : currentGeofence?.length}
          styles={styles}
        />
        <InfoRow
          label={t('Common.CreatedAt')}
          value={
            fleetData.createdAt
              ? format(new Date(fleetData.createdAt), localization.formats.time.formats.dby_imp)
              : ''
          }
          styles={styles}
        />
        <InfoRow
          label={t('Common.UpdatedAt')}
          value={
            fleetData.updatedAt
              ? format(new Date(fleetData.updatedAt), localization.formats.time.formats.dby_imp)
              : ''
          }
          styles={styles}
        />
      </div>
    </React.Fragment>
  );
};

export default FleetView;
