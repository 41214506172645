/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Item from "./Item";

/**
 * The ItemAttribute model module.
 * @module model/ItemAttribute
 * @version 1.0
 */
class ItemAttribute {
  /**
   * Constructs a new <code>ItemAttribute</code>.
   * @alias module:model/ItemAttribute
   */
  constructor() {
    ItemAttribute.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>ItemAttribute</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ItemAttribute} obj Optional instance to populate.
   * @return {module:model/ItemAttribute} The populated <code>ItemAttribute</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ItemAttribute();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("item")) {
        obj["item"] = Item.constructFromObject(data["item"]);
      }
      if (data.hasOwnProperty("key")) {
        obj["key"] = ApiClient.convertToType(data["key"], "String");
      }
      if (data.hasOwnProperty("value")) {
        obj["value"] = ApiClient.convertToType(data["value"], "String");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
ItemAttribute.prototype["id"] = undefined;

/**
 * @member {module:model/Item} item
 */
ItemAttribute.prototype["item"] = undefined;

/**
 * @member {String} key
 */
ItemAttribute.prototype["key"] = undefined;

/**
 * @member {String} value
 */
ItemAttribute.prototype["value"] = undefined;

export default ItemAttribute;
