import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../config';
import { ApiClient, TachoFatigueStatusApi, TachoCardUserApi } from '../../nextgen_api/index';
import { useSelector } from 'react-redux';

export const actionTypes = {
  init: 0,
  processing: 1,
  error: 2,
  done: 3
};

export const statusType = {
  userFatigue: 0,
  companyFatigue: 1,
  userCard: 2,
  companyCard: 3
};

const REST_TIME = 15 * 1000;

export function getStatusId(type, id) {
  switch (type) {
    case statusType.userFatigue:
      return `user_fatigue_${id}`;
    case statusType.companyFatigue:
      return `company_fatigue_${id}`;
    case statusType.userCard:
      return `user_card_${id}`;
    case statusType.companyCard:
      return `company_card_${id}`;
    default:
      return null;
  }
}

export const tacho_data = {
  companies: {
    /*structure
    [company id]: {
      [user id]: {
          card: {
            "user_id": 219954,
            "company_id": 14873,
            "username": null,
            "licence_number": "101023-9810",
            "licence_state": "S",
            "email": "",
            "mobile": null,
            "user_first_names": "Sally",
            "user_last_name": "Grundström",
            "last_activity": "2020-03-11T10:17:39+11:00",
            "isCardExpired": false,
            "isCardSoonExpiry": false,
            "id": 7,
            "card_number": "21806040637550",
            "card_type": 0,
            "first_names": "Sally",
            "last_name": "Grundström",
            "subscription_start": "2018-06-04T00:00:00+10:00",
            "subscription_end": "2023-06-04T23:59:59+10:00",
            "driver_licence": "101023-9810",
            "driver_licence_state": "S",
            "created_at": "2020-05-11T08:20:53.0301265+10:00",
            "updated_at": "2020-05-11T08:20:53.0301282+10:00",
            "version": 0,
            "activity_start": "2018-06-04T00:00:00+10:00",
            "activity_end": "2020-03-12T00:00:00+11:00"
          },
          fatigueStatus: {
            "id": 7,
            "card_number": "21806040637550",
            "card_id": 7,
            "user_id": 219954,
            "user_version": null,
            "company_id": 14873,
            "company_version": null,
            "device_id": 72548,
            "device_version": 78,
            "vehicle_id": 75752,
            "vehicle_version": 0,
            "created_at": "2017-06-18T13:35:44.679979+10:00",
            "updated_at": "2020-05-01T17:25:31.772104+10:00",
            "event_at": "2020-03-11T10:17:39+11:00",
            "mode": 0,
            "activity": "rest",
            "duration": 60676,
            "alert": "No time related warning active",
            "alert_level": 0,
            "drive_continuous": 0,
            "drive_daily": 0,
            "drive_weekly": 0,
            "drive_twoweekly": 0,
            "work_daily": 0,
            "work_weekly": 0,
            "work_break_taken": 60676,
            "remaining_drive": 3585,
            "next_rest_break_duration": 0,
            "extended_drives_available": 2,
            "break_rest_taken": 53554,
            "required_break_rest": 0,
            "max_allowed_next_drive": 3585,
            "reduced_rests_available": 3,
            "next_daily_rest_due_at": "2020-03-12T01:17:39+11:00",
            "next_weekly_rest_due_at": "2020-03-15T15:45:39+11:00",
            "min_next_daily_rest": 7170,
            "min_next_weekly_rest": 40965,
            "max_daily_drive": 10,
            "max_weekly_drive": 56,
            "max_two_week_drive": 90
          }
        }
      }
    */
  },
  status: {}
};

const tachoSlice = createSlice({
  name: 'tachoData',
  initialState: tacho_data,
  reducers: {
    //fetch tacho card by user id
    fetchTachoCardByUserId(state, { payload }) {
      const userId = payload;
      const statusId = getStatusId(statusType.userCard, userId);
      if (statusId != null) {
        state.status[statusId] = {
          fetching: actionTypes.processing,
          fetchingTime: moment().valueOf(),
          error: null
        };
      }
    },
    fetchTachoCardByUserIdSucceeded(state, { payload }) {
      const [userId, data] = payload;
      const statusId = getStatusId(statusType.userCard, userId);
      if (data != null && data.company_id != null) {
        if (state.companies[data.company_id] == null) {
          state.companies[data.company_id] = {};
        }
        if (state.companies[data.company_id][userId] == null) {
          state.companies[data.company_id][userId] = {};
        }
        state.companies[data.company_id][userId].card = data;
      }
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchTachoCardByUserIdFailed(state, { payload }) {
      const [userId, error] = payload;
      const statusId = getStatusId(statusType.userCard, userId);
      state.status[userId] = {
        ...state.status[statusId],
        fetching: actionTypes.error,
        error: error
      };
    },

    //fetch tacho card by company id
    fetchTachoCardByCompanyIdStart(state, { payload }) {
      const companyId = payload;
      const statusId = getStatusId(statusType.companyCard, companyId);
      state.status[statusId] = {
        fetching: actionTypes.processing,
        fetchingTime: moment().valueOf(),
        error: null
      };
    },
    fetchTachoCardByCompanyIdSucceeded(state, { payload }) {
      const { companyId, data } = { ...payload };
      const statusId = getStatusId(statusType.companyCard, companyId);

      if (state.companies[companyId] == null) {
        state.companies[companyId] = {};
      }

      for (let cardInfo of data) {
        if (cardInfo.user_id != null && cardInfo.user_id !== 0) {
          if (state.companies[companyId][cardInfo.user_id] == null) {
            state.companies[companyId][cardInfo.user_id] = {};
          }
          state.companies[companyId][cardInfo.user_id].card = cardInfo;
        } else {
          if (state.companies[companyId].unassociatedCard == null) {
            state.companies[companyId].unassociatedCard = [];
          }
          state.companies[companyId].unassociatedCard.push(cardInfo);
        }
      }

      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchTachoCardByCompanyIdFailed(state, { payload }) {
      const { companyId, error } = { ...payload };
      const statusId = getStatusId(statusType.companyCard, companyId);
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.error,
        error: error
      };
    },

    //fetch fatigue status by company id
    fetchFatigueStatusByCompanyIdStart(state, { payload }) {
      const companyId = payload;
      const statusId = getStatusId(statusType.companyFatigue, companyId);
      state.status[statusId] = {
        fetching: actionTypes.processing,
        fetchingTime: moment().valueOf(),
        error: null
      };
    },
    fetchFatigueStatusByCompanyIdSucceeded(state, { payload }) {
      const { companyId, data } = { ...payload };
      const statusId = getStatusId(statusType.companyFatigue, companyId);

      if (state.companies[companyId] == null) {
        state.companies[companyId] = {};
      }

      if (data != null && Array.isArray(data)) {
        for (let item of data) {
          if (item.user_id == null || item.user_id === 0) {
            if (state.companies[companyId].unassociatedFatigue == null) {
              state.companies[companyId].unassociatedFatigue = [];
            }
            state.companies[companyId].unassociatedFatigue.push(item);
          } else {
            if (state.companies[companyId][item.user_id] == null) {
              state.companies[companyId][item.user_id] = {};
            }
            state.companies[companyId][item.user_id].fatigueStatus = item;
          }
        }
      }

      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchFatigueStatusByCompanyIdFailed(state, { payload }) {
      const { companyId, error } = { ...payload };
      const statusId = getStatusId(statusType.companyFatigue, companyId);
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.error,
        error: error
      };
    },

    //fetch fatigue status by user id
    fetchFatigueStatusByUserIdStart(state, { payload }) {
      const userId = payload;
      const statusId = getStatusId(statusType.userFatigue, userId);
      state.status[statusId] = {
        fetching: actionTypes.processing,
        fetchingTime: moment().valueOf(),
        error: null
      };
    },
    fetchFatigueStatusByUserIdSucceeded(state, { payload }) {
      const { userId, data } = { ...payload };
      const statusId = getStatusId(statusType.userFatigue, userId);

      if (data != null && data.company_id != null) {
        if (state.companies[data.company_id] == null) {
          state.companies[data.company_id] = {};
        }
        if (state.companies[data.company_id][userId] == null) {
          state.companies[data.company_id][userId] = {};
        }
        state.companies[data.company_id][userId].fatigueStatus = data;
      }

      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchFatigueStatusByUserIdFailed(state, { payload }) {
      const { userId, error } = { ...payload };
      const statusId = getStatusId(statusType.userFatigue, userId);
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.error,
        error: error
      };
    }
  }
});

export const {
  fetchFatigueStatusByCompanyIdFailed,
  fetchFatigueStatusByCompanyIdStart,
  fetchFatigueStatusByCompanyIdSucceeded,

  fetchFatigueStatusByUserIdFailed,
  fetchFatigueStatusByUserIdStart,
  fetchFatigueStatusByUserIdSucceeded,

  fetchTachoCardByCompanyIdFailed,
  fetchTachoCardByCompanyIdStart,
  fetchTachoCardByCompanyIdSucceeded,

  fetchTachoCardByUserIdStart,
  fetchTachoCardByUserIdFailed,
  fetchTachoCardByUserIdSucceeded
} = tachoSlice.actions;

export const fetchFatigueStatusByCompanyId = (companyId, userKey) => async (dispatch, getState) => {
  const statusId = getStatusId(statusType.companyFatigue, companyId);
  const fetchInfo = getState().tachoData.status[statusId];
  if (
    fetchInfo != null &&
    (fetchInfo.fetching === actionTypes.processing ||
      moment().valueOf() - fetchInfo.fetchingTime < REST_TIME)
  ) {
    return;
  }

  dispatch(fetchFatigueStatusByCompanyIdStart(companyId));
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;
  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };

  const tachoFatigueApi = new TachoFatigueStatusApi(apiClient);
  const promise = new Promise((resolve, reject) => {
    tachoFatigueApi.fatigueStatusApiGetCompany(companyId, (err, data, resp) => {
      if (err && (resp == null || resp.status !== 200)) {
        console.log(err);
        reject(err);
      } else {
        resolve(resp.body);
      }
    });
  });

  try {
    const data = await promise;
    dispatch(fetchFatigueStatusByCompanyIdSucceeded({ companyId: companyId, data: data }));
  } catch (err) {
    dispatch(fetchFatigueStatusByCompanyIdFailed({ companyId: companyId, error: err.toString() }));
  }
};

export const fetchFatigueStatusByUserId = (userId, userKey) => async (dispatch, getState) => {
  const statusId = getStatusId(statusType.userFatigue, userId);
  const fetchInfo = getState().tachoData.status[statusId];
  if (
    fetchInfo != null &&
    (fetchInfo.fetching === actionTypes.processing ||
      moment().valueOf() - fetchInfo.fetchingTime < REST_TIME)
  ) {
    return;
  }
  dispatch(fetchFatigueStatusByUserIdStart(userId));
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;
  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };

  const api = new TachoFatigueStatusApi(apiClient);
  const promise = new Promise((resolve, reject) => {
    api.fatigueStatusApiGetUser(userId, (err, data, resp) => {
      if (err && (resp == null || resp.status !== 200)) {
        console.log(err);
        reject(err);
      } else {
        resolve(resp.body);
      }
    });
  });

  try {
    const data = await promise;
    dispatch(fetchFatigueStatusByUserIdSucceeded({ userId: userId, data: data }));
  } catch (err) {
    dispatch(fetchFatigueStatusByUserIdFailed({ userId: userId, error: err.toString() }));
  }
};

export const fetchTachoCardByCompanyId = (companyId, userKey) => async (dispatch, getState) => {
  const statusId = getStatusId(statusType.companyCard, companyId);
  const fetchInfo = getState().tachoData.status[statusId];
  if (
    fetchInfo != null &&
    (fetchInfo.fetching === actionTypes.processing ||
      moment().valueOf() - fetchInfo.fetchingTime < REST_TIME)
  ) {
    return;
  }

  dispatch(fetchTachoCardByCompanyIdStart(companyId));
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;
  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };

  const api = new TachoCardUserApi(apiClient);
  const promise = new Promise((resolve, reject) => {
    api.tachoCardUsersApiGetByCompany(companyId, (err, data, resp) => {
      if (err && (resp == null || resp.status !== 200)) {
        console.log(err);
        reject(err);
      } else {
        resolve(resp.body);
      }
    });
  });

  try {
    const data = await promise;
    dispatch(fetchTachoCardByCompanyIdSucceeded({ companyId: companyId, data: data }));
  } catch (err) {
    dispatch(fetchTachoCardByCompanyIdFailed({ companyId: companyId, error: err.toString() }));
  }
};

export const fetchTachoCardByUserId = (userId, userKey) => async (dispatch, getState) => {
  const statusId = getStatusId(statusType.userCard, userId);
  const fetchInfo = getState().tachoData.status[statusId];
  if (
    fetchInfo != null &&
    (fetchInfo.fetching === actionTypes.processing ||
      moment().valueOf() - fetchInfo.fetchingTime < REST_TIME)
  ) {
    return;
  }
  dispatch(fetchTachoCardByUserIdStart(userId));
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;
  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };

  const api = new TachoCardUserApi(apiClient);
  const promise = new Promise((resolve, reject) => {
    api.tachoCardUsersApiGetByUser(userId, (err, data, resp) => {
      if (err && (resp == null || resp.status !== 200)) {
        console.log(err);
        reject(err);
      } else {
        resolve(resp.body);
      }
    });
  });

  try {
    const data = await promise;
    dispatch(fetchTachoCardByUserIdSucceeded({ userId: userId, data: data }));
  } catch (err) {
    dispatch(fetchTachoCardByUserIdFailed({ userId: userId, error: err.toString() }));
  }
};

export const useTachoStatus = () => useSelector(state => state.tachoData.status);
export const useTachoInfo = () => useSelector(state => state.tachoData.companies);
export const useCompanyFetchingStatus = companies => {
  const tachoStatus = useTachoStatus();
  if (companies == null || companies.length === 0) return false;

  return companies.some(companyId => {
    const fetchingCardStatus = tachoStatus[getStatusId(statusType.companyCard, companyId)];
    const fetchingFatigueStatus = tachoStatus[getStatusId(statusType.fatigueStatus, companyId)];
    if (
      (fetchingCardStatus && fetchingCardStatus.fetching === actionTypes.processing) ||
      (fetchingFatigueStatus && fetchingFatigueStatus.fetching === actionTypes.processing)
    ) {
      return true;
    }
    return false;
  });
};

export const useGetTachoInfoByCompanyId = companyId =>
  useSelector(state => state.tachoData.companies[companyId]);
export default tachoSlice.reducer;
