import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Space, Tooltip } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useUser } from 'features/user/userSlice';
import {
  useGetUserConfigByPrefQuery,
  useUpdateUserConfigByPrefMutation
} from 'services/nextgen/ngUserPrefConfigApi';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { orderBy } from 'lodash';

export const DB_Registered_Pref = {
  video_requests: 'video_requests.table_columns',
  camera_health_overview: 'camera_health_overview.table_columns',
  smart_jobs: 'smartjobs.table_columns'
};

const getErrorMessage = error => {
  let str = '';
  if (error?.error && typeof error?.error === 'string') {
    str = error.error;
  } else if (error?.status && typeof error?.status === 'string') {
    str = error.error;
  } else if (error?.message && typeof error?.message === 'string') {
    str = error.message;
  }
  return str;
};

export const useUserManageColumns = ({ columns, pref, manageColKey = 'actions' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useUser();
  const { data: dbVisibleColKeys, isLoading, isError, refetch } = useGetUserConfigByPrefQuery({
    userId: user.id,
    pref
  });

  const [update, { isLoading: isSaving }] = useUpdateUserConfigByPrefMutation();

  const [showModal, setShowModal] = useState(false);

  const visibleColumns = useMemo(
    () =>
      orderBy(
        columns
          .filter(col => (!dbVisibleColKeys?.length ? true : dbVisibleColKeys.includes(col.key)))
          .map(col =>
            col.key === manageColKey
              ? {
                  ...col,
                  id: col,
                  get title() {
                    return (
                      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                        {col.title}
                        <Tooltip title={t('SmartJobs.manageColumns')}>
                          <Button
                            type="text"
                            shape="circle"
                            style={{ fontSize: 18 }}
                            disabled={isLoading || isError}
                            icon={<SettingOutlined />}
                            onClick={() => setShowModal(true)}
                          />
                        </Tooltip>
                      </Space>
                    );
                  }
                }
              : col
          ),
        col => dbVisibleColKeys?.indexOf(col.key)
      ),
    [t, columns, dbVisibleColKeys, isLoading, isError, manageColKey]
  );

  const onSave = useCallback(
    columns => {
      update({ userId: user.id, pref, columns })
        .then(res => {
          if (res?.error) {
            dispatch(
              openToast({
                type: ToastType.Error,
                message: t('SmartJobs.Toasts.columnsUpdateErrorWithMessage', {
                  error: getErrorMessage(res.error)
                })
              })
            );
          } else {
            dispatch(
              openToast({
                type: ToastType.Success,
                message: t('SmartJobs.Toasts.columnsUpdateSuccess')
              })
            );
          }
        })
        .finally(() => {
          refetch();
          setShowModal(false);
        });
    },
    [update, user, pref, refetch, dispatch]
  );

  const onCancel = useCallback(() => {
    setShowModal(false);
  }, []);

  const manageModalProps = useMemo(
    () => ({
      show: showModal,
      isSaving: isSaving,
      columns: columns.map((col, colIndex) => ({
        id: col.key,
        order: dbVisibleColKeys?.indexOf(col.key),
        visible:
          col.fixedPosition || !dbVisibleColKeys?.length || dbVisibleColKeys.includes(col.key),
        title: col.title,
        canDrag: !col.fixedPosition
      })),
      onSave: onSave,
      onCancel: onCancel
    }),
    [showModal, columns, dbVisibleColKeys, isSaving, onSave, onCancel]
  );

  return { visibleColumns, manageModalProps };
};
