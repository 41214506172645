export const getAlertStatus = (alert, companyAlerts) =>
  companyAlerts?.reduce(
    (accumulator, currentValue) => {
      if (currentValue.type === alert.type) {
        // eslint-disable-next-line no-unused-expressions
        currentValue?.associations?.some(assoc => assoc.sms || assoc.email || assoc.onscreen)
          ? (accumulator.active += 1)
          : (accumulator.inactive += 1);
      }
      return accumulator;
    },
    {
      active: 0,
      inactive: 0
    }
  );
