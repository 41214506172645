import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Form, Input } from 'components/ant';
import { Modal } from 'antd';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { useUser } from 'features/user/userSlice';
import { cancelSchedule, fetchScheduleById } from 'features/driverManagement';

export const DriverManagementCancelModal = ({
  isVisible,
  handleCancel,
  scheduleData,
  cancelReason = ''
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const [form] = Form.useForm();

  const onCancel = () => {
    handleCancel();
  };

  const handleSave = async () => {
    const values = form.getFieldsValue();
    const payload = {
      cancellationParameters: JSON.stringify({
        reason: values.cancelReason,
        user_id: user?.id,
        time_at: new Date()
      })
    };

    try {
      const response = await dispatch(cancelSchedule({ id: scheduleData.id, body: payload }));
      unwrapResult(response);

      dispatch(
        openToast({
          type: ToastType.Success,
          message: `${t('VehicleMntSchedules.Notifications.ScheduleCancelled', {
            name: scheduleData?.name || ''
          })}`
        })
      );
      dispatch(fetchScheduleById({ id: scheduleData.id, query: { embed: 'events' } }));
      onCancel();
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t('VehicleMntSchedules.Notifications.ScheduleDeleteError', {
            name: scheduleData?.name || ''
          })} ${err.message}`
        })
      );
    }
  };

  return (
    <Modal
      title={t('VehicleMntSchedules.View.CancelReason')}
      open={isVisible}
      onCancel={onCancel}
      onOk={handleSave}
      okText={t('VehicleMntSchedules.View.Submit')}
      centered
    >
      <div>
        <Form layout="vertical" initialValues={{ cancelReason }} form={form} name="CancelModalForm">
          <Form.Item
            name="cancelReason"
            label={t('VehicleMntSchedules.View.EnterReason')}
            labelAlign="left"
          >
            <Input />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
