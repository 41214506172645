import React from 'react';

import { LoadingTable } from 'components/grid/LoadingTable';
import { Column, Table, AutoSizer } from 'react-virtualized';
import {
  cache,
  geofencesCellRenderer,
  nameCellRenderer,
  companyCellRenderer,
  addressCellRenderer,
  ellipsisButtonCellRenderer
} from './CellRenderers';

import { useTranslation } from 'react-i18next';
import { useCan, entities } from 'features/permissions';
import { Empty } from 'components/ant';

export const LocationsTable = ({
  locations,
  companies,
  isLoading,
  setTableRef,
  typeOfEntityToDelete,
  handleDeleteAction,
  handleAction,
  filterTab
}) => {
  const { t } = useTranslation();
  const can = useCan();
  const canView = can && can({ everyEntity: [entities.LOCATION_VIEW] });

  if (isLoading) return <LoadingTable columnSizes={[73, 94, 57, 57, 94, 19]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          geofences: 100,
          name: 300,
          company: 200,
          state: 0,
          type: 100,
          // Commented as of TN360WEB-1877 - Hide unverified tab and status column
          // status: 150,
          ellipsisButton: 60
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.geofences +
            fixedColumnsWidth.name +
            fixedColumnsWidth.company +
            fixedColumnsWidth.state +
            fixedColumnsWidth.type +
            fixedColumnsWidth.ellipsisButton);

        const columnStyle = {
          // padding: "12px 12px 12px 0"
        };

        const hideDelete = filterTab === 'deleted';
        const hideEdit = filterTab === 'deleted';
        const enableRestoreAction = filterTab === 'deleted';
        return (
          <>
            <Table
              deferredMeasurementCache={cache}
              width={width}
              height={height}
              headerHeight={52}
              headerStyle={{ paddingTop: '18px' }}
              rowClassName="tableRow"
              rowHeight={cache.rowHeight}
              rowCount={locations.length}
              rowGetter={({ index }) => locations[index]}
              ref={ref => setTableRef(ref)}
              noRowsRenderer={() => <Empty />}
            >
              <Column
                style={columnStyle}
                label={t('Locations.Table.Name')}
                dataKey="name"
                width={fixedColumnsWidth.name}
                cellRenderer={props => nameCellRenderer({ ...props, canView })}
              />
              <Column
                style={columnStyle}
                label={t('Locations.View.Company')}
                dataKey="companyId"
                width={fixedColumnsWidth.company}
                cellRenderer={props => companyCellRenderer({ location: props?.rowData, companies })}
              />
              <Column
                style={columnStyle}
                label={t('Locations.Table.Address')}
                dataKey="address"
                width={dynamicColumnWidth}
                cellRenderer={addressCellRenderer}
              />
              <Column
                style={columnStyle}
                label={t('Locations.Table.Type')}
                dataKey="type"
                width={fixedColumnsWidth.type}
                cellDataGetter={({ rowData }) =>
                  rowData.type && t(`Locations.LocationType.${rowData.type?.name}`)
                }
              />
              <Column
                style={columnStyle}
                label={t('Locations.Table.Geofences')}
                dataKey="geofences"
                width={fixedColumnsWidth.geofences}
                cellRenderer={geofencesCellRenderer}
              />
              <Column
                label={t('Locations.Table.Action')}
                dataKey=""
                style={{ overflow: 'visible' }}
                width={fixedColumnsWidth.ellipsisButton}
                cellRenderer={props =>
                  ellipsisButtonCellRenderer({
                    ...props,
                    typeOfEntityToDelete,
                    hideEdit,
                    hideDelete,
                    enableRestoreAction,
                    handleAction,
                    handleDeleteAction
                  })
                }
              />
            </Table>
          </>
        );
      }}
    </AutoSizer>
  );
};
