import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//components
import { AuditsTable } from 'components/auditsTable/AuditsTable';

//slices
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits } from 'features/audits';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';

// helpers
import { getIDFromPathname } from 'utils/methods';

//constants
import { DRIVER_ID_PATHS as PATHS } from './constants';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { useGetDriverIdConfigurationTemplateByIdQuery } from 'services/nextgen/ngDriverIdConfigurationApi';

export const DriverIdAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const audits = useAudits(AUDIT_ENTITY.CONFIGURATION_TEMPLATE, id);

  const { data: template, isFetching } = useGetDriverIdConfigurationTemplateByIdQuery(
    { templateId: id },
    { skip: id === undefined }
  );

  const pageTitle = !isFetching
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: `${template?.configurationTemplate?.name}` });

  useRedirectToMainFeaturePageOnCompanyChange(PATHS.DEFAULT);

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle, dispatch]);

  return <AuditsTable audits={audits} entity={AUDIT_ENTITY.CONFIGURATION_TEMPLATE} />;
};
