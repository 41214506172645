import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { cloneDeep, toLower } from 'lodash';
import { useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import useDebounce from 'utils/hooks/useDebounce';
import { useBranches, useIsBranchesFetched } from 'features/locations/locationsSlice';
import { useDispatch } from 'react-redux';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import LoaderTable from 'components/tables/LoaderTable';
import { useDrivers } from 'features/users/usersSlice';
import { setEWDDriver } from './driversSlice';
import { fetchEWDStatus, useEWDDriversStatus, useEWDFetchingStatus } from '../status/statusSlice';
import { driversCols } from './Columns';
import { useLocalization } from 'features/localization/localizationSlice';
import { EWDCellCache, EWDRowRender } from './RowRender';
import { FatigueToolbar } from 'features/fatigue/Components';
import { useLocation } from 'react-router';
import { useLocalCache } from 'utils/hooks/useLocalCache';
import { useUser } from 'features/user/userSlice';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { useTimer } from 'utils/hooks/useTimer';
import { canDriverMessaging, useRecipientTree } from 'features/messaging/hooks';
import { useKeeperFetchStatus, useKeepers } from '../keepers/keepersSlice';
import { actionTypes } from 'utils/reduxFetchingUtils';

const expandTableHeight = 134;

const formatListForDropdownSelect = list => {
  let updatedList = [];
  list.forEach(val => {
    const listItem = {
      label: val.name,
      checked: true,
      id: val.id
    };
    updatedList.push(listItem);
  });

  return updatedList;
};

export const EWDDriverTable = ({ filter }) => {
  const dispatch = useDispatch();
  const localization = useLocalization();
  const driverList = useDrivers();
  const location = useLocation();

  const currentUser = useUser();
  const currentCompany = useCurrentCompany();
  useEffect(() => {
    dispatch(setPageTitle('Fatigue - EWD'));
    dispatch(setBackButton(false));
    dispatch(setEWDDriver({}));
  }, [dispatch]);
  const subCompanies = useSubCompanies();

  const ewdDriversStatus = useEWDDriversStatus();
  const userPreferences = useUserPreferences();

  const allDrivers = useMemo(() => {
    return driverList?.filter(
      driver =>
        !!driver.associations?.find(assoc => {
          return assoc.domain === 'EWD' && assoc.externalId.includes('driver_id');
        })
    );
  }, [driverList]);

  const isListEmpty = allDrivers.length <= 0;
  const allBranches = useBranches();
  const isBranchesFetched = useIsBranchesFetched();
  const companies = useSubCompanies();
  const [filterText, setFilterText] = useState('');
  const [filterBranches, setFilterBranches] = useState([]);
  const debouncedSearchText = useDebounce(filterText, 300);
  const keepers = useKeepers();
  const [filterCompanies, setFilterCompanies] = useState(
    formatListForDropdownSelect([{ id: 0, name: 'All Companies' }, ...companies])
  );

  const [rowsExpandStatus, setRowsExpandStatus] = useState({});
  const [sortBy, setSortBy] = useState('Name');
  const [orderBy, setOrderBy] = useState('0');

  const [filterOptions, setFilterOptions, context] = useLocalCache('filterOptions', {
    userId: currentUser.id,
    pagePathname: location.pathname,
    companyId: currentCompany.id
  });
  const [filterOptionsContexts, setFilterOptionsContexts] = useState({});
  const {
    isCompaniesRestored,
    isBranchesRestored,
    isNormalFilterRestored,
    currentFilterOptionsContext
  } = useMemo(() => {
    const currentFilterOptionsContext = JSON.stringify(context);
    return {
      isCompaniesRestored: filterOptionsContexts[currentFilterOptionsContext]?.isCompaniesRestored,
      isBranchesRestored: filterOptionsContexts[currentFilterOptionsContext]?.isBranchesRestored,
      isNormalFilterRestored:
        filterOptionsContexts[currentFilterOptionsContext]?.isNormalFilterRestored,
      currentFilterOptionsContext
    };
  }, [context, filterOptionsContexts]);

  const getFilterBranches = useCallback(
    filterCompanies => {
      const checkedCompanies = filterCompanies?.filter(c => c.checked);
      const companiesBranches = allBranches.filter(
        b => b.type.id === 1 && checkedCompanies.find(c => c.id === b.companyId)
      );
      //always add All Branches
      companiesBranches.splice(0, 0, { name: 'All Branches', label: 'All Branches', id: 0 });

      if (!companiesBranches.find(b => b.id === -1)) {
        companiesBranches.push({ name: 'No Branch', label: 'No Branch', id: -1 });
      }
      return companiesBranches.map(b => {
        return {
          label: b.name,
          id: b.id,
          checked: true
        };
      });
    },
    [allBranches]
  );

  const fetchRef = useRef(null);
  const recipientTree = useRecipientTree();

  const keeperId = useMemo(() => {
    if (!currentCompany || !keepers) {
      return null;
    }

    const keeper = Object.values(keepers).find(k => k.externalId === currentCompany.slug);
    return keeper?.id;
  }, [currentCompany, keepers]);
  const keeperFetchStatus = useKeeperFetchStatus();

  useEffect(() => {
    if (isNormalFilterRestored) {
      return;
    }

    if (filterOptions) {
      setFilterOptionsContexts(c => ({
        ...c,
        [currentFilterOptionsContext]: {
          ...c[currentFilterOptionsContext],
          isNormalFilterRestored: true
        }
      }));
      setSortBy(
        filterOptions.sortBy &&
          driversCols.some(
            c =>
              c.sort?.name === filterOptions.sortBy ||
              c.sort?.some?.(sc => sc.name === filterOptions.sortBy)
          )
          ? filterOptions.sortBy
          : 'Name'
      );
      setOrderBy(filterOptions.orderBy || '0');
      setFilterText(filterOptions.filterText || '');
    }
  }, [filterOptions, isNormalFilterRestored, driversCols, currentFilterOptionsContext]);

  const tableDrivers = useMemo(() => {
    return allDrivers
      ?.map(d => {
        const row = cloneDeep(d);
        row.expand = false;
        row.handleExpand = expand => {
          setRowsExpandStatus(prev => {
            return { ...prev, [row.id]: expand };
          });
        };
        const driverAssociation = row.associations?.find(
          a => a.domain === 'EWD' && a.externalId.includes('driver_id')
        );
        let ewdDriverId = null;
        if (driverAssociation && driverAssociation.externalId) {
          try {
            const externalData = JSON.parse(driverAssociation.externalId);
            ewdDriverId = externalData?.driver_id;
          } catch (e) {
            console.error(e);
          }
        }
        row.ewdData = ewdDriversStatus?.[ewdDriverId];
        row.ewdDriverId = ewdDriverId;
        row.canMessaging = canDriverMessaging(recipientTree, row.id);
        return row;
      })
      .filter(row => {
        const status = row.ewdData;
        if (filter === 'Working_Driving') {
          return status?.lastStatusEvent?.type?.match(/onduty|drive|startwork|\bwork\b/i) != null;
        }

        if (filter === 'Resting') {
          return status?.lastStatusEvent?.type?.match(/rest|off|sleep|break|stopwork/i) != null;
        }
        return true;
      });
  }, [allDrivers, ewdDriversStatus, recipientTree, filter]);

  const sortColumns = useMemo(() => {
    return driversCols
      .filter(c => c.sort)
      .flatMap(c => c.sort)
      .map(c => {
        return { id: c.name, label: c.name, name: c.name, sortFn: c.sortFn };
      });
  }, [driversCols]);

  const getDriverStatus = useCallback(() => {
    if (
      allDrivers?.length <= 0 ||
      (keeperFetchStatus.fetching === actionTypes.processing && !keeperId) ||
      !currentCompany ||
      !allDrivers.some(d => d.company.id === currentCompany.id)
    )
      return;

    const sub_companies = {};
    const driverList = allDrivers?.map(d => {
      const association = d.associations.find(
        a => a.domain === 'EWD' && a.externalId.includes('driver_id')
      );
      const externalData = JSON.parse(association.externalId);
      if (d.companyId !== currentCompany.id) {
        const subCompany = subCompanies?.find(c => c.id === d.companyId);
        const subCompanyKeeper = Object.values(keepers || {}).find(
          k => k.externalId === subCompany?.slug
        );
        if (subCompany && subCompanyKeeper) {
          sub_companies[subCompany.slug] = subCompanyKeeper.id;
        }
      }
      return { id: d.id, ewdDriverId: externalData?.driver_id };
    });
    dispatch(
      fetchEWDStatus(keeperId, currentCompany.id, driverList, fetchRef, false, sub_companies)
    );
  }, [
    allDrivers,
    dispatch,
    keeperId,
    currentCompany,
    fetchRef,
    keeperFetchStatus,
    keepers,
    subCompanies
  ]);

  useEffect(() => {
    getDriverStatus();
    return () => {
      if (fetchRef.current != null) {
        fetchRef.current(); //call the assigned cancel funciton
        fetchRef.current = null;
      }
    };
  }, [getDriverStatus, fetchRef]);

  useEffect(() => {
    setFilterCompanies(
      formatListForDropdownSelect([{ id: 0, name: `All Companies` }, ...companies])
    );
  }, [companies]);

  //restore company filters
  useEffect(() => {
    if (isCompaniesRestored) {
      return;
    }

    if (filterOptions) {
      setFilterOptionsContexts(c => ({
        ...c,
        [currentFilterOptionsContext]: {
          ...c[currentFilterOptionsContext],
          isCompaniesRestored: true
        }
      }));
      setFilterCompanies(companies => {
        return companies.map(c => {
          const storedOption = filterOptions?.filterCompanies?.find(sc => sc.id === c.id);
          if (storedOption) {
            c.checked = storedOption.checked;
          }
          return c;
        });
      });
    }
  }, [filterOptions, isCompaniesRestored, currentFilterOptionsContext]);

  // Update branches based on the company filter
  useEffect(() => {
    setFilterBranches(getFilterBranches(filterCompanies));
  }, [filterCompanies, getFilterBranches]);

  //restore branches filters
  useEffect(() => {
    if (!isBranchesFetched || isBranchesRestored || !isCompaniesRestored) {
      return;
    }

    if (filterOptions?.filterBranches) {
      setFilterOptionsContexts(c => ({
        ...c,
        [currentFilterOptionsContext]: {
          ...c[currentFilterOptionsContext],
          isBranchesRestored: true
        }
      }));
      setFilterBranches(branches => {
        return branches.map(b => {
          const branchOption = filterOptions?.filterBranches?.find(fb => b.id === fb.id);
          if (branchOption) {
            b.checked = branchOption.checked;
          }
          return b;
        });
      });
    }
  }, [
    filterOptions,
    isBranchesFetched,
    isBranchesRestored,
    isCompaniesRestored,
    currentFilterOptionsContext
  ]);

  const handleExpandRows = useCallback(
    expand => {
      const rowsExpandStatus = {};
      tableDrivers &&
        tableDrivers.forEach(d => {
          rowsExpandStatus[d.id] = expand;
        });

      setRowsExpandStatus(rowsExpandStatus);
    },
    [tableDrivers]
  );

  const drivers = useMemo(() => {
    let filteredDrivers = Object.values(tableDrivers).filter(driver => {
      let validDriver = true;
      // Filter by search field
      if (debouncedSearchText) {
        const searchArray = debouncedSearchText.split(' ');
        searchArray.forEach(splitValue => {
          validDriver =
            validDriver &&
            (toLower(driver.name).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.firstName).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.lastName).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.username).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.licenceState).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.licenceNumber).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.status).indexOf(toLower(splitValue)) > -1);
        });
      }
      // Filter by companies
      const checkedCompaniesIds = filterCompanies
        .filter(company => company.checked)
        .map(company => parseInt(company.id, 10));
      if (!(checkedCompaniesIds.indexOf(0) > -1)) {
        validDriver =
          validDriver && checkedCompaniesIds.indexOf(parseInt(driver.companyId, 10)) > -1;
      }
      // Filter by branches
      const checkedBranchesIds = filterBranches
        .filter(branch => branch.checked)
        .map(branch => parseInt(branch.id, 10));
      if (!(checkedBranchesIds.indexOf(0) > -1)) {
        validDriver =
          validDriver &&
          checkedBranchesIds.indexOf(driver.location ? parseInt(driver.location.id, 10) : -1) > -1;
      }

      return validDriver;
    });

    filteredDrivers = filteredDrivers.map(driver => {
      driver.expand = rowsExpandStatus[driver.id] || false;
      driver.branch = filterBranches.find(
        branch => driver.location && branch.id === driver.location.id
      );
      driver.company = filterCompanies.find(
        company => driver.companyId && company.id === driver.companyId
      );
      return driver;
    });

    filteredDrivers.sort(sortColumns.find(c => c.name === sortBy).sortFn);
    if (orderBy === '1') {
      filteredDrivers.reverse();
    }
    return filteredDrivers;
  }, [
    tableDrivers,
    rowsExpandStatus,
    filterCompanies,
    filterBranches,
    debouncedSearchText,
    sortBy,
    sortColumns,
    orderBy
  ]);

  const rowRender = ({ ...rowProps }) => EWDRowRender({ ...rowProps, localization });
  EWDCellCache.clearAll();

  const getRowHeight = useCallback(
    ({ index, ...props }) => {
      let rowHeight = 0;
      for (let i = 0; i < driversCols.length; i++) {
        rowHeight = Math.max(rowHeight, EWDCellCache.getHeight(index, i));
      }
      if (drivers[index]?.expand) {
        rowHeight += expandTableHeight;
      }
      return rowHeight;
    },
    [drivers]
  );

  const noDriversFound = isListEmpty || drivers.length < 1;
  const NoDriversFound = () => (
    <div
      style={{
        flex: '1 0 0',
        p: 4,
        textAlign: 'center',
        color: '#b6b6b6'
      }}
    >
      No Drivers found.
    </div>
  );

  useTimer(Math.max(userPreferences?.refresh?.tracking, 60) * 1000, getDriverStatus);

  const { isFetchingDriverEWDStatus, isFetchingUserSession } = useEWDFetchingStatus(
    currentCompany?.id
  );
  const loaderTableColumns = useMemo(
    () =>
      driversCols.map(col => ({
        ...col,
        cellRenderer: col.cellRenderer
          ? props =>
              col.cellRenderer({
                ...props,
                isLoading: isFetchingDriverEWDStatus(props.rowData.ewdDriverId)
              })
          : null
      })),
    [driversCols, isFetchingDriverEWDStatus]
  );

  return (
    <div
      style={{
        display: 'flex',
        flex: '1 0 0',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <FatigueToolbar
        filterText={filterText}
        onSearchChanged={text => {
          setFilterText(text);
          setFilterOptions({
            ...filterOptions,
            filterText: text
          });
        }}
        filterCompanies={filterCompanies}
        onCompanyChanged={companies => {
          setFilterCompanies(companies);
          setFilterOptions({
            ...filterOptions,
            filterCompanies: companies,
            filterBranches: getFilterBranches(companies)
          });
        }}
        filterBranches={filterBranches}
        onBranchChanged={branches => {
          setFilterBranches(branches);
          setFilterOptions({
            ...filterOptions,
            filterBranches: branches
          });
        }}
        driverLength={drivers.length}
        onExpandRows={handleExpandRows}
        disableExpand={isFetchingUserSession}
        sortBy={sortBy}
        onSortChanged={name => {
          setSortBy(name);
          setFilterOptions({
            ...filterOptions,
            sortBy: name
          });
        }}
        sortLists={sortColumns}
        orderBy={orderBy}
        onOrderByChanged={val => {
          setOrderBy(val);
          setFilterOptions({
            ...filterOptions,
            orderBy: val
          });
        }}
        showFleetFilter={false}
      />
      <div style={{ flex: '1 0 0' }}>
        <LoaderTable
          rows={drivers}
          cols={loaderTableColumns}
          emptyResult={noDriversFound ? <NoDriversFound /> : null}
          rowHeight={getRowHeight}
          rowRenderer={rowRender}
          cellCache={EWDCellCache}
        />
      </div>
    </div>
  );
};
