import { DatePicker } from './DatePicker';
import { FormGroup, FormLabel, Col } from 'react-bootstrap';
import { Field } from 'formik';
import moment from 'moment';
import styles from './FormDatePicker.module.scss';
import { useLocalization } from 'features/localization/localizationSlice';
import cn from 'classnames';

export const FormDatePicker = ({
  name,
  xs,
  label,
  isRequired,
  setFieldValue,
  disabled,
  format: viewFormat,
  onApply,
  timePicker,
  isReadonly,
  keepZone,
  className,
  ...props
}) => {
  const localization = useLocalization();

  const datePickerClasses = cn(styles.datePicker, className);

  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <FormGroup as={Col} xs={xs}>
            <FormLabel className={!label && styles.invisible}>{label || 'Label'}</FormLabel>
            {isRequired && <span className={styles.inputRequired}>*</span>}
            <DatePicker
              className={datePickerClasses}
              showTime={timePicker}
              format={viewFormat || localization.formats.time.formats.dby}
              disabled={isReadonly || disabled || false}
              defaultValue={field.value ? moment(field.value) : null}
              value={field.value ? moment(field.value) : null}
              onChange={(date, dateString) => {
                if (keepZone) {
                  setFieldValue(name, dateString);
                } else {
                  setFieldValue(name, moment(date).toISOString());
                }

                if (onApply) {
                  onApply(moment(date).toISOString());
                }
              }}
              {...props}
            />
            {isRequired && (
              <div className={`fieldError ${meta.touched && meta.error ? 'error has' : 'error'}`}>
                {meta.touched && meta.error}
              </div>
            )}
          </FormGroup>
        );
      }}
    </Field>
  );
};
