import { ENTITIES } from 'components/alertStatusBar/constants';

import { nanoid } from 'nanoid';
import { insertIf } from 'utils/methods';

import {
  ALERT_TYPES,
  CHECKBOX_PROPERTIES,
  INPUT_PROPERTIES,
  MULTISELECT_TYPE,
  SELECT_PROPERTIES,
  usesFleetsAndVehicles,
  usesDriversAndBranches,
  eventSupportMultiTiered
} from './constants';
import {
  getAlertStatusBar,
  getCheckboxContainer,
  getSwitchContainer,
  getExpirySelect,
  getFleetsMultiselect,
  getInputNumber,
  getNameFormInput,
  getUsersMultiselect,
  getVehiclesMultiselect,
  getDriversMultiselect,
  getBranchesMultiselect,
  getMeterAlertConfiguration,
  getGPIOTypeSelect,
  getDuressTypeSelect,
  getTimeZoneSelect,
  getTimerSelect
} from './helpers/componentConfigsGetters';
import { getAlertFormOptions } from './helpers/getAlertFormOptions';
import ALERT_ACTIONS from './reducer/alertFormActions';
// import i18n from 'i18next';

const UNIQUE_IDS = {
  MULTISELECT_FORMS: String(nanoid()),
  MULTISELECT_GEOFENCE: String(nanoid()),
  MULTISELECT_MANAGED_GEOFENCE: String(nanoid()),
  MULTISELECT_PRETRIPS: String(nanoid()),
  MULTISELECT_EASYDOCSFOLDERS: String(nanoid()),
  SELECT_SPEED_LIMIT: String(nanoid()),
  SCHEDULER: String(nanoid())
};

const getFormsWithFleets = options =>
  options?.forms?.map(form => ({
    ...form,
    companyName: options?.companies?.find(company => company.id === form?.CompanyId)?.name,
    fleets: form?.Associations?.map?.(association =>
      options.fleets.find(fleet => fleet.id === association.fleetId)
    )
  }));

const getPretripChecklistsWithFleets = options => {
  return options?.pretripChecklists?.map(pretrip => ({
    ...pretrip,
    companyName: options?.companies?.find(company => company.id === pretrip?.company?.id)?.name,
    fleets: pretrip?.associations
      ? JSON.parse(pretrip?.associations)?.fleet_id?.map(id => {
          return options.fleets.find(fleet => +fleet.id === +id);
        })
      : []
  }));
};

const getDriversWithBranches = (drivers, branches) => {
  return drivers?.map(driver => ({
    ...driver,
    branch: driver?.location && (branches?.find(b => b.id === driver.location?.id)?.name || '')
  }));
};

const getBranchesWithDrivers = (branches = [], drivers = []) => {
  return branches?.map(branch => ({
    ...branch,
    drivers: drivers
      ?.filter(
        driver => driver?.location?.id === branch.id || (branch.id === -1 && !driver.location)
      )
      .map(d => `${d.firstName || ''} ${d.lastName || ''}`)
  }));
};

function getAlertConfig(
  alert,
  options,
  loaders,
  entityType,
  localization,
  can = () => false,
  initialValue = {},
  geofenceProviders
) {
  const { handleDispatch, type, state } = alert;

  return [
    getAlertStatusBar(handleDispatch),
    ...insertIf(entityType !== ENTITIES.USER, getNameFormInput(handleDispatch)),
    ...insertIf(
      type === ALERT_TYPES.ENGINE,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_EVENT_CHECKBOX,
        options.eventTypes,
        CHECKBOX_PROPERTIES.ENGINE
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.ROUTE,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_ROUTE_COMPLIANCE_CHECKBOX,
        options.routeTypes,
        CHECKBOX_PROPERTIES.ROUTE_COMPLIANCE
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.SENTINEL,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_SENTINEL_CHECKBOX,
        options.fatigueViolationsNotif,
        CHECKBOX_PROPERTIES.SENTINEL
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.DRIVER_AND_VEHICLE,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_DRIVER_AND_VEHICLE_CHECKBOX,
        options.alarmCodesLabels,
        CHECKBOX_PROPERTIES.DRIVER_AND_VEHICLE
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.DRIVER_AND_VEHICLE,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_EVENT_CHECKBOX,
        options.eventTypesForDriverVehicleAlerts,
        CHECKBOX_PROPERTIES.ENGINE_DRIVER_VEHICLE,
        'EVT-'
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.ELD,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_ELD_CHECKBOX_VIOLATIONS,
        options.eldCodesLabels,
        CHECKBOX_PROPERTIES.ELD_VIOLATIONS,
        'vio'
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.TACHO,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_TACHO_CHECKBOX,
        options.tachoLabels,
        CHECKBOX_PROPERTIES.TACHO
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.ELD,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_ELD_CHECKBOX_WARNINGS,
        options.eldAlertWarningLabels,
        CHECKBOX_PROPERTIES.ELD_WARNINGS,
        'war'
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.SMARTJOBS,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_SMARTJOBS_CHECKBOX,
        options.smartjobsOptions,
        CHECKBOX_PROPERTIES.SMARTJOBS
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.SMARTJOBS,
      getInputNumber(handleDispatch, ALERT_ACTIONS.SET_INPUT_NUMBER, INPUT_PROPERTIES.NUMBER)
    ),
    ...insertIf(type === ALERT_TYPES.DOCUMENTEXPIRATION, {
      id: UNIQUE_IDS.MULTISELECT_EASYDOCSFOLDERS,
      type: 'multiselect',
      multiSelectType: MULTISELECT_TYPE.DOCUMENTEXPIRATIONFOLDERS,
      options: getAlertFormOptions(
        options?.documentExpirationFolders,
        MULTISELECT_TYPE.DOCUMENTEXPIRATIONFOLDERS
      ),
      properties: SELECT_PROPERTIES.DOCUMENTEXPIRATIONFOLDERS,
      isLoading: loaders.isFetchingDocumentExpirationFolders,
      onChange: value =>
        handleDispatch({ type: ALERT_ACTIONS.SET_DOCUMENTEXPIRATIONFOLDERS, payload: value })
    }),
    ...insertIf(
      type === ALERT_TYPES.DOCUMENTEXPIRATION,
      getInputNumber(handleDispatch, ALERT_ACTIONS.SET_INPUT_NUMBER, INPUT_PROPERTIES.DAYSTOEXPIRE)
    ),
    ...insertIf(
      type === ALERT_TYPES.SPEED || type === ALERT_TYPES.GEOFENCE,
      getInputNumber(
        handleDispatch,
        ALERT_ACTIONS.SET_SPEED_LIMIT,
        localization.formats.speed.unit_per_hour === 'mph'
          ? INPUT_PROPERTIES.SPEED_LIMIT_MI
          : INPUT_PROPERTIES.SPEED_LIMIT_KM
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.SPEED,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_SPEED_CHECKBOX,
        options.signposted,
        CHECKBOX_PROPERTIES.SPEED
      )
    ),
    ...insertIf(type === ALERT_TYPES.GEOFENCE, {
      id: UNIQUE_IDS.MULTISELECT_GEOFENCE,
      type: 'multiselect',
      multiSelectType: MULTISELECT_TYPE.GEOFENCES,
      options: getAlertFormOptions(options?.geofences, MULTISELECT_TYPE.GEOFENCES),
      properties: SELECT_PROPERTIES.GEOFENCES,
      isLoading: loaders.isFetchingGeofences,
      onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_GEOFENCES, payload: value })
    }),
    ...insertIf(
      type === ALERT_TYPES.GEOFENCE,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_GEOFENCES_CHECKBOX,
        options.geofenceOptionsLabels,
        CHECKBOX_PROPERTIES.GEOFENCES
      )
    ),
    ...insertIf(type === ALERT_TYPES.GEOFENCE && geofenceProviders?.length > 0, {
      id: UNIQUE_IDS.MULTISELECT_MANAGED_GEOFENCE,
      type: 'multiselect',
      multiSelectType: MULTISELECT_TYPE.MANAGED_GEOFENCES,
      options: getAlertFormOptions(options?.managedGeofences, MULTISELECT_TYPE.MANAGED_GEOFENCES),
      properties: SELECT_PROPERTIES.MANAGED_GEOFENCES,
      isLoading: loaders.isFetchingGeofences,
      disable: options => !options?.length,
      onChange: value =>
        handleDispatch({ type: ALERT_ACTIONS.SET_MANAGED_GEOFENCES, payload: value })
    }),
    ...insertIf(
      type === ALERT_TYPES.GEOFENCE && geofenceProviders?.length > 0,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_MANAGED_GEOFENCES_CHECKBOX,
        options.managedGeofenceOptionsLabels,
        CHECKBOX_PROPERTIES.MANAGED_GEOFENCES,
        'MANAGED_'
      )
    ),
    ...insertIf(type === ALERT_TYPES.FORM, {
      id: UNIQUE_IDS.MULTISELECT_FORMS,
      type: 'multiselect',
      multiSelectType: MULTISELECT_TYPE.FORMS,
      options: getAlertFormOptions(getFormsWithFleets(options), MULTISELECT_TYPE.FORMS),
      properties: SELECT_PROPERTIES.FORMS,
      isLoading: loaders.isFetchingFleets,
      onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_FORMS, payload: value })
    }),
    ...insertIf(type === ALERT_TYPES.PRETRIP, {
      id: UNIQUE_IDS.MULTISELECT_PRETRIPS,
      type: 'multiselect',
      multiSelectType: MULTISELECT_TYPE.PRETRIP_CHECKLISTS,
      options: getAlertFormOptions(
        getPretripChecklistsWithFleets(options),
        MULTISELECT_TYPE.PRETRIP_CHECKLISTS
      ),
      properties: SELECT_PROPERTIES.PRETRIP_CHECKLISTS,
      isLoading: loaders.isFetchingPretrips,
      onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_PRETRIPS, payload: value })
    }),
    ...insertIf(
      type === ALERT_TYPES.PRETRIP,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_INSPECTIONS_CHECKBOX,
        options.inspectionDvirOptionsLabels,
        CHECKBOX_PROPERTIES.INSPECTIONS
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.GPIO,
      getGPIOTypeSelect(handleDispatch, options.gpioTypes, MULTISELECT_TYPE.GPIO)
    ),
    ...insertIf(
      type === ALERT_TYPES.DURESS,
      getDuressTypeSelect(handleDispatch, options.duressTypes, MULTISELECT_TYPE.DURESS)
    ),
    ...insertIf(type !== ALERT_TYPES.DOCUMENTEXPIRATION, getExpirySelect(handleDispatch)),
    ...insertIf(type === ALERT_TYPES.OOH, getTimeZoneSelect(handleDispatch, options.timezones)),
    ...insertIf(
      type === ALERT_TYPES.OOH,
      getCheckboxContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_EVENT_CHECKBOX,
        options.oohOptionsLabels,
        CHECKBOX_PROPERTIES.OOH
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.METER,
      getMeterAlertConfiguration(
        handleDispatch,
        options.companyMeterThresholds,
        loaders.isAlertMeterThresholdsFetching || loaders.isMeterThresholdsSourcesFetching
      )
    ),
    ...insertIf(
      usesFleetsAndVehicles(type),
      getVehiclesMultiselect(
        handleDispatch,
        options.vehicles.filter(v => v.id),
        options.devices,
        loaders.isFetchingFleets,
        type,
        state,
        initialValue
      )
    ),
    ...insertIf(
      usesFleetsAndVehicles(type),
      getFleetsMultiselect(
        handleDispatch,
        options.fleets.filter(f => f.id),
        loaders.isFetchingFleets
      )
    ),
    ...insertIf(
      usesDriversAndBranches(type),
      getDriversMultiselect(
        handleDispatch,
        getDriversWithBranches(options.drivers, options.branches),
        loaders.isFetchingUsers
      )
    ),
    ...insertIf(
      usesDriversAndBranches(type),
      getBranchesMultiselect(
        handleDispatch,
        getBranchesWithDrivers(options.branches, options.drivers),
        loaders.isFetchingBranches
      )
    ),
    ...(entityType === ENTITIES.COMPANY
      ? [
          getUsersMultiselect(handleDispatch, options.users, loaders.isFetchingUsers, {
            branches: options.branches
          })
        ]
      : []),
    ...insertIf(type === ALERT_TYPES.OOH, {
      id: UNIQUE_IDS.SCHEDULER,
      type: 'scheduler',
      onChange: value => handleDispatch({ type: ALERT_ACTIONS.SET_SCHEDULER_CELLS, payload: value })
    })
  ];
}

export const getMultiTieredAlertConfig = (alert, options, loaders, entityType) => {
  const { handleDispatch, type, state } = alert;
  const isMultiTieredSupported =
    (state.eventTypes || []).filter(i => eventSupportMultiTiered.includes(i)).length !== 0;

  if (!isMultiTieredSupported) {
    return [];
  }

  return [
    ...insertIf(
      type === ALERT_TYPES.DRIVER_AND_VEHICLE,
      getSwitchContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_SECOND_ALERT_SWITCH,
        CHECKBOX_PROPERTIES.SECOND_ALERT
      )
    ),
    ...insertIf(
      state.isSecondAlertEnabled === true,
      getInputNumber(
        handleDispatch,
        ALERT_ACTIONS.SET_SECOND_ALERT_TIMER,
        INPUT_PROPERTIES.SECOND_TIMER
      )
    ),
    ...insertIf(
      state.isSecondAlertEnabled === true && entityType === ENTITIES.COMPANY,
      getUsersMultiselect(
        handleDispatch,
        options.users,
        loaders.isFetchingUsers,
        {
          branches: options.branches
        },
        SELECT_PROPERTIES.SECOND_ALERT_USERS,
        ALERT_ACTIONS.SET_SECOND_ALERT_USERS,
        MULTISELECT_TYPE.SECONDALERTUSERS
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.DRIVER_AND_VEHICLE && state.isSecondAlertEnabled === true,
      getSwitchContainer(
        handleDispatch,
        ALERT_ACTIONS.SET_THIRD_ALERT_SWITCH,
        CHECKBOX_PROPERTIES.THIRD_ALERT
      )
    ),
    ...insertIf(
      type === ALERT_TYPES.DRIVER_AND_VEHICLE && state.isThirdAlertEnabled === true,
      getInputNumber(
        handleDispatch,
        ALERT_ACTIONS.SET_THIRD_ALERT_TIMER,
        INPUT_PROPERTIES.THIRD_TIMER
      )
    ),
    ...insertIf(
      state.isThirdAlertEnabled === true && entityType === ENTITIES.COMPANY,
      getUsersMultiselect(
        handleDispatch,
        options.users,
        loaders.isFetchingUsers,
        {
          branches: options.branches
        },
        SELECT_PROPERTIES.THIRD_ALERT_USERS,
        ALERT_ACTIONS.SET_THIRD_ALERT_USERS,
        MULTISELECT_TYPE.THIRDALERTUSERS
      )
    )
  ];
};

export default getAlertConfig;
