import React from 'react';

import Searchbar from './Searchbar';
import Tabs from './Tabs';

import styles from './Insights.module.scss';

const TabHeader = ({ toolbar, ...props }) => {
  return (
    <header {...props}>
      <Searchbar />
      <div className={styles.tabHeader}>
        <Tabs />
        {toolbar}
      </div>
    </header>
  );
};

export default TabHeader;
