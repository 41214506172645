/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import PositionEvent from '../model/PositionEvent';

/**
* Positions service.
* @module api/PositionsApi
* @version 1.0
*/
export default class PositionsApi {

    /**
    * Constructs a new PositionsApi. 
    * @alias module:api/PositionsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the replayEvents operation.
     * @callback module:api/PositionsApi~replayEventsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PositionEvent} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * A user token key is required for this call.
     * @param {Number} deviceId device id
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.highDefinition Set to true if you need HD data (default to false)
     * @param {String} opts.from filter range from, yyyy-MM-ddTHH:mm:ss. default to 7 days
     * @param {String} opts.to filter range to, yyyy-MM-ddTHH:mm:ss. default to now
     * @param {String} opts.orderBy sort result by: id, time_at, created_at, updated_at. default: time_at
     * @param {String} opts.direction sort direction: asc, desc. default: asc
     * @param {module:model/String} opts.pruning  (default to 'APP')
     * @param {module:api/PositionsApi~replayEventsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PositionEvent}
     */
    replayEvents(deviceId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'deviceId' is set
      if (deviceId === undefined || deviceId === null) {
        throw new Error("Missing the required parameter 'deviceId' when calling replayEvents");
      }

      let pathParams = {
        'deviceId': deviceId
      };
      let queryParams = {
        'highDefinition': opts['highDefinition'],
        'from': opts['from'],
        'to': opts['to'],
        'order_by': opts['orderBy'],
        'direction': opts['direction'],
        'pruning': opts['pruning']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PositionEvent;
      return this.apiClient.callApi(
        '/devices/{deviceId}/positions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
