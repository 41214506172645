import React from 'react';
import { VehicleTypesStatus } from './constants';
import i18n from 'i18next';
import { getRegistrationCountryLabel } from 'features/localization/localization';
import { formatMeterValue, getCalculatedBy, MeterSource } from 'features/meters';
import { getRegistrationTerritoryLabel } from 'features/localization/localization';
import { METER_TYPES, PATHS } from './constants';
import { sortStrings, formatForCsv } from 'utils/strings';
import { entities } from 'features/permissions';

import moment from 'moment';
import { toUpper } from 'lodash';

export const prepareVehicleTypes = (types = [], companyId) => {
  return types
    .filter(type => type.status === VehicleTypesStatus.ENABLED && type.companyId === companyId)
    .map(vehicleType => ({
      value: vehicleType.id,
      label: vehicleType.name
    }));
};

export const renderFleetsAndCompany = (vehicle, vehiclesBoth, companies) => {
  const fleets = ((vehiclesBoth || []).find(v => v.id === vehicle.id)?.fleets || [])
    .filter(fleet => fleet.id)
    .map((fleet, i) => {
      const companyName = companies?.find(company => fleet?.companyId === company.id)?.name;
      return (
        <div key={`row-${vehicle.id}-${i}`}>
          {`${fleet.name} ${companyName ? `(${companyName})` : ''}`}
          {i < vehicle.fleets.length - 1 ? ',' : ''}
        </div>
      );
    });
  return fleets;
};

export const prepareFileForExcelExport = data => {
  const {
    filteredVehicles,
    companies,
    countryCode,
    devicesStats,
    vehiclesStats,
    localization,
    dateFormat,
    canControlGpioConfiguration
  } = data;

  const rows = filteredVehicles
    ? filteredVehicles
        .filter(vehicle => vehicle?.name)
        .map(vehicle => {
          const fleets = (vehicle.fleets || []).map(fleet => fleet.name).join(', ');
          const devices = (vehicle.devices || []).map(device => device.name).join(', ');
          const company = companies.find(comp => comp.id === vehicle.companyId) || {};
          const vehicleStats = vehiclesStats?.find(vs => +vs.vehicleId === +vehicle.id);
          const driver =
            vehicleStats &&
            vehicleStats.currentUser &&
            `${vehicleStats.currentUser?.firstName || ''} ${vehicleStats.currentUser?.lastName ||
              ''}`;
          let lastLocation = '';
          let lastContact = '';
          (vehicle.devices || []).forEach(device => {
            const stats = devicesStats.find(ds => ds.deviceId === device.id);
            if (stats) {
              lastLocation = stats.location;
              lastContact = stats.lastCommsAt ? moment(stats.lastCommsAt).format(dateFormat) : '';
            }
          });

          let odometer = 0;
          let engineHours = 0;
          const vehiclesMeters = vehicle.meters || [];
          let odometerSource = '';
          let engineHoursSource = '';

          const rebasedVehicle =
            vehiclesMeters && vehiclesMeters.find(meter => meter.rebasedAt !== undefined);

          vehiclesMeters &&
            vehiclesMeters.forEach(meter => {
              // Skip RUC as it is no longer an option for the odometer source anymore in TN360.
              if (
                meter.type === METER_TYPES.ODOMETER &&
                toUpper(meter.source) !== MeterSource.Ruc
              ) {
                odometer = formatMeterValue(localization, meter);
                odometerSource = `${meter.source || ''} ${getCalculatedBy(meter)}`;
              }
              if (meter.type === METER_TYPES.HOURS) {
                engineHours = formatMeterValue(localization, meter);
                engineHoursSource = odometerSource = `${meter.source || ''} ${getCalculatedBy(
                  meter
                )}`;
              }
            });

          const gpioConfigurations = vehicle.devices
            ?.map((device, i) => {
              return device?.gpioTemplate?.name;
            })
            .filter((obj, index, self) => !!obj && index === self.findIndex(o => o === obj))
            .join('; ');

          const vehicleExcel = {
            ...vehicle,
            fleets,
            driver,
            devices,
            lastLocation,
            lastContact,
            odometer,
            engineHours,
            odometerSource,
            engineHoursSource,
            gpioConfigurations
          };

          const row = {
            [i18n.t('ExportToExcel.Name')]: vehicleExcel.name || '',
            [i18n.t('ExportToExcel.Company')]: company.name || '',
            [i18n.t('ExportToExcel.Fleet')]: vehicleExcel.fleets || '',
            [i18n.t('ExportToExcel.Driver')]: vehicleExcel.driver || '',
            [i18n.t('ExportToExcel.Devices')]: vehicleExcel.devices || '',
            [i18n.t('ExportToExcel.LastLocation')]: vehicleExcel.lastLocation || '',
            [i18n.t('ExportToExcel.LastContact')]: vehicleExcel.lastContact || '',
            [i18n.t('ExportToExcel.Type')]: (vehicleExcel.type || {})?.name || '',
            [getRegistrationCountryLabel(countryCode)]: vehicleExcel.registrationCountry || '',
            [getRegistrationTerritoryLabel(countryCode)]: vehicleExcel.registrationState || '',
            [i18n.t('ExportToExcel.Registration')]: vehicleExcel.registration || '',
            [i18n.t('ExportToExcel.VIN')]: vehicleExcel.vin || '',
            [i18n.t('Vehicles.CSV.ELDVehicle')]: formatForCsv(
              !!vehicleExcel?.eldVehicle ? i18n.t('Common.Yes') : i18n.t('Common.No')
            ),
            [i18n.t('ExportToExcel.EngineNumber')]: vehicleExcel.engineNumber || '',
            [i18n.t('Vehicles.View.DateInService')]: vehicleExcel?.serviceOn
              ? moment(vehicleExcel.serviceOn).format(localization.formats.time.formats.dby)
              : '',
            [i18n.t('Vehicles.View.ManufactureDate')]: vehicleExcel?.manufacturedOn
              ? moment(vehicleExcel.manufacturedOn).format(localization.formats.time.formats.dby)
              : '',
            [i18n.t('ExportToExcel.Make')]: vehicleExcel.make || '',
            [i18n.t('ExportToExcel.Model')]: vehicleExcel.model || '',
            [i18n.t('Vehicles.View.Phone')]: vehicleExcel.phone || '',
            [i18n.t('ExportToExcel.ExternalID')]: vehicleExcel.externalId || '',
            [i18n.t('ExportToExcel.Note')]: vehicleExcel.note || '',
            [i18n.t('ExportToExcel.OdometerSource')]: vehicleExcel.odometerSource || '',
            [i18n.t('ExportToExcel.Odometer')]: vehicleExcel.odometer || '',
            [i18n.t('ExportToExcel.EngineHoursSource')]: vehicleExcel.engineHoursSource || '',
            [i18n.t('ExportToExcel.EngineHours')]: vehicleExcel.engineHours || '',
            [i18n.t('Vehicles.LastCalibratedAt')]: rebasedVehicle?.rebasedAt
              ? moment(rebasedVehicle?.rebasedAt).format(dateFormat)
              : '',
            [i18n.t('ExportToExcel.EngineProfile')]:
              (vehicleExcel && vehicleExcel.engineProfile && vehicleExcel.engineProfile.name) ||
              i18n.t('Vehicles.Default'),
            [i18n.t('ExportToExcel.CreatedAt')]: moment(vehicleExcel.createdAt).format(dateFormat),
            [i18n.t('ExportToExcel.UpdatedAt')]: moment(vehicleExcel.updatedAt).format(dateFormat)
          };

          if (canControlGpioConfiguration) {
            row[i18n.t('ExportToExcel.GpioConfiguration')] = vehicleExcel.gpioConfigurations || '';
          }

          return row;
        })
    : [];

  return rows;
};

export const getPrimaryButton = ({ can, history }) => {
  if (!can({ everyEntity: [entities.VEHICLE_CREATE] })) {
    return;
  }
  return {
    name: i18n.t('Vehicles.AddNewVehicle'),
    onClick: () => {
      history.push(`${PATHS.VEHICLE_ADD}`);
    }
  };
};

export const getMoreButtons = ({
  handleExportExcel,
  handleBulkImport,
  handleExportCSV,
  isLoadingExcel,
  isExportAllowed = true
}) => {
  return [
    {
      name: i18n.t('Common.BulkImport'),
      onClick: handleBulkImport,
      canPermissions: {
        everyEntity: [entities.VEHICLE_CREATE]
      },
      id: 'btn_bulkImport'
    },
    {
      name: i18n.t('Common.ExportToCSV'),
      onClick: handleExportCSV,
      disabled: !isExportAllowed,
      id: 'btn_exportCsv'
    },
    {
      name: i18n.t('Common.ExporttoExcel'),
      onClick: handleExportExcel,
      isLoading: isLoadingExcel,
      disabled: !isExportAllowed,
      id: 'btn_exportExcel'
    }
  ].sort((a, b) => sortStrings(a.name, b.name));
};

export const prepareDataForCsvExport = (data, countryCode, canViewGpio) => {
  const result = data
    .filter(i => i.id)
    .map(entry => {
      const fleetNames = entry.fleets?.map(f => f.name)?.join('; ');
      const deviceNames = entry.devices?.map(f => f.name)?.join('; ');
      const gpioConfigurations = entry.devices
        ?.map((device, i) => {
          return device?.gpioTemplate?.name;
        })
        .filter((obj, index, self) => !!obj && index === self.findIndex(o => o === obj))
        .join('; ');

      let row = {
        [i18n.t('Vehicles.CSV.VehicleName')]: formatForCsv(entry?.name),
        [getRegistrationCountryLabel(countryCode) + '*']: formatForCsv(
          entry?.registrationCountryName
        ),
        [getRegistrationTerritoryLabel(countryCode) + '*']: formatForCsv(
          entry?.registrationStateName
        ),
        [i18n.t('Vehicles.CSV.Registration')]: formatForCsv(entry?.registration),
        [i18n.t('Vehicles.CSV.VIN')]: formatForCsv(entry?.vin),
        [i18n.t('Vehicles.CSV.VehicleTypeName')]: entry?.type?.name,
        [i18n.t('Vehicles.CSV.EngineNumber')]: formatForCsv(entry?.engineNumber),
        [i18n.t('Vehicles.CSV.DateInService')]:
          entry?.serviceOn && '="' + formatForCsv(entry?.serviceOn) + '"',
        [i18n.t('Vehicles.CSV.ManufactureDate')]:
          entry?.manufacturedOn && '="' + formatForCsv(entry?.manufacturedOn) + '"',
        [i18n.t('Vehicles.CSV.Device')]: deviceNames && formatForCsv(deviceNames),
        [i18n.t('Vehicles.CSV.PhoneNumber')]: formatForCsv(entry?.phone),
        [i18n.t('Vehicles.CSV.Note')]: formatForCsv(entry?.note),
        [i18n.t('Vehicles.CSV.Make')]: formatForCsv(entry?.make),
        [i18n.t('Vehicles.CSV.Model')]: formatForCsv(entry?.model),
        [i18n.t('Vehicles.CSV.Fleets')]: fleetNames && formatForCsv(fleetNames),
        [i18n.t('Vehicles.CSV.ExternalId')]: formatForCsv(entry?.externalId),
        [i18n.t('Vehicles.CSV.ELDVehicle')]: formatForCsv(
          !!entry?.eldVehicle ? i18n.t('Common.Yes') : i18n.t('Common.No')
        )
      };

      if (canViewGpio) {
        row[i18n.t('Vehicles.CSV.GpioConfiguration')] =
          gpioConfigurations && formatForCsv(gpioConfigurations);
      }

      return row;
    });
  return result;
};

export const doesVehicleHaveHermesDevice = vehicle => {
  return vehicle.devices?.some(device => device.type?.code === 'HERMES');
};

export const initialYearModalValue = ({ tempValue, tempValueType, searchedType, initialValue }) => {
  if (tempValueType === searchedType && tempValue) {
    if (Number.isInteger(parseInt(tempValue, 0))) {
      return tempValue;
    } else {
      return '';
    }
  }
  return initialValue;
};
