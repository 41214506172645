import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from 'components/form/form-input/FormInput';
import FormTitle from 'components/form/form-title/FormTitle';
import { Row, Col } from 'antd';
import { CountrySelect } from 'features/common/CountrySelect';
import { StateSelect } from 'features/common/StateSelect';
import styles from './ELDDriverTab.module.scss';
import { FormLabel } from 'react-bootstrap';
import { Field, useFormikContext } from 'formik';
import { getStateLabelByCountry } from 'utils/countryState';

export function ELDDriverTab({ readOnlyStatus, ...props }) {
  const { t } = useTranslation();
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');

  const form = useFormikContext();
  useEffect(() => {
    setCountry(form.values.licenceCountry);
    setState(form.values.licenceState);
  }, [form]);

  const handleCountryChanged = useCallback((value, field, form) => {
    setCountry(value);
    form.setFieldValue(field.name, value);
    form.setFieldValue('licenceNumber', '');

    if (value === 'United Kingdom') {
      setState('GB');
      form.setFieldValue('licenceState', 'GB');
    } else {
      setState('');
      form.setFieldValue('licenceState', undefined);
    }
  }, []);

  const handleStateChanged = useCallback((value, field, form) => {
    setState(value);
    form.setFieldValue(field.name, value);
  }, []);

  const regionMeta = useMemo(() => {
    return {
      label: getStateLabelByCountry(form.values.licenceCountry),
      placeholder: `${t('Common.Select')} ${getStateLabelByCountry(form.values.licenceCountry)}`
    };
  }, [form.values.licenceCountry, t]);

  return (
    <div className={styles.eldDriverTab}>
      <FormTitle title={t('Users.Form.LicenceInformation')} underlined />
      <Row className={styles.licenceContent} gutter={15}>
        <Col span={12}>
          <Field name="licenceCountry">
            {({ field, form }) => (
              <>
                <FormLabel>{t('Users.Form.LicenceCountry')}</FormLabel>
                <span className={styles.inputRequired}>*</span>
                <CountrySelect
                  {...field}
                  onChange={value => {
                    handleCountryChanged(value, field, form);
                  }}
                  size="large"
                  style={{ width: '100%' }}
                  disabled={readOnlyStatus}
                />
              </>
            )}
          </Field>
        </Col>
        <Col span={12}>
          <Field name="licenceState">
            {({ field, form }) => (
              <>
                <FormLabel>{regionMeta.label}</FormLabel>
                <span className={styles.inputRequired}>*</span>
                <StateSelect
                  {...field}
                  addCountryAsRegion={true}
                  country={country}
                  size="large"
                  onChange={value => {
                    handleStateChanged(value, field, form);
                  }}
                  style={{ width: '100%' }}
                  disabled={readOnlyStatus}
                />
              </>
            )}
          </Field>
        </Col>
        <Col span={12}>
          <FormInput
            name="licenceNumber"
            label={t('Users.Form.LicenceNumber')}
            placeholder={
              state == null || state === ''
                ? 'Please select a country and state before typing in licence number'
                : t('Users.Form.LicenceNumberPlaceholder')
            }
            isValidated
            isReadonly={readOnlyStatus || state == null || state === ''}
            isRequired
          />
        </Col>
      </Row>
    </div>
  );
}
