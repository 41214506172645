import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { LoadingCentered } from 'components/loading/Loading';
import { Button, Space, Modal, Affix } from 'antd';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import {
  handleScheduleDeleteAction,
  handleScheduleRestoreAction,
  refetchSchedules
} from 'features/vehicleMaintenance/schedulesSlice';
import {
  useVehicles,
  useIsFetchingFinished as useIsFetchingFleetsFinished
} from 'features/fleets/fleetsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import InfoRow from 'components/form/info-row/InfoRow';
import InfoTable from 'components/form/info-table/InfoTable';
import FormTitle from 'components/form/form-title/FormTitle';
import FormInput from 'components/form/form-input/FormInput';
import { Formik, Form } from 'formik';
import { useUser } from 'features/user/userSlice';
import { useUsers } from 'features/users/usersSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Can, entities } from 'features/permissions';
import { downloadDocument } from 'features/easydocs/documentsSlice';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { useVehicleMeters } from 'features/vehicles/vehiclesMetersSlice';
import { useVehicleConfig } from 'features/vehicles/vehiclesConfigsSlice';
import RescheduleModal from './rescheduleModal';
import {
  formatMeterValue,
  formatGPIOValue,
  formatEngineHours,
  formatOdometer,
  getMeterByType
} from 'features/meters';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { DATE_FORMAT } from 'features/localization/localization';

//helpers
import { getScheduledBy, formatStatus, showButton } from './utils/helpers';
import { format } from 'utils/dates';
import { api } from 'utils/api';
import { parseErrorMessage } from 'utils/strings';

//constants
import {
  SCHEDULE_BY_ID_URL,
  NoApplicableVehicleOdometer,
  BUTTON_ACTIONS,
  STATUS,
  Paths
} from './utils/constants';
import { METER_TYPES, VehicleConfig } from '../Administration/Vehicles/constants';

import styles from './VehicleMaintenanceSchedules.module.scss';
import './VehicleMaintenanceSchedules.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

// Endpoint URLS
const CANCEL_CALL = '/schedules/vehiclemaintenance/cancel/';

const ScheduleView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localization = useLocalization();
  const vehicles = useVehicles();
  const user = useUser();
  const users = useUsers();
  const [schedule, setSchedule] = useState({});
  const vehicleMeters = useVehicleMeters(schedule?.vehicle?.id);
  const [fleets, setFleets] = useState([]);
  const [parameters, setParameters] = useState({});
  const [completionParameters, setCompletionParameters] = useState({});
  const [cancellationParameters, setCancellationParameters] = useState({});
  const history = useHistory();
  const hasFetchedFleets = useIsFetchingFleetsFinished();
  const [currentOdometer, setCurrentOdometer] = useState(0);
  const [currentEngineHours, setCurrentEngineHours] = useState(0);
  const [gpioMeterType, setGPIOMeterType] = useState(null);
  const [currentGPIO, setCurrentGPIO] = useState(0);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const vehicleConfig = useVehicleConfig(schedule?.vehicle?.id, VehicleConfig.OdometerForMnt.value);
  let reason = '';

  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );

  useEffect(() => {
    if (schedule.name) {
      dispatch(setPageTitle(schedule.name));
      dispatch(setBackButton(true));
    }
  }, [schedule.name, dispatch]);

  useEffect(() => {
    const getScheduleById = async () => {
      if (!schedule.name && user && id) {
        try {
          const res = await api.get(`${SCHEDULE_BY_ID_URL}/${id}`, {
            authKey: user.auth.key,
            query: {
              embed: 'vehicle,user,events,company,managetype,previousMaintenance',
              pruning: 'ALL'
            }
          });
          setSchedule(res.body);
        } catch (error) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: parseErrorMessage(error)
            })
          );
        }
      }
    };
    getScheduleById();
  }, [schedule.name, user, id, dispatch]);

  useEffect(() => {
    const completionParameters =
      schedule.completionParameters !== 'null' ? schedule.completionParameters : '{}';
    const cancellationParameters =
      schedule.cancellationParameters !== 'null' ? schedule.cancellationParameters : '{}';
    try {
      setParameters(JSON.parse(schedule?.parameters || '{}'));
    } catch (e) {
      console.error(e);
    }
    try {
      setCompletionParameters(JSON.parse(completionParameters || '{}'));
    } catch (e) {
      console.error(e);
    }
    try {
      setCancellationParameters(JSON.parse(cancellationParameters || '{}'));
    } catch (e) {
      console.error(e);
    }
  }, [schedule]);

  useEffect(() => {
    if (vehicles.length && schedule.vehicle) {
      const fleets = (vehicles.find(vehicle => vehicle.id === schedule.vehicle.id) || {}).fleets;
      if (fleets && fleets[0].name !== undefined) {
        setFleets(fleets);
      }
    }
  }, [vehicles, schedule]);

  useEffect(() => {
    if (schedule.vehicle) {
      const meter = { meters: vehicleMeters || [] };
      const odometerMeter = getMeterByType(meter, METER_TYPES.ODOMETER);
      const engineHoursMeter = getMeterByType(meter, METER_TYPES.HOURS);
      let gpioMeter = null;
      const isGPIOCheckboxEnabled = meter.meters?.some(
        meter => meter.maintenanceType === 'TIME' && meter.source === 'gpio'
      );

      if (isGPIOCheckboxEnabled) {
        let gpioMeterType;
        const gpioTypeValues = meter.meters
          ?.filter(meter => meter.maintenanceType === 'TIME' && meter.source === 'gpio')
          .map(meter => ({
            value: meter.type,
            label: t(`Vehicles.GPIO.${meter.type}`)
          }));
        gpioMeterType = (gpioTypeValues || [])?.find(type => {
          let foundGPIOType = false;
          Object.keys(parameters).forEach(key => {
            if (key.includes(type?.value)) {
              foundGPIOType = true;
            }
          });
          return foundGPIOType;
        })?.value;
        setGPIOMeterType(gpioMeterType);

        gpioMeter = getMeterByType(meter, gpioMeterType);
      }
      const odometerValue = odometerMeter ? formatMeterValue(localization, odometerMeter) : 0;
      const engineHours = engineHoursMeter ? formatMeterValue(localization, engineHoursMeter) : 0;
      const gpioValue = gpioMeter ? formatGPIOValue(localization, gpioMeter) : '-';
      if (vehicleConfig?.isUsingRUCOdometerForMnt) {
        setCurrentOdometer(formatOdometer(localization, vehicleConfig?.rucOdometer || 0));
      } else {
        setCurrentOdometer(odometerValue);
      }
      setCurrentEngineHours(engineHours);
      setCurrentGPIO(gpioValue);
    }
  }, [vehicleMeters, schedule, localization, vehicleConfig, parameters]);

  const handleDocumentDownload = document => () => dispatch(downloadDocument(document.id));

  // Get the documents attached to this schedule
  const getScheduleDocuments = () => {
    const { documents } = completionParameters;

    if (!documents || !documents.length) return;

    return documents.map(document => (
      <div
        className={styles.linkStyle}
        key={document.id}
        onClick={handleDocumentDownload(document)}
      >
        {document.filename || document.id}
      </div>
    ));
  };

  const getScheduledOdometer = parameters => {
    if (!parameters) return;
    const odometer = formatOdometer(localization, parameters.odometer) || '-';
    const odometerAlert = formatOdometer(localization, parameters.alert_odometer) || '-';
    return `${odometer} / ${odometerAlert}`;
  };

  const getScheduledEngineHours = parameters => {
    if (!parameters) return;
    const engineHours = formatEngineHours(localization, parameters.engine_hours) || '-';
    const engineHoursAlert = formatEngineHours(localization, parameters.alert_engine_hours) || '-';
    return `${engineHours} / ${engineHoursAlert}`;
  };

  const getScheduledGPIOHours = (parameters, meterType) => {
    if (!parameters) return;
    const engineHours = formatEngineHours(localization, parameters[meterType]) || '-';
    const engineHoursAlert =
      formatEngineHours(localization, parameters[`alert_${meterType}`]) || '-';
    return `${engineHours} / ${engineHoursAlert}`;
  };

  const getDaysBeforeAfter = parameters => {
    if (!parameters) return;
    const daysBefore = parameters.days
      ? `${parameters.days} ${t('VehicleMntSchedules.View.Days')}`
      : '-';
    const daysAfter = parameters.overdue_days
      ? `${parameters.overdue_days} ${t('VehicleMntSchedules.View.Days')}`
      : '-';
    return `${daysBefore} / ${daysAfter}`;
  };

  const cancelContent = (
    <Formik initialValues={{ reason: cancellationParameters.reason }}>
      {({ setFieldValue }) => (
        <Form>
          <FormInput
            name="reason"
            label={t('VehicleMntSchedules.View.EnterReason')}
            placeholder=""
            onChange={reason => handleOnChange(reason, setFieldValue)}
          />
        </Form>
      )}
    </Formik>
  );

  const handleOnChange = (newReason, setFieldValue) => {
    reason = newReason;
    setFieldValue('reason', reason);
  };

  const onSubmitCancelAction = async reason => {
    const body = {
      cancellationParameters: JSON.stringify({
        reason,
        user_id: user.id,
        time_at: moment().format()
      }),
      updatedAt: moment().format()
    };
    const response = await api.put(
      `${CANCEL_CALL}${schedule.id}`,
      {
        authKey: user.auth.key
      },
      body
    );
    if (response && response.body) {
      const res = await api.get(`${SCHEDULE_BY_ID_URL}/${id}`, {
        authKey: user.auth.key,
        query: { embed: 'vehicle,user,events,company,managetype', pruning: 'ALL' }
      });
      setSchedule(res.body);
      dispatch(refetchSchedules(res.body || { id }));
      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('VehicleMntSchedules.Notifications.ScheduleCancelled', {
            name: schedule.name
          })
        })
      );
    }
  };

  const handleRescheduleModal = () => {
    updateRescheduleModalVisibility(true);
  };

  const handleCancelModal = cancelType => {
    Modal.warning({
      title: `${t('VehicleMntSchedules.View.CancelReason')}`,
      content: cancelContent,
      okCancel: true,
      okText: `${t('VehicleMntSchedules.View.Submit')}`,
      cancelText: `${t('VehicleMntSchedules.View.Cancel')}`,
      onOk: () => {
        Mixpanel.sendTrackEvent(MPTrackingEvents.VehicleMaintenance.Schedule.cancel, {
          type: cancelType,
          reason,
          typeName: schedule?.manageType?.name,
          serviceName: schedule.name
        });
        onSubmitCancelAction(reason);
      },
      width: 600
    });
  };

  const handleDeleteModal = () => {
    confirmationModal(
      `${t('Common.DeleteButton')} ${schedule.name}`,
      `${t('Common.SureDelete')} ${t('Common.vehicleMntSchedule')} ${schedule.name}?`,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      handleDeleteAction(schedule, t, history),
      'delete'
    );
  };

  const handleDeleteAction = (schedule, t, history) => () => {
    dispatch(handleScheduleDeleteAction({ schedule, t, history }));
  };

  const handleRestoreAction = schedule => () => {
    dispatch(handleScheduleRestoreAction({ schedule, t }));
  };

  const eventsColumn = [
    {
      label: `${t('VehicleMntSchedules.View.Date')}`,
      width: 300,
      cellDataGetter: ({ rowData }) =>
        format(rowData.date, localization.formats.time.formats.dby_imp)
    },
    {
      label: `${t('VehicleMntSchedules.View.Status')}`,
      width: 300,
      cellDataGetter: ({ rowData }) =>
        !!rowData.status && t(`VehicleMntSchedules.Table.${formatStatus(rowData.status)}`)
    }
  ];

  const updateRescheduleModalVisibility = bool => {
    setShowRescheduleModal(bool);
  };

  if (!Object.keys(schedule).length) {
    return <LoadingCentered />;
  }

  return (
    <>
      <RescheduleModal
        scheduleId={id}
        show={showRescheduleModal}
        updateRescheduleModalVisibility={updateRescheduleModalVisibility}
        from={'viewSchedule'}
      />
      <Affix offsetTop={63}>
        <div className={styles.scheduleHeader}>
          <div style={{ display: 'flex', color: '#000000' }}>
            <div className={styles.scheduleName}>{schedule.name}</div>
            <div>
              <span
                style={{
                  display: 'inline-block',
                  width: '12px',
                  height: '12px',
                  borderRadius: '10px',
                  marginRight: '9px'
                }}
                className={styles[`status_${(schedule?.status || '').toLowerCase()}`]}
              ></span>
              {!!schedule.status && t(`VehicleMntSchedules.${formatStatus(schedule.status)}`)}
            </div>
          </div>
          <div>
            {schedule && schedule.status && (
              <Space size={16}>
                {showButton({ buttonType: BUTTON_ACTIONS.EDIT, data: schedule }) && (
                  <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_UPDATE]}>
                    <Button size="large" id={BUTTON_IDS.scheduleViewEdit}>
                      <Link
                        to={{
                          pathname: `${Paths.VEHICLEMAINTENANCE_EDIT}/${schedule.id}`,
                          state: { dataToEdit: schedule }
                        }}
                      >
                        {t('VehicleMntSchedules.View.Edit')}
                      </Link>
                    </Button>
                  </Can>
                )}
                {showButton({ buttonType: BUTTON_ACTIONS.COMPLETE, data: schedule }) && (
                  <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_UPDATE]}>
                    <Button size="large" id={BUTTON_IDS.scheduleViewEllipsis}>
                      <Link to={`${Paths.VEHICLEMAINTENANCE_COMPLETE}/${schedule.id}`}>
                        {schedule?.status === STATUS.completed
                          ? t('Common.EllipsisButton.EditCompletedInfo')
                          : t('Common.EllipsisButton.Complete')}
                      </Link>
                    </Button>
                  </Can>
                )}
                {showButton({ buttonType: BUTTON_ACTIONS.CANCEL, data: schedule }) && (
                  <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_UPDATE]}>
                    <Button
                      size="large"
                      danger
                      onClick={() => handleCancelModal('cancel')}
                      id={BUTTON_IDS.scheduleViewCancel}
                    >
                      {schedule?.status === STATUS.cancelled
                        ? t('VehicleMntSchedules.View.EditCancelledInfo')
                        : t('Common.Cancel')}
                    </Button>
                  </Can>
                )}
                {showButton({ buttonType: BUTTON_ACTIONS.RESCHEDULE, data: schedule }) && (
                  <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_UPDATE]}>
                    <Button
                      size="large"
                      onClick={handleRescheduleModal}
                      id={BUTTON_IDS.scheduleViewReschedule}
                    >
                      {t('VehicleMntSchedules.View.Reschedule')}
                    </Button>
                  </Can>
                )}
                {showButton({ buttonType: BUTTON_ACTIONS.AUDIT, data: schedule }) && (
                  <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_AUDIT]}>
                    <Button size="large" id={BUTTON_IDS.scheduleViewAudits}>
                      <Link
                        to={{
                          pathname: `${Paths.VEHICLEMAINTENANCE_AUDITS}/${schedule.id}`,
                          state: { dataToEdit: schedule }
                        }}
                      >
                        {t('Common.AuditsButton')}
                      </Link>
                    </Button>
                  </Can>
                )}
                {showButton({ buttonType: BUTTON_ACTIONS.RESTORE, data: schedule }) && (
                  <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_RESTORE]}>
                    <Button
                      size="large"
                      onClick={handleRestoreAction(schedule)}
                      id={BUTTON_IDS.scheduleViewRestore}
                    >
                      {t('Common.RestoreButton')}
                    </Button>
                  </Can>
                )}
                {showButton({ buttonType: BUTTON_ACTIONS.DELETE, data: schedule }) && (
                  <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_DESTROY]}>
                    <Button
                      size="large"
                      danger
                      onClick={handleDeleteModal}
                      id={BUTTON_IDS.scheduleViewDelete}
                    >
                      {t('VehicleMntSchedules.View.Delete')}
                    </Button>
                  </Can>
                )}
              </Space>
            )}
          </div>
        </div>
      </Affix>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 32px',
          color: '#181c21'
        }}
      >
        <FormTitle title={t('VehicleMntSchedules.View.VehicleMntInformation')} underlined />
        <InfoRow
          label={t('VehicleMntSchedules.View.ServiceName')}
          value={schedule.name}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.Company')}
          value={schedule.company && schedule.company.name}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.Fleet')}
          value={
            <>
              {schedule.name && hasFetchedFleets ? (
                fleets.length > 0 ? (
                  fleets.map((fleet, index) => <div key={`key-${index}`}>{fleet.name}</div>)
                ) : (
                  `${t('VehicleMntSchedules.View.NoFleet')}`
                )
              ) : (
                <span>{`${t('Home.Loading')}...`}</span>
              )}
            </>
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.Vehicle')}
          value={
            schedule.vehicle &&
            schedule.vehicle.name && (
              <Link to={`/settings/vehicles/id/${schedule.vehicle.id}`}>
                {schedule.vehicle.name}
              </Link>
            )
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.MntType')}
          value={
            schedule.manageType &&
            schedule.manageType.name && (
              <Link to={`/settings/vehicleMntTypes/id/${schedule.manageType.id}`}>
                {schedule.manageType.name}
              </Link>
            )
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.ScheduledBy')}
          value={getScheduledBy(schedule, users)}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.ScheduledDueDate')}
          value={
            schedule.schedulesOn &&
            format(schedule.schedulesOn.split('+')[0], localization.formats.time.formats.dby)
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.ScheduledOdometer')}
          value={getScheduledOdometer(parameters)}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.OverdueOdometer')}
          value={formatOdometer(localization, parameters.overdue_odometer) || '-'}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.ScheduledEngineHours')}
          value={getScheduledEngineHours(parameters)}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.OverdueEngineHours')}
          value={formatEngineHours(localization, parameters.overdue_engine_hours) || '-'}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.DaysBeforeDaysAfter')}
          value={getDaysBeforeAfter(parameters)}
          styles={styles}
        />
        <>
          <InfoRow
            label={
              gpioMeterType
                ? `${t('VehicleMntSchedules.View.ScheduledGPIOType', {
                    type: t(`Vehicles.GPIO.${gpioMeterType}`)
                  })}`
                : t('VehicleMntSchedules.View.ScheduledGPIO')
            }
            value={getScheduledGPIOHours(parameters, gpioMeterType)}
            styles={styles}
          />
          <InfoRow
            label={
              gpioMeterType
                ? `${t('VehicleMntSchedules.View.OverdueGPIO')} ${t(
                    `Vehicles.GPIO.${gpioMeterType}`
                  )}`
                : t('VehicleMntSchedules.View.OverdueGPIO')
            }
            value={formatEngineHours(localization, parameters[`overdue_${gpioMeterType}`]) || '-'}
            styles={styles}
          />
        </>
        <InfoRow
          label={t('VehicleMntSchedules.View.Status')}
          value={
            schedule.status ? t(`VehicleMntSchedules.Table.${formatStatus(schedule.status)}`) : '-'
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.Registration')}
          value={schedule?.vehicle?.registration || '-'}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.Completed')}
          value={
            completionParameters.service_on &&
            format(
              moment(completionParameters.service_on, DATE_FORMAT.DEFAULT),
              localization.formats.time.formats.dby
            )
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.LastMileage')}
          value={
            schedule.previousMaintenance
              ? `${t('VehicleMntSchedules.View.Odometer')}: ${localization.formatDistance(
                  JSON.parse(schedule.previousMaintenance?.completionParameters)?.odometer
                )}`
              : ''
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.CurrentOdometer')}
          value={
            schedule.vehicle
              ? vehicleConfig?.isUsingRUCOdometerForMnt && !vehicleConfig?.hasEDRDevice
                ? NoApplicableVehicleOdometer
                : currentOdometer
              : '-'
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.CurrentEngineHours')}
          value={schedule.vehicle ? currentEngineHours : '-'}
          styles={styles}
        />
        <InfoRow
          label={
            gpioMeterType
              ? `${t('VehicleMntSchedules.View.CurrentGPIO')} ${t(
                  `Vehicles.GPIO.${gpioMeterType}`
                )}`
              : t('VehicleMntSchedules.View.CurrentGPIO')
          }
          value={schedule.vehicle ? currentGPIO : '-'}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.CreatedAt')}
          value={
            schedule.createdAt &&
            format(schedule.createdAt, localization.formats.time.formats.dby_imp)
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleMntSchedules.View.UpdatedAt')}
          value={
            schedule.updatedAt &&
            format(schedule.updatedAt, localization.formats.time.formats.dby_imp)
          }
          styles={styles}
        />
        {Object.keys(cancellationParameters).length > 0 && (
          <>
            <FormTitle title={t('VehicleMntSchedules.View.CancelledMntRecord')} underlined />
            <InfoRow
              label={t('VehicleMntSchedules.View.MntCancelledAt')}
              value={format(
                new Date(cancellationParameters.time_at),
                localization.formats.time.formats.dby
              )}
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.MntCancelledBy')}
              value={cancellationParameters.user_name}
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.Reason')}
              value={cancellationParameters.reason}
              styles={styles}
            />
          </>
        )}
        {Object.keys(cancellationParameters).length === 0 && (
          <FormTitle title={t('VehicleMntSchedules.View.CompletedMaintenanceRecord')} underlined />
        )}
        {Object.keys(cancellationParameters).length === 0 &&
          Object.keys(completionParameters).length === 0 && (
            <InfoRow
              label={t('VehicleMntSchedules.View.MntNotComplete')}
              styles={styles}
              labelWidth="100%"
            />
          )}
        {Object.keys(completionParameters).length > 0 && (
          <>
            <InfoRow
              label={t('VehicleMntSchedules.View.ServiceDate')}
              value={
                completionParameters.service_on &&
                format(
                  moment(completionParameters.service_on, DATE_FORMAT.DEFAULT),
                  localization.formats.time.formats.dby
                )
              }
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.Odometer')}
              value={formatOdometer(localization, completionParameters.odometer)}
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.EngineHours')}
              value={formatEngineHours(localization, completionParameters.engine_hours)}
              styles={styles}
            />
            <InfoRow
              label={
                gpioMeterType
                  ? `${t('VehicleMntSchedules.View.GPIOType', {
                      type: t(`Vehicles.GPIO.${gpioMeterType}`)
                    })}`
                  : t('VehicleMntSchedules.View.GPIOHours')
              }
              value={
                gpioMeterType && completionParameters[gpioMeterType]
                  ? formatEngineHours(localization, completionParameters[gpioMeterType])
                  : '-'
              }
              styles={styles}
            />
            <InfoRow
              label={`${t('VehicleMntSchedules.View.CostOfService')} (${
                localization.formats.currency.unit
              })`}
              value={completionParameters.service_cost}
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.InvoiceNumber')}
              value={completionParameters.invoice_number}
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.MntRaisedBy')}
              value={
                schedule.user &&
                `${schedule.user?.firstName || ''} ${schedule.user?.lastName || ''}`
              }
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.MntSignedOffBy')}
              value={completionParameters.signed_off_by}
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.MntCompletedBy')}
              value={completionParameters.completed_by}
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.InternalOrderNumber')}
              value={completionParameters.order_number}
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.Notes')}
              value={completionParameters.notes}
              styles={styles}
            />
            <InfoRow
              label={t('VehicleMntSchedules.View.Documents')}
              value={getScheduleDocuments()}
              styles={styles}
            />
          </>
        )}
        <FormTitle title={t('VehicleMntSchedules.View.VehicleScheduleEvents')} underlined />
        {schedule.events && schedule.events.length > 0 && (
          <InfoRow
            label=""
            labelWidth={'0%'}
            hideBorder
            value={
              <InfoTable
                data={schedule.events || []}
                columns={eventsColumn}
                styles={styles}
                customRowStyle={{ alignItems: 'center' }}
              />
            }
            styles={styles}
            sxValue={{
              width: '100%',
              minHeight: `${schedule.events ? schedule.events.length * 64 + 32 : 0}px`
            }}
          />
        )}
      </div>
    </>
  );
};

export default ScheduleView;
