import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useFleetsWithDynamicLinkVehicleDevice } from 'features/vehicles/hooks';
import { useIsFetchingVehicleStats } from 'features/vehicles/vehiclesStatsSlice';
import { useIsFetchingDevicesStats } from 'features/devices/devicesStatsSlice';
import { useIsFetching as useIsFetchingGeofences } from 'features/geofences/geofencesSlice';
import { useDevices, useIsFetchingDevices } from 'features/devices/devicesSlice';
import { useDevicesStats } from 'features/devices/devicesStatsSlice';
import { useVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import { useGeofences } from 'features/geofences/geofencesSlice';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { useRunsheets, useIsFetchingRunsheets } from 'features/smartJobs/smartJobsSlice';

import { isMessagingEnabledDevice, isRouteToEnabledDevice } from 'containers/Messaging/helpers';
import { trackingTimeTypes } from 'containers/Settings/user/Preferences/Preferences';

import { RunsheetStatus } from 'containers/SmartJobs/utils/constants';

import { vehicleActiveDeviceComparison } from 'containers/Tracking/helpers';

const getTrackingData = (
  fleetData,
  deviceStats,
  deviceServices,
  vehiclesStats,
  geofences,
  userPreferences,
  runsheets,
  t
) => {
  const fleets = [];
  const devicesMap = {};
  const vehiclesStatsMap = {};
  const deviceStatsMap = {};

  const setDeviceMap = (device, vehicle, fleet, lastRuc, lastRucAt, lastEdr, lastEdrAt) => {
    let currentUser;
    if (deviceStatsMap[device.id]) {
      if (!devicesMap[device.id]) {
        currentUser =
          vehiclesStatsMap[vehicle.id]?.currentUser || deviceStatsMap[device.id].currentUser;
        let isVehicleDefaultDriver =
          currentUser?.id &&
          vehicle.defaultDriver &&
          String(currentUser.id) === String(vehicle.defaultDriver);

        devicesMap[device.id] = {
          ...device,
          vehicleId: vehicle.id,
          icon: vehicle.type?.icon,
          markerType: fleet.iconType,
          markerSize: fleet.iconSize,
          markerColor: fleet.colour,
          vehicleName: vehicle.name ? vehicle.name : '',
          vehicleNames: vehicle.name ? [vehicle.name] : [],
          vehicleRegistration: vehicle.registration,
          vehicleRegistrationState: vehicle.registrationState,
          vehicleImage: vehicle.imageUrl,
          fleetId: fleet.id,
          fleetName: fleet.id ? fleet.name : t('Common.NoFleet'),
          fleetNames: [fleet.id ? fleet.name : t('Common.NoFleet')],
          fleets: [
            {
              id: fleet.id ? fleet.id : -1,
              name: fleet.id ? fleet.name : t('Common.NoFleet')
            }
          ],
          driver: currentUser ? { ...currentUser, isVehicleDefaultDriver } : currentUser,
          driverName: currentUser
            ? currentUser.firstName + ' ' + currentUser.lastName
            : t('Tracking.NotLoggedIn'),
          driverImage: currentUser?.imageUrl,
          deviceStats: deviceStatsMap[device.id],
          vehicleStats: vehiclesStatsMap[vehicle.id],
          lastRuc: lastRuc,
          lastRucAt: lastRucAt,
          lastEdr: lastEdr,
          lastEdrAt: lastEdrAt,
          vehicleType: vehicle?.type?.name
        };
      } else {
        vehicle.name && devicesMap[device.id].vehicleNames.push(vehicle.name);
        devicesMap[device.id].fleetNames.push(fleet.id ? fleet.name : t('Common.NoFleet'));
        devicesMap[device.id].fleets.push({
          id: fleet.id ? fleet.id : -1,
          name: fleet.id ? fleet.name : t('Common.NoFleet')
        });
      }
    }
  };

  deviceStats.forEach(stats => {
    if (stats.gps) {
      let vehicleActive = true;
      const trackingTimeValue = userPreferences?.trackingTimeValue || 3;
      let timeDifference = 0;

      switch (userPreferences?.trackingTimeType) {
        case trackingTimeTypes.minutes:
          timeDifference = moment().diff(moment(stats.lastEventAt), 'minutes');
          break;
        case trackingTimeTypes.hours:
          timeDifference = moment().diff(moment(stats.lastEventAt), 'hours');
          break;
        case trackingTimeTypes.days:
          timeDifference = moment().diff(moment(stats.lastEventAt), 'days');
          break;
        case trackingTimeTypes.weeks:
          timeDifference = moment().diff(moment(stats.lastEventAt), 'weeks');
          break;
        default:
          timeDifference = moment().diff(moment(stats.lastEventAt), 'months');
          break;
      }
      if (timeDifference > trackingTimeValue) {
        // Filter out inactive vehicles per User Preferences setting
        vehicleActive = false;
      }
      if (vehicleActive) {
        deviceStatsMap[stats.deviceId] = stats;
      }
    }
  });

  vehiclesStats.forEach(stats => {
    vehiclesStatsMap[stats.vehicleId] = stats;
  });

  fleetData.forEach(fleet => {
    fleets.push({
      id: fleet.id ? fleet.id : -1,
      name: fleet.id ? fleet.name : t('Common.NoFleet')
    });

    fleet.vehicles.forEach(vehicle => {
      let lastRuc, lastRucAt, lastEdr, lastEdrAt;
      (vehicle.devices || []).forEach(device => {
        device.services = deviceServices[device.id]?.services || [];
        if (deviceStatsMap[device.id]) {
          lastRuc =
            !lastRuc && deviceStatsMap[device.id].lastRuc
              ? deviceStatsMap[device.id].lastRuc
              : lastRuc;
          lastRucAt =
            !lastRucAt && deviceStatsMap[device.id].lastRucAt
              ? deviceStatsMap[device.id].lastRucAt
              : lastRucAt;
          lastEdr =
            !lastEdr && deviceStatsMap[device.id].lastEdr
              ? deviceStatsMap[device.id].lastEdr
              : lastEdr;
          lastEdrAt =
            !lastEdrAt && deviceStatsMap[device.id].lastEdrAt
              ? deviceStatsMap[device.id].lastEdrAt
              : lastEdrAt;
        }
      });
      if (vehicle.devices) {
        if (vehicle.name && vehicle.devices?.length) {
          const device = vehicle.devices.sort((deviceA, deviceB) =>
            vehicleActiveDeviceComparison(
              { ...deviceA, deviceStats: deviceStatsMap[deviceA.id] },
              { ...deviceB, deviceStats: deviceStatsMap[deviceB.id] }
            )
          )[0];
          // device.services?.includes('MESSAGING') && console.debug('device.services', device.name, device.services);
          device.interactiveDevices = vehicle.devices.filter(isMessagingEnabledDevice);
          device.messagingDevice = vehicle.devices.some(isMessagingEnabledDevice);
          device.routeToDevice = vehicle.devices.find(isRouteToEnabledDevice)?.imei;
          device.cameraDeviceId = vehicle.devices.find(d => d?.type?.code === 'CAMERA')?.id;
          setDeviceMap(device, vehicle, fleet, lastRuc, lastRucAt, lastEdr, lastEdrAt);
        } else {
          vehicle.devices.forEach(device => {
            // device.services?.includes('MESSAGING') && console.debug('device.services', device.name, device.services);
            device.messagingDevice = isMessagingEnabledDevice(device);
            device.routeToDevice = isRouteToEnabledDevice(device) ? device.imei : null;
            device.cameraDeviceId = device?.type?.code === 'CAMERA' ? device.id : null;
            setDeviceMap(device, vehicle, fleet, lastRuc, lastRucAt, lastEdr, lastEdrAt);
          });
        }
      }
    });
  });

  const devices = Object.values(devicesMap);

  if (runsheets) {
    const openRunsheets = runsheets.filter(runsheet => runsheet.status === RunsheetStatus.OPENED);

    openRunsheets.forEach(runsheet => {
      // Find by vehicle id first then by device id
      let foundDevice = null;
      if (runsheet.vehicle) {
        foundDevice = devices.find(device => device.vehicleId === runsheet.vehicle.id);
      }
      if (!foundDevice && runsheet.device) {
        foundDevice = devicesMap[runsheet.device.id];
      }

      if (foundDevice) {
        // remove circular references and sub objects like carrier, device, user, vehicle
        const { carrier, device, user, vehicle, ...runsheetNonCircular } = runsheet;
        if (!foundDevice.runsheets) {
          foundDevice.runsheets = [runsheetNonCircular];
        } else {
          foundDevice.runsheets.push(runsheetNonCircular);
        }
      }
    });
  }

  return {
    fleets: fleets,
    devices: devices,
    devicesMap: devicesMap,
    geofences: geofences,
    isLoading: false
  };
};

export const useTrackingData = () => {
  const { t } = useTranslation();
  const userPreferences = useUserPreferences();
  const { fleets, isFetchingFleets } = useFleetsWithDynamicLinkVehicleDevice();
  const deviceServices = useDevices('services');
  const vehicleStats = useVehiclesStats();
  const devicesStats = useDevicesStats();
  const geofences = useGeofences();
  const runSheets = useRunsheets();
  const isFetchingDeviceServices = useIsFetchingDevices();
  const isFetchingVehicleStats = useIsFetchingVehicleStats();
  const isFetchingDevicesStats = useIsFetchingDevicesStats();
  const isFetchingGeofences = useIsFetchingGeofences();
  const isRunSheetsLoading = useIsFetchingRunsheets();

  const isLoading =
    isFetchingFleets ||
    isFetchingVehicleStats ||
    isFetchingDevicesStats ||
    isFetchingDeviceServices ||
    isFetchingGeofences ||
    isRunSheetsLoading;

  const [trackingData, setTrackingData] = useState({
    fleets: [],
    devices: [],
    devicesMap: {},
    geofences: [],
    isLoading: isLoading
  });

  useEffect(() => {
    if (isLoading) return;

    // console.debug('useTrackingData', { vehicleStats, devicesStats });

    setTrackingData(
      getTrackingData(
        fleets,
        devicesStats.filter(
          deviceStats => deviceStats.gps && deviceStats.gps.Lat && deviceStats.gps.Lng
        ),
        deviceServices,
        vehicleStats,
        geofences,
        userPreferences,
        runSheets,
        t
      )
    );
  }, [
    isLoading,
    userPreferences?.trackingTimeValue,
    userPreferences?.trackingTimeType,
    fleets,
    devicesStats,
    vehicleStats,
    runSheets,
    t
  ]);

  return trackingData;
};
