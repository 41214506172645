import { Modal, Progress } from 'antd';

//@Author Durga Prasad Kolli
const ProgressModal = ({ open, progress, onClose, title }) => {
  const footer = null;
  const { completed, total } = progress;
  return (
    <Modal
      title={title}
      open={open}
      footer={null}
      onCancel={onClose}
      centered
      closable={false} // to avoid default close
      maskClosable={false} // Prevents closing when clicking outside the modal
    >
      <div style={{ textAlign: 'center' }}>
        <Progress
          percent={Math.round((completed / total) * 100)}
          strokeColor="#52c41a" // Ant Design green color
          showInfo={true}
          size="medium"
        />
        <div style={{ marginTop: '10px' }}>
          <span>
            {completed}/{total} got downloaded
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ProgressModal;
