import React, { useEffect, useReducer, useState } from 'react';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import i18n from 'i18nextConfig';
import { prepareDataForMultiselect } from 'utils/filters';
import { CountRefreshButton } from './CountRefreshButton';
import { sortBy, upperFirst } from 'lodash';
import { useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import {
  useDevices,
  useFleets,
  useIsFetchingFinished,
  useVehicles
} from 'features/fleets/fleetsSlice';
import useDebounce from 'utils/hooks/useDebounce';
import { BUTTON_IDS } from 'utils/globalConstants';

export const DefaultToolbarFilterOptions = [
  {
    key: 'searchFilter',
    component: AntSearchbar,
    config: (dataStore, actions) => ({ onFilter: actions?.onSearch, maxLength: 50 })
  },
  {
    key: 'companyFilter',
    component: AntMultiselect,
    config: (dataStore, actions) => ({
      title: !dataStore?.companyOptions?.some(company => !company.checked)
        ? i18n.t('Common.AllCompanies')
        : i18n.t('Common.Companies'),
      data: dataStore?.companyOptions,
      onFilter: actions?.onFilterCompanies
    })
  },
  {
    key: 'fleetFilter',
    component: AntMultiselect,
    config: (dataStore, actions) => ({
      title: !dataStore?.fleetOptions?.some(value => !value.checked)
        ? i18n.t('Common.AllFleets')
        : i18n.t('Common.Fleets'),
      data: dataStore?.fleetOptions,
      onFilter: actions?.onFilterFleets
    })
  },
  {
    key: 'vehicleFilter',
    component: AntMultiselect,
    config: (dataStore, actions) => ({
      title: !dataStore?.vehicleOptions?.find(value => !value.checked)
        ? i18n.t('VehicleMntSchedules.AllVehicles')
        : i18n.t('Common.Vehicles'),
      data: dataStore?.vehicleOptions,
      onFilter: actions?.onFilterVehicles
    })
  }
];

export const DefaultToolbarAction = {
  Search: { Filter: 'filterBySearch', TempSearch: 'tempSearch' },
  CurrentCompany: { Init: 'initCurrentCompany' },
  Companies: { Init: 'initCompanies', Filter: 'filterCompanies' },
  Fleets: { Init: 'initFleets', Filter: 'filterFleets' },
  Vehicles: { Init: 'initVehicles', Filter: 'filterVehicles' },
  Devices: { Init: 'initDevices' }
};

const FilterUtils = {
  selectStandaloneCameras: d => !d.vehicle?.id && d.type?.code === 'CAMERA',
  selectVehiclesWithCamera: v => v.id && v.devices?.some(d => d.type?.code === 'CAMERA')
};

function generateFilterCompanies(currentCompany, subCompanies) {
  return prepareDataForMultiselect(subCompanies, i18n.t('Common.AllCompanies'), null);
}

function generateFilterFleets(fleets) {
  const fleetsList = fleets?.map(f => {
    return {
      id: f.id || Number.MAX_SAFE_INTEGER,
      name: f.id ? f.name : i18n.t('Common.NoFleet')
    };
  });
  return prepareDataForMultiselect(sortBy(fleetsList, 'name'), i18n.t('Common.AllFleets'), null);
}

function generateFilterVehicles(vehicles, devices) {
  const mixedRecords = devices?.map(d => ({ id: 'd_' + d.id, name: d.name })) || [];
  mixedRecords.push(...(vehicles?.map(v => ({ id: 'v_' + v.id, name: v.name })) || []));
  return prepareDataForMultiselect(
    sortBy(mixedRecords, 'name'),
    i18n.t('Common.AllVehicles'),
    null
  );
}

function generateFilterVehiclesForVehicles(state, vehicles) {
  const standaloneDevices = state.allDevices?.filter(FilterUtils.selectStandaloneCameras);
  const vehiclesWithCamera = vehicles?.filter(FilterUtils.selectVehiclesWithCamera);
  return generateFilterVehicles(vehiclesWithCamera, standaloneDevices);
}

function generateFilterVehiclesForStandaloneDevices(state, devices) {
  const standaloneDevices = devices?.filter(FilterUtils.selectStandaloneCameras);
  const vehiclesWithCamera = state.allVehicles?.filter(FilterUtils.selectVehiclesWithCamera);
  return generateFilterVehicles(vehiclesWithCamera, standaloneDevices);
}

export function useDefaultToolbarReducer(reducer, initStateFunc, injectActions) {
  const currentCompany = useCurrentCompany();
  const subCompanies = useSubCompanies();
  const allFleets = useFleets();
  const allVehicles = useVehicles();
  const allDevices = useDevices();
  const isFleetFetchingDone = useIsFetchingFinished();

  const [dataStore, updateDataStore] = useReducer(
    (state, action) => {
      switch (action.type) {
        case DefaultToolbarAction.Search.Filter:
          return { ...state, search: action.payload };
        case DefaultToolbarAction.Search.TempSearch:
          return { ...state, tempSearch: action.payload };
        case DefaultToolbarAction.CurrentCompany.Init:
          return {
            ...state,
            currentCompany: action.payload,
            companyOptions: generateFilterCompanies(action.payload, state.subCompanies)
          };
        case DefaultToolbarAction.Companies.Init:
          return {
            ...state,
            subCompanies: action.payload,
            companyOptions: generateFilterCompanies(state.currentCompany, action.payload)
          };
        case DefaultToolbarAction.Fleets.Init:
          return {
            ...state,
            allFleets: action.payload,
            fleetOptions: generateFilterFleets(action.payload)
          };
        case DefaultToolbarAction.Vehicles.Init:
          return {
            ...state,
            allVehicles: action.payload,
            vehicleOptions: generateFilterVehiclesForVehicles(action.payload)
          };
        case DefaultToolbarAction.Devices.Init:
          return {
            ...state,
            allDevices: action.payload,
            vehicleOptions: generateFilterVehiclesForStandaloneDevices(state, action.payload),
            initLoading: false
          };
        case DefaultToolbarAction.Companies.Filter: {
          const companiesObj = action.payload?.reduce((v, c) => {
            v[c.id] = c.checked;
            return v;
          }, {});
          const filteredFleets = generateFilterFleets(
            state.allFleets?.filter(f => companiesObj[f.company?.id] || !f.id) || []
          );
          const filteredVehicles = generateFilterVehicles(
            state.allVehicles?.filter(
              v => companiesObj[v.company?.id] && FilterUtils.selectVehiclesWithCamera(v)
            ) || [],
            state.allDevices?.filter(
              d => companiesObj[d.company?.id] && FilterUtils.selectStandaloneCameras(d)
            ) || []
          );
          return {
            ...state,
            companyOptions: action.payload,
            fleetOptions: filteredFleets,
            vehicleOptions: filteredVehicles
          };
        }
        case DefaultToolbarAction.Fleets.Filter: {
          const companiesObj = state.companyOptions?.reduce((o, c) => {
            o[c.id] = c.checked;
            return o;
          }, {});
          const fleetsObj = action.payload?.reduce((o, f) => {
            o[f.id] = f.checked;
            return o;
          }, {});
          const filteredVehicles = generateFilterVehicles(
            state.allVehicles?.filter(
              v =>
                (v.fleets?.some(f => fleetsObj[f.id]) ||
                  (fleetsObj[Number.MAX_SAFE_INTEGER] &&
                    companiesObj[v.company?.id] &&
                    v.fleets?.some(f => !f.id))) &&
                FilterUtils.selectVehiclesWithCamera(v)
            ) || [],
            state.allDevices?.filter(
              d =>
                (d.fleetInfo?.some(f => fleetsObj[f.id]) ||
                  (fleetsObj[Number.MAX_SAFE_INTEGER] &&
                    companiesObj[d.company?.id] &&
                    d.fleetInfo?.some(f => !f.id))) &&
                FilterUtils.selectStandaloneCameras(d)
            ) || []
          );
          return { ...state, fleetOptions: action.payload, vehicleOptions: filteredVehicles };
        }
        case DefaultToolbarAction.Vehicles.Filter: {
          return { ...state, vehicleOptions: action.payload };
        }
        default:
          return reducer?.(state, action);
      }
    },
    null,
    () => {
      return {
        initLoading: true,
        currentCompany: null,
        subCompanies: [],
        allFleets: [],
        allVehicles: [],
        allDevices: [],
        tempSearch: null,
        search: null,
        companyOptions: [],
        fleetOptions: [],
        vehicleOptions: [],
        ...(initStateFunc?.() || {})
      };
    }
  );

  const [actions] = useState({
    onSearch: data => {
      updateDataStore({ type: DefaultToolbarAction.Search.TempSearch, payload: data });
    },
    onFilterCompanies: data => {
      updateDataStore({ type: DefaultToolbarAction.Companies.Filter, payload: data });
    },
    onFilterFleets: data => {
      updateDataStore({ type: DefaultToolbarAction.Fleets.Filter, payload: data });
    },
    onFilterVehicles: data => {
      updateDataStore({ type: DefaultToolbarAction.Vehicles.Filter, payload: data });
    },
    ...(injectActions?.(updateDataStore) || {})
  });

  useDebounce(dataStore.tempSearch, 150, s => {
    updateDataStore({ type: DefaultToolbarAction.Search.Filter, payload: s });
  });

  useEffect(() => {
    updateDataStore({ type: DefaultToolbarAction.CurrentCompany.Init, payload: currentCompany });
  }, [currentCompany]);

  useEffect(() => {
    updateDataStore({ type: DefaultToolbarAction.Companies.Init, payload: subCompanies });
  }, [subCompanies]);

  useEffect(() => {
    updateDataStore({ type: DefaultToolbarAction.Fleets.Init, payload: allFleets });
  }, [allFleets]);

  useEffect(() => {
    updateDataStore({ type: DefaultToolbarAction.Vehicles.Init, payload: allVehicles });
  }, [allVehicles]);

  useEffect(() => {
    if (isFleetFetchingDone) {
      updateDataStore({ type: DefaultToolbarAction.Devices.Init, payload: allDevices });
    }
  }, [allDevices, isFleetFetchingDone]);

  return [dataStore, updateDataStore, actions];
}

export function Toolbar({ filterOptions, dataStore, actions, countConfig = {} }) {
  return (
    <div
      style={{
        display: 'flex',
        padding: '0px 5px',
        minHeight: '64px',
        zIndex: '5',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        margin: '0',
        backgroundColor: '#f7f8f9'
      }}
    >
      {filterOptions
        .filter(o => o.position !== 'end' && (o.visible?.(dataStore) || o.visible === undefined))
        ?.map((c, idx) => {
          const Component = c.component;
          return (
            <Component
              id={`${BUTTON_IDS.cameraEventsFilterBy}${upperFirst(c.key)}`}
              key={idx}
              {...c.config(dataStore, actions)}
              {...(c.defaultValue && { defaultValue: c.defaultValue })}
              {...(c.defaultDates && { defaultDates: c.defaultDates })}
            />
          );
        })}

      <div
        style={{ display: 'flex', flex: '2 0 0', justifyContent: 'flex-end', placeItems: 'center' }}
      >
        <span style={{ display: 'block!important', textAlign: 'right', paddingRight: '10px' }}>
          <CountRefreshButton {...countConfig} />
        </span>
        {filterOptions
          .filter(o => o.position === 'end')
          ?.map((c, idx) => {
            const Component = c.component;
            return (
              <Component
                id={`${BUTTON_IDS.cameraEventsFilterBy}${upperFirst(c.key)}`}
                key={idx}
                {...c.config(dataStore, actions)}
                defaultValue={c.defaultValue}
              />
            );
          })}
      </div>
    </div>
  );
}
