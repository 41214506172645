import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { PATHS as CompanyAgreementPaths } from './constants';
import { CompanyAgreement } from './CompanyAgreement';
import { CompanyAgreementAudits } from './CompanyAgreementAudits';
import CompanyAgreementView from './CompanyAgreementViews';

export const CompanyAgreementRouter = () => {
  return (
    <Switch>
      <Route path={CompanyAgreementPaths.AGREEMENT_VIEW}>
        <CompanyAgreementView />
      </Route>
      <Route path={CompanyAgreementPaths.AGREEMENT_AUDIT}>
        <CompanyAgreementAudits />
      </Route>
      <Route path={CompanyAgreementPaths.AGREEMENT_DEFAULT}>
        <CompanyAgreement />
      </Route>
    </Switch>
  );
};
