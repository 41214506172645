import { capitalize, isArray } from 'lodash';

export const CHANGE_WIDTH = {
  KEY: '20%',
  FROM: '30%',
  TO: '30%',
  ENTITY: '20%'
};
export const MAX_CELL_HEIGHT = 180;

export const BULK_EDIT_AUDIT_ENTITY = {
  DEVICE: 'Device'
};

const isEmptyChange = c => !c?.[0] && !c?.[1];

export const getChangesByKeyTableData = ({ changesByKey, entity, idPrefix = '' }) => {
  return changesByKey?.reduce((a, c, index) => {
    const change = c?.change || [];
    const entities = Object.values(c?.[entity] || {});
    if (change?.length > 2) {
      const pairs = renderPairedChangeValue(change);
      if (pairs?.length > 1) {
        return [
          ...a,
          {
            entities,
            isPairs: true,
            id: `${idPrefix}-${index}`,
            pairs: pairs.map((pair, pairIndex) => ({
              from: pair?.[0],
              to: pair?.[1],
              entities
            }))
          }
        ];
      }
      return [
        ...a,
        ...pairs.map(pair => ({
          from: pair?.[0],
          to: pair?.[1],
          entities,
          id: `${idPrefix}-${index}`
        }))
      ];
    } else {
      const c = change.map(renderChangeValue);
      if (isEmptyChange(c)) {
        return a;
      }
      return [
        ...a,
        {
          id: `${idPrefix}-${index}`,
          from: c?.[0],
          to: c?.[1],
          entities
        }
      ];
    }
  }, []);
};

const renderChangeValue = change => {
  const _toString = value => {
    try {
      return typeof value === 'string' ? value : JSON.stringify(value);
    } catch (error) {
      return value;
    }
  };
  const getSingleValue = changeValue => {
    try {
      if (!changeValue) {
        return '';
      } else if (changeValue?.entity || changeValue?.entityType) {
        if (changeValue.id && changeValue.name) {
          const entityType = changeValue.entity || changeValue.entityType;
          return `${changeValue.name}${entityType ? ` (${capitalize(entityType)}) ` : ''}`;
        } else {
          delete changeValue.entity;
          delete changeValue.entityType;
        }
      }
      return _toString(changeValue);
    } catch (error) {
      return _toString(changeValue);
    }
  };
  return isArray(change) ? change.map(getSingleValue) : getSingleValue(change);
};

const renderPairedChangeValue = (pairedChange = []) => {
  const ret = [];
  let pairIndex = 0;
  pairedChange.forEach((change, changeValueIndex) => {
    if (changeValueIndex % 2 === 0) {
      const fromValue = renderChangeValue(change);
      ret[pairIndex] = [];
      ret[pairIndex].push(fromValue);
    } else {
      const toValue = renderChangeValue(change);
      ret[pairIndex].push(toValue);
      //empty check
      if (isEmptyChange(ret[pairIndex][0]) && isEmptyChange(ret[pairIndex][1])) {
        ret.splice(pairIndex);
      } else {
        ret[pairIndex].pairIndex = pairIndex;
        pairIndex++;
      }
    }
  });
  return ret;
};
