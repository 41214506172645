import { useEffect } from 'react';

const AXIS = { x: 'x', y: 'y' };

export const useScrollIntoView = (
  elRef,
  canScroll,
  vpPadding = [0, 0, 0, 0],
  detectAxes = [AXIS.y]
) => {
  useEffect(() => {
    const scrollToView = () => {
      if (!elRef.current || !canScroll) {
        return;
      }
      const rect = elRef.current?.getBoundingClientRect();
      if (rect) {
        const vpPaddingT = isNaN(Number(vpPadding[0])) ? 0 : Number(vpPadding[0]),
          vpPaddingR = isNaN(Number(vpPadding[1])) ? 0 : Number(vpPadding[1]),
          vpPaddingB = isNaN(Number(vpPadding[2])) ? 0 : Number(vpPadding[2]),
          vpPaddingL = isNaN(Number(vpPadding[3])) ? 0 : Number(vpPadding[3]);
        const vpWidth = window.innerWidth || document.documentElement.clientWidth,
          vpHeight = window.innerHeight || document.documentElement.clientHeight;
        const isInVpX = rect.left >= vpPaddingL && rect.right <= vpWidth - vpPaddingR,
          isInVpY = rect.top >= vpPaddingT && rect.bottom <= vpHeight - vpPaddingB;
        if (
          (!isInVpX && detectAxes.includes(AXIS.x)) ||
          (!isInVpY && detectAxes.includes(AXIS.y))
        ) {
          elRef.current.scrollIntoView();
        }
      }
    };
    scrollToView();
  }, [elRef, detectAxes, canScroll]);
};
