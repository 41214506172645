// import { TEMPLATE_TYPES } from './constants';

// export const formatTemplates = (templates, option) => {
//   return templates.reduce((temps, temp) => {
//     const fileExtension = temp.filename.split('.')[1];

//     return (
//       (temp.type === TEMPLATE_TYPES[option] && {
//         ...temps,
//         [fileExtension]: {
//           id: temp.id || -1,
//           fileName: temp.filename || 'N/A',
//           infoName: `${option}-template.${fileExtension}` || 'N/A'
//         }
//       }) ||
//       temps
//     );
//   }, {});
// };

export const filterCarrierData = ({
  carrierData,
  debouncedSearchText,
  typeFilter,
  statusFilter
}) => {
  if (!carrierData || !Array.isArray(carrierData)) {
    return [];
  }
  return carrierData.reduce((acc, entry) => {
    // filters are not selected, return all the data
    if (!typeFilter && !statusFilter) {
      acc.push(entry);
    }

    // both filters selected, filter by both
    if (statusFilter && typeFilter) {
      entry.type === typeFilter && entry.status === statusFilter && acc.push(entry);
    }

    // only status filter selected
    if (statusFilter && !typeFilter) {
      entry.status === statusFilter && acc.push(entry);
    }

    // only type filter selected
    if (!statusFilter && typeFilter) {
      entry.type === typeFilter && acc.push(entry);
    }

    // search text filter
    if (debouncedSearchText) {
      acc = acc.filter(data => data.name?.toLowerCase()?.includes(debouncedSearchText));
    }

    return acc;
  }, []);
};

export const preparFleetsForSelect = (fleets = [], selected, translate = string => string) => {
  const selectData = fleets.map(fleet => {
    if (fleet.id) {
      return {
        label: fleet.name,
        id: fleet.id,
        checked: true
      };
    } else {
      return {
        id: -1,
        checked: true,
        label: translate('Common.NoFleet')
      };
    }
  });
  const isAllSelected = selected.some(s => s.id === 0 && s.checked);
  const selectedFleetIds = selected
    .filter(s => s.checked && selectData.find(sd => sd.id === s.id))
    .map(s => s.id);

  if (!isAllSelected && selectedFleetIds?.length) {
    return selectData.map(item =>
      selectedFleetIds.includes(item.id) ? { ...item, checked: true } : { ...item, checked: false }
    );
  }

  return selectData;
};

export const prepareRunsheetsForCalendarView = (
  runsheets,
  { searchValue, activeTab, selectedCarriers, selectedVehicles, selectedLocations, selectedFleets }
) => {
  const isAllFleets = selectedFleets.some(sf => sf.id === 0 && sf.checked);

  let filteredRunsheets = [...runsheets];

  // Search
  /*  if (searchValue) {
      filteredRunsheets = filteredRunsheets.filter(filterByQuery(searchValue));
    } */

  // // Filter by Fleet
  if (!isAllFleets) {
    const fleets = selectedFleets.reduce((accumulator, fleet) => {
      if (fleet.checked) accumulator.push(fleet.id);
      return accumulator;
    }, []);

    filteredRunsheets = filteredRunsheets.filter(filterByFleet(fleets));
  }

  return filteredRunsheets;
};

const filterByFleet = fleets => item => {
  // Use case for when No Fleet option is selected
  if (!item.fleet?.length && fleets.includes(-1)) {
    return true;
  }

  // return fleets.includes(item?.fleet?.id);
  return (item.fleet || []).some(f => fleets.includes(f.id));
};
