import * as moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useUserInfo } from '../user/userSlice';
import { fbtSelectors, fetchTrips, fetchTripStates, fetchPositions } from './';

export const useTrips = (startAt, endAt, fbtMultipleCallsFeatureFlag = false) => {
  const dispatch = useDispatch();
  const trips = useSelector(fbtSelectors.getTrips);

  const isFetching = useSelector(fbtSelectors.isFetchingTrips);
  const isSuccess = useSelector(fbtSelectors.isTripsSuccess);
  const isError = useSelector(fbtSelectors.isTripsError);
  //   const isCompanyKeyDifferent = useIsCompanyKeyDifferent();
  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete) {
    dispatch(
      fetchTrips({
        query: {
          from: startAt,
          to: endAt,
          fbtMultipleCallsFeatureFlag
        }
      })
    );
  }

  return trips;
};

export const useTripStates = () => {
  const dispatch = useDispatch();
  const tripStates = useSelector(fbtSelectors.getTripStates);

  const isFetching = useSelector(fbtSelectors.isFetchingTripState);
  const isSuccess = useSelector(fbtSelectors.isTripStateSuccess);
  const isError = useSelector(fbtSelectors.isTripStateError);
  //   const isCompanyKeyDifferent = useIsCompanyKeyDifferent();
  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete) {
    dispatch(fetchTripStates());
  }

  return tripStates;
};

export const usePositions = (deviceId, events, tripId) => {
  const dispatch = useDispatch();
  const positions = useSelector(fbtSelectors.getPositions(tripId));
  const userInfo = useUserInfo();

  const isFetching = useSelector(fbtSelectors.isFetchingPositions(tripId));
  const isSuccess = useSelector(fbtSelectors.isPositionsSuccess(tripId));
  const isError = useSelector(fbtSelectors.isPositionsError(tripId));

  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete && deviceId) {
    const startAtISOTime = moment(events.startAt)
      .utc()
      .toISOString(true);

    const endAtISOTime = moment(events.endAt)
      .utc()
      .toISOString(true);

    dispatch(
      fetchPositions({
        query: {
          deviceId,
          highDefinition: true,
          from: startAtISOTime || '',
          to: endAtISOTime || '',
          order_by: 'time_at',
          hideNonBusiness: false
        },
        tripId
      })
    );
  }

  return positions;
};
export const useIsFetching = () => useSelector(fbtSelectors.isFetchingTrips);
export const useIsPositionsFetching = id => useSelector(fbtSelectors.isFetchingPositions(id));
export const useIsAddingTripState = () => useSelector(fbtSelectors.isAddingTripState);
export const useIsDeletingTripState = () => useSelector(fbtSelectors.isDeletingTripState);
export const useDates = () => useSelector(fbtSelectors.getDates);
export const useFilters = () => useSelector(fbtSelectors.filters);
