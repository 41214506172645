import React from 'react';
import { BUTTON_IDS } from 'utils/globalConstants';
import styles from './FormButton.module.scss';

// Usage: <FormButton />
const FormButton = ({ label, id = BUTTON_IDS.form, customStyling = {}, handleClick }) => {
  return (
    // <div style={{ width: fullWidth ? '100%' : 'auto' }}>
    <button
      className={styles.formButton}
      id={id}
      onClick={handleClick}
      style={{ ...customStyling }}
    >
      {label}
    </button>
  );
};

export default FormButton;
