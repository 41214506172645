import React from 'react';
import { TabNavLink } from 'components/nav/NavLinks';
import * as Yup from 'yup';
import { API_PATH } from 'config';
import i18n from 'i18next';

export const SCHEDULE_BY_ID_URL = `/schedules/vehiclemaintenance`;
export const deleteUrl = `${API_PATH}/schedules/vehiclemaintenance/`;

export const maxDocumentUploadSizeInBytes = 5242880;

export const NoApplicableVehicleOdometer = 'N/A';

export const STATUS = {
  completed: 'COMPLETED',
  dueNow: 'DUE_NOW',
  pending: 'PENDING',
  cancelled: 'CANCELLED',
  deleted: 'DELETED',
  overdue: 'OVERDUE',
  invalid: 'INVALID'
};

export const BUTTON_ACTIONS = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  RESTORE: 'RESTORE',
  COMPLETE: 'COMPLETE',
  DELETE: 'DELETE',
  CANCEL: 'CANCEL',
  AUDIT: 'AUDIT',
  RESCHEDULE: 'RESCHEDULE'
};

export const Paths = {
  VEHICLEMAINTENANCE_DEFAULT: '/vehiclemaintenance',
  VEHICLEMAINTENANCE_ADD: '/vehiclemaintenance/schedules/add',
  VEHICLEMAINTENANCE_ADD_NEW: '/vehiclemaintenance/schedules/addnew',
  VEHICLEMAINTENANCE_VIEW: '/vehiclemaintenance/schedules/id',
  VEHICLEMAINTENANCE_VIEW_OLD: '/vehiclemaintenance/id',
  VEHICLEMAINTENANCE_EDIT: '/vehiclemaintenance/schedlues/edit/id',
  VEHICLEMAINTENANCE_AUDITS: '/vehiclemaintenance/schedules/audits/id',
  VEHICLEMAINTENANCE_COMPLETE: '/vehiclemaintenance/schedules/complete',
  VEHICLEMAINTENANCE_RESCHEDULE: '/vehiclemaintenance/schedules/reschedule',
  VEHICLEMAINTENANCE_SCHEDULES: '/vehiclemaintenance/schedules',
  VEHICLEMAINTENANCE_INSPECTIONS: '/vehiclemaintenance/inspections',
  VEHICLEMAINTENANCE_INSPECTIONS_VIEW: '/vehiclemaintenance/inspections/view'
};

export const FILTER_BY = {
  groupBy: 'groupBy',
  odometer: 'odometer',
  engineHours: 'engineHours',
  days: 'days'
};

export const RescheduleType = {
  CURRENT: 'current',
  SCHEDULED: 'scheduled',
  COMPLETED: 'completed'
};

export const VEHICLE_MNT_TABS = {
  SCHEDULES: 'schedules',
  INSPECTIONS: 'inspections'
};

export const TABS = {
  active: 'active',
  overdue: 'overdue',
  duenow: 'duenow',
  scheduled: 'scheduled',
  completed: 'completed',
  cancelled: 'cancelled',
  invalid: 'invalid',
  deleted: 'deleted'
};

export const TabsFilters = ({ setFilterTab, historyFiltersState }) => {
  const tabs = {
    Active: `${i18n.t('VehicleMntSchedules.Active')}`,
    Scheduled: `${i18n.t('VehicleMntSchedules.Scheduled')}`,
    DueNow: `${i18n.t('VehicleMntSchedules.DueNow')}`,
    Completed: `${i18n.t('VehicleMntSchedules.Completed')}`,
    Cancelled: `${i18n.t('VehicleMntSchedules.Cancelled')}`,
    Overdue: `${i18n.t('VehicleMntSchedules.Overdue')}`,
    Invalid: `${i18n.t('VehicleMntSchedules.Invalid')}`,
    Deleted: `${i18n.t('VehicleMntSchedules.Deleted')}`
  };

  return (
    <>
      <TabNavLink
        to={{
          pathname: `${Paths.VEHICLEMAINTENANCE_SCHEDULES}/active`
        }}
        isActive={(match, location) => {
          return [
            '/',
            Paths.VEHICLEMAINTENANCE_SCHEDULES,
            `${Paths.VEHICLEMAINTENANCE_SCHEDULES}/active`
          ].includes(location.pathname);
        }}
        onClick={() => {
          setFilterTab(TABS.active);
        }}
      >
        {tabs.Active}
      </TabNavLink>
      <TabNavLink
        exact
        to={{
          pathname: `${Paths.VEHICLEMAINTENANCE_SCHEDULES}/overdue`
        }}
        onClick={() => {
          setFilterTab(TABS.overdue);
        }}
      >
        {tabs.Overdue}
      </TabNavLink>
      <TabNavLink
        exact
        to={{
          pathname: `${Paths.VEHICLEMAINTENANCE_SCHEDULES}/duenow`
        }}
        onClick={() => {
          setFilterTab(TABS.duenow);
        }}
      >
        {tabs.DueNow}
      </TabNavLink>
      <TabNavLink
        exact
        to={{
          pathname: `${Paths.VEHICLEMAINTENANCE_SCHEDULES}/scheduled`
        }}
        onClick={() => {
          setFilterTab(TABS.scheduled);
        }}
      >
        {tabs.Scheduled}
      </TabNavLink>
      <TabNavLink
        exact
        to={{
          pathname: `${Paths.VEHICLEMAINTENANCE_SCHEDULES}/completed`
        }}
        onClick={() => {
          setFilterTab(TABS.completed);
        }}
      >
        {tabs.Completed}
      </TabNavLink>
      <TabNavLink
        exact
        to={{
          pathname: `${Paths.VEHICLEMAINTENANCE_SCHEDULES}/cancelled`
        }}
        onClick={() => {
          setFilterTab(TABS.cancelled);
        }}
      >
        {tabs.Cancelled}
      </TabNavLink>
      <TabNavLink
        exact
        to={{
          pathname: `${Paths.VEHICLEMAINTENANCE_SCHEDULES}/invalid`
        }}
        onClick={() => {
          setFilterTab(TABS.invalid);
        }}
      >
        {tabs.Invalid}
      </TabNavLink>
      <TabNavLink
        exact
        to={{
          pathname: `${Paths.VEHICLEMAINTENANCE_SCHEDULES}/deleted`
        }}
        onClick={() => {
          setFilterTab(TABS.deleted);
        }}
      >
        {tabs.Deleted}
      </TabNavLink>
    </>
  );
};

export const initialValues = {
  name: '',
  manageTypeId: '', //maintenanceTypeId
  entityId: '', //VehiceId
  fleetId: '',
  //For Odometer:
  alert_odometer: '',
  odometer: '',
  overdue_odometer: '',
  repeat_odometer_value: '',
  //For EngineHours
  alert_engine_hours: '',
  engine_hours: '',
  overdue_engine_hours: '',
  repeat_engine_hours_value: '',
  // For Dates
  days: '',
  overdue_days: '',
  repeat_schedules_on: '',

  // current readings
  odometer_reading: '',
  engineHours_reading: '',
  date_reading: '',
  gpio_reading: ''
};

export const initialValuesComplete = {
  vehicleId: '',
  fleetId: '',
  type: '',
  raised_by: '',
  service_on: '',
  odometer: '',
  engine_hours: '',
  service_cost: '',
  invoice_number: '',
  signed_off_by: '',
  completed_by: '',
  order_number: '',
  notes: ''
};

export const validationSchema = gpioBool => {
  return Yup.lazy(values => {
    const odometerScheduleDueMaxLimit = 9999999;
    const odometerScheduleDueMinLimit = values?.odometer_reading ? values?.odometer_reading : 0;
    const engineScheduleDueMaxLimit = 9999999;
    const engineScheduleDueMinLimit = values?.engineHours_reading ? values?.engineHours_reading : 0;
    const maxDeltaValueLimit = 9999999;
    const maxDaysValueLimit = 999999;
    const gpioScheduleDueMinLimit = values?.gpio_reading ? values?.gpio_reading : 0;

    return Yup.object().shape({
      name: Yup.string().required(`${i18n.t('VehicleMntSchedules.Notifications.ServiceRequired')}`),
      manageTypeId: Yup.string().required(
        `${i18n.t('VehicleMntSchedules.Notifications.TypeRequired')}`
      ),
      notes: Yup.string().test(
        'empty-check',
        `${i18n.t('VehicleMntSchedules.FormValidation.NotesEmptyCharacters')}`,
        notes => (notes && notes.trim().length !== 0) || !notes
      ),
      entityId: Yup.string().required(
        `${i18n.t('VehicleMntSchedules.Notifications.VehicleRequired')}`
      ),
      alert_odometer: Yup.number()
        .min(0, i18n.t('VehicleMntSchedules.FormValidation.PositiveValue'))
        .max(
          maxDeltaValueLimit,
          i18n.t('VehicleMntSchedules.FormValidation.MaxValue', {
            max: `${maxDeltaValueLimit}`
          })
        ),
      overdue_odometer: Yup.number()
        .min(0, i18n.t('VehicleMntSchedules.FormValidation.PositiveValue'))
        .max(
          maxDeltaValueLimit,
          i18n.t('VehicleMntSchedules.FormValidation.MaxValue', {
            max: `${maxDeltaValueLimit}`
          })
        ),
      odometer: Yup.number()
        .min(
          odometerScheduleDueMinLimit,
          i18n.t('VehicleMntSchedules.FormValidation.MinValue', {
            min: `${odometerScheduleDueMinLimit}`
          })
        )
        .max(
          odometerScheduleDueMaxLimit,
          i18n.t('VehicleMntSchedules.FormValidation.RangeValidation', {
            range: `${odometerScheduleDueMinLimit}-${odometerScheduleDueMaxLimit}`
          })
        ),
      repeat_odometer_value: Yup.number()
        .min(0, i18n.t('VehicleMntSchedules.FormValidation.PositiveValue'))
        .max(
          maxDeltaValueLimit,
          i18n.t('VehicleMntSchedules.FormValidation.MaxValue', {
            max: `${maxDeltaValueLimit}`
          })
        ),
      alert_engine_hours: Yup.number()
        .min(0, i18n.t('VehicleMntSchedules.FormValidation.PositiveValue'))
        .max(
          maxDeltaValueLimit,
          i18n.t('VehicleMntSchedules.FormValidation.MaxValue', {
            max: `${maxDeltaValueLimit}`
          })
        ),
      overdue_engine_hours: Yup.number()
        .min(0, i18n.t('VehicleMntSchedules.FormValidation.PositiveValue'))
        .max(
          maxDeltaValueLimit,
          i18n.t('VehicleMntSchedules.FormValidation.MaxValue', {
            max: `${maxDeltaValueLimit}`
          })
        ),
      engine_hours: Yup.number()
        .min(
          engineScheduleDueMinLimit,
          i18n.t('VehicleMntSchedules.FormValidation.MinValue', {
            min: `${engineScheduleDueMinLimit}`
          })
        )
        .max(
          engineScheduleDueMaxLimit,
          i18n.t('VehicleMntSchedules.FormValidation.RangeValidation', {
            range: `${engineScheduleDueMinLimit}-${engineScheduleDueMaxLimit}`
          })
        ),
      repeat_engine_hours_value: Yup.number()
        .min(0, i18n.t('VehicleMntSchedules.FormValidation.PositiveValue'))
        .max(
          maxDeltaValueLimit,
          i18n.t('VehicleMntSchedules.FormValidation.MaxValue', {
            max: `${maxDeltaValueLimit}`
          })
        ),
      days: Yup.number()
        .min(0, i18n.t('VehicleMntSchedules.FormValidation.PositiveValue'))
        .max(
          maxDaysValueLimit,
          i18n.t('VehicleMntSchedules.FormValidation.MaxValue', {
            max: `${maxDaysValueLimit}`
          })
        )
        .integer(i18n.t('VehicleMntSchedules.FormValidation.IntegerValue')),
      overdue_days: Yup.number()
        .min(0, i18n.t('VehicleMntSchedules.FormValidation.PositiveValue'))
        .max(
          maxDaysValueLimit,
          i18n.t('VehicleMntSchedules.FormValidation.MaxValue', {
            max: `${maxDaysValueLimit}`
          })
        )
        .integer(i18n.t('VehicleMntSchedules.FormValidation.IntegerValue')),
      ...(gpioBool && {
        GPIOTypes: Yup.string().required(
          `${i18n.t('VehicleMntSchedules.Notifications.TypeRequired')}`
        ),
        GPIO: Yup.number().min(
          gpioScheduleDueMinLimit,
          i18n.t('VehicleMntSchedules.FormValidation.MinValue', {
            min: `${gpioScheduleDueMinLimit}`
          })
        )
      })
    });
  });
};

export const validationSchemaComplete = gpioType => {
  return Yup.object().shape({
    type: Yup.string().required(i18n.t('VehicleMntSchedules.Notifications.TypeRequired')),
    odometer: Yup.number().required(i18n.t('ELD.Odometer is required')),
    engine_hours: Yup.number().required(
      `${i18n.t('Vehicles.EngineHours')} ${i18n.t('Users.ValidationErrors.IsRequired')}`
    ),
    ...(gpioType && {
      [gpioType]: Yup.string().required(
        `${i18n.t('VehicleMntSchedules.FormValidation.GPIORequired', {
          type: i18n.t(`Vehicles.GPIO.${gpioType}`)
        })}`
      )
    })
  });
};
