export const REGION_NAMES = {
  AUSTRALIA: 'Australia',
  UK: 'United Kingdom',
  NZ: 'New Zealand',
  US: 'United States',
  MEXICO: 'Mexico'
};

export const REGION_PRIVACY_URL = {
  AUSTRALIA: 'https://www.teletracnavman.com.au/privacy',
  UK: 'https://www.teletracnavman.co.uk/privacy-notice',
  NZ: 'https://www.teletracnavman.co.nz/privacy',
  US: 'https://www.teletracnavman.com/privacy',
  MEXICO: 'https://www.teletracnavman.com.mx/aviso-de-privacidad',
  GLOBAL: 'https://www.teletracnavman.com/global-privacy-notice'
};
