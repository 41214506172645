import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import { PATHS } from './constants';
import { GPIOs } from './GPIOs';
import { GPIOForm } from './GPIOForm';
import { Devices } from '../components/Devices';
import { GPIOView } from './GPIOView';
import { GPIOAudits } from './GPIOAudits';

export const GPIORouter = () => {
  const history = useHistory();
  const pathnameSegments = history.location.pathname.split('/');
  const id = pathnameSegments[pathnameSegments.length - 1];
  return (
    <Switch>
      <Route path={PATHS.GPIO_CONFIGURED_ASSIGNED}>
        <Devices tab="assigned" />
      </Route>
      <Route path={PATHS.GPIO_CONFIGURED_UNASSIGNED}>
        <Devices tab="unassigned" />
      </Route>
      <Route path={PATHS.GPIO_CONFIGURED}>
        <Devices />
      </Route>
      <Route path={PATHS.GPIO_VIEW}>
        <GPIOView />
      </Route>
      <Route path={PATHS.GPIO_DELETED}>
        <GPIOs tab="deleted" />
      </Route>
      <Route path={PATHS.GPIO_AUDIT}>
        <GPIOAudits />
      </Route>
      <Route path={PATHS.GPIO_EDIT}>
        <GPIOForm action="edit" templateId={parseInt(id)} />
      </Route>
      <Route path={PATHS.GPIO_ADD}>
        <GPIOForm action="add" />
      </Route>
      <Route path={PATHS.GPIO_DEFAULT}>
        <GPIOs />
      </Route>
    </Switch>
  );
};
