/* global google */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LocalizationUtil, UNITS } from 'features/localization/localization';
import { Button, Col, Divider, Radio, Row } from 'antd';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import DropdownTreeSelect from 'components/form/treeselect/DropdownTreeSelect';
import { useTranslation } from 'react-i18next';
import { useVehicleTypes } from 'features/vehicles/vehicleTypesSlice';
import i18next from 'i18nextConfig';
import { DriverLogStatus, IgnitionStatus } from 'utils/types';
import { PATH_SPLIT, selectAll } from 'components/form/treeselect/TreeSelect';
import { Select } from 'components/ant/Select/Select';
import styles from './controlpanel.module.scss';
import { useLocalization } from 'features/localization/localizationSlice';
import { MessageComposeModal } from 'containers/Messaging/MessageComposeModal';
import { RequestFootageModal } from 'features/requestFootage/RequestFootageModal';
import { RouteToModal, useRouteTo } from 'containers/Tracking/RouteTo';
import { ElipsisButton } from './ElipsisButton';
import { useHistory } from 'react-router';
import { RECIPIENT_TYPE } from 'containers/Messaging/constants';
import { useMsgClient } from 'utils/hooks/msgChannel';
import { MsgType } from './constants';
import { orderBy as orderByFn } from 'lodash';
import { DriverName } from 'containers/Tracking/Common/DiverName';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useIQCameraUser } from 'features/permissions';

function getObjectKeyProxy(object) {
  const proxy = new Proxy(object, {
    get(target, prop, receiver) {
      return prop;
    }
  });
  return proxy;
}

const FilterByTypes = {
  get IgnitionStatus() {
    return i18next.t('Tracking.IgnitionStatus');
  },
  get DriverStatus() {
    return i18next.t('Tracking.DriverStatus');
  },
  get VehicleType() {
    return i18next.t('Tracking.Columns.Vehicle Type');
  }
};

const FilterByTypesKeyMap = getObjectKeyProxy(FilterByTypes);

const SortBy = {
  get Location() {
    return i18next.t('Common.Location');
  },
  get Driver() {
    return i18next.t('Common.Driver');
  },
  get VehicleName() {
    return i18next.t('Map.VehicleName');
  }
};
const SortByKeyMap = getObjectKeyProxy(SortBy);

const SortByKeyField = {
  Location: 'distance',
  Driver: 'driverName',
  VehicleName: 'sortName'
};

const OrderBy = {
  get Ascending() {
    return i18next.t('Common.Ascending');
  },
  get Descending() {
    return i18next.t('Common.Descending');
  }
};

const OrderByKeyMap = getObjectKeyProxy(OrderBy);
const DriverLogStatusKeyMap = getObjectKeyProxy(DriverLogStatus);

function getIgnitionStyle(deviceInfo) {
  const keyProxy = getObjectKeyProxy(IgnitionStatus);

  const ignitionStatus = deviceInfo.ignitionStatus?.toLowerCase();

  switch (ignitionStatus) {
    case keyProxy.On.toLowerCase():
      let oorThreshhold = new Date();
      const deviceLastEventAt = deviceInfo.deviceStats?.lastEventAt;
      oorThreshhold.setHours(oorThreshhold.getHours() - 6);

      const statusColor =
        new Date(deviceLastEventAt) < oorThreshhold ? styles.ignitionOn : styles.ignitionOn_Predict;
      return statusColor;
    case keyProxy.Out.toLowerCase():
      return styles.ignitionOut;
    default:
      return styles.ignitionOff;
  }
}

function getDeviceIcon(device) {
  const DEFAULT_VEHICLE_ICON = 'tn-i-flatbed';
  const DEFAULT_DEVICE_ICON = 'tn-i-device';
  if (!device.icon) {
    if (device.vehicleId) {
      return DEFAULT_VEHICLE_ICON;
    } else {
      return DEFAULT_DEVICE_ICON;
    }
  }
  return device.icon;
}

function DeviceInfo({
  device,
  onDeviceSelected,
  onDeviceFocused,
  onDeviceLostFocus,
  onRouteTo,
  onViewVehicleDevice,
  onRequestVideo,
  onMessageVehicle,
  ...props
}) {
  const { t } = useTranslation();

  const { canAccessNonCameraFeatures } = useIQCameraUser();

  const handleDeviceSelected = useCallback(() => {
    if (onDeviceSelected) {
      onDeviceSelected(device);
    }
  }, [device, onDeviceSelected]);

  const handleMouseOver = useCallback(() => {
    if (onDeviceFocused) {
      onDeviceFocused(device);
    }
  }, [device, onDeviceFocused]);

  const handleMouseOut = useCallback(() => {
    if (onDeviceLostFocus) {
      onDeviceLostFocus(device);
    }
  }, [device, onDeviceLostFocus]);

  const extraStyle = device.selected || device.focused ? styles.active : '';

  return (
    <Row
      className={styles.deviceInfo + ' ' + extraStyle}
      onClick={handleDeviceSelected}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div className={styles.ignitionStatus}>
        <span className={getIgnitionStyle(device)} />
      </div>
      <div span={1} className={styles.deviceIcon}>
        <span>
          <i className={device.deviceIcon} />
        </span>
      </div>
      <div>
        <div>
          <span>{device.sortName}</span>
          <span>
            {canAccessNonCameraFeatures && (
              <ElipsisButton
                deviceInfo={device}
                onMessageVehicle={onMessageVehicle}
                onRequestVideo={onRequestVideo}
                onRouteTo={onRouteTo}
                onViewVehicleDevice={onViewVehicleDevice}
              />
            )}
          </span>
        </div>
        <div>
          <DriverName device={device}>
            <span>{device.driverName || t('Home.NotLoggedIn')}</span>
          </DriverName>{' '}
          / <span>{device.fleetName}</span>
        </div>
        <div className={styles.locationInfo}>
          <span>
            <i className="tn-i-room" />
          </span>
          <span>{device.location}</span>
          <span>{device.distanceLabel}</span>
        </div>
      </div>
    </Row>
  );
}

export function getLinearDistance(from, to, unit = UNITS.KM) {
  if (!from || !to || !(from.lat && from.lng) || !(to.lat && to.lng)) {
    return;
  }
  const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(from, to);
  let distanceInKM = Number((distanceInMeters / 1000).toFixed(2));
  distanceInKM = isNaN(distanceInKM) ? 0 : distanceInKM;
  return unit === UNITS.KM ? distanceInKM : LocalizationUtil.kmtomile(distanceInKM);
}

export function NearestResultPane({ devices, onReset, centerLatLng, msgChannel, ...props }) {
  const [deviceList, setDeviceList] = useState([]);
  const [originalDeviceList, setOriginalDeviceList] = useState([]);
  const [filters, setFilters] = useState(null);
  const [sortBy, setSortBy] = useState(SortByKeyMap.Location);
  const [isSortDropOpen, setIsSortDropOpen] = useState(false);
  const [orderBy, setOrderBy] = useState(OrderByKeyMap.Ascending);
  const [sortLists] = useState(() => {
    return Object.keys(SortBy).map(k => {
      return {
        id: k,
        label: SortBy[k],
        name: k
      };
    });
  });
  const [showPopup, setShowpopup] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const [showFootageModal, setShowFootageModal] = useState(false);
  const [showRouteToModal, setShowRouteToModal] = useState(false);
  const [routeData, setRouteData] = useState(null);
  const [requestVideoDeviceId, setRequestVideoDeviceId] = useState(null);
  const [requestVideoVehicleId, setRequestVideoVehicleId] = useState(null);

  const { t } = useTranslation();
  const vehicleTypes = useVehicleTypes();
  const locale = useLocalization();
  const history = useHistory();
  const msgClient = useMsgClient(msgChannel);
  const [searchText, setSearchText] = useState('');
  const timerRef = useRef(null);

  const filterDevices = useCallback(
    (txt, filterTree) => {
      const deviceIdMap = {};
      let filteredList = Array.from(originalDeviceList || []);

      let searchCondition = () => true;
      let ignitionCondition = () => true;
      let driverStatusCondition = () => true;
      let vehicleTypesCondition = () => true;

      if (txt !== null && txt.trim() !== '') {
        const lowerTxt = txt.toLowerCase();
        searchCondition = d =>
          d.location?.toLowerCase().includes(lowerTxt) ||
          d.distance?.toString().includes(lowerTxt) ||
          d.driverName?.toLowerCase().includes(lowerTxt) ||
          d.fleetName?.toLowerCase().includes(lowerTxt) ||
          d.ignitionStatus?.toLowerCase().includes(lowerTxt) ||
          d.deviceName?.toLowerCase().includes(lowerTxt) ||
          d.vehicleName?.toLowerCase().includes(lowerTxt);
      }

      for (const node of Object.values(filterTree)) {
        if (!(node.children[0].checked || node.children.filter(n => n.checked).length <= 0)) {
          const checkedValues = [];
          for (let childNode of node.children) {
            if (childNode.id === 0 && childNode.checked) {
              break;
            }
            if (childNode.id !== 0 && childNode.checked) {
              checkedValues.push(childNode.dataKey.toLowerCase());
            }
          }

          if (checkedValues.length > 0) {
            if (node.label === FilterByTypes.IgnitionStatus) {
              ignitionCondition = d => checkedValues.includes(d.ignitionStatus?.toLowerCase());
            } else if (node.label === FilterByTypes.DriverStatus) {
              driverStatusCondition = d =>
                checkedValues.includes(
                  d.driverId
                    ? DriverLogStatusKeyMap.LoggedOn.toLowerCase()
                    : DriverLogStatusKeyMap.LoggedOff.toLowerCase()
                );
            } else if (node.label === FilterByTypes.VehicleType) {
              vehicleTypesCondition = d => checkedValues.includes(d.vehicleTypeId?.toString());
            }
          }
        }
      }

      filteredList = filteredList?.filter(d => {
        const condition =
          searchCondition(d) &&
          ignitionCondition(d) &&
          driverStatusCondition(d) &&
          vehicleTypesCondition(d);
        if (condition) {
          deviceIdMap[d.deviceId] = true;
        }
        return condition;
      });

      setDeviceList(
        orderByFn(
          filteredList,
          [SortByKeyField[sortBy]],
          orderBy === OrderByKeyMap.Ascending ? 'asc' : 'desc'
        )
      );

      const filteredDevices = devices?.filter(d => deviceIdMap[d.id]) || [];
      if (msgClient) {
        msgClient.sendMsg({
          type: MsgType.FilteredResult,
          value: filteredDevices
        });
      }
    },
    [devices, originalDeviceList, sortBy, orderBy, msgClient]
  );

  const handleSearch = useCallback(
    txt => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      timerRef.current = setTimeout(() => {
        setSearchText(txt);
        filterDevices(txt, filters);
      }, 15);
    },
    [filters, filterDevices]
  );

  const handleFilterChanged = useCallback(
    f => {
      setFilters(f);
      filterDevices(searchText, f);
    },
    [searchText, filterDevices]
  );

  const handleSortChanged = useCallback(val => {
    setSortBy(val);
  }, []);

  const handleOrderByChanged = useCallback(e => {
    setIsSortDropOpen(false);
    setOrderBy(e.target.value);
  }, []);

  const selectDevice = useCallback(deviceId => {
    setDeviceList(prev => {
      const deivceIndex = prev.findIndex(d => d.deviceId === deviceId);
      const selectedDevIndex = prev.findIndex(d => d.selected);
      if (deivceIndex >= 0) {
        prev[deivceIndex] = { ...prev[deivceIndex], selected: true };
      }
      if (selectedDevIndex >= 0) {
        prev[selectedDevIndex] = { ...prev[selectedDevIndex], selected: false };
      }
      return Array.from(prev);
    });
  }, []);

  const handleDeviceFocused = useCallback(
    device => {
      setDeviceList(prev => {
        const d = prev.find(d => d.deviceId === device.deviceId);
        if (d) {
          d.focused = true;
        }
        return Array.from(prev);
      });
      if (msgClient) {
        msgClient.sendMsg({
          type: MsgType.DeviceFocused,
          value: device.deviceId
        });
      }
    },
    [msgClient]
  );

  const handleDeviceLostFocus = useCallback(
    device => {
      setDeviceList(prev => {
        const d = prev.find(d => d.deviceId === device.deviceId);
        if (d) {
          d.focused = false;
        }
        return Array.from(prev);
      });
      if (msgClient) {
        msgClient.sendMsg({
          type: MsgType.DeviceFocused,
          value: null
        });
      }
    },
    [msgClient]
  );

  const handleDeviceSelected = useCallback(
    device => {
      selectDevice(device.deviceId);
      if (msgClient) {
        msgClient.sendMsg({
          type: MsgType.DeviceSelected,
          value: device.deviceId
        });
      }
    },
    [selectDevice, msgClient]
  );

  const handleViewVehicleDevice = useCallback(deviceInfo => {
    if (deviceInfo.vehicleId) {
      history.push(`/settings/vehicles/id/${deviceInfo.vehicleId}`);
    } else {
      history.push(`/settings/devices/id/${deviceInfo.deviceId}`);
    }
  }, []);

  const handleMessageVehicle = useCallback(deviceInfo => {
    //rowData could be a standalone device or a device on a vehicle
    const recipient =
      !!deviceInfo.vehicleId && deviceInfo.vehicleId > -1
        ? {
            recipientType: RECIPIENT_TYPE.VEHICLE,
            recipientId: deviceInfo.vehicleId
          }
        : {
            recipientType: RECIPIENT_TYPE.DEVICE,
            recipientId: deviceInfo.deviceId
          };
    setRecipient(recipient);
    setShowpopup(true);
  }, []);

  const handleRouteTo = useCallback(
    deviceInfo => {
      setShowRouteToModal(true);
      const device = devices?.find(d => d.id === deviceInfo.deviceId);
      setRouteData(device);
    },
    [devices]
  );

  const cancelRouteTo = () => {
    setShowRouteToModal(false);
    setRouteData(null);
  };

  const handleRequestVideo = useCallback((vehicleId, deviceId) => {
    setRequestVideoDeviceId(deviceId);
    setRequestVideoVehicleId(vehicleId);
    setShowFootageModal(true);
  }, []);

  const handleMessaging = useCallback(
    msg => {
      if (msg.type === MsgType.DeviceSelected) {
        selectDevice(msg.value);
      } else if (msg.type === MsgType.DeviceFocused) {
        setDeviceList(prev => {
          const d = prev.find(d => d.deviceId === msg.value);
          if (d) {
            d.focused = true;
          }
          return Array.from(prev);
        });
      } else if (msg.type === MsgType.DeviceLostFocus) {
        setDeviceList(prev => {
          const d = prev.find(d => d.deviceId === msg.value);
          if (d) {
            d.focused = false;
          }
          return Array.from(prev);
        });
      }
    },
    [selectDevice]
  );

  const generateDeviceList = useCallback((devices, locale, centerLatLng) => {
    const devicesData = [];
    if (devices) {
      devices.forEach(d => {
        const exitObj = d.exit;
        const distance = getLinearDistance(
          centerLatLng,
          { lat: exitObj.lat, lng: exitObj.lng },
          locale.formats.speed.unit
        );
        devicesData.push({
          location: exitObj.location,
          distance: distance,
          distanceLabel: distance + ' ' + locale.formats.speed.unit_pluralize,
          driver: d.driver,
          driverName: d.driverName,
          fleetName: d.fleetName,
          ignitionStatus: d.deviceStats?.ignition,
          deviceName: d.name,
          vehicleName: d.vehicleName,
          deviceId: d.id,
          vehicleId: d.vehicleId,
          driverId: d.driver?.id,
          fleetId: d.fleetId,
          deviceIcon: getDeviceIcon(d),
          focused: false,
          selected: false,
          sortName: d.vehicleId ? d.vehicleName : d.name,
          vehicleTypeId: d.vehicle?.type?.id,
          messagingDevice: d.messagingDevice,
          routeToDevice: d.routeToDevice
        });
      });
    }

    return devicesData;
  }, []);

  useEffect(() => {
    const filterTree = {};
    let idx = 1;
    for (const filterType in FilterByTypes) {
      let node = {
        label: FilterByTypes[filterType],
        function: undefined,
        nodeKey: (idx + 1).toString(),
        id: idx + 1,
        children: [
          {
            ...selectAll,
            label: t('Common.' + selectAll.name),
            nodeKey: idx + 1 + PATH_SPLIT + 0
          }
        ]
      };

      if (
        filterType === FilterByTypesKeyMap.IgnitionStatus ||
        filterType === FilterByTypesKeyMap.DriverStatus
      ) {
        const typeObj =
          filterType === FilterByTypesKeyMap.IgnitionStatus ? IgnitionStatus : DriverLogStatus;
        for (const t in typeObj) {
          const nodeId = node.children.length;
          const childNode = {
            id: nodeId,
            label: typeObj[t],
            function: undefined,
            nodeKey: node.nodeKey + PATH_SPLIT + nodeId,
            dataKey: t
          };
          node.children.push(childNode);
        }
      } else if (filterType === FilterByTypesKeyMap.VehicleType && vehicleTypes) {
        for (const vt of vehicleTypes.filter(t => t.status === 'ENABLED')) {
          const nodeId = node.children.length;
          const childNode = {
            id: nodeId,
            label: vt.name,
            function: undefined,
            nodeKey: node.nodeKey + PATH_SPLIT + nodeId,
            dataKey: vt.id.toString()
          };
          node.children.push(childNode);
        }
      }

      filterTree[node.id] = node;
      idx++;
    }

    setFilters(filterTree);
  }, [vehicleTypes]);

  useEffect(() => {
    const devicesData = generateDeviceList(devices, locale, centerLatLng);
    setOriginalDeviceList(devicesData);
    setDeviceList(orderByFn(devicesData, [SortByKeyField.Location]));
  }, [centerLatLng, devices, locale, generateDeviceList]);

  useEffect(() => {
    if (msgClient) {
      msgClient.addOnMessageEventListener(handleMessaging);
    }
  }, [msgClient, handleMessaging]);

  const routeToProps = useRouteTo(routeData, centerLatLng);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    setDeviceList(prev => {
      return orderByFn(
        prev,
        [SortByKeyField[sortBy]],
        orderBy === OrderByKeyMap.Ascending ? 'asc' : 'desc'
      );
    });
  }, [sortBy, orderBy]);

  return (
    <Row className={styles.resultPanel} wrap={false}>
      <Col span={24}>
        <div className={styles.resultHeader}>
          <Row>
            <Col span={24}>
              <AntSearchbar className={styles.searchBar} onFilter={handleSearch} />
            </Col>
          </Row>
          <Row className={styles.filterContainer}>
            <DropdownTreeSelect
              title={t('Common.Filter')}
              showSelectAll={true}
              tree={filters}
              onChange={handleFilterChanged}
            />

            <Select
              open={isSortDropOpen}
              onDropdownVisibleChange={open => setIsSortDropOpen(open)}
              size="large"
              value={sortBy}
              placeholder={t('ELD.Sort By')}
              data={sortLists}
              title={t('ELD.Sort By')}
              onChange={handleSortChanged}
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: '5px 0px' }} />
                  <div className={styles.selectFooter}>
                    <p>{t('Common.Order')}</p>
                    <Radio.Group
                      size="large"
                      defaultValue={orderBy || OrderBy.Ascending}
                      onChange={handleOrderByChanged}
                    >
                      {Object.keys(OrderBy).map((k, i) => (
                        <Radio key={k} value={k}>
                          {OrderBy[k]}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </div>
                </div>
              )}
            />
          </Row>
          <Row className={styles.sortBy}>
            <span>
              <i
                className={
                  orderBy === 'Ascending' ? 'tn-i-column-sort-asc' : 'tn-i-column-sort-desc'
                }
              />
            </span>
            <span>{t('ELD.Sort By')}: </span>
            <span>{SortBy[sortBy] + ' ' + t('Common.in') + ' ' + OrderBy[orderBy]}</span>
          </Row>
        </div>
        <div className={styles.resultGrid}>
          {deviceList.map(d => (
            <DeviceInfo
              device={d}
              key={d.deviceId}
              onDeviceFocused={handleDeviceFocused}
              onDeviceLostFocus={handleDeviceLostFocus}
              onDeviceSelected={handleDeviceSelected}
              onMessageVehicle={handleMessageVehicle}
              onRequestVideo={handleRequestVideo}
              onRouteTo={handleRouteTo}
              onViewVehicleDevice={handleViewVehicleDevice}
            />
          ))}
          {!deviceList?.length && <p>{t('Tracking.NoNearByVehicles')}</p>}
        </div>
      </Col>
      <Col span={24} className={styles.resultFooter}>
        <Button size="large" onClick={onReset} id={BUTTON_IDS.nearestResultPaneReset}>
          {t('Common.New Search')}
        </Button>
      </Col>

      {showPopup && (
        <MessageComposeModal
          visible={showPopup}
          showModal={setShowpopup}
          recipients={[recipient]}
        />
      )}
      {showFootageModal && (
        <RequestFootageModal
          showModal={showFootageModal}
          vehicleId={requestVideoVehicleId}
          deviceId={requestVideoDeviceId}
          onClose={() => {
            setShowFootageModal(false);
          }}
        />
      )}
      <RouteToModal
        title={t('Tracking.RouteTo')}
        isOpen={showRouteToModal}
        data={routeData}
        handleCancel={cancelRouteTo}
        {...routeToProps}
      />
    </Row>
  );
}
