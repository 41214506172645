import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../config';
import { ApiClient } from '../../nextgen_api/index';
import { useSelector } from 'react-redux';

export const actionTypes = {
  init: 0,
  pending: 1,
  processing: 2,
  error: 3,
  done: 4
};

const statusType = {
  LogExportCompanyStatus: 0
};

function getStatusId(type, id) {
  switch (type) {
    case statusType.LogExportCompanyStatus:
      return `logexport_status_${id}`;
    default:
      return null;
  }
}

const REST_TIME = 5 * 1000;

export const LogTransferStatus = {
  New: 'In Progress',
  GenerateFileSuccessful: 'In Progress',
  GenerateFileFailed: 'Error',
  EmailSendSuccessful: 'Succeeded',
  EmailSendFailed: 'Error',
  WebServiceSendSuccessful: 'Succeeded',
  WebServiceSendFailed: 'Error'
};

export const logexport_data = {
  companies: {
    /*structure
    [company id]: [{
        id,
        companyId,
        driverIds,
        vehicleIds,
        initiatedAt,
        periodBegin,
        periodEnd,
        initiatedByUserId,
        destinations: {
            sendEmail,
            sendToSoapServer,
            status,
            errorDescription,
            attemptsMade,
            nextAttempt
        }
    }]
    */
  },
  status: {}
};

const logExportSlice = createSlice({
  name: 'logExportData',
  initialState: logexport_data,
  reducers: {
    //fetch log export data by company id
    fetchLogExportByCompanyIdStart(state, { payload }) {
      const companyId = payload;
      const statusId = getStatusId(statusType.LogExportCompanyStatus, companyId);
      if (statusId != null) {
        state.status[statusId] = {
          fetching: actionTypes.processing,
          fetchingTime: moment().valueOf(),
          error: null
        };
      }
    },
    fetchLogExportByCompanyIdSucceeded(state, { payload }) {
      const { companyId, data } = payload;
      const statusId = getStatusId(statusType.LogExportCompanyStatus, companyId);
      if (data != null) {
        state.companies[companyId] = data;
      }
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchLogExportByCompanyIdFailed(state, { payload }) {
      const { companyId, error } = payload;
      const statusId = getStatusId(statusType.LogExportCompanyStatus, companyId);
      state.status[statusId] = {
        ...state.status[statusId],
        fetching: actionTypes.error,
        error: error
      };
    }
  }
});

export const {
  fetchLogExportByCompanyIdStart,
  fetchLogExportByCompanyIdSucceeded,
  fetchLogExportByCompanyIdFailed
} = logExportSlice.actions;

export const fetchLogExport = (companyId, userKey) => async (dispatch, getState) => {
  if (companyId == null || userKey == null) return;

  const statusId = getStatusId(statusType.LogExportCompanyStatus, companyId);
  const fetchInfo = getState().logExportData.status[statusId];
  if (
    fetchInfo != null &&
    (fetchInfo.fetching === actionTypes.processing ||
      moment().valueOf() - fetchInfo.fetchingTime < REST_TIME)
  ) {
    return;
  }

  dispatch(fetchLogExportByCompanyIdStart(companyId));

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const url = '/eld/LogTransfer';
    apiClient.callApi(
      url,
      'GET',
      {},
      {
        companyId
      },
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchLogExportByCompanyIdSucceeded({ companyId: companyId, data: data }));
  } catch (err) {
    const payload = { companyId: companyId, error: err.toString() };
    dispatch(fetchLogExportByCompanyIdFailed(payload));
  }
};

export const useGetLogExports = companyId => {
  const logExports = useSelector(state => state.logExportData.companies);
  const companyLogExports = logExports[companyId];
  return companyLogExports;
};

export const getLogReport = async (userKey, reportId, retryCount = 0) => {
  if (reportId == null) {
    return Promise.reject("ReportId can't be empty.");
  }

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const url = '/eld/logreport/{reportId}';
    apiClient.callApi(
      url,
      'GET',
      {
        reportId: reportId
      },
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp);
        }
      }
    );
  });

  const data = await promise;

  //retry
  if (Number(retryCount) < 12) {
    if (data && data?.text && data.text.length > 0) {
      window.open(data.text, '_blank');
      return;
    }

    setTimeout(function() {
      const retry = Number(retryCount) + 1;
      getLogReport(userKey, reportId, retry);
    }, 5000);
  } else {
    console.error('Max retries reached');
    return;
  }
};

export default logExportSlice.reducer;
