export const DELETE_CAMERA_FIELDS = 'companyConfig/DELETE_CAMERA_FIELDS';
export const DELETE_DRIVER_MANAGEMENT_FIELDS = 'companyConfig/DELETE_DRIVER_MANAGEMENT_FIELDS';
export const DELETE_VEHICLE_MAINTENANCE_FIELDS = 'companyConfig/DELETE_VEHICLE_MAINTENANCE_FIELDS';
export const FETCH_CAMERA_FIELDS = 'companyConfig/FETCH_CAMERA_FIELDS';
export const FETCH_DRIVER_MANAGEMENT_FIELDS = 'companyConfig/FETCH_DRIVER_MANAGEMENT_FIELDS';
export const FETCH_VEHICLE_MAINTENANCE_FIELDS = 'companyConfig/FETCH_VEHICLE_MAINTENANCE_FIELDS';
export const UPDATE_CAMERA_FIELDS = 'companyConfig/UPDATE_CAMERA_FIELDS';
export const UPDATE_DRIVER_MANAGEMENT_FIELDS = 'companyConfig/UPDATE_DRIVER_MANAGEMENT_FIELDS';
export const UPDATE_VEHICLE_MAINTENANCE_FIELDS = 'companyConfig/UPDATE_VEHICLE_MAINTENANCE_FIELDS';
export const UPDATE_NON_BUSINESS_CONFIG = 'companyConfig/UPDATE_NON_BUSINESS_CONFIG';
export const UPDATE_SMARTJOBS_INTEGRATION_CONFIG =
  'companyConfig/UPDATE_SMARTJOBS_INTEGRATION_CONFIG';
export const FETCH_CARRIER_DATA = 'companyConfig/FETCH_CARRIER_DATA';
export const FETCH_MULTICARRIER_FIELDS = 'companyConfig/FETCH_MULTICARRIER_FIELDS';
export const UPDATE_MULTICARRIER_FIELDS = 'companyConfig/UPDATE_MULTICARRIER_FIELDS';
export const UPDATE_SMART_DASHCAM_CONFIG = 'companyConfig/UPDATE_SMART_DASHCAM_CONFIG';
export const FETCH_WITHIN_CONFIG = 'companyConfig/FETCH_WITHIN_CONFIG';
