/* global google */
import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, withGoogleMap } from 'react-google-maps';
import smartjobMarker from 'static/images/icons/location_on_24px.svg';
import { useDispatch } from 'react-redux';
import {
  DEFAULT_LOCATION,
  NUMBER_OF_OPAQUE_ARROWS,
  STOP_STATUS,
  polylineColors,
  MAX_WAYPOINTS
} from './utils/constants';
import {
  chunk,
  getActualPolylinesArray,
  getActualStopMarkers,
  getOpaqueArrow,
  getPolyline,
  getPolylines,
  getRunsheetActualStops,
  getRunsheetStops,
  getStopMarkers,
  setRoutePolylines
} from './utils/helpers';
import { polylineArrowAnimationSpeed } from 'containers/Easydocs/constants';
import { useAllRegions } from 'features/regions/regionsSlice';
import { useCurrentCompany } from 'features/company/companySlice';

export const RunsheetMap = withGoogleMap(({ runsheet, positions }) => {
  const map = useRef(null);
  const regions = useAllRegions();
  const currentCompany = useCurrentCompany();
  const directionsService = new google.maps.DirectionsService();
  const directionsRenderer = new google.maps.DirectionsRenderer();
  const [bounds, setBounds] = useState(new google.maps.LatLngBounds());
  const [opaqueArrowIndex, setOpaqueArrowIndex] = useState(0);
  const [iconArray, setIconArray] = useState([]);
  const [stopsArray, setStopsArray] = useState([]);
  const [actualStopsArray, setActualStopsArray] = useState([]);
  const [plannedPolylines, setPlannedPolylines] = useState([]);
  const [actualPolylines, setActualPolylines] = useState([]);
  const [stopMarkers, setStopMarkers] = useState([]);
  const [actualStopMarkers, setActualStopMarkers] = useState([]);
  const [polylineElements, setPolylineElements] = useState([]);
  const [actualPolylineElements, setActualPolylineElements] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const value = getStopMarkers(stopsArray, smartjobMarker);
    setStopMarkers(value);
  }, [stopsArray]);

  useEffect(() => {
    const value = getActualStopMarkers(actualStopsArray);
    setActualStopMarkers(value);
  }, [actualStopsArray]);

  useEffect(() => {
    const value = getPolylines(
      { current: plannedPolylines, color: polylineColors.tnColorHavelockBlueDark, zIndex: 0 },
      iconArray
    );
    setPolylineElements(value);
  }, [iconArray, plannedPolylines]);

  useEffect(() => {
    const value = getPolylines(
      { current: actualPolylines, color: polylineColors.tnColorJungleGreen, zIndex: 1 },
      iconArray
    );

    setActualPolylineElements(value);
  }, [actualPolylines, iconArray]);

  useEffect(() => {
    const positionsArray = Array.isArray(positions) ? positions : positions?.GPS;
    const polyline = getActualPolylinesArray(positionsArray);
    setActualPolylines(polyline);
  }, [positions]);

  useEffect(() => {
    const stops = getRunsheetStops(runsheet) || [];
    const actualStops = getRunsheetActualStops(runsheet) || [];

    setStopsArray(stops);
    setActualStopsArray(actualStops);
  }, [runsheet]);

  useEffect(() => {
    let routePolylines = [];
    const validStops = stopsArray.reduce((accumulator, stop) => {
      if (stop.status !== STOP_STATUS.CANCELLED && stop.coordinates.lat && stop.coordinates.lng) {
        accumulator.push(stop.coordinates);
      }
      return accumulator;
    }, []);

    const splitStops = chunk(validStops, MAX_WAYPOINTS);

    /*to avoid gaps in the route due to the limit of the waypoints, 
    we include at the beggining of every splitStop array (except the first one) 
    the last element of the previous one */
    splitStops.forEach(
      (chunk, index) =>
        index && chunk.unshift(splitStops[index - 1][splitStops[index - 1].length - 1])
    );

    //the origin and destination don't count as waypoints
    //the limit is origin + destination + 10 waypoints
    splitStops.forEach(chunk => {
      const origin = chunk.shift();
      const destination = chunk.pop();

      const mapPoints = {
        origin: origin,
        destination: destination,
        waypts: chunk.map(val => ({
          location: val,
          stopover: false
        }))
      };

      const directions = {
        service: directionsService,
        renderer: directionsRenderer
      };
      const current = { setRoutePolylines, routePolylines, dispatch };

      getPolyline(mapPoints, directions, current);
      setPlannedPolylines(routePolylines);
    });
  }, [stopsArray]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      let iconarray = [];
      for (let arrowIndex = 0; arrowIndex < NUMBER_OF_OPAQUE_ARROWS; arrowIndex++) {
        iconarray.push(getOpaqueArrow(arrowIndex, opaqueArrowIndex));
        if (arrowIndex === opaqueArrowIndex) {
          if (arrowIndex === NUMBER_OF_OPAQUE_ARROWS - 1) {
            setOpaqueArrowIndex(0);
          } else {
            setOpaqueArrowIndex(arrowIndex + 1);
          }
        }
      }
      setIconArray(iconarray);
    }, polylineArrowAnimationSpeed);

    return () => clearTimeout(timeout);
  }, [opaqueArrowIndex]);

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds();

    if (!(stopsArray?.length || actualStopsArray?.length)) {
      const region = regions.find(region => region.code === currentCompany.country);
      const geocode = JSON.parse(region?.geocode || '{}');

      geocode.bounds &&
        geocode.bounds.forEach(bound => {
          const point = new google.maps.LatLng(...bound);
          bounds.extend(point);
        });
    }

    actualPolylines &&
      actualPolylines.forEach(polyline => {
        polyline.forEach(latLng => {
          bounds.extend(latLng);
        });
      });

    plannedPolylines &&
      plannedPolylines.forEach(polyline => {
        polyline.forEach(latLng => {
          bounds.extend(latLng);
        });
      });

    stopsArray &&
      stopsArray.forEach(stop => {
        if (stop?.coordinates?.lat && stop?.coordinates?.lng) {
          const point = new google.maps.LatLng(stop?.coordinates?.lat, stop?.coordinates?.lng);
          bounds.extend(point);
        }
      });

    actualStopsArray &&
      actualStopsArray.forEach(stop => {
        if (stop?.position?.lat && stop?.position?.lng) {
          const point = new google.maps.LatLng(stop?.position?.lat, stop?.position?.lng);
          bounds.extend(point);
        }
      });

    setBounds(bounds);
  }, [
    regions,
    actualPolylines,
    plannedPolylines,
    currentCompany.country,
    stopsArray,
    actualStopsArray
  ]);

  useEffect(() => {
    map.current.fitBounds(bounds);
  }, [bounds]);

  return (
    <GoogleMap defaultZoom={10} defaultCenter={DEFAULT_LOCATION} ref={map}>
      {stopMarkers}
      {polylineElements}

      {actualStopMarkers}
      {actualPolylineElements}
    </GoogleMap>
  );
});
