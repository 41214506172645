import React, { useState, useEffect, useRef } from 'react';
import { embedQSearchBar } from 'amazon-quicksight-embedding-sdk';

import { useGetQsSearchbarUrlQuery } from 'services/nextgen';

import { useLocalization } from 'features/localization/localizationSlice';
import { Can, services } from 'features/permissions';
import { useCurrentCompany } from 'features/company/companySlice';

import styles from './Insights.module.scss';

const Searchbar = () => {
  const localization = useLocalization();
  const searchbarContainerRef = useRef(null);
  const searchbarFrameRef = useRef(null);
  const currentCompany = useCurrentCompany();
  const searchbarUrl = useGetQsSearchbarUrlQuery({ companyId: currentCompany.id });

  const [qSession, setQSession] = useState();

  const onLoad = () => {
    // console.debug('searchbar - onLoad');
    searchbarContainerRef.current.style.display = 'block';
    setTimeout(() => {
      searchbarContainerRef.current.style.height = '80px';
    }, 10);
    setTimeout(() => {
      searchbarFrameRef.current.style.height = '80px';
      searchbarFrameRef.current.style.opacity = 1;
    }, 100);
  };

  const onError = payload => {
    // console.debug('searchbar - onError', payload);
    searchbarContainerRef.current.style.display = 'none';
    searchbarContainerRef.current.style.height = '0px';
    searchbarFrameRef.current.style.opacity = 0;
  };

  const onOpen = () => {
    // console.debug('searchbar - onOpen');
  };

  const onClose = () => {
    // console.debug('searchbar - onClose');
    searchbarFrameRef.current.style.height = '80px';
  };

  useEffect(() => {
    // console.debug('searchbar - currentCompany change');
    setQSession();
  }, [currentCompany]);

  useEffect(() => {
    const frameDiv = searchbarFrameRef.current;

    // console.debug('searchbar - embed sdk - before', { searchbarUrl, qSession, frameDiv });

    if (!qSession && frameDiv && searchbarUrl.data) {
      const options = {
        url: searchbarUrl.data,
        container: frameDiv,
        locale: localization.locale,
        width: '100%',
        qSearchBarOptions: {
          iconDisabled: true,
          topicNameDisabled: false,
          allowTopicSelection: true,
          maxHeightForQ: '500px'
        }
      };
      const session = embedQSearchBar(options);
      session.on('load', onLoad);
      session.on('error', onError);
      session.on('open', onOpen);
      session.on('close', onClose);
      setQSession(session);
      // console.debug('searchbar - embed sdk - after', { searchbarUrl, session, options });
    }
  }, [searchbarFrameRef, searchbarUrl]);

  return (
    <Can everyCompanyService={services.INSIGHTSSEARCH} otherConditions={[() => searchbarUrl.data]}>
      <div className={styles.searchbarContainer} ref={searchbarContainerRef}>
        <div className={styles.searchbarFrame} ref={searchbarFrameRef} />
      </div>
    </Can>
  );
};

export default Searchbar;
