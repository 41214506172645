import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

//components
import { AuditsTable } from 'components/auditsTable/AuditsTable';

//slices
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits } from 'features/audits';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';

// helpers
import { getIDFromPathname } from 'utils/methods';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

//constants
import { Paths } from './constants';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { useHistory } from 'react-router';

import { useGeofences } from 'features/geofences/geofencesSlice';

export const GeofenceAudits = ({ geofenceId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = geofenceId || getIDFromPathname(window.location.pathname);
  const audits = useAudits(AUDIT_ENTITY.GEOFENCE, id);
  const history = useHistory();
  const geofences = useGeofences(true);
  const geofence = geofences?.find(location => location.id === Number(id));
  const handleFetchError = useCallback(() => {
    history.replace(Paths.Default);
  }, [history]);
  const pageTitle = !geofence
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: `${geofence?.name}` });

  useRedirectToMainFeaturePageOnCompanyChange('/settings/geofences');

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(pageTitle));
  }, [pageTitle, dispatch]);

  useEffect(() => {
    if (id <= 0 || isNaN(id) || (geofences?.length > 0 && !geofence && !audits)) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [dispatch, id, geofences, handleFetchError, geofence, audits]);

  return <AuditsTable audits={audits} entity={AUDIT_ENTITY.GEOFENCE} />;
};
