import React from 'react';
import { Button, Table, Tooltip } from 'antd';
import { InfoCircleFilled, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { prepareColumnsForInboundTable, prepareDataForInboundTable } from '../helpers';

import styles from '../Configuration.module.scss';

export const Inbound = ({ adapter, handleDisableInbound, showCloseInboundModal }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.inbound}>
        <div className={styles.header}>
          <div className={styles.title}>
            {t('Integrations.Inbound')}
            <div className={styles.infoIcon}>
              <Tooltip
                overlayClassName={styles.tooltipStyle}
                defaultOpen={true}
                placement={'topRight'}
                title={t('Integrations.Tooltip.InboundInfo')}
              >
                <InfoCircleFilled className={styles.inboundInfo} />
              </Tooltip>
            </div>
          </div>
          <div className={styles.addButton}>
            <Button onClick={showCloseInboundModal} icon={<PlusOutlined />}>
              {t('Integrations.AddInbound')}
            </Button>
          </div>
        </div>
        <div className={styles.data}>
          <Table
            className={styles.table}
            dataSource={prepareDataForInboundTable(adapter?.inbound, { handleDisableInbound }, t)}
            columns={prepareColumnsForInboundTable(t)}
            pagination={false}
          />
        </div>
      </div>
    </div>
  );
};
