import React from 'react';
import { Button } from 'antd';
import cx from 'classnames';

import styles from './buttons.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const ToggleButton = ({
  label,
  onToggle,
  isActive = false,
  isDisabled = false,
  isLoading = false,
  id = BUTTON_IDS.toggle,
  ...props
}) => {
  return (
    <Button
      className={cx(
        styles.toggleButton,
        { [styles.active]: isActive },
        { [styles.disabled]: isDisabled }
      )}
      onClick={() => {
        onToggle && onToggle(!isActive);
      }}
      id={id}
      disabled={isDisabled}
      loading={isLoading}
      {...props}
    >
      {label}
    </Button>
  );
};
