import lightLogoName from '../images/Teletrac_Navman_White_Wordmark.svg';
import greyLogoName from '../images/logo_name_grey.svg';
import { defaultTheme } from './_rebassThemes';

const colors = {
  tomato: '#ff4d4f',
  tomato_tangerine: '#E3784C',
  tangerine_tomato: '#EC8F38',
  tangerine: '#F5A623',
  tangerine_topaz: '#F3B117',
  topaz_tangerine: '#F2BB0C',
  topaz: '#ffc53d',
  topaz_emerald: '#ADC32E',
  emerald_topaz: '#69C05D',
  emerald: '#26bd8b',
  emerald_sapphire: '#37AFA5',
  sapphire_emerald: '#47A1BE',
  sapphire: '#1890ff',
  sapphire_amethyst: '#5A7FD8',
  amethyst_sapphire: '#5B6CD8',
  amethyst: '#5d58d8',
  amethyst_tomato: '#875BB0',
  tomato_amethyst: '#B05E89',
  steel: '#969696'
};

const greys = {
  black: '#000000',
  d200: '#4a4a4a',
  d100: '#717171',
  d000: '#9fa9b7',
  l100: '#bcc3cd',
  l200: '#dddddd',
  l300: '#f7f8f9',
  white: '#ffffff'
};

const slate = {
  d400: '#2b323c',
  d300: '#2f333a',
  d200: '#374049',
  d100: '#3e4651',
  d000: '#434c57',
  l100: '#525f70',
  l200: '#545f6f',
  l300: '#939ca8'
};

export const baseTheme = {
  // ...defaultTheme,
  colors: colors,
  slate: slate,
  greys: greys,
  black: greys.black,
  white: greys.white,
  brand: '#9cca55'
};

// Should be 1:1 to colors defined in GlobalNav.styles.js
export const darkTheme = {
  ...defaultTheme,
  base: baseTheme,
  logoSrc: lightLogoName,
  brand: baseTheme.brand,
  nav: {
    background: baseTheme.slate.d400,
    color: baseTheme.greys.white,
    afterBackground: baseTheme.black
  },
  logo: {
    borderColor: baseTheme.black
  },
  subNavLinks: {
    color: baseTheme.greys.d000
  },
  navLink: {
    hoverBackground: baseTheme.brand,
    hoverColor: baseTheme.brand,
    hoverBeforeBackground: baseTheme.brand
  },
  navItem: {
    borderColor: baseTheme.black,
    hoverBackground: baseTheme.white,
    hoverColor: baseTheme.white
  },
  navExpand: {
    background: baseTheme.slate.d400,
    color: baseTheme.greys.d000,
    hoverBackground: baseTheme.slate.d200
  },
  topActions: {
    background: baseTheme.white,
    color: baseTheme.greys.d100
  },

  buttons: {
    ...defaultTheme.buttons,
    action: {
      background: baseTheme.greys.d100,
      color: baseTheme.greys.d000
    },
    profile: {
      background: baseTheme.slate.d400
    },
    fleetDropdown: {
      color: baseTheme.greys.d400
    }
  }
};

export const lightTheme = {
  ...darkTheme,
  logoSrc: greyLogoName,
  nav: {
    background: baseTheme.white,
    color: baseTheme.greys.d100,
    afterBackground: baseTheme.greys.l200
  },
  logo: {
    borderColor: baseTheme.greys.l200
  },
  subNavLinks: {
    color: baseTheme.greys.d000
  },
  navExpand: {
    background: baseTheme.greys.l100,
    color: baseTheme.greys.d000,
    hoverBackground: baseTheme.greys.l200
  },
  buttons: {
    ...darkTheme.buttons,
    profile: {
      background: baseTheme.greys.d100
    }
  }
};
