import axios from 'axios';
import PQueue from 'p-queue';
//@Author Durga Prasad Kolli
// Create a queue with a concurrency limit
const queue = new PQueue({ concurrency: 100 });

const fetchImage = async ({ url, name, createdAt }) => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache'
      }
    });
    return {
      name,
      blob: response.data,
      createdAt
    };
  } catch (error) {
    console.error('Fetch error:', error);
    return null;
  }
};

const fetchImageBlobs = async (attachments, onProgress) => {
  if (!attachments || !Array.isArray(attachments) || attachments.length === 0) {
    console.warn('No attachments provided or attachments is not an array.');
    return [];
  }

  const total = Math.min(attachments.length);
  let completed = 0;

  try {
    const urlNamePairs = attachments.map(attachment => ({
      url: attachment?.url,
      name: attachment?.name,
      createdAt: attachment?.createdAt
    }));

    // Create an array of tasks to be processed by the queue
    const tasks = urlNamePairs.map(pair => async () => {
      const result = await fetchImage(pair);
      completed += 1; // Increment completed count
      if (onProgress) {
        onProgress({ completed, total });
      }
      return result;
    });

    // Add tasks to the queue and wait for all to complete
    const results = await Promise.all(tasks.map(task => queue.add(task)));

    // Filter out null results (failed fetches)
    return results.filter(result => result !== null);
  } catch (error) {
    console.error('Error fetching image blobs:', error);
    throw error; // Re-throw the error to let the caller handle it
  }
};

export { fetchImageBlobs };
