import { FeatureFlag } from './featureFlags';
import { useUserInfo } from 'features/user/userSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import {
  useCompanyConfigPermissons,
  useEntityPermissons,
  useServicePermissons
} from 'features/permissions/permissionsSlice';
import { useCurrentCompanyServices } from 'features/company/companySlice';
import { services as Services } from 'features/permissions/services';

import { isSuperAdmin } from 'utils/permissions';

import { API_PATH } from 'config';

export const useCan = () => {
  const entities = useEntityPermissons();
  const services = useServicePermissons();
  const company = useCurrentCompany();
  const companyServices = useCurrentCompanyServices();
  const companyConfiguration = useCompanyConfigPermissons();
  const user = useUserInfo();

  return ({
    everyEntity,
    oneOfEntities,
    everyService,
    oneOfServices,
    everyRole,
    oneOfRoles,
    noneOfRoles,
    beyondRoles,
    everyCompanyService,
    oneOfCompanyServices,
    companyConfigs,
    featureFlag,
    oneOfFeatureFlags,
    featureHide,
    otherConditions,
    path
  }) => {
    let canChecks = [];
    const checkEveryEntity = canEveryEntity(everyEntity, entities);
    const checkOneOfEntities = canOneOfEntities(oneOfEntities, entities);
    const checkEveryService = canEveryService(everyService, services);
    const checkOneOfServices = canOneOfServices(oneOfServices, services);
    const checkEveryRole = canEveryRole(everyRole, user);
    const checkOneOfRoles = canOneOfRoles(oneOfRoles, user);
    const checkNoneOfRoles = cannotOneOfRoles(noneOfRoles, user);
    const checkBeyondRoles = canBeyondRoles(beyondRoles, user);
    const checkEveryCompanyService = canEveryCompanyService(everyCompanyService, companyServices);
    const checkOneOfCompanyServices = canOneOfCompanyServices(
      oneOfCompanyServices,
      companyServices
    );
    const checkOneOfCompanyConfigs = canOneOfCompanyConfigs(companyConfigs, companyConfiguration);
    const checkFeatureFlag = canFeatureFlag(
      { featureFlag, path },
      user,
      companyServices,
      services,
      company
    );
    const checkHideFeature = canFeatureHide(featureHide, company);

    const checkOneOfFeatureFlags = canOneOfFeatureFlags(
      oneOfFeatureFlags,
      user,
      companyServices,
      services,
      company,
      path
    );

    if (everyEntity) {
      canChecks.push(checkEveryEntity);
    }

    if (oneOfEntities) {
      canChecks.push(checkOneOfEntities);
    }

    if (everyService) {
      canChecks.push(checkEveryService);
    }

    if (oneOfServices) {
      canChecks.push(checkOneOfServices);
    }

    if (everyRole) {
      canChecks.push(checkEveryRole);
    }

    if (oneOfRoles) {
      canChecks.push(checkOneOfRoles);
    }

    if (noneOfRoles) {
      canChecks.push(checkNoneOfRoles);
    }

    if (beyondRoles) {
      canChecks.push(checkBeyondRoles);
    }

    if (everyCompanyService) {
      canChecks.push(checkEveryCompanyService);
    }

    if (oneOfCompanyServices) {
      canChecks.push(checkOneOfCompanyServices);
    }

    if (companyConfigs) {
      canChecks.push(checkOneOfCompanyConfigs);
    }

    if (featureFlag || path) {
      canChecks.push(checkFeatureFlag);
    }

    if (featureHide) {
      canChecks.push(checkHideFeature);
    }

    if (oneOfFeatureFlags) {
      canChecks.push(checkOneOfFeatureFlags);
    }

    if (otherConditions) {
      canChecks.push(otherConditions.every(f => f()));
    }

    return canChecks.every(check => check);
  };
};

export const useCanEveryEntity = entities => {
  const userEntities = useEntityPermissons();

  if (Array.isArray(entities)) {
    return entities.every(eP => userEntities.includes(eP));
  }
  return userEntities.includes(entities);
};

export const useCanOneOfEntities = entities => {
  const userEntities = useEntityPermissons();

  if (Array.isArray(entities)) {
    return entities.some(eP => userEntities.includes(eP));
  }
  return userEntities.includes(entities);
};

export const useCanEveryService = services => {
  const userServices = useServicePermissons();

  if (Array.isArray(services)) {
    return services.every(sP => userServices.includes(sP));
  }
  return !!userServices.includes(services);
};

export const useCanOneOfServices = services => {
  const userServices = useServicePermissons();

  if (services && services[0] === 'SMARTNAV3D') console.log('service list', userServices, services);
  if (Array.isArray(services)) {
    return services.some(sP => userServices.includes(sP));
  }
  return userServices.includes(services);
};

export const useCanEveryRole = roles => {
  const user = useUserInfo();

  return canEveryRole(roles, user);
};

export const useCanOneOfRoles = roles => {
  const user = useUserInfo();

  return canOneOfRoles(roles, user);
};

export const useCannotOneOfRoles = roles => {
  const user = useUserInfo();
  return cannotOneOfRoles(roles, user);
};

export const useCanBeyondRoles = roles => {
  const user = useUserInfo();
  return canBeyondRoles(roles, user);
};

export const useCanEveryCompanyService = services => {
  const companyServices = useCurrentCompanyServices();

  return canEveryCompanyService(services, companyServices);
};

export const useCanOneOfCompanyServices = services => {
  const companyServices = useCurrentCompanyServices();

  return canOneOfCompanyServices(services, companyServices);
};

export const useCanOneOfCompanyConfigs = configs => {
  const companyConfigs = useCompanyConfigPermissons();

  return canOneOfCompanyConfigs(configs, companyConfigs);
};

export const useCanFeatureFlag = flag => {
  const user = useUserInfo();
  const companyServices = useCurrentCompanyServices();
  const userServices = useServicePermissons();
  const company = useCurrentCompany();

  return canFeatureFlag(flag, user, companyServices, userServices, company);
};

export const useCanOneOfFeatureFlags = flags => {
  const user = useUserInfo();
  const companyServices = useCurrentCompanyServices();
  const userServices = useServicePermissons();
  const company = useCurrentCompany();
  return canOneOfFeatureFlags(flags, user, companyServices, userServices, company);
};

export const useCanNPI = () => {
  const companyServices = useCurrentCompanyServices();
  const userServices = useServicePermissons();

  return canNPI(companyServices, userServices);
};

export const useCanFeatureHide = hideFeature => {
  const company = useCurrentCompany();

  return canFeatureHide(hideFeature, company);
};

const canEveryEntity = (expectedEntities, userEntities) => {
  if (Array.isArray(expectedEntities)) {
    return expectedEntities.every(eP => (userEntities || []).includes(eP));
  }
  return (userEntities || []).includes(expectedEntities);
};

const canOneOfEntities = (expectedEntities, userEntities) => {
  if (Array.isArray(expectedEntities)) {
    return expectedEntities.some(eP => userEntities.includes(eP));
  }
  return (userEntities || []).includes(expectedEntities);
};

const canEveryService = (expectedServices, userServices) => {
  if (Array.isArray(expectedServices)) {
    return expectedServices.every(sP => (userServices || []).includes(sP));
  }
  return !!(userServices || []).includes(expectedServices);
};

const canOneOfServices = (expectedServices, userServices) => {
  if (Array.isArray(expectedServices)) {
    return expectedServices.some(sP => userServices.includes(sP));
  }
  return (userServices || []).includes(expectedServices);
};

const canEveryRole = (expectedRoles, user) => {
  if (
    !expectedRoles ||
    !user?.roles ||
    !(
      expectedRoles.every(r => user.roles.some(ur => ur.global && ur.name?.trim() === r)) ||
      user.siteAdmin
    )
  ) {
    return false;
  }
  return true;
};

const canOneOfRoles = (expectedRoles, user) => {
  if (
    !expectedRoles ||
    !user?.roles ||
    !(
      expectedRoles.some(r => user.roles.some(ur => ur.global && ur.name?.trim() === r)) ||
      user.siteAdmin
    )
  ) {
    return false;
  }
  return true;
};

const cannotOneOfRoles = (expectedRoles, user) => {
  if (
    !expectedRoles ||
    !user?.roles ||
    !expectedRoles.some(r => user.roles.some(ur => ur.global && ur.name?.trim() === r))
  ) {
    return true;
  }
  return false;
};

const canBeyondRoles = (expectedRoles, user) => {
  return (
    !expectedRoles?.length ||
    !user?.roles ||
    user.siteAdmin ||
    user.roles.every(ur => !ur.global) ||
    user.roles.filter(ur => ur.global).some(ur => !expectedRoles.includes(ur.name?.trim()))
  );
};

const canEveryCompanyService = (expectedServices, companyServices) => {
  if (!expectedServices || !companyServices) {
    return false;
  }
  if (Array.isArray(expectedServices)) {
    return expectedServices.every(eP => companyServices?.find(f => f.code === eP) != null);
  }
  return companyServices?.find(f => f.code === expectedServices) != null;
};

const canOneOfCompanyServices = (expectedServices, companyServices) => {
  if (!expectedServices || !companyServices) {
    return false;
  }
  if (Array.isArray(expectedServices)) {
    return expectedServices.some(eP => companyServices?.find(f => f.code === eP) != null);
  }
  return companyServices?.find(f => f.code === expectedServices) != null;
};

const canOneOfCompanyConfigs = (configs, companyConfigs) => {
  if (configs == null || companyConfigs == null || !configs.some(r => companyConfigs[r] === '1')) {
    return true;
  }
  return false;
};

const canFeatureFlag = (flag, user, companyServices, userServices, company) => {
  // no API_PATH, we're likely in test or build tooling.  return true as we have nothing to check against.
  if (!flag || !API_PATH) {
    return true;
  }

  const featureFlag = flag.featureFlag
    ? FeatureFlag[flag.featureFlag]
    : Object.values(FeatureFlag).find(feature => feature.path && feature.path === flag?.path);

  // unflagged feature, no active checks
  if (!featureFlag) {
    return true;
  }

  let checkNPI = true;
  let checkSA = true;
  let checkAllowEnvs = true;
  let checkBlockEnvs = true;
  let checkAutomation = true;
  let checkAllowCompany = true;

  // verify NPI company service & user role service/module
  if (featureFlag.useNPI) {
    checkNPI = canNPI(companyServices, userServices);
  }

  // verify user is Site Admin
  if (featureFlag.useSA) {
    checkSA = isSuperAdmin(user);
  }

  // verify in allowed envs
  if (featureFlag?.allow) {
    checkAllowEnvs = featureFlag.allow.includes(API_PATH);
  }

  // verify not in blocked envs
  if (featureFlag?.block) {
    checkBlockEnvs = !featureFlag.block.includes(API_PATH);
  }

  // verify to see if the user is automation
  if (featureFlag.disableForAutomation && window.localStorage.getItem('AutomationTest')) {
    checkAutomation = false;
  }

  //verify current company is allowed
  if (featureFlag.allowCompanies?.[API_PATH]) {
    const { allowCompanyIds, allowSubCompanies } = featureFlag.allowCompanies[API_PATH];
    checkAllowCompany =
      company?.id && allowCompanyIds.some(companyId => String(companyId) === String(company.id));

    if (!checkAllowCompany && allowSubCompanies) {
      checkAllowCompany =
        company?.parent?.id &&
        allowCompanyIds.some(companyId => String(companyId) === String(company.parent.id));
    }
  }
  return (
    checkNPI && checkSA && checkAllowEnvs && checkBlockEnvs && checkAutomation && checkAllowCompany
  );
};

const canOneOfFeatureFlags = (
  expectedFlags,
  user,
  companyServices,
  userServices,
  company,
  path
) => {
  return (
    expectedFlags?.length &&
    expectedFlags.some(featureFlag =>
      canFeatureFlag({ featureFlag, path }, user, companyServices, userServices, company)
    )
  );
};

const canNPI = (companyServices, userServices) => {
  return (
    canOneOfCompanyServices([Services.NPI], companyServices) &&
    canOneOfServices([Services.NPI], userServices)
  );
};

export const canFeatureHide = (hideFeature, company) => {
  return !company?.companyFeatures?.includes(hideFeature);
};
