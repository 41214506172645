export const fbtSelectors = {
  getTrips: state => state.fbt.trips,
  isFetchingTrips: state => state.fbt.isFetching,
  isTripsError: state => state.fbt.error,
  isTripsSuccess: state => state.fbt.success,
  getTripStates: state => state.fbt.tripStates,
  isFetchingTripState: state => state.fbt.meta.tripState.isFetching,
  isTripStateSuccess: state => state.fbt.meta.tripState.error,
  isTripStateError: state => state.fbt.meta.tripState.success,
  getPositions: id => state => state.fbt.positions[id],
  isFetchingPositions: id => state => state.fbt.meta.positions[id]?.isFetching,
  isPositionsSuccess: id => state => state.fbt.meta.positions[id]?.success,
  isPositionsError: id => state => state.fbt.meta.positions[id]?.error,
  isAddingTripState: state => state.fbt.meta.addTripState.isFetching,
  isDeletingTripState: state => state.fbt.meta.deleteTripState.isFetching,
  getDates: state => state.fbt.dates,
  filters: state => state.fbt.filters
};
