import React from 'react';
import { Layout } from 'antd';
import { Header } from './Header';
import { SnapshotsTable } from './Snapshots/SnapshotsTable.js';
import styles from './Snapshots.module.scss';
import { DailySnapshotsTable } from './DailySnapshots/DailySnapshotsTable';

export const SnapshotsContainer = ({ filters, snapshots, isDailyMode }) => {
  return (
    <Layout className={isDailyMode ? styles.dailySnapshots : styles.snapshots}>
      <Layout.Header>
        <Header {...filters} isDailyMode={isDailyMode} />
      </Layout.Header>
      <Layout.Content>
        {isDailyMode ? <DailySnapshotsTable {...snapshots} /> : <SnapshotsTable {...snapshots} />}
      </Layout.Content>
    </Layout>
  );
};
