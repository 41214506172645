import React from 'react';
import CalibrationElement from './CalibrationElement';
import { useTranslation } from 'react-i18next';
import { METER_TYPES } from '../constants';

const CalibrationWrapper = ({
  vehicleData,
  calibrateValues,
  onCalibrate,
  onCalibrateValueChange,
  calibratingInProgress
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="calibration">
        <CalibrationElement
          type={METER_TYPES.HOURS}
          title={t('Vehicles.EngineHours')}
          vehicleData={vehicleData}
          value={calibrateValues && calibrateValues[METER_TYPES.HOURS]}
          onCalibrate={onCalibrate}
          onCalibrateValueChange={onCalibrateValueChange}
          calibratingInProgress={calibratingInProgress}
        />
        <CalibrationElement
          type={METER_TYPES.ODOMETER}
          title={t('Vehicles.Odometer')}
          vehicleData={vehicleData}
          value={calibrateValues && calibrateValues[METER_TYPES.ODOMETER]}
          onCalibrate={onCalibrate}
          onCalibrateValueChange={onCalibrateValueChange}
          calibratingInProgress={calibratingInProgress}
        />
      </div>
      {vehicleData?.meters?.some(m => m.source === METER_TYPES.GPIO) && (
        <div className="calibration_gpio">
          {vehicleData?.meters?.map(
            m =>
              m.source === METER_TYPES.GPIO && (
                <CalibrationElement
                  type={m.type}
                  title={t(`Vehicles.GPIO.${m.type}`)}
                  vehicleData={vehicleData}
                  value={calibrateValues && calibrateValues[m.type]}
                  onCalibrate={onCalibrate}
                  onCalibrateValueChange={onCalibrateValueChange}
                  calibratingInProgress={calibratingInProgress}
                />
              )
          )}
        </div>
      )}
    </div>
  );
};

export default CalibrationWrapper;
