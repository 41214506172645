import { useVehicles } from 'features/fleets/fleetsSlice';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'utils/strings';
import styles from './AlertData.module.scss';

export function MeterAlertContentRender({ alertData }) {
  const { t } = useTranslation();
  const vehicles = useVehicles();
  const currentVehicle = vehicles.find(vehicle => vehicle.id === alertData.vehicleId);
  const extras = alertData?.extras ? JSON.parse(alertData?.extras) : {};
  const isDeviceMeterAlert = alertData?.type === 'Ng::Event::DeviceMeterAlert';

  const getAlertExpression = () => {
    if (extras?.alert?.type && extras?.alert?.source && extras?.alert?.expression) {
      return (
        <span className={styles.location}>{`${capitalize(extras?.alert?.type)} (${capitalize(
          extras?.alert?.source
        )}) ${extras?.alert?.expression?.slice(11)}`}</span>
      );
    }
    return '';
  };

  return (
    <>
      {isDeviceMeterAlert
        ? t('Notifications.DeviceMeterAlert')
        : t('Notifications.VehicleMeterAlert')}{' '}
      <strong>{extras?.alert?.name || t('Common.Unknown')}</strong> {t('Notifications.FromVehicle')}{' '}
      - <strong>{currentVehicle?.name || t('Common.Unknown')}.</strong>
      <strong> {getAlertExpression()}</strong>
    </>
  );
}
