import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import moment from 'moment';
import { RowStatus, Status, Icon } from 'components/ant';
import { Row as AntRow, Divider, Typography, Modal } from 'antd';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { toTitleCase } from 'utils/strings';
import {
  maintenanceTypeCellRenderer,
  serviceDueCellRenderer,
  nextDueCellRenderer,
  serviceNameCellRenderer,
  cancelReasonRenderer
} from './CellRenderers';
import { deleteModal, handleDelete } from 'components/actionMenu/helpers';
import { FILTER_BY, Paths, STATUS, TABS, BUTTON_ACTIONS } from './constants';
import { getMeterBySource, getMeterValue, getMeterByType, MeterSource } from 'features/meters';
import { METER_TYPES } from 'containers/Administration/Vehicles/constants';
import ImagePreview from 'static/images/icons/image-preview.svg';

export const isFilteredByNextDue = filterValue => {
  return filterValue !== FILTER_BY.groupBy;
};

export const getScheduledBy = (schedule, users, canViewUser) => {
  if (!schedule) return;
  if (schedule.source) {
    return <div>{schedule.source}</div>;
  }
  if (schedule?.user?.id) {
    const fullName = `${schedule?.user?.firstName || ''} ${schedule?.user?.lastName || ''}`;
    if (canViewUser && users && users.find(user => Number(user.id) === Number(schedule.user.id))) {
      return <Link to={`/settings/users/id/${schedule.user.id}`}>{fullName}</Link>;
    } else {
      return <div>{fullName}</div>;
    }
  }
};

export const getScheduleStatus = status => {
  const scheduleStatus = statusMap((status || '').toLowerCase());
  return (
    <RowStatus showIcon status={scheduleStatus}>
      {i18n.t(`VehicleMntSchedules.Table.${formatStatus(status)}`)}
    </RowStatus>
  );
};

export function statusMap(status) {
  const map = {
    pending: Status.PENDING,
    due_now: Status.DUE_NOW,
    cancelled: Status.CANCELLED,
    overdue: Status.OVERDUE,
    completed: Status.COMPLETED,
    deleted: Status.DELETED,
    invalid: Status.ERROR,
    inprogress: Status.INPROGRESS,
    closed: Status.CLOSED,
    opened: Status.OPENED,
    fail: Status.FAIL,
    repaired: Status.REPAIRED,
    pass: Status.PASS
  };

  return map[status];
}

export const formatStatus = status => {
  if (!status) {
    return;
  }
  return formats[status.toLowerCase()] || toTitleCase(status.toLowerCase());
};

const formats = {
  [Status.PENDING]: 'Scheduled',
  [Status.DUE_NOW]: 'DueNow',
  [Status.INPROGRESS]: 'In Progress',
  [Status.CLOSED]: 'Closed',
  [Status.OPENED]: 'Opened',
  [Status.FAIL]: 'Failed',
  [Status.REPAIRED]: 'Pending Driver Sign Off',
  [Status.PASS]: 'Passed'
};

export const calcOdometer = (source, stats) => {
  switch (source) {
    case 'CAN':
      return parseInt(stats.canOdometer);
    case 'CAN_DIFF':
      return parseInt(stats.canDiffOdometer) + parseInt(stats.canDiffOdometerRef);
    default:
      return parseInt(stats.gpsOdometer) + parseInt(stats.gpsOdometerRef);
  }
};

export const calcEngineHours = (source, stats) => {
  switch (source) {
    case 'CAN':
      return parseInt(stats.canEngineHours);
    case 'CAN_DIFF':
      return parseInt(stats.canDiffEngineHours) + parseInt(stats.canDiffEngineHoursRef);
    default:
      return parseInt(stats.gpsEngineHours) + parseInt(stats.gpsEngineHoursRef);
  }
};

export const preparePopupRows = ({
  t,
  can,
  history,
  localization,
  users,
  schedule,
  isCancelled
}) => {
  return [
    {
      key: t('Vehicles.View.Name'),
      value: serviceNameCellRenderer({ rowData: schedule, history, can })
    },
    {
      key: t('Vehicles.View.Status'),
      value: schedule.status && t(`VehicleMntSchedules.Table.${formatStatus(schedule.status)}`)
    },
    {
      key: t('VehicleMntSchedules.View.CancelReason'),
      value: cancelReasonRenderer(schedule),
      isHidden: !isCancelled
    },
    {
      key: t('VehicleMntSchedules.View.Vehicle'),
      value: schedule.vehicle?.name && (
        <Link to={`/settings/vehicles/id/${schedule.vehicle?.id}`}>{schedule.vehicle?.name}</Link>
      )
    },
    {
      key: t('Vehicles.View.MaintenanceType'),
      value: maintenanceTypeCellRenderer({ rowData: schedule })
    },
    {
      key: t('Vehicles.View.ScheduledBy'),
      value: getScheduledBy(schedule, users)
    },
    {
      key: t('VehicleMntSchedules.Table.ServiceDue'),
      value: serviceDueCellRenderer({ rowData: schedule, localization, t })
    },
    {
      key: t('VehicleMntSchedules.Table.NextDue'),
      value: nextDueCellRenderer({ rowData: schedule, localization, t })
    }
  ];
};

export const preparePopupActions = ({
  t,
  history,
  schedule,
  isDisabled,
  handleDeleteAction,
  handleActivePopup
}) => {
  return [
    {
      title: t('Actions.View'),
      onClick: () =>
        history.push(`${Paths.VEHICLEMAINTENANCE_VIEW}/${schedule.id}`, {
          id: schedule.id
        })
    },
    {
      title: t('Actions.Edit'),
      disabled: !showButton({ buttonType: BUTTON_ACTIONS.EDIT, data: schedule }),
      onClick: () => {
        history.push(`${Paths.VEHICLEMAINTENANCE_EDIT}/${schedule.id}`, {
          schedule
        });
      }
    },
    {
      title:
        schedule?.status === STATUS.completed
          ? t('VehicleMntSchedules.View.EditCompletedInfo')
          : t('Common.EllipsisButton.Complete'),
      disabled: !showButton({ buttonType: BUTTON_ACTIONS.COMPLETE, data: schedule }),
      onClick: () => {
        history.push(`${Paths.VEHICLEMAINTENANCE_COMPLETE}/${schedule.id}`);
      }
    },
    {
      title: t('Actions.Delete'),
      disabled: !showButton({ buttonType: BUTTON_ACTIONS.DELETE, data: schedule }),
      danger: true,
      onClick: () => {
        // Close the popup
        handleActivePopup(false);

        confirmationModal(
          `${t('VehicleMntSchedules.View.DeleteSchedule')}`,
          t('VehicleMntSchedules.View.AreYouSure', {
            name: schedule?.name
          }),
          `${t('VehicleMntSchedules.View.Delete')}`,
          `${t('VehicleMntSchedules.View.Cancel')}`,
          handleDeleteAction(schedule),
          'delete'
        );
      }
    }
  ];
};

export const EDRDevicesSortFun = (deviceA, deviceB) => {
  //find latest reading as RUCOdometer device if have multiple
  const lastReadingAtA = isNaN(
    moment(deviceA?.updatedAt)
      .toDate()
      .getTime()
  )
    ? 0
    : moment(deviceA?.updatedAt)
        .toDate()
        .getTime();
  const lastReadingAtB = isNaN(
    moment(deviceB?.updatedAt)
      .toDate()
      .getTime()
  )
    ? 0
    : moment(deviceB?.updatedAt)
        .toDate()
        .getTime();
  return lastReadingAtB - lastReadingAtA;
};

export const getScheduleStatusByTab = tab => {
  const _defaultTab = Paths.VEHICLEMAINTENANCE_DEFAULT.replace('/', '');
  switch (tab) {
    case _defaultTab:
    case TABS.active:
      return [STATUS.pending, STATUS.dueNow, STATUS.overdue];
    case TABS.overdue:
      return [STATUS.overdue];
    case TABS.duenow:
      return [STATUS.dueNow];
    case TABS.scheduled:
      return [STATUS.pending];
    case TABS.completed:
      return [STATUS.completed];
    case TABS.cancelled:
      return [STATUS.cancelled];
    case TABS.invalid:
      return [STATUS.invalid];
    case TABS.deleted:
      return [STATUS.deleted];
    default:
      return [STATUS.pending, STATUS.dueNow, STATUS.overdue];
  }
};

export const getScheduleVehicleOdometer = scheduleVehicle => {
  const vehicleConfig = scheduleVehicle?.config || {};
  const vehicleMeters = scheduleVehicle?.meters || [];
  const vehicleRUCDevice = scheduleVehicle?.rucDevice;
  let odometerMeter, odometerValue;
  if (vehicleConfig?.isUsingRUCOdometerForMnt) {
    odometerMeter = getMeterBySource(vehicleRUCDevice, MeterSource.Ruc);
    odometerValue = odometerMeter ? getMeterValue(odometerMeter) : 0;
  } else {
    odometerMeter = getMeterByType({ meters: vehicleMeters || [] }, METER_TYPES.ODOMETER);
    odometerValue = odometerMeter ? getMeterValue(odometerMeter) : 0;
  }
  return { odometerValue, odometerMeter };
};

export const getActionMenuItems = ({
  schedule,
  t,
  history,
  dispatch,
  handleDeleteAction,
  handleRestoreAction,
  handleRescheduleAction
}) => [
  {
    name: t(`Common.View`),
    onClick: () => {
      history.push(`${Paths.VEHICLEMAINTENANCE_VIEW}/${schedule.id}`, { schedule });
    },
    entity: 'VIEW',
    id: 'btn_vehicleMaintenanceView'
  },
  showButton({ buttonType: BUTTON_ACTIONS.EDIT, data: schedule }) && {
    name: t(`Common.Edit`),
    onClick: () => {
      history.push(`${Paths.VEHICLEMAINTENANCE_EDIT}/${schedule.id}`, { schedule });
    },
    entity: 'UPDATE',
    id: 'btn_vehicleMaintenanceEdit'
  },
  showButton({ buttonType: BUTTON_ACTIONS.COMPLETE, data: schedule }) && {
    name:
      schedule?.status === STATUS.completed
        ? t('Common.EllipsisButton.EditCompletedInfo')
        : t('Common.EllipsisButton.Complete'),
    onClick: () => {
      history.push(`${Paths.VEHICLEMAINTENANCE_COMPLETE}/${schedule.id}`);
    },
    entity: 'CREATE',
    id: 'btn_vehicleMaintenanceComplate'
  },
  showButton({ buttonType: BUTTON_ACTIONS.RESCHEDULE, data: schedule }) && {
    name: t('VehicleMntSchedules.View.Reschedule'),
    onClick: handleRescheduleAction(schedule),
    entity: 'UPDATE',
    id: 'btn_vehicleMaintenanceReschedule'
  },
  showButton({ buttonType: BUTTON_ACTIONS.RESTORE, data: schedule }) &&
    handleRestoreAction && {
      name: t('Common.RestoreButton'),
      onClick: handleRestoreAction(schedule),
      entity: 'RESTORE',
      id: 'btn_vehicleMaintenanceRestore'
    },
  showButton({ buttonType: BUTTON_ACTIONS.DELETE, data: schedule }) && {
    name: t(`Common.Delete`),
    danger: true,
    onClick: deleteModal({
      t,
      data: schedule,
      typeOfEntityToDelete: t('Common.vehicleMntSchedule'),
      handleDelete,
      modalType: 'delete',
      dataTypeMessages: 'VehicleMaintenance',
      dispatch,
      customDeleteCall: handleDeleteAction
    }),
    entity: 'DESTROY',
    id: 'btn_vehicleMaintenanceDelete'
  }
];

export const showButton = ({ buttonType, data }) => {
  const { status } = data;
  switch (buttonType) {
    case BUTTON_ACTIONS.EDIT:
      return ![STATUS.cancelled, STATUS.completed, STATUS.deleted].includes(status);
    case BUTTON_ACTIONS.CANCEL:
      return ![STATUS.completed, STATUS.deleted].includes(status);
    case BUTTON_ACTIONS.COMPLETE:
      return ![STATUS.cancelled, STATUS.deleted].includes(status);
    case BUTTON_ACTIONS.RESCHEDULE:
      return [STATUS.completed].includes(status);
    case BUTTON_ACTIONS.RESTORE:
      return [STATUS.deleted].includes(status);
    case BUTTON_ACTIONS.DELETE:
      return ![STATUS.deleted].includes(status);
    case BUTTON_ACTIONS.AUDIT:
    default:
      return true;
  }
};

export const renderFailedItems = ({
  inspectionData,
  isPictureModalOpen,
  showPictureModal,
  cancelPictureModal,
  styles,
  t
}) => {
  const { Text, Title, Link } = Typography;
  return (
    <AntRow className={styles.failedItems}>
      <div className={styles.dataRow}>
        <div className={styles.content}>
          <Title level={4} className={styles.title}>
            {t('VehicleMntSchedules.FailedItems')}
          </Title>
        </div>
        <Divider className={styles.divider} />
      </div>
      {inspectionData.questions &&
        JSON.parse(inspectionData.questions).map((questionDetails, id) => (
          <>
            {questionDetails?.Result === false &&
              !['con-new', 'sig-new', 'con-repaired', 'sig-repaired'].includes(
                questionDetails?.DVIR?.toLowerCase()
              ) && (
                <div key={`${questionDetails?.Text}-title-${id}`} className={styles.dataRow}>
                  <div className={styles.content}>
                    <Text type="secondary" className={styles.title}>
                      {questionDetails?.Text}
                    </Text>
                    <div className={styles['preview-container']}>
                      {[true, false].includes(questionDetails?.Result) ? (
                        <Text type={`${questionDetails.Result ? 'success' : 'danger'}`}>
                          {questionDetails?.Answer}
                          {questionDetails?.Result === false &&
                            questionDetails?.SubFailedQuestions?.[0]?.Answer &&
                            ` - ${questionDetails?.SubFailedQuestions?.[0].Answer}`}
                        </Text>
                      ) : (
                        <Text>{questionDetails?.Answer}</Text>
                      )}
                      {questionDetails?.SubFailedQuestions?.[0].Attachments && (
                        <>
                          <Link
                            className={styles.preview}
                            onClick={() => showPictureModal(`${questionDetails?.Text}-${id}`)}
                          >
                            <Icon className={styles['preview--icon']} srcIcon={ImagePreview} />
                            {questionDetails?.SubFailedQuestions?.[0].Attachments?.[0].Attachment?.Name?.split(
                              '.'
                            )[0].substring(0, 30) ?? ''}
                          </Link>
                          <Modal
                            title={
                              questionDetails?.SubFailedQuestions?.[0].Attachments?.[0].Attachment?.Name?.split(
                                '.'
                              )[0].substring(0, 30) ?? ''
                            }
                            open={isPictureModalOpen === `${questionDetails?.Text}-${id}`}
                            onCancel={cancelPictureModal}
                            footer={null}
                            maskClosable={false}
                            key={`${questionDetails?.Text}-modal-${id}`}
                          >
                            <img
                              alt={t('Inspections.View.MissingImage')}
                              width="400px"
                              loading="lazy"
                              src={`data:${questionDetails?.SubFailedQuestions?.[0].Attachments?.[0].Attachment?.MimeType};base64,${questionDetails?.SubFailedQuestions?.[0].Attachments?.[0].Attachment?.Content}`}
                            />
                          </Modal>
                        </>
                      )}
                    </div>
                  </div>
                  <Divider className={styles.divider} />
                </div>
              )}
          </>
        ))}
    </AntRow>
  );
};
