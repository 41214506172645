import React from 'react';
import styles from '../Overview.module.scss';
import { WorkStatus } from 'containers/ELD/Constants';
import i18next from 'i18nextConfig';

export function getClassOfDriveTimeStyle(state) {
  switch (state) {
    case 1:
      return styles.yellow;
    case 2:
      return styles.red;
    default:
      return '';
  }
}

export function driveTimeCell_Default(rowData) {
  const isExempt = rowData.isExemptDriver || false;
  const maxDriveTime = Math.max(1, (rowData?.driveTimeLeft || 0) + (rowData?.driveTime || 0));
  const driveTimeAvailable = isExempt ? 0 : Math.max(rowData?.driveTimeAvailable || 0, 0);

  let remainedPercent = Math.min(
    100,
    Math.max(0, (driveTimeAvailable / maxDriveTime).toFixed(2) * 100)
  );
  let barStyle = '';

  switch (rowData.driveTimeStatus) {
    case WorkStatus.AtRisk:
      if (driveTimeAvailable <= 0) {
        barStyle = styles.outline_yellow;
      } else {
        barStyle = styles.yellow;
      }
      break;
    case WorkStatus.InVio:
      barStyle = styles.red;
      if (remainedPercent === 0) {
        remainedPercent = 100;
      }
      break;
    default:
      if (rowData.lastUpdate !== 'N/A' && driveTimeAvailable <= 0) {
        barStyle = styles.outline_yellow;
      } else {
        barStyle = '';
      }
      break;
  }
  if (isExempt) {
    barStyle = '';
  }
  return (
    <div className={styles.barChart}>
      <div className={styles.bar + ' ' + barStyle}>
        <span
          className={remainedPercent === 100 ? styles.fullBar : styles.progressBar}
          style={{ width: remainedPercent + '%' }}
        ></span>
      </div>

      {!isExempt && (
        <p>
          {Math.floor(driveTimeAvailable / 3600)} {i18next.t('Common.Hours')}{' '}
          {Math.floor((driveTimeAvailable % 3600) / 60)} {i18next.t('Common.Mins')}
        </p>
      )}
      {isExempt && <p>{i18next.t('Common.Exempt')}</p>}
    </div>
  );
}
