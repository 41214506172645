import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { GeofenceView } from './GeofenceView';
import { Geofences } from './Geofences';
import { GeofenceForm } from './GeofenceForm';
import { GeofenceAudits } from './GeofenceAudits';
import { Can, entities, services } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { useAllRegions } from 'features/regions/regionsSlice';
import { helpers } from './helpers';
import { Paths } from './constants';
import { useLocation } from 'react-router';

export const GeofencesRouter = () => {
  const regions = useAllRegions();
  const location = useLocation();
  const MapCoordinates = helpers.getGPSFromRegions(regions);

  return (
    <Can
      everyEntity={[entities.GEOFENCE]}
      everyService={[services.GEOFENCE]}
      onFail={<NoAccessBody />}
    >
      <Switch>
        <Route path={Paths.VIEW_GEOFENCE}>
          <Can everyEntity={[entities.GEOFENCE_VIEW]} onFail={<NoAccessBody />}>
            <GeofenceView />
          </Can>
        </Route>
        <Route exact path={Paths.NEW_GEOFENCE}>
          <Can everyEntity={[entities.GEOFENCE_CREATE]} onFail={<NoAccessBody />}>
            <GeofenceForm action="add" coordinates={MapCoordinates} />
          </Can>
        </Route>
        <Route path={Paths.EDIT_GEOFENCE}>
          <Can everyEntity={[entities.GEOFENCE_UPDATE]} onFail={<NoAccessBody />}>
            <GeofenceForm action="edit" coordinates={MapCoordinates} />
          </Can>
        </Route>
        <Route path={Paths.AUDIT_GEOFENCE}>
          <Can everyEntity={[entities.GEOFENCE_UPDATE]} onFail={<NoAccessBody />}>
            <GeofenceAudits />
          </Can>
        </Route>
        <Route path={Paths.Default}>
          <Geofences fleetId={location.state?.fleetId} />
        </Route>
      </Switch>
    </Can>
  );
};
