import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import hash from 'object-hash';

import { DeleteFilled } from '@ant-design/icons';
import { TRIP_STATE } from '../utils/constants';
import { getResponseType } from '../utils/helpers';

import { Input } from 'components/ant';
import { Form, Modal, Button, Col, Row, Divider, Radio, Space } from 'antd';
import { ToastType } from 'components/notifications/toasts/Toast';

import {
  addTripStates,
  deleteTripStates,
  useIsAddingTripState,
  useIsDeletingTripState,
  setTripStateDefault,
  fetchTripStates
} from 'features/fbt';
import { openToast } from 'features/toasts/toastsSlice';

import styles from './AddTypeModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const AddTypeModal = ({ onCancel, tripStates = [], editable, originalDefault }) => {
  const { t } = useTranslation();
  const [typeForm] = Form.useForm();
  const dispatch = useDispatch();
  const isAdding = useIsAddingTripState();
  const isDeleting = useIsDeletingTripState();
  const [defaultTripState, setDefaultTripState] = useState(
    tripStates.findIndex(ts => ts === originalDefault)
  );
  const originalTripStates = tripStates;
  const [currentTripStates, setCurrentTripStates] = useState(tripStates);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    if (
      hash.sha1(originalTripStates) === hash.sha1(currentTripStates) &&
      originalDefault === currentTripStates[defaultTripState]
    ) {
      setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }
  }, [currentTripStates, defaultTripState]);

  //Filter out the private state - that cannot be deleted
  const initialValues = {
    useTypes: tripStates.map(tripState => ({ type: tripState }))
  };

  function handleDefaultTripStateChange(event) {
    setDefaultTripState(event.target.value);
  }

  const handleDeleteTripState = (remove, field) => () => {
    remove(field.name);
    setCurrentTripStates(typeForm.getFieldValue().useTypes.map(entry => entry?.type));
  };

  const handleInputChange = () =>
    setCurrentTripStates(typeForm.getFieldsValue().useTypes.map(entry => entry?.type));

  const handleSubmit = async () => {
    const newTypes = currentTripStates.filter(type => !tripStates.includes(type));
    const valuesToDelete = tripStates.filter(
      ts => ts !== TRIP_STATE.PRIVATE && !currentTripStates.includes(ts)
    );
    let response = [];

    try {
      if (hash.sha1(originalTripStates) !== hash.sha1(currentTripStates)) {
        // We should have at least 2 types at all times
        if (currentTripStates?.length <= 2) {
          // Add New Types
          if (newTypes?.length) {
            response = await dispatch(addTripStates({ tripStates: newTypes }));
          }

          if (valuesToDelete?.length) {
            response = await dispatch(deleteTripStates({ tripStates: valuesToDelete }));
          }
        } else {
          // Delete Types
          if (valuesToDelete?.length) {
            response = await dispatch(deleteTripStates({ tripStates: valuesToDelete }));
          }

          // Add New Types
          if (newTypes?.length) {
            response = await dispatch(addTripStates({ tripStates: newTypes }));
          }
        }

        if (!response) {
          onCancel();
          return;
        }

        const parsedResponse = getResponseType(response);

        if (parsedResponse.isValid) {
          dispatch(
            openToast({
              type: ToastType.Success,
              message: `${t('FBTManager.Toast.AddTypeSuccess')}`
            })
          );

          onCancel();
        } else {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: `${t('FBTManager.Toast.AddTypeError')}: ${parsedResponse.message}`
            })
          );
        }
      }

      if (
        currentTripStates[defaultTripState] &&
        originalDefault !== currentTripStates[defaultTripState]
      ) {
        await dispatch(
          setTripStateDefault({
            query: { defaultTripState: currentTripStates[defaultTripState] },
            successToaster: () =>
              openToast({
                type: ToastType.Success,
                message: t('Alerts.Toast.ChangeDefaultTypeOfUse', {
                  name: currentTripStates[defaultTripState]
                })
              }),
            errorToaster: () =>
              openToast({
                type: ToastType.Error,
                message: t('Alerts.Toast.FailedToChangeDefaultTypeOfUse')
              })
          })
        );
      }
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('FBTManager.Toast.AddTypeError')
        })
      );
    }
    dispatch(fetchTripStates());
    onCancel();
  };

  function getRadioButton(field) {
    return <Radio value={field.name}></Radio>;
  }

  return (
    <Modal
      className={styles.addTypeModal}
      title={t('FBTManager.AddTypesOfUse')}
      open
      width={700}
      destroyOnClose={true}
      onCancel={onCancel}
      footer={null}
    >
      <div className={styles.modalBody}>
        <div className={styles.header}>
          <div className={styles.headerLabel}>{t('Default')}</div>
          <div>{t('FBTManager.purpose')}</div>
        </div>
        <div className={styles.form}>
          <Form
            name="type_of_use"
            layout="vertical"
            form={typeForm}
            initialValues={initialValues}
            onFinish={handleSubmit}
          >
            <Form.List name="useTypes">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <Radio.Group onChange={handleDefaultTripStateChange} value={defaultTripState}>
                      <Space direction="vertical" className={styles.space}>
                        {fields.map(field => {
                          const isUneditableOption = field.key === 0;
                          const deleteForbidden =
                            fields.length <= 2 || !editable || isUneditableOption;
                          return (
                            <Row key={field.fieldKey}>
                              {getRadioButton(field)}
                              <Col span={18}>
                                <Form.Item
                                  name={[field.name, 'type']}
                                  fieldKey={[field.fieldKey, 'type']}
                                  style={{ margin: 0, padding: 0 }}
                                  rules={[
                                    {
                                      required: true,
                                      pattern: /^(?!\s*$).+/,
                                      message: t(
                                        'FBTManager.Modal.FormValidation.AValueMustBeEntered'
                                      )
                                    },
                                    () => ({
                                      validator() {
                                        //Get all fields except the current one
                                        const existingFields = typeForm
                                          .getFieldsValue()
                                          .useTypes.reduce(
                                            (accumulator, currentValue, currentIndex) => {
                                              if (currentIndex !== field.name) {
                                                accumulator.push(currentValue?.type);
                                              }
                                              return accumulator.filter(Boolean);
                                            },
                                            []
                                          );

                                        //Get current field
                                        const currentField = typeForm.getFieldsValue().useTypes[
                                          field.name
                                        ]?.type;

                                        //Look for duplicates
                                        if (
                                          existingFields.every(
                                            field =>
                                              field?.toLowerCase() !== currentField?.toLowerCase()
                                          )
                                        ) {
                                          return Promise.resolve();
                                        }

                                        return Promise.reject(
                                          new Error(
                                            t(
                                              'FBTManager.Modal.FormValidation.DuplicatesAreNotAllowed'
                                            )
                                          )
                                        );
                                      }
                                    })
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder={t('FBTManager.Input.PlaceholderType')}
                                    disabled={!editable || isUneditableOption}
                                    onChange={handleInputChange}
                                  />
                                </Form.Item>
                              </Col>
                              {!deleteForbidden && (
                                <Col span={2} className={styles.delete}>
                                  <DeleteFilled
                                    className={styles.deleteIcon}
                                    onClick={handleDeleteTripState(remove, field)}
                                  />
                                </Col>
                              )}
                              <Divider className={styles.divider} />
                            </Row>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                    <Form.Item>
                      <Button
                        type="secondary"
                        className={styles.addBtn}
                        onClick={() => {
                          add();
                          setIsSaveDisabled(false);
                        }}
                        block
                        disabled={!editable}
                        id={BUTTON_IDS.addTypeAdd}
                      >
                        {t(`SmartJobsCodes.addAnother`)}
                      </Button>
                    </Form.Item>
                    <Divider className={styles.divider} />
                    <Space>
                      <Form.Item>
                        <Button
                          disabled={!editable || isSaveDisabled}
                          type="primary"
                          htmlType="submit"
                          loading={isAdding || isDeleting}
                          id={BUTTON_IDS.addTypeSave}
                        >
                          {t('Common.Save')}
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button key="back" onClick={onCancel} id={BUTTON_IDS.addTypeCancel}>
                          {t('Common.Cancel')}
                        </Button>
                      </Form.Item>
                    </Space>
                  </>
                );
              }}
            </Form.List>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
