import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import moment from 'moment';

export const initialState = {
  events: [],
  currentEvents: [],
  refetchEvents: false,
  checkpoint: {},
  GPSDataLoaded: false,
  fromDate: moment()
    .startOf('day')
    .valueOf(),
  toDate: moment()
    .endOf('day')
    .valueOf()
};

const filterEvents = (fromDate, toDate, events) => {
  let filteredEvents = null;

  filteredEvents = events?.filter(e => e.eventAt >= fromDate && e.eventAt < toDate) || [];
  return filteredEvents;
};

const events = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvents(state, action) {
      state.events = action.payload;
      state.currentEvents = filterEvents(state.fromDate, state.toDate, action.payload);
    },
    setEventId(state, action) {
      state.eventId = action.payload;
    },
    setViolations(state, action) {
      state.violations = action.payload;
    },
    setViolationId(state, action) {
      state.violationId = action.payload;
    },
    setPeriod(state, action) {
      state.currentEvents = filterEvents(
        action.payload.fromDate,
        action.payload.toDate,
        state.events
      );
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
    refetchSentinelEvents(state, action) {
      state.refetchEvents = action.payload;
    },
    setCheckpoint(state, action) {
      state.checkpoint = action.payload;
    },
    setGPSDataLoaded(state, action) {
      state.GPSDataLoaded = action.payload;
    }
  }
});

export const {
  setEvents,
  setEventId,
  setViolations,
  setViolationId,
  setPeriod,
  refetchSentinelEvents,
  setCheckpoint,
  setGPSDataLoaded
} = events.actions;

export default events.reducer;

export const useSentinelAudits = eventID => {
  const events = useSelector(state => state.sentinel.events);
  return events[eventID];
};
