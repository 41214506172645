import React from 'react';

import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

import styles from './LineChart.module.scss';

export const handleToggle = (key, lines, setLines) => () => {
  const newData = lines.map(line =>
    line.key === key ? { ...line, checked: !line.checked } : line
  );

  setLines(newData);
};

export const renderLegend = (lines, setLines) => () => {
  return (
    <div className={styles.checkboxContainer}>
      {lines.map((entry, index) => (
        <span key={entry.key} className={styles.checkbox}>
          <Checkbox
            checked={entry?.checked}
            className={styles.legendCheckBox}
            style={{ color: entry.color }}
            onChange={handleToggle(entry.key, lines, setLines)}
          >
            {entry.name}
          </Checkbox>
        </span>
      ))}
    </div>
  );
};

export const getDomainMargins = data => {
  let maxDomain = -Infinity;
  let minDomain = Infinity;

  data &&
    data.chart.forEach(element => {
      Object.values(element).forEach(val => {
        if (!isNaN(Number(val))) {
          if (maxDomain < val) {
            maxDomain = val;
          }
          if (minDomain > val) {
            minDomain = val;
          }
        }
      });
    });

  return {
    maxDomain,
    minDomain
  };
};

renderLegend.propTypes = {
  key: PropTypes.string,
  lines: PropTypes.array,
  setLines: PropTypes.func
};

handleToggle.propTypes = {
  key: PropTypes.string,
  lines: PropTypes.array,
  setLines: PropTypes.func
};
