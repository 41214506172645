import React from 'react';

import { Button, InputNumber, Row, Col } from 'antd';
import { Input, Select, DatePicker, TimePicker } from 'components/ant';

import { CloseCircleFilled } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';

import moment from 'moment';

import {
  ColumnTypes,
  DateConditions,
  EmptyConditions,
  getIgnitionValues,
  getConditionsForType,
  getDateValue,
  getDefaultConditionAndValueForType
} from '../../../components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';

import styles from '../../../components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/FilterColumnsModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const FilterColumnsRow = ({
  allColumns,
  operationTypeData,
  operationStatusData,
  operationSubTypeData,
  operationSubTypeStatusData,
  availableColumns,
  filters,
  setFilters,
  filter
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();

  const handleColumnSelect = (value, id) => {
    let newFilters = [...filters];
    let foundFilter = newFilters.find(item => item.id === id);

    if (foundFilter) {
      // If type changed, then change condition and value to defaults for new type
      const newFoundColumn = allColumns.find(item => item.key === value);
      if (newFoundColumn) {
        if (foundFilter.type !== newFoundColumn.type) {
          const { condition, value } = getDefaultConditionAndValueForType(newFoundColumn.type, t);
          foundFilter.type = newFoundColumn.type;
          foundFilter.condition = condition;
          foundFilter.value = value;
        }
      }

      foundFilter.columnKey = value;
    }
    setFilters(newFilters);
  };

  const handleConditionSelect = (value, filter) => {
    let newFilters = [...filters];
    let foundFilter = newFilters.find(item => item.id === filter.id);
    if (foundFilter) {
      const prevCondition = foundFilter.condition;
      foundFilter.condition = value;

      if (filter.type === ColumnTypes.Date) {
        // If just changed from regular date condition to within, add "to" date portion
        if (prevCondition !== DateConditions.IsWithin && value === DateConditions.IsWithin) {
          filter.value = filter.value + '|' + moment().format();
        }

        // If just changed from within to regular date condition, remove the "to" portion
        if (prevCondition === DateConditions.IsWithin && value !== DateConditions.IsWithin) {
          filter.value = filter.value.split('|')[0];
        }
      }

      if (EmptyConditions.includes(foundFilter.condition)) {
        foundFilter.value = null;
      }
    }

    setFilters(newFilters);
  };

  const handleDateChange = (value, filter) => {
    let newFilters = [...filters];
    let foundFilter = newFilters.find(item => item.id === filter.id);
    if (foundFilter) {
      if (value) {
        const filterValue = filter.value ? filter.value : moment().format();
        if (filter.condition === DateConditions.IsWithin) {
          const dateParts = filterValue.split('|');
          if (dateParts.length > 1) {
            // use moment to set the values
            let momentValue = moment(dateParts[0]);
            momentValue.date(value.date());
            momentValue.month(value.month());
            momentValue.year(value.year());

            // convert back to and store as a string
            foundFilter.value = momentValue.format() + '|' + dateParts[1];
          }
        } else {
          // use moment to set the values
          let momentValue = moment(filterValue);
          momentValue.date(value.date());
          momentValue.month(value.month());
          momentValue.year(value.year());

          // convert back to and store as a string
          foundFilter.value = momentValue.format();
        }
      } else {
        foundFilter.value = null;
      }
    }
    setFilters(newFilters);
  };

  const handleTimeChange = (value, filter) => {
    let newFilters = [...filters];
    let foundFilter = newFilters.find(item => item.id === filter.id);
    if (foundFilter) {
      if (value) {
        const filterValue = filter.value ? filter.value : moment().format();
        if (filter.condition === DateConditions.IsWithin) {
          const dateParts = filterValue.split('|');
          if (dateParts.length > 1) {
            // use moment to set the values
            let momentValue = moment(dateParts[0]);
            momentValue.hours(value.hours());
            momentValue.minutes(value.minutes());
            momentValue.seconds(value.seconds());

            // convert back to and store as a string
            foundFilter.value = momentValue.format() + '|' + dateParts[1];
          }
        } else {
          // use moment to set the values
          let momentValue = moment(filterValue);
          momentValue.hours(value.hours());
          momentValue.minutes(value.minutes());
          momentValue.seconds(value.seconds());

          // convert back to and store as a string
          foundFilter.value = momentValue.format();
        }
      } else {
        foundFilter.value = null;
      }
    }
    setFilters(newFilters);
  };

  const handleDateToChange = (value, filter) => {
    let newFilters = [...filters];
    let foundFilter = newFilters.find(item => item.id === filter.id);
    if (foundFilter) {
      if (value) {
        const filterValue = filter.value ? filter.value : moment().format();
        // Assume 'within' and set "to" value
        const dateParts = filterValue.split('|');
        if (dateParts.length > 1) {
          // use moment to set the values
          let momentValue = moment(dateParts[1]);
          momentValue.date(value.date());
          momentValue.month(value.month());
          momentValue.year(value.year());

          // convert back to and store as a string
          foundFilter.value = dateParts[0] + '|' + momentValue.format();
        }
      } else {
        foundFilter.value = null;
      }
    }
    setFilters(newFilters);
  };

  const handleTimeToChange = (value, filter) => {
    let newFilters = [...filters];
    let foundFilter = newFilters.find(item => item.id === filter.id);
    if (foundFilter) {
      if (value) {
        const filterValue = filter.value ? filter.value : moment().format();
        // Assume 'within' and set "to" value
        const dateParts = filterValue.split('|');
        if (dateParts.length > 1) {
          // use moment to set the values
          let momentValue = moment(dateParts[1]);
          momentValue.hours(value.hours());
          momentValue.minutes(value.minutes());
          momentValue.seconds(value.seconds());

          // convert back to and store as a string
          foundFilter.value = dateParts[0] + '|' + momentValue.format();
        }
      } else {
        foundFilter.value = null;
      }
    }
    setFilters(newFilters);
  };

  const handleValueChange = (value, filter) => {
    let newFilters = [...filters];
    let foundFilter = newFilters.find(item => item.id === filter.id);
    if (foundFilter) {
      foundFilter.value = value;
    }
    setFilters(newFilters);
  };

  const handleDeleteFilter = id => {
    const newFilters = filters.filter(item => item.id !== id);
    setFilters(newFilters);
  };

  const conditions = getConditionsForType(filter.type, t);

  const valueDisabled = EmptyConditions.includes(filter.condition);

  const { dateValue, dateValueTo } = getDateValue(filter);

  return (
    <div key={filter.id}>
      <Row gutter={8} className={styles.filterContainer}>
        <Col xs={{ span: 5 }}>
          <Select
            className={styles.columnSelect}
            data={availableColumns}
            value={filter.columnKey}
            dropdownMatchSelectWidth={false}
            dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
            onSelect={value => handleColumnSelect(value, filter.id)}
          />
        </Col>
        <Col xs={{ span: 5 }}>
          <Select
            className={styles.conditionSelect}
            data={conditions}
            value={filter.condition}
            dropdownMatchSelectWidth={false}
            dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
            onSelect={value => handleConditionSelect(value, filter)}
          />
        </Col>
        {filter.type === ColumnTypes.Date && (
          <>
            <Col xs={{ span: 6 }}>
              <DatePicker
                disabled={valueDisabled}
                controlClass={styles.datePicker}
                format={localization.formats.time.formats.dby}
                value={dateValue}
                onChange={value => handleDateChange(value, filter)}
                placeholder={t('Common.SelectDate')}
              />
            </Col>
            <Col xs={{ span: 6 }}>
              <TimePicker
                use12Hours
                disabled={valueDisabled}
                format="HH:mm:ss A"
                value={dateValue}
                onChange={value => handleTimeChange(value, filter)}
                style={{ marginLeft: '5px' }}
                placeholder={t('Common.SelectTime')}
              />
            </Col>
          </>
        )}
        {filter.type === ColumnTypes.OperationType && (
          <Col xs={{ span: 12 }}>
            <Select
              className={styles.valueSelect}
              dropdownStyle={{ Position: 'absolute' }}
              data={operationTypeData}
              value={filter.value}
              dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
              onSelect={value => handleValueChange(value, filter)}
            />
          </Col>
        )}
        {filter.type === ColumnTypes.OperationSubType && (
          <Col xs={{ span: 12 }}>
            <Select
              className={styles.valueSelect}
              dropdownStyle={{ Position: 'absolute' }}
              data={operationSubTypeData}
              value={filter.value}
              dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
              onSelect={value => handleValueChange(value, filter)}
            />
          </Col>
        )}
        {filter.type === ColumnTypes.OperationStatus && (
          <Col xs={{ span: 12 }}>
            <Select
              className={styles.valueSelect}
              dropdownStyle={{ Position: 'absolute' }}
              data={operationStatusData}
              value={filter.value}
              dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
              onSelect={value => handleValueChange(value, filter)}
            />
          </Col>
        )}
        {filter.type === ColumnTypes.OperationSubTypeStatus && (
          <Col xs={{ span: 12 }}>
            <Select
              className={styles.valueSelect}
              dropdownStyle={{ Position: 'absolute' }}
              data={operationSubTypeStatusData}
              value={filter.value}
              dropdownAlign={{ overflow: { adjustX: false, adjustY: false } }}
              onSelect={value => handleValueChange(value, filter)}
            />
          </Col>
        )}
        {filter.type === ColumnTypes.Number && (
          <Col xs={{ span: 12 }}>
            <InputNumber
              className={styles.numberInput}
              disabled={valueDisabled}
              placeholder={t('Tracking.FilterColumns.TypeCondition')}
              onChange={value => handleValueChange(value, filter)}
              value={filter.value}
            />
          </Col>
        )}
        {filter.type === ColumnTypes.String && (
          <Col xs={{ span: 12 }}>
            <Input
              className={styles.stringInput}
              disabled={valueDisabled}
              placeholder={t('Tracking.FilterColumns.TypeCondition')}
              onChange={e => handleValueChange(e.target.value, filter)}
              value={filter.value}
            />
          </Col>
        )}
        <Col xs={{ span: 2 }}>
          <Button
            className={styles.deleteFilterButton}
            size="medium"
            type="text"
            id={BUTTON_IDS.deleteFilterColumnsRow}
            icon={<CloseCircleFilled className={styles.deleteIcon} />}
            onClick={() => handleDeleteFilter(filter.id)}
          />
        </Col>
      </Row>
      {filter.type === ColumnTypes.Date && filter.condition === DateConditions.IsWithin && (
        <Row gutter={8} className={styles.filterContainer}>
          <Col xs={{ span: 5 }} offset={5} className={styles.endingLabelPanel}>
            <span className={styles.endingLabel}>{t('Tracking.FilterColumns.Ending')}</span>
          </Col>
          <Col xs={{ span: 6 }}>
            <DatePicker
              disabled={valueDisabled}
              controlClass={styles.datePicker}
              format={localization.formats.time.formats.dby}
              value={dateValueTo}
              onChange={value => handleDateToChange(value, filter)}
              placeholder={t('Common.SelectDate')}
            />
          </Col>
          <Col xs={{ span: 6 }}>
            <TimePicker
              use12Hours
              disabled={valueDisabled}
              format="HH:mm:ss A"
              value={dateValueTo}
              onChange={value => handleTimeToChange(value, filter)}
              style={{ marginLeft: '5px' }}
              placeholder={t('Common.SelectTime')}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
