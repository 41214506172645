import React, { useCallback, useState, useRef, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CompanySelect from 'features/company/CompanySelect';
import { Popover, OverlayTrigger, ListGroup, PopoverContent, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AWS_CHAT_ENABLED } from 'config';

import { useRichNotifications } from 'features/notifications/notificationsSlice';
import {
  AlertListCommonRender,
  RichNotificationsRender
} from 'features/notifications/notificationsHelper';
import { useUser } from 'features/user/userSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useEntityPermissons } from 'features/permissions';
import { FeatureFlag, useCan, services, entities } from 'features/permissions';
import { Button } from 'antd';
import { useWalkMeReady, useWalkme } from 'features/walkme/walkmeHooks';
import { useBackButtonLinks, useBreadcrumbs } from 'features/page/pageSlice';
import { useRegions } from 'features/regions/regionsSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { useActiveBrand } from 'features/brands/useActiveBrand';
import { Mixpanel, MPTrackingEvents, MPTrackingFunctions } from 'features/mixpanel';

import * as TN from './TopNav.style';
import { Breadcrumb } from 'components/ant';
import { NotificationButton } from './NotificationPopup';
import { ChangePasswordModal } from 'containers/Settings/user/ChangePassword';
import { useIQCameraUser } from 'features/permissions';

import styles from './TopNav.module.scss';

export const useBackLinks = () => {
  const history = useHistory();
  const backButtonLinks = useBackButtonLinks();
  return () => {
    let backLink = undefined;
    if (backButtonLinks) {
      if (backButtonLinks[history.location.pathname]) {
        backLink = backButtonLinks[history.location.pathname];
      }
    }
    if (backLink) {
      history.push(backLink);
    } else {
      history.length > 2 ? history.goBack() : history.push('/');
    }
  };
};

const BackButton = ({ showBackButton }) => {
  const handleBack = useBackLinks();
  if (showBackButton) {
    return (
      <TN.BackButton onClick={() => handleBack()} data-testid="back-button">
        <i className="tn-i-chevron-left" />
      </TN.BackButton>
    );
  }
  return (
    <div style={{ display: 'none' }}>
      <TN.BackButton onClick={() => handleBack()}>
        <i className="tn-i-chevron-left" />
      </TN.BackButton>
    </div>
  );
};

export const TopNav = ({ title, showBackButton }) => {
  const history = useHistory();
  const user = useUser();
  const breadcrumbs = useBreadcrumbs();
  const notifications = useSelector(state => state.notifications.notifications);
  const richNotifications = useRichNotifications();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const profileRef = useRef(null);
  const helpBtnRef = useRef(null);
  const regions = useRegions();
  const company = useCurrentCompany();
  const brand = useActiveBrand();
  const entityPermissions = useEntityPermissons();
  const can = useCan();
  const isWalkMePlayerAPIAvailable = useWalkMeReady();

  const isDriverPortal = can({
    everyCompanyService: [services.ELD],
    featureFlag: [FeatureFlag.driverPortal.flag],
    everyEntity: [entities.DRIVERPORTAL],
    otherConditions: [() => user?.type?.code === 'DRIVER']
  });

  const localization = useLocalization();
  const { t } = useTranslation();
  const { canAccessNonCameraFeatures } = useIQCameraUser();

  useWalkme();

  const onChangePwd = useCallback(() => {
    if (profileRef.current != null) {
      profileRef.current.click();
    }

    setShowChangePasswordModal(true);
  }, [profileRef]);

  const showHideChat = () => {
    const chatContainer = document.querySelector('#awsChatContainer');
    if (chatContainer) {
      chatContainer.style.display = chatContainer.style.display === 'none' ? 'block' : 'none';
    }
  };

  const handlePwdModalHide = useCallback(() => {
    setShowChangePasswordModal(false);
  }, []);

  const helpCenterURL = useMemo(() => {
    let link = canAccessNonCameraFeatures
      ? localization.formats.support_doc_link
      : localization.formats.iq_camera_support_doc_link;

    if (brand?.helpCenterUrl) {
      return brand?.helpCenterUrl;
    }

    if (regions == null || company == null) {
      return link;
    }
    const region = regions.find(r => r.code === company.country);
    if (region == null) {
      return link;
    }

    try {
      const configJson = JSON.parse(region.config);
      if (canAccessNonCameraFeatures && configJson.help_center) {
        link = configJson.help_center;
      } else if (!canAccessNonCameraFeatures && configJson.iq_camera_help_center) {
        link = configJson.iq_camera_help_center;
      } else {
        console.warn('Region config has no value for help center, use default one.');
      }
    } catch {
      console.error('Region has no config or config json has format problem.');
    }
    return link;
  }, [company, regions, localization]);

  const supportCenterURL = useMemo(() => {
    let link;
    if (regions == null || company == null) {
      return link;
    }
    const region = regions.find(r => r.code === company.country);
    if (region == null) {
      return link;
    }
    try {
      const configJson = JSON.parse(region.config);
      if (configJson.support_center) {
        const hasSupportCenterPermission = entityPermissions.find(
          entity => entity === 'SUPPORTCENTER'
        );
        if (hasSupportCenterPermission) {
          link = configJson.support_center;
        } else {
          console.warn('User does not have permission for support center.');
        }
      } else {
        console.warn('Region config has no value for support center, use default one.');
      }
    } catch {
      console.error('Region has no config or config json has format problem.');
    }
    return link;
  }, [company, regions, localization]);

  const HelpMenu = (
    <Popover id="help-menu-popover">
      <PopoverContent className="p-0">
        <ListGroup variant="flush">
          <ListGroup.Item
            action
            target="_blank"
            href={helpCenterURL}
            onClick={() => {
              Mixpanel.sendTrackEvent(MPTrackingEvents.Help.AskforHelp, {
                helpType: MPTrackingEvents.Help.HelpType.HelpCenter
              });
              helpBtnRef.current.click();
            }}
          >
            {t('HelpCenter')}
          </ListGroup.Item>
          {supportCenterURL != null && (
            <ListGroup.Item
              action
              target="_blank"
              href={supportCenterURL}
              onClick={() => {
                Mixpanel.sendTrackEvent(MPTrackingEvents.Help.AskforHelp, {
                  helpType: MPTrackingEvents.Help.HelpType.SupportCenter
                });
                helpBtnRef.current.click();
              }}
            >
              {t('SupportCenter')}
            </ListGroup.Item>
          )}
        </ListGroup>
      </PopoverContent>
    </Popover>
  );

  const UserMenu = (
    <Popover id="popover-user-menu" style={{ minWidth: '300px' }}>
      <Card border="light">
        <Card.Body>
          <Card.Title>
            {user.firstName} {user.lastName}
          </Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{user.username}</Card.Subtitle>
        </Card.Body>
        <ListGroup variant="flush">
          {canAccessNonCameraFeatures && (
            <>
              {!isDriverPortal && (
                <ListGroup.Item
                  action
                  id="btn_preferences"
                  onClick={() => history.push('/settings/user/preferences')}
                >
                  {t('Preferences.Title')}
                </ListGroup.Item>
              )}
              <ListGroup.Item action id="btn_changePassword" onClick={onChangePwd}>
                {t('ChangePassword.ChangePwd')}
              </ListGroup.Item>
              {AWS_CHAT_ENABLED && (
                <ListGroup.Item action id="btn_showHideChat" onClick={showHideChat}>
                  {t('Preferences.ShowHideChat')}
                </ListGroup.Item>
              )}
            </>
          )}
          <ListGroup.Item
            action
            id="btn_logout"
            onClick={() => {
              history.push('/logout');
              Mixpanel.sendToMixpanel(MPTrackingFunctions.logOffTrack);
            }}
          >
            {t('SignOut')}
          </ListGroup.Item>
        </ListGroup>
      </Card>
    </Popover>
  );

  function itemRender(route) {
    return <Link to={route.path}>{route.breadcrumbName}</Link>;
  }

  const toggleTeachMeModal = () => {
    if (window.WalkMePlayerAPI) {
      window.WalkMePlayerAPI.toggleMenu();
    }
  };

  return (
    <TN.TopActions>
      <TN.LeftActions className={styles.headerLeft} id="btn_topNav_headerLeft">
        <BackButton showBackButton={showBackButton && history.length > 1} />
        {!!breadcrumbs.length && <Breadcrumb items={breadcrumbs} itemRender={itemRender} />}
        <h2 className={styles.headerTitle}>{title}</h2>
      </TN.LeftActions>
      <TN.RightActions className={styles.headerRight}>
        <CompanySelect />
        <Button
          disabled={!isWalkMePlayerAPIAvailable}
          className={styles.walkMeButton}
          onClick={toggleTeachMeModal}
        />

        <OverlayTrigger trigger="click" placement="bottom" rootClose={true} overlay={HelpMenu}>
          <TN.IconButton ref={helpBtnRef}>
            <i className="tn-i-help" />
          </TN.IconButton>
        </OverlayTrigger>
        {!isDriverPortal && (
          <NotificationButton
            notifications={notifications}
            alertListRender={AlertListCommonRender}
            richNotifications={richNotifications}
            RichNotificationsRender={RichNotificationsRender}
          ></NotificationButton>
        )}
        <OverlayTrigger trigger="click" placement="bottom" rootClose={true} overlay={UserMenu}>
          <TN.Profile
            id="profile-button"
            ref={profileRef}
            initials={user.firstName[0] + user.lastName[0]}
          />
        </OverlayTrigger>
      </TN.RightActions>
      <ChangePasswordModal show={showChangePasswordModal} onHide={handlePwdModalHide} />
    </TN.TopActions>
  );
};

export default TopNav;
