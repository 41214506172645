import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/util';

import { API_PATH } from 'config';

export const ngTimezonesApi = createApi({
  reducerPath: 'ngTimezonesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  endpoints: builder => ({
    getTimezones: builder.query({
      query: () => `timezones`
    })
  })
});

export const { useGetTimezonesQuery } = ngTimezonesApi;
