import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { ewdAPI } from 'features/ewd/endpoints/ewdAPI';
import { useDispatch, useSelector } from 'react-redux';

export const actionTypes = {
  init: 0,
  pending: 1,
  processing: 2,
  error: 3,
  done: 4
};

const DATA_FETCH_INTERVAL = 60 * 1000;

export const initialState = {
  currentDriver: {},
  ewdDrivers: {
    drivers: null,
    status: {
      fetching: actionTypes.init,
      fetchingTime: null,
      error: null
    }
  }
};

const driversSlice = createSlice({
  name: 'ewdDrivers',
  initialState: initialState,
  reducers: {
    fetchEWDDriversStart(state, { payload }) {
      state.ewdDrivers.status = {
        fetching: actionTypes.processing,
        fetchingTime: moment().valueOf(),
        error: null
      };
    },
    fetchEWDDriversSucceeded(state, { payload }) {
      state.ewdDrivers.drivers = payload;
      state.ewdDrivers.status = {
        ...state.status,
        fetching: actionTypes.done,
        error: null
      };
    },
    fetchEWDDriversFailed(state, { payload }) {
      const { error } = payload;

      state.ewdDrivers.status = {
        ...state.status,
        fetching: actionTypes.error,
        error: error
      };
    },
    setEWDDriver(state, { payload }) {
      state.currentDriver = payload;
    }
  }
});

export const {
  setEWDDriver,
  fetchEWDDriversStart,
  fetchEWDDriversSucceeded,
  fetchEWDDriversFailed
} = driversSlice.actions;

export const useEwdDriver = () => {
  return useSelector(state => state.ewd.currentDriver);
};

export const useEwdDriverId = () => {
  const externalId = useEwdDriver()?.associations?.find(
    assoc => assoc.domain === 'EWD' && assoc.externalId.includes('driver_id')
  )?.externalId;
  if (externalId) {
    return JSON.parse(externalId)?.driver_id;
  }
};

export const useDriverTimezone = () => {
  return useEwdDriver()?.timeZone ?? 'UTC';
};

export const fetchEWDDrivers = () => async (dispatch, getState) => {
  const fetchStatus = getState().ewd.ewdDrivers.status;
  if (
    fetchStatus.fetching === actionTypes.init ||
    moment().valueOf() - fetchStatus.fetchingTime >= DATA_FETCH_INTERVAL
  ) {
    dispatch(fetchEWDDriversStart());
    try {
      const data = await ewdAPI.drivers();
      if (data?.error != null) {
        throw data.error;
      }
      dispatch(fetchEWDDriversSucceeded(data));
    } catch (error) {
      dispatch(fetchEWDDriversFailed(error));
    }
  }
};

export const useEWDDrivers = () => {
  const dispatch = useDispatch();
  const ewdDrivers = useSelector(state => state.ewd.ewdDrivers.drivers);
  if (ewdDrivers == null) {
    dispatch(fetchEWDDrivers());
  }
  return ewdDrivers;
};

export const useEWDDriverById = ewdDriverId => {
  const ewdDrivers = useEWDDrivers();
  return ewdDrivers?.find?.(d => d.id === ewdDriverId);
};

export default driversSlice.reducer;
