import * as d3 from 'd3';

import './svg-graph-track.scss';
import colors from 'static/scss/_colors.scss';

// Putting this scrollbar SVG code in a separate element from the main graph so that it is not
// affected by the re-renders that happens while scrolling left/right which break the interaction
export const graphScrollbar = (
  selector,
  size,
  maxBounds,
  currentBounds,
  updateCurrentBounds,
  setIsScrolling
) => {
  const { width: fullWidth, height: fullHeight } = size;
  document.querySelector(selector).innerHTML = '';

  // canvas dimensions
  let margin = { top: 10, right: 38, bottom: 10, left: 38 };
  let width = fullWidth - margin.left - margin.right;

  // svg canvas
  var svgContainer = d3
    .select(selector)
    .classed('svg-container', true)
    .append('svg')
    .classed('svg-content-responsive', true)
    .attr('width', '100%')
    .attr('height', '16px')
    .attr('viewBox', '0 0 ' + fullWidth + ' ' + fullHeight)
    .attr('preserveAspectRatio', 'none');

  let scrollHeight = 10;
  let scrollMarginBottom = 4;
  let scrollOffsetTop = fullHeight - scrollHeight - scrollMarginBottom;

  let scrollX = d3.time.scale().range([0, width]);
  scrollX.domain([maxBounds.startDate, maxBounds.endDate]);

  var scroll = svgContainer
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + scrollOffsetTop + ')')
    .attr('width', width)
    .attr('height', scrollHeight)
    .on('click', function() {
      console.log('click');
    });

  let scrollUpdateTimeout = null;

  var scrollBrush = d3.svg
    .brush()
    .x(scrollX)
    .extent([currentBounds.startDate, currentBounds.endDate])
    .on('brushstart', function() {
      setIsScrolling(true);
    })
    .on('brush', function() {
      if (!scrollUpdateTimeout) {
        scrollUpdateTimeout = setTimeout(() => {
          scrollUpdateTimeout = null;
          let extent = scrollBrush.extent();
          if (extent && extent.length > 0) {
            const time1 = extent[0].getTime();
            const time2 = extent[1].getTime();
            updateCurrentBounds({ startDate: time1, endDate: time2 });
          }
        }, 100);
      }

      // uppdate gripper lines position so it follows the bursh extent
      let extent = scrollBrush.extent();
      if (extent && extent.length > 0) {
        let gripperMiddle = scrollX(
          extent[0].getTime() + (extent[1].getTime() - extent[0].getTime()) / 2
        );

        scrollBrushContext
          .select('.gripper-left')
          .attr('transform', 'translate(' + (gripperMiddle - 2) + ', 2)');
        scrollBrushContext
          .select('.gripper-middle')
          .attr('transform', 'translate(' + gripperMiddle + ', 2)');
        scrollBrushContext
          .select('.gripper-right')
          .attr('transform', 'translate(' + (gripperMiddle + 2) + ', 2)');
      }
    })
    .on('brushend', function() {
      console.log('brushend');
      setIsScrolling(false);
    });

  var scrollBrushContext = scroll
    .append('g')
    .style('display', 'block')
    .call(scrollBrush);

  // Remove resize handles
  scrollBrushContext.selectAll('.resize').remove();

  scrollBrushContext
    .select('.extent')
    .attr('height', scrollHeight)
    .attr('fill', colors.neutral4)
    .attr('rx', '6px');

  scrollBrushContext
    .select('.background')
    .attr('height', scrollHeight)
    .style('visibility', 'visible')
    .style('pointer-events', 'none')
    .style('fill', colors.neutral2)
    .style('fill-opacity', '1.0')
    .attr('rx', '6px');

  // Add three verticle lines for a gripper like icon
  let gripperMiddle = scrollX(
    currentBounds.startDate + (currentBounds.endDate - currentBounds.startDate) / 2
  );

  scrollBrushContext
    .append('line')
    .attr('class', 'gripper-left')
    .style('stroke', colors.neutral2)
    .style('stroke-width', '1px')
    .style('pointer-events', 'none')
    .attr('transform', 'translate(' + (gripperMiddle - 2) + ', 2)')
    .attr('y2', 6);

  scrollBrushContext
    .append('line')
    .attr('class', 'gripper-middle')
    .style('stroke', colors.neutral2)
    .style('stroke-width', '1px')
    .style('pointer-events', 'none')
    .attr('transform', 'translate(' + gripperMiddle + ', 2)')
    .attr('y2', 6);

  scrollBrushContext
    .append('line')
    .attr('class', 'gripper-right')
    .style('stroke', colors.neutral2)
    .style('stroke-width', '1px')
    .style('pointer-events', 'none')
    .attr('transform', 'translate(' + (gripperMiddle + 2) + ', 2)')
    .attr('y2', 6);
};
