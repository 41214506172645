import React from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { EllipsisButton } from 'components/tables/EllipsisButton';
import { getFormattedAddressStr } from 'components/form/address';
import { TYPES } from './constants';

export const nameCellRenderer = ({ rowData, rowIndex, dataKey, parent, canView }) => {
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${rowData.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      {canView ? (
        <Link to={`/settings/locations/id/${rowData.id}`}>{rowData.name}</Link>
      ) : (
        <span>{rowData.name}</span>
      )}
    </CellMeasurer>
  );
};

export const companyCellRenderer = ({ location, companies }) => {
  const company = companies?.find(comp => comp.id === location.companyId);
  return <div>{company && company.name}</div>;
};

export const geofenceLinkCellRenderer = ({ rowData }) => {
  return <Link to={`/settings/geofences/id/${rowData.id}`}>{rowData.name}</Link>;
};

export const geofencesCellRenderer = ({ rowData }) => {
  const location = rowData;
  let geofences;
  if (location.geofences.length > 0) {
    geofences = <div style={{ textAlign: 'center' }}>{location.geofences.length}</div>;
  }
  return geofences;
};

export const addressCellRenderer = ({ rowData }) => {
  const address = rowData.address;
  let renderAddress;
  if (address) {
    renderAddress = <div>{getFormattedAddressStr(address)}</div>;
  }
  return renderAddress;
};

export const stateCellRenderer = ({ rowData }) => {
  return <div>{rowData.address && rowData.address.state ? rowData.address.state : ''}</div>;
};

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const ellipsisButtonCellRenderer = ({
  dataKey,
  rowData,
  typeOfEntityToDelete,
  hideEdit,
  hideDelete,
  enableRestoreAction,
  handleAction,
  handleDeleteAction
}) => {
  const isLocationExternal =
    rowData.externalId || [TYPES.journey].includes((rowData.type?.code || '').toLowerCase());
  return (
    <EllipsisButton
      dataKey={dataKey}
      typeOfEntityToDelete={typeOfEntityToDelete}
      path={'/settings/locations/'}
      data={rowData}
      hideEdit={hideEdit || isLocationExternal}
      hideDelete={hideDelete}
      canUse="LOCATION"
      enableRestoreAction={enableRestoreAction}
      handleAction={handleAction}
      handleDeleteAction={handleDeleteAction}
    />
  );
};
