import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { Col, Row, Button, Divider, Form } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Input, Select } from 'components/ant';
import { prepareCodeTypesForSelect } from '../helpers';
import { Paths, CODE_PREFIX } from '../constants';

import { openToast } from 'features/toasts/toastsSlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useCompanies } from 'features/company/companySlice';
import { useIsFetchingCompleted, useSmartJobsCodes } from 'features/smartJobs';
import { addCode, updateCode, useIsUpdatingCode } from 'features/smartJobs';

import style from './AddNewCode.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const AddNewCode = props => {
  const companies = useCompanies();
  const codes = useSmartJobsCodes();
  const dispatch = useDispatch();
  const loading = useIsUpdatingCode();
  const isEdit = props.action === 'edit';
  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const { t } = useTranslation();
  const defaultValues = { codes: [{ code: '', description: '' }] };
  const [codeForm] = Form.useForm();
  const companiesForSelect = (companies || []).map(company => ({
    id: company.id,
    label: company.name
  }));

  const { state } = useLocation();
  const isFetchingCompleted = useIsFetchingCompleted();

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  const handleError = useCallback(
    err => {
      if (history.location.pathname !== Paths.CODES_TABLE) {
        if (err) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: err
            })
          );
        }
        history.replace(Paths.CODES_TABLE);
      }
    },
    [history, dispatch]
  );

  useEffect(() => {
    const parsedId = parseInt(
      history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1)
    );
    if (
      isEdit &&
      (parsedId <= 0 ||
        parsedId > Number.MAX_SAFE_INTEGER ||
        isNaN(parsedId) ||
        (!state?.code && isFetchingCompleted && !codes?.some(c => c.id === parsedId)))
    ) {
      handleError(t('Common.Invalid Request ID'));
    }
  }, [t, isEdit, handleError, history, codes, isFetchingCompleted, state]);

  // Set page title
  dispatch(
    setPageTitle(
      isEdit ? `${t(`SmartJobsCodes.Edit`)} ${state?.code?.name}` : `${t(`SmartJobsCodes.addCode`)}`
    )
  );

  const handleSave = async () => {
    const values = codeForm.getFieldsValue();

    try {
      const actionCreator = isEdit
        ? updateCode({
            id: state.code.id,
            body: values
          })
        : addCode({ body: values });
      const response = await dispatch(actionCreator);
      unwrapResult(response);

      dispatch(
        openToast({
          type: ToastType.Success,
          message: `${t(`SmartJobsCodes.success${isEdit ? 'Edit' : 'Add'}Toast`)} ${state?.code
            ?.name || values.name}!`
        })
      );
      setIsDirty(false);
      // Go back to the table page
      history.push(Paths.CODES_TABLE);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCancel = () => history.push(Paths.CODES_TABLE);

  const validators = useMemo(() => {
    return {
      name: [
        { required: true, message: t('DriverMgtTypes.Form.NameRequired') },
        ({ getFieldValue }) => ({
          validator(_, value) {
            //Checking for duplicate names with the same company and code type
            if (
              isEdit &&
              value === state?.code?.name.substring(CODE_PREFIX.length, state?.code?.name.length)
            ) {
              return Promise.resolve();
            }
            const existingCodesCombinations = codes.map(
              code =>
                `${
                  code?.name.includes(CODE_PREFIX)
                    ? code?.name.substring(CODE_PREFIX.length, code?.name.length)
                    : code?.name
                }-${code?.companyId}-${code?.codeType}`
            );

            return existingCodesCombinations.includes(
              `${value}-${getFieldValue('companyId')}-${getFieldValue('codeType')}`
            )
              ? Promise.reject(new Error(t(`SmartJobsCodes.IdenticalName`)))
              : Promise.resolve();
          }
        })
      ],
      codeType: [{ required: true, message: t('Devices.FormValidation.TypeRequired') }],
      companyId: [{ required: true, message: t('DriverMgtTypes.Form.CompanyRequired') }],
      codes: [
        {
          validator: async (_, codes) => {
            if (!codes || codes.length < 1) {
              return Promise.reject(new Error(t(`SmartJobsCodes.codesError`)));
            }
          }
        }
      ],
      code: [{ required: true, message: t(`SmartJobsCodes.codeError`) }],
      description: [{ required: true, message: t(`SmartJobsCodes.descriptionError`) }]
    };
  }, [t, isEdit, state, codes]);

  const isSubmitDisabled = useCallback(form => {
    const isPristine = !form.isFieldsTouched();
    const hasRequiredValues =
      ['name', 'codeType', 'companyId'].every(fieldName => !!form.getFieldValue(fieldName)) &&
      form.getFieldValue('codes').length > 0 &&
      form.getFieldValue('codes').every(code => code?.code && code?.description);
    const hasError = !!form.getFieldsError().filter(({ errors }) => errors.length).length;
    return isPristine || !hasRequiredValues || hasError;
  }, []);

  return (
    <>
      <EditRouteGuard when={isDirty} navigate={history.push} />
      <Form
        layout="vertical"
        initialValues={
          state?.code
            ? {
                ...state.code,
                name: state.code.name.substring(CODE_PREFIX.length, state.code?.name.length)
              }
            : defaultValues
        }
        form={codeForm}
        onValuesChange={(changed, all) => {
          setIsDirty(true);
        }}
        name="addNewCode"
      >
        <div className={style.formCard}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                labelAlign="left"
                label={t(`SmartJobsCodes.name`)}
                dependencies={['codeType', 'companyId']}
                rules={validators.name}
              >
                <Input size="large" placeholder={t(`SmartJobsCodes.name`)} />
              </Form.Item>
              <Form.Item
                name="codeType"
                labelAlign="left"
                label={t(`SmartJobsCodes.codeTypeSelect`)}
                style={{ marginBottom: 0 }}
                rules={validators.codeType}
              >
                <Select
                  size="large"
                  placeholder={t(`SmartJobsCodes.selectOption`)}
                  data={prepareCodeTypesForSelect(t)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="companyId"
                labelAlign="left"
                label={t(`SmartJobsCodes.companyName`)}
                rules={validators.companyId}
              >
                <Select
                  size="large"
                  placeholder={t(`SmartJobsCodes.selectOption`)}
                  data={companiesForSelect}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={style.formCard}>
          <Row gutter={16}>
            <Col span={8}>{t(`SmartJobsCodes.code`)}</Col>
            <Col span={8}>{t(`SmartJobsCodes.description`)}</Col>
          </Row>
          <Divider />
          <Form.List name="codes" rules={validators.codes}>
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row gutter={16} key={key}>
                      <Col span={8}>
                        <Form.Item {...restField} name={[name, 'code']} rules={validators.code}>
                          <Input size="large" placeholder="Code" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          {...restField}
                          name={[name, 'description']}
                          rules={validators.description}
                        >
                          <Input size="large" placeholder="Description" />
                        </Form.Item>
                      </Col>
                      <Col span={2} className={style.delete}>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      type="secondary"
                      className={style.addButton}
                      onClick={() => add()}
                      block
                      id={BUTTON_IDS.smartjobsCodesAdd}
                    >
                      {t(`SmartJobsCodes.addAnother`)}
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </div>
        <div className={style.formFooter}>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                className={style.submitButton}
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}
                onClick={handleSave}
                id={BUTTON_IDS.smartjobsCodesSave}
                disabled={isSubmitDisabled(codeForm)}
              >
                {t(`Common.${loading ? 'Saving' : 'Save'}`)}
              </Button>
            )}
          </Form.Item>
          <Button
            size="large"
            type="secondary"
            id={BUTTON_IDS.smartjobsCodesCancel}
            className={style.cancelButton}
            onClick={handleCancel}
          >
            {t(`Common.Cancel`)}
          </Button>
        </div>
      </Form>
    </>
  );
};
