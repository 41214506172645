import { useCallback, useEffect, useState } from 'react';
import { WALKME_SUPPORT_URL, WALKME_ENABLED } from 'config';
import { useUserInfo } from 'features/user/userSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { useAppState } from 'features/appState/appStateSlice';
import moment from 'moment';
import { useFleets } from 'features/fleets/fleetsSlice';
import { useServicePermissons } from 'features/permissions';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { useDevices } from 'features/devices/devicesSlice';

function onWalkMeLoad() {
  //inject display none to walkme player
  const styleNode = document.createElement('style');
  styleNode.id = 'walkme_forcehide_style';
  styleNode.innerText = '#walkme-player {visibility:hidden!important;}';
  document.head.appendChild(styleNode);
}

const CompanyFeatureMap = {
  SA: 'Sensitive Account',
  FS: 'Field Service',
  OC: 'Operational Company',
  DS: 'Data Sovereignty',
  SP: 'Service Provider'
};

const CompanyCountryMap = {
  //add quotes to the following countries
  AU: 'Ausralia',
  MX: 'Mexico',
  DK: 'Denmark',
  Australia: 'Australia',
  US: 'United States',
  FR: 'France',
  NZ: 'New Zealand',
  GB: 'United Kingdom'
};

export function useWalkme() {
  const userInfo = useUserInfo();
  const company = useCurrentCompany();
  const services = useServicePermissons();
  const fleets = useFleets();
  const userPreferences = useUserPreferences();
  const devices = useDevices();

  useEffect(() => {
    const automationTestFlag = window.localStorage.getItem('AutomationTest');
    const isWalkMeDisabled = automationTestFlag || !WALKME_ENABLED;
    if (isWalkMeDisabled) {
      console.log(
        `${
          automationTestFlag ? 'Under automation test mode' : 'WalkMe Disabled'
        }, WalkMe script will not be loaded.`
      );
    }
    if (!isWalkMeDisabled && !document.querySelector('#walkmeScript')) {
      var walkme = document.createElement('script');
      walkme.id = 'walkmeScript';
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src = WALKME_SUPPORT_URL;
      walkme.onload = onWalkMeLoad;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(walkme, s);
    }

    window._walkmeConfig = { smartLoad: true };
    if (userInfo) {
      window.username = userInfo.username;
      window.region = userInfo.timeZone;
      window.userroles = userInfo.roles?.map(r => r.name).join(',');

      window.last_login_time = userInfo.userSession?.lastSignInAt
        ? moment(userInfo.userSession?.lastSignInAt).toISOString()
        : '';
      window.userLoginCount = userInfo.userSession?.signInCount;
      window.userLoginDaysCount = userInfo.userSession?.loginDaysCount;
      window.userType = userInfo.type?.name;
      window.userEmail = userInfo.email;
      window.userId = userInfo.id;
      window.userCreatedDate = moment(userInfo.createdAt).toISOString();
      window.userFirstName = userInfo.firstName;
      window.userLastName = userInfo.lastName;
      window.userFeatures = services ? services.join(',') : '';
    }
    if (company?.id) {
      window.company = company.name;
      window.sap_customer_number = company.customerNumber;

      if (company.createdAt) {
        window.companyAge = moment().diff(moment(company.createdAt), 'day');
      }
      window.companyCountry = CompanyCountryMap[company.country] || 'Unknown';
      window.companyLang = company.locale;
      window.parentCompany = company.parent?.id;
      window.companyTimeZone = company.statisticsTz;
      window.companyFeatures = Object.keys(CompanyFeatureMap)
        .map(k => CompanyFeatureMap[k] + ':' + (company.companyFeatures?.includes(k) || false))
        .join(',');
    }

    if (fleets) {
      window.fleetSize = fleets.length;
    }

    if (userPreferences) {
      window.langCode = userPreferences.language;
    } else if (company) {
      window.langCode = company.locale;
    }

    if (devices) {
      const cameraProviders = [
        ...new Set(
          Object.values(devices)
            .filter(device => device.companyId === company?.id && device.type.code === 'CAMERA')
            .map(device => device.model.name)
        )
      ];
      window.cameraProviders = cameraProviders.join(',');
    }

    const langCode = window.langCode?.toUpperCase().split('-')[0];
    if (!langCode || langCode === 'EN') {
      window.langCode = 'default';
    } else if (langCode === 'ES') {
      window.langCode = 'Spanish';
    } else if (langCode === 'FR') {
      window.langCode = 'French';
    } else if (langCode === 'DA') {
      window.langCode = 'Danish';
    } else {
      window.langCode = 'default';
    }

    window.walkme_get_language = function() {
      return window.langCode;
    };
  }, [userInfo, company, services, fleets, userPreferences]);
}

export function useWalkMeInFrame(iframeRef) {
  const userInfo = useUserInfo();
  const company = useCurrentCompany();
  const appState = useAppState();
  const [frameLoaded, setFrameLoaded] = useState(0);

  const loadWalkMeScript = useCallback(
    targetWindow => {
      const data = {
        msgType: 'loadWalkMeScript',
        walkme_link: WALKME_SUPPORT_URL,
        config: {
          _walkmeConfig: {
            smartLoad: true
          }
        }
      };
      if (userInfo) {
        data.config.username = userInfo.username;
        data.config.region = userInfo.timeZone;
        data.config.userroles = userInfo.roles;
        //last login time?
        data.config.last_login_time = userInfo.lastLoginTime;
        //saleforce id
      }
      if (company) {
        data.config.company = company.name;
        data.config.saleforce_id = company.saleforce_id;
        //size of fleet?
        data.config.fleetSize = company.fleetSize;
      }
      setTimeout(() => targetWindow.postMessage(data, '*'), 100);
    },
    [userInfo, company]
  );

  const hideWalkMe = useCallback(targetWindow => {
    targetWindow.postMessage(
      {
        msgType: 'hideWalkMe'
      },
      '*'
    );
  }, []);

  const handleIFrameLoaded = useCallback(
    evt => {
      console.log('handleIFrameLoaded', evt?.target?.contentWindow);
      evt?.target?.contentWindow?.postMessage('init', '*');
      setFrameLoaded(prevCount => prevCount + 1);
    },
    [loadWalkMeScript]
  );

  const handlePostMessage = useCallback(evt => {
    if (
      !evt.origin ||
      !evt.origin.match(/[a-zA-Z0-9-]*.(?:teletracnavman.net)|(?:telematics.com)/)
    ) {
      return;
    }
    if (evt?.data?.msgType === 'LOAD_WALKE_ME') {
      loadWalkMeScript(evt.source);
    }
  }, []);

  useEffect(() => {
    if (
      !iframeRef.current ||
      !iframeRef.current.tagName ||
      iframeRef.current.tagName !== 'IFRAME'
    ) {
      return;
    }

    iframeRef.current.addEventListener('load', handleIFrameLoaded);
    iframeRef.current.addEventListener('message', handlePostMessage);
    return () => {
      if (iframeRef?.current?.removeEventListener) {
        iframeRef.current.removeEventListener('load', handleIFrameLoaded);
        iframeRef.current.removeEventListener('message', handlePostMessage);
      }
    };
  }, [userInfo, company, iframeRef.current, handleIFrameLoaded, handlePostMessage]);

  useEffect(() => {
    if (!iframeRef.current) {
      return;
    }

    if (appState.isWalkMeActive) {
      loadWalkMeScript(iframeRef.current.contentWindow);
    } else {
      hideWalkMe(iframeRef.current.contentWindow);
    }
  }, [iframeRef.current, appState, hideWalkMe, frameLoaded, loadWalkMeScript]);
}

export function useWalkMeReady() {
  const [isWalkMePlayerAPIAvailable, setIsWalkMePlayerAPIAvailable] = useState(
    !!window.WalkMePlayerAPI
  );

  useEffect(() => {
    window.walkme_ready = function() {
      setIsWalkMePlayerAPIAvailable(true);
    };

    return () => {
      window.walkme_ready = null;
    };
  }, []);

  return isWalkMePlayerAPIAvailable;
}
