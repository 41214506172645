export const TrackingPaths = {
  Default: '/tracking',
  Track: '/tracking/track',
  TrackSummary: '/tracking/track/summary/:deviceId',
  TrackDetails: '/tracking/track/details/:deviceId',
  TrackParameters: '/tracking/track/parameters/:deviceId',
  TrackHDData: '/tracking/track/hddata/:deviceId/:dateFrom/:dateTo',
  Trips: '/tracking/trips',
  TripsSummary: '/tracking/trips/summary',
  Safety: '/tracking/safety',
  SafetySummary: '/tracking/safety/summary',
  MapTiles: '/tracking/maptiles',
  MapTilesSummary: '/tracking/maptiles/summary',
  Proximity: '/tracking/proximity',
  CardView: '/tracking/cardview',
  Summary: '/tracking/:lens/summary/:deviceId',
  Details: '/tracking/:lens/details/:deviceId',
  Parameters: '/tracking/:lens/parameters/:deviceId',
  Lens: '/tracking/:lens?'
};

export const TrackingLens = t => {
  if (t) {
    return {
      Track: t('Tracking.Lens.Track'),
      Trips: t('Tracking.Lens.Trips'),
      Safety: t('Tracking.Lens.Safety'),
      Proximity: t('Tracking.Lens.Proximity'),
      MapTiles: t('Tracking.Lens.MapTiles'),
      CardView: t('Tracking.Lens.CardView')
    };
  }
  return {
    Track: 'Track',
    Trips: 'Trips',
    Safety: 'Safety',
    Proximity: 'Proximity',
    MapTiles: 'Map Tiles',
    CardView: 'Card View'
  };
};

export const TrackingRouteLens = {
  track: TrackingLens().Track,
  trips: TrackingLens().Trips,
  safety: TrackingLens().Safety,
  proximity: TrackingLens().Proximity,
  maptiles: TrackingLens().MapTiles,
  cardview: TrackingLens().CardView
};

export const DEFAULT_VEHICLE_ICON = 'tn-i-flatbed';
export const DEFAULT_DEVICE_ICON = 'tn-i-device';
export const IGNITION_STATUS = {
  OUT: {
    status: 'OUT',
    color: '#969696'
  },
  ON: {
    status: 'ON',
    color: '#26bd8b'
  },
  OFF: {
    status: 'OFF',
    color: '#22ADE2'
  }
};

export const DUTY_STATUS = {
  Driving: {
    status: 'Driving',
    color: '#26bd8b'
  },
  OnDuty: {
    status: 'On Duty',
    color: '#1890ff'
  },
  OffDuty: {
    status: 'Off Duty',
    color: '#9fa9b7'
  },
  POA: {
    status: 'POA', //"POA  ***Don't translate this, it's just an explanation of the acronym: Period of Availability",
    color: '#969696'
  },
  SleeperBerth: {
    status: 'Sleeper Berth',
    color: '#42c3f9'
  },
  Unknown: {
    status: 'Unknown',
    color: '#969696'
  },
  Work: {
    status: 'Work',
    color: '#1890ff'
  },
  Rest: {
    status: 'Rest',
    color: '#9fa9b7'
  },
  PersonalConveyance: {
    status: 'Personal Conveyance',
    color: '#9fa9b7'
  },
  YardMove: {
    status: 'Yard Move',
    color: '#1890ff'
  },
  AdverseConditions: {
    status: 'Adverse Conditions',
    color: '#26bd8b'
  },
  NonBusiness: {
    status: 'NonBusiness',
    color: '#9F5494'
  }
};

//Reference : src/constianers/ELD/Constants : DriverStatusId
//For get status: src/constianers/ELD/Driverlog/DriverLogPageHeader.js line65:  driverInfoCardData.status = driverEldData.lastEvent?.type;
//Or: src/constianers/ELD/Driverlog/DriverLogGrid.js line246: row.dutyStatus = DutyStatus[row.action];
//Or: src/constianers/ELD/Overview.js line84: DriverStatusId[eldData?.lastEvent?.type || eldData?.lastEvent?.action || 'OffDuty'],
export const ELD_DRIVER_DUTY_STATUS = {
  Driving: DUTY_STATUS.Driving,
  OnDuty: DUTY_STATUS.OnDuty,
  'On Duty': DUTY_STATUS.OnDuty,
  OffDuty: DUTY_STATUS.OffDuty,
  'Off Duty': DUTY_STATUS.OffDuty,
  SleeperBerth: DUTY_STATUS.SleeperBerth,
  'Sleeper Berth': 'Sleeper Berth',
  PersonalConveyance: DUTY_STATUS.PersonalConveyance,
  'Personal Conveyance': DUTY_STATUS.PersonalConveyance,
  YardMove: DUTY_STATUS.YardMove,
  'Yard Move': DUTY_STATUS.YardMove,
  AdverseConditions: DUTY_STATUS.AdverseConditions,
  'Adverse Conditions': DUTY_STATUS.AdverseConditions,
  IntermediateLog: DUTY_STATUS.IntermediateLog,
  'Intermediate Log': DUTY_STATUS.IntermediateLog
};

//Reference: src/features/fatigue/StateRenderer.js function: getClassOfDriverState
//For get status: rowData.[sentinelStatus | ewdData]?.lastRelevantEvent?.type
export const SENTINEL_EWD_DRIVER_DUTY_STATUS = {
  Driving: DUTY_STATUS.Driving,
  OnDuty: DUTY_STATUS.OnDuty,
  OffDuty: DUTY_STATUS.OffDuty,
  Work: DUTY_STATUS.Work,
  Rest: DUTY_STATUS.Rest,
  Unknown: DUTY_STATUS.Unknown
};

//Refernce: src/containers/Fatigue/tacho/cellRenderers.js function: getClassOfDriverState
//For get status: rowData.fatigueStatus && rowData.fatigueStatus.mode != null ? rowData.fatigueStatus.mode : 3
export const TACHO_DRIVER_DUTY_STATUS = {
  0: {
    ...DUTY_STATUS.Rest,
    color: '#42c3f0'
  }, //mode:0
  1: DUTY_STATUS.POA, //mode:1
  2: {
    ...DUTY_STATUS.Work,
    color: '#9cca55'
  }, //mode:2
  3: {
    ...DUTY_STATUS.Driving,
    color: '#22c594'
  } //mode:3
};

export const FATIGUE_TYPE = {
  EWD: 'EWD',
  ELD: 'ELD',
  TACHO: 'TACHO',
  SENTINEL: 'SENTINEL',
  NonBusiness: 'NonBusiness',
  Unknown: 'Unknown'
};

export const AUTO_HELI_THRESHOLD = 30;
export const DEFAULT_IGNITION = IGNITION_STATUS.OFF;
export const DRONE_VIEW_AUTO_END = 5 * 60 * 1000;

export const HDDATA_URL_DATE_FORMAT = 'YYYY-MM-DDTHH:mm';

export const LIVE_UPDATE_PUBLIC_PAGE_INTERVAL = 30000;
