import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Drawer } from 'antd';
import { GeofenceForm } from 'containers/Administration/Geofences/GeofenceForm';
import { helpers } from 'containers/Administration/Geofences/helpers';
import { useAllRegions } from 'features/regions/regionsSlice';
import { GeofenceDrawerView } from './GeofenceDrawerView';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Space, Button } from 'antd';
import { BUTTON_IDS } from 'utils/globalConstants';
import { GeofenceAudits } from './GeofenceAudits';
import { deleteGeofenceApi } from 'features/geofences/geofencesSlice';
import { useDispatch } from 'react-redux';
import styles from './Geofences.module.scss';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

export const GeofenceDrawer = props => {
  const {
    onClose,
    onGeofenceDelete,
    mapOption,
    title,
    action,
    open,
    geofence,
    ...otherProps
  } = props;
  const regions = useAllRegions();
  const dispatch = useDispatch();
  const mapCoordinates = helpers.getGPSFromRegions(regions);
  const { t } = useTranslation();
  const { innerWidth: width } = window;
  const [geofenceConfiguration, setGeofenceConfiguration] = useState({ open: false });

  useEffect(() => {
    setGeofenceConfiguration({
      title: props.title,
      action: props.action,
      open: props.open,
      geofence: props.geofence
    });
  }, [props.title, props.action, props.open, props.geofence]);

  const handleClose = val => {
    if (onClose) {
      onClose(val);
    }
  };

  const onDelete = useCallback(() => {
    dispatch(
      deleteGeofenceApi(geofenceConfiguration?.geofence, undefined, () => {
        onGeofenceDelete();
        handleClose();
      })
    );
  }, [geofenceConfiguration]);

  const drawerFooter = useMemo(() => {
    if (geofenceConfiguration?.action === 'view') {
      return (
        <Row>
          <Col span={12}>
            <Space size={16}>
              <Button
                size="large"
                id={BUTTON_IDS.geofenceViewEdit}
                onClick={() => {
                  setGeofenceConfiguration(prev => ({
                    ...prev,
                    title: `${t('Common.EllipsisButton.Edit')} ${
                      geofenceConfiguration?.geofence?.name
                    }`,
                    action: 'edit'
                  }));
                }}
                type="primary"
              >
                {t('Common.EditButton')}
              </Button>
              <Button
                size="large"
                id={BUTTON_IDS.geofenceViewAudit}
                onClick={() => {
                  setGeofenceConfiguration(prev => ({
                    ...prev,
                    title: t('Audits.AuditHistoryFor', {
                      name: `${geofenceConfiguration?.geofence?.name}`
                    }),
                    action: 'audit'
                  }));
                }}
                type="secondary"
              >
                {t('Common.AuditsButton')}
              </Button>
              <Button
                size="large"
                id={BUTTON_IDS.geofenceViewCancel}
                onClick={() => handleClose()}
                type="secondary"
              >
                {t('Common.CancelButton')}
              </Button>
            </Space>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button
              size="large"
              id={BUTTON_IDS.geofenceViewDelete}
              onClick={() => {
                confirmationModal(
                  `${t('Common.DeleteButton')} ${geofenceConfiguration?.geofence?.name}`,
                  `${t('Common.SureDelete')} ${t('Common.geofence')} ${
                    geofenceConfiguration?.geofence?.name
                  }?`,
                  t('Common.DeleteButton'),
                  t('Common.CancelButton'),
                  onDelete,
                  'delete',
                  null,
                  null,
                  null,
                  null,
                  null,
                  10000
                );
              }}
              danger
              type="secondary"
            >
              {t('Common.DeleteButton')}
            </Button>
          </Col>
        </Row>
      );
    } else if (geofenceConfiguration?.action === 'audit') {
      return (
        <Row>
          <Col span={12}>
            <Space size={16}>
              <Button
                size="large"
                id={BUTTON_IDS.geofenceViewEdit}
                onClick={() => {
                  setGeofenceConfiguration(prev => ({
                    ...prev,
                    title: `${geofenceConfiguration?.geofence?.name}`,
                    action: 'view'
                  }));
                }}
                type="primary"
              >
                {t('Common.BackButton')}
              </Button>
            </Space>
          </Col>
        </Row>
      );
    }
  }, [geofenceConfiguration]);

  return (
    <Drawer
      title={geofenceConfiguration?.title}
      zIndex={10000}
      onClose={() => handleClose()}
      open={geofenceConfiguration?.open}
      destroyOnClose={true}
      width={width - 200}
      className={styles.drawer}
      bodyStyle={{ padding: 0 }}
      footer={drawerFooter}
    >
      {geofenceConfiguration?.action === 'audit' && (
        <div style={{ padding: 16 }}>
          <GeofenceAudits geofenceId={geofenceConfiguration?.geofence?.id} />
        </div>
      )}
      {geofenceConfiguration?.action === 'view' && (
        <GeofenceDrawerView
          id={geofenceConfiguration?.geofence?.id}
          onExit={onClose}
          {...otherProps}
        />
      )}
      {(geofenceConfiguration?.action === 'edit' || geofenceConfiguration?.action === 'add') && (
        <GeofenceForm
          action={geofenceConfiguration?.action}
          drawerView={true}
          coordinates={mapCoordinates}
          id={geofenceConfiguration?.geofence?.id}
          onFinish={onClose}
          mapOption={geofenceConfiguration?.action === 'add' ? mapOption : {}}
          {...otherProps}
        />
      )}
    </Drawer>
  );
};
