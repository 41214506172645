import React from 'react';
import P from 'prop-types';
import { ReactSortable } from 'react-sortablejs';

export const SortableList = ({
  list,
  setList,
  group,
  containerTag = 'div',
  handle,
  dragIconSrc,
  styles,
  animation = 150,
  setShowPlaceholder = () => {}
}) => {
  return (
    <ReactSortable
      list={list}
      setList={setList}
      animation={animation}
      group={group}
      className={styles.list}
      handle={'img'}
      tag={containerTag}
      onStart={() => {
        setShowPlaceholder(false);
      }}
      onEnd={() => {
        setShowPlaceholder(true);
      }}
    >
      {list.map(item => (
        <div key={item.key} className={styles.listItem}>
          {handle && dragIconSrc && <img src={dragIconSrc} className={styles.handle} alt="" />}
          {item.title}
        </div>
      ))}
    </ReactSortable>
  );
};

SortableList.propTypes = {
  list: P.arrayOf(
    P.shape({
      key: P.string,
      title: P.string
    })
  ),
  setList: P.func,
  group: P.string,
  styles: P.object,
  animation: P.number,
  containerTag: P.string,
  handle: P.string,
  dragIconSrc: P.string
};
