import React, { useState } from 'react';
import FormInput from 'components/form/form-input/FormInput';
import FormTitle from 'components/form/form-title/FormTitle';
import { GlobalRoles, useCan } from 'features/permissions';
import { Field } from 'formik';
import { Col, Row, FormCheck, FormGroup, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { USER_TYPES, ACTIONS, AUTH_REQUIRED_ACTIONS } from './constants';
import styles from './Users.module.scss';
import { format } from 'utils/dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const UserSecurityField = ({ enabled, children, fieldTitle, tooltipMsg, icon }) =>
  enabled ? (
    <div className={styles.userSecurityFieldContainer}>
      <div className={styles.formContainer} style={{ paddingBottom: '32px' }}>
        <FormTitle title={fieldTitle} underlined tooltipMsg={tooltipMsg} icon={icon} />
        {children}
      </div>
    </div>
  ) : null;

export const UserSecurityFields = ({ action, userType, isELD, localization, userData }) => {
  const { t } = useTranslation();
  const can = useCan();
  const audit = userData?.audit;
  const [passwordType, setPasswordType] = useState('password');
  const [passwordConfirmType, setPasswordConfirmType] = useState('password');
  return (
    <Col>
      <Row>
        <UserSecurityField
          enabled
          fieldTitle={
            action === ACTIONS.ADD
              ? `${t('Users.Form.Password')}`
              : `${t('Users.Form.ChangePassword')}`
          }
        >
          {action === ACTIONS.EDIT && (
            <div className={styles.infoContainer} style={{ margin: '16px 0 0 0' }}>
              {t('Users.Form.LeaveBlankPassword')}
            </div>
          )}

          <Row>
            <InputGroup>
              <FormInput
                name="password"
                type={passwordType}
                autoComplete="new-password"
                label={t('Users.Form.Password')}
                placeholder={t('Users.Form.PasswordPlaceholder')}
                isValidated
                isRequired={
                  isELD &&
                  action === ACTIONS.ADD &&
                  (userType === USER_TYPES.DRIVER || userType === USER_TYPES.SUPPORT_PERSONNEL)
                }
              />
              <InputGroup.Append className={styles.inputGroupClass}>
                <button
                  className={styles.pinPasswordEye}
                  type="button"
                  onClick={() =>
                    passwordType === 'password'
                      ? setPasswordType('text')
                      : setPasswordType('password')
                  }
                >
                  <FontAwesomeIcon icon={passwordType === 'password' ? faEye : faEyeSlash} />{' '}
                </button>
              </InputGroup.Append>
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <FormInput
                name="passwordConfirm"
                type={passwordConfirmType}
                autoComplete="new-password"
                label={t('Users.Form.PasswordConfirm')}
                placeholder={t('Users.Form.PasswordConfirmPlaceholder')}
                isValidated
                isRequired={
                  isELD &&
                  action === ACTIONS.ADD &&
                  (userType === USER_TYPES.DRIVER || userType === USER_TYPES.SUPPORT_PERSONNEL)
                }
              />
              <InputGroup.Append className={styles.inputGroupClass}>
                <button
                  className={styles.pinPasswordEye}
                  type="button"
                  onClick={() =>
                    passwordConfirmType === 'password'
                      ? setPasswordConfirmType('text')
                      : setPasswordConfirmType('password')
                  }
                >
                  <FontAwesomeIcon icon={passwordConfirmType === 'password' ? faEye : faEyeSlash} />{' '}
                </button>
              </InputGroup.Append>
            </InputGroup>
          </Row>
        </UserSecurityField>

        <UserSecurityField
          fieldTitle={action === ACTIONS.ADD ? t('Users.Form.PIN') : t('Users.Form.ChangePIN')}
          enabled={[USER_TYPES.SUPPORT_PERSONNEL, USER_TYPES.DRIVER].some(t => t === userType)}
        >
          <div className={styles.infoContainer} style={{ margin: '16px 0 0 0' }}>
            {t('Users.Form.PINToolTip')}
          </div>
          <Row>
            <InputGroup>
              <FormInput
                name="pin"
                type={'text'}
                autoComplete="new-password"
                label={t('Users.Form.PIN')}
                placeholder={t('Users.Form.TypePIN')}
                isValidated
                defaultValue={userData?.externalPin}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <FormInput
                name="pinConfirm"
                type={'text'}
                autoComplete="new-password"
                label={t('Users.Form.ConfirmPIN')}
                placeholder={t('Users.Form.ConfirmPIN')}
                isValidated
                defaultValue={userData?.externalPin}
              />
            </InputGroup>
          </Row>
        </UserSecurityField>
      </Row>
      <Row>
        <UserSecurityField
          enabled={can({
            oneOfRoles: [GlobalRoles.Admin],
            otherConditions: [() => userType === USER_TYPES.DRIVER]
          })}
          fieldTitle={t('Users.Form.ElectronicID.Title', { count: '1' })}
        >
          <div className={styles.infoContainer} style={{ margin: '16px 0 0 0' }}>
            {t('Users.Form.ElectronicID1ToolTip')}
          </div>
          <Row>
            <InputGroup>
              <FormInput
                name="ePin1"
                type={'text'}
                label={t('Users.Form.ElectronicID.ID')}
                placeholder={t('Users.Form.ElectronicID.TypeID')}
                isValidated
                defaultValue={userData?.electronicPin}
                isReadonly={true}
              />
            </InputGroup>
          </Row>
        </UserSecurityField>
        <UserSecurityField
          enabled={can({
            oneOfRoles: [GlobalRoles.Admin],
            otherConditions: [() => userType === USER_TYPES.DRIVER]
          })}
          fieldTitle={t('Users.Form.ElectronicID.Title', { count: '2' })}
        >
          <div className={styles.infoContainer} style={{ margin: '16px 0 0 0' }}>
            {t('Users.Form.ElectronicID2ToolTip')}
          </div>
          <Row>
            <InputGroup>
              <FormInput
                name="ePin2"
                type={'text'}
                label={t('Users.Form.ElectronicID.ID')}
                placeholder={t('Users.Form.ElectronicID.TypeID')}
                isValidated
                defaultValue={userData?.electronicPin2}
              />
            </InputGroup>
          </Row>
          <Row>
            <InputGroup>
              <FormInput
                name="electronicPin2Confirm"
                type={'text'}
                label={t('Users.Form.ElectronicID.ConfirmID')}
                placeholder={t('Users.Form.ElectronicID.ConfirmID')}
                isValidated
                defaultValue={userData?.electronicPin2}
              />
            </InputGroup>
          </Row>
        </UserSecurityField>
      </Row>
      <Row>
        <UserSecurityField
          enabled={can({
            oneOfRoles: [GlobalRoles.Admin]
          })}
          fieldTitle={t('Login.SSO.AuthenticationRequiredActions')}
        >
          <Field name="features">
            {({ field }) => (
              <FormGroup>
                {AUTH_REQUIRED_ACTIONS.map(action => (
                  <div className={styles.multipleCheckboxRow} key={action.key}>
                    <FormCheck
                      {...field}
                      style={{ paddingBottom: 'unset', minWidth: '50%' }}
                      key={action.key}
                      id={`required_action_${action.key}`}
                      label={`${t(`Login.SSO.RequiredActions.${action.key}`)}`}
                      checked={(field.value || []).some(selected => selected === action.key)}
                      value={action.key}
                    />
                    <span
                      className="checkboxRowSpan"
                      style={{ minWidth: '50%', textAlign: 'right' }}
                    >
                      {audit && audit.filter(i => i.action === action.auditKey).length !== 0
                        ? format(
                            new Date(audit.filter(i => i.action === action.auditKey)[0].createdAt),
                            localization.formats.time.formats.dby_imp
                          )
                        : ''}
                    </span>
                  </div>
                ))}
              </FormGroup>
            )}
          </Field>
        </UserSecurityField>
      </Row>
    </Col>
  );
};
