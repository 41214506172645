import React, { useState, useCallback, useMemo } from 'react';
import { Row, Col, Switch } from 'antd';
import Donut from 'components/visualization/Donut';
import styles from './UDT.module.scss';
import { useTranslation } from 'react-i18next';
import i18next from 'i18nextConfig';
import { UDTAssignmentMode } from '../Constants';

const DEFAULT_CHART_DATA = {
  incompletedEvents: [
    /*
    {
      id: 'Rejected',
      angle: 0,
      color: '#ff4d4f',
      mousehoverColor: '#9fa9b7',
      get message() {
        return i18next.t('ELD.Rejected');
      }
    },*/
    {
      id: 'Pending',
      angle: 0,
      color: '#1890ff',
      mousehoverColor: '#9fa9b7',
      get message() {
        return i18next.t('ELD.Pending');
      }
    },
    {
      id: 'Unassigned',
      angle: 0,
      color: '#969696',
      mousehoverColor: '#9fa9b7',
      get message() {
        return i18next.t('ELD.Unassigned');
      }
    }
  ],
  completedEvents: [
    {
      id: 'Assigned',
      angle: 0,
      color: '#5D58D8',
      mousehoverColor: '#9fa9b7',
      get message() {
        return i18next.t('ELD.Assigned');
      }
    },
    {
      id: 'Commented',
      angle: 0,
      color: '#26bd8b',
      mousehoverColor: '#9fa9b7',
      get message() {
        return i18next.t('ELD.Commented');
      }
    }
  ]
};

export function ChartDisableOverlay(props) {
  return <div className={styles.disableOverlay}></div>;
}

export function UDTChart({ data, assignMode, onChartFilterChanged, ...props }) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({
    selectedStatus: null,
    showCompletedEvents: false
  });

  const chartData = useMemo(() => {
    const chartData = { ...DEFAULT_CHART_DATA };
    if (data != null) {
      //chartData.incompletedEvents[0].angle = data.rejectedEvents;
      chartData.incompletedEvents[0].angle = data.pendingEvents;
      chartData.incompletedEvents[1].angle = data.unassignedEvents;

      chartData.completedEvents[0].angle = data.assignedEvents;
      chartData.completedEvents[1].angle = data.commentedEvents;
    }
    return chartData;
  }, [data]);

  const handleFilterChanged = useCallback(
    (chart, status) => {
      setFilter(oldState => {
        if (
          chart === 'showCompletedEvents' &&
          !status &&
          ['Assigned', 'Commented'].includes(oldState?.selectedStatus?.id)
        ) {
          oldState.selectedStatus = null;
        }
        if (onChartFilterChanged) {
          onChartFilterChanged({
            ...oldState,
            [chart]: status
          });
        }
        return {
          ...oldState,
          [chart]: status
        };
      });
    },
    [onChartFilterChanged]
  );

  const handleEventTypeSelected = useCallback(
    status => {
      handleFilterChanged('selectedStatus', status);
    },
    [handleFilterChanged]
  );

  const handleShowCompletedEvents = useCallback(
    checked => {
      handleFilterChanged('showCompletedEvents', checked);
    },
    [handleFilterChanged]
  );

  return (
    <Row gutter={16}>
      <Col xxl={5} xs={8}>
        {assignMode === UDTAssignmentMode.BulkAssignment && <ChartDisableOverlay />}
        <LogStatusSummaryChart
          assignMode={assignMode}
          data={chartData.incompletedEvents}
          onEventTypeSelected={handleEventTypeSelected}
        />
      </Col>
      <Col xxl={5} xs={8}>
        {(assignMode === UDTAssignmentMode.BulkAssignment || !filter.showCompletedEvents) && (
          <ChartDisableOverlay />
        )}
        <LogTypesGroupCharts
          key={!filter.showCompletedEvents}
          assignMode={assignMode}
          data={chartData}
          onEventTypeSelected={handleEventTypeSelected}
        />
      </Col>
      <Col xxl={14} xs={8}>
        {assignMode === UDTAssignmentMode.BulkAssignment && <ChartDisableOverlay />}
        <div className={styles.completedEventsSwitch}>
          <Switch
            disabled={assignMode}
            title={t('ELD.Show Completed Events')}
            onChange={handleShowCompletedEvents}
          />
          <span>{t('ELD.Show Completed Events')}</span>
        </div>
      </Col>
    </Row>
  );
}

function LogStatusSummaryChart({ data, assignMode, onEventTypeSelected, ...props }) {
  const { t } = useTranslation();
  const [, setSelectionId] = useState(null);
  const handleSelection = useCallback(
    id => {
      setSelectionId(oldState => {
        if (oldState !== id) {
          if (onEventTypeSelected) {
            onEventTypeSelected({ id: id, selected: true });
          }
          return id;
        } else {
          onEventTypeSelected({ id: id, selected: false });
          return null;
        }
      });
    },
    [onEventTypeSelected]
  );
  return (
    <Donut
      disabled={assignMode}
      title={t('ELD.Unassigned Events')}
      data={data}
      onSelection={handleSelection}
      isLoading={false}
    />
  );
}

function LogTypesGroupCharts({ data, assignMode, onEventTypeSelected, disabled, ...props }) {
  return (
    <Row gutter={16} style={{ height: '100%', alignItems: 'center' }}>
      <Col xxl={6} xs={10}>
        <CompletedLogsChart
          disabled={disabled}
          assignMode={assignMode}
          data={data}
          onEventTypeSelected={onEventTypeSelected}
        />
      </Col>
    </Row>
  );
}
/*
function LogWithCommentsChart({ data, assignMode, onChartClicked, ...props }) {
  const { t } = useTranslation();
  return (
    <Gauge
      message={t('ELD.Events with comments')}
      color={'#5d58d8'}
      mousehoverColor={'#ff7875'}
      activeState={'DEFAULT_STATE'}
      value={data.eventsWithComments}
      max={data.totalEvents}
      isLoading={false}
      onLabelClicked={onChartClicked}
    />
  );
}*/

function CompletedLogsChart({ data, assignMode, onEventTypeSelected, ...props }) {
  const { t } = useTranslation();

  const [, setSelectionId] = useState(null);
  const handleSelection = useCallback(
    id => {
      setSelectionId(oldState => {
        if (oldState !== id) {
          if (onEventTypeSelected) {
            onEventTypeSelected({ id: id, selected: true });
          }
          return id;
        } else {
          onEventTypeSelected({ id: id, selected: false });
          return null;
        }
      });
    },
    [onEventTypeSelected]
  );
  return (
    <Donut
      title={t('ELD.Completed Events')}
      data={data.completedEvents}
      onSelection={handleSelection}
      isLoading={false}
    />
  );
}
