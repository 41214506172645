import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { format } from 'utils/dates';
import { LoadingTable } from 'components/grid/LoadingTable';
import { Col, Row, Spin } from 'antd';
import {
  useCompanies,
  fetchCompanies,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import { useGetTimezonesQuery } from 'services/nextgen';
import { useBranches } from 'features/locations/locationsSlice';
import {
  deleteUserApi,
  fetchELDDrivingConditions,
  restoreUserApi,
  useUsers
} from 'features/users/usersSlice';
import { useRoles } from 'features/roles/rolesSlice';
import { useDriverMgtTypes, useDriverManagementSchedules } from 'features/driverManagement';
import { logInAsUser } from 'features/user/userSlice';
import { fetchPermissions } from 'features/permissions/permissionsSlice';
import InfoRow from 'components/form/info-row/InfoRow';
import FormTitle from 'components/form/form-title/FormTitle';
import { useUserViewData } from './APICalls';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import Accordion from 'components/form/accordion/Accordion';
import { ImageUpload, Table } from 'components/ant';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';
import { useCan } from 'features/permissions/canHooks';
import {
  services,
  entities,
  useIQCameraUser,
  useCanOneOfRoles,
  GlobalRoles
} from 'features/permissions';
import { canLogInAsUser, hyperlinkToLocation } from './helpers';
import {
  prepareDataForInnerTable,
  prepareColumnsForInnerTable
} from '../../DriverManagement/helpers';
import { useDriverManagementFields } from 'features/company_config/hooks';
import { useForceLogoff } from 'features/users/forceLogoff';

//constants
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import {
  rulesetsViewTableColumns,
  LOCATION_VIEW_URL,
  ROLE_VIEW_URL,
  USER_TYPES,
  isDriverType,
  PATHS,
  BRANCH_TYPE
} from './constants';

//styles
import styles from './Users.module.scss';

import { downloadDocument } from 'features/easydocs/documentsSlice';
import { DeletableEntityType, DeletedEntityAlert } from 'features/common/deletedEntityAlert';
import { DEFAULT_DRIVER_FORM_FIELD_NAME } from '../Vehicles/DefaultDriver/constants';
import defaultDriverStyles from '../Vehicles/DefaultDriver/DefaultDriver.module.scss';

export const UserView = ({ rulesets }) => {
  const { canAccessNonCameraFeatures } = useIQCameraUser();
  const { t } = useTranslation();
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );
  const [refetch, setRefetch] = useState(0);
  const history = useHistory();
  const handleUserFetchError = useCallback(() => {
    history.replace(PATHS.USERS_DEFAULT);
  }, [history]);
  const data = useUserViewData({
    id: id,
    refetch,
    onError: handleUserFetchError,
    embedParam: 'fleets,locations,image_url,user_session'
  });
  const { isLoadingEmbed } = data;

  const loggedInUserInfo = useSelector(state => state.user.currentUserInfo);
  const hasSiteAdminOrResellerRole = useCanOneOfRoles([
    GlobalRoles.Reseller,
    GlobalRoles.SiteAdmin
  ]);

  const companies = useCompanies();
  const users = useUsers();
  const roles = useRoles();
  const { data: newTimezones } = useGetTimezonesQuery();
  const mgtTypes = useDriverMgtTypes();
  const schedules = useDriverManagementSchedules();
  const branches = useBranches();
  const dispatch = useDispatch();

  const localization = useLocalization();
  const locationLink = useLocation();
  const { pageTitle } = locationLink.state || {};
  const [userData, setUserData] = useState(null);
  const [rulesetsData, setRulesetsData] = useState(null);
  const [personalConveyance, setPersonalConveyance] = useState(null);
  const [yardMoves, setYardMoves] = useState(null);
  const [accordionExpanded, setAccordionExpanded] = useState(false);
  const can = useCan();
  const mgtTypeIds = mgtTypes.map(t => t.id);
  const filteredSchedules = schedules.filter(
    s => s.entityId === Number(id) && mgtTypeIds?.includes(s?.manageTypeId)
  );
  const userDefinedFields = useDriverManagementFields(userData?.companyId);

  useRedirectToMainFeaturePageOnCompanyChange('/settings/users');

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (pageTitle) {
      dispatch(setPageTitle(pageTitle));
    } else {
      dispatch(setPageTitle(userData?.firstName && `${userData.firstName} ${userData.lastName}`));
    }
    if (pageTitle && userData) {
      const userDataName = `${userData.firstName} ${userData.lastName}`;
      if (pageTitle !== userDataName) {
        dispatch(setPageTitle(userDataName));
      }
    }
  }, [dispatch, pageTitle, userData]);

  useEffect(() => {
    if (userData?.id && can({ everyService: [services.ELD] })) {
      dispatch(fetchELDDrivingConditions(userData.id)).then(
        data => {
          setYardMoves(data?.yardMoves);
          setPersonalConveyance(data?.personalConveyance);
        },
        err => {
          console.error(err);
        }
      );
    }
  }, [dispatch, userData, can]);

  useEffect(() => {
    const { userViewData } = data;
    if (!userViewData) {
      return;
    }
    setUserData(userViewData);
    if (rulesets && userViewData.rulesets) {
      const newRulesets = userViewData?.rulesets
        .map(rule => ({
          ...rule,
          desc: rulesets.find(value => value.name === rule.ruleset)?.desc,
          key: rule.id
        }))
        .sort((a, b) => (a.enabledAt < b.enabledAt ? 1 : -1));
      setRulesetsData(newRulesets);
    }
  }, [data, rulesets]);

  const doRefetch = useCallback(() => {
    setRefetch(prev => prev + 1);
  }, []);

  const driverLicence =
    userData?.licenceState && userData?.licenceNumber
      ? `${userData.licenceState} | ${userData.licenceNumber}`
      : '';

  const driverNotes = userData?.driverNotes?.reduce((driverInfos, info) => {
    const { date, key, value } = info;
    return {
      ...driverInfos,
      [key]: {
        date: date
          ? moment
              .parseZone(date)
              .utcOffset(moment.parseZone(userData.createdAt).utcOffset())
              .format(localization.formats.time.formats.dby_imp)
          : '',
        note: value ?? ''
      }
    };
  }, {});

  // Get the company by the id
  const company = companies.find(comp => comp.id === userData?.companyId);
  const isELDCompany = company?.features?.find(f => f.code === 'ELD') ? true : false;

  let branch;
  if (userData?.location?.id) {
    branch = branches.find(b => b.id === userData.location.id);
  }

  const getTable = data => {
    if (typeof data !== 'undefined') {
      return (
        <Table
          columns={rulesetsViewTableColumns(localization)}
          dataSource={data}
          pagination={false}
        />
      );
    }
  };

  let userRoles;
  if (userData?.roles) {
    userRoles = (userData.roles || []).map((role, i) => {
      // Only show as a link if the user is able to access the role
      const canAccessRole = roles && roles.find(r => r.id === role.id);

      return canAccessRole && canAccessNonCameraFeatures ? (
        <Link to={`${ROLE_VIEW_URL}${role?.id}`} key={`row-${role.id}-${userData.id}-${i}`}>
          {role.name}
        </Link>
      ) : (
        role.name
      );
    });
  }

  const fleetPermissions = (userData?.fleets || []).map((fleet, i) => {
    const companyName = companies.find(company => company.id === fleet?.company?.id)?.name;
    return (
      <div key={`row-${fleet.id}-${userData.id}-${i}`}>
        {fleet?.name} {companyName && `(${companyName})`}
      </div>
    );
  });

  const branchPermissions = useMemo(
    () =>
      (userData?.locations || []).map((location, i) => {
        const companyName =
          !location.id || String(location.id) === String(BRANCH_TYPE.NO_BRANCH)
            ? null
            : companies.find(company => company.id === location?.companyId)?.name;
        return (
          <div key={`row-${location.id}-${userData.id}-${i}`}>
            {location?.name} {companyName && `(${companyName})`}
          </div>
        );
      }),
    [userData, companies]
  );

  if (!userData) {
    return <LoadingTable columnSizes={[400]} />;
  }

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        dispatch(deleteUserApi(userData, history));
        break;
      case 'restore':
        dispatch(restoreUserApi(userData, doRefetch));
        break;
      default:
    }
  };

  const additionDrivingSection = can({ everyService: [services.ELD] }) ? (
    <>
      <div>
        <span>Personal Conveyance: </span>
        <span>{personalConveyance ? 'Yes' : 'No'}</span>
      </div>
      <div>
        <span>Yard Moves: </span>
        <span>{yardMoves ? 'Yes' : 'No'}</span>
      </div>
    </>
  ) : null;

  const renderDriverUserDefinedLabel = userDefinedField => {
    if (!userDefinedField) {
      return `${t('Users.View.UserDefined')}`;
    }
    return userDefinedField;
  };

  const renderDriverInfo = driverDetails => {
    const { date, note } = driverDetails || {};
    const shouldInfoRender = date || note;
    return (
      shouldInfoRender && (
        <Row>
          {date && <Col flex="200px">{date}</Col>}
          {note && <Col>{note}</Col>}
        </Row>
      )
    );
  };

  const handleDocumentDownload = document => () => dispatch(downloadDocument(document.id));

  const renderDocuments = () => {
    const { driverDocs } = userData;
    if (!driverDocs || !driverDocs.length) {
      return;
    }

    return driverDocs.map(doc => (
      <div className={styles.linkStyle} key={doc.id} onClick={handleDocumentDownload(doc)}>
        {doc.filename || doc.id}
      </div>
    ));
  };

  // log in as user
  const handleLogInAsUser = () => {
    dispatch(logInAsUser(userData, fetchPermissions, fetchCompanies, history, can));
  };

  const canLoginAsUser = canLogInAsUser(
    userData,
    loggedInUserInfo,
    isELDCompany,
    hasSiteAdminOrResellerRole
  );

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={{ entityName: AUDIT_ENTITY.USER, ...userData }}
        editPath={`/settings/users/edit/id/${id}`}
        auditPath={`/settings/users/audit/id/${id}`}
        canUse="USER"
        handleButtonAction={handleButtonAction}
        canLogInAsUser={canLoginAsUser}
        handleLogInAsUser={handleLogInAsUser}
        typeOfEntityToDelete={t('Common.user')}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0 32px',
          color: '#181c21'
        }}
        className={styles.userView}
      >
        {userData && (
          <>
            {
              <DeletedEntityAlert
                entity={DeletableEntityType.User}
                entityStatus={userData?.status}
                style={{ marginTop: '16px' }}
              />
            }
            <FormTitle title={t('Users.View.UserInformation')} underlined />
            <InfoRow
              label={t('Users.View.Company')}
              value={company ? company.name : ''}
              styles={styles}
            />
            <InfoRow
              label={t('Users.View.LocationBranch')}
              value={hyperlinkToLocation(
                branch,
                userData,
                LOCATION_VIEW_URL,
                canAccessNonCameraFeatures
              )}
              styles={styles}
            />
            <InfoRow
              label={t('Users.View.Email')}
              value={userData ? userData.email : ''}
              styles={styles}
            />
            <InfoRow
              label={t('Users.View.Username')}
              value={userData ? userData?.username : ''}
              styles={styles}
            />
            <InfoRow
              label={t('Users.View.Mobile')}
              value={userData ? userData.mobile : ''}
              styles={styles}
            />
            <InfoRow
              label={t('Users.View.UserTimeZone')}
              value={
                (newTimezones || []).find(t => t.id === userData.timeZone)?.label ||
                userData.timeZone
              }
              styles={styles}
            />
            <InfoRow
              label={t('Users.View.UserType')}
              value={userData && userData.type ? t(`Users.Form.Type.${userData.type.name}`) : ''}
              styles={styles}
            />
            {userData.type.code && ['DRIVER', 'SUPPORT'].indexOf(userData.type.code) >= 0 && (
              <>
                <InfoRow
                  label={t('Users.Form.PinSet')}
                  value={userData?.externalPin ? t('Common.Yes') : t('Common.No')}
                  styles={styles}
                />
                <InfoRow
                  label={t('Users.Form.PIN')}
                  value={userData.externalPin ? userData.externalPin : ''}
                  styles={styles}
                />
                {userData.type.code === 'DRIVER' && (
                  <>
                    <InfoRow
                      label={t('Users.Form.ElectronicID.Title', { count: '1' })}
                      value={userData.electronicPin ? userData.electronicPin : ''}
                      styles={styles}
                    />
                    <InfoRow
                      label={t('Users.Form.ElectronicID.Title', { count: '2' })}
                      value={userData.electronicPin2 ? userData.electronicPin2 : ''}
                      styles={styles}
                    />
                  </>
                )}
              </>
            )}
            {userData && userData.type && userData.type.id === 5 && (
              <InfoRow
                label={t('Users.Form.ExternalReference')}
                value={userData?.externalReference}
                styles={styles}
              />
            )}
            <InfoRow
              label={t('Users.View.SiteAdmin')}
              value={userData.siteAdmin ? t('Common.Yes') : t('Common.No')}
              styles={styles}
            />
            <InfoRow
              label={t('Users.View.Path')}
              value={userData.path ? userData.path : ''}
              styles={styles}
            />
            <InfoRow label={t('Users.View.VNC')} value={'-'} styles={styles} />

            <FormTitle title={t('Users.View.Photos')} underlined />
            <InfoRow
              label={
                isLoadingEmbed ? (
                  <Spin size="large" />
                ) : (
                  <ImageUpload disabled round imageUrl={userData.imageUrl} />
                )
              }
              styles={styles}
            />

            <FormTitle title={t('Users.View.RolesAndAccessPermissions')} underlined />
            <InfoRow
              label={t('Users.View.Roles')}
              value={userRoles}
              styles={styles}
              delimiter={', '}
            />
            <InfoRow
              label={t('Users.View.Fleet')}
              value={fleetPermissions?.length ? fleetPermissions : t('Common.AllFleets')}
              styles={styles}
              isLoading={isLoadingEmbed}
            />
            <InfoRow
              label={t('Users.View.Branch')}
              value={branchPermissions?.length ? branchPermissions : t('Common.AllBranches')}
              styles={styles}
              isLoading={isLoadingEmbed}
            />

            <FormTitle title={t('Users.View.Status')} underlined />
            <InfoRow
              label={t('Users.View.LastSignIn')}
              value={
                userData?.userSession?.lastSignInAt
                  ? format(
                      new Date(userData.userSession?.lastSignInAt),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
              isLoading={isLoadingEmbed}
            />
            <InfoRow
              label={t('Users.View.LastSignInIpAddress')}
              value={userData?.userSession?.lastSignInIP ? userData?.userSession?.lastSignInIP : ''}
              styles={styles}
              isLoading={isLoadingEmbed}
            />
            <InfoRow
              label={t('Users.View.SignInCount')}
              value={userData?.userSession?.signInCount}
              styles={styles}
              isLoading={isLoadingEmbed}
            />
            <InfoRow
              label={t('Users.View.LastDriverEvent')}
              value={
                userData?.userSession?.lastFatigueEventAt
                  ? format(
                      new Date(userData.userSession?.lastFatigueEventAt),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <LoggedInVehiclesRow
              userData={userData}
              styles={styles}
              refetchUserData={doRefetch}
              isLoading={isLoadingEmbed}
            />
            <InfoRow
              label={t('Users.View.ExternalID')}
              value={userData.externalId ? userData.externalId : ''}
              styles={styles}
            />
            <InfoRow label={t('Users.View.SyncInAt')} value={'-'} styles={styles} />
            <InfoRow
              label={t('Users.View.CreatedAt')}
              value={
                userData.createdAt
                  ? format(new Date(userData.createdAt), localization.formats.time.formats.dby_imp)
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={t('Users.View.UpdatedAt')}
              value={
                userData.updatedAt
                  ? format(new Date(userData.updatedAt), localization.formats.time.formats.dby_imp)
                  : ''
              }
              styles={styles}
            />

            {userData.type.code && ['DRIVER', 'SUPPORT'].indexOf(userData.type.code) >= 0 && (
              <>
                {userData.type.code === 'DRIVER' && (
                  <FormTitle title={t('Users.View.Driver')} underlined />
                )}
                {userData.type.code === 'SUPPORT' && (
                  <FormTitle title="Support Personnel" underlined />
                )}
                {can({ everyService: [services.ELD] }) && (
                  <>
                    <InfoRow
                      label="Licence Country"
                      value={userData?.licenceCountry || ''}
                      styles={styles}
                    />
                  </>
                )}
                <InfoRow
                  label={t('Users.View.DriverLicense')}
                  value={driverLicence || ''}
                  styles={styles}
                />

                {userData.type.code === 'DRIVER' && (
                  <>
                    {can({
                      everyService: [services.ELD],
                      everyCompanyService: [services.ELD]
                    }) && (
                      <InfoRow
                        label="Additional Driving Conditions"
                        value={additionDrivingSection}
                        styles={styles}
                      />
                    )}
                    <InfoRow
                      label={`${t('Users.View.DriversLicence')}`}
                      value={renderDriverInfo(driverNotes?.licence_expiry)}
                      styles={styles}
                      isLoading={isLoadingEmbed}
                    />
                    <InfoRow
                      label={`${t('Users.View.HealthCheck')}`}
                      value={renderDriverInfo(driverNotes?.health_check_expiry)}
                      styles={styles}
                      isLoading={isLoadingEmbed}
                    />
                    <InfoRow
                      label={renderDriverUserDefinedLabel(userDefinedFields?.field1)}
                      value={renderDriverInfo(driverNotes?.field1)}
                      styles={styles}
                      isLoading={isLoadingEmbed}
                    />
                    <InfoRow
                      label={renderDriverUserDefinedLabel(userDefinedFields?.field2)}
                      value={renderDriverInfo(driverNotes?.field2)}
                      styles={styles}
                      isLoading={isLoadingEmbed}
                    />
                    <InfoRow
                      label={renderDriverUserDefinedLabel(userDefinedFields?.field3)}
                      value={renderDriverInfo(driverNotes?.field3)}
                      styles={styles}
                      isLoading={isLoadingEmbed}
                    />
                    <InfoRow
                      label={`${t('Users.View.Notes')}`}
                      value={renderDriverInfo(driverNotes?.driver_notes)}
                      styles={styles}
                      isLoading={isLoadingEmbed}
                    />
                    <InfoRow
                      label={`${t('Users.View.Document')}`}
                      value={renderDocuments()}
                      styles={styles}
                      isLoading={isLoadingEmbed}
                    />
                    <InfoRow
                      value={getTable(rulesetsData)}
                      label={t('Users.View.Rulesets')}
                      styles={styles}
                      sxValue={{
                        width: '75%',
                        minHeight: `${userData.rulesets ? userData.rulesets.length * 64 + 32 : 0}px`
                      }}
                    />
                  </>
                )}
                {userData.type.code === 'SUPPORT' &&
                  can({
                    everyService: [services.ELD],
                    everyCompanyService: [services.ELD]
                  }) && (
                    <InfoRow
                      label="Exempt Reason"
                      value={userData?.sentinelExemptionNotes}
                      styles={styles}
                    />
                  )}
              </>
            )}
            {userData.type?.name === USER_TYPES.DRIVER && (
              <Accordion
                title={t('DriverManagement.Title')}
                body={
                  <Table
                    dataSource={prepareDataForInnerTable({
                      schedules: filteredSchedules,
                      users,
                      mgtTypes,
                      localization,
                      history,
                      can,
                      t,
                      dispatch
                    })}
                    tableLayout="fixed"
                    columns={prepareColumnsForInnerTable(t)}
                    pagination={false}
                    sticky={true}
                  />
                }
                icon={accordionExpanded}
                onClick={() => setAccordionExpanded(!accordionExpanded)}
              />
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};

const CurrentVehicleRow = ({ vehicle, onVehicleLogedOff }) => {
  const onForceLogoff = useCallback(
    loggedOff => {
      if (loggedOff && onVehicleLogedOff) {
        onVehicleLogedOff();
      }
    },
    [onVehicleLogedOff]
  );
  const { canForceLoggoff, ForceLogoffBtn } = useForceLogoff(
    vehicle.driver,
    vehicle,
    entities.USER,
    onForceLogoff
  );
  return <span>{canForceLoggoff && <ForceLogoffBtn />}</span>;
};

const LoggedInVehiclesRow = ({ userData, styles, refetchUserData, isLoading }) => {
  const { t } = useTranslation();
  const can = useCan();
  const { isDriver, currentVehicles } = useMemo(
    () => ({
      isDriver: isDriverType(userData),
      currentVehicles:
        userData?.currentVehicles?.map(loggedVehicle => ({
          ...loggedVehicle,
          driver: userData,
          key: loggedVehicle.id
        })) || []
    }),
    [userData]
  );

  const columns = useMemo(
    () => [
      {
        title: `${t('Tracking.VehicleName')}`,
        key: 'name',
        dataIndex: 'name',
        render: (text, record) => (
          <span>
            {record?.id ? (
              <>
                <span>
                  <Link to={`/settings/vehicles/id/${record.id}`}>{text}</Link>
                </span>
                {record?.[DEFAULT_DRIVER_FORM_FIELD_NAME] && (
                  <span className={defaultDriverStyles.boldDefault}>
                    {t('Vehicles.Form.DriverMng.DefaultDriver')}
                  </span>
                )}
              </>
            ) : (
              '-'
            )}
          </span>
        )
      },
      {
        title: `${t('Common.TableColumns.Actions')}`,
        key: 'action',
        render: record => <CurrentVehicleRow vehicle={record} onVehicleLogedOff={refetchUserData} />
      }
    ],
    [t, refetchUserData]
  );
  const tableDataSource = useMemo(() => {
    const canVehicleView = can({
      everyEntity: [entities.VEHICLE_VIEW]
    });
    return canVehicleView && currentVehicles?.length ? currentVehicles : [{ name: '', key: '' }];
  }, [currentVehicles]);

  const ForceLogoffTable = useMemo(() => {
    return <Table size="small" columns={columns} dataSource={tableDataSource} pagination={false} />;
  }, [tableDataSource]);
  return isDriver ? (
    <InfoRow
      label={t('Users.LoggedInVehicles')}
      value={ForceLogoffTable}
      styles={styles}
      sxValue={{ width: '75%' }}
      isLoading={isLoading}
    />
  ) : null;
};
