import { clone, toLower } from 'lodash';

export const initializeHierarchyStructure = (hierarchy, parentNode = null) => {
  hierarchy.forEach(child => {
    child.parent = parentNode;
    child.documentIds = [];
    initializeHierarchyStructure(child.treeData, child);
  });
};

export const getCheckedKeysForDocument = (document, hierarchy) => {
  const checkedKeysForDoc = [];
  document.associations.forEach(assoc => {
    if (assoc.fleetId === -1) {
      checkedKeysForDoc.push(`1fleet-No Fleet`);
      hierarchy
        .find(h => h.key === '1fleet-No Fleet')
        ?.treeData.forEach(child => {
          checkedKeysForDoc.push(child.key);
        });
    } else if (assoc.fleetId) {
      checkedKeysForDoc.push(`1fleet-${assoc.fleetId}`);
      hierarchy
        .find(h => h.key === `1fleet-${assoc.fleetId}`)
        ?.treeData.forEach(child => {
          checkedKeysForDoc.push(child.key);
        });
    } else if (assoc.vehicleId) {
      for (let h of hierarchy) {
        const vehicleInfo = h.treeData.find(child => {
          const vehicleKey = child.key.split('-')[2];
          return parseInt(vehicleKey) === assoc.vehicleId;
        });
        if (vehicleInfo) {
          checkedKeysForDoc.push(vehicleInfo.key);
          break;
        }
      }
    } else if (assoc.deviceId) {
      for (let h of hierarchy) {
        const vehicleInfo = h.treeData.find(child => {
          const vehicleKey = child.key.split('-')[2];
          return parseInt(vehicleKey) === assoc.deviceId;
        });
        if (vehicleInfo) {
          checkedKeysForDoc.push(vehicleInfo.key);
          break;
        }
      }
    }
  });
  return checkedKeysForDoc;
};

export const initializeDocumentIds = (hierarchy, checkedKeys, documentId) => {
  hierarchy.forEach(h => {
    if (checkedKeys.includes(h.key)) {
      h.documentIds = h.documentIds ? [...h.documentIds, documentId] : [documentId];
    }
    if (h.treeData.length) {
      initializeDocumentIds(h.treeData, checkedKeys, documentId);
    }
  });
};

export const initializeCheckedStatus = (hierarchy, documentLength) => {
  hierarchy.forEach(h => {
    h.checked = false;
    h.halfChecked = false;
    if (h.treeData.length) {
      initializeCheckedStatus(h.treeData, documentLength);
      if (h.treeData.every(child => child.checked)) {
        h.checked = true;
      } else if (h.treeData.some(child => child.checked || child.halfChecked)) {
        h.halfChecked = true;
      }
    } else {
      if (h.documentIds?.length === documentLength) {
        h.checked = true;
      } else if (h.documentIds?.length) {
        h.halfChecked = true;
      }
    }
  });
};

const getChangedElements = (arr1, arr2) => {
  const removedElements = [];
  const appendedElements = [];

  for (let element of arr1) {
    if (!arr2.includes(element)) {
      removedElements.push(element);
    }
  }

  for (let element of arr2) {
    if (!arr1.includes(element)) {
      appendedElements.push(element);
    }
  }

  return {
    removed: removedElements,
    appended: appendedElements
  };
};

export const getChangedKeys = (prev, current) => {
  const { checked: prevChecked, halfChecked: prevHalfChecked } = prev;
  const { checked: currentChecked, halfChecked: currentHalfChecked } = current;

  return {
    checkedChangedKeys: getChangedElements(prevChecked, currentChecked),
    halfCheckedChangedKeys: getChangedElements(prevHalfChecked, currentHalfChecked)
  };
};

export const traverseHierarchy = (hierarchy, changedKeys, documentIds) => {
  const { checkedChangedKeys, halfCheckedChangedKeys } = changedKeys;
  hierarchy.forEach(h => {
    if (halfCheckedChangedKeys.appended.includes(h.key)) {
      updateChildNodes(h, false, true, documentIds);
    } else if (halfCheckedChangedKeys.removed.includes(h.key)) {
      updateChildNodes(h, false, false, documentIds);
    } else if (checkedChangedKeys.appended.includes(h.key)) {
      updateChildNodes(h, true, false, documentIds);
    } else if (checkedChangedKeys.removed.includes(h.key)) {
      updateChildNodes(h, false, false, documentIds);
    }

    if (h.treeData.length) {
      traverseHierarchy(h.treeData, changedKeys, documentIds);
    }

    updateParentNodes(h, documentIds);
  });
};

const updateChildNodes = (node, checked, halfChecked, documentIds) => {
  node.checked = checked;
  node.halfChecked = halfChecked;
  if (checked) {
    node.documentIds = documentIds;
  } else {
    node.documentIds = [];
  }

  if (!halfChecked) {
    for (let child of node.treeData) {
      updateChildNodes(child, checked, false);
    }
  }
};

const updateParentNodes = (node, documentIds) => {
  if (node.treeData.length === 0) {
    return;
  }

  const checked = node.treeData.every(child => child.checked);
  const halfChecked = !checked && node.treeData.some(child => child.checked || child.halfChecked);
  node.checked = checked;
  node.halfChecked = halfChecked;

  if (checked) {
    node.documentIds = documentIds;
  } else if (!checked && !halfChecked) {
    node.documentIds = [];
  } else if (halfChecked) {
    node.documentIds = documentIds.filter(id =>
      node.treeData.every(child => child.documentIds?.includes(id))
    );
  }

  if (node.parent) {
    updateParentNodes(node.parent);
  }
};

const SELECTION = {
  FLEET: '1fleet',
  VEHICLE: '2vehicle',
  DEVICE: '3device'
};

const getKeyMapping = key => {
  const types = key.split('-');
  const [selectionType, fleetId, deviceOrVehicleId] = types;

  if (selectionType === SELECTION.FLEET) {
    return ['fleetId', fleetId === 'No Fleet' ? -1 : fleetId];
  }

  if (selectionType === SELECTION.VEHICLE) {
    return ['vehicleId', deviceOrVehicleId];
  }

  if (selectionType === SELECTION.DEVICE) {
    return ['deviceId', deviceOrVehicleId];
  }
};

export const generateAssociations = (document, hierarchy) => {
  const association = [];

  const traverse = hierarchy => {
    hierarchy.forEach(h => {
      if (h.checked || (h.halfChecked && h.documentIds?.includes(document.id))) {
        const keyMapping = getKeyMapping(h.key);
        association.push({ documentId: document.id, [keyMapping[0]]: keyMapping[1] });
      } else if (h.halfChecked) {
        // Recursive only if upper level not checked
        traverse(h.treeData);
      }
    });
  };
  traverse(hierarchy);

  if (association.length === 0) {
    association.push({ documentId: document.id });
  }
  return association;
};

export const getCountsFromCheckedKeys = checkedKeys => {
  let fleetsCount = 0;
  let vehicleDeviceCount = 0;
  let deviceIdMap = {};
  let vehicleIdMap = {};

  if (checkedKeys && checkedKeys.checked) {
    checkedKeys.checked.forEach(key => {
      const types = key.split('-');
      const [selectionType, fleetId, deviceOrVehicleId] = types;

      if (selectionType === SELECTION.FLEET) {
        fleetsCount++;
      }
      if (selectionType === SELECTION.VEHICLE) {
        // Only count each unique vehicle Id once
        if (!vehicleIdMap[deviceOrVehicleId]) {
          vehicleDeviceCount++;
          vehicleIdMap[deviceOrVehicleId] = true;
        }
      }
      if (selectionType === SELECTION.DEVICE) {
        // Only count each unique device Id once
        if (!deviceIdMap[deviceOrVehicleId]) {
          vehicleDeviceCount++;
          deviceIdMap[deviceOrVehicleId] = true;
        }
      }
    });
  }

  return { fleetsCount, vehicleDeviceCount };
};

export const getFilteredHierarchy = (hierarchy, searchText) => {
  let filteredHierarchy = [];

  if (!searchText) {
    return hierarchy;
  }

  if (hierarchy) {
    const filteredFleets = hierarchy.filter(fleet => {
      // Keep fleet if its name matches search or if at least one child vehicle/device matches search
      const fleetNameMatch = toLower(fleet.title).indexOf(toLower(searchText)) > -1;
      const vehicleOrDeviceNameMatch =
        fleet.treeData &&
        fleet.treeData.find(item => toLower(item.title).indexOf(toLower(searchText)) > -1);

      return fleetNameMatch || vehicleOrDeviceNameMatch;
    });

    // Filter out child vehicles and devices by name
    filteredFleets.forEach(fleet => {
      const fleetClone = clone(fleet);
      fleetClone.treeData = [];
      fleet.treeData.forEach(item => {
        if (toLower(item.title).indexOf(toLower(searchText)) > -1) {
          const itemClone = clone(item);
          fleetClone.treeData.push(itemClone);
        }
      });

      filteredHierarchy.push(fleetClone);
    });
  }

  return filteredHierarchy;
};
