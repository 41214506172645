import React, { useEffect, useState, useRef, useCallback, useMemo, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18nextConfig';
import { List, Tag, Button } from 'antd';
import _ from 'lodash';

import { useUser } from 'features/user/userSlice';
import { useDevices } from 'features/fleets/fleetsSlice.js';
import { services, GlobalRoles, companyFeatures } from 'features/permissions';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { AuthUtil } from 'features/auth/authUtil';
import { useCurrentCompany, useCurrentCompanyServices } from 'features/company/companySlice';
import { useServicePermissons } from 'features/permissions/permissionsSlice';
//import { useCurrentCompanyServices } from 'features/company/companySlice';

import { Comparators } from 'utils/sorting';

import ReportCard from 'containers/InsightsQS/Cards/ReportCard';
import { SortOrders } from 'containers/InsightsQS/constants';
import styles from 'containers/InsightsQS/Pages/pages.module.scss';
import cardStyles from 'containers/InsightsQS/Cards/cards.module.scss';
import {
  ReportsPageToolbar,
  isReportCategory,
  getReportCategoryLabels,
  getReportCategoryLabel
} from 'containers/InsightsQS/Toolbars/ReportsPageToolbar';

import { Grid } from 'components/tn';
import { Iframe } from 'components/iframe/Iframe';

import {
  useGetUserConfigByPrefQuery,
  useUpdateUserConfigByPrefMutation
} from 'services/nextgen/ngUserPrefConfigApi';

function useNotTnDevice() {
  const devices = useDevices();
  const companyConfigs = useSelector(state => state.permissions.companyConfig);
  const types = ['Qube', 'Qtanium', 'TM'];
  if (typeof companyConfigs === 'undefined' || companyConfigs['director_qube_customer'] === null) {
    return devices.some(
      device => types.some(t => t === device.type.name) && device.status === 'ENABLED'
    );
  }
  return companyConfigs['director_qube_customer'] === '1';
}

const ReportMaps = [
  {
    reportId: 'agreement',
    name: 'Agreement',
    type: 'Ng::Report::Agreement',
    description: 'AgreementDescription',
    link: '/ng/reports/agreement',
    requireServices: [services.COMMON],
    requireRoles: [GlobalRoles.SiteAdmin],
    category: 'General',
    Id: 1
  },
  {
    reportId: 'alarm',
    name: 'Alarm',
    type: 'Ng::Report::Alarm',
    description: 'AlarmDescription',
    link: '/ng/reports/alarm',
    requireServices: [services.COMMON, services.IAP, services.SWD, services.EWD, services.EDL],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    category: 'General',
    Id: 2
  },
  {
    reportId: 'alert_notification',
    name: 'Alert Notification',
    type: 'Ng::Report::AlertNotification',
    description: 'Alert NotificationDescription',
    link: '/ng/reports/alert_notification',
    requireServices: [services.COMMON],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    category: 'General',
    Id: 3
  },
  {
    reportId: 'automated_mass',
    name: 'Automated Mass',
    type: 'Ng::Report::AutomatedMass',
    description: 'Automated MassDescription',
    link: '/ng/reports/automated_mass',
    requireServices: [services.OBM],
    category: 'General',
    Id: 4
  },
  {
    reportId: 'callmate',
    name: 'CallMate Usage',
    type: 'Ng::Report::Callmate',
    description: 'CallMate UsageDescription',
    link: '/ng/reports/callmate',
    requireServices: [services.VOIP],
    category: 'General',
    Id: 5
  },
  {
    reportId: 'camera',
    name: 'Camera Event',
    type: 'Ng::Report::Camera',
    description: 'Camera EventDescription',
    link: '/ng/reports/camera',
    requireServices: [services.CAMERA],
    category: 'General',
    Id: 6
  },
  {
    reportId: 'data_pack_usage',
    name: 'Data Pack Usage',
    type: 'Ng::Report::DataPackUsage',
    description: 'Data Pack UsageDescription',
    link: '/ng/reports/data_pack_usage',
    requireServices: [services.COMMON],
    requireRoles: [GlobalRoles.SiteAdmin],
    category: 'General',
    Id: 7
  },
  {
    reportId: 'distance_trip',
    name: 'Distance Trip',
    type: 'Ng::Report::DistanceTrip',
    description: 'Distance TripDescription',
    link: '/ng/reports/distance_trip',
    requireServices: [services.TRACKING],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    featureHide: companyFeatures.HTF,
    category: 'General',
    Id: 8
  },
  {
    reportId: 'distance_trip_detail',
    name: 'Distance Trip Detail',
    type: 'Ng::Report::DistanceTripDetail',
    description: 'Distance Trip DetailDescription',
    link: '/ng/reports/distance_trip_detail',
    requireServices: [services.TRACKING],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    featureHide: companyFeatures.HTF,
    category: 'General',
    Id: 9
  },
  {
    reportId: 'driver_action',
    name: 'Driver Action',
    type: 'Ng::Report::DriverAction',
    description: 'Driver ActionDescription',
    link: '/ng/reports/driver_action',
    requireServices: [
      services.COMMON,
      services.QUICKJOBS,
      services.SWD,
      services.EWD,
      services.EDL,
      services.MASS,
      services.PRETRIP,
      services.TACHO
    ],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    category: 'General',
    Id: 10
  },
  {
    reportId: 'driver_management_summary',
    name: 'Driver Management Summary',
    type: 'Ng::Report::DriverManagementSummary',
    description: 'Driver Management SummaryDescription',
    link: '/ng/reports/driver_management_summary',
    requireServices: [services.DMGT],
    category: 'General',
    Id: 11
  },
  {
    reportId: 'driver_summary',
    name: 'Driver Summary',
    type: 'Ng::Report::DriverSummary',
    description: 'Driver SummaryDescription',
    link: '/ng/reports/driver_summary',
    requireServices: [services.DMGT],
    category: 'General',
    Id: 12
  },
  {
    reportId: 'duress',
    name: 'Duress',
    type: 'Ng::Report::Duress',
    description: 'DuressDescription',
    link: '/ng/reports/duress',
    requireServices: [services.TRACKING],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    featureHide: companyFeatures.HTF,
    category: 'General',
    Id: 13
  },
  {
    reportId: 'form',
    name: 'Form',
    type: 'Ng::Report::Form',
    description: 'FormDescription',
    link: '/ng/reports/form',
    requireServices: [services.FORM],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    category: 'General',
    Id: 14
  },
  {
    reportId: 'gpio',
    name: 'GPIO',
    type: 'Ng::Report::GPIO',
    description: 'GpioDescription',
    link: '/ng/reports/gpio',
    requireServices: [services.TRACKING, services.GPIO],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    featureHide: companyFeatures.HTF,
    category: 'General',
    Id: 15
  },
  {
    reportId: 'iap_mass_declaration',
    name: 'IAP Mass Declaration',
    type: 'Ng::Report::IapMassDeclaration',
    description: 'IAP Mass DeclarationDescription',
    link: '/ng/reports/iap_mass_declaration',
    requireServices: [services.IAP],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    category: 'General',
    Id: 16
  },
  {
    reportId: 'iap_comment_declaration',
    name: 'IAP Comment Declaration',
    type: 'Ng::Report::IapCommentDeclaration',
    description: 'IAP Comment DeclarationDescription',
    link: '/ng/reports/iap_comment_declaration',
    requireServices: [services.IAP],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    category: 'General',
    Id: 17
  },
  {
    reportId: 'last_contact',
    name: 'Last Contact',
    type: 'Ng::Report::LastContact',
    description: 'Last ContactDescription',
    link: '/ng/reports/last_contact',
    requireServices: [services.COMMON],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    category: 'General',
    Id: 18
  },
  {
    reportId: 'message_history',
    name: 'Message History',
    type: 'Ng::Report::MessageHistory',
    description: 'Message HistoryDescription',
    link: '/ng/reports/message_history',
    requireServices: [services.MESSAGING],
    category: 'General',
    Id: 19
  },
  {
    reportId: 'movement',
    name: 'Movement',
    type: 'Ng::Report::Movement',
    description: 'MovementDescription',
    link: '/ng/reports/movement',
    tooltip: 'NotAvailableForAllDevices',
    requireServices: [services.TRACKING],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    featureHide: companyFeatures.HTF,
    category: 'General',
    Id: 20
  },
  {
    reportId: 'out_of_hours',
    name: 'OutOfHours',
    type: 'Ng::Report::OutOfHours',
    description: 'OutOfHoursDescription',
    link: '/ng/reports/out_of_hours',
    requireServices: [services.TRACKING],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    featureHide: companyFeatures.HTF,
    category: 'General',
    Id: 21
  },
  {
    reportId: 'road_network_usage',
    name: 'Road Network Usage',
    type: 'Ng::Report::RoadNetworkUsage',
    description: 'Road Network UsageDescription',
    link: '/ng/reports/road_network_usage',
    requireServices: [services.TOLL],
    category: 'General',
    Id: 22
  },
  {
    reportId: 'ruc_distance',
    name: 'RUC Distance',
    type: 'Ng::Report::RucDistance',
    description: 'RUC DistanceDescription',
    link: '/ng/reports/ruc_distance',
    requireServices: [services.RUC],
    category: 'General',
    Id: 23
  },
  {
    reportId: 'scorecard_progressive_trend',
    name: 'Scorecard Progressive Trend',
    type: 'Ng::Report::ScorecardProgressiveTrend',
    description: 'Scorecard Progressive Trend Description',
    link: '/ng/reports/scorecard_progressive_trend',
    requireServices: [services.SCORECARD],
    featureHide: companyFeatures.HTF,
    category: 'General',
    Id: 24
  } /*
  {
    reportId: 'scorecard_summary',
    name: 'Scorecard Summary',
    type: 'Ng::Report::ScorecardSummary',
    description: 'Scorecard Summary Description',
    link: '/ng/reports/scorecard_summary',
    requireServices: [services.SCORECARD],
    featureHide: companyFeatures.HTF,
    category: 'General',
    Id: 24
  },*/,
  {
    reportId: 'security_events_audit',
    name: 'Security Events Audit',
    type: 'Ng::Report::SecurityEventsAudit',
    description: 'Security Events AuditDescription',
    link: '/ng/reports/security_events_audit',
    requireServices: [services.COMMON],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    category: 'General',
    Id: 25
  },
  {
    reportId: 'state_mileage',
    name: 'State Mileage',
    type: 'Ng::Report::StateMileage',
    description: 'State MileageDescription',
    link: '/ng/reports/state_mileage',
    requireServices: [services.TRACKING],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    category: 'General',
    Id: 26
  },
  {
    reportId: 'timesheet',
    name: 'Timesheet',
    type: 'Ng::Report::Timesheet',
    description: 'Timesheet Report Description',
    link: '/ng/reports/timesheet',
    requireServices: [services.TRACKING],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    category: 'General',
    Id: 27
  },
  {
    reportId: 'vehicle_activity_redshift',
    name: 'Vehicle Activity',
    type: 'Ng::Report::VehicleActivityRedshift',
    description: 'Vehicle ActivityDescription',
    link: '/ng/reports/vehicle_activity_redshift',
    requireServices: [services.TRACKING],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    featureHide: companyFeatures.HTF,
    category: 'General',
    Id: 28
  },
  {
    reportId: 'vehicle_activity',
    name: 'Vehicle Activity legacy',
    type: 'Ng::Report::VehicleActivity',
    description: 'Vehicle Activity legacyDescription',
    link: '/ng/reports/vehicle_activity',
    requireServices: [services.TRACKING],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    featureHide: companyFeatures.HTF,
    category: 'General',
    Id: 28
  },
  {
    reportId: 'vehicle_daily_summary',
    name: 'Vehicle Daily Summary',
    type: 'Ng::Report::VehicleDailySummary',
    description: 'Vehicle Daily SummaryDescription',
    link: '/ng/reports/vehicle_daily_summary',
    requireServices: [services.TRACKING],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    featureHide: companyFeatures.HTF,
    category: 'General',
    Id: 29
  },
  {
    reportId: 'user_alert',
    name: 'User Alert Subscription',
    type: 'Ng::Report::UserAlert',
    description: 'User Alert SubscriptionDescription',
    link: '/ng/reports/user_alert',
    requireServices: [services.COMMON],
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    category: 'General',
    Id: 30
  },
  {
    reportId: 'compliances',
    name: 'Compliance Management',
    type: 'Ng::Report::ComplianceManagement',
    description: 'Compliance ManagementDescription',
    link: '/ng/reports/compliances',
    requireServices: [services.COMPMGMT],
    category: 'Compliance',
    Id: 31,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'dvir',
    name: 'DVIR',
    type: 'Ng::Report::Dvir',
    description: 'DVIR Description',
    link: '/ng/reports/dvir',
    requireServices: [services.DVIR],
    category: 'Compliance',
    Id: 32,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'mass_manager',
    name: 'Mass Manager',
    type: 'Ng::Report::MassManager',
    description: 'Mass ManagerDescription',
    link: '/ng/reports/mass_manager',
    requireServices: [services.MASS],
    category: 'Compliance',
    Id: 33,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'route_compliance_completed',
    name: 'Completed Route Compliance',
    type: 'Ng::Report::RouteComplianceCompleted',
    description: 'Completed Route ComplianceDescription',
    link: '/ng/reports/route_compliance_completed',
    requireServices: [services.ROUTECOMPLIANCE],
    category: 'Compliance',
    Id: 34,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'route_compliance_breach',
    name: 'Route Compliance Breach',
    type: 'Ng::Report::RouteComplianceBreach',
    description: 'Route Compliance BreachDescription',
    link: '/ng/reports/route_compliance_breach',
    requireServices: [services.ROUTECOMPLIANCE],
    category: 'Compliance',
    Id: 35,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'route_compliance_mass',
    name: 'Route Compliance Mass',
    type: 'Ng::Report::RouteComplianceMass',
    description: 'Route Compliance MassDescription',
    link: '/ng/reports/route_compliance_mass',
    requireServices: [services.ROUTECOMPLIANCE, services.MASS],
    category: 'Compliance',
    Id: 36,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator],
    hide: [services.ROUTECOMPLIANCE, services.MASS]
  },
  {
    reportId: 'pretrip_checklist',
    name: 'Pre-Trip Checklist',
    type: 'Ng::Report::PretripChecklist',
    description: 'Pre-Trip ChecklistDescription',
    link: '/ng/reports/pretrip_checklist',
    requireServices: [services.PRETRIP],
    category: 'Compliance',
    Id: 37,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'speed',
    name: 'Speed Event Management',
    type: 'Ng::Report::Speed',
    description: 'Speed Event ManagementDescription',
    link: '/ng/reports/speed',
    requireServices: [services.SPEED],
    category: 'Compliance',
    Id: 38,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'fatigue_violation',
    name: 'Fatigue Violation',
    type: 'Ng::Report::FatigueViolation',
    description: 'Fatigue ViolationDescription',
    link: '/ng/reports/fatigue_violation',
    requireServices: [services.SWD, services.EDL],
    category: 'Sentinel',
    Id: 39,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'fatigue_management_summary',
    name: 'Fatigue Management Summary',
    type: 'Ng::Report::FatigueManagementSummary',
    description: 'Fatigue Management SummaryDescription',
    link: '/ng/reports/fatigue_management_summary',
    requireServices: [services.SWD, services.EDL],
    category: 'Sentinel',
    Id: 40,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'driver_daily_summary',
    name: 'Driver Daily Summary',
    type: 'Ng::Report::DriverDailySummary',
    description: 'Driver Daily SummaryDescription',
    link: '/ng/reports/driver_daily_summary',
    requireServices: [services.SWD, services.EDL],
    category: 'Sentinel',
    Id: 41,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'driver_action_audit',
    name: 'Driver Action Audit',
    type: 'Ng::Report::DriverActionAudit',
    description: 'Driver Action AuditDescription',
    link: '/ng/reports/driver_action_audit',
    requireServices: [services.SWD, services.EDL],
    category: 'Sentinel',
    Id: 42,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'log_book_check',
    name: 'Log Book Check',
    type: 'Ng::Report::LogBookCheck',
    description: 'Log Book CheckDescription',
    link: '/ng/reports/log_book_check',
    requireServices: [services.SWD, services.EDL],
    category: 'Sentinel',
    Id: 43,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'driver_hours_distance',
    name: 'Driver Hours/Distance',
    type: 'Ng::Report::DriverHoursDistance',
    description: 'Driver Hours/DistanceDescription',
    link: '/ng/reports/driver_hours_distance',
    requireServices: [services.SWD, services.EDL],
    category: 'Sentinel',
    Id: 44,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'enforcement_request',
    name: 'Enforcement Request',
    type: 'Ng::Report::FatigueSubmit',
    description: 'Enforcement RequestDescription',
    link: '/ng/reports/enforcement_request',
    requireServices: [services.EDL],
    category: 'Sentinel',
    Id: 45,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'driver_hos_available_hours',
    name: 'Driver HOS Available Hours',
    type: 'Ng::Report::DriverHosAvailableHours',
    description: 'Driver HOS Available Hours Description',
    link: '/ng/reports/driver_hos_available_hours',
    requireServices: [services.ELD, services.ELDUS, services.ELDCAN],
    category: 'ELDFatigue',
    Id: 46
  },
  {
    reportId: 'driver_hos_violation',
    name: 'Driver HOS Violation',
    type: 'Ng::Report::DriverHosViolation',
    description: 'Driver HOS Violation Description',
    link: '/ng/reports/driver_hos_violation',
    requireServices: [services.ELD, services.ELDUS, services.ELDCAN],
    category: 'ELDFatigue',
    Id: 47
  },
  {
    reportId: 'duty_status_summary',
    name: 'Duty Status Summary',
    type: 'Ng::Report::DutyStatusSummary',
    description: 'Duty Status Summary Description',
    link: '/ng/reports/duty_status_summary',
    requireServices: [services.ELD, services.ELDUS, services.ELDCAN],
    category: 'ELDFatigue',
    Id: 48
  },
  {
    reportId: 'hos_compliance',
    name: 'HOS Compliance (US)',
    type: 'Ng::Report::HosCompliance',
    description: 'HOS Compliance Description (US)',
    link: '/ng/reports/hos_compliance',
    requireServices: [services.ELDUS],
    category: 'ELDFatigue',
    Id: 49
  },
  {
    reportId: 'hos_compliance_ca',
    name: 'HOS Compliance (Canada)',
    type: 'Ng::Report::HosComplianceCa',
    description: 'HOS Compliance Description (Canada)',
    link: '/ng/reports/hos_compliance_ca',
    requireServices: [services.ELDCAN],
    category: 'ELDFatigue',
    Id: 50
  },
  {
    reportId: 'log_edit_history',
    name: 'Log Edit History',
    type: 'Ng::Report::LogEditHistory',
    description: 'Log Edit History Description',
    link: '/ng/reports/log_edit_history',
    requireServices: [services.ELD, services.ELDUS, services.ELDCAN],
    category: 'ELDFatigue',
    Id: 51
  },
  {
    reportId: 'unassigned_events_chain_of_custody',
    name: 'Unassigned Events Chain of Custody',
    type: 'Ng::Report::UnassignedEventsChainOfCustody',
    description: 'Unassigned Events Chain of CustodyDescription',
    link: '/ng/reports/unassigned_events_chain_of_custody',
    requireServices: [services.ELD],
    category: 'ELDFatigue',
    Id: 52
  },
  {
    reportId: 'unsigned_logs',
    name: 'Unsigned Logs',
    type: 'Ng::Report::UnsignedLogs',
    description: 'Unsigned Logs Description',
    link: '/ng/reports/unsigned_logs',
    requireServices: [services.ELD, services.ELDUS, services.ELDCAN],
    category: 'ELDFatigue',
    Id: 53
  },
  {
    reportId: 'ewd_fatigue_violation',
    name: 'EWD Fatigue Violation',
    type: 'Ng::Report::EwdFatigueViolation',
    description: 'EWD Fatigue ViolationDescription',
    link: '/ng/reports/ewd_fatigue_violation',
    requireServices: [services.EWD],
    category: 'EWD',
    Id: 54
  },
  {
    reportId: 'ewd_fatigue_management_summary',
    name: 'EWD Fatigue Management Summary',
    type: 'Ng::Report::EwdFatigueManagementSummary',
    description: 'EWD Fatigue Management SummaryDescription',
    link: '/ng/reports/ewd_fatigue_management_summary',
    requireServices: [services.EWD],
    category: 'EWD',
    Id: 55
  },
  {
    reportId: 'ewd_driver_daily_summary',
    name: 'EWD Driver Daily Summary',
    type: 'Ng::Report::EwdDriverDailySummary',
    description: 'EWD Driver Daily SummaryDescription',
    link: '/ng/reports/ewd_driver_daily_summary',
    requireServices: [services.EWD],
    category: 'EWD',
    Id: 56
  },
  {
    reportId: 'ewd_driver_event_audit',
    name: 'EWD Driver Event Audit',
    type: 'Ng::Report::EwdDriverEventAudit',
    description: 'EWD Driver Event AuditDescription',
    link: '/ng/reports/ewd_driver_event_audit',
    requireServices: [services.EWD],
    category: 'EWD',
    Id: 57
  },
  {
    reportId: 'runsheet_summary',
    name: 'Runsheet Summary',
    type: 'Ng::Report::RunsheetSummary',
    description: 'Runsheet SummaryDescription',
    link: '/ng/reports/runsheet_summary',
    requireServices: [services.SMARTJOBS],
    category: 'SmartJobs',
    Id: 58
  },
  {
    reportId: 'non_delivery',
    name: 'Non-Delivery',
    type: 'Ng::Report::NonDelivery',
    description: 'Non-DeliveryDescription',
    link: '/ng/reports/non_delivery',
    requireServices: [services.SMARTJOBS],
    category: 'SmartJobs',
    Id: 59
  },
  {
    reportId: 'job_statistic',
    name: 'Job Statistic',
    type: 'Ng::Report::JobStatistic',
    description: 'Job StatisticDescription',
    link: '/ng/reports/job_statistic',
    requireServices: [services.SMARTJOBS],
    category: 'SmartJobs',
    Id: 60
  },
  {
    reportId: 'job_notification_usage',
    name: 'Job Notification Usage',
    type: 'Ng::Report::JobNotificationUsage',
    description: 'Job Notification UsageDescription',
    link: '/ng/reports/job_notification_usage',
    requireServices: [services.SMARTJOBS],
    category: 'SmartJobs',
    Id: 61
  },
  {
    reportId: 'job_summary',
    name: 'Drop/Adhoc Summary',
    type: 'Ng::Report::JobSummary',
    description: 'Drop/Adhoc SummaryDescription',
    link: '/ng/reports/job_summary',
    requireServices: [services.SMARTJOBS],
    category: 'SmartJobs',
    Id: 62
  },
  {
    reportId: 'job_kpi',
    name: 'Job KPI',
    type: 'Ng::Report::JobKpi',
    description: 'JobKPIDescription',
    link: '/ng/reports/job_kpi',
    requireServices: [services.SMARTJOBS],
    category: 'SmartJobs',
    Id: 63
  },
  {
    reportId: 'task_manager_runsheets',
    name: 'Runsheet Detail',
    type: 'Ng::Report::TaskManagerRunsheets',
    description: 'Runsheet DetailDescription',
    link: '/ng/reports/task_manager_runsheets',
    requireServices: [services.SMARTJOBS],
    category: 'SmartJobs',
    Id: 64
  },
  {
    reportId: 'timesheet_audit',
    name: 'Timesheet Audit',
    type: 'Ng::Report::TimesheetAudit',
    description: 'Timesheet AuditDescription',
    link: '/ng/reports/timesheet_audit',
    requireServices: [services.TIMESHEETS],
    category: 'Timesheets',
    Id: 65
  },
  {
    reportId: 'timesheet_summary',
    name: 'Timesheet Summary',
    type: 'Ng::Report::TimesheetSummary',
    description: 'Timesheet SummaryDescription',
    link: '/ng/reports/timesheet_summary',
    requireServices: [services.TIMESHEETS],
    category: 'Timesheets',
    Id: 66
  },
  {
    reportId: 'excessive_acceleration',
    name: 'Excessive Acceleration',
    type: 'Ng::Report::ExcessiveAcceleration',
    description: 'Excessive AccelerationDescription',
    link: '/ng/reports/excessive_acceleration',
    requireServices: [services.BPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 67,
    hide: [services.VPM]
  },
  {
    reportId: 'harsh_braking',
    name: 'Harsh Braking',
    type: 'Ng::Report::HarshBraking',
    description: 'Harsh BrakingDescription',
    link: '/ng/reports/harsh_braking',
    requireServices: [services.BPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 68,
    hide: [services.VPM]
  },
  {
    reportId: 'idle_time',
    name: 'Idle Time',
    type: 'Ng::Report::IdleTime',
    description: 'Idle TimeDescription',
    link: '/ng/reports/idle_time',
    requireServices: [services.BPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 69,
    hide: [services.VPM]
  },
  {
    reportId: 'diagnostic_trouble_code',
    name: 'Diagnostic Trouble Codes',
    type: 'Ng::Report::DiagnosticTroubleCode',
    description: 'Diagnostic Trouble CodesDescription',
    link: '/ng/reports/diagnostic_trouble_code',
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 70,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'excessive_acceleration',
    name: 'Excessive Acceleration',
    type: 'Ng::Report::ExcessiveAcceleration',
    description: 'Excessive AccelerationDescription',
    link: '/ng/reports/excessive_acceleration',
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 71,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'fuel_rate',
    name: 'Fuel Rate',
    type: 'Ng::Report::FuelRate',
    description: 'Fuel RateDescription',
    link: '/ng/reports/fuel_rate',
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 72,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'fuel_usage',
    name: 'Fuel Usage',
    type: 'Ng::Report::FuelUsage',
    description: 'Fuel UsageDescription',
    link: '/ng/reports/fuel_usage',
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 73,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'harsh_braking',
    name: 'Harsh Braking',
    type: 'Ng::Report::HarshBraking',
    description: 'Harsh BrakingDescription',
    link: '/ng/reports/harsh_braking',
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 74,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'harsh_cornering',
    name: 'Harsh Cornering',
    type: 'Ng::Report::HarshCornering',
    description: 'Harsh CorneringDescription',
    link: '/ng/reports/harsh_cornering',
    tooltip: i18n.t('Reports.NotAvailableForAllDevices'),
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 75,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'idle_time',
    name: 'Idle Time',
    type: 'Ng::Report::IdleTime',
    description: 'Idle TimeDescription',
    link: '/ng/reports/idle_time',
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 76,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'impact',
    name: 'Impact',
    type: 'Ng::Report::Impact',
    description: 'Impact Description',
    link: '/ng/reports/impact',
    requireServices: [services.VPM],
    category: 'Performance',
    Id: 77,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'mixed_energy_performance',
    name: 'MixedEnergyPerformance',
    type: 'Ng::Report::MixedEnergyPerformance',
    description: 'MixedEnergyPerformance Description',
    link: '/ng/reports/mixed_energy_performance',
    requireServices: [services.VPM],
    category: 'Performance',
    Id: 78,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'over_revving',
    name: 'Over Revving',
    type: 'Ng::Report::OverRevving',
    description: 'Over RevvingDescription',
    link: '/ng/reports/over_revving',
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 79,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'tyre_pressure',
    name: 'Tyre Pressure',
    type: 'Ng::Report::TyrePressure',
    description: 'Tyre PressureDescription',
    link: '/ng/reports/tyre_pressure',
    requireServices: [services.TPMS],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 80,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'engine_coolant_over_temperature',
    name: 'Engine Coolant Over Temperature',
    type: 'Ng::Report::EngineCoolantOverTemperature',
    description: 'Engine Coolant Over TemperatureDescription',
    link: '/ng/reports/engine_coolant_over_temperature',
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 81,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'engine_oil_over_temperature',
    name: 'Engine Oil Over Temperature',
    type: 'Ng::Report::EngineOilOverTemperature',
    description: 'Engine Oil Over TemperatureDescription',
    link: '/ng/reports/engine_oil_over_temperature',
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 82,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'engine_low_oil_pressure',
    name: 'Engine Low Oil Pressure',
    type: 'Ng::Report::EngineLowOilPressure',
    description: 'Engine Low Oil PressureDescription',
    link: '/ng/reports/engine_low_oil_pressure',
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 83,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'power_take_off',
    name: 'Power Take Off',
    type: 'Ng::Report::PowerTakeOff',
    description: 'Power Take OffDescription',
    link: '/ng/reports/power_take_off',
    requireServices: [services.PTO],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 84,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'trip_odometer',
    name: 'Trip Odometer',
    type: 'Ng::Report::TripOdometer',
    description: 'Trip OdometerDescription',
    link: '/ng/reports/trip_odometer',
    requireServices: [services.VPM],
    //requireOtherConditions: [() => !useNotTnDevice()],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 85,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'vpm_trip',
    name: 'VPM Trip',
    type: 'Ng::Report::VpmTrip',
    description: 'VPM TripDescription',
    link: '/ng/reports/vpm_trip',
    tooltip: i18n.t('Reports.NotAvailableForAllDevices'),
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 86,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'vpm_period',
    name: 'VPM Period',
    type: 'Ng::Report::VpmPeriod',
    description: 'VPM PeriodDescription',
    link: '/ng/reports/vpm_period',
    tooltip: i18n.t('Reports.NotAvailableForAllDevices'),
    requireServices: [services.VPM],
    featureHide: companyFeatures.HTF,
    category: 'Performance',
    Id: 87,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'geofence_entry_exit',
    name: 'Geofence Entry/Exit',
    type: 'Ng::Report::GeofenceEntryExit',
    description: 'Geofence Entry/ExitDescription',
    link: '/ng/reports/geofence_entry_exit',
    requireServices: [
      services.GEOFENCE,
      services.GEOFENCETRIP,
      services.SPEED,
      services.SAFERTOGETHER
    ],
    featureHide: companyFeatures.HTF,
    category: 'Geofence',
    Id: 88,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'geofence_overtime',
    name: 'Geofence Overtime',
    type: 'Ng::Report::GeofenceOvertime',
    description: 'Geofence OvertimeDescription',
    link: '/ng/reports/geofence_overtime',
    requireServices: [
      services.GEOFENCE,
      services.GEOFENCETRIP,
      services.SPEED,
      services.SAFERTOGETHER
    ],
    featureHide: companyFeatures.HTF,
    category: 'Geofence',
    Id: 89,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'geofence_undertime',
    name: 'Geofence Undertime',
    type: 'Ng::Report::GeofenceUndertime',
    description: 'Geofence UndertimeDescription',
    link: '/ng/reports/geofence_undertime',
    requireServices: [
      services.GEOFENCE,
      services.GEOFENCETRIP,
      services.SPEED,
      services.SAFERTOGETHER
    ],
    featureHide: companyFeatures.HTF,
    category: 'Geofence',
    Id: 90,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'geofence_speed',
    name: 'Geofence Speed',
    type: 'Ng::Report::GeofenceSpeed',
    description: 'Geofence SpeedDescription',
    link: '/ng/reports/geofence_speed',
    requireServices: [services.SPEED],
    featureHide: companyFeatures.HTF,
    category: 'Geofence',
    Id: 91,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'geofence_trip',
    name: 'Geofence Trip',
    type: 'Ng::Report::GeofenceTrip',
    description: 'Geofence TripDescription',
    link: '/ng/reports/geofence_trip',
    requireServices: [services.GEOFENCETRIP],
    featureHide: companyFeatures.HTF,
    category: 'Geofence',
    Id: 92,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'geofence_trip_mass',
    name: 'Geofence Trip Mass',
    type: 'Ng::Report::GeofenceTripMass',
    description: 'Geofence Trip MassDescription',
    link: '/ng/reports/geofence_trip_mass',
    requireServices: [services.GEOFENCETRIP],
    featureHide: companyFeatures.HTF,
    category: 'Geofence',
    Id: 93,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'geofence_safertogether',
    name: 'Safer Together Event Performance',
    type: 'Ng::Report::GeofenceSafertogether',
    description: 'Safer Together Event Performance Description',
    link: '/ng/reports/geofence_safertogether',
    requireServices: [services.SAFERTOGETHER],
    featureHide: companyFeatures.HTF,
    category: 'Geofence',
    Id: 94,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'geofence_safertogether_monthly',
    name: 'Safer Together Monthly Performance',
    type: 'Ng::Report::GeofenceSafertogetherMonthly',
    description: 'Safer Together Monthly Performance Description',
    link: '/ng/reports/geofence_safertogether_monthly',
    requireServices: [services.SAFERTOGETHER],
    featureHide: companyFeatures.HTF,
    category: 'Geofence',
    Id: 95,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'quickjobs_trip',
    name: 'QuickJobs Trip',
    type: 'Ng::Report::QuickjobsTrip',
    description: 'QuickJobs TripDescription',
    link: '/ng/reports/quickjobs_trip',
    requireServices: [services.QUICKJOBS],
    category: 'QuickJobs',
    Id: 96
  },
  {
    reportId: 'quickjobs_delay',
    name: 'QuickJobs Delay',
    type: 'Ng::Report::QuickjobsDelay',
    description: 'QuickJobs DelayDescription',
    link: '/ng/reports/quickjobs_delay',
    requireServices: [services.QUICKJOBS],
    category: 'QuickJobs',
    Id: 97
  },
  {
    reportId: 'quickjobs_combined',
    name: 'QuickJobs Combined',
    type: 'Ng::Report::QuickjobsCombined',
    description: 'QuickJobs CombinedDescription',
    link: '/ng/reports/quickjobs_combined',
    requireServices: [services.QUICKJOBS],
    category: 'QuickJobs',
    Id: 98
  },
  {
    reportId: 'fbt_trip',
    name: 'FBT Trip',
    type: 'Ng::Report::FbtTrip',
    description: 'FBT Trip SummaryDescription',
    link: '/ng/reports/fbt_trip',
    requireServices: [services.FBTMANAGER],
    category: 'Trip',
    Id: 99
  },
  {
    reportId: 'fbt_elb_summary',
    name: 'FBT ELB Summary',
    type: 'Ng::Report::FbtElbSummary',
    description: 'FBT ELB Summary SummaryDescription',
    link: '/ng/reports/fbt_elb_summary',
    requireServices: [services.FBTMANAGER],
    category: 'Trip',
    Id: 100
  },
  {
    reportId: 'fbt_audit',
    name: 'FBT Audit',
    type: 'Ng::Report::FbtAudit',
    description: 'FBT Audit SummaryDescription',
    link: '/ng/reports/fbt_audit',
    requireServices: [services.FBTMANAGER],
    category: 'Trip',
    Id: 101
  },
  {
    reportId: 'inspection_status',
    name: 'Inspection Status',
    type: 'Ng::Report::InspectionStatus',
    description: 'Inspection Status Description',
    link: '/ng/reports/inspection_status',
    requireServices: [services.DVIR, services.PRETRIP],
    category: 'Maintenance',
    Id: 102
  },
  {
    reportId: 'vehicle_maintenance_summary',
    name: 'Vehicle Maintenance Summary',
    type: 'Ng::Report::VehicleMaintenanceSummary',
    description: 'Vehicle Maintenance SummaryDescription',
    link: '/ng/reports/vehicle_maintenance_summary',
    requireServices: [services.VEHMNT],
    featureHide: companyFeatures.HTF,
    category: 'Maintenance',
    Id: 103
  },
  {
    reportId: 'vehicle_summary',
    name: 'Vehicle Summary',
    type: 'Ng::Report::VehicleSummary',
    description: 'Vehicle SummaryDescription',
    link: '/ng/reports/vehicle_summary',
    requireServices: [services.VEHMNT],
    featureHide: companyFeatures.HTF,
    category: 'Maintenance',
    Id: 104
  },
  {
    reportId: 'equipment_utilization',
    name: 'Equipment Utilization Report',
    type: 'Ng::Report::EquipmentUtilization',
    description: 'Equipment Utilization Report Description',
    link: '/ng/reports/equipment_utilization',
    requireServices: [services.TRACKING],
    category: 'Equipment',
    Id: 105,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  },
  {
    reportId: 'work_idle_rpm',
    name: 'Work vs Idle RPM Report ',
    type: 'Ng::Report::WorkIdleRpm',
    description: 'Work vs Idle RPM Report Description',
    link: '/ng/reports/work_idle_rpm',
    requireServices: [services.VPMHD],
    category: 'Equipment',
    Id: 106,
    beyondRoles: [GlobalRoles.IQCameraUser, GlobalRoles.IQCameraAdministrator]
  }
];

function _ReportLanding() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [searchText, setSearchText] = useState();
  const [sortOrder, setSortOrder] = useState(SortOrders.Default);
  const [categories, setCategories] = useState();
  const [viewType, setViewType] = useState('tile');
  const userInfo = useUser();
  const company = useCurrentCompany();
  const companyServices = useCurrentCompanyServices();
  const services = useServicePermissons();
  const [pageUrl, setPageUrl] = useState('');
  const frameRef = useRef(null);
  const [showIframe, setShowIframe] = useState(false);
  const pref = 'insightsallreports.view';
  const history = useHistory();

  const reportsView = useGetUserConfigByPrefQuery({
    userId: userInfo?.id,
    pref: pref
  });

  const [updateViewType, { isLoading: isSaving }] = useUpdateUserConfigByPrefMutation();

  useEffect(() => {
    dispatch(setPageTitle(t('Reports.ReportTitle')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  const includesAll = (arr, values) => values.every(v => arr.includes(v));

  const canOneOfServices = (expectedServices, userServices) => {
    if (Array.isArray(expectedServices)) {
      return expectedServices.some(sP => userServices.includes(sP));
    }
    return (userServices || []).includes(expectedServices);
  };

  const canEveryRole = (expectedRoles, user) => {
    if (
      !expectedRoles ||
      !user?.roles ||
      !(
        expectedRoles.every(r => user.roles.some(ur => ur.global && ur.name?.trim() === r)) ||
        user.siteAdmin
      )
    ) {
      return false;
    }
    return true;
  };

  const canBeyondRoles = (expectedRoles, user) => {
    return (
      !expectedRoles?.length ||
      !user?.roles ||
      user.siteAdmin ||
      user.roles.every(ur => !ur.global) ||
      user.roles.filter(ur => ur.global).some(ur => !expectedRoles.includes(ur.name?.trim()))
    );
  };

  const canFeatureHide = (hideFeature, company) => {
    return !company?.companyFeatures?.includes(hideFeature);
  };

  useEffect(() => {
    const compServices = companyServices.map(row => row.code);
    const actualReports = [];

    ReportMaps.forEach(r => {
      const hasRequireServices = r.hasOwnProperty('requireServices');
      const hasRequireRoles = r.hasOwnProperty('requireRoles');
      const hasHide = r.hasOwnProperty('hide');
      const hasBeyondRoles = r.hasOwnProperty('beyondRoles');
      const hasfeatureHide = r.hasOwnProperty('featureHide');

      const requireServices = hasRequireServices
        ? canOneOfServices(r.requireServices, services)
        : true;
      const requireRoles = hasRequireRoles ? canEveryRole(r.requireRoles, userInfo) : true;
      const hide = hasHide ? !includesAll(compServices, r.hide) : true;
      const beyondRoles = hasBeyondRoles ? canBeyondRoles(r.beyondRoles, userInfo) : true;
      const featureHide = hasfeatureHide ? canFeatureHide(r.featureHide, company) : true;

      if (requireServices && requireRoles && hide && beyondRoles && featureHide) {
        actualReports.push(r);
      }
    });

    const sortedReports = sort(actualReports, 0);
    setReports(sortedReports);
    setFilteredReports(sortedReports);
  }, [ReportMaps, companyServices, services, userInfo, company]);

  useEffect(() => {
    if (reportsView.isFetching) {
      return;
    }

    if (reportsView?.data !== null && reportsView?.data !== undefined) {
      setViewType(reportsView?.data?.view);
    } else {
      var view = JSON.parse(JSON.stringify({ view: 'tile' }));
      setViewType('tile');
      updateViewType({
        userId: userInfo?.id,
        pref: pref,
        columns: view
      }).then(res => {
        if (res?.error) {
          setViewType('tile');
        } else {
          setViewType('tile');
          reportsView.refetch();
        }
      });
    }
  }, [reportsView]);

  useEffect(() => {
    if (
      categories !== undefined &&
      categories !== null &&
      reports !== undefined &&
      reports !== undefined
    ) {
      handleFilter(categories);
    }
  }, [categories, reports]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (frameRef.current && !frameRef.current.contains(event.target)) {
        setShowIframe(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearch = value => {
    setSearchText(value);

    let filteredReports = search(reports, value);
    if (categories) {
      filteredReports = filter(filteredReports, categories);
    }
    if (sortOrder) {
      filteredReports = sort(filteredReports, sortOrder);
    }

    setFilteredReports(filteredReports);
  };

  const handleSort = order => {
    setSortOrder(order);

    const sortedReports = sort(filteredReports, order);
    setFilteredReports(sortedReports);
  };

  const handleFilter = categoryIds => {
    setCategories(categoryIds);

    let filteredReports = filter(reports, categoryIds);
    if (searchText) {
      filteredReports = search(filteredReports, searchText);
    }
    if (sortOrder) {
      filteredReports = sort(filteredReports, sortOrder);
    }
    setFilteredReports(filteredReports);
  };

  const handleDeleteCard = () => {
    handleFilter(categories);
  };

  const search = (reports, searchText) => {
    return reports.filter(
      report =>
        t(`Reports.${report.name}`)
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        t(`Reports.${report.description}`)
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        report.category.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const sort = (reports, order) => {
    const isDefault = order === SortOrders.Default;
    const isDescending = order === SortOrders.NameDesc;
    const sortedDashboards = isDefault
      ? _.orderBy(reports, ['Id', db => t(`Reports.${db.name}`)], ['asc', 'asc'])
      : reports.toSorted(Comparators.String(db => t(`Reports.${db.name}`), isDescending));
    return sortedDashboards;
  };

  const filter = (reports, categoryIds) => {
    return reports.filter(report =>
      categoryIds?.reduce(
        (accumulator, categoryId) => accumulator || isReportCategory(report, categoryId),
        false
      )
    );
  };

  const onViewChange = useCallback(
    //viewType === 'tile' ? setViewType('grid') : setViewType('tile');
    e => {
      if (viewType === 'tile') {
        setViewType('grid');
        var view = JSON.parse(JSON.stringify({ view: 'grid' }));
        updateViewType({
          userId: userInfo?.id,
          pref: pref,
          columns: view
        }).then(res => {
          if (res?.error) {
            setViewType('grid');
          } else {
            setViewType('grid');
            reportsView.refetch();
          }
        });
      } else {
        var view = JSON.parse(JSON.stringify({ view: 'tile' }));
        setViewType('tile');
        updateViewType({
          userId: userInfo?.id,
          pref: pref,
          columns: view
        }).then(res => {
          if (res?.error) {
            setViewType('tile');
          } else {
            setViewType('tile');
            reportsView.refetch();
          }
        });
      }
    },
    [userInfo, pref, viewType]
  );

  //*****GRID************//
  const nameCellRenderer = (value, rowData, rowIndex) => {
    return <div>{t(`Reports.${rowData.name}`)}</div>;
  };

  const descriptionCellRenderer = (value, rowData, rowIndex) => {
    return <div>{t(`Reports.${rowData.description}`)}</div>;
  };

  const categoryCellRenderer = (value, rowData, rowIndex) => {
    const categories = getReportCategoryLabels(rowData);

    return (
      Array.isArray(categories) &&
      categories?.map(category => (
        <Tag className={[cardStyles.categories, cardStyles.reportCard]} key={category}>
          {t(`Insights.Tags.${category}`)}
        </Tag>
      ))
    );
  };

  const actionCellRenderer = (value, rowData, rowIndex) => {
    return (
      <Button className={styles.generate} onClick={() => onViewReportClick(rowData)}>
        {' '}
        {t(`Insights.Criteria`)}{' '}
      </Button>
    );
  };

  const columns = [
    {
      title: t(`Insights.Table.Name`),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 16,
      render: nameCellRenderer,
      sorter: Comparators.String('name')
    },
    {
      title: t(`Insights.Table.Description`),
      key: 'description',
      fixed: 'left',
      width: 32,
      render: descriptionCellRenderer
    },
    {
      title: t(`Insights.Table.Category`),
      key: 'tag',
      fixed: 'left',
      width: 8,
      render: categoryCellRenderer,
      sorter: Comparators.String(getReportCategoryLabel)
    },
    {
      title: t(`Insights.Table.Action`),
      key: 'action',
      fixed: 'left',
      width: 8,
      render: actionCellRenderer
    }
  ];
  //*****GRID************//

  const onViewReportClick = report => {
    setPageUrl(
      AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
        `&url=${encodeURIComponent(`/ng/reports/${report.reportId}`)}`
    );
    //setShowIframe(true);
    history.push(`/reports/catalog/${report.reportId}`);
  };

  return (
    <>
      <ReportsPageToolbar
        count={filteredReports?.length}
        onSearch={handleSearch}
        onSort={handleSort}
        onFilter={handleFilter}
        onViewChange={onViewChange}
        viewType={viewType}
        showIframe={showIframe}
        reports={reports}
      />

      {!showIframe && viewType === 'tile' && (
        <List
          className={styles.dashboardList}
          grid={{
            gutter: 12,
            xs: 2,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 4,
            xxl: 5
          }}
          itemLayout={'horizontal'}
          loading={reportsView.isFetching}
          dataSource={filteredReports}
          renderItem={report => (
            <List.Item key={report.reportId}>
              <ReportCard
                report={report}
                categories={getReportCategoryLabels(report)}
                //image={}
                //onDelete={handleDeleteCard}
                onViewReportClick={onViewReportClick}
              />
            </List.Item>
          )}
        />
      )}
      {!showIframe && viewType === 'grid' && (
        <Grid
          columns={columns}
          data={filteredReports}
          showHeader={false}
          tableClassName={`${styles.newGridStyle} ${styles.history}`}
          isLoading={reportsView.isFetching}
        />
      )}

      {showIframe && (
        <Iframe
          ref={frameRef}
          src={pageUrl}
          className={styles.iframe}
          width="100%"
          height="100%"
        ></Iframe>
      )}
    </>
  );
}

function _ReportFrame({ type }) {
  const userInfo = useUser();
  const frameRef = useRef(null);

  const [clickCount, increaseCount] = useReducer(
    state => {
      return { count: state.count + 1 };
    },
    { count: 0 }
  );

  const url = useMemo(() => {
    const url =
      AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
      `&url=${encodeURIComponent('/ng/reports/' + type)}`;
    return url;
  }, [type, userInfo, clickCount]);

  //useWalkMeInFrame(frameRef);
  return (
    <>
      <Iframe
        ref={frameRef}
        className={styles.reportFrame}
        src={url}
        width="100%"
        height="100%"
      ></Iframe>
    </>
  );
}

const ReportLanding = React.memo(_ReportLanding);
const ReportFrame = React.memo(_ReportFrame);

export function ReportsStandardNew() {
  return (
    <Switch>
      <Route path="/reports/catalog/:type">
        {({ match }) => <ReportFrame type={match.params.type} />}
      </Route>
      <Route exact path={['/reports', '/reports/catalog']}>
        <ReportLanding />
      </Route>
    </Switch>
  );
}
