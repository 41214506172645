import React from 'react';
import { Layout as AntLayout } from 'antd';

import { SalesForceButton } from 'features/saleforce/SalesForceButton';
import { AWSConnectChat } from 'features/awsconnecchat/AWSConnectChat';
import { ChurnZero } from 'features/churnzero/ChurnZero';

import { Loading } from 'components/loading/Loading';
import PageRouter from 'components/route/PageRouter';
import { LeftNav } from './nav';

import { AWS_CHAT_ENABLED, CHURNZERO_ENABLE } from 'config';

import styles from './layout.module.scss';

const { Content } = AntLayout;

export const Layout = ({ isLoading, ...props }) => {
  return (
    <>
      {!isLoading ? (
        <AntLayout className={styles.layout}>
          <LeftNav />
          <AntLayout>
            <Content>
              <PageRouter />
            </Content>
          </AntLayout>

          {AWS_CHAT_ENABLED ? <AWSConnectChat /> : <SalesForceButton />}
          {CHURNZERO_ENABLE && <ChurnZero />}
        </AntLayout>
      ) : (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
    </>
  );
};
