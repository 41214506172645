import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Tooltip } from 'antd';
import { Link, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import parsePhoneNumber from 'libphonenumber-js';

import { format, formatTimeago } from 'utils/dates';
import { Comparators } from 'utils/sorting';
import { getFleetTooltipForGrid, getFleetDisplayValueForGrid } from 'utils/methods';
import { AUTO_HELI_THRESHOLD, DEFAULT_DEVICE_ICON, DEFAULT_VEHICLE_ICON } from '../constants';

import {
  MeterType,
  getMeterByType,
  getVehicleOdometer,
  getVehicleEngineHours
} from 'features/meters';
import {
  services,
  useCanEveryService,
  useCanEveryCompanyService,
  useCanFeatureFlag,
  FeatureFlag
} from 'features/permissions';
import { useLocalization } from 'features/localization/localizationSlice';
import { useAutoHeliUpdates, useIsMqttConnected } from 'features/mqtt/mqttSlice';
import { useUserGridSettings, updateUserGridSettings } from 'features/user/userGridSettingsSlice';
import {
  getIncludedColumns,
  updateIncludedColumns,
  getSort,
  updateSort,
  getFilterFleets,
  getViewsGridFilters
} from 'features/tracking/trackingGridSettings';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import {
  useCurrentCompany,
  useSubCompanyEntityConfig,
  CompanyConfigKey,
  CompanyConfigValue
} from 'features/company/companySlice';

import { getEventAttributesByType } from 'containers/Tracking/EventTypes';
import { addResizeToColumns } from 'components/ant/Table';
import { Grid, ExpandIcon } from 'components/tn';
import { ColumnTypes } from 'components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';
import { Icon } from 'components/ant';
import { ActionsMenu } from './ActionsMenu';
import { TrackEvents } from '../Track/TrackEvents';
import { DriverName } from '../Common/DiverName';
import HideNonBusinessStatus from '../Common/HideNonBusinessStatus';
import BatteryIcons from 'static/images/icons/battery';

import styles from 'components/tn/grid/grid.module.scss';

const DeviceStatus = {
  IgnitionOn: {
    id: 1,
    key: 'Tracking.DeviceStatus.IgnitionOn',
    value: 'IgnitionOn',
    color: '#52C41A'
  },
  IgnitionOff: {
    id: 2,
    key: 'Tracking.DeviceStatus.IgnitionOff',
    value: 'IgnitionOff',
    color: '#1890FF'
  },
  OutOfCoverage: {
    id: 3,
    key: 'Tracking.DeviceStatus.OutOfCoverage',
    value: 'OutOfCoverage',
    color: '#A1A1A1'
  },
  BatteryPowered: {
    id: 4,
    key: 'Tracking.DeviceStatus.BatteryPowered',
    value: 'BatteryPowered',
    color: '#875BB0'
  }
};

const EnableVirtualScrolling = true;
const EnableColumnResize = true;

export const TrackGrid = ({
  deviceUpdates,
  data,
  trips,
  fleets,
  onEdit,
  onDelete,
  tab,
  gridSettingsKey,
  defaultGridConfig,
  selectedDeviceId,
  expandedDeviceId,
  isLoading,
  isTripsLoading,
  dateRange,
  datePickerRef,
  selectedTrip,
  selectedEvent,
  onFilteredDevicesChanged,
  onDateRangeSelected,
  onDateRangeClose,
  onEventClicked,
  onDeviceExpanded,
  onDeviceClicked,
  onTripSegmentSelected,
  groupByTripsEnabled,
  onGroupByTripsChange,
  openShareLiveLocationModal,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localization = useLocalization();
  const currentCompany = useCurrentCompany();
  const userPreferences = useUserPreferences();
  const isMqttConnected = useIsMqttConnected();
  const userGridSettings = useUserGridSettings(gridSettingsKey);
  const autoHeliUpdates = useAutoHeliUpdates();
  const isUserTachoEnabled = useCanEveryService(services.TACHO);
  const isCompanyTachoEnabled = useCanEveryCompanyService(services.TACHO);
  const isUserSmartJobsEnabled = useCanEveryService(services.SMARTJOBS);
  const hasIridiumService = useCanEveryCompanyService(services.IRIDIUM);
  const isCompanySmartJobsEnabled = useCanEveryCompanyService(services.SMARTJOBS);
  const hideNonBusinessTrips = useSubCompanyEntityConfig(
    currentCompany?.id,
    CompanyConfigKey.HideNonBusiness
  );
  const hasTrackingLastUpdateColumn = useCanFeatureFlag({
    featureFlag: FeatureFlag.trackingLastUpdateColumn.flag
  });
  const { deviceId } = useParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    const selectedDeviceId = Number(expandedDeviceId);
    setExpandedRowKeys(isNaN(selectedDeviceId) ? [] : [Number(expandedDeviceId)]);
  }, [expandedDeviceId]);

  // console.debug('TrackGrid', trips, selectedTrip);

  // Filter out columns accessable to user and company with optional canAccess permissions check
  const getAccessableColumns = columns => {
    return columns.filter(column => {
      const canAccess = column.hasOwnProperty('canAccess') ? column.canAccess() : true;
      return canAccess;
    });
  };

  // Get list of columns based on user configuration in the view (order, enabled/disabled)
  const getUserColumns = (allColumns, userGridSettings) => {
    if (userGridSettings && userGridSettings.lastFetched) {
      let userColumns = [];

      let result = getIncludedColumns(userGridSettings);
      let columnsFromConfig = result.columnsFromConfig;
      let newColumnsSchema = result.newColumnsSchema;

      let sort = getSort(userGridSettings, columnsFromConfig);

      // Always add non configurable fixed left columns on the beginning
      const fixedLeftColumns = allColumns.filter(
        item => item.fixed === 'left' && !item.isConfigurable
      );
      userColumns.push(...fixedLeftColumns);

      // Add all the columns from the config (or defaults) in the right order
      columnsFromConfig.forEach(columnFromConfig => {
        let key = newColumnsSchema ? columnFromConfig.key : columnFromConfig;

        const foundColumn = allColumns.find(x => x.key === key);
        const alreadyAdded = userColumns.find(x => x.key === key);
        if (foundColumn && !alreadyAdded) {
          const userColumn = { ...foundColumn };

          // Add widths for resize feature
          if (newColumnsSchema) {
            userColumn.width = columnFromConfig.width;
          }

          // Add default sort for configured sort column
          if (userColumn.key === sort.column) {
            userColumn.defaultSortColumn = true;
            userColumn.defaultSortOrder = sort.order;
          }

          userColumns.push(userColumn);
        }
      });

      // Always add non configurable fixed right columns on the end
      const fixedRightColumns = allColumns.filter(
        item => item.fixed === 'right' && !item.isConfigurable
      );
      userColumns.push(...fixedRightColumns);

      return userColumns;
    }

    return null;
  };

  const twoLineEllipsisCell = value => (
    <span title={value} className={styles.twoLineEllipsisCell}>
      {value}
    </span>
  );

  const getLastEventAtValue = rowData => {
    const lastEventAt = rowData?.deviceStats?.lastEventAt;
    return new Date(lastEventAt);
  };

  const getVehicleOrDeviceDisplayName = rowData => {
    return rowData.vehicleName ? rowData.vehicleName : rowData.name;
  };

  const getRegistrationValue = rowData => {
    let registration = '';
    if (rowData.vehicleRegistrationState) {
      registration += `${rowData.vehicleRegistrationState}`;
    }
    if (rowData.vehicleRegistration) {
      registration += ` | ${rowData.vehicleRegistration}`;
    }
    return registration;
  };

  const getFleetValue = rowData => {
    const fleetDisplay = getFleetDisplayValueForGrid(rowData.fleetNames, t);
    return fleetDisplay;
  };

  const getOdometer = rowData => {
    const meterOdometer = getMeterByType(rowData?.vehicleStats, MeterType.Odometer);
    let odometer = getVehicleOdometer(
      meterOdometer,
      rowData?.vehicle?.engineSummarySource,
      rowData?.vehicleStats?.canOdometer,
      rowData?.vehicleStats?.canDiffOdometer,
      rowData?.vehicleStats?.gpsOdometer
    );
    if (odometer) {
      odometer = parseFloat(localization.convertDistance(odometer, 1));
    }
    return odometer || 0;
  };

  const getEngineHours = rowData => {
    const meterHours = getMeterByType(rowData?.vehicleStats, MeterType.Hours);
    const engineHours = getVehicleEngineHours(
      meterHours,
      rowData?.vehicle?.engineSummarySource,
      rowData?.vehicleStats?.canEngineHours,
      rowData?.vehicleStats?.canDiffEngineHours,
      rowData?.vehicleStats?.gpsEngineHours
    );
    return engineHours || 0;
  };

  const getRucStatus = rowData => {
    let rucStatus = '';
    if (rowData.lastRuc) {
      rucStatus = rowData.lastRuc;
      if (rowData.lastRucAt) {
        rucStatus +=
          ' (' +
          format(moment(rowData.lastRucAt).toDate(), localization.formats.time.formats.dby_imp) +
          ')';
      }
    }
    return rucStatus;
  };

  const getSpeed = rowData => {
    const speed = localization.convertSpeed(
      !rowData.deviceStats?.gps?.Spd || rowData.deviceStats?.gps?.Spd < 0
        ? 0
        : rowData.deviceStats?.gps?.Spd
    );
    return speed;
  };

  const getEdrStatus = rowData => {
    let edrStatus = '';
    if (rowData.lastEdr) {
      edrStatus = rowData.lastEdr;
      if (rowData.lastEdrAt) {
        edrStatus +=
          ' (' +
          format(moment(rowData.lastEdrAt).toDate(), localization.formats.time.formats.dby_imp) +
          ')';
      }
    }
    return edrStatus;
  };

  const getFleetToolsip = rowData => {
    const fleetTooltip = getFleetTooltipForGrid(rowData.fleetNames, t);
    return fleetTooltip;
  };

  const fleetValueCellRenderer = (value, rowData, rowIndex) => {
    const fleetTooltip = getFleetToolsip(rowData);
    const fleetValue = getFleetValue(rowData);
    return (
      <span
        title={fleetTooltip}
        className={styles.twoLineEllipsisCell}
        style={{ whiteSpace: 'pre-line' }}
      >
        {fleetValue}
      </span>
    );
  };

  const getDeviceStatus = (value, rowData) => {
    const lastEventAt = getLastEventAtValue(rowData);

    const ignStatus = value;

    const outOfCoverageLimit = new Date();
    outOfCoverageLimit.setMilliseconds(
      outOfCoverageLimit.getMilliseconds() - userPreferences?.trackingOutOfCoverageThreshold
    );

    let isBatteryPowered = rowData.type?.code === 'ATRACKER';
    // if (isBatteryPowered) {
    //   console.debug('TrackGrid - ignition status: ', ignStatus, ' | device type: ', rowData.type?.code, ' | model: ', rowData.model?.name);
    // }

    let deviceStatus = isBatteryPowered
      ? DeviceStatus.BatteryPowered
      : ignStatus?.toLowerCase() === 'on'
      ? lastEventAt < outOfCoverageLimit
        ? DeviceStatus.OutOfCoverage
        : DeviceStatus.IgnitionOn
      : DeviceStatus.IgnitionOff;
    return deviceStatus;
  };

  const statusCellRenderer = (value, rowData, rowIndex) => {
    const deviceStatus = getDeviceStatus(value, rowData);

    return (
      <Tooltip title={t(deviceStatus.key)} color={deviceStatus.color}>
        <div
          className={styles.cellStatus}
          style={{
            backgroundColor: deviceStatus.color
          }}
        />
      </Tooltip>
    );
  };

  const vehicleIconCellRenderer = (value, rowData, rowIndex) => {
    let isVehicleInAction = false;

    if (autoHeliUpdates[rowData?.id]) {
      const lastUpdateSecondsAgo =
        new Date().getTime() -
        autoHeliUpdates[rowData?.id][autoHeliUpdates[rowData?.id].length - 1]?.at?.getTime();
      if (lastUpdateSecondsAgo / 1000 <= AUTO_HELI_THRESHOLD) {
        isVehicleInAction = true;
      }
    }

    const classIcon = cn(rowData.icon, {
      [DEFAULT_VEHICLE_ICON]: !rowData.icon && rowData.vehicle,
      [DEFAULT_DEVICE_ICON]: !rowData.icon && !rowData.vehicle
    });

    const primaryCircle = cn({
      [styles.primaryCircle]: isVehicleInAction
    });
    const secondaryCircle = cn(styles.centerAlign, {
      [styles.secondaryCircle]: isVehicleInAction
    });

    return (
      <div key={rowIndex}>
        <div className={primaryCircle}>
          <div className={secondaryCircle}>
            <i style={{ fontSize: '20px' }} className={classIcon} />
          </div>
        </div>
      </div>
    );
  };

  const locationRenderer = (value, rowData) => {
    return (
      <>
        {hideNonBusinessTrips && rowData?.deviceStats?.attr === CompanyConfigValue.Private ? (
          <HideNonBusinessStatus alignLeft />
        ) : (
          <span title={rowData.deviceStats.location} className={styles.twoLineEllipsisCell}>
            {rowData.deviceStats.location}
          </span>
        )}
      </>
    );
  };

  const phoneRenderer = value => {
    let num = '';
    if (value !== null && value !== undefined) {
      num = parsePhoneNumber('+' + value)?.format('NATIONAL');
    }

    return num;
  };

  const getEvSoc = data => {
    return (
      data?.vehicleStats?.evDeviceMeters?.find(e => e.type === 'hv_soc')?.value?.toFixed(1) ?? null
    );
  };

  const getEvRange = data => {
    return data?.vehicleStats?.evDeviceMeters?.find(e => e.type === 'hv_range')?.value ?? null;
  };

  const getRdt = data => {
    const { maxDailyDrive, driveDaily } = data?.vehicleStats?.tachoFatigueStatusEvent || {};
    const rdt = maxDailyDrive ? maxDailyDrive - (driveDaily || 0) : null;
    return rdt;
  };

  const getDriverStatus = data => {
    const { activity } = data?.vehicleStats?.tachoFatigueStatusEvent || {};

    return activity ?? '';
  };

  const getNextBreak = data => {
    const { remainingDriveTime, nextDailyRestDueAt, nextWeeklyRestDueAt } =
      data?.vehicleStats?.tachoFatigueStatusEvent || {};

    if (remainingDriveTime != null || nextDailyRestDueAt != null || nextWeeklyRestDueAt != null) {
      const nextBreakBy = Math.min(
        moment().add(new Date(), remainingDriveTime),
        nextDailyRestDueAt,
        nextWeeklyRestDueAt
      );
      return nextBreakBy;
    }

    return null;
  };

  const getRunsheetDisplay = runsheet => {
    return `${runsheet.name ? runsheet.name : ''}${
      runsheet.name ? ' (' + runsheet.externalId + ')' : runsheet.externalId
    }`;
  };

  const getRunsheetsValue = rowData => {
    let runsheetsDisplay = '';
    const runsheets = rowData.runsheets;
    if (runsheets) {
      runsheets.forEach(runsheet => {
        runsheetsDisplay += `${getRunsheetDisplay(runsheet)}\n`;
      });
    }
    return runsheetsDisplay;
  };

  const runsheetsCellRenderer = (value, rowData, rowIndex) => {
    if (!rowData.runsheets) return null;

    const runsheetsValue = getRunsheetsValue(rowData);

    return (
      <div title={runsheetsValue} className={styles.twoLineEllipsisCell}>
        {rowData.runsheets.map(runsheet => (
          <Link style={{ display: 'block' }} target="_blank" to={`/smartjobs/id/${runsheet.id}`}>
            {getRunsheetDisplay(runsheet)}
          </Link>
        ))}
      </div>
    );
  };

  const getLastEventValue = rowData => {
    return rowData?.vehicleStats?.lastEventType + rowData?.vehicleStats?.lastEventSubtype || '';
  };

  const lastEventRenderer = (value, rowData, rowIndex) => {
    if (rowData?.vehicleStats?.lastEventType && rowData?.vehicleStats?.lastEventSubtype) {
      const eventAttributes = getEventAttributesByType(
        rowData?.vehicleStats?.lastEventType,
        rowData?.vehicleStats?.lastEventSubtype
      );
      if (eventAttributes) {
        return (
          <img
            className={styles.eventTypeImage}
            src={eventAttributes?.markerSvg}
            alt={t(`Tracking.Events.${eventAttributes?.label}`, {
              defaultValue: eventAttributes?.label
            })}
            title={t(`Tracking.Events.${eventAttributes?.label}`, {
              defaultValue: eventAttributes?.label
            })}
          />
        );
      }
    }

    return '';
  };

  const getCommunicationChannelValue = rowData => {
    let comChannelLabel = '';
    if (rowData?.vehicleStats?.lastEventOrigin?.includes('satellite')) {
      comChannelLabel = t('Tracking.Satellite');
    } else if (rowData?.vehicleStats?.lastEventOrigin?.includes('hermes')) {
      comChannelLabel = t('Tracking.Cellular');
    } else {
      comChannelLabel = t('Tracking.Server');
    }
    return comChannelLabel;
  };

  const communicationChannelRenderer = (value, rowData, rowIndex) => {
    const comChannelLabel = getCommunicationChannelValue(rowData);
    let comChannelClass = null;
    if (rowData?.vehicleStats?.lastEventOrigin?.includes('satellite')) {
      comChannelClass = styles.comChannelSatellite;
    } else if (rowData?.vehicleStats?.lastEventOrigin?.includes('hermes')) {
      comChannelClass = styles.comChannelCellular;
    } else {
      comChannelClass = styles.comChannelServer;
    }

    return (
      <span title={comChannelLabel} className={`${comChannelClass} ${styles.twoLineEllipsisCell}`}>
        {comChannelLabel}
      </span>
    );
  };

  const handleRowClick = deviceId => {
    console.debug('TrackGrid - handleRowClick', deviceId);

    // If expanded, ignore click on vehicle row to leave it expanded
    if (expandedRowKeys.includes(deviceId)) return;

    if (selectedRowKeys.includes(deviceId)) {
      handleSelectChange([]);
      onDeviceClicked && onDeviceClicked(null);
    } else {
      if (expandedRowKeys.length) {
        handleExpandChange([]);
        onDeviceExpanded && onDeviceExpanded(null);
      }
      handleSelectChange([deviceId]);
      onDeviceClicked && onDeviceClicked(deviceId);
    }
  };

  const handleRowExpand = (isExpanding, record) => {
    console.debug('TrackGrid - handleRowExpand', isExpanding, record.id);
    if (isExpanding) {
      handleSelectChange([record.id]);
      handleExpandChange([record.id]);
      onDeviceExpanded && onDeviceExpanded(record);
    } else {
      handleSelectChange([]);
      handleExpandChange([]);
      onDeviceExpanded && onDeviceExpanded(null);
    }
  };

  const handleResizeColumnsStop = () => {
    if (userGridSettings && userGridSettings.lastFetched) {
      const includedColumns = columns
        .filter(col => col.isConfigurable)
        .map(col => ({ key: col.key, width: col.width }));

      const newUserGridSettings = updateIncludedColumns(userGridSettings, includedColumns);

      dispatch(updateUserGridSettings(newUserGridSettings, gridSettingsKey, () => {}, false));
    }
  };

  const handleColumnHeaderClick = column => {
    if (userGridSettings && userGridSettings.lastFetched) {
      const newUserGridSettings = updateSort(userGridSettings, column);
      dispatch(updateUserGridSettings(newUserGridSettings, gridSettingsKey, () => {}, false));
    }
  };

  const allColumns = [
    {
      title: '',
      key: 'status',
      type: ColumnTypes.Ignition,
      isConfigurable: false,
      isSelectedByDefault: true,
      dataIndex: ['deviceStats', 'ignition'],
      fixed: 'left',
      width: 8,
      isSearchable: false,
      isFilterable: false,
      className: styles.noSidePadding,
      getValue: getDeviceStatus,
      render: statusCellRenderer
    },
    {
      title: '',
      key: 'icon',
      type: ColumnTypes.None,
      isConfigurable: false,
      isSelectedByDefault: true,
      dataIndex: 'icon',
      fixed: 'left',
      width: 32,
      isSearchable: false,
      isFilterable: false,
      className: styles.noSidePadding,
      render: vehicleIconCellRenderer
    },
    {
      title: t('Entity.Vehicle'),
      label: t('Entity.Vehicle'),
      dataIndex: 'vehicleName',
      key: 'vehicle',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: true,
      fixed: 'left',
      width: 150,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      getValue: getVehicleOrDeviceDisplayName,
      sorter: Comparators.String(getVehicleOrDeviceDisplayName),
      render: (value, rowData, rowIndex) =>
        twoLineEllipsisCell(getVehicleOrDeviceDisplayName(rowData))
    },
    {
      // used to render the grid header
      title: t('Tracking.Columns.Driver'),
      // label for displaying in views config UIs
      label: t('Tracking.Columns.Driver'),
      dataIndex: 'driverName',
      key: 'driver',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: true,
      width: 150,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      sorter: Comparators.String('driverName'),
      render: (value, device) => (
        <DriverName
          title={device.driverName}
          className={styles.twoLineEllipsisCell}
          device={device}
        />
      )
    },
    {
      title: twoLineEllipsisCell(t('Tracking.Columns.Last Update')),
      label: t('Tracking.Columns.Last Update'),
      dataIndex: ['deviceStats', 'lastEventAt'],
      key: 'lastUpdate',
      type: ColumnTypes.Date,
      isConfigurable: true,
      isSelectedByDefault: true,
      width: 150,
      isSearchable: false,
      isFilterable: true,
      ellipsis: true,
      getValue: getLastEventAtValue,
      sorter: Comparators.Date(getLastEventAtValue),
      render: (value, rowData, rowIndex) => {
        const lastUpdateValue = getLastEventAtValue(rowData);
        return twoLineEllipsisCell(formatTimeago(lastUpdateValue));
      }
    },
    {
      title: t('Common.Location'),
      label: t('Common.Location'),
      dataIndex: ['deviceStats', 'location'],
      key: 'location',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: true,
      width: 350,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      sorter: Comparators.String('deviceStats.location'),
      render: locationRenderer
    },
    {
      title: twoLineEllipsisCell(t('Vehicles.View.DeviceType')),
      label: t('Vehicles.View.DeviceType'),
      dataIndex: ['type', 'name'],
      key: 'deviceType',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 100,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      sorter: Comparators.String('type.name')
    },
    {
      title: twoLineEllipsisCell(t('Vehicles.View.VehicleType')),
      label: t('Vehicles.View.VehicleType'),
      dataIndex: ['vehicleType'],
      key: 'vehicleType',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 100,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      sorter: Comparators.String('vehicleType'),
      render: (value, rowData, rowIndex) => twoLineEllipsisCell(rowData.vehicleType)
    },
    {
      title: t('Common.Latitude'),
      label: t('Common.Latitude'),
      dataIndex: ['deviceStats', 'gps', 'Lat'],
      key: 'latitude',
      type: ColumnTypes.Number,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 110,
      isSearchable: false,
      isFilterable: true,
      ellipsis: true,
      numDecimals: 5,
      sorter: Comparators.Number('deviceStats.gps.Lat'),
      render: (value, rowData, rowIndex) => {
        return rowData?.deviceStats?.gps?.Lat?.toFixed(5) || 0;
      }
    },
    {
      title: t('Common.Longitude'),
      label: t('Common.Longitude'),
      dataIndex: ['deviceStats', 'gps', 'Lng'],
      key: 'longitude',
      type: ColumnTypes.Number,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 110,
      isSearchable: false,
      isFilterable: true,
      ellipsis: true,
      numDecimals: 5,
      sorter: Comparators.Number('deviceStats.gps.Lng'),
      render: (value, rowData, rowIndex) => {
        return rowData?.deviceStats?.gps?.Lng?.toFixed(5) || 0;
      }
    },
    {
      title: t('Common.Registration'),
      label: t('Common.Registration'),
      dataIndex: 'vehicleRegistrationState',
      key: 'registration',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 120,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      getValue: getRegistrationValue,
      sorter: Comparators.String(getRegistrationValue),
      render: (value, rowData, rowIndex) => twoLineEllipsisCell(getRegistrationValue(rowData))
    },
    {
      // used to render the grid header
      title: t('Common.Fleet'),
      // label for displaying in views config UIs
      label: t('Common.Fleet'),
      dataIndex: 'fleetNames',
      key: 'fleet',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 150,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      getValue: getFleetValue,
      sorter: Comparators.String(getFleetValue),
      render: fleetValueCellRenderer
    },
    {
      title: twoLineEllipsisCell(t('Vehicles.EngineHours')),
      label: t('Vehicles.EngineHours'),
      dataIndex: ['vehicleStats', 'gpsEngineHours'],
      key: 'engineHours',
      type: ColumnTypes.Number,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 100,
      isSearchable: false,
      isFilterable: true,
      ellipsis: true,
      numDecimals: 1,
      getValue: getEngineHours,
      sorter: Comparators.Number(getEngineHours),
      render: (value, rowData, rowIndex) => {
        const engineHours = getEngineHours(rowData);
        const engineHoursDisplay = engineHours.toLocaleString(localization.locale, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        });
        return engineHoursDisplay;
      }
    },
    {
      title: twoLineEllipsisCell(`${t('Vehicles.Odometer')} (${localization.formats.speed.unit})`),
      label: `${t('Vehicles.Odometer')} (${localization.formats.speed.unit})`,
      dataIndex: ['vehicleStats', 'gpsOdometer'],
      key: 'odometer',
      type: ColumnTypes.Number,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 110,
      isSearchable: false,
      isFilterable: true,
      ellipsis: true,
      numDecimals: 1,
      getValue: getOdometer,
      sorter: Comparators.Number(getOdometer),
      render: (value, rowData, rowIndex) => {
        const odometer = getOdometer(rowData);
        const odometerDisplay = odometer.toLocaleString(localization.locale, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        });
        return twoLineEllipsisCell(odometerDisplay);
      }
    },
    {
      title: t('Tracking.Speed'),
      label: t('Tracking.Speed'),
      dataIndex: ['deviceStats', 'gps', 'Spd'],
      key: 'speed',
      type: ColumnTypes.Number,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 90,
      isSearchable: false,
      isFilterable: true,
      ellipsis: true,
      numDecimals: 1,
      getValue: getSpeed,
      sorter: Comparators.Number(getSpeed),
      render: (value, rowData, rowIndex) => getSpeed(rowData)
    },
    {
      title: twoLineEllipsisCell(t('Tracking.EDRStatus')),
      label: t('Tracking.EDRStatus'),
      dataIndex: 'lastEdr',
      key: 'edrStatus',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 130,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      getValue: getEdrStatus,
      sorter: Comparators.String(getEdrStatus),
      render: (value, rowData, rowIndex) => twoLineEllipsisCell(getEdrStatus(rowData))
    },
    {
      title: twoLineEllipsisCell(t('Tracking.RUCStatus')),
      label: t('Tracking.RUCStatus'),
      dataIndex: 'lastRuc',
      key: 'rucStatus',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 130,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      getValue: getRucStatus,
      sorter: Comparators.String(getRucStatus),
      render: (value, rowData, rowIndex) => twoLineEllipsisCell(getRucStatus(rowData))
    },
    {
      // used to render the grid header
      title: t('Vehicles.View.Phone'),
      // label for displaying in views config UIs
      label: t('Vehicles.View.Phone'),
      dataIndex: ['driver', 'mobile'],
      key: 'driverPhone',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 130,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      sorter: Comparators.String('driver.mobile'),
      render: (value, rowData, rowIndex) => twoLineEllipsisCell(phoneRenderer(value))
    },
    {
      title: t('Common.Rdt'),
      label: t('Common.Rdt'),
      dataIndex: 'rdt',
      key: 'rdt',
      type: ColumnTypes.Number,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 130,
      isSearchable: false,
      isFilterable: true,
      ellipsis: true,
      canAccess: () => isUserTachoEnabled && isCompanyTachoEnabled,
      getValue: getRdt,
      sorter: Comparators.Number(getRdt),
      render: (value, rowData, rowIndex) => {
        const rdt = getRdt(rowData);
        return rdt ? twoLineEllipsisCell(moment.utc(rdt * 1000).format('h [hrs] m [mins]')) : '';
      }
    },
    {
      title: twoLineEllipsisCell(t('Common.DriverStatus')),
      label: t('Common.DriverStatus'),
      dataIndex: 'driverStatus',
      key: 'driverStatus',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 130,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      canAccess: () => isUserTachoEnabled && isCompanyTachoEnabled,
      getValue: getDriverStatus,
      sorter: Comparators.String(getDriverStatus),
      render: (value, rowData, rowIndex) => twoLineEllipsisCell(getDriverStatus(rowData))
    },
    {
      title: twoLineEllipsisCell(t('Common.NextBreakBy')),
      label: t('Common.NextBreakBy'),
      dataIndex: 'nextBreak',
      key: 'nextBreak',
      type: ColumnTypes.Date,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 130,
      isSearchable: false,
      isFilterable: true,
      ellipsis: true,
      canAccess: () => isUserTachoEnabled && isCompanyTachoEnabled,
      getValue: getNextBreak,
      sorter: Comparators.Date(getNextBreak),
      render: (value, rowData, rowIndex) => {
        const nextBreakBy = getNextBreak(rowData);
        return nextBreakBy ? format(nextBreakBy, localization.formats.time.formats.dby_imp) : '';
      }
    },
    {
      title: twoLineEllipsisCell(t('Tracking.EV.Soc')),
      label: t('Tracking.EV.Soc'),
      dataIndex: 'soc',
      key: 'soc',
      type: ColumnTypes.Number,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 130,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      getValue: getEvSoc,
      sorter: (a, b) => {
        const aMeter =
          a.vehicleStats?.evDeviceMeters?.find(e => e.type === 'hv_soc')?.value ?? null;
        const bMeter =
          b.vehicleStats?.evDeviceMeters?.find(e => e.type === 'hv_soc')?.value ?? null;
        return (aMeter === null) - (bMeter === null) || +(aMeter > bMeter) || -(aMeter < bMeter);
      },
      render: (value, rowData, rowIndex) => {
        const socData = getEvSoc(rowData);
        const valueInRange = (value, min, max) => value >= min && value <= max;
        switch (true) {
          case socData === 0:
            return (
              <div className={styles.evSoc}>
                <Icon srcIcon={BatteryIcons.BatteryIcon0} className={styles.battery} />
                <span>{`${socData}%`}</span>
              </div>
            );
          case valueInRange(socData, 1, 19):
            return (
              <div className={styles.evSoc}>
                <Icon srcIcon={BatteryIcons.BatteryIcon1} className={styles.battery} />
                <span>{`${socData}%`}</span>
              </div>
            );
          case valueInRange(socData, 20, 39):
            return (
              <div className={styles.evSoc}>
                <Icon srcIcon={BatteryIcons.BatteryIcon2} className={styles.battery} />
                <span>{`${socData}%`}</span>
              </div>
            );
          case valueInRange(socData, 40, 59):
            return (
              <div className={styles.evSoc}>
                <Icon srcIcon={BatteryIcons.BatteryIcon3} className={styles.battery} />
                <span>{`${socData}%`}</span>
              </div>
            );
          case valueInRange(socData, 60, 79):
            return (
              <div className={styles.evSoc}>
                <Icon srcIcon={BatteryIcons.BatteryIcon4} className={styles.battery} />
                <span>{`${socData}%`}</span>
              </div>
            );
          case valueInRange(socData, 80, 100):
            return (
              <div className={styles.evSoc}>
                <Icon srcIcon={BatteryIcons.BatteryIcon5} className={styles.battery} />
                <span>{`${socData}%`}</span>
              </div>
            );
          default:
            return null;
        }
      }
    },
    {
      title: twoLineEllipsisCell(`${t('Tracking.EV.Range')} (${localization.formats.speed.unit})`),
      label: `${t('Tracking.EV.Range')} (${localization.formats.speed.unit})`,
      dataIndex: 'range',
      key: 'range',
      type: ColumnTypes.Number,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 130,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      getValue: getEvRange,
      sorter: (a, b) => {
        const aMeter =
          a.vehicleStats?.evDeviceMeters?.find(e => e.type === 'hv_range')?.value ?? null;
        const bMeter =
          b.vehicleStats?.evDeviceMeters?.find(e => e.type === 'hv_range')?.value ?? null;
        return (aMeter === null) - (bMeter === null) || +(aMeter > bMeter) || -(aMeter < bMeter);
      },
      render: (value, rowData, rowIndex) => {
        const rangeData = getEvRange(rowData);
        return rangeData !== null && rangeData >= 0 ? localization.formatDistance(rangeData) : null;
      }
    },
    {
      title: t('SmartJobs.Runsheet'),
      label: t('SmartJobs.Runsheet'),
      dataIndex: 'runsheets',
      key: 'runsheet',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 130,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      canAccess: () => isUserSmartJobsEnabled && isCompanySmartJobsEnabled,
      getValue: getRunsheetsValue,
      sorter: Comparators.String(getRunsheetsValue),
      render: runsheetsCellRenderer
    },
    {
      title: twoLineEllipsisCell(t('Tracking.LastEvent')),
      label: t('Tracking.LastEvent'),
      dataIndex: ['vehicleStats', 'lastEventType'],
      key: 'lastEventType',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 90,
      isSearchable: false,
      isFilterable: false,
      ellipsis: false,
      canAccess: () => hasTrackingLastUpdateColumn,
      getValue: getLastEventValue,
      sorter: Comparators.String(getLastEventValue),
      render: lastEventRenderer
    },
    {
      title: twoLineEllipsisCell(t('Tracking.CommunicationChannel')),
      label: t('Tracking.CommunicationChannel'),
      dataIndex: ['vehicleStats', 'lastEventOrigin'],
      key: 'lastEventOrigin',
      type: ColumnTypes.String,
      isConfigurable: true,
      isSelectedByDefault: false,
      width: 150,
      isSearchable: true,
      isFilterable: true,
      ellipsis: true,
      canAccess: () => hasIridiumService,
      getValue: getCommunicationChannelValue,
      sorter: Comparators.String(getCommunicationChannelValue),
      render: communicationChannelRenderer
    },
    {
      title: '',
      key: 'action',
      type: ColumnTypes.None,
      isConfigurable: false,
      isSelectedByDefault: true,
      fixed: 'right',
      align: 'center',
      width: 35,
      className: styles.noSidePadding,
      render: record => (
        <ActionsMenu
          device={record}
          onClick={e => e.stopPropagation()}
          openShareLiveLocationModal={openShareLiveLocationModal}
        />
      )
    }
  ];

  // Store columns in state to support resize of columns feature
  const [columns, setColumns] = useState(getUserColumns(allColumns, userGridSettings));

  const filteredData = useMemo(() => {
    let filteredData = [];

    if (userGridSettings && userGridSettings.lastFetched) {
      filteredData = data;
      const filterFleets = getFilterFleets(userGridSettings);

      // apply fleet filter if companyId matches current company
      if (filterFleets && filterFleets.companyId === currentCompany.id) {
        if (!filterFleets.includeAllFleets) {
          filteredData = data.filter(device =>
            device.fleets.some(fleet => filterFleets.includedFleetIds.includes(fleet.id))
          );
        }
      }
    }

    //Update LiveRefresh deviceStats
    if (isMqttConnected && Object.entries(deviceUpdates)) {
      filteredData = filteredData.map(entry => {
        let devices = { ...entry };

        if (deviceUpdates[entry.id]) {
          devices = {
            ...devices,
            deviceStats: {
              ...entry.deviceStats,
              location: deviceUpdates[entry.id]?.location,
              ignition: deviceUpdates[entry.id]?.ignition,
              ...(deviceUpdates[entry.id]?.lastEventAt && {
                lastEventAt: deviceUpdates[entry.id]?.lastEventAt
              })
            }
          };
        }

        return devices;
      });
    }

    return filteredData;
  }, [data, userGridSettings, currentCompany, deviceUpdates, isMqttConnected]);

  const { allAccessableColumns, viewsGridFilters } = useMemo(() => {
    // put in memo so columns and viewsGridFilters don't change every render call
    const allAccessableColumns = getAccessableColumns(allColumns);

    const viewsGridFilters = getViewsGridFilters(userGridSettings, allAccessableColumns);

    return {
      allAccessableColumns,
      viewsGridFilters
    };
  }, [userGridSettings, currentCompany]);

  useEffect(() => {
    const newColumns = getUserColumns(allAccessableColumns, userGridSettings);
    if (newColumns && newColumns.length > 0) {
      setColumns(newColumns);
    }
  }, [userGridSettings, allAccessableColumns, currentCompany]);

  const columnsForGrid = EnableColumnResize
    ? addResizeToColumns(columns, setColumns, handleResizeColumnsStop, handleColumnHeaderClick)
    : columns;

  const isUserGridSettingsLoaded =
    userGridSettings && userGridSettings.lastFetched && userGridSettings.config;

  const handleSelectChange = newSelectedRowKeys => {
    console.debug('TrackGrid - handleSelectChange', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleExpandChange = newExpandedRowKeys => {
    console.debug('TrackGrid - handleExpandChange', newExpandedRowKeys);
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
    type: 'radio',
    columnWidth: 0
  };

  useEffect(() => {
    const deviceToExpand = data.find(device => String(device.id) === String(deviceId));

    // data changes often during auto-refresh so only trigger expand if not already expanded
    if (deviceToExpand && !expandedRowKeys.includes(deviceToExpand.id)) {
      handleRowExpand(true, deviceToExpand);
    }
  }, [deviceId, data]);

  return columnsForGrid != null ? (
    <Grid
      data={filteredData}
      columns={columnsForGrid}
      allColumns={allAccessableColumns}
      fleets={fleets}
      rowKey={'id'}
      recordTypeSingular={'Vehicle'}
      recordTypePlural={'Vehicles'}
      useViewsGridHeader={true}
      viewsGridFilters={viewsGridFilters}
      onFilteredDevicesChanged={onFilteredDevicesChanged}
      isLoading={isLoading && isUserGridSettingsLoaded}
      gridSettingsKey={gridSettingsKey}
      defaultGridConfig={defaultGridConfig}
      showFilters={false}
      expandIconAsCell={true}
      expandIconColumnIndex={2}
      expandIcon={props => ExpandIcon(props)}
      expandedRowKeys={expandedRowKeys}
      rowSelection={rowSelection}
      hideSelectAll={true}
      onRowClick={handleRowClick}
      onRowExpand={handleRowExpand}
      enableMultipleExpansion={false}
      enableVirtualScrolling={EnableVirtualScrolling}
      enableColumnResize={EnableColumnResize}
      tableLayout="fixed"
      expandable={{
        expandedRowRender: record => (
          <TrackEvents
            device={record}
            trips={trips}
            tab={tab}
            isLoading={isTripsLoading}
            gridSettingsKey={gridSettingsKey}
            dateRange={dateRange}
            datePickerRef={datePickerRef}
            selectedTrip={selectedTrip}
            selectedEvent={selectedEvent}
            onDateRangeSelected={onDateRangeSelected}
            onDateRangeClose={onDateRangeClose}
            onTripClicked={onTripSegmentSelected}
            onEventClicked={onEventClicked}
            onDeviceClicked={onDeviceClicked}
            groupByTripsEnabled={groupByTripsEnabled}
            onGroupByTripsChange={onGroupByTripsChange}
          />
        ),
        rowExpandable: record => record.name !== 'Not Expandable'
      }}
      {...props}
    />
  ) : (
    <></>
  );
};
