import { useMemo } from 'react';
import { useVehiclesStats, useIsFetchingVehicleStats } from 'features/vehicles/vehiclesStatsSlice';
import { useDevicesStats, useIsFetchingDevicesStats } from 'features/devices/devicesStatsSlice';
import {
  useFleets,
  useIsFetching,
  extractVehiclesMap,
  extractDevicesMap
} from 'features/fleets/fleetsSlice';
import { DEVICE_DYNAMIC_LINKED_VEHICLE } from './constants';

const getDynamicLinkedVehicleDevicePairs = ({ vehiclesStats, devicesStats, fleets }) => {
  const vehiclesFromFleets = extractVehiclesMap(fleets || []) || [];
  const devicesFromFleets = extractDevicesMap(fleets || []) || [];
  const vehicleStatsMap = vehiclesStats?.reduce((r, vs) => {
    r[vs.vehicleId] = vs;
    return r;
  }, {});
  const deviceStatsMap = devicesStats?.reduce((r, ds) => {
    r[ds.deviceId] = ds;
    return r;
  }, {});

  const getVehicleFromFleets = vehicleId => (vehicleId ? vehiclesFromFleets?.[vehicleId] : null);
  const getVehicleFromStats = vehicleId => (vehicleId ? vehicleStatsMap?.[vehicleId] : null);
  const getDeviceFromFleets = deviceId => (deviceId ? devicesFromFleets[deviceId] : null);
  const getDeviceFromStats = deviceId => (deviceId ? deviceStatsMap?.[deviceId] : null);

  const getVehicleCurrentAssociatedDevice = vehicleId => {
    const vehicleStats = getVehicleFromStats(vehicleId);
    const deviceStats = getDeviceFromStats(vehicleStats?.currentDeviceId);
    const isVehicleDevicePaired =
      !!deviceStats?.currentVehicleId &&
      !!vehicleStats?.vehicleId &&
      parseInt(vehicleStats.vehicleId) === parseInt(deviceStats?.currentVehicleId);

    const deviceFromFleets = getDeviceFromFleets(vehicleStats?.currentDeviceId);
    const vehicleFromFleets = getVehicleFromFleets(vehicleId);
    //I don't believe the API return a fleet list that has a device been associated to multiple vehicles
    //so comment the code below
    /*const deviceStaticLinkedVehicles = getDeviceStaticLinkedVehicles(vehicleStats?.currentDeviceId);
    
    const isDeviceStaticLinkedToOtherVehicles =
      deviceStaticLinkedVehicles.length &&
      deviceStaticLinkedVehicles.every(
        deviceStaticLinkedVehicle => parseInt(deviceStaticLinkedVehicle.id) !== parseInt(vehicleId)
      );
    */
    const isDeviceStaticLinkedToOtherVehicles =
      vehicleFromFleets?.devices?.some(d => d.id === vehicleStats?.currentDeviceId) === true;
    const currentDeviceId =
      isVehicleDevicePaired && !isDeviceStaticLinkedToOtherVehicles ? deviceStats.deviceId : null;

    //permission check, deviceFromFleets may not accessable for user
    const currentDeviceIdIsAccessable = currentDeviceId && deviceFromFleets;

    return {
      deviceId: currentDeviceIdIsAccessable ? currentDeviceId : null,
      vehicleId,
      deviceStats,
      deviceFromFleets,
      vehicleStats,
      vehicleFromFleets
    };
  };

  const getVehicleDynamicLinkedDevice = vehicleId => {
    const vehicle = getVehicleFromFleets(vehicleId);
    const vehicleCurrentDevice = getVehicleCurrentAssociatedDevice(vehicleId);
    const isVehicleCurrentDeviceDynamicLinked =
      !!vehicleCurrentDevice.deviceId &&
      vehicle &&
      (vehicle.devices || []).every(
        vDevice => parseInt(vDevice.id) !== parseInt(vehicleCurrentDevice.deviceId)
      );
    return isVehicleCurrentDeviceDynamicLinked ? vehicleCurrentDevice : null;
  };

  const dynamicLinkedVehicleDevicePairs =
    vehiclesStats?.reduce((r, vs) => {
      const vehicleDevicePair = getVehicleDynamicLinkedDevice(vs.vehicleId);
      if (!!vehicleDevicePair) {
        r.push(vehicleDevicePair);
      }
      return r;
    }, []) || [];

  return dynamicLinkedVehicleDevicePairs;
};

//fetchFleets already use embed devices and vehicles, no need to have another embed
export const useFleetsWithDynamicLinkVehicleDevice = () => {
  const vehiclesStats = useVehiclesStats();
  const devicesStats = useDevicesStats();
  const fleets = useFleets();

  const isFetchingVehicleStats = useIsFetchingVehicleStats();
  const isFetchingDevicesStats = useIsFetchingDevicesStats();
  const isFetchingFleets = useIsFetching();

  const isLoading = useMemo(
    () => isFetchingVehicleStats || isFetchingDevicesStats || isFetchingFleets,
    [isFetchingVehicleStats, isFetchingDevicesStats, isFetchingFleets]
  );

  const fleetsWithDynamicLinkVehicleDevice = useMemo(
    () =>
      isLoading
        ? []
        : getFleetsByDynamicLinkVehicleDevice(
            fleets,
            getDynamicLinkedVehicleDevicePairs({
              vehiclesStats,
              devicesStats,
              fleets
            })
          ),
    [isLoading, vehiclesStats, fleets, devicesStats]
  );

  return {
    isFetchingFleets: isLoading,
    fleets: fleetsWithDynamicLinkVehicleDevice
  };
};

const getFleetsByDynamicLinkVehicleDevice = (rawfleets, dynamicLinkedVehicleDevicePairs) => {
  const NoFleet = Symbol('NoFleet');
  //associate dynamic linking device to its vehicle
  const unpluginFleets = {};
  let ret = rawfleets.map(fleet => {
    const fleetInfo = JSON.parse(JSON.stringify(fleet));
    fleetInfo.vehicles.forEach(vehicle => {
      const vehicleDynamicLinedDevice = dynamicLinkedVehicleDevicePairs?.find(
        pair => parseInt(pair.vehicleId) === parseInt(vehicle.id)
      );
      if (vehicleDynamicLinedDevice) {
        const { deviceFromFleets } = vehicleDynamicLinedDevice;
        deviceFromFleets.vehicleInfo = {
          id: vehicle.id,
          name: vehicle.name
        };
        deviceFromFleets[DEVICE_DYNAMIC_LINKED_VEHICLE] = vehicle;
        deviceFromFleets.vehicle = vehicle || {};
        //get rid of //JSON String Circle Error
        deviceFromFleets.vehicle = Object.keys(deviceFromFleets.vehicle)
          .filter(vehiclePropKey => vehiclePropKey !== 'devices')
          .reduce((a, key) => ({ ...a, [key]: deviceFromFleets.vehicle[key] }), {});
        vehicle.devices = vehicle.devices || [];
        vehicle.devices.push(deviceFromFleets);
        //remove standalone device from fleet later
        const deviceBelongFleets = rawfleets.filter(f =>
          deviceFromFleets?.fleetInfo.some(
            item => parseInt(f.id, 10)?.toString() === parseInt(item?.id, 10)?.toString()
          )
        );
        const unpluginFromFleets = deviceBelongFleets.filter(deviceBelongFleet => {
          const fleetStandaloneDevices =
            (deviceBelongFleet.vehicles || []).find(fleetVehicle => !fleetVehicle.id)?.devices ||
            [];
          return fleetStandaloneDevices.some(
            fleetStandaloneDevice =>
              parseInt(fleetStandaloneDevice.id) === parseInt(deviceFromFleets.id)
          );
        });
        unpluginFromFleets.forEach(unpluginFromFleet => {
          const fleetId = unpluginFromFleet.id || NoFleet;
          unpluginFleets[fleetId] = unpluginFleets[fleetId] || {};
          unpluginFleets[fleetId]['fleet'] = unpluginFromFleet;
          unpluginFleets[fleetId]['devices'] = unpluginFleets[fleetId]['devices'] || [];
          unpluginFleets[fleetId]['devices'].push(deviceFromFleets);
        });
      }
    });
    return fleetInfo;
  });
  ret = ret.map(fleet => {
    const fleetId = fleet.id || NoFleet;
    const unpluginFleet = unpluginFleets[fleetId];
    if (unpluginFleet) {
      fleet.vehicles = fleet.vehicles.map(fV => {
        if (!fV.id) {
          return {
            ...fV,
            devices: fV.devices.filter(fStandaloneDevice =>
              unpluginFleet.devices.every(
                unpluginDevice => parseInt(unpluginDevice.id) !== parseInt(fStandaloneDevice.id)
              )
            )
          };
        }
        return fV;
      });
      fleet.devices = (fleet.devices || []).filter(fStandaloneDevice =>
        unpluginFleet.devices.every(
          unpluginDevice => parseInt(unpluginDevice.id) !== parseInt(fStandaloneDevice.id)
        )
      );
    }
    return fleet;
  });

  return ret;
};

export const getFleetsWithDynamicLinkVehicleDevice = ({
  fleets = [],
  vehiclesStats = [],
  devicesStats = []
}) =>
  getFleetsByDynamicLinkVehicleDevice(
    fleets || [],
    getDynamicLinkedVehicleDevicePairs({ vehiclesStats, devicesStats, fleets })
  );
