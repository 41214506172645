import { useDispatch, useSelector } from 'react-redux';
import { scorecardSelectors } from '../selectors';
import {
  fetchBuckets,
  fetchBucketDefaults,
  fetchSummary,
  fetchConfig,
  fetchScoreEvents,
  fetchEnabledEventTypes,
  fetchEnabledEventTypesBranch,
  fetchEnabledEventTypesFleet
} from '../thunks';
import { useEffect } from 'react';

export const useScoreEvents = (startAt, endAt, companyId, entityName, entityId, eventType) => {
  const dispatch = useDispatch();
  const scoreEvents = useSelector(scorecardSelectors.getScoreEvents);
  const isFetching = useSelector(scorecardSelectors.isFetchingScoreEvents);
  const isSuccess = useSelector(scorecardSelectors.isSuccessScoreEvents);
  const isError = useSelector(scorecardSelectors.isErrorScoreEvents);
  const isFetchingFinished = isSuccess || isError;

  useEffect(() => {
    if (!isFetching && !isFetchingFinished && companyId) {
      dispatch(
        fetchScoreEvents({
          startAt,
          endAt,
          companyId,
          entityName,
          entityId,
          eventType
        })
      );
    }
  }, [
    isFetching,
    isFetchingFinished,
    companyId,
    startAt,
    endAt,
    companyId,
    entityName,
    entityId,
    eventType,
    dispatch
  ]);

  return scoreEvents;
};

export const useIsFetchingScorecardEvents = () =>
  useSelector(scorecardSelectors.isFetchingScoreEvents);

export const useSummary = (
  startAt,
  endAt,
  companyId,
  entityName,
  entityId,
  groups = null,
  delayFetch = false
) => {
  const dispatch = useDispatch();
  const summary = useSelector(scorecardSelectors.getSummary);
  const isFetching = useSelector(scorecardSelectors.isFetchingSummary);
  const isSuccess = useSelector(scorecardSelectors.isSuccessSummary);
  const isError = useSelector(scorecardSelectors.isErrorSummary);
  const isFetchingFinished = isSuccess || isError;

  useEffect(() => {
    if (!isFetching && !isFetchingFinished && companyId && !delayFetch) {
      dispatch(
        fetchSummary({
          startAt,
          endAt,
          companyId,
          entityName,
          entityId,
          groups
        })
      );
    }
  }, [
    isFetching,
    isFetchingFinished,
    companyId,
    delayFetch,
    startAt,
    endAt,
    companyId,
    entityName,
    entityId,
    groups,
    dispatch
  ]);

  return summary;
};

export const useIsConfigUpdating = () => useSelector(scorecardSelectors.isUpdatingConfig);

export const useIsScorecardFetching = () => useSelector(scorecardSelectors.isFetchingSummary);

export const useBuckets = (entityName, entityId, companyId) => {
  const dispatch = useDispatch();
  const buckets = useSelector(scorecardSelectors.getBuckets);
  const isFetching = useSelector(scorecardSelectors.isFetchingBucket);
  const isSuccess = useSelector(scorecardSelectors.isSuccessBucket);
  const isError = useSelector(scorecardSelectors.isErrorBucket);
  const isFetchingFinished = isSuccess || isError;

  useEffect(() => {
    if (!isFetching && !isFetchingFinished) {
      dispatch(
        fetchBuckets({
          entityName,
          entityId,
          companyId
        })
      );
    }
  }, [isFetching, isFetchingFinished, entityName, entityId, companyId, dispatch]);

  return buckets;
};

export const useIsUpdatingBucket = () => {
  return useSelector(scorecardSelectors.isUpdatingBucket);
};

export const useBucketDefaults = () => {
  const dispatch = useDispatch();
  const bucketDefaults = useSelector(scorecardSelectors.getBucketDefaults);
  const isFetching = useSelector(scorecardSelectors.isFetchingBucketDefaults);
  const isSuccess = useSelector(scorecardSelectors.isSuccessBucketDefaults);
  const isError = useSelector(scorecardSelectors.isErrorBucketDefaults);
  const isFetchingFinished = isSuccess || isError;

  useEffect(() => {
    if (!isFetching && !isFetchingFinished) {
      dispatch(fetchBucketDefaults({}));
    }
  }, [isFetching, isFetchingFinished, dispatch]);

  return bucketDefaults;
};

export const useIsFetchingFinished = () => {
  const isSuccess = useSelector(scorecardSelectors.isSuccessSummary);
  const isError = useSelector(scorecardSelectors.isErrorSummary);
  const isFetchingFinished = isSuccess || isError;
  return isFetchingFinished;
};

export const useConfig = companyId => {
  const dispatch = useDispatch();
  const config = useSelector(scorecardSelectors.getConfig);
  const isFetching = useSelector(scorecardSelectors.isFetchingConfig);
  const isSuccess = useSelector(scorecardSelectors.isSuccessConfig);
  const isError = useSelector(scorecardSelectors.isErrorConfig);
  const isFetchingFinished = isSuccess || isError;

  useEffect(() => {
    if (!isFetching && !isFetchingFinished) {
      dispatch(fetchConfig({ companyId }));
    }
  }, [isFetching, isFetchingFinished, companyId, dispatch]);

  return config;
};

export const useIsIncludeExcludeEventsSaving = () =>
  useSelector(scorecardSelectors.isIncludeExcludeEventsSaving);

export const useEnabledEventTypes = (
  companyId,
  entityType,
  entityId,
  groups = null,
  delayFetch = false
) => {
  const dispatch = useDispatch();
  const enabledEventTypes = useSelector(scorecardSelectors.getEnabledEventTypes);
  const isFetching = useSelector(scorecardSelectors.isFetchingEnabledEventTypes);
  const isSuccess = useSelector(scorecardSelectors.isSuccessEnabledEventTypes);
  const isError = useSelector(scorecardSelectors.isErrorEnabledEventTypes);
  const isFetchingFinished = isSuccess || isError;
  const lastRequest = useSelector(scorecardSelectors.lastRequestEnabledEventTypes);

  // Whenever any of the parameters changed, make sure to trigger a new request
  // otherwise the enabled event types in the UI will not be correct
  let requestParametersChanged = false;
  if (lastRequest) {
    if (
      lastRequest.companyId !== companyId ||
      lastRequest.entityType !== entityType ||
      lastRequest.entityId !== entityId
    ) {
      requestParametersChanged = true;
    }
  }

  useEffect(() => {
    if (!isFetching && (!isFetchingFinished || requestParametersChanged) && !delayFetch) {
      dispatch(fetchEnabledEventTypes({ companyId, entityType, entityId, groups }));
    }
  }, [
    isFetching,
    isFetchingFinished,
    requestParametersChanged,
    delayFetch,
    companyId,
    entityType,
    entityId,
    groups,
    dispatch
  ]);

  return enabledEventTypes;
};

export const useEnabledEventTypesCombined = companyId => {
  const dispatch = useDispatch();
  let enabledEventTypesCombined = [];

  const enabledEventTypesBranch = useSelector(scorecardSelectors.getEnabledEventTypesBranch);
  const isFetchingB = useSelector(scorecardSelectors.isFetchingEnabledEventTypesBranch);
  const isSuccessB = useSelector(scorecardSelectors.isSuccessEnabledEventTypesBranch);
  const isErrorB = useSelector(scorecardSelectors.isErrorEnabledEventTypesBranch);
  const isFetchingFinishedB = isSuccessB || isErrorB;
  const lastRequestB = useSelector(scorecardSelectors.lastRequestEnabledEventTypesBranch);

  const enabledEventTypesFleet = useSelector(scorecardSelectors.getEnabledEventTypesFleet);
  const isFetchingF = useSelector(scorecardSelectors.isFetchingEnabledEventTypesFleet);
  const isSuccessF = useSelector(scorecardSelectors.isSuccessEnabledEventTypesFleet);
  const isErrorF = useSelector(scorecardSelectors.isErrorEnabledEventTypesFleet);
  const isFetchingFinishedF = isSuccessF || isErrorF;
  const lastRequestF = useSelector(scorecardSelectors.lastRequestEnabledEventTypesFleet);

  // Whenever any of the parameters changed, make sure to trigger a new request
  // otherwise the enabled event types in the UI will not be correct
  let requestParametersChangedB = false;
  let requestParametersChangedF = false;
  if (lastRequestB) {
    if (lastRequestB.companyId !== companyId) {
      requestParametersChangedB = true;
    }
  }
  if (lastRequestF) {
    if (lastRequestF.companyId !== companyId) {
      requestParametersChangedF = true;
    }
  }

  useEffect(() => {
    if (
      (!isFetchingB && (!isFetchingFinishedB || requestParametersChangedB)) ||
      (!isFetchingF && (!isFetchingFinishedF || requestParametersChangedF))
    ) {
      Promise.resolve(dispatch(fetchEnabledEventTypesBranch({ companyId }))).then(() =>
        dispatch(fetchEnabledEventTypesFleet({ companyId }))
      );
    }
  }, [
    isFetchingB,
    isFetchingFinishedB,
    requestParametersChangedB,
    isFetchingF,
    isFetchingFinishedF,
    requestParametersChangedF,
    companyId,
    dispatch
  ]);

  if (enabledEventTypesBranch.length > 0 && enabledEventTypesFleet.length > 0) {
    enabledEventTypesCombined = enabledEventTypesBranch.concat(enabledEventTypesFleet);

    return enabledEventTypesCombined.filter(eventType => eventType.enabled);
  } else {
    return;
  }
};
