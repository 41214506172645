import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  status,
  loadingFailed,
  loadingStarted,
  loadingFinished,
  actionTypes
} from 'utils/reduxFetchingUtils';
import { ewdAPI } from 'features/ewd/endpoints/ewdAPI';
import { useEwdDriverId } from '../drivers/driversSlice';

export const initialState = {
  audits: {
    byEventID: {},
    status: status
  }
};

const auditsSlice = createSlice({
  name: 'ewdAudits',
  initialState: initialState,
  reducers: {
    fetchAuditsFailure(state, { payload }) {
      state.audits.status = loadingFailed(state, payload);
    },
    fetchAuditsStart(state, { payload }) {
      state.audits.status = loadingStarted(state, payload);
    },
    fetchAuditsSuccess(state, { payload }) {
      state.audits.byEventID[payload.eventId] = payload.audit;
      state.audits.status = loadingFinished(state, payload);
    }
  }
});

export const { fetchAuditsStart, fetchAuditsSuccess, fetchAuditsFailure } = auditsSlice.actions;

export const fetchAudit = (driverId, eventId) => (dispatch, getState) => {
  const fetching = getState().ewd.audits.status.fetching;
  if (fetching !== actionTypes.processing) {
    try {
      dispatch(fetchAuditsStart());
      const req = ewdAPI.getAudits(driverId, eventId);
      req.then(result => {
        dispatch(fetchAuditsSuccess({ eventId, audit: result }));
      });
    } catch (err) {
      debugger;
      dispatch(fetchAuditsFailure(err.toString()));
    }
  }
};

export const useAudit = eventId => {
  const dispatch = useDispatch();
  const audit = useSelector(state => state.ewd.audits.byEventID)[eventId];
  const fetching = useSelector(state => state.ewd.audits.status.fetching);
  const EWDDriverId = useEwdDriverId();

  if (EWDDriverId && audit === undefined && fetching !== actionTypes.fetching) {
    dispatch(fetchAudit(EWDDriverId, eventId));
  }
  return audit;
};

export default auditsSlice.reducer;
