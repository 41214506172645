import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Popover } from 'antd';
import { CalendarPopupContent } from 'components/ant/CalendarView';
import { CalendarView, RowStatus, Tooltip } from 'components/ant';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

import {
  statusMap,
  getTableStatus,
  isRunsheetInProgress,
  isRunsheetFrozen
} from '../utils/helpers';
import { Paths } from '../utils/constants';
import { useCan, entities } from 'features/permissions';

import { handleRunsheetDeleteAction } from 'features/smartJobs/smartJobsSlice';

import style from './RunsheetCalendarView.module.scss';

const DATE_FORMAT = 'YYYY-MM-DD';
export const RunsheetCalendarView = ({ onDateChange, value, runsheets = [], onAllocate }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeRunsheetPopup, setActiveRunsheetPopup] = useState();
  const can = useCan();

  return (
    <CalendarView
      onChange={onDateChange}
      value={value}
      renderer={day => {
        const runsheetsForDay = runsheets.filter(runsheet => {
          const scheduledAt = moment(runsheet.scheduledAt).format(DATE_FORMAT);
          return (scheduledAt || '').includes(day.date);
        });

        return runsheetsForDay.map((runsheet, runsheetIndex) => {
          const isFrozen = isRunsheetFrozen(runsheet);

          return (
            <Popover
              key={runsheet?.id || runsheetIndex}
              content={
                <CalendarPopupContent
                  title={getTableStatus(runsheet.status, t, false)}
                  rows={[
                    {
                      key: t('SmartJobs.Calendar.ID'),
                      value: runsheet.externalId
                    },
                    {
                      key: t('SmartJobs.Calendar.Name'),
                      value: runsheet.name
                    },
                    {
                      key: t('SmartJobs.Calendar.Jobs'),
                      value: (runsheet.jobs || []).length
                    },
                    {
                      key: t('SmartJobs.Calendar.Vehicle'),
                      value: runsheet.vehicle?.name
                    },
                    {
                      key: t('SmartJobs.Calendar.Status'),
                      value: getTableStatus(runsheet.status, t, false)
                    }
                  ]}
                  actions={[
                    {
                      title: t('Actions.View'),
                      onClick: () =>
                        history.push(`${Paths.SMARTJOBS_VIEW}/${runsheet.id}`, {
                          id: runsheet.id
                        }),
                      entity: 'VIEW',
                      disabled: !can({ everyEntity: entities.RUNSHEET_VIEW })
                    },
                    {
                      title: t('Actions.Edit'),
                      onClick: () => {
                        history.push(`${Paths.EDIT_RUNSHEET}/${runsheet.id}`, { runsheet });
                      },
                      entity: 'UPDATE',
                      disabled: isFrozen || !can({ everyEntity: entities.RUNSHEET_UPDATE })
                    },
                    {
                      title: t('Actions.Copy'),
                      onClick: () => {
                        history.push(`${Paths.COPY_RUNSHEET}/${runsheet.id}`, { runsheet });
                      },
                      entity: 'COPY',
                      disabled: !can({ everyEntity: entities.RUNSHEET_COPY })
                    },
                    {
                      title: t('Actions.Allocate'),
                      onClick: () => {
                        // Close the popup
                        setActiveRunsheetPopup(false);

                        // Open the allocate modal
                        onAllocate(runsheet);
                      },
                      entity: 'UPDATE',
                      disabled:
                        isFrozen ||
                        isRunsheetInProgress(runsheet) ||
                        !can({ everyEntity: entities.RUNSHEET_UPDATE })
                    },
                    {
                      title: t('Actions.Delete'),
                      danger: true,
                      onClick: () => {
                        // Close the popup
                        setActiveRunsheetPopup(false);

                        confirmationModal(
                          `${t('Common.DeleteButton')} ${runsheet.name}`,
                          `${t('Common.SureDelete')} ${t('Common.runsheet')} ${runsheet.name}?`,
                          t('Common.DeleteButton'),
                          t('Common.CancelButton'),
                          () => dispatch(handleRunsheetDeleteAction(runsheet.id, runsheet.name, t)),
                          'delete'
                        );
                      },
                      entity: 'DESTROY',
                      disabled: isFrozen || !can({ everyEntity: entities.RUNSHEET_DESTROY })
                    }
                  ]}
                />
              }
              trigger="click"
              placement="right"
              open={activeRunsheetPopup === runsheet.id}
              onOpenChange={() =>
                setActiveRunsheetPopup(activeRunsheetPopup ? !activeRunsheetPopup : runsheet.id)
              }
            >
              <div className={style.calendarItemWrapper}>
                <RowStatus showIcon status={statusMap(runsheet.status.toLowerCase())}>
                  <Tooltip
                    content={runsheet.name}
                    target={
                      <>
                        <div className={style.calendarItemName}>{runsheet.name}</div>
                        {runsheet.vehicle && (
                          <div className={style.calendarItemVehicle}>{runsheet.vehicle.name}</div>
                        )}{' '}
                      </>
                    }
                  />
                </RowStatus>
              </div>
            </Popover>
          );
        });
      }}
    />
  );
};
