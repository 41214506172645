export const getHours = () => {
  const hours = [];
  for (let i = 0; i < 24; i++) {
    const hour = i
      .toString()
      .padStart(2, '0')
      .padEnd(5, ':00');
    hours.push(hour);
  }

  return hours;
};

export const getHourInterval = hour => {
  const fullHour = Math.floor(hour / 4);
  const quarter = hour % 4;

  switch (quarter) {
    case 0:
      return {
        get startTime() {
          return fullHour
            .toString()
            .padStart(2, '0')
            .padEnd(5, ':00');
        },
        get endTime() {
          return fullHour
            .toString()
            .padStart(2, '0')
            .padEnd(5, ':14');
        }
      };
    case 1:
      return {
        get startTime() {
          return fullHour
            .toString()
            .padStart(2, '0')
            .padEnd(5, ':15');
        },
        get endTime() {
          return fullHour
            .toString()
            .padStart(2, '0')
            .padEnd(5, ':29');
        }
      };
    case 2:
      return {
        get startTime() {
          return fullHour
            .toString()
            .padStart(2, '0')
            .padEnd(5, ':30');
        },
        get endTime() {
          return fullHour
            .toString()
            .padStart(2, '0')
            .padEnd(5, ':44');
        }
      };
    case 3:
      return {
        get startTime() {
          return fullHour
            .toString()
            .padStart(2, '0')
            .padEnd(5, ':45');
        },
        get endTime() {
          return fullHour
            .toString()
            .padStart(2, '0')
            .padEnd(5, ':59');
        }
      };
    default:
  }
};

const getQuarter = time => {
  switch (time) {
    case 14:
    case 15:
      return 1;
    case 29:
    case 30:
      return 2;
    case 44:
    case 45:
      return 3;
    case 59:
      return 4;
    default:
      return 0;
  }
};

export const getCalendarStateFromPayload = payload => {
  const calendarState = {};
  if (payload?.timeslots) {
    payload.timeslots.forEach(timeslot => {
      const day = +timeslot.day - 1;

      // the time we use for the calendar has values in the interval 0-96 because there are 4 * 24 quarters of hours in a full day.
      // The calendar displays quarters of hours.
      // That's why we multiply by 4.
      const startHour = +timeslot.startTime.split(':')[0] * 4;
      const startQuarter = getQuarter(+timeslot.startTime.split(':')[1]);
      const endHour = +timeslot.endTime.split(':')[0] * 4;
      const endQuarter = getQuarter(+timeslot.endTime.split(':')[1]);

      if (!calendarState[day]) {
        calendarState[day] = new Map();
      }

      for (let i = startHour + startQuarter; i < endHour + endQuarter; i += 1) {
        calendarState[day].set(i, getHourInterval(i));
      }
    });
  }

  return { calendar: calendarState };
};
