import React, { useEffect, useState } from 'react';
import { Input, Tooltip } from 'antd';

export const HintedSearchInput = ({
  hint = '',
  placeholder,
  loading,
  disabled,
  defaultValue,
  onSearch = () => {},
  ...props
}) => {
  const [value, setValue] = useState();
  useEffect(() => setValue(defaultValue), [defaultValue]);
  return (
    <Tooltip trigger={['focus']} title={hint} placement="topLeft" destroyTooltipOnHide>
      <Input.Search
        style={{
          width: 200
        }}
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder={placeholder}
        loading={loading}
        disabled={disabled}
        allowClear
        enterButton
        onSearch={(text, e) => {
          onSearch(text);
          e.target.blur();
        }}
        {...props}
      />
    </Tooltip>
  );
};
