import React, { useCallback } from 'react';
import { Button } from 'antd';
import { writeFile, utils } from 'xlsx';
import { useTranslation } from 'react-i18next';

export const FileType = {
  EXCEL: 'xlsx',
  CSV: 'csv'
};

const defaultFormater = ({ columnName, columnValue, columnDataIndex }) => ({
  columnName,
  columnValue
});

export const DeviceListExportButton = ({
  id,
  type = 'primary',
  devices,
  columns,
  fileName,
  filteType = FileType.CSV,
  formater = defaultFormater
}) => {
  const { t } = useTranslation();
  const onExport = useCallback(
    () =>
      exportFile({
        fileName: fileName || t('Common.Devices'),
        filteType,
        devices,
        columns,
        formater,
        sheetName: t('Common.Devices')
      }),
    [t, devices, columns, fileName, filteType, formater]
  );
  return (
    <Button id={id} size="medium" type={type} onClick={onExport}>
      {t('Common.Export')}
    </Button>
  );
};

const exportFile = ({
  devices = [],
  columns = [],
  fileName,
  filteType = FileType.CSV,
  sheetName,
  formater = defaultFormater
}) => {
  const workbook = utils.book_new();
  const sheet = utils.json_to_sheet(
    devices.map(device =>
      columns.reduce((cols, col) => {
        const { columnName, columnValue } = formater({
          columnName: col.title,
          columnValue: device?.[col.dataIndex],
          columnDataIndex: col.dataIndex
        });
        return {
          ...cols,
          [columnName]: columnValue
        };
      }, {})
    )
  );
  utils.book_append_sheet(workbook, sheet, sheetName);
  writeFile(workbook, `${fileName}.${filteType}`);
};
