import React, { useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import {
  useGetQsDashboardQuery,
  // useGetQsDashboardUrlQuery,
  useGetQsConsoleUrlQuery
} from 'services/nextgen';

import { setBackButton, setPageTitle, addBreadcrumbs } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useCan, services } from 'features/permissions';

import { Iframe } from 'components/iframe/Iframe';
import { LoadingCentered } from 'components/loading/Loading';
import { ToastType } from 'components/notifications/toasts/Toast';

const DashboardPage = () => {
  const can = useCan();
  const canBuilder = can({
    everyService: [services.INSIGHTSBUILDER],
    everyCompanyService: [services.INSIGHTSBUILDER]
  });
  const dispatch = useDispatch();
  const { dashboardId } = useParams();
  const { t } = useTranslation();
  const localization = useLocalization();
  const history = useHistory();
  const dashboardFrameRef = useRef(null);
  const dashboard = useGetQsDashboardQuery({ dashboardId: dashboardId });
  const dashboardUrl = useGetQsConsoleUrlQuery({ dashboardId: dashboardId });
  // const dashboardEmbedUrl = useGetQsDashboardUrlQuery({ dashboardId: dashboardId });

  useEffect(() => {
    console.debug('dashboard', { dashboard, canBuilder });
    dispatch(setPageTitle(dashboard?.data?.name ? dashboard?.data?.name : ''));
    dispatch(setBackButton(true));
    dispatch(
      addBreadcrumbs([
        {
          breadcrumbName: t('Common.Insights'),
          path: '/insights'
        },
        {}
      ])
    );

    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [dispatch, dashboard.data]);

  useEffect(() => {
    console.debug('dashboardUrl', dashboardUrl);
    if (dashboardUrl.error) {
      console.error('dashboardUrl - error', dashboardUrl.error.data);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Insights.Toasts.LoadDashboardError')
        })
      );
      history.push('/insights');
    }
  }, [dashboardUrl]);

  const isLoading = useMemo(
    () =>
      dashboard.isLoading ||
      dashboard.isFetching ||
      dashboardUrl.isLoading ||
      dashboardUrl.isFetching,
    [dashboard.isLoading, dashboard.isFetching, dashboardUrl.isLoading, dashboardUrl.isFetching]
  );

  return (
    <>
      {isLoading ? (
        <LoadingCentered />
      ) : (
        <Iframe
          id="dashboard"
          title="Dashboard"
          width={'100%'}
          style={{ border: 'none', height: '100%', backgroundColor: '#eee' }}
          src={dashboardUrl.data ? dashboardUrl.data + `&locale=${localization.locale}` : ''}
          ref={dashboardFrameRef}
        />
      )}
    </>
  );
};

export default DashboardPage;
