/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Device from "./Device";
import Gps from "./Gps";
import LocalDateTime from "./LocalDateTime";
import MessageEvent from "./MessageEvent";
import MessagePriority from "./MessagePriority";
import MessageType from "./MessageType";
import User from "./User";

/**
 * The Message model module.
 * @module model/Message
 * @version 1.0
 */
class Message {
  /**
   * Constructs a new <code>Message</code>.
   * @alias module:model/Message
   */
  constructor() {
    Message.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Message</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Message} obj Optional instance to populate.
   * @return {module:model/Message} The populated <code>Message</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Message();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("parent")) {
        obj["parent"] = Message.constructFromObject(data["parent"]);
      }
      if (data.hasOwnProperty("device")) {
        obj["device"] = Device.constructFromObject(data["device"]);
      }
      if (data.hasOwnProperty("sender")) {
        obj["sender"] = User.constructFromObject(data["sender"]);
      }
      if (data.hasOwnProperty("priority")) {
        obj["priority"] = MessagePriority.constructFromObject(data["priority"]);
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = MessageType.constructFromObject(data["type"]);
      }
      if (data.hasOwnProperty("subject")) {
        obj["subject"] = ApiClient.convertToType(data["subject"], "String");
      }
      if (data.hasOwnProperty("body")) {
        obj["body"] = ApiClient.convertToType(data["body"], "String");
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("readAt")) {
        obj["readAt"] = LocalDateTime.constructFromObject(data["readAt"]);
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = LocalDateTime.constructFromObject(data["createdAt"]);
      }
      if (data.hasOwnProperty("updatedAt")) {
        obj["updatedAt"] = LocalDateTime.constructFromObject(data["updatedAt"]);
      }
      if (data.hasOwnProperty("events")) {
        obj["events"] = ApiClient.convertToType(data["events"], [MessageEvent]);
      }
      if (data.hasOwnProperty("GPS")) {
        obj["GPS"] = Gps.constructFromObject(data["GPS"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Message.prototype["id"] = undefined;

/**
 * @member {module:model/Message} parent
 */
Message.prototype["parent"] = undefined;

/**
 * @member {module:model/Device} device
 */
Message.prototype["device"] = undefined;

/**
 * @member {module:model/User} sender
 */
Message.prototype["sender"] = undefined;

/**
 * @member {module:model/MessagePriority} priority
 */
Message.prototype["priority"] = undefined;

/**
 * @member {module:model/MessageType} type
 */
Message.prototype["type"] = undefined;

/**
 * @member {String} subject
 */
Message.prototype["subject"] = undefined;

/**
 * @member {String} body
 */
Message.prototype["body"] = undefined;

/**
 * @member {String} status
 */
Message.prototype["status"] = undefined;

/**
 * @member {module:model/LocalDateTime} readAt
 */
Message.prototype["readAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
Message.prototype["createdAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} updatedAt
 */
Message.prototype["updatedAt"] = undefined;

/**
 * @member {Array.<module:model/MessageEvent>} events
 */
Message.prototype["events"] = undefined;

/**
 * @member {module:model/Gps} GPS
 */
Message.prototype["GPS"] = undefined;

export default Message;
