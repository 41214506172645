import React from 'react';
import { FormLabel } from 'react-bootstrap';
import { Select } from 'antd';
import { getValuesForPrefilledDropdown } from 'containers/Configuration/Forms/helpers';
import { useTranslation } from 'react-i18next';
import styles from './PrefilledDropdown.module.scss';

const PrefilledDropdown = ({ element, setProperty }) => {
  const { t } = useTranslation();
  const dropdownValue = element?.DynamicContent?.source;

  return (
    ['Text', 'Numeric', 'Description'].includes(element?.Type) && (
      <>
        <div>
          <FormLabel className={styles.formLabel}>
            {t('Forms.ActualForm.FillInWithSmartjobsProperty')}
          </FormLabel>
        </div>
        <div>
          <Select
            size={'large'}
            allowClear
            showSearch
            className={styles.prefilledSelect}
            placeholder={t('Common.TypeHere')}
            options={getValuesForPrefilledDropdown({ type: element.Type })}
            value={dropdownValue}
            onChange={value => {
              setProperty('DynamicContent', { source: value });
            }}
          />
        </div>
      </>
    )
  );
};

export default PrefilledDropdown;
