import i18n from 'i18next';

import { api } from 'utils/api';
import { parseUserErrorMessage } from 'utils/strings';

import { openToast } from 'features/toasts/toastsSlice';
import { DOCUMENTS_UPLOAD_URL, DOCUMENTS_URL } from 'features/easydocs/documentsSlice';

import { ToastType } from 'components/notifications/toasts/Toast';

const VEHICLES_API = '/vehicles';
const DEVICES_API = '/devices';
const FLEETS_API = '/fleets';

export const saveVehicleApi = (method, id, payload) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyId = getState().companies.current.id;
  let url = VEHICLES_API;
  if (id) {
    url = `${VEHICLES_API}/${id}`;
  }

  try {
    const response = await api.post(
      url,
      {
        authKey,
        query: {
          company_id: companyId
        }
      },
      payload,
      method
    );

    if (!response || response.status !== 200) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `Something went wrong while saving a vehicle!`
        })
      );
    }

    return response.body || [];
  } catch (err) {
    console.error(err);
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseUserErrorMessage(err)
      })
    );
  }
};

export const associateDeviceToVehicleApi = (device, vehicleId) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyId = getState().companies.current.id;
  const payload = vehicleId ? { vehicle: { id: vehicleId } } : { vehicle: null };
  const url = `${DEVICES_API}/${device}`;

  try {
    const response = await api.put(
      url,
      {
        authKey,
        query: {
          copy_vehicle_fleets: true,
          company_id: companyId
        }
      },
      payload
    );
    return response.body || [];
  } catch (err) {
    console.error(err);
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseUserErrorMessage(err)
      })
    );
  }
};

export const associateFleetsToVehicleApi = (vehicleId, fleets) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyId = getState().companies.current.id;
  const payload = fleets;
  const url = `${VEHICLES_API}/${vehicleId}${FLEETS_API}`;

  try {
    const response = await api.put(
      url,
      {
        authKey,
        query: {
          company_id: companyId
        }
      },
      payload
    );
    return response.body || [];
  } catch (err) {
    console.error(err);
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseUserErrorMessage(err)
      })
    );
  }
};

export const attachFiles = (files, vehicleId) => async (dispatch, getState) => {
  const {
    companies: { current },
    user,
    documents
  } = getState();
  const authKey = user.current.auth.key;
  const returnedUploadResponses = [];
  // Upload the files and create an array of uploaded files ids
  for (let file of files) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('uploadParams', JSON.stringify({ overwrite: true, vehicleId }));

    try {
      const uploadResponse = await api.post(
        DOCUMENTS_UPLOAD_URL,
        {
          authKey,
          company_id: documents.uploadCompanyId || current.id
        },
        formData
      );

      returnedUploadResponses.push(uploadResponse);

      if (!uploadResponse.ok) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: i18n.t('Vehicles.Notifications.FileWasNotSaved', {
              name: file.name ?? file.filename
            })
          })
        );
      }
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('Vehicles.Notifications.FileWasNotSavedError', {
            name: file.name ?? file.filename,
            err
          })
        })
      );
    }
  }

  return returnedUploadResponses;
};

export const removeFiles = file => async (dispatch, getState) => {
  const {
    companies: { current }
  } = getState();
  const authKey = current?.api_key;

  try {
    const response = await api.delete(`${DOCUMENTS_URL}/${file.id}`, { authKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('Vehicles.Notifications.DeleteSuccess', {
            name: file?.name || file?.filename
          })
        })
      );
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: i18n.t('Vehicles.Notifications.DeleteError', {
          name: file?.name || file?.filename,
          err
        })
      })
    );
  }
};
