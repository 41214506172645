import './components/jquery.ewd.js';
import EWD from './components/EWD';
import driversReducer, * as drivers from 'features/ewd/components/drivers/driversSlice';
import eventsReducer, * as events from 'features/ewd/components/events/eventsSlice';
import auditsReducer, * as audits from 'features/ewd/components/audits/auditsSlice';
import annotationsReducer, * as annotations from 'features/ewd/components/annotations/annotationsSlice';
import keepersReducer, * as keepers from 'features/ewd/components/keepers/keepersSlice';
import violationsReducer, * as violations from 'features/ewd/components/violations/violationsSlice';
import statusReducer, * as status from 'features/ewd/components/status/statusSlice';
import reduceReducers from 'reduce-reducers';

const initialState = {
  ...drivers.initialState,
  ...events.initialState,
  ...audits.initialState,
  ...annotations.initialState,
  ...keepers.initialState,
  ...violations.initialState,
  ...status.initialState
};

const rootReducer = reduceReducers(
  initialState,
  eventsReducer,
  driversReducer,
  auditsReducer,
  annotationsReducer,
  keepersReducer,
  violationsReducer,
  statusReducer
);

export const ewdStore = {
  reducer: rootReducer
};

export default EWD;
