import { API_PATH } from '../../config';
import { ApiClient } from '../../nextgen_api/index';

export function saveEventRecord(userKey, companyId, record) {
  if (record == null) {
    return Promise.reject("Record can't be empty.");
  }

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    const method = record.id ? 'PUT' : 'POST';

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const apiQuery = record.id
      ? '/sentinel/users/{driverId}/events/{eventId}'
      : '/sentinel/users/{driverId}/events';
    apiClient.callApi(
      `${apiQuery}?company_id=${companyId}`,
      method,
      {
        driverId: record.DriverId,
        eventId: record.id
      },
      {},
      {},
      {},
      record,
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject({ err: err.toString(), resp });
        } else {
          resolve(resp.body);
        }
      }
    );
  });
  return promise;
}

export function saveSignedLog(userKey, signLogsRecord) {
  if (signLogsRecord == null) {
    return Promise.reject("Record can't be empty.");
  }

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    const method = 'POST';

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const apiQuery = '/sentinel/users/{driverId}/signlogs';
    apiClient.callApi(
      `${apiQuery}`,
      method,
      {
        driverId: signLogsRecord.DriverId
      },
      {},
      {},
      {},
      signLogsRecord.Dates,
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject({ err: err.toString(), resp });
        } else {
          resolve(resp.body);
        }
      }
    );
  });
  return promise;
}

export function saveSuggestedDriverEvent(userKey, driverId, payload) {
  if (payload == null) {
    return Promise.reject("Payload can't be empty.");
  }

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    const method = 'POST';

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const apiQuery = '/sentinel/users/{driverId}/suggest/update/de';
    apiClient.callApi(
      `${apiQuery}`,
      method,
      {
        driverId: driverId
      },
      {},
      {},
      {},
      payload,
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject({ err: err.toString(), resp });
        } else {
          resolve(resp.body);
        }
      }
    );
  });
  return promise;
}

export function saveSuggestedUDT(userKey, driverId, payload) {
  if (payload == null) {
    return Promise.reject("Payload can't be empty.");
  }

  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    const method = 'POST';

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const apiQuery = '/sentinel/users/{driverId}/suggest/update/udt';
    apiClient.callApi(
      `${apiQuery}`,
      method,
      {
        driverId: driverId
      },
      {},
      {},
      {},
      payload,
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject({ err: err.toString(), resp });
        } else {
          resolve(resp.body);
        }
      }
    );
  });
  return promise;
}
