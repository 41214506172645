import React from 'react';
import { Marker } from 'react-google-maps';
import ScorecardInfoWindow from '../info-windows/ScorecardInfoWindow';
import { getEventAttributesByScorecardEventType } from 'containers/Scorecard/ScorecardEventsHelpers';
import { useLocalization } from 'features/localization/localizationSlice';

export const ScorecardMarker = ({
  onSelected,
  enableInfoWindowActions,
  draggable = false,
  event,
  onIncludeExcludeEventClick
}) => {
  const localization = useLocalization();
  const eventAttributes = getEventAttributesByScorecardEventType(event, localization);

  return (
    <Marker
      position={{
        lat: event.GPS.Lat,
        lng: event.GPS.Lng
      }}
      icon={{
        url: require('./images/' + eventAttributes.marker),
        anchor: { x: 4, y: 2 }
      }}
      draggable={draggable}
      clickable={true}
      onClick={() => {
        onSelected();
      }}
      onClose={() => {
        onSelected();
      }}
    >
      {enableInfoWindowActions && (
        <ScorecardInfoWindow
          event={event}
          onIncludeExcludeEventClick={onIncludeExcludeEventClick}
          eventAttributes={eventAttributes}
        />
      )}
    </Marker>
  );
};
