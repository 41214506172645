import React from 'react';
import { Button as AntButton } from 'antd';

export const Button = ({ children, size, type, onClick, danger, disabled, ...props }) => {
  return (
    <AntButton
      size={size}
      type={type}
      onClick={onClick}
      disabled={disabled}
      danger={danger}
      {...props}
    >
      {children}
    </AntButton>
  );
};
