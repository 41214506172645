import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Tag, Table, Form, InputNumber } from 'antd';

import { Comparators, DefaultSortInfo, getSortOrder } from 'utils/sorting';

const numFormatter = (value, precision = 2) => {
  let num = Number(String(value)?.replace(/[^0-9.]+/g, ''));
  num = isNaN(num) ? 0 : num;
  return num.toFixed(precision);
};
const formatNumProperties = obj =>
  Object.keys(obj).reduce((a, key) => {
    const value = obj[key];
    return {
      ...a,
      [key]: !isNaN(Number(value)) && isFinite(Number(value)) ? Number(numFormatter(value)) : value
    };
  }, {});

const EditableCell = ({ record, dataIndex, title, editing, index, children, onSave, ...props }) => {
  return (
    <td {...props}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[{ required: true, message: `Please input ${title}.` }]}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <InputNumber
            style={{ width: '70px' }}
            min={0}
            max={999.99}
            step={0.1}
            onFocus={event => event.target.select()}
            onPressEnter={onSave}
            onBlur={onSave}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const MassSchemesTable = forwardRef(
  ({ data, axleGroups, onSave, isReadOnly = false, t, ...props }, ref) => {
    const [massSchemesForm] = Form.useForm();
    const [updatedData, setUpdatedData] = useState([...data]);
    const [editingKey, setEditingKey] = useState('');
    const [sortInfo, setSortInfo] = useState(DefaultSortInfo);

    const columns = [
      {
        title: t('MassManagement.Field.MassScheme'),
        key: 'name',
        dataIndex: 'name',
        defaultSortColumn: true,
        defaultSortOrder: 'ascend',
        sorter: Comparators.String('name'),
        sortOrder: getSortOrder(sortInfo, 'name'),
        render: scheme => {
          let color = '';
          switch (scheme) {
            case 'GML':
            case 'AMMS1':
              color = 'geekblue';
              break;
            case 'CML':
            case 'AMMS2':
              color = 'orange';
              break;
            case 'HML':
            case 'AMMS3':
              color = 'volcano';
              break;
            default:
              color = 'magenta';
          }

          return (
            <Tag color={color} key={scheme}>
              {scheme && scheme.toUpperCase()}
            </Tag>
          );
        }
      },
      {
        title: t('MassManagement.Field.GrossMass'),
        dataIndex: 'grossMass',
        key: 'grossMass',
        editable: true,
        sorter: Comparators.Number('grossMass'),
        sortOrder: getSortOrder(sortInfo, 'grossMass')
      },
      {
        title: t('MassManagement.Field.Steer'),
        dataIndex: 'steer',
        key: 'steer',
        editable: true,
        axleGroupIndex: 1,
        sorter: Comparators.Number('steer'),
        sortOrder: getSortOrder(sortInfo, 'steer')
      },
      {
        title: t('MassManagement.Field.Drive'),
        dataIndex: 'drive',
        key: 'drive',
        editable: true,
        axleGroupIndex: 2,
        sorter: Comparators.Number('drive'),
        sortOrder: getSortOrder(sortInfo, 'drive')
      },
      {
        title: t('MassManagement.Field.GroupNumber', { number: 3 }),
        dataIndex: 'group3',
        key: 'group3',
        editable: true,
        axleGroupIndex: 3,
        sorter: Comparators.Number('group3'),
        sortOrder: getSortOrder(sortInfo, 'group3')
      },
      {
        title: t('MassManagement.Field.GroupNumber', { number: 4 }),
        dataIndex: 'group4',
        key: 'group4',
        editable: true,
        axleGroupIndex: 4,
        sorter: Comparators.Number('group4'),
        sortOrder: getSortOrder(sortInfo, 'group4')
      },
      {
        title: t('MassManagement.Field.GroupNumber', { number: 5 }),
        dataIndex: 'group5',
        key: 'group5',
        editable: true,
        axleGroupIndex: 5,
        sorter: Comparators.Number('group5'),
        sortOrder: getSortOrder(sortInfo, 'group5')
      },
      {
        title: t('MassManagement.Field.GroupNumber', { number: 6 }),
        dataIndex: 'group6',
        key: 'group6',
        editable: true,
        axleGroupIndex: 6,
        sorter: Comparators.Number('group6'),
        sortOrder: getSortOrder(sortInfo, 'group6')
      },
      {
        title: t('MassManagement.Field.GroupNumber', { number: 7 }),
        dataIndex: 'group7',
        key: 'group7',
        editable: true,
        axleGroupIndex: 7,
        sorter: Comparators.Number('group7'),
        sortOrder: getSortOrder(sortInfo, 'group7')
      },
      {
        title: t('MassManagement.Field.GroupNumber', { number: 8 }),
        dataIndex: 'group8',
        key: 'group8',
        editable: true,
        axleGroupIndex: 8,
        sorter: Comparators.Number('group8'),
        sortOrder: getSortOrder(sortInfo, 'group8')
      }
    ];

    const mergedColumns = columns
      .filter(col => !axleGroups || !col.axleGroupIndex || col.axleGroupIndex <= axleGroups)
      .map(col => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          render: text => numFormatter(text),
          onCell: record => ({
            record,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
            onSave: save
          })
        };
      });

    const isEditing = record => {
      return record.key === editingKey;
    };

    const edit = record => {
      if (!isEditing(record)) {
        massSchemesForm.setFieldsValue({
          ...record
        });
      }
      setEditingKey(record.key);
    };

    const closeEdit = () => {
      setEditingKey('');
    };

    const save = async () => {
      if (!massSchemesForm.isFieldsTouched()) {
        return;
      }

      try {
        let row = await massSchemesForm.validateFields();
        row = formatNumProperties(row);
        const newData = [...updatedData];
        const index = newData.findIndex(item => item.id === editingKey);

        if (editingKey) {
          if (index > -1) {
            const item = newData[index];
            newData.splice(index, 1, {
              ...item,
              ...row
            });
            setUpdatedData(newData);
          } else {
            newData.push({
              ...row
            });
            setUpdatedData(newData);
          }
        }

        onSave(newData);
      } catch (errInfo) {
        console.log('Validation Failed:', errInfo);
      }
    };

    const onChange = (pagination, filters, sorter) => {
      setSortInfo(sorter);
    };

    const isMassSchemeConfigured = record => {
      const isConfigured = record.grossMass && record.steer && record.drive;
      return isConfigured;
    };

    const rowSelection = {
      selectedRowKeys: updatedData.filter(row => row.selected).map(row => row.id),
      onSelect: (record, selected, selectedRows) => {
        const index = updatedData.findIndex(row => row.id === record.id);
        const newData = [...updatedData];
        newData.splice(index, 1, {
          ...record,
          selected: selected
        });
        setUpdatedData(newData);
        onSave(newData);
      },
      onSelectAll: (selected, selectedRows) => {
        const newData = updatedData.map(row => {
          return {
            ...row,
            selected: selected
          };
        });
        setUpdatedData(newData);
        onSave(newData);
      },
      getCheckboxProps: record => {
        return {
          name: record.name,
          disabled: !isMassSchemeConfigured(record)
        };
      }
    };

    useImperativeHandle(ref, () => ({
      clearForm() {
        massSchemesForm.resetFields();
        setUpdatedData([...data]);
        setSortInfo(DefaultSortInfo);
        closeEdit();
      }
    }));

    useEffect(() => {
      setUpdatedData([...data]);
    }, [data, massSchemesForm]);

    return (
      <Form form={massSchemesForm} component={false}>
        <Table
          dataSource={updatedData.map(scheme => ({
            ...scheme,
            key: scheme.id
          }))}
          columns={mergedColumns}
          rowKey={'id'}
          components={{
            body: {
              cell: EditableCell
            }
          }}
          rowSelection={
            isReadOnly
              ? null
              : {
                  type: 'checkbox',
                  ...rowSelection
                }
          }
          onRow={record => {
            return {
              onClick: () => {
                if (!isReadOnly && !isEditing(record)) {
                  edit(record);
                } else {
                  save(record.key);
                }
              },
              onMouseLeave: () => {
                !isReadOnly && save(record.key);
                closeEdit();
              }
            };
          }}
          loading={false}
          pagination={false}
          sortDirections={['ascend', 'descend', 'ascend']}
          onChange={onChange}
          {...props}
        />
      </Form>
    );
  }
);
