import { createSlice } from '@reduxjs/toolkit';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH_WITHOUT_V1 } from 'config';
import moment from 'moment';
import { ApiClient, CameraApi } from 'nextgen_api';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import request from 'superagent';
import { isEqual } from 'lodash';
import { fetchCameraEvents } from './cameraApi';
import { useCan, GlobalRoles, useCanFeatureFlag, FeatureFlag } from 'features/permissions';
import { useCurrentRegion } from 'features/regions/regionsSlice';
import { useDeviceModelsList } from 'features/device_models/deviceModelsSlice';
import { CameraModelConfig, isIQCamModelId } from 'features/camera/CameraModelConfig';
import services from 'features/permissions/services';
import entities from 'features/permissions/entities';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { CameraEventTypes, CameraTabs, extractCameraError } from 'containers/home/Camera/constant';
import {
  useDevices,
  useFleets,
  useIsFetchingFinished,
  useVehicles
} from 'features/fleets/fleetsSlice';
import { useCurrentCompany, useIsLoadingCurrentCompany } from 'features/company/companySlice';
import { isIQCamera } from 'features/camera/CameraModelConfig';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { RequestQueue } from 'features/requestQueue/RequestQueue';

const REST_TIME = 90 * 1000;
const actionTypes = {
  init: 'init',
  processing: 'processing',
  error: 'error',
  done: 'done'
};

const initValues = {
  videos: {},
  status: {},
  diagnosticStatus: {},
  cameraEvents: {},
  cameraChannels: {},
  cameraHealth: {
    eventTypes: {},
    overviewFilter: {}
  }
};

function getStatusId(id, type = 'info', start, length, provider) {
  if (type === 'info') {
    return `${type}_status_${id}`;
  } else {
    return `${type}_status_${id}_${start}_${length}_${provider}`;
  }
}

const camerasSlice = createSlice({
  name: 'camerasSlice',
  initialState: initValues,
  reducers: {
    fetchCameraVideoStart(state, { payload }) {
      const statusId = payload;
      state.status[statusId] = {
        fetching: actionTypes.processing,
        fetchTime: moment().valueOf(),
        error: null
      };
    },
    fetchCameraVideoSucceeded(state, { payload }) {
      const { statusId, data } = payload;
      state.videos[statusId] = data;
      state.status[statusId].fetching = actionTypes.done;
      state.status[statusId].error = null;
      state.status[statusId].data = data;
    },
    fetchCameraVideoFailed(state, { payload }) {
      const { statusId, err } = payload;
      state.status[statusId] = {
        fetching: actionTypes.error,
        error: err
      };
    },
    fetchCameraDiagnosticStatusStart(state, { payload }) {
      state.diagnosticStatus[payload.deviceId] = state.diagnosticStatus[payload.deviceId] || {};
      state.diagnosticStatus[payload.deviceId].status = {
        isFetching: true,
        fetchTime: moment().valueOf()
      };
    },
    fetchCameraDiagnosticStatusSuccess(state, { payload }) {
      state.diagnosticStatus[payload.deviceId].status.isFetching = false;
      state.diagnosticStatus[payload.deviceId].status.error = null;
      state.diagnosticStatus[payload.deviceId].data = payload.data;
    },
    fetchCameraDiagnosticStatusFailure(state, { payload }) {
      state.diagnosticStatus[payload.deviceId].status.isFetching = false;
      state.diagnosticStatus[payload.deviceId].status.error = payload.error;
    },
    fetchCameraDiagnosticStatusCancelled(state, { payload }) {
      const hasFetched =
        (!!state.diagnosticStatus[payload.deviceId]?.status?.error ||
          !!state.diagnosticStatus[payload.deviceId]?.data) &&
        state.diagnosticStatus[payload.deviceId].status?.fetchTime &&
        moment().valueOf() - state.diagnosticStatus[payload.deviceId]?.status?.fetchTime <
          REST_TIME;
      if (!hasFetched && !!state.diagnosticStatus[payload.deviceId].status.isFetching) {
        state.diagnosticStatus[payload.deviceId] = {
          status: {
            isFetching: false,
            fetchTime: null
          },
          data: null
        };
      }
    },
    fetchCameraChannelsStart(state, { payload }) {
      state.cameraChannels[payload.deviceId] = state.cameraChannels[payload.deviceId] || {};
      state.cameraChannels[payload.deviceId].status = {
        isFetching: true,
        fetchTime: moment().valueOf()
      };
    },
    fetchCameraChannelsSuccess(state, { payload }) {
      state.cameraChannels[payload.deviceId].status.isFetching = false;
      state.cameraChannels[payload.deviceId].status.error = null;
      state.cameraChannels[payload.deviceId].data = payload.data;
    },
    fetchCameraChannelsFailure(state, { payload }) {
      state.cameraChannels[payload.deviceId].status.isFetching = false;
      state.cameraChannels[payload.deviceId].status.error = payload.error;
    },
    fetchCameraEventsStart(state, { payload }) {
      state.cameraEvents[payload.deviceId] = state.cameraEvents[payload.deviceId] || {};
      state.cameraEvents[payload.deviceId].status = {
        isFetching: true,
        dateRange: { from: payload.from, to: payload.to },
        eventTypes: payload.eventTypes
      };
    },
    fetchCameraEventsSuccess(state, { payload }) {
      state.cameraEvents[payload.deviceId].status.isFetching = false;
      state.cameraEvents[payload.deviceId].status.error = null;
      state.cameraEvents[payload.deviceId].data = payload.data;
    },
    fetchCameraEventsFailure(state, { payload }) {
      state.cameraEvents[payload.deviceId].status.isFetching = false;
      state.cameraEvents[payload.deviceId].status.error = payload.error;
    },
    updateCameraHealthOverviewFilter(state, { payload }) {
      state.cameraHealth = state.cameraHealth || {};
      state.cameraHealth.overviewFilter = state.cameraHealth.overviewFilter || {};
      state.cameraHealth.overviewFilter[payload.companyId] =
        state.cameraHealth.overviewFilter[payload.companyId] || {};
      if (payload.multiple) {
        for (const filterBy of payload.filterBy) {
          const [key, value] = filterBy;
          state.cameraHealth.overviewFilter[payload.companyId][key] = value;
        }
      } else {
        const [key, value] = payload.filterBy;
        state.cameraHealth.overviewFilter[payload.companyId][key] = value;
      }
      state.cameraHealth.overviewFilter[payload.companyId].filterTree = {
        ...(state.cameraHealth.overviewFilter[payload.companyId].filterTree || {
          search: null,
          companyOptions: [],
          fleetOptions: [],
          vehicleOptions: [],
          deviceOptions: [],
          eventTypeOptions: [],
          selectedDateRange: [
            moment()
              .startOf('month')
              .startOf('day'),
            moment()
              .endOf('month')
              .endOf('day')
          ]
        }),
        ...payload.filterTree
      };
      state.cameraHealth.overviewFilter[payload.companyId].filterTree.pristine = !!payload
        .filterTree.pristine;
    },
    fetchCameraHealthEventTypesStart(state) {
      state.cameraHealth = state.cameraHealth || {};
      state.cameraHealth.eventTypes = state.cameraHealth.eventTypes || {};
      state.cameraHealth.eventTypes.isFetching = true;
      state.cameraHealth.eventTypes.types = [];
      state.cameraHealth.eventTypes.fetchTime = moment().valueOf();
    },
    fetchCameraHealthEventTypesSucceeded(state, { payload }) {
      state.cameraHealth.eventTypes.isFetching = false;
      state.cameraHealth.eventTypes.types = payload.types || [];
    },
    fetchCameraHealthEventTypesFailed(state, { payload }) {
      state.cameraHealth.eventTypes.isFetching = false;
      state.cameraHealth.eventTypes.error = payload.error || new Error('Unknown');
    }
  }
});

export const {
  fetchCameraVideoStart,
  fetchCameraVideoSucceeded,
  fetchCameraVideoFailed,
  fetchCameraDiagnosticStatusStart,
  fetchCameraDiagnosticStatusSuccess,
  fetchCameraDiagnosticStatusFailure,
  fetchCameraDiagnosticStatusCancelled,
  fetchCameraChannelsStart,
  fetchCameraChannelsSuccess,
  fetchCameraChannelsFailure,
  fetchCameraEventsStart,
  fetchCameraEventsSuccess,
  fetchCameraEventsFailure,
  fetchCameraHealthEventTypesStart,
  fetchCameraHealthEventTypesSucceeded,
  fetchCameraHealthEventTypesFailed,
  updateCameraHealthOverviewFilter
} = camerasSlice.actions;

export const fetchCameraVideo = (
  deviceId,
  start,
  length,
  provider,
  camera,
  checkDuplicate
) => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  if (!userKey) {
    return;
  }
  const userId = getState().user?.current?.id;
  if (!userId) {
    return;
  }

  const statusId = getStatusId(deviceId, 'video', start, length, provider);
  dispatch(fetchCameraVideoStart(statusId));
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH_WITHOUT_V1;
  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };
  const cameraApi = new CameraApi(apiClient);
  const reqPromise = new Promise((resolve, reject) => {
    cameraApi.requestVideo(
      deviceId,
      userKey,
      start,
      length,
      provider,
      camera,
      userId,
      checkDuplicate === false ? false : true,
      (err, data, resp) => {
        if (err && resp.status !== 200) {
          if (resp.body?.error || resp.body?.message) {
            reject(resp.body.error || resp.body.message);
          } else if (resp.text) {
            reject(resp.text);
          }
        } else {
          resolve(data);
        }
      }
    );
  });
  try {
    const data = await reqPromise;
    dispatch(fetchCameraVideoSucceeded({ statusId: statusId, data: data }));
  } catch (err) {
    dispatch(fetchCameraVideoFailed({ statusId: statusId, err: err }));
  }
  return statusId;
};

export const requestVideoUpload = (deviceId, eventId, provider, eventDateTime) => async (
  dispatch,
  getState
) => {
  const userKey = getState().user?.current?.auth?.key;
  if (!userKey) {
    return;
  }

  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH_WITHOUT_V1;
  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };
  const cameraApi = new CameraApi(apiClient);
  const reqPromise = new Promise((resolve, reject) => {
    cameraApi.requestUploadVideo(deviceId, eventId, provider, eventDateTime, (err, data, resp) => {
      if (err && resp.status !== 200) {
        console.error(err);
        if (resp.body?.error) {
          reject(resp.body.message || resp.body.error);
        } else if (resp.text) {
          reject(resp.text);
        }
      } else {
        resolve(data);
      }
    });
  });
  try {
    const data = await reqPromise;
    return data;
  } catch (err) {
    throw err;
  }
};

export const requestRetryVideoFootage = (deviceId, eventId, provider, eventDateTime) => async (
  dispatch,
  getState
) => {
  const userKey = getState().user?.current?.auth?.key;
  if (!userKey) {
    return;
  }

  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH_WITHOUT_V1;
  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };
  const cameraApi = new CameraApi(apiClient);
  const reqPromise = new Promise((resolve, reject) => {
    cameraApi.requestRetryVideoFootage(
      deviceId,
      eventId,
      provider,
      eventDateTime,
      (err, data, resp) => {
        if (err && resp.status !== 200) {
          if (resp.body?.error) {
            reject(resp.body.message || resp.body.error);
          } else if (resp.text) {
            reject(resp.text);
          }
        } else {
          resolve(data);
        }
      }
    );
  });
  try {
    const data = await reqPromise;
    return data;
  } catch (err) {
    throw err;
  }
};

const fetchCameraDeviceStatus = (deviceId, onError = () => {}) => async (dispatch, getState) => {
  if (getState().cameraInfo?.diagnosticStatus?.[deviceId]?.status?.isFetching) {
    return;
  }
  dispatch(fetchCameraDiagnosticStatusStart({ deviceId }));
  const userKey = getState().user.current.auth.key;
  let req = null;
  const reqHandle = RequestQueue.queueRequest(
    () =>
      new Promise((resolve, reject) => {
        req = request('GET', `${API_PATH_WITHOUT_V1}cameras/${deviceId}/status`)
          .set('Authorization', `Token token="${userKey}"`)
          .set('Content-Type', 'application/json');
        req.then((resp, error) => (error ? reject(error) : resolve(resp.body || {})));
      }),
    data => dispatch(fetchCameraDiagnosticStatusSuccess({ deviceId, data })),
    error => {
      if (onError) {
        onError(error);
      }
      dispatch(fetchCameraDiagnosticStatusFailure({ error, deviceId }));
    },
    () => {
      if (req?.abort) {
        req?.abort();
      }
      dispatch(fetchCameraDiagnosticStatusCancelled({ deviceId }));
    }
  );
  return reqHandle;
};

export const fetchCameraChannels = deviceId => (dispatch, getState) => {
  if (getState().cameraInfo?.cameraChannels?.[deviceId]?.status?.isFetching) {
    return;
  }

  dispatch(fetchCameraChannelsStart({ deviceId }));
  const userKey = getState().user.current.auth.key;

  // TODO: Remove the mode=TGE parameter after fledged implementation
  // Add the keyword 'evoPhase1' here to be mindful when removing the feature flag
  request('GET', `${API_PATH_WITHOUT_V1}cameras/${deviceId}/channels?mode=TGE`)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(response => {
      dispatch(fetchCameraChannelsSuccess({ deviceId, data: response.body }));
    })
    .catch(error => {
      dispatch(fetchCameraChannelsFailure({ error, deviceId }));
    });
};

export const useCameraDiagnosticStatus = (deviceId, periodicChecking = false) => {
  const dispatch = useDispatch();
  const diagnosticStatus = useSelector(state => state.cameraInfo.diagnosticStatus);
  const { shouldFetch, cameraStatus, isFetching } = useMemo(() => {
    const diagnostic = diagnosticStatus?.[deviceId];
    const isFetching = !!diagnostic?.status?.isFetching;
    const hasFetched = !!diagnostic?.status?.error || !!diagnostic?.data;
    const isExpired =
      periodicChecking &&
      diagnostic?.status?.fetchTime &&
      moment().valueOf() - diagnostic?.status?.fetchTime >= REST_TIME;
    return {
      shouldFetch: !isFetching && (!hasFetched || isExpired),
      cameraStatus: diagnostic?.data || {},
      isFetching
    };
  }, [diagnosticStatus, deviceId, periodicChecking]);

  const requestRef = useRef(null);
  useEffect(() => {
    if (shouldFetch) {
      dispatch(fetchCameraDeviceStatus(deviceId)).then(reqHandle => {
        requestRef.current = reqHandle;
      });
    }
  }, [shouldFetch, deviceId, requestRef]);

  useEffect(() => {
    return () => {
      if (requestRef.current) {
        RequestQueue.removeRequest(requestRef.current);
      }
    };
  }, []);

  return { isFetching, cameraStatus };
};

export const useCamerasStatus = (deviceIds = []) => {
  const dispatch = useDispatch();
  const diagnosticStatus = useSelector(state => state.cameraInfo.diagnosticStatus);
  const { ids, camerasStatus } = useMemo(() => {
    const camerasStatus = deviceIds.map(deviceId => {
      const diagnostic = diagnosticStatus?.[deviceId];
      const isFetching = !!diagnostic?.status?.isFetching;
      const hasFetched = !!diagnostic?.status?.error || !!diagnostic?.data;
      return {
        deviceId,
        shouldFetch: !isFetching && !hasFetched,
        cameraStatus: diagnostic?.data || {}
      };
    });

    return {
      ids: camerasStatus.filter(status => status.shouldFetch).map(d => d.deviceId),
      camerasStatus: deviceIds.reduce(
        (a, deviceId) => ({ ...a, [deviceId]: diagnosticStatus?.[deviceId]?.data || {} }),
        {}
      )
    };
  }, [diagnosticStatus, deviceIds]);

  const reqHandles = useRef({});

  useEffect(() => {
    if (ids?.length) {
      ids.map(deviceId =>
        dispatch(fetchCameraDeviceStatus(deviceId)).then(reqHandle => {
          reqHandles.current[deviceId] = reqHandle;
        })
      );
    }
  }, [ids, reqHandles]);

  useEffect(() => {
    return () => {
      if (Object.values(reqHandles.current).length) {
        for (const reqHandle of Object.values(reqHandles.current)) {
          RequestQueue.removeRequest(reqHandle);
        }
      }
    };
  }, []);

  return camerasStatus;
};

export const useIsFetchingCameraInfo = deviceId => {
  const camerasInfo = useSelector(state => state.cameraInfo);
  const statusId = getStatusId(deviceId);
  return deviceId ? camerasInfo.status[statusId].fetching === actionTypes.processing : false;
};

export const getCameraVideoStatus = statusId => (_, getState) => {
  const camerasInfo = getState().cameraInfo;
  return camerasInfo.status[statusId];
};

export const useDeviceCameraEvents = ({ deviceId, from, to, eventTypes: types }) => {
  const can = useCan();
  const devices = useDevices();
  const history = useHistory();
  const deviceModels = useDeviceModelsList();
  const currentRegion = useCurrentRegion();

  const { canFetch, canVRUsageCheck } = useMemo(() => {
    const device = deviceId && devices?.find(d => String(d.id) === String(deviceId));
    const isIQCamera = device && isIQCamModelId(device.model?.id, deviceModels);
    const canVRUsageCheck = !can({ oneOfRoles: [GlobalRoles.SiteAdmin] }) && isIQCamera;
    return {
      canVRUsageCheck,
      canFetch:
        device &&
        device?.type?.code === 'CAMERA' &&
        can({
          everyService: [services.CAMERA],
          everyEntity: [entities.VIDEO, entities.VIDEO_CREATE]
        })
    };
  }, [deviceId, devices, deviceModels]);

  const diagnosticStatus = useSelector(state => state.cameraInfo.diagnosticStatus);
  const { shouldFetchCamStatus, cameraStatus, isFetching: isFetchingCamStatus } = useMemo(() => {
    const diagnostic = diagnosticStatus?.[deviceId];
    const isFetching = !!diagnostic?.status?.isFetching;
    const hasFetched = !!diagnostic?.status?.error || !!diagnostic?.data;
    return {
      shouldFetchCamStatus: canVRUsageCheck && !isFetching && !hasFetched,
      cameraStatus: diagnostic?.data || {},
      isFetching
    };
  }, [diagnosticStatus, deviceId, canVRUsageCheck]);

  useEffect(() => {
    if (shouldFetchCamStatus) {
      dispatch(fetchCameraDeviceStatus(deviceId));
    }
  }, [shouldFetchCamStatus, deviceId]);

  const canPotentialCrash = useCanFeatureFlag({
    featureFlag: FeatureFlag.potentialCrashEvent.flag
  });
  const eventTypes = useMemo(
    () =>
      (types || Object.keys(CameraEventTypes)).filter(type =>
        canPotentialCrash ? true : type !== 'POTENTIAL_CRASH'
      ),
    [types, canPotentialCrash]
  );

  const dispatch = useDispatch();
  const cameraEvents = useSelector(state => state.cameraInfo.cameraEvents);
  const requestRef = useRef();
  const { shouldFetch, events, asyncFetch } = useMemo(() => {
    const readyToFetch = !!(deviceId && eventTypes?.length && from && to);
    const deviceCameraEvents = cameraEvents?.[deviceId];
    const isFetching = !!deviceCameraEvents?.status?.isFetching;
    const queryDateRange = deviceCameraEvents?.status?.dateRange;
    const queryEventTypes = deviceCameraEvents?.status?.eventTypes;
    const queryChanged =
      readyToFetch &&
      !(isEqual(queryDateRange, { from, to }) && isEqual(queryEventTypes, eventTypes));
    const hasFetched = !!deviceCameraEvents?.status?.error || !!deviceCameraEvents?.data;
    return {
      shouldFetch: readyToFetch && ((!isFetching && !hasFetched) || queryChanged),
      events: deviceCameraEvents?.data || {},
      asyncFetch: async () => {
        if (requestRef.current) {
          requestRef.current.abort();
          requestRef.current = null;
        }
        dispatch(fetchCameraEventsStart({ deviceId, from, to, eventTypes }));
        requestRef.current = dispatch(
          fetchCameraEvents(
            from,
            to,
            [deviceId],
            undefined,
            eventTypes,
            undefined,
            undefined,
            0,
            undefined,
            data => {
              const totalCount = isNaN(Number(data?.totalCount || 0))
                  ? 0
                  : Number(data?.totalCount || 0),
                firstPartEvents = data?.events || [];
              if (totalCount > firstPartEvents.length) {
                const subsqReqs = new Array(
                  Math.ceil((totalCount - firstPartEvents.length) / 100)
                ).fill(1);
                const reqIds = subsqReqs.map((_, reqIndex) =>
                  RequestQueue.queueRequest(
                    async () => {
                      const ret = {
                        success: false,
                        done: false,
                        error: null,
                        reqIndex,
                        rowOffset: reqIndex * 100 + firstPartEvents.length,
                        events: []
                      };
                      try {
                        await dispatch(
                          fetchCameraEvents(
                            from,
                            to,
                            [deviceId],
                            undefined,
                            eventTypes,
                            undefined,
                            undefined,
                            ret.rowOffset,
                            undefined,
                            respJson => {
                              ret.success = true;
                              ret.done = true;
                              ret.events = respJson?.events || [];
                            },
                            null,
                            false,
                            false
                          )
                        );
                      } catch (error) {
                        ret.success = false;
                        ret.done = true;
                        ret.error = error;
                      }
                      return ret;
                    },
                    data => {
                      subsqReqs[data.reqIndex] = data;
                      if (subsqReqs.every(req => !!req.success)) {
                        const events = subsqReqs.reduce(
                          (a, req) => [...a, ...req.events],
                          firstPartEvents
                        );
                        requestRef.current = null;
                        dispatch(
                          fetchCameraEventsSuccess({
                            deviceId,
                            data: { events, totalCount: events.length }
                          })
                        );
                      } else if (subsqReqs.every(req => !!req.done)) {
                        requestRef.current = null;
                        dispatch(
                          fetchCameraEventsFailure({
                            deviceId,
                            error: subsqReqs.reduce(
                              (errors, req) => (req.error ? [...errors, req.error] : errors),
                              []
                            )
                          })
                        );
                      }
                    },
                    null,
                    null
                  )
                );
                requestRef.current = {
                  abort: () => {
                    for (const reqId of reqIds) {
                      RequestQueue.removeRequest(reqId);
                    }
                  }
                };
              } else {
                requestRef.current = null;
                dispatch(fetchCameraEventsSuccess({ deviceId, data: data || {} }));
              }
              requestRef.current = null;
              dispatch(fetchCameraEventsSuccess({ deviceId, data: data || {} }));
            },
            error => {
              requestRef.current = null;
              dispatch(fetchCameraEventsFailure({ deviceId, error }));
            },
            false,
            false
          )
        );
        return await requestRef.current;
      }
    };
  }, [dispatch, cameraEvents, deviceId, from, to, requestRef, eventTypes]);

  useEffect(() => {
    if (canFetch && shouldFetch) {
      asyncFetch();
    }
  }, [canFetch, shouldFetch, asyncFetch]);

  const getEventRequestVideoMeta = useCallback(
    event => {
      const matchedEvent =
        event?.id && (events.events || []).find(evt => String(evt.id) === String(event.id));
      const canRequest =
        !!matchedEvent &&
        matchedEvent.deviceId &&
        matchedEvent.provider &&
        matchedEvent.timeAt &&
        can({
          everyService: [services.CAMERA],
          everyEntity: [entities.VIDEO, entities.VIDEO_CREATE]
        });
      return {
        can: canRequest && !(canVRUsageCheck && isFetchingCamStatus),
        requestStatus: matchedEvent?.attachmentStatus,
        canPriorCheck: canVRUsageCheck,
        usage: CameraModelConfig.IQCamera.getDataPlanUsage({
          dataPlan: cameraStatus?.dataPlan,
          planKeys: ['DVR_UPLOADED'],
          currentRegionCode: currentRegion?.code
        }),
        onRequest: (afterRequest, hasFeedback = true) => {
          if (canRequest) {
            dispatch(
              requestVideoUpload(
                matchedEvent.deviceId,
                matchedEvent.id,
                matchedEvent.provider,
                matchedEvent.timeAt
              )
            ).then(
              d => {
                asyncFetch();
                if (hasFeedback) {
                  dispatch(
                    openToast({
                      type: ToastType.Success,
                      linkable: true,
                      delay: 10000,
                      message: (
                        <Trans
                          i18nKey={'Footage.RequestVideoSubmitInfo.SuccessSubmitted'}
                          components={{
                            1: (
                              <Button
                                type="link"
                                onClick={_ => {
                                  history.push(
                                    Object.values(CameraTabs).find(
                                      t => t.title === 'Video Requests'
                                    )?.path
                                  );
                                }}
                              />
                            )
                          }}
                        />
                      )
                    })
                  );
                }
                if (afterRequest) {
                  afterRequest();
                }
              },
              error => {
                if (hasFeedback) {
                  dispatch(
                    openToast({
                      message: extractCameraError(error).message,
                      type: ToastType.Error
                    })
                  );
                }
                if (afterRequest) {
                  afterRequest();
                }
              }
            );
          }
        }
      };
    },
    [
      dispatch,
      events,
      asyncFetch,
      canVRUsageCheck,
      cameraStatus,
      currentRegion,
      isFetchingCamStatus
    ]
  );

  return { events, getEventRequestVideoMeta };
};

const fetchCameraHealthEventTypes = () => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  if (!userKey || getState().cameraInfo?.cameraHealth?.eventTypes?.isFetching) {
    return;
  }
  dispatch(fetchCameraHealthEventTypesStart());
  return request('GET', `${API_PATH_WITHOUT_V1}cameras/health/types`)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(response => {
      dispatch(
        fetchCameraHealthEventTypesSucceeded({
          types: response.body
        })
      );
      return response.body;
    })
    .catch(error => {
      dispatch(fetchCameraHealthEventTypesFailed({ error }));
      return error;
    });
};

export const useCameraHealthEventTypes = () => {
  const dispatch = useDispatch();
  const types = useSelector(state => state.cameraInfo.cameraHealth?.eventTypes);
  const shouldFetch =
    !types ||
    (!types.isFetching && !(types.types || types.error)) ||
    (types?.error && types.fetchTime && moment().valueOf() - types.fetchTime > REST_TIME);
  useEffect(() => {
    if (shouldFetch) {
      dispatch(fetchCameraHealthEventTypes());
    }
  }, [dispatch, shouldFetch]);

  return { types: types?.types, isFetching: shouldFetch && types?.isFetching };
};

export const useCurrentCompanyIQCameras = () => {
  const currentCompany = useCurrentCompany();
  const isFetchingCompany = useIsLoadingCurrentCompany();
  const allFleets = useFleets();
  const allVehicles = useVehicles();
  const allDevices = useDevices();
  const allIQCameras = useMemo(
    () => allDevices?.filter(d => d.type?.code === 'CAMERA' && isIQCamera(d)),
    [allDevices]
  );
  const allVehiclesWithIQCamera = useMemo(
    () =>
      allVehicles?.filter(
        v => v.id && v.devices?.some(d => d.type?.code === 'CAMERA' && isIQCamera(d))
      ),
    [allDevices]
  );
  const isFleetFetchingDone = useIsFetchingFinished();

  return {
    currentCompany,
    allVehiclesWithIQCamera,
    allIQCameras,
    allFleets,
    allVehicles,
    allDevices,
    isFetching: !(currentCompany?.id && !isFetchingCompany && isFleetFetchingDone)
  };
};
export default camerasSlice.reducer;
