import { eventSupportMultiTiered } from '../constants';
import ALERT_ACTIONS from './alertFormActions';

const defaultMultiTieredSetting = {
  selectedSecondAlertUsers: [],
  selectedThirdAlertUsers: [],
  secondAlertTimer: 0,
  thirdAlertTimer: 0,
  isSecondAlertEnabled: false,
  isThirdAlertEnabled: false
};

function alertFormReducer(state, action) {
  let isMultiTieredSupported = false;
  switch (action.type) {
    case ALERT_ACTIONS.SET_PAYLOAD:
      return { ...state, ...action.payload };
    case ALERT_ACTIONS.SWITCH_ACTIVE:
      return { ...state, active: !state.active };
    case ALERT_ACTIONS.SET_NAME:
      return { ...state, name: action.payload };
    case ALERT_ACTIONS.SWITCH_MOBILE:
      return { ...state, phone: !state.phone };
    case ALERT_ACTIONS.SWITCH_MAIL:
      return { ...state, mail: !state.mail };
    case ALERT_ACTIONS.SWITCH_DESKTOP:
      return { ...state, desktop: !state.desktop };
    case ALERT_ACTIONS.SET_EXPIRY:
      return { ...state, expiry: action.payload };
    case ALERT_ACTIONS.SET_TIMEZONE:
      return { ...state, timezone: action.payload };
    case ALERT_ACTIONS.SET_DEVICE_TYPE:
      return { ...state, deviceType: action.payload };
    case ALERT_ACTIONS.SET_METER_TYPE:
      return { ...state, meterType: action.payload };
    case ALERT_ACTIONS.SET_METER_ENTITY_TYPE:
      return { ...state, meterEntityType: action.payload };
    case ALERT_ACTIONS.SET_METER_SOURCE:
      return { ...state, meterSource: action.payload };
    case ALERT_ACTIONS.SET_METER_OPERATION:
      return { ...state, meterOperation: action.payload };
    case ALERT_ACTIONS.SET_METER_VALUE:
      return { ...state, meterValue: action.payload };
    case ALERT_ACTIONS.SET_SPEED_LIMIT:
      return { ...state, speedLimit: action.payload };
    case ALERT_ACTIONS.SET_DEVICES:
      return { ...state, selectedDevices: action.payload };
    case ALERT_ACTIONS.SET_VEHICLES:
      return { ...state, selectedVehicles: action.payload };
    case ALERT_ACTIONS.SET_FLEETS:
      return { ...state, selectedFleets: action.payload };
    case ALERT_ACTIONS.SET_DRIVERS:
      return { ...state, selectedDrivers: action.payload };
    case ALERT_ACTIONS.SET_BRANCHES:
      return { ...state, selectedBranches: action.payload };
    case ALERT_ACTIONS.SET_USERS:
      return { ...state, selectedUsers: action.payload };
    case ALERT_ACTIONS.SET_SECOND_ALERT_USERS:
      return { ...state, selectedSecondAlertUsers: action.payload };
    case ALERT_ACTIONS.SET_THIRD_ALERT_USERS:
      return { ...state, selectedThirdAlertUsers: action.payload };
    case ALERT_ACTIONS.SET_SECOND_ALERT_TIMER:
      return { ...state, secondAlertTimer: action.payload };
    case ALERT_ACTIONS.SET_THIRD_ALERT_TIMER:
      return { ...state, thirdAlertTimer: action.payload };
    case ALERT_ACTIONS.SET_FORMS:
      return { ...state, selectedForms: action.payload };
    case ALERT_ACTIONS.SET_PRETRIPS:
      return { ...state, selectedPretrips: action.payload };
    case ALERT_ACTIONS.SET_GEOFENCES:
      return { ...state, selectedGeofences: action.payload };
    case ALERT_ACTIONS.SET_MANAGED_GEOFENCES:
      return { ...state, selectedManagedGeofences: action.payload };
    case ALERT_ACTIONS.SET_DOCUMENTEXPIRATIONFOLDERS:
      return { ...state, selectedDocumentExpirationFolders: action.payload };
    case ALERT_ACTIONS.SET_SECOND_ALERT_SWITCH:
      return {
        ...state,
        isSecondAlertEnabled: action.payload,
        isThirdAlertEnabled: action.payload && state.isThirdAlertEnabled
      };
    case ALERT_ACTIONS.SET_THIRD_ALERT_SWITCH:
      return { ...state, isThirdAlertEnabled: action.payload };
    case ALERT_ACTIONS.SET_EVENT_CHECKBOX:
      isMultiTieredSupported =
        (action.payload.value || []).filter(i => eventSupportMultiTiered.includes(i)).length !== 0;

      return {
        ...state,
        eventTypes: action.payload.value,
        ...(!isMultiTieredSupported ? defaultMultiTieredSetting : {})
      };
    case ALERT_ACTIONS.SET_ROUTE_COMPLIANCE_CHECKBOX:
      return {
        ...state,
        routeTypes: {
          started: action?.payload?.value.some(val => val === 'started'),
          exit: action?.payload?.value.some(val => val === 'exit'),
          finished: action?.payload?.value.some(val => val === 'finished')
        }
      };
    case ALERT_ACTIONS.SET_SENTINEL_CHECKBOX:
      return {
        ...state,
        fatigueViolationsNotif: action.payload.value
      };
    case ALERT_ACTIONS.SET_SPEED_CHECKBOX:
      return {
        ...state,
        signposted: action.payload.value
      };
    case ALERT_ACTIONS.SET_DRIVER_AND_VEHICLE_CHECKBOX:
      return {
        ...state,
        alarmCodes: action.payload.value
      };
    case ALERT_ACTIONS.SET_TACHO_CHECKBOX:
      return {
        ...state,
        tachoCodes: action.payload.value
      };
    case ALERT_ACTIONS.SET_ELD_CHECKBOX_VIOLATIONS:
      return {
        ...state,
        eldCodes: {
          violations: action?.payload?.value,
          warnings: state?.eldCodes?.warnings
        }
      };
    case ALERT_ACTIONS.SET_ELD_CHECKBOX_WARNINGS:
      return {
        ...state,
        eldCodes: {
          violations: state?.eldCodes?.violations,
          warnings: action?.payload?.value
        }
      };
    case ALERT_ACTIONS.SET_INSPECTIONS_CHECKBOX:
      return {
        ...state,
        inspectionTypes: {
          dvir_failed: action?.payload?.value.some(val => val === 'dvir_failed'),
          dvir_passed: action?.payload?.value.some(val => val === 'dvir_passed'),
          dvir_repaired: action?.payload?.value.some(val => val === 'dvir_repaired')
        }
      };
    case ALERT_ACTIONS.SET_SMARTJOBS_CHECKBOX:
      return {
        ...state,
        smartjobTypes: {
          completed: action?.payload?.value.some(val => val === 'completed'),
          partially_completed: action?.payload?.value.some(val => val === 'partially_completed'),
          non_delivered: action?.payload?.value.some(val => val === 'non_delivered')
        }
      };
    case ALERT_ACTIONS.SET_GEOFENCES_CHECKBOX:
      return {
        ...state,
        geofencesTypes: {
          entry: action?.payload?.value.some(val => val === 'entry'),
          exit: action?.payload?.value.some(val => val === 'exit'),
          thrsh_undertime: action?.payload?.value.some(val => val === 'thrsh_undertime'),
          thrsh_overtime: action?.payload?.value.some(val => val === 'thrsh_overtime')
        }
      };
    case ALERT_ACTIONS.SET_MANAGED_GEOFENCES_CHECKBOX:
      return {
        ...state,
        managedGeofencesTypes: {
          entry: action?.payload?.value.some(val => val === 'entry'),
          exit: action?.payload?.value.some(val => val === 'exit')
        }
      };
    case ALERT_ACTIONS.SET_INPUT_NUMBER:
      return {
        ...state,
        inputNumberValue: action.payload
      };
    case ALERT_ACTIONS.SET_GPIO_TYPES:
      return {
        ...state,
        selectedGpioTypes: action.payload
      };
    case ALERT_ACTIONS.SET_SCHEDULER_CELLS:
      return {
        ...state,
        calendar: action.payload
      };
    case ALERT_ACTIONS.SET_DURESS_TYPES:
      return {
        ...state,
        selectedDuressTypes: action.payload
      };
    default:
      return state;
  }
}

export default alertFormReducer;
