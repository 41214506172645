/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import User from '../model/User';

/**
* Users service.
* @module api/UsersApi
* @version 1.0
*/
export default class UsersApi {

    /**
    * Constructs a new UsersApi. 
    * @alias module:api/UsersApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the changePassword operation.
     * @callback module:api/UsersApi~changePasswordCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Change a user's password
     * A token key is required for this call.
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/UsersApi~changePasswordCallback} callback The callback function, accepting three arguments: error, data, response
     */
    changePassword(key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling changePassword");
      }

      let pathParams = {
      };
      let queryParams = {
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/users/password', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteUser operation.
     * @callback module:api/UsersApi~deleteUserCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} id 
     * @param {module:api/UsersApi~deleteUserCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteUser(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteUser");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/users/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the findByCompanyId operation.
     * @callback module:api/UsersApi~findByCompanyIdCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/User>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Find a list of users
     * A token key is required for this call. Response is enriched to include Sentinel ruleset when applicable.
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {String} opts.embed What needs to be included in response, options: audit
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {String} opts.username username
     * @param {String} opts.partialName Partial name
     * @param {String} opts.partialExt Partial external id
     * @param {String} opts.count Limit the number of users matching 'partialName'
     * @param {module:api/UsersApi~findByCompanyIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/User>}
     */
    findByCompanyId(key, opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'embed': opts['embed'],
        'pruning': opts['pruning'],
        'key': key,
        'username': opts['username'],
        'partialName': opts['partialName'],
        'partialExt': opts['partialExt'],
        'count': opts['count'],
        'company_id': opts['company_id']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [User];
      return this.apiClient.callApi(
        '/users', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the findById operation.
     * @callback module:api/UsersApi~findByIdCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.embed What needs to be included in response, options: audit
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {module:api/UsersApi~findByIdCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    findById(id, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling findById");
      }
      
      let pathParams = {
        'id': id
      };
      let queryParams = {
        'embed': opts['embed'],
        'pruning': opts['pruning']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/users/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the generateResetPasswordToken operation.
     * @callback module:api/UsersApi~generateResetPasswordTokenCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/UsersApi~generateResetPasswordTokenCallback} callback The callback function, accepting three arguments: error, data, response
     */
    generateResetPasswordToken(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/users/password/reset/token', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the importUsers operation.
     * @callback module:api/UsersApi~importUsersCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Import a list of users
     * A token key is required for this call.
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {String} opts.fleetPermissions 
     * @param {String} opts.branchPermissions 
     * @param {String} opts.body List of users in JSON Array
     * @param {module:api/UsersApi~importUsersCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    importUsers(key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling importUsers");
      }

      let pathParams = {
      };
      let queryParams = {
        'fleetPermissions': opts['fleetPermissions'],
        'branchPermissions': opts['branchPermissions'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/users/import', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the insert operation.
     * @callback module:api/UsersApi~insertCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add a new user
     * A token key is required for this call.
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {String} opts.fleetPermissions 
     * @param {String} opts.branchPermissions 
     * @param {module:model/User} opts.body 
     * @param {module:api/UsersApi~insertCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    insert(key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling insert");
      }

      let pathParams = {
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'fleetPermissions': opts['fleetPermissions'],
        'branchPermissions': opts['branchPermissions'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/users', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the mergeUser operation.
     * @callback module:api/UsersApi~mergeUserCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {String} opts.fleetPermissions 
     * @param {String} opts.branchPermissions 
     * @param {module:model/User} opts.body 
     * @param {module:api/UsersApi~mergeUserCallback} callback The callback function, accepting three arguments: error, data, response
     */
    mergeUser(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'fleetPermissions': opts['fleetPermissions'],
        'branchPermissions': opts['branchPermissions']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/users/merge', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the resetPassword operation.
     * @callback module:api/UsersApi~resetPasswordCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Reset a user's password
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/UsersApi~resetPasswordCallback} callback The callback function, accepting three arguments: error, data, response
     */
    resetPassword(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/users/password/reset', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the update operation.
     * @callback module:api/UsersApi~updateCallback
     * @param {String} error Error message, if any.
     * @param {module:model/User} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a user
     * A token key is required for this call.
     * @param {String} id 
     * @param {String} key API key for a company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning  (default to 'B2B')
     * @param {String} opts.fleetPermissions 
     * @param {String} opts.branchPermissions 
     * @param {module:model/User} opts.body 
     * @param {module:api/UsersApi~updateCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/User}
     */
    update(id, key, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling update");
      }
      // verify the required parameter 'key' is set
      if (key === undefined || key === null) {
        throw new Error("Missing the required parameter 'key' when calling update");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'fleetPermissions': opts['fleetPermissions'],
        'branchPermissions': opts['branchPermissions'],
        'key': key
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = User;
      return this.apiClient.callApi(
        '/users/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
