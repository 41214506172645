import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18n from 'i18next';
import { canHistoryGoBack } from 'utils/methods';
import { parseErrorMessage } from 'utils/strings';

export const JOURNEYS_URL = '/journeys';

export const useIsFetching = () => useSelector(state => state.journeys.meta.journeys.isFetching);
const useIsJourneyStopsFetching = () =>
  useSelector(state => state.journeys.meta.journeyStops.isFetching);
const useJourneysList = () => useSelector(state => state.journeys.journeysList);
const useDeletedJourneysList = () => useSelector(state => state.journeys.deletedJourneysList);
const useJourneyStopsList = () => useSelector(state => state.journeys.stopsList);

const useIsListEmpty = () => useSelector(state => state.journeys.meta.journeys.isListEmpty);
const useIsStopsListEmpty = () =>
  useSelector(state => state.journeys.meta.journeyStops.isListEmpty);
const useCompanyKey = () => useSelector(state => state.journeys.meta.journeys.companyKey);
const useIsCompanyKeyDifferent = () => useCompanyKey() !== useCurrentCompanyKey();

const journeys = {
  journeysList: [],
  deletedJourneysList: [],
  stopsList: [],
  legs: null,
  waypoints: null,
  meta: {
    journeys: {
      isFetching: false,
      error: null,
      success: false,
      isListEmpty: false,
      companyKey: null
    },
    journeyStops: {
      isFetching: false,
      error: null,
      success: false,
      isListEmpty: false,
      companyKey: null
    }
  }
};

function startLoading(state, { payload }) {
  state.meta[payload].isFetching = true;
}

function loadingFailed(state, action) {
  state.meta.journeys.isFetching = false;
  state.meta.journeys.error = action.payload.err;
  state.meta.journeys.isListEmpty = true;
  state.journeysList = [];
  state.deletedJourneysList = [];
  state.meta.journeys.companyKey = action.payload.companyKey;
}

function loadingFailedJourneyStops(state, action) {
  state.meta.journeyStops.isFetching = false;
  state.meta.journeyStops.error = action.payload.err;
  state.meta.journeyStops.isListEmpty = true;
  state.journeyStops = [];
  state.meta.journeyStops.companyKey = action.payload.companyKey;
}

const journeysSlice = createSlice({
  name: 'journeys',
  initialState: journeys,
  reducers: {
    fetchJourneysStart: startLoading,
    fetchJourneysSuccess(state, { payload }) {
      state.journeysList = payload.journeysList.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
      );
      state.deletedJourneysList = payload.deletedJourneysList.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
      );
      state.meta.journeys.isFetching = false;
      state.meta.journeys.error = null;
      state.meta.journeys.isListEmpty = payload.journeysList.length === 0;
      state.meta.journeys.companyKey = payload.companyKey;
    },
    fetchJourneysFailure: loadingFailed,
    fetchJourneyStopsStart: startLoading,
    fetchJourneyStopsSuccess(state, { payload }) {
      state.stopsList = payload.stopsList;
      state.meta.journeyStops.isFetching = false;
      state.meta.journeyStops.error = null;
      state.meta.journeyStops.isListEmpty = payload.stopsList.length === 0;
      state.meta.journeyStops.companyKey = payload.companyKey;
    },
    fetchJourneyStopsFailure: loadingFailedJourneyStops
  }
});

export const {
  fetchJourneysStart,
  fetchJourneysSuccess,
  fetchJourneysFailure,
  fetchJourneyStopsStart,
  fetchJourneyStopsSuccess,
  fetchJourneyStopsFailure
} = journeysSlice.actions;

export const fetchJourneys = () => (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies.current.id;
  const userKey = getState().user.current.auth.key;

  try {
    if (!companyKey || !companyId.toString() || !userKey) {
      return;
    }
    dispatch(fetchJourneysStart('journeys'));

    const loadJourneys = new Promise((resolve, reject) => {
      const url = `${API_PATH}${JOURNEYS_URL}?direction=DOWN&company_id=${companyId}`;
      request('GET', url)
        .set('Authorization', `Token token="${userKey}"`)
        .set('Content-Type', 'application/json')
        .then(resp => {
          resolve(resp.body);
        })
        .catch(err => {
          reject(err);
        });
    });

    const loadDeletedJourneys = new Promise((resolve, reject) => {
      const url = `${API_PATH}/${JOURNEYS_URL}?direction=DOWN&status=DELETED&embed=stop,location,geofence,address&company_id=${companyId}`;
      request('GET', url)
        .set('Authorization', `Token token="${userKey}"`)
        .set('Content-Type', 'application/json')
        .then(resp => {
          resolve(resp.body);
        })
        .catch(err => {
          reject(err);
        });
    });

    Promise.all([loadJourneys, loadDeletedJourneys])
      .then(([journeysList, deletedJourneysList]) => {
        dispatch(fetchJourneysSuccess({ journeysList, deletedJourneysList, companyKey }));
      })
      .catch(err => {
        dispatch(fetchJourneysFailure({ err: err.toString(), companyKey }));
        console.warn('ERROR', err);
      });
  } catch (err) {
    dispatch(fetchJourneysFailure({ err: err.toString(), companyKey }));
  }
};

export const useJourneys = () => {
  const dispatch = useDispatch();
  const journeys = useJourneysList();
  const isFetching = useIsFetching();
  const isListEmpty = useIsListEmpty();
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();

  if (!isFetching && (isCompanyKeyDifferent || (journeys.length === 0 && !isListEmpty))) {
    dispatch(fetchJourneys());
  }
  return journeys;
};

export const useDeletedJourneys = () => {
  const deletedJourneys = useDeletedJourneysList();
  return deletedJourneys;
};

export const fetchJourneyStops = () => (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies.current.id;
  const userKey = getState().user.current.auth.key;

  try {
    if (!companyKey || !userKey || !companyId) {
      return;
    }
    dispatch(fetchJourneyStopsStart('journeyStops'));
    const url = `${API_PATH}/locations?type[]=BRANCH&type[]=DEPOT&type[]=SUPPLIER&type[]=CUSTOMER&embed=address&company_id=${companyId}`;
    request('GET', url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(resp => {
        dispatch(fetchJourneyStopsSuccess({ stopsList: resp.body, companyKey }));
      })
      .catch(err => {
        dispatch(fetchJourneyStopsFailure({ err: err.toString(), companyKey }));
      });
  } catch (err) {
    dispatch(fetchJourneyStopsFailure({ err: err.toString(), companyKey }));
  }
};

export const useJourneyStops = () => {
  const dispatch = useDispatch();

  const journeyStops = useJourneyStopsList();
  const isFetching = useIsJourneyStopsFetching();
  const isListEmpty = useIsStopsListEmpty();
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();

  if (!isFetching && (isCompanyKeyDifferent || (journeyStops.length === 0 && !isListEmpty))) {
    dispatch(fetchJourneyStops());
  }

  return journeyStops;
};

export const getAddressFromLatLng = (geocoder, latLng) => {
  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          resolve(results[0]);
        } else {
          reject();
        }
      } else {
        reject();
      }
    });
  });
};

export const deleteJourneyApi = (journey, history) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const { id, name } = journey;
  try {
    const response = await api.delete(`${JOURNEYS_URL}/${id}`, { authKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('JourneyPlanner.Notifications.Delete', { name })
        })
      );
      dispatch(fetchJourneys());
      history && canHistoryGoBack(history, '/journeyplanner');
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `Journey ${journey?.name} could not be deleted: ${err}`
      })
    );
  }
};

export const restoreJourneyApi = journey => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const { id, name } = journey;
  const url = `${API_PATH}/journeys/${id}/restore`;
  request('PUT', url)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(resp => {
      if (resp.ok) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('JourneyPlanner.Notifications.Restore', { name })
          })
        );
        dispatch(fetchJourneys());
      }
    })
    .catch(err => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
      );
    });
};

export const getJourneyById = (id, userKey, companyId) => {
  return new Promise((resolve, reject) => {
    const url = `${API_PATH}${JOURNEYS_URL}/${id}?embed=stop,location,geofence,address,leg&company_id=${companyId}`;
    request('GET', url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(resp => {
        if (resp.ok) {
          resolve(resp.body);
        } else {
          reject(resp);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default journeysSlice.reducer;
