import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Space, Typography } from 'antd';
import { Select } from 'antd';
import { useDeviceTypes } from 'features/device_types/deviceTypesSlice';
import styles from '../DevicesBulkEdit.module.scss';
import { useGpioConfigurations } from 'services/nextgen/ngGpioConfigurationApi';

export const GpioSelectModal = ({ show, onOk, onCancel }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState();
  const { data: gpioConfigurationTemplates, isFetching } = useGpioConfigurations();
  const deviceTypes = useDeviceTypes();

  const { isLoading, options } = useMemo(() => {
    const isFetchingTypes = !deviceTypes?.status?.fetching || deviceTypes?.status?.fetching === 1;

    return {
      isLoading: isFetching || isFetchingTypes,
      options: gpioConfigurationTemplates.map(t => {
        return {
          value: t.id,
          label: t.name
        };
      })
    };
  }, [deviceTypes, gpioConfigurationTemplates]);

  return (
    <Modal
      bodyStyle={{
        height: 200,
        display: 'block'
      }}
      okButtonProps={{
        disabled: !value
      }}
      okText={t('Common.Save')}
      cancelText={t('Common.Modal.Cancel')}
      title={t('Devices.BulkEdit.EditColumnTitle')}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      onOk={() => {
        onOk(value);
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Space direction="vertical" size={0} className={styles.entitySelect}>
        <Typography.Title level={5}>{`${t('Devices.ActualForm.GpioConfig')}`}</Typography.Title>
        <Select
          allowClear
          showSearch
          value={value?.deviceTypeModel}
          options={options}
          onChange={v => {
            setValue(v);
          }}
          placeholder={t('Devices.ActualForm.GpioConfigPlaceHolder')}
          notFoundContent={isLoading ? <Spin size="small" /> : null}
        />
      </Space>
    </Modal>
  );
};
