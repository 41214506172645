import { api } from 'utils/api';

const VEHICLES_IMAGE_PATH = '/vehicles/image';
const USERS_IMAGE_PATH = '/users/image';

export const saveImage = (blob, payload = {}) => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const { vehicleId, userId } = payload;
  const formData = new FormData();

  formData.append('file', blob, blob.name);

  try {
    const path = vehicleId ? VEHICLES_IMAGE_PATH : USERS_IMAGE_PATH;
    const response = await api.post(
      path,
      {
        authKey: userKey,
        query: { ...(vehicleId && { vehicle_id: vehicleId }), ...(userId && { user_id: userId }) }
      },
      formData
    );
    const { body } = response;

    return body;
  } catch (err) {
    console.error(err);
  }
};

export const removeImage = (payload = {}) => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const { vehicleId, userId } = payload;

  try {
    const path = vehicleId ? VEHICLES_IMAGE_PATH : USERS_IMAGE_PATH;
    const id = vehicleId ? vehicleId : userId;
    const response = await api.delete(`${path}/${id}`, {
      authKey: userKey
    });
    const { body } = response;

    return body;
  } catch (err) {
    console.error(err);
  }
};
