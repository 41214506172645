/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";

/**
 * The DeviceType model module.
 * @module model/DeviceType
 * @version 1.0
 */
class DeviceType {
  /**
   * Constructs a new <code>DeviceType</code>.
   * @alias module:model/DeviceType
   */
  constructor() {
    DeviceType.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>DeviceType</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DeviceType} obj Optional instance to populate.
   * @return {module:model/DeviceType} The populated <code>DeviceType</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DeviceType();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("code")) {
        obj["code"] = ApiClient.convertToType(data["code"], "String");
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("description")) {
        obj["description"] = ApiClient.convertToType(
          data["description"],
          "String"
        );
      }
      if (data.hasOwnProperty("features")) {
        obj["features"] = ApiClient.convertToType(data["features"], ["String"]);
      }
      if (data.hasOwnProperty("priority")) {
        obj["priority"] = ApiClient.convertToType(data["priority"], "Number");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
DeviceType.prototype["id"] = undefined;

/**
 * @member {String} code
 */
DeviceType.prototype["code"] = undefined;

/**
 * @member {String} name
 */
DeviceType.prototype["name"] = undefined;

/**
 * @member {String} description
 */
DeviceType.prototype["description"] = undefined;

/**
 * @member {Array.<String>} features
 */
DeviceType.prototype["features"] = undefined;

/**
 * @member {Number} priority
 */
DeviceType.prototype["priority"] = undefined;

export default DeviceType;
