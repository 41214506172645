import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Space, Tooltip } from 'antd';
import moment from 'moment';
import { useLocalization } from 'features/localization/localizationSlice';
import { useCameraDiagnosticStatus } from 'features/camera/cameraSlice';
import { format } from 'utils/dates';
import { CAMERA_DIAGNOSTIC_STATUS } from '../../helpers';
import { IQCameraSensitivity } from 'containers/home/Camera/Components/IQCameraSensitivity';

const _DiagnosticToolTip = ({ description, color, status, deviceName }) => (
  <div>
    <span style={{ color, fontWeight: 'bold', marginRight: '0.5em' }}>
      {deviceName ? `${deviceName} - ${status}` : status}
    </span>
    <span>{description}</span>
  </div>
);

const DiagnosticToolTip = React.memo(_DiagnosticToolTip);

const getDiagnosticMeta = ({ diagnostic, locale, t }) => {
  const matchedSignal = diagnostic?.signals?.find(item => item.signalStatus === diagnostic.status);
  const meta = { timestampUTC: matchedSignal?.signalReportedAt };
  switch (diagnostic.status) {
    case CAMERA_DIAGNOSTIC_STATUS.AMBER:
      meta.color = '#ffbf00';
      meta.status = t('Devices.Diagnostic.MODERATE');
      meta.description = matchedSignal?.likelyIssue || '';
      meta.description += meta.timestampUTC
        ? ` - ${format(
            moment.utc(meta.timestampUTC).toISOString(true),
            locale.formats.time.formats.dby_imsp
          )}`
        : '';
      meta.description += matchedSignal?.recommendedAction
        ? ` - ${matchedSignal?.recommendedAction || ''}`
        : '';
      break;
    case CAMERA_DIAGNOSTIC_STATUS.RED:
      meta.color = '#be3d3b';
      meta.status = t('Devices.Diagnostic.CRITICAL');
      meta.description = matchedSignal?.likelyIssue || '';
      meta.description += meta.timestampUTC
        ? ` - ${format(
            moment.utc(meta.timestampUTC).toISOString(true),
            locale.formats.time.formats.dby_imsp
          )}`
        : '';
      meta.description += matchedSignal?.recommendedAction
        ? ` - ${matchedSignal?.recommendedAction || ''}`
        : '';
      break;
    case CAMERA_DIAGNOSTIC_STATUS.GREEN:
      meta.color = '#3c763d';
      meta.status = t('Devices.Diagnostic.NORMAL');
      meta.description = meta.timestampUTC
        ? format(
            moment.utc(meta.timestampUTC).toISOString(true),
            locale.formats.time.formats.dby_imsp
          )
        : '';
      meta.hidden = true;
      break;
    case CAMERA_DIAGNOSTIC_STATUS.INSUFFICIENT_DATA:
    default:
      meta.color = '#dedede';
      meta.status = t('Devices.Diagnostic.NODATA');
      meta.description = t('Devices.Diagnostic.NoDataDesc');
      meta.hidden = true;
      break;
  }
  return meta;
};

const CameraDiagnosticStatus = ({ deviceId, deviceName, showDeviceName }) => {
  const { t } = useTranslation();
  const locale = useLocalization();

  const { cameraStatus: diagnostic } = useCameraDiagnosticStatus(deviceId);
  const { status, color, description, hidden } = useMemo(
    () => getDiagnosticMeta({ diagnostic, locale, t }),
    [diagnostic, locale, t]
  );

  return (
    <Space>
      <IQCameraSensitivity deviceId={deviceId} strong />
      {!hidden && (
        <Tooltip
          title={
            <DiagnosticToolTip
              color={color}
              status={status}
              description={description}
              deviceName={showDeviceName && deviceName}
            />
          }
          placement="rightTop"
        >
          <FontAwesomeIcon style={{ fontSize: '18px', color }} icon={faInfoCircle} />
        </Tooltip>
      )}
    </Space>
  );
};

const _CameraDiagnosis = ({
  entityType,
  diagnosableDevices: devices,
  diagnosableDeviceId: deviceId
}) =>
  !!devices?.length || !!deviceId ? (
    <>
      {entityType === 'Vehicle' &&
        devices.map(device => (
          <CameraDiagnosticStatus
            key={`${deviceId}-diagnostic`}
            deviceId={device.id}
            deviceName={device?.name}
            showDeviceName={devices.length > 1}
          />
        ))}
      {entityType === 'Device' && <CameraDiagnosticStatus deviceId={deviceId} />}
    </>
  ) : null;

export const CameraDiagnosis = React.memo(_CameraDiagnosis);
