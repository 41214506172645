import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Row } from 'react-bootstrap';
import FormSelect from 'components/form/form-select/FormSelect';
import FormInput from 'components/form/form-input/FormInput';
import FormDatePicker from 'components/form/form-date-picker/FormDatePicker';
import { useLocalization } from 'features/localization/localizationSlice';
import FormTitle from 'components/form/form-title/FormTitle';
import * as Yup from 'yup';
import moment from 'moment';
import { format } from 'utils/dates';
import { ewdAPI } from 'features/ewd/endpoints/ewdAPI';
import { API_PATH } from 'config';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import request from 'superagent';
import { useUserKey } from 'features/user/userSlice';
import { Alert, Button, Descriptions, Space } from 'antd';
import { useEWDUser } from 'features/user/EWDUserHooks';
import { useDispatch } from 'react-redux';
import { fetchUsers } from 'features/users/usersSlice';
import { actionTypes, loadingFinished, loadingStarted, status } from 'utils/reduxFetchingUtils';
import { isFunction } from 'lodash';
import i18next from 'i18next';
import { t_error } from 'i18nextConfig';
import { BUTTON_IDS } from 'utils/globalConstants';

const validationPasswordSchema = () =>
  Yup.object().shape({
    ewdDriverPassword: Yup.string()
      .required(i18next.t('Users.ValidationErrors.PasswordRequired'))
      .matches(/.{4,}/, i18next.t('Users.ValidationErrors.MustContain4Characters')),
    ewdDriverPasswordConfirm: Yup.string().when('ewdDriverPassword', {
      is: password => password?.length > 1,
      then: Yup.string()
        .oneOf([Yup.ref('ewdDriverPassword')], i18next.t('Users.ValidationErrors.PasswordsMatch'))
        .required(i18next.t('Users.ValidationErrors.PasswordsMatch')),
      otherwise: Yup.string().matches(
        /.{4,}/,
        i18next.t('Users.ValidationErrors.MustContain4Characters')
      )
    })
  });

const validationSchema = () =>
  Yup.object().shape({
    ewdDriverFirstName: Yup.string().required(
      i18next.t('Users.ValidationErrors.FirstNameRequired')
    ),
    ewdDriverLastName: Yup.string().required(i18next.t('Users.ValidationErrors.LastNameRequired')),
    ewdDriverBirthday: Yup.string().required(i18next.t('Users.ValidationErrors.DateOfBirth')),
    ewdDriverTimeZone: Yup.string().required(i18next.t('Users.ValidationErrors.RequiredTimezone')),
    ewdDriverLicenceNumber: Yup.string().required(
      i18next.t('Users.ValidationErrors.LicenceNumberRequired')
    ),
    ewdDriverLicenceState: Yup.string().required(
      i18next.t('Users.ValidationErrors.RequiredLicenceState')
    ),
    ewdDriverPassword: Yup.string().matches(
      /.{4,}/,
      i18next.t('Users.ValidationErrors.MustContain4Characters')
    ),
    ewdDriverPasswordConfirm: Yup.string()
      .matches(/.{4,}/, i18next.t('Users.ValidationErrors.MustContain4Characters'))
      .oneOf([Yup.ref('ewdDriverPassword')], i18next.t('Users.ValidationErrors.PasswordsMatch'))
  });

const Fields = ({
  isReadonly,
  localization,
  setFieldValue,
  timezones,
  licenceStateLabel,
  licenceStatePlaceholder,
  states,
  isUpdate
}) => (
  <>
    <Row>
      <FormInput
        name="ewdDriverFirstName"
        label={i18next.t('Users.Form.FirstName')}
        placeholder={i18next.t('Users.Form.FirstNamePlaceholder')}
        isReadonly={isReadonly}
        isRequired
      />
      <FormInput
        name="ewdDriverLastName"
        label={i18next.t('Users.Form.LastName')}
        placeholder={i18next.t('Users.Form.LastNamePlaceholder')}
        isReadonly={isReadonly}
        isRequired
      />
    </Row>
    <Row>
      <FormDatePicker
        name="ewdDriverBirthday"
        label={i18next.t('Users.EWDTab.Birthday')}
        format={localization.formats.time.formats.dby}
        setFieldValue={setFieldValue}
        placeholder={i18next.t('Users.EWDTab.SelectDate')}
        drops="up"
        isRequired
        keepZone
      />
      <FormSelect
        name="ewdDriverTimeZone"
        label={i18next.t('Users.EWDTab.UserTimeZone')}
        placeholder={i18next.t('Users.EWDTab.SelectTimezone')}
        values={
          timezones &&
          timezones.map(timezone => ({
            label: timezone,
            value: timezone
          }))
        }
        isDisabled={isReadonly}
        isRequired
      />
    </Row>
    <Row>
      <FormSelect
        name="ewdDriverLicenceState"
        label={licenceStateLabel}
        placeholder={licenceStatePlaceholder}
        values={states}
        isRequired
        isDisabled={isReadonly}
      />
      <FormInput
        name="ewdDriverLicenceNumber"
        label={i18next.t('Users.EWDTab.LicenceNumber')}
        placeholder={i18next.t('Users.EWDTab.LicenceNumberPlaceholder')}
        isRequired
        isReadonly={isReadonly}
      />
    </Row>
    {!isUpdate && (
      <Row>
        <FormInput
          name="ewdDriverPassword"
          type="password"
          label={i18next.t('Users.Form.Password')}
          placeholder={i18next.t('Users.Form.PasswordPlaceholder')}
          isValidated
        />
        <FormInput
          name="ewdDriverPasswordConfirm"
          type="password"
          label={i18next.t('Users.Form.PasswordConfirm')}
          placeholder={i18next.t('Users.Form.PasswordConfirmPlaceholder')}
          isValidated
        />
      </Row>
    )}
  </>
);

function ewdUserMapper(ewdUser) {
  return {
    ewdDriverFirstName: ewdUser.firstName,
    ewdDriverLastName: ewdUser.lastName,
    ewdDriverLicenceNumber: ewdUser.licenceNumber,
    ewdDriverLicenceState: ewdUser.licenceState,
    ewdDriverTimeZone: ewdUser.timeZone,
    ewdDriverBirthday: ewdUser.dob,
    username: ewdUser.username,
    ewdDriverPassword: ewdUser.password
  };
}

export const EWDAssociateDriverForm = ({
  userData,
  timezones,
  licenceStateLabel,
  licenceStatePlaceholder,
  states,
  doRefetch,
  ewdDriverUpdateRef,
  onFormUpdated
}) => {
  const userKey = useUserKey();
  const localization = useLocalization();
  const tn360UserId = userData.id;
  const dispatch = useDispatch();
  const ewdUser = useEWDUser();
  const [EWDAccount, setEWDAccount] = useState(null);
  const [EWDAccountStatus, setEWDAccountStatus] = useState(status);
  const isEWDUser = !!ewdUser?.auth?.token;
  const association = userData.associations?.find(
    assoc => assoc.domain === 'EWD' && assoc.externalId.includes('driver_id')
  );

  useEffect(() => {
    let request = false;

    const fetchEWDAccount = async () => {
      const assoc = JSON.parse(association.externalId);
      setEWDAccountStatus(loadingStarted(EWDAccountStatus, {}));
      request = await ewdAPI.driver(assoc.driver_id);
      setEWDAccountStatus(loadingFinished(EWDAccountStatus, {}));
      setEWDAccount(request);
    };

    if (isEWDUser && association && !EWDAccount && EWDAccountStatus.fetching === actionTypes.init) {
      request = fetchEWDAccount();
    }

    return () => {
      if (request && isFunction(request.abort)) {
        request.abort();
      }
    };
  }, [EWDAccount, EWDAccountStatus, association, isEWDUser]);

  const linkEWDDriverAccount = (values, formikBag) => {
    const payload = {
      firstName: values.ewdDriverFirstName,
      lastName: values.ewdDriverLastName,
      licenceNumber: values.ewdDriverLicenceNumber,
      licenceState: values.ewdDriverLicenceState,
      timeZone: values.ewdDriverTimeZone,
      dob: format(moment(values.ewdDriverBirthday).toDate(), 'YYYY-MM-DD'),
      username: userData.username,
      password: values.ewdDriverPassword
    };
    ewdAPI.driverUpsert(payload).then(response => {
      if (response.error) {
        const toastMessageError = `${response.error} - ${response.message}`;
        dispatch(
          openToast({
            message: `${i18next.t('Users.Notifications.UserEWDLinkError')}: ${toastMessageError}`,
            type: ToastType.Error
          })
        );
        formikBag.setSubmitting(false);
        return;
      }

      if (response.message === 'Existing User') {
        dispatch(
          openToast({
            message: (
              <>
                Existing driver linked. <strong>Password not updated</strong>
              </>
            ),
            type: ToastType.Info
          })
        );
      }

      let urlPath = `${API_PATH}/users`;

      request('POST', `${urlPath}/${tn360UserId}/associations/`)
        .set('Authorization', `Token token="${userKey}"`)
        .set('Content-Type', 'application/json')
        .send({
          domain: 'EWD',
          externalId: JSON.stringify({
            driver_id: response.id,
            accessToken: response.accessToken
          })
        })
        .then(() => {
          dispatch(
            openToast({
              message: i18next.t('Users.Notifications.DriverEWDLinkSuccess', {
                firstName: userData.firstName,
                lastName: userData.lastName
              }),
              type: ToastType.Success
            })
          );
          dispatch(fetchUsers());
          doRefetch();
          formikBag.setSubmitting(false);
        })
        .catch(err => {
          console.warn('ERROR', err);
          const toastMessageError = `${err.response.statusText} - ${err.response.text}`;
          dispatch(
            openToast({
              message: `${i18next.t('Users.Notifications.UserEWDLinkError')}:${toastMessageError}`,
              type: ToastType.Error
            })
          );
          formikBag.setSubmitting(false);
        });
    });
  };

  const updatePassword = (values, formikBag) => {
    const payload = {
      id: EWDAccount.id,
      newPassword: values.ewdDriverPassword,
      username: EWDAccount.username,
      referrer: 'teletracnavman.com'
    };
    ewdAPI
      .changeDriverPassword(payload)
      .then(response => {
        if (!response?.error) {
          dispatch(
            openToast({
              message: i18next.t('Users.Notifications.DriverPasswordUpdateSuccess', {
                firstName: userData.firstName,
                lastName: userData.lastName
              }),
              type: ToastType.Success
            })
          );

          formikBag.resetForm({
            values: {
              ewdDriverPassword: '',
              ewdDriverPasswordConfirm: ''
            },
            isSubmitting: false
          });
        } else {
          const toastMessageError = t_error(response);
          dispatch(
            openToast({
              message: `${i18next.t('Users.Notifications.EWDError')}: ${toastMessageError}`,
              type: ToastType.Error
            })
          );
          formikBag.setSubmitting(false);
        }
      })
      .catch(err => {
        console.warn('ERROR', err);
        const toastMessageError = `${err.response.statusText} - ${err.response.text}`;
        dispatch(
          openToast({
            message: `${i18next.t('Users.Notifications.EWDError')}:${toastMessageError}`,
            type: ToastType.Error
          })
        );
        formikBag.setSubmitting(false);
      });
  };

  const updateUsername = async username => {
    const payload = {
      id: EWDAccount.id,
      firstName: EWDAccount.firstName,
      lastName: EWDAccount.lastName,
      licenceNumber: EWDAccount.licenceNumber,
      licenceState: EWDAccount.licenceState,
      timeZone: EWDAccount.timeZone,
      dob: EWDAccount.dob ? format(new Date(EWDAccount.dob), 'YYYY-MM-DD') : '',
      username: username,
      referrer: 'teletracnavman.com'
    };

    try {
      const response = await ewdAPI.driverUpdate(payload);
      if (!response?.error) {
        dispatch(
          openToast({
            message: i18next.t('Users.Notifications.EWDUsernameUpdated'),
            type: ToastType.Success
          })
        );
      } else {
        const toastMessageError = t_error(response);
        dispatch(
          openToast({
            message: `${i18next.t('Users.Notifications.EWDError')}: ${toastMessageError}`,
            type: ToastType.Error
          })
        );
      }
    } catch (err) {
      console.error('ERROR', err);
      const toastMessageError = `${err.response.statusText} - ${err.response.text}`;
      dispatch(
        openToast({
          message: `${i18next.t('Users.Notifications.EWDError')}:${toastMessageError}`,
          type: ToastType.Error
        })
      );
    }
  };

  const updateEWDDriverDetail = async (values, formikBag) => {
    const payload = {
      id: EWDAccount.id,
      firstName: values.ewdDriverFirstName,
      lastName: values.ewdDriverLastName,
      licenceNumber: values.ewdDriverLicenceNumber,
      licenceState: values.ewdDriverLicenceState,
      timeZone: values.ewdDriverTimeZone,
      dob: format(moment(values.ewdDriverBirthday).toDate(), 'YYYY-MM-DD'),
      username: EWDAccount.username,
      referrer: 'teletracnavman.com'
    };

    await ewdAPI
      .driverUpdate(payload)
      .then(response => {
        if (!response?.error) {
          /*not open the toast as the Save is in main form
          dispatch(
            openToast({
              message: i18next.t('Users.Notifications.DriverUpdateSuccess', {
                firstName: userData.firstName,
                lastName: userData.lastName
              }),
              type: ToastType.Success
            })
          );*/
          dispatch(fetchUsers());
          doRefetch();
          setEWDAccount({
            ...EWDAccount,
            firstName: values.ewdDriverFirstName,
            lastName: values.ewdDriverLastName,
            licenceNumber: values.ewdDriverLicenceNumber,
            licenceState: values.ewdDriverLicenceState,
            timeZone: values.ewdDriverTimeZone,
            dob: format(moment(values.ewdDriverBirthday).toDate(), 'YYYY-MM-DD')
          });
          formikBag.resetForm({
            values: values,
            isSubmitting: false
          });
        } else {
          const toastMessageError = t_error(response);
          dispatch(
            openToast({
              message: `${i18next.t('Users.Notifications.EWDError')}: ${toastMessageError}`,
              type: ToastType.Error
            })
          );
          formikBag.setSubmitting(false);
        }
      })
      .catch(err => {
        console.error('ERROR', err);
        const toastMessageError = `${err.response.statusText} - ${err.response.text}`;
        dispatch(
          openToast({
            message: `${i18next.t('Users.Notifications.EWDError')}:${toastMessageError}`,
            type: ToastType.Error
          })
        );
        formikBag.setSubmitting(false);
      });
  };

  const unlinkEWDDriverAccount = (associationId, setSubmitting) => {
    let urlPath = `${API_PATH}/users`;
    request('DELETE', `${urlPath}/${tn360UserId}/associations/${associationId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(() => {
        dispatch(
          openToast({
            message: i18next.t('Users.Notifications.DriverUnlinkSuccess', {
              firstName: userData.firstName,
              lastName: userData.lastName
            }),
            type: ToastType.Success
          })
        );
        doRefetch();
        dispatch(fetchUsers());
        setSubmitting(false);
      })
      .catch(err => {
        console.warn('ERROR', err);
        const toastMessageError = `${err.response.statusText} - ${err.response.text}`;
        dispatch(
          openToast({
            message: `${i18next.t('Users.Notifications.UserEWDLinkError')}:${toastMessageError}`,
            type: ToastType.Error
          })
        );
        setSubmitting(false);
      });
  };

  if (!userData.id) {
    return (
      <div className="formContainer">
        <FormTitle title={i18next.t('Users.EWDTab.EWDDriverLink')} underlined />
        <Alert
          message={i18next.t('Users.EWDTab.EWDDriverRecord')}
          description={i18next.t('Users.EWDTab.EWDDriverRecordDescriptionWarning')}
          type="warning"
          showIcon
        />
      </div>
    );
  }

  if (association && EWDAccount?.firstName) {
    if (ewdDriverUpdateRef) {
      ewdDriverUpdateRef.current = {
        usernameUpdate: updateUsername
      };
    }

    return (
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={ewdUserMapper(EWDAccount)}
        onSubmit={(values, formikBag) => updateEWDDriverDetail(values, formikBag)}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          setFieldValue,
          setSubmitting,
          values,
          ...props
        }) => {
          if (dirty && isValid && isEWDUser) {
            if (onFormUpdated) {
              onFormUpdated();
            }

            ewdDriverUpdateRef.current = {
              detailsUpdate: async userName =>
                await updateEWDDriverDetail(values, { ...props, setSubmitting, userName }),
              ...(ewdDriverUpdateRef.current || {})
            };
          }
          return (
            <div className="formContainer">
              <FormTitle title={i18next.t('Users.EWDTab.EWDDriverLink')} underlined />
              <Space direction="vertical" style={{ width: '100%' }}>
                <Alert
                  message={i18next.t('Users.EWDTab.EWDDriverRecord')}
                  description={i18next.t('Users.EWDTab.EWDDriverRecordDescriptionSuccess')}
                  type="success"
                  showIcon
                />
                {!isEWDUser && (
                  <Alert
                    message={i18next.t('Users.EWDTab.NoAccess')}
                    description={i18next.t('Users.EWDTab.NoAccessDescriptionWarning')}
                    type="warning"
                    showIcon
                  />
                )}
              </Space>
              {!isEWDUser && (
                <Row>
                  <Descriptions layout="horizontal" column={2} bordered style={{ width: '100%' }}>
                    <Descriptions.Item label={i18next.t('Users.Form.FirstName')}>
                      {EWDAccount.firstName}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18next.t('Users.Form.LastName')}>
                      {EWDAccount.lastName}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18next.t('Users.EWDTab.Birthday')}>
                      {format(
                        moment(EWDAccount.dob, 'YYYY-MM-DD hh:mm:ss').toDate(),
                        localization.formats.time.formats.dby
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18next.t('Users.EWDTab.Timezone')}>
                      {EWDAccount.timeZone}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18next.t('Users.EWDTab.LicenceNumber')}>
                      {EWDAccount.licenceNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18next.t('Users.EWDTab.LicenceState')}>
                      {EWDAccount.licenceState}
                    </Descriptions.Item>
                    <Descriptions.Item label={i18next.t('Users.Form.Username')}>
                      {EWDAccount.username}
                    </Descriptions.Item>
                  </Descriptions>
                </Row>
              )}

              {isEWDUser && (
                <>
                  <Fields
                    isReadonly={!isEWDUser}
                    localization={localization}
                    setFieldValue={setFieldValue}
                    timezones={timezones}
                    licenceStateLabel={licenceStateLabel}
                    licenceStatePlaceholder={licenceStatePlaceholder}
                    states={states}
                    isUpdate={true}
                  />
                  <Row className={'mt-3 mx-1'}>
                    <Button
                      className={'mr-3'}
                      onClick={() => unlinkEWDDriverAccount(association.id, setSubmitting)}
                      type="primary"
                      danger
                      loading={isSubmitting}
                      disabled={!isValid || !isEWDUser}
                      id={BUTTON_IDS.ewdUnlink}
                    >
                      {i18next.t('Users.EWDTab.Unlink')}
                    </Button>
                    <Button
                      className={'mr-3'}
                      id={BUTTON_IDS.ewdViewDriverActivity}
                      href={`/fatigue/ewd/${userData.id}`}
                    >
                      {i18next.t('Users.EWDTab.ViewDriverActivity')}
                    </Button>
                  </Row>
                  <Formik
                    enableReinitialize={true}
                    validationSchema={validationPasswordSchema}
                    initialValues={{}}
                    onSubmit={(values, formikBag) => updatePassword(values, formikBag)}
                  >
                    {({ handleSubmit, isSubmitting, isValid, dirty }) => (
                      <>
                        <Row>
                          <FormInput
                            name="ewdDriverPassword"
                            type="password"
                            label={i18next.t('Users.Form.Password')}
                            placeholder={i18next.t('Users.Form.PasswordPlaceholder')}
                            isRequired
                          />
                          <FormInput
                            name="ewdDriverPasswordConfirm"
                            type="password"
                            label={i18next.t('Users.Form.PasswordConfirm')}
                            placeholder={i18next.t('Users.Form.PasswordConfirmPlaceholder')}
                            isRequired
                          />
                        </Row>
                        <Row className={'mt-3 mx-1'}>
                          <Button
                            className={'mr-3'}
                            onClick={() => handleSubmit()}
                            type="primary"
                            loading={isSubmitting}
                            disabled={!(dirty && isValid && isEWDUser)}
                            id={BUTTON_IDS.ewdUpdatePassword}
                          >
                            {i18next.t('Users.EWDTab.UpdatePassword')}
                          </Button>
                        </Row>
                      </>
                    )}
                  </Formik>
                </>
              )}
            </div>
          );
        }}
      </Formik>
    );
  }

  ewdDriverUpdateRef.current = null;

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={{
        ewdDriverBirthday: '',
        ewdDriverFirstName: userData.firstName,
        ewdDriverLastName: userData.lastName,
        ewdDriverLicenceNumber: userData.licenceNumber,
        ewdDriverLicenceState: userData.licenceState,
        ewdDriverTimeZone: userData.timeZone
      }}
      onSubmit={(values, formikBag) => linkEWDDriverAccount(values, formikBag)}
    >
      {({ handleSubmit, isSubmitting, isValid, setFieldValue }) => (
        <div className="formContainer">
          <FormTitle title={i18next.t('Users.EWDTab.EWDDriverLink')} underlined />
          <Space direction="vertical" style={{ width: '100%' }}>
            {association ? (
              <Alert
                message={i18next.t('Users.EWDTab.EWDDriverRecord')}
                description={i18next.t('Users.EWDTab.EWDDriverRecordDescriptionSuccess')}
                type="success"
                showIcon
              />
            ) : (
              <Alert
                message={i18next.t('Users.EWDTab.EWDDriverRecord')}
                description={i18next.t('Users.EWDTab.EWDDriverRecordDescriptionInfo')}
                type="info"
                showIcon
              />
            )}
            {!isEWDUser && (
              <Alert
                message={i18next.t('Users.EWDTab.NoAccess')}
                description={i18next.t('Users.EWDTab.NoAccessDescriptionWarning')}
                type="warning"
                showIcon
              />
            )}
          </Space>
          {isEWDUser && (
            <>
              <Fields
                isReadonly={!isEWDUser}
                localization={localization}
                setFieldValue={setFieldValue}
                timezones={timezones}
                licenceStateLabel={licenceStateLabel}
                licenceStatePlaceholder={licenceStatePlaceholder}
                states={states}
              />
              <Row className={'mt-3 mx-1'}>
                <Button
                  onClick={() => handleSubmit()}
                  type="primary"
                  loading={isSubmitting}
                  disabled={!isValid || !isEWDUser}
                  id={BUTTON_IDS.ewdLink}
                >
                  {i18next.t('Users.EWDTab.Link')}
                </Button>
              </Row>
            </>
          )}
        </div>
      )}
    </Formik>
  );
};
