export const MainUSTimeZones = {
  Eastern: 'America/New_York',
  Central: 'America/Chicago',
  MDT: 'America/Denver',
  Pacific: 'America/Los_Angeles',
  Alaska: 'America/Anchorage',
  'Aleutian Islands': 'America/Adak',
  Hawaii: 'Pacific/Honolulu',
  MST: 'America/Phoenix',
  Newfoundland: 'America/St_Johns'
};

export const USTimezoneMapping = {
  'America/Indiana/Petersburg': 'Eastern',
  'America/New_York': 'Eastern',
  'America/Kentucky/Monticello': 'Eastern',
  'America/Indiana/Winamac': 'Eastern',
  'America/Indiana/Marengo': 'Eastern',
  'America/Indiana/Indianapolis': 'Eastern',
  'America/Detroit': 'Eastern',
  'America/Indiana/Vevay': 'Eastern',
  'America/Indiana/Vincennes': 'Eastern',
  'America/Kentucky/Louisville': 'Eastern',
  'America/Chicago': 'Central',
  'America/Indiana/Tell_City': 'Central',
  'America/Indiana/Knox': 'Central',
  'America/North_Dakota/Beulah': 'Central',
  'America/North_Dakota/Center': 'Central',
  'America/North_Dakota/New_Salem': 'Central',
  'America/Menominee': 'Central',
  'America/Denver': 'MDT',
  'America/Boise': 'MDT',
  'America/Los_Angeles': 'Pacific',
  'America/Phoenix': 'MST',
  'America/Nome': 'Alaska',
  'America/Yakutat': 'Alaska',
  'America/Anchorage': 'Alaska',
  'America/Sitka': 'Alaska',
  'America/Juneau': 'Alaska',
  'America/Metlakatla': 'Alaska',
  'America/Adak': 'Aleutian Islands',
  'Pacific/Honolulu': 'Hawaii',
  'America/St_Johns': 'Newfoundland'
};

export function displayUSTimezone(timezoneValue) {
  return USTimezoneMapping[timezoneValue] || timezoneValue;
}
