import React, { useMemo } from 'react';
import { InfoWindow } from 'react-google-maps';
import moment from 'moment';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';
import { toDirectionString } from 'utils/geo';
import { TextButton } from 'components/buttons/TextButton';
import { RequestFootageModal } from 'features/requestFootage/RequestFootageModal';

import styles from './InfoWindows.module.scss';
import { useTranslation } from 'react-i18next';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { useRequestFootage } from 'features/requestFootage/useRequestFootage';

const TripInfoWindow = ({ data, position, deviceId, camereDeviceId, onCloseClick, ...props }) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const userPreferences = useUserPreferences();
  const reqDeviceId = useMemo(() => camereDeviceId || deviceId, [deviceId, camereDeviceId]);
  const { canRequest, onRequest, showModal, ...requestModalProps } = useRequestFootage();

  return (
    <>
      <InfoWindow
        onCloseClick={onCloseClick}
        position={position}
        options={{ disableAutoPan: true }}
        {...props}
      >
        <div className={styles.infoWindow}>
          <div className={styles.infoWindowTitle}>{t('Tracking.QuickView')}</div>
          <div>
            <label>{t('Common.DateTime')}</label>
            <span>
              {format(moment(data.At).toDate(), localization.formats.time.formats.dby_imp)}
            </span>
          </div>
          <div>
            <label>{t('Tracking.Speed')}</label>
            <span>{localization.formatSpeed(data.Spd)}</span>
          </div>
          <div>
            <label>{t('Tracking.Direction')}</label>
            <span>{toDirectionString(data.Dir)}</span>
          </div>
          <div>
            <label>{t('Tracking.Altitude')}</label>
            <span>{data.Alt ? localization.formatAltitude(data.Alt) : '-'}</span>
          </div>
          <div>
            <label>{t('Tracking.SatHDOP')}</label>
            <span>{data.NSat + ' / ' + (data.HDOP ? data.HDOP.toFixed(1) : '-')}</span>
          </div>
          {(userPreferences?.calculateDistance ||
            userPreferences?.calculateDistance === undefined) && (
            <div>
              <label>{t('Scorecard.Distance')}</label>
              <span>{data.distance ? `${localization.formatDistance(data.distance)}` : '-'}</span>
            </div>
          )}
          {canRequest(reqDeviceId) && (
            <footer>
              <TextButton
                style={{ margin: 0 }}
                text={t('Footage.RequestVideoFootage')}
                variant="primary"
                onClick={() => onRequest(reqDeviceId, moment(data.At))}
                id={`tripInfo_${reqDeviceId}_requestVideo`}
              />
            </footer>
          )}
        </div>
      </InfoWindow>
      {showModal && <RequestFootageModal showModal={showModal} {...requestModalProps} />}
    </>
  );
};

export default TripInfoWindow;
