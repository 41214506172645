if (!process.env.REACT_APP_API_PATH) {
  console.error(
    '%cERROR API_PATH not found please create your .env file based on EXAMPLE.env',
    'color: RED; font-size: 36px; background-color: white;padding: 20px, line-height:56px'
  );
  throw new Error('ERROR API_PATH not found please create your .env file based on EXAMPLE.env');
}
export const API_PATH = process.env.REACT_APP_API_PATH;
export const API_PATH_WITHOUT_V1 = process.env.REACT_APP_API_PATH.replace('v1', '');
export const TACHO_API_PATH = process.env.REACT_APP_TACHO_API_PATH;
export const NODE_API_PATH = process.env.REACT_APP_NODE_API_PATH;

if (!process.env.REACT_APP_NEXTGEN_BASE_URL) {
  console.error(
    '%cERROR REACT_APP_NEXTGEN_BASE_URL not found please add one to your your .env file',
    'color: RED; font-size: 36px; background-color: white;padding: 20px, line-height:56px'
  );
  throw new Error('ERROR REACT_APP_NEXTGEN_BASE_URL not found please add one to your .env file');
}
export const NEXTGEN_BASE_URL = process.env.REACT_APP_NEXTGEN_BASE_URL;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const HELICOPTER_URL = process.env.REACT_APP_HELICOPTER_URL;
export const HELICOPTER_USER = process.env.REACT_APP_HELICOPTER_USER;
export const HELICOPTER_PW = process.env.REACT_APP_HELICOPTER_PW;

export const EventDetailPageOpenMethods = {
  FullTab: 'FullTab', //Open event detail page in a new tab with header nav and side bar.
  SimpleTab: 'SimpleTab', //Open event detail page in a new tab WITHOUT header nav and side bar.
  FullEmbed: 'FullEmbed', //Open event detail page within TN360 with header nav and side bar.
  SimpleEmbed: 'SimpleEmbed' //Open event detail page within TN360 WITHOUT header nav and side bar.
};

export const OPEN_EVENT_DETAIL_PAGE = EventDetailPageOpenMethods.FullTab;

export const WALKME_ENABLED = process.env.REACT_APP_WALKME_ENABLED === 'true';

export const WALKME_SUPPORT_URL = process.env.REACT_APP_WALKME_SUPPORT_URL;

export const REACT_APP_ALEXA_URL = process.env.REACT_APP_ALEXA_URL;

export const RUC_MANAGER_URL = process.env.REACT_APP_RUC_MANAGER_URL;

export const STORE_URL = process.env.REACT_APP_STORE_URL;

export const KEYCLOAK_SSO_ENABLED = process.env.REACT_APP_KEYCLOAK_SSO_ENABLED === 'true';

export const KEYCLOAK_URL = process.env.REACT_APP_KEYCLOAK_URL;

export const KEYCLOAK_CONFIG = process.env.REACT_APP_KEYCLOAK_CONFIG;

export const MIXPANEL_PROXY_URL = process.env.REACT_APP_MIXPANEL_PROXY_URL;

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

export const AWS_CHAT_ENABLED = process.env.REACT_APP_AWS_CHAT_ENABLED === 'true';

export const AWS_CHAT_ENV = process.env.REACT_APP_AWS_CHAT_ENV;

export const AWS_CHAT_OFFICE = process.env.REACT_APP_AWS_CHAT_OFFICE;

export const CHURNZERO_ENABLE = process.env.REACT_APP_CHURNZERO_ENABLE === 'true';

export const CHURNZERO_APIKEY = process.env.REACT_APP_CHURNZERO_APIKEY;

export const CHURNZERO_URL = process.env.REACT_APP_CHURNZERO_URL;

//deactivate mixpanel unless explicitly turn on in env files
export const MIXPANEL_ACTIVE = process.env.REACT_APP_MIXPANEL_ACTIVE === 'true';
