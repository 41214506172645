/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";

/**
 * The ApiKey model module.
 * @module model/ApiKey
 * @version 1.0
 */
class ApiKey {
  /**
   * Constructs a new <code>ApiKey</code>.
   * @alias module:model/ApiKey
   */
  constructor() {
    ApiKey.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>ApiKey</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/ApiKey} obj Optional instance to populate.
   * @return {module:model/ApiKey} The populated <code>ApiKey</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new ApiKey();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("accessToken")) {
        obj["accessToken"] = ApiClient.convertToType(
          data["accessToken"],
          "String"
        );
      }
      if (data.hasOwnProperty("label")) {
        obj["label"] = ApiClient.convertToType(data["label"], "String");
      }
      if (data.hasOwnProperty("companyId")) {
        obj["companyId"] = ApiClient.convertToType(data["companyId"], "Number");
      }
      if (data.hasOwnProperty("userId")) {
        obj["userId"] = ApiClient.convertToType(data["userId"], "Number");
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = ApiClient.convertToType(data["type"], "String");
      }
      if (data.hasOwnProperty("expiresAt")) {
        obj["expiresAt"] = ApiClient.convertToType(data["expiresAt"], "String");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
ApiKey.prototype["id"] = undefined;

/**
 * @member {String} accessToken
 */
ApiKey.prototype["accessToken"] = undefined;

/**
 * @member {String} label
 */
ApiKey.prototype["label"] = undefined;

/**
 * @member {Number} companyId
 */
ApiKey.prototype["companyId"] = undefined;

/**
 * @member {Number} userId
 */
ApiKey.prototype["userId"] = undefined;

/**
 * @member {module:model/ApiKey.TypeEnum} type
 */
ApiKey.prototype["type"] = undefined;

/**
 * @member {String} expiresAt
 */
ApiKey.prototype["expiresAt"] = undefined;

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
ApiKey["TypeEnum"] = {
  /**
   * value: "API"
   * @const
   */
  API: "API",

  /**
   * value: "REGISTRATION"
   * @const
   */
  REGISTRATION: "REGISTRATION",

  /**
   * value: "USER"
   * @const
   */
  USER: "USER",

  /**
   * value: "HMAC"
   * @const
   */
  HMAC: "HMAC",

  /**
   * value: "LOGIN"
   * @const
   */
  LOGIN: "LOGIN"
};

export default ApiKey;
