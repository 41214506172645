import React from 'react';
import styles from './fatigue.module.scss';

export function getClassOfDriveTimeStyle(state) {
  switch (state) {
    case 1:
      return styles.yellow;
    case 2:
      return styles.red;
    default:
      return '';
  }
}

//TODO: check if driveTimeCell_Rest and driveTimeCell_Drive cand replace one another since they are the same; also needs refactoring
export function driveTimeCell_Rest(rowData) {
  let maxDriveTime = 1;
  const remainingDrive = (rowData.fatigueStatus && rowData.fatigueStatus.remaining_drive) || 0;
  const continueDriveTime = (rowData.fatigueStatus && rowData.fatigueStatus.drive_continuous) || 0;
  if (rowData.fatigueStatus && rowData.fatigueStatus.next_rest_break_required_in > 0) {
    maxDriveTime = rowData.fatigueStatus.remaining_drive + rowData.fatigueStatus.drive_continuous;
  } else {
    maxDriveTime = continueDriveTime;
  }
  if (continueDriveTime === 0) {
    const exceedPercent = 100;
    return (
      <div className={styles.barChart}>
        <div className={styles.bar}>
          <span className={styles.fullBar} style={{ width: exceedPercent + '%' }}>
            {' '}
          </span>
        </div>
        <p>
          {Math.floor(continueDriveTime / 3600)} hrs {Math.floor((continueDriveTime % 3600) / 60)}{' '}
          mins
        </p>
      </div>
    );
  } else {
    const remainedPercent = Math.min(
      100,
      Math.max(0, (remainingDrive / maxDriveTime).toFixed(2) * 100)
    );
    const barStyle = getClassOfDriveTimeStyle(
      (rowData.fatigueStatus && rowData.fatigueStatus.warning_state) || 0
    );
    return (
      <div className={styles.barChart}>
        <div className={styles.bar + ' ' + barStyle}>
          <span
            className={remainedPercent === 100 ? styles.fullBar : styles.progressBar}
            style={{ width: remainedPercent + '%' }}
          ></span>
        </div>
        <p>
          {Math.floor(continueDriveTime / 3600)} hrs {Math.floor((continueDriveTime % 3600) / 60)}{' '}
          mins
        </p>
      </div>
    );
  }
}

export function driveTimeCell_Drive(rowData) {
  let maxDriveTime = 1;
  const remainingDrive = (rowData.fatigueStatus && rowData.fatigueStatus.remaining_drive) || 0;
  const continueDriveTime = (rowData.fatigueStatus && rowData.fatigueStatus.drive_continuous) || 0;
  if (rowData.fatigueStatus && rowData.fatigueStatus.next_rest_break_required_in > 0) {
    maxDriveTime = rowData.fatigueStatus.remaining_drive + rowData.fatigueStatus.drive_continuous;
  } else {
    maxDriveTime = continueDriveTime;
  }
  if (continueDriveTime === 0) {
    const exceedPercent = 100;
    return (
      <div className={styles.barChart}>
        <div className={styles.bar}>
          <span className={styles.fullBar} style={{ width: exceedPercent + '%' }}>
            {' '}
          </span>
        </div>
        <p>
          {Math.floor(continueDriveTime / 3600)} hrs {Math.floor((continueDriveTime % 3600) / 60)}{' '}
          mins
        </p>
      </div>
    );
  } else {
    const remainedPercent = Math.min(
      100,
      Math.max(0, (remainingDrive / maxDriveTime).toFixed(2) * 100)
    );
    let barStyle = getClassOfDriveTimeStyle(
      (rowData.fatigueStatus && rowData.fatigueStatus.warning_state) || 0
    );
    return (
      <div className={styles.barChart}>
        <div className={styles.bar + ' ' + barStyle}>
          <span
            className={remainedPercent === 100 ? styles.fullBar : styles.progressBar}
            style={{ width: remainedPercent + '%' }}
          ></span>
        </div>
        <p>
          {Math.floor(continueDriveTime / 3600)} hrs {Math.floor((continueDriveTime % 3600) / 60)}{' '}
          mins
        </p>
      </div>
    );
  }
}

export function driveTimeCell_POA(rowData) {
  const maxWorkTime = (rowData.fatigueStatus && rowData.fatigueStatus.max_daily_drive) || 1;
  const workedTime = (rowData.fatigueStatus && rowData.fatigueStatus.drive_daily) || 0;

  if (workedTime >= maxWorkTime) {
    const exceedPercent = 0;
    return (
      <div className={styles.barChart}>
        <div className={styles.bar + ' ' + styles.red}>
          <span className={styles.fullBar} style={{ width: exceedPercent + '%' }}>
            {' '}
          </span>
        </div>
        <p>
          {Math.floor(workedTime / 3600)} hrs {Math.floor((workedTime % 3600) / 60)} mins
        </p>
      </div>
    );
  } else {
    const remainedPercent = Math.min(
      100,
      Math.max(0, ((maxWorkTime - workedTime) / maxWorkTime).toFixed(2) * 100)
    );
    return (
      <div className={styles.barChart}>
        <div className={styles.bar}>
          <span className={styles.progressBar} style={{ width: remainedPercent + '%' }}></span>
        </div>
        <p>
          {Math.floor(workedTime / 3600)} hrs {Math.floor((workedTime % 3600) / 60)} mins
        </p>
      </div>
    );
  }
}
