import React from 'react';
import { Dropdown as DropdownBS } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import styles from './DropdownSelect.module.scss';
import './DropdownSelect.scss';

export const Dropdown = ({ title, data, onItemSelected, style, dropDownStyle, ...props }) => {
  return (
    <DropdownBS className="dropdown-select" style={style} {...props}>
      <DropdownBS.Toggle variant="outline-secondary" style={style}>
        <div className="dropdownTitle">
          <span className={styles.dropdownTitleText}>{title}</span>
          <FontAwesomeIcon className="dropdownCaret" icon={faCaretDown} />
        </div>
      </DropdownBS.Toggle>

      <DropdownBS.Menu style={dropDownStyle}>
        {data.map(datum => (
          <DropdownBS.Item
            key={datum.id || datum.key}
            eventKey={datum.id || datum.key}
            onClick={() => {
              onItemSelected(datum);
            }}
            active={datum.active ? true : false}
          >
            {datum.name}
          </DropdownBS.Item>
        ))}
      </DropdownBS.Menu>
    </DropdownBS>
  );
};
