import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'antd';
import { useVT } from 'virtualizedtableforantd4';
import ResizeObserver from 'resize-observer-polyfill';
import { OverviewGridColumns } from './Grid/columns';
import styles from './Overview.module.scss';

export function OverviewGrid({ isLoading, data, ...props }) {
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const tableRef = useRef();

  const [vtSettings, setVTSettings] = useState({
    scroll: {
      y: 600,
      x: 1200
    }
  });

  const [vt] = useVT(() => vtSettings, [vtSettings]);

  const handleResize = entries => {
    const { width, height } = entries[0].contentRect;
    // Account for vertical scrollbar width
    const newTableWidth = width > 10 ? width - 10 : width;
    setTableWidth(newTableWidth);

    // account for header in table height
    const header = tableRef.current.querySelector('.ant-table-thead');
    const headerHeight = header?.getBoundingClientRect()?.height || 0;
    const newTableHeight = height - headerHeight;
    setTableHeight(newTableHeight);

    const newVTSettings = {
      scroll: {
        y: newTableHeight,
        x: newTableWidth
      }
    };

    setVTSettings(newVTSettings);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    const tableElement = document.querySelector(`.${styles.overviewGrid}`);
    resizeObserver.observe(tableElement);
    return () => {
      resizeObserver.unobserve(tableElement);
    };
  }, []);

  return (
    <Table
      className={`${styles.overviewGrid}`}
      dataSource={data}
      showSorterTooltip={false}
      columns={OverviewGridColumns}
      pagination={false}
      loading={isLoading}
      ref={tableRef}
      scroll={vtSettings.scroll}
      components={vt}
    />
  );
}
