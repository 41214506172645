import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import {
  driverManagementSelectors,
  fetchTypes,
  fetchDeletedTypes,
  fetchSchedules,
  fetchDeletedSchedules
} from './';
import {
  getSchedulesQuery,
  getDeletedSchedulesQuery,
  getTypesQuery,
  getDeletedTypesQuery
} from '../../containers/Configuration/DriverMgtTypes/constants';

export const useDriverMgtTypes = () => {
  const dispatch = useDispatch();
  const types = useSelector(driverManagementSelectors.getTypes);
  const companyKey = useCurrentCompanyKey();

  const isFetching = useSelector(driverManagementSelectors.isFetchingTypes);
  const isSuccess = useSelector(driverManagementSelectors.isTypesSuccess);
  const isError = useSelector(driverManagementSelectors.isTypesError);
  const isFetchComplete = isSuccess || isError;

  useEffect(() => {
    if (!isFetching && !isFetchComplete) {
      dispatch(fetchTypes(getTypesQuery));
    }
  }, [companyKey]);
  return types || [];
};

export const useDeletedDriverMgtTypes = () => {
  const dispatch = useDispatch();
  const deletedTypes = useSelector(driverManagementSelectors.getDeletedTypes);

  const isFetching = useSelector(driverManagementSelectors.isFetchingDeletedTypes);
  const isSuccess = useSelector(driverManagementSelectors.isDeletedTypesSuccess);
  const isError = useSelector(driverManagementSelectors.isDeletedTypesError);
  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete) {
    dispatch(fetchDeletedTypes(getDeletedTypesQuery));
  }

  return deletedTypes || [];
};

export const useDriverManagementSchedules = () => {
  const dispatch = useDispatch();
  const schedules = useSelector(driverManagementSelectors.getSchedules);

  const isFetching = useSelector(driverManagementSelectors.isFetchingSchedules);
  const isSuccess = useSelector(driverManagementSelectors.isSchedulesSuccess);
  const isError = useSelector(driverManagementSelectors.isSchedulesError);
  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete) {
    dispatch(fetchSchedules(getSchedulesQuery));
  }

  return schedules || [];
};

export const useDriverManagementDeletedSchedules = isDeletedTabActive => {
  const dispatch = useDispatch();
  const companyKey = useCurrentCompanyKey();
  const deletedSchedules = useSelector(driverManagementSelectors.getDeletedSchedules);

  const isFetching = useSelector(driverManagementSelectors.isFetchingDeletedSchedules);

  useEffect(() => {
    if (isDeletedTabActive && !isFetching) {
      dispatch(fetchDeletedSchedules(getDeletedSchedulesQuery));
    }
  }, [isDeletedTabActive, companyKey]);
  return deletedSchedules || [];
};

export const useScheduleById = () => {
  const schedule = useSelector(driverManagementSelectors.getScheduleById);
  return schedule || [];
};

export const useIsSchedulesFetchingFinished = () => {
  const isSuccess = useSelector(driverManagementSelectors.isSchedulesSuccess);
  const isError = useSelector(driverManagementSelectors.isSchedulesError);
  const isFetchingFinished = isSuccess || isError;
  return isFetchingFinished;
};

export const useIsTypesFetchingFinished = () => {
  const isSuccess = useSelector(driverManagementSelectors.isTypesSuccess);
  const isError = useSelector(driverManagementSelectors.isTypesError);
  const isFetchingFinished = isSuccess || isError;
  return isFetchingFinished;
};

export const useIsTypesFetching = () => useSelector(driverManagementSelectors.isFetchingTypes);
export const useIsSchedulesFetching = () =>
  useSelector(driverManagementSelectors.isFetchingSchedules);
export const useFilters = () => useSelector(driverManagementSelectors.filters);
export const useIsCalendarView = () => useSelector(driverManagementSelectors.calendarView);
export const useCalendarDate = () => useSelector(driverManagementSelectors.calendarDate);
export const useIsScheduleByIdFetching = () =>
  useSelector(driverManagementSelectors.isFetchingScheduleById);
