/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Attachment model module.
 * @module model/Attachment
 * @version 1.0
 */
class Attachment {
    /**
     * Constructs a new <code>Attachment</code>.
     * @alias module:model/Attachment
     */
    constructor() { 
        
        Attachment.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Attachment</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Attachment} obj Optional instance to populate.
     * @return {module:model/Attachment} The populated <code>Attachment</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Attachment();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('externalId')) {
                obj['externalId'] = ApiClient.convertToType(data['externalId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
            if (data.hasOwnProperty('content')) {
                obj['content'] = ApiClient.convertToType(data['content'], 'String');
            }
            if (data.hasOwnProperty('contentLength')) {
                obj['contentLength'] = ApiClient.convertToType(data['contentLength'], 'Number');
            }
            if (data.hasOwnProperty('compressionType')) {
                obj['compressionType'] = ApiClient.convertToType(data['compressionType'], 'String');
            }
            if (data.hasOwnProperty('hash')) {
                obj['hash'] = ApiClient.convertToType(data['hash'], 'String');
            }
            if (data.hasOwnProperty('mimeType')) {
                obj['mimeType'] = ApiClient.convertToType(data['mimeType'], 'String');
            }
            if (data.hasOwnProperty('compressed')) {
                obj['compressed'] = ApiClient.convertToType(data['compressed'], 'Boolean');
            }
            if (data.hasOwnProperty('orientation')) {
                obj['orientation'] = ApiClient.convertToType(data['orientation'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
Attachment.prototype['id'] = undefined;

/**
 * @member {String} externalId
 */
Attachment.prototype['externalId'] = undefined;

/**
 * @member {String} name
 */
Attachment.prototype['name'] = undefined;

/**
 * @member {module:model/Attachment.StatusEnum} status
 */
Attachment.prototype['status'] = undefined;

/**
 * @member {String} url
 */
Attachment.prototype['url'] = undefined;

/**
 * @member {String} content
 */
Attachment.prototype['content'] = undefined;

/**
 * @member {Number} contentLength
 */
Attachment.prototype['contentLength'] = undefined;

/**
 * @member {module:model/Attachment.CompressionTypeEnum} compressionType
 */
Attachment.prototype['compressionType'] = undefined;

/**
 * @member {String} hash
 */
Attachment.prototype['hash'] = undefined;

/**
 * @member {String} mimeType
 */
Attachment.prototype['mimeType'] = undefined;

/**
 * @member {Boolean} compressed
 */
Attachment.prototype['compressed'] = undefined;

/**
 * @member {Number} orientation
 */
Attachment.prototype['orientation'] = undefined;





/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Attachment['StatusEnum'] = {

    /**
     * value: "ENABLED"
     * @const
     */
    "ENABLED": "ENABLED",

    /**
     * value: "DISABLED"
     * @const
     */
    "DISABLED": "DISABLED",

    /**
     * value: "READY_FOR_DOWNLOAD"
     * @const
     */
    "READY_FOR_DOWNLOAD": "READY_FOR_DOWNLOAD",

    /**
     * value: "PENDING"
     * @const
     */
    "PENDING": "PENDING",

    /**
     * value: "AVAILABLE"
     * @const
     */
    "AVAILABLE": "AVAILABLE",

    /**
     * value: "UNAVAILABLE"
     * @const
     */
    "UNAVAILABLE": "UNAVAILABLE",

    /**
     * value: "FAILED"
     * @const
     */
    "FAILED": "FAILED"
};


/**
 * Allowed values for the <code>compressionType</code> property.
 * @enum {String}
 * @readonly
 */
Attachment['CompressionTypeEnum'] = {

    /**
     * value: "BZIP2"
     * @const
     */
    "BZIP2": "BZIP2",

    /**
     * value: "DEFLATE"
     * @const
     */
    "DEFLATE": "DEFLATE"
};



export default Attachment;

