import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

//components
import { Layout, TabsFilters, Table } from 'components/ant';
import { Space, Button } from 'antd';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect, {
  formatListForMultiselect
} from 'components/form/antMultiselect/AntMultiselect';

//slices
import { useIsTypesFetching, useDeletedDriverMgtTypes } from 'features/driverManagement';
import { Can, useCan, entities } from 'features/permissions';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';

//hooks
import useDebounce from 'utils/hooks/useDebounce';

//helpers
import { getTabs } from 'utils/tabs';
import { getCheckedFilterIds } from 'utils/filters';

//constants
import { Paths, Tabs, TabKeys } from './constants';
import { filterTypes, prepareDataForTable, prepareColumnsForTable } from './helpers';

import styles from './DriverMgtTypes.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const DriverMgtTypes = ({ types, subCompanies }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const can = useCan();
  const localization = useLocalization();
  const history = useHistory();
  const isTypesFetching = useIsTypesFetching();
  const [activeTab, setActiveTab] = useState(TabKeys.all);
  const deletedTypes = useDeletedDriverMgtTypes(activeTab === 'deleted');
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 300);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const companyIds = getCheckedFilterIds(filteredCompanies);

  const filteredTypes = filterTypes(activeTab === 'deleted' ? deletedTypes : types, {
    searchText: debouncedSearchText,
    companyIds: companyIds
  });

  const handleTabChange = tab => {
    setActiveTab(tab);
  };

  const handleSearchTable = text => {
    setSearchText(text);
  };

  const handleCompaniesChange = newCompanies => {
    setFilteredCompanies(newCompanies);
  };

  useEffect(() => {
    dispatch(setPageTitle(t('DriverMgtTypes.Title')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  useEffect(() => {
    setFilteredCompanies(
      formatListForMultiselect([{ id: 0, name: `${t('Common.AllCompanies')}` }, ...subCompanies])
    );
  }, [subCompanies, t]);

  const Header = () => {
    return (
      <Layout.Header>
        <div className={styles.spaceAround}>
          <div>
            <TabsFilters tabs={getTabs(Tabs, activeTab, handleTabChange, t)} />
          </div>
          <div className={styles.headerButtons}>
            <Buttons />
          </div>
        </div>
      </Layout.Header>
    );
  };

  const Buttons = () => {
    return (
      <Can everyEntity={[entities.DRIVERMANAGEMENTTYPE_CREATE]}>
        <Button
          id={BUTTON_IDS.driverMgtTypeAdd}
          type="primary"
          size="large"
          onClick={() => {
            history.push(Paths.ADD_DRIVERMANAGEMENTTYPE);
          }}
        >
          {t('DriverMgtTypes.AddButton')}
        </Button>
      </Can>
    );
  };

  return (
    <Layout>
      <Header />
      <Layout.Content className={styles.content}>
        <div className={`${styles.filterWrapper} ${styles.spaceAround}`}>
          <Space size={16}>
            <AntSearchbar
              className={styles.searchBar}
              size={'medium'}
              onFilter={handleSearchTable}
            />
            <AntMultiselect
              title={
                !filteredCompanies.find(company => !company.checked)
                  ? t('Users.AllCompanies')
                  : t('Common.Companies')
              }
              data={filteredCompanies}
              onFilter={handleCompaniesChange}
              className={styles.multiselect}
            />
          </Space>
          <div className={styles.counter}>{`${filteredTypes.length} ${
            filteredTypes.length === 1 ? t('DriverMgtTypes.Type') : t('DriverMgtTypes.Types')
          }`}</div>
        </div>
        <Table
          className={styles.table}
          dataSource={prepareDataForTable(
            filteredTypes,
            subCompanies,
            localization,
            can,
            entities,
            t
          )}
          columns={prepareColumnsForTable(t)}
          loading={isTypesFetching}
          pagination={false}
          scroll={{ y: 'calc(100vh - 230px)' }}
        />
      </Layout.Content>
    </Layout>
  );
};
