import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';

import styles from './modal.module.scss';

export const confirmationModal = (
  title,
  content,
  confirmButtonText,
  cancelButtonText,
  onOk,
  buttonType,
  onCancel,
  className,
  width,
  customProps,
  afterClose,
  zIndex
) => {
  return Modal.warning({
    autoFocusButton: null,
    cancelButtonProps: {
      type: 'secondary',
      ...customProps?.cancelButtonProps
    },
    cancelText: cancelButtonText,
    centered: true,
    className: `${styles.modal} ${className ? className : ''}`,
    content: content,
    icon: (
      <ExclamationCircleFilled
        style={{
          ...(buttonType === 'delete'
            ? { color: 'rgba(218, 97, 97, 255)' }
            : { color: 'rgba(250, 173, 20, 1)' }),
          ...customProps?.iconStyles
        }}
      />
    ),
    okButtonProps: {
      danger: buttonType === 'delete',
      style: {
        ...customProps?.okButtonStyles
      },
      type: 'primary',
      ...customProps?.okButtonProps
    },
    okCancel: true,
    okText: confirmButtonText,
    onCancel: () => (onCancel ? onCancel() : Modal.destroyAll()),
    onOk: () => onOk(),
    title: title,
    width: width || 'max-content',
    afterClose,
    footer: customProps?.footer,
    zIndex: zIndex
  });
};
