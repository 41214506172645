import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  status,
  loadingFailed,
  loadingStarted,
  loadingFinished,
  actionTypes
} from 'utils/reduxFetchingUtils';
import { ewdAPI } from 'features/ewd/endpoints/ewdAPI';
import { sortBy } from 'lodash';
import moment from 'moment';

export const initialState = {
  violations: {
    data: [],
    status: status
  },
  violationId: null
};

const violationsSlice = createSlice({
  name: 'ewdViolations',
  initialState: initialState,
  reducers: {
    fetchViolationsFailure(state, { payload }) {
      state.violations.status = loadingFailed(state, payload);
    },
    fetchViolationsStart(state, { payload }) {
      state.violations.status = loadingStarted(state, payload);
      state.violations.status.fetchParams = payload?.fetchParams;
    },
    fetchViolationsSuccess(state, { payload }) {
      state.violations.data = payload;
      state.violations.data.violations = sortBy(payload.violations, ['start']);
      state.violations.status = loadingFinished(state, payload);
    },
    setViolations(state, action) {
      state.violations = action.payload;
    },
    setViolationId(state, action) {
      state.violationId = action.payload;
    }
  }
});

export const {
  fetchViolationsStart,
  fetchViolationsSuccess,
  fetchViolationsFailure,
  setViolations,
  setViolationId
} = violationsSlice.actions;

export const fetchViolations = driverId => (dispatch, getState) => {
  const fetching = getState().ewd.violations.status.fetching;
  const fetchParams = getState().ewd.violations.status.fetchParams;
  const jwt = getState().user?.currentEWDUser?.auth?.token;
  let checkpoint = getState().ewd?.checkpoint?.checkpoint ?? 0;
  if (typeof checkpoint !== 'number') {
    checkpoint = 0;
  }
  const from = getState().ewd.period.fromDate;
  const to = getState().ewd.period.toDate;
  const driverTimezone = getState().ewd.currentDriver?.timeZone || 'Australia/Sydney';
  const isSameFetching =
    fetching === actionTypes.processing && fetchParams?.from === from && fetchParams?.to === to;

  if (jwt && !isSameFetching && from && to) {
    try {
      const dateFrom = moment
        .tz(moment(from).format('YYYY-MM-DDTHH:mm:ss'), driverTimezone)
        .format();
      const dateTo = moment.tz(moment(to).format('YYYY-MM-DDTHH:mm:ss'), driverTimezone).format();

      dispatch(fetchViolationsStart({ fetchParams: { dateFrom, dateTo } }));
      const req = ewdAPI.driverViolations(driverId, dateFrom, dateTo, checkpoint);
      req
        .then(result => {
          if (result?.error != null && result?.error !== '') {
            dispatch(fetchViolationsFailure(result.error));
            return;
          }
          dispatch(fetchViolationsSuccess(result));
        })
        .catch(error => {
          dispatch(fetchViolationsFailure(error.toString()));
        });
    } catch (err) {
      debugger;
      dispatch(fetchViolationsFailure(err.toString()));
    }
  }
};

export const useViolations = () => {
  const dispatch = useDispatch();
  const violations = useSelector(state => state.ewd.violations.data);
  const fetching = useSelector(state => state.ewd.violations?.status.fetching);
  const userAuth = useSelector(state => state.user.currentEWDUser)?.auth?.token;
  const EWDDriverId = useSelector(state => state.ewd.currentDriverId);
  if (EWDDriverId && userAuth && violations.length === 0 && fetching === actionTypes.init) {
    dispatch(fetchViolations(EWDDriverId));
  }
  return violations;
};

export const useHasViolations = () => {
  const violations = useSelector(state => state.ewd.violations.data);
  const fetching = useSelector(state => state.ewd.violations?.status.fetching);
  if (violations.length > 0 && fetching === actionTypes.done) {
    return true;
  }
  return false;
};

export default violationsSlice.reducer;
