/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import DeviceModel from "./DeviceModel";
import DeviceType from "./DeviceType";
import Vehicle from "./Vehicle";

/**
 * The Device model module.
 * @module model/Device
 * @version 1.0
 */
class Device {
  /**
   * Constructs a new <code>Device</code>.
   * @alias module:model/Device
   */
  constructor() {
    Device.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Device</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Device} obj Optional instance to populate.
   * @return {module:model/Device} The populated <code>Device</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Device();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("path")) {
        obj["path"] = ApiClient.convertToType(data["path"], "String");
      }
      if (data.hasOwnProperty("version")) {
        obj["version"] = ApiClient.convertToType(data["version"], "Number");
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("companyId")) {
        obj["companyId"] = ApiClient.convertToType(data["companyId"], "Number");
      }
      if (data.hasOwnProperty("vehicle")) {
        obj["vehicle"] = Vehicle.constructFromObject(data["vehicle"]);
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = DeviceType.constructFromObject(data["type"]);
      }
      if (data.hasOwnProperty("model")) {
        obj["model"] = DeviceModel.constructFromObject(data["model"]);
      }
      if (data.hasOwnProperty("did")) {
        obj["did"] = ApiClient.convertToType(data["did"], "String");
      }
      if (data.hasOwnProperty("registration")) {
        obj["registration"] = ApiClient.convertToType(
          data["registration"],
          "String"
        );
      }
      if (data.hasOwnProperty("externalId")) {
        obj["externalId"] = ApiClient.convertToType(
          data["externalId"],
          "String"
        );
      }
      if (data.hasOwnProperty("serialNumber")) {
        obj["serialNumber"] = ApiClient.convertToType(
          data["serialNumber"],
          "String"
        );
      }
      if (data.hasOwnProperty("imei")) {
        obj["imei"] = ApiClient.convertToType(data["imei"], "String");
      }
      if (data.hasOwnProperty("sim")) {
        obj["sim"] = ApiClient.convertToType(data["sim"], "String");
      }
      if (data.hasOwnProperty("phone")) {
        obj["phone"] = ApiClient.convertToType(data["phone"], "String");
      }
      if (data.hasOwnProperty("operatingSystem")) {
        obj["operatingSystem"] = ApiClient.convertToType(
          data["operatingSystem"],
          "String"
        );
      }
      if (data.hasOwnProperty("firmwareVersion")) {
        obj["firmwareVersion"] = ApiClient.convertToType(
          data["firmwareVersion"],
          "String"
        );
      }
      if (data.hasOwnProperty("softwareVersion")) {
        obj["softwareVersion"] = ApiClient.convertToType(
          data["softwareVersion"],
          "String"
        );
      }
      if (data.hasOwnProperty("note")) {
        obj["note"] = ApiClient.convertToType(data["note"], "String");
      }
      if (data.hasOwnProperty("auto")) {
        obj["auto"] = ApiClient.convertToType(data["auto"], "Boolean");
      }
      if (data.hasOwnProperty("iapId")) {
        obj["iapId"] = ApiClient.convertToType(data["iapId"], "String");
      }
      if (data.hasOwnProperty("releaseVersion")) {
        obj["releaseVersion"] = ApiClient.convertToType(
          data["releaseVersion"],
          "String"
        );
      }
      if (data.hasOwnProperty("modemType")) {
        obj["modemType"] = ApiClient.convertToType(data["modemType"], "String");
      }
      if (data.hasOwnProperty("modemImei")) {
        obj["modemImei"] = ApiClient.convertToType(data["modemImei"], "String");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Device.prototype["id"] = undefined;

/**
 * @member {String} name
 */
Device.prototype["name"] = undefined;

/**
 * @member {String} path
 */
Device.prototype["path"] = undefined;

/**
 * @member {Number} version
 */
Device.prototype["version"] = undefined;

/**
 * @member {module:model/Device.StatusEnum} status
 */
Device.prototype["status"] = undefined;

/**
 * @member {Number} companyId
 */
Device.prototype["companyId"] = undefined;

/**
 * @member {module:model/Vehicle} vehicle
 */
Device.prototype["vehicle"] = undefined;

/**
 * @member {module:model/DeviceType} type
 */
Device.prototype["type"] = undefined;

/**
 * @member {module:model/DeviceModel} model
 */
Device.prototype["model"] = undefined;

/**
 * @member {String} did
 */
Device.prototype["did"] = undefined;

/**
 * @member {String} registration
 */
Device.prototype["registration"] = undefined;

/**
 * @member {String} externalId
 */
Device.prototype["externalId"] = undefined;

/**
 * @member {String} serialNumber
 */
Device.prototype["serialNumber"] = undefined;

/**
 * @member {String} imei
 */
Device.prototype["imei"] = undefined;

/**
 * @member {String} sim
 */
Device.prototype["sim"] = undefined;

/**
 * @member {String} phone
 */
Device.prototype["phone"] = undefined;

/**
 * @member {String} operatingSystem
 */
Device.prototype["operatingSystem"] = undefined;

/**
 * @member {String} firmwareVersion
 */
Device.prototype["firmwareVersion"] = undefined;

/**
 * @member {String} modemType
 */
Device.prototype["modemType"] = undefined;

/**
 * @member {String} modemImei
 */
Device.prototype["modemImei"] = undefined;

/**
 * @member {String} softwareVersion
 */
Device.prototype["softwareVersion"] = undefined;

/**
 * @member {String} note
 */
Device.prototype["note"] = undefined;

/**
 * @member {Boolean} auto
 */
Device.prototype["auto"] = undefined;

/**
 * @member {String} iapId
 */
Device.prototype["iapId"] = undefined;

/**
 * @member {String} releaseVersion
 */
Device.prototype["releaseVersion"] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Device["StatusEnum"] = {
  /**
   * value: "ENABLED"
   * @const
   */
  ENABLED: "ENABLED",

  /**
   * value: "DISABLED"
   * @const
   */
  DISABLED: "DISABLED",

  /**
   * value: "READY_FOR_DOWNLOAD"
   * @const
   */
  READY_FOR_DOWNLOAD: "READY_FOR_DOWNLOAD",

  /**
   * value: "PENDING"
   * @const
   */
  PENDING: "PENDING",

  /**
   * value: "AVAILABLE"
   * @const
   */
  AVAILABLE: "AVAILABLE",

  /**
   * value: "UNAVAILABLE"
   * @const
   */
  UNAVAILABLE: "UNAVAILABLE",

  /**
   * value: "FAILED"
   * @const
   */
  FAILED: "FAILED"
};

export default Device;
