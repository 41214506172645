import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import {
  fetchUserAlerts,
  editUserAlert,
  fetchToggleUserAlertsStatus,
  toggleUserAlerts
} from './thunks';

const initialState = {
  list: [],
  isFetching: false,
  error: null,
  success: null,
  meta: {
    editUserAlert: {
      isSaving: false,
      error: null,
      success: false
    },
    deleteAlert: {
      isDeleting: false,
      error: null,
      success: false
    },
    global: {
      active: true,
      isFetching: false,
      error: null,
      success: false
    },
    toggle: {
      isSaving: false,
      error: null,
      success: false
    },
    companyId: null
  }
};

export const userAlertsSlice = createSlice({
  name: 'userAlerts',
  initialState,
  extraReducers: {
    [fetchUserAlerts.pending]: (state, action) => {
      state.isFetching = true;
      state.error = false;
      state.success = false;
    },
    [fetchUserAlerts.fulfilled]: (state, action) => {
      state.list = unwrapResult(action)?.alertSubscriptions;
      state.isFetching = false;
      state.success = true;
      state.error = false;
      state.meta.companyId = action?.meta?.arg?.query?.company_id;
    },
    [fetchUserAlerts.rejected]: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.success = false;
      state.meta.companyId = action?.meta?.arg?.query?.company_id;
    },
    [fetchToggleUserAlertsStatus.pending]: (state, action) => {
      state.meta.global.isFetching = true;
      state.meta.companyId = action?.meta?.arg?.query?.company_id;
    },
    [fetchToggleUserAlertsStatus.rejected]: (state, action) => {
      state.meta.global.isFetching = false;
      state.meta.global.error = true;
      state.meta.global.success = false;
      state.meta.companyId = action?.meta?.arg?.query?.company_id;
    },
    [fetchToggleUserAlertsStatus.fulfilled]: (state, action) => {
      state.meta.global.active = unwrapResult(action)?.active;
      state.meta.global.isFetching = false;
      state.meta.global.error = false;
      state.meta.global.success = true;
      state.meta.companyId = action?.meta?.arg?.query?.company_id;
    },
    [toggleUserAlerts.pending]: (state, action) => {
      state.meta.toggle.isSaving = true;
    },
    [toggleUserAlerts.rejected]: (state, action) => {
      state.meta.toggle.isSaving = false;
      state.meta.toggle.error = true;
      state.meta.toggle.success = false;
      state.meta.companyId = action?.meta?.arg?.query?.company_id;
    },
    [toggleUserAlerts.fulfilled]: (state, action) => {
      state.meta.toggle.isSaving = false;
      state.meta.toggle.error = false;
      state.meta.toggle.success = true;
    },
    [editUserAlert.pending]: (state, action) => {
      state.meta.editUserAlert.isSaving = true;
    },
    [editUserAlert.rejected]: (state, action) => {
      state.meta.editUserAlert.isSaving = false;
      state.meta.editUserAlert.error = true;
      state.meta.editUserAlert.success = false;
    },
    [editUserAlert.fulfilled]: (state, action) => {
      state.meta.editUserAlert.isSaving = false;
      state.meta.editUserAlert.error = false;
      state.meta.editUserAlert.success = true;
    }
  }
});
