import React from 'react';
import styles from './Element.module.scss';

const FormElement = ({ label, icon, onAction, className }) => {
  return (
    <div className={`${styles.element} ${className ? styles[className] : ''}`}>
      <i className={styles.icon} style={{ content: `url(${icon})` }} />
      <div className={styles.label}>{label}</div>
      <i
        className={`tn-i-plus ${styles.add} ${className ? styles.addFailedElement : ''}`}
        onClick={onAction}
      />
    </div>
  );
};

export default FormElement;
