export const ColumnKeys = {
  ITEM_ID: 'item_id',
  ITEM_ATTRS: 'item_attrs',
  ITEM_TYPE: 'item_type',
  ITEM_QUANTITY: 'item_quantity',
  ITEM_WEIGHT: 'item_weight',
  ITEM_DESCRIPTION: 'item_description',
  ACTIONS: 'actions',
  STATUS: 'status',
  DESCRIPTION: 'description',
  CREATED_AT: 'created_at',
  EVENT_AT: 'event_at',
  DEVICE: 'device',
  DRIVER: 'driver',
  JOB_STATUS: 'job_status',
  DETAILS: 'details'
};

export const TABLE_JV_JOBS_ITEMS_COLUMNS = [
  {
    title: 'ITEM ID',
    label: 'Item ID',
    dataIndex: 'item_id',
    key: ColumnKeys.ITEM_ID
  },
  {
    title: 'ATTRS',
    label: 'Attrs',
    dataIndex: 'item_attrs',
    key: ColumnKeys.ITEM_ATTRS
  },
  {
    title: 'ITEM',
    label: 'Item',
    dataIndex: 'item_type',
    key: ColumnKeys.ITEM_TYPE
  },
  {
    title: 'QUANTITY',
    label: 'Quantity',
    dataIndex: 'item_quantity',
    key: ColumnKeys.ITEM_QUANTITY
  },
  {
    title: 'WEIGHT',
    label: 'Weight',
    dataIndex: 'item_weight',
    key: ColumnKeys.ITEM_WEIGHT
  },
  {
    title: 'DELIVERY STATUS',
    label: 'DELIVERY STATUS',
    dataIndex: 'job_status',
    key: ColumnKeys.JOB_STATUS
  },
  {
    title: 'Notes',
    label: 'notes',
    dataIndex: ColumnKeys.ITEM_DESCRIPTION,
    key: ColumnKeys.ITEM_DESCRIPTION
  },
  {
    title: 'ACTIONS',
    label: 'Actions',
    dataIndex: 'actions',
    key: ColumnKeys.ACTIONS
  }
];

export const JobDetailsLabels = {
  SEQ: 'Seq',
  TYPE: 'Type',
  STATUS: 'Status',
  START: 'Start',
  END: 'End',
  JOB_NAME: 'Job Name',
  STOP_SEQ: 'Stop Seq',
  LOCATION: 'Location',
  ADDRESS: 'Address',
  DESCRIPTION: 'Description',
  REASON: 'Reason'
};

export const ViewJobLabelsLeft = [
  JobDetailsLabels.SEQ,
  JobDetailsLabels.TYPE,
  JobDetailsLabels.STATUS,
  JobDetailsLabels.START,
  JobDetailsLabels.END
];

export const ViewJobLabelsRight = [
  JobDetailsLabels.JOB_NAME,
  JobDetailsLabels.STOP_SEQ,
  JobDetailsLabels.LOCATION,
  JobDetailsLabels.ADDRESS,
  JobDetailsLabels.DESCRIPTION,
  JobDetailsLabels.REASON
];

export const PublicViewJobLabels = [
  JobDetailsLabels.JOB_NAME,
  JobDetailsLabels.STOP_SEQ,
  JobDetailsLabels.LOCATION,
  JobDetailsLabels.ADDRESS,
  JobDetailsLabels.DESCRIPTION,
  JobDetailsLabels.REASON,
  JobDetailsLabels.SEQ,
  JobDetailsLabels.TYPE,
  JobDetailsLabels.STATUS,
  JobDetailsLabels.START,
  JobDetailsLabels.END
];

export const JobAttributes = {
  LOCATION: 'location',
  ADDRESS: 'address'
};

export const JobViewTabsLabels = {
  DETAILS: 'details',
  EVENTS: 'events'
};

export const JobViewTabs = [
  {
    pathname: '/smartjobs/id',
    history: {},
    key: JobViewTabsLabels.DETAILS
  },
  {
    pathname: 'smartjobs/id',
    history: {},
    key: JobViewTabsLabels.EVENTS
  }
];

export const JOB_PNG_ATTACHMENT_PREFIX = 'data:image/png;base64,';
export const JOB_JPG_PDF_ATTACHMENT_PREFIX = 'data:image/jpg;base64,';

//Job Events

export const TABLE_JV_EVENTS_COLUMNS = [
  {
    title: 'STATUS',
    label: 'Status',
    dataIndex: 'status',
    key: ColumnKeys.STATUS
  },
  {
    title: 'DESCRIPTION',
    label: 'Description',
    dataIndex: 'description',
    key: ColumnKeys.DESCRIPTION
  },
  {
    title: 'CREATED AT',
    label: 'Created at',
    dataIndex: 'created_at',
    key: ColumnKeys.CREATED_AT
  },
  {
    title: 'EVENT AT',
    label: 'Event at',
    dataIndex: 'event_at',
    key: ColumnKeys.EVENT_AT
  },
  {
    title: 'DEVICE',
    label: 'Device',
    dataIndex: 'device',
    key: ColumnKeys.DEVICE
  },
  {
    title: 'DRIVER',
    label: 'Driver',
    dataIndex: 'driver',
    key: ColumnKeys.DRIVER
  },
  {
    title: 'DESCRIPTION',
    label: 'Description',
    dataIndex: 'description',
    key: ColumnKeys.DESCRIPTION
  },
  {
    title: 'DETAILS',
    label: 'Details',
    dataIndex: 'details',
    key: ColumnKeys.DETAILS
  }
];

export const PublicJobPageStatuses = {
  NOT_VERIFIED: 'not_verified',
  AVAILABLE: 'available',
  NOT_AVAILABLE: 'not_available',
  FAILED_DETAILS_CALL: 'failed_details_calls'
};
