import React from 'react';
import { Button } from 'antd';

import cn from 'classnames';
import style from './CalendarPopupContent.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { Tooltip } from 'components/ant';

export const CalendarPopupContent = ({ title, rows = [], actions = [], wrapButtons = false }) => {
  const buttonsClassName = cn(style.actionButtons, { [style.wrapButtons]: wrapButtons });

  return (
    <div className={style.container}>
      <div className={style.title}>{title}</div>
      {rows.map(
        row =>
          !row.isHidden && (
            <div className={style.row} key={row.key}>
              <div className={style.rowKey}>{row.key}</div>
              <Tooltip
                content={row.value}
                target={
                  <div className={`${style.rowValue} ${style.rowOverflowHiddenText}`}>
                    {row.value}
                  </div>
                }
              />
            </div>
          )
      )}

      <div className={style.actionsWrapper}>
        <div className={buttonsClassName}>
          {actions.map(action => (
            <Button
              key={action.title}
              type="secondary"
              className={style.action}
              onClick={action.onClick}
              disabled={action.disabled}
              danger={action.danger || false}
              id={BUTTON_IDS.calendarPopup}
            >
              {action.title}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
