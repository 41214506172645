import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import InfoRow from 'components/form/info-row/InfoRow';
import { Button, Card, Image, Space, Modal, Progress, Row, Col, Spin } from 'antd';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import styles from 'components/tn/grid/grid.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { Empty } from 'components/ant';
import ImageCard from './ImageCard';
import {
  fetchInstallationViewPhotosByEventId,
  useInstallationViewPhotosByEventId
} from 'features/installation_logs/InstallationLogsSlice';
import { capitalizeFirstLetter } from 'utils/strings';
import i18n from 'i18next';
import useDownloadImages from './useDownloadImages';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import ProgressModal from './ProgressModal';

//@Author Durga Prasad Kolli
const InstallationAuditLogsPhotos = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [viewData, setViewData] = useState({});
  const [supportType, setSupportType] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const { state } = useLocation();
  const eventId = state?.data?.rowData?.id;
  const title =
    state?.data?.entityName !== undefined
      ? state.data.entityName + ' - ' + t('Common.Photos')
      : t('Common.Photos');
  const { downloadImagesAsZip } = useDownloadImages();

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchInstallationViewPhotosByEventId(eventId));
  }, [dispatch, eventId]);

  useEffect(() => {
    if (state?.data) {
      dispatch(setBackButton(true));
      dispatch(setPageTitle(t(title)));
      setViewData(state.data.rowData);
      setSupportType(state.data.supportType);
    }
  }, [dispatch, state, t]);

  const data = useInstallationViewPhotosByEventId();

  useEffect(() => {
    if (data !== null && data.length > 0) {
      const resp = data[0]?.attachments ?? [];
      setAttachments(resp);
    }
    setIsLoading(false);
  }, [data]);

  const [progress, setProgress] = useState({ total: 0, completed: 0 });
  const [modalVisible, setModalVisible] = useState(false);

  const handleProgress = ({ total, completed }) => {
    setProgress({ total, completed });
  };

  console.log('progress ', progress);

  const handleDownloadClick = async () => {
    dispatch(
      openToast({
        type: ToastType.Success,
        message: 'Downloading Started...'
      })
    );
    setProgress({ total: 0, completed: 0 });
    setModalVisible(true);
    if (!attachments || !Array.isArray(attachments)) {
      console.warn('Attachments must be an array.');
      return;
    }
    try {
      const results = await downloadImagesAsZip(attachments, handleProgress, title);
      setTimeout(() => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: 'Downloaded Successfully'
          })
        );
      }, 5000);
    } catch (error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: error?.message
        })
      );
      console.error('Error fetching images:', error);
    } finally {
      setModalVisible(false);
    }
  };
  return (
    <ContainerPageWrapper>
      <HeaderPageWrapper>
        <div style={{ display: 'flex' }}></div>
        <div style={{ marginLeft: 'auto' }}>
          <Space size={16}>
            <Button
              type="primary"
              size="medium"
              id={BUTTON_IDS.viewPhotosBtn}
              onClick={() => console.log('Im calling ---- viewPhotos')}
              disabled={attachments.length === 0}
            >
              {t('SupportToolsELD.ExportPhotostoPDF')}
            </Button>
            <Button
              type="secondary"
              size="medium"
              id={BUTTON_IDS.exportToPDFBtn}
              onClick={handleDownloadClick}
              disabled={attachments.length === 0}
            >
              {t('SupportToolsELD.ExportAsZIP')}
            </Button>
          </Space>
        </div>
      </HeaderPageWrapper>
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '0 32px', color: '#181c21' }}
      >
        {viewData && (
          <>
            <InfoRow
              label={t(
                `SupportToolsELD.InstallationLogsColumns.${supportType &&
                  capitalizeFirstLetter(supportType)}`
              )}
              value={viewData[supportType]?.name || ''}
              styles={styles}
            />
            <InfoRow
              label={t(`SupportToolsELD.InstallationLogsColumns.Company`)}
              value={viewData.company?.id || ''}
              styles={styles}
            />
            <InfoRow
              label={t(`SupportToolsELD.InstallationLogsColumns.Fleet`)}
              value={viewData.vehicle?.fleet || ''}
              styles={styles}
            />
            <InfoRow
              label={t(`SupportToolsELD.InstallationLogsColumns.Engineer`)}
              value={viewData.vehicle?.engineer || ''}
              styles={styles}
            />
            <InfoRow
              label={t(`SupportToolsELD.InstallationLogsColumns.WorkOrder`)}
              value={viewData.vehicle?.workorder || ''}
              styles={styles}
            />
            <InfoRow
              label={t(`SupportToolsELD.InstallationLogsColumns.OperationType`)}
              value={
                viewData.action === i18n.t('SupportToolsELD.InstallationLogsView.Test')
                  ? i18n.t('SupportToolsELD.InstallationLogsView.Validate')
                  : viewData?.action || ''
              }
              styles={styles}
            />
            <div>
              <ProgressModal
                title={title}
                open={modalVisible}
                progress={progress}
                onClose={() => setModalVisible(false)}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <Spin spinning={isLoading}>
                {attachments.length === 0 ? (
                  <Empty description={'No Photos'} />
                ) : (
                  <ImageCard attachments={attachments} />
                )}
              </Spin>
            </div>
          </>
        )}
      </div>
    </ContainerPageWrapper>
  );
};
export default InstallationAuditLogsPhotos;
