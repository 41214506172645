import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import {
  fetchTypes,
  fetchDeletedTypes,
  fetchSchedules,
  fetchDeletedSchedules,
  fetchScheduleById
} from './thunks';

const initialState = {
  types: [],
  deletedTypes: [],
  schedules: [],
  deletedSchedules: [],
  scheduleById: null,
  calendarView: false,
  calendarDate: null,
  filters: {
    companies: null,
    branches: null,
    drivers: null,
    types: null
  },
  meta: {
    types: {
      isFetching: false,
      error: null,
      success: false
    },
    deletedTypes: {
      isFetching: false,
      error: null,
      success: false
    },
    schedules: {
      isFetching: false,
      error: null,
      success: false
    },
    deletedSchedules: {
      isFetching: false,
      error: null,
      success: false
    },
    scheduleById: {
      isFetching: false,
      error: null,
      success: false
    }
  }
};

export const driverManagementSlice = createSlice({
  name: 'driverManagement',
  initialState,
  reducers: {
    updateFilters(state, { payload }) {
      state.filters[payload.type] = payload.filterIds;
    },
    changeCalendarView(state) {
      state.calendarView = !state.calendarView;
    },
    updateCalendarDate(state, { payload }) {
      state.calendarDate = payload;
    }
  },
  extraReducers: {
    [fetchTypes.pending]: state => {
      state.meta.types.isFetching = true;
      state.meta.types.success = false;
      state.meta.types.error = false;
    },
    [fetchTypes.fulfilled]: (state, action) => {
      state.meta.types.isFetching = false;
      state.meta.types.success = true;
      state.meta.types.error = false;
      state.types = unwrapResult(action);
    },
    [fetchTypes.rejected]: state => {
      state.meta.types.isFetching = false;
      state.meta.types.success = false;
      state.meta.types.error = true;
    },
    [fetchDeletedTypes.pending]: state => {
      state.meta.deletedTypes.isFetching = true;
      state.meta.deletedTypes.success = false;
      state.meta.deletedTypes.error = false;
    },
    [fetchDeletedTypes.fulfilled]: (state, action) => {
      state.meta.deletedTypes.isFetching = false;
      state.meta.deletedTypes.success = true;
      state.meta.deletedTypes.error = false;
      state.deletedTypes = unwrapResult(action);
    },
    [fetchDeletedTypes.rejected]: state => {
      state.meta.deletedTypes.isFetching = false;
      state.meta.deletedTypes.success = false;
      state.meta.deletedTypes.error = true;
    },
    [fetchSchedules.pending]: state => {
      state.meta.schedules.isFetching = true;
      state.meta.schedules.success = false;
      state.meta.schedules.error = false;
    },
    [fetchSchedules.fulfilled]: (state, action) => {
      state.meta.schedules.isFetching = false;
      state.meta.schedules.success = true;
      state.meta.schedules.error = false;
      state.schedules = unwrapResult(action);
    },
    [fetchSchedules.rejected]: state => {
      state.meta.schedules.isFetching = false;
      state.meta.schedules.success = false;
      state.meta.schedules.error = true;
    },
    [fetchDeletedSchedules.pending]: state => {
      state.meta.deletedSchedules.isFetching = true;
      state.meta.deletedSchedules.success = false;
      state.meta.deletedSchedules.error = false;
    },
    [fetchDeletedSchedules.fulfilled]: (state, action) => {
      state.meta.deletedSchedules.isFetching = false;
      state.meta.deletedSchedules.success = true;
      state.meta.deletedSchedules.error = false;
      state.deletedSchedules = unwrapResult(action);
    },
    [fetchDeletedSchedules.rejected]: state => {
      state.meta.deletedSchedules.isFetching = false;
      state.meta.deletedSchedules.success = false;
      state.meta.deletedSchedules.error = true;
    },
    [fetchScheduleById.pending]: state => {
      state.meta.scheduleById.isFetching = true;
      state.meta.scheduleById.success = false;
      state.meta.scheduleById.error = false;
    },
    [fetchScheduleById.fulfilled]: (state, action) => {
      state.meta.scheduleById.isFetching = false;
      state.meta.scheduleById.success = true;
      state.meta.scheduleById.error = false;
      state.scheduleById = unwrapResult(action);
    },
    [fetchScheduleById.rejected]: state => {
      state.meta.scheduleById.isFetching = false;
      state.meta.scheduleById.success = false;
      state.meta.scheduleById.error = true;
    }
  }
});

export const {
  updateFilters,
  changeCalendarView,
  updateCalendarDate
} = driverManagementSlice.actions;
