import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Divider, Space, Tooltip } from 'antd';
import { SnapshotConfigPopup } from 'features/dashboard/camera_tab/SnapshotConfig';
import { Can, FeatureFlag, GlobalRoles, services, useCanFeatureFlag } from 'features/permissions';
import { refreshDevicesSnapshots } from 'features/dashboard/dashboardSlice';
import { ReloadOutlined, SettingOutlined } from '@ant-design/icons';

export function SnapshotsTabExtras() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showConfigPopup, setShowConfigPopup] = useState(false);
  const handleRefresh = useCallback(() => dispatch(refreshDevicesSnapshots()), [dispatch]);
  const canCameraHealth = useCanFeatureFlag({
    featureFlag: FeatureFlag.iqCameraHealth.flag
  });

  return (
    <Space
      size={[canCameraHealth ? 6 : 16]}
      split={canCameraHealth ? <Divider type="vertical" /> : null}
    >
      {canCameraHealth ? (
        <Tooltip title={t('Home.Refresh')}>
          <Button
            style={{ minWidth: '20px', width: '20px', height: '20px' }}
            type="text"
            icon={<ReloadOutlined style={{ color: '#64748B', fontSize: '20px' }} />}
            onClick={handleRefresh}
          ></Button>
        </Tooltip>
      ) : (
        <Button type="primary" onClick={handleRefresh}>
          {t('Home.Refresh')}
        </Button>
      )}
      <Can oneOfRoles={[GlobalRoles.Admin, GlobalRoles.SiteAdmin]} everyService={[services.CAMERA]}>
        {canCameraHealth ? (
          <Tooltip title={t('Home.Configure')} placement="topLeft">
            <Button
              style={{ minWidth: '20px', width: '20px', height: '20px' }}
              type="text"
              icon={<SettingOutlined style={{ color: '#64748B', fontSize: '20px' }} />}
              onClick={() => setShowConfigPopup(true)}
            ></Button>
          </Tooltip>
        ) : (
          <Button onClick={() => setShowConfigPopup(true)}>{t('Home.Configure')}</Button>
        )}
        <SnapshotConfigPopup show={showConfigPopup} onHide={() => setShowConfigPopup(false)} />
      </Can>
    </Space>
  );
}
