/* global google */
import React, { useState, useEffect } from 'react';
import Geosuggest from '@ubilabs/react-geosuggest';

import { useCurrentRegion } from 'features/regions/regionsSlice';
import '@ubilabs/react-geosuggest/module/geosuggest.css';

export const GeoSuggest = React.forwardRef((props, ref) => {
  const region = useCurrentRegion();
  const [bounds, setBounds] = useState();

  //do not include unncessary fields due to PD cost reduction
  //specify only the fields that are needed
  //not specifying fields will result in all fields being returned by google API
  const placesFieldsToBeReturned = ['geometry'];

  useEffect(() => {
    let bounds = new google.maps.LatLngBounds();
    try {
      const geocode = JSON.parse(region?.geocode || '{}');
      geocode.bounds &&
        geocode.bounds.forEach(bound => {
          const point = new google.maps.LatLng(...bound);
          bounds.extend(point);
        });
      setBounds(bounds);
    } catch (e) {
      console.error(e);
    }
  }, [region]);

  return (
    <Geosuggest
      ref={ref}
      bounds={bounds}
      {...props}
      inputType={'search'}
      placeDetailFields={placesFieldsToBeReturned}
    />
  );
});
