import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InfoTable from 'components/form/info-table/InfoTable';
import { uniqBy } from 'lodash';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';

import { LoadingTable } from 'components/grid/LoadingTable';
import { ExpandAllButton } from 'components/ant';
import { Column, Table, AutoSizer, defaultTableRowRenderer } from 'react-virtualized';
import { useCan } from 'features/permissions';
import entities from 'features/permissions/entities';
import {
  cache,
  vehicleCellRenderer,
  lastChecklistCellRenderer,
  registrationCellRenderer,
  maintenanceTypeCellRenderer,
  scheduledByCellRenderer,
  serviceDueCellRenderer,
  nextDueCellRenderer,
  lastServiceCellRenderer,
  currentOdometerCellRenderer,
  currentEngineHoursCellRenderer,
  ellipsisButtonCellRenderer,
  statusCellRenderer,
  notesCellRenderer,
  serviceNameCellRenderer,
  lastMileageCellRenderer
} from './utils/CellRenderers';

import './VehicleMaintenanceSchedules.scss';
import styles from './VehicleMaintenanceSchedules.module.scss';

export const VehicleMaintenanceSchedulesTable = ({
  schedules,
  users,
  expandAll,
  isLoading,
  handleDeleteAction,
  handleRestoreAction,
  handleRescheduleAction,
  handleExpandAll
}) => {
  const { t } = useTranslation();
  const can = useCan();
  const [expandedRows, setExpandedRows] = useState([]);
  const tableRef = useRef();
  const localization = useLocalization();
  const history = useHistory();
  const dispatch = useDispatch();
  const canViewVehicle = can && can({ everyEntity: [entities.VEHICLE_VIEW] });
  const canViewUser = can && can({ everyEntity: [entities.USER_VIEW] });
  const canViewMaintenanceType =
    can && can({ everyEntity: [entities.VEHICLEMAINTENANCETYPE_VIEW] });

  const vehicles = useMemo(
    () =>
      uniqBy(
        schedules
          .map(schedule => schedule.vehicle)
          .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        'id'
      ),
    [schedules]
  );

  const handleRowsState = (index, state) => {
    let newExpandedRows;
    switch (state) {
      case 'collapsed':
        newExpandedRows = [...expandedRows, index];
        break;
      case 'expanded':
        newExpandedRows = [...expandedRows].filter(item => item !== index);
        break;
      default:
        break;
    }
    setExpandedRows(newExpandedRows);
  };

  const ButtonExpandCollapse = ({ children, index, state }) => (
    <div style={{ cursor: 'pointer' }} onClick={() => handleRowsState(index, state)}>
      {children}
    </div>
  );

  const isRowExpanded = index => {
    return expandedRows.includes(index);
  };

  const _getRowHeight = ({ index }) => {
    let rowHeight = 64;
    const rowSchedules = schedules.filter(schedule => schedule.vehicle.id === vehicles[index].id);
    if (isRowExpanded(index)) {
      if (vehicles[index] && rowSchedules) {
        // Limit the height of the inner table
        if (rowSchedules.length > 4) {
          rowHeight = 460;
        } else {
          rowHeight = rowSchedules.length * 64 + 132;
        }
      }
    }
    return rowHeight;
  };

  const expandCollapseCellRenderer = ({ rowIndex, rowData }) => {
    if (isRowExpanded(rowIndex)) {
      return (
        <ButtonExpandCollapse index={rowIndex} state="expanded">
          <i className="tn-i-chevron-up" style={{ fontSize: '24px' }} />
        </ButtonExpandCollapse>
      );
    } else {
      return (
        <ButtonExpandCollapse index={rowIndex} state="collapsed">
          <i className="tn-i-chevron-down" style={{ fontSize: '24px' }} />
        </ButtonExpandCollapse>
      );
    }
  };

  useEffect(() => {
    if (expandAll) {
      const expandedRows = vehicles.map((vehicle, index) => index);
      setExpandedRows(expandedRows);
    } else {
      setExpandedRows([]);
    }
  }, [expandAll, vehicles]);

  useEffect(() => {
    if (tableRef && tableRef.current) {
      tableRef.current.recomputeRowHeights();
    }
  }, [expandedRows, schedules, tableRef]);

  const innerColumns = [
    {
      label: `${t('VehicleMntSchedules.Table.Status')}`,
      width: 120,
      cellRenderer: statusCellRenderer
    },
    {
      label: `${t('VehicleMntSchedules.Table.ServiceName')}`,
      width: 200,
      cellRenderer: props => serviceNameCellRenderer({ ...props, history, can })
    },
    {
      label: `${t('VehicleMntSchedules.Table.MaintenanceType')}`,
      width: 150,
      cellRenderer: props => maintenanceTypeCellRenderer({ ...props, canViewMaintenanceType })
    },
    {
      label: `${t('VehicleMntSchedules.Table.ScheduledBy')}`,
      width: 200,
      cellRenderer: props => scheduledByCellRenderer({ ...props, users, canViewUser })
    },
    {
      label: `${t('VehicleMntSchedules.Table.ServiceDue')}`,
      width: 200,
      cellRenderer: props => serviceDueCellRenderer({ ...props, localization, t })
    },
    {
      label: `${t('VehicleMntSchedules.Table.NextDue')}`,
      width: 200,
      cellRenderer: props => nextDueCellRenderer({ ...props, localization, t })
    },
    {
      label: `${t('VehicleMntSchedules.View.Completed')}`,
      width: 150,
      cellRenderer: props => lastServiceCellRenderer({ ...props, localization })
    },
    {
      label: `${t('VehicleMntSchedules.View.LastMileage')}`,
      width: 150,
      cellRenderer: props => lastMileageCellRenderer({ ...props, localization, t })
    },
    {
      label: `${t('VehicleMntSchedules.View.Notes')}`,
      width: 0,
      cellRenderer: props => notesCellRenderer(props)
    },
    {
      label: `${t('VehicleMntSchedules.Table.Actions')}`,
      width: 60,
      cellRenderer: props =>
        ellipsisButtonCellRenderer({
          ...props,
          handleDeleteAction,
          handleRestoreAction,
          handleRescheduleAction,
          t,
          history,
          dispatch
        })
    }
  ];

  let status = {
    OVERDUE: 1,
    DUE_NOW: 2,
    PENDING: 3,
    COMPLETED: 4,
    CANCELLED: 5
  };

  const rowRenderer = props => {
    const { index, style, className, key, rowData } = props;
    const vehicleSchedules = (
      schedules.filter(schedule => schedule.vehicle.id === rowData.id) || []
    ).sort((a, b) => status[a.status] - status[b.status]);
    if (isRowExpanded(index)) {
      return (
        <div
          style={{
            ...style,
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 0,
            paddingBottom: 0
          }}
          className={className}
          key={key}
        >
          {defaultTableRowRenderer({
            ...props,
            style: { width: style.width, height: 64 }
          })}
          <div
            style={{
              flex: '1 0 0',
              height: 'calc(100% - 64px)',
              width: '100%',
              padding: '16px 32px',
              background: '#f7f8f9'
            }}
          >
            <InfoTable
              infoTableCls={vehicleSchedules?.length < 3 ? styles.smallTable : ''}
              data={vehicleSchedules}
              columns={innerColumns}
              styles={styles}
              customRowStyle={{ alignItems: 'center', background: '#fff' }}
            />
          </div>
        </div>
      );
    }
    return defaultTableRowRenderer(props);
  };

  const expandAllRenderer = (
    <ExpandAllButton handleOnClick={handleExpandAll} expandAll={expandAll} />
  );

  if (isLoading) return <LoadingTable columnSizes={[73, 94, 57, 57, 94, 19]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          vehicle: 300,
          lastChecklist: 200,
          registration: 200,
          registrationState: 200,
          currentOdometer: 200,
          currentEngineHours: 200,
          schedules: 80,
          ellipsisButton: 60
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.vehicle +
            fixedColumnsWidth.lastChecklist +
            fixedColumnsWidth.registration +
            fixedColumnsWidth.currentOdometer +
            fixedColumnsWidth.schedules +
            fixedColumnsWidth.ellipsisButton);

        const columnStyle = {
          // padding: "12px 12px 12px 0"
        };

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={40}
            headerStyle={{ paddingTop: '5px' }}
            rowClassName="tableRow"
            rowHeight={_getRowHeight}
            rowCount={vehicles.length}
            rowGetter={({ index }) => vehicles[index]}
            ref={tableRef}
            rowRenderer={rowRenderer}
          >
            <Column
              label={expandAllRenderer}
              cellDataGetter={({ rowData }) => rowData.length}
              cellRenderer={expandCollapseCellRenderer}
              dataKey="index"
              disableSort
              width={40}
            />
            <Column
              style={columnStyle}
              label={t('VehicleMntSchedules.Table.Vehicle')}
              dataKey="name"
              width={fixedColumnsWidth.vehicle}
              cellRenderer={props => vehicleCellRenderer({ ...props, canViewVehicle })}
            />
            <Column
              style={columnStyle}
              label={t('VehicleMntSchedules.Table.LastPreTripChecklist')}
              dataKey="preTripChecklistName"
              width={fixedColumnsWidth.lastChecklist}
              cellRenderer={lastChecklistCellRenderer}
            />
            <Column
              style={columnStyle}
              label={t('VehicleMntSchedules.Table.Registration')}
              dataKey="registration"
              width={fixedColumnsWidth.registration}
              cellRenderer={registrationCellRenderer}
            />
            <Column
              style={columnStyle}
              label={t('VehicleMntSchedules.Table.CurrentOdometer')}
              dataKey=""
              width={fixedColumnsWidth.currentOdometer}
              cellRenderer={props => currentOdometerCellRenderer({ ...props, localization })}
            />
            <Column
              style={columnStyle}
              label={t('VehicleMntSchedules.Table.CurrentEngineHours')}
              dataKey=""
              width={dynamicColumnWidth}
              cellRenderer={props => currentEngineHoursCellRenderer({ ...props, localization })}
            />
            <Column
              style={columnStyle}
              label={t('VehicleMntSchedules.Table.Schedules')}
              dataKey=""
              width={fixedColumnsWidth.schedules}
              cellDataGetter={({ rowData, rowIndex }) =>
                schedules.filter(schedule => schedule.vehicle.id === rowData.id).length
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
