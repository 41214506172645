// TODO: use uppercase for constants
export const initialValues = {
  name: '',
  description: ''
};

export const getTypesQuery = {
  query: {
    direction: 'DOWN',
    pruning: 'ALL'
  }
};

export const getDeletedTypesQuery = {
  query: {
    direction: 'DOWN',
    pruning: 'ALL',
    status: 'DELETED'
  }
};

export const getSchedulesQuery = {
  query: {
    direction: 'DOWN'
  }
};

export const getDeletedSchedulesQuery = {
  query: {
    direction: 'DOWN',
    status: 'DELETED'
  }
};

export const ACTIONS = {
  ADD: 'add',
  EDIT: 'edit'
};

export const Paths = {
  MAIN_PAGE: '/settings/driverMgtTypes',
  VIEW_DRIVERMANAGEMENTTYPE: '/settings/driverMgtTypes/id',
  ADD_DRIVERMANAGEMENTTYPE: '/settings/driverMgtTypes/add',
  EDIT_DRIVERMANAGEMENTTYPE: '/settings/driverMgtTypes/edit/id',
  AUDIT_DRIVERMANAGEMENTTYPE: '/settings/driverMgtTypes/audit/id/:id'
};

export const TabKeys = {
  all: 'all',
  deleted: 'deleted'
};

export const Tabs = [
  {
    pathame: '/settings/driverMgtTypes',
    history: {},
    key: TabKeys.all
  },
  {
    pathame: '/settings/driverMgtTypes/deleted',
    history: {},
    key: TabKeys.deleted
  }
];

export const ColumnKeys = {
  NAME: 'name',
  COMPANY: 'companyId',
  DESCRIPTION: 'description',
  CREATED_AT: 'createdAt',
  ACTIONS: 'actions'
};

export const Columns = [
  {
    title: 'Name',
    dataIndex: ColumnKeys.NAME,
    key: ColumnKeys.NAME
  },
  {
    title: 'Company',
    dataIndex: ColumnKeys.COMPANY,
    key: ColumnKeys.COMPANY
  },
  {
    title: 'Description',
    dataIndex: ColumnKeys.DESCRIPTION,
    key: ColumnKeys.DESCRIPTION
  },
  {
    title: 'Created At',
    dataIndex: ColumnKeys.CREATED_AT,
    key: ColumnKeys.CREATED_AT
  },
  {
    title: 'Actions',
    dataIndex: ColumnKeys.ACTIONS,
    key: ColumnKeys.ACTIONS,
    width: 95
  }
];

export const LabelAlignLeft = 'left';
export const InputSizeLarge = 'large';
