import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useChattingEntity,
  setChattingEntity,
  useUnreadMessagesMeta
} from 'features/messaging/messagingSlice';
import { Row, Col, List, Tooltip, Button, Spin, Empty } from 'antd';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';
import { TreeSelect } from 'components/ant';
import {
  DEFAULT_VEHICLE_ICON,
  DEFAULT_DEVICE_ICON,
  DEFAULT_DRIVER_ICON,
  DEFAULT_GROUP_MESSAGE_ICON,
  SORT,
  RECIPIENT_TYPE,
  MESSAGE_SENDER_TYPE,
  MESSAGE_STYLES,
  MESSAGE_NOTIFICATION_TYPE,
  MESSAGE_DIR_ICON,
  MESSAGE_STATUE
} from './constants';
import styles from './Messaging.module.scss';
import { Loading } from 'components/loading/Loading';
import {
  getMessageDisplayDate,
  chatEntityMessageSentFailed,
  getMessageSubject,
  isRouteToMessage,
  messageCanMarkAsRead,
  getChatEntityLastChatMessageLastUpdatedDate,
  getWrappedChatEntity
} from './helpers';
import { useLocalization } from 'features/localization/localizationSlice';
import { toTitleCase } from 'utils/strings';
import { BUTTON_IDS } from 'utils/globalConstants';

const MessageVList = ({
  t,
  loading,
  dataSource,
  selectedChatEntity,
  getChatEntityName,
  getChatEntityMeta
}) => {
  const dispatch = useDispatch();
  const localization = useLocalization();

  const unreadMessagesMeta = useUnreadMessagesMeta();

  const onSelectListItem = entity => () => {
    dispatch(setChattingEntity(entity));
  };

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 90
  });

  const getWrappedChatEntityType = useCallback(
    chatEntity => getWrappedChatEntity(chatEntity).chatEntityType,
    []
  );
  const isGroupChatEntity = useCallback(
    chatEntity => getWrappedChatEntity(chatEntity).chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE,
    []
  );

  const chatEntityIcon = useCallback(chatEntityType => {
    let cls;
    if (chatEntityType === RECIPIENT_TYPE.VEHICLE) {
      cls = DEFAULT_VEHICLE_ICON;
    } else if (chatEntityType === RECIPIENT_TYPE.DEVICE) {
      cls = DEFAULT_DEVICE_ICON;
    } else if (chatEntityType === RECIPIENT_TYPE.DRIVER) {
      cls = DEFAULT_DRIVER_ICON;
    } else if (chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE) {
      cls = DEFAULT_GROUP_MESSAGE_ICON;
    }
    return cls ? <i className={`${cls} ${styles.messagingListItemIcon}`} /> : null;
  }, []);
  const getLastChatMessageSubject = useCallback(
    chatEntity => {
      if (chatEntity.chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE) {
        return (
          <>
            <b>{t('Messaging.Subject')}</b>
            {` : ${getMessageSubject(t, chatEntity.lastChatMsg)}`}
          </>
        );
      } else {
        return getMessageSubject(t, chatEntity.lastChatMsg);
      }
    },
    [t]
  );
  const getChatEntityMessageReceiptIcon = useCallback(
    chatEntity => {
      const message = chatEntity.lastChatMsg;
      if (chatEntity.chatEntityType === RECIPIENT_TYPE.GROUP_MESSAGE) {
        return;
      } else {
        const messageIsRead = msg =>
          msg.messageStatus.some(status => status.status === MESSAGE_STATUE.READ);
        const messageIsReceived = msg =>
          msg.messageStatus.some(status => status.status === MESSAGE_STATUE.RECEIVED);
        const isOutGoingMessage = msg =>
          isRouteToMessage(msg) ||
          !msg.device ||
          msg.messageSender?.senderType === MESSAGE_SENDER_TYPE.WEB_DISPATCHER;
        const receiptTooltip = messageIsRead(message)
          ? t('Messaging.STATUS.READ')
          : messageIsReceived(message)
          ? t('Messaging.STATUS.RECEIVED')
          : null;

        const receiptIcon = messageIsRead(message)
          ? 'tn-i-done-all'
          : messageIsReceived(message)
          ? 'tn-i-done'
          : null;
        const messageReadByRecipients =
          isOutGoingMessage(message) && (messageIsReceived(message) || messageIsRead(message));
        return messageReadByRecipients ? (
          <Tooltip title={receiptTooltip}>
            <i className={`${receiptIcon} ${styles.messagingListItemIcon}`} />
          </Tooltip>
        ) : null;
      }
    },
    [t]
  );

  const UnreadIndicator = useCallback(
    ({ chatEntity }) => {
      const unreadMessages = (chatEntity?.flattenChatMessages || []).filter(
        msg =>
          messageCanMarkAsRead(msg) &&
          (!unreadMessagesMeta[msg?.id] ||
            (!unreadMessagesMeta[msg?.id]?.isMarkingAsRead &&
              !unreadMessagesMeta[msg?.id]?.readAt &&
              !unreadMessagesMeta[msg?.id]?.error))
      );
      const hasUnread = !!unreadMessages.length;
      return (
        hasUnread && (
          <Tooltip title={t('Messaging.UnreadMessagesCount', { count: unreadMessages.length })}>
            <div className={styles.unreadMessageIndicator}></div>
          </Tooltip>
        )
      );
    },
    [t, unreadMessagesMeta]
  );

  return (
    <AutoSizer>
      {({ width, height }) => (
        <VList
          className={`${styles.messagingListItemsContainer} showScrollbarsOnHover ${
            loading ? styles.messageListLoading : ''
          }`}
          height={height - 54}
          width={width}
          rowCount={dataSource.length}
          overscanRowCount={3}
          deferredMeasurementCache={cache}
          rowHeight={cache.rowHeight}
          rowRenderer={({ index, parent, key, style }) => {
            const item = dataSource[index];
            return (
              <CellMeasurer
                key={key}
                cache={cache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
              >
                <List.Item
                  key={key}
                  style={style}
                  className={`${styles.messagingListItem} ${
                    selectedChatEntity &&
                    selectedChatEntity.chatEntityId === item.chatEntityId &&
                    selectedChatEntity.chatEntityName === item.chatEntityName &&
                    selectedChatEntity.chatEntityType === item.chatEntityType
                      ? styles.messagingListSelectedItem
                      : ''
                  }`}
                  onClick={onSelectListItem(item)}
                >
                  {<UnreadIndicator chatEntity={item} />}
                  <List.Item.Meta
                    style={{ marginBottom: 0, alignItems: 'baseline', width: '100%' }}
                    avatar={
                      <div style={{ position: 'relative' }}>{chatDirIcon(item.lastChatMsg)}</div>
                    }
                    title={
                      <Row justify="space-between">
                        <Col className={styles.messageRecipient} span={8}>
                          <Row gutter={16}>
                            <p className={styles.messageRecipientName}>
                              <Tooltip
                                placement="topLeft"
                                title={isGroupChatEntity(item) && getChatEntityName(item)}
                              >
                                {getChatEntityName(item)}
                              </Tooltip>
                            </p>
                            {isGroupChatEntity(item) && (
                              <p className={styles.messageRecipientCount}>
                                {`${
                                  item?.lastChatMsg?.recipients?.length
                                    ? '(' + item?.lastChatMsg?.recipients?.length + ')'
                                    : '(0)'
                                }`}
                              </p>
                            )}
                            <p style={{ marginBottom: 0 }}>
                              <Tooltip
                                title={
                                  !isGroupChatEntity(item) &&
                                  t(`Common.${toTitleCase(getWrappedChatEntityType(item))}`)
                                }
                              >
                                {chatEntityIcon(getWrappedChatEntityType(item))}
                              </Tooltip>
                            </p>
                          </Row>
                        </Col>
                        <Col className={styles.messageCurrentDriver} span={7}>
                          <Tooltip
                            placement="topLeft"
                            title={getChatEntityMeta(item).currentDriverName}
                          >
                            {getChatEntityMeta(item).currentDriverName || '-'}
                          </Tooltip>
                        </Col>
                        <Col span={9} className={styles.messageDate}>
                          {getMessageDisplayDate(
                            t,
                            getChatEntityLastChatMessageLastUpdatedDate(item),
                            localization.formats.time.formats.dmY_imsp
                          )}
                        </Col>
                      </Row>
                    }
                  />
                  <div className={styles.messageSubjectOverview}>
                    <span className={styles.messageSubject}>{getLastChatMessageSubject(item)}</span>
                    {getChatEntityMessageReceiptIcon(item)}
                  </div>
                  {chatEntityMessageSentFailed(item, item.lastChatMsg) && (
                    <div
                      className={`${styles.messageSentFailedInfo} ${styles.messageSentFailedInfoListView}`}
                    >
                      {t('Messaging.MessageSentFailed')}
                    </div>
                  )}
                </List.Item>
              </CellMeasurer>
            );
          }}
        />
      )}
    </AutoSizer>
  );
};

export const MessagingList = ({
  getChatEntityMeta,
  getChatEntityName,
  treeData,
  filteredChatEntities,
  isFetchingfilteredChatEntities,
  treeDataSelectValue,
  chatEntitySort,
  handleRecipientFilter,
  handleRecipientSort,
  messageIsRefreshing
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedChatEntity = useChattingEntity();

  useEffect(() => {
    if (isFetchingfilteredChatEntities) {
      return;
    }
    if (selectedChatEntity) {
      const updated = filteredChatEntities.find(
        entity =>
          entity.chatEntityType === selectedChatEntity.chatEntityType &&
          entity.chatEntityName === selectedChatEntity.chatEntityName &&
          entity.chatEntityId === selectedChatEntity.chatEntityId
      );
      dispatch(setChattingEntity(updated || filteredChatEntities[0] || null));
    } else {
      dispatch(setChattingEntity(filteredChatEntities ? filteredChatEntities[0] : null));
    }
  }, [isFetchingfilteredChatEntities, filteredChatEntities, dispatch]);

  return (
    <>
      <Row align="middle" className={styles.messagingListFilterBar}>
        <Col span={20} xl={22}>
          <TreeSelect
            size="middle"
            style={{ width: '100%' }}
            labelInValue={true}
            listHeight={195}
            treeDefaultExpandAll
            treeData={treeData}
            allowClear={false}
            placeholder={`${t('Common.Filter')} ${t('Messaging.Recipients')}`}
            treeNodeFilterProp="title"
            filterTreeNode={(inputValue, treeNode) => {
              const reg = new RegExp(inputValue, 'gi');
              if (typeof treeNode.title === 'object') {
                return reg.test(treeNode.rawData?.name);
              } else {
                return reg.test(treeNode.title);
              }
            }}
            maxTagCount={4}
            value={!treeData?.length ? [] : treeDataSelectValue}
            onChange={handleRecipientFilter}
            disabled={!treeData}
            dropdownRender={originNode => {
              return !treeData ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {' '}
                  <Loading />
                </div>
              ) : (
                originNode
              );
            }}
          />
        </Col>
        <Col span={4} xl={2} style={{ textAlign: 'center' }}>
          <Tooltip
            title={
              chatEntitySort === SORT.DESC
                ? t('Messaging.SORT.Newest to Oldest')
                : t('Messaging.SORT.Oldest to Newest')
            }
          >
            <Button
              className={styles.messageSortBtn}
              style={{ borderWidth: 0 }}
              icon={
                <i
                  style={{ fontSize: '25px' }}
                  className={
                    chatEntitySort === SORT.DESC ? 'tn-i-column-sort-desc' : 'tn-i-column-sort-asc'
                  }
                />
              }
              onClick={handleRecipientSort}
              id={BUTTON_IDS.messagingListSort}
            />
          </Tooltip>
        </Col>
      </Row>
      {(messageIsRefreshing || !filteredChatEntities) && (
        <Spin className={styles.messageListLoader} />
      )}
      {!(messageIsRefreshing || !filteredChatEntities) &&
        filteredChatEntities &&
        filteredChatEntities.length === 0 && (
          <div className={styles.messageListNoData}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('Messaging.NoMessages')} />
          </div>
        )}
      {filteredChatEntities && filteredChatEntities.length > 0 && <ListHeader />}
      {filteredChatEntities && filteredChatEntities.length > 0 && (
        <MessageVList
          t={t}
          loading={messageIsRefreshing || !filteredChatEntities}
          selectedChatEntity={selectedChatEntity}
          dataSource={filteredChatEntities}
          getChatEntityName={getChatEntityName}
          getChatEntityMeta={getChatEntityMeta}
        />
      )}
    </>
  );
};

export default MessagingList;

const chatDirIcon = message => {
  const isRouteTo = message.notificationType === MESSAGE_NOTIFICATION_TYPE.ROUTE_TO;
  const arrowIcon = isRouteTo
    ? MESSAGE_DIR_ICON.SENT
    : !message.device
    ? MESSAGE_DIR_ICON.SENT
    : MESSAGE_DIR_ICON.RECEIVED;
  let cssCls = MESSAGE_STYLES.OTHER.cssCls;
  if (isRouteTo) {
    cssCls = MESSAGE_STYLES.ROUTE_TO.cssCls;
  } else if (message.messageSender?.senderType === MESSAGE_SENDER_TYPE.WEB_DISPATCHER) {
    cssCls = MESSAGE_STYLES.WEB_DISPATCHER.cssCls;
  }
  return <i className={`${arrowIcon} ${styles[cssCls]}`} />;
};

const ListHeader = () => {
  const { t } = useTranslation();
  return (
    <Row className={styles.messagingListHeader} justify="space-between">
      <Col span={8} className={styles.messagingListHeaderItem}>
        {`${t('Messaging.To')}/${t('Messaging.From')}`}
      </Col>
      <Col span={6} className={styles.messagingListHeaderItem}>
        {t('Messaging.Current Driver')}
      </Col>
      <Col span={9} className={styles.messagingListHeaderItem}>
        {t('Common.DateTime')}
      </Col>
    </Row>
  );
};
