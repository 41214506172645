import React from 'react';
import { useParams } from 'react-router';

import { useGetQsUserQuery, useGetQsConsoleUrlQuery } from 'services/nextgen';
import { useQuery } from 'utils/hooks/useQuery';

import { Toolbar } from './Toolbar';

export const DashboardToolbar = ({ children, ...props }) => {
  const { dashboardId } = useParams();
  const params = useQuery();
  const isCustom = params.get('custom') !== null;
  const dashboardUrl = useGetQsConsoleUrlQuery({ dashboardId: dashboardId });
  const qsUser = useGetQsUserQuery()?.data;
  const isQsReader = qsUser?.role?.toUpperCase() === 'READER';

  return (
    <Toolbar {...props}>
      {children}

      {/* button masks */}
      {dashboardUrl.data && (
        <>
          <div
            style={{
              display: 'block',
              position: 'absolute',
              right: isCustom ? (isQsReader ? '8rem' : '12rem') : isQsReader ? '6rem' : '10rem',
              marginTop: '2.2rem',
              width: '10rem',
              height: '2.2rem',
              backgroundColor: '#eee',
              zIndex: 'auto'
            }}
          />
          <div
            style={{
              display: 'block',
              position: 'absolute',
              right: 0,
              marginTop: '2.2rem',
              width: isCustom ? (isQsReader ? '4rem' : '8rem') : isQsReader ? '2rem' : '6rem',
              height: '2.2rem',
              backgroundColor: '#eee',
              zIndex: 'auto'
            }}
          />
        </>
      )}
    </Toolbar>
  );
};
