import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ForceCompleteModal } from './ForceCompleteModal';
import { Col, Layout, Row } from 'antd';
import { TabsFilters, MoreMenu } from 'components/ant';
import { setPageTitle, setBackButton, addBackButtonLink } from 'features/page/pageSlice';
import { useRunsheet, useRunsheetEvents, onRunsheetViewLeave } from 'features/smartJobs';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';
import styles from './SmartJobs.module.scss';
import {
  getIDFromPathname,
  isRunsheetCompleted,
  isRunsheetFrozen,
  isRunsheetInProgress
} from './utils/helpers';
import { ViewTabs, ViewTabsLabels, Paths } from './utils/constants';
import AllocateModal from './AllocateModal/AllocateModal';
import { useFleets, useVehicles } from 'features/fleets/fleetsSlice';
import { getTabs } from 'utils/tabs';

import { JobsTable } from './RunsheetViewTabs/JobsTable/JobsTable';
import { LoadedItems } from './RunsheetViewTabs/LoadedItems';
import { EventsTable } from './RunsheetViewTabs/EventsTable';
import { Details } from './RunsheetViewTabs/Details';
import { useCan } from 'features/permissions/canHooks';
import entities from 'features/permissions/entities';

const getActiveTab = (activeTab, runsheet, runsheetEvents, setShowAllocateModal) => {
  switch (activeTab) {
    case ViewTabsLabels.DETAILS:
      return <Details runsheet={runsheet} events={runsheetEvents} />;
    case ViewTabsLabels.JOBS:
      return <JobsTable runsheet={runsheet} setShowAllocateModal={setShowAllocateModal} />;
    case ViewTabsLabels.LOADED_ITEMS:
      return <LoadedItems runsheet={runsheet} />;
    case ViewTabsLabels.EVENTS:
      return <EventsTable runsheet={runsheet} events={runsheetEvents} />;
    default:
      return;
  }
};

const RunsheetView = () => {
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const runsheetId = state?.id || getIDFromPathname(pathname);

  const [activeTab, setActiveTab] = useState(ViewTabsLabels.DETAILS);
  const [showAllocateModal, setShowAllocateModal] = useState(false);
  const runsheet = useRunsheet(runsheetId);
  const fleets = useFleets();
  const vehicles = useVehicles();
  const runsheetEvents = useRunsheetEvents();
  const { t } = useTranslation();
  const can = useCan();
  const [showForceCompleteModal, setShowForceCompleteModal] = useState(false);

  useRedirectToMainFeaturePageOnCompanyChange('/smartjobs');

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(
      addBackButtonLink({
        url: `${pathname}`,
        backLink: Paths.SMARTJOBS_DEFAULT
      })
    );
  }, []);

  useEffect(() => {
    dispatch(setPageTitle(`${t('SmartJobs.Runsheet')} ${runsheet?.externalId ?? ''}`));
  }, [runsheet]);

  useEffect(() => {
    return () => {
      onRunsheetViewLeave(dispatch);
    };
  }, [runsheetEvents]);

  const handleTabClick = value => {
    setActiveTab(value);
  };

  const renderMoreMenu = () => {
    if (isRunsheetCompleted(runsheet)) {
      return;
    }
    const options = [
      {
        name: t('SmartJobs.ForceComplete'),
        onClick: () => setShowForceCompleteModal(true),
        id: 'btn_runsheetViewMenuComplete'
      }
    ];
    if (
      !isRunsheetInProgress(runsheet) &&
      !isRunsheetFrozen(runsheet) &&
      can &&
      can({ everyEntity: [entities.RUNSHEET_UPDATE] })
    ) {
      options.unshift({
        name: t('Actions.Allocate'),
        onClick: () => setShowAllocateModal(true),
        id: 'btn_runsheetViewAllocate'
      });
    }
    return (
      <div className={styles.menuContainer}>
        <MoreMenu size="xl" border items={options} useNewIcon={true} />
      </div>
    );
  };

  return (
    <>
      {showForceCompleteModal && (
        <ForceCompleteModal
          visible={showForceCompleteModal}
          showModal={setShowForceCompleteModal}
        />
      )}
      <AllocateModal
        isOpen={showAllocateModal}
        onCancel={() => setShowAllocateModal(false)}
        current={runsheet}
        fleets={fleets}
        vehicles={vehicles}
      />
      <Layout>
        <Row>
          <Col span={20}>
            <div className={styles.tabContainer}>
              <TabsFilters tabs={getTabs(ViewTabs, activeTab, handleTabClick, t)} />
            </div>
          </Col>
          <Col span={4}>{renderMoreMenu()}</Col>
        </Row>
        {getActiveTab(activeTab, runsheet, runsheetEvents, setShowAllocateModal)}
      </Layout>
    </>
  );
};

export default RunsheetView;
