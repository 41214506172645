import React, { useState, useEffect, useCallback } from 'react';
import { AlertData, AlertStatus } from 'components/notifications/alerts/AlertData';
import { RichNotification } from 'components/notifications/alerts/RichNotification';
import { dismissNotification, dismissRichNotification } from './notificationsSlice';
import styles from './Notification.module.scss';
import { useDispatch } from 'react-redux';
import { openToast } from '../toasts/toastsSlice';
import { useUser } from '../user/userSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { EventDetailPageOpenMethods, OPEN_EVENT_DETAIL_PAGE } from '../../config';
import { EventUtil } from '../events/eventUtil';
import { useHistory } from 'react-router';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useTranslation } from 'react-i18next';
import { getEventUrl } from './helpers';
import { format } from 'utils/dates';

export function AlertListCommonRender({ onViewDetail, onViewEmbedDetail, onDismiss, alertData }) {
  const [alertStatus, setAlertStatus] = useState({});
  const [alertDataList, setAlertDataList] = useState([]);

  const dispatch = useDispatch();
  const userInfo = useUser();
  const urlHistory = useHistory();
  const { t } = useTranslation();

  const handleViewDetail = useCallback(
    alert => {
      if (onViewDetail != null) {
        onViewDetail(alert);
      }

      const pageUrl = EventUtil.generateUrlForEventDetailPage(
        alert,
        userInfo.id,
        userInfo.auth.key
      );
      if (pageUrl != null && pageUrl !== '') {
        if (
          OPEN_EVENT_DETAIL_PAGE === EventDetailPageOpenMethods.FullTab ||
          OPEN_EVENT_DETAIL_PAGE === EventDetailPageOpenMethods.SimpleTab
        ) {
          window.open(pageUrl, '_blank');
        }
      }
    },
    [onViewDetail, userInfo]
  );

  const handleEmbedViewDetail = useCallback(
    alert => {
      if (onViewEmbedDetail != null) {
        onViewEmbedDetail(alert);
      }
      window.open(getEventUrl(alert), '_blank');
    },
    [urlHistory, onViewEmbedDetail]
  );

  const handleDismiss = useCallback(
    alert => {
      if (onDismiss) {
        onDismiss(alert);
      }

      setAlertStatus(prevAlertStatus => ({
        ...prevAlertStatus,
        [alert.id]: AlertStatus.Processing
      }));
      dispatch(dismissNotification(alert.id)).then(
        done => {
          dispatch(
            openToast({
              type: ToastType.Success,
              message: t('Notifications.AlertDismissed')
            })
          );
          setAlertStatus(prevAlertStatus => ({
            ...prevAlertStatus,
            [alert.id]: AlertStatus.Dismiss
          }));
        },
        error => {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('Notifications.DismissFailed')
            })
          );
          setAlertStatus(prevAlertStatus => ({
            ...prevAlertStatus,
            [alert.id]: AlertStatus.Error
          }));
        }
      );
    },
    [onDismiss, dispatch]
  );

  const updateAlertStatus = useCallback(() => {
    setAlertStatus(prevStatus => {
      const newAlertStatus = {};
      for (let id in prevStatus) {
        if (prevStatus[id] != null && prevStatus[id].status !== AlertStatus.Dismiss) {
          newAlertStatus[id] = prevStatus[id].status;
        }
      }
      return newAlertStatus;
    });
  }, []);

  useEffect(() => {
    let timeoutHandle = null;
    setAlertDataList(prevAlert => {
      if (prevAlert == null || prevAlert.length === 0) {
        return alertData;
      } else {
        timeoutHandle = setTimeout(() => {
          setAlertDataList(alertData);
          updateAlertStatus();
        }, 600);

        return prevAlert;
      }
    });
    return () => {
      if (timeoutHandle != null) {
        clearTimeout(timeoutHandle);
      }
    };
  }, [alertData, updateAlertStatus]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          transition: 'filter 0.2s linear'
        }}
      >
        {alertDataList.length > 0 && (
          <div className={styles.alertItems}>
            {alertDataList.map((dataItem, idx) => (
              <AlertData
                key={dataItem.id}
                data={dataItem}
                status={alertStatus[dataItem.id]}
                onView={handleViewDetail}
                onDismiss={handleDismiss}
                onEmbedView={handleEmbedViewDetail}
              />
            ))}
          </div>
        )}
        {alertDataList.length === 0 && (
          <div
            style={{
              color: '#969696',
              fontWeight: '500',
              fontSize: 1,
              textAlign: 'center'
            }}
          >
            {t('Notifications.NoAlerts')}
          </div>
        )}
      </div>
    </>
  );
}

export const RichNotificationsRender = ({ richNotifications = [], onViewEmbedDetail }) => {
  const localization = useLocalization();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleDismissRichNotification = id => () => {
    dispatch(dismissRichNotification(id));
  };
  const getNotificationTime = useCallback(
    notification => {
      const time = notification?.extras?.CreatedDateTime || notification?.eventAt;
      return time && format(new Date(time), localization.formats.time.formats.dby_imp);
    },
    [localization]
  );
  return (
    <div className={styles.richNotifications}>
      <div className={styles.header}>
        <div className={styles.title}>
          {`${t('Notifications.RichNotifications.Processes')} (${richNotifications?.length})`}
        </div>
      </div>
      <div>
        {[...richNotifications]?.reverse()?.map(notification => (
          <RichNotification
            key={notification?.id}
            t={t}
            notification={notification}
            createdAt={getNotificationTime(notification)}
            handleDismissRichNotification={handleDismissRichNotification}
            onViewEmbedDetail={onViewEmbedDetail}
          />
        ))}
      </div>
    </div>
  );
};
