import React, { useCallback, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';

import styles from '../Documents/DocumentView.module.scss';
//toolbar
export const DevicesToolbar = ({
  filteredDevicesCount,
  searchText,
  companiesFilter,
  fleetsFilter,
  onSearchChange,
  onCompaniesFilterChange,
  onFleetsFilterChange
}) => {
  const { t } = useTranslation();
  const timeoutHandler = useRef(-1);

  const handleSearchChange = useCallback(value => {
    if (timeoutHandler.current !== -1) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = -1;
    }
    timeoutHandler.current = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(value);
      }
      timeoutHandler.current = -1;
    }, 100);
  }, []);

  return (
    <div className={styles.documentToolbar}>
      <AntSearchbar defaultValue={searchText} onFilter={handleSearchChange} />
      <AntMultiselect
        title={
          companiesFilter?.some(value => !value.checked)
            ? t('Common.Companies')
            : t('Common.AllCompanies')
        }
        data={companiesFilter}
        onFilter={onCompaniesFilterChange}
      />
      <AntMultiselect
        title={
          fleetsFilter?.some(value => !value.checked) ? t('Common.Fleets') : t('Common.AllFleets')
        }
        data={fleetsFilter}
        onFilter={onFleetsFilterChange}
      />
      <div>
        <div className={styles.documentsCount}>
          {filteredDevicesCount + ' ' + t('Vehicles.View.Devices')}
        </div>
      </div>
    </div>
  );
};
//toolbar
export const DetailsToolbar = ({
  filteredDocumentsCount,
  searchText,
  onSearchChange,
  documentFilter,
  onDocumentFilterChange
}) => {
  const { t } = useTranslation();
  const timeoutHandler = useRef(-1);

  const handleSearchChange = useCallback(value => {
    if (timeoutHandler.current !== -1) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = -1;
    }
    timeoutHandler.current = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(value);
      }
      timeoutHandler.current = -1;
    }, 100);
  }, []);

  return (
    <div className={styles.documentToolbar}>
      <AntSearchbar defaultValue={searchText} onFilter={handleSearchChange} />
      <AntMultiselect
        title={
          documentFilter?.some(value => !value.checked)
            ? t('Common.Companies')
            : t('Easydocs.AllDocuments')
        }
        data={documentFilter}
        onFilter={onDocumentFilterChange}
      />
      <div>
        <div className={styles.documentsCount}>
          {filteredDocumentsCount + ' ' + t('Easydocs.Documents')}
        </div>
      </div>
    </div>
  );
};
