import React, { useEffect, useState } from 'react';
import { AutoComplete as AntAutoComplete } from 'antd';

export const AutoComplete = ({ ...props }) => {
  return (
    <AntAutoComplete
      {...props}
      filterOption={(inputValue, option) =>
        option.value
          ?.toString()
          .toUpperCase()
          .indexOf(inputValue?.toString().toUpperCase()) !== -1
      }
    />
  );
};
