import React, { useEffect, useState, useRef } from 'react';
//hooks
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  useResendEventMutation,
  useBulkResendMutation,
  useLazyGetEventsQuery,
  useGetEventByIdMutation
} from 'services/nextgen';
import { useLocalization } from 'features/localization/localizationSlice';
import { useCurrentCompanyId } from 'features/company/companySlice';
//components
import { Table, Drawer, DatePicker, Button, Alert, Select } from 'antd';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
//constants & helpers
import { columns } from './constants';
import {
  prepareEventsForTable,
  eventDetails,
  eventStatus,
  eventContent,
  loader,
  filterByStatusOptions,
  filterEventsByStatus,
  statuses
} from './helpers';
import { parseErrorMessage } from 'utils/strings';
import moment from 'moment/moment';
//styles
import styles from './Events.module.scss';

export const Events = ({ adapterType }) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const companyId = useCurrentCompanyId();
  const [resendEvent] = useResendEventMutation({ companyId });
  const [getEventById, resultById] = useGetEventByIdMutation({ companyId });
  const [bulkResend] = useBulkResendMutation({ companyId });
  const [showEventDrawer, setShowEventDrawer] = useState(false);
  const [eventInDrawer, setEventInDrawer] = useState(null);
  const [trigger, result] = useLazyGetEventsQuery({ companyId });
  const { data: events, error, isFetching } = result || {};
  const { data: eventById, error: eventByIdError, isLoading: eventByIdLoading } = resultById;
  let calendarSelectedDates = useRef({ dates: [], isoDates: [] });
  const [filterBy, setFilterBy] = useState(statuses.ALL);
  const [filteredEvents, setFilteredEvents] = useState(null);

  const [tempDates, setTempDates] = useState([moment(), moment().add(1, 'days')]);
  const [dates, setDates] = useState(tempDates);
  const [isoDates, setIsoDates] = useState([
    moment()
      .toISOString()
      ?.split('T')[0],
    moment()
      .add(1, 'days')
      ?.toISOString()
      ?.split('T')[0]
  ]);

  const rangePresets = [
    { label: t('Common.Today'), value: [moment(), moment()] },
    { label: t('Common.Yesterday'), value: [moment().add(-1, 'd'), moment()] },
    { label: t('Common.Past2Days'), value: [moment().add(-2, 'd'), moment()] },
    { label: t('Common.Past7Days'), value: [moment().add(-7, 'd'), moment()] }
  ];

  const openEventDrawer = event => {
    setShowEventDrawer(true);
    getEventById({
      eventId: event.id,
      companyId,
      timeAt: event?.createdAt
    });
  };

  useEffect(() => {
    if (eventById === null) {
      return;
    }
    setEventInDrawer(eventById);
  }, [eventById]);

  const closeEventDrawer = () => {
    setShowEventDrawer(false);
    setEventInDrawer(null);
  };

  const resendEventMethod = event => {
    resendEvent({
      eventId: event.id,
      companyId,
      timeAt: event?.createdAt,
      body: {
        createdAt: event?.createdAt
      }
    });
  };

  const refreshEvents = () => {
    isoDates
      ? trigger({
          companyId,
          adapterType,
          selectedDates: isoDates
        })
      : trigger({ companyId, adapterType, selectedDates: [] });
  };

  const bulkResendMethod = () =>
    bulkResend({
      companyId,
      adapterType,
      to: isoDates[1] && calendarSelectedDates.current?.isoDates[1],
      from: isoDates[0]
    });

  const bulkResendModal = () => {
    confirmationModal(
      `${t('Integrations.EventsTab.BulkResend')}`,
      isoDates && isoDates[0] && isoDates[1]
        ? `${t('Integrations.EventsTab.BulkResendModalDescriptionWithDates', {
            fromDate: isoDates[0],
            toDate: isoDates[1]
          })}`
        : `${t('Integrations.EventsTab.BulkResendModalDescriptionNoDates')}`,
      t('Integrations.EventsTab.BulkResend'),
      t('Common.CancelButton'),
      bulkResendMethod
    );
  };

  useEffect(() => {
    if (isoDates) {
      trigger({
        companyId,
        adapterType,
        selectedDates: isoDates
      });
    }
  }, [isoDates]);

  useEffect(() => {
    if (error?.data?.error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(error?.data)
        })
      );
    }
  }, [error?.data?.id]);

  useEffect(() => {
    setFilteredEvents(filterEventsByStatus(events, filterBy));
  }, [events, filterBy]);

  const handleDisableDates = current => {
    if (!tempDates) {
      return false;
    }
    const tooLate = tempDates[0] && current.diff(tempDates[0], 'days') >= 7;
    const tooEarly = tempDates[1] && tempDates[1].diff(current, 'days') >= 7;
    return !!tooEarly || !!tooLate;
  };

  const handleOnOpenChange = open => {
    if (open) {
      setTempDates([null, null]);
    } else {
      setTempDates(null);
    }
  };

  const handleStatusChange = value => {
    setFilterBy(value);
  };

  return (
    <div className={styles.eventsContainer}>
      <Drawer
        title={`${t('Integrations.EventsTab.Event')} ${eventInDrawer?.id || ''}`}
        placement="right"
        open={showEventDrawer}
        onClose={closeEventDrawer}
        className={styles.integrationsEventDrawer}
        width={'35%'}
      >
        {loader(eventByIdLoading)}
        {!eventByIdLoading && !eventInDrawer ? (
          <Alert message={t('Integrations.EventsTab.NoDataAvailable')} showIcon type="error" />
        ) : (
          <>
            {eventStatus(eventInDrawer, t, eventByIdLoading)}
            {eventDetails(eventInDrawer, localization, t, eventByIdLoading)}
            {eventContent(eventInDrawer, eventByIdLoading)}
          </>
        )}
      </Drawer>
      <div className={styles.eventsActionsHeader}>
        <div>
          <DatePicker.RangePicker
            allowClear={false}
            value={tempDates || dates}
            disabledDate={handleDisableDates}
            onCalendarChange={val => {
              setTempDates(val);
            }}
            onChange={val => {
              setDates(val);
              setIsoDates(val?.map(date => date?.toISOString()?.split('T')[0] || null) || null);
            }}
            onOpenChange={handleOnOpenChange}
            changeOnBlur
            presets={rangePresets}
          />
          <Select
            className={styles.eventsStatusSelect}
            value={filterBy}
            style={{ width: 120 }}
            onChange={handleStatusChange}
            options={filterByStatusOptions(t)}
          />
        </div>
        <div>
          <Button type="primary" onClick={bulkResendModal}>
            {t('Integrations.EventsTab.BulkResend')}
          </Button>
          <Button className={styles.eventsRefresh} type="secondary" onClick={refreshEvents}>
            {t('Integrations.EventsTab.Refresh')}
          </Button>
        </div>
      </div>
      <Table
        className={styles.table}
        columns={columns(t)}
        dataSource={prepareEventsForTable({
          events: filteredEvents,
          localization,
          t,
          openEventDrawer,
          resendEventMethod
        })}
        loading={isFetching}
        pagination={{ position: ['bottomRight'], hideOnSinglePage: true, defaultPageSize: 100 }}
        scroll={{ y: 'calc(100vh - 270px)' }}
        showSorterTooltip={false}
      />
    </div>
  );
};
