import React from 'react';
import DatePickerWrapper from './DatePickerWrapper';
import CalibrationWrapper from './CalibrationWrapper';

const VehicleCalibrateMeters = ({
  disableSyncDatePicker,
  onSyncDateChange,
  onEventChange,
  fetchingEvents,
  events,
  vehicleData,
  calibrateValues,
  onCalibrateValueChange,
  onCalibrate,
  calibratingInProgress
}) => {
  return (
    <React.Fragment>
      <DatePickerWrapper
        disableSyncDatePicker={disableSyncDatePicker}
        onSyncDateChange={onSyncDateChange}
        onEventChange={onEventChange}
        fetchingEvents={fetchingEvents}
        events={events}
      />
      <CalibrationWrapper
        vehicleData={vehicleData}
        calibrateValues={calibrateValues}
        onCalibrate={onCalibrate}
        onCalibrateValueChange={onCalibrateValueChange}
        calibratingInProgress={calibratingInProgress}
      />
    </React.Fragment>
  );
};

export default VehicleCalibrateMeters;
