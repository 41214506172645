import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import {
  cache,
  nameCellRenderer,
  companyCellRenderer,
  descriptionCellRenderer,
  ellipsisButtonCellRenderer
} from './CellRenderers';
import { useTranslation } from 'react-i18next';
import { useCan } from 'features/permissions/canHooks';
import { LoadingTable } from 'components/grid/LoadingTable';
import entities from 'features/permissions/entities';
import { Empty } from 'components/ant';

export const RolesTable = ({
  roles,
  companies,
  isLoading,
  setTableRef,
  deleteModal,
  canEditGlobalRoles
}) => {
  const { t } = useTranslation();
  const can = useCan();
  const canView = can && can({ everyEntity: [entities.ROLE_VIEW] });
  if (isLoading) return <LoadingTable columnSizes={[80, 60, 60, 68, 63, 49]} />;

  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 280,
          company: 230,
          ellipsis: 70
        };

        const dynamicColumnWidth =
          width - (fixedColumnsWidth.name + fixedColumnsWidth.company + fixedColumnsWidth.ellipsis);

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={roles.length}
            rowGetter={({ index }) => roles[index]}
            ref={ref => setTableRef(ref)}
            noRowsRenderer={() => <Empty />}
          >
            <Column
              label={t('Roles.Table.Name')}
              dataKey="name"
              width={fixedColumnsWidth.name}
              cellRenderer={props => nameCellRenderer({ ...props, canView })}
            />
            <Column
              label={t('Roles.Table.Company')}
              dataKey="companyId"
              width={fixedColumnsWidth.company}
              cellRenderer={props => companyCellRenderer({ ...props, companies: companies })}
            />
            <Column
              label={t('Roles.Table.Description')}
              dataKey="global"
              width={dynamicColumnWidth}
              cellRenderer={descriptionCellRenderer}
            />
            <Column
              label={t('Roles.Table.Actions')}
              dataKey="ellipsisMenu"
              style={{ overflow: 'visible' }}
              width={52}
              flexShrink={0}
              cellRenderer={props =>
                ellipsisButtonCellRenderer({ ...props, deleteModal, canEditGlobalRoles, can })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
