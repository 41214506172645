/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LocalDateTime from './LocalDateTime';

/**
 * The RegistrationCode model module.
 * @module model/RegistrationCode
 * @version 1.0
 */
class RegistrationCode {
    /**
     * Constructs a new <code>RegistrationCode</code>.
     * @alias module:model/RegistrationCode
     */
    constructor() { 
        
        RegistrationCode.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>RegistrationCode</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RegistrationCode} obj Optional instance to populate.
     * @return {module:model/RegistrationCode} The populated <code>RegistrationCode</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RegistrationCode();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('companyId')) {
                obj['companyId'] = ApiClient.convertToType(data['companyId'], 'Number');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
            if (data.hasOwnProperty('workOrder')) {
                obj['workOrder'] = ApiClient.convertToType(data['workOrder'], 'String');
            }
            if (data.hasOwnProperty('expiresAt')) {
                obj['expiresAt'] = ApiClient.convertToType(data['expiresAt'], 'Date');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = LocalDateTime.constructFromObject(data['createdAt']);
            }
        }
        return obj;
    }


}

/**
 * @member {Number} id
 */
RegistrationCode.prototype['id'] = undefined;

/**
 * @member {Number} companyId
 */
RegistrationCode.prototype['companyId'] = undefined;

/**
 * @member {String} code
 */
RegistrationCode.prototype['code'] = undefined;

/**
 * @member {String} workOrder
 */
RegistrationCode.prototype['workOrder'] = undefined;

/**
 * @member {Date} expiresAt
 */
RegistrationCode.prototype['expiresAt'] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
RegistrationCode.prototype['createdAt'] = undefined;






export default RegistrationCode;

