import { v4 as uuidv4 } from 'uuid';
import { JobItems } from './constants';

export const jobsToServer = (jobs = [], locations = [], initialJobs = []) => {
  return jobs.map(job => {
    const { location, stop, location_type, jobItems, jobType, ...jobToServer } = job;
    const initialJob = initialJobs.find(iJob => iJob.externalId === job.externalId);
    const fullJobToServer = { ...initialJob, ...jobToServer };
    const { locationType, siteNotes, ...rest } = fullJobToServer;

    // IF the job does not have an external uuid attached - create one
    if (!rest.externalId) {
      rest.externalId = uuidv4();
    }

    if (typeof location === 'string' && !stop) {
      return {
        ...rest,
        type: jobType,
        jobItems: jobItemsToServer(jobItems)
      };
    }

    if (typeof location === 'object') {
      // Build the correct geofence object
      const {
        location: { id }
      } = location;
      const customerLocation = locations.find(l => l.id === id);

      return {
        ...rest,
        type: jobType,
        jobItems: jobItemsToServer(jobItems),
        stop: { ...location, location: customerLocation }
      };
    }

    const formattedLocation = locationToServer(location, locations);

    // Add GPS to stop
    if (stop) {
      formattedLocation.GPS = {
        Lat: stop.location?.lat,
        Lng: stop.location?.lng
      };

      formattedLocation.type = {
        code: 'JOB'
      };

      formattedLocation.name = stop.label;
      formattedLocation.externalId = uuidv4();
    }

    return {
      ...rest,
      type: jobType,
      jobItems: jobItemsToServer(jobItems),
      stop: { location: formattedLocation }
    };
  });
};

const jobItemsToServer = (items = []) =>
  items.map(item => {
    if (item.id) {
      const {
        id,
        createdAt,
        originalQuantity,
        originalId,
        originalCreatedAt,
        status,
        ...rest
      } = item;

      return { item: rest, id: originalId, createdAt: originalCreatedAt, originalQuantity, status };
    }
    const { uuid, ...itemToServer } = item;

    return { item: itemToServer, uuid };
  });

function locationToServer(locationId, locations = []) {
  const currentLocation = locations.find(location => location.id === locationId);
  return currentLocation || {};
}

export const jobsFromServer = (runsheet, isCopy) => {
  if (!runsheet?.jobs) {
    return [];
  }

  return runsheet.jobs.map(job => {
    return {
      [JobItems.NAME.key]: job[JobItems.NAME.key],
      [JobItems.JOB_TYPE.key]: job[JobItems.JOB_TYPE.key] || job.type,
      [JobItems.LOCATION.key]: job.stop,
      seq: job.seq,
      [JobItems.LOCATION_TYPE.key]: job.stop?.location?.type?.code,
      [JobItems.NOTES.key]: job[JobItems.NOTES.key],
      ...(!isCopy && { [JobItems.EXTERNAL_ID.key]: job[JobItems.EXTERNAL_ID.key] }),
      jobItems: (job.jobItems || [])
        .map(jobItem => {
          return (
            (jobItem.item && {
              ...jobItem.item,
              originalQuantity: jobItem.originalQuantity,
              originalId: jobItem.id,
              originalCreatedAt: jobItem.createdAt
            }) ||
            jobItem
          );
        })
        .map(jobItem =>
          isCopy
            ? Object.keys(jobItem || {})
                .filter(jobItemKey => jobItemKey !== 'id')
                .reduce((a, jobItemKey) => ({ ...a, [jobItemKey]: jobItem[jobItemKey] }), {
                  uuid: uuidv4()
                })
            : jobItem
        ),
      ...(isCopy ? { [JobItems.EXTERNAL_ID.key]: uuidv4() } : { id: job.id })
    };
  });
};

export const geofenceToServer = (jobs = [], locations = []) => {
  return jobs.map(job => {
    if (job.stop.location?.geofences?.length) {
      const { id: locationId } = job.stop.location;
      const location = locations.find(loc => loc.id === locationId);
      // If we couldn't find the actual location - return the initial job
      if (!location) {
        return job;
      }

      // Update the geofence array to match the one in locations
      return {
        ...job,
        stop: {
          location: {
            ...job.stop.location,
            geofences: location.geofences
          }
        }
      };
    }

    return job;
  });
};
