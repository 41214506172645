import { getLocalizedDistance } from 'utils/methods';
import { ENTITIES } from 'components/alertStatusBar/constants';
import {
  ALERT_TYPES,
  usesFleetsAndVehicles,
  usesDriversAndBranches,
  eventSupportMultiTiered
} from '../../constants';

const sortByAscendingHours = (a, b) => a[0] - b[0];

const getCalendarPayload = (calendar, userTimezone) => {
  const totalTimeslots = [];

  // For each day we sort all the hour intervals ascendingly and group together neighbouring intervals
  Object.entries(calendar).forEach(dayIndex => {
    // we do a +1 operation because arrays start at zero and weekdays at 1
    const weekday = +dayIndex[0] + 1;
    const hoursInterval = dayIndex[1];
    const sortedHoursInterval = new Map([...hoursInterval.entries()].sort(sortByAscendingHours));
    let currentTimeslot = {
      day: weekday
    };

    sortedHoursInterval.forEach((interval, hour) => {
      if (!sortedHoursInterval?.has(hour - 1)) {
        currentTimeslot = {
          ...currentTimeslot,
          startTime: interval.startTime
        };
      }
      if (!sortedHoursInterval?.has(hour + 1)) {
        currentTimeslot = {
          ...currentTimeslot,
          endTime: interval.endTime
        };
        totalTimeslots.push(currentTimeslot);

        currentTimeslot = {
          day: weekday
        };
      }
    });
  });

  const calendarPayload = {
    timeslots: totalTimeslots,
    timezone: userTimezone
  };

  return calendarPayload;
};

const getAssociations = alertState => {
  switch (alertState.type) {
    case ALERT_TYPES.ROUTE:
      return JSON.stringify(alertState.routeTypes || []);
    case ALERT_TYPES.SENTINEL:
      return JSON.stringify(alertState.fatigueViolationsNotif || []);
    case ALERT_TYPES.SPEED:
      return JSON.stringify({
        threshold: getLocalizedDistance(alertState?.localization, alertState?.speedLimit),
        signposted: !!alertState?.signposted?.length
      });
    case ALERT_TYPES.DRIVER_AND_VEHICLE:
      return JSON.stringify({
        alarm_codes: alertState.alarmCodes
      });
    case ALERT_TYPES.TACHO:
      return JSON.stringify(alertState?.tachoCodes || []);
    case ALERT_TYPES.ELD:
      let retVals = { violations: alertState?.eldCodes?.violations || [] };
      if (alertState?.eldAlertWarningsFlag) {
        retVals = {
          violations: alertState?.eldCodes?.violations || [],
          warnings: alertState?.eldCodes?.warnings || []
        };
      }
      return JSON.stringify(retVals);

    case ALERT_TYPES.PRETRIP:
      return alertState.isDVIRServiceEnabled
        ? JSON.stringify({
            dvir_failed: alertState?.inspectionTypes?.dvir_failed || false,
            dvir_passed: alertState?.inspectionTypes?.dvir_passed || false,
            dvir_repaired: alertState?.inspectionTypes?.dvir_repaired || false
          })
        : {};
    case ALERT_TYPES.SMARTJOBS:
      return JSON.stringify({
        [`job.completed`]: alertState?.smartjobTypes?.completed,
        [`job.partially_completed`]: alertState?.smartjobTypes?.partially_completed,
        [`job.non_delivered`]: alertState?.smartjobTypes?.non_delivered,
        [`job.driver_feedback`]: alertState?.inputNumberValue
      });
    /*  case ALERT_TYPES.DOCUMENTEXPIRATION:
      return JSON.stringify({
        folders: alertState.folders,
        daystoexpire: alertState.daystoexpire
      }); */
    case ALERT_TYPES.GEOFENCE:
      return JSON.stringify({
        thrsh_speed: getLocalizedDistance(alertState?.localization, alertState?.speedLimit),
        entry: alertState?.geofencesTypes?.entry,
        exit: alertState?.geofencesTypes?.exit,
        thrsh_undertime: alertState?.geofencesTypes?.thrsh_undertime,
        thrsh_overtime: alertState?.geofencesTypes?.thrsh_overtime,
        managed_entry: alertState?.managedGeofencesTypes?.entry,
        managed_exit: alertState?.managedGeofencesTypes?.exit
      });
    default:
      return {};
  }
};

const getExtras = alertState => {
  switch (alertState.type) {
    case ALERT_TYPES.ENGINE:
      return JSON.stringify({ event_types: alertState.eventTypes });
    case ALERT_TYPES.DRIVER_AND_VEHICLE:
      return JSON.stringify({
        alarm_codes: alertState.alarmCodes,
        event_types: alertState.eventTypes
      });
    case ALERT_TYPES.GPIO:
      return JSON.stringify({ gpio_types: alertState.selectedGpioTypes });
    case ALERT_TYPES.OOH:
      return JSON.stringify({
        io: {
          enabled: alertState?.eventTypes?.some(val => val === 'io')
        },
        movement: {
          enabled: alertState?.eventTypes?.some(val => val === 'movement')
        },
        geofence: {
          enabled: alertState?.eventTypes?.some(val => val === 'geofence')
        }
      });
    case ALERT_TYPES.DURESS:
      return JSON.stringify({ duress_types: alertState.selectedDuressTypes });
    case ALERT_TYPES.SMARTJOBS:
      return JSON.stringify({
        [`job.completed`]: alertState?.smartjobTypes?.completed,
        [`job.partially_completed`]: alertState?.smartjobTypes?.partially_completed,
        [`job.non_delivered`]: alertState?.smartjobTypes?.non_delivered,
        [`job.driver_feedback`]: alertState?.inputNumberValue
      });
    case ALERT_TYPES.DOCUMENTEXPIRATION:
      return JSON.stringify({
        folders: alertState.selectedDocumentExpirationFolders,
        daystoexpire: alertState.inputNumberValue
      });
    default:
      return '';
  }
};

export const buildRequestPayloadFromState = (alertState, properties, activeStatus) => {
  const { action, id, entityType, currentUser } = properties;

  let multiTieredConfig = [];
  if (alertState.eventTypes) {
    alertState.eventTypes
      .filter(i => eventSupportMultiTiered.includes(i))
      .forEach(et => {
        if (alertState.isSecondAlertEnabled) {
          multiTieredConfig.push({
            level: 2,
            eventType: et,
            userIds:
              entityType === ENTITIES.COMPANY
                ? alertState.selectedSecondAlertUsers
                : [currentUser.id],
            timeout: alertState.secondAlertTimer * 60 * 1000
          });
        }

        if (alertState.isThirdAlertEnabled) {
          multiTieredConfig.push({
            level: 3,
            eventType: et,
            userIds:
              entityType === ENTITIES.COMPANY
                ? alertState.selectedThirdAlertUsers
                : [currentUser.id],
            timeout: alertState.thirdAlertTimer * 60 * 1000
          });
        }
      });
  }

  return {
    active: activeStatus,
    expiry: alertState.expiry,
    name: alertState.name,
    ...(action === 'edit' && { id: alertState?.id }),
    companyId: id,
    ...(action === 'edit' && { entityId: entityType === ENTITIES.COMPANY ? id : alertState?.id }),
    ...(action === 'edit' && { id: alertState?.id }),
    extras: getExtras(alertState),
    updatedAt: new Date(),
    ...(action === 'add' && { createdAt: new Date() }),
    ...(alertState?.type === ALERT_TYPES.FORM && {
      formAlertAssociations: alertState?.selectedForms?.map(form => ({ formId: form }))
    }),
    ...(alertState?.type === ALERT_TYPES.PRETRIP && {
      preTripAlertAssociations: alertState?.selectedPretrips?.map(pretrip => ({
        checklistId: pretrip
      }))
    }),
    ...(alertState.type === ALERT_TYPES.GEOFENCE && {
      geofenceAlertAssociations: [
        ...(alertState?.selectedGeofences || []),
        ...(alertState?.selectedManagedGeofences || [])
      ]?.map(geofence => ({
        geofenceId: geofence
      }))
    }),
    ...(alertState.type === ALERT_TYPES.OOH && {
      calendar: getCalendarPayload(alertState.calendar, alertState.timezone)
    }),
    ...(alertState.type === ALERT_TYPES.DOCUMENTEXPIRATION && {
      associations: [
        {
          onscreen: alertState?.desktop || false,
          email: alertState?.mail || false,
          sms: alertState?.phone || false,
          popup: false,
          options: {}
        }
      ]
    }),
    ...(usesFleetsAndVehicles(alertState.type) && {
      associations: (alertState?.selectedVehicles || [])
        .map(vehicle => ({
          onscreen: alertState?.desktop || false,
          email: alertState?.mail || false,
          sms: alertState?.phone || false,
          popup: false,
          options: getAssociations(alertState),
          vehicleId: vehicle
        }))
        .concat(
          (alertState?.selectedDevices || []).map(device => ({
            onscreen: alertState?.desktop || false,
            email: alertState?.mail || false,
            sms: alertState?.phone || false,
            popup: false,
            options: getAssociations(alertState),
            deviceId: device
          }))
        )
        .concat(
          (alertState?.selectedFleets || []).map(fleet => ({
            onscreen: alertState?.desktop || false,
            email: alertState?.mail || false,
            sms: alertState?.phone || false,
            popup: false,
            options: getAssociations(alertState),
            fleetId: fleet
          }))
        )
    }),
    ...(usesDriversAndBranches(alertState.type) && {
      associations: (alertState?.selectedDrivers || [])
        .map(driver => ({
          onscreen: alertState?.desktop || false,
          email: alertState?.mail || false,
          sms: alertState?.phone || false,
          popup: false,
          options: getAssociations(alertState),
          userId: driver
        }))
        .concat(
          (alertState?.selectedBranches || []).map(branch => ({
            onscreen: alertState?.desktop || false,
            email: alertState?.mail || false,
            sms: alertState?.phone || false,
            popup: false,
            options: getAssociations(alertState),
            locationId: branch
          }))
        )
    }),
    notificationTypes: {
      onscreen: alertState.desktop,
      email: alertState.mail,
      sms: alertState.phone
    },
    type: alertState.type,
    userIds: alertState.selectedUsers,
    multiTieredConfig: multiTieredConfig,
    assignedGroups: action === 'edit' ? `${alertState?.selectedFleets?.length} fleets` : ' - ',
    assignedIndividuals:
      action === 'edit'
        ? `${(alertState?.selectedVehicles?.length || 0) +
            (alertState?.selectedDevices?.length || 0)} vehicles`
        : ' - ',
    ...(entityType !== ENTITIES.USER && {
      subscribedUsers: action === 'edit' ? `${alertState?.selectedUsers?.length} users` : ' - '
    }),
    entityType: entityType,
    userId: currentUser.id
  };
};
