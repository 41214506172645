import React from 'react';
import { useSelector } from 'react-redux';

import { LoadingTable } from 'components/grid/LoadingTable';
import { Column, Table, AutoSizer } from 'react-virtualized';
import {
  cache,
  detailsCellRenderer,
  branchCellRenderer,
  fullNameCellRenderer,
  ellipsisButtonCellRenderer,
  licenceCellRenderer,
  tachoCardCellRender,
  tachoCellCache
} from './CellRenderers';
import { useBranches } from 'features/locations/locationsSlice';
import { useTranslation } from 'react-i18next';
import { useCan, entities, useCanOneOfRoles, GlobalRoles } from 'features/permissions';
import { Empty } from 'components/ant';

export const UsersTable = ({
  users,
  companies,
  isLoading,
  typeOfEntityToDelete,
  deleteUser,
  setToastMessage,
  setTableRef,
  filterTab,
  handleAction,
  handleDeleteAction,
  showTachoColumn,
  tachoData,
  localization,
  handleLogInAsUser
}) => {
  const { t } = useTranslation();
  const loggedInUserInfo = useSelector(state => state.user.currentUserInfo);
  const hasSiteAdminOrResellerRole = useCanOneOfRoles([
    GlobalRoles.Reseller,
    GlobalRoles.SiteAdmin
  ]);
  const branches = useBranches();
  const getUserBranchById = id => branches.find(branch => branch.id === id);
  const can = useCan();
  const canViewUser = can && can({ everyEntity: [entities.USER_VIEW] });

  if (isLoading) return <LoadingTable nrOfRows={10} columnSizes={[110, 57, 57, 57, 94, 19]} />;
  const cellCache = showTachoColumn ? tachoCellCache : cache;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 330,
          details: 300,
          licence: 200,
          branch: 250,
          ellipsisButton: 60,
          tachoCard: 300
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.name +
            fixedColumnsWidth.details +
            fixedColumnsWidth.licence +
            fixedColumnsWidth.ellipsisButton +
            (showTachoColumn ? fixedColumnsWidth.tachoCard : 0));

        const columnStyle = {
          // padding: "12px 12px 12px 0"
        };

        const hideEdit = filterTab === 'deleted';
        const hideDelete = filterTab === 'deleted';
        const enableRestoreAction = filterTab === 'deleted';
        return (
          <Table
            deferredMeasurementCache={cellCache}
            width={width}
            height={height}
            headerHeight={52}
            headerStyle={{ paddingTop: '18px' }}
            rowHeight={cellCache.rowHeight}
            rowCount={users.length}
            rowGetter={({ index }) => users[index]}
            rowStyle={{ alignItems: 'flex-start' }}
            rowClassName="tableRow"
            ref={ref => setTableRef(ref)}
            noRowsRenderer={() => <Empty />}
          >
            <Column
              style={columnStyle}
              label={t('Users.Table.Name')}
              dataKey="firstName"
              width={fixedColumnsWidth.name}
              cellRenderer={props => fullNameCellRenderer({ ...props, canViewUser })}
            />
            <Column
              style={columnStyle}
              label={t('Users.Table.Details')}
              dataKey="companyId"
              width={fixedColumnsWidth.details}
              cellRenderer={props =>
                detailsCellRenderer({
                  ...props,
                  companies: companies,
                  filterTab: filterTab,
                  showTachoColumn: showTachoColumn,
                  localization: localization
                })
              }
              filterTab={filterTab}
            />
            <Column
              style={columnStyle}
              label={t('Users.Table.DriversLicense')}
              dataKey="licenceNumber"
              width={fixedColumnsWidth.licence}
              cellRenderer={licenceCellRenderer}
            />
            <Column
              style={columnStyle}
              label={t('Users.Table.Branch')}
              dataKey=""
              width={dynamicColumnWidth}
              cellRenderer={props => {
                const branch = getUserBranchById(props.rowData?.location?.id);
                return branchCellRenderer(branch);
              }}
            />
            {showTachoColumn && (
              <Column
                label={t('Users.Table.TachoCard')}
                dataKey=""
                width={fixedColumnsWidth.tachoCard}
                cellRenderer={props => tachoCardCellRender({ ...props, tachoData, localization })}
              />
            )}
            <Column
              label={t('Users.Table.Actions')}
              dataKey=""
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.ellipsisButton}
              cellRenderer={props =>
                ellipsisButtonCellRenderer({
                  ...props,
                  typeOfEntityToDelete,
                  deleteUser,
                  setToastMessage,
                  hideEdit,
                  hideDelete,
                  enableRestoreAction,
                  handleAction,
                  handleDeleteAction,
                  loggedInUserInfo,
                  companies,
                  handleLogInAsUser,
                  hasSiteAdminOrResellerRole
                })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
