import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Card, Button, Modal } from 'antd';
import styles from './DriverLog.module.scss';
import { sortBy } from 'lodash';
import {
  useDriverEventsByDate,
  fetchDriverLogHeaderByDriverId,
  useDriverLogHeaderByDate
} from 'features/eld/driverLogSlice.js';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { format } from 'utils/dates';

import { useLocalization } from 'features/localization/localizationSlice';
import { useFleets, useVehicles } from 'features/fleets/fleetsSlice';
import { useTranslation } from 'react-i18next';
import i18next from 'i18nextConfig';
import { fetchCompanyConfig, useCompanyConfig } from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';
import { displayUSTimezone } from 'features/regions/timezones';
import { useRulesets } from 'features/rulesets/rulesetsSlice';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useCan, services, FeatureFlag } from 'features/permissions';

const logHeaderGroup = {
  get DriverInfo() {
    return i18next.t('ELD.Driver Information');
  },
  get VehicleInfo() {
    return i18next.t('ELD.Vehicle Information');
  },
  get CarrierInfo() {
    return i18next.t('ELD.Carrier Information');
  },
  get ELDInfo() {
    return i18next.t('ELD.ELD Information');
  },
  get RecordInfo() {
    return i18next.t('ELD.Record Information');
  },
  get AdditionalInfo() {
    return i18next.t('ELD.Additional Information');
  }
};

const statusCardViewDataMeta = [
  {
    get title() {
      return i18next.t('Common.Driver');
    },
    dataKey: data => data?.driver?.firstName + ' ' + data?.driver?.lastName,
    get group() {
      return logHeaderGroup.DriverInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Driver ID');
    },
    dataKey: data => data?.driver.username,
    get group() {
      return logHeaderGroup.DriverInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Ruleset');
    },
    dataKey: data => data?.ruleset?.desc,
    display: false,
    summary: { order: 4 }
  },
  {
    get title() {
      return i18next.t('ELD.Driver License #');
    },
    dataKey: data => data?.driver.licenceNumber,
    get group() {
      return logHeaderGroup.DriverInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Licence State');
    },
    dataKey: data => data?.driver.licenceState,
    get group() {
      return logHeaderGroup.DriverInfo;
    }
  },
  //no need to display currently{ title: 'Branch', dataKey: 'branch', summary: { order: 2 }, group: logHeaderGroup.DriverInfo },
  {
    get title() {
      return i18next.t('ELD.Exempt Driver');
    },
    dataKey: data => (data?.eldDriver?.exempt ? i18next.t('Common.Yes') : i18next.t('Common.No')),
    get group() {
      return logHeaderGroup.DriverInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Unidentified Driver');
    },
    dataKey: data => (data?.unidentifiedDriver ? i18next.t('Common.Yes') : i18next.t('Common.No')),
    get group() {
      return logHeaderGroup.DriverInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Co-Driver');
    },
    dataKey: 'coDriverFullNames',
    summary: { order: 1 },
    get group() {
      return logHeaderGroup.DriverInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Co-Driver ID');
    },
    dataKey: 'coDriverUserNames',
    get group() {
      return logHeaderGroup.DriverInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Current Location');
    },
    dataKey: 'lastLocation',
    get group() {
      return logHeaderGroup.DriverInfo;
    }
  },

  {
    get title() {
      return i18next.t('ELD.Vehicle Name');
    },
    dataKey: (data, locale, props) =>
      data?.vehicles
        ?.map(
          v =>
            props?.vehicles?.find(vehicle => vehicle.id === v.id)?.name ||
            i18next.t('Common.Unknown')
        )
        .join(',') || i18next.t('ELD.None'),
    get group() {
      return logHeaderGroup.VehicleInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Fleet');
    },
    dataKey: (data, locale, props) => {
      return data?.fleets
        ?.map(
          f =>
            props?.fleets?.find(fleet => fleet.id === f.fleetId)?.name ||
            i18next.t('Common.Unknown')
        )
        .filter(name => name !== i18next.t('Common.Unknown'))
        .join(',');
    },
    get group() {
      return logHeaderGroup.VehicleInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Start Odometer');
    },
    dataKey: (data, locale, props) => {
      if (data.startOdometer == null) return null;
      return locale.convertDistance(data.startOdometer);
    },
    get group() {
      return logHeaderGroup.VehicleInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.End Odometer');
    },
    dataKey: (data, locale, props) => {
      if (data.endOdometer == null) return null;
      return locale.convertDistance(data.endOdometer);
    },
    get group() {
      return logHeaderGroup.VehicleInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Start Engine');
    },
    dataKey: data => {
      return data.startEngineHours?.toFixed(1);
    },
    get group() {
      return logHeaderGroup.VehicleInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.End Engine');
    },
    dataKey: data => {
      return data.endEngineHours?.toFixed(1);
    },
    get group() {
      return logHeaderGroup.VehicleInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.ELD ID');
    },
    dataKey: data => data?.ruleset?.name,
    get group() {
      return logHeaderGroup.ELDInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.ELD Manufacturer');
    },
    dataKey: 'eldManufacturer',
    get group() {
      return logHeaderGroup.ELDInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.ELD Malfunctions');
    },
    dataKey: data => (data?.eldMalfunctions ? i18next.t('Common.Yes') : i18next.t('Common.No')),
    get group() {
      return logHeaderGroup.ELDInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Data Diagnostics');
    },
    dataKey: data => (data?.eldDiagnostics ? i18next.t('Common.Yes') : i18next.t('Common.No')),
    get group() {
      return logHeaderGroup.ELDInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Carrier');
    },
    dataKey: data => {
      if (data?.carriers && Array.isArray(data?.carriers) && data?.carriers.length > 0) {
        return data?.carriers.map(carrier => carrier?.name).join('; ');
      } else {
        return data?.companyConfig?.name || i18next.t('ELD.None');
      }
    },
    get group() {
      return logHeaderGroup.CarrierInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.US DOT #');
    },
    dataKey: data => {
      if (data?.carriers && Array.isArray(data?.carriers) && data?.carriers.length > 0) {
        return data?.carriers.map(carrier => carrier?.officialIdentifer).join('; ');
      } else {
        return data?.companyConfig?.officialIdentifer || i18next.t('ELD.None');
      }
    },
    get group() {
      return logHeaderGroup.CarrierInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Trailer Number');
    },
    dataKey: data => data?.trailerIds || i18next.t('ELD.None'),
    get group() {
      return logHeaderGroup.VehicleInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Manifest');
    },
    dataKey: data => data?.manifests || i18next.t('ELD.None'),
    get group() {
      return logHeaderGroup.CarrierInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Shipper');
    },
    dataKey: data => data?.shippers || i18next.t('ELD.None'),
    get group() {
      return logHeaderGroup.CarrierInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Commodity');
    },
    dataKey: data => data?.commodities || i18next.t('ELD.None'),
    get group() {
      return logHeaderGroup.CarrierInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Time Zone');
    },
    dataKey: data => displayUSTimezone(data?.driver?.timeZone),
    summary: { order: 2 },
    get group() {
      return logHeaderGroup.RecordInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Period Start Time');
    },
    dataKey: (data, locale) =>
      data?.periodStartAt
        ? format(new Date(data.periodStartAt), locale.formats.time.formats.default)
        : i18next.t('ELD.None'),
    get group() {
      return logHeaderGroup.RecordInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Record Date');
    },
    dataKey: (data, locale) =>
      data?.reportStartAt
        ? format(new Date(data.reportStartAt), locale.formats.time.formats.default)
        : i18next.t('ELD.None'),
    get group() {
      return logHeaderGroup.RecordInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Print / Display Date');
    },
    dataKey: (data, locale) => format(new Date(), locale.formats.time.formats.default),
    get group() {
      return logHeaderGroup.RecordInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Signed Logs');
    },
    dataKey: data => (data.signedLogs ? i18next.t('ELD.Signed') : i18next.t('ELD.Not Signed')),
    summary: { order: 5 },
    get group() {
      return logHeaderGroup.RecordInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Comments');
    },
    dataKey: 'comments',
    get group() {
      return logHeaderGroup.AdditionalInfo;
    }
  },
  {
    get title() {
      return i18next.t('ELD.Start of Day');
    },
    dataKey: data =>
      data?.driver?.startOfDay
        ? moment(data?.driver?.startOfDay, 'hh:mm a')?.format('hh:mm A')
        : moment('00:00', 'hh:mm a')?.format('hh:mm A'),
    display: false,
    summary: { order: 3 }
  }
];

const summaryGridStyle = {
  width: '25%',
  padding: '8px 15px',
  minHeight: '56px',
  border: 'none',
  boxShadow: 'none'
};

const summaryGridStyleSOD = {
  width: '20%',
  padding: '8px 15px',
  minHeight: '56px',
  border: 'none',
  boxShadow: 'none'
};
export function DriverLogHeaderSummary({
  company,
  driver,
  logDate,
  eldData,
  refreshData,
  reloadData,
  ...props
}) {
  const [show, setShow] = useState(false);
  const [editExists, setEditExists] = useState(false);
  const { t } = useTranslation();
  const userKey = useUserKey();
  const can = useCan();
  const sodFlag = can({ featureFlag: [FeatureFlag.startOfDay.flag] });
  const canCAN = can({ everyCompanyService: [services.ELDCAN] });
  const startOfDayVisible = sodFlag === true && canCAN === true;

  const driverLogHeader = useDriverLogHeaderByDate(
    company?.id,
    driver?.id,
    moment(logDate || new Date())
      .startOf('day')
      .toDate(),
    moment(logDate || new Date())
      .endOf('day')
      .toDate()
  );

  const rulesets = useRulesets();
  const dispatch = useDispatch();
  const companyConfig = useCompanyConfig();

  useEffect(() => {
    if (company && userKey && !companyConfig) {
      dispatch(fetchCompanyConfig(company, userKey));
    }
  }, [dispatch, company, userKey, companyConfig]);

  const getHeaderInfo = useCallback(() => {
    if (company == null || driver?.id == null || driver?.id === '' || logDate == null) {
      return;
    }

    dispatch(
      fetchDriverLogHeaderByDriverId(
        company,
        driver?.id,
        moment(logDate || new Date())
          .startOf('day')
          .toDate(),
        moment(logDate || new Date())
          .endOf('day')
          .toDate(),
        true
      )
    );
  });

  useEffect(() => {
    if (
      company == null ||
      driver?.id == null ||
      driver?.id === '' ||
      logDate == null ||
      reloadData
    ) {
      return;
    }

    dispatch(
      fetchDriverLogHeaderByDriverId(
        company,
        driver.id,
        moment(logDate || new Date())
          .startOf('day')
          .toDate(),
        moment(logDate || new Date())
          .endOf('day')
          .toDate()
      )
    );
  }, [dispatch, company, driver, logDate, reloadData]);

  const data = useMemo(() => {
    const rulesetName = driverLogHeader?.ruleset || eldData?.ruleset?.name;
    const ruleset = rulesets?.find(r => r.name === rulesetName);
    return Object.assign({}, driverLogHeader, { eld: eldData, companyConfig, ruleset });
  }, [driverLogHeader, eldData, companyConfig, rulesets]);

  const driverEvents = useDriverEventsByDate(
    company.id,
    driver,
    moment(logDate || new Date())
      .startOf('day')
      .toDate(),
    moment(logDate || new Date())
      .endOf('day')
      .toDate(),
    true
  );
  useEffect(() => {
    if (driverEvents) {
      const edits = driverEvents?.filter(
        e =>
          e.status === 'P' &&
          moment(e.timeAt) >
            moment(logDate || new Date())
              .startOf('day')
              .toDate() &&
          moment(e.timeAt) <
            moment(logDate || new Date())
              .endOf('day')
              .toDate()
      );
      setEditExists(edits.length > 0);
    }
  }, [driverEvents, editExists, logDate]);

  if (reloadData) {
    getHeaderInfo();
  }
  return (
    <>
      <Modal
        width={800}
        title={t('ELD.Driver Log Header')}
        className={styles.driverStatusModal}
        footer={null}
        open={show}
        onCancel={() => setShow(false)}
      >
        <DriverLogHeader data={data} />
      </Modal>
      <div className={styles.driverStatusSummaryContainer}>
        <Button
          className={styles.showDetailButton}
          id={BUTTON_IDS.driverLogHeaderShow}
          type="link"
          onClick={() => setShow(true)}
        >
          <i className="tn-i-elipsis" />
        </Button>
        <Card className={styles.driverStatusSummary}>
          {sortBy(
            statusCardViewDataMeta.filter(
              f =>
                f.summary &&
                (Number(f.summary.order) !== 3 ||
                  (Number(f.summary.order) === 3 && startOfDayVisible))
            ),
            ['summary.order']
          ).map((row, idx) => (
            <Card.Grid
              key={idx}
              className={styles.driverStatusCardContent}
              style={startOfDayVisible ? summaryGridStyleSOD : summaryGridStyle}
              hoverable={false}
            >
              <p>{row.title}</p>
              <p>
                {(row?.summary && Number(row?.summary?.order) === 5
                  ? driverLogHeader?.currentDayLogon
                    ? t('ELD.DriverPortal.InRoute')
                    : editExists
                    ? t('ELD.DriverPortal.SuggestedEditsExist')
                    : data?.signedLogs
                    ? moment(data?.signedLogAt).format('l')
                    : t('ELD.Not Signed')
                  : typeof row.dataKey === 'string'
                  ? data?.[row.dataKey]
                  : row.dataKey(data)) || t('ELD.None')}
              </p>
            </Card.Grid>
          ))}
        </Card>
      </div>
    </>
  );
}

export function DriverLogHeader({ data, ...props }) {
  const locale = useLocalization();
  const fleets = useFleets();
  const vehicles = useVehicles();
  const { t } = useTranslation();

  return (
    <Card bordered={false} className={styles.driverStatusCard + ' showScrollbarsOnHover'}>
      {Object.keys(logHeaderGroup).map((key, idx) => {
        if (logHeaderGroup[key].display === false) {
          return <React.Fragment key={key}></React.Fragment>;
        }
        return (
          <React.Fragment key={idx}>
            <Card.Grid className={styles.driverStatusCardTitle} hoverable={false}>
              {logHeaderGroup[key]}
            </Card.Grid>
            {statusCardViewDataMeta
              .filter(f => f.group === logHeaderGroup[key])
              .map((row, iidx) => (
                <Card.Grid key={iidx} className={styles.driverStatusCardContent} hoverable={false}>
                  <span>{row.title}</span>
                  <span>
                    {(typeof row.dataKey === 'string'
                      ? data?.[row.dataKey]
                      : row.dataKey(data, locale, { fleets, vehicles })) || t('ELD.None')}
                  </span>
                </Card.Grid>
              ))}
          </React.Fragment>
        );
      })}
    </Card>
  );
}
