import React, { useState, useEffect } from 'react';
//Hooks
import { useDispatch } from 'react-redux';
import { useRoles, useIsFetching as isRolesFetching } from 'features/roles/rolesSlice';
import { useCompanies, useSubCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useHistory } from 'react-router';
import { useUserInfo } from 'features/user/userSlice';
import useDebounce from 'utils/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
//Methods
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { toLower } from 'lodash';
import { removeRole } from 'features/roles/rolesSlice';
import { isSuperAdmin } from 'utils/permissions';
import { sortBy, trim } from 'lodash';
//Components
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { RolesTable } from './RolesTable';
import { cache } from './CellRenderers';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { Affix, Row, Button } from 'antd';
import { Can, entities } from 'features/permissions';
import { prepareDataForMultiselect } from 'utils/filters';

//styles
import styles from './Roles.module.scss';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { BUTTON_IDS } from 'utils/globalConstants';

export const Roles = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const roles = useRoles();
  const companies = useCompanies();
  const subCompanies = useSubCompanies();
  const history = useHistory();
  //Check that user is super admin
  const currentCompany = useCurrentCompany();
  const currentUser = useUserInfo();
  const canEditGlobalRoles = isSuperAdmin(currentUser, currentCompany);

  const [filteredRoles, setFilteredRoles] = useState([]);
  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(trim(filterText), 300);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [tableRef, setTableRef] = useState(null);

  useEffect(() => {
    dispatch(setPageTitle(t('Roles.Roles')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
  }, [subCompanies, t]);

  useEffect(() => {
    const filteredRoles = roles.filter(role => {
      let validRole = true;

      // Filter by search field
      if (debouncedSearchText) {
        const searchArray = debouncedSearchText.split(' ');
        searchArray.forEach(splitValue => {
          validRole = validRole && toLower(role.name).indexOf(toLower(splitValue)) > -1;
        });
      }
      // Filter by companies
      const checkedCompaniesIds = filterCompanies
        .filter(company => company.checked)
        .map(company => parseInt(company.id, 10));
      validRole =
        validRole &&
        (checkedCompaniesIds.indexOf(parseInt(role.companyId, 10)) > -1 || role.global);

      return validRole;
    });
    const sorterRolesByCompany = sortBy(filteredRoles, [
      role => {
        if (role.global) {
          return 'Global';
        }
        if (role.companyId) {
          const companyRole = companies.find(comp => comp.id === role.companyId);
          return companyRole ? companyRole.name : '';
        }
      }
    ]);
    setFilteredRoles(sorterRolesByCompany);
  }, [roles, filterCompanies, debouncedSearchText, companies]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [filteredRoles, tableRef]);

  const goToAddForm = () => {
    history.push('/settings/roles/newRole');
  };

  const deleteModal = data => {
    const deleteRole = () => {
      dispatch(removeRole(data));
    };

    confirmationModal(
      `${t('Common.DeleteButton')} ${data.name || data.Name || data.username}`,
      `${t('Common.SureDelete')} ${t('Common.role')} ${data.name}?`,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      deleteRole,
      'delete'
    );
  };

  return (
    <div className={styles.mainWrapper}>
      <Affix offsetTop={63}>
        <div>
          <Can everyEntity={[entities.ROLE_CREATE]}>
            <div className={styles.headerActions}>
              <Button type="primary" size="large" id={BUTTON_IDS.rolesAddNew} onClick={goToAddForm}>
                {t('Roles.AddNewRole')}
              </Button>
            </div>
          </Can>
          <div className={styles.filterWrapper}>
            <FilterWrapper>
              <AntSearchbar onFilter={value => setFilterText(value)} />
              <AntMultiselect
                title={
                  filterCompanies?.some(value => !value.checked)
                    ? t('Common.Companies')
                    : t('Common.AllCompanies')
                }
                onFilter={v => setFilterCompanies(v)}
                data={filterCompanies}
              />
            </FilterWrapper>
            <div className={styles.itemsCounter}>
              {`${filteredRoles.length} ${
                filteredRoles.length === 1 ? `${t('Roles.Role')}` : `${t('Roles.Roles')}`
              }`}
            </div>
          </div>
        </div>
      </Affix>
      <Row style={{ height: '100%' }}>
        <RolesTable
          roles={filteredRoles}
          companies={companies}
          isLoading={isRolesFetching()}
          setTableRef={setTableRef}
          deleteModal={deleteModal}
          canEditGlobalRoles={canEditGlobalRoles}
        />
      </Row>
    </div>
  );
};
