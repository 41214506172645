import { useMemo } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import {
  useCompanyGeofenceProviders,
  useCurrentCompanyKey,
  useIsLoadingCurrentCompany
} from 'features/company/companySlice';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18next from 'i18next';
import { canHistoryGoBack } from 'utils/methods';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { fetchLocations } from 'features/locations/locationsSlice';
import { isEqual } from 'lodash';
import { isLatLngValid } from 'utils/geo';

export const GEOFENCES_URL = '/geofences';

const geofences = {
  list: [],
  meta: {
    fullyLoaded: false,
    lastFetched: null,
    isFetching: false,
    isAsyncFetching: false,
    error: null,
    isListEmpty: false,
    companyKey: null
  }
};

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.isAsyncFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.fullyLoaded = false;
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.list = [];
  state.meta.companyKey = action.payload.companyKey;
}

const geofencesSlice = createSlice({
  name: 'geofences',
  initialState: geofences,
  reducers: {
    fetchGeofencesStart(state, { payload }) {
      state.meta.spec = payload.spec;
      state.meta.isFetching = true;
      state.meta.fullyLoaded = false;
      state.meta.isAsyncFetching = payload.loadAsync;
    },
    fetchGeofencesSuccess(state, { payload }) {
      state.list = payload.list.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
      );
      state.meta.fullyLoaded = payload.excludeShape !== undefined && payload.excludeShape === false;
      state.meta.isFetching = false;
      state.meta.isAsyncFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchGeofencesFailure: loadingFailed,
    fetchGeofenceByIdStart(state) {
      state.meta.isFetching = true;
    },
    fetchGeofenceByIdSuccess(state, { payload }) {
      state.list = [...state.list.filter(i => i.id !== payload.data.id), ...[payload.data]];
      state.meta.isFetching = false;
    },
    fetchGeofenceByIdFailure(state, { payload }) {
      state.meta.isFetching = false;
      state.meta.error = payload.err;
    }
  }
});

const {
  fetchGeofencesStart,
  fetchGeofencesSuccess,
  fetchGeofencesFailure,
  fetchGeofenceByIdStart,
  fetchGeofenceByIdSuccess,
  fetchGeofenceByIdFailure
} = geofencesSlice.actions;

export const fetchGeofences = (spec, excludeShape = false, loadAsync = false) => async (
  dispatch,
  getState
) => {
  const authKey = getState().user.current.auth.key;
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies.current.id;
  const urlForGeofences =
    `${GEOFENCES_URL}?direction=DOWN&embed=fleets&pruning=ALL${
      excludeShape ? '&excludeShape=true' : ''
    }&company_id=${companyId}` +
    (spec
      ? '&types=ALL' + (spec.geofenceProviders ? ',' + spec.geofenceProviders.join(',') : '')
      : '');
  if (!authKey || !companyKey || !companyId) {
    return;
  }
  dispatch(fetchGeofencesStart({ spec, loadAsync }));
  try {
    const response = await api.get(urlForGeofences, { authKey });
    const { body } = response;
    dispatch(fetchGeofencesSuccess({ list: body, authKey, companyKey, excludeShape }));
  } catch (err) {
    console.error(err);
    dispatch(fetchGeofencesFailure({ err: err.toString(), companyKey }));
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18next.t(
          'GeofencesFeature.ToastMessages.ServerReturnedError:'
        )} ${err.toString()}`
      })
    );
  }
};

export const fetchGeofenceById = id => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyKey = getState().companies.current.api_key;
  const urlForGeofences = `${GEOFENCES_URL}/${id}?embed=fleets&pruning=ALL`;
  if (!authKey || !companyKey) {
    return;
  }
  dispatch(fetchGeofenceByIdStart());
  try {
    const response = await api.get(urlForGeofences, { authKey });
    const { body } = response;
    dispatch(fetchGeofenceByIdSuccess({ data: body }));
  } catch (err) {
    dispatch(fetchGeofenceByIdFailure({ err: err.toString(), companyKey }));
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18next.t(
          'GeofencesFeature.ToastMessages.ServerReturnedError:'
        )} ${err.toString()}`
      })
    );
  }
};

export const useGeofences = (simplified = false, loadAsync = false) => {
  const dispatch = useDispatch();
  const geofences = useSelector(state => state.geofences.list);
  const isFetching = useIsFetching();
  const isListEmpty = useSelector(state => state.geofences.meta.isListEmpty);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();
  const geofenceProviders = useCompanyGeofenceProviders();
  const isCompanyFetching = useIsLoadingCurrentCompany();
  const lastFetchSpec = useSelector(state => state.geofences.meta.spec);
  const isFetchSpecDiff = !isEqual(lastFetchSpec || {}, { geofenceProviders });
  if (
    !isFetching &&
    !isCompanyFetching &&
    (isCompanyKeyDifferent || (geofences?.length === 0 && !isListEmpty) || isFetchSpecDiff)
  ) {
    dispatch(fetchGeofences({ geofenceProviders }, simplified, loadAsync));
  }

  return geofences;
};

export const useClassifiedGeofences = () => {
  const geofenceProviders = useCompanyGeofenceProviders();
  const data = useGeofences();
  const ret = useMemo(() => {
    const geofences = [],
      managedGeofences = [];
    (data || []).forEach(g => {
      if (geofenceProviders?.some(managedType => managedType === g.type)) {
        managedGeofences.push(g);
      } else {
        geofences.push(g);
      }
    });
    return { geofences, managedGeofences };
  }, [data]);

  return ret;
};

export const useGeoSuggestableGeofences = () => {
  const geofences = useGeofences();

  const suggestableGeofences = useMemo(() => {
    return Object.values(
      (geofences || []).reduce((r, geofence, idx) => {
        const isUserType = geofence.type === 'USER' || geofence.type === 'TRAFFIC';
        if (!isUserType) return r;

        const hasGeoLocation =
          geofence?.centroid?.Lat &&
          geofence?.centroid?.Lng &&
          isLatLngValid(geofence.centroid.Lat, geofence.centroid.Lng);

        if (hasGeoLocation) {
          const samenameGeo = r[geofence.name];
          let label = geofence.name;
          if (samenameGeo) {
            label += '_' + idx;
          }
          r[label] = {
            isGeofence: true,
            label,
            geofenceName: geofence.name,
            geofence,
            location: {
              lat: geofence.centroid.Lat,
              lng: geofence.centroid.Lng
            }
          };
        }
        return r;
      }, {})
    );
  }, [geofences]);
  return suggestableGeofences;
};

export const useIsAsyncFetching = () => useSelector(state => state.geofences.meta.isAsyncFetching);
export const useIsFullyFetched = () => useSelector(state => state.geofences.meta.fullyLoaded);
export const useIsFetching = () => useSelector(state => state.geofences.meta.isFetching);
export const useCompanyKey = () => useSelector(state => state.geofences.meta.companyKey);

const useIsCompanyKeyDifferent = () => useCompanyKey() !== useCurrentCompanyKey();

export const addGeofence = data => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  if (!authKey) {
    return;
  }
  try {
    const postResponse = await api.post(
      GEOFENCES_URL,
      {
        authKey
      },
      data
    );

    if (!postResponse.ok) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('GeofencesFeature.ToastMessages.GeofenceAddedError', {
            name: data?.name
          })
        })
      );
    } else {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('GeofencesFeature.ToastMessages.GeofenceAddedSuccess', {
            name: data?.name
          })
        })
      );

      const { body } = postResponse;
      dispatch(fetchGeofenceById(body.id));
      dispatch(fetchLocations());
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18next.t('GeofencesFeature.ToastMessages.GeofenceAddedError', {
          name: data?.name
        })}: ${err}`
      })
    );
  }
};

export const updateGeofence = data => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const urlForUpdateRole = GEOFENCES_URL;
  if (!authKey) {
    return;
  }
  try {
    const updateResponse = await api.put(
      urlForUpdateRole,
      {
        authKey
      },
      data
    );

    if (!updateResponse.ok) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('GeofencesFeature.ToastMessages.GeofenceUpdatedError', {
            name: data?.name
          })
        })
      );
    } else {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('GeofencesFeature.ToastMessages.GeofenceUpdatedSuccess', {
            name: data?.name
          })
        })
      );
      const { body } = updateResponse;
      dispatch(fetchGeofenceById(body.id));
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18next.t('GeofencesFeature.ToastMessages.GeofenceUpdatedError', {
          name: data?.name
        })}: ${err}`
      })
    );
  }
};

export const deleteGeofenceApi = (data, history, callBack) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;

  Mixpanel.sendTrackEvent(MPTrackingEvents.Settings.Geofences.Update, { typeOfUpdate: 'delete' });
  try {
    const response = await api.delete(`${GEOFENCES_URL}/${data.id}`, { authKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18next.t('GeofencesFeature.ToastMessages.GeofenceDeletedSuccess', {
            name: data?.name
          })
        })
      );

      dispatch(fetchGeofences());
      history && canHistoryGoBack(history, '/settings/geofences');
      callBack && callBack();
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${i18next.t('GeofencesFeature.ToastMessages.GeofenceDeletedError', {
          name: data?.name
        })} ${err}`
      })
    );
  }
};

export default geofencesSlice.reducer;
