import { useMemo } from 'react';
import { useDevicesWithDriversFatigueStatusAndIgnitionStatus } from './driverCardSlice';
import { useVehicleTypes } from 'features/vehicles/vehicleTypesSlice';
import { useSelector } from 'react-redux';

export const useCardViewDevices = (devices, fetchRef) => {
  const vehicleTypes = useVehicleTypes();
  const isFetchingVehicleTypes = useSelector(state => state.vehicleTypes.meta.isFetching.list);

  const {
    devicesWithIgnitionStatusAndFatigueStatus,
    isLoadingStatus
  } = useDevicesWithDriversFatigueStatusAndIgnitionStatus(devices, fetchRef);
  const cardViewDevices = useMemo(
    () =>
      devicesWithIgnitionStatusAndFatigueStatus?.map(d => {
        const vehicleType =
          d.vehicle?.type?.id &&
          vehicleTypes.find(vt => String(vt.id) === String(d.vehicle.type.id));
        return {
          ...d,
          vehicleType
        };
      }),
    [devicesWithIgnitionStatusAndFatigueStatus, vehicleTypes]
  );

  const isFetching = useMemo(() => isLoadingStatus || isFetchingVehicleTypes, [
    isLoadingStatus,
    isFetchingVehicleTypes
  ]);

  return { cardViewDevices, isFetching };
};

export const useCompanyCardViewSettings = (userPreferences, company) => {
  return useMemo(() => {
    if (!userPreferences || !company) {
      return;
    }
    //get pinnedCards
    let companyPinnedCards = {};
    let pinnedCardsInUserPrefs = [];
    try {
      pinnedCardsInUserPrefs =
        userPreferences?.cardViewPinnedCards &&
        userPreferences?.cardViewPinnedCards[company.id] &&
        JSON.parse(userPreferences?.cardViewPinnedCards[company.id]);
    } catch (error) {
      pinnedCardsInUserPrefs = [];
    }
    //Check validPinnedCards in UserPrefs below, otherwise may contain [null,null,..]
    pinnedCardsInUserPrefs =
      Array.isArray(pinnedCardsInUserPrefs) &&
      pinnedCardsInUserPrefs.filter(pinnedCard => !!pinnedCard);
    if (pinnedCardsInUserPrefs && pinnedCardsInUserPrefs.length) {
      //companyPinnedCards should be an Object
      pinnedCardsInUserPrefs.forEach(pinnedCard => {
        companyPinnedCards[pinnedCard.deviceId] = {
          deviceId: pinnedCard.deviceId,
          cardType: pinnedCard.cardType
        };
      });
    }
    //get individual card settings
    let companyCardSettings = {};
    try {
      companyCardSettings =
        (userPreferences?.cardViewSettings &&
          userPreferences?.cardViewSettings[company.id] &&
          JSON.parse(userPreferences?.cardViewSettings[company.id])) ||
        {};
    } catch (error) {
      companyCardSettings = {};
    }
    return { companyPinnedCards, companyCardSettings };
  }, [userPreferences, company]);
};
