import { asyncThunk } from 'utils/api';
import {
  FETCH_ALERTS,
  CLEAR_ALERTS,
  ADD_ALERT,
  ADD_ALERT_FORM,
  ADD_ALERT_PRETRIP,
  ADD_ALERT_GEOFENCE,
  EDIT_ALERT,
  DELETE_ALERT,
  FETCH_GPIO_TYPES
} from './actions';

const GET_ALERTS_URL = '/alert';
const POST_ALERTS_URL = '/alert/config';
const POST_ALERTS_FORMS_URL = '/alert/config/form';
const POST_ALERTS_PRETRIP_URL = '/alert/config/pretripchecklist';
const POST_ALERTS_GEOFENCES_URL = '/alert/config/geofence';
const GET_GPIO_TYPES_URL = '/alert/gpiotypes';

export const clearAlerts = asyncThunk(CLEAR_ALERTS, GET_ALERTS_URL);
export const fetchAlerts = asyncThunk(FETCH_ALERTS, GET_ALERTS_URL);
export const addAlert = asyncThunk(ADD_ALERT, POST_ALERTS_URL, 'post');
export const editAlert = asyncThunk(EDIT_ALERT, POST_ALERTS_URL, 'post');
export const addAlertForms = asyncThunk(ADD_ALERT_FORM, POST_ALERTS_FORMS_URL, 'post');
export const addAlertPretrips = asyncThunk(ADD_ALERT_PRETRIP, POST_ALERTS_PRETRIP_URL, 'post');
export const addAlertGeofence = asyncThunk(ADD_ALERT_GEOFENCE, POST_ALERTS_GEOFENCES_URL, 'post');
export const deleteAlertById = asyncThunk(DELETE_ALERT, GET_ALERTS_URL, 'delete');
export const fetchGpioTypes = asyncThunk(FETCH_GPIO_TYPES, GET_GPIO_TYPES_URL);
