import React, { useEffect, useCallback, useState } from 'react';
import { Button } from 'antd';
import styles from './ExpandButton.module.scss';
import doubleArrowDown from 'static/images/icons/doublearrow_down.svg';
import doubleArrowUp from 'static/images/icons/doublearrow_up.svg';
import { useMemo } from 'react';
import { BUTTON_IDS } from 'utils/globalConstants';

export const IconStyle = Object.freeze({
  SignleArrow: 0,
  DoubleArrow: 1
});

export const ButtonStyle = Object.freeze({
  text: 'text',
  default: 'default'
});

export function ExpandButton({
  defaultExpand,
  onExpand,
  buttonStyle = ButtonStyle.default,
  iconStyle,
  disabled = false,
  ...props
}) {
  const [expand, setExpand] = useState(defaultExpand);
  const handleExpand = useCallback(() => {
    setExpand(!expand);
    if (onExpand) {
      onExpand(!expand);
    }
  }, [expand, onExpand]);

  useEffect(() => {
    setExpand(defaultExpand);
  }, [defaultExpand]);

  const icon = useMemo(() => {
    if (iconStyle === IconStyle.SignleArrow) {
      return <i className={expand ? 'tn-i-chevron-up' : 'tn-i-chevron-down'} />;
    } else {
      return <i style={{ content: `url(${expand ? doubleArrowUp : doubleArrowDown})` }} />;
    }
  }, [expand, iconStyle]);

  return (
    <Button
      className={styles.expandBtn}
      id={BUTTON_IDS.expand}
      type={buttonStyle}
      onClick={handleExpand}
      disabled={disabled}
    >
      <span>{icon}</span>
    </Button>
  );
}
