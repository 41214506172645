import { Form, InputNumber } from 'antd';
import React from 'react';

export const FormInputNumber = ({ properties, onChange, setIsDirty }) => {
  const { labelCol, name, wrapperCol, label, max, precision } = properties;

  const handleOnChange = e => {
    if (typeof setIsDirty === 'function') {
      setIsDirty(true);
    }
    onChange(e);
  };

  return (
    <Form.Item
      labelCol={labelCol}
      name={name}
      wrapperCol={wrapperCol}
      label={label}
      max={max || 1000}
    >
      <InputNumber
        min={0}
        max={max}
        precision={precision}
        onChange={handleOnChange}
        style={{ width: '100%' }}
      />
    </Form.Item>
  );
};
