import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchDevices, useDevices } from 'features/devices/devicesSlice';
import { fetchDeviceDetails, useEasydocsDeviceDetails } from 'features/easydocs/devicesSlice';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { ExportToExcel, ExportType } from '../ExportToExcel';
import { Space } from 'antd';
import { formatBytes } from 'utils/methods';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';
import { useCurrentCompany } from 'features/company/companySlice';
import { DetailsToolbar } from './DevicesToolbar';
import { DetailsTable } from './DevicesTable';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';

export const DeviceDetails = ({ setTableRef }) => {
  const { t } = useTranslation();
  const localization = useLocalization();

  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const deviceId = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );
  const company = useCurrentCompany();
  const devices = useDevices();
  const device = Object.values(devices)?.find(d => d.id.toString() === deviceId);
  const documents = useEasydocsDeviceDetails();
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const dateFormat = localization.formats.time.formats.dby_imsp;
  const [documentFilter, setdocumentFilter] = useState([
    {
      label: t('Easydocs.AllDocuments'),
      id: 0,
      checked: true
    },
    {
      label: t('Easydocs.NotReceived'),
      id: 1,
      checked: true
    },
    {
      label: t('Easydocs.NotRead'),
      id: 2,
      checked: true
    }
  ]);

  const dispatch = useDispatch();

  if (device && parseInt(device.id) === parseInt(deviceId)) {
    dispatch(setPageTitle(t('Easydocs.DocumentsForDevice', { deviceName: device.name })));
    dispatch(setBackButton(true));
  }

  useEffect(() => {
    dispatch(fetchDevices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDeviceDetails(deviceId));
  }, [dispatch, deviceId]);

  const handleSearchChange = useCallback(searchText => {
    setSearchText(searchText);
  }, []);

  const handleDocumentFilterChange = useCallback(filters => {
    setdocumentFilter(filters);
  }, []);

  const dateConverter = date => {
    return format(new Date(date), dateFormat);
  };

  // useEffect for applying the filters
  useEffect(() => {
    if (documents) {
      let newFilteredDocuments = [];

      documents.forEach(row => {
        let matchSearch = true;

        // filter by search text for name,description,folder,driver names
        if (matchSearch && searchText && searchText.trim()) {
          const searchTextLowerCase = searchText.trim().toLowerCase();
          matchSearch =
            row.name?.toLowerCase().indexOf(searchTextLowerCase) >= 0 ||
            row.description?.toLowerCase().indexOf(searchTextLowerCase) >= 0 ||
            row.folders.some(
              folder => folder.name?.toLowerCase().indexOf(searchTextLowerCase) >= 0
            ) ||
            formatBytes(row.filesize)
              ?.toLowerCase()
              .indexOf(searchTextLowerCase) >= 0 ||
            String(row.acknowledge)
              ?.toLowerCase()
              .includes(searchTextLowerCase) ||
            dateConverter(row.uploadedAt)
              ?.toLowerCase()
              .indexOf(searchTextLowerCase) >= 0 ||
            dateConverter(row.receivedAt)
              ?.toLowerCase()
              .indexOf(searchTextLowerCase) >= 0 ||
            dateConverter(row.expiredAt)
              ?.toLowerCase()
              .indexOf(searchTextLowerCase) >= 0 ||
            dateConverter(row.removedAt)
              ?.toLowerCase()
              .indexOf(searchTextLowerCase) >= 0 ||
            dateConverter(row.lastReadAt)
              ?.toLowerCase()
              .indexOf(searchTextLowerCase) >= 0 ||
            (
              row.driver?.firstName?.toLowerCase() +
              ' ' +
              row.driver?.lastName?.toLowerCase()
            ).includes(searchTextLowerCase);
        }

        // filter by document type
        if (matchSearch && documentFilter && !documentFilter[0].checked) {
          matchSearch = documentFilter.some(
            f =>
              f.checked &&
              ((f.label === t('Easydocs.NotReceived') && !row.receivedAt) ||
                (f.label === t('Easydocs.NotRead') && row.receivedAt && !row.lastReadAt))
          );
        }

        if (matchSearch) {
          newFilteredDocuments.push(row);
        }
      });

      setFilteredDocuments(newFilteredDocuments);
    }
  }, [documents, searchText, documentFilter]);

  return (
    <div>
      <HeaderPageWrapper>
        <Space size={16} style={{ marginLeft: 'auto' }}>
          <ExportToExcel
            data={documents}
            company={company}
            localization={localization}
            exportType={ExportType.DeviceDetails}
            devices={[{ name: device?.name }]}
            folders={[]}
            fleets={[]}
            vehicles={[]}
            subCompanies={[]}
          />
        </Space>
      </HeaderPageWrapper>
      <div>
        <DetailsToolbar
          filteredDocumentsCount={filteredDocuments?.length}
          searchText={searchText}
          onSearchChange={handleSearchChange}
          documentFilter={documentFilter}
          onDocumentFilterChange={handleDocumentFilterChange}
        />

        <div style={{ height: 'calc(100vh - 230px)' }}>
          <DetailsTable documents={filteredDocuments} setTableRef={setTableRef} />
        </div>
      </div>
    </div>
  );
};
