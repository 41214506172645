import { createSlice, unwrapResult } from '@reduxjs/toolkit';

import { fetchInspection, fetchInspections } from './thunks';

import { GROUP_BY } from 'containers/VehicleMaintenance/Inspections/utils/constants';

const initialState = {
  list: [],
  currentInspection: {
    meta: {
      isFetching: false,
      error: false,
      success: false
    },
    data: {}
  },
  meta: {
    isFetching: false,
    error: null,
    success: false
  },
  filters: {
    companies: null,
    fleets: null,
    types: null,
    groupBy: GROUP_BY.inspections,
    rowFilter: null,
    calendarDate: null
  }
};

export const vehicleMaintenanceInspectionsSlice = createSlice({
  name: 'inspections',
  initialState,
  reducers: {
    updateFilters(state, { payload }) {
      state.filters[payload.filterType] = payload.filterValue;
    }
  },
  extraReducers: {
    [fetchInspections.pending]: state => {
      state.meta.isFetching = true;
      state.meta.success = false;
      state.meta.error = false;
    },
    [fetchInspections.fulfilled]: (state, action) => {
      state.meta.isFetching = false;
      state.meta.success = true;
      state.meta.error = false;
      state.list = unwrapResult(action);
    },
    [fetchInspections.rejected]: state => {
      state.meta.isFetching = false;
      state.meta.success = false;
      state.meta.error = true;
    },
    [fetchInspection.pending]: state => {
      state.currentInspection.meta.isFetching = true;
      state.currentInspection.meta.success = false;
      state.currentInspection.meta.error = false;
    },
    [fetchInspection.fulfilled]: (state, action) => {
      state.currentInspection.meta.isFetching = false;
      state.currentInspection.meta.success = true;
      state.currentInspection.meta.error = false;
      state.currentInspection.data = unwrapResult(action);
    },
    [fetchInspection.rejected]: state => {
      state.currentInspection.meta.isFetching = false;
      state.currentInspection.meta.success = false;
      state.currentInspection.meta.error = true;
      state.currentInspection.data = {};
    }
  }
});

export const { updateFilters } = vehicleMaintenanceInspectionsSlice.actions;
