import React from 'react';
import { Menu, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

const ContextMenu = ({ menuItems, onItemClick }) => (
  <Menu
    style={{
      minWidth: '150px'
    }}
    items={menuItems}
    onClick={e => {
      e.domEvent.preventDefault();
      e.domEvent.stopPropagation();
      onItemClick && onItemClick(e);
    }}
  />
);

export const ActionsMenu = ({ menuItems, onItemClick, ...props }) => {
  return (
    <Dropdown
      overlay={<ContextMenu menuItems={menuItems} onItemClick={onItemClick} />}
      placement="bottomLeft"
      trigger={['click']}
      {...props}
    >
      <MoreOutlined
        key="actions"
        disabled
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </Dropdown>
  );
};
