import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import React from 'react';
import { prepareAuthHeaders } from 'services/util';
import { useMemo } from 'react';
import { API_PATH } from 'config';
import { useCurrentCompany } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';
import { parseErrorMessage } from 'utils/strings';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Trans } from 'react-i18next';
import i18n from 'i18nextConfig';
import GlobalRoles from 'features/permissions/GlobalRoles';
import { useCan } from 'features/permissions';
import services from 'features/permissions/services';

export const ngGpioConfigurationApi = createApi({
  reducerPath: 'ngGpioConfigurationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  tagTypes: ['gpioConfigurationTemplate'],
  endpoints: builder => ({
    getGpioConfigurationTemplateById: builder.query({
      query: ({ templateId, embed }) => {
        return {
          url: `configuration/GPIO/template/${templateId}`,
          params: {
            embed: embed
          }
        };
      },
      async onQueryStarted(query, { dispatch, queryFulfilled, getState }) {
        try {
          const { data: updatedData } = await queryFulfilled;
          dispatch(
            ngGpioConfigurationApi.util.updateQueryData(
              'getGpioConfigurationTemplateById',
              query,
              cache => {
                cache.dataFormatted = true;
                cache.configurationTemplate.configurations?.forEach(c => {
                  c.jsonValue = JSON.parse(c.value);
                });
              }
            )
          );
        } catch {}
      },
      providesTags: ['gpioConfigurationTemplate']
    }),
    getGpioConfigurationTemplates: builder.query({
      query: ({ companyId, embed }) => ({
        url: `configuration/GPIO/template`,
        params: {
          company_id: companyId,
          embed: embed
        }
      }),
      providesTags: ['gpioConfigurationTemplate']
    }),
    addGpioConfigurationTemplate: builder.mutation({
      query: ({ body }) => ({
        url: `configuration/GPIO/template`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['gpioConfigurationTemplate']
    }),
    updateGpioConfigurationTemplate: builder.mutation({
      query: ({ id, body }) => ({
        url: `configuration/GPIO/template/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['gpioConfigurationTemplate']
    }),
    copyGpioConfigurationTemplate: builder.mutation({
      query: payload => {
        const { id, companyIds } = payload;
        let url = '/configuration/GPIO/template/' + id + '/copy';

        return {
          url,
          method: 'POST',
          body: { targetCompanyIds: companyIds },
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        };
      },
      invalidatesTags: ['gpioConfigurationTemplate']
    }),
    deleteGpioConfigurationTemplateById: builder.mutation({
      query: payload => {
        const { id } = payload;

        return {
          url: `configuration/GPIO/template/${id}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: (result, error, arg) => {
        return error ? [] : ['gpioConfigurationTemplate'];
      }
    }),
    restoreGpioConfigurationTemplateById: builder.mutation({
      query: payload => {
        const { id } = payload;

        return {
          url: `configuration/GPIO/template/${id}/restore`,
          method: 'PUT'
        };
      },
      invalidatesTags: ['gpioConfigurationTemplate']
    })
  })
});

export const {
  useGetGpioConfigurationTemplatesQuery,
  useGetGpioConfigurationTemplateByIdQuery,
  useAddGpioConfigurationTemplateMutation,
  useUpdateGpioConfigurationTemplateMutation,
  useCopyGpioConfigurationTemplateMutation,
  useRestoreGpioConfigurationTemplateByIdMutation,
  useDeleteGpioConfigurationTemplateByIdMutation
} = ngGpioConfigurationApi;

export const useGpioConfigurations = (getActiveOnly = true) => {
  const currentCompany = useCurrentCompany();
  const can = useCan();
  const canControlGpioConfiguration = can({
    oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin],
    oneOfCompanyServices: [services.GPIO]
  });
  const { data: gpioConfigurations, isFetching } = useGetGpioConfigurationTemplatesQuery(
    { companyId: currentCompany?.id },
    { skip: currentCompany?.id === undefined || !canControlGpioConfiguration }
  );

  return useMemo(() => {
    let configurationTemplates = (gpioConfigurations || []).map(i => i.configurationTemplate);

    if (getActiveOnly) {
      configurationTemplates = configurationTemplates.filter(i => i.status === 'ENABLED');
    }

    return { data: configurationTemplates, isFetching };
  }, [currentCompany?.id, isFetching]);
};

export const useCurrentCompanyServicesMap = () => {
  const currentCompany = useCurrentCompany();

  return useMemo(() => {
    const serviceMap = currentCompany.features.reduce((map, service) => {
      map[service.code] = service;
      return map;
    }, {});
    return serviceMap;
  }, [currentCompany?.id]);
};

export const executeCopyGpioConfigurationTemplate = (
  payload,
  copyGpioConfigurationTemplate,
  companies,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    copyGpioConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        const companyIds = data.companyIds;
        const companyName = companies
          .filter(i => companyIds.find(k => k === i.id))
          .map(i => i.name)
          .join(', ');

        if (data.totalError !== 0) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: data?.errors
                ? data?.errors.map((i, index) => {
                    const errorCompany = companies.find(k => k.id === i.companyId);
                    return (
                      <React.Fragment key={index}>
                        {errorCompany.name + ' : ' + i.message}
                        {<br />}
                      </React.Fragment>
                    );
                  })
                : parseErrorMessage(data.data)
            })
          );
          reject(data);
        } else {
          handleSuccessResult(
            dispatch,
            <Trans
              values={{ companies: companyName }}
              i18nKey={
                'CompanyConfig.DeviceConfigurations.GPIOCopyModal.GpioTemplateClonedResponseMessage'
              }
              components={{
                1: <span style={{ color: '#003a8c' }} />
              }}
            />,
            resolve
          );
        }
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeRestoreGpioConfigurationTemplate = (
  payload,
  restoreGpioConfigurationTemplateById,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    restoreGpioConfigurationTemplateById(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ConfigurationRestored', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeDeleteGpioConfigurationTemplate = (
  payload,
  deleteGpioConfigurationTemplate,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    deleteGpioConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ConfigurationDeleted', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeSetCompanyGpioConfigurationTemplate = (
  payload,
  updateGpioConfigurationTemplate,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    updateGpioConfigurationTemplate(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          dispatch,
          i18n.t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success', {
            name: payload.name
          }),
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

const handleException = (dispatch, error, reject) => {
  if (error) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseErrorMessage(error.data)
      })
    );
  }

  if (reject) {
    reject(error);
  }
};

const handleSuccessResult = (dispatch, msg, resolve) => {
  dispatch(
    openToast({
      type: ToastType.Success,
      message: msg
    })
  );

  if (resolve) {
    resolve();
  }
};
