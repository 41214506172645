import React from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { EllipsisButton } from 'components/tables/EllipsisButton';
import { useRegions } from 'features/regions/regionsSlice';

export const nameCellRenderer = ({ rowData, rowIndex, dataKey, parent, canView }) => {
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${rowData.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      {canView ? (
        <Link to={`/settings/subcompanies/id/${rowData.id}`}>{rowData.name}</Link>
      ) : (
        <span>{rowData.name}</span>
      )}
    </CellMeasurer>
  );
};

export const countryCellRenderer = ({ rowData, rowIndex, dataKey, parent, regions }) => {
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${rowData.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <span>{regions.find(i => i.code === rowData.country)?.name}</span>
    </CellMeasurer>
  );
};

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const ellipsisButtonCellRenderer = ({
  dataKey,
  rowData,
  typeOfEntityToDelete,
  hideEdit,
  hideDelete,
  enableRestoreAction,
  handleAction,
  handleDeleteAction
}) => {
  return (
    <EllipsisButton
      dataKey={dataKey}
      typeOfEntityToDelete={typeOfEntityToDelete}
      path={'/settings/subcompanies/'}
      data={rowData}
      hideEdit={hideEdit}
      hideDelete={hideDelete}
      canUse="SUBCOMPANY"
      enableRestoreAction={enableRestoreAction}
      handleAction={handleAction}
      handleDeleteAction={handleDeleteAction}
    />
  );
};
