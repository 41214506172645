import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnKeys, KEY_MAPPINGS, ActionMenu, CODES_TABLE_COLUMNS, CODE_PREFIX } from './';

export const prepareCodesDataForTable = (
  data = [],
  searchQuery = '',
  translate,
  canViewSmartJobsCode
) => {
  return data
    .filter(c => (c.name || '').toLowerCase().includes(searchQuery.toLowerCase()))
    .map(code => ({
      key: code.id,
      [ColumnKeys.NAME]: canViewSmartJobsCode ? (
        <Link
          to={{
            pathname: `/settings/smartJobs/codes/id/${code.id}`,
            state: { code }
          }}
        >
          {trimCodeName(code[ColumnKeys.NAME])}
        </Link>
      ) : (
        <span>{trimCodeName(code[ColumnKeys.NAME])}</span>
      ),
      [ColumnKeys.COMPANY_NAME]: code[ColumnKeys.COMPANY_NAME],
      [ColumnKeys.KEY]: KEY_MAPPINGS[code[ColumnKeys.KEY]],
      [ColumnKeys.VALUE_COUNT]: (code[ColumnKeys.VALUE_COUNT] || []).length,
      [ColumnKeys.ACTIONS]: (
        <ActionMenu code={code} typeOfEntityToDelete={translate('Common.smartjobCode')} />
      )
    }));
};

export const prepareCodeTypesForSelect = t => {
  return Object.keys(KEY_MAPPINGS).map(codeKey => ({
    id: codeKey,
    label: t(`SmartJobsCodes.Codes.${KEY_MAPPINGS[codeKey]}`)
  }));
};

export const prepareColumnsForTable = translate =>
  CODES_TABLE_COLUMNS.map(column => ({
    ...column,
    title: translate(`SmartJobsCodes.${column.key}`)
  }));

const trimCodeName = code => {
  if (!code) {
    return '';
  }

  return code.replace(CODE_PREFIX, '');
};
