import { ColumnKeys } from './constants';
import { format } from 'utils/dates';
import { RedoOutlined, EyeFilled } from '@ant-design/icons';
import { Tag, Button, Tooltip, Spin } from 'antd';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import styles from './Events.module.scss';

export const eventType = event => {
  if (event?.type) {
    const eventTypeArray = event?.type.split('.');
    const eventTypeArrayLength = eventTypeArray.length;

    return `${eventTypeArray[eventTypeArrayLength - 2]} | ${
      eventTypeArray[eventTypeArrayLength - 1]
    }`;
  }
  return '';
};

export const loader = eventByIdLoading => {
  if (eventByIdLoading) {
    return (
      <div className={styles.loader}>
        <Spin />
      </div>
    );
  }
};

export const eventStatus = (event, t, eventByIdLoading) => {
  if (eventByIdLoading) {
    return;
  }
  if (event?.sentAt) {
    return (
      <div className="detailsContainer">
        <Tag className="successful">{t('Integrations.EventsTab.DetailsStatus.Successful')}</Tag>
      </div>
    );
  }
  if (event?.attempts < 3) {
    return (
      <div className="detailsContainer">
        <Tag className="pending">{t('Integrations.EventsTab.DetailsStatus.Pending')}</Tag>
      </div>
    );
  }
  return (
    <div className="detailsContainer">
      <Tag className="failed">{t('Integrations.EventsTab.DetailsStatus.Failed')}</Tag>
    </div>
  );
};

export const eventDetails = (event, localization, t, eventByIdLoading) => {
  if (eventByIdLoading) {
    return;
  }
  // Success or Pending Event
  if (event?.sentAt || event?.attempts < 3) {
    return;
  }
  // Failed Event
  return (
    <div className="detailsContainer">
      <span className="attempts">{`${event?.attempts ? event?.attempts : 0} ${t(
        'Integrations.EventsTab.Attempts'
      )}`}</span>
      <span>{`${t('Integrations.EventsTab.LastFailure')} ${format(
        new Date(event?.lastFailureAt),
        localization.formats.time.formats.dby_imsp
      )}`}</span>
      <span className="lastFailureReason">{event?.lastFailureReason}</span>
    </div>
  );
};

export const eventContent = (event, eventByIdLoading) => {
  if (eventByIdLoading) {
    return;
  }
  return (
    <div className={styles.eventContent}>
      {event?.content && (
        <JSONPretty
          id="json-pretty"
          data={event?.content}
          mainStyle="background: #181c21 !important"
        ></JSONPretty>
      )}
    </div>
  );
};

export const detailsStatusForSorting = (event, t) => {
  if (event?.sentAt) {
    return t('Integrations.EventsTab.DetailsStatus.Successful');
  }
  if (event?.attempts < 3) {
    return t('Integrations.EventsTab.DetailsStatus.Pending');
  }
  return t('Integrations.EventsTab.DetailsStatus.Failed');
};

export const prepareEventsForTable = ({
  events,
  localization,
  t,
  openEventDrawer = () => {},
  resendEventMethod = () => {}
}) => {
  return events?.map(event => {
    return {
      [ColumnKeys.ID]: event.id,
      [ColumnKeys.EVENT_TYPE]: eventType(event),
      [ColumnKeys.CREATED_AT]: event.createdAt
        ? format(new Date(event.createdAt), localization.formats.time.formats.dby_imsp)
        : '',
      [ColumnKeys.SENT_AT]: event.sentAt
        ? format(new Date(event.sentAt), localization.formats.time.formats.dby_imsp)
        : '',
      [ColumnKeys.STATUS]: eventStatus(event, t),
      [ColumnKeys.DETAILS]: eventDetails(event, localization, t),
      createdAt_api: event.createdAt,
      [ColumnKeys.ACTIONS]: (
        <div className="actionsContainer">
          <Tooltip
            placement={'top'}
            title={t('Integrations.EventsTab.ViewDetails')}
            color="#818ea1"
          >
            <Button onClick={() => openEventDrawer(event)}>
              <EyeFilled />
            </Button>
          </Tooltip>
          {!event?.sentAt && event?.attempts > 2 && (
            <Tooltip
              placement={'top'}
              title={t('Integrations.EventsTab.ResendEvent')}
              color="#818ea1"
            >
              <Button onClick={() => resendEventMethod(event)}>
                <RedoOutlined />
              </Button>
            </Tooltip>
          )}
        </div>
      ),
      key: event.id
    };
  });
};

export const statuses = {
  ALL: 'all',
  SUCCESS: 'success',
  PENDING: 'pending',
  FAILED: 'failed'
};

export const filterByStatusOptions = t => [
  { value: statuses.ALL, label: t('Integrations.EventsTab.All') },
  { value: statuses.SUCCESS, label: t('Integrations.EventsTab.Success') },
  { value: statuses.PENDING, label: t('Integrations.EventsTab.Pending') },
  { value: statuses.FAILED, label: t('Integrations.EventsTab.Failed') }
];

export const filterEventsByStatus = (events, filterBy) => {
  switch (filterBy) {
    case statuses.SUCCESS:
      return events.filter(e => e.sentAt);
    case statuses.PENDING:
      return events.filter(e => !e.sentAt && e.attempts < 3);
    case statuses.FAILED:
      return events.filter(e => !e.sentAt && e.attempts > 2);
    case statuses.ALL:
    default:
      return events;
  }
};
