import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'utils/api';
import {
  FETCH_AUDITS,
  FETCH_VEHICLE_DEVICE_ASSOCIATION_AUDITS,
  FETCH_BULK_EDIT_AUDITS
} from '../actions';
import { lowerCase } from 'lodash';

export const fetchAudits = createAsyncThunk(
  FETCH_AUDITS,
  async ({ entityName, entityId }, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;

    const response = await api.get(`/audit/${entityName}/${entityId}`, {
      authKey
    });

    return response.body;
  }
);

export const fetchVehicleDeviceAssociationAudits = createAsyncThunk(
  FETCH_VEHICLE_DEVICE_ASSOCIATION_AUDITS,
  async ({ entityName, entityId }, { getState, dispatch }) => {
    const state = getState();
    const authKey = state?.user?.current?.auth?.key;

    const response = await api.get(`/audit/companies/${entityId}/dutytype`, {
      authKey
    });

    return {
      data: response.body,
      entityId
    };
  }
);

export const fetchBulkEditAudits = createAsyncThunk(
  FETCH_BULK_EDIT_AUDITS,
  async ({ entityName }, { getState, dispatch }) => {
    const authKey = getState()?.user?.current?.auth?.key;
    const userId = getState()?.user?.currentUserInfo?.id;
    const response = await api.get(`/audit/user_bulk_edit_${lowerCase(entityName)}/${userId}`, {
      authKey
    });
    return response.body;
  }
);
