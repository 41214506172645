import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, DatePicker, Button, Tooltip } from 'antd';
import styles from './DriverPortal.module.scss';
import { LogEventModal } from './Modals/LogRecord';
import { useCurrentCompany } from 'features/company/companySlice';
import { useParams, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { DriverLogPageBody } from './DriverLogPageBody';
import moment from 'moment-timezone';
import { useCanEveryCompanyService, services } from 'features/permissions';
import { useDrivers } from 'features/users/usersSlice';
import { useUser, useUserKey } from 'features/user/userSlice';
import { PageHeader } from './DriverLogPageHeader';
import {
  saveEventRecord,
  saveSignedLog,
  saveSuggestedDriverEvent,
  saveSuggestedUDT
} from 'features/eld/eventsApi';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import {
  fetchUnsignedLogs,
  useUnsignedLogs,
  fetchSuggestedDriverEvents,
  useSuggestedDriverEvents,
  fetchSuggestedUDT,
  useSuggestedUDT
} from 'features/eld/eldSlice';
import { useTranslation } from 'react-i18next';
import { extractSuggestEditError } from 'features/eld/suggestEditErrorCodes';
import { useLocalization } from 'features/localization/localizationSlice';
import { BUTTON_IDS } from 'utils/globalConstants';
import { SwitchStatusModal } from './Modals/SwitchStatusModal';
import { PrintModal } from './Modals/PrintModal';
import { EmailModal } from './Modals/EmailModal';
import { UnsignedLogsModal } from './Modals/UnsignedLogsModal';
import { UnidentifiedDriverProfileModal } from './Modals/UnidentifiedDriverProfileModal';
import { SuggestedLogsModal } from './Modals/SuggestedLogsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPrint,
  faEnvelope,
  faPeopleArrows,
  faSignature,
  faAddressCard
} from '@fortawesome/free-solid-svg-icons';
import { DutyStatus } from './util';
import { ApiClient } from 'nextgen_api';
import { API_PATH } from 'config';
import { useTimer } from 'utils/hooks/useTimer';
import { getLogReport } from 'features/eld/logExportSlice';
import { UDTRoutePreviewModal } from '../UDT/Modal/UDTRoutePreviewModal';

const PointEvents = ['ZoneChange', 'CycleChange', 'OffDutyDeferral'];

export function DriverPortal({ ...props }) {
  const [showSwitchStatusModal, setShowSwitchStatusModal] = useState(false);
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showSuggestedLogsModal, setShowSuggestedLogsModal] = useState(false);
  const [showUnsignedLogsModal, setShowUnsignedLogsModal] = useState(false);
  const [showUnidentifiedDriverProfileModal, setShowUnidentifiedDriverProfileModal] = useState(
    false
  );
  const [showPreviewRouteModal, setShowPreviewRouteModal] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const company = useCurrentCompany();
  const userKey = useUserKey();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const drivers = useDrivers();

  const { t } = useTranslation();
  const locale = useLocalization();
  const [rowActions, setRowActions] = useState({});
  const driver = useUser();
  const [logDate, setLogDate] = useState(moment());
  const isUSELD = useCanEveryCompanyService([services.ELDUS]);
  const isCANELD = useCanEveryCompanyService([services.ELDCAN]);
  const unsignedLogs = useUnsignedLogs();
  const suggestedDriverEvents = useSuggestedDriverEvents();
  const suggestedUDT = useSuggestedUDT();
  const [unsignedLogsInit, setUnsignedLogsInit] = useState(false);
  const [suggestedDriverEventsInit, setSuggestedDriverEventsInit] = useState(false);
  const [suggestedUDTInit, setSuggestedUDTInit] = useState(false);
  const [eventCounter, setEventCounter] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [udtDate, setUdtDate] = useState(null);
  const [udpRow, setUdpRow] = useState(null);

  const handleSaveRecord = useCallback(
    event => {
      const record = event;
      saveEventRecord(userKey, company?.id, record).then(
        updatedRecord => {
          const message = record.id
            ? t('ELD.DriverPortal.UpdateRecordSucceeded')
            : t('ELD.DriverPortal.AddRecordSuccessfully');
          dispatch(openToast({ type: ToastType.Success, message: message }));
          setShowRecordModal(false);
          refreshData();
        },
        ({ err, resp }) => {
          if (resp.statusCode === 412) {
            const extraMessage = extractSuggestEditError(resp.body, locale, {
              users: drivers,
              vehicles: []
            });
            const message =
              (record.id
                ? t('ELD.DriverPortal.UpdateRecordFailed')
                : t('ELD.DriverPortal.AddRecordFailed')) +
              '. ' +
              extraMessage;
            dispatch(openToast({ type: ToastType.Warning, message: message }));
          } else {
            const message =
              (record.id
                ? t('ELD.DriverPortal.UpdateRecordFailed')
                : t('ELD.DriverPortal.AddRecordFailed')) +
              '. ' +
              err;
            dispatch(openToast({ type: ToastType.Error, message: message }));
          }
        }
      );
    },
    [company, id, dispatch, locale, drivers, logDate, t]
  );

  const handleCancelEditRecord = useCallback(() => {
    setShowRecordModal(false);
  }, []);

  const handleAddRecord = useCallback(() => {
    setShowRecordModal(true);
  }, []);

  const handleSwitchStatus = useCallback(() => {
    setShowSwitchStatusModal(true);
  }, []);

  const handleSwitchStatus_Save = useCallback(
    eventData => {
      const record = eventData;

      saveEventRecord(userKey, company?.id, record).then(
        updatedRecord => {
          const message = t('ELD.DriverPortal.SwitchStatusSuccess', {
            status: DutyStatus[record.Action]
          });
          dispatch(openToast({ type: ToastType.Success, message: message }));
          setShowSwitchStatusModal(false);
          refreshData();
        },
        ({ err, resp }) => {
          const message = t('ELD.DriverPortal.SwitchStatusFailed') + '. ' + err;
          dispatch(openToast({ type: ToastType.Error, message: message }));
        }
      );
      setShowSwitchStatusModal(false);
    },
    [dispatch, company, id, logDate, locale, t]
  );

  const handleSwitchStatus_Cancel = useCallback(() => {
    setShowSwitchStatusModal(false);
  }, []);

  const handlePrint = useCallback(() => {
    setShowPrintModal(true);
  }, []);

  const handlePrint_Save = useCallback(async (countrySelected, dateRanges) => {
    if (userKey == null) return;

    const promise = new Promise((resolve, reject) => {
      const apiClient = new ApiClient();
      apiClient.basePath = API_PATH;

      apiClient.defaultHeaders = {
        Authorization: `Token token="${userKey}"`
      };
      const apiQuery = '/eld/logreport';
      apiClient.callApi(
        apiQuery,
        'POST',
        {},
        {},
        {},
        {},
        {
          companyId: company?.id,
          DriverId: driver?.id,
          StartDate: dateRanges[0].startOf('day'),
          EndDate: dateRanges[1].endOf('day'),
          Timezone: driver?.timeZone,
          ReportType: countrySelected,
          ReportId: 0
        },
        [],
        [],
        [],
        null,
        null,
        (err, data, resp) => {
          if (err && (resp == null || resp.status !== 200)) {
            console.error(err);
            reject(err);
          } else {
            resolve(resp.body);
          }
        }
      );
    });

    try {
      setShowPrintModal(false);
      dispatch(
        openToast({
          type: ToastType.Info,
          message: t('ELD.DriverPortal.PrintCreation')
        })
      );
      const data = await promise;

      setTimeout(function() {
        getLogReport(userKey, data);
      }, 1000);
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: err.toString()
        })
      );
    }
  }, []);
  const handlePrint_Cancel = useCallback(() => {
    setShowPrintModal(false);
  }, []);

  const handleEmail = useCallback(() => {
    setShowEmailModal(true);
  }, []);

  const handleEmail_Save = useCallback(
    async (countrySelected, emailSubject, emailRecipients, emailBody, dateRanges) => {
      if (userKey == null) return;
      const promise = new Promise((resolve, reject) => {
        const apiClient = new ApiClient();
        apiClient.basePath = API_PATH;

        apiClient.defaultHeaders = {
          Authorization: `Token token="${userKey}"`
        };
        const apiQuery = '/eld/logexport';
        apiClient.callApi(
          apiQuery,
          'POST',
          {},
          {},
          {},
          {},
          {
            companyId: company.id,
            comment: emailBody,
            logType: 'Driver',
            userId: driver.id,
            vehicleId: null,
            initiateAt: new Date(),
            initiatedByUserId: driver.id,
            periodBegin: dateRanges[0].startOf('day'),
            periodEnd: dateRanges[1].endOf('day'),
            sendEmail: true,
            sendToWebService: false,
            logTransferType: countrySelected,
            AdditionalEmailees: emailRecipients,
            EmailSubject: emailSubject
          },
          [],
          [],
          [],
          null,
          null,
          (err, data, resp) => {
            if (err && (resp == null || resp.status !== 200)) {
              console.error(err);
              reject(err);
            } else {
              resolve(resp.body);
            }
          }
        );
      });

      try {
        await promise;
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('ELD.DriverPortal.EmailSentSuccess')
          })
        );
      } catch (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err.toString()
          })
        );
      }

      setShowEmailModal(false);
    },
    [driver, userKey, company, dispatch, t]
  );

  const handleEmail_Cancel = useCallback(() => {
    setShowEmailModal(false);
  }, []);

  const handleSuggestedLogs = useCallback(() => {
    setShowSuggestedLogsModal(true);
  }, []);

  const handleSuggestedLogs_Save = useCallback(
    (driverEventRecords, UDTRecords) => {
      let success = true;
      let error = '';

      saveSuggestedDriverEvent(userKey, driver.id, driverEventRecords).then(
        updatedRecord => {
          refreshData();
        },
        ({ err, resp }) => {
          success = false;
          error = err;
        }
      );

      saveSuggestedUDT(userKey, driver.id, UDTRecords).then(
        updatedRecord => {
          dispatch(fetchSuggestedUDT(driver.id));
        },
        ({ err, resp }) => {
          success = false;
          error.concat(err);
        }
      );

      if (success) {
        const message = t('ELD.DriverPortal.SuggestedLogsSuccess');
        dispatch(openToast({ type: ToastType.Success, message: message }));
      } else {
        const message = t('ELD.DriverPortal.SuggestedLogsFailed') + '. ' + error;
        dispatch(openToast({ type: ToastType.Error, message: message }));
      }
      setShowSuggestedLogsModal(false);
      refreshData();
    },
    [dispatch, userKey, driver, company, logDate, id, t]
  );

  const handleSuggestedLogs_Cancel = useCallback(() => {
    setShowSuggestedLogsModal(false);
  }, []);

  const handleUnsignedLogs = useCallback(() => {
    setShowUnsignedLogsModal(true);
  }, []);

  const handleUDTLinkClick = useCallback(udpRow => {
    setShowSuggestedLogsModal(false);
    setShowPreviewRouteModal(true);

    setUdpRow(udpRow);
    setUdtDate(udpRow?.TimeAt);
  }, []);

  const handleLogNavigationClick = useCallback(date => {
    const day = moment(date).startOf('Day');
    setLogDate(day);
    setShowUnsignedLogsModal(false);
    setShowSuggestedLogsModal(false);
    refreshData();
  }, []);

  const handleUnsignedLogs_Save = useCallback(
    eventData => {
      const record = eventData;

      saveSignedLog(userKey, record).then(
        updatedRecord => {
          const message = t('ELD.DriverPortal.SignSuccess');
          dispatch(openToast({ type: ToastType.Success, message: message }));
          setShowUnsignedLogsModal(false);
          refreshData();
        },
        ({ err, resp }) => {
          const message = t('ELD.DriverPortal.SignFailed') + '. ' + err;
          dispatch(openToast({ type: ToastType.Error, message: message }));
        }
      );
      setShowUnsignedLogsModal(false);
    },
    [dispatch, company, id, logDate, locale, t]
  );

  const handleUnsignedLogs_Cancel = useCallback(() => {
    setShowUnsignedLogsModal(false);
  }, []);

  const handleUnidentifiedDriverProfile = useCallback(() => {
    setShowUnidentifiedDriverProfileModal(true);
  }, []);

  const handleUnidentifiedDriverProfile_Save = useCallback(() => {
    setShowUnidentifiedDriverProfileModal(false);
    setUdtDate(null);
  }, []);

  const handleUnidentifiedDriverProfile_Cancel = useCallback(() => {
    setShowUnidentifiedDriverProfileModal(false);
    setUdtDate(null);
  }, []);

  const disabledDate = useCallback(
    current => {
      const AvailableDateRange = [
        moment()
          .tz(driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
          .startOf('day')
          .subtract(6, 'month'),
        moment()
          .tz(driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone)
          .endOf('day')
      ];
      let disableDate = false;
      if (current && AvailableDateRange) {
        disableDate = current < AvailableDateRange[0] || current > AvailableDateRange[1];
      }

      return disableDate;
    },
    [driver]
  );

  const handleEventRowMouseEnter = useCallback((row, index, event) => {
    const prefix = PointEvents.includes(row.action) ? 'flag_' : 'event_';
    setRowActions(prevState => {
      return {
        ...prevState,
        [`${prefix}${row.id}`]: 'mouseEnter'
      };
    });
  }, []);

  const handleEventRowMouseLeave = useCallback((row, index, event) => {
    const prefix = PointEvents.includes(row.action) ? 'flag_' : 'event_';
    setRowActions(prevState => {
      return {
        ...prevState,
        [`${prefix}${row.id}`]: 'mouseLeave'
      };
    });
  }, []);

  const handleViolationRowMouseEnter = useCallback((row, index, event) => {
    setRowActions(prevState => {
      return {
        ...prevState,
        ['violation_' + row.rule?.id]: 'mouseEnter'
      };
    });
  }, []);

  const handleViolationRowMouseLeave = useCallback((row, index, event) => {
    setRowActions(prevState => {
      return {
        ...prevState,
        ['violation_' + row.rule?.id]: 'mouseLeave'
      };
    });
  }, []);

  const handleCloseRoutePreviewModal = useCallback((row, index, event) => {
    setShowPreviewRouteModal(false);
    setShowSuggestedLogsModal(true);
  }, []);

  const refreshData = useCallback(() => {
    dispatch(fetchUnsignedLogs(driver.id));
    dispatch(fetchSuggestedDriverEvents(driver.id));
    dispatch(fetchSuggestedUDT(driver.id));
    setReloadData(true);
    setReloadData(false);
  }, [reloadData, driver, logDate]);

  const backOneDay = useCallback(() => {
    setLogDate(prev => moment(prev).subtract(1, 'day'));
    refreshData();
  }, []);

  const forwardOneDay = useCallback(() => {
    setLogDate(prev => moment(prev).add(1, 'day'));
    refreshData();
  }, []);

  useEffect(() => {
    if (driver?.id && !unsignedLogs && !unsignedLogsInit) {
      dispatch(fetchUnsignedLogs(driver.id));
      setUnsignedLogsInit(true);
    }
  }, [dispatch, driver, unsignedLogs, unsignedLogsInit]);

  useEffect(() => {
    if (
      suggestedDriverEvents &&
      suggestedDriverEvents?.length > 0 &&
      suggestedUDT &&
      suggestedUDT?.length > 0
    ) {
      setEventCounter(suggestedDriverEvents.length + suggestedUDT.length);
    } else if (suggestedDriverEvents && suggestedDriverEvents?.length > 0) {
      setEventCounter(suggestedDriverEvents.length);
    } else if (suggestedUDT && suggestedUDT.length > 0) {
      setEventCounter(suggestedUDT.length);
    } else {
      setEventCounter(parseInt(0));
    }
  }, [suggestedDriverEvents, suggestedUDT, eventCounter]);

  useEffect(() => {
    if (driver?.id && !suggestedDriverEvents && !suggestedDriverEventsInit) {
      dispatch(fetchSuggestedDriverEvents(driver.id));
      setSuggestedDriverEventsInit(true);
    }
  }, [dispatch, driver, suggestedDriverEvents, suggestedDriverEventsInit]);

  useEffect(() => {
    if (driver?.id && !suggestedUDT && !suggestedUDTInit) {
      dispatch(fetchSuggestedUDT(driver.id));
      setSuggestedUDTInit(true);
    }
  }, [dispatch, driver, suggestedUDT, suggestedUDTInit]);

  useEffect(() => {
    dispatch(setBackButton(true));
    if (driver != null) {
      dispatch(setPageTitle(t('ELD.DriverPortal.Welcome', { name: driver?.name })));
    }
  }, [dispatch, driver, t]);

  useTimer(60000, refreshData, true);

  useEffect(() => {
    if (id && driver && company && logDate) {
      setIsLoaded(true);
    } else {
      setIsLoaded(false);
    }
  }, [id, driver, company, logDate]);

  if (id === null || id === '') {
    history.goBack();
    return;
  }

  const PrevIcon = <i className="tn-i-caret-left" />;
  const ForwardIcon = <i className="tn-i-caret-right" />;

  return (
    <Row
      style={{
        flexDirection: 'column',
        minHeight: 'calc(100vh - 62px)',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        overflowY: 'scroll'
      }}
    >
      <Row
        style={{
          paddingRight: 20,
          paddingLeft: 3,
          zIndex: '5',
          minHeight: '64px',
          background: '#fff',
          justifyContent: 'flex-end',
          alignItems: 'center',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Col span={24}>
          <Row className={styles.headerbar} gutter={6} justify="end">
            <Col>
              <Button
                type="primary"
                onClick={handleSwitchStatus}
                id={BUTTON_IDS.driverPortalSwitchStatus}
              >
                {t('ELD.DriverPortal.SwitchStatus')}
              </Button>
            </Col>
            <Col>
              <Button onClick={handleAddRecord} id={BUTTON_IDS.driverPortalNewStatus}>
                {t('ELD.DriverPortal.NewRecord')}
              </Button>
            </Col>

            <Col>
              <Tooltip title={t('ELD.DriverPortal.Print')}>
                <Button onClick={handlePrint} id={BUTTON_IDS.driverPortalPrint}>
                  <FontAwesomeIcon icon={faPrint} />{' '}
                </Button>
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title={t('ELD.DriverPortal.Email')}>
                <Button onClick={handleEmail} id={BUTTON_IDS.driverPortalEmail}>
                  <FontAwesomeIcon icon={faEnvelope} />{' '}
                </Button>
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title={t('ELD.DriverPortal.SuggestedLogs')}>
                <Button onClick={handleSuggestedLogs} id={BUTTON_IDS.driverPortalSuggestedLogs}>
                  <FontAwesomeIcon icon={faPeopleArrows} />{' '}
                </Button>
                {eventCounter !== 0 && (
                  <span onClick={handleSuggestedLogs} className={styles.badgeComponent}>
                    {eventCounter}
                  </span>
                )}
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title={t('ELD.DriverPortal.UnsignedLogs')}>
                <Button onClick={handleUnsignedLogs} id={BUTTON_IDS.driverPortalUnsignedLogs}>
                  <FontAwesomeIcon icon={faSignature} />{' '}
                </Button>
                {unsignedLogs && unsignedLogs.length > 0 && (
                  <span onClick={handleUnsignedLogs} className={styles.badgeComponent}>
                    {unsignedLogs.length}
                  </span>
                )}
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title={t('ELD.DriverPortal.UnidentifiedDriverProfile')}>
                <Button
                  onClick={handleUnidentifiedDriverProfile}
                  id={BUTTON_IDS.driverPortalUnidentifiedDriverProfile}
                >
                  <FontAwesomeIcon icon={faAddressCard} />{' '}
                </Button>
              </Tooltip>
            </Col>

            <Col className={styles.datePickerContainer}>
              <Button
                onClick={backOneDay}
                icon={PrevIcon}
                id={BUTTON_IDS.driverLogBackDay}
                disabled={disabledDate(
                  moment
                    .tz(
                      logDate,
                      driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                    )
                    .subtract(1, 'day')
                )}
              ></Button>
              <DatePicker
                value={logDate}
                className={styles.datePicker}
                onChange={date => {
                  setLogDate(date);
                  refreshData();
                }}
                format={locale.formats.time.formats.dby.toUpperCase()}
                disabledDate={disabledDate}
                allowClear={false}
              />
              <Button
                onClick={forwardOneDay}
                icon={ForwardIcon}
                id={BUTTON_IDS.driverLogForwardDay}
                disabled={disabledDate(
                  moment
                    .tz(
                      logDate,
                      driver?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
                    )
                    .add(1, 'day')
                )}
              ></Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ height: '100%', overflow: 'scroll' }}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              {isLoaded && (
                <PageHeader
                  driverId={id}
                  company={company}
                  logDate={logDate}
                  rowActions={rowActions}
                  refreshData={() => dispatch(refreshData)}
                  reloadData={reloadData}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {isLoaded && (
                <DriverLogPageBody
                  onEventRowMouseEnter={handleEventRowMouseEnter}
                  onEventRowMouseLeave={handleEventRowMouseLeave}
                  onViolationRowMouseEnter={handleViolationRowMouseEnter}
                  onViolationRowMouseLeave={handleViolationRowMouseLeave}
                  company={company}
                  driverId={id}
                  logDate={logDate}
                  driverDetail={driver}
                  reloadData={reloadData}
                  refreshData={() => dispatch(refreshData)}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {showRecordModal && (
        <LogEventModal
          event={{ source: 'Driver', eventAt: logDate }}
          onSave={handleSaveRecord}
          onCancel={handleCancelEditRecord}
          showRecordModal={showRecordModal}
          editMode={false}
          driverId={id}
          title={t('ELD.DriverPortal.AddNewRecord')}
          driverDetail={driver}
        />
      )}
      {showSwitchStatusModal && (
        <SwitchStatusModal
          driver={driver}
          showModal={showSwitchStatusModal}
          onCancel={handleSwitchStatus_Cancel}
          onSave={handleSwitchStatus_Save}
        />
      )}
      {showPrintModal && (
        <PrintModal
          showModal={showPrintModal}
          onCancel={handlePrint_Cancel}
          onSave={handlePrint_Save}
          isUSELD={isUSELD}
          isCANELD={isCANELD}
        />
      )}
      {showEmailModal && (
        <EmailModal
          showModal={showEmailModal}
          onCancel={handleEmail_Cancel}
          onSave={handleEmail_Save}
          driver={driver}
          isUSELD={isUSELD}
          isCANELD={isCANELD}
        />
      )}
      {showSuggestedLogsModal && (
        <SuggestedLogsModal
          driver={driver}
          showModal={showSuggestedLogsModal}
          onCancel={handleSuggestedLogs_Cancel}
          onSave={handleSuggestedLogs_Save}
          handleLogNavigationClick={handleLogNavigationClick}
          handleUDTLinkClick={handleUDTLinkClick}
        />
      )}
      {showUnsignedLogsModal && (
        <UnsignedLogsModal
          driver={driver}
          currentDate={logDate}
          showModal={showUnsignedLogsModal}
          onCancel={handleUnsignedLogs_Cancel}
          onSave={handleUnsignedLogs_Save}
          handleUnsignedLogClick={handleLogNavigationClick}
        />
      )}
      {showUnidentifiedDriverProfileModal && (
        <UnidentifiedDriverProfileModal
          showModal={showUnidentifiedDriverProfileModal}
          onCancel={handleUnidentifiedDriverProfile_Cancel}
          onSave={handleUnidentifiedDriverProfile_Save}
          date={udtDate}
        />
      )}
      {showPreviewRouteModal && (
        <UDTRoutePreviewModal
          visible={true}
          isDriverPortal={true}
          rowData={udpRow}
          onClose={handleCloseRoutePreviewModal}
          onUpdate={handleCloseRoutePreviewModal}
        />
      )}
    </Row>
  );
}
