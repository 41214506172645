import React from 'react';
import { Redirect } from 'react-router';
import { Switch } from 'react-router-dom';

import { usePages } from 'Pages';

import Page from 'components/pages/Page';
import IdleTimerContainer from 'components/IdleTimerContainer/IdleTimerContainer';
import NoAccess from 'components/pages/NoAccess';

import CanRoute from './CanRoute';

const replaceMatchedParams = (link, match) => {
  if (link && match) {
    let result = link;
    for (let key in match.params) {
      result = result.replace(':' + key, match.params[key]);
    }
    if (match.search != null) {
      result += match.search;
    }
    return result;
  }
  return '';
};

const PageRouter = () => {
  const pages = usePages().filter(page => !page.isExternalLink);

  return (
    <>
      <Switch>
        {pages.map(page => {
          const Component = page.component;
          return (
            <CanRoute
              everyService={page.everyService}
              everyEntity={page.everyEntity}
              oneOfServices={page.oneOfServices}
              oneOfEntities={page.oneOfEntities}
              everyRole={page.everyRole}
              oneOfRoles={page.oneOfRoles}
              companyConfigs={page.companyConfigs}
              featureFlag={page.featureFlag}
              featureHide={page.featureHide}
              key={page.path}
              loginPath={'/login'}
              path={page.path}
              exact
              onFail={<NoAccess />}
              render={routeProps => {
                if (page.fullPage) {
                  return (
                    <>
                      <IdleTimerContainer />
                      <Component />
                    </>
                  );
                }

                if (page.redirection) {
                  return (
                    <>
                      <IdleTimerContainer />
                      <Redirect
                        to={{ pathname: replaceMatchedParams(page.link, routeProps.match) }}
                      />
                    </>
                  );
                }

                return window.location.pathname.includes('/tracking/maptiles') ? (
                  <Page page={page} />
                ) : (
                  <>
                    <IdleTimerContainer />
                    <Page page={page} />
                  </>
                );
              }}
            />
          );
        })}
      </Switch>
    </>
  );
};

export default PageRouter;
