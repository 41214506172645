import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LogExport.module.scss';

export function LogExportToolbar({ gridCount, onSearchChanged }) {
  const { t } = useTranslation();
  return (
    <div className={styles.logExportToolbar}>
      <AntSearchbar onFilter={onSearchChanged} />
      <div>
        {gridCount || 0} {t('ELD.Log Export Records')}
      </div>
    </div>
  );
}
