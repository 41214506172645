import moment from 'moment';

export const actionTypes = {
  init: 'init',
  processing: 'processing',
  error: 'error',
  done: 'done'
};

export const status = {
  lastFetched: null,
  fetching: actionTypes.init,
  error: null,
  isListEmpty: false
};

export const loadingStarted = (state, action) => ({
  fetching: actionTypes.processing,
  error: null
});

export const loadingFailed = (state, action) => ({
  fetching: actionTypes.error,
  lastFetched: moment().format(),
  error: action.payload
});

export const loadingFinished = (state, action) => ({
  fetching: actionTypes.done,
  lastFetched: moment().format(),
  error: null
});
