import React, { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Layout, Space, Button } from 'antd';
import { Select } from 'components/ant';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { CalendarSwitch } from 'components/ant';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import RescheduleModal from './rescheduleModal';
import {
  useFleets,
  useIsFetching as useIsFleetsFetching,
  useVehiclesFromFleets
} from 'features/fleets/fleetsSlice';
import {
  useSchedulesWithVehicleOdometer,
  changeCalendarView,
  useIsCalendarView,
  handleScheduleDeleteAction,
  handleScheduleRestoreAction,
  updateCalendarDate,
  useCaledarDate,
  updateFilters,
  useFilters,
  setActiveTab
} from 'features/vehicleMaintenance/schedulesSlice';
import { useUsers } from 'features/users/usersSlice';
import { useSubCompanies } from 'features/company/companySlice';
import { VehicleMaintenanceSchedulesTable } from './VehicleMaintenanceSchedulesTable';
import { FlattenTable } from './FlattenTable';
import { VehicleMaintenanceCalendarView } from './VehicleMaintenanceCalendarView';
import {
  useVehicleMntTypes,
  useIsFetching as useIsVehicleMntTypesFetching
} from 'features/vehicleMntTypes/vehicleMntTypesSlice';
import { toLower, orderBy } from 'lodash';
import useDebounce from 'utils/hooks/useDebounce';
import { Can, entities, FeatureFlag } from 'features/permissions';
import { getMeterValue, getMeterByType } from 'features/meters';
import { isFilteredByNextDue, getScheduleStatusByTab } from './utils/helpers';
import { METER_TYPES } from '../Administration/Vehicles/constants';
import { FilterTypes, getCheckedFilterIds, prepareDataForMultiselect } from 'utils/filters';
import { dateDiffInDays } from 'utils/dates';
import { TabsFilters, TABS, FILTER_BY, Paths } from './utils/constants';

import './VehicleMaintenanceSchedules.scss';
import styles from './VehicleMaintenanceSchedules.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const { Content } = Layout;

export const VehicleMaintenanceSchedules = () => {
  const { t } = useTranslation();
  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const history = useHistory();
  const dispatch = useDispatch();
  const isFleetsFetching = useIsFleetsFetching();
  const isVehicleMntTypesFetching = useIsVehicleMntTypesFetching();
  const [filterTab, setFilterTab] = useState(filterPath);
  const isCalendarView = useIsCalendarView();
  const storedCalendarDate = useCaledarDate();
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [rescheduleId, setRescheduleId] = useState(null);
  const [calendarDate, setCalendarDate] = useState(
    storedCalendarDate ? moment(storedCalendarDate) : moment()
  );
  const filterByScheduleStatus = useMemo(() => {
    return getScheduleStatusByTab(filterTab);
  }, [filterTab]);

  const {
    schedulesWithVehiceOdometer: initialSchedules,
    isFetching: isSchedulesFetching
  } = useSchedulesWithVehicleOdometer(filterByScheduleStatus);

  const subCompanies = useSubCompanies();
  const vehicleMntTypes = useVehicleMntTypes();
  const fleets = useFleets();
  const { vehicles } = useVehiclesFromFleets();
  const users = useUsers();
  const [searchText, setSearchText] = useState('');
  const [expandAll, setExpandAll] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const filters = useFilters();
  const filterBy = useMemo(() => filters.filterBy, [filters]);
  const filterByItems = useMemo(() => {
    return Object.keys(FILTER_BY).map(id => ({
      id,
      label: t(`VehicleMntSchedules.${id}`)
    }));
  }, [t]);

  useEffect(() => {
    dispatch(setPageTitle(t('VehicleMntSchedules.Title')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  const schedules = useMemo(() => {
    const schedulesWithOdometer = initialSchedules.map(schedule => {
      const meter = { meters: schedule?.vehicle?.meters || [] };
      const odometerValue = schedule?.vehicle?.vehicleOdometer?.odometerValue;
      const engineHoursMeter = getMeterByType(meter, METER_TYPES.HOURS);
      const engineHoursValue = engineHoursMeter ? getMeterValue(engineHoursMeter) : 0;

      let parameters = {};
      try {
        parameters = JSON.parse(schedule?.parameters || '{}');
      } catch (e) {
        console.error(e);
      }

      const nextDueOdometer =
        parameters.odometer && Number(parameters.odometer) - Number(odometerValue);
      const nextDueEngineHours =
        parameters.engine_hours && Number(parameters.engine_hours) - Number(engineHoursValue);
      const nextDueDays = schedule?.schedulesOn
        ? Number(dateDiffInDays(new Date(), new Date(schedule.schedulesOn.split('+')[0])))
        : '';

      const vehicleWithOdo = {
        ...schedule.vehicle,
        odometer: odometerValue,
        engineHours: engineHoursValue
      };

      return {
        ...schedule,
        vehicle: vehicleWithOdo,
        nextDueOdometer,
        nextDueEngineHours,
        nextDueDays
      };
    });
    return schedulesWithOdometer;
  }, [initialSchedules]);

  useEffect(() => {
    dispatch(setActiveTab(filterTab === 'vehiclemaintenance' ? TABS.active : filterTab));
  }, [filterTab, dispatch]);

  const { filterCompanies, filterFleets, filterVehicles, filterTypes } = useMemo(() => {
    const filterCompanies =
      subCompanies &&
      prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), filters.companies);
    const subCompaniesIdsFromFilter = filterCompanies
      .filter(comp => comp.checked)
      .map(comp => comp.id);
    const filterFleets =
      fleets &&
      prepareDataForMultiselect(
        fleets.filter(fleet => subCompaniesIdsFromFilter?.includes(fleet?.company?.id)),
        t('Common.AllFleets'),
        filters.fleets
      );
    const filterVehicles =
      vehicles &&
      prepareDataForMultiselect(
        vehicles.filter(vehicle => !!vehicle.id),
        t('Common.AllVehicles'),
        filters.vehicles
      );
    const filterTypes =
      vehicleMntTypes &&
      prepareDataForMultiselect(vehicleMntTypes, t('Common.AllTypes'), filters.types);
    return {
      filterCompanies,
      filterFleets,
      filterVehicles,
      filterTypes
    };
  }, [subCompanies, fleets, vehicles, vehicleMntTypes, filters, t]);

  useEffect(() => {
    if (path === Paths.VEHICLEMAINTENANCE_DEFAULT) {
      setFilterTab(TABS.active);
    }
  }, [path]);

  useEffect(() => {
    const autoExpandAll =
      !!debouncedSearchText ||
      [filterCompanies, filterFleets, filterVehicles, filterTypes].some(
        options => !options?.find(option => Number(option?.id) === 0)?.checked
      );
    setExpandAll(autoExpandAll);
  }, [filterCompanies, filterFleets, filterVehicles, filterTypes, debouncedSearchText]);

  const sortedSchedules = useMemo(() => {
    const getSortedSchedules = (sortBy, schedules) => {
      if (!sortBy) {
        return;
      }
      switch (sortBy) {
        case FILTER_BY.odometer:
          return orderBy(
            schedules.filter(s => s.nextDueOdometer),
            ['nextDueOdometer'],
            'asc'
          );
        case FILTER_BY.engineHours:
          return orderBy(
            schedules.filter(s => s.nextDueEngineHours),
            ['nextDueEngineHours'],
            'asc'
          );
        case FILTER_BY.days:
          return orderBy(
            schedules.filter(s => s.nextDueDays),
            ['nextDueDays'],
            'asc'
          );
        default:
          return schedules;
      }
    };
    return isFilteredByNextDue(filterBy) ? getSortedSchedules(filterBy, schedules) : schedules;
  }, [filterBy, schedules]);

  const filteredSchedules = useMemo(
    () =>
      sortedSchedules.filter(schedule => {
        let validSchedule = filterByScheduleStatus.some(
          filteredStatus => filteredStatus === schedule.status
        );
        // Filter by search field
        if (debouncedSearchText) {
          const searchArray = debouncedSearchText.split(' ');
          searchArray.forEach(splitValue => {
            validSchedule =
              validSchedule &&
              [schedule.name, schedule?.vehicle?.name, schedule?.vehicle?.registration].some(
                value => toLower(value).indexOf(toLower(splitValue)) > -1
              );
          });
        }

        // Filter by companies
        const checkedCompaniesIds = filterCompanies
          .filter(company => company.checked)
          .map(company => parseInt(company.id, 10));
        if (!(checkedCompaniesIds.indexOf(0) > -1)) {
          validSchedule =
            validSchedule && checkedCompaniesIds.indexOf(parseInt(schedule.company.id, 10)) > -1;
        }

        // Filter by fleets
        const checkedFleetIds = filterFleets
          .filter(fleet => fleet.checked)
          .map(fleet => parseInt(fleet.id, 10));
        if (!(checkedFleetIds.indexOf(0) > -1)) {
          validSchedule =
            validSchedule &&
            checkedFleetIds.some(id => schedule.fleetIds && schedule.fleetIds.includes(id));
        }

        // Filter by vehicles
        const checkedVehiclesIds = filterVehicles
          .filter(vehicle => vehicle.checked)
          .map(vehicle => parseInt(vehicle.id, 10));
        if (!(checkedVehiclesIds.indexOf(0) > -1)) {
          validSchedule =
            validSchedule && checkedVehiclesIds.indexOf(parseInt(schedule.vehicle.id, 10)) > -1;
        }

        // Filter by types
        const checkedTypesIds = filterTypes
          .filter(type => type.checked)
          .map(type => parseInt(type.id, 10));
        if (!(checkedTypesIds.indexOf(0) > -1)) {
          validSchedule =
            validSchedule && checkedTypesIds.indexOf(parseInt(schedule.manageType.id, 10)) > -1;
        }
        return validSchedule;
      }),
    [
      sortedSchedules,
      filterByScheduleStatus,
      debouncedSearchText,
      filterCompanies,
      filterFleets,
      filterVehicles,
      filterTypes
    ]
  );

  const handleDeleteAction = schedule => () => {
    dispatch(handleScheduleDeleteAction({ schedule, t }));
  };

  const handleRestoreAction = schedule => () => {
    dispatch(handleScheduleRestoreAction({ schedule, t }));
  };

  const handleRescheduleAction = schedule => () => {
    setShowRescheduleModal(true);
    setRescheduleId(schedule?.id);
  };

  const goToAddForm = () => {
    history.push(Paths.VEHICLEMAINTENANCE_ADD);
  };

  const goToAddFormNew = () => {
    history.push(Paths.VEHICLEMAINTENANCE_ADD_NEW);
  };

  const goToMntTypes = () => {
    history.push('/settings/vehicleMntTypes');
  };

  const handleFilterChange = type => newFilterValues => {
    const checkedFilterIds = getCheckedFilterIds(newFilterValues);
    dispatch(updateFilters(type, checkedFilterIds));
  };

  const toggleCalendarView = () => {
    dispatch(changeCalendarView());
  };

  const handleCalendarDateChange = value => {
    const date = moment(value).format();
    dispatch(updateCalendarDate(date));
    setCalendarDate(value);
  };

  const toggleExpandAll = () => {
    setExpandAll(prev => !prev);
  };

  const handleFilterByChange = value => {
    dispatch(updateFilters(FilterTypes.filterBy, value));
  };

  const updateRescheduleModalVisibility = bool => {
    setShowRescheduleModal(bool);
  };

  return (
    <>
      <Layout className={styles.height100}>
        <RescheduleModal
          scheduleId={rescheduleId}
          show={showRescheduleModal}
          updateRescheduleModalVisibility={updateRescheduleModalVisibility}
        />
        <div className={styles.tabFilters}>
          <TabsFilters setFilterTab={setFilterTab} />
          <div style={{ marginLeft: 'auto' }}>
            <Space size={16}>
              <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_CREATE]}>
                <Can featureFlag={FeatureFlag.newVehicleMaintenanceForm.flag}>
                  <Button
                    type="primary"
                    size="large"
                    style={{ marginRight: 16 }}
                    onClick={goToAddFormNew}
                  >
                    New Form (UX)
                  </Button>
                </Can>
                <Button
                  type="primary"
                  size="large"
                  onClick={goToAddForm}
                  id={BUTTON_IDS.vehicleMntSchedulesAdd}
                >
                  {t('VehicleMntSchedules.AddNewSchedule')}
                </Button>
              </Can>
              <Can everyEntity={[entities.VEHICLEMAINTENANCETYPE]}>
                <Button
                  type="secondary"
                  size="large"
                  onClick={goToMntTypes}
                  id={BUTTON_IDS.vehicleMntSchedulesTypes}
                >
                  {t('VehicleMntSchedules.ManageTypes')}
                </Button>
              </Can>
            </Space>
          </div>
        </div>
        <div className={styles.filterWrapper}>
          <FilterWrapper>
            <AntSearchbar onFilter={setSearchText} />
            <AntMultiselect
              key="companies"
              title={
                !filterCompanies.find(company => !company.checked)
                  ? t('Common.AllCompanies')
                  : t('Common.Companies')
              }
              data={filterCompanies}
              onFilter={handleFilterChange(FilterTypes.companies)}
            />
            <AntMultiselect
              key="fleets"
              title={
                !filterFleets.find(value => !value.checked)
                  ? t('Common.AllFleets')
                  : t('Common.Fleets')
              }
              data={filterFleets}
              onFilter={handleFilterChange(FilterTypes.fleets)}
              loading={isFleetsFetching}
            />
            <AntMultiselect
              key="vehicles"
              title={
                !filterVehicles.find(value => !value.checked)
                  ? t('VehicleMntSchedules.AllVehicles')
                  : t('Common.Vehicles')
              }
              data={filterVehicles}
              onFilter={handleFilterChange(FilterTypes.vehicles)}
              loading={isSchedulesFetching}
            />
            <AntMultiselect
              key="vehicleMntTypes"
              title={
                !filterTypes.find(value => !value.checked)
                  ? t('Common.AllTypes')
                  : t('Common.Types')
              }
              data={filterTypes}
              onFilter={handleFilterChange(FilterTypes.types)}
              loading={isVehicleMntTypesFetching}
            />
            {!isCalendarView && (
              <Select
                size="large"
                className={styles.orderBySelect}
                placeholder={t('VehicleMntSchedules.OrderByNextDue')}
                data={filterByItems}
                onSelect={handleFilterByChange}
                value={filterBy}
              />
            )}
          </FilterWrapper>
          <div className={styles.counterWrapper}>
            {!isCalendarView && (
              <span className={styles.counter}>
                {filteredSchedules.length} {t('VehicleMntSchedules.Schedules')}
              </span>
            )}
            {!isFilteredByNextDue(filterBy) && (
              <CalendarSwitch
                isCalendarView={isCalendarView}
                toggleCalendarView={toggleCalendarView}
              />
            )}
          </div>
        </div>
        <Content style={{ background: '#fff', height: '100%' }}>
          {isCalendarView ? (
            <VehicleMaintenanceCalendarView
              schedules={filteredSchedules}
              users={users}
              onDateChange={handleCalendarDateChange}
              value={calendarDate}
            />
          ) : !isFilteredByNextDue(filterBy) ? (
            <VehicleMaintenanceSchedulesTable
              schedules={filteredSchedules}
              users={users}
              expandAll={expandAll}
              isLoading={isSchedulesFetching}
              handleDeleteAction={handleDeleteAction}
              handleRestoreAction={handleRestoreAction}
              handleRescheduleAction={handleRescheduleAction}
              typeOfEntityToDelete={t('Common.vehicle maintenance schedule')}
              handleExpandAll={toggleExpandAll}
            />
          ) : (
            <FlattenTable
              schedules={filteredSchedules}
              isLoading={isSchedulesFetching}
              handleDeleteAction={handleDeleteAction}
              typeOfEntityToDelete={t('Common.vehicle maintenance schedule')}
            />
          )}
        </Content>
      </Layout>
    </>
  );
};
