import React, { useState, useCallback } from 'react';
import { Toast as BSToast } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';

import { closeToast, useToast } from 'features/toasts/toastsSlice';

import styles from './toast.module.scss';

export const ToastType = {
  Default: 'default',
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
  Info: 'info'
};

export const ToastContainer = () => {
  const toasts = useToast();

  return (
    <>
      {toasts && toasts.length > 0 && (
        <div className={styles.container}>
          {toasts.map(toast => (
            <Toast key={toast.toastId} {...toast} />
          ))}
        </div>
      )}
    </>
  );
};

export const Toast = ({
  type = ToastType.Default,
  message,
  iconClass,
  autohide,
  dateTime,
  toastId,
  onClick = null,
  linkable = false,
  delay = 3000
}) => {
  const [show, setShow] = useState(true);
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    setShow(false);
    setTimeout(() => {
      dispatch(closeToast(toastId));
    }, 600);
  }, []);

  const getAutohide = () => {
    switch (type) {
      case ToastType.Error:
      case ToastType.Warning:
        return false;
      case ToastType.Success:
      case ToastType.Info:
      case ToastType.Default:
      default:
        return true;
    }
  };

  const getIcon = () => {
    switch (type) {
      case ToastType.Success:
        return faCheckCircle;
      case ToastType.Error:
        return faTimesCircle;
      case ToastType.Warning:
        return faExclamationCircle;
      case ToastType.Info:
      default:
        return faInfoCircle;
    }
  };

  return (
    <BSToast
      className={`${styles.toast} ${
        type ? `${linkable ? `${styles[type]} ${styles.linkable}` : styles[type]}` : ''
      }`}
      autohide={autohide !== undefined ? autohide : getAutohide()}
      show={show}
      delay={delay}
      onClose={onClose}
      onClick={onClick || onClose}
    >
      <BSToast.Header className={styles.header}>
        {iconClass || type === ToastType.Default ? (
          <i className={'mr-2 tn-i-bell'} />
        ) : (
          <FontAwesomeIcon className={`mr-2 ${styles.faIcon}`} icon={getIcon()} />
        )}
        <span className={'mr-auto ' + styles.message}>
          {Array.isArray(message)
            ? message.map((msg, index) => (
                <React.Fragment key={index}>
                  {msg}
                  {index < message.length - 1 && <br />}
                </React.Fragment>
              ))
            : message}
        </span>
        {dateTime && <small>{moment(dateTime).fromNow()}</small>}
      </BSToast.Header>
      <BSToast.Body className={styles.body}></BSToast.Body>
    </BSToast>
  );
};
