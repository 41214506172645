import { CellMeasurerCache } from 'react-virtualized';
import { driversCols, driverStatusCells } from './Columns';
import { FatigueExpandRowRender } from 'features/fatigue/Components';

export const EWDCellCache = new CellMeasurerCache({
  fixedHeight: false,
  fixedWidth: true,
  minWidth: 40,
  minHeight: 80
});

export function EWDRowRender({ index, style, className, key, rowData, localization, ...props }) {
  return FatigueExpandRowRender({
    index,
    style,
    className,
    key,
    rowData,
    localization,
    cellCache: EWDCellCache,
    tableColumns: driversCols,
    expandTableColumns: driverStatusCells,
    fatigueDataField: 'ewdData',
    ...props
  });
}
