import React from 'react';
import i18next from 'i18next';
import { Tooltip, Button } from 'components/ant';

import doubleArrowDown from 'static/images/icons/doublearrow_down.svg';
import doubleArrowUp from 'static/images/icons/doublearrow_up.svg';
import { BUTTON_IDS } from 'utils/globalConstants';

export const ExpandAllButton = ({ handleOnClick, expandAll, customStyle }) => {
  return (
    <Tooltip
      content={i18next.t('Common.ExpandCollapseAll')}
      target={
        <Button
          size="small"
          className={customStyle}
          id={BUTTON_IDS.expandAll}
          onClick={handleOnClick}
        >
          <i
            style={{
              content: `url(${expandAll ? doubleArrowUp : doubleArrowDown})`,
              marginBottom: '-2px'
            }}
          />
        </Button>
      }
    />
  );
};
