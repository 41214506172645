import React, { useEffect, useMemo } from 'react';
//hooks
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import { useItemTypes } from 'features/smartJobs';
//components
import { Button } from 'antd';
import { Document, Page, Text, View, StyleSheet, usePDF, Image, Font } from '@react-pdf/renderer';
import logoTNLg from './assets/images/tn-logo-green-140px.png';
import noData from './assets/images/no_data.png';
import Roboto from './assets/Roboto-Regular.ttf';
//helpers
import { getInfoRowValue } from '../utils/helpers';
import { renderItemData } from './assets/helpers';
import { format } from 'utils/dates';
import moment from 'moment';
//constants
import { ColumnKeys } from '../../utils/constants';
import {
  PublicJobPageStatuses,
  PublicViewJobLabels,
  JobDetailsLabels,
  TABLE_JV_JOBS_ITEMS_COLUMNS,
  JOB_PNG_ATTACHMENT_PREFIX,
  JOB_JPG_PDF_ATTACHMENT_PREFIX
} from '../utils/constans';
import { GOOGLE_MAPS_API_KEY } from 'config';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: Roboto
    }
  ]
});

const PdfBody = ({
  t,
  localization,
  job,
  itemTypes,
  jobAttach,
  jobSignature,
  jobDestinationCoordinates
}) => {
  const ItemsTableColumns = useMemo(() => {
    return TABLE_JV_JOBS_ITEMS_COLUMNS.filter(column => column.key !== ColumnKeys.ACTIONS).map(
      column => ({
        ...column,
        title: t(`SmartJobs.ColumnList.Columns.${column.key}`)
      })
    );
  }, [t, job]);

  const pdfStyles = StyleSheet.create({
    page: {
      position: 'relative',
      padding: '5px 0 35px 0',
      fontFamily: 'Roboto'
    },
    logo: {
      width: '121px'
    },
    section: {
      margin: '8px 0',
      padding: '0 10px',
      width: '100%'
    },
    sectionTitle: {
      fontSize: '14px',
      width: '100%',
      marginBottom: '15px',
      fontWeight: '500'
    },
    sectionUnderlinedTitle: {
      fontSize: '14px',
      width: '100%',
      marginBottom: '15px',
      paddingBottom: '5px',
      borderBottom: '1px solid #dadee3',
      fontWeight: '500'
    },
    detailsMapSection: {
      display: 'flex',
      padding: '0 10px',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    detailsSection: {
      width: jobDestinationCoordinates ? '50%' : '100%',
      paddingRight: '10px'
    },
    mapSection: {
      width: jobDestinationCoordinates ? '50%' : '0'
    },
    detailsRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: '10px 0',
      borderBottom: '1px solid rgb(238, 238, 238)'
    },
    detailsLabel: {
      width: '25%',
      color: '#818ea1',
      fontSize: '11px'
    },
    detailsValue: {
      fontSize: '11px',
      textAlign: 'left',
      flexWrap: 'wrap',
      width: '75%'
    },
    descriptionValue: {
      marginBottom: '35px'
    },
    actualTime: {
      color: '#26bd8b'
    },
    itemsTable: {
      width: '100%'
    },
    itemsHeader: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    itemsHeaderColumn: {
      width: `calc(100% / ${ItemsTableColumns.length})`,
      display: 'flex',
      alignItems: 'center',
      fontSize: '9px',
      fontWeight: '500',
      textTransform: 'uppercase',
      padding: '12px',
      textAlign: 'center',
      borderBottom: '1px solid #e8ebee',
      borderRight: '1px solid #e8ebee',
      backgroundColor: '#fafafa'
    },
    noDataContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    noDataImage: {
      width: '64px',
      marginTop: '10px',
      marginBottom: '10px',
      alignSelf: 'center'
    },
    noDataText: {
      fontSize: '10px',
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.25)'
    },
    itemsContainer: {
      width: '100%'
    },
    itemRow: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    itemData: {
      width: `calc(100% / ${ItemsTableColumns.length})`,
      display: 'flex',
      padding: '12px',
      textAlign: 'center',
      fontSize: '9px',
      borderBottom: '1px solid #e8ebee'
    },
    noImagesAttached: {
      fontSize: '11px',
      fontWeight: '400'
    },
    attachmentsWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    },
    attachmentWrapper: {
      width: '50%',
      marginBottom: '10px'
    },
    attachmentImage: {
      width: '100%',
      height: '270px'
    },
    attachmentText: {
      width: '100%',
      textAlign: 'center',
      fontSize: '10px'
    },
    signatureRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: '10px 0'
    },
    footerWrapper: {
      width: '100%',
      padding: '10px',
      backgroundColor: '#2b323c',
      position: 'absolute',
      bottom: '0'
    },
    footerText: {
      fontSize: '11px',
      color: '#bcc3cd'
    }
  });
  return (
    <Document>
      <Page style={pdfStyles.page}>
        <View>
          <Image src={logoTNLg} style={pdfStyles.logo} />
        </View>
        <View style={pdfStyles.detailsMapSection}>
          <View style={pdfStyles.detailsSection}>
            {PublicViewJobLabels.map(label => {
              let value = getInfoRowValue(label, job, localization, false, true);
              if (label === JobDetailsLabels.TYPE) {
                value = t(`SmartJobs.Input.JobType.${value}`, '');
              }
              if (label === JobDetailsLabels.STATUS) {
                value = t(`SmartJobs.Status.${(value || '').toLowerCase()}`, '');
              }

              if (label === JobDetailsLabels.DESCRIPTION && Array.isArray(value)) {
                value = value.map(note => (
                  <Text key={note.key} style={pdfStyles.descriptionValue}>
                    {note.key}
                  </Text>
                ));
              }

              if (label === JobDetailsLabels.START && job.actualStartAt) {
                value = (
                  <Text style={pdfStyles.actualTime}>
                    {format(
                      moment(job.actualStartAt).toDate(),
                      localization.formats.time.formats.dby_imsp
                    )}
                  </Text>
                );
              }

              if (label === JobDetailsLabels.END && job.actualEndAt) {
                value = (
                  <Text style={pdfStyles.actualTime}>
                    {format(
                      moment(job.actualEndAt).toDate(),
                      localization.formats.time.formats.dby_imsp
                    )}
                  </Text>
                );
              }

              return (
                <View style={pdfStyles.detailsRow}>
                  <Text style={pdfStyles.detailsLabel}>{t(`SmartJobs.PDF.${label}`)}</Text>
                  <Text style={pdfStyles.detailsValue}>{value}</Text>
                </View>
              );
            })}
          </View>
          {jobDestinationCoordinates && (
            <View style={pdfStyles.mapSection}>
              <Image
                src={`https://maps.googleapis.com/maps/api/staticmap?center=${jobDestinationCoordinates.lat},${jobDestinationCoordinates.lng}&zoom=15&size=400x400&markers=size:mid%7Ccolor:0x69c0ff%7Clabel:D%7C${jobDestinationCoordinates.lat},${jobDestinationCoordinates.lng}&key=${GOOGLE_MAPS_API_KEY}`}
                style={pdfStyles.mapImage}
              />
            </View>
          )}
        </View>
        <View style={pdfStyles.section}>
          <Text style={pdfStyles.sectionTitle}>{t('SmartJobs.Items')}</Text>
          <View style={pdfStyles.itemsHeader}>
            {ItemsTableColumns.map(column => (
              <Text style={pdfStyles.itemsHeaderColumn}>{column.title}</Text>
            ))}
          </View>
          {job?.jobItems?.length ? (
            <View pdfStyles={pdfStyles.itemsContainer}>
              {job?.jobItems.map(jobItem => {
                return (
                  <View style={pdfStyles.itemRow}>
                    {ItemsTableColumns.map(itemColumn => {
                      return renderItemData({
                        jobItem: jobItem,
                        columnKey: itemColumn.key,
                        pdfStyles: pdfStyles,
                        itemTypes: itemTypes,
                        localization: localization,
                        job: job,
                        t: t
                      });
                    })}
                  </View>
                );
              })}
            </View>
          ) : (
            <View pdfStyles={pdfStyles.noDataContainer}>
              <Image src={noData} style={pdfStyles.noDataImage} />
              <Text style={pdfStyles.noDataText}>{t('SmartJobs.PDF.NoData')}</Text>
            </View>
          )}
        </View>
        <View style={pdfStyles.section} wrap>
          <Text style={pdfStyles.sectionUnderlinedTitle}>{t('SmartJobs.Attachments')}</Text>
          <View style={pdfStyles.attachmentsWrapper}>
            {jobAttach?.jobAttachments?.length ? (
              jobAttach.jobAttachments
                .filter(attachment => attachment.type === 'PHOTO')
                .map(attachment => {
                  return (
                    <View style={pdfStyles.attachmentWrapper} key={attachment.id}>
                      <Image
                        source={{
                          uri: `${
                            attachment?.attachment?.mimeType.includes('png')
                              ? JOB_PNG_ATTACHMENT_PREFIX
                              : JOB_JPG_PDF_ATTACHMENT_PREFIX
                          }${attachment?.attachment?.content}`
                        }}
                        style={{ height: 282, width: 282 }}
                      />
                      <Text style={pdfStyles.attachmentText}>{attachment?.notes || ''}</Text>
                    </View>
                  );
                })
            ) : (
              <Text style={pdfStyles.noImagesAttached}>{t('SmartJobs.NoImagesAttached')}</Text>
            )}
          </View>
        </View>
        <View style={pdfStyles.section} wrap>
          <Text style={pdfStyles.sectionUnderlinedTitle}>{t('SmartJobs.Signature')}</Text>
          <View style={pdfStyles.detailsRow}>
            <Text style={pdfStyles.detailsLabel}>{t('SmartJobs.Name')}</Text>
            <Text style={pdfStyles.detailsValue}>{jobSignature?.notes || '-'}</Text>
          </View>
          <View style={pdfStyles.signatureRow}>
            <Text style={pdfStyles.detailsLabel}>{t('SmartJobs.Signature')}</Text>
            {jobSignature?.attachment?.content ? (
              <Image
                source={{
                  uri: `${
                    jobSignature?.attachment?.mimeType.includes('png')
                      ? JOB_PNG_ATTACHMENT_PREFIX
                      : JOB_JPG_PDF_ATTACHMENT_PREFIX
                  }${jobSignature?.attachment?.content}`
                }}
                style={{ height: 65, width: 65 }}
              />
            ) : (
              <Text style={pdfStyles.detailsValue}>-</Text>
            )}
          </View>
        </View>
        <View style={pdfStyles.footerWrapper} fixed>
          <Text style={pdfStyles.footerText} fixed>
            {t('SmartJobs.PublicPageFooterText')}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export const DownloadPdf = ({
  isLinkAvailable,
  job,
  jobAttach,
  jobSignature,
  jobDestinationCoordinates
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const itemTypes = useItemTypes();
  const [instance, update] = usePDF({
    document: (
      <PdfBody
        t={t}
        localization={localization}
        job={job}
        itemTypes={itemTypes}
        jobAttach={jobAttach}
        jobSignature={jobSignature}
        jobDestinationCoordinates={jobDestinationCoordinates}
      />
    )
  });

  useEffect(() => {
    update(
      <PdfBody
        t={t}
        localization={localization}
        job={job}
        itemTypes={itemTypes}
        jobAttach={jobAttach}
        jobSignature={jobSignature}
        jobDestinationCoordinates={jobDestinationCoordinates}
      />
    );
  }, [isLinkAvailable, job, itemTypes, jobAttach, jobSignature, jobDestinationCoordinates]);

  return (
    isLinkAvailable === PublicJobPageStatuses.AVAILABLE && (
      <Button
        type="primary"
        onClick={() => window.open(window.URL.createObjectURL(instance.blob), '_blank')}
      >
        {t('SmartJobs.DownloadPdf')}
      </Button>
    )
  );
};
