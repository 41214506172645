import { useDispatch, useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import request from 'superagent';
import i18n from 'i18next';

import { ToastType } from 'components/notifications/toasts/Toast';

import { openToast } from 'features/toasts/toastsSlice';
import { useCurrentCompanyKey } from 'features/company/companySlice';

import { Comparators } from 'utils/sorting';
import { API_PATH } from 'config';

const initialState = {
  list: [],
  meta: {
    error: null,
    lastFetched: null,
    isFetching: false,
    companyKey: null
  }
};

const massConfigurationsSlice = createSlice({
  name: 'massConfigurations',
  initialState: initialState,
  reducers: {
    fetchMassConfigurationsStart: state => {
      state.meta.isFetching = true;
    },
    fetchMassConfigurationsSucceeded: (state, { payload }) => {
      state.list = payload.list.sort(Comparators.String('name'));
      state.meta.error = null;
      state.meta.lastFetched = new Date().toISOString();
      state.meta.isFetching = false;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchMassConfigurationsFailed: (state, { payload }) => {
      state.list = [];
      state.meta.error = payload.error;
      state.meta.lastFetched = new Date().toISOString();
      state.meta.isFetching = false;
      state.meta.isListEmpty = true;
      state.meta.companyKey = payload.companyKey;
    },
    addMassConfigurationStart: state => {
      state.meta.isFetching = true;
    },
    addMassConfigurationSucceeded: state => {
      state.meta.isFetching = false;
    },
    addMassConfigurationFailed: (state, { payload }) => {
      state.meta.isFetching = false;
      state.meta.error = payload.error;
    },
    updateMassConfigurationStart: state => {
      state.meta.isFetching = true;
    },
    updateMassConfigurationSucceeded: state => {
      state.meta.isFetching = false;
    },
    updateMassConfigurationFailed: (state, { payload }) => {
      state.meta.isFetching = false;
      state.meta.error = payload.error;
    },
    deleteMassConfigurationStart: state => {
      state.meta.isFetching = true;
    },
    deleteMassConfigurationSucceeded: state => {
      state.meta.isFetching = false;
    },
    deleteMassConfigurationFailed: (state, { payload }) => {
      state.meta.isFetching = false;
      state.meta.error = payload.error;
    }
  }
});

const {
  fetchMassConfigurationsStart,
  fetchMassConfigurationsSucceeded,
  fetchMassConfigurationsFailed,
  addMassConfigurationStart,
  addMassConfigurationSucceeded,
  addMassConfigurationFailed,
  updateMassConfigurationStart,
  updateMassConfigurationSucceeded,
  updateMassConfigurationFailed,
  deleteMassConfigurationStart,
  deleteMassConfigurationSucceeded,
  deleteMassConfigurationFailed
} = massConfigurationsSlice.actions;

const convertKgToTonnes = (massConfigs, precision = 2) => {
  return massConfigs.map(massConfig => ({
    ...massConfig,
    massSchemes: massConfig.massSchemes.map(scheme => ({
      ...scheme,
      grossMass: parseFloat((scheme.grossMass / 1000).toFixed(precision)),
      drive: parseFloat((scheme.drive / 1000).toFixed(precision)),
      steer: parseFloat((scheme.steer / 1000).toFixed(precision)),
      group3: parseFloat((scheme.group3 / 1000).toFixed(precision)),
      group4: parseFloat((scheme.group4 / 1000).toFixed(precision)),
      group5: parseFloat((scheme.group5 / 1000).toFixed(precision)),
      group6: parseFloat((scheme.group6 / 1000).toFixed(precision)),
      group7: parseFloat((scheme.group7 / 1000).toFixed(precision)),
      group8: scheme.group8 ? parseFloat((scheme.group8 / 1000).toFixed(precision)) : 0
    }))
  }));
};

const convertTonnesToKg = massConfig => {
  return {
    ...massConfig,
    massSchemes: massConfig.massSchemes.map(scheme => ({
      ...scheme,
      grossMass: scheme.grossMass * 1000,
      drive: scheme.drive * 1000,
      steer: scheme.steer * 1000,
      group3: scheme.group3 * 1000,
      group4: scheme.group4 * 1000,
      group5: scheme.group5 * 1000,
      group6: scheme.group6 * 1000,
      group7: scheme.group7 * 1000,
      group8: scheme.group8 * 1000
    }))
  };
};

export const fetchMassConfigurations = () => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const companyId = getState().companies.current?.id;
  const companyKey = getState().companies.current.api_key;

  if (!userKey) {
    return;
  }

  dispatch(fetchMassConfigurationsStart());
  request('GET', `${API_PATH}/massmanagement/massschemeconfigs`)
    .query({
      embed: 'vehicles,fleets',
      ...(companyId && { company_id: companyId })
    })
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(res => {
      const massConfigs = convertKgToTonnes(res.body);
      dispatch(fetchMassConfigurationsSucceeded({ list: massConfigs, companyKey }));
    })
    .catch(error => {
      dispatch(fetchMassConfigurationsFailed({ error: error.toString(), companyKey }));
      console.error('fetchMassConfigurations', error);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${error}`
        })
      );
    });
};

export const addMassConfiguration = massConfiguration => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const companyId = getState().companies.current?.id;
  const massConfig = convertTonnesToKg(massConfiguration);

  if (!userKey) {
    return;
  }

  dispatch(addMassConfigurationStart());
  request('POST', `${API_PATH}/massmanagement/massschemeconfigs`)
    .query({
      ...(companyId && { company_id: companyId })
    })
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .send(massConfig)
    .then(res => {
      dispatch(addMassConfigurationSucceeded({ list: res.body }));
      dispatch(fetchMassConfigurations());
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('MassManagement.Message.Added', {
            config: i18n.t('MassManagement.MassConfiguration'),
            name: massConfiguration.name
          })
        })
      );
    })
    .catch(error => {
      const errorMsg = error.response?.body?.message || error.toString();
      dispatch(addMassConfigurationFailed({ error: errorMsg }));
      console.error('addMassConfiguration:', errorMsg);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('MassManagement.Message.AddError', {
            config: i18n.t('MassManagement.MassConfiguration'),
            name: massConfiguration.name,
            error: errorMsg
          })
        })
      );
    });
};

export const updateMassConfiguration = massConfiguration => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const massConfig = convertTonnesToKg(massConfiguration);

  if (!userKey) {
    return;
  }

  dispatch(updateMassConfigurationStart());
  request('PUT', `${API_PATH}/massmanagement/massschemeconfigs/${massConfiguration.id}`)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .send(massConfig)
    .then(res => {
      dispatch(updateMassConfigurationSucceeded({ list: res.body }));
      dispatch(fetchMassConfigurations());
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('MassManagement.Message.Updated', {
            config: i18n.t('MassManagement.MassConfiguration'),
            name: massConfiguration.name
          })
        })
      );
    })
    .catch(error => {
      const errorMsg = error.response?.body?.message || error.toString();
      dispatch(updateMassConfigurationFailed({ error: errorMsg }));
      console.error('updateMassConfiguration:', errorMsg);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('MassManagement.Message.UpdateError', {
            config: i18n.t('MassManagement.MassConfiguration'),
            name: massConfiguration.name,
            error: errorMsg
          })
        })
      );
    });
};

export const deleteMassConfiguration = massConfiguration => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;

  if (!userKey) {
    return;
  }

  dispatch(deleteMassConfigurationStart());
  request('DELETE', `${API_PATH}/massmanagement/massschemeconfigs/${massConfiguration.id}`)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(res => {
      dispatch(deleteMassConfigurationSucceeded({ list: res.body }));
      dispatch(fetchMassConfigurations());
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('MassManagement.Message.Deleted', {
            config: i18n.t('MassManagement.MassConfiguration'),
            name: massConfiguration.name
          })
        })
      );
    })
    .catch(error => {
      const errorMsg = error.response?.body?.message || error.toString();
      dispatch(deleteMassConfigurationFailed({ error: errorMsg }));
      console.error('deleteMassConfiguration:', errorMsg);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('MassManagement.Message.DeleteError', {
            config: i18n.t('MassManagement.MassConfiguration'),
            name: massConfiguration.name,
            error: errorMsg
          })
        })
      );
    });
};

export const useIsFetching = () => useSelector(state => state.massConfigurations.meta.isFetching);

export const useMassConfigurations = () => {
  const dispatch = useDispatch();
  const configurations = useSelector(state => state.massConfigurations.list);
  const isFetching = useSelector(state => state.massConfigurations.meta.isFetching);
  const lastFetched = useSelector(state => state.massConfigurations.meta.lastFetched);
  const lastCompanyKey = useSelector(state => state.massConfigurations.meta.companyKey);
  const isCompanyDifferent = useCurrentCompanyKey() !== lastCompanyKey;

  if (!isFetching && (!lastFetched || isCompanyDifferent)) {
    dispatch(fetchMassConfigurations());
  }

  return configurations;
};

export default massConfigurationsSlice.reducer;
