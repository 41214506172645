import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
//components
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import InfoRow from 'components/form/info-row/InfoRow';
import { ContactFormModal } from './ContactFormModal';
import { ToastType } from 'components/notifications/toasts/Toast';
//slices
import { useLocalization } from 'features/localization/localizationSlice';
import { useContacts } from 'features/contacts';
import { useSubCompanies } from 'features/company/companySlice';
import { useLocations, useDeletedLocations } from 'features/locations/locationsSlice';
//constants
import { PATHS, VIEW_PAGE_LABEL_WIDTH } from './constants';
//helpers and methods
import { setBackButton, setPageTitle, addBreadcrumbs } from 'features/page/pageSlice';
import { getLocationIDFromPathname, notificationConverter } from './helpers';
import { getIDFromPathname } from 'utils/methods';
import { format } from 'utils/dates';
import { openToast } from 'features/toasts/toastsSlice';
import { deleteContact, restoreContact } from 'features/contacts';
// styles
import styles from './ContactView.module.scss';
import { displayUSTimezone } from 'features/regions/timezones';

export const ContactView = () => {
  const { t } = useTranslation();
  const locationId = getLocationIDFromPathname(window.location.pathname);
  const id = getIDFromPathname(window.location.pathname);
  const dispatch = useDispatch();
  const localization = useLocalization();
  //variables
  const contacts = useContacts(locationId);
  const contactData = contacts?.find(wifi => wifi.id === parseInt(id, 10));
  const companies = useSubCompanies();
  const contactCompany = companies.find(company => company.id === contactData?.companyId);
  const enabledLocations = useLocations();
  const deletedLocations = useDeletedLocations();
  const [editContactData, setEditContactData] = useState(null);
  const [isAddContactOpen, setIsAddContactOpen] = useState(false);
  const locations =
    deletedLocations.length > 0 ? enabledLocations : enabledLocations.concat(deletedLocations);
  const contactLocation = locations?.find(location => location.id === parseInt(locationId, 10));
  const BREANDCRUMBS = [
    {
      path: '/settings/locations',
      breadcrumbName: t('Locations.LocationsTitle')
    },
    {
      path: `/settings/locations/id/${contactLocation?.id}`,
      breadcrumbName: `${t('Locations.Location')} ${contactLocation?.name || ''}`
    },
    {}
  ];

  useEffect(() => {
    dispatch(addBreadcrumbs(BREANDCRUMBS));
    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [contactLocation]);
  //methods on load
  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(contactData?.name));
  }, [contactData?.name, dispatch]);

  const handleOpenEditContactForm = () => {
    setEditContactData(contactData);
    setIsAddContactOpen(true);
  };

  const closeContactForm = () => {
    setEditContactData(null);
    setIsAddContactOpen(false);
  };

  const handleButtonAction = action => async () => {
    try {
      const deleteResponse = await dispatch(
        action === 'delete'
          ? deleteContact(contactData.id)
          : restoreContact({ id: contactData.id, locationId: contactData.entityId })
      );
      unwrapResult(deleteResponse);

      dispatch(
        openToast({
          type: ToastType.Success,
          message: `${t(`Locations.Contacts.${action}Success`)} ${contactData.name}!`
        })
      );
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: err?.message
        })
      );
    }
  };

  return (
    <>
      <ViewHeaderWrapper
        data={contactData}
        editPath={`${PATHS.EDIT_CONTACT}/${id}`}
        handleEditAction={handleOpenEditContactForm}
        canUse="CONTACT"
        handleButtonAction={handleButtonAction}
      />
      <ContactFormModal
        company={contactCompany}
        isOpen={isAddContactOpen}
        onCancel={closeContactForm}
        editContactData={editContactData}
      />
      <div className={styles.contactViewContainer}>
        <InfoRow
          label={t('WiFi.ViewPage.Company')}
          value={contactCompany?.name || '-'}
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('Locations.Location')}
          value={
            contactLocation?.name && (
              <Link to={`/settings/locations/id/${contactLocation?.id}`}>
                {contactLocation?.name}
              </Link>
            )
          }
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('Locations.Contacts.Table.Columns.email')}
          value={contactData?.email || '-'}
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('Locations.Contacts.Table.Columns.emailNotification')}
          value={
            contactData?.emailNotification
              ? notificationConverter(contactData?.emailNotification, t)
              : '-'
          }
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('Locations.Contacts.Table.Columns.mobile')}
          value={contactData?.mobile || '-'}
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('Locations.Contacts.Table.Columns.mobileNotification')}
          value={
            contactData?.mobileNotification
              ? notificationConverter(contactData?.mobileNotification, t)
              : '-'
          }
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('Locations.Contacts.Table.Columns.timeZone')}
          value={displayUSTimezone(contactData?.timeZone) || '-'}
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('WiFi.ViewPage.CreatedAt')}
          value={
            (contactData?.createdAt &&
              format(
                new Date(contactData?.createdAt),
                localization.formats.time.formats.dby_imp
              )) ||
            ''
          }
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
        <InfoRow
          label={t('WiFi.ViewPage.UpdatedAt')}
          value={
            (contactData?.updatedAt &&
              format(
                new Date(contactData?.updatedAt),
                localization.formats.time.formats.dby_imp
              )) ||
            ''
          }
          styles={styles}
          labelWidth={VIEW_PAGE_LABEL_WIDTH}
        />
      </div>
    </>
  );
};
