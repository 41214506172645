import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const appStateSlice = createSlice({
  name: 'appState',
  initialState: {
    isWalkMeActive: false,
    useEmptyGoogleMapKey: false
  },
  reducers: {
    setWalkMeActive(state, { payload }) {
      state.isWalkMeActive = payload;
    },
    setUseEmptyGoogleMapKey(state, { payload }) {
      state.useEmptyGoogleMapKey = payload;
    }
  }
});

export const { setWalkMeActive, setUseEmptyGoogleMapKey } = appStateSlice.actions;

export const switchWalkMe = () => async (dispatch, getState) => {
  dispatch(setWalkMeActive(!getState().appState.isWalkMeActive));
};

export const useAppState = () => useSelector(state => state.appState);

export const useUseEmptyGoogleMapKey = () =>
  useSelector(state => state.appState.useEmptyGoogleMapKey);

export default appStateSlice.reducer;
