/* eslint-disable */
import * as jQuery from 'jquery';
import moment from 'moment-timezone';
import * as d3 from 'd3/d3.min.js';

/**
 * Created by agough on 9/09/14. core
 */

/** TOOLTIPSY START */

/* tooltipsy by Brian Cray
 * Lincensed under GPL2 - http://www.gnu.org/licenses/gpl-2.0.html
 * Option quick reference:
 * - alignTo: "element" or "cursor" (Defaults to "element")
 * - offset: Tooltipsy distance from element or mouse cursor, dependent on alignTo setting. Set as array [x, y] (Defaults to [0, -1])
 * - content: HTML or text content of tooltip. Defaults to "" (empty string), which pulls content from target element's title attribute
 * - show: function(event, tooltip) to show the tooltip. Defaults to a show(100) effect
 * - hide: function(event, tooltip) to hide the tooltip. Defaults to a fadeOut(100) effect
 * - delay: A delay in milliseconds before showing a tooltip. Set to 0 for no delay. Defaults to 200
 * - css: object containing CSS properties and values. Defaults to {} to use stylesheet for styles
 * - className: DOM class for styling tooltips with CSS. Defaults to "tooltipsy"
 * - showEvent: Set a custom event to bind the show function. Defaults to mouseenter
 * - hideEvent: Set a custom event to bind the show function. Defaults to mouseleave
 * Method quick reference:
 * - $('element').data('tooltipsy').show(): Force the tooltip to show
 * - $('element').data('tooltipsy').hide(): Force the tooltip to hide
 * - $('element').data('tooltipsy').destroy(): Remove tooltip from DOM
 * More information visit http://tooltipsy.com/
 */
(function(a) {
  a.tooltipsy = function(c, b) {
    this.options = b;
    this.$el = a(c);
    this.title = this.$el.attr('title') || '';
    this.$el.attr('title', '');
    this.random = parseInt(Math.random() * 10000);
    this.ready = false;
    this.shown = false;
    this.width = 0;
    this.height = 0;
    this.delaytimer = null;
    this.$el.data('tooltipsy', this);
    this.init();
  };
  a.tooltipsy.prototype = {
    init: function() {
      var e = this,
        d,
        b = e.$el,
        c = b[0];
      e.settings = d = a.extend({}, e.defaults, e.options);
      d.delay = +d.delay;
      if (typeof d.content === 'function') {
        e.readify();
      }
      if (d.showEvent === d.hideEvent && d.showEvent === 'click') {
        b.toggle(
          function(f) {
            if (d.showEvent === 'click' && c.tagName == 'A') {
              f.preventDefault();
            }
            if (d.delay > 0) {
              e.delaytimer = window.setTimeout(function() {
                e.show(f);
              }, d.delay);
            } else {
              e.show(f);
            }
          },
          function(f) {
            if (d.showEvent === 'click' && c.tagName == 'A') {
              f.preventDefault();
            }
            window.clearTimeout(e.delaytimer);
            e.delaytimer = null;
            e.hide(f);
          }
        );
      } else {
        b.bind(d.showEvent, function(f) {
          if (d.showEvent === 'click' && c.tagName == 'A') {
            f.preventDefault();
          }
          e.delaytimer = window.setTimeout(function() {
            e.show(f);
          }, d.delay || 0);
        }).bind(d.hideEvent, function(f) {
          if (d.showEvent === 'click' && c.tagName == 'A') {
            f.preventDefault();
          }
          window.clearTimeout(e.delaytimer);
          e.delaytimer = null;
          e.hide(f);
        });
      }
    },
    show: function(i) {
      if (this.ready === false) {
        this.readify();
      }
      var b = this,
        f = b.settings,
        h = b.$tipsy,
        k = b.$el,
        d = k[0],
        g = b.offset(d);
      if (b.shown === false) {
        if (
          (function(m) {
            var l = 0,
              e;
            for (e in m) {
              if (m.hasOwnProperty(e)) {
                l++;
              }
            }
            return l;
          })(f.css) > 0
        ) {
          b.$tip.css(f.css);
        }
        b.width = h.outerWidth();
        b.height = h.outerHeight();
      }
      if (f.alignTo === 'cursor' && i) {
        var j = [i.clientX + f.offset[0], i.clientY + f.offset[1]];
        if (j[0] + b.width > a(window).width()) {
          var c = { top: j[1] + 'px', right: j[0] + 'px', left: 'auto' };
        } else {
          var c = { top: j[1] + 'px', left: j[0] + 'px', right: 'auto' };
        }
      } else {
        var j = [
          (function() {
            if (f.offset[0] < 0) {
              return g.left - Math.abs(f.offset[0]) - b.width;
            } else {
              if (f.offset[0] === 0) {
                return g.left - (b.width - k.outerWidth()) / 2;
              } else {
                return g.left + k.outerWidth() + f.offset[0];
              }
            }
          })(),
          (function() {
            if (f.offset[1] < 0) {
              return g.top - Math.abs(f.offset[1]) - b.height;
            } else {
              if (f.offset[1] === 0) {
                return g.top - (b.height - b.$el.outerHeight()) / 2;
              } else {
                return g.top + b.$el.outerHeight() + f.offset[1];
              }
            }
          })()
        ];
      }
      h.css({ top: j[1] + 'px', left: j[0] + 'px' });
      b.settings.show(i, h.stop(true, true));
    },
    hide: function(c) {
      var b = this;
      if (b.ready === false) {
        return;
      }
      if (c && c.relatedTarget === b.$tip[0]) {
        b.$tip.bind('mouseleave', function(d) {
          if (d.relatedTarget === b.$el[0]) {
            return;
          }
          b.settings.hide(d, b.$tipsy.stop(true, true));
        });
        return;
      }
      b.settings.hide(c, b.$tipsy.stop(true, true));
    },
    readify: function() {
      this.ready = true;
      this.$tipsy = a(
        '<div class="tooltipsy-wrapper" id="tooltipsy' +
          this.random +
          '" style="position:fixed;z-index:2147483647;display:none">'
      ).appendTo('body');
      this.$tip = a('<div class="' + this.settings.className + '">').appendTo(this.$tipsy);
      this.$tip.data('rootel', this.$el);
      var c = this.$el;
      var b = this.$tip;
      this.$tip.html(
        this.settings.content != ''
          ? typeof this.settings.content == 'string'
            ? this.settings.content
            : this.settings.content(c, b)
          : this.title
      );
    },
    offset: function(b) {
      return this.$el[0].getBoundingClientRect();
    },
    destroy: function() {
      if (this.$tipsy) {
        this.$tipsy.remove();
        a.removeData(this.$el, 'tooltipsy');
      }
    },
    defaults: {
      alignTo: 'element',
      offset: [0, -1],
      content: '',
      show: function(c, b) {
        b.fadeIn(100);
      },
      hide: function(c, b) {
        b.fadeOut(100);
      },
      css: {},
      className: 'tooltipsy',
      delay: 200,
      showEvent: 'mouseenter',
      hideEvent: 'mouseleave'
    }
  };
  a.fn.tooltipsy = function(b) {
    return this.each(function() {
      new a.tooltipsy(this, b);
    });
  };
})(jQuery);

/** TOOLTIPSY END */

/** HELPER FUNCTIONS START */

var sentinel = (function() {
  var ACTIONS = {
    Logon: { type: 'work' },
    Logoff: { type: 'rest' },
    StopWork: { type: 'rest' },
    StartRest: { type: 'rest' },
    StartWork: { type: 'work' },
    StopRest: { type: 'work' },
    EndRest: { type: 'work' },
    StartDrive: { type: 'drive' },
    StopDrive: { type: 'work' },
    work: { type: 'work' },
    drive: { type: 'drive' },
    rest: { type: 'rest' },
    Work: { type: 'work' },
    Drive: { type: 'drive' },
    Rest: { type: 'rest' }
  };

  // This function will transform '2015-01-11 00:00:00+10:00' to '2015-01-11 00:00:00+08:00' (i.e. keeps time the same)
  var copyInTz = function(now, tz) {
    var other = now.clone().tz(tz);
    other.add(now.utcOffset() - other.utcOffset(), 'minutes');

    return other;
  };

  var dateFormat = function(date, timezone) {
    if (!date) return '';

    if (typeof date === 'string') return date.replace('T', ' ').replace(':00.000', '');

    if (typeof date === 'number') {
      date = moment(date);
      if (moment.tz.zone(timezone) != null) date = date.tz(timezone);
    }

    return date.format('YYYY-MM-DD HH:mm:ss');
  };

  var customTimeFormat = function(timezone) {
    return timeFormat(
      [
        [
          timeFormatter('YYYY'),
          function() {
            return true;
          }
        ],
        [
          timeFormatter('MMM'),
          function(d) {
            return d.get('month');
          }
        ],
        [
          timeFormatter('D/M'),
          function(d) {
            return d.get('date') != 1;
          }
        ],
        [
          timeFormatter('D/M'),
          function(d) {
            return d.get('day') && d.get('date') != 1;
          }
        ],
        [
          timeFormatter('HH:00'),
          function(d) {
            return d.get('hours');
          }
        ],
        [
          timeFormatter('HH:mm'),
          function(d) {
            return d.get('minutes');
          }
        ],
        [
          timeFormatter('ss'),
          function(d) {
            return d.get('seconds');
          }
        ]
      ],
      timezone
    );
  };

  var calculateTimeTicks = function(min, max) {
    var hoursDiv = [3, 6, 12, 24];
    var diff = max - min;
    var maxSteps = 24;
    var stepSize = days(1); // default step size

    if (diff <= hours(25)) {
      stepSize = hours(1);
    } else {
      var found = false;

      // Is it divisible by hours ?
      for (var i = 0; i < hoursDiv.length; i++) {
        if (Math.ceil(diff / hours(hoursDiv[i])) <= maxSteps) {
          stepSize = hours(hoursDiv[i]);
          found = true;
          break;
        }
      }

      // Then divide by days
      if (!found) {
        // find out the least about
        var nDays = 1;
        while (Math.ceil(diff / days(nDays)) > maxSteps) {
          nDays = nDays + 1;
        }
        stepSize = days(nDays);
      }
    }

    var value = min;
    var values = [];

    while (value <= max) {
      values.push(value);
      if (stepSize < days(1))
        value = moment(value)
          .add(stepSize / hours(1), 'hours')
          .valueOf();
      else
        value = moment(value)
          .add(stepSize / days(1), 'days')
          .valueOf();
    }

    return values;
  };

  var isInfiniteViolation = function(v) {
    return (
      !v.hasOwnProperty('finish') ||
      v.finish == null ||
      v.finish == 0 ||
      v.finish - moment().valueOf() > days(350)
    );
  };

  var violationClass = function(show, v, now) {
    var cls = 'violation';
    if (v && show) {
      if (v.start) {
        if (v.start < now) cls += ' current';
        if (v.start > now) cls += ' predicted';
      }

      if (!isInfiniteViolation(v) && v.finish < now) cls += ' historical';
    }

    return cls;
  };

  var needToShowPredicted = function(from, to, checkpoint, events) {
    var isResting = false;
    if (events) {
      var lastEvent = events[events.length - 1];
      isResting = sentinel.isRest(lastEvent.action);
    }

    return !(checkpoint == null || checkpoint >= to || from - checkpoint > days(3) || isResting);
  };

  var days = function(n) {
    return n * 24 * 1000 * 60 * 60;
  };

  var hours = function(n) {
    return n * 1000 * 60 * 60;
  };

  var minutes = function(n) {
    return n * 1000 * 60;
  };

  var durFormat = function(t, short) {
    if (!t) return '-';

    var day = Math.floor(t / hours(24));
    var hrs = Math.floor((t % hours(24)) / hours(1));
    var min = Math.floor(((t % hours(24)) % hours(1)) / minutes(1));
    var secR = Math.floor(((t % hours(24)) % hours(1)) % minutes(1)) / 1000;

    var s = '';
    if (short) {
      // seconds not shown to avoid confusion with mm:ss
      //        if (day == 0 && hrs == 0 && min == 0 && secR > 0)
      //            s = pad0(secR, 2) + "s";
      if (day > 0) s = day + 'd ';

      if (hrs == 0 && min > 0) s = s + pad0(min, 2) + 'm';
      else if (hrs > 0 && min == 0) s = s + hrs + 'h';
      else {
        if (hrs > 0) s = s + hrs + ':';
        if (min > 0) s = s + pad0(min, 2);
      }
      if (s.length === 0) s = '00m';
    } else {
      //todo: 1 day(s), 1 hr(s)?
      if (day > 0) s = s + day + 'days ';
      if (hrs > 0) s = s + hrs + 'hrs ';
      if (min > 0) s = s + pad0(min, 2) + 'mins ';
      if (secR > 0) s = s + pad0(secR, 2) + 'secs';
      if (s.length === 0) s = '0secs';
    }

    if (s.substr(s.length - 1, 1) == ' ' || s.substr(s.length - 1, 1) == ':')
      return s.substr(0, s.length - 1);

    return s;
  };

  var apiFormat = function(date) {
    if (typeof date == 'string') return date;

    if (typeof date == 'number') date = new Date(date);

    return date.toISOString();
  };

  var restFormat = function(theDate) {
    var month = theDate.getMonth() < 9 ? '0' + (theDate.getMonth() + 1) : theDate.getMonth() + 1;
    var date = theDate.getDate() < 10 ? '0' + theDate.getDate() : theDate.getDate();

    return date + '-' + month + '-' + theDate.getFullYear();
  };

  var duration = function(t) {
    if (!t) return '-';

    var day = Math.floor(t / hours(24));
    var hrs = Math.floor((t % hours(24)) / hours(1));
    var min = Math.floor(((t % hours(24)) % hours(1)) / minutes(1));
    var secR = Math.floor(((t % hours(24)) % hours(1)) % minutes(1)) / 1000;

    var s = '';
    if (day > 0) s = s + day + 'd ';
    if (hrs > 0) s = s + hrs + 'h:';
    if (min > 0) s = s + pad0(min, 2) + 'm';
    if (secR > 0) s = s + pad0(secR, 2) + 's';

    if (s.length == 0) s = '0';

    if (s.substr(s.length - 1, 1) == ' ' || s.substr(s.length - 1, 1) == ':')
      return s.substr(0, s.length - 1);

    return s;
  };

  var dateFormat2 = function(date) {
    if (!date) return '';

    if (typeof date == 'string') return date.replace('T', ' ').replace(':00.000', '');

    if (typeof date == 'number') date = new Date(date);

    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return (
      pad0(date.getDate(), 2) +
      '/' +
      pad0(date.getMonth() + 1, 2) +
      '/' +
      date.getFullYear() +
      ' ' +
      pad0(hours, 2) +
      ':' +
      pad0(minutes, 2) +
      ampm
    );
  };

  var durFormat2 = function(t) {
    if (!t) return '-';

    var day = Math.floor(t / hours(24));
    var hrs = Math.floor((t % hours(24)) / hours(1));
    var min = Math.floor(((t % hours(24)) % hours(1)) / minutes(1));
    var secR = Math.floor((((t % hours(24)) % hours(1)) % minutes(1)) / 1000);

    var s = '';
    if (day > 0) hrs = hrs + day * 24;
    s = s + pad0(hrs, 2) + ':' + pad0(min, 2) + ':' + pad0(secR, 2);

    if (s.length === 0) s = '0';
    if (s.substr(s.length - 1, 1) == ' ' || s.substr(s.length - 1, 1) == ':')
      return s.substr(0, s.length - 1);

    return s;
  };

  function timeFormat(formats, timezone) {
    return function(date) {
      date = moment.tz(moment(date), timezone);
      var i = formats.length - 1,
        f = formats[i];
      while (!f[1](date)) f = formats[--i];
      return f[0](date);
    };
  }

  function timeFormatter(format) {
    return function(date) {
      return date.format(format);
    };
  }

  function pad0(s, n) {
    var s1 = '' + s;
    return s1.length < n ? '0'.repeat(Math.max(n - s1.length, -1)) + s1 : s1;
  }

  function isAction(act) {
    return ACTIONS.hasOwnProperty(act);
  }

  function isDrive(act) {
    return act !== null && ACTIONS.hasOwnProperty(act) && ACTIONS[act].type == 'drive';
  }

  function isWork(act) {
    return (
      act !== null &&
      ACTIONS.hasOwnProperty(act) &&
      (ACTIONS[act].type == 'work' || ACTIONS[act].type == 'drive')
    );
  }

  function isRest(act) {
    return act !== null && !isWork(act);
  }

  function eventType(act) {
    return ACTIONS.hasOwnProperty(act) ? ACTIONS[act].type : null;
  }

  return {
    copyInTz: copyInTz,
    dateFormat: dateFormat,
    dateFormat2: dateFormat2,
    customTimeFormat: customTimeFormat,
    calculateTimeTicks: calculateTimeTicks,
    isInfiniteViolation: isInfiniteViolation,
    violationClass: violationClass,
    needToShowPredicted: needToShowPredicted,
    days: days,
    hours: hours,
    minutes: minutes,
    durFormat: durFormat,
    durFormat2: durFormat2,
    apiFormat: apiFormat,
    restFormat: restFormat,
    duration: duration,
    isAction: isAction,
    isDrive: isDrive,
    isWork: isWork,
    isRest: isRest,
    eventType: eventType
  };
})();

/** HELPER FUNCTIONS END */

/** SENTINEL CORE START */

(function($) {
  var DEFAULT_TZ = 'UTC';

  String.prototype.repeat = function(num) {
    return new Array(num + 1).join(this);
  };

  function getUserDetails(senObj, id) {
    $.ajax({
      url: senObj.options.url + '/users/' + id,
      success: function(data, status, jqXHR) {
        senObj.$element.data('sentinel-user', data);

        //fire any callback
        if (senObj.options.on_user_details && typeof senObj.options.on_user_details == 'function')
          senObj.options.on_user_details(data);

        getUserEvents(senObj, id);
      },
      error: function(jqXHR, status, err) {
        senObj.$element.data('sentinel-user', '');
        console.log('Failed to retrieve details for user ' + id + ': ' + err);
      }
    });
  }

  function getUserEvents(senObj, id) {
    var f = senObj.from();
    var t = senObj.to();

    if (!f || !t) return;

    var params = '?c=1';
    if (f) params = params + '&fromDate=' + sentinel.apiFormat(f);
    if (t) params = params + '&toDate=' + sentinel.apiFormat(t);
    if (senObj.options.embed !== null && senObj.options.embed.length > 0)
      params = params + '&embed=' + senObj.options.embed;

    $.ajax({
      url: senObj.options.url + '/users/' + id + '/events' + (params.length > 4 ? params : ''),
      success: function(data, status, jqXHR) {
        var filtered = [];
        var vehicles = [];
        if (data) {
          // console.log("EVENT: Retrieved " + data.length + " events for user " + id + " between " + sentinel.apiFormat(f) + " and " + sentinel.apiFormat(t));

          // We actually want vehicle id's, not device id's.  It's unlikely that the device recording the event
          // is the same as the tracking device for the vehicle in question (although possible), so let's harvest the
          // vehicle identifiers, and have the service resolve the associated tracking device at which point we can
          // retrieve the associated position data
          var lastVehicle = { id: -1, start: f, finish: f };
          var lastEvent = null;
          //colours from http://ksrowell.com/blog-visualizing-data/2012/02/02/optimal-colors-for-graphs/
          var colours = [
            '#396AB1',
            '#DA7C30',
            '#3E9651',
            '#535154',
            '#CC2529',
            '#6B4C9A',
            '#922428',
            '#948B3D'
          ];
          var cidx = 0;

          $.each(data, function(i, v) {
            if (sentinel.isAction(v.action)) {
              filtered.push(v);
            }
            if (v.eventAt >= f && v.eventAt <= t) {
              if ('vehicle' in v) {
                if (lastVehicle.id < 0) {
                  lastVehicle.id = v.vehicle.id;
                } else if (lastVehicle.id !== v.vehicle.id && v.eventAt >= lastVehicle.start) {
                  lastVehicle['finish'] = v.eventAt;
                  lastVehicle['colour'] = colours[cidx++ % colours.length];
                  console.log(
                    'GPS: Added vehicle ' +
                      lastVehicle.id +
                      ' between ' +
                      moment(lastVehicle.start).format() +
                      ' and ' +
                      moment(lastVehicle.finish).format() +
                      ', with color ' +
                      lastVehicle.colour
                  );
                  vehicles.push(lastVehicle);
                  lastVehicle = { id: v.vehicle.id, start: v.eventAt, finish: v.eventAt };
                }
              }
            }
            lastEvent = v.id > 0 ? v : lastEvent;
          });

          if (lastVehicle.id > 0 && lastEvent != null && lastEvent.eventAt >= lastVehicle.start) {
            lastVehicle['finish'] = Math.max(t, lastEvent.eventAt);
            lastVehicle['colour'] = colours[cidx++ % colours.length];
            //console.log("GPS: Added device " + lastVehicle.id + " between " +
            //    moment(lastVehicle.start).format() + " and " + moment(lastVehicle.finish).format() + ", with color " + lastVehicle.colour);
            vehicles.push(lastVehicle);
          }

          if (senObj.options.on_events && typeof senObj.options.on_events == 'function')
            senObj.options.on_events(data);
        }
        senObj.$element.data('sentinel-events', filtered);
        var redraw = true;

        if (senObj.options.show_checkpoint) {
          redraw = false;
          getUserCheckpoint(senObj, id);
        }

        //clear the previous GPS data
        senObj.$element.data('sentinel-gps', []);
        if (senObj.options.show_speed && vehicles.length > 0) {
          if (t - f < sentinel.hours(25)) {
            $.each(vehicles, function(i, v) {
              redraw = false;
              getGps(senObj, v, id);
            });
          } else console.log('Not retrieving GPS as the view is > 1 day');
        }
        if (redraw) senObj.redraw();
      },
      error: function(jqXHR, status, err) {
        console.log('Failed to retrieve events for user ' + id + ': ' + err);
      }
    });
  }

  function getUserStatus(senObj, id, ruleset) {
    var f = senObj.from();
    var t = senObj.to();
    if (!f || !t || !id || !senObj) return;

    var params = '?c=1&persist=false';
    //if( f ) params = params + "&fromDate=" + sentinel.apiFormat( f );
    //if( t ) params = params + "&toDate=" + sentinel.apiFormat( t );
    if (ruleset) params = params + '&ruleset=' + ruleset;

    $.ajax({
      url: senObj.options.url + '/users/' + id + '/status' + (params.length > 4 ? params : ''),
      success: function(data, status, jqXHR) {
        console.log('Retrieved status report for user ' + id);
        senObj.$element.data('sentinel-status', data);

        if (senObj.options.on_status && typeof senObj.options.on_status == 'function') {
          senObj.options.on_status(data);
        }
        senObj.redraw();
      },
      error: function(jqXHR, status, err) {
        console.log('Failed to retrieve status for user ' + id + ': ' + err);
      }
    });
  }

  function getViolations(senObj, id, ruleset) {
    var f = senObj.from();
    var t = senObj.to();
    var c = senObj.checkpoint();

    if (!f || !t || !id || !ruleset || !senObj) return;

    var params = '?userId=' + id;
    if (f) params = params + '&fromDate=' + sentinel.apiFormat(f);
    if (t) params = params + '&toDate=' + sentinel.apiFormat(t);
    if (c && f) params = params + '&checkpointDate=' + sentinel.apiFormat(c);

    if (senObj.options.show_rests) params = params + '&incRests=true';
    if (senObj.options.show_trace) params = params + '&incTrace=true';
    if (senObj.options.show_predicted) {
      if (
        senObj.checkpoint() &&
        senObj.events() &&
        sentinel.isWork(senObj.events()[senObj.events().length - 1].action)
      ) {
        params = params + '&predicted=true';
      }
    }

    params = params + '&incTotals=true';

    $.ajax({
      url: senObj.options.url + '/' + ruleset + (params.length > 4 ? params : ''),
      success: function(data, status, jqXHR) {
        if (data) {
          if (data.violations && data.violations.length > 0) {
            data.violations = data.violations.sort(function(a, b) {
              return a.start - b.start;
            });
          }
        }
        senObj.$element.data('sentinel-violations', data);

        if (senObj.options.on_violations && typeof senObj.options.on_violations == 'function') {
          senObj.options.on_violations(data);
        }
        senObj.redraw();
      },
      error: function(jqXHR, status, err) {
        console.log('Failed to retrieve violations for user ' + id + ': ' + err);
      }
    });
  }

  function getUserCheckpoint(senObj, id) {
    if (!id || !senObj) return;

    $.ajax({
      url: senObj.options.url + '/users/' + id + '/checkpoint',
      success: function(data, status, jqXHR) {
        if (typeof data === 'undefined' || data === null) {
          console.log('No checkpoint for user: ' + id);
          senObj.$element.data('sentinel-checkpoint', null);
        } else {
          //NB: The '/checkpoint' API now just retruns the timestamp or NULL
          console.log('Successfully obtained checkpoint for user ' + id + ' at: ' + data);
          senObj.$element.data('sentinel-checkpoint', data);
          senObj.redraw();
        }
        if (senObj.options.show_violations && id) {
          var ruleset = senObj.$element.data('sentinel-ruleset');
          if (ruleset) {
            getViolations(senObj, id, ruleset);
          }
        }
        if (senObj.options.on_checkpoint && typeof senObj.options.on_checkpoint == 'function')
          senObj.options.on_checkpoint(data);
      },
      error: function(jqXHR, status, err) {
        console.log('Failed to retrieve checkpoint for user ' + id + ': ' + err);
      }
    });
  }

  function getGps(senObj, vehicle, userId) {
    var params = '?c=1';
    params = params + '&from=' + sentinel.apiFormat(vehicle.start);
    params = params + '&to=' + sentinel.apiFormat(vehicle.finish);
    params = params + '&userId=' + userId;

    $.ajax({
      url:
        senObj.options.url + '/vehicles/' + vehicle.id + '/pr' + (params.length > 4 ? params : ''),
      success: function(data, status, jqXHR) {
        //we need to append these GPS coordinates to the gps array rather than replace
        var gpsArr = senObj.$element.data('sentinel-gps') || [];
        if (data) {
          gpsArr.push({ vehicle: vehicle, data: data });
          console.log(
            'GPS: Retrieved ' +
              data.length +
              ' GPS for vehicle ' +
              vehicle.id +
              ' from ' +
              sentinel.apiFormat(vehicle.start) +
              ' to ' +
              sentinel.apiFormat(vehicle.finish) +
              '. There are now ' +
              gpsArr.length +
              ' results in the array'
          );
          if (senObj.options.on_gps && typeof senObj.options.on_gps == 'function')
            senObj.options.on_gps(data);
        }
        senObj.$element.data('sentinel-gps', gpsArr);
        senObj.redraw();
      },
      error: function(jqXHR, status, err) {
        console.log('GPS: Failed to retrieve GPS for vehicle ' + vehicle.id + ': ' + err);
      }
    });
  }

  function Sentinel(element, options) {
    this.$element = $(element);
    this.options = options;
    this.enabled = true;
    this.chart = sentinelChart(element, options);
  }

  Sentinel.prototype = {
    user: function(id) {
      if (!id) return this.$element.data('sentinel-userid');

      this.$element.data('sentinel-userid', id);
      this.clear();
      getUserDetails(this, id);
      return this;
    },
    period: function(from, to, internal) {
      // When setting, assumes from and to is in local (browser) timezone so ENSURE this is the case.
      if (from) this.$element.data('sentinel-from', from);

      if (to) this.$element.data('sentinel-to', to);

      var user = this.$element.data('sentinel-user');
      var userid = this.$element.data('sentinel-userid');
      var ruleset = this.$element.data('sentinel-ruleset');

      //clear current data
      this.clear();

      if ((user || userid) && from && to) {
        getUserEvents(this, user ? user.id : userid);

        if (this.options.show_violations && user && ruleset) {
          getViolations(this, user.id, ruleset);
          if (this.options.show_status) getUserStatus(this, user.id, ruleset);
        }
      }

      //only fire if this change occurred due to an internal control UI change
      if (this.options.on_period && internal && typeof this.options.on_period == 'function')
        this.options.on_period(from, to);

      return this;
    },
    ruleset: function(rs) {
      if (!rs) return this.$element.data('sentinel-ruleset');

      if (rs !== this.$element.data('sentinel-ruleset')) this.options.show_violations = true;

      this.$element.data('sentinel-ruleset', rs);

      var userId = this.$element.data('sentinel-userid');
      if (this.options.show_violations && userId && rs) {
        getViolations(this, userId, rs);
        if (this.options.show_status) getUserStatus(this, userId, rs);
      }
    },
    redraw: function() {
      var user = this.$element.data('sentinel-user');
      var from = this.from();
      var to = this.to();

      //console.log("From: " + from + ", To: " + to);

      var userEvents = this.events();
      var userViolations = this.$element.data('sentinel-violations');
      var userCheckpoint = this.$element.data('sentinel-checkpoint');
      var userStatus = this.$element.data('sentinel-status');
      var gps = this.$element.data('sentinel-gps');

      if (user && (userEvents || userViolations))
        this.chart({
          user: user,
          events: userEvents,
          violations: userViolations,
          checkpoint: userCheckpoint,
          status: userStatus,
          gps: gps,
          fromDate: from,
          toDate: to
        });

      return this;
    },
    reload: function() {
      var userid = this.$element.data('sentinel-userid');
      if (userid) this.user(userid);
      var ruleset = this.$element.data('sentinel-ruleset');
      if (ruleset) this.ruleset(ruleset);

      return this;
    },
    clear: function() {
      //clear current data
      this.$element.data('sentinel-gps', null);
      this.$element.data('sentinel-events', null);
      this.$element.data('sentinel-violations', null);
      //this.$element.data("sentinel-checkpoint", null);
      //this.$element.data("sentinel-ruleset", null);
    },
    clearRuleset: function() {
      if (this.$element.data('sentinel-ruleset') != null) {
        this.$element.data('sentinel-ruleset', null);
        this.$element.data('sentinel-violations', null);
        this.$element.data('sentinel-checkpoint', null);
        this.redraw();
      }

      return this;
    },
    options: function() {
      return this.options;
    },
    removeChart: function() {
      this.clear();

      //remove the graph data
      this.$element.children().remove();
    },
    events: function(arg) {
      if (!arg) return this.$element.data('sentinel-events');

      this.$element.data('sentinel-events', arg);
    },
    checkpoint: function(arg) {
      if (!arg) return this.$element.data('sentinel-checkpoint');

      this.$element.data('sentinel-checkpoint', arg);
    },
    violations: function(arg) {
      if (!arg) return this.$element.data('sentinel-violations');

      this.$element.data('sentinel-violations', arg);
    },
    userData: function(arg) {
      if (!arg) return this.$element.data('sentinel-user');

      this.$element.data('sentinel-user', arg);
    },
    from: function(arg) {
      if (!arg) {
        //var user = this.$element.data("sentinel-user");
        var timezone = this.timezone();
        var internalFrom = this.$element.data('sentinel-from');

        return sentinel.copyInTz(moment(internalFrom), timezone).valueOf();
      }

      this.$element.data('sentinel-from', arg);
    },
    to: function(arg) {
      if (!arg) {
        //var user = this.$element.data("sentinel-user");
        var timezone = this.timezone();
        var internalTo = this.$element.data('sentinel-to');

        return sentinel.copyInTz(moment(internalTo), timezone).valueOf();
      }
      this.$element.data('sentinel-to', arg);
    },
    timezone: function() {
      var tz = this.userData() == null ? this.options.timezone : this.userData().timeZone;

      if (!this.isSupportedTimezone(tz)) tz = DEFAULT_TZ;

      return tz;
    },
    isSupportedTimezone: function(tz) {
      if (tz == null || typeof tz === 'undefined') return false;

      return moment.tz.zone(tz) != null;
    },
    gps: function(g) {
      if (!g) {
        return this.$element.data('sentinel-gps');
      }
      this.$element.data('sentinel-gps', g);
    }
  };

  //usage: $('#svg_element').sentinel({ user: 123 });
  $.fn.sentinel = function(options) {
    if (this.length > 1) {
      console.error(
        'Cannot process multiple sentinel selections simultaneously.  Use a selector to match an individual element'
      );
      return null;
    }

    var sentinel = $.data(this[0], 'sentinel');
    if (sentinel) return sentinel;

    options = $.extend({}, $.fn.sentinel.defaults, options);

    sentinel = new Sentinel(this[0], options);
    $.data(this[0], 'sentinel', sentinel);
    return sentinel;
  };

  $.fn.sentinel.defaults = {
    url: '/ng/sentinel',
    embed: null,
    show_driver: true, //display driver name
    show_rests: true, //request & show rest periods
    show_nights: true, //request & show night rests
    show_totals: false, //display totals
    show_trace: false, //request (& show) rule execution trace
    show_control: true, //show date period control
    show_violations: false, //show/calculate violations
    show_predicted: false, //show/calculate predicted violations also
    show_checkpoint: false, //show checkpoint marker on activity chart
    show_animation: true, // show chart animation
    show_tooltips: false, // show tooltips on activity chart
    show_dials: false, //show donut dials
    show_status: false, //show status info from '/status' endpoint
    show_titles: true, //show title data (work/rest)
    show_speed: false, //show PR speed graph

    on_user_details: null, //event callback fired after getUserDetails()
    on_events: null, //event callback fired after getUserEvents()
    on_violations: null, //event callback fired after getViolations()
    on_period: null, //event callback fired after user modifies control slider
    on_status: null, //event callback fired after getUserStatus()
    on_checkpoint: null, //event callback fired after getUserCheckpoint()
    on_gps: null, //event callback fired after getGps()

    num_ticks: 15, //approx. number of ticks to show on x-axis
    timezone: DEFAULT_TZ, // default timezone
    margins: { top: 7, bottom: 5, left: 5, right: 5 }
  };

  function sentinelChart(ele, options) {
    var margins = options.margins;

    //Get current sizes...
    var width = 1200 - margins.right - margins.left;
    var height = 200 - margins.bottom;

    function violationTitle(v, timezone) {
      var periodText;
      if (sentinel.isInfiniteViolation(v))
        periodText =
          '<br/>Violation from ' + sentinel.dateFormat(v.start, timezone) + ' indefinitely';
      else
        periodText =
          '<br/>Violation from ' +
          sentinel.dateFormat(v.start, timezone) +
          ' to ' +
          sentinel.dateFormat(v.finish, timezone) +
          ' (' +
          sentinel.durFormat(v.finish - v.start) +
          ')';

      if (v.period)
        periodText =
          periodText +
          '<br/>Occurred in ' +
          v.period.type +
          ' period between ' +
          sentinel.dateFormat(v.period.start, timezone) +
          ' and ' +
          sentinel.dateFormat(v.period.finish, timezone);

      return '<b>[' + v.rule.id + '] ' + v.rule.description + '</b>' + periodText;
    }

    function eventTitle(e1, e2, isLeadin, user, timezone) {
      var title = '';
      if (sentinel.isDrive(e1.action)) title = title + '<b>DRIVE</b> period';
      else if (sentinel.isWork(e1.action)) title = title + '<b>WORK</b> period';
      else title = title + '<b>REST</b> period';

      if (e1 && isLeadin === true && e2) {
        title = title + ' for ' + sentinel.durFormat(e2.eventAt - e1.eventAt);
        title = title + '<br/>Lead in at ' + sentinel.dateFormat(e1.eventAt, timezone);
      } else if (e2) {
        title = title + ' for ' + sentinel.durFormat(e2.eventAt - e1.eventAt);
        title =
          title + '<br/><b>' + e1.action + '</b> at ' + sentinel.dateFormat(e1.eventAt, timezone);
        title =
          title + '<br/><b>' + e2.action + '</b> at ' + sentinel.dateFormat(e2.eventAt, timezone);
      } else {
        title = title + '<br/>Lead out at ' + sentinel.dateFormat(e1.eventAt, timezone);
      }
      return title;
    }

    var minE, maxE;
    var statusView = 0;

    //this.chart({user: user, events: userEvents, violations: userViolations, checkpoint: userCheckpoint, fromDate: from, toDate: to});
    function chart(args) {
      // hide all tooltips when about to draw chart
      if ($.fn.tooltipsy && options.show_tooltips) $('.tooltipsy').hide();

      var user = args.user;
      var violations = args.violations;
      var events = args.events;
      var checkpoint = args.checkpoint;
      var status = args.status;
      var gps = args.gps;

      if (!user) return;

      // console.log("D3 version " + d3.version);
      var workChart = d3.select(ele);
      width = +workChart.attr('width');
      height = +workChart.attr('height');

      var adjLeft = options.show_titles ? margins.left + 105 : margins.left;
      var xscale = d3.scale.linear().rangeRound([adjLeft, width - margins.right]);

      workChart.selectAll('*').remove();

      var defs = workChart.append('defs');
      defs
        .append('pattern')
        .attr({
          id: 'pattern-stripe',
          patternUnits: 'userSpaceOnUse',
          width: 4,
          height: 4,
          patternTransform: 'rotate(45)'
        })
        .append('rect')
        .attr({ width: 2, height: 4, fill: 'white' });
      defs
        .append('mask')
        .attr('id', 'mask-stripe')
        .append('rect')
        .attr({
          x: 0,
          y: 0,
          width: '100%',
          height: '100%',
          fill: 'url(#pattern-stripe)'
        });
      defs
        .append('pattern')
        .attr({
          id: 'pattern-stars',
          patternUnits: 'userSpaceOnUse',
          width: 16,
          height: 16
        })
        .append('circle')
        .attr({
          cx: 8,
          cy: 8,
          r: 10,
          style: 'stroke: none; fill: #8C92AC; fill-opacity: .6'
        });

      var chart = workChart.append('g').attr('class', 'sentinel');
      var gutter_height = 29; //amount of space below activity events for violation period info (or other stuff)
      var control_height = 19;
      var speed_height = 47 + (options.show_control ? 0 : 10);
      var name_height = 28;
      var markerRadius = 9;

      var dial_height = options.show_dials ? height / 2.9 - margins.top - control_height - 3 : 0;

      var adjTop =
        margins.top +
        (options.show_driver ? name_height : markerRadius * 2) +
        dial_height +
        control_height;
      var adjBottom =
        height -
        (options.show_control ? control_height : 0) -
        (options.show_speed ? speed_height : 0) -
        margins.bottom -
        40;
      var adjHeight = adjBottom - adjTop;
      var adjWidth = width - margins.right;
      var adjStatus = options.show_status ? 50 : 0;
      var bar_height = adjHeight / 5;

      dial_height = Math.min(dial_height, adjWidth / 4);
      dial_height = Math.max(dial_height, 115);

      var titles = chart.append('g').attr({ class: 'sentinel-header' });
      var userName;
      if (options.show_driver) {
        var tName = titles.append('g').attr('class', 'driver');
        userName = tName.append('text');
        userName.attr({ x: 10, y: margins.top, dy: '1em' }).text('Driver');
      }

      var statusTab;
      var statusPage;

      function updateTab() {
        statusTab.selectAll('*>*').remove();

        var tab_height = Math.min(90, dial_height);
        var tab_middle = (adjTop - control_height + driverAdj) / 2;

        var dx = statusView == 0 ? 6 : 37;
        var dy = statusView == 0 ? tab_middle + 25 : tab_middle - 25;

        statusTab.append('rect').attr({
          x: 1,
          y: tab_middle - tab_height / 2,
          width: 44,
          height: tab_height
        });
        statusTab.append('line').attr({
          x1: 2,
          y1: tab_middle - tab_height / 2,
          x2: 45,
          y2: tab_middle - tab_height / 2
        });
        statusTab.append('line').attr({
          x1: statusView == 0 ? 45 : 2,
          y1: tab_middle - tab_height / 2,
          x2: statusView == 0 ? 45 : 2,
          y2: tab_middle + tab_height / 2
        });
        statusTab.append('line').attr({
          x1: 2,
          y1: tab_middle + tab_height / 2,
          x2: 45,
          y2: tab_middle + tab_height / 2
        });

        statusTab
          .append('text')
          .attr({
            x: dx,
            y: dy,
            dy: '1em',
            transform: 'rotate(' + (statusView == 0 ? 270 : 90) + ',' + dx + ',' + dy + ')'
          })
          .text(statusView == 0 ? 'Status' : 'Totals');

        statusTab
          .append('text')
          .attr({
            x: statusView == 0 ? 30 : 6,
            y: tab_middle - tab_height / 3,
            dy: '1em',
            class: 'smaller'
          })
          .text(statusView == 0 ? '\u00bb' : '\u00ab');
        statusTab
          .append('text')
          .attr({
            x: statusView == 0 ? 30 : 6,
            y: tab_middle + tab_height / 3 - 20,
            dy: '1em',
            class: 'smaller'
          })
          .text(statusView == 0 ? '\u00bb' : '\u00ab');
      }

      //STATUS/DIAL TAB
      var driverAdj = 0;
      if (options.show_status && options.show_dials) {
        driverAdj = options.show_driver ? name_height / 2 : 0;
        statusTab = titles.append('g').attr('class', 'status-tab');
        statusView = 0;
        updateTab();
        statusTab.on('click', function() {
          var zeroState = 'translate(0,0)';
          var posState = 'translate(' + (adjWidth - 50) + ',0)';
          var negState = 'translate(-' + (adjWidth - 50) + ',0)';

          if (titles)
            titles
              .transition()
              .duration(1000)
              .ease('quad-in-out')
              .attrTween('transform', function(d) {
                return statusView == 0
                  ? d3.interpolateString(posState, zeroState)
                  : d3.interpolateString(zeroState, posState);
              });
          statusView = statusView == 0 ? 1 : 0;
          updateTab();
          if (statusPage)
            statusPage
              .transition()
              .duration(1000)
              .ease('quad-in-out')
              .attrTween('transform', function(d) {
                return statusView == 0
                  ? d3.interpolateString(zeroState, negState)
                  : d3.interpolateString(negState, zeroState);
              });
        });
      }

      //STATUS TABLE
      if (options.show_status && status) {
        statusPage = chart.append('g').attr({
          transform: 'translate(-' + (adjWidth - 50) + ',0)',
          class: 'sentinel-status'
        });
        var table = statusPage.append('g').attr('class', 'status-table');
        table.append('rect').attr({ x: 2, y: 3, width: 860, height: 38 });
        table
          .append('text')
          .attr({ x: 8, y: 5, dy: '1em', class: 'header' })
          .text('Current Fatigue Status');
        table
          .append('text')
          .attr({ x: 8, y: 23, dy: '1em', class: 'header2' })
          .text('Periods start at the end of a relevant rest break');
        var c1w = 150;
        var c2w = 130;
        var ch = 23;
        var addCell = function(label, value, row, col, clz) {
          clz = clz || '';
          clz = clz + (row % 2 == 0 ? ' even' : ' odd');
          var ldx = 3 + col * (c1w + c2w + 7);
          var ldy = 40 + row * (ch + 2);

          table.append('rect').attr({
            x: ldx,
            y: ldy + 4,
            width: '' + c1w,
            height: ch,
            class: clz
          });
          table
            .append('text')
            .attr({ x: ldx + 4, y: ldy + 8, dy: '1em', class: 'label ' + clz })
            .text(label);
          table.append('rect').attr({
            x: ldx + c1w + 2,
            y: ldy + 4,
            width: '' + c2w,
            height: ch,
            class: clz
          });
          table
            .append('text')
            .attr({
              x: ldx + c1w + 6,
              y: ldy + 8,
              dy: '1em',
              class: 'value ' + clz
            })
            .text(value);
        };

        addCell(
          'Hours worked',
          status['totals'] && 'work' in status['totals']
            ? sentinel.durFormat2(status['totals'].work * 1000)
            : '-',
          0,
          0
        );
        addCell(
          'Hours available',
          status['totals'] && 'available' in status['totals']
            ? sentinel.durFormat2(status['totals'].available * 1000)
            : '-',
          1,
          0
        );
        if (
          status['longNightTotals'] &&
          'excess' in status['longNightTotals'] &&
          'night' in status['longNightTotals']
        )
          addCell(
            'Long/night worked',
            sentinel.durFormat2(
              (status['longNightTotals'].excess + status['longNightTotals'].night) * 1000
            ),
            2,
            0
          );

        addCell(
          'Last 24hr rest',
          status['24HrRest'] && status['24HrRest'].lastFinishAt
            ? sentinel.dateFormat2(status['24HrRest'].lastFinishAt)
            : '-',
          0,
          1
        );
        addCell(
          '24hr rest due',
          status['24HrRest'] && status['24HrRest'].nextStartAt
            ? sentinel.dateFormat2(status['24HrRest'].nextStartAt)
            : '-',
          1,
          1
        );
        if (status['84HrRule'] && 'work' in status['84HrRule'])
          addCell(
            'Work since 24hr rest',
            sentinel.durFormat2(status['84HrRule'].work * 1000),
            2,
            1
          );

        addCell(
          'Night rests',
          status['nightRest'] && status['nightRest'].count ? '' + status['nightRest'].count : '-',
          0,
          2
        );
        addCell(
          'Night rest due',
          status['nightRest'] && status['nightRest'].nextStartAt
            ? sentinel.dateFormat2(status['nightRest'].nextStartAt)
            : '-',
          1,
          2
        );
      }

      //DIALS
      if (options.show_dials && violations) {
        var period = args.toDate - args.fromDate;
        var tmp = violations.totals || {
          work: 0,
          worked: 0,
          rest: 0,
          rested: 0,
          complied: 0,
          infringed: 0,
          available: 0
        };
        var totals = {};
        $.each(tmp, function(name, val) {
          totals[name] = tmp[name] * 1000;
        });
        var dialData = {
          'work-dial': {
            maxScale: period,
            paths: [
              { startVal: 0, endVal: totals.work, color: '#8cc641' },
              {
                startVal: totals.work,
                endVal: totals.worked,
                color: '#228DB2'
              },
              { startVal: totals.worked, endVal: period, color: '#bcc3cd' }
            ],
            labels: [
              { fontWeight: 'bold', fontSize: '16px', dy: '0', text: 'Work' },
              { fontWeight: '', fontSize: '11px', dy: '13', text: 'Total Time' }
            ],
            tooltip:
              (totals.worked ? sentinel.durFormat2(totals.worked) : 'No time') +
              ' spent working<br>' +
              (totals.worked - totals.work > 0
                ? sentinel.durFormat2(totals.worked - totals.work)
                : 'No') +
              ' unallocated rest'
          },
          'rest-dial': {
            maxScale: period,
            paths: [
              {
                startVal: 0,
                endVal: totals.rested == 0 ? period : totals.rested,
                color: '#228DB2'
              },
              {
                startVal: totals.rested == 0 ? period : totals.rested,
                endVal: period,
                color: '#bcc3cd'
              }
            ],
            labels: [
              { fontWeight: 'bold', fontSize: '16px', dy: '0', text: 'Rest' },
              { fontWeight: '', fontSize: '11px', dy: '13', text: 'Total Time' }
            ],
            tooltip:
              (totals.rested > 0 ? sentinel.durFormat2(totals.rested) : 'No time') +
              ' spent resting'
          },
          'violation-dial': {
            maxScale: period,
            paths: [
              { startVal: 0, endVal: totals.infringed, color: '#c72e33' },
              {
                startVal: totals.infringed,
                endVal: period,
                color: '#bcc3cd'
              }
            ],
            labels: [
              {
                fontWeight: 'bold',
                fontSize: '16px',
                dy: '0',
                text: 'Violation'
              },
              { fontWeight: '', fontSize: '11px', dy: '13', text: 'Work Time' }
            ],
            tooltip:
              (totals.infringed > 0 ? sentinel.durFormat2(totals.infringed) : 'No time') +
              ' spent working in violation'
          }
        };

        var id = 0;
        var dials = titles.append('g').attr('class', 'sentinel-dials');
        var tName = titles.append('g').attr('class', 'totals');
        var addLabel = function(label, value, ldx, ldy, clz) {
          clz = clz || '';
          tName
            .append('text')
            .attr({ x: ldx, y: ldy + 6, dy: '1em', class: 'label ' + clz })
            .text(label);
          tName
            .append('text')
            .attr({ x: ldx + 100, y: ldy, dy: '1em', class: 'value ' + clz })
            .text(value);
        };

        var lbl_middle = (adjTop - control_height + driverAdj) / 2;

        addLabel(
          'Worked',
          sentinel.durFormat2(totals.worked, true),
          20 + adjStatus,
          lbl_middle - 44,
          'work'
        );
        addLabel(
          'Rested',
          sentinel.durFormat2(totals.rested, true),
          20 + adjStatus,
          lbl_middle - 15,
          'rest'
        );
        addLabel(
          'Infringed',
          sentinel.durFormat2(totals.infringed, true),
          20 + adjStatus,
          lbl_middle + 14,
          'violated'
        );

        //vertical divider between work/rest/infringed & dials
        tName.append('line').attr({
          x1: adjWidth / 3 + adjStatus / 2,
          y1: margins.top + 15,
          x2: adjWidth / 3 + adjStatus / 2,
          y2: adjTop - 40
        });

        $.each(dialData, function(name, data) {
          id++;
          var dialScale = d3.scale
            .linear()
            .domain([0, data.maxScale])
            .range([0, 2 * Math.PI]);
          var dx = id * (adjWidth / 4) + id * 20;
          var dial_offset = adjWidth / 3;
          if (dial_offset > 0) dx = dial_offset + id * ((adjWidth - dial_offset) / 4) + id * 20;
          var dy = adjTop - dial_height / 2.0 - margins.top - control_height - 4;
          var arc = d3.svg
            .arc()
            .innerRadius(dial_height / 2.0 - dial_height / 6.0)
            .outerRadius(dial_height / 2.0)
            .startAngle(function(d) {
              return dialScale(d.startVal);
            })
            .endAngle(function(d) {
              return dialScale(d.endVal);
            });

          var dial = dials
            .append('g')
            .attr('class', 'sentinel-dial sentinel-tip')
            .attr('title', data.tooltip)
            .attr('id', name);

          dial
            .selectAll('path')
            .data(data.paths)
            .enter()
            .append('path')
            .attr('d', arc)
            .style('fill', function(d) {
              return d.color;
            })
            .attr('transform', 'translate(' + dx + ',' + dy + ')');

          dial
            .selectAll('text')
            .data(data.labels)
            .enter()
            .append('text')
            .attr('x', dx)
            .attr('y', dy)
            .attr('fill', '#404040')
            .attr('text-anchor', 'middle')
            .append('tspan')
            .attr('font-weight', function(d) {
              return d.fontWeight;
            })
            .attr('font-size', function(d) {
              return d.fontSize;
            })
            .attr('dy', function(d) {
              return d.dy;
            })
            .text(function(d) {
              return d.text;
            });
        });
      }

      //top horizontal bar over chart data
      chart
        .append('g')
        .attr('class', 'title')
        .append('rect')
        .attr({
          x: 2,
          y: adjTop - control_height,
          width: adjWidth,
          height: control_height - 1
        });

      //TITLES
      if (options.show_titles) {
        var tWork = chart.append('g').attr('class', 'title');
        var yWork = adjTop + adjHeight / 4 - bar_height / 2 - 3;

        tWork.append('rect').attr({ x: 2, y: adjTop, width: adjLeft - 3, height: adjHeight / 2 });

        /** This path is the "steering wheel" icon **/
        tWork
          .append('g')
          .append('path')
          .attr({
            d:
              'M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0zM16,4c5.207,0,9.605,3.354,11.266,8H4.734C6.395,7.354,10.793,4,16,4z M16,18c-1.105,0-2-0.895-2-2s0.895-2,2-2s2,0.895,2,2S17.105,18,16,18zM4,16c5.465,0,9.891,5.266,9.984,11.797C8.328,26.828,4,21.926,4,16zM18.016,27.797C18.109,21.266,22.535,16,28,16C28,21.926,23.672,26.828,18.016,27.797z',
            fill: '#78ce3f',
            transform: 'translate(8,' + yWork + ') scale(1.0)'
          });

        tWork
          .append('text')
          .attr({
            x: adjLeft - 60,
            y: yWork + (options.show_totals ? 0 : 5),
            dy: '1em'
          })
          .text('Work');

        var workTot = tWork.append('text');
        workTot.attr({
          x: adjLeft - 59,
          y: yWork + (options.show_totals ? 0 : 5) + 18,
          dy: '1em',
          class: 'title2'
        });

        var tRest = chart.append('g').attr('class', 'title');
        var yRest = adjTop + adjHeight / 2 + adjHeight / 4 - bar_height / 2 - 3;
        tRest.append('rect').attr({
          x: 2,
          y: adjTop + adjHeight / 2 + 1,
          width: adjLeft - 3,
          height: adjHeight / 2 - 1
        });

        /** This path is the "sleepy face" icon **/
        tRest
          .append('g')
          .append('path')
          .attr({
            d:
              'M15.516,90.547c10.341,10.342,23.927,15.513,37.513,15.512s27.172-5.173,37.517-15.517c20.686-20.684,20.684-54.341,0.002-75.024C80.202,5.174,66.615,0.001,53.029,0.001c-13.587,0-27.173,5.17-37.515,15.513C-5.173,36.199-5.171,69.858,15.516,90.547z M21.301,21.3c8.748-8.747,20.238-13.12,31.728-13.12s22.98,4.374,31.729,13.123c17.494,17.494,17.492,45.962-0.002,63.455c-8.747,8.746-20.237,13.12-31.728,13.121s-22.98-4.372-31.728-13.119c-2.188-2.187-4.101-4.546-5.741-7.032C4.078,60.317,5.993,36.608,21.301,21.3z M40.642,80.552H65.42c1.242,0,2.252,1.557,2.252,3.479c0,1.92-1.01,3.478-2.252,3.478H40.642c-1.244,0-2.253-1.558-2.253-3.478C38.389,82.108,39.398,80.552,40.642,80.552z M24.688,52.314c-1.212-1.134-1.274-3.035-0.142-4.247c1.132-1.213,3.018-1.291,4.247-0.143c3.251,3.052,6.589,0.241,6.959-0.089c1.105-0.99,2.741-1.011,3.867-0.119c0.133,0.105,0.259,0.224,0.376,0.354c1.106,1.236,1.001,3.136-0.235,4.244C37.096,54.698,30.552,57.797,24.688,52.314zM66.193,52.279c-1.212-1.134-1.273-3.035-0.142-4.247c1.132-1.213,3.018-1.291,4.247-0.143c3.251,3.052,6.589,0.241,6.959-0.089c1.104-0.99,2.741-1.011,3.867-0.119c0.133,0.105,0.259,0.224,0.376,0.354c1.105,1.236,1.001,3.136-0.235,4.244C78.602,54.663,72.058,57.762,66.193,52.279z',
            fill: '#6fabec',
            transform: 'translate(8 ' + yRest + ') scale(0.3)'
          });

        tRest
          .append('text')
          .attr({
            x: adjLeft - 60,
            y: yRest + (options.show_totals ? 0 : 5),
            dy: '1em'
          })
          .text('Rest');

        var restTot = tRest.append('text');
        restTot.attr({
          x: adjLeft - 59,
          y: yRest + (options.show_totals ? 0 : 5) + 18,
          dy: '1em',
          class: 'title2'
        });
      }

      //now select the dynamic, data driven part of the chart
      var ch = chart.append('g').attr('class', 'sentinel-data');

      if (options.show_driver && userName) userName.text(user.firstName + ' ' + user.lastName);

      var timezone = this.timezone();
      minE = args.fromDate ? moment.tz(args.fromDate, timezone).valueOf() : null;
      maxE = args.toDate ? moment.tz(args.toDate, timezone).valueOf() : null;

      xscale.domain([minE, maxE]);

      //TOTALS
      if (options.show_totals && options.show_titles) {
        if (violations && violations.totals) {
          workTot.text(
            sentinel.durFormat(
              (violations.totals.work + (violations.totals.rest - violations.totals.rested)) * 1000,
              true
            )
          );
          restTot.text(sentinel.durFormat(violations.totals.rested * 1000, true));
        } else {
          workTot.text('-');
          restTot.text('-');
        }
      }

      //VIOLATIONS
      //process the violations first so they're at a lower z-order
      if (violations) {
        var v_area = ch.selectAll('.violation').data(violations.violations);
        var now = checkpoint ? checkpoint : moment.tz(timezone).valueOf();

        var v_area1 = v_area
          .enter()
          .append('g')
          .attr({ class: 'violation' });

        var vrect = v_area1
          .append('rect')
          .attr({
            class: function(d, i) {
              return 'sentinel-tip ' + sentinel.violationClass(options.show_predicted, d, now);
            },
            id: function(d, i) {
              return 'violation-' + d.id;
            },
            x: function(d, i) {
              return xscale(Math.max(d.start, minE));
            },
            y: adjTop,
            width: function(d, i) {
              var actFinish = sentinel.isInfiniteViolation(d) ? maxE : d.finish;
              var w = xscale(Math.min(actFinish, maxE)) - xscale(Math.max(d.start, minE));
              return w < 0 ? 0 : w;
            },
            height: adjHeight,
            'data-violation-index': function(d, i) {
              return i;
            },
            title: function(d, i) {
              return violationTitle(d, timezone);
            }
          })
          .on('mouseover', function(d, i) {
            d3.select(ele)
              .selectAll("g.violation .period[data-violation-index='" + i + "']")
              .attr('visibility', 'visible');
          })
          .on('mouseout', function(d, i) {
            d3.select(ele)
              .selectAll('g.violation .period[data-violation-index]')
              .attr('visibility', 'hidden');
          });

        // Chrome 43 https://code.google.com/p/chromium/issues/detail?id=479548
        if (/chrome/.test(navigator.userAgent.toLowerCase())) {
          vrect.style('width', function(d, i) {
            var actFinish = sentinel.isInfiniteViolation(d) ? maxE : d.finish;
            var w = xscale(Math.min(actFinish, maxE)) - xscale(Math.max(d.start, minE));
            return w < 0 ? 0 : w;
          });
        }

        var ph = 12; //period triangle height
        v_area1.append('path').attr({
          d: function(d, i) {
            if (!d.period) return '';
            var x1 = xscale(d.period.start);
            var y1 = adjBottom + 8;
            return 'M' + x1 + ',' + y1 + 'V' + (y1 + ph) + 'H' + (x1 + ph) + 'z';
          },
          class: 'period',
          visibility: 'hidden',
          'data-violation-index': function(d, i) {
            return i;
          }
        });
        v_area1.append('path').attr({
          d: function(d, i) {
            if (!d.period) return '';
            var x1 = xscale(d.period.start);
            var x2 = xscale(d.period.finish);
            var y1 = adjBottom + 8;
            return 'M' + (x1 + ph) + ',' + (y1 + ph) + 'H' + (x2 - ph);
          },
          class: 'period',
          visibility: 'hidden',
          'data-violation-index': function(d, i) {
            return i;
          }
        });
        v_area1.append('path').attr({
          d: function(d, i) {
            if (!d.period) return '';
            var x2 = xscale(d.period.finish);
            var y1 = adjBottom + 8;
            return 'M' + x2 + ',' + y1 + 'V' + (y1 + ph) + 'H' + (x2 - ph) + 'z';
          },
          class: 'period',
          visibility: 'hidden',
          'data-violation-index': function(d, i) {
            return i;
          }
        });

        v_area1
          .append('text')
          .attr({
            x: function(d, i) {
              return d.period ? xscale(d.period.start) - 104 : 0;
            },
            y: adjBottom + gutter_height,
            class: 'period',
            visibility: 'hidden',
            'data-violation-index': function(d, i) {
              return i;
            }
          })
          .text(function(d, i) {
            return d.period ? sentinel.dateFormat(d.period.start, timezone) : '';
          });

        v_area1
          .append('text')
          .attr({
            x: function(d, i) {
              return d.period ? xscale(d.period.finish) : 0;
            },
            y: adjBottom + gutter_height,
            class: 'period',
            visibility: 'hidden',
            'data-violation-index': function(d, i) {
              return i;
            }
          })
          .text(function(d, i) {
            return d.period ? sentinel.dateFormat(d.period.finish, timezone) : '';
          });

        v_area.exit().remove();
      }

      //NIGHT RESTS
      //Night rest periods
      if (
        maxE - minE < sentinel.days(28) &&
        options.show_nights &&
        violations &&
        violations.rests &&
        violations.rests['night']
      ) {
        var rline3 = ch.selectAll('.rest.night').data(violations.rests['night']);

        rline3
          .enter()
          .append('g')
          .attr('class', 'rest night')
          .append('rect')
          .attr({
            x: function(d, i) {
              return Math.max(adjLeft, xscale(d.start));
            },
            y: adjTop,
            width: function(d, i) {
              return xscale(d.finish) - Math.max(adjLeft, xscale(d.start));
            },
            height: adjHeight,
            title: function(d, i) {
              if (d.finish - d.start >= sentinel.hours(7))
                return (
                  '<b>Night rest</b><br/>' +
                  sentinel.dateFormat(d.start, timezone) +
                  ' - ' +
                  sentinel.dateFormat(d.finish, timezone)
                );
              return (
                '<b>Night rest (<7h)</b><br/>' +
                sentinel.dateFormat(d.start, timezone) +
                ' - ' +
                sentinel.dateFormat(d.finish, timezone)
              );
            },
            class: 'sentinel-tip',
            id: function(d, i) {
              return 'nightrest-' + d.start;
            },
            style: function(d, i) {
              if (d.finish - d.start >= sentinel.hours(7)) return 'stroke: none; fill: #8C92AC;';
              return 'stroke: none; fill: #8C92AC; fill-opacity: 0.4';
            }
          });

        rline3.exit().remove();
      }

      var ticks = sentinel.calculateTimeTicks(minE, maxE);
      ch.append('g')
        .attr('class', 'x axis')
        .attr('transform', 'translate(0,' + adjBottom + ')')
        .call(
          d3.svg
            .axis()
            .scale(xscale)
            .tickValues(ticks)
            .orient('bottom')
            .tickFormat(sentinel.customTimeFormat(timezone))
        );

      var grid = ch
        .append('g')
        .attr('class', 'grid')
        .selectAll('line.major')
        .data(ticks);
      grid
        .enter()
        .append('line')
        .attr({
          x1: function(d) {
            return xscale(d);
          },
          x2: function(d) {
            return xscale(d);
          },
          y1: adjTop - control_height,
          y2: adjBottom,
          class: 'major'
        });
      grid.exit().remove();

      if (maxE - minE <= sentinel.hours(25)) {
        var tick15 = function() {
          var vals = [];
          for (var i = 0; i < ticks.length; i++) {
            vals.push(ticks[i] + sentinel.minutes(15));
            vals.push(ticks[i] + sentinel.minutes(30));
            vals.push(ticks[i] + sentinel.minutes(45));
          }
          console.log('Added ' + vals.length + ' grid entries');
          return vals;
        };
        var grid2 = ch
          .append('g')
          .attr('class', 'grid')
          .selectAll('line.minor')
          .data(tick15());
        grid2
          .enter()
          .append('line')
          .attr({
            x1: function(d) {
              return xscale(d);
            },
            x2: function(d) {
              return xscale(d);
            },
            y1: function(d, i) {
              return adjBottom - (i % 3 == 1 ? 40 : 20);
            },
            y2: adjBottom,
            class: 'minor'
          });
        grid2
          .enter()
          .append('line')
          .attr({
            x1: function(d) {
              return xscale(d);
            },
            x2: function(d) {
              return xscale(d);
            },
            y1: function(d, i) {
              return adjTop + (i % 3 == 1 ? 40 : 20);
            },
            y2: adjTop,
            class: 'minor'
          });
        grid2.exit().remove();
      }

      if (violations && violations.rests) {
        var keys = Object.keys(violations.rests).sort(function cmp(k1, k2) {
          var k1x = parseInt(k1.replace(/[^\d]+/g, ''));
          var k2x = parseInt(k2.replace(/[^\d]+/g, ''));
          if (isNaN(k1x)) return 1;
          if (isNaN(k2x)) return -1;
          return k1x < k2x ? -1 : k1x > k2x ? 1 : 0;
        });
        for (var i = 0; i < keys.length; i++) {
          var key = keys[i];
          var key1 = key.replace(/[^\d]+/g, '');
          if (key1.trim().length == 0)
            //should filter nights
            continue;

          var tooltipFn = function(d, i) {
            if (d === undefined || d === null) return;

            return '<b>' + key1 + ' hour break</b><br/>' + sentinel.dateFormat(d.finish, timezone);
          };

          var isBig = parseInt(key1) > 20;
          var classN = 'rest' + key1;

          var rline1 = ch.selectAll('.rest.' + classN).data(violations.rests[key]);

          var period = rline1
            .enter()
            .append('g')
            .attr('class', 'rest ' + classN);
          var rh = isBig ? 14 : 11;
          var rw = isBig ? 21 : 13;
          var lo = key1.length == 1 ? 4 : isBig ? 2 : 1;

          period.append('line').attr({
            x1: function(d, i) {
              return xscale(d.finish);
            },
            y1: adjTop - 1,
            x2: function(d, i) {
              return xscale(d.finish);
            },
            y2: adjBottom,
            title: tooltipFn,
            class: 'sentinel-tip'
          });

          period.append('rect').attr({
            x: function(d, i) {
              return xscale(d.finish) - (isBig ? 1 : 0);
            },
            y: adjTop - (rh + 2),
            width: rw,
            height: rh,
            title: tooltipFn,
            class: 'sentinel-tip'
          });

          period
            .append('text')
            .attr({
              x: function(d, i) {
                return xscale(d.finish) + lo;
              },
              y: adjTop - 3,
              title: tooltipFn,
              class: 'sentinel-tip'
            })
            .text(key1);

          rline1.exit().remove();
        }
      }

      //DATE CONTROL
      if (options.show_control) {
        function brushended(timezone) {
          if (!d3.event.sourceEvent) return; // only transition after input
          var extent0 = brush.extent();
          var extent1 = [];

          extent1[0] = moment
            .tz(moment(extent0[0]), timezone)
            .startOf('day')
            .valueOf();
          extent1[1] = moment
            .tz(moment(extent0[1]), timezone)
            .startOf('day')
            .valueOf();

          // ensure from < to and at least a day between them
          if (extent1[0] > extent1[1]) {
            var temp = extent1[0];
            extent1[0] = extent1[1];
            extent1[1] = temp;
          } else if (extent1[0] == extent1[1]) {
            extent1[1] = moment
              .tz(moment(extent1[1]), timezone)
              .add(1, 'day')
              .valueOf();
          }

          var localFrom = moment.tz(extent1[0], timezone);
          var localTo = moment.tz(extent1[1], timezone);

          localFrom = new Date(
            localFrom.get('year'),
            localFrom.get('month'),
            localFrom.get('date')
          ).valueOf();
          localTo = new Date(
            localTo.get('year'),
            localTo.get('month'),
            localTo.get('date')
          ).valueOf();

          $(ele)
            .sentinel()
            .period(localFrom, localTo, true);
        }

        //make the control range 40% larger on each finish than the
        //range of data
        var minEDate = moment.tz(minE, timezone);
        var maxEDate = moment.tz(maxE, timezone);
        var overflowDays = Math.max(Math.floor(maxEDate.diff(minEDate, 'days')) * 0.5, 7);

        // mutate minEDate and maxEDate to store the max/min + overflow days
        minEDate.subtract(overflowDays, 'day');
        maxEDate.add(overflowDays, 'day');

        var cx = d3.scale
          .linear()
          .domain([minEDate.valueOf(), maxEDate.valueOf()])
          .range([0, width]);

        var brush = d3.svg
          .brush()
          .x(cx)
          .extent([minE, maxE])
          .on('brushend', function() {
            brushended(timezone);
          });

        chart
          .append('g')
          .attr('class', 'x axis')
          .attr({
            transform: 'translate(0,' + (height - 11) + ')',
            x: adjLeft,
            width: width - margins.right - adjLeft,
            height: control_height / 2
          })
          .call(
            d3.svg
              .axis()
              .scale(cx)
              .orient('bottom')
              .tickValues(sentinel.calculateTimeTicks(minEDate.valueOf(), maxEDate.valueOf()))
              .tickSize(0)
              .tickFormat(sentinel.customTimeFormat(timezone))
          )
          .selectAll('.tick')
          .classed('minor', function(d) {
            moment.tz(moment(d), timezone).get('date');
          });

        chart
          .append('g')
          .attr('class', 'control background')
          .attr('transform', 'translate(0,' + (height - control_height - 2) + ')')
          .append('rect')
          .attr({
            width: width,
            height: control_height / 2
          });

        var gBrush = chart
          .append('g')
          .attr('class', 'brush')
          .attr('transform', 'translate(0,' + (height - control_height - 2) + ')')
          .call(brush)
          .call(brush.event);

        gBrush.selectAll('rect').attr('height', control_height / 2);
      }

      //EVENTS
      if (events) {
        var bars = ch.selectAll('.sentinel-event').data(events);
        bars
          .enter()
          .append('g')
          .attr('class', function(d, i) {
            var ret = 'sentinel-event ' + sentinel.eventType(d.action);
            if (i === 0 && xscale(d.eventAt) < adjLeft) ret = ret + ' leadin'; //it only a lead in if its off-screen
            if (i == events.length - 1) ret = ret + ' leadout';
            return ret;
          })
          .append('rect')
          .attr({
            class: 'sentinel-tip',
            id: function(d, i) {
              return 'event-' + d.id;
            },
            x: function(d, i) {
              var x = Math.max(adjLeft, xscale(d.eventAt));
              return !isNaN(x) ? x : adjLeft;
            },
            y: function(d, i) {
              var y =
                adjTop +
                adjHeight / 4 -
                bar_height / 2 +
                (sentinel.isRest(d.action) ? adjHeight / 2 : 5);
              return !isNaN(y) ? y : adjTop;
            },
            width: function(d, i) {
              var next = i < events.length ? events[i + 1] : null;
              var w = NaN;
              if (next == null) w = width - margins.right - Math.max(adjLeft, xscale(d.eventAt));
              else w = xscale(next.eventAt) - Math.max(adjLeft, xscale(d.eventAt));

              return !isNaN(w) && w > 0 ? w : 0;
            },
            height: bar_height,
            index: function(d, i) {
              return i;
            },
            title: function(d, i) {
              var next = i < events.length ? events[i + 1] : null;
              return eventTitle(d, next, i === 0 ? true : false, args.user, timezone);
            },
            style: function(d, i) {
              if (i === 0 || i == events.length - 1) return 'opacity: 0.4;';
            }
          });
        bars.exit().remove();
      }

      //CHECKPOINT
      if (options.show_checkpoint && checkpoint) {
        var cpAt = checkpoint;
        if (cpAt && cpAt >= minE && cpAt <= maxE) {
          var title = '<b>Latest checkpoint</b><br/>' + sentinel.dateFormat(cpAt, timezone);

          var lCheckpoint = ch.append('g').attr('class', 'checkpoint');
          lCheckpoint.append('circle').attr({
            cx: xscale(cpAt),
            cy: adjBottom,
            r: 3,
            title: title,
            class: 'sentinel-tip'
          });
          lCheckpoint.append('line').attr({
            x1: xscale(cpAt),
            y1: adjTop - 1,
            x2: xscale(cpAt),
            y2: adjBottom - 3,
            title: title
          });
          lCheckpoint.append('rect').attr({
            x: xscale(cpAt),
            y: adjTop - 16,
            width: 21,
            height: 14,
            title: tooltipFn,
            class: 'sentinel-tip'
          });
          lCheckpoint
            .append('text')
            .attr({
              x: xscale(cpAt) + 5,
              y: adjTop - 4,
              title: title,
              class: 'sentinel-tip fsize13'
            })
            .text('C');
        }
      }

      if (options.show_speed && gps && !gps.length) {
        var sgPos = adjBottom + gutter_height + 5;
        const noData = chart.append('g').attr('class', 'noData');
        noData
          .append('text')
          .classed('label', true)
          .attr('x', '9');
        var ty = adjBottom + gutter_height + 40;
        var tx = width / 2 - 127;
        var textPosY = ty - 6;
        var textPosX = tx + 8;
        noData
          .select('text')
          .attr({
            transform: 'translate(' + textPosX + ',' + textPosY + ')',
            fill: 'black'
          })
          .text('No GPS Data Found.');
      }

      //GPS SPEED GRAPH
      if (options.show_speed && gps && gps.length) {
        console.log('GPS: Processing GPS for ' + gps.length + ' vehicles');
        var sgPos = adjBottom + gutter_height + 5;
        var yscale = d3.scale
          .linear()
          .domain([0, 100])
          .range([speed_height - 5, 0]);

        chart
          .append('g')
          .attr('class', 'y axis')
          .attr({
            transform: 'translate(' + (adjLeft - 20) + ',' + sgPos + ')',
            x: adjLeft,
            width: width - margins.right - adjLeft + 20,
            height: speed_height - 5
          })
          .call(
            d3.svg
              .axis()
              .orient('left')
              .scale(yscale)
              .tickValues([10, 60, 100])
              .tickFormat(function(d) {
                return d + ' km/h';
              })
          );

        chart
          .append('g')
          .attr('class', 'axis')
          .append('line')
          .attr({
            class: 'dotted',
            transform: 'translate(' + (adjLeft - 20) + ',' + sgPos + ')',
            x1: 0,
            y1: yscale(10),
            x2: width - margins.right - adjLeft + 20,
            y2: yscale(10)
          });

        chart
          .append('g')
          .attr('class', 'axis')
          .append('line')
          .attr({
            class: 'dotted',
            transform: 'translate(' + (adjLeft - 20) + ',' + sgPos + ')',
            x1: 0,
            y1: yscale(60),
            x2: width - margins.right - adjLeft + 20,
            y2: yscale(60)
          });

        var line = d3.svg
          .line()
          .x(function(d) {
            // console.log("GPS: time " + d.At + ", xpos " + xscale(d.At));
            return isNaN(d.At) ? adjLeft : xscale(d.At);
          })
          .y(function(d) {
            return d.Spd == undefined || isNaN(d.Spd) ? yscale(0) : yscale(d.Spd);
          })
          .interpolate('linear');
        //x.domain(d3.extent(data, function(d) { return d.date }));   y.domain(d3.extent(data, function(d) { return d.value }));

        var speedG = chart
          .append('g')
          .attr('class', 'speed')
          .attr('transform', 'translate(0,' + sgPos + ')');

        var sg1 = speedG.selectAll('path').data(gps);
        sg1
          .enter()
          .append('path')
          .attr({
            class: 'speedline',
            d: function(d) {
              return line(d.data);
            },
            stroke: function(d) {
              return d.vehicle.colour;
            },
            title: function(d) {
              return d.vehicle.id;
            },
            fill: 'transparent'
          });
        sg1.exit().remove();
        const blurFilter1 = defs.append('svg:filter').attr('id', 'blur-filter-1');
        blurFilter1
          .append('svg:feGaussianBlur')
          .attr('in', 'SourceGraphic')
          .attr('stdDeviation', 0.2)
          .attr('result', 'blur-out');
        const blurFilter2 = defs.append('svg:filter').attr('id', 'blur-filter-2');
        blurFilter2
          .append('svg:feGaussianBlur')
          .attr('in', 'SourceGraphic')
          .attr('stdDeviation', 0.5)
          .attr('result', 'blur-out');

        const focus = chart
          .append('g')
          .attr('class', 'focus')
          .style('display', 'none');

        focus.append('line').classed('y', true);

        focus
          .append('rect')
          .classed('label-box', true)
          .attr({
            fill: '#2b323c',
            'stroke-width': 0,
            rx: 4,
            width: 86,
            height: 28
          })
          .style('filter', 'url(#blur-filter-2)');

        focus
          .append('rect')
          .classed('label-arrow', true)
          .attr({
            fill: '#2b323c',
            'stroke-width': 0,
            transform: 'rotate(45)',
            width: 8,
            height: 8
          })
          .style('filter', 'url(#blur-filter-1)');
        focus
          .append('text')
          .classed('label', true)
          .attr('x', '9');

        chart
          .append('rect')
          .attr({
            class: 'overlay',
            transform: 'translate(' + (adjLeft - 20) + ',' + (sgPos - 15) + ')',
            x: 0,
            width: width - margins.right - adjLeft + 20,
            height: speed_height + 15
          })
          .on('mouseover', function() {
            focus.style('display', null);
          })
          .on('mouseout', function() {
            focus.style('display', 'none');
          })
          .on('mousemove', mousemove);

        d3.select('.overlay').attr({
          fill: 'none',
          'pointer-events': 'all'
        });

        const bisectDate = d3.bisector(function(d) {
          return d.At;
        }).left;
        function mousemove() {
          var x0 = xscale.invert(d3.mouse(this)[0] + adjLeft - 20);

          var set = null;
          var i = -1;
          for (var j = 0; j < gps.length; j++) {
            i = bisectDate(gps[j].data, x0);
            if (i > 0 && i < gps[j].data.length) {
              set = gps[j]; //the device data set containing this inverted mouse location
              console.log(
                'Found in dataset for vehicle ' +
                  set.vehicle.id +
                  ' at index ' +
                  i +
                  ' of ' +
                  set.data.length
              );
              break;
            }
          }

          if (set == null) return;

          // console.log("MM: x0 = " + x0 + ", i = " + i);
          focus.select('line.y').attr({
            transform: 'translate(' + xscale(x0) + ',' + adjTop + ')',
            stroke: set.vehicle.colour,
            x1: 0,
            x2: 0,
            y1: 0,
            y2: sgPos + speed_height - adjTop
          });
          var ty = adjBottom + gutter_height + 40;
          var tx = xscale(x0);
          if (xscale(x0) > width - 120) tx = tx - 100; //move text left of line if close to the right edge
          var textPosY = ty - 6;
          var textPosX = tx + 8;
          var boxPosY = ty - 25;
          var boxPosX = tx + 8;
          var arrowPosY = ty - 16;
          var arrowPosX = tx + 8;
          if (xscale(x0) > width - 120) arrowPosX = tx + 94;
          focus
            .select('text')
            .attr({
              transform: 'translate(' + textPosX + ',' + textPosY + ')',
              fill: 'white'
            })
            .text(Math.max(0, set.data[i].Spd || 0) + ' km/h');
          focus.select('.label-box').attr({
            transform: 'translate(' + boxPosX + ',' + boxPosY + ')'
          });

          focus.select('.label-arrow').attr({
            transform: 'translate(' + arrowPosX + ',' + arrowPosY + ') rotate(45)'
          });
        }
      }

      if ($.fn.tooltipsy && options.show_tooltips) $('svg .sentinel-tip').tooltipsy();
    }

    chart.highlightViolation = function(idx) {
      if (idx === null) {
        return;
      }
      $('.tooltipsy-wrapper').hide();
      $('.violation .period').attr({ visibility: 'hidden', opacity: 0 });
      var workChart = d3.select(ele);
      // Hide violation time marks
      workChart.selectAll('rect[data-violation-index]').attr('visibility', 'hidden');
      // Show the violation timeframe lines for this violation
      workChart
        .selectAll("rect[data-violation-index='" + idx + "']")
        .attr({ visibility: 'visible', opacity: 1 });
      workChart
        .selectAll("g.violation .period[data-violation-index='" + idx + "']")
        .attr('visibility', 'visible');
      // Show the tooltipsy
      var vioRect = $("svg g rect[data-violation-index='" + idx + "']");
      if (
        $.fn.tooltipsy &&
        options.show_tooltips &&
        vioRect &&
        typeof vioRect.data('tooltipsy') != 'undefined'
      ) {
        vioRect.data('tooltipsy').show();
      }
    };

    chart.resetHighlight = function(idx) {
      if (idx === null) {
        return;
      }
      var workChart = d3.select(ele);
      // Show all the violation time marks
      workChart.selectAll('rect[data-violation-index]').attr('visibility', 'visible');
      // Hide the violation timeframe lines for this violation
      workChart.selectAll('g.violation .period[data-violation-index]').attr('visibility', 'hidden');
      // Hide the tooltipsy
      var vioRect = $("svg g rect[data-violation-index='" + idx + "']");
      if (
        $.fn.tooltipsy &&
        options.show_tooltips &&
        vioRect &&
        typeof vioRect.data('tooltipsy') != 'undefined'
      )
        vioRect.data('tooltipsy').hide();
    };

    chart.showEventTooltipsy = function(id) {
      $('.tooltipsy-wrapper').hide();
      var event = $('#' + id);
      if (event && event.data('tooltipsy')) {
        event.data('tooltipsy').show();
      }
    };

    chart.hideEventTooltipsy = function(id) {
      $('.tooltipsy-wrapper').hide();
      if (id !== null) {
        var event = $('#' + id);
        if (event && event.data('tooltipsy')) {
          event.data('tooltipsy').hide();
        }
      }
    };

    return chart;
  }
})(jQuery);

/** SENTINEL CORE END */
