import { FatigueExpandRowRender } from 'features/fatigue/Components';
import { CellMeasurerCache } from 'react-virtualized';
import { driversCols, driverStatusCells } from './Columns';

export const SentinelCellCache = new CellMeasurerCache({
  fixedHeight: false,
  fixedWidth: true,
  minWidth: 40,
  minHeight: 80
});

export function SentinelRowRender({
  index,
  style,
  className,
  key,
  rowData,
  localization,
  ...props
}) {
  return FatigueExpandRowRender({
    index,
    style,
    className,
    key,
    rowData,
    localization,
    cellCache: SentinelCellCache,
    tableColumns: driversCols,
    expandTableColumns: driverStatusCells,
    fatigueDataField: 'sentinelStatus',
    ...props
  });
}
