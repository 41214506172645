import React, { useState } from 'react';
import { Button, List, Modal } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  QsEntityType,
  useGetQsFoldersEntitiesQuery,
  useAddQsAnalysisMutation
} from 'services/nextgen';
import { CategoryFiltersById } from '../constants';

import { services, useCanOneOfCompanyServices } from 'features/permissions';
import { openToast } from 'features/toasts/toastsSlice';
import { useCurrentCompany } from 'features/company/companySlice';

import { ToastType } from 'components/notifications/toasts/Toast';
import DashboardCard from '../Cards/DashboardCard';
import {
  getDashboardCategoryLabels,
  getDashboardCategoryIds
} from '../Toolbars/DashboardsPageToolbar';

import styles from './modals.module.scss';

export const AddDashboardModal = ({ isOpen, onSave, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentCompany = useCurrentCompany();
  const [addQsAnalysis] = useAddQsAnalysisMutation();
  const [isAdding, setIsAdding] = useState(false);

  const folderIds = [
    ...(useCanOneOfCompanyServices([services.INSIGHTSBASIC]) ? [services.INSIGHTSBASIC] : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSADVPROD]) ? [services.INSIGHTSADVPROD] : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSADVSAFETY])
      ? [services.INSIGHTSADVSAFETY]
      : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSADVCOMP]) ? [services.INSIGHTSADVCOMP] : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSSUSTAINABILITYSNAPSHOT])
      ? [services.INSIGHTSSUSTAINABILITYSNAPSHOT]
      : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSSUSTAINABILITYGOALS])
      ? [services.INSIGHTSSUSTAINABILITYGOALS]
      : []),
    ...(useCanOneOfCompanyServices([services.INSIGHTSUSTAINABILITYFLEETS])
      ? [services.INSIGHTSSUSTAINABILITYFLEETS]
      : [])
  ];

  const tnAnalyses = useGetQsFoldersEntitiesQuery({
    folderIds: folderIds,
    entityType: QsEntityType.Analysis
  });

  const getDashboardGuid = categoryIds => {
    const newGuid = crypto.randomUUID();
    const guidCategoryStanza = newGuid.substr(newGuid.indexOf('-') + 1, 4);
    const guid = categoryIds?.length
      ? newGuid.replace(guidCategoryStanza, CategoryFiltersById[categoryIds[0]].guidKey)
      : newGuid;

    // console.debug('AddDashboardModal', { guid, newGuid, guidCategoryStanza });
    return guid;
  };

  const handleAddDashboard = (analysis, categoryIds) => {
    setIsAdding(true);

    addQsAnalysis({
      ...(analysis.analysisId ? { sourceAnalysisId: analysis.analysisId } : {}),
      ...{ companyId: currentCompany.id },
      ...{ analysisId: getDashboardGuid(categoryIds) },
      ...{
        name: analysis.analysisId
          ? analysis.name
          : t('Insights.Modals.AddDashboard.NewDashboardName')
      }
    })
      .then(result => {
        console.debug('addQsAnalysis', { result: result.data });
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Insights.Toasts.AddDashboardSuccess')
          })
        );

        history.push(`/insights/analysis/${result.data.analysisId}`);
      })
      .catch(error => {
        console.error('addQsAnalysis', { error: error });
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Insights.Toasts.AddDashboardError')
          })
        );
      });
  };

  return (
    <Modal
      className={styles.addDashboardModal}
      title={t('Insights.Modals.AddDashboard.Title')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      width={1100}
    >
      <List
        header={<h5>{t('Insights.Modals.AddDashboard.CreateFromScratch').toUpperCase()}</h5>}
        style={{ padding: 20 }}
        grid={{
          gutter: 12,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 4,
          xxl: 4
        }}
        itemLayout={'horizontal'}
        loading={tnAnalyses.isLoading}
        dataSource={[
          {
            id: 'new',
            name: t('Insights.Modals.AddDashboard.NewDashboardLabel')
          }
        ]}
        renderItem={analysis => (
          <List.Item key={analysis.analysisId}>
            <DashboardCard
              dashboard={analysis}
              showTags={false}
              hoverable={!isAdding}
              onClick={() => {
                !isAdding && handleAddDashboard(analysis);
              }}
            />
          </List.Item>
        )}
      />
      <br />
      <List
        header={<h5>{t('Insights.Modals.AddDashboard.CreateFromTemplate').toUpperCase()}</h5>}
        style={{ padding: 20 }}
        grid={{
          gutter: 12,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 4,
          xxl: 4
        }}
        itemLayout={'horizontal'}
        loading={tnAnalyses.isLoading}
        dataSource={tnAnalyses.data}
        renderItem={analysis => (
          <List.Item key={analysis.analysisId}>
            <DashboardCard
              dashboard={analysis}
              categories={getDashboardCategoryLabels(analysis)}
              hoverable={!isAdding}
              onClick={() => {
                !isAdding && handleAddDashboard(analysis, getDashboardCategoryIds(analysis));
              }}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};
