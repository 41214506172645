import React from 'react';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { Tabs } from './Tabs';

export const TabHeader = ({ toolbar, isFullScreen, deviceId }) => {
  return (
    <HeaderPageWrapper>
      <div style={{ display: 'flex' }}>
        <Tabs isFullScreen={isFullScreen} deviceId={deviceId} />
      </div>

      <div style={{ display: 'flex' }}>{toolbar}</div>
    </HeaderPageWrapper>
  );
};
