import * as Yup from 'yup';
import i18n from 'i18next';

import barcodeIcon from 'static/images/icons/formBuilder/barcode.svg';
import dateIcon from 'static/images/icons/formBuilder/date.svg';
import descriptionIcon from 'static/images/icons/formBuilder/description.svg';
import numericIcon from 'static/images/icons/formBuilder/numeric.svg';
import okIcon from 'static/images/icons/formBuilder/ok.svg';
import photoIcon from 'static/images/icons/formBuilder/photo.svg';
import sectionIcon from 'static/images/icons/formBuilder/section.svg';
import selectionIcon from 'static/images/icons/formBuilder/selection.svg';
import signatureIcon from 'static/images/icons/formBuilder/signature.svg';
import textIcon from 'static/images/icons/formBuilder/text.svg';
import timeIcon from 'static/images/icons/formBuilder/time.svg';
import yesnoIcon from 'static/images/icons/formBuilder/yesno.svg';

export const initialValues = {
  CompanyId: '',
  Fleets: [],
  Name: '',
  AlertOnCompletion: 'on'
};

export const validationSchema = () => {
  return Yup.object().shape({
    Name: Yup.string().required(i18n.t('Forms.FormValidation.NameRequired')),
    CompanyId: Yup.string().required(i18n.t('Forms.FormValidation.CompanyRequired'))
  });
};

export const icons = [
  'text',
  'barcode',
  'date time',
  'description',
  'numeric',
  'ok/not ok',
  'photo',
  'section',
  'selection',
  'signature',
  'time',
  'yes/no'
];

export const dictionary = {
  text: textIcon,
  barcode: barcodeIcon,
  'date time': dateIcon,
  description: descriptionIcon,
  numeric: numericIcon,
  'ok/not ok': okIcon,
  photo: photoIcon,
  section: sectionIcon,
  selection: selectionIcon,
  signature: signatureIcon,
  time: timeIcon,
  'yes/no': yesnoIcon
};

export const PERMISSION = {
  FORM: 'FORM'
};

export const STATUS = {
  ENABLED: 'ENABLED'
};

export const EMPTY_ARRAY = [];

export const ROUTE_PATHS = {
  main: '/settings/forms',
  edit: '/settings/forms/edit/id',
  copy: '/settings/forms/copy/id',
  add: '/settings/forms/newForm',
  view: '/settings/forms/id',
  ngAdmin: '/admin/ng/forms/'
};

export const prefilledSmartjobsData = [
  {
    name: 'jobAmount',
    type: 'number'
  },
  {
    name: 'jobCustomerNumber',
    type: 'string'
  },
  {
    name: 'jobCustomerAddressLine1',
    type: 'string'
  },
  {
    name: 'jobCustomerAddressLine2',
    type: 'string'
  },
  {
    name: 'jobCustomerAddressLine3',
    type: 'string'
  },
  {
    name: 'jobCustomerAddressLine4',
    type: 'string'
  },
  {
    name: 'jobCustomerAddressPostCode',
    type: 'string'
  },
  {
    name: 'jobDespatchNote',
    type: 'string'
  },
  {
    name: 'jobTotalExpectedHours',
    type: 'number'
  },
  {
    name: 'jobCompanyId',
    type: 'number'
  },
  {
    name: 'jobContactName',
    type: 'string'
  },
  {
    name: 'jobContactNumber',
    type: 'string'
  },
  {
    name: 'jobCustomerCompanyId',
    type: 'string'
  },
  {
    name: 'jobCustomerExternalId',
    type: 'string'
  },
  {
    name: 'jobCustomerId',
    type: 'number'
  },
  {
    name: 'jobCustomerName',
    type: 'string'
  },
  {
    name: 'jobCustomerOrderNumber',
    type: 'string'
  },
  {
    name: 'jobEndAt',
    type: 'string'
  },
  {
    name: 'jobExternalId',
    type: 'string'
  },
  {
    name: 'jobId',
    type: 'number'
  },
  {
    name: 'jobInstructions',
    type: 'string'
  },
  {
    name: 'jobNotes',
    type: 'string'
  },
  {
    name: 'jobOrderNumber',
    type: 'string'
  },
  {
    name: 'jobSequenceNumber',
    type: 'number'
  },
  {
    name: 'jobStartAt',
    type: 'string'
  },
  {
    name: 'jobStatus',
    type: 'string'
  },
  {
    name: 'jobType',
    type: 'string'
  },
  {
    name: 'runsheetExternalId',
    type: 'string'
  },
  {
    name: 'runsheetExternalReference',
    type: 'string'
  },
  {
    name: 'runsheetId',
    type: 'number'
  },
  {
    name: 'runsheetName',
    type: 'string'
  },
  {
    name: 'runsheetNotes',
    type: 'string'
  }
];
