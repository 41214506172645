import { Modal } from 'antd';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import P from 'prop-types';
import React from 'react';
import { useGetIsFormDirty } from 'features/easydocs/documentsSlice';
import { AssociationsForm } from './';
import i18next from 'i18next';

export const AssociationsModal = ({
  title,
  show,
  onSave,
  onHide,
  documents,
  hierarchy,
  handleCheck
}) => {
  const dirtyForm = useGetIsFormDirty();
  const handleConfirmClose = () => {
    if (!onHide) {
      return;
    }

    onHide();
  };

  const confirmClose = () => {
    confirmationModal(
      i18next.t('Easydocs.Notifications.AreYouSure'),
      i18next.t('Easydocs.Notifications.SureEdit'),
      i18next.t('Common.Modal.CancelChanges'),
      i18next.t('Common.Modal.Stay'),
      handleConfirmClose
    );
  };
  return (
    <>
      <Modal
        title={title}
        open={show}
        onOk={onSave}
        onCancel={dirtyForm ? confirmClose : handleConfirmClose}
        width={1000}
        okText={i18next.t('Easydocs.Associate')}
        cancelText={i18next.t('Common.Cancel')}
        bodyStyle={{ padding: '0' }}
      >
        <AssociationsForm documents={documents} hierarchy={hierarchy} handleCheck={handleCheck} />
      </Modal>
    </>
  );
};

AssociationsModal.propTypes = {
  title: P.string,
  show: P.bool,
  onSave: P.func,
  onHide: P.func,
  documents: P.array,
  hierarchy: P.array,
  handleCheck: P.func
};
