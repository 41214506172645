import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { setPageTitle, setBackButton } from 'features/page/pageSlice';

import { Layout } from 'components/ant';
import { TabNavLink } from 'components/nav/NavLinks';

import TabHeader from './TabHeader';
import Tabs from './Tabs';
import { MassConfigurationModal } from './Modals/MassConfigurationModal';
import { MassConfigurationsTable } from './Tables';
import {
  useIsFetching,
  useMassConfigurations,
  addMassConfiguration,
  updateMassConfiguration,
  deleteMassConfiguration
} from 'features/massManagement/massConfigurationsSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

const MassConfigurations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const massConfigurations = useMassConfigurations();
  const isLoading = useIsFetching();

  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [editRecord, setEditRecord] = useState(null);
  const [showMassConfigurationModal, setShowMassConfigurationModal] = useState(false);

  const onAdd = () => {
    setEditRecord(null);
    if (window.location.pathname.includes('/massmanagement/configurations/mrwa')) {
      setEditRecord({
        jurisdiction: 'MRWA'
      });
    } else {
      setEditRecord({
        jurisdiction: 'NHVR'
      });
    }
    setShowMassConfigurationModal(true);
  };

  const onAddSave = record => {
    dispatch(addMassConfiguration(record));
  };

  const onEdit = record => {
    setEditRecord({
      ...data.find(row => row.id === record.id),
      jurisdiction: record.jurisdiction,
      vehicleCombinationId: record.vehicleCombinationId
    });
    setShowMassConfigurationModal(true);
  };

  const onEditSave = record => {
    dispatch(updateMassConfiguration(record));
  };

  const onDelete = record => {
    dispatch(deleteMassConfiguration(record));
  };

  const isNameUnique = name => {
    return (
      data.find(
        massConfig => massConfig?.name?.toLowerCase().trim() === name?.toLowerCase().trim()
      ) === undefined
    );
  };

  useEffect(() => {
    setData(massConfigurations);
    setSelectedData(
      massConfigurations.map(config => {
        return { ...config, massSchemes: config.massSchemes.filter(scheme => scheme.selected) };
      })
    );
  }, [massConfigurations]);

  dispatch(setPageTitle(t('Common.MassManagement')));
  dispatch(setBackButton(false));

  return (
    <Layout>
      <TabHeader
        tabs={<Tabs />}
        toolbar={
          <Button
            type="primary"
            size="large"
            onClick={() => {
              onAdd();
            }}
            id={BUTTON_IDS.massConfigurationsAddNew}
          >
            {t('MassManagement.Action.AddConfiguration')}
          </Button>
        }
      />
      <TabHeader
        tabs={
          <>
            <TabNavLink
              to={'/settings/massmanagement/configurations/nhvr'}
              isActive={(match, location) => {
                return match || location.pathname === '/settings/massmanagement/configurations';
              }}
            >
              {'NHVR'}
            </TabNavLink>
            <TabNavLink to={'/settings/massmanagement/configurations/mrwa'}>{'MRWA'}</TabNavLink>
          </>
        }
      />

      <Switch>
        <Route path={'/settings/massmanagement/configurations/mrwa'}>
          <MassConfigurationsTable
            data={selectedData.filter(config => config.jurisdiction === 'MRWA')}
            onEdit={onEdit}
            onDelete={onDelete}
            isLoading={isLoading}
            childrenColumnName="massSchemes"
          />
        </Route>
        <Route
          path={[
            '/settings/massmanagement/configurations/nhvr',
            '/settings/massmanagement/configurations'
          ]}
        >
          <MassConfigurationsTable
            data={selectedData.filter(config => config.jurisdiction === 'NHVR')}
            onEdit={onEdit}
            onDelete={onDelete}
            isLoading={isLoading}
            childrenColumnName="massSchemes"
          />
        </Route>
      </Switch>

      <MassConfigurationModal
        data={editRecord}
        isOpen={showMassConfigurationModal}
        isNameUnique={isNameUnique}
        onSave={record => {
          editRecord && editRecord.id ? onEditSave(record) : onAddSave(record);
        }}
        onClose={() => {
          setShowMassConfigurationModal(false);
        }}
      />
    </Layout>
  );
};

export default MassConfigurations;
