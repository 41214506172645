import { useCurrentCompany } from 'features/company/companySlice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchToggleUserAlertsStatus, fetchUserAlerts } from './thunks';

export const useUserAlerts = () => {
  const dispatch = useDispatch();

  const userAlerts = useSelector(state => state?.userAlerts?.list);
  const currentCompany = useCurrentCompany();
  const isCompanyChanged = useIsCompanyChanged();

  const isFetching = useSelector(state => state?.userAlerts?.isFetching);
  const isSuccess = useSelector(state => state?.userAlerts?.success);
  const isError = useSelector(state => state?.userAlerts?.error);

  const isFetchComplete = isSuccess || isError;

  useEffect(() => {
    if (!isFetching && (!isFetchComplete || isCompanyChanged) && currentCompany?.id) {
      dispatch(
        fetchUserAlerts({
          query: {
            company_id: currentCompany.id
          }
        })
      );
    }
  }, [isFetching, isFetchComplete, isCompanyChanged, currentCompany?.id]);

  return userAlerts;
};

export const useIsUserAlertFetching = () => {
  const isAlertFetching = useSelector(state => state.userAlerts.isFetching);
  return isAlertFetching;
};

export const useIsEditUserAlertSaving = () => {
  const isAlertSaving = useSelector(state => state.userAlerts.meta.editUserAlert.isSaving);
  return isAlertSaving;
};

const useIsCompanyChanged = () =>
  useSelector(state => state.companies.current.id !== state.userAlerts.meta.companyId);

export const useIsUserAlertsEnabled = () => {
  const dispatch = useDispatch();

  const globalActiveStatus = useSelector(state => state?.userAlerts?.meta?.global?.active);
  const isFetching = useSelector(state => state?.userAlerts?.meta?.global?.isFetching);
  const isSuccess = useSelector(state => state?.userAlerts?.meta?.global?.success);
  const isError = useSelector(state => state?.userAlerts?.meta?.global?.error);
  const currentCompany = useCurrentCompany();
  const isCompanyChanged = useIsCompanyChanged();

  const isFetchComplete = isSuccess || isError;

  useEffect(() => {
    if (((!isFetching && !isFetchComplete) || isCompanyChanged) && currentCompany?.id) {
      dispatch(
        fetchToggleUserAlertsStatus({
          query: {
            company_id: currentCompany.id
          }
        })
      );
    }
  });

  return globalActiveStatus;
};

export const useIsUserAlertsEnabledFetching = () =>
  useSelector(state => state.userAlerts.meta.global.isFetching);

export const useIsUserAlertsToggleSaving = () =>
  useSelector(state => state.userAlerts.meta.toggle.isSaving);

export const useIsUserAlertsFetched = () => {
  const isSuccess = useSelector(state => state?.userAlerts?.success);
  const isError = useSelector(state => state?.userAlerts?.error);
  return isSuccess || isError;
};
