import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
//components
import { Input, Select, Button } from 'components/ant';
import { Form, Col, Row } from 'antd';
import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
//slices
import {
  useCurrentCompany,
  useRedirectToMainFeaturePageOnCompanyChange,
  useCompanies,
  useIsFetchingCompany,
  saveCompanyApi,
  fetchCompanies,
  fetchSubCompanies,
  fetchDeletedSubCompanies,
  useSubCompanies
} from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';
//constants
import { PATHS, initialValues } from './constants';
//helpers and methods
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { openToast } from 'features/toasts/toastsSlice';
// styles
import styles from './SubCompanies.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useRegions } from 'features/regions/regionsSlice';
import { canHistoryGoBack } from 'utils/methods';
import moment from 'moment';

export const SubCompaniesForm = ({ action }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isEdit = action === 'edit';
  const path = window.location.pathname;
  const companyEditId = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const companies = useSubCompanies();

  const regions = useRegions();
  const [companyForm] = Form.useForm();

  const [companyData, setCompanyData] = useState({});
  const currentCompany = useCurrentCompany();
  const [localeList, setLocaleList] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);

  const isFetchingCompanies = useIsFetchingCompany();
  const [isDirty, setIsDirty] = useState(false);
  const [formInitialValue, setFormInitialValue] = useState(initialValues);
  useRedirectToMainFeaturePageOnCompanyChange('/settings/subcompanies');
  const name = Form.useWatch('name', companyForm);
  const countryValue = Form.useWatch('country', companyForm);

  const handleError = useCallback(
    err => {
      if (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err
          })
        );
      }
      if (history.location.pathname !== PATHS.SUBCOMPANY_DEFAULT) {
        history.replace(PATHS.SUBCOMPANY_DEFAULT);
      }
    },
    [history, dispatch]
  );

  useEffect(() => {
    if (!isEdit) {
      if (!!name) {
        companyForm.setFieldValue('slug', 'tn360_' + name?.toLowerCase());
      } else {
        companyForm.setFieldValue('slug', null);
      }
    }
  }, [name]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (isEdit) {
      const parsedId = parseInt(companyEditId);
      if (
        parsedId <= 0 ||
        isNaN(parsedId) ||
        (!isFetchingCompanies && !companies?.some(w => w.id === parsedId))
      ) {
        handleError(t('Common.Invalid Request ID'));
      }
    }
  }, [isEdit, companyEditId, handleError, t, isFetchingCompanies, companies]);

  useEffect(() => {
    if (isEdit) {
      if (!isFetchingCompanies && companies && companyEditId) {
        const locatedCompany = companies.find(i => i.id === parseInt(companyEditId));
        setCompanyData(locatedCompany);
        if (!!locatedCompany) {
          setFormInitialValue({
            ...initialValues,
            ...locatedCompany,
            parent: locatedCompany.parent?.id
          });
        }
      }
    } else {
      const newGuid = crypto.randomUUID();
      setFormInitialValue({
        ...initialValues,
        externalId: newGuid,
        parent: currentCompany.id
      });
    }
  }, [isEdit, companyEditId, companies, isFetchingCompanies]);

  useEffect(() => {
    companyForm.resetFields();
  }, [formInitialValue]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        isEdit ? `${t('Common.Edit')} ${companyData?.name}` : t('SubCompanies.AddNewCompanies')
      )
    );
  }, [isEdit, companyData?.name, dispatch]);

  useEffect(() => {
    if (regions) {
      const selectedRegion = regions.find(i => i.code === companyForm.getFieldValue('country'));
      if (!!selectedRegion) {
        setLocaleList([
          {
            id: selectedRegion.locale,
            name: t(`Preferences.ChangeLanguage.${selectedRegion.locale}`)
          }
        ]);
        setTimezoneList(
          Object.values(JSON.parse(selectedRegion.timezone)).map(i => {
            return { id: i, name: i };
          })
        );
      } else {
        setTimezoneList([]);
        setLocaleList([]);
      }
    }
  }, [regions, countryValue]);

  const handleCancelPress = () => {
    history.goBack();
  };

  const handleSave = async () => {
    const submitValues = {
      ...companyForm.getFieldsValue(),
      parent: { id: companyForm.getFieldValue('parent') },
      status: 'ENABLED',
      updatedAt: moment().format(),
      ...(action === 'add' && { createdAt: moment().format() }),
      ...(action === 'edit' && { id: companyEditId })
    };

    setIsDirty(false);
    const saveCompanyResponse = await dispatch(
      saveCompanyApi(action === 'add' ? 'POST' : 'PUT', submitValues)
    );
    if (saveCompanyResponse && saveCompanyResponse.id) {
      let successMessage = t('SubCompanies.Notifications.Added', {
        name: name
      });
      if (isEdit) {
        successMessage = t('SubCompanies.Notifications.Updated', {
          name: name
        });
      }

      setTimeout(() => {
        // Added timeout in order for the backend to give back correct data after Create/Update
        dispatch(fetchCompanies());
        dispatch(fetchSubCompanies(currentCompany.id));
        dispatch(fetchDeletedSubCompanies());
      }, 1000);

      dispatch(
        openToast({
          type: ToastType.Success,
          message: successMessage
        })
      );
      canHistoryGoBack(history, PATHS.SUBCOMPANY_DEFAULT);
    }
  };

  return (
    <div className={styles.formContainer}>
      <EditRouteGuard when={isDirty} navigate={history.push} />
      <Form
        layout="vertical"
        initialValues={formInitialValue}
        form={companyForm}
        name="companyForm"
        onFieldsChange={e => {
          if (e[0].name[0] === 'country') {
            companyForm.setFieldValue('locale', null);
            companyForm.setFieldValue('statisticsTz', null);
          }

          setIsDirty(companyForm.isFieldsTouched());
        }}
        onFinish={handleSave}
      >
        <div className={styles.formCard}>
          <Row>
            <Col span={12}>
              <Form.Item
                name="name"
                labelAlign={'left'}
                label={t(`SubCompanies.Form.Name`)}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t('SubCompanies.Form.NameRequired')
                  }
                ]}
              >
                <Input size={'large'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="slug"
                labelAlign={'left'}
                required
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t('SubCompanies.Form.SlugRequired')
                  }
                ]}
                label={t(`SubCompanies.Form.Identifier`)}
              >
                <Input size={'large'} disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="parent"
                labelAlign={'left'}
                required
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t('SubCompanies.Form.ParentCompanyRequired')
                  }
                ]}
                label={t(`SubCompanies.Form.ParentCompany`)}
              >
                <Select
                  size={'large'}
                  placeholder={t(`SubCompanies.Form.ParentCompany`)}
                  data={companies}
                  disabled={isFetchingCompanies || isEdit}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="externalId"
                labelAlign={'left'}
                required
                colon={false}
                label={t(`SubCompanies.Form.ExternalId`)}
              >
                <Input size={'large'} disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <div className={styles.formCard}>
          <Row>
            <Col span={12}>
              <Form.Item
                name="country"
                labelAlign={'left'}
                required
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t('SubCompanies.Form.CountryRequired')
                  }
                ]}
                label={t(`SubCompanies.Form.Country`)}
              >
                <Select
                  size={'large'}
                  valueProperty={'code'}
                  placeholder={t(`SubCompanies.Form.Country`)}
                  data={regions}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name="statisticsTz"
                labelAlign={'left'}
                required
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t('SubCompanies.Form.TimezoneRequired')
                  }
                ]}
                label={t(`SubCompanies.Form.Timezone`)}
              >
                <Select
                  size={'large'}
                  placeholder={t(`SubCompanies.Form.Timezone`)}
                  data={timezoneList}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="locale"
                labelAlign={'left'}
                required
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t('SubCompanies.Form.LocaleRequired')
                  }
                ]}
                label={t(`SubCompanies.Form.Locale`)}
              >
                <Select
                  size={'large'}
                  placeholder={t(`SubCompanies.Form.Locale`)}
                  data={localeList}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.formFooter}>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            id={BUTTON_IDS.subcompanyFormSave}
            disabled={!isDirty}
          >
            {t(`Common.Save`)}
          </Button>
          <Button id={BUTTON_IDS.subcompanyFormCancel} size="large" onClick={handleCancelPress}>
            {t('Common.Cancel')}
          </Button>
        </div>
      </Form>
    </div>
  );
};
