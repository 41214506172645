import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'components/ant/Modal/Modal';
import { Button, Row, Col } from 'antd';
import styles from './VehicleOperationalMakeModelYearFormModal.module.scss';
import { VehicleMakeModelYearForm } from './VehicleMakeModelYearForm';

export const VehicleOperationalMakeModelYearFormModal = ({
  initialOperationalVehicleModelDetail,
  isOpen,
  handleCancel,
  setSelectedOperationalVehicleModelDetail,
  companyId
}) => {
  const { t } = useTranslation();
  const [selectedVehicleModel, setSelectedVehicleModel] = useState();

  const isVehicleModelValid = () => {
    return (
      selectedVehicleModel?.id !== undefined ||
      (!selectedVehicleModel?.make &&
        !selectedVehicleModel?.model &&
        !selectedVehicleModel?.year &&
        !selectedVehicleModel?.source)
    );
  };

  return (
    <Modal
      title={t('Vehicles.Form.OverrideVehicleModel')}
      open={isOpen}
      onCancel={handleCancel}
      wrapClassName={styles.vehicleMakeFormModal}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {t('Common.Cancel')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!isVehicleModelValid()}
          onClick={() => {
            setSelectedOperationalVehicleModelDetail(selectedVehicleModel);
          }}
        >
          {t('Common.Save')}
        </Button>
      ]}
    >
      <Row>
        <Col xs={{ span: 24 }}>
          <div className={styles.vehicleMakeFormContainer}>
            <VehicleMakeModelYearForm
              isRequired={false}
              isOperationalVehicleModel={true}
              companyId={companyId}
              initialVehicleModel={initialOperationalVehicleModelDetail}
              onModelSelected={e => {
                setSelectedVehicleModel(e);
              }}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
