export const EMPTY_ARRAY = [];

export const ColumnKeys = {
  ID: 'id',
  NAME: 'name',
  COMPANY_NAME: 'companyName',
  ASSOCIATIONS: 'associations',
  ACTIONS: 'actions'
};

export const WIFI_TABLE_COLUMNS = [
  {
    title: 'NAME',
    label: 'Name',
    dataIndex: ColumnKeys.NAME,
    key: ColumnKeys.NAME
  },
  {
    title: 'COMPANY',
    label: 'Company',
    dataIndex: ColumnKeys.COMPANY_NAME,
    key: ColumnKeys.COMPANY_NAME
  },
  {
    title: 'ASSOCIATIONS',
    label: 'Associations',
    dataIndex: ColumnKeys.ASSOCIATIONS,
    key: ColumnKeys.ASSOCIATIONS
  },
  {
    title: 'ACTIONS',
    label: 'Actions',
    dataIndex: ColumnKeys.ACTIONS,
    key: ColumnKeys.ACTIONS,
    width: 100
  }
];

export const PLACEMENT_MORE_MENU = 'left';

export const PATHS = {
  WIFI_DEFAULT: '/settings/wifi',
  ADD_NEW_WIFI: '/settings/wifi/new',
  VIEW_WIFI: '/settings/wifi/id',
  EDIT_WIFI: '/settings/wifi/edit/id',
  AUDIT_WIFI: '/settings/wifi/audit/id/:id'
};

export const VIEW_PAGE_LABEL_WIDTH = '295px';

export const DEFAULT_STATUS = 'ENABLED';

export const DELETE_PARAM_TO_MODAL = 'delete';

export const ACTION_MENU_PLACEMENT = 'bottomRight';

export const DATA_TYPE_MESSAGE_FOR_AM = 'WiFi';

export const DATA_TYPE_FOR_AM = 'WIFI';

export const DATA_TYPE_FOR_INITIAL_COMPANY = 'string';

export const LABEL_ALIGN_FOR_INPUTS = 'left';

export const INPUT_SIZE = 'large';

export const FLEET_DIRECTION_GET_CALL = 'DOWN';

export const WIFI_FORM_ACTIONS = {
  ADD: 'add',
  EDIT: 'edit'
};
