import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Button } from 'antd';
import AlertStatusBar from 'components/alertStatusBar/AlertStatusBar';
import { ENTITIES, PAGE_TYPES } from 'components/alertStatusBar/constants';

import {
  clearAlerts,
  useAlerts,
  useIsAlertFetching as isFetchingAlerts
} from 'features/company_alerts';
import { setPageTitle, setBackButton, addBackButtonLink } from 'features/page/pageSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';

import { mapCompanyAlertsAPIResponseToState } from './reducer/state/mapCompanyAlertsAPIResponseToState';

import styles from './CompanyAlerts.module.scss';
import {
  getAddTitleFromLink,
  getNameFromLink,
  getTypeFromLink
} from './helpers/linkManipulationFunctions';
import Can from 'features/permissions/Can';
import entities from 'features/permissions/entities';
import { BUTTON_IDS } from 'utils/globalConstants';

const AlertsView = () => {
  const history = useHistory();
  const isFetching = isFetchingAlerts();

  const link = history.location.pathname
    .split('/')
    .slice(-1)
    .find(type => type);
  const alertType = getTypeFromLink(link);
  const viewTitle = getNameFromLink(link);
  const alerts = useAlerts(alertType);

  const dispatch = useDispatch();
  const localization = useLocalization();
  const currentCompany = useCurrentCompany();

  const [alertLists, setAlertLists] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(
      addBackButtonLink({ url: `/settings/alerts/view/${link}`, backLink: `/settings/alerts` })
    );
    dispatch(setPageTitle(`${viewTitle}`));
  }, []);

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  useEffect(() => {
    setAlertLists(mapCompanyAlertsAPIResponseToState(alerts, null, localization));
  }, [isLoading]);

  useEffect(() => {
    return () => {
      dispatch(clearAlerts());
    };
  }, []);

  const listOfAlerts = {
    entries: alertLists,
    pageType: PAGE_TYPES.VIEW,
    entityType: ENTITIES.COMPANY,
    currentCompanyId: currentCompany.id
  };

  return (
    <>
      <div className={styles.headerContainer}>
        <Can everyEntity={[entities.COMPANYALERT_CREATE]}>
          <Link
            to={{
              pathname: `/settings/alerts/new/${link}`
            }}
          >
            <Button
              type="primary"
              size="large"
              id={BUTTON_IDS.alertsViewAdd}
              className={styles.buttonContainer}
            >
              {getAddTitleFromLink(link)}
            </Button>{' '}
          </Link>
        </Can>
      </div>
      {listOfAlerts?.entries && (
        <AlertStatusBar
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          listOfAlerts={listOfAlerts}
        />
      )}
    </>
  );
};

export default AlertsView;
