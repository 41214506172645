import { useRef, useEffect } from 'react';
import { LocalizationUtil } from 'features/localization/localization';

export const multiplyIfValue = (a, b) => (Number(a) > 0 ? a * b : -1);

export const formatBytes = (numberOfBytes, decimals = 2) => {
  if (0 === numberOfBytes) return '0 Bytes';
  const minDecimals = 0 > decimals ? 0 : decimals,
    unitIndex = Math.floor(Math.log(numberOfBytes) / Math.log(1024));

  return (
    parseFloat((numberOfBytes / Math.pow(1024, unitIndex)).toFixed(minDecimals)) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][unitIndex]
  );
};

export const formatTimeDiff = (time, t) => {
  let timeInSeconds = parseInt(time, 10) / 1000;
  const days = Math.floor(timeInSeconds / (3600 * 24));
  timeInSeconds -= days * 3600 * 24;
  const hours = Math.floor(timeInSeconds / 3600);
  timeInSeconds -= hours * 3600;
  const minutes = Math.floor(timeInSeconds / 60);

  let formatDays = '';
  let formatHours = '';
  let formatMinutes = '';
  let formatSeconds = '';
  if (days > 0) {
    formatDays = days === 1 ? `1 ${t('Common.day')} ` : `${days} ${t('Common.days')} `;
  }
  if (hours > 0) {
    formatHours = hours === 1 ? `1 ${t('Common.hour')} ` : `${hours} ${t('Common.hours')} `;
  }
  if (minutes > 0) {
    formatMinutes =
      minutes === 1 ? `1 ${t('Common.minute')} ` : `${minutes} ${t('Common.minutes')} `;
  }
  if (!formatMinutes && !formatHours && !formatDays) {
    formatSeconds = `${timeInSeconds} ${t('Common.seconds')}`;
  }
  return `${formatDays}${formatHours}${formatMinutes}${formatSeconds}`;
};

export const usePrevious = value => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

export const formatOrdinalSuffix = number => {
  let digit = number % 10,
    digits = number % 100;
  if (digit === 1 && digits !== 11) {
    return number + 'st';
  }
  if (digit === 2 && digits !== 12) {
    return number + 'nd';
  }
  if (digit === 3 && digits !== 13) {
    return number + 'rd';
  }
  return number + 'th';
};

export const formatSecondsToTime = seconds => {
  if (!seconds) {
    return '';
  }
  return new Date(seconds * 1000).toISOString().substr(11, 8);
  // return '';
};

export const secondsToHHMMSS = timeInSeconds => {
  var sec_num = parseInt(timeInSeconds, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  const adjustTime = time => {
    try {
      return !isNaN(Number(time)) && isFinite(Number(time)) ? time : '00';
    } catch (error) {
      return '00';
    }
  };
  return `${adjustTime(hours)}:${adjustTime(minutes)}:${adjustTime(seconds)}`;
};

export const HHMMSSToSeconds = timeString => {
  return timeString.split(':').reduce((acc, time) => 60 * acc + +time);
};

export const canHistoryGoBack = (history, path) => {
  return history.length > 2 ? history.goBack() : history.push(path);
};

export const getRoundValue = (value, precision = 0) => {
  const multiplier = Math.pow(10, precision);
  const ret = Math.round(value * multiplier) / multiplier || 0;
  return isNaN(ret) ? 0 : ret;
};

export const getIDFromPathname = pathname => {
  const indexBeginingId = pathname.lastIndexOf('/');
  const id = pathname.substr(indexBeginingId + 1, pathname.length - 1);
  return Number(id) || id;
};

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const filterDriversForBranch = (drivers = [], branchId) => {
  return drivers.filter(driver =>
    branchId === -1
      ? !driver.location || driver.location === -1
      : driver.location && driver.location.id === branchId
  );
};

export const buildDriverNames = (drivers = []) => {
  return drivers.map(driver => ({
    name: `${driver.firstName || ''} ${driver.lastName || ''}`,
    ...driver
  }));
};

export const prepareDataForSelect = data => {
  return data.map(val => ({
    id: val.id,
    label: val.name
  }));
};

export function insertIf(condition, elements) {
  return condition ? [elements] : [];
}

export const getLocalizedDistance = (localization, value) => {
  return localization?.formats?.speed?.unit === 'mi'
    ? LocalizationUtil.miletokm(parseInt(value))
    : value;
};

// Gets tooltip value useful for grid that show all fleets comma separated
export const getFleetTooltipForGrid = (fleets, t) => {
  let fleetTooltip = t('Common.NoFleet');

  if (fleets) {
    const validFleets = fleets.filter(i => !!i);

    if (validFleets.length === 1) {
      fleetTooltip = `${(validFleets || []).find(item => item !== null)}`;
    } else if (validFleets.length > 1) {
      fleetTooltip = validFleets.join(', ');
    }
  }
  return fleetTooltip;
};

// Gets display value useful for grids that show up to two fleet names with ... and number of fleets after
// Needs whiteSpace: 'pre-line' to render correctly
export const getFleetDisplayValueForGrid = (fleets, t) => {
  let fleetDisplay = t('Common.NoFleet');

  if (fleets) {
    const validFleets = fleets.filter(i => !!i);

    if (validFleets.length === 1) {
      fleetDisplay = `${(validFleets || []).find(item => item !== null)}`;
    } else if (validFleets.length > 1) {
      fleetDisplay = `${validFleets[0]}\n${validFleets[1]}`;
      if (validFleets.length > 2) {
        fleetDisplay += ` ... (${validFleets.length} ${t('fleets')})`;
      }
    }
  }
  return fleetDisplay;
};
