/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Company from "./Company";

/**
 * The Fleet model module.
 * @module model/Fleet
 * @version 1.0
 */
class Fleet {
  /**
   * Constructs a new <code>Fleet</code>.
   * @alias module:model/Fleet
   */
  constructor() {
    Fleet.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Fleet</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Fleet} obj Optional instance to populate.
   * @return {module:model/Fleet} The populated <code>Fleet</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Fleet();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("version")) {
        obj["version"] = ApiClient.convertToType(data["version"], "Number");
      }
      if (data.hasOwnProperty("company")) {
        obj["company"] = Company.constructFromObject(data["company"]);
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("description")) {
        obj["description"] = ApiClient.convertToType(
          data["description"],
          "String"
        );
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("path")) {
        obj["path"] = ApiClient.convertToType(data["path"], "String");
      }
      if (data.hasOwnProperty("docId")) {
        obj["docId"] = ApiClient.convertToType(data["docId"], "String");
      }
      if (data.hasOwnProperty("externalId")) {
        obj["externalId"] = ApiClient.convertToType(
          data["externalId"],
          "String"
        );
      }
      if (data.hasOwnProperty("colour")) {
        obj["colour"] = ApiClient.convertToType(data["colour"], "String");
      }
      if (data.hasOwnProperty("iconType")) {
        obj["iconType"] = ApiClient.convertToType(data["iconType"], "String");
      }
      if (data.hasOwnProperty("carrierId")) {
        obj["carrierId"] = ApiClient.convertToType(data["carrierId"], "Number");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Fleet.prototype["id"] = undefined;

/**
 * @member {Number} version
 */
Fleet.prototype["version"] = undefined;

/**
 * @member {module:model/Company} company
 */
Fleet.prototype["company"] = undefined;

/**
 * @member {String} name
 */
Fleet.prototype["name"] = undefined;

/**
 * @member {String} description
 */
Fleet.prototype["description"] = undefined;

/**
 * @member {module:model/Fleet.StatusEnum} status
 */
Fleet.prototype["status"] = undefined;

/**
 * @member {String} path
 */
Fleet.prototype["path"] = undefined;

/**
 * @member {String} docId
 */
Fleet.prototype["docId"] = undefined;

/**
 * @member {String} externalId
 */
Fleet.prototype["externalId"] = undefined;

/**
 * @member {String} colour
 */
Fleet.prototype["colour"] = undefined;

/**
 * @member {String} iconType
 */
Fleet.prototype["iconType"] = undefined;

/**
 * @member {Number} carrierId
 */
 Fleet.prototype["carrierId"] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Fleet["StatusEnum"] = {
  /**
   * value: "ENABLED"
   * @const
   */
  ENABLED: "ENABLED",

  /**
   * value: "DISABLED"
   * @const
   */
  DISABLED: "DISABLED",

  /**
   * value: "READY_FOR_DOWNLOAD"
   * @const
   */
  READY_FOR_DOWNLOAD: "READY_FOR_DOWNLOAD",

  /**
   * value: "PENDING"
   * @const
   */
  PENDING: "PENDING",

  /**
   * value: "AVAILABLE"
   * @const
   */
  AVAILABLE: "AVAILABLE",

  /**
   * value: "UNAVAILABLE"
   * @const
   */
  UNAVAILABLE: "UNAVAILABLE",

  /**
   * value: "FAILED"
   * @const
   */
  FAILED: "FAILED"
};

export default Fleet;
