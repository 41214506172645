import {
  ScorecardEventType,
  EventTypes,
  TabKeys,
  DefaultScore,
  DefaultBandColor,
  PerfectScore
} from './constants';
import { getEventAttributesByType } from 'containers/Tracking/EventTypes';
import { getBoundsFromLatLng, getBoundsZoomLevel } from 'utils/geo';

export const getEventTypeData = (eventTypeDataKey, localization) => {
  const eventTypesDataArray = Object.values(EventTypes(localization));
  return eventTypesDataArray.find(eventType => eventType.dataKey === eventTypeDataKey);
};

export const getEventTypeDisplayName = (event, localization) => {
  const eventTypes = EventTypes(localization);
  return eventTypes[event.type]?.name;
};

export const getScoresFromSummary = (summary, eventTypeData) => {
  const eventTypeKey = eventTypeData?.key || ScorecardEventType.SPEED;
  const entity = summary?.entities?.length > 0 ? summary.entities[0] : null;

  const defaultScore = Object.keys(summary).length > 0 ? PerfectScore : DefaultScore;

  const eventTypeScore = entity?.types?.[eventTypeKey];
  const entityScore = eventTypeScore ? eventTypeScore.normalizedScore : defaultScore;

  const previousEventTypeScore = entity?.previous?.types?.[eventTypeKey];
  const previousEntityScore = previousEventTypeScore
    ? previousEventTypeScore.normalizedScore
    : defaultScore;

  const companyEventTypeScore = summary?.company?.types?.[eventTypeKey];
  const companyScore = companyEventTypeScore ? companyEventTypeScore.normalizedScore : defaultScore;

  const groupEventTypeScore = summary?.group?.types?.[eventTypeKey];
  const groupScore = groupEventTypeScore ? groupEventTypeScore.normalizedScore : defaultScore;

  return { entityScore, previousEntityScore, companyScore, groupScore };
};

export const getScoreBandColorFromSummary = (summary, eventTypeData) => {
  const eventTypeKey = eventTypeData?.key || ScorecardEventType.SPEED;
  const entity = summary?.entities?.length > 0 ? summary.entities[0] : null;

  const eventTypeScore = entity?.types?.[eventTypeKey];
  const entityScoreBandColor = eventTypeScore?.band?.color || DefaultBandColor;

  const companyScoreBandColor =
    summary?.company?.types?.[eventTypeKey]?.band?.color || DefaultBandColor;
  const groupScoreBandColor =
    summary?.group?.types?.[eventTypeKey]?.band?.color || DefaultBandColor;

  return { entityScoreBandColor, companyScoreBandColor, groupScoreBandColor };
};

export const getTotalDistance = summary => {
  const totalDistance = summary?.entities?.length > 0 ? summary.entities[0].distanceTravelled : 0;
  return totalDistance || 0;
};

export const getTotalPoints = (events, entityTypeKey) => {
  let totalPoints = 0;
  if (events) {
    events.forEach(event => {
      if (!event.excluded) {
        const eventPoints =
          entityTypeKey === TabKeys.drivers ? event.userPoints : event.vehiclePoints;
        totalPoints += eventPoints;
      }
    });
  }

  return totalPoints;
};

export const getEventAttributesByScorecardEventType = (scorecardEvent, localization) => {
  const eventTypes = EventTypes(localization, scorecardEvent);

  const eventData = eventTypes[scorecardEvent.type];
  return getEventAttributesByType(eventData.eventType, eventData.subType);
};

export const getMapSettingsFromEvents = (events, mapRef) => {
  const points = events.map(event => event.GPS);

  const bounds = getBoundsFromLatLng(points);

  const center = bounds.getCenter();
  const location = { lat: center.lat(), lng: center.lng() };

  const zoom = getBoundsZoomLevel(bounds, {
    height: mapRef.current?.state.map.getDiv().clientHeight,
    width: mapRef.current?.state.map.getDiv().clientWidth
  });

  return {
    bounds,
    location,
    zoom
  };
};
