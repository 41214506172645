import React, { useState, useEffect, useCallback } from 'react';
import { useForms, fetchForms } from 'features/forms/formsSlice';
import { useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import { useSelector, useDispatch } from 'react-redux';
import useDebounce from 'utils/hooks/useDebounce';
import { toLower } from 'lodash';
import { useFleets } from 'features/fleets/fleetsSlice';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { FormsTable } from './FormsTable';
import { cache } from './CellRenderers';
import { useHistory } from 'react-router';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import request from 'superagent';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { API_PATH } from 'config';
import { Can, entities } from 'features/permissions';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { prepareDataForMultiselect } from 'utils/filters';
import { useUserKey } from 'features/user/userSlice';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { ROUTE_PATHS } from './constants';
import { BUTTON_IDS } from 'utils/globalConstants';

const Forms = () => {
  const allForms = useForms();
  const company = useCurrentCompany();
  const subCompanies = useSubCompanies();
  const fleets = useFleets();
  const dispatch = useDispatch();
  const { isFetching } = useSelector(state => state.forms.meta);
  const userKey = useUserKey();
  const [filteredForms, setFilteredForms] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterFleets, setFilterFleets] = useState([]);
  const debouncedSearchText = useDebounce(filterText, 300);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('Forms.FormsTitle')));
  }, [dispatch, t]);

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
  }, [subCompanies, t]);

  useEffect(() => {
    const subCompaniesIdsFromFilter = filterCompanies
      .filter(comp => comp.checked)
      .map(comp => comp.id);

    const fleetsOptions = (fleets || []).filter(
      fleet => fleet.id && fleet.company && subCompaniesIdsFromFilter.includes(fleet.company.id)
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets'), null));
  }, [fleets, filterCompanies, t]);

  useEffect(() => {
    setFilteredForms(
      [...(allForms || [])].filter(form => {
        let validForm = true;
        const formFleetsIds = (form.Associations || []).map(association => association.fleetId);
        const formFleets = [...fleets, { name: 'No Fleet', id: -1 }].filter(fleet =>
          formFleetsIds.includes(fleet.id)
        );
        // Filter by search field
        if (debouncedSearchText) {
          validForm = validForm && toLower(form.Name).indexOf(toLower(debouncedSearchText)) > -1;
        }

        // Filter by companies
        const checkedCompaniesIds = filterCompanies
          .filter(company => company.checked)
          .map(company => parseInt(company.id, 10));
        if (!(checkedCompaniesIds.indexOf(0) > -1)) {
          validForm = validForm && checkedCompaniesIds.indexOf(parseInt(form.CompanyId, 10)) > -1;
        }

        // Filter by fleets
        const checkedFleetsIds = filterFleets
          .filter(fleet => fleet.checked)
          .map(fleet => parseInt(fleet.id, 10));
        if (!(checkedFleetsIds.indexOf(0) > -1)) {
          let isFormInFleet = false;
          formFleets.forEach(fleet => {
            isFormInFleet = isFormInFleet || checkedFleetsIds.indexOf(parseInt(fleet.id, 10)) > -1;
          });
          if (!isFormInFleet && checkedFleetsIds.indexOf(-1) > -1) {
            isFormInFleet = !formFleets.length;
          }
          validForm = validForm && isFormInFleet;
        }
        return validForm;
      })
    );
  }, [debouncedSearchText, filterFleets, allForms, filterCompanies, fleets, company]);

  const [tableRef, setTableRef] = useState(null);
  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [filteredForms, tableRef]);

  const handleDeleteAction = data => () => {
    const { Id, Name } = data;
    let url = `${API_PATH}/forms/${Id}`;
    let method = 'DELETE';
    Mixpanel.sendTrackEvent(MPTrackingEvents.Settings.Forms.Update, { typeOfUpdate: 'delete' });
    request(method, url)
      .set('Authorization', `Token token="${userKey}"`)
      .then(() => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18next.t('Forms.Notifications.DeleteNotification', {
              name: Name
            })
          })
        );
        dispatch(fetchForms());
      });
  };

  const goToAddForm = useCallback(() => {
    history.push(ROUTE_PATHS.add);
  }, [history]);

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <Can everyEntity={[entities.FORM_CREATE]}>
            <Button
              type="primary"
              size="large"
              style={{ marginLeft: 'auto' }}
              onClick={goToAddForm}
              id={BUTTON_IDS.configFormsAdd}
            >
              {t('Forms.AddNewForm')}
            </Button>
          </Can>
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} />
            <AntMultiselect
              title={
                filterCompanies?.some(value => !value.checked)
                  ? t('Common.Companies')
                  : t('Common.AllCompanies')
              }
              onFilter={v => setFilterCompanies(v)}
              data={filterCompanies}
            />
            <AntMultiselect
              title={
                filterFleets?.some(value => !value.checked)
                  ? t('Common.Fleets')
                  : t('Common.AllFleets')
              }
              data={filterFleets}
              onFilter={v => setFilterFleets(v)}
            />
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {filteredForms.length}{' '}
            {filteredForms.length === 1 ? `${t('Forms.Form')}` : `${t('Forms.Forms')}`}
          </label>
        </div>
        <div style={{ flex: '1 0 0' }}>
          <FormsTable
            forms={filteredForms}
            company={company}
            fleets={fleets}
            isLoading={isFetching}
            setTableRef={setTableRef}
            typeOfEntityToDelete={t('Common.form')}
            handleDeleteAction={handleDeleteAction}
            allFleets={fleets}
          />
        </div>
      </ContainerPageWrapper>
    </>
  );
};

export default Forms;
