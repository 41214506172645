import { useDispatch, useSelector } from 'react-redux';
import { wifiSelectors } from '../selectors';
import { fetchWifi } from '../thunks';

export const useIsUpdatingWifi = () => useSelector(wifiSelectors.isUpdatingWifi);
export const useIsFetching = () => useSelector(wifiSelectors.isFetching);

export const useWifi = () => {
  const dispatch = useDispatch();
  const wifi = useSelector(wifiSelectors.getWifi);
  const isFetching = useSelector(wifiSelectors.isFetching);
  const isSuccess = useSelector(wifiSelectors.isSuccess);
  const isError = useSelector(wifiSelectors.isError);
  const isFetchingFinished = isSuccess || isError;

  if (!isFetching && !isFetchingFinished) {
    dispatch(fetchWifi());
  }

  return wifi;
};

export const useIsFetchingFinished = () => {
  const isSuccess = useSelector(wifiSelectors.isSuccess);
  const isError = useSelector(wifiSelectors.isError);
  return isSuccess || isError;
};
