import React from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import { EllipsisButton } from 'components/tables/EllipsisButton';
import i18next from 'i18next';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

const getRowContent = content => {
  try {
    return JSON.parse(content);
  } catch (err) {
    return '';
  }
};

export const associationsCellRenderer = ({
  rowData,
  companies,
  fleets,
  t,
  parent,
  rowIndex,
  dataKey
}) => {
  if (typeof rowData.associations !== 'undefined') {
    const company = companies?.find(
      comp => comp.id === parseInt(getRowContent(rowData.associations).company_id)
    );

    const associatedfleets = [...fleets, { id: -1, name: t('Common.NoFleet') }]
      .filter(fleet => fleet.id)
      .filter(
        fleet =>
          getRowContent(rowData.associations).fleet_id &&
          getRowContent(rowData.associations).fleet_id.indexOf(fleet.id.toString()) > -1
      );

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        parent={parent}
        rowIndex={rowIndex}
        key={`cell-measurer-${dataKey}-${rowData.id}`}
      >
        <div style={{ padding: '15px 0' }}>
          {typeof company !== 'undefined' && (
            <div>
              <div>{`${i18next.t('PreTripChecklist.Table.Company')}:`}</div>
              <div>{company.name}</div>
            </div>
          )}
          {typeof associatedfleets !== 'undefined' && associatedfleets.length > 0 && (
            <div>
              <div>{`${i18next.t('Forms.Table.Fleets')}:`}</div>
              {associatedfleets.map((fleet, index) => {
                const fleetCompanyName =
                  fleet.name !== t('Common.NoFleet')
                    ? `(${companies?.find(company => company.id === fleet.company.id)?.name || ''})`
                    : '';
                return (
                  <div key={`key-index-${index}`}>
                    {fleet.name} {fleetCompanyName}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </CellMeasurer>
    );
  }
  return (
    <CellMeasurer cache={cache} columnIndex={0} parent={parent} rowIndex={rowIndex}>
      <div></div>
    </CellMeasurer>
  );
};

export const nameCellRenderer = ({ rowData, canViewInspection }) => {
  const content = getRowContent(rowData.content);
  return canViewInspection ? (
    <Link to={`/settings/inspectionChecklist/id/${rowData.id}`}>{content.ChecklistName}</Link>
  ) : (
    <span>{content.ChecklistName}</span>
  );
};

export const versionCellRenderer = ({ rowData, parent, rowIndex }) => {
  return <div>{rowData.version}</div>;
};

export const companyCellRenderer = ({ rowData, companies }) => {
  const company = companies?.find(comp => comp.id === rowData.company.id) || {};
  return <div>{company.name}</div>;
};

export const ellipsisButtonCellRenderer = ({
  dataKey,
  rowData,
  typeOfEntityToDelete,
  handleDeleteAction
}) => {
  return (
    <EllipsisButton
      dataKey={dataKey}
      typeOfEntityToDelete={typeOfEntityToDelete}
      handleDeleteAction={handleDeleteAction}
      path={'/settings/inspectionChecklist/'}
      data={rowData}
      hideCopy={false}
      canUse="PRETRIPCHECKLIST"
    />
  );
};
