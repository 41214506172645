import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import { fetchVehicleModels, addVehicleModel } from './thunks';

const initialState = {
  list: [],
  companyId: undefined,
  meta: {
    isFetching: false,
    error: false,
    success: false
  }
};

export const vehicleModelsSlice = createSlice({
  name: 'vehicleModels',
  initialState,
  reducers: {
    updateCompanyId(state, { payload }) {
      state.companyId = payload;
    }
  },
  extraReducers: {
    [fetchVehicleModels.pending]: state => {
      state.meta.isFetching = true;
      state.meta.success = false;
      state.meta.error = false;
    },
    [fetchVehicleModels.fulfilled]: (state, action) => {
      state.meta.isFetching = false;
      state.meta.success = true;
      state.meta.error = false;
      state.list = unwrapResult(action).sort((a, b) =>
        a.make.toLowerCase() > b.make.toLowerCase() ? 1 : -1
      );
    },
    [fetchVehicleModels.rejected]: state => {
      state.meta.isFetching = false;
      state.meta.success = false;
      state.meta.error = true;
    },
    [addVehicleModel.fulfilled]: (state, action) => {
      state.list = [...state.list, ...[unwrapResult(action)]];
    }
  }
});

export const { updateCompanyId } = vehicleModelsSlice.actions;
