import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Typography } from 'antd';

import { setBackButton, setPageTitle, addBreadcrumbs } from 'features/page/pageSlice';
import { PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';
import { useDeleteSharedLinkMutation, useGetSharedLinksQuery } from 'services/nextgen';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { format } from 'utils/dates';
import { useLocalization } from 'features/localization/localizationSlice';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { useTranslation } from 'react-i18next';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage } from 'utils/strings';
import { useHistory } from 'react-router';

export const SharedLinks = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const localization = useLocalization();
  const companyId = useCurrentCompanyId();
  const { data: links, isFetching } = useGetSharedLinksQuery(
    { companyId },
    { refetchOnMountOrArgChange: true }
  );
  const [deleteSharedLink] = useDeleteSharedLinkMutation();

  const { Link } = Typography;

  const ColumnKeys = {
    LINK: 'link',
    SHARED_ASSET: 'sharedAsset',
    START_END_DATE: 'startEndDate',
    ACTIONS: 'actions'
  };

  const columns = () => {
    return [
      {
        title: t('CompanyConfig.SharedLinks.Link'),
        dataIndex: ColumnKeys.LINK,
        key: ColumnKeys.LINK
      },
      {
        title: t('CompanyConfig.SharedLinks.SharedAsset'),
        dataIndex: ColumnKeys.SHARED_ASSET,
        key: ColumnKeys.SHARED_ASSET
      },
      {
        title: t('CompanyConfig.SharedLinks.StartEndDate'),
        dataIndex: ColumnKeys.START_END_DATE,
        key: ColumnKeys.START_END_DATE
      },
      {
        dataIndex: ColumnKeys.ACTIONS,
        key: ColumnKeys.ACTIONS,
        width: 80
      }
    ];
  };

  const handleDeleteSharedLink = keyId => {
    deleteSharedLink({ keyId })
      .then(
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('CompanyConfig.SharedLinks.DeletedLink')
          })
        )
      )
      .catch(error =>
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(error?.data)
          })
        )
      );
  };

  const prepareDataForTable = () => {
    return links?.map(link => {
      return {
        [ColumnKeys.LINK]: <Link onClick={() => window.open(link.url, '_blank')}>{link.url}</Link>,
        [ColumnKeys.SHARED_ASSET]: link.jobId && (
          <Link
            onClick={() => history.push(`/smartjobs/id/${link.runsheetId}/jobs/id/${link.jobId}`)}
          >
            {t('CompanyConfig.SharedLinks.Smartjob')}
          </Link>
        ),
        [ColumnKeys.START_END_DATE]: `${format(
          new Date(link.startAt),
          localization.formats.time.formats.dby_imsp
        )} -> ${format(new Date(link.expiresAt), localization.formats.time.formats.dby_imsp)}`,
        [ColumnKeys.ACTIONS]: (
          <Link
            onClick={() =>
              confirmationModal(
                t('CompanyConfig.SharedLinks.ModalTitle'),
                t('CompanyConfig.SharedLinks.ModalBody'),
                t('Common.Modal.Delete'),
                t('Common.Modal.Cancel'),
                () => handleDeleteSharedLink(link.keyId),
                'delete'
              )
            }
          >
            {t('Common.Modal.Delete')}
          </Link>
        )
      };
    });
  };

  useEffect(() => {
    dispatch(setPageTitle(t('CompanyConfig.SharedLinks.Title')));
    dispatch(setBackButton(false));
    dispatch(
      addBreadcrumbs([
        {
          breadcrumbName: t('CompanyConfig.Title'),
          path: PATHS.COMPANY_CONFIG
        },
        {}
      ])
    );

    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [dispatch]);

  return (
    <Table
      columns={columns()}
      dataSource={prepareDataForTable()}
      loading={isFetching}
      pagination={false}
      scroll={{ y: 'calc(100vh - 112px)' }}
    />
  );
};
