import { get } from 'lodash';

export const compareByStringProps = (a, b, property) => {
  const isHierarchalProp = property.includes?.('.');
  const isFunc = typeof property === 'function';

  let aString = isFunc ? property(a) : isHierarchalProp ? get(a, property) : a?.[property];
  let bString = isFunc ? property(b) : isHierarchalProp ? get(b, property) : b?.[property];

  aString = aString?.trim().toLowerCase();
  bString = bString?.trim().toLowerCase();

  // support cases where one or more of the strings is null or undefined
  if (aString == null && bString == null) {
    return 0;
  } else if (aString == null && bString != null) {
    return -1;
  } else if (aString != null && bString == null) {
    return 1;
  }

  return aString.localeCompare(bString);
};

export const compareByNumberProps = (a, b, property) => {
  const isHierarchalProp = property.includes?.('.');
  const isFunc = typeof property === 'function';
  const aNumber = isHierarchalProp ? get(a, property) : isFunc ? property(a) : a?.[property];
  const bNumber = isHierarchalProp ? get(b, property) : isFunc ? property(b) : b?.[property];

  return aNumber - bNumber;
};

export const compareByDateProps = (a, b, property) => {
  const isHierarchalProp = property.includes?.('.');
  const isFunc = typeof property === 'function';

  const aValue = isHierarchalProp ? get(a, property) : isFunc ? property(a) : a?.[property];
  const bValue = isHierarchalProp ? get(b, property) : isFunc ? property(b) : b?.[property];

  const aDate = aValue && aValue !== 'N/A' ? new Date(aValue).getTime() : -1;
  const bDate = bValue && bValue !== 'N/A' ? new Date(bValue).getTime() : -1;

  if (aDate > bDate) {
    return 1;
  } else if (aDate === bDate) {
    return 0;
  } else {
    return -1;
  }
};

const stringPropertyComparator = (property, desc = false) => (a, b) => {
  return desc ? -1 * compareByStringProps(a, b, property) : compareByStringProps(a, b, property);
};

const numberPropertyComparator = (property, desc = false) => (a, b) => {
  return desc ? -1 * compareByNumberProps(a, b, property) : compareByNumberProps(a, b, property);
};

const datePropertyComparator = (property, desc = false) => (a, b) => {
  return desc ? -1 * compareByDateProps(a, b, property) : compareByDateProps(a, b, property);
};

export const Comparators = {
  String: stringPropertyComparator,
  Number: numberPropertyComparator,
  Date: datePropertyComparator
};

export const DefaultSortInfo = {
  columnKey: 'name',
  order: 'ascend'
};

export const getSortOrder = (sortInfo, columnKey) => {
  return sortInfo?.columnKey === columnKey && sortInfo.order;
};
