import React from 'react';
import moment from 'moment';
import { TimePicker as AntTimePicker } from 'antd';

export const TimePicker = ({
  value,
  onChange,
  format = 'hh:mm a',
  style,
  use12Hours,
  placeholder,
  disabled,
  allowClear = 'true',
  showNow = 'true',
  popupStyle
}) => {
  let val = value;
  if (value) {
    val = moment(value, format);
  }
  return (
    <AntTimePicker
      value={val}
      use12Hours={use12Hours}
      format={format}
      style={style}
      onChange={onChange}
      placeholder={placeholder || 'Select time'}
      disabled={disabled}
      showNow={showNow}
      allowClear={allowClear}
      popupStyle={popupStyle}
    />
  );
};
