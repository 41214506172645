import React, { useEffect, useState } from 'react';
import { Table, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { deviceServiceColumns } from './constants';
import services from 'features/permissions/services';
import { useCurrentCompanyServices } from 'features/company/companySlice';
import { useHistory } from 'react-router';
import { useUpdateConfigsMutation, executeUpdateConfig } from 'services/nextgen/ngConfigKeyApi';
import { useDispatch } from 'react-redux';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import { useCanEveryCompanyService } from 'features/permissions';

const DeviceConfigurations = ({ device }) => {
  const types = useDeviceTypesList();
  const isVPMEnabled = useCanEveryCompanyService(services.VPM);
  const isBPMEnabled = useCanEveryCompanyService(services.BPM);
  const isSpeedsirenEnabled = useCanEveryCompanyService(services.SPEEDSIREN);

  const supportedServices = [
    {
      code: services.GPIO,
      getServiceCodes: () => {
        const serviceCodes = [services.GPIO];

        if (isVPMEnabled && device.services?.includes(services.VPM)) {
          serviceCodes.push(services.VPM);
        }

        if (isBPMEnabled && device.services?.includes(services.BPM)) {
          serviceCodes.push(services.BPM);
        }

        if (isSpeedsirenEnabled && device.services?.includes(services.SPEEDSIREN)) {
          serviceCodes.push(services.SPEEDSIREN);
        }

        return serviceCodes;
      },
      condition: () =>
        device.gpioTemplateId !== undefined &&
        device.services?.includes(services.GPIO) &&
        types.find(type => type.id === device?.type?.id)?.name?.toUpperCase() === 'HERMES'
    }
  ];

  const companyServices = useCurrentCompanyServices();
  const [serviceAvailable, setServiceAvailable] = useState([]);
  const [updateConfigs] = useUpdateConfigsMutation();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyServices) {
      setServiceAvailable(
        companyServices.filter(i => supportedServices.some(k => k.code === i.code && k.condition()))
      );
    }
  }, [companyServices, device]);

  const handleView = d => {
    if (d.code === services.GPIO) {
      history.push('/settings/company_config/gpio/id/' + device.gpioTemplateId);
    }
  };

  const handleServicePush = d => {
    let payload = [];
    if (d.code === services.GPIO) {
      payload = {
        deviceId: device.id,
        service: supportedServices.find(i => i.code === d.code)?.getServiceCodes() || d.code
      };
    }

    executeUpdateConfig(payload, updateConfigs, dispatch);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }} size={'middle'}>
      {serviceAvailable?.length == 0 ? (
        t('Devices.View.ConfigurationProtocolNotAvailable')
      ) : (
        <Table
          headerHeight={52}
          headerStyle={{ paddingTop: '18px' }}
          rowClassName="tableRow"
          columns={deviceServiceColumns(t, handleServicePush, handleView)}
          dataSource={serviceAvailable}
          rowKey="id"
          pagination={false}
          loading={false}
        />
      )}
    </Space>
  );
};

export default DeviceConfigurations;
