/* global google */
import React from 'react';
import { Marker } from 'react-google-maps';

// Shows route with markers for each position instead of a Polyline
export const NonHDRoute = ({ waypoints, isSelected, onSelected, pathColor = '#de6ff7' }) => {
  return (
    <>
      {waypoints &&
        waypoints.map((point, pointIndex) => (
          <Marker
            key={`NonHD-${pointIndex}`}
            position={{ lat: point.Lat, lng: point.Lng }}
            icon={{
              path: google.maps.SymbolPath.CIRCLE,
              scale: 5,
              strokeWeight: 0.5,
              strokeColor: isSelected ? '#101010' : '#303030',
              strokeOpacity: isSelected ? 1 : 0.3,
              fillColor: pathColor,
              fillOpacity: isSelected ? 1 : 0.3
            }}
            onClick={onSelected}
          />
        ))}
    </>
  );
};
