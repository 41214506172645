import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import styles from './Autocomplete.module.scss';

const Autocomplete = ({
  placeholder,
  searchText,
  styles: parentStyles,
  handleKeyPress,
  handleOnChange,
  handleDropdownSelect,
  data
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div
      style={{
        position: 'relative'
      }}
    >
      <FormControl
        type="text"
        className={parentStyles.searchDriver}
        placeholder={placeholder}
        value={searchText}
        onKeyPress={handleKeyPress}
        onChange={handleOnChange}
        onFocus={() => setShowDropdown(true)}
        onBlur={() => {
          setTimeout(() => setShowDropdown(false), 100);
        }}
      />
      {showDropdown && (
        <ul className={`${styles.dropdown} ${parentStyles.dropdown}`}>
          {data
            .sort((a, b) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
              }
              if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
              }
              return 0;
            })
            .filter(item => item.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            .map((item, index) => (
              <li key={`item_${index}`} onClick={() => handleDropdownSelect(item.id)}>
                {item.specialName || item.name}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
