import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Radio, Alert } from 'antd';
import { Form, Formik, useField } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { DateRangePicker } from 'components/ant/DateTime/DateRangePicker';
import { useEwdDriverId, useDriverTimezone } from './drivers/driversSlice';
import { ewdAPI } from '../endpoints/ewdAPI';
import { useDispatch } from 'react-redux';
import { useCurrentCompany } from 'features/company/companySlice';
import { useDriverEWDKeeperId } from './keepers/keepersSlice';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useLocalization } from 'features/localization/localizationSlice';
import dayjs from 'dayjs';

export const EWDExport = () => {
  const [visible, showModal] = useState(false);

  return (
    <>
      <Button id={BUTTON_IDS.ewdExport} onClick={showModal}>
        Export
      </Button>
      <ExportForm visible={visible} showModal={showModal} />
    </>
  );
};

const schema = Yup.object().shape({
  dateTo: Yup.date().required('Required'),
  dateFrom: Yup.date().required('Required')
});

const RangePickerFormik = ({ maxDayRange, ...props }) => {
  const dateTo = useField(`${props.name}To`);
  const dateFrom = useField(`${props.name}From`);

  const dateToValue = dateTo[1].value;
  const dateFromValue = dateFrom[1].value;

  const setDateTo = dateTo[2].setValue;
  const setDateFrom = dateFrom[2].setValue;

  const updateValues = dates => {
    const from = moment(dates[0]);
    let to = moment(dates[1]);
    if (to.isAfter(moment(from).add(maxDayRange, 'days'))) {
      to = moment(from).add(maxDayRange, 'days');
    }
    setDateFrom(from.format());
    setDateTo(to.format());
  };
  const localization = useLocalization();

  return (
    <>
      <DateRangePicker
        defaultDates={[dayjs().subtract(27, 'days'), dayjs()]}
        maxDayRange={maxDayRange}
        availableDatesRange={[0, dayjs().endOf('day')]}
        onDateRangeChanged={dates => updateValues(dates)}
        showLastMonth={false}
        format={localization.formats.date.formats.default}
        value={[dayjs(dateFromValue), dayjs(dateToValue)]}
      />
    </>
  );
};

const ExportForm = ({ title, visible, onOk, onCancel, showModal }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const driverId = useEwdDriverId();
  const currentCompany = useCurrentCompany();
  const keeperId = useDriverEWDKeeperId(driverId, currentCompany?.slug);
  const driverTimezone = useDriverTimezone();
  const dispatch = useDispatch();
  useEffect(() => {
    if (keeperId == null) {
      setConfirmLoading(true);
    } else {
      setConfirmLoading(false);
    }
  }, [dispatch, keeperId]);

  const handleOk = (values, actions) => {
    setConfirmLoading(true);
    const fromDate = moment
      .tz(moment(values.dateFrom).format('YYYY-MM-DDTHH:mm:ss'), driverTimezone)
      .startOf('day')
      .toISOString();
    // Add a day as the range defaults to midnight
    const toDate = moment
      .tz(moment(values.dateTo).format('YYYY-MM-DDTHH:mm:ss'), driverTimezone)
      .endOf('day')
      .toISOString();
    ewdAPI.keeperExport(keeperId, driverId, fromDate, toDate, values.type).then(response => {
      let filename = '';
      const disposition = response.headers.get('content-disposition');
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      } else {
        filename = 'file.zip';
      }
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setConfirmLoading(false);
        showModal(false);
      });
    });
  };

  const handleCancel = () => {
    showModal(false);
  };
  return (
    <Formik
      initialValues={{
        dateFrom: moment()
          .startOf('day')
          .subtract(27, 'days')
          .format(),
        dateTo: moment()
          .endOf('day')
          .format(),
        type: 'pdf'
      }}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        handleOk(values, actions);
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <Form>
            <Modal
              title={'Export'}
              open={visible}
              onOk={handleSubmit}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
              maskClosable={false}
              okText="Download"
            >
              <div>
                <Row>
                  <Col span={24}>
                    <label htmlFor="type" className="ant-form-item-required" title="Export Format">
                      Export Format
                    </label>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '2rem' }}>
                  <Col>
                    <Radio.Group
                      options={[
                        { label: 'CSV', value: 'csv' },
                        { label: 'PDF', value: 'pdf' }
                      ]}
                      name="type"
                      optionType="button"
                      value={values.type}
                      onChange={value => setFieldValue('type', value.target.value)}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: '2rem' }}>
                  <Col span={24}>
                    <Alert
                      style={{ width: '428px' }}
                      message={
                        values.type === 'csv' ? (
                          <span>
                            You can export up to <strong>3 years</strong> worth of driver data into
                            a CSV file.
                          </span>
                        ) : (
                          <span>
                            You can export up to <strong>28 days</strong> worth of driver data into
                            a PDF file.
                          </span>
                        )
                      }
                      type="info"
                      showIcon
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <label htmlFor="date" className="ant-form-item-required" title="date">
                      Date Range
                    </label>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '2rem' }}>
                  <Col>
                    <RangePickerFormik maxDayRange={values.type === 'csv' ? 766 : 27} name="date" />
                  </Col>
                </Row>
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
