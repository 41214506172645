import React, { useCallback, useState, useEffect } from 'react';
import { Modal } from 'antd';
import { Row, Col, Button, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { updateUDT } from 'features/eld/udtSlice';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { useUser, useUserKey } from 'features/user/userSlice';
import { Loading } from 'components/loading/Loading';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { DriverSearchInput } from 'features/eld/DriverInputComponent';
import i18next from 'i18nextConfig';
import { useTranslation } from 'react-i18next';
import { BUTTON_IDS } from 'utils/globalConstants';
import { saveSuggestedUDT } from 'features/eld/eventsApi';

export const AssignLogModalMode = {
  EditComment: 0,
  AssignLog: 1,
  UndoAssignment: 2,
  DriverPortal: 3,
  Accept: 4,
  None: 5 //Logged into Driver portal but record is assigned to another driver.
};

export function AssignLogModal({ logData, visible, onUpdate, onClose, mode, ...props }) {
  const [updateForm] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const dispatch = useDispatch();
  const companyKey = useCurrentCompanyKey();
  const userKey = useUserKey();
  const user = useUser();
  const [isProcessing, setIsProcessing] = useState(false);
  const { t } = useTranslation();
  const [commentLenValid, setCommentLenValid] = useState(false);
  const [driverSelected, setDriverSelected] = useState(false);

  const handleUpdate = useCallback(
    values => {
      setIsProcessing(true);
      let updateDispatch = null;
      if (mode === AssignLogModalMode.AssignLog) {
        updateDispatch = dispatch(
          updateUDT(logData?.id, companyKey, {
            Status: 'P',
            Comments: values.comments,
            AssignedUserId: values.driver,
            UpdatedBy: user.id
          })
        );
      } else if (mode === AssignLogModalMode.DriverPortal) {
        updateDispatch = dispatch(
          updateUDT(logData?.id, companyKey, {
            Status: 'A',
            Comments: values.comments,
            UpdatedBy: user.id,
            AssignedUserId: user.id
          })
        );
      } else if (mode === AssignLogModalMode.Accept) {
        updateDispatch = saveSuggestedUDT(userKey, user.id, [
          { id: logData?.id, action: 'approve', comment: values.comments }
        ]);
      } else if (mode === AssignLogModalMode.EditComment) {
        updateDispatch = dispatch(
          updateUDT(logData?.id, companyKey, {
            Status: logData.status === 'Pending' ? 'P' : logData.status === 'Rejected' ? 'D' : 'C',
            Comments: values.comments,
            UpdatedBy: user.id,
            ...(['Pending', 'Rejected'].some(s => s === logData.status) && {
              AssignedUserId: logData.assignedUser?.id
            })
          })
        );
      } else {
        updateDispatch = dispatch(
          updateUDT(logData?.id, companyKey, {
            Status: 'U',
            Comments: values.comments,
            UpdatedBy: user.id,
            AssignedUserId: ''
          })
        );
      }

      updateDispatch.then(
        data => {
          setIsProcessing(false);
          dispatch(
            openToast({ type: ToastType.Success, message: t('ELD.Update Event Succeeded') + '.' })
          );
          if (onUpdate) {
            onUpdate({ udtValues: values, udt: logData, mode: mode });
          }
        },
        error => {
          setIsProcessing(false);
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('ELD.Update Event Failed') + ' ' + error
            })
          );
          if (onClose) {
            onClose();
          }
        }
      );
    },
    [onUpdate, onClose, logData, mode, companyKey, user]
  );

  const handleFormChange = useCallback(() => {
    setIsFormChanged(true);
    if (
      updateForm.getFieldValue()?.comments &&
      updateForm.getFieldValue()?.comments?.length > 3 &&
      updateForm.getFieldValue()?.comments?.length < 61
    ) {
      setCommentLenValid(true);
    } else {
      setCommentLenValid(false);
    }
    if (mode === AssignLogModalMode.EditComment || updateForm.getFieldValue()?.driver) {
      setDriverSelected(true);
    }
  }, []);

  useEffect(() => {
    if (logData?.comments) {
      handleFormChange();
    }
  }, [logData]);

  useEffect(() => {
    if (mode == AssignLogModalMode.DriverPortal) {
      setDriverSelected(true);
    }
  }, [mode]);

  const modalTitle = getModalTitle(mode);
  const updateBtnText = getModalButtonText(mode);
  const footer = (
    <Row gutter={15}>
      <Col>
        <Button
          type="primary"
          disabled={!isFormChanged || isProcessing || !commentLenValid || !driverSelected}
          onClick={() => updateForm.submit()}
          id={BUTTON_IDS.assignLogUpdate}
        >
          {updateBtnText}
        </Button>
      </Col>
      <Col>
        <Button disabled={isProcessing} onClick={onClose} id={BUTTON_IDS.assignLogCancel}>
          {t('Common.Cancel')}
        </Button>
      </Col>
    </Row>
  );
  return (
    <Modal title={modalTitle} open={visible} footer={footer} closable={false}>
      <Row style={{ height: '100%', width: '100%' }}>
        <Col span={24} style={{ position: 'relative' }}>
          {isProcessing && (
            <div
              style={{
                zIndex: 2,
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Loading />
            </div>
          )}
          {!isProcessing && (
            <Form
              form={updateForm}
              initialValues={{ driver: logData?.driver, comments: logData?.comments }}
              layout="vertical"
              onValuesChange={handleFormChange}
              onFinish={handleUpdate}
            >
              {(mode === AssignLogModalMode.AssignLog ||
                mode === AssignLogModalMode.DriverPortal) && (
                <Form.Item label={t('Common.Driver')} name="driver" required>
                  <DriverSearchInput
                    mode={mode}
                    value={mode === AssignLogModalMode.DriverPortal ? user?.id : ''}
                    defaultValue={mode === AssignLogModalMode.DriverPortal ? user?.id : ''}
                  />
                </Form.Item>
              )}

              <Form.Item
                label={t('ELD.Comments')}
                name="comments"
                required
                rules={[{ required: true, min: 4, max: 60 }]}
              >
                <Input.TextArea
                  minLength={4}
                  maxLength={60}
                  rows={10}
                  placeholder={t('ELD.DriverPortal.CommentLength', { min: 4, max: 60 })}
                />
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </Modal>
  );
}

export function getModalButtonText(mode) {
  switch (mode) {
    case AssignLogModalMode.EditComment:
      return i18next.t('ELD.Update Comments');
    case AssignLogModalMode.AssignLog:
      return i18next.t('ELD.Assign');
    case AssignLogModalMode.DriverPortal:
      return i18next.t('ELD.DriverPortal.Claim');
    case AssignLogModalMode.Accept:
      return i18next.t('ELD.Accept');
    default:
      return i18next.t('ELD.Undo Assignment');
  }
}

function getModalTitle(mode) {
  switch (mode) {
    case AssignLogModalMode.EditComment:
      return i18next.t('ELD.Update Comments');
    case AssignLogModalMode.AssignLog:
      return i18next.t('ELD.Assign Drive Time');
    case AssignLogModalMode.DriverPortal:
      return i18next.t('ELD.DriverPortal.ClaimDriveTime');
    case AssignLogModalMode.Accept:
      return i18next.t('ELD.DriverPortal.AcceptDriveTime');
    default:
      return i18next.t('ELD.Undo Assignment');
  }
}
