import React from 'react';
import P from 'prop-types';
import cn from 'classnames';
import styles from './FormCard.module.scss';

export const FormCard = ({ header, body, footer, additionalItems, size }) => {
  const containerClasses = cn(styles.container, {
    [styles.large]: size === 'large'
  });
  return (
    <div className={containerClasses}>
      {header && <div className={styles.header}>{header}</div>}
      {body && <div className={styles.body}>{body}</div>}
      {footer && <div className={styles.footer}>{footer}</div>}
      <div className={styles.additional}>{additionalItems}</div>
    </div>
  );
};

FormCard.propTypes = {
  header: P.node,
  body: P.node,
  footer: P.node,
  additionalItems: P.node,
  size: P.oneOf(['mediu', 'large'])
};
