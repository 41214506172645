import React from 'react';

import Can from 'features/permissions/Can';

import NoAccessBody from 'components/pages/NoAccess';
import PrivateRoute from './PrivateRoute';

const CanRoute = ({
  children,
  render,
  onFail = <NoAccessBody />,
  loginPath,
  everyEntity,
  oneOfEntities,
  everyService,
  oneOfServices,
  everyRole,
  oneOfRoles,
  noneOfRoles,
  beyondRoles,
  everyCompanyService,
  oneOfCompanyServices,
  companyConfigs,
  featureFlag,
  featureHide,
  otherConditions,
  ...props
}) => {
  return (
    <PrivateRoute
      {...props}
      render={routeProps =>
        render ? (
          <Can
            onFail={onFail}
            everyEntity={everyEntity}
            oneOfEntities={oneOfEntities}
            everyService={everyService}
            oneOfServices={oneOfServices}
            everyRole={everyRole}
            oneOfRoles={oneOfRoles}
            noneOfRoles={noneOfRoles}
            beyondRoles={beyondRoles}
            everyCompanyService={everyCompanyService}
            oneOfCompanyServices={oneOfCompanyServices}
            companyConfigs={companyConfigs}
            featureFlag={featureFlag}
            featureHide={featureHide}
            otherConditions={otherConditions}
          >
            {React.isValidElement(render) ? render : render(routeProps)}
          </Can>
        ) : (
          ''
        )
      }
    >
      {children && (
        <Can
          onFail={onFail}
          everyEntity={everyEntity}
          oneOfEntities={oneOfEntities}
          everyService={everyService}
          oneOfServices={oneOfServices}
          everyRole={everyRole}
          oneOfRoles={oneOfRoles}
          noneOfRoles={noneOfRoles}
          beyondRoles={beyondRoles}
          everyCompanyService={everyCompanyService}
          oneOfCompanyServices={oneOfCompanyServices}
          companyConfigs={companyConfigs}
          featureFlag={featureFlag}
          featureHide={featureHide}
          otherConditions={otherConditions}
        >
          {children}
        </Can>
      )}
    </PrivateRoute>
  );
};

export default CanRoute;
