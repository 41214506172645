import React from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentCompany, useCurrentCompany, useCompanies } from './companySlice';
import { Select } from 'antd';
import './CompanySelect.scss';
import { useTranslation } from 'react-i18next';

const CompanySelect = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const companies = useCompanies()
    .filter(company => company.status !== 'DISABLED')
    .sort((a, b) => a.name.localeCompare(b.name));
  const currentCompany = useCurrentCompany();
  const { t } = useTranslation();

  if (companies.length && currentCompany.name) {
    return (
      <div className="companySelectWrapper">
        <Select
          defaultValue={currentCompany.id}
          popupClassName="companySelectDropdown"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          listHeight={400}
          notFoundContent={t('Common.NoCompanyFound')}
          onChange={companyId => {
            dispatch(
              setCurrentCompany(companies.find(company => company.id === parseInt(companyId)))
            );
          }}
          optionFilterProp="children"
          placeholder={t('Company.Select')}
          showSearch
        >
          {companies.map(company => (
            <Option key={company.id} value={company.id}>
              {company.name}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
  return <p>Loading...</p>;
};

export default CompanySelect;
