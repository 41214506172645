import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import logo_grey_1 from 'static/images/logo_grey_1.svg';
import styled from '@emotion/styled';

const Image = styled.img`
  display: block;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
`;

export function MobileLogs(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Set page title and back button
  useEffect(() => {
    dispatch(setPageTitle(t('SupportToolsELD.ELDMobileLogs')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  return (
    <>
      <div style={{ 'text-align': 'center' }}>
        <span style={{ 'font-size': '18.0mm' }}> Under Construction</span>
      </div>
      <div style={{ 'text-align': 'center' }}>
        <span style={{ 'font-size': '18.0mm' }}> Future Home Of Support Tools - Mobile Logs</span>
      </div>
      <div>
        <Image src={logo_grey_1} />
      </div>
    </>
  );
}
