import React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from 'antd';
import TabHeader from '../TabHeader';
import { MenuNavLink } from 'components/nav/NavLinks';

import styles from '../Insights.module.scss';
const { Content, Sider } = Layout;

const ReportsPage = ({ SubPage, toolbar }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <TabHeader toolbar={toolbar} />

      <Layout>
        <Sider className={styles.reportsSider}>
          <MenuNavLink to={'/insights/reports/myreports'}>
            {t('Insights.ReportsMenu.MyReports')}
          </MenuNavLink>
          <MenuNavLink to={'/insights/reports/catalogue'}>
            {t('Insights.ReportsMenu.Catalogue')}
          </MenuNavLink>
          <MenuNavLink to={'/insights/reports/scheduled'}>
            {t('Insights.ReportsMenu.Scheduled')}
          </MenuNavLink>
        </Sider>
        <Content>
          <SubPage />
        </Content>
      </Layout>
    </Layout>
  );
};

export default ReportsPage;
