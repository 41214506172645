import * as Yup from 'yup';
import i18n from 'i18next';
import { GEOFENCE_DEFAULT_COLORS } from 'features/geofences/geofencesUtil';

export const initialValues = {
  name: '',
  companyId: '',
  locationId: '',
  type: 'USER',
  fleets: [],
  thresholdSpeed: '',
  offsetSpeed: '',
  thresholdDurationSpeed: '',
  speedAssist: false,
  thresholdUndertime: '',
  thresholdOvertime: '',
  pretripIgnition: '',
  driverMessageIgnition: '',
  pretripEntry: '',
  driverMessageEntry: '',
  mode: '',
  document: '',
  timeFrom: '',
  timeTo: '',
  weight: 2,
  shape: null,
  colour: GEOFENCE_DEFAULT_COLORS.NON_MANAGED,
  points: [],
  //These are used for searching locations by coordinates
  lat: '',
  lng: ''
};

export const GeofencesLens = {
  All: `${i18n.t('Common.All')}`,
  WithLocation: 'With Location',
  WithoutLocation: 'Without Location',
  More: 'More'
};

export const validationSchema = (isSpeedValidated, geofenceNames, initialName) => {
  return Yup.object().shape({
    name: Yup.string()
      .required(i18n.t('GeofencesFeature.FormValidation.GeofenceName'))
      .max(50, i18n.t('GeofencesFeature.FormValidation.NameMax'))
      .test(
        'Unique',
        i18n.t('GeofencesFeature.FormValidation.IdenticalName'),
        values => !geofenceNames.includes(values) || values === initialName
      ),
    companyId: Yup.string().required(i18n.t('GeofencesFeature.FormValidation.CompanyRequired')),
    thresholdSpeed:
      isSpeedValidated &&
      Yup.string().matches(
        /^[1-9][0-9]*$/,
        i18n.t('GeofencesFeature.FormValidation.Value1OrHigher')
      ),
    offsetSpeed:
      isSpeedValidated &&
      Yup.string().matches(
        /^[1-9][0-9]*$/,
        i18n.t('GeofencesFeature.FormValidation.Value1OrHigher')
      ),
    thresholdDurationSpeed:
      isSpeedValidated &&
      Yup.string().matches(
        /^[1-9][0-9]*$/,
        i18n.t('GeofencesFeature.FormValidation.Value1OrHigher')
      ),
    thresholdUndertime: Yup.string().matches(
      /^[0-9]*$/,
      i18n.t('GeofencesFeature.FormValidation.OnlyNumeric')
    ),
    thresholdOvertime: Yup.string().matches(
      /^[0-9]*$/,
      i18n.t('GeofencesFeature.FormValidation.OnlyNumeric')
    )
  });
};

export const GEOFENCE_TYPES = [
  { label: 'User', value: 'USER' },
  {
    label: 'Traffic',
    value: 'TRAFFIC',
    permission: 'ONSITE_TRAFFIC'
  },
  {
    label: 'Load',
    value: 'LOAD',
    permission: 'SMARTJOBS_HEADLESS'
  }
];

export const GEOFENCE_SHAPES = {
  POLYGON: 'POLYGON',
  RECTANGLE: 'RECTANGLE',
  CIRCLE: 'CIRCLE'
};

export const DefaultCoordinates = {
  AU: { Lat: -33.8424664, Lng: 150.6201274 },
  NZ: { Lat: -36.8632994, Lng: 174.6167118 },
  US: { Lat: 37.7576948, Lng: -122.4726192 },
  UK: { Lat: 51.5287718, Lng: -0.2416811 }
};

export const XLSX_FILE_TITLE = 'Geofences';

export const NO_OF_COLUMNS = 14;
export const columnWidth = numColumns =>
  Array.apply(null, { length: numColumns }).map(_ => ({ wch: 25 }));

export const TOOLPTIP_TEXTS = (translate, unitMeasure) => {
  return {
    THRESHOLD: translate('GeofencesFeature.Form.HelpIconTitles.Threshold', { unitMeasure }),
    OFFSET: translate('GeofencesFeature.Form.HelpIconTitles.Offset', { unitMeasure }),
    DURATION: translate('GeofencesFeature.Form.HelpIconTitles.Duration'),
    USEINNSPEEDASSIST: translate('GeofencesFeature.Form.HelpIconTitles.UseInSpeedAssist')
  };
};

export const TOOLTIP_ICON_CLASS = 'tn-i-help';

export const EMPTY_ARRAY = [];

export const Paths = {
  NEW_GEOFENCE: '/settings/geofences/newGeofence',
  VIEW_GEOFENCE: '/settings/geofences/id',
  EDIT_GEOFENCE: '/settings/geofences/edit',
  AUDIT_GEOFENCE: '/settings/geofences/audit',
  Default: '/settings/geofences'
};

export const modeGeofenceEntryOptions = () => [
  { label: i18n.t('GeofencesFeature.Dismiss'), value: 'DISMISS', key: 'DISMISS' },
  { label: i18n.t('GeofencesFeature.Acknowledge'), value: 'ACK', key: 'ACKNOWLEDGE' }
];

export const geofenceDetailTableColumn = [
  {
    title: 'key',
    dataIndex: 'key',
    key: 'key',
    width: '35%',
    render: text => <div style={{ background: '#f7f8f9', padding: 10 }}>{text}</div>
  },
  {
    title: 'value',
    dataIndex: 'value',
    key: 'value',
    width: '65%',
    render: text => <div style={{ padding: 10 }}>{text}</div>
  }
];
