import { LoadingTable } from 'components/grid/LoadingTable';
import React from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import {
  cache,
  ellipsisButtonCellRenderer,
  nameCellRenderer,
  codeCellRenderer,
  descriptionCellRenderer,
  companyCellRenderer
} from './CellRenderers';
import { useTranslation } from 'react-i18next';
import { useCan, entities } from 'features/permissions';

export const VehicleTypesTable = ({
  vehicleTypes,
  company,
  isLoading,
  setTableRef,
  filterTab,
  handleAction,
  handleDeleteAction,
  typeOfEntityToDelete,
  companies
}) => {
  const { t } = useTranslation();
  const can = useCan();
  const canViewVehicleType = can && can({ everyEntity: [entities.VEHICLETYPE_VIEW] });
  if (isLoading) return <LoadingTable columnSizes={[73, 94, 57, 57, 94, 19]} />;
  const enableRestoreAction = filterTab === 'deleted';
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 250,
          company: 200,
          code: 200,
          description: 300,
          elipsisButton: 60
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.name +
            fixedColumnsWidth.company +
            fixedColumnsWidth.code +
            fixedColumnsWidth.elipsisButton);

        const hideEdit = filterTab === 'deleted';
        const hideDelete = filterTab === 'deleted';

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            headerStyle={{ paddingTop: '18px' }}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={vehicleTypes.length}
            rowGetter={({ index }) => vehicleTypes[index]}
            ref={ref => setTableRef(ref)}
          >
            <Column
              label={t('VehicleTypes.Table.Name')}
              dataKey="name"
              width={fixedColumnsWidth.name}
              cellRenderer={props => nameCellRenderer({ ...props, canViewVehicleType })}
            />
            <Column
              label={t('VehicleTypes.Table.Company')}
              dataKey=""
              width={fixedColumnsWidth.company}
              cellRenderer={props => companyCellRenderer({ ...props, companies: companies })}
            />
            <Column
              label={t('VehicleTypes.Table.Code')}
              dataKey=""
              width={fixedColumnsWidth.code}
              cellRenderer={codeCellRenderer}
            />
            <Column
              label={t('VehicleTypes.Table.Description')}
              width={dynamicColumnWidth}
              dataKey="Description"
              cellRenderer={descriptionCellRenderer}
            />
            <Column
              label={t('VehicleTypes.Table.Actions')}
              dataKey=""
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.elipsisButton}
              cellRenderer={props =>
                ellipsisButtonCellRenderer({
                  ...props,
                  handleDeleteAction,
                  typeOfEntityToDelete,
                  enableRestoreAction,
                  hideEdit,
                  hideDelete,
                  handleAction
                })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
