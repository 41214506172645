import { sortStrings } from 'utils/strings';

export const DEFAULT_SELECT_NO_OPTION_ID = -1;

export const ColumnKeys = {
  STATUS_ICON: 'status_icon',
  STATUS: 'status',
  ID: 'external_id',
  REF: 'external_reference',
  FLEET: 'fleet',
  DRIVER: 'user',
  DEVICE: 'device',
  START_AT: 'start_at',
  JOBS_NUMBER: 'jobs_count',
  SOURCE_LOCATION: 'source_location',
  CREATED_AT: 'created_at',
  VEHICLE: 'vehicle',
  CARRIER: 'ownerCarrier',
  ACTIONS: 'actions',
  JOB_SEQ: 'job_seq',
  JOB_ID: 'job_id',
  ATTRIBUTES: 'attributes',
  JOB_NAME: 'job_name',
  END_AT: 'end_at',
  JOB_TYPE: 'job_type',
  JOB_STATUS: 'job_status',
  JOB_ITEMS: 'job_items',
  ITEM_ID: 'item_id',
  ITEM_ATTRS: 'item_attrs',
  ITEM_TYPE: 'item_type',
  ITEM_QUANTITY: 'item_quantity',
  ITEM_WEIGHT: 'item_weight',
  ITEM_CUBICS: 'item_cubics',
  ITEM_TEMPERATURE: 'item_temperature',
  ITEM_DESCRIPTION: 'item_description',
  ITEM_TIME: 'item_time',
  DELIVERED_JOBS: 'deliveredJobs',
  ADHOC_JOBS: 'adhocJobs',
  NOT_DELIVERED_ITEMS: 'notDeliveredItems',
  PICKEDUP_JOBS: 'pickedupJobs',
  COMPLETED_JOBS: 'completedJobs',

  ITEM_REASON: 'item_reason',
  EVENT_AT: 'event_at',
  BRANCH: 'branch',
  NAME: 'name',
  DESCRIPTION: 'description',
  DETAILS: 'details'
};

export const JobsColumnKeys = {
  NAME: 'customerName',
  LOCATION_TYPE: 'locationType',
  LOCATION: 'location',
  JOB_TYPE: 'jobType',
  ITEMS: 'jobItems',
  ACTIONS: 'actions',
  ID: 'externalId',
  EXPANDED_ITEMS: 'expandedItems'
};

export const RunsheetLabel = {
  DEVICE: 'Device',
  VEHICLE: 'Vehicle',
  DRIVER: 'Driver',
  STATUS: 'Status',
  START: 'Start',
  END: 'End',
  DETAILS: 'Details',
  ATTRIBUTES: 'Attributes'
};

export const ViewRunsheetLabels = [
  {
    value: RunsheetLabel.DEVICE,
    key: 'device',
    name: RunsheetLabel.DEVICE
  },
  {
    value: RunsheetLabel.VEHICLE,
    key: 'vehicle',
    name: RunsheetLabel.VEHICLE
  },
  {
    value: RunsheetLabel.DRIVER,
    key: 'user',
    name: RunsheetLabel.DRIVER
  },
  {
    value: RunsheetLabel.STATUS,
    name: RunsheetLabel.STATUS,
    key: 'status'
  },
  {
    value: RunsheetLabel.START,
    name: RunsheetLabel.START,
    key: 'startTime'
  },
  {
    value: RunsheetLabel.END,
    name: RunsheetLabel.END,
    key: 'endTime'
  },
  {
    value: RunsheetLabel.DETAILS,
    name: RunsheetLabel.DETAILS,
    key: 'details'
  },
  {
    value: RunsheetLabel.ATTRIBUTES,
    name: RunsheetLabel.ATTRIBUTES,
    key: 'attributes'
  }
];

export const travelModes = {
  DRIVING: 'DRIVING'
};

export const JobItems = {
  NAME: {
    name: 'Name',
    key: 'customerName'
  },
  QUANTITY: {
    name: 'Quantity',
    key: 'quantity'
  },
  JOB_TYPE: {
    name: 'Job Type',
    key: 'jobType'
  },
  ITEM_TYPE: {
    name: 'Type',
    key: 'type'
  },
  DESCRIPTION: {
    name: 'Description',
    key: 'description'
  },
  WEIGHT: {
    name: 'Weight',
    key: 'weight'
  },
  UNIT: {
    name: 'Unit',
    key: 'weightUom'
  },
  ACTIONS: {
    name: 'Actions',
    key: 'actions'
  },
  LOCATION: {
    name: 'Location',
    key: 'location'
  },
  LOCATION_TYPE: {
    name: 'Location Type',
    key: 'locationType'
  },
  NOTES: {
    name: 'Notes',
    key: 'notes'
  },
  STOP: {
    name: 'Stop',
    key: 'stop'
  },
  EXTERNAL_ID: {
    name: 'ExternalId',
    key: 'externalId'
  }
};

export const ViewTabsLabels = {
  DETAILS: 'details',
  JOBS: 'jobs',
  LOADED_ITEMS: 'loaded items',
  EVENTS: 'events'
};

export const TABLE_COLUMNS = [
  {
    title: 'STATUS',
    label: 'Status',
    dataIndex: ColumnKeys.STATUS,
    key: ColumnKeys.STATUS
  },
  {
    title: 'REF',
    label: 'Ref',
    dataIndex: ColumnKeys.REF,
    key: ColumnKeys.REF
  },
  {
    title: 'ID',
    label: 'ID',
    dataIndex: 'external_id',
    key: ColumnKeys.ID
  },
  {
    title: 'NAME',
    label: 'Name',
    dataIndex: 'name',
    key: ColumnKeys.NAME
  },
  {
    title: 'FLEET',
    label: 'Fleet',
    dataIndex: 'fleet',
    key: ColumnKeys.FLEET
  },
  {
    title: '# JOBS',
    label: '# Jobs',
    dataIndex: 'jobs_count',
    key: ColumnKeys.JOBS_NUMBER
  },
  {
    title: 'SOURCE LOCATION',
    label: 'Source Location',
    dataIndex: ColumnKeys.SOURCE_LOCATION,
    key: ColumnKeys.SOURCE_LOCATION
  },
  {
    title: 'DRIVER',
    label: 'Driver',
    dataIndex: ColumnKeys.DRIVER,
    key: ColumnKeys.DRIVER
  },
  {
    title: 'DEVICE',
    label: 'Device',
    dataIndex: ColumnKeys.DEVICE,
    key: ColumnKeys.DEVICE
  },
  {
    title: 'START AT',
    label: 'Start at',
    dataIndex: 'start_at',
    key: ColumnKeys.START_AT
  },
  {
    title: 'VEHICLE',
    label: 'Vehicle',
    dataIndex: 'vehicle',
    key: ColumnKeys.VEHICLE
  },
  {
    title: 'CREATED AT',
    label: 'Created at',
    dataIndex: ColumnKeys.CREATED_AT,
    key: ColumnKeys.CREATED_AT
  },
  {
    title: 'OWNER / CARRIER',
    label: 'Owner / Carrier',
    dataIndex: 'ownerCarrier',
    key: ColumnKeys.CARRIER
  },
  {
    title: 'DELIVERED JOBS',
    label: 'Delivered Jobs',
    dataIndex: 'deliveredJobs',
    key: ColumnKeys.DELIVERED_JOBS
  },
  {
    title: 'AD HOC JOBS',
    label: 'Ad Hoc Jobs',
    dataIndex: 'adhocJobs',
    key: ColumnKeys.ADHOC_JOBS
  },
  {
    title: 'NOT DELIVERED ITEMS',
    label: 'Not Delivered Items',
    dataIndex: 'notDeliveredItems',
    key: ColumnKeys.NOT_DELIVERED_ITEMS
  },
  {
    title: 'PICKED UP JOBS',
    label: 'Picked Up Jobs',
    dataIndex: 'pickedupJobs',
    key: ColumnKeys.PICKEDUP_JOBS
  },
  {
    title: 'COMPLETED JOBS',
    label: 'Completed Jobs',
    dataIndex: 'completedJobs',
    key: ColumnKeys.COMPLETED_JOBS
  },
  {
    title: 'ACTIONS',
    label: 'Actions',
    dataIndex: 'actions',
    width: 85,
    key: ColumnKeys.ACTIONS
  }
];
export const JOBS_TABLE_COLUMNS = [
  {
    title: 'NAME',
    dataIndex: JobsColumnKeys.NAME,
    key: JobsColumnKeys.NAME,
    sorter: {
      compare: (a, b) => sortStrings(a[JobsColumnKeys.NAME], b[JobsColumnKeys.NAME]),
      multiple: 2
    }
  },
  {
    title: 'LOCATION TYPE',
    dataIndex: JobsColumnKeys.LOCATION_TYPE,
    key: JobsColumnKeys.LOCATION_TYPE,
    sorter: {
      compare: (a, b) =>
        sortStrings(a[JobsColumnKeys.LOCATION_TYPE], b[JobsColumnKeys.LOCATION_TYPE]),
      multiple: 3
    }
  },
  {
    title: 'LOCATION',
    dataIndex: JobsColumnKeys.LOCATION,
    key: JobsColumnKeys.LOCATION,
    sorter: {
      compare: (a, b) => sortStrings(a[JobsColumnKeys.LOCATION], b[JobsColumnKeys.LOCATION]),
      multiple: 1
    }
  },
  { title: 'JOB TYPE', dataIndex: JobsColumnKeys.JOB_TYPE, key: JobsColumnKeys.JOB_TYPE },
  {
    title: 'ITEMS',
    dataIndex: JobsColumnKeys.ITEMS,
    key: JobsColumnKeys.ITEMS
  },
  {
    title: 'ACTIONS',
    dataIndex: JobsColumnKeys.ACTIONS,
    key: JobsColumnKeys.ACTIONS
  }
];

export const ITEMS_TABLE_COLUMNS = [
  {
    title: JobItems.QUANTITY.name,
    dataIndex: JobItems.QUANTITY.key,
    key: JobItems.QUANTITY.key
  },
  {
    title: JobItems.ITEM_TYPE.name,
    dataIndex: JobItems.ITEM_TYPE.key,
    key: JobItems.ITEM_TYPE.key
  },
  {
    title: JobItems.DESCRIPTION.name,
    dataIndex: JobItems.DESCRIPTION.key,
    key: JobItems.DESCRIPTION.key
  },
  { title: JobItems.WEIGHT.name, dataIndex: JobItems.WEIGHT.key, key: JobItems.WEIGHT.key }
  // { title: JobItems.ACTIONS.name, dataIndex: JobItems.ACTIONS.key, key: JobItems.ACTIONS.key }
];

export const TableStatus = {
  ALL: 'all',
  CREATED: 'created',
  OPENED: 'opened',
  LOAD_VERIFIED: 'load_verified',
  HELD: 'held',
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
  CANCELLED: 'cancelled'
};

export const Tabs = [
  { name: 'All', pathame: '/smartjobs', history: {}, key: TableStatus.ALL },
  { name: 'Created', pathame: '/smartjobs', history: {}, key: TableStatus.CREATED },
  { name: 'Opened', pathame: '/smartjobs', history: {}, key: TableStatus.OPENED },
  { name: 'Load Verified', pathame: '/smartjobs', history: {}, key: TableStatus.LOAD_VERIFIED },
  { name: 'Held', pathame: '/smartjobs', history: {}, key: TableStatus.HELD },
  { name: 'Completed', pathame: '/smartjobs', history: {}, key: TableStatus.COMPLETED },
  { name: 'Incomplete', pathame: '/smartjobs', history: {}, key: TableStatus.INCOMPLETE },
  { name: 'Cancelled', pathame: '/smartjobs', history: {}, key: TableStatus.CANCELLED }
];

export const ViewTabs = [
  { name: 'Details', pathname: '/smartjobs/id', history: {}, key: ViewTabsLabels.DETAILS },
  { name: 'Jobs', pathname: 'smartjobs/id', history: {}, key: ViewTabsLabels.JOBS },
  { name: 'Loaded Items', pathname: 'smartjobs/id', history: {}, key: ViewTabsLabels.LOADED_ITEMS },
  { name: 'Events', pathname: 'smartjobs/id', history: {}, key: ViewTabsLabels.EVENTS }
];

export const viewDetailsLabelStyle = {
  width: '30%',
  paddingLeft: '18px'
};

export const viewDetailsValueStyle = {
  width: '75%'
};

export const TableFilterTypes = {
  CARRIER: 'carrier',
  LOCATION: 'location',
  FLEET: 'fleet',
  VEHICLE: 'vehicle'
};

export const TABLE_RV_JOBS_COLUMNS = [
  {
    title: 'STATUS',
    label: 'Status',
    dataIndex: 'status',
    key: ColumnKeys.STATUS
  },
  {
    title: 'JOB SEQ',
    label: 'Job Seq',
    dataIndex: 'job_seq',
    key: ColumnKeys.JOB_SEQ
  },
  {
    title: 'JOB ID',
    label: 'Job ID',
    dataIndex: 'job_id',
    key: ColumnKeys.JOB_ID
  },
  {
    title: 'ATTRIBUTES',
    label: 'Attributes',
    dataIndex: 'attributes',
    key: ColumnKeys.ATTRIBUTES
  },
  {
    title: 'JOB NAME',
    label: 'Job Name',
    dataIndex: 'job_name',
    key: ColumnKeys.JOB_NAME
  },
  {
    title: 'JOB TYPE',
    label: 'Job Type',
    dataIndex: 'job_type',
    key: ColumnKeys.JOB_TYPE
  },
  {
    title: 'START',
    label: 'Start',
    dataIndex: 'start_at',
    key: ColumnKeys.START_AT
  },
  {
    title: 'END',
    label: 'End',
    dataIndex: 'end_at',
    key: ColumnKeys.END_AT
  },
  {
    title: 'JOB ITEMS',
    label: 'Job Items',
    dataIndex: 'job_items',
    key: ColumnKeys.JOB_ITEMS
  },
  {
    title: 'ACTIONS',
    label: 'Actions',
    dataIndex: 'actions',
    key: ColumnKeys.ACTIONS
  }
];

export const TABLE_RV_JOBS_ITEMS_COLUMNS = [
  {
    title: 'ITEM ID',
    label: 'Item ID',
    dataIndex: 'item_id',
    key: ColumnKeys.ITEM_ID
  },
  {
    title: 'ATTRS',
    label: 'Attrs',
    dataIndex: 'item_attrs',
    key: ColumnKeys.ITEM_ATTRS
  },
  {
    title: 'ITEM',
    label: 'Item',
    dataIndex: 'item_type',
    key: ColumnKeys.ITEM_TYPE
  },
  {
    title: 'QUANTITY',
    label: 'Quantity',
    dataIndex: 'item_quantity',
    key: ColumnKeys.ITEM_QUANTITY
  },
  {
    title: 'WEIGHT',
    label: 'Weight',
    dataIndex: 'item_weight',
    key: ColumnKeys.ITEM_WEIGHT
  },
  {
    title: 'DESCRIPTION',
    label: 'DESCRIPTION',
    dataIndex: 'item_description',
    key: ColumnKeys.ITEM_DESCRIPTION
  },
  {
    title: 'DELIVERY TYPE',
    label: 'DELIVERY TYPE',
    dataIndex: 'job_type',
    key: ColumnKeys.JOB_TYPE
  },
  {
    title: 'DELIVERY STATUS',
    label: 'DELIVERY STATUS',
    dataIndex: 'job_status',
    key: ColumnKeys.JOB_STATUS
  },
  {
    title: 'REASON',
    label: 'Reason',
    dataIndex: 'item_reason',
    key: ColumnKeys.ITEM_REASON
  }
];

export const ITEM_LABEL_JOBS_TABLE = 'item';
export const RunsheetItems = {
  ID: {
    name: 'Runsheet ID',
    key: 'externalId'
  },
  NAME: {
    name: 'Name',
    key: 'name'
  },
  SCHEDULE: {
    name: 'Schedule Date',
    key: 'scheduledAt'
  },
  VEHICLE: {
    name: 'Vehicle',
    key: 'vehicle'
  }
};

export const UNITS = [
  { id: 'kg', label: 'kgs' },
  { id: 'ton', label: 'tonnes' }
];

export const JOB_TYPES = [
  {
    id: 'PICKUP',
    label: 'Pickup'
  },
  {
    id: 'DELIVERY',
    label: 'Delivery'
  }
  // {
  //   id: 'REST_BREAK',
  //   label: 'Rest Break'
  // },
  // {
  //   id: 'DEPOT',
  //   label: 'Depot'
  // },
  // {
  //   id: 'OTHER',
  //   label: 'Other'
  // },
  // {
  //   id: 'ADHOC',
  //   label: 'AdHoc'
  // }
];

export const JOB_ITEMS_TYPES = {
  DELIVERY: 'DELIVERY',
  PICKUP: 'PICKUP'
};

export const JOB_ITEMS_DELIVERY_STATUSES = {
  NOT_DELIVERED: 'Not Delivered',
  DELIVERED: 'Delivered',
  PARTIALLY_DELIVERED: 'Partially Delivered',
  PICKED_UP: 'Picked Up',
  NOT_PICKED_UP: 'Not Picked Up',
  PARTIALLY_PICKED_UP: 'Partially Picked Up',
  CREATED: 'Created'
};

export const JOB_CATEGORY = {
  ADHOC: 'ADHOC'
};

export const LocationType = {
  CUSTOMER: 'CUSTOMER',
  ADDRESS: 'JOB'
};

export const DEFAULT_LOCATION = {
  lat: parseInt('-33.8688', 10),
  lng: 151.2093
};

export const JOB_STATUS = {
  CREATED: 'CREATED',
  STARTED: 'STARTED',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED'
};

export const EVENT_STATUS = {
  OPENED: 'OPENED',
  COMPLETED: 'COMPLETED'
};

export const NUMBER_OF_OPAQUE_ARROWS = 7;

export const polylineColors = {
  tnColorHavelockBlueDark: '#1890ff',
  tnColorJungleGreen: '#26bd8b'
};

export const INDEX_PATHNAME_FOR_BEGINING_RUNSHEET_ID = 14;

//Runsheet Events

export const TABLE_RV_EVENTS_COLUMNS = [
  {
    title: 'STATUS',
    label: 'Status',
    dataIndex: 'status',
    key: ColumnKeys.STATUS
  },
  {
    title: 'DEVICE',
    label: 'Device',
    dataIndex: 'device',
    key: ColumnKeys.DEVICE
  },
  {
    title: 'VEHICLE',
    label: 'Vehicle',
    dataIndex: 'vehicle',
    key: ColumnKeys.VEHICLE
  },
  {
    title: 'FLEET',
    label: 'Fleet',
    dataIndex: 'fleet',
    key: ColumnKeys.FLEET
  },
  {
    title: 'DRIVER',
    label: 'Driver',
    dataIndex: 'user',
    key: ColumnKeys.DRIVER
  },
  {
    title: 'BRANCH',
    label: 'Branch',
    dataIndex: 'branch',
    key: ColumnKeys.BRANCH
  },
  {
    title: 'TIME',
    label: 'Time',
    dataIndex: 'event_at',
    key: ColumnKeys.EVENT_AT
  },
  // Needs to be added after API support
  {
    title: 'DESCRIPTION',
    label: 'Description',
    dataIndex: 'description',
    key: ColumnKeys.DESCRIPTION
  },
  {
    title: 'DETAILS',
    label: 'Details',
    dataIndex: 'details',
    key: ColumnKeys.DETAILS
  }
  // {
  //   title: 'ACTION',
  //   label: 'Action',
  //   dataIndex: 'actions',
  //   key: ColumnKeys.ACTIONS
  // }
];

export const ALLOCATE_MODAL = {
  TITLE: 'Allocate to vehicle',
  SAVE_BUTTON: 'Save',
  MAIN_HEADER: 'Runsheet currently allocated to:',
  REMOVE_BUTTON: 'Remove',
  FLEET_HEADER: 'Fleet',
  VEHICLE_HEADER: 'Vehicle',
  SELECT_PLACEHOLDER: 'Select Option'
};

export const fullWidth = { width: '100%' };

export const SMARTJOB_ERRORS = {
  ROUTE: 'Route error'
};

export const STOP_STATUS = {
  CANCELLED: 'CANCELLED'
};

export const RunsheetStatus = {
  CREATED: 'CREATED',
  OPENED: 'OPENED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED'
};

export const JobStatus = { ...RunsheetStatus };

export const MAX_WAYPOINTS = 12;

export const Paths = {
  SMARTJOBS_DEFAULT: '/smartjobs',
  NEW_RUNSHEET: '/smartjobs/runsheets/new',
  EDIT_RUNSHEET: '/smartjobs/runsheets/edit',
  COPY_RUNSHEET: '/smartjobs/runsheets/copy',
  SMARTJOBS_VIEW: '/smartjobs/id',
  JOBS_VIEW: '/smartjobs/id/:id/jobs/id/:jobid'
};

export const MAX_INT_VALUE = 2147483647;
