import React from 'react';
import { Route, Switch } from 'react-router';
import { JourneyPlanner } from './JourneyPlanner';
import { JourneyPlannerForm } from './JourneyPlannerForm';
import JourneyPlannerView from './JourneyPlannerView';
import JourneyPlannerExportView from './JourneyPlannerExportView';
import { JourneyAudits } from './JourneyAudits';
import { Can, entities } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { useFleets } from 'features/fleets/fleetsSlice';
import { useCompanies } from 'features/company/companySlice';
import { useJourneys } from 'features/journeyPlanner/journeysSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useUsers } from 'features/users/usersSlice';

export const JourneyPlannerRouter = ({ containerRef }) => {
  const fleets = useFleets();
  const journeys = useJourneys();
  const localization = useLocalization();
  const companies = useCompanies();
  const users = useUsers();

  return (
    <Switch>
      <Route path="/journeyplanner/add">
        <Can everyEntity={[entities.TRIP_CREATE]} onFail={<NoAccessBody />}>
          <JourneyPlannerForm localization={localization} action="add" initialFleets={fleets} />
        </Can>
      </Route>
      <Route path="/journeyplanner/edit/id">
        <Can everyEntity={[entities.TRIP_CREATE]} onFail={<NoAccessBody />}>
          <JourneyPlannerForm
            localization={localization}
            action="edit"
            initialFleets={fleets}
            journeys={journeys}
          />
        </Can>
      </Route>
      <Route path="/journeyplanner/copy/id">
        <Can everyEntity={[entities.TRIP_CREATE]} onFail={<NoAccessBody />}>
          <JourneyPlannerForm
            localization={localization}
            action="copy"
            initialFleets={fleets}
            journeys={journeys}
          />
        </Can>
      </Route>
      <Route path="/journeyplanner/id">
        <Can everyEntity={[entities.TRIP_VIEW]} onFail={<NoAccessBody />}>
          <JourneyPlannerView
            journeys={journeys}
            localization={localization}
            companies={companies}
            fleets={fleets}
            users={users}
            containerRef={containerRef}
          />
        </Can>
      </Route>
      <Route path="/journeyplanner/export/id">
        <Can everyEntity={[entities.TRIP_VIEW]} onFail={<NoAccessBody />}>
          <JourneyPlannerExportView
            journeys={journeys}
            localization={localization}
            companies={companies}
            fleets={fleets}
            users={users}
          />
        </Can>
      </Route>
      <Route path="/journeyplanner/audits/id">
        <Can everyEntity={[entities.TRIP_AUDIT]} onFail={<NoAccessBody />}>
          <JourneyAudits />
        </Can>
      </Route>
      <Route path="/journeyplanner">
        <Can everyEntity={[entities.TRIP]} onFail={<NoAccessBody />}>
          <JourneyPlanner journeys={journeys} fleets={fleets} localization={localization} />
        </Can>
      </Route>
    </Switch>
  );
};
