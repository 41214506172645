import { nanoid } from 'nanoid';
import i18n from 'i18next';
import {
  companyDeviceFeaturesCheck,
  deviceCapabilities
} from 'features/permissions/deviceFeaturesByFirmware';
import { FeatureFlag } from 'features/permissions';

const eventTypesIds = {
  EOT: String(nanoid()),
  ECT: String(nanoid()),
  EOP: String(nanoid()),
  IT: String(nanoid()),
  M2S: String(nanoid()),
  S2M: String(nanoid()),
  HB: String(nanoid()),
  EA: String(nanoid()),
  HC: String(nanoid()),
  IM: String(nanoid())
};

export function getEventTypesForVehicleAlerts(hasPermissions) {
  return hasPermissions
    ? [
        {
          id: eventTypesIds.EOT,
          value: 'EOT',
          label: i18n.t('Reports.Engine Oil Over Temperature')
        },
        {
          id: eventTypesIds.ECT,
          value: 'ECT',
          label: i18n.t('Reports.Engine Coolant Over Temperature')
        },
        {
          id: eventTypesIds.EOP,
          value: 'EOP',
          label: i18n.t('Tracking.Events.Engine Low Oil Pressure')
        },
        { id: eventTypesIds.IT, value: 'IT', label: i18n.t('Reports.Idle Time') },
        {
          id: eventTypesIds.M2S,
          value: 'M2S',
          label: i18n.t('Reports.Vehicle Moving To Stationary')
        },
        {
          id: eventTypesIds.S2M,
          value: 'S2M',
          label: i18n.t('Reports.Vehicle Stationary To Moving')
        }
      ]
    : [
        { id: eventTypesIds.IT, value: 'IT', label: i18n.t('Reports.Idle Time') },
        {
          id: eventTypesIds.M2S,
          value: 'M2S',
          label: i18n.t('Reports.Vehicle Moving To Stationary')
        },
        {
          id: eventTypesIds.S2M,
          value: 'S2M',
          label: i18n.t('Reports.Vehicle Stationary To Moving')
        }
      ];
}

function getEventTypes(hasPermissions) {
  return hasPermissions
    ? [
        { id: eventTypesIds.HB, value: 'HB', label: i18n.t('Tracking.Events.Harsh Braking') },
        { id: eventTypesIds.EA, value: 'EA', label: i18n.t('Tracking.Events.Harsh Acceleration') },
        { id: eventTypesIds.HC, value: 'HC', label: i18n.t('Tracking.Events.Harsh Cornering') },
        {
          id: eventTypesIds.IM,
          value: 'IM',
          label: i18n.t('Tracking.Events.Impact'),
          can: {
            otherConditions: [() => companyDeviceFeaturesCheck([deviceCapabilities.Impact], true)]
          }
        }
      ]
    : [
        { id: eventTypesIds.HB, value: 'HB', label: i18n.t('Tracking.Events.Harsh Braking') },
        { id: eventTypesIds.EA, value: 'EA', label: i18n.t('Tracking.Events.Harsh Acceleration') }
      ];
}

export default getEventTypes;
