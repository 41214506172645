import React from 'react';

import { Tag } from 'antd';

import { Grid, ExpandIcon } from 'components/tn';
import { Comparators } from 'utils/sorting';
import { useTranslation } from 'react-i18next';

export const MassConfigurationsModalTable = ({ data, onEdit, onDelete, ...props }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('MassManagement.Field.ConfigurationName'),
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        if (name && record.hasOwnProperty('vehicleCombinationId')) {
          return <span>{name}</span>;
        }
      },
      isSearchable: true,
      isFilterable: true,
      defaultSortColumn: true,
      defaultSortOrder: 'ascend',
      sorter: Comparators.String('name')
    },
    {
      title: t('MassManagement.Jurisdiction'),
      dataIndex: 'jurisdiction',
      key: 'jurisdiction',
      width: 125,
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.String('jurisdiction'),
      render: jurisdiction => {
        if (!jurisdiction) {
          return;
        }

        let color = '';
        switch (jurisdiction) {
          case 'NHVR':
            color = 'blue';
            break;
          case 'MRWA':
          default:
            color = 'purple';
            break;
        }

        return (
          <Tag color={color} key={jurisdiction}>
            {jurisdiction?.toUpperCase()}
          </Tag>
        );
      }
    },
    {
      title: t('MassManagement.VehicleCombination'),
      dataIndex: ['vehicleCombination', 'name'],
      key: 'vehicleCombination',
      width: 125,
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.String('vehicleCombination.name')
    },
    {
      title: t('MassManagement.Field.AxleGroups'),
      dataIndex: ['vehicleCombination', 'axleGroups'],
      key: 'axleGroups',
      width: 125,
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.Number('vehicleCombination.axleGroups')
    },
    {
      title: t('MassManagement.Field.Axles'),
      dataIndex: ['vehicleCombination', 'axles'],
      key: 'axles',
      width: 125,
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.Number('vehicleCombination.axles')
    },
    {
      title: t('MassManagement.Field.Scheme'),
      dataIndex: 'name',
      key: 'massSchemes',
      width: 125,
      isSearchable: true,
      render: (scheme, record) => {
        if (scheme && !record.hasOwnProperty('vehicleCombinationId')) {
          let color = '';
          switch (scheme) {
            case 'GML':
            case 'AMMS1':
              color = 'geekblue';
              break;
            case 'CML':
            case 'AMMS2':
              color = 'orange';
              break;
            case 'HML':
            case 'AMMS3':
              color = 'volcano';
              break;
            default:
              color = 'magenta';
          }

          return (
            <Tag color={color} key={scheme}>
              {scheme.toUpperCase()}
            </Tag>
          );
        }
      }
    },
    {
      title: t('MassManagement.Field.GrossMass'),
      dataIndex: 'grossMass',
      key: 'grossMass',
      width: 125
    },
    {
      title: '',
      dataIndex: 'expand',
      key: 'expand',
      width: 75
    }
  ];

  return (
    <Grid
      data={data}
      columns={columns}
      rowKey={'id'}
      recordTypeSingular={t('MassManagement.MassConfiguration')}
      recordTypePlural={t('MassManagement.MassConfigurations')}
      expandIconColumnIndex={7}
      expandIconAsCell={true}
      expandIcon={props => {
        return props.record.massSchemes && ExpandIcon(props);
      }}
      {...props}
    />
  );
};
