import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModeMethod } from './constants';
import { Radio, Row, Col } from 'antd';
import styles from './controlpanel.module.scss';
import { useCallback } from 'react';

export function ModeSwitch({ mode, onChange }) {
  const { t } = useTranslation();
  const [methodOptions] = useState(() => {
    return Object.keys(ModeMethod).map(k => {
      return { label: ModeMethod[k], value: Number(k) };
    });
  });
  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange(Number(e.target.value));
      }
    },
    [onChange]
  );

  return (
    <Row className={styles.stepContainer + ' ' + styles.modeSwitch}>
      <Col className={styles.step} span={24}>
        <span>{t('Tracking.Proximity.Steps', { num: 2 })}:</span>
        <span>{t('Tracking.Proximity.SelectSearchMethod')}</span>
      </Col>
      <Col span={24}>
        <Radio.Group
          className={styles.switchGroup}
          options={methodOptions}
          value={mode}
          onChange={handleChange}
        />
      </Col>
    </Row>
  );
}
