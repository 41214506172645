/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Company from "./Company";
import Device from "./Device";
import Form from "./Form";
import Gps from "./Gps";
import LocalDateTime from "./LocalDateTime";
import User from "./User";
import Vehicle from "./Vehicle";

/**
 * The FormEvent model module.
 * @module model/FormEvent
 * @version 1.0
 */
class FormEvent {
  /**
   * Constructs a new <code>FormEvent</code>.
   * @alias module:model/FormEvent
   */
  constructor() {
    FormEvent.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>FormEvent</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/FormEvent} obj Optional instance to populate.
   * @return {module:model/FormEvent} The populated <code>FormEvent</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new FormEvent();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("form")) {
        obj["form"] = Form.constructFromObject(data["form"]);
      }
      if (data.hasOwnProperty("company")) {
        obj["company"] = Company.constructFromObject(data["company"]);
      }
      if (data.hasOwnProperty("device")) {
        obj["device"] = Device.constructFromObject(data["device"]);
      }
      if (data.hasOwnProperty("vehicle")) {
        obj["vehicle"] = Vehicle.constructFromObject(data["vehicle"]);
      }
      if (data.hasOwnProperty("user")) {
        obj["user"] = User.constructFromObject(data["user"]);
      }
      if (data.hasOwnProperty("content")) {
        obj["content"] = ApiClient.convertToType(data["content"], "String");
      }
      if (data.hasOwnProperty("location")) {
        obj["location"] = ApiClient.convertToType(data["location"], "String");
      }
      if (data.hasOwnProperty("timeAt")) {
        obj["timeAt"] = ApiClient.convertToType(data["timeAt"], "Date");
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = LocalDateTime.constructFromObject(data["createdAt"]);
      }
      if (data.hasOwnProperty("updatedAt")) {
        obj["updatedAt"] = LocalDateTime.constructFromObject(data["updatedAt"]);
      }
      if (data.hasOwnProperty("GPS")) {
        obj["GPS"] = Gps.constructFromObject(data["GPS"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
FormEvent.prototype["id"] = undefined;

/**
 * @member {module:model/Form} form
 */
FormEvent.prototype["form"] = undefined;

/**
 * @member {module:model/Company} company
 */
FormEvent.prototype["company"] = undefined;

/**
 * @member {module:model/Device} device
 */
FormEvent.prototype["device"] = undefined;

/**
 * @member {module:model/Vehicle} vehicle
 */
FormEvent.prototype["vehicle"] = undefined;

/**
 * @member {module:model/User} user
 */
FormEvent.prototype["user"] = undefined;

/**
 * @member {String} content
 */
FormEvent.prototype["content"] = undefined;

/**
 * @member {String} location
 */
FormEvent.prototype["location"] = undefined;

/**
 * @member {Date} timeAt
 */
FormEvent.prototype["timeAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
FormEvent.prototype["createdAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} updatedAt
 */
FormEvent.prototype["updatedAt"] = undefined;

/**
 * @member {module:model/Gps} GPS
 */
FormEvent.prototype["GPS"] = undefined;

export default FormEvent;
