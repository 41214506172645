import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';

const vehiclesDeleted = {
  list: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false
  }
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload;
  state.meta.list = [];
  state.meta.isListEmpty = true;
}

const vehiclesDeletedSlice = createSlice({
  name: 'vehiclesDeleted',
  initialState: vehiclesDeleted,
  reducers: {
    fetchDeletedVehiclesStart: startLoading,
    fetchDeletedVehiclesSuccess(state, { payload }) {
      state.list = payload;
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.length === 0;
    },
    fetchDeletedVehiclesFailure: loadingFailed
  }
});

const {
  fetchDeletedVehiclesStart,
  fetchDeletedVehiclesSuccess,
  fetchDeletedVehiclesFailure
} = vehiclesDeletedSlice.actions;

export const fetchDeletedVehicles = () => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const companyId = getState().companies?.current?.id;

  try {
    if (!companyId || !userKey) {
      return;
    }
    dispatch(fetchDeletedVehiclesStart());
    const url = `${API_PATH}/vehicles?status=DELETED&pruning=ALL&company_id=${companyId}`;
    request('GET', url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(resp => {
        dispatch(fetchDeletedVehiclesSuccess(resp.body));
      })
      .catch(err => {
        dispatch(fetchDeletedVehiclesFailure(err.toString()));
      });
  } catch (err) {
    dispatch(fetchDeletedVehiclesFailure(err.toString()));
  }
};

export const useDeletedVehicles = () => {
  const dispatch = useDispatch();
  const vehiclesDeleted = useSelector(state => state.vehiclesDeleted.list);
  const isFetching = useSelector(state => state.vehiclesDeleted.meta.isFetching);
  const isListEmpty = useSelector(state => state.vehiclesDeleted.meta.isListEmpty);

  if (vehiclesDeleted.length === 0 && !isFetching && !isListEmpty) {
    dispatch(fetchDeletedVehicles());
  }

  return vehiclesDeleted;
};

export default vehiclesDeletedSlice.reducer;
