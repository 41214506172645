/* eslint-disable */
import * as $ from 'jquery';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useEvents, useCheckpoint } from 'features/ewd/components/events/eventsSlice';
import moment from 'moment';
import '../ewd.scss';
import { useViolations } from './violations/violationsSlice';
import { useEwdDriver, useEWDDrivers } from './drivers/driversSlice';

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const EWDD3 = () => {
  const [$senObject, setSenObject] = useState(null);
  const toDate = useSelector(state => state.ewd.period.toDate);
  const fromDate = useSelector(state => state.ewd.period.fromDate);
  const violationId = useSelector(state => state.ewd.violationId);
  const eventId = useSelector(state => state.ewd.eventId);
  const ewdEvents = useEvents();
  const ewdViolations = useViolations();
  const ewdDriver = useEwdDriver();
  const ewdCheckpoint = useCheckpoint();
  const ewdDrivers = useEWDDrivers();

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      if (!$senObject) {
        return;
      }
      $senObject.$element.attr('width', $senObject.$element.parent().width());
      $senObject.redraw();
    }, 250);

    window.addEventListener('resize', debouncedHandleResize);

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  useEffect(() => {
    if ($senObject) {
      return;
    }
    const $svg = $('#ewdd3');
    const senObj = $svg.ewd({
      show_speed: true,
      show_driver: false,
      show_trace: false,
      show_rests: true,
      show_nights: true,
      show_totals: true,
      show_violations: true,
      show_control: false, //show date period control
      show_dials: true,
      show_status: true,
      show_tooltips: true,
      show_predicted: true,
      show_checkpoint: true,
      show_titles: true,
      margins: { top: 0, bottom: 0, left: 0, right: 0 }
    });
    $svg.attr('width', $svg.parent().width());
    setSenObject(senObj);
  });

  if ($senObject && ewdEvents && toDate && fromDate && ewdDriver) {
    $senObject.userData(ewdDriver);
    $senObject.user(ewdDriver.id);
    const midnight = moment(toDate)
      .add(1, 'day')
      .startOf('day')
      .valueOf();
    const morning = moment(fromDate)
      .startOf('day')
      .valueOf();
    $senObject.period(morning, midnight);
    $senObject.events(ewdEvents);
    if (ewdCheckpoint !== 'No Checkpoint') {
      $senObject.checkpoint(ewdCheckpoint);
    }
    if (ewdViolations?.driverName) {
      $senObject.violations(ewdViolations);
    }
    $senObject.ewdDrivers(ewdDrivers);
    $senObject.redraw();
  }

  useEffect(() => {
    if (!$senObject || !violationId) {
      return;
    }
    const [action, id] = violationId.split('#!');
    if (action === 'show') {
      $senObject.chart.highlightViolation(id);
    }
    if (action === 'hide') {
      $senObject.chart.resetHighlight(id);
    }
  }, [violationId, $senObject]);

  useEffect(() => {
    if (!$senObject || !eventId) {
      return;
    }
    const [action, id] = eventId.split('#!');
    if (action === 'show') {
      $senObject.chart.showEventTooltipsy(id);
    }
    if (action === 'hide') {
      $senObject.chart.hideEventTooltipsy(id);
    }
  }, [eventId, $senObject]);

  return useMemo(() => <></>);
};

export const EWDJquery = () => {
  return (
    <>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
        id="ewdd3"
        width="500"
        height="224"
      />
      <EWDD3 />
    </>
  );
};

export default EWDD3;
