/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import LocalDateTime from "./LocalDateTime";
import Stop from "./Stop";
import Trip from "./Trip";

/**
 * The Leg model module.
 * @module model/Leg
 * @version 1.0
 */
class Leg {
  /**
   * Constructs a new <code>Leg</code>.
   * @alias module:model/Leg
   */
  constructor() {
    Leg.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Leg</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Leg} obj Optional instance to populate.
   * @return {module:model/Leg} The populated <code>Leg</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Leg();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("trip")) {
        obj["trip"] = Trip.constructFromObject(data["trip"]);
      }
      if (data.hasOwnProperty("startStop")) {
        obj["startStop"] = Stop.constructFromObject(data["startStop"]);
      }
      if (data.hasOwnProperty("endStop")) {
        obj["endStop"] = Stop.constructFromObject(data["endStop"]);
      }
      if (data.hasOwnProperty("duration")) {
        obj["duration"] = ApiClient.convertToType(data["duration"], "Number");
      }
      if (data.hasOwnProperty("distance")) {
        obj["distance"] = ApiClient.convertToType(data["distance"], "Number");
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("routeDefinition")) {
        obj["routeDefinition"] = ApiClient.convertToType(
          data["routeDefinition"],
          "String"
        );
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = LocalDateTime.constructFromObject(data["createdAt"]);
      }
      if (data.hasOwnProperty("updatedAt")) {
        obj["updatedAt"] = LocalDateTime.constructFromObject(data["updatedAt"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Leg.prototype["id"] = undefined;

/**
 * @member {module:model/Trip} trip
 */
Leg.prototype["trip"] = undefined;

/**
 * @member {module:model/Stop} startStop
 */
Leg.prototype["startStop"] = undefined;

/**
 * @member {module:model/Stop} endStop
 */
Leg.prototype["endStop"] = undefined;

/**
 * @member {Number} duration
 */
Leg.prototype["duration"] = undefined;

/**
 * @member {Number} distance
 */
Leg.prototype["distance"] = undefined;

/**
 * @member {module:model/Leg.StatusEnum} status
 */
Leg.prototype["status"] = undefined;

/**
 * @member {String} routeDefinition
 */
Leg.prototype["routeDefinition"] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
Leg.prototype["createdAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} updatedAt
 */
Leg.prototype["updatedAt"] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Leg["StatusEnum"] = {
  /**
   * value: "ENABLED"
   * @const
   */
  ENABLED: "ENABLED",

  /**
   * value: "DISABLED"
   * @const
   */
  DISABLED: "DISABLED",

  /**
   * value: "READY_FOR_DOWNLOAD"
   * @const
   */
  READY_FOR_DOWNLOAD: "READY_FOR_DOWNLOAD",

  /**
   * value: "PENDING"
   * @const
   */
  PENDING: "PENDING",

  /**
   * value: "AVAILABLE"
   * @const
   */
  AVAILABLE: "AVAILABLE",

  /**
   * value: "UNAVAILABLE"
   * @const
   */
  UNAVAILABLE: "UNAVAILABLE",

  /**
   * value: "FAILED"
   * @const
   */
  FAILED: "FAILED"
};

export default Leg;
