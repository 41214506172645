export const saleforceStatus = {
  Init: 0,
  Loading: 1,
  Running: 2,
  Opened: 3
};

export const saleforceScriptLink =
  'https://teletracnavman--TNStaging.my.salesforce.com/embeddedservice/5.0/esw.min.js';
export const saleforceLiveChatScriptLink =
  'https://c.la3-c1cs-ph2.salesforceliveagent.com/content/g/js/48.0/deployment.js';
export const saleforceGslbBaseURL = 'https://service.force.com';
export const saleforceStyle = `
.embeddedServiceHelpButton {
    display: none;
}

.minButton.cChatSalesSupport{
    display: none!important;
}

.embeddedServiceSidebarMinimizedDefaultUI {
    min-width: auto;
    width: min-content;
}

.embeddedServiceHelpButton div.helpButton {
    cursor: pointer;
    padding: 0 16px;
    display: none;
    background-color: #99C221;
    font-family: "Arial", sans-serif;
    margin: 0px;
    border-radius: 23px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 12px 0px;
}

    .embeddedServiceHelpButton div.helpButton .helpButtonLabel,
    .embeddedServiceHelpButton div.helpButton .embeddedServiceIcon {
        color: white;
        transform: translateY(12px);
        /*display:inline-block;*/
        display: inline;
        margin: 0;
    }

    .embeddedServiceHelpButton div.helpButton #helpButtonSpan {
        line-height: 44px;
        display: inline;
        padding-left: 10px;
    }
    
    .embeddedServiceHelpButton div.helpButton.isIE11 #helpButtonSpan     {
        top: 12px;
    }

.embeddedServiceHelpButton .helpButton .uiButton:focus {
    display: none;
}

.embeddedServiceHelpButton div.helpButton .helpButtonEnabled {
    height: 0px;
}

.embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #99C221;
    font-family: "Arial", sans-serif;
    border: none;
}
/*.embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid #005290;
}*/
.embeddedServiceHelpButton .helpButton .uiButton {
    min-width: 5px;
}

.embeddedServiceIcon {
    margin: 0 10px;
}

.embeddedServiceHelpButton button.uiButton {
    background-color: yellow;
    height: 50px;
}

    .embeddedServiceHelpButton button.uiButton:before {
        background-color: yellow !important;
        height: 50px;
        pointer-events: auto !important;
        display: none !important;
    }

.dragMinButton {
    cursor: pointer;
    padding: 0 16px;
    width: fit-content !important;
    background-color: #99C221;
    font-family: "Arial", sans-serif;
    margin: 0px;
    border-radius: 23px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 12px 0px;
    width: auto;
    height: 46px;
}

    .dragMinButton .embeddedServiceIcon::before {
        font-family: "embeddedserviceiconfont";
        font-size: 1.25em;
        content: attr(data-icon);
        color: white;
        speak: none;
        text-rendering: auto;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
    }

    .dragMinButton .embeddedServiceIcon {
        display: inline;
        transform: translateY(3px);
        margin: 0px;
    }

    .dragMinButton .message {
        display: inline;
        color: white;
        line-height: 44px;
        padding-left: 10px;
    }

.consentlink {
    /*text-decoration: none;*/
}

.consent {
    font-size: 12px;
    text-align: center;
    padding: 12px;
    margin-top: 25px;
    color: #206877;
}


.sidebarMaximized .dragMinButton .message,
.sidebarMinimized .dragMinButton .message {
    /*color: transparent;*/
}

.embeddedServiceHelpButton div.helpButton .helpButtonEnabled {
    height: 0px;
}

.dragActiveChatButton {
    cursor: pointer;
    height: 46px;
    /*width: 60px;*/
    padding: 0 15px;
    min-width: fit-content;
    background-color: #99C221;
    font-family: "Arial", sans-serif;
    border: none;
    border-radius: 23px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 12px 0px;
}

.embeddedServiceSidebar button.activeAgent {
    height: 0px;
    padding: 0px;
    border: none;
}

.activeAgentDragButtonIcon::before {
    font-family: "embeddedserviceiconfont";
    font-size: 1.25em;
    content: attr(data-icon);
    color: white;
    speak: none;
    text-rendering: auto;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    line-height: 44px;
}

.dragActiveChatButton .chatmsg {
    color: white;
    position: relative;
    top: -3px;
    margin-left: 5px;
}
`;
