import React, { useState, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { Input, Select, Menu } from 'components/ant';
import { Button, Modal, InputNumber, Form } from 'antd';
import { rowRenderer, isFormValid } from './utils/helpers';
import { JobItems, UNITS, JOB_TYPES, LocationType, MAX_INT_VALUE } from './utils/constants';
import { DocumentsDisplay as ItemsTable } from 'components/Documents';
import { GeoSuggest } from 'components/GeoSuggest';
import style from './scss/Runsheet.module.scss';
import { v4 as uuidv4 } from 'uuid';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { BUTTON_IDS } from 'utils/globalConstants';

const columnComponents = (
  data,
  isLocationTypeAddress,
  callbacks = {},
  defaultValue,
  t,
  localization
) => {
  return {
    [JobItems.NAME.key]: <Input size="large" placeholder={t('SmartJobs.Input.Placeholder.Name')} />,
    [JobItems.JOB_TYPE.key]: (
      <Select
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.SelectOption')}
        data={JOB_TYPES.map(item => ({
          ...item,
          label: t(`SmartJobs.Input.JobType.${item.label}`)
        }))}
      />
    ),
    [JobItems.LOCATION_TYPE.key]: (
      <Select
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.SelectOption')}
        data={[
          { id: LocationType.CUSTOMER, label: t('SmartJobs.Input.LocationType.Customer') },
          { id: LocationType.ADDRESS, label: t('SmartJobs.Input.LocationType.Address') }
        ]}
        onChange={() => {}}
      />
    ),
    [JobItems.LOCATION.key]: isLocationTypeAddress ? (
      <GeoSuggest
        autoComplete="off"
        className={style.geoSuggestContainer}
        inputClassName={style.geoSuggestInputs}
        onSuggestSelect={suggest => {
          callbacks.updateLocation(suggest, true);
        }}
        initialValue={defaultValue}
      />
    ) : (
      <Select
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.SelectOption')}
        data={data}
        onChange={value => callbacks.updateLocation(value)}
      />
    ),
    [JobItems.NOTES.key]: (
      <Input.TextArea
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.WriteSomething')}
        rows={6}
      />
    ),
    // items
    [JobItems.QUANTITY.key]: (
      <InputNumber
        size="large"
        className={style.inputNumber}
        placeholder={t(`SmartJobs.Input.Placeholder.${JobItems.QUANTITY.name}`)}
        min={0}
      />
    ),
    [JobItems.ITEM_TYPE.key]: (
      <Select
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.SelectOption')}
        data={data}
      />
    ),
    [JobItems.WEIGHT.key]: (
      <InputNumber
        size="large"
        className={style.inputNumber}
        placeholder={t(`SmartJobs.Input.Placeholder.${JobItems.WEIGHT.name}`)}
        min={0}
      />
    ),
    [JobItems.UNIT.key]: (
      <Select
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.SelectOption')}
        data={UNITS.map(unit => ({
          id: localization.formats.weight[unit.id]?.unit,
          label: t(`SmartJobs.Input.Unit.${localization.formats.weight[unit.id]?.unit}`)
        }))}
        onChange={() => {}}
      />
    ),
    [JobItems.DESCRIPTION.key]: (
      <Input.TextArea
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.WriteSomething')}
        rows={6}
        maxLength="150"
      />
    )
  };
};

const getFormColumn = (
  config,
  required,
  validationMessage,
  data,
  isAddress,
  callbacks = {},
  defaultValue,
  max,
  maxValueMessage,
  span = 12,
  translate,
  localization
) => ({
  span,
  style: { textAlign: 'right' },
  item: {
    label: translate(`SmartJobs.Input.Label.${config.key}`),
    labelAlign: 'left',
    key: config.key,
    component: columnComponents(data, isAddress, callbacks, defaultValue, translate, localization)[
      config.key
    ],
    other: {
      rules: [
        required && {
          required,
          message: validationMessage
        },
        max !== undefined && {
          max,
          message: maxValueMessage,
          type: 'number'
        }
      ].filter(Boolean)
    }
  }
});

const getFormRows = (
  type = JobKeys.DETAILS,
  data = {},
  translate = n => n,
  localization,
  form,
  callbacks = {},
  defaultValues = {}
) => {
  const formValues = form?.getFieldsValue();

  const isLocationTypeAddress =
    (formValues && formValues[JobItems.LOCATION_TYPE.key] === LocationType.ADDRESS) ||
    defaultValues.locationType === LocationType.ADDRESS;

  // Details rows config
  const detailsRows = [
    {
      columns: [
        { item: JobItems.NAME, required: true, message: i18n.t('SmartJobs.JobDetails.selectName') },
        {
          item: JobItems.JOB_TYPE,
          required: true,
          message: i18n.t('SmartJobs.JobDetails.selectJobType')
        }
      ]
    },
    {
      columns: [
        {
          item: JobItems.LOCATION_TYPE,
          required: true,
          message: i18n.t('SmartJobs.JobDetails.selectLocationType')
        },
        {
          item: JobItems.LOCATION,
          required: true,
          message: i18n.t('SmartJobs.JobDetails.addLocation'),
          data: data.locations,
          isLocationTypeAddress,
          callbacks,
          defaultValue: defaultValues.location
        }
      ]
    },
    {
      columns: [
        {
          item: JobItems.NOTES
        }
      ]
    }
  ];
  // Items rows config

  const itemsRows = [
    {
      columns: [
        {
          item: JobItems.QUANTITY,
          required: true,
          message: i18n.t('SmartJobs.JobDetails.selectQuantity'),
          max: MAX_INT_VALUE,
          maxValueMessage: i18n.t('SmartJobs.JobDetails.quantityMaxValue', {
            maxValue: MAX_INT_VALUE
          })
        },
        {
          item: JobItems.ITEM_TYPE,
          required: true,
          message: i18n.t('SmartJobs.JobDetails.selectItemType'),
          data: data.types
        }
      ]
    },
    {
      columns: [
        {
          item: JobItems.WEIGHT,
          max: MAX_INT_VALUE,
          maxValueMessage: i18n.t('SmartJobs.JobDetails.weightMaxValue', {
            maxValue: MAX_INT_VALUE
          })
        },
        {
          item: JobItems.UNIT
        }
      ]
    },
    {
      columns: [{ item: JobItems.DESCRIPTION }]
    }
  ];
  // Render the correct rows
  const rows = type === JobKeys.DETAILS ? detailsRows : itemsRows;

  return rows.map(row => {
    return {
      columns: row.columns.map(column => {
        const span = [JobItems.NOTES.key, JobItems.DESCRIPTION.key].includes(column.item.key)
          ? 24
          : 12;

        return getFormColumn(
          column.item,
          column.required,
          column.message,
          column.data,
          column.isLocationTypeAddress,
          column.callbacks,
          column.defaultValue,
          column.max,
          column.maxValueMessage,
          span,
          translate,
          localization
        );
      })
    };
  });
};

const JobKeys = {
  DETAILS: 'job_details',
  ITEMS: 'job_items'
};

export const JobForm = ({
  isOpen,
  onAddJob,
  onCancel,
  locations,
  editing,
  localization,
  itemTypes
}) => {
  const { t } = useTranslation();

  const [jobForm] = Form.useForm();
  const [itemsForm] = Form.useForm();

  const [selectedMenuItem, selectMenuItem] = useState(JobKeys.DETAILS);
  const [currentJob, updateCurrentJob] = useState({ externalId: uuidv4() });
  const formValues = jobForm.getFieldValue();

  const isAddItemDisabled = false;

  const isFormDetailsValid = isFormValid(formValues, [
    JobItems.NAME.key,
    JobItems.LOCATION_TYPE.key,
    JobItems.LOCATION.key,
    JobItems.JOB_TYPE.key
  ]);

  // @TODO move this
  const getLocationLabel = (job = {}) => {
    const { location, locationType, stop } = job;
    if (!location && !stop) {
      return;
    }

    if (typeof location === 'number') {
      return location;
    }

    if (locationType === LocationType.CUSTOMER) {
      return location?.location?.id || stop?.location?.id;
    } else {
      if (!stop && typeof location === 'object') {
        return location?.location?.name;
      } else if (!stop && typeof location === 'string') {
        return location;
      }

      return stop.description;
    }
  };

  useEffect(() => {
    if (editing && isOpen) {
      const location = getLocationLabel(editing);

      jobForm.setFieldsValue({ ...editing, location });
      updateCurrentJob({ ...editing, location });
    } else {
      resetJob({ externalId: uuidv4() });
    }
  }, [editing, isOpen]);

  const handleSelect = e => selectMenuItem(e.key);

  const updateLocation = (value, isGeo) => {
    if (isGeo) {
      updateCurrentJob({ ...currentJob, stop: value });
      return;
    }
    const { stop, ...updatedJob } = currentJob;
    updateCurrentJob({ ...updatedJob, location: value });
  };

  const renderFormItems = t => {
    const defaultLocation = currentJob?.location;

    if (selectedMenuItem === JobKeys.DETAILS) {
      const detailsRows = getFormRows(
        JobKeys.DETAILS,
        {
          locations: locations.map(location => ({ id: location.id, label: location.name }))
        },
        t,
        localization,
        jobForm,
        { updateLocation },
        { location: defaultLocation, locationType: currentJob?.locationType }
      );

      return rowRenderer(detailsRows);
    }
  };

  const handleAddItem = values => {
    // Add an id to this item so we'll know which one to delete later on
    const currentItems = currentJob.jobItems || [];
    // const generate uuid
    const newItem = { ...values, uuid: uuidv4() };

    // push
    currentItems.push(newItem);

    updateCurrentJob({ ...currentJob, jobItems: currentItems });

    // reset items form
    itemsForm.resetFields();
  };

  const handleNext = e => {
    e.preventDefault();

    selectMenuItem(JobKeys.ITEMS);
  };

  const handleValuesChange = (changed, all) => {
    updateCurrentJob({ ...currentJob, ...changed });
  };

  const handleFinishJobAdd = () => {
    onAddJob(currentJob);

    // Reset
    resetJob();
  };

  const handleCancel = () => {
    confirmationModal(
      t('SmartJobs.Confirmation.confirm'),
      t('SmartJobs.Confirmation.changes'),
      t('SmartJobs.Confirmation.cancel'),
      t('SmartJobs.Confirmation.stay'),
      () => {
        onCancel();
        resetJob();
      }
    );
  };

  const resetJob = params => {
    // Reset Fields
    jobForm.resetFields();

    // Reset Menu Item
    selectMenuItem(JobKeys.DETAILS);

    // Reset Items
    updateCurrentJob(params);
  };

  const footer = t => {
    return (
      <>
        {selectedMenuItem === JobKeys.DETAILS ? (
          <Button
            type="primary"
            onClick={handleNext}
            disabled={!isFormDetailsValid}
            id={BUTTON_IDS.jobFormNext}
          >
            {t('SmartJobs.Next')}
          </Button>
        ) : (
          <Button type="primary" onClick={handleFinishJobAdd} id={BUTTON_IDS.jobFormFinish}>
            {t('SmartJobs.Finish')}
          </Button>
        )}
        <Button type="secondary" onClick={handleCancel} id={BUTTON_IDS.jobFormCancel}>
          {t('SmartJobs.Cancel')}
        </Button>
      </>
    );
  };

  const itemsTableColumns = [
    { ...JobItems.QUANTITY, name: t(`SmartJobs.Input.Label.${JobItems.QUANTITY.key}`) },
    { ...JobItems.ITEM_TYPE, name: t(`SmartJobs.Input.Label.${JobItems.ITEM_TYPE.key}`) },
    { ...JobItems.DESCRIPTION, name: t(`SmartJobs.Input.Label.${JobItems.DESCRIPTION.key}`) },
    { ...JobItems.WEIGHT, name: t(`SmartJobs.Input.Label.${JobItems.WEIGHT.key}`) },
    { ...JobItems.ACTIONS, name: t(`SmartJobs.Input.Label.${JobItems.ACTIONS.key}`) }
  ];

  const handleDeleteItem = uuid => () => {
    updateCurrentJob({
      ...currentJob,
      jobItems: currentJob.jobItems.filter(item =>
        item.id ? item.id !== uuid : item.uuid !== uuid
      )
    });
  };

  const getItemsTableRows = (items = []) => {
    let updatedItems = items.map(item => {
      const currentItem = {
        ...item,
        actions: handleDeleteItem(item.uuid || item.id)
      };

      for (const itemKey in currentItem) {
        // In case we're rendering weight - combine weight with units for the table display
        if (itemKey === JobItems.WEIGHT.key) {
          currentItem[itemKey] = `${currentItem[itemKey] || ''} ${currentItem[JobItems.UNIT.key] ||
            ''}`;
        }
      }
      return currentItem;
    });

    return updatedItems;
  };

  const getItemsForTable = () =>
    currentJob.jobItems.map(item => ({
      ...item,
      type: itemTypes.find(type => type.code === item.type)?.description || item.type
    }));
  const menuItems = useMemo(() => {
    return [
      {
        key: JobKeys.DETAILS,
        label: t('SmartJobs.addJobDetails')
      },
      {
        key: JobKeys.ITEMS,
        label: t('SmartJobs.addJobItems'),
        disabled: !isFormDetailsValid
      }
    ];
  }, [t, isFormDetailsValid]);

  return (
    <Modal
      title={t('SmartJobs.addNewJob')}
      open={isOpen}
      onOk={() => {}}
      onCancel={handleCancel}
      footer={footer(t)}
      width={914}
      centered
      forceRender
    >
      <div style={{ width: '100%' }}>
        <Menu
          onClick={handleSelect}
          selectedKeys={[selectedMenuItem]}
          mode="horizontal"
          items={menuItems}
        />
        <div className={style.jobFormContainer}>
          <Form form={jobForm} onValuesChange={handleValuesChange} name="JobForm" layout="vertical">
            {renderFormItems(t)}
          </Form>
        </div>
        {selectedMenuItem !== JobKeys.DETAILS && (
          <Form form={itemsForm} onFinish={handleAddItem} name="ItemForm" layout="vertical">
            {rowRenderer(
              getFormRows(
                JobKeys.ITEMS,
                {
                  types: itemTypes.map(itemType => ({
                    id: itemType.code,
                    label: itemType.description
                  }))
                },
                t,
                localization
              )
            )}
            <Button
              type="secondary"
              htmlType="submit"
              htmlFor="#ItemForm"
              disabled={isAddItemDisabled}
              id={BUTTON_IDS.jobFormAdd}
            >
              {t('SmartJobs.addItem')}
            </Button>
            {(currentJob.jobItems || editing?.jobItems) && (
              <ItemsTable
                columns={itemsTableColumns}
                rows={getItemsTableRows(getItemsForTable())}
              />
            )}
          </Form>
        )}
      </div>
    </Modal>
  );
};
