import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import moment from 'moment';
import { entities } from 'features/permissions';

import { sortStrings, formatForCsv } from 'utils/strings';
import {
  ColumnKeys,
  CONTACTS_TABLE_COLUMNS,
  PATHS,
  DATA_TYPE_FOR_AM,
  INDEX_PATHNAME_FOR_BEGINING_LOCATION_ID,
  EDIT_LAYOUT_MODAL,
  DISABLED_STATUS_STRING,
  STATUSES_IN_GRID,
  DATA_TYPE_MESSAGES,
  MODAL_TYPE_DELETE,
  notificationsObject
} from './constants';
import { ActionMenu } from 'components/actionMenu';
import { deleteContact, restoreContact } from 'features/contacts';
import { displayUSTimezone } from 'features/regions/timezones';
import { getFormattedAddressStr as getFormattedAddress } from 'components/form/address';
const getCoordinates = gps => {
  if (!gps || gps.Lat === 0 || gps.Lng === 0) return {};

  const gpsPosition = `${gps.Lat || ''}, ${gps.Lng || ''}`;

  const isGpsWithinRange = gps.Lat >= -90 && gps.Lat <= 90 && gps.Lng >= -180 && gps.Lng <= 180;
  const mapCoordinates = isGpsWithinRange && {
    lat: gps.Lat,
    lng: gps.Lng
  };

  return {
    gpsPosition,
    mapCoordinates
  };
};

const getFormattedGeofences = geofences => {
  return geofences && geofences.length > 0
    ? geofences
        .map(geofence => geofence?.name)
        .filter(name => !!name)
        .join(', ')
    : '';
};

export const locationHelpers = {
  getFormattedAddress,
  getCoordinates,
  getFormattedGeofences
};

//contacts

export const prepareColumnsForTable = translate =>
  CONTACTS_TABLE_COLUMNS.map(column => ({
    ...column,
    title: translate(`Locations.Contacts.Table.Columns.${column.key}`)
  }));

export const prepareContactsDataForTable = ({
  contacts = [],
  id,
  can = () => {},
  entities = [],
  handleOpenEditContactForm = () => {},
  t
}) => {
  return contacts.map(contact => {
    return {
      key: contact.id,
      [ColumnKeys.NAME]: can({ everyEntity: [entities.CONTACT_VIEW] }) ? (
        <Link to={`/settings/locations/id/${id}/contacts/id/${contact.id}`}>
          {contact[ColumnKeys.NAME]}
        </Link>
      ) : (
        <span>{contact[ColumnKeys.NAME]}</span>
      ),
      [ColumnKeys.EMAIL]: contact[ColumnKeys.EMAIL],
      [ColumnKeys.EMAIL_NOTIFICATION]: notificationConverter(
        contact[ColumnKeys.EMAIL_NOTIFICATION],
        t
      ),
      [ColumnKeys.MOBILE]: contact[ColumnKeys.MOBILE],
      [ColumnKeys.MOBILE_NOTIFICATION]: notificationConverter(
        contact[ColumnKeys.MOBILE_NOTIFICATION],
        t
      ),
      [ColumnKeys.TIMEZONE]: displayUSTimezone(contact[ColumnKeys.TIMEZONE]),
      [ColumnKeys.STATUS]:
        contact[ColumnKeys.STATUS] !== DISABLED_STATUS_STRING
          ? STATUSES_IN_GRID(t).ACTIVE
          : STATUSES_IN_GRID(t).DELETED,
      [ColumnKeys.ACTIONS]: (
        <ActionMenu
          data={contact}
          paths={convertContactPaths(id)}
          dataType={DATA_TYPE_FOR_AM}
          formLayout={EDIT_LAYOUT_MODAL}
          handleOpenModalForm={handleOpenEditContactForm}
          dataTypeMessages={DATA_TYPE_MESSAGES}
          modalType={MODAL_TYPE_DELETE}
          deleteAction={deleteContact}
          restoreAction={restoreContact}
        />
      )
    };
  });
};

export const notificationConverter = (notificationArray, t) => {
  const notificationConversionArray = notificationArray.map(notif => notificationsObject(t)[notif]);
  const notificationCommaSeparated = notificationConversionArray?.join(', ');
  return notificationCommaSeparated;
};

const convertContactPaths = locationId => {
  const pathsKeys = Object.keys(PATHS);
  return pathsKeys.reduce((acc, key) => {
    acc[key] = `/settings/locations/id/${locationId}${PATHS[key]}`;
    return acc;
  }, {});
};

export const getLocationIDFromPathname = pathname => {
  const indexEndingId = pathname.indexOf('/contacts/id/');
  const locationID = pathname.substring(
    INDEX_PATHNAME_FOR_BEGINING_LOCATION_ID,
    indexEndingId !== -1 ? indexEndingId : pathname.length
  );
  return locationID;
};

export const getTimezones = regions => {
  const timezones = regions.map(region => Object.values(JSON.parse(region.timezone))).flat();
  return timezones.map(timezone => {
    return { id: timezone, label: timezone };
  });
};

export const prepareFileForExcelExport = ({ locations, companies, dateFormat }) => {
  const rows = locations
    ? locations.map(location => {
        const { address, GPS, geofences } = location;
        const company = companies.find(company => company.id === location.companyId);

        const formattedGeofences = getFormattedGeofences(geofences);
        const fullAddress = getFormattedAddress(address);
        const { gpsPosition } = getCoordinates(GPS);

        const row = {
          [i18n.t('ExportToExcel.Name')]: location.name || '',
          [i18n.t('ExportToExcel.Company')]: company?.name || '',
          [i18n.t('ExportToExcel.Address')]: fullAddress || '',
          [i18n.t('ExportToExcel.GPSPosition')]: gpsPosition || '',
          [i18n.t('ExportToExcel.Geofences')]: formattedGeofences ? formattedGeofences : '',
          [i18n.t('ExportToExcel.Type')]:
            location.type && location.type.name ? location.type.name : '',
          [i18n.t('ExportToExcel.SiteNotes')]: location?.siteNotes || '',
          [i18n.t('ExportToExcel.ExternalID')]: location.externalId ? location.externalId : '',
          [i18n.t('ExportToExcel.Status')]: location?.status || '',
          [i18n.t('ExportToExcel.CreatedAt')]: moment(location.createdAt).format(dateFormat),
          [i18n.t('ExportToExcel.UpdatedAt')]: moment(location.updatedAt).format(dateFormat)
        };

        return row;
      })
    : [];

  return rows;
};

export const getCSVFilename = ({ entityName, companyName, extension = 'csv' }) => {
  if (!entityName || !companyName) {
    return;
  }
  const timestamp = moment()
    .local()
    .format('YYMMDD_HH:mm');
  return `${entityName}_${companyName}_${timestamp}.${extension}`;
};

export const prepareDataForCsvExport = data => {
  const result = data.map(entry => ({
    [i18n.t('Locations.CSV.Name')]: formatForCsv(entry.name),
    [i18n.t('Locations.CSV.Type')]: entry.type?.name,
    [i18n.t('Locations.CSV.ExternalId')]: entry.externalId,
    [i18n.t('Locations.CSV.Number')]: formatForCsv(entry?.address?.number),
    [i18n.t('Locations.CSV.Street')]: formatForCsv(entry?.address?.street),
    [i18n.t('Locations.CSV.Suburb')]: formatForCsv(entry.address?.suburb),
    [i18n.t('Locations.CSV.State')]: formatForCsv(entry.address?.stateName),
    [i18n.t('Locations.CSV.Postcode')]: formatForCsv(entry.address?.postcode),
    [i18n.t('Locations.CSV.Country')]: formatForCsv(entry.address?.country),
    [i18n.t('Locations.CSV.Latitude')]: entry.GPS?.Lat,
    [i18n.t('Locations.CSV.Longitude')]: entry.GPS?.Lng,
    [i18n.t('Locations.CSV.SiteNotes')]: formatForCsv(entry.siteNotes)
  }));
  return result;
};

const getPrimaryButton = ({ can, history }) => {
  if (!can({ everyEntity: [entities.LOCATION_CREATE] })) {
    return;
  }
  return {
    name: i18n.t('Locations.AddNewLocation'),
    onClick: () => {
      history.push(`${PATHS.NEW_LOCATION}`);
    }
  };
};

const getMoreButtons = ({
  handleExportExcel,
  handleBulkImport,
  handleExportCSV,
  isExportCsvAllowed = true,
  isExportExcelAllowed = true
}) => {
  return [
    {
      name: i18n.t('Common.BulkImport'),
      onClick: handleBulkImport,
      canPermissions: {
        everyEntity: [entities.LOCATION_CREATE, entities.BULKIMPORT]
      },
      id: 'btn_bulkImport'
    },
    {
      name: i18n.t('Common.ExportToCSV'),
      onClick: handleExportCSV,
      disabled: !isExportCsvAllowed,
      id: 'btn_exportCsv'
    },
    {
      name: i18n.t('Common.ExporttoExcel'),
      onClick: handleExportExcel,
      disabled: !isExportExcelAllowed,
      id: 'btn_exportExcel'
    }
  ].sort((a, b) => sortStrings(a.name, b.name));
};

export const helpers = {
  getPrimaryButton,
  getMoreButtons
};
