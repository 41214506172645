import React from 'react';

import { Tag } from 'antd';

import { Grid, ExpandIcon } from 'components/tn';
import { ActionsMenu } from './ActionsMenu';
import { Comparators } from 'utils/sorting';
import { Can, entities } from 'features/permissions';
import { useTranslation } from 'react-i18next';

export const MassConfigurationsTable = ({ data, onEdit, onDelete, ...props }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('MassManagement.Field.ConfigurationName'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 200,
      render: (name, record) => {
        if (name && record.hasOwnProperty('vehicleCombinationId')) {
          return <span>{name}</span>;
        }
      },
      isSearchable: true,
      isFilterable: true,
      defaultSortColumn: true,
      defaultSortOrder: 'ascend',
      sorter: Comparators.String('name')
    },
    {
      title: t('MassManagement.VehicleCombination'),
      dataIndex: ['vehicleCombination', 'name'],
      key: 'vehicleCombination',
      width: 200,
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.String('vehicleCombination.name')
    },
    {
      title: t('MassManagement.Field.AxleGroups'),
      dataIndex: ['vehicleCombination', 'axleGroups'],
      key: 'axleGroups',
      width: 150,
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.Number('vehicleCombination.axleGroups')
    },
    {
      title: t('MassManagement.Field.Axles'),
      dataIndex: ['vehicleCombination', 'axles'],
      key: 'axles',
      width: 125,
      isSearchable: true,
      isFilterable: true,
      sorter: Comparators.Number('vehicleCombination.axles')
    },
    {
      title: t('MassManagement.Field.Scheme'),
      dataIndex: 'name',
      key: 'massSchemes',
      width: 125,
      isSearchable: true,
      render: (scheme, record) => {
        if (scheme && !record.hasOwnProperty('vehicleCombinationId')) {
          let color = '';
          switch (scheme) {
            case 'GML':
            case 'AMMS1':
              color = 'geekblue';
              break;
            case 'CML':
            case 'AMMS2':
              color = 'orange';
              break;
            case 'HML':
            case 'AMMS3':
              color = 'volcano';
              break;
            default:
              color = 'magenta';
          }

          return (
            <Tag color={color} key={scheme}>
              {scheme.toUpperCase()}
            </Tag>
          );
        }
      }
    },
    {
      title: `${t('MassManagement.Field.GrossMass')} (t)`,
      dataIndex: 'grossMass',
      key: 'grossMass',
      width: 150
    },
    {
      title: `${t('MassManagement.Field.Steer')} (t)`,
      dataIndex: 'steer',
      key: 'steer',
      width: 125
    },
    {
      title: `${t('MassManagement.Field.Drive')} (t)`,
      dataIndex: 'drive',
      key: 'drive',
      width: 125
    },
    {
      title: `${t('MassManagement.Field.GroupNumber', { number: 3 })} (t)`,
      dataIndex: 'group3',
      key: 'group3',
      width: 125
    },
    {
      title: `${t('MassManagement.Field.GroupNumber', { number: 4 })} (t)`,
      dataIndex: 'group4',
      key: 'group4',
      width: 125
    },
    {
      title: `${t('MassManagement.Field.GroupNumber', { number: 5 })} (t)`,
      dataIndex: 'group5',
      key: 'group5',
      width: 125
    },
    {
      title: `${t('MassManagement.Field.GroupNumber', { number: 6 })} (t)`,
      dataIndex: 'group6',
      key: 'group6',
      width: 125
    },
    {
      title: `${t('MassManagement.Field.GroupNumber', { number: 7 })} (t)`,
      dataIndex: 'group7',
      key: 'group7',
      width: 125
    },
    {
      title: `${t('MassManagement.Field.GroupNumber', { number: 8 })} (t)`,
      dataIndex: 'group8',
      key: 'group8',
      width: 125
    },
    {
      title: t('Common.TableColumns.Actions'),
      key: 'action',
      align: 'center',
      width: 100,
      fixed: 'right',
      render: record =>
        record.massSchemes && (
          <Can everyEntity={[entities.VEHICLE_UPDATE]}>
            <ActionsMenu
              record={record}
              entityType={t('MassManagement.MassConfiguration')}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </Can>
        )
    },
    {
      title: '',
      dataIndex: 'expand',
      key: 'expand',
      fixed: 'right',
      width: 75
    }
  ];

  return (
    <Grid
      data={data}
      columns={columns}
      rowKey={'id'}
      recordTypeSingular={t('MassManagement.MassConfiguration')}
      recordTypePlural={t('MassManagement.MassConfigurations')}
      expandIconColumnIndex={15}
      expandIconAsCell={true}
      expandIcon={props => {
        return props.record.massSchemes && ExpandIcon(props);
      }}
      {...props}
    />
  );
};
