import React from 'react';
import { Tag } from 'antd';

export const MultiValueInput = ({ items }) => {
  return (
    <div>
      {items.map(item => (
        <Tag closable={item.onClose}>{item.label}</Tag>
      ))}
    </div>
  );
};
