import React from 'react';
import P from 'prop-types';
import style from './DocumentsDisplay.module.scss';
import { DeleteFilled } from '@ant-design/icons';
import { formatBytes } from 'utils/methods';
import i18n from 'i18next';

const getDefaultColumns = () => {
  return [
    { name: i18n.t('VehicleMntSchedules.View.File'), key: 'file' },
    { name: i18n.t('VehicleMntSchedules.View.FileSize'), key: 'fileSize' },
    { name: i18n.t('VehicleMntSchedules.View.UploadedBy'), key: 'uploadedBy' },
    { name: i18n.t('VehicleMntSchedules.View.UploadedDate'), key: 'uploadDate' },
    { name: i18n.t('VehicleMntSchedules.View.Actions'), key: 'actions' }
  ];
};

const renderRows = (rows, columns) => {
  if (!rows.length)
    return <div className={style.noData}>{i18n.t('VehicleMntSchedules.View.NoData')}</div>;
  const rowColumns = columns || getDefaultColumns();

  return rows.map((row, rowIndex) => (
    <div className={style.row} key={rowIndex}>
      {rowColumns.map(column => {
        return (
          <div className={style.data} key={column.key}>
            {column.key === 'actions' && row.actions ? (
              <span className={style.action}>
                <DeleteFilled onClick={row.actions} />
              </span>
            ) : column.key === 'fileSize' ? (
              formatBytes(row[column.key])
            ) : (
              row[column.key]
            )}
          </div>
        );
      })}
    </div>
  ));
};

export const DocumentsDisplay = ({ rows, columns, customStyle }) => {
  const tableColumns = columns || getDefaultColumns();

  return (
    <div className={customStyle || style.container}>
      <div className={style.header}>
        {tableColumns.map(column => (
          <div className={style.data} key={column.key}>
            {column.name}
          </div>
        ))}
      </div>
      <div className={style.body}>{renderRows(rows, columns)}</div>
    </div>
  );
};

DocumentsDisplay.propTypes = {
  i18n: P.shape({
    noData: P.string
  }),
  rows: P.arrayOf(
    P.shape({
      id: P.node,
      file: P.node,
      fileSize: P.node,
      uploadedBy: P.node,
      uploadDate: P.node,
      actions: P.func
    })
  )
};

DocumentsDisplay.defaultProps = {
  i18n: {
    noData: 'No data in the table.'
  },
  rows: []
};
