import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareAuthHeaders } from 'services/util';
import { API_PATH } from 'config';
import { parseErrorMessage } from 'utils/strings';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18n from 'i18nextConfig';
import React from 'react';

export const ngAgreementApi = createApi({
  reducerPath: 'ngVehicleAgreementApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  tagTypes: ['companyAgreement'],
  endpoints: builder => ({
    getVehicleAgreements: builder.query({
      query: ({ id, embed }) => `agreements/vehicle/${id}${embed ? '?embed=' + embed : ''}`
    }),
    getDeviceAgreements: builder.query({
      query: ({ id, embed }) => `agreements/device/${id}${embed ? '?embed=' + embed : ''}`
    }),
    getCompanyAgreements: builder.query({
      query: ({ id, embed }) => `agreements/company/${id}${embed ? '?embed=' + embed : ''}`,
      providesTags: ['companyAgreement']
    }),
    addCompanyAgreements: builder.mutation({
      query: payload => {
        let url = `agreements/company/${payload.companyId}`;

        if (Array.isArray(payload.agreement)) {
          url += '/list';
        }

        return {
          url: url,
          method: 'POST',
          body: JSON.stringify(payload.agreement)
        };
      },
      invalidatesTags: ['companyAgreement']
    }),
    deleteCompanyAgreements: builder.mutation({
      query: payload => {
        return {
          url: `agreements/company/${payload.companyId}/${payload.agreementId}`,
          method: 'DELETE'
        };
      },
      invalidatesTags: ['companyAgreement']
    })
  })
});

export const {
  useGetVehicleAgreementsQuery,
  useGetDeviceAgreementsQuery,
  useGetCompanyAgreementsQuery,
  useAddCompanyAgreementsMutation,
  useDeleteCompanyAgreementsMutation
} = ngAgreementApi;

export const executeAddCompanyAgreementsAction = (payload, action, dispatch) => {
  return new Promise((resolve, reject) => {
    action(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          data,
          dispatch,
          {
            success: i18n.t('Agreement.Notifications.AssignSuccess', {
              target: payload.companyName
            }),
            partial: i18n.t('Agreement.Notifications.PartialAssignSuccess')
          },
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

export const executeDeleteCompanyAgreementsAction = (payload, action, dispatch) => {
  return new Promise((resolve, reject) => {
    action(payload)
      .unwrap()
      .then(data => {
        handleSuccessResult(
          data,
          dispatch,
          {
            success: i18n.t('Agreement.Notifications.DeleteSuccess'),
            partial: i18n.t('Agreement.Notifications.DeleteFailed')
          },
          resolve
        );
      })
      .catch(error => {
        handleException(dispatch, error, reject);
      });
  });
};

const handleException = (dispatch, error, reject) => {
  if (error) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseErrorMessage(error.data)
      })
    );
  }

  if (reject) {
    reject(error);
  }
};

const handleSuccessResult = (data, dispatch, msg, resolve) => {
  if (data?.errorCount !== undefined && data?.errorCount !== 0) {
    const mainMessage = i18n.t(msg.partial, {
      failedCount: data.errorCount
    });

    const messageArr = [mainMessage, ...data.errors];
    dispatch(
      openToast({
        type: ToastType.Warning,
        message: messageArr
      })
    );
  } else {
    dispatch(
      openToast({
        type: ToastType.Success,
        message: i18n.t(msg.success)
      })
    );
  }

  if (resolve) {
    resolve();
  }
};
