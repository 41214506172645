import React from 'react';
import { Card } from 'components/ant/Card';
import cn from 'classnames';
import { getEventTypeComponent } from 'components/ant/Charts/pie-chart/helpers';
import styles from './CardContainer.module.scss';
import { Tooltip } from 'components/ant';

export const CardContainer = ({ title, trend, children, t, previousValue }) => {
  const icon = getEventTypeComponent(trend);
  const arrowClass = cn(styles.arrow, {
    [styles.arrowAscending]: trend > 0,
    [styles.arrowDescending]: trend < 0
  });
  const titleNode =
    trend !== undefined ? (
      <div>
        {title}{' '}
        <Tooltip
          content={`${t('Scorecard.PreviousValue')} ${previousValue}`}
          target={<span className={arrowClass}>{icon}</span>}
        />
      </div>
    ) : (
      title
    );

  return (
    <div className={styles.container}>
      <Card title={titleNode} bordered={false} headStyle={{ borderBottom: '0px' }}>
        {children}
      </Card>
    </div>
  );
};
