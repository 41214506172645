import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders } from 'services/util';

import { API_PATH } from 'config';

export const ngAwsQsEmbedApi = createApi({
  reducerPath: 'ngAwsQsEmbedApi',
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  endpoints: builder => ({
    getQsDashboardUrl: builder.query({
      query: ({ dashboardId }) => ({
        url: `qs/embed/dashboards/${dashboardId}`,
        params: {
          'reset-disabled': true,
          'undo-redo-disabled': true
        }
      }),
      transformResponse: response => response.embedUrl
    }),
    getQsConsoleUrl: builder.query({
      query: ({ analysisId, dashboardId } = {}) => ({
        url: `qs/embed/console${
          analysisId || dashboardId
            ? '?entry-point=' +
              (analysisId ? '/analyses/' + analysisId : '/dashboards/' + dashboardId)
            : ''
        }`,
        responseHandler: 'text'
      })
    }),
    getQsSearchbarUrl: builder.query({
      query: ({ companyId }) => ({
        url: `qs/embed/qsearchbar`,
        params: {
          'initial-topic-id': 'Search_1',
          'session-lifetime-in-minutes': '600'
        }
      }),
      transformResponse: response => response.embedUrl
    })
  })
});

export const {
  useGetQsDashboardUrlQuery,
  useGetQsConsoleUrlQuery,
  useGetQsSearchbarUrlQuery
} = ngAwsQsEmbedApi;
