import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import { Select, Input } from 'components/ant';
import { confirmNavigateAway } from './confirmNavigateAway';

import { axleGroups, axles } from '../Data';
import { VehicleVisSelector } from '../Vis';

import styles from './Modal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const LABEL_ALIGN = 'left';

export const VehicleCombinationModal = ({
  data,
  isOpen = false,
  isNameUnique,
  onSave,
  onClose
}) => {
  const { t } = useTranslation();
  const [vehicleCombinationForm] = Form.useForm();

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [axleGroupCount, setAxleGroupCount] = useState();
  const [axleCount, setAxleCount] = useState();
  const [filteredAxles, setFilteredAxles] = useState(axles);
  const [vis, setVis] = useState();

  const clearForm = (isOnOpen = false) => {
    vehicleCombinationForm.resetFields();
    setIsSaveEnabled(false);
    if (isOnOpen) {
      setVis(null);
      setAxleGroupCount(null);
      setAxleCount(null);
    }
    setIsFormDirty(false);
  };

  const onCancel = () => {
    confirmNavigateAway(
      isFormDirty,
      () => {
        onClose();
        clearForm();
      },
      null,
      t
    );
  };

  const onSaveForm = values => {
    onClose();
    onSave(values);
    clearForm();
  };

  const validateForm = async isDirty => {
    try {
      const values = await vehicleCombinationForm.validateFields();
      setIsSaveEnabled(isDirty);
      return values;
    } catch (errorInfo) {
      setIsSaveEnabled(!errorInfo.errorFields.length && isDirty);
      return errorInfo.values;
    }
  };

  const isFormModified = () => {
    const isModified = data
      ? Object.keys(vehicleCombinationForm.getFieldsValue()).some(
          field =>
            field !== 'visualization' &&
            vehicleCombinationForm.isFieldTouched(field) &&
            vehicleCombinationForm.getFieldValue(field) !== data[field]
        )
      : true;
    return isModified;
  };

  const onFormChanged = changed => {
    if (['axleGroups', 'axles'].some(field => Object.keys(changed).includes(field))) {
      setAxleGroupCount(vehicleCombinationForm.getFieldValue('axleGroups'));
      setAxleCount(vehicleCombinationForm.getFieldValue('axles'));
      setVis(null);
      vehicleCombinationForm.setFieldsValue({ visualization: '{}' });
    }

    const isDirty = isFormModified();
    setIsFormDirty(isDirty);
    validateForm(isDirty);
  };

  const filterAxles = axleGroups => {
    const axleGroupsInt = typeof axleGroups === 'string' ? parseInt(axleGroups) : axleGroups;
    axles?.length && setFilteredAxles(axles.filter(axle => parseInt(axle.label) >= axleGroupsInt));
  };

  const onAxleGroupsChanged = axleGroups => {
    filterAxles(axleGroups);
  };

  const onVisChanged = (vis, onInit = false) => {
    try {
      vehicleCombinationForm.setFieldsValue({ visualization: JSON.stringify(vis) });
      if (!onInit) {
        setIsFormDirty(true);
        if (vehicleCombinationForm.getFieldValue('axles')) {
          validateForm(true);
        }
      }
    } catch (e) {
      console.debug('visualization serialization error: ', e);
    }
  };

  useEffect(() => {
    clearForm(true);

    if (data) {
      try {
        setAxleGroupCount(data.axleGroups);
        setAxleCount(data.axles);
        filterAxles(data.axleGroups);
        data.visualization && setVis(JSON.parse(data.visualization));
        setIsFormDirty(false);
        vehicleCombinationForm.setFieldsValue(data);
      } catch (e) {
        console.debug('visualization deserialization error: ', e);
      }
    }
  }, [data, vehicleCombinationForm]);

  return (
    <Modal
      className={styles.modal}
      title={`${data ? t('Common.Edit') : t('Vehicles.Form.AddNew')} ${t(
        'MassManagement.VehicleCombination'
      )}`}
      open={isOpen}
      centered={true}
      onCancel={onCancel}
      width={1000}
      footer={
        <>
          <Button
            type="primary"
            size="large"
            form="combinationTypeForm"
            key="submit"
            htmlType="submit"
            disabled={!isSaveEnabled}
            id={BUTTON_IDS.vehicleCombinationModalSave}
          >
            {t('Common.Save')}
          </Button>
          <Button id={BUTTON_IDS.vehicleCombinationModalCancel} size="large" onClick={onCancel}>
            {t('Common.Cancel')}
          </Button>
        </>
      }
    >
      <Form
        layout="vertical"
        initialValues={data}
        form={vehicleCombinationForm}
        name="combinationTypeForm"
        onFinish={onSaveForm}
        onValuesChange={onFormChanged}
        onKeyPress={event => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Row align="middle" gutter={[16, 32]} style={{ marginBottom: '32px' }}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={t('MassManagement.Field.VehicleCombinationName')}
              labelAlign={LABEL_ALIGN}
              colon={false}
              rules={[
                {
                  required: true,
                  message: t('MassManagement.Validation.NameRequired', {
                    name: t('MassManagement.Field.VehicleCombinationName')
                  })
                },
                {
                  validator: (rule, value) => {
                    const valid =
                      isNameUnique && value?.toLowerCase()?.trim() !== data?.name?.toLowerCase()
                        ? isNameUnique(value)
                        : true;
                    return valid ? Promise.resolve([]) : Promise.reject(valid);
                  },
                  message: t('MassManagement.Validation.NameUnique', {
                    name: t('MassManagement.Field.VehicleCombinationName')
                  })
                }
              ]}
            >
              <Input
                size={'large'}
                placeholder={t('MassManagement.Field.VehicleCombinationName')}
                maxLength={50}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle" gutter={[16, 32]} style={{ marginBottom: '32px' }}>
          <Col span={12}>
            <Form.Item
              name="axleGroups"
              label={t('MassManagement.Field.AxleGroups')}
              labelAlign={LABEL_ALIGN}
              colon={false}
              rules={[
                {
                  required: true,
                  message: t('MassManagement.Validation.NumberRequired', {
                    name: t('MassManagement.Field.AxleGroups')
                  })
                }
              ]}
            >
              <Select
                placeholder={t('MassManagement.Field.AxleGroups')}
                data={axleGroups}
                size={'large'}
                onChange={onAxleGroupsChanged}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="axles"
              label={t('MassManagement.Field.Axles')}
              labelAlign={LABEL_ALIGN}
              colon={false}
              rules={[
                {
                  required: true,
                  message: t('MassManagement.Validation.NumberRequired', {
                    name: t('MassManagement.Field.Axles')
                  })
                }
              ]}
            >
              <Select
                placeholder={t('MassManagement.Field.Axles')}
                data={filteredAxles}
                size={'large'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="middle" gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item name="visualization">
              <VehicleVisSelector
                axleGroups={axleGroupCount}
                axles={axleCount}
                visualization={vis}
                showHeader={true}
                onVisChanged={onVisChanged}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item hidden name="id">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
