import i18next from 'i18nextConfig';

export const Mode = {
  Lens: 0,
  NearestVehicle: 1
};

export const ModeMethod = {
  get [Mode.Lens]() {
    return i18next.t('Tracking.Proximity.Method.WithinGeofence');
  },
  get [Mode.NearestVehicle]() {
    return i18next.t('Tracking.Proximity.Method.NearestToLocation');
  }
};

export const MsgType = {
  AddressUpdated: 0,
  RadiusUpdated: 1,
  Reset: 2,
  Searching: 3,
  SearchResult: 4,
  DeviceSelected: 5,
  DeviceFocused: 6,
  FilteredResult: 7,
  DeviceLostFocus: 8
};

export const LENS_STEPS = {
  SELECT_LOCATION: 0,
  DRAW_GEOFENCE: 1,
  SPECIFY_TIME_PERIOD: 2,
  SEARCHING: 3,
  SHOW_RESULT: 4
};

export const DefaultRadius = 50;
export const EarthRadius = 6371000;
