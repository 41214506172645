import React, { useEffect, useRef, useState } from 'react';
import styles from './Properties.module.scss';
import { FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import SpecificProperties from './SpecificProperties';
import PrefilledDropdown from './PrefilledDropdown';
import yesnoIcon from 'static/images/icons/formBuilder/yesno.svg';
import textIcon from 'static/images/icons/formBuilder/text.svg';
import selectionIcon from 'static/images/icons/formBuilder/selection.svg';
import numericIcon from 'static/images/icons/formBuilder/numeric.svg';
import okIcon from 'static/images/icons/formBuilder/ok.svg';
import { useTranslation } from 'react-i18next';
import { useCanEveryCompanyService, services } from 'features/permissions';
import {
  useSubCompanyEntityConfig,
  useCurrentCompany,
  CompanyConfigKey
} from 'features/company/companySlice';

const ElementProperties = ({
  relElRef,
  element,
  setProperty,
  setFollowUpProperty,
  setFailedQuestionProperty,
  isDraggable,
  isPretripChecklist,
  ElementDynamicFields = () => null,
  isFailedQuestion,
  setActiveElement
}) => {
  const isCompanySmartJobsEnabled = useCanEveryCompanyService(services.SMARTJOBS);
  const currentCompany = useCurrentCompany();
  const companyId = currentCompany?.id;

  const SFICompanyConfigEnabled = useSubCompanyEntityConfig(
    companyId,
    CompanyConfigKey.SmartJobsIntegration
  );

  const enableSmartjobsDropdown =
    SFICompanyConfigEnabled && isCompanySmartJobsEnabled && !isPretripChecklist;

  const { Text, Type, Invalid } = element;
  const { t } = useTranslation();

  const { ref: wrapperRef, style } = useRelToElement(relElRef);

  const setType = type => {
    setProperty('Type', type);
    switch (type) {
      case 'Yes/No':
      case 'Ok/Not Ok':
        if (type === 'Yes/No') {
          setProperty('Options', [
            { Value: 'Yes', Pass: true },
            { Value: 'No', Pass: false }
          ]);
        } else {
          setProperty('Options', [
            { Value: 'Ok', Pass: true },
            { Value: 'Not Ok', Pass: false }
          ]);
        }
        setProperty('AllowBlank', false);
        setProperty('AllowNonApplicable', false);
        if (type === 'Yes/No') {
          setProperty('Icon', yesnoIcon);
        } else {
          setProperty('Icon', okIcon);
        }
        break;
      case 'Text':
      case 'Numeric':
        setProperty('AllowBlank', false);
        setProperty('Options', null);
        setProperty('AllowNonApplicable', null);
        if (type === 'Text') {
          setProperty('Icon', textIcon);
        } else {
          setProperty('Icon', numericIcon);
        }
        break;
      default:
        setProperty('Options', [
          { Value: '', Pass: true },
          { Value: '', Pass: true }
        ]);
        setProperty('AllowBlank', false);
        setProperty('AllowNonApplicable', null);
        setProperty('Dropdown', false);
        setProperty('Icon', selectionIcon);
        break;
    }
  };

  const handleClickOutsideAntSelect = eventTarget => {
    // Do not close the Properties when the select dropdown is clicked, selected or cleared
    // SVGAnimatedString is used when Clear button is clicked
    if (!eventTarget) {
      return;
    }
    return !(
      eventTarget?.className?.toString()?.includes('ant-select') ||
      eventTarget?.className?.toString()?.includes('SVGAnimatedString')
    );
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        handleClickOutsideAntSelect(event.target)
      ) {
        setActiveElement(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, setActiveElement]);

  return (
    <div
      ref={wrapperRef}
      className={`${styles.wrapper} ${isFailedQuestion && styles.failedQuestion}`}
      style={{ display: !isDraggable ? 'block' : 'none', ...style }}
    >
      <div>
        <div className={styles.title}>
          {`${t(`Forms.ActualForm.${Type}`)} ${t('Forms.ActualForm.PropertiesLowerCase')}`}
        </div>
        <FormGroup>
          <FormLabel style={{ fontSize: '15px' }}>{t('Forms.ActualForm.Label')}</FormLabel>
          <FormControl
            as="input"
            value={Text}
            autoFocus
            onChange={e => setProperty('Text', e.target.value)}
            style={{ height: '40px' }}
            className={Invalid && Text.length === 0 && styles.invalid}
          />
        </FormGroup>
        {isFailedQuestion && (
          <FormGroup>
            <FormLabel style={{ fontSize: '15px' }}>{t('Forms.ActualForm.Type')}</FormLabel>
            <FormControl as="select" value={Type} onChange={e => setType(e.target.value)}>
              <option value="Text">{t('Forms.ActualForm.Text')}</option>
              <option value="Numeric">{t('Forms.ActualForm.Numeric')}</option>
              <option value="Selection">{t('Forms.ActualForm.Selection')}</option>
              <option value="Yes/No">{t('Forms.ActualForm.YesNo')}</option>
              <option value="Ok/Not Ok">{t('Forms.ActualForm.OkNotOk')}</option>
            </FormControl>
          </FormGroup>
        )}
        <SpecificProperties
          isFollowUpElement={isFailedQuestion}
          element={element}
          setProperty={setProperty}
          setFollowUpProperty={setFollowUpProperty}
          setFailedQuestionProperty={setFailedQuestionProperty}
          isPretripChecklist={isPretripChecklist}
          ElementDynamicFields={ElementDynamicFields}
        />
        {enableSmartjobsDropdown && (
          <PrefilledDropdown element={element} setProperty={setProperty} />
        )}
      </div>
    </div>
  );
};

export default ElementProperties;

const useRelToElement = relElRef => {
  const absolElRef = useRef();
  const obserRef = useRef();
  const relToTop = -30;
  const [position, setPosition] = useState();

  useEffect(() => {
    const align = () => {
      if (!relElRef.current || !absolElRef.current) {
        return;
      }
      const relEl = relElRef.current?.getBoundingClientRect();
      const absolEl = absolElRef.current?.getBoundingClientRect();
      if (absolEl && relEl) {
        //align absolEl from relEl's top, otherwise align from bottom
        const bottomBtnsHeight = 65;
        const visibleHeightAlignTop = window.innerHeight - bottomBtnsHeight - relEl.top - relToTop;
        if (visibleHeightAlignTop < absolEl.height) {
          setPosition({ bottom: -Math.abs(window.innerHeight - bottomBtnsHeight - relEl.bottom) });
        } else {
          setPosition({ top: relToTop });
        }
      }
    };
    if (relElRef.current && !obserRef.current) {
      obserRef.current = new IntersectionObserver(align, {
        root: null,
        threshold: 1
      });
      obserRef.current.observe(relElRef.current);
    }
    align();
    return () => {
      if (obserRef.current?.disconnect) {
        obserRef.current.disconnect();
      }
    };
  }, [relElRef, absolElRef, obserRef]);

  return {
    ref: absolElRef,
    style: position
  };
};
