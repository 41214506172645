import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import i18n from 'i18nextConfig';
import { canHistoryGoBack } from 'utils/methods';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { useEffect, useRef } from 'react';

const forms = {
  list: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    isListEmpty: false
  }
};

function startLoading(state, { payload }) {
  state.meta.isFetching = true;
  state.meta.companyKey = payload.companyKey;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.list = [];
  state.meta.companyKey = action.payload.companyKey;
}

const formsSlice = createSlice({
  name: 'forms',
  initialState: forms,
  reducers: {
    fetchFormsStart: startLoading,
    fetchFormsSuccess(state, { payload }) {
      state.list = payload.list.sort((a, b) => a.Name?.localeCompare(b.Name));
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchFormsCancelled(state) {
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = true;
      state.list = [];
    },
    fetchFormsFailure: loadingFailed
  }
});

const {
  fetchFormsStart,
  fetchFormsSuccess,
  fetchFormsFailure,
  fetchFormsCancelled
} = formsSlice.actions;

export const fetchForms = fetchRef => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies?.current?.id;

  const assignRef = value => {
    if (fetchRef) {
      fetchRef.current = value;
    }
  };

  try {
    dispatch(fetchFormsStart({ companyKey }));
    const getRequest = request('GET', `${API_PATH}/forms?embed=fleets&company_id=${companyId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json');

    assignRef(getRequest);

    getRequest.on('abort', () => {
      assignRef(null);
      dispatch(fetchFormsCancelled());
    });

    const response = await getRequest;
    assignRef(null);
    const { body } = response;
    dispatch(fetchFormsSuccess({ list: body, companyKey }));
  } catch (err) {
    console.error(err);
    assignRef(null);
    dispatch(fetchFormsFailure({ err: err.toString(), companyKey }));
  }
};

export const useForms = () => {
  const dispatch = useDispatch();
  const forms = useSelector(state => state.forms.list);
  const isFetching = useSelector(state => state.forms.meta.isFetching);
  const isListEmpty = useSelector(state => state.forms.meta.isListEmpty);
  const companyKey = useSelector(state => state.forms.meta.companyKey);
  const currentCompanyKey = useCurrentCompanyKey();
  const isCompanyKeyDifferent = (currentCompanyKey && companyKey !== currentCompanyKey) || false;

  const couldFetch = !isFetching && !isListEmpty && forms.length === 0;
  const fetchRef = useRef(null);

  useEffect(() => {
    if ((couldFetch || isCompanyKeyDifferent) && !fetchRef.current) {
      dispatch(fetchForms(fetchRef));
    }
  }, [dispatch, couldFetch, isCompanyKeyDifferent]);

  useEffect(() => {
    return () => {
      if (fetchRef.current) {
        fetchRef.current.abort();
        fetchRef.current = null;
      }
    };
  }, [currentCompanyKey]);

  return forms;
};

export const handleDeleteAction = (data, history, userKey, dispatch) => () => {
  const { Id, Name } = data;
  let url = `${API_PATH}/forms/${Id}`;
  let method = 'DELETE';
  Mixpanel.sendTrackEvent(MPTrackingEvents.Settings.Forms.Update, { typeOfUpdate: 'delete' });
  request(method, url)
    .set('Authorization', `Token token="${userKey}"`)
    .then(() => {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('Forms.Notifications.DeleteNotification', {
            name: Name
          })
        })
      );
      dispatch(fetchForms());
      history && canHistoryGoBack(history, '/settings/forms');
    });
};

export const useIsFetching = () => useSelector(state => state.forms.meta.isFetching);

export const useIsFetched = () => {
  const lastFetchedTime = useSelector(state => state.forms.meta.lastFetched);
  const isFetching = useIsFetching();
  return !isFetching && !!lastFetchedTime;
};

export default formsSlice.reducer;
