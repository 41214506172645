import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { format } from 'utils/dates';

//components
import { AuditsTable } from 'components/auditsTable/AuditsTable';

//slices
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits, fetchAudits } from 'features/audits';
import { useLocalization } from 'features/localization/localizationSlice';

// helpers
import { getIDFromPathname } from 'utils/methods';

//constants
import { AUDIT_ENTITY } from 'components/auditsTable/constants';

const API_DATE_FORMAT = 'YYYY-MM-DD';

export const DriverManagementAudits = ({ schedules }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const localization = useLocalization();
  const id = getIDFromPathname(window.location.pathname);
  const audits = useAudits(AUDIT_ENTITY.DRIVER_MANAGEMENT_SCHEDULE, id);
  const schedule = schedules?.find(s => s.id === Number(id));
  const pageTitle = !schedule
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: schedule?.name || '' });

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    dispatch(setBackButton(true));
  }, [pageTitle, dispatch]);

  useEffect(() => {
    dispatch(fetchAudits({ entityName: AUDIT_ENTITY.DRIVER_MANAGEMENT_SCHEDULE, entityId: id }));
  }, [id, dispatch]);

  const auditTimeKeys = useMemo(
    () => [
      {
        key: 'schedulesOn',
        get: schedulesOn =>
          format(moment(schedulesOn, API_DATE_FORMAT), localization.formats.time.formats.dby_imsp)
      },
      {
        key: 'eventOn',
        get: eventOn =>
          format(moment(eventOn, API_DATE_FORMAT), localization.formats.time.formats.dby_imsp)
      }
    ],
    [localization]
  );

  return (
    <AuditsTable
      audits={audits}
      entity={AUDIT_ENTITY.DRIVER_MANAGEMENT_SCHEDULE}
      timeKeys={auditTimeKeys}
    />
  );
};
