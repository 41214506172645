import React from 'react';
import T from 'prop-types';
import { Progress } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'react-bootstrap';
import { Tooltip } from 'components/ant';
import styles from './FileUpload.module.scss';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import { BUTTON_IDS } from 'utils/globalConstants';

const FILE_UPLOAD_ERRORS = {
  fileExists: 'The file already exists',
  uploadLimitReached: 'You have exceeded your monthly limit'
};

const PROGRESS_TRAIL_COLOR = '#dadee3';
const PROGRESS_STROKE_COLOR_BLUE = '#22ADE2';

const handleAddNewFile = () => {
  document.getElementById('hiddenFileInput').click();
};

export const FileUpload = ({
  uploadedFiles,
  uploading,
  progress,
  onAdd,
  onRemove,
  i18n,
  secondary,
  multiple = 'multiple',
  ignoreFileStatus = false,
  accept = 'application/pdf',
  isUploadCapacityFull
}) => {
  const wrapperClasses = cn(styles.wrapper, { [styles.secondary]: !!secondary });
  const containerClasses = cn(styles.container, {
    [styles.secondary]: !!secondary
  });

  const getFileError = file => {
    const errorName = Object.keys(file).find(
      error => file[error] && Object.keys(FILE_UPLOAD_ERRORS).includes(error)
    );

    return FILE_UPLOAD_ERRORS[errorName];
  };

  return (
    <div className={wrapperClasses}>
      <div className={containerClasses}>
        <div className={styles.info}>{i18next.t('VehicleMntSchedules.View.ClickButton')}</div>
        <div className={styles.action}>
          <Button
            className={styles.addNewFileBtn}
            id={BUTTON_IDS.addFile}
            onClick={handleAddNewFile}
            disabled={isUploadCapacityFull}
          >
            {i18next.t('VehicleMntSchedules.View.AddFiles')}
          </Button>
          <input
            type="file"
            id="hiddenFileInput"
            accept={accept}
            multiple={multiple}
            onChange={onAdd}
            onClick={event => {
              event.target.value = '';
            }}
            style={{ display: 'none' }}
          />
        </div>
      </div>
      <div className={styles.uploadContainer}>
        {uploadedFiles.map(file => {
          return (
            file && (
              <div className={styles.fileContainer}>
                <span className={styles.fileName}>{file.name}</span>
                <span className={styles.fileStatus}>
                  {!ignoreFileStatus || file.success ? (
                    <CheckOutlined style={{ color: '#26bd8b', fontSize: 16 }} />
                  ) : (
                    <>
                      <span className={styles.errorIcon}>
                        <CloseOutlined style={{ color: 'red', fontSize: 16 }} />
                      </span>
                      <Tooltip
                        content={getFileError(file)}
                        target={
                          <span className={styles.errorDescription}>
                            {!file.success && `${getFileError(file)}`}
                          </span>
                        }
                      />
                    </>
                  )}
                </span>
                <span className={styles.fileAction}>
                  {(!ignoreFileStatus || file.success) && (
                    <FontAwesomeIcon
                      className={styles.close}
                      icon={faTimes}
                      onClick={onRemove(file.documentId || file.name)}
                    />
                  )}
                </span>
              </div>
            )
          );
        })}
      </div>
      {uploading && (
        <div className={styles.progressWrapper}>
          <div className={styles.fileName}>{uploading}</div>
          <div className={styles.progress}>
            <Progress
              percent={progress}
              strokeColor={PROGRESS_STROKE_COLOR_BLUE}
              trailColor={PROGRESS_TRAIL_COLOR}
              showInfo={false}
              status="active"
            />
          </div>
        </div>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  onAdd: T.func,
  onRemove: T.func,
  progress: T.number,
  i18n: T.shape({
    info: T.string
  }),
  uploading: T.string,
  uploadedFiles: T.arrayOf(T.shape({}))
};

FileUpload.defaultProps = {
  i18n: {
    info: 'Click the button to select a file.'
  },
  uploadedFiles: []
};
