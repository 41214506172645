import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';

import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';

import { api } from 'utils/api';

import * as actions from './actions';

/**
 * Bulk Import API
 * @description The default route is /bulkImport and the payload items {apiEndpoint} and {itemId} will be used for additional endpoints
 * @param itemId It can be {template} or {batchID}
 * @param apiEndpoint It can be /templates, /upload, /status or /errors
 * @param responseType Used for identifying the need of a Blob for downloading files
 * @param shouldErrorToast Boolean used for triggering an error toast
 * @param shouldSuccessToast Boolean used for triggering a success toast
 * @param toastMessage Object which contains success and/or error message(s)
 * @param t Translation function
 * @see {@link https://transtech.atlassian.net/wiki/spaces/TN360/pages/4169695345/Bulk+Import+-+Backend+Design| Bulk Import Backend Design} for more details
 */

const asyncThunkWrapper = (action, verb = 'get') =>
  createAsyncThunk(action, async (payload = {}, { dispatch, getState, rejectWithValue }) => {
    const { user } = getState();
    const {
      apiEndpoint,
      body,
      itemId,
      responseType,
      shouldErrorToast = false,
      shouldSuccessToast = false,
      toastMessage = {}
    } = payload;

    const authKey = user?.current?.auth?.key;
    const companyId = getState().companies.current.id;

    // This is used for building endpoints other than the standard /bulkImport
    const endpointBuilder = () =>
      `${
        apiEndpoint ? `/${apiEndpoint}${itemId ? `/${itemId}` : ''}` : ''
      }?company_id=${companyId}`;

    const method = api[verb];
    const url = `/bulkimport${endpointBuilder()}`;

    try {
      const response = await method(
        url,
        {
          authKey
        },
        body
      ).responseType(responseType ?? '');

      if (shouldSuccessToast) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t(toastMessage.success)
          })
        );
      }

      return response?.body;
    } catch (err) {
      if (shouldErrorToast) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: i18n.t(toastMessage.error, { error: err })
          })
        );
      }
      return rejectWithValue(err);
    }
  });

export const uploadBulkFile = asyncThunkWrapper(actions.UPLOAD_BULK_FILE, 'post');
export const getTemplates = asyncThunkWrapper(actions.GET_TEMPLATES);
export const getTemplate = asyncThunkWrapper(actions.GET_TEMPLATE);
export const getBulkData = asyncThunkWrapper(actions.GET_BULK_DATA);
export const getBulkErrors = asyncThunkWrapper(actions.GET_BULK_ERORRS);
export const getBulkStatus = asyncThunkWrapper(actions.GET_BULK_STATUS);
