import React from 'react';
import styles from './fatigue.module.scss';

export function getClassOfDriveTimeStyle(state) {
  switch (state) {
    case 1:
      return styles.yellow;
    case 2:
      return styles.red;
    default:
      return '';
  }
}

export function rdtCell(rowData) {
  const maxDriveTime = rowData.fatigueStatus?.max_daily_drive || 1;
  const remainingDrive =
    rowData.fatigueStatus?.max_daily_drive - (rowData.fatigueStatus?.drive_daily || 0);

  const remainedPercent = Math.min(
    100,
    Math.max(0, (remainingDrive / maxDriveTime).toFixed(2) * 100)
  );
  let barStyle = '';
  return (
    <div className={styles.barChart}>
      <div className={styles.bar + ' ' + barStyle}>
        <span
          className={remainedPercent === 100 ? styles.fullBar : styles.progressBar}
          style={{ width: remainedPercent + '%' }}
        ></span>
      </div>
      <p>
        {Math.floor(remainingDrive / 3600)} hrs {Math.floor((remainingDrive % 3600) / 60)} mins
      </p>
    </div>
  );
}
