import { useAllRegions } from 'features/regions/regionsSlice';
import { useMemo } from 'react';
import { LocalizationConfig } from '../localization';
import { useUserPreferences, UserPrefsDefaultLocale } from 'features/user/userPreferencesSlice';
import { useCurrentCompany } from 'features/company/companySlice';

export const DefaultLocale = UserPrefsDefaultLocale;

const DefaultConfig = () => {
  try {
    return LocalizationConfig[/-(\w+)/g.exec(DefaultLocale)[1]] || LocalizationConfig.AU;
  } catch (error) {
    return LocalizationConfig.AU;
  }
};

export function useLocalizationConfig(shouldUseCompanyLocale = false, selectedCompany = null) {
  const regions = useAllRegions();
  const userPref = useUserPreferences();
  const currentCompany = useCurrentCompany();
  const locale = useMemo(() => {
    return shouldUseCompanyLocale
      ? selectedCompany?.locale || currentCompany?.locale
      : userPref?.language;
  }, [shouldUseCompanyLocale, currentCompany, selectedCompany, userPref]);
  const localization = useMemo(() => {
    try {
      return mergedRegionConfig(locale, regions);
    } catch (error) {}
    return DefaultConfig();
  }, [regions, locale]);

  return localization;
}

const mergedRegionConfig = (userPrefLocale = DefaultLocale, regions = []) => {
  const regionCode =
    (/-(\w+)/g.exec(userPrefLocale) &&
      /-(\w+)/g.exec(userPrefLocale)[1] &&
      /-(\w+)/g.exec(userPrefLocale)[1].toUpperCase()) ||
    /-(\w+)/g.exec(DefaultLocale)[1]; //AU/CA/US.etc
  const staticConfig = LocalizationConfig[regionCode] || DefaultConfig();
  const region = regions?.find(region => region?.code === regionCode);

  let regionConfig = Object.assign({}, region || {});
  try {
    regionConfig.config = JSON.parse(regionConfig.config);
  } catch (error) {
    regionConfig.config = {};
  }
  try {
    regionConfig.geocode = JSON.parse(regionConfig.geocode);
  } catch (error) {
    regionConfig.geocode = {};
  }
  try {
    regionConfig.states = JSON.parse(regionConfig.states);
  } catch (error) {
    regionConfig.states = {};
  }
  try {
    regionConfig.timezone = JSON.parse(regionConfig.timezone);
  } catch (error) {
    regionConfig.timezone = {};
  }
  return {
    ...staticConfig,
    ...{
      country: regionConfig.name || staticConfig.country,
      locale: userPrefLocale || staticConfig.locale,
      default_time_zone: regionConfig.config.default_time_zone || staticConfig.default_time_zone,
      geocode:
        Object.keys(regionConfig.geocode || {}).length > 0
          ? regionConfig.geocode
          : staticConfig.geocode,
      support_email: regionConfig.supportEmail || staticConfig.support_email,
      support_doc_link: regionConfig.config.help_center || staticConfig.support_doc_link,
      //below may helpful for in furture, using useLocalization only (instead of combining with useRegions) to get any data from RegionsAPI
      max_phone_length: regionConfig.config.max_phone_length || '11',
      max_speed_kmh: regionConfig.config.max_speed_kmh || 140,
      timezone: regionConfig.timezone || {},
      states: regionConfig.states || {},
      code: regionConfig.code
    }
  };
};
