import { createAsyncThunk } from '@reduxjs/toolkit';
import { api, asyncThunk } from 'utils/api';

import {
  FETCH_METERTHRESHOLDS,
  ADD_METER_THRESHOLDS,
  UPDATE_METER_THRESHOLDS,
  FETCH_ALERT_METER_THRESHOLDS_BY_ID,
  ASSOCIATE_METER_THRESHOLD,
  FETCH_ALERT_METER_THRESHOLDS,
  DELETE_METETR_THRESHOLD_BY_ID
} from './actions';

const GET_METER_THRESHOLDS_URL = '/meterthresholds/sources';
const POST_METER_THRESHOLDS_URL = '/meterthresholds/create';
const PUT_METER_THRESHOLDS_URL = '/meterthresholds/update';
const GET_ALERT_METER_THRESHOLDS_URL = '/meterthresholds';
const ASSOCIATE_METER_THRESHOLD_URL = '/alert/config/meterthreshold';

export const fetchMeterThresholds = asyncThunk(FETCH_METERTHRESHOLDS, GET_METER_THRESHOLDS_URL);
export const addMeterThresholds = asyncThunk(
  ADD_METER_THRESHOLDS,
  POST_METER_THRESHOLDS_URL,
  'post'
);
export const associateMeterThreshold = asyncThunk(
  ASSOCIATE_METER_THRESHOLD,
  ASSOCIATE_METER_THRESHOLD_URL,
  'post'
);
export const fetchAlertMeterThresholds = asyncThunk(
  FETCH_ALERT_METER_THRESHOLDS,
  GET_ALERT_METER_THRESHOLDS_URL
);

export const fetchAlertMeterThresholdsById = createAsyncThunk(
  FETCH_ALERT_METER_THRESHOLDS_BY_ID,
  async (args = {}, { getState, dispatch, rejectWithValue }) => {
    const { user, companies } = getState();
    const { query, alertId } = args;

    const authKey = user?.current?.auth?.key;
    const company_id = companies?.current?.id;

    // Methods map
    const method = api['get'];
    try {
      const response = await method(`/meterthresholds/${query.meterThresholdId}`, {
        authKey,
        query: {
          company_id,
          ...query
        }
      });

      return {
        id: alertId,
        response: response?.body
      };
    } catch (err) {
      return rejectWithValue(err.response?.body);
    }
  }
);

export const deleteMeterThresholdById = createAsyncThunk(
  DELETE_METETR_THRESHOLD_BY_ID,
  async (args = {}, { getState, dispatch, rejectWithValue }) => {
    const { user, companies } = getState();
    const { query } = args;

    const authKey = user?.current?.auth?.key;
    const company_id = companies?.current?.id;

    const method = api['delete'];

    try {
      const response = await method(`/meterthresholds/${query.id}`, {
        authKey,
        query: {
          company_id,
          ...query
        }
      });

      return {
        response: response?.body
      };
    } catch (err) {
      return rejectWithValue(err.response?.body);
    }
  }
);

export const updateAlertMeterThreshold = asyncThunk(
  UPDATE_METER_THRESHOLDS,
  PUT_METER_THRESHOLDS_URL,
  'put'
);
