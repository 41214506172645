import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Affix, Button, Row } from 'antd';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { Can, entities } from 'features/permissions';

//Hooks
import { useSubCompanies } from 'features/company/companySlice';
import {
  useDeletedJourneys,
  useIsFetching as useIsJourneysFetching,
  restoreJourneyApi
} from 'features/journeyPlanner/journeysSlice';
import { useIsFetching as useIsFleetsFetching } from 'features/fleets/fleetsSlice';
import useDebounce from 'utils/hooks/useDebounce';

//Methods
import { TabsFilters, JOURNEY_TYPE } from './constants';
import { toLower } from 'lodash';

//Components
import { JourneysTable } from './JourneysTable';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect, {
  formatListForMultiselect
} from 'components/form/antMultiselect/AntMultiselect';
import { cache } from './CellRenderers';
import { deleteJourneyApi } from 'features/journeyPlanner/journeysSlice';
import { useTranslation } from 'react-i18next';

//styles
import styles from './JourneyPlanner.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const JourneyPlanner = ({ journeys, fleets, localization }) => {
  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const isJourneysFetching = useIsJourneysFetching();
  const isFleetsFetching = useIsFleetsFetching();
  const isFetching = isJourneysFetching || isFleetsFetching;

  const subCompanies = useSubCompanies();
  const deletedJourneys = useDeletedJourneys();
  const [tableRef, setTableRef] = useState(null);
  const [filterTab, setFilterTab] = useState(filterPath);
  const [filterFleets, setFilterFleets] = useState([]);
  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(filterText, 300);
  const [filteredJourneys, setFilteredJourneys] = useState([]);
  const [filterCompanies, setFilterCompanies] = useState([]);

  const showFleetsFilterForTabs = [
    JOURNEY_TYPE.TAB.ALL,
    JOURNEY_TYPE.TAB.ROUTE_COMPLIANCE,
    JOURNEY_TYPE.TAB.DELETED
  ];

  useEffect(() => {
    dispatch(setPageTitle(t('JourneyPlanner.Title')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    if (path === '/journeyplanner') {
      setFilterTab(JOURNEY_TYPE.TAB.ALL);
    }
  }, [path]);

  useEffect(() => {
    let journeysToFilter = journeys;
    if (filterTab === JOURNEY_TYPE.TAB.DELETED) {
      journeysToFilter = deletedJourneys;
    }
    setFilteredJourneys(
      journeysToFilter.filter(journey => {
        let isValid = true;

        //Filter by active tab
        if (filterTab === JOURNEY_TYPE.TAB.ALL) {
          isValid = true;
        }
        if (filterTab === JOURNEY_TYPE.TAB.JOURNEY) {
          isValid = isValid && journey.type === JOURNEY_TYPE.TYPE.JOURNEY;
        }
        if (filterTab === JOURNEY_TYPE.TAB.GEOFENCE_TRIP) {
          isValid = isValid && journey.type === JOURNEY_TYPE.TYPE.GEOFENCE_TRIP;
        }
        if (filterTab === JOURNEY_TYPE.TAB.ROUTE_COMPLIANCE) {
          isValid = isValid && journey.type === JOURNEY_TYPE.TYPE.ROUTE_COMPLIANCE;
        }

        // Filter by search field
        if (debouncedSearchText) {
          const searchArray = debouncedSearchText.split(' ');
          searchArray.forEach(splitValue => {
            isValid = isValid && toLower(journey.name).indexOf(toLower(splitValue)) > -1;
          });
        }

        // Filter by companies
        const checkedCompaniesIds = filterCompanies
          .filter(company => company.checked)
          .map(company => parseInt(company.id, 10));
        if (!(checkedCompaniesIds.indexOf(0) > -1)) {
          isValid = isValid && checkedCompaniesIds.indexOf(parseInt(journey.companyId, 10)) > -1;
        }

        // Filter by fleets
        const checkedFleetIds = filterFleets
          .filter(fleet => fleet.checked)
          .map(fleet => parseInt(fleet.id, 10));
        if (!(checkedFleetIds.indexOf(0) > -1)) {
          isValid =
            isValid &&
            (!showFleetsFilterForTabs.includes(filterTab) ||
              checkedFleetIds.find(
                id =>
                  journey?.tripAssociations?.find(association => association?.fleetId === id) ||
                  (id === -1 && !journey?.tripAssociations.length)
              ));
        }
        return isValid;
      })
    );
  }, [journeys, deletedJourneys, debouncedSearchText, filterCompanies, filterFleets, filterTab]);

  useEffect(() => {
    setFilterCompanies(
      formatListForMultiselect([{ id: 0, name: `${t('Common.AllCompanies')}` }, ...subCompanies])
    );
  }, [subCompanies, t]);

  useEffect(() => {
    const subCompaniesIdsFromFilter = filterCompanies
      .filter(comp => comp.checked)
      .map(comp => comp.id);
    const filteredFleets = fleets
      .filter(
        fleet => fleet.id && fleet.company && subCompaniesIdsFromFilter.includes(fleet.company.id)
      )
      .map(fleet => {
        return {
          name: fleet.name,
          checked: true,
          id: fleet.id
        };
      });
    const formattedFleets = formatListForMultiselect([
      { id: 0, name: `${t('Common.AllFleets')}` },
      ...filteredFleets,
      { id: -1, name: `${t('Common.NoFleets')}` }
    ]);
    setFilterFleets(formattedFleets);
  }, [fleets, filterCompanies, history, t]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [filteredJourneys, tableRef]);

  const handleAddButton = () => {
    history.push('/journeyplanner/add');
  };

  function handleFilterTextChange(newFilter) {
    setFilterText(newFilter);
  }

  function handleFilteredCompaniesChange(newFilter) {
    setFilterCompanies(newFilter);
  }

  function handleFilteredFleetsChange(newFilter) {
    setFilterFleets(newFilter);
  }

  const deleteModal = data => {
    const deleteJourney = () => {
      dispatch(deleteJourneyApi(data));
    };

    confirmationModal(
      `${t('Common.DeleteButton')} ${data.name}`,
      `${t('Common.SureDelete')} ${t('Common.journey')} ${data.name}?`,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      deleteJourney,
      'delete'
    );
  };

  const restoreJourney = data => {
    dispatch(restoreJourneyApi(data));
  };

  return (
    <div className={styles.mainWrapper}>
      <Affix offsetTop={63}>
        <div>
          <div className={styles.headerActions}>
            <TabsFilters setFilterTab={setFilterTab} historyFiltersState={null} />
            <Can everyEntity={[entities.TRIP_CREATE]}>
              <Button
                type="primary"
                size="large"
                id={BUTTON_IDS.journeyPlannerAdd}
                onClick={handleAddButton}
              >
                {t('JourneyPlanner.Buttons.AddJourney')}
              </Button>
            </Can>
          </div>
          <div className={styles.filterWrapper}>
            <FilterWrapper>
              <AntSearchbar onFilter={handleFilterTextChange} />
              <AntMultiselect
                title={
                  filterCompanies.filter(comp => comp.checked).length === subCompanies.length + 1
                    ? t('Common.AllCompanies')
                    : t('Common.Companies')
                }
                data={filterCompanies}
                onFilter={handleFilteredCompaniesChange}
              />
              {showFleetsFilterForTabs.includes(filterTab) && (
                <AntMultiselect
                  title={
                    filterFleets.filter(comp => comp.checked).length === fleets.length + 1
                      ? t('Common.AllFleets')
                      : t('Common.Fleets')
                  }
                  data={filterFleets}
                  onFilter={handleFilteredFleetsChange}
                />
              )}
            </FilterWrapper>
            <div className={styles.itemsCounter}>
              {`${filteredJourneys.length} ${
                filteredJourneys.length === 1
                  ? `${t('JourneyPlanner.Journey')}`
                  : `${t('JourneyPlanner.Journeys')}`
              }`}
            </div>
          </div>
        </div>
      </Affix>
      <Row style={{ height: '100%' }}>
        <JourneysTable
          journeys={filteredJourneys}
          isLoading={isFetching}
          setTableRef={setTableRef}
          fleets={fleets}
          localization={localization}
          deleteModal={deleteModal}
          filterTab={filterTab}
          restoreJourney={restoreJourney}
        />
      </Row>
    </div>
  );
};
