/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Runsheet from "./Runsheet";
import User from "./User";

/**
 * The VehicleStats model module.
 * @module model/VehicleStats
 * @version 1.0
 */
class VehicleStats {
  /**
   * Constructs a new <code>VehicleStats</code>.
   * @alias module:model/VehicleStats
   */
  constructor() {
    VehicleStats.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>VehicleStats</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/VehicleStats} obj Optional instance to populate.
   * @return {module:model/VehicleStats} The populated <code>VehicleStats</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new VehicleStats();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("vehicleId")) {
        obj["vehicleId"] = ApiClient.convertToType(data["vehicleId"], "Number");
      }
      if (data.hasOwnProperty("currentUserId")) {
        obj["currentUserId"] = ApiClient.convertToType(
          data["currentUserId"],
          "Number"
        );
      }
      if (data.hasOwnProperty("gpsOdometer")) {
        obj["gpsOdometer"] = ApiClient.convertToType(
          data["gpsOdometer"],
          "Number"
        );
      }
      if (data.hasOwnProperty("gpsOdometerRef")) {
        obj["gpsOdometerRef"] = ApiClient.convertToType(
          data["gpsOdometerRef"],
          "Number"
        );
      }
      if (data.hasOwnProperty("gpsEngineHours")) {
        obj["gpsEngineHours"] = ApiClient.convertToType(
          data["gpsEngineHours"],
          "Number"
        );
      }
      if (data.hasOwnProperty("gpsEngineHoursRef")) {
        obj["gpsEngineHoursRef"] = ApiClient.convertToType(
          data["gpsEngineHoursRef"],
          "Number"
        );
      }
      if (data.hasOwnProperty("canDiffOdometer")) {
        obj["canDiffOdometer"] = ApiClient.convertToType(
          data["canDiffOdometer"],
          "Number"
        );
      }
      if (data.hasOwnProperty("canDiffOdometerRef")) {
        obj["canDiffOdometerRef"] = ApiClient.convertToType(
          data["canDiffOdometerRef"],
          "Number"
        );
      }
      if (data.hasOwnProperty("canDiffEngineHours")) {
        obj["canDiffEngineHours"] = ApiClient.convertToType(
          data["canDiffEngineHours"],
          "Number"
        );
      }
      if (data.hasOwnProperty("canDiffEngineHoursRef")) {
        obj["canDiffEngineHoursRef"] = ApiClient.convertToType(
          data["canDiffEngineHoursRef"],
          "Number"
        );
      }
      if (data.hasOwnProperty("canDiffFuel")) {
        obj["canDiffFuel"] = ApiClient.convertToType(
          data["canDiffFuel"],
          "Number"
        );
      }
      if (data.hasOwnProperty("canDiffFuelRef")) {
        obj["canDiffFuelRef"] = ApiClient.convertToType(
          data["canDiffFuelRef"],
          "Number"
        );
      }
      if (data.hasOwnProperty("canOdometer")) {
        obj["canOdometer"] = ApiClient.convertToType(
          data["canOdometer"],
          "Number"
        );
      }
      if (data.hasOwnProperty("canEngineHours")) {
        obj["canEngineHours"] = ApiClient.convertToType(
          data["canEngineHours"],
          "Number"
        );
      }
      if (data.hasOwnProperty("canFuel")) {
        obj["canFuel"] = ApiClient.convertToType(data["canFuel"], "Number");
      }
      if (data.hasOwnProperty("lastReadingsAt")) {
        obj["lastReadingsAt"] = ApiClient.convertToType(
          data["lastReadingsAt"],
          "String"
        );
      }
      if (data.hasOwnProperty("runsheetId")) {
        obj["runsheetId"] = ApiClient.convertToType(
          data["runsheetId"],
          "Number"
        );
      }
      if (data.hasOwnProperty("currentUser")) {
        obj["currentUser"] = User.constructFromObject(data["currentUser"]);
      }
      if (data.hasOwnProperty("runsheet")) {
        obj["runsheet"] = Runsheet.constructFromObject(data["runsheet"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
VehicleStats.prototype["id"] = undefined;

/**
 * @member {Number} vehicleId
 */
VehicleStats.prototype["vehicleId"] = undefined;

/**
 * @member {Number} currentUserId
 */
VehicleStats.prototype["currentUserId"] = undefined;

/**
 * @member {Number} gpsOdometer
 */
VehicleStats.prototype["gpsOdometer"] = undefined;

/**
 * @member {Number} gpsOdometerRef
 */
VehicleStats.prototype["gpsOdometerRef"] = undefined;

/**
 * @member {Number} gpsEngineHours
 */
VehicleStats.prototype["gpsEngineHours"] = undefined;

/**
 * @member {Number} gpsEngineHoursRef
 */
VehicleStats.prototype["gpsEngineHoursRef"] = undefined;

/**
 * @member {Number} canDiffOdometer
 */
VehicleStats.prototype["canDiffOdometer"] = undefined;

/**
 * @member {Number} canDiffOdometerRef
 */
VehicleStats.prototype["canDiffOdometerRef"] = undefined;

/**
 * @member {Number} canDiffEngineHours
 */
VehicleStats.prototype["canDiffEngineHours"] = undefined;

/**
 * @member {Number} canDiffEngineHoursRef
 */
VehicleStats.prototype["canDiffEngineHoursRef"] = undefined;

/**
 * @member {Number} canDiffFuel
 */
VehicleStats.prototype["canDiffFuel"] = undefined;

/**
 * @member {Number} canDiffFuelRef
 */
VehicleStats.prototype["canDiffFuelRef"] = undefined;

/**
 * @member {Number} canOdometer
 */
VehicleStats.prototype["canOdometer"] = undefined;

/**
 * @member {Number} canEngineHours
 */
VehicleStats.prototype["canEngineHours"] = undefined;

/**
 * @member {Number} canFuel
 */
VehicleStats.prototype["canFuel"] = undefined;

/**
 * @member {String} lastReadingsAt
 */
VehicleStats.prototype["lastReadingsAt"] = undefined;

/**
 * @member {Number} runsheetId
 */
VehicleStats.prototype["runsheetId"] = undefined;

/**
 * @member {module:model/User} currentUser
 */
VehicleStats.prototype["currentUser"] = undefined;

/**
 * @member {module:model/Runsheet} runsheet
 */
VehicleStats.prototype["runsheet"] = undefined;

export default VehicleStats;
