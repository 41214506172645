/* eslint react/no-did-mount-set-state: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import styles from './RemoteSignIn.module.scss';
import { remoteSignIn } from '../../features/user/userSlice';
import { connect } from 'react-redux';

class RemoteSignIn extends React.Component {
  state = {
    credentials: {},
    isLoggedIn: false,
    error: false
  };

  componentDidMount() {
    const search = this.props.location.search;
    const attributes = search.split('?')[1].split('&');
    const credentials = {};

    attributes.forEach(attribute => {
      if (attribute.includes('user_id=')) {
        const data = attribute.split('user_id=');
        credentials.user_id = data[1];
        return;
      }

      if (attribute.includes('nonce=')) {
        const data = attribute.split('nonce=');
        credentials.nonce = data[1];
        return;
      }

      if (attribute.includes('timestamp=')) {
        const data = attribute.split('timestamp=');
        credentials.timestamp = data[1];
        return;
      }

      if (attribute.includes('hmac=')) {
        const data = attribute.split('hmac=');
        credentials.hmac = data[1];
      }
    });

    this.setState({ credentials });
    this.props.onSignIn(credentials);
  }

  render() {
    if (
      this.props.user.current != null &&
      this.props.user.current.auth != null &&
      this.props.user.current.auth.key != null
    ) {
      return <Redirect to="/" />;
    }

    if (this.props.user.meta.error) {
      return <div className={styles.error}>Error logging in</div>;
    }

    return (
      <div className={styles.signin}>
        <p>Signing in...</p>
      </div>
    );
  }
}

RemoteSignIn.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSignIn: credentials => dispatch(remoteSignIn(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoteSignIn);
