import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { getMapObjectByProperty, getEnum } from 'utils/arrays';

// sort orders
export const SortOrderOptions = [
  { value: 0, key: 'Default', label: 'Default' },
  { value: 1, key: 'NameAsc', label: 'Name A-Z', icon: <SortAscendingOutlined /> },
  { value: 2, key: 'NameDesc', label: 'Name Z-A', icon: <SortDescendingOutlined /> }
];
export const SortOrders = getEnum(SortOrderOptions, 'key', 'value');
export const SortOrdersById = getMapObjectByProperty(SortOrderOptions, 'value');

// category filters
export const CategoryFilterOptions = [
  { value: 0, key: 'All', label: 'All' },
  { value: 1, key: 'System', label: 'System' },
  { value: 2, key: 'Published', label: 'Published' },
  { value: 3, key: 'Unpublished', label: 'Draft' },
  // { value: 4, key: 'General', label: 'General' },
  {
    value: 5,
    key: 'Safety',
    guidKey: 'safe',
    label: 'Safety',
    dashboards: ['Safety Analytics', 'Speed Management', 'Scorecard']
  },
  // { value: 6, key: 'Advanced Safety', label: 'Advanced Safety' },
  {
    value: 7,
    key: 'Productivity',
    guidKey: 'prod',
    label: 'Productivity',
    dashboards: ['Time Utilization', 'Vehicle Management']
  }
  // { value: 8, key: 'Compliance', label: 'Compliance' }
];

// category filters
export const ReportsCategoryFilterOptions = [
  { value: 0, key: 'All', label: 'All' },
  {
    value: 1,
    key: 'General',
    label: 'General',
    reports: [
      'Ng::Report::Agreement',
      'Ng::Report::Alarm',
      'Ng::Report::AlertNotification',
      'Ng::Report::AutomatedMass',
      'Ng::Report::BinItems',
      'Ng::Report::Callmate',
      'Ng::Report::Camera',
      'Ng::Report::DataPackUsage',
      'Ng::Report::DistanceTrip',
      'Ng::Report::DistanceTripDetail',
      'Ng::Report::DriverAction',
      'Ng::Report::DriverManagementSummary',
      'Ng::Report::DriverSummary',
      'Ng::Report::Duress',
      'Ng::Report::Form',
      'Ng::Report::GPIO',
      'Ng::Report::IapCommentDeclaration',
      'Ng::Report::IapMassDeclaration',
      'Ng::Report::JobDriverFeedback',
      'Ng::Report::LastContact',
      'Ng::Report::MessageHistory',
      'Ng::Report::Movement',
      'Ng::Report::OutOfHours',
      'Ng::Report::RoadNetworkUsage',
      'Ng::Report::RucDistance',
      'Ng::Report::ScorecardProgressiveTrend',
      'Ng::Report::ScorecardSummary',
      'Ng::Report::SecurityEventsAudit',
      'Ng::Report::StateMileage',
      'Ng::Report::Timesheet',
      'Ng::Report::UserAlert',
      'Ng::Report::VehicleActivity',
      'Ng::Report::VehicleActivityRedshift',
      'Ng::Report::VehicleDailySummary'
    ]
  },
  {
    value: 2,
    key: 'Compliance',
    label: 'Compliance',
    reports: [
      'Ng::Report::ComplianceManagement',
      'Ng::Report::Dvir',
      'Ng::Report::MassManager',
      'Ng::Report::PretripChecklist',
      'Ng::Report::RouteComplianceBreach',
      'Ng::Report::RouteComplianceCompleted',
      'Ng::Report::RouteComplianceMass',
      'Ng::Report::Speed'
    ]
  },
  {
    value: 3,
    key: 'Sentinel',
    label: 'Sentinel',
    reports: [
      'Ng::Report::DriverActionAudit',
      'Ng::Report::DriverDailySummary',
      'Ng::Report::DriverHoursDistance',
      'Ng::Report::FatigueManagementSummary',
      'Ng::Report::FatigueSubmit',
      'Ng::Report::FatigueViolation',
      'Ng::Report::LogBookCheck'
    ]
  },
  {
    value: 4,
    key: 'ELD',
    label: 'ELD',
    reports: [
      'Ng::Report::DriverHosAvailableHours',
      'Ng::Report::DriverHosViolation',
      'Ng::Report::DutyStatusSummary',
      'Ng::Report::HosCompliance',
      'Ng::Report::HosComplianceCa',
      'Ng::Report::LogEditHistory',
      'Ng::Report::UnassignedEventsChainOfCustody',
      'Ng::Report::UnsignedLogs'
    ]
  },
  {
    value: 5,
    key: 'EWD',
    label: 'EWD',
    reports: [
      'Ng::Report::EwdDriverDailySummary',
      'Ng::Report::EwdDriverEventAudit',
      'Ng::Report::EwdFatigueManagementSummary',
      'Ng::Report::EwdFatigueViolation'
    ]
  },
  {
    value: 6,
    key: 'SmartJobs',
    label: 'SmartJobs',
    reports: [
      'Ng::Report::JobKpi',
      'Ng::Report::JobNotificationUsage',
      'Ng::Report::JobStatistic',
      'Ng::Report::JobSummary',
      'Ng::Report::NonDelivery',
      'Ng::Report::RunsheetSummary',
      'Ng::Report::TaskManagerRunsheets'
    ]
  },
  {
    value: 7,
    key: 'Timesheets',
    label: 'Timesheets',
    guidKey: 'time',
    reports: ['Ng::Report::TimesheetAudit', 'Ng::Report::TimesheetSummary']
  },
  {
    value: 8,
    key: 'Performance',
    label: 'Performance',
    reports: [
      'Ng::Report::DiagnosticTroubleCode',
      'Ng::Report::EngineCoolantOverTemperature',
      'Ng::Report::EngineLowOilPressure',
      'Ng::Report::EngineOilOverTemperature',
      'Ng::Report::ExcessiveAcceleration',
      'Ng::Report::FuelRate',
      'Ng::Report::FuelUsage',
      'Ng::Report::HarshBraking',
      'Ng::Report::HarshCornering',
      'Ng::Report::IdleTime',
      'Ng::Report::Impact',
      'Ng::Report::MixedEnergyPerformance',
      'Ng::Report::OverRevving',
      'Ng::Report::PowerTakeOff',
      'Ng::Report::TripOdometer',
      'Ng::Report::TyrePressure',
      'Ng::Report::VpmPeriod',
      'Ng::Report::VpmTrip'
    ]
  },
  {
    value: 9,
    key: 'Geofence',
    label: 'Geofence',
    reports: [
      'Ng::Report::GeofenceEntryExit',
      'Ng::Report::GeofenceOvertime',
      'Ng::Report::GeofenceSafertogether',
      'Ng::Report::GeofenceSafertogetherMonthly',
      'Ng::Report::GeofenceSpeed',
      'Ng::Report::GeofenceTrip',
      'Ng::Report::GeofenceTripMass',
      'Ng::Report::GeofenceUndertime'
    ]
  },
  {
    value: 10,
    key: 'QuickJobs',
    label: 'QuickJobs',
    reports: [
      'Ng::Report::QuickjobsCombined',
      'Ng::Report::QuickjobsDelay',
      'Ng::Report::QuickjobsTrip'
    ]
  },
  {
    value: 11,
    key: 'FBT',
    label: 'FBT',
    reports: ['Ng::Report::FbtAudit', 'Ng::Report::FbtElbSummary', 'Ng::Report::FbtTrip']
  },
  {
    value: 12,
    key: 'Maintenance',
    label: 'Maintenance',
    reports: [
      'Ng::Report::InspectionStatus',
      'Ng::Report::VehicleMaintenanceSummary',
      'Ng::Report::VehicleSummary'
    ]
  },
  {
    value: 13,
    key: 'Equipment',
    label: 'Equipment',
    reports: ['Ng::Report::EquipmentUtilization', 'Ng::Report::WorkIdleRpm']
  }
];

export const CategoryFilters = getEnum(CategoryFilterOptions, 'key', 'value');
export const CategoryFiltersById = getMapObjectByProperty(CategoryFilterOptions, 'value');
export const CategoryFiltersByGuidKey = getMapObjectByProperty(CategoryFilterOptions, 'guidKey');

export const ReportsCategoryFilters = getEnum(ReportsCategoryFilterOptions, 'key');
export const ReportsCategoryFiltersById = getMapObjectByProperty(
  ReportsCategoryFilterOptions,
  'value'
);
