import { createSlice } from '@reduxjs/toolkit';
import * as sentinelAPI from '../../endpoints/sentinelAPI';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { setUser } from '../user/sentinelUserSlice';

const actionTypes = {
  init: 'init',
  processing: 'processing',
  error: 'error',
  done: 'done'
};

export const initialState = {
  rulesets: {
    list: [],
    status: {
      lastFetched: null,
      fetching: actionTypes.init,
      error: null,
      isListEmpty: false
    }
  },
  userRulesets: [],
  activeRuleset: {},
  error: null
};

const mergeRulesets = (userRulesets, rulesets) => {
  let detailedRulesets = userRulesets;
  if (userRulesets.length && rulesets.length) {
    detailedRulesets = userRulesets.map(aR => {
      let activeRuleset = Object.assign({}, aR);
      const ruleset = rulesets.find(ruleset => ruleset.name === activeRuleset.ruleset);
      activeRuleset = { ...ruleset, ...activeRuleset };
      return activeRuleset;
    });
  }
  return detailedRulesets;
};

export const rulesets = createSlice({
  name: 'rulesets',
  initialState,
  reducers: {
    getUserRulesetsSuccess(state, action) {
      state.userRulesets = mergeRulesets(action.payload, state.rulesets.list);
      state.error = null;
    },
    getUserRulesetsFailed(state, action) {
      state.userRulesets = {};
      state.error = action.payload;
    },
    getRulesetsStart(state, action) {
      state.rulesets.status.fetching = actionTypes.processing;
    },
    getRulesetsSuccess(state, action) {
      state.rulesets.list = action.payload;
      state.userRulesets = mergeRulesets(state.userRulesets, action.payload);
      state.rulesets.status.fetching = actionTypes.done;
      state.rulesets.status.lastFetched = moment().format();
      state.rulesets.status.error = null;
      state.rulesets.status.isListEmpty = !action.payload.length;
    },
    getRulesetsFailed(state, action) {
      state.rulesets.list = [];
      state.rulesets.status.fetching = actionTypes.error;
      state.rulesets.status.lastFetched = moment().format();
      state.rulesets.status.isListEmpty = false;
      state.rulesets.error = action.payload;
    },
    setActiveRuleset(state, action) {
      state.activeRuleset = action.payload;
    },
    setRulesets(state, action) {
      state.userRulesets = action.payload;
    },
    clearRulesets(state) {
      state.userRulesets = [];
    }
  },
  extraReducers: {
    [setUser]: (state, action) => {
      const rulesets = action?.payload?.rulesets;
      if (rulesets) {
        let mergedRulesets = state.userRulesets.concat(
          rulesets.filter(r => !state.userRulesets.some(ur => ur.ruleset === r.ruleset))
        );
        state.userRulesets = mergeRulesets(mergedRulesets, state.rulesets.list);
      }
    }
  }
});

export const {
  getUserRulesetsSuccess,
  getUserRulesetsFailed,
  getRulesetsStart,
  getRulesetsSuccess,
  getRulesetsFailed,
  setActiveRuleset,
  setRulesets,
  clearRulesets
} = rulesets.actions;

export default rulesets.reducer;

export const fetchUserRulesets = userId => async (dispatch, getState) => {
  try {
    const usersList = getState().users.usersList;
    const driver = usersList?.find(u => u.id === userId);
    if (driver.rulesets?.length > 0) {
      dispatch(getUserRulesetsSuccess(driver.rulesets));
    } else {
      const rulesetsData = await sentinelAPI.fetchUserRulesets(userId);
      dispatch(getUserRulesetsSuccess(rulesetsData));
    }
  } catch (err) {
    dispatch(getUserRulesetsFailed(err.toString()));
  }
};

export const fetchRulesets = () => async dispatch => {
  try {
    dispatch(getRulesetsStart());
    const rulesetsData = await sentinelAPI.fetchRulesets();
    dispatch(getRulesetsSuccess(rulesetsData));
  } catch (err) {
    dispatch(getRulesetsFailed(err.toString()));
  }
};

export const useActiveRuleset = action => {
  const activeRuleset = useSelector(state => state.sentinel.activeRuleset);
  const fullRuleset = useSelector(state => state.sentinel.rulesets.list);
  const ruleset = mergeRulesets([activeRuleset], fullRuleset)[0];
  if (!ruleset) {
    return false;
  }
  if (action === 'editable') {
    return ruleset.options && ruleset.options['edit.enabled']
      ? ruleset.options['edit.enabled'].includes('web')
      : false;
  }

  return ruleset;
};

export const useRulesets = () => {
  const dispatch = useDispatch();
  const rulesets = useSelector(state => state.sentinel.rulesets.list);
  const fetching = useSelector(state => state.sentinel.rulesets.status.fetching);
  const isListEmpty = useSelector(state => state.sentinel.rulesets.status.isListEmpty);
  if (!isListEmpty && fetching === actionTypes.init) {
    dispatch(fetchRulesets());
  }
  return rulesets;
};
