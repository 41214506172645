import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AlertData.module.scss';

export function NormalAlertContentRender({ alertData }) {
  const { t } = useTranslation();
  return (
    <>
      {alertData.vehicleId !== 'Unknown' && (
        <>
          <> {t('Notifications.FromVehicle')} - </>
          <strong>{alertData.vehicleName}</strong>.{' '}
        </>
      )}
      {alertData.vehicleId === 'Unknown' && (
        <>
          <> {t('Notifications.FromDevice')} - </>
          <strong>{alertData.deviceName}</strong>.{' '}
        </>
      )}
      {t('Notifications.AtLocation')}:<span className={styles.location}> {alertData.location}</span>
    </>
  );
}
