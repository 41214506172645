import { useDispatch, useSelector } from 'react-redux';
import { smartJobsSelectors, getRunsheetById } from '../';

export const useRunsheet = id => {
  const dispatch = useDispatch();
  const runsheet = useSelector(smartJobsSelectors.getRunsheet(id));

  const isFetching = useSelector(state => state.smartJobs.meta?.runsheet[id]?.isFetching);
  const isSuccess = useSelector(state => state.smartJobs.meta?.runsheet[id]?.success);
  const isError = useSelector(state => state.smartJobs.meta?.runsheet[id]?.error);
  //   const isCompanyKeyDifferent = useIsCompanyKeyDifferent();
  const isFetchComplete = isSuccess || isError;

  if (id && !isFetching && !isFetchComplete) {
    dispatch(getRunsheetById(id));
  }

  return runsheet;
};

export const useIsFetchingAttachments = () => useSelector(smartJobsSelectors.isFetchingAttachments);
export const useIsFetchingJobDetails = () => useSelector(smartJobsSelectors.isFetchingJobDetails);
export const useIsCalendarView = () => useSelector(smartJobsSelectors.isCalendarView);
export const useRunsheetDate = () => useSelector(smartJobsSelectors.date);
export const useCalendarDate = () => useSelector(smartJobsSelectors.calendarDate);
