import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import DropdownTreeSelect from 'components/form/treeselect/DropdownTreeSelect';
import { Dropdown } from 'components/form/dropdownselect/Dropdown';
import { checkedListItems } from 'components/form/treeselect/TreeSelect';
import { upperFirst } from 'lodash';

export function DashboardGridHeader({
  onSearch,
  filterFleets = [],
  onFilterFleets,
  filterTree,
  onFilterTree,
  sortBy,
  sortOptions = [],
  onSortChange,
  rowCount = 0
}) {
  const { t } = useTranslation();
  const filterTitle = useMemo(() => {
    const checkedFilters = filterTree && checkedListItems(filterTree);
    return checkedFilters?.length
      ? `${t('Common.Filter')} • ${checkedFilters.length}`
      : t('Common.Filter');
  }, [filterTree]);

  return (
    <div
      style={{
        display: 'flex',
        padding: '8px',
        backgroundColor: '#f7f8f9',
        borderColor: '#dadee3',
        borderStyle: 'solid',
        borderWidth: '1px',
        justifyContent: 'space-between',
        height: '64px'
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: '1',
          padding: '8px',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <AntSearchbar
          className="ml-1"
          style={{ minWidth: '200px', marginBottom: '1px' }}
          onFilter={onSearch}
        />
        <AntMultiselect
          title={
            filterFleets?.some(value => !value.checked) ? t('Common.Fleets') : t('Common.AllFleets')
          }
          data={filterFleets}
          onFilter={onFilterFleets}
        />
        <DropdownTreeSelect
          title={filterTitle}
          tree={filterTree}
          onChange={nodeTree => onFilterTree(nodeTree)}
          showSelectAll={true}
        />
        <Dropdown
          title={
            !sortBy ? t('Common.Sort') : sortOptions.find(option => option.key === sortBy).name
          }
          data={sortOptions.map(o => ({ ...o, active: sortBy === o.key }))}
          onItemSelected={onSortChange}
        />
        <label className="tn-grid-count">
          {rowCount} {upperFirst(t('Home.VehiclesDevices'))}
        </label>
      </div>
    </div>
  );
}
