import styled from '@emotion/styled';

import { rgba } from '../../static/js/styles';

export const Action = styled.button`
    padding: 0;
    margin-right: 8px;


    display: inline-flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 40px;

    cursor: pointer;

    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    background: ${props =>
      props.theme.buttons.action.background &&
      `rgba(${rgba(`${props.theme.buttons.action.background}`, 0.15)});`}
    color: ${props => props.theme.buttons.action.color};
    border-color: transparent;
    
    &:focus {
        box-shadow: none;
        box-shadow: 0 0 0 1px ${props =>
          props.theme.buttons.action.background &&
          `rgba(${rgba(`${props.theme.buttons.action.background}`, 0.3)});`}
        border-color: transparent;
        outline: none;
    }
    &:hover {
        background: ${props =>
          props.theme.buttons.action.background &&
          `rgba(${rgba(`${props.theme.buttons.action.background}`, 0.3)});`}
    }
`;
