import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ApiClient, AuthApi, UsersApi } from '../../nextgen_api';
import { API_PATH, KEYCLOAK_SSO_ENABLED } from 'config';
import jwt from 'jsonwebtoken';
import {
  status,
  loadingFailed as ewdLoadingFailed,
  loadingStarted as ewdLoadingStarted,
  loadingFinished as ewdLoadingFinished
} from 'utils/reduxFetchingUtils';
import { useMemo } from 'react';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18next from 'i18next';
import { softReset } from 'features/user/softReset';
import { addBackButtonLink } from 'features/page/pageSlice';
import { fetchPreferences } from './userPreferencesSlice';
import { parseErrorMessage } from 'utils/strings';
import moment from 'moment';
import { UserAuthToken } from './userAuthToken';
import { Mixpanel, MPTrackingFunctions } from 'features/mixpanel';
import { useIsThirdPartyBrand } from 'features/brands/useActiveBrand';
import { getAuthedRedirectUrlWitPath } from 'utils/authedRedirectUrl';

const IMPERSONATE_URL = '/auth/impersonate';

export const initialUser = {
  current: {
    auth: {
      key: undefined,
      keycloakConfig: null
    }
  },
  currentUserInfo: {},
  currentEWDUser: {
    firstName: '',
    lastName: '',
    auth: {
      token: null
    },
    mobile: '',
    timeZone: '',
    id: null,
    userId: null,
    email: '',
    username: ''
  },
  currentEWDUserStatus: status,
  userBlocked: {
    isBlocked: false,
    attempsLeft: null
  },
  userCompanyBlocked: false,
  meta: {},
  currentAdminLoggedInUser: null,
  currentAdminLoggedInUserInfo: null
};

function startLoading(state) {
  state.meta.isLoading = true;
}

function loadingFailed(state, action) {
  state.meta.isLoading = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.userBlocked = action.payload.userBlocked;
  state.userCompanyBlocked = action.payload.userCompanyBlocked;
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialUser,
  reducers: {
    setUser(state, action) {
      state.current = action.payload;
    },
    fetchEWDUserFailure(state, { payload }) {
      state.currentEWDUserStatus = ewdLoadingFailed(state, payload);
    },
    fetchEWDUserStart(state, { payload }) {
      state.currentEWDUserStatus = ewdLoadingStarted(state, payload);
    },
    fetchEWDUserSuccess(state, { payload }) {
      if (payload?.auth?.token) {
        state.currentEWDUser = payload;
        const jwtPayload = jwt.decode(payload?.auth?.token);
        state.currentEWDUser.auth.expiry = jwtPayload.exp;
        state.currentEWDUserStatus = ewdLoadingFinished(state, payload);
        return;
      }
      state.currentEWDUserStatus = ewdLoadingFailed(state, payload);
    },
    fetchLoginStart: startLoading,
    fetchLoginSuccess(state, { payload }) {
      state.current = payload;
      UserAuthToken.put(payload);
      state.meta.isLoading = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.currentAdminLoggedInUser = null;
      state.currentAdminLoggedInUserInfo = null;
    },
    fetchLoginFailure: loadingFailed,
    logout(state) {
      state.current = initialUser.current;
      state.currentUserInfo = {};
      UserAuthToken.clear();
    },
    remoteSignInStart(state) {
      state.meta.remoteSigning = true;
      state.meta.error = null;
      state.meta.remoteSignInAvaliable = null;
      state.meta.remoteSignInError = null;
    },
    remoteSignInSuccess(state, { payload }) {
      state.meta.remoteSigning = false;
      state.current = payload;
      state.currentAdminLoggedInUser = null;
      state.currentAdminLoggedInUserInfo = null;
      UserAuthToken.put(payload);
      state.meta.remoteSignInAvaliable = true;
      state.meta.remoteSignInError = null;
    },
    remoteSignInFailed(state, { payload }) {
      state.meta.remoteSigning = false;
      state.meta.error = payload;
      state.meta.remoteSignInAvaliable = ![
        REMOTE_SIGN_IN_ERROR.KC_ADAPTER_LOAD_ERROR,
        REMOTE_SIGN_IN_ERROR.KC_INIT_ERROR,
        REMOTE_SIGN_IN_ERROR.KC_AUTH_ERROR
      ].some(err => err === payload);
      state.meta.remoteSignInError = payload;
    },
    setRemoteSignInAvaliable(state, { payload }) {
      state.meta.remoteSignInAvaliable = payload.remoteSignInAvaliable;
      state.meta.remoteSignInError = payload.remoteSignInError;
    },
    resetRemoteSignIn(state) {
      state.meta.remoteSignInAvaliable = null;
      state.meta.remoteSignInError = null;
      state.meta.remoteSigning = false;
    },
    fetchCurrentUserInfoStart(state) {
      state.meta.fetchingUserInfo = true;
      state.currentUserInfo = {};
      state.meta.fetchingUserInfoError = null;
    },
    fetchCurrentUserInfoSuccess(state, { payload }) {
      state.currentUserInfo = payload.userInfo;
      state.meta.fetchingUserInfo = false;
      state.meta.fetchingUserInfoError = null;
    },
    fetchCurrentUserInfoFailed(state, { payload }) {
      state.meta.fetchingUserInfo = false;
      state.meta.fetchingUserInfoError = payload;
    },
    setCurrentAdminLoggedInUser(state, { payload }) {
      state.currentAdminLoggedInUser = payload;
    },
    setCurrentAdminLoggedInUserInfo(state, { payload }) {
      state.currentAdminLoggedInUserInfo = payload;
    },
    reloadUserInfoFromStorage(state, { payload }) {
      const stateStr = localStorage.getItem('state');
      if (stateStr) {
        try {
          const stateJSON = JSON.parse(stateStr);
          Object.keys(initialUser).forEach(k => {
            state[k] = stateJSON.user?.[k];
          });
          state.current = payload;
          return state;
        } catch (ex) {
          console.warn(ex);
        }
      }
      //give initial user state when there is any error
      state = initialUser;
      return state;
    }
  }
});

export const {
  setUser,
  fetchLoginStart,
  fetchLoginSuccess,
  fetchLoginFailure,
  fetchEWDUserFailure,
  fetchEWDUserStart,
  fetchEWDUserSuccess,
  logout,
  remoteSignInStart,
  remoteSignInSuccess,
  remoteSignInFailed,
  fetchCurrentUserInfoStart,
  fetchCurrentUserInfoSuccess,
  fetchCurrentUserInfoFailed,
  setCurrentAdminLoggedInUser,
  setCurrentAdminLoggedInUserInfo,
  reloadUserInfoFromStorage,
  setRemoteSignInAvaliable,
  resetRemoteSignIn
} = userSlice.actions;

export const login = (username, password, fetchPermissions = null) => async (
  dispatch,
  getState
) => {
  const {
    permissions: { isFetching: isPermissionsFetching, hasFetched: havePermissionsFetched }
  } = getState();

  try {
    dispatch(fetchLoginStart());
    const result = await fetchLogin(username, password, dispatch);
    if (fetchPermissions && !isPermissionsFetching && !havePermissionsFetched) {
      dispatch(fetchPermissions(result?.auth?.key));
    }
    dispatch(fetchLoginSuccess(result));

    //system_location as super prop
    Mixpanel.sendToMixpanel(MPTrackingFunctions.registerSystemLocation);

    const userProfile = {
      $first_name: result.firstName,
      $last_name: result.lastName,
      username: result.username,
      $email: result.email,
      user_type: result.keys[0]?.type,
      id: result.id
    };
    //userProfile as super props
    Mixpanel.sendToMixpanel(MPTrackingFunctions.registerSuperProperty, userProfile);

    Mixpanel.sendToMixpanel(MPTrackingFunctions.updateUserProfile, result.id, userProfile);
    //add user to company-group
    Mixpanel.sendToMixpanel(MPTrackingFunctions.addUserToCompany, result.companyId);
    //login event track
    Mixpanel.sendToMixpanel(MPTrackingFunctions.loginTrack, userProfile);

    return 'success';
  } catch (err) {
    console.warn('ERROR', err);
    return 'error';
  }
};

export const getCurrentUserInfo = () => async (dispatch, getState) => {
  if (getState().user.meta.fetchingUserInfo) {
    return;
  }

  dispatch(fetchCurrentUserInfoStart());
  try {
    const currentUser = getState().user.current;
    if (!currentUser || !currentUser.auth.key) {
      return;
    }
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    const userApi = new UsersApi(apiClient);
    apiClient.defaultHeaders = {
      Authorization: `Token token="${currentUser.auth.key}"`
    };

    const promise = new Promise((resolve, reject) => {
      userApi.findById(
        currentUser.id,
        {
          pruning: 'ALL',
          embed: 'associations,fleets,locations,user_session'
        },
        (err, data, resp) => {
          if (err) {
            console.error(err, resp);
            reject(err);
          } else {
            resolve(resp.body);
          }
        }
      );
    });

    const userInfo = await promise;
    dispatch(fetchCurrentUserInfoSuccess({ userInfo }));
  } catch (err) {
    dispatch(fetchCurrentUserInfoFailed(err.toString()));
  }
};

export function fetchLogin(username, password, dispatch) {
  const data = {
    referrer: 'app.teletracnavman.com',
    username,
    password
  };
  let apiClient = new ApiClient();
  apiClient.basePath = API_PATH;
  const authApi = new AuthApi(apiClient);

  const loginPromise = new Promise((resolve, reject) => {
    authApi.authUser(
      {
        format: 'jwt',
        body: JSON.stringify(data)
      },
      (err, data, resp) => {
        if (err) {
          const response = resp?.body;
          const hasAttemptsReturned =
            response &&
            response?.failedAttempts !== undefined &&
            response?.failedAttempts !== 0 &&
            response?.maxAttempts !== undefined &&
            response?.maxAttempts !== 0;
          const userBlocked = {
            isBlocked: hasAttemptsReturned && response?.failedAttempts === response?.maxAttempts,
            attempsLeft: hasAttemptsReturned && response?.maxAttempts - response?.failedAttempts
          };
          const userCompanyBlocked = response && response?.maxAttempts === 0;
          console.warn('ERROR', err);
          reject(err);
          dispatch(fetchLoginFailure({ err, userBlocked, userCompanyBlocked }));
          if (!resp || resp.statusCode !== 401) {
            const message = err.response?.body?.error || err.message;
            dispatch(
              openToast({
                type: ToastType.Error,
                message: parseErrorMessage(message)
              })
            );
          }
        } else {
          const tokenInfo = jwt.decode(resp.body.auth.token);
          const userInfo = resp.body;
          userInfo.companyId = tokenInfo['company.id'];
          userInfo.auth.key = tokenInfo['user.key'];
          resolve(userInfo);
        }
      }
    );
  });
  return loginPromise;
}

export const remoteSignIn = credentials => async dispatch => {
  dispatch(remoteSignInStart());
  try {
    const userInfo = await doRemoteSignIn(credentials);
    dispatch(remoteSignInSuccess(userInfo));
  } catch (err) {
    dispatch(remoteSignInFailed(err));
  }
};

function doRemoteSignIn(credentials) {
  let apiClient = new ApiClient();
  apiClient.basePath = API_PATH;
  const authApi = new AuthApi(apiClient);

  const loginPromise = new Promise((resolve, reject) => {
    authApi.authSSO(
      {
        format: 'jwt',
        userId: credentials.user_id,
        ...credentials
      },
      (err, data, resp) => {
        //needs to check error status since the Api Client SDK throw an error when convert JWT failed.
        //the JWT can get from err.rawResponse.
        if (err && err.status !== 200) {
          console.log(err, resp);
          reject(err);
        } else {
          //the sso api returns json payload now
          const authObj = Object.assign({}, resp.body);
          const userKey = authObj?.keys?.find(k => k.type === 'USER');
          authObj.auth = {
            ...authObj?.auth,
            key: userKey?.accessToken
          };
          localStorage.setItem('userActionTime', moment().format('X'));
          resolve(authObj);
        }
      }
    );
  });
  return loginPromise;
}

export default userSlice.reducer;

export const useUser = () => {
  const user = useSelector(state => state.user.current);
  const userInfo = useSelector(state => state.user.currentUserInfo);
  return useMemo(() => {
    return { ...user, ...userInfo };
  }, [user, userInfo]);
};

export const useUserInfo = () => {
  return useSelector(state => state.user.currentUserInfo);
};

export const useUserKey = () => {
  return useSelector(state => state.user.current.auth.key);
};

export const useIsUserBlocked = () => {
  return useSelector(state => state.user.userBlocked);
};

export const useIsUserCompanyBlocked = () => {
  return useSelector(state => state.user.userCompanyBlocked);
};

export const useCurrentAdminLoggedIn = () => {
  return useSelector(state => state.user.currentAdminLoggedInUser);
};

export const logInAsUser = (user, fetchPermissions, fetchCompanies, history, can) => async (
  dispatch,
  getState
) => {
  const authKey = getState().user.current.auth.key;
  const currentUser = getState().user.current;
  const currentUserInfo = getState().user.currentUserInfo;

  if (!authKey || !user) {
    return;
  }
  const data = {
    user_id: user?.id,
    reason: i18next.t('Users.LogInAsUser.ReasonForLogInAsUser')
  };

  try {
    const uploadResponse = await api.post(
      IMPERSONATE_URL,
      {
        authKey
      },
      data
    );

    if (!uploadResponse.ok) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('Users.LogInAsUser.LogInAsUserError', {
            name: user?.name
          })
        })
      );
    } else {
      const { body } = uploadResponse;
      const payloadForCurrentUser = { ...body };
      payloadForCurrentUser.auth.key = body.keys.find(key => key.accessToken).accessToken;

      swapLoggedInUser({
        admin: currentUser,
        adminInfo: currentUserInfo,
        currentUser: payloadForCurrentUser,
        authKey: payloadForCurrentUser.auth.key,
        dispatch,
        fetchCompanies,
        fetchPermissions,
        history,
        urlAfterLogAction: '/login',
        can
      });
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: i18next.t('Users.LogInAsUser.LogInAsUserError', {
          name: user?.name
        })
      })
    );
  }
};

export const logOutAsUser = (fetchPermissions, fetchCompanies, history, can) => async (
  dispatch,
  getState
) => {
  const currentAdminLoggedInUser = getState().user.currentAdminLoggedInUser;
  const adminUserInfo = getState().user.currentAdminLoggedInUserInfo;
  const currentUser = getState().user.current;
  swapLoggedInUser({
    admin: null,
    adminInfo: null,
    currentUser: currentAdminLoggedInUser,
    currentUserInfo: adminUserInfo,
    authKey: currentAdminLoggedInUser?.auth?.key,
    dispatch,
    fetchCompanies,
    fetchPermissions,
    history,
    urlAfterLogAction: `/settings/users/id/${currentUser?.id}`,
    impersonatedUser: currentUser,
    can
  });
};

const swapLoggedInUser = config => {
  const {
    admin,
    adminInfo,
    currentUser,
    currentUserInfo,
    authKey,
    dispatch,
    fetchCompanies,
    fetchPermissions,
    history,
    urlAfterLogAction,
    impersonatedUser,
    can
  } = config;
  dispatch(setCurrentAdminLoggedInUser(admin));
  dispatch(setCurrentAdminLoggedInUserInfo(adminInfo));
  dispatch(setUser(currentUser));
  if (currentUserInfo) {
    dispatch(fetchCurrentUserInfoSuccess({ currentUserInfo }));
  } else {
    dispatch(getCurrentUserInfo());
  }
  dispatch(fetchCompanies(can));
  dispatch(fetchPreferences());
  dispatch(fetchPermissions(authKey));
  dispatch(softReset());
  UserAuthToken.put(currentUser);
  if (impersonatedUser) {
    dispatch(
      addBackButtonLink({
        url: `/settings/users/id/${impersonatedUser.id}`,
        backLink: `/settings/users`
      })
    );
  }
  history.push(urlAfterLogAction);
};

export const verifyUserLogInfo = (history, keycloakConfig) => async (dispatch, getState) => {
  const userFromCookie = UserAuthToken.get();
  const userKey = getState().user?.current?.auth?.key;
  if (userFromCookie?.auth?.key !== userKey) {
    if (!userFromCookie?.auth?.key) {
      dispatch(logout());
      dispatch(resetRemoteSignIn());
      if (keycloakConfig) {
        let redirectUrl = '/sso';
        if (keycloakConfig.realm) {
          redirectUrl += '?realm=' + keycloakConfig.realm;
        }
        window.location.href = redirectUrl;
      }
    } else {
      dispatch(reloadUserInfoFromStorage(userFromCookie));
      dispatch(softReset());
      dispatch(getCurrentUserInfo());
      history.push('/');
    }
  }
};

export const REMOTE_SIGN_IN_ERROR = {
  KC_ADAPTER_LOAD_ERROR: 'KC_ADAPTER_LOAD_ERROR',
  KC_INIT_ERROR: 'KC_INIT_ERROR',
  KC_AUTH_ERROR: 'KC_AUTH_ERROR',
  API_KEY_ERROR: 'API_KEY_ERROR',
  API_KEY_EMPTY_ERROR: 'API_KEY_EMPTY_ERROR'
};

export const REMOTE_SIGN_IN_TIMEOUT = 300000; //5*60*1000;

export const useCompatibleLoginState = () => {
  const userKey = useUserKey();
  const currentUser = UserAuthToken.get();
  const userMeta = useSelector(state => state.user.meta);
  const thirdPartyBrand = useIsThirdPartyBrand();

  const {
    isLoggedIn,
    isNormalLogin,
    isKCAvaliable,
    canCompatibleLogin,
    isRemoteSigning,
    isKCAuthenticated
  } = useMemo(() => {
    const isKCAvaliable =
      KEYCLOAK_SSO_ENABLED &&
      (userMeta?.hasOwnProperty('remoteSignInAvaliable') && userMeta.remoteSignInAvaliable !== null
        ? userMeta.remoteSignInAvaliable
        : true);

    const isKCAuthenticated = !!currentUser?.auth?.keycloakConfig;
    const isLoggedIn = !!userKey;
    const isNormalLogin = isLoggedIn && !isKCAuthenticated;
    return {
      isLoggedIn,
      isNormalLogin,
      isKCAvaliable,
      isKCAuthenticated,
      canCompatibleLogin: !thirdPartyBrand && !isLoggedIn && isKCAvaliable,
      isRemoteSigning: userMeta?.remoteSigning
    };
  }, [userMeta, userKey, currentUser, thirdPartyBrand]);

  return {
    isLoggedIn,
    isNormalLogin,
    isKCAvaliable,
    canCompatibleLogin,
    isRemoteSigning,
    isKCAuthenticated
  };
};

export const compatibleLogout = can => (dispatch, getState) => {
  const userFromCookie = UserAuthToken.get();
  const currentAdminUser = getState().user?.currentAdminLoggedInUser;
  const keycloakConfig =
    userFromCookie?.auth?.keycloakConfig || currentAdminUser?.auth?.keycloakConfig;

  let redirectUrl = '/login';
  if (keycloakConfig != null) {
    redirectUrl = '/sso';
    if (keycloakConfig?.realm) {
      redirectUrl += '?realm=' + keycloakConfig.realm;
    }
    redirectUrl = getAuthedRedirectUrlWitPath({
      redirectUrl,
      servicesPermissions: getState().permissions.services,
      userInfo: { ...getState().user.current, ...getState().user.currentUserInfo },
      can
    });
    redirectUrl += '&logoutType=2';
  }
  if (window.keycloak?.logout) {
    window.keycloak.logout({
      redirectUri: window.location.origin + redirectUrl
    });
  }
  dispatch(logout());
  dispatch(resetRemoteSignIn());
};
