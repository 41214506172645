import { createSelector } from 'reselect';

const groupByInspections = createSelector(
  state => state.inspections.list,
  inspections => {
    return inspections.reduce((acc = [], item) => {
      const {
        id,
        checklistId,
        vehicle,
        inspectionBy,
        inspectionDate,
        status,
        repairedBy,
        repairedDate,
        details,
        schedule,
        notes
      } = item;

      const index = acc.findIndex(x => x.checklistId === checklistId);
      const groupedItem = {
        ...vehicle,
        inspectionBy,
        inspectionDate,
        status,
        repairedBy,
        repairedDate,
        details,
        schedule: schedule ?? schedule,
        inspectionId: item.id,
        notes,
        inspectedBy: `${inspectionBy?.firstName || ''} ${inspectionBy?.lastName || ''}`,
        scheduleName: schedule?.name || ''
      };

      if (index === -1) {
        acc.push({
          key: id,
          checklistId,
          item,
          instances: [],
          groupedItems: [groupedItem]
        });
      } else {
        acc[index]?.groupedItems?.push(groupedItem);
      }

      return acc;
    }, []);
  }
);

const groupByVehicles = createSelector(
  state => state.inspections.list,
  inspections => {
    return inspections.reduce((acc = [], item) => {
      const {
        id,
        checklistId,
        vehicle,
        inspectionBy,
        inspectionDate,
        status,
        repairedBy,
        repairedDate,
        details,
        schedule,
        notes
      } = item;

      const index = acc.findIndex(x => x.vehicle?.id === item.vehicle?.id);
      const groupedItem = {
        name: item.checklistName,
        inspectionBy,
        inspectionDate,
        status,
        repairedBy,
        repairedDate,
        details,
        schedule: schedule ?? schedule,
        inspectionId: item.id,
        notes,
        inspectedBy: `${inspectionBy?.firstName || ''} ${inspectionBy?.lastName || ''}`,
        scheduleName: schedule?.name || ''
      };

      if (index === -1) {
        acc.push({
          key: id,
          checklistId,
          vehicle,
          item: { ...item, checklistName: vehicle?.name },
          instances: [],
          groupedItems: [groupedItem]
        });
      } else {
        acc[index]?.groupedItems?.push(groupedItem);
      }

      return acc;
    }, []);
  }
);

export const inspectionsSelectors = {
  filters: state => state.inspections.filters,
  getInspections: state => state.inspections.list,
  getInspection: state => state.inspections.currentInspection.data,
  isFetching: state => state.inspections.meta.isFetching,
  isError: state => state.inspections.meta.error,
  isSuccess: state => state.inspections.meta.success,
  isInspectionFetching: state => state.inspections.currentInspection.meta.isFetching,
  isInspectionError: state => state.inspections.currentInspection.meta.error,
  isInspectionSuccess: state => state.inspections.currentInspection.meta.success,
  groupByInspections: groupByInspections,
  groupByVehicles: groupByVehicles
};
