import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { DriverId } from './DriverId';
import { DRIVER_ID_PATHS } from './constants';
import { DriverIdForm } from './DriverIdForm';
import { Devices } from '../components/Devices';
import { DriverIdView } from './DriverIdView';
import { DriverIdAudits } from './DriverIdAudits';

export const DriverIdRouter = () => {
  const history = useHistory();
  const pathnameSegments = history.location.pathname.split('/');
  const id = pathnameSegments[pathnameSegments.length - 1];
  return (
    <Switch>
      <Route path={DRIVER_ID_PATHS.NEW_TEMPLATE}>
        <DriverIdForm action="add" />
      </Route>
      <Route exact path={DRIVER_ID_PATHS.DRIVER_ID_DELETED}>
        <DriverId tab="deleted" />
      </Route>
      <Route exact path={DRIVER_ID_PATHS.DRIVER_ID_CONFIGURED}>
        <Devices servicesConfig="DriverId" />
      </Route>
      <Route exact path={DRIVER_ID_PATHS.DRIVER_ID_CONFIGURED_ASSIGNED}>
        <Devices servicesConfig="DriverId" tab="assigned" />
      </Route>
      <Route exact path={DRIVER_ID_PATHS.DRIVER_ID_CONFIGURED_UNASSIGNED}>
        <Devices servicesConfig="DriverId" tab="unassigned" />
      </Route>
      <Route path={DRIVER_ID_PATHS.DRIVER_ID_VIEW}>
        <DriverIdView />
      </Route>
      <Route path={DRIVER_ID_PATHS.DRIVER_ID_AUDIT}>
        <DriverIdAudits />
      </Route>
      <Route path={DRIVER_ID_PATHS.DRIVER_ID_EDIT}>
        <DriverIdForm action="edit" templateId={parseInt(id)} />
      </Route>
      <Route exact path={DRIVER_ID_PATHS.DEFAULT}>
        <DriverId />
      </Route>
    </Switch>
  );
};
