import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { useCompanies } from 'features/company/companySlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { PhoneInputField, usePhoneNumber } from 'components/form/phone-input-field';

export const PhoneNumberModal = ({ show, onOk, onCancel, companyId }) => {
  const { t } = useTranslation();

  const localization = useLocalization();
  const companies = useCompanies();
  const preferredCountryCodes = useMemo(() => {
    const company = companies?.find(comp => String(comp.id) === String(companyId));
    return company?.country ? [localization.region, company.country] : [localization.region];
  }, [companies, localization, companyId]);

  const { phoneNumber, phoneNumberWithoutDialCode, handlePhoneNumberChange } = usePhoneNumber();

  return (
    <Modal
      bodyStyle={{ height: 160, display: 'block' }}
      okText={t('Common.Save')}
      cancelText={t('Common.Modal.Cancel')}
      title={t('Devices.BulkEdit.EditColumnTitle')}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      onOk={() => {
        onOk(phoneNumberWithoutDialCode ? phoneNumber : '');
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      {' '}
      <PhoneInputField
        fullWidth
        preferredCountryCodes={preferredCountryCodes}
        initialValue={phoneNumber}
        label={t('Devices.ActualForm.PhoneLabel')}
        onChange={handlePhoneNumberChange}
        countryCodeEditable={true}
      />
    </Modal>
  );
};
