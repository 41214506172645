import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Affix, Space } from 'antd';
import { Button, MoreMenu } from 'components/ant';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { fetchAudits } from 'features/audits';
import { useCanEveryEntity } from 'features/permissions/canHooks';
import { entities } from 'features/permissions';
import { insertIf } from 'utils/methods';
import { getAuditsUrl } from './helpers';

import styles from './Header.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const Header = ({
  containerRef,
  primaryButton = null,
  secondaryButton = null,
  defaultMoreMenu = null,
  moreMenuExtraItems = []
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    entity,
    showAudits,
    showRestore,
    showDelete,
    data,
    typeOfEntityToDelete,
    handleButtonAction
  } = defaultMoreMenu;
  const { status, statusType } = data;

  const canPrimaryButton = useCanEveryEntity(primaryButton?.canEveryEntities);
  const canSecondaryButton = useCanEveryEntity(primaryButton?.canEveryEntities);
  const canAudits = useCanEveryEntity([entities[`${entity}_AUDIT`]]) && showAudits;
  const canRestore =
    useCanEveryEntity([entities[`${entity}_RESTORE`]]) &&
    showRestore &&
    (status === 'DELETED' || status === 'DISABLED');
  const canDelete =
    useCanEveryEntity([entities[`${entity}_DESTROY`]]) &&
    showDelete &&
    (status === 'CREATED' || status === 'ENABLED' || statusType === 'SCHEDULE');

  const handleDeleteModal = () => {
    confirmationModal(
      `${t('Common.DeleteButton')} ${data?.name}`,
      `${t('Common.SureDelete')} ${typeOfEntityToDelete ?? ''} ${data?.name}?`,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      handleButtonAction('delete'),
      'delete'
    );
  };

  const handleAudits = () => {
    const { entityName } = data;
    const url = getAuditsUrl({ entityName, id: data.id });
    if (url) {
      dispatch(fetchAudits({ entityName, entityId: data.id }));
      history.push(url);
    }
  };

  //build the default moreMenu: Audits/Restore/Delete
  const defaultItems = [
    ...insertIf(canAudits, { name: t('Common.AuditsButton'), onClick: handleAudits }),
    ...insertIf(canRestore, {
      name: t('Common.RestoreButton'),
      onClick: handleButtonAction('restore')
    }),
    ...insertIf(canDelete, {
      name: t('Common.DeleteButton'),
      onClick: handleDeleteModal,
      danger: true
    })
  ];

  //add the extra menu items
  !!moreMenuExtraItems.length && defaultItems.unshift(...moreMenuExtraItems);

  return (
    <Affix target={() => containerRef?.current}>
      <header className={styles.header}>
        <div className={styles.buttons}>
          <Space size={16}>
            {primaryButton?.name && canPrimaryButton && (
              <Button
                size="large"
                type="primary"
                id={BUTTON_IDS.headerPrimary}
                onClick={primaryButton?.onClick}
              >
                {primaryButton?.name}
              </Button>
            )}
            {secondaryButton?.name && canSecondaryButton && (
              <Button
                size="large"
                type="secondary"
                id={BUTTON_IDS.headerSecondary}
                onClick={secondaryButton?.onClick}
              >
                {secondaryButton?.name}
              </Button>
            )}
            {defaultItems && <MoreMenu size="xl" border items={defaultItems} useNewIcon />}
          </Space>
        </div>
      </header>
    </Affix>
  );
};

export default Header;
