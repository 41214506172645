import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import {
  fetchDriverManagementFields,
  fetchVehicleMaintenanceFields,
  fetchCameraFields,
  updateDriverManagementFields,
  updateVehicleMaintenanceFields,
  updateCameraFields,
  deleteDriverManagementFields,
  deleteVehicleMaintenanceFields,
  deleteCameraFields,
  updateNonBusinessConfig,
  fetchCarrierData,
  fetchMultiCarrierFields,
  updateMultiCarrierFields,
  fetchWithinConfig
} from './thunks';
import moment from 'moment';

const initialState = {
  carrierData: {
    items: [],
    meta: {
      isFetching: false,
      error: null,
      success: false
    }
  },
  driverManagementFields: {
    meta: {},
    fields: {}
  },
  vehicleMaintenanceFields: {
    fields: {},
    meta: {}
  },
  cameraFields: {
    fields: {},
    meta: {}
  },
  vehicleLoginLimit: {
    companies: {}
  },
  iqCameraConfig: {
    companies: {}
  },
  iqCameraDevicesConfig: {
    companies: {}
  },
  nonBusiness: {
    companies: {},
    meta: {}
  },
  multicarrierFields: {
    fields: {},
    meta: {}
  },
  withinConfig: {
    companies: {},
    meta: {}
  }
};

export const companyConfigSlice = createSlice({
  name: 'companyConfig',
  initialState,
  reducers: {
    fetchVehicleLoginLimitConfigStart(state, { payload }) {
      state.vehicleLoginLimit.companies[payload.companyId] = {
        isFetching: true,
        config: null,
        fetchError: null,
        fetchedAt: null
      };
    },
    fetchVehicleLoginLimitConfigSuccess(state, { payload }) {
      state.vehicleLoginLimit.companies[payload.companyId].isFetching = false;
      state.vehicleLoginLimit.companies[payload.companyId].config = payload.config;
      state.vehicleLoginLimit.companies[payload.companyId].fetchError = null;
      state.vehicleLoginLimit.companies[payload.companyId].fetchedAt = moment().valueOf();
    },
    fetchVehicleLoginLimitConfigFailure(state, { payload }) {
      state.vehicleLoginLimit.companies[payload.companyId].isFetching = false;
      state.vehicleLoginLimit.companies[payload.companyId].config = null;
      state.vehicleLoginLimit.companies[payload.companyId].fetchError = payload.error || true;
      state.vehicleLoginLimit.companies[payload.companyId].fetchedAt = moment().valueOf();
    },
    updateVehicleLoginLimitConfigStart(state, { payload }) {
      state.vehicleLoginLimit.companies[payload.companyId]['updateMeta'] = {
        isUpdating: true,
        updateError: null,
        updateAt: null
      };
    },
    updateVehicleLoginLimitConfigSuccess(state, { payload }) {
      state.vehicleLoginLimit.companies[payload.companyId].config = payload.config;
      state.vehicleLoginLimit.companies[payload.companyId]['updateMeta'].isUpdating = false;
      state.vehicleLoginLimit.companies[payload.companyId]['updateMeta'].updateError = null;
      state.vehicleLoginLimit.companies[payload.companyId][
        'updateMeta'
      ].updateAt = moment().valueOf();
    },
    updateVehicleLoginLimitConfigFailure(state, { payload }) {
      state.vehicleLoginLimit.companies[payload.companyId]['updateMeta'].isUpdating = false;
      state.vehicleLoginLimit.companies[payload.companyId]['updateMeta'].updateError =
        payload.error || true;
      state.vehicleLoginLimit.companies[payload.companyId][
        'updateMeta'
      ].updateAt = moment().valueOf();
    },
    fetchIQCameraConfigStart(state, { payload }) {
      const silentFetch = payload.silentFetch || false;
      if (silentFetch) {
        const prevState = state.iqCameraConfig?.companies?.[payload?.companyId] || {};
        state.iqCameraConfig.companies[payload.companyId] = {
          isFetching: true,
          config: prevState.config || null,
          fetchError: null,
          fetchedAt: prevState.fetchedAt || null,
          silentFetch: true
        };
      } else {
        state.iqCameraConfig.companies[payload.companyId] = {
          isFetching: true,
          config: null,
          fetchError: null,
          fetchedAt: null,
          silentFetch: false
        };
      }
    },
    fetchIQCameraConfigSuccess(state, { payload }) {
      state.iqCameraConfig.companies[payload.companyId].isFetching = false;
      state.iqCameraConfig.companies[payload.companyId].config = payload.config;
      state.iqCameraConfig.companies[payload.companyId].fetchError = null;
      state.iqCameraConfig.companies[payload.companyId].fetchedAt = moment().valueOf();
      state.iqCameraConfig.companies[payload.companyId].silentFetch = payload.silentFetch || false;
    },
    fetchIQCameraConfigFailure(state, { payload }) {
      state.iqCameraConfig.companies[payload.companyId].isFetching = false;
      state.iqCameraConfig.companies[payload.companyId].config = {};
      state.iqCameraConfig.companies[payload.companyId].fetchError = payload.error || true;
      state.iqCameraConfig.companies[payload.companyId].fetchedAt = moment().valueOf();
      state.iqCameraConfig.companies[payload.companyId].silentFetch = payload.silentFetch || false;
    },
    updateIQCameraConfigStart(state, { payload }) {
      state.iqCameraConfig.companies[payload.companyId]['updateMeta'] = {
        isUpdating: true,
        updateError: null,
        updateAt: null
      };
    },
    updateIQCameraConfigSuccess(state, { payload }) {
      state.iqCameraConfig.companies[payload.companyId].config = payload.config;
      state.iqCameraConfig.companies[payload.companyId]['updateMeta'].isUpdating = false;
      state.iqCameraConfig.companies[payload.companyId]['updateMeta'].updateError = null;
      state.iqCameraConfig.companies[payload.companyId]['updateMeta'].updateAt = moment().valueOf();
    },
    updateIQCameraConfigFailure(state, { payload }) {
      state.iqCameraConfig.companies[payload.companyId]['updateMeta'].isUpdating = false;
      state.iqCameraConfig.companies[payload.companyId]['updateMeta'].updateError =
        payload.error || true;
      state.iqCameraConfig.companies[payload.companyId]['updateMeta'].updateAt = moment().valueOf();
    },
    fetchIQCameraDevicesConfigStart(state, { payload }) {
      const devicesConfig =
        state.iqCameraDevicesConfig.companies[payload.companyId]?.devicesConfig || {};
      state.iqCameraDevicesConfig.companies[payload.companyId] = {
        isFetching: true,
        devicesConfig: payload.deviceIds.reduce(
          (a, deviceId) => ({ ...a, [deviceId]: null }),
          devicesConfig
        ),
        fetchError: null,
        fetchedAt: null
      };
    },
    fetchIQCameraDevicesConfigSuccess(state, { payload }) {
      state.iqCameraDevicesConfig.companies[payload.companyId].isFetching = false;
      state.iqCameraDevicesConfig.companies[
        payload.companyId
      ].devicesConfig = payload.devices.reduce(
        (a, device) => ({ ...a, [device.id]: { config: device.config } }),
        state.iqCameraDevicesConfig.companies[payload.companyId]?.devicesConfig || {}
      );
      state.iqCameraDevicesConfig.companies[payload.companyId].fetchError = null;
      state.iqCameraDevicesConfig.companies[payload.companyId].fetchedAt = moment().valueOf();
    },
    fetchIQCameraDevicesConfigFailure(state, { payload }) {
      state.iqCameraDevicesConfig.companies[payload.companyId].isFetching = false;
      state.iqCameraDevicesConfig.companies[
        payload.companyId
      ].devicesConfig = payload.deviceIds.reduce(
        (a, deviceId) => ({ ...a, [deviceId]: { config: null } }),
        state.iqCameraDevicesConfig.companies[payload.companyId]?.devicesConfig || {}
      );
      state.iqCameraDevicesConfig.companies[payload.companyId].fetchError = payload.error || true;
      state.iqCameraDevicesConfig.companies[payload.companyId].fetchedAt = moment().valueOf();
    },
    bulkUpdateIQCamerasStart(state, { payload }) {
      state.iqCameraDevicesConfig.companies[payload.companyId] =
        state.iqCameraDevicesConfig.companies[payload.companyId] || {};
      state.iqCameraDevicesConfig.companies[payload.companyId]['updateMeta'] = {
        isUpdating: true,
        updateError: null,
        updateAt: null
      };
    },
    bulkUpdateIQCamerasSuccess(state, { payload }) {
      const existingDevicesConfig =
        state.iqCameraDevicesConfig.companies[payload.companyId]?.devicesConfig || {};
      state.iqCameraDevicesConfig.companies[
        payload.companyId
      ].devicesConfig = payload.devices.reduce(
        (a, device) => ({
          ...a,
          [device.id]: {
            config: {
              ...existingDevicesConfig[device.id]?.config,
              ...device.config
            }
          }
        }),
        existingDevicesConfig
      );
      state.iqCameraDevicesConfig.companies[payload.companyId]['updateMeta'].isUpdating = false;
      state.iqCameraDevicesConfig.companies[payload.companyId]['updateMeta'].updateError = null;
      state.iqCameraDevicesConfig.companies[payload.companyId][
        'updateMeta'
      ].updateAt = moment().valueOf();
    },
    bulkUpdateIQCamerasFailure(state, { payload }) {
      state.iqCameraDevicesConfig.companies[payload.companyId]['updateMeta'].isUpdating = false;
      state.iqCameraDevicesConfig.companies[payload.companyId]['updateMeta'].updateError =
        payload.error || true;
      state.iqCameraDevicesConfig.companies[payload.companyId][
        'updateMeta'
      ].updateAt = moment().valueOf();
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCarrierData.pending, state => {
        state.carrierData.meta.isFetching = true;
        state.carrierData.meta.success = false;
        state.carrierData.meta.error = false;
      })
      .addCase(fetchCarrierData.fulfilled, (state, action) => {
        state.carrierData.meta.isFetching = false;
        state.carrierData.meta.success = true;
        state.carrierData.meta.error = false;
        state.carrierData.items = unwrapResult(action);
      })
      .addCase(fetchCarrierData.rejected, state => {
        state.carrierData.meta.isFetching = false;
        state.carrierData.meta.success = false;
        state.carrierData.meta.error = true;
        state.carrierData.items = [];
      })
      .addCase(fetchCameraFields.fulfilled, (state, { meta, payload }) => {
        state.cameraField.fields[meta.arg.companyId] = payload;
        state.cameraField.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(fetchCameraFields.pending, (state, { meta }) => {
        state.cameraFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(fetchCameraFields.rejected, (state, { meta }) => {
        state.cameraFields.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(fetchDriverManagementFields.fulfilled, (state, { payload, meta }) => {
        state.driverManagementFields.fields[meta.arg.companyId] = payload;
        state.driverManagementFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(fetchDriverManagementFields.pending, (state, { meta }) => {
        state.driverManagementFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(fetchDriverManagementFields.rejected, (state, { meta }) => {
        state.driverManagementFields.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(fetchVehicleMaintenanceFields.fulfilled, (state, { payload, meta }) => {
        state.vehicleMaintenanceFields.fields[meta.arg.companyId] = payload;
        state.vehicleMaintenanceFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(fetchVehicleMaintenanceFields.pending, (state, { meta }) => {
        state.vehicleMaintenanceFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(fetchVehicleMaintenanceFields.rejected, (state, { meta }) => {
        state.vehicleMaintenanceFields.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(updateNonBusinessConfig.fulfilled, (state, { payload, meta }) => {
        state.nonBusiness.companies[meta.arg.companyId] = payload;
        state.nonBusiness.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(updateNonBusinessConfig.pending, (state, { meta }) => {
        state.nonBusiness.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(updateNonBusinessConfig.rejected, (state, { meta }) => {
        state.cameraField.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(updateCameraFields.fulfilled, (state, { payload, meta }) => {
        state.cameraField.fields[meta.arg.companyId] = payload;
        state.cameraField.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(updateCameraFields.pending, (state, { meta }) => {
        state.cameraFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(updateCameraFields.rejected, (state, { meta }) => {
        state.cameraFields.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(updateDriverManagementFields.fulfilled, (state, { payload, meta }) => {
        state.driverManagementFields.fields[meta.arg.companyId] = payload;
        state.driverManagementFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(updateDriverManagementFields.pending, (state, { meta }) => {
        state.driverManagementFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(updateDriverManagementFields.rejected, (state, { meta }) => {
        state.driverManagementFields.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(updateVehicleMaintenanceFields.fulfilled, (state, { payload, meta }) => {
        state.vehicleMaintenanceFields.fields[meta.arg.companyId] = payload;
        state.vehicleMaintenanceFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(updateVehicleMaintenanceFields.pending, (state, { meta }) => {
        state.vehicleMaintenanceFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(updateVehicleMaintenanceFields.rejected, (state, { meta }) => {
        state.vehicleMaintenanceFields.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(deleteCameraFields.fulfilled, (state, { meta }) => {
        state.cameraField.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(deleteCameraFields.pending, (state, { meta }) => {
        state.cameraFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(deleteCameraFields.rejected, (state, { meta }) => {
        state.cameraFields.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(deleteDriverManagementFields.fulfilled, (state, { meta }) => {
        state.driverManagementFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(deleteDriverManagementFields.pending, (state, { meta }) => {
        state.driverManagementFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(deleteDriverManagementFields.rejected, (state, { meta }) => {
        state.driverManagementFields.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(deleteVehicleMaintenanceFields.fulfilled, (state, { meta }) => {
        state.vehicleMaintenanceFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(deleteVehicleMaintenanceFields.pending, (state, { meta }) => {
        state.vehicleMaintenanceFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(deleteVehicleMaintenanceFields.rejected, (state, { meta }) => {
        state.vehicleMaintenanceFields.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(fetchMultiCarrierFields.fulfilled, (state, { meta, payload }) => {
        state.multicarrierFields.fields[meta.arg.companyId] = payload;
        state.multicarrierFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(fetchMultiCarrierFields.pending, (state, { meta }) => {
        state.multicarrierFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(fetchMultiCarrierFields.rejected, (state, { meta }) => {
        state.multicarrierFields.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(updateMultiCarrierFields.fulfilled, (state, { payload, meta }) => {
        state.multicarrierFields.fields[meta.arg.companyId] = payload;
        state.multicarrierFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(updateMultiCarrierFields.pending, (state, { meta }) => {
        state.multicarrierFields.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(updateMultiCarrierFields.rejected, (state, { meta }) => {
        state.multicarrierFields.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      })
      .addCase(fetchWithinConfig.fulfilled, (state, { payload, meta }) => {
        state.withinConfig.companies[meta.arg.companyId] = payload;
        state.withinConfig.meta[meta.arg.companyId] = {
          error: false,
          isFetching: false,
          success: true
        };
      })
      .addCase(fetchWithinConfig.pending, (state, { meta }) => {
        state.withinConfig.meta[meta.arg.companyId] = {
          error: false,
          isFetching: true,
          success: false
        };
      })
      .addCase(fetchWithinConfig.rejected, (state, { meta }) => {
        state.withinConfig.meta[meta.arg.companyId] = {
          error: true,
          isFetching: false,
          success: false
        };
      });
  }
});

export const {
  fetchVehicleLoginLimitConfigStart,
  fetchVehicleLoginLimitConfigSuccess,
  fetchVehicleLoginLimitConfigFailure,
  updateVehicleLoginLimitConfigStart,
  updateVehicleLoginLimitConfigSuccess,
  updateVehicleLoginLimitConfigFailure,
  fetchIQCameraConfigStart,
  fetchIQCameraConfigSuccess,
  fetchIQCameraConfigFailure,
  updateIQCameraConfigStart,
  updateIQCameraConfigSuccess,
  updateIQCameraConfigFailure,
  fetchIQCameraDevicesConfigStart,
  fetchIQCameraDevicesConfigSuccess,
  fetchIQCameraDevicesConfigFailure,
  bulkUpdateIQCamerasStart,
  bulkUpdateIQCamerasSuccess,
  bulkUpdateIQCamerasFailure
} = companyConfigSlice.actions;
