import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnrolements, getSchemes, enrolmentsSelectors } from '.';

const useIsCompanyChanged = () =>
  useSelector(state => state.companies.current.id !== state.enrolments.meta.companyId);

export const useEnrolments = () => {
  const dispatch = useDispatch();
  const enrolments = useSelector(enrolmentsSelectors.enrolments);

  const isFetching = useSelector(enrolmentsSelectors.isEnrolmentsFetching);
  const isSuccess = useSelector(enrolmentsSelectors.isEnrolmentsSuccess);
  const isError = useSelector(enrolmentsSelectors.isEnrolmentsError);
  const isCompanyChanged = useIsCompanyChanged();
  const isFetchComplete = isSuccess || isError;

  if (!isFetching && (!isFetchComplete || isCompanyChanged)) {
    dispatch(getEnrolements());
  }

  return enrolments || [];
};

export const useIsEnrolmentsFetching = () => useSelector(enrolmentsSelectors.isEnrolmentsFetching);

export const useSchemes = companyId => {
  const dispatch = useDispatch();
  const schemes = useSelector(state => state.enrolments.schemes.companies[companyId]?.values);

  const isFetching = useSelector(state => state.enrolments.schemes.isFetching);
  const isSuccess = useSelector(state => state.enrolments.schemes.companies[companyId]?.success);
  const isError = useSelector(state => state.enrolments.schemes.companies[companyId]?.error);

  const isFetchComplete = isSuccess || isError;

  useEffect(() => {
    if (!isFetching && !isFetchComplete) {
      dispatch(getSchemes({ companyId }));
    }
  }, [isFetching, isFetchComplete]);

  return schemes;
};

export const useIsSchemesFetching = () => useSelector(state => state.enrolments.schemes.isFetching);
export const useIsEnrolmentSaving = () =>
  useSelector(state => state.enrolments.meta.addEnrolment.isSaving);
