import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import styles from './UDT.module.scss';
import { useBranches } from 'features/locations/locationsSlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import DropdownTreeSelect from 'components/form/treeselect/DropdownTreeSelect';
import { useTranslation } from 'react-i18next';
import { UDTAssignmentMode } from '../Constants';
import { useCurrentCompany } from 'features/company/companySlice';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { useIsFetchingCompanyUDTData } from 'features/eld/udtSlice';
import { useLayoutEffect } from 'react';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { prepareDataForMultiselect } from 'utils/filters';
import { ELDDateRangePicker } from '../ELDDateRangePicker';
import { useLocalization } from 'features/localization/localizationSlice';
import moment from 'moment';
import { Select } from 'components/ant';

const durationFilterOptions = t => [
  { id: 0, name: `${t('ELD.None')}` },
  { id: 2, name: `2 ${t('Common.Minutes')}` },
  { id: 5, name: `5 ${t('Common.Minutes')}` },
  { id: 10, name: `10 ${t('Common.Minutes')}` },
  { id: 15, name: `15 ${t('Common.Minutes')}` }
];

const groupByFilterOptions = t => [
  { id: 'none', name: `${t('ELD.ListView')}` },
  { id: 'vehicle', name: `${t('ELD.GroupByVehicle')}` }
];

const RowCount = React.memo(({ assignMode, selectedCount, gridCount }) => {
  const company = useCurrentCompany();
  const isFetchingUDTData = useIsFetchingCompanyUDTData(company.id);
  const { t } = useTranslation();
  const [spinner] = useState(() => {
    return (
      <Tooltip title="Loading Data">
        <FontAwesomeIcon icon={faCircleNotch} spin={true} />
      </Tooltip>
    );
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const timerHandler = useRef();

  useLayoutEffect(() => {
    const time_count = 2000;
    if (isFetchingUDTData) {
      setShowSpinner(isFetchingUDTData);
      return;
    }

    timerHandler.current = setTimeout(() => {
      setShowSpinner(isFetchingUDTData);
    }, time_count);

    return () => {
      if (timerHandler.current != null) {
        clearTimeout(timerHandler.current);
      }
    };
  }, [isFetchingUDTData]);

  return (
    <span className={styles.rowCount}>
      {assignMode === UDTAssignmentMode.BulkAssignment && (
        <>{t('Common.Selected') + ' ' + selectedCount + ' ' + t('Fleets.Form.of') + ' '}</>
      )}
      {showSpinner && spinner}
      {gridCount} {t('ELD.Events')}
    </span>
  );
});

export function UDTToolbar({
  onSearchChange,
  onDateChange,
  onFleetChange,
  onBranchChange,
  onSortChange,
  onGeneralFilterChange,
  onShowCompletedLogsChange,
  onAssignClicked,
  onDurationFilterChange,
  onGroupByFilterChange,
  groupByFilter,
  durationFilter,
  filters,
  selectedCount = 0,
  gridCount,
  assignMode,
  ...props
}) {
  const locale = useLocalization();
  const [filterBranches, setFilterBranches] = useState([]);
  const [filterFleets, setFilterFleets] = useState([]);
  const [generalFilters, setGeneralFilters] = useState(filters);
  const branches = useBranches();
  const fleets = useFleets();
  const timeoutHandler = useRef(-1);
  const { t } = useTranslation();
  const defaultDates = useMemo(() => [moment().subtract(8, 'days'), moment()], []);

  const handleSearchChange = useCallback(value => {
    if (timeoutHandler.current !== -1) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = -1;
    }
    timeoutHandler.current = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(value);
      }
      timeoutHandler.current = -1;
    }, 100);
  }, []);

  const handleDateChange = useCallback(value => {
    if (onDateChange) {
      onDateChange(value);
    }
  }, []);

  const handleFleetsChange = useCallback(
    f => {
      setFilterFleets(f);
      if (onFleetChange) {
        onFleetChange(f);
      }
    },
    [filterFleets]
  );

  const handleBranchesChange = useCallback(
    f => {
      setFilterBranches(f);
      if (onBranchChange) {
        onBranchChange(f);
      }
    },
    [filterBranches, t]
  );

  const handleGeneralFilterChange = useCallback(filters => {
    //toggleItem(item, generalFilters, setGeneralFilters)
    setGeneralFilters(filters);
    if (onGeneralFilterChange) {
      onGeneralFilterChange(filters);
    }
  }, []);

  const handleDurationFilterChange = useCallback(filter => {
    if (onDurationFilterChange) {
      onDurationFilterChange(filter);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutHandler.current !== -1) {
        clearTimeout(timeoutHandler.current);
        timeoutHandler.current = -1;
      }
    };
  }, []);

  useEffect(() => {
    setGeneralFilters(prevFilters => {
      if (prevFilters == null) {
        return filters;
      } else {
        filters &&
          Object.keys(filters).forEach(k => {
            if (prevFilters[k]?.checked) {
              filters[k].checked = true;
            }
            prevFilters[k] &&
              prevFilters[k].children &&
              prevFilters[k].children.forEach(childNode => {
                if (childNode.checked) {
                  const node = filters[k]?.children?.find(n => n.label === childNode.label);
                  if (node != null) node.checked = true;
                }
              });
          });
        return filters;
      }
    });
  }, [filters]);

  useEffect(() => {
    const branchOptions = prepareDataForMultiselect(branches, t('Common.AllBranches'), null);
    const noBranch = branchOptions?.find(b => b.id === -1);
    if (noBranch) {
      noBranch.name = t('Users.NoBranch');
    }
    setFilterBranches(branchOptions);
  }, [branches, t]);

  useEffect(() => {
    const fleetsList = [];
    if (fleets != null && fleets.length > 0) {
      fleetsList.push(...fleets.filter(f => f.name != null));
    }
    fleetsList.push({ id: -1, name: t('Home.No Fleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsList, t('Common.AllFleets'), null));
  }, [fleets, t]);

  return (
    <div className={styles.fatigueToolbar}>
      <div>
        <AntSearchbar onFilter={handleSearchChange} />

        {onBranchChange && (
          <AntMultiselect
            title={
              filterBranches?.some(value => !value.checked)
                ? t('Common.Branches')
                : t('Common.AllBranches')
            }
            onFilter={handleBranchesChange}
            data={filterBranches}
          />
        )}
        <AntMultiselect
          title={
            filterFleets?.some(value => !value.checked) ? t('Common.Fleets') : t('Common.AllFleets')
          }
          data={filterFleets}
          onFilter={handleFleetsChange}
        />

        <DropdownTreeSelect
          title={t('Common.Filter')}
          showSelectAll={true}
          tree={generalFilters}
          onChange={handleGeneralFilterChange}
        />
        <Select
          className={styles.durationFilterSelect}
          data={durationFilterOptions(t)}
          placeholder={t('ELD.MinDuration')}
          value={durationFilter}
          showSearch={false}
          size={'middle'}
          onSelect={handleDurationFilterChange}
        />
        <Select
          className={styles.groupByFilterSelect}
          data={groupByFilterOptions(t)}
          value={groupByFilter}
          showSearch={false}
          size={'middle'}
          onSelect={onGroupByFilterChange}
        />
        <ELDDateRangePicker
          defaultDates={defaultDates}
          onDateRangeChanged={handleDateChange}
          format={locale.formats.time.formats.dby.toUpperCase()}
        />
      </div>

      <RowCount gridCount={gridCount} assignMode={assignMode} selectedCount={selectedCount} />
    </div>
  );
}
