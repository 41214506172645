import React, { useRef, useMemo } from 'react';
import styles from './NotificationEvent.module.scss';
import { Iframe } from 'components/iframe/Iframe';
import { useParams } from 'react-router';
import { useUser } from 'features/user/userSlice';
import { AuthUtil } from 'features/auth/authUtil';
import { useWalkMeInFrame } from 'features/walkme/walkmeHooks';

function _NotificationEvent(props) {
  const params = useParams();
  const userInfo = useUser();
  const frameRef = useRef(null);
  useWalkMeInFrame(frameRef);
  const reportUrl = useMemo(() => {
    return (
      AuthUtil.generateAuthUrl(userInfo.id, userInfo.auth.key) +
      `&url=${encodeURIComponent('/notifications/' + params['id'])}`
    );
  }, []);

  return <Iframe ref={frameRef} src={reportUrl} className={styles.container}></Iframe>;
}

export const NotificationEvent = React.memo(_NotificationEvent);
