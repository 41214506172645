import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { Row } from 'react-bootstrap';
import { Button } from 'antd';

import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useVehicleTypes, fetchVehicleTypesByCompany } from 'features/vehicles/vehicleTypesSlice';
import { useUserKey } from 'features/user/userSlice';
import { openToast } from 'features/toasts/toastsSlice';
import {
  fetchVehicleMntTypes,
  useVehicleMntTypes,
  fetchVehicleMntType,
  useIsFetching
} from 'features/vehicleMntTypes/vehicleMntTypesSlice';
import { MPTrackingEvents, useFormUpdatedFields } from 'features/mixpanel';

import SearchableListMultiSelect from 'components/form/searchable-list-multi-select/SearchableListMultiSelect';
import FormInput from 'components/form/form-input/FormInput';
import FormSelect from 'components/form/form-select/FormSelect';
import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';

import { prepareVehicleTypes } from './helpers';
import { canHistoryGoBack } from 'utils/methods';
import { parseErrorMessage } from 'utils/strings';
import { api } from 'utils/api';

import { EMPTY_ARRAY, initialValues, Paths, validationSchema } from './constants';

import styles from './VehicleMntTypes.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const VehicleMntTypesForm = ({ action }) => {
  const path = window.location.pathname;
  const vehicleMntTypeId = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const { t } = useTranslation();

  const companies = useCompanies();
  const dispatch = useDispatch();
  const history = useHistory();
  const userKey = useUserKey();
  const currentCompany = useCurrentCompany();
  const allVehicleTypes = useVehicleTypes();
  const allVehicleMntTypes = useVehicleMntTypes();
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [userCheckedVehicleTypes, setUserCheckedVehicleTypes] = useState([]);
  const [initialVehicleTypes, setInitialVehicleTypes] = useState(EMPTY_ARRAY);
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  const [typesLoaded, setTypesLoaded] = useState(true);

  const vehicleMntTypeEdit =
    allVehicleMntTypes.find(type => type.id === parseInt(vehicleMntTypeId, 10)) || initialValues;

  const isFetchingData = useIsFetching();

  const handleError = useCallback(
    err => {
      if (history.location.pathname !== Paths.DEFAULT) {
        if (err) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: err
            })
          );
        }
        history.replace(Paths.DEFAULT);
      }
    },
    [history, dispatch]
  );

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    const parsedId = parseInt(vehicleMntTypeId);
    if (
      action === 'edit' &&
      (parsedId <= 0 ||
        parsedId > Number.MAX_SAFE_INTEGER ||
        isNaN(parsedId) ||
        (!isFetchingData && vehicleMntTypeEdit.id !== parsedId))
    ) {
      handleError(t('Common.Invalid Request ID'));
    }
  }, [t, action, vehicleMntTypeId, handleError, isFetchingData, vehicleMntTypeEdit]);

  useEffect(() => {
    if (action === 'edit') {
      dispatch(
        setPageTitle(
          formikInitialValues.name &&
            `${t('VehicleMaintenanceTypes.Form.EditTitle')} ${formikInitialValues.name}`
        )
      );
    } else {
      dispatch(
        setPageTitle(`${t('VehicleMaintenanceTypes.Form.AddNewVehicleMaintenanceTypeTitle')}`)
      );
    }
  }, [dispatch, action, formikInitialValues.name, t]);

  useEffect(() => {
    if (action === 'edit') {
      if (vehicleMntTypeEdit?.companyId && currentCompany.id !== vehicleMntTypeEdit.companyId) {
        updateFormOnCompanyChange(vehicleMntTypeEdit.companyId);
      }
      setFormikInitialValues(vehicleMntTypeEdit);
    }
  }, [action, vehicleMntTypeEdit]);

  useEffect(() => {
    setVehicleTypes(prepareVehicleTypes(allVehicleTypes, currentCompany?.id));
  }, [allVehicleTypes, currentCompany, vehicleMntTypeEdit, action]);

  useEffect(() => {
    setInitialVehicleTypes(
      formikInitialValues.vehicleTypes.map(vehicleType => ({
        label: vehicleType.name,
        value: vehicleType.id,
        checked: true
      }))
    );
  }, [formikInitialValues.vehicleTypes]);

  const updateFormOnCompanyChange = async newCompanyId => {
    setTypesLoaded(false);
    // Set the vehicle types for the new company
    const types = await dispatch(fetchVehicleTypesByCompany(newCompanyId));
    setVehicleTypes(prepareVehicleTypes(types, newCompanyId));
    setTypesLoaded(true);
  };

  const onCompanyChange = (company, setFieldValue) => {
    setFieldValue('companyId', company);

    if (!company) {
      return;
    }
    updateFormOnCompanyChange(company);
  };

  const [formValue, setFormValue] = useState({});
  useEffect(() => {
    setFormValue({
      ...formikInitialValues,
      companyId: action === 'add' ? currentCompany.id : formikInitialValues.companyId
    });
  }, [formikInitialValues, currentCompany, action]);

  useFormUpdatedFields(formValue, {
    eventName: MPTrackingEvents.Settings.VehicleMntTypes.Update,
    eventProps: { typeOfUpdate: action },
    trackFields: MPTrackingEvents.Settings.VehicleMntTypes.TrackFormFields,
    eventPropsOnSubmitFunc: MPTrackingEvents.Settings.VehicleMntTypes.OnSubmitTrackEventPropsFunc
  });

  const handleSubmit = async (values, actions) => {
    const method = action === 'add' ? 'POST' : 'PUT';
    const message = t(
      `VehicleMaintenanceTypes.Notifications.${action === 'add' ? 'Added' : 'Updated'}Notification`,
      {
        name: values.name
      }
    );

    let postBody = {
      ...initialValues
    };
    const vehicleTypesToSend = userCheckedVehicleTypes.reduce((accumulator, type) => {
      if (type.checked) {
        accumulator.push(type.value);
      }
      return accumulator;
    }, []);
    postBody = {
      ...postBody,
      name: values.name,
      description: values.description,
      type: 'NG::VehicleMaintenanceType',
      status: 'ENABLED',
      vehicleTypes: vehicleTypesToSend,
      version: values.version,
      companyId: values.companyId,
      ...(action === 'add' && { createdAt: new Date() }),
      ...(action === 'edit' && {
        id: vehicleMntTypeEdit.id,
        updatedAt: new Date()
      })
    };
    setFormValue({
      ...postBody,
      vehicleTypes: vehicleTypesToSend.map(typeId =>
        allVehicleTypes.find(item => item.id === typeId)
      ),
      submit: true
    });
    try {
      const response = await api.post(
        '/types/vehiclemaintenance',
        { authKey: userKey },
        postBody,
        method
      );
      if (response?.body) {
        setPromptModalWhenLeaving(false);
        postBody.id && dispatch(fetchVehicleMntType(postBody.id));
        dispatch(
          openToast({
            type: ToastType.Success,
            message
          })
        );
        dispatch(fetchVehicleMntTypes());
        actions.resetForm();
        actions.setSubmitting(false);
        canHistoryGoBack(history, '/settings/vehicleMntTypes');
      }
    } catch (err) {
      actions.setSubmitting(false);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
      );
    }
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...formikInitialValues,
          companyId: action === 'add' ? currentCompany.id : formikInitialValues.companyId
        }}
        validationSchema={validationSchema()}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid, setFieldValue, dirty }) => (
          <>
            <EditRouteGuard when={dirty && promptModalWhenLeaving} navigate={history.push} />
            <Form id="VehicleMntTypesForm">
              <div className={styles.formContainer}>
                <Row>
                  <FormInput
                    name="name"
                    rows="6"
                    label={t('VehicleMaintenanceTypes.Form.Name')}
                    placeholder={t('VehicleMaintenanceTypes.Form.NamePlaceholder')}
                    isRequired
                  />
                  <FormSelect
                    name="companyId"
                    label={t('VehicleMaintenanceTypes.Form.Company')}
                    rows="5"
                    onChange={id => onCompanyChange(parseInt(id, 10), setFieldValue)}
                    placeholder=""
                    values={companies.map(company => ({
                      label: company.name,
                      value: company.id
                    }))}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormInput
                    name="description"
                    label={t('VehicleMaintenanceTypes.Form.Description')}
                    rows="10"
                    placeholder={t('VehicleMaintenanceTypes.Form.DescriptionPlaceholder')}
                    as="textarea"
                  />
                </Row>
                <Row>
                  <div className={styles.multiselectWidth}>
                    <SearchableListMultiSelect
                      name="vehicleTypes"
                      label={t('VehicleMaintenanceTypes.Form.VehicleTypes')}
                      placeholder={t('VehicleMaintenanceTypes.Form.VehicleTypesPlaceholder')}
                      allLabel={t('VehicleMaintenanceTypes.Form.AllVehicleTypes')}
                      initialValues={initialVehicleTypes}
                      values={vehicleTypes}
                      setFieldValue={setUserCheckedVehicleTypes}
                      height={250}
                      isLoading={!typesLoaded}
                    />
                  </div>
                  <></>
                </Row>
              </div>
              <div className={styles.formFooter}>
                <Button
                  type="primary"
                  htmlType="submit"
                  id={BUTTON_IDS.vehicleMntTypesFormSave}
                  disabled={!isValid || isSubmitting}
                >
                  {t('Common.SaveButton')}
                </Button>
                <Button id={BUTTON_IDS.vehicleMntTypesFormCancel} onClick={history.goBack}>
                  {t('Common.CancelButton')}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default VehicleMntTypesForm;
