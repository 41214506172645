import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik, useField } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18next from 'i18nextConfig';
import * as Yup from 'yup';
import { Button } from 'antd';

import { openToast } from 'features/toasts/toastsSlice';
import { useUser } from 'features/user/userSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import * as sentinelAPI from 'features/sentinel/endpoints/sentinelAPI';
import { refetchSentinelEvents } from 'features/sentinel/components/events/eventsSlice';
import { LocalizationUtil } from 'features/localization/localization';

import { ToastType } from 'components/notifications/toasts/Toast';
import FormInput from 'components/form/form-input/FormInput';
import FormSelect from 'components/form/form-select/FormSelect';
import SentinelDateTimePicker from 'components/sentinel/SentinelDateTimePicker';
import { useDriverTimezone } from './user/sentinelUserSlice';

import { TIMESTAMP_FORMAT } from '../constants/Generals';
import { BUTTON_IDS } from 'utils/globalConstants';

const DateTimePicker = ({ ...props }) => {
  // This isn't an input, so instead of using the values in 'field' directly,
  // we'll use 'meta' and 'helpers'.
  const [field, meta, helpers] = useField(props.name);
  const { value } = meta;
  const { setValue } = helpers;
  let combinedClassName = props.className ? 'form-group ' + props.className : 'form-group';
  if (meta.touched && meta.error) {
    combinedClassName = combinedClassName + ' mb-0';
  }
  return (
    <div className={combinedClassName}>
      <label htmlFor={field.name}>{props.label}</label>
      <SentinelDateTimePicker
        value={value}
        {...props}
        onBlur={() => field.onBlur}
        onChange={newDate => setValue(newDate)}
      />
      {meta.touched && meta.error ? (
        <small className="form-text text-muted">{meta.error}</small>
      ) : null}
    </div>
  );
};

const sentinelSchema = ruleset => {
  let schema = Yup.object().shape({
    Action: Yup.string().required('Required'),
    EventDateTime: Yup.string().required('Required'),
    Location: Yup.string().max(256, i18next.t('SentinelValidation.LocationLength', { max: 256 })),
    Comment: Yup.string().max(1000, i18next.t('SentinelValidation.CommentLength', { max: 1000 })),
    DeclaredOdometer: Yup.number()
      .max(9999999, i18next.t('SentinelValidation.OdometerSize', { min: -9999999, max: 9999999 }))
      .min(-9999999, i18next.t('SentinelValidation.OdometerSize', { min: -9999999, max: 9999999 })),
    DeclaredRegistration: Yup.string()
      .nullable(true)
      .max(100, i18next.t('SentinelValidation.RegistrationLength', { max: 100 }))
  });

  //location
  const isLocationEditable = ruleset?.options?.['edit.location']?.includes('web');
  const isLocationRequired = ruleset?.options?.['location.required'] || false;
  schema.fields['Location'].withMutation(s => {
    s.nullable(!isLocationEditable || !isLocationEditable);
    if (isLocationRequired) {
      s.required('Required');
    }
  });
  //notes
  const isNotesEditable = ruleset?.options?.['edit.notes']?.includes('web');
  const isNotesRequired = ruleset?.options?.['notes.required'] || false;
  schema.fields['Comment'].withMutation(s => {
    s.nullable(!isNotesEditable || !isNotesRequired);
    if (isNotesRequired) {
      s.required('Required');
    }
  });
  //odometer
  const isOdoEditable = ruleset?.options?.['edit.odometer']?.includes('web');
  const isOdoRequired = ruleset?.options?.['odometer.required'] || false;
  schema.fields['DeclaredOdometer'].withMutation(s => {
    s.nullable(!isOdoEditable || !isOdoRequired);
    if (isOdoRequired) {
      s.required('Required');
    }
  });

  return schema;
};

const SentinelAddEditFormik = ({ initialValues, setOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useUser();
  const driverTimezone = useDriverTimezone();
  const activeRuleset = useSelector(state => state.sentinel.activeRuleset);
  const action = activeRuleset && activeRuleset.actions ? activeRuleset.actions[0] : '';
  const localization = useLocalization();

  const [formSchema, setFormSchema] = useState(() => {
    let schema = sentinelSchema(activeRuleset);
    return schema;
  });

  const [initialFormValues, setInitialFormValues] = useState({
    Action: action,
    EventDateTime: moment()
      .tz(driverTimezone)
      .seconds(0)
      .valueOf(),
    Location: '',
    Comment: '',
    Reason: '',
    DeclaredRegistration: '',
    DeclaredOdometer: ''
  });

  useEffect(() => {
    setFormSchema(sentinelSchema(activeRuleset));
    if (activeRuleset?.actions?.length > 0) {
      setInitialFormValues(prev => {
        if (prev.Action === '') {
          const newFormValue = { ...prev, Action: activeRuleset.actions[0] };
          return newFormValue;
        }
        return prev;
      });
    }
  }, [activeRuleset]);

  useEffect(() => {
    if (initialValues) {
      const declaredOdo = parseFloat(initialValues.declaredOdometer);
      setInitialFormValues({
        id: initialValues.id ? initialValues.id : '',
        Action: initialValues.action ? initialValues.action : action,
        EventDateTime: initialValues.eventAt
          ? initialValues.eventAt
          : moment()
              .seconds(0)
              .valueOf(),
        Location: initialValues.declaredLocation ? initialValues.declaredLocation : '',
        Comment: initialValues.notes ? initialValues.notes : '',
        DeclaredOdometer: (!isNaN(declaredOdo) && localization.convertDistance(declaredOdo)) || '',
        DeclaredRegistration: initialValues.declaredRegistration || ''
      });
    }
  }, [initialValues]);

  const resetInitialFormValues = useCallback(() => {
    setInitialFormValues({
      Action: action,
      EventDateTime: moment()
        .tz(driverTimezone)
        .seconds(0)
        .valueOf(),
      Location: '',
      Comment: '',
      Reason: '',
      DeclaredRegistration: '',
      DeclaredOdometer: ''
    });
  }, [action, driverTimezone]);

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={formSchema}
      enableReinitialize={true}
      onSubmit={(values, actions) => {
        let odo = values.DeclaredOdometer;
        if (odo != null && localization.formats.speed?.unit !== 'km') {
          odo = LocalizationUtil.miletokm(odo);
        }

        const payload = {
          ...values,
          Source: 'TN360',
          EventDateTime: moment(parseInt(values.EventDateTime, 10))
            .tz(driverTimezone)
            .seconds(0)
            .format(TIMESTAMP_FORMAT),
          EntryAt: moment().format(TIMESTAMP_FORMAT),
          Notes: values.Comment,
          DeclaredLocation: values.Location,
          DeclaredOdometer: odo,
          DeclaredRegistration: values.DeclaredRegistration,
          Parameters: {
            UserId: user.id
          }
        };
        const submitEvent = payload.id
          ? payload => sentinelAPI.updateEvent(payload)
          : payload => sentinelAPI.submitEvent(payload);
        submitEvent(payload)
          .then(response => {
            dispatch(
              openToast({
                type: ToastType.Success,
                message: 'Event submitted'
              })
            );
            resetInitialFormValues();
            dispatch(refetchSentinelEvents(true));
            actions.setSubmitting(false);
            setOpen(false);
          })
          .catch(error => {
            console.warn('ERROR', error);
            dispatch(
              openToast({
                type: ToastType.Error,
                message: 'Error submitting event.'
              })
            );
            actions.setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Field id="id" name="id" type="hidden" component="input" />
          <Row className="py-2 form-row align-items-start">
            <Col className="col-auto">
              {activeRuleset && activeRuleset.actions && (
                <FormSelect
                  name="Action"
                  label="Fatigue Event"
                  values={activeRuleset.actions.map(action => ({
                    label: action,
                    value: action
                  }))}
                  isRequired
                />
              )}
            </Col>
            <Col className="col-auto">
              <DateTimePicker name="EventDateTime" label="Time" id="AddDate" />
            </Col>
            <Col>
              <FormInput
                name="Location"
                label="Declared Location"
                placeholder="Address of event"
                isValidated
                isRequired={formSchema.fields['Location'].tests.some(
                  t => t.OPTIONS.name === 'required'
                )}
              />
            </Col>
            <Col>
              <FormInput
                name="Comment"
                label="Comment"
                isValidated
                isRequired={formSchema.fields['Comment'].tests.some(
                  t => t.OPTIONS.name === 'required'
                )}
              />
            </Col>
          </Row>
          <Row className="py-2 form-row align-items-start">
            <Col className="col-auto">
              <FormInput
                name="DeclaredRegistration"
                label="Declared Registration"
                isValidated
                isRequired={formSchema.fields['DeclaredRegistration'].tests.some(
                  t => t.OPTIONS.name === 'required'
                )}
              />
            </Col>
            <Col className="col-auto">
              <FormInput
                name="DeclaredOdometer"
                label="Declared Odometer"
                isValidated
                isRequired={formSchema.fields['DeclaredOdometer'].tests.some(
                  t => t.OPTIONS.name === 'required'
                )}
                type="number"
              />
            </Col>
            <Col
              className="col-auto px-2 mb-3"
              style={{ flex: '1 1', display: 'flex', alignSelf: 'end', placeContent: 'end' }}
            >
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isValid || isSubmitting}
                id={BUTTON_IDS.sentinelAddEditSave}
              >
                {t('Common.SaveButton')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default SentinelAddEditFormik;
