import { FeatureFlag, GlobalRoles } from 'features/permissions';

export const AUDITS_ACTIONS = {
  DELETE: 'delete',
  RESTORE: 'restore'
};

export const AUDIT_ENTITY = {
  DEVICE: 'device',
  DRIVER_MANAGEMENT_SCHEDULE: 'Driver_Management_Schedule',
  JOURNEY: 'trip',
  ROLE: 'role',
  USER: 'user',
  LOCATION: 'location',
  COMPANY: 'company',
  VEHICLE_MAINTENANCE_SCHEDULE: 'Vehicle_Maintenance_Schedule',
  GEOFENCE: 'geofence',
  VEHICLE: 'vehicle',
  WIFI: 'wifi',
  DRIVER_MANAGEMENT_TYPES: 'driver_management_type',
  CONFIGURATION_TEMPLATE: 'Configuration_Template',
  AGREEMENT: 'agreement',
  CAMERA_CONFIGURATION: 'camera_configuration',
  CAMERA_VEHICLE_DEVICE_ASSOCIATION: 'association_configuration'
};

// TODO: Add the rest of the entity urls for audits when Header component becomes the standard
export const AUDIT_URL = {
  [AUDIT_ENTITY.JOURNEY]: '/journeyplanner/audits/id/'
};

// TODO: use uppercase for constants
export const ColumnKeys = {
  TIME: 'createdAt',
  ACTION: 'action',
  USER: 'user',
  USERID: 'userId',
  COMMENT: 'comment',
  CHANGES: 'parameters',
  KEY: 'keyValue',
  FROM: 'from',
  TO: 'to'
};

export const Columns = [
  {
    title: 'Time',
    dataIndex: ColumnKeys.TIME,
    key: ColumnKeys.TIME,
    width: '15%'
  },
  {
    title: 'Action',
    dataIndex: ColumnKeys.ACTION,
    key: ColumnKeys.ACTION,
    width: '10%'
  },
  {
    title: 'User',
    dataIndex: ColumnKeys.USER,
    key: ColumnKeys.USER,
    width: '10%'
  },
  {
    title: 'Comment',
    dataIndex: ColumnKeys.COMMENT,
    key: ColumnKeys.COMMENT,
    width: '10%'
  },
  {
    title: 'Changes',
    dataIndex: ColumnKeys.CHANGES,
    key: ColumnKeys.CHANGES,
    width: '45%'
  }
];

export const ChangesColumns = [
  {
    title: 'Key',
    dataIndex: ColumnKeys.KEY,
    key: ColumnKeys.KEY,
    width: '20%'
  },
  {
    title: 'From',
    dataIndex: ColumnKeys.FROM,
    key: ColumnKeys.FROM,
    width: '40%'
  },
  {
    title: 'To',
    dataIndex: ColumnKeys.TO,
    key: ColumnKeys.TO,
    width: '40%'
  }
];

export const PROTECTED_AUDIT_KEYS = {
  features: {
    //backward compatibility
    can: {
      oneOfRoles: [GlobalRoles.Admin]
    }
  },
  featureList: {
    //renamed features
    can: {
      oneOfRoles: [GlobalRoles.Admin]
    }
  }
};
