// We'll use this file to translate whatever constant content we need
import {
  TABLE_COLUMNS,
  TABLE_RV_JOBS_COLUMNS,
  TABLE_RV_JOBS_ITEMS_COLUMNS,
  TABLE_RV_EVENTS_COLUMNS,
  JOBS_TABLE_COLUMNS,
  ITEMS_TABLE_COLUMNS
} from './constants';

import { Table } from 'antd';

const translateTitle = t => column => ({
  ...column,
  title: t(`SmartJobs.ColumnList.Columns.${column.key}`)
});

export const getSmartJobsTableColumns = t => TABLE_COLUMNS.map(translateTitle(t));
export const getJobsTableColumns = t => TABLE_RV_JOBS_COLUMNS.map(translateTitle(t));
export const getItemsTableColumns = t => TABLE_RV_JOBS_ITEMS_COLUMNS.map(translateTitle(t));
export const getRunsheetEventsTableColumns = t => TABLE_RV_EVENTS_COLUMNS.map(translateTitle(t));
export const getRunsheetJobsTableColumns = t => {
  const columns = JOBS_TABLE_COLUMNS.map(translateTitle(t));
  columns.splice(5, 0, Table.EXPAND_COLUMN);
  return columns;
};
export const getRunsheetItemsTableColumns = t => ITEMS_TABLE_COLUMNS.map(translateTitle(t));
