import React, { useState } from 'react';
import { Table, Switch, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import { toTitleCase } from 'utils/strings';

import { GPIOOccurrence } from 'containers/Administration/Vehicles/constants';

import styles from 'components/tn/grid/grid.module.scss';

export const GPOutputUpdateTable = ({ data, onStatusChanged, ...props }) => {
  const isStatusOn = () => {
    if (data?.nextStatus) {
      return (
        data?.nextStatus?.toLowerCase().startsWith('pending on') ||
        data?.nextStatus?.toLowerCase().startsWith('on')
      );
    } else if (data?.status) {
      return data?.status?.toLowerCase() === 'on';
    }
    return false;
  };

  const getNextStatus = isOn => {
    return isOn !== isInitiallyOn || !(data.nextStatus || data.status)
      ? isStatusChangeDelayed
        ? t('GPIO.Tables.PendingStatusIgnOff', {
            status: isOn ? t('GPIO.Status.On') : t('GPIO.Status.Off')
          })
        : isOn
        ? t('GPIO.Status.On')
        : t('GPIO.Status.Off')
      : toTitleCase(data.nextStatus || data.status);
  };

  const { t } = useTranslation();

  const [isOn, setIsOn] = useState(isStatusOn());

  const isInitiallyOn = isStatusOn();
  const isStatusChangeDelayed = parseInt(data?.occurrence) === GPIOOccurrence.IgnitionOff.id;
  const nextStatus = getNextStatus(isOn);

  const columns = [
    {
      title: t('GPIO.Tables.NextStatus'),
      key: 'status',
      dataIndex: 'status',
      width: 300,
      render: () => {
        return (
          <>
            <Switch
              style={{ marginRight: '10px' }}
              checked={isOn}
              onChange={checked => {
                setIsOn(checked);
                onStatusChanged &&
                  onStatusChanged(
                    data,
                    parseInt(data?.channel?.slice(-1)),
                    checked,
                    getNextStatus(checked)
                  );
              }}
            />
            {isStatusChangeDelayed && (
              <Spin style={{ marginRight: '10px', verticalAlign: 'top' }} />
            )}
            <span>{nextStatus}</span>
          </>
        );
      }
    },
    {
      title: t('GPIO.Tables.Channel'),
      key: 'channel',
      dataIndex: 'channel',
      width: 300
    },
    {
      title: t('GPIO.Tables.Description'),
      key: 'description',
      dataIndex: 'description',
      render: (value, rowData, rowIndex) => {
        return toTitleCase(
          value + (rowData.disambiguator ? ' (' + rowData.disambiguator + ')' : '')
        );
      }
    }
  ];

  return (
    <Table
      className={styles.grid}
      style={{ width: '100%' }}
      dataSource={[data]}
      columns={columns}
      rowKey={'key'}
      loading={false}
      showSorterTooltip={false}
      pagination={false}
      sticky={true}
      scroll={{ x: 'max-content' }}
      {...props}
    />
  );
};
