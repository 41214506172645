import React from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { EllipsisButton } from 'components/tables/EllipsisButton';

export const fleetsCellRenderer = ({
  rowIndex,
  rowData,
  dataKey,
  parent,
  fleets,
  companies,
  t
}) => {
  const form = rowData;
  const formFleetsIds = (form.Associations || []).map(association => association.fleetId);
  const formFleets = [...fleets, { name: t('Common.NoFleet'), id: -1 }].filter(fleet =>
    formFleetsIds.includes(fleet.id)
  );
  const fleetElement = formFleets.map((fleet, i) => {
    const fleetCompanyName =
      fleet.name !== t('Common.NoFleet')
        ? `(${companies?.find(company => company.id === fleet.company.id)?.name || ''})`
        : '';
    return (
      <div key={`row-${dataKey}-${form.id}-${i}`}>
        {fleet.name} {fleetCompanyName}
        {i < formFleets.length - 1 ? ',' : ''}
      </div>
    );
  });
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${form.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div>{fleetElement.length > 0 && <div>{fleetElement}</div>}</div>
    </CellMeasurer>
  );
};
export const nameCellRenderer = ({ rowData, canViewForms }) => {
  return canViewForms ? (
    <Link to={`/settings/forms/id/${rowData.Id}`}>{rowData.Name}</Link>
  ) : (
    <div>{rowData.Name}</div>
  );
};

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const ellipsisButtonCellRenderer = ({
  dataKey,
  rowData,
  typeOfEntityToDelete,
  handleDeleteAction
}) => {
  return (
    <EllipsisButton
      dataKey={dataKey}
      typeOfEntityToDelete={typeOfEntityToDelete}
      handleDeleteAction={handleDeleteAction}
      path={'/settings/forms/'}
      data={rowData}
      hideCopy={false}
      canUse="FORM"
    />
  );
};
