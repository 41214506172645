import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { DateRangePicker, MoreMenu } from 'components/ant';
import { Button } from 'antd';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import moment from 'moment';
import { FBTTable } from './';
import { FBTStatisticsCard } from './FBTStatisticsCard';
import { AddTypeModal } from './TypeOfUse';
import { ToastType } from 'components/notifications/toasts/Toast';
import {
  openToast,
  setBulkUpdateState,
  useBulkUpdateMode,
  setFilterUpdates
} from 'features/toasts/toastsSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useFleets, useIsFetching as isFleetsFetching } from 'features/fleets/fleetsSlice';
import {
  useTrips,
  useIsFetching as isTripsFetching,
  useTripStates,
  fetchTrips,
  fetchTripStates,
  setDates,
  useDates,
  useFilters,
  setFilters
} from 'features/fbt';
import { usePrevious } from 'utils/methods';
import { useLocalization } from 'features/localization/localizationSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { prepareDataForMultiselect, FilterTypes } from 'utils/filters';
import { driversKeys, vehiclesKeys } from './utils/constants';
import { parseErrorMessage } from 'utils/strings';
import { filterTrips, getFilterOptionsFromTrips, sortByName } from './utils/helpers';
import { countDropdownSelectedValues } from 'utils/filters';
import useDebounce from 'utils/hooks/useDebounce';
import { formatDateDifference, getTypesOfUse } from './utils';
import { FeatureFlag, useCanFeatureFlag, useCanOneOfEntities, useCan } from 'features/permissions';
import styles from './FBTManager.module.scss';
import dayjs from 'dayjs';
import { useUserInfo } from 'features/user/userSlice';

const EDIT_TRIP_STATE_PERMISSION = 'TRIP_EDIT_PURPOSE';

const useDatePickerPanel = (t, dateFrom, dateTo) => {
  const RangeType = {
    START: 'start',
    END: 'end'
  };
  const [datePickerPanel, setDatePickerPanel] = useState({
    range: null,
    dates: null
  });

  const { placeholders, setDatePickerRange, setDatePickerDates } = useMemo(
    () => ({
      ...datePickerPanel,
      placeholders: [
        { label: t('FBTManager.startedAt'), range: RangeType.START },
        { label: t('FBTManager.endedAt'), range: RangeType.END }
      ],
      setDatePickerRange: range => setDatePickerPanel({ ...datePickerPanel, range }),
      setDatePickerDates: dates => setDatePickerPanel({ ...datePickerPanel, dates })
    }),
    [t, datePickerPanel]
  );

  useEffect(() => {
    setDatePickerDates([moment(dateFrom).endOf('day'), moment(dateTo).endOf('day')]);
  }, [dateFrom, dateTo]);

  const placeholder = useMemo(() => placeholders.map(placeholder => placeholder.label), [
    placeholders
  ]);

  const onFocus = useCallback(
    e => {
      const focusedDateRange = placeholders.find(p => p.label === e?.target?.placeholder).range;
      if (focusedDateRange) {
        setDatePickerRange(focusedDateRange);
      }
    },
    [placeholders]
  );

  return {
    placeholder,
    datePickerPanel,
    setDatePickerRange,
    setDatePickerDates,
    onFocus
  };
};
export const FBTManager = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const can = useCan();
  const user = useUserInfo();
  const fleets = useFleets();
  const tripStates = useTripStates();
  const tripsLoadingStatus = isTripsFetching();
  const localization = useLocalization();
  const currentCompany = useCurrentCompany();
  const prevCompany = usePrevious(currentCompany);
  const bulkUpdateMode = useBulkUpdateMode();
  const fbtMultipleCallsFeatureFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.fbtMultipleCalls.flag
  });
  const fbtBulkAssignment = useCanFeatureFlag({
    featureFlag: FeatureFlag.fbtBulkAssignment.flag
  });
  const isDriverPortal_FBTManager =
    user?.type?.code === 'DRIVER' && can({ featureFlag: FeatureFlag.driverPortalTripManager.flag })
      ? true
      : false;

  const { dateFrom, dateTo } = useDates();
  const maxDayRange = useMemo(() => {
    return Math.abs(
      moment
        .duration(
          moment()
            .endOf('day')
            .add(-3, 'months')
            .add(-1, 'day')
            .diff(moment().endOf('day'))
        )
        .asDays()
    );
  }, []);

  const canEditTrips = useCanOneOfEntities(EDIT_TRIP_STATE_PERMISSION);
  const [filterText, setFilterText] = useState('');
  const debouncedFilterText = useDebounce(filterText, 300);
  const [filteredFleets, setFilteredFleets] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [filteredTypesOfUse, setFilteredTypesOfUse] = useState([]);
  const [isAddTypeOfUseOpen, setTypeOfUseModal] = useState(false);
  const [datePicker, setDatePicker] = useState({
    isOpen: false,
    hasBeenOpened: false,
    haveDatesBeenChanged: false
  });
  const filters = useFilters();

  const { placeholder, setDatePickerRange, setDatePickerDates, onFocus } = useDatePickerPanel(
    t,
    dateFrom,
    dateTo,
    maxDayRange
  );

  const trips = useTrips(dateFrom, dateTo, fbtMultipleCallsFeatureFlag);

  const [filteredTrips, setFilteredTrips] = useState(trips);
  const [period, setPeriod] = useState(formatDateDifference(dateFrom, dateTo, t, localization));

  const moreMenuItems = [
    // Removed as per TN360-998 request
    // { name: t('FBTManager.AuditTrialReport'), onClick: () => {} },
    // { name: t('FBTManager.ExportReport'), onClick: () => {} },
    {
      name: t('FBTManager.AddTypesOfUse'),
      onClick: () => {
        setTypeOfUseModal(true);
      },
      id: 'btn_fbtMenuAddTypesUse'
    }
  ];

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('FBTManager.Title')));
  }, [dispatch, t]);

  const handleDateRangeChange = (dates, info) => {
    setDatePickerDates(dates);
    setDatePickerRange(info.range);
    if (!dates || !dates[0] || !dates[1]) {
      return;
    }
    setDatePicker(prev => {
      return { ...prev, haveDatesBeenChanged: true };
    });
    const tempStartAt = moment(dates[0].format('YYYY-MM-DD'));
    const tempEndAt = moment(dates[1].format('YYYY-MM-DD'));
    dispatch(
      setDates({
        dateFrom: tempStartAt.format('YYYY-MM-DD'),
        dateTo: tempEndAt.format('YYYY-MM-DD')
      })
    );

    setPeriod(
      formatDateDifference(
        tempStartAt.format('YYYY-MM-DD'),
        tempEndAt.format('YYYY-MM-DD'),
        t,
        localization
      )
    );
  };

  useEffect(() => {
    // We don't need to fetch the data if we don't change the company or if it's already fetching
    if (!tripsLoadingStatus && prevCompany) {
      dispatch(
        fetchTrips({
          query: { from: dateFrom, to: dateTo, fbtMultipleCallsFeatureFlag },
          errorToaster: err =>
            openToast({
              type: ToastType.Error,
              message: parseErrorMessage(err)
            })
        })
      );
      dispatch(fetchTripStates());
    }
  }, [currentCompany]);

  useEffect(() => {
    const { isOpen, hasBeenOpened, haveDatesBeenChanged } = datePicker;
    if (!isOpen && hasBeenOpened && !tripsLoadingStatus && haveDatesBeenChanged) {
      dispatch(fetchTrips({ query: { from: dateFrom, to: dateTo, fbtMultipleCallsFeatureFlag } }));
      setDatePicker(prev => {
        return { ...prev, haveDatesBeenChanged: false };
      });
    }
  }, [datePicker, dispatch]);

  useEffect(() => {
    const typeOfUseOptions = getTypesOfUse(tripStates);

    typeOfUseOptions.forEach(data => {
      data.checked = filters.typeOfUses === null || filters.typeOfUses.includes(data.id);
    });

    setFilteredTypesOfUse(typeOfUseOptions);
  }, [tripStates]);

  useEffect(() => {
    setFilteredTrips(
      filterTrips(trips, {
        filterText: debouncedFilterText,
        filteredVehicles,
        filteredDrivers,
        filteredTypesOfUse,
        filteredFleets
      })
    );
  }, [
    trips,
    debouncedFilterText,
    filteredVehicles,
    filteredDrivers,
    filteredTypesOfUse,
    filteredFleets
  ]);

  useEffect(() => {
    if (fleets && trips) {
      // Get only the fleets associated with our trips
      const tripFleetsIds = trips.reduce((acc, current) => {
        if (current.fleets?.length) {
          for (let i = 0; i < current.fleets.length; i++) {
            const fleet = current.fleets[i];
            if (!acc.includes(fleet.id)) {
              acc.push(fleet.id);
            }
          }
        }
        return acc;
      }, []);

      const filterFleets =
        fleets &&
        prepareDataForMultiselect(
          fleets.filter(fleet => tripFleetsIds.includes(fleet.id)).sort(sortByName),
          t('Common.AllFleets'),
          filters?.fleets
        );
      filterFleets.push({ id: -1, label: t('Common.NoFleet'), checked: true });
      setFilteredFleets(filterFleets);
    }
  }, [fleets, trips]);

  useEffect(() => {
    const initialValueVehicles = {
      id: 0,
      name: t('Common.AllVehicles')
    };

    const initialValueDrivers = {
      id: 0,
      name: t('Common.AllDrivers')
    };

    const vehicles = getFilterOptionsFromTrips(
      trips,
      vehiclesKeys,
      initialValueVehicles,
      false,
      filters?.vehicles,
      t
    );
    const drivers = getFilterOptionsFromTrips(
      trips,
      driversKeys,
      initialValueDrivers,
      true,
      filters?.drivers,
      t
    );

    setFilteredDrivers(drivers);
    setFilteredVehicles(vehicles);
  }, [t, trips]);

  const handleDateRangeClose = isOpen => {
    setDatePicker(prev => {
      return { ...prev, isOpen, hasBeenOpened: true };
    });
    dispatch(setFilterUpdates(true));
  };

  const handleSearchTable = text => {
    setFilterText(text);
    dispatch(setFilterUpdates(true));
  };

  const handleFleetChange = newFleets => {
    const type = FilterTypes.fleets;
    const filteredIds = newFleets.filter(i => i.checked).map(f => f.id);

    dispatch(setFilters({ type, filteredIds }));
    setFilteredFleets(newFleets);
    dispatch(setFilterUpdates(true));
  };

  const handleVehicleChange = newVehicles => {
    const type = FilterTypes.vehicles;
    const filteredIds = newVehicles.filter(i => i.checked).map(v => v.id);

    dispatch(setFilters({ type, filteredIds }));
    setFilteredVehicles(newVehicles);
    dispatch(setFilterUpdates(true));
  };

  const handleDriverChange = newDrivers => {
    const type = FilterTypes.drivers;
    const filteredIds = newDrivers.filter(i => i.checked).map(d => d.id);

    dispatch(setFilters({ type, filteredIds }));
    setFilteredDrivers(newDrivers);
    dispatch(setFilterUpdates(true));
  };

  const handleTypeOfUseChange = newTypes => {
    const type = FilterTypes.typesOfUses;
    const filteredIds = newTypes.filter(i => i.checked).map(t => t.id);

    dispatch(setFilters({ type, filteredIds }));
    setFilteredTypesOfUse(newTypes);
    dispatch(setFilterUpdates(true));
  };

  const handleCancelModal = () => {
    setTypeOfUseModal(false);
  };

  return (
    <>
      {fbtBulkAssignment && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px' }}>
          <Button
            type="primary"
            style={{ margin: '5px', height: '40px' }}
            disabled={bulkUpdateMode}
            onClick={() => {
              dispatch(setBulkUpdateState());
            }}
            id={BUTTON_IDS.fbtBulkAssignment}
          >
            {t('ELD.Bulk Assignment')}
          </Button>
        </div>
      )}
      {isAddTypeOfUseOpen && (
        <AddTypeModal
          onCancel={handleCancelModal}
          tripStates={tripStates?.tripStates}
          originalDefault={tripStates?.defaultTripState}
          editable={canEditTrips}
        />
      )}
      <div className={styles.filterWrapper}>
        <FilterWrapper rowStyle={styles.spaceBetweenRow}>
          <span className={styles.filterContainer}>
            <AntSearchbar onFilter={handleSearchTable} />
            {!isDriverPortal_FBTManager && (
              <AntMultiselect
                title={countDropdownSelectedValues(filteredFleets, t('Common.Fleets'), true)}
                data={filteredFleets}
                onFilter={handleFleetChange}
                loading={isFleetsFetching()}
              />
            )}
            <AntMultiselect
              title={countDropdownSelectedValues(filteredVehicles, t('Common.Vehicles'), true)}
              data={filteredVehicles}
              onFilter={handleVehicleChange}
            />
            {!isDriverPortal_FBTManager && (
              <AntMultiselect
                title={countDropdownSelectedValues(filteredDrivers, t('Common.Drivers'), true)}
                data={filteredDrivers}
                onFilter={handleDriverChange}
              />
            )}
            <AntMultiselect
              title={countDropdownSelectedValues(filteredTypesOfUse, t('Common.TypesOfUse'), true)}
              data={filteredTypesOfUse}
              onFilter={handleTypeOfUseChange}
            />
          </span>

          <span className={styles.filterContainer}>
            <DateRangePicker
              size="large"
              maxDayRange={maxDayRange}
              placeholder={placeholder}
              availableDatesRange={[0, moment().endOf('day')]}
              onFocus={onFocus}
              format={localization.formats.time.formats.dby.toUpperCase()}
              defaultDates={[dayjs(dateFrom), dayjs(dateTo)]}
              onDateRangeChanged={handleDateRangeChange}
              onOpenChange={handleDateRangeClose}
              adjustStartEndByXDays={-1}
            />
            {!isDriverPortal_FBTManager && (
              <MoreMenu size="xl" border items={moreMenuItems} placement="bottomRight" />
            )}
          </span>
        </FilterWrapper>
      </div>
      <FBTStatisticsCard trips={filteredTrips} loading={tripsLoadingStatus} period={period} />
      <FBTTable
        trips={filteredTrips}
        localization={localization}
        tripStates={tripStates?.tripStates}
        loading={tripsLoadingStatus}
        editable={canEditTrips}
        fbtMultipleCallsFeatureFlag={fbtMultipleCallsFeatureFlag}
      />
    </>
  );
};
