import i18n from 'i18next';
import { CountryRegionData } from 'react-country-region-selector';
import * as addressFormatter from '@fragaria/address-formatter';

export const initialAddress = {
  number: '',
  street: '',
  suburb: '',
  state: undefined,
  postcode: '',
  country: undefined
};

export const AllCountryRegionData = CountryRegionData;

export const getCountryCodebyName = countryName => {
  const countryArr = CountryRegionData.find(
    countryArr => countryArr && countryArr[0] && countryArr[0] === countryName
  );
  return countryArr && countryArr[1];
};

export const getCountryNamebyCode = countryCode => {
  const countryArr = CountryRegionData.find(
    countryArr => countryArr && countryArr[1] && countryArr[1] === countryCode?.toUpperCase()
  );
  return countryArr && countryArr[0];
};

export const getFormattedAddressStr = (addressObj, fullAddress = true) => {
  const address = { ...initialAddress, ...(addressObj || {}) };
  const { number: houseNumber, street: road, suburb, state, postcode, country } = address;
  const countryCode = getCountryCodebyName(country);
  const defaultFormat = `${houseNumber} ${road ? road + ',' : ''} ${suburb ? suburb + ',' : ''}${
    fullAddress ? (state ? ' ' + state + ' ' : '') : ''
  }${postcode ? ' ' + postcode + ',' : ''} ${country}`.trim();

  const getAddrObj = countryCode => {
    switch (countryCode) {
      case 'CA':
        return {
          houseNumber,
          road,
          postcode,
          city: suburb,
          state,
          country,
          countryCode
        };
      case 'NZ':
        return {
          houseNumber,
          road,
          postcode,
          city: `${suburb}, ${state}`,
          country,
          countryCode
        };
      case 'GB':
        return {
          houseNumber,
          road: `${road}, ${suburb}`,
          postcode,
          county: state,
          country,
          countryCode
        };
      default:
        return {
          houseNumber,
          road,
          postcode,
          suburb,
          state,
          country,
          countryCode
        };
    }
  };
  try {
    const addr = getAddrObj(countryCode);
    const formattedAddr = addressFormatter.format(addr, {
      abbreviate: true,
      output: 'array',
      appendCountry: true,
      cleanupPostcode: false
    });
    return formattedAddr
      .join(', ')
      .replace(!fullAddress ? `${state}` : '', '')
      .replace(/,(\s+)?,/, ',');
  } catch (error) {
    return defaultFormat;
  }
};

export const getStateLabelByCountry = countryName => {
  const countryCode = getCountryCodebyName(countryName);
  switch (countryCode) {
    case 'CA':
      return i18n.t('Locations.Form.ProvinceTerritory');
    case 'GB':
    case 'IE':
      return i18n.t('Locations.Form.County');
    default:
      return i18n.t('Locations.Form.State');
  }
};
export const getStatePlaceholderByCountry = countryName => {
  return `${i18n.t('Common.Select')} ${getStateLabelByCountry(countryName)}`;
};
export const getSuburbLabelByCountry = countryName => {
  const countryCode = getCountryCodebyName(countryName);
  switch (countryCode) {
    case 'GB':
      return `${i18n.t('Locations.Form.CityTown')}`;
    case 'US':
    case 'MX':
      return `${i18n.t('Locations.Form.City')}`;
    case 'CA':
      return `${i18n.t('Locations.Form.Municipality')}`;
    default:
      return `${i18n.t('Locations.Form.Suburb')}`;
  }
};
export const getSuburbPlaceholderByCountry = countryName => {
  return `${i18n.t('Locations.Form.TypeInPlaceholder')} ${getSuburbLabelByCountry(countryName)}`;
};

export const slicedToArray = (function() {
  function sliceIterator(arr, i) {
    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i['return']) _i['return']();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function(arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError('Invalid attempt to destructure non-iterable instance');
    }
  };
})();
