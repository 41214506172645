import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../config';
import { ApiClient } from '../../nextgen_api/index';
import { useSelector } from 'react-redux';

export const supportELD_Data = {
  summaryByDriver: null,
  summaryByVehicle: null,
  summaryDetailsByVehicle: null,
  summaryDetailsByDriver: null
};

const supportELDSlice = createSlice({
  name: 'supportELDData',
  initialState: supportELD_Data,
  reducers: {
    fetchSummaryByDriverStart(state, { payload }) {
      state.summaryByDriver = null;
    },
    fetchSummaryByDriverSucceeded(state, { payload }) {
      const { data } = payload;
      state.summaryByDriver = data;
    },
    fetchSummaryByDriverFailed(state, { payload }) {
      state.summaryByDriver = null;
    },
    fetchSummaryByVehicleStart(state, { payload }) {
      state.summaryByVehicle = null;
    },
    fetchSummaryByVehicleSucceeded(state, { payload }) {
      const { data } = payload;
      state.summaryByVehicle = data;
    },
    fetchSummaryByVehicleFailed(state, { payload }) {
      state.summaryByVehicle = null;
    },

    fetchSummaryDetailsByVehicleStart(state, { payload }) {
      state.summaryDetailsByVehicle = null;
    },
    fetchSummaryDetailsByVehicleSucceeded(state, { payload }) {
      const { data } = payload;
      state.summaryDetailsByVehicle = data;
    },
    fetchSummaryDetailsByVehicleFailed(state, { payload }) {
      state.summaryDetailsByVehicle = null;
    },
    fetchSummaryDetailsByDriverStart(state, { payload }) {
      state.summaryDetailsByDriver = null;
    },
    fetchSummaryDetailsByDriverSucceeded(state, { payload }) {
      const { data } = payload;
      state.summaryDetailsByDriver = data;
    },
    fetchSummaryDetailsByDriverFailed(state, { payload }) {
      state.summaryDetailsByDriver = null;
    }
  }
});

export const {
  fetchSummaryByDriverStart,
  fetchSummaryByDriverSucceeded,
  fetchSummaryByDriverFailed,
  fetchSummaryByVehicleStart,
  fetchSummaryByVehicleSucceeded,
  fetchSummaryByVehicleFailed,

  fetchSummaryDetailsByVehicleStart,
  fetchSummaryDetailsByVehicleSucceeded,
  fetchSummaryDetailsByVehicleFailed,
  fetchSummaryDetailsByDriverStart,
  fetchSummaryDetailsByDriverSucceeded,
  fetchSummaryDetailsByDriverFailed
} = supportELDSlice.actions;

export const fetchSummaryByDriver = () => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;

  dispatch(fetchSummaryByDriverStart());
  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/eld/support/summary/${companyId}/driver`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchSummaryByDriverSucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchSummaryByDriverFailed(payload));
  }
};

export const fetchSummaryByVehicle = () => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;

  dispatch(fetchSummaryByVehicleStart());
  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/eld/support/summary/${companyId}/vehicle`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchSummaryByVehicleSucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchSummaryByVehicleFailed(payload));
  }
};

export const fetchSummaryDetailsByVehicle = (vehicleId, fromDate, toDate) => async (
  dispatch,
  getState
) => {
  const userKey = getState().user?.current?.auth?.key;

  dispatch(fetchSummaryDetailsByVehicleStart());
  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var eldUrl = `/eld/support/detail/vehicle/${vehicleId}`;

    apiClient.callApi(
      eldUrl,
      'GET',
      { vehicleId: vehicleId },
      {
        fromDateTime: fromDate.toISOString(),
        toDateTime: toDate.toISOString()
      },
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchSummaryDetailsByVehicleSucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchSummaryDetailsByVehicleFailed(payload));
  }
};

export const fetchSummaryDetailsByDriver = (driverId, fromDate, toDate) => async (
  dispatch,
  getState
) => {
  const userKey = getState().user?.current?.auth?.key;

  dispatch(fetchSummaryDetailsByDriverStart());
  const promise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var eldUrl = `/eld/support/detail/driver/${driverId}`;

    apiClient.callApi(
      eldUrl,
      'GET',
      { driverId: driverId },
      {
        fromDateTime: fromDate.toISOString(),
        toDateTime: toDate.toISOString()
      },
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    dispatch(fetchSummaryDetailsByDriverSucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchSummaryDetailsByDriverFailed(payload));
  }
};

export const useSummaryByDriver = () => {
  const data = useSelector(state => state.supportELDData.summaryByDriver);
  return data;
};

export const useSummaryByVehicle = () => {
  return useSelector(state => state.supportELDData.summaryByVehicle);
};

export const useSummaryDetailsByVehicle = () => {
  return useSelector(state => state.supportELDData.summaryDetailsByVehicle);
};

export const useSummaryDetailsByDriver = () => {
  return useSelector(state => state.supportELDData.summaryDetailsByDriver);
};

export default supportELDSlice.reducer;
