import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Space, Button, Dropdown } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { entities, useCan } from 'features/permissions';
import { RequestFootageModal } from 'features/requestFootage/RequestFootageModal';
import styles from '../Snapshots.module.scss';

export const DailySnapshotsHeader = ({ t, deviceId, vehicleId, nodeType, devices }) => {
  const can = useCan();
  const [rvDeviceId, setRVDeviceId] = useState(null);
  const cameraDevices = devices?.filter(device => device.type?.code === 'CAMERA') || [
    {
      id: deviceId
    }
  ];

  return (
    <div className={styles.topBtns}>
      <Space size={16}>
        <ExpandableButton
          id={`RV-${nodeType}-${vehicleId || deviceId}`}
          label={t('Home.Request Video')}
          type="primary"
          trigger={['click']}
          items={cameraDevices.map(camera => ({
            key: camera.id,
            label: camera.name || camera.id,
            title: camera.name || camera.id
          }))}
          overlayClassName={styles.highlightOnHover}
          disabled={!cameraDevices?.length}
          onClick={deviceId => {
            setRVDeviceId(deviceId);
          }}
        />
        <ExpandableButton
          id={`more-${nodeType}-${vehicleId || deviceId}`}
          label={<EllipsisOutlined />}
          trigger={['click']}
          btnClassName={styles.moreIcon}
          overlayClassName={styles.dropdownMenu}
          items={[
            ...[
              nodeType === 'Vehicle' && can({ everyEntity: [entities.VEHICLE_VIEW] })
                ? {
                    key: vehicleId,
                    label: (
                      <Link to={`/settings/vehicles/id/${vehicleId}`}>
                        {t('Home.View Vehicle')}
                      </Link>
                    ),
                    title: t('Home.View Vehicle')
                  }
                : null
            ],
            ...[
              !!cameraDevices.length && can({ everyEntity: [entities.DEVICE_VIEW] })
                ? cameraDevices.length === 1
                  ? {
                      key: cameraDevices[0].id,
                      label: (
                        <Link to={`/settings/devices/id/${cameraDevices[0].id}`}>
                          {t('Home.View Device')}
                        </Link>
                      ),
                      title: t('Home.View Device')
                    }
                  : {
                      key: `${nodeType}-${vehicleId || deviceId}`,
                      label: t('Home.View Device'),
                      title: t('Home.View Device'),
                      expandIcon: () => null,
                      popupOffset: [4, 0],
                      popupClassName: styles.dropdownMenu,
                      children: cameraDevices.map(camera => ({
                        key: camera.id,
                        label: (
                          <Link to={`/settings/devices/id/${camera.id}`}>
                            {camera.name || camera.id}
                          </Link>
                        ),
                        title: camera.name || camera.id
                      }))
                    }
                : null
            ]
          ]}
        />
      </Space>
      {rvDeviceId && (
        <RequestFootageModal
          deviceId={rvDeviceId}
          vehicleId={vehicleId}
          showModal={!!rvDeviceId}
          onClose={() => {
            setRVDeviceId(null);
          }}
        />
      )}
    </div>
  );
};

const ExpandableButton = ({
  id,
  items = [],
  label,
  disabled,
  type,
  trigger,
  size = 'large',
  btnClassName,
  overlayClassName,
  onClick = deviceId => {}
}) => {
  if (items?.length > 1) {
    return (
      <Dropdown
        menu={{
          items,
          onClick: ({ key }) => onClick(key)
        }}
        trigger={trigger}
        overlayClassName={overlayClassName}
      >
        <Button id={id} type={type} size={size} className={btnClassName}>
          {label}
        </Button>
      </Dropdown>
    );
  } else if (items?.length === 1) {
    return (
      <Button
        id={id}
        type={type}
        size={size}
        title={items[0].title}
        onClick={() => onClick(items[0].key)}
        disabled={disabled}
      >
        {label}
      </Button>
    );
  }
  return null;
};
