import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, Row, Col, Input } from 'antd';

import { ScorecardEventsList } from './ScorecardEventsList';
import { TabKeys } from './constants';
import { includeExcludeEvents, useIsIncludeExcludeEventsSaving } from 'features/scorecard';
import { useTranslation } from 'react-i18next';

import { InfoCircleFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import { clearScore, clearScoreEvents } from 'features/scorecard/reducers';

import eventStyles from './ScorecardEvents.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const getRelevantEvents = (eventsForIncludeExclude, isExclude) => {
  let events = eventsForIncludeExclude?.filter(event =>
    isExclude ? event.excluded !== true && event.geofenceExcluded !== true : event.excluded === true
  );

  // make sure they are sorted by date newest to oldest
  events = events?.sort((a, b) => {
    const aTimeAt = new Date(a.timeAt);
    const bTimeAt = new Date(b.timeAt);
    if (aTimeAt > bTimeAt) {
      return -1;
    }
    if (aTimeAt < bTimeAt) {
      return 1;
    }
    return 0;
  });

  return events;
};

const getTotalPenaltyPoints = (events, entityType, isExclude) => {
  let totalPenaltyPoints = 0;

  events.forEach(event => {
    const penaltyPoints = entityType === TabKeys.drivers ? event.userPoints : event.vehiclePoints;

    totalPenaltyPoints += isExclude ? penaltyPoints : -penaltyPoints; // reverse points for include
  });

  return totalPenaltyPoints;
};

export const ScorecardEventsModal = ({
  eventsForIncludeExclude,
  entityId,
  entityType,
  isExclude,
  isOpen = false,
  currentCompany,
  onSave,
  onCancel
}) => {
  const { t } = useTranslation();
  const isIncludeExcludeEventsSaving = useIsIncludeExcludeEventsSaving();
  const dispatch = useDispatch();

  const [comment, setComment] = useState('');

  const onEventsModalSave = async (comment, events, isExclude) => {
    const result = await dispatch(
      includeExcludeEvents({
        comment: comment,
        events: events,
        entityType: entityType,
        entityId: entityId,
        isExclude: isExclude,
        companyId: currentCompany?.id
      })
    );

    const operation = isExclude ? 'Exclude' : 'Include';
    let success = false;

    if (result.error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t(`Scorecard.${operation}EventsSaveError`)
        })
      );
    } else {
      // trigger a refresh of the whole event list and summary data with new score
      dispatch(clearScoreEvents());
      dispatch(clearScore());

      dispatch(
        openToast({
          type: ToastType.Success,
          message: t(`Scorecard.${operation}EventsSaveSuccess`)
        })
      );
      success = true;
    }

    onSave(success);
  };

  const events = getRelevantEvents(eventsForIncludeExclude, isExclude);
  const totalPenaltyPoints = getTotalPenaltyPoints(events, entityType, isExclude);

  const operation = isExclude ? 'Exclude' : 'Include';

  return (
    <Modal
      open={isOpen}
      centered={true}
      closable={false}
      width={900}
      footer={null}
      wrapClassName={eventStyles.eventsModal}
    >
      <Row wrap={false} className={eventStyles.eventsModalContent}>
        <Col flex="480px" className={eventStyles.eventsModalLeftPane}>
          <div>
            <InfoCircleFilled className={eventStyles.eventsModalWarningIcon} />
            <span className={eventStyles.eventsModalConfirmText}>
              {t(`Scorecard.${operation}EventsConfirmation`)}
            </span>
          </div>
          <div className={eventStyles.eventsModalAdditionalInfoText}>
            {t(`Scorecard.${operation}EventsInfo`)}
          </div>
          <div>
            <span className={eventStyles.eventsModalLeaveNoteText}>
              {t('Scorecard.LeaveANote')}
            </span>
          </div>
          <div className={eventStyles.eventsModalCommentInputContainer}>
            <Input.TextArea
              placeholder={t('Scorecard.WriteSomething')}
              className={eventStyles.eventsModalCommentInput}
              rows={4}
              onChange={e => setComment(e.target.value)}
            />
          </div>
          <div>
            <Button
              type="primary"
              size="large"
              loading={isIncludeExcludeEventsSaving}
              className={eventStyles.eventsModalSaveButton}
              onClick={() => onEventsModalSave(comment, events, isExclude)}
              id={BUTTON_IDS.scorecardEventsModalSave}
            >
              {t(`Scorecard.${operation}Events`)}
            </Button>
            <Button
              id={BUTTON_IDS.scorecardEventsModalCancel}
              size="large"
              className={eventStyles.eventsModalCancelButton}
              onClick={onCancel}
            >
              {t('Common.Cancel')}
            </Button>
          </div>
        </Col>
        <Col flex="auto" className={eventStyles.eventsModalRightPane}>
          <div className={eventStyles.eventsModalEventsHeaderRow}>
            <FontAwesomeIcon
              className={eventStyles.excludeEventIcon}
              flip="horizontal"
              icon={faBan}
            />
            <span className={eventStyles.eventsModalExcludeEventsText}>
              {t(`Scorecard.EventsTo${operation}`)}
            </span>
            <div className={eventStyles.eventsModalPenaltyPointsText}>
              {totalPenaltyPoints} {t('Scorecard.PenaltyPoints')}
            </div>
          </div>
          <div className={eventStyles.eventsModalEventsListContainer}>
            <ScorecardEventsList
              events={events}
              checkedEvents={[]}
              entityType={entityType}
              onEventCheckedChange={null}
              onEventSelected={null}
              smallList={true}
            />
          </div>
        </Col>
      </Row>
    </Modal>
  );
};
