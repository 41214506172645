import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'components/ant';
import * as moment from 'moment';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { SelectSort } from 'containers/home/SelectSort';
import styles from './Snapshots.module.scss';
import { SortInitValue } from './helpers';

export function Header({
  filterCompanies,
  filterFleets,
  filterVehicles,
  filterText,
  selectedMonth,
  selectedYear,
  snapshotsCount = 0,
  onSearch,
  onMonthSelected,
  onFilterCompanies,
  onFilterFleets,
  onFilterVehicles,
  onSortChange,
  isDailyMode = false
}) {
  const { t } = useTranslation();
  const disabledDate = useCallback(current => {
    if (
      current <
        moment()
          .subtract(12, 'month')
          .startOf('month') ||
      current >=
        moment()
          .startOf('month')
          .add(1, 'month')
    ) {
      return true;
    }
    return false;
  }, []);

  const defaultMonth = useMemo(() => moment(selectedYear + '-' + selectedMonth, 'YYYY-MM'), [
    selectedYear,
    selectedMonth
  ]);

  const options = useMemo(() => {
    const dateOption = [{ key: 'date', label: t('Common.Snapshot Date') }];
    return isDailyMode
      ? dateOption
      : [...dateOption, { key: 'vehicleName', label: t('Map.VehicleName') }];
  }, [isDailyMode]);

  return (
    <>
      <AntSearchbar defaultValue={filterText} onFilter={onSearch} />
      <AntMultiselect
        title={
          !filterCompanies.find(company => !company.checked)
            ? t('Common.AllCompanies')
            : t('Common.Companies')
        }
        data={filterCompanies}
        onFilter={onFilterCompanies}
      />
      <AntMultiselect
        title={
          !filterFleets.find(value => !value.checked) ? t('Common.AllFleets') : t('Common.Fleets')
        }
        data={filterFleets}
        onFilter={onFilterFleets}
      />
      {!isDailyMode && (
        <AntMultiselect
          title={
            !filterVehicles.find(value => !value.checked)
              ? t('VehicleMntSchedules.AllVehicles')
              : t('Common.Vehicles')
          }
          data={filterVehicles}
          onFilter={onFilterVehicles}
        />
      )}
      <DatePicker
        className={styles.datePicker}
        format="MMMM YYYY"
        defaultValue={defaultMonth}
        picker="month"
        onChange={onMonthSelected}
        disabledDate={disabledDate}
        allowClear={false}
      />
      <SelectSort onChange={onSortChange} defaultValue={SortInitValue} options={options} />
      <div className={styles.count}>{` ${snapshotsCount} ${t('Home.Snapshots')}`}</div>
    </>
  );
}
