import { Modal, Table, Divider, Button } from 'antd';
import { gpioDiginColumns, gpioDigoutColumns } from './constants';
import { useTranslation } from 'react-i18next';
import { toTableStructure } from './helpers';

export const GPIOTableModal = ({
  visible,
  templateName,
  inBiasMap,
  inOccurrenceMap,
  inTriggerMap,
  outBiasMap,
  outOccurrenceMap,
  outTriggerMap,
  data,
  diginIODefinition,
  vpmData,
  bpmData,
  speedsirenData,
  channelTypes,
  onClose
}) => {
  const { t } = useTranslation();
  const diginData = data.filter(cfg =>
    channelTypes.some(t => t.form === 'digin' && cfg.input.startsWith(t.key))
  );
  const digoutData = data.filter(cfg =>
    channelTypes.some(t => t.form === 'digout' && cfg.input.startsWith(t.key))
  );
  const inGPIOData = toTableStructure(channelTypes, diginData);
  const outGPIOData = toTableStructure(channelTypes, digoutData);
  const getGPIORowKey = record => `${record.input}-${record.channel}`;

  return (
    <Modal
      title={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ViewGPIOTitle', { templateName })}
      open={visible}
      width="800px"
      onCancel={onClose}
      footer={[
        <Button key="ok" type="primary" onClick={onClose}>
          {t('Common.Modal.OK')}
        </Button>
      ]}
      destroyOnClose={true}
    >
      {inGPIOData?.length > 0 &&
        inGPIOData.map(digin => {
          return (
            digin.data?.length > 0 && (
              <>
                <Divider orientation="left" orientationMargin={0} plain>
                  {t(
                    'CompanyConfig.DeviceConfigurations.GPIOTemplates.' + digin.value,
                    digin.value
                  )}
                </Divider>
                <Table
                  columns={gpioDiginColumns(
                    t,
                    inBiasMap,
                    inTriggerMap,
                    inOccurrenceMap,
                    diginIODefinition
                  )}
                  dataSource={digin.data}
                  pagination={false}
                  rowKey={getGPIORowKey}
                ></Table>
              </>
            )
          );
        })}
      {outGPIOData?.length > 0 &&
        outGPIOData.map(digout => {
          return (
            digout.data?.length > 0 && (
              <>
                <Divider orientation="left" orientationMargin={0} plain>
                  {t(
                    'CompanyConfig.DeviceConfigurations.GPIOTemplates.' + digout.value,
                    digout.value
                  )}
                </Divider>
                <Table
                  columns={gpioDigoutColumns(
                    t,
                    outBiasMap,
                    outTriggerMap,
                    outOccurrenceMap,
                    vpmData,
                    bpmData,
                    speedsirenData
                  )}
                  dataSource={digout.data}
                  pagination={false}
                  rowKey={getGPIORowKey}
                ></Table>
              </>
            )
          );
        })}
    </Modal>
  );
};
