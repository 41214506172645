import { useDispatch, useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import request from 'superagent';

import { ToastType } from 'components/notifications/toasts/Toast';

import { openToast } from 'features/toasts/toastsSlice';
import { useCurrentCompanyKey } from 'features/company/companySlice';

import { Comparators } from 'utils/sorting';
import { API_PATH } from 'config';
import i18n from 'i18next';

const initialState = {
  list: [],
  meta: {
    error: null,
    lastFetched: null,
    isFetching: false,
    companyKey: null
  }
};

const vehicleCombinationsSlice = createSlice({
  name: 'vehicleCombinations',
  initialState: initialState,
  reducers: {
    fetchVehicleCombinationsStart: state => {
      state.meta.isFetching = true;
    },
    fetchVehicleCombinationsSucceeded: (state, { payload }) => {
      state.list = payload.list.sort(Comparators.String('name'));
      state.meta.error = null;
      state.meta.lastFetched = new Date().toISOString();
      state.meta.isFetching = false;
      state.meta.companyKey = payload.companyKey;
    },
    fetchVehicleCombinationsFailed: (state, { payload }) => {
      state.list = [];
      state.meta.error = payload.error;
      state.meta.lastFetched = new Date().toISOString();
      state.meta.isFetching = false;
      state.meta.companyKey = payload.companyKey;
    },
    addVehicleCombinationStart: state => {
      state.meta.isFetching = true;
    },
    addVehicleCombinationSucceeded: state => {
      state.meta.isFetching = false;
    },
    addVehicleCombinationFailed: (state, { payload }) => {
      state.meta.isFetching = false;
      state.meta.error = payload.error;
    },
    updateVehicleCombinationStart: state => {
      state.meta.isFetching = true;
    },
    updateVehicleCombinationSucceeded: state => {
      state.meta.isFetching = false;
    },
    updateVehicleCombinationFailed: (state, { payload }) => {
      state.meta.isFetching = false;
      state.meta.error = payload.error;
    },
    deleteVehicleCombinationStart: state => {
      state.meta.isFetching = true;
    },
    deleteVehicleCombinationSucceeded: state => {
      state.meta.isFetching = false;
    },
    deleteVehicleCombinationFailed: (state, { payload }) => {
      state.meta.isFetching = false;
      state.meta.error = payload.error;
    }
  }
});

const {
  fetchVehicleCombinationsStart,
  fetchVehicleCombinationsSucceeded,
  fetchVehicleCombinationsFailed,
  addVehicleCombinationStart,
  addVehicleCombinationSucceeded,
  addVehicleCombinationFailed,
  updateVehicleCombinationStart,
  updateVehicleCombinationSucceeded,
  updateVehicleCombinationFailed,
  deleteVehicleCombinationStart,
  deleteVehicleCombinationSucceeded,
  deleteVehicleCombinationFailed
} = vehicleCombinationsSlice.actions;

export const fetchVehicleCombinations = () => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const companyId = getState().companies.current?.id;
  const companyKey = getState().companies.current.api_key;

  if (!userKey) {
    return;
  }

  dispatch(fetchVehicleCombinationsStart());
  request('GET', `${API_PATH}/massmanagement/vcombinations`)
    .query({
      ...(companyId && { company_id: companyId })
    })
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(res => {
      dispatch(fetchVehicleCombinationsSucceeded({ list: res.body, companyKey }));
    })
    .catch(error => {
      dispatch(fetchVehicleCombinationsFailed({ error: error.toString(), companyKey }));
      console.error('fetchVehicleCombinations:', error);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${error}`
        })
      );
    });
};

export const addVehicleCombination = vehicleCombination => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const companyId = getState().companies.current?.id;

  if (!userKey) {
    return;
  }

  dispatch(addVehicleCombinationStart());
  request('POST', `${API_PATH}/massmanagement/vcombinations`)
    .query({
      ...(companyId && { company_id: companyId })
    })
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .send(vehicleCombination)
    .then(res => {
      dispatch(addVehicleCombinationSucceeded({ list: res.body }));
      dispatch(fetchVehicleCombinations());
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('MassManagement.Message.Added', {
            config: i18n.t('MassManagement.VehicleCombination'),
            name: vehicleCombination.name
          })
        })
      );
    })
    .catch(error => {
      const errorMsg = error.response?.body?.message || error.toString();
      dispatch(addVehicleCombinationFailed({ error: errorMsg }));
      console.error('addVehicleCombination:', errorMsg);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('MassManagement.Message.AddError', {
            config: i18n.t('MassManagement.VehicleCombination'),
            name: vehicleCombination.name,
            error: errorMsg
          })
        })
      );
    });
};

export const updateVehicleCombination = vehicleCombination => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;

  if (!userKey) {
    return;
  }

  dispatch(updateVehicleCombinationStart());
  request('PUT', `${API_PATH}/massmanagement/vcombinations/${vehicleCombination.id}`)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .send(vehicleCombination)
    .then(res => {
      dispatch(updateVehicleCombinationSucceeded({ list: res.body }));
      dispatch(fetchVehicleCombinations());
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('MassManagement.Message.Updated', {
            config: i18n.t('MassManagement.VehicleCombination'),
            name: vehicleCombination.name
          })
        })
      );
    })
    .catch(error => {
      const errorMsg = error.response?.body?.message || error.toString();
      dispatch(updateVehicleCombinationFailed({ error: errorMsg }));
      console.error('updateVehicleCombination:', errorMsg);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('MassManagement.Message.UpdatedError', {
            config: i18n.t('MassManagement.VehicleCombination'),
            name: vehicleCombination.name,
            error: errorMsg
          })
        })
      );
    });
};

export const deleteVehicleCombination = vehicleCombination => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;

  if (!userKey) {
    return;
  }

  dispatch(deleteVehicleCombinationStart());
  request('DELETE', `${API_PATH}/massmanagement/vcombinations/${vehicleCombination.id}`)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(res => {
      dispatch(deleteVehicleCombinationSucceeded({ list: res.body }));
      dispatch(fetchVehicleCombinations());
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('MassManagement.Message.Deleted', {
            config: i18n.t('MassManagement.VehicleCombination'),
            name: vehicleCombination.name
          })
        })
      );
    })
    .catch(error => {
      const errorMsg = error.response?.body?.message || error.toString();
      dispatch(deleteVehicleCombinationFailed({ error: errorMsg }));
      console.error('deleteVehicleCombination:', errorMsg);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('MassManagement.Message.DeleteError', {
            config: i18n.t('MassManagement.VehicleCombination'),
            name: vehicleCombination.name,
            error: errorMsg
          })
        })
      );
    });
};

export const useVehicleCombinations = () => {
  const dispatch = useDispatch();
  const combinations = useSelector(state => state.vehicleCombinations.list);
  const isFetching = useSelector(state => state.vehicleCombinations.meta.isFetching);
  const lastFetched = useSelector(state => state.vehicleCombinations.meta.lastFetched);
  const lastCompanyKey = useSelector(state => state.vehicleCombinations.meta.companyKey);
  const isCompanyDifferent = useCurrentCompanyKey() !== lastCompanyKey;

  if (!isFetching && (!lastFetched || isCompanyDifferent)) {
    dispatch(fetchVehicleCombinations());
  }

  return combinations;
};

export const useIsFetching = () => useSelector(state => state.vehicleCombinations.meta.isFetching);

export default vehicleCombinationsSlice.reducer;
