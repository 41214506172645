/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";

/**
 * The Contact model module.
 * @module model/Contact
 * @version 1.0
 */
class Contact {
  /**
   * Constructs a new <code>Contact</code>.
   * @alias module:model/Contact
   */
  constructor() {
    Contact.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Contact</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Contact} obj Optional instance to populate.
   * @return {module:model/Contact} The populated <code>Contact</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Contact();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("version")) {
        obj["version"] = ApiClient.convertToType(data["version"], "Number");
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("companyId")) {
        obj["companyId"] = ApiClient.convertToType(data["companyId"], "Number");
      }
      if (data.hasOwnProperty("entityId")) {
        obj["entityId"] = ApiClient.convertToType(data["entityId"], "Number");
      }
      if (data.hasOwnProperty("entityType")) {
        obj["entityType"] = ApiClient.convertToType(
          data["entityType"],
          "String"
        );
      }
      if (data.hasOwnProperty("email")) {
        obj["email"] = ApiClient.convertToType(data["email"], "String");
      }
      if (data.hasOwnProperty("emailNotification")) {
        obj["emailNotification"] = ApiClient.convertToType(
          data["emailNotification"],
          ["String"]
        );
      }
      if (data.hasOwnProperty("mobile")) {
        obj["mobile"] = ApiClient.convertToType(data["mobile"], "String");
      }
      if (data.hasOwnProperty("mobileNotification")) {
        obj["mobileNotification"] = ApiClient.convertToType(
          data["mobileNotification"],
          ["String"]
        );
      }
      if (data.hasOwnProperty("internal")) {
        obj["internal"] = ApiClient.convertToType(data["internal"], "String");
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("timeZone")) {
        obj["timeZone"] = ApiClient.convertToType(data["timeZone"], "String");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Contact.prototype["id"] = undefined;

/**
 * @member {Number} version
 */
Contact.prototype["version"] = undefined;

/**
 * @member {String} name
 */
Contact.prototype["name"] = undefined;

/**
 * @member {Number} companyId
 */
Contact.prototype["companyId"] = undefined;

/**
 * @member {Number} entityId
 */
Contact.prototype["entityId"] = undefined;

/**
 * @member {String} entityType
 */
Contact.prototype["entityType"] = undefined;

/**
 * @member {String} email
 */
Contact.prototype["email"] = undefined;

/**
 * @member {Array.<String>} emailNotification
 */
Contact.prototype["emailNotification"] = undefined;

/**
 * @member {String} mobile
 */
Contact.prototype["mobile"] = undefined;

/**
 * @member {Array.<String>} mobileNotification
 */
Contact.prototype["mobileNotification"] = undefined;

/**
 * @member {String} internal
 */
Contact.prototype["internal"] = undefined;

/**
 * @member {module:model/Contact.StatusEnum} status
 */
Contact.prototype["status"] = undefined;

/**
 * @member {String} timeZone
 */
Contact.prototype["timeZone"] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Contact["StatusEnum"] = {
  /**
   * value: "ENABLED"
   * @const
   */
  ENABLED: "ENABLED",

  /**
   * value: "DISABLED"
   * @const
   */
  DISABLED: "DISABLED",

  /**
   * value: "READY_FOR_DOWNLOAD"
   * @const
   */
  READY_FOR_DOWNLOAD: "READY_FOR_DOWNLOAD",

  /**
   * value: "PENDING"
   * @const
   */
  PENDING: "PENDING",

  /**
   * value: "AVAILABLE"
   * @const
   */
  AVAILABLE: "AVAILABLE",

  /**
   * value: "UNAVAILABLE"
   * @const
   */
  UNAVAILABLE: "UNAVAILABLE",

  /**
   * value: "FAILED"
   * @const
   */
  FAILED: "FAILED"
};

export default Contact;
