import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './Scheduler.module.scss';

import { getHourInterval } from './helpers';
import { getDaysOfWeek, hoursOfDay } from './constants';

export const Scheduler = ({ initialSchedule = {}, onChange }) => {
  const daysOfWeek = getDaysOfWeek();

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [cells, setCells] = useState(initialSchedule);

  const daysWrapper = cn(styles.noBorder, styles.cellsWrapper);

  useEffect(() => {
    onChange(cells);
  }, [cells]);

  const onMouseDown = (day, hour) => {
    setIsMouseDown(true);

    const newHourIntervals = new Map();
    if (cells[day]) {
      cells[day].forEach((hourInterval, hour) => newHourIntervals.set(hour, hourInterval));
    }

    if (cells[day]?.has(hour)) {
      newHourIntervals.delete(hour);
    } else {
      newHourIntervals.set(hour, getHourInterval(hour));
    }

    setCells({
      ...cells,
      [day]: newHourIntervals
    });
  };

  const endSelection = () => {
    setIsMouseDown(false);
  };

  const onMouseEnter = (day, hour) => {
    if (isMouseDown) {
      const newHourIntervals = new Map();
      if (cells[day]) {
        cells[day].forEach((hourInterval, hour) => newHourIntervals.set(hour, hourInterval));
      }
      if (cells[day]?.has(hour)) {
        newHourIntervals.delete(hour);
      } else {
        newHourIntervals.set(hour, getHourInterval(hour));
      }
      setCells({
        ...cells,
        [day]: newHourIntervals
      });
    }
  };

  const onWeekdayClick = weekday => {
    const newHourIntervals = new Map();
    let allWeekdaysSelected = true;

    hoursOfDay.forEach(hour => {
      if (!cells[weekday]?.has(hour)) {
        allWeekdaysSelected = false;
      }
    });

    if (!allWeekdaysSelected) {
      hoursOfDay.forEach(hour => {
        newHourIntervals.set(hour, getHourInterval(hour));
      });
    }

    setCells({
      ...cells,
      [weekday]: newHourIntervals
    });
  };

  const onHourClick = hour => {
    let shallowCopyCells = { ...cells };
    let allHoursSelected = true;

    daysOfWeek.forEach((_, day) => {
      for (let quarter = 0; quarter < 4; quarter += 1) {
        if (!cells[day]?.has(hour + quarter)) {
          allHoursSelected = false;
        }
      }

      daysOfWeek.forEach((_, day) => {
        const newHourIntervals = new Map();
        if (cells[day]) {
          cells[day].forEach((hourInterval, hour) => newHourIntervals.set(hour, hourInterval));
        }

        for (let quarter = 0; quarter < 4; quarter += 1) {
          if (allHoursSelected) {
            if (newHourIntervals?.has(hour + quarter)) {
              newHourIntervals.delete(hour + quarter);
            }
          } else if (!newHourIntervals?.has(hour + quarter)) {
            newHourIntervals.set(hour + quarter, getHourInterval(hour + quarter));
          }
        }

        shallowCopyCells = {
          ...shallowCopyCells,
          [day]: newHourIntervals
        };
      });
    });

    setCells({
      ...shallowCopyCells
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.hourWrapper}>
        <div className={styles.hour}></div>
        <div className={daysWrapper}>
          {daysOfWeek.map((day, dayIndex) => (
            <div
              key={`day-${dayIndex}`}
              className={styles.day}
              onClick={() => onWeekdayClick(dayIndex)}
            >
              {day}
            </div>
          ))}
        </div>
      </div>
      <div onMouseLeave={endSelection} className={styles.schedulerWrapper}>
        {hoursOfDay.map(hour => {
          return (
            <div key={`hour-${hour}`} className={styles.hourWrapper}>
              {hour % 4 === 0 ? (
                <div className={styles.hour} onClick={() => onHourClick(hour)}>
                  {getHourInterval(hour).startTime}
                </div>
              ) : (
                ''
              )}

              <div className={styles.cellsWrapper}>
                {daysOfWeek.map((day, cellIndex) => {
                  const cellClassName = cn(styles.cell, {
                    [styles.topBorder]: hour % 4 === 0 || hour === 0,
                    [styles.bottomBorder]: hour === 95,
                    [styles.leftBorder]: cellIndex === 0,
                    [styles.rightBorder]: cellIndex === 6,
                    [styles.colored]: cells[cellIndex] && cells[cellIndex]?.has(hour)
                  });
                  return (
                    <div
                      key={`cell-${cellIndex}-${hour}`}
                      className={cellClassName}
                      onMouseDown={() => onMouseDown(cellIndex, hour)}
                      onMouseUp={endSelection}
                      onMouseEnter={() => onMouseEnter(cellIndex, hour)}
                    ></div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
