import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';

import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18next from 'i18next';

export const DEFAULT_VIEW_NAME = 'Default';

const defaultInitialState = {
  config: null,
  lastFetched: null,
  isFetching: false,
  isUpdating: false,
  error: null,
  isEmpty: false,
  companyKey: null
};

const userGridSettingsSlice = createSlice({
  name: 'userGridSettings',
  initialState: {
    allSettings: {}
  },
  reducers: {
    fetchUserGridSettingsStart: (state, { payload }) => {
      if (!state.allSettings[payload.key])
        state.allSettings[payload.key] = { ...defaultInitialState };

      state.allSettings[payload.key].isFetching = true;
    },
    fetchUserGridSettingsSucceeded(state, { payload }) {
      state.allSettings[payload.key].config = payload.config;
      state.allSettings[payload.key].isFetching = false;
      state.allSettings[payload.key].lastFetched = 'now';
      state.allSettings[payload.key].error = null;
      state.allSettings[payload.key].isEmpty = payload === null;
    },
    fetchUserGridSettingsFailed(state, { payload }) {
      state.allSettings[payload.key].config = null;
      state.allSettings[payload.key].isFetching = false;
      state.allSettings[payload.key].lastFetched = 'now';
      state.allSettings[payload.key].error = payload.err;
      state.allSettings[payload.key].isEmpty = true;
    },
    updateUserGridSettingsStart: (state, { payload }) => {
      state.allSettings[payload.key].config = payload.config;
      state.allSettings[payload.key].isUpdating = true;
    },
    updateUserGridSettingsSucceeded: (state, { payload }) => {
      state.allSettings[payload.key].isUpdating = false;
    },
    updateUserGridSettingsFailed: (state, { payload }) => {
      state.allSettings[payload.key].isUpdating = false;
      state.allSettings[payload.key].error = payload.error;
    }
  }
});

const {
  fetchUserGridSettingsStart,
  fetchUserGridSettingsSucceeded,
  fetchUserGridSettingsFailed,
  updateUserGridSettingsStart,
  updateUserGridSettingsSucceeded,
  updateUserGridSettingsFailed
} = userGridSettingsSlice.actions;

export const fetchUserGridSettings = key => async (dispatch, getState) => {
  const {
    user: { current: currentUser }
  } = getState();
  const userKey = currentUser?.auth?.key;
  const id = currentUser?.id;
  const url = `${API_PATH}/users/${id}/config/userGridSettings.${key}`;

  if (!userKey) {
    return;
  }

  dispatch(fetchUserGridSettingsStart({ key: key }));

  request('GET', url)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(res => {
      dispatch(fetchUserGridSettingsSucceeded({ key: key, config: res.body }));
    })
    .catch(error => {
      dispatch(fetchUserGridSettingsFailed({ key: key, error: error.toString() }));
      console.error('fetchUserGridSettings', error);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${error}`
        })
      );
    });
};

export const updateUserGridSettings = (
  config,
  key,
  onSuccess,
  showToastOnSuccess = false
) => async (dispatch, getState) => {
  const {
    user: { current: currentUser }
  } = getState();
  const userKey = currentUser?.auth?.key;
  const id = currentUser?.id;
  const url = `${API_PATH}/users/${id}/config/userGridSettings.${key}`;

  if (!userKey) {
    return;
  }

  dispatch(updateUserGridSettingsStart({ key: key, config: config }));

  request('PUT', url)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .send(config)
    .then(res => {
      dispatch(updateUserGridSettingsSucceeded({ key: key, config: res.body }));
      dispatch(fetchUserGridSettings(key));
      if (showToastOnSuccess) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18next.t('Users.Notifications.ViewsUpdateSuccess')
          })
        );
      }
      onSuccess();
    })
    .catch(error => {
      const errorMsg = error.response?.body?.message || error.toString();
      dispatch(updateUserGridSettingsFailed({ key: key, error: errorMsg }));
      console.error('updateUserGridSettings:', errorMsg);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('Users.Notifications.ViewsUpdateError', { error: errorMsg })
        })
      );
    });
};

//export const useIsUserGridSettingsFetching = (key) => useSelector(state => state.userGridSettings.allSettings[key].isFetching);

export const useIsUserGridSettingsUpdating = key => {
  const settings = useSelector(state => state.userGridSettings.allSettings[key]);
  return settings.isUpdating;
};

export const useUserGridSettings = key => {
  const dispatch = useDispatch();
  const settings = useSelector(state => state.userGridSettings.allSettings[key]);

  if (!settings || (settings && !settings.isFetching && !settings.lastFetched)) {
    dispatch(fetchUserGridSettings(key));
  }

  return settings;
};

export default userGridSettingsSlice.reducer;
