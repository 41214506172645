import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useGeoSuggestableGeofences } from 'features/geofences/geofencesSlice';
import { getFormattedAddressFromLatLng } from 'utils/geo';
import { boldKeywordsInText } from 'utils/strings';
import { toLower } from 'lodash';

export const useRouteTo = (routeToData, routeToLocation) => {
  const geoSuggestRef = useRef(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationName, setLocationName] = useState('');

  const [geoSearchText, setGeoSearchText] = useState('');
  const searchableGeofences = useGeoSuggestableGeofences();

  const geofenceFixtures = useMemo(
    () =>
      (searchableGeofences || []).filter(geofence =>
        toLower(geofence.label).includes(toLower(geoSearchText))
      ),
    [geoSearchText, searchableGeofences]
  );

  const skipGeoSuggest = useCallback(
    suggest => {
      if (!geoSearchText || !geoSearchText.trim()) {
        return true;
      } else {
        return suggest.isGeofence && !toLower(suggest.label).includes(toLower(geoSearchText));
      }
    },
    [geoSearchText]
  );

  const renderGeoSuggestItem = useCallback((suggest, inputText) => {
    try {
      if (suggest.isFixture) {
        const textSegements = boldKeywordsInText(inputText, suggest.label);
        return (
          <span>
            {textSegements.map((textSegement, index) =>
              textSegement.bold ? (
                <strong key={index}>{textSegement.word}</strong>
              ) : (
                <span key={index}>{textSegement.word}</span>
              )
            )}
          </span>
        );
      } else {
        const boldStr = suggest.description.slice(
          suggest.matchedSubstrings.offset,
          suggest.matchedSubstrings.offset + suggest.matchedSubstrings.length
        );
        return suggest.description.split(boldStr).map((segment, index, segments) => (
          <span key={`addressSegment-${index}`}>
            <span key={index}>{segment}</span>
            {index !== segments.length - 1 && <strong key={`bold-${index}`}>{boldStr}</strong>}
          </span>
        ));
      }
    } catch (error) {
      return <span>{suggest.description || suggest.label}</span>;
    }
  }, []);

  const clearGeoSearchText = useCallback(() => setGeoSearchText(''), []);

  const deviceGeoLocation = useMemo(() => {
    return routeToData?.deviceStats?.gps &&
      routeToData?.deviceStats?.gps?.Lat &&
      routeToData?.deviceStats?.gps?.Lng
      ? {
          lat: routeToData.deviceStats.gps.Lat,
          lng: routeToData.deviceStats.gps.Lng
        }
      : null;
  }, [routeToData]);

  const setRouteToLocation = useCallback(
    async (latLng, updateGoogleAddressText = false, locationName) => {
      if (latLng) {
        if (updateGoogleAddressText) {
          const label = await getFormattedAddressFromLatLng(latLng);
          setSelectedLocation(latLng);
          if (geoSuggestRef.current) {
            geoSuggestRef.current.update(label);
          }
          setLocationName(label);
        } else {
          setSelectedLocation(latLng);
          setLocationName(locationName);
        }
      } else {
        setSelectedLocation(null);
        setLocationName('');
      }
    },
    [geoSuggestRef]
  );

  useEffect(() => {
    if (routeToLocation) {
      setRouteToLocation(routeToLocation, true);
    } else if (deviceGeoLocation) {
      setSelectedLocation(deviceGeoLocation);
    }
  }, [deviceGeoLocation, routeToLocation]);

  return {
    selectedLocation,
    setSelectedLocation: setRouteToLocation,
    geoSuggestRef,
    locationName,
    setLocationName,
    geoSuggestWithGeofences: {
      fixtures: geofenceFixtures,
      skipSuggest: skipGeoSuggest,
      renderSuggestItem: renderGeoSuggestItem,
      onChange: setGeoSearchText
    },
    onModalClose: clearGeoSearchText
  };
};
