/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Company from "./Company";
import Device from "./Device";
import Job from "./Job";
import Location from "./Location";
import RunsheetAttribute from "./RunsheetAttribute";
import Trip from "./Trip";
import User from "./User";
import Vehicle from "./Vehicle";

/**
 * The Runsheet model module.
 * @module model/Runsheet
 * @version 1.0
 */
class Runsheet {
  /**
   * Constructs a new <code>Runsheet</code>.
   * @alias module:model/Runsheet
   */
  constructor() {
    Runsheet.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Runsheet</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Runsheet} obj Optional instance to populate.
   * @return {module:model/Runsheet} The populated <code>Runsheet</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Runsheet();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("companyId")) {
        obj["companyId"] = ApiClient.convertToType(data["companyId"], "Number");
      }
      if (data.hasOwnProperty("carrier")) {
        obj["carrier"] = Company.constructFromObject(data["carrier"]);
      }
      if (data.hasOwnProperty("location")) {
        obj["location"] = Location.constructFromObject(data["location"]);
      }
      if (data.hasOwnProperty("user")) {
        obj["user"] = User.constructFromObject(data["user"]);
      }
      if (data.hasOwnProperty("device")) {
        obj["device"] = Device.constructFromObject(data["device"]);
      }
      if (data.hasOwnProperty("vehicle")) {
        obj["vehicle"] = Vehicle.constructFromObject(data["vehicle"]);
      }
      if (data.hasOwnProperty("plannedTrip")) {
        obj["plannedTrip"] = Trip.constructFromObject(data["plannedTrip"]);
      }
      if (data.hasOwnProperty("actualTrip")) {
        obj["actualTrip"] = Trip.constructFromObject(data["actualTrip"]);
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("category")) {
        obj["category"] = ApiClient.convertToType(data["category"], "String");
      }
      if (data.hasOwnProperty("notes")) {
        obj["notes"] = ApiClient.convertToType(data["notes"], "String");
      }
      if (data.hasOwnProperty("externalId")) {
        obj["externalId"] = ApiClient.convertToType(
          data["externalId"],
          "String"
        );
      }
      if (data.hasOwnProperty("externalReference")) {
        obj["externalReference"] = ApiClient.convertToType(
          data["externalReference"],
          "String"
        );
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("scheduledAt")) {
        obj["scheduledAt"] = ApiClient.convertToType(
          data["scheduledAt"],
          "String"
        );
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = ApiClient.convertToType(data["createdAt"], "String");
      }
      if (data.hasOwnProperty("updatedAt")) {
        obj["updatedAt"] = ApiClient.convertToType(data["updatedAt"], "String");
      }
      if (data.hasOwnProperty("jobs")) {
        obj["jobs"] = ApiClient.convertToType(data["jobs"], [Job]);
      }
      if (data.hasOwnProperty("attributes")) {
        obj["attributes"] = ApiClient.convertToType(data["attributes"], [
          RunsheetAttribute
        ]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Runsheet.prototype["id"] = undefined;

/**
 * @member {Number} companyId
 */
Runsheet.prototype["companyId"] = undefined;

/**
 * @member {module:model/Company} carrier
 */
Runsheet.prototype["carrier"] = undefined;

/**
 * @member {module:model/Location} location
 */
Runsheet.prototype["location"] = undefined;

/**
 * @member {module:model/User} user
 */
Runsheet.prototype["user"] = undefined;

/**
 * @member {module:model/Device} device
 */
Runsheet.prototype["device"] = undefined;

/**
 * @member {module:model/Vehicle} vehicle
 */
Runsheet.prototype["vehicle"] = undefined;

/**
 * @member {module:model/Trip} plannedTrip
 */
Runsheet.prototype["plannedTrip"] = undefined;

/**
 * @member {module:model/Trip} actualTrip
 */
Runsheet.prototype["actualTrip"] = undefined;

/**
 * @member {String} name
 */
Runsheet.prototype["name"] = undefined;

/**
 * @member {module:model/Runsheet.CategoryEnum} category
 */
Runsheet.prototype["category"] = undefined;

/**
 * @member {String} notes
 */
Runsheet.prototype["notes"] = undefined;

/**
 * @member {String} externalId
 */
Runsheet.prototype["externalId"] = undefined;

/**
 * @member {String} externalReference
 */
Runsheet.prototype["externalReference"] = undefined;

/**
 * @member {module:model/Runsheet.StatusEnum} status
 */
Runsheet.prototype["status"] = undefined;

/**
 * @member {String} scheduledAt
 */
Runsheet.prototype["scheduledAt"] = undefined;

/**
 * @member {String} createdAt
 */
Runsheet.prototype["createdAt"] = undefined;

/**
 * @member {String} updatedAt
 */
Runsheet.prototype["updatedAt"] = undefined;

/**
 * @member {Array.<module:model/Job>} jobs
 */
Runsheet.prototype["jobs"] = undefined;

/**
 * @member {Array.<module:model/RunsheetAttribute>} attributes
 */
Runsheet.prototype["attributes"] = undefined;

/**
 * Allowed values for the <code>category</code> property.
 * @enum {String}
 * @readonly
 */
Runsheet["CategoryEnum"] = {
  /**
   * value: "DEFAULT"
   * @const
   */
  DEFAULT: "DEFAULT",

  /**
   * value: "HEADLESS"
   * @const
   */
  HEADLESS: "HEADLESS"
};

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Runsheet["StatusEnum"] = {
  /**
   * value: "CREATED"
   * @const
   */
  CREATED: "CREATED",

  /**
   * value: "OPENED"
   * @const
   */
  OPENED: "OPENED",

  /**
   * value: "LOAD_VERIFIED"
   * @const
   */
  LOAD_VERIFIED: "LOAD_VERIFIED",

  /**
   * value: "COMPLETED"
   * @const
   */
  COMPLETED: "COMPLETED",

  /**
   * value: "CANCELLED"
   * @const
   */
  CANCELLED: "CANCELLED",

  /**
   * value: "HELD"
   * @const
   */
  HELD: "HELD"
};

export default Runsheet;
