import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, Spin, Tabs, Divider } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import dayjs from 'dayjs';
import { useLocation } from 'react-router';
import Can from 'features/permissions/Can';
import { NoAccessBody } from 'components/pages/NoAccess';
import { showHideAdapter } from '../constants';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

import {
  useAddInboundMutation,
  useAddOutboundMutation,
  useDeleteOutboundMutation,
  useDisableInboundMutation,
  useGetAdapterByIdQuery,
  useValidateAdapterMutation
} from 'services/nextgen';
import { setBackButton, setPageTitle, addBackButtonLink } from 'features/page/pageSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { GlobalRoles, useCanOneOfRoles } from 'features/permissions';

import styles from './Configuration.module.scss';
import { useCurrentCompanyId } from '../../../features/company/companySlice';
import { Inbound } from './components/Inbound';
import { Outbound } from './components/Outbound';
import { ToastType } from 'components/notifications/toasts/Toast';
import { Events } from '../Events/Events';

import { parseErrorMessage } from 'utils/strings';
import { getIDFromPathname } from 'utils/methods';
import { buildFormItems, buildValidateResult } from './utils/utils';
import { buildIcon } from './../constants';

import 'github-markdown-css/github-markdown-light.css';

export const Configuration = ({ match, history }) => {
  const companyId = useCurrentCompanyId();
  const dispatch = useDispatch();
  const id = getIDFromPathname(window.location.pathname);
  const { data: adapter, isLoading: adapterLoading } = useGetAdapterByIdQuery({ id, companyId });
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const activeTab = history?.location?.state?.activeTab;
  const canSiteAdminRole = useCanOneOfRoles([GlobalRoles.SiteAdmin]);

  const [editId, setEditId] = useState(false);
  const [showInboundModal, setShowInboundModal] = useState(false);
  const [showOutboundModal, setShowOutboundModal] = useState(false);
  const [validateResult, setValidateResult] = useState(null);
  const markdownDocumentation = adapter?.documentation?.content;
  const [formInbound] = Form.useForm();
  const [formOutbound] = Form.useForm();
  const [addInbound, { isLoading: addInboundLoading }] = useAddInboundMutation();
  const [addOutbound, { isLoading: addOutboundLoading }] = useAddOutboundMutation();
  const [deleteOutbound] = useDeleteOutboundMutation();
  const [disableInbound] = useDisableInboundMutation();
  const [validateAdapter, { isLoading: validateAdapterLoading }] = useValidateAdapterMutation();
  const [initialValues, setInitialValues] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [valid, setValid] = useState(false);

  const handleValuesChange = (changedValues, allValues) => {
    const formIsDirty = Object.keys(allValues).some(
      key =>
        allValues[key] !== initialValues[key] &&
        !(
          (allValues[key] === '' || allValues[key] === null || allValues[key] === undefined) &&
          (initialValues[key] === undefined ||
            initialValues[key] === null ||
            initialValues[key] === '')
        )
    );
    setIsDirty(formIsDirty);
    setTimeout(() => {
      if (showOutboundModal) {
        formOutbound
          .validateFields({ validateOnly: true })
          .then(() => setValid(true))
          .catch(() => setValid(false));
      } else if (showInboundModal) {
        formInbound
          .validateFields({ validateOnly: true })
          .then(() => setValid(true))
          .catch(() => setValid(false));
      }
    }, 10);
  };

  useEffect(() => {
    dispatch(setPageTitle(`${t('Integrations.Configure')} ${adapter?.name || ''}`));
    dispatch(setBackButton(true));
  }, [dispatch, adapter]);

  useEffect(() => {
    dispatch(
      addBackButtonLink({ url: pathname, backLink: `/integrations/${activeTab || 'marketplace'}` })
    );
  }, [dispatch, pathname]);

  const disabledDate = current => {
    // Do not allow past dates selection
    return current && current < dayjs().endOf('day');
  };

  const buildInitialFormValues = currentOutbound => {
    const fields = adapter?.fields?.map(field => field.id);
    const obj = {};
    fields.forEach(field => {
      Object.assign(obj, { [field]: currentOutbound[field] });
    });
    return obj;
  };

  const handleCloseInboundModal = () => {
    isDirty
      ? confirmationModal(
          t('Common.Modal.SureTitle'),
          t('Common.Modal.SureQuestionChangesLost'),
          t('Common.Modal.CancelChanges'),
          t('Common.Modal.Stay'),
          showCloseInboundModal
        )
      : showCloseInboundModal();
  };

  const showCloseInboundModal = () => {
    setShowInboundModal(!showInboundModal);
    //on close
    if (showInboundModal) {
      formInbound.resetFields();
    } else {
      setIsDirty(false);
      setInitialValues(formInbound.getFieldsValue());
    }
  };

  const handleCloseOutboundModal = () => {
    isDirty
      ? confirmationModal(
          t('Common.Modal.SureTitle'),
          t('Common.Modal.SureQuestionChangesLost'),
          t('Common.Modal.CancelChanges'),
          t('Common.Modal.Stay'),
          showCloseOutboundModal
        )
      : showCloseOutboundModal();
  };

  const showCloseOutboundModal = () => {
    setShowOutboundModal(!showOutboundModal);

    //on close
    if (showOutboundModal) {
      formOutbound.resetFields();
      setEditId(false);
      setValidateResult(null);
    } else {
      setIsDirty(false);
      setInitialValues(formOutbound.getFieldsValue());
    }
  };

  const handleInboundOk = () => {
    formInbound.submit();
  };

  const handleOutboundOk = () => {
    formOutbound.submit();
  };

  const handleOutboundValidate = () => {
    const values = formOutbound.getFieldsValue();
    validateAdapter({
      type: adapter?.type,
      body: { ...values }
    })
      .then(result => {
        if (result.error) {
          return;
        }
        setValidateResult(result.data);
      })
      .catch(error => {
        console.error(`Validate error: ${parseErrorMessage(error?.data)}`);
      });
  };

  const handleOutboundEdit = currentOutbound => {
    setEditId(currentOutbound.id);
    formOutbound.setFieldsValue(buildInitialFormValues(currentOutbound));
    setShowOutboundModal(true);
    setIsDirty(false);
    setInitialValues(formOutbound.getFieldsValue());
  };

  const handleInboundSubmit = () => {
    const values = formInbound.getFieldsValue();

    addInbound({
      type: adapter?.type,
      companyId,
      body: {
        label: values.name,
        expiresAt: values.expirationDate?.toISOString()?.split('.')[0]
      }
    })
      .then(result => {
        if (result.error) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: `${t('Integrations.Toasts.InboundAddError')} ${parseErrorMessage(
                result?.error?.data
              )}`
            })
          );
          return;
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Integrations.Toasts.InboundAddSuccess')
          })
        );
        setShowInboundModal(false);
        formInbound.resetFields();
      })
      .catch(error => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(error?.data)
          })
        );
      });
  };

  const handleDisableInbound = (adapterId, keyId) => {
    disableInbound({
      adapterId,
      companyId,
      keyId
    })
      .then(() => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Integrations.Toasts.InboundDisableSuccess')
          })
        );
      })
      .catch(error =>
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(error?.data)
          })
        )
      );
  };

  const handleDeleteOutbound = (type, id) => {
    deleteOutbound({
      type,
      companyId,
      id
    })
      .then(() => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Integrations.Toasts.OutboundDeleteSuccess')
          })
        );
      })
      .catch(error =>
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(error?.data)
          })
        )
      );
  };

  const handleOutboundSubmit = () => {
    const values = formOutbound.getFieldsValue();
    addOutbound({
      type: adapter?.type,
      companyId,
      body: { ...values },
      editId
    })
      .then(result => {
        if (result.error) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: `${t('Integrations.Toasts.OutboundAddError')} ${parseErrorMessage(
                result?.error?.data
              )}`
            })
          );
          return;
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Integrations.Toasts.OutboundAddSuccess')
          })
        );
        setShowOutboundModal(false);
        setValidateResult(null);
        setEditId(false);
        formOutbound.resetFields();
      })
      .catch(error => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: parseErrorMessage(error?.data)
          })
        );
      });
  };

  const renderDocumentation = () => {
    return (
      <div className={styles.documentationWrapper}>
        <div className="markdown-body">
          <div className={styles.documentationHeader}>
            <div className={styles.logo}>
              {adapter?.icon && <img alt="cardIcon" src={buildIcon(adapter?.icon)} />}
            </div>
            <div className={styles.description}>{adapter?.description}</div>
          </div>
          <Divider />
          <ReactMarkdown children={markdownDocumentation} remarkPlugins={[remarkGfm]} />
        </div>
      </div>
    );
  };

  const buildTabsItems = () => {
    return [
      {
        label: t('Integrations.DocumentationTab'),
        key: 'documentation',
        children: renderDocumentation()
      },
      adapter?.supportsInbound &&
        canSiteAdminRole && {
          label: t('Integrations.InboundTab'),
          key: 'inbound',
          children: (
            <Inbound
              adapter={adapter}
              handleDisableInbound={handleDisableInbound}
              showCloseInboundModal={showCloseInboundModal}
            />
          )
        },
      adapter?.supportsOutbound &&
        canSiteAdminRole && {
          label: t('Integrations.OutboundTab'),
          key: 'outbound',
          children: (
            <Outbound
              adapter={adapter}
              showCloseOutboundModal={showCloseOutboundModal}
              handleOutboundEdit={handleOutboundEdit}
              handleDeleteOutbound={handleDeleteOutbound}
            />
          )
        },
      adapter?.type &&
        adapter?.supportsOutbound &&
        canSiteAdminRole && {
          label: t('Integrations.EventsTab.TabName'),
          key: 'events',
          children: <Events adapterType={adapter?.type} />
        }
    ];
  };

  if (adapterLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Can otherConditions={[() => showHideAdapter(adapter)]} onFail={<NoAccessBody />}>
      <div className={styles.wrapper}>
        <div className={styles.tabs}>
          <Tabs
            defaultActiveKey={match?.params?.tab}
            items={buildTabsItems()}
            onChange={key => {
              history.push(`/integrations/configuration/${key}/${id}`, { activeTab });
            }}
          />
        </div>
        <Modal
          title={t('Integrations.AddInboundData')}
          open={showInboundModal}
          onCancel={handleCloseInboundModal}
          centered
          maskClosable={false}
          footer={
            <>
              <Button
                type="primary"
                onClick={handleInboundOk}
                loading={addInboundLoading}
                disabled={!isDirty || !valid}
              >
                {t('Common.SaveButton')}
              </Button>
              <Button onClick={handleCloseInboundModal}>{t('Common.Close')}</Button>
            </>
          }
        >
          <div>
            <Form
              name="formInbound"
              layout="vertical"
              onFinish={handleInboundSubmit}
              onValuesChange={handleValuesChange}
              form={formInbound}
              colon={false}
            >
              <Form.Item
                name="name"
                label={t('Integrations.InboundName')}
                rules={[
                  {
                    required: true,
                    message: t('Integrations.InboundRequired')
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="expirationDate" label={t('Integrations.InboundExpirationDate')}>
                <DatePicker className={styles.datePicker} disabledDate={disabledDate} />
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          title={`${editId ? t('Common.Edit') : t('Common.Add')} ${t('Integrations.OutboundData')}`}
          open={showOutboundModal}
          onCancel={handleCloseOutboundModal}
          centered
          width={validateResult ? '900px' : '520px'}
          maskClosable={false}
          colon={false}
          footer={
            <>
              <Button
                type="primary"
                onClick={handleOutboundOk}
                loading={addOutboundLoading}
                disabled={!isDirty}
              >
                {t('Common.SaveButton')}
              </Button>
              <Button onClick={handleCloseOutboundModal}>{t('Common.Close')}</Button>
            </>
          }
        >
          <Form
            name="formOutbound"
            layout="vertical"
            onFinish={handleOutboundSubmit}
            onValuesChange={handleValuesChange}
            form={formOutbound}
            initialValues={
              !editId &&
              adapter?.fields.some(field => field.id === 'frequency') &&
              buildInitialFormValues({ frequency: 'daily' })
            }
          >
            <div className={styles.wrap1}>
              <div className={styles.left}>
                {buildFormItems(adapter)}
                <Button
                  className={styles.validateButton}
                  onClick={handleOutboundValidate}
                  loading={validateAdapterLoading}
                >
                  {t('Integrations.ValidateButton')}
                </Button>
              </div>
              {validateResult && (
                <div className={styles.right}>{buildValidateResult(validateResult)}</div>
              )}
            </div>
          </Form>
        </Modal>
      </div>
    </Can>
  );
};
