import React from 'react';

import { TabHeader } from './TabHeader';

import styles from './Tracking.module.scss';

export const TrackingPage = ({ children, toolbar, subpage, isFullScreen = false, deviceId }) => {
  return (
    <div className={styles.trackingPage + ' ' + (isFullScreen && styles.fullScreen)}>
      <TabHeader isFullScreen={isFullScreen} toolbar={toolbar} deviceId={deviceId} />

      <div className={styles.subPage}>{children || subpage}</div>
    </div>
  );
};
