import { useMemo } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import request from 'superagent';
import { useCurrentCompanyKey, useCompanies } from 'features/company/companySlice';
import { api } from 'utils/api';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import i18n from 'i18next';
import { canHistoryGoBack } from 'utils/methods';
import { parseErrorMessage, parseUserErrorMessage } from 'utils/strings';
import { Mixpanel, MPTrackingEvents } from 'features/mixpanel';

export const VEHICLE_TYPES = '/vehicletypes';

const vehicleTypes = {
  list: [],
  deletedList: [],
  meta: {
    lastFetched: {
      list: null,
      deletedList: null
    },
    isFetching: {
      list: null,
      deletedList: null
    },
    error: {
      list: null,
      deletedList: null
    },
    isListEmpty: {
      list: false,
      deletedList: false
    },
    companyKey: {
      list: null,
      deletedList: null
    }
  }
};

function startLoading(state, { payload }) {
  state.meta.isFetching[payload] = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching[action.payload.type] = false;
  state.meta.lastFetched[action.payload.type] = 'now';
  state.meta.error[action.payload.type] = action.payload.err;
  state.meta.isListEmpty[action.payload.type] = true;
  state[action.payload.type] = [];
  state.meta.companyKey[action.payload.type] = action.payload.companyKey;
}

const vehicleTypesSlice = createSlice({
  name: 'vehicleTypes',
  initialState: vehicleTypes,
  reducers: {
    fetchVehicleTypesStart: startLoading,
    fetchVehicleTypesSuccess(state, { payload }) {
      state.list = payload.list.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      state.meta.isFetching.list = false;
      state.meta.lastFetched.list = 'now';
      state.meta.error.list = null;
      state.meta.isListEmpty.list = payload.list.length === 0;
      state.meta.companyKey.list = payload.companyKey;
    },
    fetchDeletedVehicleTypesSuccess(state, { payload }) {
      state.deletedList = payload.deletedList.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
      state.meta.isFetching.deletedList = false;
      state.meta.lastFetched.deletedList = 'now';
      state.meta.error.deletedList = null;
      state.meta.isListEmpty.deletedList = payload.deletedList.length === 0;
      state.meta.companyKey.deletedList = payload.companyKey;
    },
    fetchVehicleTypesFailure: loadingFailed
  }
});

const {
  fetchVehicleTypesStart,
  fetchVehicleTypesSuccess,
  fetchDeletedVehicleTypesSuccess,
  fetchVehicleTypesFailure
} = vehicleTypesSlice.actions;

export const fetchVehicleTypes = () => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies?.current?.id;

  if (!companyKey || !companyId || !authKey) {
    return;
  }

  const urlForVehicleTypes = `${VEHICLE_TYPES}?direction=DOWN&pruning=ALL&company_id=${companyId}`;
  dispatch(fetchVehicleTypesStart('list'));

  try {
    const response = await api.get(urlForVehicleTypes, { authKey });
    const { body } = response;
    dispatch(fetchVehicleTypesSuccess({ list: body, companyKey }));
  } catch (err) {
    console.error(err);
    dispatch(fetchVehicleTypesFailure({ err: err.toString(), companyKey, type: 'list' }));
  }
};

export const fetchDeletedVehicleTypes = () => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const companyKey = getState().companies.current.api_key;
  const companyId = getState().companies?.current?.id;

  if (!companyKey || !companyId || !authKey) {
    return;
  }

  const urlForDeletedVehicleTypes = `${VEHICLE_TYPES}?status=DELETED&direction=DOWN&pruning=ALL&company_id=${companyId}`;
  dispatch(fetchVehicleTypesStart('deletedList'));

  try {
    const response = await api.get(urlForDeletedVehicleTypes, { authKey });
    const { body } = response;
    dispatch(fetchDeletedVehicleTypesSuccess({ deletedList: body, companyKey }));
  } catch (err) {
    console.error(err);
    dispatch(fetchVehicleTypesFailure({ err: err.toString(), companyKey, type: 'deletedList' }));
  }
};

export const fetchVehicleTypesByCompany = companyId => async (dispatch, getState) => {
  const {
    user: { current: currentUser }
  } = getState();
  const authKey = currentUser?.auth?.key;

  try {
    const response = await api.get(VEHICLE_TYPES, {
      authKey,
      query: {
        company_id: companyId
      }
    });

    if (!response || response.status !== 200) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('VehicleTypes.Notifications.SomethingWentWrong')
        })
      );
    }

    return response.body || [];
  } catch (err) {
    console.error(err);
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseUserErrorMessage(err)
      })
    );
  }
};

export const useVehicleTypes = () => {
  const dispatch = useDispatch();
  const vehicleTypes = useSelector(state => state.vehicleTypes.list);
  const isFetching = useSelector(state => state.vehicleTypes.meta.isFetching.list);
  const isListEmpty = useSelector(state => state.vehicleTypes.meta.isListEmpty.list);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent('list');

  if (!isFetching && (isCompanyKeyDifferent || (vehicleTypes.length === 0 && !isListEmpty))) {
    dispatch(fetchVehicleTypes());
  }
  return vehicleTypes;
};

export const useDeletedVehicleTypes = flagForAPICall => {
  const dispatch = useDispatch();
  const deletedVehicleTypes = useSelector(state => state.vehicleTypes.deletedList);
  const isFetching = useSelector(state => state.vehicleTypes.meta.isFetching.deletedList);
  const isListEmpty = useSelector(state => state.vehicleTypes.meta.isListEmpty.deletedList);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent('deletedList');

  if (
    !isFetching &&
    (isCompanyKeyDifferent || (deletedVehicleTypes.length === 0 && !isListEmpty)) &&
    flagForAPICall
  ) {
    dispatch(fetchDeletedVehicleTypes());
  }

  return deletedVehicleTypes;
};

export const useVehicleType = id => {
  const allVehicleTypes = useVehicleTypes();
  const allDeletedVehicleTypes = useDeletedVehicleTypes();
  const allCompanies = useCompanies();
  const vehicleType = useMemo(() => {
    const type = [...allVehicleTypes, ...allDeletedVehicleTypes].find(
      type => Number(type.id) === Number(id)
    ) || { id };
    const company = {
      ...(type.company || {}),
      ...(allCompanies.find(company => company.id === type?.companyId) || {})
    };
    return { ...type, company };
  }, [allVehicleTypes, allDeletedVehicleTypes, id, allCompanies]);
  return vehicleType;
};

export const useCompanyKey = type => useSelector(state => state.vehicleTypes.meta.companyKey[type]);
export const useIsFetching = (type = 'list') =>
  useSelector(state => state.vehicleTypes.meta.isFetching[type]);
const useIsCompanyKeyDifferent = type => useCompanyKey(type) !== useCurrentCompanyKey();

export const deleteVehicleTypeApi = (data, history) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const { id, name } = data;
  const url = `/vehicletypes/${id}`;
  Mixpanel.sendTrackEvent(MPTrackingEvents.Settings.VehicleTypes.Update, {
    typeOfUpdate: 'delete'
  });
  try {
    const response = await api.delete(url, { authKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('VehicleTypes.Notifications.DeleteNotification', { name })
        })
      );
      dispatch(fetchVehicleTypes());
      dispatch(fetchDeletedVehicleTypes());
      history && canHistoryGoBack(history, '/settings/vehicleTypes');
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${name} could not be deleted: ${err}`
      })
    );
  }
};

export const restoreVehicleTypeApi = data => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const { id, name } = data;
  const url = `${API_PATH}/vehicletypes/${id}/restore`;
  request('PUT', url)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(resp => {
      if (resp.ok) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('VehicleTypes.Notifications.RestoreNotification', { name })
          })
        );
        dispatch(fetchVehicleTypes());
        dispatch(fetchDeletedVehicleTypes());
      }
    })
    .catch(err => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
      );
    });
};

export default vehicleTypesSlice.reducer;
