import React, { useMemo, useEffect, useState } from 'react';
import { LensSearchPane } from './ControlPanel/LensSearchPane';
import { Mode } from './ControlPanel/constants';
import { Col, Row } from 'antd';
import styles from './ControlPanel/controlpanel.module.scss';
import { LENS_STEPS } from './ControlPanel/constants';
import { NearestPane } from './ControlPanel/NearestPane';

export function ProximityControlPanel({ mode, lensConfig, onChange, msgChannel, trackingDevices }) {
  const extraStyles = useMemo(() => {
    return mode === Mode.Lens && lensConfig?.currentStep === LENS_STEPS.SHOW_RESULT
      ? { padding: 0 }
      : {};
  }, [mode, lensConfig.currentStep]);

  return (
    <Row className={styles.controlPanel} style={extraStyles}>
      <Col span="24">
        {mode === Mode.Lens && (
          <LensSearchPane {...lensConfig} mode={mode} onModeChange={onChange} />
        )}

        {mode === Mode.NearestVehicle && (
          <NearestPane
            mode={mode}
            onModeChange={onChange}
            msgChannel={msgChannel}
            trackingDevices={trackingDevices}
            {...lensConfig}
          />
        )}
      </Col>
    </Row>
  );
}
