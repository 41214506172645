import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { FormControl } from 'react-bootstrap';

import FakeCheckbox from 'components/form/fake-checkbox/FakeCheckbox';
import InfoRow from 'components/form/info-row/InfoRow';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import FormTitle from 'components/form/form-title/FormTitle';

import { format } from 'utils/dates';
import { useCompanies } from 'features/company/companySlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useInspectionChecklists } from 'features/inspectionChecklist/inspectionChecklistSlice';
import { useLocalization } from 'features/localization/localizationSlice';

import numericIcon from 'static/images/icons/formBuilder/numeric.svg';
import okIcon from 'static/images/icons/formBuilder/ok.svg';
import selectionIcon from 'static/images/icons/formBuilder/selection.svg';
import textIcon from 'static/images/icons/formBuilder/text.svg';
import yesnoIcon from 'static/images/icons/formBuilder/yesno.svg';
import checkboxIcon from 'static/images/icons/check_box_outline_blank.svg';
import signatureIcon from 'static/images/icons/formBuilder/signature.svg';

import styles from './InspectionChecklist.module.scss';
import { PERMISSION, STATUS } from './constants';
import { handleDeleteAction, handleEditAction, handleEditDvirAction } from './helpers';

export const InspectionChecklistView = () => {
  const inspectionChecklists = useInspectionChecklists();
  const companies = useCompanies();
  const fleets = useFleets();
  const dispatch = useDispatch();
  const [inspectionData, setInspectionData] = useState({});
  const localization = useLocalization();
  const { t } = useTranslation();
  const history = useHistory();

  const path = window.location.pathname;
  const indexBeginningId = path.lastIndexOf('/');
  const id = path.substr(indexBeginningId + 1, path.length - 1);

  const icons = [
    'text',
    'numeric',
    'ok/not ok',
    'selection',
    'yes/no',
    'multiselection',
    'checkbox',
    'signature'
  ];
  const dictionary = {
    text: textIcon,
    numeric: numericIcon,
    'ok/not ok': okIcon,
    selection: selectionIcon,
    'yes/no': yesnoIcon,
    multiselection: selectionIcon,
    checkbox: checkboxIcon,
    signature: signatureIcon
  };

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    const inspectionChecklist = inspectionChecklists.find(
      inspection => inspection.id === parseInt(id, 10)
    );
    setInspectionData({ ...inspectionChecklist });
  }, [inspectionChecklists, id]);

  useEffect(() => {
    dispatch(setPageTitle(getInspectionName(inspectionData.content)));
  }, [inspectionData, dispatch]);

  function getInspectionName(content) {
    return content ? JSON.parse(content).ChecklistName : '';
  }

  function getAssociatedCompanies(company_id) {
    return (
      <>
        <div>
          {company_id.length > 1
            ? `${t('PreTripChecklist.Companies')}`
            : `${t('PreTripChecklist.Company')}`}
        </div>
        <div>
          {company_id.length > 1
            ? company_id.map(id => {
                let companyToReturn = companies.find(company => company.id === parseInt(id, 10));
                return <div>{companyToReturn.name}</div>;
              })
            : (companies.find(company => company.id === parseInt(company_id, 10)) || {}).name}
        </div>
      </>
    );
  }

  function getAssociatedFleets(fleet_id) {
    return (
      <>
        <div>
          {fleet_id.length > 1
            ? `${t('PreTripChecklist.Fleets')}`
            : `${t('PreTripChecklist.Fleet')}`}
        </div>
        <div>
          {fleet_id.length > 1
            ? fleet_id.map(id => {
                let fleetToReturn = fleets.find(fleet => fleet.id === parseInt(id, 10));
                return <div>{typeof fleetToReturn !== 'undefined' ? fleetToReturn.name : ''}</div>;
              })
            : fleets.find(fleet => fleet.id === parseInt(fleet_id, 10))
            ? fleets.find(fleet => fleet.id === parseInt(fleet_id, 10)).name
            : ''}
        </div>
      </>
    );
  }

  function getAssociatedCompaniesAndFleets() {
    let assoc = inspectionData.associations ? JSON.parse(inspectionData.associations) : null;
    return assoc ? (
      <div>
        {assoc.company_id ? getAssociatedCompanies(assoc.company_id) : null}
        {assoc.fleet_id ? getAssociatedFleets(assoc.fleet_id) : null}
      </div>
    ) : (
      '-'
    );
  }

  function getInfoRowCompanyValue() {
    return inspectionData.company
      ? companies.find(company => company.id === inspectionData.company.id).name
      : null;
  }

  function getInfoRowDateValue(date) {
    return inspectionData.createdAt
      ? format(new Date(date), localization.formats.time.formats.dby_imp)
      : '';
  }

  function getQuestionTitleAndIcon(id, icon, type, text) {
    return (
      <>
        <span className={styles.elementTypeContainer}>
          {id ? <h5 className={styles.elementIndex}>{id}.</h5> : ''}
          <i className={styles.icon} style={{ content: `url(${icon})` }} />
          <span className={styles.elementType}>{t(`Forms.ActualForm.${type}`)}</span>
        </span>
        <div className={styles.elementName}>{text}</div>
      </>
    );
  }

  function getQuestionFormElementExample(question, type) {
    switch (type) {
      case 'Yes/No':
      case 'Ok/Not Ok':
        return (
          <div className={styles.stockInput}>
            {question.PossibleAnswers.map((option, index) => {
              return (
                <span key={`possible-answers-${index}`}>
                  <input className={styles.checkboxText} type="radio" readOnly disabled />
                  <label className={styles.checkboxText}>{option.Value}</label>
                </span>
              );
            })}
          </div>
        );
      case 'Checkbox':
        return (
          <div className={styles.stockInput}>
            {question.PossibleAnswers.map((option, index) => {
              return (
                <span key={`possible-answers-${index}`}>
                  <input className={styles.checkboxText} type="checkbox" readOnly disabled />
                  <label className={styles.checkboxText}>{option.Value}</label>
                </span>
              );
            })}
          </div>
        );
      default:
        return (
          <FormControl
            as="input"
            className={styles.stockInput}
            rows={3}
            readOnly
            placeholder={''}
            onChange={() => {}}
          />
        );
    }
  }

  function getQuestionLabel(question, icon, id, type) {
    return (
      <div className={styles.labelContainerWithLine}>
        {getQuestionTitleAndIcon(id, icon, type, question.Text)}
        {getQuestionFormElementExample(question, type)}
      </div>
    );
  }

  function getAllowBlanksCheckboxElement(question) {
    return (
      <>
        <FakeCheckbox checked={question.AllowBlank} disabled />
        <span className={styles.marginLeft10}>{t('PreTripChecklist.View.AllowBlank')}</span>
      </>
    );
  }

  function getDropdown(question) {
    return (
      <div>
        <FakeCheckbox checked={question.Dropdown} disabled />
        <span className={styles.marginLeft10}>{t('PreTripChecklist.View.Dropdown')}</span>
      </div>
    );
  }

  function getPossibleAnswer(question, option) {
    const value = ['Yes', 'No'].includes(option.Value)
      ? t(`Forms.ActualForm.${option.Value}`)
      : option.Value;
    return (
      <InfoRow
        labelWidth="20%"
        key={`${t('Forms.ActualForm.Option')}-${question.PossibleAnswers.indexOf(option)}`}
        label={`${t('Forms.ActualForm.Option')} ${question.PossibleAnswers.indexOf(option) + 1}`}
        value={
          <div className={styles.flexRow}>
            <div className={styles.left}>{value}</div>
            <div className={styles.right}>
              <span className={styles.marginLeft10}>
                <FakeCheckbox checked={option.Pass} disabled />
              </span>
              <span className={styles.marginLeft10}>{t('PreTripChecklist.View.PassAnswer')}</span>
            </div>
          </div>
        }
        sxValue={{ width: '80%' }}
        styles={styles}
      />
    );
  }

  function getQuestionValue(question, failed) {
    const allowBlanksCheckbox =
      typeof question.AllowBlank !== 'undefined' ? getAllowBlanksCheckboxElement(question) : null;
    const dropdown =
      getQuestionType(question).toLowerCase() === 'selection' ? getDropdown(question) : null;

    let possibleAnswersArray = null;

    if (question.PossibleAnswers) {
      possibleAnswersArray = question.PossibleAnswers.map(option =>
        getPossibleAnswer(question, option)
      );
    }

    return (
      <div>
        <div className={styles.marginLeft10}>{dropdown}</div>
        {failed ? '' : <div className={styles.marginLeft10}>{allowBlanksCheckbox}</div>}
        <div className={styles.marginLeft10}>{possibleAnswersArray}</div>
      </div>
    );
  }

  function getSubFailedQuestionContentValue(failedQuestion, showHeader, icon, index) {
    return (
      <div className={styles.followUp} key={`sub-failed-${index}`}>
        {showHeader ? <h5>{t('PreTripChecklist.View.FailedQuestions')}</h5> : ''}
        <div>
          {showHeader
            ? getQuestion(true, failedQuestion, dictionary[icon])
            : getQuestion(true, failedQuestion, dictionary[icon], index)}
        </div>
      </div>
    );
  }

  function getSubFailedQuestionContent(questions, showHeader) {
    return questions.map(failedQuestion => {
      const icon = icons.find(ic => ic.includes(getQuestionType(failedQuestion).toLowerCase()));
      return getSubFailedQuestionContentValue(
        failedQuestion,
        showHeader,
        icon,
        questions.indexOf(failedQuestion) + 1
      );
    });
  }

  function getSubFailedQuestion(subFailedQuestions, showHeader, type) {
    return subFailedQuestions ? (
      <div>
        <div className={styles.failed}>
          {getSubFailedQuestionContent(subFailedQuestions, showHeader, type)}
        </div>
        <div className={styles.borderBottom}></div>
      </div>
    ) : (
      ''
    );
  }

  function getQuestionType(question) {
    if (question) {
      if (question.PossibleAnswers) {
        switch (question.PossibleAnswers[0].Value) {
          case 'Yes':
            return 'YesNo';
          case 'Ok':
            return 'OkNotOk';
          // case 'No':
          //   return 'Checkbox';
          default:
            break;
        }
        if (question.Type === 'MultiSelection') {
          question.Dropdown = true;
          return 'Selection';
        }
      }
      return question.Type === 'Numerical' ? 'Numeric' : question.Type;
    }
    return null;
  }

  function getQuestion(failed, question, icon, id, type) {
    return (
      <div key={`key_${id}`}>
        <InfoRow
          labelWidth="40%"
          label={getQuestionLabel(question, icon, id, getQuestionType(question))}
          value={getQuestionValue(question, failed)}
          key={`formElement-${id}`}
          sxValue={{
            width: '60%'
          }}
          hideBorder={question.SubFailedQuestions ? true : false}
          styles={styles}
        />
        {failed ? '' : getSubFailedQuestion(question.SubFailedQuestions, true, type)}
      </div>
    );
  }

  function getInspectionQuestions(content) {
    const questionElements = [];
    if (content) {
      try {
        const parsedContent = JSON.parse(content);
        parsedContent.Questions.forEach(question => {
          const icon = icons.find(ic => ic.includes(getQuestionType(question).toLowerCase()));
          questionElements.push(
            getQuestion(
              false,
              question,
              dictionary[icon],
              questionElements.length + 1,
              getQuestionType(question)
            )
          );
        });
      } catch (error) {
        console.error(error);
      }
    }

    return questionElements;
  }

  function getFailedQuestions(content) {
    let failedElements = [];
    let displayFailedQuestions = false;
    if (content) {
      try {
        const parsedContent = JSON.parse(content);
        if (parsedContent.FailedQuestions && parsedContent.FailedQuestions.length) {
          displayFailedQuestions = true;
          failedElements = getSubFailedQuestion(
            parsedContent.FailedQuestions,
            false,
            getQuestionType()
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
    return displayFailedQuestions ? (
      <div>
        <FormTitle title={t('PreTripChecklist.View.FailedQuestions')} underlined />
        {failedElements}
      </div>
    ) : (
      ''
    );
  }

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        dispatch(handleDeleteAction(inspectionData, history));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ViewHeaderWrapper
        data={{
          ...inspectionData,
          status: STATUS.ENABLED
        }}
        editPath={
          inspectionData.isDVIR
            ? `/settings/inspectionChecklist/editDvir/id/${id}`
            : `/settings/inspectionChecklist/edit/id/${id}`
        }
        canUse={PERMISSION.PRETRIP}
        handleButtonAction={handleButtonAction}
        handleEditAction={
          inspectionData.isDVIR
            ? handleEditDvirAction(history, id, inspectionData)
            : handleEditAction(history, id, inspectionData)
        }
        typeOfEntityToDelete={t('Common.pre-trip checklist')}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '32px',
          color: '#181c21'
        }}
      >
        <FormTitle title={t('PreTripChecklist.View.Details')} underlined />
        <InfoRow
          label={t('PreTripChecklist.View.Name')}
          value={getInspectionName(inspectionData.content)}
          styles={styles}
        />
        <InfoRow
          label={t('PreTripChecklist.View.Company')}
          value={getInfoRowCompanyValue()}
          styles={styles}
        />
        <InfoRow
          label={t('PreTripChecklist.View.Version')}
          value={inspectionData.version}
          styles={styles}
        />
        <InfoRow
          label={t('PreTripChecklist.View.VehicleRegistration')}
          value={
            inspectionData.content && JSON.parse(inspectionData.content).RequireVehicleRegistration
              ? t('Common.Yes')
              : t('Common.No')
          }
          styles={styles}
        />
        <InfoRow
          label={t('PreTripChecklist.View.CreateMaintenance')}
          value={
            inspectionData.content && inspectionData.createMaintSchedule
              ? t('Common.Yes')
              : t('Common.No')
          }
          styles={styles}
        />
        <InfoRow
          label={t('PreTripChecklist.View.DriverSignature')}
          value={
            inspectionData.content && JSON.parse(inspectionData.content).RequireSignature
              ? t('Common.Yes')
              : t('Common.No')
          }
          styles={styles}
        />
        <InfoRow
          label={t('PreTripChecklist.View.Association')}
          value={getAssociatedCompaniesAndFleets()}
          styles={styles}
        />
        <InfoRow
          label={t('PreTripChecklist.View.CreatedAt')}
          value={getInfoRowDateValue(inspectionData.createdAt)}
          styles={styles}
        />
        <InfoRow
          label={t('PreTripChecklist.View.UpdatedAt')}
          value={getInfoRowDateValue(inspectionData.updatedAt)}
          styles={styles}
        />
        <FormTitle title={t('InspectionChecklist.View.InspectionQuestions')} underlined />
        {getInspectionQuestions(inspectionData.content)}
        {getFailedQuestions(inspectionData.content)}
      </div>
    </>
  );
};
