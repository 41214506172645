import React, { useState, useEffect } from 'react';
//methods
import {
  setPageTitle,
  setBackButton,
  addBreadcrumbs,
  addBackButtonLink
} from 'features/page/pageSlice';
import { getActiveTab } from './utils/helpers';
//hooks
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import {
  useJob,
  useJobAttachments,
  useJobEvents,
  useIsFetchingAttachments,
  useIsFetchingJobDetails,
  clearJobError
} from 'features/smartJobs';
import { useRedirectToMainFeaturePageOnCompanyChange } from 'features/company/companySlice';
//components
import { TabsFilters } from 'components/ant';
import { Col, Row } from 'antd';
//constants
import { JobViewTabs, JobViewTabsLabels } from './utils/constans';
//styles
import styles from './JobView.module.scss';
import { getTabs } from 'utils/tabs';

const JobView = () => {
  const dispatch = useDispatch();
  const job = useJob();
  const jobAttach = useJobAttachments();
  const jobEvents = useJobEvents();
  const [activeTab, setActiveTab] = useState(JobViewTabsLabels.DETAILS);
  const { t } = useTranslation();
  const loading = {
    job: useIsFetchingJobDetails(),
    attachments: useIsFetchingAttachments()
  };

  useRedirectToMainFeaturePageOnCompanyChange('/smartjobs');

  useEffect(() => {
    return () => {
      dispatch(clearJobError());
    };
  }, []);

  const handleTabClick = value => {
    setActiveTab(value);
  };

  const { pathname } = useLocation();

  useEffect(() => {
    const runsheetPath = `/smartjobs/id/${job?.runsheet.id}`;
    const BREANDCRUMBS = [
      {
        path: '/smartjobs',
        breadcrumbName: t('SmartJobs.Runsheets')
      },
      {
        path: runsheetPath,
        breadcrumbName: `${t('SmartJobs.Runsheet')} ${job?.runsheet.externalId || ''}`
      },
      {}
    ];
    dispatch(setPageTitle(`${t('SmartJobs.Job')} ${job?.externalId || job?.id || ''}`));
    dispatch(setBackButton(true));
    dispatch(addBreadcrumbs(BREANDCRUMBS));
    dispatch(addBackButtonLink({ url: pathname, backLink: runsheetPath }));
    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [dispatch, job, pathname]);

  return (
    <div className={styles.jobViewContainer}>
      <Row>
        <Col span={24}>
          <div className={styles.tabContainer}>
            <TabsFilters tabs={getTabs(JobViewTabs, activeTab, handleTabClick, t)} />
          </div>
        </Col>
      </Row>
      {getActiveTab(activeTab, job, jobAttach, jobEvents, loading)}
    </div>
  );
};

export default JobView;
