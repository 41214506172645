import { Trans, useTranslation } from 'react-i18next';

import { Alert, Button, Progress, Typography, Upload } from 'antd';

import { ALERT_STATUS_MESSAGES, ALERT_STATUS_TYPE } from '../constants';

import styles from '../ImportModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const BottomPanelImport = ({
  checkStatus,
  fileList,
  handleErrorFileDownload,
  handleFileUpload,
  handleResetModal,
  importStatus,
  isUploadFailed,
  isUploading,
  selectedFile,
  selectedImportType,
  selectedTemplates,
  setFileList,
  setIsUploadFailed,
  setSelectedFile,
  TemplateInstructions,
  uploadFailedMessage
}) => {
  const { Text, Title } = Typography;
  const { Dragger } = Upload;
  const { t } = useTranslation();

  return (
    <div className={styles['bottom-panel']}>
      <div className={styles['bottom-panel__left-side']}>
        <div className={styles['bottom-panel__left-side__instructions-header']}>
          <Title
            level={5}
            id={'instructions-title'}
            className={styles['title--instructions-header']}
            type="secondary"
          >
            {t('CompanyConfig.BulkManagement.Modal.Instructions')}
          </Title>
        </div>
        <div className={styles['bottom-panel__left-side__instructions-content']}>
          <ul className={styles['ul--instructions-content']}>
            <li key={'info-content-1'}>
              <Text>{t('CompanyConfig.BulkManagement.Modal.SpecifyType')}</Text>
            </li>
            <TemplateInstructions />
            <li key={'info-content-4'}>
              <Text>{t('CompanyConfig.BulkManagement.Modal.AdditionalHelpInformation')}</Text>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles['bottom-panel__right-side']}>
        <Title level={5} className={styles['title--upload-documents']} type="secondary">
          {t('CompanyConfig.BulkManagement.Modal.UploadedDocuments')}
        </Title>
        <Dragger
          accept={Object.keys(selectedTemplates)
            .map(fileExtension => `.${fileExtension}`)
            .join(',')}
          beforeUpload={file => {
            setFileList([file]);
            setSelectedFile(file.name);
            setIsUploadFailed(false);
            return false;
          }}
          disabled={!selectedImportType || Object.keys(importStatus).length > 0}
          fileList={fileList}
          height={'fit-content'}
          showUploadList={false}
        >
          <div className="ant-upload-text">
            {selectedFile ? (
              <Trans
                i18nKey={'CompanyConfig.BulkManagement.Modal.Selected'}
                values={{ selectedFile }}
                components={{ 1: <strong /> }}
              />
            ) : (
              t('CompanyConfig.BulkManagement.Modal.Dragger')
            )}
            {checkStatus && (
              <>
                {importStatus.recordTotal !==
                importStatus.errorTotal + importStatus.processedTotal ? (
                  <Progress
                    percent={
                      (importStatus.processedTotal + importStatus.errorTotal) /
                      (importStatus.recordTotal / 100)
                    }
                    strokeColor={'#1790ff'}
                    trailColor={'#E6F7FF'}
                    showInfo={false}
                    status="active"
                  />
                ) : (
                  <Progress
                    percent={100}
                    success={{
                      percent: importStatus.processedTotal / (importStatus.recordTotal / 100),
                      strokeColor: '#b7eb8f'
                    }}
                    strokeColor={'#ffa39e'}
                    showInfo={false}
                  />
                )}
                <p>
                  <Trans
                    i18nKey={'CompanyConfig.BulkManagement.Modal.Progress'}
                    values={{
                      processed: importStatus.processedTotal ?? 0,
                      record: importStatus.recordTotal ?? 0,
                      error: importStatus.errorTotal ?? 0
                    }}
                    components={{ 1: <Text type="danger" /> }}
                  />
                </p>
              </>
            )}
          </div>
        </Dragger>
        <Button
          className={styles['button--upload-import']}
          disabled={
            !selectedImportType ||
            !Object.keys(selectedTemplates).length > 0 ||
            !selectedFile ||
            importStatus.processingStatus === 'IN_PROGRESS'
          }
          id={BUTTON_IDS.bulkManagementImport}
          onClick={
            ['SUCCESS', 'PARTIAL', 'FAILED'].includes(importStatus.processingStatus)
              ? handleResetModal
              : handleFileUpload
          }
          loading={isUploading}
        >
          {['SUCCESS', 'PARTIAL', 'FAILED'].includes(importStatus.processingStatus)
            ? t('CompanyConfig.BulkManagement.Modal.UploadAnotherButton')
            : t('CompanyConfig.BulkManagement.Modal.UploadButton')}
        </Button>
        {isUploadFailed && (
          <Alert
            message={uploadFailedMessage}
            showIcon
            type="error"
            className={styles['alert--upload']}
          />
        )}
        {checkStatus &&
          (importStatus.recordTotal !== importStatus.errorTotal + importStatus.processedTotal ? (
            <Alert
              message={t('CompanyConfig.BulkManagement.Modal.Alerts.ModalClose')}
              showIcon
              type="info"
              className={styles['alert--upload']}
            />
          ) : (
            <Alert
              message={
                ALERT_STATUS_MESSAGES(handleErrorFileDownload)[importStatus.processingStatus]
              }
              showIcon
              type={ALERT_STATUS_TYPE[importStatus.processingStatus]}
              className={styles['alert--upload']}
            />
          ))}
      </div>
    </div>
  );
};
