import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import { fetchSmartJobsCodes, updateCode, addCode } from '../thunks';

const initialState = {
  codes: [],
  isFetching: false,
  error: null,
  success: null,
  meta: {
    companyId: null
  }
};

export const smartJobsCodesSlice = createSlice({
  name: 'smartJobsCodes',
  initialState,
  // reducers: {},
  extraReducers: {
    [fetchSmartJobsCodes.pending]: (state, action) => {
      state.isFetching = true;
    },
    [fetchSmartJobsCodes.fulfilled]: (state, action) => {
      const result = unwrapResult(action);

      state.isFetching = false;
      state.success = true;
      state.codes = result.body;
      state.meta.companyId = result.companyId;
    },
    [fetchSmartJobsCodes.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = true;
      state.meta.companyId = payload.companyId;
    },
    [updateCode.pending]: (state, action) => {
      state.isUpdadtingCode = true;
    },
    [updateCode.fulfilled]: (state, action) => {
      state.isUpdadtingCode = false;
    },
    [updateCode.rejected]: (state, action) => {
      state.isUpdadtingCode = false;
    },
    [addCode.pending]: (state, action) => {
      state.isUpdadtingCode = true;
    },
    [addCode.fulfilled]: (state, action) => {
      state.isUpdadtingCode = false;
    },
    [addCode.rejected]: (state, action) => {
      state.error = action.payload;
      state.isUpdadtingCode = false;
    }
  }
});
