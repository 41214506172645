import React from 'react';
import { Table } from 'components/ant';
import { useTranslation } from 'react-i18next';

import { useLocalization } from 'features/localization/localizationSlice';
import { useGetVehicleAgreementsQuery } from 'services/nextgen/ngAgreementApi';
import { vehicleAgreementColumns } from './constants';

const VehicleAgreements = ({ vehicleId }) => {
  const localization = useLocalization();
  const { data, isFetching } = useGetVehicleAgreementsQuery({
    id: vehicleId,
    embed: 'device,subscriptionPack'
  });
  const { t } = useTranslation();
  return (
    <Table
      headerHeight={52}
      headerStyle={{ paddingTop: '18px' }}
      rowClassName="tableRow"
      columns={vehicleAgreementColumns(t, localization)}
      dataSource={data}
      rowKey="id"
      pagination={false}
      loading={isFetching}
    />
  );
};

export default VehicleAgreements;
