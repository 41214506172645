import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DeviceForm from './DeviceForm';
import Devices from './Devices';
import { DeviceView } from './DeviceView';
import { Can, entities } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { DeviceAudits } from './DeviceAudits';
import { DevicesBulkEditAudit } from './BulkEdit/DevicesBulkEditAudit';
import { useLocation } from 'react-router';
import { PATHS } from './constants';

const DevicesRouter = () => {
  const location = useLocation();

  return (
    <Can everyEntity={[entities.DEVICE]} onFail={<NoAccessBody />}>
      <Switch>
        <Route path={PATHS.VIEW_DEVICE}>
          <Can everyEntity={[entities.DEVICE_VIEW]} onFail={<NoAccessBody />}>
            <DeviceView />
          </Can>
        </Route>
        <Route exact path={PATHS.NEW_DEVICE}>
          <Can everyEntity={[entities.DEVICE_CREATE]} onFail={<NoAccessBody />}>
            <DeviceForm action="add" />
          </Can>
        </Route>
        <Route path={PATHS.EDIT_DEVICE}>
          <Can everyEntity={[entities.DEVICE_UPDATE]} onFail={<NoAccessBody />}>
            <DeviceForm action="edit" />
          </Can>
        </Route>
        <Route path={PATHS.AUDIT_DEVICE}>
          <Can everyEntity={[entities.DEVICE_AUDIT]} onFail={<NoAccessBody />}>
            <DeviceAudits />
          </Can>
        </Route>
        <Route path={PATHS.AUDIT_BULKEDIT}>
          <Can everyEntity={[entities.DEVICE_AUDIT]} onFail={<NoAccessBody />}>
            <DevicesBulkEditAudit />
          </Can>
        </Route>
        <Route path={PATHS.DEVICE_DEFAULT}>
          <Devices
            fleetId={location.state?.fleetId}
            gpioConfigurationId={location.state?.gpioConfigurationId}
          />
        </Route>
      </Switch>
    </Can>
  );
};

export default DevicesRouter;
