import { createSlice } from '@reduxjs/toolkit';
import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { usePrevious } from 'utils/methods';
import { sortBy } from 'lodash';
import { t_error } from 'i18nextConfig';
import request from 'superagent';
import moment from 'moment';

import { ApiClient, CompaniesApi } from 'nextgen_api';
import * as API from './companiesAPI';
import { api } from 'utils/api';

import { openToast } from 'features/toasts/toastsSlice';

import { compatibleLogout, logout, reloadUserInfoFromStorage, useUserKey } from '../user/userSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { parseErrorMessage, parseUserErrorMessage } from 'utils/strings';

import { API_PATH } from 'config';
import { useHistory } from 'react-router';
import i18n from 'i18next';
import { canHistoryGoBack } from 'utils/methods';

export const CompanyConfigKey = {
  HideNonBusiness: 'hide_non_business',
  SmartDashcam: 'netradyne_smart_dashcam',
  SmartJobsIntegration: 'smartjobs_forms_integration'
};

export const CompanyConfigValue = {
  Private: 'Private'
};

export const companies = {
  current: {},
  currentCompanyConfig: {},
  currentCompanyAgreement: [],
  list: [],
  subCompaniesList: [],
  parentCompaniesList: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null
  },
  servicesFetchMeta: {
    lastFetched: null,
    isFetching: false,
    error: null
  },
  companyConfigFetchMeta: {
    lastFetched: null,
    isFetching: false,
    error: null
  }
};

const startLoading = state => {
  state.meta.isFetching = true;
};

const loadingFailed = (state, action) => {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload;
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState: companies,
  reducers: {
    setCurrent(state, action) {
      state.current = { ...action.payload, ...{ api_key: action.payload.id } };
    },
    fetchCompaniesStart: startLoading,
    fetchCompaniesSuccess(state, { payload }) {
      state.list = sortBy(payload, ['name']);
      for (const company of state.list) {
        company.api_key = company.id;
      }
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
    },
    fetchCompaniesFailure: loadingFailed,
    fetchParentCompaniesStart: startLoading,
    fetchParentCompaniesSuccess(state, { payload }) {
      state.parentCompaniesList = sortBy(payload, ['name']);
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
    },
    fetchParentCompaniesFailure(state, action) {
      state.parentCompaniesList = [];
      loadingFailed(state, action);
    },
    fetchSubCompaniesStart: startLoading,
    fetchSubCompaniesSuccess(state, { payload }) {
      state.subCompaniesList = sortBy(payload, ['name']);
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      if (state?.current?.id) {
        state.current.companyFeatures = payload?.find(
          company => company.id === state.current.id
        )?.features;
        state.current.geofenceProviders = payload?.find(
          company => company.id === state.current.id
        )?.geofenceProviders;
      }
    },
    fetchSubCompaniesFailure(state, action) {
      state.subCompaniesList = [];
      loadingFailed(state, action);
    },
    fetchCurrentCompanyServicesStart(state, { payload }) {
      state.servicesFetchMeta.isFetching = true;
      state.servicesFetchMeta.error = null;
    },
    fetchCurrentCompanyServicesSucceeded(state, { payload }) {
      const { companyId, data } = payload;
      state.servicesFetchMeta.isFetching = false;
      if (state.current != null && state.current.id === companyId) {
        state.current.features = data;
      }
      state.servicesFetchMeta.lastFetched = Date.now();
    },
    fetchCurrentCompanyServicesFailed(state, { payload }) {
      state.servicesFetchMeta.isFetching = false;
      state.servicesFetchMeta.error = payload.err;
      state.servicesFetchMeta.lastFetched = Date.now();
    },
    fetchCompanyConfigFailure(state, { payload }) {
      state.companyConfigFetchMeta.isFetching = false;
      state.companyConfigFetchMeta.lastFetched = null;
      state.companyConfigFetchMeta.error = payload;
    },
    fetchCompanyConfigStart(state, { payload }) {
      state.companyConfigFetchMeta.isFetching = true;
      state.companyConfigFetchMeta.lastFetched = null;
      state.companyConfigFetchMeta.error = null;
    },
    fetchCompanyConfigSuccess(state, { payload }) {
      state.currentCompanyConfig = payload;
      state.companyConfigFetchMeta.isFetching = false;
      state.companyConfigFetchMeta.lastFetched = moment().valueOf();
      state.companyConfigFetchMeta.error = null;
    },
    fetchCarrierConfigListFailure(state, { payload }) {
      state.companyConfigFetchMeta.isFetching = false;
      state.companyConfigFetchMeta.lastFetched = null;
      state.companyConfigFetchMeta.error = payload;
    },
    fetchCarrierConfigListStart(state, { payload }) {
      state.companyConfigFetchMeta.isFetching = true;
      state.companyConfigFetchMeta.lastFetched = null;
      state.companyConfigFetchMeta.error = null;
    },
    fetchCarrierConfigListSuccess(state, { payload }) {
      state.currentCompanyConfig = payload;
      state.companyConfigFetchMeta.isFetching = false;
      state.companyConfigFetchMeta.lastFetched = moment().valueOf();
      state.companyConfigFetchMeta.error = null;
    },
    fetchCarrierConfigFailure(state, { payload }) {
      state.companyConfigFetchMeta.isFetching = false;
      state.companyConfigFetchMeta.lastFetched = null;
      state.companyConfigFetchMeta.error = payload;
    },
    fetchCarrierConfigStart(state, { payload }) {
      state.companyConfigFetchMeta.isFetching = true;
      state.companyConfigFetchMeta.lastFetched = null;
      state.companyConfigFetchMeta.error = null;
    },
    fetchCarrierConfigSuccess(state, { payload }) {
      state.currentCompanyConfig = payload;
      state.companyConfigFetchMeta.isFetching = false;
      state.companyConfigFetchMeta.lastFetched = moment().valueOf();
      state.companyConfigFetchMeta.error = null;
    },
    fetchHideNonBusinessConfigSuccess(state, { payload }) {
      state.currentCompanyConfig.hideNonBusinessEnabled = payload;
    },
    fetchCompanySFIConfigSuccess(state, { payload }) {
      state.currentCompanyConfig.SFIEnabled = payload;
    }
  },
  extraReducers: {
    [logout]: (state, action) => {
      state.current = companies.current;
      state.list = companies.list;
    },
    [reloadUserInfoFromStorage]: (state, action) => {
      state = companies;
      return state;
    }
  }
});

export const {
  setCurrent,
  fetchCompaniesStart,
  fetchCompaniesSuccess,
  fetchCompaniesFailure,
  fetchParentCompaniesStart,
  fetchParentCompaniesSuccess,
  fetchParentCompaniesFailure,
  fetchSubCompaniesStart,
  fetchSubCompaniesSuccess,
  fetchSubCompaniesFailure,
  fetchCurrentCompanyServicesStart,
  fetchCurrentCompanyServicesSucceeded,
  fetchCurrentCompanyServicesFailed,

  fetchCompanyConfigFailure,
  fetchCompanyConfigStart,
  fetchCompanyConfigSuccess,

  fetchAgreementFailure,
  fetchAgreementStart,
  fetchAgreementSuccess,

  fetchCarrierConfigListFailure,
  fetchCarrierConfigListStart,
  fetchCarrierConfigListSuccess,
  fetchCarrierConfigFailure,
  fetchCarrierConfigStart,
  fetchCarrierConfigSuccess,
  fetchHideNonBusinessConfigSuccess,
  fetchCompanySFIConfigSuccess
} = companiesSlice.actions;

export const deletedSubCompanies = {
  list: [],
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null
  }
};

const deletedSubCompaniesSlice = createSlice({
  name: 'deletedSubCompanies',
  initialState: deletedSubCompanies,
  reducers: {
    fetchDeletedSubCompaniesStart: startLoading,
    fetchDeletedSubCompaniesSuccess(state, { payload }) {
      state.list = sortBy(payload, ['name']);
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
    },
    fetchDeletedSubCompaniesFailure: loadingFailed
  }
});

export const {
  fetchDeletedSubCompaniesStart,
  fetchDeletedSubCompaniesSuccess,
  fetchDeletedSubCompaniesFailure
} = deletedSubCompaniesSlice.actions;

export const setCurrentCompany = company => async dispatch => {
  dispatch(setCurrent(company));
  if (company.id) {
    dispatch(fetchSubCompanies(company.id, 'config,geofenceProviders,services'));
    dispatch(fetchParentCompanies(company.id, 'config,geofenceProviders,services'));
    dispatch(fetchCurrentCompanyServices());
  }
};

export const fetchCompanies = can => async (dispatch, getState) => {
  const {
    companies: {
      meta: { isCompaniesLoading: isLoading }
    }
  } = getState();
  if (!isLoading) {
    try {
      dispatch(fetchCompaniesStart());
      const request = API.fetchCompanies(getState().user.current.auth.key);
      request.then(result => {
        dispatch(fetchCompaniesSuccess(result));
        const companies = getState().companies;
        if (!companies.current.id) {
          const user = getState().user.current;
          const currentCompany = companies.list.find(company => company.id === user.companyId);
          if (!currentCompany?.id) {
            dispatch(compatibleLogout(can));
            if (getState().user.current.auth.key) {
              dispatch(
                openToast({
                  type: ToastType.Error,
                  message: 'Company retrieve error. You have been redirected to the login screen.'
                })
              );
            }
            return false;
          }
          dispatch(setCurrent(currentCompany));
          dispatch(fetchCurrentCompanyServices());
          dispatch(fetchSubCompanies(currentCompany.id, 'config,geofenceProviders,services'));
          dispatch(fetchParentCompanies(currentCompany.id, 'config,geofenceProviders,services'));
        } else if (!companies.subCompaniesList) {
          dispatch(fetchSubCompanies(companies.current.id, 'config,geofenceProviders,services'));
        }
      });
    } catch (err) {
      dispatch(fetchCompaniesFailure(err.toString()));
    }
  }
};

export const fetchParentCompanies = (companyId, embed = '') => async (dispatch, getState) => {
  dispatch(fetchParentCompaniesStart());
  const request = API.fetchSubCompanies(
    getState().user.current.auth.key,
    companyId,
    embed,
    true,
    'UP'
  );
  request
    .then(result => {
      dispatch(fetchParentCompaniesSuccess(result));
    })
    .catch(err => {
      dispatch(fetchParentCompaniesFailure(err?.toString()));
    });
};

export const fetchSubCompanies = (companyId, embed = '') => async (dispatch, getState) => {
  dispatch(fetchSubCompaniesStart());
  const request = API.fetchSubCompanies(getState().user.current.auth.key, companyId, embed);
  request
    .then(result => {
      dispatch(fetchSubCompaniesSuccess(result));
    })
    .catch(err => {
      dispatch(fetchSubCompaniesFailure(err?.toString()));
    });
};

export const fetchCurrentCompanyServices = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const companyId = state.companies.current?.id;
    const userKey = state.user.current.auth.key;
    if (companyId == null || state.companies.servicesFetchMeta.isFetching) {
      return;
    }
    dispatch(fetchCurrentCompanyServicesStart());
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const companyApi = new CompaniesApi(apiClient);
    const promise = new Promise((resolve, reject) => {
      companyApi.getServicesByCompanyId(
        state.companies.current.id,
        null,
        { embed: 'features' },
        (err, data, resp) => {
          if (err && (resp == null || resp.status !== 200)) {
            console.error(err);
            reject(err);
          } else {
            resolve(resp.body);
          }
        }
      );
    });

    const data = await promise;
    dispatch(fetchCurrentCompanyServicesSucceeded({ data, companyId }));
  } catch (err) {
    dispatch(fetchCurrentCompanyServicesFailed({ err: err.toString() }));
  }
};

//Currently, only ELD Carrier info
export const fetchCompanyConfig = (company, userKey, isELDUS = false) => async (
  dispatch,
  getState
) => {
  const status = getState().companies.companyConfigFetchMeta;

  if (status.isFetching || company?.id == null || userKey == null) {
    return;
  }

  dispatch(fetchCompanyConfigStart());
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;

  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };
  const apiQuery = '/carrier/company/{companyId}';
  const promise = new Promise((resolve, reject) => {
    apiClient.callApi(
      apiQuery,
      'GET',
      { companyId: company.id },
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(resp?.body || err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    if (!apiClient.canceled) {
      if (!isELDUS) {
        //For single we need to get the enabled carrier
        const enabledCarriers = data.filter(d => d.status === 'ENABLED');
        if (enabledCarriers.length > 0) {
          dispatch(fetchCompanyConfigSuccess(enabledCarriers[0]));
        } else {
          dispatch(fetchCompanyConfigSuccess({}));
        }
      } else {
        dispatch(fetchCompanyConfigSuccess(data));
      }
    }
  } catch (err) {
    dispatch(fetchCompanyConfigFailure(err));
    dispatch(
      openToast({
        type: ToastType.Error,
        message: t_error(err).toString()
      })
    );
  }
};

//Currently, only ELD Carrier info
export const fetchCarrierConfigList = (company, userKey) => async (dispatch, getState) => {
  const status = getState().companies.companyConfigFetchMeta;

  if (status.isFetching || company?.id == null || userKey == null) {
    return;
  }

  dispatch(fetchCarrierConfigListStart());
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;

  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };
  const apiQuery = '/carrier/company/{companyId}';
  const promise = new Promise((resolve, reject) => {
    apiClient.callApi(
      apiQuery,
      'GET',
      { companyId: company.id },
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(resp?.body || err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    if (!apiClient.canceled) {
      dispatch(fetchCarrierConfigListSuccess(data));
    }
  } catch (err) {
    dispatch(fetchCarrierConfigListFailure(err));
    dispatch(
      openToast({
        type: ToastType.Error,
        message: t_error(err).toString()
      })
    );
  }
};
//Currently, only ELD Carrier info
export const fetchCarrierConfig = (carrierId, userKey) => async (dispatch, getState) => {
  const status = getState().companies.companyConfigFetchMeta;

  if (status.isFetching || carrierId == null || userKey == null) {
    return;
  }

  dispatch(fetchCarrierConfigStart());
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;

  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };
  const apiQuery = '/carrier/{carrierId}';
  const promise = new Promise((resolve, reject) => {
    apiClient.callApi(
      apiQuery,
      'GET',
      { carrierId: carrierId },
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          reject(resp?.body || err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const data = await promise;
    if (!apiClient.canceled) {
      dispatch(fetchCarrierConfigSuccess(data));
    }
  } catch (err) {
    dispatch(fetchCarrierConfigFailure(err));
    dispatch(
      openToast({
        type: ToastType.Error,
        message: t_error(err).toString()
      })
    );
  }
};

export const fetchCompanyHideNonBusinessConfig = companyId => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;

  if (companyId == null || userKey == null) {
    return;
  }

  try {
    const url = `${API_PATH}/companies/${companyId}/hidenonbusiness/config`;
    await request('GET', url)
      .set('Authorization', `Token token="${userKey}"`)
      .then(resp => {
        const hideNonBusinessEnabled = resp?.body?.value?.includes('true');
        dispatch(fetchHideNonBusinessConfigSuccess(hideNonBusinessEnabled));
      });
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseErrorMessage(err)
      })
    );
  }
};

export const fetchCompanySFIConfig = companyId => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;

  if (companyId == null || userKey == null) {
    return;
  }

  try {
    const url = `${API_PATH}/companies/${companyId}/smartjobs_forms_integration/config`;
    await request('GET', url)
      .set('Authorization', `Token token="${userKey}"`)
      .then(resp => {
        const SFIEnabled = resp?.body?.value?.includes('true');
        dispatch(fetchCompanySFIConfigSuccess(SFIEnabled));
      });
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseErrorMessage(err)
      })
    );
  }
};

export const fetchDeletedSubCompanies = () => async (dispatch, getState) => {
  if (getState().deletedSubCompanies.meta.isFetching) {
    return;
  }

  const currentCompanyId = getState().companies.current.id;

  try {
    dispatch(fetchDeletedSubCompaniesStart());
    const request = API.fetchSubCompanies(
      getState().user.current.auth.key,
      currentCompanyId,
      '',
      false
    );
    request.then(result => {
      dispatch(fetchDeletedSubCompaniesSuccess(result));
    });
  } catch (err) {
    dispatch(fetchDeletedSubCompaniesFailure(err.toString()));
  }
};

const companiesReducers = {
  companies: companiesSlice.reducer,
  deletedSubCompanies: deletedSubCompaniesSlice.reducer
};

export default companiesReducers;

export const useCompanies = () => {
  return useSelector(state => state.companies.list);
};

export const useSubCompanies = () => {
  return useSelector(state => state.companies.subCompaniesList) || [];
};

export const useCurrentParentCompanies = () => {
  return useSelector(state => state.companies.parentCompaniesList) || [];
};

export const useCurrentSubcompany = companyId =>
  useSelector(
    state =>
      companyId &&
      (state.companies.subCompaniesList || []).find(subCompany => subCompany.id === companyId)
  );

export const useSubCompanyEntityConfig = (companyId, key) => {
  const currentSubcompany = useCurrentSubcompany(companyId);
  const configValue = currentSubcompany?.entityConfigurationList?.find(
    entityConfig => entityConfig?.key === key
  );
  try {
    if (key === 'netradyne_smart_dashcam') {
      return configValue?.value && JSON.parse(configValue?.value);
    }
    if (key === 'smartjobs_forms_integration') {
      return configValue?.value && JSON.parse(configValue?.value)?.enabled;
    }

    return configValue?.value && JSON.parse(configValue?.value)?.field1;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const useCurrentCompany = companyId =>
  useSelector(state =>
    companyId
      ? (state.companies.list || []).find(company => company.id === companyId)
      : state.companies.current
  );

export const useCurrentCompanyServices = () => {
  return useSelector(state => state.companies.current.features);
};

export const useIsLoadingCurrentCompany = () =>
  useSelector(state => state.companies.meta.isFetching);

export const useIsFetchingCurrentCompanyServices = () =>
  useSelector(state => state.companies.servicesFetchMeta.isFetching);

export const useIsFetchingDeletedSubCompany = () =>
  useSelector(state => state.deletedSubCompanies.meta.isFetching);

export const useIsFetchingCompany = () => useSelector(state => state.companies.meta.isFetching);

export const useCurrentCompanyId = () => {
  return useSelector(state => state.companies.current.id);
};

export const useCurrentCompanyKey = () => {
  return useSelector(state => state.companies.current.api_key);
};

export const useIsELDCompany = () => {
  const company = useCurrentCompany();
  if (company?.features?.find(f => f.code === 'ELD')) {
    return true;
  } else {
    return false;
  }
};

export const useCompanyConfig = () => useSelector(state => state.companies.currentCompanyConfig);

export const useCompanyConfigMeta = () =>
  useSelector(state => state.companies.companyConfigFetchMeta);

export const useCompanyGeofenceProviders = () =>
  useSelector(state => state.companies.current.geofenceProviders);

export const useCompanyServicesFetchingDone = () =>
  useSelector(
    state =>
      !state.companies.servicesFetchMeta.isFetching && state.companies.servicesFetchMeta.lastFetched
  );

export const useRedirectToMainFeaturePageOnCompanyChange = link => {
  const companyKey = useCurrentCompanyKey();
  const previousCompanyKey = usePrevious(companyKey);
  const history = useHistory();
  useEffect(() => {
    if (previousCompanyKey && companyKey !== previousCompanyKey) {
      history.push(link);
    }
  }, [companyKey]);
};

export const useIsCompanyKeyDifferent = entity =>
  useSelector(state => state[entity].meta.companyKey) !== useCurrentCompanyKey();

export const useDeletedSubCompanies = flagForApiCall => {
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const companyDeleted = useSelector(state => state.deletedSubCompanies.list);
  const isFetching = useSelector(state => state.deletedSubCompanies.meta.isFetching);
  const isListEmpty = useSelector(state => state.deletedSubCompanies.meta.isListEmpty);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent('deletedSubCompanies');

  useEffect(() => {
    if (
      !!userKey &&
      !isFetching &&
      (isCompanyKeyDifferent || (companyDeleted.length === 0 && !isListEmpty)) &&
      flagForApiCall
    ) {
      dispatch(fetchDeletedSubCompanies());
    }
  }, [userKey, flagForApiCall, isCompanyKeyDifferent]);

  return useMemo(() => {
    return companyDeleted.filter(i => !!i.parent);
  }, [companyDeleted]);
};

export const useParentCompanies = () => {
  const activeCompanies = useSelector(state => state.companies.subCompaniesList);
  const deletedSubCompanies = useSelector(state => state.deletedSubCompanies.list);

  return useMemo(() => {
    let allCompanies = [...activeCompanies, ...deletedSubCompanies];
    let parentCompanies = allCompanies.filter(i => i.parent === undefined);
    let allParentCompaniesId = allCompanies.filter(i => !!i.parent?.id).map(i => i.parent?.id);

    parentCompanies = Array.from(
      new Map(
        [
          ...parentCompanies,
          ...allCompanies.filter(i => allParentCompaniesId.includes(i.id))
        ].map(item => [item.id, item])
      ).values()
    );

    return parentCompanies;
  }, [deletedSubCompanies, activeCompanies]);
};

export const useActiveSubCompanies = () => {
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const subCompanies = useSelector(state => state.companies.subCompaniesList);
  const isFetching = useSelector(state => state.companies.meta.isFetching);
  const companies = useSelector(state => state.companies.subCompaniesList);
  const currentCompanyId = useSelector(state => state.companies.current.id);

  useEffect(() => {
    if (!!userKey && !isFetching && subCompanies.length === 0 && !!currentCompanyId) {
      dispatch(fetchSubCompanies(currentCompanyId));
    }
  }, [userKey, currentCompanyId]);

  return useMemo(() => {
    let parentCompanies = companies.filter(i => i.parent !== undefined);

    return parentCompanies;
  }, [companies]);
};

export const deleteCompanyApi = (data, history) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  const { id, name } = data;
  const url = `/companies/${id}`;
  try {
    const currentCompanyId = getState().companies.current.id;
    const response = await api.delete(url, { authKey });
    if (response && response.ok) {
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('SubCompanies.Notifications.Delete', { name })
        })
      );
      dispatch(fetchSubCompanies(currentCompanyId));
      dispatch(fetchDeletedSubCompanies());
      dispatch(fetchCompanies());
      history && canHistoryGoBack(history, '/settings/subcompanies');
    }
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${name} could not be deleted: ${err}`
      })
    );
  }
};

export const restoreCompanyApi = data => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const currentCompanyId = getState().companies.current.id;
  const { id, name } = data;
  const url = `${API_PATH}/companies/${id}/restore`;
  request('PUT', url)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(resp => {
      if (resp.ok) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('SubCompanies.Notifications.Restore', { name })
          })
        );
        dispatch(fetchSubCompanies(currentCompanyId));
        dispatch(fetchDeletedSubCompanies());
        dispatch(fetchCompanies());
      }
    })
    .catch(err => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
      );
    });
};

export const saveCompanyApi = (method, payload) => async (dispatch, getState) => {
  const authKey = getState().user.current.auth.key;
  let url = `/companies`;

  if (!!payload.id) {
    url += '/' + payload.id;
  }

  try {
    const apiMethod = method === 'POST' ? api.post : api.put;
    const response = await apiMethod(url, { authKey }, payload);

    if (!response || response.status !== 200) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('SubCompanies.Notifications.Error')
        })
      );
    }

    return response.body || [];
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: parseUserErrorMessage(err)
      })
    );
  }
};
