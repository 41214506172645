import { Tooltip } from 'antd';
import React from 'react';
import styles from './Pill.module.scss';

export const PillColor = {
  Green: '',
  Yellow: styles.yellow,
  Red: styles.red,
  Blue: styles.blue
};

export function Pill({ value, max, pillColor = PillColor.Green, tooltip, legend }) {
  max = Math.max(1, max);
  const percent = Math.min(1, parseFloat((value / max).toFixed(2))) * 100;

  return (
    <Tooltip title={tooltip}>
      <div className={styles.barChart}>
        <div className={styles.bar + ' ' + pillColor}>
          <span
            className={percent === 100 ? styles.fullBar : styles.progressBar}
            style={{ width: percent + '%' }}
          ></span>
        </div>
        {legend && typeof legend !== 'function' && <p>{legend}</p>}
        {legend && typeof legend === 'function' && legend()}
      </div>
    </Tooltip>
  );
}
