import { EnrolmentStatuses, ColumnKeys, ENROLMENTS_TABLE_COLUMNS } from '../constants';
import { format } from 'utils/dates';
import { convertEnrolmentIDWithPrefixAndZeroPadding } from '../helpers';

export const prepareColumnsForTable = translate =>
  ENROLMENTS_TABLE_COLUMNS.map(column => ({
    ...column,
    title: translate(`TCAEnrolments.${column.key}`)
  }));

export const prepareFileForExcelExport = data => {
  const { filteredEnrolments, localization, t } = data;

  const rows = filteredEnrolments
    ? filteredEnrolments.map(enrolment => {
        const operatorName = enrolment?.companyDetails
          ? JSON.parse(enrolment?.companyDetails)?.identity?.operatorName || ''
          : '';

        const enrolmentID = convertEnrolmentIDWithPrefixAndZeroPadding(enrolment?.id);

        const row = {
          [t(`TCAEnrolments.${ColumnKeys.STATUS}`)]:
            enrolment.status === EnrolmentStatuses.APPROVED
              ? t('TCAEnrolments.Approved')
              : t('TCAEnrolments.Cancelled'),
          [t(`TCAEnrolments.${ColumnKeys.ENROLMENT_ID}`)]: enrolmentID,
          [t(`TCAEnrolments.${ColumnKeys.SCHEME}`)]: enrolment.scheme?.id,
          [t(`TCAEnrolments.${ColumnKeys.COMPANY_NAME}`)]: enrolment.company?.name,
          [t(`TCAEnrolments.${ColumnKeys.OPERATOR_NAME}`)]: operatorName,
          [t(`TCAEnrolments.${ColumnKeys.DEVICE}`)]: enrolment.deviceDetails?.imei,
          [t(`TCAEnrolments.${ColumnKeys.VEHICLE_REGISTRATION}`)]: enrolment.vehicleRegistration,
          [t(`TCAEnrolments.${ColumnKeys.APPROVAL_DATE}`)]:
            (enrolment.startedAt &&
              format(new Date(enrolment.startedAt), localization.formats.time.formats.dby_imp)) ||
            '',
          [t(`TCAEnrolments.${ColumnKeys.PREVIOUS_ENROLMENT}`)]: enrolment.previousId || '',
          [t(`TCAEnrolments.${ColumnKeys.CANCELLATION_DATE}`)]:
            (enrolment.cancelledAt &&
              format(new Date(enrolment.cancelledAt), localization.formats.time.formats.dby_imp)) ||
            ''
        };

        return row;
      })
    : [];

  return rows;
};
