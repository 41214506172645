import { Button, Form, Modal, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ToastType } from 'components/notifications/toasts/Toast';
import { usePrevious } from 'utils/methods';

import { useCurrentCompany } from 'features/company/companySlice';
import { openToast } from 'features/toasts/toastsSlice';

import { modalFormProps } from './constants';
import styles from './UserDefinedModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export function UserDefinedModal({ modalDetails, onClose, show, title }) {
  const [form] = Form.useForm();
  const {
    initialValues,
    fetchHook,
    formElements,
    formName,
    type,
    updateAction,
    hideTitle = false,
    onSuccessActions = []
  } = modalFormProps[modalDetails];
  const { t } = useTranslation();
  const { Title } = Typography;
  const company = useCurrentCompany();
  const dispatch = useDispatch();
  const userDefinedFieldsValues = fetchHook(company.id);
  const previousUserDefinedFieldsValues = usePrevious(userDefinedFieldsValues);

  const [isFormTouched, setIsFormTouched] = useState(false);

  const showNotification = (toastType, error) => {
    if (onClose) {
      onClose();
    }
    return openToast({
      type: toastType,
      message:
        toastType === ToastType.Success
          ? t('CompanyConfig.Notifications.UserDefinedSuccess', { name: title })
          : t('CompanyConfig.Notifications.UserDefinedError', { error })
    });
  };

  const handleCancel = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleSave = () => {
    form.submit();
  };

  const handleSubmit = values => {
    dispatch(
      updateAction({
        body: {
          Config: JSON.stringify(values)
        },
        type,
        companyId: company.id,
        onSuccessActions,
        successToaster: () => showNotification(ToastType.Success),
        errorToaster: error => showNotification(ToastType.Error, error)
      })
    );
  };

  const handleValuesChanged = () => {
    const checkFieldsValue =
      JSON.stringify(form.getFieldsValue()) !== JSON.stringify(userDefinedFieldsValues);
    setIsFormTouched(checkFieldsValue);
  };

  useEffect(() => {
    if (
      JSON.stringify(previousUserDefinedFieldsValues) !== JSON.stringify(userDefinedFieldsValues)
    ) {
      form.setFieldsValue(userDefinedFieldsValues);
    }
  }, [userDefinedFieldsValues]);

  const modalFooter = [
    <div key="modalFooter">
      <Button
        id={BUTTON_IDS.userDefinedSave}
        disabled={!isFormTouched}
        onClick={handleSave}
        type="primary"
      >
        {t('Common.SaveButton')}
      </Button>
      <Button id={BUTTON_IDS.userDefinedCancel} onClick={handleCancel}>
        {t('Common.CancelButton')}
      </Button>
    </div>
  ];

  return (
    <Modal
      centered
      className={styles.userDefinedModal}
      footer={modalFooter}
      onCancel={handleCancel}
      title={title}
      open={show}
    >
      <Form
        className={styles.form}
        form={form}
        initialValues={initialValues}
        name={formName}
        onFinish={handleSubmit}
        onValuesChange={handleValuesChanged}
      >
        {!hideTitle && (
          <Title className={styles.subTitle} level={5}>
            {t('CompanyConfig.UserDefinedFields.Title').toUpperCase()}
          </Title>
        )}
        {formElements.map(e => (
          <Form.Item
            {...e.formItemProps}
            key={e.label || e.name}
            label={t(e.label)}
            name={e.name}
            rules={e.rules}
          >
            {<e.component placeholder={t(e.placeholder)} {...e.props} />}
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
}
