import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useDevices } from 'features/fleets/fleetsSlice';
import { useDeletedDevices } from 'features/devices/devicesDeletedSlice';
import { useCompanies } from 'features/company/companySlice';
import { useCan, entities } from 'features/permissions';
import { PATHS as DevicePath } from 'containers/Administration/Devices/constants';
import { BULK_EDIT_AUDIT_ENTITY } from '../../helpers';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';

import { EllipsisContent } from './EllipsisContent';

export const EntityListCell = ({ entity, entities = [] }) => (
  <EllipsisContent>
    {entities.map(entityData => (
      <EntityName
        key={entityData.id}
        entity={entity}
        entityId={entityData.id}
        entityName={entityData.name}
        entityCompanyId={entityData.companyId}
      />
    ))}
  </EllipsisContent>
);

const EntityName = ({ entity, entityId, entityName, entityCompanyId }) => {
  switch (entity) {
    case BULK_EDIT_AUDIT_ENTITY.DEVICE:
    case AUDIT_ENTITY.CAMERA_VEHICLE_DEVICE_ASSOCIATION:
      return (
        <DeviceName deviceId={entityId} deviceName={entityName} deviceCompanyId={entityCompanyId} />
      );
    default:
      return null;
  }
};

const DeviceName = ({ deviceId, deviceName, deviceCompanyId }) => {
  const { t } = useTranslation();
  const can = useCan();
  const canViewDevice = can({ oneOfEntities: [entities.DEVICE_VIEW] });
  const devices = useDevices();
  const deletedDevices = useDeletedDevices();
  const companies = useCompanies();
  const { canView, name, id, companyName } = useMemo(() => {
    const deviceFromCurrentCompany = [...devices, ...deletedDevices].find(
      d => String(d.id) === String(deviceId)
    );
    const companyName = companies.find(comp => String(comp.id) === String(deviceCompanyId))?.name;
    const id = deviceFromCurrentCompany?.id || deviceId;
    return {
      canView: deviceFromCurrentCompany?.id && canViewDevice,
      id,
      name: deviceFromCurrentCompany?.name || deviceName || (id ? `id : ${id}` : ''),
      companyName: companyName
        ? `${t('Devices.Company')} : ${companyName}`
        : deviceCompanyId
        ? `${t('Devices.Company')} id : ${deviceCompanyId}`
        : ''
    };
  }, [t, devices, deletedDevices, deviceId, deviceName, deviceCompanyId, companies, canViewDevice]);
  return (
    <div>
      {canView ? (
        <Link to={`${DevicePath.VIEW_DEVICE}/${id}`}>{name}</Link>
      ) : (
        <Tooltip title={companyName}>{name}</Tooltip>
      )}
    </div>
  );
};
