/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Vehicle from '../model/Vehicle';

/**
* Dashboard service.
* @module api/DashboardApi
* @version 1.0
*/
export default class DashboardApi {

    /**
    * Constructs a new DashboardApi. 
    * @alias module:api/DashboardApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the stats operation.
     * @callback module:api/DashboardApi~statsCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Vehicle>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Look up dashboard information
     * A token key is required for this call.
     * @param {String} key API key for user/company
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.pruning 
     * @param {module:api/DashboardApi~statsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Vehicle>}
     */
    stats(companyId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'key' is set
      if (companyId === undefined || companyId === null) {
        throw new Error("Missing the required parameter 'companyId' when calling stats");
      }

      let pathParams = {
      };
      let queryParams = {
        'pruning': opts['pruning'],
        'company_id': companyId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Vehicle];
      return this.apiClient.callApi(
        '/dashboard', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
