import React from 'react';
import i18n from 'i18next';

import { Input, Select } from 'components/ant';
import { InputNumber } from 'antd';
import {
  JobItems,
  UNITS,
  JOB_TYPES,
  LocationType,
  JobsColumnKeys,
  MAX_INT_VALUE
} from '../utils/constants';
import { GeoSuggest } from 'components/GeoSuggest';

import style from './EditJob.module.scss';

export const JobKeys = {
  DETAILS: 'job_details',
  ITEMS: 'job_items'
};

export const columnComponents = (
  data,
  isLocationTypeAddress,
  callbacks = {},
  defaultValue,
  t,
  localization
) => {
  return {
    [JobItems.NAME.key]: <Input size="large" placeholder={t('SmartJobs.Input.Placeholder.Name')} />,
    [JobItems.JOB_TYPE.key]: (
      <Select
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.SelectOption')}
        data={JOB_TYPES.map(item => ({
          ...item,
          label: t(`SmartJobs.Input.JobType.${item.label}`)
        }))}
      />
    ),
    [JobItems.LOCATION_TYPE.key]: (
      <Select
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.SelectOption')}
        data={[
          { id: LocationType.CUSTOMER, label: t('SmartJobs.Input.LocationType.Customer') },
          { id: LocationType.ADDRESS, label: t('SmartJobs.Input.LocationType.Address') }
        ]}
        onChange={() => {}}
      />
    ),
    [JobItems.LOCATION.key]: isLocationTypeAddress ? (
      <GeoSuggest
        autoComplete="off"
        className={style.geoSuggestContainer}
        inputClassName={style.geoSuggestInputs}
        onSuggestSelect={suggest => {
          callbacks.updateLocation(suggest, true);
        }}
        initialValue={defaultValue}
      />
    ) : (
      <Select
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.SelectOption')}
        data={data}
        // defaultValue={defaultValue}
        onChange={value => callbacks.updateLocation(value)}
      />
    ),
    [JobItems.NOTES.key]: (
      <Input.TextArea
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.WriteSomething')}
        rows={6}
      />
    ),
    // items
    [JobItems.QUANTITY.key]: (
      <InputNumber
        size="large"
        className={style.inputNumber}
        placeholder={t(`SmartJobs.Input.Placeholder.${JobItems.QUANTITY.name}`)}
        min={0}
      />
    ),
    [JobItems.ITEM_TYPE.key]: (
      <Select
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.SelectOption')}
        data={data}
      />
    ),
    [JobItems.WEIGHT.key]: (
      <InputNumber
        size="large"
        className={style.inputNumber}
        placeholder={t(`SmartJobs.Input.Placeholder.${JobItems.WEIGHT.name}`)}
        min={0}
      />
    ),
    [JobItems.UNIT.key]: (
      <Select
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.SelectOption')}
        data={UNITS.map(unit => ({
          id: localization.formats.weight[unit.id]?.unit,
          label: t(`SmartJobs.Input.Unit.${localization.formats.weight[unit.id]?.unit}`)
        }))}
        onChange={() => {}}
      />
    ),
    [JobItems.DESCRIPTION.key]: (
      <Input.TextArea
        size="large"
        placeholder={t('SmartJobs.Input.Placeholder.WriteSomething')}
        rows={6}
        maxLength="150"
      />
    )
  };
};

export const getFormColumn = (
  config,
  required,
  validationMessage,
  data,
  isAddress,
  callbacks = {},
  defaultValue,
  max,
  maxValueMessage,
  span = 12,
  translate,
  localization
) => ({
  span,
  style: { textAlign: 'right' },
  item: {
    label: translate(`SmartJobs.Input.Label.${config.key}`),
    labelAlign: 'left',
    key: config.key,
    component: columnComponents(data, isAddress, callbacks, defaultValue, translate, localization)[
      config.key
    ],
    other: {
      rules: [
        required && {
          required: true,
          message: validationMessage
        },
        max !== undefined && {
          max: max,
          message: maxValueMessage,
          type: 'number'
        }
      ].filter(Boolean)
    }
  }
});

export const getFormRows = (
  type = JobKeys.DETAILS,
  data = {},
  translate = n => n,
  localization,
  locationType,
  callbacks = {},
  defaultValues = {}
) => {
  const isLocationTypeAddress = locationType === LocationType.ADDRESS;
  // Details rows config
  const detailsRows = [
    {
      columns: [
        { item: JobItems.NAME, required: true, message: i18n.t('SmartJobs.JobDetails.selectName') },
        {
          item: JobItems.JOB_TYPE,
          required: true,
          message: i18n.t('SmartJobs.JobDetails.selectJobType')
        }
      ]
    },
    {
      columns: [
        {
          item: JobItems.LOCATION_TYPE,
          required: true,
          message: i18n.t('SmartJobs.JobDetails.selectLocationType')
        },
        {
          item: JobItems.LOCATION,
          required: true,
          message: i18n.t('SmartJobs.JobDetails.addLocation'),
          data: data.locations,
          isLocationTypeAddress,
          callbacks,
          defaultValue: defaultValues.location
        }
      ]
    },
    {
      columns: [
        {
          item: JobItems.NOTES
        }
      ]
    }
  ];
  // Items rows config

  const itemsRows = [
    {
      columns: [
        {
          item: JobItems.QUANTITY,
          required: true,
          message: i18n.t('SmartJobs.JobDetails.selectQuantity'),
          max: MAX_INT_VALUE,
          maxValueMessage: i18n.t('SmartJobs.JobDetails.quantityMaxValue', {
            maxValue: MAX_INT_VALUE
          })
        },
        {
          item: JobItems.ITEM_TYPE,
          required: true,
          message: i18n.t('SmartJobs.JobDetails.selectItemType'),
          data: data.types
        }
      ]
    },
    {
      columns: [
        {
          item: JobItems.WEIGHT,
          max: MAX_INT_VALUE,
          maxValueMessage: i18n.t('SmartJobs.JobDetails.weightMaxValue', {
            maxValue: MAX_INT_VALUE
          })
        },
        {
          item: JobItems.UNIT
        }
      ]
    },
    {
      columns: [{ item: JobItems.DESCRIPTION }]
    }
  ];
  // Render the correct rows
  const rows = type === JobKeys.DETAILS ? detailsRows : itemsRows;

  return rows.map(row => {
    return {
      columns: row.columns.map(column => {
        const span = [JobItems.NOTES.key, JobItems.DESCRIPTION.key].includes(column.item.key)
          ? 24
          : 12;

        return getFormColumn(
          column.item,
          column.required,
          column.message,
          column.data,
          column.isLocationTypeAddress,
          column.callbacks,
          column.defaultValue,
          column.max,
          column.maxValueMessage,
          span,
          translate,
          localization
        );
      })
    };
  });
};

// Creates a label for different types of locations
export const getLocationLabel = (job = {}) => {
  if (!job || !job.stop) {
    return;
  }

  const {
    stop: { location, descrpition }
  } = job;

  // If the location is CUSTOMER type - return location id
  if (location?.type?.code === LocationType.CUSTOMER) {
    return location.id;
  }

  // If the location is ADDRESS type - return the label
  if (location?.type?.code === LocationType.ADDRESS) {
    return location?.name;
  }
  return descrpition;
};

/**
 * Helper method that checks if the Job Form has been changed
 */
export const isFormDirty = (currentJob, newJob) => {
  return Object.keys(newJob).some(key =>
    key !== JobsColumnKeys.ITEMS
      ? currentJob[key] !== newJob[key]
      : newJob.jobItems.length !== currentJob.jobItems.length
  );
};
