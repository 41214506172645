import React, { useCallback, useMemo } from 'react';
import { Button, Tooltip } from 'antd';
import { sortBy } from 'lodash';
import * as XLSX from 'xlsx';
import { useCurrentCompany } from 'features/company/companySlice';
import { useIQCameraDevicesConfig } from 'features/company_config';
import { useDevices, useIsFetching, useVehicles } from 'features/fleets/fleetsSlice';
import { FeatureFlag, useCan } from 'features/permissions';
import { isStandaloneDevice, Form_Field_Type, TABS } from './helper';
import { isIQCamera } from 'features/camera/CameraModelConfig';
import { BUTTON_IDS } from 'utils/globalConstants';

export const ExportIQConfigButton = ({ t, companyConfig: config, localization }) => {
  const can = useCan();

  const currentCompany = useCurrentCompany();
  const isFetchingFleets = useIsFetching();
  const vehicles = useVehicles();
  const devices = useDevices();

  const { deviceIds, iqStandaloneDevices } = useMemo(() => {
    const { iqStandaloneDevices, allIQDeviceIds } = (devices || []).reduce(
      (a, device) => {
        if (!!device.id && isIQCamera(device)) {
          a.allIQDeviceIds[device.id] = true;
          if (isStandaloneDevice(device)) {
            a.iqStandaloneDevices[device.id] = {
              id: device.id,
              name: device.name,
              fleets: device.fleetInfo.reduce(
                (a, fleet) => `${a}${fleet?.name ? fleet.name + ',' : ''}`,
                ''
              ),
              serialNumber: device.serialNumber,
              modelName: device.model?.name
            };
          }
        }
        return a;
      },
      { iqStandaloneDevices: {}, allIQDeviceIds: {} }
    );
    return {
      iqStandaloneDevices,
      deviceIds: Object.keys(allIQDeviceIds)
    };
  }, [devices]);

  const { iqCameraDevicesConfig, isFetching } = useIQCameraDevicesConfig({
    companyId: currentCompany?.id,
    deviceIds
  });

  const { iqDevices, iqVehicles } = useMemo(() => {
    return {
      iqDevices: sortBy(
        Object.values(iqStandaloneDevices).map(d => ({
          ...d,
          dutyType: t(
            `CompanyConfig.IQCamera.${iqCameraDevicesConfig?.[d.id]?.config?.dutyType || 'light'}`
          ),
          deviceAudioVolume: iqCameraDevicesConfig?.[d.id]?.config?.deviceAudioVolume
        })),
        ['name']
      ),
      iqVehicles: sortBy(
        (vehicles || []).reduce((a, vehicle) => {
          const vehicleIQDevice = vehicle?.devices?.find(isIQCamera);
          if (!!vehicle.id && vehicleIQDevice) {
            return [
              ...a,
              {
                id: vehicle.id,
                name: vehicle.name,
                registration: vehicle.registration,
                typeName: vehicle.type.name,
                iqDeviceName: vehicleIQDevice.name,
                iqDeviceModelName: vehicleIQDevice.model?.name,
                iqDeviceDutyType: t(
                  `CompanyConfig.IQCamera.${iqCameraDevicesConfig?.[vehicleIQDevice.id]?.config
                    ?.dutyType || 'light'}`
                )
              }
            ];
          }
          return a;
        }, []),
        ['name']
      )
    };
  }, [vehicles, iqStandaloneDevices, iqCameraDevicesConfig]);

  const onExport = useCallback(
    () =>
      exportConfigSetting(config, iqDevices, iqVehicles, currentCompany.name, {
        t,
        localization,
        can
      }),
    [config, t, iqDevices, iqVehicles, localization, currentCompany]
  );
  return (
    <Tooltip title={t('CompanyConfig.IQCamera.ExportDutyTypeToolTip')}>
      <Button
        id={BUTTON_IDS.iqCameraPresetsExport}
        size="medium"
        disabled={isFetchingFleets || isFetching || !currentCompany?.id || !iqCameraDevicesConfig}
        onClick={onExport}
      >
        {t('CompanyConfig.IQCamera.ExportDutyType')}
      </Button>
    </Tooltip>
  );
};

const exportConfigSetting = (
  config,
  devices,
  vehicles,
  companyName,
  convertors = { t: v => v, localization: v => v, can: () => false }
) => {
  const { t, localization, can } = convertors;
  const commonConfigColNames = {
    eventName: t('Tabs.events'),
    dutyType: t('Devices.ActualForm.CameraSensitivity'),
    eventControl: t('CompanyConfig.IQCamera.MediaControls'),
    enabled: t('Vehicles.View.Status')
  };

  const { basicConfig, advancedConfig, basicConfigColumns, advancedConfigColumns } = Object.keys(
    config
  )?.reduce(
    (a, dutyType) => {
      const generateConfigRows = (dutyConfig, isAdvancedConfig = false) => {
        const dutyEvents = Object.values(dutyConfig);
        for (const dutyEvent of dutyEvents) {
          const eventName = dutyEvent.header?.getText
            ? dutyEvent.header.getText(t, localization, can, true)
            : String(dutyEvent.header);
          const eventConfigs = {},
            configColumns = {};
          const generateConfigRow = eventField => {
            let eventFieldName = eventField.label?.getText
                ? eventField.label.getText(t, localization, can, true)
                : String(eventField.label),
              eventFieldValue =
                typeof eventField.value === 'function'
                  ? eventField.value(localization)
                  : eventField.value;

            if (eventField.isSwitch || eventField.fieldType === Form_Field_Type.Switch) {
              eventFieldName = commonConfigColNames.enabled;
              eventFieldValue = eventField.value
                ? t('Common.AttachmentStatus.ENABLED')
                : t('Common.AttachmentStatus.DISABLED');
            } else if (eventField.selection) {
              if (eventField?.name.includes('eventControl')) {
                eventFieldName = commonConfigColNames.eventControl;
              } else if (
                eventField.isExtra &&
                /(threshold|allowance)$/i.test(eventField?.name?.[0])
              ) {
                eventFieldName = t('GeofencesFeature.Tolerance');
              }
              const selected = eventField?.selection.find(
                opt => opt.value === eventField.value || opt.id === eventField.value
              );
              eventFieldValue = selected?.getText
                ? selected.getText(t, localization, can, true)
                : selected?.label;
            }
            return { eventFieldName, eventFieldValue };
          };
          for (const eventField of dutyEvent.fields || []) {
            const { eventFieldName, eventFieldValue } = generateConfigRow(eventField);
            eventConfigs[eventFieldName] = eventFieldValue;
            configColumns[eventFieldName] = true;
          }
          const dutyEventSubConfig = dutyEvent?.subConfigs || {};
          if (Object.values(dutyEventSubConfig).length) {
            generateConfigRows(dutyEventSubConfig, dutyEvent.isAdvanced);
          }
          const eventConfigsRow = {
            [commonConfigColNames.eventName]: eventName,
            [commonConfigColNames.dutyType]: dutyType
              ? t(`CompanyConfig.IQCamera.${dutyType}`)
              : '',
            ...eventConfigs
          };
          if (dutyEvent.isAdvanced || isAdvancedConfig) {
            a.advancedConfig[dutyType] = a.advancedConfig[dutyType] || [];
            a.advancedConfig[dutyType].push(eventConfigsRow);
            a.advancedConfigColumns = { ...a.advancedConfigColumns, ...configColumns };
          } else {
            a.basicConfig[dutyType] = a.basicConfig[dutyType] || [];
            a.basicConfig[dutyType].push(eventConfigsRow);
            a.basicConfigColumns = { ...a.basicConfigColumns, ...configColumns };
          }
        }
      };
      generateConfigRows(config[dutyType]);
      return a;
    },
    {
      basicConfig: {},
      advancedConfig: {},
      basicConfigColumns: {
        [commonConfigColNames.eventName]: true,
        [commonConfigColNames.dutyType]: true
      },
      advancedConfigColumns: {
        [commonConfigColNames.eventName]: true,
        [commonConfigColNames.dutyType]: true
      }
    }
  );
  const layoutSheet = (config, columns) =>
    Object.keys(config).reduce(
      (a, dutyType) => [
        ...a,
        ...config[dutyType].map(row =>
          columns.reduce((a, colName) => ({ ...a, [colName]: row[colName] || '-' }), {})
        )
      ],
      []
    );

  const workbook = XLSX.utils.book_new();

  const basicWorksheet = XLSX.utils.json_to_sheet(
    layoutSheet(
      basicConfig,
      sortBy(Object.keys(basicConfigColumns), colName =>
        Object.values(commonConfigColNames).some(commonName => commonName === colName) ? -1 : 1
      )
    )
  );
  const advanceWorksheet = XLSX.utils.json_to_sheet(
    layoutSheet(advancedConfig, Object.keys(advancedConfigColumns))
  );

  XLSX.utils.sheet_add_aoa(
    advanceWorksheet,
    [
      Object.keys(advancedConfigColumns).map(colName =>
        colName === commonConfigColNames.eventName ? '' : colName
      )
    ],
    {
      origin: 'A1'
    }
  );

  const devicesWorksheet = XLSX.utils.json_to_sheet(
    devices.map(u => ({
      [t('Devices.Table.NameLabel')]: u.name,
      [t('Devices.Table.ImeiLabel')]: u.imei,
      [t('Vehicles.Table.Fleets')]: u.fleets,
      [t('Devices.ActualForm.SerialNumberLabel')]: u.serialNumber,
      ...(can({ oneOfFeatureFlags: [FeatureFlag.evoPhase1.flag, FeatureFlag.evoPhaseTGE.flag] })
        ? { [t('Common.Model')]: u.modelName }
        : {}),
      [commonConfigColNames.dutyType]: u.dutyType
    }))
  );
  const vehiclesWorksheet = XLSX.utils.json_to_sheet(
    vehicles.map(u => ({
      [t('Vehicles.View.Name')]: u.name,
      [t('Vehicles.View.Registration')]: u.registration,
      [t('Vehicles.Table.Type')]: u.typeName,
      [t('Entity.Device')]: u.iqDeviceName,
      ...(can({ oneOfFeatureFlags: [FeatureFlag.evoPhase1.flag, FeatureFlag.evoPhaseTGE.flag] })
        ? { [t('Devices.DeviceModel')]: u.iqDeviceModelName }
        : {}),
      [commonConfigColNames.dutyType]: u.iqDeviceDutyType
    }))
  );

  XLSX.utils.book_append_sheet(
    workbook,
    basicWorksheet,
    t(`CompanyConfig.IQCamera.${TABS.BASIC}.Title`)
  );
  XLSX.utils.book_append_sheet(
    workbook,
    advanceWorksheet,
    t(`CompanyConfig.IQCamera.${TABS.ADVANCED}.Title`)
  );
  XLSX.utils.book_append_sheet(workbook, vehiclesWorksheet, t('Common.Vehicles'));
  XLSX.utils.book_append_sheet(workbook, devicesWorksheet, t('Common.Devices'));

  XLSX.writeFile(
    workbook,
    `${t('Entity.Company Configuration')} - ${t('CompanyConfig.IQCamera.Title')} - ${companyName ||
      ''}.xlsx`
  );
};
