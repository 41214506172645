import React, { useCallback, useEffect, useState } from 'react';
import { Select, Row, Col, Space } from 'antd';
import styles from './DriverLog.module.scss';
import { ViolationView } from './ViolationView';
import { sortBy } from 'lodash';
import * as moment from 'moment';
import i18next from 'i18nextConfig';
import { useTranslation } from 'react-i18next';

const sortList = [
  {
    get title() {
      return i18next.t('ELD.Violation Name');
    },
    dataKey: 'name',
    default: true,
    sortFn: o => moment(o.rule?.id)
  },
  {
    get title() {
      return i18next.t('ELD.Violation Description');
    },
    dataKey: 'description',
    sortFn: o => moment(o.rule?.description)
  },
  {
    get title() {
      return i18next.t('ELD.Event Start');
    },
    dataKey: 'startPeriod',
    sortFn: o => moment(o.period?.start)
  },
  {
    get title() {
      return i18next.t('ELD.Event End');
    },
    dataKey: 'endPeriod',
    sortFn: o => moment(o.period?.finish)
  } /*
  {
    title: 'Worked Time',
    dataKey: 'workedTime'
  },
  {
    title: 'Unalloc Time',
    dataKey: 'unalloc'
  },
  {
    title: 'Total Reset',
    dataKey: 'totalRest'
  }*/
];

export function ViolationList({ data, onRowMouseEnter, onRowMouseLeave, dateRange, ...props }) {
  const { t } = useTranslation();
  const [violations, setViolations] = useState([]);

  useEffect(() => {
    if (data?.violations != null) {
      const dateFilterStart = moment(dateRange || new Date()).startOf('day');
      const filteredViolations = data.violations.filter(v => moment(v.finish) >= dateFilterStart);
      setViolations(sortBy(filteredViolations, ['name']));
    } else {
      setViolations([]);
    }
  }, [dateRange, data]);

  const handleSortChanged = useCallback(
    value => {
      if (violations != null) {
        const sortObj = sortList.find(s => s.dataKey === value);
        if (sortObj.sortFn != null) {
          setViolations(sortBy(violations, [sortObj.sortFn]));
        } else {
          setViolations(sortBy(violations, [value]));
        }
      }
    },
    [violations]
  );

  return (
    <Row className={styles.violationContainer + ' showScrollbarsOnHover'}>
      <Col span={24}>
        <Space className={styles.violaitonToolbar}>
          <Select
            defaultValue={'name'}
            onChange={handleSortChanged}
            optionLabelProp="label"
            placeholder={t('ELD.Sort By')}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            {sortList.map((s, idx) => (
              <Select.Option key={idx} value={s.dataKey} label={t('ELD.Sort By') + ' ' + s.title}>
                {s.title}
              </Select.Option>
            ))}
          </Select>
        </Space>
      </Col>
      <Col span={24}>
        {violations.map((v, idx) => (
          <ViolationView
            onMouseEnter={event => onRowMouseEnter && onRowMouseEnter(v, idx, event)}
            onMouseLeave={event => onRowMouseLeave && onRowMouseLeave(v, idx, event)}
            key={idx}
            data={v}
            ruleSet={data?.ruleset?.rules || []}
          />
        ))}
      </Col>
    </Row>
  );
}
