import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

//components
import AntMultiselect, {
  formatListForMultiselect
} from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { Table, Layout } from 'components/ant';
import { Button } from 'antd';
import { Can, useCan, entities } from 'features/permissions';

//hooks
import useDebounce from 'utils/hooks/useDebounce';

//helpers
import {
  prepareWiFisDataForTable,
  prepareColumnsForTable,
  filteredWifisForTable,
  filterFleetsBySelectedCompanies,
  setTitleForDropdownsFilters
} from './utils/helpers';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { trim } from 'lodash';

//constants
import { PATHS } from './utils/constants';

//slices
import { useIsFetching as useIsFetchingFleets } from 'features/fleets/fleetsSlice';
import { useIsFetching as useIsFetchingWiFi } from 'features/wifi';

// styles
import styles from './Wifi.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const Wifi = ({ wifi, subCompanies, fleets }) => {
  const { t } = useTranslation();
  const can = useCan();
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoadingFleets = useIsFetchingFleets();
  const isLoadingWiFi = useIsFetchingWiFi();
  const [filterText, setFilterText] = useState('');
  const debouncedSearchText = useDebounce(trim(filterText), 300);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [filterFleets, setFilterFleets] = useState([]);
  const filterCompanyIds = filterCompanies
    .filter(company => company.checked)
    .map(comp => parseInt(comp.id, 10));
  const filterFleetIds = filterFleets
    .filter(fleet => fleet.checked)
    .map(fleet => parseInt(fleet.id, 10));

  // //filter wifis based by search and dropdown filters
  const filteredWifis = filteredWifisForTable(wifi, {
    searchText: debouncedSearchText,
    companyIds: filterCompanyIds,
    fleetIds: filterFleetIds
  });

  //useEffects for filtering dropdowns
  useEffect(() => {
    setFilterCompanies(
      formatListForMultiselect([{ id: 0, name: `${t('Common.AllCompanies')}` }, ...subCompanies])
    );
  }, [subCompanies, t]);

  //filter fleets based on filtered companies
  useEffect(() => {
    const formattedFleets = filterFleetsBySelectedCompanies(
      fleets,
      filterCompanies,
      formatListForMultiselect,
      t
    );
    setFilterFleets(formattedFleets);
  }, [fleets, filterCompanies, history, t]);

  // Set page title and back button
  useEffect(() => {
    dispatch(setPageTitle(t('WiFi.GridPage.ListingWiFi')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  //filter functions
  function handleFilterTextChange(newFilter) {
    setFilterText(newFilter);
  }

  function handleFilteredCompaniesChange(newFilter) {
    setFilterCompanies(newFilter);
  }

  function handleFilteredFleetsChange(newFilter) {
    setFilterFleets(newFilter);
  }

  return (
    <div className={styles.wifiContainer}>
      <div className={styles.headerActions}>
        <Can everyEntity={[entities.WIFI_CREATE]}>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              history.push(PATHS.ADD_NEW_WIFI);
            }}
            disabled={isLoadingFleets}
            id={BUTTON_IDS.wifiAddNew}
          >
            {t('WiFi.GridPage.AddNewWiFi')}
          </Button>
        </Can>
      </div>
      <Layout.Content>
        <div className={styles.filterWrapper}>
          <AntSearchbar
            className={styles.searchBar}
            size={'medium'}
            onFilter={handleFilterTextChange}
          />
          <AntMultiselect
            title={setTitleForDropdownsFilters(
              filterCompanies,
              subCompanies,
              t('Common.AllCompanies'),
              t('Common.Companies')
            )}
            data={filterCompanies}
            onFilter={handleFilteredCompaniesChange}
          />
          <AntMultiselect
            title={setTitleForDropdownsFilters(
              filterFleets,
              fleets,
              t('Common.AllFleets'),
              t('Common.Fleets')
            )}
            data={filterFleets}
            onFilter={handleFilteredFleetsChange}
          />
          <div className={styles.wifiCounter}>{`${filteredWifis.length} ${t('WiFi.WiFi')}`}</div>
        </div>
        <Table
          dataSource={prepareWiFisDataForTable(
            filteredWifis,
            subCompanies,
            filterFleets,
            t,
            can,
            fleets,
            entities
          )}
          rowKey={row => row.id}
          columns={prepareColumnsForTable(t)}
          pagination={false}
          loading={isLoadingFleets || isLoadingWiFi}
        />
      </Layout.Content>
    </div>
  );
};
