import React from 'react';
import { Button, Table, Tooltip } from 'antd';
import { InfoCircleFilled, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { prepareColumnsForOutboundTable, prepareDataForOutboundTable } from '../helpers';

import styles from '../Configuration.module.scss';

export const Outbound = ({
  adapter,
  handleOutboundEdit,
  handleDeleteOutbound,
  showCloseOutboundModal
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.outbound}>
      <div className={styles.header}>
        <div className={styles.title}>
          {t('Integrations.Outbound')}
          <div className={styles.infoIcon}>
            <Tooltip
              overlayClassName={styles.tooltipStyle}
              placement={'topRight'}
              title={t('Integrations.Tooltip.OutboundInfo')}
            >
              <InfoCircleFilled className={styles.inboundInfo} />
            </Tooltip>
          </div>
        </div>
        <div className={styles.addButton}>
          <Button onClick={showCloseOutboundModal} icon={<PlusOutlined />}>
            {t('Integrations.AddOutbound')}
          </Button>
        </div>
      </div>
      <div className={styles.data}>
        <Table
          className={styles.table}
          dataSource={prepareDataForOutboundTable(
            adapter,
            { handleOutboundEdit },
            { handleDeleteOutbound },
            t
          )}
          columns={prepareColumnsForOutboundTable(adapter?.fields)}
          pagination={false}
        />
      </div>
    </div>
  );
};
