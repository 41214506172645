import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import PhoneInput from 'react-phone-input-2';
import cn from 'classnames';
import 'react-phone-input-2/lib/style.css';
import './PhoneInputField.scss';
import { AllCountryRegionData } from '../address';
import { usePrevious } from 'utils/methods';

const useRandomKey = preferredCountries => {
  const [randomKey, setRandomKey] = useState(Math.random());
  const prevPrefered = usePrevious(preferredCountries);
  useEffect(() => {
    let allSame = false;
    try {
      allSame = JSON.stringify(prevPrefered) === JSON.stringify(preferredCountries);
    } catch (error) {
      allSame = false;
    }
    if (!allSame) {
      setRandomKey(Math.random());
    }
  }, [preferredCountries]);
  return randomKey;
};

export const PhoneInputField = ({
  preferredCountryCodes,
  fullWidth,
  label,
  initialValue,
  onChange,
  countryCodeEditable
}) => {
  const { t } = useTranslation();

  const localeSettings = useMemo(
    () =>
      AllCountryRegionData.reduce((acc, country) => {
        acc[country[0]] = t(`Countries.${country[0]}`);
        return acc;
      }, {}),
    [t, AllCountryRegionData]
  );

  const preferredCountries = useMemo(() => {
    return Array.from(
      new Set(preferredCountryCodes?.map(code => code?.toLowerCase()).filter(code => !!code))
    );
  }, [preferredCountryCodes]);
  const randomKey = useRandomKey(preferredCountries); //force update PhoneInput render

  const phoneInputClassName = cn({
    'fullWidth-container': fullWidth,
    'halfWidth-container': !fullWidth
  });

  const handleChange = (...props) => {
    onChange(...props);
  };
  return (
    <>
      <div className={phoneInputClassName}>
        <div className="label">{label}</div>
        <PhoneInput
          key={randomKey}
          country={preferredCountries && preferredCountries[0]}
          preferredCountries={preferredCountries}
          countryCodeEditable={countryCodeEditable}
          enableSearch={true}
          value={initialValue}
          localization={localeSettings}
          placeholder=""
          searchPlaceholder={t('Common.Search')}
          disableSearchIcon={true}
          inputClass="inputContainer"
          searchClass="searchContainer"
          dropdownClass="dropdownContainer"
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export const usePhoneNumber = initialPhoneNumber => {
  const [phone, setPhone] = useState({
    phone: '',
    dialCode: ''
  });

  useEffect(() => {
    setPhone(prevPhone => ({ ...prevPhone, phone: initialPhoneNumber }));
  }, [initialPhoneNumber]);

  const phoneNumber = useMemo(() => {
    return phone.phone || '';
  }, [phone]);

  const phoneNumberWithoutDialCode = useMemo(() => {
    return phone.phone?.slice(phone.dialCode.length) || '';
  }, [phone]);

  const handlePhoneChange = useCallback((phone, data) => {
    setPhone({
      phone,
      dialCode: data.dialCode
    });
  }, []);

  const resetPhoneNumber = useCallback(
    () =>
      setPhone({
        phone: '',
        dialCode: ''
      }),
    []
  );

  return {
    phoneNumber,
    phoneNumberWithoutDialCode,
    handlePhoneNumberChange: handlePhoneChange,
    resetPhoneNumber
  };
};
