import React, { useEffect, useMemo, useCallback } from 'react';
import {
  useVehicleType,
  deleteVehicleTypeApi,
  restoreVehicleTypeApi,
  useIsFetching
} from 'features/vehicles/vehicleTypesSlice';
import { useDispatch } from 'react-redux';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import InfoRow from 'components/form/info-row/InfoRow';
import { format } from 'utils/dates';
import styles from './VehicleTypes.module.scss';
import { useLocalization } from 'features/localization/localizationSlice';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import FormTitle from 'components/form/form-title/FormTitle';
import {
  useConfigurationValues,
  useDefaultConfiguration
} from 'features/configuration/configurationSlice';
import { ENGINE_PROFILES, FuelFieldKey, FUEL_PROFILES, FUEL_TYPE, Paths } from './constants';
import { useFuelEfficiencyPermission } from 'features/vehicles/hooks';
import { Can, useCanEveryService, services, companyFeatures } from 'features/permissions';
import { getLocalizedValue } from './helpers';
import './VehicleTypesView.scss';
import { vehicleTypeMetrics } from 'features/configuration/constants';
import { DeletedEntityAlert, DeletableEntityType } from 'features/common/deletedEntityAlert';
import { Space } from 'antd';
import { useDevicesFeatures } from 'features/devices/devicesSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

export const VehicleTypesView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const id = history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1);
  const vehicleType = useVehicleType(id);

  const handleError = useCallback(
    err => {
      if (history.location.pathname !== Paths.DEFAULT) {
        if (err) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: err
            })
          );
        }
        history.replace(Paths.DEFAULT);
      }
    },
    [history, dispatch]
  );

  const defaultConfiguration = useDefaultConfiguration();
  const configurationValues = useConfigurationValues(id, '', services.VPM, handleError);

  const localization = useLocalization();
  const VPMService = useCanEveryService(services.VPM);
  const BPMService = useCanEveryService(services.BPM);
  const SafetyAnalyticsService = useCanEveryService(services.SAFETYANALYTICS);
  const deviceFeatures = useDevicesFeatures(vehicleType?.company?.id);
  const isFetchingVehicleTypes = useIsFetching();
  const isFetchingDeletedTypes = useIsFetching('deletedList');

  useEffect(() => {
    if (
      id <= 0 ||
      id > Number.MAX_SAFE_INTEGER ||
      isNaN(id) ||
      (!isFetchingVehicleTypes && !isFetchingDeletedTypes && !vehicleType)
    ) {
      handleError(t('Common.Invalid Request ID'));
    }
  }, [t, id, handleError, vehicleType, isFetchingVehicleTypes, isFetchingDeletedTypes]);

  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(setPageTitle(vehicleType?.name));
  }, [dispatch, vehicleType]);

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        dispatch(deleteVehicleTypeApi(vehicleType, history));
        break;
      case 'restore':
        dispatch(restoreVehicleTypeApi(vehicleType));
        break;
      default:
    }
  };

  const isValidEngineProfile = useCallback(
    profile => {
      let isValid =
        VPMService ||
        (BPMService && profile.BPM) ||
        (SafetyAnalyticsService && profile.SafetyAnalytics);
      isValid = profile.deviceFeature ? profile.deviceFeature(deviceFeatures) : isValid;
      return isValid;
    },
    [VPMService, BPMService, SafetyAnalyticsService, deviceFeatures]
  );

  const getEngineProfileValue = useCallback(
    profile => {
      const getProfileLocalizedValue = profile => {
        const value =
          (configurationValues &&
            configurationValues.find(value => value.key === profile.key)?.value) ||
          defaultConfiguration[profile.key]?.defaultValue;
        const localizedValue = getLocalizedValue({
          localization,
          metricKey: profile.key,
          metricValue: value
        });
        return isNaN(localizedValue) || localizedValue === NaN.toString() ? '' : localizedValue;
      };
      if (profile?.segments?.length) {
        return (
          <Space size={16}>
            {profile.segments.map(
              profileSegment =>
                `${profileSegment.label} : ${getProfileLocalizedValue(profileSegment)}`
            )}
          </Space>
        );
      }
      return getProfileLocalizedValue(profile);
    },
    [configurationValues, localization, defaultConfiguration]
  );

  const EngineProfiles = useMemo(() => {
    return ENGINE_PROFILES(t, localization)
      .filter(isValidEngineProfile)
      .map(profile => (
        <InfoRow
          key={profile.key}
          label={profile.label}
          sxValue={{ width: '75%' }}
          value={getEngineProfileValue(profile)}
          styles={styles}
        />
      ));
  }, [t, localization, isValidEngineProfile, getEngineProfileValue]);

  const hasFuelEfficiencyPermission = useFuelEfficiencyPermission();
  const getFuelProfileValue = useCallback(
    profile => {
      if (profile.key === FuelFieldKey.FuelType) {
        return vehicleType[profile.key];
      } else {
        return (
          configurationValues?.find(value => value.key === vehicleTypeMetrics[profile.key])
            ?.value || ''
        );
      }
    },
    [vehicleType, configurationValues]
  );

  const FuelProfiles = useMemo(() => {
    const profileItems = Object.values(FUEL_PROFILES(t, localization)).map(profile => ({
      key: profile.key,
      label: profile.label,
      value: getFuelProfileValue(profile),
      canShow: profile?.canShow || (() => false)
    }));
    const values = Object.values(profileItems).reduce(
      (a, item) => ({ ...a, [item.key]: item.value }),
      {}
    );
    return profileItems
      .filter(profile => profile.canShow(values))
      .map(profile => (
        <InfoRow
          key={profile.key}
          label={profile.label}
          sxValue={{ width: '75%' }}
          value={
            profile.key === FuelFieldKey.FuelType
              ? t(Object.values(FUEL_TYPE).find(type => type.value === profile.value)?.translated)
              : getLocalizedValue({
                  localization,
                  metricKey: vehicleTypeMetrics[profile.key],
                  metricValue: profile.value
                })
          }
          styles={styles}
        />
      ));
  }, [t, localization, getFuelProfileValue]);

  return (
    <>
      <ViewHeaderWrapper
        data={vehicleType}
        editPath={`/settings/vehicleTypes/edit/id/${id}`}
        canUse="VEHICLETYPE"
        handleButtonAction={handleButtonAction}
        typeOfEntityToDelete={t('Common.vehicleType')}
      />
      <div className="vehicle-type-section" style={{ display: 'flex' }}>
        <div className={styles.firstRowElement}>
          <DeletedEntityAlert
            entity={DeletableEntityType.VehicleType}
            entityStatus={vehicleType?.status}
          />
          <InfoRow
            label={t('VehicleTypes.View.Company')}
            sxValue={{ width: '75%' }}
            value={vehicleType?.company?.name || ''}
            styles={styles}
          />
        </div>
        <InfoRow
          label={t('VehicleTypes.View.Code')}
          sxValue={{ width: '75%' }}
          value={vehicleType && vehicleType.code ? vehicleType.code : ''}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleTypes.Form.Icon')}
          sxValue={{ width: '75%', fontSize: '20px' }}
          value={vehicleType?.icon ? <i className={vehicleType?.icon} /> : '-'}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleTypes.SpeedLimitClass')}
          sxValue={{ width: '75%' }}
          value={
            vehicleType?.speedLimitClass ? t(`VehicleTypes.${vehicleType?.speedLimitClass}`) : ''
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleTypes.View.Description')}
          sxValue={{ width: '75%' }}
          value={vehicleType && vehicleType.description ? vehicleType.description : ''}
          styles={styles}
        />
        <InfoRow
          label={t('VehicleTypes.View.CreatedAt')}
          sxValue={{ width: '75%' }}
          value={
            vehicleType && vehicleType.createdAt
              ? format(new Date(vehicleType.createdAt), localization.formats.time.formats.dby_imp)
              : ''
          }
          styles={styles}
        />
        <InfoRow
          label={t('VehicleTypes.View.UpdatedAt')}
          sxValue={{ width: '75%' }}
          value={
            vehicleType && vehicleType.updatedAt
              ? format(new Date(vehicleType.updatedAt), localization.formats.time.formats.dby_imp)
              : ''
          }
          styles={styles}
        />
      </div>
      <Can featureHide={companyFeatures.HTF}>
        <div className="vehicle-type-section" style={{ display: 'flex' }}>
          <Can oneOfServices={[services.VPM, services.BPM, services.SAFETYANALYTICS]}>
            <div>
              <FormTitle title={t('Vehicles.View.EngineProfile')} underlined />
              {EngineProfiles}
            </div>
          </Can>
        </div>
        <div className="vehicle-type-section" style={{ display: 'flex' }}>
          {hasFuelEfficiencyPermission && (
            <div>
              <FormTitle title={t('Vehicles.View.FuelProfile')} underlined />
              {FuelProfiles}
            </div>
          )}
        </div>
      </Can>
    </>
  );
};
