import React from 'react';
import { Route, Switch } from 'react-router-dom';
import InspectionChecklist from './InspectionChecklist';
import { InspectionChecklistView } from './InspectionChecklistView';
import InspectionChecklistForm from './InspectionChecklistForm';
import DvirInspectionForm from './DvirInspectionForm';
import { Can, entities, services } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';

export const InspectionChecklistRouter = () => {
  let isDvirForm = true;
  return (
    <Can
      everyEntity={[entities.PRETRIPCHECKLIST]}
      everyService={[services.PRETRIP]}
      onFail={<NoAccessBody />}
    >
      <Switch>
        <Route path={'/settings/inspectionchecklist/id'}>
          <Can everyEntity={[entities.PRETRIPCHECKLIST_VIEW]} onFail={<NoAccessBody />}>
            <InspectionChecklistView />
          </Can>
        </Route>
        <Route path={'/settings/inspectionChecklist/newInspectionChecklist'}>
          <Can everyEntity={[entities.PRETRIPCHECKLIST_CREATE]} onFail={<NoAccessBody />}>
            <InspectionChecklistForm action="add" />
          </Can>
        </Route>
        <Route path={'/settings/inspectionChecklist/edit'}>
          <Can everyEntity={[entities.PRETRIPCHECKLIST_UPDATE]} onFail={<NoAccessBody />}>
            <InspectionChecklistForm action="edit" />
          </Can>
        </Route>
        <Route path={'/settings/inspectionChecklist/newDvirInspection'}>
          <Can everyEntity={[entities.PRETRIPCHECKLIST_CREATE]} onFail={<NoAccessBody />}>
            <DvirInspectionForm action="add" isDvir={isDvirForm} />
          </Can>
        </Route>
        <Route path={'/settings/inspectionChecklist/editDvir'}>
          <Can everyEntity={[entities.PRETRIPCHECKLIST_UPDATE]} onFail={<NoAccessBody />}>
            <DvirInspectionForm action="edit" isDvir={isDvirForm} />
          </Can>
        </Route>
        <Route path={'/settings/inspectionchecklist/copy'}>
          <Can everyEntity={[entities.PRETRIPCHECKLIST_COPY]} onFail={<NoAccessBody />}>
            <InspectionChecklistForm action="copy" />
          </Can>
        </Route>

        <Route path={'/settings/inspectionChecklist'}>
          <InspectionChecklist />
        </Route>
      </Switch>
    </Can>
  );
};
