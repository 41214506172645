import { nanoid } from '@reduxjs/toolkit';
import i18n from 'i18next';

export const OPERATION = [
  {
    id: String(nanoid()),
    value: '!=',
    get label() {
      return i18n.t('Alerts.MeterOperations.NotEqual');
    }
  },
  {
    id: String(nanoid()),
    value: '==',
    get label() {
      return i18n.t('Alerts.MeterOperations.Equal');
    }
  },
  {
    id: String(nanoid()),
    value: '<',
    get label() {
      return i18n.t('Alerts.MeterOperations.LessThan');
    }
  },
  {
    id: String(nanoid()),
    value: '<=',
    get label() {
      return i18n.t('Alerts.MeterOperations.LessThanOrEqual');
    }
  },
  {
    id: String(nanoid()),
    value: '>',
    get label() {
      return i18n.t('Alerts.MeterOperations.GreaterThan');
    }
  },
  {
    id: String(nanoid()),
    value: '>=',
    get label() {
      return i18n.t('Alerts.MeterOperations.GreaterThanOrEqual');
    }
  }
];

export const TYPE = {
  DEVICE: 'Ng::Device',
  VEHICLE: 'Ng::Vehicle'
};
