import { unwrapResult } from '@reduxjs/toolkit';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';

export const deleteModal = ({
  t,
  data,
  typeOfEntityToDelete,
  handleDelete,
  modalType = 'delete',
  deleteAction,
  dataTypeMessages,
  dispatch,
  customDeleteCall = false
}) => () => {
  confirmationModal(
    `${t('Common.DeleteButton')} ${data.name}`,
    `${t('Common.SureDelete')} ${typeOfEntityToDelete ?? ''} ${data.name}?`,
    t('Common.DeleteButton'),
    t('Common.CancelButton'),
    handleDelete({ deleteAction, data, t, dataTypeMessages, dispatch, customDeleteCall }),
    modalType
  );
};

export const handleDelete = ({
  deleteAction,
  data,
  t,
  dataTypeMessages,
  dispatch,
  customDeleteCall
}) => async () => {
  if (customDeleteCall) {
    dispatch(customDeleteCall(data));
    return;
  }

  try {
    const deleteResponse = await dispatch(deleteAction(data.id));
    unwrapResult(deleteResponse);

    dispatch(
      openToast({
        type: ToastType.Success,
        message: `${t(`${dataTypeMessages}.deleteSuccess`)} ${data.name}!`
      })
    );
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: err?.message
      })
    );
  }
};

export const handleRestore = ({
  restoreAction,
  data,
  t,
  dataTypeMessages,
  dispatch
}) => async () => {
  try {
    const restoreResponse = await dispatch(
      restoreAction({ id: data.id, locationId: data.entityId })
    );
    unwrapResult(restoreResponse);

    dispatch(
      openToast({
        type: ToastType.Success,
        message: `${t(`${dataTypeMessages}.restoreSuccess`)} ${data.name}!`
      })
    );
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: err?.message
      })
    );
  }
};
