import React from 'react';
import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MapTiles.scss';

const MapTilesExpand = ({ setFullScreen, fullScreen }) => {
  return (
    <div className="mapTilesExpandCompress" onClick={() => setFullScreen(!fullScreen)}>
      {fullScreen ? <FontAwesomeIcon icon={faCompress} /> : <FontAwesomeIcon icon={faExpand} />}
    </div>
  );
};

export default MapTilesExpand;
