import React from 'react';
import Can from './Can';

export const CanOrNull = ({ children, extraCheck, ...permissionChecks }) => {
  if (extraCheck !== undefined) {
    if (permissionChecks && extraCheck) {
      return <Can {...permissionChecks}>{children}</Can>;
    }
    return null;
  }
  if (permissionChecks) {
    return <Can {...permissionChecks}>{children}</Can>;
  }
  return null;
};
