import React, { useState, useEffect } from 'react';
import { FormGroup, Col, FormLabel } from 'react-bootstrap';
import styles from './SearchableListMultiSelect.module.scss';
import InfiniteScroll from 'react-infinite-scroller';
import { List, Checkbox, Spin } from 'antd';
import { useIsFetching as isFleetsFetching } from 'features/fleets/fleetsSlice';

const SearchableListMultiSelect = ({
  label,
  values: allValues,
  initialValues,
  setFieldValue,
  height,
  width,
  isRequired,
  isDisabled,
  disabledMessage,
  placeholder,
  allLabel,
  hideSelectAll,
  isLoading = false,
  onCheckboxChanged = null,
  formStyle
}) => {
  const [values, setValues] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const isFetchingFleets = isFleetsFetching();
  const showSpinner = isLoading || isFetchingFleets;

  const allItem = hideSelectAll
    ? {}
    : {
        value: 0,
        label: allLabel,
        checked: false
      };

  useEffect(() => {
    const updated = allValues
      .reduce((accumulator, currrent) => {
        initialValues.some(item => item.value === currrent.value)
          ? accumulator.push({ ...currrent, checked: true })
          : accumulator.push(currrent);
        return accumulator;
      }, [])
      .sort(sortByChecked);

    let allItemCheckbox;
    if (updated.some(item => !item.value)) {
      setValues(updated);
      setFilteredValues(updated);
    } else {
      if (!updated.some(value => value.checked === false && value.value !== allItem.value)) {
        allItemCheckbox = {
          ...allItem,
          checked: true
        };
      } else {
        allItemCheckbox = allItem;
      }

      setValues([allItemCheckbox, ...updated]);
      setFilteredValues([allItemCheckbox, ...updated]);
    }
  }, [allValues.length, initialValues.length]);

  useEffect(() => {
    // We want to filter the ids that do not include the "All" option
    setFieldValue(values.filter(value => value.value));
  }, [values]);

  const sortByChecked = (a, b) => {
    if (b.checked || isNaN(b.value)) {
      return 1;
    } else {
      if (!b.checked && !a.checked) {
        return 0;
      }
      return -1;
    }
  };
  const filter = text => {
    const filteredValues = values.filter(
      value => value.label?.toLowerCase().indexOf(text?.toLowerCase()) > -1 || !value.value
    );
    setFilteredValues(filteredValues);
  };

  const updateCheckedValue = (value, checked, values) => {
    let checkAll = true;

    const newValues =
      value === allItem.value
        ? values.map(item => ({ ...item, checked }))
        : values.map(item =>
            item.value === value
              ? {
                  ...item,
                  checked
                }
              : item
          );

    newValues.forEach(value => {
      if (value.checked === false && value.value !== allItem.value) {
        checkAll = false;
      }
    });

    return newValues.map(item =>
      item.value === allItem.value ? { ...allItem, label: item.label, checked: checkAll } : item
    );
  };

  const handleCheckboxChange = (value, checked) => () => {
    const newValues = updateCheckedValue(value, checked, values);
    const newfilteredValues = updateCheckedValue(value, checked, filteredValues);
    setValues(newValues);
    setFilteredValues(newfilteredValues);
    onCheckboxChanged && onCheckboxChanged(true);
  };

  const renderCheckboxes = () => {
    if (isDisabled) {
      return <div className={styles.disabledMessage}>{disabledMessage}</div>;
    }
    if (showSpinner) {
      return (
        <div className={styles.loaderContainer}>
          <Spin />
        </div>
      );
    } else {
      return (
        <div className={`${styles.list} ${'showScrollbarsOnHover'}`}>
          <InfiniteScroll initialLoad={false} pageStart={0} useWindow={false} loadMore={() => {}}>
            <List
              dataSource={filteredValues}
              renderItem={item => {
                return (
                  <List.Item key={item.id}>
                    <div className={styles.itemContainer}>
                      {item.value !== undefined ? (
                        <>
                          <Checkbox
                            checked={item.checked}
                            onChange={handleCheckboxChange(item.value, !item.checked)}
                          />
                          <div className={styles.content}>{item.label}</div>
                        </>
                      ) : (
                        <div className={styles.label}>{item.label}</div>
                      )}
                    </div>
                  </List.Item>
                );
              }}
            ></List>
          </InfiniteScroll>
        </div>
      );
    }
  };

  return (
    <FormGroup
      as={Col}
      className={styles.col}
      style={{ ...{ height: `${height}px`, width: `${width}px` }, ...formStyle }}
    >
      {label && <FormLabel>{label}</FormLabel>}
      {isRequired && <span className={styles.inputRequired}>*</span>}
      <div className={styles.listContainer}>
        <input
          className={`${styles.searchInput} ${isDisabled ? styles.disabled : ''}`}
          type="text"
          placeholder={placeholder}
          onChange={e => filter(e.target.value)}
          readOnly={isDisabled ? true : false}
        />
        {renderCheckboxes()}
      </div>
    </FormGroup>
  );
};

export default SearchableListMultiSelect;
