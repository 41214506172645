import React from 'react';
import styles from './fatigue.module.scss';

export function restTimeCell_Rest(rowData) {
  const usedRestTime = rowData?.fatigueStatus?.break_rest_taken || 0;
  const maxRestTime = rowData.fatigueStatus?.required_break_rest || 1;

  const percent = Math.min(100, (usedRestTime / maxRestTime).toFixed(2) * 100);
  return (
    <div className={styles.barChart}>
      <div className={styles.bar + ' ' + styles.blue}>
        <span
          className={percent === 100 ? styles.fullBar : styles.progressBar}
          style={{ width: percent + '%' }}
        ></span>
      </div>
      <p>
        {Math.floor(usedRestTime / 3600)} hrs {Math.round((usedRestTime % 3600) / 60)} mins
      </p>
    </div>
  );
}

export function restTimeCell_POA(rowData) {
  return restTimeCell_Drive(rowData);
}

export function restTimeCell_Drive(rowData) {
  let restTimeDue = rowData.fatigueStatus?.next_rest_break_required_in || 0;
  const warning_state = rowData.fatigueStatus?.warning_state || 0;

  if (warning_state === 2) {
    return (
      <div className={styles.breakRestDue}>
        <p>BREAK PAST DUE</p>
      </div>
    );
  } else {
    restTimeDue = Math.abs(restTimeDue);
    return (
      <div className={styles.restDue}>
        <p>REST DUE IN</p>
        <p>
          {Math.floor(restTimeDue / 3600)} hrs {Math.round((restTimeDue % 3600) / 60)} mins
        </p>
      </div>
    );
  }
}
