import React from 'react';
import { useTranslation } from 'react-i18next';
import { getBreachName } from 'features/notifications/helpers';

export function TachoAlertContentRender({ alertData }) {
  const { t } = useTranslation();

  const extras = alertData.extras && JSON.parse(alertData.extras);
  const breachName = getBreachName({ type: extras?.AlertCode, name: extras?.Alert });
  return (
    <>
      {alertData.driverId !== 'Unknown' && (
        <>
          {` ${alertData.driverName}, ${alertData.vehicleName} 
        ${t('Notifications.InBreachOf')} `}
          <strong>{breachName}</strong>
        </>
      )}
    </>
  );
}
