import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Divider, Radio, Tooltip as AntTooltip } from 'antd';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import styles from './Components.module.scss';
import { defaultTableRowRenderer } from 'react-virtualized';
import { useDevices, useFleets, useVehicles } from 'features/fleets/fleetsSlice';
import { durationFormat } from 'features/fatigue/time_util';
import { Select as TNSelect } from 'components/ant/Select/Select';
import { ExpandButton, IconStyle } from 'components/ant/Button/ExpandButton';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { TabNavLink } from 'components/nav/NavLinks';
import { useUser } from 'features/user/userSlice';
import { useLocalCache } from 'utils/hooks/useLocalCache';
import { useTranslation } from 'react-i18next';
import { useSentinelTimer } from 'features/sentinel/components/status/statusSlice';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';

function TimeText({ time }) {
  useSentinelTimer();
  return <span>{durationFormat(time - moment().valueOf())}</span>;
}

export function TimeTooltip({ tooltip, time, className, status }) {
  const isResting = [/rest/i, /offduty/i, /sleep/i].some(reg => status?.match(reg));
  const { t } = useTranslation();

  return (
    <AntTooltip title={tooltip} overlayClassName={className}>
      {time ? (
        time > moment().valueOf() ? (
          <div className={styles.rectangle}>
            <span>{t('Fatigue.REST DUE IN')}</span>
            <TimeText time={time} />
          </div>
        ) : (
          <div className={styles.rectangle + (isResting ? '' : ' ' + styles.violationRect)}>
            <span>{t('Fatigue.REST PAST DUE')}</span>
          </div>
        )
      ) : (
        t('Unknown')
      )}
    </AntTooltip>
  );
}

export function Reseting() {
  const { t } = useTranslation();
  return (
    <div className={styles.rectangle}>
      <span>{t('Fatigue.In Resting')}</span>
    </div>
  );
}

export function Violations({ tooltip, className, isPotentialViolation }) {
  const { t } = useTranslation();
  return (
    <AntTooltip title={tooltip} overlayClassName={className}>
      <div
        className={
          styles.rectangle +
          ' ' +
          (isPotentialViolation ? styles.potentialViolationRect : styles.violationRect)
        }
      >
        <span>
          {isPotentialViolation ? t('Fatigue.Potential Violation') : t('Fatigue.In Violation')}
        </span>
      </div>
    </AntTooltip>
  );
}

export function Tooltip({ type, startAt, endAt }) {
  const localization = useLocalization();
  const { t } = useTranslation();
  if (!type) {
    return null;
  }
  return (
    <div className={styles.tooltip}>
      {type && <p>{type}</p>}
      <p>
        <span>{t('ELD.Start')}:</span>
        <span>
          {startAt ? format(startAt, localization.formats.time.formats.dby_hm) : t('Unknown')}
        </span>
      </p>
      {endAt && (
        <p>
          <span>{t('ELD.End')}:</span>
          <span>
            {endAt ? format(endAt, localization.formats.time.formats.dby_hm) : t('Unknown')}
          </span>
        </p>
      )}
    </div>
  );
}

export function ViolationsTooltip({ violations }) {
  return (
    <>
      {violations.map((v, idx) => (
        <React.Fragment key={v.startAt}>
          {idx > 0 && <Divider />}
          <div className={styles.tooltip}>
            <p>{v.rule.id}</p>
            <p>{v.rule.description}</p>
          </div>
        </React.Fragment>
      ))}
    </>
  );
}

export function RestDueTooltip({ nextViolation }) {
  const localization = useLocalization();
  const { t } = useTranslation();
  return (
    <div className={styles.tooltip}>
      <p>{nextViolation.rule.id}</p>
      <p>{nextViolation.rule.description}</p>
      {nextViolation.startAt < moment().valueOf() && (
        <p>
          {t('ELD.Start') +
            ': ' +
            format(nextViolation.startAt, localization.formats.time.formats.dby_hm)}
        </p>
      )}
    </div>
  );
}

export function FatigueExpandRowRender({
  index,
  style,
  className,
  key,
  rowData,
  localization,
  cellCache,
  tableColumns,
  expandTableColumns,
  fatigueDataField,
  ...props
}) {
  if (rowData.expand) {
    style.top = parseInt(style.top);
  }

  let rowHeight = 0;
  for (let i = 0; i < tableColumns.length; i++) {
    rowHeight = Math.max(rowHeight, cellCache.getHeight(index, i));
  }

  const NestCellRender = ({ cell }) => {
    const content = cell.cellRenderer(rowData, cell.dataKey, localization);
    return (
      <div>
        <span>{cell.title}</span>
        <span title={typeof content === 'string' ? content : ''}>{content}</span>
      </div>
    );
  };

  function expandRender() {
    const enabledCells = expandTableColumns[1].filter(c => c.enable(rowData));
    const cellArray = [];
    for (let i = 0; i < enabledCells.length; i += 2) {
      cellArray.push(
        <div className={styles.nestCellsGroup} key={i}>
          <NestCellRender cell={enabledCells[i]} />
          {i + 1 < enabledCells.length && <NestCellRender cell={enabledCells[i + 1]} />}
        </div>
      );
    }
    return cellArray;
  }

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 0,
        paddingBottom: 0
      }}
      className={className}
      key={key}
    >
      {defaultTableRowRenderer({
        ...props,
        style: {
          width: style.width,
          height: rowHeight + 'px',
          display: 'flex',
          marginLeft: '-10px'
        }
      })}
      {rowData.expand && (
        <div className={styles.expandGrid}>
          <div>
            <span>Ruleset - </span>
            {rowData?.[fatigueDataField]?.ruleset ? (
              <span>
                {expandTableColumns[0][0].cellRenderer(
                  rowData,
                  expandTableColumns[0][0].dataKey,
                  localization
                )}{' '}
                (
                {expandTableColumns[0][1].cellRenderer(
                  rowData,
                  expandTableColumns[0][1].dataKey,
                  localization
                )}
                )
              </span>
            ) : (
              'N/A'
            )}
          </div>
          <div className={styles.nestCell}>{expandRender()}</div>
        </div>
      )}
    </div>
  );
}

export function TimeCell({ time }) {
  const localization = useLocalization();
  return <span>{time ? format(time, localization.formats.time.formats.dby_hm) : ''}</span>;
}

export function FleetVehicleCell({ vehicle, device }) {
  const fleets = useFleets();
  const vehicles = useVehicles();
  const devices = useDevices();
  let fleetsOf = null;
  let name = '-';
  if (vehicle) {
    fleetsOf = fleets
      .filter(f => f.name && f.vehicles?.find(v => v.id === vehicle.id))
      ?.map(f => f.name);
    name = vehicles?.find(v => v.id === vehicle.id)?.name || '-';
  } else if (device) {
    fleetsOf = fleets
      .filter(f => f.name && f.vehicles?.devices?.find(d => d.id === device.id))
      ?.map(f => f.name);
    name = devices?.find(d => d.id === device.id)?.name || '-';
  }

  if (!fleetsOf?.length && (vehicle || device)) {
    fleetsOf.push('No Fleet');
  }

  return (
    <div>
      <span>{fleetsOf?.length ? fleetsOf.join(',') : '-'}</span> / {name}
    </div>
  );
}

function getStyleForOrderBy(orderBy) {
  if (orderBy === '1') {
    return styles.descending;
  }
  return styles.ascending;
}

export function FatigueToolbar({
  filterText,
  onSearchChanged,
  filterCompanies,
  onCompanyChanged,
  filterBranches,
  onBranchChanged,
  filterFleets,
  onFleetChanged,
  onExpandRows,
  disableExpand = false,
  driverLength,
  sortBy,
  sortLists,
  onSortChanged,
  orderBy,
  onOrderByChanged,
  showFleetFilter = true
}) {
  const [isSortDrpOpen, setIsSortDropOpen] = useState(false);
  return (
    <div
      className={styles.fatigueToolbar + ' ' + getStyleForOrderBy(orderBy)}
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f7f8f9',
        padding: '6px 16px',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#dadee3'
      }}
    >
      <AntSearchbar value={filterText} onFilter={value => onSearchChanged(value)} />

      <AntMultiselect
        data={filterCompanies}
        onFilter={onCompanyChanged}
        title={filterCompanies?.some(c => !c.checked) ? 'Companies' : 'All Companies'}
      />
      <AntMultiselect
        data={filterBranches}
        onFilter={onBranchChanged}
        title={filterBranches?.some(b => !b.checked) ? 'Branches' : 'All Branches'}
      />
      {showFleetFilter && (
        <AntMultiselect
          data={filterFleets}
          onFilter={onFleetChanged}
          title={filterFleets?.some(b => !b.checked) ? 'Fleets' : 'All Fleets'}
        />
      )}
      <TNSelect
        open={isSortDrpOpen}
        onDropdownVisibleChange={open => setIsSortDropOpen(open)}
        width={200}
        size="large"
        value={sortBy}
        placeholder={'Sort By'}
        data={sortLists}
        title={'Sort By ' + sortBy + ' in ' + (orderBy === '0' ? 'Ascending' : 'Descending')}
        onChange={onSortChanged}
        dropdownRender={menu => (
          <div>
            {menu}
            <Divider style={{ margin: '5px 0px' }} />
            <div className={styles.selectFooter}>
              <p>Order</p>
              <Radio.Group
                size="large"
                defaultValue={orderBy || '0'}
                onChange={e => setIsSortDropOpen(false) || onOrderByChanged(e.target.value)}
              >
                <Radio value="0">Ascending</Radio>
                <Radio value="1">Descending</Radio>
              </Radio.Group>
            </div>
          </div>
        )}
      />

      <div style={{ display: 'flex', marginLeft: 'auto', height: '100%' }}>
        <label
          style={{
            display: 'flex',
            width: '100%',
            marginRight: '10px',
            paddingRight: '10px',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          {driverLength} {driverLength === 1 ? 'Driver' : 'Drivers'}
        </label>
        <ExpandButton
          iconStyle={IconStyle.DoubleArrow}
          onExpand={onExpandRows}
          defaultExpand={false}
          disabled={disableExpand}
        />
      </div>
    </div>
  );
}

export function QuickFilterTable({ parentPath, basePath, tableComponent }) {
  const tabs = [
    { title: 'All', path: 'All' },
    { title: 'Working/Driving', path: 'Working_Driving' },
    { title: 'Resting', path: 'Resting' }
  ];
  const FatigueTable = tableComponent;
  const location = useLocation();
  const history = useHistory();
  const user = useUser();
  const [lastSelection, setLastSelection] = useLocalCache('quickFilter', {
    userId: user?.id,
    pagePathname: basePath
  });

  useEffect(() => {
    if (lastSelection && (location.pathname === parentPath || location.pathname === basePath)) {
      history.replace(basePath + '/' + lastSelection);
    } else if (location.pathname === parentPath || location.pathname === basePath) {
      history.replace(basePath + '/All');
    }
  }, [lastSelection, location.pathname, history]);

  return (
    <>
      <div className={styles.quickFilter}>
        <div>
          {tabs.map(t => (
            <TabNavLink
              isActive={(_, location) => {
                return (
                  location?.pathname?.toLowerCase().endsWith(t.path.toLowerCase()) ||
                  (t.path === 'All' &&
                    (parentPath.toLowerCase() === location?.pathname.toLowerCase() ||
                      basePath.toLowerCase() === location?.pathname.toLowerCase()))
                );
              }}
              onClick={() => setLastSelection(t.path)}
              to={basePath + '/' + t.path}
              key={t.path}
            >
              {t.title}
            </TabNavLink>
          ))}
        </div>
        <Switch>
          {tabs.map(t => (
            <Route
              path={
                t.path === 'All'
                  ? [parentPath, basePath, basePath + '/' + t.path]
                  : basePath + '/' + t.path
              }
              exact
              key={t.path}
            >
              <FatigueTable filter={t.path} />
            </Route>
          ))}
        </Switch>
      </div>
    </>
  );
}

export const CellContentLoader = () => (
  <div className={styles.contentCellLoader}>
    <div className={styles.loader}></div>
  </div>
);
