/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import DateTimeFieldType from "./DateTimeFieldType";
import DurationField from "./DurationField";

/**
 * The DateTimeField model module.
 * @module model/DateTimeField
 * @version 1.0
 */
class DateTimeField {
  /**
   * Constructs a new <code>DateTimeField</code>.
   * @alias module:model/DateTimeField
   */
  constructor() {
    DateTimeField.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>DateTimeField</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DateTimeField} obj Optional instance to populate.
   * @return {module:model/DateTimeField} The populated <code>DateTimeField</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DateTimeField();

      if (data.hasOwnProperty("lenient")) {
        obj["lenient"] = ApiClient.convertToType(data["lenient"], "Boolean");
      }
      if (data.hasOwnProperty("durationField")) {
        obj["durationField"] = DurationField.constructFromObject(
          data["durationField"]
        );
      }
      if (data.hasOwnProperty("minimumValue")) {
        obj["minimumValue"] = ApiClient.convertToType(
          data["minimumValue"],
          "Number"
        );
      }
      if (data.hasOwnProperty("maximumValue")) {
        obj["maximumValue"] = ApiClient.convertToType(
          data["maximumValue"],
          "Number"
        );
      }
      if (data.hasOwnProperty("rangeDurationField")) {
        obj["rangeDurationField"] = DurationField.constructFromObject(
          data["rangeDurationField"]
        );
      }
      if (data.hasOwnProperty("leapDurationField")) {
        obj["leapDurationField"] = DurationField.constructFromObject(
          data["leapDurationField"]
        );
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = DateTimeFieldType.constructFromObject(data["type"]);
      }
      if (data.hasOwnProperty("supported")) {
        obj["supported"] = ApiClient.convertToType(
          data["supported"],
          "Boolean"
        );
      }
    }
    return obj;
  }
}

/**
 * @member {Boolean} lenient
 */
DateTimeField.prototype["lenient"] = undefined;

/**
 * @member {module:model/DurationField} durationField
 */
DateTimeField.prototype["durationField"] = undefined;

/**
 * @member {Number} minimumValue
 */
DateTimeField.prototype["minimumValue"] = undefined;

/**
 * @member {Number} maximumValue
 */
DateTimeField.prototype["maximumValue"] = undefined;

/**
 * @member {module:model/DurationField} rangeDurationField
 */
DateTimeField.prototype["rangeDurationField"] = undefined;

/**
 * @member {module:model/DurationField} leapDurationField
 */
DateTimeField.prototype["leapDurationField"] = undefined;

/**
 * @member {String} name
 */
DateTimeField.prototype["name"] = undefined;

/**
 * @member {module:model/DateTimeFieldType} type
 */
DateTimeField.prototype["type"] = undefined;

/**
 * @member {Boolean} supported
 */
DateTimeField.prototype["supported"] = undefined;

export default DateTimeField;
