import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

export const EllipsisContent = ({ children, style, className }) => {
  const { t } = useTranslation();
  return (
    <Typography.Paragraph
      className={className}
      style={{ ...style, marginBottom: 0 }}
      ellipsis={{
        rows: 3,
        expandable: true,
        symbol: t('Common.Show More Content')
      }}
    >
      {children}
    </Typography.Paragraph>
  );
};
