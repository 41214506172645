import React from 'react';
import {
  availableCellRenderer,
  majorRestDueCellRenderer,
  nextRestDueCellRenderer
} from './CellRenderer';
import { ButtonStyle, ExpandButton, IconStyle } from 'components/ant/Button/ExpandButton';
import { LinkCell, MeatballsMenu } from './CellRenderer';
import { durationFormat, toNowStamp } from 'features/fatigue/time_util';
import { getClassOfDriverState, stateRenderer } from 'features/fatigue/StateRenderer';
import { TimeCell, CellContentLoader } from 'features/fatigue/Components.js';
import moment from 'moment';

export const driversCols = [
  {
    label: '', //State
    dataKey: 'state',
    cellRenderer: ({ rowData }) => stateRenderer(rowData, 'ewdData'),
    width: '1%',
    sort: {
      name: 'State',
      sortFn: (a, b) => {
        const valueA = getClassOfDriverState(a.ewdData?.lastStatusEvent?.type).title;
        const valueB = getClassOfDriverState(b.ewdData?.lastStatusEvent?.type).title;
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Name',
    dataKey: 'name',
    width: '9%',
    cellRenderer: ({ rowData }) => <LinkCell rowData={rowData} />,
    style: { whiteSpace: 'normal' },
    sort: {
      name: 'Name',
      sortFn: (a, b) => {
        const valueA = (a.firstName + ' ' + a.lastName).toLowerCase();
        const valueB = (b.firstName + ' ' + b.lastName).toLowerCase();
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Licence',
    dataKey: 'licenceNumber',
    width: '9%',
    style: { whiteSpace: 'normal' },
    sort: {
      name: 'Licence',
      sortFn: (a, b) => {
        if (a.licenceNumber > b.licenceNumber) return 1;
        if (a.licenceNumber === b.licenceNumber) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Company/Branch',
    dataKey: 'companyBranch',
    cellDataGetter: ({ rowData }) =>
      (rowData.company ? rowData.company.label : '-') +
      ' / ' +
      (rowData.branch ? rowData.branch.label : 'No Branch'),
    width: '9%',
    style: { whiteSpace: 'normal' },
    sort: [
      {
        name: 'Company',
        sortFn: (a, b) => {
          if (a.company?.label > b.company?.label) return 1;
          if (a.company?.label === b.company?.label) return 0;
          return -1;
        }
      },
      {
        name: 'Branch',
        sortFn: (a, b) => {
          const valueA = a.branch?.label || 'No Branch';
          const valueB = b.branch?.label || 'No Branch';
          if (valueA > valueB) return 1;
          if (valueA === valueB) return 0;
          return -1;
        }
      }
    ]
  } /* disable for now
  {
    label: 'Fleet/Vehicle',
    dataKey: 'branch',
    cellRenderer: ({ rowData }) => (<FleetVehicleCell vehicle={rowData.ewdData?.lastEvent?.vehicle} device={rowData.ewdData?.lastEvent?.device} driverId={rowData.id} />),
    width: '9%',
    style: {whiteSpace: 'normal'}
  },*/,
  {
    label: 'Rego',
    dataKey: 'rego',
    cellRenderer: ({ rowData, isLoading = false }) =>
      isLoading ? <CellContentLoader /> : rowData?.ewdData?.lastEvent?.registration || '',
    width: '9%',
    style: { whiteSpace: 'normal' },
    sort: {
      name: 'Rego',
      sortFn: (a, b) => {
        const valueA = a.ewdData?.lastEvent?.registration || '';
        const valueB = b.ewdData?.lastEvent?.registration || '';
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Current Location',
    dataKey: 'location',
    cellRenderer: ({ rowData, isLoading = false }) =>
      isLoading ? <CellContentLoader /> : rowData.ewdData?.lastEvent?.location || '',
    width: '13%',
    style: { whiteSpace: 'normal' },
    sort: {
      name: 'Current Location',
      sortFn: (a, b) => {
        const valueA = a.ewdData?.lastEvent?.location || '';
        const valueB = b.ewdData?.lastEvent?.location || '';
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Last Update',
    dataKey: 'branch',
    cellRenderer: ({ rowData, isLoading = false }) =>
      isLoading ? (
        <CellContentLoader />
      ) : (
        <TimeCell
          time={
            rowData.ewdData?.checkpointAt ||
            rowData.ewdData?.pivotTimeAt ||
            rowData.ewdData?.lastStatusEvent?.timeAt
          }
        />
      ),
    width: '9%',
    style: { whiteSpace: 'normal' },
    sort: {
      name: 'Last Update',
      sortFn: (a, b) => {
        const valueA =
          (a.ewdData?.checkpointAt ||
            a.ewdData?.pivotTimeAt ||
            a.ewdData?.lastStatusEvent?.timeAt) ??
          -1;
        const valueB =
          (b.ewdData?.checkpointAt ||
            b.ewdData?.pivotTimeAt ||
            b.ewdData?.lastStatusEvent?.timeAt) ??
          -1;
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  } /*
  {
    label: 'Hours Worked Since Last Rest',
    dataKey: 'hoursWorkdToday',
    cellRenderer: hoursWorkedCellRenderer,
    width: '9%',
    sort: {
      name: 'Hours Worked Since Last Rest',
      sortFn: (a, b) => {
        const valueA = a.ewdData?.work24?.work ?? -1;
        const valueB = b.ewdData?.work24?.work ?? -1;
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },*/,
  {
    label: 'Hours Worked in Period',
    dataKey: 'available',
    cellRenderer: ({ isLoading = false, ...cellProps }) =>
      isLoading ? <CellContentLoader /> : availableCellRenderer(cellProps),
    width: '9%',
    sort: {
      name: 'Hours Worked in Period',
      sortFn: (a, b) => {
        const valueA =
          a.ewdData?.totals?.period?.finishAt > moment().valueOf()
            ? a.ewdData?.totals?.worked ?? -1
            : -1;
        const valueB =
          b.ewdData?.totals?.period?.finishAt > moment().valueOf()
            ? b.ewdData?.totals?.worked ?? -1
            : -1;
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Major Rest Due In',
    dataKey: 'majorRestDue',
    cellRenderer: ({ isLoading = false, ...cellProps }) =>
      isLoading ? <CellContentLoader /> : majorRestDueCellRenderer(cellProps),
    width: '9%',
    sort: {
      name: 'Major Rest Due In',
      sortFn: (a, b) => {
        const getKey = rowData => {
          const restFieldKeys = ['24HrRest', '2NightRest', 'nightRest'];
          const key = restFieldKeys.reduce((key, currKey) => {
            if (rowData.ewdData?.[key]?.nextStartAt > rowData.ewdData?.[currKey]?.nextStartAt) {
              return currKey;
            } else {
              return key;
            }
          });
          return key;
        };

        const valueA =
          a.ewdData?.lastStatusEvent != null
            ? a.ewdData?.[getKey(a)]?.nextStartAt || (a.ewdData?.[getKey(a)] ? 0 : -1)
            : -1;
        const valueB =
          b.ewdData?.lastStatusEvent != null
            ? b.ewdData?.[getKey(b)]?.nextStartAt || (b.ewdData?.[getKey(b)] ? 0 : -1)
            : -1;
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Next Rest Due In',
    dataKey: 'nextRestDue',
    cellRenderer: ({ isLoading = false, ...cellProps }) =>
      isLoading ? <CellContentLoader /> : nextRestDueCellRenderer(cellProps),
    width: '9%',
    sort: {
      name: 'Next Rest Due In',
      sortFn: (a, b) => {
        function timeValue(ewdData) {
          const TypeRank = {
            NoEvent: -5,
            HasViolations: -4,
            HasPotentialViolations: -3,
            RestPastDue: -2,
            InResting: -1,
            Normal: 0
          };
          if (ewdData?.currentViolations?.length > 0) {
            if (ewdData.currentViolations.every(v => v.startAt > ewdData.checkpointAt)) {
              return TypeRank.HasPotentialViolations;
            }
            return TypeRank.HasViolations;
          }

          if (
            ewdData &&
            (!ewdData.lastStatusEvent ||
              moment()
                .subtract(7, 'day')
                .isAfter(moment(ewdData.lastStatusEvent.timeAt)))
          ) {
            return TypeRank.NoEvent;
          }

          const isResting = [/rest/i, /offduty/i, /sleep/i].some(reg =>
            ewdData?.lastStatusEvent?.type?.match(reg)
          );
          if (isResting) {
            return TypeRank.InResting;
          }

          return (
            (ewdData?.nextViolation?.start || ewdData?.nextViolation?.startAt) ??
            TypeRank.RestPastDue
          );
        }

        const valueA = timeValue(a.ewdData);
        const valueB = timeValue(b.ewdData);
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: '',
    dataKey: 'meatballs',
    cellRenderer: ({ rowData, isLoading = false }) =>
      isLoading ? (
        <CellContentLoader />
      ) : (
        <div
          style={{
            display: 'flex',
            height: '36px',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <MeatballsMenu rowData={rowData} />
          <ExpandButton
            buttonStyle={ButtonStyle.text}
            iconStyle={IconStyle.SignleArrow}
            onExpand={rowData.handleExpand}
            defaultExpand={rowData.expand}
          />
        </div>
      ),
    width: '9%'
  }
];

export const driverStatusCells = [
  [
    {
      title: 'Ruleset',
      dataKey: 'ruleset',
      cellRenderer: (rowData, dataKey, localization) => {
        return rowData?.ewdData?.ruleset?.name || '-';
      }
    },
    {
      title: 'Description',
      dataKey: 'rulesetDesc',
      cellRenderer: (rowData, dataKey, localization) => {
        return rowData?.ewdData?.ruleset?.desc || '-';
      }
    }
  ],
  [
    {
      title: 'Hours worked',
      dataKey: 'hoursWorked',
      cellRenderer: (rowData, dataKey, localization) => {
        return rowData.ewdData?.totals?.period?.finishAt > moment().valueOf()
          ? durationFormat(rowData?.ewdData?.totals?.worked * 1000) ?? '-'
          : '-';
      },
      enable: () => true
    },
    {
      title: 'Hours available',
      dataKey: 'hoursAvailable',
      cellRenderer: (rowData, dataKey, localization) => {
        return durationFormat(rowData?.ewdData?.totals?.available * 1000) ?? '-';
      },
      enable: () => true
    },
    {
      title: 'Last 24hr rest',
      dataKey: '24hrRest',
      cellRenderer: (rowData, dataKey, localization) => {
        return (
          toNowStamp(
            rowData?.ewdData?.['24HrRest']?.lastFinishAt,
            localization.formats.time.formats.dby_hms_z,
            localization.formats.time.formats.dby_hm
          ) ?? '-'
        );
      },
      enable: () => true
    },
    {
      title: 'Work since 24hr rest',
      dataKey: 'nightRestDue',
      cellRenderer: (rowData, dataKey, localization) => {
        if (
          rowData?.ewdData?.['84HrRule']?.period?.finishAt > moment().valueOf() ||
          rowData?.ewdData?.['70HrRule']?.period?.finishAt > moment().valueOf()
        ) {
          return (
            durationFormat(
              (rowData?.ewdData?.['84HrRule']?.work ?? rowData?.ewdData?.['70HrRule']?.work) * 1000
            ) ?? '-'
          );
        }
        return '-';
      },
      enable: rowData =>
        rowData?.ewdData?.['84HrRule'] != null || rowData?.ewdData?.['70HrRule'] != null
    },
    {
      title: '24hr rest due',
      dataKey: '24hrRestDue',
      cellRenderer: (rowData, dataKey, localization) => {
        return (
          toNowStamp(
            rowData?.ewdData?.['24HrRest']?.nextStartAt,
            localization.formats.time.formats.dby_hms_z,
            localization.formats.time.formats.dby_hm
          ) ?? '-'
        );
      },
      enable: () => true
    },
    {
      title: 'Long/night worked',
      dataKey: 'longNightWorked',
      cellRenderer: (rowData, dataKey, localization) => {
        return (
          durationFormat(
            (rowData?.ewdData?.longNightTotals?.excess + rowData?.ewdData?.longNightTotals?.night) *
              1000
          ) ?? '-'
        );
      },
      enable: rowData => rowData?.ewdData?.longNightTotals != null
    },
    {
      title: 'Night rests',
      dataKey: 'nightRests',
      cellRenderer: (rowData, dataKey, localization) => {
        return rowData?.ewdData?.nightRest?.count ?? '-';
      },
      enable: rowData => rowData?.ewdData?.nightRest?.count != null
    },
    {
      title: 'Night rest due',
      dataKey: 'nightRestDue',
      cellRenderer: (rowData, dataKey, localization) => {
        return (
          toNowStamp(
            rowData?.ewdData?.nightRest?.nextStartAt,
            localization.formats.time.formats.dby_hms_z,
            localization.formats.time.formats.dby_hm
          ) ?? '-'
        );
      },
      enable: rowData => rowData?.ewdData?.nightRest?.nextStartAt != null
    }
  ]
];
