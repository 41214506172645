import React from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { EllipsisMenu } from 'components/tables/EllipsisMenu';
import { Checkbox, Tooltip } from 'antd';

//styles
import styles from './Roles.module.scss';
import i18n from 'i18next';
import { BUTTON_IDS } from 'utils/globalConstants';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const nameCellRenderer = ({ dataKey, parent, rowIndex, rowData, canView }) => {
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      {canView ? (
        <Link to={`/settings/roles/id/${rowData.id}`}>{rowData.name}</Link>
      ) : (
        <span>{rowData.name}</span>
      )}
    </CellMeasurer>
  );
};

export const companyCellRenderer = ({ rowData, companies }) => {
  const role = rowData;
  if (role.global) {
    return <div>{i18n.t('Users.Table.Global')}</div>;
  }
  const company = companies.find(comp => comp.id === role.companyId);
  return <div>{company && company.name}</div>;
};

export const descriptionCellRenderer = ({ rowData }) => {
  return (
    rowData.description && (
      <div className={styles.description}>{rowData.description.replace('NextGen', 'TN360')}</div>
    )
  );
};

export const ellipsisButtonCellRenderer = ({ rowData, deleteModal, canEditGlobalRoles, can }) => {
  const deleteAction = () => {
    deleteModal(rowData);
  };

  const restrictVisibility = canEditGlobalRoles || !rowData.global;

  const menuItems = [
    {
      label: `${i18n.t('Common.EllipsisButton.View')}`,
      link: `/settings/roles/id/${rowData.id}`,
      entity: 'VIEW',
      visible: true,
      id: 'btn_roleView'
    },
    {
      label: `${i18n.t('Common.EllipsisButton.Edit')}`,
      link: `/settings/roles/edit/id/${rowData.id}`,
      entity: 'UPDATE',
      visible: restrictVisibility,
      id: 'btn_roleEdit'
    },
    {
      label: `${i18n.t('Common.EllipsisButton.Copy')}`,
      link: `/settings/roles/copy/id/${rowData.id}`,
      entity: ['CREATE', 'COPY'],
      visible: true,
      id: 'btn_roleCopy'
    },
    {
      label: `${i18n.t('Common.EllipsisButton.Delete')}`,
      onClick: deleteAction,
      entity: 'DESTROY',
      danger: true,
      visible: restrictVisibility,
      id: 'btn_roleDelete'
    }
  ];

  const allowedMenuItems = menuItems.filter(
    item =>
      can({
        everyEntity: Array.isArray(item.entity)
          ? item.entity.map(ent => `ROLE_${ent}`)
          : `ROLE_${item.entity}`
      }) && item.visible
  );

  return (
    <EllipsisMenu
      menuItems={allowedMenuItems}
      menuStyle={{ width: '194px', top: '-80px', right: '45px' }}
    />
  );
};

export const nameSubjectRenderer = ({ dataKey, parent, rowIndex, rowData, isForm, t }) => {
  const className = !isForm || rowData.isEnabled ? styles.description : styles.descriptionDisabled;
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      <div className={className}>{t(`Entity.${rowData.name}`, rowData.name || '')}</div>
    </CellMeasurer>
  );
};

export const selectedCellRenderer = ({ rowData }) => {
  const { permissions, maxNumberPermissions } = rowData;
  if (Array.isArray(permissions) && permissions?.length) {
    return (
      <div className={styles.description}>
        <span className={styles.permissionsCounter}>
          {permissions?.length}/ {maxNumberPermissions}
        </span>{' '}
        {i18n.t('Roles.EntityTable.Permissions')}
      </div>
    );
  }
  return <div className={styles.description}>{i18n.t('Roles.EntityTable.NoPermissions')}</div>;
};

export const actionButtonCellRenderer = ({
  rowData,
  handleClickPermissions,
  isForm,
  buttonActionText
}) => {
  const handleClick = () => {
    handleClickPermissions(rowData.name);
  };
  return (
    <div className={styles.buttonCell}>
      <button
        className={`${styles.permissionsButton} ${rowData.viewPermissions && ` ${styles.active}`}`}
        id={BUTTON_IDS.rolesPermissions}
        onClick={handleClick}
        type="button"
        onBlur={rowData.viewPermissions && !isForm ? handleClick : () => {}}
      >
        {`${buttonActionText} ${i18n.t('Roles.EntityTable.Permissions')}`}
      </button>
    </div>
  );
};

export const checkboxCellRenderer = ({ rowData, isForm, handleClickModule }) => {
  return (
    <Tooltip
      title={isForm && !rowData.isEnabled ? i18n.t('Roles.EntityTable.PermissionNotAvailable') : ''}
    >
      <Checkbox
        checked={rowData.isChecked}
        disabled={!isForm || !rowData.isEnabled}
        onChange={handleClickModule}
        nameModule={rowData.name}
      />
    </Tooltip>
  );
};
