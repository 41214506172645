import React from 'react';
import { LoadingTable } from 'components/grid/LoadingTable';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { Link } from 'react-router-dom';

import {
  cache,
  detailsCellRenderer,
  deviceNameCellRenderer,
  vehicleCellRenderer,
  sortableHeaderRenderer
} from '../components/CellRenderers';
import { useTranslation } from 'react-i18next';
import { useCan, entities } from 'features/permissions';

export const DevicesTable = ({ devices, isLoading, handleSort, sortBy, sortDirection }) => {
  const can = useCan();
  const canViewDevice = can && can({ everyEntity: [entities.DEVICE_VIEW] });
  const canViewVehicle = can && can({ everyEntity: [entities.VEHICLE_VIEW] });
  const { t } = useTranslation();
  if (isLoading) return <LoadingTable columnSizes={[200, 225, 100, 150, 150, 150]} />;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          name: 200,
          details: 225,
          type: 100,
          model: 100,
          vehicle: 150,
          vehicleType: 150
        };

        const dynamicColumnWidth =
          width -
          (fixedColumnsWidth.name +
            fixedColumnsWidth.details +
            fixedColumnsWidth.type +
            fixedColumnsWidth.model +
            fixedColumnsWidth.vehicle +
            fixedColumnsWidth.vehicleType);

        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerHeight={52}
            headerStyle={{ paddingTop: '18px' }}
            rowClassName="tableRow"
            rowHeight={cache.rowHeight}
            rowCount={devices.length}
            rowGetter={({ index }) => devices[index]}
            rowStyle={{ alignItems: 'flex-start' }}
          >
            <Column
              label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Device Name')}
              dataKey="name"
              width={fixedColumnsWidth.name}
              cellRenderer={props => deviceNameCellRenderer({ ...props, canViewDevice })}
              headerRenderer={() =>
                sortableHeaderRenderer({
                  label: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Device Name'),
                  name: 'name',
                  sortBy,
                  sortDirection,
                  handleSort
                })
              }
            />
            <Column
              label={t('Common.Fleets')}
              dataKey="fleet"
              width={fixedColumnsWidth.details}
              cellRenderer={props => detailsCellRenderer({ ...props })}
            />
            <Column
              label={t('Devices.Table.TypeLabel')}
              dataKey="type"
              width={fixedColumnsWidth.type}
              cellRenderer={({ cellData }) => <div>{cellData.name}</div>}
            />
            <Column
              label={t('Devices.ActualForm.ModelLabel')}
              dataKey="model"
              width={fixedColumnsWidth.model}
              cellRenderer={({ cellData }) => <div>{cellData.name}</div>}
              headerRenderer={() =>
                sortableHeaderRenderer({
                  label: t('Devices.ActualForm.ModelLabel'),
                  name: 'model',
                  sortBy,
                  sortDirection,
                  handleSort
                })
              }
            />
            <Column
              label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Vehicle Name')}
              dataKey="vehicle"
              width={fixedColumnsWidth.vehicle}
              cellRenderer={props => vehicleCellRenderer({ ...props, canViewVehicle })}
              headerRenderer={() =>
                sortableHeaderRenderer({
                  label: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Vehicle Name'),
                  name: 'vehicle',
                  sortBy,
                  sortDirection,
                  handleSort
                })
              }
            />
            <Column
              label={t('Common.VehicleType')}
              dataKey="vehicleType"
              width={fixedColumnsWidth.vehicleType}
              cellRenderer={({ rowData }) => <div>{rowData.vehicle?.type?.name}</div>}
              headerRenderer={() =>
                sortableHeaderRenderer({
                  label: t('Common.VehicleType'),
                  name: 'vehicleType',
                  sortBy,
                  sortDirection,
                  handleSort
                })
              }
            />
            <Column
              label={t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Template')}
              dataKey="template"
              width={dynamicColumnWidth}
              cellRenderer={({ cellData }) => (
                <Link to={`/settings/company_config/speedassist/id/${cellData?.id}`}>
                  {cellData?.name}
                </Link>
              )}
              headerRenderer={() =>
                sortableHeaderRenderer({
                  label: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Template'),
                  name: 'template',
                  sortBy,
                  sortDirection,
                  handleSort
                })
              }
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};
