import { fetchFleets } from 'features/fleets/fleetsSlice';
import store from '../../store';

export const deviceCapabilities = Object.freeze({
  TowAway: 'TowAway',
  HarshManeuveurs: 'HarshManeuveurs',
  Impact: 'Impact'
});

const bitsCompare = (a, b) =>
  a[0] > b[0] || (a[0] === b[0] && a[1] > b[1]) || (a[0] === b[0] && a[1] === b[1] && a[2] >= b[2]);

const hermesVersionCheck = (ver, minVerBits) => {
  let meetMinmumVer = false;
  if (ver?.length > 2 && ver.substr(0, 1).toUpperCase() === 'A') {
    const versionBits = ver
      .replace('A', '')
      .split('.')
      .map(v => parseInt(v));
    meetMinmumVer = bitsCompare(versionBits, minVerBits);
  }
  return meetMinmumVer;
};

/**
 * the firmware version of HERMES uses the pattern AX.XX.XX, where X is a number
 */
export const deviceFirmwareCapabilities = [
  {
    version: 'A0.26.1',
    deviceTypes: ['HERMES'],
    capabilities: [
      deviceCapabilities.TowAway,
      deviceCapabilities.HarshManeuveurs,
      deviceCapabilities.Impact
    ],
    condition: device =>
      device?.type?.code === 'HERMES' && hermesVersionCheck(device.releaseVersion, [0, 26, 1])
  },
  {
    version: 'A0.24.1',
    deviceTypes: ['HERMES'],
    capabilities: [deviceCapabilities.TowAway],
    condition: device =>
      device?.type?.code === 'HERMES' && hermesVersionCheck(device.releaseVersion, [0, 24, 1])
  }
];

export const getCompanyDeviceFeatures = (companyId, devices, ignoreCompanyId = false) => {
  const features = Object.keys(deviceCapabilities).reduce((prev, curr) => {
    prev[curr] = false;
    return prev;
  }, {});

  if ((companyId || ignoreCompanyId) && devices) {
    let companyDevices = Object.values(devices);
    if (!ignoreCompanyId) {
      companyDevices = companyDevices.filter(d => d.company?.id === companyId);
    }

    for (const d of companyDevices) {
      //check firmware version
      for (const firmware of deviceFirmwareCapabilities) {
        if (firmware.condition(d)) {
          firmware.capabilities.forEach(c => {
            features[c] = true;
          });
        }
      }

      if (Object.values(features).every(v => v)) {
        break;
      }
    }
  }
  return features;
};
/**
 * create a function to check if there are any devices of current company meet the required features
 */
export const companyDeviceFeaturesCheck = (requiredFeatures, ignoreCompanyId = false) => {
  /**
   * @see {@link fleetsSlice.js} for the devices reducer structure
   * @see {@link companySlice.js} for the companies reducer structure
   */
  const devices = store.getState().devices.list;
  const lastTimeDeviceFetched = store.getState().fleets.meta.lastFetched;
  if (devices.length === 0 && !lastTimeDeviceFetched) {
    store.dispatch(fetchFleets());
  }
  const companyId = store.getState().companies?.current?.id;
  const companyDeviceFeatures = getCompanyDeviceFeatures(companyId, devices, ignoreCompanyId);

  if (requiredFeatures?.every(f => companyDeviceFeatures[f])) {
    return true;
  }
  return false;
};
