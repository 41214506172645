import { format } from 'utils/dates';

const getVehicleDetails = vehicle => {
  if (!vehicle) {
    return;
  }
  return `${vehicle?.name} (${vehicle?.registrationState || ''} | ${vehicle?.registration || ''})`;
};

const getVehiclePhoneDetails = (vehicle, t) => {
  if (!vehicle?.phone) {
    return;
  }
  return `${vehicle?.phone || t('Common.NA')}`;
};

const getUserDetails = user => {
  if (!user) {
    return;
  }
  return `${user.firstName} ${user.lastName}`;
};

const getUserPhoneDetails = (user, t) => {
  if (!user?.mobile) {
    return;
  }
  return `${user?.mobile || t('Common.NA')}`;
};

const getSatsHDOP = (gps, t) => {
  if (!gps || !t) {
    return;
  }
  return `${gps.NSat || t('Common.NA')} / ${gps.HDOP || t('Common.NA')}`;
};

const getSpeed = (speed, localization) => {
  if (!speed?.toString()) {
    return;
  }
  return `${localization.convertSpeed(speed)} ${localization.formats.speed.unit_per_hour}`;
};

const getNoteLabel = (alert, t, localization) => {
  if (!alert?.status || !t) {
    return t('Alerts.DuressModal.Note');
  }
  const { status } = alert;
  return `${t('Alerts.DuressModal.AcknowledgedBy')} ${status?.user?.firstName} ${
    status?.user?.lastName
  } ${t('Alerts.DuressModal.At')} ${getFormattedDate(status?.timeAt, localization)}`;
};

const getFormattedDate = (date, localization) => {
  if (!date) {
    return;
  }
  return format(new Date(date), localization.formats.time.formats.dby_imp);
};

export const duressHelpers = {
  getVehicleDetails,
  getUserDetails,
  getSatsHDOP,
  getSpeed,
  getNoteLabel,
  getUserPhoneDetails,
  getVehiclePhoneDetails
};
