import React, { useState, useRef, useCallback, useEffect } from 'react';
import styles from './NotificationPopup.module.scss';
import { BadgeIconButton } from './BadgeIconButton';
import { Overlay } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button, Spin, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { useDispatch } from 'react-redux';
import {
  dismissNotifications,
  useLastNotificationFetchDate
} from 'features/notifications/notificationsSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  fetchToggleUserAlertsStatus,
  toggleUserAlerts,
  useIsUserAlertsEnabled,
  useIsUserAlertsEnabledFetching,
  useIsUserAlertsToggleSaving
} from 'features/user_alerts';
import { BUTTON_IDS } from 'utils/globalConstants';
export const NotificationPopupContent = props => {
  const { t } = useTranslation();
  const {
    notifications,
    richNotifications,
    width = 450,
    height = 600,
    NotificationRender,
    RichNotificationsRender,
    mutedAlarms,
    setMutedAlarms
  } = {
    ...props
  };
  const dispatch = useDispatch();
  const lastNotificationFetchDate = useLastNotificationFetchDate();

  const [isLoading, setIsLoading] = useState(false);

  const isUserAlertsEnabledFetching = useIsUserAlertsEnabledFetching();
  const isUserAlertsToggleSaving = useIsUserAlertsToggleSaving();

  const currentCompany = useCurrentCompany();

  useEffect(() => {
    setIsLoading(isUserAlertsEnabledFetching || isUserAlertsToggleSaving);
  }, [isUserAlertsEnabledFetching, isUserAlertsToggleSaving]);

  const handleMuteAlarms = async activeStatus => {
    await dispatch(
      toggleUserAlerts({
        query: {
          active: activeStatus
        },
        successToaster: () =>
          openToast({
            type: ToastType.Success,
            message: mutedAlarms
              ? t('Alerts.Toast.AlertsSuccessfullyDisabled')
              : t('Alerts.Toast.AlertsSuccessfullyEnabled')
          }),
        errorToaster: err =>
          openToast({
            type: ToastType.Error,
            message: mutedAlarms
              ? t('Alerts.Toast.AlertsDisableFail')
              : t('Alerts.Toast.AlertsEnableFail')
          })
      })
    );
    await dispatch(
      fetchToggleUserAlertsStatus({
        query: {
          company_id: currentCompany.id
        }
      })
    );
  };

  const doDismiss = useCallback(() => {
    dispatch(
      dismissNotifications(
        moment()
          .subtract(10, 'day')
          .startOf('day')
          .toDate(),
        lastNotificationFetchDate ? new Date(lastNotificationFetchDate) : new Date()
      )
    ).then(
      () => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('Notifications.Your recent notifications dismissed successfully') + '.'
          })
        );
      },
      err => {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: err?.message || t('Notifications.Dismiss failed')
          })
        );
      }
    );
  }, [dispatch, t, lastNotificationFetchDate]);

  const handleDismissAll = useCallback(() => {
    confirmationModal(
      '',
      t('Notifications.Are you sure to dismiss all notifications') + '?',
      t('Common.Yes'),
      t('Common.CancelButton'),
      doDismiss,
      '',
      () => {}
    );
  }, [t]);
  return (
    <div
      className={styles.notificationPopupContent}
      style={{
        display: 'flex',
        width: width,
        height: height
      }}
    >
      {!!richNotifications?.length && (
        <RichNotificationsRender
          richNotifications={richNotifications}
          onViewEmbedDetail={props.onViewEmbedDetail}
        />
      )}
      <Spin spinning={isLoading}>
        <div className={styles.notificationHeader}>
          <label style={{ display: 'flex', marginBottom: 0, justifyContent: 'center' }}>
            {t('Notifications.RecentNotification')} ({(notifications && notifications.length) || 0})
          </label>

          <div className={styles.notificationIconContainer}>
            <Tooltip
              placement="left"
              title={
                mutedAlarms ? t('Notifications.DisableAlerts') : t('Notifications.EnableAlerts')
              }
            >
              <Button
                className={styles.dismissAll}
                id={BUTTON_IDS.muteNotif}
                type="link"
                onClick={() => {
                  setMutedAlarms(!mutedAlarms);
                  handleMuteAlarms(!mutedAlarms);
                }}
              >
                {mutedAlarms ? (
                  <FontAwesomeIcon icon={faBell} />
                ) : (
                  <FontAwesomeIcon icon={faBellSlash} />
                )}
              </Button>
            </Tooltip>
            <Tooltip placement="left" title={t('Notifications.GoToAlerts')}>
              <Link
                to={{
                  pathname: `/settings/user/alerts/`
                }}
              >
                <Button className={styles.dismissAll} id={BUTTON_IDS.goToNotif} type="link">
                  <FontAwesomeIcon icon={faCog} />
                </Button>
              </Link>
            </Tooltip>
            {notifications && notifications.length > 0 && (
              <Tooltip placement="left" title={t('Notifications.DismissAllNotifications')}>
                <Button
                  className={styles.dismissAll}
                  id={BUTTON_IDS.dismissAllNotif}
                  type="link"
                  onClick={handleDismissAll}
                >
                  <FontAwesomeIcon icon={faCheckDouble} />
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
        <NotificationRender
          alertData={notifications}
          onViewEmbedDetail={props.onViewEmbedDetail}
        ></NotificationRender>
      </Spin>
    </div>
  );
};

export const NotificationButton = ({
  notifications,
  alertListRender,
  richNotifications,
  RichNotificationsRender,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const [mutedAlarms, setMutedAlarms] = useState(false);
  const toggleActive = useIsUserAlertsEnabled();

  useEffect(() => {
    setMutedAlarms(toggleActive);
  }, [toggleActive]);

  const popupRef = useRef(null);
  const AlertListRender = alertListRender;
  return (
    <>
      <BadgeIconButton
        ref={popupRef}
        count={notifications?.length + richNotifications?.length}
        onClick={() => setShow(!show)}
        mutedAlarms={mutedAlarms}
      />
      <Overlay
        target={popupRef.current}
        show={show}
        rootClose={true}
        onHide={() => setShow(false)}
        placement="bottom"
      >
        {({ placement, scheduleUpdate, arrowProps, outOfBoundaries, show: _show, ...props }) => (
          <div
            {...props}
            className={props.className + ' ' + styles.notificationPopup}
            style={props.style}
          >
            <NotificationPopupContent
              notifications={notifications}
              richNotifications={richNotifications}
              NotificationRender={AlertListRender}
              RichNotificationsRender={RichNotificationsRender}
              onViewEmbedDetail={() => setShow(false)}
              mutedAlarms={mutedAlarms}
              setMutedAlarms={setMutedAlarms}
            ></NotificationPopupContent>
          </div>
        )}
      </Overlay>
    </>
  );
};
