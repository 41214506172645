import React, { useState, useCallback, useEffect } from 'react';
import { CellMeasurerCache } from 'react-virtualized';
import { NavLink } from 'react-router-dom';
import styles from './fatigue.module.scss';
import * as DriveTimeRenderer from './driveTimeCellRenderer';
import * as RestTimeRenderer from './restTimeCellRenderer';
import i18n from 'i18next';
import * as RDTRenderer from './rdtCellRenderer';
import { Button } from 'antd';
import { BUTTON_IDS } from 'utils/globalConstants';

export const FatigueCellCache = new CellMeasurerCache({
  fixedHeight: false,
  fixedWidth: true,
  minWidth: 40,
  minHeight: 90
});

export function getClassOfDriverState(mode) {
  switch (mode) {
    case 0: //rest
      return styles.restState;
    case 1: //available
      return styles.poaState;
    case 2: //work
      return styles.workState;
    case 3: //drive
    default:
      return styles.driveState;
  }
}

export function getClassOfDriverStatus(mode) {
  switch (mode) {
    case 0: //rest
      return styles.restStatus;
    case 1: //available
      return styles.poaStatus;
    case 2: //work
      return styles.workStatus;
    case 3: //drive
    default:
      return styles.driveStatus;
  }
}

export function stateCellRendererForAntTable({ dataKey, rowData, ...props }) {
  const status =
    rowData.fatigueStatus && rowData.fatigueStatus.mode != null ? rowData.fatigueStatus.mode : 3;
  const className = getClassOfDriverStatus(status);
  return (
    <div className={className}>
      <span>&nbsp;</span>
      <span>
        <i />
      </span>
    </div>
  );
}

export function stateCellRenderer({ dataKey, rowData, ...props }) {
  const status =
    rowData.fatigueStatus && rowData.fatigueStatus.mode != null ? rowData.fatigueStatus.mode : 3;
  const className = getClassOfDriverState(status);
  return (
    <div className={className}>
      <i />
    </div>
  );
}

export function driverCellRenderer({ dataKey, rowData, ...props }) {
  if (rowData.user) {
    return (
      <div>
        <NavLink
          className={styles.link}
          to={`/settings/users/id/${rowData.user && rowData.user.user_id}`}
        >
          {rowData['driver']}
        </NavLink>
      </div>
    );
  }
  return <p>{rowData['driver']}</p>;
}

export function normalCellRenderer({ dataKey, rowData, ...props }) {
  return (
    <div style={{ height: '100%' }}>
      <span
        style={{ display: 'flex', alignItems: 'center', height: '100%', lineHeight: 'initial' }}
      >
        {rowData[dataKey]}
      </span>
    </div>
  );
}

export function noRowsRenderer() {
  return <h3 className={styles.noRow}>{i18n.t('Vehicles.Form.NoData')}</h3>;
}

export function driveTimeCellRenderer({ dataKey, rowData, ...props }) {
  const status =
    rowData.fatigueStatus && rowData.fatigueStatus.mode != null ? rowData.fatigueStatus.mode : 3;
  switch (status) {
    case 0: //rest
      return DriveTimeRenderer.driveTimeCell_Rest(rowData);
    case 1: //available
    case 2: //work
      return DriveTimeRenderer.driveTimeCell_POA(rowData);
    case 3: //drive
    default:
      return DriveTimeRenderer.driveTimeCell_Drive(rowData);
  }
}

export function restTimeCellRenderer({ dataKey, rowData, ...props }) {
  const status =
    rowData.fatigueStatus && rowData.fatigueStatus.mode != null ? rowData.fatigueStatus.mode : 3;
  switch (status) {
    case 0: //rest
      return RestTimeRenderer.restTimeCell_Rest(rowData);
    case 1: //available
    case 2: //work
      return RestTimeRenderer.restTimeCell_POA(rowData);
    case 3: //drive
    default:
      return RestTimeRenderer.restTimeCell_Drive(rowData);
  }
}

export function rdtCellRenderer({ dataKey, rowData, ...props }) {
  return RDTRenderer.rdtCell(rowData);
}

function ExpandButton({ defaultExpand, onExpand, ...props }) {
  const [expand, setExpand] = useState(defaultExpand);
  const handleExpand = useCallback(() => {
    setExpand(!expand);
    if (onExpand) {
      onExpand(!expand);
    }
  }, [expand]);

  useEffect(() => {
    setExpand(defaultExpand);
  }, [defaultExpand]);

  return (
    <Button
      className={styles.expandBtn}
      type="text"
      onClick={handleExpand}
      id={BUTTON_IDS.tachoExpand}
    >
      <span>
        <i className={expand ? 'tn-i-chevron-up' : 'tn-i-chevron-down'} />
      </span>
    </Button>
  );
}

export function expandCellRenderer({ dataKey, rowData, rowIndex, onExpand, ...props }) {
  return (
    <div style={props?.style || { width: '100%', textAlign: 'right' }}>
      <ExpandButton
        onExpand={expand => {
          rowData.onExpand(rowIndex, expand, rowData);
          if (onExpand) {
            onExpand(rowData);
          }
        }}
        defaultExpand={rowData.expand}
      />
    </div>
  );
}
