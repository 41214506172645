import { EllipsisButton } from 'components/tables/EllipsisButton';
import { default as React } from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { format } from 'utils/dates';
import styles from 'containers/Administration/Devices/Devices.module.scss';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 52
});

export const nameCellRenderer = ({ dataKey, parent, rowIndex, rowData, canViewVehicleMntType }) => {
  return canViewVehicleMntType ? (
    <Link to={`/settings/vehicleMntTypes/id/${rowData.id}`}>{rowData.name}</Link>
  ) : (
    <span>{rowData.name}</span>
  );
};

export const companyCellRenderer = ({ dataKey, parent, rowIndex, rowData, companies }) => {
  const company = companies.find(company => company.id === rowData.companyId);
  return <div>{company.name}</div>;
};

export const descriptionCellRenderer = ({ dataKey, parent, rowIndex, rowData }) => {
  return rowData.description;
};

export const vehicleTypeCellRenderer = ({ dataKey, parent, rowIndex, rowData }) => {
  const typeNames = rowData.vehicleTypes.map((type, i) => (
    <div key={`row-${dataKey}-${rowData.id}-${i}`}>
      {type.name}
      {i < rowData.vehicleTypes.length - 1 ? ',' : ''}
    </div>
  ));
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${rowData.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div className={styles.cellMultilineContainer}>
        <div>{typeNames}</div>
      </div>
    </CellMeasurer>
  );
};

export const createdAtCellRenderer = ({ dataKey, parent, rowIndex, rowData, localization }) => {
  return format(new Date(rowData.createdAt), localization.formats.time.formats.dby_imp);
};

export const ellipsisButtonCellRenderer = ({
  dataKey,
  rowData,
  typeOfEntityToDelete,
  hideEdit,
  hideDelete,
  enableRestoreAction,
  handleAction,
  handleDeleteAction
}) => {
  return (
    <EllipsisButton
      dataKey={dataKey}
      typeOfEntityToDelete={typeOfEntityToDelete}
      path={'/settings/vehicleMntTypes/'}
      data={rowData}
      canUse="VEHICLEMAINTENANCETYPE"
      hideEdit={hideEdit}
      hideDelete={hideDelete}
      enableRestoreAction={enableRestoreAction}
      handleAction={handleAction}
      handleDeleteAction={handleDeleteAction}
    />
  );
};
