import React from 'react';
import { Route, Switch } from 'react-router';

import { VehicleMaintenance } from './VehicleMaintenance';
import { VehicleMaintenanceSchedules } from './VehicleMaintenanceSchedules';
import { ScheduleAddEditForm } from './ScheduleAddEditForm';
import { ScheduleForm } from './ScheduleForm';
import ScheduleView from './ScheduleView';
import ScheduleComplete from './ScheduleComplete';
import { ScheduleAudits } from './ScheduleAudits';
import { Can, entities } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { useCanFeatureFlag, FeatureFlag } from 'features/permissions';

import { Paths, RescheduleType } from './utils/constants';
import { InspectionsView } from './Inspections/InspectionsView';

export const VehicleMaintenanceRouter = () => {
  const showNewInspectionsPage = useCanFeatureFlag({
    featureFlag: FeatureFlag.vehMaintenanceInpspections.flag
  });
  return (
    <Switch>
      <Route path={Paths.VEHICLEMAINTENANCE_ADD}>
        <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_CREATE]} onFail={<NoAccessBody />}>
          <ScheduleAddEditForm action="add" />
        </Can>
      </Route>
      <Route path={Paths.VEHICLEMAINTENANCE_ADD_NEW}>
        <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_CREATE]} onFail={<NoAccessBody />}>
          <ScheduleForm />
        </Can>
      </Route>
      <Route path={Paths.VEHICLEMAINTENANCE_EDIT}>
        <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_UPDATE]} onFail={<NoAccessBody />}>
          <ScheduleAddEditForm action="edit" />
        </Can>
      </Route>
      <Route
        path={[
          `${Paths.VEHICLEMAINTENANCE_RESCHEDULE}/${RescheduleType.CURRENT}/id`,
          `${Paths.VEHICLEMAINTENANCE_RESCHEDULE}/${RescheduleType.SCHEDULED}/id`,
          `${Paths.VEHICLEMAINTENANCE_RESCHEDULE}/${RescheduleType.COMPLETED}/id`
        ]}
      >
        <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_UPDATE]} onFail={<NoAccessBody />}>
          <ScheduleAddEditForm action="reschedule" />
        </Can>
      </Route>
      <Route path={[Paths.VEHICLEMAINTENANCE_VIEW, Paths.VEHICLEMAINTENANCE_VIEW_OLD]}>
        <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_VIEW]} onFail={<NoAccessBody />}>
          <ScheduleView />
        </Can>
      </Route>
      <Route path={Paths.VEHICLEMAINTENANCE_COMPLETE}>
        <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_UPDATE]} onFail={<NoAccessBody />}>
          <ScheduleComplete />
        </Can>
      </Route>
      <Route path={Paths.VEHICLEMAINTENANCE_AUDITS}>
        <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE_AUDIT]} onFail={<NoAccessBody />}>
          <ScheduleAudits />
        </Can>
      </Route>
      <Route path={Paths.VEHICLEMAINTENANCE_INSPECTIONS_VIEW}>
        <InspectionsView />
      </Route>
      <Route
        path="/vehiclemaintenance/:schedules"
        render={({ match, history }) => {
          return (
            <Can everyEntity={[entities.VEHICLEMAINTENANCESCHEDULE]} onFail={<NoAccessBody />}>
              {showNewInspectionsPage ? (
                <VehicleMaintenance match={match} history={history} />
              ) : (
                <VehicleMaintenanceSchedules />
              )}
            </Can>
          );
        }}
      ></Route>
    </Switch>
  );
};
