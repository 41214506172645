import { useDispatch, useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import request from 'superagent';
import i18n from 'i18next';

import { ToastType } from 'components/notifications/toasts/Toast';

import { openToast } from 'features/toasts/toastsSlice';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { fetchMassConfigurations } from './massConfigurationsSlice';

import { onlyUnique } from 'utils/filters';
import { API_PATH } from 'config';

const initialState = {
  massConfigurations: [],
  massConfigurationsMap: {},
  vehicles: [],
  vehiclesMap: {},
  fleets: [],
  fleetsMap: {},
  meta: {
    error: null,
    lastFetched: null,
    isFetching: false,
    companyKey: null
  }
};

const VehicleConfigurationsSlice = createSlice({
  name: 'vehicleConfigurations',
  initialState: initialState,
  reducers: {
    fetchConfigurationsStart: state => {
      state.meta.isFetching = true;
    },
    fetchConfigurationsSucceeded: (state, { payload }) => {
      state.massConfigurations = payload.massConfigurations;
      state.massConfigurationsMap = payload.massConfigurationsMap;
      state.vehicles = payload.vehicles;
      state.vehiclesMap = payload.vehiclesMap;
      state.fleets = payload.fleets;
      state.fleetsMap = payload.fleetsMap;
      state.meta.error = null;
      state.meta.lastFetched = new Date().toISOString();
      state.meta.isFetching = false;
      state.meta.companyKey = payload.companyKey;
    },
    fetchConfigurationsFailed: (state, { payload }) => {
      state.massConfigurations = [];
      state.meta.error = payload.error;
      state.meta.lastFetched = new Date().toISOString();
      state.meta.isFetching = false;
      state.meta.companyKey = payload.companyKey;
    },
    updateConfigurationsStart: state => {
      state.meta.isFetching = true;
    },
    updateConfigurationsSucceeded: state => {
      state.meta.isFetching = false;
    },
    updateConfigurationsFailed: (state, { payload }) => {
      state.meta.isFetching = false;
      state.meta.error = payload.error;
    }
  }
});

const {
  fetchConfigurationsStart,
  fetchConfigurationsSucceeded,
  fetchConfigurationsFailed,
  updateConfigurationsStart,
  updateConfigurationsSucceeded,
  updateConfigurationsFailed
} = VehicleConfigurationsSlice.actions;

const getConfiguredVehiclesAndFleets = massConfigurations => {
  let vehicles = [],
    fleets = [];
  let massConfigurationsMap = {},
    vehiclesMap = {},
    fleetsMap = {};

  // invert mass configuration data
  massConfigurations.forEach((config, index, array) => {
    massConfigurationsMap[config.id] = array[index];

    // process vehicles
    config.vehicles &&
      config.vehicles.forEach(vehicle => {
        if (vehiclesMap[vehicle.id]) {
          if (!vehiclesMap[vehicle.id].massConfigurations.find(mass => mass.id === config.id)) {
            vehiclesMap[vehicle.id].massConfigurations.push({
              id: config.id,
              name: config.name,
              jurisdiction: config.jurisdiction,
              vehicleCombination: config.vehicleCombination
            });
          }
        } else {
          let length = vehicles.push({
            ...vehicle,
            massConfigurations: [
              {
                id: config.id,
                name: config.name,
                jurisdiction: config.jurisdiction,
                vehicleCombination: config.vehicleCombination
              }
            ]
          });
          vehiclesMap[vehicle.id] = vehicles[length - 1];
        }
      });

    // process fleets
    config.fleets &&
      config.fleets.forEach(fleet => {
        if (fleetsMap[fleet.id]) {
          if (!fleetsMap[fleet.id].massConfigurations.find(mass => mass.id === config.id)) {
            fleetsMap[fleet.id].massConfigurations.push({
              id: config.id,
              name: config.name,
              jurisdiction: config.jurisdiction,
              vehicleCombination: config.vehicleCombination
            });
          }
        } else {
          let length = fleets.push({
            ...fleet,
            massConfigurations: [
              {
                id: config.id,
                name: config.name,
                jurisdiction: config.jurisdiction,
                vehicleCombination: config.vehicleCombination
              }
            ]
          });
          fleetsMap[fleet.id] = fleets[length - 1];
        }
      });
  });

  return {
    massConfigurations: massConfigurations,
    massConfigurationsMap: massConfigurationsMap,
    vehicles: vehicles,
    vehiclesMap: vehiclesMap,
    fleets: fleets,
    fleetsMap: fleetsMap
  };
};

export const fetchConfigurations = () => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const companyId = getState().companies.current?.id;
  const companyKey = getState().companies.current.api_key;

  if (!userKey) {
    return;
  }

  dispatch(fetchConfigurationsStart());
  request('GET', `${API_PATH}/massmanagement/massschemeconfigs`)
    .query({
      embed: 'vehicles,fleets',
      ...(companyId && { company_id: companyId })
    })
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(res => {
      dispatch(
        fetchConfigurationsSucceeded({ ...getConfiguredVehiclesAndFleets(res.body), companyKey })
      );
    })
    .catch(error => {
      dispatch(fetchConfigurationsFailed({ error: error.toString(), companyKey }));
      console.error('fetchConfiguredVehicles:', error);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${error}`
        })
      );
    });
};

export const updateConfigurations = massConfiguration => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;

  if (!userKey) {
    return;
  }

  dispatch(updateConfigurationsStart());
  request('PUT', `${API_PATH}/massmanagement/vehicleconfig/${massConfiguration.id}`)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .send({
      ...massConfiguration,
      vehicles: massConfiguration.vehicles?.filter(id => !isNaN(id)), // filter bad vehicle id data
      fleets: massConfiguration.fleets?.filter(id => !isNaN(id)) // filter bad fleet id data
    })
    .then(res => {
      dispatch(updateConfigurationsSucceeded({ list: res.body }));
      dispatch(fetchMassConfigurations());
      dispatch(fetchConfigurations());
      dispatch(
        openToast({
          type: ToastType.Success,
          message: i18n.t('MassManagement.Message.Updated', {
            config: i18n.t('MassManagement.MassConfiguration'),
            name: massConfiguration.name
          })
        })
      );
    })
    .catch(error => {
      const errorMsg = error.response?.body?.message || error.toString();
      dispatch(updateConfigurationsFailed({ error: errorMsg }));
      console.error('updateMassConfiguration:', errorMsg);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18n.t('MassManagement.Message.UpdateError', {
            config: i18n.t('MassManagement.MassConfiguration'),
            name: massConfiguration.name,
            error: errorMsg
          })
        })
      );
    });
};

export const updateVehicleConfiguration = ({
  id,
  isVehicle,
  massConfigIds,
  massConfigNames
}) => async (dispatch, getState) => {
  const massConfigurationsMap = getState().vehicleConfigurations.massConfigurationsMap;
  const userKey = getState().user.current.auth.key;

  if (!userKey) {
    return;
  }

  Object.keys(massConfigNames).forEach(configId => {
    const isDeleted = !massConfigIds.includes(parseInt(configId));
    const mappedIds = isVehicle
      ? massConfigurationsMap[configId]?.vehicles
      : massConfigurationsMap[configId]?.fleets;
    const vehicleOrFleetIds = [
      ...(mappedIds ? mappedIds?.map(vehicleOrFleet => vehicleOrFleet.id) : []),
      ...(!isDeleted ? [id] : [])
    ].filter(onlyUnique);
    if (isDeleted) {
      vehicleOrFleetIds.splice(vehicleOrFleetIds.indexOf(id), 1);
    } // remove ID

    dispatch(updateConfigurationsStart());
    request('PUT', `${API_PATH}/massmanagement/vehicleconfig/${configId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send(isVehicle ? { vehicles: vehicleOrFleetIds } : { fleets: vehicleOrFleetIds })
      .then(res => {
        dispatch(updateConfigurationsSucceeded({ list: res.body }));
        dispatch(fetchMassConfigurations());
        dispatch(fetchConfigurations());
        dispatch(
          openToast({
            type: ToastType.Success,
            message: i18n.t('MassManagement.Message.Updated', {
              config: i18n.t('MassManagement.MassConfiguration'),
              name: massConfigNames[res.body.id]
            })
          })
        );
      })
      .catch(error => {
        const errorMsg = error.response?.body?.message || error.toString();
        dispatch(updateConfigurationsFailed({ error: errorMsg }));
        console.error('updateVehicleConfiguration:', errorMsg);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: i18n.t('MassManagement.Message.UpdateError', {
              config: i18n.t('MassManagement.MassConfiguration'),
              name: massConfigNames[configId],
              error: errorMsg
            })
          })
        );
      });
  });
};

export const updateFleetConfiguration = ({ ...props }) => {
  updateVehicleConfiguration({
    ...props,
    isVehicle: false
  });
};

export const useConfiguredVehicles = () => {
  const dispatch = useDispatch();
  const vehicles = useSelector(state => state.vehicleConfigurations.vehicles);
  const lastFetched = useSelector(state => state.vehicleConfigurations.meta.lastFetched);
  const isFetching = useSelector(state => state.vehicleConfigurations.meta.isFetching);
  const lastCompanyKey = useSelector(state => state.vehicleConfigurations.meta.companyKey);
  const isCompanyDifferent = useCurrentCompanyKey() !== lastCompanyKey;

  if (!isFetching && (!lastFetched || isCompanyDifferent)) {
    dispatch(fetchConfigurations());
  }

  return vehicles;
};

export const useConfiguredFleets = () => {
  const dispatch = useDispatch();
  const fleets = useSelector(state => state.vehicleConfigurations.fleets);
  const lastFetched = useSelector(state => state.vehicleConfigurations.meta.lastFetched);
  const isFetching = useSelector(state => state.vehicleConfigurations.meta.isFetching);
  const lastCompanyKey = useSelector(state => state.vehicleConfigurations.meta.companyKey);
  const isCompanyDifferent = useCurrentCompanyKey() !== lastCompanyKey;

  if (!isFetching && (!lastFetched || isCompanyDifferent)) {
    dispatch(fetchConfigurations());
  }

  return fleets;
};

export const useIsFetching = () =>
  useSelector(state => state.vehicleConfigurations.meta.isFetching);

export default VehicleConfigurationsSlice.reducer;
