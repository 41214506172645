import React from 'react';
import { Route, Switch } from 'react-router';
import { MessageTemplates } from 'containers/Messaging/MessageTemplates';
import { Messaging } from './Messaging';
import { Can, entities, services, useCanOneOfCompanyServices } from 'features/permissions';
import { NoAccessBody } from 'components/pages/NoAccess';
import { Loading } from 'components/loading/Loading';
import { useIsFetchingCurrentCompanyServices } from 'features/company/companySlice';

const centerLoaderStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1 0',
  margin: '0 auto'
};

export const MessagingRouter = props => {
  const isCompanyMessagingEnabled = useCanOneOfCompanyServices(services.MESSAGING);
  const isFetchingCompanyServices = useIsFetchingCurrentCompanyServices();
  return (
    <Switch>
      <Route exact path={'/messaging'}>
        {isFetchingCompanyServices ? (
          <Loading style={centerLoaderStyle} />
        ) : (
          <Can
            everyService={[services.MESSAGING]}
            everyEntity={[entities.MESSAGE, entities.MESSAGE_VIEW]}
            otherConditions={[() => isCompanyMessagingEnabled]}
            onFail={<NoAccessBody />}
          >
            <Messaging />
          </Can>
        )}
      </Route>
      <Route exact path={'/messaging/templates'}>
        {isFetchingCompanyServices ? (
          <Loading style={centerLoaderStyle} />
        ) : (
          <Can
            everyService={[services.MESSAGING]}
            everyEntity={[entities.MESSAGETEMPLATE, entities.MESSAGETEMPLATE_VIEW]}
            otherConditions={[() => isCompanyMessagingEnabled]}
            onFail={<NoAccessBody />}
          >
            <MessageTemplates />
          </Can>
        )}
      </Route>
    </Switch>
  );
};
