import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { useIQCameraDevicesConfig } from 'features/company_config';

export const IQCameraSensitivity = ({ deviceId, strong, bracket }) => {
  const { t } = useTranslation();
  const companyId = useCurrentCompanyId();

  const { iqCameraDevicesConfig } = useIQCameraDevicesConfig({
    companyId,
    deviceIds: [deviceId]
  });

  const cameraSensitivity = useMemo(
    () =>
      iqCameraDevicesConfig?.[deviceId]?.config.dutyType
        ? t(`CompanyConfig.IQCamera.${iqCameraDevicesConfig[deviceId].config.dutyType}`)
        : null,
    [t, deviceId, iqCameraDevicesConfig]
  );

  return cameraSensitivity ? (
    <Typography.Text strong={strong}>
      {bracket ? ` (${cameraSensitivity})` : cameraSensitivity}
    </Typography.Text>
  ) : null;
};
