import React from 'react';
import { Polyline } from 'react-google-maps';

import { VehicleMarker } from '../markers/VehicleMarker';

export const DroneViewRoute = ({ device, path }) => {
  return (
    <>
      {path && path.length > 0 && (
        <Polyline
          path={path.map(gps => {
            return {
              lat: gps.lat,
              lng: gps.lng
            };
          })}
          options={{
            strokeColor: '#de6ff7',
            strokeOpacity: 0.8,
            strokeWeight: 8
          }}
        />
      )}
      <VehicleMarker
        device={device}
        gpsUpdate={path && path.length ? path[path.length - 1] : null}
        showLabel={false}
      />
    </>
  );
};
