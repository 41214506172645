import i18n from 'i18next';
import { Paths } from '../../utils/constants';

export const GROUP_BY = {
  inspections: 'inspections',
  vehicles: 'vehicles'
};

export const VEHICLE_INSPECTIONS_TABS = {
  all: 'all',
  passed: 'passed',
  failed: 'failed',
  inprogress: 'inprogress',
  pendingdriversignoff: 'pendingdriversignoff',
  closed: 'closed'
};

export const INSPECTION_KEY = {
  VEHICLE: 'vehicle',
  INSPECTED_BY: 'inspectedBy',
  INSPECTION_DATE: 'inspectionDate',
  LINKED_SCHEDULE: 'linkedSchedule',
  REPAIRED_BY: 'repairedBy',
  REPAIR_DATE: 'repairDate',
  DETAILS: 'details',
  STATUS: 'status',
  NOTES: 'notes',
  ACTIONS: 'actions'
};

export const MainColumnKeys = {
  INSPECTION_NAME: 'inspectionName',
  INSTANCES: 'instances'
};

export const MainColumns = groupBy => [
  {
    title: groupBy === GROUP_BY.inspections ? 'Inspection name' : 'Vehicle',
    dataIndex: MainColumnKeys.INSPECTION_NAME,
    key: MainColumnKeys.INSPECTION_NAME
  },
  {
    title: 'Instances',
    dataIndex: MainColumnKeys.INSTANCES,
    key: MainColumnKeys.INSTANCES
  }
];

export const InspectionInnerColumns = groupBy => [
  {
    title: `${i18n.t('Inspections.Table.Status')}`,
    dataIndex: INSPECTION_KEY.STATUS,
    key: INSPECTION_KEY.STATUS
  },
  {
    title:
      groupBy === GROUP_BY.inspections
        ? `${i18n.t('Inspections.Table.Vehicle')}`
        : `${i18n.t('Inspections.Table.Inspection name')}`,
    dataIndex: INSPECTION_KEY.VEHICLE,
    key: INSPECTION_KEY.VEHICLE
  },
  {
    title: `${i18n.t('Inspections.Table.InspectionDate')}`,
    dataIndex: INSPECTION_KEY.INSPECTION_DATE,
    key: INSPECTION_KEY.INSPECTION_DATE
  },
  {
    title: `${i18n.t('Inspections.Table.InspectedBy')}`,
    dataIndex: INSPECTION_KEY.INSPECTED_BY,
    key: INSPECTION_KEY.INSPECTED_BY
  },
  {
    title: `${i18n.t('Inspections.Table.Details')}`,
    dataIndex: INSPECTION_KEY.DETAILS,
    key: INSPECTION_KEY.DETAILS
  },
  {
    title: `${i18n.t('Inspections.Table.LinkedSchedule')}`,
    dataIndex: INSPECTION_KEY.LINKED_SCHEDULE,
    key: INSPECTION_KEY.LINKED_SCHEDULE
  },
  {
    title: `${i18n.t('Inspections.Table.Notes')}`,
    dataIndex: INSPECTION_KEY.NOTES,
    key: INSPECTION_KEY.NOTES
  },
  {
    title: `${i18n.t('Inspections.Table.RepairDate')}`,
    dataIndex: INSPECTION_KEY.REPAIR_DATE,
    key: INSPECTION_KEY.REPAIR_DATE
  },
  {
    title: `${i18n.t('Inspections.Table.RepairedBy')}`,
    dataIndex: INSPECTION_KEY.REPAIRED_BY,
    key: INSPECTION_KEY.REPAIRED_BY
  },
  {
    title: '',
    dataIndex: INSPECTION_KEY.ACTIONS,
    key: INSPECTION_KEY.ACTIONS,
    width: 70
  }
];

export const InspectionsTabs = [
  {
    pathame: `${Paths.VEHICLEMAINTENANCE_INSPECTIONS}/${VEHICLE_INSPECTIONS_TABS.all}`,
    key: VEHICLE_INSPECTIONS_TABS.all
  },
  {
    pathame: `${Paths.VEHICLEMAINTENANCE_INSPECTIONS}/${VEHICLE_INSPECTIONS_TABS.passed}`,
    key: VEHICLE_INSPECTIONS_TABS.passed
  },
  {
    pathame: `${Paths.VEHICLEMAINTENANCE_INSPECTIONS}/${VEHICLE_INSPECTIONS_TABS.failed}`,
    key: VEHICLE_INSPECTIONS_TABS.failed
  },
  {
    pathame: `${Paths.VEHICLEMAINTENANCE_INSPECTIONS}/${VEHICLE_INSPECTIONS_TABS.inprogress}`,
    key: VEHICLE_INSPECTIONS_TABS.inprogress
  },
  {
    pathame: `${Paths.VEHICLEMAINTENANCE_INSPECTIONS}/${VEHICLE_INSPECTIONS_TABS.pendingdriversignoff}`,
    key: VEHICLE_INSPECTIONS_TABS.pendingdriversignoff
  },
  {
    pathame: `${Paths.VEHICLEMAINTENANCE_INSPECTIONS}/${VEHICLE_INSPECTIONS_TABS.closed}`,
    key: VEHICLE_INSPECTIONS_TABS.closed
  }
];

export const INSPECTION_TYPES = [
  {
    id: 'isDVIR',
    name: 'DVIR'
  },
  {
    id: 'nonDVIR',
    name: 'non-DVIR'
  }
];

export const PATHS = {
  VEHICLE_INSPECTIONS_VIEW: '/vehiclemaintenance/inspections/view',
  COMPLETE_DRIVERMANAGEMENTSCHEDULE: '/drivermanagement/complete/id'
};
