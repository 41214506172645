import React from 'react';

import { Tooltip, List, Checkbox, Row, Col } from 'antd';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';

import { useIsFetchingScorecardEvents } from 'features/scorecard';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';
import { format } from 'utils/dates';
import { Loading } from 'components/loading/Loading';
import { getEventAttributesByScorecardEventType } from './ScorecardEventsHelpers';
import { TabKeys } from './constants';

import { InfoCircleOutlined } from '@ant-design/icons';
import { RightOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';

import eventStyles from './ScorecardEvents.module.scss';

const getPenaltyPointsData = (event, entityType, smallList) => {
  let penaltyPoints = 0;
  let penaltyPointsTextClass = eventStyles.penaltyPointsTextExcluded;

  if (smallList) {
    penaltyPoints = entityType === TabKeys.drivers ? event.userPoints : event.vehiclePoints;
    if (event.excluded || event?.geofenceExcluded) {
      penaltyPoints = -penaltyPoints;
    }
    penaltyPointsTextClass = eventStyles.penaltyPointsTextSmall;
  } else if (!event.excluded && !event?.geofenceExcluded) {
    penaltyPoints = entityType === TabKeys.drivers ? event.userPoints : event.vehiclePoints;
    penaltyPointsTextClass = eventStyles.penaltyPointsText;
  }

  return { penaltyPoints, penaltyPointsTextClass };
};

const getEventListItemClass = (event, selected, smallList) => {
  let eventListItemClass = eventStyles.eventListItem;

  if (smallList) {
    eventListItemClass = eventStyles.eventListItemSmall;
  } else if (selected) {
    eventListItemClass = eventStyles.eventListItemSelected;
  } else if (event.excluded || event?.geofenceExcluded) {
    eventListItemClass = eventStyles.eventListItemExcluded;
  }

  return eventListItemClass;
};

const renderListItem = (
  event,
  key,
  style,
  checkedEvents,
  selectedEvent,
  entityType,
  canIncludeExcludeEvents,
  localization,
  t,
  onEventCheckedChange,
  onEventSelected,
  smallList,
  isEWDevent
) => {
  const onRowClick = e => {
    if (onEventSelected && !isEWDevent) onEventSelected(e, event);
  };

  const eventAttributes = getEventAttributesByScorecardEventType(event, localization);

  const { penaltyPoints, penaltyPointsTextClass } = getPenaltyPointsData(
    event,
    entityType,
    smallList
  );

  const eventChecked = checkedEvents[event.id] ? true : false;

  const selected = selectedEvent && event.id === selectedEvent.id;

  const eventListItemClass = getEventListItemClass(event, selected, smallList);

  const dateLabel = format(new Date(event.timeAt), localization.formats.time.formats.dby_imp);

  const excludedTooltipTitle = event?.geofenceExcluded
    ? t('Scorecard.ExcludedDueToGeofence')
    : t('Scorecard.ExcludedByUser');

  const dateTextClass = !smallList ? eventStyles.dateText : eventStyles.dateTextSmall;

  const locationTextClass = !smallList ? eventStyles.locationText : eventStyles.locationTextSmall;

  const eventTypeImageClass = !smallList
    ? eventStyles.eventTypeImage
    : eventStyles.eventTypeImageSmall;

  const horizontalLineClass = !smallList
    ? eventStyles.horizontalLine
    : eventStyles.horizontalLineSmall;

  const excludeIconOrLine =
    (event.excluded || event?.geofenceExcluded) && !smallList ? (
      <Tooltip title={excludedTooltipTitle} overlayStyle={{ maxWidth: '500px' }}>
        <FontAwesomeIcon className={eventStyles.excludedIcon} flip="horizontal" icon={faBan} />
      </Tooltip>
    ) : (
      <div className={horizontalLineClass} />
    );

  return (
    <List.Item key={key} style={style} className={eventListItemClass} onClick={onRowClick}>
      <Row align="middle" className={eventStyles.eventListItemRow}>
        <Col span={8}>
          {!smallList && canIncludeExcludeEvents && (
            <Checkbox checked={eventChecked} onChange={e => onEventCheckedChange(e, event)} />
          )}
          <span className={dateTextClass}>{dateLabel}</span>
        </Col>
        <Col span={1}>
          <div className={eventStyles.verticleLine} />
          {excludeIconOrLine}
        </Col>
        <Col span={2}>
          <img
            className={eventTypeImageClass}
            src={eventAttributes.markerSvg}
            alt={t(`Tracking.Events.${eventAttributes.label}`)}
          />
        </Col>
        <Col span={13}>
          <div>
            <div className={penaltyPointsTextClass}>
              {penaltyPoints} {t('Scorecard.PenaltyPoints')}
            </div>
            {!event.excluded && !event?.geofenceExcluded && (
              <Tooltip title={`${t('Scorecard.Bucket')} ${event.bucketNumber}`}>
                <InfoCircleOutlined className={eventStyles.bucketInfoIcon} />
              </Tooltip>
            )}
          </div>
          <div className={locationTextClass}>{event.location}</div>
          {selected && <RightOutlined className={eventStyles.selectedRightArrowIcon} />}
        </Col>
      </Row>
    </List.Item>
  );
};

export const ScorecardEventsList = ({
  events,
  checkedEvents,
  selectedEvent,
  entityType,
  onEventCheckedChange,
  onEventSelected,
  smallList,
  canIncludeExcludeEvents,
  isEWDevent
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();

  const isFetchingScorecardEvents = useIsFetchingScorecardEvents();

  const cellMeasureCache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 61
  });

  let heightOffset = 0;
  if (!smallList) {
    // Need to find more dynamic way to find this offset which is needed for list scroll to work correctly
    heightOffset = entityType === TabKeys.drivers ? 398 : 356;
  }

  return isFetchingScorecardEvents ? (
    <div
      className={eventStyles.eventListLoading}
      style={{ height: `calc(100% - ${heightOffset}px)` }}
    >
      <Loading />
    </div>
  ) : events?.length > 0 ? (
    <AutoSizer>
      {({ width, height }) => (
        <VList
          className={eventStyles.eventList}
          height={height - heightOffset}
          width={width}
          rowCount={events?.length || 0}
          overscanRowCount={3}
          deferredMeasurementCache={cellMeasureCache}
          rowHeight={cellMeasureCache.rowHeight}
          rowRenderer={({ index, parent, key, style }) => {
            const event = events[index];
            return (
              <CellMeasurer
                key={key}
                cache={cellMeasureCache}
                parent={parent}
                columnIndex={0}
                rowIndex={index}
              >
                {renderListItem(
                  event,
                  key,
                  style,
                  checkedEvents,
                  selectedEvent,
                  entityType,
                  canIncludeExcludeEvents,
                  localization,
                  t,
                  onEventCheckedChange,
                  onEventSelected,
                  smallList,
                  isEWDevent
                )}
              </CellMeasurer>
            );
          }}
        />
      )}
    </AutoSizer>
  ) : (
    <div className={eventStyles.eventListNoDataMessage}>{t('Scorecard.NoEvents')}</div>
  );
};
