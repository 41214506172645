import React, { useRef } from 'react';
import { Tooltip, Table, Checkbox, Button, Alert, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { toTitleCase } from 'utils/strings';
import { Comparators } from 'utils/sorting';

import { useUpdateDeviceGPOutputMutation } from 'services/nextgen';

import { useLocalization } from 'features/localization/localizationSlice';
import { openToast } from 'features/toasts/toastsSlice';

import { InfoTooltipHeaderCell } from 'components/tn/grid';
import { ToastType } from 'components/notifications/toasts/Toast';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { GPIOOccurrence, GPIOStatus } from 'containers/Administration/Vehicles/constants';
import { GPOutputUpdateTable } from '.';

import styles from 'components/tn/grid/grid.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const GPOutputsTable = ({ data, isLoading, deviceIMEI, disableImmoblise, ...props }) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const modalRef = useRef();
  const [updateDeviceGPOutput] = useUpdateDeviceGPOutputMutation();

  let isUpdateDelayConfirmed = false;
  let isUpdateDelayed = false;
  let isFormUpdated = false;
  let outputUpdate = {};

  const getDescription = rowData => {
    const displayValue = t(
      `Alerts.GPIO.${rowData.customDescription || rowData.description}`,
      rowData.customDescription || rowData.description
    );
    return displayValue ? displayValue : '-';
  };

  const statusCellRenderer = value => {
    const gpioStatus = value?.toLowerCase() === 'on' ? GPIOStatus.On : GPIOStatus.Off;

    return value ? (
      <>
        <Tooltip title={t(gpioStatus.key)} color={gpioStatus.color}>
          <div
            className={styles.cellStatus}
            style={{
              backgroundColor: gpioStatus.color
            }}
          />
        </Tooltip>
        <span
          style={{
            marginLeft: '10px'
          }}
        >
          {t(gpioStatus.key)}
        </span>
      </>
    ) : (
      '-'
    );
  };

  const dateTimeCellRenderer = value => {
    return value ? moment(value).format(localization.formats.time.formats.dby_imp) : '-';
  };

  const handleOutputStatusChanged = (output, channelID, isOn, nextStatus) => {
    console.debug('handleOutputStatusChanged', output, channelID, isOn, nextStatus);
    outputUpdate = {
      output,
      channelID,
      isOn,
      nextStatus
    };

    isFormUpdated = true;
    modalRef.current?.update({
      okButtonProps: { disabled: (isUpdateDelayed && !isUpdateDelayConfirmed) || !isFormUpdated }
    });
  };

  const handleOutputChangeDelayConfirmed = checked => {
    console.debug('handleOutputChangeDelayConfirmed - checked', checked);
    isUpdateDelayConfirmed = checked;
    modalRef.current?.update({
      okButtonProps: { disabled: (isUpdateDelayed && !isUpdateDelayConfirmed) || !isFormUpdated }
    });
  };

  const handleOutputUpdateConfirmed = () => {
    console.debug('handleOutputUpdateConfirmed - outputUpdate', outputUpdate);
    if (!outputUpdate?.channelID) {
      return;
    }

    updateDeviceGPOutput({
      imei: deviceIMEI,
      body: {
        channelNum: outputUpdate?.channelID,
        outputState: outputUpdate?.isOn ? 'on' : 'off'
      }
    })
      .then(result => {
        console.log('updateDeviceGPOutput - result', result);
        if (result.error) {
          throw new Error(result.error);
        }
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('GPIO.Toasts.UpdateOutputSuccess', {
              output: outputUpdate?.output?.channel,
              description: toTitleCase(outputUpdate?.output?.description),
              nextStatus: outputUpdate?.nextStatus
            })
          })
        );
      })
      .catch(error => {
        console.error('updateDeviceGPOutput - error', error);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('GPIO.Toasts.UpdateOutputError', {
              output: outputUpdate?.output?.channel,
              description: toTitleCase(outputUpdate?.output?.description)
            })
          })
        );
      });
  };

  const handleOutputUpdateCancelled = () => {
    outputUpdate = {};
  };

  const handleUpdateOutput = output => {
    outputUpdate = {
      output
    };
    isUpdateDelayed = parseInt(output.occurrence) === GPIOOccurrence.IgnitionOff.id;
    console.debug('handleUpdateOutput - isUpdateDelayed', isUpdateDelayed);

    modalRef.current = confirmationModal(
      t('Common.Modal.SureTitle'),
      <div style={{ margin: '2rem 0', fontSize: '1rem' }}>
        <div>{t('GPIO.Modals.SelectChannelStatus', { channel: output.channel })}</div>
        {isUpdateDelayed && (
          <Alert
            style={{ fontSize: 'inherit', margin: '0.5rem 0' }}
            message={t('GPIO.Modals.StatusChangeDelayedAlert')}
            type="warning"
            showIcon
          />
        )}

        <GPOutputUpdateTable
          style={{ margin: '1rem 0' }}
          data={output}
          onStatusChanged={handleOutputStatusChanged}
        />

        {isUpdateDelayed && (
          <Checkbox
            style={{ fontSize: 'inherit' }}
            onChange={e => handleOutputChangeDelayConfirmed(e.target.checked)}
          >
            {t('GPIO.Modals.StatusChangeDelayedConfirmation')}
          </Checkbox>
        )}
      </div>,
      t('Common.Modal.Confirm'),
      t('Common.Modal.Cancel'),
      handleOutputUpdateConfirmed,
      null,
      handleOutputUpdateCancelled,
      null,
      '50%',
      { okButtonProps: { id: 'btnConfirmUpdate', disabled: true } }
    );
  };

  const columns = [
    {
      title: t('GPIO.Tables.Status'),
      key: 'status',
      dataIndex: 'status',
      fixed: 'left',
      width: 140,
      sorter: Comparators.String('status'),
      render: statusCellRenderer
    },
    {
      title: t('GPIO.Tables.Channel'),
      key: 'channel',
      dataIndex: 'channel',
      fixed: 'left',
      width: 140,
      defaultSortColumn: true,
      defaultSortOrder: 'ascend',
      sorter: Comparators.String('channel')
    },
    {
      title: t('GPIO.Tables.Description'),
      key: 'description',
      dataIndex: 'description',
      width: 150,
      sorter: Comparators.String(getDescription),
      render: (value, rowData, rowIndex) => getDescription(rowData)
    },
    {
      title: (
        <InfoTooltipHeaderCell
          label={t('GPIO.Tables.NextStatusChange')}
          tooltip={t('GPIO.Tables.NextStatusChangeTooltip')}
        />
      ),
      key: 'nextStatus',
      dataIndex: 'nextStatus',
      width: 250,
      sorter: Comparators.String('nextStatus'),
      render: (value, rowData) => {
        return value ? (
          <>
            {value?.toLowerCase().startsWith('pending') && (
              <Spin style={{ marginRight: '10px', verticalAlign: 'top' }} />
            )}
            <span>{value}</span>
            {/* <span>{value.replace('at Ignition Off', '(at Ignition Off)')}</span> */}
          </>
        ) : (
          '-'
        );
      }
    },
    {
      title: (
        <InfoTooltipHeaderCell
          label={t('GPIO.Tables.LastStatusChangeRequest')}
          tooltip={t('GPIO.Tables.LastStatusChangeRequestTooltip')}
        />
      ),
      key: 'lastStatusChangeRequest',
      dataIndex: 'lastStatusChangeRequest',
      width: 300,
      sorter: Comparators.Date('lastStatusChangeRequest'),
      render: dateTimeCellRenderer
    },
    {
      title: (
        <InfoTooltipHeaderCell
          label={t('GPIO.Tables.LastDeviceResponse')}
          tooltip={t('GPIO.Tables.LastDeviceResponseTooltip')}
        />
      ),
      key: 'lastStatusChangeResponse',
      dataIndex: 'lastStatusChangeResponse',
      width: 250,
      sorter: Comparators.Date('lastStatusChangeResponse'),
      render: dateTimeCellRenderer
    },
    {
      title: (
        <InfoTooltipHeaderCell
          label={t('GPIO.Tables.LastStatusChange')}
          tooltip={t('GPIO.Tables.LastStatusChangeTooltip')}
        />
      ),
      key: 'lastStatusChange',
      dataIndex: 'lastStatusChange',
      width: 250,
      sorter: Comparators.Date('lastStatusChange'),
      render: dateTimeCellRenderer
    },
    {
      title: t('Common.TableColumns.Actions'),
      key: 'actions',
      fixed: 'right',
      align: 'center',
      width: 100,
      render: output => {
        // Check for cases to disable Update button with a message
        let disableUpdate = false;
        let disableUpdateMessage = null;
        if (disableImmoblise && output?.description === 'immobilise') {
          disableUpdate = true;
          disableUpdateMessage = t('GPIO.Tables.UpdateNotAuthorizedMessage');
        } else if (output?.description === 'siren') {
          disableUpdate = true;
          disableUpdateMessage = t('GPIO.Tables.UpdateSirenDisabledMessage');
        } else if (output?.description === 'led') {
          disableUpdate = true;
          disableUpdateMessage = t('GPIO.Tables.UpdateLedDisabledMessage');
        }

        return disableUpdate ? (
          <Tooltip
            overlayInnerStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }}
            title={disableUpdateMessage}
          >
            <Button type="link" id={BUTTON_IDS.gpioTablesUpdate} disabled>
              {t('GPIO.Tables.Update')}
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="link"
            id={BUTTON_IDS.gpioTablesUpdate}
            onClick={() => handleUpdateOutput(output)}
          >
            {t('GPIO.Tables.Update')}
          </Button>
        );
      }
    }
  ];

  return (
    <Table
      className={`${styles.grid} ${styles.gridShowScrollbars}`}
      dataSource={data}
      columns={columns}
      rowKey={'key'}
      loading={isLoading}
      showSorterTooltip={false}
      sortDirections={['ascend', 'descend', 'ascend']}
      pagination={false}
      sticky={true}
      scroll={{ x: 'max-content' }}
      {...props}
    />
  );
};
