import { useEffect } from 'react';
function on(eventType, listener) {
  document.addEventListener(eventType, listener);
}

function off(eventType, listener) {
  document.removeEventListener(eventType, listener);
}

function once(eventType, listener) {
  on(eventType, handleEventOnce);

  function handleEventOnce(event) {
    listener(event);
    off(eventType, handleEventOnce);
  }
}

function trigger(eventType, data) {
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
}

export { on, once, off, trigger };

export const useCustomEventListener = (eventType, lisenter) => {
  useEffect(() => {
    on(eventType, lisenter);
    return () => {
      off(eventType, lisenter);
    };
  }, [lisenter]);
};

export const CustomEvents = Object.freeze({
  autoCloseSidebar: 'autoCloseSidebar'
});
