import moment from 'moment';

export class UserAuthToken {
  static put(tokenInfo) {
    const authCookie = {
      _A_TN360_: window.btoa(encodeURIComponent(JSON.stringify(tokenInfo))),
      path: '/',
      secure: true,
      samesite: 'strict'
    };
    document.cookie = Object.keys(authCookie)
      .map(k => k + '=' + authCookie[k])
      .join(';');
    window.localStorage.setItem('tokenTime', moment().valueOf());
    window.localStorage.removeItem('logout');
  }

  static get() {
    const authCookie = document.cookie.split('; ').find(c => c.startsWith('_A_TN360_'));
    if (authCookie) {
      let authStr = authCookie.split('=')[1];
      try {
        authStr = decodeURIComponent(window.atob(authStr));
        const authObj = JSON.parse(authStr);
        return authObj;
      } catch (e) {
        //
      }
    }
    return null;
  }

  static clear() {
    const authCookie = {
      _A_TN360_: '',
      path: '/',
      secure: true,
      samesite: 'strict',
      expires: 0
    };
    document.cookie = Object.keys(authCookie)
      .map(k => k + '=' + authCookie[k])
      .join(';');
    window.localStorage.setItem('logout', moment().valueOf());
    window.localStorage.removeItem('tokenTime');
  }
}
