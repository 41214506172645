import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import P from 'prop-types';
//components
import { Input, Select, Button } from 'components/ant';
import { Form, Col, Row } from 'antd';
import SearchableListMultiSelect from 'components/form/searchable-list-multi-select/SearchableListMultiSelect';
import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
//slices
import {
  useCurrentCompany,
  useRedirectToMainFeaturePageOnCompanyChange,
  useIsCompanyKeyDifferent
} from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';
import { useIsFetching as isFleetsFetching } from 'features/fleets/fleetsSlice';
//constants
import {
  PATHS,
  DATA_TYPE_FOR_INITIAL_COMPANY,
  LABEL_ALIGN_FOR_INPUTS,
  INPUT_SIZE,
  EMPTY_ARRAY,
  FLEET_DIRECTION_GET_CALL
} from './utils/constants';
//helpers and methods
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { addWifi, updateWifi, useIsFetching, useIsUpdatingWifi } from 'features/wifi';
import { setFormFleets, setAssociations } from './utils/helpers';
import { arraysEqual } from 'utils/objects';
import { getFleetsByCompany } from 'containers/Administration/Fleets/APICalls';
// styles
import styles from './Wifi.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const WifiForm = ({ wifi, subCompanies, fleets, action }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const history = useHistory();
  const loading = useIsUpdatingWifi();
  const isFetchingFleets = isFleetsFetching();
  const isEdit = action === 'edit';
  const companiesForSelect = (subCompanies || []).map(company => ({
    id: company.id,
    label: company.name
  }));

  const [wifiForm] = Form.useForm();
  const { state } = useLocation();

  const currentCompany = useCurrentCompany();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(false);
  const [fleetsLoaded, setFleetsLoaded] = useState(true);
  const [formFleets, setFleets] = useState([]);
  const isFetchingWifi = useIsFetching();

  const hasCompanyChanged = useIsCompanyKeyDifferent('wifi');
  useRedirectToMainFeaturePageOnCompanyChange('/settings/wifi');

  const handleError = useCallback(
    err => {
      if (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err
          })
        );
      }
      if (history.location.pathname !== PATHS.WIFI_DEFAULT) {
        history.replace(PATHS.WIFI_DEFAULT);
      }
    },
    [history, dispatch]
  );

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  //useEffects
  useEffect(() => {
    const formFleets = setFormFleets({ fleets, subCompanies, currentCompany, state, t });
    setFleets(formFleets);

    if (state?.data?.company?.id) {
      updateAssociationsByCompanySelectorChange(state?.data?.company?.id);
    }
  }, [fleets, subCompanies, currentCompany, state, t]);

  useEffect(() => {
    wifiForm.setFieldsValue({
      companyId: currentCompany.id
    });
  }, [currentCompany]);

  useEffect(() => {
    const initialAssociations = setAssociations(formFleets);
    wifiForm.setFieldsValue({
      associations: initialAssociations
    });
  }, [formFleets, wifiForm]);

  useEffect(() => {
    !state?.data?.company?.id &&
      wifiForm.setFieldsValue({
        companyId: currentCompany?.name
      });
  }, []);

  useEffect(() => {
    if (isEdit) {
      const parsedId = parseInt(state?.data?.id);
      if (
        parsedId <= 0 ||
        isNaN(parsedId) ||
        (!isFetchingWifi && !wifi?.some(w => w.id === parsedId))
      ) {
        handleError(t('Common.Invalid Request ID'));
      }
    }
  }, [isEdit, state, handleError, t, isFetchingWifi, wifi]);

  //methods on load
  useEffect(() => {
    dispatch(
      setPageTitle(
        isEdit ? `${t('Common.Edit')} ${state?.data?.name}` : t('WiFi.GridPage.AddNewWiFi')
      )
    );
  }, [isEdit, state?.data?.name, dispatch]);

  //specific component methods
  const handleChangeAssociations = (newValues = []) => {
    const selectedValues = setAssociations(newValues);
    wifiForm.setFieldsValue({
      associations: selectedValues
    });

    const initialCompanyIds = (state?.data?.companyIds || []).map(ci => ({ id: ci }));
    const initialFleetIds = (state?.data?.fleetIds || []).map(fi => ({ id: fi }));

    const selectedCompanyIds = newValues.reduce((acc, curr) => {
      const isSelectedCompanyCondition =
        typeof curr?.value === DATA_TYPE_FOR_INITIAL_COMPANY && curr?.checked;
      if (isSelectedCompanyCondition) {
        acc.push({ id: parseInt(curr?.value.substring(2, (curr?.value || []).length)) });
      }
      return acc;
    }, []);

    const selectedFleetIds = newValues.reduce((acc, curr) => {
      const isSelectedFleedCondition =
        typeof curr?.value !== DATA_TYPE_FOR_INITIAL_COMPANY && curr?.checked;
      if (isSelectedFleedCondition) {
        acc.push({ id: curr?.value });
      }
      return acc;
    }, []);

    const companyScenarioCheck =
      !!selectedCompanyIds.length && !arraysEqual(initialCompanyIds, selectedCompanyIds);
    const fleetsScenarioCheck =
      !!selectedFleetIds.length && !arraysEqual(initialFleetIds, selectedFleetIds);
    if (isEdit && (companyScenarioCheck || fleetsScenarioCheck)) {
      setPromptModalWhenLeaving(true);
      validateForm();
    }
  };

  const onFormChanged = changedValues => {
    if (changedValues?.companyId) {
      updateAssociationsByCompanySelectorChange(changedValues?.companyId);
    }
    validateForm();
    setPromptModalWhenLeaving(true);
  };

  const validateForm = async () => {
    try {
      await wifiForm.validateFields();
      setIsSaveEnabled(true);
    } catch (errorInfo) {
      setIsSaveEnabled(errorInfo?.errorFields?.length === 0);
    }
  };

  const updateAssociationsByCompanySelectorChange = async companyId => {
    setFleetsLoaded(false);
    const selectedCompany = subCompanies.find(comp => comp.id === companyId);
    await getFleetsByCompany(companyId, userKey, FLEET_DIRECTION_GET_CALL).then(newFleets => {
      const formFleets = setFormFleets({
        fleets: newFleets,
        subCompanies,
        currentCompany: selectedCompany,
        state,
        t
      });
      setFleets(formFleets);
      setFleetsLoaded(true);
    });
    wifiForm.setFieldsValue({
      companyId: companyId
    });
  };

  const handleCancelPress = () => {
    history.goBack();
  };

  const handleSave = async () => {
    const valuesFromForm = wifiForm.getFieldsValue();
    const { associations, companyId, ...payload } = valuesFromForm;
    payload.company = {};

    //Checking for duplicate names
    const identicalWifiName = wifi.find(
      wifi => wifi.name === payload.name && state?.data?.id !== wifi.id
    );
    if (identicalWifiName) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(`WiFi.Form.WiFiIdenticalNameError`, {
            name: payload?.name
          })}`
        })
      );
      return;
    }
    //In the usecase the company selector receive the value from changing the company in the navbar,
    // we must convert the name into an id
    payload.company.id =
      typeof companyId !== DATA_TYPE_FOR_INITIAL_COMPANY
        ? companyId
        : companiesForSelect.find(comp => comp.label === companyId).id;
    const companyAssocation = associations
      ?.filter(assoc => typeof assoc === DATA_TYPE_FOR_INITIAL_COMPANY)
      ?.map(company => parseInt(company.substring(2, company.length)));
    const fleetAssociations = associations?.filter(
      assoc => typeof assoc !== DATA_TYPE_FOR_INITIAL_COMPANY
    );

    if (!!companyAssocation?.length) {
      payload.companyIds = [...companyAssocation];
    }

    if (!!fleetAssociations?.length) {
      payload.fleetIds = [...fleetAssociations];
    }
    try {
      const actionCreator = isEdit
        ? updateWifi({
            id: state?.data?.id,
            body: payload
          })
        : addWifi({ body: payload });
      const response = await dispatch(actionCreator);
      unwrapResult(response);

      dispatch(
        openToast({
          type: ToastType.Success,
          message: t(`WiFi.Form.WiFi${isEdit ? 'Edited' : 'Added'}Success`, {
            name: payload?.name
          })
        })
      );
      // Go back to the table page
      setPromptModalWhenLeaving(false);
      history.push(PATHS.WIFI_DEFAULT);
    } catch (err) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(`WiFi.Form.WiFiAddedError`, {
            name: payload?.name
          })} ${err.message}`
        })
      );
    }
  };

  return (
    <div className={styles.wifiFormContainer}>
      <EditRouteGuard when={promptModalWhenLeaving && !hasCompanyChanged} navigate={history.push} />
      <Form
        layout="vertical"
        initialValues={state?.data ? { ...state?.data, companyId: state?.data?.company?.id } : {}}
        form={wifiForm}
        name="addNewWiFi"
        onFinish={handleSave}
        onValuesChange={onFormChanged}
      >
        <div className={styles.formCard}>
          <Row>
            <Col span={12}>
              <Form.Item
                name="name"
                labelAlign={LABEL_ALIGN_FOR_INPUTS}
                label={t(`WiFi.Form.WiFiName`)}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t('WiFi.Form.NameRequired'),
                    whitespace: true
                  }
                ]}
              >
                <Input size={INPUT_SIZE} placeholder={t('WiFi.Form.TypeHere')} />
              </Form.Item>
              <Form.Item
                name="companyId"
                labelAlign={LABEL_ALIGN_FOR_INPUTS}
                required
                colon={false}
                label={t(`SmartJobsCodes.companyName`)}
              >
                <Select
                  size={INPUT_SIZE}
                  placeholder={t(`SmartJobsCodes.selectOption`)}
                  data={companiesForSelect}
                  disabled={isFetchingFleets}
                />
              </Form.Item>
              <div className={styles.infoContainer}>{t(`WiFi.Form.InfoNote`)}</div>
            </Col>
            <Col span={12}>
              <Form.Item name="associations" colon={false}>
                <SearchableListMultiSelect
                  name="fleets"
                  label={t('WiFi.Form.AssignWiFiFleet')}
                  placeholder={t('GeofencesFeature.Form.SearchForFleet')}
                  allLabel={t('Common.AllFleets')}
                  initialValues={EMPTY_ARRAY}
                  values={formFleets}
                  setFieldValue={handleChangeAssociations}
                  hideSelectAll
                  height={275}
                  isLoading={!fleetsLoaded}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.formCard}>
          <h4 className={styles.formCardTitle}>{t(`Users.Form.Security`)}</h4>
          <div className={styles.divider} />
          <Row>
            <Col span={12}>
              <Form.Item
                name="ssid"
                labelAlign={LABEL_ALIGN_FOR_INPUTS}
                label={t(`WiFi.ViewPage.SSID`)}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t('WiFi.Form.SSIDRequired'),
                    whitespace: true
                  }
                ]}
              >
                <Input size={INPUT_SIZE} placeholder={t('WiFi.Form.TypeHere')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password"
                labelAlign={LABEL_ALIGN_FOR_INPUTS}
                rules={[
                  {
                    required: true,
                    min: 8,
                    message: t('WiFi.Form.PasswordRequired'),
                    whitespace: true
                  }
                ]}
                colon={false}
                label={t(`WiFi.ViewPage.Password`)}
              >
                <Input
                  size={INPUT_SIZE}
                  placeholder={t('WiFi.Form.TypeHere')}
                  autoComplete="new-password"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.formFooter}>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={!isSaveEnabled}
            id={BUTTON_IDS.wifiFormSave}
          >
            {t(`Common.${loading ? 'Saving' : 'Save'}`)}
          </Button>

          <Button id={BUTTON_IDS.wifiFormCancel} size="large" onClick={handleCancelPress}>
            {t('Common.Cancel')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

WifiForm.propTypes = {
  wifi: P.array,
  subCompanies: P.array,
  fleets: P.array,
  action: P.string
};
