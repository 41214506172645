import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import { Button, Tooltip } from 'antd';

import { CloseCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';

import { sortStrings } from 'utils/strings';

import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import styles from './ViewsConfigModal.module.scss';

export const getDefaultSelectedColumns = (allColumns, t) => {
  const defaultSelectedColumns = allColumns.filter(c => c.isConfigurable && c.isSelectedByDefault);
  return defaultSelectedColumns;
};

export const getIncludedAndAvailableColumns = (userGridSettings, allColumns, t) => {
  let includedColumns = getDefaultSelectedColumns(allColumns, t);

  // Check if config has any data yet
  if (userGridSettings?.config) {
    let newColumnsSchema = true;
    let columnsFromConfig = null;
    const viewConfig = userGridSettings.config.views[userGridSettings.config.selectedView];

    // New schema that has column keys and widths
    if (viewConfig.hasOwnProperty('includedColumns')) {
      columnsFromConfig = viewConfig.includedColumns;
    } else {
      newColumnsSchema = false;
      columnsFromConfig = viewConfig.columns;
    }

    if (columnsFromConfig) {
      // If has data then add columns from previously saved config/view
      includedColumns = [];
      columnsFromConfig.forEach(columnFromConfig => {
        let key = newColumnsSchema ? columnFromConfig.key : columnFromConfig;

        const foundColumn = allColumns.find(x => x.isConfigurable && x.key === key);
        if (foundColumn) {
          includedColumns.push(foundColumn);
        }
      });
    }
  }

  // Add rest of columns to available columns list
  let availableColumns = [];
  allColumns.forEach(availableColumn => {
    if (availableColumn.isConfigurable) {
      const foundColumn = includedColumns.find(x => x.key === availableColumn.key);
      if (!foundColumn) {
        availableColumns.push({
          value: availableColumn.key,
          label: availableColumn.label
        });
      }
    }
  });

  return {
    availableColumns: availableColumns,
    includedColumns: includedColumns
  };
};

const DragHandle = SortableHandle(() => (
  <FontAwesomeIcon className={styles.gripperIcon} icon={faGripVertical} />
));

export const getColumnsForTable = (onRemove, t) => {
  const columns = [
    {
      title: (
        <div style={{ whiteSpace: 'nowrap' }}>
          <span>{t('Tracking.ViewsConfig.Reorder')}</span>
          <Tooltip title={t('Tracking.ViewsConfig.ReorderTooltip')}>
            <InfoCircleOutlined className={styles.reorderInfoIcon} />
          </Tooltip>
        </div>
      ),
      key: 'Reorder',
      width: '26%',
      render: (text, record) => <>{!record.fixed && <DragHandle />}</>
    },
    {
      title: t('Tracking.ViewsConfig.ColumnName'),
      dataIndex: 'title',
      key: 'Column Name',
      width: '52%'
    },
    {
      title: t('Tracking.ViewsConfig.Remove'),
      key: 'Remove',
      width: '22%',
      render: (text, record) => (
        <>
          {!record.fixed ? (
            <div style={{ textAlign: 'center' }}>
              <Button
                size="middle"
                type="text"
                icon={<CloseCircleFilled className={styles.removeIcon} />}
                onClick={() => {
                  onRemove(record);
                }}
              ></Button>
            </div>
          ) : (
            <Tooltip title={t('Tracking.ViewsConfig.FixedColumnTooltip')}>
              <InfoCircleOutlined className={styles.fixedInfoIcon} />
            </Tooltip>
          )}
        </>
      )
    }
  ];

  return columns;
};

export const sortColumns = availableColumns => {
  const sortedAvailableColumns = availableColumns.sort((a, b) => sortStrings(a?.label, b?.label));
  return sortedAvailableColumns;
};

export const validateViewName = (newViewName, userGridSettings, oldViewName, dispatch, t) => {
  let errorMessage = null;

  // Check length is too short
  if (!newViewName || newViewName.length < 1) {
    errorMessage = t('Tracking.ViewsConfig.ViewNameEmpty');
  }

  // Check length is greater than 50
  if (newViewName && newViewName.length > 50) {
    errorMessage = t('Tracking.ViewsConfig.ViewNameTooLong', { maxLength: 50 });
  }

  if (newViewName) {
    // Check duplicate names
    const existingNames = userGridSettings ? Object.keys(userGridSettings.views) : [];
    const nameMatch = existingNames.find(
      existingName =>
        existingName.toUpperCase() === newViewName.toUpperCase() && existingName !== oldViewName
    );
    if (nameMatch) {
      errorMessage = t('Tracking.ViewsConfig.ViewNameDuplicate');
    }
  }

  if (errorMessage) {
    // Todo: Figure out how to show error inline isntead of toast message
    dispatch(
      openToast({
        type: ToastType.Error,
        message: errorMessage
      })
    );

    return false;
  }

  return true;
};
