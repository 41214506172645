import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InfoWindow } from 'react-google-maps';

import { Button } from 'components/ant';
import { useLocalization } from 'features/localization/localizationSlice';
import { useCan } from 'features/permissions/canHooks';
import { services, entities } from 'features/permissions';
import { getEventTypeDisplayName } from 'containers/Scorecard/ScorecardEventsHelpers';
import { ScorecardEventType, ScorecardUnitLabels } from 'containers/Scorecard/constants';

import styles from './InfoWindows.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const ScorecardInfoWindow = ({ event, onIncludeExcludeEventClick, eventAttributes }) => {
  const { t } = useTranslation();
  const localization = useLocalization(true);
  const can = useCan();

  const value = Math.round(event.value * 100) / 100;

  const getValueLabel = event => {
    switch (event.type) {
      case ScorecardEventType.HARSH_ACCELERATION:
      case ScorecardEventType.HARSH_CORNERING:
        return t('Tracking.Events.Acceleration');
      case ScorecardEventType.HARSH_BRAKING:
        return t('Tracking.Events.Deceleration');
      case ScorecardEventType.EXCESSIVE_IDLE:
        return t('Scorecard.Time');
      case ScorecardEventType.OVERREV:
        return t('Scorecard.RPMvariance');
      case ScorecardEventType.SPEED:
        return t('Scorecard.SpeedVariance');
      case ScorecardEventType.SEAT_BELT_NON_USAGE:
        return t('Common.Count');
      default:
        return t('Devices.Meters.Value');
    }
  };

  const getValueUnits = event => {
    switch (event.type) {
      case ScorecardEventType.HARSH_ACCELERATION:
      case ScorecardEventType.HARSH_CORNERING:
      case ScorecardEventType.HARSH_BRAKING:
        return ScorecardUnitLabels.ACCELERATION;
      case ScorecardEventType.OVERREV:
        return ScorecardUnitLabels.RPM;
      case ScorecardEventType.SPEED:
        return localization.formats.speed.unit_per_hour;
      case ScorecardEventType.SEAT_BELT_NON_USAGE:
      default:
        return '';
    }
  };

  // To Include/Exclude events, Company needs Scorecard Advanced subscription pack and user needs
  // Scorecard Advanced service permission and Scorecard Event -> Update entity permission
  const canIncludeExcludeEvents = can({
    everyService: [services.SCORECARDADV],
    everyCompanyService: [services.SCORECARDADV],
    oneOfEntities: [entities.SCORECARDEVENT_UPDATE]
  });

  return (
    <InfoWindow>
      <div className={styles.scorecardInfoWindow}>
        <div className={styles.infoWindowTitle}>{t('Tracking.QuickView')}</div>
        <div>
          <label className={styles.firstColumn}> {t('Tracking.EventType')} </label>
          <span className={styles.secondColumn}>
            {' '}
            {t(getEventTypeDisplayName(event, localization))}{' '}
          </span>
        </div>
        <div>
          <label className={styles.firstColumn}> {t('Common.Driver')} </label>
          <span className={styles.secondColumn}>
            {' '}
            {event.user?.fullName || 'No Driver Logged In'}{' '}
          </span>
        </div>
        <div>
          <label className={styles.firstColumn}> {t('Tracking.Device')} </label>
          <span className={styles.secondColumn}> {event.device?.name} </span>
        </div>
        <div>
          <label className={styles.firstColumn}> {t('Common.Location')} </label>
          <span className={styles.secondColumn}> {event.location} </span>
        </div>

        {event.type !== ScorecardEventType.EXCESSIVE_IDLE &&
          event.type !== ScorecardEventType.SEAT_BELT_NON_USAGE && (
            <div>
              <label className={styles.firstColumn}> {getValueLabel(event)} </label>
              <span className={styles.secondColumn}>
                {event.type !== ScorecardEventType.SPEED ? value : localization.convertSpeed(value)}{' '}
                {getValueUnits(event)}
              </span>
            </div>
          )}

        {event.type !== ScorecardEventType.SEAT_BELT_NON_USAGE && (
          <div>
            <label className={styles.firstColumn}> {t('Tracking.Events.Duration')} </label>
            <span className={styles.secondColumn}>
              {' '}
              {event.duration} {t('Common.seconds')}
            </span>
          </div>
        )}
        <div className={styles.footer}>
          <Button
            className={styles.viewDetailsBtn}
            id={BUTTON_IDS.scorecardViewDetails}
            onClick={() =>
              window.open(
                eventAttributes.url
                  ?.replace(':id:', event.eventId)
                  ?.replace(':timeAt:', moment(event.timeAt).unix()),
                '_blank'
              )
            }
          >
            {t('Tracking.ViewDetails')}
          </Button>
          {canIncludeExcludeEvents && (
            <Button
              className={
                event?.geofenceExcluded ? styles.excludeEventBtnDisabled : styles.excludeEventBtn
              }
              id={BUTTON_IDS.scorecardIncExcludeEvent}
              onClick={() => onIncludeExcludeEventClick({ useCheckedEvents: false })}
            >
              {event && (event.excluded || event?.geofenceExcluded)
                ? t('Scorecard.IncludeEvent')
                : t('Scorecard.ExcludeEvent')}
            </Button>
          )}
        </div>
      </div>
    </InfoWindow>
  );
};

export default ScorecardInfoWindow;
