import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Spin, Space, Typography } from 'antd';
import { Cascader } from 'antd';
import { useDeviceTypes } from 'features/device_types/deviceTypesSlice';
import { useDeviceModels } from 'features/device_models/deviceModelsSlice';
import styles from '../DevicesBulkEdit.module.scss';
import { useCan } from 'features/permissions';
import { IQCameraMetaSelect, getIQCameraMetaConfig } from './IQCameraMetaSelect';
import { isIQCamModelId } from 'features/camera/CameraModelConfig';
import { useDispatch } from 'react-redux';
import { useCurrentCompanyId } from 'features/company/companySlice';
import { fetchIQCamerDevicesConfig } from 'features/company_config';

export const TypeSelectModal = ({ show, onOk, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useCurrentCompanyId();
  const can = useCan();
  const { isValidIQCameraMeta } = getIQCameraMetaConfig(can);
  const [value, setValue] = useState({ deviceTypeModel: null, meta: {} });

  const deviceTypes = useDeviceTypes();
  const deviceModels = useDeviceModels();

  const { isLoading, options } = useMemo(() => {
    const isFetchingTypes = !deviceTypes?.status?.fetching || deviceTypes?.status?.fetching === 1;
    const isFetchingModels =
      !deviceModels?.status?.fetching || deviceModels?.status?.fetching === 1;
    return {
      isLoading: isFetchingTypes || isFetchingModels,
      options: deviceTypes?.list?.map(deviceType => {
        return {
          value: deviceType.id,
          label: deviceType.name,
          children: deviceModels?.list
            ?.filter(model => String(model.deviceType.id) === String(deviceType.id))
            ?.map(model => ({
              label: model.name.toLowerCase() === 'iqcamera' ? 'IQ Camera' : model.name,
              value: model.id
            }))
        };
      })
    };
  }, [deviceTypes, deviceModels]);

  const isIqCamera = useCallback(modelId => isIQCamModelId(modelId, deviceModels?.list), [
    deviceModels
  ]);

  return (
    <Modal
      bodyStyle={{
        height: isIqCamera(value?.deviceTypeModel?.[1]) ? 360 : 200,
        display: 'block'
      }}
      okButtonProps={{
        disabled:
          !value?.deviceTypeModel ||
          (isIqCamera(value?.deviceTypeModel?.[1]) && !isValidIQCameraMeta(value.meta))
      }}
      okText={t('Common.Save')}
      cancelText={t('Common.Modal.Cancel')}
      title={t('Devices.BulkEdit.EditColumnTitle')}
      centered
      open={show}
      closable={false}
      maskClosable={false}
      onOk={() => {
        onOk(value, ({ allFailed, devices }) => {
          if (!allFailed && isIqCamera(value?.deviceTypeModel?.[1])) {
            dispatch(
              fetchIQCamerDevicesConfig({
                companyId,
                deviceIds: devices.map(d => d.id),
                forceFetch: true
              })
            );
          }
        });
      }}
      onCancel={() => {
        onCancel();
      }}
    >
      <Space direction="vertical" size={0} className={styles.entitySelect}>
        <Typography.Title level={5}>{`${t('Vehicles.Form.Type')} / ${t(
          'Vehicles.Form.Model'
        )}`}</Typography.Title>
        <Cascader
          allowClear
          showSearch
          value={value?.deviceTypeModel}
          options={options}
          onChange={deviceTypeModel => {
            setValue(value => ({
              ...value,
              deviceTypeModel,
              meta: {}
            }));
          }}
          placeholder={t('Vehicles.Form.TypePlaceholder')}
          notFoundContent={isLoading ? <Spin size="small" /> : null}
        />
      </Space>
      {isIqCamera(value?.deviceTypeModel?.[1]) && (
        <IQCameraMetaSelect
          t={t}
          meta={value.meta}
          onMetaChange={meta => setValue(value => ({ ...value, meta }))}
        />
      )}
    </Modal>
  );
};
