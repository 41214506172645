import { ALERT_TYPES } from 'containers/Configuration/CompanyAlerts/constants';
import { useCurrentCompany } from 'features/company/companySlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAlerts, fetchGpioTypes } from './thunks';

export const useCompanyAlerts = () => {
  const dispatch = useDispatch();
  const companyAlerts = useSelector(state => state.companyAlerts.list);
  const currentCompany = useCurrentCompany();
  const isCompanyChanged = useIsCompanyChanged();

  const isFetching = useSelector(state => state.companyAlerts.isFetching);
  const isSuccess = useSelector(state => state.companyAlerts.success);
  const isError = useSelector(state => state.companyAlerts.error);

  const isFetchComplete = isSuccess || isError;

  if (!isFetching && (!isFetchComplete || isCompanyChanged)) {
    dispatch(
      fetchAlerts({
        query: {
          entity_type: 'Ng::Company',
          entity_id: currentCompany.id
        }
      })
    );
  }

  return companyAlerts;
};

export const useAlerts = type => {
  const alerts = useCompanyAlerts();

  switch (type) {
    case ALERT_TYPES.CAMERA:
      return alerts.filter(alert => alert.type === ALERT_TYPES.CAMERA);
    case ALERT_TYPES.ENGINE:
      return alerts.filter(alert => alert.type === ALERT_TYPES.ENGINE);
    case ALERT_TYPES.FORM:
      return alerts.filter(alert => alert.type === ALERT_TYPES.FORM);
    case ALERT_TYPES.GEOFENCE:
      return alerts.filter(alert => alert.type === ALERT_TYPES.GEOFENCE);
    case ALERT_TYPES.PRETRIP:
      return alerts.filter(alert => alert.type === ALERT_TYPES.PRETRIP);
    case ALERT_TYPES.ROUTE:
      return alerts.filter(alert => alert.type === ALERT_TYPES.ROUTE);
    case ALERT_TYPES.SENTINEL:
      return alerts.filter(alert => alert.type === ALERT_TYPES.SENTINEL);
    case ALERT_TYPES.SPEED:
      return alerts.filter(alert => alert.type === ALERT_TYPES.SPEED);
    case ALERT_TYPES.VEHICLE_MAINTENANCE:
      return alerts.filter(alert => alert.type === ALERT_TYPES.VEHICLE_MAINTENANCE);
    case ALERT_TYPES.DRIVER_MANAGEMENT:
      return alerts.filter(alert => alert.type === ALERT_TYPES.DRIVER_MANAGEMENT);
    case ALERT_TYPES.DRIVER_AND_VEHICLE:
      return alerts.filter(alert => alert.type === ALERT_TYPES.DRIVER_AND_VEHICLE);
    case ALERT_TYPES.SMARTJOBS:
      return alerts.filter(alert => alert.type === ALERT_TYPES.SMARTJOBS);
    case ALERT_TYPES.ELD:
      return alerts.filter(alert => alert.type === ALERT_TYPES.ELD);
    case ALERT_TYPES.DOCUMENTEXPIRATION:
      return alerts.filter(alert => alert.type === ALERT_TYPES.DOCUMENTEXPIRATION);
    case ALERT_TYPES.MASS_MANAGEMENT:
      return alerts.filter(alert => alert.type === ALERT_TYPES.MASS_MANAGEMENT);
    case ALERT_TYPES.METER:
      return alerts.filter(alert => alert.type === ALERT_TYPES.METER);
    case ALERT_TYPES.OOH:
      return alerts.filter(alert => alert.type === ALERT_TYPES.OOH);
    case ALERT_TYPES.GPIO:
      return alerts.filter(alert => alert.type === ALERT_TYPES.GPIO);
    case ALERT_TYPES.DURESS:
      return alerts.filter(alert => alert.type === ALERT_TYPES.DURESS);
    case ALERT_TYPES.TACHO:
      return alerts.filter(alert => alert.type === ALERT_TYPES.TACHO);
    default:
      return [];
  }
};

export const useIsAlertFetching = () => {
  const isAlertFetching = useSelector(state => state.companyAlerts.isFetching);
  return isAlertFetching;
};

export const useIsAddAlertSaving = () => {
  const isAddAlertSaving = useSelector(state => state.companyAlerts.meta.addAlert.isSaving);
  return isAddAlertSaving;
};

export const useIsEditAlertSaving = () => {
  const isEditAlertSaving = useSelector(state => state.companyAlerts.meta.editAlert.isSaving);
  return isEditAlertSaving;
};

const useIsCompanyChanged = () =>
  useSelector(state => state.companies.current.id !== state.companyAlerts.meta.companyId);

export const useGpioTypes = () => {
  const dispatch = useDispatch();

  const gpioTypes = useSelector(state => state.companyAlerts.gpioTypes);
  const isFetching = useSelector(state => state.companyAlerts.meta.gpioTypes.isFetching);
  const isSuccess = useSelector(state => state.companyAlerts.meta.gpioTypes.success);
  const isError = useSelector(state => state.companyAlerts.meta.gpioTypes.error);

  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete) {
    dispatch(fetchGpioTypes());
  }

  return gpioTypes;
};
