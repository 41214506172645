import React, { useMemo, useState, useEffect } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { getCountryRegions } from 'utils/countryState';
import { useAllRegions } from 'features/regions/regionsSlice';

export function StateSelect({ country, value, addCountryAsRegion = false, ...props }) {
  const { t } = useTranslation();
  const regions = useAllRegions();

  const stateObject = useMemo(() => {
    if (!country) {
      return null;
    }

    let defaultValue;

    if (!!value) {
      const region = regions.find(r => r.name === country);

      if (region) {
        const stateJsonObj = JSON.parse(region.states);
        const reversedObject = Object.fromEntries(
          Object.entries(stateJsonObj).map(([key, value]) => [value, key])
        );

        //if library doesn't have what we need, we will add it to option
        if (reversedObject[value] !== undefined) {
          defaultValue = { value: value, label: reversedObject[value] };
        }
      }
    }

    const states = getCountryRegions({
      country,
      addCountryAsRegion,
      checkLabel: true,
      defaultValue
    });

    return states;
  }, [country, regions, value]);

  return (
    <Select
      {...props}
      value={value}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      placeholder={t('Common.Select a state')}
    >
      {!country && (
        <Select.Option key="0" disabled={true}>
          Please select a country first.
        </Select.Option>
      )}
      {(stateObject || []).map(state => (
        <Select.Option key={state.label} value={state.value}>
          {state.label}
        </Select.Option>
      ))}
    </Select>
  );
}
