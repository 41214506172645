export const CODE_PREFIX = 'SMARTJOBS/CODE/';

export const Paths = {
  CODES_TABLE: '/settings/smartJobs/codes',
  ADD_NEW_CODE: '/settings/smartJobs/codes/new',
  VIEW_CODE: '/settings/smartJobs/codes/id/',
  EDIT_CODE: '/settings/smartJobs/codes/edit'
};

export const ColumnKeys = {
  NAME: 'name',
  COMPANY_ID: 'companyId',
  COMPANY_NAME: 'companyName',
  KEY: 'codeType',
  VALUE_COUNT: 'codes',
  ACTIONS: 'actions'
};

export const CODES_TABLE_COLUMNS = [
  {
    title: 'NAME',
    label: 'Name',
    dataIndex: ColumnKeys.NAME,
    key: ColumnKeys.NAME
  },
  {
    title: 'COMPANY',
    label: 'Company',
    dataIndex: ColumnKeys.COMPANY_NAME,
    key: ColumnKeys.COMPANY_NAME
  },
  {
    title: 'KEY',
    label: 'Key',
    dataIndex: ColumnKeys.KEY,
    key: ColumnKeys.KEY
  },
  {
    title: 'VALUE COUNT',
    label: 'Value Count',
    dataIndex: ColumnKeys.VALUE_COUNT,
    key: ColumnKeys.VALUE_COUNT
  },
  {
    title: 'ACTIONS',
    label: 'Actions',
    dataIndex: ColumnKeys.ACTIONS,
    key: ColumnKeys.ACTIONS,
    width: 100
  }
];

export const KEY_MAPPINGS = {
  'variance.adhoc': 'Ad Hoc Variance',
  'variance.delivery': 'Delivery Variance',
  'variance.delivery.cancellation': 'Delivery Cancellation Variance',
  'variance.delivery.delay': 'Delivery Delay Variance',
  'variance.load': 'Load Variance',
  'variance.load.cancellation': 'Load Cancellation Variance',
  'variance.pickup': 'Pickup Variance',
  'variance.pickup.cancellation': 'Pickup Cancellation Variance',
  'variance.pickup.delay': 'Pickup Delay Variance',
  'variance.runsheet.cancellation': 'Runsheet Cancellation Variance',
  'variance.runsheet.reject': 'Runsheet Reject Variance',
  'item.type': 'Item Type'
};

export const CodeKeys = {
  codeType: 'codeType',
  companyName: 'companyName',
  name: 'name',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  codes: 'codes'
};

export const CodeLabels = [
  { key: CodeKeys.name, label: 'Name' },
  { key: CodeKeys.companyName, label: 'Company' },
  {
    key: CodeKeys.codeType,
    label: 'Key'
  },
  { key: CodeKeys.codes, label: 'Codes' },
  { key: CodeKeys.createdAt, label: 'Created At' },
  { key: CodeKeys.updatedAt, label: 'Updated At' }
];
