import {
  FRENCH_LOCALES,
  LANGUAGES,
  LANGUAGE_LOCALES,
  SPANISH_LOCALES
} from 'features/localization/languages';

export const config = {
  fallbackLng: {
    es: Object.values(SPANISH_LOCALES),
    fr: Object.values(FRENCH_LOCALES),
    default: [LANGUAGES.EN]
  },
  lng: LANGUAGE_LOCALES.EN_AU,
  debug: false,
  supportedLngs: ['test', ...Object.values(LANGUAGE_LOCALES), ...Object.values(LANGUAGES)],
  lowerCaseLng: false,
  cleanCode: true,
  interpolation: {
    escapeValue: false // not needed for react as it escapes by default
  }
};
