/* global google */
import React, { useCallback, useEffect, useRef, useState, useLayoutEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import Map, { MapMode } from 'components/map/Map';
import { useMsgClient } from 'utils/hooks/msgChannel';
import { MsgType, DefaultRadius, EarthRadius } from './ControlPanel/constants';
import { getBoundsZoomLevel } from 'utils/geo';
import { Button } from 'antd';
import { faHandPaper, faRedo, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Proximity.module.scss';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
import { LocalizationUtil, UNITS } from 'features/localization/localization';
import { BUTTON_IDS } from 'utils/globalConstants';

function generateDrawingOptions(hasLocation) {
  return {
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: [!hasLocation && google.maps.drawing.OverlayType.MARKER],
      markerOptions: {
        draggable: true
      }
    },
    drawingMode: null,
    drawingControl: false
  };
}

function generateCircleSelectionConfig({ center, radius, onRadiusChanged, ref }) {
  return {
    visible: true,
    center: center,
    radius: radius,
    editable: true,
    draggable: false,
    onRadiusChanged: onRadiusChanged,
    options: {
      clickable: false,
      zIndex: 112233
    },
    ref: ref
  };
}

function NearestMapMenu({ hasLocation, onPaneClicked, onResetClicked }) {
  const { t } = useTranslation();
  return (
    <div className={styles.nearestMapMenu}>
      <div>
        <Button
          type="text"
          title={t('Map.Move Map')}
          size="32px"
          icon={<FontAwesomeIcon icon={faHandPaper} />}
          onClick={onPaneClicked}
          id={BUTTON_IDS.showNearestMove}
        />
        {hasLocation && (
          <Button
            type="text"
            title={t('Common.Reset')}
            size="32px"
            icon={<FontAwesomeIcon icon={faRedo} />}
            onClick={onResetClicked}
            id={BUTTON_IDS.showNearestReset}
          />
        )}
      </div>
    </div>
  );
}

function ContextMenu({ menuPos, onLocationSelected, onContextMenuBlur }) {
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const btnRef = useRef(null);

  const handleBtnBlur = useCallback(() => {
    if (containerRef.current != null) {
      containerRef.current.style.display = 'none';
    }
    if (onContextMenuBlur) {
      onContextMenuBlur();
    }
  }, []);

  useEffect(() => {
    if (containerRef.current == null) {
      const container = document.createElement('div');
      container.id = 'map_contextmenu_' + Math.random().toFixed(10);
      container.className = styles.mapContextMenu;
      container.style.display = 'none';
      containerRef.current = container;
      document.body.appendChild(container);
    }

    return () => {
      if (containerRef.current != null) {
        document.body.removeChild(containerRef.current);
      }
    };
  }, []);

  useLayoutEffect(() => {
    if (containerRef.current != null) {
      if (menuPos == null) {
        containerRef.current.style.display = 'none';
      } else {
        containerRef.current.style.display = 'inherit';
        containerRef.current.style.left = menuPos.left;
        containerRef.current.style.top = menuPos.top;
        btnRef.current.focus();
      }
    }
  }, [menuPos]);

  if (containerRef.current == null) {
    return <></>;
  }
  return ReactDOM.createPortal(
    <Button
      ref={btnRef}
      onBlur={handleBtnBlur}
      className={styles.selectLocationBtn}
      onClick={onLocationSelected}
      icon={<FontAwesomeIcon size="lg" icon={faMapPin} />}
      id={BUTTON_IDS.showNearestSelect}
    >
      {t('Map.Select Location')}
    </Button>,
    containerRef.current
  );
}

const addressLevel = {
  street_address: 0,
  premise: 1,
  locality: 2,
  plus_code: 3
};

function selectAddress(results) {
  let selectedAddress = null;
  let level = 99;

  for (let a of results) {
    if (a.types.includes('premise')) {
      selectedAddress = a;
      level = addressLevel.premise;
    } else if (a.types.includes('street_address')) {
      selectedAddress = a;
      break;
    } else if (a.types.includes('locality') && level >= addressLevel.locality) {
      selectedAddress = a;
      level = addressLevel.locality;
    } else if (a.types.includes('plus_code') && level >= addressLevel.plus_code) {
      selectedAddress = a;
      level = addressLevel.plus_code;
    }
  }
  return selectedAddress;
}

export function ShowNearestVehicleMap({ regionGeoDetails, msgChannel }) {
  const mapRef = useRef();
  const circleSelectionRef = useRef(null);
  const locale = useLocalization();
  const [searchedDevices, setSearchedDevices] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [isLocationSelected, setIsLocationSelected] = useState(false);
  const [drawingOptions] = useState(generateDrawingOptions());
  const [mapOptions, setMapOptions] = useState(
    regionGeoDetails?.lat &&
      regionGeoDetails.lng && {
        zoom: regionGeoDetails.zoom && Number(regionGeoDetails.zoom),
        center: regionGeoDetails.lat && {
          lat: Number(regionGeoDetails.lat),
          lng: Number(regionGeoDetails.lng)
        }
      }
  );
  const [focusedDevice, setFocusedDevice] = useState(null);
  const containerElement = <div style={{ height: `100%`, width: `100%` }} />;
  const mapElement = <div style={{ height: `100%`, width: `100%` }} />;
  const msgClient = useMsgClient(msgChannel);
  const [contextMenuPos, setContextMenuPos] = useState(null);
  const [lastLocation, setLastLocation] = useState(null);
  const geocoder = new google.maps.Geocoder();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [circleSelectionConfig, setCircleSelectionConfig] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const reset = useCallback(() => {
    setIsLocationSelected(false);
    setContextMenuPos(null);
    setLastLocation(null);
    setSelectedLocation(null);
    setCircleSelectionConfig(null);
    setSearchedDevices(null);
    setSelectedDevice(null);
  }, []);

  const convertDistanceToMeter = useCallback(
    distance => {
      if (locale.formats.speed?.unit === UNITS.MILE) {
        return LocalizationUtil.miletokm(Number(distance)) * 1000;
      } else {
        return distance * 1000;
      }
    },
    [locale]
  );

  const calculateCircleSelectionZoomLevel = useCallback(
    latLng => {
      const mapDiv = mapRef.current?.state.map?.getDiv();
      const radiusMeters = convertDistanceToMeter(DefaultRadius);
      const degreeDiff = radiusMeters / EarthRadius / Math.PI;
      const bounds = new google.maps.LatLngBounds(
        {
          lat: latLng.lat - degreeDiff * 90,
          lng: latLng.lng - degreeDiff * 180
        },
        {
          lat: latLng.lat + degreeDiff * 90,
          lng: latLng.lng + degreeDiff * 180
        }
      );

      const zoom = getBoundsZoomLevel(bounds, {
        height: mapDiv?.clientHeight || 256,
        width: mapDiv?.clientWidth || 256
      });

      return zoom;
    },
    [convertDistanceToMeter, mapRef]
  );

  const handleCircleSelectionRadiusChanged = useCallback(() => {
    if (circleSelectionRef.current && msgClient) {
      msgClient.sendMsg({
        type: MsgType.RadiusUpdated,
        value: circleSelectionRef.current.getRadius()
      });
    }
  }, [msgClient]);

  const handleMessaging = useCallback(
    msg => {
      if (msg.type === MsgType.AddressUpdated) {
        const latLng = msg.value;
        let zoom = null;

        if (!isLocationSelected) {
          zoom = calculateCircleSelectionZoomLevel(latLng);
        }

        setMapOptions(prev => {
          return {
            ...prev,
            center: latLng,
            zoom: zoom || prev.zoom
          };
        });

        setSelectedLocation(latLng);
        setContextMenuPos(null);
        setLastLocation(null);
        setCircleSelectionConfig(prev => {
          if (!prev || !prev.center) {
            return generateCircleSelectionConfig({
              center: latLng,
              radius: convertDistanceToMeter(DefaultRadius),
              ref: circleSelectionRef,
              onRadiusChanged: handleCircleSelectionRadiusChanged
            });
          } else {
            return {
              ...prev,
              center: latLng
            };
          }
        });
        setIsLocationSelected(true);
      } else if (msg.type === MsgType.Reset) {
        reset();
      } else if (msg.type === MsgType.RadiusUpdated) {
        setCircleSelectionConfig(prev => {
          return {
            ...prev,
            radius: convertDistanceToMeter(msg.value)
          };
        });
      } else if (msg.type === MsgType.Searching) {
        setIsSearching(msg.value);
      } else if (msg.type === MsgType.SearchResult) {
        const devices = msg.value || [];
        setSearchedDevices(devices);
        setMenu(null);
      } else if (msg.type === MsgType.DeviceSelected) {
        setSelectedDevice(prev => {
          if (prev === msg.value) {
            return null;
          } else {
            return msg.value;
          }
        });
      } else if (msg.type === MsgType.DeviceFocused) {
        setFocusedDevice(msg.value);
      } else if (msg.type === MsgType.FilteredResult) {
        setSearchedDevices(msg.value || []);
        setSelectedDevice(prev => {
          if (msg.value?.find(d => d.id === prev)) {
            return prev;
          } else {
            return null;
          }
        });
      }
    },
    [
      reset,
      handleCircleSelectionRadiusChanged,
      convertDistanceToMeter,
      isLocationSelected,
      calculateCircleSelectionZoomLevel
    ]
  );
  const extraMapConfig = useMemo(() => {
    const origin = circleSelectionConfig?.center;
    const _selectedDevice = selectedDevice
      ? searchedDevices?.find(d => Number(d.id) === Number(selectedDevice))
      : null;
    const destination =
      _selectedDevice?.deviceStats?.gps &&
      _selectedDevice.deviceStats.gps.Lat &&
      _selectedDevice.deviceStats.gps.Lng
        ? {
            lat: _selectedDevice.deviceStats.gps.Lat,
            lng: _selectedDevice.deviceStats.gps.Lng
          }
        : null;
    return {
      circleSelectionConfig: searchedDevices ? null : circleSelectionConfig,
      draggableMarker: !isSearching && !searchedDevices,
      selectedTripSegment: origin && destination ? { origin, destination } : null
    };
  }, [selectedDevice, searchedDevices, circleSelectionConfig, isSearching]);

  const handleDeviceFocused = useCallback(
    deviceId => {
      setFocusedDevice(deviceId);
      msgClient.sendMsg({
        type: MsgType.DeviceFocused,
        value: deviceId
      });
    },
    [msgClient]
  );

  const handleDeviceBlurred = useCallback(
    deviceId => {
      setFocusedDevice(null);
      msgClient.sendMsg({
        type: MsgType.DeviceLostFocus,
        value: deviceId
      });
    },
    [msgClient]
  );

  const handleDeviceClicked = useCallback(
    deviceId => {
      setSelectedDevice(prev => {
        if (prev === deviceId) {
          return null;
        }
        return deviceId;
      });
      msgClient.sendMsg({
        type: MsgType.DeviceSelected,
        value: deviceId
      });
    },
    [msgClient]
  );
  const handleLocationSelected = useCallback(() => {
    msgClient.sendMsg({
      type: MsgType.AddressUpdated,
      value: lastLocation.address
    });
    setSelectedLocation(lastLocation.latLng);
    setCircleSelectionConfig(
      generateCircleSelectionConfig({
        center: lastLocation.latLng,
        radius: convertDistanceToMeter(DefaultRadius),
        onRadiusChanged: handleCircleSelectionRadiusChanged,
        ref: circleSelectionRef
      })
    );
    setContextMenuPos(null);
    setLastLocation(null);
    setIsLocationSelected(true);
  }, [msgClient, lastLocation, convertDistanceToMeter]);

  const handlePaneClicked = useCallback(() => {}, []);

  const handleResetClicked = useCallback(() => {
    reset();
    msgClient.sendMsg({
      type: MsgType.Reset
    });
  }, [msgClient, reset]);

  const handleMapClick = useCallback(
    evt => {
      evt.stop();
      if (!isLocationSelected) {
        const latLng = {
          lat: evt.latLng.lat(),
          lng: evt.latLng.lng()
        };
        geocoder
          .geocode({
            location: latLng
          })
          .then(response => {
            if (response.results.length > 0) {
              const address = selectAddress(response.results);
              const lastLocation = { address: address.formatted_address, latLng };
              msgClient.sendMsg({
                type: MsgType.AddressUpdated,
                value: {
                  label: lastLocation.address,
                  latLng: latLng
                }
              });
              if (!isLocationSelected) {
                const zoom = calculateCircleSelectionZoomLevel(latLng);
                setMapOptions(prev => {
                  return {
                    ...prev,
                    center: latLng,
                    zoom: zoom
                  };
                });
              }
              setSelectedLocation(lastLocation.latLng);
              setCircleSelectionConfig(
                generateCircleSelectionConfig({
                  center: lastLocation.latLng,
                  radius: convertDistanceToMeter(DefaultRadius),
                  onRadiusChanged: handleCircleSelectionRadiusChanged,
                  ref: circleSelectionRef
                })
              );
              setContextMenuPos(null);
              setLastLocation(null);
              setIsLocationSelected(true);
            }
          });
      }
    },
    [
      isLocationSelected,
      geocoder,
      msgClient,
      convertDistanceToMeter,
      handleCircleSelectionRadiusChanged,
      calculateCircleSelectionZoomLevel,
      isLocationSelected
    ]
  );

  const handleMarkerMoved = useCallback(
    evt => {
      if (!evt || isSearching) return;

      const latLng = {
        lat: evt.latLng.lat(),
        lng: evt.latLng.lng()
      };
      setSelectedLocation(latLng);
      setCircleSelectionConfig(prev => {
        return {
          ...prev,
          center: latLng
        };
      });
      geocoder
        .geocode({
          location: latLng
        })
        .then(response => {
          if (response.results.length > 0) {
            const address = selectAddress(response.results);
            msgClient.sendMsg({
              type: MsgType.AddressUpdated,
              value: {
                label: address.formatted_address,
                latLng: latLng
              }
            });
          }
        });
    },
    [msgClient, isSearching]
  );

  const handleContextMenuBlur = useCallback(() => {
    setContextMenuPos(null);
  }, []);

  const [Menu, setMenu] = useState(
    <NearestMapMenu onPaneClicked={handlePaneClicked} onResetClicked={handleResetClicked} />
  );

  useEffect(() => {
    if (msgClient) {
      msgClient.addOnMessageEventListener(handleMessaging);
    }
  }, [msgClient, handleMessaging]);

  useEffect(() => {
    if (isSearching) {
      setMenu(null);
    } else {
      setMenu(
        <NearestMapMenu
          hasLocation={isLocationSelected}
          onPaneClicked={handlePaneClicked}
          onResetClicked={handleResetClicked}
        />
      );
    }
  }, [isLocationSelected, handlePaneClicked, handleResetClicked, isSearching]);

  useEffect(() => {
    setCircleSelectionConfig(prev => {
      return {
        ...prev,
        editable: !isSearching
      };
    });
  }, [isSearching]);

  return (
    <>
      <Map
        location={selectedLocation}
        mode={MapMode.NearestVehicle}
        devices={searchedDevices || []}
        ref={mapRef}
        focusedDevice={focusedDevice}
        enableMapMenu={true}
        enableGeofenceSuggest={true}
        onDeviceFocused={handleDeviceFocused}
        onDeviceClicked={handleDeviceClicked}
        onLocationMarked={handleLocationSelected}
        onDeviceBlurred={handleDeviceBlurred}
        mapOptions={mapOptions}
        drawingOptions={drawingOptions}
        containerElement={containerElement}
        mapElement={mapElement}
        draggableMarker={extraMapConfig.draggableMarker}
        useDefaultLocationMarker={true}
        customizedMenu={Menu}
        onClick={handleMapClick}
        onDragEnd={handleMarkerMoved}
        circleSelectionConfig={extraMapConfig.circleSelectionConfig}
        selectedTripSegment={extraMapConfig.selectedTripSegment}
      />
      <ContextMenu
        menuPos={contextMenuPos}
        onLocationSelected={handleLocationSelected}
        onContextMenuBlur={handleContextMenuBlur}
      />
    </>
  );
}
