import { ALERT_LINKS } from '../constants';

export const hasSpeedLimit = link => {
  switch (link) {
    case ALERT_LINKS.SPEED:
    case ALERT_LINKS.GEOFENCE:
      return true;
    default:
      return false;
  }
};
