import React from 'react';
import i18next from 'i18next';
import { Link } from 'react-router-dom';
import { ActionMenu } from 'components/actionMenu';
import { Table } from 'antd';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { deleteSchedule, restoreSchedule } from 'features/driverManagement';
import { format } from 'utils/dates';
import moment from 'moment';
import { entities } from 'features/permissions';
import { getScheduleStatus } from '../VehicleMaintenance/utils/helpers';
import { removeFiles } from 'features/vehicleMaintenance/schedulesSlice';
import { v4 as uuidv4 } from 'uuid';
import {
  Paths,
  MainColumns,
  MainColumnKeys,
  InnerColumns,
  InnerColumnKeys,
  TabKeys,
  STATUS,
  EventColumnKeys,
  EventsColumns,
  BUTTON_ACTIONS
} from './constants';
import { PATHS as UserPaths } from '../Administration/Users/constants';
import { Paths as MgtTypePaths } from '../Configuration/DriverMgtTypes/constants';
import { formatStatus } from '../VehicleMaintenance/utils/helpers';
import { filterFilesBy } from 'utils/files';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { formatBytes } from 'utils/methods';
import { DATE_FORMAT } from 'features/localization/localization';

const maxDocumentUploadSizeInBytes = 5242880;

export const filterSchedules = (
  schedules = [],
  filters = {
    activeTab: 'all',
    searchText: '',
    companyIds: [],
    branchIds: [],
    driverIds: [],
    typeIds: []
  },
  setExpandAll
) => {
  const { activeTab, searchText, companyIds, branchIds, driverIds, typeIds } = filters;
  return schedules.filter(schedule => {
    let isValid = true;

    //Filter by active tab
    if (activeTab === TabKeys.all) {
      isValid = true;
    }
    if (activeTab === TabKeys.scheduled) {
      isValid = isValid && schedule.status === STATUS.pending;
    }
    if (activeTab === TabKeys.duenow) {
      isValid = isValid && schedule.status === STATUS.dueNow;
    }
    if (activeTab === TabKeys.completed) {
      isValid = isValid && schedule.status === STATUS.completed;
    }
    if (activeTab === TabKeys.cancelled) {
      isValid = isValid && schedule.status === STATUS.cancelled;
    }
    if (activeTab === TabKeys.deleted) {
      isValid = isValid && schedule.status === STATUS.deleted;
    }

    // Filter by search field
    if (searchText) {
      isValid = isValid && (schedule?.name || '').toLowerCase().includes(searchText.toLowerCase());
    }

    // Filter by companies
    isValid = isValid && companyIds.includes(schedule.companyId);
    // Filter by branches
    isValid = isValid && branchIds.includes(schedule.branchId);
    // Filter by drivers
    // Do not filter by drivers for deleted schedules because
    // some deleted schedules can belong to drivers outside of the filter
    // since Drivers filters only show drivers from the active schedules
    isValid = isValid && (driverIds.includes(schedule.entityId) || activeTab === TabKeys.deleted);
    // Filter by types
    isValid = isValid && typeIds.includes(schedule.manageTypeId);

    //expand all rows if search or filters are applied
    if (
      searchText ||
      !filters.companyIds.includes(0) ||
      !filters.branchIds.includes(0) ||
      !filters.driverIds.includes(0) ||
      !filters.typeIds.includes(0)
    ) {
      setExpandAll(true);
    }

    return isValid;
  });
};

export const prepareColumnsForTable = (t, expandAllButton) => {
  const cols = MainColumns.map(column => ({
    ...column,
    title: t(`DriverManagement.Table.Columns.${column.title}`)
  }));
  cols.unshift(Table.EXPAND_COLUMN, { title: expandAllButton, width: 1 });
  return cols;
};

export const prepareDataForTable = (schedules = [], drivers = [], branches = [], canViewDriver) => {
  return schedules.map(schedule => {
    const driver = drivers.find(driver => driver.id === schedule.entityId);
    const branch =
      driver?.location?.id && branches.find(branch => branch.id === driver.location.id);
    const licence = driver && `${driver?.licenceState} | ${driver?.licenceNumber}`;
    return {
      [MainColumnKeys.DRIVER]: driver ? (
        canViewDriver ? (
          <Link to={`/settings/users/id/${driver.id}`}>{`${driver.firstName ||
            ''} ${driver.lastName || ''}`}</Link>
        ) : (
          <span>{`${driver.firstName || ''} ${driver.lastName || ''}`}</span>
        )
      ) : (
        ''
      ),
      [MainColumnKeys.USERNAME]: <span>{driver?.username}</span>,
      [MainColumnKeys.BRANCH]: <span>{branch && branch.name}</span>,
      [MainColumnKeys.LICENCE]: <span>{licence}</span>,
      [MainColumnKeys.SCHEDULES]: <span>{schedule?.info?.length}</span>,
      key: schedule.key,
      info: schedule.info
    };
  });
};

export const prepareColumnsForInnerTable = t => {
  const innercols = InnerColumns.map(column => ({
    ...column,
    title: t(`DriverManagement.Table.Columns.${column.title}`)
  }));
  return innercols;
};

export const prepareDataForInnerTable = ({
  schedules,
  mgtTypes,
  users,
  localization,
  history,
  can,
  t,
  dispatch
}) => {
  return schedules.map(schedule => {
    return {
      [InnerColumnKeys.STATUS]: getScheduleStatus(schedule[InnerColumnKeys.STATUS]),
      [InnerColumnKeys.NAME]: getScheduleName(schedule, can),
      [InnerColumnKeys.TYPE_ID]: getMntType(mgtTypes, schedule[InnerColumnKeys.TYPE_ID], can),
      [InnerColumnKeys.SCHEDULED_BY]: getUser(users, schedule[InnerColumnKeys.SCHEDULED_BY]),
      [InnerColumnKeys.SCHEDULED_DATE]: getScheduleDate(
        schedule[InnerColumnKeys.SCHEDULED_DATE],
        localization
      ),
      [InnerColumnKeys.ACTIONS]: (
        <ActionMenu
          items={getActionMenuItems({ schedule, t, history, dispatch })}
          data={schedule}
          dataType={'DRIVERMANAGEMENTSCHEDULE'}
        />
      ),
      key: schedule.id
    };
  });
};

const handleButtonAction = ({ action, data, dispatch }) => () => {
  switch (action) {
    case 'delete':
      dispatch(deleteSchedule({ data }));
      break;
    case 'restore':
      dispatch(restoreSchedule({ data }));
      break;
    default:
  }
};

const getActionMenuItems = ({ schedule, t, history, dispatch }) => {
  return [
    {
      name: t(`Common.View`),
      onClick: () => {
        history.push(`${Paths.VIEW_DRIVERMANAGEMENTSCHEDULE}/${schedule.id}`, { schedule });
      },
      entity: 'VIEW',
      id: 'btn_driverManagementView'
    },
    showButton({ buttonType: BUTTON_ACTIONS.EDIT, data: schedule }) && {
      name: t(`Common.Edit`),
      onClick: () => {
        history.push(`${Paths.EDIT_DRIVERMANAGEMENTSCHEDULE}/${schedule.id}`, { schedule });
      },
      entity: 'UPDATE',
      id: 'btn_driverManagementEdit'
    },
    showButton({ buttonType: BUTTON_ACTIONS.COMPLETE, data: schedule }) && {
      name:
        schedule?.status === STATUS.completed
          ? t('Common.EllipsisButton.EditCompletedInfo')
          : t('Common.EllipsisButton.Complete'),
      onClick: () => {
        history.push(`${Paths.COMPLETE_DRIVERMANAGEMENTSCHEDULE}/${schedule.id}`);
      },
      entity: 'CREATE',
      id: 'btn_driverManagementComplete'
    },
    showButton({ buttonType: BUTTON_ACTIONS.RESTORE, data: schedule }) && {
      name: t('Common.RestoreButton'),
      onClick: handleButtonAction({ action: 'restore', data: schedule, dispatch }),
      entity: 'RESTORE',
      id: 'btn_driverManagementRestore'
    },
    showButton({ buttonType: BUTTON_ACTIONS.DELETE, data: schedule }) && {
      name: t(`Common.Delete`),
      danger: true,
      onClick: () => {
        handleDeleteScheduleModal({ scheduleData: schedule, handleButtonAction, dispatch });
      },
      entity: 'DESTROY',
      id: 'btn_driverManagementDelete'
    }
  ];
};

export const handleDeleteScheduleModal = ({ scheduleData, handleButtonAction, dispatch }) => {
  confirmationModal(
    `${i18next.t('Common.DeleteButton')} ${scheduleData.name}`,
    `${i18next.t('Common.SureDelete')} ${i18next.t('Common.driverMngSchedule')} ${
      scheduleData.name
    }?`,
    i18next.t('Common.DeleteButton'),
    i18next.t('Common.CancelButton'),
    handleButtonAction({ action: 'delete', data: scheduleData, dispatch }),
    'delete'
  );
};

export const showButton = ({ buttonType, data }) => {
  const { status } = data;
  switch (buttonType) {
    case BUTTON_ACTIONS.EDIT:
      return ![STATUS.completed, STATUS.deleted, STATUS.cancelled].includes(status);
    case BUTTON_ACTIONS.CANCEL:
      return ![STATUS.completed, STATUS.deleted].includes(status);
    case BUTTON_ACTIONS.COMPLETE:
      return ![STATUS.cancelled, STATUS.deleted].includes(status);
    case BUTTON_ACTIONS.RESTORE:
      return [STATUS.deleted].includes(status);
    case BUTTON_ACTIONS.DELETE:
      return ![STATUS.deleted].includes(status);
    case BUTTON_ACTIONS.AUDIT:
    default:
      return true;
  }
};

export const getUser = (users = [], userId, can) => {
  if (!userId || !users) {
    return;
  }
  const user = users.find(u => u.id === userId);
  return user ? (
    can && can({ everyEntity: [entities.USER_VIEW] }) ? (
      <Link to={`${UserPaths.USER_VIEW}/${userId}`}>{`${user?.firstName || ''} ${user?.lastName ||
        ''}`}</Link>
    ) : (
      <span>{`${user?.firstName || ''} ${user?.lastName || ''}`}</span>
    )
  ) : (
    ''
  );
};

export const getBranch = (users = [], branches = [], driverId) => {
  if (!driverId) {
    return;
  }
  const driver = users.find(driver => driver.id === driverId);
  const branch = driver?.location?.id && branches.find(branch => branch.id === driver.location.id);
  return branch?.name;
};

export const getRaisedBy = (users = [], userId) => {
  if (!userId || !users) {
    return;
  }
  const user = users.find(u => u.id === userId);
  return `${user?.firstName || ''} ${user?.lastName || ''}`;
};

export const getScheduleName = (schedule, can) => {
  if (!schedule?.name) {
    return;
  }
  return can && can({ everyEntity: [entities.DRIVERMANAGEMENTSCHEDULE_VIEW] }) ? (
    <Link to={`/driverManagement/id/${schedule.id}`}>{schedule[InnerColumnKeys.NAME]}</Link>
  ) : (
    <span>{schedule[InnerColumnKeys.NAME]}</span>
  );
};

export const getMntType = (types = [], typeId, can = false, suppressHyperlink = false) => {
  if (!types || !typeId) {
    return;
  }
  const type = types.find(t => t.id === typeId);
  if (suppressHyperlink) {
    return type?.name;
  }

  return type && can && can({ everyEntity: [entities.DRIVERMANAGEMENTTYPE_VIEW] }) ? (
    <Link to={`${MgtTypePaths.VIEW_DRIVERMANAGEMENTTYPE}/${typeId}`}>{type?.name}</Link>
  ) : (
    <span>{type?.name || ''}</span>
  );
};

export const getTrimmedScheduleDate = date => date && date.split('+') && date.split('+')[0];
export const getScheduleDate = (date, localization) => {
  if (!getTrimmedScheduleDate(date)) {
    return;
  }
  return format(getTrimmedScheduleDate(date), localization.formats.time.formats.dby);
};

export const formatCompletionDate = (date, localization) => {
  if (!date) {
    return;
  }
  return format(moment(date, DATE_FORMAT.DEFAULT), localization.formats.time.formats.dby);
};

export const getDataForEdit = (id, schedules, drivers, branches) => {
  if (!schedules || !id) {
    return;
  }
  const data = schedules.find(s => s.id === parseInt(id, 10));
  return {
    ...data,
    schedulesOn: data?.schedulesOn ? moment(data?.schedulesOn) : null,
    days: data?.parameters ? JSON.parse(data.parameters)?.days : '',
    branchId: getBranchId(data, drivers, branches)
  };
};

export const prepareScheduleData = (schedule, drivers, branches) => {
  if (!schedule) {
    return;
  }
  return {
    ...schedule,
    schedulesOn: getTrimmedScheduleDate(schedule?.schedulesOn)
      ? moment(getTrimmedScheduleDate(schedule?.schedulesOn))
      : null,
    days: schedule?.parameters ? JSON.parse(schedule.parameters)?.days : '',
    branchId: getBranchId(schedule, drivers, branches)
  };
};

export const getBranchId = (schedule, drivers = [], branches = []) => {
  const driver = drivers.find(d => d.id === schedule.entityId);
  const branch = driver?.location?.id && branches.find(b => b.id === driver?.location?.id);
  return branch?.id || -1;
};

export const getCancelReason = schedule => {
  if (!schedule.cancellationParameters) {
    return;
  }
  return JSON.parse(schedule.cancellationParameters)?.reason;
};

export const getSignedOffBy = schedule => {
  if (!schedule.completionParameters) {
    return;
  }
  return JSON.parse(schedule.completionParameters)?.signed_off_by;
};

export const preparePopupRows = ({
  t,
  localization,
  users,
  branches,
  mgtTypes,
  schedule,
  isCancelled,
  can
}) => {
  return [
    {
      key: t('DriverManagement.Form.Name'),
      value: schedule?.name
    },
    {
      key: t('Vehicles.View.Status'),
      value: schedule.status && t(`VehicleMntSchedules.Table.${formatStatus(schedule.status)}`)
    },
    {
      key: t('VehicleMntSchedules.View.CancelReason'),
      value: getCancelReason(schedule),
      isHidden: !isCancelled
    },
    {
      key: t('DriverManagement.Form.Driver'),
      value: getUser(users, schedule.entityId, can)
    },
    {
      key: t('Common.Branch'),
      value: getBranch(users, branches, schedule.entityId)
    },
    {
      key: t('Common.Type'),
      value: getMntType(mgtTypes, schedule[InnerColumnKeys.TYPE_ID], can)
    },
    {
      key: t('DriverManagement.Form.ScheduledBy'),
      value: getUser(users, schedule[InnerColumnKeys.SCHEDULED_BY], can)
    },
    {
      key: t('DriverManagement.Form.SignedOffBy'),
      value: getSignedOffBy(schedule)
    }
  ];
};

export const preparePopupActions = ({
  t,
  history,
  schedule,
  handleDeleteAction,
  handleActivePopup
}) => {
  return [
    {
      title: t('Actions.View'),
      onClick: () =>
        history.push(`${Paths.VIEW_DRIVERMANAGEMENTSCHEDULE}/${schedule.id}`, {
          id: schedule.id
        })
    },
    {
      title: t('Actions.Edit'),
      disabled: !showButton({ buttonType: BUTTON_ACTIONS.EDIT, data: schedule }),
      onClick: () => {
        history.push(`${Paths.EDIT_DRIVERMANAGEMENTSCHEDULE}/${schedule.id}`, {
          schedule
        });
      }
    },
    {
      title:
        schedule?.status === STATUS.completed
          ? t('VehicleMntSchedules.View.EditCompletedInfo')
          : t('Common.EllipsisButton.Complete'),
      disabled: !showButton({ buttonType: BUTTON_ACTIONS.COMPLETE, data: schedule }),
      onClick: () => {
        history.push(`${Paths.COMPLETE_DRIVERMANAGEMENTSCHEDULE}/${schedule.id}`);
      }
    },
    {
      title: t('Actions.Delete'),
      danger: true,
      onClick: () => {
        // Close the popup
        handleActivePopup(false);

        confirmationModal(
          `${t('VehicleMntSchedules.View.DeleteSchedule')}`,
          t('VehicleMntSchedules.View.AreYouSure', {
            name: schedule?.name
          }),
          `${t('VehicleMntSchedules.View.Delete')}`,
          `${t('VehicleMntSchedules.View.Cancel')}`,
          handleDeleteAction(schedule, t),
          'delete'
        );
      }
    }
  ];
};

export const prepareColumnsForEvents = t => {
  return EventsColumns.map(column => ({
    ...column,
    title: t(`DriverManagement.Table.Columns.${column.title}`)
  }));
};

export const prepareDataForEvents = ({ events, t, localization }) => {
  return events.map(event => {
    return {
      [EventColumnKeys.DATE]:
        event.date && format(new Date(event.date), localization.formats.time.formats.dby_imp),
      [EventColumnKeys.STATUS]:
        event.status && t(`VehicleMntSchedules.Table.${formatStatus(event.status)}`),
      key: event.date
    };
  });
};

export const renderUploadedFiles = ({
  files = [],
  currentUser,
  dateFormat,
  updateFilesCb,
  dispatch
}) => {
  return files.map(file => {
    const uploadDate = file.uploadedAt ? new Date(file.uploadedAt.split('+')[0]) : new Date();
    return {
      id: file.id || uuidv4(),
      file: file.name,
      fileSize: file.size,
      uploadedBy: file.uploadedBy || `${currentUser.firstName} ${currentUser.lastName}`,
      uploadDate: format(uploadDate, dateFormat),
      actions: removeFile({ fileObj: file, files, updateFilesCb, dispatch })
    };
  });
};

export const handleFileAttachment = ({
  uploadFiles,
  files,
  dispatch,
  updateFilesCb,
  maxFileSizeBytes
}) => {
  if (!uploadFiles || !uploadFiles.length) return;
  // Since files is a set - we need to convert it to an actual array
  const filesToAdd = [...uploadFiles];

  const companyConfigMaxFileSizeInBytes = maxFileSizeBytes || maxDocumentUploadSizeInBytes;
  const maxFileSize = formatBytes(companyConfigMaxFileSizeInBytes);

  // Remove duplicates
  const currentFileNames = files.map(file => file.name);
  const uniqueFiles = filesToAdd.filter(file => !currentFileNames.some(fn => fn === file.name));

  const filesToSave = filterFilesBy(uniqueFiles, {
    type: {
      values: ['application/pdf'],
      callback: name => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: i18next.t('VehicleMntSchedules.Notifications.OnlyPdf', {
              name: name
            })
          })
        );
      }
    },
    size: {
      value: companyConfigMaxFileSizeInBytes,
      callback: name => {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: i18next.t('VehicleMntSchedules.Notifications.FileSize', {
              name: name,
              fileSize: maxFileSize
            })
          })
        );
      }
    }
  });
  //callback save files
  updateFilesCb([...files, ...filesToSave]);
};

const removeFile = ({ fileObj, files, updateFilesCb, dispatch }) => () => {
  // do not show modal confirmation for the files that are not yet uploaded, just delete them
  if (fileObj && fileObj.id) {
    //if the file has id, show modal and remove them from api
    handleDeleteModal({ fileObj, files, updateFilesCb, dispatch });
    return;
  }
  updateFilesCb(files.filter(file => file.name !== fileObj.name));
};

const handleDeleteModal = ({ fileObj, files, updateFilesCb, dispatch }) => {
  confirmationModal(
    `${i18next.t('VehicleMntSchedules.DeleteDocument')}`,
    i18next.t('VehicleMntSchedules.Notifications.SureDelete', {
      name: fileObj.name
    }),
    i18next.t('Common.Modal.Delete'),
    i18next.t('Common.Modal.Cancel'),
    () => onSubmitDeleteAction({ fileObj, files, updateFilesCb, dispatch }),
    'delete'
  );
};

const onSubmitDeleteAction = ({ fileObj, files, updateFilesCb, dispatch }) => {
  dispatch(removeFiles(fileObj, files, updateFilesCb));
};
