import { useMemo } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { t_error } from 'i18nextConfig';
import { useDispatch, useSelector } from 'react-redux';
import { api } from 'utils/api';
import { vehicleTypeMetrics } from './constants';

const configuration = {
  defaultConfiguration: {},
  configurationValues: {},
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    defaultFetched: false
  }
};

function startDefaultConfigurationLoading(state) {
  state.meta.isFetching = true;
}

function startConfigurationValuesLoading(state) {
  state.meta.isFetching = true;
}

function loadingDefaultConfigurationFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.fetched = true;
  state.meta.error = action.payload.err;
  state.defaultConfiguration = {};
}

function loadingConfigurationValuesFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.fetched = true;
  state.meta.error = action.payload.err;
}

const updateIdle = metric => {
  const keys = Object.keys(metric);
  const key = keys.includes('defaultValue') ? 'defaultValue' : 'value';
  const value = (parseFloat(metric[key]) / 60).toFixed(1);
  return {
    ...metric,
    [key]: value
  };
};

const updatedMetrics = (metric = {}) => {
  const { key } = metric;
  const metricsMap = {
    'idle.threshold': updateIdle
  };
  const method = metricsMap[key];

  return method ? method(metric) : metric;
};

const recalculateConfigurationValues = (metrics = []) => {
  return metrics.map(updatedMetrics);
};

const configurationSlice = createSlice({
  name: 'configuration',
  initialState: configuration,
  reducers: {
    fetchDefaultConfigurationStart: startDefaultConfigurationLoading,
    fetchConfigurationValuesStart: startConfigurationValuesLoading,
    fetchDefaultConfigurationSuccess(state, { payload }) {
      const defaultConfiguration = (payload || []).reduce((accum, metric) => {
        if (Object.values(vehicleTypeMetrics).includes(metric.key)) {
          accum[metric.key] = updatedMetrics(metric);
        }
        return accum;
      }, {});
      state.defaultConfiguration = defaultConfiguration;
      state.meta.isFetching = false;
      state.meta.fetched = true;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
    },
    fetchConfigurationValuesSuccess(state, { payload }) {
      const { body, vehicleTypeId } = payload;
      const newBody = recalculateConfigurationValues(body);
      state.configurationValues[vehicleTypeId] = newBody;
      state.meta.isFetching = false;
      state.meta.fetched = true;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
    },
    fetchDefaultConfigurationFailure: loadingDefaultConfigurationFailed,
    fetchConfigurationValuesFailure: loadingConfigurationValuesFailed
  }
});

const {
  fetchDefaultConfigurationStart,
  fetchDefaultConfigurationSuccess,
  fetchDefaultConfigurationFailure,
  fetchConfigurationValuesStart,
  fetchConfigurationValuesSuccess,
  fetchConfigurationValuesFailure
} = configurationSlice.actions;

const fetchDefaultConfiguration = () => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  try {
    if (!userKey) {
      return;
    }
    dispatch(fetchDefaultConfigurationStart());
    api
      .get(`/configuration/keys`, {
        authKey: userKey,
        query: {
          customer: true,
          service: 'VPM'
        }
      })
      .then(res => {
        dispatch(fetchDefaultConfigurationSuccess(res.body));
      })
      .catch(err => {
        dispatch(fetchDefaultConfigurationFailure({ err: err.toString() }));
        console.warn('ERROR', err);
      });
  } catch (err) {
    dispatch(fetchDefaultConfigurationFailure({ err: err.toString() }));
  }
};

export const fetchConfigurationValues = (vehicleTypeId, deviceid, service, onError) => (
  dispatch,
  getState
) => {
  const userKey = getState().user.current.auth.key;
  try {
    if (!userKey) {
      return;
    }
    dispatch(fetchConfigurationValuesStart());
    api
      .get(`/configuration/values`, {
        authKey: userKey,
        query: {
          ...(vehicleTypeId ? { vehicle_type_id: vehicleTypeId } : {}),
          ...(deviceid ? { device_id: deviceid } : {}),
          ...(service ? { service: service } : {})
        }
      })
      .then(res => {
        dispatch(fetchConfigurationValuesSuccess({ body: res.body, vehicleTypeId }));
      })
      .catch(err => {
        dispatch(fetchConfigurationValuesFailure({ err: err.toString() }));
        if (onError) onError(t_error(err?.response?.body || err.toString()));
        console.warn('ERROR', err);
      });
  } catch (err) {
    if (onError) onError(t_error(err));
    dispatch(fetchConfigurationValuesFailure({ err: err.toString() }));
  }
};

export const useDefaultConfiguration = () => {
  const dispatch = useDispatch();
  const configuration = useSelector(state => state.configuration.defaultConfiguration);
  const isFetching = useSelector(state => state.configuration.meta.isFetching);
  const fetched = useSelector(state => state.configuration.meta.fetched);

  if (!isFetching && (!configuration || Object.keys(configuration).length === 0) && !fetched) {
    dispatch(fetchDefaultConfiguration());
  }
  return configuration;
};

export const useIsFetchingVehicleConfig = () =>
  useSelector(state => state.configuration.meta.isFetching);

export const useConfigurationValues = (vehicleTypeId, deviceId, service, onError) => {
  const dispatch = useDispatch();
  const isFetching = useIsFetchingVehicleConfig();
  const configurationValues = useSelector(
    state => state.configuration.configurationValues[vehicleTypeId]
  );

  if (!isFetching && !configurationValues && Number(vehicleTypeId)) {
    dispatch(fetchConfigurationValues(vehicleTypeId, deviceId, service, onError));
  }

  const configurationValuesOfService = useMemo(
    () =>
      service && configurationValues?.length
        ? configurationValues.filter(
            configValue => configValue.service === service || !configValue.service
          )
        : configurationValues,
    [configurationValues, service]
  );

  return configurationValuesOfService;
};

export const setConfigurationValues = postBody => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  try {
    const response = await api.post('/configuration/values', { authKey: userKey }, postBody);
    if (response?.body) {
      postBody.length > 0 && dispatch(fetchConfigurationValues(postBody[0].vehicleTypeId));
      return response.body;
    }
    return;
  } catch (err) {
    const message = err.response?.body?.error || err.message;
    dispatch(
      openToast({
        type: ToastType.Error,
        message: `${message}`
      })
    );
  }
};

export default configurationSlice.reducer;
