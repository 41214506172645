import i18next from 'i18nextConfig';
import { Comparators } from 'utils/sorting';
import {
  stateCellRenderer,
  normalCellRenderer,
  driveTimeCellRenderer,
  onDutyTimeCellRenderer,
  cycleTimeCellRenderer,
  driverCellRenderer,
  fleetCellRenderer
} from './CellRenders';

export const OverviewGridColumns = [
  {
    title: <></>,
    key: 'stateCell',
    width: 54,
    fixed: 'left',
    render: stateCellRenderer
  },
  {
    get title() {
      return i18next.t('Common.Driver');
    },
    key: 'driverName',
    dataIndex: 'driverName',
    render: driverCellRenderer,
    sorter: Comparators.String('driverName'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true,
    canSearch: true,
    fixed: 'left',
    width: 120
  },
  {
    get title() {
      return i18next.t('Common.Status');
    },
    key: 'typeDesc',
    dataIndex: 'typeDesc',
    render: normalCellRenderer,
    sorter: Comparators.String('typeDesc'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true,
    canSearch: true,
    width: 100
  },
  {
    get title() {
      return i18next.t('Common.Branch');
    },
    key: 'branch',
    dataIndex: 'branch',
    render: normalCellRenderer,
    sorter: Comparators.String('branch'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: false,
    canSearch: true,
    width: 100
  },
  {
    get title() {
      return i18next.t('Common.Fleet');
    },
    key: 'fleet',
    dataIndex: 'fleet',
    render: fleetCellRenderer,
    sorter: Comparators.String('fleet'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: false,
    canSearch: true,
    width: 200
  },
  {
    get title() {
      return i18next.t('Common.Vehicle');
    },
    key: 'vehicleName',
    dataIndex: 'vehicleName',
    render: normalCellRenderer,
    sorter: Comparators.String('vehicleName'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: true,
    canSearch: true,
    width: 150
  },
  {
    get title() {
      return i18next.t('Common.Location');
    },
    key: 'location',
    dataIndex: 'location',
    render: normalCellRenderer,
    sorter: Comparators.String('location'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: false,
    canSearch: true,
    width: 200
  },
  {
    get title() {
      return i18next.t('Common.LastUpdate');
    },
    key: 'lastUpdate',
    dataIndex: 'lastUpdate',
    render: normalCellRenderer,
    sorter: Comparators.Date('lastUpdate'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    generalFilter: false,
    defaultSortColumn: true,
    defaultSortOrder: 'descend',
    canSearch: true,
    width: 150
  },
  {
    get title() {
      return i18next.t('ELD.Drive Time Left');
    },
    key: 'driveTime',
    dataIndex: 'driveTime',
    render: driveTimeCellRenderer,
    sorter: Comparators.Number('driveTimeAvailable'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 150
  },
  {
    get title() {
      return i18next.t('ELD.On Duty Time Left');
    },
    key: 'onDutyTime',
    dataIndex: 'onDutyTime',
    render: onDutyTimeCellRenderer,
    sorter: Comparators.Number('onDutyTimeAvailable'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 150
  },
  {
    get title() {
      return i18next.t('ELD.Cycle Time Left');
    },
    key: 'cycleTime',
    dataIndex: 'cycleTime',
    render: cycleTimeCellRenderer,
    sorter: Comparators.Number('cycleTimeLeft'),
    sortDirections: ['ascend', 'descend', 'ascend'],
    width: 150
  }
];
