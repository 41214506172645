import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toLower, trim } from 'lodash';
import {
  useLocations,
  useDeletedLocations,
  deleteLocationApi,
  restoreLocationApi,
  useIsFetching
} from 'features/locations/locationsSlice';
import { useLocationTypes } from 'features/locations/locationTypesSlice';
import { useCompanies, useSubCompanies, useCurrentCompany } from 'features/company/companySlice';
import { TabNavLink } from 'components/nav/NavLinks';
import { LocationsTable } from './LocationsTable';
import { cache } from './CellRenderers';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { exportFile } from 'components/excelFile';
import { CSVLink } from 'react-csv';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { Buttons } from 'components/ant';
import { useCan } from 'features/permissions';

import useDebounce from 'utils/hooks/useDebounce';
import { prepareDataForMultiselect } from 'utils/filters';
import {
  helpers,
  prepareFileForExcelExport,
  prepareDataForCsvExport,
  getCSVFilename
} from './helpers';
import { helpers as geofencesHelpers } from 'containers/Administration/Geofences/helpers';
import { XLSX_FILE_TITLE, columnWidth, TYPES } from './constants';
import { BULK_TYPES } from 'containers/Administration/BulkManagement/components/Modal/constants';
import { PATHS as COMPANYCONFIG_PATHS } from 'containers/Configuration/CompanyConfig/utils/constants';

import './Locations.scss';
import styles from './Locations.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const Locations = () => {
  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const locations = useLocations();
  const locationTypes = useLocationTypes();

  const companies = useCompanies();
  const subCompanies = useSubCompanies();
  const company = useCurrentCompany();
  const dispatch = useDispatch();
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filterType, setFilterType] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filterTab, setFilterTab] = useState(filterPath);
  const deletedLocations = useDeletedLocations(filterTab === 'deleted');
  const debouncedSearchText = useDebounce(trim(filterText), 300);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const [tableRef, setTableRef] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const localization = useLocalization();
  const can = useCan();
  const csvLinkRef = useRef(null);

  useEffect(() => {
    dispatch(setPageTitle(t('Locations.LocationsTitle')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  useEffect(() => {
    if (path === '/settings/locations') {
      setFilterTab('all');
    }
  }, [path]);

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
  }, [subCompanies, t]);

  useEffect(() => {
    const types = locationTypes.reduce((previous, type) => {
      if (type.code !== TYPES.job.toUpperCase()) {
        return [
          ...previous,
          {
            ...type,
            name: t(`Locations.LocationType.${type?.name}`)
          }
        ];
      }

      return previous;
    }, []);
    setFilterType(prepareDataForMultiselect(types, t('Locations.AllTypes'), null));
  }, [locationTypes, t]);

  useEffect(() => {
    let locationsToFilter = locations;
    if (filterTab === 'deleted') {
      locationsToFilter = deletedLocations;
    }
    setFilteredLocations(
      locationsToFilter.filter(location => {
        let validLocation = true;

        // Filter by user type
        if (filterTab === 'all') {
          validLocation =
            validLocation &&
            location.type &&
            location.type.code &&
            toLower(location.type.code) !== 'job';
        }
        if (filterTab === 'withGeofences') {
          validLocation = validLocation && location.geofences && location.geofences.length > 0;
        }
        if (filterTab === 'withoutGeofences') {
          validLocation = validLocation && location.geofences && location.geofences.length === 0;
        }

        // Filter by search field
        if (debouncedSearchText) {
          validLocation =
            validLocation && toLower(location.name).indexOf(toLower(debouncedSearchText)) > -1;
        }

        // Filter by companies
        const checkedCompaniesIds = filterCompanies
          .filter(company => company.checked)
          .map(company => parseInt(company.id, 10));
        if (!(checkedCompaniesIds.indexOf(0) > -1)) {
          validLocation =
            validLocation &&
            location.companyId &&
            checkedCompaniesIds.indexOf(parseInt(location.companyId, 10)) > -1;
        }

        // Filter by type
        const checkeTypeIds = filterType
          .filter(type => type.checked)
          .map(type => parseInt(type.id, 10));
        if (!(checkeTypeIds.indexOf(0) > -1)) {
          validLocation =
            validLocation &&
            location.type &&
            location.type.id &&
            checkeTypeIds.indexOf(parseInt(location.type.id, 10)) > -1;
        }

        return validLocation;
      })
    );
  }, [debouncedSearchText, filterType, filterCompanies, locations, deletedLocations, filterTab]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [filteredLocations, tableRef]);

  useEffect(() => {
    csvData?.length && csvLinkRef?.current?.link?.click();
  }, [csvData]);

  const handleExportCSV = () =>
    setCsvData(prepareDataForCsvExport(geofencesHelpers.getFilteredLocations(filteredLocations)));

  const handleBulkImport = () =>
    history.push({
      pathname: COMPANYCONFIG_PATHS.BULK_MANAGEMENT,
      state: { showImportModal: true, defaultImportType: BULK_TYPES.LOCATIONS }
    });

  const TabsFilters = () => {
    return (
      <>
        <TabNavLink
          to="/settings/locations/all"
          isActive={(_, location) => {
            return ['/', '/settings', '/settings/locations', '/settings/locations/all'].includes(
              location.pathname
            );
          }}
          onClick={() => {
            setFilterTab('all');
          }}
        >
          {t('Locations.All')}
        </TabNavLink>
        <TabNavLink
          exact
          to={`/settings/locations/withGeofences`}
          onClick={() => {
            setFilterTab('withGeofences');
          }}
        >
          {t('Locations.WithGeofences')}
        </TabNavLink>
        <TabNavLink
          exact
          to={`/settings/locations/withoutGeofences`}
          onClick={() => {
            setFilterTab('withoutGeofences');
          }}
        >
          {t('Locations.WithoutGeofences')}
        </TabNavLink>

        <TabNavLink
          exact
          to={`/settings/locations/deleted`}
          onClick={() => {
            setFilterTab('deleted');
          }}
        >
          {t('Locations.Deleted')}
        </TabNavLink>
      </>
    );
  };

  const handleAction = actionObject => {
    if (actionObject.action === 'restore') {
      dispatch(restoreLocationApi(actionObject.data));
    }
  };

  const handleDeleteAction = data => () => {
    dispatch(deleteLocationApi(data));
  };

  const handleExportExcel = () => {
    const data = prepareFileForExcelExport({
      locations: filteredLocations,
      companies,
      dateFormat: localization.formats.time.formats.dby_imsp
    });

    dispatch(
      exportFile(data, {
        width: columnWidth,
        title: XLSX_FILE_TITLE,
        dateFormat: localization.formats.time.formats.dby_imsp
      })
    );
  };

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div>
            <TabsFilters />
          </div>
          <div>
            <Buttons
              id={BUTTON_IDS.locationsPrimary}
              primaryButton={helpers.getPrimaryButton({ can, history })}
              moreButtons={helpers.getMoreButtons({
                handleExportExcel,
                handleBulkImport,
                handleExportCSV,
                isExportCsvAllowed:
                  geofencesHelpers.getFilteredLocations(filteredLocations).length !== 0,
                isExportExcelAllowed: filteredLocations.length !== 0
              })}
            />
            <CSVLink
              enclosingCharacter={``}
              data={csvData || []}
              className={styles.hidden}
              filename={getCSVFilename({
                entityName: t('Locations.CSV.Filename'),
                companyName: company?.name
              })}
              ref={csvLinkRef}
            ></CSVLink>
          </div>
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} />
            <AntMultiselect
              title={
                filterCompanies?.some(value => !value.checked)
                  ? t('Common.Companies')
                  : t('Common.AllCompanies')
              }
              onFilter={v => setFilterCompanies(v)}
              data={filterCompanies}
            />
            <AntMultiselect
              title={
                filterType?.some(value => !value.checked) ? t('Common.Types') : t('Common.AllTypes')
              }
              onFilter={v => setFilterType(v)}
              data={filterType}
            />
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {filteredLocations.length}{' '}
            {filteredLocations.length === 1
              ? `${t('Locations.Location')}`
              : `${t('Locations.Locations')}`}
          </label>
        </div>
        <div style={{ flex: '1 0 0' }}>
          <LocationsTable
            locations={filteredLocations}
            companies={companies}
            isLoading={useIsFetching(filterTab === 'deleted' ? 'deletedLocations' : 'locations')}
            setTableRef={setTableRef}
            typeOfEntityToDelete={t('Common.location')}
            handleAction={action => handleAction(action)}
            handleDeleteAction={handleDeleteAction}
            filterTab={filterTab}
          />
        </div>
      </ContainerPageWrapper>
    </>
  );
};
