import imgElectric from './Images/mixed-energy-electric.svg';

export const TILES = [
  {
    translationKey: 'MixedEnergy.ElectricVehicles',
    img: imgElectric
  }
];

export const TILES_DIRECTION = 'horizontal';

export const TILES_GRID_SYSTEM = {
  gutter: 12,
  xs: 1,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 4,
  xxl: 6
};

export const TYLE_LIST_STYLE = { padding: 20 };
