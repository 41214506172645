import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import { VehicleMaintenanceSchedules } from './VehicleMaintenanceSchedules';
import { Inspections } from './Inspections/Inspections';
import {
  useCanOneOfServices,
  services,
  useCanFeatureFlag,
  FeatureFlag
} from 'features/permissions';

import styles from './VehicleMaintenanceSchedules.module.scss';
import { insertIf } from '../../utils/methods';
import { InspectionSummary } from './InspectionSummary/InspectionSummary';

export const VehicleMaintenance = ({ match, history }) => {
  const pretripPermission = useCanOneOfServices([services.PRETRIP]);
  const dvirFeatureFlag = useCanFeatureFlag({ featureFlag: FeatureFlag.dvir_inspection.flag });
  const inspectionStatusFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.inspectionStatusGrid.flag
  });
  const { t } = useTranslation();

  return (
    <div className={styles.tabs}>
      <Tabs
        defaultActiveKey={match?.params?.schedules}
        onChange={key => {
          history.push(`/vehiclemaintenance/${key}`);
        }}
        items={[
          {
            label: t('VehicleMntSchedules.SchedulesTab'),
            key: 'schedules',
            children: <VehicleMaintenanceSchedules />
          },
          ...insertIf(dvirFeatureFlag && pretripPermission, {
            label: t('VehicleMntSchedules.InspectionsTab'),
            key: 'inspections',
            children: <Inspections />
          }),
          ...insertIf(inspectionStatusFlag, {
            label: t('VehicleMntSchedules.InspectionSummary'),
            key: 'summary',
            children: <InspectionSummary />
          })
        ]}
      />
    </div>
  );
};
