import { set, isObject, pickBy, get, merge, some } from 'lodash';
import { UNITS } from 'features/localization/localization';
import { format } from 'utils/dates';
import { Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export const TABS = {
  BASIC: 'BASIC',
  ADVANCED: 'ADVANCED',
  BULK_EDIT: 'BULK_EDIT'
};

export const DUTY_TYPE = {
  heavy: 'heavy',
  medium: 'medium',
  light: 'light'
};

const lowerCaseFirstLetter = string => {
  return string.charAt(0).toLocaleLowerCase() + string.slice(1);
};

export const Form_Field_Type = {
  InputNumber: 'InputNumber',
  Select: 'Select',
  Switch: 'Switch'
};

export const getDirtyCheckConfirmProps = ({ t }) => [
  t('Common.Modal.SureTitle'),
  t('CompanyConfig.IQCamera.Validation.SureQuestionDiscardChanges'),
  t('RouteGuard.SaveAndLeavePage'),
  t('RouteGuard.StayOnPage')
];

export const getSubmissionConfirmProps = ({ t, tab }) => [
  t(`CompanyConfig.IQCamera.${tab}.SubmissionConfirm.SureTitle`),
  t(`CompanyConfig.IQCamera.${tab}.SubmissionConfirm.SureQuestion`),
  t('Common.Modal.OK'),
  t('Common.Modal.Cancel')
];

const mphFormField = {
  getUnit: localization => localization.formats.speed.unit_per_hour,
  getFieldvalue: ({ value_in_mile, localization }) => {
    if (!localization) {
      return value_in_mile;
    }
    const localizedValue = isNaN(Number(value_in_mile))
      ? 0
      : localization.convertSpeedWithUnit(
          Number(value_in_mile),
          localization?.formats?.speed?.unit || UNITS.KM,
          UNITS.MILE,
          0
        );
    return isNaN(Number(localizedValue)) ? 0 : Number(localizedValue);
  },
  getFieldLabel: ({ value_in_mile, localization }) => {
    if (!localization) {
      return `${value_in_mile} mph`;
    }

    const locale_unit = localization?.formats?.speed?.unit || UNITS.KM;
    let localizedValue = isNaN(Number(value_in_mile))
      ? 0
      : localization.convertSpeedWithUnit(Number(value_in_mile), locale_unit, UNITS.MILE, 2);

    localizedValue = isNaN(Number(localizedValue)) ? 0 : Number(localizedValue);

    return locale_unit === UNITS.MILE
      ? `${value_in_mile} mph`
      : `${Math.trunc(localizedValue * 10) / 10} ${
          localization.formats.speed.unit_per_hour
        } (${value_in_mile} mph)`;
  },
  getRules: ({ min_in_mile, max_in_mile }) => [
    { required: true },
    { pattern: /^\d+$/ },
    localization => ({
      min: Number(
        localization.convertSpeedWithUnit(
          min_in_mile,
          localization?.formats?.speed?.unit || UNITS.KM,
          UNITS.MILE,
          0
        )
      ),
      type: 'number'
    }),
    localization => ({
      max: Number(
        localization.convertSpeedWithUnit(
          max_in_mile,
          localization?.formats?.speed?.unit || UNITS.KM,
          UNITS.MILE,
          0
        )
      ),
      type: 'number'
    })
  ],
  getHelp: ({ min_in_mile, max_in_mile }) => ({
    getText: (t, localization) => {
      const locale_unit = localization?.formats?.speed?.unit || UNITS.KM;
      return t('CompanyConfig.IQCamera.Validation.range', {
        from: localization.convertSpeedWithUnit(min_in_mile, locale_unit, UNITS.MILE, 0),
        to: localization.convertSpeedWithUnit(max_in_mile, locale_unit, UNITS.MILE, 0)
      });
    }
  }),
  toPayloadValue(localization, localizedValue) {
    const payloadValue = isNaN(Number(localizedValue))
      ? 0
      : localization.convertSpeedWithUnit(
          Number(localizedValue),
          UNITS.MILE,
          localization?.formats?.speed?.unit || UNITS.KM,
          0
        );
    return isNaN(Number(payloadValue)) ? 0 : Number(payloadValue);
  }
};

const CONFIG_FIELDS = {
  eventVideoDriverCameraQuality: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `eventVideoDriverCameraQuality${config_type}`;
      return {
        label: {
          getText: t =>
            `${t('CompanyConfig.IQCamera.Video Quality')} (${t(
              'CompanyConfig.IQCamera.Driver Camera'
            )})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min: 0, type: 'number' },
          { max: 10, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 0, to: 10 })
        }
      };
    }
  },
  eventVideoDriverCameraResolution: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `eventVideoDriverCameraResolution${config_type}`;
      return {
        label: {
          getText: t =>
            `${t('CompanyConfig.IQCamera.Video Resolution')} (${t(
              'CompanyConfig.IQCamera.Driver Camera'
            )})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.VideoDriverCameraResolution,
        rules: [{ required: true }]
      };
    }
  },
  eventVideoQuality: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `eventVideoQuality${config_type}`;
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Video Quality')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min: 0, type: 'number' },
          { max: 10, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 0, to: 10 })
        }
      };
    }
  },
  eventVideoResolution: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `eventVideoResolution${config_type}`;
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Video Resolution')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.VideoResolutions,
        rules: [{ required: true }]
      };
    }
  },
  preEventVideoDuration: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `preEventVideoDuration${config_type}`;
      let min = 5,
        max = 9;
      switch (config_type) {
        case 'DistractedDriving':
        case 'CellphoneDistraction':
        case 'DrowsyDriving':
        case 'LizardEyeDistraction':
          min = 7;
          break;
        default:
          break;
      }
      return {
        label: {
          getText: t =>
            `${t('CompanyConfig.IQCamera.Pre-event')} ${t(
              'CompanyConfig.IQCamera.Video Duration'
            )} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min, type: 'number' },
          { max, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: min, to: max })
        }
      };
    }
  },
  postEventVideoDuration: {
    toDutyFormField(dutyPayload, config_type) {
      const affix = config_type === 'StopSignViolation' ? 'StopSign' : config_type;
      let min = 2,
        max = 50;
      switch (config_type) {
        case 'DistractedDriving':
        case 'CellphoneDistraction':
        case 'LizardEyeDistraction':
          min = 3;
          break;
        case 'DrowsyDriving':
        case 'Speeding':
        case 'StopSignViolation':
          min = 1;
          break;
        default:
          break;
      }
      const payloadName = `postEventVideoDuration${affix}`;
      return {
        label: {
          getText: t =>
            `${t('CompanyConfig.IQCamera.Post-event')} ${t(
              'CompanyConfig.IQCamera.Video Duration'
            )} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min, type: 'number' },
          { max, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: min, to: max })
        }
      };
    }
  },
  EventVideoType: {
    toDutyFormField(dutyPayload, config_type) {
      const prefix =
        config_type === 'StopSignViolation' ? 'stopSign' : lowerCaseFirstLetter(config_type);
      const payloadName = `${prefix}EventVideoType`;
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Video Format')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.VideoFormats,
        rules: [{ required: true }]
      };
    }
  },
  eventControl: {
    toDutyFormField(dutyPayload, config_type, dutyType, sub_config_type) {
      let payloadName = '',
        valueWhenEnabled = 'ON';
      switch (config_type) {
        case 'HarshAcceleration':
          payloadName = 'Harsh-Acceleration';
          break;
        case 'HardBraking':
          payloadName = 'Harsh-Braking';
          break;
        case 'Cornering':
          payloadName = 'Cornering';
          break;
        case 'Speeding':
          if (sub_config_type === 'PostedSpeed') {
            payloadName = 'Traffic-Speed-Violated';
          } else if (sub_config_type === 'MaximumSpeed') {
            payloadName = 'MaxSpeedExceeded';
          } else {
            payloadName = 'Traffic-Speed-Violated';
          }
          break;
        case 'MaxSpeed':
          payloadName = 'MaxSpeedExceeded';
          break;
        case 'LaneDrift':
          payloadName = 'Lane-Drift-Found';
          valueWhenEnabled = 'REQUEST';
          break;
        case 'Tailgating':
          payloadName = 'Tail-Gating-Detected';
          break;
        case 'StopSignViolation':
          payloadName = 'Traffic-STOP-Sign-Violated';
          break;
        case 'DistractedDriving':
          payloadName = 'Distracted-Driving';
          break;
        case 'CellphoneDistraction':
          payloadName = 'Cellphone-Distracted-Driving';
          break;
        case 'DrowsyDriving':
          payloadName = 'Drowsy-Driving-Detected';
          break;
        case 'LizardEyeDistraction':
          payloadName = 'Lizard-Eye-Distracted-Driving';
          break;
        case 'ForwardCollisionWarning':
          payloadName = 'Forward-Collision-Warning';
          break;
        default:
          break;
      }
      const value = dutyPayload?.eventControl?.[payloadName]?.['control'];
      return {
        name: ['eventControl', payloadName, 'control'],
        value: value === 'TN360_ON' ? 'ON' : value,
        rules: [{ required: true }],
        isExtra: true,
        order: 1,
        selection: SELECTIONS.eventControl,
        onEventSwitch: (checked, setFieldsValue, formValues) => {
          if (checked) {
            //set value to default
            setFieldsValue(
              set(formValues, [dutyType, 'eventControl', payloadName, 'control'], valueWhenEnabled)
            );
          } else {
            //set value to 'DISABLED'
            setFieldsValue(
              set(formValues, [dutyType, 'eventControl', payloadName, 'control'], 'DISABLED')
            );
          }
        },
        isDisabled: value => value === 'DISABLED'
      };
    }
  },
  enabled: {
    toDutyFormField(dutyPayload, config_type, dutyType, sub_config_type) {
      let payloadName = '',
        extras = {};
      const getSwitchWithSensors = sensorFields => ({
        sensorFields,
        getChecked(getFieldValue) {
          return !sensorFields.every(
            sensorField => !getFieldValue([dutyType, ...sensorField.name])
          );
        },
        onSwitch(checked, setFieldsValue, formValues) {
          //Trigger sensor-fields' value change
          const updateValues = sensorFields.reduce(
            (a, sensorField) => set(a, sensorField.name, checked),
            formValues?.[dutyType]
          );
          setFieldsValue({ [dutyType]: updateValues });
          //Trigger sensor-fields' onSensorSwitch
          sensorFields.forEach(sensorField => {
            if (sensorField?.onSensorSwitch) {
              sensorField.onSensorSwitch(checked, setFieldsValue, formValues);
            }
          });
        }
      });
      switch (config_type) {
        case 'HarshAcceleration':
          payloadName = 'harshAccelerationEnabled';
          break;
        case 'HardBraking':
          payloadName = 'hardBrakingEnabled';
          break;
        case 'Cornering':
          payloadName = 'corneringEnabled';
          break;
        case 'Speeding':
          payloadName = 'speedingEnabled';
          //Top-level's speeding swith itself doesn't contain any config data, but triggers sub-configs' swith(on/off) which contains config data(named sensorFields)
          extras = {};
          break;
        case 'LaneDrift':
          payloadName = 'laneDriftEnabled'; //false => laneDepartureEnabled =false;
          break;
        case 'Tailgating':
          payloadName = 'tailgatingEnabled';
          break;
        case 'StopSignViolation':
          payloadName = 'stopSignEnabled';
          break;
        case 'MaxSpeed':
          payloadName = 'maxSpeedEnabled';
          break;
        case 'DistractedDriving':
          payloadName = 'distractedDrivingEnabled';
          break;
        case 'CellphoneDistraction':
          payloadName = 'enableCellphoneDistraction';
          break;
        case 'DrowsyDriving':
          payloadName = 'drowsyDrivingEnabled';
          break;
        case 'LizardEyeDistraction':
          payloadName = 'lizardEyeDistractionEnabled';
          break;
        case 'ForwardCollisionWarning':
          payloadName = 'forwardCollisionWarningEnabled';
          break;
        default:
          break;
      }
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isSwitch: true,
        ...extras
      };
    }
  },
  harshAccelerationThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'harshAccelerationThreshold';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isExtra: true,
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.thresehold,
        rules: [{ required: true }],
        isDisabled: (value, eventEnabled) => !eventEnabled
      };
    }
  },
  corneringThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'corneringThreshold';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isExtra: true,
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.thresehold,
        rules: [{ required: true }],
        isDisabled: (value, eventEnabled) => !eventEnabled
      };
    }
  },
  hardBrakingThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'hardBrakingThreshold';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isExtra: true,
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.thresehold,
        rules: [{ required: true }],
        isDisabled: (value, eventEnabled) => !eventEnabled
      };
    }
  },
  speedingAllowance: {
    toDutyFormField(dutyPayload, config_type, dutyType, sub_config_type) {
      const payloadName = 'speedingAllowance';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isExtra: true,
        withSlotAfter: false,
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.allowance,
        rules: [{ required: true }],
        isDisabled: (value, eventEnabled) => !eventEnabled
      };
    }
  },
  minimumPostedSpeedEnforced: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'minimumPostedSpeedEnforced';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.MinSpeedEnforced(Board Value)')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min: 0, type: 'number' },
          { max: 50, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 0, to: 50 })
        }
      };
    }
  },
  speedUpperLimit: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'speedUpperLimit',
        min_in_mile = 0,
        max_in_mile = 150;
      return {
        label: {
          getText: (t, localization) =>
            `${t('CompanyConfig.IQCamera.MaxSpeedLimit')} (${mphFormField.getUnit(localization)})`
        },
        name: [payloadName],
        value: localization =>
          mphFormField.getFieldvalue({ value_in_mile: dutyPayload?.[payloadName], localization }),
        fieldType: Form_Field_Type.InputNumber,
        rules: mphFormField.getRules({ min_in_mile, max_in_mile }),
        help: mphFormField.getHelp({ min_in_mile, max_in_mile }),
        toPayloadValue: mphFormField.toPayloadValue
      };
    }
  },
  speedingEnabled: {
    belongs_to_sub_config_type: 'PostedSpeed',
    toDutyFormField(dutyPayload, config_type, dutyType, sub_config_type) {
      const payloadName = 'speedingEnabled';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isSwitch: true,
        onSensorSwitch: (checked, setFieldsValue, formValues) => {
          const eventControl = CONFIG_FIELDS.eventControl.toDutyFormField(
            dutyPayload,
            config_type,
            dutyType,
            sub_config_type || 'PostedSpeed'
          );
          if (eventControl) {
            eventControl.onEventSwitch(checked, setFieldsValue, formValues);
          }
        }
      };
    }
  },
  maxSpeedEnabled: {
    belongs_to_sub_config_type: 'MaximumSpeed',
    toDutyFormField(dutyPayload, config_type, dutyType, sub_config_type) {
      const payloadName = 'maxSpeedEnabled';
      return {
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        isSwitch: true,
        onSensorSwitch: (checked, setFieldsValue, formValues) => {
          const eventControl = CONFIG_FIELDS.eventControl.toDutyFormField(
            dutyPayload,
            config_type,
            dutyType,
            sub_config_type || 'MaximumSpeed'
          );
          if (eventControl) {
            eventControl.onEventSwitch(checked, setFieldsValue, formValues);
          }
        }
      };
    }
  },
  tailgatingSensitivity: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'tailgatingSensitivity';
      return {
        label: {
          getText: t =>
            `${t('CompanyConfig.IQCamera.Sensitivity')} (${t(
              'CompanyConfig.IQCamera.ToBeDeprecated'
            )})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 1, type: 'number' }, { max: 5, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 1, to: 5 })
        }
      };
    }
  },
  TTCThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `${lowerCaseFirstLetter(config_type)}TTCThreshold`;
      return {
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.TTC Threshold')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 0.25, type: 'number' }, { max: 3, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 0.25, to: 3 })
        }
      };
    }
  },
  tailgatingTimeThreshold: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'tailgatingTimeThreshold';
      return {
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.Duration Threshold')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 10, type: 'number' }, { max: 30, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 10, to: 30 })
        }
      };
    }
  },
  yawDistractionSpeedThresholdMph: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'yawDistractionSpeedThresholdMph',
        min_in_mile = 0,
        max_in_mile = 100;
      return {
        compatible: true,
        label: {
          getText: (t, localization) =>
            `${t('CompanyConfig.IQCamera.YawDistractionThreshold')} (${mphFormField.getUnit(
              localization
            )})`
        },
        name: [payloadName],
        value: localization =>
          mphFormField.getFieldvalue({ value_in_mile: dutyPayload?.[payloadName], localization }),
        fieldType: Form_Field_Type.InputNumber,
        rules: mphFormField.getRules({ min_in_mile, max_in_mile }),
        help: mphFormField.getHelp({ min_in_mile, max_in_mile }),
        toPayloadValue: mphFormField.toPayloadValue
      };
    }
  },
  minDistractionDurationShortPitch: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'minDistractionDurationShortPitch';
      return {
        compatible: true,
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.MinShortPitchDuration')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 3, type: 'number' }, { max: 10, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 3, to: 10 })
        }
      };
    }
  },
  minYawDistractionDurationSeconds: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'minYawDistractionDurationSeconds';
      return {
        compatible: true,
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.MinYawDistraction')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 3, type: 'number' }, { max: 15, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 3, to: 15 })
        }
      };
    }
  },
  pitchDistractionSpeedThresholdMph: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'pitchDistractionSpeedThresholdMph',
        min_in_mile = 0,
        max_in_mile = 10;
      return {
        compatible: true,
        label: {
          getText: (t, localization) =>
            `${t('CompanyConfig.IQCamera.PitchDistractionThreshold')} (${mphFormField.getUnit(
              localization
            )})`
        },
        name: [payloadName],
        value: localization =>
          mphFormField.getFieldvalue({ value_in_mile: dutyPayload?.[payloadName], localization }),
        fieldType: Form_Field_Type.InputNumber,
        rules: mphFormField.getRules({ min_in_mile, max_in_mile }),
        help: mphFormField.getHelp({ min_in_mile, max_in_mile }),
        toPayloadValue: mphFormField.toPayloadValue
      };
    }
  },
  minimumPitchDistractionDurationSeconds: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'minimumPitchDistractionDurationSeconds';
      return {
        compatible: true,
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.MinPitchDistraction')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min: 2, type: 'number' }, { max: 15, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 2, to: 15 })
        }
      };
    }
  },
  minEyeCloseDuration: {
    toDutyFormField(dutyPayload, config_type) {
      let configType = config_type,
        min = 1,
        max = 10;
      switch (config_type) {
        case 'DrowsyDriving':
          configType = 'Drowsiness';
          break;
        case 'LizardEyeDistraction':
          configType = 'LizardEye';
          min = 3;
          max = 20;
          break;
        default:
          break;
      }
      const payloadName = `minEyeCloseDurationFor${configType}`;
      return {
        compatible: true,
        label: {
          getText: t => `${t('CompanyConfig.IQCamera.MinEyeClosureDuration')} (${t('Common.Sec')})`
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [{ required: true }, { min, type: 'number' }, { max, type: 'number' }],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: min, to: max })
        }
      };
    }
  },
  triggerMinimumSpeedMph: {
    toDutyFormField(dutyPayload, config_type) {
      let payloadName = `triggerMinimumSpeed${config_type}Mph`,
        trans = t =>
          `${t(`CompanyConfig.IQCamera.${config_type}`)} ${t('CompanyConfig.IQCamera.MinSpeed')}`,
        min_in_mile = 0,
        max_in_mile = 20;
      switch (config_type) {
        case 'DrowsyDriving':
          payloadName = 'triggerMinimumSpeedDrowsinessMph';
          break;
        case 'LizardEyeDistraction':
          trans = t => t('CompanyConfig.IQCamera.MinSpeed');
          break;
        case 'LaneDrift':
        case 'Tailgating':
          min_in_mile = 30;
          max_in_mile = 50;
          break;
        case 'ForwardCollisionWarning':
          min_in_mile = 30;
          max_in_mile = 50;
          trans = t => t('CompanyConfig.IQCamera.MinSpeed');
          break;
        default:
          break;
      }
      return {
        compatible: true,
        label: {
          getText: (t, localization) => `${trans(t)} (${mphFormField.getUnit(localization)})`
        },
        name: [payloadName],
        value: localization =>
          mphFormField.getFieldvalue({ value_in_mile: dutyPayload?.[payloadName], localization }),
        fieldType: Form_Field_Type.InputNumber,
        rules: mphFormField.getRules({ min_in_mile, max_in_mile }),
        help: mphFormField.getHelp({ min_in_mile, max_in_mile }),
        toPayloadValue: mphFormField.toPayloadValue
      };
    }
  },
  eventMediaType: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = `eventMediaType${config_type}`;
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Media Type')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.MediaTypes,
        rules: [{ required: true }]
      };
    }
  },
  defaultLanguageCode: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'defaultLanguageCode';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Default Language (Notifications)')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.Langs,
        rules: [{ required: true }]
      };
    }
  },
  deviceAudioVolume: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'deviceAudioVolume';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Device Volume')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.InputNumber,
        rules: [
          { required: true },
          { min: 0, type: 'number' },
          { max: 15, type: 'number' },
          { pattern: /^\d+$/ }
        ],
        help: {
          getText: t => t('CompanyConfig.IQCamera.Validation.range', { from: 0, to: 15 })
        }
      };
    }
  },
  enableDriverCamera: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'enableDriverCamera';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Driver Camera')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Switch
      };
    }
  },
  defaultRoadSignsType: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'defaultRoadSignsType';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Traffic Sign Type')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.TrafficSignTypes,
        rules: [{ required: true }]
      };
    }
  },
  defaultRoadSignUnits: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'defaultRoadSignUnits';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Traffic Sign Unit')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.TrafficSignUnits,
        rules: [{ required: true }]
      };
    }
  },
  defaultDriverSeatSide: {
    toDutyFormField(dutyPayload, config_type) {
      const payloadName = 'defaultDriverSeatSide';
      return {
        label: {
          getText: t => t('CompanyConfig.IQCamera.Driver Side in Cabin')
        },
        name: [payloadName],
        value: dutyPayload?.[payloadName],
        fieldType: Form_Field_Type.Select,
        selection: SELECTIONS.DriverSignInCabin,
        rules: [{ required: true }]
      };
    }
  }
};

const COMMON_FIELDS = [
  CONFIG_FIELDS.postEventVideoDuration,
  CONFIG_FIELDS.eventVideoQuality,
  CONFIG_FIELDS.eventVideoDriverCameraQuality,
  CONFIG_FIELDS.eventVideoResolution,
  CONFIG_FIELDS.eventVideoDriverCameraResolution,
  CONFIG_FIELDS.EventVideoType,
  CONFIG_FIELDS.enabled
];

const CONFIG_TYPES = {
  HarshAcceleration: {
    id: 'HarshAcceleration',
    header: {
      getText: t => t('Scorecard.HarshAcceleration')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventControl,
      CONFIG_FIELDS.harshAccelerationThreshold
    ]
  },
  HardBraking: {
    id: 'HardBraking',
    header: {
      getText: t => t('Scorecard.HarshBraking')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventControl,
      CONFIG_FIELDS.hardBrakingThreshold
    ]
  },
  Cornering: {
    id: 'Cornering',
    header: {
      getText: t => t('Scorecard.HarshCornering')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventControl,
      CONFIG_FIELDS.corneringThreshold
    ]
  },
  MaxSpeed: {
    id: 'MaxSpeed',
    header: {
      getText: t => t('CompanyConfig.IQCamera.MaximumSpeed')
    },
    compatible: true,
    fields: [
      ...COMMON_FIELDS,
      CONFIG_FIELDS.speedUpperLimit,
      CONFIG_FIELDS.eventMediaType,
      CONFIG_FIELDS.eventControl
    ]
  },
  Speeding: {
    id: 'Speeding',
    header: {
      getText: t => t('CompanyConfig.IQCamera.SpeedLimitViolation')
    },
    fields: () => [
      ...COMMON_FIELDS,
      CONFIG_FIELDS.speedingAllowance,
      CONFIG_FIELDS.eventMediaType,
      CONFIG_FIELDS.minimumPostedSpeedEnforced,
      CONFIG_FIELDS.eventControl
    ]
  },
  LaneDrift: {
    id: 'LaneDrift',
    header: {
      getText: t => t('CompanyConfig.IQCamera.LaneDrift')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.triggerMinimumSpeedMph,
      CONFIG_FIELDS.eventControl
    ]
  },
  Tailgating: {
    id: 'Tailgating',
    header: {
      getText: t => t('CompanyConfig.IQCamera.Tailgating')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventControl,
      CONFIG_FIELDS.tailgatingSensitivity,
      CONFIG_FIELDS.tailgatingTimeThreshold,
      CONFIG_FIELDS.TTCThreshold,
      CONFIG_FIELDS.triggerMinimumSpeedMph
    ]
  },
  StopSignViolation: {
    id: 'StopSignViolation',
    header: {
      getText: t => t('CompanyConfig.IQCamera.StopSignViolation')
    },
    fields: [...COMMON_FIELDS, CONFIG_FIELDS.eventControl]
  },
  DistractedDriving: {
    id: 'DistractedDriving',
    header: {
      getText: t => t('CompanyConfig.IQCamera.DistractedDriving')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      CONFIG_FIELDS.postEventVideoDuration,
      CONFIG_FIELDS.eventVideoQuality,
      CONFIG_FIELDS.eventVideoDriverCameraQuality,
      CONFIG_FIELDS.yawDistractionSpeedThresholdMph,
      CONFIG_FIELDS.minYawDistractionDurationSeconds,
      CONFIG_FIELDS.pitchDistractionSpeedThresholdMph,
      CONFIG_FIELDS.minimumPitchDistractionDurationSeconds,
      CONFIG_FIELDS.minDistractionDurationShortPitch,
      CONFIG_FIELDS.eventVideoResolution,
      CONFIG_FIELDS.eventVideoDriverCameraResolution,
      CONFIG_FIELDS.EventVideoType,
      CONFIG_FIELDS.enabled,
      CONFIG_FIELDS.eventControl
    ]
  },
  DrowsyDriving: {
    id: 'DrowsyDriving',
    header: {
      getText: t => t('CompanyConfig.IQCamera.DrowsyDriving')
    },
    compatible: true,
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.triggerMinimumSpeedMph,
      CONFIG_FIELDS.minEyeCloseDuration,
      CONFIG_FIELDS.eventControl
    ]
  },
  CellphoneDistraction: {
    id: 'CellphoneDistraction',
    header: {
      getText: t => t('CompanyConfig.IQCamera.CellphoneDistraction')
    },
    compatible: true,
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.triggerMinimumSpeedMph,
      CONFIG_FIELDS.eventControl
    ]
  },
  LizardEyeDistraction: {
    id: 'LizardEyeDistraction',
    header: {
      getText: t => t('CompanyConfig.IQCamera.LizardEyeDistraction')
    },
    compatible: true,
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.triggerMinimumSpeedMph,
      CONFIG_FIELDS.minEyeCloseDuration,
      CONFIG_FIELDS.eventControl
    ]
  },
  ForwardCollisionWarning: {
    id: 'ForwardCollisionWarning',
    header: {
      getText: t => t('CompanyConfig.IQCamera.ForwardCollisionWarning')
    },
    fields: [
      CONFIG_FIELDS.preEventVideoDuration,
      ...COMMON_FIELDS,
      CONFIG_FIELDS.eventMediaType,
      CONFIG_FIELDS.TTCThreshold,
      CONFIG_FIELDS.triggerMinimumSpeedMph,
      CONFIG_FIELDS.eventControl
    ]
  },
  Device: {
    id: 'Device',
    header: {
      getText: (t, localization, can = () => false, plain = false) => {
        return plain ? (
          t('CompanyConfig.IQCamera.DeviceSettings')
        ) : (
          <Space>
            {t('CompanyConfig.IQCamera.DeviceSettings')}
            <Tooltip title={t('CompanyConfig.IQCamera.DeviceSettingsTooltip')}>
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        );
      }
    },
    isAdvanced: true,
    fields: [
      CONFIG_FIELDS.defaultLanguageCode,
      CONFIG_FIELDS.deviceAudioVolume,
      CONFIG_FIELDS.enableDriverCamera
    ]
  },
  Location: {
    id: 'Location',
    header: {
      getText: t => t('Entity.Location')
    },
    isAdvanced: true,
    fields: [
      CONFIG_FIELDS.defaultRoadSignsType,
      CONFIG_FIELDS.defaultRoadSignUnits,
      CONFIG_FIELDS.defaultDriverSeatSide
    ]
  }
};

const SELECTIONS = {
  thresehold: [
    {
      id: '4.5',
      value: 4.5,
      getText(t, localization, can = () => false) {
        return `${t(
          'CompanyConfig.IQCamera.tolerance.light'
        )} ${localization.convertAccelerationFromUnit(this.value)} ${
          localization.formats.acceleration.unit
        } (${this.value.toFixed(1)} mph/s)`;
      }
    },
    {
      id: '6',
      value: 6,
      getText(t, localization, can = () => false) {
        return `${t(
          'CompanyConfig.IQCamera.tolerance.medium'
        )} ${localization.convertAccelerationFromUnit(this.value)} ${
          localization.formats.acceleration.unit
        } (${this.value.toFixed(1)} mph/s)`;
      }
    },
    {
      id: '8',
      value: 8,
      getText(t, localization, can = () => false) {
        return `${t(
          'CompanyConfig.IQCamera.tolerance.severe'
        )} ${localization.convertAccelerationFromUnit(this.value)} ${
          localization.formats.acceleration.unit
        } (${this.value.toFixed(1)} mph/s)`;
      }
    }
  ],
  allowance: [
    {
      id: '5',
      value: 5,
      getText(t, localization, can = () => false) {
        return `${t('CompanyConfig.IQCamera.tolerance.light')} ${mphFormField.getFieldLabel({
          value_in_mile: this.value,
          localization
        })} ${t('CompanyConfig.IQCamera.overlimit')}`;
      }
    },
    {
      id: '10',
      value: 10,
      getText(t, localization, can = () => false) {
        return `${t('CompanyConfig.IQCamera.tolerance.medium')} ${mphFormField.getFieldLabel({
          value_in_mile: this.value,
          localization
        })} ${t('CompanyConfig.IQCamera.overlimit')}`;
      }
    },
    {
      id: '15',
      value: 15,
      getText(t, localization, can = () => false) {
        return `${t('CompanyConfig.IQCamera.tolerance.severe')} ${mphFormField.getFieldLabel({
          value_in_mile: this.value,
          localization
        })} ${t('CompanyConfig.IQCamera.overlimit')}`;
      }
    }
  ],
  VideoResolutions: [
    { id: '320x180', label: '320x180' },
    { id: '640x360', label: '640x360' },
    { id: '1280x720', label: '1280x720' },
    { id: '1920x1080', label: '1920x1080' }
  ],
  VideoDriverCameraResolution: [
    { id: '320x180', label: '320x180' },
    { id: '640x360', label: '640x360' },
    { id: '1280x720', label: '1280x720' }
  ],
  VideoFormats: [
    { id: 'road', getText: t => t('Preferences.MapSettings.Map.Type.Road') },
    { id: 'driver', getText: t => t('Tracking.Columns.Driver') },
    {
      id: 'sideBySide',
      getText: t => t('CompanyConfig.IQCamera.Video.sideBySide')
    },
    {
      id: 'pictureInPicture',
      getText: t => t('CompanyConfig.IQCamera.Video.pictureInPicture')
    },
    {
      id: 'separate',
      getText: t =>
        `${t('Preferences.MapSettings.Map.Type.Road')} + ${t('Tracking.Columns.Driver')}`
    }
  ],
  MediaTypes: [
    { id: 'video', getText: t => t('Entity.Video') },
    { id: 'image', getText: t => t('Entity.Image') }
  ],
  Langs: [
    { id: 'en-US', getText: t => t('Preferences.ChangeLanguage.en-US') },
    { id: 'en-GB', getText: t => t('Preferences.ChangeLanguage.en-GB') },
    { id: 'en-AU', getText: t => t('Preferences.ChangeLanguage.en-AU') },
    { id: 'en-CA', getText: t => t('Preferences.ChangeLanguage.en-CA') },
    { id: 'es-ES', getText: t => t('Preferences.ChangeLanguage.Spanish') },
    { id: 'fr-FR', getText: t => t('Preferences.ChangeLanguage.fr-FR') },
    { id: 'fr-CA', getText: t => t('Preferences.ChangeLanguage.fr-CA') },
    { id: 'pt-PT', getText: t => t('Preferences.ChangeLanguage.pt-PT') },
    { id: 'pt-BR', getText: t => t('Preferences.ChangeLanguage.pt-BR') }
  ],
  TrafficSignTypes: [
    { id: 'US', getText: t => t('Countries.codes.us') },
    { id: 'Canada', getText: t => t('Countries.codes.ca') },
    { id: 'Australia', getText: t => t('Countries.codes.au') },
    { id: 'Israel', getText: t => t('Countries.Israel') },
    { id: 'Vienna', getText: t => t('CompanyConfig.IQCamera.Vienna') },
    { id: 'UK', getText: t => t('Countries.codes.gb') },
    { id: 'MX', getText: t => t('Countries.codes.mx') },
    { id: 'South Africa', getText: t => t('Countries.South Africa') }
  ],
  TrafficSignUnits: [
    { id: 'mph', label: 'mph' },
    { id: 'kmph', label: 'kmph' }
  ],
  DriverSignInCabin: [
    { id: 'right', getText: t => t('Alerts.GPIO.right') },
    { id: 'left', getText: t => t('Alerts.GPIO.left') }
  ],
  eventControl: [
    {
      id: 'ON',
      value: 'ON',
      isDefault: true,
      getText: t => t('CompanyConfig.IQCamera.UploadMediaAutomatically')
    },
    {
      id: 'REQUEST',
      value: 'REQUEST',
      getText: t => t('CompanyConfig.IQCamera.UploadMediaOnRequest')
    },
    { id: 'OFF', value: 'OFF', getText: t => t('CompanyConfig.IQCamera.DisableMediaUpload') },
    {
      id: 'DISABLED',
      value: 'DISABLED',
      hidden: true,
      getText: t => t('GeofencesFeature.View.Disabled')
    }
  ]
};

export const PERMISSION = {
  FULL: {
    isFieldAccessable: (field, isAdvancedField) => true,
    isSubConfigsAccessable: subConfigs => true
  },
  TOP_LEVEL_ONLY: {
    isFieldAccessable: (field, isAdvancedField) =>
      isAdvancedField ||
      [
        CONFIG_FIELDS.harshAccelerationThreshold,
        CONFIG_FIELDS.hardBrakingThreshold,
        CONFIG_FIELDS.corneringThreshold,
        CONFIG_FIELDS.speedingAllowance,
        CONFIG_FIELDS.eventControl,
        CONFIG_FIELDS.enabled
      ].some(accessableF => accessableF === field),
    isSubConfigsAccessable: subConfigs => true
  }
};

export const EDIT_LEVEL = {
  NONE: 'NONE',
  FULL: 'FULL',
  PARTIAL: 'EventToglleAndOthers'
};

const toDutyFormData = (
  dutyPayload,
  dutyType,
  {
    isFieldAccessable = (field, isAdvancedField) => true,
    isSubConfigsAccessable = subConfigs => true
  }
) => {
  const getConfigFields = config =>
    typeof config.fields === 'function' ? config.fields() : config.fields;
  const getSubConfig = config =>
    typeof config.subConfigs === 'function' ? config.subConfigs() : config.subConfigs;
  return Object.values(CONFIG_TYPES).reduce((a, config_type) => {
    const config = {
      ...config_type,
      fields: getConfigFields(config_type)
        .filter(f => isFieldAccessable(f, config_type.isAdvanced))
        .map(field => ({
          ...field.toDutyFormField(dutyPayload, config_type.id, dutyType, null),
          config_type: config_type.id
        })),
      subConfigs:
        getSubConfig(config_type) && isSubConfigsAccessable(getSubConfig(config_type))
          ? Object.values(getSubConfig(config_type)).reduce((a, sub_config_type) => {
              const subConfig = {
                ...sub_config_type,
                fields: getConfigFields(sub_config_type)
                  .filter(f => isFieldAccessable(f, config_type.isAdvanced))
                  .map(field => ({
                    ...field.toDutyFormField(
                      dutyPayload,
                      config_type.id,
                      dutyType,
                      sub_config_type.id
                    ),
                    config_type: config_type.id
                  }))
              };
              return getConfigFields(subConfig).length
                ? {
                    ...a,
                    [sub_config_type.id]: subConfig
                  }
                : a;
            }, {})
          : {}
    };
    return !getConfigFields(config).length && !Object.keys(getSubConfig(config)).length
      ? a
      : {
          ...a,
          [config_type.id]: config
        };
  }, {});
};

export const toIQCameraFormData = (responseBody, permission = PERMISSION.FULL) => {
  const configPayload = responseBody?.configuration || {};
  try {
    return Object.keys(DUTY_TYPE).reduce((a, dutyType) => {
      return {
        ...a,
        [dutyType]: toDutyFormData(configPayload?.[dutyType], dutyType, permission)
      };
    }, {});
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const toIQCameraConfigPayload = (flattenedChangedConfig, configFormData) => {
  const nonAdvisoryEventControlKeys = [
    'MaxSpeedExceeded',
    'Tail-Gating-Detected',
    'Traffic-Speed-Violated',
    'Traffic-STOP-Sign-Violated',
    'Distracted-Driving',
    'Cellphone-Distracted-Driving',
    'Drowsy-Driving-Detected',
    'Lizard-Eye-Distracted-Driving'
  ];
  //eventControl should always be included no matter it's changed or not
  const dutyEventControls = Object.keys(configFormData).reduce((a, dutyKey) => {
    const eventControl = merge({}, get(configFormData, [dutyKey, 'eventControl']));
    nonAdvisoryEventControlKeys.forEach(ck => {
      if (eventControl[ck] && eventControl[ck].control === 'ON') {
        eventControl[ck].control = 'TN360_ON';
      }
    });
    return {
      ...a,
      [dutyKey]: { eventControl }
    };
  }, {});

  const transformChangedValue = (flattenedChangedKey, changedValue) => {
    if (changedValue === 'ON') {
      const isNonAdvisoryEventControl = /^(heavy|medium|light).eventControl.(MaxSpeedExceeded|Tail-Gating-Detected|Traffic-Speed-Violated|Traffic-STOP-Sign-Violated|Distracted-Driving|Cellphone-Distracted-Driving|Drowsy-Driving-Detected|Lizard-Eye-Distracted-Driving).control$/.test(
        flattenedChangedKey
      );
      return isNonAdvisoryEventControl ? 'TN360_ON' : changedValue;
    }
    return changedValue;
  };

  const changedConfigs = Object.keys(flattenedChangedConfig || {}).reduce(
    (a, key) => set(a, key, transformChangedValue(key, flattenedChangedConfig[key])),
    {}
  );
  return merge({}, dutyEventControls, changedConfigs);
};

const flatObj = (obj, prefix = '') =>
  Object.keys(obj || {}).reduce((a, key) => {
    if (isObject(obj[key])) {
      return {
        ...a,
        ...flatObj(obj[key], `${prefix}${key}.`)
      };
    }
    return {
      ...a,
      [`${prefix}${key}`]: obj[key]
    };
  }, {});

export const getDifferentValues = (values, olderValues) => {
  return pickBy(flatObj(values), (value, key) => value !== flatObj(olderValues)[key]);
};

export const hasDifferentValues = (values, olderValues) => {
  return some(flatObj(values), (value, key) => value !== flatObj(olderValues)[key]);
};

export const isStandaloneDevice = device => !device?.vehicleInfo || !device.vehicleInfo.id;

export const convertAuditPayloadToDisplay = ({ payloadName, t, localization, payloadValue }) => {
  let displayName = payloadName,
    displayValue = payloadValue;
  switch (payloadName) {
    case 'Harsh-Acceleration':
      displayName = t('Scorecard.HarshAcceleration');
      break;
    case 'Cornering':
      displayName = t('Scorecard.HarshCornering');
      break;
    case 'Harsh-Braking':
      displayName = t('Scorecard.HarshBraking');
      break;
    case 'MaxSpeedExceeded':
      displayName = t('CompanyConfig.IQCamera.MaximumSpeed');
      break;
    case 'Traffic-Speed-Violated':
      displayName = t('CompanyConfig.IQCamera.SpeedLimitViolation');
      break;
    case 'Lane-Drift-Found':
      displayName = t('CompanyConfig.IQCamera.LaneDrift');
      break;
    case 'Tail-Gating-Detected':
      displayName = t('CompanyConfig.IQCamera.Tailgating');
      break;
    case 'Traffic-STOP-Sign-Violated':
      displayName = t('CompanyConfig.IQCamera.StopSignViolation');
      break;
    case 'Distracted-Driving':
      displayName = t('CompanyConfig.IQCamera.DistractedDriving');
      break;
    case 'Drowsy-Driving-Detected':
      displayName = t('CompanyConfig.IQCamera.DrowsyDriving');
      break;
    case 'Cellphone-Distracted-Driving':
      displayName = t('CompanyConfig.IQCamera.CellphoneDistraction');
      break;
    case 'Lizard-Eye-Distracted-Driving':
      displayName = t('CompanyConfig.IQCamera.LizardEyeDistraction');
      break;
    case 'Forward-Collision-Warning':
      displayName = t('CompanyConfig.IQCamera.ForwardCollisionWarning');
      break;
    case 'enableDriverCamera':
      displayName = t('CompanyConfig.IQCamera.Driver Camera');
      break;
    case 'Texting-Distracted-Driving':
      displayName = t('Devices.View.CameraAudioAlert.DRIVER_TEXTING_DISTRACTION');
      break;
    case 'Smoking-Distracted-Driving':
      displayName = t('Devices.View.CameraAudioAlert.DRIVER_SMOKING_DISTRACTION');
      break;
    case 'defaultRoadSignsType':
      displayName = t('CompanyConfig.IQCamera.Traffic Sign Type');
      break;
    case 'lastUpdated':
      displayName = t('Footage.LastUpdated');
      displayValue = format(new Date(payloadValue), localization.formats.time.formats.dby_imsp);
      break;
    case 'control':
      displayName = t('CompanyConfig.IQCamera.MediaControls');
      displayValue =
        SELECTIONS.eventControl.find(opt => opt.value === payloadValue)?.getText(t) || payloadValue;
      break;
    case 'videoResolution':
      displayName = t('CompanyConfig.IQCamera.Video Resolution');
      break;
    case 'driverCameraVideoResolution':
      displayName = `${t('CompanyConfig.IQCamera.Video Resolution')} (${t(
        'CompanyConfig.IQCamera.Driver Camera'
      )})`;
      break;
    case 'preEventVideoDuration':
      displayName = `${t('CompanyConfig.IQCamera.Pre-event')} ${t(
        'CompanyConfig.IQCamera.Video Duration'
      )} (${t('Common.Sec')})`;
      break;
    case 'videoQuality':
      displayName = t('CompanyConfig.IQCamera.Video Quality');
      break;
    case 'driverCameraVideoQuality':
      displayName = `${t('CompanyConfig.IQCamera.Video Quality')} (${t(
        'CompanyConfig.IQCamera.Driver Camera'
      )})`;
      break;
    case 'postEventVideoDuration':
      displayName = `${t('CompanyConfig.IQCamera.Post-event')} ${t(
        'CompanyConfig.IQCamera.Video Duration'
      )} (${t('Common.Sec')})`;
      break;
    case 'dvrVideoType':
      displayName = t('CompanyConfig.IQCamera.Video Format');
      displayValue =
        SELECTIONS.VideoFormats.find(opt => opt.id === payloadValue)?.getText(t) || payloadValue;
      break;
    case 'pitchDistractionSpeedThresholdMph':
      displayName = `${t(
        'CompanyConfig.IQCamera.PitchDistractionThreshold'
      )} (${mphFormField.getUnit(localization)})`;
      break;
    case 'yawDistractionSpeedThresholdMph':
      displayName = `${t('CompanyConfig.IQCamera.YawDistractionThreshold')} (${mphFormField.getUnit(
        localization
      )})`;
      break;
    case 'harshAccelerationThreshold':
    case 'hardBrakingThreshold':
    case 'corneringThreshold':
      displayName = t('GeofencesFeature.Tolerance');
      displayValue =
        SELECTIONS.thresehold
          .find(opt => opt.value === Number(payloadValue))
          ?.getText(t, localization, () => true) || payloadValue;
      break;
    case 'forwardCollisionWarningTTCThreshold':
      displayName = `${t('CompanyConfig.IQCamera.ForwardCollisionWarning')} ${t(
        'CompanyConfig.IQCamera.TTC Threshold'
      )} (${t('Common.Sec')})`;
    case 'tailgatingTTCThreshold':
      displayName = `${t('CompanyConfig.IQCamera.Tailgating')} ${t(
        'CompanyConfig.IQCamera.TTC Threshold'
      )} (${t('Common.Sec')})`;
      break;
    default:
      break;
  }
  return { displayName, displayValue };
};
