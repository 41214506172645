export const Colors = {
  Red: 'Red',
  Yellow: 'Yellow',
  Green: 'Green',
  Blue: 'Blue'
};

export const EmptyRange = {
  color: '',
  value: 0,
  label: ''
};
