import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Alert, Col, Modal, Radio, Row } from 'antd';
import * as Yup from 'yup';
import { Form, Formik, useField } from 'formik';
import moment from 'moment';
import { DateRangePicker } from 'components/ant';
import { ApiClient } from 'nextgen_api';
import { useUserKey } from 'features/user/userSlice';
import { API_PATH } from 'config';
import { t_error } from 'i18nextConfig';
import { useDispatch } from 'react-redux';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useTranslation } from 'react-i18next';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useLocalization } from 'features/localization/localizationSlice';
import dayjs from 'dayjs';

const schema = Yup.object().shape({
  dateTo: Yup.date().required('Required'),
  dateFrom: Yup.date().required('Required')
});

const RangePickerFormik = ({ maxDayRange, ...props }) => {
  const dateTo = useField(`${props.name}To`);
  const dateFrom = useField(`${props.name}From`);

  const dateToValue = dateTo[1].value;
  const dateFromValue = dateFrom[1].value;

  const setDateTo = dateTo[2].setValue;
  const setDateFrom = dateFrom[2].setValue;

  const updateValues = dates => {
    const from = moment(dates[0]);
    let to = moment(dates[1]);
    if (to.isAfter(moment(from).add(maxDayRange, 'days'))) {
      to = moment(from).add(maxDayRange, 'days');
    }
    setDateFrom(from.format());
    setDateTo(to.format());
  };
  const localization = useLocalization();

  return (
    <>
      <DateRangePicker
        defaultDates={[dayjs().subtract(27, 'days'), dayjs()]}
        maxDayRange={maxDayRange}
        availableDatesRange={[0, dayjs().endOf('day')]}
        onDateRangeChanged={dates => updateValues(dates)}
        showLastMonth={false}
        format={localization.formats.date.formats.default}
        value={[dayjs(dateFromValue), dayjs(dateToValue)]}
      />
    </>
  );
};

export function EDLExportModal({ visible = false, onModalVisibleChange, onSubmit, onCancel }) {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [initFormValue] = useState({
    dateFrom: moment()
      .subtract(27, 'days')
      .format(),
    dateTo: moment().format(),
    type: 'pdf'
  });

  const handleSubmit = useCallback(
    async (values, actions) => {
      setConfirmLoading(true);
      await onSubmit(values, actions);
      setConfirmLoading(false);
    },
    [onSubmit]
  );

  const handleCancel = useCallback(() => {
    if (onModalVisibleChange) {
      onModalVisibleChange(false);
    }
    if (onCancel) {
      onCancel();
    }
  }, [onCancel, onModalVisibleChange]);

  return (
    <Formik
      initialValues={initFormValue}
      validationSchema={schema}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <Form>
            <Modal
              title={'Export'}
              open={visible}
              onOk={handleSubmit}
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
              maskClosable={false}
              okText="Download"
            >
              <div>
                <Row>
                  <Col span={24}>
                    <label htmlFor="type" className="ant-form-item-required" title="Export Format">
                      Export Format
                    </label>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '2rem' }}>
                  <Col>
                    <Radio.Group
                      options={[
                        { label: 'CSV', value: 'csv' },
                        { label: 'PDF', value: 'pdf' }
                      ]}
                      name="type"
                      optionType="button"
                      value={values.type}
                      onChange={value => setFieldValue('type', value.target.value)}
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: '2rem' }}>
                  <Col span={24}>
                    <Alert
                      style={{ width: '428px' }}
                      message={
                        values.type === 'csv' ? (
                          <span>
                            You can export up to <strong>3 years</strong> worth of driver data into
                            a CSV file.
                          </span>
                        ) : (
                          <span>
                            You can export up to <strong>28 days</strong> worth of driver data into
                            a PDF file.
                          </span>
                        )
                      }
                      type="info"
                      showIcon
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <label htmlFor="date" className="ant-form-item-required" title="date">
                      Date Range
                    </label>
                  </Col>
                </Row>
                <Row style={{ marginBottom: '2rem' }}>
                  <Col>
                    <RangePickerFormik maxDayRange={values.type === 'csv' ? 766 : 27} name="date" />
                  </Col>
                </Row>
              </div>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
}

async function edlExportAPI(params) {
  const { driverId, fromDate, toDate, format, userKey } = params;
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;

  apiClient.defaultHeaders = {
    Authorization: 'Token token="' + userKey + '"'
  };

  const apiCall = new Promise((resolve, reject) => {
    apiClient.callApi(
      `/sentinel/users/${driverId}/export?userId=${driverId}&from=${fromDate}&to=${toDate}&format=${format}`,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      'Blob',
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          console.error(err);
          if (resp.text) {
            if (resp.type === 'application/json') {
              reject(t_error(JSON.parse(resp.text)));
            } else {
              reject(resp.text);
            }
          }
          reject(err);
        } else {
          resolve(resp);
        }
      }
    );
  });

  const data = await apiCall;
  return data;
}

export function EDLExportButton({ driverId, ...props }) {
  const userKey = useUserKey();
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleShowExportModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleExport = useCallback(
    async (values, actions) => {
      const fromDate = moment(values.dateFrom)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      // Add a day as the range defaults to midnight
      const toDate = moment(values.dateTo)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      try {
        const response = await edlExportAPI({
          driverId,
          fromDate,
          toDate,
          format: values.type,
          userKey
        });
        let filename = '';
        const disposition = response.headers['content-disposition'];
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        } else {
          filename = 'file.zip';
        }

        const attachmentBlob = response.xhr.response;
        const url = window.URL.createObjectURL(attachmentBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}`);
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setShowModal(false);
      } catch (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Sentinel.DownloadPDFFailed', { error: err })
          })
        );
      }
    },
    [userKey, dispatch, t]
  );

  return (
    <>
      <Button variant="outline-secondary" onClick={handleShowExportModal} id={BUTTON_IDS.edlExport}>
        Export
      </Button>
      <EDLExportModal
        visible={showModal}
        onModalVisibleChange={setShowModal}
        onSubmit={handleExport}
      />
    </>
  );
}
