import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  useCurrentCompany,
  useIsFetchingCompany,
  useSubCompanies
} from 'features/company/companySlice';
import { Button, Col, Form, Input, Modal, Row, Select, Table } from 'antd';
import styles from './CompanyAgreementCopyModal.module.scss';
import {
  executeAddCompanyAgreementsAction,
  useAddCompanyAgreementsMutation,
  useGetCompanyAgreementsQuery
} from 'services/nextgen/ngAgreementApi';

export const CompanyAgreementCopyModal = ({ agreement, isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const subCompanies = useSubCompanies();
  const isFetchingCompany = useIsFetchingCompany();
  const [agreementCopyForm] = Form.useForm();
  const [addCompanyAgreements, { isLoading }] = useAddCompanyAgreementsMutation();
  const currentCompany = useCurrentCompany();
  const selectedWorkOrder = Form.useWatch('workOrder', agreementCopyForm);
  const { data: agreementData, isFetching: isFetchingAgreement } = useGetCompanyAgreementsQuery(
    {
      id: currentCompany?.id,
      embed: 'devices'
    },
    { skip: currentCompany?.id === undefined }
  );

  useEffect(() => {
    if (agreement) {
      agreementCopyForm.setFieldValue('workOrder', agreement.workOrder);
    }
  }, [agreement]);

  useEffect(() => {
    agreementCopyForm.setFieldValue('agreements', null);
  }, [selectedWorkOrder]);

  const footer = () => {
    return (
      <>
        <Button
          type="primary"
          loading={isLoading}
          onClick={() => {
            agreementCopyForm.submit();
          }}
          htmlType="submit"
        >
          {t('Agreement.CopyAgreementModal.CopyAgreement')}
        </Button>
        <Button onClick={onClose}>{t('Common.CancelButton')}</Button>
      </>
    );
  };

  const columns = [
    {
      title: t('Agreement.SubscriptionPack'),
      dataIndex: 'name',
      key: 'name',
      render: (e, r) => {
        return <span>{r.subscriptionPack.name}</span>;
      },
      width: '25%'
    },
    {
      title: t('Agreement.BillingCode'),
      dataIndex: 'billingCode',
      key: 'billingCode',
      render: (e, r) => {
        return <span>{r.subscriptionPack.billingCode}</span>;
      },
      width: '30%'
    },
    {
      title: t('Agreement.Description'),
      dataIndex: 'description',
      key: 'description',
      render: (e, r) => {
        return <span>{r.subscriptionPack.description}</span>;
      },
      width: '35%'
    },
    {
      title: t('Agreement.CopyAgreementModal.Quantity'),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      render: (e, r) => {
        return (
          <Form.Item
            name={'quantity_' + r.id}
            colon={false}
            className={styles.tableInput}
            initialValue={r.quantity}
            rules={[
              {
                validator: (_, value) => {
                  const num = Number(value);

                  if (!value) {
                    return Promise.reject(t('Agreement.CopyAgreementModal.QuantityRequired'));
                  }

                  if (!Number.isInteger(num)) {
                    return Promise.reject(
                      t('Agreement.CopyAgreementModal.QuantityIntegerRequired')
                    );
                  }

                  if (num < 0) {
                    return Promise.reject(
                      t('Agreement.CopyAgreementModal.QuantityMin', {
                        quantity: 0
                      })
                    );
                  }
                  if (num > r.quantity) {
                    return Promise.reject(
                      t('Agreement.CopyAgreementModal.QuantityMax', {
                        quantity: r.quantity
                      })
                    );
                  }

                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input></Input>
          </Form.Item>
        );
      },
      width: '10%'
    }
  ];

  return (
    <Modal
      title={t('Agreement.CopyAgreementModal.CopyToSubCompany')}
      open={isOpen}
      width={'80%'}
      centered={true}
      onCancel={onClose}
      destroyOnClose={true}
      footer={footer()}
      wrapClassName={styles.copyAgreementModal}
    >
      <Form
        layout="vertical"
        form={agreementCopyForm}
        name="agreementCopyForm"
        onFinish={() => {
          const selectedAgreement = agreementCopyForm.getFieldValue('agreements');
          const agreementToCopy = agreementData.find(i => i.workOrder === selectedWorkOrder);
          const agreements = agreementToCopy.agreements.filter(i =>
            selectedAgreement.includes(i.id)
          );

          const newAgreement = {
            companyId: agreementCopyForm.getFieldValue('company'),
            enabledAt: agreementToCopy.enabledAt,
            expiresAt: agreementToCopy.expiresAt,
            contractEndAt: agreementToCopy.contractEndAt,
            contractStartAt: agreementToCopy.contractStartAt,
            description: agreementToCopy.description,
            workOrder: agreementToCopy.workOrder,
            agreements: (agreements || {}).map(k => {
              return {
                quantity: parseInt(agreementCopyForm.getFieldValue('quantity_' + k.id)),
                price: k.price,
                subscriptionPack: { id: k.subscriptionPack.id }
              };
            })
          };

          executeAddCompanyAgreementsAction(
            {
              companyId: agreementCopyForm.getFieldValue('company'),
              agreement: newAgreement,
              companyName: subCompanies.find(
                i => i.id === agreementCopyForm.getFieldValue('company')
              ).name
            },
            addCompanyAgreements,
            dispatch
          ).then(e => {
            onClose();
          });
        }}
      >
        <Row gutter={[64, 0]}>
          <Col xs={24} md={8}>
            <Form.Item
              name="company"
              labelAlign={'left'}
              label={t(`Agreement.CopyAgreementModal.SubCompany`)}
              colon={false}
              rules={[
                {
                  required: true,
                  message: t('Agreement.CopyAgreementModal.SubCompanyRequired')
                }
              ]}
            >
              <Select
                name="companySelect"
                showSearch={true}
                filterOption={(input, option) =>
                  option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                }
                placeholder={t('Agreement.CopyAgreementModal.SelectSubCompany')}
                options={(subCompanies || [])
                  .filter(i => i.id !== currentCompany.id)
                  .map(e => {
                    return { value: e.id, label: e.name };
                  })}
                loading={isFetchingCompany}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[64, 0]}>
          <Col xs={24} md={8}>
            <Form.Item
              name="workOrder"
              labelAlign={'left'}
              label={t(`Agreement.CopyAgreementModal.Agreement`)}
              colon={false}
              initialValue={agreement.workOrder}
              rules={[
                {
                  required: true,
                  message: t('Agreement.CopyAgreementModal.AgreementRequired')
                }
              ]}
            >
              <Select
                name="agreementSelect"
                showSearch={true}
                filterOption={(input, option) =>
                  option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                }
                placeholder={t('Agreement.CopyAgreementModal.SelectAgreement')}
                loading={isFetchingAgreement}
                options={(agreementData || []).map(a => {
                  return { label: a.workOrder, value: a.workOrder };
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        {!!selectedWorkOrder && (
          <Row gutter={[64, 0]}>
            <Col span={24}>
              <Form.Item
                name="agreements"
                labelAlign={'left'}
                colon={false}
                shouldUpdate
                rules={[
                  {
                    required: true,
                    message: t('Agreement.CopyAgreementModal.AgreementRequired')
                  }
                ]}
              >
                <Table
                  bordered
                  loading={isFetchingAgreement}
                  columns={columns}
                  rowKey={'id'}
                  rowSelection={{
                    ...{
                      onChange: (selectedRowKeys, selectedRows) => {
                        agreementCopyForm.setFieldValue(
                          'agreements',
                          selectedRows.map(i => i.id)
                        );
                      }
                    }
                  }}
                  dataSource={
                    (agreementData || []).find(i => i.workOrder === selectedWorkOrder)?.agreements
                  }
                  pagination={false}
                  scroll={{ y: 600 }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};
