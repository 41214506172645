export const FETCH_BUCKET = 'scorecard/FETCH_BUCKET';
export const UPDATE_BUCKET = 'scorecard/UPDATE_BUCKET';
export const FETCH_BUCKET_DEFAULTS = 'scorecard/FETCH_BUCKET_DEFAULTS';
export const FETCH_SUMMARY = 'scorecard/FETCH_SUMMARY';
export const FETCH_CONFIG = 'scorecard/FETCH_CONFIG';
export const UPDATE_CONFIG = 'scorecard/UPDATE_CONFIG';
export const FETCH_SCORE_EVENTS = 'scorecard/FETCH_SCORE_EVENTS';
export const INCLUDE_EXCLUDE_EVENTS = 'scorecard/INCLUDE_EXCLUDE_EVENTS';
export const ENABLED_EVENT_TYPES = 'scorecard/ENABLED_EVENT_TYPES';
export const ENABLED_EVENT_TYPES_BRANCH = 'scorecard/ENABLED_EVENT_TYPES_BRANCH';
export const ENABLED_EVENT_TYPES_FLEET = 'scorecard/ENABLED_EVENT_TYPES_FLEET';
