import React from 'react';
import { useTranslation } from 'react-i18next';

export const OnboardingCard = ({ cardSize, noPins }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        width: cardSize || '100%',
        height: cardSize || '100%',
        margin: 16,
        backgroundColor: 'white',
        fontSize: '20px',
        padding: '80px 40px',
        borderRadius: '4px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        transition: 'box-shadow .1s ease-in-out',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateColumns: '8px 64px auto 32px 32px',
        gridTemplateRows: '32px 8px 24px 32px auto',
        '&:hover': {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.35)'
        }
      }}
    >
      <div
        style={{
          gridRowStart: 1,
          gridRowEnd: 6,
          gridColumnStart: 1,
          gridColumnEnd: 6
        }}
      >
        <div
          style={{
            marginBottom: '20px'
          }}
        >
          {noPins ? t('Tracking.NoPins') : t('Tracking.NoCards')}
        </div>
        {noPins && (
          <div>
            <span>{t('Tracking.ClickThe')}</span>
            <i
              style={{
                padding: '0 10px'
              }}
              className="tn-i-unpin"
            />
            <span>{t('Tracking.TheIcon')}</span>
          </div>
        )}
      </div>
    </div>
  );
};
