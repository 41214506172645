import React from 'react';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { EllipsisMenu } from 'components/tables/EllipsisMenu';
import { secondsToHHMMSS, insertIf } from 'utils/methods';
import { journeyHelpers } from './helpers';
import {
  getJourneyType,
  JOURNEY_TYPE,
  ellipsisMenuActiveEntities,
  ellipsisMenuDeletedEntities
} from './constants';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const nameCellRenderer = ({ rowData }) => {
  return <Link to={`/journeyplanner/id/${rowData.id}`}>{rowData.name}</Link>;
};

export const fleetsCellRenderer = ({
  dataKey,
  parent,
  rowIndex,
  rowData,
  fleets,
  companies,
  t
}) => {
  const renderFleet = (rowData.tripAssociations || []).map(
    trip => fleets?.find(fleet => fleet.id === trip.fleetId) || {}
  );
  const fleetElement = renderFleet.map((fleet, i) => (
    <div key={`${rowData.id}-${fleet.id}-${i}`}>
      {journeyHelpers.renderFleetsName(fleet, companies)}
    </div>
  ));
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${rowData.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div>{fleetElement.length > 0 && <div>{fleetElement}</div>}</div>
    </CellMeasurer>
  );
};

export const ellipsisButtonCellRenderer = ({
  rowData,
  can,
  deleteModal,
  filterTab,
  restoreJourney
}) => {
  const deleteAction = () => {
    deleteModal(rowData);
  };
  const restoreAction = () => {
    restoreJourney(rowData);
  };

  const menuItems = [
    {
      label: `${i18n.t('Common.EllipsisButton.View')}`,
      link: `/journeyplanner/id/${rowData.id}`,
      entity: 'VIEW',
      id: 'btn_journeyPlannerView'
    },
    {
      label: `${i18n.t('Common.EllipsisButton.Edit')}`,
      link: `/journeyplanner/edit/id/${rowData.id}`,
      entity: 'UPDATE',
      id: 'btn_journeyPlannerEdit'
    },
    {
      label: `${i18n.t('Common.EllipsisButton.Copy')}`,
      link: `/journeyplanner/copy/id/${rowData.id}`,
      entity: 'CREATE',
      id: 'btn_journeyPlannerCopy'
    },
    ...insertIf(rowData.stopsCount > 1, {
      label: `${i18n.t('Common.EllipsisButton.ExportToPdf')}`,
      onClick: () => {
        window.open(`/journeyplanner/export/id/${rowData.id}`);
      },
      entity: 'VIEW',
      id: 'btn_journeyPlannerExportPdf'
    }),
    {
      label: `${i18n.t('Common.EllipsisButton.Delete')}`,
      onClick: deleteAction,
      danger: true,
      entity: 'DESTROY',
      id: 'btn_journeyPlannerDelete'
    },
    {
      label: `${i18n.t('Common.EllipsisButton.Restore')}`,
      onClick: restoreAction,
      entity: 'RESTORE',
      id: 'btn_journeyPlannerRestore'
    }
  ];

  const showEntities =
    filterTab !== JOURNEY_TYPE.TAB.DELETED
      ? ellipsisMenuActiveEntities
      : ellipsisMenuDeletedEntities;
  const allowedMenuItems = menuItems.filter(
    item => can({ everyEntity: `TRIP_${item.entity}` }) && showEntities.includes(item.entity)
  );
  return <EllipsisMenu menuItems={allowedMenuItems} />;
};

export const typeCellRenderer = ({ rowData }) => {
  return getJourneyType(rowData.type);
};

export const plannedDurationCellRenderer = ({ rowData }) => {
  return secondsToHHMMSS(rowData?.estimatedDuration);
};

export const calculatedDistanceCellRenderer = ({ rowData, localization }) => {
  if (!rowData.calculatedDistance) return;
  return localization.convertDistance(rowData.calculatedDistance);
};

export const stopsNumberCellRenderer = ({ rowData }) => {
  return rowData?.stopsCount;
};
