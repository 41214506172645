import Fuse from 'fuse.js';
import { useMemo } from 'react';

export function useFuzzySearch(data) {
  const fuse = new Fuse(data, {
    keys: ['make', 'model', 'yearOfManufacture'],
    threshold: 0.3,
    minMatchCharLength: 2,
    ignoreLocation: true,
    useExtendedSearch: true
  });

  const constructQueryObject = (keyword, defaultField) => {
    const result = { $and: [] };
    result.$and.push({ [defaultField]: keyword?.trim() });
    return result;
  };

  const search = (keyword, key) => {
    const parsedQuery = constructQueryObject(keyword, key);
    let filteredData = fuse.search(parsedQuery).map(result => result.item);

    return filteredData;
  };

  return useMemo(() => {
    return {
      performSearch: search
    };
  }, [data]);
}
