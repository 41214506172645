import i18n from 'i18next';
import moment from 'moment';

import { getEventAttributesByType } from 'containers/Tracking/EventTypes';

export const escapeXml = input => {
  return input.replace(/[<>&'"]/g, function(c) {
    switch (c) {
      case '<':
        return '&lt;';
      case '>':
        return '&gt;';
      case '&':
        return '&amp;';
      case "'":
        return '&apos;';
      case '"':
        return '&quot;';
      default:
        return c;
    }
  });
};

export const prepareDataForGPXExport = trips => {
  let result =
    '<?xml version="1.0" encoding="UTF-8"?>\n<gpx\n  version="1.1"\n  creator="TN360"\n  xmlns="http://www.topografix.com/GPX/1/1"\n  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"\n  xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">\n';

  if (trips) {
    trips.forEach(trip => {
      if (trip.events && trip.events.length) {
        trip.events.forEach(event => {
          const eventAttributes = getEventAttributesByType(event?.eventType, event?.subType);
          const eventType = i18n.t(`Tracking.Events.${eventAttributes?.label}`);
          const gps = event.GPS;
          const timeAtUtc = moment(event.timeAt).utc();
          const timeUtcISO = timeAtUtc.format();
          const timeUtcFormatted = timeAtUtc.format('YYYY-MM-DD HH:mm:ss');

          // Not sure if we need this, but to be safe replace any characters in address that might break the xml file formatting
          const location = event.location ? escapeXml(event.location) : '';

          let wpt = `<wpt lat="${gps?.Lat}" lon="${gps?.Lng}">\n`;
          wpt += `  <name>Event: ${eventType}</name>\n`;
          wpt += `  <time>${timeUtcISO}</time>\n`;
          wpt += `  <ele>${gps?.Alt}</ele>\n`;
          wpt += `  <hdop>${gps?.HDOP}</hdop>\n`;
          wpt += `  <sat>${gps?.NSat}</sat>\n`;
          wpt += `  <desc>Latitude: ${gps?.Lat}&lt;br&gt;Longitude: ${gps?.Lng}&lt;br&gt;Location: ${location}&lt;br&gt;Date &amp; Time (UTC): ${timeUtcFormatted}</desc>\n`;
          wpt += '</wpt>\n';

          result += wpt;
        });
      }

      if (trip.replay && trip.replay.length) {
        let name = '';
        if (trip.id && trip.ignitionOn) {
          const start = moment(trip.ignitionOn)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
          name = 'Trip: ' + start;
          if (trip.ignitionOff) {
            const end = moment(trip.ignitionOff)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss');
            name += ' - ' + end;
          }
        }

        result += `<trk>\n  <name>${name}</name>\n  <trkseg>\n`;

        trip.replay.forEach(point => {
          const timeUtcISO = moment(point.At)
            .utc()
            .format();

          let trkpt = `    <trkpt lat="${point.Lat}" lon="${point.Lng}">\n`;
          trkpt += `      <ele>${point.Alt}</ele>\n`;
          trkpt += `      <time>${timeUtcISO}</time>\n`;
          trkpt += '    </trkpt>\n';

          result += trkpt;
        });

        result += '  </trkseg>\n</trk>\n';
      }
    });
  }

  result += '</gpx>';

  return result;
};
