import { Card } from 'antd';
import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import styles from './CompanyConfig.module.scss';
import { useCanEveryCompanyService, services } from 'features/permissions';
import { UNIQUE_IDS } from './utils/constants';
export function ConfigCard({
  title,
  shortDescription,
  modal,
  modalDetails,
  navigateTo,
  id,
  forceShow,
  ...props
}) {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const isELDUS = useCanEveryCompanyService([services.ELDUS]);

  const handleCardClick = useCallback(() => {
    if (id === UNIQUE_IDS.ELD) {
      if (isELDUS) {
        history.push(navigateTo);
      } else {
        setShowModal(true);
      }
    } else {
      if (modal) {
        setShowModal(true);
      } else if (navigateTo) {
        history.push(navigateTo);
      }
    }
  }, [modal, navigateTo, history, id, isELDUS]);

  useEffect(() => {
    if (forceShow) {
      setShowModal(forceShow);
    }
  }, [history]);

  const handleModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const Modal = modal;

  return (
    <>
      <Card className={styles.configCard} onClick={handleCardClick} hoverable id={id}>
        <p className={styles.configCard_title}>{title}</p>
        <p className={styles.configCard_desc}>{shortDescription}</p>
      </Card>
      {modal && showModal && (
        <Modal
          title={title}
          show={showModal}
          modalDetails={modalDetails}
          onClose={handleModalClose}
        />
      )}
    </>
  );
}
