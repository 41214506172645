/* eslint-disable import/prefer-default-export */
import { nanoid } from 'nanoid';

export const getHeaderOption = label => ({
  name: 'Name',
  description: 'Description',
  enumerable: label
});

export const MULTIPLE_SELECT_HEADER_ROW_ID = nanoid();
