import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { setPageTitle, setBackButton } from 'features/page/pageSlice';

import { Layout } from 'components/ant';

import TabHeader from './TabHeader';
import Tabs from './Tabs';
import { VehicleCombinationModal } from './Modals/VehicleCombinationModal';
import { VehicleCombinationsTable } from './Tables';

import {
  useIsFetching,
  useVehicleCombinations,
  addVehicleCombination,
  updateVehicleCombination,
  deleteVehicleCombination
} from 'features/massManagement/vehicleCombinationsSlice';
import { BUTTON_IDS } from 'utils/globalConstants';

const VehicleCombinations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const combinations = useVehicleCombinations();
  const isLoading = useIsFetching();

  const [data, setData] = useState([]);
  const [editRecord, setEditRecord] = useState(null);
  const [showVehicleCombinationModal, setShowVehicleCombinationModal] = useState(false);

  const onAdd = () => {
    setEditRecord(null);
    setShowVehicleCombinationModal(true);
  };

  const onAddSave = record => {
    dispatch(addVehicleCombination(record));
  };

  const onEdit = record => {
    setEditRecord(record);
    setShowVehicleCombinationModal(true);
  };

  const onEditSave = record => {
    dispatch(updateVehicleCombination(record));
  };

  const onDelete = record => {
    dispatch(deleteVehicleCombination(record));
  };

  const isNameUnique = name => {
    return (
      data.find(
        vehicleCombo => vehicleCombo?.name?.toLowerCase().trim() === name?.toLowerCase().trim()
      ) === undefined
    );
  };

  useEffect(() => {
    setData(combinations);
  }, [combinations]);

  useEffect(() => {
    dispatch(setPageTitle(t('Common.MassManagement')));
    dispatch(setBackButton(false));
  }, [dispatch]);

  return (
    <Layout>
      <TabHeader
        tabs={<Tabs />}
        toolbar={
          <Button
            type="primary"
            size="large"
            onClick={() => {
              onAdd();
            }}
            id={BUTTON_IDS.vehicleCombinationsAddNew}
          >
            {t('MassManagement.Action.AddCombination')}
          </Button>
        }
      />

      <VehicleCombinationsTable
        data={data}
        onEdit={onEdit}
        onDelete={onDelete}
        isLoading={isLoading}
      />

      <VehicleCombinationModal
        data={editRecord}
        isOpen={showVehicleCombinationModal}
        isNameUnique={isNameUnique}
        onSave={record => {
          editRecord ? onEditSave(record) : onAddSave(record);
        }}
        onClose={() => {
          setShowVehicleCombinationModal(false);
        }}
      />
    </Layout>
  );
};

export default VehicleCombinations;
