import React from 'react';
import Table from './Table';
import { ResponsiveLoadingTable } from 'components/grid/ResponsiveLoadingTable';

const LoaderTable = ({ cols, rows, emptyResult, ...props }) => {
  if (emptyResult) {
    return emptyResult;
  }
  if (!rows || rows.length === 0) {
    const loadingCols = [...cols].fill(100 / cols.length);
    return <ResponsiveLoadingTable columnSizes={loadingCols} />;
  }
  return <Table cols={cols} rows={rows} {...props} />;
};

export default LoaderTable;
