import { useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { fetchImageBlobs } from './fetchImageBlobs';
const useDownloadImages = () => {
  const [loading, setLoading] = useState(false);

  const downloadImagesAsZip = async (attachments, handleProgress, title) => {
    if (!attachments || !Array.isArray(attachments)) {
      console.warn('Invalid attachments array provided.');
      return;
    }
    setLoading(true);
    try {
      const blobsWithNames = await fetchImageBlobs(attachments, handleProgress);
      if (blobsWithNames.length === 0) {
        console.warn('No valid blobs were returned.');
        return;
      }
      const zip = new JSZip();
      blobsWithNames.forEach(({ blob, name }) => {
        zip.file(name, blob);
      });
      const { files } = zip;
      const fileCount = Object.keys(files).length;
      console.log('attachments count:', attachments.length);
      console.log('Blobs count:', blobsWithNames.length);
      console.log('zipFileCount: ', fileCount);
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `${title || 'download'}.zip`);
    } catch (error) {
      console.error('Error downloading or zipping images:', error);
    } finally {
      setLoading(false);
    }
  };

  return { downloadImagesAsZip, loading };
};

export default useDownloadImages;
