import { useDispatch, useSelector } from 'react-redux';
import { contactsSelectors } from '../selectors';
import { fetchContacts } from '../thunks';

export const useIsUpdatingWifi = () => useSelector(contactsSelectors.isUpdatingWifi);
export const useIsFetching = () => useSelector(contactsSelectors.isFetching);

export const useContacts = locationId => {
  const dispatch = useDispatch();
  const contacts = useSelector(contactsSelectors.getContacts(locationId));

  const isFetching = useSelector(contactsSelectors.isFetching(locationId));
  const isSuccess = useSelector(contactsSelectors.isSuccess(locationId));
  const isError = useSelector(contactsSelectors.isError(locationId));

  const isFetchingComplete = isSuccess || isError;

  if (!isFetching && !isFetchingComplete && locationId) {
    dispatch(fetchContacts(locationId));
  }

  return contacts;
};
