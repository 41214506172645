import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input, Form, Modal } from 'antd';
import {
  submitMessageTemplate,
  useIsSubmittingMessageTemplate,
  useIsSubmitMessageTemplateSuccess,
  useIsSubmitMessageTemplateFailed,
  useSubmitMessageTemplateFailedError,
  setInitailSubmitMessageTemplateStatus
} from 'features/messaging/messagingSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import styles from './Messaging.module.scss';

export const MessageTemplateModal = ({ visible, showModal, template }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isEditing = useIsSubmittingMessageTemplate();
  const isEditSuccess = useIsSubmitMessageTemplateSuccess();
  const isEditFailed = useIsSubmitMessageTemplateFailed();
  const failedSubmitErrorStr = useSubmitMessageTemplateFailedError();
  const isAdding = !template;

  const [disableOKBtn, setDisableEdit] = useState(isAdding);
  const [formValue, setFormValue] = useState(template);

  const timeoutRef = useRef();
  useEffect(() => {
    if (timeoutRef.current != null) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      const formIsInValid =
        !form.getFieldValue('subject') ||
        !form.getFieldValue('body') ||
        form.getFieldsError(['subject', 'body']).some(f => !!f.errors.length);
      setDisableEdit(formIsInValid);
    });
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [formValue, form]);

  useEffect(() => {
    if (isEditSuccess) {
      dispatch(setInitailSubmitMessageTemplateStatus());
      dispatch(
        openToast({
          type: ToastType.Success,
          message: `${
            isAdding
              ? t('Messaging.Notifications.Added', { entity: t('Entity.Message Template') })
              : t('Messaging.Notifications.Updated', { entity: t('Entity.Message Template') })
          }`
        })
      );
      showModal(false);
    } else if (isEditFailed) {
      dispatch(setInitailSubmitMessageTemplateStatus());
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${
            isAdding
              ? t('Messaging.Notifications.AddFailed', {
                  entity: t('Entity.Message Template'),
                  error: failedSubmitErrorStr
                })
              : t('Messaging.Notifications.UpdateFailed', {
                  entity: t('Entity.Message Template'),
                  error: failedSubmitErrorStr
                })
          }`
        })
      );
    }
  }, [isEditSuccess, isEditFailed, isAdding, showModal, failedSubmitErrorStr, dispatch, t]);

  const handleOk = useCallback(() => {
    form.submit();
  }, [form]);
  const handleCancel = () => {
    //check if isFieldsTouched, if touched check if all fields are empty
    if (form.isFieldsTouched()) {
      const formValue = form.getFieldsValue() || {};
      //body,subject
      const bodyIsEmpty = !formValue['body'] || !formValue['body'].trim();
      const subjectIsEmpty = !formValue['subject'] || !formValue['subject'].trim();
      if (bodyIsEmpty && subjectIsEmpty) {
        showModal(false);
      } else {
        confirmationModal(
          t('Common.Modal.SureTitle'),
          t('Common.Modal.SureQuestionChangesLost'),
          t('Common.Modal.CancelChanges'),
          t('Common.Modal.Stay'),
          () => showModal(false)
        );
      }
    } else {
      showModal(false);
    }
  };
  const handleSubmit = values => {
    dispatch(submitMessageTemplate({ ...values, id: template?.id }));
  };
  return (
    <Modal
      wrapClassName={styles.messagingModal}
      title={`${isAdding ? t('Messaging.Form.New') : t('Messaging.Form.Edit')} ${t(
        'Entity.Message Template'
      )}`}
      open={visible}
      cancelText={t('Common.Cancel')}
      okText={isAdding ? t('Messaging.Create Template') : t('Common.SaveButton')}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ disabled: disableOKBtn, loading: isEditing }}
      maskClosable={false}
    >
      <Form
        initialValues={template}
        layout="vertical"
        form={form}
        name="message_template_form"
        style={{ width: '100%' }}
        onValuesChange={(changedValues, allValues) => {
          setFormValue(allValues);
        }}
        onFinish={handleSubmit}
      >
        <Form.Item
          key="subject"
          name="subject"
          label={t('Messaging.Subject')}
          rules={[
            { required: true, message: t('Messaging.Form.FormValidation.SubjectRequired') },
            { max: 50, message: t('Messaging.Form.FormValidation.SubjectLength') }
          ]}
          hasFeedback
        >
          <Input placeholder={t('Messaging.Form.FormValidation.TypeHere')} />
        </Form.Item>
        <Form.Item
          key="body"
          name="body"
          label={t('Messaging.Message')}
          rules={[
            { required: true, message: t('Messaging.Form.FormValidation.MessageRequired') },
            { max: 1000, message: t('Messaging.Form.FormValidation.MessageLength') }
          ]}
          hasFeedback
        >
          <Input.TextArea
            className={'showScrollbarsOnHover'}
            rows={5}
            placeholder={t('Messaging.Form.FormValidation.WriteSomething')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
