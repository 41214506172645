import React from 'react';
import PropTypes from 'prop-types';
import { Select, Tag } from 'antd';

import MultipleSelectTableOption from './multipleSelectTableOption/MultipleSelectTableOption';
import { MULTIPLE_SELECT_HEADER_ROW_ID } from './constants';
import styles from './MultipleSelectTable.module.scss';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const getHeaderOption = columnHeaderLabels => ({
  ...columnHeaderLabels,
  id: MULTIPLE_SELECT_HEADER_ROW_ID,
  addMore: 'all'
});

export const tagRender = props => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      className={styles.tagContainer}
      color="green"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {label?.props?.option?.firstColumnValue}
    </Tag>
  );
};

export const getEntries = (input, option) =>
  option?.children?.props?.option?.firstColumnValue?.toLowerCase().indexOf(input.toLowerCase()) >=
    0 ||
  option?.children?.props?.option?.secondColumnValue?.toLowerCase().indexOf(input.toLowerCase()) >=
    0 ||
  option?.children?.props?.option?.thirdColumnValue?.toLowerCase().indexOf(input.toLowerCase()) >=
    0;

export const getTableValues = (options, columnHeaderLabels, selected, selectedAllCheckbox) => {
  const headerOption = getHeaderOption(columnHeaderLabels);
  const values = options?.length
    ? Array(
        <Select.Option className={styles.alertItemContainer} key={headerOption.id} disabled>
          <div className={styles.optionsContainer} onMouseDown={e => e.preventDefault()} disabled>
            <Checkbox
              checked={selectedAllCheckbox?.checkboxStatus}
              onClick={selectedAllCheckbox?.handleCheckboxChange}
            ></Checkbox>
            <span className={styles.option}>{headerOption.firstColumnValue || ''}</span>
            <span className={styles.option}>{headerOption.secondColumnValue || ''}</span>
            {Object.keys(columnHeaderLabels).length > 2 && (
              <span className={styles.option}>{headerOption?.thirdColumnValue || ''}</span>
            )}
          </div>
        </Select.Option>
      )
    : [];

  if (options?.length) {
    options.forEach(option => {
      values.push(
        <Select.Option
          className={styles.alertItemContainer}
          key={String(option.id)}
          value={option.id}
        >
          <MultipleSelectTableOption
            option={option}
            columnsNumber={Object.keys(columnHeaderLabels).length}
            selected={selected}
          />
        </Select.Option>
      );
    });
  }

  return values;
};

tagRender.propTypes = {
  label: PropTypes.string.isRequired,
  closable: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired
};

getEntries.propTypes = {
  option: PropTypes.node.isRequired,
  enumerable: PropTypes.node.isRequired,
  input: PropTypes.node.isRequired
};
