import React, { useRef } from 'react';
import { Resizable } from 'react-resizable';

import './ResizableTitle.scss';

export const handleColumnResize = (index, columns, setColumns) => (_, { size }) => {
  const newColumns = [...columns];
  newColumns[index] = {
    ...newColumns[index],
    width: Math.floor(size.width)
  };
  setColumns(newColumns);
};

export const addResizeToColumns = (columns, setColumns, onResizeStop, onClick) => {
  let mergeColumns = null;

  if (columns) {
    mergeColumns = columns.map((col, index) => {
      if (col.isConfigurable) {
        return {
          ...col,
          onHeaderCell: column => ({
            width: column.width,
            minWidth: col.minWidth ? col.minWidth : 60,
            onResize: handleColumnResize(index, columns, setColumns),
            onClick: () => onClick(column),
            onResizeStop: onResizeStop
          })
        };
      } else {
        return col;
      }
    });
  }

  return mergeColumns;
};

export const ResizableTitle = props => {
  const { onResize, onResizeStop, width, minWidth, onClick, ...restProps } = props;

  // resizing state to prevent click event while resizing (triggers column sort sometimes)
  const resizing = useRef(false);

  if (!width) {
    return <th {...restProps} />;
  }
  return (
    <Resizable
      width={width}
      height={0}
      minConstraints={minWidth ? [minWidth, 0] : null}
      onResizeStart={() => (resizing.current = true)}
      onResizeStop={() => {
        setTimeout(() => {
          resizing.current = false;
        });
        if (onResizeStop) {
          onResizeStop();
        }
      }}
      handle={
        <span
          className="react-resizable-handle"
          onClick={e => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{
        enableUserSelectHack: false
      }}
    >
      <th
        onClick={(...args) => {
          if (!resizing.current && onClick) {
            onClick(...args);
          }
        }}
        {...restProps}
      />
    </Resizable>
  );
};
