import React from 'react';
import { Field } from 'formik';
import { FormGroup, Row, Col, FormLabel, FormCheck } from 'react-bootstrap';
import styles from './FormRadio.module.scss';

const FormRadio = ({ name, values, xs, label, isRequired, type, disabled }) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <FormGroup as={Col} xs={xs}>
          <FormLabel className={!label && styles.invisible}>{label || 'Label'}</FormLabel>
          {isRequired && <span className={styles.inputRequired}>*</span>}
          {values.map((value, index) => (
            <Row key={`radio-${value.value}`}>
              <Col xs={1} style={{ maxWidth: '30px' }}>
                <FormCheck
                  {...field}
                  type={type}
                  id={`check-${name}-${index}`}
                  checked={
                    value.value ===
                    (type === 'checkbox'
                      ? field.value && field.value.length > 0 && field.value[index]
                      : field.value)
                  }
                  value={value.value}
                  disabled={disabled || false}
                  className={styles.formCheck}
                />
              </Col>
              <Col className={styles.formRadioLabelWarpper}>
                <label htmlFor={`check-${name}-${index}`} className={styles.formRadioLabel}>
                  {value.label}
                </label>
              </Col>
            </Row>
          ))}
          {isRequired && (
            <div className={`fieldError ${meta.touched && meta.error ? 'error has' : 'error'}`}>
              {meta.touched && meta.error}
            </div>
          )}
        </FormGroup>
      )}
    </Field>
  );
};

export default FormRadio;
