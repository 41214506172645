import React from 'react';
import styles from '../Overview.module.scss';
import { WorkStatus } from 'containers/ELD/Constants';
import i18next from 'i18nextConfig';

export function onDutyTimeCell_Default(rowData) {
  const isExempt = rowData.isExemptDriver || false;
  const ondutyTimeAvailable = isExempt ? 0 : Math.max(rowData?.onDutyTimeAvailable || 0, 0);
  const maxOndutyTime = Math.max(1, (rowData?.onDutyTime || 0) + (rowData?.onDutyTimeLeft || 0));

  let remainedPercent = Math.min(
    100,
    Math.max(0, (ondutyTimeAvailable / maxOndutyTime).toFixed(2) * 100)
  );
  let barStyle = '';
  switch (rowData.onDutyTimeStatus) {
    case WorkStatus.AtRisk:
      if (ondutyTimeAvailable <= 0) {
        barStyle = styles.outline_yellow;
      } else {
        barStyle += ' ' + styles.yellow;
      }
      break;
    case WorkStatus.InVio:
      barStyle = styles.red;
      if (remainedPercent === 0) {
        remainedPercent = 100;
      }
      break;
    default:
      if (rowData.lastUpdate !== 'N/A' && ondutyTimeAvailable <= 0) {
        barStyle = styles.outline_yellow;
      } else {
        barStyle = styles.onDutyTimeBar;
      }
      break;
  }
  if (isExempt) {
    barStyle = '';
  }
  return (
    <div className={styles.barChart}>
      <div className={styles.bar + ' ' + barStyle}>
        <span
          className={remainedPercent === 100 ? styles.fullBar : styles.progressBar}
          style={{ width: remainedPercent + '%' }}
        ></span>
      </div>
      {!isExempt && (
        <p>
          {Math.floor(ondutyTimeAvailable / 3600)} {i18next.t('Common.Hours')}{' '}
          {Math.floor((ondutyTimeAvailable % 3600) / 60)} {i18next.t('Common.Mins')}
        </p>
      )}
      {isExempt && <p>{i18next.t('Common.Exempt')}</p>}
    </div>
  );
}
