import { Card, Row, Col } from 'antd';
import {
  fetchAttachmentContent,
  useAttachmentContentById
} from 'features/attachments/attachmentSlice';
import { useUserKey } from 'features/user/userSlice';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AttachmentStatus } from '../constant';
import { isIQCamera } from 'features/camera/CameraModelConfig';
import styles from './EventPreview.module.scss';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { EventMenuItem } from './MenuItem';
import { FootageCountBadge } from './FootagesCountBadge';
import { LoadingSpinner } from 'components/loading/LoadingSpinner';
import { useDevices } from 'features/fleets/fleetsSlice';
import { IQCameraSensitivity } from './IQCameraSensitivity';

const LIMITED_PLAY_TIME = 15.0; //only allow maximum 15 seconds play

function MediaDisplay({ event, ...props }) {
  const [videoIsLoading, setVideoIsLoading] = useState(false);

  const isVideo = event.mimeType?.includes('video');
  const userKey = useUserKey();
  const attachmentContent = useAttachmentContentById(
    event.attachmentId,
    event.mimeType,
    userKey,
    moment(event.rawTimeAt).year(),
    moment(event.rawTimeAt).month() + 1
  );

  useEffect(() => {
    if (attachmentContent?.url) {
      if (videoIsLoading) {
        setVideoIsLoading(false);
      }
    }
  }, [attachmentContent]);

  const handleRightClick = useCallback(evt => {
    evt.stopPropagation();
    evt.preventDefault();
  }, []);
  const dispatch = useDispatch();
  const videoContextRef = useRef(null);

  const handleRef = useCallback(node => {
    videoContextRef.current = {
      lastDate: Date.now(),
      timeHandle: null,
      node: node
    };
  }, []);

  const handleVideoPlay = useCallback(() => {
    if (!attachmentContent?.url) {
      if (!videoIsLoading) {
        setVideoIsLoading(true);
      }
      dispatch(
        fetchAttachmentContent(
          event.attachmentId,
          userKey,
          false,
          moment(event.rawTimeAt).year(),
          moment(event.rawTimeAt).month() + 1
        )
      );
    } else {
      if (videoIsLoading) {
        setVideoIsLoading(false);
      }
    }
  }, [event, userKey, attachmentContent, dispatch]);

  const handleMouseOver = useCallback(() => {
    if (videoContextRef.current.timeHandle) {
      clearTimeout(videoContextRef.current.timeHandle);
      videoContextRef.current.timeHandle = null;
      if (videoIsLoading) {
        setVideoIsLoading(false);
      }
    }

    videoContextRef.current.node?.play();
    videoContextRef.current.timeHandle = null;
    if (videoIsLoading) {
      setVideoIsLoading(false);
    }
  }, []);

  const handleMouseOut = useCallback(() => {
    if (videoIsLoading) {
      setVideoIsLoading(false);
    }
    if (videoContextRef.current?.timeHandle) {
      clearTimeout(videoContextRef.current?.timeHandle);
      videoContextRef.current.timeHandle = null;
    }
    videoContextRef.current?.node?.pause();
  }, []);

  const handleTimeUpdate = useCallback(() => {
    if (
      videoContextRef.current.node?.duration > LIMITED_PLAY_TIME &&
      videoContextRef.current.node.currentTime > LIMITED_PLAY_TIME
    ) {
      videoContextRef.current.node.currentTime = 0;
    }
  }, []);

  return (
    <>
      {!isVideo && <img src={attachmentContent?.url} onContextMenu={handleRightClick} alt="" />}
      {isVideo && (
        <div style={{ position: 'relative' }}>
          {videoIsLoading && <LoadingSpinner className={styles.VideoLoadingSpinner} />}
          <video
            ref={handleRef}
            onContextMenu={handleRightClick}
            onPlay={handleVideoPlay}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onTimeUpdate={handleTimeUpdate}
            poster={attachmentContent?.thumb_url || ''}
            preload="none"
            muted
            controlsList="nodownload noplay nopause nofullscreen"
            disablePictureInPicture={true}
          >
            {!attachmentContent?.url && <source src="dummy.mp4" type={event.mimeType} />}
            {attachmentContent?.url && (
              <source src={attachmentContent?.url} type={event.mimeType} />
            )}
          </video>
        </div>
      )}
    </>
  );
}

function MediaCover({ event, onViewEvent, ...props }) {
  const isAttAvailable =
    event.attachmentId &&
    [AttachmentStatus.ENABLED, AttachmentStatus.AVAILABLE].includes(event.attachmentStatus);
  const [showMedia, setShowMedia] = useState(false);
  const observer = useRef(null);
  const { t } = useTranslation();

  const handleViewEvent = useCallback(() => {
    if (onViewEvent) {
      onViewEvent(event);
    }
  }, [event, onViewEvent]);

  const attachRef = useCallback(
    node => {
      if (isAttAvailable && node) {
        observer.current = new IntersectionObserver(
          () => {
            setShowMedia(true);
          },
          {
            root: null,
            threshold: 0.3
          }
        );
        observer.current.observe(node);
      }
      return node;
    },
    [isAttAvailable]
  );

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <div ref={attachRef} className={styles.mediaCover} onClick={handleViewEvent}>
      {!isAttAvailable && (
        <div className={styles.attachmentNotAvailable}>
          <label>
            {t(
              'Common.AttachmentStatus.' + (event.attachmentStatus || AttachmentStatus.UNAVAILABLE)
            )}
          </label>
        </div>
      )}

      {isAttAvailable && showMedia && <MediaDisplay event={event} />}
      <FootageCountBadge event={event} />
    </div>
  );
}

export function EventPreview({ event, onRequestVideo, onViewEvent, ...props }) {
  const { t } = useTranslation();
  const devices = useDevices();
  const isIQCam = useMemo(
    () => isIQCamera(devices?.find(d => String(d.id) === String(event?.deviceId))),
    [event?.deviceId, devices]
  );

  return (
    <Card
      className={event.pristine ? styles.pristine : styles.eventPreview}
      cover={<MediaCover event={event} onViewEvent={onViewEvent} />}
      onMouseOver={_ => {
        if (event.pristine && event.dismissPristine) {
          event.dismissPristine(event.id);
        }
      }}
    >
      <Row>
        <Col span={20} className={styles.eventContent}>
          <p>{event.eventType}</p>
          <p>{event.timeAt}</p>
          <p>
            <span>
              {event.vehicleName || event.deviceName}
              {isIQCam && <IQCameraSensitivity deviceId={event.deviceId} bracket />}
            </span>
            <span>{event.driverName ? ` | ${event.driverName}` : ''}</span>
          </p>
          <p>{isIQCam && <EventCameraPreset t={t} value={event.dutyType} />}</p>
        </Col>
        <Col className={styles.eventAction}>
          <EventMenuItem
            event={event}
            showRetry={false}
            showViewEvent={false}
            onRequestVideo={onRequestVideo}
          />
        </Col>
      </Row>
    </Card>
  );
}

function EventCameraPreset({ t, value }) {
  return value ? (
    <span className={styles.eventCamPreset}>{`${t('Home.CameraPresetOfEvent')} - ${t(
      `CompanyConfig.IQCamera.${value}`
    )}`}</span>
  ) : null;
}
