import { useEffect, useState } from 'react';
import { useUserKey } from '../../../features/user/userSlice';
import { API_PATH } from 'config';
import { ApiClient, UsersApi, RolesApi } from 'nextgen_api';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { useDispatch } from 'react-redux';
import request from 'superagent';
import { api } from 'utils/api';
import { isDriverType } from './constants';
import { DEFAULT_DRIVER_FORM_FIELD_NAME } from '../Vehicles/DefaultDriver/constants';
import { t_error } from 'i18nextConfig';
import i18next from 'i18nextConfig';

const COMPANIES_URL = '/companies';

export const useUserViewData = hookParams => {
  const { id, refetch, onError, embedParam } = hookParams;
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const [userViewData, setUserViewData] = useState({
    userViewData: '',
    isLoading: true,
    isLoadingEmbed: false
  });

  useEffect(() => {
    if (refetch) {
      setUserViewData({
        userViewData: '',
        isLoading: false,
        isLoadingEmbed: false
      });
    }
    if (id) {
      // We do the API request without any embeds first
      dispatch(
        fetchUserDetails(
          id,
          ({ userViewData, error, errorMsg }) => {
            setUserViewData({
              userViewData,
              isLoading: false,
              isLoadingEmbed: true
            });
            if (error) {
              dispatch(
                openToast({
                  type: ToastType.Error,
                  message: errorMsg
                })
              );
              if (onError) {
                onError();
              }
            }
            // After, we do the call with the embeds, so that we can show
            // a part of the information to the user while other data is retrieved
            const updatedEmbedParams =
              userViewData?.type?.code === 'DRIVER'
                ? `${embedParam},current_vehicles,document`
                : embedParam;
            dispatch(
              fetchUserDetails(
                id,
                ({ userViewData, error, errorMsg }) => {
                  setUserViewData(
                    {
                      userViewData,
                      isLoading: false,
                      isLoadingEmbed: false
                    },
                    embedParam
                  );
                  if (error) {
                    dispatch(
                      openToast({
                        type: ToastType.Error,
                        message: errorMsg
                      })
                    );
                    if (onError) {
                      onError();
                    }
                  }
                },
                updatedEmbedParams
              )
            );
          },
          null
        )
      );
    } else if (id !== undefined && isNaN(id)) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('Common.Invalid Request ID')
        })
      );
      if (onError) {
        onError();
      }
    }
  }, [userKey, id, dispatch, refetch, onError]);
  return userViewData;
};

export const useUserData = hookParams => {
  const { id, refetch = false, onError, embedParam } = hookParams;
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const [userViewData, setUserViewData] = useState({
    userViewData: '',
    isLoading: true
  });

  useEffect(() => {
    if (refetch) {
      setUserViewData({
        userViewData: '',
        isLoading: false
      });
    }
    if (id) {
      dispatch(
        fetchUserDetails(
          id,
          ({ userViewData, error, errorMsg }) => {
            setUserViewData({
              userViewData,
              isLoading: false
            });
            if (error) {
              dispatch(
                openToast({
                  type: ToastType.Error,
                  message: errorMsg
                })
              );
              if (onError) {
                onError();
              }
            }
          },
          embedParam
        )
      );
    } else if (id !== undefined && isNaN(id)) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: i18next.t('Common.Invalid Request ID')
        })
      );
      if (onError) {
        onError();
      }
    }
  }, [userKey, id, dispatch, refetch, onError]);
  return userViewData;
};

export const useRoles = userKey => {
  const [rolesData, setRolesData] = useState({
    roles: [],
    isLoading: true
  });

  useEffect(() => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    const rolesApi = new RolesApi(apiClient);
    new Promise((resolve, reject) => {
      rolesApi.getRoles({}, (err, data, resp) => {
        if (err && resp.status !== 200) {
          console.log(err);
          reject(err);
        } else {
          resolve(resp.body);
        }
      });
    }).then(roles => {
      roles.forEach(role => {
        role.description = role.description.replace('NextGen', 'TN360');
      });
      setRolesData({
        roles,
        isLoading: false
      });
    });
  }, [userKey]);
  return rolesData;
};

export const getBranchesByCompany = (companyId, userKey) => {
  return new Promise((resolve, reject) => {
    const url = `${API_PATH}/locations?direction=DOWN&type[]=BRANCH&company_id=${companyId}`;
    request('GET', url)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(resp => {
        const filteredBranches = resp?.body.sort((a, b) =>
          a?.name?.toUpperCase() > b?.name?.toUpperCase() ? 1 : -1
        );
        resolve(filteredBranches);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const fetchCompanyDetails = companyId => async (dispatch, getState) => {
  const authKey = getState().user?.current?.auth?.key;
  try {
    const response = await api.get(`${COMPANIES_URL}/${companyId}`, {
      authKey
    });
    return response.body || [];
  } catch (err) {
    console.error(err);
    dispatch(
      openToast({
        type: ToastType.Error,
        message: err?.message
      })
    );
  }
};

export const fetchUserDetails = (
  userId,
  callback = () => {},
  embedParam = 'audits,fleets,locations,image_url,associations,user_session,document,current_vehicles'
) => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  if (isNaN(Number(userId)) || Number(userId) > Number.MAX_SAFE_INTEGER) {
    const errorMsg = {
      error: true,
      errorMsg: i18next.t('Common.Invalid Request ID'),
      userViewDatauserErrorMessage: {}
    };
    callback(errorMsg);
    return Promise.resolve(errorMsg);
  }

  let apiClient = new ApiClient();
  apiClient.basePath = API_PATH;
  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };

  const embedAndPruningObj = { pruning: 'ALL' };
  if (embedParam) {
    embedAndPruningObj['embed'] = embedParam;
  }

  const usersApi = new UsersApi(apiClient);
  new Promise(resolve => {
    usersApi.findById(userId, embedAndPruningObj, (err, data, resp) => {
      let userErrorMessage = 'Error retrieving the user details.';
      if (err && resp && resp.status !== 200) {
        if (resp.type === 'application/json') {
          userErrorMessage = t_error(resp.body);
        } else if (resp.text) {
          userErrorMessage = resp.text;
        }

        resolve({
          error: true,
          errorMsg: userErrorMessage,
          userViewDatauserErrorMessage: {}
        });
        callback({
          error: true,
          errorMsg: userErrorMessage
        });
      } else {
        const userDetails = resp?.body;
        if (isDriverType(userDetails)) {
          fetchDefaulDriverByUserId(userId, userKey).then(defaultDrivers => {
            const getVehicleDefaultDriver = vehicle =>
              (defaultDrivers || [])?.find(defaultDriver => {
                const isSameUser =
                  defaultDriver?.user?.id &&
                  parseInt(defaultDriver.user.id, 10) === parseInt(userId, 10);
                const isSameVehicle =
                  defaultDriver?.vehicle?.id &&
                  vehicle?.id &&
                  parseInt(defaultDriver.vehicle.id, 10) === parseInt(vehicle.id, 10);
                return isSameUser && isSameVehicle;
              });
            const userViewData = {
              ...userDetails,
              currentVehicles: (userDetails?.currentVehicles || []).map(currentVehicle => {
                const currentVehicleDefaultDriver = getVehicleDefaultDriver(currentVehicle);
                return currentVehicleDefaultDriver
                  ? {
                      ...currentVehicle,
                      [DEFAULT_DRIVER_FORM_FIELD_NAME]: currentVehicleDefaultDriver.user.id
                    }
                  : currentVehicle;
              })
            };
            resolve({
              error: false,
              userViewData
            });
            callback({
              error: false,
              userViewData
            });
          });
        } else {
          resolve({
            error: false,
            userViewData: userDetails
          });
          callback({
            error: false,
            userViewData: userDetails
          });
        }
      }
    });
  });
};

const fetchDefaulDriverByUserId = async (userId, authKey) => {
  return api
    .get(`/users/${userId}/vehicles`, { authKey })
    .then(response => response?.body || [])
    .catch(err => []);
};
