import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks
import { useDispatch } from 'react-redux';
import {
  useCompanies,
  useCurrentCompany,
  useRedirectToMainFeaturePageOnCompanyChange,
  useIsCompanyKeyDifferent
} from 'features/company/companySlice';
import { useUserInfo } from 'features/user/userSlice';
import {
  useRoles,
  useIsFetching as useIsRolesFetching,
  useSpecificRole,
  usePermissionsMetadata,
  useEntityPermissionsMetadata,
  useModulePermissionsMetadata
} from 'features/roles/rolesSlice';
import { useServicePermissons, useEntityPermissons } from 'features/permissions/permissionsSlice';
import { useHistory } from 'react-router';
// Methods
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { addRole, updateRole } from 'features/roles/rolesSlice';
import { uniqBy, sortBy, trim } from 'lodash';
import { filterEntityPermissionsBySubjectName, findIndexPermissionByName } from './helpers';
import { isSuperAdmin } from 'utils/permissions';
import { canHistoryGoBack } from 'utils/methods';

// Components
import {
  initialAddFormValues,
  PermissionsTypes,
  PermissionsCriteria,
  ReservedPermissionTags,
  PATHS
} from './constants';
import EntityPermissions from './EntityPermissions';
import { EntityTable } from './EntityTable';
import { ModuleTable } from './ModuleTable';
import { cache } from './CellRenderers';
import { Form, Checkbox, Row, Col, Tabs } from 'antd';
import { Input, Select, Button } from 'components/ant';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import MultipleSelectTable from 'components/multipleSelectTable/MultipleSelectTable';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';

// Styles
import styles from './Roles.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const RolesForm = ({ action }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companies = useCompanies();
  const currentCompany = useCurrentCompany();
  const roles = useRoles();
  const isRolesFetching = useIsRolesFetching();
  const history = useHistory();
  const [rolesForm] = Form.useForm();
  //For edit and copy
  const path = window.location.pathname;
  const roleId = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.Default);
  }, [history]);
  const _rawRoleData = useSpecificRole(roleId, handleFetchError);
  const roleData = useMemo(
    () =>
      _rawRoleData && {
        ..._rawRoleData,
        companyId: action === 'copy' ? currentCompany?.id : _rawRoleData.companyId,
        permissions: _rawRoleData?.permissions?.filter(p =>
          ReservedPermissionTags.every(reservedTag => reservedTag !== p?.tag)
        )
      },
    [_rawRoleData, action, currentCompany]
  );
  //All permissions from API
  const permissionsMetadata = usePermissionsMetadata();
  const _rawEntityGlobalPermissions = useEntityPermissionsMetadata();
  const entityGlobalPermissions = useMemo(
    () =>
      _rawEntityGlobalPermissions.filter(p =>
        ReservedPermissionTags.every(reservedTag => reservedTag !== p?.tag)
      ),
    [_rawEntityGlobalPermissions]
  );
  const moduleGlobalPermissions = useModulePermissionsMetadata();
  const currentUser = useUserInfo();
  const canSeeGlobalCheckbox = isSuperAdmin(currentUser, currentCompany);

  // USESTATE Variables
  const [entitySubjects, setEntitySubjects] = useState([]);
  //For Entity Actions Permissions screen
  const [entityActionsName, setEntityActionsName] = useState('');
  const [entityActionsIndex, setEntityActionsIndex] = useState(0);
  const [entityActionsPermissions, setEntityActionsPermissions] = useState([]);
  const [entityActionsMetadata, setEntityActionsMetaData] = useState([]);

  const [modules, setModules] = useState([]);
  const [assignedFromRoles, setAssignedFromRoles] = useState([]);
  const [selectedAssignedFromRoleIds, setSelectedAssignedFromRoleIds] = useState([]);
  const [nonAssignableFromFeatures, setNonAssignableFromFeatures] = useState([]);
  const [tableRef, setTableRef] = useState(null);
  const [temporaryRole, setTemporaryRole] = useState({ permissions: [] });
  const [activePermissionsScreen, setActivePermissionsScreen] = useState();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const userEntityPermissions = useEntityPermissons();
  const userServicePermissions = useServicePermissons();

  const roleName = Form.useWatch('name', rolesForm);
  const companyId = Form.useWatch('companyId', rolesForm);
  const global = Form.useWatch('global', rolesForm);
  const description = Form.useWatch('description', rolesForm);

  const hasCompanyChanged = useIsCompanyKeyDifferent('roles');
  useRedirectToMainFeaturePageOnCompanyChange('/settings/roles');

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    switch (action) {
      case 'edit':
        dispatch(setPageTitle(roleData && `${t('Roles.Form.EditTitle')} ${roleData.name}`));
        break;
      case 'copy':
        dispatch(setPageTitle(roleData && `${t('Roles.Form.CopyTitle')} ${roleData.name}`));
        break;
      default:
        dispatch(setPageTitle(t('Roles.Form.AddNewRoleTitle')));
        return;
    }
  }, [action, roleData, dispatch]);

  useEffect(() => {
    const entityPermissions = temporaryRole.permissions?.filter(
      per => per.type === PermissionsTypes.Entity
    );
    const modulePermissions = temporaryRole.permissions.filter(
      per => per.type === PermissionsTypes.Module
    );
    const entitySubjectsNames = uniqBy(entityGlobalPermissions, PermissionsCriteria.Subject)
      .map(per => per.subject)
      .sort();

    const entitySubjectObjects = entitySubjectsNames.map(entityName => {
      return {
        name: entityName,
        permissions:
          entityPermissions
            .filter(permission => permission.subject === entityName)
            .map(permission => permission.action) || [],
        maxNumberPermissions: filterEntityPermissionsBySubjectName(
          entityGlobalPermissions,
          entityName
        )?.length,
        viewPermissions: activePermissionsScreen === entityName
      };
    });

    const moduleObjects = moduleGlobalPermissions
      .map(moduleGlobalPermission => {
        return {
          name: moduleGlobalPermission.name,
          isChecked: modulePermissions.find(module => module.name === moduleGlobalPermission.name)
            ? true
            : false,
          // Only enable if user has access to this permission (or is siteAdmin)
          isEnabled: userServicePermissions.includes(moduleGlobalPermission.tag)
        };
      })
      .sort((a, b) => a.name?.localeCompare(b.name));

    setEntitySubjects(entitySubjectObjects);
    setModules(moduleObjects);
  }, [dispatch, temporaryRole, activePermissionsScreen, permissionsMetadata]);

  useEffect(() => {
    const activeSubject = entitySubjects.find(subject => subject.viewPermissions);
    const activeSubjectIndex = entitySubjects.findIndex(subject => subject.viewPermissions);
    if (activeSubject?.name && activeSubjectIndex !== -1) {
      setEntityActionsName(activeSubject.name);
      setEntityActionsIndex(activeSubjectIndex);
      setEntityActionsPermissions(activeSubject.permissions || []);
      const activeSubjectActionsMetadata = entityGlobalPermissions
        .filter(per => per.subject === activeSubject.name)
        .map(per => ({
          ...per,
          isEnabled: userEntityPermissions.includes(per.tag)
        }));
      setEntityActionsMetaData(activeSubjectActionsMetadata);
    }
  }, [entitySubjects]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [entitySubjects, tableRef]);

  // METHODS
  const onGlobalStatusChange = e => {
    rolesForm.setFieldValue('global', e?.target?.checked);

    // Force company Id to be 1 (root copmany), in some envs it is Transtech, in others it is Teletrac Navman
    rolesForm.setFieldValue('companyId', 1);

    onFormChanged(null);
  };

  const handleClickPermissions = name => {
    const entitySubjectsModifiedView = entitySubjects.map(entity => {
      if (entity.name === name) {
        entity.viewPermissions = !entity.viewPermissions;
      } else {
        entity.viewPermissions = false;
      }
      return entity;
    });
    setEntitySubjects(entitySubjectsModifiedView);
  };

  const handleClickEntity = (e, userClick) => {
    const temporaryRoleCopy = { ...temporaryRole };
    const { checked, entityName, permissionName } = e.target;
    if (checked) {
      temporaryRoleCopy.permissions.push({
        type: PermissionsTypes.Entity,
        subject: entityName,
        action: permissionName
      });
    } else {
      const unCheckedIndex = findIndexPermissionByName(
        temporaryRoleCopy.permissions,
        PermissionsTypes.Entity,
        { subject: entityName, action: permissionName }
      );
      temporaryRoleCopy.permissions.splice(unCheckedIndex, 1);
    }
    setActivePermissionsScreen(entityName);
    setTemporaryRole(temporaryRoleCopy);
    if (userClick) {
      onFormChanged(null);
    }
  };

  const handleClickAllEntity = e => {
    const temporaryRoleCopy = { ...temporaryRole };
    const { checked, entityName } = e.target;
    //Get the permission names from the global permissions metadata
    const permissionNames = filterEntityPermissionsBySubjectName(
      entityGlobalPermissions,
      entityName
    ).map(per => per.action);
    if (checked) {
      permissionNames.forEach(name => {
        const newPermission = {
          type: PermissionsTypes.Entity,
          subject: entityName,
          action: name
        };
        const permissionAlreadyExists = temporaryRoleCopy.permissions.find(
          per => per.subject === entityName && per.action === name
        );
        if (!permissionAlreadyExists) {
          temporaryRoleCopy.permissions.push(newPermission);
        }
        return newPermission;
      });
    } else {
      permissionNames.forEach(name => {
        const unCheckedIndex = findIndexPermissionByName(
          temporaryRoleCopy.permissions,
          PermissionsTypes.Entity,
          { subject: entityName, action: name }
        );
        temporaryRoleCopy.permissions.splice(unCheckedIndex, 1);
      });
    }
    setActivePermissionsScreen(entityName);
    setTemporaryRole(temporaryRoleCopy);
    onFormChanged(null);
  };

  const handleClickModule = (e, userClick) => {
    const { checked, nameModule } = e.target;
    const temporaryRoleCopy = { ...temporaryRole };
    if (checked) {
      const newPermission = {
        type: PermissionsTypes.Module,
        name: nameModule
      };
      temporaryRoleCopy.permissions.push(newPermission);
    } else {
      const unCheckedIndex = findIndexPermissionByName(
        temporaryRoleCopy.permissions,
        PermissionsTypes.Module,
        { module: nameModule }
      );
      temporaryRoleCopy.permissions.splice(unCheckedIndex, 1);
    }
    setTemporaryRole(temporaryRoleCopy);
    setActivePermissionsScreen(null);
    if (userClick) {
      onFormChanged(null);
    }
  };

  const onAssignedFromRoleChanged = value => {
    const newSelectedAssignableFromRoleIds = value ? value : [];

    rolesForm.setFieldsValue({ selectedAssignableRoleIds: newSelectedAssignableFromRoleIds });
    setSelectedAssignedFromRoleIds(newSelectedAssignableFromRoleIds);

    onFormChanged(null);
  };

  const onTabRoleClick = () => {
    const entitySubjectsCopy = entitySubjects.map(entity => {
      return { ...entity, viewPermissions: false };
    });
    setEntitySubjects(entitySubjectsCopy);
  };

  const onFormChanged = () => {
    validateForm();
    setIsFormDirty(true);
    setPromptModalWhenLeaving(true);
  };

  const validateForm = async () => {
    try {
      await rolesForm.validateFields();
      setIsSaveEnabled(true);
    } catch (errorInfo) {
      setIsSaveEnabled(errorInfo?.errorFields?.length === 0);
    }
  };

  const checkUserAccess = () => {
    if (!canSeeGlobalCheckbox) {
      if (selectedAssignedFromRoleIds && selectedAssignedFromRoleIds.length > 0) {
        let userHasOneOfTheAFRoles = false;
        selectedAssignedFromRoleIds.forEach(id => {
          const afRoleId = id === -1 && action === 'edit' ? Number(roleId) : id;
          if (currentUser.roles.find(role => role.id === afRoleId)) {
            userHasOneOfTheAFRoles = true;
          }
        });
        return userHasOneOfTheAFRoles;
      }
    }

    return true;
  };

  const handleSave = async () => {
    const valuesFromForm = rolesForm.getFieldsValue();

    if (valuesFromForm.name) {
      valuesFromForm.name = trim(valuesFromForm.name);
    }

    const save = () => {
      if (!temporaryRole.permissions.length) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Roles.ToastMessages.OnePermissionError')
          })
        );
      } else {
        // Check for an edge case where if a user adds a role they don't have assigned to them but have
        // access to as an Assignable From value, warn user they will lose access to this role
        if (!checkUserAccess()) {
          confirmationModal(
            t('Roles.ConfirmationModal.AreYouSure'),
            t('Roles.ConfirmationModal.WillLoseAccessWarning'),
            t('Common.Modal.Confirm'),
            t('Common.Modal.Cancel'),
            () => executeSave(valuesFromForm)
          );
        } else {
          // Check for an edge case where if a user adds a role they don't have assigned to them but have
          // access to as an Assignable From value, warn user they will lose access to this role
          if (!checkUserAccess()) {
            confirmationModal(
              t('Roles.ConfirmationModal.AreYouSure'),
              t('Roles.ConfirmationModal.WillLoseAccessWarning'),
              t('Common.Modal.Confirm'),
              t('Common.Modal.Cancel'),
              () => executeSave(valuesFromForm)
            );
          } else {
            executeSave(valuesFromForm);
          }
        }
      }
    };

    if (action === 'edit' && valuesFromForm?.global) {
      confirmationModal(
        t('Roles.ConfirmationModal.AreYouSure'),
        t('Roles.ConfirmationModal.AreYouSureEditGlobalRole'),
        t('Common.Yes'),
        t('Common.Modal.Cancel'),
        () => save()
      );
    } else {
      save();
    }
  };

  const executeSave = valuesFromForm => {
    submitData(valuesFromForm);
    canHistoryGoBack(history, '/settings/roles');
    setPromptModalWhenLeaving(false);
  };

  const submitData = values => {
    // Create the format for the permissions array in the payload

    const permissionsForPayload = temporaryRole.permissions.reduce(
      (accPermissions, currentPermission) => {
        if (currentPermission.type === PermissionsTypes.Entity) {
          //Fing the key for the entity UI name
          const globalEntity = entityGlobalPermissions.find(
            entity =>
              entity.subject === currentPermission.subject &&
              entity.action === currentPermission.action
          );
          if (globalEntity) {
            accPermissions.push({
              type: currentPermission?.type,
              tag: globalEntity?.tag
            });
          }
        } else {
          //Fing the key for the module UI name
          const globalModule = moduleGlobalPermissions.find(
            module => module.name === currentPermission.name
          );
          if (globalModule) {
            accPermissions.push({
              type: currentPermission?.type,
              tag: globalModule?.tag
            });
          }
        }
        return accPermissions;
      },
      []
    );

    let roleToSave = {
      name: values.name,
      companyId: values.companyId,
      global: values.global,
      description: values.description,
      permissions: permissionsForPayload
    };

    let featuresForPayload = [...nonAssignableFromFeatures];
    selectedAssignedFromRoleIds.forEach(afRoleId => {
      featuresForPayload.push(`AF:${afRoleId}`);
    });
    roleToSave.features = featuresForPayload;

    if (action !== 'edit') {
      dispatch(addRole(roleToSave));
    } else {
      dispatch(updateRole(roleId, roleToSave));
    }
  };

  // EDIT
  useEffect(() => {
    if (roleId && roleData) {
      //Set entity permissions
      const entityPermissions =
        roleData.permissions?.filter(permission => permission.type === PermissionsTypes.Entity) ||
        [];
      const entityPermissionsObjects = entityPermissions.map(permission => {
        return {
          target: {
            checked: true,
            entityName: permission.subject,
            permissionName: permission.action
          }
        };
      });
      entityPermissionsObjects.forEach(permission => {
        handleClickEntity(permission);
      });
      setActivePermissionsScreen(null);
      //Set module permissions
      const modulePermissions =
        roleData.permissions?.filter(permission => permission.type === PermissionsTypes.Module) ||
        [];
      const modulePermissionsObjects = modulePermissions.map(permission => {
        return { target: { checked: true, nameModule: permission.name } };
      });
      modulePermissionsObjects.forEach(permission => {
        handleClickModule(permission);
      });

      rolesForm.setFieldsValue({
        ...initialAddFormValues,
        name: `${action === 'copy' ? `${t('Common.Copy of')} ` : ''}${roleData.name}`,
        companyId: roleData.companyId,
        global: canSeeGlobalCheckbox && action !== 'copy' ? roleData.global : false,
        description: roleData.description.replace('NextGen', 'TN360')
      });

      if (action === 'copy') {
        validateForm();
        setIsFormDirty(true);
      }
    }
  }, [roleData, roleId, action, dispatch]);

  useEffect(() => {
    // useEffect for initial load of roleData to convert AF:<id> flags to selectedAssignedFromRoles
    let newSelectedAssignableFromRoleIds = [];
    let nonAssignedFromFeatures = [];

    // Get all selected AF roles if set in features array
    if (roleData && roleData.features && roleData.features.length > 0) {
      roleData.features.forEach(feature => {
        const parts = feature.split(':');
        if (parts && parts.length > 1 && parts[0] === 'AF') {
          const selectedRoleId = Number(parts[1]);
          newSelectedAssignableFromRoleIds.push(selectedRoleId);
        } else {
          // Save all non "AF" items from features array to save later so that data is not overwritten
          nonAssignedFromFeatures.push(feature);
        }
      });
    }

    rolesForm.setFieldsValue({ selectedAssignableRoleIds: newSelectedAssignableFromRoleIds });
    setSelectedAssignedFromRoleIds(newSelectedAssignableFromRoleIds);
    setNonAssignableFromFeatures(nonAssignedFromFeatures);
  }, [roleData]);

  useEffect(() => {
    // useEffect for initializing assignedFromRoles and updating it and other items when companyId and global changes
    if (roles && companyId) {
      let newSelectedAssignableFromRoleIds = [...selectedAssignedFromRoleIds];

      // Remove all roles except for global and selected company roles
      let filteredRoles = roles.filter(role => {
        return (
          (action !== 'edit' || role.id !== Number(roleId)) &&
          (role.global ||
            (role.companyId === companyId && !global) ||
            (role.name?.toLowerCase() === 'site admin' && role.companyId === 1))
        );
      });

      // Always add item to represent current role to the list with id -1
      filteredRoles.push({
        id: -1,
        name: `${roleName} (${t('Roles.Form.CurrentRole')})`,
        companyId: companyId,
        description: description
      });

      // If companyId/global changed, remove any assigned from roles that are no longer there (previous company)
      newSelectedAssignableFromRoleIds = newSelectedAssignableFromRoleIds.filter(afRoleId => {
        return !!filteredRoles.find(role => role.id === afRoleId);
      });

      const getCompanyName = role => {
        if (role.global) {
          return 'Global';
        }
        if (role.companyId) {
          const companyRole = companies.find(comp => comp.id === role.companyId);
          return companyRole ? companyRole.name : '';
        }
        return '';
      };

      // Sort by name then company
      const rolesSortedByName = sortBy(filteredRoles, [role => role.name.toLowerCase()]);
      const sorterByCompanyRoles = sortBy(rolesSortedByName, [role => getCompanyName(role)]);

      let newAssignableFromRoles = sorterByCompanyRoles.map(role => {
        return {
          id: role.id,
          firstColumnValue: role.name,
          secondColumnValue: getCompanyName(role),
          thirdColumnValue: role.description
        };
      });

      rolesForm.setFieldsValue({ selectedAssignableRoleIds: newSelectedAssignableFromRoleIds });
      setSelectedAssignedFromRoleIds(newSelectedAssignableFromRoleIds);
      setAssignedFromRoles(newAssignableFromRoles);
    }
  }, [roleId, action, roles, companyId, global, roleName, description]);

  return (
    <div className={styles.rolesFormContainer}>
      <EditRouteGuard when={promptModalWhenLeaving && !hasCompanyChanged} navigate={history.push} />
      <Form
        form={rolesForm}
        initialValues={initialAddFormValues(currentCompany?.id)}
        layout="vertical"
        name="rolesForm"
        onFinish={handleSave}
        onValuesChange={onFormChanged}
      >
        <div className={styles.form__details}>
          <Row className={styles.form__row}>
            <Col span={canSeeGlobalCheckbox ? 11 : 12}>
              <Form.Item
                name="name"
                labelAlign="left"
                label={t('Roles.Form.Name')}
                colon={false}
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: t('Roles.FormValidation.RoleNameRequired'),
                    whitespace: true
                  },
                  {
                    max: 50,
                    message: t('Roles.FormValidation.RoleNameTooLong')
                  }
                ]}
              >
                <Input size="middle" placeholder="" />
              </Form.Item>
            </Col>
            <Col span={canSeeGlobalCheckbox ? 11 : 12}>
              <Form.Item noStyle shouldUpdate>
                {() => (
                  <Form.Item
                    name="companyId"
                    labelAlign="left"
                    required
                    colon={false}
                    label={t('Roles.Form.Company')}
                  >
                    <Select
                      size="middle"
                      placeholder=""
                      data={companies.map(company => ({
                        id: company.id,
                        label: company.name
                      }))}
                      disabled={rolesForm.getFieldValue('global') || action === 'edit'}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            </Col>
            {canSeeGlobalCheckbox && (
              <Col span={2}>
                <Form.Item noStyle shouldUpdate>
                  {() => (
                    <Form.Item name="global">
                      <GlobalCheckBox
                        className={styles.form__checkbox}
                        label="Global"
                        name="global"
                        checked={rolesForm.getFieldValue('global')}
                        disabled={action === 'edit'}
                        onChange={onGlobalStatusChange}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row className={styles.form__row}>
            <Col span={24}>
              <Form.Item
                name="description"
                labelAlign="left"
                colon={false}
                label={t('Roles.Form.Description')}
              >
                <Input.TextArea
                  className={styles.form__description}
                  rows={4}
                  size="middle"
                  placeholder=""
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.form__row}>
            <Col span={24}>
              <Form.Item noStyle shouldUpdate>
                {() => (
                  <MultipleSelectTable
                    form={rolesForm}
                    selected={selectedAssignedFromRoleIds}
                    options={assignedFromRoles}
                    properties={{
                      name: 'selectedAssignableRoleIds',
                      label: t('Roles.Form.AssignableFrom'),
                      mode: 'multiple',
                      placeholder: t('Common.All'),
                      labelCol: {
                        offset: 0,
                        span: 24
                      },
                      wrapperCol: {
                        offset: 0,
                        span: 24
                      },
                      columnHeaderLabels: {
                        firstColumnValue: t('Common.Name'),
                        secondColumnValue: t('Common.Company'),
                        thirdColumnValue: t('Common.Description')
                      }
                    }}
                    isLoading={isRolesFetching}
                    onChange={onAssignedFromRoleChanged}
                    size="large"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div className={styles.form__permissions}>
          <h5 className={styles.form__heading}>{t('Roles.Form.Permissions')}</h5>
          {entitySubjects.find(subject => subject.viewPermissions) && (
            <EntityPermissions
              permissions={entityActionsPermissions}
              permissionsMetadata={entityActionsMetadata}
              index={entityActionsIndex}
              handleClickEntity={e => handleClickEntity(e, true)}
              handleClickAllEntity={handleClickAllEntity}
              entityName={entityActionsName}
            />
          )}
          <Tabs
            type="line"
            animated={false}
            size="large"
            onTabClick={onTabRoleClick}
            items={[
              {
                label: t('Roles.EntityTable.Entity'),
                key: 'entity',
                children: (
                  <div style={{ height: `${(entitySubjects.length || 3) * 60 + 72}px ` }}>
                    <EntityTable
                      minHeight={(entitySubjects.length || 3) * 60 + 72}
                      subjects={entitySubjects || []}
                      setTableRef={setTableRef}
                      handleClickPermissions={handleClickPermissions}
                      isForm
                      buttonActionText={t('Roles.EntityTable.Give')}
                    />
                  </div>
                )
              },
              {
                label: t('Roles.EntityTable.Module'),
                key: 'module',
                children: (
                  <div style={{ height: `${(modules.length || 3) * 60 + 72}px ` }}>
                    <ModuleTable
                      minHeight={(modules.length || 3) * 60 + 72}
                      modules={modules || []}
                      setTableRef={setTableRef}
                      handleClickModule={e => handleClickModule(e, true)}
                      isForm
                    />
                  </div>
                )
              }
            ]}
          />
        </div>
        <div className={styles.formFooter}>
          <Button
            size="large"
            type="primary"
            disabled={!(isFormDirty && isSaveEnabled)}
            htmlType="submit"
            id={BUTTON_IDS.roleFormSave}
          >
            {t('Common.SaveButton')}
          </Button>
          <Button size="large" id={BUTTON_IDS.roleFormCancel} onClick={history.goBack}>
            {t('Common.CancelButton')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default RolesForm;

function GlobalCheckBox({ checked, onChange, ...props }) {
  const { t } = useTranslation();

  const handleChecked = useCallback(
    e => {
      if (checked) {
        onChange(e);
      } else {
        confirmationModal(
          t('Roles.ConfirmationModal.AreYouSure'),
          t('Roles.ConfirmationModal.AreYouSureRoleGlobal'),
          t('Common.Yes'),
          t('Common.Modal.Cancel'),
          () => onChange(e)
        );
      }
    },
    [onChange, checked]
  );

  return (
    <Checkbox {...props} checked={checked} onChange={handleChecked}>
      {t('Users.Table.Global')}
    </Checkbox>
  );
}
