/* global google */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import Map, { MapMode } from 'components/map/Map';
import { Row, Col, Button, Modal, Input } from 'antd';
import { Loading } from 'components/loading/Loading';
import { ApiClient, PositionsApi } from 'nextgen_api';
import { API_PATH } from 'config';
import { GOOGLE_MAPS_API_KEY } from 'config';
import { DriverSearchInput } from 'features/eld/DriverInputComponent';
import { AssignLogModalMode, getModalButtonText } from './AssignLogModal';
import { UDTStatusCategory } from 'containers/ELD/Constants';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { useUser } from 'features/user/userSlice';
import { useDrivers } from 'features/users/usersSlice';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { updateUDT } from 'features/eld/udtSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useGeofences } from 'features/geofences/geofencesSlice';
import { useCompanyGeofenceProviders } from 'features/company/companySlice';
import { filterGeofencesByShowTypes } from 'features/geofences/geofencesUtil';
import { useUserKey } from 'features/user/userSlice';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useTranslation } from 'react-i18next';
import { showGeofenceType } from 'containers/Settings/constants';
import { ReactComponent as ChevronLeft } from 'static/images/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'static/images/icons/chevron-right.svg';
import styles from './UDTRoutePreviewModal.module.scss';
import InfoRow from 'components/form/info-row/InfoRow';
import { BUTTON_IDS } from 'utils/globalConstants';
import { saveSuggestedUDT } from 'features/eld/eventsApi';

const getDurationDisplayValue = duration => {
  const durationDisplayValue =
    duration == null
      ? ''
      : Math.floor(duration / 3600)
          .toString()
          .padStart(2, 0) +
        ':' +
        (Math.floor(duration / 60) % 60).toString().padStart(2, 0) +
        ':' +
        Math.floor(duration % 60)
          .toString()
          .padStart(2, 0);
  return durationDisplayValue;
};

const RoutePreviewInfoRow = ({ key, label, value }) => {
  return (
    <InfoRow
      key={key}
      label={label}
      value={value}
      styles={styles}
      labelWidth="35%"
      sxValue={{ width: 180 }}
    />
  );
};

export function UDTRoutePreviewModal({
  visible,
  isDriverPortal,
  rowData,
  onPrevious,
  onNext,
  onUpdate,
  onClose,
  ...props
}) {
  const drivers = useDrivers();
  const [routeGPS, setRouteGPS] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userKey = useUserKey();
  const userPreferences = useUserPreferences();
  const dispatch = useDispatch();
  const companyKey = useCurrentCompanyKey();
  const user = useUser();
  const geofences = useGeofences();
  const geofenceProviders = useCompanyGeofenceProviders();
  const mapRef = useRef();
  const localization = useLocalization();
  const { t } = useTranslation();
  const [assignedDriverId, setAssignedDriverId] = useState(rowData?.assignedUser?.id);
  const [comments, setComments] = useState(rowData?.comments);
  const [dataChanged, setDataChanged] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [commented, setCommented] = useState(false);
  const assignedToCurrentUser = assignedDriverId && assignedDriverId === user.id;
  const mode = useMemo(() => {
    let newMode = AssignLogModalMode.EditComment;

    if (
      [UDTStatusCategory['U'], UDTStatusCategory['C'], UDTStatusCategory['D']].indexOf(
        rowData?.status
      ) >= 0
    ) {
      // Use edit comment if there is no assigned driver Id yet, else use AssignLog or DriverPortal
      if (isDriverPortal) {
        newMode = AssignLogModalMode.DriverPortal;
      } else if (changesMade) {
        newMode = AssignLogModalMode.EditComment;
      } else {
        newMode = AssignLogModalMode.AssignLog;
      }
    } else if (UDTStatusCategory['P'].indexOf(rowData?.status) >= 0) {
      newMode = isDriverPortal
        ? assignedToCurrentUser === true
          ? AssignLogModalMode.Accept
          : AssignLogModalMode.None
        : AssignLogModalMode.EditComment;
    } else if (UDTStatusCategory['A'].indexOf(rowData?.status) >= 0) {
      newMode = AssignLogModalMode.UndoAssignment;
    }

    return newMode;
  }, [rowData, assignedDriverId, comments, changesMade]);

  const driverName = useMemo(() => {
    const driver = drivers?.find(d => d.id === user?.id);
    if (driver != null) {
      return driver.firstName + ' ' + driver.lastName;
    }
    return '';
  }, [user, drivers]);

  const handleUpdate = useCallback(() => {
    // Similar update code to AssignLogModal to handle all the cases
    setIsProcessing(true);
    let updateDispatch = null;
    if (mode === AssignLogModalMode.AssignLog) {
      updateDispatch = dispatch(
        updateUDT(rowData?.id, companyKey, {
          Status: 'P',
          Comments: comments,
          AssignedUserId: assignedDriverId,
          UpdatedBy: user.id
        })
      );
    } else if (mode === AssignLogModalMode.DriverPortal) {
      updateDispatch = dispatch(
        updateUDT(rowData?.id, companyKey, {
          Status: 'A',
          Comments: comments,
          UpdatedBy: user.id,
          AssignedUserId: user.id
        })
      );
    } else if (mode === AssignLogModalMode.EditComment) {
      updateDispatch = dispatch(
        updateUDT(rowData?.id, companyKey, {
          Status: rowData.status === 'Pending' ? 'P' : rowData.status === 'Rejected' ? 'D' : 'C',
          Comments: comments,
          UpdatedBy: user.id,
          ...(['Pending', 'Rejected'].some(s => s === rowData.status) && {
            AssignedUserId: rowData.assignedUser?.id
          })
        })
      );
    } else if (mode === AssignLogModalMode.Accept) {
      updateDispatch = saveSuggestedUDT(userKey, user.id, [
        { id: rowData?.id, action: 'approve', comment: comments }
      ]);
    } else {
      updateDispatch = dispatch(
        updateUDT(rowData?.id, companyKey, {
          Status: 'U',
          Comments: comments,
          UpdatedBy: user.id,
          AssignedUserId: ''
        })
      );
    }

    updateDispatch.then(
      data => {
        setIsProcessing(false);
        setCommented(true);
        setChangesMade(true);
        dispatch(
          openToast({ type: ToastType.Success, message: t('ELD.Update Event Succeeded') + '.' })
        );
        if (onUpdate) {
          onUpdate({ udtValues: { driver: assignedDriverId, comments }, udt: rowData, mode: mode });
        }
      },
      error => {
        setIsProcessing(false);
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('ELD.Update Event Failed') + ' ' + error
          })
        );
        if (onClose) {
          onClose();
        }
      }
    );
  }, [onUpdate, onClose, rowData, mode, assignedDriverId, comments, companyKey, user]);

  useEffect(() => {
    if (
      rowData?.device?.id == null ||
      rowData?.device?.id === '' ||
      rowData?.startAt == null ||
      rowData?.endAt == null
    ) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    let cancelRequest = false;

    const deviceApi = new PositionsApi(apiClient);
    new Promise((resolve, reject) => {
      deviceApi.replayEvents(
        rowData?.device?.id,
        {
          from: new Date(rowData?.startAt).toISOString(),
          to: new Date(rowData?.endAt).toISOString(),
          orderBy: 'time_at',
          highDefinition: true
        },
        (err, data, resp) => {
          if (err && (resp == null || resp.status !== 200)) {
            console.error(err);
            reject(err);
          } else {
            resolve(resp.body);
          }
        }
      );
    }).then(
      data => {
        if (cancelRequest) {
          return;
        } else {
          setRouteGPS(data.GPS?.sort((a, b) => Number(a?.At) - Number(b?.At)));
          setIsLoading(false);
        }
      },
      error => {
        if (!cancelRequest) {
          setIsLoading(false);
        }
        return;
      }
    );

    return () => {
      cancelRequest = true;
    };
  }, [rowData?.device?.id, rowData?.startAt, rowData?.endAt, userKey]);

  useEffect(() => {
    // If switched record, update and reset data changed state for driver Id and comments
    setAssignedDriverId(rowData?.assignedUser?.id);
    setComments(rowData?.comments);
    setChangesMade(false);
    if (mode === AssignLogModalMode.Accept) {
      setDataChanged(true);
    } else {
      setDataChanged(false);
    }
  }, [rowData]);

  const updateBtnText = getModalButtonText(mode);
  let showUpdateButton = true;
  let allowUpdateComments = true;
  let allowSelectDriver =
    [UDTStatusCategory['A'], UDTStatusCategory['P']].indexOf(rowData?.status) === -1;

  if (isDriverPortal) {
    allowSelectDriver = false;
    if (mode === AssignLogModalMode.UndoAssignment || mode === AssignLogModalMode.None) {
      showUpdateButton = false;
      allowUpdateComments = false;
    } else if (mode === AssignLogModalMode.Accept) {
      if (assignedToCurrentUser === true) {
        showUpdateButton = true;
        allowUpdateComments = true;
      } else {
        showUpdateButton = false;
        allowUpdateComments = false;
      }
    }
  }
  const isAcceptButtonEnabled =
    isDriverPortal &&
    (!comments || comments.length < 4 || comments.length > 60 || !assignedToCurrentUser);
  const footer = (
    <Row>
      {showUpdateButton && (
        <Col>
          <Button
            type="primary"
            disabled={
              !dataChanged ||
              isProcessing ||
              commented ||
              (isAcceptButtonEnabled && isDriverPortal && assignedToCurrentUser) ||
              (!isDriverPortal && !assignedDriverId) ||
              !comments ||
              comments.length < 4 ||
              comments.length > 60
            }
            onClick={handleUpdate}
            id={BUTTON_IDS.udtRoutePreviewUpdate}
          >
            {updateBtnText}
          </Button>
        </Col>
      )}
      <Col>
        <Button disabled={isProcessing} id={BUTTON_IDS.udtRoutePreviewClose} onClick={onClose}>
          {t('Common.Close')}
        </Button>
      </Col>
    </Row>
  );

  function findCenter(gps) {
    if (gps != null && gps.length > 0) {
      let center = gps.reduce(
        (c, g) => {
          c.lat += g.Lat;
          c.lng += g.Lng;
          return c;
        },
        { lat: 0, lng: 0 }
      );
      return { lat: center.lat / gps.length, lng: center.lng / gps.length };
    } else if (
      rowData?.startGps?.valid &&
      rowData.startGps.Lat !== 0 &&
      rowData.startGps.Lng !== 0
    ) {
      const startLocation = { lat: rowData.startGps.Lat, lng: rowData.startGps.Lng };
      return startLocation;
    } else if (rowData?.endGps?.valid && rowData.endGps.Lat !== 0 && rowData.endGps.Lng !== 0) {
      const endLocation = { lat: rowData.endGps.Lat, lng: rowData.endGps.Lng };
      return endLocation;
    }
    return null;
  }

  return (
    <Modal
      className={styles.modalContainer}
      title={t('ELD.Route Preview')}
      open={visible}
      width={1024}
      onCancel={onClose}
      footer={footer}
      okText={t('Common.Close')}
    >
      <div className={styles.navButtonsContainer}>
        <div className={styles.navButton} onClick={onPrevious}>
          {onPrevious && (
            <>
              <ChevronLeft />
              <div className={styles.text}>{t('ELD.Prev').toUpperCase()}</div>
            </>
          )}
        </div>
        <div className={styles.navButton} onClick={onNext}>
          {onNext && (
            <>
              <div className={styles.text}>{t('ELD.Next').toUpperCase(0)}</div>
              <ChevronRight />
            </>
          )}
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.infoRowsContainer}>
          <RoutePreviewInfoRow
            key={'vehicleName'}
            label={t('Common.Vehicle')}
            value={rowData?.vehicleName}
          />
          <RoutePreviewInfoRow
            key={'startTime'}
            label={t('ELD.Start Time')}
            value={rowData?.startTime}
          />
          <RoutePreviewInfoRow key={'endTime'} label={t('ELD.End Time')} value={rowData?.endTime} />
          <RoutePreviewInfoRow
            key={'duration'}
            label={t('ELD.Duration') + ' (' + t('ELD.DurationFormat') + ')'}
            value={getDurationDisplayValue(rowData?.duration)}
          />
          <RoutePreviewInfoRow
            key={'startLocation'}
            label={t('ELD.Start Location')}
            value={rowData?.startLocation}
          />
          <RoutePreviewInfoRow
            key={'endLocation'}
            label={t('ELD.End Location')}
            value={rowData?.endLocation}
          />
          <RoutePreviewInfoRow
            key={'distance'}
            label={t('ELD.Distance') + ' (' + localization.formats.speed.unit.toLowerCase() + ')'}
            value={rowData?.distance}
          />
          {allowSelectDriver && !changesMade ? (
            <>
              <div className={styles.driverLabel}>{t('Common.Driver')}</div>
              <DriverSearchInput
                className={styles.driverSelect}
                mode={AssignLogModalMode.AssignLog}
                disabled={rowData.assignedUser?.id}
                value={assignedDriverId}
                onChange={value => {
                  setAssignedDriverId(value);
                  setDataChanged(true);
                }}
              />
            </>
          ) : (
            <RoutePreviewInfoRow
              key={'driver'}
              label={t('Common.Driver')}
              value={
                isDriverPortal &&
                (mode === AssignLogModalMode.DriverPortal || mode === AssignLogModalMode.None)
                  ? driverName
                  : rowData?.assignedTo
              }
            />
          )}
          {allowUpdateComments ? (
            <>
              <div className={styles.commentsLabel}>{t('ELD.Comments')}</div>
              <Input.TextArea
                className={styles.comments}
                maxLength={60}
                rows={3}
                disabled={isProcessing}
                value={comments}
                placeholder={t('ELD.DriverPortal.CommentLength', { min: 4, max: 60 })}
                onChange={evt => {
                  setComments(evt.target.value);
                  setDataChanged(true);
                  setCommented(false);
                }}
              />
            </>
          ) : (
            <RoutePreviewInfoRow key={'comments'} label={t('ELD.Comments')} value={comments} />
          )}
        </div>
        <div className={styles.mapContainer}>
          {(isLoading || isProcessing) && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '560px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Loading />
            </div>
          )}
          {!isLoading && !isProcessing && (
            <div style={{ height: '560px', width: '100%' }}>
              <Map
                ref={mapRef}
                mode={MapMode.Trip}
                selectedTripSegment={{
                  replay: routeGPS,
                  IgnOnGPS: routeGPS?.[0],
                  IgnOffGPS: routeGPS?.[routeGPS?.length - 1]
                }}
                defaultZoom={12}
                zoom={routeGPS?.length > 0 ? 12 : 6}
                center={findCenter(routeGPS) ?? localization.formats.geocode}
                mapTypeId={google.maps.MapTypeId.ROADMAP}
                mapOptions={{
                  mapTypeId: userPreferences?.mapType || google.maps.MapTypeId.ROADMAP
                }}
                geofences={filterGeofencesByShowTypes(
                  geofences,
                  showGeofenceType(userPreferences),
                  geofenceProviders
                )}
                enableMapMenu={false}
                enableInfoWindowActions={false}
                enableVehicleClustering={false}
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%`, width: `100%` }} />}
                mapElement={<div style={{ height: `100%`, width: `100%` }} />}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
