import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { API_PATH } from 'config';
import request from 'superagent';
import { useCurrentCompanyKey } from 'features/company/companySlice';
import { useEffect, useRef } from 'react';
import { splitDatesIntoChunks, formatDatesToIso } from '../../containers/FBTManager/utils';
import { api } from 'utils/api';

const inspectionChecklists = {
  list: [],
  dvirTemplate: '',
  inspectionTemplates: [],
  inspectionSummary: null,
  meta: {
    lastFetched: null,
    isFetching: false,
    inspectionsLastFetched: null,
    isInspectionsFetching: false,
    error: null,
    isListEmpty: false
  }
};

function startLoading(state, { payload }) {
  state.meta.isFetching = true;
  state.meta.companyKey = payload.companyKey;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.meta.isListEmpty = true;
  state.list = [];
  state.dvirTemplate = '';
  state.meta.companyKey = action.payload.companyKey;
}

function startDVIRInspectionTemplateLoading(state) {
  state.meta.isFetching = true;
}

function loadingDVIRInspectionTemplateFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  state.dvirTemplate = null;
  state.meta.companyKey = action.payload.companyKey;
}
const inspectionChecklistsSlice = createSlice({
  name: 'inspectionChecklists',
  initialState: inspectionChecklists,
  reducers: {
    fetchInspectionChecklistsStart: startLoading,
    fetchInspectionChecklistsSuccess(state, { payload }) {
      state.list = payload.list.sort((a, b) => a.name?.localeCompare(b.name));
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = payload.list.length === 0;
      state.meta.companyKey = payload.companyKey;
    },
    fetchInspectionChecklistsCancelled(state) {
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.isListEmpty = true;
      state.list = [];
      state.dvirTemplate = '';
    },
    fetchInspectionChecklistsFailure: loadingFailed,

    fetchDVIRInspectionTemplateStart: startDVIRInspectionTemplateLoading,
    fetchDVIRInspectionTemplateSuccess(state, { payload }) {
      state.dvirTemplate = payload?.dvirTemplate;
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
      state.meta.companyKey = payload.companyKey;
    },
    fetchDVIRInspectionTemplateFailure: loadingDVIRInspectionTemplateFailed,
    // defs fetching inspection templates
    fetchInspectionTemplatesStart(state) {
      state.meta.isFetching = true;
    },
    fetchInspectionTemplatesSuccess(state, { payload }) {
      state.inspectionTemplates = payload.inspectionTemplates;
      state.meta.isFetching = false;
      state.meta.inspectionsLastFetched = 'now';
      state.meta.error = null;
    },
    fetchInspectionTemplatesFailure(state, action) {
      state.meta.isFetching = false;
      state.meta.inspectionsLastFetched = 'now';
      state.meta.error = action.payload.err;
      state.inspectionTemplates = [];
    },
    //inspection summary
    fetchInspectionSummaryStart(state) {
      state.meta.isInspectionsFetching = true;
    },
    fetchInspectionSummarySuccess(state, { payload }) {
      state.inspectionSummary = payload.inspectionSummary;
      state.meta.isInspectionsFetching = false;
      state.meta.inspectionsLastFetched = 'now';
      state.meta.error = null;
    },
    fetchInspectionSummaryFailure(state, action) {
      state.meta.isInspectionsFetching = false;
      state.meta.inspectionsLastFetched = 'now';
      state.meta.error = action.payload.err;
      state.inspectionSummary = null;
    }
  }
});

const {
  fetchInspectionChecklistsStart,
  fetchInspectionChecklistsSuccess,
  fetchInspectionChecklistsCancelled,
  fetchInspectionChecklistsFailure,
  fetchDVIRInspectionTemplateStart,
  fetchDVIRInspectionTemplateSuccess,
  fetchDVIRInspectionTemplateFailure,
  fetchInspectionTemplatesStart,
  fetchInspectionTemplatesSuccess,
  fetchInspectionTemplatesFailure,
  //inspection summary
  fetchInspectionSummaryStart,
  fetchInspectionSummarySuccess,
  fetchInspectionSummaryFailure
} = inspectionChecklistsSlice.actions;

export const fetchInspectionChecklists = fetchRef => async (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  const userKey = getState().user.current.auth.key;
  const companyId = getState().companies?.current?.id;

  const assignRef = value => {
    if (fetchRef) {
      fetchRef.current = value;
    }
  };

  try {
    dispatch(fetchInspectionChecklistsStart({ companyKey }));
    const getRequest = request('GET', `${API_PATH}/pretripchecklists?company_id=${companyId}`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json');

    assignRef(getRequest);

    getRequest.on('abort', () => {
      assignRef(null);
      dispatch(fetchInspectionChecklistsCancelled());
    });

    const response = await getRequest;
    assignRef(null);
    const { body } = response;
    dispatch(fetchInspectionChecklistsSuccess({ list: body, companyKey }));
    dispatch(fetchDVIRInspectionTemplate());
  } catch (err) {
    console.warn('ERROR', err);
    assignRef(null);
    dispatch(fetchInspectionChecklistsFailure({ err: err.toString(), companyKey }));
  }
};

export const useIsFetching = () => useSelector(state => state.inspectionChecklists.meta.isFetching);
export const useIsInspectionsFetching = () =>
  useSelector(state => state.inspectionChecklists.meta.isInspectionsFetching);

export const useInspectionChecklists = () => {
  const dispatch = useDispatch();
  const inspectionChecklists = useSelector(state => state.inspectionChecklists.list);
  const isFetching = useIsFetching();
  const isListEmpty = useSelector(state => state.inspectionChecklists.meta.isListEmpty);
  const companyKey = useSelector(state => state.inspectionChecklists.meta.companyKey);
  const currentCompanyKey = useCurrentCompanyKey();
  const isCompanyKeyDifferent = (currentCompanyKey && companyKey !== currentCompanyKey) || false;
  const couldFetch = inspectionChecklists.length === 0 && !isFetching && !isListEmpty;

  const fetchRef = useRef(null);

  useEffect(() => {
    if ((couldFetch || isCompanyKeyDifferent) && !fetchRef.current) {
      dispatch(fetchInspectionChecklists(fetchRef));
    }
  }, [dispatch, couldFetch, isCompanyKeyDifferent]);

  useEffect(() => {
    return () => {
      if (fetchRef.current) {
        fetchRef.current.abort();
        fetchRef.current = null;
      }
    };
  }, [currentCompanyKey]);

  return inspectionChecklists;
};

export const fetchDVIRInspectionTemplate = () => (dispatch, getState) => {
  const companyKey = getState().companies.current.api_key;
  const userKey = getState().user.current.auth.key;
  try {
    dispatch(fetchDVIRInspectionTemplateStart());
    request('GET', `${API_PATH}/pretripchecklists/dvirtemplate`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .then(res => {
        dispatch(fetchDVIRInspectionTemplateSuccess({ dvirTemplate: res.body, companyKey }));
      })
      .catch(err => {
        dispatch(fetchDVIRInspectionTemplateFailure({ err: err.toString(), companyKey }));
        console.warn('ERROR', err);
      });
  } catch (err) {
    dispatch(fetchDVIRInspectionTemplateFailure({ err: err.toString(), companyKey }));
  }
};

export const useDVIRInspectionTemplate = () => {
  const dispatch = useDispatch();
  const dvirInspectionTemplate = useSelector(state => state.inspectionChecklists.dvirTemplate);
  const isFetching = useIsFetching();
  if (dvirInspectionTemplate.length === 0 && !isFetching) {
    dispatch(fetchDVIRInspectionTemplate());
  }
  return dvirInspectionTemplate;
};

export default inspectionChecklistsSlice.reducer;

// fetching inspection templates
export const fetchInspectionTemplates = () => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;

  try {
    dispatch(fetchInspectionTemplatesStart());
    const response = await fetch(`${API_PATH}/pretripchecklists/inspectiontemplates`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token token="${userKey}"`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    dispatch(fetchInspectionTemplatesSuccess({ inspectionTemplates: data }));
  } catch (error) {
    console.error('Error fetching inspection templates:', error.message);
    dispatch(fetchInspectionTemplatesFailure({ err: error.message }));
  }
};

export const useNewDVIRInspectionTemplates = () => {
  const isTemplateFetching = useSelector(state => state.inspectionChecklists.meta.isFetching);
  const inspectionTemplates = useSelector(state => state.inspectionChecklists.inspectionTemplates);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!inspectionTemplates || (inspectionTemplates.length === 0 && !isTemplateFetching)) {
      dispatch(fetchInspectionTemplates());
    }
  }, [dispatch, inspectionTemplates, isTemplateFetching]);

  const inspectionTypes = [{ name: 'New Inspection' }];
  inspectionTemplates.forEach((template, i) => {
    inspectionTypes.push({ name: JSON.parse(template).ChecklistName });
  });
  return { inspectionTypes: inspectionTypes, templates: inspectionTemplates };
};

// inspection summary
export const fetchInspectionSummary = (fromDate, toDate) => async (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const companyId = getState().companies?.current?.id;
  const numberOfApiCallsSplit = 8;
  const multipleDatesArray = splitDatesIntoChunks({
    fromDate: fromDate,
    toDate: toDate,
    numberOfApiCallsSplit
  });

  const promiseArray = [];
  multipleDatesArray.forEach(date => {
    promiseArray.push(
      new Promise(async (resolve, reject) => {
        try {
          dispatch(fetchInspectionSummaryStart());
          const resp = await api
            .get('/inspections/summary', {
              authKey: userKey,
              query: {
                company_id: companyId,
                from: formatDatesToIso({ from: date.dateFrom, to: date.dateTo }).from,
                to: formatDatesToIso({ from: date.dateFrom, to: date.dateTo }).to
              }
            })
            .then(response => {
              resolve(response?.body);
            });
        } catch (error) {
          console.error('Error fetching inspection summary:', error.message);
          dispatch(fetchInspectionSummaryFailure({ err: error.message }));
        }
      })
    );
  });
  const response = await Promise.all(promiseArray);
  const data = [].concat(...response);
  dispatch(fetchInspectionSummarySuccess({ inspectionSummary: data }));
};

export const useInspectionSummary = () => {
  const inspectionSummary = useSelector(state => state?.inspectionChecklists.inspectionSummary);
  return inspectionSummary;
};
