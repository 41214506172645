import i18n from 'i18next';
import moment from 'moment';

import { LocalizationUtil } from 'features/localization/localization';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { entities, services } from 'features/permissions';

import { GEOFENCE_TYPES, DefaultCoordinates, Paths, modeGeofenceEntryOptions } from './constants';

import { api } from 'utils/api';
import { getRoundValue } from 'utils/methods';
import { sortStrings, formatForCsv } from 'utils/strings';

import { getSelectedEventTypeNames } from '../../Scorecard/helpers';
import {
  getGeofencePoints,
  isMultiPolygonGeofence,
  isDrawableGeofence,
  getGeofenceShapeType
} from 'features/geofences/geofencesUtil';

// Get the allowed geofence types based on the permissions a user has
const getAllowedTypes = (permissions = [], types = GEOFENCE_TYPES) =>
  types
    .filter(type => (type.permission ? permissions.includes(type.permission) : true))
    .map(allowedType => ({
      value: allowedType.value,
      label: i18n.t(`Common.${allowedType.label}`)
    }));

const getGPSFromRegions = (regions = []) => {
  if (!regions.length) {
    return DefaultCoordinates;
  }

  return regions.reduce((acc, region) => {
    if (region?.code && region?.geocode) {
      const { code, geocode } = region;
      try {
        const parsedGeocode = JSON.parse(geocode);
        acc[code] = {
          Lat: parseFloat(parsedGeocode.lat),
          Lng: parseFloat(parsedGeocode.lng)
        };
      } catch (e) {}
    }

    return acc;
  }, {});
};

const anyOverlap = vertices => {
  const length = vertices.length;
  let intersection,
    result = false;

  for (let i = 0; i < length; i++) {
    const point1A = vertices[i];
    const point2A = vertices[i !== length - 1 ? i + 1 : 0];
    const lineA = { x1: point1A.lat(), y1: point1A.lng(), x2: point2A.lat(), y2: point2A.lng() };

    // NGT-1134 j=i+2 because no need to check for the next adjacent segment.
    // Adjacent segments may sometimes trip up a false overlap because of floating point accuracy weirdness.
    // Note: the final and first segment are technically adjacent although they are considered not sharing a point.
    for (let j = i + 2; j < length; j++) {
      const point1B = vertices[j];
      const point2B = vertices[j !== length - 1 ? j + 1 : 0];
      const lineB = { x1: point1B.lat(), y1: point1B.lng(), x2: point2B.lat(), y2: point2B.lng() };

      intersection = Math.checkLineIntersection(lineA, lineB);
      if (!intersection.x || !intersection.y) {
        result = false;
      } else {
        result = intersection.onLine1 && intersection.onLine2;
      }

      if (result) {
        break;
      }
    }

    if (result) break;
  }

  return result;
};

Math.checkLineIntersection = (line1, line2) => {
  const x1 = line1.x1,
    y1 = line1.y1;
  const x2 = line1.x2,
    y2 = line1.y2;
  const x3 = line2.x1,
    y3 = line2.y1;
  const x4 = line2.x2,
    y4 = line2.y2;

  // if the lines intersect, the result contains the x and y of the intersection (treating the lines as infinite) and booleans for whether line segment 1 or line segment 2 contain the point
  const result = {
    x: null,
    y: null,
    onLine1: false,
    onLine2: false
  };
  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
  if (denominator === 0) {
    return result;
  }

  let a = y1 - y3;
  let b = x1 - x3;
  const numerator1 = (x4 - x3) * a - (y4 - y3) * b;
  const numerator2 = (x2 - x1) * a - (y2 - y1) * b;
  a = numerator1 / denominator;
  b = numerator2 / denominator;

  // if we cast these lines infinitely in both directions, they intersect here:
  result.x = x1 + a * (x2 - x1);
  result.y = y1 + a * (y2 - y1);

  // if line1 is a segment and line2 is infinite, they intersect if:
  if (a > 0 && a < 1) {
    result.onLine1 = true;
  }
  // if line2 is a segment and line1 is infinite, they intersect if:
  if (b > 0 && b < 1) {
    result.onLine2 = true;
  }
  // if line1 and line2 are segments, they intersect if both of the above are true
  return result;
};

const notificationOverlap = dispatch => {
  dispatch(
    openToast({
      type: ToastType.Error,
      message: i18n.t('GeofencesFeature.ToastMessages.IntersectingEdges')
    })
  );
};

const showAllGeofences = ({
  event,
  geofencesForMap,
  shapeDrawn,
  setGeofencesForMap,
  initialGeofencesForMap,
  geofences
}) => {
  event.preventDefault();
  if (geofencesForMap?.length > 1) {
    if (shapeDrawn?.map) {
      setGeofencesForMap([]);
      setGeofencesForMap([
        {
          id: -1,
          shape: {
            type: shapeDrawn.type.toUpperCase(),
            points: shapeDrawn.points
          },
          isEditable: true
        }
      ]);
    } else {
      setGeofencesForMap(initialGeofencesForMap);
    }
  } else {
    setGeofencesForMap(geofences);
  }
};

export const getCanViewScorecardItems = can => {
  // To view Scorecard Exclude events UI elements (Graph, View, Edit) you need:
  //  SCORECARD module permission on user and feature permission on company
  //  SCORECARDADV module permission on user and feature permission on company
  const canViewScorecardItems = can({
    everyService: [services.SCORECARD, services.SCORECARDADV],
    everyCompanyService: [services.SCORECARD, services.SCORECARDADV],
    everyEntity: [entities.SCORECARD]
  });

  return canViewScorecardItems;
};

export const getCanEditExludedScorecardEvents = can => {
  // To edit scorecard excluded events need Scorecard Events -> Update permission on user
  const canEditExludedScorecardEvents = can({
    everyEntity: [entities.SCORECARDEVENT_UPDATE]
  });
  return canEditExludedScorecardEvents;
};

const getPrimaryButton = ({ can, history }) => {
  if (!can({ everyEntity: [entities.GEOFENCE_CREATE] })) {
    return;
  }
  return {
    name: i18n.t('GeofencesFeature.AddNewGeofence'),
    onClick: () => {
      history.push(`${Paths.NEW_GEOFENCE}`);
    }
  };
};

const getMoreButtons = ({
  handleExportExcel,
  handleBulkImport,
  handleExportCSV,
  handleExportGeojson,
  isExportCsvAllowed = true,
  isExportExcelAllowed = true
}) => {
  return [
    {
      name: i18n.t('Common.BulkImport'),
      onClick: handleBulkImport,
      canPermissions: {
        everyEntity: [entities.GEOFENCE_CREATE, entities.BULKIMPORT]
      },
      id: 'btn_bulkImport'
    },
    {
      name: i18n.t('Common.ExportToCSV'),
      onClick: handleExportCSV,
      disabled: !isExportCsvAllowed,
      id: 'btn_exportCsv'
    },
    {
      name: i18n.t('Common.ExportToGeojson'),
      onClick: handleExportGeojson,
      disabled: !isExportCsvAllowed,
      id: 'btn_exportGeojson'
    },
    {
      name: i18n.t('Common.ExporttoExcel'),
      onClick: handleExportExcel,
      disabled: !isExportExcelAllowed,
      id: 'btn_exportExcel'
    }
  ].sort((a, b) => sortStrings(a.name, b.name));
};

const getFilteredLocations = locations =>
  locations.filter(loc => loc.type?.id !== 4 && loc.type?.id !== 12 && loc.id !== -1);

export const helpers = {
  getAllowedTypes,
  getGPSFromRegions,
  anyOverlap,
  notificationOverlap,
  showAllGeofences,
  getCanViewScorecardItems,
  getCanEditExludedScorecardEvents,
  getPrimaryButton,
  getMoreButtons,
  getFilteredLocations
};

export const getGeofenceShape = geofence =>
  isMultiPolygonGeofence(geofence)
    ? i18n.t('GeofencesFeature.Shape.MultiPolygon')
    : getShape(getGeofencePoints(geofence, true));

const getShape = points => {
  if (!points) {
    return '';
  }
  const lenghtPoints = points.length;
  if (lenghtPoints === 361) {
    return i18n.t('GeofencesFeature.Shape.Circle');
  }

  if (lenghtPoints !== 5 && lenghtPoints !== 361) {
    return i18n.t('GeofencesFeature.Shape.Polygon');
  }

  if (lenghtPoints === 5) {
    if (points[0].Lat !== points[1].Lat || points[0].Lng !== points[3].Lng) {
      return i18n.t('GeofencesFeature.Shape.Polygon');
    }

    if (points[1].Lat !== points[0].Lat || points[1].Lng !== points[2].Lng) {
      return i18n.t('GeofencesFeature.Shape.Polygon');
    }

    if (points[2].Lat !== points[3].Lat || points[2].Lng !== points[1].Lng) {
      return i18n.t('GeofencesFeature.Shape.Polygon');
    }

    if (points[3].Lat !== points[2].Lat || points[3].Lng !== points[0].Lng) {
      return i18n.t('GeofencesFeature.Shape.Polygon');
    }
    return i18n.t('GeofencesFeature.Shape.Rectangle');
  }
};

export const prepareFileForExcelExport = data => {
  const {
    filterGeofences,
    companies,
    locations,
    localization,
    dateFormat,
    canViewScorecardItems,
    t
  } = data;
  const rows = filterGeofences
    ? filterGeofences.map(geofence => {
        const company = companies.find(comp => comp.id === geofence.companyId);
        const location = locations.find(loc => loc.id === geofence.location?.id);
        const fleetNames = geofence.fleets.map(fleet => fleet.name).join(', ');

        let row = {
          [i18n.t('ExportToExcel.Name')]: geofence.name || '',
          [i18n.t('ExportToExcel.Company')]: company?.name || '',
          [i18n.t('ExportToExcel.Fleet')]: fleetNames,
          [i18n.t('ExportToExcel.Location')]: location?.name || '',
          [i18n.t('ExportToExcel.Type')]:
            (geofence.type &&
              geofence.type.charAt(0).toUpperCase() + geofence.type.slice(1).toLowerCase()) ||
            '',
          [localization.formats.area.unit === 'mi²'
            ? i18n.t('ExportToExcel.AreaMi')
            : i18n.t('ExportToExcel.AreaKm')]: localization.convertArea(geofence.area_sqm),
          [i18n.t('ExportToExcel.Shape')]:
            isDrawableGeofence(geofence) && getGeofenceShape(geofence),
          [i18n.t('ExportToExcel.SpeedLimit')]:
            !geofence.thresholdSpeed ||
            geofence.thresholdSpeed === '-1' ||
            geofence.thresholdSpeed === -1
              ? i18n.t('GeofencesFeature.View.Disabled')
              : `${
                  localization.formats.speed.unit_per_hour === 'mph'
                    ? getRoundValue(LocalizationUtil.kmtomile(parseFloat(geofence.thresholdSpeed)))
                    : geofence.thresholdSpeed
                } ${localization.formats.speed.unit_per_hour}`,
          [i18n.t('ExportToExcel.SpeedTolerance')]:
            !geofence.offsetSpeed || geofence.offsetSpeed === '-1' || geofence.offsetSpeed === -1
              ? i18n.t('GeofencesFeature.View.Disabled')
              : `${
                  localization.formats.speed.unit_per_hour === 'mph'
                    ? getRoundValue(LocalizationUtil.kmtomile(parseFloat(geofence.offsetSpeed)))
                    : geofence.offsetSpeed
                } ${localization.formats.speed.unit_per_hour}`,
          [i18n.t('ExportToExcel.SpeedDuration')]:
            !geofence.thresholdDurationSpeed ||
            geofence.thresholdDurationSpeed === '-1' ||
            geofence.thresholdDurationSpeed === -1
              ? i18n.t('GeofencesFeature.View.Disabled')
              : `${geofence.thresholdDurationSpeed} sec`,
          [i18n.t('ExportToExcel.Undertime')]:
            geofence.thresholdUndertime === undefined ||
            geofence.thresholdUndertime === '-1' ||
            geofence.thresholdUndertime === -1
              ? i18n.t('GeofencesFeature.View.Disabled')
              : `${geofence.thresholdUndertime / 60} minutes`,
          [i18n.t('ExportToExcel.Overtime')]:
            geofence.thresholdOvertime === undefined ||
            geofence.thresholdOvertime === '-1' ||
            geofence.thresholdOvertime === -1
              ? i18n.t('GeofencesFeature.View.Disabled')
              : `${geofence.thresholdOvertime / 60} minutes`
        };

        if (canViewScorecardItems) {
          const scorecardEventsDisplayString =
            getSelectedEventTypeNames(geofence.features, localization, t)?.join(', ') || '';
          row[i18n.t('Scorecard.ExcludedScorecardEvents')] = scorecardEventsDisplayString;
        }

        row[i18n.t('ExportToExcel.CreatedAt')] = moment(geofence.createdAt).format(dateFormat);
        row[i18n.t('ExportToExcel.UpdatedAt')] = moment(geofence.updatedAt).format(dateFormat);

        return row;
      })
    : [];

  return rows;
};

const getLocalizedValue = ({ localization, value }) => {
  if (localization.formats.speed.unit_per_hour === 'mph') {
    return getRoundValue(LocalizationUtil.kmtomile(parseFloat(value)));
  }
  return value;
};

const findEntityName = ({ data, id }) => data.find(entity => entity.id === Number(id))?.name || '';

const formatCoordinatesForCsv = coordsArray =>
  coordsArray.map(entry => `[${entry.Lat}|${entry.Lng}]`).join('');

// geoJSON needs to have Lng,Lat order
const formatCoordinatesForGeojson = coordsArray => {
  if (!coordsArray?.length) {
    return [];
  }
  return [[...coordsArray.map(entry => [entry.Lng, entry.Lat])]];
};

export const prepareDataForCsvExport = ({
  data,
  locations,
  pretrips,
  documents,
  localization,
  t
}) => {
  const result = data.map(entry => {
    const style = JSON.parse(entry?.style || '{}');
    const driverNotification = JSON.parse(entry?.driverNotification || '[]');
    const ignitionEvent = driverNotification.find(e => e.TriggerEvent === 'IOR');
    const geofenceEntryEvent = driverNotification.find(e => e.TriggerEvent === 'GEO-EN');
    const fleetNames = entry.fleets?.map(f => f.name)?.join('; ');
    const excludedScorecardEvents =
      entry.features &&
      getSelectedEventTypeNames(entry.features, localization)
        ?.toString()
        ?.replace(/,/g, ';');
    return {
      [i18n.t('GeofencesFeature.CSV.GeofenceName')]: formatForCsv(entry.name),
      [i18n.t('GeofencesFeature.CSV.LocationName')]:
        entry?.location?.id &&
        formatForCsv(findEntityName({ data: locations, id: entry.location.id })),
      [i18n.t('GeofencesFeature.CSV.LocationId')]: entry?.location?.id,
      [i18n.t('GeofencesFeature.CSV.Type')]: formatForCsv(entry.type),
      [i18n.t('GeofencesFeature.CSV.FleetNames')]: fleetNames && formatForCsv(fleetNames),
      [i18n.t('GeofencesFeature.CSV.SpeedLimit', {
        unit: localization.formats.speed.unit_pluralize
      })]:
        entry.thresholdSpeed !== -1
          ? getLocalizedValue({ value: entry.thresholdSpeed, localization })
          : '',
      [i18n.t('GeofencesFeature.CSV.Tolerance', {
        unit: localization.formats.speed.unit_pluralize
      })]:
        entry.offsetSpeed !== -1
          ? getLocalizedValue({ value: entry.offsetSpeed, localization })
          : '',
      [i18n.t('GeofencesFeature.CSV.Duration')]:
        entry.thresholdDurationSpeed !== -1 ? entry.thresholdDurationSpeed : '',
      [i18n.t('GeofencesFeature.CSV.SpeedAssist')]: entry.speedAssist ? 'Yes' : 'No',
      [i18n.t('GeofencesFeature.CSV.Undertime')]:
        entry.thresholdUndertime && entry.thresholdUndertime !== -1
          ? entry.thresholdUndertime / 60
          : '',
      [i18n.t('GeofencesFeature.CSV.Overtime')]:
        entry.thresholdOvertime && entry.thresholdOvertime !== -1
          ? entry.thresholdOvertime / 60
          : '',
      [i18n.t('GeofencesFeature.CSV.IgnitionOn')]: ignitionEvent ? 'Yes' : 'No',
      [i18n.t('GeofencesFeature.CSV.IgnitionPTC')]:
        ignitionEvent?.Ptc?.Id &&
        formatForCsv(findEntityName({ data: pretrips, id: ignitionEvent?.Ptc?.Id })),
      [i18n.t('GeofencesFeature.CSV.IgnitionMessage')]:
        ignitionEvent?.Message && formatForCsv(ignitionEvent?.Message),
      [i18n.t('GeofencesFeature.CSV.GeofenceEntry')]: geofenceEntryEvent ? 'Yes' : 'No',
      [i18n.t('GeofencesFeature.CSV.GeofenceEntryPTC')]:
        geofenceEntryEvent?.Ptc?.Id &&
        formatForCsv(findEntityName({ data: pretrips, id: geofenceEntryEvent?.Ptc?.Id })),
      [i18n.t('GeofencesFeature.CSV.GeofenceEntryMessage')]:
        geofenceEntryEvent?.Message && formatForCsv(geofenceEntryEvent?.Message),
      [i18n.t('GeofencesFeature.CSV.GeofenceEntryMode')]: modeGeofenceEntryOptions().find(
        i =>
          i.value.toLowerCase() === geofenceEntryEvent?.Mode?.toLowerCase() ||
          i.key.toLowerCase() === geofenceEntryEvent?.Mode?.toLowerCase()
      )?.key,
      [i18n.t('GeofencesFeature.CSV.GeofenceEntryDocument')]:
        geofenceEntryEvent?.Document?.Id &&
        formatForCsv(findEntityName({ data: documents, id: geofenceEntryEvent?.Document?.Id })),
      [i18n.t('GeofencesFeature.CSV.GeofenceEntryStart')]: geofenceEntryEvent?.ActivePeriods
        ?.All?.[0],
      [i18n.t('GeofencesFeature.CSV.GeofenceEntryStop')]: geofenceEntryEvent?.ActivePeriods
        ?.All?.[1],
      [i18n.t('GeofencesFeature.CSV.ScorecardExcludedEvents')]:
        excludedScorecardEvents && formatForCsv(excludedScorecardEvents),
      [i18n.t('GeofencesFeature.CSV.BoundaryType')]: getGeofenceShapeType(entry),
      [i18n.t('GeofencesFeature.CSV.Radius')]: '',
      [i18n.t('GeofencesFeature.CSV.LatLng')]:
        isDrawableGeofence(entry) && formatCoordinatesForCsv(getGeofencePoints(entry, true)),
      [i18n.t('GeofencesFeature.CSV.StyleColour')]: style.colour,
      [i18n.t('GeofencesFeature.CSV.StyleWeight')]: style.weight
    };
  });

  return result;
};

export const prepareDataForGeojsonExport = ({
  data,
  locations,
  pretrips,
  documents,
  localization,
  t
}) => {
  const features = data.map(entry => {
    const style = JSON.parse(entry?.style || '{}');
    const driverNotification = JSON.parse(entry?.driverNotification || '[]');
    const ignitionEvent = driverNotification.find(e => e.TriggerEvent === 'IOR');
    const geofenceEntryEvent = driverNotification.find(e => e.TriggerEvent === 'GEO-EN');
    const fleetNames = entry.fleets?.map(f => f.name);
    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates:
          isDrawableGeofence(entry) && formatCoordinatesForGeojson(getGeofencePoints(entry, true))
      },
      properties: {
        geofence_name: entry.name,
        location_name:
          entry?.location?.id && findEntityName({ data: locations, id: entry.location.id }),
        location_id: entry?.location?.id,
        type: entry.type,
        fleet_names: fleetNames,
        speed_limit:
          entry.thresholdSpeed !== -1
            ? getLocalizedValue({ value: entry.thresholdSpeed, localization })
            : '',
        tolerance:
          entry.offsetSpeed !== -1
            ? getLocalizedValue({ value: entry.offsetSpeed, localization })
            : '',
        duration: entry.thresholdDurationSpeed !== -1 ? entry.thresholdDurationSpeed : '',
        speed_assist: entry.speedAssist,
        undertime:
          entry.thresholdUndertime && entry.thresholdUndertime !== -1
            ? entry.thresholdUndertime / 60
            : '',
        overtime:
          entry.thresholdOvertime && entry.thresholdOvertime !== -1
            ? entry.thresholdOvertime / 60
            : '',
        ignition_on: ignitionEvent ? true : false,
        ignition_pretrip_checklist:
          ignitionEvent?.Ptc?.Id && findEntityName({ data: pretrips, id: ignitionEvent?.Ptc?.Id }),
        ignition_driver_message: ignitionEvent?.Message,
        geofence_entry: geofenceEntryEvent ? true : false,
        geofence_entry_pretrip_checklist:
          geofenceEntryEvent?.Ptc?.Id &&
          findEntityName({ data: pretrips, id: geofenceEntryEvent?.Ptc?.Id }),
        geofence_entry_driver_message: geofenceEntryEvent?.Message,
        geofence_entry_mode: geofenceEntryEvent?.Mode,
        geofence_entry_document:
          geofenceEntryEvent?.Document?.Id &&
          findEntityName({ data: documents, id: geofenceEntryEvent?.Document?.Id }),
        geofence_entry_active_period_start_time: geofenceEntryEvent?.ActivePeriods?.All?.[0],
        geofence_entry_active_period_end_time: geofenceEntryEvent?.ActivePeriods?.All?.[1],
        scorecard_excluded_events:
          entry.features && getSelectedEventTypeNames(entry.features, localization),
        boundary_type: getGeofenceShapeType(entry),
        style_colour: style.colour,
        style_weight: style.weight
      }
    };
  });

  return {
    type: 'FeatureCollection',
    features: features
  };
};

export const fetchLocationsOnCompanyChange = (company, setFilteredLocations) => async (
  _,
  getState
) => {
  const authKey = getState().user.current.auth.key;

  const urlForLocations = `/locations?direction=DOWN&embed=address,geofences&company_id=${parseInt(
    company,
    10
  )}`;

  try {
    const response = await api.get(urlForLocations, { authKey });
    const { body } = response;
    setFilteredLocations(getFilteredLocations(body));
  } catch (err) {
    console.error(err);
  }
};

export const setLocationFromCoordinateSearch = payload => {
  const { place, setNewLocationFromSearch, dispatch, t } = payload;

  if (isNaN(Number(place.lat)) || isNaN(Number(place.lng))) {
    isNaN(Number(place.lat)) &&
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('GeofencesFeature.ToastMessages.InvalidLatitude'),
          autohide: true
        })
      );
    isNaN(Number(place.lng)) &&
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('GeofencesFeature.ToastMessages.InvalidLongitude'),
          autohide: true
        })
      );
    return;
  }
  setNewLocationFromSearch({ lat: Number(place.lat), lng: Number(place.lng) });
};

export const toActivePeriodFormField = formData => {
  let activeTime = { period: null };
  try {
    activeTime = formData?.activeTime ? JSON.parse(formData?.activeTime) : { period: null };
  } catch (error) {
    activeTime = { period: null };
  }
  return { activeTime };
};

export const toActivePeriodPayload = activeTimeFormField => {
  let activeTime = null;
  try {
    activeTime =
      activeTimeFormField?.period && Object.keys(activeTimeFormField.period).length
        ? JSON.stringify(activeTimeFormField)
        : '';
  } catch (error) {
    activeTime = null;
  }
  return { activeTime };
};

export const downloadFile = ({ data, fileName, fileType = 'application/json' }) => {
  const blob = new Blob([data], { type: fileType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
  link.remove();
};
