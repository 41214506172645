import React from 'react';
import { Link } from 'react-router-dom';
import { formatSecondsToTime } from 'utils/methods';
import { format } from 'utils/dates';
import { getBoundsFromLatLng, getCardinalPointsFromBounds, isLatLngValid } from 'utils/geo';

const getFormattedAddress = address => {
  if (!address) return;

  const { number, street, suburb, state, postcode, country } = address;

  return `${number || ''} ${street ? street + ',' : ''}  ${suburb ? suburb + ',' : ''} ${
    state ? state + ',' : ''
  } ${postcode ? postcode + ',' : ''} ${country || ''}`;
};

const getLocationName = (stop, t) => {
  if (!stop || !stop?.location?.name) return;
  return stop.location.name === 'User Entered'
    ? t('JourneyPlanner.UserEntered')
    : stop.location.name;
};

const getAddressDetails = result => {
  const address = {
    name: 'User Entered',
    address: {
      number:
        result.address_components.find(res => res.types.includes('street_number'))?.long_name || '',
      street: result.address_components.find(res => res.types.includes('route'))?.long_name || '',
      suburb:
        result.address_components.find(res => res.types.includes('locality'))?.long_name || '',
      state:
        result.address_components.find(res => res.types.includes('administrative_area_level_1'))
          ?.long_name || '',
      postcode:
        result.address_components.find(res => res.types.includes('postal_code'))?.long_name || '',
      country: result.address_components.find(res => res.types.includes('country'))?.long_name || ''
    }
  };
  return address;
};

const getLegsDuration = (legs, seconds) => {
  if (!legs) return;
  const durationInSeconds = legs.reduce((acc, obj) => acc + obj.duration.value, 0);
  if (seconds) {
    return durationInSeconds;
  }
  return formatSecondsToTime(durationInSeconds);
};

const getFilteredStops = stops => {
  const filteredStops = stops
    .filter(stop => stop.seq !== 0)
    .map(stop => {
      if (stop.geofence) {
        return {
          ...stop,
          seq: stop.seq.toString(),
          latitude: stop.geofence.centroid.Lat,
          longitude: stop.geofence.centroid.Lng,
          position: {
            lat: stop.geofence.centroid.Lat,
            lng: stop.geofence.centroid.Lng
          },
          formattedAddress: `Geofence: ${stop.geofence.name}`
        };
      }
      return {
        ...stop,
        seq: stop.seq.toString(),
        latitude: stop.location.GPS.Lat,
        longitude: stop.location.GPS.Lng,
        position: {
          lat: stop.location.GPS.Lat,
          lng: stop.location.GPS.Lng
        }
      };
    });
  return filteredStops;
};

const buildDirectionsObject = (legs, latLngArray) => {
  const bounds = getBoundsFromLatLng(latLngArray);
  const cardinalBounds = getCardinalPointsFromBounds(bounds);
  const isRouteDefinitionValid = Array.isArray(JSON.parse(legs[0].routeDefinition));
  if (!isRouteDefinitionValid) {
    return false;
  }
  const directions = {
    routes: [
      {
        bounds: cardinalBounds,
        legs: legs.map(leg => ({
          steps: [...JSON.parse(leg.routeDefinition)].map(step => ({
            ...step,
            ...(step.path &&
              !step.path[0]?.lat && {
                path: step.path.map(oldArray => ({ lat: oldArray[0], lng: oldArray[1] }))
              })
          }))
        }))
      }
    ],
    request: {
      travelMode: 'DRIVING'
    }
  };
  return directions;
};

const computeTotalDistance = route => {
  let total = 0;

  // maybe change this with reduce
  for (let i = 0; i < route.legs.length; i++) {
    total += route.legs[i].distance.value;
  }
  total = parseFloat(total / 1000).toFixed(2);
  return total;
};

const computeTotalDuration = route => {
  let total = 0;

  // maybe change this with reduce
  for (let i = 0; i < route.legs.length; i++) {
    total += route.legs[i].duration.value;
  }
  return total;
};

const numberToLetter = number => {
  const letter = String.fromCharCode(97 + Number(number)).toUpperCase();
  return letter;
};

const getReversedJourneyOrder = waypoints =>
  [...waypoints]
    .filter(w => w.seq)
    .reverse()
    .map((val, index) => ({
      ...val,
      seq: index + 1
    }));

const renderFleetsName = (fleet, companies) => {
  if (!fleet) {
    return;
  }
  if (fleet.id === -1) {
    return fleet?.name || '';
  }
  const fleetCompany = companies?.find(company => company.id === fleet?.company?.id);
  return (
    <Link to={`/settings/fleets/id/${fleet.id}`}>
      {fleet?.name || ''} {fleetCompany?.name ? `(${fleetCompany.name})` : ''}
    </Link>
  );
};

const getCompanyName = (companies, id) => {
  if (!companies?.length) return;
  return companies.find(company => company.id === id)?.name;
};

const getTripAssociations = (userCheckedFleets, initialTripAssociations = []) => {
  // According to TN360WEB-4255, we should not deassociate existing associations, unless we uncheck them in the UI
  // The UI might not display all the fleets, since the user can have hidden associations due to fleet permissions
  // Send to the api the checked fleets + all the previous associated fleets that are not part of the selection
  const initialFleetsWithoutCheckedFleets = initialTripAssociations.reduce((acc, initialFleet) => {
    // add only the fleets that are not already in the multiselect (userCheckedFleets)
    if (!userCheckedFleets.find(checkedFleet => checkedFleet.value === initialFleet.fleetId)) {
      acc.push({ fleetId: initialFleet.fleetId });
    }
    return acc;
  }, []);

  const tripAssociations = userCheckedFleets
    .reduce((accumulator, fleet) => {
      if (fleet.checked) {
        accumulator.push({ fleetId: fleet.value });
      }
      return accumulator;
    }, [])
    .concat(initialFleetsWithoutCheckedFleets);
  return tripAssociations;
};

const format_dby_imp = (entity, property, localization) => {
  if (!entity[property]) return;
  return format(new Date(entity[property]), localization.formats.time.formats.dby_imp);
};

const adjustMapWithWaypoints = (mapRef, waypoints) => {
  const map = mapRef.current?.state?.map,
    bounds = getBoundsFromLatLng(
      waypoints
        ?.filter(place => isLatLngValid(place.latitude, place.longitude))
        .map(place => ({
          lat: place.latitude,
          lng: place.longitude
        }))
    );
  if (map?.fitBounds && bounds) {
    map.fitBounds(bounds);
  }
};

export const journeyHelpers = {
  getFormattedAddress,
  getLocationName,
  getAddressDetails,
  getLegsDuration,
  buildDirectionsObject,
  getFilteredStops,
  computeTotalDistance,
  computeTotalDuration,
  numberToLetter,
  getReversedJourneyOrder,
  renderFleetsName,
  getCompanyName,
  getTripAssociations,
  format_dby_imp,
  adjustMapWithWaypoints
};
