import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useAudits } from 'features/audits';

import { getIDFromPathname } from 'utils/methods';

import { AuditsTable } from 'components/auditsTable/AuditsTable';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';
import { PATHS } from './constants';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { useHistory } from 'react-router';
import { useCurrentCompany } from 'features/company/companySlice';
import { useGetCompanyAgreementsQuery } from 'services/nextgen/ngAgreementApi';

export const CompanyAgreementAudits = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const history = useHistory();
  const handleFetchError = useCallback(() => {
    history.replace(PATHS.AGREEMENT_DEFAULT);
  }, [history]);
  const audits = useAudits(AUDIT_ENTITY.AGREEMENT, id, handleFetchError);
  const currentCompany = useCurrentCompany();
  const { data: agreementData, isFetching } = useGetCompanyAgreementsQuery(
    {
      id: currentCompany?.id,
      embed: 'devices'
    },
    { skip: currentCompany?.id === undefined }
  );
  const agreementDetail = agreementData?.find(d => d.agreements.find(k => k.id === id));
  const pageTitle = t('Audits.AuditHistoryFor', { name: agreementDetail?.workOrder });

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    dispatch(setBackButton(true));
  }, [pageTitle, dispatch]);

  useEffect(() => {
    if (id <= 0 || isNaN(id) || (agreementData?.length > 0 && !agreementDetail)) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Common.Invalid Request ID')
        })
      );
      handleFetchError();
    }
  }, [dispatch, id, agreementData, handleFetchError, agreementDetail]);

  return <AuditsTable audits={audits} entity={AUDIT_ENTITY.AGREEMENT} />;
};
