import React from 'react';
import { Menu, Alert, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';

import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

import styles from './ActionsMenu.module.scss';

export const ActionsMenu = ({ record, entityType, onEdit, onDelete, ...props }) => {
  const { t } = useTranslation();

  const showDeleteConfirmation = (record, onDelete) => {
    confirmationModal(
      `${t('Common.DeleteButton')} ${record.name}`,
      <>
        <div>
          {t('Common.SureDelete')} {entityType} {record.name}?
        </div>
        {(record.vehicles || record.fleets) && (
          <Alert
            style={{
              marginTop: '10px'
            }}
            type="error"
            message={t('MassManagement.Validation.AssociationWarning')}
          />
        )}
      </>,
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      onDelete,
      'delete'
    );
  };

  const actionsMenu = () => {
    const menuItems = [];

    onEdit &&
      menuItems.push({
        key: '1',
        onClick: () => {
          onEdit(record);
        },
        label: t(`Common.Edit`)
      });
    onDelete &&
      menuItems.push({
        key: '2',
        onClick: () => {
          showDeleteConfirmation(record, () => {
            onDelete(record);
          });
        },
        label: <span className={styles.dangerLabel}>{t(`Common.Delete`)}</span>
      });

    return (
      <Menu
        style={{
          width: '150px'
        }}
        className={styles.actionsMenu}
        items={menuItems}
      />
    );
  };

  return (
    <Dropdown overlay={actionsMenu} placement="bottomLeft" trigger="click" {...props}>
      <i style={{ fontSize: '26px' }} className={'tn-i-elipsis'} />
    </Dropdown>
  );
};
