import React, { useState, useEffect, useRef } from 'react';
import {
  stringCellRenderer_ANT,
  easydocVehicleCellRenderer_ANT,
  easydocDeviceCellRenderer_ANT,
  nameCellRenderer_ANT,
  dateCellRenderer_ANT
} from '../CellRenderers';
import { Table } from 'antd';
import { ColumnTypes } from 'components/tn/grid/ViewsGridHeader/Modals/FilterColumnsModal/Filters';
import { useTranslation } from 'react-i18next';
import scrollStyles from '../../ELD/Overview/Overview.module.scss';
import { SortUtil } from 'components/tables/SortUtil';
import { useUser } from 'features/user/userSlice';
import { formatBytes } from 'utils/methods';
import { useLocalization } from 'features/localization/localizationSlice';
import { useCanOneOfEntities } from 'features/permissions/canHooks';

export const rowAction = (id, items, action) => {
  const newCheckedValues = (items || []).includes(id)
    ? items.filter(item => item !== id)
    : [...(items || []), id];
  action(newCheckedValues);
};

export const DevicesTable = ({ devices, setTableRef, checkedItems, setCheckedItems }) => {
  const sortedDevices = devices.slice().sort((a, b) => {
    const nameA = a.device.name.toLowerCase();
    const nameB = b.device.name.toLowerCase();
    return nameA.localeCompare(nameB);
  });

  const { t } = useTranslation();
  const localization = useLocalization();
  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;
  const { tableRef, vtSettings } = useTableResize();
  const allColumns = [
    {
      title: t('Common.Device'),
      key: 'device',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'device.name',
      // fixed: 'left',
      width: 200,
      // isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return easydocDeviceCellRenderer_ANT({ rowData: rowData });
      },
      defaultSortColumn: true,
      sorter: (a, b) => {
        const nameA = a.device.name.toLowerCase();
        const nameB = b.device.name.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['descend', 'ascend', 'descend']
    },
    {
      title: t('Common.Vehicle'),
      key: 'vehicle',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'vehicle.name',
      // fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return easydocVehicleCellRenderer_ANT({ rowData: rowData });
      },
      sorter: (a, b) => {
        const nameA = (a.vehicle?.name || '').toLowerCase();
        const nameB = (b.vehicle?.name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Common.Company'),
      key: 'company',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'company.name',
      // fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData) => `${rowData.company.name}`,
      // defaultSortColumn: false,
      sorter: (a, b) => {
        const nameA = (a.company?.name || '').toLowerCase();
        const nameB = (b.company?.name || '').toLowerCase();
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Common.Fleet'),
      key: 'fleets',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'fleets',
      // fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: rowData => {
        const fleets = rowData?.map(c => c.name).join(', ');
        return stringCellRenderer_ANT(fleets || '');
      },
      defaultSortColumn: false,
      sorter: (a, b) => {
        const nameA = (a.fleets || [])
          .map(f => f.name)
          .join(', ')
          .toLowerCase();
        const nameB = (b.fleets || [])
          .map(f => f.name)
          .join(', ')
          .toLowerCase();
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.documentsInApp'),
      key: 'inAppCount',
      type: ColumnTypes.Number,
      isConfigurable: false,
      dataIndex: 'inAppCount',
      // fixed: 'left',
      width: 200,
      sorter: (a, b) => a.inAppCount - b.inAppCount,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.Uploaded'),
      key: 'uploadedCount',
      type: ColumnTypes.Number,
      isConfigurable: false,
      dataIndex: 'uploadedCount',
      // fixed: 'left',
      width: 150,
      isSearchable: false,
      isFilterable: false,
      sorter: (a, b) => a.uploadedCount - b.uploadedCount,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.Received'),
      key: 'receivedCount',
      type: ColumnTypes.Number,
      isConfigurable: false,
      dataIndex: 'receivedCount',
      // fixed: 'left',
      width: 150,
      isSearchable: false,
      isFilterable: false,
      sorter: (a, b) => a.receivedCount - b.receivedCount,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.Removed'),
      key: 'removedCount',
      type: ColumnTypes.Number,
      isConfigurable: false,
      dataIndex: 'removedCount',
      // fixed: 'left',
      width: 150,
      isSearchable: false,
      isFilterable: false,
      sorter: (a, b) => a.removedCount - b.removedCount,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.lastAccessed'),
      key: 'lastAccessed',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'lastAccessed',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return dateCellRenderer_ANT({
          dateFormat: localization.formats.time.formats.dby_imsp,
          date: rowData.lastAccessed
        });
      },
      sorter: (a, b) => SortUtil.sortDate(a, b, 'lastAccessed'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Common.Driver'),
      key: 'driver',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'driver.firstName',
      // fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData) => {
        return rowData.driver ? `${rowData.driver?.firstName} ${rowData.driver?.lastName}` : '';
      },
      sorter: (a, b) => {
        const nameA = `${(a.driver?.firstName || '').toLowerCase()} ${(
          a.driver?.lastName || ''
        ).toLowerCase()}`;
        const nameB = `${(b.driver?.firstName || '').toLowerCase()} ${(
          b.driver?.lastName || ''
        ).toLowerCase()}`;
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    }
  ];

  if (!isSiteAdmin) {
    allColumns.splice(4, 4);
  }

  return (
    <Table
      className={`${scrollStyles.overviewGrid}`}
      dataSource={sortedDevices}
      showSorterTooltip={false}
      columns={allColumns}
      pagination={false}
      ref={tableRef}
      scroll={vtSettings.scroll}
      components={vtSettings.vt}
    />
  );
};

export const DetailsTable = ({ documents, setTableRef }) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const canViewDocument = useCanOneOfEntities(['DOCUMENT_VIEW']);
  const { tableRef, vtSettings } = useTableResize();

  const allColumns = [
    {
      title: t('Easydocs.DocumentName'),
      key: 'name',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'name',
      // fixed: 'left',
      width: 300,
      isSearchable: true,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return nameCellRenderer_ANT({ rowData: rowData, canViewDocument: canViewDocument });
      },
      defaultSortColumn: 'ascend',
      sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'name'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.DocumentDescription'),
      key: 'description',
      type: ColumnTypes.String,
      //isConfigurable: false,
      dataIndex: 'description',
      //fixed: 'left',
      width: 300,
      isSearchable: true,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return stringCellRenderer_ANT(value || '');
      },
      sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'description'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.Folders'),
      key: 'folders',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'folders',
      //fixed: 'left',
      width: 200,
      isSearchable: true,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        const folders = rowData?.folders?.map(c => c.name).join(', ');
        return stringCellRenderer_ANT(folders || '');
      },
      sorter: (a, b) => SortUtil.sortStringIgnoreCase(a, b, 'folder'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.FileSize'),
      key: 'filesize',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'filesize',
      // fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return rowData.filesize ? formatBytes(rowData.filesize) : '';
      },
      defaultSortColumn: false,
      sorter: (a, b) => SortUtil.sortNumber(a, b, 'filesize'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.Acknowledge'),
      key: 'acknowledge',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'acknowledge',
      // fixed: 'left',
      width: 200,
      render: (value, rowData, rowIndex) => {
        return rowData.acknowledge ? t('Easydocs.True') : t('Easydocs.False');
      },
      //sorter: (a, b) => a.removedCount - b.removedCount,
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.UploadedDate'),
      key: 'uploadedAt',
      type: ColumnTypes.Date,
      isConfigurable: false,
      dataIndex: 'uploadedAt',
      // fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return dateCellRenderer_ANT({
          dateFormat: localization.formats.time.formats.dby_imsp,
          date: rowData.uploadedAt
        });
      },
      sorter: (a, b) => SortUtil.sortDate(a, b, 'uploadedAt'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.ReceivedDate'),
      key: 'receivedAt',
      type: ColumnTypes.Date,
      isConfigurable: false,
      dataIndex: 'receivedAt',
      // fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return dateCellRenderer_ANT({
          dateFormat: localization.formats.time.formats.dby_imsp,
          date: rowData.receivedAt
        });
      },
      sorter: (a, b) => SortUtil.sortDate(a, b, 'receivedAt'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.Columns.expirationDate'),
      key: 'expiredAt',
      type: ColumnTypes.Date,
      isConfigurable: false,
      dataIndex: 'expiredAt',
      // fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return dateCellRenderer_ANT({
          dateFormat: localization.formats.time.formats.dby,
          date: rowData.expiredAt
        });
      },
      sorter: (a, b) => SortUtil.sortDate(a, b, 'expiredAt'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.RemovedDate'),
      key: 'removedAt',
      type: ColumnTypes.Date,
      isConfigurable: false,
      dataIndex: 'removedAt',
      // fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return dateCellRenderer_ANT({
          dateFormat: localization.formats.time.formats.dby_imsp,
          date: rowData.removedAt
        });
      },
      sorter: (a, b) => SortUtil.sortDate(a, b, 'removedAt'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Easydocs.LastRead'),
      key: 'lastReadAt',
      type: ColumnTypes.Date,
      isConfigurable: false,
      dataIndex: 'lastReadAt',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData, rowIndex) => {
        return dateCellRenderer_ANT({
          dateFormat: localization.formats.time.formats.dby_imsp,
          date: rowData.lastReadAt
        });
      },
      sorter: (a, b) => SortUtil.sortDate(a, b, 'lastReadAt'),
      sortDirections: ['ascend', 'descend', 'ascend']
    },
    {
      title: t('Common.Driver'),
      key: 'driver',
      type: ColumnTypes.String,
      isConfigurable: false,
      dataIndex: 'driver.firstName',
      // fixed: 'left',
      width: 200,
      isSearchable: false,
      isFilterable: false,
      render: (value, rowData) => {
        return rowData.driver ? `${rowData.driver?.firstName} ${rowData.driver?.lastName}` : '';
      },
      sorter: (a, b) => {
        const nameA = `${a.driver?.firstName} ${a.driver?.lastName}`.toLowerCase();
        const nameB = `${b.driver?.firstName} ${b.driver?.lastName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      sortDirections: ['ascend', 'descend', 'ascend']
    }
  ];

  return (
    <Table
      className={`${scrollStyles.overviewGrid}`}
      dataSource={documents}
      showSorterTooltip={false}
      columns={allColumns}
      rowKey={'id'}
      pagination={false}
      ref={tableRef}
      scroll={vtSettings.scroll}
      components={vtSettings.vt}
    />
  );
};

export const useTableResize = () => {
  const [tableWidth, setTableWidth] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const tableRef = useRef();

  const [vtSettings, setVTSettings] = useState({
    scroll: {
      y: 600,
      x: 1200
    }
  });

  const handleResize = entries => {
    const { width, height } = entries[0].contentRect;

    // Adjust table width for vertical scrollbar
    const newTableWidth = width > 10 ? width - 10 : width;
    setTableWidth(newTableWidth);

    // Adjust table height for horizontal scrollbar
    const header = tableRef.current.querySelector('.ant-table-thead');
    const headerHeight = header?.getBoundingClientRect()?.height || 0;
    const newTableHeight = height - headerHeight - 16; // Adjust for horizontal scrollbar height
    setTableHeight(newTableHeight);

    const newVTSettings = {
      scroll: {
        y: newTableHeight,
        x: newTableWidth
      }
    };

    setVTSettings(newVTSettings);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    const tableElement = document.querySelector(`.${scrollStyles.overviewGrid}`);
    resizeObserver.observe(tableElement);
    return () => {
      resizeObserver.unobserve(tableElement);
    };
  }, []);

  return { tableRef, vtSettings };
};
