import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

//components
import { Button } from 'antd';
import { Buttons } from 'components/ant';
import { SubCompaniesTable } from './SubCompaniesTable';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import { TabNavLink } from 'components/nav/NavLinks';

import { Can } from 'features/permissions';
import { useLocalization } from 'features/localization/localizationSlice';
import {
  useDeletedSubCompanies,
  useActiveSubCompanies,
  useParentCompanies,
  useIsFetchingDeletedSubCompany,
  useIsFetchingCompany,
  restoreCompanyApi,
  deleteCompanyApi,
  fetchSubCompanies,
  useCurrentCompany
} from 'features/company/companySlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';

//methods & helpers
import { toLower, trim } from 'lodash';
import { prepareDataForMultiselect } from 'utils/filters';
import useDebounce from 'utils/hooks/useDebounce';

//constants
import { PATHS, TABS } from './constants';
import { cache } from './CellRenderers';

//styles
import { BUTTON_IDS } from 'utils/globalConstants';
import entities from 'features/permissions/entities';
import { sortStrings } from 'utils/strings';

export const SubCompanies = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const path = window.location.pathname;
  const filterPath = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const parentCompanies = useParentCompanies();
  const activeSubCompanies = useActiveSubCompanies();

  const localization = useLocalization();
  const [companyList, setCompanyList] = useState([]);
  const [filterTab, setFilterTab] = useState(filterPath);
  const deletedCompanies = useDeletedSubCompanies(filterTab === TABS.deleted);
  const [filterText, setFilterText] = useState('');
  const [filterParentCompany, setFilterParentCompany] = useState([]);
  const debouncedSearchText = useDebounce(trim(filterText), 300);
  const [tableRef, setTableRef] = useState(null);
  const dispatch = useDispatch();
  const isFetchingDeletedCompany = useIsFetchingDeletedSubCompany();
  const isFetchingCompany = useIsFetchingCompany();
  const currentCompany = useCurrentCompany();

  useEffect(() => {
    if (path === PATHS.SUBCOMPANY_DEFAULT) {
      setFilterTab(TABS.all);
    }
  }, [path]);

  useEffect(() => {
    dispatch(setPageTitle(t('SubCompanies.SubCompanies')));
    dispatch(setBackButton(false));
    dispatch(fetchSubCompanies(currentCompany.id));
  }, [dispatch, t]);

  useEffect(() => {
    setFilterParentCompany(
      prepareDataForMultiselect(parentCompanies, t('Common.AllCompanies'), null)
    );
    setFilterText('');
  }, [parentCompanies, t]);

  useEffect(() => {
    const companiesToFilter = filterTab === TABS.deleted ? deletedCompanies : activeSubCompanies;
    setCompanyList(
      companiesToFilter
        .filter(company => {
          let validCompany = true;

          if (filterTab === TABS.all) {
            validCompany = true;
          }

          // Filter by search field
          if (debouncedSearchText) {
            validCompany =
              validCompany &&
              [company.name, company.slug].some(
                value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1
              );
          }

          // Filter by companies
          const checkedCompaniesIds = filterParentCompany
            .filter(company => company.checked)
            .map(company => parseInt(company.id, 10));
          if (!(checkedCompaniesIds.indexOf(0) > -1)) {
            validCompany =
              validCompany && checkedCompaniesIds.indexOf(parseInt(company.parent?.id, 10)) > -1;
          }
          return validCompany;
        })
        .sort((a, b) => sortStrings(a?.name, b?.name))
    );
  }, [
    parentCompanies,
    deletedCompanies,
    filterParentCompany,
    filterTab,
    filterText,
    debouncedSearchText
  ]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [companyList, tableRef]);

  const handleAction = actionObject => {
    if (actionObject.action === 'restore') {
      dispatch(restoreCompanyApi(actionObject.data));
    }
  };

  const handleDeleteAction = data => () => {
    dispatch(deleteCompanyApi(data));
  };

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div>
            <TabNavLink
              to="/settings/subcompanies/all"
              isActive={(match, location) => {
                return [
                  '/',
                  '/settings',
                  '/settings/subcompanies',
                  '/settings/subcompanies/all'
                ].includes(location.pathname);
              }}
              onClick={() => {
                setFilterTab(TABS.all);
              }}
            >
              {t('SubCompanies.All')}
            </TabNavLink>
            <TabNavLink
              exact
              to={`/settings/SubCompanies/deleted`}
              onClick={() => {
                setFilterTab(TABS.deleted);
              }}
            >
              {t('SubCompanies.Deleted')}
            </TabNavLink>
          </div>

          <div style={{ marginLeft: 'auto' }}>
            <Can everyEntity={[entities.SUBCOMPANY_CREATE]}>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  history.push(PATHS.SUBCOMPANY_ADD);
                }}
                id={BUTTON_IDS.subcompanyAddNew}
              >
                {t('SubCompanies.AddNewCompanies')}
              </Button>
            </Can>
          </div>
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} value={filterText} />
            <AntMultiselect
              title={
                filterParentCompany?.some(value => !value.checked)
                  ? t('Common.ParentCompanies')
                  : t('Common.AllParentCompanies')
              }
              onFilter={v => setFilterParentCompany(v)}
              data={filterParentCompany}
            />
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {companyList.length}{' '}
            {companyList.length === 1
              ? `${t('SubCompanies.SubCompanies')}`
              : `${t('SubCompanies.SubCompanies')}`}
          </label>
        </div>
        <div style={{ display: 'flex', flex: '1 0 0' }}>
          <SubCompaniesTable
            companies={companyList}
            isLoading={isFetchingCompany || isFetchingDeletedCompany}
            typeOfEntityToDelete={t('Common.company')}
            handleAction={action => handleAction(action)}
            handleDeleteAction={handleDeleteAction}
            setTableRef={setTableRef}
            filterTab={filterTab}
            localization={localization}
          />
        </div>
      </ContainerPageWrapper>
    </>
  );
};
