import React from 'react';
import { useLocalization } from 'features/localization/localizationSlice';
import { format } from 'utils/dates';
import { useDrivers } from 'features/users/usersSlice';
import { useTranslation } from 'react-i18next';

export function DriverManagementScheduleRender({ data, alertData, ...props }) {
  const drivers = useDrivers();
  const driver = drivers.find(d => d.id === data?.event?.schedule?.entityId);
  const localization = useLocalization();
  const { t } = useTranslation();

  return (
    <>
      <span> {t('Notifications.ForDriver')} </span>
      <strong>{driver?.firstName + ' ' + driver?.lastName}</strong>
      <span>. {t('Notifications.ScheduleName')}: </span>
      <strong>{data?.event?.schedule?.name}</strong>
      <span>.</span>
      <p>
        <span> {t('Notifications.ScheduledBy')} </span>
        <strong>{data?.event?.user?.firstName + ' ' + data?.event?.user?.lastName}</strong>
        {data?.event?.schedule?.schedulesOn && (
          <>
            <span> {t('Notifications.ForThe')} </span>
            <span>
              {format(
                new Date(data?.event?.schedule?.schedulesOn),
                localization.formats.time.formats.dmY
              )}
            </span>
          </>
        )}
      </p>
    </>
  );
}
