/* global google */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';

//components
import { LoadingTable } from 'components/grid/LoadingTable';
import Map, { MapMode } from 'components/map/Map';
import InfoRow from 'components/form/info-row/InfoRow';
import { FleetsTable } from './FleetsTable';
import { ActiveTimePeriodRow } from './ActiveTimePeriodRow';
import { ToastType } from 'components/notifications/toasts/Toast';

//slices
import {
  useGeofences,
  useIsFullyFetched,
  useIsFetching as useIsFetchingGeofences
} from 'features/geofences/geofencesSlice';
import { useCompanies } from 'features/company/companySlice';
import { useLocations } from 'features/locations/locationsSlice';
import { useInspectionChecklists } from 'features/inspectionChecklist/inspectionChecklistSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useDocuments } from 'features/easydocs/documentsSlice';

//methods & helpers
import { helpers, getGeofenceShape, toActivePeriodFormField } from './helpers';
import { format } from 'utils/dates';
import { getSelectedEventTypeNames } from '../../Scorecard/helpers';
import { useCan } from 'features/permissions';
import { openToast } from 'features/toasts/toastsSlice';

//styles
import './GeofencesDrawerView.scss';
import styles from './Geofences.module.scss';
import { Paths, geofenceDetailTableColumn } from './constants';
import { Row, Col, Form, Table, Button } from 'antd';
import { LocalizationUtil } from 'features/localization/localization';
import { getRoundValue } from 'utils/methods';

export const GeofenceDrawerView = ({ id, onExit, ...props }) => {
  const { t } = useTranslation();
  const can = useCan();
  const history = useHistory();
  const isFetching = useSelector(state => state.fleets.meta.isFetching);
  const isFetchingGeofences = useIsFetchingGeofences();
  const isFullyFetched = useIsFullyFetched();
  const geofences = useGeofences();
  const dispatch = useDispatch();
  const [geofenceData, setGeofenceData] = useState({});
  const [geofenceSpeedData, setGeofenceSpeedData] = useState({});
  const [geofenceIgnitionData, setGeofenceIgnitionData] = useState();
  const [geofenceEntryData, setGeofenceEntryData] = useState();
  const localization = useLocalization();

  const handleError = useCallback(
    err => {
      if (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err
          })
        );
      }
      if (onExit) {
        onExit();
      }
    },
    [history, dispatch]
  );

  // Get the company by the id
  const companies = useCompanies();
  let company;
  if (geofenceData.companyId) {
    company = companies.find(comp => comp.id === geofenceData.companyId);
  }

  // Get the location by the id
  const locations = useLocations();
  let location;
  if (geofenceData.location && geofenceData.location.id) {
    location = locations.find(loc => loc.id === geofenceData.location.id);
  }

  // Get the pretripchecklist and document by the id
  const pretripChecklist = useInspectionChecklists();
  const documents = useDocuments();
  let pretripGeofenceEntry;
  let pretripIgnition;
  let documentGeofenceEntry;

  if (geofenceData.driverNotification) {
    const driverNotificationArray = JSON.parse(geofenceData.driverNotification);
    const driverGeofenceEntry = driverNotificationArray.find(
      ({ TriggerEvent }) => TriggerEvent === 'GEO-EN'
    );
    const driverIgnition = driverNotificationArray.find(
      ({ TriggerEvent }) => TriggerEvent === 'IOR'
    );
    if (driverGeofenceEntry) {
      pretripGeofenceEntry = pretripChecklist.find(
        pre => pre.id === parseInt(driverGeofenceEntry.Ptc && driverGeofenceEntry.Ptc.Id)
      );
      documentGeofenceEntry = documents.find(
        document => document.id === parseInt(driverGeofenceEntry.Document?.Id)
      );
    }
    if (driverIgnition) {
      pretripIgnition = pretripChecklist.find(
        pre => pre.id === parseInt(driverIgnition.Ptc && driverIgnition.Ptc.Id)
      );
    }
  }

  useEffect(() => {
    const parsedId = parseInt(id, 10);
    if (parsedId <= 0 || isNaN(parsedId)) {
      handleError(t('Common.Invalid Request ID'));
      return;
    }

    if (!isFetchingGeofences && isFullyFetched) {
      const geofence = geofences.find(g => parseInt(g.id, 10) === parsedId);
      if (!geofence) {
        handleError(t('Common.Invalid Request ID'));
      } else {
        setGeofenceData({ ...geofence });
      }
    }
  }, [t, geofences, id, isFetchingGeofences, isFullyFetched]);

  useEffect(() => {
    const geofenceSpeed = {};
    geofenceSpeed.threshold =
      geofenceData.thresholdSpeed !== -1 ? geofenceData.thresholdSpeed : 'disabled';
    geofenceSpeed.offset = geofenceData.offsetSpeed !== -1 ? geofenceData.offsetSpeed : 'disabled';
    geofenceSpeed.duration =
      geofenceData.thresholdDurationSpeed !== -1 ? geofenceData.thresholdDurationSpeed : 'disabled';
    geofenceSpeed.speedAssist = geofenceData.speedAssist ? 'Yes' : 'No';

    setGeofenceSpeedData({ ...geofenceSpeed });

    let geofenceNotification;
    if (geofenceData.driverNotification) {
      geofenceNotification = JSON.parse(geofenceData.driverNotification);
      setGeofenceIgnitionData(
        geofenceNotification.find(({ TriggerEvent }) => TriggerEvent === 'IOR')
      );
      const geofenceEntryData = geofenceNotification.find(
        ({ TriggerEvent }) => TriggerEvent === 'GEO-EN'
      );
      if (geofenceEntryData && Object.keys(geofenceEntryData).length > 1) {
        geofenceEntryData.timeFrom = geofenceEntryData?.ActivePeriods?.All?.[0]
          ? geofenceEntryData.ActivePeriods.All[0]
          : '';
        geofenceEntryData.timeTo = geofenceEntryData?.ActivePeriods?.All?.[1]
          ? geofenceEntryData.ActivePeriods.All[1]
          : '';
      }
      setGeofenceEntryData(geofenceEntryData);
    } else {
      setGeofenceIgnitionData(null);
      setGeofenceEntryData(null);
    }
  }, [geofenceData]);

  const geofenceEntryTableData = useMemo(() => {
    return [
      {
        key: t('GeofencesFeature.Form.PreTripChecklist'),
        value: pretripGeofenceEntry && pretripGeofenceEntry.name
      },
      { key: t('Easydocs.Document'), value: documentGeofenceEntry?.name },
      { key: t('GeofencesFeature.View.Message'), value: geofenceEntryData?.Message || '-' },
      {
        key: t('GeofencesFeature.Mode'),
        value:
          geofenceEntryData?.Mode === 'ACK'
            ? t('GeofencesFeature.Acknowledge')
            : t('GeofencesFeature.Dismiss')
      },
      {
        key: t('GeofencesFeature.View.ActivePeriods'),
        value:
          geofenceEntryData?.timeFrom && geofenceEntryData?.timeTo
            ? `${t('GeofencesFeature.Daily')} ${t('GeofencesFeature.From').toLowerCase()} ${moment(
                geofenceEntryData?.timeFrom,
                'hh:mm a'
              ).format('hh:mm a')} ${t('GeofencesFeature.To').toLowerCase()} ${moment(
                geofenceEntryData?.timeTo,
                'hh:mm a'
              ).format('hh:mm a')}`
            : '-'
      }
    ];
  }, [geofenceEntryData, pretripGeofenceEntry, documentGeofenceEntry]);

  const ignitionTableData = useMemo(() => {
    return [
      {
        key: t('GeofencesFeature.Form.PreTripChecklist'),
        value: pretripIgnition && pretripIgnition.name
      },
      { key: t('GeofencesFeature.View.Message'), value: geofenceIgnitionData?.Message || '-' }
    ];
  }, [geofenceIgnitionData, pretripIgnition]);

  const geofenceSpeedTableData = useMemo(() => {
    return [
      {
        key: t('GeofencesFeature.SpeedLimit'),
        value:
          (geofenceSpeedData?.threshold || geofenceSpeedData?.threshold === 0) &&
          geofenceSpeedData?.threshold !== 'disabled'
            ? `${
                localization.formats.speed.unit_per_hour === 'mph'
                  ? getRoundValue(
                      LocalizationUtil.kmtomile(parseFloat(geofenceSpeedData?.threshold))
                    )
                  : geofenceSpeedData?.threshold
              } ${localization.formats.speed.unit_per_hour}`
            : t('GeofencesFeature.View.Disabled').toLowerCase()
      },
      {
        key: t('GeofencesFeature.Tolerance'),
        value:
          (geofenceSpeedData?.offset || geofenceSpeedData?.offset === 0) &&
          geofenceSpeedData?.offset !== 'disabled'
            ? `${
                localization.formats.speed.unit_per_hour === 'mph'
                  ? getRoundValue(LocalizationUtil.kmtomile(parseFloat(geofenceSpeedData?.offset)))
                  : geofenceSpeedData?.offset
              } ${localization.formats.speed.unit_per_hour}`
            : t('GeofencesFeature.View.Disabled').toLowerCase()
      },
      {
        key: t('GeofencesFeature.Duration'),
        value:
          (geofenceSpeedData?.duration || geofenceSpeedData?.duration === 0) &&
          geofenceSpeedData?.duration !== 'disabled'
            ? `${geofenceSpeedData?.duration} seconds`
            : t('GeofencesFeature.View.Disabled').toLowerCase()
      },
      {
        key: t('GeofencesFeature.View.SpeedAssist'),
        value: `${t(`Common.${geofenceSpeedData?.speedAssist}`)}`
      }
    ];
  }, [geofenceSpeedData]);

  if (isFetching || isFetchingGeofences || Object.keys(geofenceData).length === 0)
    return <LoadingTable columnSizes={[91, 60, 60, 68, 63, 49]} />;

  // Can only view and edit excluded scorecard events with proper permissions
  const canViewScorecardItems = helpers.getCanViewScorecardItems(can);
  const scorecardEvents = getSelectedEventTypeNames(geofenceData?.features, localization, t);

  return (
    <ReflexContainer orientation="vertical">
      <ReflexElement flex={0.275} className={styles.scrollBarPanel}>
        <div className={styles.GeofenceView}>
          <Form layout="vertical">
            <Row style={{ width: '100%' }}>
              <Col xs={{ span: 24 }}>
                <InfoRow
                  label={`${t('Common.Company')}`}
                  value={company ? company.name : ''}
                  styles={styles}
                  labelSxValue={{ width: '100%' }}
                  direction="vertical"
                  hideBorder={true}
                />
              </Col>
              <Col xs={{ span: 24 }}>
                <InfoRow
                  label={`${t('VehicleMaintenanceTypes.Type')}`}
                  labelSxValue={{ width: '100%' }}
                  value={
                    geofenceData.type
                      ? geofenceData.type.charAt(0).toUpperCase() +
                        geofenceData.type.slice(1).toLowerCase()
                      : ''
                  }
                  direction="vertical"
                  hideBorder={true}
                  styles={styles}
                />
              </Col>
              <Col xs={{ span: 24 }}>
                <InfoRow
                  label={`${t('GeofencesFeature.Area')}`}
                  hideBorder={true}
                  labelSxValue={{ width: '100%' }}
                  direction="vertical"
                  styles={styles}
                  value={
                    geofenceData && geofenceData.area_sqm
                      ? `${localization.convertArea(geofenceData.area_sqm)} ${
                          localization.formats.area.unit
                        }`
                      : ''
                  }
                />
              </Col>
              <Col xs={{ span: 24 }}>
                <InfoRow
                  label={`${t('GeofencesFeature.View.Shape')}`}
                  hideBorder={true}
                  labelSxValue={{ width: '100%' }}
                  direction="vertical"
                  styles={styles}
                  value={getGeofenceShape(geofenceData)}
                />
              </Col>
              <Col xs={{ span: 24 }}>
                <InfoRow
                  label={`${t('Common.CreatedAt')}`}
                  labelSxValue={{ width: '100%' }}
                  hideBorder={true}
                  direction="vertical"
                  value={
                    geofenceData && geofenceData.createdAt
                      ? format(
                          new Date(geofenceData.createdAt),
                          localization.formats.time.formats.dby_imp
                        )
                      : '-'
                  }
                  styles={styles}
                />
              </Col>
              <Col xs={{ span: 24 }}>
                <InfoRow
                  label={`${t('Common.UpdatedAt')}`}
                  labelSxValue={{ width: '100%' }}
                  hideBorder={true}
                  direction="vertical"
                  value={
                    geofenceData && geofenceData.updatedAt
                      ? format(
                          new Date(geofenceData.updatedAt),
                          localization.formats.time.formats.dby_imp
                        )
                      : '-'
                  }
                  styles={styles}
                />
              </Col>
              <Col xs={{ span: 24 }}>
                <InfoRow
                  label={`${t('Locations.Location')}`}
                  labelSxValue={{ width: '100%' }}
                  hideBorder={true}
                  direction="vertical"
                  value={(location && location.name) || '-'}
                  styles={styles}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }}>
                <h5 style={{ marginTop: '1rem' }}>{t('GeofencesFeature.View.AssociatedFleets')}</h5>
                <FleetsTable fleets={geofenceData.fleets || []} />
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }}>
                <h5 style={{ marginTop: '1rem' }}>{t('GeofencesFeature.Form.EventParameters')}</h5>
                <div className="activeTimeTable">
                  <ActiveTimePeriodRow
                    title={t('GeofencesFeature.ActiveTimesTableTitle')}
                    period={toActivePeriodFormField(geofenceData).activeTime.period}
                  />
                </div>
              </Col>
              <Col xs={{ span: 24 }}>
                <InfoRow
                  label={t('GeofencesFeature.Speed')}
                  hideBorder={true}
                  labelSxValue={{ width: '100%', fontWeight: 'bold' }}
                  direction="vertical"
                  value={
                    <Table
                      showHeader={false}
                      tableLayout={'fixed'}
                      className="noPadding"
                      size="small"
                      dataSource={geofenceSpeedTableData}
                      columns={geofenceDetailTableColumn}
                      pagination={false}
                      bordered
                    />
                  }
                />
                <InfoRow
                  label={t('GeofencesFeature.Undertime')}
                  hideBorder={true}
                  labelSxValue={{ width: '100%', fontWeight: 'bold' }}
                  direction="vertical"
                  value={
                    geofenceData.thresholdUndertime && geofenceData.thresholdUndertime !== -1
                      ? `${geofenceData.thresholdUndertime / 60} ${t(
                          'GeofencesFeature.View.Minutes'
                        ).toLowerCase()}`
                      : t('GeofencesFeature.View.Disabled').toLowerCase()
                  }
                />
                <InfoRow
                  label={t('GeofencesFeature.Overtime')}
                  hideBorder={true}
                  labelSxValue={{ width: '100%', fontWeight: 'bold' }}
                  direction="vertical"
                  value={
                    geofenceData.thresholdOvertime && geofenceData.thresholdOvertime !== -1
                      ? `${geofenceData.thresholdOvertime / 60} ${t(
                          'GeofencesFeature.View.Minutes'
                        ).toLowerCase()}`
                      : t('GeofencesFeature.View.Disabled').toLowerCase()
                  }
                />
                {geofenceIgnitionData && Object.keys(geofenceIgnitionData).length > 1 && (
                  <InfoRow
                    label={t('GeofencesFeature.IgnitionOn')}
                    hideBorder={true}
                    labelSxValue={{ width: '100%', fontWeight: 'bold' }}
                    direction="vertical"
                    value={
                      <Table
                        showHeader={false}
                        tableLayout={'fixed'}
                        size="small"
                        className="noPadding"
                        dataSource={ignitionTableData}
                        columns={geofenceDetailTableColumn}
                        pagination={false}
                        bordered
                      />
                    }
                  />
                )}
                {geofenceEntryData && Object.keys(geofenceEntryData).length > 1 && (
                  <InfoRow
                    label={t('GeofencesFeature.GeofenceEntry')}
                    hideBorder={true}
                    labelSxValue={{ width: '100%', fontWeight: 'bold' }}
                    direction="vertical"
                    value={
                      <Table
                        showHeader={false}
                        size="small"
                        className="noPadding"
                        dataSource={geofenceEntryTableData}
                        columns={geofenceDetailTableColumn}
                        pagination={false}
                        bordered
                      />
                    }
                  />
                )}
              </Col>
              {canViewScorecardItems && scorecardEvents?.length > 0 && (
                <Col span={24}>
                  <h5 style={{ marginTop: '3rem' }}>{t('Scorecard.ExcludedScorecardEvents')}</h5>
                  {scorecardEvents.map(scorecardEvent => (
                    <InfoRow
                      key={scorecardEvent}
                      label={scorecardEvent}
                      direction="vertical"
                      py={2}
                      labelSxValue={{ width: '100%', padding: '0 0 0 10px' }}
                      hideBorder={true}
                    />
                  ))}
                </Col>
              )}
            </Row>
          </Form>
        </div>
      </ReflexElement>
      <ReflexSplitter className={styles.gridSplitter} />
      <ReflexElement>
        <Map
          mode={MapMode.Geofence}
          mapOptions={{
            mapTypeControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT }
          }}
          showDevice={(props.devices || []).length !== 0}
          devices={props.devices || []}
          geofences={[geofenceData]}
          filterGeofencesByType={false}
          containerElement={<div style={{ height: `100%`, width: `100%` }} />}
          mapElement={<div style={{ height: `100%`, width: `100%` }} />}
        />
      </ReflexElement>
    </ReflexContainer>
  );
};
