import React from 'react';
import {
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  PieChart,
  Pie,
  Sector,
  DefaultLegendContent
} from 'recharts';
import styles from './ResponsivePieChart.module.scss';

const renderActiveShape = (activePieLabel = activeEntry => null) => props => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  const labelContent = activePieLabel(payload);
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      {labelContent && (
        <>
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          <circle cx={ex} cy={ey} r={5} fill={fill} stroke="none" />
          <text
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey + 4}
            textAnchor={textAnchor}
            fill={'#333'}
            fontWeight={800}
          >
            {labelContent}
          </text>
        </>
      )}
    </g>
  );
};

const MARGIN = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
};

const DATA = [];

export const ResponsivePieChart = ({
  data = DATA,
  height = 100,
  margin = MARGIN,
  tooltipContent = activeEntry => null,
  legendContent = (OriginalLegend, props) => OriginalLegend,
  activePieLabel = activeEntry => null,
  activePieIndex,
  onPieClick
}) => (
  <ResponsiveContainer width="100%" height={height}>
    <PieChart margin={margin}>
      <Legend
        align="right"
        formatter={value => <span style={{ color: '#333', verticalAlign: 'middle' }}>{value}</span>}
        content={props => legendContent(<DefaultLegendContent {...props} />, props)}
      />
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        fill="#8884d8"
        dataKey="value"
        legendType="circle"
        outerRadius={100}
        activeIndex={activePieIndex}
        activeShape={renderActiveShape(activePieLabel)}
        {...(onPieClick ? { onClick: onPieClick } : {})}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={
              Array.isArray(entry.color)
                ? index === activePieIndex
                  ? entry.color[1] || entry.color[0]
                  : entry.color[0]
                : entry.color
            }
          />
        ))}
      </Pie>
      <Tooltip
        shared={false}
        content={({ payload, active }) =>
          active && payload?.[0] ? (
            <div className={styles.tooltip}>{tooltipContent(payload[0])}</div>
          ) : null
        }
      />
    </PieChart>
  </ResponsiveContainer>
);

export const TooltipContent = ({ label, value, valueColor }) => (
  <div className={styles.tooltip}>
    <div className={styles.name}>{label}</div>
    <div>
      <span className={styles.value} style={{ backgroundColor: valueColor }}></span>
      <span>{value}</span>
    </div>
  </div>
);
