import entities from 'features/permissions/entities';
import i18n from 'i18next';

export const TEMPLATE_TYPES = {
  geofences: 'Ng::Geofence',
  locations: 'Ng::Location',
  vehicles: 'Ng::Vehicle',
  users: 'Ng::User'
};

export const STATUS_SELECT_PLACEHOLDER = () => {
  return [
    {
      id: '',
      label: i18n.t('CompanyConfig.BulkManagement.AllStatuses', '')
    },
    {
      id: 'FAILED',
      label: i18n.t('CompanyConfig.BulkManagement.Failed', '')
    },
    {
      id: 'IN_PROGRESS',
      label: i18n.t('CompanyConfig.BulkManagement.InProgress', '')
    },
    {
      id: 'SUCCESS',
      label: i18n.t('CompanyConfig.BulkManagement.Successful', '')
    },
    {
      id: 'PARTIAL',
      label: i18n.t('CompanyConfig.BulkManagement.Partial', '')
    }
  ];
};

export const TYPE_SELECT_PLACEHOLDER = () => {
  return [
    {
      id: '',
      label: i18n.t('CompanyConfig.BulkManagement.AllTypes', '')
    },
    {
      id: TEMPLATE_TYPES.geofences,
      label: i18n.t('CompanyConfig.BulkManagement.Geofence', ''),
      permissions: { everyEntity: [entities.GEOFENCE_CREATE, entities.BULKIMPORT] }
    },
    {
      id: TEMPLATE_TYPES.locations,
      label: i18n.t('CompanyConfig.BulkManagement.Location', ''),
      permissions: { everyEntity: [entities.LOCATION_CREATE, entities.BULKIMPORT] }
    },
    {
      id: TEMPLATE_TYPES.users,
      label: i18n.t('CompanyConfig.BulkManagement.User', ''),
      permissions: {
        everyEntity: [entities.USER_CREATE, entities.BULKIMPORT]
      }
    },
    {
      id: TEMPLATE_TYPES.vehicles,
      label: i18n.t('CompanyConfig.BulkManagement.Vehicle', ''),
      permissions: {
        everyEntity: [entities.VEHICLE_CREATE, entities.BULKIMPORT]
      }
    }
  ];
};
