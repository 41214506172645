import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import styles from './FloatButton.module.scss';
import { Button } from 'react-bootstrap';
import { BUTTON_IDS } from 'utils/globalConstants';

const MouseStatus = {
  None: 0,
  MouseDown: 1,
  Dragging: 2,
  DragRelease: 3
};

export const FloatButton = React.forwardRef(
  ({ title, iconClass, onClick, style, visible = true }, ref) => {
    const containerRef = useRef(null);
    const isDragging = useRef(false);
    useEffect(() => {
      const container = document.createElement('div');
      container.id = 'fb_' + Math.random().toFixed(10);
      container.className = styles.floatButtonContainer;
      if (!visible) {
        container.style.display = 'none';
      }
      container.addEventListener('mousedown', evt => {
        if (evt.which === 1) {
          isDragging.current = MouseStatus.MouseDown;
          container.setAttribute('lastMousePageX', evt.pageX);
          container.setAttribute('lastMousePageY', evt.pageY);
        }
      });
      container.addEventListener(
        'click',
        evt => {
          if (evt.which === 1) {
            if (isDragging.current === MouseStatus.DragRelease) {
              evt.preventDefault();
              evt.stopPropagation();
            }
          }
        },
        true
      );
      window.addEventListener('mouseup', evt => {
        if (evt.which === 1) {
          if (isDragging.current === MouseStatus.MouseDown) {
            isDragging.current = MouseStatus.None;
          } else {
            isDragging.current = MouseStatus.DragRelease;
          }
        }
      });

      window.addEventListener('mousemove', evt => {
        if (
          (isDragging.current === MouseStatus.MouseDown ||
            isDragging.current === MouseStatus.Dragging) &&
          (evt.movementX !== 0 || evt.movementY !== 0)
        ) {
          isDragging.current = MouseStatus.Dragging;
          const calculatedStyle = container.computedStyleMap();
          const rightOffsetStyle = calculatedStyle.get('right');
          const bottomOffsetStyle = calculatedStyle.get('bottom');
          const lastMousePageX = container.getAttribute('lastMousePageX');
          const lastMousePageY = container.getAttribute('lastMousePageY');
          const movementX = evt.pageX - lastMousePageX;
          const movementY = evt.pageY - lastMousePageY;
          const posX = Math.min(
            document.body.offsetWidth - container.offsetWidth,
            Math.max(0, rightOffsetStyle.value - movementX)
          );
          const posY = Math.min(
            document.body.offsetHeight - container.offsetHeight,
            Math.max(0, bottomOffsetStyle.value - movementY)
          );
          container.style.right = posX + 'px';
          container.style.bottom = posY + 'px';
          container.setAttribute('lastMousePageX', evt.pageX);
          container.setAttribute('lastMousePageY', evt.pageY);
        }
      });
      document.body.appendChild(container);
      containerRef.current = container;
      if (ref) {
        ref.current = container;
      }
      return () => {
        if (container) {
          document.body.removeChild(container);
          containerRef.current = null;
        }
      };
    }, []);

    if (!containerRef.current) {
      return <></>;
    }

    return ReactDOM.createPortal(
      <Button className={styles.floatButton} id={BUTTON_IDS.float} style={style} onClick={onClick}>
        {title || ''}
        {iconClass ? <span className={iconClass} /> : ''}
      </Button>,
      containerRef.current
    );
  }
);
