import React from 'react';
import * as Yup from 'yup';
import { getLicenceStateLabel } from 'features/localization/localization';
import { format } from 'utils/dates';
import i18next from 'i18next';
import { toUpper } from 'lodash';

export const tooltip = (hasBranches = true) => ({
  permissions: hasBranches
    ? [
        'Users.Form.FirstFleetBranchInfo',
        'Users.Form.SecondFleetBranchInfo',
        'Users.Form.ThidrFleetBranchInfo'
      ]
    : ['Users.Form.SecondFleetInfo', 'Users.Form.ThirdFleetInfo']
});

export const ACTIONS = {
  ADD: 'add',
  EDIT: 'edit'
};

export const USER_TYPES = {
  USER: 'User',
  DRIVER: 'Driver',
  CUSTOMER: 'Customer',
  SUPPORT_PERSONNEL: 'Support Personnel'
};

export const TABS = {
  all: 'all',
  users: 'users',
  sentinel: 'sentinel',
  ewd: 'ewd',
  unverified: 'unverified', //not using for now
  deleted: 'deleted'
};

export const initialValues = {
  type: '',
  roles: [],
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  mobile: '',
  companyId: '',
  timeZone: '',
  siteAdmin: false,
  location: '',
  externalReference: '',
  licenceNumber: '',
  licenceState: undefined,
  siteNotes: '',
  licenceCountry: undefined,
  sentinelExemptionNotes: '',
  field1Date: '',
  field1: '',
  field2Date: '',
  field2: '',
  field3Date: '',
  field3: '',
  driver_notes: '',
  health_check_expiryDate: '',
  health_check_expiry: '',
  licence_expiryDate: '',
  licence_expiry: '',
  features: []
};

export const validationSchema = (
  userType,
  countryCode,
  eld,
  userExternalIds,
  licenceNumbers,
  userData,
  externalPinsArrayPerCompany,
  electronicPinsArrayPerCompany,
  electronicPin2sArrayPerCompany
) => {
  let passwordStrength = {
    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*;\\?])(?=.{8,})/,
    message: i18next.t(
      'Users.ValidationErrors.MustContain8charactersUppercaseLowerCaseNumberSpecialCharacter'
    )
  };
  if (userType === USER_TYPES.DRIVER || userType === USER_TYPES.SUPPORT_PERSONNEL) {
    passwordStrength = {
      regex: /.{4,}/,
      message: i18next.t('Users.ValidationErrors.MustContain4Characters')
    };
  }
  let validations = Yup.object().shape({
    firstName: Yup.string()
      .max(50, i18next.t('Users.ValidationErrors.FirstNameTooLong', { len: 50 }))
      .required(i18next.t('Users.ValidationErrors.FirstNameRequired')),
    lastName: Yup.string()
      .max(50, i18next.t('Users.ValidationErrors.LastNameTooLong', { len: 50 }))
      .required(i18next.t('Users.ValidationErrors.LastNameRequired')),
    username: Yup.string()
      .min(6, i18next.t('Users.ValidationErrors.UserNameShort', { len: 6 }))
      .max(64, i18next.t('Users.ValidationErrors.UserNameLong', { len: 64 }))
      .matches(/^\S+$/, 'Username cannot contain any spaces')
      .required(i18next.t('Users.ValidationErrors.UserNameRequired')),
    email: Yup.string()
      .email(i18next.t('Users.ValidationErrors.InvalidEmail'))
      .required(i18next.t('Users.ValidationErrors.RequiredEmail')),
    externalId: Yup.string().test(
      'Unique',
      i18next.t('Users.ValidationErrors.ExternalErrorDuplicate'),
      values => !userExternalIds.includes(values) || values === userData?.externalId
    ),
    password: Yup.string().matches(passwordStrength.regex, passwordStrength.message),
    passwordConfirm: Yup.string().when('password', {
      is: password => password?.length > 1,
      then: Yup.string()
        .oneOf([Yup.ref('password')], i18next.t('Users.ValidationErrors.PasswordsMatch'))
        .required(i18next.t('Users.ValidationErrors.PasswordsMatch')),
      otherwise: Yup.string()
    }),
    pin: Yup.string()
      .matches(/.{6,}/, i18next.t('Users.ValidationErrors.MustContain6Characters'))
      .test(
        'Unique',
        i18next.t('Users.ValidationErrors.IdenticalPin'),
        values =>
          values === userData?.externalPin ||
          values === userData?.electronicPin ||
          values === userData?.electronicPin2 ||
          (!externalPinsArrayPerCompany.includes(values) &&
            !electronicPinsArrayPerCompany.includes(values) &&
            !electronicPin2sArrayPerCompany.includes(values))
      ),
    pinConfirm: Yup.string().when('pin', {
      is: pin => !!pin?.length,
      then: Yup.string()
        .oneOf([Yup.ref('pin')], i18next.t('Users.ValidationErrors.PINSMatch'))
        .required(i18next.t('Users.ValidationErrors.PINSMatch')),
      otherwise: Yup.string()
    }),
    ePin2: Yup.string()
      .matches(/^[0-9]*$/, i18next.t('Users.ValidationErrors.NumericOnly'))
      .min(7, i18next.t('Users.ValidationErrors.MustContain7Characters'))
      .max(7, i18next.t('Users.ValidationErrors.MustContain7Characters'))
      .test(
        'Unique',
        i18next.t('Users.ValidationErrors.IdenticalPin'),
        values =>
          values === userData?.externalPin ||
          values === userData?.electronicPin ||
          values === userData?.electronicPin2 ||
          (!externalPinsArrayPerCompany.includes(values) &&
            !electronicPinsArrayPerCompany.includes(values) &&
            !electronicPin2sArrayPerCompany.includes(values))
      ),
    electronicPin2Confirm: Yup.string().when('ePin2', {
      is: ePin2 => !!ePin2?.length,
      then: Yup.string()
        .oneOf([Yup.ref('ePin2')], i18next.t('Users.ValidationErrors.ElectronicIDsMatch'))
        .required(i18next.t('Users.ValidationErrors.ElectronicIDsMatch')),
      otherwise: Yup.string().length(0, i18next.t('Users.ValidationErrors.ElectronicIDsMatch'))
    }),
    licenceState: Yup.string().when('licenceNumber', {
      is: licenceNumber => licenceNumber?.length > 1,
      then: Yup.string().required(i18next.t('Users.ValidationErrors.RequiredLicenceState')),
      otherwise: Yup.string().nullable()
    }),
    licenceNumber:
      (userType === USER_TYPES.DRIVER &&
        Yup.string()
          .matches(
            /^[A-Z0-9]{1,20}$/,
            'Invalid Input. The available characters are A-Z and 0-9. No spaces. No special characters. Only 1-20 characters allowed.'
          )
          .test(
            'Unique',
            i18next.t('Users.ValidationErrors.LicenceNumberErrorDuplicate'),
            values => !licenceNumbers.includes(values) || values === userData?.licenceNumber
          )) ||
      (userType === USER_TYPES.SUPPORT_PERSONNEL &&
        Yup.string()
          .matches(
            /^[A-Z0-9]{1,20}$/,
            'Invalid Input. The available characters are A-Z and 0-9. No spaces. No special characters. Only 1-20 characters allowed.'
          )
          .test(
            'Unique',
            i18next.t('Users.ValidationErrors.LicenceNumberErrorDuplicate'),
            values => !licenceNumbers.includes(values) || values === userData?.licenceNumber
          )),
    sentinelExemptionNotes:
      userType === USER_TYPES.SUPPORT_PERSONNEL &&
      Yup.string().required('Exemption Comments is required')
  });

  if (eld) {
    validations = validations.shape({
      firstName: Yup.string()
        .min(2, i18next.t('Users.ValidationErrors.FirstNameTooShort', { len: 2 }))
        .max(30, i18next.t('Users.ValidationErrors.FirstNameTooLong', { len: 30 }))
        .required(i18next.t('Users.ValidationErrors.FirstNameRequired'))
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9 '-]*[a-zA-Z0-9]$/,
          i18next.t('Users.ValidationErrors.NamesWarn')
        ),

      lastName: Yup.string()
        .min(2, i18next.t('Users.ValidationErrors.LastNameTooShort', { len: 2 }))
        .max(30, i18next.t('Users.ValidationErrors.LastNameTooLong', { len: 30 }))
        .required(i18next.t('Users.ValidationErrors.LastNameRequired'))
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9 '-]*[a-zA-Z0-9]$/,
          i18next.t('Users.ValidationErrors.NamesWarn')
        ),
      ...((userType === USER_TYPES.DRIVER || userType === USER_TYPES.SUPPORT_PERSONNEL) && {
        username: Yup.string()
          .min(4, i18next.t('Users.ValidationErrors.UserNameShort', { len: 4 }))
          .max(60, i18next.t('Users.ValidationErrors.UserNameLong', { len: 60 }))
          .required(i18next.t('Users.ValidationErrors.UserNameRequired'))
          .matches(/^[a-zA-Z0-9]*$/, i18next.t('Users.ValidationErrors.NormalChars'))
      }),
      licenceState:
        userType === USER_TYPES.DRIVER &&
        Yup.string().required(
          `${getLicenceStateLabel(countryCode)} ${i18next.t('Users.ValidationErrors.IsRequired')}`
        ),
      licenceNumber:
        userType === USER_TYPES.DRIVER &&
        Yup.string()
          .min(1, i18next.t('Users.ValidationErrors.LicenceNumberShort', { len: 1 }))
          .max(20, i18next.t('Users.ValidationErrors.LicenceNumberLong', { len: 20 }))
          .required(i18next.t('Users.ValidationErrors.LicenceNumberRequired'))
          .matches(/^[a-zA-Z0-9]*$/, i18next.t('Users.ValidationErrors.NormalChars')),
      ...(!userData?.id &&
        (userType === USER_TYPES.DRIVER || userType === USER_TYPES.SUPPORT_PERSONNEL) && {
          password: validations.fields['password'].required(
            i18next.t('Users.ValidationErrors.PasswordRequired')
          ),
          passwordConfirm: validations.fields['passwordConfirm'].required(
            i18next.t('Users.ValidationErrors.PasswordConfirmRequired')
          )
        })
    });
  }

  return validations;
};

export const limitedUserRoles = [
  'External Admin',
  'External Superuser',
  'External User',
  'External Driver'
];

export const rulesetsViewTableColumns = localization => {
  return [
    {
      title: `${i18next.t('Users.View.Ruleset')}`,
      dataIndex: 'desc'
    },
    {
      title: `${i18next.t('Users.View.ValidFrom')}`,
      render: record => (
        <span>{format(new Date(record.enabledAt), localization.formats.time.formats.dby_imp)}</span>
      )
    },
    {
      title: `${i18next.t('Users.View.ValidTo')}`,
      render: record => (
        <span>
          {record && record.expiresAt
            ? format(new Date(record.expiresAt), localization.formats.time.formats.dby_imp)
            : ''}
        </span>
      )
    }
  ];
};
export const XLSX_FILE_TITLE = 'Users';

const NO_OF_COLUMNS = 20;
export const columnWidth = Array.apply(null, { length: NO_OF_COLUMNS }).map(() => ({ wch: 25 }));

export const AUDITS_ACTIONS = {
  LOGIN: 'login',
  LOGOUT: 'logout'
};

export const AUDIT_COMMENTS = {
  LOGIN_SUCCESSFULLY: 'Successfully logged in.',
  INTERNAL_LOGIN: 'Internal login successful'
};

export const PATHS = {
  USERS_DEFAULT: '/settings/users',
  USER_ADD: '/settings/users/newUser',
  USER_VIEW: '/settings/users/id',
  USER_EDIT: '/settings/users/edit/id',
  USER_AUDITS: '/settings/users/audit/id'
};

export const BRANCH_TYPE = {
  NO_BRANCH: -1,
  ALL_BRANCHES: 0
};

export const FLEET_TYPE = {
  NO_FLEET: -1,
  ALL_FLEETS: 0
};

export const USER_STATUSES = {
  ENABLED: 'ENABLED'
};

export const ELD_COMPANY_ID = 14787;

export const LOCATION_VIEW_URL = '/settings/locations/id/';

export const ROLE_VIEW_URL = '/settings/roles/id/';

export const isDriverType = user =>
  user?.type?.code && ['DRIVER'].includes(toUpper(user.type.code));

export const loadEmbeds = 'locations,fleets,audits,associations,user_session';

export const AUTH_REQUIRED_ACTIONS = [
  { key: 'UPDATE_PASSWORD', auditKey: 'password_update_request' },
  { key: 'VERIFY_EMAIL', auditKey: 'email_verification_request' },
  { key: 'CONFIGURE_TOTP', auditKey: 'configure_otp' }
];
