import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { formatVehicleParameterValue } from 'features/vehicles/vehicleParameters';
import { useLocalization } from 'features/localization/localizationSlice';
import { VehicleParameter } from 'features/vehicles/vehicleParameters';

import styles from './Trips.module.scss';
import { formatTimeago } from 'utils/dates';

export const VehicleParameters = ({ device }) => {
  const [vpm, setVpm] = useState();
  const { t } = useTranslation();
  const localization = useLocalization();

  useEffect(() => {
    if (device?.deviceStats?.lastVpm) {
      try {
        const vpmJson = JSON.parse(device?.deviceStats?.lastVpm);
        setVpm(vpmJson);
      } catch (e) {}
    }
  }, [device]);

  const DetailsRowHeader = props => (
    <div
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 16px',
        padding: '4px 16px',
        backgroundColor: '#f7f8f9', // tn-color-neutral-1
        color: '#64748b', // tn-color-neutral-7
        fontSize: '1rem',
        marginTop: '2rem'
      }}
    >
      {props.children}
    </div>
  );

  const DetailsRow = props => (
    <div
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        minHeight: '50px',
        margin: '0 32px',
        color: '#050607', // tn-color-neutral-12
        fontSize: '1rem',
        borderBottom: '1px solid',
        borderColor: '#dadee3',
        padding: '1rem 0'
      }}
    >
      {props.children}
    </div>
  );

  if (!device) {
    return null;
  }

  return (
    <>
      <DetailsRowHeader>
        <div>{t(`Tracking.VehicleParameters.LastVPM`)}</div>
        <div>{device?.deviceStats?.lastVpmAt && formatTimeago(device?.deviceStats?.lastVpmAt)}</div>
      </DetailsRowHeader>

      {vpm &&
        Object.keys(vpm)
          .filter(
            key =>
              ![VehicleParameter.Odometer, VehicleParameter.Hours].includes(key) &&
              vpm[key] !== -1 &&
              key !== VehicleParameter.Id &&
              key !== VehicleParameter.GPS
          )
          .map(key => (
            <DetailsRow key={key}>
              <div className={styles.detailsRowLabel}>{t(`Tracking.VehicleParameters.` + key)}</div>
              <div>{formatVehicleParameterValue(t, localization, key, vpm[key])}</div>
            </DetailsRow>
          ))}
    </>
  );
};
