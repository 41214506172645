import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { Table, Input, Layout } from 'components/ant';

import style from './CodesTable.module.scss';
import { Paths, ColumnKeys } from './constants';
import { prepareCodesDataForTable, prepareColumnsForTable } from './helpers';

import { useSmartJobsCodes } from 'features/smartJobs';
import { useCompanies } from 'features/company/companySlice';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useCan, entities } from 'features/permissions';
import { BUTTON_IDS } from 'utils/globalConstants';

export const SmartJobsCodes = () => {
  const can = useCan();
  const canViewSmartJobsCode = can && can({ everyEntity: [entities.SMARTJOBSCODE_VIEW] });
  const codes = useSmartJobsCodes();
  const companies = useCompanies();
  const history = useHistory();
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('Common.SmartJobsCodes')));
  }, [dispatch, t]);

  const mappedCodes = codes.map(code => ({
    ...code,
    companyName: (companies.find(company => company.id === code.companyId) || {})[ColumnKeys.NAME]
  }));

  return (
    <>
      <div className={style.addCodesContainer}>
        <Button
          type="primary"
          onClick={() => {
            history.push(Paths.ADD_NEW_CODE);
          }}
          className={style.addCodesButton}
          id={BUTTON_IDS.smartjobsCodesTableAdd}
        >
          {t('SmartJobsCodes.addNewCode')}
        </Button>
      </div>
      <Layout.Content>
        <Input.Search
          className={style.input}
          placeholder={t('SmartJobsCodes.SearchCode')}
          onChange={e => {
            setQuery(e.target.value);
          }}
          style={{ width: 200 }}
        />
        <Table
          dataSource={prepareCodesDataForTable(mappedCodes, query, t, canViewSmartJobsCode)}
          columns={prepareColumnsForTable(t)}
          pagination={false}
          loading={false}
        />
      </Layout.Content>
    </>
  );
};
