import React, { useState } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { CameraTabs } from './constant';
import { TabNavLink, IconNavLink } from 'components/nav/NavLinks';
import styles from './CameraTab.module.scss';
import { useTranslation } from 'react-i18next';
import { services, useCan } from 'features/permissions';
import { CompanyIQDVRAlert } from './Components/CompanyIQDVRAlert';
import { Divider } from 'antd';

const isTabActive = (path, location) =>
  path.toLowerCase().endsWith(location?.pathname?.toLowerCase());

const TabNav = ({ tab }) => {
  const { t } = useTranslation();
  return tab.icon ? (
    <IconNavLink
      isActive={(_, location) => isTabActive(tab.path, location)}
      to={tab.path}
      key={tab.path}
      icon={tab.icon(t)}
    />
  ) : (
    <TabNavLink
      isActive={(_, location) => isTabActive(tab.path, location)}
      to={tab.path}
      key={tab.path}
    >
      {t('Home.' + tab.title)}
    </TabNavLink>
  );
};

export function CameraTab({ actions, ...props }) {
  const [sharedData, setSharedData] = useState({});
  const can = useCan();
  const canDVRUsage = can({
    everyCompanyService: [services.IQ]
  });
  return (
    <>
      <div className={styles.tabs}>
        {CameraTabs.filter(tab => (tab.can ? can(tab.can) : true)).map((tab, i, tabs) => (
          <React.Fragment key={`cameraTab-${i}`}>
            <span className={tab.position === 'end' ? styles.endTab : ''}>
              <TabNav tab={tab} />
            </span>
            <TabExtras
              key={`tab-${i}-extra`}
              tab={tab}
              actions={actions}
              showDivider={tabs.some(tab => tab.position === 'end')}
            />
          </React.Fragment>
        ))}
      </div>
      {canDVRUsage && <CompanyIQDVRAlert />}
      <Switch>
        {CameraTabs.map(t => (
          <Route
            exact
            key={t.path}
            path={t.path}
            render={renderProps => {
              const Component = t.component;
              return <Component {...props} sharedData={sharedData} setSharedData={setSharedData} />;
            }}
          />
        ))}
      </Switch>
    </>
  );
}

const TabExtras = ({ tab, showDivider, ...props }) => {
  const location = useLocation();
  if (!tab.tabExtras || !isTabActive(tab.path, location)) {
    return null;
  }
  const Component = tab.tabExtras;
  return (
    <div className={styles.tabExtras}>
      {showDivider && <Divider type="vertical" />}
      <Component {...props} />
    </div>
  );
};
