export const getCameraVehiclesAndDevices = (vehicles = [], standaloneDevices = []) => {
  const cameraVehicles = vehicles?.reduce((acc, vehicle) => {
    vehicle?.devices &&
      vehicle?.devices.some(device => device?.type?.code === 'CAMERA') &&
      acc.push(vehicle);
    return acc;
  }, []);
  const cameraDevices = standaloneDevices?.reduce((acc, device) => {
    device?.type && device?.type?.code === 'CAMERA' && acc.push(device);
    return acc;
  }, []);
  return cameraVehicles.concat(cameraDevices);
};

export const getStandaloneDevices = (devices = [], selectedValue = []) => {
  return devices
    .filter(d => !d.vehicleInfo.id || selectedValue.filter(i => i === d.id).length !== 0)
    .map(device => ({ ...device, fleets: device.fleetInfo, registration: '' }));
};
