import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Popover } from 'antd';
import { CalendarPopupContent } from 'components/ant/CalendarView';
import { CalendarView, RowStatus, Tooltip } from 'components/ant';

import { useCan } from 'features/permissions';
import { statusMap, preparePopupRows, preparePopupActions } from './utils/helpers';
import { STATUS } from './utils/constants';

import { handleScheduleDeleteAction } from 'features/vehicleMaintenance/schedulesSlice';
import { useLocalization } from 'features/localization/localizationSlice';

import style from './VehicleMaintenanceCalendarView.module.scss';

export const VehicleMaintenanceCalendarView = ({
  onDateChange,
  value,
  schedules = [],
  users = []
}) => {
  const { t } = useTranslation();
  const can = useCan();
  const history = useHistory();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const [activePopup, setActivePopup] = useState();

  const handleDeleteAction = schedule => () => {
    dispatch(handleScheduleDeleteAction({ schedule, t }));
  };

  const handleActivePopup = bool => {
    setActivePopup(bool);
  };

  return (
    <CalendarView
      onChange={onDateChange}
      value={value}
      renderer={day => {
        const schedulesForDay = schedules.filter(schedule => {
          if (!schedule.schedulesOn) {
            return false;
          }
          const scheduledAt = schedule.schedulesOn;
          return (scheduledAt || '').includes(day.date);
        });

        return schedulesForDay.map(schedule => {
          const { status } = schedule;
          const isDisabled = [STATUS.completed, STATUS.cancelled].includes(status);
          const isCancelled = [STATUS.cancelled].includes(status);

          return (
            <Popover
              content={
                <CalendarPopupContent
                  title={schedule.name}
                  wrapButtons
                  rows={preparePopupRows({
                    t,
                    can,
                    history,
                    localization,
                    users,
                    schedule,
                    isCancelled
                  })}
                  actions={preparePopupActions({
                    t,
                    history,
                    schedule,
                    isDisabled,
                    handleDeleteAction,
                    handleActivePopup
                  })}
                />
              }
              trigger="click"
              placement="right"
              key={schedule.id}
              open={activePopup === schedule.id}
              onOpenChange={() => setActivePopup(activePopup ? !activePopup : schedule.id)}
            >
              <div className={style.calendarItemWrapper}>
                <RowStatus showIcon status={statusMap(schedule.status.toLowerCase())}>
                  <Tooltip
                    content={schedule.name}
                    target={
                      <>
                        <div className={style.calendarItemName}>{schedule.name}</div>
                        {schedule.vehicle && (
                          <div className={style.calendarItemVehicle}>{schedule.vehicle?.name}</div>
                        )}
                      </>
                    }
                  />
                </RowStatus>
              </div>
            </Popover>
          );
        });
      }}
    />
  );
};
