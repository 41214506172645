import React from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { EllipsisButton } from 'components/tables/EllipsisButton';
import { getSelectedEventTypeNames } from '../../Scorecard/helpers';
import { GeofencesTypes } from '../Fleets/constants';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 60
});

export const nameCellRenderer = ({ dataKey, parent, rowIndex, rowData, canView }) => {
  return canView ? (
    <Link to={`/settings/geofences/id/${rowData.id}`} key={dataKey}>
      {rowData.name}
    </Link>
  ) : (
    <span>{rowData.name}</span>
  );
};

export const companyCellRenderer = ({ rowData, companies }) => {
  const geofence = rowData;
  const company = companies?.find(comp => comp.id === geofence.companyId);
  return <div>{company && company.name}</div>;
};
export const locationCellRenderer = ({ rowData, locations }) => {
  const geofence = rowData;
  const location = locations?.find(loc => loc.id === geofence.location.id);
  return (
    <div>
      <Link to={`/settings/locations/id/${location && location.id}`}>
        {location && location.name}
      </Link>
    </div>
  );
};
export const fleetsInCellRenderer = ({ parent, rowIndex, rowData, companies }) => {
  const geofenceFleets = rowData.fleets;
  return (
    <CellMeasurer cache={cache} columnIndex={0} parent={parent} rowIndex={rowIndex}>
      <div style={{ padding: '8px 0' }}>
        {geofenceFleets.map(fleet => {
          if (fleet.name === 'No Fleet') {
            return <div key={fleet.id}>{fleet.name}</div>;
          }
          const fleetCompanyName = companies?.find(company => company.id === fleet?.company?.id)
            ?.name;
          return (
            <div key={fleet.id}>
              <Link to={`/settings/fleets/id/${fleet.id}`}>
                {fleet.name} {fleetCompanyName ? `(${fleetCompanyName})` : ''}
              </Link>
            </div>
          );
        })}
      </div>
    </CellMeasurer>
  );
};

export const areaCellRenderer = ({ rowData, localization }) => {
  return <div>{rowData.area_sqm && localization.convertArea(rowData.area_sqm)}</div>;
};

export const ellipsisButtonCellRenderer = ({
  rowData,
  dataKey,
  typeOfEntityToDelete,
  handleDeleteAction,
  geofenceProviders
}) => {
  return (
    <EllipsisButton
      dataKey={dataKey}
      data={rowData}
      hideDelete={geofenceProviders?.includes(rowData.type)}
      hideEdit={geofenceProviders?.includes(rowData.type)}
      path={'/settings/geofences/'}
      typeOfEntityToDelete={typeOfEntityToDelete}
      handleDeleteAction={handleDeleteAction}
      canUse="GEOFENCE"
    />
  );
};

export const scorecardEventsCellRenderer = ({ parent, rowIndex, rowData, localization, t }) => {
  const geofence = rowData;

  const scorecardEvents = getSelectedEventTypeNames(geofence?.features, localization, t);

  return (
    <CellMeasurer cache={cache} columnIndex={1} parent={parent} rowIndex={rowIndex}>
      <div style={{ padding: '8px 0' }}>
        {scorecardEvents.map(event => (
          <div key={event}>{event}</div>
        ))}
      </div>
    </CellMeasurer>
  );
};

export const geofenceTypeCellRenderer = ({ rowData, dataKey, parent, rowIndex }) => {
  return <div>{rowData.type ? GeofencesTypes[rowData.type] : ''}</div>;
};
