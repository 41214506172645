import i18next from 'i18nextConfig';

export const IgnitionStatus = {
  get On() {
    return i18next.t('Tracking.Ignition.ON');
  },
  get Off() {
    return i18next.t('Tracking.Ignition.OFF');
  },
  get Out() {
    return i18next.t('Tracking.Ignition.OUT');
  }
};

export const DriverLogStatus = {
  get LoggedOn() {
    return i18next.t('Home.OndutyTitle');
  },
  get LoggedOff() {
    return i18next.t('Home.OffdutyTitle');
  }
};
