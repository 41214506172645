import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { List, AutoSizer } from 'react-virtualized';
import { Divider, Empty, Space, Button } from 'antd';
import { Spinner } from 'containers/home/Camera/Components/Loading';
import { DailySnapshotRow } from './DailySnapshotRow';
import { FootagePerRow, sizeCalculator } from '../helpers';
import styles from '../Snapshots.module.scss';
import { debounce } from 'lodash';

function _DailySnapshotsTable({
  isLoading,
  snapshots,
  maxDailySnapshotsCount,
  snapshotsCount,
  snapshotYear,
  snapshotMonth,
  onLoadMore,
  allLoaded,
  sortBy
}) {
  const scrollRef = useRef();
  const onDebouncedScoll = useCallback(
    debounce(({ clientHeight, scrollHeight, scrollTop }) => {
      if (scrollRef.current) {
        const isScrollToBottom =
          scrollRef.current.scrollTop < scrollTop &&
          clientHeight + scrollTop >= scrollHeight * 0.75;
        if (isScrollToBottom && !allLoaded) {
          onLoadMore();
        }
      }
      scrollRef.current = { clientHeight, scrollHeight, scrollTop };
    }, 200),
    [scrollRef, allLoaded]
  );

  const { t } = useTranslation();
  const visibleFootageCountPerRow = useMemo(
    () =>
      Math.min(Math.max(maxDailySnapshotsCount, FootagePerRow.Default), FootagePerRow.DailyMode),
    [maxDailySnapshotsCount]
  );

  function rowRenderer({ index, style, key, parent }) {
    if (index >= snapshots.length) {
      return (
        <div key={key} style={style} className={styles.alignItemCenter}>
          {isLoading ? (
            <Spinner size="small" desciption={t('Home.Loading')} />
          ) : (
            <Divider style={{ color: '#86b341' }} plain={!!allLoaded}>
              <Space>
                {`${snapshotsCount} ${t('Home.Snapshots')}`}
                {!allLoaded && (
                  <Button type="primary" size="small" onClick={onLoadMore}>
                    {t('Common.Load More')}
                  </Button>
                )}
              </Space>
            </Divider>
          )}
        </div>
      );
    }
    const item = snapshots[index];
    return (
      <DailySnapshotRow
        t={t}
        key={key}
        positionedFootages={item.positionedFootages}
        isDeviceFailure={item.isDeviceFailure}
        style={style}
        width={parent.props.width}
        visibleFootageCountPerRow={visibleFootageCountPerRow}
        sortBy={sortBy}
        driverId={item.driverId}
        rowDate={item.snapshotDateId}
        snapshotYear={snapshotYear}
        snapshotMonth={snapshotMonth}
      />
    );
  }

  return (
    <div className={styles.snapshotsTableContainer}>
      {snapshots?.length > 0 ? (
        <AutoSizer>
          {({ height, width }) => (
            <>
              <List
                className={styles.snapshotsTable}
                rowCount={snapshots.length + 1}
                height={height}
                width={width - 10}
                rowHeight={sizeCalculator(width, true, visibleFootageCountPerRow).rowHeight}
                overscanRowCount={3}
                rowRenderer={rowRenderer}
                onScroll={onDebouncedScoll}
              />
            </>
          )}
        </AutoSizer>
      ) : isLoading ? (
        <div className={styles.loading}>
          <Spinner size="large" desciption={t('Home.Loading')} />
        </div>
      ) : (
        <Empty description={t('Home.No Snapshots')} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );
}

export const DailySnapshotsTable = React.memo(_DailySnapshotsTable);
