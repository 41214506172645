import React, { useCallback } from 'react';
import { Select, Space } from 'antd';
import './VehicleCalibrate.scss';
import { useTranslation } from 'react-i18next';
import { MeterSourceCalculatedBy } from 'features/meters';

const { Option } = Select;

const MeterSourcesWrapper = ({
  title,
  type,
  onSourceChange,
  source,
  onCalculatedByChange,
  calculatedBy,
  options = []
}) => {
  const { t } = useTranslation();

  const onSourceOptionChange = useCallback(
    sourceOptionValue => {
      const sourceOption = options.find(option => option.value === sourceOptionValue);
      onSourceChange(type, sourceOption);
    },
    [type, onSourceChange, options]
  );

  return (
    <div className="meter-sources-wrapper">
      <div className="title-wrapper">
        <h5>{title}</h5>
      </div>
      <div className="select-wrapper">
        <Space size={24} align="end">
          <div className="event-wrapper">
            <div>{t('Vehicles.Source')}</div>
            <Select
              className="select__element"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={triggerNode => triggerNode.parentNode}
              onChange={onSourceOptionChange}
              placeholder={t('Vehicles.SelectOption')}
              value={!!options.length ? source[type] : ''}
            >
              {options
                .filter(option => option.type === type)
                .map(option => (
                  <Option value={option.value} key={option.label}>
                    {option.label}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="event-wrapper">
            <div>{t('Vehicles.CalculatedBy')}</div>
            <Select
              className="select__element"
              disabled={source[type]?.indexOf('CAN') === -1}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={triggerNode => triggerNode.parentNode}
              onChange={value => onCalculatedByChange(type, value)}
              placeholder={t('Vehicles.SelectOption')}
              value={calculatedBy[type]}
            >
              <Option value={MeterSourceCalculatedBy.Actual}>{t('Vehicles.Actual')}</Option>
              <Option value={MeterSourceCalculatedBy.Elapsed}>{t('Vehicles.Elapsed')}</Option>
            </Select>
          </div>
        </Space>
      </div>
    </div>
  );
};

export default MeterSourcesWrapper;
