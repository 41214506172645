import React, { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useIsLoadingCurrentCompany,
  useIsFetchingCurrentCompanyServices
} from 'features/company/companySlice';
import { setBackButton, setPageTitle, addBreadcrumbs } from 'features/page/pageSlice';
import { LoadingCentered } from 'components/loading/Loading';
import { NoAccessBody } from 'components/pages/NoAccess';
import { PATHS } from '../../utils/constants';

export const LoadingOrNoAccess = ({ suspendedTitle = '' }) => {
  const { t } = useTranslation();
  const isFetchCompany = useIsLoadingCurrentCompany();
  const isFetchCompanyServices = useIsFetchingCurrentCompanyServices();
  const pageTitles = useMemo(() => [t(suspendedTitle), t('CompanyConfig.Title')], [
    t,
    suspendedTitle
  ]);

  return isFetchCompany || isFetchCompanyServices ? (
    <LoadingBody t={t} pageTitles={pageTitles} />
  ) : (
    <NoAccessBody />
  );
};

const LoadingBody = ({ t, pageTitles = [] }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const [current, ...prevs] = pageTitles;
    dispatch(setPageTitle(t(current)));
    dispatch(setBackButton(false));
    prevs.forEach(prevPage => {
      dispatch(
        addBreadcrumbs([
          {
            breadcrumbName: prevPage,
            path: PATHS.COMPANY_CONFIG
          },
          {}
        ])
      );
    });
    return () => {
      dispatch(addBreadcrumbs([]));
    };
  }, [t, dispatch, pageTitles]);
  return <LoadingCentered />;
};
