import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { cloneDeep, toLower } from 'lodash';
import { useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import useDebounce from 'utils/hooks/useDebounce';
import { useDrivers } from 'features/users/usersSlice';
import { useBranches, useIsBranchesFetched } from 'features/locations/locationsSlice';
import { useDispatch } from 'react-redux';
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import LoaderTable from 'components/tables/LoaderTable';
import { driversCols } from './Columns';
import { SentinelCellCache, SentinelRowRender } from './RowRenderer';
import { useLocalization } from 'features/localization/localizationSlice';
import {
  fetchSentinelStatus,
  useSentinelDriversStatus,
  useSentinelFetchingStatus
} from './status/statusSlice';
import { FatigueToolbar } from 'features/fatigue/Components';
import {
  useDevices,
  useFleets,
  useVehicles,
  useIsFetchingFinished as useIsFleetsFetchingFinished
} from 'features/fleets/fleetsSlice';
import { useLocalCache } from 'utils/hooks/useLocalCache';
import { useUser } from 'features/user/userSlice';
import { useLocation } from 'react-router';
import { useUserPreferences } from 'features/user/userPreferencesSlice';
import { useTimer } from 'utils/hooks/useTimer';
import { canDriverMessaging, useRecipientTree } from 'features/messaging/hooks';
import { useRulesets } from 'features/rulesets/rulesetsSlice';

const expandTableHeight = 134;

const formatListForDropdownSelect = list => {
  let updatedList = [];
  list.forEach(val => {
    const listItem = {
      label: val.name,
      checked: true,
      id: val.id
    };
    updatedList.push(listItem);
  });

  return updatedList;
};

function isVehicleInFleet(vehicleId, fleetId, fleets) {
  if (vehicleId != null && fleetId != null && fleets != null) {
    let fleet = null;
    if (fleetId === -1) {
      fleet = fleets.find(f => f.name == null);
    } else {
      fleet = fleets.find(f => f.id === fleetId);
    }

    if (fleet != null) {
      return fleet.vehicles.some(v => v.id === vehicleId);
    }
  } else if (!vehicleId && fleetId === -1 && fleets != null) {
    return true;
  }

  return false;
}

function isDeviceInFleet(deviceId, fleetId, fleets) {
  if (deviceId != null && fleetId != null && fleets != null) {
    let fleet = null;
    if (fleetId === -1) {
      fleet = fleets.find(f => f.name == null);
    } else {
      fleet = fleets.find(f => f.id === fleetId);
    }

    if (fleet != null) {
      return (
        fleet.vehicles?.some(v => v.devices?.find(d => d.id === deviceId)) ||
        fleet.devices?.some(d => d.id === deviceId)
      );
    }
  } else if (!deviceId && fleetId === -1 && fleets != null) {
    return true;
  }

  return false;
}

const gridFlexStyle = {
  display: 'flex',
  flex: '1 0 0',
  flexDirection: 'column',
  height: '100%'
};

const flexStyle = { flex: '1 0 0' };

export const SentinelDriverTable = ({ filter }) => {
  const dispatch = useDispatch();
  const localization = useLocalization();

  useEffect(() => {
    dispatch(setPageTitle('Fatigue - Sentinel'));
    dispatch(setBackButton(false));
  }, [dispatch]);

  const currentUser = useUser();
  const currentCompany = useCurrentCompany();
  const location = useLocation();
  const allFleets = useFleets();
  const isFleetsFetched = useIsFleetsFetchingFinished();
  const allVehicles = useVehicles();
  const allDevices = useDevices();

  const driverList = useDrivers();
  const allDrivers = useMemo(() => {
    return driverList?.filter(driver => driver.rulesets && driver.rulesets.length > 0);
  }, [driverList]);

  const isListEmpty = allDrivers?.length <= 0;
  const allBranches = useBranches();
  const isBranchesFetched = useIsBranchesFetched();
  const companies = useSubCompanies();

  const [filterText, setFilterText] = useState('');
  const [filterBranches, setFilterBranches] = useState([]);
  const debouncedSearchText = useDebounce(filterText, 300);
  const [filterCompanies, setFilterCompanies] = useState(
    formatListForDropdownSelect([{ id: 0, name: 'All Companies' }, ...companies])
  );
  const [rowsExpandStatus, setRowsExpandStatus] = useState({});
  const sentinelDriversStatus = useSentinelDriversStatus();
  const [filterFleets, setFilterFleets] = useState([]);
  const [sortBy, setSortBy] = useState('Name');
  const [orderBy, setOrderBy] = useState('0');
  const userPreferences = useUserPreferences();
  const fetchRef = useRef(null);
  const recipientTree = useRecipientTree();
  const rulesets = useRulesets();

  const [filterOptions, setFilterOptions, context] = useLocalCache('filterOptions', {
    userId: currentUser.id,
    pagePathname: location.pathname,
    companyId: currentCompany.id
  });
  const [filterOptionsContexts, setFilterOptionsContexts] = useState({});

  const {
    isCompaniesRestored,
    isBranchesRestored,
    isFleetsOptionsRestored,
    isNormalFilterRestored,
    currentFilterOptionsContext
  } = useMemo(() => {
    const currentFilterOptionsContext = JSON.stringify(context);
    return {
      isCompaniesRestored: filterOptionsContexts[currentFilterOptionsContext]?.isCompaniesRestored,
      isBranchesRestored: filterOptionsContexts[currentFilterOptionsContext]?.isBranchesRestored,
      isFleetsOptionsRestored:
        filterOptionsContexts[currentFilterOptionsContext]?.isFleetsOptionsRestored,
      isNormalFilterRestored:
        filterOptionsContexts[currentFilterOptionsContext]?.isNormalFilterRestored,
      currentFilterOptionsContext
    };
  }, [context, filterOptionsContexts]);

  const tableColumns = useMemo(() => {
    if (allDrivers?.length <= 0 || rulesets?.length <= 0) {
      return driversCols;
    }

    const allNZRule = allDrivers.every(
      d =>
        d.rulesets?.length <= 0 ||
        d.rulesets.every(r => rulesets.find(rl => rl.name === r.ruleset)?.region === 'NZ')
    );
    if (allNZRule) {
      const cols = cloneDeep(driversCols);
      const majorCol = cols.splice(
        driversCols.findIndex(c => c.dataKey === 'majorRestDue'),
        1
      )[0];
      const extraWidth = parseFloat(majorCol.width);
      const companyBranchCol = cols.find(c => c.dataKey === 'companyBranch');
      companyBranchCol.width = parseFloat(companyBranchCol.width) + extraWidth / 2 + '%';
      const locationCol = cols.find(c => c.dataKey === 'location');
      locationCol.width = parseFloat(locationCol.width) + extraWidth / 2 + '%';
      return cols;
    } else {
      return driversCols;
    }
  }, [allDrivers, rulesets]);

  useEffect(() => {
    if (isNormalFilterRestored) {
      return;
    }

    if (filterOptions) {
      setFilterOptionsContexts(c => ({
        ...c,
        [currentFilterOptionsContext]: {
          ...c[currentFilterOptionsContext],
          isNormalFilterRestored: true
        }
      }));
      setSortBy(
        filterOptions.sortBy &&
          tableColumns.some(
            c =>
              c.sort?.name === filterOptions.sortBy ||
              c.sort?.some?.(sc => sc.name === filterOptions.sortBy)
          )
          ? filterOptions.sortBy
          : 'Name'
      );
      setOrderBy(filterOptions.orderBy || '0');
      setFilterText(filterOptions.filterText || '');
    }
  }, [filterOptions, isNormalFilterRestored, tableColumns, currentFilterOptionsContext]);

  //restore company filters
  useEffect(() => {
    if (isCompaniesRestored) {
      return;
    }

    if (filterOptions) {
      setFilterOptionsContexts(c => ({
        ...c,
        [currentFilterOptionsContext]: {
          ...c[currentFilterOptionsContext],
          isCompaniesRestored: true
        }
      }));
      setFilterCompanies(companies => {
        return companies.map(c => {
          const storedOption = filterOptions?.filterCompanies?.find(sc => sc.id === c.id);
          if (storedOption) {
            c.checked = storedOption.checked;
          }
          return c;
        });
      });
    }
  }, [filterOptions, isCompaniesRestored, currentFilterOptionsContext]);

  const getDriverStatus = useCallback(() => {
    if (
      allDrivers?.length <= 0 ||
      currentUser === null ||
      currentCompany === null ||
      !allDrivers.some(d => d.company.id === currentCompany.id)
    )
      return;

    const driverList = allDrivers?.map(d => d.id);
    dispatch(fetchSentinelStatus(currentUser.auth.key, currentCompany.id, driverList, fetchRef));
  }, [allDrivers, dispatch, currentUser, currentCompany, fetchRef]);

  useEffect(() => {
    getDriverStatus();
    return () => {
      if (fetchRef.current != null) {
        fetchRef.current(); //call the assigned cancel funciton
        fetchRef.current = null;
      }
    };
  }, [getDriverStatus, fetchRef]);

  const getFilterBranches = useCallback(
    filterCompanies => {
      const checkedCompanies = filterCompanies?.filter(c => c.checked);
      const companiesBranches = allBranches.filter(
        b => b.type.id === 1 && checkedCompanies.find(c => c.id === b.companyId)
      );
      //always add All Branches
      companiesBranches.splice(0, 0, { name: 'All Branches', label: 'All Branches', id: 0 });

      if (!companiesBranches.find(b => b.id === -1)) {
        companiesBranches.push({ name: 'No Branch', label: 'No Branch', id: -1 });
      }
      return companiesBranches.map(b => {
        return {
          label: b.name,
          id: b.id,
          checked: true
        };
      });
    },
    [allBranches]
  );

  const getFilterFleets = useCallback(
    filterCompanies => {
      const checkedCompanies = filterCompanies?.filter(c => c.checked);
      const filteredFleets = allFleets.filter(f =>
        checkedCompanies.some(c => c.id === f.company?.id)
      );
      if (filteredFleets.length > 0) {
        filteredFleets.splice(0, 0, { name: 'All Fleets', label: 'All Fleets', id: 0 });
      }
      if (checkedCompanies.length > 0) {
        filteredFleets.push({ name: 'No Fleet', label: 'No Fleet', id: -1 });
      }
      return filteredFleets.map(f => {
        return {
          id: f.id,
          label: f.name,
          checked: true
        };
      });
    },
    [allFleets]
  );

  useEffect(() => {
    setFilterBranches(getFilterBranches(filterCompanies));
  }, [filterCompanies, getFilterBranches]);

  //restore branches filters
  useEffect(() => {
    if (!isBranchesFetched || isBranchesRestored || !isCompaniesRestored) {
      return;
    }

    if (filterOptions?.filterBranches) {
      setFilterOptionsContexts(c => ({
        ...c,
        [currentFilterOptionsContext]: {
          ...c[currentFilterOptionsContext],
          isBranchesRestored: true
        }
      }));
      setFilterBranches(branches => {
        return branches.map(b => {
          const branchOption = filterOptions?.filterBranches?.find(fb => b.id === fb.id);
          if (branchOption) {
            b.checked = branchOption.checked;
          }
          return b;
        });
      });
    }
  }, [
    filterOptions,
    isBranchesFetched,
    isBranchesRestored,
    isCompaniesRestored,
    currentFilterOptionsContext
  ]);

  useEffect(() => {
    setFilterFleets(getFilterFleets(filterCompanies));
  }, [filterCompanies, getFilterFleets]);

  //restore fleets options
  useEffect(() => {
    if (!isFleetsFetched || isFleetsOptionsRestored || !isCompaniesRestored) {
      return;
    }

    if (filterOptions?.filterFleets) {
      setFilterOptionsContexts(c => ({
        ...c,
        [currentFilterOptionsContext]: {
          ...c[currentFilterOptionsContext],
          isFleetsOptionsRestored: true
        }
      }));

      setFilterFleets(fleets => {
        return fleets.map(fleet => {
          const fleetOption = filterOptions?.filterFleets?.find(fo => fo.id === fleet.id);
          if (fleetOption) {
            fleet.checked = fleetOption.checked;
          }
          return fleet;
        });
      });
    }
  }, [
    isFleetsFetched,
    filterOptions,
    isFleetsOptionsRestored,
    isCompaniesRestored,
    currentFilterOptionsContext
  ]);

  const tableDrivers = useMemo(() => {
    return allDrivers
      ?.filter(row => {
        const status = sentinelDriversStatus?.[row.id];
        if (filter === 'Working_Driving') {
          return status?.lastStatusEvent?.type?.match(/onduty|drive|startwork|\bwork\b/i) != null;
        }

        if (filter === 'Resting') {
          return status?.lastStatusEvent?.type?.match(/rest|off|sleep|break|stopwork/i) != null;
        }
        return true;
      })
      .map(d => {
        const row = cloneDeep(d);
        row.expand = false;
        row.handleExpand = expand => {
          setRowsExpandStatus(prev => {
            return { ...prev, [row.id]: expand };
          });
        };
        row.sentinelStatus = sentinelDriversStatus?.[row.id];

        let fleetsOf = null;
        let name = '-';
        if (row.sentinelStatus?.lastEvent?.vehicle != null) {
          fleetsOf = allFleets
            .filter(
              f =>
                f.name && f.vehicles?.find(v => v.id === row.sentinelStatus?.lastEvent?.vehicle.id)
            )
            ?.map(f => f.name);
          name =
            allVehicles?.find(v => v.id === row.sentinelStatus?.lastEvent?.vehicle.id)?.name || '-';
        } else if (row.sentinelStatus?.lastEvent?.device != null) {
          fleetsOf = allFleets
            .filter(
              f =>
                f.name &&
                f.vehicles?.devices?.find(d => d.id === row.sentinelStatus?.lastEvent?.device.id)
            )
            ?.map(f => f.name);
          name =
            allDevices?.find(d => d.id === row.sentinelStatus?.lastEvent?.device.id)?.name || '-';
        }

        if (
          !fleetsOf?.length &&
          (row.sentinelStatus?.lastEvent?.vehicle != null ||
            row.sentinelStatus?.lastEvent?.device != null)
        ) {
          fleetsOf.push('No Fleet');
        }

        row.tmpData = {
          fleet: { name: fleetsOf?.join(',') || '-' },
          vehicle: { name: name }
        };

        row.canMessaging = canDriverMessaging(recipientTree, row.id);

        return row;
      });
  }, [
    allDrivers,
    sentinelDriversStatus,
    allFleets,
    allVehicles,
    allDevices,
    filter,
    recipientTree
  ]);

  const handleExpandRows = useCallback(
    expand => {
      const rowsExpandStatus = {};
      tableDrivers &&
        tableDrivers.forEach(d => {
          rowsExpandStatus[d.id] = expand;
        });

      setRowsExpandStatus(rowsExpandStatus);
    },
    [tableDrivers]
  );

  const sortColumns = useMemo(() => {
    return tableColumns
      .filter(c => c.sort)
      .flatMap(c => c.sort)
      .map(c => {
        return { id: c.name, label: c.name, name: c.name, sortFn: c.sortFn };
      });
  }, [tableColumns]);

  const drivers = useMemo(() => {
    let filteredDrivers = Object.values(tableDrivers).filter(driver => {
      let validDriver = true;
      // Filter by search field
      if (debouncedSearchText) {
        const searchArray = debouncedSearchText.split(' ');
        searchArray.forEach(splitValue => {
          validDriver =
            validDriver &&
            (toLower(driver.name).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.firstName).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.lastName).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.username).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.licenceState).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.licenceNumber).indexOf(toLower(splitValue)) > -1 ||
              toLower(driver.status).indexOf(toLower(splitValue)) > -1);
        });
      }
      // Filter by companies
      const checkedCompaniesIds = filterCompanies
        .filter(company => company.checked)
        .map(company => parseInt(company.id, 10));
      if (!(checkedCompaniesIds.indexOf(0) > -1)) {
        validDriver =
          validDriver && checkedCompaniesIds.indexOf(parseInt(driver.companyId, 10)) > -1;
      }
      // Filter by branches
      const checkedBranchesIds = filterBranches
        .filter(branch => branch.checked)
        .map(branch => parseInt(branch.id, 10));
      if (!(checkedBranchesIds.indexOf(0) > -1)) {
        validDriver =
          validDriver &&
          checkedBranchesIds.indexOf(driver.location ? parseInt(driver.location.id, 10) : -1) > -1;
      }

      //filter by fleets
      if (filterFleets.length > 0 && !filterFleets[0].checked) {
        validDriver =
          validDriver &&
          filterFleets
            .filter(f => f.checked)
            .some(
              f =>
                isVehicleInFleet(driver.sentinelStatus?.lastEvent?.vehicle?.id, f.id, allFleets) ||
                isDeviceInFleet(driver.sentinelStatus?.lastEvent?.device?.id, f.id, allFleets)
            );
      }
      return validDriver;
    });

    filteredDrivers = filteredDrivers.map(driver => {
      driver.expand = rowsExpandStatus[driver.id] || false;
      driver.branch = filterBranches.find(
        branch => driver.location && branch.id === driver.location.id
      );
      driver.company = filterCompanies.find(
        company => driver.companyId && company.id === driver.companyId
      );
      return driver;
    });

    filteredDrivers.sort(sortColumns.find(c => c.name === sortBy)?.sortFn);
    if (orderBy === '1') {
      filteredDrivers.reverse();
    }
    return filteredDrivers;
  }, [
    tableDrivers,
    rowsExpandStatus,
    filterCompanies,
    filterBranches,
    debouncedSearchText,
    allFleets,
    filterFleets,
    sortBy,
    sortColumns,
    orderBy
  ]);

  const [rowRender] = useState(() => {
    return rowProps => SentinelRowRender({ ...rowProps, localization });
  });

  SentinelCellCache.clearAll();

  const getRowHeight = useCallback(
    ({ index, ...props }) => {
      let rowHeight = 0;
      for (let i = 0; i < tableColumns.length; i++) {
        rowHeight = Math.max(rowHeight, SentinelCellCache.getHeight(index, i));
      }
      if (drivers[index]?.expand) {
        rowHeight += expandTableHeight;
      }
      return rowHeight;
    },
    [drivers]
  );

  const noDriversFound = isListEmpty || drivers.length < 1;
  const NoDriversFound = () => (
    <div style={{ flex: '1 0 0', padding: '16px', textAlign: 'center', color: '#b6b6b6' }}>
      No Drivers found.
    </div>
  );

  const handleSearchChanged = useCallback(
    text => {
      setFilterText(text);
      setFilterOptions({
        ...filterOptions,
        filterText: text
      });
    },
    [filterOptions]
  );

  const handleCompanyChanged = useCallback(
    companies => {
      setFilterCompanies(companies);
      setFilterOptions({
        ...filterOptions,
        filterCompanies: companies,
        filterFleets: getFilterFleets(companies),
        filterBranches: getFilterBranches(companies)
      });
    },
    [filterOptions, getFilterBranches, getFilterFleets]
  );

  const handleBranchChanged = useCallback(
    branches => {
      setFilterBranches(branches);
      setFilterOptions({
        ...filterOptions,
        filterBranches: branches
      });
    },
    [filterOptions]
  );

  const handleFleetChanged = useCallback(
    fleets => {
      setFilterFleets(fleets);
      setFilterOptions({
        ...filterOptions,
        filterFleets: fleets
      });
    },
    [filterOptions]
  );

  const handleSortChanged = useCallback(
    name => {
      setSortBy(name);
      setFilterOptions({
        ...filterOptions,
        sortBy: name
      });
    },
    [filterOptions]
  );

  const handleOrderByChanged = useCallback(
    val => {
      setOrderBy(val);
      setFilterOptions({
        ...filterOptions,
        orderBy: val
      });
    },
    [filterOptions]
  );

  useTimer(Math.max(userPreferences?.refresh?.tracking || 60, 60) * 1000, getDriverStatus);

  const { isFetchingDriverSentinalStatus, isFetchingUserSession } = useSentinelFetchingStatus(
    currentCompany?.id
  );
  const loaderTableColumns = useMemo(
    () =>
      tableColumns.map(col => ({
        ...col,
        cellRenderer: col.cellRenderer
          ? props =>
              col.cellRenderer({
                ...props,
                isLoading: isFetchingDriverSentinalStatus(props.rowData.id)
              })
          : null
      })),
    [tableColumns, isFetchingDriverSentinalStatus]
  );

  return (
    <div style={gridFlexStyle}>
      <FatigueToolbar
        filterText={filterText}
        onSearchChanged={handleSearchChanged}
        filterCompanies={filterCompanies}
        onCompanyChanged={handleCompanyChanged}
        filterBranches={filterBranches}
        onBranchChanged={handleBranchChanged}
        filterFleets={filterFleets}
        onFleetChanged={handleFleetChanged}
        driverLength={drivers.length}
        onExpandRows={handleExpandRows}
        disableExpand={isFetchingUserSession}
        sortBy={sortBy}
        onSortChanged={handleSortChanged}
        sortLists={sortColumns}
        orderBy={orderBy}
        onOrderByChanged={handleOrderByChanged}
      />
      <div style={flexStyle}>
        <LoaderTable
          rows={drivers}
          cols={loaderTableColumns}
          emptyResult={noDriversFound ? <NoDriversFound /> : null}
          rowHeight={getRowHeight}
          rowRenderer={rowRender}
          cellCache={SentinelCellCache}
        />
      </div>
    </div>
  );
};
