import React, { useEffect, useState, useRef } from 'react';
import { Carousel, Alert } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Comparators } from 'utils/sorting';

import { getVehicleVisualization } from '../Data';
import { VehicleVis } from '.';

import styles from './vis.module.scss';
import { useTranslation } from 'react-i18next';

const PrevArrow = ({ className, style, onClick }) => {
  return (
    <LeftOutlined
      className={className + ' ' + styles.arrowNav}
      {...style}
      type="left-circle"
      onClick={onClick}
    />
  );
};

const NextArrow = ({ className, style, onClick }) => {
  return (
    <RightOutlined
      className={className + ' ' + styles.arrowNav}
      {...style}
      type="right-circle"
      onClick={onClick}
    />
  );
};

export const VehicleVisSelector = ({
  axleGroups,
  axles,
  visualization,
  showHeader = false,
  onVisChanged
}) => {
  const { t } = useTranslation();
  const [visData, setVisData] = useState(null);
  const [autoPlay, setAutoPlay] = useState(false);

  const carousel = useRef();

  useEffect(() => {
    if (axleGroups && axles) {
      setVisData(
        getVehicleVisualization(axleGroups, axles).sort(Comparators.String('visualization.name'))
      );
    } else {
      setVisData(null);
    }
  }, [axleGroups, axles]);

  useEffect(() => {
    if (visData?.length) {
      if (visualization?.id) {
        const visIndex = visData.findIndex(visItem => {
          return visItem.visualization.id === visualization.id;
        });
        carousel && carousel.current && carousel.current.goTo(visIndex);
      } else {
        onVisChanged && onVisChanged(visData[0].visualization, true);
      }
    }
  }, [visData]);

  const onCarouselChange = index => {
    onVisChanged && onVisChanged(visData[index].visualization, false);
  };

  const onCarouselClick = e => {
    setAutoPlay(false);
  };

  return visData?.length > 0 ? (
    <>
      {showHeader && (
        <label>{`${visData.length > 1 ? `${t('Common.Select')} ` : ''}${t(
          'MassManagement.Visualization'
        )}`}</label>
      )}
      <Carousel
        arrows
        prevArrow={<PrevArrow />}
        nextArrow={<NextArrow />}
        autoplay={autoPlay}
        afterChange={onCarouselChange}
        onClick={onCarouselClick}
        dots={{ className: styles.carouselNav }}
        ref={carousel}
      >
        {visData?.map((visItem, index) => (
          <VehicleVis
            key={`${visItem.id || 'visItem'}-${index}`}
            visualization={visItem.visualization}
            showVisName={true}
            visNameSuffix={visData.length > 1 && '(' + (index + 1) + ' of ' + visData.length + ')'}
          />
        ))}
      </Carousel>
    </>
  ) : (
    <Alert
      message={t('MassManagement.Validation.NoMatchingVisualisationsFound')}
      type="info"
      showIcon
    />
  );
};
