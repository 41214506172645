import React from 'react';
import { WeeklyScheduleSelectorViewOnly } from 'components/ScheduleSelector/WeeklyScheduleSelector';
import { Space } from 'antd';
import { useCanEveryService, services } from 'features/permissions';

export const ActiveTimePeriodRow = ({ title, period }) => {
  const isNPIEnabled = useCanEveryService(services.NPI);
  if (!isNPIEnabled) {
    return null;
  }
  return (
    <>
      {period && (
        <Space direction="vertical" align="center">
          <div className="activeTimePeriodTableTitle">{title}</div>
          <WeeklyScheduleSelectorViewOnly period={period} />
        </Space>
      )}
    </>
  );
};
