import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Can, entities } from 'features/permissions';

import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { BUTTON_IDS } from 'utils/globalConstants';
import './EllipsisButton.scss';

export const EllipsisButton = ({
  data,
  path,
  handleDeleteAction,
  typeOfEntityToDelete,
  hideEdit,
  hideDelete,
  hideCopy,
  showCalibrate,
  showGPIO,
  showComplete,
  enableRestoreAction,
  handleAction,
  canEnableLoginAsUser,
  handleLogInAsUser,
  canUse
}) => {
  const { t } = useTranslation();

  const ActionsContainer = data => (
    <Popover
      id="popover-device-menu"
      style={{ minWidth: '12rem', transform: 'translate(1235px, 257px, 0px' }}
    >
      <div>
        <ul className={'tn-menu-context'}>
          <Can everyEntity={[entities[`${canUse}_VIEW`]]}>
            <Link
              to={{
                pathname: path + `id/${data.id ? data.id : data.Id}`,
                state: { dataToEdit: data }
              }}
            >
              <li>{t('Common.EllipsisButton.View')}</li>
            </Link>
          </Can>
          {!hideEdit && (
            <Can everyEntity={[entities[`${canUse}_UPDATE`]]}>
              <Link
                to={{
                  pathname:
                    data.isDVIR && canUse === entities.PRETRIPCHECKLIST
                      ? `${path}editDvir/id/${data.id ? data.id : data.Id}`
                      : `${path}edit/id/${data.id ? data.id : data.Id}`,
                  state: { dataToEdit: data }
                }}
              >
                <li>{t('Common.EllipsisButton.Edit')}</li>
              </Link>
            </Can>
          )}
          {!hideCopy && (
            <Can everyEntity={[entities[`${canUse}_COPY`]]}>
              <Link
                to={{
                  pathname: `${path}copy/id/${data.id ? data.id : data.Id}`,
                  state: { dataToEdit: data }
                }}
              >
                <li>{t('Common.EllipsisButton.Copy')}</li>
              </Link>
            </Can>
          )}
          {showCalibrate && (
            <Can everyEntity={[entities[`${canUse}_UPDATE`]]}>
              <Link
                to={{
                  pathname: `${path}calibrate/id/${data.id ? data.id : data.Id}`,
                  state: { dataToEdit: data }
                }}
              >
                <li>{t('Common.EllipsisButton.Calibrate')}</li>
              </Link>
            </Can>
          )}
          {showGPIO && (
            <Can everyEntity={[entities[`${canUse}_UPDATE`]]}>
              <Link
                to={{
                  pathname: `${path}gpio/${data.id ? data.id : data.Id}`,
                  state: { dataToEdit: data }
                }}
              >
                <li>{t('Common.EllipsisButton.GPIO')}</li>
              </Link>
            </Can>
          )}
          {showComplete && (
            <Can everyEntity={[entities[`${canUse}_UPDATE`]]}>
              <Link
                to={{
                  pathname: `${path}complete/${data.id ? data.id : data.Id}`,
                  state: { dataToEdit: data }
                }}
              >
                <li>{t('Common.EllipsisButton.Complete')}</li>
              </Link>
            </Can>
          )}
          {canEnableLoginAsUser && (
            <li onClick={() => handleLogInAsUser(data)}>{t('Users.LogInAsUser.LogInAsUser')}</li>
          )}
          {!hideDelete && (
            <Can everyEntity={[entities[`${canUse}_DESTROY`]]}>
              <li
                style={{
                  color: 'red'
                }}
                onClick={() => {
                  confirmationModal(
                    `${t('Common.DeleteButton')} ${data.name || data.Name || data.username}`,
                    `${t('Common.SureDelete')} ${typeOfEntityToDelete} ${data.name ||
                      data.Name ||
                      data.username}?`,
                    t('Common.DeleteButton'),
                    t('Common.CancelButton'),
                    handleDeleteAction(data),
                    'delete'
                  );
                  document.body.click();
                }}
              >
                {t('Common.EllipsisButton.Delete')}
              </li>
            </Can>
          )}
          {enableRestoreAction && (
            <Can everyEntity={[entities[`${canUse}_RESTORE`]]}>
              <li
                onClick={() => {
                  handleAction({ data, action: 'restore' });
                  document.body.click();
                }}
              >
                {t('Common.EllipsisButton.Restore')}
              </li>
            </Can>
          )}
        </ul>
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="left"
      rootClose={true}
      overlay={ActionsContainer(data)}
    >
      <button
        id={BUTTON_IDS.tableEllipsis}
        style={{ border: 'none' }}
        className={'tn-btn-elipsis'}
        onClick={() => {}}
      >
        <i style={{ fontSize: '26px' }} className={'tn-i-elipsis'} />
      </button>
    </OverlayTrigger>
  );
};
