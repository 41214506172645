import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  status,
  loadingFailed,
  loadingStarted,
  loadingFinished,
  actionTypes
} from 'utils/reduxFetchingUtils';
import { ewdAPI } from 'features/ewd/endpoints/ewdAPI';
import moment from 'moment';
import { fetchViolations } from '../violations/violationsSlice';
import { useMemo } from 'react';

export const initialState = {
  events: {
    list: [],
    status: status
  },
  checkpoint: {
    checkpoint: 0,
    status: status
  },
  eventId: null,
  period: {
    fromDate: null,
    toDate: null
  }
};

const eventsSlice = createSlice({
  name: 'ewdEvents',
  initialState: initialState,
  reducers: {
    fetchEventsFailure(state, { payload }) {
      state.events.list = [];
      state.events.status = loadingFailed(state, payload);
    },
    fetchEventsStart(state, { payload }) {
      state.events.status = loadingStarted(state, payload);
    },
    fetchEventsSuccess(state, { payload }) {
      state.events.list = payload;
      state.events.status = loadingFinished(state, payload);
    },
    fetchCheckpointFailure(state, { payload }) {
      state.checkpoint.status = loadingFailed(state, payload);
    },
    fetchCheckpointStart(state, { payload }) {
      state.checkpoint.status = loadingStarted(state, payload);
    },
    fetchCheckpointSuccess(state, { payload }) {
      state.checkpoint.checkpoint = payload;
      state.checkpoint.status = loadingFinished(state, payload);
    },
    setEventId(state, action) {
      state.eventId = action.payload;
    },
    setPeriod(state, action) {
      state.period = {
        fromDate: action.payload.fromDate,
        toDate: action.payload.toDate
      };
    },
    refetchSentinelEvents(state, action) {
      state.refetchEvents = action.payload;
    },
    setCheckpoint(state, action) {
      state.checkpoint = action.payload;
    }
  }
});

export const {
  fetchEventsStart,
  fetchEventsSuccess,
  fetchEventsFailure,
  fetchCheckpointStart,
  fetchCheckpointSuccess,
  fetchCheckpointFailure,
  setEventId,
  setPeriod,
  refetchSentinelEvents,
  setCheckpoint
} = eventsSlice.actions;

export const fetchEvents = driverId => (dispatch, getState) => {
  const fetching = getState().ewd.events.status.fetching;
  const jwt = getState().user?.currentEWDUser?.auth?.token;
  const from = getState().ewd.period.fromDate;
  const to = getState().ewd.period.toDate;
  const driverTimezone = getState().ewd.currentDriver?.timeZone || 'Australia/Sydney';

  if (jwt && fetching !== actionTypes.processing && from && to) {
    try {
      dispatch(fetchEventsStart());
      //conver to driver's time zone
      const dateFrom = moment
        .tz(moment(from).format('YYYY-MM-DDTHH:mm:ss'), driverTimezone)
        .format();
      const dateTo = moment.tz(moment(to).format('YYYY-MM-DDTHH:mm:ss'), driverTimezone).format();
      const req = ewdAPI.driverEvents(driverId, dateFrom, dateTo);
      req
        .then(result => {
          if (result?.error) {
            dispatch(fetchEventsFailure(result.error));
            return;
          }
          dispatch(fetchEventsSuccess(result));
        })
        .catch(err => {
          return dispatch(fetchEventsFailure(err?.toString()));
        });
    } catch (err) {
      dispatch(fetchEventsFailure(err.toString()));
    }
  }
};

export const fetchCheckpoint = driverId => (dispatch, getState) => {
  const fetching = getState().ewd.checkpoint.status.fetching;
  const jwt = getState().user?.currentEWDUser?.auth?.token;
  if (jwt && fetching !== actionTypes.processing) {
    try {
      dispatch(fetchCheckpointStart());
      const req = ewdAPI.getCheckpoint(driverId);
      req.then(result => {
        if (result?.error) {
          dispatch(fetchCheckpointFailure(result.error));
          return;
        }
        dispatch(fetchCheckpointSuccess(result));
      });
    } catch (err) {
      dispatch(fetchCheckpointFailure(err.toString()));
    }
  }
};

export const fetchCheckpointAndViolations = driverId => (dispatch, getState) => {
  const fetching = getState().ewd.checkpoint.status.fetching;
  const jwt = getState().user?.currentEWDUser?.auth?.token;
  if (jwt && fetching !== actionTypes.processing) {
    try {
      dispatch(fetchCheckpointStart());
      const req = ewdAPI.getCheckpoint(driverId);
      req.then(result => {
        if (result?.error) {
          dispatch(fetchCheckpointFailure(result.error));
          return;
        }
        dispatch(fetchCheckpointSuccess(result));
        dispatch(fetchViolations(driverId));
      });
    } catch (err) {
      dispatch(fetchCheckpointFailure(err.toString()));
    }
  }
};

export const useEvents = () => {
  const events = useSelector(state => state.ewd.events.list);
  return events;
};

export const useCheckpoint = () => {
  const events = useSelector(state => state.ewd.checkpoint.checkpoint);
  return events;
};

export const useIsFetchingEWDDriverRecords = _ => {
  const isFetchingEvents = useSelector(state => state.ewd.events.status.fetching);
  const isFetchingCheckPoint = useSelector(state => state.ewd.checkpoint.status.fetching);
  const isFetchingViolations = useSelector(state => state.ewd.violations.status.fetching);
  const isFetchingAnnotations = useSelector(state => state.ewd.annotations.status.fetching);

  const isFetching = useMemo(
    () =>
      isFetchingEvents === actionTypes.processing ||
      isFetchingCheckPoint === actionTypes.processing ||
      isFetchingViolations === actionTypes.processing ||
      isFetchingAnnotations === actionTypes.processing,
    [isFetchingEvents, isFetchingCheckPoint, isFetchingViolations, isFetchingAnnotations]
  );
  return isFetching;
};

export default eventsSlice.reducer;
