import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { logout } from 'features/user/userSlice';
import { api } from 'utils/api';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import i18next from 'i18next';

export const STANDARD_SERVICE = 'SERVICE';
export const STANDARD_ENTITY = 'ENTITY';
export const NOENTITY = 'MISSING';

export const CREATE = '_CREATE';
export const UPDATE = '_UPDATE';
export const RESTORE = '_RESTORE';
export const DESTROY = '_DESTROY';
export const AUDIT = '_AUDIT';
export const VIEW = '_VIEW';

export const initialState = {
  services: [],
  entities: [],
  error: null,
  isFetching: false,
  hasFetched: false
};

export const permissions = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    getPermissionsStarted(state) {
      state.isFetching = true;
    },
    getPermissionsSuccess(state, action) {
      state.entities = action.payload.permissions;
      state.services = action.payload.services;
      state.error = null;
      state.isFetching = false;
      state.hasFetched = action.payload.hasFetched;
      state.companyConfig = action.payload.configs;
    },
    getPermissionsFailed(state, action) {
      state.entities = [];
      state.services = [];
      state.error = action.payload;
      state.isFetching = false;
      state.hasFetched = true;
    }
  },
  extraReducers: {
    [logout]: state => {
      state.services = [];
      state.entities = [];
      state.error = null;
      state.isFetching = false;
      state.hasFetched = false;
    }
  }
});

export const {
  getPermissionsStarted,
  getPermissionsSuccess,
  getPermissionsFailed
} = permissions.actions;

export default permissions.reducer;

export const fetchPermissions = (userKey, hasFetched = true) => async (dispatch, getState) => {
  const {
    user: { current: currentUser },
    permissions: { isFetching }
  } = getState();

  const authKey = userKey || currentUser?.auth?.key;

  if (!authKey) {
    return;
  }

  if (!isFetching) {
    dispatch(getPermissionsStarted());
    try {
      const response = await api.get('/permissions', {
        authKey
      });
      if (!response?.body) {
        dispatch(getPermissionsFailed('no data'));
      }
      dispatch(getPermissionsSuccess({ ...response.body, hasFetched }));
    } catch (err) {
      dispatch(getPermissionsFailed(err.toString()));
    }
  }
};

export const fetchNetradyneUrl = userKey => async (dispatch, getState) => {
  const {
    user: { current: currentUser },
    permissions: { services, entities }
  } = getState();
  const hasNetradyne = services?.find(service => service === 'NETRADYNE');
  const hasVideo = entities.find(entity => entity === 'VIDEO');
  if (!hasNetradyne || !hasVideo) {
    return;
  }

  const authKey = userKey || currentUser?.auth?.key;
  if (!authKey) {
    return;
  }
  try {
    const response = await api.get('/netradyne/sso', { authKey });
    const url = response?.body?.url || '';
    return url;
  } catch (err) {
    dispatch(
      openToast({
        type: ToastType.Error,
        message: i18next.t('Preferences.Save.ErrorMessage')
      })
    );
  }
};

export const useServicePermissons = () => useSelector(state => state.permissions.services);

export const useEntityPermissons = () => useSelector(state => state.permissions.entities);

export const useCompanyConfigPermissons = () =>
  useSelector(state => state.permissions.companyConfig);

export const usePermissions = () => useSelector(state => state.permissions);
