import React from 'react';
import styled from '@emotion/styled';
import TopNav from '../nav/TopNav';
import { ButtonNavLink } from 'components/nav/NavLinks';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'features/page/pageSlice';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import styles from './NoAccess.module.scss';
import { useAllRegions } from 'features/regions/regionsSlice';

const supportEmail = regions => {
  const currentRegion = regions.find(
    region => region.locale === localStorage.getItem('i18nextLng')
  );
  return typeof currentRegion === 'undefined'
    ? 'tn360support-au@teletracnavman.com'
    : currentRegion.supportEmail;
};

const Main = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  order: 2;
  flex: 1 0 0;
  flex-direction: column;
`;

const Subpage = styled.section`
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  justify-content: center;
  align-items: center;
`;

const NoAccess = () => {
  const location = useLocation();
  const isSubpath = /^\/[^/]+\/.*$/;
  if (location?.pathname === '/' || location?.pathname?.match(/\/home/i)) {
    return (
      <Main>
        {!isSubpath.test(location?.pathname) && <TopNav title="" showBackButton={false} />}
        <NoCommonServiceBody />
      </Main>
    );
  }
  return (
    <Main>
      {!isSubpath.test(location?.pathname) && <TopNav title="No Access" showBackButton={false} />}
      <NoAccessBody />
    </Main>
  );
};

export const NoAccessBody = () => {
  const regions = useAllRegions();

  useDispatch()(setPageTitle('No Access'));

  return (
    <Subpage className="NoAccess">
      <h1>Dang it!</h1>
      <h2>You don't have access to this feature.</h2>
      <p>
        If you think you should have access or this is an error please contact{' '}
        <a href={`mailto:${supportEmail(regions)}`}>{supportEmail(regions)}</a>
      </p>
      <ButtonNavLink to="/">Back to Home</ButtonNavLink>
    </Subpage>
  );
};

export const NoCommonServiceBody = () => {
  const { t } = useTranslation();
  return (
    <Subpage className={styles.noCommonServiceBody}>
      <span>
        <i className="tn-i-logo" />
      </span>
      <div>
        <h1>{t('Common.Hmm')}...</h1>
        <h2>{t("Common.Looks like you don't have anything to see here")}.</h2>
      </div>
      <div>
        <h3>{t('Common.It appears your account is not completely set up')}.</h3>
        <h3>
          {t(
            'Common.Please contact your administrator so they can complete setting up your account'
          )}
          .
        </h3>
      </div>
    </Subpage>
  );
};

export const CompanyLocked = () => {
  const { t } = useTranslation();
  return (
    <Subpage className={styles.noCommonServiceBody}>
      <span>
        <i className="tn-i-logo" />
      </span>
      <div>
        <h1>{t('Login.CompanyLocked.Accountlocked')}</h1>
        <h2>
          {t('Login.CompanyLocked.Your account has been locked due the following possible reasons')}
          :
        </h2>
        <h3>&#45;&#32;{t("Login.CompanyLocked.your company's account has been disabled")}.</h3>
        <h3>&#45;&#32;{t('Login.CompanyLocked.your account has been disabled')}.</h3>
      </div>
      <div>
        <h3>
          {t(
            'Login.CompanyLocked.Please contact TN360 Support for more information on your account'
          )}
          .
        </h3>
      </div>
    </Subpage>
  );
};

export default NoAccess;
