import { EllipsisButton } from 'components/tables/EllipsisButton';
import { default as React } from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import HideOverflow from 'components/text-overflow/HideOverflow';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 52
});

export const nameCellRenderer = ({ dataKey, parent, rowIndex, rowData, canViewVehicleType }) => {
  return canViewVehicleType ? (
    <Link to={`/settings/vehicleTypes/id/${rowData.id}`}>{rowData.name}</Link>
  ) : (
    <span>{rowData.name}</span>
  );
};

export const companyCellRenderer = ({ dataKey, parent, rowIndex, rowData, companies }) => {
  const company = companies.find(company => company.id === rowData.companyId);
  if (!company) return;
  return <div>{company.name}</div>;
};

export const codeCellRenderer = ({ dataKey, parent, rowIndex, rowData }) => {
  return rowData.code;
};

export const descriptionCellRenderer = ({ dataKey, parent, rowIndex, rowData }) => {
  return (
    <CellMeasurer
      cache={cache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${rowData.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <HideOverflow text={rowData.description} />
    </CellMeasurer>
  );
};

export const ellipsisButtonCellRenderer = ({
  dataKey,
  rowData,
  handleDeleteAction,
  typeOfEntityToDelete,
  hideEdit,
  hideDelete,
  enableRestoreAction,
  handleAction
}) => {
  return (
    <EllipsisButton
      dataKey={dataKey}
      handleDeleteAction={handleDeleteAction}
      typeOfEntityToDelete={typeOfEntityToDelete}
      path={'/settings/vehicleTypes/'}
      data={rowData}
      canUse="VEHICLETYPE"
      hideEdit={hideEdit}
      hideDelete={hideDelete}
      hideCopy={false}
      enableRestoreAction={enableRestoreAction}
      handleAction={handleAction}
    />
  );
};
