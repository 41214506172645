import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './EventDetail.module.scss';
import { useNotificationsData } from '../../features/notifications/notificationsSlice';
import { useUser } from '../../features/user/userSlice';
import { EventUtil } from '../../features/events/eventUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ContentLoader from 'react-content-loader';

const PageContentLoader = () => (
  <div>
    <ContentLoader
      speed={2}
      style={{ height: '100%', width: '100%' }}
      primaryColor="#f7f8f9"
      viewBox="0 0 100 100"
      secondaryColor="#eeeeee"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
    </ContentLoader>
  </div>
);

export function _EventDetail(props) {
  const frameRef = useRef(null);
  const params = useParams();
  const alertData = useNotificationsData();
  const [error, setError] = useState(null);
  const [url, setUrl] = useState('');
  const [loadComplete, setLoadComplete] = useState(false);
  const userInfo = useUser();

  const [alert, setAlert] = useState(null);

  const handlePostMessage = useCallback(msgEvent => {
    console.debug('done');
  }, []);

  const bindFrame = useCallback(
    frameNode => {
      frameRef.current = frameNode;
      if (frameRef.current != null && frameRef.addEventListener != null) {
        frameNode.addEventListener('message', handlePostMessage, false);
      }
    },
    [handlePostMessage]
  );

  const handleLoadComplete = useCallback(() => {
    setTimeout(() => setLoadComplete(true), 300);
  }, []);

  useEffect(() => {
    if (!alertData) {
      setLoadComplete(false);
    }

    const eventId = params['id'];
    if (eventId == null || eventId.trim() === '') {
      setError('Sorry but the event id is not available.');
      return;
    }

    const alert = alertData.find(e => e.id === parseInt(eventId));
    if (alert == null) {
      setError('Sorry but we could not find the event.');
      return;
    }

    setError(null);
    setAlert(alert);
  }, [params, alertData]);

  useEffect(() => {
    if (alert == null) {
      return;
    }

    const eventPageUrl = EventUtil.generateUrlForEventDetailPage(
      alert,
      userInfo.id,
      userInfo.auth.key
    );
    setUrl(eventPageUrl);
  }, [alert, userInfo]);

  return (
    <div className={styles.eventContainer}>
      {error && (
        <div className={styles.errorContainer}>
          <FontAwesomeIcon className={styles.errorIcon} icon={faExclamationTriangle} />
          <span className={styles.errorMessage}>{error}</span>
        </div>
      )}

      {!loadComplete && <PageContentLoader />}

      {url !== '' && (
        <iframe
          title="Event Detail"
          src={url}
          key={`${params['id']}`}
          frameBorder="0"
          ref={bindFrame}
          className={styles.frame + ' ' + (loadComplete ? styles.show : '')}
          onLoad={handleLoadComplete}
        ></iframe>
      )}
    </div>
  );
}

export const EventDetail = React.memo(_EventDetail);
