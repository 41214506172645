import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router';
import {
  useFleets,
  useVehicles,
  useIsFetching as useIsFetchingFleets
} from 'features/fleets/fleetsSlice';
import { useTranslation } from 'react-i18next';

import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import {
  Table,
  TabsFilters,
  MoreMenu,
  DatePicker,
  Icon,
  CalendarSwitch,
  Tooltip
} from 'components/ant';
import { ManageColumnsModal } from './ManageColumnsModal';
import { Affix, Layout, Space } from 'antd';
import {
  prepareRunsheetsForTable,
  prepareCarriersForSelect,
  manageColumns,
  prepareRunsheetsForCalendarView,
  prepareLocationsForSelect,
  filterByActiveTab,
  preparFleetsForSelect,
  prepareVehiclesForSelect
} from './utils/helpers';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';

import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import moment from 'moment';
import { UploadRunsheetModal } from './UploadRunsheetModal';
import { RunsheetCalendarView } from './';
//constants
import { TableFilterTypes, TableStatus, ColumnKeys, Tabs, Paths } from './utils/constants';
import { getSmartJobsTableColumns } from './utils/translation';

//icons
import settingsIcon from 'static/images/icons/settings-icon.svg';

//slices
import {
  useRunsheets,
  useUserSmartJobsColumns,
  updateColumns,
  useIsFetchingFilters,
  useUserSmartJobsFilters,
  updateFilters,
  fetchRunsheets,
  useIsFetchingRunsheets,
  changeCalendarView,
  setCalendarDate,
  updateCalendarDate,
  setDate
} from 'features/smartJobs/smartJobsSlice';
import { useIsCalendarView, useRunsheetDate, useCalendarDate } from 'features/smartJobs';
import { useDispatch } from 'react-redux';
import AllocateModal from './AllocateModal/AllocateModal';
import { getTabs } from 'utils/tabs';
import { useLocalization } from 'features/localization/localizationSlice';
import style from './SmartJobs.module.scss';

import { useCan } from 'features/permissions/canHooks';
import entities from 'features/permissions/entities';

const getRunsheetWithFleets = (runsheets, fleets) => {
  return runsheets.map(runsheet => ({
    ...runsheet,
    ...(runsheet.vehicle && {
      fleet: fleets.filter(
        fleet =>
          (fleet.vehicles || []).some(vehicle => runsheet.vehicle.id === vehicle.id) && fleet.id
      )
    })
  }));
};

export const SmartJobs = () => {
  const [forceFetchFilters, setForceFetchFilters] = useState(true);
  const dispatch = useDispatch();
  const runsheets = useRunsheets();
  const history = useHistory();
  const fleets = useFleets();
  const allVehicles = useVehicles();
  const isFetchingFleets = useIsFetchingFleets();
  const isFetchingRunsheets = useIsFetchingRunsheets();
  const isFetchingFilters = useIsFetchingFilters();
  const [activeTab, setActiveTab] = useState(TableStatus.ALL);
  const initialColumnsKeys = useUserSmartJobsColumns();
  const initialFiltersKeys = useUserSmartJobsFilters(forceFetchFilters);
  const isCalendarView = useIsCalendarView();
  const selectedDate = useRunsheetDate();
  const calendarDate = useCalendarDate();
  const can = useCan();

  const { t } = useTranslation();

  const defaultAll = {
    id: 0,
    checked: true
  };

  const selectDefaults = {
    CARRIERS: {
      ...defaultAll,
      label: t('SmartJobs.AllCarriers')
    },
    FLEETS: {
      ...defaultAll,
      label: t('SmartJobs.AllFleets')
    },
    VEHICLES: {
      ...defaultAll,
      label: t('Common.AllVehicles')
    },
    LOCATIONS: {
      ...defaultAll,
      label: t('SmartJobs.AllLocations')
    }
  };

  const [selectedCarriers, selectCarriers] = useState([]);
  const [selectedFleets, selectFleets] = useState([]);
  const [selectedVehicles, selectVehicles] = useState([]);
  const [selectedLocations, selectLocations] = useState([]);
  const [runsheetsLength, setRunsheetsLength] = useState(runsheets.length || 0);
  const [vehicles, setVehicles] = useState([]);

  const [searchValue, setSearchValue] = useState(null);
  const [isUploadRunsheetModalOpen, setUploadModalStatus] = useState(false);

  const [allocateModal, setAllocateModal] = useState(false);
  const [runsheetForAlloc, setRunsheetForAlloc] = useState({});

  //Manage Columns
  const [showManageColumns, setShowManageColumns] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [columnsForTable, setColumnsForTable] = useState([]);

  const TABLE_COLUMNS = getSmartJobsTableColumns(t);

  const localization = useLocalization();

  // Set page title
  useEffect(() => {
    dispatch(setPageTitle(t('SmartJobs.title')));
    dispatch(setBackButton(false));
  }, [dispatch, t]);

  // Effects
  useEffect(() => {
    setVehicles(allVehicles.filter(vehicle => !!vehicle?.id));
  }, [allVehicles]);

  useEffect(() => {
    const filtered = prepareRunsheetsForCalendarView(getRunsheetWithFleets(runsheets, fleets), {
      searchValue,
      activeTab,
      selectedCarriers,
      selectedVehicles,
      selectedLocations,
      selectedFleets
    });

    setRunsheetsLength(filtered.length || 0);
  }, [
    runsheets,
    fleets,
    vehicles,
    searchValue,
    activeTab,
    selectedCarriers,
    selectedVehicles,
    selectedLocations,
    selectedFleets
  ]);

  useEffect(() => {
    const visibleUserColumns = manageColumns.visible(TABLE_COLUMNS, initialColumnsKeys);
    const hiddenUserColumns = manageColumns.hidden(TABLE_COLUMNS, initialColumnsKeys);
    setVisibleColumns(visibleUserColumns);
    setColumnsForTable([
      TABLE_COLUMNS.find(item => item.key === ColumnKeys.STATUS),
      ...visibleUserColumns,
      TABLE_COLUMNS.find(item => item.key === ColumnKeys.CARRIER),
      TABLE_COLUMNS.find(item => item.key === ColumnKeys.ACTIONS),
      { title: renderSettingsIcon, width: 40 }
    ]);
    setHiddenColumns(hiddenUserColumns);
  }, [initialColumnsKeys]);

  useEffect(() => {
    selectCarriers(initialFiltersKeys?.carrier || selectedCarriers);
    selectFleets(initialFiltersKeys?.fleet || selectedFleets);
    selectLocations(initialFiltersKeys?.location || selectedLocations);
    selectVehicles(initialFiltersKeys?.vehicle || selectedVehicles);
    setForceFetchFilters(false);
  }, [initialFiltersKeys]);

  useEffect(() => {
    if (selectedDate && !isCalendarView) {
      dispatch(setDate(selectedDate));
      dispatch(fetchRunsheets());
    }
    if (isCalendarView) {
      dispatch(fetchRunsheets());
    }
  }, [selectedDate, isCalendarView, calendarDate]);

  useEffect(() => {
    // Filter Runsheet based on current active filters
    const filteredRunsheets =
      activeTab && activeTab !== TableStatus.ALL
        ? runsheets.filter(filterByActiveTab(activeTab))
        : runsheets.slice();

    const carriers = prepareCarriersForSelect(filteredRunsheets, selectedCarriers);
    selectCarriers([
      { ...selectDefaults.CARRIERS, checked: carriers.every(dv => dv.checked) },
      ...carriers
    ]);

    const defaultFleets = preparFleetsForSelect(fleets, selectedFleets, t);
    selectFleets([
      { ...selectDefaults.FLEETS, checked: defaultFleets.every(df => df.checked) },
      ...defaultFleets
    ]);

    const defaultVehicles = prepareVehiclesForSelect(vehicles, selectedVehicles, t);
    selectVehicles([
      { ...selectDefaults.VEHICLES, checked: defaultVehicles.every(dv => dv.checked) },
      ...defaultVehicles
    ]);

    const defaultLocations = prepareLocationsForSelect(filteredRunsheets, selectedLocations);
    selectLocations([
      { ...selectDefaults.LOCATIONS, checked: defaultLocations.every(dv => dv.checked) },
      ...defaultLocations
    ]);
  }, [runsheets, fleets, vehicles, activeTab]);

  const handleTabClick = value => {
    setActiveTab(value);
  };

  const handleSearch = value => {
    setSearchValue(value);
  };

  // Change Carrier
  const handleChange = type => items => {
    const selectItems = {
      [TableFilterTypes.CARRIER]: { list: selectedCarriers, fn: selectCarriers },
      [TableFilterTypes.LOCATION]: { list: selectedLocations, fn: selectLocations },
      [TableFilterTypes.FLEET]: { list: selectedFleets, fn: selectFleets },
      [TableFilterTypes.VEHICLE]: { list: selectedVehicles, fn: selectVehicles }
    };

    selectItems[type].fn(items);

    const selectItems2 = {
      [TableFilterTypes.CARRIER]: selectedCarriers,
      [TableFilterTypes.LOCATION]: selectedLocations,
      [TableFilterTypes.FLEET]: selectedFleets,
      [TableFilterTypes.VEHICLE]: selectedVehicles
    };
    selectItems2[type] = items;
    var newFilters = JSON.parse(JSON.stringify(selectItems2));
    //Call Config API
    dispatch(updateFilters(newFilters));
  };

  const handleAllocate = runsheet => {
    setRunsheetForAlloc(runsheet);
    setAllocateModal(true);
  };

  // set the active filters for the tab
  const activeFilters = {
    activeTab,
    selectedCarriers,
    selectedDate,
    searchValue,
    selectedFleets,
    selectedVehicles,
    selectedLocations
  };

  const hideManageColums = () => {
    setShowManageColumns(false);
    resetColumns();
  };

  const renderSettingsIcon = (
    <Tooltip
      content={t('SmartJobs.manageColumns')}
      target={<Icon cb={() => setShowManageColumns(true)} srcIcon={settingsIcon} />}
    />
  );

  const saveManageColumns = () => {
    //We need to send the status_icon and status first in the array, so we keep compatibility with NG
    const visibleColumnKeys = [
      ColumnKeys.STATUS_ICON,
      ColumnKeys.STATUS,
      ...visibleColumns.map(column => column.key)
    ];
    dispatch(updateColumns(visibleColumnKeys, t));
    setShowManageColumns(false);
  };

  const resetColumns = () => {
    setHiddenColumns(manageColumns.hidden(TABLE_COLUMNS, initialColumnsKeys));
    setVisibleColumns(manageColumns.visible(TABLE_COLUMNS, initialColumnsKeys));
  };
  const closeRunsheetUploadModal = () => setUploadModalStatus(false);
  const openRunsheetUploadModal = () => setUploadModalStatus(true);

  const toggleCalendarView = () => {
    dispatch(changeCalendarView());
    dispatch(setCalendarDate());
    if (!selectedDate) {
      dispatch(setDate(selectedDate));
    }
  };

  const handleCalendarDateChange = value => {
    dispatch(updateCalendarDate(value));
  };

  const getCounterText = length =>
    `${length} ${length === 1 ? t('SmartJobs.Runsheet') : t('SmartJobs.Runsheets')}`;

  return (
    <Layout>
      <Affix offsetTop={62.38}>
        <div>
          <UploadRunsheetModal
            visible={isUploadRunsheetModalOpen}
            onCancel={closeRunsheetUploadModal}
          />
          <HeaderPageWrapper>
            <AllocateModal
              isOpen={allocateModal}
              onCancel={() => setAllocateModal(false)}
              current={runsheetForAlloc}
              fleets={fleets}
              vehicles={vehicles}
              isCalendar={isCalendarView}
              selectedInterval={calendarDate}
            />
            <ManageColumnsModal
              show={showManageColumns}
              title={t('SmartJobs.manageColumns')}
              onSave={saveManageColumns}
              onHide={hideManageColums}
              hiddenColumns={hiddenColumns}
              setHiddenColumns={setHiddenColumns}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              resetColumns={resetColumns}
            />
            <div style={{ paddingLeft: '8px' }}>
              <TabsFilters tabs={getTabs(Tabs, activeTab, handleTabClick, t)} />
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <Space size={0}>
                {!isCalendarView && (
                  <DatePicker
                    size="middle"
                    placeholder={t('Common.SelectDate')}
                    onChange={value => dispatch(setDate(value.toISOString()))}
                    allowClear={false}
                    value={moment(selectedDate)}
                    controlClass={style.datePicker}
                    format={localization.formats.time.formats.dby}
                  />
                )}
                {can && can({ everyEntity: [entities.RUNSHEET_CREATE] }) && (
                  <MoreMenu
                    size="xl"
                    border
                    useNewIcon={true}
                    items={[
                      {
                        name: t('SmartJobs.addNewRunsheet'),
                        onClick: () => {
                          history.push(Paths.NEW_RUNSHEET);
                        },
                        id: 'btn_smartjobsMenuAddNew'
                      },
                      {
                        name: t('SmartJobs.uploadRunsheet'),
                        onClick: openRunsheetUploadModal,
                        id: 'btn_smartjobsMenuOpenUpload'
                      }
                    ]}
                  />
                )}
              </Space>
            </div>
          </HeaderPageWrapper>
          <div className={style.filterWrapper}>
            <FilterWrapper>
              <Space size={8}>
                <AntSearchbar size={'medium'} onFilter={handleSearch} className={style.searchBar} />
                <AntMultiselect
                  title={
                    !selectedCarriers.some(carrier => !carrier?.checked)
                      ? t('SmartJobs.AllCarriers')
                      : t('Common.Carriers')
                  }
                  data={selectedCarriers}
                  onFilter={handleChange(TableFilterTypes.CARRIER)}
                  className={style.multiselect}
                  loading={false}
                />
                <AntMultiselect
                  title={
                    !selectedFleets.some(fleet => !fleet?.checked)
                      ? t('SmartJobs.AllFleets')
                      : t('Common.Fleets')
                  }
                  data={selectedFleets}
                  onFilter={handleChange(TableFilterTypes.FLEET)}
                  className={style.multiselect}
                  loading={isFetchingFleets}
                />
                <AntMultiselect
                  title={
                    !selectedVehicles.some(vehicle => !vehicle?.checked)
                      ? t('Common.AllVehicles')
                      : t('Common.Vehicles')
                  }
                  data={selectedVehicles}
                  onFilter={handleChange(TableFilterTypes.VEHICLE)}
                  className={style.multiselect}
                  loading={isFetchingFleets}
                />
                <AntMultiselect
                  title={
                    !selectedLocations.some(location => !location?.checked)
                      ? t('SmartJobs.AllLocations')
                      : t('Common.Locations')
                  }
                  data={selectedLocations}
                  onFilter={handleChange(TableFilterTypes.LOCATION)}
                  className={style.multiselect}
                  loading={false}
                />
              </Space>
            </FilterWrapper>
            <div className={style.switchContainer}>
              <div className={style.counter}>{getCounterText(runsheetsLength || 0)}</div>
              <CalendarSwitch
                isCalendarView={isCalendarView}
                toggleCalendarView={toggleCalendarView}
              />
            </div>
          </div>
        </div>
      </Affix>
      <div
        style={{
          width: '100%',
          padding: '0 32px',
          background: '#f7f8f9'
        }}
      >
        {isCalendarView ? (
          <RunsheetCalendarView
            onDateChange={handleCalendarDateChange}
            onAllocate={handleAllocate}
            value={calendarDate}
            runsheets={prepareRunsheetsForCalendarView(getRunsheetWithFleets(runsheets, fleets), {
              searchValue,
              activeTab,
              selectedFleets,
              selectedVehicles,
              selectedLocations,
              selectedCarriers
            })}
          />
        ) : (
          <Table
            dataSource={prepareRunsheetsForTable(
              getRunsheetWithFleets(runsheets, fleets),
              { filters: activeFilters, selectedDate },
              dispatch,
              setAllocateModal,
              setRunsheetForAlloc,
              t,
              localization
            )}
            columns={columnsForTable}
            pagination={false}
            loading={isFetchingRunsheets || isFetchingFilters}
            virtual={true}
            scroll={{
              y: window.innerHeight - 220,
              x: 1000
            }}
          />
        )}
      </div>
    </Layout>
  );
};
