import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import './MapTiles.scss';

export const availableMapTilesPositions = {
  justOne: {
    tiles: 1,
    element: (
      <div className="positionWrapper">
        <div className="positionMapTilePositioning large" />
      </div>
    )
  },
  horizontalTwo: {
    tiles: 2,
    element: (
      <div className="positionWrapper">
        <div className="positionMapTilePositioning horizontalLarge" />
        <div className="positionMapTilePositioning horizontalLarge" />
      </div>
    )
  },
  verticalTwo: {
    tiles: 2,
    element: (
      <div className="positionWrapper">
        <div className="positionMapTilePositioning verticalLarge" />
        <div className="positionMapTilePositioning verticalLarge" />
      </div>
    )
  },
  verticalTwoOne: {
    tiles: 3,
    element: (
      <div className="positionWrapper">
        <div className="emptyVertical">
          <div className="positionMapTilePositioning smallSquare" />
          <div className="positionMapTilePositioning smallSquare" />
        </div>
        <div className="positionMapTilePositioning verticalLarge" />
      </div>
    )
  },
  verticalOneTwo: {
    tiles: 3,
    element: (
      <div className="positionWrapper">
        <div className="positionMapTilePositioning verticalLarge" />
        <div className="emptyVertical">
          <div className="positionMapTilePositioning smallSquare" />
          <div className="positionMapTilePositioning smallSquare" />
        </div>
      </div>
    )
  },
  horizontalTwoOne: {
    tiles: 3,
    element: (
      <div className="positionWrapper">
        <div className="emptyHorizontal">
          <div className="positionMapTilePositioning smallSquare" />
          <div className="positionMapTilePositioning smallSquare" />
        </div>
        <div className="positionMapTilePositioning horizontalLarge" />
      </div>
    )
  },
  horizontalOneTwo: {
    tiles: 3,
    element: (
      <div className="positionWrapper">
        <div className="positionMapTilePositioning horizontalLarge" />
        <div className="emptyHorizontal">
          <div className="positionMapTilePositioning smallSquare" />
          <div className="positionMapTilePositioning smallSquare" />
        </div>
      </div>
    )
  },
  allFour: {
    tiles: 4,
    element: (
      <div className="positionWrapper">
        <div className="emptyHorizontal">
          <div className="positionMapTilePositioning smallSquare" />
          <div className="positionMapTilePositioning smallSquare" />
        </div>
        <div className="emptyHorizontal">
          <div className="positionMapTilePositioning smallSquare" />
          <div className="positionMapTilePositioning smallSquare" />
        </div>
      </div>
    )
  }
};

const MapTilesPositioning = ({ mapTilesPosition, onMapTilePositionChange }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div className="dropdownMapTilePositioning" onClick={() => setShowDropdown(!showDropdown)}>
      <div>{availableMapTilesPositions[mapTilesPosition].element}</div>
      <FontAwesomeIcon icon={faCaretDown} />
      {showDropdown && (
        <div className="mapTilesList">
          {Object.keys(availableMapTilesPositions).map(position => (
            <div onClick={() => onMapTilePositionChange(position)} className="mapTileOption">
              {availableMapTilesPositions[position].element}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MapTilesPositioning;
