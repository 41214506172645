import React, { useEffect, useState, useCallback } from 'react';
import './VehicleCalibrate.scss';
import { DatePicker, Select } from 'antd';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const DatePickerWrapper = ({
  disableSyncDatePicker,
  onSyncDateChange,
  onEventChange,
  isFetchingEvents,
  events
}) => {
  const localization = useLocalization();
  const format = localization.formats.time.formats.dby_imp.toUpperCase().replace(':MM', ':mm');
  const { t } = useTranslation();

  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    setSelectedEvent(null);
  }, [events]);

  const onSelectEvent = useCallback(
    eventTime => {
      const eventObj = events?.find(evt => evt.value === eventTime);
      setSelectedEvent(eventObj);
      if (onEventChange) {
        onEventChange(eventObj);
      }
    },
    [events, onEventChange]
  );

  return (
    <div className="wrapper">
      <div className="date-picker-wrapper">
        <div>{t('Vehicles.SyncDate')}</div>
        <DatePicker
          disabled={disableSyncDatePicker}
          onChange={onSyncDateChange}
          showTime
          format={format}
          placeholder={t('Common.SelectDate')}
        />
      </div>

      <div className="event-wrapper">
        <div>{t('Vehicles.Events')}</div>
        <Select
          className="select__element"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          getPopupContainer={triggerNode => triggerNode.parentNode}
          loading={isFetchingEvents}
          onChange={onSelectEvent}
          placeholder={t('Vehicles.SelectOption')}
          showSearch
          value={selectedEvent?.value}
        >
          {events.map((event, index) => (
            <Option value={event.value} key={`event-${index}`}>
              {event.label}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default DatePickerWrapper;
