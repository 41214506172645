export const bulkImportSelectors = {
  bulkData: state => state.bulkImport.bulkData.items,
  isFetchingBulkData: state => state.bulkImport.bulkData.meta.isFetching,
  isErrorBulkData: state => state.bulkImport.bulkData.meta.error,
  isSuccessBulkData: state => state.bulkImport.bulkData.meta.success,
  templates: state => state.bulkImport.templates.items,
  isFetchingTemplates: state => state.bulkImport.templates.meta.isFetching,
  isErrorTemplates: state => state.bulkImport.templates.meta.error,
  isSuccessTemplates: state => state.bulkImport.templates.meta.success,
  currentBulkItem: state => state.bulkImport.currentBulkItem.data,
  isFetchingCurrentBulkItem: state => state.bulkImport.currentBulkItem.meta.isFetching,
  isErrorCurrentBulkItem: state => state.bulkImport.currentBulkItem.meta.error,
  isSuccessCurrentBulkItem: state => state.bulkImport.currentBulkItem.meta.success
};
