import React from 'react';
import { Accordion as AccordionBootstrap, Card } from 'react-bootstrap';
import styles from './Accordion.module.scss';

const Accordion = props => {
  return (
    <AccordionBootstrap>
      <Card className={styles.card}>
        <AccordionBootstrap.Toggle
          as={Card.Header}
          eventKey="2"
          className={styles.cardHeader}
          onClick={props.onClick}
        >
          <span>{props.title}</span>
          {props.icon ? (
            <i className={`tn-i-chevron-up ${styles.icon}`} />
          ) : (
            <i className={`tn-i-chevron-down ${styles.icon}`} />
          )}
        </AccordionBootstrap.Toggle>
        <AccordionBootstrap.Collapse eventKey="2">
          <Card.Body>{props.body}</Card.Body>
        </AccordionBootstrap.Collapse>
      </Card>
    </AccordionBootstrap>
  );
};

export default Accordion;
