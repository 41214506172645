export const vehicleLayoutType = {
  cab: 'cab',
  rigidCab: 'rigid_cab',
  twinCab: 'twin_cab',
  trailer: 'trailer'
};

export const axleLayoutType = {
  steerWheel: 'steer_wheel',
  twinSteerWheel: 'twin_steer_wheel',
  wheel: 'wheel',
  wheels: 'wheels',
  hitchA: 'hitch_a',
  hitchB: 'hitch_b'
};

const v7VehicleComboAxleGroups = [
  { name: 'Rigid Truck - FUPS', jurisdiction: 'NHVR', axleGroups: 2, axles: 2 },
  { name: 'Rigid Truck - FUPS', jurisdiction: 'NHVR', axleGroups: 2, axles: 3 },
  { name: 'Rigid Truck - FUPS', jurisdiction: 'NHVR', axleGroups: 2, axles: 4 },
  { name: 'Twinsteer Rigid Truck - FUPS', jurisdiction: 'NHVR', axleGroups: 2, axles: 4 },
  { name: 'Twinsteer Rigid Truck - FUPS', jurisdiction: 'NHVR', axleGroups: 2, axles: 5 },
  { name: 'Semitrailer - FUPS', jurisdiction: 'NHVR', axleGroups: 3, axles: 3 },
  { name: 'Semitrailer - FUPS', jurisdiction: 'NHVR', axleGroups: 3, axles: 4 },
  { name: 'Semitrailer - FUPS', jurisdiction: 'NHVR', axleGroups: 3, axles: 5 }, // Single Drive Semitrailer
  { name: 'Semitrailer - FUPS', jurisdiction: 'NHVR', axleGroups: 3, axles: 5 }, // Tandem Drive Semitrailer
  { name: 'Semitrailer - FUPS', jurisdiction: 'NHVR', axleGroups: 3, axles: 6 },
  { name: 'Semitrailer - FUPS', jurisdiction: 'NHVR', axleGroups: 3, axles: 7 },
  { name: 'Truck & Dog Trailer - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 4 },
  { name: 'Truck & Dog Trailer - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 5 },
  { name: 'Truck & Dog Trailer - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 6 },
  { name: 'Truck & Dog Trailer - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 7 }, // 3-4 Truck & Dog
  { name: 'Truck & Dog Trailer - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 7 }, // 4-3 Truck & Dog
  { name: 'Truck & Dog Trailer - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 8 },
  { name: 'Truck & Pig Trailer - FUPS', jurisdiction: 'NHVR', axleGroups: 3, axles: 4 },
  { name: 'Truck & Pig Trailer - FUPS', jurisdiction: 'NHVR', axleGroups: 3, axles: 5 },
  { name: 'Truck & Pig Trailer - FUPS', jurisdiction: 'NHVR', axleGroups: 3, axles: 6 },
  { name: 'B-Double - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 7 },
  { name: 'B-Double - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 8 }, // 2-3-2 B-Double
  { name: 'B-Double - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 8 }, // 2-2-3 B-Double
  { name: 'B-Double - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 9 },
  { name: 'A-Double - FUPS', jurisdiction: 'NHVR', axleGroups: 5, axles: 9 },
  { name: 'A-Double - FUPS', jurisdiction: 'NHVR', axleGroups: 5, axles: 11 },
  { name: 'A-Double - FUPS', jurisdiction: 'NHVR', axleGroups: 5, axles: 12 },
  { name: 'Modular B-Triple - FUPS', jurisdiction: 'NHVR', axleGroups: 5, axles: 12 },
  { name: 'B-Triple - FUPS', jurisdiction: 'NHVR', axleGroups: 5, axles: 12 },
  { name: 'A-Triple - FUPS', jurisdiction: 'NHVR', axleGroups: 7, axles: 16 },
  { name: 'A-Triple - FUPS', jurisdiction: 'NHVR', axleGroups: 7, axles: 18 },
  { name: 'AB-Triple - FUPS', jurisdiction: 'NHVR', axleGroups: 6, axles: 14 },
  { name: 'AB-Triple - FUPS', jurisdiction: 'NHVR', axleGroups: 6, axles: 15 },
  { name: 'Rigid Truck & 2 Dog Trailers - FUPS', jurisdiction: 'NHVR', axleGroups: 6, axles: 11 },
  { name: 'Rigid Truck & 2 Dog Trailers - FUPS', jurisdiction: 'NHVR', axleGroups: 6, axles: 13 },
  { name: 'BAB-Quad - FUPS', jurisdiction: 'NHVR', axleGroups: 7, axles: 17 },
  { name: 'BAB-Quad - FUPS', jurisdiction: 'NHVR', axleGroups: 7, axles: 18 },
  { name: 'ABB-Quad - FUPS', jurisdiction: 'NHVR', axleGroups: 7, axles: 17 },
  { name: 'ABB-Quad - FUPS', jurisdiction: 'NHVR', axleGroups: 7, axles: 18 },
  { name: 'PBS Truck & Dog - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 7 },
  { name: 'PBS Truck & Dog - FUPS', jurisdiction: 'NHVR', axleGroups: 4, axles: 9 },
  { name: 'AAB-Quad - FUPS', jurisdiction: 'MRWA', axleGroups: 8, axles: 21 },
  { name: 'PBS Road Trains - FUPS', jurisdiction: 'NHVR', axleGroups: 6, axles: 7 },
  {
    name: 'PBS Twinsteer Rigid Truck & 2 Dogs Trailers - FUPS',
    jurisdiction: 'NHVR',
    axleGroups: 6,
    axles: 12
  },
  { name: 'Prime Mover', jurisdiction: 'NHVR', axleGroups: 2, axles: 3 },
  { name: 'Semitrailer', jurisdiction: 'NHVR', axleGroups: 4, axles: 7 },
  { name: 'B-Double', jurisdiction: 'NHVR', axleGroups: 4, axles: 11 },
  { name: 'Truck & Dog', jurisdiction: 'NHVR', axleGroups: 4, axles: 8 }, // Truck & Dog 1-2-2-3
  { name: 'Truck & Dog', jurisdiction: 'NHVR', axleGroups: 4, axles: 9 } // Truck & Dog 1-2-3-3
];

const importVisualizations = () => {
  const data = require('./v9.json');
  const visData = [];

  data.forEach(vehicleVis => {
    vehicleVis.axles.forEach(axle => {
      const axleCount = vehicleVis.axleCounts.find(axleCount => axleCount.id === axle.id).count;
      const vehicleComboAxleGroup = v7VehicleComboAxleGroups.find(
        comboAxleGroup =>
          comboAxleGroup.name === vehicleVis.name && comboAxleGroup.axles === axleCount
      );

      // const hitchMultiples = [];
      // axle.axleLayouts.forEach(axleLayout => {
      //   if ([axleLayoutType.hitchA, axleLayoutType.hitchB].includes(axleLayout.type)) {
      //     hitchMultiples.push(Math.abs(axleLayout.width));
      //   }
      // });

      visData.push({
        jurisdiction: vehicleComboAxleGroup.jurisdiction,
        axleGroups: vehicleComboAxleGroup.axleGroups,
        axles: axleCount,
        visualization: {
          name: vehicleVis.name,
          id: vehicleVis.id,
          vehicleLayouts: vehicleVis.vehicleLayouts.map(vehicleLayout => ({
            ...vehicleLayout
            // ...(!vehicleLayout.type
            //     && hitchMultiples.length
            //     && { hitchMultiples: hitchMultiples.shift() })
          })),
          axleLayouts: axle.axleLayouts.map(axleLayout => {
            return {
              width: axleLayout.width,
              ...(axleLayout.id && { id: axleLayout.id }),
              ...(axleLayout.type && { type: axleLayout.type }),
              ...(axleLayout.label && { label: axleLayout.label })
            };
          })
        }
      });
    });
  });

  return visData;
};

const vehicleVisualizations = importVisualizations();

export const getVehicleVisualization = (axleGroups, axles) => {
  const axleGroupsInt = typeof axleGroups === 'string' ? parseInt(axleGroups) : axleGroups;
  const axlesInt = typeof axles === 'string' ? parseInt(axles) : axles;

  const vis = vehicleVisualizations.filter(vehicleVis => {
    return (
      // match visualizations without regard to jurisdiction
      vehicleVis.axleGroups === axleGroupsInt && vehicleVis.axles === axlesInt
    );
  });

  // console.debug('getVehicleVisualization', vis);
  return vis;
};
