import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { useAudits } from 'features/audits';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';

import { AuditsTable } from 'components/auditsTable/AuditsTable';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';

export const DriverMgtTypesAudits = ({ types }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const audits = useAudits(AUDIT_ENTITY.DRIVER_MANAGEMENT_TYPES, id);
  const dispatch = useDispatch();
  const selectedType = types.find(type => type.id === parseInt(id, 10));
  const pageTitle = !selectedType
    ? t('Audits.AuditHistory')
    : t('Audits.AuditHistoryFor', { name: selectedType?.name });

  useEffect(() => {
    dispatch(setPageTitle(pageTitle));
    dispatch(setBackButton(true));
  }, [pageTitle, dispatch]);

  return <AuditsTable audits={audits} entity={AUDIT_ENTITY.DRIVER_MANAGEMENT_TYPES} />;
};
