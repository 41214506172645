import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { prepareAuthHeaders, getAuthHeader } from 'services/util';

import { onlyUnique } from 'utils/filters';
import { Comparators } from 'utils/sorting';

import { API_PATH } from 'config';

export const QsEntityType = {
  Dashboard: { singular: 'dashboard', plural: 'dashboards' },
  Analysis: { singular: 'analysis', plural: 'analyses' },
  Reports: { singular: 'report', plural: 'reports' }
};

export const ngAwsQsApi = createApi({
  reducerPath: 'ngAwsQsApi',
  tagTypes: ['dashboards', 'analyses', 'reports'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_PATH}`,
    prepareHeaders: prepareAuthHeaders
  }),
  endpoints: builder => ({
    getQsUser: builder.query({
      query: () => `qs/user`,
      transformResponse: response => response.user
    }),
    getQsDashboard: builder.query({
      query: ({ dashboardId }) => ({
        url: `qs/dashboards/${dashboardId}`
      }),
      transformResponse: response => response.dashboard,
      providesTags: ['dashboards']
    }),
    searchQsDashboards: builder.mutation({
      query: ({ filters }) => ({
        method: 'POST',
        url: `qs/dashboards/search`,
        params: {
          filters: filters
        }
      }),
      providesTags: ['dashboards']
    }),
    deleteQsDashboard: builder.mutation({
      query: ({ dashboardId }) => ({
        method: 'DELETE',
        url: `qs/dashboards/${dashboardId}`
      }),
      invalidatesTags: ['dashboards']
    }),
    getQsAnalysis: builder.query({
      query: ({ analysisId }) => ({
        url: `qs/analyses/${analysisId}`
      }),
      transformResponse: response => response.analysis,
      providesTags: ['analyses']
    }),
    searchQsAnalyses: builder.mutation({
      query: ({ filters }) => ({
        method: 'POST',
        url: `qs/analyses/search`,
        params: {
          filters: filters
        }
      }),
      providesTags: ['analyses']
    }),
    addQsAnalysis: builder.mutation({
      query: ({ sourceAnalysisId, analysisId, companyId, name }) => ({
        method: 'POST',
        url: `qs/analyses`,
        params: {
          'source-analysis-id': sourceAnalysisId,
          'analysis-id': analysisId,
          company_id: companyId,
          name
        }
      }),
      invalidatesTags: ['analyses']
    }),
    updateQsAnalysis: builder.mutation({
      query: ({ analysisId, name }) => ({
        method: 'POST',
        url: `qs/analyses/${analysisId}`,
        body: {
          name
        }
      }),
      invalidatesTags: ['analyses']
    }),
    deleteQsAnalysis: builder.mutation({
      query: ({ analysisId }) => ({
        method: 'DELETE',
        url: `qs/analyses/${analysisId}`
      }),
      invalidatesTags: ['analyses']
    }),
    publishQsAnalysis: builder.mutation({
      query: ({ analysisId, companyId, name }) => ({
        method: 'POST',
        url: `qs/analyses/${analysisId}/share`,
        params: {
          company_id: companyId,
          name
        }
      }),
      invalidatesTags: ['dashboards', 'analyses']
    }),
    getQsFolders: builder.query({
      query: () => ({
        url: `qs/folders`
      }),
      transformResponse: response => response.folderSummaryList
    }),
    getQsFolderMembers: builder.query({
      query: ({ folderId }) => ({
        url: `qs/folders/${folderId}/members`
      }),
      transformResponse: response => response.folderMemberList,
      providesTags: ['dashboards', 'analyses']
    }),
    getQsFolderEntities: builder.query({
      async queryFn({ folderId, entityType }, queryApi, extraOptions, fetchWithBQ) {
        const folderMembers = await fetchWithBQ(`qs/folders/${folderId}/members`);
        if (folderMembers.error) {
          return { error: folderMembers.error };
        }

        let entityIds = [];
        folderMembers.data.folderMemberList.forEach(folderMember => {
          if (folderMember?.memberArn.includes(`:${entityType.singular}/`)) {
            const dashboardId = folderMember?.memberArn.substring(
              folderMember?.memberArn.lastIndexOf('/') + 1
            );
            entityIds.push(dashboardId);
            // console.debug('getQsFolderEntities', { folderMembers: entityIds });
          }
        });

        if (!entityIds.length) {
          return { data: [] };
        }

        let entities = [];
        let entityPromises = [];
        entityIds.forEach(entityId => {
          entityPromises.push(fetchWithBQ(`qs/${entityType.plural}/${entityId}`));
        });

        return Promise.allSettled(entityPromises)
          .then(values => {
            // console.debug('getQsFolderEntities', { entityPromises: values });
            entities = values
              .map(value => value.value.data[entityType.singular])
              .sort(Comparators.String('name'));
            return { data: entities };
          })
          .catch(error => {
            console.error('getQsFolderEntities - entityPromises', { error });
            return { error: error };
          });
      },
      providesTags: ['dashboards', 'analyses']
    }),
    getQsFoldersEntities: builder.query({
      async queryFn({ folderIds, entityType }, queryApi, extraOptions, fetchWithBQ) {
        let folderPromises = [];
        folderIds.forEach(folderId => {
          folderPromises.push(fetchWithBQ(`qs/folders/${folderId}/members`));
        });

        let entityIds = [];
        await Promise.allSettled(folderPromises)
          .then(values => {
            // console.debug('getQsFoldersEntities', { folderPromises: values });
            values.forEach(value => {
              value.value.data.folderMemberList.forEach(folderMember => {
                if (folderMember?.memberArn.includes(`:${entityType.singular}/`)) {
                  const entityId = folderMember?.memberArn.substring(
                    folderMember?.memberArn.lastIndexOf('/') + 1
                  );
                  entityIds.push(entityId);
                  // console.debug('getQsFoldersEntities', { folderMembers: entityIds });
                }
              });
            });
          })
          .catch(error => {
            console.error('getQsFoldersEntities - folderMembers', { error });
            return { error: error };
          });

        if (!entityIds.length) {
          return { data: [] };
        } else {
          entityIds = entityIds.filter(onlyUnique);
        }

        let entities = [];
        let entityPromises = [];
        entityIds.forEach(entityId => {
          entityPromises.push(fetchWithBQ(`qs/${entityType.plural}/${entityId}`));
        });

        return Promise.allSettled(entityPromises)
          .then(values => {
            // console.debug('getQsFoldersEntities', { entityPromises: values });
            entities = values
              .map(value => value.value.data[entityType.singular])
              .sort(Comparators.String('name'));
            return { data: entities };
          })
          .catch(error => {
            console.error('getQsFoldersEntities - entityPromises', { error });
            return { error: error };
          });
      },
      providesTags: ['dashboards', 'analyses']
    }),
    searchQsFolderEntities: builder.query({
      async queryFn({ folderId, entityType }, queryApi, extraOptions, fetchWithBQ) {
        const folderMembers = await fetchWithBQ(`qs/folders/${folderId}/members`);
        if (folderMembers.error) {
          return { error: folderMembers.error };
        }

        let entityIds = [];
        folderMembers.data.folderMemberList.forEach(folderMember => {
          if (folderMember?.memberArn.includes(`:${entityType.singular}/`)) {
            const dashboardId = folderMember?.memberArn.substring(
              folderMember?.memberArn.lastIndexOf('/') + 1
            );
            entityIds.push(dashboardId);
            // console.debug('searchQsFolderEntities', { folderMembers: entityIds });
          }
        });

        if (!entityIds.length) {
          return { data: [] };
        }

        return fetch(`${API_PATH}/qs/${entityType.plural}/search`, {
          method: 'POST',
          headers: getAuthHeader(queryApi.getState)
        })
          .then(response => response.json())
          .then(data => {
            // console.debug('searchQsFolderEntities', { data });
            const entities = data[entityType.singular + 'SummaryList']
              .filter(value => entityIds.includes(value[entityType.singular + 'Id']))
              .sort(Comparators.String('Name'));
            return { data: entities };
          })
          .catch(error => {
            console.error('searchQsFolderEntities', { error });
            return { error: error };
          });
      },
      providesTags: ['dashboards', 'analyses']
    }),
    searchQsFoldersEntities: builder.query({
      async queryFn({ folderIds, entityType }, queryApi, extraOptions, fetchWithBQ) {
        let folderPromises = [];
        folderIds.forEach(folderId => {
          folderPromises.push(fetchWithBQ(`qs/folders/${folderId}/members`));
        });

        let entityIds = [];
        await Promise.allSettled(folderPromises)
          .then(values => {
            // console.debug('searchQsFoldersEntities', { folderPromises: values });
            values.forEach(value => {
              value.value.data.folderMemberList.forEach(folderMember => {
                if (folderMember?.memberArn.includes(`:${entityType.singular}/`)) {
                  const entityId = folderMember?.memberArn.substring(
                    folderMember?.memberArn.lastIndexOf('/') + 1
                  );
                  entityIds.push(entityId);
                }
              });
            });
          })
          .catch(error => {
            console.error('searchQsFoldersEntities - folderMembers', { error });
            return { error: error };
          });

        // console.debug('searchQsFoldersEntities', { folderIds, entityType, folderMembers: entityIds });

        if (!entityIds.length) {
          return { data: [] };
        } else {
          entityIds = entityIds.filter(onlyUnique);
        }

        return fetch(`${API_PATH}/qs/${entityType.plural}/search`, {
          method: 'POST',
          headers: getAuthHeader(queryApi.getState)
        })
          .then(response => response.json())
          .then(data => {
            // console.debug('searchQsFolderEntities', { data });
            const entities = data[entityType.singular + 'SummaryList']
              .filter(value => entityIds.includes(value[entityType.singular + 'Id']))
              .map(dashboard => ({
                ...dashboard,
                entityType: entityType.singular
              }))
              .sort(Comparators.String('Name'));
            return { data: entities };
          })
          .catch(error => {
            console.error('searchQsFoldersEntities', { error });
            return { error: error };
          });
      },
      providesTags: ['dashboards', 'analyses']
    }),
    getQsReports: builder.query({
      query: ({ userId }) => ({
        url: `qs/reportHistory/${userId}`
      }),
      transformResponse: response => {
        const sortedReports = response.sort((a, b) => b.id - a.id);

        return sortedReports;
      },
      providesTags: ['reports']
    }),
    GetQsReportSchedules: builder.query({
      query: ({ userId }) => ({
        url: `qs/reportSchedules/${userId}`
      }),
      transformResponse: response => {
        const sortedReports = response.sort((a, b) => b.id - a.id);

        return sortedReports;
      },
      providesTags: ['reports']
    }),
    deleteQsReportSchedule: builder.mutation({
      query: ({ reportId }) => ({
        method: 'DELETE',
        url: `qs/reportSchedules/${reportId}`
      }),
      providesTags: ['reports']
    })
  })
});

export const {
  useGetQsUserQuery,
  useGetQsDashboardQuery,
  useSearchQsDashboardsMutation,
  useDeleteQsDashboardMutation,
  useGetQsAnalysisQuery,
  useSearchQsAnalysesMutation,
  useAddQsAnalysisMutation,
  useUpdateQsAnalysisMutation,
  useDeleteQsAnalysisMutation,
  usePublishQsAnalysisMutation,
  useGetQsFoldersQuery,
  useGetQsFolderMembersQuery,
  useGetQsFolderEntitiesQuery,
  useGetQsFoldersEntitiesQuery,
  useSearchQsFolderEntitiesQuery,
  useSearchQsFoldersEntitiesQuery,
  useGetQsReportsQuery,
  useGetQsReportSchedulesQuery,
  useDeleteQsReportScheduleMutation
} = ngAwsQsApi;
