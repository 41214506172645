import React from 'react';
import moment from 'moment';
import { SortUtil } from 'components/tables/SortUtil';
import { Select } from 'antd';
import { FbtColumnsKeys, FbtItemColumnKeys, TRIP_STATE, reduceTripsToDrivers } from '../utils';
import { format } from 'utils/dates';

export const prepareColumnsForTable = (translate, fbtBulkAssignment) => {
  return Object.keys(FbtColumnsKeys).map(key => {
    const columnConfig = {
      title: translate(`FBTManager.${FbtColumnsKeys[key]}`),
      dataIndex: FbtColumnsKeys[key],
      key: FbtColumnsKeys[key],
      sortDirections: ['ascend', 'descend', 'ascend']
    };

    if (fbtBulkAssignment) {
      columnConfig.sorter = (a, b) => SortUtil.sortNumbersandStrings(a, b, FbtColumnsKeys[key]);
    }

    return columnConfig;
  });
};

export const parseTripForTable = (trip, index, localization, onSelectType, translate, states) => {
  return {
    ...trip,
    originalStartedAt: trip.startedAt,
    originalEndedAt: trip.endedAt,
    [FbtItemColumnKeys.START_DATE]: format(
      moment(trip[FbtItemColumnKeys.START_DATE]),
      localization.formats.time.formats.dby_imp
    ),
    [FbtItemColumnKeys.END_DATE]: format(
      moment(trip[FbtItemColumnKeys.END_DATE]),
      localization.formats.time.formats.dby_imp
    ),
    [FbtItemColumnKeys.TYPE_OF_USE]: trip[FbtItemColumnKeys.TYPE_OF_USE],
    [FbtItemColumnKeys.TOTAL_KM]:
      localization.convertDistance(trip[FbtItemColumnKeys.TOTAL_KM]) +
      ' ' +
      localization.formats.speed.unit,
    index
  };
};

export const prepareTripsData = (
  trips,
  localization,
  onOpenMap,
  onSelectType = () => {},
  states,
  translate,
  editable
) => {
  return trips.map((trip, index) => {
    const updated = parseTripForTable(trip, index, localization, onSelectType, translate, states);
    return {
      ...updated,
      [FbtItemColumnKeys.TYPE_OF_USE]: (
        <Select
          key={`fbt-main-table-${updated.createdAt}`}
          style={{ width: '160px' }}
          options={states?.map?.(tripState => ({
            value: tripState, // Updated id to value for Ant Design Select options
            label: translate(`FBTManager.${tripState}`, tripState)
          }))}
          placeholder={trip[FbtItemColumnKeys.TYPE_OF_USE]}
          onChange={onSelectType(trip.id)}
          disabled={!editable}
        />
      ),
      [FbtItemColumnKeys.ACTIONS]: (
        <div
          style={{
            color: '#069',
            cursor: 'pointer'
          }}
          onClick={onOpenMap(updated)}
        >
          Map
        </div>
      )
    };
  });
};

export const getNextAndPrevTrips = (index, trips = []) => {
  return {
    nextTrip: trips[index + 1] || trips[0],
    prevTrip: trips[index - 1] || trips[trips.length - 1]
  };
};

export const prepareSubRowColumns = (translate, localization, fbtBulkAssignment) => {
  const {
    VEHICLE_ID,
    VEHICLE_NAME,
    DRIVER_ID,
    FIRST_NAME,
    LAST_NAME,
    ...columnKeys
  } = FbtItemColumnKeys;

  const columns = Object.keys(columnKeys).map(key => {
    const columnKey = FbtItemColumnKeys[key];
    let columnConfig = {
      title:
        columnKey === FbtItemColumnKeys.TOTAL_KM
          ? `${translate(`FBTManager.${columnKey}`)} ${localization.formats.speed.unit}`
          : translate(`FBTManager.${columnKey}`),
      dataIndex: columnKey,
      key: columnKey,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: 200
    };

    // Conditionally assign sorter for TYPE_OF_USE and ACTIONS columns
    if (
      fbtBulkAssignment &&
      columnKey !== FbtItemColumnKeys.TYPE_OF_USE &&
      columnKey !== FbtItemColumnKeys.ACTIONS
    ) {
      columnConfig.sorter = (a, b) => SortUtil.sortNumbersandStrings(a, b, columnKey);
    }

    return columnConfig;
  });

  return columns;
};

export const prepareTripsForTable = (trips, localization, devices) => {
  // calculate usage percentage
  const vehicleTrips = reduceTripsToDrivers(trips, devices);

  return vehicleTrips.map((vt, index) => ({
    ...vt,
    businessUsagePercentage: (
      <div
        style={{
          textAlign: 'left'
        }}
      >
        {getUsagePercentage(vt)}
      </div>
    ),
    privateUsagePercentage: (
      <div
        style={{
          textAlign: 'left'
        }}
      >
        {getUsagePercentage(vt, TRIP_STATE.PRIVATE)}
      </div>
    ),
    privateUsage: (
      <div
        style={{
          textAlign: 'left'
        }}
      >
        {localization.convertDistance(vt.privateUsage) + ' ' + localization.formats.speed.unit}
      </div>
    ),
    businessUsage: (
      <div
        style={{
          textAlign: 'left'
        }}
      >
        {localization.convertDistance(vt.businessUsage) + ' ' + localization.formats.speed.unit}
      </div>
    ),
    key: index
  }));
};

const getUsagePercentage = (trip, type = TRIP_STATE.BUSINESS) => {
  const total = trip.businessUsage + trip.privateUsage;
  const usage = type === TRIP_STATE.BUSINESS ? trip.businessUsage : trip.privateUsage;

  return `${total ? parseFloat((usage / total) * 100).toFixed(2) : 0} %`;
};

export const getBulkAssignTitle = (hasCheckedRows, hasType) => {
  if (!hasCheckedRows && !hasType) {
    return 'FBTManager.FBTNoTypeNoSelection';
  } else if (!hasCheckedRows && hasType) {
    return 'FBTManager.FBTNoSelection';
  } else if (hasCheckedRows && !hasType) {
    return 'FBTManager.FBTNoType';
  } else {
    return 'ELD.Submit Assignments';
  }
};

export const handleSelectAll = (rowData, selectedTrips, setSelectedTrips) => {
  const allRowIds = rowData.map((row, index) => index);
  const anyTripsSelected = selectedTrips.some(trip => trip.trips.length > 0);

  if (!anyTripsSelected) {
    setSelectedTrips(
      allRowIds.map(rowid => ({
        rowid,
        trips: rowData[rowid]?.trips?.map(trip => trip.id) || []
      }))
    );
  } else {
    setSelectedTrips([]);
  }
};

export const handleSelectRow = (row, tripId, setSelectedTrips) => e => {
  const rowid = row.key;
  setSelectedTrips(prevSelectedTrips => {
    let updatedSelectedTrips = [...prevSelectedTrips];
    const index = updatedSelectedTrips.findIndex(item => item.rowid === rowid);

    if (e.target.checked) {
      if (index === -1) {
        updatedSelectedTrips.push({ rowid, trips: [tripId] });
      } else {
        if (!updatedSelectedTrips[index].trips.includes(tripId)) {
          updatedSelectedTrips[index].trips.push(tripId);
        }
      }
    } else {
      if (index !== -1) {
        updatedSelectedTrips[index].trips = updatedSelectedTrips[index].trips.filter(
          id => id !== tripId
        );
        if (updatedSelectedTrips[index].trips.length === 0) {
          updatedSelectedTrips.splice(index, 1);
        }
      }
    }

    return updatedSelectedTrips;
  });
};

export const handleSelectTrips = (record, selectedTrips, setSelectedTrips) => e => {
  const rowid = record.key;
  const tripIds = record.trips.map(trip => trip.id);
  const anyTripsSelected = selectedTrips.some(
    trip => trip.rowid === rowid && trip.trips.length > 0
  );

  setSelectedTrips(prevSelectedTrips => {
    if (e.target.checked && anyTripsSelected) {
      return prevSelectedTrips.filter(item => item.rowid !== rowid);
    } else {
      return e.target.checked
        ? [...prevSelectedTrips.filter(item => item.rowid !== rowid), { rowid, trips: tripIds }]
        : prevSelectedTrips.filter(item => item.rowid !== rowid);
    }
  });
};
