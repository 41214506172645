import React from 'react';
import { FormGroup, Col, FormLabel, FormControl } from 'react-bootstrap';
import { Field } from 'formik';
import i18n from 'i18next';

import { Tooltip } from 'components/ant';

import styles from './FormInput.module.scss';
import './FormInput.scss';

const FormInput = props => {
  let classInvisible = 'invisible';
  if (props.label === '') {
    classInvisible = 'none';
  }

  return (
    <Field name={props.name}>
      {({ field, meta }) => (
        <FormGroup as={Col} xs={props.xs} className={props?.wrapperClassName || ''}>
          <FormLabel className={!props.label && styles[classInvisible]}>
            <span>{props.label || 'Label'}</span>
            {!!props.tooltip && (
              <Tooltip
                content={props.tooltip}
                zIndex={props.tooltipZindex}
                target={<i className={`${props.tooltipIconClass || 'tn-i-info'} ${styles.info}`} />}
              />
            )}
          </FormLabel>
          {props.isRequired && <span className={styles.inputRequired}>*</span>}
          <FormControl
            {...field}
            as={props.as || 'input'}
            rows={props.rows || '3'}
            type={props.type || 'text'}
            placeholder={props.placeholder}
            autoComplete={props.autoComplete}
            className={`${styles.input} ${
              meta.touched && meta.error ? 'field-error' : ''
            } ${props.className || ''}`}
            disabled={props.disabled || false}
            readOnly={props.isReadonly || false}
            onChange={props.onChange ? e => props.onChange(e.target.value) : field.onChange}
            onKeyDown={props.onKeyDown ? e => props.onKeyDown(e, props.name) : () => {}}
            onBlur={props.onBlur ? e => props.onBlur(e, props.name) : field.onBlur}
            style={props.customStyle}
            defaultValue={props.defaultValue}
          />
          {(props.isRequired || props.isValidated) && !props?.hideError && (
            <div className={`fieldError ${meta.touched && meta.error ? 'error has' : 'error'}`}>
              {meta.error === 'Required'
                ? i18n.t('Forms.FormValidation.Required')
                : meta.touched && meta.error}
            </div>
          )}
        </FormGroup>
      )}
    </Field>
  );
};

export default FormInput;
