import i18next from 'i18nextConfig';

export const DutyStatusDescription = {
  get Driving() {
    return i18next.t('ELD.Driving');
  },
  get 'On Duty'() {
    return i18next.t('ELD.On Duty');
  },
  get 'Off Duty'() {
    return i18next.t('ELD.Off Duty');
  },
  get 'Off Duty At Well'() {
    return i18next.t('ELD.Off Duty At Well');
  },
  get 'Sleeper Berth'() {
    return i18next.t('ELD.Sleeper Berth');
  },
  get 'Personal Conveyance'() {
    return i18next.t('ELD.Personal Conveyance');
  },
  get 'Yard Move'() {
    return i18next.t('ELD.Yard Move');
  },
  get 'Adverse Conditions'() {
    return i18next.t('ELD.Adverse Conditions');
  },
  get 'Intermediate Log'() {
    return i18next.t('ELD.Intermediate Log');
  },
  get 'Exemption Area Exited'() {
    return i18next.t('ELD.Exemption Area Exited');
  },
  get 'Zone Change'() {
    return i18next.t('ELD.ZoneChange');
  },
  get 'Cycle Change'() {
    return i18next.t('ELD.CycleChange');
  },
  get 'Off Duty Deferral'() {
    return i18next.t('ELD.OffDutyDeferral');
  },
  get OnDuty() {
    return i18next.t('ELD.DutyStatus.On Duty');
  },
  get OffDuty() {
    return i18next.t('ELD.DutyStatus.Off Duty');
  },
  get OffDutyAtWell() {
    return i18next.t('ELD.DutyStatus.Off Duty At Well');
  },
  get SleeperBerth() {
    return i18next.t('ELD.DutyStatus.Sleeper Berth');
  },
  get PersonalConveyance() {
    return i18next.t('ELD.DutyStatus.Personal Conveyance');
  },
  get YardMove() {
    return i18next.t('ELD.DutyStatus.Yard Move');
  },
  get AdverseConditions() {
    return i18next.t('ELD.DutyStatus.Adverse Conditions');
  },
  get IntermediateLog() {
    return i18next.t('ELD.DutyStatus.Intermediate Log');
  },
  get ExemptionAreaExited() {
    return i18next.t('ELD.Exemption Area Exited');
  },
  get ZoneChange() {
    return i18next.t('ELD.ZoneChange');
  },
  get CycleChange() {
    return i18next.t('ELD.CycleChange');
  },
  get OffDutyDeferral() {
    return i18next.t('ELD.OffDutyDeferral');
  },
  get LogonDriver() {
    return i18next.t('ELD.DutyStatus.LogonDriver');
  },
  get 'Logon Driver'() {
    return i18next.t('ELD.DutyStatus.LogonDriver');
  },
  get LogIn() {
    return i18next.t('ELD.DutyStatus.LogonDriver');
  },
  get 'Log In'() {
    return i18next.t('ELD.DutyStatus.LogonDriver');
  },
  get LogOut() {
    return i18next.t('ELD.DutyStatus.LogoffDriver');
  },
  get 'Log Out'() {
    return i18next.t('ELD.DutyStatus.LogoffDriver');
  },
  get LogoffDriver() {
    return i18next.t('ELD.DutyStatus.LogoffDriver');
  },
  get 'Logoff Driver'() {
    return i18next.t('ELD.DutyStatus.LogoffDriver');
  }
};
