import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TachoTab } from './tacho/HoursOfService';

export const Fatigue = () => {
  return (
    <Switch>
      <Route
        exact
        path={['/fatigue/tacho', '/fatigue/tacho/:type', '/fatigue/tacho/hours_of_service/:type']}
      >
        <TachoTab />
      </Route>
    </Switch>
  );
};
