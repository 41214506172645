/* global google */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import request from 'superagent';

//components
import { Row } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import FormInput from 'components/form/form-input/FormInput';
import FormTitle from 'components/form/form-title/FormTitle';
import FormSelect from 'components/form/form-select/FormSelect';
import { GeoSuggest } from 'components/GeoSuggest';
import Map, { MapMode } from 'components/map/Map';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { Button } from 'components/ant';

//slices
import {
  useCompanies,
  useCurrentCompany,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import { useUserKey } from 'features/user/userSlice';
import { useCurrentRegion } from 'features/regions/regionsSlice';
import {
  useLocations,
  fetchLocations,
  useIsLocationsFetched,
  useIsCompanyKeyDifferent
} from 'features/locations/locationsSlice';
import { useLocationTypes } from 'features/locations/locationTypesSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useUsers } from 'features/users/usersSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { Address, getCountryNamebyCode } from 'components/form/address';

//methods & heleprs
import { canHistoryGoBack } from 'utils/methods';
import { parseErrorMessage } from 'utils/strings';
import moment from 'moment';
import { toLower } from 'lodash';
import { MPTrackingEvents, useFormUpdatedFields } from 'features/mixpanel';
import { helpers as geofencesHelpers } from '../../Administration/Geofences/helpers';

//constants
import { initialValues, validationSchema, EMPTY_ARRAY, TYPES, PATHS } from './constants';
import { API_PATH } from 'config';

//styles
import styles from './Locations.module.scss';
import './Locations.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const LocationForm = ({ action }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentCompany = useCurrentCompany();
  const userKey = useUserKey();
  const companies = useCompanies();
  const locations = useLocations();
  const filteredLocations = geofencesHelpers.getFilteredLocations(locations);
  const locationTypes = useLocationTypes();
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
  const path = window.location.pathname;
  const locationId = path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationsByCompanyId, setLocationsByCompanyId] = useState(
    filteredLocations.filter(loc => loc.companyId === currentCompany.id)
  );
  const [mapCenter, setMapCenter] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(null);
  const [isBranchAssignedToDriver, setIsBranchAssignedToDriver] = useState(null);
  const region = useCurrentRegion();
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(true);
  const users = useUsers();
  const { t } = useTranslation();
  const localization = useLocalization();
  const geosuggestRef = useRef(null);
  const hasLocationsFetched = useIsLocationsFetched();

  const hasCompanyChanged = useIsCompanyKeyDifferent('locations');
  useRedirectToMainFeaturePageOnCompanyChange('/settings/locations');

  const handleFetchError = useCallback(() => {
    if (history.location.pathname !== PATHS.DEFAULT) {
      history.replace(PATHS.DEFAULT);
    }
  }, [history]);

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    if (action === 'edit' && hasLocationsFetched) {
      const parsedId = parseInt(locationId);
      if (parsedId <= 0 || isNaN(parsedId) || !locations?.some(l => l.id === parsedId)) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('Common.Invalid Request ID')
          })
        );
        handleFetchError();
      }
    }
  }, [t, locationId, dispatch, handleFetchError, hasLocationsFetched, locations, action]);

  // set default map center
  useEffect(() => {
    if (region?.geocode) {
      try {
        const geocode = JSON.parse(region.geocode || '{}');
        const center = { lat: geocode.lat, lng: geocode.lng };
        const { zoom } = geocode;
        setMapCenter(center);
        setZoomLevel(zoom);
      } catch (e) {
        console.error(e);
      }
    }
  }, [region]);

  useEffect(() => {
    if (action === 'edit') {
      dispatch(
        setPageTitle(
          formikInitialValues.name && `${t('Locations.Form.EditTitle')} ${formikInitialValues.name}`
        )
      );
      if (formikInitialValues.GPS) {
        const location = formikInitialValues.GPS;
        if (!!location.Lat && !!location.Lng) {
          setSelectedLocation({ lat: location.Lat, lng: location.Lng });
        } else {
          setSelectedLocation(null);
        }
      }
    } else {
      dispatch(setPageTitle(`${t('Locations.Form.AddNewLocation')}`));
    }
  }, [dispatch, formikInitialValues, action, t]);

  useEffect(() => {
    const isBranchAssigned = users.find(
      user => toLower(user?.type?.name) === 'driver' && user?.location?.id === parseInt(locationId)
    );
    setIsBranchAssignedToDriver(isBranchAssigned);
  }, [users, locationId]);

  useEffect(() => {
    if (locationId) {
      const data = locations.find(location => location.id === parseInt(locationId, 10));
      if (data) {
        const isLocationExternal =
          data.externalId || [TYPES.journey].includes((data.type?.code || '').toLowerCase());
        if (isLocationExternal) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: t('Locations.Not Allowed to Edit Location')
            })
          );
          handleFetchError();
          return;
        }
        setFormikInitialValues({
          ...initialValues,
          ...data,
          type: data.type.id
        });
      } else {
        //preset country based on currentRegion
        setFormikInitialValues({
          ...initialValues,
          address: {
            ...(initialValues.address || {}),
            country: getCountryNamebyCode(localization?.region)
          }
        });
      }
    }
  }, [locations, locationId, region, localization, dispatch, handleFetchError]);

  const onCompanyChange = (company, setFieldValue) => {
    setFieldValue('companyId', company);
    setLocationsByCompanyId(filteredLocations.filter(loc => loc.companyId === Number(company)));

    if (!company) {
      return;
    }
  };

  const setNewLocationFromSearch = (place, setFieldValue) => {
    setSelectedLocation({ lat: place.lat, lng: place.lng });
    setFieldValue('GPS.Lat', place.lat);
    setFieldValue('GPS.Lng', place.lng);
  };

  const onReset = () => {
    setSelectedLocation(null);
    if (geosuggestRef?.current) {
      geosuggestRef.current.clear();
    }
  };

  const [formValue, setFormValue] = useState({});
  useEffect(() => {
    setFormValue({
      ...formikInitialValues,
      ...(action === 'add' && { companyId: currentCompany.id })
    });
  }, [formikInitialValues, action, currentCompany]);

  useFormUpdatedFields(formValue, {
    eventName: MPTrackingEvents.Settings.Locations.Update,
    eventProps: { typeOfUpdate: action },
    trackFields: MPTrackingEvents.Settings.Locations.TrackFormFields
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...formikInitialValues,
          ...(action === 'add' && { companyId: currentCompany.id })
        }}
        validationSchema={validationSchema({
          locationsByCompanyId,
          initialValues: formikInitialValues
        })}
        onReset={onReset}
        onSubmit={(values, actions) => {
          const submitValues = {
            ...values,
            type: { id: values.type },
            externalId: values.externalId !== '' ? values.externalId : null,
            status: 'ENABLED',
            updatedAt: moment().format(),
            ...(action === 'add' && { createdAt: moment().format() }),
            ...(action === 'edit' && { id: locationId })
          };
          setFormValue({ ...submitValues, submit: true });

          const url = `${API_PATH}/locations?company_id=${values.companyId}`;
          const method = 'POST';
          const successMessage = t(
            action === 'edit'
              ? 'Locations.Notifications.UpdateNotification'
              : 'Locations.Notifications.AddedNotification',
            {
              name: values.name
            }
          );

          request(method, url)
            .set('Authorization', `Token token="${userKey}"`)
            .set('Content-Type', 'application/json')
            .send(submitValues)
            .then(res => {
              setPromptModalWhenLeaving(false);
              dispatch(
                openToast({
                  type: ToastType.Success,
                  message: successMessage
                })
              );
              dispatch(fetchLocations());
              actions.setSubmitting(false);
              canHistoryGoBack(history, '/settings/locations');
            })
            .catch(err => {
              actions.setSubmitting(false);
              dispatch(
                openToast({
                  type: ToastType.Error,
                  message: parseErrorMessage(err)
                })
              );
            });
        }}
      >
        {({ isSubmitting, isValid, dirty, setFieldValue, initialValues: _initialValues }) => (
          <>
            <EditRouteGuard
              when={dirty && promptModalWhenLeaving && !hasCompanyChanged}
              navigate={history.push}
            />
            <Form id="LocationForm">
              <div className={styles.formContainer}>
                <Row>
                  <FormInput
                    name="name"
                    label={t('Locations.Form.Name')}
                    placeholder={t('Locations.Form.NamePlaceholder')}
                    isRequired
                  />
                  <FormSelect
                    name="companyId"
                    label={t('Locations.Form.Company')}
                    onChange={id => onCompanyChange(id, setFieldValue)}
                    values={companies.map(company => ({
                      label: company.name,
                      value: company.id
                    }))}
                    isDisabled={action === 'edit'}
                    isRequired
                  />
                </Row>
                <Row>
                  <FormSelect
                    name="type"
                    label={t('Locations.Form.Type')}
                    placeholder={t('Locations.Form.TypePlaceholder')}
                    values={locationTypes
                      .filter(t => ![TYPES.job, TYPES.journey].includes(toLower(t.name)))
                      .map(location => ({
                        label: t(`Locations.LocationType.${location?.name}`),
                        value: location.id
                      }))}
                    isRequired
                    isDisabled={isBranchAssignedToDriver}
                  />
                  <FormInput
                    name="externalId"
                    label={t('Locations.Form.ExternalID')}
                    placeholder={t('Locations.Form.ExternalIDPlaceholder')}
                    disabled
                  />
                </Row>
                <FormTitle title={t('Locations.Form.Address')} underlined />
                <Address
                  name="address"
                  initialValues={_initialValues?.address}
                  onChange={({ address, formated }) => {
                    setFieldValue('address', address);
                  }}
                />
                <FormTitle title={t('Locations.Form.GPSPosition')} underlined />
                <Row>
                  <FormInput
                    name="GPS.Lat"
                    label={t('Locations.Form.Latitude')}
                    placeholder={t('Locations.Form.LatitudePlaceholder')}
                  />
                  <FormInput
                    name="GPS.Lng"
                    label={t('Locations.Form.Longitude')}
                    placeholder={t('Locations.Form.LongitudePlaceholder')}
                  />
                </Row>
                <FormTitle title={t('Locations.Form.SelectCoordinates')} underlined />
                <Row
                  style={{ height: '500px', margin: '30px 0 20px 0', position: 'relative' }}
                  className="map-row"
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '5px',
                      left: '5px',
                      zIndex: 1,
                      background: '#fff',
                      borderRadius: '8px',
                      padding: '16px',
                      width: '332px'
                    }}
                  >
                    <div>
                      <label style={{ marginBottom: 0 }}>{t('Locations.Form.Address')}</label>
                      <GeoSuggest
                        ref={geosuggestRef}
                        inputClassName={styles.searchLocation}
                        placeholder={t('Locations.Form.PlaceOrLocation')}
                        onSuggestSelect={suggest => {
                          suggest && setNewLocationFromSearch(suggest.location, setFieldValue);
                        }}
                      />
                    </div>
                  </div>
                  <Map
                    mode={MapMode.Location}
                    location={selectedLocation}
                    devices={EMPTY_ARRAY}
                    geofences={EMPTY_ARRAY}
                    mapOptions={{
                      mapTypeControlOptions: {
                        position: google.maps.ControlPosition.TOP_RIGHT
                      },
                      ...(!selectedLocation && mapCenter && { center: mapCenter }),
                      ...(!selectedLocation && zoomLevel && { zoom: zoomLevel })
                    }}
                    containerElement={<div style={{ height: `100%`, width: `100%` }} />}
                    mapElement={<div style={{ height: `100%`, width: `100%` }} />}
                  />
                </Row>
                <FormTitle title={t('Locations.Form.OtherInformation')} underlined />
                <Row>
                  <FormInput
                    name="siteNotes"
                    label={t('Locations.Form.SiteNotes')}
                    placeholder={t('Locations.Form.SiteNotesPlaceholder')}
                    as="textarea"
                  />
                </Row>
              </div>
              <div className={styles.formFooter}>
                <Button
                  size="large"
                  type="primary"
                  disabled={!dirty || !isValid || isSubmitting}
                  htmlType="submit"
                  id={BUTTON_IDS.locationFormSave}
                >
                  {t('Common.SaveButton')}
                </Button>
                <Button size="large" id={BUTTON_IDS.locationFormCancel} onClick={history.goBack}>
                  {t('Common.CancelButton')}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
