import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, Space } from 'antd';
import moment from 'moment';
import request from 'superagent';
import { API_PATH } from 'config';
import { useUserInfo, useUserKey } from 'features/user/userSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { fetchDeviceStats } from 'features/devices/devicesStatsSlice';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useCanOneOfRoles, GlobalRoles } from 'features/permissions';

const isDeviceIgnitionOn = deviceStats => deviceStats?.ignition === 'ON';
const isDeviceMoving = deviceStats => deviceStats?.movement === 'Moving';
const DEVICE_CALIBRATE_APP_KEY = {
  ANGLE_ROLLOVER: ['last rollover calibration', 'last_rollover_calibration'],
  HARSH_DIRECTIONAL: ['last harsh calibration', 'last_harsh_calibration']
};
const DEVICE_STATS_CHECK = { CHECK_INTERVAL: 5000, CHECK_TIMEOUT: 30000 };

const getDeviceLastCalibrateAt = ({ t, localization, hermesDeviceStats, APP_KEY }) => {
  let lastCalibrateAt;
  try {
    if (hermesDeviceStats?.devAppInfo) {
      const devAppInfo = JSON.parse(hermesDeviceStats.devAppInfo);
      const locatedKey = APP_KEY.find(key => devAppInfo?.[key] !== undefined);
      lastCalibrateAt = devAppInfo?.[locatedKey];
      if (!lastCalibrateAt || moment(lastCalibrateAt).year() < 2000) {
        lastCalibrateAt = t('Vehicles.CalibrateAccelerometer.NeverBeenCalibrated');
      } else {
        const momentDate = moment(lastCalibrateAt);
        const today = {
            start: moment().startOf('day'),
            end: moment().endOf('day')
          },
          yesterday = {
            start: moment()
              .subtract(1, 'days')
              .startOf('day'),
            end: moment()
              .subtract(1, 'days')
              .endOf('day')
          };
        if (
          today.start.isSameOrBefore(momentDate, 'day') &&
          today.end.isSameOrAfter(momentDate, 'day')
        ) {
          lastCalibrateAt = `${momentDate.format('h:mm A')} ${t('Common.Today')}`;
        } else if (
          yesterday.start.isSameOrBefore(momentDate, 'day') &&
          yesterday.end.isSameOrAfter(momentDate, 'day')
        ) {
          lastCalibrateAt = `${momentDate.format('h:mm A')} ${t('Common.Yesterday')}`;
        } else {
          lastCalibrateAt = momentDate.format(localization.formats.time.formats.dby_imp);
        }
      }
    }
  } catch (error) {
    lastCalibrateAt = t('Vehicles.CalibrateAccelerometer.NeverBeenCalibrated');
  }
  return lastCalibrateAt;
};

export const useVehicleCalibrateAccelerometer = ({
  vehicleData,
  onAccelerometerCalibrated = () => {}
}) => {
  const localization = useLocalization();
  const { t } = useTranslation();

  const currentUser = useUserInfo();
  const hasSiteAdminOrResellerRole = useCanOneOfRoles([
    GlobalRoles.Reseller,
    GlobalRoles.SiteAdmin
  ]);

  const {
    isSupportCallibrate,
    hermesDevice,
    lastRolloverAt,
    lastHarshDrivingDirectionalAt,
    isRolloverUpdated
  } = useMemo(() => {
    const hermesDevice = (vehicleData.devices || []).find(
      vDevice => vDevice?.type?.code === 'HERMES'
    );
    const hermesDeviceStats = hermesDevice?.stats;
    let isSupportCallibrate = false;
    let lastRolloverAt = t('Vehicles.CalibrateAccelerometer.NeverBeenCalibrated'),
      lastHarshDrivingDirectionalAt = t('Vehicles.CalibrateAccelerometer.NeverBeenCalibrated');
    try {
      if (hermesDeviceStats?.devAppInfo) {
        const devAppInfo = JSON.parse(hermesDeviceStats.devAppInfo);
        isSupportCallibrate = DEVICE_CALIBRATE_APP_KEY.ANGLE_ROLLOVER.some(i =>
          devAppInfo?.hasOwnProperty(i)
        );
        lastRolloverAt = getDeviceLastCalibrateAt({
          t,
          localization,
          hermesDeviceStats,
          APP_KEY: DEVICE_CALIBRATE_APP_KEY.ANGLE_ROLLOVER
        });
        lastHarshDrivingDirectionalAt = getDeviceLastCalibrateAt({
          t,
          localization,
          hermesDeviceStats,
          APP_KEY: DEVICE_CALIBRATE_APP_KEY.HARSH_DIRECTIONAL
        });
      }
    } catch (error) {
      lastRolloverAt = t('Vehicles.CalibrateAccelerometer.NeverBeenCalibrated');
      lastHarshDrivingDirectionalAt = t('Vehicles.CalibrateAccelerometer.NeverBeenCalibrated');
      isSupportCallibrate = false;
    }
    return {
      isSupportCallibrate: isSupportCallibrate && !!hermesDevice?.id,
      hermesDevice,
      lastRolloverAt,
      lastHarshDrivingDirectionalAt,
      isRolloverUpdated(deviceStats) {
        const lastestRolloverAt =
          getDeviceLastCalibrateAt({
            t,
            localization,
            hermesDeviceStats: deviceStats,
            APP_KEY: DEVICE_CALIBRATE_APP_KEY.ANGLE_ROLLOVER
          }) || t('Vehicles.CalibrateAccelerometer.NeverBeenCalibrated');
        return lastestRolloverAt !== lastRolloverAt;
      }
    };
  }, [t, vehicleData, localization]);

  const canUse = useMemo(
    () => (currentUser?.siteAdmin || hasSiteAdminOrResellerRole) && isSupportCallibrate,
    [isSupportCallibrate, currentUser]
  );

  const { startDeviceRollover, isProcessing } = useDeviceRollover(
    hermesDevice,
    onAccelerometerCalibrated,
    isRolloverUpdated
  );

  const VehicleCalibrateAccelerometer = useCallback(() => {
    return (
      canUse && (
        <Row className="calibrateAccelerometerWrapper">
          <Col span={24}>
            <p>{t('Vehicles.CalibrateAccelerometer.Description')}</p>
          </Col>
          <Col span={24}>
            <p className="note">{t('Vehicles.CalibrateAccelerometer.Note')}</p>
          </Col>
          <Col span={24}>
            <Row className="calibrationSection" align="middle">
              <Space direction="vertical" size={0}>
                <h5 styles={{ marginBottom: 0 }}>
                  {t('Vehicles.CalibrateAccelerometer.AngleCalibration.Title')}
                </h5>
                <p>{t('Vehicles.CalibrateAccelerometer.AngleCalibration.Description')}</p>
                <Space size={24}>
                  <Button
                    size="large"
                    type="primary"
                    onClick={startDeviceRollover}
                    loading={isProcessing}
                    id={BUTTON_IDS.vehicleCalibrateAccelerCalibrate}
                  >
                    {t('Vehicles.Calibrate')}
                  </Button>
                  <Space size={4}>
                    <span>{t('Vehicles.CalibrateAccelerometer.AngleCalibration.LastAt')}</span>
                    <em>{lastRolloverAt}</em>
                  </Space>
                </Space>
              </Space>
            </Row>
            <Row className="calibrationSection" align="middle">
              <Space direction="vertical" size={4}>
                <h5>{t('Vehicles.CalibrateAccelerometer.DirectionalCalibration.Title')}</h5>
                <p>{t('Vehicles.CalibrateAccelerometer.DirectionalCalibration.Description')}</p>
                <Space size={4}>
                  <span>{t('Vehicles.CalibrateAccelerometer.DirectionalCalibration.LastAt')}</span>
                  <em>{lastHarshDrivingDirectionalAt}</em>
                </Space>
              </Space>
            </Row>
          </Col>
        </Row>
      )
    );
  }, [t, canUse, lastRolloverAt, isProcessing, startDeviceRollover, lastHarshDrivingDirectionalAt]);

  return {
    canUse,
    VehicleCalibrateAccelerometer
  };
};

const sleep = async seconds => new Promise(reolve => setTimeout(() => reolve(), seconds));

const useDeviceRollover = (hermesDevice, onRolledover = () => {}, isRolloverUpdated = () => {}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userKey = useUserKey();

  const [isProcessing, setIsProcessing] = useState(false);

  const popupRolloverWarning = useCallback(
    warningText => {
      confirmationModal(
        t('Users.Form.Warning'),
        warningText,
        t('Common.Modal.OK'),
        null,
        () => {},
        null,
        null,
        null,
        '600px',
        { cancelButtonProps: { style: { display: 'none' } } }
      );
    },
    [t]
  );

  const { getRolloverPrecondition, rollover } = useMemo(() => {
    const deviceId = hermesDevice?.id;
    const deviceImei = hermesDevice?.imei;
    return {
      getRolloverPrecondition: async () =>
        getDeviceStats(deviceId, userKey).then(deviceStats => ({
          ignitionOn: isDeviceIgnitionOn(deviceStats),
          moving: isDeviceMoving(deviceStats)
        })),
      rollover: async () => {
        const { ok: rolledover, errorReason } = await rolloverDevice({
          deviceId,
          deviceImei,
          userKey,
          isRolloverUpdated
        });
        if (rolledover) {
          dispatch(fetchDeviceStats());
          dispatch(
            openToast({
              type: ToastType.Success,
              message: t('Vehicles.CalibrateAccelerometer.Success')
            })
          );
        } else {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: errorReason
                ? t('Vehicles.CalibrateAccelerometer.Failure.WithReason', {
                    reason: t(`Vehicles.CalibrateAccelerometer.Failure.${errorReason}`)
                  })
                : t('Vehicles.CalibrateAccelerometer.Failure.Default')
            })
          );
        }
        return !!rolledover;
      }
    };
  }, [t, dispatch, hermesDevice, userKey]);

  const onDeviceRollover = useCallback(async () => {
    try {
      setIsProcessing(true);
      const { ignitionOn, moving } = await getRolloverPrecondition();
      if (ignitionOn) {
        if (moving) {
          popupRolloverWarning(t('Vehicles.CalibrateAccelerometer.Warning.VehicleMoving'));
          setIsProcessing(false);
        } else {
          const rolledover = await rollover();
          setIsProcessing(false);
          onRolledover(rolledover);
        }
      } else {
        setIsProcessing(false);
        popupRolloverWarning(t('Vehicles.CalibrateAccelerometer.Warning.IgnitionOff'));
      }
    } catch (error) {
      setIsProcessing(false);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('Vehicles.CalibrateAccelerometer.Failure.Default')
        })
      );
    }
  }, [t, dispatch, popupRolloverWarning, getRolloverPrecondition, rollover]);

  const startDeviceRollover = useCallback(async () => {
    confirmationModal(
      t('Vehicles.CalibrateAccelerometer.Confirm.SureTitle'),
      t('Vehicles.CalibrateAccelerometer.Confirm.SureDescription'),
      t('Vehicles.Calibrate'),
      t('Vehicles.Form.DriverMng.Back'),
      () => {
        onDeviceRollover();
      },
      null,
      null,
      null,
      '600px'
    );
  }, [t, onDeviceRollover]);

  return {
    isProcessing,
    startDeviceRollover
  };
};

const getDeviceStats = async (deviceId, userKey) =>
  request('GET', `${API_PATH}/devices/${deviceId}`)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .then(res => res.body?.deviceStats || { deviceId })
    .catch(err => ({ deviceId }));

const rolloverDevice = async ({ deviceId, deviceImei, userKey, isRolloverUpdated }) =>
  new Promise(resolve => {
    request('POST', `${API_PATH}/devices/${deviceImei}/mdm/calibrate`)
      .set('Authorization', `Token token="${userKey}"`)
      .set('Content-Type', 'application/json')
      .send({
        module: 'accelerometer'
      })
      .end(async (err, response) => {
        const getErrorReason = respStatus => {
          const ERROR_STATUS = {
            401: 'InvalidAccessToken',
            403: 'NoPermissionToDevice',
            404: 'DeviceNotFound',
            406: 'DeviceNotSupportRollover',
            412: 'DeviceOffline'
          };
          return ERROR_STATUS[respStatus];
        };
        //204 only mean service have received the request,service have no way of knowing Hermes have received it or not
        //Rollover success need to be confirmed by further querying hermes last rollover calibration
        const isRolloverDispatched = response?.ok || response?.status === 204;
        if (isRolloverDispatched) {
          const rolledoverConfirmed = await takeUntil({
            take: () => getDeviceStats(deviceId, userKey).then(isRolloverUpdated)
          });
          return resolve({
            ok: rolledoverConfirmed,
            errorReason: !rolledoverConfirmed && 'CalibrationUnconfirmed'
          });
        } else {
          return resolve({
            ok: false,
            errorReason: getErrorReason(response?.status)
          });
        }
      });
  });

const takeUntil = async ({
  take,
  restTime = DEVICE_STATS_CHECK.CHECK_TIMEOUT,
  executeStartAt = moment.now(),
  lastTaken
}) => {
  if (restTime > 0) {
    await sleep(DEVICE_STATS_CHECK.CHECK_INTERVAL);
    lastTaken = await take();
    if (lastTaken) {
      return true;
    }
    restTime -= moment.now() - executeStartAt;
    return await takeUntil({ restTime, executeStartAt: moment.now(), take, lastTaken });
  } else {
    return lastTaken;
  }
};
