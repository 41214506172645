import { createSlice, unwrapResult } from '@reduxjs/toolkit';
import { addEnrolment, getEnrolements, getSchemes } from './thunks';

const initialState = {
  enrolments: [],
  isFetching: false,
  error: null,
  success: null,
  schemes: {
    companies: {},
    isFetching: false
  },
  meta: {
    companyId: null,
    addEnrolment: {
      isSaving: false,
      error: null,
      success: false
    }
  }
};

export const enrolmentsSlice = createSlice({
  name: 'enrolments',
  initialState,
  extraReducers: {
    [getEnrolements.pending]: state => {
      state.isFetching = true;
      state.success = false;
      state.error = false;
    },
    [getEnrolements.fulfilled]: (state, action) => {
      const result = unwrapResult(action);

      state.isFetching = false;
      state.success = true;
      state.error = false;
      state.enrolments = result.body;
      state.meta.companyId = result.companyId;
    },
    [getEnrolements.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.error = true;
      state.success = false;
      state.enrolments = [];
      state.meta.companyId = payload.companyId;
    },
    [addEnrolment.pending]: state => {
      state.meta.addEnrolment.isSaving = true;
    },
    [addEnrolment.rejected]: state => {
      state.meta.addEnrolment.isSaving = false;
      state.meta.addEnrolment.error = true;
      state.meta.addEnrolment.success = false;
    },
    [addEnrolment.fulfilled]: state => {
      state.meta.addEnrolment.isSaving = false;
      state.meta.addEnrolment.error = false;
      state.meta.addEnrolment.success = true;
    },
    [getSchemes.pending]: (state, action) => {
      state.schemes.isFetching = true;

      state.schemes.companies[action.meta.arg.companyId] = {
        success: false,
        error: false
      };
    },
    [getSchemes.fulfilled]: (state, action) => {
      const result = unwrapResult(action);

      state.schemes.isFetching = false;
      state.schemes.companies[action.meta.arg.companyId] = {
        values: result.body,
        success: true,
        error: false
      };
    },
    [getSchemes.rejected]: (state, action) => {
      state.schemes.isFetching = false;

      state.schemes.companies[action.meta.arg.companyId] = {
        success: false,
        error: true
      };
    }
  }
});
