import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUser } from 'features/user/userSlice';
import { useVehicleModels, useIsFetching } from 'features/vehicles/vehicleModels';
import { Button } from 'components/ant';
import { Row, Col, Button as AntButton, Space, Alert } from 'antd';
import { FormGroup, FormLabel, Col as BootStrapCol } from 'react-bootstrap';
import { Select, Tooltip } from 'components/ant';
import styles from './Vehicles.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { VehicleMakeModelYearFormModal } from './VehicleMakeModelYearFormModal';
import { useFuzzySearch } from './VehicleModelSearch.js';

export const VehicleMakeModelYearForm = ({
  onChange,
  isRequired = true,
  onModelSelected,
  isOperationalVehicleModel = false,
  initialVehicleModel = undefined,
  selectedOperationalVehicleModelDetail = undefined,
  setShowOverwriteModal,
  companyId
}) => {
  const { t } = useTranslation();
  const [isFormMakeModelYearDirty, setIsFormMakeModelYearDirty] = useState(false);

  const currentUser = useUser();
  const isSiteAdmin = currentUser.siteAdmin;
  const makeModelYearFullList = useVehicleModels(companyId);
  const isFetchingVehicleModels = useIsFetching();
  const [makeModelYearList, setMakeModelYearList] = useState([]);
  const { performSearch } = useFuzzySearch(makeModelYearList);
  const makeValues = useMemo(() => {
    const makeListWithDuplicates = makeModelYearList.map(makeModelYear => makeModelYear.make);
    const makeList = makeListWithDuplicates.reduce((accumulator, make, index) => {
      if (makeListWithDuplicates.indexOf(make) === index) {
        accumulator.push({ label: make, id: make });
      }
      return accumulator;
    }, []);
    return makeList;
  }, [makeModelYearList]);

  useEffect(() => {
    if (makeModelYearFullList && !isFetchingVehicleModels) {
      const source = isOperationalVehicleModel ? undefined : 'internal';
      setMakeModelYearList(
        (makeModelYearFullList || []).filter(
          i =>
            source === undefined ||
            i.source?.toLowerCase() === source?.toLowerCase() ||
            i.displaySource?.toLowerCase() === source?.toLowerCase()
        )
      );
    }
  }, [makeModelYearFullList, isFetchingVehicleModels]);

  const [tempSearchedValue, setTempSearchedValue] = useState({});
  const [selectedYearValue, setSelectedYearValue] = useState(null);
  const [selectedMakeValue, setSelectedMakeValue] = useState(null);
  const [selectedModelValue, setSelectedModelValue] = useState(null);
  const [selectedSourceValue, setSelectedSourceValue] = useState(null);
  const [selectedDbcValue, setSelectedDbcValue] = useState(null);

  const handleModelValueSelect = value => {
    setSelectedModelValue(value);
    setSelectedYearValue(null);
    setSelectedSourceValue(null);
    setSelectedDbcValue(null);
    setIsFormMakeModelYearDirty(true);
    if (onChange) {
      onChange({ make: selectedMakeValue, model: value });
    }
  };

  const handleYearValueSelect = value => {
    setSelectedYearValue(value);
    setSelectedSourceValue(null);
    setSelectedDbcValue(null);
    setIsFormMakeModelYearDirty(true);
    if (onChange) {
      onChange({ make: selectedMakeValue, model: selectedModelValue, manufactureYear: value });
    }
  };

  const handleSourceValueSelect = value => {
    setSelectedSourceValue(value);
    setSelectedDbcValue(null);
    setIsFormMakeModelYearDirty(true);
    if (onChange) {
      onChange({
        make: selectedMakeValue,
        model: selectedModelValue,
        manufactureYear: selectedYearValue,
        source: value
      });
    }
  };

  const handleDbcValueSelect = value => {
    setSelectedDbcValue(value);
    setIsFormMakeModelYearDirty(true);
    if (onChange) {
      onChange({
        make: selectedMakeValue,
        model: selectedModelValue,
        manufactureYear: selectedYearValue,
        source: selectedSourceValue,
        dbcFileKey: value
      });
    }
  };

  const handleMakeValueSelect = value => {
    setSelectedMakeValue(value);
    setSelectedModelValue(null);
    setSelectedYearValue(null);
    setSelectedSourceValue(null);
    setSelectedDbcValue(null);
    setIsFormMakeModelYearDirty(true);
    if (onChange) {
      onChange({ make: value });
    }
  };

  const handleMakeValueSearch = value => {
    value && setTempSearchedValue({ value, type: 'make' });
  };

  const handleModelValueSearch = value => {
    value && setTempSearchedValue({ value, type: 'model' });
  };

  const handleYearValueSearch = value => {
    value && setTempSearchedValue({ value, type: 'yearOfManufacture' });
  };

  const handleSourceValueSearch = value => {
    value && setTempSearchedValue({ value, type: 'source' });
  };

  const handleDbcValueSearch = value => {
    value && setTempSearchedValue({ value, type: 'dbcKey' });
  };

  const isYearSelected = useCallback(() => {
    return selectedYearValue !== null || selectedYearValue !== undefined;
  }, []);

  useEffect(() => {
    const selectedModel = makeModelYearList.find(
      makeModelYear =>
        makeModelYear.make === selectedMakeValue &&
        makeModelYear.model === selectedModelValue &&
        (makeModelYear.displaySource || makeModelYear.source || null) === selectedSourceValue &&
        makeModelYear.yearOfManufacture === parseInt(selectedYearValue, 0)
    );

    if (
      (!!selectedMakeValue || !!selectedModelValue || !!selectedYearValue) &&
      !selectedModel &&
      !isFetchingVehicleModels
    ) {
      setSelectedYearValue(null);
      setSelectedMakeValue(null);
      setSelectedModelValue(null);
      setSelectedSourceValue(null);
      setSelectedDbcValue(null);
    }
  }, [makeModelYearList]);

  useEffect(() => {
    if (initialVehicleModel) {
      setSelectedYearValue(initialVehicleModel.year);
      setSelectedMakeValue(initialVehicleModel.make);
      setSelectedModelValue(initialVehicleModel.model);
      setSelectedSourceValue(
        initialVehicleModel.displaySource
          ? initialVehicleModel.displaySource
          : initialVehicleModel.source
      );
      setSelectedDbcValue(initialVehicleModel.dbcFileKey);
    }
  }, [initialVehicleModel]);

  const modelValues = useMemo(() => {
    const modelListWithDuplicates = makeModelYearList.reduce((accumulator, makeModelYear) => {
      if (makeModelYear.make === selectedMakeValue) {
        accumulator.push(makeModelYear.model);
      }
      return accumulator;
    }, []);
    const modelList = modelListWithDuplicates.reduce((accumulator, model, index) => {
      if (modelListWithDuplicates.indexOf(model) === index) {
        accumulator.push({ label: model, id: model });
      }

      return accumulator.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    }, []);
    return modelList;
  }, [selectedMakeValue, makeModelYearList]);

  const yearValues = useMemo(() => {
    const yearListWithDuplicates = makeModelYearList.reduce((accumulator, makeModelYear) => {
      if (
        makeModelYear.make === selectedMakeValue &&
        makeModelYear.model === selectedModelValue &&
        makeModelYear.yearOfManufacture !== undefined &&
        makeModelYear.yearOfManufacture !== null
      ) {
        accumulator.push(makeModelYear.yearOfManufacture);
      }
      return accumulator;
    }, []);
    const yearList = yearListWithDuplicates.reduce((accumulator, yearOfManufacture, index) => {
      if (yearListWithDuplicates.indexOf(yearOfManufacture) === index) {
        accumulator.push({ label: yearOfManufacture.toString(), id: yearOfManufacture });
      }

      return accumulator.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));
    }, []);
    return yearList;
  }, [selectedMakeValue, selectedModelValue, makeModelYearList]);

  const sourceValues = useMemo(() => {
    return makeModelYearList.reduce((accumulator, makeModelYear) => {
      if (
        makeModelYear.make === selectedMakeValue &&
        makeModelYear.model === selectedModelValue &&
        parseInt(makeModelYear.yearOfManufacture, 0) === parseInt(selectedYearValue, 0)
      ) {
        const source = makeModelYear.displaySource
          ? makeModelYear.displaySource
          : makeModelYear.source;
        accumulator.push({
          label: source || 'Unknown',
          id: source
        });
      }
      return accumulator;
    }, []);
  }, [selectedMakeValue, selectedModelValue, selectedYearValue, makeModelYearList]);

  const dbcValues = useMemo(() => {
    return makeModelYearList.reduce((accumulator, makeModelYear) => {
      if (
        makeModelYear.make === selectedMakeValue &&
        makeModelYear.model === selectedModelValue &&
        parseInt(makeModelYear.yearOfManufacture, 0) === parseInt(selectedYearValue, 0) &&
        (makeModelYear.displaySource || makeModelYear.source || null) === selectedSourceValue &&
        (makeModelYear.extras || makeModelYear.dbcFileKey)
      ) {
        const extrasObj = JSON.parse(makeModelYear.extras || '{}');
        const dbcAvailable = extrasObj.dbcAvailable || [];
        if (dbcAvailable.length !== 0) {
          (extrasObj.dbcAvailable || []).forEach(data => {
            accumulator.push({
              label: data.dbc_file_key,
              id: data.dbc_file_key
            });
          });
        } else if (makeModelYear.dbcFileKey) {
          accumulator.push({
            label: makeModelYear.dbcFileKey,
            id: makeModelYear.dbcFileKey
          });
        }
      }
      return accumulator;
    }, []);
  }, [
    selectedMakeValue,
    selectedModelValue,
    selectedYearValue,
    selectedSourceValue,
    makeModelYearList
  ]);

  useEffect(() => {
    let selectedModelObj = {};
    selectedModelObj.make = selectedMakeValue;
    selectedModelObj.model = selectedModelValue;
    selectedModelObj.year = selectedYearValue;
    selectedModelObj.displaySource = selectedSourceValue;
    selectedModelObj.dbcFileKey = selectedDbcValue;

    if (selectedMakeValue && selectedModelValue && isYearSelected()) {
      const modelId = makeModelYearList.find(
        makeModelYear =>
          makeModelYear.make === selectedMakeValue &&
          makeModelYear.model === selectedModelValue &&
          (makeModelYear.displaySource || makeModelYear.source || null) === selectedSourceValue &&
          makeModelYear.yearOfManufacture === parseInt(selectedYearValue, 0)
      )?.id;

      selectedModelObj.id = modelId;
    }

    onModelSelected(selectedModelObj);
  }, [
    makeModelYearList,
    selectedMakeValue,
    selectedModelValue,
    selectedYearValue,
    selectedSourceValue,
    selectedDbcValue
  ]);

  useEffect(() => {
    if (!isOperationalVehicleModel && !isFetchingVehicleModels) {
      const newSource = sourceValues.find(i => i.id === 'internal' || i.id === undefined);
      setSelectedSourceValue(
        newSource
          ? newSource.id || null
          : (sourceValues || []).length !== 0
          ? sourceValues[0].id
          : null
      );
    }
  }, [selectedYearValue]);

  const [isMakeFormOpen, setIsMakeFormOpen] = useState(false);

  const openMakeModelYearForm = e => {
    e.preventDefault();
    setIsMakeFormOpen(true);
  };

  const closeMakeForm = () => {
    setTempSearchedValue({});
    setIsMakeFormOpen(false);
  };

  const updateMakeModelYearValuesAfterAdd = (make, model, year, source) => {
    setSelectedMakeValue(make);
    setSelectedModelValue(model);
    setSelectedYearValue(year);
    handleSourceValueSelect(source);
  };

  const renderNotFoundPanel = type => {
    let possibleMatch = [];
    if (tempSearchedValue.value) {
      let possibleMatchModel = performSearch(tempSearchedValue.value || '', type);
      possibleMatchModel = possibleMatchModel.filter(
        i =>
          type === 'make' ||
          (type === 'model' && selectedMakeValue === i.make) ||
          (type === 'yearOfManufacture' &&
            selectedMakeValue === i.make &&
            selectedModelValue === i.model)
      );
      possibleMatch = Array.from(new Set(possibleMatchModel.map(i => i[type]))).slice(0, 5);
    }

    return (
      <div style={{ padding: '0px 10px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {(possibleMatch || []).map((data, index) => (
            <a
              key={index}
              className={styles.makeModelYearSuggestionValue}
              onClick={() => {
                if (type === 'make') {
                  handleMakeValueSelect(data);
                } else if (type === 'model') {
                  handleModelValueSelect(data);
                } else if (type === 'year') {
                  handleYearValueSelect(data);
                }

                document.activeElement.blur();
              }}
            >
              {data}
            </a>
          ))}
        </div>
        <div className={styles.makeModelYearSuggestionText}>
          {t('Vehicles.Form.MakeModelYearNotFoundSuggestion', {
            keyword: tempSearchedValue.value
          })}
        </div>
        {!isOperationalVehicleModel && (
          <div className={styles.makeModelYearSuggestionText}>
            <Button
              size="medium"
              type="link"
              className={styles.addNewButton}
              onClick={openMakeModelYearForm}
              id={BUTTON_IDS.vehicleFormSelectOpenModelYear}
            >{`${t('Vehicles.Form.AddNew')}`}</Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {isMakeFormOpen && (
        <VehicleMakeModelYearFormModal
          isOpen={isMakeFormOpen}
          onCancel={closeMakeForm}
          companyId={companyId}
          initialValues={[selectedMakeValue, selectedModelValue, selectedYearValue]}
          key={`${selectedMakeValue}-${selectedModelValue}-${selectedYearValue}-${tempSearchedValue.value}`}
          tempSearchedValue={tempSearchedValue}
          updateMakeModelYearValuesAfterAdd={updateMakeModelYearValuesAfterAdd}
          makeModelYearList={makeModelYearList}
        />
      )}
      <FormGroup as={BootStrapCol} className={styles.makeModelYear}>
        {isOperationalVehicleModel && (
          <Row className={styles.formHeaderRow}>
            <Alert
              description={t('Vehicles.Form.OverrideVehicleModelWarning')}
              type="info"
              showIcon
            />
          </Row>
        )}
        <Row className={styles.formHeaderRow}>
          <Col xs={{ span: 16 }} className={styles.makeModelYearHeaderRow}>
            <FormLabel>
              {t('Vehicles.Form.MakeModelYearLabel')}
              {isRequired && <span className={styles.inputRequired}>*</span>}
            </FormLabel>
          </Col>
          <Col xs={{ span: 8 }} className={styles.makeModelYearActionDiv}>
            {isSiteAdmin && !isOperationalVehicleModel && (
              <AntButton
                type="link"
                className={styles.overrideMakeModelYear}
                onClick={setShowOverwriteModal}
                id={BUTTON_IDS.vehicleOperationalFormOpenModelYear}
              >
                {t(
                  selectedOperationalVehicleModelDetail?.id
                    ? 'Vehicles.Form.EditOverrideVehicleModel'
                    : 'Vehicles.Form.OverrideVehicleModel'
                )}
              </AntButton>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} className={styles.borderCol}>
            <Space direction="vertical">
              <Select
                name="make"
                placeholder={t('Vehicles.Form.MakePlaceholder')}
                value={selectedMakeValue}
                onSearch={handleMakeValueSearch}
                onSelect={handleMakeValueSelect}
                data={isFetchingVehicleModels ? [] : makeValues}
                allowClear={!isRequired}
                onClear={handleMakeValueSelect}
                loading={isFetchingVehicleModels}
                status={
                  isRequired && !selectedMakeValue && isFormMakeModelYearDirty ? 'error' : null
                }
                errorMessage={t('Vehicles.VehicleMakeRequired')}
                notFoundContent={renderNotFoundPanel('make')}
              />
              <Select
                name="model"
                placeholder={t('Vehicles.Form.ModelPlaceholder')}
                value={selectedModelValue}
                onSearch={handleModelValueSearch}
                onSelect={handleModelValueSelect}
                disabled={!selectedMakeValue}
                onClear={handleModelValueSelect}
                data={isFetchingVehicleModels ? [] : modelValues}
                allowClear={!isRequired}
                loading={isFetchingVehicleModels}
                status={
                  (isRequired || selectedMakeValue) &&
                  !selectedModelValue &&
                  isFormMakeModelYearDirty
                    ? 'error'
                    : null
                }
                errorMessage={t('Vehicles.VehicleModelRequired')}
                notFoundContent={renderNotFoundPanel('model')}
              />

              <Select
                name="year"
                placeholder={t('Vehicles.Form.YearPlaceholder')}
                disabled={!selectedModelValue}
                value={selectedYearValue}
                onSearch={handleYearValueSearch}
                allowClear={!isRequired}
                onSelect={handleYearValueSelect}
                onClear={handleYearValueSelect}
                loading={isFetchingVehicleModels}
                data={isFetchingVehicleModels ? [] : yearValues}
                status={
                  (isRequired || selectedMakeValue) && !isYearSelected() && isFormMakeModelYearDirty
                    ? 'error'
                    : null
                }
                errorMessage={t('Vehicles.ManufactureYearRequired')}
                notFoundContent={renderNotFoundPanel('yearOfManufacture')}
              />
              {(sourceValues || []).length >= 1 && isOperationalVehicleModel && (
                <Select
                  name="source"
                  placeholder={t('Vehicles.Form.SourcePlaceholder')}
                  disabled={!isYearSelected() || !isSiteAdmin}
                  value={selectedSourceValue}
                  allowClear={!isRequired}
                  loading={isFetchingVehicleModels}
                  onClear={handleSourceValueSelect}
                  status={
                    (isRequired || selectedMakeValue) &&
                    isOperationalVehicleModel &&
                    !selectedSourceValue
                      ? 'error'
                      : null
                  }
                  onSearch={handleSourceValueSearch}
                  onSelect={handleSourceValueSelect}
                  data={isFetchingVehicleModels ? [] : sourceValues}
                />
              )}
              {(dbcValues || []).length >= 1 && isOperationalVehicleModel && (
                <Select
                  name="dbcFileKey"
                  placeholder={t('Vehicles.Form.DbcFileKeyPlaceholder')}
                  disabled={!selectedSourceValue || !isSiteAdmin}
                  value={selectedDbcValue}
                  allowClear={!isRequired}
                  loading={isFetchingVehicleModels}
                  onClear={handleDbcValueSelect}
                  status={
                    (isRequired || selectedMakeValue) &&
                    isOperationalVehicleModel &&
                    !selectedSourceValue
                      ? 'error'
                      : null
                  }
                  onSearch={handleDbcValueSearch}
                  onSelect={handleDbcValueSelect}
                  data={isFetchingVehicleModels ? [] : dbcValues}
                />
              )}
            </Space>
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};
