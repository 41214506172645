/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import LocalDateTime from "./LocalDateTime";
import User from "./User";

/**
 * The Announcement model module.
 * @module model/Announcement
 * @version 1.0
 */
class Announcement {
  /**
   * Constructs a new <code>Announcement</code>.
   * @alias module:model/Announcement
   */
  constructor() {
    Announcement.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Announcement</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Announcement} obj Optional instance to populate.
   * @return {module:model/Announcement} The populated <code>Announcement</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Announcement();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("title")) {
        obj["title"] = ApiClient.convertToType(data["title"], "String");
      }
      if (data.hasOwnProperty("content")) {
        obj["content"] = ApiClient.convertToType(data["content"], "String");
      }
      if (data.hasOwnProperty("link")) {
        obj["link"] = ApiClient.convertToType(data["link"], "String");
      }
      if (data.hasOwnProperty("timeAt")) {
        obj["timeAt"] = LocalDateTime.constructFromObject(data["timeAt"]);
      }
      if (data.hasOwnProperty("softwareVersion")) {
        obj["softwareVersion"] = ApiClient.convertToType(
          data["softwareVersion"],
          "String"
        );
      }
      if (data.hasOwnProperty("user")) {
        obj["user"] = User.constructFromObject(data["user"]);
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = LocalDateTime.constructFromObject(data["createdAt"]);
      }
      if (data.hasOwnProperty("updatedAt")) {
        obj["updatedAt"] = LocalDateTime.constructFromObject(data["updatedAt"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Announcement.prototype["id"] = undefined;

/**
 * @member {String} title
 */
Announcement.prototype["title"] = undefined;

/**
 * @member {String} content
 */
Announcement.prototype["content"] = undefined;

/**
 * @member {String} link
 */
Announcement.prototype["link"] = undefined;

/**
 * @member {module:model/LocalDateTime} timeAt
 */
Announcement.prototype["timeAt"] = undefined;

/**
 * @member {String} softwareVersion
 */
Announcement.prototype["softwareVersion"] = undefined;

/**
 * @member {module:model/User} user
 */
Announcement.prototype["user"] = undefined;

/**
 * @member {module:model/Announcement.StatusEnum} status
 */
Announcement.prototype["status"] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
Announcement.prototype["createdAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} updatedAt
 */
Announcement.prototype["updatedAt"] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Announcement["StatusEnum"] = {
  /**
   * value: "ENABLED"
   * @const
   */
  ENABLED: "ENABLED",

  /**
   * value: "DISABLED"
   * @const
   */
  DISABLED: "DISABLED",

  /**
   * value: "READY_FOR_DOWNLOAD"
   * @const
   */
  READY_FOR_DOWNLOAD: "READY_FOR_DOWNLOAD",

  /**
   * value: "PENDING"
   * @const
   */
  PENDING: "PENDING",

  /**
   * value: "AVAILABLE"
   * @const
   */
  AVAILABLE: "AVAILABLE",

  /**
   * value: "UNAVAILABLE"
   * @const
   */
  UNAVAILABLE: "UNAVAILABLE",

  /**
   * value: "FAILED"
   * @const
   */
  FAILED: "FAILED"
};

export default Announcement;
