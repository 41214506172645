import { ButtonStyle, ExpandButton, IconStyle } from 'components/ant/Button/ExpandButton';
import { durationFormat, toNowStamp } from 'features/fatigue/time_util';
import React from 'react';
import {
  availableCellRenderer,
  LinkCell,
  majorRestDueCellRenderer,
  MeatballsMenu,
  nextRestDueCellRenderer
} from './CellRenderer';
import { getClassOfDriverState, stateRenderer } from 'features/fatigue/StateRenderer';
import { TimeCell, CellContentLoader } from 'features/fatigue/Components';
import moment from 'moment';

export const driversCols = [
  {
    label: '', //State
    dataKey: 'state',
    cellRenderer: ({ rowData }) => stateRenderer(rowData, 'sentinelStatus'),
    width: '1%',
    sort: {
      name: 'State',
      sortFn: (a, b) => {
        const valueA = getClassOfDriverState(a.sentinelStatus?.lastStatusEvent?.type).title;
        const valueB = getClassOfDriverState(b.sentinelStatus?.lastStatusEvent?.type).title;
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Name',
    dataKey: 'name',
    cellRenderer: ({ rowData }) => <LinkCell rowData={rowData} />,
    width: '9%',
    style: { whiteSpace: 'normal' },
    sort: {
      name: 'Name',
      sortFn: (a, b) => {
        const valueA = (a.firstName + ' ' + a.lastName).toLowerCase();
        const valueB = (b.firstName + ' ' + b.lastName).toLowerCase();
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Licence',
    dataKey: 'licenceNumber',
    width: '9%',
    style: { whiteSpace: 'normal' },
    sort: {
      name: 'Licence',
      sortFn: (a, b) => {
        if (a.licenceNumber > b.licenceNumber) return 1;
        if (a.licenceNumber === b.licenceNumber) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Company/Branch',
    dataKey: 'companyBranch',
    cellDataGetter: ({ rowData }) =>
      (rowData.company ? rowData.company.label : '-') +
      ' / ' +
      (rowData.branch ? rowData.branch.label : 'No Branch'),
    width: '9%',
    style: { whiteSpace: 'normal' },
    sort: [
      {
        name: 'Company',
        sortFn: (a, b) => {
          if (a.company?.label > b.company?.label) return 1;
          if (a.company?.label === b.company?.label) return 0;
          return -1;
        }
      },
      {
        name: 'Branch',
        sortFn: (a, b) => {
          const valueA = a.branch?.label || 'No Branch';
          const valueB = b.branch?.label || 'No Branch';
          if (valueA > valueB) return 1;
          if (valueA === valueB) return 0;
          return -1;
        }
      }
    ]
  },
  {
    label: 'Fleet/Vehicle',
    dataKey: 'branch',
    cellRenderer: ({ rowData }) => (
      <div>
        <span>{rowData.tmpData?.fleet.name || '-'}</span> / {rowData.tmpData?.vehicle.name || '-'}
      </div>
    ),
    width: '9%',
    style: { whiteSpace: 'normal' },
    sort: [
      {
        name: 'Fleet',
        sortFn: (a, b) => {
          if (a.tmpData?.fleet?.name > b.tmpData?.fleet?.name) return 1;
          if (a.tmpData?.fleet?.name === b.tmpData?.fleet?.name) return 0;
          return -1;
        }
      },
      {
        name: 'Vehicle',
        sortFn: (a, b) => {
          if (a.tmpData?.vehicle?.name > b.tmpData?.vehicle?.name) return 1;
          if (a.tmpData?.vehicle?.name === b.tmpData?.vehicle?.name) return 0;
          return -1;
        }
      }
    ]
  },
  {
    label: 'Current Location',
    dataKey: 'location',
    cellRenderer: ({ rowData, isLoading = false }) =>
      isLoading ? <CellContentLoader /> : rowData.sentinelStatus?.lastEvent?.location || '',
    width: '13%',
    style: { whiteSpace: 'normal' },
    sort: {
      name: 'Current Location',
      sortFn: (a, b) => {
        const valueA = a.sentinelStatus?.lastEvent?.location || '';
        const valueB = b.sentinelStatus?.lastEvent?.location || '';
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Last Update',
    dataKey: 'lastUpdate',
    cellRenderer: ({ rowData, isLoading = false }) =>
      isLoading ? (
        <CellContentLoader />
      ) : (
        <TimeCell
          time={
            rowData.sentinelStatus?.checkpointAt ||
            rowData.sentinelStatus?.pivotTimeAt ||
            rowData.sentinelStatus?.lastStatusEvent?.timeAt
          }
        />
      ),
    width: '9%',
    style: { whiteSpace: 'normal' },
    sort: {
      name: 'Last Update',
      sortFn: (a, b) => {
        const valueA =
          (a.sentinelStatus?.checkpointAt ||
            a.sentinelStatus?.pivotTimeAt ||
            a.sentinelStatus?.lastStatusEvent?.timeAt) ??
          -1;
        const valueB =
          (b.sentinelStatus?.checkpointAt ||
            b.sentinelStatus?.pivotTimeAt ||
            b.sentinelStatus?.lastStatusEvent?.timeAt) ??
          -1;
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Hours Worked in Period',
    dataKey: 'available',
    cellRenderer: ({ isLoading = false, ...cellProps }) =>
      isLoading ? <CellContentLoader /> : availableCellRenderer(cellProps),
    width: '9.5%',
    sort: {
      name: 'Hours Worked in Period',
      sortFn: (a, b) => {
        const valueA =
          a.sentinelStatus?.totals?.period?.finishAt > moment().valueOf()
            ? a.sentinelStatus?.totals?.worked ?? -1
            : -1;
        const valueB =
          b.sentinelStatus?.totals?.period?.finishAt > moment().valueOf()
            ? b.sentinelStatus?.totals?.worked ?? -1
            : -1;
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Major Rest Due In',
    dataKey: 'majorRestDue',
    cellRenderer: ({ isLoading = false, ...cellProps }) =>
      isLoading ? <CellContentLoader /> : majorRestDueCellRenderer(cellProps),
    width: '9%',
    sort: {
      name: 'Major Rest Due In',
      sortFn: (a, b) => {
        const getKey = rowData => {
          const restFieldKeys = ['24HrRest', '2NightRest', 'nightRest'];
          const key = restFieldKeys.reduce((key, currKey) => {
            if (
              rowData.sentinelStatus?.[key]?.nextStartAt >
              rowData.sentinelStatus?.[currKey]?.nextStartAt
            ) {
              return currKey;
            } else {
              return key;
            }
          });
          return key;
        };

        const valueA = a.sentinelStatus?.lastStatusEvent
          ? a.sentinelStatus?.[getKey(a)]?.nextStartAt || (a.sentinelStatus?.[getKey(a)] ? 0 : -1)
          : -1;
        const valueB = b.sentinelStatus?.lastStatusEvent
          ? b.sentinelStatus?.[getKey(b)]?.nextStartAt || (b.sentinelStatus?.[getKey(b)] ? 0 : -1)
          : -1;
        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: 'Next Rest Due In',
    dataKey: 'nextRestDue',
    cellRenderer: ({ isLoading = false, ...cellProps }) =>
      isLoading ? <CellContentLoader /> : nextRestDueCellRenderer(cellProps),
    width: '9%',
    sort: {
      name: 'Next Rest Due In',
      sortFn: (a, b) => {
        function timeValue(sentinelData) {
          const TypeRank = {
            NoEvent: -5,
            HasViolations: -4,
            HasPotentialViolations: -3,
            RestPastDue: -2,
            InResting: -1,
            Normal: 0
          };
          if (sentinelData?.currentViolations?.length > 0) {
            if (sentinelData.currentViolations.every(v => v.startAt > sentinelData.checkpointAt)) {
              return TypeRank.HasPotentialViolations;
            }
            return TypeRank.HasViolations;
          }

          if (
            sentinelData &&
            (!sentinelData.lastStatusEvent ||
              moment()
                .subtract(7, 'day')
                .isAfter(moment(sentinelData.lastStatusEvent.timeAt)))
          ) {
            return TypeRank.NoEvent;
          }

          const isResting = [/rest/i, /offduty/i, /sleep/i].some(reg =>
            sentinelData?.lastStatusEvent?.type?.match(reg)
          );
          if (isResting) {
            return TypeRank.InResting;
          }

          return (
            (sentinelData?.nextViolation?.start || sentinelData?.nextViolation?.startAt) ??
            TypeRank.RestPastDue
          );
        }

        const valueA = timeValue(a.sentinelStatus);
        const valueB = timeValue(b.sentinelStatus);

        if (valueA > valueB) return 1;
        if (valueA === valueB) return 0;
        return -1;
      }
    }
  },
  {
    label: '',
    dataKey: 'meatballs',
    cellRenderer: ({ rowData, isLoading = false }) =>
      isLoading ? (
        <CellContentLoader />
      ) : (
        <div
          style={{
            display: 'flex',
            height: '36px',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <MeatballsMenu rowData={rowData} />
          <ExpandButton
            buttonStyle={ButtonStyle.text}
            iconStyle={IconStyle.SignleArrow}
            onExpand={rowData.handleExpand}
            defaultExpand={rowData.expand}
          />
        </div>
      ),
    width: '9%'
  }
];

export const driverStatusCells = [
  [
    {
      title: 'Ruleset',
      dataKey: 'ruleset',
      cellRenderer: rowData => {
        return rowData?.sentinelStatus?.ruleset?.name || '-';
      }
    },
    {
      title: 'Description',
      dataKey: 'rulesetDesc',
      cellRenderer: rowData => {
        return rowData?.sentinelStatus?.ruleset?.desc || '-';
      }
    }
  ],
  [
    {
      title: 'Hours worked',
      dataKey: 'hoursWorked',
      cellRenderer: rowData => {
        return rowData.sentinelStatus?.totals?.period?.finishAt > moment().valueOf()
          ? durationFormat(rowData?.sentinelStatus?.totals?.worked * 1000) ?? '-'
          : '-';
      },
      enable: () => true
    },
    {
      title: 'Hours available',
      dataKey: 'hoursAvailable',
      cellRenderer: rowData => {
        return durationFormat(rowData?.sentinelStatus?.totals?.available * 1000) ?? '-';
      },
      enable: () => true
    },
    {
      title: 'Last 24hr rest',
      dataKey: '24hrRest',
      cellRenderer: (rowData, _, localization) => {
        return (
          toNowStamp(
            rowData?.sentinelStatus?.['24HrRest']?.lastFinishAt,
            localization.formats.time.formats.dby_hms_z,
            localization.formats.time.formats.dby_hm
          ) ?? '-'
        );
      },
      enable: () => true
    },
    {
      title: 'Work since 24hr rest',
      dataKey: 'nightRestDue',
      cellRenderer: rowData => {
        if (
          rowData?.sentinelStatus?.['84HrRule']?.period?.finishAt > moment().valueOf() ||
          rowData?.sentinelStatus?.['70HrRule']?.period?.finishAt > moment().valueOf()
        ) {
          return (
            durationFormat(
              (rowData?.sentinelStatus?.['84HrRule']?.work ??
                rowData?.sentinelStatus?.['70HrRule']?.work) * 1000
            ) ?? '-'
          );
        }
        return '-';
      },
      enable: rowData =>
        rowData?.sentinelStatus?.['84HrRule'] || rowData?.sentinelStatus?.['70HrRule']
    },
    {
      title: '24hr rest due',
      dataKey: '24hrRestDue',
      cellRenderer: (rowData, _, localization) => {
        return (
          toNowStamp(
            rowData?.sentinelStatus?.['24HrRest']?.nextStartAt,
            localization.formats.time.formats.dby_hms_z,
            localization.formats.time.formats.dby_hm
          ) ?? '-'
        );
      },
      enable: () => true
    },
    {
      title: 'Long/night worked',
      dataKey: 'longNightWorked',
      cellRenderer: rowData => {
        return (
          durationFormat(
            (rowData?.sentinelStatus?.longNightTotals?.excess +
              rowData?.sentinelStatus?.longNightTotals?.night) *
              1000
          ) ?? '-'
        );
      },
      enable: rowData => rowData?.sentinelStatus?.longNightTotals != null
    },
    {
      title: 'Night rests',
      dataKey: 'nightRests',
      cellRenderer: rowData => {
        return rowData?.sentinelStatus?.nightRest?.count ?? '-';
      },
      enable: rowData => rowData?.sentinelStatus?.nightRest?.count != null
    },
    {
      title: 'Night rest due',
      dataKey: 'nightRestDue',
      cellRenderer: (rowData, _, localization) => {
        return (
          toNowStamp(
            rowData?.sentinelStatus?.nightRest?.nextStartAt,
            localization.formats.time.formats.dby_hms_z,
            localization.formats.time.formats.dby_hm
          ) ?? '-'
        );
      },
      enable: rowData => rowData?.sentinelStatus?.nightRest?.nextStartAt != null
    }
  ]
];
