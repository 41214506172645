import React from 'react';
import { Link } from 'react-router-dom';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { Checkbox, Tooltip } from 'antd';
import { format } from 'utils/dates';
import styles from 'components/tn/grid/grid.module.scss';

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 51,
  heigth: 51
});

export const nameCellRenderer = ({
  dataKey,
  parent,
  rowIndex,
  rowData,
  checkedItems,
  canViewDocument
}) => {
  const active = checkedItems && checkedItems.includes(rowData.id);
  const name = rowData.name || rowData.filename;
  return (
    <CellMeasurer cache={cache} columnIndex={0} key={dataKey} parent={parent} rowIndex={rowIndex}>
      <div className={`document__date-wrapper ${active && 'active'}`}>
        {canViewDocument ? (
          <Link
            to={{
              pathname: `/easydocs/documents/id/${rowData.id}`,
              state: {
                pageTitle: name,
                folder: rowData.folders.length && rowData.folders[0]
              }
            }}
          >
            {name}
          </Link>
        ) : (
          <div>{name}</div>
        )}
      </div>
    </CellMeasurer>
  );
};
export const createdAtCellRendered = ({ rowData, checkedItems, localization }) => {
  const active = checkedItems && checkedItems.includes(rowData.id);
  return (
    <div className={`document__date-wrapper ${active && 'active'}`}>
      {rowData.uploadedAt
        ? format(new Date(rowData.uploadedAt), localization.formats.time.formats.dby_imsp)
        : '-'}
    </div>
  );
};

export const deviceLinkCellRenderer = ({ rowData }) => {
  if (!rowData) return '';
  const { name, id } = rowData;

  if (name && id) {
    return <Link to={`/settings/devices/id/${id}`}>{name}</Link>;
  }

  return '';
};

export const auditVehicleLink = ({ rowData }) => {
  if (rowData) {
    const { vehicle } = rowData;
    if (vehicle?.id && vehicle?.name) {
      return <Link to={`/settings/vehicles/id/${vehicle?.id}`}>{vehicle?.name}</Link>;
    }
  }
  return '';
};

export const auditDeviceLink = ({ rowData }) => {
  if (!rowData) return '';

  const { device, deviceId, deviceName } = rowData;
  const id = device?.id || deviceId;
  const name = device?.name || deviceName;

  if (id && name) {
    return <Link to={`/settings/devices/id/${id}`}>{name}</Link>;
  }

  return '';
};
export const auditUserLink = ({ rowData }) => {
  if (!rowData) return '';
  const { userFirstname, userLastname, userId, user } = rowData;
  const firstName = user?.firstName || userFirstname;
  const lastName = user?.lastName || userLastname;
  const id = user?.id || userId;
  if (firstName && lastName && id) {
    return <Link to={`/settings/users/id/${id}`}>{`${firstName} ${lastName}`}</Link>;
  }

  return '';
};

export const checkboxCellRenderer = ({ rowData, checkedItems }) => {
  const active = checkedItems && checkedItems.includes(rowData.id);
  return (
    <div className={`document__checkbox-wrapper ${active && 'active'}`}>
      <Checkbox value={rowData.id} checked={active} onChange={() => {}} />
    </div>
  );
};

export const nameCellRenderer_ANT = ({ rowData, canViewDocument }) => {
  const name = rowData.name || rowData.filename;
  return canViewDocument ? (
    <Link
      to={{
        pathname: `/easydocs/documents/id/${rowData.id}`,
        state: {
          pageTitle: name,
          folder: rowData.folders?.length && rowData.folders[0]
        }
      }}
    >
      <span title={name} className={styles.twoLineEllipsisCell}>
        {name}
      </span>
    </Link>
  ) : (
    <span title={name} className={styles.twoLineEllipsisCell}>
      {name}
    </span>
  );
};

export const vehicleCellRenderer_ANT = ({ rowData }) => {
  if (rowData && rowData.vehicleDto) {
    const name = rowData.vehicleName;
    return (
      <Link
        to={{
          pathname: `/settings/vehicles/id/${rowData.vehicleDto?.id}`
        }}
      >
        <span title={name} className={styles.twoLineEllipsisCell} style={{ width: '300px' }}>
          {name}
        </span>
      </Link>
    );
  }
  return '';
};

export const deviceCellRenderer_ANT = ({ rowData }) => {
  if (rowData && rowData.deviceDto) {
    const name = rowData.deviceName;
    return (
      <Link
        to={{
          pathname: `/settings/devices/id/${rowData.deviceDto?.id}`
        }}
      >
        <span title={name} className={styles.twoLineEllipsisCell} style={{ width: '300px' }}>
          {name}
        </span>
      </Link>
    );
  }
  return '';
};

export const checkboxCellRenderer_ANT = ({ rowData, checkedItems }) => {
  const active = checkedItems && checkedItems.includes(rowData.id);
  return (
    <div>
      <Checkbox value={rowData.id} checked={active} onChange={() => {}} />
    </div>
  );
};

export const dateCellRenderer_ANT = ({ date, dateFormat }) => {
  return <div>{date ? format(new Date(date), dateFormat) : '-'}</div>;
};

export const stringCellRenderer_ANT = string => {
  return (
    <span title={string} className={styles.twoLineEllipsisCell}>
      {string}
    </span>
  );
};

export const easydocVehicleCellRenderer_ANT = ({ rowData }) => {
  if (rowData && rowData.vehicle) {
    const name = rowData.vehicle.name;
    return (
      <Link
        to={{
          pathname: `/settings/vehicles/id/${rowData.vehicle?.id}`
        }}
      >
        <span title={name} className={styles.twoLineEllipsisCell} style={{ width: '200px' }}>
          {name}
        </span>
      </Link>
    );
  }
  return '';
};

export const easydocDeviceCellRenderer_ANT = ({ rowData }) => {
  if (rowData && rowData.device) {
    const name = rowData.device.name;
    return (
      <Link
        to={{
          pathname: `/easydocs/mobiledevices/id/${rowData.device.id}`
        }}
      >
        <span title={name} className={styles.twoLineEllipsisCell} style={{ width: '200px' }}>
          {name}
        </span>
      </Link>
    );
  }
  return '';
};
