import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareAuthHeaders } from 'services/util';
import { API_PATH } from 'config';

export const ngUserPrefConfigApi = createApi({
  reducerPath: 'ngUserPrefConfigApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_PATH,
    prepareHeaders: prepareAuthHeaders
  }),
  endpoints: builder => ({
    getUserConfigByPref: builder.query({
      query: ({ userId, pref }) => `users/${userId}/config/${pref}`
    }),
    updateUserConfigByPref: builder.mutation({
      query: ({ userId, pref, columns }) => ({
        method: 'PUT',
        url: `users/${userId}/config/${pref}`,
        body: columns
      })
    })
  })
});

export const {
  useGetUserConfigByPrefQuery,
  useUpdateUserConfigByPrefMutation
} = ngUserPrefConfigApi;
