import { Card } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './AlertCard.module.scss';

function AlertCard({ title, active, inactive, disabled }) {
  return (
    <Card className={styles.cardContainer + (disabled ? ' ' + styles.disabled : '')} title={title}>
      <div className={styles.infoContainer}>
        <span className={styles.dataContainer}>
          <span className={styles.number}>{active}</span>
          <span className={styles.status}>ON</span>
        </span>
        <span className={styles.dataContainer}>
          <span className={styles.number}>{inactive}</span>
          <span className={styles.status}>OFF</span>
        </span>
      </div>
    </Card>
  );
}

AlertCard.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.number.isRequired,
  inactive: PropTypes.number.isRequired
};

export default AlertCard;
