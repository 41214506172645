import React, { useState, useEffect } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Switch, Route } from 'react-router-dom';

import { TabNavLink } from 'components/nav/NavLinks';
import { SortableCardViewCard } from './Cards/SortableCardViewCard';
import { OnboardingCard } from './Cards/OnboardingCard';
import { Loading } from 'components/loading/Loading';
import { CardType } from './Cards/CardType';

import { MessageComposeModal } from 'containers/Messaging/MessageComposeModal';
import { RECIPIENT_TYPE } from 'containers/Messaging/constants';

import styles from './CardView.module.scss';
import { useTranslation } from 'react-i18next';

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import VList from 'react-virtualized/dist/commonjs/List';
import { CardViewLens, CARD_SIZE } from './constants';
import useCardViewFilterBar from './useCardViewFilterBar';
import {
  useCurrentCompanyKey,
  useCurrentCompanyId,
  useSubCompanyEntityConfig,
  CompanyConfigKey,
  CompanyConfigValue
} from 'features/company/companySlice';

export const CardViewPanel = SortableContainer(
  ({
    cards,
    geofences,
    pinnedCards,
    unpinnedDriverCards,
    unpinnedVehicleCards,
    onPinClick,
    toggleMapView,
    isLoading
  }) => {
    const { t } = useTranslation();
    const currentCompanyKey = useCurrentCompanyKey();
    const currentCompanyId = useCurrentCompanyId();
    const nonBusinessCompanyConfig = useSubCompanyEntityConfig(
      currentCompanyId,
      CompanyConfigKey.HideNonBusiness
    );

    const {
      FilterBar,
      filterConfig,
      filterCards,
      sortCards,
      setCurrentTab,
      resetFilter
    } = useCardViewFilterBar(pinnedCards, unpinnedDriverCards, unpinnedVehicleCards);

    useEffect(() => {
      resetFilter();
    }, [currentCompanyKey]);

    const [showMessagingPopup, setShowMessagingPopup] = useState(false);
    const [recipient, setRecipient] = useState(null);

    const onMessageVehicle = device => {
      const recipient =
        !!device.vehicleId && device.vehicleId > -1
          ? {
              recipientType: RECIPIENT_TYPE.VEHICLE,
              recipientId: device.vehicleId
            }
          : {
              recipientType: RECIPIENT_TYPE.DEVICE,
              recipientId: device.id || device.deviceStats?.deviceId
            };
      setRecipient(recipient);
      setShowMessagingPopup(true);
      return true;
    };

    const VCards = (vcards, vcardKeyFormater, vcardFormater, vcardSize = CARD_SIZE) => {
      const ITEMS_COUNT = vcards.length;
      const ITEM_MARGIN = 16;
      const ITEM_SIZE = vcardSize + ITEM_MARGIN;
      vcardFormater = vcardFormater || (card => card);
      return (
        <AutoSizer>
          {({ width, height }) => {
            let itemsPerRow = Math.floor((width - ITEM_MARGIN * 2) / ITEM_SIZE);
            let rowCount = Math.ceil(ITEMS_COUNT / itemsPerRow);
            if (!isFinite(rowCount) || rowCount < 0) {
              rowCount = ITEMS_COUNT;
              itemsPerRow = 1;
            }
            return (
              <VList
                className={'showScrollbarsOnHover'}
                width={width}
                height={height}
                rowCount={rowCount}
                rowHeight={ITEM_SIZE}
                rowRenderer={({ index, key, style }) => {
                  const items = [];
                  const fromIndex = index * itemsPerRow;
                  const toIndex = Math.min(fromIndex + itemsPerRow, ITEMS_COUNT);
                  for (let i = fromIndex; i < toIndex; i++) {
                    const card = vcards[i];
                    const shouldHideNonBusiness =
                      nonBusinessCompanyConfig &&
                      card.deviceStats?.attr === CompanyConfigValue.Private;
                    items.push(
                      <SortableCardViewCard
                        key={vcardKeyFormater(i)}
                        index={cards.indexOf(card)}
                        card={vcardFormater(card)}
                        geofences={geofences}
                        collection="cards"
                        isPinned={card.isPinned}
                        showMap={!shouldHideNonBusiness && card.showMap}
                        onPinClick={onPinClick}
                        toggleMapView={toggleMapView}
                        onMessageVehicle={onMessageVehicle}
                        cardKey={vcardKeyFormater(i)}
                        hideNonBusiness={shouldHideNonBusiness}
                      />
                    );
                  }
                  return (
                    <div
                      key={key}
                      style={{
                        ...style,
                        ...{
                          display: 'grid',
                          gridTemplateColumns: `repeat(auto-fill, ${CARD_SIZE}px)`,
                          gridTemplateRows: `repeat(999, ${CARD_SIZE}px)`,
                          columnGap: `${ITEM_MARGIN}px`,
                          padding: 8,
                          justifyContent: 'center'
                        }
                      }}
                    >
                      {items}
                    </div>
                  );
                }}
              />
            );
          }}
        </AutoSizer>
      );
    };
    return (
      <div className={styles.cardView}>
        <header className={styles.cardViewHeader}>
          <span
            style={{
              display: 'flex',
              flex: 'auto'
            }}
          >
            <TabNavLink
              to={`/tracking/cardview/pinned`}
              isActive={(match, location) => {
                return match || location.pathname === '/tracking/cardview';
              }}
            >
              {t(`Tracking.CardViewLens.${CardViewLens.Pinned}`)}
            </TabNavLink>
            <TabNavLink to={`/tracking/cardview/drivers`}>
              {t(`Tracking.CardViewLens.${CardViewLens.Drivers}`)}
            </TabNavLink>
            <TabNavLink to={`/tracking/cardview/vehicles`}>
              {t(`Tracking.CardViewLens.${CardViewLens.Vehicles}`)}
            </TabNavLink>
          </span>

          <span
            style={{
              display: 'flex',
              marginTop: '8px',
              flex: '1 1 auto'
            }}
          >
            <FilterBar {...filterConfig} />
          </span>
        </header>

        <div className={styles.cardViewBody}>
          <Switch>
            <Route
              path={`/tracking/cardview/drivers`}
              render={() => {
                setCurrentTab(CardViewLens.Drivers);
                return isLoading ? (
                  <Loading />
                ) : unpinnedDriverCards?.filter(filterCards).sort(sortCards).length ? (
                  VCards(
                    unpinnedDriverCards.filter(filterCards).sort(sortCards),
                    cardIndex => `driver-${cardIndex}`,
                    card => ({
                      ...card,
                      cardType: CardType.Driver
                    })
                  )
                ) : (
                  <OnboardingCard cardSize={CARD_SIZE} />
                );
              }}
            />

            <Route
              path={`/tracking/cardview/vehicles`}
              render={() => {
                setCurrentTab(CardViewLens.Vehicles);

                return isLoading ? (
                  <Loading />
                ) : unpinnedVehicleCards?.filter(filterCards).sort(sortCards).length ? (
                  VCards(
                    unpinnedVehicleCards.filter(filterCards).sort(sortCards),
                    cardIndex => `vehicle-${cardIndex}`,
                    card => ({
                      ...card,
                      cardType: CardType.Vehicle
                    })
                  )
                ) : (
                  <OnboardingCard cardSize={CARD_SIZE} />
                );
              }}
            />

            <Route
              path={`/tracking/cardview`}
              render={() => {
                setCurrentTab(CardViewLens.Pinned);
                return pinnedCards?.length ? (
                  VCards(
                    pinnedCards.filter(filterCards).sort(sortCards),
                    cardIndex => `pinned-${cardIndex}`
                  )
                ) : isLoading ? (
                  <Loading />
                ) : (
                  <OnboardingCard cardSize={CARD_SIZE} noPins />
                );
              }}
            />
          </Switch>
        </div>
        {showMessagingPopup && (
          <MessageComposeModal
            visible={showMessagingPopup}
            showModal={setShowMessagingPopup}
            recipients={[recipient]}
          />
        )}
      </div>
    );
  }
);
