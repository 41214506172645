import { API_PATH } from 'config';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { parseErrorMessage } from 'utils/strings';

export const redirectToKonetik = async (userKey, dispatch, t) => {
  await fetch(`${API_PATH.replace('v1', 'b2b')}/konetik/sso`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token token="${userKey}"`
    }
  })
    .then(response => {
      if (response.status === 200) {
        response.text().then(textResponse => {
          const parsedResponse = JSON.parse(textResponse);
          const { redirectUri } = parsedResponse;
          if (redirectUri) {
            window.open(redirectUri, '_blank');
          } else {
            dispatch(
              openToast({
                type: ToastType.Error,
                message: t('MixedEnergy.Toasts.FailedSSO')
              })
            );
          }
        });
      } else {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('MixedEnergy.Toasts.FailedSSO')
          })
        );
      }
    })
    .catch(err => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: parseErrorMessage(err)
        })
      );
    });
};

export const setKonetikData = async (userKey, companyId, dispatch, t) => {
  await fetch(`${API_PATH.replace('v1', 'b2b')}/konetik/upload`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token token="${userKey}"`
    },
    body: JSON.stringify({
      companyId
    })
  })
    .then(response => {
      const { status } = response;
      if (status === 202) {
        dispatch(
          openToast({
            type: ToastType.Success,
            message: t('MixedEnergy.Toasts.UpdateKonetikDataSuccess')
          })
        );
      } else {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: t('MixedEnergy.Toasts.UpdateKonetikDataError')
          })
        );
      }
    })
    .catch(() => {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: t('MixedEnergy.Toasts.UpdateKonetikDataError')
        })
      );
    });
};
