/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Company from "./Company";
import User from "./User";

/**
 * The UserRuleset model module.
 * @module model/UserRuleset
 * @version 1.0
 */
class UserRuleset {
  /**
   * Constructs a new <code>UserRuleset</code>.
   * @alias module:model/UserRuleset
   */
  constructor() {
    UserRuleset.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>UserRuleset</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/UserRuleset} obj Optional instance to populate.
   * @return {module:model/UserRuleset} The populated <code>UserRuleset</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new UserRuleset();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("company")) {
        obj["company"] = Company.constructFromObject(data["company"]);
      }
      if (data.hasOwnProperty("user")) {
        obj["user"] = User.constructFromObject(data["user"]);
      }
      if (data.hasOwnProperty("ruleset")) {
        obj["ruleset"] = ApiClient.convertToType(data["ruleset"], "String");
      }
      if (data.hasOwnProperty("enabledAt")) {
        obj["enabledAt"] = ApiClient.convertToType(data["enabledAt"], "Date");
      }
      if (data.hasOwnProperty("expiresAt")) {
        obj["expiresAt"] = ApiClient.convertToType(data["expiresAt"], "String");
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
UserRuleset.prototype["id"] = undefined;

/**
 * @member {module:model/Company} company
 */
UserRuleset.prototype["company"] = undefined;

/**
 * @member {module:model/User} user
 */
UserRuleset.prototype["user"] = undefined;

/**
 * @member {String} ruleset
 */
UserRuleset.prototype["ruleset"] = undefined;

/**
 * @member {Date} enabledAt
 */
UserRuleset.prototype["enabledAt"] = undefined;

/**
 * @member {String} expiresAt
 */
UserRuleset.prototype["expiresAt"] = undefined;

export default UserRuleset;
