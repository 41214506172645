export const ENV = Object.freeze({
  TEST_ANZ: 'https://tst.api-au.telematics.com/v1',
  QA_ANZ: 'https://qa.api-au.telematics.com/v1',
  QA_INTERNAL_ANZ: 'https://qa-internal.api-au.telematics.com/v1',
  STAGING_ANZ: 'https://stg.api-au.telematics.com/v1',
  PROD_ANZ: 'https://api-au.telematics.com/v1',
  PROD_UK: 'https://api-uk.telematics.com/v1',
  PROD_US: 'https://api-us.telematics.com/v1',
  PROD_MX: 'https://api-mx.telematics.com/v1'
});

export const NON_PROD = [ENV.QA_ANZ, ENV.QA_INTERNAL_ANZ, ENV.STAGING_ANZ, ENV.TEST_ANZ];
export const PROD = [ENV.PROD_ANZ, ENV.PROD_UK, ENV.PROD_US, ENV.PROD_MX];

export const FeatureFlag = Object.freeze({
  npiExample: {
    flag: 'npiExample',
    allow: [ENV.TEST_ANZ, ENV.QA_ANZ, ENV.STAGING_ANZ],
    useNPI: true
  },
  changeLanguage: {
    flag: 'changeLanguage',
    allow: NON_PROD,
    useNPI: false,
    useSA: false
  },
  driverRestriction: {
    flag: 'driverRestriction',
    allow: NON_PROD,
    useNPI: true
  },
  bulkImportLocationsGeofences: {
    flag: 'bulkImportLocationsGeofences',
    useNPI: true
  },
  storeMenuItem: {
    flag: 'storeMenuItem',
    allow: [...NON_PROD, ENV.PROD_US, ENV.PROD_UK],
    useNPI: false
  },
  insightsQSConsole: {
    flag: 'insightsQSConsole',
    useSA: true
  },
  insightsUpdatedToolbarMask: {
    flag: 'insightsUpdatedToolbarMask',
    allow: NON_PROD,
    useSA: true
  },
  TCAEnrolments: {
    flag: 'TCAEnrolments',
    allow: [...NON_PROD, ENV.PROD_ANZ]
  },
  ssoGuard: {
    flag: 'ssoGuard',
    allow: NON_PROD
  },
  pretripChecklistPhotoOnPass: {
    flag: 'pretripChecklistPhotoOnPass'
  },
  vehMaintenanceInpspections: {
    flag: 'vehMaintenanceInpspections'
  },
  dvir_inspection: {
    flag: 'dvir_inspection'
  },
  npi: {
    flag: 'npi',
    useNPI: true
  },
  churnzero: {
    flag: 'churnzero',
    allow: [ENV.QA_ANZ]
  },
  includeRulesetPreRelease: {
    flag: 'includeRulesetPreRelease',
    allow: NON_PROD,
    useNPI: true
  },
  bulkImportCountryStateHint: {
    flag: 'bulkImportCountryStateHint',
    allow: NON_PROD,
    useNPI: true
  },
  startOfDay: {
    flag: 'startOfDay',
    allow: NON_PROD,
    useNPI: true
  },
  newVehicleMaintenanceForm: {
    flag: 'newVehicleMaintenanceForm',
    allow: [ENV.TEST_ANZ],
    useNPI: true
  },
  eldAlertWarnings: {
    flag: 'eldAlertWarnings',
    allow: [],
    useNPI: true
  },
  driverPortal: {
    flag: 'driverPortal',
    allow: [ENV.STAGING_ANZ, ENV.PROD_US]
  },
  driverPortalTripManager: {
    flag: 'driverPortalTripManager',
    allow: [ENV.STAGING_ANZ, ENV.TEST_ANZ] //, ENV.PROD_US, ENV.PROD_ANZ]
  },
  trackingLastUpdateColumn: {
    flag: 'trackingLastUpdateColumn',
    allow: NON_PROD,
    useNPI: true
  },
  generateTCAPDF: {
    flag: 'generateTCAPDF',
    useSA: true
  },
  iqCameraHealth: {
    flag: 'iqCameraHealth',
    allow: NON_PROD,
    useNPI: true
  },
  trackingPublicPage: {
    flag: 'trackingPublicPage',
    useSA: true
  },
  logOnEventsConfiguration: {
    flag: 'logOnEventsConfiguration',
    allow: []
  },
  dvirInspection: {
    flag: 'dvirInspection',
    allow: [...NON_PROD, ENV.PROD_US]
  },
  easydocsDevices: {
    flag: 'easydocsDevices',
    allow: NON_PROD,
    useNPI: true
  },
  fbtBulkAssignment: {
    flag: 'fbtBulkAssignment',
    useNPI: true,
    allow: [ENV.STAGING_ANZ, ENV.TEST_ANZ] //, ENV.PROD_US, ENV.PROD_ANZ]
  },
  inspectionStatusGrid: {
    flag: 'inspectionStatusGrid',
    allow: NON_PROD,
    useNPI: true
  },
  sapEquipmentId: {
    flag: 'sapEquipmentId',
    allow: NON_PROD,
    useNPI: true
  },
  vt202Output: {
    flag: 'vt202Output',
    allow: NON_PROD
  },
  evoPhase1: {
    flag: 'evoPhase1',
    allow: NON_PROD,
    useNPI: true
  },
  evoPhaseTGE: {
    flag: 'evoPhaseTGE',
    allow: [ENV.PROD_ANZ],
    allowCompanies: {
      [ENV.PROD_ANZ]: {
        allowCompanyIds: [489624, 432639],
        allowSubCompanies: true
      }
    }
  },
  trackingEnhancement: {
    flag: 'trackingEnhancement',
    useNPI: true
  },
  potentialCrashEvent: {
    flag: 'potentialCrashEvent',
    allow: NON_PROD,
    useNPI: true
  },
  pbsField: {
    flag: 'pbsField',
    allow: NON_PROD
  },
  fbtMultipleCalls: {
    flag: 'fbtMultipleCalls'
  },
  installationAuditLog: {
    flag: 'installationAuditLog',
    allow: NON_PROD,
    useNPI: true
  },
  deviceConfig_speedAssist: {
    flag: 'deviceConfig_speedAssist',
    allow: NON_PROD,
    useNPI: true
  },
  subCompanyManagement: {
    flag: 'subCompanyManagement',
    allow: NON_PROD,
    useNPI: true
  },
  autoGeneratedTemplate: {
    flag: 'autoGeneratedTemplate',
    allow: NON_PROD,
    useNPI: true
  },
  driverIdPage: {
    flag: 'driveridpage',
    useNPI: true
  },
  idleBuzzer: {
    flag: 'idleBuzzer',
    allow: NON_PROD,
    useNPI: true
  }
});
