export const ENTITIES = {
  device: 'device',
  vehicle: 'vehicle',
  truck: 'truck'
};

export const ASSET_STATUS = {
  out: 'outofcoverage',
  on: 'ignitionon',
  off: 'ignitionoff'
};

export const IGNITION_STATUS = {
  ON: 'ON'
};

export const HEADINGS = {
  0: 'north',
  45: 'north-east',
  90: 'east',
  135: 'south-east',
  180: 'south',
  225: 'south-west',
  270: 'west',
  315: 'north-west',
  360: 'north'
};

export const DEFAULT_VEHICLE_MARKER = 'asset/ignitionon/north/truck.png';

export const DEFAULT_DIRECTION = 'north';

export const MARKER_TYPE = {
  asset: 'asset',
  arrow: 'arrow'
};

export const MARKER_COLOR_TYPE = {
  status: 'status',
  custom: 'custom'
};

export const MARKER_SIZE = {
  small: 'small',
  large: 'large'
};

export const MARKER_COLOR = {
  '#09c325': 'green',
  '#f02731': 'red',
  '#ffa100': 'orange',
  '#e5c306': 'yellow',
  '#0d79de': 'blue',
  '#914fcf': 'purple',
  '#0d79de': 'ignitionoff',
  '#09c325': 'ignitionon',
  '#9fa9b7': 'outofcoverage'
  // '#0380c8': 'light blue'
  // '#dadee3': 'dark gray',
  // '#757474': 'light gray',
  // '#000000': 'black',
};
