/* global google */
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';

//components
import { LoadingTable } from 'components/grid/LoadingTable';
import Map, { MapMode } from 'components/map/Map';
import ViewHeaderWrapper from 'components/view-header-wrapper/ViewHeaderWrapper';
import InfoRow from 'components/form/info-row/InfoRow';
import InfoTable from 'components/form/info-table/InfoTable';
import { FleetsTable } from './FleetsTable';
import { ActiveTimePeriodRow } from './ActiveTimePeriodRow';
import { ToastType } from 'components/notifications/toasts/Toast';

//slices
import {
  useGeofences,
  deleteGeofenceApi,
  useIsFullyFetched,
  useIsFetching as useIsFetchingGeofences
} from 'features/geofences/geofencesSlice';
import {
  useCompanies,
  useCompanyGeofenceProviders,
  useRedirectToMainFeaturePageOnCompanyChange
} from 'features/company/companySlice';
import { useLocations } from 'features/locations/locationsSlice';
import { useInspectionChecklists } from 'features/inspectionChecklist/inspectionChecklistSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useDocuments } from 'features/easydocs/documentsSlice';

//methods & helpers
import { helpers, getGeofenceShape, toActivePeriodFormField } from './helpers';
import { format } from 'utils/dates';
import { getRoundValue } from 'utils/methods';
import { LocalizationUtil } from 'features/localization/localization';
import { getSelectedEventTypeNames } from '../../Scorecard/helpers';
import { useCan, Can, services } from 'features/permissions';
import { openToast } from 'features/toasts/toastsSlice';

//styles
import './Geofences.scss';
import styles from '../Vehicles/Vehicles.module.scss';
import { Paths } from './constants';
import { AUDIT_ENTITY } from 'components/auditsTable/constants';

export const GeofenceView = () => {
  const { t } = useTranslation();
  const can = useCan();
  const indexBeginingId = window.location.pathname.lastIndexOf('/');
  const id = window.location.pathname.substr(
    indexBeginingId + 1,
    window.location.pathname.length - 1
  );
  const history = useHistory();
  const isFetching = useSelector(state => state.fleets.meta.isFetching);
  const isFetchingGeofences = useIsFetchingGeofences();
  const isFullyFetched = useIsFullyFetched();
  const geofences = useGeofences();
  const dispatch = useDispatch();
  const [geofenceData, setGeofenceData] = useState({});
  const [geofenceSpeedData, setGeofenceSpeedData] = useState({});
  const [geofenceIgnitionData, setGeofenceIgnitionData] = useState();
  const [geofenceEntryData, setGeofenceEntryData] = useState();
  const localization = useLocalization();
  const geofenceProviders = useCompanyGeofenceProviders();

  useRedirectToMainFeaturePageOnCompanyChange('/settings/geofences');

  const handleError = useCallback(
    err => {
      if (err) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: err
          })
        );
      }
      if (history.location.pathname !== Paths.Default) {
        history.replace(Paths.Default);
      }
    },
    [history, dispatch]
  );

  const geofenceSpeedDataColumns = [
    {
      label: t('GeofencesFeature.SpeedLimit'),
      width: 180,
      cellDataGetter: ({ rowData }) =>
        (rowData.threshold || rowData.threshold === 0) && rowData.threshold !== 'disabled'
          ? `${
              localization.formats.speed.unit_per_hour === 'mph'
                ? getRoundValue(LocalizationUtil.kmtomile(parseFloat(rowData.threshold)))
                : rowData.threshold
            } ${localization.formats.speed.unit_per_hour}`
          : t('GeofencesFeature.View.Disabled').toLowerCase()
    },
    {
      label: t('GeofencesFeature.Tolerance'),
      width: 130,
      cellDataGetter: ({ rowData }) =>
        (rowData.offset || rowData.offset === 0) && rowData.offset !== 'disabled'
          ? `${
              localization.formats.speed.unit_per_hour === 'mph'
                ? getRoundValue(LocalizationUtil.kmtomile(parseFloat(rowData.offset)))
                : rowData.offset
            } ${localization.formats.speed.unit_per_hour}`
          : t('GeofencesFeature.View.Disabled').toLowerCase()
    },
    {
      label: t('GeofencesFeature.Duration'),
      width: 130,
      cellDataGetter: ({ rowData }) =>
        (rowData.duration || rowData.duration === 0) && rowData.duration !== 'disabled'
          ? `${rowData.duration} seconds`
          : t('GeofencesFeature.View.Disabled').toLowerCase()
    },
    {
      label: `${t('GeofencesFeature.View.SpeedAssist')}?`,
      width: 160,
      cellDataGetter: ({ rowData }) => `${t(`Common.${rowData.speedAssist}`)}`
    }
  ];

  // Get the company by the id
  const companies = useCompanies();
  let company;
  if (geofenceData.companyId) {
    company = companies.find(comp => comp.id === geofenceData.companyId);
  }

  // Get the location by the id
  const locations = useLocations();
  let location;
  if (geofenceData.location && geofenceData.location.id) {
    location = locations.find(loc => loc.id === geofenceData.location.id);
  }

  // Get the pretripchecklist and document by the id
  const pretripChecklist = useInspectionChecklists();
  const documents = useDocuments();
  let pretripGeofenceEntry;
  let pretripIgnition;
  let documentGeofenceEntry;

  if (geofenceData.driverNotification) {
    const driverNotificationArray = JSON.parse(geofenceData.driverNotification);
    const driverGeofenceEntry = driverNotificationArray.find(
      ({ TriggerEvent }) => TriggerEvent === 'GEO-EN'
    );
    const driverIgnition = driverNotificationArray.find(
      ({ TriggerEvent }) => TriggerEvent === 'IOR'
    );
    if (driverGeofenceEntry) {
      pretripGeofenceEntry = pretripChecklist.find(
        pre => pre.id === parseInt(driverGeofenceEntry.Ptc && driverGeofenceEntry.Ptc.Id)
      );
      documentGeofenceEntry = documents.find(
        document => document.id === parseInt(driverGeofenceEntry.Document?.Id)
      );
    }
    if (driverIgnition) {
      pretripIgnition = pretripChecklist.find(
        pre => pre.id === parseInt(driverIgnition.Ptc && driverIgnition.Ptc.Id)
      );
    }
  }

  useEffect(() => {
    dispatch(setBackButton(true));
  }, [dispatch]);

  useEffect(() => {
    const parsedId = parseInt(id, 10);
    if (parsedId <= 0 || isNaN(parsedId)) {
      handleError(t('Common.Invalid Request ID'));
      return;
    }

    if (!isFetchingGeofences && isFullyFetched) {
      const geofence = geofences.find(g => parseInt(g.id, 10) === parsedId);
      if (!geofence) {
        handleError(t('Common.Invalid Request ID'));
      } else {
        setGeofenceData({ ...geofence });
      }
    }
  }, [t, geofences, id, isFetchingGeofences, isFullyFetched]);

  useEffect(() => {
    dispatch(setPageTitle(geofenceData.name && `${geofenceData.name}`));
  }, [geofenceData, dispatch]);

  useEffect(() => {
    const geofenceSpeed = {};
    geofenceSpeed.threshold =
      geofenceData.thresholdSpeed !== -1 ? geofenceData.thresholdSpeed : 'disabled';
    geofenceSpeed.offset = geofenceData.offsetSpeed !== -1 ? geofenceData.offsetSpeed : 'disabled';
    geofenceSpeed.duration =
      geofenceData.thresholdDurationSpeed !== -1 ? geofenceData.thresholdDurationSpeed : 'disabled';
    geofenceSpeed.speedAssist = geofenceData.speedAssist ? 'Yes' : 'No';

    setGeofenceSpeedData([{ ...geofenceSpeed }]);

    let geofenceNotification;
    if (geofenceData.driverNotification) {
      geofenceNotification = JSON.parse(geofenceData.driverNotification);
      setGeofenceIgnitionData(
        geofenceNotification.find(({ TriggerEvent }) => TriggerEvent === 'IOR')
      );
      const geofenceEntryData = geofenceNotification.find(
        ({ TriggerEvent }) => TriggerEvent === 'GEO-EN'
      );
      if (geofenceEntryData && Object.keys(geofenceEntryData).length > 1) {
        geofenceEntryData.timeFrom = geofenceEntryData?.ActivePeriods?.All?.[0]
          ? geofenceEntryData.ActivePeriods.All[0]
          : '';
        geofenceEntryData.timeTo = geofenceEntryData?.ActivePeriods?.All?.[1]
          ? geofenceEntryData.ActivePeriods.All[1]
          : '';
      }
      setGeofenceEntryData(geofenceEntryData);
    } else {
      setGeofenceIgnitionData(null);
      setGeofenceEntryData(null);
    }
  }, [geofenceData]);

  if (isFetching || isFetchingGeofences || Object.keys(geofenceData).length === 0)
    return <LoadingTable columnSizes={[91, 60, 60, 68, 63, 49]} />;

  const handleButtonAction = action => () => {
    switch (action) {
      case 'delete':
        dispatch(deleteGeofenceApi(geofenceData, history));
        break;
      default:
        break;
    }
  };

  // Can only view and edit excluded scorecard events with proper permissions
  const canViewScorecardItems = helpers.getCanViewScorecardItems(can);

  const scorecardEvents = getSelectedEventTypeNames(geofenceData?.features, localization, t);

  return (
    <React.Fragment>
      <ViewHeaderWrapper
        data={{ entityName: AUDIT_ENTITY.GEOFENCE, ...geofenceData }}
        editPath={`${Paths.EDIT_GEOFENCE}/id/${id}`}
        auditPath={`${Paths.AUDIT_GEOFENCE}/id/${id}`}
        canUse="GEOFENCE"
        handleButtonAction={handleButtonAction}
        typeOfEntityToDelete={t('Common.geofence')}
        disableEdit={geofenceProviders?.includes(geofenceData.type)}
        disableDelete={geofenceProviders?.includes(geofenceData.type)}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '32px',
          color: '#181c21'
        }}
        className="vehicleView"
      >
        <Row>
          <Col>
            <InfoRow
              label={`${t('Common.Company')}:`}
              value={company ? company.name : ''}
              styles={styles}
            />
            <InfoRow
              label={`${t('VehicleMaintenanceTypes.Type')}:`}
              value={
                geofenceData.type
                  ? geofenceData.type.charAt(0).toUpperCase() +
                    geofenceData.type.slice(1).toLowerCase()
                  : ''
              }
              styles={styles}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '15px 0 0 0' }}>
              <label style={{ display: 'flex', marginRight: '40px', justifyContent: 'center' }}>
                {`${t('Locations.Location')}:`}
              </label>
              <Link to={`/settings/locations/id/${location && location.id}`}>
                {location && location.name}
              </Link>
            </div>
          </Col>
          <Col>
            <InfoRow
              label={`${t('GeofencesFeature.Area')}:`}
              value={
                geofenceData && geofenceData.area_sqm
                  ? `${localization.convertArea(geofenceData.area_sqm)} ${
                      localization.formats.area.unit
                    }`
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={`${t('GeofencesFeature.View.Shape')}:`}
              value={getGeofenceShape(geofenceData)}
              styles={styles}
            />
          </Col>
          <Col>
            <InfoRow
              label={`${t('Common.CreatedAt')}:`}
              value={
                geofenceData && geofenceData.createdAt
                  ? format(
                      new Date(geofenceData.createdAt),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
            <InfoRow
              label={`${t('Common.UpdatedAt')}:`}
              value={
                geofenceData && geofenceData.updatedAt
                  ? format(
                      new Date(geofenceData.updatedAt),
                      localization.formats.time.formats.dby_imp
                    )
                  : ''
              }
              styles={styles}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 style={{ marginTop: '3rem' }}>{t('GeofencesFeature.View.AssociatedFleets')}</h5>
            <FleetsTable fleets={geofenceData.fleets || []} />
          </Col>
        </Row>
        <Row>
          <Col lg={7}>
            <h5 style={{ marginTop: '3rem' }}>{t('GeofencesFeature.Form.EventParameters')}</h5>
            <ActiveTimePeriodRow
              title={t('GeofencesFeature.ActiveTimesTableTitle')}
              period={toActivePeriodFormField(geofenceData).activeTime.period}
            />
            <InfoRow
              label={t('GeofencesFeature.Speed')}
              value={
                <InfoTable
                  data={geofenceSpeedData || []}
                  columns={geofenceSpeedDataColumns}
                  styles={styles}
                />
              }
              styles={styles}
              sxValue={{
                width: '95%',
                minHeight: `${geofenceSpeedData ? geofenceSpeedData.length * 64 + 32 : 0}px`,
                padding: '0'
              }}
            />
            <InfoRow
              label={t('GeofencesFeature.Undertime')}
              value={
                geofenceData.thresholdUndertime && geofenceData.thresholdUndertime !== -1
                  ? `${geofenceData.thresholdUndertime / 60} ${t(
                      'GeofencesFeature.View.Minutes'
                    ).toLowerCase()}`
                  : t('GeofencesFeature.View.Disabled').toLowerCase()
              }
              styles={styles}
            />
            <InfoRow
              label={t('GeofencesFeature.Overtime')}
              value={
                geofenceData.thresholdOvertime && geofenceData.thresholdOvertime !== -1
                  ? `${geofenceData.thresholdOvertime / 60} ${t(
                      'GeofencesFeature.View.Minutes'
                    ).toLowerCase()}`
                  : t('GeofencesFeature.View.Disabled').toLowerCase()
              }
              styles={styles}
            />
            {geofenceIgnitionData && Object.keys(geofenceIgnitionData).length > 1 && (
              <InfoRow
                label={t('GeofencesFeature.IgnitionOn')}
                value={
                  <>
                    <InfoRow
                      label={t('GeofencesFeature.Form.PreTripChecklist')}
                      value={
                        <Link
                          to={`/settings/inspectionChecklist/id/${pretripIgnition &&
                            pretripIgnition.id}`}
                        >
                          {pretripIgnition && pretripIgnition.name}
                        </Link>
                      }
                      styles={styles}
                    />
                    <InfoRow
                      label={t('GeofencesFeature.View.Message')}
                      value={geofenceIgnitionData.Message}
                      styles={styles}
                    />
                  </>
                }
                styles={styles}
                sxValue={{ width: '100%', padding: '0 0 0 25px' }}
              />
            )}
            {geofenceEntryData && Object.keys(geofenceEntryData).length > 1 && (
              <InfoRow
                label={t('GeofencesFeature.GeofenceEntry')}
                value={
                  <>
                    <InfoRow
                      label={t('GeofencesFeature.Form.PreTripChecklist')}
                      value={
                        <Link
                          to={`/settings/inspectionChecklist/id/${pretripGeofenceEntry &&
                            pretripGeofenceEntry.id}`}
                        >
                          {pretripGeofenceEntry && pretripGeofenceEntry.name}
                        </Link>
                      }
                      styles={styles}
                    />
                    <InfoRow
                      label={t('Easydocs.Document')}
                      value={
                        <Link to={`/easydocs/documents/id/${documentGeofenceEntry?.id}`}>
                          {documentGeofenceEntry?.name}
                        </Link>
                      }
                      styles={styles}
                    />
                    <InfoRow
                      label={t('GeofencesFeature.View.Message')}
                      value={geofenceEntryData.Message}
                      styles={styles}
                    />
                    {geofenceEntryData.Mode === 'ACK' && (
                      <InfoRow
                        label={t('GeofencesFeature.Mode')}
                        value={t('GeofencesFeature.Acknowledge')}
                        styles={styles}
                      />
                    )}
                    {geofenceEntryData.Mode === 'DISMISS' && (
                      <InfoRow
                        label={t('GeofencesFeature.Mode')}
                        value={t('GeofencesFeature.Dismiss')}
                        styles={styles}
                      />
                    )}
                    {geofenceEntryData.timeFrom && geofenceEntryData.timeTo && (
                      <InfoRow
                        label={t('GeofencesFeature.View.ActivePeriods')}
                        value={`${t('GeofencesFeature.Daily')} ${t(
                          'GeofencesFeature.From'
                        ).toLowerCase()} ${moment(geofenceEntryData.timeFrom, 'hh:mm a').format(
                          'hh:mm a'
                        )} ${t('GeofencesFeature.To').toLowerCase()} ${moment(
                          geofenceEntryData.timeTo,
                          'hh:mm a'
                        ).format('hh:mm a')}`}
                        styles={styles}
                      />
                    )}
                  </>
                }
                styles={styles}
                sxValue={{ width: '100%', padding: '0 0 0 25px' }}
              />
            )}
          </Col>
          {canViewScorecardItems && scorecardEvents?.length > 0 && (
            <Col lg={5}>
              <h5 style={{ marginTop: '3rem' }}>{t('Scorecard.ExcludedScorecardEvents')}</h5>
              {scorecardEvents.map(scorecardEvent => (
                <InfoRow key={scorecardEvent} label={scorecardEvent} />
              ))}
            </Col>
          )}
        </Row>

        <h5 style={{ marginTop: '3rem' }}>{t('Preferences.MapSettings.Map.Title')}</h5>
        <Row style={{ height: '500px', margin: '0 0 20px 0' }}>
          <Map
            mode={MapMode.Geofence}
            mapOptions={{
              mapTypeControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT }
            }}
            geofences={[geofenceData]}
            filterGeofencesByType={false}
            containerElement={<div style={{ height: `100%`, width: `100%` }} />}
            mapElement={<div style={{ height: `100%`, width: `100%` }} />}
          />
        </Row>
      </div>
    </React.Fragment>
  );
};
