import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useUserKey } from 'features/user/userSlice';

const PrivateRoute = ({ render, loginPath = '/login', ...props }) => {
  const userKey = useUserKey();

  return (
    <Route
      {...props}
      render={routeProps =>
        userKey?.length ? (
          render(routeProps)
        ) : (
          <Redirect
            to={{
              pathname: loginPath,
              state: { from: routeProps.location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
