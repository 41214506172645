/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import ItemAttribute from "./ItemAttribute";

/**
 * The Item model module.
 * @module model/Item
 * @version 1.0
 */
class Item {
  /**
   * Constructs a new <code>Item</code>.
   * @alias module:model/Item
   */
  constructor() {
    Item.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Item</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Item} obj Optional instance to populate.
   * @return {module:model/Item} The populated <code>Item</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Item();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("version")) {
        obj["version"] = ApiClient.convertToType(data["version"], "Number");
      }
      if (data.hasOwnProperty("companyId")) {
        obj["companyId"] = ApiClient.convertToType(data["companyId"], "Number");
      }
      if (data.hasOwnProperty("itemId")) {
        obj["itemId"] = ApiClient.convertToType(data["itemId"], "String");
      }
      if (data.hasOwnProperty("itemIdReverse")) {
        obj["itemIdReverse"] = ApiClient.convertToType(
          data["itemIdReverse"],
          "String"
        );
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = ApiClient.convertToType(data["type"], "String");
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("quantity")) {
        obj["quantity"] = ApiClient.convertToType(data["quantity"], "Number");
      }
      if (data.hasOwnProperty("commodity")) {
        obj["commodity"] = ApiClient.convertToType(data["commodity"], "String");
      }
      if (data.hasOwnProperty("description")) {
        obj["description"] = ApiClient.convertToType(
          data["description"],
          "String"
        );
      }
      if (data.hasOwnProperty("adg7Code")) {
        obj["adg7Code"] = ApiClient.convertToType(data["adg7Code"], "String");
      }
      if (data.hasOwnProperty("cubics")) {
        obj["cubics"] = ApiClient.convertToType(data["cubics"], "Number");
      }
      if (data.hasOwnProperty("cubicsUom")) {
        obj["cubicsUom"] = ApiClient.convertToType(data["cubicsUom"], "String");
      }
      if (data.hasOwnProperty("weight")) {
        obj["weight"] = ApiClient.convertToType(data["weight"], "Number");
      }
      if (data.hasOwnProperty("weightUom")) {
        obj["weightUom"] = ApiClient.convertToType(data["weightUom"], "String");
      }
      if (data.hasOwnProperty("temperature")) {
        obj["temperature"] = ApiClient.convertToType(
          data["temperature"],
          "Number"
        );
      }
      if (data.hasOwnProperty("temperatureUom")) {
        obj["temperatureUom"] = ApiClient.convertToType(
          data["temperatureUom"],
          "String"
        );
      }
      if (data.hasOwnProperty("time")) {
        obj["time"] = ApiClient.convertToType(data["time"], "Number");
      }
      if (data.hasOwnProperty("timeUom")) {
        obj["timeUom"] = ApiClient.convertToType(data["timeUom"], "String");
      }
      if (data.hasOwnProperty("externalReference")) {
        obj["externalReference"] = ApiClient.convertToType(
          data["externalReference"],
          "String"
        );
      }
      if (data.hasOwnProperty("attributes")) {
        obj["attributes"] = ApiClient.convertToType(data["attributes"], [
          ItemAttribute
        ]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Item.prototype["id"] = undefined;

/**
 * @member {Number} version
 */
Item.prototype["version"] = undefined;

/**
 * @member {Number} companyId
 */
Item.prototype["companyId"] = undefined;

/**
 * @member {String} itemId
 */
Item.prototype["itemId"] = undefined;

/**
 * @member {String} itemIdReverse
 */
Item.prototype["itemIdReverse"] = undefined;

/**
 * @member {String} type
 */
Item.prototype["type"] = undefined;

/**
 * @member {module:model/Item.StatusEnum} status
 */
Item.prototype["status"] = undefined;

/**
 * @member {Number} quantity
 */
Item.prototype["quantity"] = undefined;

/**
 * @member {String} commodity
 */
Item.prototype["commodity"] = undefined;

/**
 * @member {String} description
 */
Item.prototype["description"] = undefined;

/**
 * @member {String} adg7Code
 */
Item.prototype["adg7Code"] = undefined;

/**
 * @member {Number} cubics
 */
Item.prototype["cubics"] = undefined;

/**
 * @member {String} cubicsUom
 */
Item.prototype["cubicsUom"] = undefined;

/**
 * @member {Number} weight
 */
Item.prototype["weight"] = undefined;

/**
 * @member {String} weightUom
 */
Item.prototype["weightUom"] = undefined;

/**
 * @member {Number} temperature
 */
Item.prototype["temperature"] = undefined;

/**
 * @member {String} temperatureUom
 */
Item.prototype["temperatureUom"] = undefined;

/**
 * @member {Number} time
 */
Item.prototype["time"] = undefined;

/**
 * @member {String} timeUom
 */
Item.prototype["timeUom"] = undefined;

/**
 * @member {String} externalReference
 */
Item.prototype["externalReference"] = undefined;

/**
 * @member {Array.<module:model/ItemAttribute>} attributes
 */
Item.prototype["attributes"] = undefined;

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Item["StatusEnum"] = {
  /**
   * value: "CREATED"
   * @const
   */
  CREATED: "CREATED",

  /**
   * value: "LOADED"
   * @const
   */
  LOADED: "LOADED",

  /**
   * value: "DELIVERED"
   * @const
   */
  DELIVERED: "DELIVERED",

  /**
   * value: "DAMAGED"
   * @const
   */
  DAMAGED: "DAMAGED",

  /**
   * value: "CHECKED"
   * @const
   */
  CHECKED: "CHECKED"
};

export default Item;
