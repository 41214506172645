import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Alert,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import styles from './ResetPassword.module.scss';
import { LoginLogo } from './LoginLogo';
import { useState } from 'react';
import { AuthApi, ApiClient } from 'nextgen_api';
import { API_PATH } from 'config';
import { useTranslation } from 'react-i18next';
import { BUTTON_IDS } from 'utils/globalConstants';

const ResetPasswordStatus = {
  Init: 0,
  Succeeded: 1,
  Failed: 2
};

const ResetPasswordForm = props => {
  const [username, setUsername] = useState('');
  const { t } = useTranslation();

  const validateForm = () => {
    return username != null && username.length >= 4;
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (validateForm()) {
      props.onSubmit(username);
    }
  };

  return (
    <div className={styles.resetPassword}>
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username">
          <FormLabel>{t('Username')}</FormLabel>
          <FormControl
            autoFocus
            type="username"
            value={username}
            onChange={event => setUsername(event.target.value)}
            autoComplete="off"
            tabIndex={1}
          />
        </FormGroup>
        <OverlayTrigger
          placement="top"
          trigger={['hover', 'focus']}
          overlay={
            <Tooltip id={`tooltip-top`}>
              {validateForm()
                ? t('Login.ResetPassword.ResetYourPassword')
                : t('Login.ResetPassword.InputYourUserName')}
            </Tooltip>
          }
        >
          <Button block type="submit" tabIndex={2} id={BUTTON_IDS.resetPassword}>
            {t('Login.ResetPassword.ResetPassword')}
          </Button>
        </OverlayTrigger>

        <div className={styles.back}>
          <span>{t('Login.ResetPassword.BackTo')} </span>
          <Link to="/login">{t('Sign In').toLowerCase()}</Link>
        </div>
      </form>
    </div>
  );
};

const ResetPassword = props => {
  const [resetStatus, setResetStatus] = useState(ResetPasswordStatus.Init);
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    username => {
      let apiClient = new ApiClient();
      apiClient.basePath = API_PATH;
      const authApi = new AuthApi(apiClient);
      authApi.generateResetPasswordToken(
        {
          body: {
            username: username
          }
        },
        (err, data, resp) => {
          if (err) {
            setResetStatus(ResetPasswordStatus.Failed);
          } else {
            setResetStatus(ResetPasswordStatus.Succeeded);
          }
        }
      );
    },
    [t]
  );

  function getTitle() {
    return t('Login.ResetPassword.ResetPassword');
  }
  return (
    <div className={styles.commonContainer}>
      <LoginLogo />
      <p>{getTitle()}</p>
      <div className={styles.form}>
        {resetStatus === ResetPasswordStatus.Init && <ResetPasswordForm onSubmit={handleSubmit} />}
        {resetStatus !== ResetPasswordStatus.Init && (
          <div className={styles.successInfo}>
            <Alert variant="info">
              <label>{t('Login.ResetPassword.WarnNotification')}</label>
            </Alert>
            <div className={styles.back}>
              <span>{t('Login.ResetPassword.BackTo')} </span>
              <Link to="/login">{t('Sign In').toLowerCase()}</Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
