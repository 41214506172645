import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { api } from 'utils/api';
import moment from 'moment';
import { useUserKey } from 'features/user/userSlice';
import { useCallback, useMemo } from 'react';
import { useCurrentCompanyKey } from 'features/company/companySlice';

const devices = {
  devices: {},
  meta: {
    lastFetched: null,
    isFetching: false,
    error: null,
    companyKey: null
  },
  devicesMetersMeta: {}
};

function startLoading(state) {
  state.meta.isFetching = true;
}

function loadingFailed(state, action) {
  state.meta.isFetching = false;
  state.meta.lastFetched = 'now';
  state.meta.error = action.payload.err;
  if (!state.devices) {
    state.devices = {};
  }
}

const devicesMetersSlice = createSlice({
  name: 'devicesMeters',
  initialState: devices,
  reducers: {
    fetchDevicesStart: startLoading,
    fetchDevicesSuccess(state, { payload }) {
      state.devices[payload?.id] = payload.devices;
      state.meta.isFetching = false;
      state.meta.lastFetched = 'now';
      state.meta.error = null;
    },
    fetchDevicesFailure: loadingFailed,
    fetchDevicesMetersStart(state, { payload }) {
      state.devicesMetersMeta[payload?.id] = {
        isFetching: true,
        lastFetched: null,
        error: null
      };
    },
    fetchDevicesMetersSuccess(state, { payload }) {
      state.devicesMetersMeta[payload?.id] = {
        isFetching: false,
        lastFetched: moment().format(),
        error: null
      };
      state.devices[payload?.id] = payload.devices;
      state.meta.companyKey = payload.companyKey;
    },
    fetchDevicesMetersFailure(state, { payload }) {
      state.devicesMetersMeta[payload?.id] = {
        isFetching: false,
        lastFetched: moment().format(),
        error: payload.error
      };
      state.devices[payload?.id] = [];
      state.meta.companyKey = payload.companyKey;
    },
    resetDevicesMeters(state) {
      state.devicesMetersMeta = {};
      state.devices = {};
    }
  }
});

const {
  fetchDevicesStart,
  fetchDevicesSuccess,
  fetchDevicesFailure,
  resetDevicesMeters,
  fetchDevicesMetersStart,
  fetchDevicesMetersSuccess,
  fetchDevicesMetersFailure
} = devicesMetersSlice.actions;

export const fetchDeviceMeters = (id, userKey) => async dispatch => {
  try {
    if (!userKey) {
      return;
    }
    dispatch(fetchDevicesStart());

    const res = await api.get(`/devices/${id}/meters`, { authKey: userKey });
    if (res.status === 200) {
      dispatch(fetchDevicesSuccess({ devices: res.body, id }));
    } else {
      console.log(res);
    }
  } catch (err) {
    dispatch(fetchDevicesFailure({ err: err.toString() }));
  }
};

export const useDeviceMeters = id => {
  const dispatch = useDispatch();
  const userKey = useUserKey();
  const device = useSelector(state => state.devicesMeters.devices[id]);
  const isFetching = useSelector(state => state.devicesMeters.meta.isFetching);

  if (!isFetching && !device) {
    dispatch(fetchDeviceMeters(id, userKey));
  }

  return device;
};

export const useIsFetchingDeviceMeters = () =>
  useSelector(state => state.devicesMeters.meta.isFetching);

export const fetchDevicesMeters = (ids, force) => async (dispatch, getState) => {
  try {
    const userKey = getState().user.current.auth.key;
    const companyKey = getState().companies.current.api_key;
    if (!userKey || !companyKey) {
      return;
    }
    ids = force
      ? ids
      : ids.filter(
          id =>
            !getState().devicesMeters.devicesMetersMeta[id] &&
            !getState().devicesMeters.devicesMetersMeta[id]?.isFetching
        );
    if (force) {
      dispatch(resetDevicesMeters());
    }
    ids.map(id => dispatch(fetchDevicesMetersStart({ id })));
    await Promise.all(
      ids.map(id =>
        api
          .get(`/devices/${id}/meters`, { authKey: userKey })
          .then(res => {
            if (res?.status === 200) {
              dispatch(fetchDevicesMetersSuccess({ devices: res.body, id, companyKey }));
            } else {
              dispatch(fetchDevicesMetersSuccess({ devices: [], id, companyKey }));
            }
          })
          .catch(err => {
            dispatch(fetchDevicesMetersFailure({ err: err.toString(), id, companyKey }));
          })
      )
    );
  } catch (err) {}
};

export const useCompanyKey = () => useSelector(state => state.devicesMeters.meta.companyKey);
const useIsCompanyKeyDifferent = () => useCompanyKey() !== useCurrentCompanyKey();

export const useDevicesMeters = ids => {
  const dispatch = useDispatch();
  const meters = useSelector(state => state.devicesMeters.devices);
  const devicesMetersMeta = useSelector(state => state.devicesMeters.devicesMetersMeta);
  const isCompanyKeyDifferent = useIsCompanyKeyDifferent();

  const devicesMeters = useMemo(() => {
    return ids
      ?.map(id => ({ id, meters: meters[id] }))
      ?.reduce((a, c) => ({ ...a, [c.id]: c.meters }), {});
  }, [ids, meters]);

  const deviceNeedMeters = useCallback(
    id => {
      return !meters[id] && !devicesMetersMeta[id];
    },
    [meters, devicesMetersMeta]
  );

  const deviceIdsNeedMeters = useMemo(() => {
    return ids.filter(deviceNeedMeters);
  }, [ids, meters, deviceNeedMeters]);

  if (deviceIdsNeedMeters?.length) {
    dispatch(fetchDevicesMeters(ids, isCompanyKeyDifferent));
  }

  const isFetching = useMemo(() => {
    return (
      ids &&
      !!ids.length &&
      ids.some(id => devicesMetersMeta[id] && devicesMetersMeta[id].isFetching)
    );
  }, [ids, meters]);

  return { devicesMeters, isFetching };
};

export default devicesMetersSlice.reducer;
