import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Radio, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './SelectSort.module.scss';

const Option = Select.Option;

export function SelectSort({ style, className, options, onChange, defaultValue, ...props }) {
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue);

  const updateSortByPrefix = useCallback(
    node => {
      const styleSelector = '.' + styles.select + ' .ant-select-selection-item::before';
      for (let i = 0; i < document.styleSheets.length; i++) {
        try {
          const rules = document.styleSheets[i].cssRules;
          for (let j = 0; j < rules.length; j++) {
            if (rules[j].selectorText === styleSelector) {
              rules[j].style.content = '"' + t('ELD.Sort By') + ' "';
              return;
            }
          }
        } catch {}
      }
    },
    [t]
  );

  const handleValueChange = useCallback(v => {
    setValue(prev => {
      const newValue = {
        item: v,
        order: prev.order
      };
      return newValue;
    });
  });

  const handleOrderChange = useCallback(e => {
    setValue(prev => {
      const newValue = {
        item: prev.item,
        order: e.target.value
      };
      return newValue;
    });
  });

  const dropdownRender = menu => (
    <div>
      {menu}
      <Divider style={{ margin: '4px 0' }} />
      <div className={styles.footer}>
        <p>{t('Common.Order')}</p>
        <Radio.Group size="large" defaultValue={value?.order || 'asc'} onChange={handleOrderChange}>
          <Radio value="asc">{t('Common.Ascending')}</Radio>
          <Radio value="desc">{t('Common.Descending')}</Radio>
        </Radio.Group>
      </div>
    </div>
  );

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  return (
    <Select
      className={styles.select + ' ' + className}
      defaultValue={value?.item}
      dropdownRender={dropdownRender}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      onChange={handleValueChange}
      placeholder={t('ELD.Sort By')}
      ref={updateSortByPrefix}
      size="large"
      style={style}
    >
      {options?.map(o => (
        <Option key={o.key} title={t('ELD.Sort By') + ' ' + o.label}>
          {o.label}
        </Option>
      ))}
    </Select>
  );
}
