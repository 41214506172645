export const DriverStatusById = {
  0: 'Driving',
  1: 'OnDuty',
  2: 'OffDuty',
  3: 'SleeperBerth'
};

export const DriverOtherStatusById = {
  0: 'RiskVio',
  1: 'InVio',
  2: 'UnderExp'
};

export const UserTypeId = {
  Driver: 2,
  Exempt: 6
};

export const DriverStatusId = {
  Driving: 0,
  AdverseConditions: 0,
  Work: 1,
  OnDuty: 1,
  YardMove: 1,
  Rest: 2,
  OffDuty: 2,
  OffDutyAtWell: 2,
  PersonalConveyance: 2,
  SleeperBerth: 3,
  Unknown: -1
};

export const DriverOtherStatusId = {
  RiskVio: 0,
  InVio: 1,
  UnderExp: 2
};

export const WorkStatus = {
  Normal: 0,
  AtRisk: 1,
  InVio: 2
};

export const ExemptionStatus = {
  YardMove: true,
  AdverseConditions: true,
  PersonalConveyance: true
};

export const UDTStatus = {
  U: 'Unassigned',
  P: 'Pending',
  D: 'Deleted',
  A: 'Assigned',
  C: 'Commented'
};

export const UDTStatusCategory = {
  U: 'Unassigned',
  P: 'Pending',
  D: 'Deleted',
  A: 'Assigned',
  C: 'Commented'
};

export const UDTEventStatusId = {
  Deleted: 0,
  Pending: 1,
  Unassigned: 2,
  Commented: 3
};

export const UDTStatusCode = {
  Unassigned: 'U',
  Deleted: 'D',
  Pending: 'P',
  Assigned: 'A',
  Commented: 'C'
};

export const UDTEventOtherStatus = {
  0: 'RAW',
  1: 'Updated'
};

export const UDTEventOtherStatusId = {
  RAW: 0,
  Updated: 1
};

export const UDTAssignmentMode = {
  None: 0,
  BulkAssignment: 1
};
