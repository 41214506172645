import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import styles from './StackedBarChart.module.scss';

const MARGIN = {
  top: 20,
  right: 30,
  bottom: 20,
  left: 20
};

const BARS = [];

export const StackedBarChart = ({
  layout = 'horizontal',
  data,
  xAxisDataKey,
  yAxisDataKey,
  xAxisLabel = null,
  yAxisLabel = null,
  hideXAxis = false,
  hideYAxis = false,
  hideCartesianGrid = false,
  barSize,
  barGap,
  bars = BARS,
  height = 455,
  margin = MARGIN,
  tooltipContent = activeEntry => null,
  legendItemContent = (barMeta, itemProps) => barMeta?.name
}) => {
  const [hiddenBars, setHiddenBars] = useState([]);
  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart data={data} margin={margin} layout={layout} barSize={barSize} barGap={barGap || 20}>
        {!hideCartesianGrid && <CartesianGrid vertical={false} />}
        <XAxis
          hide={hideXAxis}
          dataKey={xAxisDataKey}
          interval={0}
          tickLine={false}
          angle={-45}
          textAnchor="end"
          fontSize={11}
          padding={{ left: 5, right: 5 }}
          tickMargin={5}
          type={layout === 'horizontal' ? 'category' : 'number'}
          label={xAxisLabel ? { fontSize: 11, value: xAxisLabel } : {}}
        />
        <YAxis
          hide={hideYAxis}
          dataKey={yAxisDataKey}
          type={layout === 'horizontal' ? 'number' : 'category'}
          tickLine={false}
          axisLine={false}
          tickCount={8}
          fontSize={11}
          label={
            yAxisLabel
              ? { fontSize: 11, angle: -90, position: 'insideLeft', value: yAxisLabel }
              : {}
          }
        />
        <Tooltip
          shared={false}
          content={({ payload, active }) =>
            active && payload?.[0] ? (
              <div className={styles.tooltip}>{tooltipContent(payload[0])}</div>
            ) : null
          }
        />
        {legendItemContent && (
          <Legend
            wrapperStyle={{ bottom: -5 }}
            fontSize={12}
            content={props => (
              <div className={styles.legend}>
                {bars.map((bar, index) => (
                  <span
                    onClick={_ => {
                      if (bar.dataKey) {
                        setHiddenBars(bars =>
                          bars.some(b => b === bar.dataKey)
                            ? bars.filter(b => b !== bar.dataKey)
                            : [...bars, bar.dataKey]
                        );
                      }
                    }}
                    key={`legentItem-${index}`}
                    className={
                      hiddenBars.some(b => b === bar.dataKey)
                        ? styles.inactiveLegendItem
                        : styles.legendItem
                    }
                  >
                    {legendItemContent(bar, props)}
                  </span>
                ))}
              </div>
            )}
          />
        )}
        {bars
          .filter(bar => hiddenBars.every(b => b !== bar.dataKey))
          .map((bar, barIndex) => (
            <Bar
              key={`stackedBar-${barIndex}`}
              {...bar}
              {...(bar?.onClick ? { cursor: 'pointer' } : {})}
            />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export const TooltipContent = ({ label, value, valueColor }) => (
  <div className={styles.tooltip}>
    <div className={styles.xAxisData}>{label}</div>
    <div>
      <span className={styles.yAxisData} style={{ backgroundColor: valueColor }}></span>
      <span>{value}</span>
    </div>
  </div>
);
