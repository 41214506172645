import React from 'react';
import { InputGroup, FormControl, Button, FormGroup, FormLabel, Col } from 'react-bootstrap';
import { Field } from 'formik';
import RBDateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { format } from 'utils/dates';
import './FormDatePicker.scss';
import 'bootstrap-daterangepicker/daterangepicker.css';
import styles from './FormDatePicker.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { subYears } from 'date-fns';

export const FormDatePicker = ({
  name,
  xs,
  className,
  label,
  isRequired,
  setFieldValue,
  disabled,
  format: viewFormat,
  onApply,
  timePicker,
  drops,
  isReadonly,
  keepZone,
  minDate = null
}) => {
  // No disabled field on RBDateRangePicker and disabled field on button doesn't full disable so
  // adding this class on the parent element to fully disable the entire control when disabled
  const datePickerClass =
    isReadonly || disabled ? `tn-datepicker ${styles.datePickerDisabled}` : 'tn-datepicker';

  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <FormGroup as={Col} xs={xs} className={className || ''}>
            <FormLabel className={!label && styles.invisible}>{label || 'Label'}</FormLabel>
            {isRequired && <span className={styles.inputRequired}>*</span>}
            <RBDateRangePicker
              minDate={minDate != null ? minDate : moment(subYears(new Date(), 100))}
              autoApply
              alwaysShowCalendars={true}
              singleDatePicker
              startDate={
                new Date(
                  (field.value ? new Date(field.value) : new Date()).getTime() +
                    moment.parseZone(moment().toDate()).utcOffset() * -60000
                )
              }
              endDate={
                new Date(
                  (field.value ? new Date(field.value) : new Date()).getTime() +
                    moment.parseZone(moment().toDate()).utcOffset() * -60000
                )
              }
              timePicker={timePicker}
              timePicker24Hour
              showDropdowns
              containerClass={datePickerClass}
              drops={drops || 'down'}
              onApply={(event, picker) => {
                if (keepZone) {
                  setFieldValue(name, picker.startDate.toString());
                } else {
                  setFieldValue(name, picker.startDate.toISOString());
                }

                if (onApply) {
                  onApply(picker.startDate.toISOString());
                }
              }}
            >
              <InputGroup>
                <FormControl
                  {...field}
                  readOnly
                  value={
                    field.value
                      ? format(
                          keepZone ? moment.parseZone(field.value) : moment(field.value).toDate(),
                          viewFormat || 'Do MMMM YYYY hh:mm A'
                        )
                      : ''
                  }
                  rows="3"
                  className={`${styles.input} ${meta.touched && meta.error ? 'field-error' : ''}`}
                  disabled={isReadonly || disabled || false}
                />
                <InputGroup.Append className="append">
                  <Button
                    variant="outline-secondary"
                    id={BUTTON_IDS.formDatePicker}
                    className={styles.button}
                    disabled={isReadonly || disabled || false}
                  >
                    <i className="tn-i-date" />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </RBDateRangePicker>
            {isRequired && (
              <div className={`fieldError ${meta.touched && meta.error ? 'error has' : 'error'}`}>
                {meta.touched && meta.error}
              </div>
            )}
          </FormGroup>
        );
      }}
    </Field>
  );
};

export default FormDatePicker;
