import React, { useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import TimePicker from './TimePicker';
import { INPUT_FORMAT } from 'features/sentinel/constants/Generals';

const SentinelDateTimePicker = ({ id, onChange, onBlur, value, format }) => {
  let outputFormat = format ? format : 'x';
  const [open, setOpen] = useState(false);
  const valueMoment = value ? moment(parseInt(value, 10)) : moment();
  const onDateChange = ({ date }) => {
    if (!date) {
      onChange(null);
    }
    if (date instanceof moment) {
      onChange(date.format(outputFormat));
      onBlur();
    }
  };

  function onTimeChange(time) {
    const momentTime = moment(time, 'hh:mm A');
    valueMoment.hour(momentTime.format('HH'));
    valueMoment.minute(momentTime.format('mm'));
    onChange(valueMoment.format(outputFormat));
  }

  return (
    <div className="datetime row m-0">
      <div className="datetime-date p-0">
        <SingleDatePicker
          date={valueMoment} // momentPropTypes.momentObj or null,
          id={id} // PropTypes.string.isRequired,
          onDateChange={date => onDateChange({ date })} // PropTypes.func.isRequired,
          focused={open} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={() => setOpen(!open)} // PropTypes.func.isRequired,
          isOutsideRange={date => date.valueOf() > moment().valueOf()}
          displayFormat={INPUT_FORMAT}
          numberOfMonths={1}
          noBorder={true}
        />
      </div>
      <div className="datetime-time p-0">
        <TimePicker onChange={time => onTimeChange(time)} value={valueMoment.format()} />
      </div>
    </div>
  );
};

export default SentinelDateTimePicker;
