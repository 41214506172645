import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAlertMeterThresholds,
  fetchMeterThresholds,
  fetchAlertMeterThresholdsById
} from './thunks';

export const useMeterThresholds = () => {
  const dispatch = useDispatch();

  const meterThresholds = useSelector(state => state.meterAlerts.meterThresholdsSources);
  const isFetching = useSelector(state => state.meterAlerts.meta.meterThresholds.isFetching);
  const isSuccess = useSelector(state => state.meterAlerts.meta.meterThresholds.success);
  const isError = useSelector(state => state.meterAlerts.meta.meterThresholds.error);

  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete) {
    dispatch(fetchMeterThresholds());
  }
  return meterThresholds;
};

export const useAlertMeterThresholds = () => {
  const dispatch = useDispatch();

  const meterThresholds = useSelector(state => state.meterAlerts.meterThresholds);
  const isFetching = useSelector(state => state.meterAlerts.meta.meterThresholds.alert.isFetching);
  const isSuccess = useSelector(state => state.meterAlerts.meta.meterThresholds.alert.success);
  const isError = useSelector(state => state.meterAlerts.meta.meterThresholds.alert.error);

  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete) {
    dispatch(fetchAlertMeterThresholds());
  }
  return meterThresholds;
};

export const useMeterThresholdById = (meterThresholdId, alertId) => {
  const dispatch = useDispatch();
  const meterThreshold = useSelector(state => state.meterAlerts.meterThresholdsArr[alertId]);

  const isFetching = useSelector(
    state => state.meterAlerts.meta.meterThresholds.meterThresholdsArr[alertId]?.isFetching
  );
  const isSuccess = useSelector(
    state => state.meterAlerts?.meta?.meterThresholds.meterThresholdsArr[alertId]?.success
  );
  const isError = useSelector(
    state => state.meterAlerts?.meta?.meterThresholds.meterThresholdsArr[alertId]?.error
  );

  const isFetchComplete = isSuccess || isError;

  if (!isFetching && !isFetchComplete && alertId && meterThresholdId) {
    dispatch(
      fetchAlertMeterThresholdsById({
        query: {
          meterThresholdId: meterThresholdId
        },
        alertId
      })
    );
  }

  return meterThreshold;
};

export const useIsAlertMeterThresholdsFetching = alertId => {
  const isAlertMeterThresholdsFetching = useSelector(
    state => state.meterAlerts.meta.meterThresholds.meterThresholdsArr[alertId]?.isFetching
  );
  return isAlertMeterThresholdsFetching || false;
};

export const useIsMeterThresholdsSourcesFetching = () => {
  const isMeterThresholdsSourcesFetching = useSelector(
    state => state.meterAlerts.meta.meterThresholds.isFetching
  );
  return isMeterThresholdsSourcesFetching;
};
