import React, { memo } from 'react';
import { AutoSizer, Table, Column } from 'react-virtualized';
import { rowAction } from './VehiclesTableAddFleet';
import {
  cacheDevices as cache,
  checkboxCellRenderer,
  nameCellRenderer,
  deviceInFleetCellRenderer,
  typeCellRenderer,
  imeiCellRenderer,
  serialNoCellRenderer
} from './CellRenderers';
import { Checkbox } from 'antd';
import { Loading } from 'components/loading/Loading';
import { useTranslation } from 'react-i18next';
import styles from './Fleets.module.scss';

export const DevicesTableAddFleetComponent = ({
  devices,
  devicesIDs,
  fleets,
  companies,
  checkedItems,
  setCheckedItems,
  setTableRef,
  isLoading
}) => {
  const { t } = useTranslation();

  const handleCheckboxChange = () => {
    setCheckedItems(devices?.length !== checkedItems?.length ? devicesIDs : []);
  };

  const headerCheckboxRender = () => {
    return (
      <Checkbox
        onChange={handleCheckboxChange}
        checked={devicesIDs?.length === checkedItems?.length}
      />
    );
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }

  return (
    <AutoSizer>
      {({ height, width }) => {
        const fixedColumnsWidth = {
          checkbox: 48,
          name: 250,
          fleetIn: 200,
          type: 160,
          imei: 270,
          serialNo: 270
        };
        return (
          <Table
            deferredMeasurementCache={cache}
            width={width}
            height={height}
            headerClassName={styles.tableHeaderText}
            headerHeight={52}
            rowHeight={cache.rowHeight}
            rowCount={devices.length}
            rowGetter={({ index }) => devices[index]}
            rowClassName="tableRow"
            onRowClick={event => {
              rowAction(event.rowData.id, checkedItems, setCheckedItems);
            }}
            ref={ref => setTableRef(ref)}
            gridClassName="showScrollbarsOnHover fleetsTablesBottomMargin"
          >
            <Column
              label=""
              dataKey="id"
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.checkbox}
              headerRenderer={headerCheckboxRender}
              cellRenderer={props =>
                checkboxCellRenderer({
                  ...props,
                  checkedItems,
                  setCheckedItems
                })
              }
            />
            <Column
              label={t('Common.Name')}
              dataKey="name"
              width={fixedColumnsWidth.name}
              cellRenderer={nameCellRenderer}
            />
            <Column
              label={t('Fleets.Form.Fleet(s)CurrentlyIn')}
              dataKey="fleetsIn"
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.fleetIn}
              cellRenderer={props => deviceInFleetCellRenderer({ ...props, companies, fleets })}
            />
            <Column
              label={t('VehicleTypes.Type')}
              dataKey="type"
              style={{ overflow: 'visible' }}
              width={fixedColumnsWidth.type}
              cellRenderer={typeCellRenderer}
            />
            <Column
              label={t('Devices.IMEI')}
              dataKey="imei"
              width={fixedColumnsWidth.imei}
              cellRenderer={imeiCellRenderer}
            />
            <Column
              label={t('Devices.SerialNo')}
              dataKey="serialNo"
              width={fixedColumnsWidth.serialNo}
              cellRenderer={serialNoCellRenderer}
            />
          </Table>
        );
      }}
    </AutoSizer>
  );
};

export const DevicesTableAddFleet = memo(DevicesTableAddFleetComponent);
