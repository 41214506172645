/* global google */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DirectionsRenderer } from 'react-google-maps';
import { ToastType } from 'components/notifications/toasts/Toast';
import { openToast } from 'features/toasts/toastsSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';

const MapDirections = ({
  places,
  travelMode,
  onNoDirections = () => {},
  onDirectionsSucceed = () => {},
  onDirectionError = () => {},
  setIsLoading = () => {},
  readonly
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localization = useLocalization();
  const [directions, setDirections] = useState(null);
  const [oldPlaces, setOldPlaces] = useState(null);
  let directionsRef = React.createRef();
  const directionsService = new google.maps.DirectionsService();
  const redrawDirections = () => {
    const directionsChanged = directionsRef.getDirections();
    onDirectionsSucceed(directionsChanged, true);
  };

  useEffect(() => {
    if (places.length > 1) {
      if (JSON.stringify(places) !== JSON.stringify(oldPlaces)) {
        const waypoints = places.map(p => {
          if (p.seq) {
            return {
              location: { lat: p.latitude, lng: p.longitude },
              stopover: true
            };
          } else {
            return { ...p };
          }
        });
        const origin = waypoints.shift().location;
        const destination = waypoints.pop().location;

        let route = {
          origin: origin,
          destination: destination,
          travelMode: travelMode,
          waypoints: waypoints,
          unitSystem:
            localization.formats.speed.unit_per_hour === 'mph'
              ? google.maps.UnitSystem.IMPERIAL
              : google.maps.UnitSystem.METRIC
        };
        setIsLoading(true);
        directionsService.route(route, (result, status) => {
          console.log('Called directions service');
          if (status === google.maps.DirectionsStatus.OK) {
            setDirections(result);
            setOldPlaces(places);
            onDirectionsSucceed(result);
          } else {
            onDirectionError(result);
            const noResult = result.status === 'ZERO_RESULTS';
            dispatch(
              openToast({
                type: noResult ? ToastType.Warning : ToastType.Error,
                message: noResult
                  ? t('JourneyPlanner.Notifications.NoRouteResult')
                  : `Map API error: ${result.status}`
              })
            );
          }
          setIsLoading(false);
        });
      }
    } else {
      setDirections(null);
      onNoDirections(null);
    }
  }, [places]);

  return (
    directions && (
      <DirectionsRenderer
        directions={directions}
        ref={r => (directionsRef = r)}
        onDirectionsChanged={redrawDirections}
        panel={document.getElementById('panel')}
        options={{
          draggable: !readonly,
          markerOptions: {
            visible: false
          }
        }}
      />
    )
  );
};

export default MapDirections;
