export const eventTypes = {
  ScheduleEvent: 'Ng::ScheduleEvent',
  DocumentExpire: 'Ng::Event::DocumentExpire',
  TachoEvent: 'Ng::Event::TachoFatigueAlert'
};

export const eventScheduleTypes = {
  VehicleMaintenanceSchedule: 'Ng::VehicleMaintenanceSchedule',
  DriverManagementSchedule: 'Ng::DriverManagementSchedule'
};

export const alertLevelDescription = {
  [2]: 'Events.SecondAlert',
  [3]: 'Events.ThirdAlert'
};
