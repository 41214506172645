/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Device from "./Device";
import Gps from "./Gps";
import LocalDateTime from "./LocalDateTime";
import Message from "./Message";
import User from "./User";
import Vehicle from "./Vehicle";

/**
 * The MessageEvent model module.
 * @module model/MessageEvent
 * @version 1.0
 */
class MessageEvent {
  /**
   * Constructs a new <code>MessageEvent</code>.
   * @alias module:model/MessageEvent
   */
  constructor() {
    MessageEvent.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>MessageEvent</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/MessageEvent} obj Optional instance to populate.
   * @return {module:model/MessageEvent} The populated <code>MessageEvent</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new MessageEvent();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("eventType")) {
        obj["eventType"] = ApiClient.convertToType(data["eventType"], "String");
      }
      if (data.hasOwnProperty("device")) {
        obj["device"] = Device.constructFromObject(data["device"]);
      }
      if (data.hasOwnProperty("receiver")) {
        obj["receiver"] = User.constructFromObject(data["receiver"]);
      }
      if (data.hasOwnProperty("vehicle")) {
        obj["vehicle"] = Vehicle.constructFromObject(data["vehicle"]);
      }
      if (data.hasOwnProperty("message")) {
        obj["message"] = Message.constructFromObject(data["message"]);
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("location")) {
        obj["location"] = ApiClient.convertToType(data["location"], "String");
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = LocalDateTime.constructFromObject(data["createdAt"]);
      }
      if (data.hasOwnProperty("eventAt")) {
        obj["eventAt"] = ApiClient.convertToType(data["eventAt"], "Date");
      }
      if (data.hasOwnProperty("GPS")) {
        obj["GPS"] = Gps.constructFromObject(data["GPS"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
MessageEvent.prototype["id"] = undefined;

/**
 * @member {String} eventType
 */
MessageEvent.prototype["eventType"] = undefined;

/**
 * @member {module:model/Device} device
 */
MessageEvent.prototype["device"] = undefined;

/**
 * @member {module:model/User} receiver
 */
MessageEvent.prototype["receiver"] = undefined;

/**
 * @member {module:model/Vehicle} vehicle
 */
MessageEvent.prototype["vehicle"] = undefined;

/**
 * @member {module:model/Message} message
 */
MessageEvent.prototype["message"] = undefined;

/**
 * @member {String} status
 */
MessageEvent.prototype["status"] = undefined;

/**
 * @member {String} location
 */
MessageEvent.prototype["location"] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
MessageEvent.prototype["createdAt"] = undefined;

/**
 * @member {Date} eventAt
 */
MessageEvent.prototype["eventAt"] = undefined;

/**
 * @member {module:model/Gps} GPS
 */
MessageEvent.prototype["GPS"] = undefined;

export default MessageEvent;
