import { useDispatch, useSelector } from 'react-redux';
import { fetchSmartJobsCodes, smartJobsCodesSelectors } from '../';

export const useSmartJobsCodes = () => {
  const dispatch = useDispatch();
  const codes = useSelector(smartJobsCodesSelectors.getCodes);

  const isFetching = useSelector(smartJobsCodesSelectors.isSmartJobsCodesFetching);
  const isSuccess = useSelector(smartJobsCodesSelectors.isSmartJobsCodesSuccess);
  const isError = useSelector(smartJobsCodesSelectors.isSmartJobsCodesError);
  const isCompanyChanged = useIsCompanyChanged();
  const isFetchComplete = isSuccess || isError;

  if (!isFetching && (!isFetchComplete || isCompanyChanged)) {
    dispatch(fetchSmartJobsCodes());
  }

  return codes;
};

export const useIsUpdatingCode = () => useSelector(smartJobsCodesSelectors.isUpdatingCode);
export const useAddCodeError = () => useSelector(smartJobsCodesSelectors.isSmartJobsCodesError);
const useIsCompanyChanged = () =>
  useSelector(state => state.companies.current.id !== state.smartJobsCodes.meta.companyId);
export const useIsFetchingCompleted = () =>
  useSelector(smartJobsCodesSelectors.isFetchingCompleted);
