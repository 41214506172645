import React, { useState } from 'react';
import styles from '../UDT.module.scss';
import { Button, Row, Col, Tooltip, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faCommentAlt,
  faClipboardCheck,
  faUndo
} from '@fortawesome/free-solid-svg-icons';
import { UDTStatusCategory } from 'containers/ELD/Constants';
import { useTranslation } from 'react-i18next';
import i18next from 'i18nextConfig';
import { BUTTON_IDS } from 'utils/globalConstants';
import { FeatureFlag, useCan, services, entities } from 'features/permissions';
import { useUser } from 'features/user/userSlice';

function ActionCell({ rowData, showIcon = false, ...props }) {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const user = useUser();
  const can = useCan();

  const isDriverPortal = can({
    everyCompanyService: [services.ELD],
    featureFlag: [FeatureFlag.driverPortal.flag],
    everyEntity: [entities.DRIVERPORTAL],
    otherConditions: [() => user?.type?.code === 'DRIVER']
  });

  const popOverMenuAdmin = (
    <div className={styles.actionCell}>
      <Row>
        <Col span={24}>
          <Button
            type="text"
            onClick={() => {
              setShow(false);
              rowData && rowData.onPreviewRoute(rowData);
            }}
            id={BUTTON_IDS.udtGridPreview}
          >
            {showIcon && <FontAwesomeIcon visibility={showIcon} icon={faMapMarkerAlt} />}
            {t('ELD.Preview Route')}
          </Button>
        </Col>
      </Row>
      {[
        UDTStatusCategory['U'],
        UDTStatusCategory['C'],
        UDTStatusCategory['P'],
        UDTStatusCategory['D']
      ].indexOf(rowData.status) >= 0 && (
        <Row>
          <Col span={24}>
            <Button
              type="text"
              onClick={() => {
                setShow(false);
                rowData && rowData.onEditComment(rowData);
              }}
              id={BUTTON_IDS.udtGridComment}
            >
              {showIcon && <FontAwesomeIcon icon={faCommentAlt} />}
              {rowData?.comments?.length > 0 ? t('ELD.Update Comments') : t('ELD.Add Comments')}
            </Button>
          </Col>
        </Row>
      )}
      {[UDTStatusCategory['U'], UDTStatusCategory['C'], UDTStatusCategory['D']].indexOf(
        rowData.status
      ) >= 0 && (
        <Row>
          <Col span={24}>
            <Button
              type="text"
              onClick={() => {
                setShow(false);
                rowData && rowData.onAssignEvent(rowData);
              }}
              id={BUTTON_IDS.udtGridSuggest}
            >
              {showIcon && <FontAwesomeIcon icon={faClipboardCheck} />}
              {t('ELD.Suggest Assignment')}
            </Button>
          </Col>
        </Row>
      )}

      {[UDTStatusCategory['A']].indexOf(rowData.status) >= 0 && (
        <Row>
          <Col span={24}>
            <Button
              type="text"
              onClick={() => {
                setShow(false);
                rowData && rowData.onUndoAssignEvent(rowData);
              }}
              id={BUTTON_IDS.udtGridUndo}
            >
              {showIcon && <FontAwesomeIcon icon={faUndo} />}
              {t('ELD.Undo Assignment')}
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );

  const popOverMenuDriverPortal = (
    <div className={styles.actionCell}>
      <Row>
        <Col span={24}>
          <Button
            type="text"
            onClick={() => {
              setShow(false);
              rowData && rowData.onPreviewRoute(rowData);
            }}
            id={BUTTON_IDS.udtGridPreview}
          >
            {showIcon && <FontAwesomeIcon visibility={showIcon} icon={faMapMarkerAlt} />}
            {t('ELD.Preview Route')}
          </Button>
        </Col>
      </Row>
      {[UDTStatusCategory['U'], UDTStatusCategory['C'], UDTStatusCategory['D']].indexOf(
        rowData.status
      ) >= 0 && (
        <>
          <Row>
            <Col span={24}>
              <Button
                type="text"
                onClick={() => {
                  setShow(false);
                  rowData && rowData.onEditComment(rowData);
                }}
                id={BUTTON_IDS.udtGridComment}
              >
                {showIcon && <FontAwesomeIcon icon={faCommentAlt} />}
                {rowData?.comments?.length > 0 ? t('ELD.Update Comments') : t('ELD.Add Comments')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Button
                type="text"
                onClick={() => {
                  setShow(false);
                  rowData && rowData.onAssignEvent(rowData);
                }}
                id={BUTTON_IDS.udtGridSuggest}
              >
                {showIcon && <FontAwesomeIcon icon={faClipboardCheck} />}
                {t('ELD.DriverPortal.ClaimDriveTime')}
              </Button>
            </Col>
          </Row>
        </>
      )}

      {[UDTStatusCategory['P']].indexOf(rowData.status) >= 0 &&
        isDriverPortal &&
        user.id === rowData?.assignedUser?.id && (
          <>
            <Row>
              <Col span={24}>
                <Button
                  type="text"
                  onClick={() => {
                    setShow(false);
                    rowData && rowData.onEditComment(rowData);
                  }}
                  id={BUTTON_IDS.udtGridComment}
                >
                  {showIcon && <FontAwesomeIcon icon={faCommentAlt} />}
                  {rowData?.comments?.length > 0 ? t('ELD.Update Comments') : t('ELD.Add Comments')}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Button
                  type="text"
                  onClick={() => {
                    setShow(false);
                    rowData && rowData.onAcceptEvent(rowData);
                  }}
                  id={BUTTON_IDS.udtGridAccept}
                >
                  {showIcon && <FontAwesomeIcon icon={faClipboardCheck} />}
                  {t('ELD.DriverPortal.AcceptDriveTime')}
                </Button>
              </Col>
            </Row>
          </>
        )}
    </div>
  );
  return (
    <Popover
      overlayClassName={styles.udtPopover}
      onOpenChange={v => setShow(v)}
      open={show}
      placement="left"
      trigger="click"
      content={isDriverPortal ? popOverMenuDriverPortal : popOverMenuAdmin}
    >
      <Button type="text" id={BUTTON_IDS.udtGridShow}>
        <i style={{ fontSize: '26px' }} className={'tn-i-elipsis'} />
      </Button>
    </Popover>
  );
}
export function actionCell_Default(rowData) {
  return <ActionCell rowData={rowData} />;
}

export function actionCell_AssignMode(rowData) {
  return (
    <Row className={styles.actionCell} gutter={10}>
      <Col>
        <Tooltip title={i18next.t('ELD.Preview Route')}>
          <Button
            type="text"
            onClick={() => rowData?.onPreviewRoute(rowData)}
            id={BUTTON_IDS.udtGridPreviewRoute}
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </Button>
        </Tooltip>
      </Col>
    </Row>
  );
}
