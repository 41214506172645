import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './AlertData.module.scss';

export function OOHAlertRender({ alertData }) {
  const { t } = useTranslation();

  return (
    <>
      <span className={styles.type}>{t('Notifications.OOHAlert')}</span>{' '}
      {t('Notifications.FromDevice')}{' '}
      <strong>{alertData?.deviceName || alertData?.device?.name || t('Common.Unknown')}</strong>
      {'. '}
      {t('Notifications.AtLocation')}
      {': '} <em>{alertData?.location}</em>
    </>
  );
}
