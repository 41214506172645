import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { useVehicle } from 'features/fleets/fleetsSlice';

import { DriverName } from 'containers/Tracking/Common/DiverName';
import { CardViewMap } from './CardViewMap';
import { EllipsisButtonContextMenu } from './EllipsisButtonContextMenu';

import HideNonBusinessStatus from '../../Common/HideNonBusinessStatus';

import { DEFAULT_IGNITION, DUTY_STATUS } from '../../constants';
import { BUTTON_IDS } from 'utils/globalConstants';

export const VehicleCard = ({
  card,
  geofences = [],
  isPinned,
  onPinClick,
  showMap,
  toggleMapView,
  onMessageVehicle,
  vehicleStatus = {
    hosProgress: 60,
    hosColor: '#ff4d4f'
  },
  cardKey,
  hideNonBusiness
}) => {
  const { t } = useTranslation();
  const vehicleInfo = useVehicle(card.vehicle?.id, 'image_url');

  useEffect(() => {
    if (vehicleStatus.hosTimeUsed / vehicleStatus.hosTimeAllotted < 0.65)
      vehicleStatus.hosColor = '#26bd8b';
    else if (vehicleStatus.hosTimeUsed / vehicleStatus.hosTimeAllotted < 0.8)
      vehicleStatus.hosColor = '#ffc53d';
  }, [card, vehicleStatus]);

  return (
    <div
      style={{
        cursor: 'grab',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        color: 'black',
        borderRadius: '4px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        transition: 'box-shadow .1s ease-in-out',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateColumns: '15px 64px auto 32px 32px',
        gridTemplateRows: '30px 1px 24px 6px auto',
        paddingTop: '8px',
        '&:hover': {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.35)'
        }
      }}
    >
      {/* Vehicle Status Bar */}
      <div
        style={{
          gridRowStart: 1,
          gridRowEnd: 4,
          gridColumnStart: 1,
          gridColumnEnd: 1,
          backgroundColor: hideNonBusiness
            ? DUTY_STATUS.NonBusiness.color
            : card?.vehicleIgnitionStatus.color ?? DEFAULT_IGNITION.color,
          borderRadius: 4,
          minWidth: 6,
          justifySelf: 'flex-end'
        }}
      />
      {/* Vehicle Name */}
      <div
        style={{
          display: 'flex',
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 2,
          gridColumnEnd: 4,
          padding: '0 4px',
          color: '#515e71',
          alignItems: 'center'
        }}
      >
        <span
          style={{
            margin: 0,
            fontSize: '20px',
            fontWeight: '500',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {card.vehicleName}
        </span>
      </div>

      {/* Pin Button */}
      <div
        style={{
          display: 'flex',
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 4,
          gridColumnEnd: 5,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          onClick={() => onPinClick(card)}
          style={{
            padding: 0,
            minHeight: 'unset',
            height: '20px',
            width: '20px',
            backgroundColor: 'transparent',
            color: isPinned ? '#26bd8b' : '#969696',
            '&:hover': {
              background: 'transparent'
            }
          }}
          id={BUTTON_IDS.vehicleCardPin}
        >
          <i style={{ fontSize: '20px' }} className={isPinned ? 'tn-i-pin' : 'tn-i-unpin'} />
        </Button>
      </div>

      {/* Elipsis Button */}
      <div
        style={{
          display: 'flex',
          gridRowStart: 1,
          gridRowEnd: 2,
          gridColumnStart: 5,
          gridColumnEnd: 6,
          padding: '4px 8px 0 0',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <EllipsisButtonContextMenu
          iconStyle={{ fontSize: '16px' }}
          iconHorizontal
          card={card}
          isCardPinned={isPinned}
          showMap={showMap}
          setShowMap={toggleMapView}
          onMessageVehicle={onMessageVehicle}
          cardKey={cardKey}
          hideNonBusiness={hideNonBusiness}
        />
      </div>

      {/* Vehicle Status */}
      <div
        style={{
          display: 'flex',
          gridRowStart: 2,
          gridRowEnd: 4,
          gridColumnStart: 2,
          gridColumnEnd: 4,
          padding: '0 8px 0 4px',
          alignItems: 'flex-end'
        }}
      >
        {hideNonBusiness ? (
          <HideNonBusinessStatus />
        ) : (
          <div
            style={{
              padding: '2px 16px',
              backgroundColor: card?.vehicleIgnitionStatus?.color ?? DEFAULT_IGNITION.color,
              color: 'white',
              fontSize: '13px',
              fontWeight: '500',
              borderRadius: '3px'
            }}
          >
            {t(
              `Tracking.Ignition.${card?.vehicleIgnitionStatus?.status ?? DEFAULT_IGNITION.status}`
            )}
          </div>
        )}
      </div>

      {/* Driver Name */}
      <div
        style={{
          gridRowStart: 3,
          gridRowEnd: 3,
          gridColumnStart: 3,
          gridColumnEnd: 6,
          textAlign: 'right',
          alignItems: 'right',
          justifyContent: 'right',
          padding: '0 1rem',
          color: '#515e71'
        }}
      >
        <span
          style={{
            fontSize: '14px',
            fontWeight: '400',
            textAlign: 'right',
            alignItems: 'right',
            justifyContent: 'right'
          }}
        >
          <DriverName device={card} />
        </span>
      </div>

      {/* Vehicle Image */}
      <div
        style={{
          display: 'flex',
          gridRowStart: 5,
          gridRowEnd: 6,
          gridColumnStart: 1,
          gridColumnEnd: 6,
          backgroundColor: '#dadee3',
          backgroundImage: `url(${vehicleInfo?.imageUrl})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#515e71',
          position: 'relative'
        }}
      >
        {showMap && <CardViewMap card={card} geofences={geofences} />}
        {!card.vehicleImage && !showMap && <span>{t('Tracking.NoImage')}</span>}
        {/* Driver Image */}
        <div
          style={{
            display: 'flex',
            gridRowStart: 1,
            gridRowEnd: 4,
            gridColumnStart: 2,
            gridColumnEnd: 2,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            right: '5px',
            top: '-10px'
          }}
        >
          <div
            style={{
              display: 'flex',
              borderStyle: 'solid',
              borderWidth: '3px',
              borderColor: 'white',
              backgroundColor: '#dadee3',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '50%',
              height: '52px',
              width: '52px',
              backgroundImage: `url(${card.driverImage})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {!card.driverImage && (
              <div
                style={{
                  fontSize: '21px',
                  color: '#fff',
                  fontWeight: '500'
                }}
              >
                {card.driverName === 'Not Logged In'
                  ? ''
                  : card.driverName
                      .split(/\s+/g)
                      .map(word => word && word[0])
                      .filter(char => !!char)
                      .join('')
                      .toUpperCase()
                      .slice(0, 2)}
              </div>
            )}
            <div
              style={{
                width: '12px',
                height: '12px',
                backgroundColor: hideNonBusiness
                  ? DUTY_STATUS.NonBusiness.color
                  : card?.vehicleIgnitionStatus?.color ?? DEFAULT_IGNITION.color,
                borderRadius: '50%',
                position: 'absolute',
                bottom: 0,
                right: '5px'
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
