import React, { useState } from 'react';
import { CardContainer } from 'components/card-container';
import styles from './CardEventType.module.scss';
import { useTranslation } from 'react-i18next';
import { Progress } from 'antd';
import { TrailColor } from './constants';
import { TabKeys } from 'containers/Scorecard';
import { Collapse } from 'react-bootstrap';
import { ButtonStyle, ExpandButton, IconStyle } from 'components/ant/Button/ExpandButton';

export const CardEventType = ({ title, scores, tab, colors, eventDetailsMode }) => {
  const { t } = useTranslation();
  const { entityValue, companyValue, groupValue, previousValue } = scores;
  const trend = entityValue - previousValue;
  const group = tab === TabKeys.vehicles ? 'Fleet' : 'Branch';
  const [open, setOpen] = useState(false);
  const progressData =
    tab === TabKeys.vehicles
      ? [
          {
            label: t('Scorecard.YourScore'),
            value: entityValue,
            color: colors.entity,
            key: 1,
            showScoreFormula: true
          },
          {
            label: t('Scorecard.CompanyScore'),
            value: companyValue,
            color: colors.company,
            key: 2,
            showScoreFormula: false
          }
        ]
      : [
          {
            label: t('Scorecard.YourScore'),
            value: entityValue,
            color: colors.entity,
            key: 1,
            showScoreFormula: true
          },
          {
            label: t(`Scorecard.${group}Score`),
            value: groupValue,
            color: colors.group,
            key: 2,
            showScoreFormula: false
          },
          {
            label: t('Scorecard.CompanyScore'),
            value: companyValue,
            color: colors.company,
            key: 3,
            showScoreFormula: false
          }
        ];

  return (
    <div className={styles.container}>
      <CardContainer title={title} trend={trend} previousValue={previousValue} t={t}>
        {eventDetailsMode ? (
          <div>
            {progressData.map(({ label, value, color, key, showScoreFormula }) => (
              <div key={key}>
                <div className={styles.progressInfo}>
                  <div
                    className={
                      showScoreFormula
                        ? styles.progressLabel
                        : styles.progressLabelCompanyBranchScore
                    }
                  >
                    {label}
                  </div>
                  {showScoreFormula && (
                    <ExpandButton
                      buttonStyle={ButtonStyle.text}
                      iconStyle={IconStyle.SignleArrow}
                      onExpand={() => setOpen(!open)}
                      defaultExpand={false}
                    />
                  )}
                  <Progress
                    showInfo={false}
                    status="normal"
                    strokeColor={color}
                    trailColor={TrailColor}
                    percent={value}
                    strokeLinecap="square"
                  />
                  <div className={styles.currentValueEventDetails}>{value}</div>
                </div>
                {showScoreFormula && (
                  <Collapse in={open}>
                    <div className={styles.scoreFormula}>
                      100 - ( <span className={styles.penaltyFormula}> TOTAL PENALTY POINTS </span>{' '}
                      / ( <span className={styles.distanceFormula}> TOTAL DISTANCE </span> / 100 ) )
                    </div>
                  </Collapse>
                )}
                <br />
              </div>
            ))}
          </div>
        ) : (
          <div>
            {progressData.map(({ label, value, color, key }) => (
              <div key={key}>
                <div className={styles.progressInfo}>
                  <div className={styles.progressLabel}>{label}</div>
                  <div className={styles.currentValue}>{value}</div>
                </div>
                <Progress
                  showInfo={false}
                  status="normal"
                  strokeColor={color}
                  trailColor={TrailColor}
                  percent={value}
                  strokeLinecap="square"
                />
              </div>
            ))}
          </div>
        )}
      </CardContainer>
    </div>
  );
};
