/* global google */
import { Button, Col, Input, Row, Space } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModeSwitch } from './ModeSwitch';
import styles from './controlpanel.module.scss';
import { useLocalization } from 'features/localization/localizationSlice';
import { useMsgClient } from 'utils/hooks/msgChannel';
import { MsgType, DefaultRadius } from './constants';
import { GeoSuggest } from 'components/GeoSuggest';
import { useCurrentCompany } from 'features/company/companySlice';
import request from 'superagent';
import { useUserKey } from 'features/user/userSlice';
import { API_PATH } from 'config';
import { LocalizationUtil } from 'features/localization/localization';
import { useGeofenceSuggests } from 'components/GeoSuggest/useGeofenceSuggests';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useFleets } from 'features/fleets/fleetsSlice';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';

const DelayTime = 30; //ms

const convertCircleToPoints = (center, radius, numberOfPoints) => {
  let points = [],
    increment = 360 / numberOfPoints,
    degrees = 0;

  for (let i = 0; i < numberOfPoints; ++i, degrees += increment) {
    points.push(google.maps.geometry.spherical.computeOffset(center, radius, degrees));
  }

  return points;
};

export function NearestSearchPane({
  mode,
  onModeChange,
  onAddressChanged,
  onRadiusChanged,
  msgChannel,
  trackingDevices,
  fleets,
  handleFilteredFleetsChange
}) {
  const { t } = useTranslation();
  const locale = useLocalization();
  const [address, setAddress] = useState('');
  const [radius, setRadius] = useState(null);
  const [latLng, setLatLng] = useState(null);
  const radiusTimeHandle = useRef(null);
  const geoSuggestRef = useRef(null);
  const msgClient = useMsgClient(msgChannel);
  const [isSearching, setIsSearching] = useState(false);
  const currentCompany = useCurrentCompany();
  const userKey = useUserKey();

  const changeAddress = useCallback(val => {
    setAddress(val);
    setRadius(prev => {
      if (prev == null) {
        return DefaultRadius;
      }
      return prev;
    });
  }, []);

  const handleAddressChanged = useCallback(
    suggest => {
      if (suggest != null) {
        const val = suggest.label;
        let latLng = null;

        if (!suggest.gmaps) {
          latLng = {
            lat: suggest.location.lat,
            lng: suggest.location.lng
          };
        } else {
          latLng = {
            lat: suggest.gmaps.geometry?.location?.lat(),
            lng: suggest.gmaps.geometry?.location?.lng()
          };
        }

        changeAddress(val);
        setLatLng(latLng);
        if (onAddressChanged) {
          onAddressChanged(val);
        }
        if (msgClient) {
          msgClient.sendMsg({ type: MsgType.AddressUpdated, value: latLng });
        }
      }
    },
    [onAddressChanged, msgClient]
  );

  const handleRadiusChanged = useCallback(
    evt => {
      const val = Number(evt.target.value);
      if (radiusTimeHandle.current != null) {
        clearTimeout(radiusTimeHandle.current);
        radiusTimeHandle.current = null;
      }

      radiusTimeHandle.current = setTimeout(() => {
        setRadius(val);
        if (onRadiusChanged) {
          onRadiusChanged(val);
        }
        if (msgClient) {
          msgClient.sendMsg({ type: MsgType.RadiusUpdated, value: val });
        }
      }, DelayTime);
    },
    [onRadiusChanged, msgClient]
  );

  const resetSearch = useCallback(() => {
    setAddress('');
    setRadius(null);
    setLatLng(null);
    geoSuggestRef.current != null && geoSuggestRef.current.clear();

    if (onAddressChanged) {
      onAddressChanged('');
    }

    if (onRadiusChanged) {
      onRadiusChanged(null);
    }
  }, [onAddressChanged, onRadiusChanged]);

  const handleMessaging = useCallback(
    msg => {
      if (msg?.type === MsgType.AddressUpdated) {
        changeAddress(msg.value.label);
        if (geoSuggestRef.current != null) {
          geoSuggestRef.current.clear();
          geoSuggestRef.current.update(msg.value.label);
          setLatLng(msg.value.latLng);
        }
      } else if (msg?.type === MsgType.RadiusUpdated) {
        setRadius(Math.max(1, locale.convertDistance(Math.ceil(msg.value / 1000))));
      } else if (msg?.type === MsgType.Reset) {
        resetSearch();
      }
    },
    [msgClient, locale]
  );

  const handleSearch = useCallback(() => {
    setIsSearching(true);
    msgClient.sendMsg({
      type: MsgType.Searching,
      value: true
    });

    let radiusMeter =
      locale.formats.speed.unit_pluralize.toLowerCase() === 'km'
        ? radius * 1000
        : LocalizationUtil.miletometer(radius);

    let devices = trackingDevices;

    const fleetIds = fleets.filter(i => i.checked).map(i => i.id);
    devices = (trackingDevices || []).filter(i => fleetIds.includes(i.fleetId || -1));

    if ((devices || []).length > 0) {
      const points = convertCircleToPoints(latLng, radiusMeter, 360);
      request('POST', API_PATH + `/devices/proximity?company_id=${currentCompany.id}`)
        .set('Authorization', `Token token="${userKey}"`)
        .set('content-type', 'application/json')
        .send({
          deviceIds: devices?.map(device => device.id.toString()).join(', ') || [],
          points: points
        })
        .then(response => {
          setIsSearching(false);
          msgClient.sendMsg({
            type: MsgType.Searching,
            value: false
          });

          let devicesMap = {};
          let filteredDevices = null;

          if (response.body?.length > 0) {
            response.body.forEach((device, index) => {
              devicesMap[device.did] = response.body[index];
            });

            filteredDevices = trackingDevices
              ?.filter(device => devicesMap[device.id] != null)
              .map(device => {
                const { interactiveDevices, ...tempDevice } = device;
                const newDevice = JSON.parse(JSON.stringify(tempDevice));

                newDevice.entry = JSON.parse(devicesMap[device.id].entry);
                newDevice.entry.at = new Date(newDevice.entry.at);
                newDevice.exit = JSON.parse(devicesMap[device.id].exit);
                newDevice.exit.at = new Date(newDevice.exit.at);
                newDevice.deviceStats.gps.Lat = newDevice.exit.lat;
                newDevice.deviceStats.gps.Lng = newDevice.exit.lng;

                return newDevice;
              });
          }

          msgClient.sendMsg({
            type: MsgType.SearchResult,
            value: filteredDevices || []
          });
        })
        .catch(e => {
          setIsSearching(false);
          msgClient.sendMsg({
            type: MsgType.Searching,
            value: false
          });
        });
    } else {
      setIsSearching(false);
      msgClient.sendMsg({
        type: MsgType.Searching,
        value: false
      });

      msgClient.sendMsg({
        type: MsgType.SearchResult,
        value: []
      });
    }
  }, [msgClient, radius, latLng, currentCompany, trackingDevices, userKey, locale, fleets]);

  const handleReset = useCallback(() => {
    resetSearch();

    if (msgClient) {
      msgClient.sendMsg({
        type: MsgType.Reset
      });
    }
  }, [msgClient, resetSearch]);

  useEffect(() => {
    if (msgClient != null) {
      msgClient.addOnMessageEventListener(handleMessaging);
    }
  }, [msgClient, handleMessaging]);

  const { geoSuggestProps } = useGeofenceSuggests();

  return (
    <Row className={styles.nearestSearchPane}>
      <Col span={24}>
        <Row className={styles.stepContainer}>
          <Col className={styles.step} span={24}>
            <span>{t('Tracking.Proximity.Step1')}:</span>
            <span>{t('Tracking.Proximity.SelectFleet')}</span>
          </Col>
          <Col span={24}>
            <AntMultiselect
              className={styles.fleetFilter}
              size={'middle'}
              title={
                fleets?.some(value => !value.checked) ? t('Common.Fleets') : t('Common.AllFleets')
              }
              onFilter={handleFilteredFleetsChange}
              data={fleets}
            />
          </Col>
        </Row>
        <ModeSwitch mode={mode} onChange={onModeChange} />

        <Row className={styles.stepContainer}>
          <Col className={styles.step} span={24}>
            <span>{t('Tracking.Proximity.Steps', { num: 3 })}:</span>
            <span>{t('Tracking.Proximity.SelectLocation')}</span>
          </Col>
          <Col span={24}>
            <p>{t('Tracking.Proximity.SelectLocationDescription')}</p>
            <div className={styles.stepInput}>
              <p>{t('Tracking.Proximity.PlaceOrAddress')}</p>
              <GeoSuggest
                tabIndex={0}
                ref={geoSuggestRef}
                className={styles.geoSearchbox}
                placeholder={t('Tracking.Proximity.PlaceOrAddressOrGeofencePlaceholder')}
                onSuggestSelect={handleAddressChanged}
                autoComplete="off"
                disabled={isSearching}
                {...geoSuggestProps}
              />
            </div>
          </Col>
        </Row>

        <Row className={styles.stepContainer}>
          <Col className={styles.step} span={24}>
            <span>{t('Tracking.Proximity.Steps', { num: 4 })}:</span>
            <span>{t('Tracking.Proximity.ModifySearchArea')}</span>
          </Col>
          <Col>
            <p>{t('Tracking.Proximity.ModifySearchAreaDescription')}</p>
            <div className={styles.stepInput}>
              <p>{t('Tracking.Proximity.Radius', { unit: locale.formats.speed.unit_pluralize })}</p>
              <Input
                size="large"
                onChange={handleRadiusChanged}
                value={radius}
                min={0}
                type="number"
                allowClear={true}
                placeholder={t('Tracking.Proximity.RadiusPlaceholder', {
                  unit: locale.formats.speed.unit_pluralize
                })}
                disabled={isSearching || !address?.trim().length}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24} className={styles.paneFooter}>
        <Space size={16}>
          <Button
            type="primary"
            size="large"
            onClick={handleSearch}
            disabled={!address?.trim().length || radius == null || radius === ''}
            loading={isSearching}
            id={BUTTON_IDS.nearestSearchPaneFind}
          >
            {t('Tracking.FindVehicles')}
          </Button>
          <Button
            size="large"
            onClick={handleReset}
            disabled={isSearching}
            id={BUTTON_IDS.nearestSearchPaneReset}
          >
            {t('Common.Reset')}
          </Button>
        </Space>
      </Col>
    </Row>
  );
}
