import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Modal, Row, Col, Input } from 'antd';

import { useTranslation } from 'react-i18next';

import { GridColumnEditor } from './GridColumnEditor';

import {
  useUserGridSettings,
  useIsUserGridSettingsUpdating,
  updateUserGridSettings,
  DEFAULT_VIEW_NAME
} from 'features/user/userGridSettingsSlice';

import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';

import { sortStrings } from 'utils/strings';

import {
  getIncludedAndAvailableColumns,
  validateViewName
} from '../../../components/tn/grid/ViewsGridHeader/Modals/ViewsConfigModal/ViewsConfigModalHelpers';

import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';

import styles from '../../../components/tn/grid/ViewsGridHeader/Modals/ViewsConfigModal/ViewsConfigModal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

export const ViewsConfigModal = ({
  allColumns,
  gridSettingsKey,
  defaultGridConfig,
  isOpen = false,
  onClose
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userGridSettings = useUserGridSettings(gridSettingsKey);
  const isUserGridSettingsUpdating = useIsUserGridSettingsUpdating(gridSettingsKey);
  const defaultColumnsLabels = allColumns
    .filter(column => (defaultGridConfig?.views?.Default.columns || []).includes(column.key))
    .map(column => column.label);

  const [includedColumns, setIncludedColumns] = useState([]);
  const [newViewName, setNewViewName] = useState('');
  const [newUserGridSettings, setNewUserGridSettings] = useState(null);

  const updateGroupBy = groupBy => {
    if (newUserGridSettings) {
      let updatedNewUserGridSettings = JSON.parse(JSON.stringify(newUserGridSettings)); // deep copy
      let viewConfig = updatedNewUserGridSettings.views[updatedNewUserGridSettings.selectedView];
      viewConfig.groupBy = groupBy;
      setNewUserGridSettings(updatedNewUserGridSettings);
    }
  };

  const handleDeleteClick = () => {
    // Show confirmation dialog first
    confirmationModal(
      `${t('Common.DeleteButton')} ${newViewName}`,
      t('Tracking.ViewsConfig.SureDeleteView', { name: newViewName }),
      t('Common.DeleteButton'),
      t('Common.CancelButton'),
      handleDeleteAction,
      'delete'
    );
  };

  const handleDeleteAction = () => {
    // remove view from userGridSettings and save
    const deletedView = newUserGridSettings.selectedView;
    delete newUserGridSettings.views[deletedView];

    // Get new selected view
    newUserGridSettings.selectedView = DEFAULT_VIEW_NAME;
    if (newUserGridSettings.views) {
      const views = Object.keys(newUserGridSettings.views);
      if (views.length > 0) {
        const sortedViews = views.sort((a, b) => sortStrings(a, b));
        newUserGridSettings.selectedView = sortedViews[0];
      }
    }

    dispatch(
      updateUserGridSettings(
        newUserGridSettings,
        gridSettingsKey,
        () => handleDeleteSuccess(deletedView),
        false
      )
    );
  };

  const handleDeleteSuccess = deletedView => {
    onClose();

    // show toast once delete is complete
    dispatch(
      openToast({
        type: ToastType.Success,
        message: t('Tracking.ViewsConfig.ViewDeleteSuccess', { name: deletedView })
      })
    );
  };

  const handleCancel = () => {
    // Todo: Warn user if there are unsaved changes

    onClose();
  };

  const onViewwConfigModalSave = () => {
    // update included column keys before saving
    let viewConfig = newUserGridSettings.views[newUserGridSettings.selectedView];
    viewConfig.includedColumns = includedColumns.map(col => ({ key: col.key, width: col.width }));

    // Save old columns schema for backwards compatibility
    viewConfig.columns = includedColumns.map(c => c.key);

    dispatch(updateUserGridSettings(newUserGridSettings, gridSettingsKey, onClose, true));
  };

  const onResetSelection = () => {
    const defaultColumnKeys =
      allColumns.filter(column => column.key !== 'actions').map(column => column.key) || [];
    const defaultColumns = allColumns.filter(column => defaultColumnKeys.includes(column.key));

    setIncludedColumns(defaultColumns);
  };

  useEffect(() => {
    if (userGridSettings && userGridSettings.lastFetched) {
      const columnSettings = getIncludedAndAvailableColumns(userGridSettings, allColumns, t);
      setIncludedColumns(columnSettings.includedColumns);

      // Make a copy of the config from the API call for editing
      let newConfig;
      if (!userGridSettings.config) {
        newConfig = JSON.parse(JSON.stringify(defaultGridConfig)); // deep copy
      } else {
        newConfig = JSON.parse(JSON.stringify(userGridSettings.config)); // deep copy
      }
      setNewUserGridSettings(newConfig);

      const viewName = newConfig?.selectedView;
      setNewViewName(viewName);
    }
  }, [userGridSettings]);

  const isDeleteButtonVisible = newUserGridSettings?.views
    ? Object.keys(newUserGridSettings.views).length > 1
    : false;

  const groupBy = newUserGridSettings
    ? newUserGridSettings.views[newUserGridSettings.selectedView].groupBy
    : [];

  return (
    <Modal
      title={t('SupportToolsELD.Edit')}
      open={isOpen}
      centered={true}
      closable={false}
      width={500}
      footer={null}
      wrapClassName={styles.viewsConfigDialog}
    >
      <>
        <GridColumnEditor
          allColumns={allColumns}
          includedColumns={includedColumns}
          setIncludedColumns={setIncludedColumns}
          groupBy={groupBy}
          updateGroupBy={updateGroupBy}
          onResetSelection={onResetSelection}
          defaultColumnsLabels={defaultColumnsLabels}
        />
        <Row wrap={false}>
          <Col span={24} style={{ display: 'flex' }}>
            <Button
              type="primary"
              size="middle"
              loading={isUserGridSettingsUpdating}
              className={styles.saveButton}
              onClick={onViewwConfigModalSave}
              id={BUTTON_IDS.saveViewsConfig}
            >
              {t('SupportToolsELD.SaveConfiguration')}
            </Button>
            <Button
              size="medium"
              id={BUTTON_IDS.cancelViewsConfig}
              className={styles.cancelButton}
              onClick={handleCancel}
            >
              {t('Common.Cancel')}
            </Button>
            {isDeleteButtonVisible && (
              <Button
                size="medium"
                danger
                className={styles.deleteButton}
                onClick={handleDeleteClick}
                id={BUTTON_IDS.deleteViewsConfig}
              >
                {t('Tracking.ViewsConfig.DeleteView')}
              </Button>
            )}
          </Col>
        </Row>
      </>
    </Modal>
  );
};
