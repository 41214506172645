import React from 'react';
import { CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import { format } from 'utils/dates';
import { Link } from 'react-router-dom';
import { EllipsisButton } from 'components/tables/EllipsisButton';
import { canLogInAsUser } from './helpers';
import i18next from 'i18next';

export const detailsCellRenderer = ({
  rowIndex,
  rowData,
  dataKey,
  parent,
  companies,
  filterTab,
  showTachoColumn,
  localization
}) => {
  const user = rowData;
  const cellCache = showTachoColumn ? tachoCellCache : cache;
  const company = companies.find(comp => comp.id === user.companyId);
  const companyName = company ? company.name : '';
  const roles = (user.roles || []).map((role, i) => (
    <div key={`row-${dataKey}-${user.id}-${i}`}>
      {`${role.name} (${role.global ? i18next.t('Users.Table.Global') : companyName})`}
      {i < user.roles.length - 1 ? ',' : ''}
    </div>
  ));
  let ruleset;
  if (user.rulesets && user.rulesets.length > 0) {
    const rule = user.rulesets[0];
    ruleset = `${rule.ruleset} | ${format(
      new Date(rule.enabledAt),
      localization.formats.time.formats.dby_imp
    )}`;
  }
  return (
    <CellMeasurer
      cache={cellCache}
      columnIndex={0}
      key={`cell-measurer-${dataKey}-${user.id}`}
      parent={parent}
      rowIndex={rowIndex}
    >
      <div
        style={{
          paddingBottom: '15px'
        }}
      >
        <div
          style={{
            fontWeight: 'bold'
          }}
        >
          {i18next.t('Users.Table.Company')}
        </div>
        <div>{companyName}</div>
        {roles.length > 0 && (
          <>
            <div
              style={{
                fontWeight: 'bold',
                paddingTop: '15px'
              }}
            >
              {i18next.t('Users.Table.Roles')}
            </div>
            <div>{roles}</div>
          </>
        )}
        {filterTab === 'sentinel' && (
          <>
            <div
              style={{
                fontWeight: 'bold',
                paddingTop: '15px'
              }}
            >
              {i18next.t('Users.Table.Ruleset')}
            </div>
            <div>{ruleset}</div>
          </>
        )}
      </div>
    </CellMeasurer>
  );
};
export const licenceCellRenderer = ({ rowData }) => {
  const user = rowData;
  let licence = '';
  if (user?.licenceState && user?.licenceNumber && user?.type?.name === 'Driver') {
    licence = `${user.licenceState} | ${user.licenceNumber}`;
  }
  return <div>{licence}</div>;
};
export const fullNameCellRenderer = ({ rowData, canViewUser }) => {
  const user = rowData;
  const fullName = `${user.firstName} ${user.lastName}`;
  const email = user.email;
  const userName = `(${user.username})`;
  return (
    <>
      {canViewUser ? (
        <Link
          to={{
            pathname: `/settings/users/id/${rowData.id}`,
            state: { pageTitle: fullName }
          }}
        >
          <div>{fullName}</div>
        </Link>
      ) : (
        <div>{fullName}</div>
      )}
      <div
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {email}
      </div>
      <div>{userName}</div>
    </>
  );
};

export const branchCellRenderer = branch => {
  return (
    <div
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      {branch && branch?.id !== -1 ? branch.name : i18next.t('Users.NoBranch')}
    </div>
  );
};

export const cache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 72
});

export const ellipsisButtonCellRenderer = ({
  dataKey,
  typeOfEntityToDelete,
  handleDeleteAction,
  rowData,
  hideEdit,
  hideDelete,
  handleAction,
  enableRestoreAction,
  loggedInUserInfo,
  companies,
  handleLogInAsUser,
  hasSiteAdminOrResellerRole
}) => {
  const company = companies.find(comp => comp.id === rowData?.companyId);
  const isELDCompany = company?.features?.find(f => f.code === 'ELD') ? true : false;
  const canEnableLoginAsUser = canLogInAsUser(
    rowData,
    loggedInUserInfo,
    isELDCompany,
    hasSiteAdminOrResellerRole
  );

  return (
    <EllipsisButton
      dataKey={dataKey}
      path={'/settings/users/'}
      canUse="USER"
      typeOfEntityToDelete={typeOfEntityToDelete}
      data={rowData}
      hideDelete={hideDelete}
      hideEdit={hideEdit}
      enableRestoreAction={enableRestoreAction}
      handleAction={handleAction}
      handleDeleteAction={handleDeleteAction}
      canEnableLoginAsUser={canEnableLoginAsUser}
      handleLogInAsUser={handleLogInAsUser}
    />
  );
};

export const tachoCellCache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 178
});

export const tachoCardCellRender = ({ rowData, tachoData, localization }) => {
  const cardInfo =
    tachoData && tachoData[rowData.companyId] && tachoData[rowData.companyId][rowData.id];
  if (cardInfo == null) {
    return <label>{i18next.t('Users.Table.NoCardAssociated')}</label>;
  }
  const display_data = {
    lastState:
      (cardInfo && cardInfo.fatigueStatus && cardInfo.fatigueStatus['activity']) || 'Unknown',
    lastActivity:
      (cardInfo &&
        cardInfo.fatigueStatus &&
        cardInfo.fatigueStatus['event_at'] &&
        format(
          new Date(cardInfo.fatigueStatus['event_at']),
          localization.formats.time.formats.dby_imp
        )) ||
      'Unknown',
    cardIssueDate:
      (cardInfo &&
        cardInfo.card &&
        cardInfo.card['subscription_start'] &&
        format(
          new Date(cardInfo.card['subscription_start']),
          localization.formats.time.formats.dby
        )) ||
      'Unknown',
    cardExpiryDate:
      (cardInfo &&
        cardInfo.card &&
        cardInfo.card['subscription_end'] &&
        format(
          new Date(cardInfo.card['subscription_end']),
          localization.formats.time.formats.dby
        )) ||
      'Unknown',
    cardNumber: (cardInfo && cardInfo.card && cardInfo.card['card_number']) || 'Unknown'
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '144px' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div flex={'1 0 0'}>
          <label style={{ fontWeight: 'bold' }}>{i18next.t('Users.Table.LastState')}</label>
          <p>{display_data.lastState}</p>
        </div>
        <div flex={'1 0 0'}>
          <label style={{ fontWeight: 'bold' }}>{i18next.t('Users.Table.LastActivity')}</label>
          <p>{display_data.lastActivity}</p>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div flex={'1 0 0'}>
          <label style={{ fontWeight: 'bold' }}>{i18next.t('Users.Table.IssueDate')}</label>
          <p>{display_data.cardIssueDate}</p>
        </div>
        <div flex={'1 0 0'}>
          <label style={{ fontWeight: 'bold' }}>{i18next.t('Users.Table.ExpiryDate')}</label>
          <p>{display_data.cardExpiryDate}</p>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div flex={'1 0 0'}>
          <label style={{ fontWeight: 'bold' }}>{i18next.t('Users.Table.CardNumber')}</label>
          <p>#{display_data.cardNumber}</p>
        </div>
      </div>
    </div>
  );
};
