import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Radio, Input, Spin } from 'antd';

import AlertSelect from 'components/alertSelect/AlertSelect';
import AntFormInput from 'components/AntFormInput/AntFormInput';

import {
  getMeterConfigurationOptions,
  getMeterTypeOptions,
  getMeterConfigurationProperties,
  getSpinDynamicStyle
} from './helpers';
import { OPERATION, TYPE } from './constants';

import styles from './MeterConfiguration.module.scss';

const MeterConfiguration = ({
  properties,
  options,
  onChange,
  setIsDirty,
  alertState,
  isLoading,
  action
}) => {
  const { name, label, labelCol, wrapperCol, placeholder, rules } = properties;
  const { DeviceMeters = [], VehicleMeters } = options;
  const { meterType, meterEntityType, deviceType } = alertState;
  const {
    meterTypeProperties,
    deviceTypeProperties,
    sourceProperties,
    operationProperties,
    valueProperties
  } = getMeterConfigurationProperties(properties, label, placeholder, name, rules);

  const { t } = useTranslation();

  const [deviceMetersOptions, setDeviceMetersOptions] = useState();
  const [vehicleMetersOptions, setVehicleMetersOptions] = useState();

  const handleTypeChange = e => {
    setIsDirty(true);
    onChange.meterType();
    onChange.deviceType();

    onChange.meterEntityType(e.target.value);
  };

  const handleDeviceTypeChange = value => {
    setIsDirty(true);
    onChange.source();
    onChange.operation();
    onChange.meterType();
    onChange.meterValue({ target: { value: undefined } });
    onChange.deviceType(value);
  };

  const handleMeterTypeChange = value => {
    setIsDirty(true);
    onChange.source();
    onChange.operation();
    onChange.meterValue({ target: { value: undefined } });
    onChange.meterType(value);
  };

  useEffect(() => {
    if (DeviceMeters?.length) {
      const deviceMetersSet = new Map();

      DeviceMeters.forEach(meter => {
        const meterTypes = new Map();
        if (meter?.types) {
          meter.types.forEach(meter => meterTypes.set(meter?.type, meter?.sources));
          deviceMetersSet.set(meter?.deviceType, meterTypes);
        }
      });

      setDeviceMetersOptions(deviceMetersSet);
    }

    if (VehicleMeters?.length) {
      const vehicleMetersSet = new Map();

      VehicleMeters.forEach(meter => vehicleMetersSet.set(meter?.type, meter?.sources));
      setVehicleMetersOptions(vehicleMetersSet);
    }
  }, [DeviceMeters, VehicleMeters]);

  return (
    <Spin spinning={isLoading} style={getSpinDynamicStyle(action)}>
      {(DeviceMeters?.length || VehicleMeters?.length) && (
        <Form.Item
          label={label.type}
          name={name.type}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
          rules={rules.type}
        >
          <Radio.Group onChange={handleTypeChange}>
            {DeviceMeters?.length && <Radio value={TYPE.DEVICE}>{t('Common.Device')}</Radio>}
            {VehicleMeters?.length && <Radio value={TYPE.VEHICLE}>{t('Common.Vehicle')}</Radio>}
          </Radio.Group>
        </Form.Item>
      )}
      {meterEntityType === TYPE.DEVICE && (
        <AlertSelect
          properties={deviceTypeProperties}
          options={DeviceMeters.map(meter => ({
            id: meter.deviceType,
            value: meter.deviceType,
            label: meter.deviceType
          }))}
          onChange={handleDeviceTypeChange}
          setIsDirty={setIsDirty}
        />
      )}
      {(meterEntityType === TYPE.VEHICLE || deviceType) && (
        <AlertSelect
          properties={meterTypeProperties}
          options={getMeterTypeOptions(
            deviceType,
            meterEntityType,
            meterEntityType === TYPE.VEHICLE ? vehicleMetersOptions : deviceMetersOptions
          )}
          onChange={handleMeterTypeChange}
          setIsDirty={setIsDirty}
        />
      )}
      {meterType && (
        <Form.Item
          name={name.meterConfiguration}
          label={label.meterConfiguration}
          className={styles.inputGroupContainer}
        >
          <Input.Group>
            <AlertSelect
              properties={sourceProperties}
              options={getMeterConfigurationOptions(
                deviceType,
                meterType,
                meterEntityType,
                deviceMetersOptions,
                vehicleMetersOptions
              )}
              onChange={onChange.source}
              noStyle
              setIsDirty={setIsDirty}
            />
            <AlertSelect
              properties={operationProperties}
              options={OPERATION}
              onChange={onChange.operation}
              noStyle
              setIsDirty={setIsDirty}
            />

            <AntFormInput
              properties={valueProperties}
              onChange={onChange.meterValue}
              noStyle
              setIsDirty={setIsDirty}
            />
          </Input.Group>
        </Form.Item>
      )}
    </Spin>
  );
};

export default MeterConfiguration;
