import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/fontawesome-free-regular';
import { useTranslation } from 'react-i18next';
import styles from './FootagesCountBadge.module.scss';

export function FootageCountBadge({ event, ...props }) {
  const { t } = useTranslation();

  if (!event?.attachmentCount || event.attachmentCount <= 1) {
    return <></>;
  }

  return (
    <div className={styles.footageBadge}>
      <Tooltip placement="top" title={t('Events.FootagesCount', { count: event.attachmentCount })}>
        <span>{event.attachmentCount}</span>
        <FontAwesomeIcon icon={faImages} size="xs" />
      </Tooltip>
    </div>
  );
}
