import React from 'react';
import { CellMeasurerCache } from 'react-virtualized';
import { NavLink } from 'react-router-dom';
import styles from '../Overview.module.scss';
import styles2 from 'components/tn/grid/grid.module.scss';
import * as DriveTimeRenderer from './driveTimeCellRenderer';
import * as OnDutyTimeRenderer from './onDutyTimeCellRenderer';
import * as CycleTimeRenderer from './cycleTimeLeftRenderer';
import { DriverOtherStatusId } from '../../Constants';
import { Tooltip } from 'antd';
import i18next from 'i18nextConfig';

export const OverviewCellCache = new CellMeasurerCache({
  fixedHeight: false,
  fixedWidth: true,
  minWidth: 40,
  minHeight: 64
});

export function getClassOfDriverState(mode) {
  switch (mode) {
    case 0: //driving
      return { className: styles.driveState, title: i18next.t('ELD.Driving') };
    case 1: //on duty
      return { className: styles.onduty, title: i18next.t('ELD.On Duty') };
    case 3: //sleeper birth
      return { className: styles.sleeperBerth, title: i18next.t('ELD.Sleeper Berth') };
    case 2: //off duty
    default:
      return { className: styles.offDuty, title: i18next.t('ELD.Off Duty') };
  }
}

export function getClassOfViolation(mode) {
  switch (DriverOtherStatusId[mode]) {
    case 0: //risk of violation
      return { className: styles.riskViolation, title: i18next.t('ELD.Risk of Violation') };
    case 1: //in violation
      return { className: styles.inViolation, title: i18next.t('ELD.In Violation') };
    case 2: //under exceptions
      return {
        className: styles.underExceptions,
        title: i18next.t('ELD.Drivers Operating Under HOS Exceptions')
      };
    default:
      return { className: '', title: '' };
  }
}

export function stateCellRenderer(value, rowData, index) {
  const state = getClassOfDriverState(rowData.status);
  const violation = getClassOfViolation(rowData.otherStatus);
  return (
    <Tooltip title={state.title}>
      <div className={state.className}>
        <span></span>
        <span className={violation.className} title={violation.title}></span>
      </div>
    </Tooltip>
  );
}

export function driverCellRenderer(value, rowData, index) {
  if (value) {
    return (
      <div>
        <NavLink
          className={`${styles.link} ${styles2.twoLineEllipsisCell}`}
          title={value}
          to={`/fatigue/eld/driverlog/${rowData?.key}`}
        >
          {value}
        </NavLink>
      </div>
    );
  }
  return <p>{value}</p>;
}

export function normalCellRenderer(value, rowData, index) {
  return (
    <div>
      <span className={styles2.twoLineEllipsisCell} title={value}>
        {value}
      </span>
    </div>
  );
}

export function dutyStatusCellRenderer(value, rowData, index) {
  const DutyStatus = {
    Driving: 'Driving',
    OnDuty: 'On Duty',
    OffDuty: 'Off Duty',
    SleeperBerth: 'Sleeper Berth',
    PersonalConveyance: 'Personal Conveyance',
    YardMove: 'Yard Move',
    AdverseConditions: 'Adverse Conditions',
    IntermediateLog: 'Intermediate Log'
  };
  return DutyStatus[value] || '';
}

export function driveTimeCellRenderer(value, rowData, index) {
  return DriveTimeRenderer.driveTimeCell_Default(rowData);
}

export function onDutyTimeCellRenderer(value, rowData, index) {
  return OnDutyTimeRenderer.onDutyTimeCell_Default(rowData);
}

export function cycleTimeCellRenderer(value, rowData, index) {
  return CycleTimeRenderer.cycleTimeCell_Default(rowData);
}

export function fleetCellRenderer(value, rowData, index) {
  const fleetTooltip = value;
  let fleetDisplay = value;

  const fleetList = value.split(',');
  if (fleetList.length > 1) {
    fleetDisplay = `${fleetList[0]}\n${fleetList[1]}`;
  }

  if (fleetList.length > 2) {
    fleetDisplay += ` ... (${fleetList.length} ${i18next.t('fleets')})`;
  }
  return (
    <span
      title={fleetTooltip}
      className={styles2.twoLineEllipsisCell}
      style={{ whiteSpace: 'pre-line' }}
    >
      {fleetDisplay}
    </span>
  );
}
