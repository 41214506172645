import React from 'react';
import styles from './SubTopNav.module.scss';

const SubTopNav = ({ left, right }) => {
  const LeftComponent = left;
  const RightComponent = right;
  return (
    <div className={styles.Wrapper}>
      <div>{left && <LeftComponent />}</div>
      <div>{right && <RightComponent />}</div>
    </div>
  );
};

export default SubTopNav;
