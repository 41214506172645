import { CellMeasurerCache } from 'react-virtualized';
import { Link } from 'react-router-dom';
import { Space, Tooltip } from 'antd';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DUTY_TYPE } from '../helper';
import { Can, FeatureFlag } from 'features/permissions';
import entities from 'features/permissions/entities';

export const BULK_DUTY_TYPE = {
  all: 'all',
  ...DUTY_TYPE
};

export const vehiclesCache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 75,
  minWidth: 60
});

export const devicesCache = new CellMeasurerCache({
  fixedWidth: true,
  fixedHeight: false,
  minHeight: 75,
  minWidth: 60
});

export const FLEET = {
  ALL: { id: 0, name: 'Common.AllFleets' },
  NO_FLEET: { id: -1, name: 'Common.NoFleet' }
};
export const TYPE = {
  ALL: { id: 0, name: 'Common.AllTypes' }
};

const FleetsCell = ({ fleetNames = [] }) => (
  <>
    {fleetNames.map((fleetName, index) => (
      <div key={`fleetName-${index}`}>{fleetName}</div>
    ))}
  </>
);

const EntityNameCell = ({
  entityName,
  isUpdatedFailed = false,
  failedMessage = '',
  entityId,
  Wrapper = ({ children }) => children
}) => {
  return (
    <Wrapper entityId={entityId}>
      <Space>
        <span>{entityName}</span>
        {isUpdatedFailed && (
          <Tooltip title={<div>{failedMessage}</div>} placement="rightTop">
            <FontAwesomeIcon style={{ fontSize: '18px', color: '#ffbf00' }} icon={faInfoCircle} />
          </Tooltip>
        )}
      </Space>
    </Wrapper>
  );
};

export const CameraSensitivityOptions = [
  {
    id: 3,
    value: 'heavy',
    label: 'CompanyConfig.IQCamera.heavy'
  },
  {
    id: 2,
    value: 'medium',
    label: 'CompanyConfig.IQCamera.medium'
  },
  {
    id: 1,
    value: 'light',
    label: 'CompanyConfig.IQCamera.light'
  }
];

export const ENTITY_TABS = {
  VEHICLE: {
    label: 'Common.Vehicles',
    entity: 'VEHICLE',
    cols: [
      {
        label: 'Vehicles.Table.Name',
        width: 0.15,
        dataKey: 'name',
        sortable: true,
        getValue(cellProps, updatedFailedInfo, Wrapper) {
          return (
            <EntityNameCell
              entityName={cellProps?.cellData}
              entityId={cellProps?.rowData?.iqCamera?.id}
              Wrapper={Wrapper}
              isUpdatedFailed={!!updatedFailedInfo?.isUpdatedFailed}
              failedMessage={updatedFailedInfo?.failedMessage}
            />
          );
        }
      },
      {
        label: 'Vehicles.Table.Fleets',
        width: 0.2,
        dataKey: 'fleets',
        getValue(cellProps) {
          return <FleetsCell fleetNames={cellProps.rowData?.fleetNames || []} />;
        }
      },
      {
        label: 'Vehicles.Table.Registration',
        width: 0.1,
        dataKey: 'registration',
        sortable: true,
        getValue(cellProps) {
          return cellProps?.cellData;
        }
      },
      {
        label: 'Vehicles.Table.Type',
        width: 0.1,
        dataKey: 'type',
        sortable: true,
        sortBy(vehicle) {
          return vehicle?.type?.name?.toUpperCase();
        },
        getValue(cellProps) {
          const vehicle = cellProps.rowData;
          return vehicle?.type?.name;
        }
      },
      {
        label: 'Entity.Device',
        width: 0.15,
        dataKey: 'device',
        sortable: true,
        sortBy(vehicle) {
          return vehicle?.iqCamera?.name?.toUpperCase();
        },
        getValue(cellProps) {
          const vehicle = cellProps.rowData;
          return (
            <Can
              oneOfEntities={entities.DEVICE_VIEW}
              onFail={<span>{vehicle?.iqCamera?.name || ''}</span>}
            >
              <Link to={`/settings/devices/id/${vehicle?.iqCamera?.id}`}>
                {vehicle?.iqCamera?.name || ''}
              </Link>
            </Can>
          );
        }
      },
      {
        label: 'Devices.DeviceModel',
        width: 0.1,
        dataKey: 'deviceModel',
        sortable: true,
        sortBy(vehicle) {
          return vehicle?.iqCamera?.model?.name?.toUpperCase();
        },
        getValue(cellProps) {
          const vehicle = cellProps.rowData;
          return vehicle?.iqCamera?.model?.name || '';
        },
        can: {
          oneOfFeatureFlags: [FeatureFlag.evoPhase1.flag, FeatureFlag.evoPhaseTGE.flag]
        }
      },
      {
        label: 'Devices.ActualForm.CameraSensitivity',
        width: 0.2,
        dataKey: 'cameraSensitivity',
        sortable: true,
        getValue(cellProps) {
          return cellProps?.cellData;
        },
        formFieldProps: {
          getFieldName(cellProps, entityTab) {
            if (entityTab === 'VEHICLE' && cellProps.rowData.iqCamera?.id) {
              return ['VEHICLE', cellProps.rowData.iqCamera.id, 'cameraSensitivity'];
            }
          },
          sortBy(fieldValue) {
            return CameraSensitivityOptions.find(opt => opt.value === fieldValue)?.id;
          },
          options: ({ t }) =>
            CameraSensitivityOptions.map(opt => ({
              ...opt,
              label: t(opt.label)
            }))
        }
      }
    ]
  },
  DEVICE: {
    label: 'Common.Devices',
    entity: 'DEVICE',
    cols: [
      {
        label: 'Devices.Table.NameLabel',
        width: 0.2,
        dataKey: 'name',
        sortable: true,
        getValue(cellProps, updatedFailedInfo, Wrapper) {
          return (
            <EntityNameCell
              entityName={cellProps?.cellData}
              entityId={cellProps?.rowData?.id}
              Wrapper={Wrapper}
              isUpdatedFailed={!!updatedFailedInfo?.isUpdatedFailed}
              failedMessage={updatedFailedInfo?.failedMessage}
            />
          );
        }
      },
      {
        label: 'Vehicles.Table.Fleets',
        width: 0.2,
        dataKey: 'fleets',
        getValue(cellProps) {
          return <FleetsCell fleetNames={cellProps.rowData?.fleetNames || []} />;
        }
      },
      {
        label: 'Common.Model',
        width: 0.1,
        dataKey: 'deviceModel',
        sortable: true,
        sortBy(iqCamera) {
          return iqCamera?.model?.name?.toUpperCase();
        },
        getValue(cellProps) {
          return cellProps.rowData?.model?.name;
        },
        can: {
          oneOfFeatureFlags: [FeatureFlag.evoPhase1.flag, FeatureFlag.evoPhaseTGE.flag]
        }
      },
      {
        label: 'Devices.Table.ImeiLabel',
        width: 0.15,
        dataKey: 'imei',
        sortable: true,
        getValue(cellProps) {
          return cellProps?.cellData;
        }
      },
      {
        label: 'Devices.ActualForm.SerialNumberLabel',
        width: 0.15,
        dataKey: 'serialNumber',
        sortable: true,
        getValue(cellProps) {
          return cellProps?.cellData;
        }
      },
      {
        label: 'Devices.ActualForm.CameraSensitivity',
        width: 0.2,
        dataKey: 'cameraSensitivity',
        sortable: true,
        getValue(cellProps) {
          return cellProps?.cellData;
        },
        formFieldProps: {
          getFieldName(cellProps, entityTab) {
            if (entityTab === 'DEVICE' && cellProps.rowData.id) {
              return ['DEVICE', cellProps.rowData.id, 'cameraSensitivity'];
            }
          },
          sortBy(fieldValue) {
            return CameraSensitivityOptions.find(opt => opt.value === fieldValue)?.id;
          },
          options: ({ t }) =>
            CameraSensitivityOptions.map(opt => ({
              ...opt,
              label: t(opt.label)
            }))
        }
      }
    ]
  }
};

export const toIQCameraBulkUpdatePayload = flattenedChangedConfig => {
  const devices = Object.keys(flattenedChangedConfig)
    .map(key => ({
      device: { id: key.match(/\w+\.(.+)\.\w+/)?.[1] },
      service: 'IQ',
      key: 'dutyType',
      value: flattenedChangedConfig[key]
    }))
    .filter(d => !!d.device.id);
  return devices;
};
