import React, { useState } from 'react';
import { Modal, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Paths } from './utils/constants';

const RescheduleModal = ({ scheduleId, show, updateRescheduleModalVisibility, from }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedValue, setSelectedValue] = useState('current');

  const onChangeRescheduleRadio = e => {
    setSelectedValue(e.target.value);
  };

  const handleReschedule = () => {
    switch (selectedValue) {
      case 'current':
        history.push(`${Paths.VEHICLEMAINTENANCE_RESCHEDULE}/current/id/${scheduleId}`, {
          navigationFrom: from
        });
        break;
      case 'scheduled':
        history.push(`${Paths.VEHICLEMAINTENANCE_RESCHEDULE}/scheduled/id/${scheduleId}`, {
          navigationFrom: from
        });
        break;
      case 'completed':
        history.push(`${Paths.VEHICLEMAINTENANCE_RESCHEDULE}/completed/id/${scheduleId}`, {
          navigationFrom: from
        });
        break;
      default:
        break;
    }
  };

  const handleCancel = () => {
    updateRescheduleModalVisibility(false);
    if (from && from === 'completeSchedule') {
      history.push(Paths.VEHICLEMAINTENANCE_SCHEDULES);
    }
  };

  return (
    <Modal
      title={t('VehicleMntSchedules.View.RescheduleMntService')}
      open={show}
      onOk={handleReschedule}
      onCancel={handleCancel}
      okText={t('VehicleMntSchedules.View.Reschedule')}
      cancelText={t('Common.Cancel')}
    >
      <div>
        <div>{t('VehicleMntSchedules.View.CanReschedule')}</div>
        <Radio.Group onChange={e => onChangeRescheduleRadio(e)} defaultValue="current">
          <Radio style={radioStyle} value={'current'}>
            {t('VehicleMntSchedules.View.CurrentState')}
          </Radio>
          <Radio style={radioStyle} value={'scheduled'}>
            {t('VehicleMntSchedules.View.ScheduledState')}
          </Radio>
          <Radio style={radioStyle} value={'completed'}>
            {t('VehicleMntSchedules.View.CompletedState')}
          </Radio>
        </Radio.Group>
      </div>
    </Modal>
  );
};

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
};

export default RescheduleModal;
