import logoTeletracNavmanSm from 'static/images/logo-collapsed.svg';
import logoTeletracNavmanLg from 'static/images/logo.svg';
import logoTeletracNavmanLogin from 'static/images/tn-logo.png';

import logoOpticomSm from 'static/images/brands/opticom/opticom-360-logo-sm.svg';
import logoOpticomLg from 'static/images/brands/opticom/opticom-360-logo-lg.svg';
import logoOpticomLogin from 'static/images/brands/opticom/opticom-360-login-67x60.png';

import logoReflexSm from 'static/images/brands/reflex/reflex-symbol-mono-light.svg';
import logoReflexLg from 'static/images/brands/reflex/reflex-logo-mono-light.svg';
import logoReflexLogin from 'static/images/brands/reflex/reflex-logo-colored-dark.svg';

import logomJobTimeSm from 'static/images/brands/mjobtime/small-logo.svg';
import logomJobTimeLg from 'static/images/brands/mjobtime/large-logo.svg';

import logoGoCodesSm from 'static/images/brands/goCodes/GoCodes-small.svg';
import logoGoCodesLg from 'static/images/brands/goCodes/GoCodes-large.svg';

import logoEkosSm from 'static/images/brands/ekos/EKOS Logo - small.svg';
import logoEkosLgWhite from 'static/images/brands/ekos/EKOS Logo - White Text.svg';
import logoEkosLgBlack from 'static/images/brands/ekos/EKOS Logo - Black Text.svg';

export const Brands = {
  telematics: {
    name: 'telematics',
    company: 'Teletrac Navman',
    product: 'TN360',
    showProductName: true,
    domains: [
      'tn360-au.telematics.com',
      'tn360-us.telematics.com',
      'tn360-mx.telematics.com',
      'tn360-uk.telematics.com',
      'localhost.telematics.com'
    ],
    favicon: 'tn_favicon_32x32.png',
    logos: {
      sm: logoTeletracNavmanSm,
      lg: logoTeletracNavmanLg,
      login: {
        source: logoTeletracNavmanLogin,
        width: 67,
        height: 60
      }
    }
  },
  opticom: {
    name: 'opticom',
    company: 'Opticom',
    product: 'Opticom 360',
    showProductName: false,
    domains: ['qa-opticom360-us.telematics.com', 'opticom360-us.telematics.com'],
    favicon: 'opticom-360-16x16-favicon.png',
    helpCenterUrl: 'https://learn.teletracnavman.net/Help/Opticom360/en-US/Default.htm',
    video: 'https://tnus-video-demo.s3-us-west-2.amazonaws.com/TK_Green_light_2_of_2.mp4',
    logos: {
      sm: logoOpticomSm,
      lg: logoOpticomLg,
      login: {
        source: logoOpticomLogin,
        width: 101,
        height: 90
      }
    },
    refresh: {
      tracking: -1
    }
  },
  reflex: {
    name: 'reflex',
    company: 'Reflex',
    product: 'Reflex Vehicle Hire',
    showProductName: false,
    domains: ['qa-reflex.telematics.com', 'reflex.telematics.com'],
    favicon: 'reflex-symbol-colored.svg',
    logos: {
      sm: logoReflexSm,
      lg: logoReflexLg,
      login: {
        source: logoReflexLogin,
        width: 244,
        height: 72
      }
    },
    refresh: {
      tracking: -1
    }
  },
  mjobtime: {
    name: 'mJobTime',
    company: 'mJobTime',
    product: 'mJobTime',
    showProductName: false,
    domains: ['qa-mjobtime.telematics.com', 'mjobtime.telematics.com'],
    favicon: 'mjobtime-favicon.svg',
    logos: {
      sm: logomJobTimeSm,
      lg: logomJobTimeLg,
      login: {
        source: logomJobTimeLg,
        width: 244,
        height: 105
      }
    },
    refresh: {
      tracking: -1
    }
  },
  goCodes: {
    name: 'goCodes',
    company: 'GoCodes',
    product: 'GoCodes',
    showProductName: false,
    domains: ['qa-gocodes.telematics.com', 'gocodes.telematics.com'],
    favicon: 'gocodes-favicon.svg',
    logos: {
      sm: logoGoCodesSm,
      lg: logoGoCodesLg,
      login: {
        source: logoGoCodesLg,
        width: 244,
        height: 105
      }
    },
    refresh: {
      tracking: -1
    }
  },
  ekos: {
    name: 'ekos',
    company: 'EKOS',
    product: 'EKOS',
    showProductName: false,
    domains: ['qa-ekos.telematics.com', 'ekos.telematics.com'],
    favicon: 'ekos-favicon.svg',
    logos: {
      sm: logoEkosSm,
      lg: logoEkosLgWhite,
      login: {
        source: logoEkosLgBlack,
        width: 244,
        height: 105
      },
      smLeftNavLogo: {
        paddingLeft: 11.5
      }
    },
    refresh: {
      tracking: -1
    }
  }
};
