import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import {
  clearEditFiles,
  removeUploadedFile,
  useGetFiles,
  useGetIsFormDirty,
  useIsFileSaveSuccess,
  useUploadDocumentsStep,
  changeDocumentStep,
  changeUploadCompanyId
} from 'features/easydocs/documentsSlice';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { UploadFilesForm } from './UploadFilesForm';
import { EditFilesForm } from './EditFilesForm';
import styles from './FormModal.module.scss';
import './FormModal.scss';
import i18next from 'i18next';
import { BUTTON_IDS } from 'utils/globalConstants';

const renderForm = props => ({
  1: <UploadFilesForm companies={props.companies} />,
  2: <EditFilesForm {...props} />
});

export const FormModal = props => {
  const dispatch = useDispatch();
  const step = useUploadDocumentsStep();
  const hasSavedFiles = useIsFileSaveSuccess();
  const isFormDirty = useGetIsFormDirty();
  const formRenderer = props => renderForm(props)[step];
  const uploadFiles = useGetFiles();
  const buttonClasses = `cancelBtn ${step === 1 && 'leftCancelBtn'}`;
  const { folders, companies, deleteDataOnExit, onHide } = props;
  const successfulFiles = uploadFiles.filter(f => f.success);

  const CONFIRM_MODAL_MESSAGES = {
    UPLOAD: `${i18next.t('Easydocs.Notifications.SureUpload')}`,
    EDIT: `${i18next.t('Easydocs.Notifications.SureEdit')}`
  };

  useEffect(() => {
    if (hasSavedFiles) props.onHide();
  }, [hasSavedFiles]);

  const handleClose = () => {
    // @TODO - replace this prop with isEdit or editMode
    const isEditModal = !deleteDataOnExit;

    (isFormDirty && isEditModal) || (successfulFiles?.length && !isEditModal)
      ? confirmationModal(
          i18next.t('Easydocs.Notifications.AreYouSure'),
          !isEditModal ? CONFIRM_MODAL_MESSAGES.UPLOAD : CONFIRM_MODAL_MESSAGES.EDIT,
          i18next.t('Common.Modal.CancelChanges'),
          i18next.t('Common.Modal.Stay'),
          handleConfirmClose
        )
      : handleConfirmClose();
  };

  const handleConfirmClose = () => {
    const { onHide, deleteDataOnExit } = props;
    if (!onHide) {
      return;
    }
    // Delete an attached files
    if (uploadFiles && uploadFiles.length && deleteDataOnExit) {
      successfulFiles.forEach(file => dispatch(removeUploadedFile(file.documentId)));
    }
    onHide();

    dispatch(clearEditFiles());
    dispatch(changeDocumentStep(1));
    dispatch(changeUploadCompanyId(null));
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="form-modal-container"
        dialogClassName="form-modal"
        centered
        onHide={handleClose}
        className={step === 2 ? 'modal-edit-easydocs' : ''}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body id="form-modal-body">{formRenderer({ folders, companies, onHide })}</Modal.Body>

        <Modal.Footer className={styles.modalFooter}>
          <Button
            className={buttonClasses}
            id={BUTTON_IDS.easydocsFormModalCancel}
            onClick={handleClose}
          >
            {i18next.t('Easydocs.Cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

FormModal.propTypes = {
  deleteDataOnExit: PropTypes.bool,
  onHide: PropTypes.func
};

FormModal.defaultProps = {
  deleteDataOnExit: true
};
