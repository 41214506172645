import { PAGE_TYPES } from '../constants';

const compareStrings = (a, b) => {
  const stringA = a?.name?.toUpperCase();
  const stringB = b?.name?.toUpperCase();
  if (stringA < stringB) {
    return -1;
  }
  if (stringA > stringB) {
    return 1;
  }

  return 0;
};

export const getDataForEachRow = (listOfAlerts, functions) =>
  listOfAlerts?.entries
    ?.map(alert => {
      return {
        ...alert,
        key: alert.name,
        setIsLoading: functions?.setIsLoading,
        pageType: listOfAlerts?.pageType,
        entityType: listOfAlerts?.entityType,
        ...(listOfAlerts?.pageType === PAGE_TYPES.VIEW && {
          actions: {
            companyId: listOfAlerts?.currentCompanyId,
            dispatch: functions?.dispatch,
            history: functions?.history
          }
        })
      };
    })
    .sort((a, b) => compareStrings(a, b));
