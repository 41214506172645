import { editAlert, fetchAlerts } from 'features/company_alerts';
import { ToastType } from 'components/notifications/toasts/Toast';
import { showAlertToaster } from 'components/baseAlertForm/helpers/handleAPICalls';
import { ENTITIES, PAGE_TYPES } from '../constants';
import { buildRequestPayloadFromState } from 'containers/Configuration/CompanyAlerts/reducer/state/buildRequestPayloadFromState';
import { MPTrackingEvents } from 'features/mixpanel';
import { fetchUserAlerts } from 'features/user_alerts';

export const handleSwitchAPICall = async (alertState, properties) => {
  const { dispatch, current, entityType } = properties;

  const payloadProperties = {
    action: 'edit',
    id: current.companyId,
    entityType: alertState?.entityType,
    currentUser: current.user
  };

  const alertToSend = buildRequestPayloadFromState(
    alertState,
    payloadProperties,
    alertState?.mail || alertState?.desktop || alertState?.phone
  );

  alertState.setIsLoading(true);

  MPTrackingEvents.Settings.Alerts.sendAlerstUpdateEvent(alertToSend, 'edit');
  await dispatch(
    editAlert({
      body: alertToSend,
      successToaster: () =>
        showAlertToaster('edit', ToastType.Success, alertState.name, {}, alertState.entityType),
      errorToaster: err => showAlertToaster('edit', ToastType.Error, alertState.name, err)
    })
  );

  entityType === ENTITIES.USER
    ? await dispatch(
        fetchUserAlerts({
          query: {
            company_id: current.companyId
          }
        })
      )
    : await dispatch(
        fetchAlerts({
          query: {
            entity_type: ENTITIES.COMPANY,
            entity_id: current.companyId
          }
        })
      );

  alertState.setIsLoading(false);
};

export const handleSwitchChange = (row, properties, action) => {
  if (row.pageType === PAGE_TYPES.VIEW) {
    handleSwitchAPICall(row, properties);
  } else {
    properties.onChange(action);
  }
};
