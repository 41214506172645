import { useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { values } from 'lodash';
import { useUserKey } from 'features/user/userSlice';
import { useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import {
  useCompaniesFleet,
  useDeviceStats,
  actionTypes,
  fetchCompanyFleetData,
  fetchDeviceStats
} from 'features/dashboard/dashboardSlice';
import { getFleetsWithDynamicLinkVehicleDevice } from 'features/vehicles/hooks';
import { useVehiclesStats } from 'features/vehicles/vehiclesStatsSlice';
import { isMessagingDevice } from 'containers/Messaging/helpers';

const useCompanyFleetData = () => {
  const dispatch = useDispatch();
  const userKey = useUserKey();

  const currentCompany = useCurrentCompany();
  const companyId = useMemo(() => currentCompany?.id, [currentCompany]);
  const rawCompaniesFleetData = useCompaniesFleet();
  const vehiclesStats = useVehiclesStats();
  const deviceStats = useDeviceStats();

  const companiesFleetData = useMemo(() => {
    const currentCompanyFleetData = currentCompany?.id
      ? {
          [currentCompany.id]: getFleetsWithDynamicLinkVehicleDevice({
            fleets: rawCompaniesFleetData?.data[currentCompany.id] || [],
            vehiclesStats,
            devicesStats: values(deviceStats?.data?.[currentCompany.id] || {})
          })
        }
      : {};
    return {
      ...rawCompaniesFleetData,
      data: {
        ...rawCompaniesFleetData.data,
        ...currentCompanyFleetData
      }
    };
  }, [rawCompaniesFleetData, currentCompany, deviceStats, vehiclesStats]);

  const {
    isLoading,
    companyFleetData,
    shouldFetchDevicesStats,
    shouldFetchFleets
  } = useMemo(() => {
    const authenticated = companyId && userKey;
    const noData = dataSet =>
      !dataSet.status[companyId] ||
      (!dataSet.data[companyId] && !dataSet.status[companyId]?.fetching);
    return {
      isLoading:
        !companiesFleetData.status[companyId] ||
        companiesFleetData.status[companyId].fetching === actionTypes.processing ||
        !deviceStats.status[companyId] ||
        deviceStats.status[companyId].fetching === actionTypes.processing,
      companyFleetData: companiesFleetData.data[companyId] || [],
      shouldFetchDevicesStats: authenticated && noData(deviceStats),
      shouldFetchFleets: authenticated && noData(companiesFleetData)
    };
  }, [companiesFleetData, deviceStats, companyId, userKey]);

  useEffect(() => {
    if (shouldFetchDevicesStats) {
      dispatch(fetchDeviceStats(userKey, companyId, 'users'));
    }
  }, [dispatch, companyId, userKey, shouldFetchDevicesStats]);

  useEffect(() => {
    if (shouldFetchFleets) {
      dispatch(fetchCompanyFleetData(userKey, companyId, 'fleets,vehicles,devices'));
    }
  }, [dispatch, companyId, userKey, shouldFetchFleets]);

  return { isLoading, companyFleetData, companyId, deviceStats };
};

const getEntitiesFromFleetData = ({
  t,
  subCompanies = [],
  showNonCameraVehicle,
  companyFleetData,
  restrictedEntities = [],
  entityNodeMetaFunc = () => ({})
}) => {
  const snapshotsEntities = [];
  const fleets = [];
  const vehiclesAndDevices = [];
  const vehiclesAndDevicesIds = {};
  const entitiesCompanies = [];

  const getValidSubCompany = entityCompanyId => {
    return entitiesCompanies.some(subCompany => String(subCompany?.id) === String(entityCompanyId))
      ? null
      : subCompanies.find(subCompany => String(subCompany?.id) === String(entityCompanyId));
  };

  const isValidFleet = fleet => {
    return (
      restrictedEntities.length === 0 ||
      restrictedEntities.some(({ entity, entityId }) => {
        if (entity === 'vehicle') {
          return fleet?.vehicles?.some(v => String(v?.id) === String(entityId));
        }
        return (
          fleet?.devices?.some(d => String(d?.id) === String(entityId)) ||
          fleet?.vehicles?.some(v => v?.devices?.some(vD => String(vD?.id) === String(entityId)))
        );
      })
    );
  };

  const isValidVehiclesDevices = vdId => {
    return (
      restrictedEntities.length === 0 ||
      restrictedEntities.some(({ entity, entityId }) => {
        if (entity && entityId) {
          return vdId === `${entity}_${entityId}`;
        }
        return true;
      })
    );
  };
  companyFleetData.forEach(f => {
    let fleetHasCameraDevices = false;

    const fleetNode = {
      id: f.id,
      name: f.name,
      fleetHasCameraDevices: false,
      companyId: f.company?.id
    };

    if (!f.id) {
      fleetNode.id = -1;
      fleetNode.name = t('Common.NoFleet');
      fleetNode.companyId = {};
    }

    //iterate vehicles and devices
    if (f.vehicles) {
      f.vehicles?.forEach(v => {
        if (!v.id) {
          //pure devices
          if (v.devices) {
            v.devices.forEach(vd => {
              if (showNonCameraVehicle || vd.type.name === 'Camera') {
                fleetHasCameraDevices = true;
                const vdId = 'device_' + vd.id;

                if (vehiclesAndDevicesIds[vdId]) {
                  const existing = vehiclesAndDevices.find(v => v.id === vdId);
                  if (existing) {
                    existing.fleetIds[fleetNode.id] = true;
                  }

                  return;
                }
                vehiclesAndDevicesIds[vdId] = true;
                if (fleetNode.id === -1) {
                  fleetNode.companyId[vd.company.id] = true;
                }
                if (isValidVehiclesDevices(vdId)) {
                  vehiclesAndDevices.push({
                    id: vdId,
                    name: vd.name,
                    deviceId: vd.id,
                    deviceType: 'Device',
                    companyId: vd.company.id,
                    fleetIds: { [fleetNode.id]: true }
                  });
                  if (restrictedEntities.length !== 0 && getValidSubCompany(vd.company.id)) {
                    entitiesCompanies.push(getValidSubCompany(vd.company.id));
                  }
                }
                let deviceNode = {
                  nodeType: 'Device',
                  deviceId: vd.id,
                  deviceName: vd.name,
                  driverLoginStatus: t('Home.NotLoggedIn'),
                  snapShotTime: null,
                  footages: [],
                  companyId: vd.company.id,
                  nodeId: vdId,
                  fleetIds: { [fleetNode.id]: true },
                  deviceModelName: vd?.model?.name,
                  deviceTypeCode: vd?.type?.code
                };

                deviceNode = { ...deviceNode, ...entityNodeMetaFunc(vd.id) };
                if (
                  !snapshotsEntities.find(
                    s => s.nodeType === 'Device' && s.deviceId === deviceNode.deviceId
                  )
                ) {
                  snapshotsEntities.push(deviceNode);
                }
              }
            });
          }
        } else {
          if (
            showNonCameraVehicle ||
            (v.devices && v.devices.some(vd => vd.type.name === 'Camera'))
          ) {
            fleetHasCameraDevices = true;
            const vdId = 'vehicle_' + v.id;

            if (vehiclesAndDevicesIds[vdId]) {
              const existing = vehiclesAndDevices.find(v => String(v.id) === String(vdId));
              if (existing) {
                existing.fleetIds[fleetNode.id] = true;
              }
              return;
            }

            vehiclesAndDevicesIds[vdId] = {};
            if (fleetNode.id === -1) {
              fleetNode.companyId[v.company.id] = true;
            }
            if (isValidVehiclesDevices(vdId)) {
              vehiclesAndDevices.push({
                id: vdId,
                name: v.name,
                deviceId: v.id,
                deviceType: 'Vehicle',
                companyId: v.company.id,
                fleetIds: { [fleetNode.id]: true }
              });
              if (restrictedEntities.length !== 0 && getValidSubCompany(v.company.id)) {
                entitiesCompanies.push(getValidSubCompany(v.company.id));
              }
            }
            let vehicleNode = {
              nodeType: 'Vehicle',
              vehicleId: v.id,
              deviceName: v.name,
              driverLoginStatus: t('Home.NotLoggedIn'),
              snapShotTime: null,
              footages: [],
              devices: [],
              companyId: v.company.id,
              nodeId: vdId,
              fleetIds: { [fleetNode.id]: true }
            };
            if (v.devices) {
              for (let i = 0; i < v.devices.length; i++) {
                const vd = v.devices[i];
                vehicleNode = { ...vehicleNode, ...entityNodeMetaFunc(vd.id) };
                if (vd.type.name === 'Camera') {
                  vehiclesAndDevicesIds[vdId][vd.id] = true;
                  vehicleNode.devices.push(vd);
                }
                if (isMessagingDevice(vd)) {
                  vehicleNode.messagingDevice = vd;
                }
              }
            }
            if (
              !snapshotsEntities.find(
                s => s.nodeType === 'Vehicle' && s.vehicleId === vehicleNode.vehicleId
              )
            ) {
              snapshotsEntities.push(vehicleNode);
            }
          }
        }
      });
    }

    if (fleetHasCameraDevices) {
      fleetNode.fleetHasCameraDevices = true;
    }

    if (isValidFleet(f)) {
      fleets.push(fleetNode);
    }
  });

  const deviceIds = new Set();
  if (restrictedEntities?.length) {
    for (const restrictedEntity of restrictedEntities) {
      const { entity, entityId } = restrictedEntity;
      if (entity === 'vehicle') {
        for (const deviceId of Object.keys(vehiclesAndDevicesIds[`vehicle_${entityId}`] || {}) ||
          []) {
          deviceIds.add(deviceId);
        }
      } else if (entity === 'device') {
        deviceIds.add(entityId);
      }
    }
  } else {
    for (const vehiclesAndDevicesId of Object.keys(vehiclesAndDevicesIds)) {
      if (vehiclesAndDevicesId.startsWith('vehicle_')) {
        for (const deviceId of Object.keys(vehiclesAndDevicesIds[vehiclesAndDevicesId] || {}) ||
          []) {
          deviceIds.add(deviceId);
        }
      } else if (vehiclesAndDevicesId.startsWith('device_')) {
        deviceIds.add(vehiclesAndDevicesId.replace('device_', ''));
      }
    }
  }
  const allDeviceIds = Array.from(deviceIds);
  return {
    snapshotsEntities,
    fleets,
    vehiclesAndDevices,
    companies: restrictedEntities.length === 0 ? subCompanies : entitiesCompanies,
    vehiclesAndDevicesIds,
    allDeviceIds
  };
};

export const useCompanySnapshotsEntities = ({
  entity = null,
  entityId = null,
  showNonCameraVehicle = false
}) => {
  const { t } = useTranslation();
  const subCompanies = useSubCompanies();

  const { isLoading, companyFleetData, companyId, deviceStats } = useCompanyFleetData();

  const {
    fleets,
    companies,
    snapshotsEntities,
    vehiclesAndDevices,
    vehiclesAndDevicesIds,
    allDeviceIds,
    snapshotsEntity
  } = useMemo(() => {
    const { snapshotsEntities, ...others } = getEntitiesFromFleetData({
      t,
      showNonCameraVehicle,
      companyFleetData,
      subCompanies,
      restrictedEntities: entity && entityId ? [{ entity, entityId }] : [],
      entityNodeMetaFunc: vdId => {
        const entityDriver = deviceStats?.data?.[companyId]?.[vdId]?.currentUser;
        return entityDriver
          ? {
              driverLoginStatus: `${entityDriver.firstName} ${entityDriver.lastName}`,
              driverId: entityDriver.id
            }
          : {};
      }
    });
    return {
      snapshotsEntities,
      ...others,
      snapshotsEntity:
        entity &&
        entityId &&
        snapshotsEntities?.find(({ nodeId }) => nodeId === `${entity}_${entityId}`)
    };
  }, [
    companyId,
    companyFleetData,
    deviceStats,
    showNonCameraVehicle,
    t,
    entity,
    entityId,
    subCompanies
  ]);

  return {
    isLoading,
    companyId,
    fleets,
    companies,
    snapshotsEntities,
    vehiclesAndDevices,
    vehiclesAndDevicesIds,
    allDeviceIds,
    snapshotsEntity
  };
};
