import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tag } from 'antd';

import cn from 'classnames';
import style from './TagStatus.module.scss';

export const STATUS = {
  INPROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CLOSED: 'Closed',
  FAIL: 'Failed',
  REPAIRED: 'Pending Driver Sign Off',
  PASS: 'Passed'
};

export const TagStatus = ({ status, value }) => {
  const { t } = useTranslation();
  const statusClasses = cn({
    [style.inprogress]: status === STATUS.INPROGRESS,
    [style.completed]: status === STATUS.COMPLETED,
    [style.closed]: status === STATUS.CLOSED,
    [style.fail]: status === STATUS.FAIL,
    [style.repaired]: status === STATUS.REPAIRED,
    [style.closed]: status === STATUS.CLOSED,
    [style.passed]: status === STATUS.PASS
  });

  return (
    <div className={style.tagContainer}>
      <Tag key={status} className={statusClasses}>
        {value}
      </Tag>
      <span className={style.instanceStatus}>{t(`Status.${status.toLowerCase()}`)}</span>
    </div>
  );
};
