import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { DriverInfoCard } from './DriverInfoCard';
import { DriverHistoryStatusLineChart } from './DriverHistoryLineChart';
import { DriverStatusChart } from './DriverStatusChart';
import { DriverLogHeaderSummary } from './Modals/DriverLogHeader';
import styles from './DriverPortal.module.scss';
import { fetchELDDataByDriverId, useDriverELDData } from 'features/eld/eldSlice';
import { useDispatch } from 'react-redux';
import { useDrivers } from 'features/users/usersSlice';
import { driverLogData } from './util';
import { useDevices, useVehicles } from 'features/fleets/fleetsSlice';
import moment from 'moment';

export function PageHeader({
  company,
  driverId,
  logDate,
  rowActions,
  refreshData,
  reloadData,
  ...props
}) {
  const [statusChart, setStatusChart] = useState(driverLogData.statusChart);
  const [infoCard, setInfoCard] = useState(driverLogData.infoCard);

  const dispatch = useDispatch();
  const driverEldData = useDriverELDData(
    company?.id,
    driverId,
    moment(logDate || new Date())
      .startOf('day')
      .toDate(),
    moment(logDate || new Date())
      .endOf('day')
      .toDate()
  );
  const drivers = useDrivers();
  const devicesList = useDevices();
  const vehiclesList = useVehicles();

  const loadDriverStatus = useCallback(
    logDate => {
      if (driverId != null && company != null && drivers != null) {
        const driver = drivers.find(c => c.id === parseInt(driverId));
        if (driver != null) {
          const fromDate = moment(logDate || new Date())
            .startOf('day')
            .toDate();
          const toDate = moment(logDate || new Date())
            .endOf('day')
            .toDate();

          dispatch(
            fetchELDDataByDriverId(
              driverId,
              company.api_key,
              driver.rulesets?.[0]?.ruleset,
              fromDate,
              toDate
            )
          );
        }
      }
    },
    [driverId, dispatch, company, drivers]
  );

  useEffect(() => {
    loadDriverStatus(logDate);
  }, [logDate, loadDriverStatus]);

  useEffect(() => {
    if (driverEldData != null) {
      const driverStatusChartData = Object.assign({}, driverLogData.statusChart);
      const driverInfoCardData = Object.assign({}, driverLogData.infoCard);

      if (driverEldData.driver?.length > 0) {
        driverInfoCardData.userName = driverEldData.driver;
      } else {
        const driver = drivers.find(d => d.id === parseInt(driverId));
        if (driver) {
          driverInfoCardData.userName = (driver.firstName || '') + ' ' + (driver.lastName || '');
        }
      }

      if (driverEldData.lastEvent?.vehicle?.id != null) {
        const vehicle = vehiclesList?.find(v => v.id === driverEldData.lastEvent?.vehicle.id);
        if (vehicle != null) {
          driverInfoCardData.vehicleName = vehicle.name;
        }
      }

      if (driverInfoCardData.vehicleName === '') {
        const device = devicesList?.find(d => d.id === driverEldData.lastEvent?.device?.id);
        driverInfoCardData.vehicleName = device?.vehicle?.name || device?.name || 'N/A';
      }

      driverInfoCardData.status = driverEldData.lastStatusEvent?.action || 'OffDuty';

      driverStatusChartData.driveTimeLeft = Math.max(0, driverEldData.drive24?.available);
      driverStatusChartData.totalDriveTime =
        driverEldData.drive24?.work + driverEldData.drive24?.remaining;

      driverStatusChartData.onDutyTimeLeft = Math.max(0, driverEldData.work24?.available);
      driverStatusChartData.totalOnDutyTime =
        driverEldData.work24?.work + driverEldData.work24?.remaining;

      driverStatusChartData.cycleTimeLeft = Math.max(0, driverEldData.totals?.available);
      driverStatusChartData.totalCycleTime =
        driverEldData.totals?.worked + driverEldData.totals?.available;

      setInfoCard(driverInfoCardData);
      setStatusChart(driverStatusChartData);
    } else {
      const driverInfoCardData = Object.assign({}, driverLogData.infoCard);
      const driver = drivers.find(c => c.id === parseInt(driverId));
      if (driver != null) {
        driverInfoCardData.userName = driver.firstName + ' ' + driver.lastName;
        driverInfoCardData.vehicleName = 'N/A';
      }
      setInfoCard(driverInfoCardData);
    }
  }, [driverId, driverEldData, drivers, devicesList, vehiclesList]);

  if (reloadData) {
    loadDriverStatus();
  }
  return (
    <Row className={styles.driverLogHeader}>
      <Col span={24}>
        <Row gutter={15}>
          <Col span={8}>
            <DriverStatusChart
              data={statusChart}
              isExempt={driverEldData?.ruleset?.name === 'ELD99'}
            />
          </Col>
          <Col span={16}>
            <Row>
              <Col span={24}>
                <DriverInfoCard data={infoCard} />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <DriverLogHeaderSummary
                  company={company}
                  driverId={driverId}
                  logDate={logDate}
                  eldData={driverEldData}
                  refreshData={refreshData}
                  reloadData={reloadData}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={22}>
            <DriverHistoryStatusLineChart
              logDate={logDate}
              driverId={driverId}
              company={company}
              rowActions={rowActions}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
