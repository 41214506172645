import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Tooltip } from 'antd';
import { useHistory } from 'react-router';
import styles from './Overview.module.scss';
import { useBranches } from 'features/locations/locationsSlice';
import { useFleets } from 'features/fleets/fleetsSlice';
import DropdownTreeSelect from 'components/form/treeselect/DropdownTreeSelect';
import { useTranslation } from 'react-i18next';
import { PATH_SPLIT } from 'components/form/treeselect/TreeSelect';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { prepareDataForMultiselect } from 'utils/filters';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';

const lastUpdateFilterOptions = t => [
  { id: '0_7_days', name: `0 - 7 ${t('Common.days')}`, checked: true },
  { id: '8_14_days', name: `8 - 14 ${t('Common.days')}`, checked: true },
  { id: '15_30_days', name: `15 - 30 ${t('Common.days')}`, checked: true },
  { id: '1_month_plus', name: `1 ${t('Common.month')} +`, checked: true }
];

export function OverviewToolbar({
  gridCount,
  filters,
  searchText,
  onBranchChange,
  onFleetChange,
  onGeneralFilterChange,
  onLastUpdateFiltersChange,
  onSearchChange
}) {
  const history = useHistory();

  const [filterBranches, setFilterBranches] = useState(
    history.location.state?.branchesFilter || []
  );
  const [filterFleets, setFilterFleets] = useState(history.location.state?.fleetsFilter || []);
  const [generalFilters, setGeneralFilters] = useState(filters);
  const { t } = useTranslation();
  const [lastUpdateFilters, setLastUpdateFilters] = useState(
    history.location.state?.lastUpdateFilters ||
      prepareDataForMultiselect(lastUpdateFilterOptions(t), t('ELD.AllRanges'), null)
  );

  const branches = useBranches();
  const fleets = useFleets();

  const timeoutHandler = useRef(-1);

  const handleSearchChange = useCallback(value => {
    if (timeoutHandler.current !== -1) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = -1;
    }
    timeoutHandler.current = setTimeout(() => {
      if (onSearchChange) {
        onSearchChange(value);
      }
      timeoutHandler.current = -1;
    }, 100);
  }, []);

  const handleFleetsChange = useCallback(
    f => {
      setFilterFleets(f);
      if (onFleetChange) {
        onFleetChange(f);
      }
    },
    [filterFleets, setFilterFleets]
  );

  const handleBranchesChange = useCallback(
    f => {
      setFilterBranches(f);
      if (onBranchChange) {
        onBranchChange(f);
      }
    },
    [filterBranches, setFilterBranches]
  );

  const handleGeneralFilterChange = useCallback(filters => {
    setGeneralFilters(filters);
    if (onGeneralFilterChange) {
      onGeneralFilterChange(filters);
    }
  }, []);

  const handleLastUpdateFiltersChange = useCallback(luFilters => {
    setLastUpdateFilters(luFilters);
    if (onLastUpdateFiltersChange) {
      onLastUpdateFiltersChange(luFilters);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (timeoutHandler.current !== -1) {
        clearTimeout(timeoutHandler.current);
        timeoutHandler.current = -1;
      }
    };
  }, []);

  useEffect(() => {
    setGeneralFilters(prevFilters => {
      if (prevFilters) {
        for (let key in prevFilters) {
          if (prevFilters[key].children[0].checked) {
            filters[key].children[0].checked = true;
          }

          for (let idx = 1; idx < prevFilters[key].children.length; idx++) {
            const node = prevFilters[key].children[idx];
            if (node.checked) {
              let filterNode = filters[key].children.find(cn => cn.label === node.label);
              if (!filterNode) {
                filterNode = Object.assign(prevFilters[key].children[idx]);
                filterNode.id = filters[key].children.length;
                filterNode.nodeKey = filters[key].nodeKey + PATH_SPLIT + filterNode.id;
                filters[key].children.push(filterNode);
              }
              filterNode.checked = true;
            }
          }
        }
      }
      return filters;
    });
  }, [filters]);

  useEffect(() => {
    // If loaded branches filter from history/page state then don't initialize it again
    if (history.location.state?.branchesFilter) {
      return;
    }

    const branchOptions = prepareDataForMultiselect(branches, t('Common.AllBranches'), null);
    const noBranch = branchOptions?.find(b => b.id === -1);
    if (noBranch) {
      noBranch.name = t('Users.NoBranch');
    }
    setFilterBranches(branchOptions);
  }, [branches, t]);

  useEffect(() => {
    // If loaded fleets filter from history/page state then don't initialize it again
    if (history.location.state?.fleetsFilter) {
      return;
    }

    const fleetsList = [];
    if (fleets && fleets.length > 0) {
      fleetsList.push(...fleets.filter(f => f.name));
    }
    fleetsList.push({ id: -1, name: t('Home.No Fleet') });
    setFilterFleets(prepareDataForMultiselect(fleetsList, t('Common.AllFleets'), null));
  }, [fleets]);

  return (
    <div className={styles.fatigueToolbar}>
      <AntSearchbar defaultValue={searchText} onFilter={handleSearchChange} />

      <AntMultiselect
        title={
          filterBranches?.some(value => !value.checked)
            ? t('Common.Branches')
            : t('Common.AllBranches')
        }
        onFilter={handleBranchesChange}
        data={filterBranches}
      />

      <AntMultiselect
        title={
          filterFleets?.some(value => !value.checked) ? t('Common.Fleets') : t('Common.AllFleets')
        }
        data={filterFleets}
        onFilter={handleFleetsChange}
      />

      <DropdownTreeSelect
        title={t('Common.Filter')}
        showSelectAll={true}
        tree={generalFilters}
        onChange={handleGeneralFilterChange}
      />
      <Tooltip title={t('ELD.LastUpdateRangeTooltip')}>
        <AntMultiselect
          title={
            lastUpdateFilters?.some(value => !value.checked) ? t('ELD.Ranges') : t('ELD.AllRanges')
          }
          data={lastUpdateFilters}
          onFilter={handleLastUpdateFiltersChange}
        />
      </Tooltip>
      <span>{gridCount + ' ' + t('Alerts.Drivers')}</span>
    </div>
  );
}
