import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Modal, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { Select } from 'components/ant';
import { confirmNavigateAway } from './confirmNavigateAway';

import { MassConfigurationsModalTable } from '../Tables';
import {
  useIsFetching,
  useMassConfigurations
} from 'features/massManagement/massConfigurationsSlice';

import styles from './Modal.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const LABEL_ALIGN = 'left';

export const VehicleConfigurationModal = ({ data, isOpen = false, onSave, onClose }) => {
  const { t } = useTranslation();
  const massConfigurations = useMassConfigurations();
  const isLoading = useIsFetching();
  const [vehicleConfigurationForm] = Form.useForm();

  const [massConfigs, setMassConfigs] = useState([]);
  const [initData, setInitData] = useState({});
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    if (!data?.massConfigurations) {
      return;
    }

    const massConfigs = getMassConfigs(data?.massConfigurations, 'id');
    setMassConfigs(massConfigs);
    const initData = data
      ? {
          id: data?.id,
          isVehicle: data?.type !== undefined,
          massConfigNames: getMassConfigNames(massConfigs),
          massConfigIds: massConfigs?.map(config => config.id)
        }
      : {};
    setInitData(initData);
    // should not be necessary but select is not properly loading initialValues on first render
    vehicleConfigurationForm.setFieldsValue(initData);
  }, [data, vehicleConfigurationForm, massConfigurations]);

  const getMassConfigs = (configs, prop) => {
    return massConfigurations
      .filter(massConfig =>
        configs.find(config => (prop ? config[prop] : config) === massConfig.id)
      )
      .map(massConfig => {
        return {
          ...massConfig,
          massSchemes: massConfig.massSchemes.filter(scheme => scheme.selected)
        };
      });
  };

  const getMassConfigNames = massConfigs => {
    return massConfigs.reduce(function(map, obj) {
      map[obj.id] = obj.name;
      return map;
    }, {});
  };

  const onMassConfigurationsChanged = ids => {
    let massConfigs = getMassConfigs(ids);
    setMassConfigs(massConfigs);
    vehicleConfigurationForm.setFieldsValue({
      massConfigIds: massConfigs?.map(config => config.id),
      massConfigNames: {
        ...vehicleConfigurationForm.getFieldValue('massConfigNames'),
        ...getMassConfigNames(massConfigs)
      }
    });
    setIsFormDirty(true);
  };

  const clearForm = () => {
    vehicleConfigurationForm.resetFields();
    setMassConfigs(getMassConfigs(data?.massConfigurations, 'id'));
    setIsSaveEnabled(false);
    setIsFormDirty(false);
  };

  const onCancel = () => {
    confirmNavigateAway(
      isFormDirty,
      () => {
        onClose();
        clearForm();
      },
      null,
      t
    );
  };

  const onFormSave = values => {
    onClose();
    onSave(values);
    clearForm();
  };

  const validateForm = async () => {
    try {
      const values = await vehicleConfigurationForm.validateFields();
      setIsSaveEnabled(true);
    } catch (errorInfo) {
      setIsSaveEnabled(!errorInfo.errorFields.length);
    }
  };

  const onFormChanged = () => {
    validateForm();
  };

  return (
    <Modal
      className={styles.modal}
      title={t('MassManagement.Action.EditMassConfigurations', { name: data?.name })}
      open={isOpen}
      centered={true}
      onCancel={onCancel}
      width={1200}
      footer={
        <>
          <Button
            type="primary"
            size="large"
            form="vehicleConfigurationForm"
            key="submit"
            htmlType="submit"
            id={BUTTON_IDS.vehicleConfigModalSave}
            disabled={!isSaveEnabled}
          >
            {t('Common.Save')}
          </Button>
          <Button id={BUTTON_IDS.vehicleConfigModalCancel} size="large" onClick={onCancel}>
            {t('Common.Cancel')}
          </Button>
        </>
      }
    >
      <Form
        layout="vertical"
        initialValues={initData}
        form={vehicleConfigurationForm}
        name="vehicleConfigurationForm"
        onFinish={onFormSave}
        onValuesChange={onFormChanged}
        onKeyPress={event => {
          if (event.which === 13 /* Enter */) {
            event.preventDefault();
          }
        }}
      >
        <Row align="middle">
          <Col span={24}>
            <Form.Item
              name="massConfigIds"
              label={t('MassManagement.MassConfigurations')}
              labelAlign={LABEL_ALIGN}
              colon={false}
            >
              <Select
                placeholder={t(`Common.Search`)}
                data={massConfigurations}
                onChange={onMassConfigurationsChanged}
                loading={isLoading}
                mode="multiple"
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: '30px' }}>
          <Col span={24}>
            <MassConfigurationsModalTable
              data={massConfigs}
              isReadOnly={true}
              loading={isLoading}
              childrenColumnName="massSchemes"
              tableLayout="fixed"
            />
          </Col>
        </Row>
        <Form.Item hidden name="id">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item hidden name="isVehicle">
          <Input type="hidden" />
        </Form.Item>
        <Form.Item hidden name="massConfigNames">
          <Input type="hidden" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
