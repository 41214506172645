import React, { useMemo } from 'react';

import { Select, Checkbox, Tag } from 'antd';

import { useTranslation } from 'react-i18next';

import { SearchOutlined } from '@ant-design/icons';

import { useSupportedEventTypes } from 'containers/Tracking/EventTypes';

import styles from './FilterEventsModal.module.scss';

const SELECT_ALL_KEY = 'Select_All';

export const FilterEventsSelector = ({
  className,
  includeAllEventTypes,
  setIncludeAllEventTypes,
  includedEventTypes,
  setIncludedEventTypes
}) => {
  const { t } = useTranslation();

  // Get all event types with duplicates labels removed and sorted
  const { allEventTypesNoDuplicatesSorted: allEventTypesNoDuplicates } = useSupportedEventTypes();

  const includedEventTypeKeys = useMemo(() => {
    let includedEventTypeKeys = includedEventTypes
      ? includedEventTypes.map(eventType => eventType.key)
      : [];
    if (includeAllEventTypes) {
      includedEventTypeKeys.push(SELECT_ALL_KEY);
    }
    return includedEventTypeKeys;
  }, [includedEventTypes, includeAllEventTypes]);

  const handleSelectAllChange = selectAll => {
    let newIncludedEventTypes = [];
    let newIncludeAllEventTypes = false;
    if (selectAll === true) {
      newIncludedEventTypes = [...allEventTypesNoDuplicates];
      newIncludeAllEventTypes = true;
    }
    setIncludedEventTypes(newIncludedEventTypes);
    setIncludeAllEventTypes(newIncludeAllEventTypes);
  };

  const getSelectValues = () => {
    // Show intermediate checked state on header if some but not all events selected
    const indeterminate =
      !includeAllEventTypes && includedEventTypes && includedEventTypes.length > 0;

    // Add "Select all", "Deselect all" header
    const headerOption = {
      headerLabel: includeAllEventTypes
        ? t('Tracking.FilterEvents.DeselectAll')
        : t('Tracking.FilterEvents.SelectAll'),
      id: SELECT_ALL_KEY
    };
    const values = Array(
      <Select.Option
        className={includeAllEventTypes ? styles.selectChecked : ''}
        key={headerOption.id}
        value={headerOption.id}
        label={headerOption.headerLabel}
      >
        <div label={headerOption.headerLabel}>
          <Checkbox checked={includeAllEventTypes} indeterminate={indeterminate}></Checkbox>
          <span className={styles.eventTypeSelectLabel}>{headerOption.headerLabel}</span>
        </div>
      </Select.Option>
    );

    // Add all event types
    if (allEventTypesNoDuplicates?.length) {
      allEventTypesNoDuplicates.forEach(eventType => {
        let isChecked = includedEventTypeKeys
          ? !!includedEventTypeKeys.find(
              includedEventTypeKey => includedEventTypeKey === eventType.key
            )
          : false;
        values.push(
          <Select.Option
            className={isChecked ? styles.selectChecked : ''}
            key={String(eventType.key)}
            value={eventType.key}
            label={t(`Tracking.Events.${eventType?.label}`)}
          >
            <div label={t(`Tracking.Events.${eventType?.label}`)}>
              <Checkbox checked={isChecked}></Checkbox>
              <span className={styles.eventTypeSelectLabel}>
                {t(`Tracking.Events.${eventType?.label}`)}
              </span>
            </div>
          </Select.Option>
        );
      });
    }

    return values;
  };

  const tagRender = props => {
    const { label, closable, onClose } = props;
    return (
      <Tag
        className={styles.tagContainer}
        onMouseDown={event => {
          event.preventDefault();
          event.stopPropagation();
        }}
        closable={closable}
        onClose={onClose}
      >
        {label?.props?.label}
      </Tag>
    );
  };

  const eventTypeSelectValues = getSelectValues();

  const handleSelectChange = selectedEventTypeKeys => {
    // Check for select all and deselect all special cases
    if (
      includedEventTypeKeys.includes(SELECT_ALL_KEY) &&
      !selectedEventTypeKeys.includes(SELECT_ALL_KEY)
    ) {
      handleSelectAllChange(false);
      return;
    } else if (
      !includedEventTypeKeys.includes(SELECT_ALL_KEY) &&
      selectedEventTypeKeys.includes(SELECT_ALL_KEY)
    ) {
      handleSelectAllChange(true);
      return;
    }

    let newIncludedEventTypes = allEventTypesNoDuplicates.filter(item => {
      return selectedEventTypeKeys.includes(item.key);
    });

    if (newIncludedEventTypes.length === allEventTypesNoDuplicates.length) {
      setIncludeAllEventTypes(true);
    } else {
      setIncludeAllEventTypes(false);
    }

    setIncludedEventTypes(newIncludedEventTypes);
  };

  return (
    <Select
      className={className}
      filterOption={(inputValue, option) => {
        const result = option?.label?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
        return result;
      }}
      maxTagCount={'responsive'}
      menuItemSelectedIcon={<></>}
      mode="multiple"
      value={includedEventTypeKeys}
      onChange={handleSelectChange}
      placeholder={t('Tracking.FilterEvents.SelectEventTypes')}
      showSearch
      autoClearSearchValue={false}
      suffixIcon={<SearchOutlined className="ant-select-suffix" />}
      tagRender={tagRender}
    >
      {eventTypeSelectValues}
    </Select>
  );
};
