import { API_PATH_WITHOUT_V1, API_PATH } from 'config';
import { PROD } from 'features/permissions';

export const Paths = {
  MAIN_PAGE: '/integrations',
  CONFIGURATION: '/integrations/configuration'
};

export const buildIcon = iconPath => {
  if (!API_PATH_WITHOUT_V1 || !iconPath) {
    return;
  }
  return `${API_PATH_WITHOUT_V1}/${iconPath}`;
};

export const searchLoaderDimensiunes = {
  width: 430,
  height: 60,
  marginTop: 16,
  marginLeft: 16
};

export const cardLoaderDimensions = {
  width: 340,
  height: 284,
  margin: 16
};

export const showHideAdapter = adapter => {
  // if ENV is PROD and preRelease flag is true, don't show the adapter
  // otherwise show the adapter (preRelease false shows for both PROD and NON_PROD)
  if (PROD.includes(API_PATH) && adapter.preRelease === true) {
    return false;
  }
  return true;
};
