/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Company from "./Company";
import Device from "./Device";
import Gps from "./Gps";
import LocalDateTime from "./LocalDateTime";
import User from "./User";
import Vehicle from "./Vehicle";

/**
 * The DeviceStatusEvent model module.
 * @module model/DeviceStatusEvent
 * @version 1.0
 */
class DeviceStatusEvent {
  /**
   * Constructs a new <code>DeviceStatusEvent</code>.
   * @alias module:model/DeviceStatusEvent
   */
  constructor() {
    DeviceStatusEvent.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>DeviceStatusEvent</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/DeviceStatusEvent} obj Optional instance to populate.
   * @return {module:model/DeviceStatusEvent} The populated <code>DeviceStatusEvent</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new DeviceStatusEvent();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("company")) {
        obj["company"] = Company.constructFromObject(data["company"]);
      }
      if (data.hasOwnProperty("user")) {
        obj["user"] = User.constructFromObject(data["user"]);
      }
      if (data.hasOwnProperty("device")) {
        obj["device"] = Device.constructFromObject(data["device"]);
      }
      if (data.hasOwnProperty("vehicle")) {
        obj["vehicle"] = Vehicle.constructFromObject(data["vehicle"]);
      }
      if (data.hasOwnProperty("timeAt")) {
        obj["timeAt"] = ApiClient.convertToType(data["timeAt"], "Date");
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = ApiClient.convertToType(data["type"], "String");
      }
      if (data.hasOwnProperty("details")) {
        obj["details"] = ApiClient.convertToType(data["details"], "String");
      }
      if (data.hasOwnProperty("location")) {
        obj["location"] = ApiClient.convertToType(data["location"], "String");
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = LocalDateTime.constructFromObject(data["createdAt"]);
      }
      if (data.hasOwnProperty("updatedAt")) {
        obj["updatedAt"] = LocalDateTime.constructFromObject(data["updatedAt"]);
      }
      if (data.hasOwnProperty("GPS")) {
        obj["GPS"] = Gps.constructFromObject(data["GPS"]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
DeviceStatusEvent.prototype["id"] = undefined;

/**
 * @member {module:model/Company} company
 */
DeviceStatusEvent.prototype["company"] = undefined;

/**
 * @member {module:model/User} user
 */
DeviceStatusEvent.prototype["user"] = undefined;

/**
 * @member {module:model/Device} device
 */
DeviceStatusEvent.prototype["device"] = undefined;

/**
 * @member {module:model/Vehicle} vehicle
 */
DeviceStatusEvent.prototype["vehicle"] = undefined;

/**
 * @member {Date} timeAt
 */
DeviceStatusEvent.prototype["timeAt"] = undefined;

/**
 * @member {String} type
 */
DeviceStatusEvent.prototype["type"] = undefined;

/**
 * @member {String} details
 */
DeviceStatusEvent.prototype["details"] = undefined;

/**
 * @member {String} location
 */
DeviceStatusEvent.prototype["location"] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
DeviceStatusEvent.prototype["createdAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} updatedAt
 */
DeviceStatusEvent.prototype["updatedAt"] = undefined;

/**
 * @member {module:model/Gps} GPS
 */
DeviceStatusEvent.prototype["GPS"] = undefined;

export default DeviceStatusEvent;
