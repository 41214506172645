import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Button, Form, Divider, Modal, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import './Modals.scss';

import { useDispatch } from 'react-redux';
import { openToast } from 'features/toasts/toastsSlice';
import { ToastType } from 'components/notifications/toasts/Toast';
import { ApiClient } from 'nextgen_api';
import { API_PATH } from 'config';
import { useUserKey } from 'features/user/userSlice';
import { t_error } from 'i18nextConfig';

import { updateMultiCarrierFields } from 'features/company_config';

import { BUTTON_IDS } from 'utils/globalConstants';

const DataStatus = {
  Init: 'Init',
  Fetched: 'Fetched'
};

export function MulticarrierDefaultSelectModal({
  handleShow,
  show,
  onClose,
  carriers = [],
  ...props
}) {
  const [dataStatus, setDataStatus] = useState(DataStatus.Init);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  var companyCarriers = Array.isArray(carriers) ? carriers : [];
  var [selectedDefaultCarrier, setSelectedDefaultCarrier] = useState(null);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const userKey = useUserKey();

  useEffect(() => {
    if (dataStatus === DataStatus.Init) {
      setSelectedDefaultCarrier(null);
    }

    if (
      dataStatus === DataStatus.Init &&
      companyCarriers.length > 0 &&
      selectedDefaultCarrier == null
    ) {
      setSelectedDefaultCarrier(companyCarriers[0]);
      selectedDefaultCarrier = companyCarriers[0];
      setDataStatus(DataStatus.Fetched);
    }
  }, [dataStatus, selectedDefaultCarrier]);

  const handleCancel = useCallback(() => {
    if (onClose) {
      onClose();
    }
    handleShow(false);
  }, [onClose]);

  const handleItemClick = carrier => {
    selectedDefaultCarrier = carrier;
    setSelectedDefaultCarrier(carrier);
    setIsFormTouched(true);
  };

  const handleSave = async () => {
    //Disable all other carriers except for the default carrier
    if (selectedDefaultCarrier == null) {
      return;
    }

    const carriersToDisable = companyCarriers
      .filter(x => x.id !== selectedDefaultCarrier.id)
      .map(disableCarrier);
    let succeed = true;
    if (carriersToDisable.length) {
      succeed = await Promise.all(carriersToDisable).then(res => {
        const errors = res.filter(result => !result.succeed);
        if (errors.length) {
          dispatch(
            openToast({
              type: ToastType.Error,
              message: errors.reduce(
                (a, error, index) =>
                  a + (error.err ? `${error.err}${index === errors.length - 1 ? '' : '; '}` : ''),
                ''
              )
            })
          );
          return false;
        } else {
          dispatch(openToast({ type: ToastType.Success, message: 'ELD Carrier Saved.' }));
        }
        return true;
      });
    }
    //Set the company config to false
    succeed = await disableCompanyOnMultiCarrier(selectedDefaultCarrier);
    if (succeed) {
      if (onClose) {
        onClose();
      }
      handleShow(false);
    }
  };

  const disableCompanyOnMultiCarrier = carrier => {
    return dispatch(
      updateMultiCarrierFields({
        companyId: carrier?.company?.id,
        body: { Config: 'false' },
        type: 'multicarrierenable'
      })
    )
      .unwrap()
      .then(res => res === false)
      .catch(err => Promise.resolve(false));
    /*
    const uploadResponse = await dispatch(
      uploadBulkFile({
        apiEndpoint: 'upload',
        body: fileData,
        companyId: currentCompany.id
      })
    );*/
  };

  const disableCarrier = entry => {
    let values = { ...entry };
    values.status = 'DISABLED';
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const apiQuery = '/carrier';
    const promise = new Promise((resolve, reject) => {
      apiClient.callApi(
        apiQuery,
        'POST',
        { companyId: values?.company?.id },
        {},
        {},
        {},
        values,
        [],
        [],
        [],
        null,
        null,
        (err, data, resp) => {
          if (err && (resp == null || resp.status !== 200)) {
            console.error(err);
            reject(resp?.body || err);
          } else {
            resolve(resp.body);
          }
        }
      );
    });

    return promise.then(
      () => ({ succeed: true }),
      err => ({ succeed: false, err: t_error(err).toString() })
    );
  };

  const modalFooter = useMemo(() => {
    return (
      <Row gutter={10}>
        <Col>
          <Button
            id={BUTTON_IDS.multiCarrierSave}
            type="primary"
            onClick={() => handleSave()}
            disabled={
              !isFormTouched ||
              !selectedDefaultCarrier?.id ||
              !companyCarriers?.some(c => c.id === selectedDefaultCarrier.id)
            }
          >
            Save
          </Button>
        </Col>
        <Col>
          <Button id={BUTTON_IDS.multiCarrierCancel} onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    );
  }, [handleCancel, handleSave]);

  return (
    <Modal
      title={t('CompanyConfig.ELDCarrier.MultipleCarriersEnabled')}
      open={show}
      onCancel={handleCancel}
      footer={modalFooter}
    >
      <Form
        id="multicarrierDefaultSelectForm"
        form={form}
        name="multicarrierDefaultSelectForm"
        /* onFinish={()=>handleSubmit} */
      >
        <div>{t('CompanyConfig.ELDCarrier.MulticarrierSelectMessage')}</div>

        <Divider className="divider" />
        {/* <FilterWrapper>
                    <AntSearchbar onFilter={onSearch} />
                </FilterWrapper> */}

        <div className="formContainer">
          <ToggleButtonGroup type="radio" name="options" vertical={true}>
            {companyCarriers?.map(carrier => (
              <ToggleButton
                key={carrier.id}
                value={carrier.id}
                onClick={() => handleItemClick(carrier)}
              >
                {`${carrier.name} | DOT: #${carrier.dotNumber || ''}`}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      </Form>
    </Modal>
  );
}
