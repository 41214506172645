import React, { useCallback } from 'react';
import { Select } from 'antd';
import { useDrivers } from 'features/users/usersSlice';
import { useTranslation } from 'react-i18next';
import { AssignLogModalMode } from 'containers/ELD/UDT/Modal/AssignLogModal';

export function DriverSearchInput({ mode, value, onChange, ...props }) {
  const drivers = useDrivers();
  const handleFilter = useCallback(
    (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    []
  );
  const { t } = useTranslation();

  if (mode === 'text' || mode === AssignLogModalMode.DriverPortal) {
    let val = value;
    if (!val && props && props.defaultValue) {
      val = props.defaultValue;
    }
    const driver = drivers?.find(d => d.id === val);
    if (driver != null) {
      return driver.firstName + ' ' + driver.lastName;
    }
    return '';
  }
  return (
    <Select
      filterOption={handleFilter}
      onChange={onChange}
      optionFilterProp="children"
      placeholder={t('ELD.Select a Driver')}
      prefix="\e918"
      showSearch={true}
      {...props}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      value={value || props?.defaultValue}
    >
      {drivers &&
        drivers.map((d, idx) => (
          <Select.Option key={idx} value={d.id}>
            {d.firstName + ' ' + d.lastName}
          </Select.Option>
        ))}
    </Select>
  );
}
