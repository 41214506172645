import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
//components
import { Form, Select, Input, Row, Col, Alert, Collapse, DatePicker } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { DocumentsDisplay } from 'components/Documents';
import { FileUpload } from 'components/FileUpload';
//slices
import { setPageTitle, setBackButton } from 'features/page/pageSlice';
import { useUser } from 'features/user/userSlice';
import { useLocalization } from 'features/localization/localizationSlice';
import { fetchInspection } from 'features/vehicleMaintenanceInspections';
//helpers
import { renderUploadedFiles, handleFileAttachment } from '../DriverManagement/helpers';
import { renderFailedItems } from './utils/helpers';
//styles
import styles from './VehicleMaintenanceSchedules.module.scss';
import { getConfig, useConfig } from 'features/easydocs/documentsSlice';
import { useCurrentCompany } from 'features/company/companySlice';

export const ScheduleForm = () => {
  const { Panel } = Collapse;
  const formCols = 3;
  const formSpan = 24 / formCols;

  const company = useCurrentCompany();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useUser();
  const history = useHistory();
  const localization = useLocalization();
  const uploadConfig = useConfig();
  const [files, saveFiles] = useState([]);
  const [inspectionData, setInspectionData] = useState(null);
  const [isPictureModalOpen, setIsPictureModalOpen] = useState(null);
  const dateFormat = localization.formats.time.formats.dby_imp.toUpperCase().replace(':MM', ':mm');

  useEffect(() => {
    dispatch(setPageTitle(`${t('VehicleMntSchedules.AddMaintenanceSchedule')}`));
    dispatch(setBackButton(true));
  }, []);

  useEffect(async () => {
    if (history?.location?.state?.inspection?.inspectionId) {
      const fetchInspectionData = async () => {
        const data = await dispatch(
          fetchInspection({ id: history?.location?.state?.inspection?.inspectionId })
        );
        setInspectionData(data?.payload);
      };

      fetchInspectionData().catch(console.error);
    }
  }, []);

  useEffect(() => {
    dispatch(getConfig(company?.id));
  }, [company]);

  const updateFilesCb = payload => {
    saveFiles(payload);
  };

  const handleAttachment = event => {
    const {
      target: { files: uploadFiles }
    } = event;
    handleFileAttachment({
      uploadFiles,
      files,
      dispatch,
      updateFilesCb,
      maxFileSizeBytes: uploadConfig?.maxFileSizeBytes
    });
  };

  const cancelPictureModal = () => {
    setIsPictureModalOpen(null);
  };

  const showPictureModal = id => {
    setIsPictureModalOpen(id);
  };

  return (
    <div className={styles.newFormContainer}>
      <Form layout="vertical" size="large">
        <Row>
          <Col span={2 * formSpan}>
            <Row gutter={24}>
              <Col span={formSpan * 1.5} className={styles.adjustLeftInput}>
                <Form.Item name="serviceName" label="Service name" required>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={formSpan * 1.5} className={styles.adjustMiddleInput}>
                <Form.Item name="type" label="Type" required>
                  <Select />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={formSpan * 1.5} className={styles.adjustLeftInput}>
                <Form.Item name="fleet" label="Fleet">
                  <Select />
                </Form.Item>
              </Col>
              <Col span={formSpan * 1.5} className={styles.adjustMiddleInput}>
                <Form.Item name="vehicle" label="Vehicle" required>
                  <Select />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={formSpan} className={styles.maintenanceNotesColumn}>
            <Form.Item name="notes" label="Maintenance notes">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Alert
          type="info"
          message="Please fill in the schedule values below including when the alert for the schedule is to be completed. You are able to enter multiple, the schedule will be set to occur at which condition will trigger first."
        ></Alert>
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          style={{ background: 'none' }}
        >
          <Panel header="Odometer" key="1">
            <Row gutter={24}>
              <Col span={formSpan}>
                <Form.Item name="scheduleDate" label="Alert (km before Schedule Due)">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={formSpan}>
                <Form.Item name="scheduleDue" label="Schedule Due (km)">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={formSpan}>
                <Form.Item name="Current reading (Kms)" label="Current reading (Hrs)">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={formSpan}>
                <Form.Item name="overdue" label="Overdue (km after Schedule Due)">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={formSpan}>
                <Form.Item name="repeat" label="Repeat (km)">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          <Panel header="Engine Hours" key="2">
            <Row gutter={24}>
              <Col span={formSpan}>
                <Form.Item name="hrsDueAlert" label="Alert (Hrs before Schedule Due)">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={formSpan}>
                <Form.Item name="hrsDue" label="Schedule Due (Hrs)">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={formSpan}>
                <Form.Item name="hrsReading" label="Current reading (Hrs)">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={formSpan}>
                <Form.Item name="hrsOverdue" label="Overdue (Hrs after Schedule Due)">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={formSpan}>
                <Form.Item name="repeat" label="Repeat (Hrs)">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          <Panel header="Date" key="3">
            <Row gutter={24}>
              <Col span={formSpan}>
                <Form.Item name="dateAlert" label="Alert (Days before Schedule Due)">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={formSpan}>
                <Form.Item name="dateDue" label="Schedule Due">
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col span={formSpan}>
                <Form.Item name="dateReading" label="Current reading">
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={formSpan}>
                <Form.Item name="dateOverdue" label="Overdue (Days after Schedule Due)">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={formSpan}>
                <Form.Item name="repeat" label="Repeat">
                  <DatePicker />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        {inspectionData &&
          renderFailedItems({
            inspectionData,
            isPictureModalOpen,
            showPictureModal,
            cancelPictureModal,
            styles,
            t
          })}
        <Row>
          <span className={styles.formLabel}>{t('VehicleMntSchedules.View.Documents')}</span>
        </Row>
        <Row>
          <Col span={2 * formSpan}>
            <DocumentsDisplay
              rows={renderUploadedFiles({
                files,
                currentUser: user,
                dateFormat,
                updateFilesCb,
                dispatch
              })}
              customStyle={styles.documentsDisplayWrapper}
            />
          </Col>
          <Col span={formSpan} className={styles.uploadContainer}>
            <span className={styles.fileUpload}>
              <FileUpload onAdd={handleAttachment} />
            </span>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
