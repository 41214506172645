import React, { useRef, useCallback, useMemo } from 'react';
import { Row, Col } from 'antd';
import Gauge from 'components/visualization/Gauge';
import Donut from 'components/visualization/Donut';

import i18next from 'i18nextConfig';
import { useTranslation } from 'react-i18next';

const DEFAULT_CHART_DATA = {
  driverSummary: [
    {
      id: 'OffDuty',
      angle: 0,
      color: '#9fa9b7',
      mousehoverColor: '#f7f8f9',
      get message() {
        return i18next.t('ELD.DutyStatus.Off Duty');
      }
    },
    {
      id: 'SleeperBerth',
      angle: 0,
      color: '#42c3f9',
      mousehoverColor: '#f7f8f9',
      get message() {
        return i18next.t('ELD.DutyStatus.Sleeper Berth');
      }
    },
    {
      id: 'Driving',
      angle: 0,
      color: '#26bd8b',
      mousehoverColor: '3ad19f',
      get message() {
        return i18next.t('ELD.DutyStatus.Driving');
      }
    },
    {
      id: 'OnDuty',
      angle: 0,
      color: '#1890ff',
      mousehoverColor: '#f7f8f9',
      get message() {
        return i18next.t('ELD.DutyStatus.On Duty');
      }
    }
  ],
  atRiskViolation: 0,
  inViolation: 0,
  dayException: 0,
  totalDriver: 0
};

export function OverviewChart({ data, onChartFilterChanged, ...props }) {
  const chartData = useMemo(() => {
    const chartData = { ...DEFAULT_CHART_DATA };
    if (data != null) {
      chartData.atRiskViolation = data.riskVio;
      chartData.inViolation = data.inVio;
      chartData.dayException = data.underExp;
      chartData.totalDriver = data.totalDriver;
      chartData.driverSummary[0].angle = data.offDuty;
      chartData.driverSummary[1].angle = data.sleeperBerth;
      chartData.driverSummary[2].angle = data.driving;
      chartData.driverSummary[3].angle = data.onDuty;
    }
    return chartData;
  }, [data]);

  const handleStatusSelected = useCallback(
    (chart, status) => {
      onChartFilterChanged?.(oldFilter => {
        let newFilter = oldFilter || {};
        return {
          ...newFilter,
          [chart]: status
        };
      });
    },
    [onChartFilterChanged]
  );

  const handleDriverStatusClicked = useCallback(
    status => {
      handleStatusSelected('selectedStatus', status);
    },
    [handleStatusSelected]
  );

  const onRiskChartClicked = useCallback(
    status => {
      handleStatusSelected('riskVioSelected', status === 1);
    },
    [handleStatusSelected]
  );

  const onViolationChartClicked = useCallback(
    status => {
      handleStatusSelected('inVioSelected', status === 1);
    },
    [handleStatusSelected]
  );

  const onExceptionChartClicked = useCallback(
    status => {
      handleStatusSelected('underExp', status === 1);
    },
    [handleStatusSelected]
  );

  return (
    <Row gutter={16}>
      <Col xxl={5} xs={8}>
        <DriverSummaryChart
          data={chartData.driverSummary}
          onDriverChartSelection={handleDriverStatusClicked}
        />
      </Col>
      <Col xxl={12} xs={16}>
        <DriverViolationGroupCharts
          data={chartData}
          onRiskChartClicked={onRiskChartClicked}
          onViolationChartClicked={onViolationChartClicked}
          onExceptionChartClicked={onExceptionChartClicked}
        />
      </Col>
    </Row>
  );
}

function DriverSummaryChart({ data, onDriverChartSelection, ...props }) {
  const selectionId = useRef(null);
  const { t } = useTranslation();
  const handleSelection = useCallback(
    id => {
      if (selectionId.current !== id) {
        onDriverChartSelection?.({ id, selected: true });
        selectionId.current = id;
      } else {
        onDriverChartSelection?.({ selected: false });
        selectionId.current = null;
      }
    },
    [onDriverChartSelection]
  );
  return (
    <Donut
      title={t('Common.Drivers')}
      data={data}
      onSelection={handleSelection}
      isLoading={false}
    />
  );
}

function DriverViolationGroupCharts({
  data,
  onRiskChartClicked,
  onViolationChartClicked,
  onExceptionChartClicked,
  ...props
}) {
  return (
    <Row gutter={16} style={{ height: '100%', alignItems: 'center' }}>
      <Col xxl={6} xs={8}>
        <DriverRiskOfViolationChart data={data} onRiskChartClicked={onRiskChartClicked} />
      </Col>
      <Col xxl={6} xs={8}>
        <DriverInViolationChart data={data} onViolationChartClicked={onViolationChartClicked} />
      </Col>
      <Col xxl={6} xs={8}>
        <DriverExceptionChart data={data} onExceptionChartClicked={onExceptionChartClicked} />
      </Col>
    </Row>
  );
}

function DriverRiskOfViolationChart({ data, onRiskChartClicked, ...props }) {
  return (
    <Gauge
      message={i18next.t('ELD.Drivers at risk of violation')}
      color={'#ed9636'}
      mousehoverColor={'#ff7875'}
      activeState={'DEFAULT_STATE'}
      value={data.atRiskViolation}
      max={data.totalDriver}
      isLoading={false}
      onLabelClicked={onRiskChartClicked}
    />
  );
}

function DriverInViolationChart({ data, onViolationChartClicked, ...props }) {
  return (
    <Gauge
      message={i18next.t('ELD.Drivers in violation')}
      color={'#ff4d4f'}
      mousehoverColor={'#ff7875'}
      activeState={'DEFAULT_STATE'}
      value={data.inViolation}
      max={data.totalDriver}
      isLoading={false}
      onLabelClicked={onViolationChartClicked}
    />
  );
}

function DriverExceptionChart({ data, onExceptionChartClicked, ...props }) {
  return (
    <Gauge
      message={i18next.t('ELD.Drivers Operating Under EXEMPTIONS')}
      color={'#5d58d8'}
      mousehoverColor={'#ff7875'}
      activeState={'DEFAULT_STATE'}
      value={data.dayException}
      max={data.totalDriver}
      isLoading={false}
      onLabelClicked={onExceptionChartClicked}
    />
  );
}
