import i18next from 'i18nextConfig';
import moment from 'moment';

export const SuggestEditErrors = {
  get ODO_CHANGE_ON_SYSTEM_EVENT() {
    return i18next.t('ELD.SuggestEditError.ODO_CHANGE_ON_SYSTEM_EVENT');
  },
  get ENGINE_HRS_CHANGE_ON_SYSTEM_EVENT() {
    return i18next.t('ELD.SuggestEditError.ENGINE_HRS_CHANGE_ON_SYSTEM_EVENT');
  },
  get TIMESTAMP_CHANGE_ON_SYSTEM_DRIVE_EVENT() {
    return i18next.t('ELD.SuggestEditError.TIMESTAMP_CHANGE_ON_SYSTEM_DRIVE_EVENT');
  },
  get TIMESTAMP_CHANGE_ON_NONDRIVE_EVENT_BELOW_ENGINE_USAGE() {
    return i18next.t('ELD.SuggestEditError.TIMESTAMP_CHANGE_ON_NONDRIVE_EVENT_BELOW_ENGINE_USAGE');
  },
  get EXISTING_DRIVE_EVENT_ACTION_EDIT_TO_NONDRIVE_ACTION() {
    return i18next.t('ELD.SuggestEditError.EXISTING_DRIVE_EVENT_ACTION_EDIT_TO_NONDRIVE_ACTION');
  },
  get NEW_LOG_WITHIN_DRIVE_SEGMENT_SHOULD_BE_DRIVING() {
    return i18next.t('ELD.SuggestEditError.NEW_LOG_WITHIN_DRIVE_SEGMENT_SHOULD_BE_DRIVING');
  },
  get NEW_LOG_WITHIN_DRIVE_SEGMENT_HAS_OUT_OF_RANGE_ODO() {
    return (payload, locale) =>
      i18next.t('ELD.SuggestEditError.NEW_LOG_WITHIN_DRIVE_SEGMENT_HAS_OUT_OF_RANGE_ODO', {
        x: locale.convertDistance(payload?.extra?.dutySegment?.start?.odometer),
        y: locale.convertDistance(payload?.extra?.dutySegment?.end?.odometer),
        unit: locale?.formats?.speed?.unit?.toUpperCase() || ''
      });
  },
  get NEW_LOG_WITHIN_DRIVE_SEGMENT_HAS_OUT_OF_RANGE_HRS() {
    return (payload, locale) =>
      i18next.t('ELD.SuggestEditError.NEW_LOG_WITHIN_DRIVE_SEGMENT_HAS_OUT_OF_RANGE_HRS', {
        x: payload?.extra?.dutySegment?.start?.engineHours || 0,
        y: payload?.extra?.dutySegment?.end?.engineHours || 0
      });
  },
  get EXISTING_DRIVE_EVENT_ACTION_EDIT_WITHOUT_CONDITIONS() {
    return i18next.t('ELD.SuggestEditError.EXISTING_DRIVE_EVENT_ACTION_EDIT_WITHOUT_CONDITIONS');
  },
  get EXISTING_DRIVE_EVENT_CHANGE_IN_READONLY_FIELD() {
    return i18next.t('ELD.SuggestEditError.EXISTING_DRIVE_EVENT_CHANGE_IN_READONLY_FIELD');
  },
  get REQUIRED_FIELD_ODO_MISSING() {
    return i18next.t('ELD.SuggestEditError.REQUIRED_FIELD_ODO_MISSING');
  },
  get NEW_LOG_WITHIN_DRIVE_SEGMENT_NOT_ALLOWED() {
    return (payload, locale, extras) => {
      const user = extras?.users.find(u => u.id === payload?.extra?.dutySegment?.start?.user?.id);
      const vehicle = extras?.vehicles.find(
        v => v.id === payload?.extra?.dutySegment?.start?.vehicle?.id
      );
      const startAt = payload?.extra?.dutySegment?.start?.eventAt;
      const endAt = payload?.extra?.dutySegment?.end?.eventAt;
      const timezone =
        payload?.extra?.dutySegment?.start?.timeZone || payload?.extra?.dutySegment?.end?.timeZone;

      return i18next.t('ELD.SuggestEditError.NEW_LOG_WITHIN_DRIVE_SEGMENT_NOT_ALLOWED', {
        driver: user ? user.firstName + ' ' + user.lastName : i18next.t('Common.Unknown Driver'),
        vehicle: vehicle ? vehicle.name : i18next.t('Common.Unknown Vehicle'),
        start: startAt
          ? moment
              .tz(startAt, timezone)
              .format('YYYY-MM-DD HH:mm:ss z' || locale.formats.time.formats.dby_imsp)
          : i18next.t('Common.Unknown Start Time'),
        end: endAt
          ? moment
              .tz(endAt, timezone)
              .format('YYYY-MM-DD HH:mm:ss z' || locale.formats.time.formats.dby_imsp)
          : i18next.t('Common.Unknown End Time')
      });
    };
  }
};

export function extractSuggestEditError(payload, locale, extras) {
  if (payload?.errorCodes?.length > 0) {
    const msg = SuggestEditErrors[payload.errorCodes[0]];
    if (typeof msg === 'function') {
      return msg(payload, locale, extras);
    }

    return msg;
  }
  return '';
}
