import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUsers, useIsFetching as useIsDriverFetching } from 'features/users/usersSlice';
import {
  useBranches,
  useIsFetching as useIsBranchesFetching
} from 'features/locations/locationsSlice';
import { useDevicesStats, useIsFetchingDevicesStats } from 'features/devices/devicesStatsSlice';
import { MESSAGE_TYPE, RECIPIENT_TYPE } from 'containers/Messaging/constants';
import {
  buildRecipientTree,
  vehicleIconTitleFunc,
  deviceIconTitleFunc,
  driverIconTitleFunc,
  findTreeNode,
  messageCanMarkAsRead
} from 'containers/Messaging/helpers';
import { useCanEveryService, useCanOneOfCompanyServices } from 'features/permissions/canHooks';
import services from 'features/permissions/services';
import {
  fetchUnreadMessageCount,
  useChatEntities,
  useUnreadMessagesMeta
} from 'features/messaging/messagingSlice';
import { useCurrentCompany } from 'features/company/companySlice';
import { useFleetsWithDynamicLinkVehicleDevice } from 'features/vehicles/hooks';

const isEmptyTree = tree => {
  return !tree || tree.every(child => child.children.length === 0);
};

export function useRecipientTree() {
  const { t } = useTranslation();

  const [recipientTree, setRecipientTree] = useState(null);

  const { fleets, isFetchingFleets: fleetIsFetching } = useFleetsWithDynamicLinkVehicleDevice();
  const branches = useBranches();
  const drivers = useUsers();
  const devicesStats = useDevicesStats();

  const deviceIsFetching = useIsFetchingDevicesStats();
  const branchIsFetching = useIsBranchesFetching();
  const driverIsFetching = useIsDriverFetching();

  useEffect(() => {
    if (fleetIsFetching || driverIsFetching || branchIsFetching || deviceIsFetching) {
      return;
    }
    const tree = buildRecipientTree(
      t,
      branches.filter(branch => !branch.addedManually),
      drivers,
      fleets,
      devicesStats,
      vehicleIconTitleFunc,
      deviceIconTitleFunc,
      driverIconTitleFunc,
      { eventType: MESSAGE_TYPE['MSG-NEW'] }
    );
    setRecipientTree(isEmptyTree(tree) ? [] : tree);
  }, [
    branches,
    devicesStats,
    drivers,
    fleets,
    fleetIsFetching,
    driverIsFetching,
    branchIsFetching,
    deviceIsFetching,
    t
  ]);

  return recipientTree;
}

export function useEntityCanMessaging(entityType, entityId) {
  const [entityCanMessaging, setEntityCanMessaging] = useState(null);

  const recipientTree = useRecipientTree();

  useEffect(() => {
    if (
      recipientTree &&
      recipientTree.length &&
      [RECIPIENT_TYPE.DRIVER, RECIPIENT_TYPE.VEHICLE, RECIPIENT_TYPE.DEVICE].some(
        recipientType => recipientType === entityType
      )
    ) {
      const [branchTreeNode, fleetTreeNode] = recipientTree;
      const entityTreeNode = findTreeNode(
        entityId,
        entityType,
        entityType === RECIPIENT_TYPE.DRIVER ? branchTreeNode : fleetTreeNode
      );
      setEntityCanMessaging(!!entityTreeNode);
    } else {
      setEntityCanMessaging(null);
    }
  }, [recipientTree, entityType, entityId]);

  return entityCanMessaging;
}

export function canDriverMessaging(recipientTree, entityId) {
  let canMessaging = false;
  if (recipientTree && recipientTree.length) {
    const [branchTreeNode] = recipientTree;
    const entityTreeNode = findTreeNode(entityId, RECIPIENT_TYPE.DRIVER, branchTreeNode);
    canMessaging = !!entityTreeNode;
  }

  return canMessaging;
}

export function usePullMessages(appReady) {
  const dispatch = useDispatch();
  const hasMessagingPermission = useCanEveryService(services.MESSAGING);
  const isCompanyMessagingEnabled = useCanOneOfCompanyServices(services.MESSAGING);
  const isMessagingEnabled = useMemo(() => hasMessagingPermission && isCompanyMessagingEnabled, [
    hasMessagingPermission,
    isCompanyMessagingEnabled
  ]);
  const currentCompany = useCurrentCompany();
  const unreadMsgCheck = useCallback(() => {
    if (isMessagingEnabled && appReady) {
      dispatch(fetchUnreadMessageCount());
    }
  }, [isMessagingEnabled, dispatch, currentCompany, appReady]);

  return { unreadMsgCheck, isMessagingEnabled };
}

export function useUnreadMessages() {
  const { chatEntities } = useChatEntities();
  const unreadMessagesMeta = useUnreadMessagesMeta();
  const chatEntityUnreadMessages = useCallback(
    chatEntity => {
      const unreadMessages = (chatEntity?.flattenChatMessages || []).filter(
        msg =>
          messageCanMarkAsRead(msg) &&
          (!unreadMessagesMeta[msg?.id] ||
            (!unreadMessagesMeta[msg?.id]?.isMarkingAsRead &&
              !unreadMessagesMeta[msg?.id]?.readAt &&
              !unreadMessagesMeta[msg?.id]?.error))
      );
      return { unreadMessages, unreadMessageCount: unreadMessages.length };
    },
    [unreadMessagesMeta]
  );

  const { unreadMessages, unreadMessageCount } = useMemo(() => {
    const { unreadMessages, unreadMessageCount } = chatEntities
      .map(chatEntityUnreadMessages)
      ?.reduce(
        (a, c) => {
          const accumulator = a.unreadMessages;
          c.unreadMessages.forEach(msg => {
            if (accumulator.every(existing => existing.id !== msg.id)) {
              accumulator.push(msg);
            }
          });
          return {
            unreadMessageCount: accumulator.length,
            unreadMessages: accumulator
          };
        },
        { unreadMessageCount: 0, unreadMessages: [] }
      );
    return { unreadMessages, unreadMessageCount };
  }, [chatEntities, chatEntityUnreadMessages]);

  return { unreadMessages, unreadMessageCount };
}
