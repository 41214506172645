export const DEFAULT_DRIVER_FORM_FIELD_NAME = 'defaultDriver';
export const isDefaultDriverOfVehicle = (driver, vehicle) => {
  return (
    vehicle?.[DEFAULT_DRIVER_FORM_FIELD_NAME] &&
    parseInt(vehicle?.[DEFAULT_DRIVER_FORM_FIELD_NAME], 10) === parseInt(driver?.id, 10)
  );
};
export const canVehicleHasDefaultDriver = (vehicleData, companyDevices) => {
  //No devices or No IFace/External/PDA Type device
  return (
    vehicleData &&
    (!vehicleData?.devices?.length ||
      vehicleData.devices.every(vDevice => {
        const vDeviceType = companyDevices?.find(
          d => parseInt(d.id, 10) === parseInt(vDevice.id, 10)
        )?.type?.code;
        return !['IFACE', 'EXTERNAL', 'PDA'].includes(vDeviceType);
      }))
  );
};
