import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Table, Menu, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ExpandIcon } from 'components/ant/Table';
import { format } from 'utils/dates';
import styles from './Lens.module.scss';
import { ForceLogoffMenuButton } from 'features/users/forceLogoff/forceLogoffMenuButton';
import { DriverName } from '../../Common/DiverName';
import { useIQCameraUser } from 'features/permissions';

const vehicleContextMenu = ({ device, t, onClick }) => {
  const menuItems = [
    {
      key: 'view',
      onClick: onClick,
      label: device.vehicleId ? t('Home.View Vehicle') : t('Home.View Device')
    },
    {
      key: 'forcelogoff',
      eventKey: 'forcelogoff',
      label: (
        <ForceLogoffMenuButton
          itemKey="forcelogoff"
          vehicleId={device.vehicleId}
          deviceId={!device.vehicleId && device.id}
          driverId={device.driver?.id}
          menuType="ant"
        />
      )
    }
  ];

  return <Menu items={menuItems} />;
};

export const LensResultPane = ({
  t,
  localization,
  devices,
  onLensDeviceTripSelected,
  onClearSearch
}) => {
  const history = useHistory();

  const { canAccessNonCameraFeatures } = useIQCameraUser();

  const TableTitle = useMemo(() => {
    return () => (
      <div className={styles.titlebar}>
        <i className={`${styles.caret} tn-i-caret-down`} />
        <span className={styles.title}>{t('Tracking.VehicleSearch')}</span>
        <FontAwesomeIcon className={styles.close} icon={faTimes} onClick={onClearSearch} />
      </div>
    );
  }, [onClearSearch]);

  const expandedRowRender = device => {
    const columns = [
      {
        title: t('Tracking.Proximity.EntryTime'),
        dataIndex: 'entry',
        key: 'entry',
        render: entry => <span>{format(entry.at, localization.formats.time.formats.dby_imp)}</span>
      },
      {
        title: t('Tracking.Proximity.ExitTime'),
        dataIndex: 'exit',
        key: 'exit',
        render: exit => <span>{format(exit.at, localization.formats.time.formats.dby_imp)}</span>
      },
      {
        title: t('Tracking.Proximity.EntryLocation'),
        dataIndex: 'entry',
        key: 'entry',
        render: entry => <span>{entry.location}</span>
      },
      {
        title: t('Tracking.Proximity.ExitLocation'),
        dataIndex: 'exit',
        key: 'exit',
        render: exit => <span>{exit.location}</span>
      }
    ];

    return (
      <Table
        columns={columns}
        dataSource={[device]}
        pagination={false}
        onRow={device => {
          return {
            onClick: () => {
              onLensDeviceTripSelected(device.id, device.entry.at, device.exit.at);
            }
          };
        }}
      />
    );
  };

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: t('Tracking.Proximity.Status'),
        align: 'center',
        width: 10,
        render: device => {
          let statusColor;
          switch (device.deviceStats.ignition) {
            case 'ON':
            case 'On':
              statusColor =
                new Date(device.deviceStats.lastEventAt) < new Date().getHours() - 6
                  ? '#969696'
                  : '#26bd8b';
              break;
            case 'OFF':
            default:
              statusColor = '#22ADE2';
          }

          return (
            <span
              style={{
                width: '10px',
                backgroundColor: statusColor
              }}
            >
              &nbsp;&nbsp;
            </span>
          );
        }
      },
      {
        title: t('VehicleTypes.Type'),
        align: 'center',
        width: 10,
        dataIndex: 'vehicle',
        key: 'vehicle',
        render: (vehicle, rowData) => (
          <i
            style={{ fontSize: '22px' }}
            className={rowData.icon || (vehicle ? 'tn-i-flatbed' : 'tn-i-device')}
          />
        )
      },
      {
        title: t('Vehicles.View.Name'),
        key: 'name',
        render: device => <span>{device.vehicleName ? device.vehicleName : device.name}</span>
      },
      {
        title: t('Vehicles.View.Driver'),
        dataIndex: 'driverName',
        key: 'driverName',
        width: 200,
        render: (driverName, device) => <DriverName device={device} />
      }
    ];

    if (canAccessNonCameraFeatures) {
      defaultColumns.push({
        title: t('Vehicles.View.Actions'),
        align: 'center',
        key: 'operation',
        // fixed: 'right',
        width: 100,
        render: device => (
          <Dropdown
            overlay={vehicleContextMenu({
              device: device,
              t,
              onClick: () => onVehicleClicked(device)
            })}
            trigger={['click']}
          >
            <FontAwesomeIcon icon={faEllipsisV} className={styles.ellipsisButton} />
          </Dropdown>
        )
      });
    }

    return defaultColumns;
  }, [canAccessNonCameraFeatures]);

  const onVehicleClicked = useCallback(
    device => {
      if (device.vehicleId) {
        history.push(`/settings/vehicles/id/${device.vehicleId}`);
      } else {
        history.push(`/settings/devices/id/${device.id}`);
      }
    },
    [history]
  );

  return (
    <div className={styles.vehicleSearch}>
      <TableTitle />
      <Table
        className={styles.proximityResultsTable}
        size="large"
        columns={columns}
        dataSource={devices.map(device => {
          return {
            ...device,
            key: device.id
          };
        })}
        expandable={{ expandedRowRender }}
        pagination={false}
        expandIconColumnIndex={5}
        expandIconAsCell={true}
        expandIcon={props => ExpandIcon(props)}
      />
    </div>
  );
};
