import React from 'react';
import { Table } from 'components/ant';
import { useTranslation } from 'react-i18next';

import { useLocalization } from 'features/localization/localizationSlice';
import { useGetDeviceAgreementsQuery } from 'services/nextgen/ngAgreementApi';
import { deviceAgreementColumns } from './constants';

const DeviceAgreements = ({ deviceId }) => {
  const localization = useLocalization();
  const { data, isFetching } = useGetDeviceAgreementsQuery({
    id: deviceId,
    embed: 'agreement,subscriptionPack'
  });
  const { t } = useTranslation();
  return (
    <Table
      headerHeight={52}
      headerStyle={{ paddingTop: '18px' }}
      rowClassName="tableRow"
      columns={deviceAgreementColumns(t, localization)}
      dataSource={data?.map(deviceAgreement => deviceAgreement.agreement)}
      rowKey="id"
      pagination={false}
      loading={isFetching}
    />
  );
};

export default DeviceAgreements;
