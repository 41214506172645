import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { format } from 'utils/dates';

import InfoRow from 'components/form/info-row/InfoRow';
import { useLocalization } from 'features/localization/localizationSlice';

//styles
import styles from '../Devices.module.scss';

export const IQCameraDiagnostic = ({ diagnostic }) => {
  const localization = useLocalization();
  const { t } = useTranslation();

  return (
    <>
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.AccountId')}
        styles={styles}
        value={diagnostic.accountId ? diagnostic.accountId : ''}
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.AssetID')}
        styles={styles}
        value={diagnostic.assetId ? diagnostic.assetId : ''}
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.BootCompleteTimestamp')}
        styles={styles}
        value={
          diagnostic.bootCompleteTimeUTC
            ? format(
                moment.utc(diagnostic.bootCompleteTimeUTC).toISOString(true),
                localization.formats.time.formats.dby_imp
              )
            : ''
        }
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.BootCycleTriggeredByApp')}
        styles={styles}
        value={
          diagnostic.bootCycleTriggeredByApp.status +
          (diagnostic.bootCycleTriggeredByApp.timeUTC
            ? ' - ' +
              format(
                moment.utc(diagnostic.bootCycleTriggeredByApp.timeUTC).toISOString(true),
                localization.formats.time.formats.dby_imp
              )
            : '')
        }
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.DeviceShutdownTimestamp')}
        styles={styles}
        value={
          diagnostic.deviceShutdownTimeUTC
            ? format(
                moment.utc(diagnostic.deviceShutdownTimeUTC).toISOString(true),
                localization.formats.time.formats.dby_imp
              )
            : ''
        }
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.TimeTakentoGetGPSSyncAfterIgnition')}
        styles={styles}
        value={
          (diagnostic.timeTakenSyncGPS ? (diagnostic.timeTakenSyncGPS / 1000).toFixed(1) : 0) +
          ' ' +
          t('Common.Sec')
        }
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.IgnitionONTimestamp')}
        styles={styles}
        value={
          diagnostic.ignitionOnTimeUTC
            ? format(
                moment.utc(diagnostic.ignitionOnTimeUTC).toISOString(true),
                localization.formats.time.formats.dby_imp
              )
            : ''
        }
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.IgnitionOFFTimestamp')}
        styles={styles}
        value={
          diagnostic.ignitionOffTimeUTC
            ? format(
                moment.utc(diagnostic.ignitionOffTimeUTC).toISOString(true),
                localization.formats.time.formats.dby_imp
              )
            : ''
        }
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.BatteryHealth')}
        styles={styles}
        value={diagnostic.batteryHealth}
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.AccLowFrequency')}
        styles={styles}
        value={
          diagnostic.accLowFrequency.status +
          (diagnostic.accLowFrequency.timeUTC
            ? ' - ' +
              format(
                moment.utc(diagnostic.accLowFrequency.timeUTC).toISOString(true),
                localization.formats.time.formats.dby_imp
              )
            : '')
        }
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.GPSLowFrequency')}
        styles={styles}
        value={
          diagnostic.gpsLowFrequency.status +
          (diagnostic.gpsLowFrequency.timeUTC
            ? ' - ' +
              format(
                moment.utc(diagnostic.gpsLowFrequency.timeUTC).toISOString(true),
                localization.formats.time.formats.dby_imp
              )
            : '')
        }
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.PowerCutAlert')}
        styles={styles}
        value={
          diagnostic.powerCutAlert.status +
          (diagnostic.powerCutAlert.timeUTC
            ? ' - ' +
              format(
                moment.utc(diagnostic.powerCutAlert.timeUTC).toISOString(true),
                localization.formats.time.formats.dby_imp
              )
            : '')
        }
      />
      <InfoRow
        label={t('Devices.View.DiagnosticReport.IQCamera.GSensorMalfunction')}
        styles={styles}
        value={
          diagnostic.gsensorMalfunction.status +
          (diagnostic.gsensorMalfunction.timeUTC
            ? ' - ' +
              format(
                moment.utc(diagnostic.gsensorMalfunction.timeUTC).toISOString(true),
                localization.formats.time.formats.dby_imp
              )
            : '')
        }
      />
    </>
  );
};
