import React, { useState } from 'react';

import { Switch, Tooltip, Button } from 'antd';
import { getTotalDistance, getTotalPoints } from './ScorecardEventsHelpers';
import { ScorecardEventsList } from './ScorecardEventsList';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTranslation } from 'react-i18next';
import { useCan, entities, services } from 'features/permissions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/fontawesome-free-regular';

import eventStyles from './ScorecardEvents.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const getIncludeExcludeEventDisabled = checkedEvents => {
  const checkedEventsValues = Object.values(checkedEvents);

  let includeEventDisabled = true;
  let excludeEventDisabled = true;

  if (checkedEventsValues?.length > 0) {
    const nonGeofenceEvents = checkedEventsValues.filter(event => event.geofenceExcluded !== true);
    if (nonGeofenceEvents.find(event => event.excluded === true)) {
      includeEventDisabled = false;
    }

    if (nonGeofenceEvents.find(event => event.excluded !== true)) {
      excludeEventDisabled = false;
    }
  }

  return { includeEventDisabled, excludeEventDisabled };
};

export const ScorecardEventsPane = ({
  summary,
  events,
  entityType,
  onIncludeEventClick,
  onExcludeEventClick,
  checkedEvents,
  selectedEvent,
  setCheckedEvents,
  onEventSelected,
  isEWDevent
}) => {
  const { t } = useTranslation();
  const localization = useLocalization();
  const can = useCan();

  const [hideExcludedEvents, setHideExcludedEvents] = useState(false);

  const onEventCheckedChange = (e, event) => {
    var newCheckedEvents = { ...checkedEvents };

    if (e.target.checked) {
      newCheckedEvents[event.id] = event;
    } else if (newCheckedEvents[event.id]) {
      delete newCheckedEvents[event.id];
    }

    setCheckedEvents(newCheckedEvents);
  };

  const onHideExcludeEventsChange = checked => {
    setHideExcludedEvents(checked);
  };

  const totalPoints = getTotalPoints(events, entityType);
  const totalDistance = getTotalDistance(summary);
  const totalDistanceDisplay = localization.formatDistance(totalDistance);

  const { includeEventDisabled, excludeEventDisabled } = getIncludeExcludeEventDisabled(
    checkedEvents
  );

  // To Include/Exclude events, Company needs Scorecard Advanced subscription pack and user needs
  // Scorecard Advanced service permission and Scorecard Event -> Update entity permission
  const canIncludeExcludeEvents = can({
    everyService: [services.SCORECARDADV],
    everyCompanyService: [services.SCORECARDADV],
    oneOfEntities: [entities.SCORECARDEVENT_UPDATE]
  });

  const includeEventIconClass = includeEventDisabled
    ? eventStyles.includeEventIconDisabled
    : eventStyles.includeEventIcon;

  const excludeEventIconClass = excludeEventDisabled
    ? eventStyles.excludeEventIconDisabled
    : eventStyles.excludeEventIcon;

  let filteredEvents = events;
  if (hideExcludedEvents) {
    filteredEvents = filteredEvents.filter(
      event => event?.geofenceExcluded !== true && event.excluded !== true
    );
  }

  return (
    <>
      <div className={eventStyles.eventsHeaderRow}>
        <span>
          <b>{t('Vehicles.Events').toUpperCase()}</b>
        </span>
        <div className={eventStyles.totalPenaltyPointsText}>
          <div>
            <b>{totalPoints}</b>
          </div>
          <div className={eventStyles.eventPointsLabel}>{t('Scorecard.TotalPenaltyPoints')}</div>
        </div>
        <div className={eventStyles.totalDistanceText}>
          <div>
            <b>{totalDistanceDisplay}</b>
          </div>
          <div className={eventStyles.eventPointsLabel}>{t('Scorecard.TotalDistance')}</div>
        </div>
      </div>
      <div className={eventStyles.eventsButtonRow}>
        <div>
          {/* <Switch size="small" />
          <span className={eventStyles.toggleSwitchLabel}>{t('Scorecard.GroupByTrip')}</span> */}
          <Switch
            size="small"
            className={eventStyles.hideExcludedEventsSwitch}
            onChange={onHideExcludeEventsChange}
          />
          <span className={eventStyles.toggleSwitchLabel}>{t('Scorecard.HideExcludedEvents')}</span>
        </div>
        {canIncludeExcludeEvents && (
          <div className={eventStyles.excludeEventsButtonGroup}>
            <Tooltip title={t('Scorecard.IncludeEvents')}>
              <Button
                type="link"
                size="small"
                className={eventStyles.includeEventButton}
                disabled={includeEventDisabled}
                onClick={() => onIncludeEventClick({ useCheckedEvents: true })}
                id={BUTTON_IDS.scorecardEventsPaneInclude}
              >
                <FontAwesomeIcon className={includeEventIconClass} icon={faCheckCircle} />
              </Button>
            </Tooltip>
            <Tooltip title={t('Scorecard.ExcludeEvents')}>
              <Button
                type="link"
                size="small"
                className={eventStyles.excludeEventButton}
                disabled={excludeEventDisabled}
                onClick={() => onExcludeEventClick({ useCheckedEvents: true })}
                id={BUTTON_IDS.scorecardEventsPaneExclude}
              >
                <FontAwesomeIcon className={excludeEventIconClass} flip="horizontal" icon={faBan} />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <ScorecardEventsList
        events={filteredEvents}
        checkedEvents={checkedEvents}
        selectedEvent={selectedEvent}
        entityType={entityType}
        onEventCheckedChange={onEventCheckedChange}
        onEventSelected={onEventSelected}
        smallList={false}
        canIncludeExcludeEvents={canIncludeExcludeEvents}
        isEWDevent={isEWDevent}
      />
    </>
  );
};
