export const EDIT_LAYOUT_MODAL = 'modal';

export const STATUS = {
  CREATED: 'CREATED',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  DELETED: 'DELETED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED'
};
