/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Chronology from "./Chronology";
import DateTimeField from "./DateTimeField";
import DateTimeFieldType from "./DateTimeFieldType";

/**
 * The LocalDateTime model module.
 * @module model/LocalDateTime
 * @version 1.0
 */
class LocalDateTime {
  /**
   * Constructs a new <code>LocalDateTime</code>.
   * @alias module:model/LocalDateTime
   */
  constructor() {
    LocalDateTime.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>LocalDateTime</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/LocalDateTime} obj Optional instance to populate.
   * @return {module:model/LocalDateTime} The populated <code>LocalDateTime</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new LocalDateTime();

      if (data.hasOwnProperty("era")) {
        obj["era"] = ApiClient.convertToType(data["era"], "Number");
      }
      if (data.hasOwnProperty("dayOfYear")) {
        obj["dayOfYear"] = ApiClient.convertToType(data["dayOfYear"], "Number");
      }
      if (data.hasOwnProperty("dayOfWeek")) {
        obj["dayOfWeek"] = ApiClient.convertToType(data["dayOfWeek"], "Number");
      }
      if (data.hasOwnProperty("dayOfMonth")) {
        obj["dayOfMonth"] = ApiClient.convertToType(
          data["dayOfMonth"],
          "Number"
        );
      }
      if (data.hasOwnProperty("year")) {
        obj["year"] = ApiClient.convertToType(data["year"], "Number");
      }
      if (data.hasOwnProperty("weekOfWeekyear")) {
        obj["weekOfWeekyear"] = ApiClient.convertToType(
          data["weekOfWeekyear"],
          "Number"
        );
      }
      if (data.hasOwnProperty("chronology")) {
        obj["chronology"] = Chronology.constructFromObject(data["chronology"]);
      }
      if (data.hasOwnProperty("millisOfDay")) {
        obj["millisOfDay"] = ApiClient.convertToType(
          data["millisOfDay"],
          "Number"
        );
      }
      if (data.hasOwnProperty("monthOfYear")) {
        obj["monthOfYear"] = ApiClient.convertToType(
          data["monthOfYear"],
          "Number"
        );
      }
      if (data.hasOwnProperty("hourOfDay")) {
        obj["hourOfDay"] = ApiClient.convertToType(data["hourOfDay"], "Number");
      }
      if (data.hasOwnProperty("minuteOfHour")) {
        obj["minuteOfHour"] = ApiClient.convertToType(
          data["minuteOfHour"],
          "Number"
        );
      }
      if (data.hasOwnProperty("secondOfMinute")) {
        obj["secondOfMinute"] = ApiClient.convertToType(
          data["secondOfMinute"],
          "Number"
        );
      }
      if (data.hasOwnProperty("millisOfSecond")) {
        obj["millisOfSecond"] = ApiClient.convertToType(
          data["millisOfSecond"],
          "Number"
        );
      }
      if (data.hasOwnProperty("weekyear")) {
        obj["weekyear"] = ApiClient.convertToType(data["weekyear"], "Number");
      }
      if (data.hasOwnProperty("yearOfEra")) {
        obj["yearOfEra"] = ApiClient.convertToType(data["yearOfEra"], "Number");
      }
      if (data.hasOwnProperty("yearOfCentury")) {
        obj["yearOfCentury"] = ApiClient.convertToType(
          data["yearOfCentury"],
          "Number"
        );
      }
      if (data.hasOwnProperty("centuryOfEra")) {
        obj["centuryOfEra"] = ApiClient.convertToType(
          data["centuryOfEra"],
          "Number"
        );
      }
      if (data.hasOwnProperty("fieldTypes")) {
        obj["fieldTypes"] = ApiClient.convertToType(data["fieldTypes"], [
          DateTimeFieldType
        ]);
      }
      if (data.hasOwnProperty("values")) {
        obj["values"] = ApiClient.convertToType(data["values"], ["Number"]);
      }
      if (data.hasOwnProperty("fields")) {
        obj["fields"] = ApiClient.convertToType(data["fields"], [
          DateTimeField
        ]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} era
 */
LocalDateTime.prototype["era"] = undefined;

/**
 * @member {Number} dayOfYear
 */
LocalDateTime.prototype["dayOfYear"] = undefined;

/**
 * @member {Number} dayOfWeek
 */
LocalDateTime.prototype["dayOfWeek"] = undefined;

/**
 * @member {Number} dayOfMonth
 */
LocalDateTime.prototype["dayOfMonth"] = undefined;

/**
 * @member {Number} year
 */
LocalDateTime.prototype["year"] = undefined;

/**
 * @member {Number} weekOfWeekyear
 */
LocalDateTime.prototype["weekOfWeekyear"] = undefined;

/**
 * @member {module:model/Chronology} chronology
 */
LocalDateTime.prototype["chronology"] = undefined;

/**
 * @member {Number} millisOfDay
 */
LocalDateTime.prototype["millisOfDay"] = undefined;

/**
 * @member {Number} monthOfYear
 */
LocalDateTime.prototype["monthOfYear"] = undefined;

/**
 * @member {Number} hourOfDay
 */
LocalDateTime.prototype["hourOfDay"] = undefined;

/**
 * @member {Number} minuteOfHour
 */
LocalDateTime.prototype["minuteOfHour"] = undefined;

/**
 * @member {Number} secondOfMinute
 */
LocalDateTime.prototype["secondOfMinute"] = undefined;

/**
 * @member {Number} millisOfSecond
 */
LocalDateTime.prototype["millisOfSecond"] = undefined;

/**
 * @member {Number} weekyear
 */
LocalDateTime.prototype["weekyear"] = undefined;

/**
 * @member {Number} yearOfEra
 */
LocalDateTime.prototype["yearOfEra"] = undefined;

/**
 * @member {Number} yearOfCentury
 */
LocalDateTime.prototype["yearOfCentury"] = undefined;

/**
 * @member {Number} centuryOfEra
 */
LocalDateTime.prototype["centuryOfEra"] = undefined;

/**
 * @member {Array.<module:model/DateTimeFieldType>} fieldTypes
 */
LocalDateTime.prototype["fieldTypes"] = undefined;

/**
 * @member {Array.<Number>} values
 */
LocalDateTime.prototype["values"] = undefined;

/**
 * @member {Array.<module:model/DateTimeField>} fields
 */
LocalDateTime.prototype["fields"] = undefined;

export default LocalDateTime;
