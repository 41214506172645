/**
 * NextGen API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import Device from "./Device";
import Leg from "./Leg";
import LocalDateTime from "./LocalDateTime";
import Stop from "./Stop";

/**
 * The Trip model module.
 * @module model/Trip
 * @version 1.0
 */
class Trip {
  /**
   * Constructs a new <code>Trip</code>.
   * @alias module:model/Trip
   */
  constructor() {
    Trip.initialize(this);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj) {}

  /**
   * Constructs a <code>Trip</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/Trip} obj Optional instance to populate.
   * @return {module:model/Trip} The populated <code>Trip</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Trip();

      if (data.hasOwnProperty("id")) {
        obj["id"] = ApiClient.convertToType(data["id"], "Number");
      }
      if (data.hasOwnProperty("version")) {
        obj["version"] = ApiClient.convertToType(data["version"], "Number");
      }
      if (data.hasOwnProperty("companyId")) {
        obj["companyId"] = ApiClient.convertToType(data["companyId"], "Number");
      }
      if (data.hasOwnProperty("name")) {
        obj["name"] = ApiClient.convertToType(data["name"], "String");
      }
      if (data.hasOwnProperty("parent")) {
        obj["parent"] = Trip.constructFromObject(data["parent"]);
      }
      if (data.hasOwnProperty("device")) {
        obj["device"] = Device.constructFromObject(data["device"]);
      }
      if (data.hasOwnProperty("type")) {
        obj["type"] = ApiClient.convertToType(data["type"], "String");
      }
      if (data.hasOwnProperty("status")) {
        obj["status"] = ApiClient.convertToType(data["status"], "String");
      }
      if (data.hasOwnProperty("estimatedDuration")) {
        obj["estimatedDuration"] = ApiClient.convertToType(
          data["estimatedDuration"],
          "Number"
        );
      }
      if (data.hasOwnProperty("externalId")) {
        obj["externalId"] = ApiClient.convertToType(
          data["externalId"],
          "String"
        );
      }
      if (data.hasOwnProperty("correlationId")) {
        obj["correlationId"] = ApiClient.convertToType(
          data["correlationId"],
          "String"
        );
      }
      if (data.hasOwnProperty("groupId")) {
        obj["groupId"] = ApiClient.convertToType(data["groupId"], "String");
      }
      if (data.hasOwnProperty("attr")) {
        obj["attr"] = ApiClient.convertToType(data["attr"], "String");
      }
      if (data.hasOwnProperty("purpose")) {
        obj["purpose"] = ApiClient.convertToType(data["purpose"], "String");
      }
      if (data.hasOwnProperty("createdAt")) {
        obj["createdAt"] = LocalDateTime.constructFromObject(data["createdAt"]);
      }
      if (data.hasOwnProperty("updatedAt")) {
        obj["updatedAt"] = LocalDateTime.constructFromObject(data["updatedAt"]);
      }
      if (data.hasOwnProperty("stops")) {
        obj["stops"] = ApiClient.convertToType(data["stops"], [Stop]);
      }
      if (data.hasOwnProperty("legs")) {
        obj["legs"] = ApiClient.convertToType(data["legs"], [Leg]);
      }
    }
    return obj;
  }
}

/**
 * @member {Number} id
 */
Trip.prototype["id"] = undefined;

/**
 * @member {Number} version
 */
Trip.prototype["version"] = undefined;

/**
 * @member {Number} companyId
 */
Trip.prototype["companyId"] = undefined;

/**
 * @member {String} name
 */
Trip.prototype["name"] = undefined;

/**
 * @member {module:model/Trip} parent
 */
Trip.prototype["parent"] = undefined;

/**
 * @member {module:model/Device} device
 */
Trip.prototype["device"] = undefined;

/**
 * @member {module:model/Trip.TypeEnum} type
 */
Trip.prototype["type"] = undefined;

/**
 * @member {module:model/Trip.StatusEnum} status
 */
Trip.prototype["status"] = undefined;

/**
 * @member {Number} estimatedDuration
 */
Trip.prototype["estimatedDuration"] = undefined;

/**
 * @member {String} externalId
 */
Trip.prototype["externalId"] = undefined;

/**
 * @member {String} correlationId
 */
Trip.prototype["correlationId"] = undefined;

/**
 * @member {String} groupId
 */
Trip.prototype["groupId"] = undefined;

/**
 * @member {String} attr
 */
Trip.prototype["attr"] = undefined;

/**
 * @member {String} purpose
 */
Trip.prototype["purpose"] = undefined;

/**
 * @member {module:model/LocalDateTime} createdAt
 */
Trip.prototype["createdAt"] = undefined;

/**
 * @member {module:model/LocalDateTime} updatedAt
 */
Trip.prototype["updatedAt"] = undefined;

/**
 * @member {Array.<module:model/Stop>} stops
 */
Trip.prototype["stops"] = undefined;

/**
 * @member {Array.<module:model/Leg>} legs
 */
Trip.prototype["legs"] = undefined;

/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
Trip["TypeEnum"] = {
  /**
   * value: "PLANNED"
   * @const
   */
  PLANNED: "PLANNED",

  /**
   * value: "ACTUAL"
   * @const
   */
  ACTUAL: "ACTUAL",

  /**
   * value: "GEO_PLANNED"
   * @const
   */
  GEO_PLANNED: "GEO_PLANNED",

  /**
   * value: "GEO_ACTUAL"
   * @const
   */
  GEO_ACTUAL: "GEO_ACTUAL",

  /**
   * value: "ROUTE_PLANNED"
   * @const
   */
  ROUTE_PLANNED: "ROUTE_PLANNED",

  /**
   * value: "ROUTE_ACTUAL"
   * @const
   */
  ROUTE_ACTUAL: "ROUTE_ACTUAL",

  /**
   * value: "NETWORK_ACTUAL"
   * @const
   */
  NETWORK_ACTUAL: "NETWORK_ACTUAL",

  /**
   * value: "FBT"
   * @const
   */
  FBT: "FBT"
};

/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
Trip["StatusEnum"] = {
  /**
   * value: "CREATED"
   * @const
   */
  CREATED: "CREATED",

  /**
   * value: "STARTED"
   * @const
   */
  STARTED: "STARTED",

  /**
   * value: "IN_PROGRESS"
   * @const
   */
  IN_PROGRESS: "IN_PROGRESS",

  /**
   * value: "COMPLETED"
   * @const
   */
  COMPLETED: "COMPLETED",

  /**
   * value: "DELETED"
   * @const
   */
  DELETED: "DELETED"
};

export default Trip;
