import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

//components
import { Row, Col } from 'antd';
import FormTitle from 'components/form/form-title/FormTitle';
import { Button } from 'components/ant';
import { ReactSortable } from 'react-sortablejs';

//icons
import { DeleteFilled } from '@ant-design/icons';
import distanceIcon from 'static/images/icons/distance.svg';
import timerIcon from 'static/images/icons/timer.svg';
import locationIcon from 'static/images/icons/location.svg';
import dragHandleIcon from 'static/images/icons/drag-handle.svg';

//helpers
import { secondsToHHMMSS } from 'utils/methods';
import { journeyHelpers } from './helpers';
import { routeTitleStyle } from './constants';

//styles
import styles from './JourneyPlanner.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';

const RoutePreview = ({
  waypoints = [],
  localization,
  onRemove,
  readonly,
  mapDirections,
  onSortCb = () => {},
  onReverseClick
}) => {
  const { t } = useTranslation();
  const route = mapDirections && mapDirections.routes[0];
  const legs = route && route.legs;
  const totalDistanceCalculated = route && journeyHelpers.computeTotalDistance(route);
  const totalDuration = route && journeyHelpers.computeTotalDuration(route);
  const [routeStops, setRouteStops] = useState([]);
  const [showDraggableArea, setShowDraggableArea] = useState(false);
  const [showDirections, setShowDirections] = useState(false);

  useEffect(() => {
    if (waypoints) {
      setRouteStops(waypoints.filter(pt => pt.seq));
    }
  }, [waypoints]);

  const handleShowDirectionsButton = () => {
    setShowDirections(!showDirections);
  };

  return (
    <>
      <div className={styles.routeHeader}>
        <FormTitle title={t('JourneyPlanner.Route')} customStyling={routeTitleStyle}></FormTitle>
        <div className={`${styles.distance} ${styles.iconColumn}`}>
          <span>
            <i style={{ content: `url(${distanceIcon})` }} />
          </span>
          {localization.convertDistance(totalDistanceCalculated || 0)}{' '}
          {localization.formats.speed.unit}
        </div>
        <div className={styles.iconColumn}>
          <span>
            <i style={{ content: `url(${timerIcon})` }} />
          </span>
          {totalDuration ? secondsToHHMMSS(totalDuration) : '00:00:00'}
        </div>
        {!readonly && (
          <Button
            type="primary"
            size="medium"
            onClick={onReverseClick}
            className={styles.routeButton}
            disabled={waypoints?.length < 2}
            id={BUTTON_IDS.routePreviewReverse}
          >
            {t('JourneyPlanner.Reverse')}
          </Button>
        )}
        {readonly && (
          <Button
            type="primary"
            size="medium"
            onClick={handleShowDirectionsButton}
            className={styles.routeButton}
            disabled={waypoints?.length < 2}
            id={BUTTON_IDS.routePreviewShow}
          >
            {showDirections ? t('JourneyPlanner.ShowRoute') : t('JourneyPlanner.ShowDirections')}
          </Button>
        )}
      </div>
      <div
        className={`${styles.scrollable} showScrollbarsOnHover ${
          showDraggableArea ? styles.listBorder : ''
        }`}
      >
        <div
          id="panel"
          className={`${showDirections ? styles.directionsPanel : styles.none}`}
        ></div>
        {!showDirections && (
          <ReactSortable
            list={routeStops}
            setList={setRouteStops}
            animation={200}
            handle={`.${styles.dragHandle}`}
            ghostClass={`${styles.ghostSortableRow}`}
            onChoose={() => {
              setShowDraggableArea(true);
            }}
            onSort={onSortCb(routeStops)}
            onUnchoose={() => {
              setShowDraggableArea(false);
            }}
          >
            {routeStops.map((stop, index) => {
              const address = journeyHelpers.getFormattedAddress(stop?.location?.address);
              const formattedAddress = stop?.formattedAddress;
              const stopNumberToLetter = journeyHelpers.numberToLetter(stop.seq - 1);
              const locationName = journeyHelpers.getLocationName(stop, t) || '';
              return (
                <div key={stop.id || stop.longitude}>
                  <div className={`${styles.wrapper}`}>
                    {!readonly && (
                      <img
                        src={dragHandleIcon}
                        className={styles.dragHandle}
                        alt={dragHandleIcon}
                      />
                    )}
                    <Row className={`${styles.row} ${readonly ? styles.margin12 : ''}`}>
                      <Col className={styles.stopNumber}>{stopNumberToLetter}</Col>
                      <Col className={styles.elementWrapper}>
                        {stop.location && <div>{`${t('Common.Location')}: ${locationName}`}</div>}
                        {stop.geofence && (
                          <div>{`${t('Common.Geofence')}: ${stop.geofence?.name}`}</div>
                        )}
                        {(address || formattedAddress) && (
                          <div className={styles.routeAddress}>
                            <i
                              className={styles.locationIcon}
                              style={{ content: `url(${locationIcon})` }}
                            />
                            <div className={styles.address}>
                              {address ? address : formattedAddress}
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col className={styles.routeDelete}>
                        {!readonly && <DeleteFilled onClick={onRemove(stop)} />}
                      </Col>
                    </Row>
                  </div>

                  {legs && legs[index] && (
                    <div className={styles.legWrapper}>
                      <Row className={styles.legRow}>
                        <Col className={styles.legIcon}>
                          <div className={styles.legDashedIcon}></div>
                        </Col>
                        <Col className={styles.iconColumn}>
                          <span>
                            <i style={{ content: `url(${distanceIcon})` }} />
                          </span>
                          {localization.convertDistance(legs[index].distance.value / 1000)}{' '}
                          {localization.formats.speed.unit}
                        </Col>
                        <Col className={styles.iconColumn}>
                          <span>
                            <i style={{ content: `url(${timerIcon})` }} />
                          </span>
                          {secondsToHHMMSS(legs[index].duration.value)}
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              );
            })}
          </ReactSortable>
        )}
      </div>
    </>
  );
};

export default RoutePreview;
