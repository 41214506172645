import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Popover } from 'antd';
import { CalendarPopupContent } from 'components/ant/CalendarView';
import { CalendarView, RowStatus, Tooltip } from 'components/ant';

import { useLocalization } from 'features/localization/localizationSlice';
import { deleteSchedule } from 'features/driverManagement';
import { openToast } from 'features/toasts/toastsSlice';
import { useCan } from 'features/permissions/canHooks';

import { parseErrorMessage } from 'utils/strings';
import { preparePopupRows, preparePopupActions, getMntType } from './helpers';
import { statusMap } from '../VehicleMaintenance/utils/helpers';
import { STATUS, InnerColumnKeys } from './constants';
import { ToastType } from 'components/notifications/toasts/Toast';

import style from './DriverManagementCalendarView.module.scss';

export const DriverManagementCalendarView = ({
  onDateChange,
  value,
  schedules = [],
  users = [],
  branches = [],
  mgtTypes = []
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const can = useCan();
  const history = useHistory();
  const localization = useLocalization();
  const [activePopup, setActivePopup] = useState();

  const handleDeleteAction = schedule => async () => {
    const response = await dispatch(deleteSchedule({ id: schedule.id }));
    if (response.error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(`DriverManagement.deleteError`, {
            name: schedule?.name
          })} ${parseErrorMessage(response?.error)}`
        })
      );
    } else {
      // success
      dispatch(
        openToast({
          type: ToastType.Success,
          message: t('DriverManagement.deleteSuccess', { name: schedule?.name })
        })
      );
    }
  };

  const handleActivePopup = bool => {
    setActivePopup(bool);
  };

  return (
    <CalendarView
      onChange={onDateChange}
      value={value}
      renderer={day => {
        const schedulesForDay = schedules.filter(schedule => {
          if (!schedule.schedulesOn) {
            return false;
          }
          const scheduledAt = schedule.schedulesOn;
          return (scheduledAt || '').includes(day.date);
        });

        return schedulesForDay.map(schedule => {
          const { status } = schedule;
          const isCancelled = [STATUS.cancelled].includes(status);

          return (
            <Popover
              content={
                <CalendarPopupContent
                  title={schedule.name}
                  wrapButtons
                  rows={preparePopupRows({
                    t,
                    history,
                    localization,
                    users,
                    branches,
                    mgtTypes,
                    schedule,
                    isCancelled,
                    can
                  })}
                  actions={preparePopupActions({
                    t,
                    history,
                    schedule,
                    handleDeleteAction,
                    handleActivePopup
                  })}
                />
              }
              trigger="click"
              placement="right"
              key={schedule.id}
              open={activePopup === schedule.id}
              onOpenChange={() => setActivePopup(activePopup ? !activePopup : schedule.id)}
            >
              <div className={style.calendarItemWrapper}>
                <RowStatus showIcon status={statusMap(schedule.status.toLowerCase())}>
                  <Tooltip
                    content={schedule.name}
                    target={
                      <>
                        <div className={style.calendarItemName}>{schedule.name}</div>
                        <div>
                          {getMntType(mgtTypes, schedule[InnerColumnKeys.TYPE_ID], can, true)}
                        </div>
                      </>
                    }
                  />
                </RowStatus>
              </div>
            </Popover>
          );
        });
      }}
    />
  );
};
