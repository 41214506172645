import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { API_PATH } from '../../config';
import { ApiClient, CompaniesApi } from '../../nextgen_api/index';
import { useDispatch, useSelector } from 'react-redux';

export const actionTypes = {
  init: 0,
  processing: 1,
  error: 2,
  done: 3
};

export const RESET_TIME = 15 * 1000; //30 seconds

export const cameraSnapshotConfigSlice = createSlice({
  name: 'cameraSnapshotConfig',
  initialState: {
    data: {}
  },
  reducers: {
    fetchCameraSnapshotConfigStart(state, { payload }) {
      const { companyId, camProvider } = payload;
      if (state.data[companyId] == null) {
        state.data[companyId] = {};
      }
      if (state.data[companyId][camProvider] == null) {
        state.data[companyId][camProvider] = {};
      }
      if (state.data[companyId][camProvider].status == null) {
        state.data[companyId][camProvider].status = {};
      }
      state.data[companyId][camProvider].status.fetching = actionTypes.processing;
      state.data[companyId][camProvider].status.fetchingTime = moment().valueOf();
      state.data[companyId][camProvider].status.fetchingError = null;
    },
    fetchCameraSnapshotConfigSucceeded(state, { payload }) {
      const { companyId, camProvider, config } = payload;
      if (state.data[companyId] == null) {
        state.data[companyId] = {};
      }
      if (state.data[companyId][camProvider] == null) {
        state.data[companyId][camProvider] = {};
      }
      if (state.data[companyId][camProvider].status == null) {
        state.data[companyId][camProvider].status = {};
      }
      state.data[companyId][camProvider].config = config;
      state.data[companyId][camProvider].status.fetching = actionTypes.done;
      state.data[companyId][camProvider].status.fetchingError = null;
    },
    fetchCameraSnapshotConfigFailed(state, { payload }) {
      const { companyId, camProvider, err } = payload;
      if (state.data[companyId] == null) {
        state.data[companyId] = {};
      }
      if (state.data[companyId][camProvider] == null) {
        state.data[companyId][camProvider] = {};
      }
      if (state.data[companyId][camProvider].status == null) {
        state.data[companyId][camProvider].status = {};
      }

      state.data[companyId][camProvider].status.fetching = actionTypes.error;
      state.data[companyId][camProvider].status.fetchingError = err;
    },
    //update config
    updateCameraSnapshotConfigStart(state, { payload }) {
      const { companyId, camProvider } = payload;
      if (state.data[companyId] == null) {
        state.data[companyId] = {};
      }
      if (state.data[companyId][camProvider] == null) {
        state.data[companyId][camProvider] = {};
      }
      if (state.data[companyId][camProvider].status == null) {
        state.data[companyId][camProvider].status = {};
      }
      state.data[companyId][camProvider].status.updating = actionTypes.processing;
      state.data[companyId][camProvider].status.updatingTime = moment().valueOf();
      state.data[companyId][camProvider].status.updatingError = null;
    },
    updateCameraSnapshotConfigSucceeded(state, { payload }) {
      const { companyId, camProvider, config } = payload;
      if (state.data[companyId] == null) {
        state.data[companyId] = {};
      }
      if (state.data[companyId][camProvider] == null) {
        state.data[companyId][camProvider] = {};
      }
      if (state.data[companyId][camProvider].status == null) {
        state.data[companyId][camProvider].status = {};
      }
      state.data[companyId][camProvider].config = config;
      state.data[companyId][camProvider].status.updating = actionTypes.done;
      state.data[companyId][camProvider].status.updatingError = null;
    },
    updateCameraSnapshotConfigFailed(state, { payload }) {
      const { companyId, camProvider, err } = payload;
      if (state.data[companyId] == null) {
        state.data[companyId] = {};
      }
      if (state.data[companyId][camProvider] == null) {
        state.data[companyId][camProvider] = {};
      }
      if (state.data[companyId][camProvider].status == null) {
        state.data[companyId][camProvider].status = {};
      }
      state.data[companyId][camProvider].status.updating = actionTypes.error;
      state.data[companyId][camProvider].status.updatingError = err;
    },
    //delete config
    deleteCameraSnapshotConfigStart(state, { payload }) {
      const { companyId, camProvider } = payload;
      if (state.data[companyId] == null) {
        state.data[companyId] = {};
      }
      if (state.data[companyId][camProvider] == null) {
        state.data[companyId][camProvider] = {};
      }
      if (state.data[companyId][camProvider].status == null) {
        state.data[companyId][camProvider].status = {};
      }
      state.data[companyId][camProvider].status.deleting = actionTypes.processing;
      state.data[companyId][camProvider].status.deletingTime = moment().valueOf();
      state.data[companyId][camProvider].status.deletingError = null;
    },
    deleteCameraSnapshotConfigSucceeded(state, { payload }) {
      const { companyId, camProvider } = payload;
      if (state.data[companyId] == null) {
        state.data[companyId] = {};
      }
      if (state.data[companyId][camProvider] == null) {
        state.data[companyId][camProvider] = {};
      }
      if (state.data[companyId][camProvider].status == null) {
        state.data[companyId][camProvider].status = {};
      }
      state.data[companyId][camProvider].config = null;
      state.data[companyId][camProvider].status.deleting = actionTypes.done;
      state.data[companyId][camProvider].status.deletingError = null;
    },
    deleteCameraSnapshotConfigFailed(state, { payload }) {
      const { companyId, camProvider, err } = payload;
      if (state.data[companyId] == null) {
        state.data[companyId] = {};
      }
      if (state.data[companyId][camProvider] == null) {
        state.data[companyId][camProvider] = {};
      }
      if (state.data[companyId][camProvider].status == null) {
        state.data[companyId][camProvider].status = {};
      }
      state.data[companyId][camProvider].status.deleting = actionTypes.error;
      state.data[companyId][camProvider].status.deletingError = err;
    }
  }
});

export const {
  fetchCameraSnapshotConfigStart,
  fetchCameraSnapshotConfigSucceeded,
  fetchCameraSnapshotConfigFailed,

  updateCameraSnapshotConfigStart,
  updateCameraSnapshotConfigSucceeded,
  updateCameraSnapshotConfigFailed,

  deleteCameraSnapshotConfigStart,
  deleteCameraSnapshotConfigSucceeded,
  deleteCameraSnapshotConfigFailed
} = cameraSnapshotConfigSlice.actions;

export const fetchCameraSnapshotConfigProxy = (userKey, companyId, camProvider) => async (
  dispatch,
  getState
) => {
  dispatch(fetchCameraSnapshotConfigStart({ companyId, camProvider }));
  const apiClient = new ApiClient();
  apiClient.basePath = API_PATH;
  apiClient.defaultHeaders = {
    Authorization: `Token token="${userKey}"`
  };

  const companiesApi = new CompaniesApi(apiClient);
  const promise = new Promise((resolve, reject) => {
    companiesApi.getCameraSnapshotConfig(
      companyId,
      userKey,
      { provider: camProvider },
      (err, data, resp) => {
        if (err || resp.status !== 200) {
          if (resp?.status === 204) {
            resolve({});
          } else {
            console.error(err);
            reject(err);
          }
        } else {
          resolve(resp.body);
        }
      }
    );
  });

  try {
    const config = await promise;
    dispatch(fetchCameraSnapshotConfigSucceeded({ companyId, camProvider, config }));
  } catch (error) {
    const err = {
      status: (error && error.status) || 400,
      message: (error && error.message) || 'Bad Request'
    };
    dispatch(fetchCameraSnapshotConfigFailed({ companyId, camProvider, err }));
  }
};

export const updateCameraSnapshotConfig = (userKey, companyId, camProvider, config) => async (
  dispatch,
  getState
) => {
  const cameraConfigs = await getState().cameraSnapshotConfig;
  const addingConfigToCompany = cameraConfigs.data[companyId] == null;
  const addingConfigToCompanyProvider =
    cameraConfigs.data[companyId][camProvider] == null ||
    cameraConfigs.data[companyId][camProvider].status == null;
  const exceedConfigUpdateResetTime =
    cameraConfigs.data[companyId][camProvider].status.updating !== actionTypes.processing &&
    moment().valueOf() - (cameraConfigs.data[companyId][camProvider].status.updatingTime || 0) >=
      RESET_TIME;
  if (addingConfigToCompany || addingConfigToCompanyProvider || exceedConfigUpdateResetTime) {
    dispatch(updateCameraSnapshotConfigStart({ companyId, camProvider }));
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    const companiesApi = new CompaniesApi(apiClient);
    const promise = new Promise((resolve, reject) => {
      companiesApi.createCameraSnapshotConfig(
        companyId,
        userKey,
        { body: config },
        (err, data, resp) => {
          if (err || resp.status !== 200) {
            console.error(err);
            reject(err);
          } else {
            resolve(resp.body);
          }
        }
      );
    });

    try {
      const config = await promise;
      dispatch(updateCameraSnapshotConfigSucceeded({ companyId, camProvider, config }));
    } catch (error) {
      const err = {
        status: (error && error.status) || 400,
        message: (error && error.message) || 'Bad Request'
      };
      dispatch(updateCameraSnapshotConfigFailed({ companyId, camProvider, config, err }));
    }
  }
};

export const deleteCameraSnapshotConfig = (userKey, companyId, camProvider, configId) => async (
  dispatch,
  getState
) => {
  const cameraConfigs = await getState().cameraSnapshotConfig;
  if (
    cameraConfigs.data[companyId] == null ||
    cameraConfigs.data[companyId][camProvider] == null ||
    cameraConfigs.data[companyId][camProvider].status == null ||
    (cameraConfigs.data[companyId][camProvider].status.deleting !== actionTypes.processing &&
      moment().valueOf() - (cameraConfigs.data[companyId][camProvider].status.deletingTime || 0) >=
        RESET_TIME)
  ) {
    dispatch(deleteCameraSnapshotConfigStart({ companyId, camProvider, configId }));
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    const companiesApi = new CompaniesApi(apiClient);
    const promise = new Promise((resolve, reject) => {
      companiesApi.deleteCameraSnapshotConfig(companyId, configId, userKey, (err, data, resp) => {
        if (err || resp.status !== 200) {
          if (resp.status === 204) {
            resolve(null);
          } else {
            console.error(err);
            reject(err);
          }
        } else {
          resolve(resp.body);
        }
      });
    });

    try {
      const data = await promise;
      dispatch(deleteCameraSnapshotConfigSucceeded({ companyId, camProvider, configId, data }));
    } catch (error) {
      const err = {
        status: (error && error.status) || 400,
        message: (error && error.message) || 'Bad Request'
      };
      dispatch(deleteCameraSnapshotConfigFailed({ companyId, camProvider, configId, err }));
    }
  }
};

export const useAllCameraSnapshotConfig = () => useSelector(state => state.cameraSnapshotConfig);

export const useCameraSnapshotConfig = (action, companyId, camProvider, userKey) => {
  const dispatch = useDispatch();
  const cameraConfigs = useAllCameraSnapshotConfig();

  if (companyId == null || camProvider == null || userKey == null) {
    return null;
  }

  if (
    cameraConfigs.data[companyId] &&
    cameraConfigs.data[companyId][camProvider] &&
    cameraConfigs.data[companyId][camProvider].config
  ) {
    return cameraConfigs.data[companyId][camProvider];
  }

  if (
    cameraConfigs.data[companyId] == null ||
    cameraConfigs.data[companyId][camProvider] == null ||
    cameraConfigs.data[companyId][camProvider].status == null ||
    (cameraConfigs.data[companyId][camProvider].status.fetching !== actionTypes.processing &&
      action !== 'deleting' &&
      moment().valueOf() - cameraConfigs.data[companyId][camProvider].status.fetchingTime >=
        RESET_TIME)
  ) {
    dispatch(fetchCameraSnapshotConfigProxy(userKey, companyId, camProvider));
    return { status: { fetching: actionTypes.processing } };
  }

  return cameraConfigs.data[companyId][camProvider];
};

export default cameraSnapshotConfigSlice.reducer;
