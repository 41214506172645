import { useEffect, useMemo, useState } from 'react';
import { DuressAlertModal } from './Modals/DuressAlertModal';
import { useUserKey } from 'features/user/userSlice';
import { useSelector } from 'react-redux';
import { useUserAlerts } from 'features/user_alerts';
import { ALERT_TYPES } from 'containers/Configuration/CompanyAlerts/constants';

const DuressAlert = () => {
  const userKey = useUserKey();
  const userAlerts = useUserAlerts();

  const [showDuressModal, setShowDuressModal] = useState(false);
  const { notifications } = useSelector(state => state.notifications);

  const duressPopupActive = useMemo(() => {
    return userAlerts
      .find(alert => alert.type === ALERT_TYPES.DURESS)
      ?.associations?.some(assoc => assoc.onscreen);
  }, [userAlerts]);

  const duressNotifications = useMemo(() => notifications.filter(notif => notif?.event?.duress), [
    notifications
  ]);

  useEffect(() => {
    setShowDuressModal(userKey && duressPopupActive && duressNotifications?.length);
  }, [duressPopupActive, duressNotifications, userKey]);

  return showDuressModal ? (
    <DuressAlertModal
      visible={showDuressModal}
      notifications={duressNotifications}
      updateModalVisibility={value => setShowDuressModal(value)}
    />
  ) : null;
};

export default DuressAlert;
