/* SCSS Imports happen before all JS so that css-modules rules overrides it */
import './static/scss/font.scss';
import 'react-virtualized/styles.css';
import './static/scss/index.scss';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import Helmet from 'react-helmet';

import './i18nextConfig';
import { Loading } from 'components/loading/Loading';
import { useActiveBrand as getActiveBrand } from 'features/brands/useActiveBrand';

const brand = getActiveBrand();

// Adds passive property for events, where possible
(function() {
  if (typeof EventTarget !== 'undefined') {
    let func = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function(type, fn, capture) {
      this.func = func;
      if (typeof capture !== 'boolean') {
        capture = capture || {};
        capture.passive = false;
      }

      this.func(type, fn, capture);
    };
  }
})();
ReactDOM.render(
  <Suspense
    fallback={
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%'
        }}
      >
        <Loading />
      </div>
    }
  >
    <Helmet defer={false}>
      <title>{brand?.product || 'TN360'}</title>
      <link
        rel="icon"
        type="image/png"
        href={brand?.favicon || 'tn_favicon_16x16.png'}
        sizes="16x16"
      />
    </Helmet>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
