import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';

export function DeletedEntityAlert({ entity, entityStatus, style = {} }) {
  const { t } = useTranslation();
  const isEntityDeleted = useMemo(() => entityStatus === 'DISABLED', [entityStatus]);
  return (
    isEntityDeleted && (
      <Alert
        style={style}
        type="warning"
        showIcon
        message={t('Common.DeletedEntityAlert', { entity: t(`Entity.${entity}`) })}
      />
    )
  );
}
