import React, { createRef, useEffect, useState } from 'react';
import { Polyline } from 'react-google-maps';

function generateIcon(color) {
  return {
    icon: {
      path: 'M 0,-1 0,0',
      strokeOpacity: 0.8,
      strokeColor: color,
      scale: 2
    },
    offset: '0',
    repeat: '8px'
  };
}

export const DashedPolyline = ({ path, selected }) => {
  const ref = createRef();
  const [options, setOptions] = useState({
    strokeOpacity: 0,
    icons: [generateIcon(selected ? 'red' : '#2B323C')]
  });

  useEffect(() => {
    setOptions(prev => {
      return {
        ...prev,
        icons: [generateIcon(selected ? 'red' : '#2B323C')]
      };
    });
  }, [selected]);

  return <Polyline ref={ref} path={path} options={options} />;
};
