/**
 * TachoService APIs
 * A set of APIs for accessing the TachoCard feature and data
 *
 * The version of the OpenAPI document: v1
 *
 * NOTE: This class is auto generated).
 * OpenAPI Generator version: 5.0.0-SNAPSHOT
 *
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";

/**
* FatigueStatusApi service.
* @module api/FatigueStatusApiApi
* @version v1
*/
export default class FatigueStatusApiApi {

    /**
    * Constructs a new FatigueStatusApiApi. 
    * @alias module:api/FatigueStatusApiApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the fatigueStatusApiDelete operation.
     * @callback module:api/FatigueStatusApiApi~fatigueStatusApiDeleteCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/FatigueStatusApiApi~fatigueStatusApiDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     */
    fatigueStatusApiDelete(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fatigueStatusApiDelete");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/fatiguestatus/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the fatigueStatusApiGet operation.
     * @callback module:api/FatigueStatusApiApi~fatigueStatusApiGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FatigueStatusDbModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:api/FatigueStatusApiApi~fatigueStatusApiGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/FatigueStatusDbModel}
     */
    fatigueStatusApiGet(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fatigueStatusApiGet");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/fatiguestatus/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the fatigueStatusApiGetAll operation.
     * @callback module:api/FatigueStatusApiApi~fatigueStatusApiGetAllCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/FatigueStatusDbModel>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:api/FatigueStatusApiApi~fatigueStatusApiGetAllCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/FatigueStatusDbModel>}
     */
    fatigueStatusApiGetAll(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/fatiguestatus', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the fatigueStatusApiGetCard operation.
     * @callback module:api/FatigueStatusApiApi~fatigueStatusApiGetCardCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FatigueStatusDbModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {String} cardnumber 
     * @param {module:api/FatigueStatusApiApi~fatigueStatusApiGetCardCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/FatigueStatusDbModel}
     */
    fatigueStatusApiGetCard(cardnumber, callback) {
      let postBody = null;
      // verify the required parameter 'cardnumber' is set
      if (cardnumber === undefined || cardnumber === null) {
        throw new Error("Missing the required parameter 'cardnumber' when calling fatigueStatusApiGetCard");
      }

      let pathParams = {
        'cardnumber': cardnumber
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/fatiguestatus/cardnumber/{cardnumber}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the fatigueStatusApiGetCompany operation.
     * @callback module:api/FatigueStatusApiApi~fatigueStatusApiGetCompanyCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/FatigueStatusDbModel>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} companyId 
     * @param {module:api/FatigueStatusApiApi~fatigueStatusApiGetCompanyCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/FatigueStatusDbModel>}
     */
    fatigueStatusApiGetCompany(companyId, callback) {
      let postBody = null;
      // verify the required parameter 'companyId' is set
      if (companyId === undefined || companyId === null) {
        throw new Error("Missing the required parameter 'companyId' when calling fatigueStatusApiGetCompany");
      }

      let pathParams = {
        'companyId': companyId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/fatiguestatus/company/{companyId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the fatigueStatusApiGetUser operation.
     * @callback module:api/FatigueStatusApiApi~fatigueStatusApiGetUserCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FatigueStatusDbModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} userId 
     * @param {module:api/FatigueStatusApiApi~fatigueStatusApiGetUserCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/FatigueStatusDbModel}
     */
    fatigueStatusApiGetUser(userId, callback) {
      let postBody = null;
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling fatigueStatusApiGetUser");
      }

      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/fatiguestatus/user/{userId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the fatigueStatusApiPost operation.
     * @callback module:api/FatigueStatusApiApi~fatigueStatusApiPostCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {module:model/FatigueStatusDbModel} value 
     * @param {module:api/FatigueStatusApiApi~fatigueStatusApiPostCallback} callback The callback function, accepting three arguments: error, data, response
     */
    fatigueStatusApiPost(value, callback) {
      let postBody = value;
      // verify the required parameter 'value' is set
      if (value === undefined || value === null) {
        throw new Error("Missing the required parameter 'value' when calling fatigueStatusApiPost");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = [];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/fatiguestatus', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the fatigueStatusApiPut operation.
     * @callback module:api/FatigueStatusApiApi~fatigueStatusApiPutCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * @param {Number} id 
     * @param {module:model/FatigueStatusDbModel} value 
     * @param {module:api/FatigueStatusApiApi~fatigueStatusApiPutCallback} callback The callback function, accepting three arguments: error, data, response
     */
    fatigueStatusApiPut(id, value, callback) {
      let postBody = value;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fatigueStatusApiPut");
      }
      // verify the required parameter 'value' is set
      if (value === undefined || value === null) {
        throw new Error("Missing the required parameter 'value' when calling fatigueStatusApiPut");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = [];
      let returnType = [];
      return this.apiClient.callApi(
        '/tacho/fatiguestatus/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
