import React from 'react';
import styles from '../Overview.module.scss';
import { WorkStatus } from 'containers/ELD/Constants';
import i18next from 'i18nextConfig';
const TWO_HOURS = 2 * 60 * 60;
export function cycleTimeCell_Default(rowData) {
  const isExempt = rowData.isExemptDriver || false;
  const cycleTimeLeft = isExempt ? 0 : Math.max(rowData?.cycleTimeLeft || 0, 0);
  const maxCycleTime = Math.max(1, (rowData?.cycleTime || 0) + (rowData?.cycleTimeLeft || 0));

  let percent = Math.min(100, (cycleTimeLeft / maxCycleTime).toFixed(2) * 100);
  let barStyle = styles.cycleTimeBar;
  switch (rowData.cycleTimeStatus) {
    case WorkStatus.AtRisk:
      if (cycleTimeLeft <= 0) {
        barStyle = styles.outline_yellow;
      } else {
        barStyle = styles.yellow;
      }
      break;
    case WorkStatus.InVio:
      barStyle = styles.red;
      if (percent === 0) {
        percent = 100;
      }
      break;
    default:
      if (rowData.lastUpdate !== 'N/A' && cycleTimeLeft <= 0) {
        barStyle = styles.outline_yellow;
      } else if (cycleTimeLeft < TWO_HOURS) {
        barStyle = styles.yellow;
      }
      break;
  }
  if (isExempt) {
    barStyle = '';
  }
  return (
    <div className={styles.barChart}>
      <div className={styles.bar + ' ' + barStyle}>
        <span
          className={percent === 100 ? styles.fullBar : styles.progressBar}
          style={{ width: percent + '%' }}
        ></span>
      </div>
      {!isExempt && (
        <p>
          {Math.floor(cycleTimeLeft / 3600)} {i18next.t('Common.Hours')}{' '}
          {Math.floor((cycleTimeLeft % 3600) / 60)} {i18next.t('Common.Mins')}
        </p>
      )}
      {isExempt && <p>{i18next.t('Common.Exempt')}</p>}
    </div>
  );
}
